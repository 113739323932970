import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from './src/renderer-keys';

const DIRTY_DIALOG_DEFAULT_TITLE = 'Warning';
const DIRTY_DIALOG_DEFAULT_MESSAGE =
  'You have unsaved changes that will be lost if you navigate away.';

export const DIRTY_DIALOG_REFRESH_CLAIM_TITLE = 'Refresh Draft';
export const DIRTY_DIALOG_REFRESH_CLAIM_MESSAGE = html`
  You selected Refresh Draft, but have unsaved changes. <br />
  Would you like to save changes and refresh claim draft?
`;

export const SAVE_BUTTON = {
  name: 'save',
  label: 'save',
};
export const DISABLED_SAVE_BUTTON = {
  ...SAVE_BUTTON,
  disabled: true,
};
export const DISCARD_BUTTON = {
  name: 'discard',
  label: 'discard changes',
};
export const STAY_BUTTON = {
  name: 'stay',
  label: 'stay on page',
};
export const SAVE_REFRESH_BUTTON = {
  name: 'saveRefresh',
  label: 'save & refresh',
};
export const DISCARD_REFRESH_BUTTON = {
  name: 'discardRefresh',
  label: 'discard & refresh',
};
export const CANCEL_BUTTON = {
  name: 'cancel',
  label: 'cancel',
};

export const DIRTY_POPUP_MODEL = {
  title: 'Warning',
  message:
    'You have unsaved changes that will be lost if you navigate away. Are you sure you wish to discard these changes?',
  confirmText: 'Discard Changes',
  cancelText: 'Stay on Page',
};

export function openDirtyPopup(model = DIRTY_POPUP_MODEL) {
  return openPopup(POPUP_RENDER_KEYS.CONFIRM, model);
}

export const DIRTY_POPUP_MODEL_WITH_SAVE_OPTION = {
  title: DIRTY_DIALOG_DEFAULT_TITLE,
  message: [
    DIRTY_DIALOG_DEFAULT_MESSAGE,
    'Do you wish to save or discard your changes?',
  ],
  buttons: [SAVE_BUTTON, DISCARD_BUTTON, STAY_BUTTON],
};

export const DIRTY_POPUP_MODEL_WITH_DISABLE_SAVE_OPTION = {
  title: DIRTY_DIALOG_DEFAULT_TITLE,
  message: [
    DIRTY_DIALOG_DEFAULT_MESSAGE,
    'Are you sure you wish to discard these changes?',
  ],
  buttons: [DISABLED_SAVE_BUTTON, DISCARD_BUTTON, STAY_BUTTON],
};

export const REFRESH_CLAIM_DIRTY_POPUP = {
  title: DIRTY_DIALOG_REFRESH_CLAIM_TITLE,
  message: DIRTY_DIALOG_REFRESH_CLAIM_MESSAGE,
  buttons: [SAVE_REFRESH_BUTTON, DISCARD_REFRESH_BUTTON, CANCEL_BUTTON],
};

export const openDirtyDialogByModel = model =>
  openPopup(POPUP_RENDER_KEYS.DIALOG, model);

export const openDirtyDialog = ({ formIsValid = true }) =>
  openDirtyDialogByModel(
    formIsValid
      ? DIRTY_POPUP_MODEL_WITH_SAVE_OPTION
      : DIRTY_POPUP_MODEL_WITH_DISABLE_SAVE_OPTION,
  );

export const openClaimRefreshDirtyDialog = () =>
  openDirtyDialogByModel(REFRESH_CLAIM_DIRTY_POPUP);
