/**
 * Provides a way to lazy import the redux store, callers must call initReduxStore before getStore
 */

let store = null;

export const initReduxStore = async () => {
  if (store) return;
  ({ store } = await import('../../../../neb-redux/neb-redux-store'));
};

export const getStore = () => {
  if (!store) throw Error('Store is not initialized in lazy-redux-store!');
  return store;
};
