import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/settings/neb-check-in-question-table-row';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/neb-table-header';

import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
} from '../../../../packages/neb-styles/neb-variables';
import { fetchAllQuestions } from '../../../api-clients/questionnaires';
import { layoutStyles } from '../../../styles';

export const SPACER = {
  key: 'spacer',
  label: '',
  flex: css`0 0 180px`,
};

export const CONFIG = [
  {
    key: 'displayText',
    label: 'Question',
    flex: css`0 0 70%`,
  },
];

export const ELEMENTS = {
  header: { id: 'header' },
  actionBar: { id: 'action-bar' },
  table: { id: 'table' },
  tableHeader: { id: 'table-header' },
  rows: {
    selector: 'neb-check-in-question-table-row',
  },
  description: {
    id: 'description',
  },
  searchField: {
    id: 'neb-search-field',
  },
  noSearchResults: {
    id: 'no-results-found',
  },
};

class NebOverlayAddCheckInQuestions extends Overlay {
  static get properties() {
    return {
      __selectedQuestions: Array,
      __allQuestions: Array,
      __searchText: String,
      __searchQuestions: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,

      css`
        .content {
          display: flex;
          flex-direction: column;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .description {
          padding-left: ${CSS_SPACING};
        }

        .table-content {
          overflow: auto;
        }

        .textfield-search {
          margin: ${CSS_SPACING} ${CSS_SPACING} 14px;
        }

        .text-no-items {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      add: question => {
        this.__selectedQuestions = [...this.__selectedQuestions, question];
      },
      dismiss: () => this.dismiss(this.__selectedQuestions),
      search: e => {
        this.__searchText = e.value;
        this.onSearch();
      },
      clearSearch: () => {
        this.__searchText = '';
        this.onSearch();
      },
    };
  }

  initState() {
    super.initState();
    this.__allQuestions = [];
    this.__selectedQuestions = [];
    this.__searchQuestions = [];
    this.__searchText = '';
    this.model = [];

    this.onSearch = () => this.filterQuestions();
  }

  async firstUpdated() {
    super.firstUpdated();
    const questions = await fetchAllQuestions();

    this.__allQuestions = questions.map(
      ({ id, displayText, type, narrativeText }) => ({
        id,
        displayText,
        type,
        narrativeText,
      }),
    );
  }

  filterQuestions() {
    this.__searchQuestions = this.__searchText
      ? this.__allQuestions.filter(question =>
          question.displayText
            .toLowerCase()
            .includes(this.__searchText.toLowerCase()),
        )
      : [];
  }

  isQuestionAdded({ id }) {
    return (
      Boolean(this.model.find(item => item.id === id)) ||
      Boolean(this.__selectedQuestions.find(item => item.id === id))
    );
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Questions"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>

      <div id="${ELEMENTS.description.id}" class="description">
        Search for and select questions to add.
      </div>
    `;
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.model.description}
      </div>
    `;
  }

  __renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.dismiss}"
      ></neb-action-bar>
    `;
  }

  __renderTableContent() {
    if (this.__searchText && !this.__searchQuestions.length) {
      return html`
        <div class="text-no-items" id="${ELEMENTS.noSearchResults.id}">
          <p>No results found.</p>
        </div>
      `;
    }

    const questions = this.__searchQuestions.length
      ? this.__searchQuestions
      : this.__allQuestions;

    return questions.map(
      question => html`
        <neb-check-in-question-table-row
          topic="Question"
          .model="${question}"
          .config="${CONFIG}"
          .onAdd="${this.handlers.add}"
          ?added="${this.isQuestionAdded(question)}"
        ></neb-check-in-question-table-row>
      `,
    );
  }

  __renderSearchBar() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="textfield-search"
        leadingIcon="neb:search"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .placeholder="${'Filter Questions'}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-header
        id="${ELEMENTS.tableHeader.id}"
        .config="${[SPACER, ...CONFIG]}"
      ></neb-table-header>

      <div class="table-content">${this.__renderTableContent()}</div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderSearchBar()}
      ${this.__renderTable()} ${this.__renderActionBar()}
    `;
  }
}

customElements.define(
  'neb-overlay-add-check-in-questions',
  NebOverlayAddCheckInQuestions,
);
