import { html, css, LitElement } from 'lit';

import '../../questions/neb-question';
import '../../misc/neb-body-diagram';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  addComplaintButton: {
    id: 'add-complaint-button',
  },
  removeComplaintButton: {
    id: 'remove-complaint-button',
  },
  finishButton: {
    id: 'finish-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
  questions: {
    selector: 'neb-question',
  },
  bodyDiagram: {
    id: 'body-diagram',
  },
};

class NebPageSubjectiveQuestionnaire extends LitElement {
  static get properties() {
    return {
      highlightRequiredFields: Boolean,
      questionnaire: Object,
      selectedBodyPartIds: Array,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }

      .content {
        display: flex;
        padding-top: 10px;
      }
      :host([layout='small']) .content {
        flex-direction: column;
      }

      neb-body-diagram {
        min-width: 300px;
      }

      .questions-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 ${CSS_SPACING} 40px ${CSS_SPACING};
      }

      .buttons {
        display: flex;
        gap: ${CSS_SPACING};
        padding: 0 ${CSS_SPACING};
      }
      .buttons > .spacer {
        flex: 1;
      }
      :host([layout='small']) .buttons {
        flex-direction: column;
      }
    `;
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.questionnaire = { questions: [] };
    this.highlightRequiredFields = false;
    this.selectedBodyPartIds = [];

    this.onSelectBodyPart = () => {};

    this.onAnswerChanged = () => {};

    this.onAddSymptom = () => {};

    this.onRemoveSymptom = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};
  }

  initHandlers() {
    this.handlers = {
      answerChanged: changedQuestion => {
        this.onAnswerChanged({
          ...this.questionnaire,
          questions: this.questionnaire.questions.map(question =>
            question.id === changedQuestion.id ? changedQuestion : question,
          ),
        });
      },
    };
  }

  render() {
    return html`
      <div class="content">${this.__renderContent()}</div>
      <div class="buttons">${this.__renderButtons()}</div>
    `;
  }

  __renderContent() {
    return html`
      ${this.__renderBodyDiagram()}
      <div class="questions-container">${this.__renderQuestions()}</div>
    `;
  }

  __renderBodyDiagram() {
    return this.questionnaire.showBodyDiagram
      ? html`
          <neb-body-diagram
            id="${ELEMENTS.bodyDiagram.id}"
            selectedBodyView="${this.questionnaire.selectedBodyView}"
            selectedBodyPartId="${this.questionnaire.selectedBodyPartId}"
            selectedBodyPartName="${this.questionnaire.selectedBodyPartName}"
            .selectedBodyPartIds="${this.selectedBodyPartIds}"
            .onChange="${this.onSelectBodyPart}"
          ></neb-body-diagram>
        `
      : html``;
  }

  __renderQuestions() {
    return this.questionnaire.questions.map(
      question => html`
        <neb-question
          .model="${question}"
          class="${this.highlightRequiredFields && !question.isAnswered
            ? 'incomplete-questionnaire'
            : ''}"
          .onAnswerChanged="${this.handlers.answerChanged}"
        ></neb-question>
      `,
    );
  }

  __renderButtons() {
    return html`
      ${this.questionnaire.showBodyDiagram
        ? html`
            <neb-button
              id="${ELEMENTS.addComplaintButton.id}"
              class="button-action"
              .label="${'Add Additional Complaint'}"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.onAddSymptom}"
              unelevated
            ></neb-button>
            <neb-button
              id="${ELEMENTS.removeComplaintButton.id}"
              class="button-action"
              .label="${'Remove this Complaint'}"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.onRemoveSymptom}"
              unelevated
            ></neb-button>
            <div class="spacer"></div>
          `
        : html``}
      <neb-button
        id="${ELEMENTS.finishButton.id}"
        class="button-action"
        .label="${'Finish'}"
        .role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.onSave}"
        unelevated
      ></neb-button>
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        class="button-action"
        .label="${'Cancel'}"
        .role="${BUTTON_ROLE.CANCEL}"
        .onClick="${this.onCancel}"
        unelevated
      ></neb-button>
    `;
  }
}

customElements.define(
  'neb-page-subjective-questionnaire',
  NebPageSubjectiveQuestionnaire,
);
