export const TEST_SAMPLE_CLAIM = {
  invoiceId: '5b259686-51ce-4a93-b0b7-e1da5fa22518',
  federalTaxIdNumber: '11-1234567',
  serviceFacilityNPI: '1234567890',
  serviceFacilityName: 'Back to Basics Chiropractic',
  serviceFacilityAddress: {
    address1: '123 Main St',
    address2: '',
    city: 'San Diego',
    state: 'CA',
    zipCode: '92121',
    phone: '',
  },
  billingProviderPracticeName: 'Back to Basics Chiropractic',
  billingProviderAddress: {
    address1: '123 Main St',
    address2: '',
    city: 'San Diego',
    state: 'CA',
    zipCode: '92121',
    phone: '5555555555',
  },
  patientFirstName: 'Josh',
  patientLastName: 'Nogra',
  patientMiddleName: '',
  patientSuffix: '',
  patientDateOfBirth: '1991-02-09T00:00:00-08:00',
  patientSex: 'Male',
  medicalRecordNumber: 'CT30008',
  patientAddress: {
    address1: '540 Fort Evans Rd NE',
    address2: '200C',
    city: 'Leesburg',
    state: 'VA',
    zipCode: '20176',
    phone: '',
  },
  insuredIdNumber: '50239DF312',
  insuredRelationToPatient: 'Employer',
  insurancePlanName: 'Good Insurance',
  insurancePayerName: 'Blue Cross Blue Shield of California',
  insurancePayerAlias: 'BCBS-CA',
  insurancePayerAddress: {
    address1: 'PO Box 60007',
    address2: null,
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90060',
  },
  insuredGroupIdNumber: '4299110',
  insuredFirstName: 'Jake',
  insuredLastName: 'StateShield',
  insuredMiddleName: 'From',
  insuredDateOfBirth: '1990-10-23T00:00:00-07:00',
  insuredAddress: {
    address1: '1329 Ewge Terrace',
    address2: 'Efueh Road',
    city: 'Jurlorud',
    state: 'AL',
    zipCode: '41083',
    phone: '',
  },
  chartNotesRequired: false,
  spinalManipInfoRequired: false,
  priorAuthorizationRequired: false,
  claimFilingIndicator: 'BL - Blue Cross/Blue Shield',
  submissionMethodPrimary: 'Electronic Claims',
  submissionMethodSecondary: null,
  timelyFiling: null,
  additionalClaimInfos: [],
  claimCodes: '',
  claimCodeRequired: false,
  claimCharges: [
    {
      billWithTreatmentInitiationDate: false,
      billWithXrayDate: false,
      shaded24: null,
      dateOfService: '2020-12-22T09:00:29-08:00',
      pos: 11,
      code: '99X14',
      modifier_1: null,
      modifier_2: null,
      modifier_3: null,
      modifier_4: null,
      chargeAmount: 17500,
      units: 1,
      EPSDT: null,
      diagnosisPointers: '',
      providerOtherIdQualifier: null,
      providerOtherId: null,
      providerNPI: 1234567890,
      lineItemId: 'c7c25cfb-5873-4050-90df-1bb82153c655',
      encounterId: 'ef840d3d-a349-41be-b20b-3e775a16f54a',
    },
    {
      billWithTreatmentInitiationDate: false,
      billWithXrayDate: false,
      shaded24: null,
      dateOfService: '2020-12-22T09:00:29-08:00',
      pos: 11,
      code: 'BIOFR4',
      modifier_1: null,
      modifier_2: null,
      modifier_3: null,
      modifier_4: null,
      chargeAmount: 1200,
      units: 1,
      EPSDT: null,
      diagnosisPointers: '',
      providerOtherIdQualifier: null,
      providerOtherId: null,
      providerNPI: 1234567890,
      lineItemId: 'a31cb78b-77fd-4ec1-933d-9325c9e913ca',
      encounterId: 'ef840d3d-a349-41be-b20b-3e775a16f54a',
    },
    {
      billWithTreatmentInitiationDate: false,
      billWithXrayDate: false,
      shaded24: null,
      dateOfService: '2020-12-22T09:00:29-08:00',
      pos: 11,
      code: 'TheraB',
      modifier_1: null,
      modifier_2: null,
      modifier_3: null,
      modifier_4: null,
      chargeAmount: 1700,
      units: 1,
      EPSDT: null,
      diagnosisPointers: '',
      providerOtherIdQualifier: null,
      providerOtherId: null,
      providerNPI: 1234567890,
      lineItemId: '1200e2ab-daf9-4d34-bc2f-c5f001582d4a',
      encounterId: 'ef840d3d-a349-41be-b20b-3e775a16f54a',
    },
  ],
  paidAmount: 0,
  totalChargeAmount: 20400,
  otherClaimQualifier: 'wN',
  otherClaimId: 'Eo8)EwL6',
  disabilityFromDate: '2019-09-28T17:00:00-07:00',
  disabilityToDate: '2019-10-23T17:00:00-07:00',
  hospitalizationFromDate: '2019-10-15T17:00:00-07:00',
  hospitalizationToDate: '2019-10-16T17:00:00-07:00',
  referringProviderFirstName: '',
  referringProviderMiddleName: '',
  referringProviderLastName: '',
  referringProviderCredentials: '',
  referringProviderQualifier: 'DK',
  patientConditionRelatedCausesCode: 'AA',
  autoAccidentState: 'NV',
  otherDate: '2020-12-15T14:34:33-08:00',
  otherDateQualifier: '439',
  dateOfCurrentIllness: '2019-10-01T17:00:00-07:00',
  dateOfCurrentIllnessQualifier: '431',
  claimDiagnoses: [
    { code: 'S43.499A', order: 0 },
    { code: 'S43.499D', order: 1 },
    { code: 'T23.452A', order: 2 },
    { code: 'V95.9XXA', order: 3 },
  ],
  billingProviderOtherIdQualifier: '',
  billingProviderOtherId: '',
  billingProviderNPI: 1234567890,
  billingProviderFirstName: 'Patch',
  billingProviderLastName: 'Adams',
  billingProviderMiddleName: null,
  billingProviderSuffix: null,
  billingProviderSignatureS3Key:
    '/signature/95a2f25c-9c59-4b77-ad03-947cd1007d17/6d48a833-0469-45f9-ac69-58885edecbaf',
  billingProviderId: '95a2f25c-9c59-4b77-ad03-947cd1007d17',
  serviceFacilityOtherIdQualifier: '',
  serviceFacilityOtherId: '',
  otherInsuredFirstName: '',
  otherInsuredLastName: '',
  otherInsuredMiddleName: '',
  otherInsuredGroupIdNumber: '',
  otherInsurancePlanName: '',
  status: 'Generated',
  paymentResponsibilityLevelCode: 'Primary',
  patientId: '739c571f-dca0-443d-9d8a-a91f7d24c718',
  insuredSuffix: '',
  otherInsuredSuffix: '',
  referringProviderOtherIdQualifier: '',
  referringProviderOtherId: '',
  referringProviderNPI: '',
  additionalClaimInformation: '',
  isOutsideLab: '',
  outsideLabChargesAmount: 0,
  resubmissionCode: '',
  originalReferenceNumber: '',
  authorizationNumber: '',
  federalTaxIdType: '',
  acceptAssignment: true,
  dateGenerated: null,
  insuredSex: '',
  isElectronic: true,
  id: 'aac9dd43-20ca-487b-a254-65a38f1a2cea',
};
