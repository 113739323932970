import '../neb-card';

import { LitElement, html, css } from 'lit';

import { updateCardTextSize } from '../../../../../src/utils/cards';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import TextService from '../../../../neb-utils/services/text';

export const CARDS = {
  READY: {
    title: 'Ready To Submit',
    name: 'ready-to-submit',
    element: 'readyToSubmitCard',
  },
  NEEDS_ATTENTION: {
    title: 'Needs Attention',
    name: 'needs-attention',
    element: 'needsAttentionCard',
  },
  SUBMITTED: {
    title: 'Submitted',
    name: 'submitted',
    element: 'submittedCard',
  },
  DENIED: { title: 'Denied & Rejected', name: 'denied', element: 'deniedCard' },
  APPROVED: { title: 'Approved', name: 'approved', element: 'approvedCard' },
};

export const ELEMENTS = {
  readyToSubmitCard: {
    id: 'readyToSubmitCard',
  },
  needsAttentionCard: {
    id: 'needs-attention-card',
  },
  submittedCard: {
    id: 'submitted-card',
  },
  deniedCard: {
    id: 'denied-card',
  },
  approvedCard: {
    id: 'approved-card',
  },
};

class NebClaimsWorklistCards extends LitElement {
  static get properties() {
    return {
      cardValues: { type: Object },
      selectedCard: { type: String },
      disableCardClick: { type: Boolean },
      loading: { type: Boolean },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__textService = new TextService();

    this.cardValues = {};
    this.selectedCard = 'ready-to-submit';
    this.loading = false;

    this.onCardSelect = () => {};

    this.onAddPayment = () => {};

    this.onAddPatientPayment = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addPatientPayment: () => this.onAddPatientPayment(),
      addPayment: () => this.onAddPayment(CARDS.APPROVED.name),
      selectReady: () => this.onCardSelect(CARDS.READY.name),
      selectNeedsAttention: () => this.onCardSelect(CARDS.NEEDS_ATTENTION.name),
      selectSubmitted: () => this.onCardSelect(CARDS.SUBMITTED.name),
      selectApproved: () => this.onCardSelect(CARDS.APPROVED.name),
      selectDenied: () => this.onCardSelect(CARDS.DENIED.name),
      updateTextSize: () => this.__updateTextSize(),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__textService.connect();
    window.addEventListener('resize', this.__handlers.updateTextSize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__textService.disconnect();
    window.removeEventListener('resize', this.__handlers.updateTextSize);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          padding: ${CSS_SPACING};
          padding-top: 0;
          display: grid;
          grid-column-gap: ${CSS_SPACING};
          grid-template-columns: repeat(5, minmax(150px, auto));
          --card-text-size: 30px;
        }
        :host([layout='medium']) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: ${CSS_SPACING};
        }
        :host([layout='small']) {
          grid-template-columns: repeat(1, minmax(150px, auto));
          grid-gap: ${CSS_SPACING};
        }
        .card {
          --text-size: var(--card-text-size);
        }
      `,
    ];
  }

  __buildCardConfig() {
    return [
      {
        id: ELEMENTS.readyToSubmitCard.id,
        title: CARDS.READY.title,
        name: CARDS.READY.name,
        cardHandler: this.__handlers.selectReady,
        buildItems: ({ readyToSubmitCount, readyToSubmitAmount }) => [
          {
            value: readyToSubmitCount,
            description: 'Invoice/Drafts',
          },
          {
            value: readyToSubmitAmount,
            description: 'Total Charges',
          },
        ],
      },
      {
        id: ELEMENTS.needsAttentionCard.id,
        title: CARDS.NEEDS_ATTENTION.title,
        name: CARDS.NEEDS_ATTENTION.name,
        cardHandler: this.__handlers.selectNeedsAttention,
        buildItems: ({ needsAttentionCount, needsAttentionAmount }) => [
          {
            value: needsAttentionCount,
            description: 'Claims',
          },
          {
            value: needsAttentionAmount,
            description: 'Total Charges',
          },
        ],
      },
      {
        id: ELEMENTS.submittedCard.id,
        title: CARDS.SUBMITTED.title,
        name: CARDS.SUBMITTED.name,
        cardHandler: this.__handlers.selectSubmitted,
        buildItems: ({ submittedCount, submittedAmount }) => [
          {
            value: submittedCount,
            description: 'Claims',
          },
          {
            value: submittedAmount,
            description: 'Total Charges',
          },
        ],
      },
      {
        id: ELEMENTS.deniedCard.id,
        title: CARDS.DENIED.title,
        name: CARDS.DENIED.name,
        cardHandler: this.__handlers.selectDenied,
        buildItems: ({ deniedCount, deniedAmount }) => [
          {
            value: deniedCount,
            description: 'Claims',
          },
          {
            value: deniedAmount,
            description: 'Total Charges',
          },
        ],
      },
      {
        id: ELEMENTS.approvedCard.id,
        title: CARDS.APPROVED.title,
        buttonConfig:
          this.layout === 'large' || this.patient
            ? {
                label: !this.patient ? 'Add EOB' : 'Add New Payment',
                icon: 'plus',
              }
            : {},
        name: CARDS.APPROVED.name,
        cardHandler: this.__handlers.selectApproved,
        buttonHandler: this.__shouldAddPatientPayment()
          ? this.__handlers.addPatientPayment
          : this.__handlers.addPayment,
        buildItems: ({ approvedCount, approvedAmount }) => [
          {
            value: approvedCount,
            description: 'Claims',
          },
          {
            value: approvedAmount,
            description: 'Claims Balance',
          },
        ],
      },
    ];
  }

  __shouldAddPatientPayment() {
    return this.layout === 'small' && this.patient;
  }

  __updateTextSize() {
    if (this.__textService.isMounted()) {
      const items = Object.values(this.cardValues);
      updateCardTextSize(this, items);
    }
  }

  updated(changedProps) {
    if (changedProps.has('cardValues')) {
      setTimeout(() => this.__updateTextSize(), 20);
    }
  }

  render() {
    const cardConfig = this.__buildCardConfig();
    return html`
      ${cardConfig.map(
        card => html`
          <neb-card
            id="${card.id}"
            class="card"
            tabindex="0"
            title="${card.title}"
            ?selected="${this.selectedCard === card.name}"
            .items="${card.buildItems(this.cardValues)}"
            .onCardClick="${card.cardHandler}"
            .buttonConfig="${card.buttonConfig}"
            .onButtonClick="${card.buttonHandler}"
            .layout="${this.layout}"
            ?disableCardClick="${this.disableCardClick}"
            ?loading="${this.loading}"
          ></neb-card>
        `,
      )}
    `;
  }
}
customElements.define('neb-claims-worklist-cards', NebClaimsWorklistCards);
