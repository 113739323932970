import '../../../../../../src/components/tables/claims-worklist/neb-table-claims-worklist-ready-to-submit';
import '../../../../../../src/components/filters/neb-filters-claims-worklist-ready-to-submit';
import '../../neb-loading-overlay';
import '../../forms/claims-worklist/neb-form-claims-worklist-ready-to-submit';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import {
  CLAIM_DRAFT_REFRESH_SUCCESS_MESSAGE,
  CONFIRM_GENERATE_CLAIM_BATCH_IF_PENDING,
  DELETE_DRAFT_CLAIMS_SUCCESS,
  DELETE_DRAFT_CLAIMS_UNABLE,
  REFRESH_CLAIM_DRAFTS_ERROR,
  generateInternalErrorMessage,
  generateValidationErrorMessage,
  successfulGenerateBatch,
} from '../../../../../../src/utils/user-message';
import * as claimBatchApi from '../../../../../neb-api-client/src/claim-batches';
import * as claimsApi from '../../../../../neb-api-client/src/claims';
import {
  getLedgerInvoiceItems,
  getReadyToSubmit,
} from '../../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getPayerPlans } from '../../../../../neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openError,
  openInfo,
  openSuccess,
  openWarning,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  isClaimElectronic,
} from '../../../../../neb-utils/claims';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import Debouncer from '../../../../../neb-utils/debouncer';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../neb-utils/formatters';
import { fetchInsuranceItems } from '../../../../../neb-utils/neb-ledger-util';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../neb-utils/services/fetch';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { SORT_DIR } from '../../neb-table-header';
import {
  WORKLIST_ITEMS_POPUP_ACTIONS,
  openWorklistItemPopup,
  MAX_INVOICE,
  DESIGNATED_CLEARINGHOUSE,
  isElectronicRow,
  verifyClaimsWithPopups,
  CLAIMS_NO_ITEMS_INITIAL_LOAD,
} from '../utils';

import {
  createFilterQueries,
  getTotalFiltersApplied,
} from './claims-controller-utils';

export const ELEMENTS = {
  filters: { id: 'filters' },
  loadingOverlay: { id: 'loading-overlay' },
  pagination: { id: 'pagination' },
  readyForm: { id: 'ready-to-submit-form' },
};

const DEBOUNCE_DELAY = 600;
export const CLAIM_FLAG_DETAILS = {
  description: '',
  assignedTo: '',
};

class NebReadyToSubmitController extends LitElement {
  static get properties() {
    return {
      __model: Array,
      __providers: Array,
      __state: Object,
      __showHidden: Boolean,
      __loading: Boolean,
      __currentPage: Number,
      __excludedClaimOrInvoiceIds: Array,
      __patientCasesDict: Object,
      __claimMetaData: Object,
      __totalFiltersApplied: Number,
      __showExpandedFilters: Boolean,
      __hasCollapsedFilters: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      __hasAppliedFilters: Boolean,
      __payerPlans: Array,

      patientId: String,
      sortParams: Object,
      userLocations: Array,
      defaultLocationId: String,
      pageSize: Number,
      hasRcmClaimFlagFF: Boolean,
      hasRcmRelease2FF: Boolean,
      hasFitInvoiceOverlayPerformanceFF: Boolean,
      tableModel: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          position: relative;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) {
          height: auto;
        }

        neb-pagination {
          align-self: flex-end;
          margin: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__model = [];
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__providers = [];
    this.__showHidden = false;
    this.__loading = false;
    this.__state = FetchService.createModel();
    this.__currentPage = 0;
    this.__validClaimItems = [];
    this.__excludedClaimOrInvoiceIds = [];
    this.__claimMetaData = { selectAll: false };
    this.__patientCasesDict = {};
    this.__totalFiltersApplied = 0;
    this.__showExpandedFilters = false;
    this.__hasCollapsedFilters = true;
    this.__payerPlans = [];

    this.patientId = '';
    this.userLocations = [];
    this.defaultLocationId = '';
    this.pageSize = 100;
    this.sortParams = {
      key: 'invoiceNumber',
      dir: SORT_DIR.ASC,
    };

    this.hasRcmClaimFlagFF = false;
    this.hasRcmRelease2FF = false;
    this.hasFitInvoiceOverlayPerformanceFF = false;
    this.tableModel = [];

    this.onTotalChange = () => {};

    this.onSelect = () => {};

    this.onDirtyDialog = () => {};

    this.onUpdateDirty = () => {};

    this.onSave = () => {};

    this.onUpdateInitState = () => {};

    this.onUpdatePatientCasesDict = () => {};
  }

  get __checkedRowItems() {
    return this.__model.filter(row => {
      const tableModelItem = this.tableModel.find(item => item.id === row.id);
      const hasClaimFlag = tableModelItem && tableModelItem.claimFlag;

      return (
        (this.__claimMetaData.selectAll ||
          this.__claimMetaData[row.id]?.checked) &&
        !hasClaimFlag
      );
    });
  }

  __convertPageIndexToRealIndex(index) {
    return index + this.__currentPage * this.pageSize;
  }

  __isItemChecked(item) {
    return !!this.__claimMetaData[item.id]?.checked;
  }

  __noneChecked() {
    if (this.__checkedRowItems.length) {
      return false;
    }

    openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Bulk Actions',
      message:
        'Please select one or more invoices or claim drafts before performing an action.',
    });

    return true;
  }

  __getAuthorizationId({ claimAuthorizations, lineItem }) {
    if (claimAuthorizations?.length) {
      return claimAuthorizations[0].id;
    }

    if (lineItem?.patientAuthorizationId) {
      return lineItem.patientAuthorizationId;
    }

    return null;
  }

  __mapClaims({ claims, patients }) {
    return claims.data.map(({ patientCases = [], patientId, ...claim }) => {
      const id = claim.claimId || claim.invoiceId;

      if (claim.claimId) {
        delete this.__claimMetaData[claim.invoiceId];
      }

      if (!this.__claimMetaData[id]) {
        this.__claimMetaData[id] = {
          checked: false,
        };
      }

      this.__patientCasesDict[patientId] = [...patientCases];

      return {
        ...claim,
        patientId,
        patientAuthorizationId: this.__getAuthorizationId({
          claimAuthorizations: claim.claimAuthorizations,
          lineItem: claim.lineItems[0],
        }),
        patient: patients.find(p => p.id === patientId),
        id,
      };
    });
  }

  __initHandlers() {
    this.__handlers = {
      load: () => this.__load(),
      fetchData: async query => {
        if (
          this.__hasDisableInitialLoadFF &&
          !this.__hasAppliedFilters &&
          !this.patientId
        ) {
          return EMPTY_RESPONSE;
        }

        this.__loading = true;

        const claims = await getReadyToSubmit({
          ...query,
          patientId: query.patientId || this.patientId,
          locationIds: query.locationIds || this.__getUserLocationIds(),
        });
        const patientIds = claims.data.map(x => x.patientId);
        const patients = await patientApiClient.fetchSome(
          patientIds,
          {},
          false,
          true,
          true,
        );

        const mappedClaims = this.__mapClaims({ claims, patients });

        this.__loading = false;
        this.onUpdateDirty(false, true);
        return { ...claims, data: mappedClaims };
      },
      change: ({ name, value }) => {
        const [index] = name.split('.');
        const realIndex = this.__convertPageIndexToRealIndex(
          parseInt(index, 10),
        );

        const { id } = this.__model[realIndex];

        this.__claimMetaData[id] = {
          checked: value,
        };

        this.__claimMetaData = { ...this.__claimMetaData, selectAll: false };
      },
      changeState: state => {
        this.__state = state;
        this.__changeTotal(state);
      },
      confirmHide: async () => {
        if (this.__noneChecked()) return;

        await this.__openConfirmHidePopup();
      },
      confirmShow: async () => {
        if (this.__noneChecked()) return;

        await this.__openConfirmShowPopup();
        this.__deselectAll();
      },
      confirmDelete: async () => {
        if (this.__noneChecked()) return;

        const claimIds = this.__checkedRowItems
          .filter(item => item.claimId && item.status !== 'Submitting')
          .map(c => c.claimId);

        const popupResult = claimIds.length
          ? await this.__openConfirmDeletePopup()
          : await this.__openSelectDraftPopup();

        if (popupResult) {
          try {
            const count = await claimsApi.deleteClaims(claimIds);
            const fn =
              count === claimIds.length
                ? openSuccess(DELETE_DRAFT_CLAIMS_SUCCESS(count))
                : openInfo(DELETE_DRAFT_CLAIMS_UNABLE(claimIds.length, count));

            store.dispatch(fn);
            this.refresh();
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError('An error occurred when deleting the claim draft'),
            );
          }
        }

        this.__deselectAll();
      },
      clickLink: async (key, index) => {
        if (key === 'patient') {
          const { patientId } =
            this.__model[this.__convertPageIndexToRealIndex(index)];

          const route = `/patients/${patientId}/${'claims/worklist'}/ready-to-submit`;

          navigate(route);
        } else {
          await this.__openCellOverlay(
            key,
            this.__convertPageIndexToRealIndex(index),
          );
        }
      },
      deselectAll: () => this.__deselectAll(),
      selectAll: () => {
        this.__selectAllCheckboxes();
        this.__claimMetaData = { ...this.__claimMetaData, selectAll: true };
      },
      selectAllOnCurrentPage: () => {
        const { start, end } = this.__pageBoundaries;
        const page = this.__model.slice(start, end);

        const { selectAll } = this.__claimMetaData;
        const isAllSelected = page
          .map(
            item =>
              this.__isItemChecked(item) || this.__claimFlagIsMarked(item.id),
          )
          .every(Boolean);

        if (selectAll || isAllSelected) {
          this.__deselectAll();
        } else {
          page.forEach(item => {
            if (!this.hasRcmClaimFlagFF || !this.__claimFlagIsMarked(item.id)) {
              this.__setCheckbox(item, true);
            }
          });
        }

        this.__claimMetaData = { ...this.__claimMetaData, selectAll: false };
      },
      openClaimBatchesOverlay: async () => {
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          patientId: this.patientId,
          onSelect: this.onSelect,
        });

        this.refresh();
        this.__deselectAll();
      },
      saveAndSubmit: async () => {
        if (this.__noneChecked()) return;

        const { excessChargesItems, designatedClearinghouseCountItems } =
          this.__checkedRowItems.reduce(
            (acc, item) => {
              if (item.lineItemCount > MAX_INVOICE.MAX_CHARGES) {
                acc.excessChargesItems.push(item.invoiceNumber);
              }

              if (
                item.designatedClearinghouseCount !==
                  DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT &&
                isElectronicRow(item)
              ) {
                acc.designatedClearinghouseCountItems.push(item.invoiceNumber);
              }

              return acc;
            },

            {
              excessChargesItems: [],
              designatedClearinghouseCountItems: [],
            },
          );

        const greenLight = await verifyClaimsWithPopups(
          excessChargesItems,
          designatedClearinghouseCountItems,
          MAX_INVOICE,
          DESIGNATED_CLEARINGHOUSE,
        );

        if (greenLight) {
          const goodRowItems = this.__checkedRowItems.filter(
            r =>
              (r.lineItemCount <= MAX_INVOICE.MAX_CHARGES &&
                r.designatedClearinghouseCount ===
                  DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT &&
                r.status !== 'Submitting') ||
              !isElectronicRow(r),
          );

          if (goodRowItems.length) {
            this.__validClaimItems = goodRowItems;
            this.__generateBatchDebounce.debounce();
          }
        }
      },
      saveClaimsData: () => {
        this.onSave({ filterIsApplied: false });
      },
      refreshDrafts: async () => {
        const draftClaimIds = this.__checkedRowItems.reduce((acc, item) => {
          if (item.status === CLAIM_STATUS.DRAFT) {
            acc.push(item.claimId);
          }

          return acc;
        }, []);

        if (!draftClaimIds.length) {
          store.dispatch(openInfo(CLAIM_DRAFT_REFRESH_SUCCESS_MESSAGE(0)));

          return;
        }

        try {
          const response = await claimsApi.refreshClaims(draftClaimIds, 2);

          store.dispatch(
            openSuccess(CLAIM_DRAFT_REFRESH_SUCCESS_MESSAGE(response.count)),
          );
        } catch (err) {
          console.error(err);
          store.dispatch(openError(REFRESH_CLAIM_DRAFTS_ERROR));
        }
      },
      scrubClaim: async () => {
        try {
          const scrubbingClaims = this.__checkedRowItems.map(row => ({
            ...(row.claimId && { claimId: row.claimId }),
            invoiceId: row.invoiceId,
            isElectronic: isClaimElectronic(row),
            insuranceId: row.primaryInsuranceId,
          }));

          const result = await claimsApi.scrubClaims({
            claims: scrubbingClaims,
          });

          const { successCount, failedCount, errorCount, claimIds } = result;

          let bannerMessage = `${
            claimIds.length - errorCount
          } claim draft(s) scrubbed`;

          if (successCount > 0) {
            bannerMessage += `, ${successCount} passed claim scrubbing`;
          }

          if (failedCount > 0) {
            bannerMessage += `, ${failedCount} failed and were moved to Needs Attention.`;

            store.dispatch(openWarning(bannerMessage));

            return;
          }

          store.dispatch(openSuccess(bannerMessage));
        } catch (err) {
          console.error(err);
          store.dispatch(
            openError('An error occurred when scrubbing selected Claims.'),
          );
        }
      },
      changeSort: (_name, result) => {
        this.sortParams = result[0];
        this.__fetchService.setQuery('sortKey', result[0].key);
        this.__fetchService.setQuery('sortDir', result[0].dir);
      },
      applyFilters: (model, isButtonPress = false) => {
        this.onDirtyDialog({
          onSave: async () => {
            await this.onSave({ filterIsApplied: isButtonPress });
            return this.__applyFilters(model, isButtonPress);
          },
          onDiscard: () => this.__applyFilters(model, isButtonPress),
        });
      },
      pageChanged: index => {
        this.__currentPage = index;
      },
      updatePatientCase: (patientId, patientCase) => {
        const patientCases = [...this.__patientCasesDict[patientId]];
        const index = patientCases.findIndex(p => p.id === patientCase.id);

        if (index >= 0) {
          patientCases[index] = patientCase;
        }

        this.__patientCasesDict[patientId] = patientCases;
      },
      addPatientCase: (patientId, patientCase) => {
        this.__patientCasesDict[patientId].push(patientCase);
      },
      dirtyDialog: handlers => this.onDirtyDialog(handlers),
      dirtyUpdated: (dirty, valid, model) =>
        this.onUpdateDirty(dirty, valid, model),
      claimFlagChange: (updatedTableModel, id) => {
        this.tableModel = updatedTableModel;

        this.__claimMetaData[id] = {
          checked: false,
        };

        this.__claimMetaData = { ...this.__claimMetaData, selectAll: false };
      },
      resetTableModel: updatedTableModel => {
        this.tableModel = updatedTableModel;
      },
    };
  }

  __claimFlagIsMarked(id) {
    const index = this.tableModel.findIndex(item => item.id === id);
    return this.tableModel[index]?.claimFlag;
  }

  __deselectRemovedItems(previousPageItems) {
    const selectedRows = previousPageItems.reduce((acc, key) => {
      if (
        this.__pageItems.some(
          pageItem =>
            pageItem.invoiceId === key.invoiceId &&
            pageItem.claimId === key.claimId,
        )
      ) {
        acc[key.claimId || key.invoiceId] = {
          checked: this.__claimMetaData[key.claimId || key.invoiceId].checked,
        };
      }

      return acc;
    }, {});

    this.__claimMetaData = {
      ...selectedRows,
      selectAll: this.__claimMetaData.selectAll,
    };
  }

  __deselectAll() {
    this.__model.forEach(item => {
      this.__setCheckbox(item, false);
    });

    this.__claimMetaData = { ...this.__claimMetaData, selectAll: false };
  }

  __initServices() {
    if (this.__fetchService) {
      this.__fetchService.cancel();
    }

    this.__fetchService = new FetchService(
      {
        onChange: this.__handlers.changeState,
        onError: error => {
          this.__loading = false;
          console.error(error);
        },
      },
      this.__handlers.fetchData,
      { hideInactive: false },
    );

    this.__generateBatchDebounce = new Debouncer(
      () => this.__generateBatch(this.__validClaimItems),
      DEBOUNCE_DELAY,
    );
  }

  __getEmptyMessage() {
    return this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
      ? CLAIMS_NO_ITEMS_INITIAL_LOAD
      : this.emptyMessage;
  }

  __applyFilters(model, isButtonPress) {
    if (isButtonPress) {
      this.__hasAppliedFilters = true;
    }

    const filterQueries = createFilterQueries(
      model,
      this.__getUserLocationIds(),
    );
    this.__totalFiltersApplied = getTotalFiltersApplied(model);

    Object.entries(filterQueries).forEach(([k, v]) => {
      this.__fetchService.setQuery(k, v);
    });

    this.refresh();
    this.__currentPage = 0;
    this.__deselectAll();
  }

  async __load() {
    this.__pageItems = [...this.__pageItems];

    const [providers, payerPlans] = await Promise.all([
      getProviderUsers(true),
      getPayerPlans({ hideInactive: true }, null, true),
      this.__fetchService.fetch(),
    ]);

    this.__providers = providers;
    this.__payerPlans = payerPlans.payerPlan.map(p => ({
      label: p.alias,
      data: p,
    }));
  }

  async __openConfirmHidePopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.HIDE,
      this.__checkedRowItems.map(({ id, ...item }) => item),
    );

    if (result) {
      this.refresh();
      this.__deselectAll();
    }
  }

  async __openConfirmShowPopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.SHOW,
      this.__checkedRowItems.map(({ id, ...item }) => item),
    );

    if (result) {
      this.refresh();
      this.__deselectAll();
    }
  }

  async __openConfirmDeletePopup() {
    const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Delete Claim Drafts',
      message: 'Are you sure you want to delete the selected claim drafts?',
      confirmText: 'Yes',
      cancelText: 'No',
    });

    return response;
  }

  async __openSelectDraftPopup() {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Select Claim Drafts',
      message:
        'Please select one or more claim drafts before performing an action.',
    });
  }

  __getUserLocationIds() {
    return this.userLocations
      ? this.userLocations.map(({ data }) => data.id)
      : [];
  }

  __isItemExcludedFromSelectAlls(item) {
    return (
      item.lineItemCount > MAX_INVOICE.MAX_CHARGES ||
      (item.designatedClearinghouseCount !==
        DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT &&
        isElectronicRow(item)) ||
      item.status === CLAIM_STATUS.SUBMITTING
    );
  }

  __mapModel() {
    const excludedIds = [];

    const newModel = this.__state.pageItems.map(item => {
      const provider = this.__providers.find(p => p.id === item.providerId);

      if (this.__isItemExcludedFromSelectAlls(item)) {
        if (item.claimId && !excludedIds.includes(item.claimId)) {
          excludedIds.push(item.claimId);
        } else if (!item.claimId && !excludedIds.includes(item.invoiceId)) {
          excludedIds.push(item.invoiceId);
        }
      }

      return {
        ...item,
        provider,
        lineItems: item.lineItems.map(({ diagnosesPointers, ...li }) => ({
          ...li,
          diagnosesPointers: diagnosesPointers || [],
        })),
        dateOfCurrentIllness: item.dateOfCurrentIllness
          ? parseDate(item.dateOfCurrentIllness).startOf('day').toISOString()
          : null,
        claimFlag: false,
        claimFlagDetails: CLAIM_FLAG_DETAILS,
      };
    });

    this.__excludedClaimOrInvoiceIds = excludedIds;
    return newModel;
  }

  // eslint-disable-next-line complexity
  async __openCellOverlay(key, index) {
    const row = this.__model[index];
    const currentItems = this.__pageItems;

    switch (key) {
      case 'primaryPayerAlias':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: row.primaryPayerId,
        });

        break;

      case 'primaryPlanName': {
        const insurances =
          key === 'primaryPlanName'
            ? await fetchInsuranceItems(row.patient.id)
            : [];

        const selectedInsurance = insurances.find(
          insurance => insurance.data.id === row.primaryInsuranceId,
        ).data;

        await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, {
          patientId: row.patient.id,
          patientInsurances: insurances,
          patientInsurance: selectedInsurance,
        });

        break;
      }

      case 'claimNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
          claimId: row.claimId,
        });

        break;

      case 'invoiceNumber': {
        const overlayKey = this.hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient: {
            id: row.patient.id,
            name: objToName(row.patient.name, DEFAULT_NAME_OPTS),
            mrn: row.patient.medicalRecordNumber,
          },
          lineItemIds: (
            await getLedgerInvoiceItems(row.invoiceId, true)
          ).data.map(li => li.id),
          selectedIds: [],
        });

        break;
      }

      case 'claimBillingNote':
        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CLAIM,
          parentId: row.claimId,
          parentData: {
            datesOfService: row.dateOfService,
            claimNumber: row.claimNumber,
            amount: row.amount,
            payer: `${row.primaryPayerAlias}, ${row.primaryPlanName}`,
          },
          patientId: row.patientId,
        });

        break;

      case 'invoiceBillingNote':
        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.INVOICE,
          parentId: row.invoiceId,
          parentData: {
            datesOfService: row.dateOfService,
            invoice: row.invoiceNumber,
          },
          patientId: row.patientId,
        });

        break;

      default:
        break;
    }

    await this.refresh();

    this.__deselectRemovedItems(currentItems);
  }

  __calculateAmount(items) {
    const claimDraft = items.find(i => i.claimId);

    return claimDraft
      ? items[0].amount
      : items.reduce((total, { amount }) => total + amount, 0);
  }

  __setCheckbox(item, checked) {
    this.__claimMetaData[item.id] = { checked };
  }

  __selectAllCheckboxes() {
    this.__model.forEach(item => {
      if (!this.hasRcmClaimFlagFF || !this.__claimFlagIsMarked(item.id)) {
        this.__setCheckbox(item, true);
      }
    });

    this.__claimMetaData = { ...this.__claimMetaData };
  }

  async __proceedIfPendingBatches() {
    const pendingBatches = await claimBatchApi.getPendingBatches();

    if (pendingBatches.length > 0) {
      const confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: 'Pending Batch',
        message: CONFIRM_GENERATE_CLAIM_BATCH_IF_PENDING(),
        confirmText: 'Yes',
        cancelText: 'No',
      });

      return confirmed;
    }

    return true;
  }

  async __generateBatch(rowItems) {
    try {
      const proceedIfPendingBatches = await this.__proceedIfPendingBatches();

      if (!proceedIfPendingBatches) {
        return;
      }

      const { data: claimBatchResponse } = await claimBatchApi.add(rowItems);

      if (claimBatchResponse[0].internalErrorCount) {
        store.dispatch(
          openWarning(
            generateInternalErrorMessage(
              claimBatchResponse[0].internalErrorCount,
            ),
          ),
        );
      }

      if (
        !claimBatchResponse[0].failedClaimsCount &&
        !claimBatchResponse[0].internalErrorCount
      ) {
        store.dispatch(openSuccess(`${successfulGenerateBatch}`));

        await this.refresh();
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          patientId: this.patientId,
          onSelect: this.onSelect,
        });

        this.__deselectAll();
        await this.refresh();
        return;
      }

      store.dispatch(
        openWarning(
          generateValidationErrorMessage(
            claimBatchResponse[0].failedClaimsCount,
          ),
        ),
      );

      await this.refresh();

      if (claimBatchResponse[0].successfulClaimsCount) {
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          patientId: this.patientId,
          onSelect: this.onSelect,
        });

        await this.refresh();
      }
    } catch (e) {
      console.error(e);
      store.dispatch(
        openError('An error occurred when generating Claim Batch.'),
      );
    }
  }

  async refresh() {
    if (this.userLocations && this.userLocations.length) {
      await this.__load();
    }
  }

  __changeTotal(state) {
    this.onTotalChange({
      readyToSubmitCount: state.pageItems.length,
      readyToSubmitAmount: state.pageItems.reduce((accum, item) => {
        accum += item.amount;
        return accum;
      }, 0),
    });
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    super.connectedCallback();
  }

  firstUpdated() {
    this.refresh();
  }

  __hasChangedProps(changedProps) {
    return !!(
      changedProps.has('userLocations') ||
      changedProps.has('__providers') ||
      (changedProps.has('__state') &&
        this.userLocations.length &&
        this.__providers.length)
    );
  }

  __shouldUpdatePageItems(changedProps) {
    const props = ['__currentPage', 'pageSize', '__model'];

    return props.some(p => changedProps.has(p));
  }

  update(changedProps) {
    if (this.__hasChangedProps(changedProps)) {
      this.__model = this.__mapModel();
    }

    if (this.__shouldUpdatePageItems(changedProps)) {
      const start = this.__currentPage * this.pageSize;
      const end = start + this.pageSize;
      this.__pageBoundaries = { start, end };
      this.__pageItems = this.__model.slice(
        this.__pageBoundaries.start,
        this.__pageBoundaries.end,
      );

      this.onUpdateInitState(this.__pageItems);
    }

    if (changedProps.has('__patientCasesDict')) {
      this.onUpdatePatientCasesDict(this.__patientCasesDict);
    }

    if (changedProps.has('patientId') && this.userLocations?.length) {
      this.refresh();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('userLocations') && this.userLocations?.length) {
      const locationIds = this.defaultLocationId
        ? [this.defaultLocationId]
        : this.__getUserLocationIds();
      this.__fetchService.setQuery('locationIds', locationIds);
      this.refresh();
    }

    super.updated(changedProps);
  }

  renderFilters() {
    const totalFiltersApplied = this.__totalFiltersApplied
      ? this.__totalFiltersApplied
      : 0;

    return html`
      <neb-filters-claims-worklist-ready-to-submit
        id="${ELEMENTS.filters.id}"
        .providers="${this.__providers}"
        .defaultLocationId="${this.defaultLocationId}"
        .userLocations="${this.userLocations}"
        .showHidden="${this.__showHidden}"
        .onApply="${this.__handlers.applyFilters}"
        .enablePatient="${!this.patientId}"
        .expanded="${this.__showExpandedFilters}"
        .totalFiltersApplied="${totalFiltersApplied}"
        .hasCollapsedFilters="${this.__hasCollapsedFilters}"
        .payerPlans="${this.__payerPlans}"
      ></neb-filters-claims-worklist-ready-to-submit>
    `;
  }

  get __pageCount() {
    return Math.ceil(this.__model.length / this.pageSize);
  }

  __renderForm() {
    return html`
      <neb-form-claims-worklist-ready-to-submit
        id="${ELEMENTS.readyForm.id}"
        .emptyMessage="${this.__getEmptyMessage()}"
        .model="${this.__pageItems}"
        .totalItemCount="${this.__model.length}"
        .providers="${this.__providers}"
        .sortParams="${this.sortParams}"
        .hasRcmClaimFlagFF="${this.hasRcmClaimFlagFF}"
        .hasRcmRelease2FF="${this.hasRcmRelease2FF}"
        .excludedClaimOrInvoiceIds="${this.__excludedClaimOrInvoiceIds}"
        .onChange="${this.__handlers.change}"
        .onSort="${this.__handlers.changeSort}"
        .onSelectAllOnCurrentPage="${this.__handlers.selectAllOnCurrentPage}"
        .onSelectAll="${this.__handlers.selectAll}"
        .onConfirmHide="${this.__handlers.confirmHide}"
        .onConfirmShow="${this.__handlers.confirmShow}"
        .onConfirmDelete="${this.__handlers.confirmDelete}"
        .onScrubClaim="${this.__handlers.scrubClaim}"
        .onDeselectAll="${this.__handlers.deselectAll}"
        .onClickLink="${this.__handlers.clickLink}"
        .onOpenClaimBatchesOverlay="${this.__handlers.openClaimBatchesOverlay}"
        .onSaveAndSubmit="${this.__handlers.saveAndSubmit}"
        .onSave="${this.__handlers.saveClaimsData}"
        .onSelect="${this.onSelect}"
        .onExpandAll="${this.__handlers.expandAll}"
        .claimMetaData="${this.__claimMetaData}"
        .patientCasesDict="${this.__patientCasesDict}"
        .currentPage="${this.__currentPage}"
        .pageCount="${this.__pageCount}"
        .onPageChanged="${this.__handlers.pageChanged}"
        .onUpdatePatientCase="${this.__handlers.updatePatientCase}"
        .onAddPatientCase="${this.__handlers.addPatientCase}"
        .onLoad="${this.__handlers.load}"
        .enablePatients="${!this.patientId}"
        .onDirtyDialog="${this.__handlers.dirtyDialog}"
        .onUpdateDirty="${this.__handlers.dirtyUpdated}"
        .onRefreshDrafts="${this.__handlers.refreshDrafts}"
        .onClaimFlagChange="${this.__handlers.claimFlagChange}"
        .onResetTableModel="${this.__handlers.resetTableModel}"
      ></neb-form-claims-worklist-ready-to-submit>
    `;
  }

  render() {
    return html`
      ${this.renderFilters()} ${this.__renderForm()}

      <neb-loading-overlay
        id="${ELEMENTS.loadingOverlay.id}"
        .show="${this.__loading}"
      ></neb-loading-overlay>
    `;
  }
}

customElements.define(
  'neb-ready-to-submit-controller',
  NebReadyToSubmitController,
);
