import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { openDoctibleSsoLink } from '../../../../../src/utils/sso';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_PRIMARY_1,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_BLACK,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  menuItem: {
    id: 'menu-item',
  },
  leadingIcon: {
    id: 'leading-icon',
  },
  trailingIcon: {
    id: 'trailing-icon',
  },
  chevron: {
    id: 'chevron',
  },
  subTrailingIcon: {
    selector: '[id^="sub-trailing-icon-"]',
  },
  subItems: {
    selector: '[id^="sub-item-"]',
  },
};
const CSS_HEIGHT_SUB = 44;

class ExpansionPanel extends LitElement {
  static get properties() {
    return {
      item: Object,
      selectedId: String,
      expanded: { type: Boolean, reflect: true },
      visible: { type: Boolean, reflect: true },
      __lastMenuItem: { type: String },
      __increasePaddingBottom: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.item = {};
    this.expanded = false;
    this.selectedId = '';
    this.index = null;
    this.visible = false;
    this.__lastMenuItem = null;
    this.__increasePaddingBottom = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickItem: async e => {
        if (e.currentTarget.label === 'CT Engage') {
          await openDoctibleSsoLink();
        } else {
          const index = this.expanded ? -1 : this.index;
          this.onChange(this.selectedId, index);
        }
      },
      clickSubItem: e => {
        const id = e.currentTarget.id.replace('sub-item-', '');

        this.onChange(id, this.index);
        e.stopPropagation();
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('expanded')) {
      const lastItem = this.item.subItems[this.item.subItems.length - 1];
      this.__lastMenuItem = this.shadowRoot.getElementById(
        `sub-item-${lastItem.id}`,
      );

      if (this.__lastMenuItem) {
        const lastMenuItemSpacer = this.__lastMenuItem.querySelector('.spacer');

        if (lastMenuItemSpacer.offsetHeight > 20) {
          this.__increasePaddingBottom = true;
        }
      }
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([visible]) .sub-item-row {
          display: grid;
          grid-template-columns: 125px auto;
          place-items: start end;
          width: 100%;
          padding-right: 22px;
        }

        .sub-item-row {
          display: grid;
          grid-template-columns: 211px auto;
          align-items: center;
        }

        .label {
          width: 100px;
        }

        .row {
          display: flex;
          align-items: center;
        }

        :host([__increasePaddingBottom]) .row-sub:last-child {
          margin-bottom: ${CSS_SPACING};
        }

        .row-primary {
          height: 56px;
        }

        .row-sub {
          height: ${CSS_HEIGHT_SUB}px;
          color: ${CSS_COLOR_WHITE};
          font-weight: 400;
          background-color: rgba(255, 255, 255, 0);
        }

        .row-sub:hover {
          background-color: rgba(64, 192, 255, 0.2);
        }

        .row-sub[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .container {
          display: flex;
          cursor: pointer;
          background-color: ${CSS_PRIMARY_1};
          color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_BODY};
          flex-flow: column nowrap;
          transition:
            background-color 200ms,
            color 200ms;
        }

        :host([expanded]) .container {
          background-color: ${CSS_COLOR_BLACK};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .bar {
          width: 4px;
          margin-right: 12px;
          height: 100%;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          opacity: 0;
        }

        :host([__increasePaddingBottom]) .row-sub:last-child .bar {
          height: calc(100% + 40px);
        }

        :host([expanded]) .bar {
          opacity: 1;
        }

        .icon-leading {
          height: 30px;
          width: 30px;
          margin-left: 4px;
          margin-right: 12px;
        }

        .icon-trailing {
          width: 13px;
          height: 13px;
        }

        .icon {
          fill: ${CSS_COLOR_WHITE};
        }

        .chevron {
          width: 16px;
          height: 16px;
          margin-right: ${CSS_SPACING};
          transform: rotate(0);
          transition: transform 200ms;
        }

        :host([expanded]) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([expanded]) .chevron {
          transform: rotate(180deg);
        }

        .spacer {
          padding-right: 5px;
        }

        .indent {
          margin-left: ${CSS_SPACING};
        }

        .content {
          display: flex;
          flex: 1 0 0px;
          align-items: center;
          height: 100%;
        }

        :host([visible]) .container-icon {
          display: flex;
          align-items: center;
          padding-top: 3px;
        }

        .container-icon {
          display: flex;
          align-items: center;
          padding: 3px 0px 0px 4px;
        }
      `,
    ];
  }

  __renderSubTrailingIcon(icon) {
    return icon
      ? html`
          <div class="container-icon">
            <neb-icon
              id="sub-trailing-icon-${this.index}"
              class="icon icon-trailing"
              icon="neb:${icon}"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderSubItems() {
    return this.expanded
      ? this.item.subItems.map(
          item => html`
            <div
              id="sub-item-${item.id}"
              class="row row-sub"
              @click="${this.__handlers.clickSubItem}"
              ?selected="${item.id === this.selectedId}"
            >
              <span class="bar"></span>
              <div class="sub-item-row">
                <span class="spacer ${this.visible ? 'label' : 'indent'}"
                  >${item.label || ''}</span
                >

                ${this.__renderSubTrailingIcon(item.trailingIcon)}
              </div>
            </div>
          `,
        )
      : '';
  }

  __renderTrailingIcon() {
    return this.item.trailingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.trailingIcon.id}"
            class="icon icon-trailing"
            icon="neb:${this.item.trailingIcon}"
          ></neb-icon>
        `
      : '';
  }

  __renderLeadingIcon() {
    return this.item.leadingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.leadingIcon.id}"
            class="icon icon-leading"
            icon="neb:${this.item.leadingIcon}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="row row-primary">
          <div
            id="${ELEMENTS.menuItem.id}"
            class="content"
            .label="${this.item.label}"
            @click="${this.__handlers.clickItem}"
          >
            <span class="bar"></span>

            ${this.__renderLeadingIcon()}

            <span class="spacer">${this.item.label}</span>

            ${this.__renderTrailingIcon()}
          </div>

          ${this.item.subItems.length
            ? html`
                <neb-icon
                  id="${ELEMENTS.chevron.id}"
                  class="icon chevron"
                  icon="neb:chevron"
                  @click="${this.__handlers.clickItem}"
                ></neb-icon>
              `
            : ''}
        </div>

        ${this.__renderSubItems()}
      </div>
    `;
  }
}

window.customElements.define('neb-expansion-panel', ExpansionPanel);
