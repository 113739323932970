import '../../../../../src/components/misc/neb-icon';
import '../neb-radio-button';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

import { renderBodyButtonStyles } from './utils/body-styles';
import { SVG } from './utils/body-svgs';

export const ELEMENTS = {
  options: {
    selector: 'neb-radio-button',
  },
  clearAllButton: {
    id: 'clear-all',
  },
};

export const REGION_LENGTH = 83;
const BACK_START_INDEX = 38;

class NebMacroRegion extends LitElement {
  static get properties() {
    return {
      selectedIndices: Array,
      showClearButton: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      renderBodyButtonStyles(),
      css`
        .body-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-shrink: 0; /* Needed for safari */
          margin: 5px 0 9px;
        }

        .body-svgs {
          position: relative;
          padding: 0 ${CSS_SPACING};
          flex: 5 0 0;
          justify-content: space-around;
          display: flex;
        }

        .body-svg-container {
          position: relative;
        }

        .clear-all {
          margin-bottom: 10px;
          text-decoration: underline;
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .option {
          position: absolute;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedIndices = [];
    this.showClearButton = true;

    this.onClick = () => {};

    this.onClear = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: index => this.onClick(index),
      clear: () => this.onClear(),
    };
  }

  __renderFrontBodyButtons() {
    return Array(BACK_START_INDEX)
      .fill('')
      .map(
        (_, index) => html`
          <neb-radio-button
            class="option option-${index}"
            .value="${index}"
            .checked="${this.selectedIndices.includes(index)}"
            .onSelect="${this.__handlers.click}"
            ?noInk="${true}"
          >
          </neb-radio-button>
        `,
      );
  }

  __renderBackBodyButtons() {
    return Array(REGION_LENGTH - BACK_START_INDEX)
      .fill('')
      .map(
        (_, index) => html`
          <neb-radio-button
            class="option option-${index + BACK_START_INDEX}"
            .value="${index + BACK_START_INDEX}"
            .checked="${this.selectedIndices.includes(
              index + BACK_START_INDEX,
            )}"
            .onSelect="${this.__handlers.click}"
            ?noInk="${true}"
          >
          </neb-radio-button>
        `,
      );
  }

  render() {
    return html`
      <div class="body-container">
        <div class="body-svgs">
          <div class="body-svg-container">
            ${SVG.BODY_FRONT} ${this.__renderFrontBodyButtons()}
          </div>
          <div class="body-svg-container">
            ${SVG.BODY_BACK} ${this.__renderBackBodyButtons()}
          </div>
          ${this.showClearButton
            ? html`
                <span
                  id="${ELEMENTS.clearAllButton.id}"
                  class="clear-all"
                  @click="${this.__handlers.clear}"
                  >Clear All
                </span>
              `
            : ''}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-macro-region', NebMacroRegion);
