/* eslint-disable no-await-in-loop */
import { PDFDocument, PageSizes } from 'pdf-lib';

const getImageResizeData = (image, pageInfo) => {
  const scaleFactor = Math.min(
    pageInfo.width / image.width,
    pageInfo.height / image.height,
  );

  const newWidth = image.width * scaleFactor;
  const newHeight = image.height * scaleFactor;

  const x = pageInfo.width / 2 - newWidth / 2;
  const y = pageInfo.height / 2 - newHeight / 2;

  return { x, y, width: newWidth, height: newHeight };
};

export const buildPdf = async (base64Images, pageSize = PageSizes.Letter) => {
  const pdfDoc = await PDFDocument.create();

  for (let i = 0; i < base64Images.length; i += 1) {
    const image = await pdfDoc.embedJpg(base64Images[i]);

    const newPage = pdfDoc.addPage(pageSize);
    const pageInfo = {
      width: newPage.getWidth(),
      height: newPage.getHeight(),
    };

    const imageDrawData = getImageResizeData(image, pageInfo);

    newPage.drawImage(image, imageDrawData);
  }
  return pdfDoc.saveAsBase64({ dataUri: true });
};
