import '../../../src/components/forms/patients/profile/neb-patient-online-booking-link-form';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { deleteAppointment } from '../../neb-api-client/src/appointment-api-client';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { store } from '../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
  helpText: {
    id: 'help-text',
  },
  backButton: {
    id: 'button-back',
  },
  createNewPatientButton: {
    id: 'button-create-new-patient',
  },
  deleteAppointmentButton: {
    id: 'button-delete-appointment',
  },
};
export const HEADER_ACCOUNT = 'Online Booking Account Details';
const HEADER_MATCH = 'Potential Matching Patient Records';
export const HEADER_REVIEW_MATCH = 'Selected Patient Record';
const HELP_TEXT =
  'This appointment was booked from an online booking account that has not been matched to a corresponding patient record in your clinic. Please click "Match" to continue with the matching process for the correct patient record above. If none of the above accounts are correct, click the "Create Patient Record" button to create a new patient record for this online booking account.';

export const DELETE_UNMATCHED = {
  TITLE: 'Delete Unmatched Appointment',
  MESSAGE:
    'Deleting this appointment will permanently remove this appointment from the application. You will not be able to view appointment details after deleting this appointment. Are you sure you want to continue?',
};

const DELETE_MESSAGE_BANNER = 'Appointment Deleted.';

class NebPopupMatchPatientAccount extends NebPopup {
  static get properties() {
    return {
      noMatches: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () =>
      this.onClose({
        back: true,
      });

    this.handlers.createNewPatient = () => {
      this.onClose({
        createNewPatient: true,
      });
    };

    this.handlers.match = async match => {
      const model = {
        title: 'Review Selection',
        account: this.model.account,
        match,
        headerAccount: HEADER_ACCOUNT,
        headerReviewMatch: HEADER_REVIEW_MATCH,
        isOnlineAccountMatch: false,
      };
      const result =
        (await openPopup(POPUP_RENDER_KEYS.REVIEW_MATCH_SELECTION, model)) ||
        {};

      if (!result.back) {
        this.onClose(result);
      }
    };

    this.handlers.delete = async () => {
      const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: DELETE_UNMATCHED.TITLE,
        message: DELETE_UNMATCHED.MESSAGE,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      });

      if (result) {
        try {
          await deleteAppointment(this.model.appointmentId);
          store.dispatch(openSuccess(DELETE_MESSAGE_BANNER));
          this.onClose({ back: true, deleted: true });
        } catch (e) {
          store.dispatch(openError('Unable to delete unmatched appointment.'));
        }
      }
    };
  }

  initState() {
    super.initState();
    this.noMatches = false;
    this.dynamic = true;
  }

  modelChanged() {
    this.title = this.model.title;
    this.noMatches = this.model.matches.length === 0;
  }

  firstUpdated() {
    this.__elements = {
      form: this.shadowRoot.getElementById(ELEMENTS.form.id),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 996px;
          min-height: 600px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) {
          min-height: unset;
        }

        :host([layout='small']) .content {
          margin: 0;
          flex: 1;
        }

        :host([layout='small']) .container-help {
          padding: 0 19px;
          flex: 1;
        }

        :host([layout='small']) .content-container {
          flex: 0;
        }

        :host([layout='small'][dynamic]) .container {
          height: 100%;
        }

        :host([noMatches][dynamic][layout='small']) .footer,
        :host([layout='small']) .footer {
          display: flex;
          padding: ${CSS_SPACING} 2px 17px 2px;
          border-top: 1px ${CSS_COLOR_HIGHLIGHT} solid;
        }

        :host([layout='small']) .new-patient,
        :host([layout='small']) .delete-appointment {
          min-width: unset;
          margin: 0 10px;
          padding: 0;
        }

        :host([layout='small']) .button-container {
          gap: 20px;
        }

        :host([noMatches][dynamic]) .container,
        :host([noMatches][dynamic]) .content {
          flex: 1 0 0;
        }

        :host([noMatches][dynamic]) .content-container {
          flex: 1 0 0;
          justify-content: space-between;
        }

        :host([noMatches][dynamic]) .footer {
          justify-content: unset;
          padding: 17px 17px 22px;
        }

        .header {
          margin: 22px 23px 0 17px;
        }

        .container {
          padding: 0;
        }

        .content-container {
          flex: 1 0 0;
          padding: unset;
          padding-bottom: ${CSS_SPACING};
        }

        .content {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }

        .form {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }
        .container-help {
          display: flex;
          padding: 0 50px 0 27px;
        }

        .text-help {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          flex: 0 0 20px;
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .back {
          min-width: 105px;
        }

        .new-patient,
        .delete-appointment {
          min-width: 300px;
          margin-right: 10px;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          white-space: nowrap;
        }

        .footer {
          padding: 7px 17px;
          display: flex;
        }

        .button-container {
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  __renderHelpText() {
    return html`
      <div class="container-help">
        <neb-icon class="icon" icon="neb:info"></neb-icon>
        <div id="${ELEMENTS.helpText.id}" class="text-help">${HELP_TEXT}</div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="content-container">
          <neb-patient-online-booking-link-form
            id="${ELEMENTS.form.id}"
            class="form"
            .model="${this.model}"
            .onClose="${this.handlers.cancel}"
            .onMatch="${this.handlers.match}"
            .layout="${this.layout}"
            .accountTitle="${HEADER_ACCOUNT}"
            .matchTitle="${HEADER_MATCH}"
          ></neb-patient-online-booking-link-form>
        </div>
        ${
          this.layout === 'small' && this.model.matches.length
            ? this.__renderHelpText()
            : ''
        }
      </div>
      <div class="footer">
        <div class="button-container">
          <neb-button
          id="${ELEMENTS.createNewPatientButton.id}"
          class="button new-patient"
          role="${BUTTON_ROLE.CONFIRM}"
          label="Create Patient Record"
          .onClick="${this.handlers.createNewPatient}"
          ></neb-button>
          <neb-button
          id="${ELEMENTS.deleteAppointmentButton.id}"
          class="button delete-appointment"
          role="${BUTTON_ROLE.DELETE}"
          label="Delete Appointment"
          .onClick="${this.handlers.delete}"
        ></neb-button>
        </div>
        <neb-button
          id="${ELEMENTS.backButton.id}"
          class="button back"
          role="${BUTTON_ROLE.CANCEL}"
          label="CANCEL"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
        ${
          this.layout !== 'small' && this.model.matches.length
            ? this.__renderHelpText()
            : ''
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-match-patient-account',
  NebPopupMatchPatientAccount,
);
