/**
 * Filter patients based on available filters.
 * Filters should have the following structure:
 *  {
 *    statePath: 'activePatients',
 *    filter: patient => patient.status === 'Active'
 *  }
 *  where:
 *
 *    statePath: the name of the property to set the filtered items into the state
 *    filter: the filter function which will determine which item to be set to the filtered list
 *
 * @param {*} state The patient state to filter from and to.
 * @param {String} filterFromPropName The name of the property where to filter items from.
 * @param {Array} filters The filters to be used on the state.
 */
export function filter(state, filterFromPropName, filters) {
  // reset the receiving array from state before filtering
  filters.forEach(filter => {
    state[filter.statePath] = [];
  });

  if (state[filterFromPropName]) {
    state[filterFromPropName].forEach(item => {
      filters.forEach(filter => {
        if (filter.filter(item)) {
          state[filter.statePath].push(item);
        }
      });
    });
  }
}
