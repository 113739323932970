import '../../controls/neb-tab-group';
import '../../encounter/neb-encounter-card-list';
import '../../../../../../src/components/collection-pages/patient-case/neb-page-case-appointments';
import '../../../../../../src/components/collection-pages/patient-case/neb-page-case-encounters';
import '../../../../../../src/components/collection-pages/patient-case/neb-page-case-charges';

import { LitElement, html, css } from 'lit';

export const ELEMENTS = {
  tabs: {
    id: 'tabs',
  },
  container: {
    id: 'container',
  },
  appointmentsPage: {
    id: 'appointments-page',
  },
  encountersPage: {
    id: 'encounters-page',
  },
  chargesPage: {
    id: 'charges-page',
  },
};

class NebLinkedRecords extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __navItems: Array,
      hidden: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      caseId: {
        type: String,
      },
      patientId: {
        type: String,
      },
      providers: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.caseId = '';
    this.patientId = '';
    this.providers = [];
    this.__selectedTab = 'appointments';
    this.__navItems = [];
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        if (tab !== this.__selectedTab) {
          this.__selectedTab = tab;
        }
      },
    };
  }

  static get styles() {
    return css`
      :host([hidden]) {
        display: none;
      }

      .page {
        overflow: hidden;
        display: flex;
        flex: 1 0 0;
      }

      .container {
        padding-top: 10px;
        padding-bottom: 10px;
        flex: 1 0 0px;
      }
    `;
  }

  __renderAppointments() {
    return html`
      <neb-page-case-appointments
        id="${ELEMENTS.appointmentsPage.id}"
        .layout="${this.layout}"
        .caseId="${this.caseId}"
        .patientId="${this.patientId}"
      ></neb-page-case-appointments>
    `;
  }

  __renderEncounters() {
    return html`
      <neb-page-case-encounters
        id="${ELEMENTS.encountersPage.id}"
        .layout="${this.layout}"
        .caseId="${this.caseId}"
        .patientId="${this.patientId}"
        .providers="${this.providers}"
      ></neb-page-case-encounters>
    `;
  }

  __renderCharges() {
    return html`
      <neb-page-case-charges
        id="${ELEMENTS.chargesPage.id}"
        .caseId="${this.caseId}"
        .patientId="${this.patientId}"
      ></neb-page-case-charges>
    `;
  }

  __genNavItems() {
    return [
      {
        id: 'appointments',
        label: 'Appointments',
        renderer: () => this.__renderAppointments(),
      },
      {
        id: 'encounters',
        label: 'Encounters',
        renderer: () => this.__renderEncounters(),
      },
      {
        id: 'charges',
        label: 'Charges',
        renderer: () => this.__renderCharges(),
      },
    ];
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      >
      </neb-tab-group>
    `;
  }

  __renderTabContent() {
    return this.__navItems
      .find(({ id }) => id === this.__selectedTab)
      .renderer();
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${this.__renderTabs()} ${this.__renderTabContent()}
      </div>
    `;
  }
}

window.customElements.define('neb-linked-records', NebLinkedRecords);
