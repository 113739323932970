import '../../../../../../src/components/misc/neb-icon';
import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_CAPTION,
  CSS_BORDER_GREY_1,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_3,
} from '../../../../../neb-styles/neb-variables';
import { formatDateForAdditionalInfo } from '../../../../../neb-utils/claims';

export const ELEMENTS = {
  supplementalInformationCell: { id: 'supplemental-information-cell' },
  expandIcons: { selector: 'neb-icon' },
};

const POPUP_CONFIG = {
  [ELEMENTS.supplementalInformationCell.id]: {
    name: 'additionalSupplmentalClaimInformation',
    type: 'supplementalFields',
    title: '19. Additional Claim Information (Designated by NUCC)',
  },
};

const SPACE_DELIMITER = ' '.repeat(3);

class NebClaimFormCellFL19 extends LitElement {
  static get properties() {
    return {
      model: Object,
      errors: Object,
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {
      additionalCheckbox: false,
      codeQualifier: '',
      reportTransmissionCode: '',
      reportTypeCode: '',
      additionalClaimInformation: '',
      identificationNumber: '',
    };

    this.errors = {
      reportTypeCode: '',
      reportTransmissionCode: '',
    };

    this.renderExpandIcon = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: ({ currentTarget: { id } }) => this.onClick(POPUP_CONFIG[id]),
      clickCell: claimCellData => this.onClick(claimCellData),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
        }

        .span-column-5 {
          grid-column: span 5;
        }

        .cell {
          display: grid;
          width: 100%;
        }

        .label {
          font-size: ${CSS_FONT_SIZE_CAPTION};
          border-right: ${CSS_BORDER_GREY_1};
          display: flex;
          align-items: center;
          padding-left: ${CSS_SPACING};
        }

        :host([renderExpandIcon]) .row:hover {
          background-color: ${CSS_COLOR_GREY_4};
          cursor: pointer;
        }

        .row:hover .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .data-container {
          padding-left: ${CSS_SPACING};
          display: flex;
          align-items: center;
          position: relative;
        }

        .row {
          display: grid;
          grid-column: span 4;
          grid-template-columns: 85px 3fr;
        }

        .row:first-child {
          border-bottom: 1px dashed ${CSS_COLOR_GREY_1};
        }

        .icon {
          margin: 6px 6px auto auto;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
          position: absolute;
          top: 0;
          right: 0;
        }

        .shaded {
          background-color: ${CSS_COLOR_GREY_3};
        }
      `,
    ];
  }

  __renderExpandButton() {
    return this.renderExpandIcon
      ? html` <neb-icon class="icon" icon="neb:expand"></neb-icon> `
      : '';
  }

  __renderRow({ id, label, data, shaded }) {
    return html`
      <div
        id="${id}"
        class="row ${shaded ? 'shaded' : ''}"
        @click="${this.__handlers.click}"
      >
        <div class="label">${label}</div>
        <div class="data-container">${data} ${this.__renderExpandButton()}</div>
      </div>
    `;
  }

  __getAdditionalFormatting() {
    const DOD_INFO = this.model.patientDateOfDeath
      ? `${SPACE_DELIMITER}V5${formatDateForAdditionalInfo(
          this.model.patientDateOfDeath,
        )}`
      : '';

    const { additionalClaimInformation } = this.model;
    return [additionalClaimInformation || '', DOD_INFO]
      .filter(Boolean)
      .join(' ');
  }

  __getSupplementalFormatting() {
    const { reportTypeCode, reportTransmissionCode, identificationNumber } =
      this.model;

    if (reportTypeCode && reportTransmissionCode) {
      return [
        'PWK',
        reportTypeCode,
        reportTransmissionCode,
        identificationNumber || '',
      ]
        .filter(Boolean)
        .join('');
    }
    return '';
  }

  __formatAdditionalClaimInfo() {
    const additional = this.__getAdditionalFormatting();
    const supplemental = this.__getSupplementalFormatting();
    return [additional, supplemental].filter(Boolean).join('   ');
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.supplementalInformationCell.id}"
          class="cell span-column-5"
          label="${POPUP_CONFIG[ELEMENTS.supplementalInformationCell.id].title}"
          borderBottom
          type="${POPUP_CONFIG[ELEMENTS.supplementalInformationCell.id].type}"
          name="${POPUP_CONFIG[ELEMENTS.supplementalInformationCell.id].name}"
          ?renderExpandIcon="${this.renderExpandIcon}"
          .data="${this.__formatAdditionalClaimInfo()}"
          .onClick="${this.__handlers.clickCell}"
          .problemText="${this.errors.reportTypeCode ||
          this.errors.reportTransmissionCode}"
        >
        </neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-19', NebClaimFormCellFL19);
