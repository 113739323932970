import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_FONT_FAMILY,
  CSS_COLOR_BLACK,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
    label: 'YES',
  },
  buttonCancel: {
    id: 'button-cancel',
    label: 'NO',
  },

  textMessage: {
    id: 'text-message',
    message: html`
      <div>
        Deleting an encounter will remove all record of the encounter from
        Charting. Any associated payments will be unallocated, and any charges
        or diagnoses will be removed. This action cannot be undone.
      </div>
      <br />
      <div>Are you sure you want to delete this encounter?</div>
    `,
  },
};

class NebPopupDeleteEncounter extends NebPopup {
  initState() {
    super.initState();
    this.model.title = '';
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () =>
        this.onClose({
          confirm: true,
        }),
      cancel: () =>
        this.onClose({
          cancel: true,
        }),
    };
  }

  modelChanged() {
    this.title = 'Delete Encounter';
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        .message {
          margin: 0;
          white-space: inherit;
          color: ${CSS_COLOR_BLACK};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${ELEMENTS.textMessage.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            .label="${ELEMENTS.buttonConfirm.label}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          ${this.__renderCancelButton()}
        </div>
      </div>
    `;
  }

  __renderCancelButton() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonCancel.id}"
        class="button"
        .label="${ELEMENTS.buttonCancel.label}"
        .role="${BUTTON_ROLE.OUTLINE}"
        .onClick="${this.__handlers.cancel}"
      ></neb-button>
    `;
  }
}

customElements.define('neb-popup-delete-encounter', NebPopupDeleteEncounter);
