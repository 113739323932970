import '../neb-action-bar';
import '../neb-tooltip';
import './neb-problem-list-add-problem-controller';
import './neb-problem-list-table';
import './neb-problems-form';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { openSuccess } from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { BUTTON_ROLE } from '../neb-button';

export const ELEMENTS = {
  copyButton: {
    id: 'button-copy',
  },
  problemListTable: {
    id: 'problem-list-table',
  },
  addProblemController: {
    id: 'add-problem-controller',
  },
  actionBar: {
    id: 'action-bar',
  },
  form: {
    id: 'form',
  },
  tooltip: {
    id: 'tooltip',
  },
};

const LABEL_TOOLTIP =
  'This will place a copy of the patient’s problem list data in the chart note for this encounter. This action is only active when there are no unsaved changes on this page.';

class NebProblemList extends LitElement {
  static get properties() {
    return {
      problems: Array,
      originalProblems: Array,
      showCopyButton: Boolean,
      small: Boolean,
      formVisible: Boolean,
      visible: Boolean,
      model: Object,
      layout: { type: String, reflect: true },
      enableSaveAndClose: {
        type: Boolean,
        attribute: 'enable-save-and-close',
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.problems = [];
    this.originalProblems = [];
    this.showCopyButton = true;
    this.small = false;
    this.formVisible = false;
    this.visible = false;
    this.model = {};
    this.layout = '';
    this.enableSaveAndClose = false;

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onCopy = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => this.__save({ closeAfterSave: false }),
      saveAndClose: () => this.__save({ closeAfterSave: true }),
      cancel: () => this.onCancel(),
      copyProblemList: () => this.onCopy(this.problems),
      addNewProblemToList: problem => this.__addProblem(problem),
      updateProblemInList: (index, problem, problems) =>
        this.__updateProblem(index, problem, problems),
      reorderProblemList: problems => {
        this.problems = problems;
        this.requestUpdate();
      },
      formSave: ({ info }) => {
        if (this.isUpdate) {
          this.__updateProblemInList(this.model.index, info);
        } else {
          this.__handleSave(info);
        }

        this.__resetProblemsFormState();
      },
      formCancel: () => {
        this.__resetProblemsFormState();
      },
      formDelete: ({ deleteProblem }) => {
        this.__resetProblemsFormState();

        this.__handleDeleteProblem(
          this.model.index,
          deleteProblem,
          this.model.problems,
        );
      },
    };
  }

  async __save(opts) {
    await this.onSave(this.problems, opts);
    this.elements.addProblemController.clear();
  }

  __resetProblemsFormState() {
    this.formVisible = false;
    this.isUpdate = false;
  }

  __handleSave(info) {
    this.__addProblemToList(info);

    this.__openSuccessBanner('Problem added to problem list');
  }

  async __addProblem(problem) {
    this.model = {
      title: 'Add to Problem List',
      problem: { ...problem, onsetDate: problem.onsetDate || parseDate() },
    };

    if (this.small) {
      this.formVisible = true;
    } else {
      const { info, resize } = await openPopup(
        POPUP_RENDER_KEYS.PROBLEMS,
        this.model,
      );

      if (resize) {
        this.model = { ...this.model, problem: info };
        this.formVisible = true;
      } else if (info) {
        this.__handleSave(info);
      }
    }
  }

  __addProblemToList(problem) {
    this.problems = [...this.problems, { ...problem, isNew: true }];
  }

  async __updateProblem(index, problem, problems) {
    this.model = {
      title: 'Update Problem',
      problem,
      problems,
      isUpdate: true,
      index,
    };

    this.isUpdate = true;

    if (this.small) {
      this.formVisible = true;
    } else {
      const { info, resize, deleteProblem } = await openPopup(
        POPUP_RENDER_KEYS.PROBLEMS,
        this.model,
      );

      if (resize) {
        this.model.problem = info;
        this.formVisible = true;
      } else if (info) {
        return this.__updateProblemInList(index, info);
      }

      if (deleteProblem) {
        this.__handleDeleteProblem(index, deleteProblem, problems);
      }
    }

    return undefined;
  }

  __openConfirmationPopup() {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Remove Problem',
      message:
        "Are you sure that you wish to remove this problem from the patient's problem list?",
      confirmText: 'YES',
      cancelText: 'NO',
    });
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.elements = {
      problemListTable: this.shadowRoot.getElementById(
        ELEMENTS.problemListTable.id,
      ),
      addProblemController: this.shadowRoot.getElementById(
        ELEMENTS.addProblemController.id,
      ),
    };
  }

  __updateProblemInList(index, problem) {
    const newProblems = [...this.problems];
    newProblems[index] = problem;
    this.problems = newProblems;

    this.__openSuccessBanner('Problem updated');
  }

  async __handleDeleteProblem(index, problem, problems) {
    const accepted = await this.__openConfirmationPopup();

    if (accepted) {
      return this.__deleteProblem(index);
    }

    this.__handlers.updateProblemInList(index, problem, problems);

    return undefined;
  }

  __deleteProblem(index) {
    const newProblems = [...this.problems];
    newProblems.splice(index, 1);
    this.problems = newProblems;

    this.__openSuccessBanner('Problem removed from list');
  }

  __openSuccessBanner(message) {
    store.dispatch(openSuccess(message));
  }

  __getUnresolvedProblems() {
    return this.problems.filter(
      problem => problem.status.toLowerCase() !== 'resolved',
    );
  }

  isDirty() {
    return (
      JSON.stringify(this.problems) !== JSON.stringify(this.originalProblems)
    );
  }

  clear() {
    this.elements.problemListTable.clear();
    this.elements.addProblemController.clear();
    this.problems = this.originalProblems.slice();
  }

  updated(changedProps) {
    if (changedProps.has('originalProblems')) {
      this.problems = [...this.originalProblems];
    }

    if (changedProps.has('problems')) {
      this.onChangeDirty(this.isDirty());
    }

    if (changedProps.has('small') && this.formVisible) {
      if (!this.small) {
        const problem = this.shadowRoot
          .getElementById(ELEMENTS.form.id)
          .getCurrentInfo();
        this.formVisible = false;

        if (this.model.isUpdate) {
          this.__handlers.updateProblemInList(this.model.index, problem);
        } else {
          this.__handlers.addNewProblemToList(problem);
        }
      }
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
      }

      .container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        position: relative;
        flex: 1 0 0;
      }

      :host(:not([layout='large'])) .container {
        overflow: auto;
      }

      .container-button {
        display: flex;
        align-items: center;

        margin: 30px 0 ${CSS_SPACING} ${CSS_SPACING};
      }

      .button-copy {
        margin-right: 10px;
      }

      .form {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      .label {
        width: 320px;
      }
    `;
  }

  __renderForm() {
    return html`
      <div class="container">
        <neb-problems-form
          id="${ELEMENTS.form.id}"
          class="form"
          .small="${this.small}"
          .model="${this.model}"
          .onCancel="${this.__handlers.formCancel}"
          .onSave="${this.__handlers.formSave}"
          .onDelete="${this.__handlers.formDelete}"
        ></neb-problems-form>
      </div>
    `;
  }

  __renderTableAndController() {
    return html`
      <div class="container">
        <neb-problem-list-table
          id="${ELEMENTS.problemListTable.id}"
          .small="${this.small}"
          .problems="${this.problems}"
          .onUpdateProblemInList="${this.__handlers.updateProblemInList}"
          .onReorderProblemList="${this.__handlers.reorderProblemList}"
        ></neb-problem-list-table>

        ${this.__renderCopyButton()}

        <neb-problem-list-add-problem-controller
          id="${ELEMENTS.addProblemController.id}"
          .visible="${this.visible}"
          .savedProblems="${this.__getUnresolvedProblems()}"
          .onAddNewProblemToList="${this.__handlers.addNewProblemToList}"
        ></neb-problem-list-add-problem-controller>
      </div>

      ${this.isDirty() ? this.__renderActionBar() : ''}
    `;
  }

  __renderCopyButton() {
    return this.showCopyButton
      ? html`
          <div class="container-button">
            <neb-button
              id="${ELEMENTS.copyButton.id}"
              class="button-copy"
              .role="${BUTTON_ROLE.OUTLINE}"
              label="COPY PROBLEM LIST TO CHART NOTE"
              .onClick="${this.__handlers.copyProblemList}"
              ?disabled="${!this.problems.length || this.isDirty()}"
            ></neb-button>

            <neb-tooltip
              id="${ELEMENTS.tooltip.id}"
              class="tooltip"
              defaultAnchor="right"
              ><div class="label" slot="tooltip">${LABEL_TOOLTIP}</div>
            </neb-tooltip>
          </div>
        `
      : '';
  }

  __renderActionBar() {
    return this.enableSaveAndClose
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.saveAndClose}"
            .onRemove="${this.__handlers.cancel}"
            confirmLabel="Save"
            cancelLabel="Save and Close"
            removeLabel="Cancel"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
            confirmLabel="Save Problems"
          ></neb-action-bar>
        `;
  }

  render() {
    return html`
      ${this.formVisible
        ? this.__renderForm()
        : this.__renderTableAndController()}
    `;
  }
}
customElements.define('neb-problem-list', NebProblemList);
