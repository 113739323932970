import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { openSuccess, openError } from '../../../store';
import { createFiservPaymentMethod } from '../fiserv-api-client';
import { PAYMENT_RESULT_CONFIG } from '../messages';

export const handlePaymentComplete = async ({
  paymentDetails,
  token,
  expiry,
  singleUseToken,
}) => {
  if (singleUseToken || paymentDetails.holderType === 'payer') {
    openPopup(
      POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
      PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
    );

    return;
  }

  const { action, saveCardOnFile, cardOnFileName } = await openPopup(
    POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
    PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS_AND_SAVE,
  );

  if (action === 'confirm' && saveCardOnFile) {
    try {
      await createFiservPaymentMethod({
        ...paymentDetails,
        token,
        expiry,
        description: cardOnFileName,
      });

      openSuccess('Payment method stored successfully');
    } catch (error) {
      console.error(error);
      openError('An error occurred when storing payment method');
    }
  }
};
