import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

const BILLING_NOTE_BASE_URL = 'billing-note';

const formatBillingNotes = rawBillingNotes =>
  rawBillingNotes.map(billingNote => ({
    id: billingNote.id,
    sortOrder: billingNote.sortOrder,
    note: billingNote.note,
    createdAt: billingNote.createdAt,
    createdBy: billingNote.createdBy,
    updatedAt: billingNote.updatedAt,
    updatedBy: billingNote.updatedBy,
  }));

export const createBillingNotes = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BILLING_NOTE_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    version: 1,
  });

export const upsertBillingNotes = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: BILLING_NOTE_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    version: 1,
  });

export const deleteBillingNotes = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    responseType: RESPONSE_TYPE.RAW,
    path: BILLING_NOTE_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    version: 1,
  });

export const getBillingNotes = async queryParams => {
  const rawBillingNotes = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: BILLING_NOTE_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    queryParams,
    version: 1,
  });

  return formatBillingNotes(rawBillingNotes);
};
