import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const fetchMany = async (optOutLoadingIndicator = false) => {
  const res = await coreApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    path: '/api/v1/tenants/:tenantId/tags',
    method: Method.GET,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const save = tag =>
  coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: '/api/v1/tenants/:tenantId/tags',
    method: Method.POST,
  });

export const update = tag =>
  coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: '/api/v1/tenants/:tenantId/tags/:tagId',
    replacements: { tagId: tag.id },
    method: Method.PUT,
  });

export const remove = id =>
  coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: '/api/v1/tenants/:tenantId/tags/:tagId',
    replacements: { tagId: id },
    method: Method.DELETE,
  });

export const replace = (oldId, newId) =>
  coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: '/api/v1/tenants/:tenantId/tags/:oldId/replace-tag/:newId',
    replacements: { oldId, newId },
    method: Method.PUT,
  });
