const SAVE_APPT_CONFLICT_ERROR = 'SAVE_APPT_CONFLICT_ERROR';
const SAVE_APPT_OTHER_ERROR = 'SAVE_APPT_OTHER_ERROR';

const setErrorType = ({ status }) =>
  status === 409 ? SAVE_APPT_CONFLICT_ERROR : SAVE_APPT_OTHER_ERROR;

export const SaveAppointmentErrorType = Object.freeze({
  SAVE_APPT_CONFLICT_ERROR,
  SAVE_APPT_OTHER_ERROR,
});
export const _sortItems = items =>
  items && items.length
    ? items.sort(
        (item1, item2) => item1.start.valueOf() - item2.start.valueOf(),
      )
    : items;
export const _itemsGroupedById = items =>
  items.reduce((memo, appointment) => {
    if (!appointment.groupId) return memo;
    const { groupId, recurrenceEventIndex } = appointment;
    const modifiedGroupId =
      !groupId.match(/(.+)-(\d{1,})$/) &&
      ![null, undefined].includes(recurrenceEventIndex)
        ? `${groupId}-${recurrenceEventIndex}`
        : appointment.groupId;

    if (!memo[modifiedGroupId]) {
      return { ...memo, [modifiedGroupId]: [appointment] };
    }
    memo[modifiedGroupId].push(appointment);

    return memo;
  }, {});

export const _buildError = (action, errorKey) => {
  const errorType = setErrorType(action);

  return {
    error: action[errorKey],
    type: errorType,
  };
};
