import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-image-selection-card';
import '../../../../packages/neb-lit-components/src/components/neb-selection-card';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/macros/neb-macro-button';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import {
  BUTTON_TYPE_TO_COLOR,
  MACRO_BUTTON_TYPE,
} from '../../../../packages/neb-utils/macro-sets';

export const ELEMENTS = {
  header: {
    id: 'header',
    title: 'Select Macro Button Type',
  },
  cardTextButton: { id: 'card-text-button' },
  cardMenuButton: { id: 'card-menu-button' },
  cardMenuLinkButton: { id: 'card-menu-link-button' },
};

export const MACRO_BUTTON_TYPES = Object.values(MACRO_BUTTON_TYPE);

class NebOverlayMacroButtonType extends Overlay {
  get cardConfig() {
    return [
      {
        id: ELEMENTS.cardTextButton.id,
        title: 'Text Button',
        type: MACRO_BUTTON_TYPE.MACRO,
        handler: this.handlers.selectButton,
        description: html`
          <p>
            This will add a new text button. Text buttons will insert formatted
            text or advanced macros into the patient’s chart note. Advanced
            macros include text replacement and question-based macros.
          </p>
        `,
      },
      {
        id: ELEMENTS.cardMenuButton.id,
        title: 'Menu Button',
        type: MACRO_BUTTON_TYPE.FOLDER,
        handler: this.handlers.selectButton,
        description: html`
          <p>
            This will add a new menu button. Menu buttons contain an additional
            page of macro buttons. This is useful to organize the macro set, and
            to give more room for additional macro buttons.
          </p>
        `,
      },
      {
        id: ELEMENTS.cardMenuLinkButton.id,
        title: 'Menu Link Button',
        type: MACRO_BUTTON_TYPE.FOLDER,
        handler: this.handlers.openMenuLinkButtonOverlay,
        description: html`
          <p>
            This will add a link to an existing menu button. This is useful to
            allow access to the same menu from multiple areas in the macro set.
          </p>
        `,
      },
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      openMenuLinkButtonOverlay: async () => {
        const result = await openOverlay(OVERLAY_KEYS.MACRO_FOLDER_FINDER, {
          ...this.model,
        });

        if (result) {
          this.dismiss(result);
        }
      },
      selectButton: type => this.dismiss({ type }),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .container {
          padding: ${CSS_SPACING};
          overflow-y: auto;
        }

        .card {
          margin-bottom: ${CSS_SPACING};
        }

        .card:last-of-type {
          margin-bottom: 0;
        }

        .button {
          width: 220px;
          margin: ${CSS_SPACING} 0;
          pointer-events: none;
        }
      `,
    ];
  }

  __renderCard({ title, type, id, description, handler }) {
    const item = { label: title, type };
    const { color, icon } = BUTTON_TYPE_TO_COLOR[type];

    return html`
      <neb-selection-card
        id="${id}"
        class="card"
        .title="${title}"
        .name="${type}"
        .value="${item}"
        .onClick="${handler}"
        >${description}
        <neb-macro-button
          class="button"
          .label="${title}"
          .type="${type}"
          .icon="${icon}"
          .color="${color}"
        ></neb-macro-button>
      </neb-selection-card>
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        .title="${ELEMENTS.header.title}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>

      <div class="container">
        ${this.cardConfig.map(card => this.__renderCard(card))}
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-macro-button-type',
  NebOverlayMacroButtonType,
);
