import { store } from '../neb-redux/neb-redux-store';

import {
  ACTION_SET_LAYOUT,
  ACTION_SET_SMALL_NAV_EXPANDED,
  ACTION_SET_STATIC_LAYOUT,
} from './neb-layout-reducer';

export const MIN_WIDTH_LARGE = 1200;
export const MIN_WIDTH_MEDIUM = 835;
export const MEDIA_QUERY_LARGE = `(min-width: ${MIN_WIDTH_LARGE}px)`;
export const MEDIA_QUERY_MEDIUM = `(min-width: ${MIN_WIDTH_MEDIUM}px) and (max-width: ${
  MIN_WIDTH_LARGE - 1
}px)`;
export const MEDIA_QUERY_SMALL = `(max-width: ${MIN_WIDTH_MEDIUM - 1}px)`;
export const MEDIA_QUERY_TOUCH_DEVICE = '(hover: none) and (pointer: coarse)';
const RESPONSIVE_LAYOUTS = [
  {
    name: 'large',
    query: MEDIA_QUERY_LARGE,
  },
  {
    name: 'medium',
    query: MEDIA_QUERY_MEDIUM,
  },
  {
    name: 'small',
    query: MEDIA_QUERY_SMALL,
  },
];
const STATIC_LAYOUTS = [
  {
    name: 'touchDevice',
    query: MEDIA_QUERY_TOUCH_DEVICE,
  },
];

const __buildDefaultResponsiveState = () => {
  const state = {};
  RESPONSIVE_LAYOUTS.forEach(layout => {
    state[layout.name] = false;
  });

  return state;
};

const DEFAULT_RESPONSIVE_STATE = __buildDefaultResponsiveState();

/**
 * Callback for when a media query changes.
 * @param {Layout} layout
 * @param {boolean} matches
 */
const __handleMediaQueryChange = (layout, matches) => {
  if (!matches) {
    return;
  }

  const state = {
    ...DEFAULT_RESPONSIVE_STATE,
    [layout.name]: true,
    layout: layout.name,
  };
  store.dispatch({
    type: ACTION_SET_LAYOUT,
    layout: state,
  });
};

/**
 * Installs a media query listener for a given layout
 * @param {Layout} layout
 * @returns {Function} call to remove the listener
 */
export const installMediaQueryListener = (query, listener) => {
  const mql = window.matchMedia(query);
  mql.addListener(e => listener(e.matches));
  listener(mql.matches);
  return () => mql.removeListener(listener);
};

const __installMediaQueryWatcher = layout =>
  installMediaQueryListener(layout.query, matches =>
    __handleMediaQueryChange(layout, matches),
  );

export const setSmallNavExpanded = smallNavExpanded => ({
  type: ACTION_SET_SMALL_NAV_EXPANDED,
  smallNavExpanded,
});

/**
 * Call to initialize media query listeners. This should only be called once.
 * @returns {Function} call to remove all media query listeners
 */
export const initialize = () => {
  const unsubscribes = [];
  RESPONSIVE_LAYOUTS.forEach(layout =>
    unsubscribes.push(__installMediaQueryWatcher(layout)),
  );

  STATIC_LAYOUTS.forEach(layout => {
    const mql = window.matchMedia(layout.query);
    const state = {
      [layout.name]: mql.matches,
    };
    store.dispatch({
      type: ACTION_SET_STATIC_LAYOUT,
      state,
    });
  });

  return () => unsubscribes.forEach(unsubscribe => unsubscribe());
};
