import { FETCH_SERVICES } from './servicesAction';

function handleFetchAction(state, action) {
  switch (action.status) {
    case 'success':
      const newState = {
        ...state,
        isFetchingServices: false,
        fetchingErrorServices: null,
        serviceItems: action.response.data,
      };
      return newState;

    case 'failed':
      return {
        ...state,
        isFetchingServices: false,
        fetchingErrorServices: action.error,
      };

    default:
      return {
        ...state,
        isFetchingServices: true,
        fetchingErrorServices: null,
      };
  }
}

export const services = (
  state = {
    isFetchingServices: false,
    fetchingErrorServices: null,
    serviceItems: [],
  },
  action,
) => {
  switch (action.type) {
    case FETCH_SERVICES:
      return handleFetchAction(state, action);

    default:
      return state;
  }
};
