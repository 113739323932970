import '../../../src/components/misc/neb-icon';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../neb-styles/neb-variables';

import Popup from './neb-popup';

export const ELEMENTS = {
  buttonReopen: {
    id: 'button-reopen',
    text: 'REOPEN ENCOUNTER',
  },
  buttonCancel: {
    id: 'button-cancel',
    text: 'CANCEL',
  },
  iconInfo: {
    id: 'icon-info',
  },
  textInfo: {
    id: 'text-info',
    text: 'Finalized encounters are permanent records of the patient\'s chart and cannot be deleted. By reopening a signed encounter, the previously finalized record will be overwritten by these addenda. You can view the history of changes by clicking on "View Encounter History" in the Encounter Summary.',
  },
  textConfirm: {
    id: 'text-confirm',
    text: 'Please confirm that you would like to reopen this encounter. Click "Reopen Encounter" to continue, or "Cancel" to return to the previous screen.',
  },
  title: {
    id: 'title',
    text: 'Confirm Reopening Encounter',
  },
};

class NebPopupEncounterReopen extends Popup {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 40%;
        }

        :host([layout='small']) {
          width: 100%;
        }

        .container {
          display: block;
        }

        .color-highlight {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .info {
          display: flex;
          justify-content: left;
        }

        .info-icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          flex: 0.15 0 0;
          height: ${CSS_SPACING};
          margin-right: 10px;
        }

        :host([layout='small']) .info-icon {
          padding-right: 5px;
        }

        .info-text {
          flex: 2 0 0;
        }

        .button-reopen {
          padding-right: 6px;
        }

        .button-container {
          display: block;
          white-space: nowrap;
          padding-bottom: 8px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.title = ELEMENTS.title.text;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      reopen: () =>
        this.onClose({
          accepted: true,
        }),
      cancel: () =>
        this.onClose({
          accepted: false,
        }),
    };
  }

  __renderInfo() {
    return html`
      <neb-icon
        id="${ELEMENTS.iconInfo.id}"
        class="info-icon"
        icon="neb:info"
      ></neb-icon>
      <div id="${ELEMENTS.textInfo.id}" class="info-text">
        ${ELEMENTS.textInfo.text}
      </div>
    `;
  }

  __renderButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonReopen.id}"
        class="button-reopen"
        .label="${ELEMENTS.buttonReopen.text}"
        role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.reopen}"
      ></neb-button>

      <neb-button
        id="${ELEMENTS.buttonCancel.id}"
        role="${BUTTON_ROLE.CANCEL}"
        .label="${ELEMENTS.buttonCancel.text}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-container">
        <div class="spacer color-highlight info">${this.__renderInfo()}</div>

        <div id="${ELEMENTS.textConfirm.id}" class="spacer confirmation-text">
          ${ELEMENTS.textConfirm.text}
        </div>

        <div class="button-container">${this.__renderButtons()}</div>
      </div>
    `;
  }
}

customElements.define('neb-popup-encounter-reopen', NebPopupEncounterReopen);
