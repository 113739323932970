/* eslint-disable complexity */
import { APPOINTMENT_BILLING_TYPE } from '../../../../packages/neb-api-client/src/mappers/appointment-billing-info-mapper';
import { FULL_DATE_FORMAT } from '../../../../packages/neb-input/nebFormatUtils';
import { formatGuarantorName } from '../../../../packages/neb-lit-components/src/components/guarantor/guarantor-util';
import { INSURANCE_LEVELS } from '../../../../packages/neb-lit-components/src/utils/insurance-util';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { PACKAGE_TYPE } from '../../../../packages/neb-utils/enums';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import {
  formatAuthorizationLabel,
  hasAuthorizationRemaining,
  VALID_AUTHORIZATION_STATUSES,
} from '../../../../packages/neb-utils/patientAuthorization';
import {
  DEFAULT_LEVEL,
  formatCoverageDates,
  formatPolicyHolderName,
} from '../../../../packages/neb-utils/patientInsurance';
import { getDescriptionLines } from '../../../../packages/neb-utils/patientPackage';
import { rteEnabledInsurance } from '../../../../packages/neb-utils/payer-plan-util';

export const BILLING_OPTIONS = {
  [APPOINTMENT_BILLING_TYPE.SelfPay]: 'Self Pay',
  [APPOINTMENT_BILLING_TYPE.CarePackage]: 'Care Package',
  [APPOINTMENT_BILLING_TYPE.Insurance]: 'Insurance',
};

export const BILLING_OPTIONS_REVERSE = {
  'Self Pay': 'selfPay',
  'Care Package': 'carePackage',
  Insurance: 'insurance',
};

export const INSURANCE_KEYS = [
  'primaryInsuranceId',
  'secondaryInsuranceId',
  'tertiaryInsuranceId',
];

export const SELF_GUARANTOR_ITEM = {
  label: 'Self',
  data: { id: '' },
};

export const formatGradualDate = (date, dateFormat = 'MM/DD/YYYY') =>
  date ? parseDate(date).format(dateFormat) : 'Gradual';

export const formatCaseItem = patientCase => {
  const {
    id,
    active,
    name,
    guarantorId,
    isDefault,
    billType,
    patientPackageId,
    onsetSymptomsDate,
  } = patientCase;

  const { primaryInsuranceId, secondaryInsuranceId } =
    patientCase.payerInsurance;

  const label = `${name} - ${formatGradualDate(onsetSymptomsDate)}`;

  return {
    label,
    data: {
      id,
      active,
      isDefault,
      billType: BILLING_OPTIONS_REVERSE[billType],
      guarantorId,
      patientPackageId,
      primaryInsuranceId,
      secondaryInsuranceId,
    },
  };
};

export const formatAuthItem = newAuth => {
  const { id, patientCaseId } = newAuth;
  const name = formatAuthorizationLabel(newAuth);
  return {
    label: name,
    data: {
      id,
      name,
      patientCaseId,
      hasRemaining: hasAuthorizationRemaining(newAuth),
    },
  };
};

export const checkValidAuthStatus = auth =>
  VALID_AUTHORIZATION_STATUSES.includes(auth.status);

export const formatAuthItems = newAuths =>
  (newAuths || [])
    .filter(checkValidAuthStatus)
    .map(auth => formatAuthItem(auth));

export const formatGuarantorItem = guarantor => ({
  data: {
    id: guarantor.id,
    default: guarantor.default,
    active: guarantor.active,
  },
  label: guarantor.organization
    ? guarantor.organization.name
    : formatGuarantorName(guarantor),
});

export const formatFeeScheduleItem = feeSchedule => ({
  data: {
    id: feeSchedule.id,
    default: feeSchedule.default,
  },
  label: feeSchedule.name,
});

export const calculatePackageUnits = charges =>
  charges.reduce((total, charge) => total + parseInt(charge.units, 10), 0);

export const formatPackageItem = carePackage => {
  const totalUnits =
    carePackage.type === PACKAGE_TYPE.SERVICE
      ? calculatePackageUnits(carePackage.charges)
      : 0;

  const usedCount = {
    totalVisits: carePackage.totalVisits,
    totalUnits,
    visitsRemaining: carePackage.totalVisits,
    unitsRemaining: totalUnits,
    visitsUsed: 0,
    unitsUsed: 0,
  };

  const name = `${carePackage.name}${!carePackage.active ? ' (Inactive)' : ''}`;

  return {
    data: {
      id: carePackage.id,
      active: carePackage.active,
      default: carePackage.isDefault,
      name,
      usageDetails: getDescriptionLines(carePackage, usedCount),
    },
    label: name,
  };
};

const getFeeScheduleForInsurance = (providerId, providerFeeSchedules) => {
  if (!providerFeeSchedules || providerFeeSchedules.length === 0) {
    return '';
  }

  const match = providerFeeSchedules.find(
    item => item.allProviders || item.providerId === providerId,
  );

  return match ? match.name : '';
};

const getProviderData = (payerPlan, providerId) =>
  payerPlan?.providerInOutOfNetwork
    ? payerPlan.providerInOutOfNetwork.find(
        data => data.providerId === providerId,
      )
    : null;

const getDefaultItem = items => items.find(item => item.isDefault) || items[0];

const getAmount = (item, providerIsInNetwork, amountType) =>
  providerIsInNetwork
    ? item[`inNetwork${amountType}`]
    : item[`outOfNetwork${amountType}`];

const formatCostSharing = (insurance, providerId) => {
  const { copays, coinsurances, deductibles, outOfPockets, payerPlan } =
    insurance;

  const providerData = getProviderData(payerPlan, providerId);
  const providerIsInNetwork = providerData
    ? providerData.isInNetwork !== false
    : true;

  const copayItem = copays?.length ? getDefaultItem(copays) : null;
  const coinsuranceItem = coinsurances?.length
    ? getDefaultItem(coinsurances)
    : null;
  const deductibleItem = deductibles?.length
    ? getDefaultItem(deductibles)
    : null;
  const outOfPocketItem = outOfPockets?.length
    ? getDefaultItem(outOfPockets)
    : null;

  const copayAmount = copayItem
    ? getAmount(copayItem, providerIsInNetwork, 'Amount')
    : null;
  const coinsurancePercent = coinsuranceItem
    ? getAmount(coinsuranceItem, providerIsInNetwork, 'Percent')
    : null;
  const deductibleAmount = deductibleItem
    ? getAmount(deductibleItem, providerIsInNetwork, 'Amount')
    : null;
  const deductibleRemaining = deductibleItem
    ? getAmount(deductibleItem, providerIsInNetwork, 'Remaining')
    : null;
  const outOfPocketAmount = outOfPocketItem
    ? getAmount(outOfPocketItem, providerIsInNetwork, 'Amount')
    : null;
  const outOfPocketRemaining = outOfPocketItem
    ? getAmount(outOfPocketItem, providerIsInNetwork, 'Remaining')
    : null;

  return {
    copay: copayAmount !== null ? centsToCurrency(copayAmount) : '',
    coinsurance:
      coinsurancePercent !== null
        ? `${coinsurancePercent} / ${100 - coinsurancePercent}`
        : '',
    deductible:
      deductibleAmount !== null
        ? `${centsToCurrency(deductibleAmount)} / ${
            deductibleRemaining !== null
              ? centsToCurrency(deductibleRemaining)
              : '-'
          }`
        : '',
    outOfPocket:
      outOfPocketAmount !== null
        ? `${centsToCurrency(outOfPocketAmount)} / ${
            outOfPocketRemaining !== null
              ? centsToCurrency(outOfPocketRemaining)
              : '-'
          }`
        : '',
  };
};

const getEligibilityDateInfo = date =>
  `Eligibility was checked on ${parseDate(date).format(FULL_DATE_FORMAT)}`;

const formatRTEDetails = insurance => {
  const { realTimeEligibilityRecord, realTimeEligibilityStatus, payerPlan } =
    insurance;
  const rteEnabled = !!rteEnabledInsurance(payerPlan) || false;
  const rteStatus = realTimeEligibilityStatus || '';
  const rteDescription = realTimeEligibilityRecord?.description || '';
  const rteLastChecked = realTimeEligibilityRecord?.submittedAt
    ? getEligibilityDateInfo(realTimeEligibilityRecord.submittedAt)
    : '';

  return {
    rteEnabled,
    rteStatus,
    rteDescription,
    rteLastChecked,
  };
};

export const formatInsuranceItem = (
  insurance,
  providerId,
  appointmentStart,
) => {
  const name = insurance.planInfo
    ? insurance.planInfo.planName
    : insurance.planName;

  const formattedName = `${name}${!insurance.active ? ' (Inactive)' : ''}`;

  const memberIdentifier = insurance.planInfo
    ? insurance.planInfo.memberIdentifier
    : insurance.memberIdentifier;

  const groupIdentifier = insurance.planInfo
    ? insurance.planInfo.groupIdentifier
    : insurance.groupIdentifier;

  const start = insurance.planInfo ? insurance.planInfo.start : insurance.start;

  const end = insurance.planInfo ? insurance.planInfo.end : insurance.end;

  const outOfCoverage =
    (start &&
      parseDate(start).startOf('day').isAfter(parseDate(appointmentStart))) ||
    (end && parseDate(end).endOf('day').isBefore(parseDate(appointmentStart)));

  const { payerPlan } = insurance;

  const { copay, coinsurance, deductible, outOfPocket } = formatCostSharing(
    insurance,
    providerId,
  );

  const { rteEnabled, rteStatus, rteDescription, rteLastChecked } =
    formatRTEDetails(insurance);

  return {
    label: formattedName,
    data: {
      id: insurance.id,
      active: insurance.active,
      name: formattedName,
      defaultLevel: insurance.defaultLevel,
      coverageDates: formatCoverageDates({
        start,
        end,
      }),
      outOfCoverage,
      policyHolder:
        insurance.policyHolderName || formatPolicyHolderName(insurance),
      memberIdentifier: memberIdentifier || '',
      groupIdentifier: groupIdentifier || '',
      payerPlanId: insurance.payerPlanId,
      alias: payerPlan.alias || '',
      payerName: `(${payerPlan.alias}) ${payerPlan.payerName}`,
      feeScheduleName: getFeeScheduleForInsurance(
        providerId,
        insurance.providerFeeSchedules,
      ),
      copay,
      coinsurance,
      deductible,
      outOfPocket,
      rteEnabled,
      rteStatus,
      rteDescription,
      rteLastChecked,
    },
  };
};

export const updateDefaultLevel = (ins, newInsuranceItem) => {
  const { Primary, Secondary, Tertiary } = DEFAULT_LEVEL;
  const { defaultLevel } = ins.data;

  if (
    [Primary, Secondary, Tertiary].includes(defaultLevel) &&
    defaultLevel === newInsuranceItem.data.defaultLevel
  ) {
    return {
      ...ins,
      data: {
        ...ins.data,
        defaultLevel: 'Other',
      },
    };
  }

  return ins;
};

export const getDefaultInsurances = insurances =>
  insurances.reduce((acc, insurance) => {
    if (acc.primary && acc.secondary && acc.tertiary) {
      return acc;
    }

    const { defaultLevel, active } = insurance.data;

    if (defaultLevel === INSURANCE_LEVELS.PRIMARY && active) {
      acc.primary = insurance;
    } else if (defaultLevel === INSURANCE_LEVELS.SECONDARY && active) {
      acc.secondary = insurance;
    } else if (defaultLevel === INSURANCE_LEVELS.TERTIARY && active) {
      acc.tertiary = insurance;
    }
    return acc;
  }, {});

export const updateInsuranceDefaultLevels = (
  insurances,
  newInsuranceDefaultLevel,
) =>
  insurances.reduce((acc, ins) => {
    const { defaultLevel, active } = ins.data;

    if (newInsuranceDefaultLevel === INSURANCE_LEVELS.PRIMARY) {
      if (defaultLevel === INSURANCE_LEVELS.TERTIARY && active) {
        ins.data.defaultLevel = 'Other';
      } else if (defaultLevel === INSURANCE_LEVELS.SECONDARY && active) {
        ins.data.defaultLevel = INSURANCE_LEVELS.TERTIARY;
      } else if (defaultLevel === INSURANCE_LEVELS.PRIMARY && active) {
        ins.data.defaultLevel = INSURANCE_LEVELS.SECONDARY;
      }
    } else if (newInsuranceDefaultLevel === INSURANCE_LEVELS.SECONDARY) {
      if (defaultLevel === INSURANCE_LEVELS.TERTIARY && active) {
        ins.data.defaultLevel = 'Other';
      } else if (defaultLevel === INSURANCE_LEVELS.SECONDARY && active) {
        ins.data.defaultLevel = INSURANCE_LEVELS.TERTIARY;
      }
    } else if (newInsuranceDefaultLevel === INSURANCE_LEVELS.TERTIARY) {
      if (defaultLevel === INSURANCE_LEVELS.TERTIARY && active) {
        ins.data.defaultLevel = 'Other';
      }
    }

    acc.push(ins);
    return acc;
  }, []);
