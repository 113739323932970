export const SOURCE_TYPE = {
  SOURCE: 'Source',
  ORGANIZATION: 'Organization',
};

export const formatReferralCategoryToUpdate = referralCategory => {
  const { sources } = referralCategory;

  let formattedSources;

  if (sources.length) {
    formattedSources = sources.map(source => ({
      ...source,
      id: source.id || null,
    }));

    return {
      ...referralCategory,
      sources: formattedSources,
    };
  }

  return referralCategory;
};

export const formatReferralSources = sources =>
  sources.map(source => {
    const { organizationId } = source;

    return {
      ...source,
      type: organizationId ? SOURCE_TYPE.ORGANIZATION : SOURCE_TYPE.SOURCE,
      name: organizationId ? source.organization.name : source.name,
      search: '',
    };
  });
