import DeprecatedApiClient, { Method } from './utils/api-client-utils';
import { encountersNotBalancedCount } from './utils/encounters-not-balanced';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

// neb-ms-billing /api/v3/tenants/:tenantId/patients/:patientId/totals GET
export const getPatientBillingTotals = async patientId => {
  const [encountersNotBalanced, { activity, ...totals }] = await Promise.all([
    encountersNotBalancedCount(patientId),
    apiClient.makeRequest({
      method: Method.GET,
      path: `patients/${patientId}/totals`,
      headers: { 'Content-Type': 'application/json' },
      version: 4,
      cacheKey: `patients/${patientId}/totals`,
    }),
  ]);

  return {
    ...totals,
    activity: { ...activity, encountersNotBalanced },
  };
};

// neb-ms-billing /api/v1/tenants/:tenantId/patients/:patientId/balance GET
export const getPatientBalance = patientId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patients/${patientId}/balance`,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    cacheKey: `patients/${patientId}/balance`,
  });
