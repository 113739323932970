import {
  format,
  startOfMonth,
  lastDayOfMonth,
  isBefore,
  isAfter,
  differenceInCalendarDays,
} from 'date-fns';

import * as availabilityApi from '../neb-api-client/src/availability-api-client';

const AVAILABILITY_FETCHING = 'AVAILABILITY_FETCHING';
const AVAILABILITY_FETCHED = 'AVAILABILITY_FETCHED';
const AVAILABILITY_FETCH_FAILED = 'AVAILABILITY_FETCH_FAILED';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Use this to classify old range requests with new ones, in order to determine whether we should
 * replace or merge the results.
 */
export const RangeClassification = Object.freeze({
  NEW_AROUND_OLD: 'NEW_AROUND_OLD',
  OLD_AROUND_OR_EQUAL_NEW: 'OLD_AROUND_OR_EQUAL_NEW',
  ADJACENT_OR_OVERLAPPING: 'ADJACENT_OR_OVERLAPPING',
  ARE_NOT_CONTIGUOUS: 'ARE_NOT_CONTIGUOUS',
  INITIAL_FETCH: 'INITIAL_FETCH',
});

function __handleAvailabilityFetched(state, action) {
  if (
    action.metadata.rangeClassification ===
    RangeClassification.ADJACENT_OR_OVERLAPPING
  ) {
    // Merge
    let { start, end } = state.metadata;

    if (isBefore(action.metadata.start, start)) {
      ({ start } = action.metadata);
    }

    if (isAfter(action.metadata.end, end)) {
      ({ end } = action.metadata);
    }

    const mergedData = { ...state.data, ...action.data };
    const mergedMeta = { ...action.metadata, start, end };
    return {
      ...state,
      isFetching: false,
      fetchingError: null,
      data: mergedData,
      metadata: mergedMeta,
    };
  }
  // Replace
  return {
    ...state,
    isFetching: false,
    fetchingError: null,
    data: action.data,
    metadata: action.metadata,
  };
}

export const availabilityReducer = (
  state = {
    isFetching: false,
    fetchingError: null,
    data: null,
    metadata: null,
  },
  action,
) => {
  switch (action.type) {
    case AVAILABILITY_FETCHING:
      return { ...state, isFetching: true, fetchingError: null };

    case AVAILABILITY_FETCHED:
      return __handleAvailabilityFetched(state, action);

    case AVAILABILITY_FETCH_FAILED:
      return { ...state, isFetching: false, fetchingError: action.error };

    default:
      return state;
  }
};

/**
 * Check if the ranges between existing (state), and next (action) are next to each other, so that merging
 *  is appropriate.
 * @param {Object} existingStateMetadata the existing state metadata
 * @param {Object} existingStateMetadata.start the existing state metadata start
 * @param {Object} existingStateMetadata.endt the existing state metadata end
 * @param {Object} newActionMetadata the new state action metadata
 * @param {Object} newActionMetadata.start the new state action metadata start
 * @param {Object} newActionMetadata.end the new state action metadata end
 * @returns {RangeClassification} range classification.
 */
function __classifyDateRanges(existingStateMetadata, newActionMetadata) {
  // Verify the existing start and end
  const { start: existingStart, end: existingEnd } = existingStateMetadata;
  const { start: newStart, end: newEnd } = newActionMetadata; // Scenarios to consider:

  if (isBefore(newStart, existingStart) && isAfter(newEnd, existingEnd)) {
    // - New overlaps around old range.
    // Do not try to merge, as the next dates will override it anyway.
    return RangeClassification.NEW_AROUND_OLD;
  }

  if (!isBefore(newStart, existingStart) && !isAfter(newEnd, existingEnd)) {
    // - Old overlaps (or equals) around new range.
    // Should do nothing.
    return RangeClassification.OLD_AROUND_OR_EQUAL_NEW;
  }
  // Check if the odd dates are overlapping or not.
  let diff = 0;

  if (isAfter(newStart, existingEnd)) {
    // Check moving forward.
    diff = differenceInCalendarDays(newStart, existingEnd);
  } else if (isAfter(existingStart, newEnd)) {
    // Check moving backward.
    diff = differenceInCalendarDays(existingStart, newEnd);
  } // If one of these is greater than one day, then it indicates a gap.

  if (diff > 1) {
    return RangeClassification.ARE_NOT_CONTIGUOUS;
  }

  return RangeClassification.ADJACENT_OR_OVERLAPPING;
}

/**
 * Convert metadata to a query string for fetching.
 * @param {FetchAvailabilityMetadata} metadata
 */
function __getQueryFromMetadata(metadata) {
  let query = `?start=${metadata.start}&end=${metadata.end}&providerId=${
    metadata.providerId
  }&apptTypeId=${metadata.apptTypeId}`;

  if (metadata.duration) {
    query += `&duration=${metadata.duration}`;
  }

  if (metadata.forAppointmentId) {
    query += `&forAppointmentId=${metadata.forAppointmentId}`;
  }

  return query;
}

/**
 * Check if two objects have the same base metadata.
 * @param {Object} lhs
 * @param {String} lhs.providerId - required provider uuid
 * @param {String} lhs.apptTypeId - require appointment type uuid
 * @param {String} [lhs.duration] - optional duration
 * @param {String} [lhs.forAppointmentId] - optional appointment id for existing (appointment itself is available)
 * @param {Object} rhs
 * @param {String} rhs.providerId - required provider uuid
 * @param {String} rhs.apptTypeId - require appointment type uuid
 * @param {String} [rhs.duration] - optional duration
 * @param {String} [rhs.forAppointmentId] - optional appointment id for existing (appointment itself is available)
 */
function __isSameBaseMetadata(lhs, rhs) {
  return (
    (lhs.providerId || rhs.providerId
      ? lhs.providerId === rhs.providerId
      : false) &&
    (lhs.apptTypeId || rhs.apptTypeId
      ? lhs.apptTypeId === rhs.apptTypeId
      : false) &&
    lhs.duration === rhs.duration &&
    lhs.forAppointmentId === rhs.forAppointmentId
  );
}

function __doFetchAvailability(options, method) {
  return (dispatch, getState) => {
    const start = format(startOfMonth(options.targetDate), ISO_DATE_FORMAT);
    const end = format(lastDayOfMonth(options.targetDate), ISO_DATE_FORMAT);
    const newMeta = {
      start,
      end,
      providerId: options.providerId,
      apptTypeId: options.apptTypeId,
      duration: options.duration,
      forAppointmentId: options.forAppointmentId,
    };
    const { availability } = getState();

    let rangeClassification = RangeClassification.INITIAL_FETCH;

    if (availability && availability.metadata) {
      if (__isSameBaseMetadata(availability.metadata, newMeta)) {
        rangeClassification = __classifyDateRanges(
          availability.metadata,
          newMeta,
        );
      }
    }

    if (
      rangeClassification !== RangeClassification.OLD_AROUND_OR_EQUAL_NEW ||
      options.reFetch
    ) {
      newMeta.rangeClassification = rangeClassification;
      return options.shortName
        ? dispatch(method(options.shortName, newMeta))
        : dispatch(method(newMeta));
    }
    return undefined;
  };
}

function __authFetchAvailability(metadata) {
  return async dispatch => {
    dispatch({
      type: AVAILABILITY_FETCHING,
    });

    try {
      const availability = await availabilityApi.get(metadata);

      dispatch({
        type: AVAILABILITY_FETCHED,
        data: availability,
        metadata,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: AVAILABILITY_FETCH_FAILED,
        message: 'An error occurred.',
      });
    }
  };
}

/**
 * Authenticated way of getting availability from the server.
 * @param {GetAvailabilityOptions} options - options
 */
function authGetAvailability(options) {
  return dispatch =>
    new Promise((resolve, reject) => {
      try {
        const result = dispatch(
          __doFetchAvailability(options, __authFetchAvailability),
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
}

/**
 * Fetch availability from the unauthenticated api.
 * @param {String} tenantShortName - The tenant short name
 * @param {FetchAvailabilityMetadata} metadata - the fetch availability metadata.
 */
function __fetchAvailability(tenantShortName, metadata) {
  return async dispatch => {
    dispatch({
      type: AVAILABILITY_FETCHING,
    });

    try {
      const availability = await availabilityApi.getPublic(
        tenantShortName,
        metadata,
      );

      dispatch({
        type: AVAILABILITY_FETCHED,
        data: availability,
        metadata,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: AVAILABILITY_FETCH_FAILED,
        message: 'An error occurred.',
      });
    }
  };
}

/**
 * Un-authenticated way of getting availability from the server.
 * @param {GetAvailabilityOptions} options - options
 */
function getAvailability(options) {
  return dispatch =>
    new Promise((resolve, reject) => {
      try {
        const result = dispatch(
          __doFetchAvailability(options, __fetchAvailability),
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
}

/**
 * @typedef FetchAvailabilityMetadata
 * @property {String} start - Start date in ISO_DATE_FORMAT
 * @property {String} end - End date in ISO_DATE_FORMAT
 * @property {String} providerId - The uuid of the provider
 * @property {String} apptTypeId - The uuid of the appointment type
 * @property {Number} [duration] - The duration in milliseconds
 * @property {String} [forAppointmentId] - The uuid of the appointment this is for (optional).
 */

/**
 * @typedef GetAvailabilityOptions
 * @property {Date} targetDate - Date; will be converted to a range around the date.
 * @property {String} providerId - The uuid of the provider
 * @property {String} apptTypeId - The uuid of the appointment type
 * @property {Number} [duration] - The duration in milliseconds
 * @property {String} [forAppointmentId] - The uuid of the appointment this is for (optional).
 */

export {
  __doFetchAvailability,
  __authFetchAvailability,
  __fetchAvailability,
  authGetAvailability,
  getAvailability,
  __handleAvailabilityFetched,
  __classifyDateRanges,
  __isSameBaseMetadata,
  __getQueryFromMetadata,
};
