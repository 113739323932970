import '../neb-popup-header';

import { html, css } from 'lit';

import * as featureApi from '../../../../../src/api-clients/feature-flags';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import FormFeatureFlag from '../forms/neb-form-feature-flag';

import Overlay, { ELEMENTS as BASE_ELEMENTS } from './neb-overlay';

// Need to export ELEMENTS here to enable test:gen to pick these up
// eslint-disable-next-line import/no-unused-modules
export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
  header: { id: 'header' },
};

class OverlayFeatureFlag extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 800px;
        }

        .form {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__model = null;

    this.title = 'Feature Flag Form';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      delete: async model => {
        const res = await featureApi.remove(model);

        if (res) {
          this.handlers.dirty(false);
          this.dismiss(res);
        }
      },
      save: async model => {
        const res = await featureApi.create(model);

        if (res) {
          this.handlers.dirty(false);
          this.dismiss(res);
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__model = this.model.item || FormFeatureFlag.createModel();
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-feature-flag
        id="${ELEMENTS.form.id}"
        class="form"
        .model="${this.__model}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onDelete="${this.handlers.delete}"
        .onSave="${this.handlers.save}"
      ></neb-form-feature-flag>
    `;
  }
}

window.customElements.define('neb-overlay-feature-flag', OverlayFeatureFlag);
