import '../../../../src/components/misc/neb-icon';
import './neb-toggle-button';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  removeIcon: {
    id: 'icon-remove',
  },
  cells: {
    selector: '.cell',
  },
  chevron: {
    id: 'chevron',
  },
  columnContainer: {
    id: 'column-container',
  },
  expandedContent: {
    id: 'expanded-content',
  },
};
export class NebExpandableRow extends LitElement {
  static get properties() {
    return {
      index: Number,
      model: Object,
      config: Array,
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      __expanded: {
        reflect: true,
        type: Boolean,
        attribute: 'expanded',
      },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.showRemoveButton = false;
    this.index = -1;
    this.topic = '';
    this.__expanded = false;
    this.model = {};
    this.config = [
      {
        key: '',
        flex: css`0 0 80px`,
      },
    ];

    this.onRemove = () => {};

    this.onExpand = () => {};
  }

  initHandlers() {
    this.handlers = {
      remove: () => this.onRemove(this.model, this.index),
      toggleExpand: () => {
        this.__expanded = !this.__expanded;
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          background-color: ${CSS_COLOR_WHITE};

          --cell-spacing: ${CSS_SPACING};
        }

        :host([expanded]) {
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([expanded]) .icon-chevron {
          transform: rotate(180deg);
        }

        .container {
          display: flex;
          flex-direction: column;
          padding: 10px var(--cell-spacing);
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          font-size: ${CSS_FONT_SIZE_BODY};
          min-height: 48px;
        }

        .content-container {
          display: flex;
          width: 100%;
          align-items: center;
        }

        .cell {
          display: flex;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          padding: 0;
        }

        .cell:last-child {
          margin-right: 0;
        }

        .column-container {
          display: flex;
          flex: 1 0 0;
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .icon-remove {
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          margin: 0 ${CSS_SPACING};
        }

        .icon-chevron {
          width: 11px;
          height: 11px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }
      `,
    ];
  }

  renderColumn(value, configItem) {
    return configItem.formatter ? configItem.formatter(value) : value;
  }

  renderExpandedContent() {
    return html``;
  }

  __renderRemoveButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeIcon.id}"
            class="icon icon-remove"
            icon="neb:minus"
            @click="${this.handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  __renderChevronButton() {
    return html`
      <neb-icon
        id="${ELEMENTS.chevron.id}"
        class="icon icon-chevron"
        icon="neb:chevron"
        @click="${this.handlers.toggleExpand}"
      ></neb-icon>
    `;
  }

  __renderColumns() {
    return this.config.map(
      item => html`
        <div class="cell" style="flex: ${item.flex}">
          ${this.renderColumn(this.model[item.key], item)}
        </div>
      `,
    );
  }

  __renderContent() {
    return this.__expanded
      ? html`
          <div id="${ELEMENTS.expandedContent.id}">
            ${this.renderExpandedContent()}
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="content-container">
          <div
            id="${ELEMENTS.columnContainer.id}"
            class="column-container"
            @click="${this.handlers.toggleExpand}"
          >
            ${this.__renderColumns()}
          </div>

          ${this.__renderRemoveButton()} ${this.__renderChevronButton()}
        </div>
        ${this.__renderContent()}
      </div>
    `;
  }
}
