import { FiservReader } from './fiserv-reader';

export const STATE = Object.freeze({
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  PROCESSING: 'processing',
  DEFAULT: 'default',
});

export const fiservReaderActivate = ({
  readerId,
  setState,
  onSave,
  paymentDetails,
}) => {
  const reader = new FiservReader({
    readerId,
    setState,
    onSave,
  });

  reader.activate(paymentDetails);

  return reader;
};
