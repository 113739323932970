import {
  SCHEDULED_PAYMENT_TYPE,
  SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP,
} from '../../../packages/neb-utils/enums';

export function paymentScheduleType() {
  return (item, selection) =>
    selection === SCHEDULED_PAYMENT_TYPE.EMPTY
      ? true
      : SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP[item.scheduleType] === selection;
}

export function dismissPaymentAlerts() {
  return (item, selection) => (selection ? !item.dismissedAt : true);
}
