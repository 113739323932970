import { isPopupCollapsible } from '../../neb-popup/src/renderers';

import { ConnectedService } from './connected-service';

export class CollapsingPopupService extends ConnectedService {
  constructor(callback) {
    super({
      popups: 'popup.main',
      layout: 'layoutMedia.layout',
    });

    this.onStateChanged = callback;
  }

  shouldPopupCollapse(popups, layout) {
    return (
      popups.length &&
      isPopupCollapsible(popups[popups.length - 1].key) &&
      layout === 'small'
    );
  }

  _stateChanged({ popups, layout }) {
    if (this.shouldPopupCollapse(popups, layout)) {
      return this.onStateChanged(true);
    }
    return this.onStateChanged(false);
  }
}
