import {
  getPatientPackageUsedCounts,
  getActivePatientPackagesOrdered,
  getPatientPackages,
} from '../../src/api-clients/patient-package';
import * as patientApiClient from '../neb-api-client/src/patient-api-client';

import { parseDate } from './date-util';
import {
  PACKAGE_TYPE,
  CARE_PACKAGE_STATUS,
  PACKAGE_FREQUENCY_DESCRIPTION_MAP,
  PAYMENT_FREQUENCY,
} from './enums';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  currencyToCents,
  HIDE_PREFERRED_OPTS,
} from './formatters';
import { buildPackageTemplateSummary } from './neb-package-template-util';
import { uniq } from './utils';

export const createModel = () => ({
  id: '',
  active: true,
  patientId: '',
  name: '',
  type: PACKAGE_TYPE.VISIT,
  subscription: false,
  unlimited: false,
  frequency: PAYMENT_FREQUENCY.MONTHLY,
  effectiveDate: null,
  expirationDate: null,
  completedDate: null,
  totalVisits: '',
  totalPrice: '',
  charges: [],
  encounterCharges: [],
  sharedCarePackage: false,
  patientPackageRelatedPatients: null,
  isDefault: false,
});

export const mapChargesToPatientPackageCharges = charges => {
  const mappedCharges = charges.map(charge => {
    const quantity = charge.units ? `${charge.units}` : '0';

    return {
      id: charge.chargeId,
      quantity,
      chargeId: charge.chargeId,
      active: true,
      charge: {
        amount: centsToCurrency(charge.amount),
        description: charge.description,
        procedure: charge.procedure,
        modifiers: charge.modifiers,
      },
    };
  });

  return mappedCharges;
};

export const mapPatientPackageChargesToCharges = charges => {
  const mappedCharges = charges.map(charge => ({
    chargeId: charge.chargeId,
    units: charge.quantity,
    active: true,
    amount: currencyToCents(charge.charge.amount),
    description: charge.charge.description,
    procedure: charge.charge.procedure,
    modifiers: charge.charge.modifiers,
  }));

  return mappedCharges;
};

export const chargesFormatters = {
  format: charges => {
    const mappedCharges = charges.map(charge => {
      const quantity = charge.units ? `${charge.units}` : '0';
      return {
        id: charge.chargeId,
        quantity,
        chargeId: charge.chargeId,
        active: true,
        charge: {
          amount: centsToCurrency(charge.charge.amount),
          description: charge.charge.description,
          procedure: charge.charge.procedure,
          modifiers: charge.charge.modifiers,
        },
      };
    });
    return mappedCharges;
  },
  unformat: charges => {
    const mappedCharges = charges.map(charge => ({
      id: charge.chargeId,
      units: charge.quantity,
      chargeId: charge.chargeId,
      charge: {
        amount: currencyToCents(charge.charge.amount),
        description: charge.charge.description,
        procedure: charge.charge.procedure,
        modifiers: charge.charge.modifiers,
      },
    }));
    return mappedCharges;
  },
};

export const formatUsageUnit = (displayUnit, count) =>
  `${displayUnit}${count === 1 ? '' : 's'}`;

const datesAreOnSameDay = (first, second) =>
  first.format('YYYY') === second.format('YYYY') &&
  first.format('M') === second.format('M') &&
  first.format('D') === second.format('D');

const dateWithinRange = (date, start, end) => {
  if (end && datesAreOnSameDay(parseDate(end), parseDate(date))) {
    return CARE_PACKAGE_STATUS.VALID;
  }

  if (start && datesAreOnSameDay(parseDate(start), parseDate(date))) {
    return CARE_PACKAGE_STATUS.VALID;
  }

  if (end && parseDate(end).format() <= parseDate(date).format()) {
    return CARE_PACKAGE_STATUS.EXPIRED;
  }

  if (start && parseDate(start).format() > parseDate(date).format()) {
    return CARE_PACKAGE_STATUS.NOTVALID;
  }

  return CARE_PACKAGE_STATUS.VALID;
};

export const getCarePackageStatus = (patientPackage, date) => {
  const { active, effectiveDate, expirationDate } = patientPackage;

  if (!active) return CARE_PACKAGE_STATUS.COMPLETED;

  return dateWithinRange(date, effectiveDate, expirationDate);
};

export const getSharedCarePackageHistory = (
  patientPackage,
  dates,
  patientId,
) => {
  const { patientPackageRelatedPatients } = patientPackage;

  if (!patientPackageRelatedPatients.length) return false;

  const [{ histories }] = patientPackageRelatedPatients.filter(
    pp => pp.relatedPatientId === patientId,
  );

  return histories.some(history =>
    dates.some(
      date =>
        dateWithinRange(date, history.start, history.end) ===
        CARE_PACKAGE_STATUS.VALID,
    ),
  );
};

export const mapToPatientPackageModel = (raw, usedCount) => {
  const { type, unlimited, patientPackageRelatedPatients } = raw;
  const { visitsRemaining, unitsRemaining } = usedCount;
  const status = getCarePackageStatus(raw, new Date());
  const sharedPackage =
    patientPackageRelatedPatients && patientPackageRelatedPatients.length > 0
      ? 'Yes'
      : 'No';

  let remaining;

  if (type === PACKAGE_TYPE.VISIT) {
    if (unlimited) {
      remaining = 'Unlimited visits';
    } else {
      remaining = `${visitsRemaining} ${formatUsageUnit(
        'visit',
        visitsRemaining,
      )}`;
    }
  }

  if (type === PACKAGE_TYPE.SERVICE) {
    remaining = `${unitsRemaining} ${formatUsageUnit('unit', unitsRemaining)}`;
  }

  return {
    ...raw,
    status,
    summary: raw.summary || buildPackageTemplateSummary(raw),
    remaining,
    usedCount,
    sharedPackage,
  };
};

export const formatFrequencyPhrase = ({ subscription, frequency }) =>
  subscription
    ? ` ${
        Object.keys(PACKAGE_FREQUENCY_DESCRIPTION_MAP).includes(frequency)
          ? PACKAGE_FREQUENCY_DESCRIPTION_MAP[frequency]
          : 'per period'
      }`
    : '';

export const getSummaryDescription = carePackage => {
  const { type, unlimited, totalVisits, totalUnits, totalPrice } = carePackage;

  let result;

  if (type === PACKAGE_TYPE.VISIT) {
    result = `${totalVisits} ${formatUsageUnit('visit', totalVisits)}`;
  }

  if (type === PACKAGE_TYPE.SERVICE) {
    result = `${totalUnits} ${formatUsageUnit('service', totalUnits)}`;
  }

  const frequencyDescription = formatFrequencyPhrase(carePackage);

  if (unlimited) {
    return `Unlimited visits for ${totalPrice}${frequencyDescription}`;
  }

  return `${result} for ${totalPrice}${frequencyDescription}`;
};

const getRenderedDescription = carePackage => {
  const { type, visitsUsed, unitsUsed } = carePackage;

  const renderedValue = type === PACKAGE_TYPE.VISIT ? visitsUsed : unitsUsed;
  return `${renderedValue >= 0 ? renderedValue : 0} rendered`;
};

const getRemainingDescription = carePackage => {
  const { type, subscription, unlimited, visitsRemaining, unitsRemaining } =
    carePackage;

  if (unlimited) return '';
  const remainingValue =
    type === PACKAGE_TYPE.VISIT ? visitsRemaining : unitsRemaining;

  return `
    ${remainingValue}
    ${subscription ? 'remaining this period' : 'remaining'}
  `;
};

const mapCarePackageUsedCount = (carePackage, usedCount) => ({
  type: carePackage.type,
  unlimited: carePackage.unlimited,
  subscription: carePackage.subscription,
  frequency: carePackage.frequency,
  totalPrice: carePackage.totalPrice,
  totalVisits: usedCount.totalVisits,
  totalUnits: usedCount.totalUnits,
  visitsRemaining: usedCount.visitsRemaining,
  unitsRemaining: usedCount.unitsRemaining,
  visitsUsed: usedCount.visitsUsed,
  unitsUsed: usedCount.unitsUsed,
});

export const getDescriptionLines = (carePackage, usedCount) => {
  const description = [];

  if (carePackage === null) return '';

  const carePackageObject = mapCarePackageUsedCount(carePackage, usedCount);

  if (carePackage.sharedCarePackage && carePackage.ownerName) {
    description.push(`Owner: ${carePackage.ownerName}`);
  }

  description.push(getSummaryDescription(carePackageObject));

  description.push(getRenderedDescription(carePackageObject));

  description.push(getRemainingDescription(carePackageObject));

  return description;
};

export const mapSharedHistory = ({ patientPackageRelatedPatients }) =>
  patientPackageRelatedPatients
    ? patientPackageRelatedPatients.reduce((memo, relatedPatient) => {
        memo[relatedPatient.relatedPatientId] = relatedPatient;

        return memo;
      }, {})
    : {};

export const hasSharedHistory = ({ patientPackageRelatedPatients }) =>
  patientPackageRelatedPatients
    ? patientPackageRelatedPatients.length > 0
    : false;

const formatRelatedPatientsToItems = items =>
  items.map(data => ({
    label: objToName(data.name, DEFAULT_NAME_OPTS),
    data,
  }));

export const buildFormattedRelationships = (
  model,
  patientNames,
  activeRelationshipsGroup,
) => {
  const { patientId: packageOwnerPatientId, patientPackageRelatedPatients } =
    model;

  const ownerIndex = patientNames.findIndex(
    p => p.id === packageOwnerPatientId,
  );

  const primaryIndex = patientNames.findIndex(
    p => p.id === activeRelationshipsGroup.primary,
  );

  const relationships = formatRelatedPatientsToItems(patientNames);

  if (primaryIndex === ownerIndex) {
    relationships[ownerIndex].label = `${
      relationships[ownerIndex].label
    } - Primary, Owner`;
  } else {
    relationships[ownerIndex].label = `${
      relationships[ownerIndex].label
    } - Owner`;

    relationships[primaryIndex].label = `${
      relationships[primaryIndex].label
    } - Primary`;
  }

  const relatedPatientIds = patientPackageRelatedPatients
    .filter(p => p.active)
    .map(pprp => pprp.relatedPatientId);

  const relationshipsToSelect = relationships.filter(
    (relationship, index) =>
      index === ownerIndex || relatedPatientIds.includes(relationship.data.id),
  );

  relationships[ownerIndex].disabled = true;

  return { relationshipsToSelect, relationships };
};

async function addSharedByName(data) {
  const uniquePatients = uniq(data.map(item => item.patientId));
  const patients = await patientApiClient.fetchSome(uniquePatients);

  const newData = data.map(row => ({
    ...row,
    sharedByName:
      row.patientPackageRelatedPatients.filter(p => p.active).length > 0
        ? objToName(
            patients[patients.findIndex(p => p.id === row.patientId)].name,
            HIDE_PREFERRED_OPTS,
          )
        : '',
  }));

  return newData;
}

export async function getActivePatientPackages(id) {
  const patientPackages = await getActivePatientPackagesOrdered(id);

  const ids = patientPackages.map(pkg => pkg.id);
  const usedCounts = await getPatientPackageUsedCounts(id, ids, null, true);

  const results = patientPackages.map(entry => {
    const match = usedCounts.find(pkg => pkg.patientPackageId === entry.id);
    return mapToPatientPackageModel(entry, match);
  });

  return addSharedByName(results);
}

export async function getPatientPackagesWithCounts(id) {
  const patientPackages = await getPatientPackages(id, { includeShared: true });
  const ids = patientPackages.map(pkg => pkg.id);
  const usedCounts = await getPatientPackageUsedCounts(id, ids, null, true);

  return patientPackages.map(entry => {
    const match = usedCounts.find(pkg => pkg.patientPackageId === entry.id);
    return mapToPatientPackageModel(entry, match);
  });
}
