import './neb-treatment-plan-table';

import { LitElement, html, css } from 'lit';

import { store, connect } from '../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../neb-styles/neb-variables';

import { NebTreatmentPlanState } from './neb-treatment-plan-state';

export const ID_TITLE = 'title';
export const ID_ACTIVE_TP = 'id-treatment-plan';

class NebActiveTreatmentPlan extends NebTreatmentPlanState(
  connect(store)(LitElement),
) {
  static get styles() {
    return css`
      :host {
        display: block;
        border: ${CSS_BORDER_GREY_2};
        padding: 10px 0;
        margin: ${CSS_SPACING} 0;
      }

      .title {
        margin: 10px;
        color: ${CSS_COLOR_HIGHLIGHT};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        text-decoration: underline;
      }
    `;
  }

  _shouldNotifyParent() {
    return false;
  }

  __getCount() {
    const count = this._activeTreatmentPlan.treatmentPhases.length;
    return count ? `(${count})` : '';
  }

  render() {
    return html`
      <div id="${ID_TITLE}" class="title">
        Treatment Plan ${this.__getCount()}
      </div>
      <neb-treatment-plan-table
        summary
        id="${ID_ACTIVE_TP}"
        .items="${this._activeTreatmentPlan.treatmentPhases}"
      ></neb-treatment-plan-table>
    `;
  }
}

customElements.define('neb-active-treatment-plan', NebActiveTreatmentPlan);
