import { ADD_ONS, hasAddOn } from './add-ons';

export const ONE_DAY = 1;
export const SEVEN_DAYS = 7;

export const PERIODS = [
  { data: { id: 1 * ONE_DAY }, label: '1 day prior' },
  { data: { id: 2 * ONE_DAY }, label: '2 days prior' },
  { data: { id: 3 * ONE_DAY }, label: '3 days prior' },
];
export const RESERVED_PRACTICE_IDENTIFIERS = [
  'waystar',
  '263923727000000',
  'zirmed',
];

export const BANNER_MESSAGE = {
  success: 'Clearinghouse saved successfully',
  error: 'An error occurred when saving Clearinghouse',
  connectionError:
    'An error occurred when saving Clearinghouse: Unable to connect to SFTP',
  payerUnavailable: 'Payer is already associated to other Clearinghouse',
};

export const FLEXCLEAR_CLEARINGHOUSE = {
  ABILITY: 'Ability',
  APEX: 'Apex',
  AVAILITY: 'Availity',
  HNS: 'HNS',
  INFINEDI: 'Infinedi',
  OFFICE_ALLY: 'Office Ally',
  STANDARD: 'Standard',
};

export const CLEARINGHOUSES = {
  TRIZETTO: 'TriZetto',
  WAYSTAR: 'Waystar',
  CHC: 'Change Healthcare',
  ...FLEXCLEAR_CLEARINGHOUSE,
};

export const SFTP_UNAVAILABLE = [CLEARINGHOUSES.HNS];

export const CLEARINGHOUSE_ITEMS = Object.values(FLEXCLEAR_CLEARINGHOUSE);

export const hasByocAddons = async () => ({
  CT_MAXCLEAR: await hasAddOn(ADD_ONS.CT_MAXCLEAR),
  CT_PROCLEAR_TRIZETTO: await hasAddOn(ADD_ONS.CT_PROCLEAR_TRIZETTO),
  CT_PROCLEAR_WAYSTAR: await hasAddOn(ADD_ONS.CT_PROCLEAR_WAYSTAR),
  CT_FLEXCLEAR: await hasAddOn(ADD_ONS.CT_FLEXCLEAR),
  CT_VERIFY: await hasAddOn(ADD_ONS.CT_VERIFY),
});

export const hasByoc = async () => {
  const byocAddon = await hasByocAddons();
  return Object.values(byocAddon).some(item => item === true);
};

export const CLEARINGHOUSE_PRESETS = {
  [CLEARINGHOUSES.TRIZETTO]: {
    sftpPathClaim: 'claims',
    sftpPathEra: 'remits',
    sftpPathAcknowledgement: '997',
    sftpPath277: '277',
    sftpHost: 'sftp.gatewayedi.com',
    sftpPort: '22',
    clearinghouseIdentifierId: '263923727000000',
    clearinghouseEtin: '263923727000000',
    clearinghouseApplicationCode: '263923727',
    practiceIdentifierIdPlaceholder: 'TriZetto Site ID',
    practiceEtinPlaceholder: 'TriZetto Site ID',
    practiceApplicationCodePlaceholder: 'TriZetto Site ID',
  },
  [CLEARINGHOUSES.WAYSTAR]: {
    sftpPathClaim: 'upload',
    sftpPathEra: 'download',
    sftpPathAcknowledgement: 'download',
    sftpPath277: 'download',
    sftpHost: 'sshftp.waystar.com',
    sftpPort: '22',
    clearinghouseIdentifierId: 'WayStar',
    clearinghouseEtin: 'WayStar',
    clearinghouseApplicationCode: 'WayStar',
    practiceIdentifierIdPlaceholder: 'Waystar Site ID',
    practiceEtinPlaceholder: 'Waystar Site ID',
    practiceApplicationCodePlaceholder: 'Waystar Site ID',
    customExtension: 'CLP',
  },
  [CLEARINGHOUSES.OFFICE_ALLY]: {
    sftpPathClaim: 'inbound',
    sftpPathEra: 'outbound',
    sftpPathAcknowledgement: 'outbound',
    sftpPath277: 'outbound',
    sftpHost: 'ftp10.officeally.com',
    sftpPort: '22',
    clearinghouseIdentifierId: '330897513',
    clearinghouseEtin: '330897513',
    clearinghouseApplicationCode: 'OA',
    practiceIdentifierIdPlaceholder: 'Tax ID',
    practiceEtinPlaceholder: 'Tax ID',
    practiceApplicationCodePlaceholder: 'Tax ID',
  },
  [CLEARINGHOUSES.ABILITY]: {
    clearinghouseIdentifierId: 'ABILITY837',
    clearinghouseEtin: 'ABILITY837',
    clearinghouseApplicationCode: 'ABILITY837',
    practiceIdentifierIdPlaceholder: 'Tax ID',
    practiceEtinPlaceholder: 'Tax ID',
    practiceApplicationCodePlaceholder: 'Tax ID',
  },
  [CLEARINGHOUSES.APEX]: {
    sftpPathClaim: 'inbound',
    sftpPathEra: 'outbound',
    sftpPathAcknowledgement: 'outbound',
    sftpPath277: 'outbound',
    sftpHost: 'sftp.apexedi.com',
    sftpPort: '22',
    clearinghouseIdentifierId: 'ApexEdi',
    clearinghouseEtin: 'ApexEdi',
    clearinghouseApplicationCode: 'ApexEdi',
    practiceIdentifierIdPlaceholder: 'Tax ID',
    practiceEtinPlaceholder: 'Tax ID',
    practiceApplicationCodePlaceholder: 'Tax ID',
  },
  [CLEARINGHOUSES.AVAILITY]: {
    sftpPathClaim: 'SendFiles',
    sftpPathEra: 'ReceiveFiles',
    sftpPathAcknowledgement: 'ReceiveFiles',
    sftpPath277: 'ReceiveFiles',
    sftpHost: 'ftp.availity.com',
    sftpPort: '9922',
    clearinghouseIdentifierId: '030240928',
    clearinghouseEtin: '030240928',
    clearinghouseApplicationCode: '030240928',
    practiceIdentifierIdPlaceholder: 'AV09311993',
    practiceIdentifierId: 'AV09311993',
    practiceEtinPlaceholder: 'Availtiy Customer ID',
    practiceApplicationCodePlaceholder: '5010',
    practiceApplicationCode: '5010',
    submitterContactOverride: true,
  },
  [CLEARINGHOUSES.HNS]: {
    clearinghouseIdentifierId: '561971088',
    clearinghouseEtin: '561971088',
    clearinghouseApplicationCode: '561971088',
    practiceIdentifierIdPlaceholder: 'Tax ID',
    practiceEtinPlaceholder: 'Tax ID',
    practiceApplicationCodePlaceholder: 'Tax ID',
  },
  [CLEARINGHOUSES.INFINEDI]: {
    sftpPathClaim: '837',
    sftpPathEra: '835',
    sftpPathAcknowledgement: '999',
    sftpPath277: '277',
    sftpHost: 'sftp2.infinedi.net',
    sftpPort: '22',
    clearinghouseIdentifierId: '731572635',
    clearinghouseEtin: '731572635',
    clearinghouseApplicationCode: '731572635',
    practiceIdentifierIdPlaceholder: 'Tax ID',
    practiceEtinPlaceholder: 'Tax ID',
    practiceApplicationCodePlaceholder: 'Tax ID',
  },
};
