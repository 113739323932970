import equal from 'fast-deep-equal';

import { openError } from '../../../neb-dialog/neb-banner-state';
import { store } from '../../../neb-redux/neb-redux-store';
import { formatEncounter } from '../../../neb-utils/neb-charting-util';
import { formatEncounterSummaryData } from '../../../neb-utils/neb-encounters-util';
import { getEncounterCharges } from '../charting/encounter-charge';
import {
  getEncounter,
  getEncounterDiagnoses,
  getEncounterHistory,
} from '../encounters-api-client';
import { fetchNotes } from '../notes';
import * as patientApiClient from '../patient-api-client';

export const ENCOUNTER = 'encounter';
export const CHARGES = 'charges';
export const DIAGNOSES = 'diagnoses';
export const NOTES = 'notes';
export const HISTORY = 'history';
const OPTIONS = [ENCOUNTER, CHARGES, DIAGNOSES, NOTES, HISTORY];
export const ENCOUNTER_DATA_FETCH_ERROR =
  'An error occurred while fetching encounter data';

const __getChartNotes = encounterId => {
  const initialNotes = {
    subjective: '',
    objective: '',
    assessment: '',
    plan: '',
  };

  return fetchNotes(initialNotes, encounterId);
};

export class EncounterDataService {
  constructor(callback) {
    this.onEncounterIdChanged = callback;
  }

  async update(encounterId, options = OPTIONS) {
    const encounterData = await this.__getEncounterData(encounterId, options);
    this.onEncounterIdChanged(encounterData);
  }

  async sync(
    syncUnformattedEncounterSrc,
    syncFormattedEncounterDest,
    options = OPTIONS,
  ) {
    const {
      providers: { item: providers },
      appointmentTypes: { items: apptTypes },
    } = store.getState();
    const syncFormattedEncounterSrc = formatEncounter(
      syncUnformattedEncounterSrc,
      providers,
      apptTypes,
    );

    const isEqual = this.__compareEncounterInfo(
      syncFormattedEncounterSrc,
      syncFormattedEncounterDest,
    );

    if (!isEqual) await this.update(syncFormattedEncounterSrc.id, options);
  }

  async __getEncounterData(encounterId, options) {
    const dataRequests = {
      [ENCOUNTER]: id => this.__getFormattedEncounter(id),
      [CHARGES]: id => getEncounterCharges(id, true),
      [DIAGNOSES]: id => getEncounterDiagnoses(id, true),
      [NOTES]: id => this.__getEncounterSummaryData(id),
      [HISTORY]: id => this.__getEncounterHistory(id),
    };
    const encounterData = {};

    try {
      await Promise.all(
        options.map(async dataProp => {
          encounterData[dataProp] = await dataRequests[dataProp](encounterId);
        }),
      );
    } catch (e) {
      console.error(e);
      store.dispatch(openError(ENCOUNTER_DATA_FETCH_ERROR));
    }

    return encounterData;
  }

  __getEncounter(id) {
    const { encounters } = store.getState();

    return (encounters && encounters[id]) || getEncounter(id);
  }

  async __getFormattedEncounter(id) {
    const encounter = await this.__getEncounter(id);

    const promiseData = await new Promise(resolve => {
      const intervalId = setInterval(() => {
        const {
          providers: { item: providers },
          appointmentTypes: { items: apptTypes },
        } = store.getState();

        if (
          this.__checkProviders(providers) &&
          this.__checkApptTypes(apptTypes)
        ) {
          clearInterval(intervalId);

          resolve({ providers, apptTypes });
        }
      }, 0);
    });

    const { providers, apptTypes } = promiseData;

    return formatEncounter(encounter, providers, apptTypes);
  }

  async __getEncounterSummaryData(id) {
    const encounter = await this.__getEncounter(id);
    const chartNotes = await __getChartNotes(id);

    const patient = await patientApiClient.fetchOne(
      encounter.patientId,
      false,
      true,
    );

    return formatEncounterSummaryData({
      encounter,
      notes: chartNotes,
      patient,
      state: store.getState(),
    });
  }

  __getEncounterHistory(id) {
    return getEncounterHistory(id, true);
  }

  __checkProviders(providers) {
    return providers && providers.length;
  }

  __checkApptTypes(apptTypes) {
    return apptTypes && apptTypes.length;
  }

  __compareEncounterInfo(encounter1, encounter2) {
    return equal(
      this.__getRelevantEncounterInfo(encounter1),
      this.__getRelevantEncounterInfo(encounter2),
    );
  }

  __getRelevantEncounterInfo({
    appointmentId,
    appointmentType,
    provider,
    fullDate,
    encounterNumber,
  }) {
    return {
      appointmentId,
      appointmentType,
      provider,
      fullDate,
      encounterNumber,
    };
  }
}
