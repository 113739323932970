import './neb-ripple-button';
import '../../../../src/components/misc/neb-icon';

import { html, LitElement, css } from 'lit';

import {
  SLIDE_DIRECTION,
  slideDown,
  slideUp,
} from '../../../neb-styles/neb-animation';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_3,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';
import { MONTH_NAMES } from '../../../neb-utils/neb-cal-util';

const ITEM_WIDTH = css`36px`;

const DEFAULT_DATE = () => parseDate();

const PICKER_OFFSETS = [-1, 0, 1, 2];
export const ELEMENTS = {
  monthPicker: {
    id: 'picker-month',
  },
  pickerMonthDecrementButton: {
    id: 'button-picker-month-decrement',
  },
  pickerMonthIncrementButton: {
    id: 'button-picker-month-increment',
  },
  yearPicker: {
    id: 'picker-year',
  },
  pickerYearDecrementButton: {
    id: 'button-picker-year-decrement',
  },
  pickerYearIncrementButton: {
    id: 'button-picker-year-increment',
  },
  okButton: {
    id: 'button-ok',
  },
  cancelButton: {
    id: 'button-cancel',
  },
  pickerMonthButton: {
    id: 'button-picker-month-',
  },
  pickerYearButton: {
    id: 'button-picker-year-',
  },
  containerToolbarPicker: {
    id: 'container-toolbar-picker',
  },
};

class NebCalendarMonthYearPicker extends LitElement {
  static get properties() {
    return {
      selectedMonth: Number,
      selectedYear: Number,
      __pickerMonthSlideDirection: String,
      __pickerYearSlideDirection: String,
      __pickerDisplayMonth: Number,
      __pickerDisplayYear: Number,
      __pickerSelectedMonth: Number,
      __pickerSelectedYear: Number,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.selectedMonth = DEFAULT_DATE().month();
    this.selectedYear = DEFAULT_DATE().year();
    this.__pickerMonthSlideDirection = '';
    this.__pickerYearSlideDirection = '';
    this.__pickerDisplayMonth = this.selectedMonth;
    this.__pickerSelectedMonth = this.selectedMonth;
    this.__pickerDisplayYear = this.selectedYear;
    this.__pickerSelectedYear = this.selectedYear;

    this.onChange = () => {};

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      animationEnd: () => {
        if (this.__pickerMonthSlideDirection) {
          this.__pickerMonthSlideDirection = '';
        } else if (this.__pickerYearSlideDirection) {
          this.__pickerYearSlideDirection = '';
        }
      },
      decrementPickerMonth: () => {
        const value = this.__pickerDisplayMonth - 4;
        this.__pickerDisplayMonth = this.__handleModulo(value);
        this.__pickerMonthSlideDirection = SLIDE_DIRECTION.DOWN;
      },
      incrementPickerMonth: () => {
        const value = this.__pickerDisplayMonth + 4;
        this.__pickerDisplayMonth = this.__handleModulo(value);
        this.__pickerMonthSlideDirection = SLIDE_DIRECTION.UP;
      },
      decrementPickerYear: () => {
        this.__pickerDisplayYear -= 4;
        this.__pickerYearSlideDirection = SLIDE_DIRECTION.DOWN;
      },
      incrementPickerYear: () => {
        this.__pickerDisplayYear += 4;
        this.__pickerYearSlideDirection = SLIDE_DIRECTION.UP;
      },
      setPickerMonth: e => {
        this.__pickerSelectedMonth = e.currentTarget.value;
      },
      setPickerYear: e => {
        this.__pickerSelectedYear = e.currentTarget.value;
      },
      setMonthYear: () => {
        this.onChange(
          this.__pickerSelectedMonth + 1,
          this.__pickerSelectedYear,
        );
      },
      cancel: () => {
        this.onCancel();
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      slideDown,
      slideUp,
      css`
        :host {
          width: 100%;
          height: 100%;
          background: ${CSS_COLOR_WHITE};
          display: flex;
          flex-direction: column;
        }

        .container-picker {
          display: flex;
          flex: 1 0 0;
        }

        .icon {
          position: relative;
          width: 14px;
          height: 14px;
          z-index: 1;
          margin: 13px 0 0 13px;
        }

        .button {
          cursor: pointer;
          outline: none;
          border: 0;
          background-color: transparent;
          text-align: center;
        }

        .button-page {
          cursor: pointer;
          width: ${ITEM_WIDTH};
          height: ${ITEM_WIDTH};
        }

        .button-up {
          transform: rotateZ(180deg);
        }

        .content {
          position: relative;
        }

        .picker-month[slide=${SLIDE_DIRECTION.DOWN}],
      .picker-year[slide=${SLIDE_DIRECTION.DOWN}] {
          animation: slide-down 0.4s linear forwards;
          -webkit-animation: slide-down 0.4s linear forwards;
        }

        .picker-month[slide=${SLIDE_DIRECTION.UP}],
      .picker-year[slide=${SLIDE_DIRECTION.UP}] {
          animation: slide-up 0.4s linear forwards;
          -webkit-animation: slide-up 0.4s linear forwards;
        }

        .picker {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .button-picker {
          align-items: center;
          background-color: transparent;
          border-radius: 15px;
          cursor: pointer;
          display: flex;
          height: 30px;
          outline: none;
          padding: 0 ${CSS_SPACING};
          user-select: none;
        }

        .button-picker[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        .button-picker:not([selected]):hover {
          cursor: pointer;
          background-color: ${CSS_COLOR_GREY_3};
        }

        .button-label {
          display: block;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-align: right;
          text-decoration: underline;
          font-size: 14px;
          padding: 10px 0;
        }

        .toolbar {
          padding: 10px ${CSS_SPACING} ${CSS_SPACING};
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }

        .toolbar > .button-label {
          margin: 0 0 0 10px;
        }

        .chevron-icon {
          fill: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('selectedMonth')) {
      this.__pickerSelectedMonth = this.selectedMonth - 1;
      this.__pickerDisplayMonth = this.__pickerSelectedMonth;
    }

    if (changedProps.has('selectedYear')) {
      this.__pickerSelectedYear = this.selectedYear;
      this.__pickerDisplayYear = this.__pickerSelectedYear;
    }

    super.update(changedProps);
  }

  __handleModulo(value) {
    const monthValue = value % MONTH_NAMES.length;
    return monthValue >= 0 ? monthValue : monthValue + MONTH_NAMES.length;
  }

  connectedCallback() {
    super.connectedCallback();
    const handler = this.__handlers.animationEnd;
    this.shadowRoot.addEventListener('animationend', handler);
    this.shadowRoot.addEventListener('webkitAnimationEnd', handler);
  }

  __renderMonthPickerRows() {
    return PICKER_OFFSETS.map(offset => {
      const monthValue = this.__handleModulo(
        this.__pickerDisplayMonth + offset,
      );

      const displayMonthValue = this.__handleModulo(monthValue);

      return html`
        <div
          id="${ELEMENTS.pickerMonthButton.id}${monthValue}"
          class="button-picker picker-month"
          slide="${this.__pickerMonthSlideDirection}"
          ?selected="${this.__pickerSelectedMonth === monthValue}"
          @click="${this.__handlers.setPickerMonth}"
          .value="${monthValue}"
        >
          ${MONTH_NAMES[displayMonthValue]}
        </div>
      `;
    });
  }

  __renderMonthPicker() {
    return html`
      <neb-ripple-button
        id="${ELEMENTS.pickerMonthDecrementButton.id}"
        class="button-page button-up"
        .onClick="${this.__handlers.decrementPickerMonth}"
      >
        <neb-icon
          slot="content"
          class="icon chevron-icon"
          icon="neb:chevron"
        ></neb-icon>
      </neb-ripple-button>

      ${this.__renderMonthPickerRows()}

      <neb-ripple-button
        id="${ELEMENTS.pickerMonthIncrementButton.id}"
        class="button-page button-down"
        .onClick="${this.__handlers.incrementPickerMonth}"
      >
        <neb-icon
          slot="content"
          class="icon chevron-icon"
          icon="neb:chevron"
        ></neb-icon>
      </neb-ripple-button>
    `;
  }

  __renderYearPickerRows() {
    return PICKER_OFFSETS.map(offset => {
      const pickerYear = this.__pickerDisplayYear + offset;
      return html`
        <div
          id="${ELEMENTS.pickerYearButton.id}${pickerYear}"
          class="button-picker picker-year"
          slide="${this.__pickerYearSlideDirection}"
          ?selected="${pickerYear === this.__pickerSelectedYear}"
          @click="${this.__handlers.setPickerYear}"
          .value="${pickerYear}"
        >
          ${pickerYear}
        </div>
      `;
    });
  }

  __renderYearPicker() {
    return html`
      <neb-ripple-button
        id="${ELEMENTS.pickerYearDecrementButton.id}"
        class="button-page button-up"
        .onClick="${this.__handlers.decrementPickerYear}"
      >
        <neb-icon
          slot="content"
          class="icon chevron-icon"
          icon="neb:chevron"
        ></neb-icon>
      </neb-ripple-button>

      ${this.__renderYearPickerRows()}

      <neb-ripple-button
        id="${ELEMENTS.pickerYearIncrementButton.id}"
        class="button-page button-down"
        .onClick="${this.__handlers.incrementPickerYear}"
      >
        <neb-icon
          slot="content"
          class="icon chevron-icon"
          icon="neb:chevron"
        ></neb-icon>
      </neb-ripple-button>
    `;
  }

  __renderPickerToolbar() {
    return html`
      <div id="${ELEMENTS.containerToolbarPicker.id}" class="toolbar">
        <button
          id="${ELEMENTS.okButton.id}"
          class="button button-label"
          @click="${this.__handlers.setMonthYear}"
        >
          OK
        </button>
        <button
          id="${ELEMENTS.cancelButton.id}"
          class="button button-label"
          @click="${this.__handlers.cancel}"
        >
          CANCEL
        </button>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container-picker">
        <div id="${ELEMENTS.monthPicker.id}" class="picker picker-month">
          ${this.__renderMonthPicker()}
        </div>
        <div id="${ELEMENTS.yearPicker.id}" class="picker picker-year">
          ${this.__renderYearPicker()}
        </div>
      </div>
      ${this.__renderPickerToolbar()}
    `;
  }
}

window.customElements.define(
  'neb-calendar-month-year-picker',
  NebCalendarMonthYearPicker,
);
