import { openWarning } from '../../../store';
import { fiservCancel } from '../fiserv-api-client';
import { formatFiservElectronicPayment } from '../utils';

import { connect } from './connect';
import { createPayment } from './create-payment';
import { handlePaymentComplete } from './handle-payment-complete';
import { readCard } from './read-card';

import { STATE } from '.';

const formatExpiry = expiry => `20${expiry.slice(-2)}${expiry.slice(0, -2)}`;

export class FiservReader {
  constructor({ readerId, setState, onSave }) {
    this.readerId = readerId;
    this.__setState = setState;
    this.onSave = onSave;
    this.sessionKey = null;
    this.isCanceled = false;
  }

  setState(...args) {
    if (!this.isCanceled) {
      this.__setState(...args);
    }
  }

  async activate(paymentDetails) {
    this.setState(STATE.CONNECTING);

    this.sessionKey = await connect(this);

    if (!this.sessionKey) {
      this.setState(STATE.DEFAULT);
      return;
    }

    this.setState(STATE.CONNECTED);

    const card = await readCard(this, paymentDetails.amount);

    if (!card) {
      this.setState(STATE.DEFAULT);
      return;
    }

    this.setState(STATE.PROCESSING);

    const payment = await createPayment({
      paymentDetails,
      token: card.token,
      expiry: formatExpiry(card.expiry),
    });

    if (!payment) {
      this.setState(STATE.DEFAULT);
      return;
    }

    await this.onSave(formatFiservElectronicPayment(payment));

    handlePaymentComplete({
      paymentDetails,
      token: card.token,
      expiry: formatExpiry(card.expiry),
      singleUseToken: card.singleUseToken,
    });
  }

  cancel() {
    this.setState(STATE.DEFAULT);
    this.isCanceled = true;

    openWarning('Payment canceled');

    if (this.sessionKey) {
      fiservCancel(this.readerId, { sessionKey: this.sessionKey });
    }
  }
}
