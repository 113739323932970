import '../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../filters/neb-filters-era-eob-associate-payments';
import '../tables/era-eob/payments/neb-table-associate-payments';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import { getPayments } from '../../../packages/neb-api-client/src/payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import { openDirtyPopup } from '../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import * as eobApi from '../../api-clients/eob';
import * as eraApi from '../../api-clients/era';
import { CSS_SPACING } from '../../styles';
import {
  ASSOCIATE_PAYMENT_ERROR,
  ASSOCIATE_PAYMENT_SUCCESSFULL,
} from '../../utils/user-message';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  content: { id: 'content' },
  filters: { id: 'filters' },
  actionBar: { id: 'action-bar' },
};

class CollectionPageAssociatePayment extends CollectionPage {
  static get config() {
    return {
      initialSortKey: 'transactionDate',
      initialSortOrder: SORT_DIR.ASC,
      description: '',
      hideHeader: true,
      useFetch: true,
      unifyForm: false,
      disableInitialLoad: true,
    };
  }

  static get properties() {
    return {
      model: Object,
      __selectedPayments: {
        type: Array,
      },
      __marginBottom: Number,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
        }
        .row-form {
          padding: 0;
        }
        .container {
          padding: 10px 0px ${CSS_SPACING} 0px;
        }
        .form {
          padding: 0px ${CSS_SPACING} ${CSS_SPACING} 15px;
        }
        .action-bar {
          position: relative;
          width: 100%;
          z-index: 3;
          position: fixed;
          bottom: 0%;
        }
        .row {
          flex-direction: column;
        }
        .filters {
          margin-bottom: ${CSS_SPACING};
          display: flex;
        }
        .cell-spacer {
          flex: 1 0 0;
        }
        .pagination {
          justify-content: flex-end;
        }
        .layout {
          display: grid;
          padding-bottom: 20px;
          margin-bottom: 65px;
          background-color: rgb(255, 255, 255);
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          flex: 1 0 0px;
          overflow: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.model = {};
    this.__selectedPayments = [];
    this.__marginBottom = 0;

    this.onDismiss = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      filter: model => this.__applyFilter(model),
      sort: (_, result) => {
        this.service.setSortParams(result[0]);
      },
      fetchData: query =>
        getPayments(null, {
          ...query,
          onlyPayerPayments: true,
          onlyNotAssociated: true,
        }),
      save: async () => {
        if (this.__selectedPayments.length === 0) {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Associate payments',
            message: `Select one or more payments to associate to the ${
              this.model.reportType
            }.`,
          });

          return;
        }

        try {
          if (this.model.reportType === 'EOB') {
            await eobApi.associatePayments(
              this.model.reportId,
              this.__selectedPayments,
            );
          } else {
            await eraApi.associatePayments(
              this.model.reportId,
              this.model.eClaimERAReportId,
              this.__selectedPayments,
            );
          }

          store.dispatch(openSuccess(ASSOCIATE_PAYMENT_SUCCESSFULL));
          this.onDismiss(true);
        } catch (err) {
          store.dispatch(
            openError(ASSOCIATE_PAYMENT_ERROR(this.model.reportType)),
          );
        }
      },
      cancel: async () => {
        if (this.__selectedPayments && this.__selectedPayments.length > 0) {
          const confirmed = await openDirtyPopup();
          if (!confirmed) return;
        }
        this.onDismiss(false);
      },
      togglePayment: (state, paymentId) => {
        if (state) {
          this.__selectedPayments.push(paymentId);
        } else {
          this.__selectedPayments.splice(
            this.__selectedPayments.indexOf(paymentId),
            1,
          );
        }
        this.__tableState = {
          ...this.__tableState,
        };
      },
    };
  }

  renderNoItemsContent() {
    return html` <neb-text>No items.</neb-text> `;
  }

  // eslint-disable-next-line complexity
  __applyFilter(model) {
    const dosFrom = model.dateOfService.from
      ? model.dateOfService.from.format('YYYY-MM-DD')
      : undefined;
    const dosTo = model.dateOfService.to
      ? model.dateOfService.to.format('YYYY-MM-DD')
      : undefined;

    this.service.setQuery('paymentId', model.paymentId || undefined);
    this.service.setQuery(
      'payerIds',
      model.payerIds.length ? model.payerIds : undefined,
    );

    this.service.setQuery('dosFrom', dosFrom);
    this.service.setQuery('dosTo', dosTo);
    this.service.setQuery(
      'paymentAmountFrom',
      model.paymentAmount.min || undefined,
    );

    this.service.setQuery(
      'paymentAmountTo',
      model.paymentAmount.max || undefined,
    );

    this.service.setQuery('authEFT', model.authEFT || undefined);

    this.service.setPageIndex(0);

    this.service.fetch();
  }

  __renderBottomActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="Done"
        cancelLabel="Cancel"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
      ></neb-action-bar>
    `;
  }

  renderPagination() {
    return this.__tableState.pageCount > 1
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                class="pagination"
                .debouncerDelay="${300}"
                .pageCount="${this.__tableState.pageCount}"
                .currentPage="${this.__tableState.pageIndex}"
                .onPageChanged="${this.handlers.selectPage}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderTable() {
    return html`
      <neb-filters-era-eob-associate-payments
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.handlers.filter}"
        .defaultPayer="${this.model.defaultPayer}"
      ></neb-filters-era-eob-associate-payments>

      <div id="${ELEMENTS.content.id}">
        <neb-table-associate-payments
          id="${ELEMENTS.table.id}"
          class="cell-spacer"
          .model="${this.__tableState.pageItems}"
          .sortParams="${[this.__tableState.sortParams]}"
          .onSort="${this.handlers.sort}"
          .onToggle="${this.handlers.togglePayment}"
          .selectedItems="${this.__selectedPayments}"
          .reportType="${this.model.reportType}"
        ></neb-table-associate-payments>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="layout">
        <div class="row">${this.renderTable()}</div>
        ${this.renderPagination()}
      </div>
      ${this.__renderBottomActionBar()}
    `;
  }
}

window.customElements.define(
  'neb-collection-page-associate-payments',
  CollectionPageAssociatePayment,
);
