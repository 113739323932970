import { CLAIM_FORM_ERROR_TYPES } from '../components/forms/neb-form-claim/create-selectors';

export const CLAIM_VIEW_ELEMENTS = {
  insuredNumberCell: { id: 'insured-number-cell' },
  patientNameCell: { id: 'patient-name-cell' },
  patientDateOfBirthCell: { id: 'patient-date-of-birth-cell' },
  insuredNameCell: { id: 'insured-name-cell' },
  totalChargesCell: { id: 'total-charges-cell' },
  insuredInfoCell: { id: 'insured-info-cell' },
  serviceFacilityInfoCell: { id: 'service-facility-info-cell' },
  billingInfoCell: { id: 'billing-info-cell' },
  patientsConditionRelatedToCell: { id: 'patients-condition-related-to-cell' },
  patientAddressCell: { id: 'patient-address-cell' },
  insuredAddressCell: { id: 'insured-address-cell' },
  federalTaxIdCell: { id: 'federal-tax-id-cell' },
  patientsAccountNumberCell: { id: 'patients-account-number-cell' },
  priorAuthorizationCell: { id: 'prior-authorization-cell' },
  claimDiagnosesCell: { id: 'claim-diagnoses-cell' },
  otherInsuredInfoCell: { id: 'other-insured-info-cell' },
  spinalManipulationCell: { id: 'spinal-manipulation-cell' },
  claimChargesCell: { id: 'claim-charges-cell' },
  dateOfCurrentIllnessCell: { id: 'date-of-current-illness-cell' },
  resubmissionCodeCell: { id: 'resubmission-code-cell' },
  originalReferenceNumberCell: { id: 'original-reference-number-cell' },
  otherDateCell: { id: 'other-date-cell' },
  patientRelationshipCell: { id: 'patient-relationship-cell' },
  referringProviderCell: { id: 'referring-provider-cell' },
  fl19Cell: { id: 'fl-19-cell' },
};

export const SCRUB_CODE = Object.freeze({
  CL001: 'CL001',
  CL002: 'CL002',
  CL003: 'CL003',
  CL004: 'CL004',
  CL005: 'CL005',
  CL006: 'CL006',
  CL007: 'CL007',
  CL008: 'CL008',
  PL001: 'PL001',
  PL002: 'PL002',
  PL003: 'PL003',
  PL005: 'PL005',
  PL006: 'PL006',
  PL007: 'PL007',
  PL008: 'PL008',
  PL009: 'PL009',
  PL010: 'PL010',
  PL011: 'PL011',
  PL012: 'PL012',
  PL013: 'PL013',
  PL014: 'PL014',
  PL015: 'PL015',
  PL016: 'PL016',
  PL017: 'PL017',
  PL018: 'PL018',
  PL019: 'PL019',
  PL020: 'PL020',
  PL021: 'PL021',
  PL022: 'PL022',
  PL023: 'PL023',
  PL024: 'PL024',
  PL025: 'PL025',
  PL026: 'PL026',
  PL027: 'PL027',
  PL028: 'PL028',
  PL031: 'PL031',
  PL032: 'PL032',
  PL033: 'PL033',
  PL034: 'PL034',
  PL035: 'PL035',
  PL036: 'PL036',
});

const ERRORS = {
  FL_1A: {
    title: 'FL 1a',
    description: "Insured's I.D. number is required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.insuredNumberCell.id,
  },

  FL_2: {
    title: 'FL 2',
    description: "Patients's first and last name are required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.patientNameCell.id,
  },

  FL_3: {
    title: 'FL 3',
    description: "Patients's date of birth and sex are required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.patientDateOfBirthCell.id,
  },

  FL_4: {
    title: 'FL 4',
    description: "Insured's first and last name are required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.insuredNameCell.id,
  },

  FL_9: {
    title: 'FL 9',
    description: "Other insured's name is required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.otherInsuredInfoCell.id,
  },

  FL_9A: {
    title: 'FL 9a',
    description: "Other insured's policy or group number is required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.otherInsuredInfoCell.id,
  },

  FL_9D: {
    title: 'FL 9d',
    description:
      "Other insured's insurance plan name or program name is required.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.otherInsuredInfoCell.id,
  },

  FL_10d: {
    title: 'FL 10d',
    description: 'Claim codes is required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.patientsConditionRelatedToCell.id,
  },

  FL_23: {
    title: 'FL 23',
    description: 'Prior Authorization required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.priorAuthorizationCell.id,
  },

  FL_32: {
    title: 'FL 32',
    description:
      'Service facility location address, city, state and zip code are required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.serviceFacilityInfoCell.id,
  },

  FL_32A: {
    title: 'FL 32a',
    description: 'Service facility location NPI is required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.serviceFacilityInfoCell.id,
  },

  FL_33: {
    title: 'FL 33',
    description:
      'Billing provider address, city, state and zip code are required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  },

  FL_33A: {
    title: 'FL 33a',
    description: 'Billing provider NPI is required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  },

  SPINAL_MANIPULATION: {
    title: 'Spinal Info',
    description: 'Spinal Manipulation Information is required.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.spinalManipulationCell.id,
  },
};

const WARNINGS = {
  FL_5: {
    title: 'FL 5',
    description:
      "Patient's Address, Street, City, State, and Zip Code are recommended.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.patientAddressCell.id,
  },
  FL_7: {
    title: 'FL 7',
    description:
      "Insured's Address, Street, City, State, and Zip Code are recommended.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.insuredAddressCell.id,
  },
  FL_11A: {
    title: 'FL 11a',
    description: "Insured's date of birth is recommended.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.insuredInfoCell.id,
  },
  FL_21: {
    title: 'FL 21',
    description: 'Diagnosis or nature of illness or injury is recommended.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.claimDiagnosesCell.id,
  },
  FL_25: {
    title: 'FL 25',
    description: 'Federal Tax I.D. is recommended.',
    idToScrollTo: CLAIM_VIEW_ELEMENTS.federalTaxIdCell.id,
  },
  FL_26: {
    title: 'FL 26',
    description: "Patient's account number is recommended.",
    idToScrollTo: CLAIM_VIEW_ELEMENTS.patientsAccountNumberCell.id,
  },
};

const isScrubbingError = v => v === CLAIM_FORM_ERROR_TYPES.EMPTY;

const evaluateAddressForErrors = (key, errors) =>
  Object.values(errors[key]).some(v => v && !isScrubbingError(v));

const evaluateAuthorizationsForErrors = (key, errors) =>
  Object.values(errors[key]).some(
    v => v.authorizationNumber && !isScrubbingError(v.authorizationNumber),
  );

export const claimStatusLineItemsExist = lineItems =>
  Array.isArray(lineItems) && lineItems.length;

const getError = (key, errors) => {
  if (isScrubbingError(errors[key])) return null;

  switch (key) {
    case 'insuredIdNumber':
      return ERRORS.FL_1A;

    case 'patientFirstName':
    case 'patientLastName':
      return ERRORS.FL_2;

    case 'patientDateOfBirth':
      return ERRORS.FL_3;

    case 'insuredFirstName':
    case 'insuredLastName':
      return ERRORS.FL_4;

    case 'otherInsuredFirstName':
    case 'otherInsuredLastName':
      return ERRORS.FL_9;

    case 'otherInsuredGroupIdNumber':
      return ERRORS.FL_9A;

    case 'otherInsurancePlanName':
      return ERRORS.FL_9D;

    case 'claimCodes':
      return ERRORS.FL_10d;

    case 'claimAuthorizations':
      return evaluateAuthorizationsForErrors(key, errors) ? ERRORS.FL_23 : null;

    case 'serviceFacilityAddress':
      return evaluateAddressForErrors(key, errors) ? ERRORS.FL_32 : null;

    case 'serviceFacilityNPI':
      return ERRORS.FL_32A;

    case 'billingProviderAddress':
      return evaluateAddressForErrors(key, errors) ? ERRORS.FL_33 : null;

    case 'billingProviderNPI':
      return ERRORS.FL_33A;

    case 'spinalManipulationInfoCode':
    case 'spinalManipulationInfoDescription':
      return ERRORS.SPINAL_MANIPULATION;

    default:
      return undefined;
  }
};

const getWarning = (key, errors) => {
  if (isScrubbingError(errors[key])) return null;

  switch (key) {
    case 'patientAddress':
      return evaluateAddressForErrors(key, errors) ? WARNINGS.FL_5 : null;

    case 'insuredAddress':
      return evaluateAddressForErrors(key, errors) ? WARNINGS.FL_7 : null;

    case 'insuredDateOfBirth':
      return WARNINGS.FL_11A;

    case 'claimDiagnoses':
      return WARNINGS.FL_21;

    case 'federalTaxIdNumber':
      return WARNINGS.FL_25;

    case 'medicalRecordNumber':
      return WARNINGS.FL_26;

    default:
      return undefined;
  }
};

const translateProblems = (problems, evaluator) => {
  const unsortedProblems = Object.entries(problems)
    .filter(([_, val]) => val)
    .reduce((acc, [key, _val]) => {
      const problem = evaluator(key, problems);

      if (!problem || acc.find(({ title }) => title === problem.title)) {
        return acc;
      }

      return [...acc, problem];
    }, []);

  return unsortedProblems.sort((a, b) => {
    const [, indexA] = a.title.split(' ');
    const [, indexB] = b.title.split(' ');

    return parseInt(indexA, 10) - parseInt(indexB, 10);
  });
};

export const translateErrors = errors => translateProblems(errors, getError);

export const translateNotifications = ({
  claimCharges,
  claimStatuses,
  chartNotesRequired,
  timelyFiling,
}) => {
  const filteredClaimStatuses = claimStatuses.filter(
    claim => claim.code !== 'SCRUB',
  );

  const claimStatus = filteredClaimStatuses.length
    ? filteredClaimStatuses[0]
    : null;

  const hasBillWithXrayDate = claimCharges.some(c => c.billWithXrayDate);

  const hasBillWithTreatmentInitiationDate = claimCharges.some(
    c => c.billWithTreatmentInitiationDate,
  );

  const notifications = [];

  if (claimStatus) {
    if (claimStatus.code && claimStatus.description) {
      notifications.push({
        title: claimStatus.code,
        description: claimStatus.description,
      });
    }

    if (claimStatus.statusCode && claimStatus.statusCodeValue) {
      notifications.push({
        title: claimStatus.statusCode,
        description: claimStatus.statusCodeValue,
      });
    }
  }

  if (claimStatusLineItemsExist(claimStatus.claimStatusLineItems)) {
    notifications.push({
      description: 'View claim status codes',
      clickable: true,
    });
  }

  if (hasBillWithXrayDate) {
    notifications.push({
      description:
        'One or more charges on this claim must be billed with Last X-ray date.',
    });
  }

  if (hasBillWithTreatmentInitiationDate) {
    notifications.push({
      description:
        'One or more charges on this claim must be billed with Initial Treatment date.',
    });
  }

  if (chartNotesRequired) {
    notifications.push({
      description: 'Chart notes required.',
    });
  }

  if (timelyFiling > 0) {
    notifications.push({
      description: `Timely filing of ${timelyFiling} ${
        timelyFiling === 1 ? 'day' : 'days'
      } required.`,
    });
  }

  return notifications;
};

export const translateWarnings = errors =>
  translateProblems(errors, getWarning);

export const translateClearinghouseErrors = errors => {
  const formatted = errors.map(e => ({
    title: '',
    description: e.description,
    idToScrollTo: null,
  }));

  return formatted;
};
