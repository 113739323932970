import equal from 'fast-deep-equal';

import { splitIntoTerms } from '../formatters';

export const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

const DEFAULT_SORT_PARAMS = {
  key: '',
  dir: SORT_DIR.DESC,
};

export class CollectionService {
  static createModel() {
    return {
      searchText: '',
      hideInactive: true,
      filteredCount: 0,
      pageSize: 20,
      pageCount: 1,
      pageIndex: 0,
      pageItems: [],
      allItems: [],
      filters: [],
      sortParams: {
        key: '',
        dir: SORT_DIR.DESC,
      },
    };
  }

  constructor(callbacks, initialConfig = {}) {
    const {
      sortParams,
      pageSize,
      hideInactive,
      preventAutoSort,
      optOutPagination,
    } = initialConfig;

    const { onChange, onSearch, onSort, onCacheItem, onMapItem } = callbacks;

    this.__hideInactive = hideInactive === undefined ? true : hideInactive;
    this.__pageSize = pageSize || 10;
    this.__sortParams = sortParams || DEFAULT_SORT_PARAMS;
    this.__preventAutoSort = preventAutoSort || false;
    this.__cache = null;
    this.__filteredItems = [];
    this.__items = [];
    this.__pageIndex = 0;
    this.__pageItems = [];
    this.__searchText = '';
    this.__onCacheItem = onCacheItem || (item => item);
    this.__onChange = onChange || (() => {});
    this.__onSearch = onSearch || (_ => true);
    this.__onSort = onSort || ((a, b) => a < b);
    this.__onMapItem = onMapItem || (item => item);
    this.__filters = [];
    this.__optOutPagination = optOutPagination || false;
  }

  getFilteredItems() {
    return this.__filteredItems;
  }

  getItems() {
    return this.__items;
  }

  getTotalCount() {
    return this.__items.length;
  }

  getPageCount() {
    return Math.ceil(this.__filteredItems.length / this.__pageSize);
  }

  getState() {
    return {
      searchText: this.__searchText,
      hideInactive: this.__hideInactive,
      filteredCount: this.__filteredItems.length,
      sortParams: this.__sortParams,
      allItems: this.__items,
      pageSize: this.__pageSize,
      pageIndex: this.__pageIndex,
      pageItems: this.__pageItems,
      pageCount: this.getPageCount(),
      filters: this.__filters,
    };
  }

  addItem(item) {
    this.__items = [...this.__items, item];
    this.__updateCache();
    this.__filter();
  }

  removeItem(item) {
    const index = this.__items.findIndex(target => target.id === item.id);

    this.__items.splice(index, 1);
    this.__updateCache();
    this.__filter();
  }

  updateItem(item) {
    const index = this.__items.findIndex(target => target.id === item.id);

    this.__items[index] = item;
    this.__updateCache();
    this.__filter();
  }

  append() {
    if (this.__pageItems.length < this.__filteredItems.length) {
      const size = this.__pageItems.length + this.__pageSize;

      this.__pageItems = this.__filteredItems.slice(0, size);
      this.__change();
    }
  }

  hideInactive(allow) {
    this.__hideInactive = allow;
    this.__pageIndex = 0;

    this.__filter();
  }

  search(str) {
    if (str !== this.__searchText) {
      this.__searchText = str;
      this.__pageIndex = 0;
      this.__filter();
    }
  }

  addFilter(name, fn, params = null) {
    const dupFound = Boolean(this.__filters.find(item => item.name === name));

    if (dupFound) {
      throw new Error(`Duplicate filter: ${name}`);
    }

    this.__filters.push({ name, fn, params });
    this.__pageIndex = 0;
    this.__filter();

    return this;
  }

  removeFilter(name) {
    this.__filters = this.__filters.filter(item => item.name !== name);
    this.__pageIndex = 0;
    this.__filter();

    return this;
  }

  updateFilter(name, params) {
    const target = this.__filters.find(item => item.name === name);
    target.params = params;

    this.__pageIndex = 0;
    this.__filter();

    return this;
  }

  setPageIndex(index) {
    if (index !== this.__pageIndex) {
      this.__pageIndex = index;

      this.__page();
    }
  }

  setPageSize(size) {
    if (size !== this.__pageSize) {
      this.__pageSize = size;

      this.__page();
    }
  }

  setItems(items) {
    if (!equal(items, this.__items)) {
      this.__items = [...items];
      this.__updateCache();
      this.__filter();
    }
  }

  setSortParams(params) {
    if (!equal(params, this.__sortParams)) {
      this.__sortParams = params;
      this.__pageIndex = 0;

      this.__sort();
    }
  }

  __updateCache() {
    this.__cache = this.__items.map(item => this.__onCacheItem(item));
  }

  __change() {
    this.__onChange(this.getState());
  }

  __page() {
    const startIndex = this.__pageIndex * this.__pageSize;
    const endIndex = startIndex + this.__pageSize;

    this.__pageItems = !this.__optOutPagination
      ? this.__filteredItems.slice(startIndex, endIndex)
      : this.__filteredItems;

    this.__change();
  }

  __sort() {
    const fn = (a, b) => this.__onSort(a, b, this.__sortParams.key);

    this.__filteredItems.sort((a, b) =>
      this.__sortParams.dir === SORT_DIR.ASC ? fn(a, b) : fn(b, a),
    );

    this.__page();
  }

  __filterLegacy() {
    const terms = splitIntoTerms(this.__searchText);

    this.__filteredItems = this.__items.filter((item, index) => {
      if (this.__hideInactive && !item.active) {
        return false;
      }

      const result = this.__cache ? this.__cache[index] : item;

      return (
        !this.__searchText ||
        this.__onSearch({
          searchText: this.__searchText,
          item: result,
          terms,
        })
      );
    });
  }

  __filter() {
    this.__filterLegacy();

    this.__filteredItems = this.__filters
      .reduce(
        (acc, cur) => acc.filter(item => cur.fn(item, cur.params)),
        this.__filteredItems,
      )
      .map(this.__onMapItem);

    if (this.__pageIndex >= this.getPageCount()) {
      this.__pageIndex = 0;
    }

    if (this.__preventAutoSort) {
      this.__page();
    } else {
      this.__sort();
    }
  }
}
