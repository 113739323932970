import '../../../neb-popup-header';
import '../../../tables/neb-table';
import '../../../tables/neb-table-claim-status-history-log';

import '../../../../../../../src/components/misc/neb-icon';

import { html, css } from 'lit';

import * as clearinghouseApi from '../../../../../../../src/api-clients/clearinghouse';
import * as eClaimFileApi from '../../../../../../../src/api-clients/eclaim-file';
import {
  CLEARINGHOUSES,
  hasByoc,
} from '../../../../../../../src/utils/clearinghouse-settings';
import { generateFileName } from '../../../../../../../src/utils/e-claim-files';
import { getClaimHistory } from '../../../../../../neb-api-client/src/claims';
import {
  openError,
  openSuccess,
} from '../../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../../../neb-styles/neb-variables';
import { downloadURLtoFile } from '../../../../../../neb-utils/blobProcessor';
import { parseDate } from '../../../../../../neb-utils/date-util';
import {
  getClaimJsonFromS3,
  openS3ClaimTab,
} from '../../../../../../neb-utils/electronic-claims';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import { fetchPracticeUsers } from '../../../../../../neb-utils/neb-ledger-util';
import Overlay from '../../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  table: {
    id: 'table',
  },
};

class NebOverlayClaimStatusHistoryLog extends Overlay {
  static get properties() {
    return {
      __historyRecords: Array,
      __hasAddOnCtBYOC: Boolean,
      __users: Array,
      __expandedFlags: Array,
      __hasRCMClaimActionHistoryFeatureFlag: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__historyRecords = [];
    this.__users = [];
    this.__expandedFlags = [];
    this.__hasRCMClaimActionHistoryFeatureFlag = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickS3Icon: async (record = {}) => {
        let s3Data;

        const { eClaimFileId, s3KeyJson, partnerName } = record;
        const { claimNumber } = this.model.claimModel;

        if (partnerName === CLEARINGHOUSES.CHC) {
          s3Data = s3KeyJson;

          const message = s3Data
            ? openSuccess('CHC Data retrieved successfully')
            : openError('CHC Data not found for claim');

          store.dispatch(message);
        } else {
          s3Data = await getClaimJsonFromS3(eClaimFileId);
        }

        openS3ClaimTab({ claim: s3Data, claimNumber, partnerName });
      },

      clickDownloadIcon: async item => {
        const { eClaimFileId, clearinghouseId, createdAt, partnerName } = item;

        const { claimNumber } = this.model.claimModel;

        const { id } = store.getState().session.item;

        try {
          const url = await eClaimFileApi.downloadFile(eClaimFileId, {
            updatedBy: id,
          });

          const clearinghouse =
            await clearinghouseApi.fetchOne(clearinghouseId);

          const {
            practiceIdentifierId,
            customExtension,
            customFilenameTemplate,
          } = clearinghouse;

          downloadURLtoFile(
            url,
            generateFileName({
              clearinghousePartner: partnerName,
              claimNumber,
              practiceIdentifierId,
              customExtension,
              customFilenameTemplate,
              createdAt,
            }),
          );

          this.__historyRecords = await this.__fetchHistoryRecords();
        } catch (e) {
          store.dispatch(
            openError('An error occurred when attempting to download the file'),
          );
        }
      },
      toggleExpand: index => {
        const expandFlags = this.__expandedFlags;
        expandFlags[index] = !expandFlags[index];
        this.__expandedFlags = [...expandFlags];
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCtBYOC = await hasByoc();
    this.__users = await fetchPracticeUsers();
    this.__historyRecords = await this.__fetchHistoryRecords();
    this.__expandedFlags = this.__historyRecords.map(() => false);
    this.__hasRCMClaimActionHistoryFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_CLAIM_ACTION_HISTORY,
    );
  }

  async __fetchHistoryRecords() {
    const { id: claimId } = this.model.claimModel;
    const { chcPayerId, id: payerPlanId } = this.model.payerPlan;

    const claimHistory = await getClaimHistory(claimId, {
      claimId,
      payerPlanId,
      chcPayerId,
    });

    return claimHistory;
  }

  __buildTable() {
    return [
      {
        truncate: true,
        key: 'effectiveDate',
        label: 'Date',
        flex: css`1.5 0 0`,
        formatter: v => html`
          <neb-text>${v ? parseDate(v).format('MM/DD/YYYY') : ''}</neb-text>
        `,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`2 0 0`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`4 0 0`,
        formatter: (v, model) => {
          const description = model.code ? `${model.code} - ${v}` : v;

          return description;
        },
      },
      ...(this.__hasAddOnCtBYOC
        ? [
            {
              key: 'partnerName',
              label: 'Clearinghouse Partner',
              flex: css`2 0 0`,
            },
          ]
        : []),
      {
        key: 'updatedBy',
        label: 'Updated By',
        flex: css`2 0 0`,
      },
      ...(this.__hasRCMClaimActionHistoryFeatureFlag
        ? [
            {
              key: 'expand',
              flex: css`0.3 0 0`,
            },
          ]
        : []),
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          flex-flow: column nowrap;
          height: 100%;
          overflow-y: auto;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="History Log"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showCancelButton
        showBackButton
      ></neb-popup-header>

      <neb-table-claim-status-history-log
        id="${ELEMENTS.table.id}"
        .name="${'table'}"
        .model="${this.__historyRecords}"
        .config="${this.__buildTable()}"
        .users="${this.__users}"
        .hasAddOnCtBYOC="${this.__hasAddOnCtBYOC}"
        .onClickS3Icon="${this.handlers.clickS3Icon}"
        .onClickDownloadIcon="${this.handlers.clickDownloadIcon}"
        .expandFlags="${this.__expandedFlags}"
        .onToggleExpand="${this.handlers.toggleExpand}"
        .hasRCMClaimActionHistoryFeatureFlag="${this
          .__hasRCMClaimActionHistoryFeatureFlag}"
      >
      </neb-table-claim-status-history-log>
    `;
  }
}

window.customElements.define(
  'neb-overlay-claim-status-history-log',
  NebOverlayClaimStatusHistoryLog,
);
