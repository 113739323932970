const __computePatientMiddleInitial = middleName =>
  middleName.charAt(0).toUpperCase();

export const buildPatientName = (patient, useSuffix = false) => {
  const { firstName, lastName, middleName, suffix, preferredName } = patient;

  let patientName = `${lastName}, ${firstName}`;

  if (suffix && useSuffix) {
    patientName = `${lastName} ${suffix}, ${firstName}`;
  }

  if (middleName) {
    patientName = `${patientName} ${__computePatientMiddleInitial(
      middleName,
    )}.`;
  }

  if (preferredName) {
    patientName = `${patientName} (${preferredName})`;
  }

  return patientName;
};

const formatName = (firstName, lastName) => `${lastName}, ${firstName}`;

export const buildOnlineBookingPatientName = appointment => {
  if (!appointment || !appointment.firstName || !appointment.lastName) {
    return '';
  }
  const { firstName, lastName } = appointment;
  return formatName(firstName, lastName);
};
