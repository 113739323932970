import DeprecatedApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'payments' });

const baseUrl = 'account';

export const TOOL_TIP_SUBSCRIPTION_ALLOWED =
  'Enable this option to set this package up as a subscription.';
export const TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED =
  'A merchant account is required in order to receive automated recurring payments for subscription-based packages';

export const createMerchantAccount = account =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(account),
  });

export const updateMerchantAccount = account =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${baseUrl}/${account.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(account),
  });

export const getMerchantAccount = async id => {
  try {
    const account = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: `${baseUrl}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${id}-merchant-account`,
    });

    return account;
  } catch (err) {
    return null;
  }
};

export const getActiveMerchantAccountCount = async () => {
  try {
    const { count } = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: `${baseUrl}/activeCount`,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: 'active-merchant-accounts',
    });

    return count;
  } catch (err) {
    return 0;
  }
};

export const getMerchantAccounts = async (
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  try {
    const accounts = await apiClient.makeRequest({
      method: Method.GET,
      path: baseUrl,
      queryParams,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${JSON.stringify(queryParams)}-merchant-accounts`,
      optOutLoadingIndicator,
    });

    return accounts;
  } catch (err) {
    return [];
  }
};

export const countMerchantAccountInSchedule = async id => {
  try {
    const count = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: `${baseUrl}/${id}/schedule/count`,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${id}-merchant-account-in-schedule`,
    });

    return count;
  } catch (err) {
    return null;
  }
};

export const getOnlinePaymentMerchantAccount = async (
  optOutLoadingIndicator = false,
) => {
  try {
    const account = await apiClient.makeRequest({
      method: Method.GET,
      path: `${baseUrl}/onlinePayments`,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: 'online-payments-merchant-accounts',
      optOutLoadingIndicator,
    });

    return account;
  } catch (err) {
    return {};
  }
};
