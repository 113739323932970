import './state/appointments/reducers/neb-appointment-reducer';

import * as appointmentApiClient from '../neb-api-client/src/appointment-api-client';
import { store } from '../neb-redux/neb-redux-store';
import { watch } from '../neb-redux/neb-redux-watcher';
import { parseDate } from '../neb-utils/date-util';

export const recurringBlockedOffTimeActions = {
  deleteSeriesForSingleProvider: 'deleteSeriesForSingleProvider',
  deleteSingleInstanceForSingleProvider:
    'deleteSingleInstanceForSingleProvider',
  updateSingleInstanceForAllProvider: 'updateSingleInstanceForAllProvider',
};

function __reactToEncounterChange() {
  const {
    appointments: { items: appointments },
    encounter: { fetching, updating, item: encounterId },
    encounters,
  } = store.getState();

  if (!fetching && !updating && encounterId && appointments) {
    const updatedEncounter = encounters[encounterId];
    const appointment = appointments.find(
      ({ id }) => id === updatedEncounter.appointmentId,
    );
    store.dispatch({
      type: 'APPOINTMENT_UPDATED',
      item: { ...appointment, encounter: updatedEncounter },
    });
  }
}

export function initSubscribe() {
  const unsubscribes = [
    store.subscribe(
      watch(store.getState, 'encounter')(() => __reactToEncounterChange()),
    ),
  ];

  return () => {
    unsubscribes.forEach(unsubscribe => unsubscribe());
  };
}

export const createAppointment = appointment => async dispatch => {
  dispatch({
    type: 'APPOINTMENT_CREATING',
  });

  let response;

  try {
    response = await appointmentApiClient.createAppointment(appointment);
  } catch (createError) {
    return dispatch({
      type: 'APPOINTMENT_CREATE_ERROR',
      createError,
      status: createError.statusCode,
    });
  }

  const createdAppointment = response.data[0];
  createdAppointment.start = parseDate(createdAppointment.start);
  createdAppointment.end = parseDate(createdAppointment.end);
  const dispatchResult = await dispatch({
    type: 'APPOINTMENT_CREATED',
    item: createdAppointment,
  }); // ticket NEB-4182 to remove duplicate dispatch

  await dispatch({
    type: 'ITEM_CREATED',
    name: 'appointment',
    res: {
      data: [createdAppointment],
    },
  });

  return dispatchResult;
};

export const isInSameMonth = (stateObject, targetDate) =>
  stateObject.targetDate &&
  stateObject.targetDate.year() === targetDate.year() &&
  stateObject.targetDate.month() === targetDate.month();

const __fetchAppointments = (targetDate, _state) => dispatch => {
  const start = parseDate().startOf('day');
  const end = parseDate().endOf('day');

  dispatch({
    type: 'APPOINTMENTS_SET_ITEMS_FETCH',
    targetDate,
    start,
    end,
    items: [],
  });
};

export const fetchAppointments =
  (targetDate, isScheduling = false) =>
  dispatch => {
    const state = store.getState();

    const date = parseDate(targetDate);

    if (isInSameMonth(state.appointments, date) && state.appointments.items) {
      return dispatch({
        type: 'APPOINTMENTS_SET_TARGET_DATE',
        targetDate,
      });
    }

    return dispatch(__fetchAppointments(date, state, isScheduling));
  };

export const forceFetchAppointments = targetDate => dispatch => {
  const state = store.getState();
  return dispatch(__fetchAppointments(targetDate, state));
};

export const updateAppointment =
  (appointmentId, actionVerb, appointment) => async dispatch => {
    if (!appointmentApiClient.SUPPORTED_UPDATE_ACTIONS.includes(actionVerb)) {
      throw new Error(
        `Unsupported action: ${actionVerb}, not in ${
          appointmentApiClient.SUPPORTED_UPDATE_ACTIONS
        }`,
      );
    }

    dispatch({
      type: 'APPOINTMENT_UPDATING',
    });

    let response;

    try {
      response = await appointmentApiClient.updateAppointment(
        appointmentId,
        actionVerb,
        appointment,
        true,
      );
    } catch (updateError) {
      return dispatch({
        type: 'APPOINTMENT_UPDATE_ERROR',
        updateError,
        status: updateError.statusCode,
      });
    }

    if (response.validationError) {
      return dispatch({
        type: 'APPOINTMENT_UPDATE_ERROR',
        updateError: response.validationError,
      });
    }

    const updatedAppointment = response.data[0];
    updatedAppointment.start = parseDate(updatedAppointment.start);
    updatedAppointment.end = parseDate(updatedAppointment.end);

    if (updatedAppointment.recurrenceEventId) {
      await store.dispatch(
        forceFetchAppointments(store.getState().appointments.targetDate),
      );
    }

    return dispatch({
      type: 'APPOINTMENT_UPDATED',
      item: updatedAppointment,
    });
  };

export const cancelOrDeleteAppointment =
  ({ appointmentId, appointmentAction, note, reason, patientId }) =>
  async dispatch => {
    const apiMap = {
      cancel: () =>
        appointmentApiClient.updateAppointment(appointmentId, 'cancel', {
          cancelReason: note,
          cancelRescheduleReasonId: reason,
        }),
      delete: () =>
        appointmentApiClient.deleteAppointment(appointmentId, patientId),
      deleteSeries: () =>
        appointmentApiClient.deleteSeries(appointmentId, patientId),
      noShow: () =>
        appointmentApiClient.updateAppointment(appointmentId, 'noShow'),
    };

    const SUPPORTED_CANCEL_OR_DELETE_ACTIONS = Object.keys(apiMap);

    if (!SUPPORTED_CANCEL_OR_DELETE_ACTIONS.includes(appointmentAction)) {
      throw new Error(
        `Unsupported action: ${appointmentAction}, not in ${SUPPORTED_CANCEL_OR_DELETE_ACTIONS}`,
      );
    }

    dispatch({
      type: 'APPOINTMENT_CANCELING_DELETING',
    });

    try {
      await apiMap[appointmentAction]();
    } catch (cancelDeleteError) {
      return dispatch({
        type: 'APPOINTMENT_CANCEL_DELETE_ERROR',
        cancelDeleteError,
        status: cancelDeleteError.statusCode,
      });
    }

    const appointments = store.getState().appointments.items;

    let newAppointmentItems;

    if (appointmentAction === 'deleteSeries') {
      newAppointmentItems = appointments;
    } else if (appointmentAction !== 'noShow') {
      newAppointmentItems = appointments.filter(
        appointment => appointment.id !== appointmentId,
      );
    } else {
      // find the appointment we are updating and change the status of the appointment to No-Show
      const updatedAppointment = appointments.find(
        appointment => appointment.id === appointmentId,
      );

      if (updatedAppointment) {
        updatedAppointment.status = 'No-Show';
        newAppointmentItems = appointments.filter(
          appointment => appointment.id !== appointmentId,
        );

        newAppointmentItems.push(updatedAppointment);
      } else {
        newAppointmentItems = appointments;
      }
    }

    const appointment = newAppointmentItems.find(
      appointment => appointment.id === appointmentId,
    );

    if (appointment && !!appointment.recurrenceEventId) {
      store.dispatch(
        forceFetchAppointments(store.getState().appointments.targetDate),
      );
    }

    return dispatch({
      type: 'APPOINTMENT_CANCEL_DELETE_SUCCESS',
      items: newAppointmentItems,
    });
  };
