import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-table-add';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { css, html } from 'lit';

import { getPayerGroups } from '../../../../packages/neb-api-client/src/billing/payer-groups';
import { SORT_DIR } from '../../../../packages/neb-lit-components/src/components/neb-table-header';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { normalizeForSearch } from '../../../../packages/neb-utils/formatters';
import { CollectionService } from '../../../../packages/neb-utils/services/collection';
import { deduplicate } from '../../../../packages/neb-www-booking/src/components/util/enable-booking-util';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../../styles/index';

export const ELEMENTS = {
  header: { id: 'associate-group-header' },
  form: { id: 'associate-group-form' },
  table: { id: 'associate-group-table' },
  searchField: {
    id: 'associate-group-search-field',
    label: 'Enter Payer Group Name or Payers to filter list below',
  },
  description: { id: 'associate-group-description' },
  actionBar: { id: 'associate-group-action-bar' },
  noResultsMessage: { id: 'associate-group-no-results-message' },
  noGroupsMessage: { id: 'associate-group-no-payers-message' },
  paginationControl: { id: 'associate-group-pagination-control' },
};

const TABLE_ROWS_KEYS = {
  NAME: 'name',
  PAYER_PLANS: 'payerPlans',
  STATUS: 'active',
};

const TABLE_CONFIG = [
  {
    key: TABLE_ROWS_KEYS.NAME,
    label: 'Payer Group Name',
    flex: css`1 0 0`,
  },
  {
    key: TABLE_ROWS_KEYS.PAYER_PLANS,
    label: 'Payers',
    flex: css`2 0 0`,
    formatter: v => v.join(', '),
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.STATUS,
    label: 'Status',
    flex: css`0 0 100px`,
    formatter: v => (v ? 'Active' : 'Inactive'),
  },
];

export const EMPTY_MODEL = {
  ruleSetPayerGroups: [],
};

class NebOverlayAssociatePayerGroups extends Overlay {
  static get properties() {
    return {
      __searchText: String,
      __allAdded: Boolean,
      __currentPageIndex: Number,
      __pageCount: Number,
      __payerGroups: Array,
      __selectedPayerGroups: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: flex;
          flex-direction: column;
        }

        .content {
          width: 1024px;
        }

        .icon-chevron {
          height: 12px;
          width: 12px;
          transform: rotate(90deg);
        }

        .main-header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .add-button {
          padding: ${CSS_SPACING};
        }

        .search-field {
          min-height: 70px;
          margin: 15px ${CSS_SPACING} 20px;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }

        .container-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__searchText = '';
    this.__allAdded = false;
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__payerGroups = [];
    this.__selectedPayerGroups = [];
    this.model = EMPTY_MODEL;

    this.__initServices();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__payerGroups = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },
        onCacheItem: ({ name, payerPlans }) => {
          const plans = payerPlans.join(' ');
          return normalizeForSearch([name, plans].join(' '));
        },
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
        onSort: this.__onSortGroups,
      },
      {
        hideInactive: false,
        sortParams: {
          key: TABLE_ROWS_KEYS.NAME,
          dir: SORT_DIR.ASC,
        },
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      search: ({ value }) => this.__collectionService.search(value),
      clearSearch: () => this.__collectionService.search(''),
      apply: () => {
        this.dismiss(this.__selectedPayerGroups.sort(this.__onSortGroups));
      },
      addAll: () => {
        const allItems = [
          ...this.__selectedPayerGroups,
          ...this.__collectionService.getFilteredItems(),
        ];
        this.__selectedPayerGroups = deduplicate(allItems, 'id');
      },
      add: (item, _index) => {
        this.__selectedPayerGroups = [...this.__selectedPayerGroups, item];
      },
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
    };
  }

  async __fetchData() {
    const res = await getPayerGroups(true);

    this.__collectionService.setItems(res.data);
  }

  async connectedCallback() {
    super.connectedCallback();

    await this.__fetchData();
  }

  __onSortGroups(a, b) {
    return a[TABLE_ROWS_KEYS.NAME].localeCompare(b[TABLE_ROWS_KEYS.NAME]);
  }

  __refreshAllAdded() {
    this.__allAdded = this.__payerGroups.length
      ? this.__collectionService
          .getFilteredItems()
          .every(i => this.__selectedPayerGroups.some(c => c.id === i.id))
      : null;
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model) {
      const { ruleSetPayerGroups = [] } = this.model;
      this.__selectedPayerGroups = ruleSetPayerGroups;
    }

    if (
      changedProps.has('__payerGroups') ||
      changedProps.has('__selectedPayerGroups')
    ) {
      this.__refreshAllAdded();
    }

    super.update(changedProps);
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="main-header"
        .onCancel="${this.handlers.dismiss}"
        title="Associate Payer Groups"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        Select payer groups to associate with the rule set.
      </div>
    `;
  }

  renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="search-field"
        leadingIcon="neb:search"
        label="${ELEMENTS.searchField.label}"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }

  __renderNoResultsMessage() {
    return html`
      <p id="${ELEMENTS.noResultsMessage.id}" class="no-items-message">
        No results.
      </p>
    `;
  }

  __renderNoPayerGroupsMessage() {
    return html`
      <p id="${ELEMENTS.noGroupsMessage.id}" class="no-items-message">
        There are no payer groups available to associate.
      </p>
    `;
  }

  __renderNoItemsMessage() {
    return this.__searchText
      ? this.__renderNoResultsMessage()
      : this.__renderNoPayerGroupsMessage();
  }

  __renderPaginationControl() {
    return html`
      <neb-pagination
        id="${ELEMENTS.paginationControl.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  __getTableConfig() {
    return {
      tableConfig: TABLE_CONFIG,
      itemName: 'group',
      itemPluralName: 'groups',
      title: 'Associate Payer Group',
    };
  }

  renderTable() {
    return html`
      <div class="container-table">
        <neb-table-add
          id="${ELEMENTS.table.id}"
          class="header-table"
          .config="${this.__getTableConfig()}"
          .allAdded="${this.__allAdded}"
          .onAddAll="${this.handlers.addAll}"
          .layout="${this.layout}"
          .onAdd="${this.handlers.add}"
          .items="${this.__payerGroups}"
          .selectedItems="${this.__selectedPayerGroups}"
        >
          ${this.__renderNoItemsMessage()}
        </neb-table-add>
      </div>

      ${this.__renderPaginationControl()}
    `;
  }

  renderContent() {
    return html`
      <div class="container">
        ${this.renderHeader()} ${this.renderDescription()}
        ${this.renderSearchField()} ${this.renderTable()}
        ${this.renderActionBar()}
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-associate-payer-groups',
  NebOverlayAssociatePayerGroups,
);
