import '../../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../../packages/neb-lit-components/src/components/neb-radio-button';

import { html, css } from 'lit';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { formatDollarAmount } from '../../../../../packages/neb-utils/formatters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkbox: { selector: '[id^=checkbox-]' },
};

class NebTableAllocatedPayments extends NebTable {
  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.config = [];

    this.onSelectItem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      ...this.handlers,
      selectItem: index => this.onSelectItem(index),
    };
  }

  __buildConfig() {
    return [
      { key: 'checkbox', label: '', flex: css`0 0 60px` },
      {
        key: 'authEFT',
        label: 'Check/Auth/EFT',
        flex: css`1 0 0`,
        truncate: true,
      },
      {
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
        truncate: true,
      },
      {
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.config = this.__buildConfig();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: 500px;
          width: 900px;
        }
        .content-container {
          padding: 0px;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checkbox':
        return html`
          <neb-checkbox
            id="checkbox-${rowIndex}"
            .name="${rowIndex}"
            ?checked="${this.model[rowIndex].selected}"
            .onChange="${() => this.__handlers.selectItem(rowIndex)}"
          ></neb-checkbox>
        `;

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

customElements.define(
  'neb-table-allocated-payments',
  NebTableAllocatedPayments,
);
