import './neb-appointment-page-view';

import { css, html, LitElement } from 'lit';

import { getRescheduledAppointmentsById } from '../../../../neb-api-client/src/appointment-api-client';
import { mapToPatientCaseName } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import { fetchOne } from '../../../../neb-api-client/src/patient-cases';
import * as reasonsApi from '../../../../neb-api-client/src/reasons-api-client';
import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

class NebRescheduledAppointmentPageController extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      patientCaseName: String,
      appointmentId: String,
      rescheduledId: String,
      __rescheduledAppointmentDetail: Object,
      __patientId: String,
      __selectedAuthorization: Object,
      __rooms: Array,
      __cancelRescheduleReason: String,
      __allReasons: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.patientCaseName = '';
    this.appointmentId = '';
    this.rescheduledId = '';

    this.__rescheduledAppointmentDetail = { details: {} };
    this.__activeReasons = null;
    this.__patientId = '';
    this.__selectedAuthorization = {};
    this.__rooms = [];
    this.__cancelRescheduleReason = '';
    this.__allReasons = [];

    this.onDismiss = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onDismiss(),
      authorizationDetailChanged: () =>
        this.__getPatientCase(this.__rescheduledAppointmentDetail.caseId),
    };
  }

  async firstUpdated() {
    await Promise.all([
      this.__fetchRescheduledAppointment(),
      this.__fetchReasons(),
    ]);

    await this.__getPatientCase(this.__rescheduledAppointmentDetail.caseId);

    await this.__getRooms();

    this.__setCancelRescheduleReason();

    this.__getPatientId();

    this.__setCancelRescheduleReason();

    await super.firstUpdated();
  }

  async __fetchReasons() {
    try {
      this.__allReasons = await reasonsApi.fetchMany();
      this.__activeReasons = this.__allReasons.filter(item => item.active);
    } catch (error) {
      store.dispatch(openError('An error occurred while fetching data'));
    }
  }

  async __fetchRescheduledAppointment() {
    try {
      const rescheduledAppointmentsDetails =
        await getRescheduledAppointmentsById(this.appointmentId);

      this.__rescheduledAppointmentDetail = rescheduledAppointmentsDetails.find(
        appt => appt.id === this.rescheduledId,
      );
    } catch (error) {
      store.dispatch(openError('An error occurred while fetching data'));
    }
  }

  __getPatientId() {
    this.__patientId = this.__rescheduledAppointmentDetail.patientId;
  }

  __setCancelRescheduleReason() {
    if (this.__rescheduledAppointmentDetail && this.__allReasons.length) {
      const currentReason = this.__allReasons.find(
        reason =>
          reason.id ===
          this.__rescheduledAppointmentDetail.cancelRescheduleReasonId,
      );

      if (currentReason) this.__cancelRescheduleReason = currentReason.name;
    }
  }

  async __getRooms() {
    const rooms = await fetchManyRooms();

    this.__rooms = rooms.filter(
      room =>
        room.locationId === this.__rescheduledAppointmentDetail.locationId &&
        room.active,
    );
  }

  __getScheduledRoom() {
    const scheduledRoom = this.__rooms.find(
      room => room.id === this.__rescheduledAppointmentDetail.resourceId,
    );

    return scheduledRoom || {};
  }

  async __getPatientCase(caseId) {
    if (!caseId || !this.__patientId) {
      this.patientCaseName = '';
      return;
    }

    const patientCase = await fetchOne(this.__patientId, caseId, true);

    this.patientCaseName = mapToPatientCaseName(
      patientCase.name,
      patientCase.onsetSymptomsDate,
    );

    this.__selectedAuthorization = patientCase.patientAuthorization || {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .view {
          flex: 1 0 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-appointment-page-view
        id="${ELEMENTS.view.id}"
        class="view"
        .model="${this.__rescheduledAppointmentDetail}"
        .layout="${this.layout}"
        .patientId="${this.__patientId}"
        .patientCaseName="${this.patientCaseName}"
        .scheduledRoom="${this.__getScheduledRoom()}"
        .cancelRescheduleReason="${this.__cancelRescheduleReason}"
        .selectedAuthorization="${this.__selectedAuthorization}"
        .onAuthorizationDetailChanged="${this.__handlers
          .authorizationDetailChanged}"
        .onClose="${this.__handlers.close}"
      ></neb-appointment-page-view>
    `;
  }
}

window.customElements.define(
  'neb-rescheduled-appointment-page-controller',
  NebRescheduledAppointmentPageController,
);
