import moment from 'moment-timezone';

import { formatPhoneNumber, formatPhoneNumberToRaw } from './formatters';
import { personToRaw } from './person';

export const EMPTY_CONTACT_BASE = {
  id: null,
  parentId: null,
  parentType: 'policyHolder',
  sortIndex: 0,
};
export const EMPTY_PHONE = {
  ...EMPTY_CONTACT_BASE,
  number: '',
  type: 'Mobile',
};
export const EMPTY_ADDRESS = Object.freeze({
  ...EMPTY_CONTACT_BASE,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
});
export const EMPTY_POLICY_HOLDER = Object.freeze({
  id: null,
  firstName: '',
  lastName: '',
  middleName: '',
  sex: '',
  suffix: '',
  insuredName: '',
  dateOfBirth: null,
  address: { ...EMPTY_ADDRESS },
  phones: [],
  insuredSSN: '',
});

export const rawDateOfBirthToDate = raw =>
  raw && raw.length ? new Date(raw) : null;

export const birthDateToRaw = start =>
  start ? moment(start).format('YYYY-MM-DD') : null;
export const formatBirthDate = input =>
  input ? moment(input).format('MM/DD/YYYY') : '';

export const convertPatientAddress = (address, attrs) => {
  if (!address) return { ...EMPTY_ADDRESS, ...attrs };
  return {
    ...EMPTY_ADDRESS,
    ...attrs,
    address1: address.address1 || '',
    address2: address.address2 || '',
    city: address.city || '',
    state: address.state || '',
    zipcode: address.zipcode || '',
  };
};

export const convertPersonAddress = (address, attrs) => {
  if (!address) return { ...EMPTY_ADDRESS, ...attrs };
  return {
    ...EMPTY_ADDRESS,
    ...attrs,
    address1: address.address1 || '',
    address2: address.address2 || '',
    city: address.city || '',
    state: address.state || '',
    zipcode: address.zipcode || '',
    sortIndex: 0,
  };
};

export const convertPhone = (phone, attrs) => {
  if (!phone) return { ...EMPTY_PHONE, ...attrs };
  return { ...EMPTY_PHONE, ...attrs, type: phone.type, number: phone.number };
};
export const formatPhone = phone => ({
  ...phone,
  number: formatPhoneNumber(phone.number),
});

export const convertAndFormatPhone = (phone, attrs) => {
  const converted = convertPhone(phone, attrs);
  return formatPhone(converted);
};

export const convertPatientPhonesAndReturnPrimary = (phones, attrs) => {
  if (!phones) return [];
  const allPhones = phones.reduce((memo, phone) => {
    if (phone.type !== '' && phone.number !== '') {
      memo.push(convertAndFormatPhone(phone, attrs));
    }
    return memo;
  }, []);
  if (allPhones.length) return [allPhones[0]];
  return [];
};

export const convertPersonPhonesAndReturnPrimary = (phones, attrs) => {
  if (!phones) return [];
  const allPhones = phones.reduce((memo, phone) => {
    if (phone.type !== '' && phone.number !== '') {
      memo.push(convertPhone(phone, attrs));
    }
    return memo;
  }, []);
  if (allPhones.length) return [allPhones[0]];
  return [];
};

export const rawPatientToPolicyHolder = (raw, attrs = {}) => {
  const { policyHolderId, policyHolderAddressId, policyHolderPhoneId } = attrs;
  const phones = convertPatientPhonesAndReturnPrimary(raw.phoneNumbers, {
    id: policyHolderPhoneId || null,
    parentId: policyHolderId || null,
    parentType: 'policyHolder',
  });
  const [address] = raw.addresses;
  const policyHolder = {
    id: policyHolderId || null,
    firstName: raw.firstName,
    lastName: raw.lastName,
    middleName: raw.middleName,
    sex: raw.sex,
    suffix: raw.suffix || '',
    insuredName: raw.name || '',
    dateOfBirth: raw.dateOfBirth,
    address: convertPatientAddress(address, {
      id: policyHolderAddressId || null,
      parentId: policyHolderId || null,
      parentType: 'policyHolder',
    }),
    phones,
    insuredSSN: '',
  };

  return policyHolder;
};

export function getInsuredName(rawPerson, currentInsuredName) {
  return !rawPerson.lastName ? rawPerson.name || currentInsuredName || '' : '';
}

export const personToPolicyHolder = (person, attrs = {}) => {
  const {
    policyHolderId,
    policyHolderAddressId,
    policyHolderPhoneId,
    address,
    currentInsuredName,
  } = attrs;

  if (person === null) {
    return { ...EMPTY_POLICY_HOLDER, id: policyHolderId || null };
  }

  const filteredPhones = convertPersonPhonesAndReturnPrimary(person.phones, {
    id: policyHolderPhoneId || null,
    parentId: policyHolderId || null,
    parentType: 'policyHolder',
  });
  const rawPerson = personToRaw(person);

  return {
    id: policyHolderId || null,
    firstName: rawPerson.firstName || '',
    lastName: rawPerson.lastName || '',
    middleName: rawPerson.middleName || '',
    sex: rawPerson.sex || '',
    suffix: rawPerson.suffix || '',
    insuredName: getInsuredName(rawPerson, currentInsuredName),
    dateOfBirth: rawDateOfBirthToDate(person.dateOfBirth),
    address: convertPersonAddress(address || rawPerson.address, {
      id: policyHolderAddressId || null,
      parentId: policyHolderId || null,
      parentType: 'policyHolder',
    }),
    phones: filteredPhones,
    insuredSSN: '',
  };
};

export function mapPolicyHolderPhonesToModel(phones = []) {
  return !phones || phones.length === 0
    ? [{ ...EMPTY_PHONE }]
    : phones.map(phone => ({
        ...EMPTY_PHONE,
        id: phone.id,
        sortIndex: phone.sortIndex,
        parentId: phone.parentId,
        parentType: phone.parentType,
        type: phone.phoneType,
        number: formatPhoneNumber(phone.phoneNumber),
      }));
}

export const policyPhoneNumberText = policyHolder => {
  if (!policyHolder || !policyHolder.phones) return '';
  const [phone] = policyHolder.phones;
  return phone && phone.number && phone.number.length > 0 ? phone.number : '';
};

export const getPolicyHolderPhoneId = policyHolder => {
  if (policyHolder.phones && policyHolder.phones.length > 0) {
    return policyHolder.phones[0].id;
  }

  return null;
};

export const getPolicyHolderIdAttributes = policyHolder => ({
  policyHolderId: policyHolder.id,
  policyHolderAddressId: policyHolder.address.id,
  policyHolderPhoneId: getPolicyHolderPhoneId(policyHolder),
});

export const policyHolderPhoneToRaw = phone => ({
  id: phone.id,
  parentId: phone.parentId,
  parentType: 'policyHolder',
  sortIndex: 0,
  phoneNumber: formatPhoneNumberToRaw(phone.number),
  phoneType: formatPhoneNumberToRaw(phone.type),
});

export const policyHolderPhonesToRaw = (phones, parentId) => {
  if (!phones) return [];
  return phones.reduce((memo, phone) => {
    if (phone.type !== '' && phone.number !== '') {
      memo.push(policyHolderPhoneToRaw({ ...phone, parentId }));
    }

    return memo;
  }, []);
};

export const mapPolicyHolderAddressToModel = address => {
  if (!address) return { ...EMPTY_ADDRESS };
  return {
    id: address.id || null,
    parentId: address.parentId || null,
    parentType: 'policyHolder',
    sortIndex: 0,
    address1: address.address1 || '',
    address2: address.address2 || '',
    city: address.city || '',
    state: address.state || '',
    zipcode: address.zipCode || '',
  };
};

export const policyHolderAddressToRaw = (address, parentId) => {
  if (!address) return { ...EMPTY_ADDRESS };
  return {
    id: address.id || null,
    parentId: parentId || null,
    parentType: 'policyHolder',
    sortIndex: 0,
    address1: address.address1 || null,
    address2: address.address2 || null,
    city: address.city || null,
    state: address.state || null,
    zipCode: address.zipcode || null,
  };
};

export const mapPolicyHolderToModel = raw => ({
  id: raw.id || null,
  firstName: raw.firstName || '',
  lastName: raw.lastName || '',
  middleName: raw.middleName || '',
  sex: raw.sex || '',
  suffix: raw.suffix || '',
  insuredName: raw.insuredName || '',
  dateOfBirth: raw.dateOfBirth || null,
  address: mapPolicyHolderAddressToModel(raw.address),
  phones: mapPolicyHolderPhonesToModel(raw.phones),
  insuredSSN: raw.insuredSSN || '',
});

export const policyHolderToRaw = policyHolder => {
  const policyHolderId = policyHolder.id || null;

  return {
    id: policyHolderId,
    firstName: policyHolder.firstName,
    lastName: policyHolder.lastName,
    middleName: policyHolder.middleName || null,
    sex: policyHolder.sex || null,
    suffix: policyHolder.suffix || null,
    insuredName: policyHolder.insuredName || null,
    dateOfBirth: birthDateToRaw(policyHolder.dateOfBirth) || null,
    address: policyHolderAddressToRaw(policyHolder.address, policyHolderId),
    phones: policyHolderPhonesToRaw(policyHolder.phones, policyHolderId),
    insuredSSN: policyHolder.insuredSSN || null,
  };
};
