import './neb-textfield';
import './neb-select';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';

export const ELEMENTS = {
  select: {
    id: 'select',
  },
  textfield: {
    id: 'textfield',
  },
};

class NebSelectSearch extends LitElement {
  static get properties() {
    return {
      __hasQuickDeleteFF: {
        type: Boolean,
      },

      showMenu: Boolean,
      maxVisibleItems: Number,
      itemHeight: Number,
      thresholdCount: Number,
      name: String,
      value: String,
      error: String,
      helper: String,
      search: String,
      placeholder: String,
      emptyMessage: String,
      items: Array,
      itemMinWidth: Number,
      elements: {
        type: Object,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      pinLabel: {
        reflect: true,
        type: Boolean,
      },
      showSearch: {
        reflect: true,
        type: Boolean,
      },
      forceDown: {
        reflect: true,
        type: Boolean,
      },
      showFullText: {
        reflect: true,
        type: Boolean,
      },
      label: {
        reflect: true,
        type: String,
      },
      wrapText: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__hasQuickDeleteFF = false;

    this.disabled = false;
    this.pinLabel = false;
    this.showSearch = false;
    this.showFullText = false;
    this.showMenu = true;
    this.forceDown = false;
    this.maxVisibleItems = 4;
    this.itemHeight = 48;
    this.thresholdCount = 4;
    this.name = '';
    this.label = '';
    this.value = '';
    this.error = '';
    this.helper = '';
    this.search = '';
    this.placeholder = '';
    this.emptyMessage = 'No items found';
    this.items = [];
    this.wrapText = false;
    this.itemMinWidth = 0;

    this.onChange = () => {};

    this.onSearch = () => {};

    this.onRequest = () => {};

    this.onRenderItem = null;
  }

  __initHandlers() {
    this.__handlers = {
      request: () => this.onRequest(),
      clear: () => {
        if (!this.disabled) {
          this.onChange({
            name: this.name,
            value: '',
          });
        }
      },
      search: e => {
        if (!this.getValue()) {
          this.onSearch({
            name: this.name,
            value: e.value,
          });
        }
      },
      selectItem: e => {
        if (this.items.length) {
          this.onChange({
            name: this.name,
            value: e.value,
          });

          this.onSearch({
            name: this.name,
            value: '',
          });
        }
      },
      emptyMessageRenderer: message => html`
        <p
          style="
          pointer-events: none;
          margin: 0 16px;
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        "
        >
          ${message.label}
        </p>
      `,
      keydown: e => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          this.onChange({
            name: this.name,
            value: '',
          });
        }
      },
    };
  }

  getValue() {
    return this.value && typeof this.value === 'object'
      ? this.value.label
      : this.value;
  }

  getTextfieldValue() {
    if (this.value) {
      return typeof this.value === 'object' ? this.value.label : this.value;
    }

    return this.search;
  }

  getItemRenderer() {
    return this.items.length
      ? this.onRenderItem
      : this.__handlers.emptyMessageRenderer;
  }

  getMenuItems() {
    if (!this.showMenu || this.getValue()) {
      return [];
    }

    return this.items.length ? this.items : [this.emptyMessage];
  }

  firstUpdated() {
    this.elements = {
      textfield: this.shadowRoot.getElementById(ELEMENTS.textfield.id),
    };
  }

  async setFocus() {
    await this.elements.textfield.updateComplete;
    this.elements.textfield.focus();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
        }

        .field {
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  update(changed) {
    if (changed.has('items') || changed.has('itemHeight')) {
      this.__menuItemHeight = this.items.length ? this.itemHeight : 48;
    }

    super.update(changed);
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasQuickDeleteFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_QUICK_DELETE_SEARCH,
    );

    if (this.__hasQuickDeleteFF) {
      this.addEventListener('keydown', this.__handlers.keydown);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.__hasQuickDeleteFF) {
      this.removeEventListener('keydown', this.__handlers.keydown);
    }
  }

  render() {
    return this.showSearch || this.value
      ? html`
          <neb-textfield
            id="${ELEMENTS.textfield.id}"
            class="field"
            leadingIcon="${!this.disabled ? 'neb:search' : ''}"
            trailingIcon="neb:clear"
            .scrollOnKeydown="${false}"
            .maxVisibleItems="${this.maxVisibleItems}"
            .itemHeight="${this.__menuItemHeight}"
            .thresholdCount="${this.thresholdCount}"
            .trailingIcon="${this.getValue() && !this.disabled
              ? 'neb:clear'
              : ''}"
            .label="${this.label}"
            .error="${this.error}"
            .helper="${this.helper}"
            .placeholder="${this.placeholder}"
            .value="${this.getTextfieldValue()}"
            .menuItems="${this.getMenuItems()}"
            .onChange="${this.__handlers.search}"
            .onSelect="${this.__handlers.selectItem}"
            .onRequest="${this.__handlers.request}"
            .onRenderItem="${this.getItemRenderer()}"
            .onClickIcon="${this.__handlers.clear}"
            .itemMinWidth="${this.itemMinWidth}"
            ?showFullText="${this.showFullText}"
            ?readonly="${Boolean(this.getValue())}"
            ?disabled="${this.disabled}"
            ?forceDown="${this.forceDown}"
            ?wrapText="${this.wrapText}"
          ></neb-textfield>
        `
      : html`
          <neb-select
            id="${ELEMENTS.select.id}"
            class="field"
            .scrollOnKeydown="${false}"
            .maxVisibleItems="${this.maxVisibleItems}"
            .itemHeight="${this.__menuItemHeight}"
            .thresholdCount="${this.thresholdCount}"
            .label="${this.label}"
            .error="${this.error}"
            .helper="${this.helper}"
            .placeholder="${this.placeholder}"
            .value="${this.value}"
            .items="${this.getMenuItems()}"
            .onChange="${this.__handlers.selectItem}"
            .onRequest="${this.__handlers.request}"
            .onRenderItem="${this.getItemRenderer()}"
            ?showFullText="${this.showFullText}"
            ?disabled="${this.disabled}"
          ></neb-select>
        `;
  }
}

window.customElements.define('neb-select-search', NebSelectSearch);
