import {
  fetchCaseItems,
  fetchGuarantorItems,
  fetchInsuranceItems,
  fetchPackageItems,
} from '../../../packages/neb-utils/neb-ledger-util';

export async function fetchBillingHeaderInfo(patientId, guarantorId, caseId) {
  const [insurances, guarantors, cases, packages] = await Promise.all([
    fetchInsuranceItems(patientId),
    fetchGuarantorItems(patientId, guarantorId),
    fetchCaseItems({ patientId, caseId }),
    fetchPackageItems(patientId, { includeShared: true }, true),
  ]);
  return { insurances, guarantors, cases, packages };
}
