import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export const postprocessBodySectionsSvg = bodySectionsSvg =>
  unsafeHTML(
    bodySectionsSvg.replace(
      /(\.st1)\s*{[^}]*}/,
      `
        $1 {
          fill: rgba(0, 0, 0, 0);
          cursor: pointer;
        }

        @media (hover: hover) {
          $1:hover {
            fill: rgba(191, 49, 49, 0.5);
          }
        }

        $1.disabled {
          fill: rgba(0, 0, 0, 0.5);
          cursor: initial;
        }

        $1.selected {
          fill: rgba(191, 49, 49, 0.75);
          cursor: initial;
        }
      `,
    ),
  );
