import { STATES } from '../../../../../neb-utils/enums';
import {
  SEX,
  EMPLOYMENT_STATUS,
  PATIENT_STATUS,
  RELATIONSHIP_STATUS,
  CONTACT_METHODS,
} from '../neb-patient-profile-data';

import { InputRenderer } from './neb-patients-table-cell-renderer';
import * as META_IDS from './neb-patients-table-meta-ids';

const CSS_MEDIUM_COLUMN_WIDTH = 'neb-table-medium-width';
const CSS_LARGE_COLUMN_WIDTH = 'neb-table-large-width';
const CSS_EXTRA_LARGE_COLUMN_WIDTH = 'neb-table-extra-large-width';
export const LASTNAME_COLUMN_META = Object.freeze({
  id: META_IDS.LASTNAME_ID,
  column: 'Last Name',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'lastName',
  input: {
    renderer: InputRenderer.text,
    options: {
      required: true,
    },
  },
});
export const FIRSTNAME_COLUMN_META = Object.freeze({
  id: META_IDS.FIRSTNAME_ID,
  column: 'First Name',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'firstName',
  input: {
    renderer: InputRenderer.text,
    options: {
      required: true,
    },
  },
});
export const MIDDLENAME_COLUMN_META = Object.freeze({
  id: META_IDS.MIDDLENAME_ID,
  column: 'Middle Name',
  cellWidth: CSS_LARGE_COLUMN_WIDTH,
  data: 'middleName',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const PREFERREDNAME_COLUMN_META = Object.freeze({
  id: META_IDS.PREFERREDNAME_ID,
  column: 'Preferred Name',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'preferredName',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const SUFFIX_COLUMN_META = Object.freeze({
  id: META_IDS.SUFFIX_ID,
  column: 'Suffix',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'suffix',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const DOB_COLUMN_META = Object.freeze({
  id: META_IDS.DOB_ID,
  column: 'DOB',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'dateOfBirth',
  input: {
    renderer: InputRenderer.date,
    options: {
      required: true,
    },
  },
});
export const SSN_COLUMN_META = Object.freeze({
  id: META_IDS.SSN_ID,
  column: 'SSN',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'ssn',
  input: {
    renderer: InputRenderer.ssn,
    options: {},
  },
});
export const MEDICALRECORDNUMBER_COLUMN_META = Object.freeze({
  id: META_IDS.MEDICALRECORDNUMBER_ID,
  column: 'MRN',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'medicalRecordNumber',
  input: {
    renderer: InputRenderer.number,
    options: {},
  },
});
export const RELATIONSHIP_COLUMN_META = Object.freeze({
  id: META_IDS.RELATIONSHIP_ID,
  column: 'Relationship',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'relationshipStatus',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: RELATIONSHIP_STATUS,
    },
  },
});
export const EMPLOYMENT_COLUMN_META = Object.freeze({
  id: META_IDS.EMPLOYMENT_ID,
  column: 'Employment',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'employmentStatus',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: EMPLOYMENT_STATUS,
    },
  },
});
export const SEX_COLUMN_META = Object.freeze({
  id: META_IDS.SEX_ID,
  column: 'Sex',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'sex',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: SEX,
    },
  },
});
export const PHONE1_COLUMN_META = Object.freeze({
  id: META_IDS.PHONE1_ID,
  column: 'Phone # 1',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberOne',
  input: {
    renderer: InputRenderer.phoneNumber,
    options: {},
  },
});
export const PHONETYPE1_COLUMN_META = Object.freeze({
  id: META_IDS.PHONETYPE1_ID,
  column: 'Phone # 1 Type',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberOneType',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: CONTACT_METHODS,
    },
  },
});
export const PHONE2_COLUMN_META = Object.freeze({
  id: META_IDS.PHONE2_ID,
  column: 'Phone # 2',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberTwo',
  input: {
    renderer: InputRenderer.phoneNumber,
    options: {},
  },
});
export const PHONETYPE2_COLUMN_META = Object.freeze({
  id: META_IDS.PHONETYPE2_ID,
  column: 'Phone # 2 Type',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberTwoType',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: CONTACT_METHODS,
    },
  },
});
export const PHONE3_COLUMN_META = Object.freeze({
  id: META_IDS.PHONE3_ID,
  column: 'Phone # 3',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberThree',
  input: {
    renderer: InputRenderer.phoneNumber,
    options: {},
  },
});
export const PHONETYPE3_COLUMN_META = Object.freeze({
  id: META_IDS.PHONETYPE3_ID,
  column: 'Phone # 3 Type',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'phoneNumberThreeType',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: CONTACT_METHODS,
    },
  },
});
export const EMAIL1_COLUMN_META = Object.freeze({
  id: META_IDS.EMAIL1_ID,
  column: 'Email 1',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'emailAddressOne',
  input: {
    renderer: InputRenderer.email,
    options: {},
  },
});
export const EMAIL2_COLUMN_META = Object.freeze({
  id: META_IDS.EMAIL2_ID,
  column: 'Email 2',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'emailAddressTwo',
  input: {
    renderer: InputRenderer.email,
    options: {},
  },
});
export const EMAIL3_COLUMN_META = Object.freeze({
  id: META_IDS.EMAIL3_ID,
  column: 'Email 3',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'emailAddressThree',
  input: {
    renderer: InputRenderer.email,
    options: {},
  },
});
export const ADDRESSLINE1_COLUMN_META = Object.freeze({
  id: META_IDS.ADDRESSLINE1_ID,
  column: 'Address Line 1',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'addressOne',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const ADDRESSLINE2_COLUMN_META = Object.freeze({
  id: META_IDS.ADDRESSLINE2_ID,
  column: 'Address Line 2',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'addressTwo',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const CITY_COLUMN_META = Object.freeze({
  id: META_IDS.CITY_ID,
  column: 'City',
  cellWidth: CSS_LARGE_COLUMN_WIDTH,
  data: 'city',
  input: {
    renderer: InputRenderer.text,
    options: {},
  },
});
export const STATE_COLUMN_META = Object.freeze({
  id: META_IDS.STATE_ID,
  column: 'State',
  cellWidth: CSS_MEDIUM_COLUMN_WIDTH,
  data: 'state',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: STATES,
    },
  },
});
export const ZIPCODE_COLUMN_META = Object.freeze({
  id: META_IDS.ZIPCODE_ID,
  column: 'Zip Code',
  cellWidth: CSS_LARGE_COLUMN_WIDTH,
  data: 'zipcode',
  input: {
    renderer: InputRenderer.postalCode,
    options: {},
  },
});
export const STATUS_COLUMN_META = Object.freeze({
  id: META_IDS.STATUS_ID,
  column: 'Status',
  cellWidth: CSS_EXTRA_LARGE_COLUMN_WIDTH,
  data: 'status',
  input: {
    renderer: InputRenderer.singleSelect,
    options: {
      items: PATIENT_STATUS,
    },
  },
});
export const CSV_TABLE_METADATA = [
  LASTNAME_COLUMN_META,
  FIRSTNAME_COLUMN_META,
  MIDDLENAME_COLUMN_META,
  PREFERREDNAME_COLUMN_META,
  SUFFIX_COLUMN_META,
  DOB_COLUMN_META,
  SSN_COLUMN_META,
  MEDICALRECORDNUMBER_COLUMN_META,
  RELATIONSHIP_COLUMN_META,
  EMPLOYMENT_COLUMN_META,
  SEX_COLUMN_META,
  PHONE1_COLUMN_META,
  PHONETYPE1_COLUMN_META,
  PHONE2_COLUMN_META,
  PHONETYPE2_COLUMN_META,
  PHONE3_COLUMN_META,
  PHONETYPE3_COLUMN_META,
  EMAIL1_COLUMN_META,
  EMAIL2_COLUMN_META,
  EMAIL3_COLUMN_META,
  ADDRESSLINE1_COLUMN_META,
  ADDRESSLINE2_COLUMN_META,
  CITY_COLUMN_META,
  STATE_COLUMN_META,
  ZIPCODE_COLUMN_META,
];
export const RECORD_TABLE_METADATA = [
  ...CSV_TABLE_METADATA,
  STATUS_COLUMN_META,
];
