import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  backButton: {
    id: 'button-back',
  },
  confirmButton: {
    id: 'button-confirm',
  },
  cancelButton: {
    id: 'button-cancel',
  },
};

class NebActionBarPaged extends LitElement {
  static get properties() {
    return {
      backLabel: String,
      cancelLabel: String,
      confirmLabel: String,
      processing: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          margin: auto 0 0 0;
          height: 80px;
          white-space: nowrap;
        }

        .container {
          display: flex;
          height: 100%;
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .right-aligned {
          margin-left: auto;
        }

        .button {
          margin-right: 10px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initHandlers();

    this.__initState();
  }

  __initState() {
    this.backLabel = '';
    this.cancelLabel = 'Cancel';
    this.confirmLabel = 'Next';
    this.processing = false;

    this.onBack = () => {};

    this.onConfirm = () => {};

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      back: () => {
        this.shadowRoot.getElementById(ELEMENTS.backButton.id).blur();
        this.onBack();
      },
      confirm: () => {
        this.shadowRoot.getElementById(ELEMENTS.confirmButton.id).blur();
        this.onConfirm();
      },
      cancel: () => {
        this.shadowRoot.getElementById(ELEMENTS.cancelButton.id).blur();
        this.onCancel();
      },
    };
  }

  __getButtonRole() {
    return this.confirmLabel.length ? BUTTON_ROLE.OUTLINE : BUTTON_ROLE.CONFIRM;
  }

  __renderConfirmButton() {
    return this.confirmLabel
      ? html`
          <neb-button
            id="${ELEMENTS.confirmButton.id}"
            class="button"
            .label="${this.processing ? 'Saving' : this.confirmLabel}"
            .processing="${this.processing}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
            unelevated
          ></neb-button>
        `
      : '';
  }

  __renderBackButton() {
    return this.backLabel
      ? html`
          <neb-button
            id="${ELEMENTS.backButton.id}"
            class="button"
            .label="${this.backLabel}"
            .role="${this.__getButtonRole()}"
            .onClick="${this.__handlers.back}"
            ?disabled="${this.processing}"
          ></neb-button>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${this.__getButtonRole()}"
          .onClick="${this.__handlers.cancel}"
          ?disabled="${this.processing}"
        ></neb-button>

        <div class="right-aligned">
          ${this.__renderBackButton()} ${this.__renderConfirmButton()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-action-bar-paged', NebActionBarPaged);
