import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_CAPTION,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

import { FILL_COLOR, SVG_TYPES } from './neb-macro-spine-section';
import { DIRECTION, SPINE_SECTION } from './utils/spine';
import { SVG } from './utils/spine-svgs';

export const ELEMENTS = {
  spineSections: {
    selector: 'neb-macro-spine-section',
  },
  leftPelvis: {
    id: 'left-pelvis',
  },
  rightPelvis: {
    id: 'right-pelvis',
  },
};

const IDS_SECTION = {
  OCCIPUT: 'occiput-section',
  C_DISKS: Array(7)
    .fill(0)
    .map((n, i) => `c-section-${n + i}`),
  T_DISKS: Array(12)
    .fill(0)
    .map((n, i) => `t-section-${n + i}`),
  L_DISKS: Array(5)
    .fill(0)
    .map((n, i) => `l-section-${n + i}`),
  SACRUM: 'sacrum-section',
  COCCYX: 'coccyx-section',
  PELVIS: {
    LEFT: 'pelvis-left',
    RIGHT: 'pelvis-right',
  },
};

class NebMacroSpine extends LitElement {
  static get properties() {
    return {
      selectedSections: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .spine-container {
          display: grid;
          margin: 5px 0 ${CSS_SPACING};
          grid-template-columns: 145px 127px 250px;
          justify-content: space-evenly;
        }

        .column {
          flex: 1 0 0;
        }

        .column-extended {
          position: relative;
          flex: 2 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .pelvis-container {
          position: absolute;
          bottom: 40px;
        }

        .pelvis-container-left {
          left: 0;
        }

        .pelvis-container-right {
          right: 0;
        }

        .pelvis-label {
          cursor: pointer;
          user-select: none;
          position: absolute;
          top: calc(50% - 8px);
          font-size: ${CSS_FONT_SIZE_CAPTION};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          transform: translate(-50%, -50%);
        }

        .pelvis-label-left {
          left: calc(50% - 6px);
        }

        .pelvis-label-right {
          left: calc(50% + 6px);
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedSections = [];

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: (direction, section) => this.onClick(direction, section),
      clickLeftPelvis: () => this.onClick(DIRECTION.LEFT, SPINE_SECTION.PELVIS),
      clickRightPelvis: () =>
        this.onClick(DIRECTION.RIGHT, SPINE_SECTION.PELVIS),
    };
  }

  __getDirectionForSection(sectionName) {
    const selectedSection = this.selectedSections.find(s =>
      s.endsWith(sectionName),
    );

    if (!selectedSection) {
      return null;
    }

    const parsedDirection = selectedSection.replace(sectionName, '').trim();
    return parsedDirection === '' ? DIRECTION.CENTER : parsedDirection;
  }

  __renderSpineSection({ name, label, id, svgType }) {
    return html`
      <neb-macro-spine-section
        id="${id}"
        .label="${label}"
        .value="${name}"
        .selectedDirection="${this.__getDirectionForSection(name)}"
        .svgType="${svgType}"
        .onSectionSelected="${this.__handlers.click}"
      >
      </neb-macro-spine-section>
    `;
  }

  __renderDiskSections(letter) {
    return IDS_SECTION[`${letter}_DISKS`].map((inputSection, i) =>
      this.__renderSpineSection({
        name: `${letter}${i + 1}`,
        label: `${letter}${i + 1}`,
        id: IDS_SECTION[`${letter}_DISKS`][i],
        svgType: SVG_TYPES[`${letter}_DISKS`],
      }),
    );
  }

  __getPelvisColor(dir) {
    return this.selectedSections.includes(`${dir} ${SPINE_SECTION.PELVIS}`)
      ? FILL_COLOR
      : 'none';
  }

  __renderPelvis() {
    return html`
      <div class="pelvis-container pelvis-container-left">
        ${SVG.PELVIS.LEFT(
          IDS_SECTION.PELVIS.LEFT,
          this.__getPelvisColor(DIRECTION.LEFT),
          this.__handlers.clickLeftPelvis,
        )}

        <div
          id="${ELEMENTS.leftPelvis.id}"
          class="pelvis-label pelvis-label-left"
          @click="${this.__handlers.clickLeftPelvis}"
        >
          P
        </div>
      </div>

      <div class="pelvis-container pelvis-container-right">
        ${SVG.PELVIS.RIGHT(
          IDS_SECTION.PELVIS.RIGHT,
          this.__getPelvisColor(DIRECTION.RIGHT),
          this.__handlers.clickRightPelvis,
        )}

        <div
          class="pelvis-label pelvis-label-right"
          id="${ELEMENTS.rightPelvis.id}"
          @click="${this.__handlers.clickRightPelvis}"
        >
          P
        </div>
      </div>
    `;
  }

  __renderCervicalSection() {
    return html`
      <div class="column">
        ${this.__renderSpineSection({
          name: SPINE_SECTION.OCCIPUT,
          label: 'OCC',
          id: IDS_SECTION.OCCIPUT,
          svgType: SVG_TYPES.OCCIPUT,
        })}
        ${IDS_SECTION.C_DISKS.map((inputSection, i) =>
          this.__renderSpineSection({
            name: `C${i + 1}`,
            label: `C${i + 1}`,
            id: IDS_SECTION.C_DISKS[i],
            svgType: SVG_TYPES.C_DISKS,
          }),
        )}
      </div>
    `;
  }

  __renderThoracicSection() {
    return html`
      <div class="column">
        ${IDS_SECTION.T_DISKS.map((inputSection, i) =>
          this.__renderSpineSection({
            name: `T${i + 1}`,
            label: `T${i + 1}`,
            id: IDS_SECTION.T_DISKS[i],
            svgType: SVG_TYPES.T_DISKS,
          }),
        )}
      </div>
    `;
  }

  __renderLumbarSection() {
    return html`
      <div class="column column-extended">
        ${this.__renderPelvis()}${this.__renderDiskSections('L')}
        ${this.__renderSpineSection({
          name: SPINE_SECTION.SACRUM,
          label: 'S',
          id: IDS_SECTION.SACRUM,
          svgType: SVG_TYPES.SACRUM,
        })}
        ${this.__renderSpineSection({
          name: SPINE_SECTION.COCCYX,
          label: 'C',
          id: IDS_SECTION.COCCYX,
          svgType: SVG_TYPES.COCCYX,
        })}
      </div>
    `;
  }

  render() {
    return html`
      <div class="spine-container">
        ${this.__renderCervicalSection()} ${this.__renderThoracicSection()}
        ${this.__renderLumbarSection()}
      </div>
    `;
  }
}

customElements.define('neb-macro-spine', NebMacroSpine);
