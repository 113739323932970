import '@polymer/polymer/polymer-legacy';

import { html } from 'lit';

import { CSS_FONT_SIZE_BODY, CSS_FONT_SIZE_HEADER } from './neb-variables';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebTypography = () => html`
  <style>
    .neb-font-header {
      @apply --neb-font-header;
    }

    .neb-font-header-highlight {
      @apply --neb-font-header;
      color: var(--neb-font-color-highlight);
    }

    .neb-font-header-highlight-regular {
      font-size: var(--neb-font-size-header);
      color: var(--neb-font-color-highlight);
    }
    .neb-font-caption-regular {
      font-size: var(--neb-font-size-caption);
      color: var(--neb-font-color-primary);
      font-weight: normal;
    }

    .neb-font-caption-bold {
      font-size: var(--neb-font-size-caption);
      font-weight: bold;
      color: var(--neb-font-color-primary);
    }

    .neb-font-regular-bold {
      font-size: var(--neb-font-size-body);
      font-family: var(--neb-font-family);
      font-weight: bold;
    }

    .neb-font-regular-bold-highlight {
      font-size: var(--neb-font-size-body);
      font-family: var(--neb-font-family);
      font-weight: bold;
      color: var(--neb-font-color-highlight);
    }

    .neb-font-regular-highlight {
      font-size: var(--neb-font-size-body);
      font-family: var(--neb-font-family);
      color: var(--neb-font-color-highlight);
    }

    .neb-font-regular-secondary {
      font-size: var(--neb-font-size-body);
      font-family: var(--neb-font-family);
      color: var(--neb-font-color-secondary);
    }

    .neb-font-secondary {
      font-size: var(--neb-font-size-caption);
      color: var(--neb-font-color-secondary);
    }

    .neb-font-regular-primary {
      font-size: var(--neb-font-size-body);
      font-family: var(--neb-font-family);
      color: var(--neb-font-color-primary);
    }
  </style>
`;
_documentContainer.innerHTML = `
<style>
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|Open+Sans:bold');
</style>

<custom-style>
  <style is="custom-style">

    html {

      --neb-font-family: 'Open Sans', sans-serif;
      font-family: var(--neb-font-family);
      --paper-font-common-base: {
        font-family: var(--neb-font-family);
        font-size: var(--neb-font-size-body);
      }
      --paper-font-common-code: {
        font-family: var(--neb-font-family);
        font-size: var(--neb-font-size-body);
      }
      --paper-font-subhead: {
        font-size: var(--neb-font-size-body);
      }

      --neb-font-size-body: ${CSS_FONT_SIZE_BODY};
      --neb-font-size-header: 16px;
      --neb-font-size-caption: 12px;
      font-size: var(--neb-font-size-body);

      --neb-font-color-primary: var(--neb-color-dark-primary);
      --neb-font-color-highlight: var(--neb-color-primary-4);
      --neb-font-color-secondary: var(--neb-color-grey-1);
      --neb-font-color-inactive: var(--neb-color-grey-2);
      --neb-font-color-white: var(--neb-color-light-primary);
      color: var(--neb-font-color-primary);

      --neb-font-header: {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: bold;
        color: var(--neb-font-color-primary);
      }

    }
  </style>
</custom-style>
<dom-module id="neb-typography">
  <template>
    ${nebTypography()}
  </template>
</dom-module>
`;

document.head.appendChild(_documentContainer);
export const renderTypographyStyles = nebTypography;
