import '../../../neb-material-design/src/components/neb-loading-spinner';

import { LitElement, html, css } from 'lit';

import { CSS_SPACING } from '../../../neb-styles/neb-variables';
import { GLOBAL_INDICATOR_MIN_SHOW_TIME } from '../../../neb-utils/env';

import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  loadingOverlay: { id: 'loading-overlay' },
  buttonCancel: { id: 'button-cancel' },
};

const TRANSITION_DELAY = 1000;

class NebLoadingOverlay extends LitElement {
  static get properties() {
    return {
      show: {
        type: Boolean,
        reflect: true,
      },
      title: {
        type: String,
      },
      showDelay: {
        type: Number,
      },
      minShowTime: {
        type: Number,
      },
      showCancel: {
        type: Boolean,
        reflect: true,
      },
      global: { type: Boolean, reflect: true },
      transition: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super();

    this.title = '';
    this.showDelay = 0;
    this.transitionDelay = TRANSITION_DELAY;
    this.minShowTime = GLOBAL_INDICATOR_MIN_SHOW_TIME || 0;
    this.show = false;
    this.global = false;
    this.showCancel = false;

    this.__doShow = false;
    this.__showStartTime = 0;
    this.__timeoutIdShow = null;
    this.__timeoutIdTransition = null;
    this.transition = false;

    this.onCancel = () => {};
    this.__initHandlers();
  }

  get show() {
    return this.__show;
  }

  set show(newValue) {
    if (this.__timeoutIdShow) {
      clearTimeout(this.__timeoutIdShow);
      this.transition = false;
      this.__timeoutIdShow = null;
    }

    if (this.__timeoutIdTransition) {
      clearTimeout(this.__timeoutIdTransition);
      this.__timeoutIdTransition = null;
    }

    if (this.__show !== newValue) {
      this.__show = newValue;

      this.__timeoutIdShow = this.__show
        ? this.__scheduleShow()
        : this.__scheduleHide();
    }
  }

  __initHandlers() {
    this.__handlers = {
      onCancel: () => this.onCancel(),
    };
  }

  __scheduleShow() {
    return setTimeout(() => {
      this.__doShow = true;
      this.__showStartTime = Date.now();

      this.requestUpdate();

      if (this.global) {
        this.__timeoutIdTransition = setTimeout(() => {
          this.transition = true;
        }, this.transitionDelay);
      }
    }, this.showDelay);
  }

  __scheduleHide() {
    const showTimeSoFar = Date.now() - this.__showStartTime;
    const showTimeRemaining = this.minShowTime - showTimeSoFar;
    const hideDelay = Math.max(0, showTimeRemaining);

    return setTimeout(() => {
      this.__doShow = false;
      this.requestUpdate();

      setTimeout(() => {
        this.transition = false;
      });
    }, hideDelay);
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        z-index: 1;
        opacity: 0;
        transition-delay: 2s;
        transition-property: opacity;
      }

      :host(:not([global])) .container,
      :host([transition]) .container {
        opacity: 1;
      }

      .container .spinner {
        margin-bottom: 10px;
      }

      .spinner {
        opacity: 0;
        transition-delay: 2.5s;
        transition-property: opacity;
      }
      :host(:not([global])) .spinner,
      :host([transition][global]) .spinner {
        opacity: 1;
      }
      .button {
        margin-top: ${CSS_SPACING};
      }
    `;
  }

  __renderButtonCancel() {
    return this.show && this.showCancel
      ? html`
          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            role="${BUTTON_ROLE.OUTLINE}"
            class="button"
            label="Cancel"
            .onClick="${this.__handlers.onCancel}"
          ></neb-button>
        `
      : html``;
  }

  __renderSpinnerAndText() {
    return html`
      <neb-loading-spinner class="spinner"></neb-loading-spinner>
      <div>${this.title}</div>
      ${this.__renderButtonCancel()}
    `;
  }

  render() {
    return this.__doShow
      ? html`
          <div id="${ELEMENTS.loadingOverlay.id}" class="container">
            ${this.__renderSpinnerAndText()}
          </div>
        `
      : '';
  }
}

customElements.define('neb-loading-overlay', NebLoadingOverlay);
