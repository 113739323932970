import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  appointmentTypeLink: {
    id: 'link-appointment-type',
  },
  providerAvailabilityLink: {
    id: 'link-provider-availability',
  },
  textPrefix: {
    id: 'text-prefix',
  },
  textPostfix: {
    id: 'text-postfix',
  },
};

class NebSchedulingErrorHandlingPage extends LitElement {
  static get properties() {
    return {
      small: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.small = false;
    this.__handlers = {
      navigateToAppointmentTypes: () => {
        window.location.href = '/settings/#/appointment-types';
      },
      navigateToProviderAvailability: () => {
        window.location.href = '/settings/#/availability';
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          opacity: 0.85;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([small]) {
          opacity: 0.95;
        }

        :host([small]) .container {
          margin-top: 100px;
        }

        .container {
          margin-top: 150px;
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          width: 50px;
          height: 50px;
        }

        .header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .text {
          padding-left: 25px;
          padding-right: 25px;
        }

        .text-underline,
        .text-underline:link,
        .text-underline:visited {
          text-decoration: underline;
          cursor: pointer;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <neb-icon class="icon" icon="neb:calendar"></neb-icon>
        <div id="${ELEMENTS.textPrefix.id}" class="header text">
          Please ensure there is at least one active
          <a
            id="${ELEMENTS.appointmentTypeLink.id}"
            class="link text-underline"
            @click="${this.__handlers.navigateToAppointmentTypes}"
            >Appointment Type</a
          >
          and your
          <a
            id="${ELEMENTS.providerAvailabilityLink.id}"
            class="link text-underline"
            @click="${this.__handlers.navigateToProviderAvailability}"
            >Provider’s Availability</a
          >
          is configured.
        </div>
        <div id="${ELEMENTS.textPostfix.id}" class="header text">
          Please contact Customer Support if you have any additional questions.
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-scheduling-error-handling-page',
  NebSchedulingErrorHandlingPage,
);
