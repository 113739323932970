import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { getLowInventoryMessage } from '../../../../src/api-clients/inventory';
import { formatToTransferBalanceModel } from '../../../../src/formatters/transfer-balance';
import { createAndAddInvoiceToLineItems } from '../../../neb-api-client/src/invoice-api-client';
import { removeLineItemsFromInvoice } from '../../../neb-api-client/src/ledger/invoices';
import {
  getLineItems,
  voidLineItems,
} from '../../../neb-api-client/src/ledger/line-items';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { TABS } from '../components/forms/neb-form-allocation-charges';

import { openOverlay, OVERLAY_KEYS } from './overlay-constants';

const pushSuccess = message => {
  store.dispatch(openSuccess(message));
};

const pushError = (message, err) => {
  console.error(err);

  store.dispatch(openError(message));
};

const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);

const execute = async ({
  method,
  onChange,
  successMessage,
  errorMessage,
  processResult,
}) => {
  let result;

  try {
    result = await method();

    await onChange();
    pushSuccess(successMessage);
  } catch (e) {
    pushError(errorMessage, e);
  }

  if (processResult && result) {
    await processResult(result);
  }
};

const checkLowInventory = async ({ postedChargeIds, postedChargeCodes }) => {
  try {
    const inventoryMessage = await getLowInventoryMessage({
      chargeIds: postedChargeIds,
      codes: postedChargeCodes,
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  } catch (e) {
    // No-op.
  }
};

const performAction = async ({ popupConfig, ...methodConfig }) => {
  if (popupConfig) {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      confirmText: 'Yes',
      cancelText: 'No',
      ...popupConfig,
    });

    if (!result) {
      return undefined;
    }
  }

  return execute(methodConfig);
};

const HANDLERS = {
  allocatePayment: async ({ patientId, selectedLineItemIds }, onChange) => {
    await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
      patientId,
      selectedTab: TABS.OUTSTANDING,
      selectedLineItemIds,
    });

    return onChange({ selectedLineItemIds });
  },

  createInvoice: ({ patientId, selectedLineItemIds }, onChange) =>
    performAction({
      onChange: () =>
        onChange({ lineItemIds: selectedLineItemIds, selectedLineItemIds }),

      method: () =>
        createAndAddInvoiceToLineItems(selectedLineItemIds, patientId),
      successMessage: 'Invoice created successfully',
      errorMessage: 'An error has occurred when creating the invoice',
    }),

  removeFromInvoice: ({ selectedLineItemIds, invoiceIds }, onChange) => {
    const popupConfig = {
      title: 'Remove From Invoice',
      message:
        'Are you sure you want to remove the selected charges from their current invoice?',
    };

    return performAction({
      popupConfig,
      onChange: () => onChange({ removeSelectedItems: true }),
      method: () => removeLineItemsFromInvoice(selectedLineItemIds, invoiceIds),
      successMessage: 'Charge(s) removed from invoice successfully',
      errorMessage:
        'An error has occurred when removing charge(s) from invoice',
    });
  },

  unpost: ({ selectedLineItemIds, someAllocated }, onChange) => {
    const plural = selectedLineItemIds.length > 1;

    const chargeText = plural
      ? ['One or more of the', 'charges']
      : ['The', 'charge'];

    const popupConfig = {
      title: `Unpost ${capitalize(chargeText[1])}`,
      message: html`
        <div>
          ${chargeText[0]} ${chargeText[1]} that you are unposting has an
          allocated payment. Unposting the ${chargeText[1]} will remove all
          allocations and the ${chargeText[1]} will return to the encounter as
          Not Posted.
        </div>

        <br />
        <div>
          Are you sure you want to unpost the selected ${chargeText[1]}?
        </div>
      `,
    };

    return performAction({
      ...(someAllocated && { popupConfig }),
      onChange: () => onChange({ removeSelectedItems: true }),
      method: () =>
        voidLineItems({
          lineItemIds: selectedLineItemIds,
        }),
      processResult: args => checkLowInventory(args),
      successMessage: 'Charge(s) unposted successfully',
      errorMessage: 'An error has occurred when unposting the charge(s)',
    });
  },

  voidCharges: ({ selectedLineItemIds }, onChange) => {
    const plural = selectedLineItemIds.length > 1;

    const chargeText = plural ? 'these charges' : 'this charge';
    const popupConfig = {
      title: `Void ${plural ? 'Charges' : 'Charge'}`,
      message: `Are you sure you want to void ${chargeText}? You may not update or view ${chargeText} once voided.`,
    };

    return performAction({
      popupConfig,
      onChange: () => onChange({ removeSelectedItems: true }),
      method: () =>
        voidLineItems({
          lineItemIds: selectedLineItemIds,
          notifyCharting: false,
        }),
      processResult: args => checkLowInventory(args),
      successMessage: 'Charge(s) voided successfully',
      errorMessage: 'An error has occurred when voiding charge(s)',
    });
  },

  transferBalance: async ({ selectedLineItemIds }, onChange) => {
    const selectedLineItems = await getLineItems(selectedLineItemIds, 1);
    const transferBalanceItems =
      formatToTransferBalanceModel(selectedLineItems);

    await openPopup(POPUP_RENDER_KEYS.TRANSFER_BALANCE, {
      transferBalanceItems,
    });

    return onChange({ transferBalanceItems });
  },
};

export const handleBulkAction = (actionId, actionDetails, onChange) =>
  HANDLERS[actionId](actionDetails, onChange);
