import { getAllEncounters } from '../../../neb-api-client/src/encounters-api-client';
import { store } from '../../../neb-redux/neb-redux-store';
import { formatEncounterCards } from '../../../neb-utils/neb-charting-util';

export class EncounterService {
  constructor(callback) {
    this.patientId = null;
    this.__shouldFetch = false;
    this.onStateChanged = callback;
  }

  connect() {
    this.__storeUnsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );

    this._stateChanged(store.getState());
  }

  disconnect() {
    this.__storeUnsubscribe();
  }

  async _stateChanged({
    providers: { item: providers } = {},
    appointmentTypes: { items: apptTypes } = {},
    session: { item: session } = {},
  }) {
    if (session && this.__shouldFetch) {
      await this.__getEncounters(providers, apptTypes, (encounters, count) => {
        this.onStateChanged(encounters, count);
      });
    }
  }

  async __getEncounters(providers, apptTypes, callback) {
    if (providers.length > 0 && apptTypes.length > 0) {
      const { data: encounters, count } = await getAllEncounters({
        patientId: this.patientId,
      });
      callback(formatEncounterCards(encounters, providers, apptTypes), count);
      this.__shouldFetch = false;
    }
  }

  update(patientId) {
    this.patientId = patientId;
    this.__shouldFetch = true;

    this._stateChanged(store.getState());
  }
}
