import '../inputs/neb-textfield';
import '../inputs/neb-select';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { BUTTON_ROLE } from '../neb-button';

import NebForm from './neb-form';
import { ASSIGNED_TO_OPTIONS, CLINIC_OPTION } from './utils';

export const ELEMENTS = {
  okButton: { id: 'ok-button' },
  cancelButton: { id: 'cancel-button' },
  issueTextfield: {
    id: 'issue-description',
  },
  assignedToDropdown: {
    id: 'assigned-to-dropdown',
  },
};

class NebFormClaimAction extends NebForm {
  static createModel() {
    return {
      description: '',
      assignedTo: CLINIC_OPTION.value,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .buttons-container {
          display: flex;
          gap: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      onChangeAssignedTo: ({ value: item, event }) => {
        if (event === 'select') {
          this.formService.apply('assignedTo', item.value);
        } else {
          this.formService.apply('assignedTo', item);
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        description: [isRequired()],
        assignedTo: [isRequired()],
      },
    };
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.okButton.id}"
          class="ok-button"
          label="Ok"
          .onClick="${this.handlers.save}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.issueTextfield.id}"
        name="description"
        placeholder="Issue"
        class="container-input"
        maxLength="255"
        .value="${this.state.description}"
        .onChange="${this.handlers.change}"
        .error="${this.errors.description}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.assignedToDropdown.id}"
        name="assignedTo"
        placeholder="Assigned To"
        .items="${ASSIGNED_TO_OPTIONS}"
        .value="${this.state.assignedTo}"
        .onChange="${this.handlers.onChangeAssignedTo}"
        .error="${this.errors.assignedTo}"
      ></neb-select>
    `;
  }
}

window.customElements.define('neb-form-claim-action', NebFormClaimAction);
