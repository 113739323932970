import '../../../../neb-lit-components/src/components/encounter/neb-encounter-summary';

import { LitElement, html, css } from 'lit';

import '../../../../neb-lit-components/src/components/neb-dropdown';
import '../../../../neb-lit-components/src/components/neb-loading-overlay';
import '../../../../neb-lit-components/src/components/encounter/neb-encounter-card';
import '../../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../../neb-lit-components/src/components/neb-popup-header';
import '../../../../neb-lit-components/src/components/inputs/neb-select';

import { BUTTON_ROLE } from '../../../../neb-lit-components/src/components/neb-button';
import { disableSignIfEncounterOnly } from '../../../../neb-lit-components/src/utils/encounter-only-util';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { NO_PRIOR_ENCOUNTERS_MESSAGE } from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  containerHeader: {
    id: 'container-header',
  },
  encounterSelect: {
    id: 'encounter-select',
  },
  header: {
    id: 'header',
  },
  spinner: {
    id: 'spinner',
  },
  signButton: {
    id: 'button-sign',
    label: 'SIGN',
  },
  printButton: {
    id: 'button-print',
    label: 'PRINT',
  },
  editButton: {
    id: 'button-edit',
    label: 'EDIT',
  },
  deleteButton: {
    id: 'button-delete',
    label: 'DELETE',
  },
  containerLinks: {
    id: 'container-links',
  },
  viewEncounterHistoryLink: {
    id: 'link-view-encounter-history',
  },
  buttonVisitSummary: {
    id: 'button-visit-summary',
    label: 'Open Visit Summary',
  },
  reopenButton: {
    id: 'button-reopen',
    label: 'REOPEN',
  },
};

const ENCOUNTER_CARD_HEIGHT = 98;
const ENCOUNTER_CARD_HEIGHT_LOCATION = 130;

const TEXT_LINK_VIEW_ENCOUNTER_HISTORY = 'View Encounter History';

class NebEncounterOverlaySummary extends LitElement {
  static get properties() {
    return {
      __loading: {
        type: Boolean,
        reflect: true,
      },
      __itemHeight: Number,
      encounters: {
        type: Array,
      },
      layout: {
        type: String,
        reflect: true,
      },
      model: {
        type: Object,
      },
      opened: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__loading = true;
    this.__selectedEncounter = '';
    this.__itemHeight = ENCOUNTER_CARD_HEIGHT;

    this.encounters = [];
    this.layout = '';
    this.model = null;
    this.opened = false;

    this.onDelete = () => {};

    this.onDismiss = () => {};

    this.onEdit = () => {};

    this.onOpenVisitSummary = () => {};

    this.onPrint = () => {};

    this.onReopen = () => {};

    this.onSelectedEncounter = () => {};

    this.onSign = () => {};

    this.onViewHistory = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      delete: () => this.onDelete(this.__selectedEncounter.data.id),
      dismiss: () => this.onDismiss(),
      edit: () => this.onEdit(this.__selectedEncounter.data.id),
      encounterSelected: e => {
        if (e.event === 'select') {
          this.__selectedEncounter = e.value;
          this.__loading = true;
          this.onSelectedEncounter(e.value.data.id);
        }
      },
      openVisitSummary: () =>
        this.onOpenVisitSummary(this.__selectedEncounter.data.id),
      print: () => this.onPrint(this.__selectedEncounter.data.id),
      renderEncounterItem: item => html`
        <neb-encounter-card
          .layout="${this.layout}"
          .model="${item.data}"
          ?selected="${item === this.__selectedEncounter}"
        ></neb-encounter-card>
      `,
      reopen: () => this.onReopen(this.__selectedEncounter.data.id),
      sign: () => this.onSign(this.__selectedEncounter.data.id),
      viewHistory: () => this.onViewHistory(this.__selectedEncounter.data.id),
    };
  }

  update(changedProps) {
    if (this.model && this.model.notes && this.opened) {
      const currentEncounter = this.encounters.find(
        encounter => encounter.data.id === this.model.notes.encounter.id,
      );

      this.__selectedEncounter = currentEncounter;
    }

    if (changedProps.has('model') && this.model) {
      this.__loading = false;
    }

    super.update(changedProps);
  }

  updated() {
    this.__itemHeight = ENCOUNTER_CARD_HEIGHT_LOCATION;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          overflow: hidden;
        }

        .container-header {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: fit-content;

          padding: ${CSS_SPACING};
          border-bottom: 2px solid ${CSS_COLOR_GREY_2};
        }

        .header {
          flex: 1;
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .encounter-select {
          flex: 1;
          display: flex;
          max-width: 450px;
        }

        .container-summary {
          position: relative;
          flex: 1 0 0;
          overflow: auto;
        }

        .buttons-container {
          padding: ${CSS_SPACING} 0px 0px;
          position: relative;
        }

        .button {
          padding: 0px 4px;
        }

        .encounter-summary {
          display: flex;
          width: 100%;
          height: 100%;

          padding: ${CSS_SPACING};
          overflow-y: auto;
          overflow-x: hidden;
        }

        .container-links {
          padding-top: ${CSS_SPACING};
        }

        .link-view-encounter-history {
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          cursor: pointer;
          margin: 0px 6px;
        }

        @media (max-width: 1024px) {
          .encounter-select {
            min-width: 350px;
            padding-top: 10px;
          }
        }
      `,
    ];
  }

  __renderSignButton() {
    const disabled =
      this.model.notes && this.model.notes.encounter
        ? disableSignIfEncounterOnly(this.model.notes.encounter)
        : false;

    return html`
      <neb-button
        id="${ELEMENTS.signButton.id}"
        class="button"
        .role="${BUTTON_ROLE.CONFIRM}"
        .label="${ELEMENTS.signButton.label}"
        ?disabled="${disabled}"
        .onClick="${this.__handlers.sign}"
      ></neb-button>
    `;
  }

  __renderPrintButton() {
    return html`
      <neb-button
        id="${ELEMENTS.printButton.id}"
        class="button"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.printButton.label}"
        .onClick="${this.__handlers.print}"
      ></neb-button>
    `;
  }

  __renderEditButton() {
    return this.layout !== 'small'
      ? html`
          <neb-button
            id="${ELEMENTS.editButton.id}"
            class="button"
            .role="${BUTTON_ROLE.OUTLINE}"
            .label="${ELEMENTS.editButton.label}"
            @click="${this.__handlers.edit}"
          ></neb-button>
        `
      : '';
  }

  __renderDeleteButton() {
    return html`
      <neb-button
        id="${ELEMENTS.deleteButton.id}"
        class="button"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.deleteButton.label}"
        @click="${this.__handlers.delete}"
      ></neb-button>
    `;
  }

  __renderViewEncounterHistoryLink() {
    return this.model && this.model.history && this.model.history.length
      ? html`
          <div id="${ELEMENTS.containerLinks.id}" class="container-links">
            <a
              id="${ELEMENTS.viewEncounterHistoryLink.id}"
              class="link-view-encounter-history"
              @click="${this.__handlers.viewHistory}"
            >
              ${TEXT_LINK_VIEW_ENCOUNTER_HISTORY}
            </a>
          </div>
        `
      : '';
  }

  __renderSummaryButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonVisitSummary.id}"
        label="${ELEMENTS.buttonVisitSummary.label}"
        showUnderline
        leadingIcon=""
        .onClick="${this.__handlers.openVisitSummary}"
      ></neb-button-action>
    `;
  }

  __renderReopenButton() {
    return html`
      <neb-button
        id="${ELEMENTS.reopenButton.id}"
        class="button"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.reopenButton.label}"
        @click="${this.__handlers.reopen}"
      ></neb-button>
    `;
  }

  __renderUnsignedButtons() {
    return html`
      ${this.__renderSignButton()} ${this.__renderPrintButton()}
      ${this.__renderEditButton()} ${this.__renderDeleteButton()}
      ${this.__renderViewEncounterHistoryLink()} ${this.__renderSummaryButton()}
    `;
  }

  __renderSignedButtons() {
    return html`
      ${this.__renderPrintButton()} ${this.__renderReopenButton()}
      ${this.__renderViewEncounterHistoryLink()} ${this.__renderSummaryButton()}
    `;
  }

  __renderButtons() {
    if (this.model && this.model.notes && this.model.notes.encounter) {
      return this.model.notes.encounter.signed
        ? this.__renderSignedButtons()
        : this.__renderUnsignedButtons();
    }

    return '';
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.containerHeader.id}" class="container-header">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="Encounter Summary"
          .onCancel="${this.__handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>

        <neb-select
          id="${ELEMENTS.encounterSelect.id}"
          class="encounter-select"
          .emptyMessage="${NO_PRIOR_ENCOUNTERS_MESSAGE}"
          .items="${this.encounters}"
          .itemHeight="${this.__itemHeight}"
          .value="${this.__selectedEncounter}"
          .onChange="${this.__handlers.encounterSelected}"
          .onRenderItem="${this.__handlers.renderEncounterItem}"
        ></neb-select>

        <div class="buttons-container">${this.__renderButtons()}</div>
      </div>
    `;
  }

  __renderEncounterData() {
    const { model } = this;
    if (!model) return html``;

    const notes = model.notes ? this.model.notes : model;

    return html`
      <neb-encounter-summary
        id="encounter-summary"
        class="encounter-summary"
        .layout="${this.layout}"
        .model="${notes}"
      ></neb-encounter-summary>
    `;
  }

  __renderLoading() {
    return html`
      <neb-loading-overlay
        id="${ELEMENTS.spinner.id}"
        title="Fetching Encounter..."
        showDelay="0"
        .show="${this.__loading}"
      ></neb-loading-overlay>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderHeader()}
        <div class="container-summary">
          ${this.__renderEncounterData()} ${this.__renderLoading()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-encounter-overlay-summary',
  NebEncounterOverlaySummary,
);
