import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { TOOLTIP_TEXT } from '../../../../../neb-utils/claims';
import { parseDate } from '../../../../../neb-utils/date-util';
import { buildQualifierIdTemplate } from '../../../utils/claim-render-formatter';

export const ELEMENTS = {
  insuredPolicyCell: { id: 'insured-policy-cell' },
  insuredDateOfBirthCell: { id: 'insured-date-of-birth-cell' },
  otherClaimNumberCell: { id: 'other-claim-number-cell' },
  insurancePlanNameCell: { id: 'insurance-plan-name-cell' },
  anotherHealthBenefitPlanCell: { id: 'another-health-benefit-plan-cell' },
};

export function formatDate(date) {
  return date ? parseDate(date).format('L') : '';
}

function formatSex(sex) {
  return sex !== 'Unknown' ? sex : '';
}
class NebClaimFormCellFL11 extends LitElement {
  static get properties() {
    return {
      type: String,
      errors: Object,
      model: Object,
      renderExpandIcon: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(5, 1fr);
        }

        .cell {
          display: grid;
          width: 100%;
        }

        .two-column-cell {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding-top: 14px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.type = '';
    this.model = {
      insuredGroupIdNumber: '',
      insuredDateOfBirth: '',
      insuredSex: '',
      otherClaimQualifier: '',
      otherClaimId: '',
      insurancePlanName: '',
      paymentResponsibilityLevelCode: '',
    };

    this.errors = {
      insuredIdNumber: '',
    };

    this.renderExpandIcon = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => this.onClick(e),
    };
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.insuredPolicyCell.id}"
          class="cell"
          label="11. Insured's Policy Group or FECA Number"
          borderBottom
          .data="${this.model.insuredGroupIdNumber}"
          .tooltipText="${TOOLTIP_TEXT.FL_11}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.insuredDateOfBirthCell.id}"
          class="cell"
          label="11a. Insured's Date of Birth and Sex"
          borderBottom
          ?warn="${this.errors.insuredDateOfBirth === 'Recommended' &&
          !this.errors.insuredSex}"
          .tooltipText="${TOOLTIP_TEXT.FL_11A}"
          .problemText="${this.errors.insuredSex ||
          this.errors.insuredDateOfBirth}"
        >
          <div class="two-column-cell">
            <div>${formatDate(this.model.insuredDateOfBirth)}</div>
            <div>${formatSex(this.model.insuredSex)}</div>
          </div>
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.otherClaimNumberCell.id}"
          class="cell"
          name="otherClaimNumber"
          type="textInput"
          label="11b. Other Claim ID (Designated by NUCC)"
          borderBottom
          .data="${buildQualifierIdTemplate(
            this.model.otherClaimQualifier,
            this.model.otherClaimId,
          )}"
          .tooltipText="${TOOLTIP_TEXT.FL_11B}"
          .onClick="${this.__handlers.click}"
          .problemText="${this.errors.otherClaimId}"
          ?renderExpandIcon="${this.renderExpandIcon}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.insurancePlanNameCell.id}"
          class="cell"
          label="11c. Insurance Plan Name or Program Name"
          borderBottom
          .data="${this.model.insurancePlanName}"
          .tooltipText="${TOOLTIP_TEXT.FL_11C}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.anotherHealthBenefitPlanCell.id}"
          class="cell"
          label="11d. Is There Another Health Benefit Plan?"
          borderBottom
          .data="${this.model.otherInsurancePlanName ||
          this.model.otherInsuredGroupIdNumber ||
          this.model.otherInsuredLastName
            ? 'Yes'
            : 'No'}"
          .tooltipText="${TOOLTIP_TEXT.FL_11D}"
        >
        </neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-11', NebClaimFormCellFL11);
