const isItemUpdating = (childrenNodes, isUpdating) =>
  isUpdating ||
  (childrenNodes.some(node => node.isUpdating) &&
    childrenNodes.every(node => node.isUpdating || node.posted));

const buildUpdatingLineItems = ({
  claim: { lineItems },
  lineItemReportId,
  isClaimUpdating,
  failed,
}) =>
  lineItems.map(lineItem => {
    if (lineItem.posted) return lineItem;

    return isClaimUpdating || lineItem.lineItemReportId === lineItemReportId
      ? { ...lineItem, isUpdating: !failed }
      : lineItem;
  });

const checkClaimIsUpdating = ({
  isEraUpdating,
  lineItemReportId,
  claim,
  claimReportId,
}) =>
  isEraUpdating || (!lineItemReportId && claim.claimReportId === claimReportId);

const buildUpdatingClaims = ({
  reportData: { claims },
  claimReportId,
  lineItemReportId,
  isEraUpdating,
  failed,
}) =>
  claims.map(claim => {
    if (claim.posted) return claim;

    const isUpdating = checkClaimIsUpdating({
      isEraUpdating,
      lineItemReportId,
      claim,
      claimReportId,
    });

    if (
      !isUpdating &&
      (!lineItemReportId || claim.claimReportId !== claimReportId)
    ) {
      return claim;
    }

    const lineItems = buildUpdatingLineItems({
      claim,
      lineItemReportId,
      isClaimUpdating: isUpdating,
      failed,
    });

    return {
      ...claim,
      isUpdating: !failed && isItemUpdating(lineItems, isUpdating, failed),
      lineItems,
    };
  });

export default ({
  reportData,
  claimReportId,
  lineItemReportId,
  failed = false,
}) => {
  const isUpdating = !(claimReportId || lineItemReportId);
  const claims = buildUpdatingClaims({
    reportData,
    claimReportId,
    lineItemReportId,
    isEraUpdating: isUpdating,
    failed,
  });

  return {
    ...reportData,
    isUpdating: !failed && isItemUpdating(claims, isUpdating, failed),
    claims,
  };
};
