import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const BASE_PATH = 'referral-source-categories';

export const BASE_CATEGORIES = {
  other: { id: '71e09932-6295-46c6-80b8-3fd67a3b7823', name: 'Other' },
  patient: { id: '19f90dfe-f6ac-4779-9a69-337065f48409', name: 'Patient' },
  staff: { id: 'aec39425-7386-4af1-8da6-29dcdfbfe266', name: 'Staff' },
};

export const BASE_CATEGORY_SOURCE_TYPES = ['Other', 'Patient', 'Staff'];

export const getReferralCategories = async () => {
  const referralCategories = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: BASE_PATH,
    version: 1,
  });

  return referralCategories;
};

export const saveReferralCategory = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_PATH,
    version: 1,
    body,
    responseType: RESPONSE_TYPE.RAW,
  });

export const updateReferralCategory = ({ id, ...body }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_PATH}/${id}`,
    version: 1,
    body,
    responseType: RESPONSE_TYPE.RAW,
  });

export const getReferralCategoryById = async id => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_PATH}/${id}`,
    version: 1,
  });

  return res.data[0];
};
