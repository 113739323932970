import '../controls/neb-button-action';
import '../neb-action-bar';
import '../neb-header';
import '../neb-loading-overlay';
import '../neb-popup-header';
import '../tables/neb-table-ledger-fee-charges';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { NO_CHARGES_SELECT_ADD_CHARGES } from '../../../../../src/utils/user-message';
import { formatGuarantorName } from '../../../../neb-api-client/src/formatters/guarantor';
import { createLineItems } from '../../../../neb-api-client/src/ledger/line-items';
import { getPatientGuarantors } from '../../../../neb-api-client/src/patient-guarantor-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import {
  CODE_TYPE,
  fetchInvoiceItems,
  invoiceSectionHandlers,
  ITEM_SELF,
  LINE_ITEM_TYPE,
} from '../../../../neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import { currency, ITEM_EMPTY } from '../../../../neb-utils/selectors';
import { getValueByPath } from '../../../../neb-utils/utils';
import { getFeeCharges } from '../../utils/fee-charges';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import {
  NebLedgerInvoiceSection,
  INVOICE_TYPE,
} from '../patients/ledger/neb-ledger-invoice-section';

import NebForm from './neb-form';

const APPEND_KEYS = [
  'amount',
  'code',
  'description',
  'id',
  'requirePayment',
  'autoInvoice',
];

export const ADD_CHARGE_CONFIG = {
  tableConfig: [
    {
      key: 'code',
      label: 'Code',
      flex: css`0 0 96px`,
    },
    {
      key: 'description',
      label: 'Description',
      flex: css`1 0 0`,
    },
  ],
  mobileTableConfig: [
    {
      mobile: true,
      key: 'code',
      label: 'Code',
      flex: css`0 0 60px`,
    },
    {
      mobile: true,
      key: 'description',
      label: 'Description',
      flex: css`1 0 0`,
    },
  ],
  itemName: 'charge',
  itemPluralName: 'charges',
  title: 'Add Charge - Fee',
};

export const ELEMENTS = {
  addButton: { id: 'add-button' },
  description: { id: 'description' },
  header: { id: 'header' },
  invoice: { id: 'invoice' },
  invoiceSection: { id: 'invoice-section' },
  spinner: { id: 'spinner' },
  table: { id: 'table' },
  total: { id: 'total' },
  actionBar: { id: 'action-bar' },
};

export class NebFormFeeCharges extends NebForm {
  static get properties() {
    return {
      __total: Number,
      __menuItemsMap: Object,

      patientId: String,
      invoiceId: String,
    };
  }

  static createModel() {
    return {
      radio: INVOICE_TYPE.GENERATE,
      guarantor: ITEM_SELF,
      invoice: ITEM_EMPTY,
      items: [],
    };
  }

  initState() {
    super.initState();

    this.__total = 0;
    this.__menuItemsMap = {
      invoices: [],
      guarantors: [],
    };

    this.patientId = '';
    this.invoiceId = '';

    this.onDismiss = () => {};

    this.onDismissAll = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      ...invoiceSectionHandlers.call(this),
      dismiss: () => this.onDismiss(),
      cancel: e => {
        if (this.invoiceId) {
          this.onDismiss(true);
        } else {
          this.onDismissAll(e);
        }
      },
      addCharges: async () => {
        const stateItemsLength = this.state.items.length;
        const stateItems = [...this.state.items];
        const selectedCharges = await openOverlay(
          OVERLAY_KEYS.LEDGER_ADD_CHARGE,
          {
            config: ADD_CHARGE_CONFIG,
            items: await getFeeCharges(),
            selectedCharges: stateItems,
            isDirty: this.__dirty,
          },
        );

        selectedCharges
          .filter(
            charge => !this.state.items.find(item => item.id === charge.id),
          )
          .map(feeCharge => ({
            ...feeCharge,
            amount: feeCharge.amount || '$0.00',
          }))
          .forEach((item, index) => {
            this.formService.addItem('items');

            APPEND_KEYS.forEach(key => {
              this.formService.apply(
                `items.${index + stateItemsLength}.${key}`,
                getValueByPath(item, key.split('.')),
              );
            });
          });

        if (this.state.items.some(c => c.autoInvoice) && !this.invoiceId) {
          this.handlers.changeRadio(INVOICE_TYPE.GENERATE);
        }
      },
      removeCharge: async (name, index) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Charge',
          message: 'Are you sure you want to remove this charge?',
          confirmText: 'Yes',
          cancelText: 'No',
        });
        if (result) this.formService.removeItem(name, index);
      },
      pay: async () => {
        if (!this.state.items.length && this.__dirty) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Fee Charges',
            message:
              'You must have at least one charge to add a fee. Please add at least one charge and save again.',
          });
        }

        if (this.formService.validate()) {
          if (this.__total > 99999999) {
            return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Fee Total',
              message:
                'Fee total amount may not exceed $999,999.99 to accept payment.',
            });
          }

          if (this.__total === 0) {
            return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Fee Total',
              message:
                'Fee total amount must be above $0.00 to accept payment.',
            });
          }
          const { patientId } = this;
          const amount = this.__total;
          const res = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
            patientId,
            doNotDismissAll: this.invoiceId,
            chargeInfo: {
              lineItems: this.__formatFees(),
              amount,
              invoiceId: this.state.invoice.data.id || null,
              guarantorId: this.state.guarantor.data.id || null,
              hideVoidRefundButton: true,
            },
          });

          if (this.invoiceId) {
            this.onChangeDirty(false);
            sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);
            this.onDismiss(res.payment.lineItems.map(li => li.id));
          }
        }

        return undefined;
      },
      post: async () => {
        if (!this.state.items.length && this.__dirty) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Fee Charges',
            message:
              'You must have at least one charge to add a fee. Please add at least one charge and save again.',
          });
        }

        if (this.formService.validate()) {
          const requirePaymentCharges = this.state.items.filter(
            c => c.requirePayment,
          );

          if (requirePaymentCharges.length) {
            return openPopup(
              POPUP_RENDER_KEYS.MESSAGE,
              this.__getRequirePaymentMessage(requirePaymentCharges),
            );
          }

          try {
            this.__saving = true;

            const res = await createLineItems({
              patientId: this.patientId,
              lineItems: this.__formatFees(),
              invoiceId: this.state.invoice.data.id || null,
              guarantorId: this.state.guarantor.data.id || null,
            });

            this.onChangeDirty(false);
            store.dispatch(openSuccess('Charge(s) posted successfully'));
            sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);

            if (this.invoiceId) {
              this.onDismiss(res.lineItems.map(li => li.id));
            } else {
              this.onDismissAll();
            }
          } catch (e) {
            this.__saving = false;
            console.error(e);
            store.dispatch(
              openError('An error occurred when posting charge(s)'),
            );
          }
        }

        return undefined;
      },
    };
  }

  createSelectors() {
    return {
      children: {
        items: {
          createItem: () => ({
            id: '',
            code: '',
            description: '',
            amount: '',
            requirePayment: '',
            autoInvoice: '',
          }),
        },
        children: {
          $: {
            children: {
              amount: currency({
                validateRaw: true,
                validators: [],
              }),
            },
          },
        },
        ...NebLedgerInvoiceSection.createSelectors(this.__menuItemsMap),
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-total {
          display: flex;
          flex-shrink: 0;
        }

        .content-total {
          display: grid;
          grid-template-columns: auto auto;
          text-align: right;
          column-gap: ${CSS_SPACING};
          width: fit-content;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 0;
        }

        .text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .spacer {
          display: flex;
          flex: 1 0 0;
        }
      `,
    ];
  }

  __formatFees() {
    return this.state.items.map(f => ({
      codeId: f.id,
      unitCharge: currencyToCents(f.amount),
      units: 1,
      type: LINE_ITEM_TYPE.FEE,
      codeType: CODE_TYPE.CODE_CHARGE,
    }));
  }

  async load() {
    const [invoices, guarantors] = await Promise.all([
      fetchInvoiceItems(this.patientId, this.invoiceId, true),
      getPatientGuarantors(this.patientId, undefined, true),
    ]);

    const formattedGuarantors = guarantors
      .map(data => ({
        label: formatGuarantorName(data),
        data,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.__menuItemsMap = {
      invoices,
      guarantors: [ITEM_SELF, ...formattedGuarantors],
    };
  }

  update(changedProps) {
    if (changedProps.has('__state')) {
      this.__total = this.state.items.reduce(
        (acc, curr) => acc + (currencyToCents(curr.amount) || 0),
        0,
      );
    }

    super.update(changedProps);
  }

  __getRequirePaymentCharges() {
    return this.state.items.filter(c => c.requirePayment);
  }

  __getRequirePaymentMessage(charges) {
    const rows = charges.map(
      c => html`
        <p style="font-weight: 700; margin: 10px;">
          ${c.code} - ${c.description}
        </p>
      `,
    );

    return {
      title: 'Payment Required',
      message: html`
        <div style="padding-bottom: 20px;">
          The following charges require payment at time of charge:
        </div>
        ${rows}
        <div style="padding-top: 20px;">
          Please remove charges or elect to receive Payment now and click “Pay”.
        </div>
      `,
    };
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Charge - Fee"
        showBackButton
        showCancelButton
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  __renderTotal() {
    return html`
      <div class="container-total">
        <div class="spacer"></div>
        <div class="content-total">
          <div class="text">Total</div>
          <div id="${ELEMENTS.total.id}" class="text">
            ${centsToCurrency(this.__total)}
          </div>
        </div>
      </div>
    `;
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            removeLabel="CANCEL"
            cancelLabel="POST"
            confirmLabel="PAY"
            .onConfirm="${this.handlers.pay}"
            .onCancel="${this.handlers.post}"
            .onRemove="${this.handlers.dismiss}"
            unelevated-cancel
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="pad">
        Add charges and enter charge details to post against the patient’s
        ledger.
      </div>

      <div class="pad">
        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          label="Add Charge"
          .onClick="${this.handlers.addCharges}"
        ></neb-button-action>
      </div>

      <neb-header label="Charge Details"></neb-header>

      <neb-table-ledger-fee-charges
        id="${ELEMENTS.table.id}"
        name="items"
        .layout="${this.layout}"
        .model="${this.state.items}"
        .errors="${this.errors.items}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeCharge}"
      >
        ${NO_CHARGES_SELECT_ADD_CHARGES}
      </neb-table-ledger-fee-charges>

      ${this.__renderTotal()}

      <neb-ledger-invoice-section
        id="${ELEMENTS.invoiceSection.id}"
        .model="${this.state}"
        .layout="${this.layout}"
        .isAutoGenerateInvoice="${this.state.items.some(i => i.autoInvoice)}"
        .invoiceId="${this.invoiceId}"
        .itemsMap="${this.__menuItemsMap}"
        .onChangeRadio="${this.handlers.changeRadio}"
        .onChangeGuarantor="${this.handlers.changeGuarantor}"
        .onChangeInvoice="${this.handlers.changeInvoice}"
      ></neb-ledger-invoice-section>
    `;
  }
}

customElements.define('neb-form-fee-charges', NebFormFeeCharges);
