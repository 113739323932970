import './neb-calendar-view';
import './neb-moment-calendar-view';

import { LitElement, html, css } from 'lit';

import { CSS_SPACING } from '../../../neb-styles/neb-variables';

import { POPOVER_POSITION } from './neb-popover';

export const ELEMENTS = {
  calendar: {
    id: 'calendar',
  },
  popover: {
    id: 'popover',
  },
};

class NebCalendarPopover extends LitElement {
  static get properties() {
    return {
      momentFlag: {
        type: Boolean,
        reflect: true,
      },
      incrementDisplayMonth: {
        type: Boolean,
      },
      selectedDate: Object,
      isDateSelectable: Function,
      popoverPosition: String,
      isPickerable: Boolean,
      isToolbarVisible: Boolean,
      updateDependency: String,
      clearSelectedDate: {
        type: Boolean,
        reflect: true,
      },
      hideArrow: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.momentFlag = false;
    this.incrementDisplayMonth = false;
    this.popoverPosition = POPOVER_POSITION.BOTTOM;
    this.selectedDate = null;
    this.isPickerable = false;
    this.isToolbarVisible = true;
    this.updateDependency = null;
    this.clearSelectedDate = false;
    this.hideArrow = false;

    this.isDateSelectable = () => true;

    this.onDateSelected = () => {};

    this.onTodaySelected = () => {};

    this.onMonthChanged = () => {};

    this.onClosePopover = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      dateSelected: date => this.onDateSelected(date),
      todaySelected: date => this.onTodaySelected(date),
      monthChanged: () => this.onMonthChanged(),
      closePopover: () => this.onClosePopover(),
    };
  }

  reset() {
    this.__elements.calendar.reset();
  }

  firstUpdated() {
    this.__elements = {
      calendar: this.shadowRoot.getElementById(ELEMENTS.calendar.id),
    };
  }

  static get styles() {
    return css`
      .calendar {
        margin: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        display: flex;
        justify-content: flex-start;
      }
    `;
  }

  __renderCalendar() {
    return this.momentFlag
      ? html`
          <neb-moment-calendar-view
            id="${ELEMENTS.calendar.id}"
            class="calendar"
            .isDateSelectable="${this.isDateSelectable}"
            .isPickerable="${this.isPickerable}"
            .isToolbarVisible="${this.isToolbarVisible}"
            .selectedDate="${this.selectedDate}"
            .updateDependency="${this.updateDependency}"
            .onChange="${this.onDateSelected}"
            .onMonthChanged="${this.onMonthChanged}"
            .onTodaySelected="${this.onTodaySelected}"
            .clearSelectedDate="${this.clearSelectedDate}"
            incrementDisplayMonth
          ></neb-moment-calendar-view>
        `
      : html`
          <neb-calendar-view
            id="${ELEMENTS.calendar.id}"
            class="calendar"
            .isDateSelectable="${this.isDateSelectable}"
            .isPickerable="${this.isPickerable}"
            .isToolbarVisible="${this.isToolbarVisible}"
            .selectedDate="${this.selectedDate}"
            .updateDependency="${this.updateDependency}"
            .onChange="${this.onDateSelected}"
            .onMonthChanged="${this.onMonthChanged}"
            .onTodaySelected="${this.onTodaySelected}"
            .clearSelectedDate="${this.clearSelectedDate}"
          ></neb-calendar-view>
        `;
  }

  render() {
    return html`
      <neb-popover
        id="${ELEMENTS.popover.id}"
        .popoverPosition="${this.popoverPosition}"
        .hideArrow="${this.hideArrow}"
        .onBlockerClicked="${this.__handlers.closePopover}"
      >
        ${this.__renderCalendar()}
      </neb-popover>
    `;
  }
}

customElements.define('neb-calendar-popover', NebCalendarPopover);
