import '../../../../../../src/components/misc/neb-icon';
import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_CAPTION,
  CSS_BORDER_GREY_1,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_3,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
} from '../../../../../neb-styles/neb-variables';
import { buildQualifierIdTemplate } from '../../../utils/claim-render-formatter';

export const ELEMENTS = {
  referringProviderOrOtherSourceCell: { id: 'referring-provider-name-cell' },
  providerDetails: { id: 'provider-details' },
  expandIcons: { selector: 'neb-icon' },
  aCell: { id: 'a-cell' },
  bCell: { id: 'b-cell' },
};

const POPUP_CONFIG = {
  [ELEMENTS.aCell.id]: {
    name: 'referringProviderOrOtherSource',
    type: 'radioNameFields',
    title: '17. Referring Provider or Other Source',
  },

  [ELEMENTS.bCell.id]: {
    name: 'referringProviderOrOtherSource',
    type: 'radioNameFields',
    title: '17. Referring Provider or Other Source',
  },

  [ELEMENTS.referringProviderOrOtherSourceCell.id]: {
    name: 'referringProviderOrOtherSource',
    type: 'radioNameFields',
    title: '17. Referring Provider or Other Source',
  },
};

class NebClaimFormCellFL17 extends LitElement {
  static get properties() {
    return {
      model: Object,
      errors: Object,
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.errors = {
      referringProviderFirstName: '',
      referringProviderMiddleName: '',
      referringProviderLastName: '',
      referringProviderCredentials: '',
      referringProviderQualifier: '',
      referringProviderOtherIdQualifier: '',
      referringProviderOtherId: '',
      referringProviderNPI: '',
    };

    this.model = {
      referringProviderFirstName: '',
      referringProviderMiddleName: '',
      referringProviderLastName: '',
      referringProviderCredentials: '',
      referringProviderQualifier: '',
      referringProviderOtherIdQualifier: '',
      referringProviderOtherId: '',
      referringProviderNPI: '',
    };

    this.renderExpandIcon = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: ({ currentTarget: { id } }) => this.onClick(POPUP_CONFIG[id]),
      clickCell: claimCellData => this.onClick(claimCellData),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
        }

        .provider-details {
          display: grid;
          grid-column: span 2;
          grid-template-columns: 85px 3fr;
          grid-template-rows: repeat(2, 1fr);
          border: ${CSS_BORDER_GREY_1};
          border-top: 0;
        }

        .span-column-3 {
          grid-column: span 3;
        }

        .cell {
          display: grid;
          width: 100%;
        }

        .label {
          font-size: ${CSS_FONT_SIZE_CAPTION};
          border-right: ${CSS_BORDER_GREY_1};
          display: flex;
          align-items: center;
          padding-left: ${CSS_SPACING};
        }

        :host([renderExpandIcon]) .row:hover {
          background-color: ${CSS_COLOR_GREY_4};
          cursor: pointer;
        }

        .row:hover .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .data-container {
          padding-left: ${CSS_SPACING};
          display: flex;
          align-items: center;
          position: relative;
        }

        .row {
          display: grid;
          grid-column: span 4;
          grid-template-columns: 85px 3fr;
        }

        .row:first-child {
          border-bottom: 1px dashed ${CSS_COLOR_GREY_1};
        }

        .icon {
          margin: 6px 6px auto auto;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
          position: absolute;
          top: 0;
          right: 0;
        }

        .shaded {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .error {
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          border-right: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          border-left: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          border-top: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          border-bottom: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }
      `,
    ];
  }

  __renderExpandButton() {
    return this.renderExpandIcon
      ? html` <neb-icon class="icon" icon="neb:expand"></neb-icon> `
      : '';
  }

  __renderRow({ id, label, data, shaded, errors }) {
    return html`
      <div
        id="${id}"
        class="row ${shaded ? 'shaded' : ''} ${errors ? 'error' : ''}"
        @click="${this.__handlers.click}"
      >
        <div class="label">${label}</div>
        <div class="data-container">${data} ${this.__renderExpandButton()}</div>
      </div>
    `;
  }

  __renderProviderDetails() {
    const {
      referringProviderNPI,
      referringProviderOtherId,
      referringProviderOtherIdQualifier,
    } = this.model;

    return html`
      <div id="${ELEMENTS.providerDetails.id}" class="provider-details">
        ${this.__renderRow({
          id: ELEMENTS.aCell.id,
          label: '17a.',
          data: buildQualifierIdTemplate(
            referringProviderOtherIdQualifier,
            referringProviderOtherId,
          ),
          shaded: true,
          errors:
            this.errors.referringProviderOtherId ||
            this.errors.referringProviderOtherIdQualifier,
        })}
        ${this.__renderRow({
          id: ELEMENTS.bCell.id,
          label: '17b.',
          data: referringProviderNPI,
          errors: this.errors.referringProviderNPI,
        })}
      </div>
    `;
  }

  __formatReferringProviderName() {
    const name = [
      this.model.referringProviderFirstName,
      this.model.referringProviderMiddleName &&
        this.model.referringProviderMiddleName[0],
      this.model.referringProviderLastName,
      this.model.referringProviderCredentials,
    ]
      .filter(Boolean)
      .join(' ');

    if (!name) return '';

    return [this.model.referringProviderQualifier, name]
      .filter(Boolean)
      .join(' - ');
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.referringProviderOrOtherSourceCell.id}"
          class="cell span-column-3"
          label="${POPUP_CONFIG[ELEMENTS.referringProviderOrOtherSourceCell.id]
            .title}"
          borderBottom
          type="radioNameFields"
          name="referringProviderName"
          ?renderExpandIcon="${this.renderExpandIcon}"
          .data="${this.__formatReferringProviderName()}"
          .onClick="${this.__handlers.clickCell}"
          .problemText="${this.errors.referringProviderQualifier ||
          this.errors.referringProviderFirstName ||
          this.errors.referringProviderLastName}"
        >
        </neb-claim-form-cell>

        ${this.__renderProviderDetails()}
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-17', NebClaimFormCellFL17);
