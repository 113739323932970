import { css } from 'lit';

import NebPopupConfirm, {
  ELEMENTS as BASE_ELEMENTS,
} from './neb-popup-confirm';

// Need to export ELEMENTS here to enable test:gen to pick these up
// eslint-disable-next-line import/no-unused-modules
export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

class NebPopupLedgerInsuranceOutOfCoverage extends NebPopupConfirm {
  static get styles() {
    return [
      super.styles,
      css`
        .content-container {
          overflow-wrap: anywhere;
        }
      `,
    ];
  }
}

customElements.define(
  'ledger-insurance-out-of-coverage',
  NebPopupLedgerInsuranceOutOfCoverage,
);
