import '../neb-loading-overlay';

import { LitElement, html, css } from 'lit';

import { connect, store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = { loadingIndicator: { id: 'loading-indicator' } };

class GlobalLoadingIndicator extends connect(store)(LitElement) {
  static get properties() {
    return {
      __show: {
        type: Boolean,
        reflect: true,
        attribute: 'loading',
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([loading]) {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        neb-loading-overlay {
          pointer-events: auto;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__show = false;
  }

  _stateChanged({ globalLoading: { count } }) {
    this.__show = count !== 0;
  }

  render() {
    return html`
      <neb-loading-overlay
        id="${ELEMENTS.loadingIndicator.id}"
        ?show="${this.__show}"
        ?global="${true}"
      ></neb-loading-overlay>
    `;
  }
}

window.customElements.define(
  'neb-global-loading-indicator',
  GlobalLoadingIndicator,
);
