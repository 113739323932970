import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { parseDate } from '../../../../../neb-utils/date-util';

const NAME_TO_KEY = Object.freeze({
  disabilityFromDate: 'date1',
  disabilityToDate: 'date2',
});

function setEditedField({ result, state, formService }) {
  Object.entries(NAME_TO_KEY).forEach(([name, key]) => {
    if (state[name] !== result[key]) {
      formService.apply(`claimEditedField.${name}`, true);
    }
  });
}

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_DATE_RANGE, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: [
      {
        name: 'date1',
        value: state.disabilityFromDate
          ? parseDate(state.disabilityFromDate)
          : null,
      },
      {
        name: 'date2',
        value: state.disabilityToDate
          ? parseDate(state.disabilityToDate)
          : null,
      },
    ],
  });

  if (result) {
    setEditedField({ result, state, formService });

    handlers.change({
      name: 'disabilityFromDate',
      value: result.date1 ? parseDate(result.date1).format() : '',
    });

    handlers.change({
      name: 'disabilityToDate',
      value: result.date2 ? parseDate(result.date2).format() : '',
    });
  }
};
