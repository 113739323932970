import { warn } from './neb-route-state';
import { createRouteParser } from './neb-route-util';

const LEAVING = true;
const STAYING = false;
const NOT_ACTIVE = false;

const __stringPatternStrategy = routeParser => (hash, nextHash) => {
  const currentRoute = routeParser.parse(hash);

  if (!currentRoute.active) {
    return NOT_ACTIVE;
  }

  const nextRoute = routeParser.parse(nextHash);

  if (!nextRoute.active) {
    return LEAVING;
  }

  if (JSON.stringify(currentRoute.data) !== JSON.stringify(nextRoute.data)) {
    return LEAVING;
  }

  return STAYING;
};

const __compareRegexpCapturedValues = (match1, match2) => {
  if (match1.length !== match2.length) {
    return false;
  }

  for (let i = 1; i < match1.length; i++) {
    if (match1[i] !== match2[i]) {
      return false;
    }
  }

  return true;
};

const __regexPatternStrategy = regexp => (hash, nextHash) => {
  const currentMatch = regexp.exec(hash);

  if (!currentMatch) {
    return NOT_ACTIVE;
  }

  const nextMatch = regexp.exec(nextHash);

  if (!nextMatch) {
    return LEAVING;
  }

  if (!__compareRegexpCapturedValues(currentMatch, nextMatch)) {
    return LEAVING;
  }

  return STAYING;
};

export const connectNavigation = (store, routePattern) => baseElement =>
  class extends baseElement {
    constructor() {
      super();

      if (typeof routePattern === 'string') {
        this.__connectNav_strategy = __stringPatternStrategy(
          createRouteParser(routePattern),
        );
      } else if (routePattern instanceof RegExp) {
        this.__connectNav_strategy = __regexPatternStrategy(routePattern);
      } else {
        throw new Error('routePattern must be either a `string` or a `RegExp`');
      }
    }

    connectedCallback() {
      super.connectedCallback();

      this.__connectNav_unsubscribe = store.subscribe(() =>
        this.__connectNavStateChanged(store.getState().route),
      );

      this.__connectNavStateChanged(store.getState().route);

      window.addEventListener(
        'beforeunload',
        (this.__connectNav_onBeforeUnloadCallback = e =>
          this.__onBeforeUnload(e)),
      );
    }

    disconnectedCallback() {
      if (this.__connectNav_unsubscribe) {
        this.__connectNav_unsubscribe();

        this.__connectNav_unsubscribe = null;
      }

      if (this.__connectNav_onBeforeUnloadCallback) {
        window.removeEventListener(
          'beforeunload',
          this.__connectNav_onBeforeUnloadCallback,
        );

        this.__connectNav_onBeforeUnloadCallback = null;
      }

      super.disconnectedCallback();
    }

    __connectNavStateChanged(routeState) {
      if (!routeState || !routeState.navigating || routeState.warn) {
        return;
      }

      if (
        this.__connectNav_strategy(routeState.hash, routeState.nextHash) !==
        LEAVING
      ) {
        return;
      }

      if (this._shouldNavigate()) {
        return;
      }

      store.dispatch(warn());
    }

    __onBeforeUnload(e) {
      const {
        route: { hash },
      } = store.getState();

      if (
        this.__connectNav_strategy(hash, '') === NOT_ACTIVE ||
        this._shouldNavigate() ||
        window.nebDisableDirty
      ) {
        return '';
      }
      const msg = 'You have unsaved changes, are you sure you want to leave?';
      e.returnValue = msg;
      return msg;
    }

    _shouldNavigate() {
      throw new Error('_shouldNavigate() not implemented', this);
    }
  };
