import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/neb-button';

import { html, css } from 'lit';

import {
  fetchAllQuestionnairesByProvider,
  saveQuestionnaire,
} from '../../../src/api-clients/questionnaires';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const COPY_QUESTIONNAIRE_BANNER = {
  success: 'Questionnaire copied successfully',
  error: 'Unable to copy questionnaire',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  closeButton: {
    id: 'close-button',
  },

  copyQuestionnaireButton: {
    id: 'copy-questionnaire-button',
  },

  selectedProvider: {
    id: 'selected-provider',
  },

  selectedAppointmentType: {
    id: 'selected-appointment-type',
  },
};

class NebPopupSubjectiveQuestionnaireCopy extends NebPopup {
  static get properties() {
    return {
      selectedProvider: Object,
      selectedAppointmentType: Object,
      appointmentTypeItems: Array,
    };
  }

  initState() {
    super.initState();

    this.title = 'Copy Existing Questionnaire';
    this.selectedProvider = null;
    this.selectedAppointmentType = null;
    this.appointmentTypeItems = [];
    this.providerQuestionnaires = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeProvider: ({ value }) => {
        this.selectedProvider = value;
        this.__getAllAppointmentTypes();
      },
      changeAppointmentType: ({ value }) => {
        this.selectedAppointmentType = value;
      },
      save: () => {
        this.__saveQuestionnaireCopy();
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 480px;
        }

        .header {
          margin-bottom: 20px;
        }

        .content {
          margin-top: 0px;
        }

        .drop-downs {
          display: grid;
          gap: 20px;
        }

        .button-close {
          margin-top: ${CSS_SPACING};
        }

        .button-copy {
          margin-top: ${CSS_SPACING};
          margin-right: 10px;
        }
      `,
    ];
  }

  async __getAllAppointmentTypes() {
    this.providerQuestionnaires = await fetchAllQuestionnairesByProvider(
      this.selectedProvider.id,
    );

    const sharedProviderAppointmentTypes =
      this.model.currentAppointmentTypeItems.filter(({ id }) =>
        this.providerQuestionnaires.find(
          questionnaire =>
            questionnaire.appointmentTypeId === id &&
            questionnaire.questions.length,
        ),
      );

    this.appointmentTypeItems = sharedProviderAppointmentTypes.filter(
      ({ id }) =>
        !(
          this.selectedProvider === this.model.currentProvider &&
          id === this.model.currentAppointmentType.id
        ),
    );
  }

  async __saveQuestionnaireCopy() {
    const questionnaireToCopy = this.providerQuestionnaires.find(
      ({ appointmentTypeId }) =>
        appointmentTypeId === this.selectedAppointmentType.id,
    );

    const questionnaire = {
      ...questionnaireToCopy,
      questionIds: questionnaireToCopy.questions.map(question => question.id),
    };

    try {
      await saveQuestionnaire(
        this.model.currentProvider.id,
        this.model.currentAppointmentType.id,
        questionnaire,
      );

      store.dispatch(openSuccess(COPY_QUESTIONNAIRE_BANNER.success));
      this.onClose(true);
    } catch (error) {
      store.dispatch(openError(COPY_QUESTIONNAIRE_BANNER.error));
    }
  }

  modelChanged() {
    this.selectedProvider = this.model.currentProvider;
    this.__getAllAppointmentTypes();
  }

  renderContent() {
    return html`
      <div class="drop-downs">
        <neb-select
          id="${ELEMENTS.selectedProvider.id}"
          label="Provider"
          .value="${this.selectedProvider}"
          .items="${this.model.providerItems}"
          .onChange="${this.handlers.changeProvider}"
        ></neb-select>
        <neb-select
          id="${ELEMENTS.selectedAppointmentType.id}"
          label="Appointment Type"
          .value="${this.selectedAppointmentType}"
          .items="${this.appointmentTypeItems}"
          .onChange="${this.handlers.changeAppointmentType}"
        ></neb-select>
      </div>

      <neb-button
        id="${ELEMENTS.copyQuestionnaireButton.id}"
        label="Copy"
        class="button-copy"
        .onClick="${this.handlers.save}"
        ?disabled="${!this.selectedProvider || !this.selectedAppointmentType}"
      ></neb-button>

      <neb-button
        id="${ELEMENTS.closeButton.id}"
        label="Cancel"
        class="button-close"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }
}

customElements.define(
  'neb-popup-subjective-questionnaire-copy',
  NebPopupSubjectiveQuestionnaireCopy,
);
