import { v4 as uuid } from 'uuid';

import DebounceService from '../debouncer';

export const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

const DEFAULT_SORT_PARAMS = {
  key: '',
  dir: SORT_DIR.DESC,
};

export const EMPTY_RESPONSE = {
  data: [],
  count: 0,
};

export class FetchService {
  static createModel() {
    return {
      searchText: '',
      hideInactive: true,
      filteredCount: 0,
      pageSize: 20,
      pageCount: 1,
      pageIndex: 0,
      pageItems: [],
      sortParams: {
        key: '',
        dir: SORT_DIR.DESC,
      },
    };
  }

  constructor(callbacks, client, initialConfig = {}) {
    const {
      pageSize,
      hideInactive,
      sortParams,
      debounceDuration,
      initialQuery,
    } = initialConfig;

    const { onChange, onMapItem, onError } = callbacks;

    this.__latestTaskId = '';
    this.__hideInactive = hideInactive === undefined ? true : hideInactive;
    this.__sortParams = sortParams || DEFAULT_SORT_PARAMS;
    this.__pageSize = pageSize || 10;
    this.__client = client;
    this.__filterCount = 0;
    this.__body = {};
    this.__pageIndex = 0;
    this.__customQuery = initialQuery || {};
    this.__searchText = '';
    this.__items = [];
    this.__task = null;

    this.__onChange = onChange;
    this.__onMapItem = onMapItem || (item => item);
    this.__onError = onError || (() => {});

    this.__debounceService = new DebounceService(
      () => this.__enqueueTask(),
      debounceDuration,
    );
  }

  hideInactive(allow) {
    this.__hideInactive = allow;

    this.__enqueueTask();
  }

  search(str, onlyIfChanged = false) {
    if (!onlyIfChanged || this.__searchText !== str) {
      this.__searchText = str;
      this.__debounceService.debounce();
      this.__pageIndex = 0;
      this.__change();
    }
  }

  setSortParams(params) {
    this.__sortParams = params;

    this.__enqueueTask();
  }

  fetch() {
    return this.__enqueueTask();
  }

  fetchMore() {
    this.__enqueueTask(true);
  }

  cancel() {
    this.__latestTaskId = '';
  }

  setQuery(key, value) {
    this.__customQuery[key] = value;

    this.__enqueueTask();

    return this;
  }

  unsetQuery(key) {
    delete this.__customQuery[key];

    this.__enqueueTask();

    return this;
  }

  setPageIndex(index) {
    this.__pageIndex = index;

    return this.__enqueueTask();
  }

  setPageSize(size) {
    this.__pageSize = size;

    this.__enqueueTask();
  }

  getFilterCount() {
    return this.__filterCount;
  }

  getBody() {
    return this.__body;
  }

  getPageCount(useMaster = false) {
    const totalCount = useMaster ? this.__masterCount : this.__filterCount;
    return Math.ceil(totalCount / this.__pageSize);
  }

  getState() {
    return {
      searchText: this.__searchText,
      hideInactive: this.__hideInactive,
      filteredCount: this.__filterCount,
      body: this.__body,
      pageSize: this.__pageSize,
      pageCount: this.getPageCount(),
      pageIndex: this.__pageIndex,
      pageItems: this.__items,
      sortParams: this.__sortParams,
    };
  }

  updateItem(item) {
    const index = this.__items.findIndex(target => target.id === item.id);

    if (index > -1) {
      const items = this.__items;
      items[index] = item;
      this.__items = [...items];
    }
  }

  __enqueueTask(append = false) {
    if (!this.__task) {
      this.__task = Promise.resolve(append).then(async append => {
        await this.__fetch(append);

        this.__task = null;
      });
    }
    return this.__task;
  }

  __change() {
    this.__onChange(this.getState());
  }

  async __fetch(append) {
    let res = null;
    let err = null;

    const taskId = uuid();
    const offset = append
      ? this.__items.length
      : this.__pageIndex * this.__pageSize;

    this.__latestTaskId = taskId;

    try {
      res = await this.__client({
        ...this.__customQuery,
        hideInactive: this.__hideInactive,
        limit: this.__pageSize,
        search: this.__searchText,
        sortParams: this.__sortParams,
        offset,
      });
    } catch (e) {
      err = e;
    }

    if (taskId === this.__latestTaskId) {
      if (!err) {
        const { data, count, ...body } = res;
        const items = data.map(item => this.__onMapItem(item));

        this.__body = body;
        this.__filterCount = res.count;
        this.__items = append ? [...this.__items, ...items] : items;
        this.__change();
      } else {
        this.__onError(err);
      }
    }
  }
}
