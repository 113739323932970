import { CLAIM_STATUS } from '../../../../../packages/neb-utils/claims';
import { REVERSAL_OF_PREVIOUS_PAYMENT } from '../../../../utils/era';
import {
  CHARGE_WARNINGS,
  CLAIM_WARNINGS,
  ERA_DETAILS_TYPES,
  ERA_STATUSES,
  getLineItemStatus,
} from '../utils';

export const itemHasSpecificWarning = (errorMessage = [], warning) => {
  if (Array.isArray(errorMessage)) {
    return errorMessage.some(message => message.includes(warning));
  }

  return errorMessage.includes(warning);
};

const checkReversalZeroPayment = claim => parseFloat(claim?.issued || 0) === 0;

const checkReversalClaimStatus = claim =>
  claim?.claimStatus === REVERSAL_OF_PREVIOUS_PAYMENT &&
  itemHasSpecificWarning(
    claim.statusMessage,
    CLAIM_WARNINGS.CLAIM_IS_A_REVERSAL,
  );

export const checkMatchButtonEnabled = (
  { patientMedicalRecordNumber, id, isUpdating },
  nodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL,
) => {
  if (isUpdating) return false;

  return nodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
    ? patientMedicalRecordNumber && !id
    : !patientMedicalRecordNumber;
};

export const checkFilterByMatchStatus = (patientMedicalRecordNumber, id) =>
  !checkMatchButtonEnabled({ patientMedicalRecordNumber, id });

const checkManualPostButtonZeroPayment = claim => {
  const isReversal = checkReversalClaimStatus(claim);
  if (!isReversal) return false;

  return isReversal && checkReversalZeroPayment(claim);
};

export const checkManualPostButtonEnabled = (
  claim,
  nodeType,
  hasRcmEraReversalsFF,
) => {
  if (claim.isUpdating) return false;

  if (nodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL) {
    return (
      itemHasSpecificWarning(claim.statusMessage, 'fully written off') ||
      (hasRcmEraReversalsFF && checkManualPostButtonZeroPayment(claim))
    );
  }

  return (
    claim.statusMessage &&
    Array.isArray(claim.statusMessage) &&
    claim.statusMessage.some(message => message.includes('out of balance'))
  );
};

export const checkMarkAsDeniedButtonEnabled = (
  { statusMessage = [], isUpdating },
  nodeType,
  claimStatus,
) => {
  if (isUpdating) return false;

  if (nodeType !== ERA_DETAILS_TYPES.CLAIM_LEVEL) {
    return false;
  }

  return (
    statusMessage.includes('fully written off') &&
    claimStatus !== CLAIM_STATUS.DENIED &&
    claimStatus !== CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED
  );
};

export const checkReversalButtonEnabled = (
  claim,
  nodeType,
  hasRcmEraReversalsFF,
) =>
  !claim.isUpdating &&
  hasRcmEraReversalsFF &&
  nodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL &&
  checkReversalClaimStatus(claim) &&
  !checkReversalZeroPayment(claim);

export const checkClaimFiltered = ({
  filteredStatuses,
  eraFailed,
  claim: {
    claimErrorMessage: claimFailed,
    patientMedicalRecordNumber,
    lineItems,
  },
}) => {
  if (!filteredStatuses.length) return false;

  if (filteredStatuses.includes(ERA_STATUSES.MATCH)) {
    return lineItems.every(({ id }) =>
      checkFilterByMatchStatus(patientMedicalRecordNumber, id),
    );
  }

  return lineItems.every(({ errorMessage: lineItemFailed, posted }) =>
    filteredStatuses.includes(
      getLineItemStatus({
        eraFailed,
        claimFailed,
        lineItemFailed,
        posted,
      }),
    ),
  );
};

export const ERA_REPORT_ACTIONS = {
  POST: 'post',
  MERGE: 'merge',
  MATCH: 'match',
  MANUAL_POST: 'manual-post',
  MARK_AS_DENIED: 'mark-as-denied',
  REVERSE: 'reverse',
};

export const getLevelActions = ({ level, hasRcmEraReversalsFF }) => {
  const availableActions = {
    [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: [
      ERA_REPORT_ACTIONS.POST,
      ERA_REPORT_ACTIONS.MERGE,
      ERA_REPORT_ACTIONS.MATCH,
      ERA_REPORT_ACTIONS.MANUAL_POST,
    ],
    [ERA_DETAILS_TYPES.CLAIM_LEVEL]: [
      ERA_REPORT_ACTIONS.POST,
      ERA_REPORT_ACTIONS.MATCH,
      ERA_REPORT_ACTIONS.MANUAL_POST,
      ERA_REPORT_ACTIONS.MARK_AS_DENIED,
      ...(hasRcmEraReversalsFF ? [ERA_REPORT_ACTIONS.REVERSE] : []),
    ],
    [ERA_DETAILS_TYPES.ERA_LEVEL]: [ERA_REPORT_ACTIONS.POST],
  };

  return availableActions[level] || [];
};

export const getNextMatchLineItemReportId = (
  reportLineItems,
  currentLineItemReportId,
) => {
  const reportLineItemKeys = Object.keys(reportLineItems);
  if (reportLineItemKeys.length === 1) return {};

  const currPosition = reportLineItemKeys.findIndex(
    key => key === currentLineItemReportId,
  );

  const nextPosition =
    currPosition < reportLineItemKeys.length - 1 ? currPosition + 1 : 0;

  return Object.values(reportLineItems)[nextPosition];
};

export const checkDisablePostButtonWhenInsufficientPaymentBalance = ({
  availableToAllocate,
  hasOwlRemitOffsetEnhancementsFF,
  selectedNodeType,
  status,
  statusMessage,
}) => {
  if (status === ERA_STATUSES.READY_TO_POST && availableToAllocate) {
    return false;
  }

  if (selectedNodeType !== ERA_DETAILS_TYPES.LINE_ITEM_LEVEL) {
    return true;
  }

  return !(
    hasOwlRemitOffsetEnhancementsFF &&
    itemHasSpecificWarning(
      statusMessage,
      CHARGE_WARNINGS.INSUFFICIENT_REMAINING_PAYMENT,
    )
  );
};
