import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html } from 'lit';

import * as apiPatientCases from '../../../../../neb-api-client/src/patient-cases';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { OTHER_DATE_QUALIFIER } from '../../../../../neb-utils/claims';
import { parseDate } from '../../../../../neb-utils/date-util';

const OTHER_DATE_DATA = [
  { key: 'initialTxDate', qualifier: '454' },
  { key: 'lastSeenDate', qualifier: '304' },
  { key: 'acuteManifestationDate', qualifier: '453' },
  { key: 'accidentDate', qualifier: '439' },
  { key: 'lastXRayDate', qualifier: '455' },
  { key: 'prescriptionDate', qualifier: '471' },
  { key: 'reportStartDate', qualifier: '090' },
  { key: 'reportEndDate', qualifier: '091' },
  { key: 'firstVisitDate', qualifier: '444' },
];

const formatOtherDateLabel = ({ qualifier, date }) =>
  qualifier
    ? `${qualifier} (${OTHER_DATE_QUALIFIER[qualifier]}) - ${date}`
    : '';

const openOtherDateWarning = title =>
  openPopup(POPUP_RENDER_KEYS.MESSAGE, {
    title,
    message: html`
      <div>There are no additional dates associated to this claim.</div>

      <br />

      <div>
        Please ensure that there is a case associated with the invoice for this
        claim, and that the case has additional dates configured.
      </div>
    `,
  });

const formatOtherDateOption = ({
  otherDates,
  name,
  otherDateKey,
  qualifier,
}) => ({
  label: formatOtherDateLabel({
    qualifier,
    date: parseDate(otherDates.get(otherDateKey)).format('MM/DD/YYYY'),
  }),
  name,
  value: {
    qualifier,
    date: otherDates.get(otherDateKey),
  },
});

const getOtherDateOptions = ({ previousOption, name, otherDates }) => {
  let listContainsPreviousOption = false;

  const formattedOtherDates = OTHER_DATE_DATA.reduce((acc, item) => {
    if (otherDates.has(item.key)) {
      const otherDateOption = formatOtherDateOption({
        otherDates,
        name,
        otherDateKey: item.key,
        qualifier: item.qualifier,
      });

      if (equal(previousOption, otherDateOption)) {
        listContainsPreviousOption = true;
      }

      return [...acc, otherDateOption];
    }
    return acc;
  }, []);

  return [
    ...(!listContainsPreviousOption && Object.values(previousOption).length
      ? [previousOption]
      : []),
    ...formattedOtherDates,
    {
      label: 'None',
      name,
      value: { qualifier: '', date: '' },
    },
  ];
};

function setEditedField({ result, state, formService }) {
  if (state.otherDate !== result.otherDate.date) {
    formService.apply('claimEditedField.otherDate', true);
  }

  if (state.otherDateQualifier !== result.otherDate.qualifier) {
    formService.apply('claimEditedField.otherDateQualifier', true);
  }
}

export default async ({
  claimCellData,
  state,
  model,
  handlers,
  formService,
}) => {
  if (!model.patientCaseId) {
    return openOtherDateWarning(claimCellData.title);
  }

  const patientCase = await apiPatientCases.fetchOne(
    model.patientId,
    model.patientCaseId,
  );

  const patientCaseDates = [
    'initialTxDate',
    'lastSeenDate',
    'accidentDate',
  ].reduce(
    (acc, dateKey) =>
      patientCase[dateKey]
        ? acc.set(dateKey, parseDate(patientCase[dateKey]).format())
        : acc,
    new Map(),
  );

  const otherDates = patientCase.additionalDates.reduce(
    (acc, curr) => acc.set(curr.type.id, parseDate(curr.date).format()),
    patientCaseDates,
  );

  const previousOption =
    model.otherDateQualifier && model.otherDate
      ? {
          label: formatOtherDateLabel({
            qualifier: model.otherDateQualifier,
            date: parseDate(model.otherDate).format('MM/DD/YYYY'),
          }),
          name: claimCellData.name,
          value: {
            qualifier: model.otherDateQualifier,
            date: parseDate(model.otherDate).format(),
          },
        }
      : {};

  const options = getOtherDateOptions({
    previousOption,
    name: claimCellData.name,
    otherDates,
  });

  if (options.length < 2) {
    return openOtherDateWarning(claimCellData.title);
  }

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SINGLE_SELECT, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    options,
    items: [
      {
        name: 'otherDate',
        value: {
          qualifier: state.otherDateQualifier,
          date: state.otherDate,
        },
      },
    ],
  });

  if (result) {
    setEditedField({ result, state, formService });

    handlers.change({
      name: 'otherDate',
      value: result.otherDate.date,
    });

    handlers.change({
      name: 'otherDateQualifier',
      value: result.otherDate.qualifier,
    });
  }

  return undefined;
};
