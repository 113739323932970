import { LitElement, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { trimBlankLines } from '../../../../neb-api-client/src/notes';
import { ckeditorStyles } from '../../../../neb-styles/neb-ckeditor-styles';

export const ELEMENTS = {
  tableData: { selector: 'td' },
  subjectiveSummary: { id: 'subjective-summary' },
  objectiveSummary: { id: 'objective-summary' },
  assessmentSummary: { id: 'assessment-summary' },
  planSummary: { id: 'plan-summary' },
};

class NebChartingNotesSummary extends LitElement {
  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initHandlers() {
    this.__handlers = {
      expand: () => this.onExpand(),
    };
  }

  __initState() {
    this.notes = {
      subjective: '',
      objective: '',
      assessment: '',
      plan: '',
    };

    this.onExpand = () => {};
  }

  static get properties() {
    return {
      notes: Object,
      onExpand: Function,
    };
  }

  __renderStyles() {
    return html`
      ${unsafeHTML(ckeditorStyles)}

      <style>
        :host() {
          display: block;
          height: 100%;
          width: 100%;
        }

        .scroll-container {
          box-sizing: border-box;
          overflow-y: auto;
          width: 100%;
          height: 100%;
        }

        .summary-column {
          display: grid;
          grid-template-columns: auto;
          box-sizing: border-box;
        }

        .note-header {
          padding: 0 0 5px 10px;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 0.5px solid rgba(151, 151, 151, 0.5);
          margin-bottom: 15px;
        }

        .note-content {
          padding: 0 10px 40px 10px;
          font-family: 'Helvetica';
          font-size: 16px;
        }

        .subjective-summary {
          margin-top: 11px;
        }
      </style>
    `;
  }

  __formatNoteContent(noteContent) {
    noteContent = trimBlankLines(noteContent);
    noteContent = unsafeHTML(noteContent);
    return noteContent;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div @click="${this.__handlers.expand}" class="scroll-container">
        <div class="summary-column">
          <div id="${ELEMENTS.subjectiveSummary.id}" class="subjective-summary">
            <div class="note-header">Subjective</div>
            <div class="note-content">
              ${this.__formatNoteContent(this.notes.subjective)}
            </div>
          </div>
          <div id="${ELEMENTS.objectiveSummary.id}">
            <div class="note-header">Objective</div>
            <div class="note-content">
              ${this.__formatNoteContent(this.notes.objective)}
            </div>
          </div>
          <div id="${ELEMENTS.assessmentSummary.id}">
            <div class="note-header">Assessment</div>
            <div class="note-content">
              ${this.__formatNoteContent(this.notes.assessment)}
            </div>
          </div>
          <div id="${ELEMENTS.planSummary.id}">
            <div class="note-header">Plan</div>
            <div class="note-content">
              ${this.__formatNoteContent(this.notes.plan)}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-charting-notes-summary', NebChartingNotesSummary);
