import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { formatDollarAmount } from '../../../packages/neb-utils/formatters';
import {
  PACKAGE_CODES,
  TRANSFER_BALANCE_FROM,
  TRANSFER_BALANCE_FROM_ITEMS,
  TRANSFER_BALANCE_TO,
  TRANSFER_BALANCE_TO_ITEMS,
} from '../../../packages/neb-utils/neb-ledger-util';
import {
  isRequiredOrOptional,
  isSameParty,
  isMissingSecondaryPayer,
  isZeroBalance,
  isMissingPrimaryPayer,
} from '../../utils/transfer-balance';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  balanceText: {
    id: 'balance-text',
  },
  transferFrom: {
    id: 'transfer-from',
  },
  transferTo: {
    id: 'transfer-to',
  },
  reasonCode: {
    id: 'reason-code',
  },
  transferButton: {
    id: 'transfer-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
};

export default class NebFormTransferBalance extends NebForm {
  static get properties() {
    return {
      ...super.properties,
      paymentCodes: Array,
      adjustmentCodes: Array,
    };
  }

  static createModel() {
    return {
      transferFrom: null,
      transferTo: null,
      reasonCode: null,
      balance: { primary: 0, secondary: 0, patient: 0 },
      hasPrimaryPayer: false,
      hasSecondaryPayer: false,
    };
  }

  createSelectors() {
    return {
      children: {
        transferFrom: {
          validators: [
            isRequired(),
            isSameParty(),
            isMissingPrimaryPayer(),
            isMissingSecondaryPayer(),
            isZeroBalance(),
          ],
        },
        transferTo: {
          validators: [
            isRequired(),
            isSameParty(),
            isMissingPrimaryPayer(),
            isMissingSecondaryPayer(),
          ],
        },
        reasonCode: {
          format: v => (v ? this.codes.find(code => code.data.id === v) : null),
          unformat: v => (v ? v.data.id : null),
          validators: [isRequiredOrOptional()],
        },
      },
    };
  }

  initState() {
    super.initState();

    this.confirmLabel = 'Transfer Balance';

    this.adjustmentCodes = [];
    this.paymentCodes = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e => {
        if (e.value) {
          this.formService.apply(e.name, e.value);

          if (this.state.reasonCode) {
            let isIncluded = false;

            switch (this.state.transferTo) {
              case TRANSFER_BALANCE_TO.ADJUSTMENT:
                isIncluded = this.getFilteredAdjustmentTypes().some(
                  code => code.data.id === this.state.reasonCode.data.id,
                );

                break;

              case TRANSFER_BALANCE_TO.PRIMARY:
                isIncluded = false;

                break;

              default:
                isIncluded = this.getFilteredPaymentTypes().some(
                  code => code.data.id === this.state.reasonCode.data.id,
                );
            }

            if (!isIncluded) this.formService.apply('reasonCode', null);
          }

          this.formService.validate();
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          display: flex;
        }

        .add-button {
          padding-right: ${CSS_SPACING};
        }

        .grid-fields {
          display: grid;
          width: 100%;
          column-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .input-field {
          display: block;
          margin-bottom: ${CSS_SPACING};
        }

        .transfer-text {
          display: block;
          margin-top: 25px;
        }
      `,
    ];
  }

  getFilteredAdjustmentTypes() {
    return this.adjustmentCodes.filter(
      ({ data }) =>
        (this.model.hasPrimaryPayer ? data.forInsurance : data.forPatient) &&
        data.active &&
        data.code !== 'Pkg',
    );
  }

  getFilteredPaymentTypes() {
    return this.paymentCodes.filter(
      ({ data }) =>
        data.active && data.forPatient && !PACKAGE_CODES.includes(data.code),
    );
  }

  getCodes() {
    return this.state.transferTo === TRANSFER_BALANCE_TO.ADJUSTMENT
      ? this.getFilteredAdjustmentTypes()
      : this.getFilteredPaymentTypes();
  }

  getAmount() {
    let amount = 0;

    switch (this.state.transferFrom) {
      case TRANSFER_BALANCE_FROM.PRIMARY:
        amount = this.model.balance.primary;
        break;
      case TRANSFER_BALANCE_FROM.SECONDARY:
        amount = this.model.balance.secondary;
        break;
      case TRANSFER_BALANCE_FROM.PATIENT:
        amount = this.model.balance.patient;
        break;
      default:
    }

    return `${formatDollarAmount(amount)} will be transferred`;
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.transferButton.id}"
          class="add-button button"
          ?disabled="${this.confirmDisabled}"
          .label="${this.confirmLabel}"
          .processing="${this.processing}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
          unelevated
        ></neb-button>

        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
          ?disabled="${this.processing}"
        ></neb-button>
      </div>
    `;
  }

  renderBalanceText() {
    return html`
      <neb-text class="transfer-text" id="${ELEMENTS.balanceText.id}">
        ${this.getAmount()}
      </neb-text>
    `;
  }

  renderReasonCode() {
    const disabled =
      this.state.transferTo &&
      this.state.transferTo === TRANSFER_BALANCE_TO.PRIMARY;

    const helper = disabled ? '' : 'Required';

    return html`
      <neb-select
        class="input-field"
        id="${ELEMENTS.reasonCode.id}"
        name="reasonCode"
        .helper="${helper}"
        .label="${'Code'}"
        .value="${this.state.reasonCode}"
        .error="${this.errors.reasonCode}"
        .onChange="${this.handlers.change}"
        .items="${this.getCodes()}"
        ?disabled="${disabled}"
        itemMinWidth="350"
        showFullText
        wrapText
        itemHeight="70"
        forceAlignMenu="right"
      ></neb-select>
    `;
  }

  renderContent() {
    return html`
      <div class="grid-fields">
        <neb-select
          class="input-field"
          id="${ELEMENTS.transferFrom.id}"
          helper="Required"
          name="transferFrom"
          .label="${'Transfer From'}"
          .value="${this.state.transferFrom}"
          .error="${this.errors.transferFrom}"
          .onChange="${this.handlers.change}"
          .items="${TRANSFER_BALANCE_FROM_ITEMS}"
        ></neb-select>

        ${this.renderBalanceText()}

        <neb-select
          id="${ELEMENTS.transferTo.id}"
          class="input-field"
          helper="Required"
          name="transferTo"
          .label="${'Transfer To'}"
          .error="${this.errors.transferTo}"
          .onChange="${this.handlers.change}"
          .value="${this.state.transferTo}"
          .items="${TRANSFER_BALANCE_TO_ITEMS}"
        ></neb-select>

        ${this.renderReasonCode()}
      </div>
    `;
  }
}

customElements.define('neb-form-transfer-balance', NebFormTransferBalance);
