export const FETCH_PRACTICE_INFO = 'FETCH_PRACTICE_INFO';

export function requestPracticeInfo() {
  return {
    type: FETCH_PRACTICE_INFO,
  };
}

export function requestPracticeInfoSuccess(response) {
  return {
    type: FETCH_PRACTICE_INFO,
    status: 'success',
    response,
  };
}

export function requestPracticeInfoFailure(error) {
  return {
    type: FETCH_PRACTICE_INFO,
    status: 'failed',
    error,
  };
}

export function fetchPracticeInfo(registryPrefix, tenantShortName) {
  return async (dispatch, getState) => {
    const practiceInfoStore = getState().practiceInfo;

    if (
      practiceInfoStore &&
      (practiceInfoStore.isFetching || practiceInfoStore.fetchingError)
    ) {
      return;
    }

    dispatch(requestPracticeInfo());
    const url = `${registryPrefix}/public/v1/tenants/${tenantShortName}`;

    try {
      const res = await fetch(url, {
        method: 'GET',
      });
      const json = await res.json();

      if (!res.ok) {
        const err = new Error();
        err.res = json;
        throw err;
      }

      dispatch(requestPracticeInfoSuccess(json));
    } catch (err) {
      const errObj = err.res ? err.res : err;
      console.log(errObj);
      dispatch(requestPracticeInfoFailure(errObj));
    }
  };
}
