import { html, css, unsafeCSS } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { trimBlankLines } from '../../../../packages/neb-api-client/src/notes';
import { ckeditorStyles } from '../../../../packages/neb-styles/neb-ckeditor-styles';
import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

class NebUnsignedEncounterProviderNotesSummary extends NebUnsignedEncounterSummaryPanel {
  createModel() {
    return { note: '' };
  }

  static get styles() {
    return [
      super.styles,
      css`
        ${unsafeCSS(ckeditorStyles)}

        :host() {
          display: block;
          height: 100%;
          width: 100%;
        }

        .note-content {
          padding: 0 10px 10px 10px;
        }
      `,
    ];
  }

  __formatNoteContent(noteContent) {
    noteContent = trimBlankLines(noteContent);
    noteContent = unsafeHTML(noteContent);
    return noteContent;
  }

  getTitle() {
    return 'Provider Notes';
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.view.id}" class="note-content">
        ${this.__formatNoteContent(this.model.note)}
      </div>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-provider-notes-summary',
  NebUnsignedEncounterProviderNotesSummary,
);
