import './neb-fiserv-iframe';

import { openPopup } from '@neb/popup';
import { LitElement, html } from 'lit';

import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { currencyToCents } from '../../../packages/neb-utils/formatters';

import {
  FISERV_RESPSTAT,
  createFiservPayment,
  createFiservPaymentMethod,
} from './fiserv-api-client';
import { PAYMENT_RESULT_CONFIG } from './messages';
import { formatFiservElectronicPayment } from './utils';

export const ELEMENTS = {
  fiservIframe: {
    id: 'fiserv-iframe',
  },
};

class NebPayfacFiserv extends LitElement {
  static get properties() {
    return {
      layout: { type: String, reflect: true },
      isProcessing: Boolean,
      confirmLabel: String,
      merchantAccount: Object,
      amount: String,
      postalCode: String,
      address1: String,
      address2: String,
      city: String,
      state: String,
      firstName: String,
      lastName: String,
      holderId: String,
      holderType: String,
      cardDescription: String,
      logContent: Object,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__token = '';
    this.__expiry = '';

    this.confirmLabel = '';
    this.postalCode = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.firstName = '';
    this.lastName = '';
    this.holderId = '';
    this.holderType = '';
    this.amount = '';
    this.cardDescription = '';
    this.merchantAccount = {};
    this.logContent = {};
    this.isProcessing = false;

    this.onDirty = () => {};

    this.onCancel = () => {};

    this.onSave = () => {};

    this.onProcessing = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onCancel(),

      submit: async ({ token, expiry }) => {
        this.__token = token;
        this.__expiry = expiry;

        if (this.amount) {
          await this.__createPayment();
        } else {
          await this.__createPaymentMethod();
        }
      },
    };
  }

  // eslint-disable-next-line complexity
  async __createPayment() {
    const payload = {
      merchantAccountId: this.merchantAccount.id,
      amount: currencyToCents(this.amount),
      postal: this.postalCode,
      address: this.address1,
      address2: this.address2,
      city: this.city,
      region: this.state,
      firstName: this.firstName,
      lastName: this.lastName,
      expiry: this.__expiry,
      token: this.__token,
      holderId: this.holderId,
      holderType: this.holderType,
      logContent: this.logContent,
      ecomind: 'T',
    };

    let result;

    try {
      result = await createFiservPayment(payload);
    } catch (e) {
      console.error(e);

      const response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
        subheader: 'Service Unavailable',
      });

      if (response.action === 'confirm') {
        await this.__createPayment();
      }

      return;
    }

    if (result.respstat === FISERV_RESPSTAT.RETRY) {
      const response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
        subheader: result.resptext,
      });

      if (response.action === 'confirm') {
        await this.__createPayment();
      }

      return;
    }

    if (result.status !== 'Approved') {
      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
        subheader: result.resptext,
      });

      return;
    }

    const electronicPayment = formatFiservElectronicPayment(result);

    await this.onSave(electronicPayment);

    if (this.holderType === 'payer') {
      await openPopup(
        POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
        PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
      );

      return;
    }

    const response = await openPopup(
      POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
      PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS_AND_SAVE,
    );

    if (response.action === 'confirm' && response.saveCardOnFile) {
      const paymentMethodPayload = this.__createPaymentMethodPayload({
        description: response.cardOnFileName,
      });

      try {
        const paymentMethodRes =
          await createFiservPaymentMethod(paymentMethodPayload);

        if (paymentMethodRes.status !== FISERV_RESPSTAT.APPROVED) {
          throw new Error(paymentMethodRes.text);
        }

        store.dispatch(openSuccess('Payment method stored successfully'));
      } catch (e) {
        store.dispatch(
          openError('An error occurred when storing payment method', e),
        );
      }
    }
  }

  __createPaymentMethodPayload(overrides = {}) {
    return {
      merchantAccountId: this.merchantAccount.id,
      description: this.cardDescription,
      postal: this.postalCode,
      address: this.address1,
      address2: this.address2,
      city: this.city,
      region: this.state,
      firstName: this.firstName,
      lastName: this.lastName,
      expiry: this.__expiry,
      token: this.__token,
      holderId: this.holderId,
      holderType: this.holderType,
      logContent: this.logContent,
      ...overrides,
    };
  }

  async __createPaymentMethod() {
    const payload = this.__createPaymentMethodPayload();

    let result;

    try {
      result = await createFiservPaymentMethod(payload);
    } catch (e) {
      console.error(e);

      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT_METHOD.FAILURE,
        subheader: 'Service Unavailable',
      });

      return;
    }

    if (result.status !== FISERV_RESPSTAT.APPROVED) {
      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT_METHOD.FAILURE,
        subheader: result.text,
      });

      return;
    }

    await this.onSave(result);

    await openPopup(
      POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
      PAYMENT_RESULT_CONFIG.PAYMENT_METHOD.SUCCESS,
    );
  }

  render() {
    return html`
      <neb-fiserv-iframe
        id="${ELEMENTS.fiservIframe.id}"
        .confirmLabel="${this.confirmLabel}"
        .cancelLabel="${'Cancel'}"
        .layout="${this.layout}"
        .isProcessing="${this.isProcessing}"
        .onProcessing="${this.onProcessing}"
        .onConfirm="${this.__handlers.submit}"
        .onCancel="${this.__handlers.close}"
        .onDirty="${this.onDirty}"
      ></neb-fiserv-iframe>
    `;
  }
}

customElements.define('neb-payfac-fiserv', NebPayfacFiserv);
