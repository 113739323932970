import './neb-radio-button';
import '../../../neb-material-design/src/components/neb-md-select';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import { parseDate } from '../../../neb-utils/date-util';

const HOUR_ITEMS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

const MINUTE_ITEMS = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export const ELEMENTS = {
  dropdownHour: {
    id: 'dropdown-hour',
  },
  dropdownMinute: {
    id: 'dropdown-minute',
  },
  radioButtonAm: {
    id: 'radio-buttom-am',
  },
  radioButtonPm: {
    id: 'radio-buttom-pm',
  },
  textColon: {
    id: 'text-colon',
  },
};

class NebTime extends LitElement {
  static get properties() {
    return {
      label: {
        type: String,
      },
      time: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.label = 'Time';
    this.time = parseDate().startOf('day');

    this.onChange = () => {};
  }

  __isPm() {
    return Boolean(Math.floor(this.time.hours() / 12));
  }

  __initHandlers() {
    this.__handlers = {
      updateHour: e => {
        const selectedHour = parseInt(e.value, 10);
        const isPm = this.__isPm();
        const result = this.time.clone().hours(selectedHour);

        if (isPm) {
          result.add(12, 'hours');
        }

        this.shadowRoot.getElementById(ELEMENTS.dropdownHour.id).validate();
        this.onChange(result);
      },
      updateMinute: e => {
        const selectedMinute = parseInt(e.value, 10);
        const result = this.time.clone().minutes(selectedMinute);

        this.shadowRoot.getElementById(ELEMENTS.dropdownMinute.id).validate();
        this.onChange(result);
      },
      updateTimePeriod: timePeriod => {
        const isPm = timePeriod === 'PM';
        const baseHours = this.time.hours() % 12;
        const hours = isPm ? baseHours + 12 : baseHours;
        const result = this.time.clone().hours(hours);

        this.onChange(result);
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;

          width: 100%;
        }

        .container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .content {
          display: flex;
          align-items: center;
        }

        .container-dropdown {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-right: 20px;
        }

        .container-radio-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .dropdown {
          width: 60px;
        }

        .radio-button:not(:last-child) {
          margin-right: 20px;
        }
        .text-colon {
          margin: 0 5px 0 5px;
        }

        .text-label {
          font-size: 16px;
        }
      `,
    ];
  }

  render() {
    const isPm = this.__isPm();
    return html`
      <div class="container">
        <div class="text text-label">${this.label}</div>

        <div class="content">
          <div class="container-dropdown">
            <neb-md-select
              id="${ELEMENTS.dropdownHour.id}"
              class="dropdown hour-dropdown"
              .items="${HOUR_ITEMS}"
              .value="${this.time.format('h')}"
              .onChange="${this.__handlers.updateHour}"
              required
            ></neb-md-select>

            <div id="${ELEMENTS.textColon.id}" class="text-colon">:</div>

            <neb-md-select
              id="${ELEMENTS.dropdownMinute.id}"
              class="dropdown minute-dropdown"
              .items="${MINUTE_ITEMS}"
              .value="${this.time.format('mm')}"
              .onChange="${this.__handlers.updateMinute}"
              required
            ></neb-md-select>
          </div>

          <div class="container-radio-buttons">
            <neb-radio-button
              id="${ELEMENTS.radioButtonAm.id}"
              class="radio-button"
              label="AM"
              value="AM"
              .checked="${!isPm}"
              .onSelect="${this.__handlers.updateTimePeriod}"
            ></neb-radio-button>

            <neb-radio-button
              id="${ELEMENTS.radioButtonPm.id}"
              class="radio-button"
              label="PM"
              value="PM"
              .checked="${isPm}"
              .onSelect="${this.__handlers.updateTimePeriod}"
            ></neb-radio-button>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-time', NebTime);
