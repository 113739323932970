import { getChargesWarnings } from '../../../utils/charting';
import { safeRequest } from '../shared/safe-request';

export class NebChargesWarningsDataController {
  constructor(host) {
    host.addController(this);
    this.host = host;

    this.__initState();
  }

  __initState() {
    this.__chargesWarnings = [];

    this.__state = {
      patientId: null,
      appointmentId: null,
      serviceDate: null,
    };
  }

  get chargesWarnings() {
    return this.__chargesWarnings;
  }

  async __loadChargesWarningsIfReady() {
    const { patientId, appointmentId, serviceDate } = this.__state;

    if (patientId && appointmentId && serviceDate) {
      await safeRequest(async () => {
        this.__chargesWarnings = await getChargesWarnings({
          patientId,
          appointmentId,
          serviceDate,
        });

        this.host.requestUpdate();
      }, 'Charges Warnings');
    } else {
      this.__chargesWarnings = [];
      this.host.requestUpdate();
    }
  }

  __getState(model) {
    return {
      patientId: model.patientId,
      appointmentId: model.appointmentId,
      serviceDate: model.serviceDate,
    };
  }

  __isStateChanged(state) {
    return JSON.stringify(state) !== JSON.stringify(this.__state);
  }

  hostUpdate() {
    const { model } = this.host;
    if (!model) return;

    const state = this.__getState(model);

    if (this.__isStateChanged(state)) {
      this.__state = {
        ...state,
      };

      this.__loadChargesWarningsIfReady();
    }
  }
}
