import { FETCH_PRACTICE_INFO } from './practiceInfoAction';

function handleFetchAction(state, action) {
  switch (action.status) {
    case 'success':
      const newState = {
        ...state,
        isFetchingPracticeInfo: false,
        fetchingErrorPracticeInfo: null,
        practiceInfoItems: action.response.data,
      };
      return newState;

    case 'failed':
      return {
        ...state,
        isFetchingPracticeInfo: false,
        fetchingErrorPracticeInfo: action.error,
      };

    default:
      return {
        ...state,
        isFetchingPracticeInfo: true,
        fetchingErrorPracticeInfo: null,
      };
  }
}

export const practiceInfo = (
  state = {
    isFetchingPracticeInfo: false,
    fetchingErrorPracticeInfo: null,
    practiceInfoItems: [],
  },
  action,
) => {
  switch (action.type) {
    case FETCH_PRACTICE_INFO:
      return handleFetchAction(state, action);

    default:
      return state;
  }
};
