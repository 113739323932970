import printUpcomingAppointmentsRequest from '../../../../../src/api-clients/pdf/print-upcoming-appointments';
import { PRINT_UPCOMING_APPOINTMENTS_ERROR } from '../../../../../src/utils/user-message';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import { getAppointmentListData } from '../../../../neb-utils/pdf/print-upcoming-appointments';

export default async ({ patient, location = null }) => {
  try {
    const appointmentListData = await getAppointmentListData(
      patient.id,
      location,
    );
    await printPdf(printUpcomingAppointmentsRequest(appointmentListData));
  } catch (e) {
    console.error(e);
    store.dispatch(openError(PRINT_UPCOMING_APPOINTMENTS_ERROR));
  }
};
