import '../../../../neb-styles/neb-icons';
import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { readDataUrlFromBlob } from '../../../../neb-utils/blobProcessor';

export const ELEMENTS = {
  imagePreview: {
    id: 'image-preview',
  },
  stockImageDiv: {
    id: 'stock-image',
  },
};
const TIMEOUT_ERROR = new Error('Timeout');
const TIMEOUT = 2000;

class NebImagePreview extends LitElement {
  static get properties() {
    return {
      fileBlob: Object,
      timeout: Number,
      __showFallback: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.fileBlob = null;
    this.timeout = TIMEOUT;
    this.__showFallback = false;

    this.onInvalidFile = () => {};
  }

  __getValidImage(dataUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve(img);

      img.onerror = e => reject(e);

      img.src = dataUrl;
    });
  }

  async firstUpdated() {
    try {
      const img = await this.loadImage();
      img.id = ELEMENTS.imagePreview.id;
      img.className = 'thumbnail';
      this.shadowRoot.appendChild(img);
    } catch (err) {
      this.__showFallback = true;

      if (err === TIMEOUT_ERROR) {
        console.log(err.message);
      } else {
        console.error(err);
        this.onInvalidFile();
      }
    }
  }

  loadImage() {
    const promise = readDataUrlFromBlob(this.fileBlob).then(dataUrl =>
      this.__getValidImage(dataUrl),
    );
    const timeoutPromise = new Promise(resolve =>
      setTimeout(() => resolve(false), this.timeout),
    );
    return Promise.race([promise, timeoutPromise]).then(
      res => res || Promise.reject(TIMEOUT_ERROR),
    );
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        width: 100%;
      }

      .stock-preview-container {
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        width: 200px;
        height: 150px;
      }

      .thumbnail {
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      ${this.__showFallback === true
        ? html`
            <div
              id="${ELEMENTS.stockImageDiv.id}"
              class="stock-preview-container"
            >
              <neb-icon icon="neb:noDocuments"></neb-icon>
            </div>
          `
        : ''}
    `;
  }
}

customElements.define('neb-image-preview', NebImagePreview);
