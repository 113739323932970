import '../neb-rich-text-editor';
import '../inputs/neb-textfield';
import '../macros/neb-macro-grid';
import '../neb-breadcrumbs';
import '../neb-action-bar';
import '../controls/neb-button-action';
import '../controls/neb-tab-group';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css, LitElement } from 'lit';
import { v4 as uuid } from 'uuid';

import { ADVANCED_MACRO_TOKEN } from '../../../../../src/components/overlays/macros/neb-overlay-advanced-macro-type';
import { MACRO_BUTTON_TYPES } from '../../../../../src/components/overlays/macros/neb-overlay-macro-button-type';
import { formatAnswerSet } from '../../../../../src/formatters/macros';
import { openWarning } from '../../../../../src/store';
import {
  UNSUPPORTED_ADVANCED_TOKENS,
  mergeUniqueAnswerSets,
  openQuestionAnswerOverlay,
} from '../../../../../src/utils/macros';
import {
  getMacroSetFolderById,
  getFolderItems,
  getChoiceTemplates,
} from '../../../../neb-api-client/src/macro-set-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import {
  BUTTON_TYPE_TO_COLOR,
  MACRO_BUTTON_TYPE,
  TABS,
  TAB_KEYS,
} from '../../../../neb-utils/macro-sets';
import { setValueByPath, deepCopy } from '../../../../neb-utils/utils';
import { TOKEN_KEYS } from '../../../../neb-www-practice-charting/src/macro-processor/token-replacement';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { BUTTON_ROLE } from '../neb-button';

export const ELEMENTS = {
  actionBar: { id: 'action-bar' },
  breadcrumbs: { id: 'breadcrumbs' },
  buttonOpenMenu: { id: 'button-open-menu' },
  buttonAdvancedMacro: { id: 'button-advanced-macro' },
  buttonDeleteMacro: { id: 'button-delete-macro' },
  editMenuButtonSection: { id: 'edit-menu-button-section' },
  editMacroButtonSection: { id: 'edit-macro-button-section' },
  grid: { id: 'grid' },
  menuName: { id: 'menu-name' },
  macroName: { id: 'macro-name' },
  macroContent: { id: 'macro-content' },
  name: { id: 'name' },
  tabGroup: { id: 'tab-group' },
  buttonEditChoiceTemplate: { id: 'button-edit-choice-template' },
};

export const MACRO_CONFIRM_MESSAGE = html`
  This macro and its contents will be deleted.<br /><br />Are you sure you want
  to delete this macro?
`;

export const FOLDER_CONFIRM_MESSAGE = {
  HARD_DELETE: html`
    This menu and all of its contents will be deleted. This includes all
    sub-menus and text macros.<br /><br />
    This action cannot be undone.<br /><br />
    Are you sure you want to delete this menu and all of its contents?
  `,
  SOFT_DELETE: html`
    This menu button will be deleted.<br /><br />
    Are you sure you want to delete this menu button?
  `,
};

const createModelItems = () => Array(20).fill(null);

const wrapAdvancedMacroToken = (field, value) =>
  `<span contenteditable="false" data-macro-replace-field="${field}">${value}</span>`;

export const getQuestionIdsFromContent = content => {
  const matchesIterator = content.matchAll(
    /data-macro-replace-field="([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})"/g,
  );

  const matches = Array.from(matchesIterator);
  return matches.map(([, questionId]) => questionId);
};

const visitItems = ({
  items,
  folderDictionary,
  visitedSet = new Set(),
  callback,
}) => {
  items.forEach(item => {
    callback(item);

    if (!item) {
      return;
    }

    const folder = folderDictionary[item.id];

    if (!folder) {
      return;
    }

    if (!folder.items) {
      return;
    }

    if (!visitedSet.has(item.id)) {
      visitedSet.add(item.id);

      visitItems({
        items: folder.items,
        folderDictionary,
        visitedSet,
        callback,
      });
    }
  });
};

export class NebFormMacroSet extends LitElement {
  static get properties() {
    return {
      __dirty: Boolean,
      __selectedIndex: Number,
      __selectedTab: String,
      __folderStack: Array,
      __loadedModel: Object,
      __state: Object,
      __errors: Object,
      __selectedItem: Object,
      __maxContentLength: Number,

      editChoiceTemplates: Boolean,
      model: Object,
    };
  }

  static createModel() {
    const subjectiveFolderId = uuid();
    const objectiveFolderId = uuid();
    const assessmentFolderId = uuid();
    const planFolderId = uuid();

    const bodyAnswerSetId = uuid();
    const spineAnswerSetId = uuid();

    const folders = {
      [subjectiveFolderId]: {
        name: 'SUBJECTIVE',
        items: createModelItems(),
      },
      [objectiveFolderId]: {
        name: 'OBJECTIVE',
        items: createModelItems(),
      },
      [assessmentFolderId]: {
        name: 'ASSESSMENT',
        items: createModelItems(),
      },
      [planFolderId]: {
        name: 'PLAN',
        items: createModelItems(),
      },
    };

    return {
      name: '',
      answerSets: {},
      subjectiveFolderId,
      objectiveFolderId,
      assessmentFolderId,
      planFolderId,
      bodyAnswerSetId,
      spineAnswerSetId,
      folders,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .container {
          display: flex;
          flex-flow: column nowrap;
          position: relative;
          width: 100%;
          height: 100%;
        }

        .content {
          display: flex;
          overflow-y: auto;
          flex-flow: column nowrap;
          flex: 1 0 0px;
        }

        .container-header {
          padding: 10px ${CSS_SPACING} 0;
          display: flex;
          align-items: center;
        }

        .tab-group {
          margin: ${CSS_SPACING} 0;
          flex: 0 0 auto;
        }

        .spacer {
          flex: 2 0 0;
        }

        .field-name {
          flex: 1 0 0;
          margin-right: ${CSS_SPACING};
        }

        .breadcrumbs {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .grid-macro {
          padding-bottom: 30px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .container-content {
          display: flex;
          justify-content: space-between;
          margin: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .name-field {
          width: 315px;
        }

        .container-button {
          display: grid;
          grid-column-gap: 10px;
          grid-template-columns: auto auto;
          align-items: end;
        }

        .macro-content {
          height: 100%;
          margin-top: ${CSS_SPACING};
        }

        .container-macro {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__dirty = false;
    this.__selectedIndex = -1;
    this.__selectedTab = TAB_KEYS.SUBJECTIVE;
    this.__folderStack = [];
    this.__selectedItem = {};
    this.__state = {};
    this.__errors = {};
    this.__maxContentLength = 256 * 1000;
    this.__deletedQuestions = [];

    this.editChoiceTemplates = false;
    this.model = {};

    this.__resetSelection();

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => {
        if (this.__validateSetName()) {
          return this.onSave(this.__state);
        }

        return undefined;
      },

      cancel: () => this.onCancel(),

      changeSetName: ({ name, value }) => {
        this.__apply(name, value);
        this.__validateSetName();
        this.__checkDirty();
      },

      change: ({ name, value }) => {
        this.__apply(name, value);
        this.__checkDirty();
      },

      macroNameChange: ({ value }) => {
        this.__selectedItem.name = value;
        this.__checkDirty();
        return this.requestUpdate();
      },

      macroContentChange: content => {
        const currentContent = this.__selectedItem.content || '';

        if (
          content.length > this.__maxContentLength &&
          currentContent.trim().length <= this.__maxContentLength
        ) {
          openWarning(
            `Content cannot exceed ${this.__maxContentLength.toLocaleString()} characters.`,
          );

          this.__selectedItem.content = `${currentContent} `;
          return this.requestUpdate();
        }

        const contentQuestionIds = getQuestionIdsFromContent(content);
        const questionDictionary = this.__buildQuestionDictionary(
          new Set(contentQuestionIds),
        );
        const currentQuestionIds = new Set(
          this.__selectedItem.questions.map(({ id }) => id),
        );

        const { questions, newContent, insertedQuestionIds } =
          contentQuestionIds.reduce(
            (acc, questionId) => {
              if (!questionDictionary[questionId]) {
                const question = new RegExp(
                  `<span[^>]+${questionId}[^>]+>(.*?)</span>`,
                );

                acc.newContent = acc.newContent.replace(question, '$1');
                return acc;
              }

              if (
                !currentQuestionIds.has(questionId) ||
                acc.insertedQuestionIds.has(questionId)
              ) {
                const newQuestionId = uuid();
                const question = questionDictionary[questionId];
                const newAnswerSetId = this.__duplicateAnswerSet(
                  question.answerSetId,
                );

                acc.questions.push({
                  ...deepCopy(question),
                  ...(newAnswerSetId ? { answerSetId: newAnswerSetId } : {}),
                  id: newQuestionId,
                });

                let count = acc.insertedQuestionIds.has(questionId) ? 0 : 1;

                acc.newContent = acc.newContent.replace(
                  new RegExp(questionId, 'g'),
                  match => {
                    count += 1;
                    if (count === 2) return newQuestionId;
                    return match;
                  },
                );

                return acc;
              }

              acc.insertedQuestionIds.add(questionId);
              acc.questions.push(questionDictionary[questionId]);
              return acc;
            },
            {
              questions: [],
              newContent: content,
              insertedQuestionIds: new Set(),
            },
          );

        const deletedQuestions = this.__selectedItem.questions.filter(
          question => !insertedQuestionIds.has(question.id),
        );

        this.__deletedQuestions = [
          ...this.__deletedQuestions,
          ...deletedQuestions,
        ];

        this.__selectedItem.content = newContent;
        this.__selectedItem.questions = questions;

        this.__checkDirty();
        return this.requestUpdate();
      },

      selectTab: id => {
        this.__selectedTab = id;
        const folderId = this.model[`${id}FolderId`];
        return this.__navigateToFolder(folderId, [folderId]);
      },

      openMenu: () =>
        this.__navigateToFolder(this.__selectedItem.id, [
          ...this.__folderStack,
          this.__selectedItem.id,
        ]),

      selectItem: async (item, index) => {
        this.__selectedIndex = index;

        if (item) {
          this.__selectedItem = this.__state.folders[
            this.__currentFolderId
          ].items
            .filter(i => i)
            .find(({ id }) => item.id === id);

          return;
        }

        const result = await openOverlay(OVERLAY_KEYS.MACRO_BUTTON_TYPE, {
          ...this.__state,
          currentFolderId: this.__currentFolderId,
        });

        if (this.__isNotMacroButtonType(result)) return;

        let elementId;

        switch (result.type) {
          case MACRO_BUTTON_TYPE.FOLDER:
            this.__selectedItem = {
              id: uuid(),
              type: result.type,
            };

            this.__state.folders = {
              ...this.__state.folders,
              [this.__selectedItem.id]: { name: '', items: createModelItems() },
            };

            elementId = ELEMENTS.menuName.id;
            break;

          case MACRO_BUTTON_TYPE.EXISTING_FOLDER:
            this.__selectedItem = {
              id: result.selectedExistingMenuId,
              type: MACRO_BUTTON_TYPE.FOLDER,
            };

            setValueByPath(this.__loadedModel, ['folders'], {
              ...this.__loadedModel.folders,
              ...result.newlyFetchedFolders,
            });

            setValueByPath(this.__loadedModel, ['answerSets'], {
              ...result.newlyFetchedAnswerSets,
              ...this.__loadedModel.answerSets,
            });

            this.__state = result.folderState;
            elementId = ELEMENTS.menuName.id;
            break;

          case MACRO_BUTTON_TYPE.MACRO:
            this.__selectedItem = {
              id: uuid(),
              type: result.type,
              name: '',
              content: '',
              questions: [],
            };

            elementId = ELEMENTS.macroName.id;
            break;

          default:
        }

        this.__state.folders[this.__currentFolderId].items[index] =
          this.__selectedItem;

        this.__checkDirty();
        setTimeout(() => this.shadowRoot.getElementById(elementId).focus(), 0);
      },

      reorder: (startIndex, endIndex) => {
        const { items } = this.__state.folders[this.__currentFolderId];
        [items[startIndex], items[endIndex]] = [
          items[endIndex],
          items[startIndex],
        ];

        if (this.__selectedItem.id === items[endIndex].id) {
          this.__selectedIndex = endIndex;
        }

        this.__apply(`folders.${this.__currentFolderId}.items`, items);
        this.__checkDirty();
      },

      selectBreadcrumb: index => {
        this.__resetSelection();
        this.__folderStack = this.__folderStack.slice(0, index + 1);
      },

      deleteMacro: async () => {
        const result = await this.__openConfirmDeletePopup();

        if (!result) {
          return;
        }

        setValueByPath(
          this.__state,
          ['folders', this.__currentFolderId, 'items', this.__selectedIndex],
          null,
        );

        this.__resetSelection();
        this.__checkDirty();
      },

      selectAdvancedMacro: async () => {
        const result = await openOverlay(OVERLAY_KEYS.ADVANCED_MACRO_TYPE, {
          defaultBodyAnswerSetId: this.__state.bodyAnswerSetId,
          defaultSpineAnswerSetId: this.__state.spineAnswerSetId,
          answerSets: this.__state.answerSets,
          macroSetId: this.model.id,
        });

        if (!result) {
          return;
        }

        const { type, model } = result;
        const editor = this.shadowRoot.getElementById(ELEMENTS.macroContent.id);

        if (model && model.answerSets) {
          this.__mergeAnswerSets(model);
        }

        if (TOKEN_KEYS.includes(type)) {
          editor.insertText(
            wrapAdvancedMacroToken(type, ADVANCED_MACRO_TOKEN[type]),
          );

          return;
        }

        if (model.question) {
          const id = uuid();
          this.__selectedItem.questions.push({
            id,
            ...model.question,
          });

          editor.insertText(wrapAdvancedMacroToken(id, model.question.text));
        }

        this.__checkDirty();
      },

      selectMacroLink: async ({ dataset }) => {
        const question = this.__selectedItem.questions.find(
          ({ id }) => id === dataset.macroReplaceField,
        );

        if (!question) {
          if (
            Object.keys(UNSUPPORTED_ADVANCED_TOKENS).includes(
              dataset.macroReplaceField,
            )
          ) {
            await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Unsupported Advanced Macro',
              message:
                'The selected advanced macro is currently unsupported and can be deleted using the backspace key.',
            });
          } else {
            await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Advanced Macro',
              message:
                'Text replacement advanced macros are not editable. If you no longer want this advanced macro in this text button, you can delete it using the backspace key.',
            });
          }

          return;
        }

        const res = await openQuestionAnswerOverlay({
          question,
          answerSets: this.__state.answerSets,
          defaultBodyAnswerSetId: this.__state.bodyAnswerSetId,
          defaultSpineAnswerSetId: this.__state.spineAnswerSetId,
          showBackButton: false,
          macroSetId: this.model.id,
        });

        if (res.answerSets) {
          this.__mergeAnswerSets(res);
        }

        if (!res.question) {
          return;
        }

        const oldQuestionText = new RegExp(
          `(data-macro-replace-field="${question.id}".*?>)(.*?)(</span>)`,
        );

        this.__selectedItem.content = this.__selectedItem.content.replace(
          oldQuestionText,
          `$1${res.question.text}$3`,
        );

        question.text = res.question.text;
        question.defaultIndices = res.question.defaultIndices;
        question.answerSetId = res.question.answerSetId;

        this.__checkDirty();
        await this.requestUpdate();
      },

      doubleClick: () => {
        if (this.__selectedItem.type === MACRO_BUTTON_TYPE.FOLDER) {
          this.__handlers.openMenu();
        }
      },

      editChoiceTemplates: async () => {
        const clientChoiceTemplates = formatAnswerSet(this.__state.answerSets);

        const serverChoiceTemplates = this.model.id
          ? await getChoiceTemplates(this.model.id)
          : [];

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_LINK_CHOICE_TEMPLATE,
          {
            templates: mergeUniqueAnswerSets(
              clientChoiceTemplates,
              serverChoiceTemplates,
            ),

            hideChooseButton: true,
          },
        );

        if (result) {
          this.__mergeAnswerSets(result);
          this.__checkDirty();
        }
      },
    };
  }

  __duplicateAnswerSet(answerSetId) {
    if (!answerSetId) {
      return null;
    }

    if (
      answerSetId === this.__state.spineAnswerSetId ||
      answerSetId === this.__state.bodyAnswerSetId
    ) {
      return null;
    }

    const answerSet = this.__state.answerSets[answerSetId];

    if (!answerSet || answerSet.name) {
      return null;
    }

    const newAnswerSetId = uuid();

    const newAnswers = answerSet.answers.map(({ value }) => ({
      id: null,
      value,
    }));

    this.__mergeAnswerSets({
      answerSets: {
        [newAnswerSetId]: { ...answerSet, answers: newAnswers },
      },
    });

    return newAnswerSetId;
  }

  __buildQuestionDictionary(contentQuestionIds) {
    const questions = this.__selectedItem.questions.filter(({ id }) =>
      contentQuestionIds.has(id),
    );

    questions.push(...this.__deletedQuestions);

    if (contentQuestionIds.size !== questions.length) {
      visitItems({
        items: this.__soapFolderIds.map(id => ({ id })),
        folderDictionary: this.__state.folders,
        callback: item => {
          if (item && item.type === MACRO_BUTTON_TYPE.MACRO) {
            item.questions.forEach(question => {
              if (contentQuestionIds.has(question.id)) {
                questions.push(question);
              }
            });
          }
        },
      });
    }

    return Object.fromEntries(questions.map(q => [q.id, q]));
  }

  __mergeAnswerSets({ answerSets }) {
    this.__apply('answerSets', {
      ...this.__state.answerSets,
      ...deepCopy(answerSets),
    });
  }

  get __soapFolderIds() {
    return [
      this.__state.subjectiveFolderId,
      this.__state.objectiveFolderId,
      this.__state.assessmentFolderId,
      this.__state.planFolderId,
    ];
  }

  async __folderHasUnloadedReferences(folderId) {
    const parentFolderItems = this.__state.id
      ? (
          await getFolderItems({
            childId: folderId,
          })
        ).data
      : [];

    return parentFolderItems.some(
      ({ parentFolderId }) =>
        !this.__state.folders[parentFolderId] ||
        !this.__state.folders[parentFolderId].items,
    );
  }

  __folderHasMultipleReferences(folderId) {
    if (this.__soapFolderIds.includes(folderId)) {
      return true;
    }

    let count = 0;

    visitItems({
      items: this.__soapFolderIds.map(id => ({ id })),
      folderDictionary: this.__state.folders,
      callback: item => {
        if (item && item.id === folderId) {
          count += 1;
        }
      },
    });

    if (count > 1) {
      return true;
    }

    return this.__folderHasUnloadedReferences(folderId);
  }

  async __openConfirmDeletePopup() {
    if (this.__selectedItem.type === MACRO_BUTTON_TYPE.MACRO) {
      return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: 'Delete Macro',
        message: MACRO_CONFIRM_MESSAGE,
        confirmText: 'Yes',
        cancelText: 'No',
      });
    }

    const hasMultipleReferences = await this.__folderHasMultipleReferences(
      this.__selectedItem.id,
    );

    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Delete Macro',
      message: hasMultipleReferences
        ? FOLDER_CONFIRM_MESSAGE.SOFT_DELETE
        : FOLDER_CONFIRM_MESSAGE.HARD_DELETE,
      confirmText: 'Yes',
      cancelText: 'No',
    });
  }

  __isNotMacroButtonType(result) {
    return !result || !MACRO_BUTTON_TYPES.includes(result.type);
  }

  __apply(name, value) {
    setValueByPath(this.__state, name.split('.'), value);
    this.__state = { ...this.__state };
  }

  async __navigateToFolder(folderId, newFolderStack) {
    this.__folderStack = newFolderStack;
    this.__resetSelection();

    if (!this.__state.folders[folderId].items) {
      const res = await getMacroSetFolderById(folderId, this.model.id);

      setValueByPath(this.__loadedModel, ['folders'], {
        ...this.__loadedModel.folders,
        ...res.folders,
      });

      this.__apply('folders', {
        ...this.__state.folders,
        ...deepCopy(res.folders),
      });

      setValueByPath(this.__loadedModel, ['answerSets'], {
        ...this.__loadedModel.answerSets,
        ...res.answerSets,
      });

      this.__mergeAnswerSets(res);
    }
  }

  __validateSetName() {
    this.__errors = !this.__state.name
      ? {
          name: 'Required',
        }
      : {};

    return this.__state.name;
  }

  __checkDirty() {
    this.__dirty = !equal(this.__loadedModel, this.__state);
  }

  __resetSelection() {
    this.__selectedItem = { id: '', type: '' };
  }

  async firstUpdated() {
    this.__state = deepCopy(this.model);
    this.__loadedModel = deepCopy(this.model);
    this.__folderStack = [this.model.subjectiveFolderId];

    if (this.editChoiceTemplates) {
      await this.__handlers.editChoiceTemplates();
    }
  }

  update(changedProps) {
    super.update(changedProps);

    if (changedProps.has('__dirty')) {
      this.onChangeDirty(this.__dirty);
    }
  }

  get __currentFolderId() {
    return this.__folderStack[this.__folderStack.length - 1];
  }

  get __currentFolderFormattedForMacroGrid() {
    if (!this.__currentFolderId) {
      return null;
    }

    const folder = this.__state.folders[this.__currentFolderId];

    return {
      ...folder,
      items: (folder.items || []).map(item => {
        const type = item ? item.type : item;

        switch (type) {
          case MACRO_BUTTON_TYPE.FOLDER:
            return {
              ...item,
              ...BUTTON_TYPE_TO_COLOR[type],
              name: this.__state.folders[item.id].name,
            };

          case MACRO_BUTTON_TYPE.MACRO:
            return {
              ...item,
              ...BUTTON_TYPE_TO_COLOR[type],
            };

          default:
            return item;
        }
      }),
    };
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Save"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  __renderDeleteMacroButton() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonDeleteMacro.id}"
        label="Delete Macro"
        .role="${BUTTON_ROLE.OUTLINE}"
        .onClick="${this.__handlers.deleteMacro}"
      ></neb-button>
    `;
  }

  __renderConfigurationArea() {
    switch (this.__selectedItem.type) {
      case MACRO_BUTTON_TYPE.FOLDER:
        return html`
          <div
            id="${ELEMENTS.editMenuButtonSection.id}"
            class="container-content"
          >
            <neb-textfield
              id="${ELEMENTS.menuName.id}"
              class="name-field"
              label="Menu Name"
              maxLength="100"
              .name="folders.${this.__selectedItem.id}.name"
              .value="${this.__state.folders[this.__selectedItem.id].name}"
              .onChange="${this.__handlers.change}"
            ></neb-textfield>

            <div class="container-button">
              <neb-button
                id="${ELEMENTS.buttonOpenMenu.id}"
                class="button"
                label="Open Menu"
                .onClick="${this.__handlers.openMenu}"
              ></neb-button>

              ${this.__renderDeleteMacroButton()}
            </div>
          </div>
        `;

      case MACRO_BUTTON_TYPE.MACRO:
        return html`
          <div
            id="${ELEMENTS.editMacroButtonSection.id}"
            class="container-macro"
          >
            <div class="container-content">
              <neb-textfield
                id="${ELEMENTS.macroName.id}"
                class="name-field"
                label="Text Name"
                maxLength="100"
                .value="${this.__selectedItem.name}"
                .onChange="${this.__handlers.macroNameChange}"
              ></neb-textfield>

              <div class="container-button">
                <neb-button
                  id="${ELEMENTS.buttonAdvancedMacro.id}"
                  class="button"
                  label="Advanced Macro"
                  .onClick="${this.__handlers.selectAdvancedMacro}"
                ></neb-button>

                ${this.__renderDeleteMacroButton()}
              </div>
            </div>

            <neb-rich-text-editor
              id="${ELEMENTS.macroContent.id}"
              class="macro-content"
              .value="${this.__selectedItem.content}"
              .hideFontControls="${true}"
              .onChange="${this.__handlers.macroContentChange}"
              .onLinkClick="${this.__handlers.selectMacroLink}"
            ></neb-rich-text-editor>
          </div>
        `;

      default:
        return '';
    }
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div class="container-header">
            <neb-textfield
              id="${ELEMENTS.name.id}"
              class="field-name"
              label="Set Name"
              helper="Required"
              name="name"
              maxLength="100"
              .error="${this.__errors.name}"
              .value="${this.__state.name}"
              .onChange="${this.__handlers.changeSetName}"
            ></neb-textfield>
            <neb-button-action
              id="${ELEMENTS.buttonEditChoiceTemplate.id}"
              leadingIcon="edit"
              label="Edit Choice Templates"
              .onClick="${this.__handlers.editChoiceTemplates}"
            ></neb-button-action>
            <div class="spacer"></div>
          </div>

          <neb-tab-group
            id="${ELEMENTS.tabGroup.id}"
            class="tab-group"
            .selectedId="${this.__selectedTab}"
            .items="${TABS}"
            .onSelect="${this.__handlers.selectTab}"
          ></neb-tab-group>

          <neb-breadcrumbs
            id="${ELEMENTS.breadcrumbs.id}"
            class="breadcrumbs"
            .items="${this.__folderStack.map(
              id => this.__state.folders[id].name.trim() || 'untitled',
            )}"
            .onSelect="${this.__handlers.selectBreadcrumb}"
          ></neb-breadcrumbs>

          <neb-macro-grid
            id="${ELEMENTS.grid.id}"
            class="grid-macro"
            reorder="true"
            .folder="${this.__currentFolderFormattedForMacroGrid}"
            .selectedId="${this.__selectedItem.id}"
            .selectedIndex="${this.__selectedIndex}"
            .onSelectItem="${this.__handlers.selectItem}"
            .onReorder="${this.__handlers.reorder}"
            .onDoubleClick="${this.__handlers.doubleClick}"
          ></neb-macro-grid>

          ${this.__renderConfigurationArea()}
        </div>

        ${this.renderActionBar()}
      </div>
    `;
  }
}

customElements.define('neb-form-macro-set', NebFormMacroSet);
