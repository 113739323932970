import { html, css } from 'lit';

import { saveMacroSet } from '../../../../packages/neb-api-client/src/macro-set-api-client';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { NebFormMacroSet } from '../../../../packages/neb-lit-components/src/components/forms/neb-form-macro-set';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
  header: {
    id: 'header',
  },
};

class NebOverlayMacroSet extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__formModel = NebFormMacroSet.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async macroSet => {
        try {
          const result = await saveMacroSet(macroSet);
          store.dispatch(openSuccess('Macro Set saved successfully'));
          this.isDirty = false;
          this.dismiss(result);
        } catch (e) {
          store.dispatch(
            openError('An error occurred when saving the Macro Set'),
          );
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = this.model.macroSet
        ? this.model.macroSet
        : NebFormMacroSet.createModel();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .spacing {
          margin: ${CSS_SPACING} ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  get __title() {
    return this.model.macroSet ? 'Update Macro Set' : 'Add Macro Set';
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="spacing"
        showCancelButton
        .title="${this.__title}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-form-macro-set
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .editChoiceTemplates="${!!this.model.editChoiceTemplates}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-macro-set>
    `;
  }
}

customElements.define('neb-overlay-macro-set', NebOverlayMacroSet);
