export const _creating = (state, _action) => ({
  ...state,
  creating: true,
  createError: null,
});
export const _created = (state, action) => ({
  ...state,
  creating: false,
  created: true,
  createError: null,
  item: action.res.data[0].id,
});
export const _createError = (state, action) => ({
  ...state,
  creating: false,
  created: false,
  createError: action.createError,
  item: '',
});
