export const _fetching = (state, _action) => ({
  ...state,
  fetching: true,
  fetchError: null,
});
export const _fetched = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  fetchError: null,
  item: action.res.data[0].id,
});
export const _fetchExists = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  fetchError: null,
  item: action.id,
});
export const _fetchError = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  fetchError: action.fetchError,
  item: '',
});
