import '../../inputs/neb-textfield';
import '../../inputs/neb-select';
import '../../field-groups/neb-modifiers';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { number } from '../../../../../neb-utils/masks';

export const ELEMENTS = {
  modifiers: {
    id: 'modifiers',
  },
  units: {
    id: 'units',
  },
  diagnosisDropdown: {
    id: 'diagnosis-dropdown',
  },
  textNoDiagnoses: {
    id: 'no-diagnoses-text',
    text: 'No Encounter Dx',
  },
};
class NebLedgerEncounterChargesMobileRow extends LitElement {
  static get properties() {
    return {
      model: Object,
      errors: Array,
      diagnosisItems: Array,
      name: String,
      onChange: Function,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      modifiers: [],
      units: '',
      diagnosisPointers: [],
    };

    this.errors = [];
    this.diagnosisItems = [];
    this.name = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          padding-top: ${CSS_SPACING};
          padding-right: 32px;
        }

        .container {
          height: 100%;
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: ${CSS_SPACING} 40px;
        }

        .label {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .textfield {
          --text-indent: 4px;
        }

        .textfield-units {
          width: 50px;
        }

        .textfield-modifiers {
          width: 40px;
        }

        .column-span {
          grid-column: span 2;
        }
      `,
    ];
  }

  __renderModifierFields() {
    return html`
      <neb-modifiers
        id="${ELEMENTS.modifiers.id}"
        label=""
        name="modifiers"
        .values="${this.model.modifiers}"
        .errors="${this.errors.modifiers}"
        .onChange="${this.__handlers.change}"
        ?disabled="${!!this.model.id}"
      ></neb-modifiers>
    `;
  }

  __renderUnitsField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.units.id}"
        class="textfield textfield-units"
        name="units"
        maxLength="3"
        label=""
        .value="${this.model.units}"
        .error="${this.errors.units}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .onChange="${this.__handlers.change}"
        ?disabled="${!!this.model.id}"
      ></neb-textfield>
    `;
  }

  __renderDiagnosisDropdown() {
    return this.diagnosisItems.length
      ? html`
          <neb-select
            id="${ELEMENTS.diagnosisDropdown.id}"
            class="column-span"
            label=""
            helper=" "
            name="diagnosisPointers"
            .items="${this.diagnosisItems}"
            .value="${this.model.diagnosisPointers}"
            .error="${this.errors.diagnosisPointers}"
            .onChange="${this.__handlers.change}"
            ?disabled="${!!this.model.id}"
            placeholder="Select"
            multiSelect
            showFullText
          ></neb-select>
        `
      : html`
          <span id="${ELEMENTS.textNoDiagnoses.id}" class="column-span"
            >${ELEMENTS.textNoDiagnoses.text}</span
          >
        `;
  }

  render() {
    return html`
      <div class="container">
        <div class="label">Modifiers</div>
        <div class="label">Units</div>

        ${this.__renderModifierFields()} ${this.__renderUnitsField()}

        <div class="label column-span">Diagnosis Pointer</div>
        ${this.__renderDiagnosisDropdown()}
      </div>
    `;
  }
}
customElements.define(
  'neb-ledger-encounter-charges-mobile-row',
  NebLedgerEncounterChargesMobileRow,
);
