import '../../../../neb-lit-components/src/components/tables/neb-table';
import '../../../../neb-lit-components/src/components/neb-header';
import '../../../../neb-lit-components/src/components/neb-text';
import '../../../../../src/components/tables/billing/neb-table-payment-methods';
import '../../../../../src/components/tables/billing/neb-table-patient-billing-cases';
import '../../../../neb-lit-components/src/components/neb-radio-button';
import '../../../../neb-lit-components/src/components/controls/neb-switch';
import '../../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../../neb-lit-components/src/components/neb-tooltip';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  formatForDefaultBillType,
  getPatientPackages,
  updatePatientPackage,
} from '../../../../../src/api-clients/patient-package';
import {
  openPayfacIframePopup,
  ADD_PAYMENT_METHOD_TEMPLATE,
} from '../../../../../src/features/payfac/utils';
import { BILL_TYPES } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import {
  fetchMany as fetchCases,
  update,
} from '../../../../neb-api-client/src/patient-cases';
import * as feeScheduleApi from '../../../../neb-api-client/src/patient-fee-schedules';
import { getPatientInsurances } from '../../../../neb-api-client/src/patient-insurance-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getMerchantAccounts } from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import {
  getPaymentMethods,
  updatePaymentMethod,
} from '../../../../neb-api-client/src/payments/payment-methods-api-client';
import { countPaymentMethodInSchedule } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import { getPracticeSettings } from '../../../../neb-api-client/src/services/practice-settings';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { NebLayout } from '../../../../neb-lit-components/src/components/neb-layout';
import { sortPatients } from '../../../../neb-lit-components/src/components/overlays/payment/util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { savePatient } from '../../../../neb-lit-components/src/utils/patients';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { connect, store } from '../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { DISPLAY_TYPE } from '../../../../neb-utils/fee-schedule';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import { createModel } from '../../../../neb-utils/patient';
import { checkCountPaymentMethodInSchedule } from '../../../../neb-utils/patientRelationship';

export const BILL_TYPE_ERROR = 'Error when saving the default bill type';
export const BILL_TYPE_SUCCESS = 'Default bill type saved successfully';
export const CARE_PACKAGE_WARNING =
  'Default bill type set using default care package';
export const CASE_OVERRIDE_SUCCESS = 'Case override successfully saved';
export const CASE_OVERRIDE_ERROR = 'Error when saving case override';

export const REMOVE_ERROR_BANNER = 'Error when removing payment method';
export const REMOVE_SUCCESS_BANNER = 'Payment method removed';
const REMOVE_ERROR_BANNER_FEE_SCHEDULE = 'Error removing fee schedule';
const REMOVE_SUCCESS_BANNER_FEE_SCHEDULE = 'Fee schedule removed';

const NO_CASE_FOR_OVERRIDE_ERROR = 'Patient does not have an active case';
export const INVALID_PRIMARY_SECONDARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR =
  'Default case does not have an active primary and secondary insurance';
export const INVALID_PRIMARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR =
  'Default case does not have an active primary insurance';
export const INVALID_SECONDARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR =
  'Default case does not have an active secondary insurance';
export const INVALID_PACKAGE_CASE_FOR_OVERRIDE_ERROR =
  'Default case does not have an active care package';

export function updateCarePackageDefault(selectedPackage, patientId) {
  const body = formatForDefaultBillType(selectedPackage.data);

  return updatePatientPackage({
    ...body,
    isDefault: true,
    defaultPatientId: patientId,
  });
}

export function updateCaseDefault(selectedCase, patientId) {
  return update(patientId, {
    ...selectedCase.data,
    isDefault: true,
  });
}

function areCasesValid(cases, insurances, packages) {
  // eslint-disable-next-line complexity
  const validCases = cases.reduce((acc, c) => {
    if (c.payerInsurance) {
      if (c.payerInsurance.primaryInsuranceId !== 'Self') {
        const validInsurance = insurances.some(
          insurance => insurance.id === c.payerInsurance.primaryInsuranceId,
        );

        if (!validInsurance) return acc;
      }

      if (c.payerInsurance.secondaryInsuranceId) {
        const validInsurance = insurances.some(
          insurance => insurance.id === c.payerInsurance.secondaryInsuranceId,
        );

        if (!validInsurance) return acc;
      }
    }

    if (c.patientPackageId) {
      const validPackage = packages.some(pkg => {
        const correctPatient = pkg.patientId === c.patientId;

        if (correctPatient) {
          return pkg.id === c.patientPackageId && pkg.active;
        }

        return pkg.patientPackageRelatedPatients.some(
          p => p.relatedPatientId === c.patientId,
        );
      });

      if (!validPackage) return acc;
    }

    acc.push(c);

    return acc;
  }, []);

  return validCases;
}

export const ELEMENTS = {
  paymentMethodsTable: {
    id: 'payment-methods-table',
  },
  feeSchedulesTable: {
    id: 'fee-schedules-table',
  },
  casesTable: {
    id: 'cases-table',
  },
  selfPayRadioButton: {
    id: 'self-pay-radio-button',
  },
  insuranceRadioButton: {
    id: 'insurance-radio-button',
  },
  carePackageRadioButton: {
    id: 'care-package-radio-button',
  },
  switchCaseBillTypeOverride: {
    id: 'case-bill-type-override',
  },
  addPaymentMethodButton: {
    id: 'add-payment-method',
  },
  associateFeeScheduleButton: {
    id: 'associate-fee-schedule-button',
  },
  tooltip: {
    id: 'tooltip',
  },
  tooltipText: {
    id: 'tooltip-text',
  },
};

const TOOLTIP_TEXT =
  'Cards stored on file can be selected at a later date to easily process transactions without requiring a card swipe. Debit cards that require a PIN for processing cannot be stored.';

const REMOVE_WITH_NO_FUTURE_PAYMENTS = html`
  <p>You are about to remove this stored payment method.</p>
  <p>Do you want to continue?</p>
`;

export const REMOVE_WITH_FUTURE_PAYMENTS = {
  title: 'Payment Method In Use',
  message:
    'This stored payment method is in use for a future scheduled payment. You must change the payment method for the future scheduled payment or stop the future scheduled payment before removing this stored payment method.',
  confirmText: 'OK',
};

const REMOVE_FEE_SCHEDULE = html`
  <p>You are about to remove the associated fee schedule.</p>
  <p>Do you want to continue?</p>
`;

const FS_CONFIG = [
  {
    key: 'name',
    label: 'Name',
    flex: css`2 0 0`,
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`2 0 0`,
    formatter: v => DISPLAY_TYPE[v],
  },
  {
    key: 'default',
    label: 'Default',
    flex: css`1 0 0`,
    formatter: v => (v ? 'Yes' : ''),
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`0 0 80px`,
    formatter: v => (v ? 'Active' : 'Inactive'),
  },
];

const NAME_OPTS = {
  reverse: true,
  middleInitial: true,
  preferred: true,
};

class NebPatientGeneralPage extends connect(store)(NebLayout) {
  static get properties() {
    return {
      patient: Object,
      __saving: Boolean,
      __paymentType: Boolean,
      __cases: Array,
      __insurances: Array,
      __packages: Array,
      __paymentMethods: Array,
      __feeSchedules: Array,
      __merchantAccounts: Array,
      __session: Object,
      __multiCarePackageEnabled: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_SPACING};
        }

        .bill-type {
          display: grid;
          grid-template-columns: auto auto 1fr;
          grid-template-rows: auto auto;
        }

        .case-bill-type-override {
          display: grid;
          grid-column: span 3;
          padding-left: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.patient = createModel();
    this.__saving = false;
    this.__cases = [];
    this.__insurances = [];
    this.__packages = [];
    this.__paymentMethods = [];
    this.__feeSchedules = [];
    this.__merchantAccounts = [];
    this.__session = null;
    this.__multiCarePackageEnabled = false;

    this.onUpdatePatient = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changePaymentType: async value => {
        const validChange = !this.__saving && this.patient.billType !== value;

        if (validChange) {
          if (value === BILL_TYPES.CARE_PACKAGE) {
            const noDefaultPackage = !this.__packages.some(p =>
              p.defaultPatientIds.includes(this.patient.id),
            );

            if (noDefaultPackage) {
              const items = this.__packages.map(c => ({
                data: c,
                label: c.name,
              }));

              if (this.__multiCarePackageEnabled) {
                const highestPriorityPackage = items[0];

                updateCarePackageDefault(
                  highestPriorityPackage,
                  this.patient.id,
                );
              } else {
                const success = await openPopup(
                  POPUP_RENDER_KEYS.BILL_TYPE_SET_DEFAULT,
                  {
                    title: 'Set Default Care Package',
                    message:
                      'This patient does not have a default care package. Please set a care package as default to continue.',
                    confirmText: 'Save',
                    cancelText: 'Cancel',
                    name: BILL_TYPES.CARE_PACKAGE,
                    items,
                    saveAction: updateCarePackageDefault,
                    patientId: this.patient.id,
                  },
                );

                if (!success) return;
              }
            }
          }
          this.__saving = true;

          await this.__saveBillType({
            name: 'billType',
            value,
          });

          this.__saving = false;
        }
      },
      // eslint-disable-next-line complexity
      changeCaseBillTypeOverride: async e => {
        if (this.__cases.length === 0) {
          store.dispatch(openError(NO_CASE_FOR_OVERRIDE_ERROR));
        } else if (
          !this.__saving &&
          this.patient.caseBillTypeOverride !== e.value
        ) {
          if (e.value) {
            const defaultCase = this.__cases.find(c => c.isDefault);

            const validCases = areCasesValid(
              this.__cases,
              this.__insurances,
              this.__packages,
            );

            if (!defaultCase) {
              const items = validCases.map(c => ({ data: c, label: c.name }));
              const success = await openPopup(
                POPUP_RENDER_KEYS.BILL_TYPE_SET_DEFAULT,
                {
                  title: 'Set Default Case',
                  message:
                    'This patient does not have a default case. Please set a case as default to continue.',
                  confirmText: 'Save',
                  cancelText: 'Cancel',
                  name: 'Case',
                  items,
                  saveAction: updateCaseDefault,
                  patientId: this.patient.id,
                },
              );

              if (!success) return;
            } else {
              const isDefaultCaseValid = validCases.some(
                c => c.id === defaultCase.id,
              );

              if (
                !isDefaultCaseValid &&
                defaultCase.payerInsurance &&
                defaultCase.payerInsurance.primaryInsuranceId !== 'Self'
              ) {
                const primaryInsurance = this.__insurances.find(
                  i => i.id === defaultCase.payerInsurance.primaryInsuranceId,
                );

                if (defaultCase.payerInsurance.secondaryInsuranceId) {
                  const secondaryInsurance = this.__insurances.find(
                    i =>
                      i.id === defaultCase.payerInsurance.secondaryInsuranceId,
                  );

                  if (!primaryInsurance && !secondaryInsurance) {
                    store.dispatch(
                      openError(
                        INVALID_PRIMARY_SECONDARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR,
                      ),
                    );

                    return;
                  }

                  if (!secondaryInsurance) {
                    store.dispatch(
                      openError(
                        INVALID_SECONDARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR,
                      ),
                    );

                    return;
                  }
                }

                if (!primaryInsurance) {
                  store.dispatch(
                    openError(
                      INVALID_PRIMARY_INSURANCE_CASE_FOR_OVERRIDE_ERROR,
                    ),
                  );

                  return;
                }
              }

              if (!isDefaultCaseValid && defaultCase.patientPackageId) {
                store.dispatch(
                  openError(INVALID_PACKAGE_CASE_FOR_OVERRIDE_ERROR),
                );

                return;
              }
            }
          }

          this.__saving = true;

          await this.__saveBillType(e);

          this.__saving = false;
        }
      },
      addPaymentMethod: async () => {
        const { addresses } = this.patient;
        const holderAddress =
          addresses.length > 0
            ? {
                postalCode: addresses[0].zipcode,
                address1: addresses[0].address1,
                address2: addresses[0].address2,
                city: addresses[0].city,
                state: addresses[0].state,
              }
            : {
                postalCode: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
              };

        const result = await openPayfacIframePopup({
          ...ADD_PAYMENT_METHOD_TEMPLATE,
          merchantAccounts: this.__merchantAccounts,
          holderId: this.patient.id,
          firstName: this.patient.name?.first,
          lastName: this.patient.name?.last,
          postalCode: holderAddress.postalCode,
          address1: holderAddress.address1,
          address2: holderAddress.address2,
          city: holderAddress.city,
          state: holderAddress.state,
        });

        if (result) {
          await this.__fetchPaymentMethods();
        }
      },
      associateFeeSchedule: async () => {
        const result = await openOverlay(OVERLAY_KEYS.ASSOCIATE_FEE_SCHEDULE, {
          patientId: this.patient.id,
          feeSchedules: this.__feeSchedules,
        });

        if (result) {
          this.__fetchFeeSchedules();
        }
      },
      removeFeeSchedule: async (_, item) => {
        const feeSchedule = this.__feeSchedules.find(fs => fs.id === item.id);

        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Fee Schedule',
          message: REMOVE_FEE_SCHEDULE,
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (accepted) {
          await this.__removeFeeSchedule(this.patient.id, feeSchedule.id);

          this.__fetchFeeSchedules();
        }
      },
      editPaymentMethodDescription: async paymentMethod => {
        const description = await openPopup(POPUP_RENDER_KEYS.TEXT_FIELD, {
          title: 'Edit Payment Method',
          label: 'Description',
          text: paymentMethod.description,
          maxLength: 50,
          confirmText: 'Save',
          cancelText: 'Cancel',
        });

        if (!description) return;

        try {
          await updatePaymentMethod({
            ...paymentMethod,
            description,
          });

          store.dispatch(openSuccess('Description updated successfully'));
          await this.__fetchPaymentMethods();
        } catch (e) {
          store.dispatch(openError('Error updating description'));
        }
      },
      toggleDefaultPaymentMethod: async (paymentMethod, isDefault) => {
        this.__paymentMethods = this.__paymentMethods.map(item =>
          item.holderId === this.patient.id
            ? {
                ...item,
                isDefault: item.id === paymentMethod.id ? isDefault : false,
              }
            : item,
        );

        try {
          await updatePaymentMethod({
            ...paymentMethod,
            isDefault,
          });

          store.dispatch(
            openSuccess('Default payment method saved successfully'),
          );
        } catch (e) {
          store.dispatch(openError('Error saving default payment method'));
          this.__fetchPaymentMethods();
        }
      },
      removePaymentMethod: async (_, item) => {
        const paymentMethod = this.__paymentMethods.find(
          pm => pm.id === item.id,
        );
        const counts = await countPaymentMethodInSchedule(
          this.patient.id,
          paymentMethod.id,
        );

        const { count } = counts;

        if (count > 0) {
          await openPopup(
            POPUP_RENDER_KEYS.MESSAGE,
            await this.__paymentConflictPopupConfig(counts),
          );
        } else {
          const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Remove Payment Method',
            message: REMOVE_WITH_NO_FUTURE_PAYMENTS,
            confirmText: 'YES',
            cancelText: 'NO',
          });

          if (accepted) {
            await this.__removePaymentMethod(paymentMethod);
          }
        }
      },
      closeCaseOverlay: async () => {
        await this.__fetchCases();
      },
      /** From NEB-39231: hiding the sort order for now */
      // reorderPaymentMethods: (index1, index2) => {
      //   const temp = this.__paymentMethods[index1];
      //   this.__paymentMethods[index1] = this.__paymentMethods[index2];
      //   this.__paymentMethods[index2] = temp;
      // },
    };
  }

  _stateChanged({ session }) {
    this.__session = session.item || null;
  }

  async __fetchPaymentMethods() {
    if (this.__merchantAccounts.length) {
      const relationships = await getPatientRelationshipsActiveGroup(
        this.patient.id,
      );

      const paymentMethods = await getPaymentMethods(
        this.__merchantAccounts[0],
        this.patient.id,
        {
          fetchAll: true,
          deleted: false,
        },
        relationships.related.length > 0
          ? [relationships.primary, ...relationships.related]
          : null,
      );

      const paymentMethodsWithNames = await Promise.all(
        paymentMethods.map(async paymentMethod => {
          let patient;

          if (this.patient.id === paymentMethod.holderId) {
            patient = this.patient;
          } else {
            patient = await patientApiClient.fetchOne(paymentMethod.holderId);
          }

          const patientName = objToName(patient.name, DEFAULT_NAME_OPTS);

          return { ...paymentMethod, patientName };
        }),
      );

      this.__paymentMethods = paymentMethodsWithNames;
    } else this.__paymentMethods = [];
  }

  async __fetchFeeSchedules() {
    this.__feeSchedules = await feeScheduleApi.fetchMany(this.patient.id);
  }

  async __fetchCases() {
    const cases = await fetchCases(this.patient.id, true);

    this.__cases = cases.filter(c => c.active);
  }

  hasActiveMerchantAccounts() {
    return (
      this.__merchantAccounts &&
      this.__merchantAccounts.some(({ active }) => active)
    );
  }

  async __removeFeeSchedule(patientId, feeScheduleId) {
    try {
      await feeScheduleApi.remove(patientId, feeScheduleId);

      store.dispatch(openSuccess(REMOVE_SUCCESS_BANNER_FEE_SCHEDULE));
    } catch (e) {
      store.dispatch(openError(REMOVE_ERROR_BANNER_FEE_SCHEDULE));
    }
  }

  async __removePaymentMethod(paymentMethod) {
    try {
      await updatePaymentMethod({
        ...paymentMethod,
        deleted: true,
      });

      store.dispatch(openSuccess(REMOVE_SUCCESS_BANNER));
      await this.__fetchPaymentMethods();
    } catch (e) {
      store.dispatch(openError(REMOVE_ERROR_BANNER));
    }
  }

  __handleSuccessBanner(e) {
    if (e.value === BILL_TYPES.CARE_PACKAGE) {
      return store.dispatch(openSuccess(CARE_PACKAGE_WARNING));
    }

    if (e.name === 'billType') {
      return store.dispatch(openSuccess(BILL_TYPE_SUCCESS));
    }

    return store.dispatch(openSuccess(CASE_OVERRIDE_SUCCESS));
  }

  __handleErrorBanner(key) {
    return key === 'billType'
      ? store.dispatch(openError(BILL_TYPE_ERROR))
      : store.dispatch(openError(CASE_OVERRIDE_ERROR));
  }

  async __saveBillType(e) {
    const patient = { ...this.patient };
    patient[e.name] = e.value;

    try {
      const payload = await savePatient(patient);

      if (payload.error) throw Error(payload.error.message);

      this.onUpdatePatient();

      this.__handleSuccessBanner(e);
    } catch (err) {
      if (err.message.includes('SSN') || err.message.includes('dob')) {
        await savePatient(patient, { force: true });

        this.onUpdatePatient();

        this.__handleSuccessBanner(e);
      } else {
        this.__handleErrorBanner(e.name);
      }
    }
  }

  async __syncPatient() {
    const patientId = this.patient.id;

    const [insurances, packages, merchantAccounts] = await Promise.all([
      getPatientInsurances(patientId, {
        active: true,
      }),
      getPatientPackages(
        patientId,
        {
          includeShared: true,
          hideInactive: true,
        },
        true,
      ),
      getMerchantAccounts(),
      this.__fetchFeeSchedules(),
      this.__fetchCases(),
    ]);

    this.__insurances = insurances;
    this.__packages = packages;
    this.__merchantAccounts = merchantAccounts;

    await this.__fetchPaymentMethods();
  }

  async connectedCallback() {
    super.connectedCallback();

    const settings = await getPracticeSettings();
    this.__multiCarePackageEnabled = settings.multiCarePackage;
  }

  async updated(changedProps) {
    if (changedProps.has('patient')) {
      await this.__syncPatient();
    }
  }

  async __paymentConflictPopupConfig({ patients }) {
    const usedByRelatedPatient = checkCountPaymentMethodInSchedule(
      patients,
      this.patient.id,
    );

    const matchingPatients = usedByRelatedPatient
      ? await patientApiClient.fetchSome(Object.keys(patients))
      : [];

    const sortedPatients = sortPatients(matchingPatients);

    return usedByRelatedPatient
      ? {
          title: `${REMOVE_WITH_FUTURE_PAYMENTS.title} By Relationship`,
          message: html`
            This payment method is being used in a scheduled payment for these
            patient(s):
            <p>
              ${sortedPatients.map(
                v => html` <div>${objToName(v.name, NAME_OPTS)}</div> `,
              )}
            </p>
            <p>
              You must change the payment method for the scheduled payment(s) or
              stop the future scheduled payment(s) before removing this stored
              payment method.
            </p>
          `,
          confirmText: 'OK',
        }
      : REMOVE_WITH_FUTURE_PAYMENTS;
  }

  __renderDefaultPaymentType() {
    const defaultType = this.patient.billType;

    return html`
      <neb-header label="Defaults"></neb-header>

      <neb-text class="pad">Bill Type</neb-text>

      <div class="grid bill-type">
        <neb-radio-button
          id="${ELEMENTS.selfPayRadioButton.id}"
          class="radio-button"
          label="Self Pay"
          .value="${BILL_TYPES.SELF}"
          .onSelect="${this.handlers.changePaymentType}"
          ?checked="${defaultType === BILL_TYPES.SELF}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.insuranceRadioButton.id}"
          class="radio-button"
          label="Insurance"
          name=""
          .value="${BILL_TYPES.INSURANCE}"
          .disabled="${!this.__insurances.some(
            insurance => insurance.defaultLevel === 'Primary',
          )}"
          .onSelect="${this.handlers.changePaymentType}"
          ?checked="${defaultType === BILL_TYPES.INSURANCE}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.carePackageRadioButton.id}"
          class="radio-button"
          label="Care Package"
          .value="${BILL_TYPES.CARE_PACKAGE}"
          .disabled="${this.__packages.length < 1}"
          .onSelect="${this.handlers.changePaymentType}"
          ?checked="${defaultType === BILL_TYPES.CARE_PACKAGE}"
        ></neb-radio-button>

        <neb-switch
          id="${ELEMENTS.switchCaseBillTypeOverride.id}"
          class="case-bill-type-override"
          name="caseBillTypeOverride"
          label="Override Default Bill Type with Case Billing Details"
          .onChange="${this.handlers.changeCaseBillTypeOverride}"
          .on="${this.patient.caseBillTypeOverride}"
        ></neb-switch>
      </div>

      ${this.patient.caseBillTypeOverride
        ? html`
            <neb-table-patient-billing-cases
              id="${ELEMENTS.casesTable.id}"
              .cases="${this.__cases}"
              .patientId="${this.patient.id}"
              .onCloseCaseOverlay="${this.handlers.closeCaseOverlay}"
            ></neb-table-patient-billing-cases>
          `
        : ''}
    `;
  }

  __renderPaymentMethods() {
    const emptyMessage =
      'There are no payment methods. Click "Add Payment Method" to add a new payment method.';
    return html`
      <neb-header label="Payment Methods"></neb-header>

      <div class="grid grid-auto-left pad">
        <neb-button-action
          id="${ELEMENTS.addPaymentMethodButton.id}"
          label="Add Payment Method"
          .onClick="${this.handlers.addPaymentMethod}"
          ?disabled="${!this.hasActiveMerchantAccounts()}"
        ></neb-button-action>

        <neb-tooltip id="${ELEMENTS.tooltip.id}" defaultAnchor="right">
          <div id="${ELEMENTS.tooltipText.id}" slot="tooltip">
            ${TOOLTIP_TEXT}
          </div>
        </neb-tooltip>
      </div>

      <!-- From NEB-39231: hiding the sort order for now -->
      <!--
        <neb-table-payment-methods
          id="${ELEMENTS.paymentMethodsTable.id}"
          .layout="${this.layout}"
          .emptyMessage="${emptyMessage}"
          .model="${this.__paymentMethods}"
          .hasSharedCardFF="${this.__hasSharedCard}"
          .patientId="${this.patient.id}"
          .onRemove="${this.handlers.removePaymentMethod}"
          .onReorder="${this.handlers.reorderPaymentMethods}"
          ?reorder="${this.__paymentMethods.length}"
        ></neb-table-payment-methods>
      -->

      <neb-table-payment-methods
        id="${ELEMENTS.paymentMethodsTable.id}"
        .layout="${this.layout}"
        .emptyMessage="${emptyMessage}"
        .model="${this.__paymentMethods}"
        .patientId="${this.patient.id}"
        .onRemove="${this.handlers.removePaymentMethod}"
        .onEditDescription="${this.handlers.editPaymentMethodDescription}"
        .onToggleDefault="${this.handlers.toggleDefaultPaymentMethod}"
      ></neb-table-payment-methods>
    `;
  }

  __renderFeeSchedules() {
    const emptyMessage =
      'There are no associated fee schedules for this patient. Click "Associate Fee Schedule" to associate a new fee schedule.';

    return html`
      <neb-header label="Fee Schedules"></neb-header>

      <div class="grid grid-auto-left pad">
        <neb-button-action
          id="${ELEMENTS.associateFeeScheduleButton.id}"
          label="Associate Fee Schedule"
          .onClick="${this.handlers.associateFeeSchedule}"
        ></neb-button-action>
      </div>

      <neb-table
        id="${ELEMENTS.feeSchedulesTable.id}"
        .model="${this.__feeSchedules}"
        .emptyMessage="${emptyMessage}"
        .config="${FS_CONFIG}"
        .onRemove="${this.handlers.removeFeeSchedule}"
        ?showRemoveButton="${true}"
      ></neb-table>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderDefaultPaymentType()} ${this.__renderPaymentMethods()}
      ${this.__renderFeeSchedules()}
    `;
  }
}

customElements.define('neb-patient-general-page', NebPatientGeneralPage);
