import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  OVERLAY_WIDTH_LARGE,
  CSS_SPACING,
  layoutStyles,
} from '../../../styles';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-selection-card';

export const ELEMENTS = {
  header: { id: 'header' },
  cards: { selector: 'neb-selection-card' },
  selectCardCharges: { id: 'select-card-charges' },
  selectCardDiagnoses: { id: 'select-card-diagnoses' },
};

class OverlayAddHotButtons extends Overlay {
  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .grid {
          padding: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
        }

        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectType: async type => {
        const result = await openOverlay(OVERLAY_KEYS.HOT_BUTTONS, {
          type,
          providerId: this.model.item.providerId,
        });

        this.dismiss(result);
      },
    };
  }

  renderContent() {
    return html`
      <div class="grid">
        <div>
          <neb-popup-header
            id="${ELEMENTS.header.id}"
            title="Hot Button Type"
            .onCancel="${this.handlers.dismiss}"
            showCancelButton
          ></neb-popup-header>
        </div>

        <neb-selection-card
          id="${ELEMENTS.selectCardDiagnoses.id}"
          title="Diagnoses"
          name="diagnoses"
          .onClick="${this.handlers.selectType}"
        >
          <p>Create a Hot Buttons Category for diagnoses</p>
        </neb-selection-card>

        <neb-selection-card
          id="${ELEMENTS.selectCardCharges.id}"
          title="Charges"
          name="charges"
          .onClick="${this.handlers.selectType}"
        >
          <p>Create a Hot Buttons Category for charges</p>
        </neb-selection-card>
      </div>
    `;
  }
}

customElements.define('neb-overlay-add-hot-buttons', OverlayAddHotButtons);
