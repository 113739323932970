import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';

import { LitElement, html, css } from 'lit';

import {
  CARE_TYPES,
  CARE_TYPES_ACCIDENT,
} from '../../../../packages/neb-api-client/src/mappers/patient-case-mapper';
import { SORT_DIR } from '../../../../packages/neb-lit-components/src/components/neb-table-header';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { MODE } from '../../../../packages/neb-utils/table';
import { CSS_SPACING } from '../../../styles';

export const ELEMENTS = {
  table: {
    id: 'table',
  },
  pagination: {
    id: 'pagination',
  },
};

const CONFIG = [
  {
    truncate: true,
    sortable: true,
    key: 'name',
    label: 'Name',
    flex: css`1 0 0`,
    formatter: (name, patientCase) =>
      `${name} ${patientCase.billType ? `(${patientCase.billType})` : ''}`,
  },
  {
    sortable: true,
    key: 'careType',
    label: 'Type',
    flex: css`1 0 40px`,
    formatter: (careType, patientCase) =>
      `${CARE_TYPES.find(item => item.data.code === careType).label}${
        CARE_TYPES_ACCIDENT.some(x => x.data.code === careType)
          ? ` (${patientCase.accidentState})`
          : ''
      }`,
  },
  {
    sortable: true,
    key: 'onsetSymptomsDate',
    label: 'Date of Condition/Injury',
    flex: css`1 0 80px`,
    formatter: onsetSymptomsDate =>
      onsetSymptomsDate
        ? parseDate(onsetSymptomsDate).format('MM/DD/YYYY')
        : 'Gradual',
  },
  {
    sortable: true,
    key: 'isDefault',
    label: 'Default',
    flex: css`0 0 80px`,
    formatter: def => (def ? 'Default' : ''),
  },
];

const PAGE_SIZE = 5;

class NebTablePatientBillingCases extends LitElement {
  static get properties() {
    return {
      cases: Array,
      patientId: String,
      __currentPage: Number,
      __sortedCases: Array,
      __sortParams: Object,
    };
  }

  static get styles() {
    return css`
      .pagination {
        padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.cases = [];
    this.patientId = '';

    this.__currentPage = 0;
    this.__sortParams = {
      key: 'isDefault',
      dir: SORT_DIR.DESC,
    };

    this.__sortedCases = [];

    this.onCloseCaseOverlay = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickCase: async (_, patientCase) => {
        await openOverlay(OVERLAY_KEYS.CASE, {
          item: patientCase,
          context: {
            patientId: this.patientId,
            onAuthorizationChange: () => {},
          },
        });

        await this.onCloseCaseOverlay();
      },
      sortCases: (_, result) => {
        this.__sortParams = result[0];
        this.__sortCases();
      },
      changePage: page => {
        this.__currentPage = page;
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('cases') || changedProps.has('__currentPage')) {
      this.__sortCases();
    }
  }

  __sortCases() {
    this.__sortedCases = this.cases
      .sort((a, b) => {
        const { key, dir } = this.__sortParams;

        let aVal;
        let bVal;

        if (key === 'onsetSymptomsDate') {
          aVal = new Date(a[key]);
          bVal = new Date(b[key]);

          if (aVal.toString() === bVal.toString()) {
            return 0;
          }
        } else {
          aVal = a[key].toString().toUpperCase();
          bVal = b[key].toString().toUpperCase();
        }

        if (aVal < bVal) return dir === SORT_DIR.ASC ? -1 : 1;
        if (aVal > bVal) return dir === SORT_DIR.ASC ? 1 : -1;

        return 0;
      })
      .slice(
        this.__currentPage * PAGE_SIZE,
        this.__currentPage * PAGE_SIZE + PAGE_SIZE,
      );
  }

  __getPageCount() {
    return Math.ceil(this.cases.length / PAGE_SIZE);
  }

  render() {
    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        .config="${CONFIG}"
        .model="${this.__sortedCases}"
        .mode="${MODE.DETAIL}"
        .onSelectRow="${this.__handlers.clickCase}"
        .sortParams="${[this.__sortParams]}"
        .onSort="${this.__handlers.sortCases}"
      ></neb-table>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .pageCount="${this.__getPageCount()}"
        .currentPage="${this.__currentPage}"
        .onPageChanged="${this.__handlers.changePage}"
      ></neb-pagination>
    `;
  }
}

customElements.define(
  'neb-table-patient-billing-cases',
  NebTablePatientBillingCases,
);
