import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  confirmButton: {
    id: 'button-confirm',
  },
  cancelButton: {
    id: 'button-cancel',
  },
  removeButton: {
    id: 'button-remove',
  },
};

export class NebActionBar extends LitElement {
  static get properties() {
    return {
      confirmDisabled: Boolean,
      cancelLabel: String,
      confirmLabel: String,
      removeLabel: String,
      elevatedRemove: Boolean,
      doubleConfirm: Boolean,
      processing: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      hideBorderTop: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          margin: auto 0 0 0;
          height: 80px;
          white-space: nowrap;
        }

        .container {
          display: flex;
          align-items: center;
          height: 100%;
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .container {
          flex-direction: column;
          align-items: flex-start;
        }

        .button {
          margin-right: 10px;
        }

        :host([layout='small']) .button-secondary-container {
          margin-top: 0;
        }

        :host([layout='small']) .button-confirm + .button-secondary-container {
          margin-top: 10px;
        }

        .button-secondary-container {
          display: flex;
        }

        :host([hideBorderTop]) .container {
          border-top: none;
          padding: 0px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initHandlers();

    this.__initState();
  }

  __initState() {
    this.confirmDisabled = false;
    this.cancelLabel = 'Cancel';
    this.confirmLabel = 'Confirm';
    this.elevatedRemove = false;
    this.doubleConfirm = false;
    this.layout = '';
    this.processing = false;
    this.removeLabel = '';
    this.hideBorderTop = false;

    this.onConfirm = () => {};

    this.onCancel = () => {};

    this.onRemove = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onCancel(),
      remove: () => this.onRemove(),
      confirm: () => {
        this.blur();
        this.onConfirm();
      },
    };
  }

  __getCancelButtonRole() {
    return this.__showConfirmButton() && !this.doubleConfirm
      ? BUTTON_ROLE.OUTLINE
      : BUTTON_ROLE.CONFIRM;
  }

  __getRemoveButtonRole() {
    return this.__showConfirmButton() || this.elevatedRemove
      ? BUTTON_ROLE.OUTLINE
      : BUTTON_ROLE.CONFIRM;
  }

  __showConfirmButton() {
    return Boolean(this.confirmLabel.length);
  }

  __renderConfirmButton() {
    return this.confirmLabel
      ? html`
          <neb-button
            id="${ELEMENTS.confirmButton.id}"
            class="button button-confirm"
            ?disabled="${this.confirmDisabled}"
            .label="${this.processing ? 'Saving' : this.confirmLabel}"
            .processing="${this.processing}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
            unelevated
          ></neb-button>
        `
      : '';
  }

  __renderRemoveButton() {
    return this.removeLabel
      ? html`
          <neb-button
            id="${ELEMENTS.removeButton.id}"
            class="button"
            .label="${this.removeLabel}"
            .role="${this.__getRemoveButtonRole()}"
            .onClick="${this.__handlers.remove}"
          ></neb-button>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderConfirmButton()}

        <div class="button-secondary-container">
          <neb-button
            id="${ELEMENTS.cancelButton.id}"
            class="button"
            .label="${this.cancelLabel}"
            .role="${this.__getCancelButtonRole()}"
            .onClick="${this.__handlers.cancel}"
            ?disabled="${this.processing}"
          ></neb-button>

          ${this.__renderRemoveButton()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-action-bar', NebActionBar);
