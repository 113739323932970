import '../neb-toggle-button';
import '../../../../../src/components/misc/neb-icon';
import '../neb-text';

import { css, html } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

import NebTable from './neb-table';

export const ELEMENTS = {
  addButton: {
    selector: '[id^=add-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
};

const CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 300px`,
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`0 0 120px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 120px`,
    formatter: value => (value ? value.filter(m => m).join(', ') : ''),
  },
];

const MOBILE_CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 50px`,
    mobile: true,
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`1 1 110px`,
    mobile: true,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 1 110px`,
    mobile: true,
  },
  {
    key: 'modifiers',
    label: 'Mods',
    flex: css`0 0 40px`,
    mobile: true,
    formatter: value => (value ? value.filter(m => m).join('\n') : ''),
  },
];

class NebTablePatientProcedureSelect extends NebTable {
  static get properties() {
    return {
      selectedItems: Array,
      selectedSecondary: Array,
      coveredType: Boolean,
    };
  }

  initState() {
    super.initState();

    this.onAddCharge = () => {};
    this.selectedItems = [];
    this.selectedSecondary = [];
    this.coveredType = false;
  }

  updated(changedProps) {
    if (changedProps.has('layout')) {
      this.config = this.layout === 'small' ? MOBILE_CONFIG : CONFIG;
    }

    super.updated(changedProps);
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      add: (_, rowIndex) => this.onAddCharge(rowIndex),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button {
          padding-right: ${CSS_SPACING};
        }

        .add-button {
          width: 280px;
          padding: 0;
          white-space: nowrap;
        }

        :host([layout='small']) .add-button {
          width: 30px;
          padding: 0;
          white-space: nowrap;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'selected':
        return this.__renderButton(rowIndex, value);
      default:
        return value;
    }
  }

  __renderButton(rowIndex, _value) {
    const isAdded = !!this.selectedItems.find(
      e => e.id === this.model[rowIndex].id,
    );
    const isAddedSecondary = !!this.selectedSecondary.find(
      e => e.id === this.model[rowIndex].id,
    );

    const buttonAddMessage = this.coveredType
      ? 'Add to Covered Services'
      : 'Add to Non-Covered Services';

    const buttonSelectedMessage = this.coveredType
      ? 'Added to Covered Services'
      : 'Added to Non-Covered Services';

    const buttonSecondaryMessage = this.coveredType
      ? 'Added to Non-Covered Services'
      : 'Added to Covered Services';

    const displayedButtonSelected = isAddedSecondary
      ? buttonSecondaryMessage
      : buttonSelectedMessage;

    return html`
      <neb-toggle-button
        id="add-button-${rowIndex}"
        class="add-button"
        .index="${rowIndex}"
        .layout="${this.layout}"
        .isToggled="${isAdded || isAddedSecondary}"
        .onClick="${this.handlers.add}"
        .buttonText="${isAdded || isAddedSecondary
          ? displayedButtonSelected
          : buttonAddMessage}"
      ></neb-toggle-button>
    `;
  }
}

customElements.define(
  'neb-table-patient-procedure-select',
  NebTablePatientProcedureSelect,
);
