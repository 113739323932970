import './neb-charting-diagnosis-problem-table';

import { LitElement, html } from 'lit';

import {
  BUTTON_TEXT_ADD_DIAGNOSIS,
  BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS,
} from './neb-charting-diagnosis-icd10-controller';

export const ELEMENTS = {
  problemsTable: {
    id: 'table-problems',
  },
};

class NebChartingDiagnosisProblemListController extends LitElement {
  static get properties() {
    return {
      currentPageIndex: Number,
      pageCount: Number,
      savedCodes: Array,
      savedProblems: Array,
      searchText: String,
      visible: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.currentPageIndex = 0;
    this.pageCount = 0;
    this.savedCodes = [];
    this.savedProblems = [];
    this.searchText = '';
    this.visible = false;

    this.onAddDiagnosis = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addDiagnosis: icd10Info => this.onAddDiagnosis(icd10Info),
      pageChanged: pageIndex => {
        this.currentPageIndex = pageIndex;
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('searchText') || changedProps.get('searchText')) {
      this.currentPageIndex = 0;
    }
  }

  __filterProblemList() {
    const searchTerms = this.searchText.trim().split(' ');
    return this.savedProblems.filter(p =>
      searchTerms.every(
        text =>
          p.code.toLowerCase().includes(text.toLowerCase()) ||
          p.shortDescription.toLowerCase().includes(text.toLowerCase()),
      ),
    );
  }

  reset() {
    this.currentPageIndex = 0;
    this.pageCount = 0;
    this.savedCodes = [];
    this.savedProblems = [];
  }

  __renderTable() {
    return html`
      <neb-charting-diagnosis-problem-table
        id="${ELEMENTS.problemsTable.id}"
        .savedCodes="${this.savedCodes}"
        .savedProblems="${this.__filterProblemList()}"
        .onButtonClick="${this.__handlers.addDiagnosis}"
        .buttonText="${BUTTON_TEXT_ADD_DIAGNOSIS}"
        .buttonToggledText="${BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS}"
        .hasSearchText="${this.searchText !== ''}"
        .onPageChanged="${this.__handlers.pageChanged}"
        .currentPageIndex="${this.currentPageIndex}"
        .pageCount="${Math.ceil(this.__filterProblemList().length / 10)}"
      ></neb-charting-diagnosis-problem-table>
    `;
  }

  render() {
    return html` ${this.visible ? this.__renderTable() : ''} `;
  }
}

customElements.define(
  'neb-charting-diagnosis-problem-list-controller',
  NebChartingDiagnosisProblemListController,
);
