import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { ERA_ACTIONS } from '../../../utils/era-eob';
import {
  CLAIM_STATUS_UPDATE_ERROR,
  CLAIM_STATUS_UPDATE_SUCCESS,
  ERA_MATCH_LINE_ITEM_ERROR,
  ERA_MATCH_LINE_ITEM_SUCCESS,
  ERA_MERGE_LINE_ITEM_ERROR,
  ERA_MERGE_LINE_ITEM_SUCCESS,
  ERA_RESET_LINE_ITEM_ERROR,
  ERA_RESET_LINE_ITEM_SUCCESS,
  MANUAL_POST_LINE_ITEM_ERROR,
  MANUAL_POST_LINE_ITEM_SUCCESS,
  STATUS_UPDATED_TO_POSTED_ERROR_MESSAGE,
  STATUS_UPDATED_TO_POSTED_SUCCESS_MESSAGE,
  CLAIM_REVERSAL_SUCCESS,
  CLAIM_REVERSAL_ERROR,
} from '../../../utils/user-message';

import eraPosted from './era-posted';
import manualPostCharge from './manual-post-charge';
import markClaimAsDenied from './mark-claim-as-denied';
import matchCharges from './match-charges';
import mergeLineItems from './merge-line-items';
import postEra from './post-era';
import resetStatus from './reset-status';
import reverseClaimAndRemoveWarnings from './reverse-claim';

const MESSAGE_TYPE = Object.freeze({
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
});

const ACTION_MESSAGES = Object.freeze({
  [MESSAGE_TYPE.SUCCESS]: {
    [ERA_ACTIONS.POST_ERA]: STATUS_UPDATED_TO_POSTED_SUCCESS_MESSAGE,
    [ERA_ACTIONS.ERA_POSTED]: STATUS_UPDATED_TO_POSTED_SUCCESS_MESSAGE,
    [ERA_ACTIONS.MERGE_LINE_ITEMS]: ERA_MERGE_LINE_ITEM_SUCCESS,
    [ERA_ACTIONS.RESET_STATUS]: ERA_RESET_LINE_ITEM_SUCCESS,
    [ERA_ACTIONS.MATCH_CHARGE]: ERA_MATCH_LINE_ITEM_SUCCESS,
    [ERA_ACTIONS.MANUAL_POST]: MANUAL_POST_LINE_ITEM_SUCCESS,
    [ERA_ACTIONS.MARK_AS_DENIED]: CLAIM_STATUS_UPDATE_SUCCESS,
    [ERA_ACTIONS.REVERSE_CLAIM]: CLAIM_REVERSAL_SUCCESS,
  },
  [MESSAGE_TYPE.ERROR]: {
    [ERA_ACTIONS.POST_ERA]: STATUS_UPDATED_TO_POSTED_ERROR_MESSAGE,
    [ERA_ACTIONS.ERA_POSTED]: STATUS_UPDATED_TO_POSTED_ERROR_MESSAGE,
    [ERA_ACTIONS.MERGE_LINE_ITEMS]: ERA_MERGE_LINE_ITEM_ERROR,
    [ERA_ACTIONS.RESET_STATUS]: ERA_RESET_LINE_ITEM_ERROR,
    [ERA_ACTIONS.MATCH_CHARGE]: ERA_MATCH_LINE_ITEM_ERROR,
    [ERA_ACTIONS.MANUAL_POST]: MANUAL_POST_LINE_ITEM_ERROR,
    [ERA_ACTIONS.MARK_AS_DENIED]: CLAIM_STATUS_UPDATE_ERROR,
    [ERA_ACTIONS.REVERSE_CLAIM]: CLAIM_REVERSAL_ERROR,
  },
});

const UPDATE_ERA_ACTIONS = Object.freeze({
  [ERA_ACTIONS.ERA_POSTED]: data => eraPosted(data),
  [ERA_ACTIONS.MERGE_LINE_ITEMS]: data => mergeLineItems(data),
  [ERA_ACTIONS.POST_ERA]: data => postEra(data),
  [ERA_ACTIONS.RESET_STATUS]: data => resetStatus(data),
  [ERA_ACTIONS.MATCH_CHARGE]: data => matchCharges(data),
  [ERA_ACTIONS.MANUAL_POST]: data => manualPostCharge(data),
  [ERA_ACTIONS.MARK_AS_DENIED]: data => markClaimAsDenied(data),
  [ERA_ACTIONS.REVERSE_CLAIM]: data => reverseClaimAndRemoveWarnings(data),
});

export default async (action, data) => {
  try {
    const res = await UPDATE_ERA_ACTIONS[action](data);

    store.dispatch(openSuccess(ACTION_MESSAGES[MESSAGE_TYPE.SUCCESS][action]));
    return res;
  } catch (error) {
    console.error(error);
    store.dispatch(openError(ACTION_MESSAGES[MESSAGE_TYPE.ERROR][action]));

    return false;
  }
};
