import '../../../../../src/components/misc/neb-icon';
import '../neb-badge';
import '../neb-profile-photo';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import {
  SHORT_WEEKDAY_DATE_AND_TIME_FORMAT,
  SHORT_DATE_AND_SHORT_TIME_FORMAT,
} from '../../../../neb-input/nebFormatUtils';
import { baseStyles, summaryStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_5,
  CSS_BORDER_GREY_2,
  CSS_BANNER_ERROR_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  capitalize,
  objToName,
  formatAge,
  formatPhoneNumber,
  formatDollarAmount,
} from '../../../../neb-utils/formatters';
import { getPhotoModel } from '../../../../neb-utils/patient';
import * as patientUtil from '../../../../neb-utils/patient';
import { APPOINTMENT_TABLE_TYPES } from '../overlays/neb-overlay-appointment-history';

export const ELEMENTS = {
  pastAppointmentHistoryCount: {
    id: 'past-appointment-count',
  },
  futureAppointmentHistoryCount: {
    id: 'future-appointment-count',
  },
  badgeBell: {
    id: 'badge-bell',
  },
  containerAccount: {
    id: 'container-account',
  },
  containerNextAppointment: {
    id: 'container-next-appointment',
  },
  containerBalance: {
    id: 'container-balance',
  },
  containerContact: {
    id: 'container-contact',
  },
  containerEdit: {
    id: 'container-edit',
  },
  containerNotifications: {
    id: 'container-notifications',
  },
  containerPatientProfile: {
    id: 'container-patient-profile',
  },
  iconEdit: {
    id: 'icon-edit',
  },
  iconNotificationsBell: {
    id: 'icon-notifications-bell',
  },
  linkAddress: {
    id: 'link-address',
  },
  linkEditPatient: {
    id: 'link-edit-patient',
  },
  linkEmail: {
    id: 'link-email',
  },
  linkTreatmentPlan: {
    id: 'link-treatment-plan',
  },
  profilePhoto: {
    id: 'profile-photo',
  },
  textMRN: {
    id: 'text-MRN',
  },
  textAddress: {
    id: 'text-address',
  },
  textAge: {
    id: 'text-age',
  },
  textBalanceInsurance: {
    id: 'text-balance-insurance',
  },
  textBalancePatient: {
    id: 'text-balance-patient',
  },
  textBalanceTotal: {
    id: 'text-balance-total',
  },
  textBalanceTotalSpan: {
    id: 'text-balance-total-span',
  },
  textDefaultBillType: {
    id: 'text-default-bill-type',
  },
  textDefaultBillTypeSpan: {
    id: 'text-default-bill-type-span',
  },
  textDateOfBirth: {
    id: 'text-date-of-birth',
  },
  textEmailAddress: {
    id: 'text-email-address',
  },
  textSex: {
    id: 'text-sex',
  },
  textName: {
    id: 'text-name',
  },
  textPhone: {
    id: 'text-phone',
  },
  textPhoneType: {
    id: 'text-phone-type',
  },
  textShowMore: {
    id: 'text-show-more',
  },
  textStatus: {
    id: 'text-status',
  },
  textPreferredLocation: {
    id: 'text-preferred-location',
  },
  inactiveAlertHeader: {
    id: 'inactive-alert-notice',
  },
  plusIcon: {
    id: 'icon-plus',
  },
  nextAppointment: {
    id: 'appointment-next',
  },
  containerFinancialInfo: {
    id: 'container-financial-info',
  },
  textInsuranceCount: {
    id: 'text-insurance-count',
  },
  textInsuranceCountLink: {
    id: 'text-insurance-count-link',
  },
  textInsuranceVisitLimit: {
    id: 'text-insurance-visit-limit',
  },
  textInsuranceVisitLimitLink: {
    id: 'text-insurance-visit-limit-link',
  },
  loadingSpinner: {
    id: 'loading-spinner',
  },
};

const NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

class PatientSummary extends LitElement {
  static get properties() {
    return {
      __expanded: Boolean,
      model: Object,
      noLinks: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
      disableAlertsOverlayLink: Boolean,
      showTreatmentPlanLink: Boolean,
      loading: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      summaryStyles,
      css`
        :host {
          display: block;
        }

        :host([layout='small']) {
          width: 100%;
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([noLinks]) .no-link {
          text-decoration: none;
          pointer-events: none;
        }

        :host([layout='small']) .container {
          flex-flow: row nowrap;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .container-edit {
          width: fit-content;
        }

        .container-line {
          border-top: ${CSS_BORDER_GREY_2};
          padding-top: 10px;
          width: 100%;
        }

        :host([layout='small']) .spacer {
          white-space: normal;
        }

        .spacer {
          white-space: nowrap;
          margin-right: 0;
        }

        .spacer-name {
          margin-bottom: 5px;
        }

        .spacer-notifications {
          position: relative;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .photo {
          margin-bottom: 24px;
          margin-right: ${CSS_SPACING};
        }

        .row {
          display: flex;
          align-items: center;
        }

        .item:first-child {
          width: 85px;
        }

        .item-text {
          margin-bottom: ${CSS_SPACING};
          overflow-x: hidden;
        }

        .icon {
          margin-right: 4px;
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-open {
          margin-bottom: -2px;
          width: 14px;
          height: 14px;
        }

        .icon-bell {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_5};
        }

        .link {
          cursor: pointer;
        }

        .link-appointment {
          margin: 0;
        }

        .badge-bell-count {
          position: absolute;
          margin: -2px 0 0 15px;
        }

        .text-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .text-link {
          width: auto;
          max-width: 100px;
        }

        .text-link-big {
          max-width: 185px;
        }

        .field-mrn {
          display: flex;
        }

        .inactive-alert-notice {
          color: ${CSS_BANNER_ERROR_COLOR};
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          text-align: center;
          padding: 2px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-style: italic;
          margin: -20px -20px 20px -20px;
        }

        :host([layout='small']) .inactive-alert-notice {
          margin: 0;
        }

        .loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 200px;
          padding-left: 77px;
          padding-right: 77px;
        }

        .small-loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 47px;
          padding-bottom: 46px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__expanded = false;
    this.noLinks = false;
    this.disableAlertsOverlayLink = false;
    this.showTreatmentPlanLink = false;
    this.layout = '';
    this.model = patientUtil.createEmptyPatientSummaryModel();

    this.onAppointmentSelect = () => {};

    this.onEditPatient = () => {};

    this.onOpenAlerts = () => {};

    this.onOpenAppointmentHistory = () => {};

    this.onViewInsurance = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      appointmentClicked: () => {
        this.onAppointmentSelect(this.model.nextAppointment.id);
      },
      editClicked: () => this.onEditPatient(),
      openAlerts: () => {
        if (!this.disableAlertsOverlayLink) this.onOpenAlerts(this.model.id);
      },
      openPastAppointmentHistory: () => {
        this.__openAppointmentHistory(APPOINTMENT_TABLE_TYPES.PAST);
      },
      openFutureAppointmentHistory: () => {
        this.__openAppointmentHistory(APPOINTMENT_TABLE_TYPES.FUTURE);
      },
      onViewInsurance: () => this.onViewInsurance(),
      clickBalance: () => navigate(`/patients/${this.model.id}/ledger/balance`),
      goToActiveInsurances: () =>
        navigate(`/patients/${this.model.id}/insurance/active`),
      clickDefaultBillType: () =>
        navigate(`/patients/${this.model.id}/billing/general`),
      goToPatientDetails: () =>
        navigate(`/patients/${this.model.id}/overview/profile`),
      toggleExpand: () => {
        this.__expanded = !this.__expanded;
      },
      clickTreatmentPlan: () =>
        navigate(`/patients/${this.model.id}/clinical/treatment-plan`),
    };
  }

  __openAppointmentHistory(appointmentFilterOption) {
    this.onOpenAppointmentHistory(this.model.id, appointmentFilterOption);
  }

  __formatDate(date, isSmallScreen) {
    return isSmallScreen
      ? parseDate(date).format(SHORT_DATE_AND_SHORT_TIME_FORMAT)
      : parseDate(date).format(SHORT_WEEKDAY_DATE_AND_TIME_FORMAT);
  }

  __renderPhoto() {
    return html`
      <div class="spacer photo">
        <neb-profile-photo
          id="${ELEMENTS.profilePhoto.id}"
          class="profile-photo"
          .model="${getPhotoModel(this.model)}"
        >
        </neb-profile-photo>
      </div>
    `;
  }

  __renderName() {
    return html`
      <div class="spacer-name">
        <p
          id="${ELEMENTS.textName.id}"
          class="text text-bold"
          @click="${this.__handlers.goToPatientDetails}"
        >
          <a class="text-link text-highlight text-underline"
            >${objToName(this.model.name, NAME_OPTS)}</a
          >

          <neb-icon class="icon icon-open text-link" icon="neb:open" />
        </p>
      </div>
    `;
  }

  __renderBasicInfo() {
    return html`
      <div class="spacer spacer-small">
        <p id="${ELEMENTS.textSex.id}" class="text">
          ${capitalize(this.model.sex)}
        </p>
        <p id="${ELEMENTS.textAge.id}" class="text">
          ${formatAge(this.model.dateOfBirth)}
        </p>
        ${this.layout !== 'small' || this.__expanded
          ? html`
              <div id="${ELEMENTS.textMRN.id}" class="field-mrn">
                <span class="text text-overflow">
                  MRN: ${this.model.medicalRecordNumber}
                </span>
              </div>
              <p id="${ELEMENTS.textStatus.id}" class="text">
                ${capitalize(this.model.statuses.patient)}
              </p>
            `
          : ''}
      </div>
    `;
  }

  __renderNotifications() {
    return html`
      <div
        id="${ELEMENTS.containerNotifications.id}"
        class="spacer-notifications"
      >
        ${this.model.alertCount > 0
          ? html`
              <neb-badge
                id="${ELEMENTS.badgeBell.id}"
                class="badge-bell-count ${this.disableAlertsOverlayLink
                  ? 'no-link'
                  : 'link'}"
                .count="${this.model.alertCount}"
                @click="${this.__handlers.openAlerts}"
              ></neb-badge>
            `
          : ''}

        <neb-icon
          id="${ELEMENTS.iconNotificationsBell.id}"
          class="icon-bell ${this.disableAlertsOverlayLink
            ? 'no-link'
            : 'link'}"
          icon="neb:notification_important"
          @click="${this.__handlers.openAlerts}"
        ></neb-icon>
      </div>
    `;
  }

  __renderPhoneNumber() {
    return this.model.phoneNumbers.length > 0
      ? html`
          <div id="${ELEMENTS.containerContact.id}" class="spacer spacer-small">
            ${this.model.phoneNumbers.map(
              (phone, index) => html`
                <div>
                  <span id="${ELEMENTS.textPhoneType.id}-${index}" class="text"
                    >${phone.type}:</span
                  >

                  <a
                    id="${ELEMENTS.textPhone.id}-${index}"
                    class="text"
                    href="tel:+1${phone.number}"
                  >
                    <span
                      id="${ELEMENTS.textPhone.id}-${index}-class"
                      class="text text-link text-highlight text-underline"
                    >
                      ${formatPhoneNumber(phone.number)}
                    </span>
                  </a>
                </div>
              `,
            )}
          </div>
        `
      : '';
  }

  __renderEmailAddress() {
    return this.model.emailAddresses.length > 0
      ? this.model.emailAddresses.map(
          (emailAddress, index) => html`
            <a
              id="${ELEMENTS.linkEmail.id}-${index}"
              class="text text-overflow"
              href="mailto:${emailAddress}"
            >
              <p
                id="${ELEMENTS.textEmailAddress.id}-${index}"
                class="text text-link text-link-big text-highlight text-underline"
              >
                ${emailAddress}
              </p>
            </a>
          `,
        )
      : '';
  }

  __renderAddress() {
    const address = this.model.addresses[0];

    if (!address) {
      return '';
    }

    const lines = [];

    if (address.address1) {
      lines.push(address.address1);
    }

    if (address.address2) {
      lines.push(address.address2);
    }

    let tempLine = '';

    if (address.city !== '' && address.city != null) {
      tempLine += address.city;
    }

    if (address.state !== '' && address.state != null) {
      if (tempLine === '') {
        tempLine += address.state;
      } else {
        tempLine += `, ${address.state}`;
      }
    }

    if (address.zipcode !== '' && address.zipcode != null) {
      if (tempLine === '') {
        tempLine += address.zipcode;
      } else {
        tempLine += ` ${address.zipcode}`;
      }
    }

    lines.push(tempLine);

    return address
      ? html`
          <a
            id="${ELEMENTS.linkAddress.id}""
            target="_blank"
            href="https://maps.google.com/maps?q=${lines.join(',')}"
          >
            ${lines.map((line, index) => {
              if (line !== null && line !== '' && line !== ',') {
                return html`
                  <p
                    id="${ELEMENTS.textAddress.id}-${index}"
                    class="text text-link-big text-highlight text-underline"
                  >
                    ${line}
                  </p>
                `;
              }

              return html``;
            })}
          </a>
          <div class="spacer spacer-small"></div>
        `
      : '';
  }

  __renderPreferredLocation() {
    return this.model.preferredLocationName
      ? html`
          <div id="${ELEMENTS.textPreferredLocation.id}" class="text">
            Preferred Location: ${this.model.preferredLocationName}
          </div>
        `
      : '';
  }

  __renderAppointmentHistory() {
    return html`
      <div>
        <p class="text">
          Future Appointments:
          <span
            id="${ELEMENTS.futureAppointmentHistoryCount.id}"
            class="text text-link text-highlight text-underline"
            @click="${this.__handlers.openFutureAppointmentHistory}"
          >
            ${this.model.futureAppointmentsCount}
          </span>
        </p>
        <p class="text">
          Past Appointments:
          <span
            id="${ELEMENTS.pastAppointmentHistoryCount.id}"
            class="text text-link text-highlight text-underline"
            @click="${this.__handlers.openPastAppointmentHistory}"
          >
            ${this.model.pastAppointmentsCount}
          </span>
        </p>
      </div>
    `;
  }

  __renderNextAppointment(isSmallScreen) {
    return html`
      <div id="${ELEMENTS.containerNextAppointment.id}" class="spacer">
        <p class="text">
          Next Appointment:
          ${this.model.nextAppointment
            ? html`
                <div
                  id="${ELEMENTS.nextAppointment.id}"
                  class="text link text-link-big text-highlight text-underline"
                  @click="${this.__handlers.appointmentClicked}"
                >
                  ${this.__formatDate(
                    this.model.nextAppointment.start,
                    isSmallScreen,
                  )}
                </div>
              `
            : html`
                <span id="${ELEMENTS.nextAppointment.id}" class="no-link">
                  None
                </span>
              `}
        </p>
      </div>
    `;
  }

  __renderBalance() {
    return html`
      <div id="${ELEMENTS.containerBalance.id}" class="spacer container-line">
        <p id="${ELEMENTS.textBalancePatient.id}" class="text">
          Patient Balance: ${formatDollarAmount(this.model.patientBalance)}
        </p>
        <p id="${ELEMENTS.textBalanceInsurance.id}" class="text">
          Payer Balance: ${formatDollarAmount(this.model.insuranceBalance)}
        </p>
        <p id="${ELEMENTS.textBalanceTotal.id}" class="text">
          Total Balance:
          <a
            id="${ELEMENTS.textBalanceTotalSpan.id}"
            class="text-link text-highlight text-underline"
            @click="${this.__handlers.clickBalance}"
            >${formatDollarAmount(this.model.totalBalance)}</a
          >

          <neb-icon
            class="icon icon-open text-link"
            icon="neb:open"
            @click="${this.__handlers.clickBalance}"
          ></neb-icon>
        </p>
      </div>
    `;
  }

  __renderInsuranceVisitLimit() {
    return this.model.primaryInsurance &&
      this.model.primaryInsurance.patientInsuranceVisitLimit &&
      this.model.primaryInsurance.patientInsuranceVisitLimit.maxVisits !== '' &&
      this.model.primaryInsurance.patientInsuranceVisitLimit
        .displayTotalRemaining !== null
      ? html`
          <p id="${ELEMENTS.textInsuranceVisitLimit.id}" class="text">
            Primary Annual Visit Limit: <br />
            <a
              id="${ELEMENTS.textInsuranceVisitLimitLink.id}"
              class="text-link text-highlight text-underline"
              @click="${this.__handlers.onViewInsurance}"
              >${this.model.primaryInsurance.patientInsuranceVisitLimit
                .displayTotalRemaining}
              of
              ${this.model.primaryInsurance.patientInsuranceVisitLimit
                .maxVisits}
              remaining</a
            >
          </p>
        `
      : '';
  }

  __renderInsuranceData() {
    return html`
      <div
        id="${ELEMENTS.containerFinancialInfo.id}"
        class="spacer container-line"
      >
        <p id="${ELEMENTS.textDefaultBillType.id}" class="text">
          Default Bill Type:
          <a
            id="${ELEMENTS.textDefaultBillTypeSpan.id}"
            class="text-link text-highlight text-underline"
            @click="${this.__handlers.clickDefaultBillType}"
            >${this.model.caseBillTypeOverride
              ? 'Case'
              : this.model.billType}</a
          >

          <neb-icon
            class="icon icon-open text-link"
            icon="neb:open"
            @click="${this.__handlers.clickDefaultBillType}"
          ></neb-icon>
        </p>
        <p id="${ELEMENTS.textInsuranceCount.id}" class="text">
          Insurance:
          <a
            id="${ELEMENTS.textInsuranceCountLink.id}"
            class="text-link text-highlight text-underline"
            @click="${this.__handlers.goToActiveInsurances}"
            >${this.model.activeInsuranceCount} Active</a
          >
          <neb-icon
            class="icon icon-open text-link"
            icon="neb:open"
            @click="${this.__handlers.goToActiveInsurances}"
          ></neb-icon>
        </p>
        ${this.__renderInsuranceVisitLimit()}
      </div>
    `;
  }

  __renderTreatmentPlanLink() {
    if (!this.showTreatmentPlanLink) {
      return '';
    }

    return html`
      <p class="text">
        <a
          id="${ELEMENTS.linkTreatmentPlan.id}"
          class="text-link text-highlight text-underline"
          @click="${this.__handlers.clickTreatmentPlan}"
          >Go to Treatment Plan</a
        >

        <neb-icon
          class="icon icon-open text-link"
          icon="neb:open"
          @click="${this.__handlers.clickTreatmentPlan}"
        ></neb-icon>
      </p>
    `;
  }

  __renderEdit() {
    return !this.noLinks
      ? html`
          <div
            id="${ELEMENTS.containerEdit.id}"
            class="container-edit spacer float-right"
          >
            <neb-icon
              id="${ELEMENTS.iconEdit.id}"
              class="icon"
              icon="neb:edit"
            ></neb-icon>

            <span
              id="${ELEMENTS.linkEditPatient.id}"
              class="text text-link text-highlight"
              @click="${this.__handlers.editClicked}"
              >Edit</span
            >
          </div>
        `
      : '';
  }

  __renderSpacer() {
    return html` <div class="spacer spacer-small"></div> `;
  }

  __renderInactiveBanner() {
    return !this.model.active
      ? html`
          <div
            id="${ELEMENTS.inactiveAlertHeader.id}"
            class="inactive-alert-notice"
          >
            ${capitalize(this.model.statuses.patient)} Patient
          </div>
        `
      : html``;
  }

  __renderLarge() {
    if (this.loading) {
      return html`
        <div class="loading">
          <neb-loading-spinner
            id="${ELEMENTS.loadingSpinner.id}"
          ></neb-loading-spinner>
        </div>
      `;
    }

    return html`
      ${this.__renderInactiveBanner()}

      <div id="${ELEMENTS.containerPatientProfile.id}" class="container">
        <div style="display: flex">
          ${this.__renderPhoto()} ${this.__renderEdit()}
        </div>

        ${this.__renderName()} ${this.__renderBasicInfo()}
        ${this.__renderNotifications()} ${this.__renderPhoneNumber()}
        ${this.__renderEmailAddress()} ${this.__renderSpacer()}
        ${this.__renderAddress()} ${this.__renderPreferredLocation()}
        ${this.__renderAppointmentHistory()}${this.__renderNextAppointment(
          false,
        )}
        ${this.__renderBalance()} ${this.__renderInsuranceData()}
        ${this.__renderTreatmentPlanLink()}
      </div>
    `;
  }

  __renderSmall() {
    if (this.loading) {
      return html`
        <div class="small-loading">
          <neb-loading-spinner
            id="${ELEMENTS.loadingSpinner.id}"
          ></neb-loading-spinner>
        </div>
      `;
    }

    return html`
      ${this.__renderInactiveBanner()}
      <div
        id="${ELEMENTS.containerPatientProfile.id}"
        class="container"
        layout="${this.layout}"
      >
        <div class="item">${this.__renderPhoto()} ${this.__renderEdit()}</div>

        <div class="item item-text">
          ${this.__renderName()} ${this.__renderBasicInfo()}
          ${this.__expanded
            ? html`
                ${this.__renderNotifications()} ${this.__renderPhoneNumber()}
                ${this.__renderEmailAddress()}
                ${this.__renderAddress()}${this.__renderPreferredLocation()}
                ${this.__renderAppointmentHistory()}${this.__renderNextAppointment(
                  true,
                )}
                ${this.__renderBalance()} ${this.__renderInsuranceData()}
              `
            : ''}

          <span
            id="${ELEMENTS.textShowMore.id}"
            class="text text-link text-highlight"
            @click="${this.__handlers.toggleExpand}"
            >Show ${this.__expanded ? 'Less' : 'More'}</span
          >
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${this.layout === 'small' ? this.__renderSmall() : this.__renderLarge()}
    `;
  }
}

window.customElements.define('neb-patient-summary', PatientSummary);
