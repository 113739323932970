import '../../neb-lit-components/src/components/forms/neb-form-claim-action';
import { html, css } from 'lit';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  claimActionForm: {
    id: 'claim-action-form',
  },
};
class NebPopupClaimAction extends NebPopup {
  static get properties() {
    return {
      __items: Array,
    };
  }

  initState() {
    super.initState();

    this.title = 'Flag Claim';
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      save: model => this.onClose(model),
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 400px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-claim-action
        id="${ELEMENTS.claimActionForm.id}"
        .onSave="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
        .layout="${this.layout}"
      >
      </neb-form-claim-action>
    `;
  }
}

customElements.define('neb-popup-claim-action', NebPopupClaimAction);
