import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';

import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import '../../tables/era-eob/payments/neb-table-allocated-payments';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  centsToCurrency,
  currencyToCents,
  toCurrency,
} from '../../../../packages/neb-utils/formatters';

export const TITLE_TEXT = 'Reverse Claim';

export const SUBTITLE_TEXT =
  'Select a payment associated to this claim that will be reversed';

const BASE_WARNING = {
  title: 'Warning',
  confirmText: 'Yes',
  cancelText: 'No',
};

export const AMOUNT_EXCEEDS_REMIT_REVERSAL = {
  ...BASE_WARNING,
  message:
    'The amount being reversed exceeds the remit reversal amount. Do you wish to proceed?',
};

export const AMOUNT_IS_LESS_REMIT_REVERSAL = {
  ...BASE_WARNING,
  message:
    'The amount being reversed is less than the remit reversal amount. Would you like to add a remainder remit offset to account for this amount?',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  actionBar: { id: 'action-bar' },
  subText: { id: 'sub-text' },
  remitReversal: { id: 'remit-reversal' },
  totalReversal: { id: 'total-reversal' },
  table: { id: 'table' },
};

class NebPopupAllocatedPayments extends NebPopup {
  static get properties() {
    return {
      model: Object,
      __allocatedPayments: Array,
      __totalReversal: Number,
    };
  }

  initState() {
    super.initState();

    this.title = TITLE_TEXT;
    this.model = {
      allocatedPayments: [],
      issuedAmount: 0,
    };

    this.__allocatedPayments = [];
    this.__totalReversal = 0;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectItem: index => {
        this.__allocatedPayments = this.__allocatedPayments.map((p, i) => ({
          ...p,
          selected: i === index ? !p.selected : p.selected,
        }));

        const { selected, amount } = this.__allocatedPayments[index];

        this.__totalReversal += selected ? amount : -amount;
      },
      confirm: async () => {
        let remainderAmount = null;

        if (this.__totalReversal > currencyToCents(this.model.issuedAmount)) {
          const accepted = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            AMOUNT_EXCEEDS_REMIT_REVERSAL,
          );

          if (!accepted) return;
        }

        if (currencyToCents(this.model.issuedAmount) > this.__totalReversal) {
          const accepted = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            AMOUNT_IS_LESS_REMIT_REVERSAL,
          );

          if (!accepted) {
            this.handlers.close();
            return;
          }

          remainderAmount =
            currencyToCents(this.model.issuedAmount) - this.__totalReversal;
        }

        const paymentIds = this.__allocatedPayments
          .map(p => (p.selected ? p.id : null))
          .filter(Boolean);
        this.onClose({ accepted: true, paymentIds, remainderAmount });
      },
      close: () => {
        this.onClose({ accepted: false });
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content-container {
          padding: 0px;
        }

        .table {
          overflow-y: auto;
        }

        .container {
          padding: 0px;
        }

        .header {
          padding: ${CSS_SPACING};
          padding-bottom: 0px;
        }

        .content {
          margin-top: 0px;
        }

        .sub-text {
          padding-left: ${CSS_SPACING};
        }

        .remit-totals {
          display: flex;
          justify-content: space-around;
          padding: ${CSS_SPACING};
        }

        .remit-totals label {
          font-weight: bold;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__allocatedPayments = this.model.allocatedPayments.map(p => ({
        ...p,
        selected: false,
      }));
    }
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="sub-text" id="${ELEMENTS.subText.id}">
          <span>${SUBTITLE_TEXT}</span>
        </div>
        <div class="remit-totals">
          <div>
            <label>Remit reversal:</label>
            <span id="${ELEMENTS.remitReversal.id}"
              >${toCurrency(this.model.issuedAmount)}</span
            >
          </div>
          <div>
            <label>Total reversal:</label>
            <span id="${ELEMENTS.totalReversal.id}"
              >${centsToCurrency(this.__totalReversal)}</span
            >
          </div>
        </div>
        <div class="table">
          <neb-table-allocated-payments
            id="${ELEMENTS.table.id}"
            .model="${this.__allocatedPayments}"
            .onSelectItem="${this.handlers.selectItem}"
          ></neb-table-allocated-payments>
        </div>
      </div>
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="Save"
        cancelLabel="Cancel"
        .onCancel="${this.handlers.close}"
        .onConfirm="${this.handlers.confirm}"
        .confirmDisabled="${this.__selectedIndex === null}"
      ></neb-action-bar>
    `;
  }
}

customElements.define(
  'neb-popup-allocated-payments',
  NebPopupAllocatedPayments,
);
