import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import {
  CONFIRM_CREATE_REMIT_OFFSET,
  NO,
  ATTENTION,
  YES,
} from '../../../../utils/user-message';
import { ERA_DETAILS_TYPES } from '../utils';

export default async ({
  selectedNodeType,
  isERALevelAvailableToAllocate,
  lineItemReportId,
  claimReportId,
  unbalancedAmount,
  callback,
}) => {
  const isLineItemWithInsufficientPaymentBalance =
    selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL &&
    !isERALevelAvailableToAllocate;

  if (isLineItemWithInsufficientPaymentBalance) {
    const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: ATTENTION,
      message: CONFIRM_CREATE_REMIT_OFFSET,
      confirmText: YES,
      cancelText: NO,
    });

    if (!response) return null;
  }
  return callback({
    lineItemReportId,
    claimReportId,
    selectedNodeType,
    ...(isLineItemWithInsufficientPaymentBalance && { unbalancedAmount }),
  });
};
