import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { getVisitSummary } from '../../../api-clients/visit-summary';
import {
  CSS_SPACING,
  layoutStyles,
  OVERLAY_WIDTH_LARGE,
} from '../../../styles';
import FormVisitSummary from '../../forms/encounters/neb-form-visit-summary';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class OverlayVisitSummary extends Overlay {
  static get properties() {
    return {
      __formLoading: {
        type: Boolean,
      },
      __savingError: {
        type: Object,
      },
      __formModel: {
        type: Object,
      },
      layout: {
        reflect: true,
        type: String,
      },
      model: {
        type: Object,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .content {
          display: grid;
          width: ${OVERLAY_WIDTH_LARGE};
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }

        .header {
          padding: ${CSS_SPACING};
          padding-bottom: initial;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__formModel = FormVisitSummary.createModel();
    this.__savingError = null;
    this.__formLoading = true;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.VISIT_SUMMARY_PATIENT_DETAIL,
          model,
        );

        if (result) {
          this.__savingError = null;
          this.__formModel = model;
          this.isDirty = false;
          this.dismiss(result);
        } else {
          this.__savingError = { error: 'Not Saved' };
        }
      },
    };
  }

  __formatFormModel(data) {
    const model = {
      ...this.__formModel,
      ...this.model,
      ...data,
      encounters: [
        {
          ...data.encounters[0],
          appointmentDetails: {
            ...data.encounters[0].appointmentDetails,
            locationName:
              data.encounters[0].location &&
              data.encounters[0].location.businessName
                ? data.encounters[0].location.businessName
                : data.encounters[0].appointmentDetails.locationName,
          },
          displayedBalanceForward: data.encounters[0].balanceForward,
          displayedTotalBalance:
            data.encounters[0].totalLineItemBalance +
            data.encounters[0].balanceForward,
        },
      ],
      feesAndPurchases: {
        ...data.feesAndPurchases,
        totals: [
          {
            ...data.feesAndPurchases.totals[0],
            displayedTotalBalance:
              data.feesAndPurchases.totals[0].totalLineItemBalance +
              data.feesAndPurchases.totals[0].balanceForward,
            displayedBalanceForward:
              data.feesAndPurchases.totals[0].balanceForward,
          },
        ],
      },
    };

    return model;
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.model.encounterId) {
      const { data } = await getVisitSummary({
        patientId: this.model.patientId,
        body: { encounterIds: [this.model.encounterId] },
      });

      this.__formModel = this.__formatFormModel(data);
    }

    this.__formLoading = false;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Visit Summary"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-visit-summary
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .layout="${this.layout}"
        .loading="${this.__formLoading}"
        .savingError="${this.__savingError}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-form-visit-summary>
    `;
  }
}

customElements.define('neb-overlay-visit-summary', OverlayVisitSummary);
