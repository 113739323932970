import '../../../../packages/neb-lit-components/src/components/charting/listings/neb-listings-summary';

import { html, css } from 'lit';

import * as listingsUtil from '../../../../packages/neb-utils/listings';
import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  view: { id: 'view' },
};

class NebUnsignedEncounterListingsSummary extends NebUnsignedEncounterSummaryPanel {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 1px 0px;
        }
      `,
    ];
  }

  createModel() {
    return listingsUtil.createModel();
  }

  getTitle() {
    return 'Listings';
  }

  renderContent() {
    return html`
      <neb-listings-summary
        id="${ELEMENTS.view.id}"
        .model="${this.model}"
      ></neb-listings-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-listings-summary',
  NebUnsignedEncounterListingsSummary,
);
