import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import '../../grids/neb-grid-buttons';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color-advanced';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_GREY_2 } from '../../../../packages/neb-styles/neb-variables';
import { SELECT_CHARGES_OVERLAY_TYPE } from '../../../../packages/neb-utils/enums';
import { swapItems } from '../../../../packages/neb-utils/utils';
import { CSS_COLOR_BLUE, getHexColorFromCSS } from '../../../styles';
import { HOT_BUTTON_CATEGORY_TYPE } from '../../../utils/hot-buttons';
import { getModifiersFromChargeItem } from '../../../utils/neb-charges-util';
import {
  HOT_BUTTON_ADD_CHARGES_DESCRIPTION,
  HOT_BUTTON_ADD_CHARGES_TITLE,
  HOT_BUTTON_ADD_DX_TITLE,
} from '../../../utils/user-message';

const MAX_SELECTION_LIMIT = 25;

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  switchActive: { id: 'switch-active' },
  buttonAddItems: { id: 'button-add-items' },
  buttonDelete: { id: 'button-delete' },
  textfieldItemName: { id: 'textfield-item-name' },
  textItemInformation: { id: 'text-item-information' },
  grid: { id: 'grid' },
  textfieldCategoryName: { id: 'textfield-category-name' },
  pickerColor: { id: 'picker-color' },
  pickerColorAdvanced: { id: 'picker-color-advanced' },
};

const buttonIsRequired = () => ({
  error: 'Required',
  validate: v => v.some(item => item),
});

const MAX_NUMBER_OF_BUTTONS = 25;

export class FormHotButtons extends NebForm {
  static get properties() {
    return {
      ...super.properties,
      __selectedIndex: Number,
      __selectedColor: String,
    };
  }

  static createModel() {
    return {
      name: null,
      active: true,
      buttons: [...Array(MAX_NUMBER_OF_BUTTONS).fill(null)],
    };
  }

  createSelectors() {
    return {
      children: {
        buttons: {
          unsafe: true,
          clipPristine: true,
          validators: [buttonIsRequired()],
        },
        name: [isRequired()],
      },
    };
  }

  initState() {
    super.initState();

    this.__selectedIndex = -1;
    this.__selectedColor = getHexColorFromCSS(CSS_COLOR_BLUE);
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      addItems: async () => {
        this.__resetPosition();
        await this.__addItems(MAX_SELECTION_LIMIT, MAX_SELECTION_LIMIT);
      },

      deleteItem: () => {
        const buttons = [...this.state.buttons];
        buttons[this.__selectedIndex] = null;

        this.formService.apply('buttons', buttons);

        this.__resetPosition();
      },

      selectItem: async (selectedButton, index) => {
        this.__selectedIndex = index;

        if (!selectedButton) {
          await this.__addItems(MAX_SELECTION_LIMIT, 1);
        } else {
          this.__selectedColor = selectedButton.color;
        }
      },

      changeItemName: ({ value }) => {
        const buttons = [...this.state.buttons];

        const item = {
          ...buttons[this.__selectedIndex],
          name: value,
        };

        buttons[this.__selectedIndex] = item;
        this.formService.apply('buttons', buttons);
      },

      changeColor: ({ value }) => {
        this.__selectedColor = value;
        const buttons = [...this.state.buttons];

        if (buttons[this.__selectedIndex]) {
          const item = {
            ...buttons[this.__selectedIndex],
            color: value,
          };

          buttons[this.__selectedIndex] = item;
          this.formService.apply('buttons', buttons);
        }
      },

      reorder: (fromIndex, toIndex) => {
        const buttons = [...this.state.buttons];

        if (buttons[fromIndex]) {
          const reorderedButtons = swapItems(buttons, fromIndex, toIndex);

          [fromIndex, toIndex].forEach(idx => {
            if (reorderedButtons[idx]) {
              reorderedButtons[idx] = {
                ...reorderedButtons[idx],
                content: {
                  ...reorderedButtons[idx].content,
                  position: idx,
                },
              };
            }
          });

          this.formService.apply('buttons', reorderedButtons);
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .border-bottom {
          padding-bottom: 30px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .text-box-width {
          width: 315px;
        }

        .button {
          width: 200px;
        }

        .active-toggle {
          margin-top: 10px;
        }
      `,
    ];
  }

  __resetPosition() {
    this.__selectedIndex = -1;
  }

  __isValidIndex(index) {
    return index > -1 && index < 25;
  }

  __getExistingItems() {
    return this.state.buttons
      .filter(button => button)
      .map(item => ({ ...item.content }));
  }

  __getPosition() {
    return this.__isValidIndex(this.__selectedIndex)
      ? this.__selectedIndex
      : this.state.buttons.indexOf(null);
  }

  __getDisplayedItemInformation(button) {
    let modifiers;

    const { content } = button;

    if (this.model.type === HOT_BUTTON_CATEGORY_TYPE.CHARGES) {
      modifiers = getModifiersFromChargeItem({ charge: content });
    }

    const message = [
      content.diagnosisCode || content.procedure,
      modifiers && modifiers.length ? modifiers : null,
      content.shortDescription || content.description,
    ]
      .filter(info => info)
      .join(' - ');

    return {
      alias: button.name,
      message,
    };
  }

  async __addItems(
    totalSelectionLimit = MAX_SELECTION_LIMIT,
    currentSelectionLimit = MAX_SELECTION_LIMIT,
  ) {
    const existingItems = this.__getExistingItems();

    const overlayKey =
      this.model.type === HOT_BUTTON_CATEGORY_TYPE.CHARGES
        ? OVERLAY_KEYS.SELECT_CHARGES
        : OVERLAY_KEYS.ADD_DIAGNOSIS;

    const overlayModel =
      this.model.type === HOT_BUTTON_CATEGORY_TYPE.CHARGES
        ? {
            charges: existingItems.map(charge => ({
              chargeId: charge.chargeId,
              amount: charge.amount,
              description: charge.description,
              procedure: charge.procedure,
              modifiers: charge.modifiers,
              active: true,
            })),
            type: SELECT_CHARGES_OVERLAY_TYPE.SHOW_TYPE,
            title: HOT_BUTTON_ADD_CHARGES_TITLE,
            description: HOT_BUTTON_ADD_CHARGES_DESCRIPTION,
            labelProcedure: 'Code',
            hideAddAll: true,
          }
        : {
            selectedDiagnosis: existingItems.map(dx => ({
              diagnosisCode: dx.diagnosisCode,
              shortDescription: dx.shortDescription,
            })),
            description: HOT_BUTTON_ADD_DX_TITLE,
          };

    let selectedItems = await openOverlay(overlayKey, {
      ...overlayModel,
      totalSelectionLimit,
      currentSelectionLimit,
    });

    if (existingItems.length) {
      const key = this.model.type === 'charges' ? 'chargeId' : 'diagnosisCode';
      const existingItemIds = existingItems.map(
        existingItem => existingItem[key],
      );

      selectedItems = selectedItems.filter(
        item => !existingItemIds.includes(item[key]),
      );
    }

    if (selectedItems.length) {
      selectedItems.forEach(item => {
        const position = this.__getPosition();

        if (this.__isValidIndex(position)) {
          const buttons = [...this.state.buttons];
          const button = {
            name: item.procedure || item.diagnosisCode,
            color: this.__selectedColor,
            content: { position, ...item },
          };

          buttons[position] = button;

          this.formService.apply('buttons', buttons);
        }
      });
    }
  }

  __renderActive() {
    return html`
      <neb-switch
        id="${ELEMENTS.switchActive.id}"
        class="active-toggle"
        name="active"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }

  __renderColorConfigurationArea() {
    return html`
      <div class="grid grid-auto-left">
        <neb-picker-color
          id="${ELEMENTS.pickerColor.id}"
          class="field"
          name="color"
          .value="${this.__selectedColor}"
          .onChange="${this.handlers.changeColor}"
        ></neb-picker-color>

        <neb-picker-color-advanced
          id="${ELEMENTS.pickerColorAdvanced.id}"
          class="field field-picker-color"
          name="color"
          .value="${this.__selectedColor}"
          .onChange="${this.handlers.changeColor}"
        ></neb-picker-color-advanced>
      </div>
    `;
  }

  __renderSetName() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldCategoryName.id}"
        class="text-box-width"
        label="Set Category Name"
        helper="Required"
        name="name"
        maxLength="100"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderGrid() {
    return html`
      <neb-grid-buttons
        id="${ELEMENTS.grid.id}"
        reorder="true"
        .model="${this.state.buttons}"
        .selectedIndex="${this.__selectedIndex}"
        .onSelectItem="${this.handlers.selectItem}"
        .onReorder="${this.handlers.reorder}"
      ></neb-grid-buttons>
    `;
  }

  __renderConfigurationArea() {
    const buttonItems = [...this.state.buttons];
    const selectedButton = buttonItems[this.__selectedIndex];

    if (selectedButton) {
      const displayedItemInformation =
        this.__getDisplayedItemInformation(selectedButton);

      return html`
        <div class="grid grid-auto-left">
          <neb-textfield
            id="${ELEMENTS.textfieldItemName.id}"
            class="text-box-width"
            label="Button Name"
            maxLength="100"
            helper=" "
            .value="${displayedItemInformation.alias}"
            .onChange="${this.handlers.changeItemName}"
          ></neb-textfield>

          <neb-button
            id="${ELEMENTS.buttonDelete.id}"
            class="button"
            label="Delete Button"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.deleteItem}"
          ></neb-button>
        </div>

        <div class="grid grid-auto-left">
          <neb-text id="${ELEMENTS.textItemInformation.id}" bold>
            ${displayedItemInformation.message}
          </neb-text>
        </div>
      `;
    }
    return html``;
  }

  __renderAddItems() {
    const addItemLabel = `Add ${this.model.type}`;

    return html`
      <div class="row row-margins">
        <neb-button-action
          id="${ELEMENTS.buttonAddItems.id}"
          class="cell"
          label="${addItemLabel}"
          .onClick="${this.handlers.addItems}"
        ></neb-button-action>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-auto-left pad">
        ${this.__renderSetName()}
        ${this.__renderAddItems()}${this.__renderActive()}
      </div>

      <div class="grid grid-lean border-bottom">${this.__renderGrid()}</div>
      <div class="grid">${this.__renderColorConfigurationArea()}</div>
      <div class="grid">${this.__renderConfigurationArea()}</div>
    `;
  }
}

customElements.define('neb-form-hot-buttons', FormHotButtons);
