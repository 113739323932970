import { html, LitElement, css } from 'lit';

import { CSS_COLOR_BLUE_BORDER } from '../../../neb-styles/neb-variables';

class NebLoadingSpinner extends LitElement {
  static get styles() {
    return css`
      :host {
        width: 50px;
        color: ${CSS_COLOR_BLUE_BORDER};
      }
      .circular {
        animation: 2s linear infinite svg-animation;
        height: 100%;
        position: relative;
        width: 100%;
      }

      .path {
        animation: 1.4s ease-in-out infinite both circle-animation;
        display: block;
        fill: transparent;
        stroke: currentColor;
        stroke-dasharray: 303;
        stroke-dashoffset: 280;
        stroke-width: 10px;
        transform-origin: 50% 50%;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes svg-animation {
        0% {
          transform: rotateZ(0deg);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }

      @keyframes circle-animation {
        0%,
        25% {
          stroke-dashoffset: 280;
          transform: rotate(0);
        }

        50%,
        75% {
          stroke-dashoffset: 75;
          transform: rotate(45deg);
        }

        100% {
          stroke-dashoffset: 280;
          transform: rotate(360deg);
        }
      }
    `;
  }

  render() {
    return html`
      <svg class="circular" viewBox="0 0 100 100">
        <circle class="path" cx="50" cy="50" r="45" />
      </svg>
    `;
  }
}

window.customElements.define('neb-loading-spinner', NebLoadingSpinner);
