import { openPopup } from '@neb/popup';

import { getBillingInfo } from '../../../../../neb-api-client/src/billing-information-api-client';
import { getPracticeUser } from '../../../../../neb-api-client/src/permissions-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import {
  FEDERAL_TAX_ID_SOURCE,
  FEDERAL_TAX_ID_TYPE,
} from '../../../../../neb-utils/enums';

const formatFederalTaxIdLabel = source =>
  source === FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN ? 'Provider Other ID' : source;

const getFederalTaxIdOptions = ({
  billingState: { federalTaxIdSource, federalTaxIdNumber, federalTaxIdType },
  practiceTaxId,
  billingProvider: {
    identifiers,
    taxId: providerTaxId,
    taxIdType: providerTaxIdType,
  },
}) => {
  const uniqueOptions = new Set();

  const taxIdOptions = [];

  if (practiceTaxId) {
    const practiceTaxIdLabel = `Practice Tax ID - ${practiceTaxId} (${
      FEDERAL_TAX_ID_TYPE.EIN
    })`;
    uniqueOptions.add(practiceTaxIdLabel);

    taxIdOptions.push({
      label: practiceTaxIdLabel,
      value: {
        federalTaxIdNumber: practiceTaxId,
        federalTaxIdType: FEDERAL_TAX_ID_TYPE.EIN,
        federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PRACTICE_TAX_ID,
      },
    });
  }

  if (providerTaxId) {
    const providerTaxIdLabel = `Provider Tax ID - ${providerTaxId} (${providerTaxIdType})`;
    uniqueOptions.add(providerTaxIdLabel);

    taxIdOptions.push({
      label: providerTaxIdLabel,
      value: {
        federalTaxIdNumber: providerTaxId,
        federalTaxIdType: providerTaxIdType,
        federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_TAX_ID,
      },
    });
  }

  const formattedSocialSecurityNumbers = identifiers.reduce(
    (acc, { number, type: { value } }) => {
      const label = `Provider Other ID - ${number} (${
        FEDERAL_TAX_ID_TYPE.SSN
      })`;

      if (value !== 'SY' || uniqueOptions.has(label)) {
        return acc;
      }

      uniqueOptions.add(label);

      return [
        ...acc,
        {
          label,
          value: {
            federalTaxIdNumber: number,
            federalTaxIdType: FEDERAL_TAX_ID_TYPE.SSN,
            federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN,
          },
        },
      ];
    },
    [],
  );

  const activeSources = [
    FEDERAL_TAX_ID_SOURCE.OTHER,
    FEDERAL_TAX_ID_SOURCE.LOCATION_TAX_ID,
  ];

  const previousOption =
    federalTaxIdSource && !activeSources.includes(federalTaxIdSource)
      ? {
          label: `${formatFederalTaxIdLabel(
            federalTaxIdSource,
          )} - ${federalTaxIdNumber} (${federalTaxIdType})`,
          value: {
            federalTaxIdNumber,
            federalTaxIdType,
            federalTaxIdSource,
          },
        }
      : null;

  if (previousOption && !uniqueOptions.has(previousOption.label)) {
    if (previousOption.label.includes('Tax ID')) {
      taxIdOptions.push(previousOption);
    } else {
      formattedSocialSecurityNumbers.push(previousOption);
    }
  }

  return [
    ...taxIdOptions.sort((a, b) => a.label.localeCompare(b.label)),
    ...formattedSocialSecurityNumbers,
  ];
};

export default async ({ claimCellData, state, handlers, formService }) => {
  const [{ taxId: practiceTaxId }, billingProvider] = await Promise.all([
    getBillingInfo(),
    getPracticeUser(state.billingProviderId),
  ]);
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_FEDERAL_TAX_ID, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    federalTaxIdNumber: state.federalTaxIdNumber,
    federalTaxIdType: state.federalTaxIdType,
    federalTaxIdSource: state.federalTaxIdSource,
    options: await getFederalTaxIdOptions({
      billingState: state,
      practiceTaxId,
      billingProvider,
    }),
    practiceTaxId,
    locations: state.locations,
  });

  if (result) {
    Object.entries(result.federalTaxId).forEach(([name, value]) => {
      formService.apply(`claimEditedField.${name}`, true);

      handlers.change({
        name,
        value,
      });
    });
  }
};
