/* istanbul ignore file */
import { css, LitElement } from 'lit';

import { connect, store } from '../../../packages/neb-redux/neb-redux-store';
import { initialize, identifyUser } from '../../library/userpilot';

const APPS = {
  PRACTICE: 'practice',
  SETTINGS: 'settings',
};

const STANDARD_ACCESSORS = {
  getPracticeInfo: ({
    item: { id: tenantId, name: practiceName, salesforceId, tier, addOns },
  }) => ({ practiceName, salesforceId, tenantId, tier, addOns }),
  getPracticeInformationFromState: ({ practiceInformation }) =>
    practiceInformation,
  getSessionInfo: session => {
    const {
      item: {
        firstName,
        idToken: {
          payload: { email },
        },
        lastName,
        tenantId,
      },
    } = session;

    const {
      item: {
        access: {
          [tenantId]: { r: role },
        },
      },
    } = session;

    return {
      email,
      firstName,
      lastName,
      role,
    };
  },
  isPracticeInformationReady: ({ item }) => Boolean(item),
};

const ACCESSORS = {
  [APPS.PRACTICE]: STANDARD_ACCESSORS,
  [APPS.SETTINGS]: STANDARD_ACCESSORS,
};

class NebUserpilot extends connect(store)(LitElement) {
  static get properties() {
    return {
      name: { reflect: true, type: String },
      initialized: Boolean,
      practiceInformation: Object,
      session: Object,
    };
  }

  static get styles() {
    return css`
      :host {
        display: none;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.name = '';
    this.initialized = false;
    this.session = null;
  }

  _stateChanged(state) {
    const {
      [this.name]: { getPracticeInformationFromState },
    } = ACCESSORS;

    const { session } = state;

    this.practiceInformation = getPracticeInformationFromState(state);
    this.session = session;
  }

  connectedCallback() {
    super.connectedCallback();

    initialize();
  }

  shouldUpdate() {
    if (this.initialized) {
      return false;
    }

    const { isAuthenticated } = this.session;

    if (!isAuthenticated) {
      return false;
    }

    const {
      [this.name]: { isPracticeInformationReady },
    } = ACCESSORS;

    if (!isPracticeInformationReady(this.practiceInformation)) {
      return false;
    }

    return true;
  }

  update() {
    const {
      [this.name]: { getPracticeInfo, getSessionInfo },
    } = ACCESSORS;

    const { email, firstName, lastName, role } = getSessionInfo(this.session);

    const { practiceName, salesforceId, tenantId, tier, addOns } =
      getPracticeInfo(this.practiceInformation);

    this.initialized = true;

    identifyUser({
      email,
      firstName,
      lastName,
      practiceName,
      role,
      salesforceId,
      tenantId,
      app: this.name,
      ...(tier && addOns && { tier, addOns }),
    });
  }
}

customElements.define('neb-userpilot', NebUserpilot);

export { APPS };
