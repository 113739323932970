import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../packages/neb-styles/neb-variables';
import {
  CODE_QUALIFIER,
  CODE_QUALIFIERS,
  REPORTS_TRANSMISSION_CODE,
  REPORTS_TYPE_CODE,
} from '../../../../packages/neb-utils/claims';
import * as selectors from '../../../../packages/neb-utils/selectors';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  sendButton: { id: 'send-button' },
  cancelButton: { id: 'cancel-button' },
  reportTypeCodeDropdown: { id: 'report-type-code-dropdown' },
  reportTransmissionCodeDropdown: { id: 'report-transmission-code-dropdown' },
  codeQualifierDropdown: { id: 'code-qualifier-dropdown' },
  identificationNumberTextfield: { id: 'identification-number-textfield' },
};

export const ITEM_EMPTY = { label: '', data: { id: null } };

export class NebFormSupplementalInformation extends NebForm {
  static get properties() {
    return {
      ...super.properties,
      __disableIdentificationNumber: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();
    this.__disableIdentificationNumber = false;

    this.validation = {
      errors: {
        reportTypeCode: '',
        reportTransmissionCode: '',
        codeQualifier: '',
        identificationNumber: '',
      },
    };
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.model.reportTransmissionCode.data.id === 'AA') {
      this.__disableIdentificationNumber = true;
    }
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.formService.apply(name, value || null);
        this.__validate();
      },
      confirm: () => {
        if (this.__validate()) this.handlers.save();
      },
    };
  }

  static createModel() {
    return {
      reportTypeCode: ITEM_EMPTY,
      reportTransmissionCode: ITEM_EMPTY,
      codeQualifier: ITEM_EMPTY,
      identificationNumber: null,
    };
  }

  createSelectors() {
    return {
      children: {
        reportTypeCode: selectors.select(
          REPORTS_TYPE_CODE,
          this.model.reportTypeCode,
        ),
        reportTransmissionCode: selectors.select(
          REPORTS_TRANSMISSION_CODE,
          this.model.reportTransmissionCode,
        ),
        codeQualifier: selectors.select(
          CODE_QUALIFIERS,
          this.model.codeQualifier,
        ),
      },
    };
  }

  __setRequiredError(name) {
    this.validation.errors = { ...this.validation.errors, [name]: 'Required' };
  }

  __cleanRequiredError(name) {
    this.validation.errors = { ...this.validation.errors, [name]: '' };
  }

  __cleanAllErrors() {
    this.validation.errors = {
      ...this.validation.errors,
      reportTypeCode: '',
      reportTransmissionCode: '',
      codeQualifier: '',
      identificationNumber: '',
    };
  }

  __isReportTransmissionCodeDifferentThanAA() {
    return (
      this.state.reportTransmissionCode.data.id &&
      this.state.reportTransmissionCode.data.id !== 'AA'
    );
  }

  __require(fieldName) {
    const field = this.state[fieldName];
    const requireCondition =
      typeof field === 'object' && field !== null ? !field.label : !field;

    return requireCondition
      ? this.__setRequiredError(fieldName)
      : this.__cleanRequiredError(fieldName);
  }

  __validate() {
    const stateFieldNames = Object.keys(this.state);

    stateFieldNames.forEach(name => {
      this.__require('reportTypeCode');
      this.__require('reportTransmissionCode');

      if (this.__isReportTransmissionCodeDifferentThanAA()) {
        this.formService.apply('codeQualifier', {
          label: `AC - ${CODE_QUALIFIER.AC}`,
          data: {
            id: 'AC',
          },
        });

        this.__disableIdentificationNumber = false;

        return this.__require('identificationNumber');
      }
      this.__disableIdentificationNumber = true;

      this.formService.apply('codeQualifier', {
        label: '',
        data: {
          id: '',
        },
      });

      this.formService.apply('identificationNumber', '');

      return this.__cleanRequiredError(name);
    });

    if (
      !this.state.reportTypeCode.label &&
      !this.state.reportTransmissionCode.label
    ) {
      this.__cleanAllErrors();
    }

    return Object.values(this.validation.errors).every(error => !error);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          min-width: 600px;
        }

        .content {
          max-height: 500px;
          display: block;
          overflow-y: auto;
        }

        .grid {
          display: grid;
          margin: ${CSS_SPACING} 40px ${CSS_SPACING} 0;
          grid-row-gap: ${CSS_SPACING_ROW};
          grid-column-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-content: center;
        }

        .buttons-container {
          margin-top: 50px;
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .text-input {
          width: 100%;
        }
      `,
    ];
  }

  __renderReportTypeCode() {
    return html`
      <neb-select
        id="${ELEMENTS.reportTypeCodeDropdown.id}"
        label="Report Type Code"
        name="reportTypeCode"
        helper="Required"
        .items="${[ITEM_EMPTY, ...REPORTS_TYPE_CODE]}"
        .value="${this.state.reportTypeCode}"
        .error="${this.validation.errors.reportTypeCode}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderReportTransmissionCode() {
    return html`
      <neb-select
        id="${ELEMENTS.reportTransmissionCodeDropdown.id}"
        label="Report Transmission Code"
        name="reportTransmissionCode"
        helper="Required"
        .items="${[ITEM_EMPTY, ...REPORTS_TRANSMISSION_CODE]}"
        .value="${this.state.reportTransmissionCode}"
        .error="${this.validation.errors.reportTransmissionCode}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderCodeQualifier() {
    return html`
      <neb-select
        id="${ELEMENTS.codeQualifierDropdown.id}"
        label="Code Qualifier"
        name="codeQualifier"
        class="codeQualifier"
        helper="${this.__isReportTransmissionCodeDifferentThanAA()
          ? 'Required'
          : ''}"
        .items="${[ITEM_EMPTY, ...CODE_QUALIFIERS]}"
        .value="${this.state.codeQualifier}"
        .error="${this.validation.errors.codeQualifier}"
        ?disabled="${true}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderIdentificationNumber() {
    return html`
      <neb-textfield
        id="${ELEMENTS.identificationNumberTextfield.id}"
        class="text-input"
        label="Identification Number"
        name="identificationNumber"
        maxLength="50"
        helper="${this.__isReportTransmissionCodeDifferentThanAA()
          ? 'Required'
          : ''}"
        .value="${this.state.identificationNumber}"
        .error="${this.validation.errors.identificationNumber}"
        ?disabled="${this.__disableIdentificationNumber}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.sendButton.id}"
          class="button add-button"
          label="DONE"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.confirm}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          label="CANCEL"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  render() {
    return html`
      <div class="grid grid-4">
        ${this.__renderReportTypeCode()}
        ${this.__renderReportTransmissionCode()} ${this.__renderCodeQualifier()}
        ${this.__renderIdentificationNumber()}
      </div>
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define(
  'neb-form-supplemental-information',
  NebFormSupplementalInformation,
);
