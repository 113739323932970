import { openSuccess, openError } from '../../neb-dialog/neb-banner-state';
import { addProviderNameToProblem } from '../../neb-utils/neb-charting-util';
import {
  getProblemList,
  saveProblemList,
} from '../actions/problem-list-actions';
import { store } from '../neb-redux-store';

export const BANNER_SUCCESS = 'Problem list saved';
export const BANNER_ERROR = 'Unable to update problem list';
export class ProblemListService {
  constructor(callback) {
    this.__problems = [];
    this.formattedProblems = [];
    this.onStateChanged = callback;
    this.patientId = null;
  }

  connect() {
    this.__storeUnsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );

    this._stateChanged(store.getState());
  }

  disconnect() {
    this.__storeUnsubscribe();
  }

  _stateChanged(state) {
    if (!(state && state.problemList && this.patientId)) return;

    if (this.__fetch && state.session.item) {
      this.__fetch = false;
      store.dispatch(getProblemList(this.patientId));
    }

    const newProblems = state.problemList[this.patientId];

    if (
      newProblems &&
      newProblems !== this.__problems &&
      state.providers.item.length
    ) {
      this.__problems = newProblems;
      this.formattedProblems = this.__problems.map(p =>
        addProviderNameToProblem(state, p),
      );

      this.onStateChanged(this.formattedProblems);
    }
  }

  update(patientId) {
    this.patientId = patientId;
    this.__fetch = true;

    this._stateChanged(store.getState());
  }

  async save(problems) {
    const response = await store.dispatch(
      saveProblemList(this.patientId, problems),
    );

    if (response.type === 'ITEM_UPDATE_ERROR') {
      store.dispatch(openError(BANNER_ERROR));
      return false;
    }

    store.dispatch(openSuccess(BANNER_SUCCESS));
    store.dispatch(getProblemList(this.patientId));
    return true;
  }
}
