import { hasLength } from '@neb/form-validators';
import { html, css } from 'lit';

import Address from '../../../../packages/neb-lit-components/src/components/field-groups/neb-address';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  TAXONOMY_DATA,
  TAXONOMY_DATA_EMPTY,
} from '../../../../packages/neb-lit-components/src/utils/taxonomy';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_BUTTON_SPACING_ROW,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { toNumeric } from '../../../../packages/neb-utils/formatters';
import { phone, number, taxId } from '../../../../packages/neb-utils/masks';
import {
  select,
  phoneNumber,
  ITEM_EMPTY,
} from '../../../../packages/neb-utils/selectors';
import { required, isEmail } from '../../../../packages/neb-utils/validators';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';

const ADDRESS_HELPERS = {
  address1: 'Required',
  city: 'Required',
  state: 'Required',
  zipcode: 'Required',
};

const CHECKBOX_VALUES = {
  addressOnly: 'addressOnly',
  useBillingNPI: 'organizationNPI',
  useBillingTaxId: 'taxId',
  useBillingTaxonomy: 'taxonomy',
  hideLogo: 'hideLogo',
};

const ADDRESS_ONLY_DISABLED_FIELDS = {
  useBillingNPI: 'useBillingNPI',
  useBillingTaxId: 'useBillingTaxId',
  useBillingTaxonomy: 'useBillingTaxonomy',
  taxId: 'taxId',
  taxonomy: 'taxonomy',
  fl33Location: 'fl33Location',
  fl32LocationId: 'fl32LocationId',
  organizationNPI: 'organizationNPI',
  allowOnlineBooking: 'allowOnlineBooking',
  allowWalkInAppointments: 'allowWalkInAppointments',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  name: { id: 'name' },
  businessName: { id: 'service-location' },
  address: { id: 'address' },
  phone: { id: 'phone' },
  email: { id: 'email' },
  allowOnlineBooking: { id: 'allow-online-booking' },
  allowWalkInAppointments: { id: 'allow-walk-in-appointments' },
  addressOnly: { id: 'address-only' },
  locationConfigHeader: { id: 'location-config-header' },
  useBillingNPI: { id: 'use-billing-information-npi', tag: 'neb-checkbox' },
  useBillingTaxId: { id: 'use-billing-information-taxId', tag: 'neb-checkbox' },
  useBillingTaxonomy: {
    id: 'use-billing-information-taxonomy',
    tag: 'neb-checkbox',
  },
  organizationNPI: { id: 'organizationNPI' },
  taxId: { id: 'taxId' },
  taxonomy: { id: 'taxonomy' },
  fl32LocationId: { id: 'fl32-location' },
  fl33Location: { id: 'fl33-location' },
  active: { id: 'active' },
  hideLogo: { id: 'hide-logo' },
};

const removeSpecialCharacters = str => str.replace(/[()-/]/g, '');

export class NebFormPracticeLocation extends NebForm {
  static get properties() {
    return {
      billingInfo: Object,
      locations: Array,
      readOnly: Boolean,
      hasSettingsPermissions: { type: Boolean },

      __searchResult: Array,
      __showResults: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid-2 {
          display: grid;
          width: 100%;
          column-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .grid-span-mobile {
          grid-gap: 20px;
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .section-title-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .section-title {
          padding-bottom: 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .field {
          padding: 0;
        }

        .taxonomy {
          padding-bottom: 10px;
        }

        .checkbox-container {
          padding: ${CSS_BUTTON_SPACING_ROW} 0px;
        }

        .switch {
          margin-top: 8px;
        }

        .d-flex-column {
          display: flex;
          flex-direction: column;
        }

        .input-with-checkbox {
          margin-bottom: 10px;
        }

        .billing-info {
          margin-bottom: ${CSS_SPACING_ROW};
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
      businessName: '',
      phoneNumber: '',
      address: Address.createModel(),
      emailAddress: '',
      useBillingNPI: false,
      useBillingTaxId: false,
      useBillingTaxonomy: false,
      organizationNPI: '',
      taxId: '',
      taxonomy: TAXONOMY_DATA_EMPTY,
      fl33Location: ITEM_EMPTY,
      fl32LocationId: ITEM_EMPTY,
      allowOnlineBooking: false,
      allowWalkInAppointments: false,
      addressOnly: false,
      main: false,
      active: true,
      hideLogo: false,
    };
  }

  initState() {
    this.billingInfo = {};
    this.locations = [];
    this.readOnly = false;

    this.__search = '';
    this.__showResults = false;
    this.__searchResult = TAXONOMY_DATA;
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeAddressOnlyCheckbox: e => {
        this.handlers.change(e);
        Object.values(ADDRESS_ONLY_DISABLED_FIELDS).forEach(field =>
          this.__clearField(field),
        );
      },
      changeCheckbox: e => {
        this.handlers.change(e);
        const formField = CHECKBOX_VALUES[e.name];

        return e.value
          ? this.__handleUseBillingField(formField)
          : this.__clearField(formField);
      },
      searchTaxonomy: e => {
        this.__search = e.value;
        if (!this.__search) this.__showResults = true;
        this.__searchTaxonomy(e.value);
      },
    };
  }

  createSelectors() {
    return {
      children: {
        name: [required()],
        address: Address.createSelectors(Object.keys(ADDRESS_HELPERS)),
        phoneNumber: {
          ...phoneNumber({
            validators: [
              {
                error: 'Required',
                validate: v => !!v,
              },
            ],
          }),
          unformat: v => toNumeric(v) || null,
        },
        emailAddress: {
          unformat: v => v || null,
          validators: [isEmail],
        },
        organizationNPI: {
          unformat: v => v || null,
          validators: [hasLength(10, 'Must be 10 digits')],
        },
        taxId: {
          unformat: v => v || null,
          validators: [
            {
              error: 'Must be 9 digits',
              validate: v => !v || (v.length && v.length === 10),
            },
          ],
        },
        taxonomy: {
          ...select(TAXONOMY_DATA, TAXONOMY_DATA_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.addressOnly || !!(v.data && v.data.id),
              },
            ],
          }),
          unformat: v => v.data.id || null,
        },
        fl33Location: {
          ...select(this.locations, ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.addressOnly || !!(v.data && v.label),
              },
            ],
          }),
          unformat: v => (v.data && v.data.id) || null,
        },
        fl32LocationId: {
          ...select(this.locations, ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.addressOnly || !!(v.data && v.label),
              },
            ],
          }),
          unformat: v => (v.data && v.data.id) || null,
        },
      },
    };
  }

  __isBooleanField(field) {
    return (
      field === 'allowOnlineBooking' ||
      field === 'allowWalkInAppointments' ||
      field === 'hideLogo' ||
      field.indexOf('useBilling') > -1 ||
      field.indexOf('useCurrent') > -1
    );
  }

  __clearField(field) {
    if (field === 'taxonomy') {
      this.formService.apply(field, TAXONOMY_DATA_EMPTY);
    } else if (field === 'fl33Location') {
      this.formService.apply(field, ITEM_EMPTY);
    } else if (this.__isBooleanField(field)) {
      this.formService.apply(field, false);
    } else this.formService.apply(field, '');
  }

  __searchTaxonomy(searchText) {
    const queryTerms = searchText.trim().toLowerCase().split(' ');

    this.__searchResult = TAXONOMY_DATA.filter(taxonomy => {
      const search = [
        taxonomy.data.code,
        taxonomy.data.description,
        taxonomy.label,
      ]
        .join(' ')
        .toLowerCase();

      return queryTerms.every(term =>
        search.includes(removeSpecialCharacters(term)),
      );
    });
  }

  __useBillingTaxonomy() {
    if (!this.state.useBillingTaxonomy) return this.state.taxonomy;

    return this.billingInfo.taxonomy
      ? TAXONOMY_DATA.find(({ data }) => data.id === this.billingInfo.taxonomy)
      : TAXONOMY_DATA_EMPTY;
  }

  __useBillingFl33Location() {
    return this.state.useCurrentFl33
      ? this.billingInfo.fl33Location
      : this.state.fl33Location;
  }

  __handleUseBillingTaxonomy(name) {
    const taxonomyValue = this.__useBillingTaxonomy(
      this.state,
      this.billingInfo,
    );

    this.formService.apply(name, taxonomyValue);

    return taxonomyValue;
  }

  __handleUseBillingField(name) {
    switch (name) {
      case 'taxonomy':
        this.__handleUseBillingTaxonomy(name);
        break;

      case 'fl33Location':
        this.formService.apply(name, ITEM_EMPTY);
        break;

      default:
        this.formService.apply(name, this.billingInfo[name]);
    }
  }

  __buildUseCurrentList(isFl32 = false) {
    const locationList = isFl32
      ? this.locations.filter(
          l =>
            l.data.id === this.model.id ||
            (l.data.active === true && l.data.addressOnly === false),
        )
      : this.locations.filter(
          l => l.data.id === this.model.id || l.data.active === true,
        );

    const location = locationList.find(l => l.data.id === this.model.id);

    const currentLocation =
      this.model.id && location
        ? {
            ...location,
            label: `Current - ${this.model.name}`,
          }
        : { data: { id: '' }, label: `Current - ${this.state.name}` };

    if (this.model.id && currentLocation.data) {
      return [
        currentLocation,
        ...locationList.filter(l => l.data.id !== currentLocation.data.id),
      ];
    }

    return [currentLocation, ...locationList];
  }

  __buildLabel(dropDownState = { data: { id: '' }, label: '' }) {
    const currentLocationLabel =
      this.model.id &&
      dropDownState.data &&
      dropDownState.data.id === this.model.id
        ? {
            ...dropDownState,
            label: `Current - ${this.model.name}`,
          }
        : dropDownState;

    return currentLocationLabel;
  }

  __handleUseBillingInformation(name) {
    switch (name) {
      case 'organizationNPI':
        return this.state.useBillingNPI
          ? this.billingInfo.organizationNPI
          : this.state.organizationNPI;

      case 'taxId':
        return this.state.useBillingTaxId
          ? this.billingInfo.taxId
          : this.state.taxId;

      case 'taxonomy':
        return this.__useBillingTaxonomy();

      case 'fl33Location':
        return this.__useBillingFl33Location();

      default:
        return this.billingInfo[name];
    }
  }

  renderCheckbox(item, handler) {
    return html`
      <neb-checkbox
        id="${ELEMENTS[item.name].id}"
        name="${item.name}"
        label="${item.label}"
        .value="${this.state[item.name]}"
        .error="${this.errors[item.name]}"
        .disabled="${item.disabled}"
        .onChange="${handler}"
        ?checked="${this.state[item.name]}"
      ></neb-checkbox>
    `;
  }

  renderBusinessName() {
    return html`
      <neb-textfield
        id="${ELEMENTS.businessName.id}"
        class="input input-with-checkbox"
        name="businessName"
        label="${'Business Name'}"
        .value="${this.state.businessName}"
        .error="${this.errors.businessName}"
        .onChange="${this.handlers.change}"
        .disabled="${this.readOnly}"
      ></neb-textfield>
    `;
  }

  renderBusinessNameWithCheckBox() {
    return html`
      <div class="d-flex-column">
        ${this.renderBusinessName()}
        ${this.renderCheckbox(
          {
            name: 'hideLogo',
            label: 'Hide practice logo on patient-facing documents',
            disabled: this.state.addressOnly || this.readOnly,
          },
          this.handlers.change,
        )}
      </div>
    `;
  }

  renderLocationDetails() {
    return html`
      <div class="grid">
        <div class="checkbox-container">
          ${!this.state.main
            ? this.renderCheckbox(
                {
                  name: 'addressOnly',
                  label: 'Billing/Claims Address Only',
                  disabled: !!this.state.id || this.readOnly,
                },
                this.handlers.changeAddressOnlyCheckbox,
              )
            : ''}
        </div>
        <div class="grid-2">
          <neb-textfield
            id="${ELEMENTS.name.id}"
            class="input"
            name="name"
            label="Location Name"
            helper="Required"
            .value="${this.state.name}"
            .error="${this.errors.name}"
            .onChange="${this.handlers.change}"
            .disabled="${this.readOnly}"
          ></neb-textfield>

          ${this.renderBusinessNameWithCheckBox()}
        </div>
        <neb-address
          id="${ELEMENTS.address.id}"
          name="address"
          .helpers="${ADDRESS_HELPERS}"
          .model="${this.state.address}"
          .errors="${this.errors.address}"
          .onChange="${this.handlers.change}"
          .disabled="${this.readOnly}"
        ></neb-address>

        <div class="grid-2">
          <neb-textfield
            id="${ELEMENTS.phone.id}"
            class="input"
            name="phoneNumber"
            .mask="${phone}"
            .inputMode="numeric"
            .value="${this.state.phoneNumber}"
            .error="${this.errors.phoneNumber}"
            .onChange="${this.handlers.change}"
            label="Phone Number"
            helper="Required"
            maxLength="14"
            validator="isMobilePhone"
            .disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.email.id}"
            class="input"
            name="emailAddress"
            .value="${this.state.emailAddress}"
            .error="${this.errors.emailAddress}"
            .onChange="${this.handlers.change}"
            label="Email Address"
            helper=" "
            validator="isEmail"
            maxLength="255"
            .disabled="${this.readOnly}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  __renderBillingInfo() {
    return html`
      <div class="grid-2 billing-info">
        <div class="d-flex-column">
          <neb-textfield
            id="${ELEMENTS.organizationNPI.id}"
            class="input input-with-checkbox"
            name="organizationNPI"
            label="Organization NPI"
            maxLength="10"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .value="${this.__handleUseBillingInformation('organizationNPI')}"
            .error="${this.errors.organizationNPI}"
            .disabled="${this.state.useBillingNPI ||
            this.state.addressOnly ||
            this.readOnly}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
          ${this.renderCheckbox(
            {
              name: 'useBillingNPI',
              label: 'Use Billing Information',
              disabled: this.state.addressOnly || this.readOnly,
            },
            this.handlers.changeCheckbox,
          )}
        </div>

        <div class="d-flex-column">
          <neb-textfield
            id="${ELEMENTS.taxId.id}"
            name="taxId"
            class="input-with-checkbox"
            label="Tax ID"
            maxlength="10"
            .inputMode="${'numeric'}"
            .mask="${taxId}"
            .value="${this.__handleUseBillingInformation('taxId')}"
            .error="${this.errors.taxId}"
            .disabled="${this.state.useBillingTaxId ||
            this.state.addressOnly ||
            this.readOnly}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
          ${this.renderCheckbox(
            {
              name: 'useBillingTaxId',
              label: 'Use Billing Information',
              disabled: this.state.addressOnly || this.readOnly,
            },
            this.handlers.changeCheckbox,
          )}
        </div>
      </div>
    `;
  }

  renderLocationConfig() {
    return html`
      <div class="section-title-container">
        <div id="${ELEMENTS.locationConfigHeader.id}" class="section-title">
          Location Configuration
        </div>
      </div>

      <div class="grid">
        ${this.__renderBillingInfo()}
        <div class="grid-2">
          <div class="grid field">
            <neb-select
              id="${ELEMENTS.fl32LocationId.id}"
              name="fl32LocationId"
              helper="Required"
              label="Service Facility Location Information (FL32)"
              .items="${this.__buildUseCurrentList(true)}"
              .value="${this.__buildLabel(this.state.fl32LocationId)}"
              .error="${this.errors.fl32LocationId}"
              .disabled="${this.state.addressOnly || this.readOnly}"
              .onChange="${this.handlers.change}"
            ></neb-select>
            <div></div>
          </div>

          <div class="grid field">
            <neb-select
              id="${ELEMENTS.fl33Location.id}"
              name="fl33Location"
              helper="Required"
              label="Billing Provider Information and Phone Number (FL33)"
              .items="${this.__buildUseCurrentList()}"
              .value="${this.__buildLabel(this.state.fl33Location)}"
              .error="${this.errors.fl33Location}"
              .disabled="${this.state.addressOnly || this.readOnly}"
              .onChange="${this.handlers.change}"
            ></neb-select>
            <div></div>
          </div>
        </div>

        <div class="grid-2">
          <div class="grid field">
            <neb-select-search
              id="${ELEMENTS.taxonomy.id}"
              name="taxonomy"
              helper="Required"
              label="Primary Taxonomy"
              .search="${this.__search}"
              .items="${this.__searchResult}"
              .value="${this.__handleUseBillingInformation('taxonomy')}"
              .error="${this.errors.taxonomy}"
              .disabled="${this.state.useBillingTaxonomy ||
              this.state.addressOnly ||
              this.readOnly}"
              .onChange="${this.handlers.change}"
              .onSearch="${this.handlers.searchTaxonomy}"
              .showMenu="${this.__showResults}"
              showSearch
            ></neb-select-search>

            <div class="taxonomy">
              ${this.state.taxonomy ? this.state.taxonomy.data.description : ''}
            </div>

            ${this.renderCheckbox(
              {
                name: 'useBillingTaxonomy',
                label: 'Use Billing Information',
                disabled: this.state.addressOnly || this.readOnly,
              },
              this.handlers.changeCheckbox,
            )}
          </div>
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.renderLocationDetails()} ${this.renderLocationConfig()}
      <div class="grid grid-span-mobile">
        ${this.renderCheckbox(
          {
            name: 'allowOnlineBooking',
            label: 'Allow online booking',
            disabled: this.state.addressOnly || this.readOnly,
          },
          this.handlers.change,
        )}
        ${this.renderCheckbox(
          {
            name: 'allowWalkInAppointments',
            label: 'Allow walk-in appointments',
            disabled: this.state.addressOnly || this.readOnly,
          },
          this.handlers.change,
        )}

        <neb-switch
          class="switch"
          id="${ELEMENTS.active.id}"
          label="Active"
          name="active"
          .on="${this.state.active}"
          .onChange="${this.handlers.change}"
          .disabled="${this.readOnly}"
        >
        </neb-switch>
      </div>
    `;
  }
}

customElements.define('neb-form-practice-location', NebFormPracticeLocation);
