import DeprecatedApiClient, { Method } from './utils/api-client-utils';

const baseUrl = 'additional-claim-code';
export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const getAdditionalClaimCodes = queryParams =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: baseUrl,
    queryParams,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });
