import { _itemsGroupedById, _sortItems } from './base'; // Create actions

export const _blockedOffTimeCreating = state => ({
  ...state,
  blockedOffTimeCreating: true,
});

export const _blockedOffTimeCreated = (state, { items: unsortedItems }) => {
  const items = _sortItems(unsortedItems);

  return {
    ...state,
    blockedOffTimeCreating: false,
    blockedOffTimeCreated: true,
    blockedOffTimeCreateError: null,
    itemsByGroupId: _itemsGroupedById(items),
    items,
  };
};
export const _blockedOffTimeCreateError = (
  state,
  { blockedOffTimeCreateError },
) => ({
  ...state,
  blockedOffTimeCreating: false,
  blockedOffTimeCreated: false,
  blockedOffTimeCreateError,
}); // Update actions

export const _blockedOffTimeUpdating = state => ({
  ...state,
  blockedOffTimeUpdating: true,
});

export const _blockedOffTimeUpdated = (state, { items: unsortedItems }) => {
  const items = _sortItems(unsortedItems);

  return {
    ...state,
    blockedOffTimeUpdating: false,
    blockedOffTimeUpdated: true,
    blockedOffTimeUpdateError: null,
    itemsByGroupId: _itemsGroupedById(items),
    items,
  };
};
export const _blockedOffTimeUpdateError = (
  state,
  { blockedOffTimeUpdateError },
) => ({
  ...state,
  blockedOffTimeUpdating: false,
  blockedOffTimeUpdated: false,
  blockedOffTimeUpdateError,
}); // Delete actions

export const _blockedOffTimeDeleting = state => ({
  ...state,
  blockedOffTimeDeleting: true,
});
export const _blockedOffTimeDeleted = (state, { items }) => ({
  ...state,
  blockedOffTimeDeleting: false,
  blockedOffTimeDeleted: true,
  blockedOffTimeDeleteError: null,
  itemsByGroupId: _itemsGroupedById(items),
  items,
});
export const _blockedOffTimeDeleteError = (
  state,
  { blockedOffTimeDeleteError },
) => ({
  ...state,
  blockedOffTimeDeleting: false,
  blockedOffTimeDeleted: false,
  blockedOffTimeDeleteError,
});
