const setProperty = property => (patient, newValue) => {
  patient[property] = newValue;
};

const commonDataProperties = [
  'id',
  'lastName',
  'firstName',
  'middleName',
  'preferredName',
  'dateOfBirth',
  'sex',
  'ssn',
  'medicalRecordNumber',
  'relationshipStatus',
  'employmentStatus',
  'status',
];
const csvDataProperties = [
  ...commonDataProperties,
  'phoneNumberOne',
  'phoneNumberOneType',
  'phoneNumberTwo',
  'phoneNumberTwoType',
  'phoneNumberThree',
  'phoneNumberThreeType',
  'emailAddressOne',
  'emailAddressTwo',
  'emailAddressThree',
  'addressOne',
  'addressTwo',
  'city',
  'state',
  'zipcode',
];

const createDataSetterFromArray = propertyArray =>
  Object.fromEntries(
    propertyArray.map(property => [property, setProperty(property)]),
  );

const commonDataSetter = createDataSetterFromArray(commonDataProperties);

export const csvDataSetter = createDataSetterFromArray(csvDataProperties);

const ARRAY_PROP_PHONE_NUMBERS = 'phoneNumbers';
const OBJECT_PROP_NUMBER = 'number';
const OBJECT_PROP_TYPE = 'type';
const ARRAY__PROP_ADDRESSES = 'addresses';
const OBJECT_PROP_ADDRESS1 = 'addressOne';
const OBJECT_PROP_ADDRESS2 = 'addressTwo';
const OBJECT_PROP_CITY = 'city';
const OBJECT_PROP_STATE = 'state';
const OBJECT_PROP_ZIPCODE = 'zipcode';
const ARRAY_PROP_EMAIL = 'emailAddresses';

const setValueToObjectInArray = (
  patient,
  arrayProp,
  index,
  objectProp,
  newValue,
) => {
  patient[arrayProp] = patient[arrayProp] || [];
  patient[arrayProp][index] = patient[arrayProp][index] || {};
  patient[arrayProp][index][objectProp] = newValue;
};

const setValueInArray = (patient, arrayProp, index, newValue) => {
  patient[arrayProp] = patient[arrayProp] || [];
  patient[arrayProp][index] = newValue;
};

export const recordDataSetter = {
  ...commonDataSetter,
  phoneNumberOne: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      0,
      OBJECT_PROP_NUMBER,
      newValue,
    );
  },
  phoneNumberOneType: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      0,
      OBJECT_PROP_TYPE,
      newValue,
    );
  },
  phoneNumberTwo: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      1,
      OBJECT_PROP_NUMBER,
      newValue,
    );
  },
  phoneNumberTwoType: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      1,
      OBJECT_PROP_TYPE,
      newValue,
    );
  },
  phoneNumberThree: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      2,
      OBJECT_PROP_NUMBER,
      newValue,
    );
  },
  phoneNumberThreeType: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY_PROP_PHONE_NUMBERS,
      2,
      OBJECT_PROP_TYPE,
      newValue,
    );
  },
  emailAddressOne: (patient, newValue) => {
    setValueInArray(patient, ARRAY_PROP_EMAIL, 0, newValue);
  },
  emailAddressTwo: (patient, newValue) => {
    setValueInArray(patient, ARRAY_PROP_EMAIL, 1, newValue);
  },
  emailAddressThree: (patient, newValue) => {
    setValueInArray(patient, ARRAY_PROP_EMAIL, 2, newValue);
  },
  addressOne: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY__PROP_ADDRESSES,
      0,
      OBJECT_PROP_ADDRESS1,
      newValue,
    );
  },
  addressTwo: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY__PROP_ADDRESSES,
      0,
      OBJECT_PROP_ADDRESS2,
      newValue,
    );
  },
  city: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY__PROP_ADDRESSES,
      0,
      OBJECT_PROP_CITY,
      newValue,
    );
  },
  state: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY__PROP_ADDRESSES,
      0,
      OBJECT_PROP_STATE,
      newValue,
    );
  },
  zipcode: (patient, newValue) => {
    setValueToObjectInArray(
      patient,
      ARRAY__PROP_ADDRESSES,
      0,
      OBJECT_PROP_ZIPCODE,
      newValue,
    );
  },
};
