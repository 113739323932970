import { html, css } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { BASE_ELEMENTS } from '../../../../features/charting/neb-unsigned-encounter-detail';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  code: { selector: '[id^=code-]' },
  modifiers: { selector: '[id^=modifiers-]', tag: 'neb-modifiers' },
};
const CONFIG = [
  {
    key: 'code',
    label: 'Procedure',
    flex: css`0 0 70px`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`1.5 0 70px`,
  },
];
class NebTableModifiersEdit extends NebTable {
  static get properties() {
    return {
      isScrollable: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row-header,
        .row-data {
          padding: 0 40px 0 0;
        }

        .cell-data {
          padding: 5px 0;
        }

        :host([isScrollable]) {
          padding: 5px 50px 20px 10px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {};

    this.errors = {};

    this.config = CONFIG;

    this.isScrollable = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      change: e => {
        this.onChange(e);
      },
    };
  }

  renderDataCell(value, columnConfig, rowIndex, name) {
    const { key } = columnConfig;

    switch (key) {
      case 'code':
        return html` <div id="code-${rowIndex}">${value}</div> `;

      case 'modifiers':
        return html`
          <div>
            <neb-modifiers
              id="modifiers-${rowIndex}"
              label=" "
              helper=" "
              name="${name}"
              .errors="${this.errors[rowIndex].modifiers}"
              .values="${value}"
              .onChange="${this.handlers.change}"
            ></neb-modifiers>
          </div>
        `;
      default:
        return value;
    }
  }
}
customElements.define('neb-table-modifiers-edit', NebTableModifiersEdit);
