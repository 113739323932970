import '../../neb-action-bar';

import { LitElement, html, css } from 'lit';

import { FULL_DATE_FORMAT } from '../../../../../neb-input/nebFormatUtils';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../neb-utils/date-util';
import { centsToCurrency } from '../../../../../neb-utils/formatters';
import {
  formatPaymentMethodInfo,
  paymentHasAuthEFT,
} from '../../../../../neb-utils/neb-payment-util';
import { BUTTON_ROLE } from '../../neb-button';

export const ELEMENTS = {
  actionBar: {
    id: 'action-bar',
  },
  amount: {
    id: 'amount',
  },
  authEFT: {
    id: 'auth-EFT',
  },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
  },
  description: {
    id: 'description',
  },
  note: {
    id: 'note',
  },
  cardRefundId: {
    id: 'card-refund-id',
  },
  refundMethod: {
    id: 'card-refund-method',
  },
  postedBy: {
    id: 'posted-by',
  },
  paymentMethod: {
    id: 'payment-method',
  },
  paymentType: {
    id: 'payment-type',
  },
  postHeader: {
    id: 'post-header',
  },
  referenceId: {
    id: 'reference-id',
  },
  transactionDate: {
    id: 'transaction-date',
  },
  cardSaleId: {
    id: 'card-sale-id',
  },
  voidedAmount: {
    id: 'voided-amount',
  },
  voidedBy: {
    id: 'voided-by',
  },
  voidedDate: {
    id: 'voided-date',
  },
  voidPayment: {
    id: 'void-payment',
  },
};

const DESCRIPTION =
  "If you're ready to proceed to allocate payments, click Allocate Payment. Otherwise click Close.";
class NebPayerPaymentPost extends LitElement {
  static get properties() {
    return {
      model: Object,
      voidPayment: Object,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.layout = '';
    this.voidPayment = {};

    this.onAllocatePayment = () => {};

    this.onClose = () => {};

    this.onVoidPayment = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      allocatePayment: () => this.onAllocatePayment(),
      close: () => this.onClose(),
      voidPayment: () =>
        this.__voidPaymentExists() ? '' : this.onVoidPayment(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .flex-page {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          overflow-y: auto;
        }

        .post-content {
          padding-top: 30px;
        }

        .description {
          padding: 0 ${CSS_SPACING};
        }

        .header-border {
          border-bottom: ${CSS_BORDER_GREY_1};
        }

        .section {
          padding: 10px ${CSS_SPACING};
        }

        .separator {
          border-top: ${CSS_BORDER_GREY_1};
        }

        .font-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .first-row {
          display: flex;
          padding-top: ${CSS_SPACING};
        }

        .first-item {
          padding-top: ${CSS_SPACING};
        }

        .void-payment {
          flex: 1;
          text-align: end;
        }

        .payment-type {
          display: flex;
          flex-direction: column;
        }

        .note:not([bottomPanelVisible]) {
          padding-bottom: 90px;
        }

        :host([layout='small']) .note:not([bottomPanelVisible]) {
          padding-bottom: 130px;
        }

        .last-section {
          padding-bottom: 90px;
        }

        .action-bar {
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        :host([layout='small']) .action-bar:not([bottomPanelVisible]) {
          height: 120px;
        }
      `,
    ];
  }

  __renderPaymentMethod() {
    return html`
      <div class="section">
        <div class="font-bold">Payment Method</div>
        <div id="${ELEMENTS.paymentMethod.id}">
          ${formatPaymentMethodInfo(this.model)}
        </div>
      </div>
    `;
  }

  __renderAuthEFT() {
    return paymentHasAuthEFT(this.model)
      ? html`
          <div class="section">
            <div class="font-bold">Auth/Check/EFT</div>
            <div id="${ELEMENTS.authEFT.id}">
              ${this.model.authEFT ? this.model.authEFT : '-'}
            </div>
          </div>
        `
      : '';
  }

  __renderCardSaleId() {
    return this.model.electronicPaymentId
      ? html`
          <div class="section">
            <div class="font-bold">Card Sale ID</div>
            <div id="${ELEMENTS.cardSaleId.id}">
              ${this.model.cardSaleId ? this.model.cardSaleId : '-'}
            </div>
          </div>
        `
      : '';
  }

  __renderReferenceId({ referenceId }) {
    return html`
      <div class="section">
        <div class="font-bold">Reference ID</div>
        <div id="${ELEMENTS.referenceId.id}">
          ${referenceId ? html` ${referenceId} ` : '-'}
        </div>
      </div>
    `;
  }

  __renderDateOfService({ dateOfServiceFrom, dateOfServiceTo }) {
    return html`
      <div class="section">
        <div class="font-bold">Date of Service From</div>
        <div id="${ELEMENTS.dateOfServiceFrom.id}">
          ${dateOfServiceFrom
            ? html`
                ${parseDate(this.model.dateOfServiceFrom).format(
                  FULL_DATE_FORMAT,
                )}
              `
            : '-'}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Date of Service To</div>
        <div id="${ELEMENTS.dateOfServiceTo.id}">
          ${dateOfServiceTo
            ? html`
                ${parseDate(this.model.dateOfServiceTo).format(
                  FULL_DATE_FORMAT,
                )}
              `
            : '-'}
        </div>
      </div>
    `;
  }

  __renderNote({ note }) {
    return html`
      <div
        class="section note"
        ?bottomPanelVisible="${this.__voidPaymentExists()}"
      >
        <div class="font-bold">Payment Note</div>
        <div id="${ELEMENTS.note.id}">${note ? html` ${note} ` : '-'}</div>
      </div>
    `;
  }

  __renderVoidPaymentContent() {
    return this.__voidPaymentExists()
      ? html`
          <div class="section separator first-item">
            <div class="font-bold">Voided Amount</div>
            <div id="${ELEMENTS.voidedAmount.id}">
              ${centsToCurrency(this.model.amount)}
            </div>
          </div>

          <div class="section">
            <div class="font-bold">Voided Date</div>
            <div id="${ELEMENTS.voidedDate.id}">
              ${parseDate(this.voidPayment.updatedAt).format(FULL_DATE_FORMAT)}
            </div>
          </div>

          <div class="section last-section">
            <div class="font-bold">Voided By</div>
            <div id="${ELEMENTS.voidedBy.id}">${this.model.postedBy}</div>
          </div>
        `
      : '';
  }

  __renderRefundPaymentContent() {
    return html`
      <div class="section separator first-item">
        <div class="font-bold">Refunded Date</div>
        <div id="${ELEMENTS.voidedDate.id}">
          ${parseDate(this.voidPayment.updatedAt).format(FULL_DATE_FORMAT)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Refunded Amount</div>
        <div id="${ELEMENTS.voidedAmount.id}">
          ${centsToCurrency(this.model.amount)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Refund Method</div>
        <div id="${ELEMENTS.refundMethod.id}">
          ${this.voidPayment.refundMethod}
        </div>
      </div>

      ${this.__renderTransactionID()}
      <div class="section last-section">
        <div class="font-bold">Refunded By</div>
        <div id="${ELEMENTS.voidedBy.id}">${this.model.postedBy}</div>
      </div>
    `;
  }

  __renderTransactionID() {
    return this.voidPayment.cardRefundId
      ? html`
          <div class="section">
            <div class="font-bold">Refund Transaction ID</div>
            <div id="${ELEMENTS.cardRefundId.id}">
              ${this.voidPayment.cardRefundId}
            </div>
          </div>
        `
      : '';
  }

  __renderRefundVoidPaymentContent() {
    if (!this.__voidPaymentExists()) return '';

    return this.model.electronicPaymentId
      ? this.__renderRefundPaymentContent()
      : this.__renderVoidPaymentContent();
  }

  __voidButtonLabel() {
    return this.model.electronicPaymentId ? 'REFUND PAYMENT' : 'VOID PAYMENT';
  }

  __voidPaymentExists() {
    return Object.keys(this.voidPayment || {}).length;
  }

  __renderPaymentPostContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${DESCRIPTION}
      </div>

      <div class="post-content">
        <div
          id="${ELEMENTS.postHeader.id}"
          class="header-border section font-bold"
        >
          ${`Payment ID: ${this.model.paymentNumber} (PAID)`}
        </div>

        <div class="section separator">
          <div class="first-row">
            <div class="payment-type">
              <div class="font-bold">Payment Type</div>

              <div id="${ELEMENTS.paymentType.id}">
                ${this.model.codePayment.description}
              </div>
            </div>

            <div class="void-payment">
              <neb-button
                id="${ELEMENTS.voidPayment.id}"
                .label="${this.__voidButtonLabel()}"
                .role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.__handlers.voidPayment}"
                ?disabled="${this.__voidPaymentExists()}"
              ></neb-button>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="font-bold">Payment Amount</div>
          <div id="${ELEMENTS.amount.id}">
            ${centsToCurrency(this.model.amount)}
          </div>
        </div>

        <div class="section">
          <div class="font-bold">Transaction Date</div>
          <div id="${ELEMENTS.transactionDate.id}">
            ${parseDate(this.model.transactionDate).format(FULL_DATE_FORMAT)}
          </div>
        </div>

        ${this.__renderPaymentMethod()} ${this.__renderAuthEFT(this.model)}
        ${this.__renderCardSaleId()} ${this.__renderReferenceId(this.model)}
        ${this.__renderDateOfService(this.model)}

        <div class="section">
          <div class="font-bold">Posted By</div>
          <div id="${ELEMENTS.postedBy.id}">${this.model.postedBy}</div>
        </div>

        ${this.__renderNote(this.model)}
      </div>
    `;
  }

  __renderConfirm() {
    return this.model.purchase || this.__voidPaymentExists()
      ? ''
      : 'ALLOCATE PAYMENT';
  }

  __renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="${this.__renderConfirm()}"
        cancelLabel="CLOSE"
        .layout="${this.layout}"
        .onConfirm="${this.__handlers.allocatePayment}"
        .onCancel="${this.__handlers.close}"
        ?bottomPanelVisible="${this.__voidPaymentExists()}"
      ></neb-action-bar>
    `;
  }

  render() {
    return html`
      <div id="patient-payment-container" class="flex-page">
        ${this.__renderPaymentPostContent()}
        ${this.__renderRefundVoidPaymentContent()}
      </div>
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define('neb-payer-payment-post', NebPayerPaymentPost);
