import '../../neb-popup-header';

import { html, css } from 'lit';

import * as api from '../../../../../neb-api-client/src/patient-fee-schedules';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../../neb-styles/neb-variables';
import NebFormAssociateFeeSchedule from '../../forms/neb-form-associate-fee-schedule';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
};

class NebOverlayPatientAssociateFeeSchedule extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = NebFormAssociateFeeSchedule.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          const res = await api.add(this.model.patientId, model);
          this.isDirty = false;
          this.dismiss(res);
          store.dispatch(openSuccess('Fee schedule associated successfully'));
        } catch (err) {
          store.dispatch(
            openError('Fee schedule was not associated successfully'),
          );

          console.error(err);
        }
      },
    };
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Associate Fee Schedule"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <div class="description">
        Select a Fee Schedule to associate to this patient.
      </div>

      <neb-form-associate-fee-schedule
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .associatedItems="${this.model.feeSchedules}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-associate-fee-schedule>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-associate-fee-schedule',
  NebOverlayPatientAssociateFeeSchedule,
);
