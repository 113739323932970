export const SYSTEM_USER = Object.freeze({
  displayName: 'SYSTEM',
  id: '00000000-0000-0000-0000-000000000000',
});

export const CODE_PAYMENTS = Object.freeze({
  BALANCE_PAYMENT: {
    id: 'e277a4c8-cd02-4cae-a726-d001cade6036',
    code: 'Blnc',
    description: 'Balance Payment',
  },
  CAPITATED: {
    id: '04695178-7429-4623-8e82-8c803a4cb16e',
    code: 'Cap',
    description: 'Capitated',
  },
  COINSURANCE: {
    id: '00b83fea-8e88-4b85-a2b8-01662b314dd1',
    code: 'Coin',
    description: 'Coinsurance',
  },
  COPAY: {
    id: '31d52060-c037-431f-991e-de67160605af',
    code: 'CoP',
    description: 'Copay',
  },
  DEDUCTIBLE: {
    id: 'a92dcbed-f096-4928-bfab-62deaace710f',
    code: 'Ded',
    description: 'Deductible',
  },
  DISCOUNT: {
    id: 'b63b3cc8-c39e-43ab-a07d-46eea9cfc265',
    code: 'Dscnt',
    description: 'Discount',
  },
  FEE: {
    id: '525b318b-49f1-45ac-be65-0d1b0f55a6b8',
    code: 'Fee',
    description: 'Fee',
  },
  FEE_FOR_SERVICE: {
    id: '19187e2e-3ae9-443c-b627-787cd56eeeb0',
    code: 'FFS',
    description: 'Fee for Service',
  },
  GENERAL_PAYMENT: {
    id: '366a28a2-e0a8-4c85-b906-dcca1d143040',
    code: 'Gen',
    description: 'General Payment',
  },
  SUBSCRIPTION: {
    id: 'd1bf10e1-8d73-4643-8b93-dd5c07b9ab28',
    code: 'Sub',
    description: 'Subscription',
  },
  PACKAGE: {
    id: 'f41b0448-ecf3-42d0-b662-f05f19cd4c78',
    code: 'Pkg',
    description: 'Package',
  },
  PURCHASE: {
    id: 'f346b1cf-04ce-4aa1-b931-ad4de54f1991',
    code: 'Pur',
    description: 'Purchase',
  },
  ERA: {
    id: '0933ee61-5264-41ef-8d9f-e7c843b465ea',
    code: 'Ins',
    description: 'Insurance Payment',
  },
  'PR-00': {
    id: '338600b6-1c82-4369-bb4a-db3e9f04341b',
    code: 'PR-00',
    description: 'Review ERA Report for specific code.',
  },
  'PR-1': {
    id: '338600b6-1c82-4369-bb4a-db3e9f04341c',
    code: 'PR-1',
    description: 'Deductible Amount',
  },
  'PR-2': {
    id: '75ab589d-199f-4b01-a565-d6eb22982931',
    code: 'PR-2',
    description: 'Coinsurance Amount',
  },
  'PR-3': {
    id: '664935fe-5b6e-472e-a2e7-9a0e35172484',
    code: 'PR-3',
    description: 'Co-payment Amount',
  },
  'PR-22': {
    id: '51c9f6e1-8f7a-4e1f-a974-58c19cc08997',
    code: 'PR-22',
    description:
      'This care may be covered by another payer per coordination of benefits.',
  },
  'PR-26': {
    id: '9d8e30da-7fb8-419f-ae12-3a18a164c2d0',
    code: 'PR-26',
    description: 'Expenses incurred prior to coverage.',
  },
  'PR-27': {
    id: 'e6e284d5-a5a4-49fb-a414-90b252e37928',
    code: 'PR-27',
    description: 'Expenses incurred after coverage terminated.',
  },
  'PR-33': {
    id: '92d7cc4e-53c8-41bc-bf2c-fcad4a6246df',
    code: 'PR-33',
    description: 'Insured has no dependent coverage.',
  },
  'PR-45': {
    id: '1c6d4cfa-4ce9-424d-b90e-773e8e20ec88',
    code: 'PR-45',
    description: 'Contractual Agreement/Negotiated Rate.',
  },
  'PR-96': {
    id: 'b106b63b-a427-480a-a4ab-4f0db6c2f1a3',
    code: 'PR-96',
    description: 'Non-covered charge(s).',
  },
  'PR-119': {
    id: 'a057390a-d3c5-4726-9443-0731a12b3958',
    code: 'PR-119',
    description:
      'Benefit maximum for this time period or occurrence has been reached.',
  },
  'PR-170': {
    id: 'b638b039-0eda-4108-8748-5fdebebab359',
    code: 'PR-170',
    description:
      'Payment is denied when performed/billed by this type of provider.',
  },
  'PR-187': {
    id: '7a013b38-6efb-418c-8a65-6906091a6a64',
    code: 'PR-187',
    description:
      'Consumer Spending Account payments (includes but is not limited to Flexible Spending Account, Health Savings Account, Health Reimbursement Account, etc.)',
  },
  'PR-197': {
    id: '36180875-625b-44fa-93fb-8ac18947e872',
    code: 'PR-197',
    description:
      'Precertification/authorization/notification/pre-treatment absent.',
  },
  'PR-200': {
    id: '293d7873-e981-444f-b850-f794d92c6bb0',
    code: 'PR-200',
    description: 'Expenses incurred during lapse in coverage',
  },
  'PR-204': {
    id: '2abf52c3-4236-4744-9a31-aa01b0eaafab',
    code: 'PR-204',
    description:
      'This service/equipment/drug is not covered under the patient’s current benefit plan.',
  },
  'PR-227': {
    id: 'ae0acba6-f703-47df-bd39-2dee4e383058',
    code: 'PR-227',
    description:
      'Information requested from the patient/insured/responsible party was not provided or was insufficient/incomplete.',
  },
  'PR-276': {
    id: '8983988f-cbef-430c-b763-862fe278bdb5',
    code: 'PR-276',
    description:
      'Services denied by the prior payer(s) are not covered by this payer.',
  },
  'PR-A1': {
    id: 'dff5d1fa-068b-491f-81aa-6f31551d90fb',
    code: 'PR-A1',
    description: 'Claim/Service denied.',
  },
  IP: {
    id: '68b18fe4-0302-11ed-b939-0242ac120002',
    code: 'IP',
    description: 'Incentive Payment',
  },
});

const WRITE_OFF_TYPE = Object.freeze({
  CONTRACTUAL: 'CO',
  OTHER_ADJUSTMENT: 'OA',
  PAYOR_INITIATED: 'PI',
  PATIENT_RESPONSIBILITY: 'PR',
});

export const CODE_WRITE_OFFS = Object.freeze({
  PACKAGE: {
    id: 'a2adef38-382e-48ce-be99-baed04e30bec',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: 'Pkg',
    description: 'Package or Subscription',
    forInsurance: false,
    forPatient: true,
    custom: false,
    active: true,
  },
  VIEW_ERA_REPORT: {
    id: '324d3eeb-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: '00',
    description: 'Review ERA Report for specific code.',
  },
  PRIOR_PAYER_ADJUDICATION: {
    id: '324d3fb6-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: '23',
    description:
      'The impact of prior payer(s) adjudication including payments and/or adjustments.',
  },
  MISSING_AUTHORIZATION: {
    id: '324d4034-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '197',
    description:
      'Precertification/authorization/notification/pre-treatment absent.',
  },
  CLAIM_DENIED: {
    id: '324d4104-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: 'A1',
    description: 'Claim/Service denied.',
  },
  PROCEDURE_CODE_MODIFIER_INCONSISTENT: {
    id: '324d41a2-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '4',
    description: 'The procedure code is inconsistent with the modifier used.',
  },
  INCOMPLETE: {
    id: '324d4214-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '226',
    description:
      'Information requested from the Billing/Rendering Provider was not provided or not provided timely or was insufficient/incomplete.',
  },
  NOT_COVERED_BY_BENEFIT_PLAN: {
    id: '324d427e-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '204',
    description:
      'This service/equipment/drug is not covered under the patient’s current benefit plan.',
  },
  DUPLICATE: {
    id: '324d42dd-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: '18',
    description: 'Exact duplicate claim/service.',
  },
  CLAIM_SPECIFIED_NEGOTIATED_DISCOUNT: {
    id: '324d4339-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '131',
    description: 'Claim specific negotiated discount.',
  },
  NON_COVERED_CHARGE: {
    id: '324d4394-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '96',
    description: 'Non-covered charge(s).',
  },
  NOT_AUTHORIZED_BY_NETWORK: {
    id: '324d43f0-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '243',
    description: 'Services not authorized by network/primary care providers.',
  },
  NOT_DEEMED_MEDICALLY_NECESSARY: {
    id: '324d4451-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '50',
    description:
      'These are non-covered services because this is not deemed a medical necessity by the payer. ',
  },
  NOT_PROVEN_EFFECTIVE: {
    id: '324d44ad-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '56',
    description:
      'Procedure/treatment has not been deemed proven to be effective by the payer.',
  },
  INCENTIVE_ADJUSTMENT: {
    id: '324d450e-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: '144',
    description: 'Incentive adjustment, e.g. preferred product/service.',
  },
  ALREADY_ADJUDICATED: {
    id: '328d1ab8-ea48-11eb-a355-0242ac180006',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '97',
    description:
      'The benefit for this service is included in the payment/allowance for another service/procedure that has already been adjudicated.',
  },
  NEGOTIATED_RATE: {
    id: '947c3da4-6ec0-432c-913b-35455a7fd6cf',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '45',
    description: 'Contractual Agreement/Negotiated Rate.',
  },
  COVERED_UNDER_CAPITATION: {
    id: '9a72840a-cc3c-4c66-9788-bab97beba499',
    codeGroup: WRITE_OFF_TYPE.CONTRACTUAL,
    code: '24',
    description:
      'Charges are covered under a capitation agreement/managed care plan',
  },
  WRITE_OFF: {
    id: '07ef5db7-bdbc-11ed-90e6-0242ac120005',
    codeGroup: WRITE_OFF_TYPE.OTHER_ADJUSTMENT,
    code: 'CT-WO',
    description: 'Write-Off',
  },
});

export const CODE_DISCOUNTS = Object.freeze({
  GENERAL: {
    id: '720ce770-ae5f-11ed-b467-26aca225fa88',
    code: 'GEN',
    description: 'General Discount',
    custom: false,
    active: true,
  },
});

export const BILLING_NOTE_TYPES = Object.freeze({
  CHARGE: 'charge',
  CLAIM: 'claim',
  INVOICE: 'invoice',
  PAYMENT: 'payment',
  STATEMENT: 'statement',
  SUPERBILL: 'superbill',
});

export const MERCHANT_PROVIDERS = Object.freeze({
  FISERV: 'Fiserv',
  GLOBAL_PAYMENTS: 'Global Payments',
});
