import * as pdfOptions from './neb-pdf-viewer-options';

export class ZoomProcessor {
  constructor(
    zoomMode = pdfOptions.DEFAULT_ZOOM_MODE,
    zoomValue = pdfOptions.DEFAULT_ZOOM_VALUE,
  ) {
    this.__zoomMode = zoomMode;
    this.__zoomValue = zoomValue;
  }

  get zoomMode() {
    return this.__zoomMode;
  }

  get zoomValue() {
    return this.__zoomValue;
  }

  zoomIn() {
    const value = this.__validateExtremeZoomValue(
      this.__zoomValue + this.__getZoomStep(this.__zoomValue),
    );

    this.setZoom(pdfOptions.ZoomMode.CUSTOM_ZOOM, value);
  }

  zoomOut() {
    const value = this.__validateExtremeZoomValue(
      this.__zoomValue - this.__getZoomStep(this.__zoomValue),
    );

    this.setZoom(pdfOptions.ZoomMode.CUSTOM_ZOOM, value);
  }

  setZoom(zoomMode, zoomValue, sizeOptions) {
    switch (zoomMode) {
      case pdfOptions.ZoomMode.ACTUAL_SIZE:
        this.__zoomMode = pdfOptions.ZoomMode.ACTUAL_SIZE;
        this.__zoomValue = 100;
        break;

      case pdfOptions.ZoomMode.PAGE_FIT:
        this.__zoomMode = pdfOptions.ZoomMode.PAGE_FIT;
        this.__zoomValue = this.__validateExtremeZoomValue(
          this.__getPageFitZoom(sizeOptions),
        );

        break;

      case pdfOptions.ZoomMode.PAGE_WIDTH:
        this.__zoomMode = pdfOptions.ZoomMode.PAGE_WIDTH;
        this.__zoomValue = this.__validateExtremeZoomValue(
          this.__getPageWidthZoom(sizeOptions),
        );

        break;

      case pdfOptions.ZoomMode.CUSTOM_ZOOM:
      case pdfOptions.ZoomMode.ZOOM:
        const value = this.__validateExtremeZoomValue(zoomValue);

        this.__zoomMode = this.__resolveZoomModeByValue(value);
        this.__zoomValue = value;
        break;

      default:
        throw Error(
          `${pdfOptions.TEXT_ZOOM_MODE} '${zoomMode}' ${
            pdfOptions.TEXT_IS_NOT_VALID
          }`,
        );
    }
  }

  getScale(viewWidth, viewHeight, pageWidth, pageHeight) {
    switch (this.__zoomMode) {
      case pdfOptions.ZoomMode.PAGE_FIT:
        if (viewWidth && pageWidth && viewHeight && pageHeight) {
          return this.__validateExtremeScaleValue(
            Math.min(viewWidth / pageWidth, viewHeight / pageHeight),
          );
        }

        return 1;

      case pdfOptions.ZoomMode.PAGE_WIDTH:
        if (viewWidth && pageWidth) {
          return this.__validateExtremeScaleValue(viewWidth / pageWidth);
        }

        return 1;

      case pdfOptions.ZoomMode.ACTUAL_SIZE:
        return 1;

      case pdfOptions.ZoomMode.ZOOM:
      case pdfOptions.ZoomMode.CUSTOM_ZOOM:
        return this.__zoomValue / 100;

      default:
        return 1;
    }
  }

  __validateExtremeScaleValue(scaleValue) {
    return this.__validateExtremeZoomValue(scaleValue * 100) / 100;
  }

  __validateExtremeZoomValue(zoomValue) {
    if (zoomValue == null) {
      throw Error(
        `${pdfOptions.TEXT_ZOOM_VALUE} '${zoomValue}' ${
          pdfOptions.TEXT_IS_NOT_VALID
        }`,
      );
    }

    if (zoomValue < pdfOptions.MIN_ZOOM) {
      return pdfOptions.MIN_ZOOM;
    }

    if (zoomValue > pdfOptions.MAX_ZOOM) {
      return pdfOptions.MAX_ZOOM;
    }

    return zoomValue;
  }

  __resolveZoomModeByValue(zoomValue) {
    if (pdfOptions.DEFAULT_ZOOM_VALUES.includes(zoomValue)) {
      return pdfOptions.ZoomMode.ZOOM;
    }

    return pdfOptions.ZoomMode.CUSTOM_ZOOM;
  }

  __getPageWidthZoom(sizeOptions) {
    return Math.floor((sizeOptions.view.width / sizeOptions.page.width) * 100);
  }

  __getPageFitZoom(sizeOptions) {
    const zoomValueWidth = Math.floor(
      (sizeOptions.view.width / sizeOptions.page.width) * 100,
    );
    const zoomValueHeight = Math.floor(
      (sizeOptions.view.height / sizeOptions.page.height) * 100,
    );
    return Math.min(zoomValueWidth, zoomValueHeight);
  }

  __getZoomStep(zoomValue) {
    const result = pdfOptions.ZoomSteps.find(
      item => zoomValue >= item.start && zoomValue <= item.end,
    );

    if (result) {
      return result.step;
    }

    throw Error(
      `${pdfOptions.TEXT_ZOOM_VALUE} '${zoomValue}' ${
        pdfOptions.TEXT_IS_NOT_VALID
      } `,
    );
  }
}
