import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/neb-toggle-button';

import { css, html } from 'lit';

import NebTable from '../../../packages/neb-lit-components/src/components/tables/neb-table';

export const ELEMENTS = {
  addButton: {
    selector: '[id^=add-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
};

const CONFIG = [
  {
    key: 'button',
    label: '',
    flex: css`0 0 180px`,
  },
  {
    key: 'name',
    label: 'Bundle Name',
    flex: css`1 0 0`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`2 0 0`,
  },
  {
    key: 'diagnosisCount',
    label: 'Diagnoses',
    flex: css`0 0 100px`,
  },
  {
    key: 'chargeCount',
    label: 'Charges',
    flex: css`0 0 100px`,
  },
];

class NebTableAddCodeBundleToEncounter extends NebTable {
  static get properties() {
    return {
      disableAdd: Boolean,
      selectedItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-data {
          word-break: break-word;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.disableAdd = false;
    this.config = CONFIG;
    this.selectedItems = [];

    this.onAddCodeBundle = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      addCodeBundle: (_, rowIndex) => {
        const codeBundle = this.model[rowIndex];
        this.onAddCodeBundle(codeBundle.id);
      },
    };
  }

  __renderButton(rowIndex, _value) {
    return html`
      <neb-toggle-button
        id="add-button-${rowIndex}"
        class="add-button"
        .buttonText="${'Add Bundle'}"
        .index="${rowIndex}"
        .disabled="${this.disableAdd}"
        .onClick="${this.handlers.addCodeBundle}"
      ></neb-toggle-button>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'button':
        return this.__renderButton(rowIndex, value);
      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-add-code-bundle-to-encounter',
  NebTableAddCodeBundleToEncounter,
);
