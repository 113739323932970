import '@polymer/polymer/polymer-legacy';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_FAMILY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_BLACK,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_5,
} from './neb-variables';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const baseTableStyles = css`
  .neb-table-cell {
    padding: 18px;
    word-break: break-all;
  }

  .neb-table-row .neb-table-cell:first-child {
    padding-left: ${CSS_SPACING};
  }

  .neb-table-row .neb-table-cell:last-child {
    padding-right: ${CSS_SPACING};
  }

  .neb-table-header {
    font-size: ${CSS_FONT_SIZE_BODY};
    font-family: ${CSS_FONT_FAMILY};
    font-weight: ${CSS_FONT_WEIGHT_BOLD};
    align-items: flex-end;
    color: ${CSS_COLOR_BLACK};
    padding-bottom: 2px;
    white-space: nowrap;
  }

  .neb-table-icon-width {
    flex: 0 0 50px;
  }

  .neb-table-small-width {
    flex: 1;
    min-width: 30px;
  }

  .neb-table-medium-width {
    flex: 2;
    min-width: 60px;
  }

  .neb-table-large-width {
    flex: 3;
    min-width: 100px;
  }

  .neb-table-extra-large-width {
    flex: 6;
    min-width: 150px;
  }

  .neb-table-align-end {
    flex: none;
    align-items: flex-end;
  }

  .neb-table-align-start {
    flex: none;
    align-items: flex-start;
  }

  .neb-table-row {
    display: flex;
    border-bottom: ${CSS_BORDER_GREY_2};
  }

  .neb-table-icon-column {
    min-width: 15px;
  }

  .neb-table-chevron-column {
    fill: ${CSS_COLOR_GREY_5};
    width: 24px;
    height: 24px;
    margin-right: ${CSS_SPACING};
    margin-top: 18px;
  }
`;
const nebTableStyles = () => html`
  <style>
    ${baseTableStyles}
  </style>
`;
_documentContainer.innerHTML = `
<dom-module id="neb-table-styles">
  <template>
    ${nebTableStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
export const renderNebTableStyles = nebTableStyles;
