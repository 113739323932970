import { format, getMonth, getYear } from 'date-fns';

export const formatCardName = raw => {
  if (!raw || !raw.cardType || !raw.maskedCardNumber) return '';
  return `${raw.cardType} ${raw.maskedCardNumber}`;
};

export const getDisplayExpireDate = paymentMethod =>
  format(paymentMethod.expiryDate, 'MM/YYYY');

export const getExpireDate = raw => {
  if (!raw.expiryYear || !raw.expiryMonth) return null;
  return new Date(`20${raw.expiryYear}-${raw.expiryMonth}-01T00:00:00`);
};

export const isExpired = date => {
  const now = Date.now();
  return (
    getYear(date) < getYear(now) ||
    (getYear(date) === getYear(now) && getMonth(date) < getMonth(now))
  );
};

export function mapToElectronicPaymentMethodModel(raw) {
  const expiryDate = getExpireDate(raw);
  return {
    ...raw,
    id: raw.id || '',
    merchantAccountId: raw.merchantAccountId || '',
    token: raw.token || '',
    name: formatCardName(raw),
    expiryDate,
    isExpired: isExpired(expiryDate),
    description: raw.description || '',
    sortOrder: raw.sortOrder || 0,
    deleted: raw.deleted || false,
  };
}

export function mapElectronicPaymentMethodsToModel(rawArray) {
  if (!rawArray) return [];
  return rawArray.map(raw => mapToElectronicPaymentMethodModel(raw));
}

export function mapToRawModel(mappedPaymentMethod) {
  return {
    id: mappedPaymentMethod.id,
    merchantAccountId: mappedPaymentMethod.merchantAccountId,
    token: mappedPaymentMethod.token,
    cardType: mappedPaymentMethod.cardType,
    expiryMonth: mappedPaymentMethod.expiryMonth,
    expiryYear: mappedPaymentMethod.expiryYear,
    maskedCardNumber: mappedPaymentMethod.maskedCardNumber,
    holderType: mappedPaymentMethod.holderType,
    holderId: mappedPaymentMethod.holderId,
    description: mappedPaymentMethod.description,
    sortOrder: mappedPaymentMethod.sortOrder,
    deleted: mappedPaymentMethod.deleted,
    isDefault: mappedPaymentMethod.isDefault,
  };
}
