import './neb-ripple';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_DISABLED,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  label: { id: 'label' },
  ripple: { id: 'ripple' },
};

class NebRadioButton extends LitElement {
  static get properties() {
    return {
      __active: {
        reflect: true,
        type: Boolean,
        attribute: 'active',
      },

      label: String,
      name: String,
      value: String,
      checked: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      noInk: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__active = false;

    this.checked = false;
    this.disabled = false;
    this.label = '';
    this.name = '';
    this.value = '';
    this.noInk = false;

    this.onSelect = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      endTransition: e => e.stopPropagation(),
      activate: () => {
        this.__active = true;
      },
      deactivate: () => {
        this.__active = false;
      },
      click: () => {
        if (!this.disabled) {
          this.onSelect(this.value);
          this.onChange({
            name: this.name,
            value: this.value,
          });
        }
      },
      keypress: e => {
        if (!this.disabled && (e.key === 'Enter' || e.key === ' ')) {
          this.onSelect(this.value);
          this.onChange({
            name: this.name,
            value: this.value,
          });
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('transitionend', this.__handlers.endTransition);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('transitionend', this.__handlers.endTransition);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: max-content;
        }

        :host([disabled]) {
          color: ${CSS_COLOR_DISABLED};
          pointer-events: none;
        }

        :host([disabled][checked]) .radio {
          border-color: ${CSS_COLOR_DISABLED};
        }

        :host([disabled]) .radio {
          border-color: ${CSS_COLOR_DISABLED};
        }

        :host([disabled][checked]) .radio-fill {
          background-color: ${CSS_COLOR_DISABLED};
        }

        .container {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: fit-content;

          outline: none;
          border: 0;
          background: transparent;
        }

        .container-button {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: space-around;
        }

        .radio {
          position: relative;
          display: flex;
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          margin: 10px;

          border: 2px solid ${CSS_COLOR_GREY_1};
          border-radius: 10px;
        }

        :host([noInk]) .radio {
          margin: 0;
        }

        :host([checked]) .radio {
          border: 2px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .radio-fill {
          display: inline-block;
          width: 100%;
          height: 100%;
          border: 3px solid ${CSS_COLOR_WHITE};
          border-radius: 8px;
          background-color: transparent;
          transform: scale(0);
          transition: transform 280ms ease-in;
        }

        :host([checked]) .radio-fill {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          transform: scale(1);
        }

        .label {
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          font-weight: var(--label-font-weight, normal);
        }
      `,
    ];
  }

  __renderRipple() {
    return !this.noInk
      ? html`
          <neb-ripple
            id="${ELEMENTS.ripple.id}"
            ?active="${this.__active}"
          ></neb-ripple>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        tabindex="${this.disabled ? -1 : 0}"
        @click="${this.__handlers.click}"
        @keypress="${this.__handlers.keypress}"
        @focus="${this.__handlers.activate}"
        @blur="${this.__handlers.deactivate}"
        @mouseover="${this.__handlers.activate}"
        @mouseleave="${this.__handlers.deactivate}"
      >
        <span class="radio">
          <span class="radio-fill"></span> ${this.__renderRipple()}
        </span>

        <label id="${ELEMENTS.label.id}" class="label">${this.label}</label>
      </div>
    `;
  }
}

window.customElements.define('neb-radio-button', NebRadioButton);
