import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tab-old';
import '../../../../neb-lit-components/src/components/neb-nav-list';
import './treatment-plan/neb-patient-treatment-plan-controller';
import './chart/neb-patient-chart-controller';
import './problem-list/neb-patient-problem-list-controller';
import './encounter/neb-patient-encounter-controller';

import { matchRouteSwitch, getParams } from '@neb/router';
import { html, css, LitElement } from 'lit';

import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { ProblemListService } from '../../../../neb-redux/services/problem-list';
import { navigate } from '../../../../neb-route/neb-route-state';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { EncounterService } from '../../services/encounter';

import { NebPatientTreatmentPlanState } from './treatment-plan/neb-patient-treatment-plan-state';

export const TAB_CHART = 'chart';
export const TAB_ENCOUNTERS = 'encounters';
export const TAB_PROBLEM_LIST = 'problem-list';
export const TAB_TREATMENT_PLAN = 'treatment-plan';
export const LABELS = {
  [TAB_TREATMENT_PLAN]: 'Treatment Plan',
  [TAB_PROBLEM_LIST]: 'Problem List',
  [TAB_ENCOUNTERS]: 'Encounters',
};
export const ELEMENTS = {
  chartController: {
    id: 'chart-controller',
  },
  clinicalContainer: {
    id: 'clinical-container',
  },
  clinicalTabs: {
    id: 'clinical-tabs',
    tag: 'neb-tabs-old',
  },
  encountersTab: {
    id: 'encounters',
  },
  problemListTab: {
    id: 'problem-list',
  },
  treatmentPlanTab: {
    id: 'treatment-plan',
  },
  encounterController: {
    id: 'encounter-controller',
  },
  problemListController: {
    id: 'problem-list-controller',
  },
  treatmentPlanController: {
    id: 'patient-treatment-plan',
  },
  navList: {
    id: 'nav-list',
  },
};

class NebPatientTabsClinicalController extends NebPatientTreatmentPlanState(
  LitElement,
) {
  static get properties() {
    return {
      __locations: Array,
      __tabsCount: {
        type: Object,
      },
      __activeProblems: {
        type: Array,
      },
      __problems: {
        type: Array,
      },
      __encounters: {
        type: Array,
      },
      layout: {
        type: String,
        reflect: true,
      },
      patientId: {
        type: String,
      },
      route: String,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        margin-right: ${CSS_SPACING};
        width: 100%;
        height: 100%;
        flex-direction: column;
      }

      .content {
        overflow-y: auto;
        background-color: ${CSS_COLOR_WHITE};
        flex: 1 0 0;
      }

      .tabs {
        padding: 10px ${CSS_SPACING} 0 ${CSS_SPACING};
        background-color: ${CSS_COLOR_WHITE};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        flex: 0 0 auto;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initServices();

    this.__initHandlers();
  }

  __initState() {
    this.__locations = [];

    this.__tabsCount = {
      [TAB_PROBLEM_LIST]: 0,
      [TAB_TREATMENT_PLAN]: 0,
      [TAB_ENCOUNTERS]: 0,
    };

    this.__navItems = this.genNavItems();
    this.__problems = [];
    this.__encounters = [];
    this.__activeProblems = [];

    this.layout = '';
    this.patientId = '';
    this.route = '';
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__problemListService = new ProblemListService(problems => {
      this.__problems = problems;
      this.__activeProblems = problems.filter(p => p.status === 'active');

      this.__updateTabCount(TAB_PROBLEM_LIST, this.__problems.length);
    });

    this.__encounterService = new EncounterService((encounters, count) => {
      this.__encounters = encounters.map(e => {
        const locationName = getLocationValue(
          this.__locations,
          e.locationId,
          LOCATION_KEYS.NAME,
        );

        return { ...e, locationName };
      });

      this.__updateTabCount(TAB_ENCOUNTERS, count);
    });
  }

  __initHandlers() {
    this.__handlers = {
      saveProblems: problems => this.__problemListService.save(problems),
      encounterStatusChanged: (encounters, shouldCount = false) => {
        this.__encounters = encounters;
        if (shouldCount) this.__updateEncounterPlanLabelWithCount();
      },
      navigateToProblemList: () =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/clinical/problem-list`),
        ),
      addProblem: () => {
        this.__handlers.navigateToProblemList();

        setTimeout(() => {
          document.scrollingElement.scrollTo(
            0,
            document.scrollingElement.scrollHeight,
          );
        }, 0);
      },
      navigateToEncounters: () =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/clinical/encounters`),
        ),
      selectTab: selectedTab =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/clinical/${selectedTab}`),
        ),
      selectNavItem: index => {
        const { name } = this.__navItems[index];
        store.dispatch(
          navigate(`#/patients/${this.patientId}/clinical/${name}`),
        );
      },
      navigatetoTreatmentPlan: () => {
        store.dispatch(
          navigate(`#/patients/${this.patientId}/clinical/treatment-plan`),
        );
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();

    this.__problemListService.connect();

    this.__encounterService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();

    this.__problemListService.disconnect();

    this.__encounterService.disconnect();
  }

  genNavItems() {
    return [
      {
        exact: false,
        label: 'Chart',
        name: TAB_CHART,
        path: `/${TAB_CHART}/`,
        resolver: () => html`
          <neb-patient-chart-controller
            id="${ELEMENTS.chartController.id}"
            class="content"
            .patientId="${this.patientId}"
            .activeProblems="${this.__activeProblems}"
            .onNavigateToProblemList="${this.__handlers.navigateToProblemList}"
            .onNavigateToEncounters="${this.__handlers.navigateToEncounters}"
            .onNavigateToTreatmentPlan="${this.__handlers
              .navigatetoTreatmentPlan}"
            .onAddProblem="${this.__handlers.addProblem}"
            .recentEncounters="${this.__encounters.slice(0, 5)}"
          ></neb-patient-chart-controller>
        `,
      },
      {
        exact: false,
        label: this.__formatTabLabel(TAB_PROBLEM_LIST),
        name: TAB_PROBLEM_LIST,
        path: `/${TAB_PROBLEM_LIST}/`,
        resolver: () => html`
          <neb-patient-problem-list-controller
            id="${ELEMENTS.problemListController.id}"
            class="content"
            .problems="${this.__problems}"
            .onSave="${this.__handlers.saveProblems}"
            .small="${this.layout === 'small'}"
          ></neb-patient-problem-list-controller>
        `,
      },
      {
        exact: false,
        label: this.__formatTabLabel(TAB_TREATMENT_PLAN),
        name: TAB_TREATMENT_PLAN,
        path: `/${TAB_TREATMENT_PLAN}/`,
        resolver: () => html`
          <neb-patient-treatment-plan-controller
            id="${ELEMENTS.treatmentPlanController.id}"
            class="content"
            .patientId="${this.patientId}"
            ?small="${this.layout === 'small'}"
          ></neb-patient-treatment-plan-controller>
        `,
      },
      {
        exact: false,
        label: this.__formatTabLabel(TAB_ENCOUNTERS),
        name: TAB_ENCOUNTERS,
        path: `/${TAB_ENCOUNTERS}/`,
        resolver: () => html`
          <neb-patient-encounter-controller
            id="${ELEMENTS.encounterController.id}"
            class="content"
            .layout="${this.layout}"
            .encountersModel="${this.__encounters}"
            .patientId="${this.patientId}"
            .route="${this.route}"
            .onEncounterStatusChanged="${this.__handlers
              .encounterStatusChanged}"
          ></neb-patient-encounter-controller>
        `,
      },
    ];
  }

  update(changedProps) {
    if (changedProps.has('layout') || changedProps.has('route')) {
      const { tab } = getParams('/:tab', this.route);

      if (this.layout !== 'small' && !tab) {
        store.dispatch(navigate(`#/patients/${this.patientId}/clinical/chart`));
      }
    }

    super.update(changedProps);
  }

  updated(changed) {
    this.__elements = {
      problemList: this.shadowRoot.getElementById(
        ELEMENTS.problemListController.id,
      ),
    };

    if (changed.has('_activeTreatmentPlan')) {
      this.__updateTreatmentPlanLabelWithCount();
    }

    if (changed.has('patientId')) {
      this.__problemListService.update(this.patientId);
      this.__encounterService.update(this.patientId);
    }

    super.updated(changed);
  }

  __updateTreatmentPlanLabelWithCount() {
    this.__updateTabCount(
      TAB_TREATMENT_PLAN,
      this._activeTreatmentPlan.treatmentPhases.length,
    );
  }

  __updateEncounterPlanLabelWithCount() {
    this.__updateTabCount(TAB_ENCOUNTERS, this.__encounters.length);
  }

  __formatTabLabel(tab) {
    const label = LABELS[tab];

    if (this.__tabsCount) {
      return this.__tabsCount[tab] > 0
        ? `${label} (${this.__tabsCount[tab]})`
        : label;
    }

    return '';
  }

  __updateTabCount(tab, count) {
    this.__tabsCount = { ...this.__tabsCount, [tab]: count };
    this.__navItems = this.genNavItems();
  }

  __renderBig() {
    const selected = this.route.split('/')[1];
    return html`
      <neb-tabs-old
        id="${ELEMENTS.clinicalTabs.id}"
        class="tabs"
        ?small="${this.layout === 'small'}"
        .selected="${selected}"
        .onChange="${this.__handlers.selectTab}"
      >
        <neb-tab-old name="${TAB_CHART}">Chart</neb-tab-old>
        <neb-tab-old
          id="${ELEMENTS.problemListTab.id}"
          name="${TAB_PROBLEM_LIST}"
          >${this.__formatTabLabel(TAB_PROBLEM_LIST)}</neb-tab-old
        >
        <neb-tab-old
          id="${ELEMENTS.treatmentPlanTab.id}"
          name="${TAB_TREATMENT_PLAN}"
          >${this.__formatTabLabel(TAB_TREATMENT_PLAN)}</neb-tab-old
        >
        <neb-tab-old id="${ELEMENTS.encountersTab.id}" name="${TAB_ENCOUNTERS}"
          >${this.__formatTabLabel(TAB_ENCOUNTERS)}</neb-tab-old
        >
      </neb-tabs-old>
    `;
  }

  getSelectedIndex() {
    return this.__navItems.findIndex(item =>
      this.route.startsWith(`/${item.name}`),
    );
  }

  __renderSmall() {
    const selectedIndex = this.getSelectedIndex();

    const items = this.__navItems.map(item => item.label);

    return selectedIndex === -1
      ? html`
          <neb-nav-list
            id="${ELEMENTS.navList.id}"
            class="list"
            .selectedIndex="${selectedIndex}"
            .items="${items}"
            .onSelect="${this.__handlers.selectNavItem}"
          ></neb-nav-list>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-tabs-clinical-controller',
  NebPatientTabsClinicalController,
);
