const isEmpty = value => value === null || value.toString().length === 0;

export const NEXT_APPOINTMENT_TYPE = Object.freeze({
  ON: 'onDay',
  IN: 'inTime',
});

export function mapNextAppointmentToServerModel(model) {
  return {
    id: model.id,
    onDay: model.onDay,
    inTime: model.inTime,
    inTimeType: model.inTimeType,
    enableExtraTime: model.enableExtraTime,
    extraMinutes: model.extraMinutes,
    note: model.note,
    encounterId: model.encounterId,
  };
}

export const mapServerModelToNextAppointment = model => {
  const nextAppointment = {
    id: model.id,
    onDay: model.onDay,
    inTime: isEmpty(model.inTime) ? '' : model.inTime,
    inTimeType: model.inTimeType,
    enableExtraTime: model.enableExtraTime,
    extraMinutes: model.extraMinutes,
    type: isEmpty(model.onDay)
      ? NEXT_APPOINTMENT_TYPE.IN
      : NEXT_APPOINTMENT_TYPE.ON,
    note: model.note,
    encounterId: model.encounterId,
  };
  return nextAppointment;
};
