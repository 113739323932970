import { postprocessBodySectionsSvg } from './utils';

const BODY_SECTIONS_SVG = `
  <?xml version="1.0" encoding="utf-8"?>
  <!--
    Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)
  -->
  <svg
    version="1.1"
    id="BODY_LAYERS"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 109.2 205.6"
    style="enable-background:new 0 0 109.2 205.6;"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: none;
        stroke: #545454;
        stroke-width: 0.1;
        stroke-linejoin: round;
      }
      .st1 {
        fill: #bf3131;
      }
      .st2 {
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
        stroke-dasharray: 2, 2, 2, 2;
      }
      .st3 {
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
        stroke-dasharray: 2, 2;
      }
      .st4 {
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
        stroke-dasharray: 2, 2, 2, 2, 2, 2;
      }
      .st5 {
        fill: none;
      }
      .st6 {
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
        stroke-dasharray: 2, 2.25, 2, 2;
      }
    </style>
    <g id="BODY_CORE">
      <g>
        <path
          class="st0"
          d="M54.6,4.9L54.6,4.9l-1,0.1l0,0L54.6,4.9h1.1 M56.8,5.2l-1.1-0.3V5h-1.1 M54.6,5v0.4h1.1V5l1.1,0.1 M52.4,5.2
			L53.5,5v0.4h1.1V6h1.1V5.4l1.1,0.1V5.2l0,0l1,0.2l-1.1-0.2 M53.5,5l-1.1,0.2 M51.4,5.4l1.1-0.2l0,0 M57.8,5.4v0.3l-1.1-0.2v0.6
			l-1-0.1v1h-1.1V6h-1.1V5.4l-1.1,0.1V5.2l-1.1,0.2 M57.8,5.4l1,0.4 M50.9,5.6l0.5-0.2v0.3l-1,0.3V5.8L50.9,5.6l0.5-0.2l0,0
			 M58.9,5.8V6l-1-0.3L58,6.3l-1.1-0.2v1L55.7,7v1.1h-1.1V7h-1.2l0.1-1l-1.1,0.1V5.5l-1.1,0.2v0.6l1.1-0.2V7h1.1v1.1h1.1v1h-1.2
			l0.1-1l-1.1,0.1l0.1-1.1l-1.1,0.2v-1l-1,0.3V6.1l-1,0.4V6.3l1-0.5 M58.8,5.8l1,0.5v0.2l-1-0.4v0.6l-1-0.3v0.9l-1-0.2l0.1,1h-1.1v1
			h-1.2v1h-1.2v-1h-1.1l0.1-1l-1,0.1v-1l-1,0.2V6.6l-1,0.4V6.4L48.6,7V6.9l0.8-0.6 M59.9,6.4L59.9,6.4v0.5L59,6.6v0.8l-1-0.2v1L57,8
			l0.1,1H56l0,0 M59.9,6.5l0.8,0.6V6.9l-0.8-0.6 M47.7,7.7l0.8-0.8 M55.9,9.1v1h-1.3v1.1h-1.2v-1.1h-1.2v-1l-1,0.1v-1l-1,0.2v-1
			l-0.9,0.3L49.4,7l-0.8,0.5V7.1l-0.8,0.7 M62.1,8.7l-0.7-1 M60.7,6.9l0.8,0.8v0.1l-0.1,0.4l-0.8-0.7v0.8l-0.9-0.4v1l-0.9-0.3v1
			l-1-0.2v1l-1-0.1v1h-1.2v-1.1h1.1v-1l1,0.1v-1l0.9,0.2v-1l1,0.3V7l0.8,0.5l0.1-0.4l0.8,0.7l0.7,1 M47,8.7l0.7-1v0.1 M47,8.7
			l-0.5,1.2l0,0L47,8.7L47,8.7L47.2,9l0.7-0.8L48,8.7l0.7-0.6V9l0.7-0.3l0.1,1l0.8-0.2v1l0.8-0.2v1l1-0.1v1h1.1v-1h-1.1l-0.1-1
			l-1,0.1v-1l-0.9,0.2v-1l-0.9,0.3v-1l-0.8,0.4l-0.1-0.7l-0.7,0.7l-0.2-0.4L47,8.7 M47.4,9.4L47.4,9.4l-0.5,1L46.7,10l-0.4,1.3H46
			l0.4-1.4l0.2,0.2L47.2,9L47.4,9.4L48,8.8l0.1,0.8l0.7-0.5l0.1,1l0.7-0.4l0.1,1l0.8-0.2l0.1,1.1l0.8-0.2v1l0.9-0.1v0.8h1l0.1-0.8
			l1.2,0.2v-1.2h1.2l0.1,1H57v-1l1,0.1l0.1-1l0.8,0.2l0.1-1l0.8,0.2v-1l0.7,0.3l0.1-0.9l0.7,0.5l0.1-0.6L62,9l0.1-0.3l0,0l0.6,1.3
			h-0.1L62,8.8 M61.2,8.7l-0.1,0.8v0.1l0.5,0.6l0.2-0.6l0.5,1l0.2-0.4l0.4,1.2l0.2-0.1l-0.3-1.4l-0.2,0.2L62,9l-0.2,0.5L61.2,8.7
			 M61.1,9.5l-0.6-0.4l-0.1,1l-0.8-0.3l-0.1,1l-0.8-0.2l-0.1,1l-0.8-0.2v1l-1-0.1L57,13h-1v-0.8l-1.3,0.2l0,0l-0.4,0.7l-1-0.1
			l-0.2,0.7h0.8l0.3-0.6l0.4,0.3l0.5-0.3l-0.5-0.7 M47.4,9.5l0.2,0.7l0.6-0.6l0.1,0.9l0.6-0.4l0.1,1l0.7-0.3l0.1,1.1l0.8-0.2l0.1,1
			l0.8-0.2v0.8l0.9-0.1l-0.1,0.7l1-0.1L53,14.3h0.8l0.2-0.6l0.5,0.1l0.2-0.4l0.2,0.4l0.5-0.1l-0.2-0.6L56,13l0.2,0.7h-0.9l0.2,0.6
			H55l-0.2-0.5l-0.2,0.1l-0.2-0.1l-0.2,0.5h-0.5l-0.1,0.6l0,0h0.5l0.1-0.5h0.4V14 M46.3,11.3L46.3,11.3l0.2,0.2l0.3-1.1l0.2,0.5
			l0.4-0.8l0.2,0.7l0.5-0.5l0.1,1l0.6-0.4L49,12l0.7-0.3l0.1,1l0.8-0.2v0.8l0.8-0.2v0.7l0.8-0.1v0.6l0.8-0.1v0.5l0.2,0.4l0.5,0.1
			h0.4l0,0v-0.5h0.4v0.5h0.4v-0.5h-0.4v-0.5H55l0.1,0.5h0.5l-0.1-0.6l0.8,0.1l-0.2-0.7h1v-0.7l0.8,0.1v-0.8l0.8,0.2l0.1-1l0.8,0.3
			l0.1-1l0.7,0.3l0.1-1l0.6,0.4l0.1-0.8 M62.9,11.4L62.9,11.4l-0.2,0.2l-0.4-1.1L62.2,11l-0.5-0.8L61.6,11L61,10.4l-0.1,1L60.3,11
			l-0.1,1.1l-0.7-0.3v1l-0.8-0.3v0.8l-0.8-0.1v0.7L57,13.7l0,0 M47.6,11v0.1l-0.4,0.7L47,11.1V11 M62,11v0.1l-0.2,0.6L61.5,11v-0.1
			 M46.2,11.4L46,12.9h-0.3l0.2-1.6h-0.1l0.5-1.4 M62.8,11.4l0.2,1.4h0.3l-0.2-1.6l0.2,0.1l-0.5-1.5 M47.1,11.1l-0.3,1l-0.2-0.4
			v-0.1 M61.6,11l-0.2,0.9l0.4,0.5l0.2-0.7v-0.1 M62.1,11.1l0.3,1l0.2-0.4v-0.1 M57.1,13.7l0.1,0.6h0.7v-0.6l0.7,0.1v-0.6l0.8,0.3
			v-0.8l0.7,0.3l0.1-1l0.6,0.4l0.5,0.5l0.2-1L61,11.4l-0.2,1l-0.1,1L60.1,13v0.7l-0.6-0.2V14l-0.8-0.1v0.5h-0.8l-0.1,0.5h0.7
			l0.1-0.4l0.8,0.1V14l0.7,0.2v-0.5l0.5,0.3v-0.6l0.4,0.4l0.1-0.9l0.3,0.5l0.2-0.9v-0.1 M47.7,11l0.1,0.9l0.5-0.5l0.2,1.1l0.6-0.4
			l0.1,1l0.7-0.3v0.7l0.8-0.2v0.6h0.7v0.6h0.8l0.1,0.6L51.4,15l-0.1-0.5h-0.7v-0.6l-0.8,0.2v-0.6l-0.7,0.3v-0.7l-0.6,0.4l-0.1-1
			L47.9,13l-0.2-1l-0.3,0.5l-0.2-0.7L47,12.5l-0.2-0.4l-0.1,1.1L46.4,13l0.1-1.3 M62.6,11.7l0.2,1.2V13l-0.2,0.2L62.5,12l-0.2,0.5
			L62,11.7 M47,12.5v0.1l0.2,0.6l0.2-0.7v-0.1 M61.7,12.5l0.2,0.7l0.2-0.6v-0.1 M46.4,13v1.4h-0.3v-1.6L46.4,13 M62.7,13v1.4H63
			v-1.6L62.7,13 M46.6,13.2v0.1v1.2l-0.2-0.1v1.5l0,0L46.2,16v-1.6l-0.3,0.1v-1.6L45.8,13l0.2-1.6 M62.6,13.2L62.6,13.2v1.3l0.2-0.1
			v1.5L63,16v-1.6l0.3,0.1l-0.1-1.7l0.2,0.2l-0.2-1.7 M47.4,12.5l0.2,1l0.4-0.6l0.1,1l0.5-0.4v0.6l0.6-0.3v0.5l0.7-0.2l0.1,0.5
			l0.8-0.1l0.1,0.4h0.7l0.1,0.4h-0.6L50.9,15L50,15l-0.1-0.4l-0.7,0.2l-0.1-0.5l-0.6,0.3v-0.5l-0.4,0.4v-0.6l-0.3,0.4l-0.2-0.8
			l-0.2,0.4l-0.2-0.7l-0.1,0.7l-0.2-0.3l0.1-1 M47.1,13.9V14v0.8l-0.2-0.2v1.2h-0.3v-1.3l0.3,0.2v-1.1l-0.2-0.3 M62.2,12.6l0.1,1
			l0.3-0.3 M62.1,14L62.1,14v0.9l0.2-0.2v1.2h0.2v-1.3l-0.2,0.1v-1.1L62.1,14L62,13.2L61.9,14l-0.3-0.5l-0.1,1l-0.4-0.6v0.6L60.7,14
			v0.5l-0.6-0.4v0.5l-0.7-0.1L59.3,15l-0.8-0.1l-0.2,0.3h-0.7l0.2-0.4L57,14.9l-0.7-0.1v-0.5h0.8L57,14.9v0.4l0.6-0.1l-0.3,0.2h0.6
			l0.4-0.3l0.8,0.1l0.2-0.3l0.7,0.2l0.1-0.4l0.5,0.3l0.1-0.4l0.4,0.5v-0.5v-0.1 M47.1,14l0.1,0.2l0.1-0.2v-0.2 M62,14l-0.2,0.2V14
			l0,0 M47.4,14l0.1,0.8l0.3-0.4v-0.2 M61.8,14l-0.1,0.8l-0.2-0.4l0,0 M48.2,14.5v0.1V15l0.4-0.4l0.1,0.4l0.6-0.3l0.1,0.4l0.7-0.2
			l0.2,0.3l0.8-0.1l0.3,0.3H52l-0.3-0.3l0.7,0.1V15l0,0l0.7-0.1h0.7 M45.8,14.7l0.1,1.4H46l0.1,1.1v0.1l0.2,0.3V16H46l-0.1-1.5
			L45.8,14.7V13 M62,14.3V15l0.2-0.1v1h0.2V17l0.2,0.1v-1.2l0.2,0.1v1.4l0,0l0.2,0.2V16h0.2l0.1-1.5l0.2,0.1V13 M55.6,14.8l-0.1,0.5
			l0.5-0.1l0.2-0.4H55.6 M48.1,14.6l-0.4,0.6l-0.2,0.4v-0.7L47.3,15v0.9h-0.2v1V17h-0.2v-1.1h0.2v-1l0.2,0.1v-0.7 M61.8,14.8v0.7
			v0.1l0.2,0.2V15L61.8,14.8 M61.2,14.6l0.3,0.6v-0.7 M51.2,15.5h-0.9l-0.2-0.3l-0.7,0.2l-0.1-0.3l-0.6,0.3L48.6,15l-0.4,0.5
			L48.1,15l-0.3,0.6v-0.4v-0.7 M52.3,15l0.8,0.4l0.7,0.3v-0.4l-0.1-0.5 M56.1,15.2l0.2,0.2l-0.8,0.3l0.1-0.4h-0.5L55,15.7h-0.4v-0.4
			h-0.4 M61.8,15.6l-0.3-0.5v0.4L61.2,15v0.4L60.7,15l-0.1,0.4L60,15.1l-0.1,0.3l-0.8-0.2L59,15.6h-1v0.2h0.8l0.2-0.2l0.7,0.2
			l0.2-0.3l0.6,0.3l0.1-0.3l0.4,0.4l0.1-0.3l0.3,0.6l0.1-0.3v-0.2 M52.3,15.6l0.5,0.2h-0.3L52,15.6H52.3v-0.2l0.7,0.2l0.1-0.2
			l0.2-0.2 M55.5,15.7L55.5,15.7l0.3,0.2l0.6-0.3l0.2,0.2h0.3l0.6-0.2H57v-0.2l-0.7,0.2l-0.1-0.2L57,15 M51.3,15.5L51.3,15.5v0.2
			h-0.8l-0.2-0.2l-0.7,0.2l-0.2-0.2l-0.6,0.2l-0.2-0.3l-0.4,0.4l-0.1-0.3L47.8,16l-0.1-0.3v-0.2 M53.7,15.6L53.7,15.6h0.5l0.1,0.4
			h0.3v-0.4h-0.3v-0.4l-0.1-0.1 M55,15.6L54.9,16l0.3,0.1l0.2-0.4L55,15.6 M47.4,15.7v0.8v0.1l0,0l-0.2,0.2l0.1-0.9L47.4,15.7v-0.1
			 M52.9,15.6l0.6,0.3L53.2,16l-0.5-0.2L52.9,15.6 M58,15.5l-0.9,0.3h-0.3L56.2,16H56l-0.3,0.3l0,0l-0.2-0.1l-0.1,0.4l-0.2-0.2v0.4
			L55,16.7V17h-0.2v-0.4h0.3v-0.3h-0.3v0.3h-0.2v-0.3l-0.2,0.1L54,16.1l-0.2,0.1l-0.3-0.4l0.3-0.2L54,16l0.3-0.1v0.3h0.3V16h0.3v0.4
			l0.2,0.1l0.1-0.3l0.2,0.1l0.3-0.4L56,16l0.5-0.2l0.5-0.2 M47.4,16.6l0.2,0.2l0.2-0.7l0.2,0.2l0.2-0.5l0.2,0.3l0.4-0.4l0.2,0.2
			l0.6-0.2l0.2,0.2l0.7-0.2l0.1,0.2h0.7l-0.1-0.3l0.7,0.2l0.2-0.1l0.7,0.3H53l0.3,0.2h0.2l-0.3-0.3H53l-0.6-0.2h-0.3l-0.9-0.3
			 M61.7,15.6v0.8l0.2,0.2l-0.1-0.9H62l0.1,1L62,16.6l0.1,0.7h-0.2l-0.1-0.7l0,0l-0.3-0.8 M61.8,16.6l-0.2,0.2L61.4,16l-0.2,0.3
			L61,15.8l-0.2,0.3l-0.4-0.4l-0.2,0.2l-0.6-0.2l-0.2,0.2l-0.7-0.2L58.6,16h-0.7l0.1-0.2L57.3,16l-0.1-0.1l-0.7,0.3L56.2,16
			l-0.3,0.2h-0.2 M46.4,15.9l0.2-0.1V17l0.2-0.1l0.1-0.1 M63.4,16l-0.2,1l0,0v-1H63.4l0.1-1.4 M53.4,16.2l-0.1,0.1L52.8,16l-0.2,0.1
			L52,15.9v0.2L51.3,16v0.2h-0.7V16l-0.7,0.1l-0.2-0.2l-0.6,0.2L49,15.9l-0.4,0.3L48.4,16l-0.3,0.4L48,16.3l-0.2,0.5l-0.2-0.1
			l-0.1,0.5h0.3l0.1-0.4l0.2,0.1l0.1-0.4l0.2,0.2l0.2-0.4l0.2,0.2l0.4-0.3l0.2,0.2l0.6-0.2l0.1,0.2l0.6-0.1l0.1,0.4h0.6v-0.3
			l0.5,0.2l0.1-0.3l0.5,0.3l0.2-0.2l0.4,0.4l0.3-0.2l0.4,0.3l-0.1-0.4H54l0.1,0.4l0.2-0.1v0.4l0.2-0.1V17h0.2v0.4h-0.3V17l-0.3,0.1
			v-0.4L53.8,17v-0.4l-0.3,0.1l0,0l-0.3,0.2L53,16.5l-0.3,0.2l-0.4-0.4l-0.1,0.3l-0.4-0.3l-0.1,0.4l-0.4-0.2h-0.1h-0.1H51h-0.2l0,0
			h-0.5l-0.1-0.2l-0.5,0.2l-0.1-0.2L49,16.7l-0.2-0.2l-0.3,0.4l-0.2-0.2l-0.1,0.2H48v0.4h-0.2v0.4l0.3-0.1v-0.4l0.3-0.1l-0.1-0.3
			 M58.6,16l-0.1,0.3l0.7,0.1l0.1-0.2l0.6,0.2l0.2-0.2l0.4,0.3l0.2-0.2l0.2,0.4l0.2-0.2l0.1,0.4l0.2-0.1l0.1,0.4h0.2l-0.1-0.5
			l-0.2,0.1l-0.2-0.5L61,16.5L60.8,16l-0.2,0.2l-0.4-0.3L60,16.1l-0.6-0.2l-0.2,0.2L58.6,16 M55.8,16.2l0.1,0.1l0.4-0.3l0.2,0.1
			l0.2,0.2l0.5-0.2l0.1,0.3l0.5-0.2v0.3l0,0h0.6v-0.3h-0.6V16l-0.6,0.2L57.1,16l-0.6,0.3l-0.4,0.3l-0.2-0.2l-0.4,0.3l0.1-0.4
			 M47.4,16.5l0.3-0.7 M57.9,16.5L57.9,16.5l0.2,0.1h0.2h0.1v-0.1l0.6,0.1l0.1-0.2l0.5,0.2l0.1-0.2l0.4,0.3l0.2-0.2l0.3,0.4l0.2-0.2
			l0.1,0.2h0.2v0.4l-0.2-0.1l-0.2-0.3L60.5,17l-0.3-0.4l-0.1,0.2l-0.4-0.3l-0.1,0.2L59,16.6l0,0h-0.2h-0.3 M50.2,16.6L50.2,16.6
			L50.2,16.6L50.2,16.6h-0.3l-0.2,0.1h-0.1l-0.4,0.2l-0.1-0.1L48.8,17l-0.2-0.1l-0.3,0.5v-0.2l0.2-0.3l0.1,0.2l0.3-0.4l0.1,0.2
			l0.4-0.3l0.1,0.2L50.2,16.6L50.2,16.6h0.3h0.3 M50.7,16.6L50.7,16.6L50.7,16.6h0.2v-0.1 M51.1,16.5L51.1,16.5l-0.2,0.2v-0.2
			 M57.8,16.5l-0.4,0.2l-0.1-0.4l-0.4,0.3l-0.1-0.3l-0.3,0.3l-0.3-0.2L56,16.9l-0.4-0.2l-0.2-0.1V17l-0.2-0.2v0.3L54.9,17v0.4h-0.3
			v0.5h-0.3v-0.5L54,17.6v-0.4l-0.2,0.2V17l-0.3,0.2v-0.5 M50.2,16.6L50.2,16.6L50.2,16.6L50.2,16.6 M58.5,16.5L58.5,16.5h-0.2v0.2
			L58,16.6l0.1-0.1 M59,16.6L59,16.6L59,16.6L59,16.6 M59.7,16.8L59.7,16.8l-0.1-0.1L59.7,16.8h0.2H60l-0.3-0.2h-0.1h-0.2l-0.2-0.1
			v0.2l0.2-0.1 M53.9,17.3L53.9,17.3l-0.4,0.4l0.1-0.6l-0.3,0.1v-0.4L53,16.9l-0.2-0.3l-0.2,0.2l-0.3-0.2l-0.2,0.3l-0.3-0.3
			l-0.1,0.1l-0.2-0.1l-0.2-0.2 M55.7,16.6v0.5l0.3,0.1l0.1-0.4l0.3,0.1l0.2-0.3l0.2,0.2l0.3-0.3l0.1,0.3l0.3-0.2h0.1l0,0h0.1
			l0.3-0.2 M57.7,16.6L57.7,16.6 M49.9,16.5l0.2,0.1v-0.2 M51.1,16.5l0.1,0.2h0.2l0,0 M47,16.6h0.2l-0.1,0.7l0.2-0.2l0.1-0.8
			 M50,16.7l0.1,0.1h0.4l-0.4-0.2L50,16.7h-0.2h-0.1h0.1 M58.1,16.6L58,16.8h-0.2V17H58v-0.2h0.2l0.1-0.1l0,0 M51,16.8V17h0.3v-0.2
			H51L51,16.8L50.5,17l0.2-0.4 M57.5,16.9V17h-0.1L57.5,16.9L57.5,16.9 M49.5,16.9V17h-0.2v-0.1H49.5l0.2-0.1 M59.3,16.9V17h0.3
			v-0.2L59.3,16.9L59.3,16.9L58.8,17l0,0v-0.5 M62.3,16.8l0.1,0.7l0,0l0.2,0.2l-0.1-0.9L62.3,16.8 M51.5,16.7v0.2h0.2v-0.2l0.4,0.2
			h0.1l0.2,0.2l0.2-0.3l0.2,0.3L53,17v0.4l0.3-0.1l-0.1,0.4h-0.3l0.1-0.3h-0.3v-0.2l-0.2,0.1l-0.1-0.1h-0.1L52.1,17l-0.2,0.1V17
			l-0.1-0.1 M59.8,16.9V17H60v-0.1h0.1l0.1-0.1l0.3,0.3l0.2-0.1l0.3,0.4l0.1-0.3v-0.3 M50.2,16.6l0.2,0.4v-0.5 M49.7,17v0.2
			l-0.2,0.1V17H49.7v-0.2 M58.3,16.8l0.4,0.2h-0.4l-0.1,0.1L58,17.2V17h0.3V16.8 M59,16.7l-0.3,0.4l0,0l-0.2-0.4 M49.7,17.1H50
			l-0.1,0.2l-0.2,0.1l0.1,0.2l0.3-0.1L50,17.4 M50.5,17.1l0.4,0.3l0.3,0.2l0.1-0.2h0.2v-0.3h-0.3v0.2L51,17.2L50.9,17L50.5,17.1
			 M58.7,17h0.5v0.2h0.2V17h0.3v0.3l-0.2-0.1l-0.1,0.2l-0.3-0.2L58.7,17L58.7,17L58.7,17l-0.3,0.3l-0.1-0.1 M50.5,17l0.2,0.4
			l-0.1,0.1h-0.1L50.5,17l-0.2,0.5h-0.1v-0.1L50.5,17L50.5,17 M57.7,16.9l-0.3,0.3l-0.1-0.1L57,17.2L56.8,17l-0.2,0.3l-0.3-0.2
			l-0.1,0.3l-0.4-0.1l0.1,0.4h0.3l-0.1-0.3l0.3,0.1v-0.2l0.2,0.1l0.1-0.2H57l0.2-0.2h0.1l0,0l0,0 M49.3,16.9L49,17.2h0.1l0.1-0.1
			h0.1v0.3l0.2-0.1l0.1,0.3l0.2-0.2l0.1,0.2l0.2-0.2 M51.4,17.3v0.1h0.2v-0.3h0.2v0.4h-0.1l-0.1,0.2h0.2l0,0v-0.1v-0.3l0,0
			 M57.6,17.2v0.3l-0.2,0.1l-0.1-0.3L57.6,17.2 M45.8,17.4L45.8,17.4l-0.2-1.1 M63.3,17.1l-0.1,0.8v-0.5L63.3,17.1 M57.4,17.2v0.3
			V17.2L57.4,17.2 M59.9,17l0.3,0.2H60L59.9,17v0.4h-0.2v-0.2 M49.1,17.2l-0.2,0.2h-0.2l0.3-0.2h-0.2l-0.2,0.3h0.1l0.2,0.1H49
			l-0.1-0.1h0.2L49.1,17.2L49.1,17.2 M55.3,17l0.1,0.4l0,0l0.3,0.3l-0.1-0.5L55.3,17 M60.3,17.1l0.1,0.3l0,0l-0.2,0.1h-0.1l0.2-0.1
			H60v-0.2v-0.1l0.2,0.2h0.2L60.3,17.1L60.3,17.1 M46.9,16.8l-0.1,0.7l0,0l-0.2,0.2v0.1l-0.1,0.1V17l-0.2,0.1v-1.3 M62.8,17.3
			l-0.2-0.2l0.1,1l-0.2-0.2l0.1,0.8l-0.2-0.2v-0.1l-0.1-0.7 M50.9,17.4l0.2,0.2l-0.2,0.1l-0.2-0.3L50.9,17.4l0.1-0.1l0.1-0.1
			 M58.8,17l0.2,0.4l0.1-0.1v-0.1l0.1-0.1 M59.1,17.4l0.2,0.2l0.1-0.2l0.2,0.1l0.2,0.1l0.1-0.3h0.2v0.1v0.1h0.1l0.2-0.1v0.1l0.1,0.1
			l0.2,0.1l-0.1-0.4h0.3l0,0l0.3,0.1V17h0.3v0.4l-0.2-0.1l-0.1,0.4L61,17.6v-0.4 M51.8,17.4L51.8,17.4l0.4-0.1l0.1-0.1h0.1h0.1v0.1
			v0.1l0.2,0.1l0.1-0.2h-0.2v-0.1h-0.1l-0.1-0.1l-0.1,0.1L51.9,17 M61.7,17.1v0.5l0.2,0.2l-0.1-0.4l-0.2-0.1 M47.5,17.3v0.5L47.3,18
			v-0.6L47.5,17.3 M58,17.3l0.1,0.2l-0.2,0.2l-0.1-0.3H58 M57.3,17.1L57,17.3l0,0l-0.1,0.1h-0.1v0.1h-0.2l0.1,0.2l0.2-0.1l-0.1-0.1
			h0.1v-0.1l0.2-0.1l0.1,0.1h0.3l0,0l0.2-0.1l0.1,0.2h-0.1h-0.1v-0.1 M45.9,17.1l0.1,0.7l0,0l0.1,0.3l0.1-0.5l0,0l0.2-0.2v-0.1
			 M55.4,17.4l-0.3-0.3l0.1,0.5l-0.3-0.2v0.5h-0.3v0.6h0.3v-0.6l0.3,0.1v-0.4l0,0 M60,17.6l0.1-0.1l0,0v-0.2 M49,17.5h0.2v-0.2h-0.1
			l0.1,0.1l0.1,0.1l0.2,0.1l-0.1-0.3h-0.1v-0.2 M59.7,17.4l-0.1,0.2l-0.2,0.2l-0.1-0.2l-0.2,0.1L59,17.5l-0.1,0.1h-0.1v-0.5
			l-0.2,0.4l-0.1-0.1v-0.1l-0.3,0.1l0.1,0.2l0.2-0.2 M56.9,17.4l0.1,0.1h0.1l0,0 M48.3,17.5v0.4L48.1,18L48,17.6L48.3,17.5h0.2v0.3
			l0.1-0.1v-0.3 M52.2,17.4v0.1l0,0l-0.1-0.2L52.2,17.4L52.2,17.4L52.2,17.4l0.2,0.1h0.1 M52.3,17.5L52.3,17.5 M57,17.5L57,17.5
			L57,17.5L57,17.5L57,17.5 M51.5,17.4l-0.1,0.2l-0.2-0.1L51,17.6l0.1,0.1h0.1l0.1-0.1h0.1h0.1h0.3l0,0h-0.1 M57.5,17.6h-0.2h-0.2
			v-0.1 M50.4,17.6l-0.1,0.3h0.1V17.6L50.4,17.6l-0.2-0.1L50,17.7l-0.2-0.1l-0.1,0.2l-0.1-0.1l-0.1-0.1l-0.2,0.1h0.1l-0.1,0.1
			L49,17.7l-0.2-0.1l-0.1,0.2h-0.1l0.2,0.2l0.2-0.1l-0.2-0.2 M58.6,17.5l-0.1,0.3h0.2v-0.3H58.6l-0.1-0.1l-0.1,0.2l-0.2-0.1L58,17.6
			h-0.1h-0.1h-0.1h-0.1 M51.5,17.6l-0.2,0.1l0,0L51.5,17.6l0.1,0.2l0.1,0.1l0.2-0.4l0,0h-0.1H52v0.1h-0.2L52,18l0.3-0.2l0.2-0.2
			l0.3,0.1L52.6,18l-0.2-0.2l-0.2-0.2H52 M57,17.6L57,17.6L57,17.6l-0.2,0.1L57,17.6 M63.2,17.3L63,17.6v0.1l0.1,0.6l0.1-0.4
			 M57.3,17.6h0.3l0,0v0.2L57.5,18L57.3,17.6L57.3,17.6L57.3,17.6 M60.3,17.7l-0.1,0.2L60,17.7l-0.3,0.1v-0.1h0.1l0.2-0.1l0.2-0.1
			 M52.3,17.6L52.3,17.6L52.3,17.6 M57.2,17.6h0.2 M47,17.6l0.2-0.1v0.6l0.2-0.1v0.6l0.3-0.2l-0.1-0.5l0.3-0.1l0.1,0.4l0.3-0.1l0,0
			 M52.4,17.6L52.4,17.6 M56.9,17.6h0.2l-0.2,0.2l-0.2-0.1l-0.3,0.1l0.2,0.3l0.2-0.2l0.3,0.2l0.2-0.4 M62.3,17.6l-0.2-0.1v0.6
			L62,17.9v0.6l-0.4-0.2l0.1-0.5l-0.3-0.1v0.4L61.1,18l-0.2,0.4l-0.3-0.1l0.2-0.3l-0.2-0.1l-0.2,0.3L60.3,18l-0.1-0.1L59.9,18
			l-0.1-0.1L59.5,18h-0.1l0.3-0.1 M50.1,17.8L50.1,17.8h0.2H50.1L50.1,17.8h-0.2h-0.1l-0.3-0.1L49.3,18l-0.2-0.1l0.1-0.2h0.1
			 M57.8,17.7l0.2,0.1L57.8,17.7h-0.2 M58.8,17.6l0.1,0.3l0,0H59h0.4l0.1,0.2l0.3-0.2l0.1,0.3l0.3-0.2l0.2-0.2v-0.3 M50.5,17.6
			l0.1,0.3l0.2-0.1l0,0h0.1l0.3-0.1 M49.8,17.7h-0.2 M50.9,17.7h-0.2h-0.1h-0.1H50.9L50.9,17.7l0.4-0.2v0.3V18l0.3-0.2l0.1,0.2
			l0.4-0.1l0.2,0.3l0.4-0.2l0.2,0.1l0.3-0.4h0.3v0.4l0.3-0.3l0,0v-0.4 M57.6,17.8l0.2,0.1v0.2l-0.3-0.2l-0.1,0.2L57.1,18l-0.2,0.3
			L56.5,18l-0.2,0.1L56,17.7h-0.3l0,0 M58.1,17.7l0.2,0.1l-0.4-0.1V18l0.3,0.1v-0.2l0,0h0.1h0.1l0.1,0.1h-0.2 M45.9,17.8v-0.5
			 M58.3,17.8L58.3,17.8 M58.6,17.8h0.2l0.2-0.1v0.1h0.2 M55.2,17.5v0.4l0.3,0.3v-0.4l0,0 M46.5,17.8V17 M50.7,17.8V18l0,0l-0.4,0.1
			v-0.3h-0.4L49.8,18h-0.4l-0.1,0.2l-0.4-0.1l-0.1,0.1L48.5,18l-0.2,0.1l-0.2-0.3l0.2-0.1l0.2,0.3l0.1-0.1l0.3,0.2l0.1-0.2l0.4,0.1
			l0.1-0.2h0.4l0,0h0.2h0.2 M58.6,17.8L58.6,17.8h0.3 M53.5,17.8l0.3-0.2v0.5l0.3-0.2v0.6h0.3v0.6h-0.3v-0.6l-0.3,0.2v-0.6l-0.2,0.2
			L53.5,17.8 M55.2,18.3L55.2,18.3v0.6l-0.3-0.2v0.5l0.1-0.1v0.4h-0.4V19h0.3v-0.6l0.3,0.2V18l0.3,0.2v-0.4l-0.3-0.2 M58.7,17.8
			L58.7,17.8h-0.4 M58.7,17.8l0.1,0.3h0.5l-0.1-0.2L58.7,17.8 M51,17.9h0.3l0.2-0.1 M45.9,18h-0.3l-0.1,0.2l-0.4-0.1l0.2-0.1h0.3
			 M63.2,17.8l0.1,0.2h0.3l0,0l0.3-0.1L64,18l0.2,0.1l0.1,0.2L64,18.2l0-0.1l-0.4,0.1L63.5,18 M46,18l0.1-0.2 M63,17.6v1v0.1
			l0.1,0.2v-0.7l0.2,0.2V18h0.3 M45.9,18L45.9,18h-0.3 M58.2,18v0.2l0.5,0.1v-0.2L58.2,18 M46.4,17.3v1l0.2-0.2V18 M50.1,18.1
			L50,18.3h0.5l0.1-0.2L50.1,18.1 M59.2,18.1l0.1,0.2l0.4-0.1l0.1,0.2l0.4-0.2l0.2,0.2l0.3-0.3L60.5,18l-0.3,0.2l-0.1-0.1l-0.4,0.1
			L59.6,18L59.2,18.1 M62.8,17.3v1l-0.1-0.2V18 M46.9,17.6l-0.1,0.7L47,18l0,0 M62.3,18.3L62.2,18l0,0 M61.4,18l-0.2,0.4l0.3,0.2
			l0.1-0.4L61.4,18 M47.8,18.1l0.2,0.4l0,0l-0.3,0.2l-0.2-0.5L47.8,18.1 M45.9,18.1v0.3l0.2-0.2V18 M53.5,17.8L53,18.3l-0.1-0.2
			l-0.4,0.4l-0.1-0.2l-0.5,0.2l-0.1-0.3l-0.4,0.2v-0.3L51,18.3V18 M45.2,18L45,18.1l-0.1,0.2l0.2-0.1L45.2,18 M57.8,18.1v0.3
			l0.5,0.1v-0.2L57.8,18.1 M55.7,17.8l0.5,0.5l0.1-0.2l0.5,0.4l0.1-0.2l0.4,0.2l0.1-0.3l0.4,0.2v0.2l-0.5-0.2v0.3l-0.4-0.2l-0.1,0.2
			l-0.5-0.4v0.4l-0.5-0.4v-0.1 M48,18.4l0.3-0.1l0.3,0.3l0.3-0.2l0.4,0.2l0.2-0.2l0.5,0.1l0.1-0.2h-0.5l-0.1,0.2L49,18.3l-0.2,0.2
			l-0.3-0.3l-0.3,0.1L48,17.9 M48,18.5l0.3,0.4l0.3-0.2L49,19l0.3-0.2l0.5,0.1l0.2-0.2h0.5l0.1-0.2l0.5-0.1v0.2l0.5-0.1l-0.1,0.3
			l0.5-0.2v0.3l0.5-0.3v0.2l0.5-0.4v0.4l0.5-0.5l0,0 M59.9,18.6L59.9,18.6l0.3,0.2l0.4-0.2l0.3,0.2l0.3-0.4l-0.3-0.2l-0.3,0.3
			l-0.3-0.2L59.9,18.6l-0.1-0.2l-0.5,0.1l-0.1-0.2h-0.5l0.1,0.2h-0.5l0.1,0.3l-0.6-0.1l0.1,0.3l-0.6-0.2l0.1,0.3l-0.6-0.3v0.4
			l-0.6-0.4v0.5l-0.4-0.4v-0.5 M45.5,18.1l0.4,0.2v0.1l-0.2,0.2l-0.3-0.3v0.2l-0.2-0.1h-0.1v-0.2l0.4,0.1l0.1-0.2 M63.8,18.4v0.2
			l0.2-0.1l0.1-0.1v-0.2L63.8,18.4l-0.1-0.2l-0.4,0.2l0,0l0.2,0.2L63.8,18.4 M63,18.6l-0.1-0.2v-0.1 M46.3,17.6l0.1,1l0.1-0.2v-0.1
			 M53.6,18.3v0.5l0.3-0.4l0,0 M46.7,17.9l-0.1,0.8l0.2-0.2v-0.1 M50.9,18.5L50.9,18.5h-0.5l-0.1,0.2h-0.5L49.5,19L49,18.9l-0.3,0.3
			l-0.4-0.3L48,19.1l-0.3-0.4L47.4,19l-0.1-0.5L47,18.8v-0.7 M62.2,18.1v0.7L62,18.5l0,0 M64,18.6L64,18.6l0.1,0.2l0.2-0.1l0.1,0.3
			l0.1-0.1l-0.1-0.3l-0.1,0.2l-0.2-0.1 M45.2,18.5L45,18.6v-0.2l-0.1,0.4l0,0l0.1-0.2l0.2,0.1l0.2-0.1l-0.1-0.1"
        />
        <path
          class="st0"
          d="M59.9,18.6l-0.5,0.1l-0.2-0.2h-0.5l0.1,0.2h-0.5l0.1,0.3h-0.5l0.1,0.4l-0.6-0.2v0.4l-0.6-0.4v0.6l-0.6-0.5
			l0,0 M45.4,18.6L45.4,18.6l0.2,0.1v0.1l0.2,0.1v-0.1v-0.1 M64.4,18.7v0.4v-0.3L64.2,19l-0.1-0.2l-0.2,0.1h-0.2l0.1-0.1h0.1
			 M46.1,18.3l0.1,0.6l0.1-0.3l0,0 M62.7,18.2v0.7l-0.1-0.1v-0.1 M45.5,18.7l-0.2,0.1l-0.2-0.1L45,18.9l-0.2-0.1V19v-0.3 M53.8,18.4
			V19l0.1,0.4l0.2-0.2v0.5l0.3-0.2V20h0.3v-0.4h-0.3v-0.4H54v-0.5L53.8,19l-0.3,0.4l-0.1-0.5L53,19.1v-0.4l-0.5,0.4v-0.4l-0.6,0.4
			v-0.3L51.3,19l0.1-0.3l-0.5,0.1l0.1-0.3 M55.2,19.6L55.2,19.6V20L55,19.9v0.5h-0.4v-0.5H55l-0.1-0.5L55.2,19.6v-0.4l0.2,0.2v-0.5
			l0.3,0.4v-0.5l-0.3-0.4 M63.8,18.8l0.1,0.1h-0.1l-0.1-0.2l-0.2,0.1v-0.1 M46.8,18.4v0.7l0.2-0.3l0.1,0.6l0.3-0.4l0.3,0.5l0.3-0.4
			l0.4,0.4l0.3-0.4l0.5,0.2l0.3-0.4l0.6,0.1l0.2-0.3h0.6L50.7,19l0.6-0.1l-0.1,0.3l0,0l-0.7,0.2l0.2-0.4H50l-0.2,0.4l-0.6-0.1
			l-0.3,0.5l-0.5-0.4L48,20l-0.4-0.5l-0.3,0.4L47,19.4l0,0 M58,19.3L58,19.3l0.7,0.2L58.5,19h0.6l-0.2-0.3h0.6l0.3,0.3l0.5-0.2
			l0.3,0.3l0.4-0.3l0.3,0.3l0.3-0.4l0.3,0.3l0.2-0.5 M46.5,18v0.8l0.1-0.2v0.8l0.2-0.2l0,0 M45.5,18.7l-0.1,0.2h-0.1L45.5,18.7
			 M63.9,18.8l0.1,0.1l0.2-0.1l0.1,0.4l0.2-0.1V19 M62.8,18.4v0.9L62.7,19v-0.1 M45.9,18.4L46,19l0.1-0.1v-0.1 M44.8,19v0.2l0.2,0.1
			l0.1-0.4l0.2,0.1v0.1v0.1l0.3-0.3l0.3,0.1v-0.1 M64,19v0.2l-0.2-0.3L63.4,19v-0.2l-0.2,0.4L63.1,19l0.1-0.6 M45.2,19L45.2,19
			 M46.4,18.3v0.9l0.1-0.3v-0.1 M45.8,19L45.8,19l-0.3,0.4l-0.3-0.1l0,0 M58.1,19.4l0.1,0.5l0.8,0.3l-0.2-0.6l0.7,0.1l-0.2-0.4
			l0.6-0.1l0.3,0.4l0.5-0.2l0.3,0.4l0.4-0.4l0.3,0.4l0.2-0.5l0.3,0.4l0.1-0.6l0.2,0.3v-0.6 M63,18.7v0.8l0,0l0.1,0.2l0.1,0.2v-0.8
			l-0.1-0.2v0.8v1L63,20.4l-0.1-0.3l0,0v-0.8l0,0 M62.6,18.7v0.7l-0.2-0.2V19 M53.9,19.4L53.9,19.4l-0.3,0.4l-0.1-0.4l-0.4,0.4
			L53,19.2l0,0 M63.2,19.2v0.2l0,0V19.2l0.4,0.2l0.2-0.1l0,0l0.1,0.2v-0.1 M45.8,18.8l0.2,0.5l0.1-0.2v0.7L46,19.6v-0.2v-0.1v0.1
			L45.8,19 M45.9,19.4L45.9,19.4l-0.2,0.1l-0.3-0.2l-0.3,0.3h-0.2v-0.3H45v0.3v0.2h-0.1v-0.6 M51.3,19.3l0.6-0.2v0.4l0.6-0.4v0.6
			l0.5-0.5 M45.1,19.3l0.1-0.2 M46.3,18.5v1l0.1-0.3l0,0 M64.1,19.4l0.1,0.2L64.1,19.4L64.1,19.4 M45.9,19.4L45.9,19.4 M62.9,19.5
			l-0.1-0.2 M63.3,19.3l0.2,0.2l0.3-0.2l0.3,0.4l0.2-0.1l0,0 M56.2,19.2L56,19.7l-0.3-0.3l-0.1,0.5l-0.2-0.3v-0.1 M64.3,19.8v-0.6
			 M46.5,18.9v0.9l0.1-0.3v-0.1 M64.3,19.8V20V19.8l-0.1-0.2 M46.8,19.2l0.1,0.8l0.4,0.5l0.1-0.6l0.4,0.6L48,20l0.5,0.6l0.3-0.6
			l0.7,0.2l0.3-0.6l0.7-0.1l-0.2,0.6l0.8-0.3l0.1-0.5 M55.4,19.4v0.5l0.3,0.3l-0.1-0.4l0.3,0.4l0.1-0.5l0.3,0.5l0.3-0.4l0,0l0.7,0.3
			l-0.1-0.6l0.8,0.3v0.6l0.8,0.3l-0.1-0.6l0.8,0.1l-0.3-0.6l0.6-0.1l0.3,0.5l0,0l0.5-0.4l0.3,0.4l0.4-0.5l0.2,0.4l0.3-0.5l0.2,0.5
			l0.2-0.7 M64.3,19.8L64.3,19.8L64.2,20l-0.1,0.1L64,19.7l-0.2,0.2l-0.4-0.4v0.3l-0.2-0.2v-0.2 M46.1,19l0.1,0.7l0.1-0.1v0.9
			l0.1-0.3v-0.1l-0.1-0.9 M53.6,19.7L53.6,19.7l-0.3,0.5l-0.1-0.5l-0.3,0.4l-0.3-0.4l0,0L51.8,20v-0.5l-0.7,0.3v0.6l0.7-0.3v0.7
			l0.5-0.2l0.2-0.8 M45.9,19.6l-0.2,0.2v-0.3l-0.4,0.4l-0.2-0.2L45,20.1V20v-0.2 M62.3,19.9V20l0.2,0.3l0.1-1l0.1,0.4v-0.9 M44.9,20
			v0.6V20l0.2,0.3l0.2-0.4V20l0.4-0.3l0.1,0.2L46,20l0,0v0l0.1-0.1l0.1-0.1 M53.8,19.5L53.7,20l0.2-0.3l-0.1,0.4l0.3-0.2v0.5h0.3V21
			H55v-0.6l0.4,0.2L55.3,20l0.2,0.2v-0.4l-0.2-0.3 M64.3,20v0.2l-0.1-0.1l-0.1,0.1l-0.2-0.3v0.2l-0.3-0.3L63.4,20h-0.2v0.2V20v-0.1
			l0,0v-0.3 M62.8,20.1l-0.1-0.3l0,0 M46.6,19.6l0.1,0.7l0.2-0.3l0.2-0.6 M44.8,19.9l0.1,0.7v0.1l0.2-0.4v0.1l0.3-0.3l0.1,0.2
			l0.3-0.3l0.2-0.2 M63.1,20.1l0.2-0.1l0.3,0.3l0.1-0.2l0.2,0.3v-0.1 M44.9,21.2v-0.5l0,0l0,0v0.1l0.2-0.4l0.1,0.2l0.2-0.3l0.4,0.2
			l0.1-0.3l0,0 M46.4,20.1l0.1-0.3l0.1,1l0.1-0.4v-0.1 M54.3,20.4L54.3,20.4l-0.4,0.2v-0.5l-0.2,0.2v-0.4l-0.2,0.3v-0.4 M59.6,20.1
			L59.6,20.1l0.6-0.2 M64.2,20.2v0.4l0,0V20 M47.1,20.4v0.1l0.3,0.6l0.2-0.7l0.5,0.7l0.3-0.6l0.7,0.5l0.2-0.9l0.8-0.1L50,20.6
			l0.8-0.4l0.1,0.6l0.7-0.3v0.7l0.5-0.2l0,0l0.3-0.4v-0.1l-0.1-0.3l0.2-0.4l0.1,0.4l0.6-0.3v0.4l0.3-0.2l0,0 M55.7,20.2L55.7,20.2
			l0.2,0.2v-0.3 M57.4,20v0.7l0.7,0.3v-0.7L57.4,20 M64.2,20.6L64.2,20.6L64,20.3v0.1l-0.1,0.2l-0.2-0.3l-0.5,0.2v-0.3 M55.5,20.3
			v0.1l0.2,0.3v-0.4 M45,20.8l0.1,0.1l0.2-0.3l0.4,0.1l0.3-0.2l0,0 M53.6,20.3v0.4l0.2-0.2v-0.1 M62.5,20.4L62.5,20.4l-0.1,1
			l-0.3-0.2l0.1-1.1L62,20.4l-0.2-0.5l-0.4,0.6L61.2,20l-0.5,0.6L60.4,20 M62.8,20.2l-0.1,1v0.2l0.1,0.2l0.1-1l0.1-1 M56.7,19.7
			l0.2,0.8h-0.3l-0.2-0.4l-0.2,0.3L56,20.2 M55.6,20.4v0.4v0.1l0.3,0.3l-0.1-0.5l0.3,0.2L56,20.5l0.3,0.2v-0.3l0,0 M52.6,20.5h-0.3
			v0.7l0.1,0.1l0.2-0.3v-0.2 M46,19.8l0.1,0.9l0.1-0.2l0,0 M64.2,20.8v-0.2 M59.7,20.2L60,21l0.7-0.5l0.3,0.6l0.5-0.7l0.2,0.7
			l0.4-0.6v-0.1 M62.5,20.4l0.1,0.4l0.1-0.9 M64.3,20.8L64.3,20.8L64.3,20.8L64.3,20.8L64.3,20.8L64,20.5l-0.4,0.1l-0.3-0.2v0.4
			l0.3-0.2l0.2,0.2h0.3v0.4L63.7,21l0.1-0.2 M52.7,20.8l0.2-0.3v0.2l0.3-0.2l-0.1,0.4l0.3-0.2v-0.1 M56.9,20.5v0.7l0.5,0.2v-0.7
			L56.9,20.5 M45.9,20.4v0.3l-0.2-0.1l-0.3,0.2h-0.3v0.4l0,0l0.4-0.2l-0.1-0.2 M64.2,20.8V21V20.8L64,20.4 M53.4,20.7l-0.1,0.5
			L53,21.4l0.1-0.5L52.8,21l0.1-0.2v-0.1 M56.3,20.7L56.3,20.7V21L56,20.8v0.5l-0.3-0.1l0,0 M62.6,21.1v-0.3l0,0 M46,20l0.1,0.9
			l0.1-0.1v-0.1 M56.3,20.5l0.3,0.4l0.1-0.4 M45.9,20.8L45.5,21v0.1h0.3l0,0v-0.3 M55.5,20.8l-0.2-0.2v0.5L55,21v0.5h-0.4V21h-0.4
			v-0.5 M46.4,20.2l0.1,1l0.1-0.2v-0.1 M62.8,21.5v0.1l0.1,0.2l0.1-1l0.1,0.1l0.1-0.9 M53.7,20.5V21l0.3-0.3v0.5l0.3-0.1v0.5h0.4V22
			h-0.4v-0.4H54v-0.4l-0.2,0.3V21l-0.3,0.3l0.1,0.6l0.2-0.4l0.1,0.2l0.2-0.1V22h0.3v0.3h-0.3L54,21.9h-0.2v-0.3 M63.3,20.8l0.4,0.2
			l-0.1,0.1L63.3,20.8v0.3l0,0V21V20.8L63.3,20.8 M45.9,21v0.2v0.1l0.1-0.2L45.9,21 M56.3,20.8l0.2,0.2v-0.2l0.4,0.4l0,0l-0.1,0.1
			l-0.2-0.2V21 M63.3,21.1L63.2,21v-0.1 M53.5,21.8l0.1,0.3h0.2l-0.1-0.3H53.5L53.5,21.8l-0.4,0.1v-0.5l-0.3,0.1l0.1-0.5l-0.2,0.2
			V21l0.3-0.2 M58.9,20.7l-0.1,0.7l0.7,0.5l0.4-0.8l0,0L58.9,20.7 M52.3,21.3L52.2,22l-0.4,0.1V22l-0.1-0.5l-0.6,0.2L51,21l-0.8,0.4
			l-0.7,0.5l-0.4-0.8l0,0l1-0.3l0.1,0.7l0.2,0.7l0.6-0.4l0,0 M64.2,20.9l0.1,0.3v-0.1v0.2v-0.1v0.1h-0.2h-0.3L63.5,21 M46.7,20.3
			l0.1,1l0.2-0.2L46.9,20 M45.9,21.1l-0.2,0.1l0.2-0.2 M63.2,21.4l-0.1,0.3L63,22h0.2l0.2-0.4h0.4v-0.4l-0.3-0.1v0.4h-0.2L63.2,21.4
			L63.2,21.4L63.2,21.4l0.1-0.2h0.2L63.3,21 M56.8,21.5v0.4V22V21.5L56.8,21.5l-0.1-0.1v-0.1L56.4,21l0.1,0.5l-0.3-0.1v0.5l-0.4-0.1
			l0.1-0.5 M45.1,21.2v0.5H45l-0.1-0.4L45.1,21.2 M64.3,21.2v0.3V21.2L64.3,21.2 M45.6,21l-0.2,0.2h0.3l0.1,0.4H46v-0.3l0.1,0.5
			L46,21.7 M45.4,21.3L45.4,21.3l-0.3-0.1 M45.4,21.3v0.4l0.4-0.1L46,22h0.2l-0.1-0.4 M46.4,20.5v1v-0.4V21 M55.5,21.2v0.1l-0.1,0.2
			l-0.2-0.1v0.4H55v-0.4h0.3V21L55.5,21.2v-0.4 M58.1,20.9L58,21.6l0.7,0.4l0.2-0.7L58.1,20.9 M46.6,20.8l0.1,1l0.1-0.4l0.2,1.1
			l0.1-0.5L47,21.1l0.3,0.3v-0.8 M49.3,21.1l-0.5,0.8l-0.5-0.8l-0.2,0.8l-0.5-0.8v1.1l-0.4-0.8l0,0 M62.6,20.8l-0.1,1l-0.1-0.4
			l-0.2,1L62.1,22l0.1-0.8L62,21.5v-0.9 M53.2,22.2L53.2,22.2h0.2h0.1h0.2l-0.2-0.1h-0.3l-0.1-0.2L52.8,22v-0.5l-0.2,0.2v-0.5
			l-0.2,0.2v-0.1 M59.9,21.1l0.5,0.9l0.5-0.7l0.2,0.7l0.4-0.7V22l0.3-0.5v-0.1 M64.2,21.5v0.2H64v-0.4 M46.2,20.7l0.1,1.1l0.1-0.3
			l0,0 M55.6,21.4l0.2,0.4l0,0l-0.1,0.3H56l0.2-0.3l0.3,0.1v-0.4l0.2,0.2v-0.4 M63.1,21L63,22l0,0v0.2l0.2-0.2l0.4,0.1l0.2-0.4h0.3
			l-0.2,0.4h-0.3l-0.3,0.3H63v-0.2L62.9,23h-0.1l0.1-1.1v-0.2 M46.3,21.7L46.3,21.7L46.1,22v0.1L46,21.9L45.6,22l-0.2-0.4h-0.3
			l0.2,0.4h0.3l0.3,0.3h0.4v-0.2 M57.5,21.4V22l0.5,0.2l0.1-0.6L57.5,21.4 M64.3,21.6l-0.1,0.2 M63.3,21.7l0.1-0.2 M56.9,21.7
			l-0.1-0.1V22l-0.2-0.1l-0.2,0.3l-0.3-0.1H56h-0.1h-0.2l0.2-0.1h-0.3l0.1-0.3v-0.3 M53.4,22.1l-0.3,0.1l-0.2-0.3L52.7,22v-0.4
			l-0.2,0.1v-0.4l-0.1,0.5l0.1-0.1v0.4l0.2-0.1l0.2,0.2l0.2-0.1H53.4h0.2 M63.2,22.2v0.3h0.1l0.2-0.3h0.4l0.2-0.3h0.1l0.1-0.2
			 M56.9,21.3L57,22l-0.1-0.1l0,0l-0.1,0.4l-0.1-0.1v-0.1 M55.9,21.8l-0.2,0.1h-0.3l-0.1,0.3H55v-0.3h-0.3v0.3h-0.3l0.1,0.2h-0.3
			L54,22.2h-0.3l0.1,0.2l-0.2-0.1l0.3,0.2l-0.1-0.1l0,0 M46.4,21.1l0.1,1.1l0.1-0.5l0,0 M45,22.1v-0.4 M62.7,21.3l-0.1,0.9L62.5,22
			v-0.2 M52.4,21.9v0.4l-0.1,0.1L52.2,22L52.4,21.9 M46.1,22.1L46.1,22.1L46,21 M63,22l-0.1-0.1 M51.2,21.6l0.1,0.6l-0.6,0.4
			L50.5,22L50,22.5l-0.3-0.6l-0.4,0.7l-0.4-0.7l-0.2,0.8L48.1,22v-0.1 M56.6,22.1l-0.2,0.2l0.1,0.1l0.2-0.2l0.1,0.1l0.1,0.1
			l-0.2,0.3l0.2,0.4l0.3-0.5l0.1-0.6L57,21.9l-0.1,0.5l0.3,0.2l0.5,0.2l0.2-0.6l0.5,0.3l0.2-0.6l0.6,0.5l0.3-0.6l0.4,0.7l0.4-0.7
			l0.2,0.9l0.5-0.8v-0.1 M46.1,22.3v0.2v0.1l-0.3-0.3h-0.3L45.3,22l-0.1,0.1l-0.2,0l0.1,0.6l0.2-0.1v-0.1l0,0l-0.2-0.4 M64.1,22.1
			L64.1,22.1l0.1-0.2 M63.1,22.6h0.4l0.2-0.2l0.1,0.1l0.2-0.3l0.1-0.1l0,0 M55.5,22.2L55.5,22.2L55.5,22.2V22h-0.2l-0.1,0.2H55.5
			v-0.1 M55.2,22.3L55.2,22.3H55v-0.2h-0.3v0.2h-0.2v0.2h-0.2l0,0h-0.2l0.1,0.2l0.1-0.1 M61.2,22l-0.1,1l0.4-0.8l0.1-0.2 M51.8,22.1
			l0.2,0.6l-0.5,0.2l-0.2-0.6L51.8,22.1 M48.1,22L48,23l-0.4-0.7l0,0 M55.8,22.2l0.3,0.1h0.2l0.2,0.1l-0.2,0.1l-0.1-0.1v-0.1
			 M47.1,22l0.2,0.5v-1 M62.1,22l-0.2,0.4v-0.8 M52.5,22.3l0.2,0.2h-0.1L52.5,22.3L52.5,22.3 M56.3,22.5v0.2l0,0l0.3-0.1h0.1
			l0.1-0.2v-0.5 M53.9,22.3l0.2,0.1v0.1 M62.8,21.6l-0.1,1.1l-0.1-0.3v-0.2 M55.1,22.4v0.1V22.4l0.2-0.1l0.1-0.1 M46.4,21.5l0.1,1.2
			l0.1-0.4v-0.1 M53,22.4l-0.1,0.2l-0.3-0.1l0.1-0.1H53l0.1-0.2 M62.5,21.9L62.3,23l-0.1-0.6v-0.2 M46.7,21.8l0.1,1.2l0.2-0.6l0,0
			 M55.2,22.5v0.1V22.5l-0.3,0.1v-0.2h-0.3v0.2h-0.2v0.2L54,22.7h-0.5v-0.4 M45.3,22.6l0.2-0.1l0.2,0.3h0.4v0.1H46h-0.2l-0.2-0.2
			l-0.2,0.1L45.3,22.6 M47.6,22.3v1l-0.3-0.7v-0.2 M54,22.6V23l-0.5,0.1v-0.4h-0.6v0.4h-0.7l-0.3-0.5l0.3-0.2l0.2,0.3l-0.2,0.4
			l-0.6,0.3l-0.2-0.6l-0.5,0.4l-0.2-0.6l-0.5,0.5L50,22.5l0,0 M61.5,22.2v1.1l0.3-0.8v-0.2 M63,22.6L63,22.6l0.1,0.2h0.2l0.2-0.2
			l0.1,0.1l0.2-0.2v0.1l0.2-0.4 M45.3,22.6l0.2,0.4l0.1,0.1v-0.2l-0.1-0.1 M50,22.5l-0.4,0.7l-0.4-0.6l0,0 M55.7,22.2v0.4h0.6
			l-0.1-0.3 M55.2,22.6V23h-0.3v-0.3h-0.2V23h-0.2v0.4H54V23h0.3v-0.2h0.2v-0.2h0.2v0.2L55.2,22.6h0.5V23h-0.5v0.4h-0.3V23h-0.2v0.4
			h-0.2l-0.1,0.4H54v-0.4l-0.5,0.1V23h-0.6v0.5l0.6-0.1v0.4l0.5-0.1v0.5h0.4v-0.5l0,0 M56.7,22.4v0.2l-0.4-0.1V23h0.6l0,0l0.6,0.3
			l0.2-0.6l0.5,0.4l0.2-0.6l0.5,0.5l0.2-0.6l0.4,0.8l0.4-0.7l0,0 M64.1,22L64,22.7v-0.1L63.7,23l-0.2-0.1l0,0l0.2-0.3l0.2,0.1
			L64.1,22 M45.2,22.6L45.2,22.6l0.2,0.4l0.2-0.1l0.1,0.1v-0.1 M52.9,22.6h-0.4v-0.2 M63,22.6v0.3h0.1L63,22.6L63,22.6 M46.2,21.8
			l0.1,1l0.1-0.1v-0.1 M63.1,22.9L63.1,22.9l0.2,0.1v0.3h-0.1v0.1l-0.1,0.1L63.1,22.9L63.1,22.9l-0.2,0.8h-0.1l0.1-0.9l-0.1-0.1
			v-0.1 M45.7,22.7l0.1,0.2l0.1-0.2H46h0.1v0.1l0.1-0.2 M63.5,22.7v0.1L63.4,23v0.3l0,0h-0.1l0.2,0.3h-0.1l-0.2-0.3v-0.4v-0.1l0,0
			h0.1l0.1,0.2l0,0l0,0l-0.1-0.2 M46.2,22.5l0.1,0.5l0,0h-0.2H46l0.1-0.1 M46.3,23L46.3,23l0.2,0.9h-0.1L46.3,23 M63.5,22.9
			L63.5,22.9v-0.1 M63.5,22.9V23V22.9 M45.7,23.1L45.7,23.1L45.7,23.1v-0.2l0.1,0.1h0.1v0.3h-0.1V23 M46.5,22.3l0.2,1.3l0.1-0.5l0,0
			 M54.3,23.8h0.3v-0.5h0.2v0.5l0.4-0.1l-0.1-0.4l0.5,0.1V23h0.6v0.5l-0.6-0.1v0.4l-0.4-0.1v0.5h-0.4v-0.5h-0.2v0.5l-0.3-0.1v0.5
			l-0.3,0v-0.4l-0.5,0.1v-0.5L53,23.9v-0.5l-0.6,0.1L52.3,23 M63.8,23L63.8,23v0.2l0.2-0.5 M45.7,23.3v0.1v-0.3V23.3L45.7,23.3
			L45.4,23v0.1l-0.2-0.5 M63.5,23.1v0.2v0.2l-0.1-0.2L63.5,23.1 M62.3,22.4L62,23.3l-0.1-0.7 M45.8,23.2L45.8,23.2v0.4v-0.1
			l-0.1,0.3l-0.1-0.2v-0.2 M63.4,23.3L63.3,23 M47.4,22.5l-0.1,0.8L47,22.4 M49.3,22.6L49,23.5l-0.4-0.7l-0.1,1L48,23v1.1l-0.4-0.8
			v-0.1 M45.4,23.2l0.2,0.4l0.1,0.3v-0.1V24l0.1-0.3h0.1l0.1-0.3h0V23l0.1,0.4v0.2L46,23.3l-0.1,0.3h-0.1v0.3l0,0h-0.1v0.2v-0.2
			 M46.8,23l0.2,1.1l0.2-0.7l0,0 M62.6,22.3l-0.1,1.1L62.3,23l-0.2,1l-0.2-0.6v-0.1 M63.7,23.2l-0.2,0.2l-0.1-0.1 M61.9,23.4
			l-0.3,0.8l-0.1-0.9L61.2,24l-0.1-1l-0.4,0.7l-0.1-1l-0.4,0.7L60,22.6 M63.6,23.4L63.6,23.4l0.1,0.2l0.2-0.4 M45.9,23.6L45.9,23.6
			l0.1,0.3l0.2,0.3l0.2-0.4l-0.2-0.2v-0.1 M50.5,23.6v0.1l0.2,0.5l0.4-0.5l0.2,0.5l0.5-0.3l0.1,0.5l0.5-0.2l0.1,0.4l0.5-0.2v0.4
			l0.4-0.1v0.4h0.4v-0.4h-0.4v-0.4L53,24.5V24l-0.5,0.2l-0.1-0.5l-0.6,0.2l-0.2-0.5l-0.5,0.3l-0.2-0.5L50.5,23.6l-0.3-0.5l-0.4,0.7
			l-0.3-0.5l-0.2,0.9L49,23.5v-0.1 M57,23.1l-0.1,0.6l-0.1,0.5L56.3,24l-0.1,0.4l-0.5-0.1v0.4h-0.4v-0.4l0.5,0.1l-0.1-0.5l0.6,0.2
			v-0.5l0.6,0.2l0.5,0.2l0.2-0.5l0.5,0.3l0.2-0.5l0.4,0.5l0.2-0.5l0.4,0.6l0.3-0.5l0.2,0.9l0.4-0.6v-0.1 M63.2,23.4l0.1,0.3
			l-0.1,0.1v0.1L63,24.2l-0.2-0.4l0,0l0.2-0.2v-0.1"
        />
        <path
          class="st0"
          d="M63.5,23.4v0.3v0.2l-0.1-0.3L63.5,23.4v0.3V24l0.1-0.3 M46.4,22.8l0.1,1.3l0.2-0.4l0,0 M62.7,22.8L62.6,24
			l-0.1-0.4v-0.2 M63.4,23.6l0.1,0.3h-0.1V23.6L63.4,23.6 M62.7,23.8v0.1L62.6,24l0,0L62.7,23.8 M63.2,23.8l-0.1-0.3 M49,23.5
			l-0.2,1l-0.4-0.7v-0.1 M46,23.7l-0.1,0.2v0.2l0.2-0.2l0.1-0.4 M60.2,23.5l0.1,1l0.4-0.7v-0.2 M45.7,23.9L45.7,23.9 M63.4,23.9
			L63.4,23.9v0.3V23.9 M46.2,23.8l0.2,0.4h0.1V24l-0.1-0.1v-0.1 M63.1,23.9l0.2,0.2v-0.2l0.1,0.3h-0.1l-0.1,0.3L63,24.3l-0.2,0.2
			l-0.2-0.2v-0.1v0.2l0,0v-0.2l0.2-0.4 M48.4,23.9v1l0.4,0.7v-1.1l0.4,0.6l0.1-1l0.2,0.5l0.3-0.8l0.2,0.5l0.2,0.4l0.4-0.6l0.2,0.4
			l0.4-0.4l0.2,0.4l0.4-0.3l0.2,0.4l0.5-0.2l0.1,0.4l0.4-0.2V25l0.4-0.1l0.1,0.2h0.4l-0.1-0.2l0.4,0.1v-0.5h0.4v-0.4l0.3-0.1v0.5
			l0.4-0.1v0.4h0.4v-0.4l0.5,0.2v-0.4l0.5,0.2l0.1-0.5l0.5,0.3l0.1-0.5l0.5,0.3l0.2-0.5l0.5,0.5l0.2-0.5l0.4,0.7l0.2-0.6l0.3,0.8
			l0.2-0.4l0.2,1l0.3-0.5l0.1,1l0.4-0.6l0.4-0.8V24 M45.4,24.1v0.2l0.2-0.1l0.1,0.3l0.2-0.2l0.2,0.2l0.2-0.2v-0.2 M47.8,24.2l-0.1,1
			l0.4,0.8l0.3-1.1L48,24.2v-0.1 M62.5,23.7l-0.2,1l-0.2-0.5V24 M46.7,23.7l0.2,1.1l0.2-0.5v-0.1 M47.2,23.4l0.3,0.9l0.1-1
			 M45.7,24.3l0.1,0.3h0.1L45.7,24.3V24 M46.6,24.3L46.6,24.3L46.6,24.3l-0.2,0.3h-0.2l-0.1,0.1l-0.3-0.2l0.2-0.1l0.2,0.1l0.1-0.2
			L46.6,24.3L46.6,24.3L46.6,24.3 M63.4,24.3L63.4,24.3L63.4,24.3v0.3h-0.1L63.4,24.3 M49.6,24.6L49.6,24.6l0.2,0.5l0.3-0.8l0.4-0.7
			 M62.5,24.3l-0.1,0.4l-0.1,0.5l-0.1-0.4v-0.1 M47.5,24.3l-0.2,0.8l0.4,0.9l0.1-0.9L47.5,24.3L47.5,24.3 M62.1,24.2l-0.3,1
			l-0.2-0.9v-0.2 M62.6,24.4l0.1,0.2h0.2l0.1,0.1l0.3-0.1l-0.1-0.1L63,24.6l-0.1-0.2H62.6L62.6,24.4 M46.9,24.7v0.2l-0.1,0.4
			l-0.2-0.9 M46.4,24.6L46.4,24.6l-0.3,0.1 M53.1,25.3L53.1,25.3l0.1,0.2l0.3-0.1l0.1,0.2H54l-0.1-0.2l0.3,0.1v-0.2h0.4v-0.5h0.3
			v0.5l0.4-0.1v0.2h0.4l0.1-0.2l0.4,0.1l0.1-0.4l0.4,0.2l0.1-0.4l0.4,0.2l0.2-0.4l0.4,0.3l0.2-0.4l0.4,0.4l0.2-0.4l0.4,0.5l0.2-0.4
			l0.2,0.8l0.2-0.5v-0.1 M63,24.6h-0.2l-0.1-0.1 M52.7,25L52.7,25l0.1,0.4l0.1,0.1l0.4-0.1l0.1,0.2l0,0l0.3-0.1l0.1,0.2H54v-0.1h0.3
			l-0.1-0.2h0.4v-0.2H55v0.2l0.4-0.1l-0.1,0.2h0.4l0.1-0.2l0.4,0.1l0.1-0.2l0.4,0.2l0.1-0.4l0.4,0.2l0.1-0.4l0.5,0.3l0.2-0.5
			l0.4,0.4l0.2-0.4l0.4,0.5l0.2-0.4v-0.1 M51.5,24.6L51.5,24.6L51.1,25L51,24.5L50.6,25l-0.2-0.4l-0.2,0.7L49.9,25l-0.1,1l-0.2-0.4
			l-0.3-0.5l0,0 M61.6,24.3l-0.4,0.9l-0.4,0.7l-0.1-1l-0.1-1.1 M52.6,25l-0.4,0.2L52,24.8l-0.4,0.3l-0.2-0.4 M46.9,24.9l0.3,1
			l0.1-0.7l-0.3-1 M62.3,24.8l-0.3,1l-0.2-0.6L61.4,26l-0.2-0.8l-0.1-1 M59.6,24.7l0.2,1l0.2-0.5v-0.1 M46.8,25.3L46.8,25.3l0.3,1.2
			l0.1-0.5v-0.1 M60,25.1v1.2l-0.2,0.3v-1l-0.2,0.3l-0.1-0.8l-0.2,0.4l-0.2-0.7 M53.1,25.3l-0.3,0.2l-0.4,0.2l0,0l-0.2-0.3l-0.4,0.2
			l-0.2-0.3l-0.4,0.3L51,25.3l-0.3,0.4l-0.2-0.3L50.2,26L50,25.8l0,0.5l-0.2-0.2l0,0 M53.3,25.6l0.1,0.1h0.3v0.1h0.1h0.1v-0.2h0.3
			v-0.2h0.3v-0.2h0.4v0.2h0.3v0.2h0.3l0.1-0.2l0.3,0.1l0.1-0.2l0.3,0.1l0.1-0.1l0.3,0.2l0.2-0.3l0.4,0.2l0.2-0.3l0.4,0.3l0.2-0.4
			l0.3,0.4l0.2-0.3l0.2,0.6l0.2-0.3l0.1,0.8l0.3-0.3l0,0 M62.5,24.7l-0.1,0.8v-0.3 M49.3,25.2v1.1l0.3,0.5v-1.1l0.1-1 M59.6,26.1v1
			l-0.3,0.2l0.1-0.9l-0.2,0.2L59,25.8L58.8,26l-0.2-0.5l-0.2,0.3l-0.3-0.3l-0.2,0.3l-0.4-0.2l-0.2,0.3l-0.4-0.2l-0.1,0.1l-0.4-0.2
			l-0.1,0.1l-0.3,0l-0.1,0.1h-0.3l-0.1,0.1h-0.3l0.1-0.1H55v-0.2h-0.4v0.2l-0.3-0.1v0.2h-0.1H54 M52.3,25.6L52,25.8l-0.2-0.3
			l-0.4,0.2l-0.2-0.3l-0.3,0.3l-0.2-0.3L50.5,26l-0.3-0.2v0.8L50,26.3l0.1,1l-0.3-0.2v-1 M48.8,25.8l-0.1,1l0.4,0.6v-1.2L48.8,25.8
			v-0.1 M53.3,25.6L53,25.7l-0.1-0.1l-0.4,0.2l-0.1-0.1 M60.4,25.7v1.1L60,27.4v-1L60.4,25.7L60.4,25.7 M55.2,25.9H55h-0.1v-0.1
			h-0.3v0.1h0.3 M53.9,25.8L53.9,25.8L53.9,25.8h-0.4l-0.1-0.1h-0.3l-0.1,0l-0.4,0.1l-0.1-0.1l-0.4,0.2L52,25.8l-0.4,0.1l-0.2-0.2
			l-0.3,0.1L51,25.7L50.8,26l-0.2-0.1l-0.1,0.6h-0.3l0.1,0.8L50,27.2l0.2,0.9L49.9,28l-0.1-1l-0.2-0.3l0,0 M55.4,25.9h0.3l0.1-0.1
			h0.3l0.1-0.1l0.4,0.1l0.1-0.1l0.4,0.1l0.1-0.1l0.4,0.1l0.2-0.2l0.3,0.2l0.2-0.2l0.2,0.3l0.2-0.1v0.6h-0.2V26l-0.2,0.1l-0.2-0.3
			L57.9,26l-0.3-0.1L57.5,26l-0.4-0.1H57l-0.4-0.1l-0.1,0.1l-0.4-0.1v0.1h-0.2h-0.2 M53.5,25.9h-0.2h0.5l0,0h-0.1L53.6,26h-0.4v-0.1
			l0,0l-0.1-0.1l-0.4,0.1l-0.1-0.1l-0.4,0.1l-0.1-0.1L51.8,26l-0.1-0.1L51.4,26l-0.2-0.2L51,26.1L50.8,26v0.5H51l0.1-0.4h0.2
			l0.2-0.1h0.1l0.3-0.1H52l0.4-0.1h0.1l0.4-0.1H53h0.3 M55.4,25.9L55.4,25.9H55h-0.1h-0.2h-0.2h-0.2 M53.8,25.9h0.4h0.1h0.2h0.1
			 M56.1,25.9L56.1,25.9h-0.2l0,0h-0.5 M53.2,25.9L53.2,25.9 M56.2,25.9L56.2,25.9h0.3l0.4,0.1H57l0.4,0.1h0.1l0.3,0.1h0.1l0.2,0.1
			H58l-0.2-0.1h-0.1l-0.3-0.1h-0.2L56.9,26h-0.1l-0.3-0.1h-0.1 M52.6,26h-0.2h-0.2H52l-0.3,0.1h-0.1l-0.2,0.1h-0.1v0.1l-0.1,0.1
			v-0.2h0.1l0.2-0.1h0.1l0.3-0.1H52l0.4-0.1h0.1l0.3-0.1l0,0 M62,26.2v0.1v0.5l0.3-1.3L62,26.3V26v-0.1 M54.7,25.9L54.7,25.9
			l-0.1,0.2h-0.4l0.1-0.3 M55.9,25.9L56,26h-0.4l-0.1-0.2 M56.9,26h-0.2 M47.7,26.1L47.7,26.1l0.5,0.9l0.5,0.8v-1L48.2,26l0,0
			 M52.7,25.9L52.6,26H53l-0.1,0.2h0.4l0.1-0.2h0.4l0.2-0.1 M56.5,26l0.1,0.1L56.1,26l0.1,0.2h-0.4L55.7,26h-0.4l-0.1-0.1
			 M57.1,26.1h0.2h0.1h0.1l0,0L57,26L57.1,26.1l-0.5,0.1l0.1,0.2h-0.4l-0.1-0.2h0.4V26h0.5H57L57.1,26.1 M60.9,26l0.1,0.9l-0.5,0.8
			v-1L60.9,26v-0.1 M52.7,26L52.7,26l-0.4,0.1l0.1-0.1 M57.1,26L57.1,26l0.4,0.1l0,0h-0.1 M51.8,26.1L51.8,26.1h-0.2h0.1H51.8h0.3
			 M53.9,26l-0.1,0.2l0.4,0.1v-0.2L53.9,26 M57.7,26.2L57.7,26.2L57.7,26.2l-0.2-0.1L57.7,26.2L57.7,26.2L57.7,26.2L57.7,26.2
			l-0.2-0.1h-0.2l0,0h0.1L57.7,26.2L57.7,26.2L57.7,26.2l-0.4-0.1l0.1,0.1H57l0.1,0.2h-0.5l0.1,0.2h-0.4l-0.1-0.3h-0.4L55.7,26h-0.4
			l-0.1-0.2L54.8,26l0.1-0.1 M57.8,26.2L57.8,26.2L57.8,26.2L57.8,26.2l-0.3,0.1l0.1,0.2h-0.4v0.2h-0.4V27h-0.4l-0.1-0.4l-0.4,0.1
			l-0.1-0.3l-0.4,0.1l-0.1-0.3L55,26.3v-0.2h-0.4v0.2h-0.4v0.3h-0.4l-0.4-0.1l0.1-0.3h0.4l-0.1,0.3l-0.1,0.3l-0.4-0.1v-0.3H53
			l0.1-0.2h-0.4l0.1-0.2 M62.1,25.9l-0.4,0.8l-0.1-0.6V26 M51.6,26.2L51.6,26.2L51.6,26.2L51.6,26.2 M51.4,26.2L51.4,26.2L51.4,26.2
			L51.4,26.2l0.1-0.1L51.4,26.2l0.1-0.1L51.4,26.2L51.4,26.2L51.4,26.2L51.4,26.2l0.4-0.1l-0.1,0.1h0.4v0.2L52,26.6h0.4H53l0.1-0.3
			h-0.5l0.1-0.2h-0.5l0.1-0.1l-0.5,0.1l0.1-0.1 M57.9,26.1L57.9,26.1L57.9,26.1L57.9,26.1L57.9,26.1 M58.3,26.1v0.3h-0.2H58
			l-0.1,0.1l-0.1-0.2h0.1l0.1,0.1v-0.2L58.3,26.1 M51.7,26.3l-0.1,0.2h-0.3l-0.1,0.2l0,0L51,27l-0.1-0.4l0.2-0.1v-0.1l0.2,0.1
			l0.1-0.1L51.7,26.3 M58.1,26.5L58,26.7l-0.1-0.2h-0.3l0.1,0.2h-0.5V27h-0.4v0.3l-0.4,0.1V27L56,27.1v-0.3l-0.4,0.1v-0.3h-0.5v-0.3
			h-0.4v0.3h-0.4V27l-0.5-0.1v0.3h-0.4l0.1-0.3l-0.4-0.1v0.4h-0.4v-0.4v-0.2h-0.4h-0.5v0.2l-0.4-0.1l-0.1-0.2 M58.4,26.4L58.4,26.4
			h0.2L58.5,27l-0.2-0.2l-0.2-0.3l0,0l-0.3,0.1L57.6,27h-0.3l-0.1,0.2l0.2,0.1l0.2-0.4l0.3,0.1l0.2-0.6 M51.3,26.5l0.3,0.5l-0.2,0.2
			l-0.3-0.5L50.9,27l-0.2,0.1l-0.1-0.7h0.2l0.1,0.6l0.3,0.6l0.2-0.3l0.4,0.5l0.2-0.3l0.2-0.2L52,27l-0.2-0.4h0.4l0.1,0.3H52L51.6,27
			l0.3,0.4l0.4,0.4l0.1-0.3l0.1-0.2V27h-0.4v0.2l0.3,0.1l0.4,0.1l0.1-0.4l0.4,0.1v0.4h-0.4v0.4l-0.4-0.2L52,27.3l0.2-0.1 M58.4,26.4
			l-0.1,0.4L58,27.3l0.2,0.3l0.3-0.6l0.3,0.2l0.1-0.7l0.3-0.1l-0.1,0.8h-0.2l-0.3,0.7l-0.2-0.2L58,28.2l-0.2-0.3l-0.2-0.3l-0.2-0.2
			L57,27.6l-0.1-0.3l0.2-0.1 M46.9,25.3l0.3,1.2l0,0l0.4,1l0.1-0.6l0.1-0.7 M47.2,26.6l0.4,1.2v-0.3v-0.1 M55.1,26.6l0.1,0.3h-0.5
			v0.3h-0.5v0.3h-0.5V28l-0.5-0.1L53,28.3l-0.5-0.2l-0.1,0.4L52,28.2l-0.2,0.4l-0.4-0.4l-0.2,0.4L50.8,28l-0.3,0.2l-0.3-0.8h0.3
			l0.3,0.7l0.2-0.3l0.4,0.5l0.2-0.3l0.4,0.4l0.2-0.3l0.4,0.2l0.2-0.3l0.4,0.1l0.1-0.4h0.4v-0.3H54v-0.3h0.5v-0.3h0.4 M59.7,26.6
			L59.7,26.6L59.4,27l-0.2,1l0.4-0.2L59.7,26.6 M48.3,26l-0.1,1l-0.1,0.7l-0.5-0.8l-0.4-1 M62.1,26.4l-0.4,1v-0.6l0,0 M61.6,26.7
			l-0.5,0.8L61,26.9l0.5-0.8 M55.2,26.9v0.3h0.5v0.3h0.5v0.4l0.4-0.1l0.1,0.4l0.4-0.3l0.2,0.3l0.4-0.4l0.3-0.5l-0.2-0.2l-0.3,0.4
			l-0.4,0.4l-0.2-0.3l-0.4,0.2l-0.1-0.4H56L55.9,27h-0.4v-0.3L55.2,26.9 M60,27.3v0.1l-0.4,0.4l-0.2,1l-0.3,0.1l0.2-0.8H59l0.2-0.9
			l-0.3,0.1l-0.2,0.8l-0.3-0.2L58,28.6l-0.2-0.4l-0.4,0.4l-0.2-0.4l-0.4,0.3l-0.1-0.4l-0.4,0.1l-0.1-0.4h-0.4l-0.1-0.4h-0.5v-0.3
			h-0.5v0.3h-0.5v0.4h-0.5l-0.1,0.4L53,28.3l-0.1,0.4l-0.5-0.2l-0.2,0.4l-0.4-0.3L51.7,29l-0.4-0.4L51,28.9l-0.4-0.8h-0.4l0.2,0.7
			H50L49.9,28l-0.3-0.2v-1 M49.3,27.4l0.1,1.1l0,0l0.4,0.1l-0.2-1L49.3,27.4L49.3,27.4 M62.1,26.8l-0.4,1v-0.4 M59.1,28.8L59.1,28.8
			h-0.4l0.2-0.7h-0.3l-0.4,0.8L58,28.5L57.6,29l-0.2-0.4L57,28.9l0,0l-0.1-0.4l-0.5,0.2l-0.1-0.4l-0.5,0.1V28h-0.5v-0.4h-0.5V28
			h-0.5v0.4h-0.6l-0.1,0.4L53,28.6L52.9,29l-0.5-0.2l-0.2,0.5L51.8,29l-0.2,0.4L51,28.9l0,0l-0.5-0.1l0.2,0.4l-0.2,0.1l-0.3-0.6
			l-0.5-0.1l0.5,0.7l0.2-0.1l0.5,0.4l0.1-0.3V29 M48.1,27.7L48.1,27.7v0.5l-0.5-0.7 M61.6,27.5l-0.5,0.7v-0.5l0,0 M48.7,27.8
			L48.7,27.8v0.6l-0.6-0.7 M60.5,27.7v0.7l0.6-0.7 M61.6,27.8L61.6,27.8l-0.1,0.3l-0.2,0.3l-0.2,0.3L61,28.8v-0.1l0.1-0.1v-0.3
			l-0.6,0.6v-0.4l-0.7,0.7v-0.5l0.6-0.8 M48.1,28.2L48.1,28.2v0.4l0.1,0.3l-0.1-0.1L48,28.7l-0.2-0.3L47.7,28l-0.1-0.2v-0.1
			 M54.6,27.9v0.4h0.6v-0.4H54.6 M50.7,29.2l0.4,0.2l0.4,0.3l0.1-0.4l0.4,0.3l0.2-0.4l0.5,0.2l0.2-0.4l0.5,0.1l0.1-0.4H54v-0.4h0.5
			v0.4H54v0.5l-0.6-0.1l-0.1,0.5l-0.6-0.1L52.6,30L52,29.7v0.5l-0.5-0.4v0.4L51,29.8v0.1l-0.6-0.5l0,0 M57,28.8L56.5,29l-0.2-0.3
			l-0.5,0.1l-0.1-0.4h-0.5v0.4l0,0h0.6l0.1,0.4l0.5-0.1l0.1,0.4l0.5-0.2l-0.1-0.5 M61,28.5l0.5-0.7 M49.3,28.5l-0.6-0.8 M59.1,28.8
			l-0.3,0.6l0.2,0.1l0.5-0.7l0.4-0.1l0,0l0.2-1.1 M50.2,29.4l-0.1,0.1l-0.8-1V29l-0.7-0.7v0.4v0.1v0.3L48,28.6l-0.5-0.7 M61,28.7
			l-0.1,0.2l-0.2,0.2v0.2L61,28.7l0.3,0.3l0.1-0.7 M48.7,28.8l-0.6-0.6 M55.2,28.8h-0.6v0.4H54v0.5l-0.7-0.1l-0.1,0.5L52.6,30
			l-0.1,0.4L52,30.2v0.4l-0.5-0.3v0.2L50.9,30l0,0 M61.1,28.7l-0.6,0.6l0.1-0.4l0,0 M48.2,28.9l0.3,0.6v-0.2l-0.1-0.4H48.2
			 M58.2,28.9v0.6l-0.4,0.3l-0.1-0.4l-0.5,0.3l-0.1-0.4l0.5-0.3l0.2,0.4L58.2,28.9l0.5-0.1l-0.1,0.4l0.2,0.1l-0.5,0.4l-0.1-0.3
			l0.4-0.2 M48.1,28.7L48,28.9v-0.6 M48,28.9V29l-0.2,0.2V28 M59.9,28.6l-0.8,1L59,29.5L58.5,30v-0.2L58,30.2v-0.4l-0.5,0.4
			l-0.1-0.4L56.8,30l-0.1-0.5l-0.6,0.1L56,29.2h-0.6v-0.4 M60.7,28.8L60,29.4l0.1-0.3l-0.8,0.6v-0.2l-0.7,0.6V30L58,30.4v-0.2
			l-0.5,0.4v-0.4L57,30.4L56.8,30l-0.6,0.1L56,29.7l-0.7,0.1l-0.1-0.5h-0.7v0.5h-0.7l-0.1,0.4H53l-0.1,0.4l-0.6-0.2v0.4l-0.6-0.3
			v0.2l-0.5-0.3l0,0 M60.7,29.4l-0.1,0.2l0.3,0.6l0.2-1.1l0.2,0.3v-1.3 M48.4,29.4v0.1l-0.3,0.6L47.8,29 M60.9,28.9l-0.6,0.5
			l0.1-0.2l-0.7,0.5l0.1-0.2L59,30v-0.2l-0.8,0.5l0.1-0.2l0,0 M48.3,28.9l0.6,0.5l-0.1-0.2l0.7,0.5l-0.1-0.2v-0.3l0.8,0.6v-0.2
			l0.7,0.6V30 M50.9,30.1l0.1,0.1l-0.8-0.5l0.1,0.2l-0.8-0.5l-0.7-0.5 M48.9,29.4L48.9,29.4l0.2,0.3l-0.6-0.5 M60.8,29.1l-0.6,0.6
			l0.2-0.3l0,0 M49.5,29.7L49.5,29.7l0.2,0.3L49,29.5 M60.3,29.5L59.5,30l0.2-0.3l0,0 M58.3,30.2l-0.6,0.5v-0.1L57.2,31v-0.3
			L56.7,31v-0.5L56,30.6v-0.5l-0.7,0.1v-0.5h-0.7v0.5h-0.7l-0.1,0.5l-0.7-0.1v0.5l-0.6-0.2v0.3L52,30.9l0,0 M50.2,29.9L50.2,29.9
			l0.1,0.2l-0.8-0.4 M58.9,29.9L58.9,29.9l-0.2,0.2l0.9-0.5 M60.7,29.5l-0.5,0.6l-0.1-0.4l-0.7,0.5l0.1-0.3l-0.8,0.4l0.1-0.2
			L58,30.5l0.1-0.2l0.2-0.2l0,0 M48.4,29.5l0.6,0.7v-0.5l0.7,0.4l-0.1-0.2l0.8,0.4l-0.1-0.2l0.8,0.4L51,30.4v-0.1v-0.1l0.6,0.4
			l-0.1-0.2 M58.7,30.3L58.7,30.3l-0.1,0.3l0.8-0.4l-0.1,0.5l0.8-0.6V30 M50.5,30.3L50.5,30.3l0.1,0.3l-0.8-0.4l-0.1-0.1 M57.2,30.8
			L57.2,30.8l-0.5,0.3v-0.3L56.1,31v-0.4l-0.7,0.1v-0.5h-0.8v0.5h-0.8v0.4L53.1,31v0.3l-0.6-0.2l0.1,0.2L52,31l-0.1-0.2 M60.1,30.2
			l0.4,0.7l0.5-0.8l0.4,0.8v-0.1v-0.6v-1 M51,30.4L50.2,30 M58,30.5l0.9-0.5 M50.5,30.4l0.8,0.4v-0.1l-0.1-0.1l0.5,0.3l-0.1-0.1
			v-0.1L52,31v0.1l-0.4-0.2l0.1,0.2l-0.5-0.4 M51.6,30.7L51,30.3 M58.1,30.4l-0.6,0.3l0.1-0.1l0.1-0.2 M57.6,30.7l0.6-0.4
			 M47.7,29.3V31l0,0l0.4-0.9l0.5,0.8l0.4-0.8l0.9,0.6l-0.1-0.5 M59.3,30.7L59.3,30.7l-0.8,0.2l0.1-0.4L57.8,31v-0.2h0.1l0.1-0.2
			L57.4,31l0.1-0.2l0.1-0.1l-0.4,0.2v-0.1 M56.1,31L56.1,31l-0.7,0.1v-0.4h-0.8v0.4h-0.8v0.3l-0.7-0.1v0.2l-0.6-0.2v0.1l-0.4-0.2
			L52,31 M57.8,30.7l0.8-0.4 M47.6,30.9l-0.1,1.3l0.1,0.3V30.9l0.7,1.1l0.4-1.1l0.7,0.7l0.5-0.8l0,0l0.8,0.2l-0.1-0.4l0.8,0.4
			l-0.1-0.3l0.6,0.4L51.8,31l0.5,0.3v-0.1 M56.9,31.2L56.9,31.2l-0.3,0.1L56.9,31.2l-0.1,0.1l0.5-0.3l-0.1,0.2l-0.1,0.2l0.6-0.4v0.4
			l0.7-0.4l-0.1,0.5l0.3,0.3l0.7-1.1l0.5,0.9l0.7-0.8l0.4,1.1l0.6-1V31v-0.6 M57.2,31L57,31.1l-0.4,0.2L57.2,31L57.2,31l0.2-0.2
			 M56.8,31.3L56.8,31.3l-0.1,0.2l0.5-0.3l0.6-0.4 M53.2,31.5L53.2,31.5l0.7,0.1v-0.2h0.8v-0.3h0.8v0.3l0.7-0.1V31 M49.9,30.7
			l0.7,1.1l0.3-0.3l-0.2-0.6l0.7,0.4V31l0.6,0.4v-0.2l-0.1-0.1 M56.7,31.3l0.1-0.2l-0.6,0.2L56,31.5L56.7,31.3L56.7,31.3l0.3-0.1
			l-0.4,0.2l0,0 M52,31.3l0.5,0.4l-0.1-0.2l0,0 M52.7,31.5L52.7,31.5l0.2,0.1v0.1l-0.5-0.3 M56.8,31.4l-0.6,0.4l0.1-0.2l0.1-0.1h0.1
			 M56.5,31.5l-0.6,0.2l0.1-0.1l-0.7,0.1v-0.2h-0.8v0.2h-0.7v0.1l-0.6-0.1l-0.1-0.1l-0.5-0.2 M49.4,31.6L49.4,31.6l0.9,0.9l0.3-0.7
			l0.7,0.8l0.2-0.6v-0.7l0.6,0.4v-0.4l0.5,0.3v-0.1l0.5,0.3L53,31.6l0.6,0.3l-0.1-0.2l-0.1-0.1l-0.5-0.1 M56.4,31.6l-0.5,0.2l0,0
			l-0.6,0.1l0.1-0.1h-0.8v0.1h-0.7V32l-0.6-0.1 M58.3,31.6l-0.5,0.5v-0.6l-0.6,0.4v-0.4l-0.5,0.3v-0.2L56.2,32l0.1-0.2L55.7,32
			l0.1-0.2l0.1-0.1 M53,31.9L53,31.9l0.1,0.2l-0.5-0.3l0,0 M56.2,32L56.2,32l-0.1,0.2l0.5-0.3l0,0 M55.8,31.8l-0.6,0.1v-0.1h-0.7
			v0.1h-0.6l0.1,0.2L53.4,32v0.2l-0.6-0.3 M52.5,31.7v0.4l0.6,0.3L53,32l0.6,0.3l-0.1-0.2l0.5,0.1V32h0.6v-0.2h0.7V32l0,0h-0.6v0.2
			h-0.5v0.1l-0.5-0.1v0.3l-0.5-0.2V33l-0.6-0.3V32L52,31.8l-0.1,0.6L51.4,32l-0.5-0.4 M56.6,31.9v0.3L56,32.5l0.1-0.4l-0.5,0.2
			l0.1-0.2l0,0l0.1-0.2L55.3,32 M61.4,31.1v1.6v0.1l-0.6,0.6l-0.1-1.3L60,32.8l-0.2-1.1l-0.9,0.8l-0.3-0.7l-0.7,0.8L57.8,32
			l-0.6,0.4l-0.6,0.4v-0.7l0.5-0.3l0.1,0.6l0.1,0.7l0.6-0.5l0.1,0.7l0.9-0.8l0.2,1l1-0.7V34l0.9-0.6l-0.1,1.5l0.6-0.5l0.2-0.6
			l-0.1-1.5l-0.1-1.4 M55.7,32.2l0.5-0.2 M55.7,32.2l-0.5,0.1v-0.2 M49.5,31.7l-0.3,1l1,0.8l0.2-1l0.8,0.7l0.1-0.6l0.6,0.5v-0.7
			l0.6,0.4l-0.1,0.7l0.6,0.4v-0.8l0.6,0.3l-0.1-0.7l0.5,0.1v-0.3l0.5,0.1l0.5-0.1l0,0v-0.1h-0.6v0.2v0.3h-0.5v0.7l-0.4-0.1v0.8
			L53.1,34v0.7l-0.6-0.4l-0.6-0.4l0.1-0.6l0.6,0.4v0.7L52.5,35L52,34.6V34l-0.7-0.5v-0.1 M60,35.3l-1.1,0.4l0.2-1.2l-1,0.4V34
			l-0.7,0.4v-0.6l-0.6,0.4v-0.7l-0.6,0.4v-0.8l-0.6,0.2v-0.7l-0.5,0.1v-0.3 M55.1,32.5l0.5-0.1l-0.1,0.3l0.5-0.2v0.7l0.6-0.3v0.7
			l0.6-0.4v0.6l0.7-0.5L58,34l1-0.6v1.1l1-0.6l-0.1,1.3l1-0.5 M61.4,32.7l0.2-0.4 M48.3,32l-0.1,1.3l0.9,0.6v-1.3L48.3,32
			 M47.7,32.8v1.5l0.7,0.5l-0.1-1.5L47.7,32.8v-0.2 M54.6,32.8v0.7l0.3-0.1v0.8l0.5-0.2v0.7l0.6-0.3l0.1,0.6l0.7-0.4l0.3,0.6
			l0.7,0.7l0.3-1.2h-0.6l-0.1-0.4l-0.6,0.4l-0.1-0.6L56,34.6v-0.7l-0.6,0.3v-0.8L55,33.5v-0.7H54.6 M51.2,33.3L51.1,34l0.7,0.4
			L51.7,35l0.4,0.5l0.3-0.6l0.7,0.4l0.1-0.6l0.6,0.3v-0.7l0.5,0.2v-0.9h0.4v0.8h-0.4v0.7l-0.4-0.2l-0.1,0.7l-0.6-0.4l-0.3,0.7
			l-0.6-0.5l-0.7,0.7l-0.2-1.2V34l-1-0.6v1.1l-1-0.6l0.1,1.3l-1-0.4l0.2,1.7L47.9,36v-0.2l-0.1-1.6l-0.2-0.5l0.1-1.5 M62.2,35.2
			l0.6,0.5l0.2,0.5l-0.6-0.5L62.2,35.2L62,34.9l-0.2-0.6v-0.5 M61.8,34.3v0.8l-0.4,1l0.1-1.7v-1.5 M47,35.2l-0.6,0.5l-0.2,0.5
			l0.6-0.5L47,35.2l0.2-0.2l0.2-0.6l0.1-0.6 M57.5,35l-0.4,0.5L56.4,36l0,0l-0.3-0.7l-0.6,0.3v-0.7L55,35.1v-0.7h-0.4v0.7h-0.4v0.7
			l-0.5-0.2l-0.2,0.7l-0.8-0.4l-0.4,0.7l-1-0.5l0,0l-1-0.4l-0.2-1.2l1,0.4h0.6 M47.8,35.9l-0.4-0.8v-0.7 M56.4,35.9l-0.7,0.4
			l-0.2-0.7L55,35.7V35h-0.4v0.7h-0.4l-0.1,0.8l-0.6-0.2l-0.3,0.8l-0.8-0.4l0.2,1.1l-0.9-0.4l-0.3-1.3 M47.8,36.1v1.8l1.1,0.2
			l-0.3-1.6l1,0.3l-0.3-1.5l1.1,0.4l0.3,1.4l-1.1-0.3l0.3,1.4l-1-0.1l0.5,1.3h-1l-0.7-1.5l0,0l-0.6-1.6V36v-1 M46.8,35.6v1.2
			l0.3,1.4l0.4,1.1l1-0.1l0.6,1h1l-0.6-1l1.1,0.1L50,38.1l1.2,0.3L50.8,37l1.1,0.3l0.4,1.3l0.8,0.2l-0.4-1.2l0.7,0.3l-0.2-1l0.8,0.2
			l0.2-0.8l0.6,0.1v-0.8h0.5l0.1,0.8l0.6-0.2l0.3,0.8l0.8-0.4L56.5,36 M61.8,35.1l0.2,1v-1.3 M47.2,35.9l0.2-0.9 M61.4,36L61.4,36
			l-0.8,0.5l0.2-1.7 M57.8,36.2l-0.3,1.3l-0.8,0.3l0.2-1.1L57.8,36.2L57.8,36.2l1-0.4l-0.3,1.4l1.1-0.3l0.3-1.5 M62.4,35.6v1.2
			l0.6,0.5v-1.2l0.9,0.5l-0.2-0.5l-0.9-0.5 M47.2,36.2l-0.4,0.6l-0.6,0.5v-1.2l-0.8,0.4l0.1-0.4l0.9-0.5 M62.1,36.2l-0.6,1.6
			l0.7,0.4l0.3-1.5L62.1,36.2L62.1,36.2 M60.7,36.5L60.7,36.5l-1,0.3l-0.5,1.4l1.1-0.2L60.7,36.5 M56.6,37.8L56.6,37.8l-0.7,0.3
			l0.2-1l-0.7,0.2l-0.2-0.8h-0.5v0.8H54l0.1,1l-0.6-0.1l0.2,1L53,39l0.4,1.1l-0.8-0.2l-0.5-1.2L51,38.4l0.5,1.2l-1.1-0.2l0.5,1
			l-1-0.1l0.5,0.9h-1.1L49,40.4h-1.1l-0.4-1l-1,0.2l-0.2-1l-0.2-1.3l-0.8,0.5l-0.1-1.1l0.1-0.1"
        />
        <path
          class="st0"
          d="M61.4,36v1.8l0,0l-0.6,1.5l1,0.1l0.4-1.2l0.7,0.3l0.2-1.3l0.8,0.5l0.1-1.1l1,0.5l-0.1-0.5l-1-0.5 M45.3,36.6
			l-1,0.5l0.1-0.4l1.1-0.6 M61.4,37.9l-1.1,0.2l-0.6,1.3h1l-0.6,1h1.1l0.4-1l1,0.2l0.2-1l0.8,0.5l0.1-1.3l1,0.5l0.1-1.2l1.1,0.6
			l-0.1-0.4l-1.2-0.6 M47.7,37.9L47,38.3l-0.7,0.3L45.5,39l-0.1-1.3l-1,0.5L44.3,37l-1.1,0.6l0.1-0.4l1.2-0.6 M56.6,37.8L56.3,39
			h-0.6l0.2-1l-0.6,0.1l0.1-1h-0.7v1h-0.6l0.1,0.9h-0.5l0.4,1l0.3-0.3L54,39.1l0.5,0.1v-1h0.7l-0.1,0.9h0.5l0,0 M58.5,37.2L58,38.6
			l-1,0.3l0.4-1.3L58.5,37.2 M67,37.9l1.3,0.8V39l-1.2-0.7l-0.1,1l-1.2-0.5l-0.3,1.1l-1.1-0.4v0.7l-1-0.3v0.6l-1.1-0.1l0.1,0.6
			l-1.3,0.1l0.2-0.8h1.1l0.2-0.8l1,0.3l0.1-0.8l0.9,0.4l0.2-1.2l1.1,0.5l0.1-1.1l1.2,0.6L67,37.9l-1.2-0.7 M44.6,39.5L44.6,39.5
			l0.1,0.7l0.9-0.3l0.1,0.6l1.1-0.1l0.1,0.7l1.2,0.1l-0.2-0.8h-1.1l-0.2-0.8l-1,0.3l-0.1-0.8L44.6,39.5l-0.2-1.2l-1.1,0.5l-0.1-1.1
			L42,38.2v-0.4l1.3-0.7 M59.2,38.2l-0.5,1.2l-1.1,0.2l0.5-1.2L59.2,38.2 M44.6,39.5l-1,0.4l-0.3-1.1l-1.2,0.4l-0.1-1l-1.3,0.7v-0.3
			l1.3-0.7 M68.4,38.6l1.4,0.7v0.1l-1.4-0.6l-0.1,0.7L67,39.1l-0.3,1.1l-1.2-0.5l-0.3,0.5L64.4,40l0.1,0.4l-1-0.1V41h-1.2l-0.1,0.9
			l-1.4,0.2l0.2-0.9l-1.2,0.1l0.3-0.9h-1l0.6-1l-1.1,0.1L58,40.6l-1,0.2l0.6-1.1l-1,0.3l0.5-1.2L56.3,39l-0.5,1.2l0.7-0.2l-0.6,1
			l1-0.2l-0.6,0.8l1.2-0.2l0.6-0.8l1.1-0.1l-0.5,0.9h1.2l-0.3,1h-1.2h-1.2l0.5-0.7l1.1-0.1l-0.4,0.8l-0.4,1l-1.1-0.3l0.2-0.7
			l-1.2,0.2l0.5-0.7l-1,0.2L56,41l-0.7,0.2l0.5-1l0,0 M54.2,39.9l0.4,0.2v-1l0.6-0.1L55,40l-0.4,0.2v1.1L54,40.2h-0.5l0.5,1L53.2,41
			l-0.6-1.1l-1-0.2l0.6,1.1L51,40.5l0.6,0.8l-1.1-0.1l0.4,0.8h1.2l-0.5-0.7l1.2,0.2l-0.6-0.8l1,0.2l0.6,0.8l-1-0.2l0.5,0.7L52.1,42
			l0.2,0.7l1.1,0.1l-0.2-0.6l1.3,0.2l0,0l-0.8-0.6h0.4l-0.3-0.6l0.7,0.1l0,0l0.7-1.1l0.4-1.1 M39.3,39.3l-1.5,0.5L39.3,39.3l0.3,0.5
			l1.3-0.3v0.7h1.5l-0.3-1.1l-1.3,0.4l-0.1-0.7l-1.4,0.6V39.3l1.4-0.7 M69.8,39.4l1.6,0.6l-1.6-0.4L69.6,40l0.1,0.4h-1.4v-0.7
			l1.3,0.3l1.3,0.1l0.5-0.1l1.6,0.3h-1.7L70.9,40 M38.1,40l-0.4,0.2l1.7,0.1l0.1-0.4L38.1,40l-0.5-0.1h0.1 M55.7,40.2h-0.5L55,40
			 M36.1,40.3L36.1,40.3L36.1,40.3h1.6l0.1-0.1L36.1,40.3l1.6-0.4 M69.7,40.1l0.1,0.3l1.6-0.2l-0.1,0.2l1-0.1l0.5-0.2l1.1,0.3
			l0.7,0.1L73,40.1l-0.2,0.1h-1.5V40L69.7,40.1 M37.7,40.3L37.7,40.3l1.6,0.2l0.1-0.3h1.4l-0.1,0.5l-1.4-0.2l-0.1,0.4l-1.4-0.3
			L37.7,40.3 M43.6,39.9l-1.2,0.5l1.1,0.3h1l-0.1,0.5h1.2V42l1.3,0.1l-0.1-0.9h-1.2v-0.5l-1.1,0.1l0.2-0.5l-0.8,0.2L43.6,39.9
			 M36.7,40.5l-0.4,0.2L35,40.6l1.1-0.4L36.7,40.5l1.1,0.1V41l1.4,0.5V41l1.3,0.4l0.2-0.5L42,41l0.3-0.6l0,0 M72.3,40.5l0.4,0.2
			l-1.3,0.4v-0.5L69.8,41l-0.1-0.4l-1.4,0.2l-0.1-0.5h-1.5l0,0l-1.1,0.3l-0.4-0.3 M36,40.3l-1.6,0.5l0.7-0.1l-0.4,0.6l1.7,0.1l0.6,1
			l1.8,0.3l-0.7-1L37.8,41l-1.4-0.3v0.7l1.7,0.4l1,0.2l0.1-0.5l1.2,0.6l0.1-0.8l1.2,0.3l0.2-0.7l1.2,0.1l0.3-0.4l0.4-0.3 M65.6,40.6
			l0.2,0.4h-1.2l0.1,0.7l0,0h-1.3V41h1.2l-0.2-0.4H65.6 M72.7,40.7v0.7l1.7-0.1L74,40.6L72.7,40.7 M74.7,40.7l1.5,0.8l-1.8-0.2
			l-0.3,0.8l2,0.1l0.2-0.7l1.7,1l-0.2,0.4L76,42.2l-0.5,0.9l-2,0.1l0.5-1.1l-1.9,0.3l0.6-1l-1.6,0.3l0.2-0.7l-1.4,0.5v-0.6l-1.3,0.4
			l-0.2-0.5L67.2,41l-0.4-0.6 M67.2,40.9l0.2,0.7l-1.2,0.1L65.9,41L67.2,40.9 M39.1,42.1l0.9,0.7l0.5-0.6l1.2,0.5l0.1-1l1.3,0.1
			l0.2-0.7h1.2l-0.1,0.7H43l-0.1,1.1l-1.3-0.3l-0.5,1.3L40,42.7h-1.3l0.6,1.2l-1.8-0.3L37,42.4l-2-0.2l-0.3-0.8l-1.8,0.2l1.5-0.7
			 M48.1,41.1l0.2,1l1.3,0.1l-0.3-1L48.1,41.1 M54.6,41.2V42v0.4l0.9-0.6H55l0.3-0.6L54.6,41.2 M68.5,41.3l0.1,0.8l-1.2,0.5l-0.1-1
			L68.5,41.3 M78,42.4l1.4,1.1v0.3l-1.6-1.1l-0.4,0.8L75.6,43l-0.4,1.4l-1.8,0.1l0.1-1.4l-2,0.4l0.5-1.2l-1.7,0.3l0.7-1l-1,0.3
			l-0.2-0.5L68.6,42l0.5,0.6L68,44l-0.5-1.3l-1.3,0.2l-0.1-1.1h-1.3 M44.4,41.8L44.3,43l1.4,0.1v-1.3H44.4 M64.8,41.8l0.1,1.2
			l-1.3,0.1v-1.3l-1.3,0.1l-0.1,1.4l1.4-0.1v1.6l1.5-0.2l0,0L64.9,43l1.3-0.1l0,0 M55,41.8l-0.4,0.1 M54.2,41.8l0.4,0.2 M31.2,42.5
			l-1.4,1.1l0.1,0.3l1.6-1.1l0.4,0.8l1.8-0.5l0.4,1.4l1.8,0.1l-0.1-1.4l-2-0.1l-0.5-0.9l-1.7,0.6L31.2,42.5l1.7-1l0.2,0.7l1.9-0.1
			l0.5,1.1l2,0.4V45l1.6,0.4l0.2-1.4h1.8l0,0l1.6,0.4l0.2-1.5l1.4,0.1l-0.2,1.6l1.5,0.2l0.1-1.6l1.4,0.1l-0.2-1.4l1.4,0.2l0.3,1.3
			l-1.5-0.1l0.1,1.5l-1.6-0.1l-0.2,1.7h-1.7l-1.5-0.3l0.6-1.7l1.4,0.2l-0.5,1.8L43,48.6L41.3,48l0.8-1.9l-1.4-0.4l0.5-1.7
			 M50.9,42.1l0.3,1L50,43.3l-0.4-1.2H50.9 M52.5,43.4l-0.6,0.5L51.2,43l1.1-0.2L52.5,43.4l0.9,0.1l0.1-0.6l1.2,0.1v-0.5l1.3-0.1
			L55.8,43l1.2-0.1l-0.1,0.6l0.7,0.5l0.4-1l1.2,0.3l0.4-1.2l1.3-0.1l-0.2,1.3l0,0h-1.4l-0.8,1.4v-0.1l-1-0.8l-1.2,0.5L56,43.5
			l-0.1-0.6L54.6,43v0.6l-1.2-0.1l-0.3,0.8l-1.2-0.5l-1.1,0.9L50,43.3h-1.4l0.3,1.5h-1.7v1.4l1.9-0.1L49,44.8l1.9-0.1l2,0.6l0.3-1
			l1.6,0.2v-0.9l1.2-0.1l0.9-0.1 M66.3,42.9l0.3,1.5L68,44l1.8-0.1l0,0l0.5-1.2H69l1-0.7 M60.6,43.4l1.5-0.1L62,44.8l1.5-0.1
			l0.2,1.7L62,46.2v-1.4h-1.7L60.6,43.4 M69.8,43.8l1.7-0.3l0.1,1.4l1.8-0.4l0.1,1.7l1.4-0.1l0.2-1.7l1.7,0.5l0.5-1.2l1.6,1l0.3-0.7
			l1.4,1.3V45l-1.3-1.4 M50.8,44.8l0.1,1.4l1.8,0.2l0.1-1l1.8,0.2v-1l1.5-0.2l0.3,1l1.9-0.6 M29.7,43.6l-1.3,1.5v0.2l0.3,0.6
			l1.4-1.3l0.6,1.1l1.5-0.8l0.5,1.6l1.4-0.3l0.2,1.8l1,0.1l0.2-1.8l1.4,0.3l0.4-1.7l-1.8-0.4l-0.1,1.7L34,46.1l-0.3-1.7L32,44.9
			l-0.5-1.3l-1.6,1l-0.3-0.7l-1.3,1.4l-1.2,2v-0.1l1.2-2.2 M65.1,44.6l1.4-0.2l0.6,1.8l1.4-0.4L68,44 M58.4,44.8l-0.1,1.4h1.9
			l0.1-1.4H58.4 M80.7,45l1.2,2.1v0.2l-1.2-2l-0.3,0.6L79,44.6l-0.6,1.1l-1.6-0.8l-0.5,1.6L75,46.2L74.8,48l-1,0.1l-0.2-1.8
			l-1.4,0.3L71.7,45L70,45.3l-0.2-1.5 M54.6,45.5v1l1.9-0.1l-0.1,1.1h1.8l0.2-1.2l-1.8,0.2v-1H54.6 M27.3,47.2l-1,3.1l0,0L27.3,47.2
			l0.4,0.5l1.1-1.8l0.6,1l1.3-1.1l0.7,1.4l1.3-0.6l0.5,1.7l1.1-0.2l0.1,1.8l0.7,0.1l0.2-1.8l1.1,0.3l0.5-1.8l1.4,0.4l1.4,0.5
			l0.8-1.8L39,45.4L38.5,47l-0.8,1.8l-1.2-0.4L36,50.2l-0.8-0.3L35,51.7l-0.4-0.1l-0.1-1.7L33.8,50l-0.5-1.7l0,0 M70,45.4l0.7,1.6
			l-1.4,0.4l-0.8-1.7L70,45.4 M50.8,46.2l0.2,1.2l-1.9,0.1L49,46.2H50.8 M60.2,46.2L60,47.5l1.9,0.2l0.1-1.4L60.2,46.2 M74.5,54.3
			v0.2l0.5-1.1l0.4,0.5l0.8-1.4l0.7,0.8l1-1.4l0.6,1l1.1-1l0.2,1.5l1.2-0.2l-0.2,2l1.2,0.2v-2.3h-1.1l-0.3-2l-1.1,0.7l-0.6-1.3
			l-1,1.4L77,51l-0.8,1.5L75.6,52l-0.5,1.4l-0.3-0.2L74.5,54.3v-1.1l-0.2,0.2l-0.2-1.6L73.6,52l-0.5-1.7l-1,0.3l-0.7-1.9l1.2-0.4
			l0.5,1.8l0.8-0.3l0.2,1.7l0.4-0.1V53h0.2l0.2-1.6l0.5,0.3l0.6-1.6l1,0.9l1-1.6l1,1.1l1-1.2l0.7,1.8l1-0.5l0.4,2.5l0.9,0.1
			l-0.5-2.9l-0.8,0.3l-0.8-2.3l-1,1l-1-1.3l-1,1.4l-1-0.8l-0.7,1.6L75.4,50L75,51.6h-0.5v-1.7l0.8,0.1l0.4-1.8l1.1,0.5l0.8-1.6
			l1.2,1l0.8-1.3l1.1,1.6l0.7-0.7l0.9,2.7h0.4l-1-3l-0.3,0.4l-1.1-1.9l-0.6,1l-1.3-1.1l-0.7,1.4l-1.3-0.6l-0.5,1.7L74.8,48l-0.1,1.8
			L74,50l-0.2-1.9l-1.1,0.3l-0.6-1.8L70.7,47l0.8,1.8l-1.3,0.5l-1-1.8L67.9,48L67,46.1l-1.5,0.3L65,44.6 M49.6,48.8l0.6,1.3l1.4-0.2
			l0,0l-0.4-1.3l1.6-0.1l0.2,1.2h1.4V51h1.2l0.3-1.4l1.4,0.2v-0.1l0.4-1.2l1.7,0.2l0.5-1.3L58,47.3l-0.2,1.2l-1.7-0.1L56,49.8h-1.4
			v-1.2h1.6l0.2-1.1h-1.8v1.1H53l-0.1-1.1h1.7v-1l-1.9-0.1l0.1,1.1H51l0.3,1.2L49.6,48.8L49.6,48.8l-0.5-1.3l-1.9,0.2l-0.1-1.4
			l-1.8,0.1 M63.8,46.4V48l2.3,0.6l-0.6-2.2L63.8,46.4 M39.9,47.4l-1,1.8l1.2,0.5l1.1-1.8L39.9,47.4 M33.3,48.2l-1,0.5l-0.8-1.6
			L30.3,48l-0.8-1.2l-1.1,1.6l-0.7-0.7l-0.9,2.7h-0.5l-0.6,3.2l0.5-0.3l0.6-2.9l0.7,0.3l0.8-2.3l0.9,1l1-1.3l1,1.3l1.1-0.7l0.7,1.6
			l0.8-0.4l0.3,1.6l0.5-0.1V53h-0.2l-0.3-1.5l-0.5,0.3L33,50.3L32.1,51l-0.9-1.5v-0.1 M49.6,48.8l-1.8,0.3l-0.6-1.4L45.4,48v-1.6
			 M61.9,47.6L61.3,49l1.9,0.5l0.6-1.6L61.9,47.6 M45.4,48l0.6,1.4l-1.9,0.8l-1-1.6l0,0L45.4,48 M57.5,49.9l1.4,0.2l-0.7,1.4
			l1.4,0.4l1-1.4l0.9-1.4l-1.8-0.3L59,50.2l1.6,0.4l1.8,0.5l1-1.4l1.9,0.7l1-1.7l0,0L68,48l1.1,1.8l1.1-0.6l1,1.7l1-0.4l0.6,1.7
			l0.8-0.3l0.4,1.6l0.4-0.2l0.1,1.4l0.1-0.2v-0.1 M31.2,49.5l-0.9,1.1l-0.7,1.3l-1.1-0.7l-0.4,2H27l0.5-2.6l1,0.5l0.8-1.8l1.1,1.2
			l1,1.3l0.8-0.9l0.8,1.5l0.6-0.6l0.5,1.4l0.2-0.2l0.2,1v-1l0.3,0.2l0.2-1.6l0.5,0.3l0.5-1.7l1,0.3l0.7-1.9l1.3,0.5l-1,1.7l-1.1-0.4
			l-0.6,1.7l-0.7-0.3l-0.4,1.6l-0.4-0.2l-0.1,1.4l-0.2-0.2V54 M46,49.4L46,49.4l1.9-0.4l0,0l0.8,1.3v0.1l1.6-0.4l0.7,1.4l1.2-0.2
			l-0.5-1.4 M51.8,49.8l1.4-0.1l0.2,1.4h1.2v1.5h1l0.2-1.5l0,0 M74.6,54.5l0.2,0.5l0.7-1.1l0.6,0.7l0.8-1.3l0.7,0.8l0.9-1.2l0.4,0.9
			l0.9-0.3l-0.2,1.7l1.3,0.2l-0.1,2.4l0,0l1.6,0.1L82,55.4l1,0.3l-0.2-2.4l0.5,0.2l-0.6-3.1h0.1l-1-3.2 M43.1,48.6l-1.7,1.8
			l-1.2-0.7l-1.1,1.7L38,51l-0.8,1.7l-0.9-0.4l-0.4,1.6l-0.7-0.3L35.1,55l-0.3-0.3v0.6l-0.2-0.8v-0.1l-0.5-1.1l-0.4,0.5L33,52.5
			l-0.7,0.8l-1-1.4l-0.6,1l-1.1-1l-0.2,1.5l-1.2-0.2l0.2,2l1.3-0.2l0,0l-0.2-1.6l0.9,0.2l0.4-0.8l0.9,1.2l0.7-0.8l0.9,1.3l0.6-0.7
			l0.6,1.1l0.3-0.4 M72.9,52.2L72.9,52.2L72,52.6L71.2,51l-1,0.5L69,49.8l-1.2,0.7l-1.7-1.8 M48.7,50.4L46.9,51l-1-1.4 M55.8,51.2
			l1.2,0.1l0.5-1.3 M52.2,51.3l0.5,1.5l1-0.2l0.2,1.6h0.8V56h0.7l0.1-1.7l0.8,0.1l0.3-1.6l1,0.2l0.7-1.5L57,51.3l-0.5,1.5l-1-0.2
			l-0.2,1.6h-0.8v-1.7h-1L53.3,51L52.2,51.3 M48.7,50.5l1,1.4L48,52.3l-1-1.4l-1.8,0.6l-1-1.4l-1.7,1.1l-1-1L40,52.1l-1-0.6l-1,1.6
			l-0.9-0.4l-0.6,1.5l-0.7-0.3l-0.3,1.6L35,55.2v1.2L34.8,56l-0.1-0.2v-0.3 M72.9,52.3l0.5,1.6l-0.8,0.3L72,52.6L71.1,53l-1-1.6
			l-1,0.6l-1.3-1.6l-1,0.9l-1.7-1.1L64,51.5L62.3,51l-1,1.4l-1.7-0.5l-0.9,1.4L57.5,53L57,54.6l-0.8-0.2L56,56.1L55.3,56v1.8h-0.7
			V56h-0.7l-0.1-1.7L53,54.4l-0.3-1.6l-1,0.2L51,51.5l-1.4,0.4l0.9,1.4l1.2-0.3l0.5,1.6l0.8-0.2l0.2,1.7l0.7-0.1v1.8h-0.7v-1.7
			l-0.8,0.1l-0.2-1.6l-1.1,0.3l-0.6-1.6L49,53.7l-1-1.4l-1.8,0.6l-1.1-1.3l-1.7,0.8l-1-1.1L41,52.7L40,52l-1.1,1.6L38.1,53l-0.7,1.5
			l-0.8-0.4l-0.3,1.6l-0.7-0.3v1.4L35,56.4v-0.1 M74.5,54.6l-0.1,0.8v-0.6l-0.3,0.3L74,53.5l-0.6,0.3l0.2,1.6l-0.7,0.4l-0.3-1.6
			l-0.8,0.5l-0.7-1.6l-0.9,0.5L69.1,52l-0.9,0.7l-1.5-1.4l-1,1.1l-1.8-0.8l-1,1.3l-1.8-0.6l-1,1.4l-1.6-0.5l-0.6,1.6L57,54.6
			l-0.3,1.6L56,56.1v1.7h0.8v-1.5l1,0.2l0.4-1.5l1.5,0.4l0.7-1.5l1.8,0.5l1-1.4l1.7,0.6l1-1.1l1.6,1.1l0.9-0.7l1.1,1.5l0.8-0.6
			l0.8,1.5l0.8-0.5l0.4,1.6l0.7-0.5v1.5l0.6-0.5v-1.4l0.6-0.3v1.2l0.3-0.4v-0.2l0.1-0.3 M27.1,53.1v2.2l-1,0.3l0.1-2.4L27.1,53.1
			 M49.6,55.2L49.6,55.2l0.6,1.5l0.4,1l1,0.1h0.8v-1.5l-1,0.2L51,55L49.6,55.2l-0.7-1.4l-1.8,0.5l-1-1.3l-1.7,0.6l-1.1-1.1l-1.5,1.1
			l-0.9-0.7l-1.2,1.4l-0.8-0.6l-0.8,1.5l-0.8-0.5L37,56.1l-0.7-0.4v1.4l-0.6-0.4l0.3,1.1l-0.4-0.5l-0.1-0.2l-0.1-0.3l-0.2-0.4v-0.2
			 M82.9,50.4l0.6,3.4l-0.2-0.2v-0.1 M29.6,55.1l0.6,1.6l-1.7,1l-0.2-2.4l-1.2,0.2l-0.2,2.4l-1,0.2l0.3-2.3l-0.6,0.4l0.2-2.5
			l-0.1,0.1l0.7-3.3 M49.6,55.3l-1.7,0.4l-0.8-1.5l-1.7,0.5l-1-1.2l-1.6,0.7l-1-0.8l-1.2,1.3l-0.9-0.6L39,55.7l-0.8-0.5l-0.4,1.5
			L37,56.2v1.5l-0.7-0.5l0.2,1.2L36,57.9l0.1,0.8l-0.3-0.6l-0.1-0.2l-0.1-0.2l-0.1-0.3 M74.1,56.3L74.1,56.3l-0.6,0.5L73.2,58
			l-0.5,0.5l0.2-1.2l-0.6,0.5l-0.1-1.5l-0.7,0.4L71,55.1l-0.8,0.5l-0.8-1.4l-0.8,0.5l-1.2-1.3l-1,0.8l-1.6-0.7l-1,1.2l-1.7-0.5
			l-0.8,1.5l-1.7-0.4L59,56.7l-1.3-0.3l-0.1,1.3l1-0.1l0.5-1l1.6,0.7l0.7-1.7l1.7,0.2l0.8-1.3l1.6,0.4l1-0.9l1.3,0.9l1-0.4l0.8,1.5
			l-1-0.2l-0.8-0.9l-1,0.5l-1.3-0.5l-0.8,1L63.1,56l-0.5,1.3h1.6l0,0l0.4-1.1l1.3,0.2l0.8-0.7l1,0.6l0.8-0.2l0.2,0.7L68.2,57
			l-0.5-0.7L67,56.8l-1-0.3l-0.4,1l-1.3-0.1 M34.7,55.6L34.3,55l-0.5,0.7l-0.7-1.1l-0.7,0.8l-0.7-1.3l-0.8,0.7l-0.5-1.1 M74.4,55.7
			l0.5-0.7l0.5,0.7l0.7-1.1l0.7,0.8l0.8-1.3l0.8,0.7l0.5-1 M34.8,55.9l-0.4,0.6l-0.6-0.8L33,56.5l-0.7-1.1l-1,0.7l-0.6-1.4L29.6,55
			 M76.8,55.3L76.8,55.3l1,0.8l1.2,0.6l0.5-1.6l-1.1-0.3l-0.6,1.4L77,57.4l-1-0.8l-0.7-0.8l-0.6,0.8L74.3,56 M46.2,55.9l0.4,1.4
			l2,0.1l-0.7-1.7L46.2,55.9L46.2,55.9l-0.8-1.2l-1.6,0.4l-1-0.9l-1.3,0.9l-1-0.4l-0.9,1.5L39,55.6l-0.5,1.2l-0.7-0.3l-0.1,1.6
			L37,57.6l0.1,1.3l-0.6-0.5l-0.1-0.1 M46.1,56l-1.6,0.2l-0.8-1l-1.3,0.5l-1-0.5L40.7,56l-1,0.2l0,0l0.4,0.8l0.4-0.4l0.2-0.7
			l0.8,0.2l1-0.6l0.8,0.8l1.3-0.2l0.4,1.1h1.6l-0.1,1.4L45,58.5l-1.3-0.1v-1l-0.5-1l-1,0.3l-0.7-0.5L41,56.8l-0.5-0.2 M83.5,53.8
			l0.3,2.6l-0.2-0.3l0.2,2.2L83.3,58l1,2.7h-0.9l-1.2-2.8l1,0.2L83,55.7l0.6,0.4l-0.2-2.5 M69.4,56.2L69.4,56.2l0.8-0.6l0.5,1.2
			l0.7-0.3l0.1,1.6l-0.7-0.2l-0.1-1L70,57.1L69.4,56.2L69.1,57l-0.4-0.4 M33,56.4L33,56.4l-1,0.8L31.3,56l-1.2,0.6l0.6,1.3L29.3,59
			l-0.9-1.4l-1.6,0.2l-1.2,2.8l-0.9,0.1l1-2.6l-0.6,0.3l0.2-2.2l-0.2,0.2l0.3-2.6 M74,56.3v0.2l0.2,0.4l0.6-0.4l0.6,0.9l0.7-0.9
			l0.8-1.1 M48.6,57.4L48.6,57.4l1.6-0.7l1.3-0.3l0.1,1.3l-0.4,1.2l-1.1-0.5L48.6,57.4L48.1,59l-1.5-0.4l-0.3,1.1L45,59.5l0.1-1.1
			v-1.2l-1.3,0.1l-1,0.1l-0.4-0.8l-0.6,0.3l-0.6-0.2l-0.3,1L40.3,57l-0.4,0.3l-0.5-0.2l-0.7-0.3L38.4,58l0.8,0.1l0.1-1l0.5-1
			 M35.2,56.5L35,56.9l-0.6-0.4l-0.6,0.9L33,56.5 M80.7,57.6l-1.7-1l-0.7,1.3L77,57.2l-0.7,1.5l-1-1.4l-0.7,0.4l-0.5-0.9h-0.3
			l0.1-0.4 M30.8,57.9L30.8,57.9l1.3-0.6l0.6,1.4l1.1-1.4l0.7,0.4l0.5-0.9h0.4 M67.3,58.1l0.4,0.4l-0.8,0.7l-0.4-1L67.3,58.1
			l0.2-0.5l1,0.2l-0.3-0.9L67.6,57l-0.1,0.6l-0.9-0.2v0.8l-1.1,0.1v-1l1,0.1l0.4-0.7l0.6,0.3 M73.7,57.2l0.2,0.6l-0.4-0.1l0.1-0.3
			L73.7,57.2l0.1-0.3 M69.4,57.4l-0.2,0.5l0.7,0.2v-1L69.4,57.4L69.1,57l-0.6,0.7l0,0l0.9,0.1l-0.2,0.5l-0.5,0.2l-0.1-0.8l-0.8,0.8
			l0.4,0.3l0.6-0.2v0.7l0.7-0.5l-0.2-0.5 M58.5,57.6l0.7,0.8l1.4-1l2.1-0.1l0.1,1.4l0,0l1.5-0.2v-1.2 M39.7,57.3l0.1,0.5l0.9-0.1
			l0,0l1-0.2l0.2,0.6l0.8,0.1v-0.8l-0.9,0.1l-0.2-0.6 M50.7,57.6L50,58.4l-0.8,1.2l1.1,0.6l0.8-1.3l1,0.2l0.3-1.5h0.8L53,59.3
			l-0.9-0.2l-0.7,1.4l-1.1-0.4l-1,1.2l-1-0.6l-1.1-0.6L48,59l1.2,0.6l-0.8,1.2l-1,0.8l0,0L46.6,61L46,60.6l0.2-0.7l1.2,0.5l-0.7,0.8
			l-1,0.3l-0.6-0.8l-0.2-1l-1.2-0.1v-1.1h-1l-0.3,1l-0.8-0.7L40.8,58l-0.1,0.9l-0.6-0.2L39.9,58l-0.7,0.2l0,0l0.7,0.8l0.2-0.5
			 M57.7,57.8l0.4,1.2l-1,0.3l-0.3-1.5H57.7 M47.4,61.7l0.8,0.7l1.1-0.9l1.1,0.5l1-1.3l1.1,0.2l0.5-1.5l0.8,0.1l0.1-1.8h0.7v1.8
			h-0.8l-0.3,1.6l-1-0.2l-0.6,1.7L50.5,62l-1.3,1l-1-0.6L46.8,63l0,0l-0.5-0.8l-0.6-0.7l-1.3,0.1l-0.4-1l-0.2-1.1l-1.3-0.2l0,0
			l-1,0.2l-0.3-0.7l0.4-0.3l0.4-0.4 M56,57.8l0.2,1.6l0,0l-0.8,0.1l-0.1-1.8H56 M35.4,57.2l-0.2,0.6l-0.7-0.1l-0.6,1.5l-1.2-0.4
			v-0.1 M35.2,57.8l0.4-0.1 M73.5,57.7l-0.1,0.2v0.2l-0.3,0.5l0.1-0.8l0.4-0.5 M73.5,57.9l0.2,1.2l0.7,0.2L74,57.8l0.7-0.1l0.6,1.5
			l1.1-0.4l0,0l1.7,0.7l0.2-1.6l1.5,1.2l0.8-1.4l1.8,3.2l1-0.2l1.5,3.1l0.6-0.2l-1.3-3l0.4,0.1l-0.8-2.4l-0.1-0.1 M35.2,57.8
			l-0.4,1.5l0.7-0.2l0.3-1.2 M70,58.1l0.2,1l-0.8,0.6l-0.1-0.8L70,58.1l0.8-0.2l-0.1,0.8l-0.6,0.4V60l0.7-0.7l-0.2-0.6 M38.3,57.9
			l0.1,0.7l-0.2,0.5l-0.6-1l0,0L38.3,57.9 M71.5,58.3v1.3v0.1l-0.6,0.6v-0.9L71.5,58.3L71.5,58.3l0.7-0.5L72.1,59l0.6-0.5l0.1-0.1
			 M35.8,58.4l-0.1,0.7l0.2-0.5L35.8,58.4v-0.3 M25.3,58.3l-0.1,0.1L25,58.5l0.2-2.2 M65.5,58.4l0.1,1.1l-1.2,0.1l-0.1-1.1
			L65.5,58.4 M73.4,58.1v0.6v-0.2V58.1 M83.9,58.4l0.2,0.2l-0.2-2.2 M36,58.7l0.1,0.3l0.5,0.7l-0.1-1.2 M73.1,58.7L73,59l-0.5,0.7
			l0.1-1.3 M73.4,58.8l0.1,0.3h0.2l0.6,2.6l-0.6-0.2v-0.8v-0.8l-0.2-0.8l-0.1,1.7L73.1,60v-0.2l0.2-1.2 M41.1,58.8l-0.5-0.2v0.7
			l-0.7-0.5l-0.1,0.8L39,59.1l0.1-1 M59.2,58.4l0.7,1.2l-1.1,0.6L58,58.9L59.2,58.4 M72.1,58.9L72.1,58.9l-0.6,0.6 M62.7,58.6
			L61.2,59l-0.6-1.6 M38.3,59.2L38.3,59.2l0.7,0.7l0.1-0.8l-0.6-0.4 M35.5,59.1l-0.2,1v1.4l-0.6,0.2l0.6-2.5L35.5,59.1 M68,58.9
			l-0.2,0.7l-1-0.2l0,0l-1.3,0.2l-0.2,1.1l1.2-0.1l0.3-1.2 M30.8,57.9l0.2,1.6l1.7-0.7l-0.9,3H33l0.8-2.6h0.9l0,0 M74.4,59.2
			l0.7,2.5h1l-0.8-2.6L74.4,59.2 M56.3,59.2l0.8-0.1l0.7,1.4l1.1-0.4l1,1.2l1-0.6L60,59.5l1.3-0.6l0.6,1.2l1.2-0.4l-0.3-1.2
			 M36.1,58.8L35.9,60l0.5,1.1l0.2-1.4l0.6,0.5l-0.1-1.3l0.6,0.5v-1.3 M40.6,59.3l0.1,0.9l1,0.2l-0.2-0.9L40.6,59.3 M68.7,59.3
			l-0.1,0.9l-1,0.2l0.2-0.9L68.7,59.3 M37.7,59.5L37.7,59.5l0.6,0.8v-1 M46.8,62.9l1,0.8l1.4-0.7l1,0.9l1.7-1.2l0,0h1.6l0.1-1.5
			l1.1,0.1v-1.7h0.8l0.2,1.6l1.1-0.2l-0.5-1.6 M63,59.9l0.2,0.7h1l0.2-1L63,59.9 M73.2,59.8L73.1,59 M35.6,59l0.1,1.8L36,60
			l-0.2-1.5 M28.5,57.6l-1.7,3.2l1,0.6l1.1,0.5l1.5,0.1l0.7-2.4L29.8,60l-0.5-1l-1.6,2.3l-1.6,3l-1-0.3l-0.9-0.3l1.5-3.1l1.1,0.2
			L25.1,64l-1.4,3.3L22.9,67l1.2-3.3l-0.6-0.1l1.3-2.9l-0.4,0.1l0.8-2.4 M84.7,60.8L84.7,60.8l0.5,1.2l0.6,1.6l-0.2,0.1l1,3.2
			l-0.4,0.2L85,63.8L84.1,64l-1.7-3.2l-1,0.5L79.8,59l-0.5,1l-1.2-0.5l0.7,2.4l-1.5-0.1l-1-3 M70.9,60.1L70.9,60.1l-0.7,0.7V60
			l-0.8,0.7v-1l-0.9,0.6l-0.1,1l1-0.5l-0.1,1l0.9-0.6v-0.1 M73.1,60.1l-0.3,1l-0.2-1.4L72,60.2l0.1-1.2 M39.7,59.7v1l1,0.5v-0.9
			L39.7,59.7 M72,60.2L72,60.2l-0.6,0.6l0.1-1.2 M37.2,60.2L37.2,60.2l0.6,0.6l-0.1-1.2 M36.4,61.1L36.4,61.1l0.6,0.7l0.7,0.4
			l0.2-1.4l0.6,0.6l-0.1-1.2l0.7,0.7L39,60l0.9,0.7v0.9l1,0.5l-0.2-0.9l1,0.3l-0.2-1h1.1l-0.3-1.2 M70.9,60.3l-0.1,1.1v0.1L70.1,62
			l-0.9,0.5l0.1-0.9l-1,0.5l0.2-0.8l-1,0.2l0.2-1h-1.1l-0.3,1l1.2-0.1l-0.3,0.8l1.2-0.2l-0.2,0.7l1.1-0.3l-0.1,0.8l0.9-0.3l0.1-1
			l0.1-1 M46,60.5h-1h-1.2l-1.2-0.1l0.3,1l-1.2-0.1l0.3,0.8l-1.1-0.2l0.1,0.7l-1-0.3l-0.2-0.9L39,61l0.1,1l-0.7-0.5v1.1l-0.7-0.4
			l-0.1,1.5l-0.7-0.2l0.1-1.7l0.2-1.5 M63.3,60.5L62.6,61l-0.8,0.6l-1-0.8l1-0.6l0.7,0.8l1,0.3l1.3,0.1l0.4-1H64l-0.6,0.8L63,62.1
			l-1.2-0.5L61,62.3l-1.1-0.9l-1.1,0.5l-1-1.3l-1.1,0.3l0.6,1.7l1.4-0.7L60,63l1-0.6l1.5,0.6l0.5-0.8l1.4,0.2l0.5-0.8h1.3l-0.4,0.8
			h-1.5 M24.3,60.8l-0.1,0.3L24.3,60.8L24.3,60.8l0.7-2.3 M84.8,60.8l-0.7-2.3 M85.2,62l-0.5-1.2 M35.4,61.8v0.3l0.1,0.3L35.4,61.8
			L35.4,61.8L35.4,61.8v-0.3V61.8l0.3-0.8v-0.2 M73.7,59.9l-0.1,1.8L73.4,61v-0.2 M24.2,61.1l-0.9,2.4l0.2,0.1l-1,3.3l-0.1-0.2
			l1-3.1 M72,60.3l0.2,1.5l0.6-0.6V61 M64.4,62.3L64,62.9h-1.6l-1,0.8L60,63l-1,0.9l-1.8-1.2l0,0h-1.5l-0.1-1.5l-1,0.1v1.6l-1.2-0.2
			l0.3,1.2l1,0.3l0,0v-1.4l1.2-0.2l-0.3,1.2l-0.9,0.3 M70.9,61.3l0.6-0.5l0.1,1.4l0.7-0.4l0.1,1.7l0.7-0.4l0,0l0.4-2.1 M36.5,61.3
			l-0.3,1.9l0.8,0.4l0.2,1.9H38l-0.2-1.8l0.9,0.2l-0.1-1.3l0.8,0.4l-0.1-1l0.9,0.5l0.1,0.8l1.1,0.2l-0.2-0.6l1.3,0.2l-0.3-0.7
			l1.3,0.1L43,61.6h1.3l0.5,0.8h-1.5l0.3,0.6h-1.4l0.2,0.5h-1.3l0.2,0.7h-1.1l-0.2-1L39.3,63l0.1,1l-0.9-0.1l0.2,1.6h-0.8l0.4,1.9
			l0.7-0.1l-0.3-1.8h0.9L39.3,64l1,0.1l0.2,1.3l0,0l-1,0.1l0.2,1.7l1.1-0.2l-0.3-1.5 M73.7,61.5L73.7,61.5v0.4v-0.2l-0.1,0.6v-0.2
			v-0.2 M22.4,66.6l-0.8,3v0.3l1-3.1L23,67l-1,3l0,0l0.6,0.3l1.1-3l1.1,0.4l1.5-3.3l1.3,0.4l1.4-2.9l1-1.8 M35.4,61.8l-0.8,2.9v0.1
			L34,65l1-3.1L34.1,62l0.7-2.5 M73.7,61.7l0.8,2.9l0.1,0.1l0.6,0.2l-1-3.1L75,62l1,3.2L75.1,65l1.4,3.3L76,67.9l-0.1-0.2l-1.3-3.1
			 M85.8,63.5l1,3.1l0.8,2.9V70v0.2L87.1,70l-1-3l-0.7,0.3L84.1,64L83,64.3l-1.6-3l-1.1,0.5l-1-1.8 M34.5,64.5l-1.4,3.1L33,67.8
			l-0.5,0.3l1.4-3.3L33.1,65l1-3.1h-1L32,65.1h-1.3l1.1-3.2L30.3,62l-1.2,3l-1.6-0.2l-1.4,3.3l-1.4-0.4l-1.2,3.1l-1-0.4L22,72.8
			l-0.6-0.3L22,70 M76.1,67.8l1.4,2.8l0.1,0.2l0.5,0.3l-1.5-3l0.9,0.2L76.1,65l1.1,0.1l-1.1-3.2h1.2l1.1,3.2L80,65l-1.2-3.1l1.5-0.1
			l1.4,2.9l1.4-0.4l1.4,3.3l1-0.3l1,3.1l0.6-0.3l0.5,2.4l0.4-0.1l-0.4-2.1l0.7,1.4l-0.7-2.1 M46.8,62.9L46.8,62.9l-1.6-0.1L45,63.2
			l-1.1,0.1l-0.1-0.4h1.4l-0.3-0.6l1.4-0.2l1.2-0.5 M73.6,62.3l-0.3,1.4l-0.2-0.6l-0.3-1.9 M67.2,62.2l-0.3,0.7h-1.4l-0.1,0.4
			l-1.1-0.1l-0.2-0.4h1.4l0.4-0.6L67.2,62.2 M35.6,62.3l0.2,1.2l0.3-0.5l0,0l-0.4-2.1 M64.3,63.1l-0.6,0.3l1.4,0.3l0.3-0.5h1.3
			l0.2-0.5l1.2-0.2L68,63.1l1-0.2l-0.2,1l1-0.1l-0.1,1.4h0.9l0.2-1.6l-0.9,0.2l0.1-1.1l0.8-0.4v1.3l0.8-0.2l-0.2,1.7h0.7l0.2-1.9
			l-0.8,0.2l0.1-1.4l-0.7,0.4v-1.1 M45,63.2l0.6,0.3l-1.4,0.3l-0.3-0.5h-1.4l0.3,0.6l1.4-0.2l0.4,0.9l1.6-0.4l0,0l-0.6-0.7l1.2-0.6
			 M55.5,63.9l1,0.5l1.7,0.7l1-1.3l1.7,0.9l0.7-1l1.5,0.6l1.6,0.4l0.4-0.9l1.4,0.2l1.3,0.1l0.2-0.7h-1.3L66.5,64l-0.4,1l-1.5-0.3
			L64.2,66l-1.6-0.6l-1.7-0.7l-0.5,1.2l-2.1-0.8l-0.7,1.4l-2.9-0.7v-1.6 M46.3,64.3l1.5-0.5l0.7,1l1.8-0.9l0.9,1.2l0.7,1.5l2.9-0.7
			v1.6l2.6,0.4l0.3-1.2l2.3,0.7l0.4-1.3l1.9,0.7l0.4-1.2l0.5-1.1l0.6-0.8l-1.2-0.6 M46.2,64.3l0.5,1.1L45,65.9l-0.4-1.2L43.1,65
			l-0.3-1h-1.4l0.3,1.2l1.4-0.2l0.5,1.3l1.5-0.4l0,0 M67.8,64.1L67.8,64.1h1.1l-0.2,1.3l-1.2-0.1L67.8,64.1 M34.6,64.5l0.2-0.2
			l0.7-2.3 M73.7,62.1l0.7,2.3l0.2,0.2 M53.7,63.9l-0.9,0.5l-1-1.8 M57.3,62.7l-0.9,1.8l-1.8,1.4l-1.8-1.4L51,65.1L49,66l-0.6-1.2
			l-1.7,0.7l0.4,1.2L49,66l0.4,1.3l2.3-0.7l0.3,1.2l2.6-0.3v1.2l2.5,0.3l0.1-1.1l2.4,0.6l0.3-1.1l2,0.6l0.3-1.3l1.7,0.6l0.3-1.2
			l1.6,0.3l0.4-1.3l1.4,0.2l-0.4,1.4l-1.4-0.3l-0.4,1.4l-1.5-0.5l-0.3,1.3L61.9,68l-0.4,1l-1.9-0.6l-0.4,1.3L57.1,69l-0.2,1.4
			l-2.3-0.2v-1.5L52.2,69L52,67.8l0,0 M81.6,64.7L83,68l-1.6,0.3L80.1,65L81.6,64.7 M21.5,69.9v0.2v0.1L21,72.3l0.4,0.1l-0.3,1.8
			l0.6,0.3l0.2-1.8l0.8,0.3l0.6-2.3l1.3,0.4l1.2-3.1l1.6,0.3l1.4-3.4l1.6,0.1L29,68.5l-1.6-0.2l-1.3,3.1l-1.5-0.3L24,73.4l-1.1-0.3
			l-0.4,1.6l-0.7-0.2l-0.1,1.2L21,75.5l0.2-1.3l-0.6-0.3l0.4-1.6l-0.3,0.2l0.1-0.6v-0.1l0.1-0.3l0.6-1.9 M32.6,68.1L32.6,68.1
			l-0.9,0.3l1.4-3.4L32,65.1l-1.4,3.4h-1.3l-1.4,3.1l-1.5-0.2l-1.1,2.2L24,73.4L23.3,75l-0.8-0.3l-0.2,1l-0.6,0.1l0.2,1.1l-1.3-0.3
			l0.3-1l-0.8-0.4l0.5-1.2l-0.4-0.2l0.5-1.2 M78.1,71.1L78.1,71.1l0.9,0.4l-1.5-3.1l1,0.1l-1.4-3.4h1.3l1.4,3.5l1.6-0.2l0,0
			 M45,65.9l0.3,1.3l-1.5,0.6l-0.3-1.4l-1.4,0.2l-0.4-1.3l-1.2,0.1 M68.7,65.4l-0.3,1.5l1.1,0.2l0.3-1.6L68.7,65.4 M42.1,66.6
			L42.1,66.6l-1.3,0.3l0.4,1.6l0,0l-1.1,0.3l-0.4-1.7l-0.8,0.1l0.4,1.8l-0.6,0.1l-0.4-1.8h-0.7l-0.5-1.9l-0.6,0.1l-0.4-2.3 M73,63.2
			l-0.4,2.4L72,65.5l-0.5,1.9h-0.7l0.4-1.9h-0.8l-0.3,1.8l-0.8-0.1L69,68.9l-1-0.4l0.4-1.6l-1.3-0.3l-0.4,1.6l-1.4-0.4l-0.4,1.4
			l-1.4-0.6L63,69.8L61.5,69l-0.4,1.2l-1.8-0.6L59,70.8l-2.1-0.5L56.8,72l-2.2-0.1v-1.8l-2.3,0.2l-0.1-1.4L50,69.5l0,0l-0.2-1.2
			l-0.3-1.2l-2,0.6l-0.3-1.2l-1.7,0.6l0.3,1.3l1.7-0.6l0.3,1.2l2-0.7l2.4-0.6 M41.2,68.6l0.6,1.6l-1.1,0.4l-0.6-1.7L39.3,69l0.5,1.8
			l-0.7,0.1l-0.5-1.8L38,69.2l-0.5-1.9L37,67.4l-0.5-1.9l-0.2,0.2l-0.5-2 M49.9,69.6l0.3,1.3l2.1-0.5l0.1,1.6l2.2-0.1v1.8l2.2,0.1
			V72l1.9,0.3l0.2-1.5l1.6,0.6l0.6-1.2l1.6,1l0.4-1.5l1.4,0.6l0.5-1.2l1.2,0.6l0.5-1.5l1.3,0.4l-0.6,1.6l1.1,0.4l0.6-1.7l0.8,0.2
			l0.4-1.8l0.7,0.1l-0.4,1.8l0.5,0.1l0.6-1.8l0.5,0.1l0.5-1.9l0.2,0.2l0.5-2 M87.6,70l-1-3.2l0.1-0.2 M33.2,67.7l0.2-0.1l1.4-3.2
			 M42.1,66.7l0.4,1.5l1.4-0.4l0.4,1.4l1.4-0.6l0.4,1.1l1.6-0.6l0.4,1.2l0.6,1.1l1.5-0.5l0.2,1.4l1.9-0.3v1.8l2.2-0.1v1.9l2.3,0.1
			l-0.1-1.9l1.9,0.2v-1.8l1.4,0.5l0.4-1.4l1.1,1l1.1-1.2l0,0l1.5,0.4l0.3-1.2l1.1,0.6l0.7-1.3l1.2,0.5l-0.8,1.5l1,0.6l1-1.7l0.8,0.2
			l0.5-1.8l0.6,0.1L70,70.9l0.5,0.1l0.5-1.8l0.4,0.1l0.6-1.9l0.2,0.1l0.6-1.9 M75.9,67.7l-0.2-0.2l-1.4-3.2 M41.2,68.5l1.3-0.3
			l0.5,1.5l-1.2,0.5l0.8,1.4l-1,0.6l-1-1.7l-0.9,0.2l0.8,1.8l-0.9,0.2L39,70.9L38.6,71l-0.5-1.8l-0.4,0.1l-0.6-1.9l-0.2,0.1
			l-0.6-1.9 M88.1,72.3L88.1,72.3l0.4,1.6l-0.6,0.3l-0.3-1.8L87,72.7l-0.5-2.4l-1,0.4l-1.1-3.1L83,68l1.2,3.1l1.3-0.4l0.7,2.2
			l0.9-0.3l0.2,1.8l0.7-0.3l0.2,1.3L89,75l-0.5-1.2l0.4-0.2l-0.5-1.2V72l-0.1-0.4v-0.1 M22.3,75.6l0.5,0.4l0.5-1.1l1.1,0.3l0.9-1.5
			l1.4,0.2l1.2-2.2h1.3l1.5-3.1l1-0.1l-1.5,3.1l0.8-0.3l0.1-0.1l1.4-3 M81.4,68.3l1.3,3.1l-1.5,0.2l-1.4-3.1h-1.3l1.5,3.1l-1-0.1
			l1.2,2.3l-0.9-0.3l-1.1-2.3 M37.6,69.3l0.6,1.5L37.6,69.3L37.6,69.3l-0.7-1.7 M71.5,69.4L70.8,71L71.5,69.4L71.5,69.4l0.7-1.8
			 M44.3,69.1l0.5,1.2l-1.1,0.6L43,69.7L44.3,69.1 M22,70.1h-0.5"
        />
        <path
          class="st0"
          d="M46.5,71.1L46.5,71.1L45,71.5l-0.3-1.2l1.4-0.6L46.5,71.1l1.6-1l1.8-0.7 M33.1,67.8l-1.5,2.9l0.2-0.2l1.6-2.9
			 M77.5,70.6l-0.2-0.1l-1.5-2.9 M31,71.1l0.5-0.3l0.1-0.1 M38.3,70.9l0.1,0.2h0.3l0.5,1.8l0.6-0.1l0.4,1.8l1.1-0.2l-0.7-1.8
			l1.1-0.4l1.2,1.8l0.8-1.4l-1.1-1l1-0.7l0.5,0.8l0.9-0.2L44.8,73l-1.2-0.4l0.5-1 M65.1,71.7l-1-0.2l0.2,1.5l1.2-0.4l1.1-1l-1-0.7
			L65.1,71.7l0.4,1l0.8,1.4l1.2-1.9l1,0.3l0.9,0.2l0.5-1.8l-0.7-0.1l-0.8,1.8l-0.6,1.9l-1.6-0.3l-1.6,0.7l-0.4-1.7L63,73.5l-0.5-2.1
			 M70.8,71.1L70.8,71.1L70.5,71L70,72.8l-0.6-0.1L69,74.5l-1.2-0.1l-0.2,1.8l1.1,0.1l0.2-1.7l0.7,0.1l0.3-1.8h0.3L70.8,71.1
			 M38.3,71.1l0.5,1.8h0.3l0.3,1.8h-0.3l-0.3-1.8 M48.7,71.4L48.7,71.4l-1,1l-1.1-1.3L46,73.2L44.8,73l-0.3,1.8l-1.6-0.7l0,0
			l-1.6,0.3l0.2,1.8l1.4,0.1l0.1-2.2 M84.3,71.1l0.9,2.2l-1.3,0.3l-1.1-2.2L84.3,71.1 M20.9,71.5l0.6-1.3 M20.7,71.8L20,73.4l0,0
			l0.2,0.3l-0.7,1.1l0.7,0.3l-0.6,1l1.1,0.4L20,77.7l1.2,0.4l0.7-1.3l0,0l0.8-0.8l0.9,0.3l0.7-1.2l1.2,0.2l1-1.6l1.2,0.1l1.3-2.3
			l1-0.1l-1.2,2.3l0.9-0.3l1.2-2.3 M86.1,73L86.1,73L85,73.4l0.6,1.6l-1,0.2l-0.9-1.5l-1.3,0.2l-1.2-2.2H80l1.3,2.3l-1-0.1l1,1.7
			l-1-0.2l-0.9-1.8l-0.6-0.4L78.6,73l-1-2.2 M50.5,72.3L50.5,72.3l-1.4,0.5l-0.4-1.4 M88.1,72.3l0.3,0.2 M31.5,70.8l-1,2.2l0.2-0.2
			l1.1-2.3 M78.6,73L78.6,73l-1.3-2.5 M20,73.4l0.7-1.5 M30.3,73.2l-0.4,1.3l-0.4,1h-0.6l0.9-1.9L30.3,73.2l0.1-0.2 M50.5,72.3V74
			l-1.3,0.3v-1.5l-0.8,0.4l-0.6-0.7l-0.6,1.4l-1-0.6l-0.3,1.9l-1.3-0.4l-0.2,1.8l-1.5-0.3l-0.2,1.9L41.3,78l0.2-1.8l-1.1,0.1
			l-0.2-1.7l-0.7,0.1l0.1,1.7h-0.4l-0.1-1.6 M39.2,76.3l-0.1,1.6l0.4,0.1l0.1-1.6h0.8L40.2,78l1.1,0.1l-0.4,1.8l1.3,0.2l0.3-1.8
			l1.5,0.2l0.2-1.8l1.4,0.4l0.1-1.8l1.1,0.4L47,74l1,0.5l1-0.1l0,1.5l1.4-0.1l-0.2,1.7h2v1.7h2.4v-1.7h-2.3l0.1-1.8l-2,0.1l0.1-1.8
			l1.9-0.2v1.9l2.3-0.1v1.8H57v-1.8l1.9,0.1V74l1.3,0.3v-1.5l0.8,0.4l0.6-0.7l0.6,1.4l1-0.6l0.3,1.9l1.3-0.4l0.2,1.8l1.5-0.3
			l-0.1-2.2 M89.1,73.4l-0.8-1.7 M88.4,72l0.7,1.4 M20,73.4l-0.8,1L20,73.4 M90,74.6l-1-1.3v0.1 M89.1,73.5l1,1.2 M89.1,73.5
			l-0.2,0.3l0.7,1l0.4-0.1l0,0l0.7,1l-0.7-1 M21.9,76.9l1.1,0.6l0.6-1l1.1,0.3l0.8-1.2l1.2,0.1l1-1.6l1.1-0.1l-1,1.7h-1.1L26,76.9
			l-1.2-0.2l-0.7,1.1L23,77.4l-0.7,1.1l-1.1-0.4l-0.9,1.5l-1.2-0.4l0.8-1.6L19,77.4l0.6-1.3L18.7,76l0.8-1.1l-0.4-0.2l0.2-0.2
			 M78.8,73.2l0.5,1.3l0.4,0.8h0.6l0.6,1.5h1l-0.7-1.4h1.1l-1.1-1.6l1.3-0.1l1,1.5l1.2-0.2l0.7,1.2l0.9-0.3l-0.5-1.1l0.8-0.3
			l-0.4-1.6 M70.4,72.8l-0.3,1.8h-0.3l-0.1,1.7h-0.8L69,78h-1l-0.2-1.8l-1.4,0.1l0.2,1.9l-1.5,0.2l-0.2-1.9l-1.4,0.4l-0.1-1.8
			l-1.1,0.4l-0.2-1.7l-1,0.6l-0.2-1.2 M39,77.8l-0.3,1.5l0.4,0.1l0.3-1.6l0.8,0.1l-0.4,1.7l-0.4,1.7l1.1,0.3l1.4,0.3l0.4-1.8
			l1.6,0.2l0.3-1.8l1.5,0.3V77l1.2,0.3l0.1-1.6l1,0.3l0.2-1.5l0.2-1.2 M70.1,74.6L70,76.3h-0.3l0.1,1.6L69,78l0.4,1.7l-1,0.2
			L67.9,78l-1.3,0.1L67,80l-1.6,0.2l-0.3-1.8l-1.5,0.3L63.5,77l-1.2,0.3v-1.7l-1,0.3l-0.2-1.4L60,74.4l0.2,1.6l-1.4-0.1l0,0
			 M29.9,74.4l0.7-1.6 M79.2,74.5l-0.7-1.6 M87.3,74.6l0.1,1.2l-0.6-0.1l-0.2-1L87.3,74.6 M89.6,74.8L89.6,74.8L89,75.1l0.6,1.1
			l0.9-0.2L89.6,74.8 M18.1,75.9l0.6,0.1L18,77.3l-0.7,0.1L18.1,75.9l1-1.3 M29.5,75.3v0.2L29,77l-0.7-0.1l0.6-1.5l-1,0.2L27.2,77
			L26,76.9L25.4,78l-1.2-0.3l-0.7,1.1l-1.1-0.4L21.5,80l-1.1-0.4L19.3,82l-1.1-0.4l1.1-2.3L18.2,79l0.8-1.6l-1-0.1L17.2,79h1l-1,2.3
			h-1l1-2.3l-0.7,0.1l0.8-1.8 M85.4,76.3L85.4,76.3l-1.1,0.3l-0.8-1.2l-1.2,0.1l0.8,1.3l-1.2,0.1l0.6,1.3l-1.1,0.1l-0.6-1.4L80.1,77
			l-0.5-1.4l-0.1-0.3 M90.8,75.6l0.2,0.3l0.9,1.6l-0.7-0.2 M86.8,75.6L86.3,76l0.8,0.8l0.2-1.1l0.8-0.2l0.3,1l1.1-0.4l0.7,1.2l1-0.1
			l-0.7-1.4l0.6-0.1 M38.7,79.3l-0.3,1.6l0.3,0.2l0.4-1.6l0.7,0.2l1.1,0.2l-0.4,1.8l-0.3,1.9l1.4,0.4l0.3-2l1.7,0.2l0.3-1.9l1.6,0.2
			l0.2-1.7l1.2,0.2v-1.6l1.1,0.2v-1.5h1l-0.1,1.6l-1-0.1v1.6l-1-0.1l-0.1,1.7l-1.3-0.1l-0.3,1.9L43.6,82l-0.3,2l-1.6-0.3l-0.2,2.1
			L40,85.4l0.1-1.9L39,83.2l-0.7-0.3l0.3-1.8l0.7,0.2L39,83.1l-0.2,1.8l-0.6-0.4l0.1-1.8l-0.2-0.2l0.2-1.7 M70,76.2l0.1,1.6
			l-0.4,0.1l0.3,1.6l-0.7,0.2l0.4,1.7l-1.1,0.3l-0.4-1.8L67,80l0.4,1.9L65.7,82l-0.3-1.9l-1.6,0.2l-0.2-1.7l-1.2,0.2v-1.6l-1,0.2
			v-1.5h-1.1l0.1,1.6l1-0.1V79l1.1-0.1l0.1,1.7l1.3-0.1l0.2,1.9l1.6-0.2l0.3,2l1.6-0.3l-0.3-2l1.4-0.3l0.3,1.9l1.1-0.4l-0.3-1.8
			l0.7-0.2l-0.4-1.6l0.3-0.1l-0.3-1.5 M91.2,77.3L92,79h-1l-0.8-1.6l-1.1,0.2l-0.6-1.1l-1.3,0.3l0,0l-1.1,0.6l-0.7-1 M87.2,76.9
			l0.7,1.3l-1.1,0.4v-0.1l-0.7-1L85,77.7l-0.7-1.1l-1.2,0.2l0.7,1.2l1.2-0.3l0.7,1.1l1.1-0.5 M27.3,76.9l-0.7,1.3l1.1,0.1l0.6-1.4
			H27.3 M21.5,80L21.5,80l1,0.4l0.9-1.5l1.2,0.3l0.8-1.2l1.2,0.2l-0.8,1.3l1.1,0.3l0.8-1.4l0.7,0.1l0.5-1.4l0.2,0.3l0.3-1.7
			 M86.7,78.5l0.8,1.6l-1.1,0.4L85.5,79l-1.1,0.4L83.7,78l-1.2,0.2l0.8,1.3l-1.1,0.3l-0.8-1.4l-0.8,0.1L80.1,77l-0.2,0.2l-0.3-1.6
			 M91.9,77.5L91.9,77.5l0.7,1.7L92,79.1l1,2.3h-1L91,79l-1.1,0.2L89,77.7v-0.1 M38.2,82.6l-0.1,1.7l0.2,0.3v1.6v0.1l0.6,0.5
			L38.8,85l1.1,0.5v1.9l1.4,0.6v-2l1.7,0.4l0.1-2.1l1.7,0.2l0.2-2.1l1.4,0.2l0.2-1.9l1.1,0.1l0.1-1.7l1,0.1v-1.6h1.4v1.7l-1.3-0.1
			l-0.1,1.7l-1-0.1l-0.2,1.9l-1.1-0.1l-0.3,2.1l-1.3-0.2l-0.2,2.2l-1.6-0.3l0.1,2.1L41.6,88l0.1,1.8L40.2,89l-0.1-1.7l-1-0.5
			l0.1,1.6l-0.6-0.5v-0.1l-0.1-1.4 M70.4,79.3l0.3,1.6l-0.3,0.2l0.3,1.8l-0.6,0.3l0.1,1.8l-1.1,0.5l-0.1-2l-1.4,0.4l0.2,2l-1.6,0.4
			L66,84.1l-1.6,0.2l-0.4-2l-1.3,0.2l-0.2-1.9l-1.1,0.1L61.3,79l-1,0.1v-1.6H59v1.7h-2v1.7h-2.4v-1.7H57l-0.1-1.7h2l-0.1-1.7
			 M91.9,77.5l0.8,1.8l0.5,1.4l0.2,0.7l-0.5-0.1l1,2.7l-0.8,0.1L92,81.3l-1.1,0.2l-1.1-2.3l-1.2,0.3l-0.8-1.4l1.2-0.4 M29.1,77.2
			l-0.4,1.3l-0.3-0.1L27.6,80l-0.8-0.2l-1,1.8l-1.1-0.4l1-1.6l-1.2-0.3l-1,1.6l-1.1-0.4l-1.2,2.3l-1-0.4l1.2-2.3 M88.7,79.6
			L88.7,79.6l-1.1,0.5l1.1,2.3l-1,0.4l-1.2-2.3l-1.1,0.4l-1-1.6l-1.2,0.3l1,1.6l-1.1,0.4L82,79.8L81.4,80l-0.7-1.5l-0.3,0.1L80,77.2
			 M59,79.1L59,79.1l1.3-0.1v1.7l1-0.1l0.1,1.9l1.1-0.1l0.3,2.1l1.3-0.2l0.2,2.2l1.6-0.3l0.1,2.1l1.6-0.5l0.1-2l1.4-0.4L69,87.3
			l1.1-0.5l0.1-1.8l0.6-0.4v-0.1l-0.2-1.7l0.2-0.2l-0.2-1.7 M38.5,87.8L38.4,89v0.1l0.7,0.6l0.1-1.4l1.1,0.7l-0.1,1.4l1.4,0.7v-1.5
			l1.7,0.6l-0.2-1.9l1.6,0.4l-0.1-2.2l1.3,0.3l0.2-2.2l1.2,0.2l0.3-2.2l1.1,0.2l0.1-1.9l1.4,0.1l0.1-1.7h2V81h-2.1v2l-1.5-0.2
			L48.5,85l-1.1-0.2L47.2,87L46,86.8l0.1,2.2l-1.4-0.3l0.1,1.9l-1.6-0.4l-0.1,1.5l-1.6-0.6l-0.2,1.4l-1.4-0.8l0.2-1.3L39,89.8
			L38.8,91l-0.6-0.6l0.2-1.3 M92.7,79.3L92.7,79.3l-0.1-0.2 M16.5,79.2l-0.8,2.2l0.5-0.1l-1,2.7l-0.4,0.1l0.9-2.7 M88.7,79.7
			l1.1,2.3l-1.1,0.4l1.3,2.7l-1,0.3l-1.4-2.7l-1.1,0.4l-1.2-2.3l-1.1,0.3l1.3,2.3l-1,0.4l-1.3-2.3l-0.8,0.3l-1-1.8h-0.3l-0.8-1.5
			 M14.8,84.1l-0.9,3.1h0.3l1-3.1l0.8,0.1l-1.1,3.1l0.9,0.2l1.2-3l1.2-2.8l-1.1-0.2L16,84.1l1,0.3l1,0.3l-1.3,2.9l1,0.3l1.4-2.9
			l1.3-2.7l-1.1-0.4L18,84.7l1,0.4l1,0.4l1.3-2.7l1.1,0.4l1.2-2.3l1.1,0.3l-1.3,2.3l1,0.4l1.3-2.3l0.8,0.3l1.1-1.8L28,80l0.8-1.6
			 M93.2,80.7l-0.6-1.5 M70.8,84.6l-0.1,1.7v1.4v0.1l-0.6,0.5l-1,0.7l0.1-1.7l-1.4,0.6l-0.2,1.8l-1.7,0.6l0.1-1.9l-1.6,0.4l0.1-2.2
			l-1.3,0.3L63,84.6l-1.2,0.2l-0.2-2.2l-1.1,0.2l-0.1-1.9L59.1,81L59,83l-2.1-0.5l-0.6,1.1l-1.7-0.1v-1.1l-2.2,0.1L52.2,81h2.4v1.5
			h2.3l0.2-1.5h2v-1.7 M13.9,87.2L13,90.3h0.3l-0.8,2.9h0.6l0.8-2.9l1-3.1l-0.7-0.1l-0.9,3.1H14l0.8,0.1l-1,2.9l0.7,0.1l1-2.9
			l1.2-2.9l-1-0.3l-1,3l0.8,0.2l0.8,0.2l1.3-2.9l0.9,0.3l1.4-2.8l1,0.4l1.4-2.7l1,0.4L22,86.1l1,0.4l1.5-2.6l0.8,0.3l1.3-2.4
			l0.3,0.1L28,80 M89.1,85.4L89.1,85.4l-1,0.4L86.7,83l-1,0.4l1.4,2.7l-1,0.4l-1.5-2.6l-0.8,0.3l-1.3-2.4l-0.3,0.1l-1-1.9 M91,81.5
			l1.1,2.8L91,84.7L89.8,82L91,81.5 M52.3,82.4l0.5,1.1l-1.1,0.6L50.2,83l0,0L52.3,82.4 M59.1,83L59.1,83l1.5-0.2l0.2,2.1l1.1-0.2
			l0.2,2.3l1.2-0.2L63,89l1.4-0.3l-0.1,1.9l1.7-0.4v1.6l1.6-0.6v-1.5l1.4-0.6l0.1,1.4l1.1-0.7l-0.1-1.4l0.1-1.6l0.6-0.4l0.2-0.3
			l0.1-1.6L71,82.6 M70.7,87.8l0.1,1.1V89l0.2,1.3l-0.6,0.6l-1.1,0.8l-0.2-1.3l-1.4,0.7l0.2,1.4l-1.6,0.7L66,91.8l-1.6,0.5l-0.1-1.7
			L62.9,91l0.2-2l-1.2,0.2L62,87l-1.1,0.2L60.7,85l-1.3,0.3L59,83l-1.5,1.2l-1.2-0.6l-0.6,0.7l-1.1-0.1v-0.7l-1.7,0.1l0.6,0.7
			l1.1-0.1v0.3l0.6,0.1l0.5-0.2l0.6,0.5l1.2-0.6l0.2,1.5l1.8-0.5v2.2l1.4-0.2l-0.1,2.3l1.2-0.2l-0.2,2.1l1.2-0.3v1.7l1.4-0.4
			l0.2,1.4l1.7-0.6l0.2,1.4l1.7-0.7l-0.3-1.4l1.5-0.8l0.3,1.4l1.1-0.7L70.4,91l-0.2-1.3l0.6-0.6 M94.3,84.1l0.9,3.1h-0.4l-1-3.1
			H94.3l-0.9-2.6 M27,82l-1.3,2.3l-0.4-0.1l-1.5,2.6L23,86.5l-1.6,2.6l-0.9-0.3l0,0l-1-0.3l1.5-2.7l1,0.4l-1.6,2.6 M89.1,85.5
			l1.4,2.8l-1,0.3L88,85.9l-1,0.4l1.6,2.7l-0.9,0.3l-1.6-2.6l-0.8,0.2l-1.5-2.6l-0.4,0.1L82.2,82 M93.1,84.1l1,3.1l-0.9,0.2l-1.2-3
			L93.1,84.1 M50.2,83l-0.4,2.2l1.8,0.5l0.2-1.4l1.1,0.6l0.7,0.1l0.3-0.4l0.3,0.1l0.4-0.1v0.3h0.2l0.2-0.3l0.2,0.2h0.4l-0.3-0.4
			L55,84.6l-0.4-0.1v-0.1L54,84.5l-0.4-0.2L53,84.8l0.3,1.2l-1.6-0.3l0.3,2l-2.1-0.3l-1.5-0.2l0.1-2.3l1.3,0.3v2.2l0.2,2.3l-1.5-0.2
			l-0.2-2.3L47.2,87l0.2,2.2L46.1,89l0.2,2l-1.4-0.4v1.6l-1.7-0.6L43,93l-1.7-0.6L41.1,94l-1.5-0.8l-1.1-0.8l0.3-1.5l1.1,0.8
			l-0.3,1.4l-0.4,1.5l-1.1-0.7l0.4-1.6l-0.7-0.6l0.3-1.4L37.9,90l0.3-1.3l0.1-1.3L38.2,86l-0.1-1.7 M70.9,84.5l0.2-0.2 M54.4,84.8
			l-0.2,0.3l0.4,0.2v-0.5H54.4l-0.1-0.2L54,84.8h-0.4l0.3,0.8l0.3-0.5L54,84.8 M55.2,84.8L55,85.1l0.3,0.5l0.3-0.8l0.7-0.1L56,85.9
			l-0.8-0.3l-0.6,0.5v-0.8l0.4-0.2l-0.2-0.3 M91,84.7l1.3,2.9l-0.9,0.3L90,85L91,84.7 M57.6,85.7l-0.3,2l-2.7-0.1l0,0L53.2,86
			l0.8-0.3l0.6,0.5v1.4L56,86L57.6,85.7L57.6,85.7 M38.1,86l0.2,0.2 M25.6,84.3l-1.4,2.6l-0.4-0.1L22,89.3l-0.7-0.2l-1.6,2.6
			l-0.8-0.3l-0.8-0.2L17.2,91l1.4-2.9l1,0.3l-1.4,2.8L16.8,94L16,93.8l1.2-2.9l-0.8-0.2l-1.1,2.9l-0.7-0.2L13.7,96L13,95.8l-0.6-0.1
			L12,95.6l0.6-2.4h-0.3l0.8-2.9 M95.2,87.2l0.9,3.1h-0.3l-0.9-3.1l-0.7,0.1l1,3.1l-0.8,0.1l-1.1-3l-1,0.2l1.2,3l-0.8,0.2L91.4,88
			l-0.9,0.3l1.4,2.9l-0.8,0.2l-1.5-2.8l-1,0.3l1.6,2.6l-0.8,0.3l-1.6-2.6L87,89.3l-1.7-2.6l-0.5,0.1l-1.4-2.6 M70.7,87.8l0.2-0.3
			L71,86 M51.9,87.7l0.2,2.1l2.5,0.1v-2.4L51.9,87.7 M59.4,87.4l-0.2,2.3l-2.1,0.2l0.3-2.2L59.4,87.4 M38.5,87.8l-0.2-0.3
			 M96.1,90.4l0.7,2.9h-0.3l-0.8-2.9h-0.6l0.8,2.9l-0.7,0.1l-1-2.9l-0.8,0.2l1,2.9l-0.7,0.2l-1.1-2.9L91.9,91l1.2,2.9L92.4,94
			l-1.3-2.8l-0.9,0.2l1.4,2.8l-0.7,0.2l-1.6-2.7l-0.7,0.2L87,89.3l-0.5,0.2l-0.9-1.3l-0.8-1.3 M20.5,88.8l-1.6,2.7l-1.4,2.8l0.7,0.2
			l1.5-2.7l0.7,0.2l1.7-2.6l0.5,0.2l0.8-1.2l0.9-1.4 M70.8,89l0.2-0.3l-0.1-1.3 M38.4,89l-0.2-0.3 M47.3,89.2l0.2,2.1l1.2,0.2
			l-0.3-2.1L47.3,89.2 M12.3,93.1l-0.6,2.4H12l-0.5,1.9H12l0.5-1.9l0.7-2.5l0.7,0.1l-0.7,2.5l-0.6,1.9l0.7,0.2l0.6-2l0.7,0.2
			l0.9-2.5l0.7,0.2l-1,2.6l0.6,0.2l1.1-2.6l0.7,0.2l-1.2,2.6l0.6,0.2l1.3-2.6l0.6,0.2l1.6-2.7l0.5,0.2l1.7-2.6h0.1l0.7-1.2
			 M96.8,93.1l0.6,2.4h-0.3l-0.6-2.4H96l0.7,2.4l0,0l-0.6,0.1l-0.8-2.5l-0.8,0.2l0.8,2.5L94.6,96l-0.9-2.5L93,93.7l1,2.6l-0.6,0.2
			l-1.1-2.6l-0.7,0.2l1.2,2.6L92.3,97l-1.4-2.5l-0.6,0.2l-1.6-2.8l-0.5,0.2l-1.7-2.7h-0.1L85.6,88 M69.6,93L69.6,93l-1.4,0.8
			l0.4,1.5L66.9,96l-0.4-1.4l-1.7,0.6l-0.2-1.5L63,94.2l-0.1-1.5L61.6,93l0.1-1.7l-1.3,0.3l0.3-2.1l-1.5,0.2l-0.3,2.1l1.5-0.2
			l-0.1,1.8l1.3-0.3v1.6l1.4-0.4l0.1,1.5l1.6-0.5l0.3,1.5l1.8-0.6l0.5,1.5l1.6-0.6l-0.4-1.6l1.4-0.8L69.6,93 M37.8,90l-0.2,0.5
			l-0.3,1L37.2,93L37,94.5l-0.1,0.6l0.3,0.4l0.6,0.4l1,0.5l0.4-1.7l1.4,0.8L41,94l1.6,0.7l0.3-1.4l1.6,0.6l0.2-1.4l1.4,0.4V91
			l1.3,0.3V93l1.3,0.3l-0.1-1.8l1.5,0.2l-0.1-2l2.1,0.2l0.2,2.2l-2-0.2l0.2,1.9L49,93.5V95l-1.4-0.4v-1.5l-1.3-0.3l-0.1,1.5
			l-1.5-0.5l-0.1,1.4l-1.8-0.6l-0.4,1.5l-1.8-0.7L40.3,97l-1.4-0.6l-0.4,1.9l-1-0.3l-0.6-0.3l0.4-2.2l0.3-2l0.3-1.6l-0.4-0.2l0.2-1
			 M37.2,93L37.2,93l0.3,0.5l0.6,0.5l-0.3,1.9L37.4,98l-0.6,2.3l1.1,0.2l0.6-2.1l1.4,0.4l0.5-1.7l1.6,0.6l0.5-1.5l1.8,0.6l0.4-1.5
			l1.6,0.4l0.1-1.5l1.4,0.4v1.5l1.4,0.4V95l1.5,0.3v-1.6l1.9,0.2L52.4,92l2.3,0.1v-2.2l2.5-0.1L56.9,92l2-0.2l-0.1,1.9l1.5-0.3V95
			l1.4-0.3v1.5l1.5-0.4l0.3,1.5l1.7-0.5l0.5,1.4l1.7-0.5l0,0 M71.4,90.6l0.3,1l-0.1-0.1L71.4,90.6L71.3,90L71,88.7 M71.3,90.1
			L71,90.4l0.3,1.4l0.3-0.3l0.3,1.5v-0.1l-0.2-1.4 M37.4,91.6L37.4,91.6l-0.2,1.5L37,94.5 M67.3,97.6l0.5,1.6l-1.7,0.5l-0.6-1.5
			l-1.7,0.4l-0.5-1.3l-1.6,0.4l-0.1-1.5l-1.4,0.3V95l-1.5,0.3l0.1-1.6l-2,0.2l0.1-1.9l-2.3,0.1V94l-2.2-0.1l0,0l0.1,1.6l-2-0.3
			l0.1,1.5L49,96.3v1.4l-1.6-0.3l0.2-1.4L46,95.7l-0.2,1.5l-1.7-0.4l-0.5,1.4l-1.8-0.6l-0.5,1.6l-1.5-0.4l-0.6,1.9l-1.4-0.2
			l-0.6,2.3l-1-0.1l0.6-2.4l-0.5-0.2l-0.1-0.2l0.5-2.5l0.3-2.3 M11.7,95.6l-0.4,1.9h0.2l-0.4,1.4h0.5l-0.4,0.7l0.6,0.1l0.4-0.7
			l0.4-1.3L12,97.6l-0.4,1.3l0.6,0.1l0.6,0.2l0.4-1.3l0.6,0.2l-0.5,1.4l0.5,0.3l0.5-1.4l0.7-2l-0.6-0.2l-0.7,2l0.6,0.3l0.5,0.2
			l0.8-2.1l0.6,0.2l-0.9,2.1l0.6,0.2l1-2.1l0.6,0.2l1.3-2.6l0.4,0.2l1.6-2.7l0.2,0.1l1.6-2.7 M96.6,95.6l0.5,1.9l-0.6,0.1L96,95.8
			L95.3,96l0.6,1.9l-0.6,0.3l-0.7-2L94,96.4l0.7,2l0,0l-0.5,0.2l-0.8-2l-0.6,0.2l0.9,2.1l-0.6,0.2l-1-2.1l-0.6,0.2l-1.3-2.6
			l-0.5,0.2l-1.6-2.7l-0.1,0l-1.6-2.7 M71.2,91.7l0.4,1.8L71,94l-0.4-1.6l0.6-0.6 M71.9,93.1L71.9,93.1l-0.3,0.4l0.3,2l0.2-0.3
			L72,94.4l-0.2-1.5 M63.8,98.5L63.8,98.5L62.1,99l-0.4-1.4l-1.6,0.3v-1.4l-1.6,0.3l0.1-1.5l-1.9,0.3l0.1-1.6l-2.1,0v1.6l-2.1-0.1
			v1.4l-1.9-0.2l0.1,1.4L49,97.8l-0.2,1.4L47.1,99l-1.7-0.4l0.5-1.4l1.6,0.4L47.1,99l-0.4,1.8l-1.9-0.7l0.6-1.5l-1.7-0.4l-0.6,1.5
			l-1.7-0.4l-0.6,1.8l-1.5-0.3l-0.6,2.1l-1.4-0.1l-0.6,2.4h-1l0.6-2.4l-0.5-0.1l0.2-1.1l0.3-1.6 M72.1,94.4l-0.2-1.3 M70.9,93.9
			l0.3,1.9l-1,0.5l-0.4-1.7L70.9,93.9 M11.3,97.5L10.8,99l0.2-0.1l-0.4,0.7l0.4-0.1l-0.3,0.3l0.7,0.1l0.3-0.3l0.7,0.3l0.6,0.3
			l0.6,0.4l0.3-0.8l0.5,0.3l0.5-1.5l0.5,0.2l-0.6,1.6l0.6,0.3l0.5-1.6l0.6,0.2l1-2.1l0.4,0.2l1.4-2.5l0.2,0.1l1.6-2.7 M94.7,98.4
			l0.5,1.4l0,0l-0.5,0.3l-0.5-1.5l-0.5,0.2l0.6,1.6l-0.6,0.3l-0.6-1.6l-0.6,0.2l-1-2.1l-0.4,0.2l-1.4-2.5L89.5,95l-1.6-2.7
			 M72.2,95.1l0.3,2.3l-0.2,0.3l-0.4-2.3l-0.6,0.4l0.4,2.1l0.6-0.3l0.5,2.4v-0.1l-0.4-2.5 M96.7,95.6h0.4l0.5,1.9h-0.5l0.4,1.3H98
			l-0.4-1.4h0.3l-0.5-1.9 M35.6,102.6l-0.5,2.5h0.5l-0.5,2.5h1l0.5-2.5H38l0.6-2.3l1.6,0.1l0.6-2l1.7,0.3l0.6-1.6l1.7,0.5l-0.6,1.6
			l1.6,0.8l0.8-1.6l0,0h2.1v-1.5l1.8,0.3V98l1.9,0.2v-1.4l2.1,0.1v-1.4l2.2-0.1V97l1.9-0.2v1.4l1.6-0.3l0.2,1.4l1.8-0.3l0.4,1.7
			l1.9-0.6l-0.6-1.5 M70.3,96.3L70.3,96.3l-1.4,0.6l0.5,1.7l1.4-0.4L70.3,96.3 M56.7,97v1.4l-2.1,0.1v1.4l-2-0.1l0.1,1.4l-1.9-0.1
			l0.2,1.5l-1.8-0.1l-0.3-1.6l1.9,0.2v-1.4l1.9,0.2l-0.1-1.4l2.1,0.1v-1.3L56.7,97 M10.8,99.8L10.8,99.8l-0.3,0.2l0.8,0.2l0.2-0.2
			l0.7,0.3l0.6,0.4l0.5,0.4l0.2-0.4l0.5,0.5l0.3-1l0.5,0.3l-0.3,1l0.5,0.3l0.3-1l0.5,0.2l0.6-1.6l0.4,0.1l1-2H18l1.4-2.5 M95.3,99.9
			l0.3,0.8l-0.5,0.4l-0.3-1l-0.5,0.3l0.3,1l-0.6,0.3l-0.3-1l-0.5,0.2l-0.6-1.6l-0.4,0.1l-1-2H91l-1.4-2.5 M35.9,101.5l0.4-1.4
			l0.6-2.4l-0.2-0.3 M96.7,97.7l0.4,1.3l-0.6,0.2L96,97.8L96.7,97.7 M72.9,99.9l0.3,1.3l-0.3-1.2l-0.5,0.2L71.8,98l-1,0.3l0.6,2.1
			l1.1-0.2l0.6,2.4l0.5-0.1l-0.3-1.3 M58.6,98.1v1.4l-2,0.2l0.1-1.4L58.6,98.1 M94.8,98.4l0.6-0.2l0.5,1.4l0.6-0.3l0.3,0.7l0.7-0.3
			l0,0L97,99l0.6-0.2l0.4,0.7l0.5,0.1L98,98.9l0.3,0.1l-0.4-1.4 M69.3,98.7l0.6,1.9l-1.5,0.3l-0.6-1.7L69.3,98.7 M10.8,99l-0.5,0.9
			l-0.1,0.1l-0.4,0.1l-0.5,0.1h0.8l0,0l1,0.3l0.1-0.3l0.8,0.4l0.1-0.2l0.2-0.4l0.3-0.7l0.6,0.3l-0.3,0.8l-0.2,0.4l-0.1,0.2l0.6,0.5
			l0.1-0.4l0.6,0.5l0.1-0.5l0.5,0.4l-0.1,0.6l0.6,0.3l0.1-0.7l0.5,0.2l0.3-1l0.4,0.1l0.6-1.5h0.2l1-2 M92,99.5l0.6,1.5h0.2l-0.6-1.5
			H92l-1-2 M60.4,99.3v1.5l-1.9,0.2l0.1-1.4L60.4,99.3 M97.4,99.7l0.6-0.1l0.3,0.3h0.1l0.5,0.1l-0.1-0.1l-0.3-0.2 M10.4,99.8
			l0.3-0.2 M73.5,102.5L74,105h-0.4l-0.6-2.4l-1,0.1l-0.6-2.3l-1.4,0.2l0.6,2.1l0,0l-1.5,0.1l-0.6-2l-1.7,0.3l-0.6-1.6l-1.7,0.5
			l0.5,1.6l-1.6,0.7l-0.9-1.6v-0.1l-2.1,0.2l-0.3,1.6l-1.8,0.1l0.1-1.5l-1.9,0.1v-1.4l-2,0.1v1.4l-1.9-0.1l0.1,1.4h-1.9L51,104
			l1.7-0.1l-0.1-1.4h1.9v-1.3l2-0.1l-0.1,1.4h-1.9v1.4l1.9,0.1l0.1-1.4h1.9l-0.2,1.5h-1.8l-0.1,1.5l-1.8-0.1V104l-1.8,0.1l0.1,1.5
			l1.8-0.1v1.5l1.6,0.1l0.1-1.6l1.6,0.2l0.2-1.7l1.5,0.1l0.5-1.7l1.4,0.3l1-1.9 M95.3,99.8l0.5-0.3l0.3,0.7l0.6-0.3l0.2,0.4l0.7-0.3
			l-0.3-0.3 M98.7,99.8L98.3,99 M10.7,99.9h-0.5 M98.4,99.9l0.2,0.2l0.7,0.1l-0.4-0.1 M99.3,100.1l0.5,0.1H99h-0.1l-0.3-0.2
			l-0.8,0.2l-0.2-0.2l0.7-0.1 M9.4,100.1l-0.5,0.1l0.6,0.1l0.7-0.2l0.3-0.2L9.9,100 M100.6,100.3l0.7,0.2l-0.3-0.1h-0.1L100.6,100.3
			l-0.3-0.1l-0.5-0.1 M96.1,100.3L96.1,100.3l-0.5,0.4l0.2,0.4l0.6-0.4L96.1,100.3 M10.2,100.2l0.4,0.7H9.9l0,0l-0.4-0.5l-0.7,0.2
			l-0.6-0.1l0.3-0.1l0.3-0.1 M100.4,100.3l-0.6,0.1l-0.7-0.2 M100.9,100.5l-0.6,0.1l-0.6-0.2l-0.5,0.5h-0.7l0.4-0.7l-1,0.3v-0.3
			l-0.8,0.4l-0.1-0.3l-0.6,0.4l0.1,0.3l0.6-0.4l0.1,0.3l0.8-0.3l-0.1,0.6l0.7-0.2l-0.2,0.8h0.7l0.2-0.8l0.7,0.2l0.4-0.5l0.7,0.4
			l0.5-0.2l-0.5-0.4 M9.9,100.9l-0.7,0.2l-0.4-0.5l-0.7,0.5L7.6,101l0.6-0.4l-0.4,0.1l0.7-0.2 M101.3,100.7l0.7,0.4l-0.5-0.1
			l0.6,0.5h0.1l0.4,0.1l-0.2-0.2l-0.3-0.2 M9.9,100.9l0.2,0.8h0.7l-0.2-0.8l0.7,0.2l-0.1-0.6l0.8,0.4v-0.3l0.6,0.4l-0.1,0.4
			l-0.6-0.4v0.5l-0.7-0.4l0.1,0.8l-0.6-0.1l-0.1,1l-0.6-0.1v-1l-0.7,0.1l-0.2-0.7l-0.7,0.4l-0.3-0.4l-0.6,0.4L7,101.4l0.6-0.5
			L7.1,101l0.7-0.4 M93.2,100.9L93.2,100.9l-0.4,0.1l0.3,1h-0.2l-0.3-1 M9.5,101.9L9.5,101.9v0.8l-0.3,0.7l0.7,0.1l0.6,0.2l0.4-1
			l0.5,0.2l0.2-1.1l0.5,0.3l0.1-0.7l0.5,0.4l0.1-0.4l0.5,0.5l0.1-0.3l0.5,0.5l0.1-0.4l0.5,0.4l-0.1,0.5l0.5,0.4l0.1-0.5l0.5,0.2
			l0.2-0.7h0.4l0.3-1h0.2l0.6-1.5 M7.1,101.1l-0.2,0.2l-0.3,0.2l0.4-0.1l-0.5,0.4l-0.4,0.1l0.2-0.2l0.2-0.2l0.3-0.2 M42.4,101.3
			L42.4,101.3l1.7,0.4l-0.6,1.8l-1.8-0.3L42.4,101.3 M102.1,101.3l-0.5,0.1L101,101l-0.4,0.4l-0.7-0.4l-0.2,0.7l-0.7-0.1v1l-0.6,0.1
			l-0.1-1l-0.6,0.2V101l-0.7,0.3v-0.5l-0.6,0.5l-0.1-0.3l-0.6,0.5l-0.1-0.4l-0.5,0.5v-0.5l-0.5,0.4l0.1,0.6l0.5-0.4l0.1,0.4l0.5-0.5
			l0.1,0.3l0.5-0.5l0.1,0.5l0.5-0.4l0.1,0.7l0.5-0.3l0.2,1.1l0.5-0.2l0.4,1l0.6-0.2l-0.3-0.9h0.7v-0.8l0.7,0.3l0.2-0.6l0.7,0.4
			l0.4-0.4l0.6,0.4l0.4-0.1l-0.5-0.4 M102.6,101.5l0.5,0.3l-0.6-0.5 M6.4,101.7l0.2-0.2 M66.8,101.3l0.6,1.9l0,0l-1.8,0.3l-0.6-1.8
			L66.8,101.3 M102.7,101.8L102.7,101.8l0.4,0.1v-0.1 M9.4,101.9l-0.7,0.3l-0.2-0.6L7.9,102l-0.4-0.4L7,101.9l-0.5-0.1l-0.4,0.3
			l-0.3,0.1l0.4-0.3 M103.1,101.9l0.4,0.3l-0.4-0.1l-0.4-0.3 M103.5,102.1l0.3,0.2L103.5,102.1L103.5,102.1h-0.4l-0.4,0.1l-0.4-0.2
			l-0.4,0.3l-0.5-0.3l-0.2,0.5l-0.6-0.3l-0.1,0.7l-0.6-0.2l0.2,0.7l-0.6,0.1l0.6,0.5l-0.5,0.2l-0.6-0.6l-0.4,0.2l-0.5-1l-0.4,0.2
			l-0.3-1l-0.4,0.4l-0.2-0.7l-0.4,0.5l-0.2-0.5l-0.5,0.6l-0.2-0.4l-0.5,0.5l-0.1-0.5l-0.5,0.3l-0.1-0.6l-0.5,0.2l-0.3-1 M5.7,102.1
			l-0.3,0.1L5.7,102.1L5.7,102.1H6l0.5,0.1l0.4-0.3l0.4,0.4l0.5-0.3l0.2,0.5l0.6-0.3v0.7l-0.2,0.6L8,103.9h0.5l0.6,0.1l0.5,0.2
			l0.6-0.6l0.4,0.2l0.5-1.1l0.4,0.2l0.3-1l0.4,0.4l0.2-0.7l0.4,0.5l0.2-0.5l0.5,0.6l0.1-0.4l0.5,0.5l-0.2,0.4l0.6,0.5l0.2-0.6
			l0.5,0.3v-0.6l0.4,0.1l0.2-0.7H16l0.3-1 M103.3,102.2l-0.5,0.1l-0.2-0.2l-0.4,0.3l-0.4-0.2l-0.2,0.5l-0.5-0.3L101,103l-0.6-0.2
			l0.2,0.6l-0.6-0.1l0.5,0.5l-0.6,0.1l0.8,0.3l-0.5,0.3l-0.8-0.4l-0.4,0.3l-0.6-0.6l-0.3,0.3l-0.5-1l-0.4,0.3l-0.4-1l-0.4,0.4
			l-0.3-0.6l-0.4,0.6l-0.3-0.5l-0.5,0.5l-0.2-0.4l-0.6,0.4l-0.1-0.5l-0.5,0.2l-0.2-0.7h-0.4l0.2,0.7h-0.2l-0.1-0.7 M5.9,102.2
			l0.4,0.1l0.3-0.2l0.4,0.3l0.4-0.2l0,0 M103.8,102.3l0.2,0.1l-0.5-0.1l-0.1-0.1 M7.4,102.2l0.3,0.5l-0.4,0.2L7,102.4l-0.2,0.1
			l-0.4-0.3l-0.2,0.1l-0.5-0.1l0.1-0.1 M5.3,102.4L5.3,102.4l-0.2,0.2l0.5-0.1l-0.1,0.2H6l0.2-0.2l0.4,0.2l0.1-0.1l0.2,0.2H7
			l-0.2-0.4l-0.2,0.1l-0.4-0.3L6,102.4H5.3l0.3-0.2L5.3,102.4l0.2-0.1 M102.9,102.2l-0.4,0.3l-0.2,0.4l0,0l-0.2-0.1l-0.1,0.5
			l-0.4-0.1v0.4l-0.4-0.1l0.3,0.4H101l-0.3-0.4l0.5,0.1L101,103l0.5,0.2l0.1-0.5l0.4,0.2l0.3-0.4l0.2,0.1l0.2,0.1l0.4-0.3l0.2,0.2
			l0.5-0.1l-0.1-0.2l-0.5,0.1L102.9,102.2 M104,102.3L104,102.3l0.4,0.2l0.1,0.3l-0.3-0.2l-0.5-0.1l0.2,0.2h-0.5l-0.2-0.2l-0.4,0.2
			l-0.1-0.1l-0.2,0.2h-0.1 M102.4,102.9v0.2l0.3-0.3l0.2,0.2l0.4-0.2l0.2,0.2h0.5l-0.1-0.2l0.4,0.1v-0.2l-0.2-0.3 M67.4,103.1
			l0.6,2.2l-1.8,0.2l-0.6-2.1L64,104l-0.5-1.6l-1.2,1.4l-0.7-1l-0.8,1.7l-1.1-0.3l-0.5,1.8l-1.2-0.2l-0.3,1.6l-1.4-0.2l-0.2,1.4
			l-1.4-0.1v-1.4l-1.6,0.1l-0.1-1.6l-1.6,0.2L51,104l-1.5,0.1l-0.5-1.7l-1.4,0.3l-1-1.9 M8.1,103.5L8.1,103.5l-0.3,0.4l-0.4,0.2h0.3
			l0.3,0.1l0.7-0.3l0.5-0.5l-0.6,0.1L8.1,103.5l0.1-0.5l-0.5,0.2l-0.1-0.5l0.5-0.3l0.1,0.6l0.6-0.2l0.6-0.2h0.7l-0.3,0.9L9.2,104
			l-0.7,0.3L8,104.1l-0.8,0.1l-0.2,0l0.2-0.2l0.1-0.1l0.2-0.3l0.1-0.4l-0.4,0.1l-0.1-0.5l-0.3,0.1l0.1,0.5l-0.2-0.3v-0.2 M7.3,104.1
			H7H6.8h0.3l0.5,0.4l0.8-0.2l0.5,0.3l0.8-0.4l0.4,0.3l0.6-0.6l0.3,0.2l0.5-1l0.3,0.3l0.4-1l0.3,0.4l0.3-0.6l0.4,0.6l0.3-0.5
			l0.5,0.5l-0.3,0.7l0.6,0.6l0.3-0.7l0.5,0.4l0.2-0.6l0.4,0.1l0.1-0.6H16l0.1-0.7 M93.7,102.4l0.1,0.6l-0.4,0.2l-0.1-0.7L93.7,102.4
			 M4.7,102.6l-0.1,0.2l0.3-0.2l-0.1,0.2l0.4-0.1h0.1l0.1-0.2l-0.5,0.1l0.1-0.2L4.7,102.6L4.7,102.6l0.4-0.3 M70.5,102.6h1.4
			l0.6,2.4h1l0.4,2.5h0.4L74,105 M94.3,102.8L94.3,102.8l-0.5,0.3l0.2,0.6l0.5-0.4L94.3,102.8 M5.9,102.7L5.7,103l0.4,0.2l0.2-0.2
			l0.3,0.3l0.2-0.2l-0.4-0.3L6.3,103L5.9,102.7 M5.3,102.9l0.4,0.1l-0.2,0.3H5L5.3,102.9 M102.2,103v0.4l-0.3-0.1v0.4l-0.4-0.1
			l0.2,0.3h-0.3h-0.1 M104.4,103.1L104.4,103.1l0.2,0.2l-0.4-0.1h-0.1l-0.2-0.3L104.4,103.1l-0.1-0.3l0.3,0.2v-0.2l0.2,0.4l-0.1-0.1
			l-0.1-0.1 M4.5,103.1l0.1-0.2 M40.2,102.9l-0.6,2.3l1.7,0.1l0.6-2.2L40.2,102.9 M67.4,103.1l1.7-0.2l0.6,2.3l1.5-0.1l-0.6-2.3
			 M4.5,103.2L4.5,103.2L4.5,103.2l-0.1,0.4l0.3-0.2l0.1-0.1l0.1-0.2L4.5,103.2L4.5,103.2L4.5,103.2 M104.2,103.1l-0.4,0.1l-0.2-0.3
			l-0.4,0.2l-0.2-0.2l-0.3,0.3l-0.2-0.2l-0.2,0.3l0,0l0.2,0.2l0.2-0.3l0.2,0.3l0.3-0.4l0.2,0.3l0.4-0.2l0.3,0.4h0.4L104.2,103.1
			 M104.5,103l0.2,0.1V103 M104.8,103.1v0.2V103.1 M4.5,103.2L4.5,103.2 M4.8,103.2l0.3-0.1l-0.2,0.4h0.4l0.2-0.2l0.4,0.2l0.2-0.3
			l0.3,0.4l0.2-0.3l0.2,0.3l0.2-0.2l0.3-0.1l-0.1,0.4l0.4-0.1l0.4-0.1 M6.8,103.6L6.8,103.6l-0.2,0.3v0.3l0,0l0.2,0.2l0.1,0.1h0.7
			l0.5,0.5l0.8-0.4l0.4,0.4l0.8-0.5l0.3,0.4l0.7-0.8l0.3,0.4l0.6-1.1l0.3,0.4l0.4-1l0.3,0.7l0.4,0.7l0.5,0.6l0.4-0.7l0.6,0.5
			l0.3-0.9l0.4,0.2l0.1-0.7l0.2,0.1l0.1-0.7 M101.5,103.9l0.3,0.2h-0.3l-0.6-0.3h-0.5l0.7,0.3l-0.5,0.2l0.8,0.2l-0.5,0.5l-0.8-0.4
			l-0.4,0.4l-0.8-0.5l-0.3,0.4l-0.7-0.8l-0.3,0.4l-0.6-1.1l-0.3,0.4l-0.4-1l-0.3,0.7l-0.4-0.7l-0.5,0.7l-0.6,0.6l-0.6,0.5l-0.3-0.9
			l-0.4,0.2l-0.1-0.7h-0.2l-0.1-0.7 M102.4,103.5L102.4,103.5v0.4l0.1,0.2v0.1l-0.2,0.2l-0.1,0.2l-0.4,1L101,105l-0.5,0.5l-0.8-0.6
			l-0.3,0.5l-0.8-0.6l-0.2,0.5l-0.8-1l-0.3,0.4l-0.5-1l-0.3,0.5l-0.4-0.7l-0.4,0.7l-0.5,0.6l-0.5-0.7l-0.3-0.8l0.6-0.5l0.3,0.6
			l0.4,0.7l0.4,0.5l0.4-0.5l0.6,1l0.4-0.3l1,1.2l0.2-0.6l0.9,0.8v-0.7l0.7,0.6l0.3-0.5l0.7,0.7l0.6-0.7l0,0l0.7-0.5l0.2-0.6
			l-0.1-0.1l0,0 M104.7,103.7L104.7,103.7l0.2,0.4l-0.3-0.1l-0.3-0.4L104.7,103.7l-0.2-0.4l0.3,0.2v-0.1l0.2,0.3l-0.1-0.1l-0.1-0.2
			 M6.9,104.6l0.4,1l0,0l0.9-0.5l0.4,0.5l0.8-0.6l0.3,0.5l0.8-0.6l0.1,0.6l0.9-1l0.3,0.4l0.6-1.1l0.3,0.5l0.4-0.7l0.4-0.7l0.5,0.7
			l-0.4,0.6l-0.4,0.5l-0.4-0.5l-0.6,1l-0.4-0.4l-1,1.2l-0.2-0.5l-0.8,0.7l-0.1-0.7L9,106.1l-0.4-0.5l-0.7,0.7l-0.6-0.8 M51.5,107.2
			L51.5,107.2l0.4,1.5l1.2-0.3l0.2,1.3l1.2-0.1v-1.4l-1.4,0.1l-0.2-1.4L51.5,107.2l-0.3-1.5l-1.3,0.2l-0.5-1.8l-1.1,0.4l-0.8-1.8
			l-0.7,1.1l-1.1-1.4l-0.5,1.6l-1.7-0.6l-0.6,2.2l-1.8-0.2l-0.5,2.4l-1.6-0.1l0.5-2.5l-1.5-0.1l-0.5,2.5h-1.3l-0.4,2.7h-1l0.4-2.7
			h-0.4l0.4-2.6 M4.6,103.4l-0.1,0.3l0,0l-0.2,0.2l0.1-0.3l-0.1,0.1l0.2-0.4 M4.5,103.7l0.3-0.1L4.5,104H4.2L4.5,103.7 M4.3,103.7
			L4.1,104l0.1-0.1 M5.9,103.5l-0.3,0.4l0.3,0.3l0.3-0.4l0.2,0.4l0.2-0.3l-0.2-0.4l-0.2,0.2L5.9,103.5 M102.8,103.5l-0.2,0.4
			l0.2,0.2l0.2-0.3l0.3,0.4l0.3-0.3l0.4,0.4l0.4-0.1l0.3,0.2h0.3l-0.3-0.4l-0.3,0.1l-0.4-0.5l-0.4,0.2l-0.3-0.4l-0.3,0.3
			L102.8,103.5 M105,103.7l0.1,0.4l-0.2-0.2L105,103.7 M7.3,103.9H7.1l0.2-0.3L7,103.7v-0.4 M101.8,103.8L101.8,103.8l0.4,0.1
			l-0.2-0.3l0.3,0.1v-0.4 M5.3,103.5L4.9,104l-0.3,0.2H4.2l0.3-0.2l0.3,0.1l0.5,0.1l0.3-0.4L5.3,103.5 M102.2,103.8L102.2,103.8
			l0.2,0.3l0.1,0.1h0.1v-0.3l-0.2-0.2 M7.4,103.9l0.4-0.1h0.4L7.7,104 M104.9,103.9l-0.2-0.1"
        />
        <path
          class="st0"
          d="M6.8,103.7v0.3l0.1-0.1l0.1-0.1 M102.4,104L102.4,104 M4.1,104L4,104.3l0.1-0.1l0.1-0.1 M102.1,104.1
			L102.1,104.1h0.2 M6.6,104.2L6.6,104.2l-0.2,0.2l0.1,0.3l0.1,0.2l-0.3,1.2l0.5,0.5l0.4,0.4l0.6-0.8l0.5,0.5l0.6-0.6l0.2,0.4
			l0.6-0.3l-0.2,1l1.2-1.2l0.5,0.4l0.9-1.2l0.5,0.3l0.4-0.8l0.5,0.3l0.5-0.3l0.4,0.9l0.6-1.1l0.4,0.3l0.3-0.9l0.2,0.1l0.1-0.8
			 M101.9,105.6l0.5,1.1l-0.4,0.4l-0.6-0.8l-0.4,0.5l-0.6-0.6l-0.2,0.4l-0.5-0.3l0.2,1l-1.2-1.2l-0.5,0.4l-0.9-1.2l-0.5,0.3
			l-0.4-0.8l-0.5,0.3l-0.5-0.3l-0.4,0.9l-0.6-1.1l-0.4,0.3l-0.3-0.9l-0.2,0.1l-0.1-0.8 M6.9,103.9l-0.1,0.2h0.1l0.2-0.1
			 M101.8,104.2h0.4l-0.3-0.2 M105.1,104.1L105.1,104.1L105.1,104.1l-0.2-0.1 M102.2,104.1L102.2,104.1L102.2,104.1l-0.3,0.1
			l-0.8-0.1l0.4-0.1 M105.1,104.1l0.1,0.2L105.1,104.1l0.1,0.3l-0.3-0.1l0.4,0.4l-0.4-0.1l-0.3-0.3l-0.3,0.3l-0.4-0.4l-0.3,0.2
			l-0.3-0.4l-0.2,0.3l-0.3-0.3v0 M6.7,104L6.7,104L6.7,104L6.7,104 M102.3,104.5h-0.8l0.5-0.4l0.4-0.1h0.1 M6.7,104.1L6.7,104.1
			l0.2-0.1 M6.6,104.9l0.7,0.5l-0.4,1.1l-0.4,1.2l0.4,0.2l0.5-0.8l0.5,0.4l0.6-0.8l0.4,0.4l0.8,0.1v-0.1l0.7,0.5l1-1.3l0.7,0.3
			l0.7-1.2l0.8,0.2l1-0.1l0,0l0.6,0.4l0.4-1.2l0.3,0.1l0.2-1 M6.8,104.2H6.6 M4.1,104.2l-0.2,0.3l0.3-0.1l-0.3,0.3l0.3-0.1l0.3-0.3
			l0.4,0.3l0.4-0.4l0.3,0.2l0.4-0.3l0.2,0.3l0.3-0.3v0.3l-0.1,0.1l-0.2,0.2v-0.3l-0.4,0.3l-0.2-0.3l-0.4,0.4l-0.3-0.2l-0.3,0.3
			l-0.3-0.3 M102.4,104.4l0.2-0.1 M6.8,104.4l-0.2-0.2 M102.8,104.4L102.8,104.4v-0.3 M3.9,104.6L3.9,104.6l-0.4,0.3L3.9,104.6
			L3.9,104.6l0.2-0.3 M104.9,104.7l-0.2,0.3l-0.4-0.3l-0.3,0.2l-0.4-0.4l-0.2,0.3l-0.4-0.3v0.3l-0.2-0.2l-0.1-0.1 M105.2,104.4
			l0.2,0.3l-0.2-0.2 M6.5,104.8l-0.2-0.2l-0.2,0.3L6,105.1l0.1-0.2l0.1-0.1 M102.8,104.6l0.2,0.3l0.1,0.1l-0.1-0.2l0.4,0.3v-0.3
			l0.4,0.3l0.2-0.2l0.4,0.3l0.3-0.3l0.4,0.2l0.2-0.4l0.3,0.1l-0.2-0.2l0.4,0.3l-0.2-0.1l-0.1,0.4l-0.4-0.1l-0.2,0.3l-0.4-0.2
			l-0.2,0.2l-0.4-0.3v0.2l-0.3-0.2l0.1,0.2l-0.4-0.3l0.3,1h-0.2l-0.2-1 M3.8,104.8l0.2,0.4l-0.4,0.1l-0.2-0.4h0l0.4-0.3
			 M105.8,104.9L105.8,104.9l-0.1,0.4l-0.2-0.1l-0.3,0.3l-0.4-0.1l-0.3,0.2l-0.4-0.2l-0.1,0.1l-0.3-0.2l0.1,0.2l-0.4-0.3 M3.4,104.9
			L3.3,105l0.1,0.3l0,0l0.2-0.1l0.2,0.3l0.4-0.2L4,105.1l0.5-0.2l0.2,0.3l0,0l0.4-0.3l0.2,0.3l0.4-0.3v0.3L6,105 M95.7,105l0.2,0.7
			l-1-0.1l0,0l-0.6,0.4l-0.5-1.1l-0.3,0.1l-0.2-1 M15.6,105v0.3l-0.3,1l-0.3-0.2l-0.7,1.5l-0.6-0.2l-0.9-0.3l0.6-1.3l0.2-0.7
			 M4.7,105.2l0.2,0.2l0.4-0.3l0.1,0.2l0.3-0.2l-0.1,0.2l0.3-0.2V105 M72.5,105.1l0.5,2.5h-1.4l-0.5-2.5H72.5 M46.9,103.8l1,1.7
			l-0.3,1.2l-1-1.4l-0.5,1.6l-1.4-0.9l-0.6,2.2l-1.7-0.3l-0.4,2.6l-1.8-0.1l-0.1,2.9h-1.7l0.2-2.9l0.4-2.7h-1.5l-0.4,2.7h1.5h1.7
			l0.4-2.7l1.8,0.2l0.6-2.4l1.8,0.5l0.5-2l1.3,1.2L46.9,103.8 M74.4,107.5l0.3,2.7h-0.4l-0.3-2.7h-1l0.4,2.7h-1.3l-0.4-2.7h-1.5
			l-0.5-2.5l-1.7,0.1l0.5,2.5l-1.8,0.2l-0.6-2.4l-1.7,0.5l-0.6-2l-1.3,1.2l-0.4-1.5l-0.9,1.7l-0.6-1l-0.7,1.9l-0.9-0.4l-0.5,1.7
			l-1.1-0.3l-0.4,1.4l-1.3-0.3l-0.2,1.3l-1.2-0.1v1.4h1.1l0.2-1.3l1,0.2l0.4-1.2l0.9,0.3l0.6-1.4l0.6,0.5l0.7-1.7l0.3,0.9l1-1.8
			l0.3,1.2l1-1.4l0.4,1.7l1.4-0.9l0.5,2.2l1.7-0.3l0.4,2.6l1.8-0.1l-0.4-2.6l1.7-0.1l0.4,2.7h1.5l0.2,3h-1.6l-0.2-2.9h-1.7l0.1,2.9
			h-1.7l-0.2-2.8l-1.7,0.2l-0.4-2.4l0,0 M94.7,105.7l0.7,1.6l-0.7,0.2l-0.6-1.5l-0.3,0.2l-0.3-1l-0.1-0.2 M105.8,105.3l-0.2,0.3
			h-0.1L105.8,105.3L105.8,105.3l0.1-0.4 M48.4,104.4l0.8,1.9l-0.4,0.9l-1-1.8L48.4,104.4 M4.7,105.3l-0.4,0.2l0.3,0.2l0.4-0.2
			l0.1,0.1l0.3-0.1v-0.1 M5.7,105.4l-0.4,0.2l0.1-0.1 M5.3,105.6L5.3,105.6l-0.4,0.2l0.2-0.2 M3.4,105.4l0.1,0.3l0,0L3.4,105.4
			 M103.9,105.7L103.9,105.7l0.4,0.2l-0.2-0.2 M105.7,105.7L105.7,105.7l-0.3-0.1l-0.3,0.2l-0.4-0.2l-0.3,0.1l0.2,0.1l-0.2-0.1
			l-0.3-0.2 M4.5,105.9H4.1l-0.3-0.1h0.4L4.5,105.9L4.5,105.9l0.3-0.2l-0.4-0.1L4,105.8l-0.3-0.2H3.5l0.3,0.2l-0.3-0.2 M96.5,105.5
			l0.7,1.2l-0.9,0.3l-0.6-1.3L96.5,105.5 M104.5,105.8L104.5,105.8l0.5-0.1h0.3l0.3-0.2 M4.7,105.8l0.3-0.1l0.2-0.2 M105.7,105.6
			l-0.2,0.2l-0.2,0.1l-0.4,0.1h-0.3 M5.9,105.1L5.7,106l-0.2,0.5l-0.2,0.7l0.2,0.3l0.5,0.1l0.4-1.2L6,105.9l0,0l0.2-1 M6,105.9H5.7
			 M103.5,105.9L103.5,105.9l0.4,1.1l0.1,0.1L103.5,105.9 M104,107.2l0.3,0.6l-0.1-0.1L104,107.2l-0.3,0.2l-0.5-1.5l-0.4,0.3
			l-0.2-1.2 M50,105.9l0.5,1.7l-0.6,0.5l-0.7-1.8L50,105.9 M100.1,106.6l0.4,0.6h-0.8l0,0l-0.7,0.5l-1-1.3l-0.7,0.3l0.8,1.4l-1,0.4
			l-0.8-1.5l-0.9,0.3l0.7,1.6l-0.7,0.3l-0.7-1.6l-0.4,0.2l-0.5-1.5h-0.1l-0.2-1 M6.8,107.9L6.8,107.9l0.4,0.3l0.5-0.7l0.4,0.4
			l0.6,0.4l0.7,0.6l0.8-1.1l0.8,0.4l0.8-1.3l0.9,0.3l-0.7,1.5l0.9,0.3l0.8,0.3l0.7-1.6l0.4,0.2l0.5-1.5h0.1l0.2-1 M61.6,106.6
			L61.6,106.6l0.3,1.4l1.2-1.1l0.4,1.9l1.5-0.5 M102.9,106.2l0.4,1.2l-0.4,0.2l-0.4-1L102.9,106.2 M47.6,106.7L47.6,106.7l-0.3,1.3
			l-1.2-1.1l-0.4,1.9l-1.5-0.5l-0.4,2.4l-1.8-0.2l-0.2,2.8h1.7l0.2-2.6l1.5,0.3l0.4-2.2l1.3,0.7l0.3-1.5v0 M5.3,107.2l-0.2,0.5
			L5.3,107.2L5.3,107.2l0.2-0.8 M6.1,105.9l-0.6,1.5l0,0l-0.3,1h0.4l-0.3,0.8l0.3,0.1l0.4-0.7l0.4-0.8L6,107.4l-0.4,1l0.4,0.1
			l0.4,0.2l0.3,0.3l0.5-0.7l0.4,0.4l0.6-0.8l0.6-0.7l0.4-0.6 M100.8,106.8l0.6,0.7l-0.5,0.4l-0.6-0.7L100.8,106.8 M9.4,107.3l-0.7,1
			L8,109l0.5,0.5l0.9-0.5l0,0l1,0.4l0,0l0.9,0.5l1,0.3l0.6-1.3l0.8-1.5l0.7-1.6 M104,107.6v0.2l-0.4-0.5l0.3,1h-0.4l-0.3-1l0.5-0.1
			l0,0 M101.8,107l0.5,0.8l-0.4,0.3l-0.5-0.7L101.8,107 M47.5,106.6l1.1,1.4l0.2-1l0.9,1.5l0.2-0.6l0.7,1.4l0.5-0.5l0.4,1.2l0.8-0.2
			l0.2,1.2h1l0.1,1.2h1v-1.3h-1l-0.1-1.3l-1.1,0.2l-0.3-1L51,109l-0.6-1.4l1-0.3 M15.4,106.4l-0.5,1.4h-0.1l-0.6,1.5l-0.5-0.1
			l-0.6,1.4l-0.8-0.4l-0.4,0.8l-0.6-0.2l0.1-1l0,0l0.7-1.3l-1-0.4l-0.7,1.2 M99.6,107.3l0.8,1.1l-0.7,0.6l-0.8-1.2l-0.9,0.4l0.7,1.2
			l-0.9,0.6l-0.7-1.3l-0.9,0.3l0.6,1.3l-0.8,0.4l-0.6-1.4l-0.5,0.1l-0.6-1.6h-0.1l-0.5-1.5 M5.5,107.4L5,107.9v-0.3l-0.2,0.5L5,108
			l0.1-0.1 M102.8,107.7l0.4,0.8l-0.4,0.2l-0.4-0.7L102.8,107.7 M104.2,107.7l0.2,0.5l-0.2-0.2l-0.1-0.2 M4.9,108.1l-0.2,0.6
			l0.1-0.1v-0.1L4.9,108.1 M61.6,106.7l-1.1,1.5l-0.2-1l-0.8,1.6l-0.2-0.7l-0.6,1.4l-0.5-0.5l-0.5,1.2l-0.8-0.2l-0.2,1.2l-1-0.1
			l-0.1,1.2l0.9-0.1l0.2-1.1l0.8,0.1l0.2-1h0.5l0.5-0.7l0.1,0.6l0.7-1.1v-0.2 M48.6,108.1L48.6,108.1l-0.2,1.1l-1.1-1.2 M59.5,108.9
			l-0.1,0.8v0.1l0.2,0.6l1.2-1.2l0.2,1.2l1.4-1l0.2,1.9l1.5-0.4l0.2,2.5l1.6-0.1l-0.1,2.9l1.7,0.1l0.1-3h-1.7l-0.2-2.6l-1.6,0.3
			l-0.3-2.2l-1.4,0.7l-0.3-1.5l-1.2,1.2l-0.2-1v-0.1 M101,107.9l0.6,0.8l-0.5,0.4l-0.7-0.7L101,107.9 M104.2,108.1l0.1,0.5l-0.3-0.2
			l0.3,0.8h-0.4l-0.3-0.8l-0.4,0.1l0.3,0.7l0.4-0.1l0.4,0.8h-0.4l-0.3-0.8l-0.3,0.2l-0.4-0.7l-0.3,0.3l-0.5-0.7l-0.4,0.4l0.6,0.7
			l0.3-0.4l0.4,0.6l0.3-0.2l0.3,0.7l0.3-0.1l0.3,0.7h0.4l-0.3-0.7l0.4,0.1l-0.4-0.8l0.3,0.2l-0.2-0.8l0.2,0.3l-0.2-0.6 M6.8,108
			l-0.4,0.7L6,109.4l-0.4-0.2l-0.3,0.7H4.9l0.4-0.8l-0.4,0.1l0.3-0.8l-0.3,0.2 M4.7,108.8l-0.2,0.7l0.1-0.2l0.2-0.7 M48.7,108.3
			l1.1,1.4l-0.1-0.8v-0.2 M104.6,108.9l0.2,0.7l-0.1-0.1l-0.1-0.1 M104.6,109.5l0.2,0.7l-0.2-0.1l0.2,0.6h-0.3l0.2,0.5h-0.4
			l-0.2-0.5l-0.4,0.2l-0.3-0.7l-0.3,0.2l-0.4-0.8l-0.2,0.3l-0.5-0.5l0,0l-0.4,0.3l-0.6-0.6l-0.5,0.5l-1-0.5l0,0l-1,0.4l0.5,0.9
			l0.7-0.3l-0.2-1 M4.5,109.5l-0.2,0.7l0.1-0.1l-0.3,0.6l0.3-0.1L4.2,111l0.3,0.1l0.3-0.5H4.5l0.3-0.6l-0.3,0.2l0.3-0.8l0.3-0.2
			L4.7,110l0.3-0.1l-0.3,0.7h0.4l0.3-0.7l0.3,0.2l0.3-0.7l0.3,0.2l0.4-0.6l0.3,0.4l0.5,0.3l0.6-0.6l-0.5-0.4L7,109.4v0.1l-0.5,0.4
			l-0.2-0.3L6,110.3l-0.3-0.2l-0.3,0.7L5,110.6l-0.2,0.5H4.4l0,0l-0.3,0.2v-0.1l0.1-0.1H4l0.1-0.2l0,0 M10.5,109.4l-0.5,0.9l0.6,0.4
			l-0.6,0.6l0.6,0.2l0.4,0.3h0.5l0.3-0.7l0.6,0.2l0.6-0.7l0,0l0.6,0.1l0.5-1.4l0.2,0.1l0.6-1.6 M102.2,109.4l0.2,0.6l-0.2,0.1
			l-0.4-0.4l-0.4,0.4l-0.7-0.6l-0.2,0.5H100l0.4,0.8l0,0l-0.6,0.1l-0.4-0.7l-0.6,0.4l-0.8-0.7l0,0l-1,0.3l0.4,0.8l-0.6,0.2l-0.6-0.7
			l0,0l-0.6,0.1l-0.6-1.4h-0.1l-0.6-1.6 M59.3,109.6l1.2-1.4 M50.9,110.1v0.7l0.1,0.1l0.7,0.2l-0.2-1L50.9,110.1l-0.5-0.8l-0.1,0.7
			l0.2,0.7l0.2,0.4l-1.1-0.8l-0.1,1l-1.2-1v1.7l-1.5-0.6v2.2l-1.6-0.2l0.2,2.7h-1.7l-0.1-2.9l1.6,0.1l0.1-2.5l1.5,0.4l0.2-1.9l1.4,1
			l0.1-1.2l1.2,1.2l0.2-0.6l-0.1-0.2 M9.4,108.9l-0.1,1l-0.6-0.1l-0.2-0.4L7.8,110l-0.4-0.4L7,110.1L6.8,110l0.2-0.6 M102.3,110
			L102.3,110l0.3,0.2V110v-0.1 M6.8,110L6.8,110l-0.3,0.2v-0.4 M51,110.9l-0.6-0.7l-0.7-1.2 M6.7,110.1l-0.3,0.4h0.2L7,110l0.2,0.3
			l0.6-0.3l0,0l0.6,0.3l0.4-0.5 M59.3,109.8l-0.7,1l0.2-0.6V110 M9.2,110l-0.4,0.8l0.6,0.1l0.5-0.7L9.2,110 M34.3,110.3l-0.2,2.9
			l0.4,0.1l0.2-3H34.3l0.4-2.7 M105,110.7L105,110.7l0.2,0.3H105l-0.2-0.4L105,110.7l-0.2-0.5l0.2,0.2l-0.2-0.7 M102.4,110.1
			l0.2,0.3h-0.1L102,110l-0.2,0.3l-0.6-0.3l-0.6,0.3l-0.4-0.5 M58.5,110.7l-0.3,0.5l1.1-0.7l0.1,1l1.2-1l0.1,1.5l1.5-0.6v2.2
			l1.6-0.2l-0.2,2.7h1.7l-0.4,3.2l1.7,0.2l0.4-3.3l1.7,0.1l0.2-3.1h1.7l-0.1,3.2l1.6,0.1v-3.3h1.4l-0.2-3h1l0.2,3l0.4-0.1l-0.2-2.9
			 M34,113.2v3.3l0.4,0.1l0.1-3.3h1l0.2-3H37l-0.1,3.1h-1.4v3.3h-1l0.2,3.5l-0.5-0.2l-0.1-3.4 M7.8,110.1l-0.2,0.7H7.2v-0.5
			l-0.5,0.3l-0.1-0.2l-0.2,0.1l0.1-0.1l0.1-0.2 M102.6,110.4L102.6,110.4v-0.2 M13.1,110.6v0.9l-0.3,0.2l-0.3-0.4l-0.4,0.6l-0.5-0.2
			l-0.3,0.4H11v-0.5l0,0l0.3-0.8h-0.5l-0.2-0.3l0.8-0.7 M6.4,110L6,110.6l-0.2-0.3L5.6,111l-0.3-0.3l-0.2,0.5L4.7,111l-0.2,0.5H4.3
			l0.1-0.5 M102.7,110l0.4,0.6l0.2-0.2l0.3,0.7l0.3-0.2l0.2,0.5l0.4-0.2l0.2,0.5h0.4l-0.1-0.5l0.2-0.1l0.1,0.2l0.2,0.1v-0.1
			l-0.1-0.1 M101.3,110.2l0.2,0.8h0.4v-0.5l0.5,0.3l0.1-0.2l0.2,0.1l0,0 M103,110.6L103,110.6L103,110.6l-0.2,0.1l-0.1-0.2
			 M96.1,110.5l-0.1,0.9l-0.3,0.2l-0.2-1h-0.1l-0.5-1.3 M12.1,111.8l0.1,0.4l-0.4,0.1l-0.2-0.2l-0.4,0.3H11l0.2-0.4l0.4,0.1
			L12.1,111.8L12.1,111.8h0.6L13,112l0.1-0.6l0.3,0.2l0.3-1h0.1l0.5-1.4 M3.9,111L3.9,111l-0.1,0.2v0.2l0.2-0.2v0.4l0.3-0.1
			l-0.2,0.7l0.4,0.1l0.2-0.6l0.4,0.2l0.2-0.5l0.3,0.2l0.2-0.5l0.2,0.2l0.3-0.6l0.1,0.2l0,0l0.1-0.2 M6.2,110.8L6.2,110.8l0.5-0.2
			l0.1,0.4l0.4-0.2L7,111.6l0.4,0.1l0.2-0.9L8,111l0.3,0.4l0.5-0.6l0,0l-0.5-0.4L8,111.1L7.7,112l-0.3-0.2l-0.2,0.8l-0.4-0.1
			l0.2-0.8l-0.4,0.1l0.1-0.6l-0.4,0.2L6.2,110.8 M4,110.8l0.2-0.6 M100.2,110.8l0.5-0.4l0.4,0.6l0.4-0.1l0.2,0.9l0.4-0.1l-0.2-0.8
			l0.5,0.2v-0.4l0.4,0.2h0.1 M50.5,110.9l-0.7-1 M104.7,111.1l0.3,0.2l0,0l0.2,0.1v-0.2l0.1,0.2l-0.1-0.2l-0.2-0.3l-0.2-0.5
			 M58.8,110.1l-0.6,0.7v-0.7 M101.1,111L101.1,111l-0.3,0.4l-0.6-0.5l0.2,0.6H100l-0.4-0.5l-0.5,0.3l-0.6-0.6l-0.2,0.4h-0.5l-0.1-1
			 M58.2,111l-0.2,0.7l-0.7,0.4l0.2-0.9L58.2,111L58.2,111 M97.2,111.1L97.2,111.1l0.6-0.2l0.3,0.8l0.4-0.3l-0.2-0.5 M102.9,111.1
			v0.3v0.1h0.1v-0.2L102.9,111.1v-0.3 M6,111.1l0.1,0.3H6v-0.2V111.1l0.1-0.3 M3.9,111.1l-0.2,0.1l0,0l0.1-0.2 M51.7,111.1l0.2,0.9
			l0.8,0.1l-0.1-1.1L51.7,111.1 M105,111.1L105,111.1 M9.5,110.9l-0.4,0.5l0.3,0.2l0.6-0.3l0,0L9.5,110.9 M103,111.3l-0.3-0.1
			l0.2,0.7l-0.4-0.2l0.2,0.7h-0.4l-0.2-0.8l0.4,0.1l-0.1-0.6l0.3,0.2l0.1-0.4 M105.1,111.2v0.3l-0.3-0.1l0.2,0.7l-0.3,0.1l-0.2-0.7
			l-0.3,0.2l-0.2-0.5l-0.3,0.2l-0.2-0.5l-0.2,0.3l-0.3-0.7 M50.7,111.2l0.5,0.5L50.7,111.2L50.7,111.2 M58.4,111.3L58.4,111.3
			L58.4,111.3L58.4,111.3 M3.8,111.3l-0.1,0.3l0.1-0.1l-0.1,0.3l0.2-0.3l-0.2,0.7l0.3-0.1l-0.2,0.7H3.5l0.2-0.6l-0.2,0.1l0.1-0.4
			l0.1-0.4 M6.4,111.2l-0.1,0.7L6,112l0.1-0.7L6.4,111.2 M6,111.2l-0.1,0.4l-0.1-0.2v-0.1 M105.4,111.4l0.1,0.3l-0.1-0.1V111.4
			 M10,111.2l-0.1,0.6l-0.5,0.1v-0.4l-0.5,0.3H8.7l0.5-0.4H8.8l0.1-0.6 M8.8,111.4l-0.1,0.1l-0.2,0.3v0.1h0.1l0.4-0.2l0.1,0.3
			l0.4-0.2l-0.1,0.6h0.4l0.2-0.7l0.4,0.2l0.3-0.6l0.2-0.5 M100.4,111.4L100.4,111.4l0.3,0.4h-0.2l-0.4-0.4l-0.2,0.1l-0.6-0.3
			l0.1,0.7l-0.4,0.2l-0.3-0.6l0.6-0.2 M100.5,111.5l0.3,0.2l0.1-0.3v-0.1 M103.2,111.3l0.1,0.2l0.1-0.2l0.2,0.4l0.2-0.3l0.2,0.4
			l0.3-0.2l0.3,0.7l0.3-0.2l0.2,0.7l0.4-0.1l-0.3-0.7l0.4,0.1l-0.3-0.7l0.2,0.1v-0.2 M96.5,111.6l0.1,0.4l-0.4,0.2l-0.1-0.6H96.5
			l0.3-0.3l0.4,0.6l0.6-0.1l-0.3-0.7 M51.3,111.7l-0.2-0.8 M51.3,111.7l0.7,0.4l0.2,0.7l-0.4-0.3L51.3,111.7 M57.5,112.5v0.3
			l-0.5,0.1l0,0l-0.7,0.3l0.1-0.9l0.8-0.1l-0.3,0.7L57.5,112.5l0.5-0.8l0.3-0.4 M95.4,110.8l0.4,1l0.1,0.1l-0.1-0.2l0,0 M8.7,111.5
			l-0.2,0.2l-0.1-0.3L8,112.2L7.8,112l-0.2,0.8l-0.3-0.2l-0.2,0.5L6.7,113l0.2-0.5H6.4l0.2-0.7l0,0 M98.1,111.7h-0.5l0.3,0.4h0.3
			L98.1,111.7L98.1,111.7 M3.6,112.9L3.6,112.9l-0.1,0.4l0.2,0.1l0.2-0.4h0.3l0.3-0.7l0.3,0.2l0.3-0.7l0.3,0.2l0.2-0.4l0.2,0.2
			l0.2-0.4 M13.8,110.7l-0.4,1v-0.1v0.1l0,0 M3.7,111.9l0.1-0.2 M100.7,111.7L100.7,111.7h-0.2h-0.2l-0.5-0.3v0.4h-0.5l0.2,0.7
			l0.4-0.1l-0.1-0.6l0.4,0.2l0.1-0.3l0.4,0.2h0.1v-0.1 M13.5,111.7l-0.2,0.5l-0.2-0.2l-0.1,0.5l-0.3-0.2l0.1-0.5l0.1-0.3 M96,111.7
			l0.1,0.4l0.2-0.1l0.1,0.5l0.3-0.2v-0.5h0.6l0,0l0.5,0.4l0.3-0.1l0.2,0.4h-0.2l0,0l-0.3-0.3l-0.2,0.2l-0.4-0.1l0.1-0.4 M6.2,111.4
			l-0.1,0.7l0.1-0.1L6,112.6l0.3-0.1l-0.2,0.4h0.3v-0.5L6,112.5l0.2-0.6l0.4-0.2 M8.5,111.8l0.2-0.1 M103,111.4l0.1,0.6l-0.2-0.1
			l0.1,0.6l-0.3-0.1l0.2,0.5h-0.5l-0.2-0.5l-0.4,0.1l-0.2-0.8l-0.3,0.2L101,111 M8.4,111.9L8.4,111.9L8.4,111.9L8.4,111.9v-0.2
			 M105.4,111.7l0.1,0.2l0.1,0.4l-0.2-0.1l0.2,0.6h-0.3l0.2,0.5l0,0l-0.4,0.1L105,113l-0.3,0.2l-0.2-0.7l-0.3,0.2l-0.3-0.7l-0.2,0.3
			l-0.2-0.5l-0.1,0.2l-0.2-0.4 M3.5,112.4L3.3,113l-0.1,0.3h0.2l-0.1,0.1l0.1,0.1l0.2-0.1l0,0l0.4,0.1l0.1-0.5l0.4,0.2l0.2-0.7
			l0.3,0.3l0.2-0.8l0.2,0.2l0.2-0.4l0.1,0.2l0.2-0.4 M100.7,112L100.7,112v-0.2 M105.6,112.4L105.6,112.4l-0.1-0.4v-0.4
			 M100.8,111.5l0.3,0.8l-0.1,0.2l-0.2-0.3V112l0,0 M103.2,112L103.2,112l0.1,0.2l-0.2-0.8 M5.8,112.6L5.8,112.6l-0.1,0.3l0.2-0.1
			v0.2l0.1,0.1l0.3-0.3h0.5l-0.1,0.5l0.4,0.1l0.2-0.5l0.3,0.2l0.2-0.6l0.2,0.2l0.2-0.8l0.1,0.2l0.1-0.2v0.1l0.1-0.1 M101.4,111.9
			l0.2,0.8l-0.2,0.3l-0.2-0.8L101.4,111.9 M11,111.6l-0.4,0.6l-0.3-0.3l-0.2,0.8l-0.4-0.2l-0.2,0.7l-0.4-0.1l0.2-0.6l-0.4,0.1
			l0.1-0.5l-0.4,0.2L8.5,112 M100.6,112l-0.1,0.3l-0.3-0.2l0.1,0.5l-0.4-0.1l0.2,0.6l-0.4,0.1l-0.2-0.6l-0.3,0.1L99,112l-0.3,0.3
			l-0.4-0.6 M51.7,112.5v0.3l-1-0.5l-0.1,1.7l-1.1-1.1l-0.2,1.2l-1-0.2l0.4,2.1l-1.7,0.1l-0.3-2.5l1.6,0.2l-0.1-1.9l1.3,0.8v-1.4
			l1.2,0.8v-1 M59.7,114l-0.7,1.4l-0.5-1.5l-0.9,0.8l-0.2-2l1-0.5l0.1,1.7l1.1-1.1L59.7,114l1-0.2l-0.4,2.1L62,116l0.3-2.5l-1.6,0.2
			l0.1-1.8l-1.3,0.8v-1.4l-1.1,0.8v-1 M5.7,111.9l-0.2,0.4l-0.1-0.1v-0.1 M96.9,112.7v0.4l-0.3,0.1v-0.4L96.9,112.7L96.9,112.7
			l0.1-0.5l-0.4,0.1v0.5l-0.3,0.2v-0.5l-0.2,0.2l-0.1-0.5 M9,112.6L9,112.6l-0.2,0.6l0.4-0.1L9,113.6h0.4l0.1-0.4l0.3,0.2l0.2-0.6
			l0.3,0.2l0.3-0.7l0.1,0.2l0.2-0.3v-0.1 M11,112.2l-0.1,0.3h0.2l-0.2,0.2l0.2,0.1l0.2-0.3l0.2,0.2l0.3-0.3l0.1,0.4l0.4-0.1v0.4
			l0.3,0.1v-0.4v-0.5l-0.4-0.1l0.1,0.4l0.4,0.1l0.2,0.2l0.1-0.5l0.1,0.1v-0.1l0.1-0.2 M100.8,112l-0.1,0.4l-0.2-0.1v0.5l-0.3-0.2
			l0.2,0.6l-0.4-0.1l0.2,0.4h-0.4l-0.1-0.4l-0.4,0.2l-0.2-0.7l-0.3,0.2l-0.2-0.7l-0.1,0.2l-0.2-0.2V112 M52.1,112.7L52.1,112.7
			l0.7,0.3l0.8,0.2v-1l-1-0.1l0.1,0.9l0.3,0.5l-0.2-0.1l-0.5-0.3l-0.3-0.4l-0.5-0.1l-0.2,2l-0.9-0.8l-0.5,1.5l-0.7-1.4 M57,112.8
			l-0.3,0.4l-0.5,0.2l-0.1,0.1l0.2-0.5l-0.8,0.2l0.1-1h-1v1l-0.9-0.1l0.1,0.6l-0.6-0.2v0.7l-0.2,0.2v-1 M8.9,112.6l-0.3,0.2l0.1-0.6
			l-0.2,0.1L8.3,112 M8.4,112.4L8.4,112.4l-0.1-0.2 M103.5,111.9l0.2,0.5l0.1-0.2l0.3,0.7l0.2-0.3l0.2,0.7l0.3-0.2l0.2,0.4l0.3-0.2
			l0.1,0.3h0.3v-0.4 M100.8,112.1l-0.1,0.4v-0.2 M98.3,112.3l0.1,0.3h-0.1h-0.1 M105.7,112.6l0.2,0.5h0.1L105.7,112.6 M100.6,112.9
			L100.6,112.9l0.2,0.2v-0.3v-0.1 M10.9,112.7l-0.1,0.2h0.1 M10.7,112.5l-0.1,0.3l-0.1,0.3l-0.2-0.2l-0.1,0.7l-0.3-0.2l-0.1,0.4
			l-0.4-0.2L9.3,114H8.9v-0.4l-0.4,0.1l0.1-0.4l-0.3,0.1l0.1-0.6L8.3,113v-0.5 M103.2,112.6l0.1,0.2v0.1l-0.2-0.1l0.1,0.2v0.2
			l-0.4-0.3l0,0l0.3-0.1l-0.2-0.4L103.2,112.6l-0.1-0.5 M97.8,112.6l-0.2,0.2l-0.3-0.3l-0.1,0.4l-0.4-0.1 M101.8,112.6l0.2,0.6
			l-0.3,0.2l-0.2-0.6L101.8,112.6 M98.2,112.6l0.1,0.2h-0.1V112.6 M98.4,112.8L98.4,112.8l0.1-0.2 M5.8,113.1l-0.2,0.1L5.8,113.1
			L5.6,113l0.1-0.3l0.2-0.6 M10.8,112.8l0.1,0.3l0.2-0.1l0.1,0.3l0.3-0.3v0.4l0.4-0.2v0.3h0.4v-0.3H12v-0.4l-0.3,0.2l-0.1-0.2
			l-0.2,0.2l-0.2-0.2L10.8,112.8l-0.1-0.1l-0.1,0.1 M98.3,112.8l-0.2,0.3l-0.2-0.1l-0.1,0.3l-0.3-0.3v0.4l-0.4-0.1l0.1,0.3h-0.4
			v-0.3h0.4l0.1-0.4l0.3,0.1l0.1-0.2L98,113l0.2-0.2H98.3L98.3,112.8L98.3,112.8l-0.3-0.3 M13.1,112.6v0.2v-0.3 M103.4,112.9
			l0.1,0.2h0.1l-0.2-0.4l-0.1-0.6 M5.5,112.3L5.2,113L5,112.7l-0.3,0.7l-0.2-0.2l-0.2,0.4L4,113.4l-0.1,0.4H3.5v-0.4 M99.8,113.7
			L99.8,113.7l-0.4,0.2l-0.1-0.4l-0.3,0.2l-0.2-0.7l-0.2,0.2l-0.2-0.4 M105.9,113l-0.2-0.1l0.1,0.4l0.2,0.1L105.9,113 M8.3,113
			l-0.1,0.6l0.2-0.2l-0.1,0.3h0.2l0,0 M3.3,113l0.2-0.1 M8.1,112.4l-0.2,0.8l-0.1-0.1V113 M102.4,113l0.1,0.5l-0.4,0.2l-0.1-0.5
			L102.4,113 M100.8,112.6v0.6V113 M105.3,113.8L105.3,113.8L105,114l-0.1-0.4l-0.3,0.2l-0.2-0.4l-0.2,0.2L104,113l-0.1,0.1
			l-0.3-0.7"
        />
        <path
          class="st0"
          d="M5.5,112.4l-0.3,0.7l-0.1-0.2l-0.3,0.6l-0.2-0.2l-0.2,0.5l-0.3-0.3L4,113.9l-0.3-0.2l-0.1,0.4H3.4l0.1-0.4
			H3.3v-0.2v-0.1 M10.9,113.1l0.1,0.3l0.2-0.1v0.2l0.3-0.2v0.3l0.4-0.1v0.3h0.4v-0.3l0.4,0.1v-0.3l0.3,0.1V113l0.2,0.1v-0.3
			 M98,113.1l-0.1,0.4l-0.2-0.2v0.2l-0.2-0.2v0.3l-0.3-0.1v0.2h-0.4v-0.2l-0.3,0.1v-0.3l-0.3,0.2V113l-0.1,0.1l-0.1-0.4
			 M103.4,113.1l0.2,0.1v0.1l0.1,0.1l-0.1-0.2 M101,112.4l0.2,0.8l0.1-0.2l0.2,0.6l0.3-0.3l0.1,0.5l0.3-0.2l0.1,0.8l0.4-0.1
			l-0.1-0.8h0.4l-0.1-0.5 M100.8,113.2l0.1,0.4l-0.2-0.2l-0.1-0.5 M8.3,113.7L8.3,113.7l-0.1,0.2h0.1l0.2-0.2v0.4l0.3-0.1v0.6
			l0.4,0.1V114l0.4,0.2l0.1-0.4L10,114l0.1-0.4l0.1,0.2l0.2-0.6 M96.1,113.1v0.4l0.1-0.1v0.3l0.3-0.2v0.3l0.3-0.1v0.3h0.3v-0.3
			l0.4,0.2v-0.3l0,0h0.2v-0.1l0.2,0.2v-0.2 M99.5,113.7l0.1,0.4l-0.4,0.2l-0.1-0.4l-0.3,0.2l-0.1-0.4l-0.2,0.2l-0.2-0.6 M41.6,113.3
			l0.1,3l-1.7,0.1l-0.1-3.1H41.6 M6.2,112.9v0.5l-0.3,0.1v-0.4l-0.3,0.3v-0.3l-0.1,0.1l0.1-0.3 M52.4,113.2l-0.2,1.6l-0.8-0.1
			l-0.6,2.9l-0.8-2.2l-1.3,0.6l0.4,2.7l-1.7,0.4l-0.4-2.9h-1.8l0.4,3.1l-1.8,0.2l-0.3-3.2l-1.7,0.1l0.4,3.3l-1.7,0.2l-0.4-3.4
			l-1.7,0.1l-0.1-3.3h-1.6l0.1,3.3l1.6-0.1l0.4,3.5l1.7-0.2l0.6,3.7l1.7-0.2l-0.6-3.7l1.7-0.2l0.5,3.6l1.6-0.3l-0.5-3.5l1.8-0.2
			l0.3,3.4l1.5-0.4v-3.4l1.7-1l0,0l1-0.1l0.3-2.7l0.6-0.3v-0.1 M56.7,113.3l0.1,1.6l0.8-0.1l0.5,2.9l0.9-2.2l1.3,0.6l-0.4,2.7
			l1.7,0.3l0.4-2.9h1.7l-0.4,3.1l1.8,0.2l-0.6,3.5l1.6,0.3l0.6-3.7l1.7,0.2l-0.6,3.7l1.8,0.2l0.6-3.7l0.4-3.5l-1.7-0.1l-0.4,3.4
			l1.7,0.2l1.6,0.1l0.3-3.5h1.4v-3.3h1v3.3l0.4-0.1v-3.2 M105.8,113.2l0.1,0.2l-0.3-0.1L105.8,113.2 M100.4,113.2l0.1,0.4h0.2
			l-0.1-0.3L100.4,113.2 M103.6,113.3v0.3l-0.1-0.2v0.4l-0.2-0.2l0.1,0.8l-0.4-0.1l0.1,0.8h-0.4l-0.1-0.8h0.4l-0.1-0.8l0.3,0.1v-0.4
			l0.2,0.2v-0.2 M53.7,113.8l0.8,0.1v-0.6l0.9-0.1v0.6l0.5-0.2l0.1-0.1 M106,113.4v-0.2 M3.3,113.4l-0.2,0.1l0.1-0.2 M106,113.6
			v-0.2 M12.9,113.3L12.9,113.3l-0.1,0.3l-0.2-0.1l-0.1,0.2l-0.3-0.1v0.3h-0.4v-0.3l-0.3,0.1v-0.3l0,0l-0.2,0.1v-0.2l-0.1,0.2v-0.2
			 M6.2,113.5l-0.1,0.8h0.3l0.1-0.8H6.2 M7.8,113l-0.2,0.5l-0.2-0.2l-0.2,0.5l-0.3-0.2l-0.1,0.8l-0.4-0.1L6.3,115H6l0.1-0.8
			l-0.3,0.1l0.1-0.8l-0.2,0.2v-0.4l-0.1,0.1v-0.3 M106,113.5h-0.2v0.1l0.2,0.1v0.2v-0.2 M3.3,113.6l-0.2,0.1v-0.2 M12.9,113.5
			l0.1,0.1v-0.4 M100.8,113.7L100.8,113.7l0.2,0.1l-0.1-0.3 M100.9,113.8L100.9,113.8l-0.3-0.1l-0.4-0.1v0.4l0.4,0.1v-0.4
			 M5.3,113.1L5,113.8l-0.1-0.1v-0.1 M103.9,113.1l0.3,0.7l0.1-0.1v-0.1 M97.6,113.5l0.2,0.2v-0.1L97.6,113.5 M3.1,113.7L3.1,113.7
			L3.1,113.7L3.1,113.7 M5.5,113.6v0.4l0.1-0.2l-0.1,0.8l0.2-0.2l-0.1,0.7L6,115l-0.1,0.5h0.4l0.1-0.5l0.4,0.1l0.1-0.8l0.3,0.2
			l0.2-0.8l0.2,0.2l0.2-0.5l0.1,0.2l0.2-0.6 M103.5,113.8l0.1,0.2v0.7l-0.2-0.2v0.7l-0.3-0.1l0.1,0.5h-0.4l-0.1-0.5l-0.4,0.1
			l-0.1-0.8l-0.3,0.2l-0.2-0.8l-0.2,0.2l-0.2-0.4l-0.1,0.2l-0.2-0.5 M97.8,113.7L97.8,113.7L97.8,113.7 M3.1,113.7v0.2v0.1V113.7
			 M105.4,113.7l0.1,0.4h0.3l-0.1-0.4l0.3,0.1v-0.2 M5.1,113.7L4.9,114l0.1,0.2L5.1,113.7 M11.6,113.6l-0.3,0.2v-0.2l-0.1,0.2v-0.3
			 M104.3,113.8l0.2,0.4v-0.1L104.3,113.8 M3.1,113.9l0.2-0.1v-0.1 M8.3,113.8l-0.1,0.1l0.1-0.3 M8.3,113.9v0.3l0.3-0.2v0.6l0.3-0.1
			v0.6h0.4v-0.6l0.4,0.2v-0.6l0.3,0.2V114l0.2,0.2l0.1-0.4 M13,113.5l-0.1,0.3l-0.1-0.2v0.2l-0.3-0.1v0.3l-0.3-0.1v0.3h-0.4V114
			l-0.3,0.1v-0.3l-0.2,0.2v-0.2h-0.1l-0.1,0.1v0.2l0.2-0.3 M98,113.8L98,113.8h-0.2v0.1v0.1l-0.2-0.2v0.3l-0.3-0.1v0.4H97v-0.3
			l-0.3,0.1v-0.3l-0.3,0.2v-0.3l-0.1,0.1v-0.3 M54.7,114l0.9-0.1 M106.3,113.9L106.3,113.9v0.3l0.1,0.1L106.3,113.9 M106.2,114
			l-0.2-0.1l0.1,0.5l-0.3-0.1l0.1,0.5h-0.3l-0.1-0.4l-0.3,0.2l-0.1-0.4l-0.2,0.2l-0.1-0.3l-0.2,0.2l0.1,0.3l0.2-0.2l0.1,0.4l0.3-0.2
			l0.1,0.5l0.3-0.2l0.1,0.4h0.3l-0.1-0.4h0.3v-0.4l0.1,0.1v-0.2 M98.2,113.9L98.2,113.9L98.2,113.9L98.2,113.9 M8.3,114L8.3,114v0.2
			l0,0l0.1-0.3L8.3,114L8.3,114 M101.2,113.8v0.2l0,0v0.1L101,114v-0.1l0.2,0.1 M13.2,113.9v0.3L13,114v-0.1 M3.5,113.8l-0.1,0.4
			l0.3-0.1l-0.1,0.5h0.3l0.2-0.4l0.3,0.1l0.2-0.4l0.2,0.2l0.2,0.2l0.1-0.3l-0.2-0.2l-0.1,0.3l-0.1,0.5l-0.3-0.3l-0.1,0.5L4,114.6
			L3.9,115H3.2l0.1-0.4L3,114.7l0.1-0.5L3,114.3V114 M100.1,114v0.6l-0.4,0.1V114H100.1 M97.8,114L97.8,114l0.2,0.2v-0.1V114
			 M103.5,113.9L103.5,113.9l0.1-0.3 M101,114.1v0.2v0.1V114.1L101,114.1 M12.7,114v0.3l-0.2-0.2v0.3l-0.3-0.1v0.2l-0.3,0.1v-0.3
			l-0.3,0.2v-0.3l-0.2,0.2l-0.1-0.4l-0.2,0.3v-0.2v0.1V114l0,0 M97.2,114.4v0.2h-0.4v-0.2l-0.3,0.1v-0.3l-0.2,0.2V114l-0.1,0.1v-0.3
			 M7.8,113.8l-0.2,0.6l-0.1-0.2v-0.1 M101.4,113.8l0.2,0.6l0.1-0.2v-0.1 M98.8,113.9l0.1,0.4l0.2-0.2l0.1,0.3l0.2-0.2l0.1,0.6
			l0.3-0.2v0.6l0.4-0.1v-0.6l0.4,0.1V114l0.2,0.2V114 M10.2,114.2l-0.1,0.4 M99.2,114.3L99.2,114.3l-0.2,0.3l-0.1-0.4 M56.2,113.5
			l0.1,1l-0.3-0.3l-0.1-0.7 M97.3,114.3l0.3,0.2v-0.4l0.2,0.2l0.1-0.3 M10.2,114.6l-0.1-0.2l-0.1,0.5l-0.2-0.2v0.6l-0.4-0.1v0.3H8.9
			v-0.4l-0.3,0.1v-0.6l-0.2,0.1v-0.5l-0.2,0.2v0.2v-0.4v-0.1 M98.1,114v0.3v0.1v-0.2 M98.1,114.3l-0.1,0.2L98.1,114.3 M106.3,114.1
			v0.3v-0.1 M4.7,114.3L4.7,114.3l-0.1,0.5l-0.2-0.2L4.3,115L4,114.8l-0.2,0.4L3.6,115l-0.2,0.3l-0.2,0.1V115l-0.3,0.1l0.1-0.5
			l-0.2,0.1l0.1-0.5 M97.7,114.3L97.7,114.3l-0.1,0.3l-0.2-0.1v0.2l-0.3-0.1l-0.1,0.1h-0.3v-0.2l-0.3,0.1v-0.2l-0.2,0.1v-0.3
			l-0.1,0.1V114 M11.1,114.2l0.1,0.2L11.1,114.2l0.1,0.4l0.2-0.3v0.3l0.2-0.2v0.2l0.3-0.1l0.1,0.1h0.3v-0.2l0.3,0.1v-0.2l0.2,0.1
			v-0.3l0.1,0.2V114 M99.1,114.5l0.1,0.6l0.3-0.2v0.6l0.3-0.1v0.3h0.4v-0.4l0.4,0.1v-0.6l0.3,0.1v-0.5l0.2,0.2v-0.1 M104.2,114.2
			l0.1,0.4l0.1-0.2l0.1,0.5l0.2-0.2l0.2,0.4l0.3-0.2l0.2,0.3l0.3-0.1l0.1,0.3l0.2,0.1v-0.4l0.3,0.1V115l-0.1-0.3l0.2,0.1l-0.1-0.3
			l0.2,0.5l-0.1-0.2 M8.3,114.8L8.3,114.8l-0.2,0.4v-0.6v-0.3 M4.9,114.2l-0.1,0.3v-0.1 M97.8,114.4l0.1,0.2l0.1-0.2 M12.9,114.4
			v0.3l-0.1-0.1L12.9,114.4 M8.3,114.8v0.5l0.3-0.2v0.4l0.3-0.1v0.2h0.4v-0.2l0.3,0.2v-0.4l0.2,0.2l0.1-0.6L10,115l0.1-0.6
			 M98,114.6v0.3V114.6 M5.5,114.6l-0.1,0.7v-0.1l-0.1,0.4l0.2-0.1l-0.1,0.1l0.1,0.1l0.2-0.2H5.5l0.1-0.4l-0.2,0.2L5.5,114.6
			L5.5,114.6V114 M97.7,114.6L97.7,114.6v0.2l-0.2-0.1l0,0l-0.1,0.2l-0.3-0.2v0.2h-0.3l0.1-0.2l-0.3,0.1v-0.2l-0.2,0.2v-0.3
			l-0.1,0.2v-0.3 M101.1,114.4l-0.1,0.3L101.1,114.4 M12,114.7L12,114.7l-0.3,0.2l-0.1-0.2l0,0l-0.2,0.1v-0.2l-0.2,0.2v-0.3l0,0
			l-0.2-0.3 M12.8,114.6v0.2l-0.2-0.2v0.2l-0.3-0.1v0.2H12v-0.2 M103.7,115.2v0.2v0.2l-0.2-0.1l0.1,0.2l-0.1,0.1l-0.3-0.2l0,0h0.3
			l-0.1-0.4L103.7,115.2l-0.1-0.6l0.1,0.2l-0.1-0.7 M2.9,114.8l-0.1,0.4l-0.1,0.1L2.9,114.8L2.9,114.8 M7.5,114.1l-0.2,0.7l-0.2-0.2
			L7,115.4l-0.3-0.2l-0.1,0.4l-0.3-0.1l-0.1,0.3H5.8v-0.4 M56.3,114.5l0.2,2.5l0.6,0.5l-0.3-2.7L56.3,114.5 M101.7,114.1l0.2,0.8
			l0.2-0.3l0.2,0.7l0.3-0.2l0.1,0.4l0.3-0.1v0.4h0.4v-0.4 M101,115v0.2v0.1V115l-0.2-0.3l0,0 M97.7,114.8L97.7,114.8l0.1,0.2
			L97.7,114.8l0.1,0.2v-0.3L97.7,114.8l-0.1-0.2 M11.2,114.7l0.1,0.4l0,0l0.1-0.2v0.1h0.1l0.1,0.1l0.2-0.2l0.1,0.1l0.2-0.1l0.1,0.2
			h0.3l-0.1-0.2l0.3,0.1v-0.2l0.2,0.2v-0.1l0.1,0.2v-0.3 M97.6,114.8l0.2,0.2l0,0l-0.1,0.1L97.6,114.8l-0.2,0.3l-0.3-0.2l-0.1,0.2
			h-0.3v-0.2l-0.2,0.1v-0.2l-0.2,0.2v-0.1l-0.1,0.1v-0.2 M104.5,114.5l0.1,0.4l0.1-0.1l0.2,0.5l0.2-0.3l0.2,0.4l0.2-0.2v-0.1
			 M3.4,115.4l0.2,0.2l0.2-0.4l0.2,0.2l0.2-0.4l0.2,0.3l0.1-0.5l0.1,0.2l0.1-0.4 M105.4,115.2l0.2,0.4l0.3,0.2l0.1-0.3l0.2,0.1v-0.4
			l0.2,0.1V115l0.2,0.4l-0.1-0.1l0.1,0.5l-0.2-0.1l-0.1,0.4l-0.3-0.2l0,0 M96.6,115L96.6,115l-0.2,0.2L96.6,115l-0.2,0.2V115
			 M11.3,115.1L11.3,115.1L11.3,115.1l0.3-0.3 M12.6,115L12.6,115l0.2,0.1v-0.2l0.1,0.2v-0.2 M98,115.1v0.2V115.1L98,115.1L98,115.1
			l-0.1-0.2 M101.6,114.3l0.2,0.7l0.1-0.1l0.2,0.6l0.2-0.2l0.1,0.5l0.3-0.2l0.1,0.4l0.3-0.1v0.5h0.3V116l0.2,0.1v-0.2l0.2,0.2v-0.2
			v-0.2 M7.6,114.3L7.4,115l-0.1-0.2l-0.2,0.7L7,115.4l-0.1,0.5l-0.3-0.2L6.5,116l-0.3-0.1v0.5H5.9V116H5.7v-0.2L5.4,116v-0.2v-0.2
			v0.1l0.1-0.4 M11.5,115l-0.1,0.3v-0.1v0.2v-0.3v-0.2 M10,115.5L10,115.5v0.4l-0.2-0.2v0.2l-0.5-0.1v0.2H9l-0.1-0.2l-0.3,0.1
			l-0.1-0.2l0,0H8.3v-0.3l-0.2,0.3v-0.5l0,0v-0.4 M11.3,115.3L11.3,115.3L11.3,115.3h0.1l0.1-0.3v0.2l0.2-0.2l0.1,0.2l0.2-0.1v0.2
			h0.3v-0.2l0.3,0.1V115 M97.7,115l0.1,0.3v0.1L97.7,115v0.2l-0.2-0.2v0.2l-0.3-0.2v0.2h-0.4V115l-0.3,0.1V115 M100.7,114.8v0.6
			l-0.2-0.1v0.4l-0.4-0.1v0.2h-0.3v-0.2l-0.3,0.2v-0.4l-0.2,0.2l-0.1-0.5l-0.1,0.2l-0.1-0.6 M11.3,115.4L11.3,115.4L11.3,115.4
			L11.3,115.4l0.1-0.2l0.1,0.2l0.2-0.2l0.1,0.1H12l0.1,0.2l0.2,0.1v-0.3l0.2,0.1v-0.2l0.2,0.1v-0.2 M97.5,115.2L97.5,115.2l-0.1,0.2
			l-0.2-0.2l-0.1,0.2l-0.2-0.1v0.2l-0.2,0.1v-0.3l-0.3,0.1v-0.2l-0.2,0.1v-0.2v0.1l-0.1,0.2l0.1-0.1v0.3l0.2-0.1v0.3l0.2-0.1
			l0.1,0.2l0.2-0.2l0.1-0.2l0.2,0.2l0.1-0.2l0.1,0.2v-0.1v-0.2v0.2l0.1-0.1L97.5,115.2L97.5,115.2L97.5,115.2L97.5,115.2 M2.9,115.2
			v0.5l-0.2,0.1l0.1-0.5L2.9,115.2 M12.9,115.2l0.1,0.2L12.9,115.2L12.9,115.2L12.9,115.2L12.9,115.2 M4.7,115l-0.1,0.4l-0.1-0.1
			l-0.2,0.4l-0.2-0.2l-0.2,0.4l-0.3-0.2L3.3,116l-0.1-0.4L3,115.7l0,0.3l0.3-0.2l0.2,0.3l0.2-0.3L4,116l0.2-0.4l0.1,0.1l0.2-0.4
			 M104.6,114.9l0.1,0.5l0.1-0.1l0.2,0.4l0.2-0.2l0.2,0.4l0.2-0.2l0.1-0.2 M11.9,115.4L11.9,115.4l-0.2,0.3l-0.1-0.2l-0.1,0.2
			l-0.1-0.2v0.2l-0.1-0.1 M103.7,115.4L103.7,115.4l-0.1-0.7 M11.8,115.5l0.1,0.2l0.2,0.2l0.1-0.2l0.2,0.1v-0.3l0.2,0.2v-0.2
			 M97.6,115.6L97.6,115.6L97.6,115.6l-0.1-0.3 M97.7,115.6L97.7,115.6 M99.2,115.5L99.2,115.5v0.4l0.2-0.2v0.2l0.3-0.1l-0.1,0.3
			h0.3v-0.3l0.3,0.1l0.1-0.3l0.2,0.1v-0.3l0.2,0.3v-0.5 M105.7,115.8l-0.2,0.3l-0.3-0.3L105,116l-0.2-0.4l-0.1,0.2l-0.1-0.2
			l-0.1-0.2 M11.5,115.6L11.5,115.6l0.2,0.2l0.1-0.2l0.1-0.2 M97.2,115.6L97.2,115.6l-0.2,0.2l-0.2-0.2l-0.1-0.2 M7.4,115l-0.2,0.7
			v-0.1v-0.1 M11.4,115.7l0.1,0.2L11.4,115.7l0.2,0.2l0.2,0.2l0.1-0.3l0.2,0.2l0.1-0.3l0.3,0.1l0.1-0.3l0.2,0.1v-0.2l0.1,0.2v-0.3
			 M101.7,115l0.2,0.7l0.1-0.1v-0.1 M97.3,115.8L97.3,115.8l-0.2,0.3l-0.1-0.3l-0.3,0.1l-0.1-0.3l-0.2,0.1v-0.2l-0.1,0.1v-0.3
			 M99.3,115.6L99.3,115.6l-0.2-0.4 M12.9,115.7l-0.1,0.1v0.2L12.9,115.7L12.9,115.7 M97.2,115.9l0.1,0.2l-0.2,0.2l0,0l-0.2-0.2
			l-0.3,0.2l-0.1-0.3l-0.2,0.1l-0.1-0.3h-0.1l-0.1-0.1v-0.1 M10,115.1l-0.1,0.6l-0.1-0.1 M97.6,115.7l-0.1,0.2L97.6,115.7
			L97.6,115.7 M8.3,115.7L8.3,115.7l-0.2,0.2v-0.2v0.1v-0.6 M4.3,115.8v0.2H4.1H3.9l-0.2,0.3l-0.2-0.2l-0.3,0.2L3,116.1l-0.2,0.1
			l-0.1-0.4H2.6l0.1-0.4 M100.6,115.6l0.2,0.3l0.1-0.1l0,0l-0.1-0.2 M101.9,115.8l0.1,0.4v-0.1L101.9,115.8 M103.6,115.7
			L103.6,115.7l0.2,0.1l-0.1-0.3 M5.3,115.7l-0.1,0.2L5.3,115.7h0.2 M105.1,116v0.2l-0.2-0.3L105.1,116L105.1,116l0.3,0.3l0.2-0.2
			l0.3,0.2l0.2-0.3l0.2,0.1l0.1-0.4h0.1l-0.1-0.4 M12.8,115.8L12.8,115.8L12.8,115.8 M100.8,115.9L100.8,115.9l0.1-0.2L100.8,115.9
			l0.1-0.6 M97.5,115.8L97.5,115.8l-0.2-0.1 M8.5,115.6l-0.2,0.2H8.2v-0.1 M11.4,115.9l0.1,0.1L11.4,115.9l0.2-0.2 M100.8,115.9
			L100.8,115.9L100.8,115.9L100.8,115.9L100.8,115.9 M8,115.9v0.2V115.9v0.2V115.9L8,115.9L8,115.9L8,115.9v-0.2 M9.4,115.8
			L9.4,115.8l0.2,0.2v-0.2l0.1,0.2v-0.4 M99.3,115.8L99.3,115.8l-0.2,0.2v-0.2L99,116v-0.4 M4,115.8l-0.2,0.3v-0.2 M5.1,115.8
			L5.1,115.8v0.2V115.8l0.1,0.3l0.2-0.2v-0.1 M103.6,115.7L103.6,115.7L103.6,115.7 M103.7,115.8v0.2V115.8l-0.1,0.2l-0.2-0.1
			l0.1,0.4l-0.3-0.1l0.1,0.5h-0.4l-0.1-0.5l-0.3,0.1l-0.1-0.4l-0.3,0.2l-0.1-0.3l-0.2,0.2l0.1,0.3l0.2-0.2l0,0 M5.6,116.1v0.4
			l0.3-0.1l-0.1,0.5h0.3v-0.5l0.3,0.1V116l0.3,0.2l0.1-0.3l0.2,0.2l0.1-0.4 M9.5,116v0.2L9.3,116H9.5l-0.1,0.3H9V116l-0.3,0.1
			l-0.1-0.2l-0.2,0.2v-0.2 M99.6,115.9v0.2l0.2-0.1v0.3l0.3-0.1l0.1-0.3l0.3,0.1l0.1-0.2l0.2,0.2l0.1-0.1l0.2,0.2V116l0.1,0.2V116
			v-0.2 M11.6,116l0.1,0.3l0.1-0.2l0.2,0.2l0.2-0.2l0.3,0.2l0.1-0.3l0.2,0.1l0.1-0.3 M96.3,115.8v0.2V115.8 M99.6,116.1L99.6,116.1
			l-0.1,0.4l0.2-0.1v0.3h0.3l0.1-0.3l0.3,0.1l0.1-0.3l0.2,0.2l0.1-0.2l0,0 M11.6,116.3l0.2,0.2l0.2-0.2l0.3,0.2l0.2-0.2l0.3,0.1
			l0.1-0.3h0.2V116 M96.2,116L96.2,116h0.2l0.1,0.3h-0.2L96.2,116 M97.6,115.9v0.2V115.9l-0.2,0.2l0.1,0.2l0.1-0.2 M11.8,116.5
			l0.2,0.2l0.2-0.2h0.3l0.2-0.2h0.2l0.1-0.2 M8.7,116.1L8.7,116.1l-0.2,0.2L8.4,116l-0.2,0.3l-0.1-0.2v0.2v-0.1v-0.1 M99.3,116v0.3
			l0.1-0.2l0,0 M4.1,116.2L4.1,116.2l-0.4,0.1l-0.2,0.2l-0.3-0.2L3,116.4l-0.2-0.3H2.6v-0.4 M105.2,116.2L105.2,116.2l0.3,0.1
			l0.2,0.2l0.3-0.2l0.2,0.1l0.2-0.3h0.2v-0.3 M9.9,116.1v0.2V116.1l-0.1,0.2l-0.2-0.1l-0.1-0.1 M7.2,115.8l-0.1,0.5L7,116l-0.1,0.3
			l-0.2-0.2v0.5l-0.3-0.2v0.5l-0.3-0.1v0.4H5.7v-0.4l-0.3,0.1v-0.5l-0.2,0.2v-0.4l-0.1,0.2v-0.3 M100.7,116l0.1,0.2l0.1-0.1v0.3
			v-0.2 M96.6,116.2L96.6,116.2h-0.2l0.2,0.2h-0.1l-0.2-0.2 M103.7,116.1v0.2V116.1L103.7,116.1 M100.7,116.1L100.7,116.1v0.3v-0.1
			l0,0 M2.8,116.3l0.3,0.2h0.1L3,116.3H2.8l-0.2-0.2 M9.7,116.2v0.3l-0.3-0.1v0.3H9.1v-0.3l-0.3,0.1l-0.1-0.3 M9.9,116.4L9.9,116.4
			l0.1,0.2L9.9,116.4 M99.4,116.2l-0.2,0.2v-0.3 M105.2,116.3l0.4,0.2h0.3l0.2-0.2h0.2l0.2-0.2 M7.1,116.2v0.3L7,116.4v-0.1
			 M97.2,116.3l-0.3,0.2l-0.2-0.2 M100.7,116.3l-0.1,0.3l-0.2-0.2l-0.1,0.3l-0.3-0.1v0.3l-0.2,0.1v-0.3l-0.3,0.2v-0.3l-0.2,0.2v-0.3
			v0.1v-0.1 M3.5,116.5L3.5,116.5h0.3l0.2-0.2 M12,116.6h0.3l0.2-0.2h0.1l0.2-0.2 M9.9,116.4v0.2l-0.2-0.1v0.3l-0.3-0.2v0.3
			l-0.2-0.1l-0.1-0.2l-0.2,0.1l-0.1-0.3l-0.2,0.2l-0.1-0.3l-0.1,0.2l-0.1-0.2v0.2l-0.1-0.2 M97.5,116.3l-0.2,0.2l-0.2-0.2
			 M3.2,116.5h0.3 M96.6,116.5l0.2,0.2h0.1L96.6,116.5H97l0.2,0.2l0.2-0.1 M105.9,116.5h0.2l0.2-0.2 M100.9,116.3v0.2V116.3
			 M102.3,116.1l0.1,0.4v0.1l-0.2,0.2l-0.1-0.5l-0.1,0.2v-0.4 M102.5,116.5l0.3-0.1l0.1,0.5l0.3-0.1l0.1,0.4h0.3v-0.4l0.2,0.1v-0.5
			l0.2,0.2v-0.2 M34.3,119.9l0.2,3.3l0.6,0.3l-0.4-3.5l1.1,0.1l-0.3-3.5h1.4l0.3,3.5l1.6-0.1l0.6,3.7l1.7-0.2l0.8,3.6l1.6-0.2
			l-0.7-3.6l1.5-0.4l0.6,3.6l1.5-0.3l-0.5-3.5l1.6-0.3l0.2,3.5l1.2-0.5l0.2-3.4l1.3-0.5l0.4-3.8 M58.2,117.7L58.2,117.7L58.2,117.7
			l1.8,1l-0.1,3.4l1.5,0.4l-0.2,3.5l1.4,0.4l0.4-3.5l0.5-3.5l-1.8-0.3l-0.3,3.4l1.6,0.3l1.7,0.3l-0.7,3.5l1.6,0.3l1.6,0.2l1.7,0.1
			l0.8-3.5l1.7,0.1l0.6-3.6h1.5l0.2-3.5h1l-0.2,3.5L75,120l0.1-3.4 M12.3,116.7h0.1l0.2-0.2 M7,116.5l-0.1,0.4l-0.2-0.2l-0.1,0.5
			L6.3,117l-0.1,0.4L6,117.2v0.3l-0.2,0.1v-0.4l-0.3,0.2v-0.5l-0.2,0.2v-0.5v0.1v-0.3 M9.2,116.8L9.1,117l-0.2-0.3l-0.2,0.2
			l-0.1-0.3l-0.1,0.2l-0.1-0.3v0.2l-0.1-0.2 M52.9,114.6l-0.2,2.5l0.3-0.4l0.2-2.4 M97.1,116.7h-0.3 M10,116.5v0.3l-0.1-0.1
			L10,116.5"
        />
        <path
          class="st0"
          d="M56.2,116.7l0.4,3.2l0.3,0.4l-0.4-3.2L56.2,116.7l-0.2-2.4 M100,116.8l0.2,0.2l0.1-0.3l0.2,0.2l0.1-0.4
			l0.1,0.2l0.1-0.2v0.2l0.1-0.2 M102.4,116.6l0.1,0.4l0.3-0.2l0.1,0.4l0.3-0.1v0.3l0.2,0.1v-0.4l0.3,0.2v-0.5l0.2,0.1v-0.4l0.1,0.2
			v-0.4 M100.8,116.7L100.8,116.7l-0.1,0.3l-0.1-0.2l-0.2,0.3l-0.2-0.2l-0.2,0.2l-0.2-0.3l-0.3,0.2v-0.3l-0.2,0.2v-0.3l-0.1,0.2
			l0.1-0.3 M8.3,116.7l0.1,0.3v-0.2l0.1,0.3l0.1-0.2l0.1,0.3L9,117l0.3,0.3l0.1-0.3l0.2,0.1l0.1-0.3l0.2,0.2v-0.2 M103.2,117.6
			l-0.2,0.2l-0.2-0.4l-0.2,0.2l-0.1-0.5l-0.2,0.2l-0.1-0.4l-0.1,0.1l-0.1-0.4 M6,117.5l0.2,0.2l0.1-0.4l0.2,0.2l0.1-0.5l0.2,0.2
			l0.1-0.4L7,117l0.1-0.5 M100.7,116.8v0.3V116.8 M100.7,117v0.3l-0.1-0.2l-0.1,0.3l-0.2-0.2l-0.2,0.3l-0.2-0.3l-0.3,0.2l-0.1-0.3
			l-0.2,0.1l-0.1-0.2l-0.1,0.1v-0.3 M8.4,117l0.1,0.2l0.1-0.1l0.1,0.3l0.2-0.2l0.2,0.3l0.2-0.2l0.3,0.2l0.1-0.4l0.2,0.1l0.1-0.3
			l0.1,0.3v-0.4 M103.9,116.8l0.1,0.5l-0.1-0.3l0,0 M5.3,116.7v0.5l0.1-0.2v0.5l0.2-0.2l0.1,0.4l0.2-0.2L6,118l0.2-0.3l0.2,0.2
			l0.2-0.4l0.2,0.2l0.2-0.4v-0.1 M99.4,117.3L99.4,117.3L99.4,117.3h-0.2v-0.2 M9.9,117.2L9.9,117.2L9.9,117.2l0.2-0.1v0.3v-0.2
			 M99.3,117.2l0.1,0.2L99.3,117.2 M102.3,116.9l0.1,0.5l0.1-0.2l0.1,0.4l0.2-0.2l0.2,0.4l0.2-0.2l0.2,0.3l0.2-0.3l0.2,0.2v-0.4
			l0.2,0.1V117 M7,117.3L7,117.3l0.2-0.3 M52.7,117l-0.4,3.2l-0.1,0.1l-0.6,0.6l0.4-3.4L52.7,117 M99.3,117.4L99.3,117.4l0.2,0.1
			l-0.1-0.3 M8.4,117.3l0.1,0.4l0.1-0.2l0.2,0.3l0.2-0.2l0.3,0.2l0.2-0.3l0.2,0.1l0.1-0.3 M99.2,117.6l0.1,0.3h0.2l-0.1-0.3l0.2-0.1
			l0.2,0.3l0.3-0.2l0.2,0.2l0.2-0.3l0.1,0.2l0.1-0.2 M8.5,117.7l0.2,0.2l0.2-0.2l0.3,0.2l0.2-0.2l0.3,0.1l0.1-0.3H10v-0.1
			 M5.2,117.3l-0.1,0.4l0.1-0.1v0.4l0.2-0.1l0.2,0.4l0.2-0.2l0.2,0.2l0.2-0.3l0.2,0.2l0.2-0.4l0.1,0.1l0.1-0.4 M58.1,117.7l-1-0.1
			l0.5,3.4l-0.7-0.6l-0.1-0.1 M102.1,117.5l0.1,0.4l0.1-0.1l0.2,0.3l0.2-0.2l0.2,0.3l0.2-0.2l0.3,0.2l0.2-0.4l0.2,0.2v-0.4l0.2,0.1
			l-0.1-0.4 M8.5,117.9l0.2,0.2L9,118l0.3,0.1l0.2-0.2h0.2l0.2-0.3 M99.3,117.9l0.3,0.2h0.1l-0.2-0.2l0.3-0.1l0.2,0.2l0.3-0.2
			l0.2,0.2l0.1-0.1l0.1-0.2 M6.7,117.9v0.2l-0.1,0.2v-0.1H6.5l-0.2,0.3l-0.1-0.2l-0.3,0.2l-0.2-0.2l-0.2,0.1L5.3,118l-0.1,0.1v-0.4
			 M104,117.7v0.4l-0.2-0.1l-0.1,0.4l-0.2-0.1l-0.2,0.3l-0.2-0.2l-0.2,0.1l-0.2-0.3h-0.1h-0.1V118 M8.9,118.1l0.2,0.1h0.2l0.2-0.1
			h0.2l0.2-0.2 M100.5,118l-0.2,0.2L100,118l-0.3,0.1l0.2,0.1l-0.3-0.1 M6.7,118.2L6.7,118.2 M9.3,118.2l0.3-0.2 M100,118.2
			L100,118.2l0.3-0.1 M6.7,118.2L6.7,118.2l-0.3,0.1l-0.2,0.2l-0.3-0.1l-0.2,0.1l-0.2-0.2l-0.2,0.1L5.2,118 M102.4,118.1l0.1,0.2
			L102.4,118.1 M102.5,118.2L102.5,118.2l0.3,0.1l0.2,0.2h-0.1l-0.2-0.2 M102.9,118.6l0.3,0.1l-0.2-0.1l0.3-0.1l0.3,0.1l0.2-0.3
			l0.2,0.1l0.1-0.3 M5.3,118.4l0.2,0.2l0.2-0.1l0.2,0.1H6l0.2-0.1h0.2l0.2-0.2 M103.2,118.7L103.2,118.7l0.3-0.1l0.3,0.1l0.2-0.2
			 M6.3,118.6L6,118.7 M5.9,118.7H5.6l-0.2-0.1 M103.3,118.7h0.3l0.2-0.1 M52.3,120.1l0.2-0.3l0.4-3.2 M35.8,120.1l0.4,3.6h1.6
			l-0.6-3.6H35.8 M74.9,119.7l-0.3,3.4l-0.6,0.2l0.4-3.4l-1.1,0.1l-0.5,3.6l1.2-0.2l-0.5,3.4l0.7-0.4l0.4-3.1 M56.9,120.3l0.5,3.4
			v0.1l0.6,0.5l-0.5-3.4l1,0.6l-0.4-3.8 M52.1,120.4l-0.6,3.4v0.1l-0.6,0.5l0.5-3.4l-1,0.6L50,125l0.8-0.5l0.1-0.1 M58.1,124.3
			L58.1,124.3l0.9,0.6l-0.4-3.4l1.3,0.5l0.1,3.4l-1-0.5l0.4,2.9l-0.6-0.3L58.1,124.3 M34.7,124.9l0.2,1.6l0,0L34.7,124.9l-0.2-1.6
			 M51.6,123.8l0.2-0.3l0.7-3.6 M57.4,123.7l-0.2-0.3l-0.7-3.6 M57.5,123.9l0.8,3.2l0,0l0.5,0.3l0.6,2.5l0.3-0.5l-0.2-1.7l0.7,0.5
			l-0.1-2.9l1.2,0.4l-0.1,3l1.1,0.4l-0.4,2.7l1.1,0.4l0.6-2.7l0.6-3.1l-1.4-0.3l-0.5,3l1.3,0.3l1.4,0.3l0.8-3.1l0.7-3.6l1.6,0.2
			l-0.8,3.6l-0.7,3.1l-1.6-0.2l-0.7,2.7l-1.3-0.3l-0.5,2.4l-1.1-0.5l-0.8-0.8v-0.1l-0.6-1.3l-0.5-2l-0.3,0.1l-0.1-0.1l-0.8-3l-1-3.5
			 M35.1,123.5l0.5,3.3l1.3,0.2l-0.6-3.4L35.1,123.5 M37.8,123.7l0.7,3.5h1.8l-0.8-3.5H37.8 M74.3,126.4v0.2l-0.5,2.9l-0.7,0.4
			l0.6-3l-1.3,0.2l0.6-3.4h-1.5l-0.7,3.5l1.6-0.1l-0.7,3l1.4-0.2l-0.6,2.7l0.8-0.3l0.1-0.1l0.5-2.6l0.2-0.4l0.4-2.5 M51,124.4
			l-0.6,3l-0.6,0.3l0.4-2.9l-1,0.5l-0.1,2.9l0.7-0.5l-0.2,1.7l0.3,0.5l0.6-2.6l0.4-0.2l0.1-0.3l0.7-3 M49.6,129.5l-0.5,1v-2.1
			l-0.9,0.6l-0.2-3l-1.3,0.4l0.4,3l1.1-0.4l0.2,2.5l0.7-0.9v-0.1 M35.4,129.3l0.5,2.7l0.2,0.3l-0.6-2.7l0.8,0.4l-0.6-3l-0.7-0.4
			l0.5,3.1L35.4,129.3l-0.4-2.9l0,0 M50.9,126.9l0.1-0.2l0.9-3.3 M45.2,126.5l0.6,3.1l-1.4,0.3l-0.7-3.1L45.2,126.5 M58.3,127.1
			l-0.1-0.2 M36,132.2l0.7,0.3l-0.6-2.7l1.4,0.2l-0.7-3l1.6,0.1l0.8,3.1l-1.8-0.1l0.7,2.6l-1.5-0.3l0.6,2.2l-0.7-0.2l-0.1-0.1
			L36,132.2 M50.8,127.2l-0.8,3l-0.2-0.1l-0.5,2l-0.6,1.3l-0.3-1.9l-0.9,0.6l-0.4-2.6l-1.3,0.4l0.6,2.7l-1.4,0.3l-0.7-2.7l-1.6,0.2
			l-0.8-3.1l-1.7,0.1l0.8,3.1h-1.7l0.7,2.6l-1.8-0.1l0.6,2.2l-1.5-0.2l0.5,1.9l-0.8-0.2l-0.4-1.9 M73.1,132.2l-0.6,2.2l-0.8,0.3
			l0.6-2.2l-1.4,0.2l0.7-2.6l-1.7,0.1l0.8-3.1H69l-0.8,3.1l-1.7-0.1l-0.7,2.7l-1.6-0.2l-0.6,2.5l-1.3-0.4l-0.4,2.1l-1-0.5l0.3-2.1
			l0.5-2.3l-1-0.6l-0.7-0.9v-0.1v-2.1l1,0.6l-0.2,2.5l-0.3,2 M49,130.5l0.3,1.6l0.2,0.7l0.5-2.5l0.1-0.2l0.9-3.2 M59.1,130.1l-0.8-3
			 M60.1,130.5l-0.2,1.5l-0.3,0.7l0,0L59,130 M35.9,131.9l0.5,2.3l0.2,1.1l0.2,1l0.1,0.2l0.3-0.1l0.3,1.8l0.7,0.2l-0.3-1.8l1.4,0.2
			l-0.5-1.9l1.8,0.2l-0.6-2.2l1.7,0.1l-0.7-2.7l1.7-0.1l0.7,2.7l1.6-0.2l0.6,2.4l1.3-0.4l-0.5-2.4l1.1-0.4l0.4,2.3l0.8-0.8v-0.1
			 M68.1,130.3l-0.7,2.7h1.8l-0.6,2.2l1.8-0.2l0,0l0.6-2.2l-1.8,0.1l0.7-2.6H68.1 M60.1,130.4l-0.5-0.9 M73.2,132.1l0.2-0.2l0.5-2.7
			 M48.7,133.5l0.3,1.8l0.5-2.5h0.1l0.6-2.7 M59.6,132.7l0.4,2.5v0.1L59.6,132.7l-0.6-2.5 M60.4,133.5l-0.4,1.8v0.1l0.9,1l-0.1,1.9
			l1,0.6l0.1-1.9l1.3,0.4l0.4-2.2l1.6,0.2l0.6-2.4l1.7,0.1l-0.6,2.3l0,0h-1.6l-0.4,2.2l-1.5-0.2l-0.2,1.9l-1.2-0.4v1.5l-0.8-0.5
			v-0.1l-0.1-1.5l-0.6-0.6v-0.1l0,0l-0.3-2.4 M49.5,132.7l-0.5,2.5l-0.1,0.2l-0.8,1l-0.3-2.1l-1,0.5l0.3,2.1l-1.3,0.4l-0.4-2.2
			l-1.5,0.2l-0.6-2.4l-1.7,0.1l0.6,2.3l-1.7-0.2l0.4,1.9l-1.8-0.2l0.3,1.8l-1.4-0.2l0.1,1.6l-0.7-0.2l-0.2-1.5l-0.3,0.2l-0.4-1.7
			v-0.1 M72.8,134.2l-0.1,0.8l-0.1,1.2l-0.2,0.4l-0.3-0.1l0.5-1.9L72.8,134.2l0.5-2.3 M36.3,134.3l0.2,0.2 M70.3,135l1.4-0.2
			l-0.5,1.9l0.7-0.2l-0.3,1.8l0.3,0.2l0.4-1.7v-0.1l0.3-1.5 M49,135.3v0.2l-0.2,2.2L49,135.3 M66.8,135.3l1.7-0.2l-0.5,2l1.8-0.3
			l0.5-1.9 M60,135.3L60,135.3 M37.2,138.4v0.1l0.2,1l0.2,0.2v1.2l0.7,0.4V140l1.5,0.3l-0.2-1.6l1.8,0.3l-0.3-1.8l1.7,0.3l-0.4-2.1
			h1.7l0.4,2.2l-1.6-0.1l0.2,1.8l-1.6-0.3l0.1,1.6l-1.8-0.3l0.1,1.5l-1.5-0.4v1.3l-0.6-0.5V142v-1l-0.3-0.5v-0.2v-0.7 M36.5,135.4
			l0.1,1l0.1,0.1 M72,138.4v0.2l-0.2,1l-0.2,0.2l0.2-1.5l-0.7,0.2l0.3-1.8l-1.4,0.2l-0.3,1.8l1.4-0.2L71,140l0.7-0.2v1.2l0.3-0.5
			v-0.2v-0.8 M48.8,137.6L48.8,137.6v0.1l-0.6,0.5l-0.9,0.5l-0.1-1.9l1-0.6v1.9l-0.1,1.6l0.4-0.5L48.8,137.6 M70.8,140L70.8,140
			l-1.5,0.3l0.2-1.6l-1.8,0.3l0.3-1.8l-1.7,0.3l0.4-2.1 M60.2,137.6l-0.2-2.2 M48.2,139.7L48.2,139.7l-0.9,0.6l0.1-1.6l-1.3,0.4
			l-0.2-1.9l-1.5,0.2l0.2,1.9l-1.6-0.1l0.1,1.6l-1.6-0.3l0.1,1.5l-1.8-0.3v1.4l-1.6-0.5v1.2l-0.7-0.6v-0.1V142 M70.8,139.9v1.4
			l-1.5,0.4l0.1-1.5l-1.8,0.3l0.2-1.7l-1.7,0.3l0.3-1.8l-1.7,0.1l-0.2,1.9l-1.4-0.2v1.6l-1.3-0.4l0.2,1.3l-0.8-0.4l-0.1-1.3
			 M60.3,137.6l0.2,1.7L60.3,137.6 M60.5,139.3l0.4,0.5 M72.3,136.6l0.1,1.8l0.1-0.3v-1.9 M36.7,136.7l-0.1,1.8l-0.1-0.3v-1.9
			 M36.6,138.5v0.2l0.3,0.8l0.1-1 M71.9,138.5l0.1,1l0.3-0.8v-0.3 M48,139.8l-0.2,1.3l-0.8,0.4l0.1-1.3l-1.2,0.4V139l-1.5,0.2v1.6
			l-1.5-0.1v1.4l-1.4-0.2v1.5l-1.8-0.4v1.3l-1.5-0.5v1.2l-0.6-0.5v-0.1v-1.2 M71.8,140.4v1.2l-0.2,0.4l-0.1-1l-0.7,0.4v1.3l-1.6,0.5
			v-1.4l-1.8,0.3l0.1-1.5l-1.6,0.3l0.1-1.6l-1.6,0.1v1.6l-1.4-0.2l0.1,1.2l-1.2-0.4l0.2,1.1l-0.8-0.4v-0.1l-0.3-1.1l-0.4-0.4
			l-0.3-1.4 M72.5,138.7l0.1,1.6l0.1-0.5l-0.1-1.7 M36.8,138.7l-0.2,1.5l-0.1-0.2l0.1-1.8 M37.3,140.2l-0.3-0.8l-0.1,1.3l-0.3-0.5
			V140 M71.8,140.2l0.3-0.8l0.1,1.3l0.3-0.5l0.1,1.3l0.1-0.4l-0.1-1.4 M48.3,140.7l-0.3,1.2l-0.3,0.3l0.2-1.1L48.3,140.7l0.2-1.4
			 M46.1,140.7l-0.2,1.2l-1.4,0.2l0.1-1.3L46.1,140.7 M64.5,140.9l0.2,1.3H66v-1.4L64.5,140.9 M36.9,140.7v0.2l0.4,0.6v-1.1
			 M72.1,140.8v0.2l-0.4,0.5 M36.5,140.3l-0.1,1.3l-0.1-0.3l0.1-1.4 M36.8,140.9v1.3l-0.4-0.5v-0.2 M72.1,141v1.3l0.4-0.5v-0.2
			 M61.3,142.2l-0.4-0.3l-0.3-1.2 M47,141.6l-0.3,1.1l-1.3,0.4l0.3-1.1L47,141.6 M37.6,142l-0.3-0.5v1.2l-0.4-0.5l-0.1,1.2l-0.4-0.4
			l0.1-1.3 M71.6,142v0.1l-0.7,0.5v1.2l0.7-0.6v-0.1V142 M63.2,142l0.3,1.1l1.4,0.3l-0.2-1.2L63.2,142 M37.7,144.6v1.2l0.7,0.5
			l-0.1-1.2l1.5,0.5v-1.2l1.7,0.4l0.1-1.2H43l0.1-1.3h1.4l-0.2,1.2l1.4-0.3l-0.4,1.1l1.2-0.3l0.5-1.2l0.8-0.4l0.1-0.1 M61.5,142.4
			l0.4,1.1v0.1l0.8,0.3l-0.5-1.1l1.2,0.3l0.4,1.2l1.3,0.3l-0.3-1.2l1.2,0.2l-0.1-1.3l1.5-0.2v1.4l1.8-0.3v1.3l1.6-0.5l-0.1,1.2
			l0.7-0.5v-0.1v-1.2 M72.6,141.8l0.1,1.3l0.2-0.5l-0.1-1.3 M36.3,142.6l-0.1,1.2l0.2,0.4V143L36.3,142.6l0.1-1.3 M72.8,142.5v1.3
			l-0.2,0.4V143l-0.4,0.4l-0.1-1.2l-0.4,0.5v-1.1 M37.2,142.7v0.2l0.2,0.4 M71.7,142.8v0.1l-0.2,0.4 M47.6,142.4l-0.5,1.2l0.4-0.4
			l0.5-1.3 M61.8,143.5l-0.3-0.3l-0.5-1.3 M45.1,144.2L45.1,144.2l-1.3,0.3l0.3-1.2l-1.2,0.2l-0.2,1.2l1.1-0.2l-0.3,1.2l1.1-0.3
			L45.1,144.2 M61.9,143.5l0.5,1.1l0.1,0.1l0.7,0.3l-0.6-1.2l1.2,0.3l0.4,1.2l1.1,0.3l-0.3-1.2l1.1,0.2l-0.2-1.2h1.4l0.1,1.3h-1.3
			l0.2,1.2l-1-0.3l0.3,1.2l-1.1-0.3l-0.3-1.2l-1.1-0.3l0.5,1.2l-0.7-0.4v-0.1l-0.4-1.1 M37.3,143v1.2l-0.4-0.5v-0.1 M71.8,143v1.2
			l0.5-0.5v-0.1 M47.2,143.6l-0.5,1.1l-0.1,0.1l-0.8,0.3l0.6-1.3L47.2,143.6 M36.9,143.6l-0.1,1.2l-0.4-0.5v-0.2 M72.3,143.6v1.3
			l0.4-0.5v-0.2 M46.7,144.7l0.4-0.3l0.5-1.2 M62.4,144.7l-0.4-0.3l-0.5-1.2 M37.6,144.5l-0.3-0.4v1.2l-0.5-0.4v1.4l-0.4-0.4v-1.4
			 M71.5,144.5l0.3-0.4l0.1,1.2l0.4-0.4v1.4l0.4-0.4v-1.4 M47.1,144.3l-0.6,1.2l-0.4,0.3l-0.7,0.3l0.4-1.2l-1.1,0.3l-0.3,1.2l-1,0.2
			l0.3-1.1l-1,0.2l0.2-1.2h-1.3l-0.1,1.2l-1.7-0.3v1.3l-1.4-0.5v1.3l-0.7-0.5l-0.1-1.3l-0.3-0.4v-0.1 M63,146l0.5,1.2l0.1,0.1
			l0.6,0.3l0.6,1.4h0.1l1,0.3l-0.7-1.5l-0.5-1.3l-1-0.2l0.5,1.2l1,0.3l1,0.2l-0.4-1.2l0.9,0.2l-0.2-1.1h1.2l-0.1-1.2l1.7-0.4v1.2
			l1.5-0.5v1.2l0.7-0.5v-0.1v-1.1 M73,145.3l0.2,1.7l-0.4,0.5l-0.1-1.7L73,145.3L73,145.3l-0.1-1.4 M36.1,145.6l-0.1,1.2v-0.3
			L36.1,145.6v-0.3l0.1-1.4 M71.8,145.3v0.1l-0.3,0.4 M62.9,145.8l-0.3-0.2l-0.6-1.2 M35.9,146.8v0.2l0.4,0.5l0.1-1.7l-0.3-0.5
			 M46.5,145.6l-0.5,1.2l-0.3,0.3l0.4-1.1l0.5-1.2 M37.3,145.4v1.4l0.4,0.3l0.1,1.3l0.6,0.7v-1.5l1.4,0.4v-1.3l1.6,0.3l0.1-1.1h1.2
			l-0.2,1.1l0.9-0.2l-0.3,1.1l1-0.1l0.4-1.3l1-0.3l-0.5,1.2l0.6-0.3l0.1-0.1 M64.8,149l0.6,1.5l1.1,0.5l-0.8-1.7l0.9,0.1l0,0L66,148
			l0.8,0.1l-0.2-1.1l1.1,0.1l-0.1-1.1l1.7-0.3v1.2l1.4-0.5v1.3l0.7-0.5V146 M36.8,146.3L36.8,146.3l0.5,0.5l0.2,1.7l-0.6-0.3v-0.1
			L36.8,146.3 M72.2,146.3v0.1l-0.5,0.4v-1.4 M73.2,146.5l0.5,2l-0.2,0.6l-0.4-2L73.2,146.5l-0.3-1.2 M63.4,147.1l-0.3-0.3l-0.4-1.2
			 M45.6,147.2l-0.5,1.3l0.5-0.4l0.2-0.5l0.3-0.8 M71.8,146.8l-0.2,1.7l-0.3-0.2l0.1-1.3L71.8,146.8 M43.1,147.9L43.1,147.9
			l-0.8,0.2l0.2-1.1h-1.1l-0.1,1.2l-1.5-0.2l-0.1,1.4l-1.3-0.4l-0.4,1.6l-0.5-2.2l0.3-0.1 M66.7,149.4l0.4-0.2l-0.3-1.1l1,0.1
			l-0.1-1.1l1.6-0.3v1.3l1.4-0.4v1.5l0.6-0.7 M44.9,147.6L44.9,147.6l-1,0.3l-0.7,1.5l1-0.4L44.9,147.6 M63.3,147.6l0.1,0.2
			L63.3,147.6L63.3,147.6l-0.3-0.8 M36.2,147.5v0.1l0.6,0.5 M72.1,146.4l-0.1,1.8l0.6-0.5v-0.1 M63.3,147.8l0.1,0.3l0.5,0.4
			l-0.3-1.2 M43.1,148l-0.6,1.4l-0.4-0.2l0.3-1.1l-1,0.1l-0.2,1.4l0.9-0.4 M72.8,147.7l0.2,2.2l-0.8,0.6v-2.2l-0.6,0.3l-0.6,2.2
			l-0.4-1.6l-1.3,0.4l-0.1-1.5l-1.5,0.2l0.2,1.4l-0.8-0.4 M64.8,148.9l-0.7-0.4v-0.1 M35.9,147l-0.4,2l-0.2-0.5l0.5-2 M45.5,148.1
			l-0.4,1.4v0.1l-0.6,0.4l0.5-1.4l-0.8,0.4l-0.7,1.6l0.9-0.4l0.1-0.1 M45.4,149.9l-0.2,1l0.1-0.4L45.4,149.9v-0.7l0.3-1.5
			 M63.5,148.1l0.4,1.3l-0.2-0.3l-0.3-1.4 M36.3,147.6l-0.2,2.2l-0.5-0.7V149 M73.5,149.1l0.5,2.4l-0.7,0.7l-0.4-2.5L73.5,149.1
			v-0.2 M66.6,149.4l1.2,1.7l0.1-1.5h1.4l0.1,1.8l-1.7-0.2l0,0l-1.3-0.1L67,153l-1.1-0.5l-0.6-1.8l-0.9-0.4l-0.1-0.1l-0.5-1.5
			 M44.5,150.1l-0.5,1.8l-1,0.4l0.6-1.8l-1.1,0.5l0.8-1.7l-0.9,0.1l-1.2,1.7l-0.1-1.5l-1.4-0.1l-0.2,1.8l-1.5-0.6l0,0l-1.2-0.3v-2.2
			 M63.9,149.4l0.1,0.2l0.6,0.4 M45.1,149.5l0.3-0.4 M63.7,149.7l0.1,1l-0.1-0.2V149.7v-0.6 M36.2,149.8l-0.4,2.5l1,0.6l0.3-2.5
			L36.2,149.8 M73.7,148.4l0.7,2.4l-0.4,0.7l0.6,2.7v0.1l-0.7,0.7l-0.5-2.8l-1,0.6l-0.3-2.5l-1.1,0.3l-1.5,0.6l0,0 M35.6,149.1
			l-0.6,2.4l-0.3-0.7l0.7-2.4 M45.1,149.6l-0.3,1.8l0.4-0.4v-0.1 M63.9,149.6l0.3,1.8l-0.4-0.4v-0.2 M44.8,151.4L44.8,151.4
			l-0.8,0.5l-0.5,2.3l-1,0.4l0.5-2.3l-1.1,0.5l0.6-1.9l-1.3,0.1l-1.7,0.2l-0.3,2.1l0,0l-1.4-0.1l-1.2-0.4l-0.4,2.7l-1.1-0.5l0.5-2.8
			l-0.7-0.8l-0.6,2.7l-0.3-0.7l0.6-2.7 M64.2,151.3L64.2,151.3l0.7,0.5l-0.4-1.8 M64.2,151.4l0.3,2.4l0.8,0.3l-0.4-2.3l1,0.4
			l0.5,2.2l-1-0.4l0.5,3l-1-0.3l-0.4-3l-0.6-0.4l-0.1-0.1l-0.2-2.3 M63.9,153.2l-0.2-0.3v-2.4 M45.2,150.9l-0.2,2.3l0.2-0.3l0.1-2.5
			 M44.7,151.5l-0.3,2.4l-0.8,0.4l-0.5,3l-1.1,0.3l0.5-3l-1.1,0.6l0.5-2.3l-1.3,0.4l0.6-2.2 M74.3,150.8l0.6,2.6l0.4,3l-0.4,0.8
			l-0.8,0.7l-0.4-3l-1.1,0.5l-0.4-2.7l-1.2,0.4l-0.2-2.6 M34.4,154.2v0.1l-0.4,3v0.1l0.8,0.7l1.1,0.4l0.3-2.9l1.2,0.3l0.3-2.6
			l0.2-2.6 M64.1,153.4l0.2,3v0.1l0.7,0.4l0.5,3.2l1,0.3l-0.5-3.2l1,0.3l-0.5-3l1.1,0.5l-0.5-2.3l1.3,0.4l-0.6-2.2 M45,153.2v0.2
			l-0.6,0.4l-0.4,3l0.7-0.4v-0.1L45,153.2 M64.3,156.5l0.4,3.2l0.1,0.1l0.8,0.4l0.6,3l0.8,0.3l-0.5-2.9l1,0.3l-0.5-3.2l1.1,0.5
			l-0.5-2.9l1.2,0.5l-0.5-2.4l1.4,0.1l-0.3-2.1 M44.7,156.5l-0.4,3.2v0.1l-0.7,0.4L44,157l-1,0.3l-0.5,3.2l-1,0.3l0.4-3.1L41,158
			l0.5-2.9l-1.2,0.5l0.5-2.4l-1.4,0.1 M64.8,159.7l0.6,2.9l0.1,0.1l0.7,0.4l0.7,2.9l0.6,0.1l-0.5-2.6l1,0.3l-0.4-3l1.1,0.4l-0.4-3.1
			l1.2,0.5l-0.4-2.9l1.3,0.2l-0.4-2.4l1.4-0.1l0.3,2.6l1.3-0.2l0.3,2.9l1.2-0.4l0.2,3l0.8-0.6l-0.2-3.1l-0.4-3 M75,153.5l-0.3,0.8
			 M34,157.3l-0.2,3v0.1l0.8,0.6l0.2-3l0.3-3l-0.7-0.8 M41.7,160.7L41.7,160.7l-1,0.4L41,158l-1.1,0.4l0.4-2.8l-1.3,0.2l0.4-2.5
			 M33.9,160.4v3.1l0.7,0.6v-3l1.2,0.4l0.2-3l1.3,0.2l0.2-2.8h1.3l-0.3,2.8l1.2-0.2l-0.3,3l1-0.3l-0.3,2.9l1-0.4l0.4-2.9
			 M65.5,162.6l0.7,2.8l0.1,0.1l0.6,0.3l0.7,2.7l0.3-0.5l-0.4-2.1l0.7,0.4l-0.3-2.7l1,0.4l-0.4-2.9l1,0.3l-0.2-2.9l1.2,0.2l-0.2-2.8
			h1.3l0.2,2.8l1.3-0.2l0.2,3l1.2-0.4v3l0.8-0.6v-3.1l0.4-0.7l-0.2-3.2 M44.8,156.4l0.3-0.4l0.2-3.1 M64.3,156.4L64,156l-0.2-3.1
			 M34,157.3l-0.3-0.7l0.4-3 M67.8,168.2l0.6,0.8l-0.2-2.6l0.9,0.4l-0.2-2.8l1,0.3l-0.2-2.9l1.1,0.2l-0.2-3h1.3l0.2,2.9h-1.3
			l0.2,2.9l-1.1-0.2l0.2,2.9l-0.9-0.3l0.1,2.8l-0.8-0.5V169 M33.7,156.7l-0.2,3.2v3.1l0.3,0.6l0.1,2.9l0.1,0.1l0.7,0.5l-0.1-2.9
			l1.1,0.3l0.1-3l1.3,0.2l0.2-2.9h1.3l-0.2,3l1.1-0.2l-0.2,2.9l1-0.3l-0.2,2.8l0.8-0.4l0.3-2.8l1-0.3l0.5-3l1-0.3l0,0"
        />
        <path
          class="st0"
          d="M44.4,159.6l0.3-0.3l0.4-3.3 M64.7,159.6l-0.3-0.3L64,156 M33.8,160.3l-0.3-0.6 M37.2,161.6l-0.1,3l1.2-0.1
			l0,0l0.2-2.9H37.2 M75.6,159.7v3.2l-0.3,0.7l-0.1,2.9v0.2l-0.7,0.5l0.1-2.9l-1.1,0.3v-3l-1.3,0.2l0.1,3l-1.2-0.1l0.1,2.9l-1-0.3
			l0.1,2.8l-0.9-0.3l0.1,2.6l-0.9-0.7l0,0V169 M65.5,162.6l-0.3-0.3l-0.6-3 M44.8,159.3l-0.7,3l-0.4,0.4l0.6-3 M44.2,162.2l-0.8,2.9
			l-0.3,0.3l0.8-2.9l-0.7,0.4l0.6-3 M43.1,165.4L43.1,165.4l-0.7,0.4l0.7-2.8l-0.9,0.3l-0.6,2.7l0.6-0.1l-0.7,2.8l-0.3-0.5l0.4-2.2
			l-0.8,0.4l-0.2,2.5l0.6-0.8 M40.7,168.9L40.7,168.9l-0.8,0.6l0.2-2.8l-1,0.3l0.1-2.9l-1,0.2 M34,166.5l0.2,2.6v0.1l0.6,0.5
			l-0.1-2.7l1,0.3v-2.9l1.3,0.1v2.9l-1.2-0.1v2.7l-1-0.3l0.1,2.4l-0.5-0.4v-0.1l-0.2-2.4 M75.5,162.9l-0.2,3.1l-0.3,2.8l-0.3,0.6
			l-0.6,0.5l0.2-2.7l-1.1,0.3v-2.9l-1.3,0.1v3l-1.1-0.2L71,170l-1-0.2l0.1,2.5l-0.8-0.2l0.1,2.2l-0.6-0.2l-0.3-2.6 M66.1,165.5
			l-0.3-0.3l-0.8-2.9 M34,166.4l-0.2-0.4l-0.2-3 M75.1,166.4l0.3-0.5 M42.9,165.6l-0.9,3l-0.4,0.1l-0.9,2.9l0,0l-0.9,0.7l0.1-2.6
			L39,170l0.1-2.8l-1,0.3l0.1-2.9 M34.5,171.8l0.2,2.1v0.1l0.5,0.3l-0.2-2.2l0.9,0.3l-0.1-2.4l1.2,0.1l1.1-0.1l0,0l0.1-2.7l-1.1,0.1
			v2.7v2.4l0,0l-1-0.1l0.1,2.1l-0.8-0.2l0.1,2L35,176v-0.1l-0.1-1.9 M75.1,166.6l-0.2,2.7l-0.2,2.4v0.1l-0.5,0.4l0.2-2.4l-1,0.3
			l0.1-2.7l-1.3,0.2v2.6l-1.1-0.1l0.1,2.5l-1-0.2l0.1,2.2l-0.8-0.2l0.1,2.1l-0.6-0.2l-0.1-2.1l-0.5-0.3v-0.1l-0.4-2.4l-0.7-2.9
			l-0.1-0.1l-0.8-2.9 M42.5,168.2l-0.8,2.9l-0.3,0.3l0.7-2.7L42.5,168.2l0.9-3 M67.1,168.5l-0.3-0.3l-0.9-3 M67.7,168.7L67.7,168.7
			l-0.5-0.1 M34.4,169.2l-0.2-0.3l-0.3-2.9 M40.8,171.5l-0.3,2.7l-0.7,0.2l0.1-2.2l-0.8,0.3l0.1-2.5l-1,0.2 M74.7,171.8l-0.2,2.2
			l-0.5,0.3l0.2-2.1l-1,0.2l0.1-2.4l-1.2,0.1v2.4H71v2.1l-1-0.1v2l-0.8-0.1l0.1,2l-0.5-0.2v-2l-0.4-0.2l-0.3-2.2 M67.5,168.7
			l0.9,2.8l-0.6-0.1l-0.3-0.4l-0.8-2.9 M41.3,171.3l-0.1,0.1l-0.6,0.1v-2.4 M74.6,171.7l0.2-0.3l0.3-2.6 M34.5,171.7l-0.2-0.2
			l-0.3-2.6 M41.3,171.4l-0.4,2.5l-0.5,0.3l-0.1,2.2l-0.6,0.2l0.1-2.1l-0.8,0.2l0.1-2.2l-1,0.1l0.1-2.5 M37,172.6h1v2.1l0.9-0.1v2
			l0.8-0.1l-0.1,2l0.6-0.2l0.1-2l0.4-0.2v-0.2l0.2-2l0.3-0.3l0.5-2.5 M73.2,172.5l-0.1,2.1l-1,0.1v-2.1L73.2,172.5 M68.2,173.7
			l-0.2-0.2l-0.5-2.5 M74.6,173.6l-0.1,2.1l-0.2,0.2l0.1-1.9L74.6,173.6l0.2-2.2 M34.6,173.9l-0.2-0.2l-0.2-2.2 M74.3,175.9v0.1
			l-0.5,0.3l0.1-2l-0.8,0.2l-0.1,1.9l0.8-0.2l-0.1,1.9l0.5-0.2L74.3,175.9 M34.8,176v2l0.5,0.2l-0.1-1.9l0.8,0.2l-0.1-1.9l1,0.1
			v-2.1 M34.4,173.7l0.1,2.1l0.1,2l0.2,0.2v1.8l0.4,0.2l0.1-1.7l0.7,0.2v-1.9l1,0.1v-1.9h1v1.9h-1v1.9l-0.9-0.1v1.7l-0.8-0.1
			l-0.1,1.4l-0.5-0.1l0.2-1.5l-0.2-0.2v-1.8 M68.1,173.6l0.4,2.3l0.1,0.2l0.1,1l0.2,1.1l0.2,0.2l0.1,1.9l0.5,0.1l-0.1-1.8l0.8,0.1
			v-1.9h0.9v-1.9h1v1.9l1-0.1v1.9l0.7-0.1v1.7l0.4-0.2v-1.8l0.2-0.3l0.1-2 M40.8,176l0.1-0.1l0.4-2.2 M34.8,175.9l0.2,0.2
			 M72.1,176.6L72.1,176.6h-1v1.9l1-0.1V176.6 M40.6,176.1l-0.1,0.9l-0.2,1.1l-0.2,1.9l-0.2,0.2l-0.5,0.1l0.1-1.8l-0.8,0.1v-1.9H38
			v1.9l-1-0.1v1.6l0,0l-0.9-0.1l-0.2,1.3h-0.7l-0.2,1.1h0.8l0.2-1.1l0.9,0.1l0.1-1.3 M68.6,177l-0.2-1.2 M40.5,177l0.2-1.3
			 M40.2,180l-0.1,1.6l-0.2,0.2l0.1-1.6l0.1-1.8l0.2-0.2 M74.4,177.7v1.8l-0.2,0.2l0.2,1.4l-0.4,0.1l-0.2-1.4L73,180l-0.1-1.7
			l-1,0.1l0.1,1.7l1-0.1l0.1,1.3h0.8l0.2,1.2h-0.8l-0.2-1.1h-1l-0.1-1.3l-1,0.1v-1.7h-0.9l0.1,1.8l0.8-0.1l0.1,1.3l1-0.2l0.2,1.1h1
			l0.3,1h-1l-0.2-1l-1,0.2l-0.2-1.1l-0.8,0.2l-0.1-1.4h-0.8l0.1,1.5h-0.5l-0.1-1.6l-0.2-0.2l-0.2-1.9 M37,180.1l0.9,0.1l0.1-1.7h0.9
			l-0.1,1.7l-0.9-0.1l-0.1,1.3l-1-0.1l-0.2,1h-1l-0.3,1h1l0.2-1l1,0.2l0.1-1.1l0.9,0.2l0.1-1.4h0.8l-0.1,1.5l-0.8-0.1l-0.1,1.1
			l-0.9-0.2l-0.2,1l-1-0.2l-0.3,1h-1l0.4-1.1l-0.8,0.2l0.3-1.1l-0.5,0.1l0.3-1.3l-0.2-0.1l0.2-1.6 M74.6,181.1l0.4,1.6l-0.3-0.2
			l0.3,1.2l-0.5-0.2l0.4,1.1l-0.8-0.2l0.5,1.2l-1-0.1l-0.5-1.1l-1,0.2l-0.2-1l-1,0.3l-0.1-1.1L70,183l-0.1-1.3l0.8-0.1l0.1,1.2
			l0.9-0.2l0.2,1l1-0.2l0.3,1h1.1l-0.4-1.1l0.8,0.2l-0.3-1.1l0.5,0.1L74.6,181.1L74.6,181.1l-0.2-1.7 M68.9,181.5v1.4l0.2,0.4v-1.6
			L68.9,181.5v-1.6 M40.1,181.4v1.5l-0.2,0.3v-1.6h-0.6l-0.1,1.3l-0.8-0.3l-0.1,1.1l-0.9-0.3l-0.2,1l-1.1-0.2l-0.4,1.1l-1.1,0.1
			l0.5-1.2l-0.8,0.2l0.4-1.1l-0.5,0.2l0.3-1.2l-0.3,0.2l0.4-1.6 M68.9,182.9l-0.2,1.2l0.2,0.3l0.3-1.1v-0.1l0.7-0.3l0.1,1.2l0.8-0.4
			l0.2,1.1l1-0.4l0.5,1.1l1-0.1l0.6,1.3H75l-0.6-1.3l0.9,0.2l-0.5-1.2l0.5,0.3l-0.4-1.2l0.3,0.3l-0.2-1v-0.1 M34.1,183.8L34.1,183.8
			l-0.3,0.3l0.1-0.7l0.2-0.6 M34.2,183.8l-0.4,1.2l0.5-0.3l-0.5,1.1l0.8-0.2l-0.6,1.3l1-0.1l0.6-1.3l1,0.2l0.5-1.1l0.9,0.3l0.2-1.1
			l0.8,0.4l0.2-1.1l0.6,0.3l0,0l0.3,1.1l0.1-0.2v-0.1L40,183 M40.4,184.2v0.4V184.2 M33.9,184l-0.1,0.2l-0.1,0.2l0.4-1.1
			 M68.8,184.2v0.2l-0.1,0.5v0.4l0.1-0.9 M75.4,184l0.1,0.2l0.2,0.3l-0.7-1.6 M33.7,185.8L33.7,185.8l-0.7,1.4l1-0.3l-0.7,1.6l1-0.1
			l0.7-1.6l1,0.2l0.6-1.3l1,0.4l0.4-1.2l0.7,0.5l0.3-1.2l0.6,0.4l0.2-1.2 M72.4,185.7L72.4,185.7l-1,0.5L71,185l-0.7,0.4l-0.3-1.2
			l-0.6,0.4l-0.2-1.2 M75.5,184.5l0.2,1h-0.1l-0.3-1.3 M33.7,185l-0.1,0.2l-0.6,1.2v0.1l-0.7,1.3l0.7-0.4l-0.7,1.6l1-0.3l-0.7,1.8
			l0.9-0.1l0.8-1.8l1,0.1l0.8-1.5l0.9,0.4l0.7-1.3l0.6,0.6l0.5-1.3l0.6,0.5l0.2-1.3L40,185l0.2-0.3v-0.1 M69.1,185v0.8l0.5,0.2
			l-0.2-1.3L69.1,185l-0.2-0.3v-0.1 M40.3,184.7l0.1,0.7 M40.4,185.9l-0.1,0.4l0.1-0.2v-0.7 M75.7,185.6v0.1V185.6l-0.2-0.2
			l-0.2-0.4 M33.6,185.2l-0.3,0.4l0.4-1.3 M68.6,185.8l0.1,0.6v-0.1L68.6,185.8l0.1-0.4l0.2,0.2l0.1-1 M33.7,184.5l-0.1,0.4
			l-0.1,0.7l0,0 M40.2,184.7l0.1,1l0.2-0.2 M33.1,186.4l-0.1,0.1l0.4-1 M40,186.3l-0.3,0.6l-0.2-1.1l0,0l0.5-0.2V186.3l0.1,0.1
			l0.1-0.9l-0.3,0.1v-0.8 M68.8,185.6l0.1,1l0.2-0.1v-0.6L68.8,185.6 M69.1,186.4l0.3,0.5l0.2-1l0,0l0.6-0.5l0.5,1.3l0.7-0.6
			l0.7,1.4l0.9-0.4l-0.6-1.3 M75.3,185.9L75.3,185.9l0.6,0.4l-0.4-1 M33.1,186.3l0.6-0.4 M75.9,186.3l0.2,0.4l-0.4-1 M40.1,186.4
			L40.1,186.4l0.2-0.2l-0.2,1l0.2-0.2v-0.3v-0.1v-0.7 M69.3,186.9v0.1l-0.3,0.4l-0.2-0.9l-0.3-0.1l0.2,1l-0.2-0.3v-0.3v-0.6
			 M75.3,185.9l0.6,1.3l0.7,0.4l-0.5-1l0.9,1.5l-0.2-0.2l-0.2-0.3 M40.4,186.8l0.4,1l-0.1,0.3L40.4,186.8 M73,187.1l0.8,1.5l1-0.1
			l0.8,1.8l0.9,0.1l-0.7-1.8l1,0.2l-0.7-1.6l-1-0.3l0.7,1.6h-1l-0.8-1.6L73,187.1 M40.1,186.5l-0.2,1l-0.3-0.4l-0.4,1.1l-0.4-0.7
			l0.6-1.5 M40.2,187.3L40.2,187.3l-0.3,0.1l-0.2,1.2l-0.4-0.5l-0.4,1.4l-0.8-0.3l-0.5-1l-0.6-0.7l-0.9,1.5l-0.8-0.4l-1,1.8
			l-0.8-0.1l-0.8,2l-0.7,0.1l0.7-2l-0.8,0.2l0.6-1.7l-0.8,0.5l0.7-1.5v-0.1 M76.8,188l0.6,1.3l-0.8-0.5l0.7,1.8l-0.9-0.2l0.7,2
			l-0.7-0.1l-0.8-2l-0.8,0.1l-1-1.8L73,189l-0.9-1.5l-0.6,0.7l-0.7-1.4l-0.5,0.7l-0.6-1.5 M31.8,190.6L31.8,190.6l-0.6,2l0.8-0.2
			l-0.6,1.8h0.5l0.7-1.8l0.7,0.2l1-2l0.7,0.3l1-1.7l0.6,0.6l0.4,1l1.1-1.3l0,0l0.7-1.8l-0.5-0.7l-0.7,1.4l-1,1.4l-1,1.6l-0.6-0.5
			l-1,2l-0.7-0.3l-0.9,1.9l-0.6-0.2l-0.5,1.2H31l0.4-1.2l-0.5,0.1l0.3-1.6l-0.8,0.4L31,191l0.7-1.7l-0.5,0.4l1.1-1.8l0.8-1.4
			 M68.7,187.2v1.2l0.4,0.2l-0.1-1.2h0.3l0,0 M68.7,186.9l-0.3,1l-0.1-0.2L68.7,186.9 M32.4,187.8l-0.1,0.2 M68.4,188v0.1l0.2,0.3
			l-0.1,1.2l-0.4-0.3l0.3-1.2 M69.5,187.1l0.4,1.1l0.5-0.7l0.7,1.7l0.6-1l1,1.4l0.6-0.6l1,1.7l0.7-0.3l1,2l0.7-0.2l0.8,1.8h0.6
			l-0.6-1.8l0.7,0.1l-0.5-1.9l0.8,0.4l-0.7-1.7l0.6,0.6l-1-1.6 M40.7,187.7l0.3,1.2l-0.2,0.3l-0.2-1.2l-0.2,0.2l-0.1-1.1
			 M40.5,188.2L40.5,188.2l-0.3,0.3l0.1-1.2 M78.1,189.8l0.1,0.2l0.7,1.4l-0.6-0.4l0.5,1.9l-0.9-0.4l0.4,1.6l-0.5-0.1l0.4,1.2h-0.5
			l-0.5-1.2l-0.6,0.2l-0.9-1.9l-0.7,0.3l-1-2l-0.5,0.5l-1-1.5l-0.4,1l-1.1-1.3l0,0l-0.8,0.3l-0.4-1.4l-0.4,0.4l-0.2-1.2 M40.1,188.6
			L40.1,188.6v1.2h-0.6l-0.2,1.1h-0.6l0.2-1.3l0.6,0.3l0.2-1.2L40.1,188.6 M69,188.5l0.1,1.2h0.6l0.2,1.1h0.6l-0.2-1.2l-0.6,0.2
			l-0.2-1.2L69,188.5 M68,188.9l-0.1,1.2l0.1,0.2l0.1-1.1L68,188.9l0.3-1.2 M41.1,188.9l0.1,1.2l-0.2,0.2v-1.1l-0.4,0.3l-0.1-1.2
			 M41.1,190.1l-0.2,1l-0.1,0.3l0.1-1.1l-0.5,0.3v-1.1l-0.5,0.2l-0.1,1l0.6-0.2l-0.1,1l0.5-0.2 M68,190.3L68,190.3l0.5,0.4v-1.1
			l0.5,0.2l0.1,1.1l-0.6-0.2l0.1,1l-0.5-0.3l0,0l-0.2-1.2 M30.8,190.3l-0.5,1l-0.1,0.1l-0.6,0.5l0.3-0.5L30.8,190.3l0.3-0.6
			 M79.3,191.8l0.1,0.2l0.6,1.3l-0.6-0.3l0.3,1.2h-0.4l-0.6-1.2l0,0l0.7,0.1l-0.6-1.7L79.3,191.8l-0.6-1l-0.6-0.9 M39.9,190.8
			L39.9,190.8l-0.6,0.1l-0.2,1l0.7-0.1L39.9,190.8 M68.2,191.3l0.3,1l0.5,0.2l-0.3-0.9l0.6,0.1l-0.2-1l0.7,0.1l0.2,1l-0.6-0.1
			l0.3,0.9l-0.6-0.1l0.4,0.6l-0.5-0.2l-0.5-0.6 M30.3,191.3l0.6-0.3l0.8-0.4 M40.9,191.4l-0.3,0.8l-0.5,0.2l0.3-0.9l-0.6,0.2
			l-0.3,0.9l-0.7,0.1l0.3-0.9l-0.6,0.1l0.2-1.2L38,191l0.1-1.8 M69.4,193.1L69.4,193.1l0.7,0.1l-0.5-0.6l0.7,0.1l-0.4-0.9l0.6,0.1
			l-0.2-1.1l0.7,0.1l-0.1-1.8 M68.1,190.3v1 M39,193.2l0.5-0.1h0.1l0.5-0.6l-0.7,0.1L39,193.2L39,193.2l-0.7,0.1l0.5-0.6l-0.6,0.1
			l0.3-0.9l-0.6,0.3L38,191l-0.8,0.7l-0.2-1.2l-1,1.3l-0.4-0.7l-1.1,1.8l-0.6-0.3l-1,1.8l-0.6-0.2l-0.6,1.2l-0.4-0.2l-0.4,0.7
			l-0.4,0.1l0.3-0.8h-0.4l0.3-1.1h-0.5v-1.2l0,0l-0.7,0.1l0.6-1.6 M69.5,193.1l0.7,0.3l0.5,0.1l-0.7-0.3l0.7,0.1l-0.5-0.6l0.6,0.1
			l-0.3-0.9l0.6,0.3l-0.2-1.3l0.8,0.7l0.2-1.2l1,1.3l0.4-0.7l1,1.8l0.6-0.3l1,1.8l0.6-0.2l0.6,1.2l0.5-0.2l0.3,0.8l0.5,0.1l-0.3-0.8
			h0.4l-0.3-1.1h0.5l-0.1-1.2 M29.5,192l-0.4,0.5l-0.6,0.9l0.4-0.1l0,0l-0.1,0.9h0.5l0.3-1.2l-0.7,0.3l0.7-1.4L29.5,192l0.5-0.7
			 M79.4,191.9l0.3,0.3l-1-1.4 M33.1,194.5L33.1,194.5l0.6,0.1l1-1.6l0.6,0.4l1-1.6l0.4,1l0.8-1.1v1.2l0.6-0.6v-0.1 M79.8,192.3
			l0.1,0.1l0.6,1l-0.4-0.1l0,0l0.6,0.8l0.4,0.1l-0.5-0.9l0.4,0.2L79.8,192.3 M70.8,193.3L70.8,193.3l0.6,0.1l-0.5-0.7l0.6,0.4
			l-0.3-1l0.6,0.6v-1.2l0.8,1.1l0.4-1l1,1.5l0.5-0.4l1,1.6l0.6-0.1l0.7,1.2l0.5-0.2l0.4,0.8l0.4-0.2l0.3,0.5h0.4l-0.2-0.5l0.4,0.3
			l-0.4-0.9L79,195l-0.2-1l0.3,0.2l0.2-0.2l0.3,0.8h-0.3l-0.2-0.5 M38.1,193L38.1,193l-0.6,0.4l0.3-1 M37.2,192.9l-0.1,0.2l-0.6,0.8
			l-0.2-1l-0.7,1.2l-0.6-0.7l-0.8,1.5l-0.6-0.3l-0.6,1h0.7l0.5-0.8l0,0l1,0.2l0.5-1.1l0.4,0.8l0.5-0.9v-0.1 M71.9,192.9v0.1l0.6,0.8
			l0.2-1l0.7,1.2l0.6-0.7l0.8,1.5l0.7-0.3l0.6,1h0.7l0.5,1l-0.9-0.3l-0.3-0.7l-0.7,0.1l-0.6-0.8l-0.9,0.2l-0.5-1.1l-0.4,0.8
			l-0.5-0.9v-0.1 M39.6,193.2l0.5-0.2l0.5-0.6 M38.3,193.4L38.3,193.4l-0.6,0.1l0.5-0.6 M29,193.2l-0.6,0.8l-0.3,0.1l0.5-0.8
			l-0.3,0.1l0.9-1 M70.8,193.5l0.8,0.2l-0.6-0.3 M39.6,193.1l-0.7,0.3l-0.7,0.1l0.7-0.3 M28.7,194.2l-0.1,0.4l0.4,0.2l0.1-0.7
			L28.7,194.2l-0.2-0.2l-0.3,0.6l-0.3,0.1l0.3-0.6l-0.4,0.1l0.5-0.7l0.1-0.1 M38.2,193.5l-0.7,0.1l0.6-0.3 M71.5,193.5l0.4,0.2h-0.3
			 M71.9,193.7l0.2,0.1l-0.5-0.7 M37.5,193.7l-0.4,0.1l0.4-0.3 M37.2,193.8l-0.2,0.1l0.6-0.7 M36.6,193.9l-0.1,0.9l0.5-0.9l0.2-0.9
			 M72.5,193.9v0.9l-0.5-1l-0.1-0.9 M30,194.2l-0.2,0.5l0.4,0.2l0.2-1L30,194.2l-0.2-0.2l0,0l0.6-1.2 M80.5,194.2l0.1,0.4h0.3
			l-0.3-0.6L80.5,194.2l-0.3-0.1l0.1,0.7L80,195l-0.2-0.9h0.5l-0.1-0.9 M29.8,194l-0.3,0.8h-0.3l-0.2,0.8l-0.3-0.4l-0.2,0.3
			l-0.2-0.2l0,0l0.1-0.4l-0.3,0.1l0.1-0.5l0.3-0.1l-0.1,0.5l0.4,0.3l0.1-0.6l0.4,0.2l0.2-0.9L29.8,194 M81.4,194.2L81.4,194.2
			l0.3,0.5h-0.4l0.2,0.6l-0.4-0.2v0.4h-0.3l-0.1-0.4l-0.3,0.3l-0.2-0.6l0.3-0.2l0.1,0.5l0.4,0.1l-0.2-0.5l0.4,0.1l-0.3-0.6
			L81.4,194.2l-0.5-0.7l0.8,0.9L81.4,194.2 M27.4,194.3l0.3-0.1l-0.3,0.5l-0.4,0.1l0.1-0.2L27.4,194.3l0.8-0.8 M81.7,194.3
			L81.7,194.3l0.3,0.4l-0.4-0.1l0.2,0.4h0.3l-0.1-0.2l0,0 M27.2,194.7l-0.2,0.2l0.1-0.1l-0.2,0.2h0.4l0.2-0.4h0.4l-0.2,0.6l-0.4-0.3
			l-0.2,0.2l-0.3-0.1l0.1-0.2l-0.2,0.1l0.2-0.3 M29.5,194.9l-0.2,0.7l0.3-0.1l0.2-0.7L29.5,194.9 M34.3,194.9l0.5,1l0.4-0.1l0.1-0.6
			l0.5,0.7l0.4-0.8V195 M74.8,194.9l-0.4,1l-0.3-0.1l-0.1-0.6l-0.5,0.7L73,195v-0.2 M80,195l0.2,0.8l-0.3-0.2L80,196h-0.2l-0.2-0.5
			l-0.3,0.3l-0.3-0.7l0.4-0.2l0.2,0.6l0.3,0.1l-0.2-0.7L80,195 M82.1,194.9l0.3,0.2l-0.6-0.6 M82.5,195.3l0.1,0.2l-0.3-0.1l-0.2-0.2
			L82.5,195.3l-0.2-0.2 M29.1,195.8L29.1,195.8l0.3-0.2l-0.1,0.4h0.3l0.2-0.4l0.3,0.3l0.3,0.5l0.4-1l-0.4-0.2l-0.2,0.7l-0.3,0.4
			l-0.2-0.2l-0.2,0.3H29l0.1-0.2l0.1-0.1 M26.6,195.4l-0.1,0.1l-0.1,0.2l0.2-0.1l0.1-0.2H26.6l0.2-0.2 M82.7,195.6L82.7,195.6
			l0.2,0.2L82.7,195.6l-0.1-0.2L82.7,195.6h-0.3l-0.2-0.2l-0.3,0.1l-0.2-0.3l-0.4,0.3v0.1l0.3,0.3l0.2-0.3l0.2,0.3l0.3-0.2l0.1,0.4
			l0.3,0.1V195.6 M26.4,195.5l-0.2,0.3l0.1-0.1l-0.1,0.3l0.3-0.1l0.1-0.3l0.3,0.2l0.1-0.3l0.2,0.4l0.3-0.3v-0.1l0.4-0.2v0.4l0.3-0.1
			 M33.1,194.5l-0.7,1.2l-0.5-0.2l-0.4,0.8l-0.4-0.2l-0.3,0.5l-0.4,0.1l0.2-0.5l-0.5,0.3l0,0v0.2v0.3l0.3-0.3l-0.2,0.3l0.3-0.1
			l0.3-0.3l0.4,0.2l0.4-0.4l0.4,0.5l0.5-1h0.7l-0.3,0.7l0.7-0.1l0,0l0.2-0.5l0.6,0.5l0.4-0.3l0.5,0.8l0.3-0.3l-0.4-0.6 M80.6,195.8
			L80.6,195.8l0.2,0.3l0.2-0.2l0,0l-0.1-0.3L80.6,195.8l-0.2-0.3l-0.3,0.4l0,0l0.3,0.3L80.6,195.8 M36.6,194.9l-0.4,1l-0.1-0.8
			 M28.3,195.6l-0.1,0.3H28v-0.2l-0.2,0.2l-0.2-0.3v0.7h-0.2l-0.1-0.5l-0.2,0.2l-0.2-0.3l-0.1,0.3l-0.3-0.1l-0.1,0.3l-0.2,0.1
			l0.1-0.4l-0.1,0.1l0.1-0.3 M36.2,195.8l-0.3,0.9l-0.2-0.9l-0.2,0.5l0.1,0.9v-0.1l0.2-0.5 M82.9,195.9l0.1,0.3l-0.2-0.2l0.1,0.4
			h-0.3l-0.1-0.4l-0.3,0.1l-0.1-0.3l-0.2,0.3l-0.2-0.2l-0.2,0.4h-0.1l-0.1-0.7l-0.2,0.3l-0.2-0.2L81,196h-0.3 M72.5,194.9l0.4,1
			L73,195 M29,195.9L29,195.9v0.2v0.2l0.2-0.1 M27.8,195.8v0.3l0.1-0.1l0.1-0.1 M81.3,195.9v0.1l0.1,0.1L81.3,195.9 M29,195.9
			l-0.3,0.3l-0.2-0.5l-0.2,0.3l-0.2-0.2l-0.2,0.2h-0.2l0.1-0.2 M80,196l0.1,0.2l0.1,0.1v-0.4 M81.3,196l0.1,0.1h-0.2l-0.2-0.2
			 M81.4,196.1L81.4,196.1l0.1,0.2 M72.9,195.8l0.3,0.9l0.2-0.9l0.2,0.6l0.3,0.3l0.4,0.3l0.4-0.8l0.3,0.4l0.6-0.3l0.1,0.4l0.6,0.1
			v-0.4l-0.7-0.1l-0.2-0.5l-0.6,0.4l-0.4-0.3l-0.5,0.8l-0.1,1l-0.3-0.3l0.1-1l0.4-0.6 M27.8,196.1l-0.1,0.2L27.8,196.1 M80.1,196.2
			l0.1,0.2H80l-0.2-0.3l-0.2,0.2l-0.2-0.4l-0.3,0.5l0,0l0.1,0.3v0.2l-0.2-0.2l-0.1-0.1 M29.1,196.1l-0.1,0.3v0.1l0,0h-0.1v-0.4
			l-0.2,0.2l-0.1-0.4l-0.2,0.2l-0.2-0.2l-0.2,0.2h-0.2V196l0,0l0,0 M80.9,196.1l-0.2,0.3l0.2,0.2l0.2-0.4l0.2,0.2l0.2-0.2l0,0
			l-0.2-0.2l-0.2,0.1L80.9,196.1 M26.3,196.2l-0.2,0.4l0.2-0.2v0.4h0.2l-0.1-0.5l0.4,0.1l0.1-0.4l0.2,0.2l0.1-0.2l0.2,0.3l0.1-0.1
			v0.2l0.2-0.2l0,0l0,0 M82.9,196.4L82.9,196.4v0.4l-0.3-0.1l-0.1-0.4l-0.3,0.1l-0.1-0.4l-0.2,0.2l-0.1-0.3l-0.1,0.4l-0.1-0.2v0.2
			l-0.1-0.1l-0.1-0.1h-0.2 M81.5,196.3L81.5,196.3 M81.5,196.3L81.5,196.3 M78.9,196.4l0.4,0.3l0.2-0.4l0.2,0.2l0.2-0.2l0.2,0.2
			l0.2,0.2h0.3l0.1-0.4l-0.2-0.2l-0.1,0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1 M26.1,196.8L26.1,196.8v0.4l0.2-0.1v-0.3l0.3,0.1l0.1-0.4
			l0.3,0.2l0.1-0.3v-0.1 M83,196.3l0.1,0.4v0.1v0.3l-0.2-0.1v0.3h-0.3v-0.4l-0.3,0.1l-0.1-0.4l-0.3,0.2l-0.1-0.4l0,0 M28.8,196.5
			L28.8,196.5 M28.7,196.7l-0.1,0.2h-0.1l-0.1-0.4l-0.1,0.1l-0.2-0.3l-0.1,0.2l-0.3-0.2l-0.2,0.3h-0.2l0.2-0.3 M80.2,196.5l-0.1-0.1
			 M30.1,196.5l-0.3,0.2l-0.2-0.4l-0.2,0.3l-0.3-0.2l-0.2,0.2h-0.2 M27,196.4l0.2,0.3v-0.2v-0.1 M28.7,196.6l0.2-0.2 M35.5,197.3
			l-0.1,0.1l-0.2,0.3l-0.1-1l-0.4,0.3l-0.5-0.8l-0.3,0.4l-0.6-0.3 M78.9,196.7L79,197l0.2,0.2v-0.5l0.2,0.2l0.2-0.4l0.2,0.2l0.2-0.2
			h0.2 M81.9,196.3l-0.2,0.3v-0.2 M83,196.6l-0.2-0.1 M27.2,196.5v0.3l0.1-0.1l-0.1,0.3l0.2-0.1l0.2-0.3l0.3,0.1l0.1-0.3l0.2,0.2
			l0.1-0.1v-0.1 M81.5,196.5v0.2h-0.2l-0.2-0.3 M30,196.9l-0.1,0.1l0.3-0.1l0.2-0.2l0.3,0.2l0.3-0.3l0.2,0.4l0.5-0.4l0,0l0.9-0.4
			v0.4l0.6-0.1l0.1-0.4 M28.5,196.9h0.2l0.2-0.2l0.3,0.2l0.2-0.2l0.1,0.4l0.2-0.2v-0.1 M78.6,196.8l0.1,0.2h-0.3l-0.2-0.3l-0.3,0.2
			l-0.4-0.4l-0.4,0.5v0.1l0.6,0.3l0.2-0.4l0.3,0.2l0.3-0.2l0.2,0.2l0,0l0.3,0.1l-0.1-0.2 M81.6,196.7v0.2l0,0V196.7L81.6,196.7
			 M25.9,196.9l-0.2,0.2v-0.2l0.1-0.3 M80.1,196.7l0.2,0.2l0.1,0.1l0.1-0.3l0.1,0.2l0.2-0.3l0.1,0.3l0.3-0.1l0.1,0.3l0,0l-0.3,0.1
			l-0.1-0.3l-0.2,0.3l-0.1-0.3l-0.1,0.3l-0.1-0.1l0,0 M27,196.8v0.1l0,0l0.1-0.3 M81.4,197.1h0.2l-0.1-0.2l-0.1-0.1l0.2,0.2
			l-0.2-0.3 M25.7,196.9v0.2l0,0v0.4l0.2-0.2v0.2l0.2-0.1v-0.3l0.3,0.1l0.1-0.4l0.3,0.3v-0.4L27,197l-0.1,0.2l0.2-0.1l-0.2,0.4
			l0.6-0.3l0.2-0.3L28,197l0.1-0.3l0.2,0.3l0.1-0.2v-0.1 M80.3,196.7L80.3,196.7l-0.2,0.2l-0.2-0.2l-0.1,0.4l-0.2-0.2v0.5h-0.2
			l-0.1-0.1H79l-0.2-0.2 M29.8,196.7v0.5l0,0l-0.1,0.1l-0.1-0.4l-0.2,0.2l-0.2-0.4l-0.2,0.2l-0.3-0.2l-0.2,0.3l-0.3,0.1l0.2-0.3
			v-0.1 M35.6,197.4l-0.2,0.5l-0.2,0.5l0.1-0.7l-0.3,0.2l-0.2-1l-0.3,0.2l-0.4-0.6l-0.2,0.3l-0.5-0.3l-0.1,0.3l-0.4,0.1l-0.1-0.3
			l-0.5,0.4l-0.4-0.4l-0.2,1h-0.3V197l-0.3,0.2l-0.2-0.3l-0.2,0.1l-0.3-0.1l-0.2,0.2h-0.3l0.2-0.2l0.1-0.4 M78.8,196.9l-0.3,0.2
			l-0.2-0.2l-0.2,0.4l-0.3-0.2l-0.1,0.6h-0.3l-0.2-1l-0.3,0.4l-0.5-0.4v0.3l-0.5-0.1l-0.1-0.3l-0.5,0.2l-0.2-0.3l-0.4,0.6l-0.3-0.2
			l-0.2,1l-0.3-0.2l0.2,0.8l-0.3-0.5l-0.2-0.5l-0.1-0.2l-0.2-0.5 M81.9,196.7L81.9,196.7v-0.1"
        />
        <path
          class="st0"
          d="M28.5,196.8L28.5,196.8l-0.2-0.3 M81.9,196.9L81.9,196.9l0.2-0.2l0.1,0.4l0.3-0.3l0.1,0.4l0.3-0.1v0.2
			l0.2,0.1v-0.2v-0.1 M27.1,196.9l0.1-0.1 M29.9,197L29.9,197l0.2-0.2 M79,197L79,197l-0.2-0.2 M28.4,197l-0.1,0.1l-0.1-0.2
			 M27,197.1v0.1l-0.1-0.2l-0.1,0.4l-0.3-0.3l-0.1,0.2l-0.3-0.1v0.2h-0.2v-0.2l-0.2,0.2v-0.1l-0.1,0.1l0.1-0.5 M80.7,196.9l0.1,0.2
			h-0.3l-0.2-0.3 M82,197l0.1,0.2L82,197L82,197 M74,198.4l0.1,0.2h0.1l-0.1-0.8l0.3,0.1l0.2-0.8l0.2,0.3l0.4-0.5l0.1,0.3l0.5-0.2
			l0.1,0.2l0.4,0.1V197l-0.1-0.1 M35.3,198.4l-0.1,0.2l-0.1,0.1l0.1-0.8l-0.3,0.1l-0.2-0.8l-0.2,0.3l-0.4-0.5l-0.1,0.3l-0.5-0.2
			l-0.1,0.2l-0.4,0.1l0.1-0.2 M83.2,196.6l0.2,0.6l-0.1-0.1l-0.1-0.2 M28.3,197l-0.1,0.2l-0.1-0.2l-0.1,0.3l-0.2-0.2l-0.2,0.3
			l-0.2-0.1l-0.1,0.3l-0.3,0.1l0.1-0.3l-0.2,0.2v-0.1l-0.1,0.1l-0.3-0.2v0.2l-0.2-0.1v0.4h-0.2v-0.4H26l-0.1-0.1l-0.1,0.2v-0.2
			 M29.7,197.2l-0.1,0.1l-0.1-0.4l-0.1,0.2l-0.2-0.3l-0.2,0.3l-0.3-0.1l-0.2,0.4h-0.3l0.1-0.4l-0.1,0.1l-0.2,0.2v-0.1l-0.2,0.4
			l-0.2-0.3l-0.2,0.3l-0.3-0.2l-0.1,0.2l-0.2,0.1l0.1-0.2l-0.2,0.2v-0.2h0.2v-0.1 M79,197.1L79,197.1l-0.3,0.2l-0.2-0.2l-0.2,0.4
			l-0.2-0.2l-0.1,0.5l-0.3-0.1l0.1,0.6H78v-0.5l0.1,0.1l0.1-0.4l0.1,0.2l0.3-0.3l0.2,0.3l0.3-0.1L79,197.1 M79.6,197.3l0.1,0.1
			l0.1-0.4l0.1,0.2l0.2-0.3l0.2,0.3l0.2-0.1l0.2,0.4H81l-0.2-0.4l0.2,0.2l-0.1-0.1l-0.1-0.2 M29.9,197.1L29.9,197.1 M81,197.1
			l0.1-0.2l0.1,0.3l0.2-0.2l0.2,0.3l0.3-0.1l-0.2-0.3 M82.1,197.1L82.1,197.1h0.2l0.1,0.4l0.3-0.3l0.1,0.2l0.2-0.1v0.2l0,0l-0.2,0.1
			v-0.2l-0.3,0.2l-0.1-0.1v0.1l-0.2-0.3 M32.6,197.3l-0.1,0.2l0,0l-0.3,0.3l-0.1,0.1v-0.1v0.1L32,198l-0.1,0.1v-0.5l0.2,0.1l0.3-0.6
			L32.6,197.3l0.4-0.2l-0.2,0.2h0.3l0.1-0.2l0.5,0.2l0.1-0.2l0.4,0.5l0.2-0.2l0.2,0.7l0.2-0.1l-0.1,0.8H35l-0.2,0.3l0.3-0.3
			 M76.4,197.2l0.2,0.2l-0.5-0.1l-0.1-0.2l-0.5,0.2l-0.1-0.2l-0.4,0.5l-0.2-0.2l-0.2,0.7l-0.2-0.1v0.8h-0.3l0.2,0.3l-0.3-0.3
			 M31.1,197.2L31.1,197.2l-0.2,0.1l-0.2-0.4l-0.2,0.2l-0.4-0.2l-0.2,0.4l-0.3,0.1l0.1-0.3 M82.3,197.6l0.1,0.2l0.1,0.2h0.1l0,0
			v-0.4H82.3L82.3,197.6L82.3,197.6v-0.1l-0.2-0.2L82.3,197.6l-0.3-0.1l-0.2-0.3h0.3l-0.1-0.3 M76.6,197.2L76.6,197.2l0.2,0.2v-0.2
			L77,197l0.3,0.6l0.3-0.1v0.6l-0.1-0.1l-0.2-0.2v-0.1l-0.1,0.1l-0.3-0.4 M83.1,197.2l0.1,0.2L83.1,197.2 M32.6,197.3L32.6,197.3
			l-0.1,0.1l-0.1,0.1 M76.7,197.3l-0.4-0.3 M82.8,197.5H83v-0.2l0.2,0.1v-0.2 M80.9,197.2l0.1,0.1l0.1-0.1l0.2,0.4l0.2-0.3l0.2,0.3
			l0.2-0.2l0.1,0.2h0.2l-0.1-0.2l0,0 M29.5,197.4L29.5,197.4L29.5,197.4l-0.2-0.3l-0.2,0.3l-0.2-0.3l-0.2,0.4l-0.3-0.2l-0.1,0.4
			l-0.3,0.1l0.2-0.4v-0.1 M33.1,197.2l-0.1,0.2l0.5,0.2l0.1-0.2l0.4,0.5l0.1-0.2l0.2,0.5l-0.1,0.1v0.1l-0.1,0.1v0.5l0.3-0.4
			l-0.2,0.1l0.1-0.6h-0.1l-0.1,0.6v0.1v0.1L34,199h0.2l0.4-0.5h-0.2l0.1-0.7l-0.2,0.2l-0.2-0.6l-0.1,0.2l-0.4-0.5l-0.1,0.2
			L33.1,197.2 M76.7,197.4l0.1,0.2l-0.5-0.1l-0.2-0.2l-0.5,0.2l-0.1-0.2l-0.4,0.5l-0.1-0.2l-0.2,0.6l-0.2-0.2l0.1,0.7l-0.2-0.1
			l0.4,0.5l-0.3-0.1l-0.2-0.2 M81,197.5L81,197.5l0.1,0.4h-0.3l-0.2-0.4l-0.2,0.1l-0.2-0.4l-0.2,0.3l-0.2-0.3l-0.2,0.4l-0.1-0.1
			 M28.1,197.5l-0.1,0.2l-0.1,0.1l0,0l-0.2,0.3l-0.2-0.3l-0.1,0.2l-0.3-0.1l-0.1,0.2h-0.2v-0.2l-0.2,0.1l0.1-0.1l0.1-0.2v-0.1
			l0.1-0.1l0.2-0.3 M79.7,197.7L79.7,197.7v0.1l-0.2-0.2l0.2,0.4l-0.3-0.1l-0.2-0.4h0.3l-0.1-0.3 M32.3,197.5L32.3,197.5
			 M32.1,197.8l0.3-0.2l-0.2,0.3l0.4-0.1l0.2-0.3l-0.5,0.1l0.2-0.2 M82.8,197.5v0.4H83v-0.4l0.1,0.1l0.1-0.1l0,0l0.1,0.1v-0.4
			 M29.5,197.6v0.2l0,0l0.2-0.2l-0.1,0.4l0.3-0.1l0.2-0.4l0.4,0.1l0.2-0.3l0.3,0.3l0.1-0.2l0.1,0.5l0.2-0.1l-0.1-0.5 M75,198.5v0.5
			l0,0l-0.2-0.2l0.1,0.1L75,198.5L75,198.5L75,198.5v0.2v0.5l-0.3-0.4h0.1v-0.6l0.1,0.1l0.2-0.6l0.1,0.1l0.4-0.4l0.1,0.2l0.5-0.2
			l0.2,0.3l0.5,0.1l-0.1-0.2h-0.1 M81,197.6l0.2,0.1L81,197.6 M28,197.7l0.2-0.1 M81.2,197.7v0.1h0.1l0.2,0.3l0.2-0.3l0.1,0.2
			l0.2-0.1l0.1,0.2h0.2l-0.1-0.2l0.2,0.2l0,0h-0.1l0,0 M26.8,197.8h-0.2v0.4h-0.2v0.4h-0.2H26v-0.1v-0.2l0.1-0.4 M28,198l-0.1,0.4
			l0.2-0.2l-0.1,0.3l0.2-0.1l0.1-0.4l0.3,0.2l0.1-0.4l0.2,0.2l0.2-0.3l0.2,0.3l0.1-0.1l0.1-0.2 M26,198.6l0.1,0.2v-0.2v-0.3H26
			l-0.1-0.1V198 M79.8,197.9l0.1,0.2l0.2-0.4l0.2,0.3l0.2-0.2l0.1,0.4l0.3-0.2l0.1,0.3l0.3,0.1l-0.1-0.4l0.2,0.2l-0.1-0.4 M31.5,198
			l-0.1,0.6h-0.2l0.1-0.5L31.5,198 M80.2,198.1L80.2,198.1l-0.2,0.4l-0.1-0.3v0.1v-0.2l-0.1-0.1 M83.3,197.8l0.1,0.3L83.3,197.8
			 M82.6,197.9l-0.1,0.2l-0.2-0.2 M31.1,198.2v0.2v0.3l-0.1,0.1l0.3-0.3h-0.1l0.1-0.4L31.1,198.2l-0.1-0.4l-0.2,0.3l-0.3-0.3
			l-0.2,0.5l-0.4-0.2l-0.2,0.4l-0.3,0.1l0.2-0.3l0.1-0.1 M77.3,198.1L77.3,198.1l-0.3-0.3 M79.8,198.2L79.8,198.2l0.2,0.5l-0.4-0.1
			l-0.2-0.5l-0.3,0.2l-0.2-0.5l-0.3,0.3l-0.2-0.4l-0.2,0.4l-0.1-0.2v0.4l-0.1-0.1l0.2,0.2l-0.1-0.5l0.2,0.3l0.2-0.4l0.2,0.4l0.3-0.3
			l0.2,0.4l0.3-0.2l0.2,0.4l0.3,0.1L79.8,198.2l0.2,0.3l-0.1-0.1l0.2-0.2v-0.1l-0.2-0.3 M73.8,198.1l-0.2-0.3l-0.1-0.4 M35.5,198.1
			l0.2-0.3l0.1-0.5 M29.5,198l-0.1,0.2L29.5,198 M82.8,198.2H83v0.4h0.2v0.2h0.1l0.1-0.2h-0.2v-0.3h0.2v0.3l0.1-0.1v-0.3l-0.1,0.1
			l-0.1-0.4 M32.1,198.2L32.1,198.2 M77.4,198.4l-0.3-0.2l-0.1-0.1 M31.9,198.4l0.3-0.2l0.1-0.1 M34.3,198.7l-0.3-0.5l-0.1,0.2
			l-0.4-0.5l-0.2,0.3l-0.6-0.2l-0.2,0.4l-0.5,0.1l0.2-0.3 M77.1,198.2l0.2,0.3h-0.5l-0.3-0.4l-0.5,0.2l-0.2-0.3l-0.4,0.5l-0.1-0.3
			l-0.3,0.5 M26.6,198.3v0.3l0.1-0.3v0.1l-0.1,0.4h0.2l0.1-0.5h-0.2v-0.1L26.6,198.3 M27.1,198.3L27.1,198.3v0.5l0.3,0.1v-0.5h0.2
			h0.2v0.2l0.1-0.2v-0.1h-0.1v-0.1l-0.1,0.1l-0.3-0.2l-0.1,0.2H27.1 M82.4,198.3v0.5h-0.3v-0.5l-0.2,0.1l-0.1-0.2l-0.2,0.2l-0.1-0.1
			l-0.1,0.1l0,0 M29.5,198.4l-0.2-0.2l0.1-0.2l-0.1,0.2l-0.2-0.3l-0.1,0.5l-0.2-0.3l-0.1,0.4l-0.3-0.2l-0.1,0.2l-0.3,0.1l0.1-0.2
			l0.1-0.1 M79.7,198.1l0.1,0.1L79.7,198.1 M82.5,198.1l0.1,0.4v0.1h-0.2v0.4h-0.3v-0.4h-0.3v-0.5l-0.2,0.1h-0.2v0.3l-0.1-0.1v-0.1
			l0,0 M80,198.2v0.1V198.2L80,198.2L80,198.2 M80.3,198.2l0.2-0.2l0.1,0.4l0.3-0.2l0.1,0.2h0.3l-0.1-0.2l0,0 M25.9,198.2l-0.1-0.3
			 M77.5,198.2l0.2,0.2v0.1l-0.3-0.2l-0.1-0.1 M29.3,198.4L29.3,198.4l-0.2,0.4l0.3-0.1l0.2-0.4l0.4,0.2l0.2-0.4l0.3,0.2l0.2-0.4
			l0.2,0.3v0.1v0.3v-0.1H31l0.3-0.3l0.2,0.1l0,0l0.3-0.2l0.1-0.1 M27.4,198.1l0.1,0.4l0.1-0.2l0.1-0.2 M30.7,198.2l0.1-0.1
			 M29.3,198.2L29.3,198.2L29.3,198.2 M81.7,198.1l-0.1,0.4l-0.1-0.2l-0.1-0.2 M31.9,198.3l-0.2,0.3h0.5h0.1l0.3-0.3l0.6,0.2
			l0.3-0.3l0.4,0.4l0.2-0.3l0.2,0.5l-0.1,0.3l-0.3-0.5l-0.2,0.4l-0.4-0.4l-0.3,0.3l-0.5-0.2 M77.3,198.3l0.2,0.3h-0.6l0,0l-0.3-0.3
			l-0.5,0.2l-0.3-0.3l-0.4,0.4l-0.2-0.3l-0.3,0.5l0.1,0.3l0.3-0.5l0.3,0.4l0.4-0.4l0.3,0.3l0.5-0.2 M82.5,198l0.1,0.3L82.5,198
			 M26.3,198.3h0.2v0.1v0.1v-0.1l-0.1,0.1h0.1v0.4h0.1v-0.4H27v0.4h-0.3v0.2l-0.2-0.2 M82.6,198.4L82.6,198.4l0.2-0.1v0.2
			L82.6,198.4L82.6,198.4 M26.7,199.2l0.2,0.1V199h0.3v-0.4h0.3v0.1l0.3-0.1l-0.1,0.2H28l0.1-0.2l0.3,0.1l0.1-0.2l0.2,0.3l0.1-0.4
			l0.2,0.3l0.1-0.3 M31.5,198.4l0.2-0.2 M77.5,198.4l0.2-0.1l0.3,0.3h0.2l-0.1-0.1l-0.1-0.2 M30.8,198.5l0.1-0.1 M80.2,198.3
			l0.1,0.3l0.2-0.3l0.1,0.2l0.3-0.1l0.1,0.2h0.2v-0.1h-0.1 M26.2,198.4v0.2H26 M81.7,199.1v0.2h0.1L81.7,199.1l0.1-0.4h-0.3l0.1,0.2
			L81.7,199.1L81.7,199.1l0.1-0.1h0.3v0.2h0.2l0.1-0.2h0.2v-0.4l0,0 M82.5,198.6h0.2l0,0h0.2 M26,198.6h0.2 M27.7,198.4l-0.3,0.2
			l0.2-0.4 M81,198.4l0.2,0.1v-0.2 M79.9,198.6l-0.1-0.2 M27.3,198.6L27.3,198.6L27.3,198.6l0.2,0.1l-0.1,0.5h0.3v-0.6l0.3,0.1
			l0.1-0.2l0,0 M28.2,198.6l0.3,0.2v-0.1l0.1,0.1l0.2-0.2l0.1-0.1 M82.6,198.6h0.2 M27.3,198.8L27.3,198.8L27.3,198.8L27.3,198.8
			l0.1-0.1 M31.9,198.6l-0.3,0.2h-0.4l0.1-0.1l0.1-0.1 M76.7,198.5l0.3,0.2l0,0l0.5,0.1l-0.1-0.1l-0.2-0.1 M77.4,198.5l0.3,0.2
			l0.2-0.1H78l0,0v-0.3l0.2,0.4l0.2-0.4l0.2,0.5l0.3-0.3l0.3,0.4l0.3-0.2l0.3,0.3H80v-0.1l-0.2-0.1 M81.2,198.4l0.2,0.2v-0.1
			 M30.5,198.4l-0.2,0.3l-0.2-0.3l-0.2,0.5l-0.3-0.3l-0.2,0.4l-0.4-0.2l-0.3,0.3l-0.3,0.1l0.2-0.2l0.1-0.1 M81.5,199.2L81.5,199.2
			h-0.2l-0.1-0.6l0.2,0.1v-0.1l-0.2-0.1 M80.2,198.6L80.2,198.6v0.2l0.3-0.2l0.1,0.2l0.3-0.1v0.6h0.3v0.4l0.2-0.1v-0.4 M30.8,198.6
			l0.2,0.1l-0.1,0.1h-0.2h-0.2v-0.1L30.8,198.6 M77.6,198.7l0.2,0.1h0.3l-0.1-0.1 M31.2,198.7l0.4-0.3 M81.6,198.7L81.6,198.7
			l0.1,0.2 M77.8,198.8L77.8,198.8l0.2,0.1l-0.4-0.1l-0.3-0.2 M31.1,198.8l0.4-0.2 M30.9,198.8L30.9,198.8h0.4h0.1 M30.8,198.8
			l-0.1,0.2v-0.2v-0.2 M80.4,198.7l-0.1,0.1l-0.2-0.2 M28.9,198.8l-0.1,0.2l-0.1-0.2h-0.1l-0.3-0.1v0.5l-0.3-0.1v0.5h-0.3h-0.2
			l-0.2-0.5l0,0l0.1-0.2v-0.2 M80.3,199l0.2,0.1h0.1l0.1,0.1h0.1v-0.5l-0.2,0.1h-0.2L80.3,199v-0.2 M78.2,198.8l-0.4-0.2
			 M31.3,198.8l-0.2,0.1l0.5-0.1l0.3-0.2l0.6,0.2l0.3-0.2l0.3,0.5l0.4-0.3l0.3,0.5L34,199v-0.1 M78.1,198.8l-0.3-0.1 M30.8,199
			l-0.2,0.6h0.3l0.2-0.6H30.8l0.2-0.1 M34.2,198.9L34.2,198.9v0.1v-0.4 M77.2,198.7l-0.5,0.2l-0.3-0.2l-0.4,0.4l-0.4-0.3l-0.3,0.5
			l-0.3-0.4v0.5l-0.1-0.2v-0.1V199 M78.2,198.8l0.2,0.2L78.2,198.8l0.2,0.7h-0.5l-0.2-0.7l-0.5,0.2l-0.3-0.2l-0.5,0.3l-0.2-0.1
			l-0.3,0.5l-0.4-0.3v0.4l-0.3-0.3 M27.5,199.6l0.2,0.3v-0.2v-0.5h-0.3V199h-0.2l-0.1,0.2h0.1h0.2 M78.4,198.8l0.1,0.2L78.4,198.8
			l0.3,0.3l0.2-0.4l0.3,0.5l0.3-0.4l0.2,0.3l0.3-0.2l0.3,0.2h0.3l-0.1-0.1l0,0v-0.3 M28.5,198.8v0.3l0.2-0.1l0.2-0.1 M75,199
			l-0.1-0.1 M34.3,199.2l0.3-0.1l0.2-0.2 M34.1,199.2l-0.1,0.2l-0.2,0.3v-0.4l-0.4,0.3l-0.3-0.4l-0.2,0.1l-0.5-0.3l-0.3,0.2
			l-0.5-0.2l-0.1,0.7H31l-0.1,0.6h-0.2v-0.5 M78.3,199l0.3,0.6h-0.3l0.1,0.6h0.3v-0.5 M80.3,199l0.2,0.1L80.3,199 M80.5,199.1
			L80.5,199.1 M28.7,199.1l-0.2,0.2h-0.1l0.2-0.1L28.7,199.1 M30.6,198.8l-0.2,0.4l-0.2-0.3l-0.3,0.4l-0.2-0.4l-0.3,0.2l-0.4-0.2
			l-0.3,0.2h-0.3l-0.1,0.6l-0.2-0.1v-0.2v-0.3h0.2 M82.4,199.2l0.2-0.3 M30.4,199.2L30.4,199.2v0.2l0.2-0.5 M82.1,199.1h-0.3
			 M27.3,199.1l-0.2,0.1l-0.1-0.1h0.2 M33.7,199.7l-0.4,0.4v-0.5l-0.4,0.2l-0.1-0.6h-0.4L32,199l-0.1,0.6l-0.5-0.1l-0.1,0.6
			l-0.5-0.1l0.1,0.4l-0.4-0.4 M75.4,199.7l0.4,0.3v-0.4l0.4,0.2l0.1-0.5h0.4l0.4-0.2l0.1,0.7l0.6-0.1l0.1,0.6l0.5-0.1l-0.1,0.4
			l0.4-0.4 M81.7,199.5l-0.3,0.3l0.1-0.3h-0.3V199l-0.4,0.1v0.2l-0.1-0.3h-0.1l0.1,0.5h0.1v-0.2 M74.8,199.2H75 M30.4,199.2
			l-0.2,0.4l-0.2-0.4l-0.1,0.1l-0.4-0.3l-0.2,0.2l-0.4-0.1v0.6h-0.4l-0.1,0.4l-0.1-0.1v-0.2l0,0v-0.2l-0.4-0.1v0.3h0.3 M80.8,199.8
			L80.8,199.8h-0.3l-0.1-0.6l-0.4,0.2l-0.2-0.2l-0.3,0.3l-0.2-0.1l-0.2,0.3l-0.2-0.5v0.3l-0.2-0.5 M34,198.9v0.4 M30.5,199.3
			l-0.2,0.4v-0.2l-0.2,0.2l-0.1-0.5h-0.2l-0.4-0.1l-0.1,0.6l-0.4-0.1l-0.1,0.5l-0.4-0.1l0.1,0.1l-0.2-0.2 M80.8,199.7v0.5l-0.3,0.1
			v-0.5l-0.5,0.1v-0.6l-0.3,0.1h-0.2l-0.1,0.5l-0.2-0.2v0.3l-0.2-0.4 M28.4,199.4l0.1-0.3h-0.2 M81.4,199.5l-0.1,0.3h-0.2v-0.2
			L81.4,199.5 M32.7,199.1v0.6l-0.6-0.1l-0.1,0.6l-0.6-0.1v0.4l-0.4-0.1l0.5,0.2l-0.1-0.2h0.4l0.1-0.3l0.5,0.1l0.1-0.5H33l-0.1,0.5
			l0.4-0.2h0.1 M76.8,199.1v0.6h-0.5l0.1,0.5L76,200l-0.1-0.1 M76,200l0.5,0.4l0.3,0.1l-0.4-0.4l0.5,0.1l-0.1-0.5l0.5-0.1l0.1,0.6
			l0.5-0.1v0.4l0.4-0.1l-0.1,0.1l-0.5,0.2l0.2-0.2h-0.4l-0.1-0.3l-0.6,0.1l0.3,0.4l-0.4-0.1 M30.2,199.9l-0.3,0.3v-0.3l-0.3,0.1
			l-0.5-0.1v0.5h-0.4l0.1,0.3l-0.2-0.1l-0.1-0.2 M81.5,199.9L81.5,199.9h-0.3h-0.1H81l0,0 M28.3,199.9H28l-0.3-0.1 M79.6,199.4v0.5
			l-0.3-0.1v0.3l-0.3-0.3 M29.1,200.6h0.2H29l-0.2-0.1L29.1,200.6l0.1-0.3h0.4v-0.4v-0.5 M79.3,200.1L79.3,200.1l0.4,0.2v-0.4h0.4
			v0.5l0.4-0.1l-0.1,0.3h-0.3l-0.1-0.2h-0.4l0.2,0.2l-0.2-0.1L79.3,200.1 M81.2,199.9l0.2-0.1 M81,200.1l-0.3,0.3l0.1-0.2L81,200.1
			v-0.2 M29.9,200.2l-0.2,0.2l-0.2,0.1l0.2-0.2L29.9,200.2L29.9,200.2 M31.8,200.5l0.3,0.1l0,0l0.4-0.1l0.3-0.3h-0.5l-0.3,0.4
			l-0.4,0.1h-0.2 M33.3,200.1l-0.4,0.3l-0.4,0.2 M80,200.6h-0.3l0.5,0.1h0.1l0.3-0.1 M29.3,200.6h0.1 M77,200.7L77,200.7l0.4-0.1
			 M77.1,200.7h0.6 M32.1,200.7l-0.5,0.1"
        />
      </g>
    </g>
    <path
      id="front-of-head"
      class="st1"
      d="M44.9,18.3c0.1-0.2,0.1-0.3,0.2-0.4c0.3-0.2,0.6,0.1,0.7,0c0.2-0.2-0.1-0.9-0.2-1.7
	c-0.1-0.5,0-0.7,0.1-1.9c0.2-2.7,0.1-2.4,0.2-3c0.4-2.1,1.8-3.5,2.1-3.9c0.3-0.3,1-1,2.2-1.6c1.8-0.9,3.4-0.9,4.4-0.9
	c1.5,0,2.7,0.3,3.2,0.5c0.9,0.3,1.9,0.6,2.9,1.4C61.5,7.5,62,8.3,62.3,9c0.6,1.1,0.8,2,0.9,2.2c0.3,1.1,0.3,2,0.3,2.8
	c0,0.9-0.1,1.6-0.1,1.8c-0.1,0.5-0.2,1.4-0.2,1.4c0,0.3,0,0.5,0.1,0.5c0.2,0.2,0.5-0.1,0.8,0.1c0.1,0.1,0.2,0.3,0.3,0.5
	c0,0,0.1,0.1,0.1,0.2c0.1,0.3-0.1,0.7-0.3,2.2c-0.1,0.8-0.1,1.1-0.3,1.3c-0.4,0.5-1.2,0.4-1.4,0.4c-2.5-0.2-5.1,0-7.6-0.1
	c-4.7-0.2-7-0.2-8.2,0.1c-0.2,0.1-1,0.3-1.4-0.1c-0.2-0.2-0.3-0.6-0.3-1.4c0-0.6,0-0.5,0-1.2C44.8,19.3,44.7,18.8,44.9,18.3z"
    />
    <path
      id="left-jaw"
      class="st1"
      d="M54.8,32.1c0-3.2,0-6.5-0.1-9.7h-9.4c0,0.3,0,0.5,0.1,0.8c0.1,0.4,0.2,0.8,0.4,1.2l0.5,0.1
	l0.4,0.5l0.6,2.4c0.1,0.3,0.2,0.8,0.7,1.3c0.3,0.3,0.6,0.4,0.8,0.5c0.4,0.4,1,0.8,1.7,1.2c0.8,0.4,1.5,0.7,2,0.9
	c0.4,0.1,0.8,0.3,1.3,0.4C54.2,31.8,54.5,31.9,54.8,32.1z"
    />
    <path
      id="right-jaw"
      class="st1"
      d="M54.7,32c0-3.2,0-6.5,0.1-9.7h9.1c0,0.3,0,0.5-0.1,0.8c-0.1,0.4-0.2,0.8-0.3,1.2L63,24.4
	l-0.4,0.5L62,27.3c-0.1,0.3-0.2,0.8-0.7,1.3c-0.3,0.3-0.6,0.4-0.8,0.5c-0.4,0.4-1,0.8-1.6,1.2c-0.7,0.4-1.4,0.7-2,0.9
	c-0.4,0.1-0.8,0.3-1.2,0.4C55.4,31.8,55,31.9,54.7,32z"
    />
    <path
      id="front-of-neck"
      class="st1"
      d="M47.4,34.7c0.2,0.4,0.9,0.8,2.2,1.6c1.1,0.7,1.8,1,3,1.6s1.5,0.7,2,0.7
	c0.4,0,0.8-0.1,1.9-0.6c0.6-0.3,1.3-0.7,2.8-1.5c2.5-1.4,1.5-0.9,1.6-1c0.5-0.2,0.9-0.4,1-0.8c0-0.1,0-0.2-0.1-0.7
	c0-0.2,0-0.3,0-0.5c0-0.7-0.1-1.7-0.1-2.4c-0.1-2.2,0.1-2.8-0.2-2.9c-0.4-0.2-0.9,0.5-1.8,1.2c-1,0.8-2.9,2.4-4.9,2.5
	c-1.1,0-2.1-0.5-4-1.5s-2.8-1.9-3.3-1.6c-0.4,0.2,0,0.9-0.1,2.6C47.5,33.3,46.9,33.8,47.4,34.7z"
    />
    <path
      id="front-of-left-shoulder"
      class="st1"
      d="M25.5,53.6c-0.2-0.4-0.2-0.8,1-4.1c1.4-3.8,1.8-4.5,2.4-5.2
	c0.8-0.8,1.5-1.4,2.2-1.8c0.3-0.2,1.4-0.9,3.3-1.7c2.5-1,2.7-0.6,4.9-1.6c0.7-0.3,0.5-0.3,3-1.5c1.7-0.8,1.8-0.8,2.3-1.2
	c1.4-0.9,1.6-1.4,2.5-1.6c0.8-0.1,1.5,0.3,2.7,1c4.2,2.6,4.5,2.4,4.7,2.9c0.4,1.7-3.4,2.7-9.4,8.3c-2.1,1.9-4.3,3.7-6.3,5.7
	c-1,1.1-1.8,1.9-3.3,2.4c-1.1,0.3-1.8,0.2-5.2-0.4C26.8,54.1,26,54.3,25.5,53.6z"
    />
    <path
      id="front-of-right-shoulder"
      class="st1"
      d="M83.2,53.3c0.5-0.7,0.2-1.7-0.4-3.6c-0.7-2.5-2-4.7-3.1-5.8c-1-1-2.2-1.8-2.2-1.8
	l0,0l-3.3-1.7C72,39.6,71.9,40,70,39.3c-1.3-0.5-1-0.6-3.7-2c-1.5-0.7-1.6-0.7-2.3-1.2c-1.4-0.9-1.7-1.5-2.5-1.6s-1.4,0.4-2.8,1.3
	c-3.6,2.5-4.1,2.2-4.2,2.9c-0.4,1.7,3.5,2.7,9,7.9c0.6,0.6,3,2.9,6.3,5.7c1.6,1.3,2.6,2.2,4.3,2.5c1.1,0.2,2.3,0,4.7-0.5
	C82.3,53.9,82.9,53.9,83.2,53.3z"
    />
    <path
      id="chest"
      class="st1"
      d="M35.9,64c0.5,0.4,1.2,0.3,1.4,0.3c1.6-0.2,6.9-0.2,17.5,0c5.9,0.1,11.9-0.2,17.8,0
	c0.1,0,0.6,0,0.9-0.3c0.5-0.4,0.4-1.2,0.2-2.9c-0.3-3.5-0.1-4,0.2-4.4c0.2-0.3,0.5-0.6,0.5-1c0-0.5-0.4-0.8-0.8-1.1
	c-0.7-0.6-1.4-1.3-2.2-1.8c-1-0.6-3.1-2.3-7.2-5.8c-6-5-7.2-7.9-9.7-7.5c-0.8,0.1-1.2,0.5-2.3,1.4c-4.5,3.7-6.6,5.8-8.2,7.2
	c-5,4.2-9,5.3-8.8,7.7c0.1,0.6,0.4,0.7,0.5,1.9c0.2,1.2,0,1.8-0.1,4.3C35.5,63.2,35.5,63.7,35.9,64z"
    />
    <path
      id="abdomen"
      class="st1"
      d="M37.2,92.8c0.4,0.5,1.4,0.4,1.7,0.3C40.5,93,45.3,93,55,93c13.5,0,14.9,0.2,14.9,0.2
	c0.9,0.1,1.6-0.1,1.8-0.3c0.7-0.6-0.1-2.3-0.5-4.3c-0.5-2.4,0-2.8-0.2-6.3c-0.3-4.2-1-4.1-1-6.5c0-5.8,4.2-10.1,3.1-11.2
	c-0.7-0.7-1.8-0.6-2.3-0.6c0,0-2.3,0.1-16.1,0.3C42.7,64.4,39,64,39,64c-0.6-0.1-2.5-0.3-3,0.6c-0.3,0.6,0.1,1.5,0.4,1.9
	c1.9,3.6,2.6,7.8,2.8,9.3c0.2,2.4-0.7,1.7-0.9,6.1c-0.2,4.2,0.5,4.9-0.3,7.4C37.4,90.8,36.6,92.1,37.2,92.8z"
    />
    <path
      id="front-of-left-hip"
      class="st1"
      d="M34,113.2c0.2,0.3,0.7,0.3,0.9,0.2c2.6-0.2,5.3,0.1,7.9-0.1c0,0,2.6-0.2,7.2-0.1
	c1.4,0,2.1,0.1,3,0.3c0.8,0.2,1.2,0.5,1.5,0.3c0.4-0.3,0.3-1,0.2-2.5c-0.1-0.9,0-1.8,0-2.7c-0.1-1.8-0.1-3.7-0.1-4.8
	c0-1.8,0.1-6.6,0.3-8.3c0.1-0.6-0.1-1.2,0-1.7c0-0.2,0-0.5-0.2-0.7c-0.3-0.3-0.7-0.2-0.8-0.2c-2.6,0.1-5.1-0.1-7.7,0.1
	c-3.3,0.2-6.8,0-7.3,0c-0.2,0-0.5,0-0.7,0c-0.7,0-0.8-0.1-0.9,0c-0.2,0.2-0.2,0.5-0.2,1s-0.2,1-0.2,1.5c-0.1,0.8-0.8,5.2-1.9,10.4
	c-0.5,2.2-0.2,4.5-0.8,6.7C34,112.5,33.8,112.9,34,113.2z"
    />
    <path
      id="front-of-right-hip"
      class="st1"
      d="M74.9,113.1c-0.2,0.2-0.4,0.2-0.5,0.2c-2.7-0.1-5.4,0.2-8,0c-0.2,0-0.6-0.1-1.1-0.1
	c-0.9,0-1.6,0-6.1,0c-1,0-1.7,0-2.7,0.2c-1,0.3-1.2,0.5-1.5,0.4c-0.7-0.3-0.3-1.9-0.2-4.7c0-2-0.1-1.6-0.2-5c0-2.7,0.1-2.2,0-4.3
	c0-1.7,0.2-3.4,0-5.1c-0.1-0.4-0.2-1.5,0.3-1.8c0.2-0.2,0.5-0.1,0.6-0.1c2.5,0.5,5.1-0.1,7.7,0.1c3.3,0.2,6.8,0,7.3,0
	c0.2,0,0.5,0,0.7,0c0.7,0,0.8-0.1,0.9,0c0.2,0.2,0.2,0.5,0.2,1s0.2,1,0.2,1.5c0.2,1.6,1.2,5.9,1.9,10.4c0,0,0.5,3,0.5,4.8
	c0,0.6-0.1,0.9,0,1.5C74.9,112.5,75.1,112.9,74.9,113.1z"
    />
    <path
      id="front-of-left-leg"
      class="st1"
      d="M36.7,139c0.6,1,2.3,0.6,5.5,0.6c3.9,0,5.6,0.7,6.3-0.3c0.2-0.3,0.1-0.4,0.3-2.1
	c0,0,0.1-0.5,0.4-2.6c0.7-4.5,2.7-12.4,2.7-12.4c1.4-5.5,2.2-7.9,1.1-8.8c-0.4-0.4-1-0.3-1.7-0.3c-4.2,0.1-7.6,0.1-8.1,0.1
	c-4.9,0-7.4,0-7.6,0c-0.3,0-1.2-0.2-1.6,0.3c-0.2,0.3-0.1,0.6-0.1,0.9c0.1,0.9,0.1,1.8,0.2,2.7c0,0.1,0.1,1.2,0.3,3.3
	c0.5,6.8,0.5,6.7,0.5,6.8c0.5,3.6,1.5,5.6,1.5,7.9c0,0.9,0,1.3,0.1,2.1C36.6,138.3,36.6,138.8,36.7,139z"
    />
    <path
      id="front-of-right-leg"
      class="st1"
      d="M72.4,139c-0.6,1-2.3,0.6-5.5,0.6c-3.9,0-5.6,0.7-6.3-0.3c-0.2-0.3-0.1-0.4-0.3-2.1
	c0,0-0.1-0.5-0.4-2.6c-0.6-3.8-2.8-12-2.8-12c-0.7-2.5-0.5-5.2-1.1-7.7c-0.1-0.3-0.3-1.1,0.2-1.5c0.5-0.5,1.4-0.4,1.7-0.3
	c2.7,0.4,5.4,0,8.1,0.1c3.4,0.2,5.5,0.1,6.3,0c0.6-0.1,1.1,0,1.7-0.1s0.8-0.2,1,0c0.3,0.2,0.3,0.8,0.2,1.8c-0.1,1.9,0.2-1.8-0.1,2.1
	c-0.1,1.9-0.3,3.3-0.3,3.3c-0.5,6.8-0.5,6.7-0.5,6.8c-0.5,3.6-1.5,5.6-1.5,7.9c0,0.9,0,1.3-0.1,2.1C72.6,138.3,72.5,138.8,72.4,139z
	"
    />
    <path
      id="left-knee"
      class="st1"
      d="M33.9,155.8c0.4,0.5,1.1,0.4,2.6,0.3c1.6-0.1-6.4,0.3,3.2,0c3.3-0.1,4.6,0.4,5.2-0.5
	c0.1-0.2,0.2-0.4,0.2-0.8c0.2-1.8,0.3-2.7,0.2-4.4c-0.1-1.2,0.5-2.8,1.7-6.1c0.9-2.3,1.9-4.6,1.5-4.9c-0.1-0.1-0.3,0.1-0.9,0.2
	c-0.6,0.1-1,0.1-1.3,0c-1.3-0.1-3.2-0.1-3.8-0.1c-2.2,0-3.3,0-3.7,0.1c-0.5,0-1.3,0.1-1.9-0.4c-0.3-0.3-0.4-0.6-0.5-0.5
	c-0.3,0-0.3,1.2-0.3,1.8c0,0,0,0.3-0.2,4c-0.1,1.9-0.5,3.3-1.1,5.2c0,0.1-0.4,1.5-1.1,4.3C33.7,154.8,33.6,155.4,33.9,155.8z"
    />
    <path
      id="right-knee"
      class="st1"
      d="M75.1,155.6c-0.4,0.5-1.1,0.4-2.6,0.3c-1.6-0.1,6.4,0.3-3.2,0c-3.3-0.1-4.6,0.4-5.2-0.5
	c-0.1-0.2-0.2-0.4-0.2-0.8c-0.2-1.8-0.3-2.7-0.2-4.4c0.1-1.2-0.5-2.8-1.7-6.1c-0.9-2.3-1.9-4.6-1.5-4.9c0.1-0.1,0.3,0.1,0.9,0.2
	c0.6,0.1,1,0.1,1.3,0c1.3-0.1,3.2-0.1,3.8-0.1c2.2,0,3.3,0,3.7,0.1c0.5,0,1.3,0.1,1.9-0.4c0.3-0.3,0.4-0.6,0.5-0.5
	c0.3,0,0.3,1.2,0.3,1.8c0,0,0,0.3,0.2,4c0.1,1.9,0.5,3.3,1.1,5.2c0,0.1,0.4,1.5,1.1,4.3C75.3,154.6,75.5,155.2,75.1,155.6z"
    />
    <path
      id="left-shin"
      class="st1"
      d="M34.4,181.2c0.3,0.5,1.2,0.2,3.3,0.2c1.4,0,2,0.1,2.3-0.3c0.3-0.3,0.2-0.7,0.2-1.1
	c0-0.3,0,0.2,0.6-3.2c0.5-3,0.5-3.6,0.7-4.9c0.2-1.1,0.7-2.6,1.6-5.8c0,0,1.4-4.7,1.9-7.7c0.2-1.5-0.1-1.2,0.1-2.3
	c0-0.1,0.1-0.4-0.1-0.5c-0.2-0.2-0.8,0.1-1,0.2c-0.4,0.2-1.6,0.2-4.1,0.3c-2.2,0.1-3.4,0.1-3.7,0c-1.3-0.2-1.8-0.5-2.3-0.1
	s-0.4,1.2-0.3,2.8c0.1,2.4-0.2,0,0,3.6c0.3,5,0.7,6.1,0.8,9.8c0.1,2.3,0.1,5.9,0,6.9c0,0.2,0,0.7-0.1,1.5
	C34.3,181.1,34.3,181.2,34.4,181.2z"
    />
    <path
      id="right-shin"
      class="st1"
      d="M74.7,180.9c-0.2,0.5-1,0.2-3.3,0.2c-1.4,0-2,0.1-2.3-0.3c-0.3-0.3-0.2-0.7-0.2-1.1
	c0-0.3,0,0.2-0.6-3.2c-0.5-3-0.5-3.6-0.7-4.9c-0.2-1.1-0.7-2.6-1.6-5.8c0,0-1.4-4.7-1.9-7.7c-0.2-1.5,0.1-1.2-0.1-2.3
	c0-0.1-0.1-0.4,0.1-0.5c0.2-0.2,0.8,0.1,1,0.2c0.4,0.2,1.6,0.2,4.1,0.3c2.2,0.1,3.4,0.1,3.7,0c1.3-0.2,1.8-0.5,2.3-0.1
	s0.4,1.2,0.3,2.8c-0.1,2.3,0.2-0.9,0,3.6c-0.3,4.4-0.7,9.3-0.7,9.3c-0.1,1.5-0.2,2.3-0.2,3.5c0,1.5,0.2,1.6,0,2.6
	c-0.1,1-0.3,1.4-0.2,2.3C74.6,180.5,74.8,180.6,74.7,180.9z"
    />
    <path
      id="left-ankle-foot"
      class="st1"
      d="M27.1,194.6c0,0,0.9-1,2.5-2.8c1.3-1.4,2-2.7,2.5-3.8c0.9-1.7,1.3-2.6,1.5-3.9
	c0.2-1.3,0-1.8,0.5-2.4c0.5-0.5,1.4-0.5,3.1-0.5c1.4,0,2.1,0,2.5,0.4s0.5,1.3,0.5,3.1c0,1.2-0.1,1.4,0.1,2.1c0.2,0.9,0.4,1,0.6,1.9
	c0.2,0.9,0.1,1.6,0.1,1.9c-0.1,0.6-0.1,1.1-0.5,1.7c-0.1,0.2-0.4,0.6-1,0.9c-1,0.5-1.8,0-2.4,0.6c-0.1,0.1-0.2,0.2-0.3,0.4
	c-0.4,0.6-0.5,1.1-0.6,1.6c-0.3,1.2-0.4,1.7-0.5,1.9c-0.3,0.7-0.8,1.1-1.3,1.5c-0.2,0.2-0.6,0.5-1.1,0.8c-0.6,0.4-0.9,0.6-1.3,0.5
	c-0.4,0-0.8-0.2-1.2-0.4c-0.3-0.2-0.4-0.4-0.6-0.4c-0.3,0-0.3,0.3-0.6,0.5c-0.5,0.3-1.2-0.1-1.4-0.2c-0.2-0.1-0.4-0.2-0.7-0.5
	c-0.3-0.2-0.3-0.3-0.5-0.4c-0.7-0.5-0.8-0.4-1-0.7c-0.3-0.4-0.2-1-0.2-1.1c0-0.2,0.1-0.2,0.2-0.8c0.2-0.5,0.2-0.6,0.2-0.9
	C26.5,195.2,26.9,194.8,27.1,194.6z"
    />
    <path
      id="right-ankle-foot"
      class="st1"
      d="M81.9,194.6c-1-1.1-2.7-3.2-2.9-3.5c-0.6-0.7-1.4-1.7-2.1-3.1c-0.5-0.9-1.2-2.2-1.5-3.9
	c-0.4-1.9-0.1-2.6-0.7-3.1c-0.7-0.5-1.3,0.2-3.1,0c-1.5-0.2-2.1-0.8-2.5-0.4c-0.3,0.2-0.1,0.6-0.1,2.1c0,0.7,0,1.3-0.1,1.9
	c-0.1,0.7-0.1,1.2-0.2,1.8c-0.2,1.1-0.3,1.2-0.5,2.2c-0.1,0.6-0.2,1.2-0.1,1.9c0.1,0.6,0.1,1.1,0.5,1.7c0.1,0.2,0.4,0.6,1,0.9
	c1,0.5,1.8,0,2.4,0.6c0.1,0.1,0.2,0.2,0.3,0.4c0.4,0.6,0.5,1.1,0.6,1.6c0.2,0.8,0.5,1.4,0.7,1.7c0.3,0.6,0.6,1.1,1.1,1.7
	c0.4,0.4,0.8,0.7,1.1,0.8c0.4,0.3,0.8,0.5,1.3,0.5c0.4,0.1,0.9,0.2,1.3-0.2c0.3-0.3,0.2-0.6,0.5-0.6c0.3-0.1,0.4,0.6,1,0.7
	c0.4,0.1,0.8,0,1.1-0.2c0.2-0.2,0.2-0.3,0.6-0.7c0.2-0.2,0.4-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.5-0.3s0.2-0.1,0.5-0.3
	c0.4-0.2,0.4-0.2,0.5-0.2c0.2-0.2,0.1-0.6-0.1-1.2c-0.1-0.4-0.2-0.6-0.2-0.8c-0.1-0.2-0.2-0.4-0.3-0.7
	C82.4,195.2,82,194.7,81.9,194.6z"
    />
    <path
      id="front-of-left-arm"
      class="st1"
      d="M21.7,69.2c0.3,0.4,1,0.4,1.4,0.4c1.4,0,2.5,0.2,4.8,0.6c2.2,0.4,2.8,1,3.6,0.5
	c0.3-0.2,0.4-0.4,1-1.7c0.8-1.7,0.7-1.4,1.2-2.4c0.8-1.8,1.3-2.8,1.5-3.9c0.5-2.2,0.2-3.4,0.4-4.3c0-0.1,0.1-0.4,0-0.8
	c-0.1-0.2-0.2-0.3-0.2-0.4c-0.4-0.7,0.1-1.4-0.3-1.7c0,0,0,0-0.6-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-1.3-0.3-2-0.4-3.3-0.7
	C27.7,54,28,54.2,27.3,54c-0.8-0.3-1.4-0.7-1.8-0.4c-0.2,0.2-0.2,0.5-0.2,0.9c0,0.9-0.2,1.8-0.2,2.7c0,0.7-0.3,1.7-0.9,3.6
	c-0.5,1.6-1.2,3.8-1.9,5.5c-0.1,0.3-0.3,0.8-0.5,1.8C21.7,68.7,21.6,69,21.7,69.2z"
    />
    <path
      id="front-of-right-arm"
      class="st1"
      d="M87.3,69.1c-0.3,0.4-1,0.4-1.4,0.4c-1.4,0-2.5,0.2-4.8,0.6c-2.2,0.4-2.8,1-3.6,0.5
	c-0.3-0.2-0.4-0.4-1-1.7c-0.8-1.7-0.7-1.4-1.2-2.4c-0.8-1.8-1.3-2.8-1.5-3.9c-0.5-2.2-0.2-3.4-0.4-4.3c0-0.1-0.1-0.4,0-0.8
	c0.1-0.2,0.2-0.3,0.2-0.4c0.4-0.7-0.1-1.4,0.3-1.7c0,0,0,0,0.6-0.1c0.3-0.1,0.5-0.1,0.8-0.2c1.3-0.3,2-0.4,3.3-0.7
	c2.7-0.5,2.4-0.3,3.1-0.5c0.8-0.3,1.4-0.7,1.8-0.4c0.2,0.2,0.2,0.5,0.2,0.9c0,0.9,0.2,1.8,0.2,2.7c0,0.7,0.3,1.7,0.9,3.6
	c0.5,1.6,1.2,3.8,1.9,5.5c0.1,0.3,0.3,0.8,0.5,1.8C87.4,68.6,87.4,68.9,87.3,69.1z"
    />
    <path
      id="front-of-left-elbow"
      class="st1"
      d="M16,80c0.3,0.9,2.6,0.3,5.9,1.2c2.6,0.7,3.9,1.8,4.9,1.1c0.2-0.2,0.5-0.7,1-1.8
	c0.3-0.6,0.6-1.2,0.9-2c1.2-3.1,0.7-3.8,1.8-5.5c0.6-0.9,0.9-1.1,0.8-1.5c-0.2-0.7-1.2-0.8-4.9-1.5c-3.6-0.6-4.2-0.8-4.8-0.3
	c-0.8,0.6-0.3,1.3-1.3,3.2c-0.7,1.4-1.1,1.2-2.1,2.9c-0.6,1-0.5,1.1-1.2,2.4C16.1,79.5,15.9,79.7,16,80z"
    />
    <path
      id="front-of-right-elbow"
      class="st1"
      d="M92.9,79.7c-0.1,0.2-0.4,0.2-0.5,0.2c-1.8,0.2-3.5,0.7-5.2,1c-3,0.5-3.9,1.8-4.9,1.1
	c-0.2-0.2-0.5-0.7-1-1.8c-0.5-1.1-0.8-1.9-0.9-2c-1.2-3.1-0.9-3.4-1.8-5.1c-0.6-1.1-1-1.4-0.8-1.9c0.2-0.4,0.7-0.6,3.7-1.2
	c5-1,4.6-0.7,4.9-0.9c0.2-0.1,0.8-0.4,1.2-0.2c0.3,0.2,0.3,0.6,0.4,0.9c0.1,0.5,0.4,1.2,1.1,2.8c0.3,0.7,0.7,1.3,1.5,2.4
	s1.5,2,1.8,2.9c0.1,0.2,0.1,0.5,0.3,0.8C92.7,79.3,93,79.5,92.9,79.7z"
    />
    <path
      id="front-of-left-forearm"
      class="st1"
      d="M10.6,99.4c0.2,0.6,0.8,0.6,2.6,1.4s2.4,1.4,2.9,1.1c0.3-0.2,0.3-0.5,0.7-1.6
	c0.1-0.3,0.4-0.8,0.9-2c0.9-1.9,2.1-4,2.4-4.6c0.6-1.1,3.3-6,4.8-8.3c0.4-0.6,0.7-1.3,1.1-1.9c0.6-0.9,0.8-1.2,0.7-1.4
	c-0.2-0.4-0.9-0.4-2.2-0.7c-1.5-0.4-1.3-0.6-2.9-1c-1.3-0.3-1.1-0.1-2.9-0.5c-1.6-0.3-2.3-0.7-2.8-0.3c-0.2,0.2-0.2,0.3-0.4,1.1
	c-0.4,1.4-0.5,2.1-0.6,2.4c-0.3,2.1-1.3,4-2.4,8.6c-0.9,3.9-0.5,4-1.3,5.4C10.8,98,10.3,98.7,10.6,99.4z"
    />
    <path
      id="front-of-right-forearm"
      class="st1"
      d="M98.4,99.3c-0.1,0.7-1.2,0.9-2.8,1.8c-1.7,1-2.1,1.7-2.6,1.4
	c-0.4-0.2-0.3-0.9-0.8-2.3c-0.1-0.3-0.4-0.9-0.9-2c-0.8-1.7-1.8-3.6-2.2-4.3c0-0.1-0.1-0.1-0.1-0.2c-1-1.8-1.7-2.8-2.6-4.2
	c-1.9-3.1-1.5-2.6-2.3-3.8c-0.5-0.7-0.9-1.5-1.3-2.2c-0.5-0.9-0.7-1.2-0.6-1.5c0.2-0.3,0.8-0.2,2.4-0.6c0.5-0.1,0.8-0.2,0.9-0.3
	c0.6-0.2,0.8-0.4,1.9-0.7c0.9-0.2,1.1-0.2,1.7-0.3c0.5-0.1,0.8-0.1,1.2-0.2c1.5-0.3,2.3-0.5,2.6-0.2c0.3,0.3,0.2,0.6,0.5,1.7
	c0.2,0.7,0.4,1.2,0.7,1.6c0.4,0.9,1.5,4.5,2.4,8.6c0.2,0.9,0.3,1.3,0.5,2.2c0.2,0.9,0.4,1.1,0.5,1.9c0.1,0.7,0.1,0.8,0.3,1.4
	c0,0.2,0.1,0.3,0.4,1.3C98.3,99,98.4,99.2,98.4,99.3z"
    />
    <path
      id="front-of-left-wrist-hand"
      class="st1"
      d="M5,107.7c0.5-1.3,0.8-2.2,0.6-2.3c-0.1-0.1-0.3,0.2-0.8,0.4
	c-0.4,0.1-1.1,0.3-1.4-0.1c-0.2-0.2-0.2-0.7-0.2-0.7s0.5-0.4,0.8-0.8c0.2-0.3,0.3-0.7,0.4-1c0.1-0.4,0.1-0.5,0.3-0.7
	c0.2-0.2,0.3-0.1,0.7-0.3c0.3-0.1,0.5-0.3,0.9-0.5c0.4-0.3,0.5-0.4,0.8-0.6s0.5-0.4,1.4-0.7c1.2-0.4,1.8-0.6,2.2-0.6
	c0.9,0,0.9,0.4,2.4,1c1.8,0.7,2.7,0.5,3,1.1c0.1,0.2,0,0.4-0.2,1.5c-0.5,2-0.3,2.1-0.7,3.4c-0.2,0.8-0.2,0.5-0.6,1.7
	c-0.5,1.7-0.5,2-0.9,2.6c-0.3,0.6-0.5,0.7-0.6,1.2c0,0.2-0.1,0.4-0.1,1.6c0,0.7,0.1,1.3-0.1,2c-0.1,0.5-0.3,0.6-0.5,0.7
	c-0.3,0.1-0.6-0.1-0.6-0.2c-0.3-0.2-0.4-0.6-0.6-1.6c-0.2-1.4-0.2-2-0.5-2c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.3-0.2,0.7-0.3,1.4
	c0,0.6-0.1,0.4-0.1,0.9c0,0.9,0.1,1.2,0,1.9c-0.1,0.4-0.1,0.6-0.2,0.7c-0.3,0.3-0.7,0.4-1.1,0.2c-0.2-0.1-0.2-0.3-0.4-1
	c-0.3-1-0.3-0.9-0.3-1.2c-0.1-0.4,0-0.6,0.1-1.7c0.1-1,0.2-1.7,0.1-1.7c-0.1,0-0.4,0.8-0.6,1.6c-0.5,1.8-0.7,3.2-0.7,3.2
	c-0.1,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.8,0.4-1.1,0.2c-0.2-0.1-0.2-0.3-0.3-0.6c-0.2-0.9,0-1.5,0.1-2.4c0.1-0.8,0-0.6,0.1-1.3
	c0.1-0.7,0.2-1.4,0.5-2.1c0.2-0.4,0.4-0.7,0.3-0.7c0,0-0.4,0.5-0.7,1c-1,1.8-0.5,3.1-1.4,3.7c-0.1,0.1-0.5,0.3-0.9,0.2
	c0,0-0.4-0.1-0.6-0.4c-0.2-0.4,0-0.8,0.3-1.8c0.2-0.9,0.1-0.8,0.3-1.6c0.2-0.6,0.3-1,0.6-1.6C4.5,109.3,4.1,110.1,5,107.7z"
    />
    <path
      id="front-of-right-wrist-hand"
      class="st1"
      d="M104.1,107.8c-0.5-1.3-0.8-2.2-0.6-2.3c0.1-0.1,0.3,0.2,0.8,0.4
	c0.4,0.1,1.1,0.3,1.4-0.1c0.2-0.2,0.2-0.7,0.2-0.7s-0.5-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.4-1s-0.1-0.5-0.3-0.7
	c-0.1-0.1-0.1-0.1-0.5-0.3c-0.7-0.3-0.9-0.4-1-0.5c-0.2-0.1-0.3-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.1-0.2-0.1
	c-0.2-0.1-0.7-0.4-1-0.6c-0.8-0.4-1-0.1-1.8-0.5c-0.5-0.3-0.4-0.4-0.8-0.5c-0.7-0.2-1.2,0.5-2.3,1.2c-1.8,1.1-2.8,0.8-3.1,1.7
	c0,0.1-0.1,0.2,0.2,1.5c0.2,1.1,0.4,1.9,0.8,3.1c0.2,0.8,0.3,1.2,0.6,1.7c0.3,0.7,0.6,1.3,0.9,2.1c0,0.1,0.4,0.9,0.6,1.8
	c0,0.1,0,0.1,0,0.1c0,0.2,0.1,0.5,0,1.5c0,0.7-0.1,1.3,0.1,2c0.1,0.5,0.3,0.6,0.5,0.7c0.3,0.1,0.6-0.1,0.6-0.2
	c0.3-0.2,0.4-0.6,0.6-1.6c0.3-1.8,0.4-2,0.5-2s0.2,0.4,0.4,0.9c0.1,0.3,0.2,0.6,0.2,0.7c0.1,0.3,0.1,0.5,0.1,0.9
	c0,0.9-0.1,1.2,0,1.9c0.1,0.4,0.1,0.6,0.2,0.7c0.3,0.3,0.7,0.4,1.1,0.2c0.2-0.1,0.2-0.3,0.4-1c0.3-1,0.3-0.8,0.3-1.2
	c0.1-0.8,0.1-1.4,0-1.9c-0.1-0.9-0.3-1.5-0.3-1.5s0.3,0.7,0.6,1.6c0.5,1.8,0.7,3.2,0.7,3.2c0.1,0.4,0.1,0.8,0.4,1.1
	c0.3,0.3,0.8,0.4,1.1,0.2c0.2-0.1,0.2-0.3,0.3-0.6c0.2-0.9,0-1.5-0.1-2.4c-0.1-0.8,0-0.6-0.1-1.3c-0.1-0.7-0.2-1.4-0.5-2.1
	c-0.2-0.4-0.4-0.7-0.3-0.7c0,0,0.4,0.5,0.7,1c1,1.8,0.5,3.1,1.4,3.7c0.1,0.1,0.5,0.3,0.9,0.2c0,0,0.4-0.1,0.6-0.4
	c0.2-0.4,0-0.8-0.3-1.8c-0.2-0.9-0.1-0.8-0.3-1.6c-0.2-0.6-0.3-1-0.6-1.6C104.7,109.4,105,110.2,104.1,107.8z"
    />
    <g id="BODY_LINES">
      <line class="st2" x1="35.9" y1="64" x2="73.4" y2="64" />
      <line class="st3" x1="47.8" y1="28.3" x2="54.9" y2="32.1" />
      <line class="st3" x1="61.6" y1="27.4" x2="55.1" y2="31.5" />
      <line class="st3" x1="47.4" y1="34.3" x2="54.6" y2="39.1" />
      <line class="st3" x1="61.5" y1="34.3" x2="54.4" y2="39" />
      <line class="st4" x1="54.6" y1="39.1" x2="35.6" y2="55.2" />
      <line class="st4" x1="54.6" y1="39.1" x2="74.6" y2="55.3" />
      <line class="st5" x1="37.3" y1="92.8" x2="71.7" y2="92.8" />
      <line class="st2" x1="37.3" y1="92.8" x2="71.7" y2="92.8" />
      <line class="st2" x1="34" y1="113.2" x2="74.8" y2="113.2" />
      <line class="st2" x1="54.7" y1="92.9" x2="54.7" y2="113.2" />
      <line class="st2" x1="37.2" y1="139.5" x2="49" y2="139.4" />
      <line class="st2" x1="61.6" y1="139.6" x2="73.4" y2="139.6" />
      <line class="st6" x1="33.9" y1="155.8" x2="44.9" y2="155.8" />
      <line class="st6" x1="64.4" y1="155.8" x2="75.2" y2="155.8" />
      <line class="st2" x1="34.4" y1="181.1" x2="39.9" y2="181.1" />
      <line class="st2" x1="69.1" y1="181.1" x2="74.3" y2="181.1" />
      <line class="st2" x1="35.6" y1="55.2" x2="25.6" y2="53.7" />
      <line class="st2" x1="74.6" y1="55.3" x2="83.3" y2="53.5" />
      <line class="st2" x1="31.5" y1="70.8" x2="21.5" y2="69.1" />
      <line class="st2" x1="87.4" y1="69.1" x2="77.6" y2="70.8" />
      <line class="st2" x1="10.6" y1="99.5" x2="16" y2="101.9" />
      <line class="st2" x1="93.3" y1="102.5" x2="98.2" y2="99.5" />
      <line class="st2" x1="92.7" y1="80.1" x2="82.2" y2="82" />
      <line class="st2" x1="16" y1="80" x2="27" y2="82" />
      <line class="st3" x1="54.8" y1="22" x2="54.8" y2="32.1" />
      <line class="st3" x1="45.7" y1="22.3" x2="64.2" y2="22.3" />
      <line class="st2" x1="35.2" y1="55.1" x2="36" y2="64.5" />
      <line class="st2" x1="75" y1="55.6" x2="72.8" y2="64.4" />
    </g>
  </svg>
`;

export default postprocessBodySectionsSvg(BODY_SECTIONS_SVG);
