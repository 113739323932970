import '@polymer/paper-checkbox/paper-checkbox';
import '@polymer/iron-icon/iron-icon';
import '../neb-app-layout/neb-sortable-column';
import '../../src/components/misc/neb-icon';
import '../neb-styles/neb-icons';

import { format } from 'date-fns';
import { LitElement, html, css } from 'lit';

import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
  CSS_COLOR_GREY_1,
} from '../../src/styles/index';
import { ADD_ONS, hasAddOn } from '../../src/utils/add-ons';
import { getRTEStatusIcon } from '../../src/utils/real-time-eligibility';
import {
  SHORT_DATE_AND_TIME_FORMAT,
  SHORT_WEEKDAY_DATE_AND_TIME_FORMAT,
} from '../neb-input/nebFormatUtils';
import {
  openAppointmentPage,
  openRescheduledAppointmentPage,
} from '../neb-lit-components/src/utils/appointment-overlays-util';
import { renderNebButtonStyles } from '../neb-styles/neb-button-styles';
import { renderNebFormStyles } from '../neb-styles/neb-form-styles';
import { renderNebStyles } from '../neb-styles/neb-styles';
import { renderNebTableStyles } from '../neb-styles/neb-table-styles';
import { renderTypographyStyles } from '../neb-styles/neb-typography';
import { objToName, DEFAULT_NAME_OPTS } from '../neb-utils/formatters';

import { AppointmentColumn } from './neb-appointment-query-meta';

export const ELEMENTS = {
  rows: {
    selector: '.appt-links',
  },
  tableRows: {
    selector: '.neb-table-row',
  },
  iconRecurrence: {
    id: 'icon-recurrence',
  },
  header: {
    id: 'header',
  },
};

class NebAppointmentTable extends LitElement {
  static get properties() {
    return {
      data: {
        type: Array,
      },
      small: {
        type: Boolean,
      },
      emptyMessage: {
        type: String,
      },
      hash: {
        type: String,
      },
      columns: {
        type: Array,
      },
      cases: {
        type: Array,
      },
      locations: {
        type: Array,
      },
      rooms: {
        type: Array,
      },
      sort: {
        type: Object,
      },
      isFromCase: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return css`
      .rte {
        border-radius: 50%;
        height: 18px;
        width: 18px;
        background-color: var(--neb-color-grey-2);
        margin-top: 2px;
      }

      [name='confirmedCheck'] {
        line-height: normal;
        margin-top: -3px;
      }

      .noAppointmentsMessage {
        margin: 10px 0;
        font-style: italic;
      }

      :host([isFromCase]) .noAppointmentsMessage {
        margin: 18px 20px;
        font-style: italic;
      }

      :host([isFromCase]) .neb-table-header {
        background-color: white;
        position: sticky;
        top: 0px;
        z-index: 1;
      }

      .arrow {
        width: 15px;
        margin-top: 9px;
      }

      .add-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .date-and-time-container {
        display: flex;
        flex: 0 0 auto;
        white-space: nowrap;
      }

      .icon {
        padding: 2px 0 0 5px;
        height: 15px;
        width: 15px;
      }

      .icon-container {
        margin-top: 2px;
        padding-left: 5px;
      }

      .date-and-time-table {
        flex: 3;
        min-width: 155px;
      }

      .icon-status {
        display: block;
        width: 20px;
        height: 20px;
        fill: ${CSS_BANNER_ERROR_COLOR};
      }

      .icon-warning {
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_YELLOW};
      }

      .icon-validated,
      .icon-partially-validated {
        width: 20px;
        height: 20px;
        fill: ${CSS_BANNER_SUCCESS_COLOR};
      }

      .placeholder {
        color: ${CSS_COLOR_GREY_1};
        font-style: italic;
      }

      .status {
        white-space: nowrap;
      }

      [name='arrowIcon'] {
        height: 11px;
        width: 11px;
        color: var(--neb-color-grey-1);
        transform: rotate(0.5turn);
      }

      [name='viewAppointmentLink'],
      [name='viewAppointmentLink']:link,
      [name='viewAppointmentLink']:visited,
      [name='viewAppointmentLink']:active {
        text-decoration: underline;
        color: var(--neb-button-primary-focus-color);
      }

      [name='viewAppointmentLink']:hover {
        cursor: pointer;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.data = [];
    this.columns = [];
    this.sort = {};
    this.small = '';
    this.hash = '';
    this.layout = '';
    this.emptyMessage = 'No Appointments';
    this.isFromCase = false;

    this.onDismiss = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      viewAppointment: async appointment => {
        await this.__viewAppointment(appointment);
      },
      dismiss: () => this.onDismiss(),
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.addEventListener('neb-sort-order-changed', e =>
      this.__handleToggle(e),
    );

    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  /**
   * Iterates through all sort toggle components and resets them.
   * Any components which are marked default will be selected.
   */
  resetSort() {
    this.shadowRoot.querySelectorAll('neb-sortable-column').forEach(element => {
      element.deselect();

      if (element.isDefault) {
        element.reset();
      }
    });
  }

  __handleToggle(e) {
    this.shadowRoot.querySelectorAll('neb-sortable-column').forEach(element => {
      if (element !== e.detail.element) {
        element.deselect();
      }
    });
  }

  __manageSmallLayout() {
    if (this.small) {
      this.setAttribute('small', '');
    } else {
      this.removeAttribute('small');
    }
  }

  __isDefaultSort(sortField, sort) {
    return sort && sortField === sort.field;
  }

  __getDefaultOrder(sortField, sort) {
    return this.__isDefaultSort(sortField, sort) ? sort.dir : 'asc';
  }

  __getRoomName(appointmentId, resourceId, fromArray) {
    const appointment = this.data.find(
      appt => appt.id === appointmentId && appt.resourceId === resourceId,
    );

    if (appointment && appointment.splits && appointment.splits.length > 1) {
      return html`
        <div class="add-ellipsis">
          Split: ${appointment.splits.length} Rooms
        </div>
      `;
    }

    return resourceId
      ? this.__getName(resourceId, fromArray)
      : html`
          <span class="placeholder">No Room Scheduled<span> </span></span>
        `;
  }

  __getName(id, fromArray) {
    const item = fromArray.find(obj => obj.id === id);

    return item && item.name;
  }

  __hideColumn(column, columns) {
    return columns == null || !columns.includes(column);
  }

  async __viewAppointment({
    currentTarget: { appointmentId, isRescheduled, id },
  }) {
    if (isRescheduled) {
      await openRescheduledAppointmentPage(id, appointmentId);
    } else {
      await openAppointmentPage(appointmentId);
    }

    this.onDismiss();
  }

  __renderEligibilityStatusIcon(appointment) {
    return this.__hasCTVerify
      ? html`
          <div name="rte" class="neb-table-cell neb-table-small-width">
            <neb-icon
              class="icon-status icon-${appointment.realTimeEligibilityStatus}"
              .icon="${appointment.realTimeEligibilityStatus
                ? getRTEStatusIcon(appointment.realTimeEligibilityStatus)
                : ''}"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderEligibilityStatusHeader() {
    return this.__hasCTVerify
      ? html`
          <div id="rte" class="neb-table-cell neb-table-small-width">RTE</div>
        `
      : '';
  }

  __renderDateAndTimeHeaderColumn() {
    return html`
      <neb-sortable-column
        id="dateAndTime"
        sortField="start"
        .isDefault="${this.__isDefaultSort('start', this.sort)}"
        .defaultOrder="${this.__getDefaultOrder('start', this.sort)}"
        .selected="${this.__isDefaultSort('start', this.sort)}"
        .sortOrder="${this.__getDefaultOrder('start', this.sort)}"
        class="neb-table-cell neb-table-large-width date-and-time-table"
        ?hidden="${this.__hideColumn(
          AppointmentColumn.DATEANDTIME,
          this.columns,
        )}"
        >Date & Time</neb-sortable-column
      >
    `;
  }

  __renderConfirmedHeaderColumn() {
    return html`
      <div
        id="confirmed"
        class="neb-table-cell neb-table-medium-width"
        ?hidden="${this.__hideColumn(
          AppointmentColumn.CONFIRMED,
          this.columns,
        )}"
      >
        Confirmed
      </div>
    `;
  }

  __renderConfirmedColumn(appointment) {
    return html`
      <div
        name="confirmed"
        class="neb-table-cell neb-table-medium-width"
        ?hidden="${this.__hideColumn(
          AppointmentColumn.CONFIRMED,
          this.columns,
        )}"
      >
        <paper-checkbox
          name="confirmedCheck"
          class="neb-checkbox"
          .checked="${appointment.confirmed}"
        ></paper-checkbox>
      </div>
    `;
  }

  __renderDateAndTimeColumn(appointment) {
    return html`
      <div
        name="dateAndTime"
        class="date-and-time-container neb-table-cell date-and-time-table"
        ?hidden="${this.__hideColumn(
          AppointmentColumn.DATEANDTIME,
          this.columns,
        )}"
      >
        <div
          class="appt-links"
          name="viewAppointmentLink"
          .appointmentId="${appointment.appointmentId
            ? appointment.appointmentId
            : appointment.id}"
          .id="${appointment.appointmentId && appointment.id}"
          .isRescheduled="${appointment.isRescheduled}"
          @click="${this.__handlers.viewAppointment}"
        >
          ${format(appointment.start, SHORT_WEEKDAY_DATE_AND_TIME_FORMAT)}
          ${appointment.recurrenceEventId
            ? html`
                <neb-icon
                  id="${ELEMENTS.iconRecurrence.id}"
                  class="icon"
                  icon="neb:loop"
                ></neb-icon>
              `
            : ''}
        </div>
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.header.id}" class="neb-table-row neb-table-header">
        ${this.isFromCase
          ? html`
              ${this.__renderDateAndTimeHeaderColumn()}
              ${this.__renderEligibilityStatusHeader()}
              ${this.__renderConfirmedHeaderColumn()}
            `
          : html`
              ${this.__renderEligibilityStatusHeader()}
              ${this.__renderConfirmedHeaderColumn()}
              ${this.__renderDateAndTimeHeaderColumn()}
            `}
        <neb-sortable-column
          id="type"
          sortField="appointmentTypeName"
          .isDefault="${this.__isDefaultSort('appointmentTypeName', this.sort)}"
          .defaultOrder="${this.__getDefaultOrder(
            'appointmentTypeName',
            this.sort,
          )}"
          class="neb-table-cell neb-table-large-width"
          ?hidden="${this.__hideColumn(AppointmentColumn.TYPE, this.columns)}"
          >Type</neb-sortable-column
        >
        <div
          id="provider"
          class="neb-table-cell neb-table-large-width neb-hide-on-small"
          ?hidden="${this.__hideColumn(
            AppointmentColumn.PROVIDER,
            this.columns,
          )}"
        >
          Provider
        </div>

        <div
          id="location"
          class="neb-table-cell neb-table-large-width neb-hide-on-small"
          ?hidden="${this.__hideColumn(
            AppointmentColumn.LOCATION,
            this.columns,
          )}"
        >
          Location
        </div>

        <div
          id="room"
          class="neb-table-cell neb-table-large-width neb-hide-on-small"
          ?hidden="${this.__hideColumn(AppointmentColumn.ROOM, this.columns)}"
        >
          Room
        </div>

        <div
          id="case"
          class="neb-table-cell neb-table-large-width neb-hide-on-small"
          ?hidden="${this.__hideColumn(AppointmentColumn.CASE, this.columns)}"
        >
          Case
        </div>

        <neb-sortable-column
          id="status"
          sortField="status"
          .isDefault="${this.__isDefaultSort('status', this.sort)}"
          .defaultOrder="${this.__getDefaultOrder('status', this.sort)}"
          class="neb-table-cell neb-table-medium-width neb-hide-on-small"
          ?hidden="${this.__hideColumn(AppointmentColumn.STATUS, this.columns)}"
          >Status</neb-sortable-column
        >
        <div
          id="arrowHeader"
          class="arrow neb-table-align-end neb-hide-on-not-small"
        ></div>
      </div>
    `;
  }

  __renderTable() {
    return this.data && this.data.length > 0
      ? html`
          ${this.data.map(
            // eslint-disable-next-line complexity
            appointment => html`
              <div
                id="data_${appointment.id}"
                class="neb-table-row"
                dateandtime="${format(
                  appointment.start,
                  SHORT_DATE_AND_TIME_FORMAT,
                )}"
                type="${appointment.appointmentType
                  ? appointment.appointmentType.name
                  : ''}"
              >
                ${this.isFromCase
                  ? html`
                      ${this.__renderDateAndTimeColumn(appointment)}
                      ${this.__renderEligibilityStatusIcon(appointment)}
                      ${this.__renderConfirmedColumn(appointment)}
                    `
                  : html`
                      ${this.__renderEligibilityStatusIcon(appointment)}
                      ${this.__renderConfirmedColumn(appointment)}
                      ${this.__renderDateAndTimeColumn(appointment)}
                    `}
                <div
                  name="type"
                  class="neb-table-cell neb-table-large-width add-ellipsis"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.TYPE,
                    this.columns,
                  )}"
                >
                  ${appointment.appointmentTypeName
                    ? appointment.appointmentTypeName
                    : ''}
                </div>
                <div
                  name="provider"
                  class="add-ellipsis neb-table-cell neb-table-large-width neb-hide-on-small"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.PROVIDER,
                    this.columns,
                  )}"
                >
                  ${appointment.provider
                    ? objToName(appointment.provider, DEFAULT_NAME_OPTS)
                    : html`
                        <span class="placeholder"
                          >No Provider Scheduled<span> </span
                        ></span>
                      `}
                </div>
                <div
                  name="location"
                  class="add-ellipsis neb-table-cell neb-table-large-width neb-hide-on-small"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.LOCATION,
                    this.columns,
                  )}"
                >
                  ${appointment.locationId
                    ? this.__getName(appointment.locationId, this.locations)
                    : ''}
                </div>
                <div
                  name="room"
                  class="add-ellipsis neb-table-cell neb-table-large-width neb-hide-on-small"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.ROOM,
                    this.columns,
                  )}"
                >
                  ${this.__getRoomName(
                    appointment.id,
                    appointment.resourceId,
                    this.rooms,
                  )}
                </div>
                <div
                  name="case"
                  class="add-ellipsis neb-table-cell neb-table-large-width neb-hide-on-small"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.CASE,
                    this.columns,
                  )}"
                >
                  ${appointment.caseId
                    ? this.__getName(appointment.caseId, this.cases)
                    : ''}
                </div>
                <div
                  name="status"
                  class="neb-table-cell neb-table-medium-width neb-hide-on-small status"
                  ?hidden="${this.__hideColumn(
                    AppointmentColumn.STATUS,
                    this.columns,
                  )}"
                >
                  ${appointment.status
                    ? appointment.status
                    : appointment.action}
                </div>
                <div
                  name="arrowContainer"
                  class="arrow neb-table-align-end neb-hide-on-not-small"
                >
                  <iron-icon name="arrowIcon" icon="neb:back"></iron-icon>
                </div>
              </div>
            `,
          )}
        `
      : html`
          <div
            id="noAppointmentsMessage"
            class="noAppointmentsMessage neb-font-regular-secondary"
          >
            ${this.emptyMessage}
          </div>
        `;
  }

  render() {
    this.__manageSmallLayout();

    return html`
      ${renderNebStyles()} ${renderNebTableStyles()} ${renderNebFormStyles()}
      ${renderTypographyStyles()} ${renderNebButtonStyles()}
      ${this.__renderHeader()} ${this.__renderTable()}
    `;
  }
}

customElements.define('neb-appointment-table', NebAppointmentTable);
