import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../../../neb-styles/neb-variables';
import { formatAge } from '../../../../../../../neb-utils/formatters';
import { createModelNewPatientInsurance } from '../../../../../../../neb-utils/patientInsurance';
import {
  EMPTY_ADDRESS,
  policyPhoneNumberText,
} from '../../../../../../../neb-utils/policyHolder';
import '../../../../neb-header';

export const ELEMENTS = {
  noPolicyHolderContactInfoText: {
    id: 'no-contact-info-text',
  },
  policyHolderDateOfBirthText: {
    id: 'policy-holder-date-of-birth-text',
  },
  policyHolderNameText: {
    id: 'policy-holder-name-text',
  },
  policyHolderPhoneText: {
    id: 'policy-holder-phone-text',
  },
  policyHolderAddress1Text: {
    id: 'policy-holder-address1-text',
  },
  policyHolderAddress2Text: {
    id: 'policy-holder-address2-text',
  },
  policyHolderAddressStateText: {
    id: 'policy-holder-address-state-text',
  },
  policyHolderInsuredRelationshipText: {
    id: 'policy-holder-insured-relationship-text',
  },
  policyHolderMemberIdText: {
    id: 'policy-holder-member-id-text',
  },
};

class NebPatientInsurancePolicyHolderDetails extends LitElement {
  static get properties() {
    return {
      patientInsurance: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.patientInsurance = createModelNewPatientInsurance();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          padding-left: ${CSS_SPACING};
          display: flex;
          justify-content: flex-start;
          width: 70%;
        }

        .header {
          padding-top: ${CSS_SPACING};
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .flex-one {
          flex: 1;
        }

        .flex-two {
          flex: 2;
        }

        .top-padding {
          padding-top: ${CSS_SPACING};
        }

        .no-contact-info {
          font-style: italic;
        }

        .column {
          flex-direction: column;
        }
      `,
    ];
  }

  __renderDateOfBirth(dateOfBirth) {
    return dateOfBirth ? html` ${formatAge(dateOfBirth)} ` : '';
  }

  __renderAddress(address) {
    const addressFields = ['address1', 'address2', 'city', 'state', 'zipcode'];
    const isEmptyAddress = Object.keys(address).every(prop => {
      if (addressFields.includes(prop)) {
        return address[prop] === EMPTY_ADDRESS[prop];
      }
      return true;
    });
    const separator = address.city && address.state ? ', ' : ' ';
    return isEmptyAddress
      ? ''
      : html`
          <div id="${ELEMENTS.policyHolderAddress1Text.id}">
            ${address.address1}
          </div>
          <div id="${ELEMENTS.policyHolderAddress2Text.id}">
            ${address.address2}
          </div>
          <div id="${ELEMENTS.policyHolderAddressStateText.id}">
            ${address.city}${separator}${address.state} ${address.zipcode}
          </div>
        `;
  }

  __renderPhone(policyHolder) {
    return html` ${policyPhoneNumberText(policyHolder)} `;
  }

  __renderPolicyHolderInfo() {
    return html`
      <div id="${ELEMENTS.policyHolderDateOfBirthText.id}">
        ${this.__renderDateOfBirth(
          this.patientInsurance.policyHolder.dateOfBirth,
        )}
      </div>
      <div>
        ${this.__renderAddress(this.patientInsurance.policyHolder.address)}
      </div>
      <div id="${ELEMENTS.policyHolderPhoneText.id}">
        ${this.__renderPhone(this.patientInsurance.policyHolder)}
      </div>
    `;
  }

  render() {
    return html`
      <neb-header class="header" label="Policyholder"></neb-header>
      <div class="container">
        <div class="flex-one top-padding">
          <div class="bold" id="${ELEMENTS.policyHolderNameText.id}">
            ${this.patientInsurance.policyHolderNameLong}
          </div>
          ${this.__renderPolicyHolderInfo()}
        </div>

        <div class="column flex-one">
          <div class="flex-one top-padding">
            <div class="bold">Insured ID</div>
            <div id="${ELEMENTS.policyHolderMemberIdText.id}">
              ${this.patientInsurance.planInfo.memberIdentifier}
            </div>
          </div>
          <div class="flex-one top-padding">
            <div class="bold">Relation to Patient</div>
            <div id="${ELEMENTS.policyHolderInsuredRelationshipText.id}">
              ${this.patientInsurance.insuredRelationship}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-policy-holder-details',
  NebPatientInsurancePolicyHolderDetails,
);
