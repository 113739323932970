/* eslint-disable complexity */
const sort = (a, b) => {
  let primaryPayerComparison;

  if (a.primaryPayer === a.patient && b.primaryPayer !== b.patient) {
    primaryPayerComparison = -1;
  } else if (b.primaryPayer === a.patient && a.primaryPayer !== b.patient) {
    primaryPayerComparison = 1;
  }

  if (primaryPayerComparison !== undefined) {
    return primaryPayerComparison;
  }

  const guarantorComparison = a.guarantor.localeCompare(b.guarantor);

  if (guarantorComparison !== 0) {
    return guarantorComparison;
  }

  const caseComparison = a.case.localeCompare(b.case);

  if (caseComparison !== 0) {
    return caseComparison;
  }

  primaryPayerComparison = a.primaryPayer.localeCompare(b.primaryPayer);

  if (primaryPayerComparison !== 0) {
    return primaryPayerComparison;
  }

  const planComparison = a.plan.localeCompare(b.plan);

  if (planComparison !== 0) {
    return planComparison;
  }

  const secondaryPayerComparison = a.secondaryPayer.localeCompare(
    b.secondaryPayer,
  );

  if (secondaryPayerComparison !== 0) {
    return secondaryPayerComparison;
  }

  const invoiceComparison = a.invoiceNumber - b.invoiceNumber;

  if (invoiceComparison !== 0) {
    return invoiceComparison;
  }

  return 0;
};

const sortRollups = items => {
  const groupedItems = items.reduce((memo, item) => {
    if (!memo[item.patientId]) {
      memo[item.patientId] = {
        patient: item.patient,
        rollups: [],
      };
    }

    memo[item.patientId].rollups.push(item);

    return memo;
  }, {});

  const patientNames = Object.keys(groupedItems).reduce((memo, key) => {
    memo.push({ patientId: key, patient: groupedItems[key].patient });

    return memo;
  }, []);

  const sortedPatientNames = patientNames.sort((a, b) =>
    a.patient.localeCompare(b.patient),
  );

  return sortedPatientNames.reduce((memo, patientName) => {
    const { patientId } = patientName;

    const { rollups } = groupedItems[patientId];

    const sortedRollups = rollups.sort((a, b) => sort(a, b));

    sortedRollups.forEach(rollup => memo.push(rollup));

    return memo;
  }, []);
};

export default sortRollups;
