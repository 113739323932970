import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../packages/neb-lit-components/src/components/neb-header';

import { css, html, LitElement } from 'lit';

import { openWarning } from '../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { normalizeForSearch } from '../../../packages/neb-utils/formatters';
import { CollectionService } from '../../../packages/neb-utils/services/collection';
import { MODE } from '../../../packages/neb-utils/table';
import { baseStyles, CSS_SPACING, layoutStyles } from '../../styles';

export const ELEMENTS = {
  searchField: { id: 'search-field' },
  table: { id: 'table' },
  pagination: { id: 'pagination' },
};

export const NO_ASSOCIATED_RULE_SET_BANNER =
  'This rule is no longer associated to any rule sets for this payer';

const TABLE_CONFIG = [
  {
    key: 'ruleName',
    label: 'Rule Name',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'ruleSetName',
    label: 'Rule Set Name',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'field',
    label: 'Field',
    flex: css`1 0 0`,
    truncate: true,
  },
];

class NebViewAssociatedRuleSets extends LitElement {
  static get properties() {
    return {
      __currentPageIndex: Number,
      __pageCount: Number,
      __searchText: String,
      __ruleSets: Array,

      model: Array,
      names: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .grid-container {
          display: grid;
          grid-gap: ${CSS_SPACING};
        }

        .side-spacing {
          padding: 0 ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .pagination {
          justify-self: flex-end;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initServices();
    this.__initState();
    this.__initHandlers();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__ruleSets = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },
        onCacheItem: ({ ruleName, ruleSetName, field }) =>
          normalizeForSearch([ruleName, ruleSetName, field].join(' ')),
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
        onSort: (a, b) => a.ruleName.localeCompare(b.ruleName),
      },
      {
        hideInactive: false,
        sortParams: {
          key: 'ruleName',
          dir: 'asc',
        },
      },
    );
  }

  __initState() {
    this.__searchText = '';
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__ruleSets = [];

    this.model = [];
    this.names = [];

    this.onUpdate = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      search: ({ value }) => this.__collectionService.search(value),
      clearSearch: () => this.__collectionService.search(''),
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
      editRuleSet: async (_, i) => {
        if (i.ruleSetId) {
          const overlay =
            i.level === 'Payer'
              ? OVERLAY_KEYS.PAYER_RULE_SET
              : OVERLAY_KEYS.CHARGE_RULE_SET;

          const result = await openOverlay(overlay, {
            id: i.ruleSetId,
            names: this.names.filter(name => name !== i.ruleSetName),
          });

          if (result) {
            this.onUpdate();
          }
        } else {
          store.dispatch(openWarning(NO_ASSOCIATED_RULE_SET_BANNER));
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__collectionService.setItems(this.model);
    }

    super.update(changedProps);
  }

  renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="side-spacing"
        leadingIcon="neb:search"
        label="Enter Rule Name, Rule Set or FL Field to filter list below"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.__handlers.search}"
        .onClickIcon="${this.__handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  renderTable() {
    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        class="side-spacing"
        showDetailArrow
        .mode="${MODE.DETAIL}"
        .config="${TABLE_CONFIG}"
        .model="${this.__ruleSets}"
        .onSelectRow="${this.__handlers.editRuleSet}"
      ></neb-table>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="side-spacing pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.__handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  render() {
    return html`
      <div class="grid-container">
        ${this.renderSearchField()} ${this.renderTable()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-view-associated-rule-sets',
  NebViewAssociatedRuleSets,
);
