import '../neb-popup-header';
import '../forms/neb-form-authorization';

import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  createPatientAuthorization,
  putPatientAuthorization,
  getPatientAuthorizations,
} from '../../../../neb-api-client/src/patient-authorization-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_7,
} from '../../../../neb-styles/neb-variables';
import {
  getPostBodyFromRaw,
  patientAuthorizationToRaw,
  createModelPatientAuth,
} from '../../../../neb-utils/patientAuthorization';

import Overlay from './neb-overlay';

export const BANNER_SUCCESS = 'Authorization saved successfully.';
export const BANNER_ERROR = 'An error occurred when saving the Authorization.';

export const ADD_AUTHORIZATION_DESCRIPTION =
  'Add authorization details. The authorization will automatically be applied wherever the case is listed.';
export const UPDATE_AUTHORIZATION_DESCRIPTION =
  'Update authorization details. The authorization will automatically be applied wherever the case is listed.';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  description: {
    id: 'description',
  },
  form: {
    id: 'form',
  },
};

class NebOverlayAuthorization extends Overlay {
  static get properties() {
    return {
      __patientAuthorization: Object,
    };
  }

  initState() {
    super.initState();
    this.__patientAuthorization = createModelPatientAuth();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        const rawModel = patientAuthorizationToRaw(model);
        const body = getPostBodyFromRaw(rawModel);

        if (model.id) {
          try {
            const result = await putPatientAuthorization({
              ...body,
              patientId: model.patientId,
              patientCaseId: model.patientCaseId,
              id: model.id,
            });
            this.isDirty = false;

            store.dispatch(openSuccess(BANNER_SUCCESS));
            this.dismiss(result);
          } catch (e) {
            console.error(e);
            store.dispatch(openError(BANNER_ERROR));
          }
        } else {
          const { patientId, patientCaseId } = this.model;

          try {
            const result = await createPatientAuthorization(
              patientId,
              patientCaseId,
              body,
            );

            this.isDirty = false;
            store.dispatch(openSuccess(BANNER_SUCCESS));
            this.dismiss(result);
          } catch (e) {
            console.error(e);
            store.dispatch(openError(BANNER_ERROR));
          }
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 860px;
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
        }

        .description {
          padding: 0 0 10px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .form {
          overflow: auto;
        }
      `,
    ];
  }

  __formatAuthorizationFromRow(model) {
    return {
      ...model,
      dateRequested: model.dateRequested
        ? moment(model.dateRequested).toISOString()
        : null,
      dateApproved: model.dateApproved
        ? moment(model.dateApproved).toISOString()
        : null,
      dateApprovedFrom: model.dateApprovedFrom
        ? moment(model.dateApprovedFrom).toISOString()
        : null,
      dateApprovedTo: model.dateApprovedTo
        ? moment(model.dateApprovedTo).toISOString()
        : null,
    };
  }

  async updated(changedProps) {
    if (!changedProps.has('model') || this.model.add) {
      super.updated(changedProps);
      return;
    }

    const authorizations = await getPatientAuthorizations(
      this.model.patientId,
      this.model.patientCaseId,
    );

    const selectedAuthorization = this.model.id
      ? authorizations.find(a => a.id === this.model.id)
      : authorizations[0];

    this.__patientAuthorization = this.__formatAuthorizationFromRow(
      selectedAuthorization,
    );

    super.updated(changedProps);
  }

  __getTitle() {
    return this.__patientAuthorization.id
      ? 'Update Authorization'
      : 'Add Authorization';
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.__patientAuthorization.id
          ? UPDATE_AUTHORIZATION_DESCRIPTION
          : ADD_AUTHORIZATION_DESCRIPTION}
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          title="${this.__getTitle()}"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>
      </div>

      ${this.__renderDescription()}

      <neb-form-authorization
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__patientAuthorization}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
      ></neb-form-authorization>
    `;
  }
}

customElements.define('neb-overlay-authorization', NebOverlayAuthorization);
