import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { floatingPoint } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import {
  dosageSelector,
  isDosageInRange,
  NDC_NUMBER_CATEGORY_ITEMS,
  NDC_QUALIFIER_ITEMS,
  NDC_UNIT_OF_MEASUREMENT_ITEMS,
} from '../../utils/national-drug-code';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  cancelButton: { id: 'button-cancel' },
  sendButton: { id: 'button-send' },
  selectNationalDrugCodeQualifier: {
    id: 'select-national-drug-code-qualifier',
  },
  textfieldNationalDrugCode: { id: 'textfield-national-drug-code' },
  textfieldNationalDrugCodeDosage: {
    id: 'textfield-national-drug-code-dosage',
  },
  selectNationalDrugCodeUnitOfMeasurement: {
    id: 'select-national-drug-code-unit-of-measurement',
  },
  selectNationalDrugCodeNumberCategory: {
    id: 'select-national-drug-code-number-category',
  },
  textfieldNationalDrugCodeSequenceOrPrescription: {
    id: 'textfield-national-drug-code-sequence-or-prescription',
  },
};

const EMPTY_NDC_ITEM = { label: '', data: { id: null } };
const ndcFieldsSelector = [
  {
    error: 'Required',
    validate: (value, _, state) => {
      if (state.ndc) {
        return !!value;
      }

      return true;
    },
  },
];

class NebFormNationalDrugCode extends NebForm {
  static get properties() {
    return {
      ...super.properties,
    };
  }

  static createModel() {
    return {
      nationalDrugCodeQualifier: EMPTY_NDC_ITEM,
      nationalDrugCode: null,
      nationalDrugCodeDosage: null,
      nationalDrugCodeUnitOfMeasurement: EMPTY_NDC_ITEM,
      nationalDrugCodeNumberCategory: EMPTY_NDC_ITEM,
      nationalDrugCodeSequenceOrPrescription: null,
    };
  }

  createSelectors() {
    return {
      children: {
        nationalDrugCodeQualifier: selectors.select(
          NDC_QUALIFIER_ITEMS,
          EMPTY_NDC_ITEM,
          {
            validators: [
              {
                error: 'Required',
                validate: (value, _, state) => {
                  if (state.ndc) {
                    return value.label !== '';
                  }

                  return true;
                },
              },
            ],
          },
        ),
        nationalDrugCode: ndcFieldsSelector,
        nationalDrugCodeDosage: dosageSelector({
          validators: [...ndcFieldsSelector, isDosageInRange()],
        }),
        nationalDrugCodeUnitOfMeasurement: selectors.select(
          NDC_UNIT_OF_MEASUREMENT_ITEMS,
          EMPTY_NDC_ITEM,
          {
            validators: [
              {
                error: 'Required',
                validate: (value, _, state) => {
                  if (state.ndc) {
                    return value.label !== '';
                  }

                  return true;
                },
              },
            ],
          },
        ),
        nationalDrugCodeNumberCategory: selectors.select(
          NDC_NUMBER_CATEGORY_ITEMS,
          EMPTY_NDC_ITEM,
        ),
      },
    };
  }

  initState() {
    super.initState();
    this.confirmLabel = 'Done';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeNdcSelect: e => this.formService.apply(e.name, e.value || null),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .container-radio {
          display: flex;
          align-items: baseline;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .input {
          flex: 1 0 0px;
        }

        .grid-ndc-fields {
          display: grid;
          width: 100%;
          column-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }
      `,
    ];
  }

  update(changedProps) {
    super.update(changedProps);
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.sendButton.id}"
          class="button add-button"
          ?disabled="${this.confirmDisabled}"
          .label="${this.confirmLabel}"
          .processing="${this.processing}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
          ?disabled="${this.processing}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="grid-ndc-fields">
        <div class="grid">
          <neb-select
            id="${ELEMENTS.selectNationalDrugCodeQualifier.id}"
            helper="Required"
            name="nationalDrugCodeQualifier"
            label="NDC Qualifier"
            .items="${NDC_QUALIFIER_ITEMS}"
            .value="${this.state.nationalDrugCodeQualifier}"
            .error="${this.errors.nationalDrugCodeQualifier}"
            .onChange="${this.handlers.changeNdcSelect}"
          ></neb-select>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textfieldNationalDrugCode.id}"
            label="NDC Code"
            helper="Required"
            name="nationalDrugCode"
            maxLength="50"
            .value="${this.state.nationalDrugCode}"
            .error="${this.errors.nationalDrugCode}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textfieldNationalDrugCodeDosage.id}"
            label="Dosage"
            helper="Required"
            name="nationalDrugCodeDosage"
            .value="${this.state.nationalDrugCodeDosage}"
            .error="${this.errors.nationalDrugCodeDosage}"
            .mask="${floatingPoint}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-select
            id="${ELEMENTS.selectNationalDrugCodeUnitOfMeasurement.id}"
            helper="Required"
            name="nationalDrugCodeUnitOfMeasurement"
            .label="${'Unit of Measurement'}"
            .items="${NDC_UNIT_OF_MEASUREMENT_ITEMS}"
            .value="${this.state.nationalDrugCodeUnitOfMeasurement}"
            .error="${this.errors.nationalDrugCodeUnitOfMeasurement}"
            .onChange="${this.handlers.changeNdcSelect}"
          ></neb-select>
        </div>

        <div class="grid">
          <neb-select
            id="${ELEMENTS.selectNationalDrugCodeNumberCategory.id}"
            name="nationalDrugCodeNumberCategory"
            .label="${'Number Category'}"
            .items="${NDC_NUMBER_CATEGORY_ITEMS}"
            .value="${this.state.nationalDrugCodeNumberCategory}"
            .onChange="${this.handlers.changeNdcSelect}"
          ></neb-select>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textfieldNationalDrugCodeSequenceOrPrescription.id}"
            name="nationalDrugCodeSequenceOrPrescription"
            label="Link Sequence or Prescription Number"
            .value="${this.state.nationalDrugCodeSequenceOrPrescription}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-form-national-drug-code', NebFormNationalDrugCode);
