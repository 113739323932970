import { reverseClaim } from '../../../../packages/neb-api-client/src/allocation-api-client';
import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { ERA_ACTIONS } from '../../../utils/era-eob';

export default ({
  model: { id: eraId },
  paymentId,
  selectedPaymentIds,
  remainderAmount,
  claimId,
  claimReportId,
}) =>
  Promise.all([
    reverseClaim(paymentId, selectedPaymentIds, remainderAmount, claimId),
    updateERAReport(eraId, {
      eraAction: ERA_ACTIONS.REVERSE_CLAIM,
      claimReportId,
    }),
  ]);
