import '../../../../neb-styles/neb-styles';
import '../../../../neb-styles/neb-icons';
import '../../../../neb-lit-components/src/components/field-groups/neb-modifiers';
import '../../../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../neb-lit-components/src/components/neb-dropdown';
import '../../../../neb-lit-components/src/components/neb-toggle-button';
import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-lit-components/src/components/neb-cell-fee-schedule';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { number } from '../../../../neb-utils/masks';

const BUTTON_TEXT = 'Add Charge';
const BUTTON_TOGGLED_TEXT = 'Charge Added';
export const ELEMENTS = {
  procedure: { id: 'procedure' },
  description: { id: 'description' },
  modifiers: { id: 'modifiers' },
  nebModifiers: { id: 'neb-modifiers' },
  units: { id: 'units' },
  unitsInput: { id: 'neb-textfield' },
  feeSchedule: { id: 'fee-schedule' },
  buttonAdd: { id: 'button-add' },
  buttonRemove: { id: 'button-remove' },
  diagnosis: { id: 'diagnosis' },
  diagnosisDropdown: { id: 'diagnosis-dropdown' },
};

class NebEncounterChargesListItem extends LitElement {
  static get properties() {
    return {
      activeCharges: Array,
      allDiagnosis: Array,
      model: Object,
      index: Number,
      showReorder: Boolean,
      showDiagnosis: {
        type: Boolean,
        reflect: true,
      },
      showAddButton: {
        type: Boolean,
        reflect: true,
      },
      priorCharge: {
        type: Boolean,
        reflect: true,
      },
      readOnly: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__setupHandlers();

    this.showAddButton = false;
    this.showReorder = false;
    this.readOnly = false;
    this.activeCharges = [];
    this.allDiagnosis = [];
  }

  firstUpdated() {
    this.__elements = {
      units: this.shadowRoot.getElementById(ELEMENTS.unitsInput.id),
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: row;
        flex: 12;
        align-items: center;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      .add-charge,
      .procedure {
        padding-left: ${CSS_SPACING};
      }

      .column-1,
      .procedure {
        flex: 1;
      }

      .column-2 {
        flex: 2;
      }

      .column-3 {
        flex: 3;
      }

      .procedure {
        min-width: 115px;
      }

      .add-charge {
        min-width: 210px;
      }

      :host([showAddButton]) .procedure {
        padding-left: 0;
      }

      .units,
      .reorder-column {
        width: 30px;
        margin: -13px 0;
      }

      .units {
        --paper-input-container: {
          width: 36px;
          height: 36px;
          text-align: center;
          padding: 0;
        };
      }

      .units-field {
        width: 50px;
      }

      .reorder-column {
        padding-left: ${CSS_SPACING};
        max-width: 50px;
        cursor: ns-resize;
      }

      .modifiers {
        min-width: 190px;
      }

      .description {
        padding-right: 10px;
      }

      .add-charge,
      .diagnosis,
      .reorder,
      .button-container,
      :host([readOnly]:not([priorCharge])) .units {
        display: none;
      }

      :host([showAddButton]) .add-charge,
      :host([showDiagnosis]) .diagnosis,
      :host([showReorder]) .reorder,
      :host([showDiagnosis]) .button-container {
        display: flex;
      }

      :host([priorCharge]) .procedure {
        min-width: 90px;
      }

      :host([priorCharge]) .description {
        flex: 2.5;
      }

      :host([priorCharge]) .modifiers {
        min-width: 110px;
      }

      .diagnosis {
        flex-basis: 60px;
      }

      .diagnosis-dropdown {
        width: 125px;
      }

      .button-container {
        justify-content: flex-end;
      }

      .remove-button {
        cursor: pointer;
        fill: ${CSS_COLOR_GREY_1};
        width: 20px;
        height: 20px;
        margin-right: 28px;
      }

      [name='arrangeIcon'] {
        width: 15px;
        height: 15px;
        padding-top: 3px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .chargeInactiveText {
        padding-left: ${CSS_SPACING};
      }

      :host([invalid-unit]) {
        min-height: 72px;
      }

      .fee-schedule {
        padding-right: ${CSS_SPACING};
      }
    `;
  }

  __renderFeeScheduleCell(model) {
    return !this.priorCharge
      ? html`
          <neb-cell-fee-schedule
            id="${ELEMENTS.feeSchedule.id}"
            class="column-1 fee-schedule"
            .model="${model}"
          ></neb-cell-fee-schedule>
        `
      : '';
  }

  render() {
    const { procedure, description, modifiers, units, isEncounter } =
      this.model;
    return html`
      <div class="column-1 add-charge">
        ${this.__isChargeActive()
          ? html`
              <neb-toggle-button
                id="${ELEMENTS.buttonAdd.id}"
                .buttonText="${isEncounter ? BUTTON_TOGGLED_TEXT : BUTTON_TEXT}"
                .isToggled="${isEncounter}"
                .onClick="${this.__handlers.chargeAdded}"
                ?disabled="${!!isEncounter}"
              >
              </neb-toggle-button>
            `
          : html`
              <span id="chargeInactiveText" class="chargeInactiveText"
                >Charge Inactive</span
              >
            `}
      </div>
      <div class="reorder-column reorder" reorder draggable="false">
        <neb-icon name="arrangeIcon" icon="neb:reorder"></neb-icon>
      </div>
      <span id="${ELEMENTS.procedure.id}" class="column-1 procedure"
        >${procedure}</span
      >
      <span id="${ELEMENTS.description.id}" class="column-3 description"
        >${description}</span
      >
      <div id="${ELEMENTS.modifiers.id}" class="column-2 modifiers">
        ${this.readOnly
          ? modifiers.filter(item => item && item.length > 0).join(', ')
          : html`
              <neb-modifiers
                id="${ELEMENTS.nebModifiers.id}"
                label=" "
                helper=" "
                name="modifiers"
                .values="${modifiers}"
                .onChange="${this.__handlers.chargeChanged}"
              ></neb-modifiers>
            `}
      </div>
      <div id="${ELEMENTS.units.id}" class="column-1 units">
        ${this.readOnly
          ? units
          : html`
              <neb-textfield
                id="${ELEMENTS.unitsInput.id}"
                class="units-field"
                helper=" "
                label=" "
                name="units"
                maxLength="3"
                .mask="${number}"
                .inputMode="${'numeric'}"
                .onChange="${this.__handlers.chargeChanged}"
                .value="${units}"
              ></neb-textfield>
            `}
      </div>
      ${this.__renderFeeScheduleCell(this.model)}
      <div id="${ELEMENTS.diagnosis.id}" class="column-1 diagnosis">
        ${this.allDiagnosis.length > 0
          ? html`
              <neb-dropdown
                class="diagnosis-dropdown"
                id="${ELEMENTS.diagnosisDropdown.id}"
                multiSelect
                placeholder="Select"
                .items="${this.__allDiagnosisCodes()}"
                .selectedItems="${this.__selectedDiagnosisCodes()}"
                limitSelect="4"
                .onItemSelected="${this.__handlers.diagnosisChanged}"
              ></neb-dropdown>
            `
          : html` <span>No Encounter Dx</span> `}
      </div>
      <div class="column-2 button-container">
        <neb-icon
          id="${ELEMENTS.buttonRemove.id}"
          class="remove-button"
          icon="neb:minus"
          @click="${this.__handlers.chargeRemoved}"
        ></neb-icon>
      </div>
    `;
  }

  __handleInvalidUnit({ detail: { value } }) {
    if (value) {
      this.setAttribute('invalid-unit', '');
    } else {
      this.removeAttribute('invalid-unit');
    }
  }

  __setupHandlers() {
    this.__handlers = {
      chargeAdded: () => {
        if (!this.model.isEncounter) {
          this.model.isEncounter = true;
          this.requestUpdate();

          this.__dispatchEvent('neb-encounter-charge-added');
        }
      },
      chargeRemoved: () => this.__dispatchEvent('neb-encounter-charge-removed'),
      chargeChanged: ({ name, value }) => {
        this.__dispatchChange(name, value);
      },
      diagnosisChanged: e => this.__dispatchDiagnosis(e),
    };
  }

  __dispatchEvent(eventName) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        detail: {
          model: this.model,
          priorCharge: this.priorCharge,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __dispatchChange(key, value) {
    let changedProp;

    if (key.includes('modifiers')) {
      const modIndex = key.split('.')[1];
      const newMods = this.model.modifiers;
      newMods[modIndex] = value;
      changedProp = {
        modifiers: newMods,
      };
    } else {
      changedProp = {
        [key]: value,
      };
    }

    this.model = { ...this.model, ...changedProp };

    this.__dispatchEvent('neb-encounter-charge-changed');
  }

  __dispatchDiagnosis({ selectedItems }) {
    const diagnosisPointers = selectedItems.map(code => ({
      diagnosisCode: code,
    }));
    this.model = { ...this.model, diagnosisPointers };

    this.__dispatchEvent('neb-encounter-charge-dropdown');
  }

  __allDiagnosisCodes() {
    return this.allDiagnosis.map(item => item.code);
  }

  __selectedDiagnosisCodes() {
    return this.model.diagnosisPointers.map(
      diagnosis => diagnosis.diagnosisCode,
    );
  }

  __isChargeActive() {
    return (
      this.model.active ||
      !!this.activeCharges.find(c => c.id === this.model.chargeId)
    );
  }
}

customElements.define(
  'neb-encounter-charges-list-item',
  NebEncounterChargesListItem,
);
