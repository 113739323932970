export const START_REQUEST = 'start-request';
export const END_REQUEST = 'end-request';

export const globalLoadingReducer = (state = { count: 0 }, action) => {
  switch (action.type) {
    case START_REQUEST:
      return {
        count: state.count + 1,
      };

    case END_REQUEST:
      return {
        count: state.count - 1,
      };

    default:
      return state;
  }
};
