export const ITEM_FETCHING = 'ITEM_FETCHING';
export const ITEM_FETCHED = 'ITEM_FETCHED';
export const ITEM_FETCH_ERROR = 'ITEM_FETCH_ERROR';
export const ITEM_FETCH_EXISTS = 'ITEM_FETCH_EXISTS';
export const ITEM_CREATING = 'ITEM_CREATING';
export const ITEM_CREATED = 'ITEM_CREATED';
export const ITEM_CREATE_ERROR = 'ITEM_CREATE_ERROR';
export const ITEM_UPDATING = 'ITEM_UPDATING';
export const ITEM_UPDATED = 'ITEM_UPDATED';
export const ITEM_UPDATE_ERROR = 'ITEM_UPDATE_ERROR';
export const ITEM_DELETING = 'ITEM_DELETING';
export const ITEM_DELETED = 'ITEM_DELETED';
export const ITEM_DELETE_ERROR = 'ITEM_DELETE_ERROR';
export const ITEM_PAGINATION_SET_QUERY = 'ITEM_PAGINATION_SET_QUERY';
