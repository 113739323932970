import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const BASE_URL = 'appointments/insurance-status';

export async function fetchMany(appointments, optOutLoadingIndicator = false) {
  const { insuranceStatuses } = await apiClient.makeRequest({
    body: JSON.stringify({ appointments }),
    headers: { 'Content-Type': 'application/json' },
    method: Method.POST,
    optOutLoadingIndicator,
    path: BASE_URL,
    version: 1,
  });

  return insuranceStatuses;
}
