import { inRange } from '@neb/form-validators';

import { ITEM_EMPTY, numeric } from '../../packages/neb-utils/selectors';

const MAXIMUM_DECIMAL = 99999999999.999;
const MINIMUM_DECIMAL = 0;
const DOSAGE_ERROR_MESSAGE = 'Out of Range';

export const isDosageInRange = () =>
  inRange(MINIMUM_DECIMAL, MAXIMUM_DECIMAL, true, true, DOSAGE_ERROR_MESSAGE);

export const dosageSelector = (opts = {}) => ({
  ...numeric(0, opts),
  format: v => (v ? v.toString() : null),
});

export const NDC_UNIT_OF_MEASUREMENT = Object.freeze({
  F2: { code: 'F2', description: 'International Unit' },
  GR: { code: 'GR', description: 'Gram' },
  ME: { code: 'ME', description: 'Milligram' },
  ML: { code: 'ML', description: 'Milliliter' },
  UN: { code: 'UN', description: 'Unit' },
});

export const NDC_NUMBER_CATEGORY = Object.freeze({
  VY: { code: 'VY', description: 'Link Sequence Number' },
  XZ: { code: 'XZ', description: 'Prescription Number' },
});

export const NDC_QUALIFIER = Object.freeze({
  EN: { code: 'EN', description: 'EAN/UCC - 13' },
  EO: { code: 'EO', description: 'EAN/UCC - 8' },
  HI: {
    code: 'HI',
    description:
      'HIBC (Health Care Industry Bar Code) Supplier Labeling Standard Primary Data Message',
  },
  N4: { code: 'N4', description: 'National Drug Code in 5-4-2 Format' },
  ON: { code: 'ON', description: 'Customer Order Number' },
  UK: { code: 'UK', description: 'GTIN 14-digit Data Structure' },
  UP: { code: 'UP', description: 'UCC - 12' },
});

export const NDC_UNIT_OF_MEASUREMENT_ITEMS = [
  ITEM_EMPTY,
  ...Object.values(NDC_UNIT_OF_MEASUREMENT).map(label => ({
    label: `${label.code} (${label.description})`,
    data: { id: label.code },
  })),
];

export const NDC_NUMBER_CATEGORY_ITEMS = [
  ITEM_EMPTY,
  ...Object.values(NDC_NUMBER_CATEGORY).map(label => ({
    label: `${label.code} (${label.description})`,
    data: { id: label.code },
  })),
];

export const NDC_QUALIFIER_ITEMS = [
  ITEM_EMPTY,
  ...Object.values(NDC_QUALIFIER).map(label => ({
    label: `${label.code} (${label.description})`,
    data: { id: label.code },
  })),
];
