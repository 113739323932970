import * as appointmentTypesApiClient from '../../neb-api-client/src/appointment-types';
import { store } from '../../neb-redux/neb-redux-store';

export const CREATE_APPOINTMENT_TYPE = 'CREATE_APPOINTMENT_TYPE';
export const DELETE_APPOINTMENT_TYPE = 'DELETE_APPOINTMENT_TYPE';
export const FETCH_APPOINTMENT_TYPES = 'FETCH_APPOINTMENT_TYPES';
export const UPDATE_APPOINTMENT_TYPE = 'UPDATE_APPOINTMENT_TYPE';
export const UPDATE_APPOINTMENT_TYPES_ORDER = 'UPDATE_APPOINTMENT_TYPES_ORDER';

export function requestAppointmentTypes() {
  return {
    type: FETCH_APPOINTMENT_TYPES,
  };
}

export function requestAppointmentTypesSuccess(response) {
  return {
    type: FETCH_APPOINTMENT_TYPES,
    status: 'success',
    response,
  };
}

export function requestAppointmentTypesFailure(error) {
  return {
    type: FETCH_APPOINTMENT_TYPES,
    status: 'failed',
    error,
  };
}

const fetchAppointmentTypesWithProviders = async () => {
  const response = await appointmentTypesApiClient.getAppointmentTypes();
  store.dispatch(requestAppointmentTypesSuccess(response));
};

export const fetchAppointmentTypes =
  (optOutLoadingIndicator = true) =>
  async dispatch => {
    dispatch(requestAppointmentTypes());

    try {
      const response = await appointmentTypesApiClient.getAppointmentTypes(
        optOutLoadingIndicator,
      );
      dispatch(requestAppointmentTypesSuccess(response));
    } catch (fetchError) {
      console.log(fetchError);
      dispatch(requestAppointmentTypesFailure(fetchError));
    }
  };

export function requestCreateAppointmentType() {
  return {
    type: CREATE_APPOINTMENT_TYPE,
  };
}

export function requestCreateAppointmentTypeSuccess(response) {
  return {
    type: CREATE_APPOINTMENT_TYPE,
    status: 'success',
    response,
  };
}

export function requestCreateAppointmentTypeFailure(error) {
  return {
    type: CREATE_APPOINTMENT_TYPE,
    status: 'failed',
    error,
  };
}

export const appointmentTypeCreate = async apptType => {
  try {
    store.dispatch(requestCreateAppointmentType());
    const response =
      await appointmentTypesApiClient.createAppointmentType(apptType);
    store.dispatch(requestCreateAppointmentTypeSuccess(response));
    await fetchAppointmentTypesWithProviders();
  } catch (err) {
    const errObj = err.res ? err.res : err;
    store.dispatch(requestCreateAppointmentTypeFailure(errObj));
  }
};

export function requestUpdateAppointmentType() {
  return {
    type: UPDATE_APPOINTMENT_TYPE,
  };
}

export function requestUpdateAppointmentTypeSuccess(response) {
  return {
    type: UPDATE_APPOINTMENT_TYPE,
    status: 'success',
    response,
  };
}

export function requestUpdateAppointmentTypeFailure(error) {
  return {
    type: UPDATE_APPOINTMENT_TYPE,
    status: 'failed',
    error,
  };
}

export const appointmentTypeUpdate = async apptType => {
  try {
    store.dispatch(requestUpdateAppointmentType());
    const response =
      await appointmentTypesApiClient.updateAppointmentType(apptType);
    store.dispatch(requestUpdateAppointmentTypeSuccess(response));
    await fetchAppointmentTypesWithProviders();
  } catch (err) {
    const errObj = err.res ? err.res : err;
    store.dispatch(requestUpdateAppointmentTypeFailure(errObj));
  }
};

export function requestDeleteAppointmentType() {
  return {
    type: DELETE_APPOINTMENT_TYPE,
  };
}

export function requestDeleteAppointmentTypeSuccess(apptTypeId) {
  return {
    type: DELETE_APPOINTMENT_TYPE,
    status: 'success',
    apptTypeId,
  };
}

export function requestDeleteAppointmentTypeFailure(error) {
  return {
    type: DELETE_APPOINTMENT_TYPE,
    status: 'failed',
    error,
  };
}

export const deleteAppointmentType = apptTypeId => async dispatch => {
  dispatch(requestDeleteAppointmentType());

  try {
    await appointmentTypesApiClient.deleteAppointmentType(apptTypeId);
    dispatch(requestDeleteAppointmentTypeSuccess(apptTypeId));
  } catch (deleteError) {
    console.log(deleteError);
    dispatch(requestDeleteAppointmentTypeFailure(deleteError));
  }
};

export function requestUpdateAppointmentTypesOrder() {
  return {
    type: UPDATE_APPOINTMENT_TYPES_ORDER,
  };
}

export function requestUpdateAppointmentTypesOrderSuccess(response) {
  return {
    type: UPDATE_APPOINTMENT_TYPES_ORDER,
    status: 'success',
    response,
  };
}

export function requestUpdateAppointmentTypesOrderFailure(error) {
  return {
    type: UPDATE_APPOINTMENT_TYPES_ORDER,
    status: 'failed',
    error,
  };
}
