import { css, html } from 'lit';

import '../../../neb-pagination';
import '../../../../../../../src/components/tables/claims-worklist/neb-table-claims-worklist-ready-to-submit';
import { Dirty } from '../../../../../../neb-redux/services/dirty';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import { map } from '../../../../../../neb-utils/utils';
import NebForm, { ELEMENTS as ELEMENTS_BASE } from '../../neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: { id: 'header' },
  readyTable: { id: 'ready-to-submit-table' },
  pagination: { id: 'pagination' },
};

export const EXPAND_FLAGS_FOR_READY_TO_SUBMIT_FORM =
  'expand-flags-for-ready-to-submit-form';

class NebFormClaimsWorklistReadyToSubmit extends NebForm {
  static get properties() {
    return {
      totalItemCount: Number,
      providers: Array,
      sortParams: Object,
      excludedClaimOrInvoiceIds: Boolean,
      emptyMessage: String,
      currentPage: Number,
      pageCount: Number,
      claimMetaData: Object,
      patientCasesDict: { type: Object },
      showExpandAll: Boolean,
      enablePatients: Boolean,
      hasRcmClaimFlagFF: Boolean,
      hasRcmRelease2FF: Boolean,

      __expandFlags: Array,
      __tableModel: Array,
      __pageItemCount: Number,
      __selectedRowsForCurrentPage: Array,
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();

    this.totalItemCount = 0;
    this.providers = [];
    this.sortParams = {};
    this.excludedClaimOrInvoiceIds = false;
    this.emptyMessage = '';
    this.currentPage = 0;
    this.pageCount = 1;
    this.claimMetaData = { selectAll: false };
    this.showExpandAll = false;
    this.patientCasesDict = {};
    this.enablePatients = false;
    this.hasRcmClaimFlagFF = false;
    this.hasRcmRelease2FF = false;

    this.__expandFlags = {};
    this.__selectedRowsForCurrentPage = [];
    this.__tableModel = [];
    this.__pageItemCount = 0;

    this.onChange = () => {};

    this.onClickLink = () => {};

    this.onConfirmDelete = () => {};

    this.onConfirmHide = () => {};

    this.onConfirmShow = () => {};

    this.onScrubClaim = () => {};

    this.onDeselectAll = () => {};

    this.onOpenPrintOffsets = () => {};

    this.onOpenClaimBatchesOverlay = () => {};

    this.onSaveAndSubmit = () => {};

    this.onSave = () => {};

    this.onSelectAll = () => {};

    this.onSelectCell = () => {};

    this.onSort = () => {};

    this.onSelectAllOnCurrentPage = () => {};

    this.onPageChanged = () => {};

    this.onUpdatePatientCase = () => {};

    this.onAddPatientCase = () => {};

    this.onChangeDirty = () => {};

    this.onLoad = () => {};

    this.onDirtyDialog = () => {};

    this.onUpdateDirty = () => {};

    this.onRefreshDrafts = () => {};

    this.onClaimFlagChange = () => {};

    this.onResetTableModel = () => {};
  }

  get __expandFlagsForCurrentPage() {
    return this.model.map(
      claim => !!this.__expandFlags.expandAll || !!this.__expandFlags[claim.id],
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      load: () => this.onLoad(),
      toggleExpand: (_, ...toggleData) => {
        const [item, , value] = toggleData;

        this.__expandFlags = {
          ...this.__expandFlags,
          [item.id]: value,
          expandAll: false,
        };

        this.__setExpandFlags();
      },
      expandAll: () => {
        const keys = Object.keys(this.__expandFlags);
        const expandAll = !this.__expandFlags.expandAll;

        this.__expandFlags = {
          ...keys.reduce((acc, cur) => ({ ...acc, [cur]: expandAll }), {}),
          ...this.model.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: expandAll }),
            {},
          ),
          expandAll,
        };

        this.__setExpandFlags();
      },
      changeSort: (name, result) =>
        this.__dirtyDialog(() => this.onSort(name, result)),
      selectAll: () => {
        this.onSelectAll();
        this.__tableModel = [...this.__tableModel];
      },
      deselectAll: () => {
        this.onDeselectAll();
        this.__tableModel = [...this.__tableModel];
      },
      selectAllOnCurrentPage: () => {
        this.onSelectAllOnCurrentPage();
        this.__tableModel = [...this.__tableModel];
      },
      selectCheckbox: (item, index) => {
        const value = this.__claimMetaData[item.id]
          ? !this.__claimMetaData[item.id].checked
          : false;
        const changed = {
          name: `${index}.checked`,
          value,
        };
        this.onChange(changed);
        this.__tableModel = [...this.__tableModel];
      },
      change: e => {
        this.onChange(e);
        this.__tableModel = [...this.__tableModel];
      },
      confirmDelete: () => this.__dirtyDialog(() => this.onConfirmDelete()),
      confirmHide: () =>
        this.__dirtyDialog(async () => {
          await this.onConfirmHide();
          await this.onLoad();
        }),
      confirmShow: () =>
        this.__dirtyDialog(async () => {
          await this.onConfirmShow();
          await this.onLoad();
        }),
      scrubClaim: () => {
        this.__dirtyDialog(async () => {
          await this.onScrubClaim();
          await this.onLoad();
        });
      },
      clickLink: (key, index) =>
        this.__dirtyDialog(() => this.onClickLink(key, index)),
      openClaimBatchesOverlay: () => this.onOpenClaimBatchesOverlay(),
      saveAndSubmit: () =>
        this.__dirtyDialog(async () => {
          await this.onSaveAndSubmit();
          await this.onLoad();
        }),
      refreshDrafts: () =>
        this.__dirtyDialog(async () => {
          await this.onRefreshDrafts();
          await this.onLoad();
        }),
      edit: e => this.__onEdit(e),
      cancel: () => this.__dirtyDialog(),
      pageChanged: index => this.__dirtyDialog(() => this.onPageChanged(index)),
      promptDirty: callback => this.__dirtyDialog(callback),
    };
  }

  __adjustDxBalance(e) {
    const [index, , liIndex] = e.name.split('.');

    const currentTotalDx =
      this.state[index].lineItems[liIndex].diagnosesPointers.length;
    const newTotalDx = e.value.length;

    if (newTotalDx < currentTotalDx) {
      this.formService.removeItem(e.name);
    }

    if (newTotalDx > currentTotalDx) {
      this.formService.addItem(e.name);
    }

    const balance = Math.abs(newTotalDx > currentTotalDx);

    if (balance > 1) {
      this.__adjustDxBalance(e);
    }
  }

  get getIndexesToUpdate() {
    return {
      dateOfCurrentIllness: stateItem =>
        this.__state.reduce((accum, item, index) => {
          if (item.encounterId === stateItem.encounterId) {
            accum.push(index);
          }
          return accum;
        }, []),
      initialTxDate: stateItem =>
        this.__state.reduce((accum, item, index) => {
          if (item.patientCaseId === stateItem.patientCaseId) {
            accum.push(index);
          }
          return accum;
        }, []),
      lineItems: stateItem =>
        this.__state.reduce((accum, item, index) => {
          if (item.invoiceId === stateItem.invoiceId) {
            accum.push(index);
          }
          return accum;
        }, []),
    };
  }

  __onEdit(e) {
    const [index, fieldName, innerIndex, innerFieldName] = e.name.split('.');

    if (this.getIndexesToUpdate[fieldName]) {
      const indexesToUpdate = this.getIndexesToUpdate[fieldName](
        this.__state[index],
      );

      if (innerFieldName === 'diagnosesPointers') {
        indexesToUpdate.forEach(itemIndex => {
          const name = `${itemIndex}.lineItems.${innerIndex}.diagnosesPointers`;
          this.__adjustDxBalance({
            name,
            value: e.value,
          });

          this.formService.apply(name, e.value);
        });
      } else {
        indexesToUpdate.forEach(i => {
          this.formService.apply(`${i}.${fieldName}`, e.value);
        });
      }
    }

    this.formService.apply(e.name, e.value);

    if (fieldName === 'claimFlag') {
      this.onClaimFlagChange(this.state, this.state[index].id);
    }
  }

  __initServices() {
    this.__dirtyService = new Dirty(
      () => true,
      () => this.__dirty,
      {
        useNewDirtyDialog: true,
        formIsValid: () => this.formService.validate(),
        onSave: () => this.save(),
        onDiscard: () => this.formService.reset(),
      },
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this.__dirtyService.connect();
  }

  disconnectedCallback() {
    this.__dirtyService.disconnect();
  }

  __dirtyDialog(callback = () => {}) {
    return this.onDirtyDialog({
      onSave: async () => {
        await this.save();
        return callback();
      },
      onDiscard: () => {
        this.formService.reset();
        this.onResetTableModel(this.state);
        return callback();
      },
    });
  }

  __updateTableModel(model) {
    this.__tableModel =
      model && model.length && this.claimMetaData
        ? model.reduce((accum, item) => {
            const newItem = {
              ...item,
              checked: this.claimMetaData[item.id]
                ? this.claimMetaData[item.id].checked
                : false,
            };
            accum.push(newItem);

            return accum;
          }, [])
        : [];
  }

  firstUpdated() {
    super.firstUpdated();

    this.__expandFlags = this.__getExpandFlags();
  }

  __getExpandFlags() {
    return (
      JSON.parse(
        window.localStorage.getItem(EXPAND_FLAGS_FOR_READY_TO_SUBMIT_FORM),
      ) || {}
    );
  }

  __setExpandFlags() {
    window.localStorage.setItem(
      EXPAND_FLAGS_FOR_READY_TO_SUBMIT_FORM,
      JSON.stringify(this.__expandFlags),
    );
  }

  __shouldUpdateForm(changedProps) {
    const props = ['__state', '__dirty', 'claimMetaData'];
    return props.some(p => changedProps.has(p));
  }

  update(changedProps) {
    if (this.__shouldUpdateForm(changedProps)) {
      this.onUpdateDirty(
        this.__dirty,
        this.formService.validate(),
        this.__formatModel(),
      );

      this.__updateTableModel(this.state);
    }

    if (changedProps.has('model') && !this.__dirty) {
      this.__updateTableModel(this.model);
    }

    if (changedProps.has('__tableModel')) {
      this.__selectedRowsForCurrentPage = this.__tableModel.map(
        item =>
          (!!this.__claimMetaData.selectAll || !!item.checked) &&
          !item.claimFlag,
      );
    }

    super.update(changedProps);
  }

  createSelectors() {
    return {
      children: {
        $: {
          children: {
            dateOfCurrentIllness: {
              validators: [
                {
                  error: 'mm/dd/yyyy',
                  validate: () => {
                    const readyTable = this.shadowRoot.getElementById(
                      ELEMENTS.readyTable.id,
                    );

                    const datePicker = readyTable.shadowRoot.querySelector(
                      '[id^=date-of-onset-]',
                    );

                    if (datePicker) {
                      const dateErrors = datePicker.invalidTextInput;
                      return !dateErrors;
                    }
                    return true;
                  },
                },
              ],
            },
            initialTxDate: {
              validators: [
                {
                  error: 'mm/dd/yyyy',
                  validate: () => {
                    const readyTable = this.shadowRoot.getElementById(
                      ELEMENTS.readyTable.id,
                    );

                    const datePicker = readyTable.shadowRoot.querySelector(
                      '[id^=initial-treatment-date-]',
                    );

                    if (datePicker) {
                      const dateErrors = datePicker.invalidTextInput;
                      return !dateErrors;
                    }
                    return true;
                  },
                },
              ],
            },
            lineItems: {
              children: {
                $: {
                  format: li => ({
                    ...li,
                    diagnosesPointers: li.diagnosesPointers.sort((a, b) =>
                      a.diagnosisCode.localeCompare(b.diagnosisCode),
                    ),
                  }),
                  unformat: li => ({
                    ...li,
                    diagnosesPointers: li.diagnosesPointers.sort((a, b) =>
                      a.diagnosisCode.localeCompare(b.diagnosisCode),
                    ),
                  }),
                  children: {
                    diagnosesPointers: {
                      clipPristine: true,
                      createItem: () => ({
                        diagnosisCode: '',
                      }),
                      children: {
                        $: {
                          format: ({ diagnosisCode }) => ({ diagnosisCode }),
                          unformat: ({ diagnosisCode }) => diagnosisCode,
                          children: {
                            diagnosisCode: {},
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  static createModel() {
    return [
      {
        patient: { name: '' },
        locationIds: [''],
        lineItems: [{ diagnosesPointers: [{ diagnosisCode: '' }] }],
        claimFlagDetails: { description: '', assignedTo: '' },
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host() {
          height: fit-content;
        }

        .content {
          position: relative;
          width: 100%;
          height: 100%;
          overflow-y: visible;
          min-height: fit-content;
        }

        #action-bar {
          position: fixed;
          width: 100%;
          bottom: 0px;
        }

        neb-pagination {
          justify-content: flex-end;
          margin: ${CSS_SPACING} ${CSS_SPACING} 100px ${CSS_SPACING};
        }
      `,
    ];
  }

  __formatModel() {
    const rawModel = this.formService.build();
    return map(rawModel, (_, value) =>
      typeof value === 'string' ? value.trim() : value,
    );
  }

  async save(...args) {
    if (this.formService.validate()) {
      const model = this.__formatModel();

      this.__saving = true;
      await this.onSave(model, ...args);
      this.__saving = false;
    }
  }

  __getSelectedItemCount() {
    return Object.values(this.claimMetaData).filter(row => row.checked).length;
  }

  renderContent() {
    return html`
      <neb-table-claims-worklist-ready-to-submit
        id="${ELEMENTS.readyTable.id}"
        .emptyMessage="${this.emptyMessage}"
        .model="${this.__tableModel}"
        .totalItemCount="${this.totalItemCount}"
        .pageCount="${this.pageCount}"
        .claimMetaData="${this.claimMetaData}"
        .selectedItemCount="${this.__getSelectedItemCount()}"
        .providers="${this.providers}"
        .sortParams="${[this.sortParams]}"
        .selectedItems="${this.__selectedRowsForCurrentPage}"
        .onSelectCheckbox="${this.handlers.selectCheckbox}"
        .onSelectAll="${this.handlers.selectAllOnCurrentPage}"
        .enablePatients="${this.enablePatients}"
        .excludedClaimOrInvoiceIds="${this.excludedClaimOrInvoiceIds}"
        .hasRcmRelease2FF="${this.hasRcmRelease2FF}"
        .hasRcmClaimFlagFF="${this.hasRcmClaimFlagFF}"
        .onChange="${this.handlers.change}"
        .onConfirmDelete="${this.handlers.confirmDelete}"
        .onConfirmHide="${this.handlers.confirmHide}"
        .onConfirmShow="${this.handlers.confirmShow}"
        .onScrubClaim="${this.handlers.scrubClaim}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .onSelectAllOnCurrentPage="${this.handlers.selectAllOnCurrentPage}"
        .onSelectAllItems="${this.handlers.selectAll}"
        .onClickLink="${this.handlers.clickLink}"
        .onSort="${this.handlers.changeSort}"
        .onOpenClaimBatchesOverlay="${this.handlers.openClaimBatchesOverlay}"
        .onSaveAndSubmit="${this.handlers.saveAndSubmit}"
        .onEdit="${this.handlers.edit}"
        .onExpandAll="${this.handlers.expandAll}"
        .onToggleExpand="${this.handlers.toggleExpand}"
        .onLoad="${this.handlers.load}"
        .onDirty="${this.handlers.promptDirty}"
        .expandFlags="${this.__expandFlagsForCurrentPage}"
        .onUpdatePatientCase="${this.onUpdatePatientCase}"
        .onAddPatientCase="${this.onAddPatientCase}"
        .onRefreshDrafts="${this.handlers.refreshDrafts}"
        .patientCasesDict="${this.patientCasesDict}"
        ?dirty="${this.__dirty}"
      ></neb-table-claims-worklist-ready-to-submit>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        .currentPage="${this.currentPage}"
        .onPageChanged="${this.handlers.pageChanged}"
        .pageCount="${this.pageCount}"
        ?warnPageChange="${this.__dirty}"
        .onWarnPageChange="${this.handlers.promptDirty}"
      ></neb-pagination>
    `;
  }
}

customElements.define(
  'neb-form-claims-worklist-ready-to-submit',
  NebFormClaimsWorklistReadyToSubmit,
);
