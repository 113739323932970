import './neb-menu';
import './neb-textbox';
import './neb-text-helper';
import './neb-floating-label';
import './neb-select-multi-item';
import '../../../../../src/components/misc/neb-icon';

import equal from 'fast-deep-equal';
import { html, css, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_ERROR,
  CSS_FIELD_MARGIN,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_5,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';
import { capitalizeEachWord } from '../../../../neb-utils/formatters';
import { decompose } from '../../../../neb-utils/utils';

export const ELEMENTS = {
  label: {
    id: 'label',
  },
  textbox: {
    id: 'textbox',
  },
  input: {
    id: 'input',
  },
  menu: {
    id: 'menu',
  },
  helper: {
    id: 'helper',
  },
  multiItems: {
    selector: 'neb-select-multi-item',
  },
  actionButton: {
    id: 'action-icon',
  },
  clearIcon: {
    id: 'clear-icon',
  },
};

class Select extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      __invalid: {
        reflect: true,
        type: Boolean,
        attribute: 'invalid',
      },
      scrollOnKeydown: Boolean,
      maxVisibleItems: Number,
      maxSelection: Number,
      itemHeight: Number,
      itemMinWidth: Number,
      thresholdCount: Number,
      allLabel: String,
      forceAlignMenu: String,
      forceDown: Boolean,
      absolutePosition: {
        reflect: true,
        type: Boolean,
      },
      value: String || Object || Array,
      name: String,
      helper: String,
      error: Boolean || String,
      placeholder: String,
      items: Array,
      showFullText: {
        reflect: true,
        type: Boolean,
      },
      multiSelect: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      nonEditable: {
        reflect: true,
        type: Boolean,
      },
      pinLabel: {
        reflect: true,
        type: Boolean,
      },
      label: {
        reflect: true,
        type: String,
      },
      wrapText: {
        reflect: true,
        type: Boolean,
      },
      addNewItemLabel: String,
      customAddText: String,
      actionIcon: String,
      enableClear: Boolean,
      useStartingSoftSelectIndex: Boolean,
      showTitle: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__open = false;
    this.__focused = false;
    this.__invalid = false;
    this.__displayItems = [];
    this.disabled = false;
    this.nonEditable = false;
    this.pinLabel = false;
    this.multiSelect = false;
    this.showFullText = false;
    this.wrapText = false;
    this.scrollOnKeydown = true;
    this.maxVisibleItems = 4;
    this.maxSelection = 0;
    this.absolutePosition = false;
    this.itemHeight = 48;
    this.itemMinWidth = 0;
    this.thresholdCount = 4;
    this.allLabel = '';
    this.forceAlignMenu = 'left';
    this.value = '';
    this.name = '';
    this.label = '';
    this.helper = '';
    this.error = '';
    this.placeholder = '';
    this.items = [];
    this.addNewItemLabel = '';
    this.actionIcon = '';
    this.enableClear = false;
    this.useStartingSoftSelectIndex = false;
    this.showTitle = false;

    this.onChange = () => {};

    this.onRequest = () => {};

    this.onAddNewItem = () => {};

    this.onClickActionIcon = () => {};

    this.onRenderItem = null;
  }

  __initHandlers() {
    this.__handlers = {
      request: () => this.onRequest(),
      focus: () => {
        if (!this.disabled) {
          this.__focused = true;
          this.onChange({
            id: this.id,
            name: this.name,
            value: this.value,
            event: 'focus',
          });
        }
      },
      blur: () => {
        if (!this.disabled) {
          this.__open = false;
          this.__focused = false;
          this.onChange({
            id: this.id,
            name: this.name,
            value: this.value,
            event: 'blur',
          });
        }
      },
      clear: () => {
        if (!this.disabled) {
          this.__open = !this.__open;
          this.onChange({
            id: this.id,
            name: this.name,
            value: [],
            selectedIndices: [],
            event: 'select',
          });
        }
      },
      toggle: () => {
        if (!this.disabled) {
          this.__open = !this.__open;
        }
      },
      keydown: e => {
        if (!this.disabled) {
          if (!this.__open && (e.key === 'Enter' || e.key === ' ')) {
            setTimeout(() => {
              this.__open = true;
            });
          }
        }

        if (e.key === 'Escape') {
          this.__open = false;
        }
      },
      select: index => {
        if (this.nonEditable) return;

        const lastItem = index === this.__displayItems.length - 1;

        if (lastItem && this.addNewItemLabel) {
          this.onAddNewItem();
        } else {
          this.__selectItem(index);
        }

        this.__open = false;
      },
      multiSelect: index => {
        if (!this.nonEditable) {
          const adjustedIndex = this.__getAdjustedIndex(index);

          if (this.allLabel && !index) {
            const selectingAll = !this.isItemSelected(0);

            const { items, indices } = this.items.reduce(
              (acc, item, index) => {
                if (
                  (selectingAll && !item.disabled) ||
                  (item.disabled &&
                    this.value.findIndex(val => equal(val, item)) !== -1)
                ) {
                  acc.items.push(item);
                  acc.indices.push(index);
                }

                return acc;
              },
              {
                items: [],
                indices: [],
              },
            );

            this.onChange({
              id: this.id,
              name: this.name,
              value: items,
              selectedIndices: indices,
              event: 'selectAll',
            });
          } else if (!this.items[adjustedIndex].disabled) {
            const targetItem = this.items[adjustedIndex];
            const listedIndex = this.value.findIndex(v => equal(v, targetItem));
            const indices =
              listedIndex === -1
                ? [
                    ...this.value.map(v =>
                      this.items.findIndex(item => equal(v, item)),
                    ),
                    adjustedIndex,
                  ].sort()
                : this.value
                    .map(v => this.items.findIndex(item => equal(v, item)))
                    .filter(v => v !== adjustedIndex);
            const items = indices.map(index => this.items[index]);
            const allowAddition =
              !this.maxSelection || this.value.length < this.maxSelection;

            if (items.length < this.value.length || allowAddition) {
              this.onChange({
                id: this.id,
                name: this.name,
                value: items,
                selectedIndices: indices,
                event: 'select',
              });
            }
          }
        }
      },
      renderMultiSelectItem: (item, index) => {
        const model = this.onRenderItem ? item : item.label;

        return html`
          <neb-select-multi-item
            .index="${index}"
            .model="${model}"
            .disabled="${this.nonEditable || item.disabled}"
            .onChange="${this.__handlers.multiSelect}"
            .onRenderItem="${this.onRenderItem}"
            ?selected="${this.isItemSelected(index)}"
            ?wrapText="${this.wrapText}"
          ></neb-select-multi-item>
        `;
      },
    };
  }

  __getAdjustedIndex(index) {
    return this.allLabel ? index - 1 : index;
  }

  __selectItem(index) {
    const adjustedIndex = this.__getAdjustedIndex(index);
    const hasDividers = Array.isArray(this.items[0]);
    const event = {
      id: this.id,
      name: this.name,
      event: 'select',
      value: this.items[adjustedIndex],
      index: adjustedIndex,
    };

    if (hasDividers) {
      const { groupIndex, itemIndex } = decompose(adjustedIndex, this.items);
      event.value = this.items[groupIndex][itemIndex];
    }

    this.onChange(event);
  }

  isPinned() {
    return Boolean(this.getDisplayValue()) || this.__focused || this.pinLabel;
  }

  isItemSelected(index) {
    if (this.allLabel && !index) {
      return this.areAllSelected();
    }

    const adjustedIndex = this.__getAdjustedIndex(index);
    const item = this.items[adjustedIndex];
    return Array.isArray(this.value) && this.value.find(v => equal(v, item));
  }

  getSelectedIndex() {
    return this.__displayItems.findIndex(item => equal(item, this.value));
  }

  getLabel() {
    const useAsterisk = this.label.trim() && this.helper === 'Required';
    return useAsterisk ? `${this.label}*` : this.label;
  }

  getHelperText() {
    if (this.error) {
      switch (typeof this.error) {
        case 'boolean':
          return this.error ? this.helper : '';

        case 'string':
          return this.error;

        default:
      }
    }

    return this.helper;
  }

  getPlaceholder() {
    const show =
      this.placeholder.length > 0 &&
      (this.label.trim().length === 0 || this.isPinned());
    return show ? this.placeholder : '';
  }

  getMultiSelectDisplayValue() {
    const count = this.maxSelection || this.items.length;
    const max = count > this.items.length ? this.items.length : count;
    return this.value.length > 1
      ? `${this.value.length} of ${max} selected`
      : `${this.value[0].label || this.value[0]}`;
  }

  areAllSelected() {
    return (
      this.value.length > 0 &&
      this.items.length > 0 &&
      this.items.every(item => this.value.find(v => equal(v, item)))
    );
  }

  getDisplayValue() {
    if (this.multiSelect) {
      if (this.allLabel && this.areAllSelected()) {
        return this.allLabel.trim() ? `All ${this.allLabel}` : 'All';
      }

      return this.value &&
        this.value.length &&
        this.value.every(val => val !== undefined)
        ? this.getMultiSelectDisplayValue()
        : '';
    }

    const displayValue =
      typeof this.value === 'object' && this.value != null
        ? this.value.label
        : this.value;

    if (displayValue == null) return '';
    return displayValue;
  }

  __checkDisplayItems(changedProps) {
    const displayItems = ['items', 'allLabel', 'addNewItemLabel'];
    return displayItems.some(displayItem => changedProps.has(displayItem));
  }

  get __allLabelOption() {
    return this.allLabel && this.items.length > 0
      ? [
          {
            label: this.allLabel.trim() ? `[All ${this.allLabel}]` : '[All]',
            value: null,
          },
        ]
      : [];
  }

  get __addNewOption() {
    return this.addNewItemLabel
      ? [
          {
            label: `${
              this.customAddText ? this.customAddText : 'Add New'
            } ${capitalizeEachWord(this.addNewItemLabel)}`,
            value: { id: 'add-new-item-option' },
            icon: 'neb:plus',
          },
        ]
      : [];
  }

  __buildDisplayItems() {
    const items = [
      ...this.__allLabelOption,
      ...this.items,
      ...this.__addNewOption,
    ];

    return items.map(item =>
      typeof item === 'object'
        ? item
        : {
            label: item,
          },
    );
  }

  update(changedProps) {
    if (changedProps.has('error')) {
      this.__invalid = Boolean(this.error);
    }

    if (changedProps.has('value')) {
      this.__displayItems = [...this.__displayItems];
    }

    if (this.__checkDisplayItems(changedProps)) {
      this.__displayItems = this.__buildDisplayItems();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
        }

        .floating-menu {
          position: absolute;
          top: 150px;
          left: 20px !important;
        }

        .relative {
          position: relative;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          padding-top: ${CSS_FIELD_MARGIN};
          flex-flow: nowrap column;
        }

        :host([label='']) .container {
          padding-top: 0;
        }

        .floating-label {
          --right-docked: 20px;
        }

        .textbox {
          outline: none;
          cursor: pointer;
        }

        :host([disabled]) .textbox,
        :host([disabled]) .content {
          cursor: initial;
        }

        :host([disabled]) .input {
          color: ${CSS_COLOR_DISABLED};
        }

        .content {
          display: grid;
          cursor: pointer;
          align-items: center;
          grid-template-columns: 1fr auto auto;
          grid-gap: 8px;
          width: 100%;
          height: 100%;
        }

        .action-icon-content {
          grid-template-columns: auto 1fr auto;
        }

        .action-icon-floating-label {
          margin-left: 28px;
        }

        .icon {
          width: 10px;
          height: 10px;
          fill: ${CSS_COLOR_GREY_5};
          transform: rotate(0);
          transition: transform 200ms;
        }

        .icon-clear {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_GREY_5};
        }

        :host([open]) .icon {
          transform: rotate(180deg);
        }

        :host([focused]) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([invalid]) .icon {
          fill: ${CSS_COLOR_ERROR};
        }

        .input {
          overflow: hidden;
          outline: none;
          border: none;
          pointer-events: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
          padding: 0;
          height: auto;
          min-width: 30px;
          font-size: ${CSS_FONT_SIZE_BODY};
          flex: 1 0 0;
        }

        .menu {
          left: 0;
        }

        .action-group {
          display: flex;
          align-items: center;
        }

        .action-icon {
          width: 16px;
          height: 16px;
        }

        :host([disabled]) .action-icon {
          fill: ${CSS_COLOR_DISABLED};
          cursor: default;
        }
      `,
    ];
  }

  __renderMenu() {
    const renderItemHandler = this.multiSelect
      ? this.__handlers.renderMultiSelectItem
      : this.onRenderItem;

    const selectItemHandler = this.multiSelect
      ? this.__handlers.multiSelect
      : this.__handlers.select;
    return html`
      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu ${this.absolutePosition ? 'floating-menu' : ''}"
        .scrollOnKeydown="${this.scrollOnKeydown}"
        .maxVisibleItems="${this.maxVisibleItems}"
        .itemHeight="${this.itemHeight}"
        .itemMinWidth="${this.itemMinWidth}"
        .thresholdCount="${this.thresholdCount}"
        .selectedIndex="${this.getSelectedIndex()}"
        .items="${this.__displayItems}"
        .forceAlign="${this.forceAlignMenu}"
        .forceDown="${this.forceDown || false}"
        .onChange="${selectItemHandler}"
        .onRequest="${this.__handlers.request}"
        .onRenderItem="${renderItemHandler}"
        ?showFullText="${this.showFullText}"
        ?wrapText="${this.wrapText}"
        ?open="${this.__open}"
        .hasNewItemOption="${!!this.addNewItemLabel}"
        .startingSoftSelectedIndex="${this.useStartingSoftSelectIndex
          ? this.getSelectedIndex()
          : -1}"
        .actionIcon="${this.actionIcon}"
        .onClickActionIcon="${this.onClickActionIcon}"
        ?showTitle="${this.showTitle}"
      ></neb-menu>
    `;
  }

  __renderLabel() {
    return this.label
      ? html`
          <neb-floating-label
            id="${ELEMENTS.label.id}"
            class="floating-label ${this.__hasActionButton
              ? 'action-icon-floating-label'
              : ''}"
            text="${this.getLabel()}"
            ?focused="${this.__focused}"
            ?invalid="${this.__invalid}"
            ?pinned="${this.isPinned()}"
            ?disabled="${this.disabled}"
          ></neb-floating-label>
        `
      : '';
  }

  __renderButtonIcon({ icon = '', onClick = () => {} }) {
    return html`
      <neb-button-icon
        id="action-icon"
        class="action-icon"
        icon="${icon}"
        @click="${e => {
          e.stopPropagation();
          onClick();
        }}"
      ></neb-button-icon>
    `;
  }

  get __isItemsEmpty() {
    return (
      this.items.length === 0 ||
      this.items.filter(item => item.label !== '').length === 0
    );
  }

  get __shouldRenderNewItem() {
    return !!(this.addNewItemLabel && this.__isItemsEmpty);
  }

  get __shouldRenderActionIcon() {
    return !!(this.actionIcon && this.getDisplayValue());
  }

  get __hasActionButton() {
    return this.__shouldRenderNewItem || this.__shouldRenderActionIcon;
  }

  __renderActionButton() {
    if (this.__shouldRenderNewItem) {
      return this.__renderButtonIcon({
        icon: 'neb:plus',
        onClick: () => {
          if (!this.disabled) this.onAddNewItem();
        },
      });
    }

    if (this.__shouldRenderActionIcon) {
      return this.__renderButtonIcon({
        icon: this.actionIcon,
        onClick: () => {
          if (!this.disabled) this.onClickActionIcon(this.value);
        },
      });
    }

    return '';
  }

  __renderClear() {
    return this.enableClear
      ? html`
          <neb-icon
            id="${ELEMENTS.clearIcon.id}"
            class="icon-clear"
            @click="${this.__handlers.clear}"
            icon="neb:clear"
            style="${styleMap({
              visibility: this.value.length ? 'visible' : 'hidden',
            })}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container ${!this.absolutePosition ? 'relative' : ''}">
        <neb-textbox
          id="${ELEMENTS.textbox.id}"
          class="textbox ${!this.absolutePosition ? 'relative' : ''}"
          tabindex="${this.disabled ? '-1' : '0'}"
          ?focused="${this.__focused}"
          ?invalid="${this.__invalid}"
          ?disabled="${this.disabled}"
          @blur="${this.__handlers.blur}"
          @focus="${this.__handlers.focus}"
          @click="${this.__handlers.toggle}"
          @keydown="${this.__handlers.keydown}"
        >
          <div
            class="content ${this.__hasActionButton
              ? 'action-icon-content'
              : ''}"
          >
            ${this.__renderActionButton()}

            <input
              tabindex="-1"
              id="${ELEMENTS.input.id}"
              class="input"
              type="text"
              .value="${this.getDisplayValue()}"
              .placeholder="${this.getPlaceholder()}"
              readonly
            />

            ${this.__renderClear()}
            <neb-icon class="icon" icon="neb:arrow"></neb-icon>
          </div>

          ${this.__renderMenu()}
        </neb-textbox>

        ${this.__renderLabel()}

        <neb-text-helper
          id="${ELEMENTS.helper.id}"
          .text="${this.getHelperText()}"
          ?invalid="${this.__invalid}"
          ?disabled="${this.disabled}"
        ></neb-text-helper>
      </div>
    `;
  }
}

window.customElements.define('neb-select', Select);
