import { LitElement, html, css } from 'lit';

import '../misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/neb-tab-old';
import '../../../packages/neb-lit-components/src/components/neb-tabs-old';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import {
  baseStyles,
  summaryStyles,
} from '../../../packages/neb-styles/neb-styles';
import {
  CSS_BORDER_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
} from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  header: { id: 'header', title: 'Encounter Information' },
  textAppointmentDate: { id: 'text-appointment-date' },
  textAppointmentTimeDuration: { id: 'text-appointment-time-duration' },
  textLocationName: { id: 'text-location-name' },
  textAppointmentType: { id: 'text-appointment-type' },
  textRenderingProvider: { id: 'text-rendering-provider' },
  linkPatientCase: { id: 'link-patient-case' },
  linkAuthorization: { id: 'link-authorization' },
  linkRequestNextAppointment: { id: 'link-request-next-appointment' },
  linkAddNewAppointment: { id: 'link-add-appointment' },
  linkPrintEncounter: { id: 'link-print-encounter' },
  linkChangeRoom: { id: 'link-change-room' },
  linkCopyPriorEncounter: { id: 'link-copy-prior-encounter' },
  linkDeleteEncounter: { id: 'link-delete-encounter' },
  linkOpenVisitSummary: { id: 'link-open-visit-summary' },
  containerAlerts: { id: 'container-alerts' },
  textGuarantor: { id: 'text-guarantor' },
  tabsInsurance: { id: 'tabs-insurance' },
};

export class NebEncounterInfoView extends LitElement {
  static get properties() {
    return {
      model: Object,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      summaryStyles,
      css`
        .header {
          display: flex;
          align-items: center;
          padding-bottom: ${CSS_SPACING};
        }

        .sub-container {
          margin: ${CSS_SPACING_ROW_LARGE} 0;
        }

        .container-row {
          display: flex;
          flex-flow: row wrap;
        }

        .container-item {
          width: 15rem;
          margin: 0.5rem 0;
        }

        .label {
          margin: 0;
        }

        .label-title {
          margin: 0;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .link {
          display: flex;
          align-items: center;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          cursor: pointer;
        }

        .alerts {
          border: ${CSS_BORDER_GREY_1};
          border-radius: 5px;
          padding: ${CSS_SPACING_ROW_LARGE};
        }

        .icon-warning {
          width: 24px;
          height: 20px;
          padding-left: 5px;
          vertical-align: bottom;
        }

        .tabs {
          margin-top: ${CSS_SPACING_ROW_LARGE};
          padding: 0 ${CSS_SPACING};
          margin-bottom: 32px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.model = {
      case: null,
      authorization: null,
    };

    this.onClose = () => {};

    this.onOpenCase = () => {};

    this.onOpenAuthorization = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onClose(),
      openCase: () => this.onOpenCase(),
      openAuthorization: () => this.onOpenAuthorization(),
    };
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${ELEMENTS.header.title}"
        .onCancel="${this.__handlers.close}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderPatientCase() {
    return this.model && this.model.case
      ? html`
          <neb-text
            id="${ELEMENTS.linkPatientCase.id}"
            link
            @click="${this.__handlers.openCase}"
          >
            ${this.model.case}
          </neb-text>
        `
      : '-';
  }

  __renderAuthorization() {
    return this.model && this.model.authorization
      ? html`
          <neb-text
            id="${ELEMENTS.linkAuthorization.id}"
            link
            @click="${this.__handlers.openAuthorization}"
          >
            ${this.model.authorization}
          </neb-text>
        `
      : '-';
  }

  render() {
    return html`
      ${this.__renderHeader()}

      <div class="container-column">
        <div class="sub-container">
          <p id="${ELEMENTS.textAppointmentDate.id}" class="label-title">
            Friday, December 9, 2022
          </p>
          <p
            id="${ELEMENTS.textAppointmentTimeDuration.id}"
            class="label-title"
          >
            11:30 AM - 11:45 AM (15 Minutes)
          </p>
          <p id="${ELEMENTS.textLocationName.id}" class="label-title">
            Location Name
          </p>
        </div>

        <div class="sub-container">
          <p id="${ELEMENTS.textAppointmentType.id}" class="label-title">
            Appointment Type
          </p>
          <p id="${ELEMENTS.textRenderingProvider.id}" class="label">
            Rendering Provider
          </p>
        </div>

        <div class="sub-container container-row">
          <div class="container-item">
            <div class="label-title">Patient Case</div>
            ${this.__renderPatientCase()}
          </div>
          <div class="container-item">
            <div class="label-title">Authorization</div>
            ${this.__renderAuthorization()}
          </div>
        </div>

        <div class="sub-container container-row">
          <neb-button-action
            id="${ELEMENTS.linkRequestNextAppointment.id}"
            class="container-item text-link text-highlight"
            label="Request Next Appointment"
            leadingIcon="plus"
          >
          </neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkAddNewAppointment.id}"
            class="container-item text-link text-highlight"
            label="Add New Appointment"
            leadingIcon="plus"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkPrintEncounter.id}"
            class="container-item text-link text-highlight"
            label="Print Encounter"
            leadingIcon="print"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkChangeRoom.id}"
            class="container-item text-link text-highlight"
            label="Change Room"
            leadingIcon="roomDoor"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkCopyPriorEncounter.id}"
            class="container-item text-link text-highlight"
            label="Copy Prior Encounter"
            leadingIcon="copy"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkDeleteEncounter.id}"
            class="container-item text-link text-highlight"
            label="Delete Encounter"
            leadingIcon="clear"
          >
          </neb-button-action>
          <neb-button-action
            id="${ELEMENTS.linkOpenVisitSummary.id}"
            class="container-item text-link text-highlight"
            label="Open Visit Summary"
            leadingIcon="print"
          >
          </neb-button-action>
        </div>

        <div id="${ELEMENTS.containerAlerts.id}" class="sub-container alerts">
          <div class="sub-container">
            <neb-icon class="icon-warning" icon="neb:warning"></neb-icon>
            <span>Alert!</span>
          </div>
          <div class="sub-container">
            <neb-icon class="icon-warning" icon="neb:warning"></neb-icon>
            <span>Warning!</span>
          </div>
        </div>

        <div class="sub-container">
          <div class="sub-container">
            <div class="label-title">Billing Details</div>
            <div>Insurance</div>
          </div>

          <div class="sub-container">
            <div>Guarantor</div>
            <div id="${ELEMENTS.textGuarantor.id}">Self</div>
          </div>

          <div id="${ELEMENTS.tabsInsurance.id}" class="sub-container">
            <neb-tabs-old class="tabs">
              <neb-tab-old>Primary</neb-tab-old>
              <neb-tab-old>Secondary</neb-tab-old>
              <neb-tab-old>Tertiary</neb-tab-old>
            </neb-tabs-old>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-encounter-info-view', NebEncounterInfoView);
