/* istanbul ignore file */
// Above 'ignore' is temporary - TODO write comprehensive Unit Tests

import { openPopup } from '@neb/popup';
import { html, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  deleteConversion,
  getConversionJobs,
  putConversionSshKey,
} from '../../../api-clients/conversions';
import '../../pages/conversions/neb-conversion-page';
import { clearSingleTenantCache } from '../../../api-clients/registry';

const SUCCESS_CACHE_CLEAR_MESSAGE = 'Tenant cache cleared successfully';

const ERROR_CACHE_CLEAR_MESSAGE = 'issue with clearing cache for tenant';

export const ELEMENTS = {
  conversionPage: { id: 'conversionPage' },
};

class NebControllerConversion extends LitElement {
  static get properties() {
    return {
      jobs: Array,
      model: Object,
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.jobs = [];
    this.model = {};

    this.onCopy = () => {};

    this.onDelete = () => {};
  }

  initHandlers() {
    this.handlers = {
      copy: () => this.__copy(),
      delete: () => this.__delete(),
      generatePrivateKey: () => this.__generatePrivateKey(),
      refreshJobs: () => this.__fetchJobs(),
      selectJob: job => this.__selectJob(job),
      clearCache: () => this.__clearTenantCache(),
    };
  }

  __copy() {
    console.log(this.model);
    openOverlay(OVERLAY_KEYS.CONVERSION_COPY, {
      conversionId: this.model.id,
      tenantId: this.model.tenantId,
    });
  }

  async __delete() {
    const deleteConfirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Delete Conversion',
      message: 'Are you sure you want to delete this conversion?',
    });

    if (!deleteConfirmed) {
      return;
    }

    try {
      await deleteConversion(this.model);

      store.dispatch(openSuccess('Conversion deleted successfully!'));

      this.onDelete(this.model);
    } catch (err) {
      console.log(err);
      store.dispatch(
        openError('An error occurred when deleting the Conversion'),
      );
    }
  }

  async __generatePrivateKey() {
    const {
      data: [sshKey],
    } = await putConversionSshKey(this.model);

    this.model = { ...this.model, ...sshKey };
  }

  __selectJob(job) {
    openOverlay(OVERLAY_KEYS.CONVERSION_JOB, {
      item: { conversion: this.model, job },
    });
  }

  async __fetchJobs() {
    const { data: jobs } = await getConversionJobs(this.model);

    this.jobs = jobs;
  }

  async __clearTenantCache() {
    const { tenantId } = this.model;

    try {
      await clearSingleTenantCache(tenantId);
      store.dispatch(openSuccess(SUCCESS_CACHE_CLEAR_MESSAGE));
    } catch (err) {
      console.log('error :>> ', err);
      store.dispatch(openError(ERROR_CACHE_CLEAR_MESSAGE));
    }
  }

  render() {
    return html`
      <neb-conversion-page
        id="${ELEMENTS.conversionPage.id}"
        .jobs="${this.jobs}"
        .model="${this.model}"
        .onCopy="${this.handlers.copy}"
        .onDelete="${this.handlers.delete}"
        .onGeneratePrivateKey="${this.handlers.generatePrivateKey}"
        .onRefreshJobs="${this.handlers.refreshJobs}"
        .onSelectJob="${this.handlers.selectJob}"
        .onClearCache="${this.handlers.clearCache}"
      ></neb-conversion-page>
    `;
  }

  async firstUpdated() {
    await this.__fetchJobs();
  }
}

customElements.define('neb-controller-conversion', NebControllerConversion);
