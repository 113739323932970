import { objToName, DEFAULT_NAME_OPTS } from '../../neb-utils/formatters';
import { activeProviders } from '../../neb-utils/provider';

import { ProviderService } from './provider';

export class MdcProviderService {
  constructor(callback) {
    this.onProvidersChanged = callback;
    this.__providerService = new ProviderService(providerConfig =>
      this.__formatProviders(providerConfig),
    );
  }

  connect() {
    this.__providerService.connect();
  }

  disconnect() {
    this.__providerService.disconnect();
  }

  __formatProvider({ id, name }) {
    return {
      id,
      value: objToName(name, DEFAULT_NAME_OPTS),
    };
  }

  __formatProviders({ selectedProvider, providers }) {
    const formattedItems = {
      selectedProvider: selectedProvider
        ? this.__formatProvider(selectedProvider)
        : '',
      activeProviders: activeProviders(providers).map(this.__formatProvider),
      allProviders: providers.map(this.__formatProvider),
    };

    this.onProvidersChanged(formattedItems);
  }
}
