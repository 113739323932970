import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { store } from '../../packages/neb-redux/neb-redux-store';

export const INBOUND_FILE_TYPE = Object.freeze({
  ACKNOWLEDGEMENT_999: 1,
  EOB_PAYMENT_835: 2,
  OTHER_FILE: 3,
  STATUS_RESPONSE_277: 4,
});

const apiClient = new DeprecatedApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const getInboundFiles = async fileType => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `inbound-files?fileType=${fileType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
  });

  return res.data;
};

const __createFileFormData = (file, clearinghouse) => {
  const data = new FormData();
  data.append('file', file);
  data.append('clearinghouseId', clearinghouse.id);
  data.append('clearinghousePartnerName', clearinghouse.partner);
  data.append('createdBy', store.getState().session.item.id);

  return data;
};

export const upload999File = async (
  file,
  optOutLoadingIndicator = false,
  clearinghouse,
) => {
  const data = __createFileFormData(file, clearinghouse);

  await apiClient.makeRequest({
    optOutLoadingIndicator,
    path: 'acknowledgement-999/upload',
    headers: {},
    body: data,
    method: Method.POST,
    version: 1,
  });
};

export const upload277File = async (
  file,
  optOutLoadingIndicator = false,
  clearinghouse,
) => {
  const data = __createFileFormData(file, clearinghouse);

  await apiClient.makeRequest({
    optOutLoadingIndicator,
    path: 'response-status-277/upload',
    headers: {},
    body: data,
    method: Method.POST,
    version: 1,
  });
};

export const resend999File = async (
  inboundFiles,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    optOutLoadingIndicator,
    path: 'acknowledgement-999/resend',
    headers: {
      'Content-Type': 'application/json',
    },
    body: { inboundFiles },
    method: Method.POST,
    version: 1,
  });
};

export const resend277File = async (
  inboundFiles,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    optOutLoadingIndicator,
    path: 'response-status-277/resend',
    headers: {
      'Content-Type': 'application/json',
    },
    body: { inboundFiles },
    method: Method.POST,
    version: 1,
  });
};
