import * as itemActions from '../../actions/item-actions';

import * as fetchHandler from './handlers/fetch';

const __getStaleState = state => ({ ...state, stale: true });

const __setLimit = (state, action) => ({
  ...state,
  stale: false,
  limit: action.limit,
  query: action.query,
  currentPage: 0,
  totalCount: 0,
  pages: {},
});

export const paginatedItemsReducer = (
  state = {
    stale: false,
    limit: 100,
    query: {},
    currentPage: 0,
    totalCount: 0,
    pages: {},
  },
  action,
) => {
  switch (action.type) {
    case itemActions.ITEM_PAGINATION_SET_QUERY:
      return __setLimit(state, action);

    case itemActions.ITEM_FETCHING:
      return fetchHandler._fetching(state, action);

    case itemActions.ITEM_FETCHED:
      return fetchHandler._fetched(state, action);

    case itemActions.ITEM_FETCH_ERROR:
      return fetchHandler._fetchError(state, action);

    case itemActions.ITEM_CREATED:
    case itemActions.ITEM_UPDATED:
    case itemActions.ITEM_DELETED:
      return __getStaleState(state);

    default:
      return state;
  }
};
