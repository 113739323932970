import ApiClientV2 from '../../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });
export const BASE_PATH = '/api/v1/tenants/:tenantId/era/:eraId/charges/match';

export const getPossibleMatchedLineItems = ({
  patientId,
  claimReportId,
  eraId,
}) =>
  apiClient.makeRequest({
    path: BASE_PATH,
    queryParams: { patientId, claimReportId },
    replacements: { eraId },
    cacheKey: `era/${eraId}/charges/match`,
  });
