import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  layoutStyles,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_5,
} from '../../../styles';
import {
  loadImage,
  getNextZoomInValue,
  getNextZoomOutValue,
  rotateImage,
} from '../processor/image-processor';
import * as pageNavigatorProcessor from '../processor/page-navigator-processor';
import { getPageImage, saveImage } from '../processor/scan-processor';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../packages/neb-styles/neb-icons';

export const ELEMENTS = {
  imageViewPanel: { id: 'image-view-area' },
  canvas: {
    id: 'canvas',
  },
  buttonIconZoomIn: { id: 'button-icon-zoom-in' },
  buttonIconZoomOut: { id: 'button-icon-zoom-out' },
  buttonIconZoomReset: { id: 'button-icon-zoom-reset' },
  buttonIconRotateClockwise: { id: 'button-icon-rotate-clockwise' },
  buttonIconRotateCounterClockwise: {
    id: 'button-icon-rotate-counter-clockwise',
  },
  buttonIconDeletePage: {
    id: 'button-icon-delete-page',
  },
  buttonIconFirst: { id: 'button-icon-first' },
  buttonIconPrevious: { id: 'button-icon-previous' },
  buttonIconNext: { id: 'button-icon-next' },
  buttonIconLast: { id: 'button-icon-last' },
  pageNavigationText: { id: 'page-navigation-text' },
  buttonClose: { id: 'button-close' },
};

const IMAGE_PREVIEW_PADDING = 5;
const IMAGE_PREVIEW_PADDING_CSS = css`
  ${IMAGE_PREVIEW_PADDING}px
`;

class NebDocumentImageViewer extends LitElement {
  static get properties() {
    return {
      scanResult: Object,
      __zoom: Number,
      __pageNavigation: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.scanResult = { count: 0 };
    this.__zoom = 100;

    this.__pageNavigation = pageNavigatorProcessor.reset();

    this.onClose = () => {};

    this.onLoadingUpdated = () => {};

    this.onDeletePage = () => {};
  }

  __getPageNavigationText() {
    return `Page ${this.__pageNavigation.currentPage} of ${
      this.__pageNavigation.totalPages
    }`;
  }

  __initHandlers() {
    this.__handlers = {
      onClose: () => this.onClose(),
      zoomIn: () => {
        this.__zoom = getNextZoomInValue(this.__zoom);
      },
      zoomOut: () => {
        this.__zoom = getNextZoomOutValue(this.__zoom);
      },
      zoomReset: () => {
        if (this.__zoom === 100) {
          this.__loadImage();
        } else {
          this.__zoom = 100;
        }
      },
      first: () => {
        this.__pageNavigation = pageNavigatorProcessor.first();
      },
      previous: () => {
        this.__pageNavigation = pageNavigatorProcessor.previous();
      },
      next: () => {
        this.__pageNavigation = pageNavigatorProcessor.next();
      },
      last: () => {
        this.__pageNavigation = pageNavigatorProcessor.last();
      },
      rotateClockwise: () => this.__rotateImage(true),
      rotateCounterClockwise: () => this.__rotateImage(false),
      deletePage: () => this.__deletePage(),
    };
  }

  async __rotateImage(clockwise = false) {
    const imageSrc = await getPageImage(this.__pageNavigation.currentPage);
    const rotatedImage = await rotateImage(imageSrc, clockwise);

    saveImage(rotatedImage, this.__pageNavigation.currentPage);
    await this.__loadImage();
  }

  async __loadImage() {
    if (!this.__pageNavigation.hasPages) {
      return;
    }

    const imageSrc = await getPageImage(this.__pageNavigation.currentPage);
    const canvas = this.shadowRoot.getElementById(ELEMENTS.canvas.id);
    const contentSize = this.__getImageContentSize(this.__zoom);
    await loadImage({ canvas, imageSrc, contentSize });
  }

  __deletePage() {
    if (this.__pageNavigation.canDeletePage) {
      this.onDeletePage(this.__pageNavigation.currentPage);
    }
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          width: 100%;
          height: 100%;
        }

        .two-rows-grid {
          display: grid;
          height: 100%;
          grid-template-rows: auto 1fr;
        }

        .row-image {
          overflow: auto;
          height: 100%;
          width: 100%;
          background: LightGray;
        }

        .canvas {
          padding-left: ${IMAGE_PREVIEW_PADDING_CSS};
          padding-top: ${IMAGE_PREVIEW_PADDING_CSS};
        }

        .toolbox {
          height: 40px;
          display: flex;
          flex-direction: row;
        }

        .toolbox-left {
          flex: 1;
        }

        .toolbox-middle {
          flex: 1;
        }

        .toolbox-right {
          flex: 0 0 40px;
        }

        .toolbox-main {
          height: 40px;
          display: flex;
          flex-direction: row;
        }

        .label {
          line-height: 40px;
          vertical-align: middle;
          color: ${CSS_COLOR_GREY_5};
        }

        .button-icon {
          width: 32px;
          height: 32px;
          margin: 4px 0px 4px 4px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .button-group-divider {
          width: 40px;
        }

        .page-navigator {
          height: 44px;
          display: flex;
          flex-direction: row;
        }

        .button-cancel {
          outline: none;
          cursor: pointer;
          display: flex;
          border: none;
          margin: 0;
          padding: 0;
          background-color: transparent;
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  __getImageContentSize(zoom) {
    const content = this.shadowRoot.getElementById(ELEMENTS.imageViewPanel.id);

    if (content && content.clientHeight && content.clientWidth) {
      return {
        width: parseInt(
          ((content.clientWidth - IMAGE_PREVIEW_PADDING * 2) * zoom) / 100,
          10,
        ),
        height: parseInt(
          ((content.clientHeight - IMAGE_PREVIEW_PADDING * 2) * zoom) / 100,
          10,
        ),
      };
    }

    return {
      width: null,
      height: null,
    };
  }

  updated(changedProps) {
    if (changedProps.has('scanResult')) {
      this.__pageNavigation = pageNavigatorProcessor.init(
        this.scanResult.count,
      );
    }

    if (
      (changedProps.has('__pageNavigation') || changedProps.has('__zoom')) &&
      this.scanResult.count > 0
    ) {
      this.__loadImage();
    }

    super.updated(changedProps);
  }

  __renderPageNavigation() {
    return html`
      <div class="page-navigator">
        <neb-button-icon
          id="${ELEMENTS.buttonIconFirst.id}"
          class="button-icon"
          icon="neb:firstDoubleArrow"
          .disabled="${!this.__pageNavigation.canGoToPrevious}"
          .onClick="${this.__handlers.first}"
        ></neb-button-icon>
        <neb-button-icon
          id="${ELEMENTS.buttonIconPrevious.id}"
          class="button-icon"
          icon="neb:previousArrow"
          .disabled="${!this.__pageNavigation.canGoToPrevious}"
          .onClick="${this.__handlers.previous}"
        ></neb-button-icon>
        <div id="${ELEMENTS.pageNavigationText.id}" class="label">
          ${this.__getPageNavigationText()}
        </div>
        <neb-button-icon
          id="${ELEMENTS.buttonIconNext.id}"
          class="button-icon"
          icon="neb:nextArrow"
          .disabled="${!this.__pageNavigation.canGoToNext}"
          .onClick="${this.__handlers.next}"
        ></neb-button-icon>
        <neb-button-icon
          id="${ELEMENTS.buttonIconLast.id}"
          class="button-icon"
          icon="neb:lastDoubleArrow"
          .disabled="${!this.__pageNavigation.canGoToNext}"
          .onClick="${this.__handlers.last}"
        ></neb-button-icon>
      </div>
    `;
  }

  __renderCloseButton() {
    return html`
      <neb-button-icon
        id="${ELEMENTS.buttonClose.id}"
        class="button-icon"
        icon="neb:close"
        .onClick="${this.__handlers.onClose}"
      ></neb-button-icon>
    `;
  }

  __renderToolbox() {
    return html`
      <neb-button-icon
        id="${ELEMENTS.buttonIconRotateClockwise.id}"
        class="button-icon"
        icon="neb:rotateClockwise"
        .onClick="${this.__handlers.rotateClockwise}"
        .disabled="${!this.__pageNavigation.hasPages}"
      ></neb-button-icon>

      <neb-button-icon
        id="${ELEMENTS.buttonIconRotateCounterClockwise.id}"
        class="button-icon"
        icon="neb:rotateCounterClockwise"
        .onClick="${this.__handlers.rotateCounterClockwise}"
        .disabled="${!this.__pageNavigation.hasPages}"
      ></neb-button-icon>

      <div class="button-group-divider"></div>

      <neb-button-icon
        id="${ELEMENTS.buttonIconDeletePage.id}"
        class="button-icon"
        icon="neb:delete"
        .onClick="${this.__handlers.deletePage}"
        .disabled="${!this.__pageNavigation.canDeletePage}"
      ></neb-button-icon>

      <div class="button-group-divider"></div>

      <neb-button-icon
        id="${ELEMENTS.buttonIconZoomIn.id}"
        class="button-icon"
        icon="neb:zoomIn"
        .onClick="${this.__handlers.zoomIn}"
        .disabled="${!this.__pageNavigation.hasPages}"
      ></neb-button-icon>

      <neb-button-icon
        id="${ELEMENTS.buttonIconZoomOut.id}"
        class="button-icon"
        icon="neb:zoomOut"
        .onClick="${this.__handlers.zoomOut}"
        .disabled="${!this.__pageNavigation.hasPages}"
      ></neb-button-icon>

      <neb-button-icon
        id="${ELEMENTS.buttonIconZoomReset.id}"
        class="button-icon"
        icon="neb:zoomReset"
        .onClick="${this.__handlers.zoomReset}"
        .disabled="${!this.__pageNavigation.hasPages}"
      ></neb-button-icon>
    `;
  }

  __renderImage() {
    return html` <canvas id="${ELEMENTS.canvas.id}" class="canvas"></canvas> `;
  }

  render() {
    return html`
      <div class="container">
        <div class="two-rows-grid">
          <div class="toolbox">
            <div class="toolbox-left">
              <div class="toolbox-main">${this.__renderToolbox()}</div>
            </div>
            <div class="toolbox-middle">
              <div class="toolbox-main">${this.__renderPageNavigation()}</div>
            </div>
            <div class="toolbox-right">${this.__renderCloseButton()}</div>
          </div>
          <div class="row-image" id="${ELEMENTS.imageViewPanel.id}">
            ${this.__renderImage()}
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-document-image-viewer', NebDocumentImageViewer);
