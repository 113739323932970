import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';

import { html, css } from 'lit';

import { getAppointmentTypes } from '../../../packages/neb-api-client/src/appointment-types';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectAppointmentType: {
    id: 'select-appointment-type',
  },
};

class NebPopupUpdateAppointmentType extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      model: Object,
      __appointmentTypes: Array,
      __appointmentTypeSearchItems: Array,
      __selectedAppointmentTypeId: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 650px;
          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .appointment-type {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = 'Update Appointment Type';
    this.model = {};

    this.__selectedAppointmentTypeId = '';
    this.__appointmentTypeSearchItems = [];
    this.__appointmentTypes = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ value }) => {
        this.__selectedAppointmentTypeId = value ? value.id : '';
      },
      search: ({ value }) => {
        const searchTerms = value.toLowerCase();

        this.__appointmentTypeSearchItems = this.__appointmentTypes.filter(
          type => type.label.toLowerCase().includes(searchTerms),
        );
      },
      confirm: () => {
        this.onClose({
          appointmentTypeId: this.__selectedAppointmentTypeId,
        });
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model.appointmentTypeId) {
      this.__selectedAppointmentTypeId = this.__appointmentTypes.find(
        type => type.id === this.model.appointmentTypeId,
      ).id;
    }
  }

  async connectedCallback() {
    await this.__loadAppointmentTypes();
    super.connectedCallback();
  }

  async __loadAppointmentTypes() {
    const res = await getAppointmentTypes();

    this.__appointmentTypes = res.data.map(a => ({ ...a, label: a.name }));
  }

  __getSelectedAppointmentType() {
    return this.__appointmentTypes.find(
      type => type.id === this.__selectedAppointmentTypeId,
    );
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="container-select">
          <neb-select-search
            id="${ELEMENTS.selectAppointmentType.id}"
            class="appointment-type"
            label="Appointment Type"
            .items="${this.__appointmentTypeSearchItems}"
            .value="${this.__getSelectedAppointmentType()}"
            .emptyMessage="No Appointment Types Found"
            .onSearch="${this.handlers.search}"
            .onChange="${this.handlers.change}"
            showSearch
          ></neb-select-search>
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="Save"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.confirm}"
            ?disabled="${!this.__selectedAppointmentTypeId}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-update-appointment-type',
  NebPopupUpdateAppointmentType,
);
