import { LitElement, html, svg, css } from 'lit';

import { baseStyles } from '../../styles';

export const ELEMENTS = {};

export default class Icon extends LitElement {
  static get properties() {
    return {
      icon: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: 0;
          height: 0;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .svg {
          display: block;
          flex: 1 0 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__renderer = {
      viewBox: '0 0 10 10',
      src: svg``,
    };

    this.icon = '';
  }

  update(changedProps) {
    if (changedProps.has('icon') && this.icon) {
      const [setName, iconName] = this.icon.split(':');
      const iconSet = Icon.iconSets[setName];

      if (iconSet[iconName]) {
        this.__renderer = iconSet[iconName];
      }
    }

    super.update(changedProps);
  }

  render() {
    return html`
      <div class="container">
        <svg
          class="svg"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="${this.__renderer.viewBox}"
        >
          ${this.__renderer.src}
        </svg>
      </div>
    `;
  }
}

Icon.iconSets = {};
window.customElements.define('neb-icon', Icon);
