import '@polymer/iron-icon/iron-icon';
import '../../../neb-styles/neb-icons';

import { LitElement, html } from 'lit';

import { CSS_COLOR_HIGHLIGHT } from '../../../neb-styles/neb-variables';

class NebToggleChevron extends LitElement {
  constructor() {
    super();
    this.expanded = false;
  }

  static get properties() {
    return {
      expanded: {
        type: Boolean,
        reflect: true,
      },
      onToggle: {
        type: Function,
      },
    };
  }

  render() {
    return html`
      <style>
        :host {
          --icon-height: 10px;
          width: 10px;
          height: 10px;
        }

        :host([expanded]) .icon {
          transform: rotate(90deg);
        }

        .icon {
          transform: rotate(270deg);
          cursor: pointer;
          width: var(--icon-height);
          height: var(--icon-height);
          color: ${CSS_COLOR_HIGHLIGHT};
        }
      </style>
      <div id="chevron" @click="${this.__toggleExpanded}">
        <iron-icon class="icon" name="arrowIcon" icon="neb:back"></iron-icon>
      </div>
    `;
  }

  __toggleExpanded() {
    this.expanded = !this.expanded;
    if (this.onToggle) this.onToggle(this);
  }
}

customElements.define('neb-toggle-chevron', NebToggleChevron);
