export const getLoadingIndicatorOn = message => ({ loading: true, message });

export const getLoadingIndicatorOff = () => ({
  loading: false,
  message: '',
});

export const LOADING_INDICATOR_MESSAGE = Object.freeze({
  SCANNING_DOCUMENT: 'Scanning document...',
  SAVING_DOCUMENT: 'Saving document...',
  LOADING_SCANNER_LIST: 'Loading list of scanners...',
  LOADING_SCANNER_CONFIGURATION: 'Loading scanner configuration...',
  LOADING_SCANNER_DOCUMENT_SOURCE_CONFIGURATION:
    'Loading scanner document source configuration...',
});
