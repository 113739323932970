export const ACTION_SET_LAYOUT = 'NEB_LAYOUT_MEDIA_SET_LAYOUT';
export const ACTION_SET_SMALL_NAV_EXPANDED =
  'NEB_LAYOUT_MEDIA_SET_SMALL_NAV_EXPANDED';
export const ACTION_SET_STATIC_LAYOUT = 'NEB_LAYOUT_MEDIA_SET_STATIC_LAYOUT';

export const DEFAULT_STATE = {
  layout: '',
  large: false,
  medium: false,
  small: false,
  touchDevice: false,
  smallNavExpanded: false,
};

export const layoutMedia = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_LAYOUT:
      return {
        ...state,
        layout: action.layout.layout,
        large: action.layout.large,
        medium: action.layout.medium,
        small: action.layout.small,
      };

    case ACTION_SET_SMALL_NAV_EXPANDED:
      return { ...state, smallNavExpanded: action.smallNavExpanded };

    case ACTION_SET_STATIC_LAYOUT:
      return { ...state, ...action.state };

    default:
      return state;
  }
};
