import '../../../../../src/components/misc/neb-icon';
import '../inputs/neb-select';
import '../neb-date-picker';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FIELD_MARGIN,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';

export const ELEMENTS = {
  typeField: {
    id: 'select-type',
  },
  dateField: {
    id: 'textfield-date',
  },
  removeButton: {
    id: 'button-remove',
  },
  removeButtonList: {
    selector: '#button-remove',
  },
};

class NebDateTypeItem extends LitElement {
  static get properties() {
    return {
      name: String,
      model: Object,
      errors: Object,
      items: Array,
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showRemoveButton = false;
    this.name = '';
    this.errors = {
      type: '',
      date: '',
    };

    this.items = [];
    this.model = {
      date: '',
      type: {
        id: '',
        label: '',
      },
    };

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      remove: () => this.onRemove(this.name),
      change: ({ name, value }) =>
        this.onChange({ name: `${this.name}.${name}`, value }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          margin-bottom: 5px;
        }

        .field {
          width: inherit;
          min-width: 0;
          flex: 1 0 0;
        }

        .field-type {
          margin-right: ${CSS_SPACING};
        }

        .field-date {
          padding-top: ${CSS_FIELD_MARGIN};
        }

        .container-date {
          display: flex;
          flex: 1 0 0;
        }

        .icon {
          cursor: pointer;
          margin: 22px 0 22px 0;
          width: 27px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderRemoveItemButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeButton.id}"
            class="icon"
            icon="neb:minus"
            @click="${this.__handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <neb-select
          id="${ELEMENTS.typeField.id}"
          class="field field-type"
          name="type"
          label="Date Type"
          .maxVisibleItems="${this.items.length}"
          .items="${this.items}"
          .value="${this.model.type}"
          .error="${this.errors.type}"
          .helper="${this.errors.type || ' '}"
          .onChange="${this.__handlers.change}"
        ></neb-select>

        <div class="container-date">
          <neb-date-picker
            id="${ELEMENTS.dateField.id}"
            class="field field-date"
            name="date"
            placeholder="Date${this.__errors.date ? '*' : ''}"
            momentFlag
            .value="${this.model.date}"
            .selectedDate="${parseDate(this.model.date)}"
            .invalidText="${this.errors.date}"
            .onChange="${this.__handlers.change}"
            ?invalid="${!!this.errors.date}"
          ></neb-date-picker>

          ${this.renderRemoveItemButton()}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-date-type-item', NebDateTypeItem);
