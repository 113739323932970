import DeprecatedApiClient, { Method } from '../utils/api-client-utils';
import { encountersNotBalancedCount } from '../utils/encounters-not-balanced';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const getLedgerBillingTotals = async () => {
  const [encountersNotBalanced, { activity, ...totals }] = await Promise.all([
    encountersNotBalancedCount(),
    apiClient.makeRequest({
      method: Method.GET,
      path: 'ledger/totals',
      headers: { 'Content-Type': 'application/json' },
      version: 3,
      cacheKey: 'ledger/totals',
    }),
  ]);

  return {
    ...totals,
    activity: { ...activity, encountersNotBalanced },
    payments: {
      unallocated: totals.payments.unallocatedCredits,
      warning: totals.payments.warning,
    },
  };
};
