import equal from 'fast-deep-equal';

import { fetchDefaultForEncounter } from '../../../../../../../src/api-clients/fee-schedules';
import { getEncounterCharges } from '../../../../../../neb-api-client/src/services/encounter-charge';
import { mapEncounterCharge } from '../../../../../../neb-utils/fee-schedule';
import { formatEncounterNumber } from '../../../../../../neb-utils/neb-encounters-util';
import { EMPTY_SELECT_VALUE } from '../../utils';

const setupEncounters = (encounters, patientId, locations) =>
  encounters.map(enc => {
    const location = locations.find(l => l.id === enc.locationId);
    const locationName = location ? location.name : '';
    return {
      ...enc,
      patientId,
      label: `(${formatEncounterNumber(enc)}) - ${enc.fullDate} ${
        enc.appointmentType
      }, ${enc.provider}`,
      locationName,
    };
  });

const setupCharges = (charges, feeSchedule) =>
  charges.map(charge => ({
    ...mapEncounterCharge(charge, feeSchedule),
    modifiers: [
      charge.modifier_1,
      charge.modifier_2,
      charge.modifier_3,
      charge.modifier_4,
    ],
    posted: !!charge.postedToLedgerId,
    repost: false,
  }));

export default async ({
  patientId = '',
  patientEncounters = [],
  patientCharges = [],
  patientDiagnoses = [],
  selectedEncounter = EMPTY_SELECT_VALUE,
  locations = [],
} = {}) => {
  const encounters = setupEncounters(patientEncounters, patientId, locations);

  const encounter = encounters.length === 1 ? encounters[0] : selectedEncounter;

  if (equal(encounter, EMPTY_SELECT_VALUE)) {
    return {
      encounters,
      charges: [],
      diagnoses: [],
      selectedEncounter,
      encounterCharges: [],
    };
  }
  const { id: encounterId, appointmentId, providerId } = encounter;
  const feeSchedule = await fetchDefaultForEncounter(
    patientId,
    appointmentId,
    providerId,
  );
  const charges = setupCharges(patientCharges, feeSchedule);
  const diagnoses = patientDiagnoses.map(d => ({
    item: d,
    label: `${d.code} - ${d.shortDescription}`,
  }));

  const encounterCharges = await getEncounterCharges(charges, encounterId);
  return {
    encounters,
    charges,
    diagnoses,
    selectedEncounter: encounter,
    encounterCharges,
  };
};
