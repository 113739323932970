import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';

export const BASE_URL = 'claims-data';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export function updateClaimsDataV1(body) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: BASE_URL,
    body: JSON.stringify(body),
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
}

export async function updateClaimsDataV2(body) {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: BASE_URL,
    body: JSON.stringify(body),
    version: 2,
  });

  return res.numOfClaimsUpdated;
}
