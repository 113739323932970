import { css, html } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
} from '../../styles';
import {
  getRTEStatusIcon,
  RTE_STATUSES,
} from '../../utils/real-time-eligibility';

import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../misc/neb-icon';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonIcons: { selector: '.button-icon' },
  dates: { selector: '.date' },
  statusIcons: { selector: '.icon-status' },
  reportIcons: { selector: '.icon-report' },
};

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

export default class TableRTEInsuranceLog extends Table {
  static get config() {
    return [
      {
        key: 'submittedAt',
        label: 'Date and Time',
        flex: css`1 0 0`,
        sortable: true,
        formatter: value => value.format(DATE_FORMAT),
        compare: (a, b) => {
          if (a.isSame(b)) {
            return 0;
          }
          return a.isBefore(b) ? -1 : 1;
        },
        mobile: true,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`0 0 100px`,
        mobile: true,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`2 0 0`,
        mobile: true,
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-icon {
          display: flex;
          align-items: center;
        }

        .icon-status {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated,
        .icon-partially-validated {
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .button-icon {
          margin-left: 10px;
        }

        .icon-report {
          width: 20px;
          height: 20px;
        }

        .report-spacer {
          width: 50px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = this.constructor.config;

    this.onOpenS3 = () => {};

    this.onOpenReport = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      openS3: rteRecordId => this.onOpenS3(rteRecordId),
      openReport: (rteRecordId, rowIndex) => {
        const { submittedAt } = this.model[rowIndex];
        const dateString = submittedAt.format(DATE_FORMAT);
        this.onOpenReport(rteRecordId, dateString);
      },
    };
  }

  __getJSONReadable(status) {
    return (
      status !== RTE_STATUSES.ERROR_CHC_INTERNAL &&
      status !== RTE_STATUSES.ERROR_CHC_VALIDATION &&
      status !== RTE_STATUSES.ERROR_INTERNAL
    );
  }

  renderDataCell(value, columnConfig, rowIndex, _name, _error) {
    const { id, chcJSONExists } = this.model[rowIndex];
    const { status } = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'status': {
        return html`
          <div class="container-icon">
            <neb-icon
              class="icon-status icon-${value}"
              .icon="${getRTEStatusIcon(value)}"
            ></neb-icon>

            ${chcJSONExists
              ? html`
                  <neb-button-icon
                    class="button-icon"
                    icon="neb:open"
                    .onClick="${() => this.handlers.openS3(id, rowIndex)}"
                  ></neb-button-icon>
                `
              : ''}
          </div>
        `;
      }

      case 'submittedAt': {
        return html` <neb-text class="date">${value}</neb-text> `;
      }

      case 'eligibilityReport': {
        return chcJSONExists && this.__getJSONReadable(status)
          ? html`
              <div class="report-spacer"></div>
              <neb-button-icon
                class="icon-report"
                icon="neb:eligibilityReport"
                .onClick="${() => this.handlers.openReport(id, rowIndex)}"
              ></neb-button-icon>
              <div class="report-spacer"></div>
            `
          : '';
      }

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

window.customElements.define(
  'neb-table-rte-insurance-log',
  TableRTEInsuranceLog,
);
