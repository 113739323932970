import '../controls/neb-switch';
import '../inputs/neb-textfield';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-tooltip';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { countScheduledPaymentsByAccount } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../../neb-utils/constants';
import { required } from '../../../../neb-utils/validators';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';
import { MERCHANT_PROVIDER_OPTIONS } from './utils/index';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  isDefault: {
    id: 'is-default',
  },
  merchantProviderSelect: {
    id: 'merchant-provider-select',
  },
  name: {
    id: 'name',
  },
  cnpTitleTooltip: {
    id: 'npm-title-tooltip',
  },
  cnpXWebId: {
    id: 'cnp-web-id',
  },
  cnpXWebIdTooltip: {
    id: 'cnp-xweb-id-tooltip',
  },
  cnpTID: {
    id: 'cnp-tid',
  },
  cnpTIDTooltip: {
    id: 'cnp-tid-tooltip',
  },
  cnpAuthKey: {
    id: 'cnp-auth-key',
  },
  cnpAuthKeyTooltip: {
    id: 'cnp-auth-key-tooltip',
  },
  geniusTooltip: {
    id: 'genius-tooltip',
  },
  geniusXWebId: {
    id: 'genius-xweb-id',
  },
  geniusXWebIdTooltip: {
    id: 'genius-xweb-id-tooltip',
  },
  geniusTID: {
    id: 'genius-terminal-id',
  },
  geniusTIDTooltip: {
    id: 'genius-terminal-id-tooltip',
  },
  geniusAuthKey: {
    id: 'genius-auth-key',
  },
  geniusAuthKeyTooltip: {
    id: 'genius-auth-key-tooltip',
  },
  merchantId: {
    id: 'merchant-id',
  },
  accountUsername: {
    id: 'account-user-name',
  },
  accountPassword: {
    id: 'account-password',
  },
  active: {
    id: 'active',
  },
};

const OPEN_EDGE_TOOLTIP_TEXT =
  'This is a credential used to identify your merchant account. Global Payments (OpenEdge) will provide you with your merchant account credentials, and will help configure them for your practice. Contact Global Payments (OpenEdge) to update your merchant account credentials.';

const DUPLICATE_MSG = {
  title: 'Duplicate Merchant Account Name',
  message:
    'There is an existing merchant account for this practice with the same name. Please change the name to save this merchant account.',
};
export const DUPLICATE_CREDENTIALS_MSG = {
  title: 'Duplicate Merchant Credentials',
  message:
    'Moto and Retail account credentials are typically different, please verify you have the correct credentials. Incorrect credentials will cause transactions to fail. Do you want to proceed?',
};

export const MERCHANT_ACCOUNT_IN_USE_MSG = {
  title: 'Merchant Account In Use',
  message:
    'This merchant account is associated with one or more scheduled, recurring or payment plan payments. You must stop the payments, resolve any failed payments, or change the merchant account before inactivating this merchant account.',
  confirmText: 'Ok',
};

export const MERCHANT_OWNER_MSG = {
  title: 'Merchant Account Is An Owner',
  message:
    'This Merchant Account is the owner of at least one Card Reader.  The Card Reader will not be available if this Merchant Account is deactivated.',
  confirmText: 'Ok',
};

export const ONLINE_PAYMENTS_IN_USE_MSG = {
  title: 'Merchant Account In Use',
  message:
    'This merchant account is associated with patient online payments. You must select a different merchant account for patient online payments or disable patient online payments before inactivating this merchant account.',
  confirmText: 'Ok',
};

export const PASSWORD_PLACEHOLDER = '********';

export default class NebFormMerchantAccount extends NebForm {
  static get properties() {
    return {
      __passwordExists: Boolean,
      accounts: Array,
      allCardReaders: Array,
    };
  }

  static createModel() {
    return {
      id: '',
      isDefault: false,
      merchantProvider: null,
      name: '',
      cnpXWebId: '',
      cnpTID: '',
      cnpAuthKey: '',
      geniusXWebId: '',
      geniusTID: '',
      geniusAuthKey: '',
      active: true,
      cardReaders: [],
      isOnlinePaymentsDefault: false,
      merchantId: '',
      accountUsername: '',
      accountPassword: '',
    };
  }

  initState() {
    super.initState();
    this.confirmLabel = 'Save Merchant Account';
    this.accounts = [];
    this.allCardReaders = [];
    this.__passwordExists = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeMerchantProvider: e => {
        if (
          e.event === 'select' &&
          e.value.data !== this.state.merchantProvider.data
        ) {
          this.__clearFields();
          this.formService.apply('merchantProvider', e.value);
        }
      },
      save: async () => {
        if (
          this.formService.validate() &&
          (await this.__checkIfInactiveAndInUse()) &&
          (await this.__checkMerchantCredentials()) &&
          (await this.__checkIfHSNOwner())
        ) {
          const model = this.formService.build();

          this.onSave(model);
        } else {
          if (this.errors.name === 'Duplicate') {
            await openPopup('message', DUPLICATE_MSG);
          }

          this.__blurActionBar();
        }
      },
      clearPassword: () => {
        this.formService.apply('accountPassword', '');
        this.__passwordExists = false;
      },
    };
  }

  firstUpdated() {
    this.__sideLoading = false;
    this.__passwordExists = this.__isExistingFiservAccount();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__setDefaultMerchantProvider();
      this.build();
      this.__saving = false;
    }

    super.update(changedProps);
  }

  __clearFields() {
    this.formService.apply('cnpXWebId', '');
    this.formService.apply('cnpTID', '');
    this.formService.apply('cnpAuthKey', '');
    this.formService.apply('geniusXWebId', '');
    this.formService.apply('geniusTID', '');
    this.formService.apply('geniusAuthKey', '');
    this.formService.apply('merchantId', '');
    this.formService.apply('accountUsername', '');
    this.formService.apply('accountPassword', '');
  }

  __merchantProviderGlobalPayments() {
    return (
      this.state.merchantProvider.data === MERCHANT_PROVIDERS.GLOBAL_PAYMENTS
    );
  }

  __setDefaultMerchantProvider() {
    if (this.model.merchantProvider === null) {
      if (this.model.id) {
        this.model.merchantProvider = MERCHANT_PROVIDERS.GLOBAL_PAYMENTS;
      } else {
        this.model.merchantProvider = MERCHANT_PROVIDERS.FISERV;
      }
    }
  }

  __blurActionBar() {
    this.shadowRoot.getElementById(ELEMENTS.actionBar.id).blur();
  }

  async __checkMerchantCredentials() {
    if (
      this.__merchantProviderGlobalPayments() &&
      this.state.cnpAuthKey === this.state.geniusAuthKey &&
      this.state.cnpTID === this.state.geniusTID &&
      this.state.cnpXWebId === this.state.geniusXWebId
    ) {
      const accepted = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        DUPLICATE_CREDENTIALS_MSG,
      );

      return accepted;
    }

    return true;
  }

  async __checkIfInactiveAndInUse() {
    if (this.state.id && !this.state.active) {
      const { count } = await countScheduledPaymentsByAccount(this.state.id);

      if (count) {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, MERCHANT_ACCOUNT_IN_USE_MSG);

        return false;
      }

      if (this.state.isOnlinePaymentsDefault) {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, ONLINE_PAYMENTS_IN_USE_MSG);

        return false;
      }
    }

    return true;
  }

  __isExistingFiservAccount() {
    return !!(
      this.model.id && this.model.merchantProvider === MERCHANT_PROVIDERS.FISERV
    );
  }

  __checkIfHSNOwner() {
    if (
      this.state.id &&
      !this.state.active &&
      this.state.merchantProvider.data === MERCHANT_PROVIDER_OPTIONS[0].data
    ) {
      if (
        this.allCardReaders.find(
          cr => cr.hsnOwnerMerchantAccountId === this.state.id && cr.active,
        )
      ) {
        return openPopup(POPUP_RENDER_KEYS.CONFIRM, MERCHANT_OWNER_MSG);
      }
    }
    return true;
  }

  __duplicate(error = 'Duplicate') {
    return {
      error,
      validate: () =>
        this.accounts.filter(
          ({ id, name }) => id !== this.state.id && name === this.state.name,
        ).length === 0,
    };
  }

  __required(forMerchantProvider) {
    return {
      error: 'Required',
      validate: (v, _, state) =>
        state.merchantProvider.data === forMerchantProvider ? v : true,
    };
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [required(), this.__duplicate()],
        },
        cnpXWebId: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        cnpTID: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        cnpAuthKey: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        geniusXWebId: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        geniusTID: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        geniusAuthKey: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        merchantProvider: {
          clipPristine: true,
          format: v =>
            MERCHANT_PROVIDER_OPTIONS.find(item => item.data === v) ||
            MERCHANT_PROVIDER_OPTIONS[1],
          unformat: v => v.data,
        },
        merchantId: {
          validators: [this.__required(MERCHANT_PROVIDERS.FISERV)],
          unformat: v => v || null,
        },
        accountUsername: {
          validators: [this.__required(MERCHANT_PROVIDERS.FISERV)],
          unformat: v => v || null,
        },
        accountPassword: {
          validators: [this.__required(MERCHANT_PROVIDERS.FISERV)],
          format: () =>
            this.__isExistingFiservAccount() ? PASSWORD_PLACEHOLDER : '',
          unformat: v => (this.__passwordExists ? null : v),
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
        }

        .layout {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          align-items: center;
          grid-auto-rows: min-content;
          padding: ${CSS_SPACING};
        }

        .grid-row-title {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 8px;
          align-items: center;
        }

        .grid-row-tooltip {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 8px;
          align-items: center;
        }

        .item-span {
          grid-column: span 2;
        }

        .subheader {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .checkbox {
          width: fit-content;
        }
      `,
    ];
  }

  __renderGlobalPaymentsFields() {
    return html`
      <div class="grid-row-title item-span">
        <div class="subheader">MOTO Service Account</div>

        <neb-tooltip id="${ELEMENTS.cnpTitleTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">
            This account service type is used for Mail Order/Telephone Order
            payments, when a card is not present. Global Payments (OpenEdge)
            will provide you with your account details, and will help configure
            your merchant account for your practice. Contact Global Payments
            (OpenEdge) to update your merchant account details.
          </div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip">
        <neb-textfield
          id="${ELEMENTS.cnpXWebId.id}"
          name="cnpXWebId"
          helper="Required"
          maxLength="50"
          label="XWeb ID"
          .value="${this.state.cnpXWebId}"
          .error="${this.errors.cnpXWebId}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip id="${ELEMENTS.cnpXWebIdTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip">
        <neb-textfield
          id="${ELEMENTS.cnpTID.id}"
          name="cnpTID"
          helper="Required"
          maxLength="50"
          label="Terminal ID"
          .value="${this.state.cnpTID}"
          .error="${this.errors.cnpTID}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip id="${ELEMENTS.cnpTIDTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip item-span">
        <neb-textfield
          id="${ELEMENTS.cnpAuthKey.id}"
          name="cnpAuthKey"
          helper="Required"
          maxLength="50"
          label="AuthKey"
          .value="${this.state.cnpAuthKey}"
          .error="${this.errors.cnpAuthKey}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip id="${ELEMENTS.cnpAuthKeyTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>

      <div class="grid-row-title item-span">
        <div class="subheader">Retail Service Account</div>

        <neb-tooltip id="${ELEMENTS.geniusTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">
            This account service type is used for payments with a card reader,
            when a card is present. Global Payments (OpenEdge) will provide you
            with your account details, and will help configure your merchant
            account for your practice. Contact Global Payments (OpenEdge) to
            update your merchant account details.
          </div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip">
        <neb-textfield
          id="${ELEMENTS.geniusXWebId.id}"
          name="geniusXWebId"
          helper="Required"
          maxLength="50"
          label="XWeb ID"
          .value="${this.state.geniusXWebId}"
          .error="${this.errors.geniusXWebId}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip
          id="${ELEMENTS.geniusXWebIdTooltip.id}"
          defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip">
        <neb-textfield
          id="${ELEMENTS.geniusTID.id}"
          name="geniusTID"
          helper="Required"
          maxLength="50"
          label="Terminal ID"
          .value="${this.state.geniusTID}"
          .error="${this.errors.geniusTID}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip id="${ELEMENTS.geniusTIDTooltip.id}" defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>

      <div class="grid-row-tooltip item-span">
        <neb-textfield
          id="${ELEMENTS.geniusAuthKey.id}"
          name="geniusAuthKey"
          helper="Required"
          maxLength="50"
          label="AuthKey"
          .value="${this.state.geniusAuthKey}"
          .error="${this.errors.geniusAuthKey}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip
          id="${ELEMENTS.geniusAuthKeyTooltip.id}"
          defaultAnchor="right"
          ><div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderFiservFields() {
    return html`
      <div class="subheader">Service Account Details</div>

      <neb-textfield
        id="${ELEMENTS.merchantId.id}"
        class="item-span"
        name="merchantId"
        helper="Required"
        maxLength="50"
        label="Merchant ID (MID)"
        .value="${this.state.merchantId}"
        .error="${this.errors.merchantId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.accountUsername.id}"
        name="accountUsername"
        helper="Required"
        maxLength="50"
        label="Fiserv Production Username"
        .value="${this.state.accountUsername}"
        .error="${this.errors.accountUsername}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.accountPassword.id}"
        name="accountPassword"
        helper="Required"
        maxLength="50"
        label="Fiserv Production Password"
        type="password"
        .value="${this.state.accountPassword}"
        .error="${this.errors.accountPassword}"
        .onChange="${this.handlers.change}"
        .readonly="${this.__passwordExists}"
        .trailingIcon="${this.__passwordExists ? 'neb:clear' : ''}"
        .onClickIcon="${this.handlers.clearPassword}"
      ></neb-textfield>
    `;
  }

  renderContent() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.isDefault.id}"
        class="item-span checkbox"
        name="isDefault"
        label="Default Account"
        .value="${this.state.isDefault}"
        .onChange="${this.handlers.change}"
        ?checked="${this.state.isDefault}"
      ></neb-checkbox>

      <neb-select
        id="${ELEMENTS.merchantProviderSelect.id}"
        name="merchantProvider"
        label="Merchant Provider"
        helper=" "
        .items="${MERCHANT_PROVIDER_OPTIONS}"
        .value="${this.state.merchantProvider}"
        .onChange="${this.handlers.changeMerchantProvider}"
        ?disabled="${this.model.id}"
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.name.id}"
        name="name"
        helper="Required"
        maxLength="50"
        label="Merchant Account Name"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
      ${this.__merchantProviderGlobalPayments()
        ? this.__renderGlobalPaymentsFields()
        : this.__renderFiservFields()}

      <neb-switch
        id="${ELEMENTS.active.id}"
        class="item-span"
        name="active"
        helper="Required"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }
}
customElements.define('neb-form-merchant-account', NebFormMerchantAccount);
