import '../../neb-popup-header';
import '../../neb-table-add';
import '../../neb-pagination';
import '../../neb-action-bar';
import '../../inputs/neb-textfield';

import { css, html } from 'lit';

import {
  ASSOCIATE_PAYER,
  buildSearchPlaceholder,
  getSelectToAssociate,
  PAYERS,
  PAYER_SEARCH_ITEMS,
} from '../../../../../../src/utils/user-message';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../../neb-styles/neb-variables';
import {
  formatAddress,
  normalizeForSearch,
} from '../../../../../neb-utils/formatters';
import { CollectionService } from '../../../../../neb-utils/services/collection';
import { deduplicate } from '../../../../../neb-www-booking/src/components/util/enable-booking-util';
import { SORT_DIR } from '../../neb-table-header';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'associate-payer-header' },
  form: { id: 'associate-payer-form' },
  table: { id: 'associate-payer-table' },
  searchField: {
    id: 'associate-payer-search-field',
    label: buildSearchPlaceholder(PAYER_SEARCH_ITEMS),
  },
  description: { id: 'associate-payer-description' },
  actionBar: { id: 'associate-payer-action-bar' },
  noResultsMessage: { id: 'associate-payer-no-results-message' },
  noPayersMessage: { id: 'associate-payer-no-payers-message' },
  paginationControl: { id: 'associate-payer-pagination-control' },
};

const TABLE_ROWS_KEYS = {
  ALIAS: 'alias',
  PAYER_ID: 'payerId',
  PAYER_NAME: 'payerName',
  ADDRESS: 'address',
};

const TABLE_CONFIG = [
  {
    key: TABLE_ROWS_KEYS.ALIAS,
    label: 'Alias',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.PAYER_ID,
    label: 'Payer ID',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.PAYER_NAME,
    label: 'Payer Name',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.ADDRESS,
    label: 'Address',
    flex: css`1 0 0`,
    formatter: v => formatAddress(v),
    mobile: true,
    truncate: true,
  },
];
const MOBILE_TABLE_CONFIG = [];

export const EMPTY_MODEL = {
  associatedPayers: [],
  parentItem: '',
  payers: [],
};

class NebOverlayAssociatePayer extends Overlay {
  static get properties() {
    return {
      __searchText: String,
      __allAdded: Boolean,
      __currentPageIndex: Number,
      __pageCount: Number,
      __payers: Array,
      __selectedPayers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: flex;
          flex-direction: column;
        }

        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .icon-chevron {
          height: 12px;
          width: 12px;
          transform: rotate(90deg);
        }

        .main-header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .add-button {
          padding: ${CSS_SPACING};
        }

        .search-field {
          min-height: 70px;
          margin: 15px ${CSS_SPACING} 20px;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }

        .container-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__searchText = '';
    this.__allAdded = false;
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__payers = [];
    this.__selectedPayers = [];
    this.model = EMPTY_MODEL;

    this.__initServices();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__payers = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },
        onCacheItem: ({ alias, payerName, payerId }) =>
          normalizeForSearch([alias, payerName, payerId].join(' ')),
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
        onSort: this.__onSortPayers,
      },
      {
        hideInactive: false,
        sortParams: {
          key: TABLE_ROWS_KEYS.ALIAS,
          dir: SORT_DIR.ASC,
        },
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      search: ({ value }) => this.__collectionService.search(value),
      clearSearch: () => this.__collectionService.search(''),
      apply: () => {
        this.dismiss(this.__selectedPayers.sort(this.__onSortPayers));
      },
      addAll: () => {
        const allItems = [
          ...this.__selectedPayers,
          ...this.__collectionService.getFilteredItems(),
        ];
        this.__selectedPayers = deduplicate(allItems, 'id');
      },
      add: (item, _index) => {
        this.__selectedPayers = [...this.__selectedPayers, item];
      },
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
    };
  }

  __onSortPayers(a, b) {
    return a[TABLE_ROWS_KEYS.ALIAS].localeCompare(b[TABLE_ROWS_KEYS.ALIAS]);
  }

  __refreshAllAdded() {
    this.__allAdded = this.__payers.length
      ? this.__collectionService
          .getFilteredItems()
          .every(i => this.__selectedPayers.some(c => c.id === i.id))
      : null;
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model) {
      const { associatedPayers = [], payers = [] } = this.model;
      this.__selectedPayers = associatedPayers;
      this.__collectionService.setItems(payers);
    }

    if (changedProps.has('__payers') || changedProps.has('__selectedPayers')) {
      this.__refreshAllAdded();
    }

    super.update(changedProps);
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="main-header"
        .title="${ASSOCIATE_PAYER}"
        .onCancel="${this.handlers.apply}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${getSelectToAssociate(PAYERS.toLowerCase(), this.model.parentItem)}
      </div>
    `;
  }

  renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="search-field"
        leadingIcon="neb:search"
        label="${ELEMENTS.searchField.label}"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }

  __renderNoResultsMessage() {
    return html`
      <p id="${ELEMENTS.noResultsMessage.id}" class="no-items-message">
        No results.
      </p>
    `;
  }

  __renderNoPayersMessage() {
    return html`
      <p id="${ELEMENTS.noPayersMessage.id}" class="no-items-message">
        There are no payers available to associate.
      </p>
    `;
  }

  __renderNoItemsMessage() {
    return this.__searchText
      ? this.__renderNoResultsMessage()
      : this.__renderNoPayersMessage();
  }

  __renderPaginationControl() {
    return html`
      <neb-pagination
        id="${ELEMENTS.paginationControl.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  __getTableConfig() {
    return {
      tableConfig: TABLE_CONFIG,
      mobileTableConfig: MOBILE_TABLE_CONFIG,
      itemName: 'payer',
      itemPluralName: 'payers',
      title: 'Associate Payer',
    };
  }

  renderTable() {
    return html`
      <div class="container-table">
        <neb-table-add
          id="${ELEMENTS.table.id}"
          class="header-table"
          .config="${this.__getTableConfig()}"
          .allAdded="${this.__allAdded}"
          .onAddAll="${this.handlers.addAll}"
          .layout="${this.layout}"
          .onAdd="${this.handlers.add}"
          .items="${this.__payers}"
          .selectedItems="${this.__selectedPayers}"
        >
          ${this.__renderNoItemsMessage()}
        </neb-table-add>
      </div>

      ${this.__renderPaginationControl()}
    `;
  }

  renderContent() {
    return html`
      <div class="container">
        ${this.renderHeader()} ${this.renderDescription()}
        ${this.renderSearchField()} ${this.renderTable()}
        ${this.renderActionBar()}
      </div>
    `;
  }
}

customElements.define('neb-overlay-associate-payer', NebOverlayAssociatePayer);
