import { html, css } from 'lit';

import NebFormRooms from '../../../../../src/components/forms/settings/neb-form-rooms';
import * as api from '../../../../neb-api-client/src/rooms-api-client';

import NebOverlayFormItem, {
  ELEMENTS as ELEMENTS_BASE,
} from './neb-overlay-form-item';

export const ELEMENTS = { ...ELEMENTS_BASE, form: { id: 'form' } };

class OverlayRooms extends NebOverlayFormItem {
  static get properties() {
    return {
      selectedLocation: String,
    };
  }

  static get config() {
    return {
      itemName: `Room for ${this.selectedLocation}`,
      form: NebFormRooms,
      messageSuccess: 'Room saved successfully',
      messageError: 'Room was not saved successfully,',
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          grid-template-rows: max-content;
          width: 78rem;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.selectedLocation = '';
  }

  firstUpdated() {
    super.firstUpdated();

    this.selectedLocation = this.initialModel.context.selectedLocation;
  }

  isEditing() {
    return Boolean(this.model.id);
  }

  getTitle() {
    return `${this.isEditing() ? 'Update' : 'Add'} Room for ${
      this.selectedLocation
    }`;
  }

  save(item) {
    const req = item.id ? api.updateRoom : api.createRoom;

    return req(item);
  }

  renderForm() {
    return html`
      <neb-form-rooms
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.getModel()}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-rooms>
    `;
  }
}

customElements.define('neb-overlay-rooms', OverlayRooms);
