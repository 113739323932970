import { getCustomAccessTextFromAttributes } from '@neb/permissions';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { getCognitoConfig } from '../neb-api-client/src/permissions-api-client';
import { KAFKA_DOWN_ERROR_MESSAGE } from '../neb-login/neb-session-state';

export const COGNITO_TYPES = {
  PATIENT: 'patient',
  PRACTICE: 'practice',
  SUPPORT: 'support',
};

let userPoolConfig = {};
let userPool;
let initPromise;

const __init = async (type = COGNITO_TYPES.PATIENT) => {
  userPoolConfig = await getCognitoConfig(type);
  userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: userPoolConfig.userPoolId,
    ClientId: userPoolConfig.bookingAppClientId,
    endpoint: userPoolConfig.endpoint || undefined,
  });
};

export const createCognitoUser = username => {
  const user = new AmazonCognitoIdentity.CognitoUser({
    Username: username.toLowerCase(),
    Pool: userPool,
  });

  if (userPoolConfig.endpoint) {
    user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
  }

  return user;
};

export const init = (type = COGNITO_TYPES.PATIENT) => {
  if (!initPromise) {
    initPromise = __init(type);
  }

  return initPromise;
};

export const teardown = () => {
  initPromise = null;
  userPoolConfig = {};
  userPool = null;
};

const getSession = (idToken, refreshToken, currentTenantId) => {
  const customAccess = getCustomAccessTextFromAttributes(idToken.payload);
  const access = customAccess && JSON.parse(customAccess);

  let tenantId = currentTenantId;
  let tenantIds;

  if (access) {
    tenantIds = Object.keys(access);

    if (tenantIds && tenantIds.length === 1) {
      tenantId = tenantIds[0];
    } else tenantId = tenantIds.find(id => id === tenantId);
  }

  return {
    refreshToken,
    idToken,
    accessToken: idToken.jwtToken,
    tenantId,
    tenantIds,
    access,
    id: idToken.payload['cognito:username'],
  };
};

const getAuthDetails = (username, password) =>
  new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username.toLowerCase(),
    Password: password,
  });

const doesPasswordExist = (password, email) => {
  if (!email) return false;
  const authenticationDetails = getAuthDetails(email, password);
  const cognitoUser = createCognitoUser(email);
  return new Promise(resolve => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => resolve(true),
      onFailure: () => resolve(false),
      newPasswordRequired: () => resolve(true),
    });
  });
};

const EMPTY_ACCESS = '{}';

export const authenticateUser = async ({
  username,
  password,
  type = COGNITO_TYPES.PATIENT,
}) => {
  try {
    await init(type);
  } catch (err) {
    console.log(err);
    throw new Error(KAFKA_DOWN_ERROR_MESSAGE);
  }

  const authenticationDetails = getAuthDetails(username, password);
  const cognitoUser = createCognitoUser(username);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        if (
          getCustomAccessTextFromAttributes(result.getIdToken().payload) ===
          EMPTY_ACCESS
        ) {
          reject(new Error('No Access'));
        } else {
          resolve({
            ...getSession(result.getIdToken(), result.getRefreshToken()),
            type,
          });
        }
      },
      onFailure: err => {
        if (err.code === 'PasswordResetRequiredException') {
          resolve({
            expiredPassword: true,
          });
        } else reject(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (
          getCustomAccessTextFromAttributes(userAttributes) === EMPTY_ACCESS
        ) {
          reject(new Error('No Access'));
        } else {
          delete userAttributes.email_verified;
          resolve({
            changePassword: async (password, email) => {
              const passwordExists = await doesPasswordExist(password, email);
              if (passwordExists) return false;
              return new Promise((resolve, reject) => {
                cognitoUser.completeNewPasswordChallenge(
                  password,
                  requiredAttributes,
                  {
                    onSuccess: resolve,
                    onFailure: reject,
                  },
                );
              });
            },
          });
        }
      },
    });
  });
};

export const renew = async options => {
  await init(options.type);
  const cognitoUser = createCognitoUser(options.idToken.payload.email);
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      {
        getToken: () => options.refreshToken.token,
      },
      (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve({
            ...getSession(
              session.getIdToken(),
              session.getRefreshToken(),
              options.tenantId,
            ),
            type: options.type,
          });
        }
      },
    );
  });
};

export const signOut = async options => {
  await init();
  const cognitoUser = createCognitoUser(options.idToken.payload.email);

  if (cognitoUser != null) {
    return new Promise((resolve, _reject) => {
      resolve(cognitoUser.signOut());
    });
  }

  return undefined;
};

export const forgotPassword = async email => {
  await init();
  const cognitoUser = createCognitoUser(email);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: data => {
        resolve(data);
      },
      onFailure: err => {
        reject(err);
      },
    });
  });
};

export const confirmPassword = async (newPassword, verificationCode, email) => {
  await init();
  const cognitoUser = createCognitoUser(email);
  const passwordExists = await doesPasswordExist(newPassword, email);
  if (passwordExists) return false;
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (data = {}) => {
        resolve(data);
      },
      onFailure: err => {
        reject(err);
      },
    });
  });
};

export const changePassword = async (oldPassword, newPassword, email) => {
  await init();
  const authenticationDetails = getAuthDetails(email, oldPassword);
  const cognitoUser = createCognitoUser(email);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          (error, success) => {
            if (error) {
              reject(error);
            } else {
              resolve(success);
            }
          },
        );
      },
      onFailure: err => {
        reject(err);
      },
    });
  });
};
