import { css, html } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import { MODE } from '../../../packages/neb-utils/table';
import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
} from '../../styles';
import { hasAddOn, ADD_ONS } from '../../utils/add-ons';
import { getRTEStatusIcon } from '../../utils/real-time-eligibility';

import '../misc/neb-icon';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  icons: { selector: '.icon' },
};

class TablePatientInsurance extends Table {
  static get properties() {
    return {
      showDefault: { reflect: true, type: Boolean },
      showDetail: { reflect: true, type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-validated,
        .icon-partially-validated {
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .icon-warning {
          fill: ${CSS_COLOR_YELLOW};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.mode = MODE.DETAIL;
    this.showDetail = true;
    this.emptyMessage = 'There are no active insurance plans for this patient.';
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);

    this.config = [
      ...(this.showDefault
        ? [
            {
              flex: css`0 1 70px`,
              key: 'defaultLevel',
              label: 'Set as',
            },
          ]
        : []),
      ...(this.__hasCTVerify
        ? [
            {
              flex: css`0.4 0 0`,
              key: 'realTimeEligibilityStatus',
              label: 'RTE',
            },
          ]
        : []),
      {
        flex: css`1.2 0 0`,
        key: 'alias',
        label: 'Payer',
      },
      {
        flex: css`2 0 0`,
        key: 'planName',
        label: 'Plan Name',
      },
      ...(this.showDetail
        ? [
            {
              flex: css`1 0 0`,
              key: 'insuredRelationship',
              label: 'Insured',
            },
          ]
        : []),
      {
        flex: css`1.5 0 0`,
        key: 'memberIdentifier',
        label: 'Insured ID',
      },
      ...(this.showDetail
        ? [
            {
              flex: css`1.5 0 0`,
              key: 'financialClass',
              label: 'Type',
            },
            {
              flex: css`2.5 0 0`,
              key: 'coverageDates',
              label: 'Coverage Dates',
            },
          ]
        : []),
    ];
  }

  __getIconClassName(icon) {
    switch (icon) {
      case 'neb:checkCircle':
        return 'icon-validated';

      case 'neb:checkOutline':
        return 'icon-partially-validated';

      case 'neb:rteWarning':
        return 'icon-warning';

      default:
        return '';
    }
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const { payerPlan, planInfo } = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'alias':
        return payerPlan.alias;

      case 'financialClass':
        return payerPlan.financialClass;

      case 'planName':
        return planInfo.planName;

      case 'memberIdentifier':
        return planInfo.memberIdentifier;

      case 'realTimeEligibilityStatus': {
        const icon = getRTEStatusIcon(value);
        const className = this.__getIconClassName(icon);

        return value
          ? html`
              <neb-icon class="icon ${className}" .icon="${icon}"></neb-icon>
            `
          : '';
      }

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

customElements.define('neb-table-patient-insurance', TablePatientInsurance);
