import './inputs/neb-menu';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_DISABLED,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

import { POPOVER_POSITION } from './neb-popover';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  ellipsis: {
    id: 'button',
  },
  menu: {
    id: 'menu',
  },
  blocker: {
    id: 'blocker',
  },
  popoverContainer: {
    id: 'popover-container',
  },
  popover: {
    id: 'popover',
  },
  tooltip: {
    id: 'tooltip',
  },
};

class NebActionsButton extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      value: Array || String,
      vertical: {
        reflect: true,
        type: Boolean,
      },
      align: {
        reflect: true,
        type: String,
      },
      maxVisibleItems: {
        type: Number,
        reflect: true,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      iconHeight: String,
      iconWidth: String,
      forceDownMenu: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__open = false;
    this.maxVisibleItems = 4;
    this.vertical = false;
    this.disabled = false;
    this.iconHeight = '30px';
    this.iconWidth = '30px';
    this.align = 'right';
    this.value = [];
    this.forceDownMenu = false;
    this.onClick = null;

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__open = false;
      },
      select: index => {
        const { onSelect, ...value } = this.value[index];

        onSelect(value, index);

        this.__open = false;
      },
      toggle: async e => {
        if (this.onClick && !this.__open) {
          this.value = await this.onClick({ value: this.value });
        }

        this.__open = !this.__open;
        e.stopPropagation();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.body.addEventListener('click', this.__handlers.blur);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.body.removeEventListener('click', this.__handlers.blur);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: 40px;
          height: 40px;
          --menu-min-width: 210px;
        }

        .container {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;
          outline: none;
        }

        :host([disabled]) .container {
          pointer-events: none;
          cursor: default;
        }

        .ellipsis {
          display: flex;
          flex-shrink: 0;
          fill: ${CSS_COLOR_HIGHLIGHT};
          height: 30px;
          width: 30px;
        }

        .ellipsis:hover {
          opacity: 0.87;
        }

        .menu {
          min-width: var(--menu-min-width);
          width: auto;
          position: absolute;
          height: fit-content;
          top: 35px;
          right: 0;
        }

        :host([align='left']) .menu {
          left: 0;
        }

        .blocker {
          cursor: default;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          z-index: 1;
        }

        .popover-container {
          width: 0;
          position: relative;
          display: none;
        }

        .popover-container[open] {
          display: block;
        }

        .popover {
          position: absolute;
          top: 15px;
        }

        .popover-text {
          padding: ${CSS_SPACING};
        }

        :host([vertical]) .ellipsis {
          transform: rotate(90deg);
        }

        :host([disabled]) .ellipsis {
          fill: ${CSS_COLOR_DISABLED};
        }
      `,
    ];
  }

  __renderBlocker() {
    return this.__open
      ? html` <div id="${ELEMENTS.blocker.id}" class="blocker"></div> `
      : '';
  }

  renderMenu() {
    return html`
      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu"
        .maxVisibleItems="${this.maxVisibleItems}"
        .items="${this.value}"
        .onChange="${this.__handlers.select}"
        ?open="${this.__open && this.value && this.value.length}"
        ?forceDown="${this.forceDownMenu}"
        .showFullText="${true}"
      ></neb-menu>
      ${this.__renderBlocker()}
    `;
  }

  __renderTooltip() {
    return html`
      <div
        id="${ELEMENTS.popoverContainer.id}"
        class="popover-container"
        ?open="${this.__open}"
      >
        <neb-popover
          id="${ELEMENTS.popover.id}"
          class="popover"
          popoverPosition="${POPOVER_POSITION.TOP}"
          ><div id="${ELEMENTS.tooltip.id}" class="popover-text">
            ${this.value}
          </div></neb-popover
        >
      </div>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.toggle}"
      >
        ${typeof this.value === 'string'
          ? this.__renderTooltip()
          : this.renderMenu()}
        <neb-icon
          id="${ELEMENTS.ellipsis.id}"
          class="ellipsis"
          icon="neb:ellipsis"
          style="height: ${this.iconHeight}; width: ${this.iconWidth};"
          ?disabled="${this.disabled}"
        ></neb-icon>
      </div>
    `;
  }
}

window.customElements.define('neb-button-actions', NebActionsButton);
