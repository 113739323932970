import openPopupAmountPaid from './open-popup-amount-paid';
import openPopupOtherDate from './open-popup-other-date';
import openPopupOtherId from './open-popup-other-id';
import openPopupProviderIdentifier from './open-popup-provider-identifier';

export default ({ claimCellData, state, model, handlers, formService }) => {
  if (claimCellData.name.includes('providerIdentifier')) {
    return openPopupProviderIdentifier({
      claimCellData,
      state,
      model,
      handlers,
      formService,
    });
  }

  if (claimCellData.name.includes('OtherId')) {
    return openPopupOtherId({ claimCellData, state, handlers, formService });
  }

  if (claimCellData.name === 'otherDate') {
    return openPopupOtherDate({
      claimCellData,
      state,
      model,
      handlers,
      formService,
    });
  }

  if (claimCellData.name === 'fl29AmountPaidOverride') {
    return openPopupAmountPaid({ claimCellData, state, handlers, formService });
  }

  return undefined;
};
