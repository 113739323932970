import '../../../neb-patient-panel';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../../../neb-styles/neb-variables';
import {
  formatCurrency,
  getMatchingServiceTypes,
  isEmpty,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkAmountText: {
    id: 'in-network-amount-text',
  },
  inNetworkAmountValue: {
    id: 'in-network-amount-value',
  },
  outOfNetworkAmountText: {
    id: 'out-of-network-amount-text',
  },
  outOfNetworkAmountValue: {
    id: 'out-of-network-amount-value',
  },
  serviceTypesText: {
    id: 'service-types-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
  title: {
    id: 'title',
  },
};
const SERVICE_TYPES_HEADER = 'Service Types';
export const NO_CONTENT_TEXT = 'There are no copays for this plan.';

class NebPatientInsuranceCopaysCoverageDetails extends LitElement {
  static get properties() {
    return {
      copay: {
        type: Object,
      },
      allServiceTypes: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.copay = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .panel {
          border-style: solid;
          border-width: 1px;
          border-color: ${CSS_COLOR_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 10px 0 14px;
          height: 100%;
        }

        .copay-container {
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .service-types-container {
          padding-top: ${CSS_SPACING};
        }

        .copay-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .copay-amounts {
          padding-bottom: ${CSS_SPACING};
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .title {
          display: flex;
          padding-left: 10px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderInNetworkAmount(inNetworkAmount) {
    if (isEmpty(inNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkAmountText.id}">
        <span class="bold">In Network: </span>
        <div id="${ELEMENTS.inNetworkAmountValue.id}" class="copay-amounts">
          ${formatCurrency(inNetworkAmount)}
        </div>
      </div>
    `;
  }

  __renderOutOfNetworkAmount(outOfNetworkAmount) {
    if (isEmpty(outOfNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkAmountText.id}">
        <span class="bold">Out of Network: </span>
        <div id="${ELEMENTS.outOfNetworkAmountValue.id}">
          ${formatCurrency(outOfNetworkAmount)}
        </div>
      </div>
    `;
  }

  __renderServiceTypes(serviceTypes) {
    if (!serviceTypes || serviceTypes.length === 0) return '';
    return html`
      <div class="bold">${SERVICE_TYPES_HEADER}</div>
      <div id="${ELEMENTS.serviceTypesText.id}">
        ${getMatchingServiceTypes(serviceTypes, this.allServiceTypes)}
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="copay-container">
        <div class="copay-row">
          <div class="flex-one">
            ${this.__renderInNetworkAmount(this.copay.inNetworkAmount)}
            ${this.__renderOutOfNetworkAmount(this.copay.outOfNetworkAmount)}
          </div>
        </div>

        <div class="copay-row">
          <div class="service-types-container">
            ${this.__renderServiceTypes(this.copay.serviceTypes)}
          </div>
        </div>
      </div>
    `;
  }

  __renderNoContent() {
    return html`
      <div id="${ELEMENTS.noContentText.id}" class="no-content">
        ${NO_CONTENT_TEXT}
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderTitle(copay) {
    const title = `Copays${
      copay.coverageLevel.code === 'NA'
        ? ''
        : ` - ${copay.coverageLevel.description}`
    }`;

    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        ${title} ${this.__renderDefaultIndicator(copay)}
      </div>
    `;
  }

  __renderCopay() {
    return html`
      <div id="${ELEMENTS.coverageLevelText.id}" class="panel">
        ${this.__renderTitle(this.copay)}
        ${this.copay.coverageLevel.code === 'NA'
          ? this.__renderNoContent()
          : this.__renderContent()}
      </div>
    `;
  }

  render() {
    return html` ${this.__renderCopay()} `;
  }
}

customElements.define(
  'neb-patient-insurance-copays-coverage-details',
  NebPatientInsuranceCopaysCoverageDetails,
);
