import '../../../../packages/neb-lit-components/src/components/problem-list/neb-problem-list';

import { html } from 'lit';

import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { saveProblemList } from '../../../../packages/neb-redux/actions/problem-list-actions';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { ProviderService } from '../../../../packages/neb-redux/services/provider';
import { mapProviderNameToProblems } from '../../../../packages/neb-utils/neb-charting-util';
import {
  FIGURE_KEYS,
  updateNotesWithTable,
} from '../../../../packages/neb-www-practice-charting/src/components/shared/util/notes-figure-table-builder';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

export const PROBLEM_LIST_ITEM_NAME = 'Problem List';
export const TEXT_COPY_SUCCESS = 'Problem list copied to chart note.';
export const TEXT_COPY_ERROR = 'Problem list were not copied to chart note.';

class NebUnsignedEncounterProblemListDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      providers: Array,
      __problems: Array,
    };
  }

  static get config() {
    return {
      itemName: PROBLEM_LIST_ITEM_NAME,
      refetchKey: PANEL_KEYS.PROBLEM_LIST,
    };
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  getTitle() {
    return this.model && this.model.length > 0
      ? `${PROBLEM_LIST_ITEM_NAME} (${this.model.length})`
      : PROBLEM_LIST_ITEM_NAME;
  }

  initState() {
    super.initState();

    this.providers = [];

    this.__problems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      copy: async problemList => {
        try {
          await updateNotesWithTable(
            this.encounter.id,
            problemList,
            FIGURE_KEYS.PROBLEM_LIST,
            this.encounter.providerId,
          );

          store.dispatch(openSuccess(TEXT_COPY_SUCCESS));

          this.onRefetch(PANEL_KEYS.CHART_NOTES);
        } catch (e) {
          console.error('Unable to copy problemList:', e);
          store.dispatch(openError(TEXT_COPY_ERROR));
        }
      },
    };
  }

  initServices() {
    super.initServices();

    this.__providerService = new ProviderService(({ providers }) => {
      this.providers = providers.map(x => ({
        id: x.id,
        name: x.name,
      }));
    });
  }

  isDirty() {
    return this.__elements.view.isDirty();
  }

  save(model) {
    return store.dispatch(saveProblemList(this.encounter.patientId, model));
  }

  connectedCallback() {
    super.connectedCallback();

    this.__providerService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__providerService.disconnect();
  }

  updated(changedProps) {
    if (changedProps.has('model') || changedProps.has('providers')) {
      this.__problems = mapProviderNameToProblems({
        problems: this.model,
        providers: this.providers,
      });
    }
  }

  renderContent() {
    return html`
      <neb-problem-list
        id="${ELEMENTS.view.id}"
        .originalProblems="${this.__problems}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onCopy="${this.handlers.copy}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .layout="${this.layout}"
        enable-save-and-close
      ></neb-problem-list>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-problem-list-detail',
  NebUnsignedEncounterProblemListDetail,
);
