import { html, css } from 'lit';

import {
  createVendor,
  updateVendor,
} from '../../../../../../src/api-clients/vendor';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import nebFormVendor from '../../forms/neb-form-vendor';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
};

export const VENDOR_ADD_SAVE_SUCCESS = 'Vendor saved successfully';
export const VENDOR_ADD_SAVE_ERROR = 'An error occurred when saving the vendor';
export const VENDOR_UPDATE_SAVE_SUCCESS = 'Vendor updated successfully';
export const VENDOR_UPDATE_SAVE_ERROR =
  'An error occurred when updating the vendor';

class NebOverlayVendor extends Overlay {
  static get properties() {
    return {
      __vendorModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__sideLoading = false;
    this.__vendorModel = nebFormVendor.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async item => {
        try {
          let result;

          if (!item.id) {
            result = await createVendor(item);
          } else {
            result = await updateVendor(item);
          }
          store.dispatch(
            openSuccess(
              !item.id ? VENDOR_ADD_SAVE_SUCCESS : VENDOR_UPDATE_SAVE_SUCCESS,
            ),
          );

          this.isDirty = false;
          this.dismiss(result);
        } catch (e) {
          if (e.statusCode !== 409) {
            store.dispatch(
              openError(
                !item.id ? VENDOR_ADD_SAVE_ERROR : VENDOR_UPDATE_SAVE_ERROR,
              ),
            );
          }
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.id) {
      this.__vendorModel = this.model;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 710px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-vendor
        id="${ELEMENTS.form.id}"
        .model="${this.__vendorModel}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-vendor>
    `;
  }
}

customElements.define('neb-overlay-vendor', NebOverlayVendor);
