import {
  openInfo,
  openSuccess,
} from '../../packages/neb-dialog/neb-banner-state';
import { BANNER_MESSAGE } from '../../packages/neb-patient/src/components/ledger/payment/neb-patient-payment-controller';

import {
  SUCCESS_AUTO_ALLOCATE_ON_POST_PAYMENT,
  DID_NOT_AUTO_ALLOCATE_PAYMENT,
  DID_NOT_AUTO_ALLOCATE_DISCOUNT,
  SUCCESS_AUTO_ALLOCATE_PAYMENT,
  SUCCESS_AUTO_ALLOCATE_DISCOUNT,
} from './user-message';

export function getAutoAllocationBanner(allocations, isDiscount = false) {
  return allocations.length
    ? openSuccess(
        isDiscount
          ? SUCCESS_AUTO_ALLOCATE_DISCOUNT
          : SUCCESS_AUTO_ALLOCATE_PAYMENT,
      )
    : openInfo(
        isDiscount
          ? DID_NOT_AUTO_ALLOCATE_DISCOUNT
          : DID_NOT_AUTO_ALLOCATE_PAYMENT,
      );
}

export function getPaymentPostBanner(payment) {
  return payment.available === payment.amount
    ? BANNER_MESSAGE.success
    : SUCCESS_AUTO_ALLOCATE_ON_POST_PAYMENT;
}
