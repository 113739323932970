import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { createFiservPayment, FISERV_RESPSTAT } from '../fiserv-api-client';
import { PAYMENT_RESULT_CONFIG } from '../messages';

export const createPayment = async ({ paymentDetails, token, expiry }) => {
  let payment;
  let action;

  try {
    payment = await createFiservPayment({ ...paymentDetails, token, expiry });
  } catch (error) {
    console.error(error);

    ({ action } = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
      ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
      subheader: 'Service Unavailable',
    }));

    return action === 'confirm'
      ? createPayment({ paymentDetails, token, expiry })
      : null;
  }

  switch (payment.respstat) {
    case FISERV_RESPSTAT.APPROVED:
      return payment;

    case FISERV_RESPSTAT.RETRY:
      ({ action } = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
        subheader: payment.resptext,
      }));

      return action === 'confirm'
        ? createPayment({ paymentDetails, token, expiry })
        : null;

    default:
      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
        subheader: payment.resptext,
      });

      return null;
  }
};
