import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import { html } from 'lit';

import NebTableMacroChoose, {
  CONFIG,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table-macro-choose';

import NebFormChoiceTemplate from './neb-form-choice-template';

export const ELEMENTS = {
  table: { id: 'table' },
  addButton: { id: 'add-button' },
};

class NebFormChoiceTemplateChoose extends NebFormChoiceTemplate {
  static createModel() {
    return {
      ...super.createModel(),
      answers: [],
    };
  }

  createSelectors() {
    return {
      children: {
        ...super.createSelectors().children,
        answers: {
          ...NebTableMacroChoose.createSelectors(),
          createItem: () => ({ id: null, value: '' }),
        },
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      swapItems: (fromIndex, toIndex) =>
        this.formService.moveItem('answers', fromIndex, toIndex),
    };
  }

  renderChoices() {
    return html`
      <neb-table-macro-choose
        id="${ELEMENTS.table.id}"
        class="table"
        name="answers"
        .disabled="${false}"
        .errors="${this.errors.answers}"
        .config="${CONFIG.slice(1)}"
        .model="${this.state.answers}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
        .onReorder="${this.handlers.swapItems}"
      ></neb-table-macro-choose>

      <neb-button-action
        id="${ELEMENTS.addButton.id}"
        label="Add Another Choice"
        name="answers"
        .onClick="${this.handlers.addItem}"
      ></neb-button-action>
    `;
  }
}

window.customElements.define(
  'neb-form-choice-template-choose',
  NebFormChoiceTemplateChoose,
);
