import moment from 'moment-timezone';

import { get } from '../../neb-api-client/src/availability-api-client';

import { ConnectedService } from './connected-service';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

function enumerateEmptyDaysBetweenDates(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const slots = {};

  while (start.isSameOrBefore(end)) {
    slots[start.format(ISO_DATE_FORMAT)] = [];
    start.add(1, 'days');
  }

  return slots;
}

export class AvailabilityService extends ConnectedService {
  constructor(callback) {
    super({
      availability: 'availability',
    });

    this.onStateChanged = callback;
  }

  _stateChanged({ availability }) {
    this.onStateChanged({
      availability,
    });
  }

  updateAvailability(appointment, signal) {
    const {
      disableFetch,
      appointmentId,
      appointmentTypeId,
      duration,
      providerId,
      date,
      locationId,
      resourceId,
    } = appointment;

    if (!disableFetch) {
      if (appointmentTypeId && duration && (providerId || resourceId) && date) {
        const start = date.clone().startOf('month').format(ISO_DATE_FORMAT);
        const end = date
          .clone()
          .startOf('month')
          .add(1, 'month')
          .format(ISO_DATE_FORMAT);

        let params = {
          start,
          end,
          ...(providerId && { providerId }),
          apptTypeId: appointmentTypeId,
          duration,
          ...(locationId && { locationId }),
          ...(resourceId && { resourceId }),
        };

        if (appointmentId) {
          params = {
            forAppointmentId: appointmentId,
            ...params,
          };
        }

        return get(params, signal);
      }
    } else if (date) {
      const start = date.clone().startOf('month').format(ISO_DATE_FORMAT);

      const end = date
        .clone()
        .startOf('month')
        .add(1, 'month')
        .format(ISO_DATE_FORMAT);

      return enumerateEmptyDaysBetweenDates(start, end);
    }

    return Promise.resolve(null);
  }
}
