import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { getPlatformOS, isSafariBrowser } from '../../utils/os-util';

import { PLATFORM_OS, mapPlatformOSToEnum } from './mappers/platform-mapper';
import {
  checkScanService,
  isPlatformSupported,
  CHECK_SCAN_RESULT,
} from './processor/scan-processor';

export const CANNOT_DETERMINE_DEVICE_OS =
  'Can not determine device OS. Going to proceed with assumption that current platform is Windows.';
export const getDevicePlatformNotSupported = platformOS =>
  `The scanner is not supported on this device OS "${platformOS}".`;

export const INSTALL_SCANNER_SERVICE_MESSAGE =
  '1. Follow the prompts on your browser to download and install the scanner service on your computer.';
export const INSTALL_SCANNER_SERVICE_CLOSING_MESSAGE =
  '2. Once the installation is complete, click "Continue".';

const DEVICE_SUPPORT_MESSAGE =
  'Note: Compatible with Windows and MacOS only. Not compatible with mobile devices or tablets.';

export const UNKNOWN_SCANNER_SERVICE_VERSION_MESSAGE =
  'Cannot determine scanner service version. Try to update scanner service.';

export const getScannerServiceUpdateMessage = (
  scannerVersion,
  supportedScanApiVersion,
) =>
  `The current scanner service version ${
    scannerVersion || ''
  } is not supported. Please update to new version ${supportedScanApiVersion}. `;

const verifyPlatform = () => {
  const platformOS = getPlatformOS();

  const platform = mapPlatformOSToEnum(platformOS);

  if (platform === PLATFORM_OS.UNKNOWN) {
    console.error(CANNOT_DETERMINE_DEVICE_OS);

    return {
      platform: PLATFORM_OS.WINDOWS,
      message: '',
    };
  }

  if (platform === PLATFORM_OS.OTHER) {
    return {
      platform: PLATFORM_OS.OTHER,
      message: getDevicePlatformNotSupported(platformOS),
    };
  }
  return { platform, message: '' };
};

const getInstallScannerServicePopupModel = (
  firstMessage,
  scanInstallUrl = {},
) => ({
  title: 'Scanner Service Needed',
  message: html`
    <p>${firstMessage}</p>
    <p>
      1.
      <b
        >Click <a href="${scanInstallUrl}"><u>HERE</u></a></b
      >
      to download and install the scanner service on your computer. Follow the
      prompts on your browser.
    </p>
    <p><i>${DEVICE_SUPPORT_MESSAGE}</i></p>
    <p>2. Once the installation is complete, click "Continue".</p>
  `,
  confirmText: 'Continue',
  cancelText: 'Cancel',
});

const checkService = async (platform, isSafari) => {
  const checkScanServiceResult = await checkScanService(platform, isSafari);

  let repeatCheckService = false;

  switch (checkScanServiceResult.checkScanResult) {
    case CHECK_SCAN_RESULT.INSTALL: {
      repeatCheckService = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        getInstallScannerServicePopupModel(
          '',
          checkScanServiceResult.downloadUrl,
        ),
      );

      break;
    }

    case CHECK_SCAN_RESULT.UPDATE: {
      repeatCheckService = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        getInstallScannerServicePopupModel(
          getScannerServiceUpdateMessage(
            checkScanServiceResult.version,
            platform,
            checkScanServiceResult.supportedScanApiVersion,
          ),
          checkScanServiceResult.downloadUrl,
        ),
      );

      break;
    }

    case CHECK_SCAN_RESULT.UNKNOWN_VERSION: {
      repeatCheckService = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        getInstallScannerServicePopupModel(
          UNKNOWN_SCANNER_SERVICE_VERSION_MESSAGE,
          checkScanServiceResult.downloadUrl,
        ),
      );

      break;
    }

    default: {
      return true;
    }
  }

  if (repeatCheckService) {
    await checkService(platform, isSafari);
  }

  return false;
};

export const checkPlatformAndService = async () => {
  const verifyPlatformResult = verifyPlatform();

  if (verifyPlatformResult.message) {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Scanner Service',
      message: verifyPlatformResult.message,
    });
  }

  const { platform } = verifyPlatformResult;

  if (!isPlatformSupported(platform)) {
    return { success: false, platform };
  }

  const result = await checkService(platform, isSafariBrowser());

  return { success: result, platform };
};
