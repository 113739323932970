import { getProviderUsers } from '../../neb-api-client/src/practice-users-api-client';
import { objToName, DEFAULT_NAME_OPTS } from '../formatters';
import { CollectionService } from '../services/collection';

export default async (onChange, items = null, serviceArgs = {}) => {
  const providers = items || (await getProviderUsers(true));

  const service = new CollectionService(
    {
      onChange,
      onSort: (a, b) => (a.label > b.label ? -1 : 1),
      onMapItem: data => ({
        label: objToName(data.name, DEFAULT_NAME_OPTS),
        data,
      }),
    },
    serviceArgs,
  );

  service.setItems(providers);
  service.setPageSize(providers.length);

  return service;
};
