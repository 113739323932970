import '../controls/neb-switch';
import '../inputs/neb-textfield';
import '../../../../../src/components/misc/neb-icon';
import '../inputs/neb-textarea';
import '../neb-popup-header';
import {
  isEmailAddress,
  isPhoneNumber,
  isPostalCode,
} from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getVendorExists } from '../../../../../src/api-clients/vendor';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { phone } from '../../../../neb-utils/masks';
import { required } from '../../../../neb-utils/validators';
import Address from '../field-groups/neb-address';
import {
  VENDOR_ADD_SAVE_ERROR,
  VENDOR_UPDATE_SAVE_ERROR,
} from '../overlays/settings/neb-overlay-vendor';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: {
    id: 'header',
  },
  name: {
    id: 'name',
  },
  website: {
    id: 'website',
  },
  address: {
    id: 'address',
  },
  phone: {
    id: 'phone',
  },
  fax: {
    id: 'fax',
  },
  email: {
    id: 'email',
  },
  notes: {
    id: 'notes',
  },
  active: {
    id: 'active',
  },
  copyButton: {
    id: 'copy-button',
  },
  copyInput: {
    id: 'copy-input',
  },
};

const DUPLICATE_MSG = {
  title: 'Duplicate Vendor Name',
  message:
    'There is an existing vendor in the system with the same name. Please change the name to save this vendor, or find and use the existing vendor.',
};

export default class NebFormVendor extends NebForm {
  static createModel() {
    return {
      id: '',
      name: '',
      website: '',
      address: Address.createModel(),
      phone: '',
      fax: '',
      email: '',
      notes: '',
      active: true,
    };
  }

  initState() {
    super.initState();
    this.__sideLoading = false;
  }

  async __duplicateCheck(vendorName) {
    const results = await getVendorExists(vendorName);

    return results;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async () => {
        let hasDuplicate = false;

        try {
          if (this.model.name !== this.state.name) {
            hasDuplicate = (await this.__duplicateCheck(this.state.name))
              .exists;
          }
        } catch (e) {
          store.dispatch(
            openError(
              !this.state.id ? VENDOR_ADD_SAVE_ERROR : VENDOR_UPDATE_SAVE_ERROR,
            ),
          );

          return;
        }

        if (hasDuplicate) {
          openPopup('message', DUPLICATE_MSG);
        } else if (this.formService.validate()) {
          const model = this.formService.build();
          this.onSave(model);
        }
      },
      copyLink: () => {
        this.__copyInputEl.style.display = '';
        this.__copyInputEl.value = this.state.website;

        this.__copyInputEl.select();

        document.execCommand('copy');
        this.__copyInputEl.style.display = 'none';
      },
    };
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [required()],
        },
        phone: [isPhoneNumber('(###) ###-####')],
        fax: [isPhoneNumber('(###) ###-####')],
        email: [isEmailAddress()],
        address: {
          children: { zipcode: [isPostalCode('US', '#####(-####)')] },
        },
      },
    };
  }

  firstUpdated() {
    super.firstUpdated();
    this.__copyInputEl = this.shadowRoot.getElementById(ELEMENTS.copyInput.id);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .icon {
          width: 18px;
          height: 18px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .grid-row-with-copy {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 8px;
          align-items: center;
        }

        .item-span {
          grid-column: span 2;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="item-span"
        .title="${this.state.id ? 'Update Vendor' : 'Add Vendor'}"
        .onCancel="${this.handlers.cancel}"
      ></neb-popup-header>

      <neb-textfield
        id="${ELEMENTS.name.id}"
        class="item-span"
        name="name"
        helper="Required"
        maxLength="50"
        label="Vendor Name"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <div class="grid-row-with-copy item-span">
        <neb-textfield
          id="${ELEMENTS.website.id}"
          name="website"
          helper=" "
          maxLength="1000"
          label="Vendor Website"
          .value="${this.state.website}"
          .error="${this.errors.website}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <div id="${ELEMENTS.copyButton.id}" @click="${this.handlers.copyLink}">
          <neb-icon class="icon" icon="neb:copy"></neb-icon>
        </div>
      </div>

      <neb-address
        id="${ELEMENTS.address.id}"
        class="item-span"
        name="address"
        .errors="${this.errors.address}"
        .model="${this.state.address}"
        .onChange="${this.handlers.change}"
      ></neb-address>

      <neb-textfield
        id="${ELEMENTS.phone.id}"
        name="phone"
        helper=" "
        maxLength="50"
        label="Phone Number"
        .mask="${phone}"
        .value="${this.state.phone}"
        .error="${this.errors.phone}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.fax.id}"
        name="fax"
        helper=" "
        maxLength="50"
        label="Fax Number"
        .mask="${phone}"
        .value="${this.state.fax}"
        .error="${this.errors.fax}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.email.id}"
        name="email"
        helper=" "
        maxLength="50"
        label="Email Address"
        .value="${this.state.email}"
        .error="${this.errors.email}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textarea
        id="${ELEMENTS.notes.id}"
        class="item-span textarea-description"
        name="notes"
        helper=" "
        label="Notes"
        rows="4"
        maxlength="500"
        .value="${this.state.notes}"
        .errors="${this.errors.notes}"
        .onChange="${this.handlers.change}"
        showCount
      ></neb-textarea>

      <neb-switch
        id="${ELEMENTS.active.id}"
        class="item-span"
        name="active"
        helper="Required"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>

      <input
        autocomplete="off"
        id="${ELEMENTS.copyInput.id}"
        style="display: none;"
      />
    `;
  }
}
customElements.define('neb-form-vendor', NebFormVendor);
