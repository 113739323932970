import '../inputs/neb-textfield';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../neb-utils/enums';
import { number } from '../../../../neb-utils/masks';

import NebTableOld, { ELEMENTS as ELEMENTS_BASE } from './neb-table-old';

const CONFIG = [
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`0 0 96px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 96px`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`0 0 80px`,
  },
];
const CONFIG_UNIT = {
  key: 'quantity',
  label: 'Max Units',
  flex: css`0 0 120px`,
};
export const ELEMENTS = {
  ...ELEMENTS_BASE,
  quantityField: {
    id: 'quantity',
  },
};
class NebTablePatientPackageCharges extends NebTableOld {
  static get properties() {
    return {
      type: String,
      errorChargeIds: Array,
      errors: Array,
    };
  }

  initState() {
    super.initState();
    this.config = CONFIG;
    this.type = PACKAGE_TYPE.VISIT;
    this.errorChargeIds = [];
    this.errors = [];
  }

  update(changedProps) {
    if (changedProps.has('type')) {
      this.config =
        this.type === PACKAGE_TYPE.VISIT ? CONFIG : [...CONFIG, CONFIG_UNIT];
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell {
          align-items: center;
          white-space: unset;
        }

        .item {
          flex: 1 0 0;
        }

        .quantity {
          padding-right: ${CSS_SPACING};
          margin-top: 10px;
        }
      `,
    ];
  }

  renderCell(_, key, rowIndex) {
    const row = this.model[rowIndex];
    const value = row.charge[key];
    const errorIndex = this.errorChargeIds.findIndex(id => id === row.chargeId);

    switch (key) {
      case 'procedure':
      case 'description':
        return value;

      case 'modifiers':
        return value.filter(v => v).join(', ');

      case 'amount':
        return value || '$0.00';

      case 'quantity':
        if (this.showRemoveButton) {
          return html`
            <neb-textfield
              id="${ELEMENTS.quantityField.id}"
              name="${rowIndex}.quantity"
              class="quantity"
              label=""
              maxLength="3"
              helper="Required"
              .mask="${number}"
              .inputMode="${'numeric'}"
              .value="${row.quantity}"
              .error="${this.errors[errorIndex].quantity}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>
          `;
        }

        return row.quantity;

      default:
        return '';
    }
  }
}
customElements.define(
  'neb-table-patient-package-charges',
  NebTablePatientPackageCharges,
);
