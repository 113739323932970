import { getValueByPath } from '../../../../neb-utils/utils';

const BASE_FORM_SERVICE_KEYS = [
  'chargeId',
  'procedure',
  'description',
  'modifiers.0',
  'modifiers.1',
  'modifiers.2',
  'modifiers.3',
  'units',
  'taxName',
  'taxRate',
  'diagnosisPointers',
  'unitCharge',
  'feeScheduleName',
  'feeScheduleCharge',
  'allowedAmount',
  'EPSDTCode',
  'suppressFromClaim',
  'billWithTreatmentInitiationDate',
  'billWithXrayDate',
  'billedAmount',
  'posted',
];

const BASE_NDC_KEYS = [
  'nationalDrugCodeQualifier',
  'nationalDrugCode',
  'nationalDrugCodeDosage',
  'nationalDrugCodeUnitOfMeasurement',
  'nationalDrugCodeNumberCategory',
  'nationalDrugCodeSequenceOrPrescription',
];

const FORM_SERVICE_KEYS_ENCOUNTER = [
  ...BASE_FORM_SERVICE_KEYS,
  ...BASE_NDC_KEYS,
  'nationalDrugCodeEnabled',
];

const FORM_SERVICE_KEYS_LEDGER = [
  ...BASE_FORM_SERVICE_KEYS,
  ...BASE_NDC_KEYS,
  'ndc',
];

const getKeysByFormField = formField =>
  formField === 'items'
    ? FORM_SERVICE_KEYS_ENCOUNTER
    : FORM_SERVICE_KEYS_LEDGER;

export default ({
  charges,
  stateTotalCharges,
  formField = 'charges',
  filterNdc,
}) =>
  charges.reduce((memo, charge, idx) => {
    const chargeIndex = idx + stateTotalCharges;
    return [
      ...memo,
      (filterNdc
        ? BASE_FORM_SERVICE_KEYS
        : getKeysByFormField(formField)
      ).reduce(
        (obj, key) => ({
          ...obj,
          [`${formField}.${chargeIndex}.${key}`]: getValueByPath(
            charge,
            key.split('.'),
          ),
        }),
        {},
      ),
    ];
  }, []);
