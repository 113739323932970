import { getPreferredPaymentAssociations } from '../api-clients/payment-associations';

export const PAYMENT_TYPE = {
  EOB: 'eob',
};

const idIsInItemList = (list, id) => {
  const foundItem = list.find(item => item.data.id === id);
  const results = foundItem ? foundItem.data.id : null;

  return results;
};

const getPreferredId = (list, fromApptId, preferredId) =>
  list.length > 1
    ? idIsInItemList(list, fromApptId) ||
      idIsInItemList(list, preferredId) ||
      list[0].data.id
    : list[0].data.id;

export const getPreferredPaymentAssociation = async ({
  providerItems,
  locationItems,
  patientId,
}) => {
  let providerId;
  let locationId;

  if (providerItems.length > 1 || locationItems.length > 1) {
    const {
      providerFromAppointmentId,
      preferredProviderId,
      locationFromAppointmentId,
      preferredLocationId,
    } = await getPreferredPaymentAssociations(patientId);

    providerId = getPreferredId(
      providerItems,
      providerFromAppointmentId,
      preferredProviderId,
    );

    locationId = getPreferredId(
      locationItems,
      locationFromAppointmentId,
      preferredLocationId,
    );
  } else {
    providerId = providerItems[0].data.id;
    locationId = locationItems[0].data.id;
  }

  return { providerId, locationId };
};
