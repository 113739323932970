import {
  manuallyPostClaim,
  manuallyPostLineItems,
} from '../../../../packages/neb-api-client/src/era-report-api-client';

export default ({ body, eraId, claimReportId, lineItemReportId }) => {
  if (lineItemReportId) {
    return manuallyPostLineItems({
      body,
      eraId,
      claimReportId,
      lineItemReportId,
    });
  }

  return manuallyPostClaim({
    body,
    eraId,
    claimReportId,
  });
};
