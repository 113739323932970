import { openPopup } from '@neb/popup';

import {
  DAY,
  FULL_WEEK,
  MONTH,
  PROVIDER,
  ROOM,
  WEEK,
  WORK_WEEK,
} from '../../src/utils/user-message';
import { roomConflictCheck } from '../neb-api-client/src/appointment-api-client';
import { POPUP_RENDER_KEYS } from '../neb-popup/src/renderer-keys';

import { toKebabCase } from './formatters';

export const MAX_OCCUPANCY_POPUP_MESSAGE = {
  title: 'Max Occupancy',
  message: 'Max occupancy has been reached.\n\nDo you want to proceed?',
  confirmText: 'Yes',
  cancelText: 'No',
};

export const SCHEDULER_VIEW_INTERVAL = 'scheduler-view-interval';

export const CALENDAR_TYPES = {
  PROVIDER,
  ROOM,
};

export const CALENDAR_TYPES_SWITCH_LABELS = {
  leftSwitch: PROVIDER,
  rightSwitch: ROOM,
};

export const PERIOD_OPTIONS = [DAY, WORK_WEEK, FULL_WEEK, MONTH];

export const formatSchedulingView = view =>
  view === WORK_WEEK ? WEEK : toKebabCase(view);

export const normalizeViewName = view => {
  switch (view) {
    case 'day':
      return DAY;
    case 'month':
      return MONTH;
    case 'week':
      return WORK_WEEK;
    default:
      return view;
  }
};

export const roomConflictOverridePopup = async roomId => {
  const { isAtLimit } = roomId ? await roomConflictCheck(roomId) : {};

  if (isAtLimit) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, MAX_OCCUPANCY_POPUP_MESSAGE);
  }
  return true;
};
