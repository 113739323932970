import { _itemsGroupedById, _sortItems } from './base';

export const _cancelingDelete = state => ({
  ...state,
  isCancelingDeleting: true,
});

export const _cancelDeleted = (state, { items: unsortedItems }) => {
  const items = _sortItems(unsortedItems);

  return {
    ...state,
    isCancelingDeleting: false,
    cancelDeleteSuccess: true,
    cancelDeleteError: null,
    itemsByGroupId: _itemsGroupedById(items),
    items,
  };
};
export const _cancelDeleteError = (state, { cancelDeleteError }) => ({
  ...state,
  isCancelingDeleting: false,
  cancelDeleteSuccess: false,
  cancelDeleteError,
});
