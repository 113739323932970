import '../controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { padArray } from '../../../../neb-utils/utils';

import LicenseItem from './neb-license-item';
import { ELEMENTS as BASE_ELEMENTS, List } from './neb-list';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  items: {
    selector: 'neb-license-item',
  },
};

export const CONFIRM_LICENSE_REMOVE = html`
  <div>
    This will remove the license and any corresponding associations.
    <br /><br />Are you sure that you want to proceed?
  </div>
`;

export const ADD_LABEL = 'Add License Number';

export default class LicenseList extends List {
  static get properties() {
    return {
      errors: Array,
    };
  }

  static createModel() {
    return [LicenseItem.createModel()];
  }

  static createSelectors() {
    return {
      createItem: () => LicenseItem.createModel(),
      format: v => padArray(v, LicenseItem.createModel()),
      unformat: v => v.filter(item => item.license && item.state),
      children: {
        $: LicenseItem.createSelectors(),
      },
    };
  }

  initState() {
    super.initState();
    this.addLabel = ADD_LABEL;
    this.errors = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      remove: async name => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_LICENSE_REMOVE,
          title: 'Remove License',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (accepted) {
          this.onRemove(this.name, Number(name));
        }
      },
    };
  }

  renderItem(item, index) {
    return html`
      <neb-license-item
        .name="${index}"
        .model="${item}"
        .errors="${this.errors[index]}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        ?showRemoveButton="${this.model.length > 1}"
      ></neb-license-item>
    `;
  }
}

window.customElements.define('neb-license-list', LicenseList);
