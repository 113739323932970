import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ITEM_PAYER_NON_UNIQUE = {
  label: 'Non-Unique',
  data: { id: null },
};

export const ELEMENTS = {
  dateRange: { id: 'range-date' },
  totalChargesRange: { id: 'range-total-charges' },
  superbillTextfield: { id: 'textfield-superbill' },
  patientSearch: { id: 'search-patient' },
  payersSelect: { id: 'select-payers' },
  providersSelect: { id: 'select-providers' },
};

class NebLedgerSuperbillsFilters extends NebFilters {
  static get properties() {
    return {
      __patientItems: Array,
      __payerItems: Array,
      __providerItems: Array,

      enablePatient: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    return {
      superbill: '',
      patientId: '',
      payerIds: [],
      providerIds: [],
      date: NebDateRange.createModel(),
      totalCharges: NebCurrencyRange.createModel(),
    };
  }

  initState() {
    super.initState();

    this.__patientItems = [];
    this.__payerItems = [];
    this.__providerItems = [];

    this.enablePatient = false;
    this.initPatientsService();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      clearSuperbillSearch: () => this.formService.apply('superbill', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        payerIds: selectors.multiSelect([]),
        providerIds: selectors.multiSelect([]),
        date: NebDateRange.createSelectors(),
        totalCharges: NebCurrencyRange.createSelectors(),
      },
    };
  }

  async loadPayerItems() {
    const payers = await payerPlansApi.fetchMany();

    this.__payerItems = payers.data.map(data => ({ label: data.alias, data }));
    this.__payerItems.unshift(ITEM_PAYER_NON_UNIQUE);
  }

  async loadProviderItems() {
    const providers = await getProviderUsers();

    this.__providerItems = providers.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));
  }

  initPatientsService() {
    this.__patientsService = createServerPatientsCollection({
      onChange: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      alwaysShowInactive: true,
    });
  }

  load() {
    return Promise.all([this.loadPayerItems(), this.loadProviderItems()]);
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      ${this.renderPatientFilter()}

      <neb-textfield
        id="${ELEMENTS.superbillTextfield.id}"
        name="superbill"
        label="Superbill ID"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.superbill ? 'neb:clear' : ''}"
        .value="${this.state.superbill}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearSuperbillSearch}"
      ></neb-textfield>

      <neb-range-date
        id="${ELEMENTS.dateRange.id}"
        name="date"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerItems}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        name="providerIds"
        label="Providers"
        helper=" "
        .value="${this.state.providerIds}"
        .items="${this.__providerItems}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-currency
        id="${ELEMENTS.totalChargesRange.id}"
        name="totalCharges"
        label="Total Charges"
        .model="${this.state.totalCharges}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>
    `;
  }
}

window.customElements.define(
  'neb-filters-ledger-superbills',
  NebLedgerSuperbillsFilters,
);
