import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_3,
  CSS_FONT_SIZE_HEADER,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  title: { id: 'title' },
  description: { id: 'description' },
  icon: { id: 'icon' },
};

class NebCardPaymentAdd extends LitElement {
  static get properties() {
    return {
      icon: String,
      title: String,
      description: String,
      value: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.icon = '';
    this.title = '';
    this.description = '';
    this.value = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clicked: () => this.onClick(this.value),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.__handlers.clicked);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.__handlers.clicked);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: 100%;
        }

        .container {
          display: grid;
          cursor: pointer;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
          border-radius: 4px;
          grid-gap: 8px;
          grid-template-columns: 1fr auto;
          grid-template-rows: repeat(2, 1fr);
          align-items: center;
          box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
          width: 100%;
        }

        .container:hover {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .icon-arrow {
          height: 14px;
          width: 14px;
          fill: ${CSS_COLOR_GREY_1};
          transform: rotate(-90deg);
        }

        .icon-symbol {
          width: 28px;
          height: 28px;
          grid-column: 2;
          grid-row: 1 / span 2;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .title {
          display: grid;
          grid-gap: 8px;
          grid-template-columns: auto auto 1fr;
          align-items: center;
          color: ${CSS_COLOR_GREY_1};
        }

        .description {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: 700;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <span id="${ELEMENTS.title.id}" class="title">
          Add ${this.title}

          <neb-icon class="icon-arrow" icon="neb:chevron"></neb-icon>
        </span>

        <neb-icon
          id="${ELEMENTS.icon.id}"
          class="icon-symbol"
          .icon="${this.icon}"
        ></neb-icon>

        <span id="${ELEMENTS.description.id}" class="description"
          >${this.description}</span
        >
      </div>
    `;
  }
}

window.customElements.define('neb-card-payment-add', NebCardPaymentAdd);
