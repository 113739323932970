import './neb-calendar-month-year-picker';
import './neb-calendar-view-row';
import '../../../../src/components/misc/neb-icon';
import './neb-ripple-button';

import { html, LitElement, css } from 'lit';

import {
  SLIDE_DIRECTION,
  slideLeft,
  slideRight,
} from '../../../neb-styles/neb-animation';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_DISABLED,
} from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';
import { MONTH_NAMES } from '../../../neb-utils/neb-cal-util';

export const ELEMENTS = {
  title: {
    id: 'title',
  },
  decrementButton: {
    id: 'button-calendar-month-decrement',
  },
  incrementButton: {
    id: 'button-calendar-month-increment',
  },
  monthYearPicker: {
    id: 'container-picker-month-year',
  },
  todayButton: {
    id: 'button-today',
  },
  calendarViewRow: {
    selector: 'neb-calendar-view-row',
  },
};

export const TYPE = {
  BAR: 'bar',
  BUBBLES: 'bubbles',
};

const DAY_SYMBOLS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const ITEM_WIDTH = css`36px`;
const TICK_SIZE = css`5`;

class NebMomentCalendarView extends LitElement {
  static get properties() {
    return {
      __calendarSlideDirection: {
        type: String,
      },
      __pickerOpen: {
        type: Boolean,
        reflect: true,
        attribute: 'picker-open',
      },
      incrementDisplayMonth: {
        type: Boolean,
      },
      availability: {
        type: Object,
      },
      disableMonthSelection: {
        type: Boolean,
        reflect: true,
      },
      disableCalendarView: {
        type: Boolean,
        reflect: true,
      },
      displayPage: {
        type: Object,
      },
      labelClickable: {
        type: Boolean,
        reflect: true,
        attribute: 'label-clickable',
      },
      isDateSelectable: {
        type: Function,
      },
      isPickerable: {
        type: Boolean,
      },
      isToolbarVisible: {
        type: Boolean,
      },
      selectedDate: {
        type: Object,
      },
      type: {
        type: String,
        reflect: true,
      },
      updateDependency: {
        type: String,
      },
      clearSelectedDate: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initCallbacks();

    this.__initHandlers();
  }

  __initState() {
    this.__calendarSlideDirection = '';
    this.__pickerOpen = false;
    this.incrementDisplayMonth = false;

    this.availability = {};
    this.clearSelectedDate = false;
    this.disableMonthSelection = false;
    this.isPickerable = false;
    this.isToolbarVisible = true;
    this.selectedDate = parseDate();
    this.type = TYPE.BAR;
    this.disableCalendarView = false;

    this.__syncDisplayPage();

    this.updateDependency = null;

    // properties for legacy automation framework
    this.currentYear = this.displayPage.year;
    this.currentMonth = this.displayPage.month;
  }

  __initCallbacks() {
    this.isDateSelectable = _date => true;

    this.onChange = _date => {};

    this.onLabelClicked = () => {};

    this.onMonthChanged = () => {};

    this.onTodaySelected = _date => {};
  }

  __initHandlers() {
    this.__handlers = {
      animationEnd: () => {
        this.__calendarSlideDirection = '';
      },
      decrementMonth: () => {
        const month = this.displayPage.month - 1;

        if (month < 1) {
          this.displayPage = {
            month: month + 12,
            year: this.displayPage.year - 1,
          };
        } else {
          this.displayPage = {
            month,
            year: this.displayPage.year,
          };
        }

        this.__calendarSlideDirection = SLIDE_DIRECTION.LEFT;
        this.onMonthChanged(this.displayPage);
      },
      incrementMonth: () => {
        const month = this.displayPage.month + 1;

        if (month > 12) {
          this.displayPage = {
            month: 1,
            year: this.displayPage.year + 1,
          };
        } else {
          this.displayPage = {
            month,
            year: this.displayPage.year,
          };
        }

        this.__calendarSlideDirection = SLIDE_DIRECTION.RIGHT;
        this.onMonthChanged(this.displayPage);
      },
      labelClicked: () => {
        if (this.isPickerable) {
          if (this.__pickerOpen) {
            this.reset();
          } else {
            this.__pickerOpen = true;
          }
        }

        this.onLabelClicked();
      },
      todaySelected: () => this.onTodaySelected(parseDate().startOf('day')),
      setMonthYear: (month, year) => {
        this.__pickerOpen = !this.__pickerOpen;
        this.__displayPickerToolbar = !this.__displayPickerToolbar;
        this.displayPage = {
          month,
          year,
        };
      },
      cancelChanges: () => {
        this.reset();
      },
      dateChanged: date => {
        this.selectedDate = date;
        this.onChange(date);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    const handler = this.__handlers.animationEnd;
    this.shadowRoot.addEventListener('animationend', handler);
    this.shadowRoot.addEventListener('webkitAnimationEnd', handler);
  }

  reset() {
    this.__pickerOpen = false;
  }

  __getNumDaysInMonth() {
    const { year } = this.displayPage;
    const nextMonth = this.displayPage.month;
    const date = parseDate().year(year).month(nextMonth).date(0);

    return date.date();
  }

  __getStartingDayOffset() {
    const { year } = this.displayPage;
    const monthIndex = this.displayPage.month - 1;
    const date = parseDate().year(year).month(monthIndex).date(1);

    return date.day();
  }

  __getDisplayMonthRowRange() {
    const minSpaces =
      this.__getNumDaysInMonth() + this.__getStartingDayOffset();

    const numRows = Math.ceil(minSpaces / 7);
    const range = new Array(numRows).fill(undefined).map((_, index) => index);

    return range;
  }

  __getDisplayMonthName() {
    const month = this.displayPage.month - 1;
    const monthValue = month % MONTH_NAMES.length;
    const wrappedMonthValue =
      monthValue >= 0 ? monthValue : monthValue + MONTH_NAMES.length;

    return MONTH_NAMES[wrappedMonthValue];
  }

  __syncDisplayPage() {
    const resetDate = this.selectedDate || parseDate();

    this.displayPage = {
      month: resetDate.month() + 1,
      year: resetDate.year(),
    };
  }

  firstUpdated() {
    if (this.__shouldIncrementMonth()) {
      this.__handlers.incrementMonth();
    }
  }

  update(changedProps) {
    if (
      changedProps.has('selectedDate') ||
      changedProps.has('updateDependency')
    ) {
      this.__syncDisplayPage();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      slideLeft,
      slideRight,
      css`
        :host {
          display: inline-block;
          width: 360px;
          background-color: white;
        }

        .container-calendar {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .row-title {
          margin-bottom: ${CSS_SPACING};
          height: 56px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .row-week {
          padding: 0 0 10px 0;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .row-days {
          padding: 4px 0;
        }

        .text {
          margin: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .text-header {
          margin: 0 auto;
          color: ${CSS_COLOR_GREY_1};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .text-title[pickerable] {
          align-items: center;
          cursor: pointer;
          display: flex;
          justify-content: center;
          width: 100%;
        }

        :host([disableCalendarView]) .text-title {
          color: ${CSS_COLOR_DISABLED};
        }

        :host([disableMonthSelection]) .row-title {
          display: flex;
          justify-content: center;
        }

        .title-spacer {
          flex: 0 0 9px;
        }

        :host([label-clickable]) .text-title {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;
        }

        .icon {
          position: relative;
          width: 14px;
          height: 14px;
          z-index: 1;
          margin: 13px 0 0 13px;
        }

        .item {
          display: flex;
          flex: 1 0 0;
        }

        .button {
          cursor: pointer;
          outline: none;
          border: 0;
          background-color: transparent;
          text-align: center;
        }

        .button[disabled] {
          cursor: default;
        }

        .button-page {
          cursor: pointer;
          width: ${ITEM_WIDTH};
          height: ${ITEM_WIDTH};
        }

        .content {
          position: relative;
        }

        .content[slide=${SLIDE_DIRECTION.RIGHT}] {
          animation: slide-right 0.4s linear forwards;
          -webkit-animation: slide-right 0.4s linear forwards;
        }

        .content[slide=${SLIDE_DIRECTION.LEFT}] {
          animation: slide-left 0.4s linear forwards;
          -webkit-animation: slide-left 0.4s linear forwards;
        }

        .caret {
          height: 0;
          width: 0;
          border-top: ${TICK_SIZE}px solid ${CSS_COLOR_BLACK};
          border-left: ${TICK_SIZE}px solid transparent;
          border-right: ${TICK_SIZE}px solid transparent;
        }

        :host([picker-open]) .caret {
          transform: rotate(180deg);
        }

        .container-picker-month-year {
          position: absolute;
          width: 100%;
          height: 100%;
          background: ${CSS_COLOR_WHITE};
          display: flex;
          z-index: 1;
        }

        .picker {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .button-label {
          display: block;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-align: right;
          text-decoration: underline;
          font-size: 14px;
          padding: 10px 0; /*break apart to be reusable*/
        }

        .toolbar {
          padding: 10px ${CSS_SPACING} ${CSS_SPACING};
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }

        .toolbar > .button-label {
          margin: 0 0 0 10px;
        }

        .container-picker {
          position: relative;
        }

        .chevron-icon {
          fill: ${CSS_COLOR_GREY_1};
        }

        .footer-spacer {
          margin-bottom: 40px;
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }
      `,
    ];
  }

  __shouldIncrementMonth() {
    return (
      parseDate().date() === parseDate().daysInMonth() &&
      this.incrementDisplayMonth &&
      !this.isDateSelectable(parseDate().startOf('day'))
    );
  }

  __renderTodayToolbar() {
    return this.isDateSelectable(parseDate().startOf('day'))
      ? html`
          <div class="toolbar">
            <button
              id="${ELEMENTS.todayButton.id}"
              type="button"
              class="button button-label"
              @click="${this.__handlers.todaySelected}"
            >
              Today
            </button>
          </div>
        `
      : this.__renderFooterSpacer();
  }

  __renderFooterSpacer() {
    return html` <div class="footer-spacer"></div> `;
  }

  __renderMonthRightArrow() {
    return !this.__pickerOpen && !this.disableMonthSelection
      ? html`
          <neb-ripple-button
            id="${ELEMENTS.incrementButton.id}"
            class="button-page"
            .onClick="${this.__handlers.incrementMonth}"
          >
            <neb-icon
              slot="content"
              class="icon chevron-icon"
              icon="neb:chevronRotatedRight"
            ></neb-icon>
          </neb-ripple-button>
        `
      : '';
  }

  __renderCalendarTitle() {
    return html`
      <div
        id="${ELEMENTS.title.id}"
        class="text text-title"
        @click="${this.__handlers.labelClicked}"
        ?pickerable="${this.isPickerable}"
      >
        <span
          >${[this.__getDisplayMonthName(), this.displayPage.year].join(
            ' ',
          )}</span
        >
        ${this.isPickerable
          ? html`
              <span class="title-spacer"></span> <span class="caret"></span>
            `
          : ''}
      </div>
    `;
  }

  __renderMonthLeftArrow() {
    return !this.__pickerOpen && !this.disableMonthSelection
      ? html`
          <neb-ripple-button
            id="${ELEMENTS.decrementButton.id}"
            class="button-page"
            .onClick="${this.__handlers.decrementMonth}"
          >
            <neb-icon
              slot="content"
              class="icon chevron-icon"
              icon="neb:chevronRotatedLeft"
            ></neb-icon>
          </neb-ripple-button>
        `
      : '';
  }

  render() {
    return html`
      <div class="container-calendar">
        <div class="row row-title">
          ${this.__renderMonthLeftArrow()} ${this.__renderCalendarTitle()}
          ${this.__renderMonthRightArrow()}
        </div>

        <div class="container-picker">
          ${this.__pickerOpen
            ? html`
                <neb-calendar-month-year-picker
                  id="${ELEMENTS.monthYearPicker.id}"
                  class="container-picker-month-year"
                  .selectedMonth="${this.displayPage.month}"
                  .selectedYear="${this.displayPage.year}"
                  .onChange="${this.__handlers.setMonthYear}"
                  .onCancel="${this.__handlers.cancelChanges}"
                ></neb-calendar-month-year-picker>
              `
            : ''}

          <div class="content" slide="${this.__calendarSlideDirection}">
            <div class="row row-week">
              ${DAY_SYMBOLS.map(
                item => html`
                  <div class="item">
                    <span class="text  text-header">${item}</span>
                  </div>
                `,
              )}
            </div>

            ${this.__getDisplayMonthRowRange().map(
              weekIndex => html`
                <neb-calendar-view-row
                  .availability="${this.availability}"
                  .clearSelectedDate="${this.clearSelectedDate}"
                  .displayPage="${this.displayPage}"
                  .index="${weekIndex}"
                  .selectedDate="${this.selectedDate}"
                  .type="${this.type}"
                  .onDateChanged="${this.__handlers.dateChanged}"
                  .isDateSelectable="${this.isDateSelectable}"
                  .disableCalendarView="${this.disableCalendarView}"
                  momentFlag
                ></neb-calendar-view-row>
              `,
            )}
          </div>

          ${this.isToolbarVisible ? this.__renderTodayToolbar() : ''}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-moment-calendar-view', NebMomentCalendarView);
