import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-styles/neb-icons';

import { LitElement, html, css } from 'lit';

import { readDataUrlFromBlob } from '../../../../neb-utils/blobProcessor';
import { PdfProcessor } from '../pdf-viewer/core/neb-pdf-processor';

export const ID_CANVAS = 'canvas';
export const ID_STOCK_PDF_DIV = 'stock-pdf';
const TIMEOUT_ERROR = new Error('Timeout');
const TIMEOUT = 2000;

class NebPdfPreview extends LitElement {
  static get properties() {
    return {
      fileBlob: Object,
      timeout: Number,
      __showFallback: Boolean,
    };
  }

  constructor() {
    super();
    this.fileBlob = null;
    this.timeout = TIMEOUT;
    this.__pdfProcessor = new PdfProcessor();
    this.__showFallback = false;

    this.onInvalidFile = () => {};
  }

  loadPdfContent(canvas) {
    const promise = readDataUrlFromBlob(this.fileBlob)
      .then(dataUrl => this.__pdfProcessor.open(dataUrl))
      .then(async () => {
        await this.__pdfProcessor.renderPageForPreview(canvas);
        return true;
      });
    const timeoutPromise = new Promise(resolve =>
      setTimeout(() => resolve(false), this.timeout),
    );
    return Promise.race([promise, timeoutPromise]).then(
      res => res || Promise.reject(TIMEOUT_ERROR),
    );
  }

  async firstUpdated() {
    try {
      const canvas = this.shadowRoot.getElementById(ID_CANVAS);
      await this.loadPdfContent(canvas);
    } catch (err) {
      this.__showFallback = true;

      if (TIMEOUT_ERROR === err) {
        console.log(err.message);
      } else {
        console.error(err);
        this.onInvalidFile();
      }
    }
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        width: 100%;
      }

      .stock-pdf-preview-container {
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 150px;
      }

      .icon-pdf {
        display: flex;
        width: 75px;
        height: 75px;
      }
    `;
  }

  render() {
    return html`
      ${this.__showFallback === true
        ? html`
            <div id="${ID_STOCK_PDF_DIV}" class="stock-pdf-preview-container">
              <neb-icon
                id="preview-pdf"
                class="icon icon-pdf"
                icon="neb:pdf"
              ></neb-icon>
            </div>
          `
        : html` <canvas id="${ID_CANVAS}"></canvas> `}
    `;
  }
}

customElements.define('neb-pdf-preview', NebPdfPreview);
