import { DARK_COLORS } from '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color';
import { fetchMany, save } from '../../../api-clients/tags';

export const fetchTags = async () =>
  (await fetchMany(true))
    .map(({ id, name, color }) => ({
      label: name,
      data: { id, color },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const findAndCreateNewTags = async tags => {
  const temporaryTagIndex = tags.findIndex(tag => !tag.data.id);

  if (temporaryTagIndex !== -1) {
    const tag = await save({
      name: tags[temporaryTagIndex].label,
      color: tags[temporaryTagIndex].data.color,
      description: '',
    });

    tags[temporaryTagIndex].data.id = tag.data[0].id;
  }
  return tags;
};

export const searchTags = async searchText => {
  const tags = await fetchTags();

  if (!searchText) {
    return tags;
  }

  const color = DARK_COLORS[Math.floor(Math.random() * DARK_COLORS.length)];

  const exactMatchTagExists = tags.some(
    ({ label }) => label.toLowerCase() === searchText.toLowerCase(),
  );

  const filteredTags = tags.filter(({ label }) =>
    label.toLowerCase().includes(searchText.toLowerCase()),
  );

  if (exactMatchTagExists) {
    return filteredTags;
  }

  const exactMatchTag = {
    label: searchText,
    data: {
      id: '',
      color,
    },
  };

  return [exactMatchTag, ...filteredTags];
};
