import { openError } from '../../../store';
import { errorOccurred } from '../../../utils/user-message';

export const showPanelLoadError = itemDescription => {
  openError(errorOccurred('loading', itemDescription));
};

export const safeRequest = async (cb, itemDescription) => {
  try {
    await cb();
  } catch (e) {
    console.error(e);

    showPanelLoadError(itemDescription);
  }
};
