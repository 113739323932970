import { html, css } from 'lit';

import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import {
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
} from '../../../packages/neb-styles/neb-variables';
import NebFormUpdateAssociatedCase from '../forms/neb-form-update-associated-case';

export const ELEMENTS = {
  form: { id: 'form' },
  header: { id: 'header' },
};

class NebPopupUpdateAssociatedCase extends NebPopup {
  static get properties() {
    return {
      model: Object,
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 480px;
        }

        :host([layout='small']) {
          width: 90%;
        }

        .header {
          margin-bottom: ${CSS_SPACING_ROW_LARGE};
        }

        .content {
          width: 100%;
          margin-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    super.initState();

    this.title = 'Update Associated Case And Authorization';
    this.model = {
      defaultCaseId: '',
      patientAuthorizationId: '',
      patientId: '',
      encounterIds: [],
      enableSave: false,
    };

    this.__formModel = NebFormUpdateAssociatedCase.createModel();
  }

  __initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      cancel: () => this.onClose(),
      addNewCase: () => this.onClose({ type: 'addNewCase' }),
      updateCase: ({ selectedCase, selectedPatientAuthorizationId }) =>
        this.onClose({
          type: 'updateCase',
          caseId: selectedCase.data.id,
          patientAuthorizationId: selectedPatientAuthorizationId,
        }),
    };
  }

  modelChanged() {
    const { defaultCaseId, ...model } = this.model;
    const caseId = defaultCaseId || this.__formModel.caseId;
    this.__formModel = { ...this.__formModel, ...model, caseId };
  }

  renderContent() {
    return html`
      <neb-form-update-associated-case
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .onCancel="${this.__handlers.cancel}"
        .onAddNewCase="${this.__handlers.addNewCase}"
        .onUpdateCase="${this.__handlers.updateCase}"
      ></neb-form-update-associated-case>
    `;
  }
}

window.customElements.define(
  'neb-popup-update-associated-case',
  NebPopupUpdateAssociatedCase,
);
