import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import { html, css } from 'lit';

import '../../misc/neb-icon';
import NebTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CLEARINGHOUSES } from '../../../utils/clearinghouse-settings';

export const ELEMENTS = {
  actionButtons: { selector: 'neb-button-actions' },
  popoverContainer: {
    id: 'popover-container',
  },
};

class NebTableClearinghouseSettings extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        .button-action {
          height: auto;
          --menu-min-width: var(--action-menu-min-width);
        }

        :host {
          --action-menu-min-width: 210px;
        }

        .popover-text {
          overflow-wrap: break-word;
          max-width: 300px;
          height: 100%;
          white-space: normal;
        }

        .icon-warn-container {
          display: flex;
          flex-direction: row;
        }

        .tooltip-icon {
          display: flex;
          flex: 0 0 30px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.onSwapClearinghouse = () => {};
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const row = this.model[rowIndex];

    if (
      columnConfig.key === 'actionMenu' &&
      row.partner !== CLEARINGHOUSES.CHC
    ) {
      return this.__renderActionButton(row);
    }

    if (
      columnConfig.key === 'partner' &&
      row.partner !== CLEARINGHOUSES.CHC &&
      row.alias
    ) {
      const partnerAlias = `${row.partner} (${row.alias})`;
      return super.renderDataCell(partnerAlias, columnConfig);
    }

    return super.renderDataCell(value, columnConfig);
  }

  __genMenuItems(row) {
    return [
      {
        icon: '',
        label: 'Swap Clearinghouse',
        onSelect: () => this.onSwapClearinghouse(row),
      },
    ];
  }

  __renderActionButton(row) {
    return html`
      <neb-button-actions
        tabindex="1"
        class="cell button-action"
        .value="${this.__genMenuItems(row)}"
        vertical
      ></neb-button-actions>
    `;
  }
}
customElements.define(
  'neb-table-clearinghouse-settings',
  NebTableClearinghouseSettings,
);
