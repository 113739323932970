import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html } from 'lit';

import {
  ELEMENTS as BASE_ELEMENTS,
  Item,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-item';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { getValueByPath } from '../../../../packages/neb-utils/utils';

export const ELEMENTS = { ...BASE_ELEMENTS };

const isRequiredIfProvider = () => [
  {
    error: 'Required',
    validate: (v, keyPath, model) => {
      const path = keyPath.slice(0, keyPath.length - 1);
      const provider = getValueByPath(model, [...path, 'providerId']);

      return provider.label ? v.length > 0 : true;
    },
  },
];
export default class NebSubstituteProviderItem extends Item {
  static get properties() {
    return {
      errors: Object,
      helpers: Object,
      model: Object,
      providerItems: Array,
      payerItems: Array,
      providerLabel: String,
    };
  }

  static createModel() {
    return {
      providerId: '',
      payerIds: [],
    };
  }

  static createSelectors(providerItems = [], payerItems = []) {
    return {
      children: {
        providerId: selectors.select(providerItems, selectors.ITEM_EMPTY, {
          validators: [],
        }),
        payerIds: selectors.multiSelect(payerItems, [], {
          validators: isRequiredIfProvider(),
        }),
      },
    };
  }

  initState() {
    super.initState();

    this.providerItems = [];
    this.payerItems = [];

    this.errors = {
      providerId: '',
      payerIds: '',
    };

    this.helpers = {
      providerId: '',
      payerIds: '',
    };

    this.model = {
      providerId: '',
      payerIds: [],
    };
  }

  renderLeftContent() {
    return html`
      <neb-select
        id="${ELEMENTS.leftContent.id}"
        class="field"
        name="providerId"
        label="${this.providerLabel}"
        .items="${this.providerItems}"
        .value="${this.model.providerId}"
        .error="${this.errors.providerId}"
        .helper="${this.helpers.providerId || ' '}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  renderRightContent() {
    return html`
      <neb-select
        id="${ELEMENTS.rightContent.id}"
        class="field field-selector"
        name="payerIds"
        label="Payers"
        .items="${this.payerItems}"
        .value="${this.model.payerIds}"
        .error="${this.errors.payerIds}"
        .helper="${this.helpers.payerIds || ' '}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }
}

window.customElements.define(
  'neb-substitute-provider-item',
  NebSubstituteProviderItem,
);
