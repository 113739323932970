import '../../neb-selection-card';
import '../../neb-popup-header';

import { html, css } from 'lit';

import { getMany } from '../../../../../../src/api-clients/charges';
import { openOverlayPatientInsuranceAddCoveredProcedure } from '../../../../../neb-app-layout/neb-open-overlay';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  serviceTypeCard: {
    id: 'service-type-card',
  },
  procedureCodeCard: {
    id: 'procedure-code-card',
  },
};

class NebOverlayPatientInsuranceServices extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          width: 700px;
          grid-gap: ${CSS_SPACING};
          grid-auto-rows: min-content;
          overflow: auto;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .item {
          margin: 0 ${CSS_SPACING};
        }

        .bottom-card {
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      procedureClick: () => this.openProcedureAdd(),
      addServiceType: () => this.handlers.dismiss('serviceType'),
    };
  }

  async openProcedureAdd() {
    const charges = await getMany({ hideInactive: true });

    const selectedItems = await openOverlayPatientInsuranceAddCoveredProcedure({
      coveredType: this.model.coveredType,
      items: charges,
      selectedCharges: [...this.model.selectedCharges],
      selectedSecondary: this.model.selectedSecondary,
    });

    if (selectedItems) {
      const chargeResults = [];

      selectedItems.forEach(selectedItem => {
        const idx = charges.findIndex(charge => charge.id === selectedItem.id);

        chargeResults.push(charges[idx]);
      });

      this.handlers.dismiss(chargeResults);
    }
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.model.coveredType
          ? 'Add Covered Service'
          : 'Add Non-Covered Service'}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <p class="item">
        ${this.model.coveredType
          ? 'Choose a covered service type to add as a covered service.'
          : 'Choose a service type to add as a non-covered service.'}
      </p>

      <div class="item">
        <neb-selection-card
          id="${ELEMENTS.serviceTypeCard.id}"
          title="Service Type"
          .onClick="${this.handlers.addServiceType}"
          ><p>
            ${this.model.coveredType
              ? 'Select a service type to add as a covered service.'
              : 'Select a service type to add as a non-covered service.'}
          </p>
        </neb-selection-card>
      </div>

      <div class="item bottom-card">
        <neb-selection-card
          id="${ELEMENTS.procedureCodeCard.id}"
          title="Procedure Code"
          .onClick="${this.handlers.procedureClick}"
          ><p>
            ${this.model.coveredType
              ? 'Select a procedure code to add as a covered service.'
              : 'Select a procedure code to add as a non-covered service.'}
          </p>
        </neb-selection-card>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-services',
  NebOverlayPatientInsuranceServices,
);
