import '@polymer/iron-icon/iron-icon';

import '../controls/neb-button-action';
import '../../../../neb-styles/neb-styles';

import { html, LitElement, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_BORDER_GREY_3,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  problemAddButton: {
    id: 'problem-add-button',
  },
  title: {
    id: 'title',
  },
  messageNoProblems: {
    id: 'message-no-problems',
    text: 'There are no problems for this patient.',
  },
};

class NebActiveProblemsPanel extends LitElement {
  static get properties() {
    return {
      activeProblems: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.activeProblems = [];

    this.onAddProblem = () => {};

    this.onSectionClicked = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      sectionClicked: () => this.onSectionClicked(),
      addProblem: e => {
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }

        this.onAddProblem();
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        border: ${CSS_BORDER_GREY_2};
        border-radius: 5px;
        margin: ${CSS_SPACING} 0;
        cursor: pointer;
      }

      :host([layoutsize='small']) {
        border: 0;
        border-radius: 0;
        margin: 5px 0;
      }

      :host([layoutsize='small']) .problem-detail {
        margin-left: -20px;
      }

      :host([layoutsize='small']) .problem-detail .problem-row {
        margin-left: 20px;
      }

      .title {
        text-decoration: underline;
        font-weight: bold;
        color: ${CSS_COLOR_HIGHLIGHT};
        margin: 10px;
      }

      .problem-row {
        display: flex;
        border-bottom: ${CSS_BORDER_GREY_3};
        padding: 3px 10px;
      }

      .cell {
        display: flex;
        align-items: center;
      }

      .cell-header {
        font-weight: bold;
        margin-bottom: 3px;
      }

      .cell-code {
        flex: 1 0 0;
      }

      .cell-short-description {
        margin-right: 10px;
        flex: 2 0 0;
      }

      .cell-type {
        text-transform: capitalize;
        flex: 1 0 0;
      }

      .cell-icon {
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .navigation-icon {
        transform: rotate(270deg);
        color: ${CSS_COLOR_GREY_1};
        height: 10px;
        width: 10px;
      }

      .add-problem {
        margin: 10px;
        display: flex;
        align-items: center;
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .add-problem-text {
        display: inline-block;
        margin-left: 5px;
      }

      .add-problem-icon {
        cursor: pointer;
        height: 18px;
        width: 18px;
      }

      .no-problems {
        padding: 3px 10px;
        margin: ${CSS_SPACING};
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.__handlers.sectionClicked);
  }

  __renderProblems() {
    return this.activeProblems.length
      ? this.activeProblems.map(
          p => html`
            <div class="problem-detail">
              <div class="problem-row">
                <div class="cell cell-code">${p.code}</div>
                <div class="cell cell-short-description">
                  ${p.shortDescription}
                </div>
                <div class="cell cell-type">${p.type}</div>
                <div class="cell cell-icon">
                  <iron-icon
                    class="navigation-icon"
                    icon="neb:down"
                  ></iron-icon>
                </div>
              </div>
            </div>
          `,
        )
      : html`
          <div id="${ELEMENTS.messageNoProblems.id}" class="no-problems">
            ${ELEMENTS.messageNoProblems.text}
          </div>
        `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        Active Problems (${this.activeProblems.length})
      </div>

      <div class="problem-row">
        <div class="cell cell-header cell-code">Diagnosis</div>

        <div class="cell cell-header cell-short-description">Description</div>

        <div class="cell cell-header cell-type">Type</div>

        <div class="cell cell-header cell-icon"></div>
      </div>

      ${this.__renderProblems()}

      <neb-button-action
        id="${ELEMENTS.problemAddButton.id}"
        class="add-problem"
        label="Add Problem"
        .onClick="${this.__handlers.addProblem}"
      ></neb-button-action>
    `;
  }
}

customElements.define('neb-active-problems-panel', NebActiveProblemsPanel);
