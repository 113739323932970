import '../inputs/neb-select';
import '../neb-toggle-button';

import { css, html } from 'lit';

import { formatAddress } from '../../../../neb-utils/formatters';

import NebTable from './neb-table';

export const ELEMENTS = {
  addButtons: { selector: '[id^=button-add-]' },
  dropdownProviders: { selector: '[id^=dropdown-provider-]' },
};

const DROPDOWN_ERROR_OFFSET = 16;

class NebTablePayerPlans extends NebTable {
  static get properties() {
    return {
      showAddButton: {
        reflect: true,
        type: Boolean,
      },
      providers: Array,
      showProviders: {
        reflect: true,
        type: Boolean,
      },
      errors: Array,
    };
  }

  initState() {
    super.initState();

    this.providers = [];
    this.showAddButton = false;
    this.showStatus = true;
    this.showProviders = false;
    this.errors = [];

    this.onAddButtonClick = () => {};

    this.onChangeProvider = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addButtonClick: (_, index) => this.onAddButtonClick(index),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button {
          width: 100%;
        }
        .dropdown {
          margin-top: -${DROPDOWN_ERROR_OFFSET}px;
        }
      `,
    ];
  }

  __getConfig() {
    return [
      ...(this.showAddButton
        ? [
            {
              key: 'added',
              label: '',
              flex: css`0 0 140px`,
            },
          ]
        : []),
      {
        key: 'alias',
        label: 'Alias',
        flex: css`0 0 80px`,
      },
      {
        key: 'payerId',
        label: 'Payer ID',
        flex: css`0 0 100px`,
      },
      {
        key: 'payerName',
        label: 'Payer Name',
        flex: css`1 0 160px`,
      },
      ...(this.showProviders
        ? [
            {
              key: 'providerIds',
              label: 'Associated Providers',
              flex: css`1 0 220px`,
              customStyle: css`
                position: relative;
                top: ${DROPDOWN_ERROR_OFFSET}px;
              `,
            },
          ]
        : [
            {
              key: 'address',
              label: 'Address',
              flex: css`1 0 220px`,
              formatter: v => formatAddress(v),
            },
          ]),
      this.showStatus
        ? {
            key: 'active',
            label: 'Status',
            flex: css`0 0 100px`,
            formatter: v => (v ? 'Active' : 'Inactive'),
          }
        : [],
    ];
  }

  firstUpdated() {
    this.config = this.__getConfig();
  }

  __renderAddButton(value, rowIndex) {
    const buttonText = value ? 'Added' : 'Add';

    return html`
      <neb-toggle-button
        id="button-add-${rowIndex}"
        class="button"
        buttonText="${buttonText}"
        .index="${rowIndex}"
        .isToggled="${value}"
        .onClick="${this.handlers.addButtonClick}"
        ?disabled="${value}"
      ></neb-toggle-button>
    `;
  }

  __renderProviderDropdown(value, rowIndex) {
    const availableProviders = this.model[rowIndex].availableProviders || [];

    const showAll = availableProviders.length === this.providers.length;

    return html`
      <neb-select
        id="dropdown-provider-${rowIndex}"
        class="dropdown"
        name="${rowIndex}.providerIds"
        .items="${availableProviders}"
        .value="${value}"
        .error="${this.errors[rowIndex].providerIds}"
        .onChange="${this.handlers.change}"
        multiSelect
        allLabel="${showAll ? 'Providers' : ''}"
        placeholder="No Providers Selected"
        helper=" "
      ></neb-select>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'added':
        return this.__renderAddButton(value, rowIndex);

      case 'providerIds':
        return this.__renderProviderDropdown(value, rowIndex);

      default:
        return value;
    }
  }
}
customElements.define('neb-table-payer-plans', NebTablePayerPlans);
