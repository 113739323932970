/* 
    WARNING!!!!

  This component does not render into a shadowDom.

  Only use with approval of Team Crab
*/

import { LitElement, html, css } from 'lit';

export class LightDom extends LitElement {
  __mergeStyles() {
    const userStyles = this.constructor.styles;

    if (Array.isArray(userStyles)) {
      return userStyles.reduce(
        (acc, s) => css`
          ${acc} ${s}
        `,
        css``,
      );
    }

    return userStyles;
  }

  getElementById(id) {
    return this.querySelector(`#${id}`);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <style>
        ${this.__mergeStyles()}
      </style>

      ${this.renderContent()}
    `;
  }
}
