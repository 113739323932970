import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tab-old';
import './neb-patient-guarantor-page';
import './neb-patient-general-page';
import './neb-recurring-scheduled-page';
import './neb-patient-relationships-page';
import '../../../../../src/components/pages/patients/packages/neb-page-care-packages-subscriptions';

import { matchRouteSwitch, navigate, redirect, getRoute } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { getPatientPackageCount } from '../../../../../src/api-clients/patient-package';
import { getPatientRelationshipsCount } from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getActiveMerchantAccountCount } from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { countScheduledPayments } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';

const LABEL_GENERAL = 'General';
export const LABEL_GUARANTOR = 'Guarantor';
const LABEL_RELATIONSHIPS = 'Relationships';
const LABEL_PACKAGES_SUBSCRIPTIONS = 'Packages & Subscriptions';
const LABEL_RECURRING_SCHEDULED = 'Recurring & Scheduled';
export const LABELS = {
  [LABEL_GUARANTOR]: 'Guarantor',
  [LABEL_RELATIONSHIPS]: 'Relationships',
  [LABEL_PACKAGES_SUBSCRIPTIONS]: 'Packages & Subscriptions',
  [LABEL_RECURRING_SCHEDULED]: 'Recurring & Scheduled',
};
export const ELEMENTS = {
  billingTabs: {
    id: 'billing-tabs-container',
  },
  generalTab: {
    id: 'general-tab',
  },
  guarantorTab: {
    id: 'guarantor-tab',
  },
  relationshipsTab: {
    id: 'relationships-tab',
  },
  packagesSubscriptionTab: {
    id: 'packages-subscription-tab',
  },
  recurringScheduledTab: {
    id: 'recurring-scheduled-tab',
  },
  guarantorPage: {
    id: 'guarantor-page',
  },
  generalPage: {
    id: 'general-page',
  },
  relationshipsPage: {
    id: 'relationships-page',
  },
  packagesSubscriptionsCollectionPage: {
    id: 'packages-subscriptions-collection-page',
  },
  packagesSubscriptionsPage: {
    id: 'packages-subscriptions-page',
  },
  recurringScheduledPage: {
    id: 'recurring-scheduled-page',
  },
};

const SELF_GUARANTOR_COUNT = 1;

class NebPatientTabsBillingController extends LitElement {
  static get properties() {
    return {
      route: {
        type: String,
      },
      patient: {
        type: Object,
      },
      guarantors: {
        type: Array,
      },
      layout: {
        type: String,
      },
      __navItems: {
        type: Array,
      },
      __selectedTab: {
        type: String,
      },
      __hideInactiveRelationships: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      .tabs {
        padding: 10px ${CSS_SPACING} 0 ${CSS_SPACING};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        background-color: ${CSS_COLOR_WHITE};
        flex: 0 0 auto;
      }

      .white-space {
        height: 100%;
      }

      .guarantor {
        padding-top: 20px;
      }

      .packages-subscriptions {
        padding-top: 20px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__tabsCount = {
      [LABEL_GUARANTOR]: SELF_GUARANTOR_COUNT,
      [LABEL_PACKAGES_SUBSCRIPTIONS]: '',
      [LABEL_RECURRING_SCHEDULED]: '',
    };

    this.__selectedTab = 'general';
    this.route = '';
    this.patient = {};
    this.__navItems = this.genNavItems();
    this.guarantors = [];
    this.packagesSubscriptions = [];
    this.layout = '';
    this.__hideInactiveGuarantor = true;
    this.__hideInactiveRelationships = true;
    this.__hidePackagesSubscriptions = true;

    this.onGuarantorChange = () => {};

    this.onPackagesSubscriptionsChange = () => {};

    this.onUpdatePatient = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: selectedTab => {
        navigate(`#/patients/${this.patient.id}/billing/${selectedTab}`);
      },
      toggleGuarantor: (tabName, items) => {
        this.__updateTabCount(tabName, items.length + SELF_GUARANTOR_COUNT);
      },
      updateGuarantor: hideInactive => {
        this.onGuarantorChange();
        this.__hideInactiveGuarantor = hideInactive;
      },
      updatePatientRelationships: async (
        hideInactive,
        count,
        navigateAway = false,
      ) => {
        this.__hideInactiveRelationships = hideInactive;

        if (navigateAway) {
          await this.__updateRelationshipsCount();
        } else {
          this.__updateTabCount(LABEL_RELATIONSHIPS, count);
        }
      },
      updatePackagesSubscriptions: async (
        hideInactive,
        count,
        navigateAway = false,
      ) => {
        this.__hidePackagesSubscriptions = hideInactive;

        if (navigateAway) {
          this.__updateTabCount(
            LABEL_PACKAGES_SUBSCRIPTIONS,
            await getPatientPackageCount(this.patient.id, {
              includeShared: true,
              hideInactive: true,
            }),
          );
        } else {
          this.__updateTabCount(LABEL_PACKAGES_SUBSCRIPTIONS, count);
        }
      },
      updatePatient: () => this.onUpdatePatient(),
      updateRecurringScheduled: count => {
        this.__updateTabCount(LABEL_RECURRING_SCHEDULED, count);
      },
      syncTabCounts: async ({ detail: { changed } }) => {
        if (
          changed.includes(REFRESH_CHANGE_TYPE.PATIENT_PACKAGE) ||
          changed.includes(REFRESH_CHANGE_TYPE.SCHEDULED_PAYMENTS)
        ) {
          await this.__updatePackagesCount();
          await this.__updateRecurringScheduledCount();
        } else if (changed.includes(REFRESH_CHANGE_TYPE.PATIENT)) {
          await this.__updateRelationshipsCount();
        }
      },
    };
  }

  connectedCallback() {
    window.addEventListener(
      NEBULA_REFRESH_EVENT,
      this.__handlers.syncTabCounts,
    );

    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener(
      NEBULA_REFRESH_EVENT,
      this.__handlers.syncTabCounts,
    );

    super.disconnectedCallback();
  }

  firstUpdated(changed) {
    this.__updatePackagesCount();

    this.__updateRecurringScheduledCount();

    this.__updateRelationshipsCount();

    super.firstUpdated(changed);
  }

  update(changed) {
    this.__whenRouteHasChanged(changed);

    this.__whenGuarantorsHaveChanged(changed);

    this.__whenPatientHasChanged(changed);

    super.update(changed);
  }

  __formatTabLabel(tab) {
    const label = LABELS[tab];

    return this.__tabsCount[tab] > 0
      ? `${label} (${this.__tabsCount[tab]})`
      : label;
  }

  async __updatePackagesCount() {
    const activePackagesCount = await getPatientPackageCount(this.patient.id, {
      includeShared: true,
      hideInactive: true,
    });

    this.__updateTabCount(LABEL_PACKAGES_SUBSCRIPTIONS, activePackagesCount);
  }

  async __updateRecurringScheduledCount() {
    const scheduleCount = await countScheduledPayments(this.patient.id);
    const merchantAccountsCount = await getActiveMerchantAccountCount();

    if (merchantAccountsCount > 0) {
      this.__updateTabCount(LABEL_RECURRING_SCHEDULED, scheduleCount.count);
    }
  }

  async __updateRelationshipsCount() {
    const relationshipsCount = await getPatientRelationshipsCount(
      this.patient.id,
      this.__hideInactiveRelationships ? { active: true } : {},
    );

    this.__updateTabCount(LABEL_RELATIONSHIPS, relationshipsCount.count);
  }

  __updateTabCount(tab, count) {
    this.__tabsCount = { ...this.__tabsCount, [tab]: count };

    this.__navItems = this.genNavItems();
  }

  __whenRouteHasChanged(changed) {
    if (changed.has('route')) {
      this.__handleSelectedTab();

      if (this.route === '/') redirect(`#${getRoute()}/general`);
    }
  }

  __whenGuarantorsHaveChanged(changed) {
    if (changed.has('guarantors')) {
      this.__activeGuarantors = this.__hideInactiveGuarantor
        ? this.guarantors.filter(g => g.active)
        : this.guarantors.filter(g => g.id);

      this.__updateTabCount(
        LABEL_GUARANTOR,
        this.__activeGuarantors.length + SELF_GUARANTOR_COUNT,
      );
    }
  }

  __whenPatientHasChanged(changed) {
    if (changed.has('patient') && !changed.has('route')) {
      this.__updateRelationshipsCount();

      this.__updatePackagesCount();
      this.__updateRecurringScheduledCount();
    }
  }

  __handleSelectedTab() {
    this.__selectedTab = this.route.split('/')[1] || '';
  }

  genNavItems() {
    return [
      {
        exact: false,
        label: LABEL_GENERAL,
        name: 'general',
        path: '/general/',
        resolver: () => html`
          <neb-patient-general-page
            id="${ELEMENTS.generalPage.id}"
            .patient="${this.patient}"
            .layout="${this.layout}"
            .onUpdatePatient="${this.__handlers.updatePatient}"
          ></neb-patient-general-page>
        `,
      },
      {
        exact: false,
        label: LABEL_GUARANTOR,
        name: 'guarantor',
        path: '/guarantor/',
        resolver: () => html`
          <neb-patient-guarantor-page
            id="${ELEMENTS.guarantorPage.id}"
            class="guarantor"
            .guarantors="${this.guarantors}"
            .patient="${this.patient}"
            .onGuarantorChange="${this.__handlers.updateGuarantor}"
            .onChecked="${this.__handlers.toggleGuarantor}"
            .layout="${this.layout}"
          ></neb-patient-guarantor-page>
        `,
      },
      {
        exact: false,
        label: LABEL_RELATIONSHIPS,
        name: 'relationships',
        path: '/relationships/',
        resolver: () => html`
          <neb-patient-relationships-page
            id="${ELEMENTS.relationshipsPage.id}"
            class="white-space"
            .patientId="${this.patient.id}"
            .onChange="${this.__handlers.updatePatientRelationships}"
            .layout="${this.layout}"
          ></neb-patient-relationships-page>
        `,
      },
      {
        exact: false,
        label: LABEL_PACKAGES_SUBSCRIPTIONS,
        name: 'packages-subscriptions',
        path: '/packages-subscriptions/',
        resolver: () => html`
          <neb-page-care-packages-subscriptions
            id="${ELEMENTS.packagesSubscriptionsPage.id}"
            class="white-space"
            .patientId="${this.patient.id}"
            .onPackagesSubscriptionsChange="${this.__handlers
              .updatePackagesSubscriptions}"
            .layout="${this.layout}"
          ></neb-page-care-packages-subscriptions>
        `,
      },
      {
        exact: false,
        label: LABEL_RECURRING_SCHEDULED,
        name: 'recurring-scheduled',
        path: '/recurring-scheduled/',
        resolver: () => html`
          <neb-recurring-scheduled-page
            id="${ELEMENTS.recurringScheduledPage.id}"
            class="white-space"
            .patientId="${this.patient.id}"
            .layout="${this.layout}"
            .onRecurringScheduledChange="${this.__handlers
              .updateRecurringScheduled}"
          ></neb-recurring-scheduled-page>
        `,
      },
    ];
  }

  __renderBig() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.billingTabs.id}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.selectTab}"
      >
        <neb-tab-old id="${ELEMENTS.generalTab.id}" name="general"
          >${LABEL_GENERAL}</neb-tab-old
        >
        <neb-tab-old id="${ELEMENTS.guarantorTab.id}" name="guarantor"
          >${this.__formatTabLabel(LABEL_GUARANTOR)}</neb-tab-old
        >
        ${html`
          <neb-tab-old id="${ELEMENTS.relationshipsTab.id}" name="relationships"
            >${this.__formatTabLabel(LABEL_RELATIONSHIPS)}</neb-tab-old
          >
        `}

        <neb-tab-old
          id="${ELEMENTS.packagesSubscriptionTab.id}"
          name="packages-subscriptions"
          >${this.__formatTabLabel(LABEL_PACKAGES_SUBSCRIPTIONS)}</neb-tab-old
        >
        <neb-tab-old
          id="${ELEMENTS.recurringScheduledTab.id}"
          name="recurring-scheduled"
          >${this.__formatTabLabel(LABEL_RECURRING_SCHEDULED)}</neb-tab-old
        >
      </neb-tabs-old>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderBig()}${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}
window.customElements.define(
  'neb-patient-tabs-billing-controller',
  NebPatientTabsBillingController,
);
