import '../copy-encounter/neb-copy-encounter';

import { html, css } from 'lit';

import NebPopup from '../../../packages/neb-popup/src/neb-popup';

const ELEMENTS = {
  viewCopyEncounter: {
    id: 'view-copy-encounter',
  },
};

class NebPopupCopyEncounter extends NebPopup {
  initState() {
    super.initState();
    this.title = 'Copy Encounter';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      copy: () => {
        this.onClose(true);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 90%;
          height: 90%;
        }

        .container {
          display: block;
        }

        .content {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .view {
          display: flex;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-copy-encounter
        id="${ELEMENTS.viewCopyEncounter.id}"
        class="view"
        .layout="${this.layout}"
        .encounter="${this.model.encounter}"
        .onCancel="${this.handlers.cancel}"
        .onCopy="${this.handlers.copy}"
      ></neb-copy-encounter>
    `;
  }
}

customElements.define('neb-popup-copy-encounter', NebPopupCopyEncounter);
