import '../../../../../src/components/misc/neb-icon';
import '../neb-profile-photo';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../neb-styles/neb-variables';
import {
  capitalize,
  objToName,
  formatAge,
  formatPhoneNumber,
} from '../../../../neb-utils/formatters';
import { getPhotoModel, PATIENT_STATUS } from '../../../../neb-utils/patient';

export const ELEMENTS = {
  photo: {
    id: 'photo',
  },
  mrnLabel: {
    id: 'label-mrn',
  },
  sexDobLabel: {
    id: 'label-sex-dob',
  },
  phoneLabel: {
    id: 'label-phone',
  },
  patientNameLabel: {
    id: 'label-patient-name',
  },
};

const NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

class PatientCard extends LitElement {
  static get properties() {
    return {
      model: Object,
      selected: {
        reflect: true,
        type: Boolean,
      },
      hidePhoto: {
        reflect: true,
        type: Boolean,
      },
      __inactive: {
        reflect: true,
        type: Boolean,
        attribute: 'inactive',
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          cursor: pointer;
          width: 100%;
          height: 100%;
          overflow-x: hidden;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          padding: 12px;
          color: ${CSS_COLOR_GREY_1};
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        :host([selected]) .container {
          color: ${CSS_COLOR_WHITE};
        }

        .profile {
          margin-right: 12px;
          width: 60px;
          height: 60px;
          flex: 0 0 60px;

          --initials-size: 24px;
        }

        :host([selected]) .profile {
          --outline-color: ${CSS_COLOR_WHITE};
        }

        .info {
          min-width: 0;
          flex: 1 0 0;
        }

        .label {
          margin: 0;
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .label-header {
          color: ${CSS_COLOR_BLACK};
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        :host([selected]) .label-header {
          color: ${CSS_COLOR_WHITE};
        }

        :host([inactive]) .container {
          font-style: italic;
        }

        :host([inactive]) .label-header {
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {
      id: '',
      active: false,
      medicalRecordNumber: '',
      sex: '',
      photoSrc: '',
      dateOfBirth: new Date(),
      phoneNumbers: [],
      name: {
        first: '',
        last: '',
        middle: '',
      },
    };

    this.selected = false;

    this.onDisplay = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      display: () => this.onDisplay(this.model),
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__inactive = this.model.status === PATIENT_STATUS.INACTIVE;
    }

    super.update(changedProps);
  }

  getSexDob() {
    return [
      this.model.sex ? capitalize(this.model.sex) : '',
      this.model.dateOfBirth ? formatAge(this.model.dateOfBirth) : '',
    ]
      .filter(item => item)
      .join(', ');
  }

  getPhone() {
    const phone = this.model.phoneNumbers[0];
    return phone ? `Phone: ${formatPhoneNumber(phone.number)}` : '';
  }

  getStatus() {
    return this.model.status && this.model.status === PATIENT_STATUS.INACTIVE
      ? `(${PATIENT_STATUS.INACTIVE})`
      : '';
  }

  renderMrn() {
    const { medicalRecordNumber } = this.model;
    return medicalRecordNumber
      ? html`
          <p id="${ELEMENTS.mrnLabel.id}" class="label">
            MRN: ${medicalRecordNumber}
          </p>
        `
      : '';
  }

  renderPhoto() {
    return !this.hidePhoto
      ? html`
          <neb-profile-photo
            id="${ELEMENTS.photo.id}"
            class="profile"
            .model="${getPhotoModel(this.model)}"
            .onDisplay="${this.__handlers.display}"
          ></neb-profile-photo>
        `
      : '';
  }

  renderName() {
    return html`
      <p id="${ELEMENTS.patientNameLabel.id}" class="label label-header">
        ${objToName(this.model.name, NAME_OPTS)} ${this.getStatus()}
      </p>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderPhoto()}

        <div class="info">
          ${this.renderName()}

          <p id="${ELEMENTS.sexDobLabel.id}" class="label">
            ${this.getSexDob()}
          </p>

          ${this.renderMrn()}

          <p id="${ELEMENTS.phoneLabel.id}" class="label">${this.getPhone()}</p>
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-patient-card', PatientCard);
