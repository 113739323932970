import {
  ONE_DAY,
  SEVEN_DAYS,
} from '../../../../src/utils/clearinghouse-settings';
import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

// eslint-disable-next-line complexity
function mapToModel(raw) {
  return {
    id: raw.id,
    chcPassword: raw.chcPassword || '',
    chcSubmitterId: raw.chcSubmitterId || '',
    chcUserName: raw.chcUserName || '',
    realTimeEligibilityEnabled: raw.realTimeEligibilityEnabled || false,
    realTimeEligibilityAutoUpdate: raw.realTimeEligibilityAutoUpdate || false,
    realTimeEligibilityPeriod: raw.realTimeEligibilityPeriod || ONE_DAY,
    realTimeEligibilityFrequency:
      raw.realTimeEligibilityFrequency || SEVEN_DAYS,
    payerPlans: raw.payerPlans || [],
    submitterContactOverride: raw.submitterContactOverride || false,
    submitterContactName: raw.submitterContactName || '',
    submitterContactNumber: raw.submitterContactNumber || '',
    submitterContactType: raw.submitterContactType || '',
    submitterContactEmail: raw.submitterContactEmail || '',
  };
}

// eslint-disable-next-line complexity
function mapToRaw(model) {
  return {
    chcPassword: model.chcPassword || '',
    chcSubmitterId: model.chcSubmitterId || '',
    chcUserName: model.chcUserName || '',
    realTimeEligibilityEnabled: model.realTimeEligibilityEnabled || false,
    realTimeEligibilityAutoUpdate: model.realTimeEligibilityAutoUpdate || false,
    realTimeEligibilityPeriod: model.realTimeEligibilityPeriod || ONE_DAY,
    realTimeEligibilityFrequency:
      model.realTimeEligibilityFrequency || SEVEN_DAYS,
    payerPlans: model.payerPlans || [],
    submitterContactOverride: model.submitterContactOverride || false,
    submitterContactName: model.submitterContactName || null,
    submitterContactNumber: model.submitterContactNumber || null,
    submitterContactType: model.submitterContactType || null,
    submitterContactEmail: model.submitterContactEmail || null,
  };
}

export function mapPayerPlanToBYOCModel(raw) {
  return {
    id: raw.id,
    active: raw.status === 'Active',
    alias: raw.alias,
    payerName: raw.payerName,
    primaryIdentifier: raw.chcPayerId,
    secondaryIdentifier: raw.payerId,
    submissionMethodPrimary: raw.submissionMethodPrimary,
    submissionMethodSecondary: raw.submissionMethodSecondary,
  };
}

export function mapPayerPlanToCHCRaw(model) {
  return {
    id: model.id,
    chcPayerId: model.primaryIdentifier,
    payerId: model.secondaryIdentifier,
    submissionMethodPrimary: model.submissionMethodPrimary,
    submissionMethodSecondary: model.submissionMethodSecondary,
  };
}

export function mapPayerPlanToCHCRawV2(model, chcId) {
  return {
    id: model.id,
    chcPayerId: model.primaryIdentifier,
    payerId: model.secondaryIdentifier,
    chcId,
    submissionMethodPrimary: model.submissionMethodPrimary,
    submissionMethodSecondary: model.submissionMethodSecondary,
  };
}

export async function fetch(optOutLoadingIndicator = false) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: 'settings',
    version: 1,
  });

  return mapToModel(res);
}

export function update(body, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    body: JSON.stringify(mapToRaw(body)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    optOutLoadingIndicator,
    path: 'settings',
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
}
