import '../../src/features/charting/neb-page-charting';
import '../../src/components/pages/recent-records/neb-page-recent-records';

import '../neb-lit-components/src/components/dashboard/neb-dashboard';
import '../../src/components/misc/neb-icon';
import '../neb-lit-components/src/components/neb-unsupported-page';
import '../neb-lit-components/src/components/pages/neb-page-feature-flags';
import '../neb-lit-components/src/components/practice/neb-practice-ledger-page';
import '../neb-patient/src/components/neb-patients-page';
import '../neb-www-practice/src/components/scheduling/neb-scheduling';
import './neb-nav-primary-lit';
import './neb-nav-secondary-lit';
import './neb-no-access-page';

import { matchRouteSwitch, getParams, navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { toggleChartingDirty } from '../../src/store/reducers/charting';
import { OVERLAY_STACK_KEY } from '../neb-lit-components/src/utils/overlay-constants';
import { RENDERER_OVERLAYS } from '../neb-lit-components/src/utils/overlay-renderers';
import { openDirtyPopup } from '../neb-popup';
import { store } from '../neb-redux/neb-redux-store';
import { BlacklistService } from '../neb-redux/services/blacklist';
import { LAYOUT_TYPE } from '../neb-redux/services/layout';
import {
  CSS_COLOR_GREY_4,
  CSS_SECONDARY_NAV_HEIGHT,
} from '../neb-styles/neb-variables';
import { isProductionEnvironment } from '../neb-utils/env';
import { hasFeatureOrBeta, FEATURE_FLAGS } from '../neb-utils/feature-util';

export const ELEMENTS = {
  primaryNav: {
    id: 'nav-primary',
  },
  secondaryNav: {
    id: 'nav-secondary',
  },
  dashboardPage: {
    id: 'dashboard-page',
    tag: 'neb-dashboard',
  },
  featureFlagsPage: {
    id: 'feature-flags-page',
  },
  scheduling: {
    id: 'neb-scheduling',
  },
  schedulingPatientFlow: {
    id: 'neb-scheduling-patient-flow',
  },
  schedulingRooms: {
    id: 'neb-scheduling-rooms',
  },
  patientsPage: {
    id: 'neb-patient-page',
  },
  chartingPage: {
    id: 'neb-charting-page',
  },
  popupStack: {
    id: 'popup-stack',
  },
  practiceLedgerPage: {
    id: 'neb-practice-ledger-page',
  },
  practiceLedgerPageClaims: {
    id: 'neb-practice-ledger-page-claims',
  },
  recentRecordsPage: {
    id: 'neb-recent-records-page',
  },
  noAccessPage: {
    id: 'neb-no-access-page',
  },
  chartingUnsupportedPage: {
    id: 'neb-charting-unsupported-page',
  },
};

const BLACKLISTED_ROUTES = ['/charting/:id', '/patients'];

export const APP_PAGE = Object.freeze({
  DASHBOARD: { name: 'dashboard', path: '/dashboard/', level: 1 },
  SCHEDULING: { name: 'scheduling', path: '/scheduling/', level: 1 },
  SCHEDULING_PAGE_FLOW: {
    name: 'schedulingPageFlow',
    path: '/scheduling/patient-flow',
    level: 2,
  },
  SCHEDULING_ROOMS: {
    name: 'schedulingRooms',
    path: '/scheduling/rooms',
    level: 2,
  },
  PATIENTS: { name: 'patients', path: '/patients/', level: 1 },
  CHARTING: { name: 'charting', path: '/charting/', level: 1 },
  PRACTICE_LEDGER: {
    name: 'practice-ledger',
    path: '/practice-ledger/',
    level: 1,
  },
  SETTINGS: { name: 'settings', path: '/settings/', level: 1 },
  PRACTICE_LEDGER_CLAIMS: {
    name: 'practice-ledger-claims',
    path: '/practice-ledger/claims/',
    level: 2,
  },
  PRACTICE_LEDGER_ERA_EOB_MANAGEMENT: {
    name: 'practice-ledger-era-eob-management',
    path: '/practice-ledger/era-eob-management',
    level: 2,
  },
  RECENT_RECORDS: {
    name: 'recent-records',
    path: '/recent-records/',
    level: 1,
  },
});

class NebAppLayout extends LitElement {
  static get properties() {
    return {
      __hideBottomSettings: {
        type: Boolean,
      },
      __navItems: Array,
      __showSecondaryNav: Boolean,

      user: {
        type: Object,
      },
      appointmentTypes: { type: Array },
      currentOverlayKey: { type: String },
      layout: {
        type: String,
        reflect: true,
      },
      page: {
        type: String,
      },
      patients: {
        type: Array,
      },
      popupCollapsed: {
        type: Boolean,
        reflect: true,
      },
      route: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__hideBottomSettings = true;
    this.__navItems = this.genNavItems();
    this.__showSecondaryNav = true;
    this.__hasChartingFeature = false;
    this.__hasRecentRecordsFeature = false;
    this.__hasAdvancedPracticeMenuFeature = false;
    this.user = {};

    this.appointmentTypes = [];
    this.currentOverlayKey = '';
    this.layout = '';
    this.page = APP_PAGE.PATIENTS.name;
    this.popupCollapsed = false;
    this.route = '';

    this.__blacklistService = new BlacklistService(
      BLACKLISTED_ROUTES,
      isBlacklisted => {
        this.__showSecondaryNav = !isBlacklisted;
      },
    );

    this.onNavigateManually = () => {};

    this.onUserMenuOptions = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      navigateManually: itemName => this.__navigateManually(itemName),
      userMenuOptions: (label, options) =>
        this.onUserMenuOptions(label, options),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__blacklistService.connect();

    this.__hasRecentRecordsFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.RECENT_RECORDS,
    );

    this.__hasAdvancedPracticeMenuFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.ADVANCED_PRACTICE_MENU,
    );

    this.__navItems = this.genNavItems();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__blacklistService.disconnect();
  }

  genNavItems() {
    if (this.__hasAdvancedPracticeMenuFeature) {
      return this.__genNavItemsNew();
    }
    return [
      {
        label: 'Analytics',
        icon: 'neb:analytics',
        name: 'dashboard',
        permission: 'dashboard',
        hash: '#/dashboard/',
        manualNav: false,
        exact: false,
        path: '/dashboard/',
        resolver: () => html`
          <neb-dashboard
            id="${ELEMENTS.dashboardPage.id}"
            class="page page-dashboard"
            name="dashboard"
          ></neb-dashboard>
        `,
      },
      {
        label: 'Scheduling',
        icon: 'neb:calendar',
        name: 'scheduling',
        permission: 'scheduling',
        hash: '#/scheduling/',
        manualNav: false,
        exact: false,
        path: '/scheduling/',
        resolver: tail => html`
          <neb-scheduling
            id="${ELEMENTS.scheduling.id}"
            class="page page-scheduling"
            name="scheduling"
            .layout="${this.__layout}"
            .route="${tail}"
          ></neb-scheduling>
        `,
      },
      {
        label: 'Patients',
        icon: 'neb:patients',
        name: 'patients',
        permission: 'patients',
        hash: '#/patients/',
        manualNav: true,
        exact: false,
        path: '/patients/',
        resolver: tail => html`
          <neb-patients-page
            id="${ELEMENTS.patientsPage.id}"
            class="page page-patients"
            name="patients"
            .layout="${this.__layout}"
            .route="${tail}"
            .onChange="${this.__handlers.updatePatient}"
            .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          ></neb-patients-page>
        `,
      },
      {
        label: 'Charting',
        icon: 'neb:charting',
        name: 'charting',
        permission: 'charting',
        hash: '#/charting/',
        manualNav: true,
        exact: false,
        path: '/charting/',
        resolver: tail => html`
          <neb-page-charting
            id="${ELEMENTS.chartingPage.id}"
            class="page page-charting"
            name="charting"
            .appointmentTypes="${this.appointmentTypes}"
            .layout="${this.__layout}"
            .route="${tail}"
            .user="${this.user}"
            .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          ></neb-page-charting>
        `,
      },
      {
        label: 'Practice Ledger',
        icon: 'neb:wallet',
        name: 'practice-ledger',
        permission: 'billing',
        hash: '#/practice-ledger/',
        manualNav: false,
        exact: false,
        path: '/practice-ledger/',
        resolver: tail => html`
          <neb-practice-ledger-page
            id="${ELEMENTS.practiceLedgerPage.id}"
            class="page page-practice-ledger"
            name="practice-ledger"
            .layout="${this.__layout}"
            .route="${tail}"
          ></neb-practice-ledger-page>
        `,
      },
      ...(this.__hasRecentRecordsFeature
        ? [
            {
              label: 'Recent Records',
              icon: 'neb:clock',
              name: 'recent-records',
              permission: 'patients',
              hash: '#/recent-records/',
              manualNav: true,
              exact: false,
              path: '/recent-records/',
              resolver: tail => html`
                <neb-page-recent-records
                  id="${ELEMENTS.recentRecordsPage.id}"
                  class="page page-recent-records"
                  name="recent-records"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-page-recent-records>
              `,
            },
          ]
        : []),
    ];
  }

  __genNavItemsNew() {
    return [
      {
        label: 'Analytics',
        icon: 'neb:analytics',
        name: APP_PAGE.DASHBOARD.name,
        permission: 'dashboard',
        hash: `#${APP_PAGE.DASHBOARD.path}`,
        manualNav: false,
        exact: false,
        path: APP_PAGE.DASHBOARD.path,
        resolver: () => html`
          <neb-dashboard
            id="${ELEMENTS.dashboardPage.id}"
            class="page page-dashboard"
            name="${APP_PAGE.DASHBOARD}"
          ></neb-dashboard>
        `,
      },
      {
        label: 'Scheduling',
        icon: 'neb:calendar',
        name: APP_PAGE.SCHEDULING.name,
        permission: 'scheduling',
        hash: `#${APP_PAGE.SCHEDULING.path}`,
        manualNav: false,
        exact: false,
        path: APP_PAGE.SCHEDULING.path,
        resolver: tail => html`
          <neb-scheduling
            id="${ELEMENTS.scheduling.id}"
            class="page page-scheduling"
            name="${APP_PAGE.SCHEDULING.name}"
            .layout="${this.__layout}"
            .route="${tail}"
          ></neb-scheduling>
        `,
      },

      ...(this.layout === LAYOUT_TYPE.LARGE
        ? [
            {
              label: 'Patient Flow',
              icon: 'neb:patientFlow',
              name: APP_PAGE.SCHEDULING_PAGE_FLOW.name,
              permission: 'scheduling',
              hash: `#${APP_PAGE.SCHEDULING_PAGE_FLOW.path}`,
              manualNav: false,
              exact: true,
              path: APP_PAGE.SCHEDULING_PAGE_FLOW.path,
              resolver: tail => html`
                <neb-scheduling
                  id="${ELEMENTS.schedulingPatientFlow.id}"
                  class="page page-scheduling-patient-flow"
                  name="${APP_PAGE.SCHEDULING_PAGE_FLOW.name}"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-scheduling>
              `,
            },
            {
              label: 'Rooms',
              icon: 'neb:rooms',
              name: APP_PAGE.SCHEDULING_ROOMS.name,
              permission: 'scheduling',
              hash: `#${APP_PAGE.SCHEDULING_ROOMS.path}`,
              manualNav: false,
              exact: true,
              path: APP_PAGE.SCHEDULING_ROOMS.path,
              resolver: tail => html`
                <neb-scheduling
                  id="${ELEMENTS.schedulingRooms.id}"
                  class="page page-scheduling-rooms"
                  name="${APP_PAGE.SCHEDULING_ROOMS.name}"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-scheduling>
              `,
            },
          ]
        : []),

      {
        label: 'Patients',
        icon: 'neb:patients',
        name: APP_PAGE.PATIENTS.name,
        permission: 'patients',
        hash: `#${APP_PAGE.PATIENTS.path}`,
        manualNav: true,
        exact: false,
        path: APP_PAGE.PATIENTS.path,
        resolver: tail => html`
          <neb-patients-page
            id="${ELEMENTS.patientsPage.id}"
            class="page page-patients"
            name="${APP_PAGE.PATIENTS.name}"
            .layout="${this.__layout}"
            .route="${tail}"
            .onChange="${this.__handlers.updatePatient}"
            .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          ></neb-patients-page>
        `,
      },
      {
        label: 'Charting',
        icon: 'neb:charting',
        name: APP_PAGE.CHARTING.name,
        permission: 'charting',
        hash: `#${APP_PAGE.CHARTING.path}`,
        manualNav: true,
        exact: false,
        path: APP_PAGE.CHARTING.path,
        resolver: tail => html`
          <neb-page-charting
            id="${ELEMENTS.chartingPage.id}"
            class="page page-charting"
            name="${APP_PAGE.CHARTING.name}"
            .appointmentTypes="${this.appointmentTypes}"
            .layout="${this.__layout}"
            .route="${tail}"
            .user="${this.user}"
            .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          ></neb-page-charting>
        `,
      },
      {
        label: 'Practice Ledger',
        icon: 'neb:wallet',
        name: APP_PAGE.PRACTICE_LEDGER.name,
        permission: 'billing',
        hash: `#${APP_PAGE.PRACTICE_LEDGER.path}`,
        manualNav: false,
        exact: false,
        path: APP_PAGE.PRACTICE_LEDGER.path,
        resolver: tail => html`
          <neb-practice-ledger-page
            id="${ELEMENTS.practiceLedgerPage.id}"
            class="page page-practice-ledger"
            name="${APP_PAGE.PRACTICE_LEDGER.name}"
            .layout="${this.__layout}"
            .route="${tail}"
          ></neb-practice-ledger-page>
        `,
      },

      ...(this.layout === LAYOUT_TYPE.LARGE
        ? [
            {
              label: 'Claims',
              icon: 'neb:claims',
              name: APP_PAGE.PRACTICE_LEDGER_CLAIMS.name,
              permission: 'billing',
              hash: `#${APP_PAGE.PRACTICE_LEDGER_CLAIMS.path}`,
              manualNav: false,
              exact: true,
              path: APP_PAGE.PRACTICE_LEDGER_CLAIMS.path,
              resolver: tail => html`
                <neb-practice-ledger-page
                  id="${ELEMENTS.practiceLedgerPageClaims.id}"
                  class="page page-practice-ledger-claims"
                  name="${APP_PAGE.PRACTICE_LEDGER_CLAIMS.name}"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-practice-ledger-page>
              `,
            },
            {
              label: 'ERA/EOB',
              icon: 'neb:eraEob',
              name: APP_PAGE.PRACTICE_LEDGER_ERA_EOB_MANAGEMENT.name,
              permission: 'billing',
              hash: `#${APP_PAGE.PRACTICE_LEDGER_ERA_EOB_MANAGEMENT.path}`,
              manualNav: false,
              exact: false,
              path: APP_PAGE.PRACTICE_LEDGER_ERA_EOB_MANAGEMENT.path,
              resolver: tail => html`
                <neb-practice-ledger-page
                  id="${ELEMENTS.practiceLedgerPageClaims.id}"
                  class="page page-practice-ledger-era-eob-management"
                  name="${APP_PAGE.PRACTICE_LEDGER_ERA_EOB_MANAGEMENT.name}"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-practice-ledger-page>
              `,
            },
          ]
        : []),

      ...(this.__hasRecentRecordsFeature
        ? [
            {
              label: 'Recent Records',
              icon: 'neb:clock',
              name: APP_PAGE.RECENT_RECORDS.name,
              permission: 'patients',
              hash: `#${APP_PAGE.RECENT_RECORDS.path}`,
              manualNav: true,
              exact: false,
              path: APP_PAGE.RECENT_RECORDS.path,
              resolver: tail => html`
                <neb-page-recent-records
                  id="${ELEMENTS.recentRecordsPage.id}"
                  class="page page-recent-records"
                  name="${APP_PAGE.RECENT_RECORDS.name}"
                  .layout="${this.__layout}"
                  .route="${tail}"
                ></neb-page-recent-records>
              `,
            },
          ]
        : []),
    ];
  }

  __getDisplayedNavItems() {
    if (this.user) {
      const { superUser, permissions } = this.user;
      const isProd = isProductionEnvironment();

      if (superUser && !isProd) {
        this.__hideBottomSettings = false;
        return [
          ...this.__navItems,
          {
            name: 'feature-flags',
            permission: 'superUser',
            exact: false,
            path: '/feature-flags/',
            resolver: () => html`
              <neb-page-feature-flags
                id="${ELEMENTS.featureFlagsPage.id}"
                class="page page-feature-flags"
                name="feature-flags"
                .layout="${this.layout}"
              ></neb-page-feature-flags>
            `,
          },
        ];
      }

      let items = [];

      items = [
        ...this.__navItems.filter(n =>
          permissions.some(p => p.access && p.name === n.permission),
        ),
      ];

      const settingsPermission = permissions.find(p => p.name === 'settings');
      this.__hideBottomSettings = !(
        settingsPermission && settingsPermission.access
      );

      return items;
    }

    return [];
  }

  async __navigateManually(name) {
    const { id } = getParams('/:subApp/:id');

    switch (name) {
      case 'patients': {
        if (id) {
          const patientsPage = this.shadowRoot.getElementById(
            ELEMENTS.patientsPage.id,
          );

          if (this.currentOverlayKey !== 'patient-list') {
            patientsPage.openPatientOverlay(id);
          }
        }

        break;
      }

      case 'charting': {
        if (id) {
          const chartingPage = this.shadowRoot.getElementById(
            ELEMENTS.chartingPage.id,
          );

          if (this.currentOverlayKey !== 'encounter-list') {
            const { charting } = store.getState();

            if (!charting.dirty) {
              return chartingPage.openEncounterList();
            }

            if (await openDirtyPopup()) {
              store.dispatch(toggleChartingDirty(false));

              return chartingPage.openEncounterList();
            }
          }
        }

        break;
      }

      default:
        break;
    }

    return undefined;
  }

  static get styles() {
    return css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
        background-color: ${CSS_COLOR_GREY_4};
      }

      :host([popupCollapsed]) {
        height: unset;
      }

      .container {
        display: flex;
        width: 100%;
        height: 100%;
        transition:
          margin-left 200ms,
          margin-right 200ms;
        overflow: hidden;
      }

      /* TODO: position and overflow-x properties should be revisited when overlay stories get reworked. Those properties can be removed after overlay refactor. */
      :host([layout='small']) .container {
        position: relative;
        flex-direction: column;
        overflow: hidden visible;
      }

      :host([layout='small']) .primary-nav {
        flex: 0 0 75px;
      }

      .container-content {
        position: relative;
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      :host([layout='small']) .container-content {
        overflow: visible;
      }

      :host([popupCollapsed]) .container-content {
        flex: 0;
      }

      .content {
        position: relative;
        flex: 1 0 0;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      :host([layout='small']) .content {
        overflow-y: auto;
      }

      .page {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .stack-overlay {
        height: auto;
        top: ${CSS_SECONDARY_NAV_HEIGHT};
        bottom: 0;
        z-index: 109;
      }

      .stack-overlay[visible] {
        left: 0;
        right: 0;
        width: auto;
        z-index: 30;
      }

      .no-access-page {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 35%;
      }
    `;
  }

  __renderNoAccessPage() {
    const doesPageExist = Object.values(APP_PAGE).some(x =>
      x.name.includes(this.page),
    );

    if (doesPageExist) {
      navigate('#/no-access');
    }

    return html`
      <neb-no-access-page
        id="${ELEMENTS.noAccessPage.id}"
        class="no-access-page"
        .layout="${this.layout}"
        .pageExists="${doesPageExist || this.page === 'no-access'}"
      >
      </neb-no-access-page>
    `;
  }

  __renderUnsupportedPage() {
    if (
      this.layout === LAYOUT_TYPE.SMALL &&
      this.page === APP_PAGE.CHARTING.name
    ) {
      return html`
        <neb-unsupported-page
          id="${ELEMENTS.chartingUnsupportedPage.id}"
          icon="charting"
          location="Charting"
        ></neb-unsupported-page>
      `;
    }

    return '';
  }

  render() {
    const pageNavItems = this.__getDisplayedNavItems();

    let navItems =
      this.layout === 'small'
        ? pageNavItems.filter(({ name }) => !name.includes('charting'))
        : pageNavItems;

    navItems = navItems.filter(({ name }) => name !== 'feature-flags');

    const page = matchRouteSwitch(pageNavItems, this.route);

    return html`
      <div class="container">
        <neb-nav-primary-lit
          id="${ELEMENTS.primaryNav.id}"
          class="primary-nav"
          .hideBottomSettings="${this.__hideBottomSettings}"
          .layout="${this.layout}"
          .navItems="${navItems}"
          .selectedItem="${this.page}"
          .user="${this.user}"
          .onNavigateManually="${this.__handlers.navigateManually}"
          .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          .expanded="${this.layout === 'large'}"
        >
        </neb-nav-primary-lit>

        <div class="container-content">
          ${this.__showSecondaryNav
            ? html`
                <neb-nav-secondary-lit
                  id="${ELEMENTS.secondaryNav.id}"
                  .layout="${this.layout}"
                  .user="${this.user}"
                  .onUserMenuOptions="${this.__handlers.userMenuOptions}"
                ></neb-nav-secondary-lit>
              `
            : ''}

          <div class="content">
            ${page || this.__renderNoAccessPage(page)}
            ${this.__renderUnsupportedPage()}
          </div>

          <zen-popup-stack
            id="${ELEMENTS.popupStack.id}"
            class="stack-overlay"
            .key="${OVERLAY_STACK_KEY}"
            .layout="${this.layout}"
            .renderers="${RENDERER_OVERLAYS}"
          ></zen-popup-stack>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-app-layout', NebAppLayout);
