import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import { getPaymentDetail } from '../../../../packages/neb-api-client/src/payments-api-client';
import Form, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { AllocationChargesTable2 } from '../../../../packages/neb-lit-components/src/components/tables/neb-table-allocation-charges-2';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyPopup } from '../../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import {
  CODE_PAYMENTS,
  CODE_WRITE_OFFS,
} from '../../../../packages/neb-utils/constants';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  centsToCurrencyWithNegative,
  currencyToCents,
  currencyToCentsWithNegative,
} from '../../../../packages/neb-utils/formatters';
import {
  BILL_TYPE,
  checkForWarnings,
  selectedChargesHasErrors,
  validateAllowedAgainstBilled,
  viewPayment,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { mapToPatientModel } from '../../../../packages/neb-utils/patient';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { map } from '../../../../packages/neb-utils/utils';
import {
  buildDebitAllocationAmounts,
  calculateBalancedAdjustmentAmount,
  calculateBalancedAllowedAmount,
  calculateDebitsOwed,
  calculateDebitsPaid,
  getPatientDebits,
  getPrimaryPayerDebits,
  getSecondaryPayerDebits,
  hasOnlyOnePaidPkgDebit,
  isPatientRespDebit,
  isPayerRespDebit,
  mapAdjustmentsForEditPopup,
  mapDebitAmountsToCents,
  mapDebitsForEditPopup,
  mapPatientInsurancesForEditPopup,
  owedGrandTotalValidatorV2,
  rowHasPackageCreditsOrAdjustments,
  validateAllowedAgainstTotalOwedV2,
  validateNonPrimaryAllocatedAmountAgainstAvailableAmount,
  validateNonPrimaryAllocatedAmountAgainstAvailableOwedAmount,
  validateNonPrimaryAllocatedAmountAgainstOwedAmount,
  validateNonPrimaryOwedAmountAgainstAllocatedAmount,
  validatePatientAllocatedAmountAgainstAvailableAmount,
  validatePatientAllocatedAmountAgainstAvailableOwedAmount,
  validatePatientAllocatedAmountAgainstOwedAmount,
  validatePatientOwedAmountAgainstAllocatedAmount,
  validatePrimaryAllocatedAmountAgainstAvailableAmount,
  validatePrimaryAllocatedAmountAgainstAvailableOwedAmount,
  validatePrimaryAllocatedAmountAgainstOwedAmount,
  validatePrimaryOwedAmountAgainstAllocatedAmount,
} from '../../../utils/allocate-charges/neb-allocate-charge-util';
import { calculateAdjustmentsSum } from '../../../utils/ledger-form/neb-ledger-form-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  tableAllocate: { id: 'allocate-table' },
};

const FOCUS = 'focus';

class NebFormManualPost extends Form {
  static get properties() {
    return {
      __selectIndexes: Array,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,

      payer: Object,
      menuItems: Object,
      paymentDetail: Object,
    };
  }

  static get styles() {
    return [
      ...super.styles,
      css`
        .content {
          flex: auto;
        }
        .content-form {
          display: flex;
          min-height: 0;
          position: relative;
        }
        .action-bar {
          width: 100%;
          z-index: 3;
          position: fixed;
          bottom: 0%;
        }
        .allocation-table {
          width: 100%;
        }
      `,
    ];
  }

  static createModel() {
    return [];
  }

  initState() {
    super.initState();

    this.__selectIndexes = [];
    this.payer = {};
    this.menuItems = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.paymentDetail = {};
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.onItemFiltersChange = () => {};

    this.onRefetch = async () => {};

    this.onUpdate = async () => {};

    this.onManualPost = async () => {};
  }

  createSelectors() {
    return {
      children: {
        $: {
          children: {
            allowedAmount: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                validateAllowedAgainstBilled(
                  'The total Owed amounts must equal the Allowed amount',
                ),
              ],
            }),
            patientAdjustment: selectors.currencyWithNegative({
              validateManually: true,
              validateRaw: true,
              validators: [
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
              ],
            }),
            patientOwed: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                validateAllowedAgainstTotalOwedV2(
                  'The total Owed amounts must be equal to or less than the Allowed amount.',
                ),
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
                {
                  error:
                    'The allocated amount may not exceed the patient’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePatientOwedAmountAgainstAllocatedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            primaryOwed: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                owedGrandTotalValidatorV2(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                ),
                {
                  error:
                    'The allocated amount may not exceed the payer’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryOwedAmountAgainstAllocatedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            patientAllocated: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                {
                  error:
                    'The allocated amount may not exceed the owed or available amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstAvailableOwedAmount(
                      idx[0],
                      this.paymentDetail.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the patient’s available amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstAvailableAmount(
                      idx[0],
                      this.paymentDetail.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the patient’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePatientAllocatedAmountAgainstOwedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            primaryAllocated: selectors.currency({
              validateManually: true,
              validateRaw: true,
              validators: [
                {
                  error:
                    'The allocated amount may not exceed the owed or available amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstAvailableOwedAmount(
                      idx[0],
                      this.paymentDetail.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the payer’s available amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstAvailableAmount(
                      idx[0],
                      this.paymentDetail.available,
                      this.model,
                      state,
                    ),
                },
                {
                  error:
                    'The allocated amount may not exceed the payer’s owed amount.',
                  validate: (_, idx, state) =>
                    validatePrimaryAllocatedAmountAgainstOwedAmount(
                      idx[0],
                      this.model,
                      state,
                    ),
                },
              ],
            }),
            patientPaid: selectors.currency({
              unsafe: true,
              ignorePristine: true,
            }),
            primaryPaid: selectors.currency({
              unsafe: true,
              ignorePristine: true,
            }),
            nonPrimaryPayerDebits: {
              createItem: () => ({
                patientInsuranceId: '',
                payerId: '',
                secondaryAllocated: 0,
                secondaryOwed: 0,
                secondaryPaid: 0,
                secondaryPayer: { alias: '' },
              }),
              children: {
                $: {
                  children: {
                    secondaryAllocated: {
                      validators: [
                        {
                          error:
                            'The allocated amount may not exceed the owed or available amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstAvailableOwedAmount(
                              idx[0],
                              idx[2],
                              this.paymentDetail,
                              this.model,
                              state,
                            ),
                        },
                        {
                          error:
                            'The allocated amount may not exceed the payer’s available amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstAvailableAmount(
                              idx[0],
                              idx[2],
                              this.paymentDetail,
                              this.model,
                              state,
                            ),
                        },
                        {
                          error:
                            'The allocated amount may not exceed the payer’s owed amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryAllocatedAmountAgainstOwedAmount(
                              idx[0],
                              idx[2],
                              this.model,
                              state,
                            ),
                        },
                      ],
                    },
                    secondaryOwed: {
                      validators: [
                        owedGrandTotalValidatorV2(
                          "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                        ),
                        {
                          error:
                            'The allocated amount may not exceed the payer’s owed amount.',
                          validate: (_, idx, state) =>
                            validateNonPrimaryOwedAmountAgainstAllocatedAmount(
                              idx[0],
                              idx[2],
                              this.model,
                              state,
                            ),
                        },
                      ],
                    },
                  },
                },
              },
            },
            adjustments: AllocationChargesTable2.createAdjustmentsSelectors(),
            debits: AllocationChargesTable2.createDebitSelectors(),
            patient: { unsafe: true, clipPristine: true },
            locations: selectors.multiSelect(this.locations, []),
          },
        },
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      checkItem: (index, checked) => {
        this.__selectIndexes.splice(index, 1, !checked);
        this.__selectIndexes = [...this.__selectIndexes];

        if (checked) this.__revertRow(index);
      },
      changeAllowed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);
        this.formService.apply(`${rowIndex}.allowedAmount`, value);

        const { debits } = getPatientDebits(row);

        if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
          const newAdjustmentAmount = calculateBalancedAdjustmentAmount(
            row.billedAmount,
            row.taxAmount,
            currencyToCentsWithNegative(value),
          );

          this.__updateAdjustmentAmount(row, rowIndex, newAdjustmentAmount);
        }

        this.formService.validate();
      },
      openPatientOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits } = getPatientDebits(row);

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Patient Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.menuItems.paymentTypes,
            billType: row.billType,
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.tableAllocate.id)
          .focusPatientOwed(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'patientOwed', result.itemsToAdd);
          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({ rowIndex, type: 'patient' });
          this.__clearAllocatedIfDebitsRemoved({ rowIndex, type: 'patient' });
          this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

          this.formService.validate();
        }
      },
      openSecondaryOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const { patientInsuranceId: nonPrimaryPatientInsuranceId, payerId } =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        const currentInsurance = this.menuItems.insurances.find(
          ins => ins.data.id === nonPrimaryPatientInsuranceId,
        );

        const { debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.menuItems.paymentTypes,
            plans: mapPatientInsurancesForEditPopup([currentInsurance]),
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.tableAllocate.id)
          .focusSecondaryOwed(rowIndex, nonPrimaryPayerDebitIdx);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          const itemsToAdd = result.itemsToAdd.map(item => ({
            ...item,
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
          }));

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            itemsToAdd,
            nonPrimaryPatientInsuranceId,
          );

          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          if (this.__debitsChanged(result, debits)) {
            this.__syncAllocated({
              rowIndex,
              field: 'secondaryAllocated',
              nonPrimaryPatientInsuranceId,
              nonPrimaryPayerDebitIdx,
            });
          }
          this.formService.validate();
        }
      },
      // eslint-disable-next-line complexity
      changePatientOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state[rowIndex].patientOwed) return;

        let { debits, debitIndexes } = getPatientDebits(row);

        if (debits.length === 0 && debitIndexes.length === 0) {
          this.formService.addItem(`${rowIndex}.debits`);

          ({ debits, debitIndexes } = getPatientDebits(row));
        }

        if (
          debits.length === 1 &&
          debitIndexes.length === 1 &&
          this.state[rowIndex].debits[debitIndexes].codePaymentId === ''
        ) {
          this.formService.apply(
            `${rowIndex}.debits.${debitIndexes}.codePaymentId`,
            CODE_PAYMENTS.GENERAL_PAYMENT.id,
          );
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`${rowIndex}.patientOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePatientAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state[rowIndex].patientAllocated) return;

        this.formService.apply(`${rowIndex}.patientAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePrimaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits, debitIndexes } = getPrimaryPayerDebits(row);

        if (debits.length !== 1 && debitIndexes.length !== 1) return;

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`${rowIndex}.primaryOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      // eslint-disable-next-line complexity
      changeSecondaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        const { debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        if (
          debits.length > 1 ||
          (currencyToCents(value) === 0 && debits.length === 0)
        ) {
          return;
        }

        if (currencyToCents(value) > 0 && debits.length === 0) {
          const { payerId } =
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

          this.__addIndividualItems(rowIndex, 'secondaryOwed', [
            {
              amount: currencyToCents(value),
              codeId: CODE_PAYMENTS['PR-45'].id,
              individualRowId: '',
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            },
          ]);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx,
          });

          this.formService.validate();
          return;
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(
          `${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryOwed`,
          currencyToCents(value),
        );

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      addSecondaryPayer: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const usedSecondaryInsuranceIds = row.nonPrimaryPayerDebits.map(
          debit => debit.patientInsuranceId,
        );
        const insuranceOptions = this.menuItems.insurances.filter(
          insurance =>
            !usedSecondaryInsuranceIds.includes(insurance.data.id) &&
            insurance.data.id !== row.primaryInsuranceId,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            plans: mapPatientInsurancesForEditPopup(insuranceOptions),
            enablePlansDropdown: true,
            initialCodes: [],
            displayPaidAmount: true,
            paymentCodes: this.menuItems.paymentTypes,
          },
        );

        if (result) {
          const {
            patientInsurance: {
              id: nonPrimaryPatientInsuranceId,
              payer: { id: payerId, alias },
            },
          } = result;

          const indexToAdd = this.__addNonPrimaryPayerDebits(rowIndex, {
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
            alias,
          });

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            result.itemsToAdd.map(item => ({
              ...item,
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            })),
          );

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx: indexToAdd,
          });

          this.formService.validate();
        }
      },
      changePrimaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.formService.apply(`${rowIndex}.primaryAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      changeSecondaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { nonPrimaryPayerDebitIdx } =
          this.__getNonPrimaryPayerDebitIndexFromName(name);

        if (
          value ===
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
              .secondaryAllocated,
          )
        ) {
          return;
        }

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        this.formService.apply(name, currencyToCents(value));

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      openAdjustmentsPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Adjustments',
            initialCodes: mapAdjustmentsForEditPopup(row.adjustments),
            displayPaidAmount: false,
            billType: row.billType,
            codeWriteOffs: this.menuItems.adjustments.map(({ data }) => data),
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.tableAllocate.id)
          .focusAdjustment(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'adjustments', result.itemsToAdd);
          this.__editIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToEdit,
          );

          this.__updateAllowedAmountFromAdjustments(
            this.state[rowIndex],
            rowIndex,
          );

          this.formService.validate();
        }
      },
      changeAdjustments: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.__updateAdjustmentAmount(
          row,
          rowIndex,
          currencyToCentsWithNegative(value),
        );

        this.__updateAllowedAmountFromAdjustments(
          this.state[rowIndex],
          rowIndex,
        );

        this.formService.validate();
      },
      viewCharge: async ({ id, invoiceId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;

        if (!accepted) return;

        const lineItemIds = invoiceId
          ? (await getLedgerInvoiceItems(invoiceId)).data.map(li => li.id)
          : [id];

        const patient = mapToPatientModel(
          this.state.find(lineItem => lineItem.id === id).patient,
        );

        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;
        await openOverlay(overlayKey, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        this.onRefetch();
      },
      viewPaymentAllocation: async ({ paymentId }) => {
        const payment = await getPaymentDetail(paymentId);
        return viewPayment({
          payment,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onRefetch,
          onSave: this.handlers.save,
        });
      },
      selectPayer: async ({ payerPlanId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;
        if (!accepted) return;

        const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payerPlanId,
        });

        if (res || this.__dirty) this.onUpdate();
      },
      selectAllFromAllocationsTable: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => true);
      },
      deselectAllFromAllocationsTable: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => false);
        this.formService.reset();
        this.formService.validate();
      },
      save: async () => {
        this.formService.validate();

        const hasErrors = selectedChargesHasErrors(
          this.errors,
          this.__selectIndexes,
        );

        const onlyWarnings = await checkForWarnings(this.errors);

        if (!hasErrors || onlyWarnings) {
          this.__deleteZeroValueNonPrimaryPayerDebits();
          this.__deleteZeroValueAdjustments();
          this.__resetZeroValuePatientDebit();

          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          await this.onManualPost(model);
        }

        this.__saving = false;
      },
      cancel: async () => {
        if (this.__dirty) {
          const result = await openDirtyPopup();
          if (!result) return;
        }

        this.onCancel();
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model?.length) {
      this.__selectIndexes = this.model.map(_ => true);
    }

    if (changedProps.has('menuItems')) {
      const { payers = [] } = this.menuItems;
      this.__payers = payers.map(payer => payer.data);
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('model')) {
      this.formService.validate();
    }
  }

  async connectedCallback() {
    super.connectedCallback();

    const features = await getFeatures();
    this.__hasFitInvoiceOverlayPerformanceFF = features.includes(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );
  }

  __addOrRemoveRow(selectedIndex, li, propName) {
    const origLength =
      this.formService.__initialState[selectedIndex][propName].length;

    const modLength = li[propName].length;

    const diffLength = modLength - origLength;
    const action = diffLength && (diffLength > 0 ? 'removeItem' : 'addItem');

    Array(Math.abs(diffLength))
      .fill()
      .forEach(() => this.formService[action](`${selectedIndex}.${propName}`));
  }

  __revertDebit(selectedIndex, initialSelectState) {
    initialSelectState.debits.forEach((_, index) => {
      const path = `${selectedIndex}.debits.${index}.`;
      const initialDebit = initialSelectState.debits[index];

      const fields = [
        'codePaymentId',
        'patientInsuranceId',
        'payerId',
        'id',
        'allocated',
        'amount',
      ];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, initialDebit[field]),
      );
    });
  }

  __revertAdjustment(selectedIndex, initialSelectState) {
    initialSelectState.adjustments.forEach((_, index) => {
      const path = `${selectedIndex}.adjustments.${index}.`;
      const selectAdjustment = initialSelectState.adjustments[index];
      const fields = ['amount', 'codeId'];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, selectAdjustment[field]),
      );
    });
  }

  __revertLineItem(selectedIndex, initialSelectState) {
    const path = `${selectedIndex}.`;

    const fields = [
      'allowedAmount',
      'patientOwed',
      'primaryOwed',
      'patientAllocated',
      'primaryAllocated',
      'patientPaid',
      'primaryPaid',
    ];

    fields.forEach(field =>
      this.formService.apply(`${path}${field}`, initialSelectState[field]),
    );
  }

  __revertNonPrimaryPayerDebits(selectedIndex, initialSelectState) {
    let countAddedItemsToDelete =
      this.state[selectedIndex].nonPrimaryPayerDebits.length;

    while (countAddedItemsToDelete !== -1) {
      this.formService.removeItem(
        `${selectedIndex}.nonPrimaryPayerDebits`,
        countAddedItemsToDelete,
      );

      countAddedItemsToDelete -= 1;
    }

    initialSelectState.nonPrimaryPayerDebits.forEach((_, index) => {
      this.formService.addItem(`${selectedIndex}.nonPrimaryPayerDebits`, index);

      const path = `${selectedIndex}.nonPrimaryPayerDebits.${index}.`;
      const initialNonPrimaryPayerDebit =
        initialSelectState.nonPrimaryPayerDebits[index];

      const fields = [
        'patientInsuranceId',
        'payerId',
        'secondaryAllocated',
        'secondaryOwed',
        'secondaryPaid',
      ];

      fields.forEach(field => {
        this.formService.apply(
          `${path}${field}`,
          initialNonPrimaryPayerDebit[field],
        );

        this.formService.apply(
          `${path}secondaryPayer.alias`,
          initialNonPrimaryPayerDebit.secondaryPayer.alias,
        );
      });
    });
  }

  __revertRow(selectedIndex) {
    if (this.formService.isDirty) {
      const li = this.state[selectedIndex];

      this.__addOrRemoveRow(selectedIndex, li, 'debits');
      this.__addOrRemoveRow(selectedIndex, li, 'adjustments');

      const initialSelectState = this.formService.__initialState[selectedIndex];

      this.__revertDebit(selectedIndex, initialSelectState);
      this.__revertAdjustment(selectedIndex, initialSelectState);
      this.__revertLineItem(selectedIndex, initialSelectState);
      this.__revertNonPrimaryPayerDebits(selectedIndex, initialSelectState);

      this.formService.validate();
    }
  }

  __getRow(rowIndex) {
    return this.state[rowIndex];
  }

  __getRowAndRowIndexFromName(name) {
    const rowIndex = name.split('.')[0];
    const row = this.__getRow(rowIndex);

    return { row, rowIndex };
  }

  __defaultAdjustmentCodeId(row) {
    return row.billType === BILL_TYPE.INSURANCE
      ? CODE_WRITE_OFFS.NEGOTIATED_RATE.id
      : CODE_WRITE_OFFS.WRITE_OFF.id;
  }

  __updateAdjustmentAmount(row, rowIndex, amount) {
    switch (row.adjustments.length) {
      case 0:
        if (amount) {
          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: this.__defaultAdjustmentCodeId(row), amount },
          ]);
        }

        break;

      case 1:
        if (amount) {
          this.__editIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: row.adjustments[0].codeId,
              amount,
            },
          ]);
        } else {
          const previousCode = row.adjustments[0].codeId;

          this.__deleteIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: previousCode,
              amount,
            },
          ]);

          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: previousCode, amount: 0 },
          ]);
        }

        break;

      default:
        break;
    }
  }

  __deleteIndividualItems(rowIndex, type, itemsToDelete) {
    itemsToDelete.forEach(itemToDelete => {
      const row = this.__getRow(rowIndex);
      const indexToDelete = row[type].findIndex(
        individualType => individualType.id === itemToDelete.individualRowId,
      );

      this.formService.removeItem(`${rowIndex}.${type}`, indexToDelete);
    });
  }

  __debitWithNoIdIndex(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let index;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        index = row.debits.findIndex(
          debit => isPatientRespDebit(debit) && !debit.id,
        );

        break;

      case 'secondaryOwed':
        index = row.debits.findIndex(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        );

        break;

      default:
        break;
    }

    return index;
  }

  __adjustmentWithNoIdIndex(rowIndex) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(adj => !adj.id);
  }

  __debitWithNoIdLength(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let length;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        length = row.debits.filter(
          debit => isPatientRespDebit(debit) && !debit.id,
        ).length;

        break;

      case 'secondaryOwed':
        length = row.debits.filter(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        ).length;

        break;

      default:
        break;
    }

    return length;
  }

  __deleteItemsBeforeAdding(rowIndex, type, nonPrimaryPatientInsuranceId) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';
    const row = this.__getRow(rowIndex);

    let countAddedItemsToDelete = 0;

    if (isDebit) {
      countAddedItemsToDelete = this.__debitWithNoIdLength(
        rowIndex,
        type,
        nonPrimaryPatientInsuranceId,
      );
    } else {
      countAddedItemsToDelete = row.adjustments.filter(adj => !adj.id).length;
    }

    while (countAddedItemsToDelete) {
      const indexToDelete = isDebit
        ? this.__debitWithNoIdIndex(
            rowIndex,
            type,
            nonPrimaryPatientInsuranceId,
          )
        : this.__adjustmentWithNoIdIndex(rowIndex);

      if (indexToDelete !== -1) {
        this.formService.removeItem(`${rowIndex}.${attribute}`, indexToDelete);
      }

      countAddedItemsToDelete -= 1;
    }
  }

  __addIndividualItems(
    rowIndex,
    type,
    itemsToAdd,
    nonPrimaryPatientInsuranceId,
  ) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';

    this.__deleteItemsBeforeAdding(
      rowIndex,
      type,
      nonPrimaryPatientInsuranceId,
    );

    const codeId = isDebit ? 'codePaymentId' : 'codeId';

    itemsToAdd.forEach(itemToAdd => {
      const row = this.__getRow(rowIndex);
      const indexToAdd = row[attribute].length;
      this.formService.addItem(`${rowIndex}.${attribute}`);

      this.formService.apply(
        `${rowIndex}.${attribute}.${indexToAdd}.${codeId}`,
        itemToAdd.codeId,
      );

      this.formService.apply(
        `${rowIndex}.${attribute}.${indexToAdd}.amount`,
        centsToCurrency(itemToAdd.amount),
      );

      if (itemToAdd.patientInsuranceId && itemToAdd.payerId) {
        this.formService.apply(
          `${rowIndex}.${attribute}.${indexToAdd}.payerId`,
          itemToAdd.payerId,
        );

        this.formService.apply(
          `${rowIndex}.${attribute}.${indexToAdd}.patientInsuranceId`,
          itemToAdd.patientInsuranceId,
        );
      }
    });
  }

  __existingDebitIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.debits.findIndex(
      debit => debit.id === itemToEdit.individualRowId,
    );
  }

  __existingAdjustmentIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(
      adj => adj.id === itemToEdit.individualRowId,
    );
  }

  __editIndividualItems(rowIndex, type, itemsToEdit) {
    const isDebit = type !== 'adjustments';

    itemsToEdit.forEach(itemToEdit => {
      const indexToEdit = isDebit
        ? this.__existingDebitIndex(rowIndex, itemToEdit)
        : this.__existingAdjustmentIndex(rowIndex, itemToEdit);

      const codeId = isDebit ? 'codePaymentId' : 'codeId';

      this.formService.apply(
        `${rowIndex}.${type}.${indexToEdit}.${codeId}`,
        itemToEdit.codeId,
      );

      this.formService.apply(
        `${rowIndex}.${type}.${indexToEdit}.amount`,
        centsToCurrencyWithNegative(itemToEdit.amount),
      );
    });
  }

  __syncOwedAndPaid({ rowIndex, type, nonPrimaryPatientInsuranceId }) {
    const row = this.__getRow(rowIndex);

    let debits;

    switch (type) {
      case 'patient':
        ({ debits } = getPatientDebits(row));
        break;
      case 'primary':
        ({ debits } = getPrimaryPayerDebits(row));
        break;
      case 'secondary':
        ({ debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
    }

    const owed = calculateDebitsOwed(debits, true);
    const paid = calculateDebitsPaid(debits, true);

    if (type === 'secondary') {
      row.nonPrimaryPayerDebits.forEach((value, index) => {
        if (value.patientInsuranceId === nonPrimaryPatientInsuranceId) {
          this.formService.apply(
            `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryOwed`,
            currencyToCents(owed),
          );

          this.formService.apply(
            `${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryPaid`,
            currencyToCents(paid),
          );
        }
      });
    } else {
      this.formService.apply(`${rowIndex}.${type}Owed`, owed);
      this.formService.apply(`${rowIndex}.${type}Paid`, paid);
    }
  }

  __clearAllocatedIfDebitsRemoved({ rowIndex, type }) {
    const row = this.__getRow(rowIndex);

    let debits;

    if (type === 'patient') {
      ({ debits } = getPatientDebits(row));
    } else {
      return;
    }

    if (debits.length === 0 || hasOnlyOnePaidPkgDebit(debits)) {
      this.formService.apply(`${rowIndex}.${type}Allocated`, '$0.00');
    }
  }

  __applyAllocatedToDebits({
    rowIndex,
    debits,
    debitIndexes,
    allocationAmount,
  }) {
    const debitAllocationAmounts = buildDebitAllocationAmounts({
      debits: mapDebitAmountsToCents(debits),
      debitIndexes,
      allocationAmount,
    });

    debitAllocationAmounts.forEach(({ debitIndex, allocated }) => {
      this.formService.apply(
        `${rowIndex}.debits.${debitIndex}.allocated`,
        centsToCurrency(allocated),
      );
    });
  }

  __syncAllocated({
    rowIndex,
    field,
    nonPrimaryPatientInsuranceId,
    nonPrimaryPayerDebitIdx,
  }) {
    const row = this.__getRow(rowIndex);

    let debits;
    let debitIndexes;

    switch (field) {
      case 'patientAllocated':
        ({ debits, debitIndexes } = getPatientDebits(row));
        break;
      case 'primaryAllocated':
        ({ debits, debitIndexes } = getPrimaryPayerDebits(row));
        break;
      case 'secondaryAllocated':
        ({ debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
        return;
    }

    const allocationAmount =
      field === 'secondaryAllocated'
        ? row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx][field]
        : currencyToCents(row[field]);

    this.__applyAllocatedToDebits({
      rowIndex,
      debits,
      debitIndexes,
      allocationAmount,
    });
  }

  __getNonPrimaryPayerDebitIndexFromName(name) {
    const nonPrimaryPayerDebitIdx = name.split('.')[2];

    return { nonPrimaryPayerDebitIdx };
  }

  __debitsChanged({ itemsToAdd, itemsToEdit, itemsToDelete }, debits) {
    if (itemsToAdd.length || itemsToDelete.length) return true;

    return !itemsToEdit.every(
      (v, idx) =>
        v.individualRowId === debits[idx].id &&
        v.codeId === debits[idx].codePaymentId &&
        v.amount === currencyToCents(debits[idx].amount),
    );
  }

  __addNonPrimaryPayerDebits(rowIndex, { patientInsuranceId, payerId, alias }) {
    const row = this.__getRow(rowIndex);
    const indexToAdd = row.nonPrimaryPayerDebits.length;

    this.formService.addItem(`${rowIndex}.nonPrimaryPayerDebits`, indexToAdd);

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.patientInsuranceId`,
      patientInsuranceId,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.payerId`,
      payerId,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryAllocated`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryOwed`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPaid`,
      0,
    );

    this.formService.apply(
      `${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPayer.alias`,
      alias,
    );

    return indexToAdd;
  }

  __updateAllowedAmountFromAdjustments(row, rowIndex) {
    const { debits } = getPatientDebits(row);

    if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
      const adjustmentsSum = calculateAdjustmentsSum(row.adjustments);
      const newAllowedAmount = calculateBalancedAllowedAmount(
        row.billedAmount,
        row.taxAmount,
        adjustmentsSum,
      );

      this.formService.apply(
        `${rowIndex}.allowedAmount`,
        centsToCurrencyWithNegative(newAllowedAmount),
      );
    }
  }

  __updateDateOfTransactionFromSelectable() {
    this.handlers.dateOfTransactionFromSelectable = date =>
      (this.itemFilters.dateOfTransactionTo === null ||
        date <= this.itemFilters.dateOfTransactionTo) &&
      date <= new Date();
  }

  __updateDateOfTransactionToSelectable() {
    this.handlers.dateOfTransactionToSelectable = date =>
      (this.itemFilters.dateOfTransactionFrom === null ||
        date >= this.itemFilters.dateOfTransactionFrom) &&
      date <= new Date();
  }

  __deleteZeroValueNonPrimaryPayerDebits() {
    // eslint-disable-next-line complexity
    this.state.forEach(({ debits, nonPrimaryPayerDebits }, rowIndex) => {
      for (
        let nonPrimaryPayerDebitIdx = nonPrimaryPayerDebits.length - 1;
        nonPrimaryPayerDebitIdx >= 0;
        nonPrimaryPayerDebitIdx -= 1
      ) {
        const {
          secondaryAllocated,
          secondaryOwed,
          secondaryPaid,
          payerId: nonPrimaryPayerId,
          patientInsuranceId: nonPrimaryPatientInsuranceId,
        } = nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        if (
          secondaryAllocated === 0 &&
          secondaryOwed === 0 &&
          secondaryPaid === 0
        ) {
          for (let debitIdx = debits.length - 1; debitIdx >= 0; debitIdx -= 1) {
            const {
              allocated,
              amount,
              payerId: debitPayerId,
              patientInsuranceId: debitPatientInsuranceId,
            } = debits[debitIdx];

            if (
              currencyToCents(allocated) === 0 &&
              currencyToCents(amount) === 0 &&
              debitPayerId === nonPrimaryPayerId &&
              debitPatientInsuranceId === nonPrimaryPatientInsuranceId
            ) {
              this.formService.removeItem(`${rowIndex}.debits`, debitIdx);
            }
          }

          this.formService.removeItem(
            `${rowIndex}.nonPrimaryPayerDebits`,
            nonPrimaryPayerDebitIdx,
          );
        }
      }
    });
  }

  __deleteZeroValueAdjustments() {
    this.state.forEach(({ adjustments }, rowIndex) => {
      if (
        adjustments.length === 1 &&
        adjustments[0].id === '' &&
        currencyToCentsWithNegative(adjustments[0].amount) === 0
      ) {
        this.__deleteIndividualItems(rowIndex, 'adjustments', [
          {
            individualRowId: adjustments[0].id,
            codeId: adjustments[0].codePaymentId,
            amount: currencyToCentsWithNegative(adjustments[0].amount),
          },
        ]);
      }
    });
  }

  __resetZeroValuePatientDebit() {
    this.state.forEach((row, rowIndex) => {
      const { debits, debitIndexes } = getPatientDebits(row);

      if (
        debits.length === 1 &&
        currencyToCents(debits[0].amount) === 0 &&
        currencyToCents(debits[0].allocated) === 0
      ) {
        this.formService.apply(
          `${rowIndex}.debits.${debitIndexes}.codePaymentId`,
          '',
        );
      }
    });
  }

  __renderTableAllocationCharges() {
    return html`
      <neb-table-allocation-charges-2
        id="${ELEMENTS.tableAllocate.id}"
        class="allocation-table"
        name="items"
        emptyMessage="${'No charges'}"
        .model="${this.state}"
        .allocatableId="${this.payer.id || null}"
        .selectIndexes="${this.__selectIndexes}"
        .menuItems="${this.menuItems}"
        .payers="${this.__payers}"
        .errors="${this.errors}"
        .hasErrors="${this.formService.hasErrors}"
        .onItemCheck="${this.handlers.checkItem}"
        .onChangeAllowed="${this.handlers.changeAllowed}"
        .onClickInvoiceLink="${this.handlers.viewCharge}"
        .onClickPaymentLink="${this.handlers.viewPaymentAllocation}"
        .onSelectPayer="${this.handlers.selectPayer}"
        .onOpenPatientOwedPopup="${this.handlers.openPatientOwedPopup}"
        .onChangePatientOwed="${this.handlers.changePatientOwed}"
        .onChangePatientAllocated="${this.handlers.changePatientAllocated}"
        .onChangePrimaryOwed="${this.handlers.changePrimaryOwed}"
        .onChangePrimaryAllocated="${this.handlers.changePrimaryAllocated}"
        .onOpenSecondaryOwedPopup="${this.handlers.openSecondaryOwedPopup}"
        .onAddSecondaryPayer="${this.handlers.addSecondaryPayer}"
        .onChangeSecondaryOwed="${this.handlers.changeSecondaryOwed}"
        .onChangeSecondaryAllocated="${this.handlers.changeSecondaryAllocated}"
        .onOpenAdjustmentsPopup="${this.handlers.openAdjustmentsPopup}"
        .onChangeAdjustments="${this.handlers.changeAdjustments}"
        .onSelectAll="${this.handlers.selectAllFromAllocationsTable}"
        .onDeselectAll="${this.handlers.deselectAllFromAllocationsTable}"
      ></neb-table-allocation-charges-2>
    `;
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        confirmLabel="${this.confirmLabel}"
        cancelLabel="${this.cancelLabel}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      <div class="content-form">${this.__renderTableAllocationCharges()}</div>
    `;
  }
}

customElements.define('neb-form-manual-post', NebFormManualPost);
