import DeprecatedApiClient from '../../packages/neb-api-client/src/utils/api-client-utils';
import { TYPE } from '../../packages/neb-utils/fee-schedule';

export const BASE_URL = 'fee-schedules';

const OPTS_V2 = { version: 2 };
const OPTS_V3 = { version: 3 };
const OPTS_V4 = { version: 4 };

function mapToChargeModel(raw) {
  const result = {
    ...raw,
    modifiers: [raw.modifier_1, raw.modifier_2, raw.modifier_3, raw.modifier_4],
  };

  delete result.modifier_1;
  delete result.modifier_2;
  delete result.modifier_3;
  delete result.modifier_4;

  return result;
}

function mapToPayerPlanModel(raw) {
  return {
    ...raw,
    providerIds: raw.providerIds || [],
  };
}

function mapToChargeRaw(model) {
  return {
    id: model.id,
    amount: model.amount,
    adjustmentAmount: model.adjustmentAmount,
  };
}

function mapToChargeRawV4(model) {
  return {
    id: model.id,
    amount: model.amount,
    adjustmentAmount: model.adjustmentAmount,
    modifier_1: model.modifiers[0],
    modifier_2: model.modifiers[1],
    modifier_3: model.modifiers[2],
    modifier_4: model.modifiers[3],
    suppressFromClaim: model.suppressFromClaim,
    nonCovered: model.nonCovered,
  };
}

function mapToModel(raw) {
  return {
    ...raw,
    referenceId: raw.referenceId || '',
    patientAdjustmentCodeId: raw.patientAdjustmentCodeId || '',
    payerAdjustmentCodeId: raw.payerAdjustmentCodeId || '',
    rate: raw.rate || 0,
    charges: raw.charges.map(item => mapToChargeModel(item)),
    payerPlans: raw.payerPlans.map(item => mapToPayerPlanModel(item)),
  };
}

function mapToFeeSchedulePayerPlanRaw(model) {
  return model.payerPlans.reduce((acc, payerPlan) => {
    if (payerPlan.allProviders) {
      acc.push({
        feeScheduleId: model.id,
        payerPlanId: payerPlan.id,
        providerId: null,
        allProviders: true,
      });

      return acc;
    }

    payerPlan.providerIds.forEach(providerId => {
      acc.push({
        feeScheduleId: model.id,
        payerPlanId: payerPlan.id,
        providerId,
        allProviders: false,
      });
    });

    return acc;
  }, []);
}

function mapToRaw(model, version = 3) {
  const usingPercent = model.type === TYPE.PERCENT;

  let feeSchedulePayerPlans = [];

  if (model.payerPlans && version === 4) {
    feeSchedulePayerPlans = mapToFeeSchedulePayerPlanRaw(model);
  }
  return {
    ...model,
    payerPlans: undefined,
    referenceId: usingPercent ? model.referenceId : null,
    rate: usingPercent ? model.rate : null,
    charges: model.charges.map(item =>
      version === 4 ? mapToChargeRawV4(item) : mapToChargeRaw(item),
    ),
    payerPlanIds: model.payerPlans.map(item => item.id),
    ...(version === 4 && { feeSchedulePayerPlans }),
  };
}

function buildRequestor(microservice) {
  const apiClient = new DeprecatedApiClient({ microservice });

  return (method, path, body = null, opts = {}) => {
    const {
      version,
      query,
      includeContentType = true,
      optOutLoadingIndicator = false,
      cacheKey,
    } = opts;

    return apiClient.makeRequest({
      path,
      method,
      version: version || undefined,
      queryParams: query || {},
      body: body ? JSON.stringify(body) : undefined,
      ...(includeContentType && {
        headers: { 'Content-Type': 'application/json' },
      }),
      optOutLoadingIndicator,
      ...(cacheKey && { cacheKey }),
    });
  };
}

const makeBillingRequest = buildRequestor('billing');

export async function fetchOne(id) {
  const path = `${BASE_URL}/${id}`;
  const item = await makeBillingRequest('GET', path, null, OPTS_V3);

  return mapToModel(item);
}

export async function fetchMany(optOutLoadingIndicator = false) {
  const items = await makeBillingRequest('GET', BASE_URL, null, {
    ...OPTS_V3,
    optOutLoadingIndicator,
  });

  return items.map(item => mapToModel(item));
}

export async function fetchManyV4(optOutLoadingIndicator = false) {
  const items = await makeBillingRequest('GET', BASE_URL, null, {
    ...OPTS_V4,
    optOutLoadingIndicator,
  });

  return items.map(item => mapToModel(item));
}

export async function add(model) {
  const body = mapToRaw(model);
  const item = await makeBillingRequest('POST', BASE_URL, body, OPTS_V3);

  return mapToModel(item);
}

export async function update(model) {
  const path = `${BASE_URL}/${model.id}`;
  const raw = mapToRaw(model);
  const item = await makeBillingRequest('PUT', path, raw, OPTS_V3);

  return mapToModel(item);
}

export async function addV4(model) {
  const body = mapToRaw(model, 4);
  const item = await makeBillingRequest('POST', BASE_URL, body, OPTS_V4);

  return mapToModel(item);
}

export async function updateV4(model) {
  const path = `${BASE_URL}/${model.id}`;
  const raw = mapToRaw(model, 4);
  const item = await makeBillingRequest('PUT', path, raw, OPTS_V4);

  return mapToModel(item);
}

export async function fetchAvailablePayerPlans(query = {}) {
  const path = `${BASE_URL}/payer-plans`;
  const opts = { ...OPTS_V2, query };
  const { data, count } = await makeBillingRequest('GET', path, null, opts);

  return { data, count };
}

export async function fetchAvailablePayerPlansV3(query = {}) {
  const path = `${BASE_URL}/payer-plans`;
  const opts = { ...OPTS_V3, query };
  const { data, count } = await makeBillingRequest('GET', path, null, opts);

  return { data, count };
}

export async function fetchDefaultForEncounter(
  patientId,
  appointmentId,
  optOutLoadingIndicator = false,
) {
  const path = `${BASE_URL}/patients/${patientId}/appointments/${appointmentId}`;
  const item = await makeBillingRequest('GET', path, null, {
    version: 1,
    optOutLoadingIndicator,
    cacheKey: path,
  });

  return Object.keys(item).length ? mapToModel(item) : null;
}
