import '../../../../../neb-material-design/src/components/neb-md-textfield';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { buildPackageTemplateSummary } from '../../../../../neb-utils/neb-package-template-util';

export const ELEMENTS = {
  nameField: {
    id: 'name-field',
  },
  summary: {
    id: 'summary',
  },
};
export const MODEL_KEYS = {
  NAME: 'name',
};

class NebPackageTemplateSummary extends LitElement {
  static get properties() {
    return {
      __errorText: String,
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__errorText = '';
    this.model = {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeName: ({ value }) =>
        this.onChange({
          key: MODEL_KEYS.NAME,
          value,
        }),
    };
  }

  updated() {
    this.__elements = {
      nameField: this.shadowRoot.getElementById(ELEMENTS.nameField.id),
    };
  }

  validate() {
    return this.__elements.nameField.validate();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .section {
          display: grid;
          margin: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .summary {
          padding-top: ${CSS_SPACING};
        }

        .summary-container {
          padding-top: 10px;
        }

        .header {
          display: flex;
          align-items: center;
          padding-bottom: ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="section">
        <neb-md-textfield
          id="${ELEMENTS.nameField.id}"
          labelText="Name"
          helperText="Required"
          errorText="${this.__errorText}"
          required
          maxLength="50"
          .value="${this.model.name}"
          .onChange="${this.__handlers.changeName}"
        ></neb-md-textfield>

        <div class="summary-container">
          <div class="header">Package Summary</div>
          <div id="${ELEMENTS.summary.id}" class="summary">
            ${buildPackageTemplateSummary(this.model)}
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-package-template-summary',
  NebPackageTemplateSummary,
);
