import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({
  microservice: 'permissions',
  useTenant: true,
});

export async function updateProviderRichTextFont(
  providerId,
  richTextFontModel,
  optOutLoadingIndicator = false,
) {
  await apiClient.makeRequest({
    method: Method.PUT,
    path: `practice-users/${providerId}/rich-text-font`,
    body: richTextFontModel,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });
}

export function getProviderRichTextFont(providerId) {
  return apiClient.makeRequest({
    path: `practice-users/${providerId}/rich-text-font`,
  });
}
