import '../../neb-popup-header';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as orgsApi from '../../../../../neb-api-client/src/organizations';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import FormOrganization from '../../forms/neb-form-organization';
import Overlay from '../neb-overlay';

export const BANNER_SUCCESS = 'Organization saved successfully';
export const BANNER_ERROR = 'An error occurred when saving the Organization';
export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
};

class NebOverlayOrganization extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
      __organizations: Array,
      __savingError: String,
    };
  }

  initState() {
    super.initState();

    this.__formModel = FormOrganization.createModel();
    this.__organizations = [];
    this.__savingError = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: model => this.__validate(model),
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__organizations = await orgsApi.getOrganizations();
  }

  async __validate(org) {
    const organizations = this.__organizations;
    const dupName = organizations.find(
      o =>
        o.name.trim().toLowerCase() === org.name.trim().toLowerCase() &&
        o.id !== org.id,
    );
    const dupTaxId =
      org.taxId &&
      organizations.find(o => o.taxId === org.taxId && o.id !== org.id);

    if (organizations && dupTaxId) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Duplicate Organization',
        message:
          'There is another organization in the system with the same Tax ID as this organization. Please update this organization’s Tax ID and save again, or find and use the existing organization.',
      });

      this.__savingError = { error: 'not saved' };
    } else if (organizations && dupName) {
      const confirm = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: 'Potential Duplicate Organization',
        message: html`
          <div>
            There is another organization in the system with the same Name as
            this organization. Please check the existing organization to ensure
            that you are not creating a duplicate organization record.
          </div>
          <br />
          <div>Do you want to continue?</div>
        `,
      });

      if (confirm) this.__save(org);
      else this.__savingError = { error: 'not saved' };
    } else {
      this.__save(org);
    }
  }

  async __save(org) {
    const reqKey = org.id ? 'updateOrganization' : 'createOrganization';

    try {
      const result = await orgsApi[reqKey](org);
      this.isDirty = false;
      this.dismiss(result);
      store.dispatch(openSuccess(BANNER_SUCCESS));
    } catch (e) {
      console.error(e);
      store.dispatch(openError(BANNER_ERROR));
    }
  }

  getInputModel() {
    return this.model.item && Object.keys(this.model.item).length
      ? this.model.item
      : FormOrganization.createModel();
  }

  getTitle() {
    return this.model.item && this.model.item.id
      ? 'Update Organization'
      : 'Add Organization';
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = this.getInputModel();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 510px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showCancelButton
        ?showBackButton="${this.model.showBackButton}"
      ></neb-popup-header>

      <neb-form-organization
        id="${ELEMENTS.form.id}"
        class="form"
        .model="${this.__formModel}"
        .savingError="${this.__savingError}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-organization>
    `;
  }
}

window.customElements.define(
  'neb-overlay-organization',
  NebOverlayOrganization,
);
