import { getNextAppointment } from '../nextAppointment-api-client';

export class RequestAppointmentService {
  constructor(callback) {
    this.callback = callback;
  }

  async fetch(encounterId) {
    const result = await getNextAppointment(encounterId);
    this.callback(result);
  }
}
