import '../controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { padArray } from '../../../../neb-utils/utils';

import IdItem from './neb-id-item';
import { ELEMENTS as BASE_ELEMENTS, List } from './neb-list';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  items: {
    selector: 'neb-id-item',
  },
};

export const CONFIRM_ID_REMOVE = html`
  <div>
    This will remove the Identifier and any corresponding associations.
    <br /><br />Are you sure that you want to proceed?
  </div>
`;

export const ADD_LABEL = 'Add Other Identifier';

export default class IdList extends List {
  static get properties() {
    return {
      errors: Array,
    };
  }

  static createModel() {
    return [IdItem.createModel()];
  }

  static createSelectors() {
    return {
      createItem: () => IdItem.createModel(),
      format: v => padArray(v, IdItem.createModel()),
      unformat: v => v.filter(item => item.number && item.type),
      children: {
        $: IdItem.createSelectors(),
      },
    };
  }

  initState() {
    super.initState();
    this.addLabel = ADD_LABEL;
    this.errors = [{ number: '', type: '' }];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      remove: async name => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_ID_REMOVE,
          title: 'Remove Identifier',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (accepted) {
          this.onRemove(this.name, Number(name));
        }
      },
    };
  }

  renderItem(item, index) {
    return html`
      <neb-id-item
        .name="${index}"
        .model="${item}"
        .errors="${this.errors[index]}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        ?showRemoveButton="${this.model.length > 1}"
      ></neb-id-item>
    `;
  }
}

window.customElements.define('neb-id-list', IdList);
