import './neb-ledger-purchase-charges-table-row';
import './neb-ledger-purchase-charges-mobile-row';
import '../../neb-table-header';
import '../../neb-action-bar';

import { LitElement, html, css } from 'lit';

import { NO_CHARGES_SELECT_ADD_CHARGES } from '../../../../../../src/utils/user-message';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  tableHeader: { id: 'table-header' },
  noChargesMessage: { id: 'no-items-message' },
  mobileRows: { selector: 'neb-ledger-purchase-charges-mobile-row' },
  tableRows: { selector: 'neb-ledger-purchase-charges-table-row' },
};

const BASE_CONFIG = [
  {
    key: 'code',
    label: 'Code',
    flex: css`0 0 66px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 68px`,
  },
  {
    key: 'units',
    label: 'Units',
    flex: css`0 0 50px`,
  },
  {
    key: 'unitCharge',
    label: 'Unit Charge',
    flex: css`0 0 90px`,
  },
  {
    key: 'adjustment',
    label: 'Adjustment',
    flex: css`0 0 88px`,
  },
  {
    key: 'adjustmentAmount',
    label: 'Amount',
    flex: css`0 0 90px`,
  },
  {
    key: 'owed',
    label: 'Owed',
    flex: css`0 0 70px`,
  },
];
export const HEADER_CONFIG = [
  ...BASE_CONFIG,
  {
    key: 'tax',
    label: 'Tax',
    flex: css`0 0 128px`,
  },
];
export const ROW_CONFIG = [
  ...BASE_CONFIG,
  {
    key: 'tax',
    label: 'Tax',
    flex: css`0 0 88px`,
  },
];
export const MOBILE_CONFIG = [
  {
    mobile: true,
    key: 'code',
    label: 'Code',
    flex: css`0 0 66px`,
  },
  {
    mobile: true,
    key: 'description',
    label: 'Description',
    flex: css`1 0 135px`,
  },
];
class NebLedgePurchaseChargesTable extends LitElement {
  static get properties() {
    return {
      __rowExpanded: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      model: Array,
      errors: Array,
      itemsMap: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__rowExpanded = false;
    this.layout = '';
    this.model = [];
    this.errors = [];
    this.itemsMap = {};

    this.onChange = () => {};

    this.onRemove = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      updateCharge: charge => this.onChange(charge),
      remove: index => this.onRemove('items', index),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        :host([layout='small']) {
          overflow-y: visible;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .mobile-row {
          --cell-spacing: 10px;
        }
      `,
    ];
  }

  __renderMobileRow(item, index) {
    return html`
      <neb-ledger-purchase-charges-mobile-row
        class="mobile-row"
        .index="${index}"
        .model="${item}"
        .error="${this.errors[index]}"
        .config="${MOBILE_CONFIG}"
        .itemsMap="${this.itemsMap}"
        .onChange="${this.__handlers.updateCharge}"
        .onRemove="${this.__handlers.remove}"
        showRemoveButton
      ></neb-ledger-purchase-charges-mobile-row>
    `;
  }

  __renderDesktopRow(item, index) {
    return html`
      <neb-ledger-purchase-charges-table-row
        .index="${index}"
        .model="${item}"
        .error="${this.errors[index]}"
        .config="${ROW_CONFIG}"
        .itemsMap="${this.itemsMap}"
        .onChange="${this.__handlers.updateCharge}"
        .onRemove="${this.__handlers.remove}"
        showRemoveButton
      ></neb-ledger-purchase-charges-table-row>
    `;
  }

  __renderRows() {
    return this.model.map((item, index) =>
      this.layout === 'small'
        ? this.__renderMobileRow(item, index)
        : this.__renderDesktopRow(item, index),
    );
  }

  __renderNoItems() {
    return html`
      <div id="${ELEMENTS.noChargesMessage.id}" class="no-items-message">
        ${NO_CHARGES_SELECT_ADD_CHARGES}
      </div>
    `;
  }

  render() {
    return html`
      <neb-table-header
        id="${ELEMENTS.tableHeader.id}"
        class="header"
        .layout="${this.layout}"
        .config="${this.layout === 'small' ? MOBILE_CONFIG : HEADER_CONFIG}"
      ></neb-table-header>

      ${this.model.length > 0 ? this.__renderRows() : this.__renderNoItems()}
    `;
  }
}
customElements.define(
  'neb-ledger-purchase-charges-table',
  NebLedgePurchaseChargesTable,
);
