/* eslint-disable no-lone-blocks */
/* eslint-disable no-await-in-loop */
import { postDocument } from '../../../../packages/neb-api-client/src/document-api-client';
import { readBlobFromDataUrl } from '../../../../packages/neb-utils/blobProcessor';
import { DOCUMENT_TYPE } from '../enums/scan-enums';
import { mapScanToPdfPageSize } from '../mappers/device-mapper';

import { base64ImageToJpeg } from './image-processor';
import { buildPdf } from './pdf-processor';

const BLOB_SIZE_10_MB = 10 * 1024 * 1024;

const getName = (name, pageNumber, pageCount) => {
  if (pageCount === 1) {
    return name;
  }
  return `${name} (Page ${pageNumber} of ${pageCount})`;
};

const getJpegDocumentPagesToUpload = async ({
  documentViewModel,
  documentModel,
}) => {
  const items = [];

  for (let i = 0; i < documentViewModel.pages.length; i += 1) {
    {
      const imageJpeg = await base64ImageToJpeg(
        documentViewModel.pages[i].image,
      );
      const blob = await readBlobFromDataUrl(imageJpeg);

      const model = {
        ...documentModel,
        name: getName(
          documentModel.name,
          i + 1,
          documentViewModel.pages.length,
        ),
        file: blob,
      };

      items.push(model);
    }
  }

  return items;
};

const getPdfDocumentPagesToUpload = async ({
  documentViewModel,
  documentModel,
  pageSizeName,
}) => {
  const items = [];

  const images = [];

  for (let i = 0; i < documentViewModel.pages.length; i += 1) {
    {
      const imageJpeg = await base64ImageToJpeg(
        documentViewModel.pages[i].image,
      );
      images.push(imageJpeg);
    }
  }

  const pageSize = mapScanToPdfPageSize(pageSizeName);

  const base64Pdf = await buildPdf(images, pageSize);
  const blob = await readBlobFromDataUrl(base64Pdf);
  const model = {
    ...documentModel,
    file: blob,
  };

  items.push(model);

  return items;
};

const verifyFileSize = (models, fileSizeMax) => {
  let result = { success: true, error: '' };

  for (let i = 0; i < models.length; i += 1) {
    {
      const model = models[i];

      if (model.file.size > fileSizeMax) {
        const consoleErrorMessage = `Cannot save document "${
          model.name
        }". The document size (${
          model.file.size
        } bytes) is greater than maximum allowed size (${fileSizeMax} bytes).`;
        console.error(consoleErrorMessage);

        const errorMessage = `The document "${
          model.name
        }" is too large and cannot be saved. Documents must be under 10 MB to save. To reduce the size, try lowering the resolution (200 or less) and/or select gray from the Color drop down. Then, scan again.`;
        result = {
          success: false,
          error: errorMessage,
          errorTitle: 'Invalid Document Size',
        };

        break;
      }
    }
  }

  return result;
};

export const uploadDocument = async ({
  documentViewModel,
  documentModel,
  patientId,
  pageSizeName,
  fileSizeMax = BLOB_SIZE_10_MB,
}) => {
  let documentPages;

  if (documentViewModel.documentType === DOCUMENT_TYPE.JPEG) {
    documentPages = await getJpegDocumentPagesToUpload({
      documentViewModel,
      documentModel,
    });
  } else {
    documentPages = await getPdfDocumentPagesToUpload({
      documentViewModel,
      documentModel,
      pageSizeName,
    });
  }

  const sizeVerificationResult = verifyFileSize(documentPages, fileSizeMax);

  if (!sizeVerificationResult.success) {
    return sizeVerificationResult;
  }

  const results = await Promise.all(
    documentPages.map(model => postDocument(model, patientId, true)),
  );

  const success = results.every(
    result => result.status === 200 && result.documentId,
  );
  return { success, error: '' };
};
