import { fiservConnect } from '../fiserv-api-client';

import { handleCardReaderError } from './handle-card-reader-error';

export const connect = async reader => {
  const { readerId } = reader;

  try {
    const { sessionKey } = await fiservConnect(readerId);
    return reader.isCanceled ? null : sessionKey;
  } catch (error) {
    return reader.isCanceled
      ? null
      : handleCardReaderError({
          error,
          message: 'Cannot connect to card reader',
          retryCallback: connect,
          retryCallbackArgs: [reader],
        });
  }
};
