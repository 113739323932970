import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../neb-styles/neb-variables';

class NebAllPatientsCard extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  static createModel() {
    return {
      label: 'All Patients Seen',
      description:
        'All patients seen with services within the selected date range.',
    };
  }

  __initState() {
    this.model = NebAllPatientsCard.createModel();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          align-items: center;
          color: ${CSS_COLOR_GREY_1};
          font-size: ${CSS_FONT_SIZE_CAPTION};
          margin-left: 12px;
        }

        .icon {
          height: 50px;
          width: 50px;
        }

        .detail {
          padding: 12px;
          display: flex;
          flex-direction: column;
        }

        .label-header {
          overflow: hidden;
          min-width: 0;
          color: ${CSS_COLOR_BLACK};
          font-size: ${CSS_FONT_SIZE_HEADER};
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-icon class="icon" icon="neb:patients"></neb-icon>
      <div class="detail">
        <span class="label-header">${this.model.label}</span>
        <span class="info">${this.model.description}</span>
      </div>
    `;
  }
}
customElements.define('neb-all-patients-card', NebAllPatientsCard);
