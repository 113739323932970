import '../../../src/components/misc/neb-icon';
import '../../neb-lit-components/src/components/neb-text';
import '../../neb-lit-components/src/components/pdf-viewer/neb-pdf-viewer-controller';
import '../../neb-document/src/components/neb-image-viewer';

import { Popup } from '@neb/popup';
import { html, css } from 'lit';

import { MIME_TYPES } from '../../neb-document/src/utils/neb-documents-util';
import { LAYOUT_TYPE } from '../../neb-redux/services/layout';
import { baseStyles } from '../../neb-styles/neb-styles';
import { CSS_COLOR_BLACK } from '../../neb-styles/neb-variables';

const ID_BUTTON_CANCEL = 'button-cancel-base';
const ID_NO_MIME = 'no-mime';

export const ELEMENTS = {
  pdf: {
    id: 'pdf',
  },
  image: { id: 'image' },
  content: { id: 'content' },
  imageViewer: { id: 'imageViewer' },
  title: { id: 'title' },
};

class NebPopupFullScreenContent extends Popup {
  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      src: '',
      name: '',
      note: '',
      mimeType: '',
    };

    this.__optionsDropdownOpened = false;
    this.__selectedOption = '';
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onClose(),
      options: () => {
        this.__optionsDropdownOpened = !this.__optionsDropdownOpened;
      },
      getDocumentContentSize: () => this.__getDocumentContentSize(),
    };
  }

  static get styles() {
    return [
      super.styles,
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          width: 100vw;
          height: 100%;
          background-color: ${CSS_COLOR_BLACK};
        }

        .header {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          height: 35px;
        }

        .header-button {
          display: flex;

          background-color: transparent;
          border: none;
        }

        .content {
          display: flex;
          overflow-y: auto;
          width: 100%;
          height: 100%;
        }

        .pdf-container {
          width: inherit;
        }

        .image-container {
          align-self: center;
          width: inherit;
        }

        .icon {
          width: 45px;
          height: 45px;
          padding: 0 0 10px 0;
          fill: white;
          cursor: pointer;
        }

        .headerText {
          display: flex;
          text-align: center;
          align-items: center;
          color: white;
        }
      `,
    ];
  }

  __getDocumentContentSize() {
    const content = this.shadowRoot.getElementById(ELEMENTS.content.id);

    if (content && content.clientHeight && content.clientWidth) {
      return {
        width: content.clientWidth,
        height: content.clientHeight,
      };
    }

    return {
      width: null,
      height: null,
    };
  }

  __renderByMimeType(mimeType) {
    switch (mimeType) {
      case MIME_TYPES.PDF:
        return html`
          <neb-pdf-viewer-controller
            id="${ELEMENTS.pdf.id}"
            class="pdf-container"
            .src="${this.model.src}"
            .small="${true}"
          ></neb-pdf-viewer-controller>
        `;

      case MIME_TYPES.JPG:
      case MIME_TYPES.PNG:
        return this.layout === LAYOUT_TYPE.SMALL
          ? html`
              <img
                id="${ELEMENTS.image.id}"
                class="image-container"
                src="${this.model.src}"
              />
            `
          : html`
              <neb-image-viewer
                id="${ELEMENTS.imageViewer.id}"
                maxZoom="5"
                .src="${this.model.src}"
                .small="${this.layout === LAYOUT_TYPE.SMALL}"
                .medium="${this.layout === LAYOUT_TYPE.MEDIUM}"
                .onGetDocumentContentSize="${this.__handlers
                  .getDocumentContentSize}"
                .model="${this.selectedDocument}"
                .fullScreen="${true}"
              ></neb-image-viewer>
            `;

      default:
        return html`
          <div id="${ID_NO_MIME}" class="no-mime-container">
            No mimeType on document
          </div>
        `;
    }
  }

  __renderHeader() {
    return html`
      <div class="header">
        <neb-text id="${ELEMENTS.title.id}" bold truncate class="headerText"
          >${this.model.name}</neb-text
        >
        <button
          id="${ID_BUTTON_CANCEL}"
          class="header-button"
          @click="${this.__handlers.cancel}"
        >
          <neb-icon class="icon" icon="neb:close" fill="white"></neb-icon>
        </button>
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderHeader()}
      <div class="content" id="${ELEMENTS.content.id}">
        ${this.__renderByMimeType(this.model.mimeType)}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-fullscreen-content',
  NebPopupFullScreenContent,
);
