let currentPage = 0;
let totalPages = 0;

const canGoToPrevious = () => totalPages > 0 && currentPage > 1;
const canGoToNext = () => totalPages > 0 && currentPage < totalPages;
const canDeletePage = () => totalPages > 1 && currentPage > 0;
const hasPages = () => totalPages > 0;

export const getPageNavigation = () => ({
  currentPage,
  totalPages,
  canGoToPrevious: canGoToPrevious(),
  canGoToNext: canGoToNext(),
  canDeletePage: canDeletePage(),
  hasPages: hasPages(),
});

export const init = count => {
  currentPage = count > 0 ? 1 : 0;
  totalPages = count;
  return getPageNavigation();
};

export const reset = () => init(0, 0);

export const first = () => {
  if (totalPages > 0) {
    currentPage = 1;
  }
  return getPageNavigation();
};

export const previous = () => {
  if (canGoToPrevious()) {
    currentPage -= 1;
  }
  return getPageNavigation();
};

export const next = () => {
  if (canGoToNext()) {
    currentPage += 1;
  }
  return getPageNavigation();
};

export const last = () => {
  if (totalPages > 0) {
    currentPage = totalPages;
  }
  return getPageNavigation();
};
