import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import * as masks from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { CSS_SPACING } from '../../styles';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  statementNumber: { id: 'statement-number' },
  date: { id: 'date' },
  attorneyName: { id: 'attorney-name' },
  organizationNames: { id: 'organization-names' },
  providerNames: { id: 'provider-names' },
  totalCharges: { id: 'total-charges' },
};

const FILTERS = Object.freeze({
  statementNumber: 'statementNumber',
  date: 'date',
  attorneyName: 'attorneyName',
  organizationNames: 'organizationNames',
  providerNames: 'providerNames',
  totalCharges: 'totalCharges',
});

class NebFiltersCaseStatements extends NebFilters {
  static get properties() {
    return {
      providers: [],
      organizations: [],
      attorneys: [],
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0 ${CSS_SPACING};
          align-items: flex-start;
          padding: 10px ${CSS_SPACING} 4px;
        }

        .date {
          margin-bottom: 11px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.providers = [];
    this.organizations = [];
    this.attorneys = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      clear: name => this.formService.apply(name, ''),
      apply: () => {
        let model = this.formService.build();

        const { from, to } = model.date;
        const { min, max } = model.totalCharges;

        model = {
          ...model,
          dateFrom: from ? from._i : null,
          dateTo: to ? to._i : null,
          totalChargesMin: min,
          totalChargesMax: max,
        };

        this.deleteEmptyValues(model);

        delete model.date;
        delete model.totalCharges;

        this.onApply(model);
      },
    };
  }

  static createModel() {
    return {
      statementNumber: '',
      date: NebDateRange.createModel(),
      attorneyName: '',
      organizationNames: [],
      providerNames: [],
      totalCharges: NebCurrencyRange.createModel(),
    };
  }

  createSelectors() {
    return {
      children: {
        date: NebDateRange.createSelectors(),
        organizationNames: selectors.multiSelect([]),
        providerNames: selectors.multiSelect([]),
        totalCharges: NebCurrencyRange.createSelectors(),
      },
    };
  }

  deleteEmptyValues(object) {
    Object.entries(object).forEach(([key, value]) => {
      if (!value || value.length === 0) delete object[key];
    });
  }

  formatProvidersToItems(providers) {
    return providers.map(({ name }) => {
      const formattedName = objToName(name, DEFAULT_NAME_OPTS);
      return {
        label: formattedName,
        data: {
          id: formattedName,
        },
      };
    });
  }

  formatOrganizationsToItems(organizations) {
    return organizations
      .filter(({ id }) =>
        this.attorneys.find(({ organizationId }) => id === organizationId),
      )
      .map(({ name }) => ({
        label: name,
        data: {
          id: name,
        },
      }));
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      <neb-textfield
        id="${ELEMENTS.statementNumber.id}"
        label="Case Statement ID"
        name="${FILTERS.statementNumber}"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.statementNumber ? 'neb:clear' : ''}"
        .value="${this.state.statementNumber}"
        .mask="${masks.number}"
        .onClickIcon="${this.handlers.clear}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-range-date
        id="${ELEMENTS.date.id}"
        name="${FILTERS.date}"
        class="date"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.attorneyName.id}"
        label="Attorney Name"
        name="${FILTERS.attorneyName}"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.attorneyName ? 'neb:clear' : ''}"
        .value="${this.state.attorneyName}"
        .onClickIcon="${this.handlers.clear}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.organizationNames.id}"
        name="${FILTERS.organizationNames}"
        label="Organizations"
        multiSelect
        .items="${this.formatOrganizationsToItems(this.organizations)}"
        .value="${this.state.organizationNames}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-select
        id="${ELEMENTS.providerNames.id}"
        label="Providers"
        name="${FILTERS.providerNames}"
        multiSelect
        .items="${this.formatProvidersToItems(this.providers)}"
        .value="${this.state.providerNames}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-range-currency
        id="${ELEMENTS.totalCharges.id}"
        label="Total Charges"
        name="${FILTERS.totalCharges}"
        .model="${this.state.totalCharges}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>
    `;
  }
}

window.customElements.define(
  'neb-filters-case-statements',
  NebFiltersCaseStatements,
);
