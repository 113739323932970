import '../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../neb-styles/neb-variables';

import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  title: {
    id: 'label-title',
  },
  subTitle: {
    id: 'label-sub-title',
  },
  cancelButton: {
    id: 'button-cancel',
  },
  backIcon: {
    id: 'icon-back',
  },
  addNoteIcon: {
    id: 'icon-add-note',
  },
  editNoteIcon: {
    id: 'icon-edit-note',
  },
  deleteButton: {
    id: 'button-delete',
  },
};

class NebPopupHeader extends LitElement {
  static get properties() {
    return {
      title: String,
      subTitle: String,
      showCancelButton: {
        reflect: true,
        type: Boolean,
      },
      showBackButton: {
        reflect: true,
        type: Boolean,
      },
      showAddNoteIcon: {
        reflect: true,
        type: Boolean,
      },
      showEditNoteIcon: {
        reflect: true,
        type: Boolean,
      },
      showDeleteButton: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showCancelButton = true;
    this.showBackButton = false;
    this.showAddNoteIcon = false;
    this.showEditNoteIcon = false;
    this.showDeleteButton = false;
    this.title = 'TITLE';
    this.subTitle = '';

    this.onCancel = () => {};

    this.onBack = () => {};

    this.onClickNoteIcon = () => {};

    this.onDelete = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onCancel(),
      back: () => this.onBack(),
      clickNoteIcon: () => this.onClickNoteIcon(),
      delete: () => this.onDelete(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .spacer {
          flex: 1 0 0;
        }

        .container {
          display: flex;
          background-color: ${CSS_COLOR_WHITE};
          align-items: center;
          width: 100%;
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-note {
          cursor: pointer;
          margin-left: 5px;
          width: 22px;
          height: 22px;
          padding-top: 0.5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-back {
          margin-right: ${CSS_SPACING};
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
        }

        .text-title {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: ${CSS_COLOR_BLACK};
        }

        .text-sub-title {
          margin: 0;
          padding: 0;
          font-size: 14px;
          color: ${CSS_COLOR_BLACK};
        }

        .delete-button {
          width: fit-content;
          padding-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        ${this.showBackButton
          ? html`
              <neb-icon
                id="${ELEMENTS.backIcon.id}"
                class="icon icon-back"
                icon="neb:chevron"
                @click="${this.__handlers.back}"
              ></neb-icon>
            `
          : ''}

        <h2 id="${ELEMENTS.title.id}" class="text text-title">${this.title}</h2>
        ${this.showAddNoteIcon
          ? html`
              <neb-icon
                id="${ELEMENTS.addNoteIcon.id}"
                class="icon-note"
                icon="neb:addNote"
                @click="${this.__handlers.clickNoteIcon}"
              ></neb-icon>
            `
          : ''}
        ${this.showEditNoteIcon
          ? html`
              <neb-icon
                id="${ELEMENTS.editNoteIcon.id}"
                class="icon-note"
                icon="neb:editNote"
                @click="${this.__handlers.clickNoteIcon}"
              ></neb-icon>
            `
          : ''}

        <div class="spacer"></div>
        ${this.showDeleteButton
          ? html`
              <neb-button
                id="${ELEMENTS.deleteButton.id}"
                class="delete-button"
                label="Delete"
                role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.__handlers.delete}"
              ></neb-button>
            `
          : ''}
        ${this.showCancelButton
          ? html`
              <neb-icon
                id="${ELEMENTS.cancelButton.id}"
                class="icon"
                icon="neb:close"
                @click="${this.__handlers.cancel}"
              ></neb-icon>
            `
          : ''}
      </div>
      ${this.subTitle.length > 0
        ? html`
            <div id="${ELEMENTS.subTitle.id}" class="text text-sub-title">
              ${this.subTitle}
            </div>
          `
        : ''}
    `;
  }
}

window.customElements.define('neb-popup-header', NebPopupHeader);
