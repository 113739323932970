import { LitElement, html, css } from 'lit';

import { CSS_COLOR_HIGHLIGHT } from '../../../neb-styles/neb-variables';

const ID_CONTAINER = 'container';

export const ELEMENTS = {
  items: { selector: '.item' },
};

class NebBreadcrumbs extends LitElement {
  static get properties() {
    return {
      items: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.items = [];

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: ({ currentTarget }) => {
        this.onSelect(currentTarget.index);
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .item:not(:last-child) {
        cursor: pointer;
        user-select: none;
        color: ${CSS_COLOR_HIGHLIGHT};
        text-decoration: underline;
      }
    `;
  }

  render() {
    return html`
      <div id="${ID_CONTAINER}">
        ${this.items.map(
          (item, index) => html`
            ${index ? html` <span>></span> ` : ''}

            <span
              class="item"
              .index="${index}"
              @click="${this.__handlers.click}"
              >${item}</span
            >
          `,
        )}
      </div>
    `;
  }
}

customElements.define('neb-breadcrumbs', NebBreadcrumbs);
