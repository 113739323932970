import { postprocessBodySectionsSvg } from './utils';

const BODY_SECTIONS_SVG = `
	<?xml version="1.0" encoding="utf-8"?>
	<!-- 
		Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  
	-->
	<svg 
		version="1.1" 
		id="BODY_LAYERS"
		xmlns="http://www.w3.org/2000/svg" 
		xmlns:xlink="http://www.w3.org/1999/xlink" 
		x="0px"
		y="0px" viewBox="0 0 109.2 205.6"
		style="enable-background:new 0 0 109.2 205.6;" 
		xml:space="preserve"
	>
		<style type="text/css">
			.st0{
				fill:none;
				stroke:#000000;
				stroke-width:0.1;
				stroke-linejoin:round;
			}
			.st1{
				fill:#BF3131;
			}
			.st2{
				fill:none;
				stroke:#000000;
				stroke-miterlimit:10;
				stroke-dasharray:2,2,2,2,2,2;
			}
			.st3{
				fill:none;
				stroke:#000000;
				stroke-miterlimit:10;
				stroke-dasharray:2,2,2,2;
			}
			.st4{
				fill:none;
				stroke:#000000;
				stroke-miterlimit:10;
				stroke-dasharray:2,2.25,2,2;
			}
			.st5{
				fill:none;
				stroke:#000000;
				stroke-miterlimit:10;
				stroke-dasharray:2,2;
			}
		</style>
		<g id="BODY_CORE">
			<g>
				<path class="st0" d="M53.6,5v0.2l-1.2,0.1l0.1-0.1L53.6,5h1.1h1.2l1,0.1v0.1h-1.1V5 M54.7,5v0.2h1.2v0.5h-1.2V5.2h-1.2l-0.1,0.5
					h1.2v0.8h-1.4l0.1-0.8h-1.2l0.2-0.4l-1,0.1l1.1-0.2 M56.9,5.2L58,5.4L56.9,5.2l0.2,0.5l-1.2-0.1L56,6.4h-1.4v1h-2.9l0.3-1l0.3-0.6
					h-1.1l0.3-0.4h0.1l-0.8,0.2h0.1l0.6-0.2 M50.8,5.6l-0.5,0.3l0.9-0.1l-0.4,0.7l-0.4,1h1.4l-0.3,1.3h1.6h1.7V7.5h1.5l-0.1-1h1.3
					l-0.2-0.7h0.9L58,5.4l0.7,0.2h0.1L58,5.4 M58.7,5.6l0.4,0.3l-0.9-0.1l0.4,0.7h-1.3l0.3,1H59l-0.4-1h1l-0.5-0.7l0.5,0.1l-0.8-0.4
					M49.9,6l-0.3,0.2l-0.4,0.4h0.6l-0.6,1h1.2l-0.5,1.1l-0.3,1.4l1.6,0.1h1.7L53,8.8l0.1-1.3l0.2-1h-1.4h-1.2h-1l0.6-0.6L49.9,6
					l0.7-0.4 M59.6,6L59.6,6l0.7,0.7l-0.7-0.1l0.6,1H59l0.4,1.2l1.3-0.1l-0.5-1.2h0.7l-0.6-0.9l0.3,0.2l-0.9-0.7 M49.2,6.6L49.2,6.6
					l-0.3,0.1l0.7-0.5 M49.1,6.6l-0.7,0.8L48,7.5l0.9-0.8 M60.5,6.7l0.8,0.8l-0.4-0.1l0.7,1.2h0.5l-0.7-1l0,0 M48.4,7.5l-0.6,1.1h0.8
					l0.5-1.2L48.4,7.5 M48,7.5l-0.1,0.1l-0.7,1l-0.6,1.3h0.6l1,0.1l0.4-1.4h1.3l1.5,0.1l-0.2,1.4L51,11.8h1.8l0.1-1.6h1.8V8.8h1.6
					l-0.2-1.3h1.5l0.3,1.3l0,0h-1.6l0.1,1.5h1.8L58,8.8 M47.9,7.7l-0.8,1l-0.7,1.2h0.3l-0.5,1.5l0.7,0.1l0.4-1.5l0.6-1.4h-0.5
					l-0.2,0.1 M61.5,7.6l1,1.1l-0.3-0.1l0.6,1.2h-0.6l-0.5-1.3h-0.9l0.4,1.4l1-0.1l0.4,1.5l0.7-0.1l-0.5-1.5h0.3l-0.7-1.2 M58,8.7
					l1.5-0.1l0.3,1.4l0,0l1.3-0.1l0.3,1.6l1.1-0.1l0.2,1.6l0.8-0.1l-0.3-1.6h0.3L63,9.8 M48.2,10l-0.3,1.6l1.4,0.1l0.2-1.6L48.2,10
					M59.8,10.1l-1.6,0.1l0.2,1.6l1.7-0.1L59.8,10.1 M63.6,11.3l0.3,1.6l0.1,1.6l-0.3,0.4h-0.9v-1.8l-1.1,0.1l-0.2-1.7l-1.4,0.1
					l0.1,1.7l-1.7,0.1l-0.1-1.6h-1.8l-0.1-1.6h-1.8v1.6h-1.9l-0.1,1.7l-1.8-0.1l0.2-1.6l-1.6-0.1l-0.1,1.7L48,13.3l0.2-1.7l-1.3-0.1
					l-0.2,1.6L45.9,13l0.3-1.6h-0.3l0.5-1.5 M63.8,12.9h-0.3l0.1,1.9v0.1l-0.4,2l-0.7-0.1l-0.4,2H61l0.3-1.9L59.9,17l0.2-1.9h1.5
					L61.4,17l1.1-0.1l0.2-1.9l-1.2,0.1v-1.8L60,13.4v1.8l-1.7,0.1v-1.8l-1.9,0.1v-1.8h-2v1.7h-1.9v1.8l-1.6-0.1l-1.7-0.1v-1.8l1.7,0.1
					v1.8l0.1,1.9L49.3,17l-0.1-1.9L47.7,15v-1.8l-1.2-0.1v1.8h-0.9l0.1-1.9h-0.3l0.3-1.6 M63.6,15.1l0.1,1.3L63.6,17l-0.2,0.6L63.2,17
					l-0.4,1.9l-0.7-0.1l-0.4,2h-1l0.4-1.9L59.7,19l0.3-1.9l-1.7,0.1l0.1-1.9l-1.8,0.1v-1.8h-1.9v1.8h-1.9v1.9l-1.7-0.1l0.2,1.9h-1.6
					L48.3,19l-0.4-2l-0.2-1.9L46.6,15l0.2,1.9l1.1,0.1l1.4,0.1l0.3,1.9l0.4,1.9h-1.3L48.3,19h-1l-0.4-2l-0.6,0.2l-0.4-1.9v-0.1
					l-0.3-0.3l0.1-1.7 M63.5,17.7L63.5,17.7l-0.3,1.6L62.9,19l-0.4,1.9h-0.7l-0.5,1.8h-1l0.4-1.8h-1.3l0.4-1.9h-1.6v-0.1l0.2-1.9
					l-1.8,0.1l0.1-1.9h-1.9v1.9h-1.9L53,19l-1.7-0.1l0.3,1.9l-1.4-0.1l0.4,1.8h-1.2L49,20.7h-1l-0.5-1.9l-0.7,0.1L46.3,17v-0.1
					M63.7,16.4l0.1-0.2l0.1-1.6 M45.8,15.1v1.4l-0.2-1.8 M45.7,16.5v0.1 M46.2,17.2L46,17.8l-0.2-1.2l0.2,1.2V18v0.3v-0.5l0.3,1.5
					l0.3-0.2l0.5,1.9h0.7l0.5,1.8h-0.7l-0.5-1.7l-0.3,0.2l-0.4-1.6v-0.2 M58.1,19.1L58.1,19.1l-1.7,0.1l0.1-1.9h-1.9v1.9h-1.7l0.1,1.9
					h-1.6l0.3,1.7h-1.4l0.3,1.5h-1.2L49,22.8h-1l0.4,1.5h-0.5l-0.4-1.5h-0.4l-0.5-1.7l-0.1,0.2L46.2,20v-0.2v-0.4 M63.6,17l-0.1,0.5
					l0.2-1.3 M63.6,17.5c0,0.1,0,0.4,0,0.4V18 M63.6,17.9v0.3h0.2l0.2-0.1l0.2-0.1h0.3l0.1,0.2l0,0l0.2,0.1L64.6,18l-0.2-0.1 M45.3,18
					l-0.1,0.2h-0.3L45,18H45.3 M45.9,18v0.1h-0.2L45.5,18l0.1,0.2l-0.1-0.1L45.4,18h0.2 M63.4,17.6v0.7l0.1-0.3 M64,18L64,18l0.2-0.1
					l0.1,0.2h0.3 M46,18.1L46,18.1v0.1v0.1v-0.1 M45.4,18.1v0.3v0.1l0.2,0.1l-0.1-0.4h0.1v-0.1 M64.6,18.2v0.5l-0.3,0.1v-0.5l-0.2,0.2
					L64,18.2l0,0h-0.1l-0.3,0.2l-0.1-0.2l-0.1,0.1V18 M45.2,18.2v0.5h-0.3v-0.5h-0.2l0.1-0.2l0.2-0.1 M44.7,18.3L44.7,18.3v0.5
					l0.2-0.1l0.1,0.7l-0.3,0.1v-0.7l0,0v-0.2V18.3 M45.7,18.2l0.1,0.2l0.1-0.2 M63.4,19l0.1-0.5l0,0 M46.1,18.7L46,18.4 M64,18.3
					l-0.1,0.4l-0.3-0.3h-0.1l-0.1-0.1v0.3v-0.2 M45.5,18.4l-0.3-0.1 M45.9,18.4v0.3l-0.2-0.1l0,0L45.9,18.4L45.9,18.4l0.1-0.1
					M46,18.4v0.2l-0.1,0.1l0.1,0.7l0.2-0.1V19l-0.1-0.4 M64,18.4L64,18.4l-0.2,0.1l0,0l-0.2,0.1v-0.3 M63.3,18.8v0.3l0.2,0.1l0.1-0.7
					h-0.1l-0.1,0.7L63.2,20v-0.2v-0.2l-0.1-0.2v-0.1 M64.7,18.3l0.1,0.2v0.4l-0.1-0.1l-0.1,0.7h-0.2v-0.7l0.3,0.1V18.3 M45.2,18.7
					L45.2,18.7l0.3,0.3v-0.6 M46,18.6l0.1,0.4 M64,18.5l-0.1,0.6l0.3-0.3l-0.1,0.7l0.3-0.1l-0.1,0.9H64v-0.8l-0.1,0.3v-0.6l0,0
					M58.1,19.1L57.9,21h-1.6l0.2-1.9h-1.8v1.8h-1.6l0.2,1.7h-1.4l0.2,1.5h-1.3l0.2,1.4l-1.1-0.1L49.7,24l-0.9-0.1l0.3,1.3l-0.6-0.3
					L48.1,24l-0.3,0.2l-0.5-1.6h-0.2l-0.4-1.5v0.1l-0.3-1.1v-0.3 M46.2,19.2V19 M45.3,18.8v0.6l0.2,0.2V19l0.3,0.3l-0.1-0.7 M64,19.1
					l-0.2,0.2l0.1-0.7 M46.2,19.3L46.2,19.3L46.2,19.3L46.2,19.3L46.2,19.3l0.1,0.8v0.1l-0.1,0.1L46,19.4h-0.2l0.1,1l-0.3-0.6l0,0
					M46.3,19.8l-0.1-0.4 M63.9,19.8L63.9,19.8l-0.3,0.5l0.1-1h-0.2l-0.2,0.9l-0.2-0.1V20L63,20.5l0.2-0.7 M45,19.3v0.9h0.3v-0.8
					L45,19.3 M45.5,19.6v0.6h-0.2l0.1,0.9l-0.3,0.1l-0.1-1h-0.3v-0.8h-0.1v-0.6 M63.9,19.6V20h0.2L64,20.9l0.3,0.1l0.1-1h0.3v-0.9
					l0.1,0.1v-0.6 M63.1,19.4l0.1-0.7 M44.6,20l0.1,0.9h0.1L44.6,20L44.6,20v-0.8 M63.1,19.9L62.9,21h0.2l0.2-1h0.3l0,0l0.2,0.4v0.1
					l0.1,0.3l-0.1,0.9l0.3,0.1l0.2-0.9h0.3l0.1-0.9h0.1v-0.8 M46.1,19.8l0.3,1.1L46.2,21v-0.7h-0.3l0,0l-0.2,0.5l-0.1-0.6 M63,20.5
					l-0.1,0.6L62.8,21l-0.4-0.2l-0.5,1.7h-0.7L60.8,24h0.7l0.4-1.5l0.4,0.1l0.4-1.7l0.4-1.6 M63.8,20.7l0.1-0.5 M45.8,20.3l0.2,1.1
					l-0.2,0.3l-0.2,0.2L45.4,21l0.2-0.2l0,0 M62.9,21.2v0.1l-0.1,0.3l-0.3,1.2l0,0h-0.1l-0.5,1.5l-0.3-0.2l-0.4,1l-0.6,0.3l0.3-1.2
					l-1,0.1l0.4-1.5h-1.2l0.4-1.8H58l-0.3,1.8h1.4l-0.3,1.5H60l-0.2,1.4l0.9-0.2v1l0.7-0.8l-0.2-0.5 M54.7,21v1.7h1.5l0.1-1.7H54.7
					M45.7,20.9l0.2,0.8l0.2,0.8l-0.2,0.2l-0.2-0.8L45.4,22l-0.1-0.9h-0.4L45,22h-0.1l-0.1-0.8 M64.8,20.3l-0.1,0.9L64.6,22h-0.1
					l0.1-0.9h0.1 M46.6,21.2l0.1,0.5L46.6,21.2L46.6,21.2 M62.9,21.2L62.9,21.2l-0.3,1.3l0.3-0.3l0.2-1l0.3,0.2l0.1-1 M46.3,21.3
					l0.2,1l-0.3,0.1l-0.2-1L46.3,21.3 M62.8,21.5l-0.3,1.1v-0.1l-0.2,0.8l0.3-0.2l-0.1,0.6l0.4,0.2l0.1-0.7l0.2,0.1l0.2-0.7l0.2,0.2
					l0.2-0.8l-0.2-0.2l-0.1,0.9l-0.3-0.2l-0.1,0.8h-0.4l0.2-0.8h0.3l0.2-0.9l0.2,0.2l0.2-0.8 M45.1,22.8L44.9,22 M46.7,21.7l0.2,0.8
					l-0.4-0.3l0.2,0.8h-0.4l-0.2-0.8l-0.2,0.4l0.2,0.7l0.2-0.1l0.1,0.7h-0.2v-0.6L46,23.4l-0.2-0.6h-0.3L45.3,22H45l0.1,0.7 M64.5,22
					l-0.2,0.8H64l-0.1,0.7h-0.3l-0.1,0.6h-0.2l0,0l0.1-0.6l0.2,0.1l0.2-0.6h0.3l0.2-0.8L64.5,22 M46.9,22.8l0.1,0.6l0.2,0.8v0.1
					l0.1,0.1L46.9,22.8L46.9,22.8v-0.2 M62.5,22.8L62.5,22.8l-0.2,1.3l0.1-0.6v-0.1 M48.3,25l-0.1,0.5l0.7,0.8v-1l0.8,0.2l0.1,1.3
					l1.1,0.2v-1.4h1.2l-0.2-1.5h1.3l-0.1-1.5h1.5v1.5h-1.3l0.1,1.4h-1.3v1.4l-1.2,0.2v1.3l-1.3-0.2l0.2-1.3l-1-0.5l-0.1,0.8l-0.8-1
					l0,0l-0.5-1.8l0.2-0.1l0.6,1.3L48,26.2l-0.4-0.4l0,0l-0.3-1.2v-0.3 M59.7,25.6L59.7,25.6l-1.1,0.1l0.2-1.5h-1.3l0.2-1.5h-1.5
					l-0.1,1.5h-1.4v1.5h-1.3v1.5l-1.3-0.1v1.3h-1.2v1.1h-1.1l-0.1-1.3l-0.9-1l-0.2,0.4l-0.5-0.3L48,26.1 M64.4,22.7l-0.3,0.6l0.1-0.6
					H64.4l0.2-0.7 M62.5,22.8l-0.4,1.6l-0.2-0.1l-0.6,1.3l0.2,0.6l0.5-1.7l0.1-0.1v-0.1v-0.2 M45.4,23.9l0.1,0.5l0.3,0.1L45.7,24H46
					l0.1,0.5l0.1-0.1v-0.5L46,24l-0.1-0.6h-0.3l0.2,0.6L45.4,23.9l-0.1-0.6h0.3l-0.2-0.6h-0.3l0.2,0.6l-0.2-0.6h0.1 M47.1,23.3
					l0.1,0.6l-0.3-0.2l-0.1-0.6L47.1,23.3 M63.2,23.9l-0.1,0.5l0.2,0.1l0.1-0.5h0.3l0.2-0.5h0.3L63.9,24l0.1-0.1l0.1-0.5v0.1
					M46.9,23.8v0.4l-0.2,0.2L46.5,24l0,0L46.9,23.8 M62.2,24.3l0.1-0.3l0.2,0.5l0.1-0.2v-0.5L62.3,24v-0.4 M63.1,24h-0.2l0,0
					l-0.1,0.5l-0.2-0.3 M47.2,24.2L47.1,24l0,0 M64,23.9l-0.1,0.4V24h-0.3l-0.1,0.5h-0.2l-0.1,0.4l-0.1-0.3V24 M59.7,25.6l-0.1,1.3
					l-1.1,0.2l0.1-1.4h-1.3l0.1-1.4H56v1.4h1.3v1.4l1.2-0.1v1.3l1.2-0.2l0,0l0.9-1l-0.1-0.9l-0.9,0.6l0.2,1.3l0.9,0.2l0.2-0.7
					l-0.2-0.5l0.8-1V26l0.4-0.3l0.1-0.2l0.2-1.4v0.2l0.2,0.2l0.1-0.3l0.3,0.3l0.1-0.2l0,0 M63.8,24.5v0.3l0.1-0.5L63.8,24.5
					M47.1,23.9l-0.2,0.5l-0.2-0.2 M62,24.3L62,24.3 M46.3,24.4l0.1,0.2l0.1-0.6 M46.4,24.6L46.4,24.6l0.1,0.2l0,0h0.1l0.1,0.5h0.2
					l-0.1-0.5h-0.2l0.1-0.1l0.1,0.1l0.3-0.1l0.1,0.6h0.2l-0.1-0.8l-0.2,0.2L47,24.5l-0.3,0.3l-0.1-0.3l0,0 M62.8,24.5l0.1,0.2l0.1-0.2
					l0.1-0.2 M46.4,24.6l-0.1,0.3l-0.1-0.4h-0.3l0.1,0.4l-0.2-0.1l-0.1-0.4 M63.8,24.5h-0.3l-0.1,0.4l-0.2-0.1L63.1,25l-0.2-0.3l0,0
					M46.5,24.7l0.1-0.3 M62.7,24.8L62.7,24.8L62.7,24.8L62.7,24.8v0.6h-0.4l0.1-0.7L62.7,24.8 M63.8,24.8L63.8,24.8L63.5,25l-0.1,0.2
					l-0.3-0.1L63,25v-0.2 M62.8,25.3l-0.2,1.1l-0.2,0.1l0.2-1.2H62.8l0.1-0.4L63,25 M46.6,24.9l-0.1,0.2l-0.1-0.2L46.1,25l0.1,0.1
					L46,25l-0.2-0.2 M46.5,25.1h-0.3 M63.4,25.1l0.3-0.1l0.1-0.2 M46.7,25.4l0.2,1.2l0.1,0.1l-0.1-1.3h0.3l0.2,1.3l-0.3-0.1l0.3,1.4
					l-0.1-0.2L47,26.5 M62.6,26.5l-0.4,1.4L62.1,28l0.3-1.3l-0.3,0.1l0.2-1.3h-0.2l0.1-0.9 M62,25.4v0.2l-0.1,0.1 M47.6,25.7
					L47.6,25.7l-0.2-0.4 M56,25.7v1.5h-1.3v1.3h-1.3v1.1h-1.3l-0.2,1h-1.2l0.1-1.1h1.3v-1.1h1.3v-1.3l1.3-0.1v-1.5L56,25.7 M47.5,26.7
					V27v0.5l-0.2-0.9L47.5,26.7l-0.1-1.1 M61.8,26.6v0.3v0.6l0.2-1L61.8,26.6l0.1-1.1 M47.6,27l0.1,0.1l-0.1-1.3 M61.9,27L61.9,27
					v-1.2 M61.5,26.2v1.1l0.3-0.2V27 M59.7,28.2v1.3h-1.2v-1.2l-1.2,0.1v-1.3H56v1.3h1.3v1.1h1.2l0.1,1.1h1.3l-0.1-1.1h1v-1.1h0.7v-1
					l-0.6,0.3 M47.6,27.6v0.5v0.3H48v-1l-0.3-0.2l-0.1,1.2v0.3l-0.3-0.6l0.3,0.1 M61.8,27.6l-0.1,0.5L62,28v-0.1 M61.6,27.2v1.2v-0.3
					M61.9,28l-0.3,0.7v-0.4h-0.4l0.1,1.2l-0.8-0.1l0.1,1.2l-1-0.1l0.3,1.3h-1.2l-0.1-1.1h-1.2l-0.1-1.1H56v-1.1h-1.3v1.1h-1.3v1H52
					l-0.1,1.2h-1.3l0.2-1.1h-1.1l0.1-1.1h-1v-1.1l-0.6-0.1v1.3l0.7-0.1l-0.1,1.2l1-0.1l-0.3,1.1h1.2l-0.2,1.1h1.3l0.1-1.1h1.4l0.1-1.1
					h1.3v-1H56v1h-1.4v1.1h-1.4v1.1h-1.5l-0.2,0.8L50,33.6h-1.2l0.4-0.7l0.2-1.1l-1,0.1l0.2-1.2L47.9,31l0.1-1.3l-0.3,0.1V29v-0.4
					M49.7,28.2l-0.9,0.2l-0.2-0.8 M61.8,28.3v1.5l0,0v-1.1l0.4-0.9 M47.6,29v-0.7 M61.8,29.8V30v1.2l-0.3-0.3l0.2,1.3L61,31.9
					l0.3,1.2l-1.1-0.2l0.4,0.7h-1.2L59,32.8h-1.3l-0.1-1.1h-1.4v-1.1h1.3l0.1,1.1h1.3l0.2,1.1h1.2l-0.2-1.1l1,0.1l-0.2-1.2l0.7,0.2
					l-0.1-1.3L61.8,29.8L61.8,29.8 M47.8,32.1l-0.1,0.2l-0.2,1.1l0.6-0.3l-0.2,0.6l1-0.2l-0.5,0.7h1.3l0.5-0.7l0.4-0.7h-1.2l-1,0.2
					l0.2-1.2L47.8,32.1l0.2-1.3L47.7,31v-1.4 M56.2,31.6h-1.5v1.2h-1.5L53,33.7l-1.5-0.1l-0.4,1l-1.5-0.3l-0.8,0.5h-1.1l0.7-0.6
					l-0.8,0.2l0.3-0.5l0.1-0.1 M61.8,31.2l0.1,1.4l-0.2-0.3l-0.1-0.1 M47.7,32.3l-0.2,0.3l0.1-1.5 M61.7,32.4l0.1,1.1l-0.6-0.4
					l0.3,0.7l-1-0.2l0.5,0.6l-1.2,0.1l-0.5-0.7l-1.4,0.1l-0.3-0.8h-1.4l-0.1-1.1 M61.5,33.8L61.5,33.8l0.4,0.6L61,34.2l0.8,0.6
					l-1.1,0.1l-0.8-0.6l-1.5,0.3l0,0l-0.4-1l-1.6,0.2l-0.1-0.9h-1.5v1h-1.6l-0.1,0.9l-1.8-0.1l0,0l-1.4,0.5l-1-0.3l-1,0.6l-1-0.2
					l0.8-0.5h0.1 M47.6,33.5l-0.1,0.4l0.1-1.2 M47.5,33.9v0.5l0.4-0.4 M58.4,34.7l1.2,0.6L59,35.7h-1L58.4,34.7l-1.8,0.1l-0.1-0.9
					h-1.6v0.9H53v0.9l-1.5-0.1l-0.3-0.9 M62,32.7l0.1,1.4l-0.2-0.6 M62,34.1l0.1,0.3L61.7,34 M47.6,34.5l-0.3,0.3l0.1-0.4 M62.1,34.4
					l0.1,0.4l-0.3-0.3 M59,35.6l0.3,0.7l-1.2,0.1L58,35.6l-1.5,0.1v-0.9h-1.8v0.9l-1.8-0.1v1l-1.6-0.1l0.1-0.9h-1l-0.5-0.4l-0.8,0.7
					l-1.2-0.3L47,36.4l-1.2-0.3l0.9-0.5h0.1 M47.6,34.9L47,35.1l0.3-0.3 M62.5,35l0.7,0.5l-0.4-0.2h-0.1l-0.9-0.6L62.5,35l-0.3-0.2
					M62.6,35.4l-1,0.2l-1-0.6l-1.1,0.3l0.8,0.7l1.2-0.3l0.8,0.8l1.2-0.3l-0.9-0.5 M45.8,36L45.8,36l-0.5,0.1l1-0.5l0.7-0.5
					M46.3,35.6l0.3-0.1 M58,36.4L58,36.4l-1.6,0.2v-1l-1.8,0.1v0.9h-1.7v1l-1.6-0.1v-1l-1.2-0.2L49,35.9l-0.6,1l-1.5-0.5l-1,0.7
					l-1.2-0.5l1.1-0.6 M63.3,35.6l1,0.5L63.9,36h-0.2 M64.1,36.1l1.1,0.5h-0.5l-1-0.6 M45.4,36.1l-1.2,0.5h0.5l-1.4,0.6l0.9,0.5
					l1.7-0.6l1.8,1l0.8-1.2l1.4,0.4l0.2-1l0.3-0.7 M60.4,35.9l0.6,1l-1.5,0.4l-0.2-1L60.4,35.9 M65.3,36.6l1.2,0.6h-0.3l-1.4-0.6
					l-1.2,0.5l-1.1-0.8L61,36.8l0.8,1.2l1.8-1l1.7,0.6l1-0.5l1.5,0.6H68l-1.5-0.6 M58,36.5v1l-1.6,0.1l0.1-1h-1.8v1H53l0.1,1.1
					l-1.6-0.1v-1.1l-1.6-0.3l-0.1,1.2l-2.1-0.3l-2.6,0.5l-0.9-0.9l-1.9,0.6l-0.6-0.5l1.6-0.6H43l1.2-0.6 M67.9,37.8l0.7,0.4L67.9,37.8
					l-0.6,0.4l-1.9-0.6l-0.9,0.9L61.9,38l0,0l-2.1,0.3l-0.2-1.1l-1.5,0.2L58,38.5l1.7-0.2v1.2l2-0.1V38 M68.6,38.2l0.7,0.3l-0.2,0.4
					l-1.8-0.7L66.7,39l-2.3-0.5l-0.4,1l-2.3-0.2v1.2l-2,0.1v-1.3l-1.8,0.4l0.1-1.2l-1.6,0.1v-1.1h-1.7v1.1h-1.6v1.2l-1.6-0.1l-0.1-1.2
					l-1.7-0.2l-0.1,1.2l-2-0.1V38 M41.6,37.8L41.2,38L41.6,37.8L41.6,37.8l1.3-0.7 M40.1,38.5l-1.6,0.7l0.2,0.3l1.6-0.6l0.3,0.7l2-0.6
					l0.3,1l2.3-0.4v1.2l2.2-0.1v1.4l2.1,0.1v-1.4l1.9,0.2l0.1-1.2l-1.8-0.2v1.2l-2.1-0.1l0.1-1.2l-2.3,0.2l-0.3-1l-2.3,0.5l-0.5-0.8
					L40.5,39L40.1,38.5l1-0.5 M54.6,38.7v1.2h1.5v1.3l1.6-0.2l-0.1,1.3l1.9-0.2l0,0v-1.3L57.6,41l0.1-1.2l-1.6,0.1l0.1-1.2L54.6,38.7
					M71.4,39.5l1.3,0.5h-0.1L71.4,39.5l-0.5-0.2l-1.6-0.8 M37.5,39.7l-0.6,0.2h-0.1L37.5,39.7l1-0.4 M70.9,39.3l-0.2,0.3L69.1,39
					l-0.3,0.7l-2.1-0.6l-0.3,1l-2.3-0.4v1.1l-2.2-0.1v1.4l-2.1,0.1 M36.9,39.9h0.5l1.4-0.4v0.6l1.9-0.6l0.3,0.9l2.1-0.5l0.2,1.2
					l2.2-0.3v1.4l2.2-0.1v1.7h2.2l-0.1-1.7l1.9,0.2L51.6,41l1.6,0.1l-0.1-1.2h1.6v1.3h-1.5v1.3l-1.6-0.1l0.1,1.6l-2-0.2v2.4h-2.2v-2.4
					l-2.2,0.1v-1.8l-2.1,0.3v-1.3l-2.1,0.5l-0.2-1.1l-2,0.5l-0.2-0.8l-1.8,0.3l0.4-0.5 M72,39.9l0.4,0.5l1.8,0.2l-1.7-0.8L72,39.9
					l-1.4-0.5L70.5,40l-1.9-0.6l-0.3,0.9l-2.1-0.6L66,40.8l-2.2-0.3v1.4l-2.2-0.1v2h-2.2v-1.7 M36.9,39.9l-1.7,0.8l-0.3-0.2L36.9,39.9
					M72.7,39.9l1.9,0.6l-0.4,0.2l0,0l1.8,0.6l0.1-0.2l-1.6-0.6 M34.9,40.4L33.3,41l0.1,0.2l1.8-0.6l0,0l1.8-0.2v0.8l1.9-0.3l0.1,1
					l2-0.4l0.1,1.3l2.1-0.4v1.7l2.1-0.3v2.4l2.2-0.1V49h2.3v-2.9l2.1,0.1l-0.1-2.3l1.6,0.1l-0.1-1.6l1.4,0.1v-1.3h1.5l-0.1,1.3
					l1.6-0.1L57.6,44l2-0.1l-0.1,2.4h2.3v-2.5l2.2,0.1v-1.8l2.1,0.3v-1.3l2.2,0.5l0.1-1.1l2,0.5l0.2-0.8l1.8,0.3v0.8L70.5,41l-0.2,1
					l-2-0.4l-0.1,1.3l-2.1-0.5v1.7L64,43.9v2.4l-2.1-0.1v2.9h2.2v-2.9l2.1,0.2v-2.3l2,0.4v-1.7l2,0.4l0.1-1.2l1.9,0.2l0.1-1l1.8,0.2
					v-0.8 M33.3,41l-1.4,0.7l0.1,0.2l1.5-0.6l0.1,0.6l1.6-0.3l0.2,0.9l1.8-0.1l0.1,1.2l1.9-0.1l0.1,1.6l1.9-0.3l0.1,2.2l2-0.3v2.7
					l2.1-0.1l-0.1,3.2l2.2,0.1l0.1-3.3h-2.2v-2.8l-2.1,0.2v-2.3l-2.1,0.4v-1.7l-2,0.4l-0.1-1.2l-1.9,0.2l-0.2-1l-1.8,0.2v-0.8
					M77.6,41.7l1.5,0.9l-0.2,0.1l-1.5-0.8l-0.2,0.4l-1.4-0.5l-0.2,0.8L74,42.3l-0.1,1.2l-1.8-0.1L72,44.9l-1.9-0.1L70,46.9l-1.9-0.2
					v2.6l-2-0.1l0.1,3l-2.1,0.1L64,49.1l2.1,0.1v-2.7l2,0.3l0.1-2.2l1.9,0.3l0.1-1.6l1.9,0.1l0.1-1.2l1.8,0.1l0.2-0.9l1.6,0.4l0.1-0.6
					l1.5,0.6L77.6,41.7L76.1,41 M31.9,41.7l-1.5,0.9l0.2,0.1l0.2,0.3l1.4-0.7l0.2,0.7l1.4-0.5l0.3,1.2l1.5-0.3l0.2,1.5l1.7-0.1l0.2,2
					l0.1,2.3l1.7,0.1l1.9-0.1l-0.1,2.8l2,0.1l0.1-3l-2,0.1v-2.6l-1.9,0.2l-0.1-2.1l-1.9,0.1l-0.1-1.5l-1.7,0.1l-0.2-1.2l-1.6,0.3
					l-0.3-0.6l-1.4,0.5L32,41.9l-1.5,0.9l-1.4,1h-0.2l1.5-1.2 M79.1,42.6l1.5,1.2l-0.2,0.1l-1.5-1.1l-0.2,0.4l-1.4-0.8L77,43.1
					l-1.4-0.5l-0.3,1.2l-1.4-0.3L73.7,45h-1.6l-0.2,2h-1.8v2.4l-1.9-0.1l0.1,2.8l-2,0.1l0.2,3.1l-2.1,0.3l-0.2-3.3l-2.3,0.3v-3.4h-2.3
					v-2.9l-2,0.1l0.1-2.3l-1.6,0.1l0.1-1.6l-1.4,0.1v1.6h-1.3v2.3h-1.6v2.9h-2.1v3.3l-2.3-0.3l-0.2,3.4l-2.2-0.3L43,55.3l0.2-3.1
					l2.1,0.1l-0.2,3.3l-0.3,3.2l-2.1-0.4l-2.2-0.6l0.4-2.8L39,54.7l0.3-2.7l0.1-2.6V47h-1.8H36l-0.2-1.9l-1.3,0.2l-0.4-1.5l-1.3,0.4
					l-0.4-1.1l-1.3,0.7l-0.3-0.7l-1.4,1l-0.3-0.3l-1.3,1.4h-0.2l1.3-1.4 M80.6,43.8l1.4,1.5h-0.3l-1.3-1.5l-0.3,0.3l-1.4-1l-0.4,0.7
					l-1.4-0.7l-0.3,1.1l-1.3-0.4L75,45.3l-1.3-0.2l0,0 M73.7,45.1L73.5,47h-1.6l-0.1,2.3L70,49.4l0.1,2.6l-1.9,0.1l0.3,2.9l-2.1,0.3
					l0.4,3l-2.2,0.5l-0.3-3.2l-2.2,0.3l-0.2-3.4l-2.3,0.1l-0.1-3.4h-2.1v-2.9h-1.5L56,44h-1.4v2.3h-1.3l0.1,2.9h-1.5v3.4l-2.1-0.1
					L49.6,56l-2.3-0.2L47,59.1l-2.2-0.3l-0.5,3.1l-2.2-0.6l0.6-2.9l0.4-3L41,55.1l0.2-2.9l-1.9-0.1l-1.7-0.3l0.1-2.5h-1.5L36,47
					l-1.2,0.1l-0.3-1.8l-1.2,0.3l-0.4-1.4l-1.3,0.6l-0.5-1l-1.2,1l-0.4-0.6l-1.2,1.3l-0.4-0.2l-1.2,2.2h-0.2l1.2-2.2 M81.9,45.2
					l1.2,2.2h-0.2l-1.2-2.2l-0.4,0.2L80.1,44l-0.5,0.6l-1.2-1l-0.5,1L76.7,44l-0.5,1.5L75,45.1L74.6,47l-1.2-0.1l-0.2,2.3h1.1l0.3-2.2
					l1,0.3l0.5-1.8l1.1,0.6l0.7-1.3l1.2,0.9l0.6-1l1.1,1.3l0.5-0.5l1.1,2.1l0.5-0.1l1.1,3.4h-0.6l-1-3.2l-0.6,0.4l-1-1.9l-0.7,0.6
					L79,45.7l-0.7,1.2l-1-0.8l-0.6,1.7l-1-0.4l-0.5,2l-0.9-0.1l-0.2,2.3h0.8l0.3-2.1l0.9,0.3l0.6-1.9l1,0.7l0.7-1.6l1,1.1l0.8-1.2
					l0.9,1.7l0.8-0.7l1,3l0.8-0.1l0.9,3.4l-0.9-0.1L83,50.9l-1,0.4l-0.8-2.7l-0.9,1L79.5,48l-0.9,1.4l-0.8-0.9l-0.9,1.7l-0.8-0.5
					l-0.5,1.9l-0.7-0.1l-0.2,1.9h0.5l0.4-1.8l0.6,0.3l0.6-1.8l0.7,0.8l0.8-1.6l0.7,1.3l1-1.2l0.5,2.4l1.1-0.6l0.5,2.9l1.2-0.1l0.4,2.7
					l-1.4-0.1l-0.2-2.5l-1.4,0.3l-0.2-2.6l-1.2,0.8L79,50.7l-1,1.2l-0.5-1l-0.8,1.5l-0.6-0.5l-0.5,1.6l-0.5-0.1L74.9,55h0.2l0.4-1.4
					l0.5,0.3l0.7-1.5l0.5,0.7l0.8-1.2l0.2,1.5l1.1-0.8l-0.1,2.2l1.5-0.5v2.5h1.7l0.3,2.3L80.9,59l-0.1-2.2L79,57l0.3-2L78,55.3
					l0.3-1.8l-0.6,0.4l-0.3-0.7l-0.7,1.3L76.2,54l-0.6,1.4l-0.3-0.3l-0.4,0.7L75,55l-0.2,0.1v-1.7l-0.5,0.2v-2.2l-1,0.1v-2.4h-1.5v2.7
					l1.4-0.2l0.2,2.4l0.8-0.3l0.2,1.9l0.4-0.4v-0.1 M33.2,45.6L33.2,45.6l-1.1,0.6l-0.6-1.4l-1.2,0.9l-0.6-1L28.6,46l-0.5-0.5L27,47.6
					l-0.5-0.1l-1,3.3h0.6l1.1-3.2l0.6,0.4l1-1.9l0.6,0.8l1-1.2l0.7,1.3l1-0.8l0.6,1.7l1-0.4L33.2,45.6 M74.8,55.3v1v0.2v0.3l-0.1,0.3
					l-0.3-1.5l-0.7,0.5l-0.3-2.2l-1.3,0.3l-0.2-2.5l-1.7,0.2l0.2,2.7l-1.9,0.3l0.4,2.8l-2.1,0.5l0.6,2.9l-2.2,0.6l-0.6-2.9l-2.2,0.4
					l-0.3-3.3l-2.3,0.2l-0.2-3.5l-2.1,0.1l-0.1-3.4h-1.5v-2.9h-1.3v2.9h-1.2l-0.1,3.5l-1.4-0.2l-0.2,3.6l-2.1-0.1l-0.3,3.3L47,59.2
					l-0.4,3.2l-2.3-0.5L43.7,65l-2.4-1l0.8-2.8l-2.2-0.7l0.6-2.7l-1.9-0.5l0.4-2.6l-1.7-0.3L35.9,54l0.2-2.4v-2.4H35L34.7,47l0,0
					M34.7,47l-1,0.3l0.4,2l0.9-0.1l0.1,2.3h-0.7l-0.3-2.1l-0.8,0.3l-0.6-1.9l-0.9,0.7L31,47l-1,1l-0.8-1.2l-0.9,1.7l-0.8-0.7l-0.9,3
					H26l-0.9,3.4l0.9-0.1l0.8-3.2l1,0.3l0.7-2.7l0.9,0.9l0.8-1.5l0.9,1.4l0.9-0.9l0.7,1.7l0.8-0.5l0.5,1.9l0.7-0.1l0.2,2h-0.5L34,51.6
					l-0.6,0.3l-0.6-1.7l-0.7,0.7l-0.9-1.5l-0.9,1.4l-1-1.2L28.9,52l-1.2-0.6l-0.5,3L26,54.3L25.6,57l1.4-0.1l0.2-2.5l1.4,0.2l0.2-2.6
					l1.2,0.8l0.3-1.9l1,1.2l0.6-1l0.7,1.5l0.6-0.5l0.5,1.6l0.4-0.1l0.3,1.6h-0.3l-0.4-1.5l0,0 M54.8,49.3v3.5h1.3v-3.5H54.8
					M33.7,53.5l-0.5,0.4l-0.6-1.5l-0.5,0.7l-0.8-1.2l-0.1,1.5l-1.1-0.8l0.1,2.3l-1.5-0.5l0.1,2.3h-1.7L26.8,59l-1.4,0.2l0.3-2.4
					l-1,0.1l0.4-2.8l-0.6,0.2l0.9-3.6l-0.3,0.1l1.2-3.4 M77.6,53.9l-0.5,1.2l-0.5-0.7L76,55.8l0.5,0.7l1,0.6H79l-0.1,2l2-0.1l0.6,2.2
					l1.9,0.1l-0.6-2.2l1.5,0.2l-0.4-2.4l1,0.1l-0.4-2.8l0.6,0.2l-1-3.6l0.3,0.1l-1.2-3.4 M31.8,53.9l0.4,1.2l-0.8,0.2l0.6,1.8h-1.5
					l0.2,2l-2-0.1l0.1-2.2l1.8,0.2l-0.3-2l1.2,0.3l-0.3-1.8L31.8,53.9l0.3-0.8l0.7,1.3l0.5-0.5l0.6,1.4l0.3-0.3l0.3,0.8L34.4,55
					l0.2,0.2v-1.7l0.5,0.2v-2.2l1,0.1l1.4,0.2l-0.2,2.5l-0.4,2.4l-1.2-0.6l0.3-2.2l-0.8-0.3L35,55.5l-0.4-0.4V55 M34.5,55.8l0.1,0.3
					l-0.1,0.2l-0.4,0.5l-0.7-1.1l-0.5,0.7L32.2,55l0.5-0.7l0.8,1.3l0.4-0.5l0.6,1.1l0.2,0.2v0.3l0.1,0.3l0.2-1.5l0.7,0.5l-0.4,1.9
					l1.1,0.9l-0.6,1.9l1.5,1.1l0.7-2.2l0.7-2.4l-1.8-0.4L36.4,59l1.6,0.8l1.9,0.8l-0.8,2.5v0.1l-0.6,2l1.9,1.2l2.3,1.3l1-2.6l2.5,0.7
					l0.4-3.2l2.4,0.2l0.3-3.2l2.2,0.1l0.2-3.3h1.6v-3.6h1.3v3.6h-1.3l-0.1,3.3h-1.7l-0.2,3.1L49,62.7l-0.3,3.1l-2.5-0.2l-0.7,3.6
					l-2.8-1.6l-1.6,1.8L39.5,68l-1.2-1.3l0.3-1.6l-1.9-1l0.8-2.2l1.8,1l2.2,1l-1,2.3L39.6,68l-1.2,0.7l-1-2l-0.7-2.6l-1.3-1.8
					l-0.7-1.5l0.1-0.2v-1.1l-0.5-0.1l0.5-1.3l0.1-0.2v-0.7v-0.4 M56,52.7l0.1,3.6h1.6l0.2,3.3l2.2-0.1l0,0l-0.3-3.4l-2.1,0.1l-0.1-3.6
					L56,52.7 M25.4,59.3L25.4,59.3l-1,0.1l0.4-2.4l-0.6,0.2l0.4-2.8l-0.3,0.1l0.9-3.6 M74.8,56.8v0.4l0.4-0.4l0.7-1.1l-0.4-0.5
					l-0.6,1.1l0.3,0.5l0.5,0.8l0.8-1.1l0.7-1.4l0.8,0.2l-0.6,1.8l-0.8,2h2.2l0.5,2.2l2.1-0.1l1,2.5l1.8,0.1l-0.9-2.5l1.5,0.2l-0.7-2.3
					l1,0.1l-0.4-2.4l0.6,0.1l-0.4-2.6l0.3,0.1l-0.9-3.6 M72,54.3l0.4,2.4l-1.7,0.6l-0.5-2.6L72,54.3 M74.9,55.8v0.3l0.1,0.3l-0.2,0.2
					M56.1,56.3l0.1,3.3h-1.4v3h-1.6l0.1-3.1h1.5v-3.3L56.1,56.3 M74.8,56.3v-0.2 M34.6,56.2l0.1,0.3v-1.2 M34.6,56.4L34.6,56.4
					M74.7,57.1v0.2l-0.5,0.8l-1.1,0.8l-0.6-2.2l1.2-0.6l0.4,1.9l0.4,1.5l0.2-1v-0.7l0.1-0.6 M34.6,57.2l-0.4-0.4l-0.5,0.7l0.1,0.1
					l-1,1.3L32,57l0.9-0.6l0.8,1.1 M25.3,59.3l-0.7,2.2l-1,0.2l0.7-2.3l-0.6,0.1l0.4-2.3l-0.2,0.1l0.4-2.8 M79.3,61.3L79.3,61.3l1,2.6
					l2.1-0.2l1.1,3.1h1.6l-1-3.1l1.4,0.2l-0.8-2.5l1,0.2L85,59.3l0.5,0.1L85.1,57l0.3,0.2L85,54.4 M34.8,57.1v0.2l0.5,0.8l-0.4,1.6
					l-0.2-1v-0.5h-0.1l-0.8-0.5 M74.7,57.8l0.1,0.4l0.8-0.5v-0.1 M70.8,57.3l0.6,2.5l-1.9,0.8l-0.7-2.8L70.8,57.3"/>
				<path class="st0" d="M34.6,58v0.2l0.1-0.9 M75.6,57.7l1,1.3l-1.3,0.5l-0.4-1.3h-0.1l-0.1-0.9 M34.7,58.2L34.7,58.2 M32.8,59
					L32.8,59h-2.2l-0.5,2.2l0,0H28l0.6-2.2l-1.8,0.1l-0.6,2.2l1.9-0.1l-1,2.5l-1.8,0.1l0.9-2.5l-1.5,0.2L23.9,64l1.4-0.2l-1,3.1h1.6
					l1.1-3.1l2.1,0.2l1-2.6 M74.6,59.7L74.6,59.7l-1,1.2L73,59l-1.6,0.8l0.8,2.2l-1.8,1l-0.9-2.5l-2.2,0.7l0.8,2.8l-2.4,1l-0.6-3
					l-2.3,0.5l-0.4-3.2l-2.3,0.2 M32.8,59.1L32,61.6l1.4,0.3l0.6-2.3L32.8,59.1 M56.1,59.6l0.1,3.1H58l-0.2-3.1H56.1 M30.1,61.3
					l1.9,0.2l-1,2.7l-1.9-0.3L27.9,67L26,66.8l-1.1,3.4l-1.4-0.1l0.8-3.3L23,67l0.8-3.1l-1,0.2l0.8-2.5l-0.4,0.2l0.7-2.3l-0.2,0.1
					l0.2-2.3 M76.6,59l0.8,2.5L76,61.8l-0.6-2.3h-0.5l-0.1-0.9 M34.6,59.5v-0.9 M79.3,61.2l-1.9,0.2l1,2.7l1.9-0.4l1.2,3.2l1.9-0.2
					l1.1,3.3h1.4L85,66.8l0,0l1.3,0.2l-0.8-3l1,0.2l-0.8-2.5l0.6,0.2l-0.8-2.4l0.3,0.1l-0.3-2.3 M34.6,60.7l0.3-0.9v-0.2 M74.5,59.8
					l0.3,1v-1.2 M34.9,59.8l0.9,1.1l-0.6,1.5L34.8,63l-0.2-0.5v-0.2l-0.1-0.8v-0.6 M74.8,60.7l0.1,0.3l-0.1-0.1V60.7 M76,61.8L76,61.8
					l-0.9,0.3L75,61.5V61 M22.9,61.9l-1,2.7l0.3-0.2l-0.8,3l0.6-0.2l-0.6,3.1l0.9-0.1l-0.4,2.9h1l0.6-3l-1.1,0.1l0.7-3.2l-0.9,0.2
					l0.7-3l-0.6,0.2l0.9-2.6L22.9,61.9l0.7-2.4 M73.6,60.9l0.6,1.5l-1.3,1.8L72.1,62L73.6,60.9 M86.6,62l0.3,0.7l0.6,1.8l-0.3-0.2
					l0.8,3.1l-0.6-0.2l0.6,3.1l-1-0.1l0.5,2.9h-1l-0.6-3l1.1,0.1L86.4,67l1,0.2l-0.8-3l0.6,0.2l-0.8-2.6L86.6,62l-0.7-2.4 M34.6,61.5
					l-0.1,0.6l-1-0.3l-1,2.9L31,64.2l-1.4,3.2L27.9,67l-1.3,3.3l-1.7-0.1l-0.8,3.1l-1.2-0.1l-0.4,2.5h-0.8l0.3-2.5h-0.9l0.4-2.8
					l-0.6,0.1l0.6-3.1l-0.4,0.2l0.9-3l0,0 M76,61.8l1,2.9l-0.9,0.5l-1-3.1l-0.3,0.2l0.1-0.8 M26.6,70.4L26.6,70.4l1.6,0.4l1.5-3.3
					l1.4,0.6l1.4-3.3l0.9,0.5l1-3.1l0.2,0.1 M74.8,62.3v0.2l-0.1,0.6l-0.5-0.7l0.6-1.4 M74.8,62.5l0.1,0.4v0.2L74.7,63L74,64.9l-1-0.8
					l0,0l-1.9,1L70.4,63l-2.2,1l1,2.3l0,0l-2.3,1.2l-1-2.6l-2.5,0.7l-0.5-3.3l-2.4,0.2l-0.3-3.2 M54.8,62.6v2.9l1.7,0.1l-0.1-2.9
					L54.8,62.6 M51.4,62.7l-0.4,3l1.9-0.1v2.8l1.8-0.1v-2.8L53,65.6l0.1-2.9h-1.8 M60.4,62.6l0.4,3.2l-2.3-0.1l0.2,2.9l-1.9-0.2
					l0.1,2.8L55,71.1v-2.7l1.8,0.1l-0.1-2.8l1.9,0.1l-0.2-3l2.3-0.1 M34.9,63L34.9,63l-0.1,0.2L34.6,63l0.1-0.4 M87.5,64.5L87.5,64.5
					l-0.6-1.8 M87.5,64.6l1,3.2l-0.2-0.2l0.7,3l-0.5-0.2l0.5,2.7h-0.7l0.3,2.4l-0.9,0.1l0.1,1.9l-0.7-0.3l-0.2-1.6L86,75.8l-0.7-2.6
					l-1.4,0.2l-1.2-3.1l-1.5,0.5l-1.5-3.3L78.3,68l-1.4-3.3l1.4-0.5l1.3,3.2l1.8-0.4l1.3,3.3l1.7-0.2l0.8,3.1l1.2-0.1l0.4,2.5h0.8
					l-0.3-2.5h0.8l-0.2-2.8l0.6,0.2l-0.6-3l0.4,0.2L87.5,64.6 M36.5,64.2l-1,0.8l-0.8-1.8 M38.2,66.6l-1,0.1l-1,0.3l-0.7-2.1l-0.4,0.2
					l-0.5-2 M72.9,64.1l-0.7,2.5l1,0.3l0.8-2.1l0.4,0.2l0.5-2 M21.1,67.6L21.1,67.6l-0.7,3.1l0.5-0.2l-0.5,2.6h0.7l-0.3,2.4l0.9,0.1
					l-0.1,1.9l0.7-0.3l0.3-1.6l0.8,0.2l0.7-2.6l1.4,0.2l1.4,0.4l1.4-3l1.3,0.5l1.5-3.3l0.9,0.6l1.4-3.3l0.3,0.3l0.8-2.6 M87.2,77.3
					l-0.6,0.6L86,76l-1.1,0.1l-1-2.7l-1.4,0.4l-1.4-3l-1.2,0.4l-1.5-3.3l-0.9,0.6l-1.4-3.3l-0.3,0.3L74.9,63 M48.7,65.8l-0.2,3.1
					l2.3-0.2l0,0l0.2-2.9H48.7 M60.7,65.8l0.2,3.1l3,0.3l-0.7-3.6L60.7,65.8 M69.1,66.3l1.9-1.2l0.3,1.5l1,0.1l-1,1.9L70.1,68
					L69.1,66.3 M50.8,68.6l-0.2,2.9l-2.2,0.3l0.1-3l-3,0.3L42.6,71l-1.5-1.7l-1.8,1.1l-1.1-1.8l-1,0.4l-0.9-2.1l-0.6,0.2l-0.6-2
					M66.7,67.5L66.7,67.5l1.6,1.8l1.8,1.1l1.1-1.8l1.1,0.4l0.8-2.1l0.6,0.2l0.6-2 M21.1,67.7l-0.2,0.1l1-3.2 M66.7,67.6l-2.8,1.6
					l2.9,1.9l1.5-1.7L70,68l1.2-1.3 M22.2,77.3l0.6,0.6l0.6-1.9l1.1,0.2l0,0l1.2,0.2l1.2-2.6l1.2,0.3l1.4-2.9l0.8,0.5l1.6-3.1l0.3,0.3
					l1.5-3.4 M50.9,68.6l2-0.2l-0.1,2.8l1.9-0.2v2.8l2.1,0.1l-0.1-2.7l2.1,0.3l-0.2-2.9l2.4,0.2l-0.1,3l-2.2-0.3l0.1,2.7l-2.1-0.3v2.5
					l-2.2-0.1v-2.5l-2.1,0.1l0.1-2.7l-2.1,0.3l-0.1,2.7l2.1-0.3l-0.1,2.6l2.1-0.1v2.4l2.2,0.1l-0.1-2.5l2.1,0.3l-0.1-2.5l2.1,0.4v-2.7
					l2.4,0.5l0.5-3.2 M89.1,70.7L89.1,70.7l0.6,2.6l-0.6-0.1l0.4,2.3l-0.9,0.1l0.3,2h-1l-0.3,1.8l-1-1.6l-1,0.3l-0.7-2l-1.2,0.2
					l-1.2-2.5l-1.2,0.3l-1.4-2.9l-0.8,0.5l-1.6-3.2l-0.3,0.4l-1.5-3.4 M24.5,76.1l-0.8,2.1l1.1,0.2l0.8-2l1.1,0.2l1.3-2.5l0.9,0.3
					l1.4-2.7l0.3,0.3l1.6-3.1 M45.5,69.2l0.5,3.2l-2.6,0.8l-0.9-2.1l-2.2,1l-1-1.7L38,70.8L37.1,69l-0.7,0.1l-0.7-1.9 M61,77.1
					L61,77.1l0.1,2.3l-2-0.2l0.1,2.4L57,81.5l0.1,2.4h-2.3v-2.6l-2.3,0.1l0.1-2.5l-2.2,0.2l0.1-2.4l-2,0.3v-2.4L46.3,75l-0.2-2.6
					l2.4-0.5v2.8l2.1-0.4l-0.1,2.5l2.2-0.3V79l2.2-0.1v2.5l2.2,0.1V79l2.2,0.2l-0.1-2.4L61,77.1l0.1-2.5l2.2,0.4l0.2-2.6l2.6,0.8
					l0.9-2.1l2.2,1.1l1-1.7l1.3,0.4l0.9-1.8l0.7,0.1l0.7-1.9 M73.1,69.1l-0.8,1.7l-0.1,0.1h-0.7l-0.9,1.7l-1.5-0.5l-0.8,1.8L66,73.1
					l-0.5,2.2l-2.4-0.4L63,77.2l-2.1-0.3 M20.3,70.7L20.3,70.7l-0.4,0.5l1-3.4 M37.3,70.9L37.3,70.9l0.8,1.7l0,0l0.8-0.1l0.7,1.7
					l1.5-0.4l0.6,1.9l2.2-0.4l0.2,2.1l2.2-0.2v2.1l2-0.1l-0.1,2.3h2.2l-0.3,2.3h2.3l-0.1,2.5l2.5-0.1v-2.4h-2.4l0.1-2.4l-2.2,0.2
					l0.1-2.4l-2,0.2l0.1-2.3l-2.1,0.3l-0.1-2.3l-2.4,0.4l-0.4-2.3L41.2,74l-0.8-1.9l-1.5,0.5L38,70.9L37.3,70.9l-0.8-1.8 M19.9,71.2
					l-0.2,0.5l-0.4,1.7l0.4-0.2l0.6-2.5 M89.1,70.8l0.4,0.5l-1-3.4 M89.6,71.2l0.1,0.2l0.5,1.9l-0.4-0.2l0.5,2.1h0.5l-0.5-1.8
					l-0.1-0.1 M87.6,79.4L87.6,79.4L86.3,80l-0.7-1.8l-1.1,0.2l-0.8-2l-1.2,0.2l-1.2-2.5l-0.9,0.3L79,71.7L78.8,72l-1.6-3.1 M30.7,72
					l-1.3,2.6 M38.1,72.6L38.1,72.6l-0.7-1.5 M72.2,70.9l-0.8,1.7l0.2-0.1l0.7-1.8 M38.2,72.7l0.6,1.7l0.9-0.1l0.6,1.7l1.5-0.2
					l0.3,1.9l2-0.2v1.9l2.2-0.1l-0.1,2l2.1,0.1L48,83.5l2.2,0.1l-0.3,2.5l2.4,0.2l-0.1,2.2h2.6v-2.3h2.4l-0.1-2.4h2.3l-0.2-2.2h2.1
					l-0.2-2.2l2,0.1v-2.1l2.2,0.2l0.2-2.1l2.1,0.4l0.6-1.9l1.5,0.4l0.7-1.7l0.8,0.1l-0.6,1.7l-0.8-0.1l-0.6,1.7l-1.5-0.3l-0.3,2
					l-2.1-0.2v1.9l-2.2-0.1l0.2,2h2.2l-0.1-1.9l2,0.1l0.1-1.9l1.6,0.1l0.3-1.8l1,0.1l0,0l0.4-1.7l0.3-0.1l0.6-1.8 M19.3,73.4l-0.1,0.1
					l-0.6,1.8h0.5l0.5-2.1l0.6-0.1l-0.3,2.4l-0.7-0.1l-0.7,1.8l-0.6-0.1l-0.4-0.2l0.9-1.6l0.6-1.5v-0.2l0.7-1.9 M90.2,73.6l0.2,0.3
					L90.2,73.6l-0.6-2.1 M24.5,76.1l1-2.6l1.2-3.1 M90.5,73.8l0.6,1.5h-0.3l0.8,1.7L91,77.1l-0.7-1.8l-0.7,0.1l0.5,1.9l-1.2,0.3
					l0.3,1.8l-1.6,0.1l0.7,2L87,81.8L86.3,80l-1.1,0.2l-0.7-1.8l-1,0.1l-1-2l-0.9,0.2l-1.2-2.3l-0.3,0.2l-1.2-2.5 M19,73.9l0.3-0.3
					M38.6,74.3L39,76h0.3l-0.5-1.7H38.6L38,72.5 M19.1,73.6l-0.7,1.5l-1,1.5L16.2,78v0.3l0.3,0.3l0.5,0.2l0.8,0.1l0.7-1.7l0.9,0.2
					l0.5-1.9l0.9,0.1l-0.3,2h1l0.2,1.8l1.3,0.5l1.1,0.2l0.7-1.7l1,0.1l1-2l1,0.1l1.2-2.3l0.3,0.2 M91.2,75.1l1.1,1.4v0.2l-0.2,0.2
					l-0.9-1.6V75.1L91.2,75.1l-0.7-1.6 M29.1,75.1l0.2-0.5 M81.2,76.8L81.2,76.8l0.8,1.9l0.7-0.1l1,1.7l0.7-0.2l0.8,1.4l0.8,0.3
					l-0.7-1.7h-0.9l-0.8-1.6l-0.8,0.1l-1-1.9L81.2,76.8l-0.9-1.7l-0.2-0.5 M29.1,75.1l-0.9,1.7l-0.5-0.1l-1,1.9l-0.9-0.1L25,80.1h-0.9
					l-0.7,1.7l0.8-0.3l0.7-1.3l0.6,0.2l1-1.8l0.7,0.1l0.8-1.8v-0.1 M16.2,78l-0.9,1.7l0.1,0.2V80l0.3,0.2l0,0l0.6,0.2l0.7-1.8l0.9-1.7
					l0.8-1.7h-0.3L18.3,75 M70.2,76h0.3l0.4-1.7 M39.2,76.1l0.2,1.7h1L40.1,76L39.2,76.1 M92.1,76.5l1.1,1.4v0.3l-1-1.6l-1-1.6
					M17.3,76.6L17.3,76.6l0.2,0.2l-1,1.7l-0.8,1.7 M92,76.9l1,1.7l-0.5,0.2l-0.9-1.7L92,76.9 M81.2,76.9v0.2l-0.8-1.9 M28.3,76.9
					l0.1,0.2l0.8-2 M91,77.2L91,77.2l-0.9,0.2l0.5,1.8l1.1-0.2L91,77.2 M24.3,81.5l0.3,0.5l1.1-1.7l0.9,0.1l0.8-1.8l0.4,0.2l0.3-1.1
					l0.1-0.7 M15.7,80.3l-0.7,2l0.7,0.3l0.6-2l1,0.2l0.6-1.8l1.1,0.2l0.5-1.8l1.1,0.2l-0.3,1.8l-1.4-0.3L18.5,81l-1.2-0.2l-0.6,2
					l-1-0.2l-0.6,2.3l-0.7-0.3L14,84.3v-0.2l-0.1-0.2l0.7-2.2l0.7-1.9 M39,77.7l-0.1,1.6l0.5,0.1v-1.6L39,77.7L38.9,76 M85.1,81.5
					L84.8,82l-1.1-1.7l-0.8,0.2L82,78.8l-0.4,0.1l-0.3-1.1l-0.1-0.6 M70.2,76.2L70,77.9l0.4-0.1l0.1-1.6 M40.5,77.9l0.1,1.7h1.6
					l-0.1-1.9L40.5,77.9 M70.5,77.7l0.1,1.6l-0.5,0.2v-1.7H69v1.7l1.1-0.1l0.2,1.6l0.5-0.2l-0.3-1.5 M14.6,81.7L14.6,81.7v0.2l0.3,0.3
					l-0.6,2.3l-0.6,2.7l0.7,0.3l0.6-2.7l1,0.3l0.6-2.4l1.2,0.2l0.5-2.1l1.3,0.3l0.4-1.8l1.6,0.1l1-1.6l1,0.4L23,80l-0.6,1.8l-1.2-0.3
					l-1.4-0.3l-0.6,2.1l-1.3-0.2l-0.7,2.4l-1.1-0.2l-0.7,2.8l-1-0.3l-0.6,3.1l-0.7-0.4l0.7-3l-0.4-0.4V87l-0.1-0.3L14,84 M15.4,79.9
					l0.8-1.6l1.1-1.6 M93.3,77.9l0.9,1.6v0.4l-0.9-1.7L93,78.5l0.8,1.8l0.3-0.3l0.7,1.9v-0.4l-0.7-1.9 M92.4,78.7L92.4,78.7l-0.8,0.2
					l0.6,1.8l1-0.2L92.4,78.7 M27.9,78.9L27.9,78.9l0.1,0.3l0.2-1.4 M81.6,78.9L81.6,78.9l-0.1,0.3l-0.2-1.4 M90.6,79.1L91,81
					l-1.4,0.3l-0.4-1.8L90.6,79.1 M39.4,79.4L39.2,81l1.1,0.2l0.2-1.6L39.4,79.4 M42.1,79.6L42,81.3l2,0.1l0.2-1.8L42.1,79.6
					M70.8,80.8l0.3,1.6l-0.5,0.2L70.3,81l-1.2,0.2l-0.2-1.6h-1.6l0.2,1.7l1.6-0.2l0.3,1.6l1.1-0.2l0.3,1.7l0.5-0.2
					c0-0.1-0.2-1.7-0.2-1.7 M93.7,80.3l0.7,2l-0.7,0.3l-0.6-2L93.7,80.3 M21.8,79.5l-0.6,2.1l-0.7,2l1.2,0.3l0.8-2l1.1,0.1l-0.5,1.8
					l1.5-1.7l0.9,0.2l1.1-1.8h0.7l0.6-1.6 M94.9,81.5l0.7,2.3v0.3L94.9,82l-0.4,0.3l0.6,2.3l-0.7,0.3l-0.5-2.3l-1,0.2l-0.6-2.1
					l-1.2,0.2l0.5,2.1l-1.3,0.3l-0.6-2.1l-1.5,0.3l0.7,2l-1.1,0.3l-0.8-2h-1.1l0.4,1.9L84.9,82L84,82.2l-1.1-1.7l-0.7,0.1L81.6,79
					M28,79.2l-0.5,1.4l-0.7,1.6l-0.3,0.1l0.9-1.8h0.2 M82,80.6l0.8,1.6l0.3,0.1l-0.9-1.7H82l-0.5-1.4 M38.7,80.8l-0.3,1.5l0,0
					l0.6,0.2l0.3-1.6L38.7,80.8l0.3-1.5 M38.4,82.4l-0.2,1.7l0.5,0.2l0.3-1.7l1.1,0.2l1.6,0.2l0.4-1.7l-1.6-0.1l-0.4,1.6l-0.4,1.7
					l-1-0.2L38.5,86l-0.4-0.2l0.1-1.7 M71.4,84.1l0.1,1.7L71.1,86l-0.2-1.7l-1,0.2l-0.5-1.7L67.8,83l-0.3-1.7l-2,0.1l0.3,1.8l2-0.2
					l0.4,1.8l1.5-0.3l0.3,1.7l1-0.3v1.6l0.4-0.2l0.1-1.6 M38.1,85.8l0.1,1.6l0.4,0.1v-1.6l1,0.3l0.2-1.7l1.5,0.3l0.4-1.8l2,0.2
					l0.3-1.8h2.2l-0.4,2l-2.2-0.2l-0.4,1.9l-2-0.3l-0.3,1.7l-1.5-0.3v1.6l-0.9-0.3l0.1,1.5l-0.4-0.2l-0.1-1.5 M63.3,81.5l0.3,2
					l-2.1,0.2l-0.2-2.1L63.3,81.5 M14.6,81.9l0.7-1.8 M17.2,85.4L17.2,85.4l1.1,0.3l0.8-2.5l1.3,0.3l-1,2.5l1.2,0.3l1-2.4H23l0,0
					l1.2,0.5l1.2-2h1l-1.1,2l0.4-0.2l1.1-1.9 M95.5,84.1L95.5,84.1l-0.4,0.4l0.6,2.8l-0.6,0.3l-0.7-2.7l-1,0.3l-0.6-2.4L91.6,83
					l0.7,2.4l-1.2,0.4l-0.9-2.5l-1.3,0.3l1,2.5l-1.2,0.3l-1-2.4h-1.4l-1.2,0.5l-1.2-2h-1l1.1,2l-0.4-0.1l-1-1.9 M71.4,87.4v1.5
					l-0.5,0.2l0.1-1.5l-0.9,0.3v-1.6l-1.4,0.3l-0.4-1.8l-2,0.3l-0.4-1.9l-2.2,0.3l0.4,2l2.2-0.4l0.4,1.9l1.9-0.4l0.1,1.7l1.4-0.4v1.5
					l0.9-0.3l0.1,1.2l0.4-0.1l-0.1-1.3 M38.2,88.9l-0.1,1.3l0.4,0.1l0.1-1.2l0.8,0.2v-1.5l1.4,0.4l0.1-1.6l1.9,0.4l0.4-1.8l2.2,0.3
					l0.4-2l2.1,0.2l-0.3,2.1l-2.2-0.3L45,87.4L42.8,87l-0.2,1.7l-1.9-0.5v1.5l-1.3-0.4v1.2l-0.9-0.3l-0.1,1.3h-0.5l0.1-0.8l0.1-0.5
					M61.5,83.7l0.3,2.2l-2.3,0.2l-0.1-2.3L61.5,83.7 M14,84.1l0.6-2.2 M86.3,83.7l1.2,2.7l-1.2,0.2l-1.2-2.4h-1l1.2,2.4L85,86.5
					l-1.3-2.4 M25.7,84.1l-1.3,2.4l-0.3,0.1l1.2-2.4h-1l-1.2,2.4l-1.2-0.1l1.2-2.7 M95,87.7L95,87.7L94,88l-0.7-2.8l-1.1,0.3l0.8,2.8
					l1-0.3l0.8,3.1l0.8-0.3L95,87.7 M71.4,90.2l0.1,0.4l0.1,0.9l-0.5,0.1L71,90.3l-0.9,0.3v-1.2l-1.3,0.4v-1.5l-1.9,0.4L66.7,87
					l-2.2,0.5l-0.4-1.9l-2.2,0.3l0.3,2l2.3-0.4l0.2,1.8l2.2-0.5l0.1,1.5l1.8-0.5v1.3l1.3-0.4l0.1,1.2l0.9-0.2l0.2,1.5l0.6-0.1
					l-0.2-1.5l0.1,0.1l-0.2-1 M17.2,85.5l-0.8,2.8l1.1,0.3l0.9-2.8l1.2,0.3l-1,2.8l-1.1-0.2l-1,3.1l-1-0.2l-1-0.3l0.8-3.1l1,0.3
					l-0.9,3.1l-0.9,3.1l-0.8-0.4l0.8-3.1l-0.7-0.4l-0.7,3l-0.6-0.4l0.7-3.1l-0.4-0.4l0.7-3.1 M95.5,84.3l0.6,2.7l-0.4,0.4l0.6,2.9v0.1
					l-0.6,0.4l0.7,3.1l-0.7,0.3l-0.8-3.1L94,91.4l-0.9-3.1l-1,0.3l-1-2.8L89.9,86l1,2.8l1.1-0.3l1,3.1l1-0.2l0,0 M37.9,90.7l-0.2,0.8
					l0.1-0.1l-0.3,1.4l0.6,0.1l0.2-1.5l0.9,0.2l0.2-1.3l1.3,0.4v-1.3l1.8,0.5v-1.5l2.2,0.5l0.2-1.8l2.3,0.5l0.3-2.1l2.3,0.3L49.6,88
					l-2.4-0.4L47,89.8l-2.3-0.6l-0.1,1.6l-2.1-0.6l-0.2,1.3L40.6,91l-0.1,1.2l-1.2-0.4l-0.2,1.4L38.2,93L38,94.6l-0.6-0.1l0.2-1.6
					L37.3,93l0.4-1.4 M59.6,86.1l0.2,2.2l-2.5,0.2l-0.1-2.3L59.6,86.1 M96.2,86.5l0.8,3.1l-0.2,0.3l-0.7-3L96.2,86.5l-0.6-2.7
					M13.2,86.6l-0.7,3.1l0.2,0.4L12,93.2l-0.7,2.6l0.4,0.3l0.6,0.4l0.7-2.7l0.7,0.3l-0.8,2.7l0.8,0.3l0.9-2.7l1,0.2l1-3l1,0.2l1-3
					l1.1,0.2l1.1-2.7l1.2,0.2l-1.2,2.7L19.7,89l-1.1,2.9l-1-0.1l-1,3l-1-0.2l-1,2.7l-0.9-0.2l-0.8,2.2L12.2,99l-0.6-0.3l-0.5-0.3
					l0.6-2.3l0.7-2.7L11.9,93l-0.2-0.3l0.7-3.1 M94,91.3l0.9,3.1l-1,0.2l-1-3l-1,0.2l-1-3L89.8,89l-1-2.7l-1.2,0.2l1.1,2.7l1.1-0.2
					l1,2.9l1-0.1l1,3l1-0.2l1,2.7l0.9-0.3l-0.8-2.6l0.8-0.3l0.8,2.6l0.6-0.3l-0.7-2.6l0.6-0.4l-0.7-3.1 M11.8,92.7l-0.6,2.8l0.1,0.3
					l-0.6,2.4l0.4,0.3l-0.5,1.9l0.5,0.3l0.5-1.9l0.7-2.2l0.6,0.3l-0.7,2.3l-0.5,1.9l0.7,0.3l0.5-1.8l0.8,0.3l0.8-2.3l0.9,0.2l1-2.7
					l1,0.1l1.1-2.9l1,0.1l1.2-2.9l1.2,0.1l1.2-2.7h1l-1.4,2.6h0.1l1.5-2.7 M96.8,90L96.8,90l0.7,3.1v0.1l0.6,2.7l-0.4,0.3l-0.6,0.3
					l0.7,2.2L97.2,99l-0.7-2.2l-0.8,0.3l0.8,2.2l-0.8,0.3l-0.8-2.3L94,97.5l-1.1-2.7l-1,0.1l-1-2.9l-1,0.1l-1.2-2.9l-1.2,0.1l-1.2-2.6
					h-1l1.3,2.6l-0.1-0.1L85,86.6 M13.4,86.8l0.6-2.6 M62.2,87.9l0.2,1.9L60,90.3l-0.2-1.9L62.2,87.9 M37.3,93L37.3,93L37,94.6h0.4
					l-0.1,1.9H38l0.1-1.8l0.9,0.2l0.2-1.6l1.2,0.3l0.2-1.4l1.7,0.6l0.2-1.3l2.2,0.8l0.1-1.4l2.3,0.7v-1.8l2.5,0.5l0.1-1.9l2.5,0.2
					l-0.1,2l-2.5-0.2v1.9l-2.5-0.6v1.7l-2.5-1l-0.3,1.4l-2.1-1l-0.4,1.3l-1.5-0.6l-0.2,1.6l-1.2-0.3v1.8L38,96.4l0.1,2.3h-0.8v-2.3
					l-0.4,0.1l0.2-1.9H37L37.3,93 M62.4,89.8L62.4,89.8v1.8l-2.5,0.5v-1.9l-2.6,0.2l-0.1-2h-2.6v0.1v1.9l-2.7-0.1v1.9l-2.6-0.3
					l0.3,1.9l-2.7-0.7l-0.2,1.6l-2.5-1.1l-0.6,1.3l-1.8-0.8l-0.4,1.5L40,95.1L39.9,97l-1-0.3l0.1,2.2l-1-0.1l0.3,2.9h-0.9l-0.1-2.9
					l-0.5,0.1l0.1-2.4h-0.1l0.2-1.9 M86.7,89.3l0.7,1.3l0.8,1.4l0.7,0.1l-1.3-2.8H86.7L86.7,89.3 M22.7,89.3L22,90.8l-0.7,1.3h-0.6
					l1.4-2.8H22.7 M62.4,89.7l2.3-0.5l0.1,1.6l2.2-0.6l0.1,1.3l1.7-0.5l0.1,1.3l1.3-0.4l0.2,1.4l0.9-0.2l0.2,1.6l0.6-0.1l-0.2-1.7l0,0
					M96.3,90.3l0.5-0.3 M64.8,90.8L64.8,90.8l-2.4,0.7l-0.1,1.7l-2.7,0.9l0.2-1.9l-2.6,0.3v-1.9l-2.6,0.1v1.9H52l0.2,2l-2.5-0.4
					l0.2,2.3l-3-1.7l-1,1.3l-2.2-1.2L43,96.3l-1.6-0.7l-0.3,1.8L39.9,97l0.1,2.2l-1-0.3l0.3,2.9l-1-0.1l0.2,3.2l-1-0.1l-0.1-3.2h-0.7
					v-2.8l-0.3,0.1l0.2-2.4 M87.4,90.7l-0.9-1.4 M22,90.8l1-1.5 M71.7,91.6l0.4,1.4h-0.2 M11.1,95.5l-0.5,2.4l-0.5,1.9l-0.4,1.4
					l-0.2,0.3v0.1l-0.1,0.2v0.2l-0.1,0.3l0.2,0.5l0.4,0.4l0.6,0.4l0.6,0.3l0.3-1.4l0.4-1.6l-0.6-0.2l-0.4,1.6l0.6,0.3l0.7,0.4l0.3-1.8
					l0.8,0.3l0.6-1.8l0.8,0.2l0.9-2.3l0.9,0.1l1.1-2.8h0.9l1.2-2.9h1l0,0 M88.9,92l1.4,2.8h0.8l-1.2-2.9L88.9,92 M71.4,94.5L71.4,94.5
					l-0.9,0.2l-0.1-1.4l-1.2,0.4l-0.3-1.5l-1.6,0.6l-0.2-1.3l-2.2,0.8l-0.1-1.5 M71.4,94.7l0.1,1.9l-0.9,0.2V95l-1.2,0.3l-0.2-1.6
					l-1.5,0.4l-0.4-1.3l-2.1,0.8l-0.3-1.3l-2.5,1l0.2,1.6l-3.1,1.6l0.2-2.3l-2.5,0.4l0.2-2h-2.6v2h-2.5l0.3,2.1L50,96.5l-2,1.2l-2-1.5
					l-1.2,1.3l-1.7-1l-0.5,1.7l-1.4-0.6v2.1l-1.1-0.4l0.4,2.8l-1-0.2l0.3,3.2h-1.1l-0.1,3.2l-1.1-0.3l0.1-3.1l-0.8-0.2v-3h-0.5
					l0.1-1.6l0.1-1.1 M97.5,93l0.2-0.3L97,89.6 M72.5,94.6l-0.2-1.4l-0.1-0.3 M72.2,93.2l0.3,1.4h-0.1 M97.7,92.7l0.6,2.8l0.6,2.3
					l-0.2,0.3l-0.4,0.3l-0.6-2.3L97,93.4l0.5-0.4 M67.6,94L67.6,94l-1.9,0.9l-0.6-1.3l-2.5,1.2l1,1.3l2.2-1.1l0.7,1.4l1.6-0.7L67.6,94
					M72.5,94.6l0.2,1.9h-0.2l0.1,2.4l-0.4-0.1l-0.2,2.9h-0.9l0.3-2.9h0.8v-2.4h0.4L72.5,94.6L72,94.5l0.1,1.9h-0.7v2.4l-0.9,0.1
					l0.1-2.2L69.5,97l-0.1-1.7L68,95.8l0.3,1.8L67,98.2l-0.6-1.7l-1.7,1l-1.2-1.3l-2,1.5l-2-1.2l-2.7,0.2l0.3-2.1h-2.4v2.7l-2.2-0.6
					l0.4,2.2h1.8v-1.6l2.1-0.6l-0.3,2.1h-1.8v1.9l-1.4,0.2L53,98.8L51.2,99L50,96.5 M89.9,94.8l1.5,2.8l0.3,0.1L90.3,95L89.9,94.8
					l-1.6-2.7 M20.6,92.1l-1.4,2.8l0.5-0.1l1.6-2.7 M19.7,94.8l-1.6,2.8l-0.4,0.1l1.4-2.7h-0.8l-1.2,2.8h-0.7l-1,2.4l-0.8-0.2
					l-0.6,1.8l-0.8-0.3l-0.3,1.4L12,103l0.6,1.2l0.3-0.4v-1l0.7,0.2l0.3-1.4l0.7,0.2l0.6-1.8h0.6l1.1-2.4l0.6-0.1l-1.2,2.5l0.3-0.1
					l1.3-2.5 M98.3,95.5l-0.2,0.3l0.6,2.3l0.5,1.9l-0.4,0.3l-0.5-1.9l-0.5,0.3l0.5,1.9l-0.6,0.3L97.2,99l-0.7,0.3l0.5,1.8l-0.8,0.3
					l-0.6-1.8l-0.8,0.2l-0.9-2.2l-0.8,0.1l-1.2-2.8H91l1.3,2.8h-0.6l1.2,2.5l-0.3-0.1l-1.3-2.5 M69.5,97v2.2l-1.1,0.4l-0.1-2.1
					L69.5,97 M94.9,99.9L94.9,99.9l-0.7,0.2l-1-2.4h-0.7l1.1,2.4l0.6-0.1l0.6,1.8l0.7-0.2L94.9,99.9 M10.6,97.9l0.2,0.2l-0.5,1.9
					l-0.2-0.2 M59.5,96.4l-1.1,2.5l-1.9-0.1l-0.3,2.1l-1.5-0.1v1.8l-1.1,0.3l-0.3-1.9l-1.5,0.2l-0.7-2.1l-1.8,0.5l-1.4-2l-1.6,1.1
					l-1.6-1.3l-0.9,1.5l-1.4-0.8l-0.3,1.9L41,99.5l0.2,2.6l-1-0.2l0.4,3h-1.1l0.1,3.1l1.2-0.2l-0.2-2.9h1l-0.4-2.9l1,0.2l-0.1-2.4
					l1.2,0.6l0.5-1.8l1.3,1l1.2-1.3l1.2,1.6l1.7-0.8l0,0 M72.7,96.6L73,99h-0.3v2.8H72l-0.1,3.1l-1,0.1l0.2-3.2l-1,0.1l0.3-2.9l-1,0.3
					l-0.3,2.8l-1,0.2l0.3-2.6l-1.1,0.5L67,98.3l-1.4,0.8l-0.9-1.5l-1.6,1.3l-1.6-1l-1.3,1.8l-1.8-0.5l-0.7,2.1l-1.6-0.2l-0.2,1.7
					l-1.1-0.2v1.6l0.9,0.4l0.2-1.8l1.3,0.3l0.5-1.9l1.7,0.4l0.8-2l1.7,0.8l1.2-1.6l1.2,1.2l1.3-1.1l0.5,1.8l1.2-0.6l-0.1,2.4l1-0.2
					l-0.4,2.8l-1-0.1l0.3-2.5l-1,0.1v-1.9l-1.1,0.7l-0.7-1.3l-1.1,1.3l-1.3-1l-1,1.8l-1.6-0.5l-0.8,1.9l-1.5-0.3l-0.5,1.8l-1-0.4
					l-0.2,1.5l-0.6-0.5l0,0v-1.4l-0.9,0.4l-0.2-1.7l-1.3,0.3l-0.6-1.9l0,0 M73.1,99.9l0.1,1.8l0.2-0.1L73.1,99.9L73,99 M99.4,99.8
					l0.4,1.3v0.2l-0.1,0.3l-0.4-1.5L99.4,99.8l-0.5-1.9 M13,103.7l0.6,0.3l0.1-1l0.6,0.2l0.4-1.4l0.5,0.1l0.7-1.9h0.5l-0.8,1.9h-0.4
					l-0.4,1.4l-0.5-0.1l-0.2,1l-0.6-0.2l-0.2,0.7l-0.7-0.6l-0.2,0.8l-0.8-0.6l-0.5-0.5l-0.2,1.2l-0.7-0.5l0.3-1.1l0.3-1.3l-0.5-0.3
					l-0.4-0.2l-0.1-0.3L9.5,102 M92.7,100l0.9,1.9h0.2l-0.9-1.9h0.6l0.7,1.8l0.5-0.1l0.4,1.4l0.6-0.2l-0.3-1.4l0.8-0.2l0.3,1.5
					l0.9,0.1l-0.3-1.7l0.7-0.3l0.4,1.6l0.6-0.3l-0.4-1.6l0.5-0.3l0.4,1.6l0.4-0.3l0.4,1.1l0.2-0.5l-0.1-0.3v-0.1l-0.1-0.2l-0.3-0.5
					M10.4,100.1l-0.5,1.5l-0.4,1.1L9,103.6l0.5,0.6l0.4-1l0.4-1.2l0.4-1.6L10.4,100.1 M49.2,99.5l0.9,1.9l-1.6,0.5l-1-1.8l-1.4,1
					l-1-1.2l-0.7,1.4l-1-0.7l-0.1,1.9l-1-0.1l0.3,2.5l0.9-0.3l-0.3-2l0.9,0.1l0.3-1.3l0.8,0.9l0.9-1l0.9,1.5l1.4-0.7l0.8,1.9l1.6-0.4
					l-0.7-1.9l1.7-0.3 M36.1,101.6l-0.5,2.6l0.4,0.3l0.3-2.8L36.1,101.6l0.3-1.5 M73.2,101.7l0.3,2.8l-0.7,0.2v-3H73.2 M54.8,105.5
					l-0.1,1.3l0.5,0.6l0.2-1.4l0.9,0.6l0.3-1.7l1.4,0.4l0.6-1.9l1.6,0.4l0.8-1.9l1.4,0.7l0.9-1.5l1,1l0.8-0.8l0.2,1.3l0.9-0.1l-0.2,2
					l0.9,0.3l-0.4,2.4l1,0.4l0.4-2.7h1l0.4-3l1-0.2l-0.3,3.2h-1.1l-0.2,2.9l-1.1-0.3l-0.5,2.3l-1-0.7l0.5-2.1l-0.9-0.6l-0.8-0.6
					l0.4-1.9l0.2-1.5l-0.6,0.2l-0.5-0.6l-0.7,1.2l-1.1-0.7l-0.8,1.7l-1.4-0.5l-0.7,1.9l-1.5-0.5l-0.5,1.8l-1.2-0.5l-0.3,1.6l-0.7-0.7
					v-0.1 M100,101.6l0.9,0.4h0.1L100,101.6 M100.2,102l-0.3-0.7 M8.6,102l1-0.4 M9.6,101.7L8.8,102 M12.2,103l-0.3,1.4l-0.2,1.2
					l0.9,0.5l0.1-1.1l0.8,0.6l0.1-0.8l0.6,0.3l0.1-0.8l0.5,0.1l0.2-1l0.4,0.1l0.5-1.4h0.2l0.9-1.9 M8.7,102.1l-0.8,0.6v0.3l0.9-0.7
					l0.1,0.2l0.5-0.7L8.7,102.1L8.7,102.1L8.5,102l-0.8,0.6v0.1 M93.5,102l0.5,1.4h0.2l-0.5-1.4h0.4l0.4,1.4l0.5-0.1l0.2,1l0.6-0.2
					l-0.1-1l0.8-0.3v0.9l-0.6,0.4l0.1,0.7l0.7-0.6l-0.3-0.4 M101.1,102l0.7,0.7l-0.8-0.6l-0.1,0.2l-0.7-0.4l0.6,0.6l0.1-0.2l0.9,0.8
					v-0.2v-0.1l0.5,0.5l0.2,0.4l-0.6-0.8 M102.5,103.7v0.2l0.4,0.8l-0.5-1.2L102.5,103.7l-0.8-0.8l-0.1,0.4l-1-1v0.6v0.1l-0.2,0.6
					l-0.5-0.9l-0.4,0.4l-0.6,0.4l-0.3-1.3l0.5-0.3l0.4,1.2l0.4,1l0.5-0.6l0.6,1l0.3-0.6l-0.7-1 M54.6,105.5l-0.7,0.6l-0.2-1.6l-1,0.4
					l-0.5-1.8l-1.5,0.4l0.6,1.9l-1.5,0.4l-0.7-1.9l-1.4,0.5l-0.8-1.7l-1.1,0.7l-0.6-1.2l-0.5,0.6l-0.6-0.2l0.2,1.6l0.8-0.3l-0.3-1.1
					M9.3,102.2l-0.5,0.7v-0.5l-0.9,1L7.8,103l-0.7,0.9v-0.1l0.6-1.1 M8.8,102.9L8.8,102.9l0.2,0.6l-0.6,1l-0.3-0.7l-0.2-0.5l-0.8,1
					v-0.5l0,0 M97.3,103l0.3,1.4l-0.8,0.7l-0.1-0.8L97.3,103l0.8-0.4l0.3,1.3l0.6-0.3l0.3,1.1l0.6-0.5l0.5,1l0.6-0.6l0.6,1.1l0.5-0.6
					l0.2-0.8l0.1-0.4 M100.7,103l-0.5-0.7 M7.1,103.6l-0.2,0.6L6.6,105v0.4l0.6-1l0.2,0.7l0.4,0.6l0.6-1.2l0.6,0.6l0.5-1l0.6,0.5
					l-0.4,1.2l0.8,0.5l0.3-1.1l0.8,0.4l-0.2,1.1l1,0.4l0.1-1.1l0.8,0.4l0.1-0.9l0.6,0.2l0.1-0.7h0.5l0.1-0.8h0.4l0.2-1h0.2l0.5-1.4
					M94.1,103.3l0.2,1h0.1L94.1,103.3l0.4-0.1l0.2,1l0.5-0.1l0.1,0.8l0.6-0.3l0.1,0.8l0.8-0.6l0.1,1l0.9-0.5l-0.2-1.2l0.7-0.5
					l0.2,1.2l0.7-0.5l0.4,1.1l0.7-0.6l0.6,1.2l0.6-0.7l0.6,1.4l0.6-0.7l0,0l0.1-0.9v-0.2l0.1-0.1l-0.1-0.2 M6.6,105.3v0.2l0.1,0.9
					l0.7-1.3l0.7-1.2l0.7-0.9 M46,103.4l0.7,1.6l-1,0.6l-0.5-1.6L46,103.4 M102.9,104.8l0.5,1.5v0.1v0.5l-0.6-0.6l-0.7-1.3l-0.8-1.1
					l0.2-0.6l0.8,1l0.6,1 M55.2,107.5l-0.1,1.4l0.7,1l0.2-1.6l1.2,0.7l0.3-1.8l1.4,0.5l0.6-1.9l1.4,0.4l0.7-1.8l1.3,0.6l0.7-1.6
					l0.8,0.6l0.4-1.1 M6.9,104.2l0.2-0.4 M54.2,107.4L54.2,107.4l-0.7,0.8l-0.3-1.6l-1.2,0.5l-0.5-1.8l1.4-0.4l0.4,1.7l0.9-0.5
					L54.2,107.4l0.5-0.6v1.1l0.4,1v1v0.2l0.6,1.1l0.1-1.4l1.1,0.7l0.2-1.6l1.3,0.5l0.4-1.8l1.4,0.4l0.6-1.8l1.3,0.4l0.7-1.7l1,0.5
					l0.9,0.6l-0.5,1.8l0.8,0.7l0.5-1.9l0.4-2.1l-0.9-0.4l-0.7-0.3l-0.5,1.5l-0.5,1.8l-1.1-0.6l-0.6,1.8l-1.3-0.4l-0.4,1.7l-1.3-0.4
					l-0.2,1.6l-1.4-0.5v1.5l-1.1-0.8v0.9l-0.6-1.1v-0.1V110 M73.9,104.2l0.4,2.7l-0.5,0.4l-0.3-2.8L73.9,104.2l-0.5-2.6 M15.2,104.3
					l-0.1,0.7H15l0.1-0.7H15.2l0.2-1 M94.3,104.3l0.1,0.7h0.1L94.3,104.3h0.4l0.1,0.8h0.5v0.8l0.6-0.2l0.1,0.9l0.8-0.4l0.1,1.1l1-0.4
					l-0.2-1.1l0.8-0.4l0.3,1.1l0.8-0.5l0.5,1.2l0.8-0.5l0.6,1.6l0.6-0.9l0.5,1.1l0.4-0.5l-0.3-1.3 M44.3,104.2l0.4,1.8l-0.9,0.6
					l-0.4-2.1L44.3,104.2 M36,104.5l-0.4,2.8l0.9,0.4l0.2-3L36,104.5 M74.4,106.9l0.4,2.7l-0.5,0.5l-0.4-2.8l-0.9,0.4l-0.2-3l-0.9,0.2
					L72,108l1-0.3l0.3,2.9l0.9-0.5l0.4,2.7l0.5-0.6l-0.3-2.7 M47.8,104.4l0.7,1.8l-1.3,0.5l-0.6-1.7L47.8,104.4 M42.6,104.9l0.4,2.4
					l-1,0.4l-0.3-2.7L42.6,104.9 M55.8,112.1L55.8,112.1L57,113l-0.1-1.1l1.3,0.5l0.1-1.4l1.3,0.3l0.3-1.5l1.3,0.3l0.5-1.6l1.1,0.4
					l0.6-1.6l0.9,0.6l-0.6,1.5l0.8,0.7l-0.9,1.1l0.6,0.9l1-1.1l0.7-1.6l-0.9-0.7l-0.6,1.5l0.8,0.8l1,0.7L67,110l1.2,0.5l0.4-2.6
					l1.2,0.2l0.1-3.1h1v3.2h-1.2l-0.3,2.7l-1.3-0.4l-0.9,1.9l-1.1-0.7l-1.2,1.2l-0.8-0.8l-1.4,0.6l-0.4-1.1v-1.3l-1.2-0.3l-0.1,1.5
					l-1.4-0.2v1.4l-1.4-0.3l0.2,1.2l-1.4-0.5l0.3,1l-1.4-0.9v-0.1v-0.8 M15.2,105.1v0.6l-0.2,0.1v-0.7h-0.4 M94.5,105.1v0.6l0.2,0.1
					v-0.7H95v0.7l-0.4-0.1l0.1,0.7l-0.2-0.2v-0.7"/>
				<path class="st0" d="M6.6,105l-0.4,1.2l-0.1,0.4v0.4l0.6-0.5l0,0l0.6,0.7l0.6-1.4l0.6,0.7l0.8,0.6l0.5-1.3l-0.7-0.6l-0.6,1.2
					L7.9,108l-0.6-0.9l-0.5,1.1l-0.5-0.4l0.3-1.3 M14.6,105.1l-0.1,0.7H14v0.8l-0.7-0.2v1l-0.9-0.3l-0.2,1.3l-1-0.4l0.3-1.3l-0.9-0.4
					l-0.4,1.3l-1-0.6l-0.5,1.6l-1-0.6l0,0L7,108.8l-0.3-0.6l-0.3,1.1L6,108.8l0.3-1.1L6,107.6V107v-0.1 M14.9,105.8v0.7l-0.4,0.1v-0.8
					H14.9 M94.4,106.3l0.2,1l0.1,0.1v-0.9l0.4,0.1l-0.1-0.8h0.5v0.8l0.6-0.2v1l0.9-0.3l0.2,1.3l1-0.4l-0.3-1.3l0.9-0.4l0.4,1.3
					l0.9-0.6l0,0 M45.6,105.6l0.6,1.8l-0.9,0.6l-0.5-1.8L45.6,105.6 M6.1,106.2l0.3-0.6 M49.9,105.8l0.6,1.9l-1.4,0.4l-0.6-1.8
					L49.9,105.8 M15,106.4l-0.1,0.8l-0.1,0.2l0.1-0.9L15,106.4v-0.7 M103.4,106.4l-0.4-0.8 M7.8,108l-0.2,1.4l0.6,0.4l0.5-1.3l1,0.8
					l0.5-1.8l1,0.4l-0.4,1.7l1.1,0.6l0.3-1.9l1,0.2l0.1-1.2l0.7,0.1v-1h0.5 M94.9,106.6v1h0.5v-1H94.9 M35.1,106.8l-0.4,2.6l0.6,0.6
					l0.3-2.8L35.1,106.8l0.4-2.7 M103.5,107.7l0.3,0.7l-0.3,0.4l-0.3-1.1H103.5v-0.6v-0.2 M43.9,106.7l0.5,1.9l-0.9,0.7l-0.5-2.1
					L43.9,106.7 M47.2,106.7l0.6,1.7l-1.1,0.4l-0.6-1.6L47.2,106.7 M103.5,107.1l0.4,0.7v-0.3l-0.4-1 M15,107.2l-0.2,0.7v-0.5
					l-0.3,0.1v-1 M94.6,107.3l0.1,0.4L94.6,107.3l0.5,0.2l0.1,1.1l-0.3-0.2l-0.2-0.8 M14.8,107.9l-0.2,0.6l-0.2,0.1l0.1-1.1H14
					l-0.1,1.2h-0.7l-0.3,1.6h0.7l0.3-1.5h0.5l0,0 M94.9,108.7l0.4,1.4l0.2,0.1l-0.3-1.5h0.5l-0.1-1.1l0.6-0.1l0.1,1.2l1-0.2l0.3,1.8
					l-1-0.1l-0.4-1.5l-0.7,0.1l0.3,1.4l-0.4-0.1l0.4,1.3h-0.1l-0.4-1.4 M51.9,107.2l0.4,1.8l-1.4,0.5l-0.4-1.8L51.9,107.2 M6,107.2
					l-0.4,0.7l0.4-1.3 M103.9,107.9v0.2l-0.1,0.5l0.3,0.8l0.1-0.4l-0.1-0.5l-0.2-0.4 M5.5,108L5.5,108 M41.9,107.7l0.5,2.3l-1.2,0.5
					l-0.4-2.6L41.9,107.7 M34.6,109.6l-0.3,2.8l0.5,0.6l0.4-2.8l0.9,0.5l1,0.4l0.3-3.1l-0.8-0.4l-0.3,2.9l-0.5,2.8l-0.9-0.5l-0.3,2.9
					l-0.4-0.5l0.3-2.9 M99.3,107.6l0.5,1.8l0,0l-1.1,0.3l-0.4-1.7L99.3,107.6 M5.3,108.7l0.3-0.8l0.1,0.5l0.3-0.7 M55.1,111v0.5v0.1
					v0.2v0.1l0.1,0.2l0.1,0.2l-0.6-0.2v-0.3v-0.6l0,0v-0.9v-0.1V108l-0.4,1l-0.6,1l-0.2-1.6l-1.2,0.7l0.2,1.7l-1.3,0.5l-0.2-1.6
					l-1.3,0.4l-0.5-1.8l-1.3,0.4l0.5,1.6l-1.2,0.3l-0.4-1.4l-1,0.6l0.5,0.9l-0.3,0.9l-0.9-1l-0.5-1.5l0.8-0.7L46,110l-0.8,0.7
					l-0.8,0.8l-0.7-1.6l-1,0.7l0.8,1.7l-1.1,0.7l-1-1.9l-1.3,0.4l-0.3-2.8h-1.2v3.5l-1.3-0.6l-0.5,2.8l-0.9-0.5l-0.5,2.8l-0.7-0.5
					l-0.2,3.2l-0.3-0.4l0.1-3.3 M63.2,110.3l0.5-0.9l-1-0.6l-0.4,1.5H63.2l0.4,0.8l-1.2,0.4H61l0.2,1.3l1.6-0.2l0.7,1l1.5-0.7l1,0.8
					l1.3-1.3l1.2,0.6l1-2.1l1.6,0.7v-3.5l1.1-0.2l0.3,3.1l1-0.4l0.5,2.8l0.8-0.6l0.4,2.8l0.4-0.4l-0.3-2.9 M104.3,108.8l0.1,0.3
					l-0.2-0.8l-0.3-0.8 M104.4,109.1L104.4,109.1l-0.1,0.5l-0.2-0.4l-0.4,0.4l-0.2-0.8l-0.4,0.5l-0.4-1.1l-0.3,0.6l-0.8-0.9l-1,0.6
					l-0.6-1.6 M5.3,108.7l0.1,0.5l0.4,0.4l0.2-0.8l-0.3-0.4l-0.3,0.8l-0.1,0.6l-0.1-0.4L5.3,108.7 M55.1,109.9l-0.4-1.1l-0.4,1.1
					l-0.1-1.1l-0.1-1.4 M104.4,109.2l0.1,0.4l-0.1,0.4l-0.1-0.3v-0.2 M100.8,108.5l0.5,1.3l-0.6,0.6l-0.8-1.1L100.8,108.5 M5.1,109.3
					L5,109.7l0.1,0.4l0.1-0.4l0.4,0.4l0.1-0.5l0.4,0.4l0.2-0.8l0.2,0.4l0.3,0.4l0.5,0.4l0.6,0.4l0.3-1.1l0.6,0.6l0.8-1l0.1,1.5
					l0.8,0.3l0.3-1.5l-1.2-0.3l0,0 M104.3,109.7l-0.4,0.4l-0.1-0.5l-0.4,0.4l-0.2-0.8l-0.2,0.4l-0.4-0.9l-0.1,0.6h-0.6l-0.2-1.4
					M7.1,108.8l-0.5,0.9l-0.2,0.8l0.3,0.4l0.5,0.4l0.2-0.8l0.2-1.1H7.2L7.1,108.8 M101.9,109.4l0.2,1.1l-0.6,0.4l-0.3-1.1
					L101.9,109.4 M14.3,108.7l-0.3,1.4l0.2-0.1l0.4-1.4 M49.6,109.8l0.2,1.6l-1.3,0.2l-0.2-1.5L49.6,109.8 M104.5,109.7v0.4l-0.1,0.3
					v-0.3l-0.4,0.4l-0.1-0.3l-0.4,0.3l-0.1-0.4l-0.2,0.4l-0.3-0.7l-0.3,0.4l-0.3-0.7 M6.1,110.8v0.2v0.3v0.2v-0.4l0.1-0.5L6,110.1
					l-0.1,0.4L6.1,110.8v0.3l0.2,0.4l0.2-0.5l0.3-0.8l0.3-0.7 M13.9,110l-0.4,1.4l-0.3,0.1l0.4-1.4L13.9,110 M55.2,110.9l-0.4-1.2
					l-0.4,1.1v-0.6V110 M6,110.5l-0.4-0.3v0.3l-0.4-0.4v0.4l-0.1-0.4l0.1-0.4 M96,111.4L96,111.4l0.4,0.2l0.5,0.1l-0.2-1.4l-0.7-0.1
					l0.4,1.4l0.3,1.2l-0.3-0.1L96,111.4 M14.2,109.9l-0.4,1.4l-0.2,0.1l-0.4,1.2l-0.3,0.1l0.3-1.1h-0.5l0.2-1.4l-1,0.1l0,0l-0.7,1.3
					l-0.7-0.4l-0.2,1.1l-0.7-0.2l0.1-1.2L9.1,111l-0.2-0.6l-0.5,1L7.9,111l-0.2,0.8l-0.6-0.3L7,112l-0.5-0.3v-0.1 M98.7,109.7l0.3,1.5
					l-0.6,0.3l-0.7-1.2l0,0L98.7,109.7 M54.4,110.2l-0.6,1.1v-1.4l-1.1,0.7l0.1,1.4l-1.4,0.5v-1.4l-1.4,0.3v1.4l-1.5,0.1l-0.6,1
					l-1.7-0.3l-1,0.8l-1.6-0.8l-1,0.8l-1.5-1.4l-1.1,1l-1.6-2.4l1.6-0.7l1,2.1l1.2-0.6l1.3,1.3l1-0.8l1.5,0.7l0.7-1l1.5,0.2l0.2-1.3
					H47l-0.4,1.1l-1.4-0.6l-0.8,0.8l-1.2-1.2l1-0.7l1,1.1l0.6-0.8l1.2,0.4v-1.3h-0.9 M104.5,110.4l0.1,0.4l-0.1-0.2V110.4v-0.3
					M103.2,110.4L103.2,110.4l-0.3,0.5l-0.5,0.4l-0.2-0.8l0.5-0.4l0.3,0.8l0.1,0.6l0.2-0.5v-0.1L103.2,110.4 M103.5,110.4l-0.1,0.3
					v0.2l0.6-0.4l0.1,0.3l0.4-0.3v-0.1 M5,110.6l-0.1,0.6l0.1,0.2l0.2-0.4l0.3,0.3l0.1-0.4l0.4,0.3l0.2-0.2l-0.5-0.5l-0.1,0.3
					l-0.4-0.3v0.4L5,110.6l0.1-0.4 M54.4,110.8v0.2v0.3l0.4-1.2 M103.4,111.1l0.1,0.3l-0.1,0.1l-0.2,0.4v-0.4l-0.6,0.3l-0.1-0.5
					l-0.6,0.4l-0.2-0.8l-0.5,0.4l-0.4-1l-0.3,0.6l-0.6-0.1l0.1-1.5 M104.5,110.5v0.4V110.5 M104.6,111.4L104.6,111.4l-0.3,0.4
					l-0.1-0.5l-0.4,0.2l-0.1-0.3l-0.2,0.1V111l0.2,0.2l0.4-0.3l0.1,0.4l0.3-0.3L104.6,111.4l0.1-0.2v-0.3l0.1,0.5v0.1l-0.1-0.3
					M103.3,111l0.1-0.2 M4.9,111.2l-0.1,0.2v0.2L4.9,111.2 M99.7,111l0.2,1.1l-0.7,0.2l-0.3-1.1L99.7,111 M6.1,111.3l-0.2-0.1
					l-0.1,0.3l-0.4-0.2l-0.1,0.5l-0.2-0.2L5,111.5 M54.4,111.3l-0.1,0.4v0.1l0.3-0.8l0.2-0.5l0.5,1.3 M103.4,111.3L103.4,111.3
					M103.8,111.5L103.8,111.5l-0.5,0.1l0,0v0.1v-0.1 M55.2,111.5l-0.4-1.3 M4.8,111.4l-0.1,0.3v0.2l0.1-0.3V111.4 M5,111.5l-0.1,0.4
					l0.3,0.3l0.1-0.4l0.4,0.2l0.2-0.5l0.4,0.1l0,0v-0.1l0.2,0.5l0.1-0.4 M104.7,111.5v0.2l0.1,0.1L104.7,111.5L104.7,111.5
					M55.3,112.3l0.2,0.4l0.2,0.5l0.1-0.1l0.7,1.4l1,0.5l-0.4-1l1.5,0.6l-0.4-1l1.6,0.3l-0.4-1.1l1.5,0.1l0.6,1l1.7-0.3l0.8,0.8
					l1.6-0.8l1,0.8l1.5-1.4l1.1,1l1.7-2.2l1.4-0.6l0.5,2.8l0.9-0.5l0.4,2.9l0.8-0.6l0,0 M12.7,111.6L12.7,111.6l-0.7,0.1l-0.1-1.5
					M6.2,111.6L6.2,111.6L6.2,111.6 M100.3,111l0.2,0.7l-0.7,0.3l0.2,0.8l-0.7,0.2l-0.2-0.8l-0.6,0.2l-0.4-1l-0.3,0.4l-0.5-0.3
					l0.1,1.1h-0.5l0.2,0.8h-0.4l-0.3-0.8h0.4l-0.2-1.1h0.7l0.2-1.4 M9.2,110.9L9,111.6l-0.6-0.3l-0.2,0.8l-0.5-0.5v0.6L7,111.8v0.4
					l-0.6-0.3v0.5l-0.1-0.3v-0.2v-0.3 M101.3,111.4l0.2,0.8l-0.5,0.4l-0.2-0.8L101.3,111.4 M4.6,112.8v-0.3v-0.6l0.2,0.3l0.1-0.3
					l-0.2-0.3 M55.2,111.8l-0.5-0.8 M104.5,111.5l0.1,0.4l0.1-0.3 M104.8,111.5L104.8,111.5v0.5v-0.1v-0.1 M104.8,111.9l-0.1,0.2
					l-0.1-0.2l-0.3,0.3l-0.1-0.4l-0.3,0.2l-0.2-0.5 M53.7,111.2v0.9l-0.1,0.1l-1.2,0.8l0.1-1.1L53.7,111.2 M11.5,111.9l-0.2,0.7h0.6
					l0.1-1L11.5,111.9l-0.3-0.4l-0.4,1l-0.5-0.2l-0.2,0.8l-0.6-0.3l0.2-0.8L9,111.8l-0.3,0.8l-0.5-0.4l-0.1,0.5l-0.4-0.4l0.1,0.4
					L7,112.3l-0.1,0.4l-0.5-0.3l0.1,0.6l-0.2-0.2v-0.1v-0.1v-0.5 M103.3,111.6l0.1,0.4h-0.2L103.3,111.6 M6.2,112H6l-0.1,0.4H5.5v0.3
					l-0.3-0.1v0.2l-0.3-0.3v0.2l-0.1-0.3l0.1,0.1v-0.2l0.3,0.3v-0.3l0.3,0.2l0.1-0.4H6l0.2-0.3 M54.7,111.1l-0.6,1l0.1-0.2
					M103.4,112.1L103.4,112.1h0.4l0.1,0.4l0.3-0.2l0.1,0.3l0.3-0.3v-0.1 M103.2,112v0.2l-0.1,0.2L103.2,112l-0.7,0.4l-0.1-0.4
					l-0.6,0.3l-0.1-0.4l-0.5,0.4l0.1,0.5l0.4-0.4v-0.1 M54.1,112.1l-0.1,0.2l0.6-0.5l0.6,0.4 M53.6,112.1l0.6-1.1 M54,112.2L54,112.2
					l0.7-0.2v0.6h0.8 M104.9,112.1l-0.2,0.3v0.2v0.3l0.2-0.3v-0.2V112.1 M103.2,112.1v0.3h0.4h0.4v0.3l0.4-0.2v0.2l0.3-0.3v-0.2
					M103.2,112.5v0.2h0.1h0.3v-0.3l-0.1-0.4 M6.3,112.3l-0.4,0.1v0.2H5.5v0.2l-0.4-0.2v0.1l-0.2-0.2v0.3l-0.2-0.3 M101.8,112.3
					l-0.1,0.4l0.8-0.3l0.1,0.4l0.4-0.3l0.1-0.2 M96.2,112.5l0.2,0.9l0.3,0.3l-0.4-1L96.2,112.5l-0.4-1.2 M103.4,112.6v0.2h0.3v-0.2
					h0.4v0.2l0.4-0.2v0.1l0.3-0.3l0.2-0.3v0.3v0.1v-0.3 M12.9,112.7L12.9,112.7l-0.3,0.8l0.2,0.2l0.4-1.1l0.2-0.1l0.4-1.2 M98.6,112.5
					L98.6,112.5l-0.5,0.2l-0.2-0.7 M5.1,112.8L5.1,112.8l0.4,0.2v-0.2h0.4v-0.2h0.3l0,0 M53.9,112.7l-0.2,0.5l1,0.2v-0.7H53.9l0.1-0.4
					M8.1,113.1L8.1,113.1l0.5,0.5v-0.4l0.7,0.2l0.1-0.5l-0.7-0.4l-0.1,0.6l-0.5-0.4V113.1l-0.3-0.4l0,0L7.5,113l-0.6-0.3v0.5L6.6,113
					v0.5l-0.2-0.3v-0.4l0,0 M7.7,112.7l0.2,0.4l0.2,0.2v0.2l0.1,0.2l0.3,0.2v-0.4l0.6,0.2l0.1-0.4l0.8,0.2V113l0.6,0.3l0.5,0.2
					l0.2-0.8l-0.4-0.2l-0.1,0.8l-0.2,0.5l-0.5-0.2v0.4l-0.9-0.2l-0.1,0.4L8.6,114v-0.1 M104.9,112.8v0.2l-0.1,0.2l-0.1-0.3l-0.2,0.2
					v-0.3l-0.3,0.2v-0.2h-0.4v0.1l-0.3-0.1l0,0l-0.2-0.1v-0.1 M8.7,114v0.4l0.4,0.2l0.1-0.4l0.6,0.1h0.4l0.2-0.1l-0.3-0.2l0.6,0.3
					l-0.1-0.5l0.5,0.2l0.1-0.5l0.5,0.1l0.2-0.8h0.6l0.2-1.1 M12.9,112.7h-0.4l-0.2,0.8h-0.5l-0.1,0.5h-0.5v0.4l-0.4-0.2l0.2,0.3
					l-0.3-0.2l-0.1-0.1l-0.1-0.1 M38.4,111.6l-0.7,2.9l1.5,1.1l0.8-1.7l1.7,1.4l0.9-1l1.8,0.8l0.8-0.7l1.8,0.4l0.7-0.9h1.7l0.4-1.1
					l1.4-0.3l-0.2,1.1l1.5-0.6l-0.3,1l1.3-0.9l0.1-0.1v-0.8 M97.1,113.9v0.3l-0.4-0.5l0,0l0.2-0.1L97.1,113.9l0.3,0.1l-0.2-0.5h0.5
					l-0.2-0.8h0.6l0.2,0.8l0.5-0.2l-0.2-0.8 M6.2,112.8H6l0.1-0.2 M5.1,112.8v0.2l-0.3-0.2l-0.1,0.3l-0.1-0.2v-0.1 M101.7,112.6
					l0.3,0.3l-0.5,0.1h-0.1l0.1-0.3L101.7,112.6 M5.9,112.8L5.9,112.8h0.3H6.1H5.9 M100.8,112.6l0.1,0.6l-0.7,0.2l-0.1-0.5
					L100.8,112.6 M102.7,112.7l0.1,0.5l-0.5,0.1l-0.2-0.5L102.7,112.7 M103.4,112.9v0.2l-0.2-0.1v-0.2l-0.1,0.3v-0.5 M4.7,113.1v0.2
					l0.3,0.2l0.1-0.4l0.3,0.1V113h0.4v0.2H5.4l-0.1,0.5L5,113.6v0.5l-0.3-0.3l-0.2-0.3v-0.2l0.1-0.4 M103.3,113v0.4v0.1l0.3,0.2
					l-0.1-0.5l0.3,0.1l-0.1-0.2h0.4v0.2h-0.4l0.1,0.5l-0.3-0.1l0.1,0.5l-0.2-0.1l-0.1-0.5 M53.6,113l0.6-1.3 M55.9,113l-0.7-1.5
					M101.7,112.8l-0.3,0.3v-0.4l-0.5,0.4v0.4l0.4-0.4l0.1-0.1 M6.3,113l-0.2,0.1v-0.3 M7.9,113.1L7.9,113.1L7.4,113l-0.2,0.5
					l-0.4-0.1v0.6l-0.4-0.2v0.5l-0.1-0.5v-0.3v-0.1v-0.1 M104.9,113l0.1,0.3v-0.2l-0.1-0.4 M4.8,113.3v0.4v0.2l0.2,0.2l0.1-0.2
					l0.3,0.1l0.1-0.5h0.4L6,113l0.3-0.1l-0.1,0.5l0.3-0.2v0.4l0.1-0.3 M104.3,113.2L104.3,113.2l0.3-0.1l0.1,0.4l0.2-0.2v-0.1
					M53.8,113.1l-0.1,0.4l-0.1,0.3l-0.1,0.3l-0.1,0.1l-0.4,0.2L53.8,113.1L53.8,113.1 M103.2,112.9v0.4v0.1l-0.3,0.2V113L103.2,112.9
					M99.4,112.9l0.1,0.5l-0.6,0.2l-0.1-0.5L99.4,112.9 M4.7,113.2v0.2V113l0.1-0.3 M101.4,113v0.3l0.1-0.1l0.2-0.2 M103.4,113.7v0.2
					l0.1,0.2L103.4,113.7L103.4,113.7L103.4,113.7L103.4,113.7L103.4,113.7v-0.6l0,0 M105.1,113.1v0.2V113.1l-0.1-0.2 M53.7,113.4
					l1.2,0.2v-0.5l1-0.2l0.1,0.4l-1.1,0.2l-0.1,0.4l-1.2-0.3v0.1l0.4,0.1l0.8,0.1h0.1l1.1-0.3l-0.1-0.3 M8.3,113.1l-0.4,0.1l0.2-0.3
					M101.5,113.1l0.4,0.1l-0.2-0.3 M6.4,113.6v0.3l0.2,0.3v-0.3l0.3,0.3v-0.5l0.4,0.1v-0.5h0.5v0.5l0.4-0.1l0.1-0.3v-0.3 M13.2,113.3
					l-0.3,0.3v0.4l0.2-0.2l0.1-0.1V113.3l0.2-1 M101.2,113.7l-0.1,0.3l-0.2,0.2v-0.4l-0.5,0.2l-0.1-0.4l-0.9,0.2v-0.4l0.7-0.2l0.1,0.4
					l0.6-0.2v0.4l0.3-0.3L101.2,113.7l-0.1,0.2l0.3,0.2v-0.5l-0.2-0.2l0.1-0.2l0.1,0.3l0.4,0.1v-0.5h0.5v0.5l0.4-0.1v0.5l0.3-0.3v-0.1
					l0.1-0.2 M103,113.3L103,113.3 M96.7,113.5v0.4l-0.2-0.2v-0.1l-0.1-0.5 M96.7,114L96.7,114l0.3,0.3l0.1-0.3l0.3,0.1v-0.3h0.5
					l-0.1-0.5l0.6-0.1l0.1,0.5l0.5-0.2v0.4l0.5-0.2h0.1l0.3,0.3l0.6-0.1l0.1,0.4l0.4-0.2V114 M12.6,113.3l-0.2,0.4H12l0.2-0.2h0.3
					M105.1,113.3v0.3v-0.1l-0.2,0.2l-0.2,0.3l-0.1-0.5l-0.3,0.2l-0.1-0.5 M4.6,113.9l0.1,0.2v0.1l-0.1-0.1V113.9v-0.3l0.2,0.3v0.2
					l0.3,0.3v-0.2l0.3,0.2v-0.3h0.4l0.1-0.5l0.3-0.1L6.1,114h0.3v-0.2v0.2l0,0l-0.2,0.3l0.1-0.3 M104.8,113.3l0.1,0.4l0.1,0.2v0.2
					l-0.3,0.2v-0.2l-0.3,0.2l-0.1-0.5h-0.4l0.1,0.5l-0.3-0.1v0.3h-0.2v-0.1 M8.4,113.7l0.1,0.5l0,0l-0.3,0.1v-0.5L8.4,113.7
					M105.1,113.8L105,114v0.2v0.1l0.1-0.2V114V113.8L105.1,113.8L105.1,113.8 M34.1,118.4v3.4l0.2,0.2v-3.3l0.6,0.5l0.3-3.2l0.9,0.6
					l0.5-2.9l1.2,0.7l-0.5,2.8l1.5,0.7l0.6-2.4l1.8,1.1l0.7-1.4l2,0.8l0.7-1l2,0.4l0.6-0.9h1.7l0.5-1l1.6-0.3l-0.4,1l1.6-0.6l-0.4,1
					l1.1-0.5l-0.4,1.3l0.6-0.3l0.1-0.1l0.2-1.2 M56,113.9L56,113.9l0.1,0.3l0.5,0.2l0.4,1.3l1.2,0.4l-0.5-1.2l1.5,0.5l-0.4-1l1.8,0.3
					l-0.5-1h1.7l0.7,0.9l1.8-0.4l0.8,0.8l0,0l1.8-0.8l0.9,1l1.7-1.3l0.8,1.8l1.5-1.1l-0.7-2.9 M103,114.1v0.2v0.1v0.2l0.1-0.1v-0.2
					L103,114.1l0.1-0.2 M102.2,114v0.5h-0.5v0.3l-0.4-0.1l0.1,0.2l-0.1,0.1l-0.3-0.3l-0.1-0.2l0,0l0.1-0.3l0.1,0.4l0.2,0.1v-0.3
					l0.4,0.1V114L102.2,114 M6.2,114L6.2,114l0.1,0.4l0.1,0.2v-0.2l0.3,0.3v-0.3l0.4,0.1V114h0.5v0.5l0.4-0.1v0.3l0.2-0.1l0.2-0.1
					l0.1-0.2v-0.1 M103.1,114v-0.4 M12.4,114v0.2l-0.4,0.2v-0.3h-0.4v0.4h-0.5l0.1,0.3l-0.3-0.3l0.2,0.3l0.1,0.2v0.1l0.2,0.1V115
					l-0.2-0.3l0.4,0.1v-0.3l0.4-0.1v0.3l0.4-0.2l0.3-0.2v-0.1 M96.7,114.2v0.4l0.3,0.2v-0.3l0.4,0.2v-0.3l0.4,0.1v-0.4h0.5v0.4
					l0.5-0.2l-0.1,0.3h0.2l0.1-0.1l0.1-0.1l0.2-0.2 M13,114.1v0.3V114.1l0.1-0.1 M6,114.1l-0.1,0.3l-0.3,0.1v0.1H5.2v0.1l-0.3-0.2v0.1
					l-0.3-0.2v0.2l-0.1-0.2v0.3v-0.1v-0.1v-0.2l0.1,0.3v-0.2l0.3,0.2v-0.1l0.3,0.2v-0.1h0.3v-0.3l0.3-0.1 M8.4,114.1v0.5l0.2,0.2v-0.4
					L8.4,114.1L8.4,114.1 M12.9,114.2L12.9,114.2l-0.2,0.4l-0.3,0.2v-0.4v-0.3l0.4-0.4 M105,113.9l-0.1,0.2l-0.2,0.1v-0.1l-0.3,0.2
					V114h-0.3v0.3l-0.3-0.1l0.1,0.1h-0.1l-0.2-0.2v0.2l-0.1-0.2l-0.1-0.3 M96.6,113.9v0.4l-0.1-0.2L96.6,113.9 M54.9,114.2l0.8-0.1
					L56,114 M8.8,114.7L8.8,114.7l0.3,0.2v-0.5h0.4l0.2-0.4l0.3-0.3 M99.2,114.1l0.2,0.3l0.4,0.1l-0.2-0.4H99.2H99 M6,114.2l-0.3,0.2
					l0.1-0.1L6,114.2L6,114.2L6,114.2L6,114.2l0.1-0.3 M53,115.5l0.2-0.1l0.1-1.2l0.1-0.2 M99,114.3l0.1,0.4v0.1l0.4,0.2l-0.1-0.5
					L99,114.3L99,114.3 M103,114.2l0.2-0.2 M104.6,114.2L104.6,114.2h0.2v0.1v0.2l0.1-0.2v-0.2l0.1,0.3l-0.1,0.2v-0.3 M54.7,114.1
					L54.7,114.1l-0.5,0.1h-0.3 M54.8,114.3h0.6l0.3-0.1 M8.4,114.5L8.4,114.5L8.4,114.5v-0.2 M10.5,114.3l-0.1,0.2l-0.1,0.5l0.2-0.3
					V114.3l0.1,0.7v-0.3 M100.8,114.4v0.5l0.2-0.3v-0.2v-0.3 M103.8,114.4l0.2,0.1h0.3l0,0v-0.1H103.8L103.8,114.4l0.1,0.2l-0.3-0.1
					v0.2l-0.2-0.2v0.3l-0.1-0.1v-0.4 M96.4,114.2v0.4l0.1,0.2v-0.4l0.2,0.3v0.3v0.1l0.3,0.2v-0.4l0.3,0.1l0.1-0.3h0.4v-0.3h0.5
					l-0.1,0.3l0.3-0.2l0.1-0.1 M12.8,114.4v0.3V114.4l0.1-0.3 M98.7,114.5L98.7,114.5 M8.4,114.4v0.3l-0.1,0.1v-0.2l-0.2,0.3L8,114.8
					l0.1-0.2l-0.4,0.1v-0.3H7.3v0.3l-0.4-0.1v0.2l-0.4-0.3l0.1,0.2l-0.1-0.3v-0.1 M101.6,114.8l0.3,0.1v-0.2h0.5v-0.3l0.4-0.1v0.3
					l0.3-0.2 M99,114.5v0.4v-0.1v-0.5V114.5 M104.9,114.3l-0.2,0.2v-0.1l0,0h-0.3 M5.9,114.5l-0.3,0.1v-0.1H5.9L5.9,114.5L5.9,114.5
					h0.3v-0.1 M103,114.5l-0.1,0.2L103,114.5L103,114.5L103,114.5v-0.2 M10.3,114.4l-0.4,0.2l0.2-0.3 M8.4,114.7L8.4,114.7l0.1,0.3
					L8.4,114.7v-0.3 M6.1,114.5v0.3l0.1-0.2v-0.3 M98.4,115L98.4,115l-0.1,0.2L98.4,115L98.4,115v-0.2l0.2-0.3 M72.9,113.9l0.5,2.9
					l-1,0.6l-0.6-2.8L72.9,113.9 M104.7,114.5l-0.3,0.1v-0.1 M4.5,114.5v0.3v0.2V114.5l0.1,0.3L4.5,114.5l0.4,0.3v-0.2l0.3,0.1v-0.1
					h0.3v0.1l0.3-0.1v0.3l0.2-0.2v0.2l0.1-0.4 M8.8,114.9v0.4l0.4,0.2v-0.4l0.4,0.1l0.1-0.5l0.5-0.1l-0.1,0.5l0.4-0.2l-0.1,0.5
					l0.2-0.2l0.1-0.2v0.2l-0.1,0.2v-0.2 M13.1,114.6L13.1,114.6l-0.2,0.3l-0.1-0.3v-0.4 M99.3,114.9v0.4l0.3,0.2v-0.4l0.4,0.1
					l-0.1-0.5h0.5v0.5l0.3-0.2v0.4l0.2-0.2V115v-0.3 M103.5,114.7l0.1,0.4L103.5,114.7l0.3,0.3l-0.1-0.3l0.3,0.1v-0.1h0.3v0.1l0.3-0.1
					l0.1-0.1 M101.2,114.6v0.4l0.3,0.2v-0.3l0.4,0.2v-0.2h0.5v-0.2l0.4-0.2l0,0 M101,114.9L101,114.9l0.1-0.5 M102.7,114.6v0.2v0.1
					l0.3-0.2v0.2l0.1-0.2l0.1-0.3"/>
				<path class="st0" d="M104.6,114.6v0.2l0.2-0.3v0.3l0.1-0.3v0.2l0.1-0.1v-0.2 M11.1,114.8l0.3,0.2l0.3,0.1l-0.1-0.3l0.4-0.1v0.3
					l0.4-0.1v0.4l0.3-0.2V115 M96.6,115l-0.1,0.3l0.3,0.2v-0.3l0.3,0.1v-0.4l0.4,0.1l0.1-0.3H98l-0.1,0.3l0.2-0.2 M4.5,114.8v0.3V115
					l0.1,0.2L4.5,114.8l0.3,0.2v-0.2l0.2,0.1v-0.2h0.3v0.2H5.1v0.4l-0.2-0.2V115 M104.3,114.7v0.2H104v0.3l-0.3-0.1v0.3l-0.2-0.4
					l0.2,0.2v-0.3l0.3,0.1v-0.3L104.3,114.7 M7.9,114.8l-0.3,0.1v-0.2H7.2v0.2l-0.5-0.1l0.1,0.2l-0.3-0.3l0.1,0.2l-0.1-0.1v-0.3
					M99.2,114.7l-0.2-0.2 M101.1,115l0.1,0.2L101.1,115l0.1-0.3l0.1,0.2v0.6l0.3,0.2v-0.5l0.4,0.1V115h0.4v-0.2l0.4-0.1l0.3-0.3
					M13,114.7v0.2l0.1-0.1l-0.1-0.4 M96.8,114.8l-0.2-0.2v0.3l-0.1-0.2l0.1-0.3 M34.4,122L34.4,122l0.5,0.5l0.1-3.3l0.8,0.5l0.3-3.1
					l1.1,0.6l-0.3,3.1l1.4,0.5l0.4-2.9l1.8,0.7l0.6-1.9l2.1,0.7l0.6-1.3l2.1,0.4l0.6-1l1.9,0.1l0.5-0.8l1.8-0.3l-0.4,1l1.5-0.5
					l-0.5,1.2l1.1-0.4l-0.3,1.7l0.7-0.2l0.2-1.7 M56.4,115.6l0.1,1.7l-0.3-0.2l-0.1-1.8l-0.1-1.1L56.4,115.6L56.4,115.6l0.5,0.2
					l0.3,1.7l1.2,0.2l-0.3-1.5l1.5,0.3l-0.5-1.1l1.9,0.2l-0.5-0.9h1.9l0.7,0.9l1.9-0.4 M105,114.8v0.2l-0.1,0.2L105,114.8l-0.3,0.2
					l-0.1-0.2l-0.3,0.1v0.4H104v0.3h-0.1l-0.1-0.1 M103,114.9L103,114.9l-0.2,0.3V115l0,0 M6.7,115L6.7,115l0.3,0.3v-0.2l0.4,0.1V115
					h0.5v0.2l0.4-0.2v0.2l0.2-0.2v-0.1 M5.9,115v0.3l-0.3,0.1v-0.3L5.9,115 M8.6,115.5v-0.2v-0.2v-0.3 M99.2,115.2v0.3v0.1l0.2,0.2
					v-0.3L99.2,115.2L99.2,115.2 M11.6,115.3L11.6,115.3l0.3,0.1v-0.3l0.4-0.1v0.4l0.3-0.1l0.1,0.3l0.3-0.2v-0.3 M96.5,114.9v0.3
					l0.1,0.2v-0.3l0.2,0.2v0.2l0.3,0.2v-0.2l0.3,0.1v-0.3h0.4V115l0.4-0.1l-0.1,0.3l0.2-0.2 M13.2,115.6l0.1,0.1h0.1L13.2,115.6
					l0.1,0.1l0.1,0.3l-0.2-0.1l-0.1-0.1v-0.1L13.2,115.6L13.2,115.6l-0.1-0.2l-0.1-0.2v-0.4 M6,115.1l-0.2,0.1l-0.1,0.3l0.1-0.1
					L6,115.1l0.1-0.2 M104.4,115.3L104.4,115.3l0.3-0.2V115 M13,115.1v0.3l0,0l-0.1,0.1v-0.2L13,115.1l0.1-0.2 M8.1,115.1L8.1,115.1
					l-0.4,0.1V115H7.3v0.2L7,115.1V115 M8.4,115l0.1,0.3L8.4,115l0.3,0.3v0.3l0.4,0.2v-0.3l0.4,0.1v-0.5l0.4-0.1v0.5l0.3-0.2v0.2
					l0.2-0.1l0.1-0.2 M98.9,114.9v0.3v0.2v0.3v-0.2l0.2,0.3v-0.2l0.3,0.2v-0.3l0.4,0.1v-0.5l0.4-0.1v0.4l0.3-0.2v0.2l0.2-0.2l0,0l0,0
					V115 M100.9,115.9v0.3l0.1,0.2v-0.3L100.9,115.9v-0.6l0.2,0.3v0.5l0.3,0.2v-0.5l0.3,0.1v-0.5h0.4v-0.2l0.4-0.1 M4.7,115.2
					L4.7,115.2l0.2,0.2v-0.3 M11.2,115.1v0.2V115.1L11.2,115.1 M6.6,114.9v0.5l-0.1-0.3v-0.4 M104.6,115v0.3l0.2-0.1l0,0 M101.1,116.2
					L101.1,116.2l0.2,0.1V116l0.4,0.1v-0.5h0.4v-0.5l0.3-0.1v-0.1 M102.7,114.9v0.3l0.1-0.1l0.1-0.6 M104.9,114.6v0.6l-0.1-0.2
					M96.3,115.3l0.2,0.3h0.1l-0.1-0.2L96.3,115.3L96.3,115.3L96.3,115.3v-0.2 M98.2,115.2v0.3l0.1-0.2L98.2,115.2 M13,115.2l-0.1,0.3
					v-0.2L13,115 M11.1,115.3L11.1,115.3v0.2V115.3 M4.5,115.1v0.2l0.1,0.2l0.2,0.3v-0.4l0.2,0.2v-0.2h0.3v0.3H5.1L5,115.8H4.9
					M10.3,115.6L10.3,115.6l-0.1,0.2h0.1l0.2-0.4v0.2l0.1-0.3v-0.2 M8,115.2v0.5l-0.3,0.1v-0.5H7.3v0.5l-0.4-0.2v-0.5 M99,115.3
					L99,115.3 M100.8,115.4v0.2V115.4l0.2-0.1v-0.2 M11.5,115.2v0.2l0,0l0.3,0.1v-0.3h0.4v0.3h-0.4l0.1,0.2l-0.3-0.1v-0.2 M98.1,115.3
					v0.3l-0.3,0.1v-0.3L98.1,115.3 M8.5,115.4L8.5,115.4l0.2,0.2v0.2l-0.1-0.1L8.5,115.4v0.4l-0.1,0.1L8.5,115.4v-0.2 M8.4,116
					L8.4,116l-0.2,0.3v-0.5l0.3-0.2V115 M98.5,115.3l-0.1,0.2l0,0l-0.2,0.1v0.2l0.2-0.2v-0.1 M102.7,115.3v0.5l0.3-0.2v-0.2
					M4.7,115.5v-0.3 M11.4,115.5l-0.2-0.1 M96.3,115.6L96.3,115.6l0.2-0.4 M96,116l0.1,0.2l-0.1,0.1V116L96,116l0.1-0.3L96,116
					L96,116l0.1-0.2v-0.1v-0.1l-0.1,0.1l0.2-0.2 M5.9,115.4L5.9,115.4L5.9,115.4l-0.3,0.1l-0.1,0.2h0.2L5.9,115.4 M56.4,115.5
					l-0.2-0.2 M13,115.4l-0.1,0.3h-0.1v-0.1v-0.1 M104.8,115.3v0.3l-0.2,0.2v-0.3l-0.2,0.2l-0.1-0.3 M96.7,115.7l0.2,0.2v-0.2l0.4,0.1
					v-0.2h0.4v0.2h0.2v0.1l0.1-0.1l0.1-0.1v-0.1 M8.4,115.6l0.1,0.2l0,0l0.3,0.2v-0.2l0.4,0.1v-0.3l0.4-0.1v0.3l0.3-0.2 M99,115.4
					l-0.1,0.4v0.2l0.1-0.4l0.2,0.3v-0.1l0,0l0.4,0.2v-0.2l0.4,0.1v-0.3l0.4-0.1v0.3l0.4-0.2 M104,115.6l0.1,0.2h0.3l-0.1-0.2H104
					M104.8,115.6l0.1-0.3 M13.2,115.4l-0.1,0.2l-0.1-0.2l-0.1,0.4v-0.1 M102.9,115.9l-0.1,0.2v0.2L102.9,115.9v-0.3v-0.4 M11.4,115.8
					l0.1,0.1l0,0L11.4,115.8l-0.1-0.2L11.4,115.8l0.2,0.1l0.3,0.1v-0.2h0.4v-0.2l0.4-0.1v0.1l0.2-0.1 M6.8,115.5v0.4v0.1l0.3,0.2v-0.5
					L6.8,115.5 M96.4,115.7l0.1,0.2L96.4,115.7l0.3,0.2l0.1-0.1l0.3,0.2v-0.1l0,0l-0.3-0.2v0.1l-0.2-0.3L96.4,115.7l0.1-0.2
					M101,115.7L101,115.7l-0.3,0.3L101,115.7L101,115.7L101,115.7 M12.6,115.7L12.6,115.7v0.2l0.2-0.2l-0.2,0.3v-0.1l-0.4,0.1v-0.1
					L12.6,115.7 M6.8,115.9l-0.1-0.1l-0.1-0.5 M104.3,115.8h-0.2l-0.1-0.1 M34.3,122.2l0.1,3.2v0.1l0.5,0.4l-0.1-3.3l0.8,0.5l0.1-3.3
					l1.1,0.5l-0.2,3.2l1.3,0.4l0.3-3.1l1.8,0.5l0.5-2.7l2.1,0.5l0.5-1.8l2.2,0.4l0.5-1.2l2.1,0.1l0.5-1l1.9-0.2l-0.5,1.1l1.5-0.3
					l-0.3,1.5l1.2-0.2l-0.3,2.3l0.8-0.1l0.2-2.4l0.3-0.2l0.1-1.7 M56.2,117.2l0.2,2.5l0.4,0.1l-0.2-2.4l0.7,0.2l0.3,2.3l1.2,0.1
					l-0.3-2.1l1.6,0.2l-0.3-1.4l1.9,0.1l-0.5-1l2-0.1l0.5,1l2.1-0.4l-0.7-1 M5.4,115.8H5 M104.5,115.8h0.2 M10.2,115.8L9.9,116
					l-0.4,0.1v0.1l-0.4-0.1l0.1,0.1l-0.3-0.1L8.8,116l0,0l-0.1-0.1l0.1,0.1l0.3,0.1V116l0.4,0.1v-0.2l0.4-0.1v0.1v0.1L10.2,115.8v0.2
					l0.2-0.3l0.1,0.4l0.1-0.3l-0.1-0.4 M98.1,115.9L98.1,115.9l-0.2,0.2L98.1,115.9L98.1,115.9L98.1,115.9 M6.7,116.1v0.2l0.3,0.2
					v-0.2l0.3,0.1v-0.5h0.4v0.5l0.3-0.1v0.3l0.2-0.1l0,0v-0.3 M101,116.3v0.2l0.1,0.3v-0.2l0.2,0.2v-0.3l0.3,0.1v-0.2h0.4v-0.6
					l0.3-0.1v0.5l0.2-0.2v-0.4 M11.6,116l0.1,0.2l0.3,0.1l-0.1-0.2h0.3V116h-0.4l0.1,0.1L11.6,116L11.6,116l-0.1-0.1l-0.1-0.1
					L11.6,116h0.2v-0.1h0.4l0.1,0.1l0.3-0.1v0.1l0.3-0.3l0.1,0.2l0.2-0.3l0.1,0.2l0.1-0.2 M97.9,115.8l-0.3,0.1v-0.1h-0.4v0.1
					l-0.4-0.1 M99.2,115.7l0.3,0.2v-0.1l0.4,0.1v-0.1l0.4-0.1v0.2l0.3-0.2 M102.7,116.1v-0.2l0.1-0.3 M6.6,116l0.1,0.2L6.6,116
					L6.6,116v-0.3 M13,116L13,116l-0.2,0.3l-0.1-0.1l-0.3,0.1l-0.1-0.1l0.3-0.1l0.1,0.2L13,116L13,116l0.2-0.1v0.1v0.1h0.1h0.2
					l0.1-0.3l0.1,0.1l-0.2-0.3 M96.9,115.8l0.3,0.1v-0.1h0.4v0.1l0,0h-0.4l-0.1,0.1L96.9,115.8l-0.1,0.2l-0.2-0.2l0,0 M13.2,116
					l0.1-0.2 M98.9,115.8l-0.1,0.3l0.1,0.3L99,116l0.2,0.3l0.1-0.4l0.3,0.2v-0.2l0.4,0.1v-0.1l0.4-0.1v0.1l0.2-0.1l-0.1,0.1l-0.3,0.2
					l0.1-0.2h0.1 M8.4,116.3L8.4,116.3L8.4,116.3L8.4,116.3v-0.2v-0.3 M97.7,116.1l0.2-0.1L97.7,116.1L97.7,116.1H98l0.1-0.2
					M9.3,116.2L9.3,116.2h0.4L9.6,116l0.4-0.1v0.2l0.3-0.2l0.1,0.4l0.2-0.3l0.1,0.3l0.1-0.1v-0.1l-0.1-0.3 M96.2,115.7l0.1,0.3
					L96.2,115.7l0.3,0.3l0.1-0.1 M11.6,116l0.1,0.2l0.1,0.1l-0.1-0.2L11.6,116l-0.1-0.1 M96.2,115.8l0.1,0.3l0.1-0.1l0,0l0.2,0.2V116
					l0.3,0.2V116l0.3,0.1l0.1-0.2h0.4l0.1-0.1 M13.2,116.1L13.2,116.1l-0.1,0.2h0.1V116.1l0.1-0.3 M98.8,116l-0.2,0.2L98.8,116
					L98.8,116v0.2l0,0l0.1,0.3v-0.3l0.2,0.2l0.1-0.3l0.3,0.2l0.1-0.3l0.3,0.1l0.1-0.2h0.4 M96.3,116l0.1,0.3h0.1v-0.1l0.2,0.2l0.1-0.1
					l0.3,0.1l0.1-0.2h0.3l0.1-0.2l0.3-0.1 M9,116l0.1,0.3L9,116.2l-0.2-0.4 M100.6,116l-0.1,0.2L100.6,116l0.2-0.3 M96.3,116.1
					l0.1,0.3l-0.1,0.1V116.1L96.3,116.1 M67.8,115.3l0.7,1.5l-2.1,0.7l-0.6-1.2L67.8,115.3 M13.2,116.2l-0.1,0.1v-0.1 M97.8,116.2
					L97.8,116.2L97.8,116.2l-0.2,0.2l-0.1,0.1L97.8,116.2L97.8,116.2L97.8,116.2 M10.7,116.2L10.7,116.2l0.1,0.2L10.7,116.2
					L10.7,116.2L10.7,116.2l0.2,0.3v-0.2v-0.1 M12.8,116.3L12.8,116.3L12.8,116.3l0.1,0.2l0,0l0,0l0.2-0.2h0.1l0.1-0.3l0.1,0.1v-0.2
					l0.1,0.3l-0.1-0.1l-0.1,0.3l-0.1-0.1l-0.2,0.2h-0.1l-0.3,0.1l0,0l0,0v-0.1l-0.1-0.1v-0.2h-0.4l0.1,0.2l-0.3-0.1l0,0l-0.1-0.2
					M8.2,116.2l-0.3,0.2l0.1-0.1l-0.3,0.1v-0.2H7.3v0.2L7,116.5v0.1v0.1l0.1,0.1l0.1,0.2L7,116.8l-0.1-0.1v-0.1v-0.1v-0.1l-0.1-0.2
					M9.3,116.2l0.1,0.3l0.3,0.1l-0.1-0.3l0.4-0.1l0.1,0.2l0.3-0.1l0.1,0.3l0.2-0.2v-0.1 M98.7,116.3l-0.1,0.2l-0.2,0.1l0.1-0.3l0,0
					M98.6,116.5l0.1,0.3l0.1-0.1l0.2,0.2l0.1-0.2l0.3,0.2l0.1-0.3l0.4,0.1l0.1-0.3h0.4l-0.1,0.3l0.3-0.2l0.1-0.1 M96.5,116.4l0.1,0.2
					l0,0L96.5,116.4L96.5,116.4l0.1,0.2h-0.1l-0.2-0.2h-0.1l-0.1-0.2l0,0v-0.3 M8.4,116.3l-0.1,0.2l-0.1-0.1l0.2-0.3 M98.7,116.3
					L98.7,116.3 M101.1,116.3v0.4l0.1,0.2l0.1-0.3l0.2,0.2v-0.2l0,0l0.4,0.1v-0.1h0.4v-0.2l0.3-0.1v0.2l0.2-0.1l0,0l-0.1,0.1l0,0v0.1
					l0,0l0.1-0.1 M12.8,116.4l-0.3,0.1h-0.3v0.1h0.1h0.1h0.2L12.8,116.4l0.2-0.1v-0.1v-0.1 M9.1,116.4l0.1,0.3l0.3,0.1l-0.1-0.3
					L9.1,116.4L9.1,116.4 M97.7,116.5l-0.3,0.1l0.1-0.2l0.3-0.1 M97.7,116.4L97.7,116.4 M12.4,116.8h0.2h0.1h0.1h0.1l0.3-0.1l0.1,0.1
					l0.2-0.2h0.1l0.1-0.2 M8.5,116.6l-0.1,0.2l0.1,0.2l0.1-0.1L8.5,116.6v-0.2 M11,116.3l0.1,0.2v0.1h0.1v-0.2v-0.1 M100.4,116.4
					l-0.1,0.3v0.2l0.2-0.2l0.1-0.2l0.1-0.3 M11.9,116.5l0.1,0.1l0.2,0.1h0.3l0.3-0.1l0.2-0.2 M9,116.2l0.1,0.3l0.2,0.3l0,0l0.3,0.2
					l-0.1-0.2l0.4,0.1l-0.1-0.3l0.3-0.1v-0.1 M97,116.5L97,116.5l-0.1,0.2l-0.3-0.1l0.1-0.1H97l0.3,0.1l0,0v-0.1H97 M102.8,116.4
					l-0.2,0.2v-0.1l-0.3,0.1v0.2l-0.3-0.1l-0.1,0.1l-0.3-0.1 M96,116.3v0.3h0.1l0,0 M97.4,116.6l0.2-0.1l0,0 M8.4,116.6L8.4,116.6
					l-0.2,0.2l-0.1-0.2H8l-0.2,0.2v-0.1H7.4v0.1l-0.3-0.1l-0.2-0.2 M11.1,116.7l-0.2-0.1v0.3l-0.1-0.1l0.1-0.3 M12.6,116.8h0.2
					l0.2-0.1l0.1-0.2 M102.8,116.6l-0.2,0.1v-0.1 M12.7,116.9L12.7,116.9h0.2l0.2,0.1l0.3-0.1l0.1,0.1l0.2-0.2l0,0 M98.8,116.8
					l0.1,0.3H99V117l0.3,0.2l0.1-0.2l0.3,0.1l0.1-0.3h0.3l-0.1,0.3l0.3-0.2l0.2-0.3 M96.7,116.8l0.3,0.1h-0.1L96.7,116.8L96.7,116.8
					M99.1,117.2l0.2,0.1v0.1L99.1,117.2L99.1,117.2l-0.2-0.2l-0.2,0.1v-0.3l-0.2,0.1l0.1-0.2l0.2-0.2 M10.2,116.7l0.1,0.3l0.3-0.2
					l0.1,0.2l0.1-0.1l0,0l-0.2-0.5 M102.7,116.6l-0.1,0.2l-0.1,0.1v-0.1L102.7,116.6l-0.2,0.1L102.7,116.6l-0.4,0.1L102.7,116.6
					l-0.1,0.2h-0.3l0.1-0.1h-0.3v0.1l-0.3-0.1v0.3l-0.2-0.2l-0.1,0.3l-0.1-0.2v-0.3 M8.1,116.6l-0.3,0.1v-0.1H7.4l0.1,0.1l-0.3-0.1
					L7,116.4 M12.4,116.6l-0.2-0.1 M97.6,116.5l-0.2,0.1l0,0 M11.2,116.5l0.1,0.2L11.2,116.5 M9.3,116.9v-0.2l-0.2-0.2 M97.3,116.7
					L97.3,116.7h-0.4l0,0H97.3H97h-0.2h-0.1l-0.2-0.1l-0.1,0.1l-0.2-0.2 M74.3,116.2l0.3,3.2l-0.8,0.5l-0.3-3.1L74.3,116.2
					M97.2,116.7H97l0,0l0,0h-0.2l-0.1,0.1l-0.2-0.1l-0.1,0.1l-0.2-0.2l0.2,0.2l0,0l0.3,0.2l0.1-0.1h0.3L97.2,116.7 M7,116.6l0.2,0.2
					l0.3,0.1v-0.1h0.3l0,0 M100.3,116.9L100.3,116.9l-0.3,0.2l-0.3,0.1l0.1-0.2l0.3-0.1l-0.1,0.2v0.1l0,0l-0.4,0.1l-0.3-0.1l0.1-0.1
					l0.3,0.1l-0.1,0.1l-0.3-0.1h-0.1L99,117l-0.2,0.1l-0.2-0.2l-0.2,0.1v-0.3l0,0 M11.4,116.6L11.4,116.6L11.4,116.6L11.4,116.6
					M34.6,125.4l0.3,3.2l0.5,0.4l-0.2-3.2l0.7,0.5l-0.2-3.1l1,0.5l0.1,3.2l1.2,0.5v-3.2l1.7,0.5l0.3-3.1l2.2,0.5l0.4-2.6l2.3,0.4
					l0.4-1.8l2.2,0.2l0.4-1.3l1.9-0.1l-0.3,1.4l1.5-0.2l-0.3,2.1l1.2-0.4l-0.4,3.1l-1.2-0.1l0,0l0.4-2.9h-1.6l0.3-1.9h-1.9l-0.3,1.9
					l-2.2-0.2l-0.4,2.6l-2.3-0.4l-0.4,3.1l-2.1-0.4l-0.1,3.2l-1.6-0.5l0.1,3.2L37,130l-0.2-3.2l-1-0.5l0.2,3.2l-0.7-0.4l0.3,3.2
					l-0.4-0.3l-0.2-1.7l-0.2-1.5l-0.1-0.2l-0.3-3.4l-0.2-3.4 M61.9,118l0.4,1.9h-2l0.3,2.8l-1.6,0.1l-0.4-2.9h1.6l-0.3-1.9H61.9
					L61.9,118l-0.4-1.3l2.1-0.1l0.5,1.2l2.2-0.4l0.5,1.8l2.2-0.6l0,0l-0.6-1.9l1.8-1.1l0.6,2.4l1.6-0.6l0.4,3.1l1.1-0.5l0.2,3.2
					l0.8-0.5l-0.1-3.3l0.6-0.5l0.3-0.5l-0.1-3.2 M102.2,116.8L102.2,116.8 M7.8,116.8L7.8,116.8h0.4v0.3l0.2-0.2l-0.1-0.3 M12.6,116.8
					l0.2,0.1h0.1L13,117h-0.1l-0.1-0.1l0,0L12.6,116.8 M96.2,116.9l0.3,0.2l0,0h0.2h0.1h0.1L97,117 M11.3,117v0.3l-0.1-0.1L11.3,117
					M13.6,116.8l-0.2,0.2l0,0l-0.3,0.2h0.1l0.2-0.2 M7,117l0.1,0.2v0.1v0.3L7,117.4V117l-0.1-0.2 M10.6,116.9l-0.1,0.2l0.1,0.1
					l0.2-0.2l0.2,0.1l0.1-0.3 M9.3,117l0.3,0.3v-0.1l0.3,0.1l-0.1-0.2l0.3-0.1l0.1,0.2l0.1,0.1l0,0l0.3-0.2h-0.1V117 M102.4,117
					L102.4,117l-0.4,0.2l0.1-0.2h-0.3l-0.1,0.3l-0.3-0.1l-0.1,0.3l-0.2-0.2v0.1l-0.1-0.3 M102.5,117.1v-0.2 M7,117.2l0.2,0.1L7,117.2
					h0.4v-0.3h0.4v0.3l0.3-0.1v0.3l0.2-0.2v-0.1 M100.2,116.9l-0.3,0.3 M12.7,116.9l0.2,0.1l0.1,0.1h0.1 M102.4,117l-0.1,0.2l-0.2,0.1
					v-0.2L102.4,117 M10.9,117l-0.2,0.3l0.2,0.1l0.2-0.2L10.9,117 M96.8,116.9l-0.3,0.2l0.1-0.1 M7,117.1l-0.1-0.2 M96.5,117.1h-0.2
					M9.9,117.4L9.9,117.4l0.4-0.1l0.2,0.1l0.2-0.2l-0.2-0.1l-0.2,0.2h-0.1l0,0L9.9,117.4L9.9,117.4L9.9,117.4L9.9,117.4H9.7l-0.2-0.1
					L9.9,117.4L9.9,117.4l0.3-0.2l0.2-0.1l-0.2,0.1 M102.4,117.2v0.2V117.2L102.4,117.2l0.1,0.2L102.4,117.2 M99.5,117.4L99.5,117.4
					l-0.2,0.1l-0.2-0.1l-0.2,0.1l-0.2-0.2l-0.2,0.1l-0.2-0.2l-0.1,0.1V117v-0.2 M11.3,117.1l-0.1,0.2l0,0L11.3,117.1L11.3,117.1
					M7.3,117.3L7.3,117.3l0.3,0.3l-0.1-0.4h0.4v0.3l0.3-0.1v0.3l0.2-0.1l0.1-0.2v-0.1 M101.1,117.3l0.1,0.2l0.2,0.2v-0.3l0.3,0.1
					v-0.3h0.3l-0.1,0.4h-0.3v0.3l-0.3-0.1l0.3,0.2v-0.1h0.3v-0.2 M99.9,117.2L99.9,117.2l-0.2,0.1l-0.2,0.1h-0.2h-0.1l-0.3-0.1
					l-0.2,0.1l-0.2-0.2l-0.2,0.1l-0.1-0.2V117 M53.1,119.6l-0.4,3.2h-0.3l0.4-3.2H53.1l0.2-2.5 M9.8,117.2H10l0.3-0.1 M99.7,117.2
					h-0.2H99.7 M8.5,117.2l0.1-0.3 M101.9,117.4l0.2-0.2l0.1-0.1 M10.3,117.4L10.3,117.4l0.2,0.2h-0.1L10.3,117.4L10.3,117.4H10
					l-0.1-0.1 M11.1,117.4l-0.1-0.1l-0.2,0.2l-0.2-0.1h-0.4h-0.1 M7.2,117.3L7.2,117.3l0.2,0.2v-0.2 M10.1,117.4h0.2l0.2,0.2
					L10.1,117.4 M102.5,117.4l-0.2,0.4v-0.3v-0.1 M98.2,117.2l0.1,0.2l0,0l0.3,0.2l0.1-0.1l0.3,0.1l0.2-0.2 M99.4,117.4L99.4,117.4
					l-0.2,0.1L99.4,117.4 M99.2,117.6h-0.3l-0.1,0.1l0.2-0.1H99.2 M8.4,117.5l-0.2,0.2l0,0L8,117.8v-0.3l-0.3,0.1v0.2l-0.2-0.2v-0.1
					M10.8,117.4l-0.3,0.1l0.2,0.2l0.3-0.2l0,0l0.2-0.2L10.8,117.4L10.8,117.4 M102.4,117.4l-0.2,0.1v-0.2 M102.2,117.5L102.2,117.5
					l-0.2,0.1v0.2l-0.3-0.1 M99,117.6L99,117.6l-0.3-0.1h-0.1l-0.3-0.1 M7,117.1l0.2,0.5v-0.3 M10.5,117.7l0.2,0.1l-0.3-0.2
					M98.6,117.6l0.3,0.2l0.1-0.1 M99.4,117.4l-0.2,0.2l-0.2,0.1l0.1-0.1 M102.3,117.7l-0.2,0.2v-0.3 M8.2,117.7l-0.3,0.1v-0.1H7.6
					v0.2H7.4v-0.3"/>
				<path class="st0" d="M10.6,117.7l0.3-0.2 M99.1,117.7h-0.2 M7.4,117.9L7.4,117.9l-0.1-0.2 M8,117.8l-0.3,0.1 M102.2,117.9H102
					M62,118l2.2-0.2l0.4,1.8l2.3-0.4l0.4,2.6l2.2-0.5l-0.5-2.7 M75.6,118.4v3.4l-0.2,0.3v0.1l-0.6,0.4l-0.1,3.2l-0.7,0.5l0.1-3.2
					l-1,0.5l-0.2-3.2l-1.4,0.5l-0.4-2.9l-1.8,0.7 M75.2,122.1l-0.1-3.2l-0.2-3.2 M56.8,119.7l0.4,3.2h0.7l-0.4-3.1L56.8,119.7
					M64.6,119.7l0.4,2.6l-2.3,0.3l-0.3-2.7L64.6,119.7 M35.5,132.3L35.5,132.3l0.7,0.5l-0.3-3.3l0.9,0.5l0.3,3.3l1.1,0.5l-0.3-3.3
					l1.5,0.6v-3.3l1.9,0.5l0.3-3.3l2.3,0.4l0.4-3.1l2.3,0.3l0.4-2.7h2l-0.3,2.8l-2-0.2l-0.4,3.2l-2.3-0.4l-0.4,3.4l-2.2-0.5l-0.2,3.4
					l-1.8-0.5l0.1,3.2l-1.4-0.5l0.2,3.1l-1-0.4l-0.3-3.1l-1-0.5l0.3,3.1l-0.7-0.5l-0.3-3.1 M75.4,121.8l-0.2,3.4l-0.2,0.3v0.1
					l-0.5,0.4l-0.3,3.2l-0.7,0.4l0.2-3.2l-1,0.5v-3.2l-1.4,0.5L71,121l-1.8,0.5l0.3,3.1l1.7-0.4v3.2l1.3-0.5l-0.2,3.2l1-0.5l-0.2,3.3
					l0.7-0.4v-0.1l0.3-3.2l0.5-0.4l0.1-0.2l0.2-3.1 M67.3,122l0.4,3.1l-2.3,0.4l-0.4-3.1L67.3,122 M74,132.4l-0.3,3.1L73,136l0.3-3.1
					l-1,0.5l0.3-3.2l-1.2,0.5l0.1-3.2l-1.6,0.3l-0.1-3.2l-2.1,0.4l0.2,3.3l-2.2,0.5l-0.4-3.4l-2.2,0.3l-0.5-3.3l-2,0.2l0.5,3.3l2-0.3
					l0.5,3.4l2.1-0.4l0.4,3.4l1.9-0.5l-0.2-3.3l2-0.5v3.3l1.5-0.5l-0.2,3.3l1.1-0.5l-0.3,3.1l1-0.5l-0.2,2.8l0.7-0.5V138l0.2-2.6
					l0.4-0.5l0.4-3v-0.2l0.4-3.1 M56.8,122.8l0.7,3.6h0.3l-0.6-3.6H56.8l-0.4-3.2 M35.1,132.1l0.4,3.1l0.3,0.4l0.2,2.6v0.2l0.7,0.5
					l-0.2-2.8l0.9,0.5l0.3,2.8l0.9,0.4l-0.1-2.7l1.2,0.4v-3l1.6,0.5l0.2-3.2l1.9,0.5l0.4-3.4l2.1,0.4l0.5-3.4l1.9,0.3l0.5-3.4l1.6,0.1
					M52,126.5l0.6-3.6 M51.6,122.9l-0.5,3.5l0.7,0.1l0.6-3.6H51.6 M73.5,138.2v1.4l-0.2,1l-0.7,0.5l0.1-2.4l-1,0.5l0.2-2.8l-1,0.4
					l0.2-3.1l-1.3,0.5V131l-1.8,0.6l0.2,3.2l-1.7,0.5l-0.4-3.1l-1.9,0.5l-0.6-3.4l-1.8,0.4l-0.7-3.5l-1.5,0.2l-0.5-3.4l-1.2,0.1
					l0.6,3.5l1.1-0.1l0.8,3.6l1.4-0.3l0.8,3.4l1.7-0.4l0.6,3.1l1.7-0.5l0.4,2.8l1.5-0.4l-0.1-2.9l1.6-0.5v3l1.2-0.4l-0.1,2.7l0.9-0.4
					l-0.2,2.4l1-0.4v1.9l0.8-0.4v-0.2l-0.1-1.9l0.1-0.5l0.2-2.4l0.4-2.7 M34.4,125.4l-0.1-0.2 M75.1,125.5l0.2-3.2 M36.1,138.3
					l0.1,2.2v0.2l0.7,0.5l-0.2-2.4l1,0.5l0.1,2.4l0.8,0.3v-2.3l1,0.3v-2.6l1.4,0.4l0.1-2.9l1.7,0.5l0.4-3.1l1.9,0.5l0.6-3.4l1.9,0.4
					l0.6-3.5l1.5,0.2l0.5-3.4 M36.2,140.7l-0.1,1.9v0.1l0.7,0.4v-1.9l1,0.4v1.9l0.7,0.3v-1.9l0.9,0.3l0.1-2.2l1.2,0.3l0.2-2.5l1.5,0.4
					l0.4-2.8l1.7,0.4l0.6-3.1l1.6,0.4l0.8-3.4l1.4,0.3l0.8-3.6l1.1,0.1l-0.8,3.6l-1-0.2l-0.9,3.4l-1.3-0.3l-0.8,3.1l-1.5-0.3l-0.5,2.8
					l-1.5-0.4l-0.3,2.5l-1.4-0.3l-0.2,2.2l-1.2-0.3l-0.1,1.9l-0.8-0.2l-0.2,1.5l-0.7-0.3l0.1-1.6l-1-0.3l-0.1,1.7l-0.8-0.3l-0.1-0.2
					l0.2-1.9v-2.2l-0.2-2.5l-0.3-2.7 M51,130.1l1-3.6h-0.4l-0.9,3.6h-0.6l-1,3.4l-1-0.2l-0.9,3.1l-1.2-0.2l-0.7,2.7l-1.4-0.3l-0.4,2.4
					l-1.4-0.3l-0.3,2.1l-1.3-0.3l-0.2,1.8l-1.1-0.3l-0.2,1.6l-0.7-0.3l-0.2,1.5l-0.7-0.2l-1-0.3l0.2-1.5l1,0.2l-0.2,1.5l-0.3,1.4
					l-0.9-0.2l-0.7-0.2l-0.1-0.1l0.1-1.4l0.2-1.6 M58.5,126.4l0.9,3.6h-0.6l-1-3.6H58.5 M69.8,137.3L69.8,137.3l-1.4,0.4l0.2,2.5
					l-1.4,0.3l-0.3-2.5l-1.5,0.4l-0.6-2.7l-1.5,0.4l-0.8-3.1l-1.3,0.3l-0.9-3.4l-1,0.2l1,3.4l-0.6,0.1l-1-3.4h-0.3c0,0-0.9-3.5-1-3.6
					M74.8,128.6l0.1-0.2l0.3-3.3 M36.2,142.8l-0.2,1.8l-0.1,1.5l0.7,0.2l-0.1,1.4l-0.3,1.4l0.8,0.3l0.3-1.4l0.7,0.3l0.3-1.4l0.7,0.3
					l0.2-1.5l1.1,0.2l0.2-1.6l1.3,0.2l0.2-1.8l1.3,0.3l0.3-2l1.3,0.3l0.5-2.3l1.1,0.2l0.8-2.7l1,0.1l0.9-3l0.6,0.1l1-3.4h0.3
					M58.5,130.1c0,0.1,0.9,3.3,0.9,3.4h0.3l0.8,3l0.7-0.1l-0.9-3l0.9-0.2l0.9,3.1l1.2-0.2l0.7,2.7l1.4-0.3l0.5,2.4l1.4-0.3l0.3,2.1
					l1.3-0.3l-0.2-2.2l1.3-0.3v-2.6 M35,130.3l-0.2-1.7 M74.4,131.9l0.5-3.4 M74.4,132.1l-0.4,0.3 M50.1,133.6l1-3.4 M35.8,147.6
					l0.2-1.4l-0.1-0.1 M50.2,133.6l-0.7,3h0.2L50.2,133.6h-0.3l-0.8,3l-0.7-0.1l-0.8,2.7l-0.9-0.1l-0.7,2.3l-1-0.2l-0.4,1.9l-1.2-0.2
					l-0.3,1.7l-1.3-0.2l-0.3,1.6l-1.2-0.2l-0.3,1.5l-1-0.2l-0.3,1.4l-0.7-0.3l-0.4,1.3l-0.7-0.2l-0.5,1.4l-0.6-0.2l0.3-1.5l-0.6-0.2
					l0,0v-1.4 M62.1,136.4l0.8,2.7l-0.9,0.1l-0.8-2.6L62.1,136.4 M59.4,133.6l0.7,3h-0.2L59.4,133.6 M35.7,147.5l-0.2,1.3l-0.1,1.5
					l0.4,0.2l-0.5,1.8l0.5,0.2l0.6-1.7l0.7,0.2l0.5-1.4l0.8,0.3l0.3-1.3l1.1,0.3l0.2-1.4l1.2,0.2l0.3-1.5l1.2,0.2l0.3-1.5l1.1,0.1
					l0.4-1.7l0.9,0.1l0.5-1.9l0.9,0.1l0.7-2.3l0.8,0.1l0.7-2.6h0.4l-0.5,2.6l0.2-0.1l0.4-2.6 M59.9,136.6l0.4,2.6l0.2,0.1L59.9,136.6
					h0.5l0.7,2.6l0.8-0.1l0.7,2.3l0.9-0.1l-0.6-2.3l1.1-0.2l0.5,2.3l1.2-0.3l0,0 M35.9,137.8l0.2,0.3 M73.7,137.7l-0.2,0.4
					M35.4,150.4l-0.3,1.8l-0.5,2.1l0.1,0.1l0.4,0.2l0.7-2l0.7,0.2l0.6-1.7l0.8,0.2l0.4-1.4l1.1,0.3l0.3-1.4l1.2,0.2l0.3-1.4l1.2,0.2
					l0.3-1.5l1.1,0.1l0.3-1.5L45,145l0.4-1.6l0.8,0.1l0.6-1.9h1l0.6-2.3h0.5l-0.4,2.3l0.3-0.1l0.3-2.2 M60.4,139.1l0.3,2.2l0.3,0.1
					l-0.3-2.2h0.6l0.5,2.3H61l0.3,1.9l-0.4-0.1l-0.2-1.9 M73.3,142.8l0.1,1.8l-0.8,0.3l-0.1-1.7l-1,0.3v-1.9l-0.8,0.3v-2.3l-1,0.3
					l0.1,2.2l0.9-0.3l0.1,1.9l0.7-0.3l0.1,1.6l1-0.3l0.2,1.5l0.8-0.2l-0.2-1.5l0.2-0.2l-0.2-1.9v-2.2 M36.1,140.3l0.1,0.2 M65.8,140.9
					l0.4,2l-1.2,0.2l-0.4-1.9l-1,0.2l0.6,1.9l0.9-0.1l0.4,1.7l1.1-0.1l-0.3-1.7l1.3-0.3l0.3,1.8l1.3-0.2l-0.2-1.8l1.2-0.3l0.1,1.8
					l0.6-0.3l0.1,1.6l0.7-0.3l0.2,1.5l1-0.3l0.2,1.4l0.7-0.2l-0.2-1.4l0.2-0.1l-0.2-1.6 M48.8,141.4l-0.2,1.9l-0.4,0.1l0.3-1.9h-0.8
					l-0.4,1.9h-1.1l-0.4,1.6l-0.9-0.1l-0.3,1.5l-0.9-0.1l-0.3,1.5l-1.1-0.1l-0.3,1.5l-1.3-0.2l-0.3,1.4l-1.2-0.3l-0.4,1.4l-1.1-0.3
					l-0.5,1.6l-0.9-0.2l-0.7,2l-0.7-0.2l-0.8,2.4l-0.5-0.2v0.2l0.7-2.5 M74,148.8L74,148.8l-0.6,0.3l-0.3-1.4l-0.9,0.2l-0.3-1.4
					l-0.6,0.2l-0.2-1.5l-0.7,0.2l-0.2-1.6l-1.1,0.2l0.2,1.6l-1.3,0.2l-0.3-1.5l-1.3,0.2l0.3,1.5l-1.1,0.1l-0.3-1.5l-0.9,0.1l-0.4-1.6
					l-0.9,0.1l-0.5-1.9h-1l0.5,1.9h-0.9l0.3,1.5l-0.4-0.1l-0.2-1.6 M73.8,146l0.1,1.4l-0.1,0.1l0.1,1.4 M73.4,142.6l0.1-0.2
					M36.1,142.5l0.1,0.2 M33.9,156.8l-0.5,2.2l-0.2,0.7l0.4,0.2l0.7-2.8l0.6,0.2l0.8-2.4l0.9,0.2l0.7-2l1.2,0.3l0.5-1.7l1.3,0.3
					l0.3-1.4l1.2,0.2l0.3-1.4l1.1,0.1l0.3-1.5h0.9l0.3-1.5h0.9l0.3-1.4H47l0.3-1.6h0.9l-0.3,1.5l0.4-0.1l0.3-1.6 M35.1,152.2l0.2,0.2
					l-0.6,2.1l-0.8,2.4 M62.2,143.4l0.3,1.6h1.1l-0.4-1.6H62.2 M61.1,144.8l0.3,1.4l0.4,0.1l-0.3-1.4l1,0.1l0.3,1.4h-1l0.4,1.4
					l-0.4-0.1l-0.4-1.4 M47.9,144.9l-0.3,1.4h-1l0.3-1.4H47.9 M63.5,145l0.3,1.4h0.9l-0.3-1.5L63.5,145 M71.2,146.8L71.2,146.8
					l-0.7,0.3l-0.2-1.5l-1,0.2l0.2,1.5l1.1-0.2l0.3,1.4l0.7-0.3L71.2,146.8 M72.1,147.9L72.1,147.9l-0.7,0.3l0.4,1.3l-0.8,0.3
					l-0.3-1.3l-1,0.3l-0.3-1.3l-1.2,0.2l-0.3-1.5l-1.2,0.2l0.3,1.5l1.2-0.2l0.3,1.5l1.2-0.2l0.3,1.4l1.1-0.3l0.4,1.4l0.8-0.2l-0.5-1.4
					l0.7-0.2L72.1,147.9 M48,146.3l-0.5,1.4l-0.4,0.1l0.4-1.4L48,146.3l0.3-1.4 M61.9,147.7l0.6,1.5l0.3,0.1l-0.5-1.5h0.9l-0.3-1.4h1
					l0.3,1.5h-1l0.4,1.5l-0.8-0.1l0.6,1.5l-0.3-0.1l-0.6-1.4 M47.6,147.7l-0.6,1.5l-0.3,0.1l0.5-1.5h-0.9l0.4-1.4h-1.1l-0.3,1.5h1
					l-0.4,1.5l0.8-0.1l-0.5,1.5l0.3-0.1l0.6-1.4 M63.1,150.6l0.6,1.4l0.2,0.1l-0.5-1.4H64l-0.4-1.4h1l-0.3-1.5h0.8l-0.3-1.5l0.8-0.1
					l0.3,1.5l1.1-0.1l0.3,1.5l1.2-0.2l0.3,1.4L70,150l0.3,1.5l1.1-0.3l0.5,1.7l0.9-0.2l0,0l-0.6-1.7l0.7-0.2l-0.5-1.5l0.8-0.3l0.3,1.5
					l0.4-0.2V150v-1.2v-1.4 M44.5,147.8l-0.3,1.5H45l0.3-1.5H44.5 M72.9,152.8l0.7,2l-1,0.2l-0.7-2l-1.2,0.2l-0.4-1.7l-1.3,0.3
					l-0.3-1.4l-1.2,0.2l-0.3-1.4l-1.1,0.1l-0.2-1.5H65l0.3,1.5h-0.8l0.3,1.5H64l0.3,1.5l-0.5-0.1l0.3,1.4l-0.1-0.1l-0.4-1.4
					M33.2,159.6l-0.5,3.2l0.4,0.3l0.5-3.2l0.6,0.2l0.7-2.8l1,0.2l0.8-2.4l1.3,0.2l0.6-1.9l1.3,0.2l0.4-1.6l1.2,0.2l0.3-1.5l1.1,0.1
					l0.3-1.4h0.9l-0.2,1.4l-0.9-0.1l-0.3,1.5l-1.2-0.1l-0.3,1.6l-1.3-0.2l-0.5,1.9l-1.4-0.3l-0.7,2.5l-1.3-0.2l-0.7,2.9l-1-0.3
					l-0.6,3.3l-0.6-0.3l-0.3,3.4l-0.3-0.3l0.2-3.4 M46.4,150.6l-0.6,1.4l-0.2,0.1l0.5-1.4l-0.6,0.1l0.4-1.5h-1l-0.3,1.5h-0.7l-0.2,1.5
					l-1-0.1l-0.3,1.5l-1.2-0.1l-0.4,1.9l-1.4-0.1l-0.6,2.5l-1.5-0.2l-0.7,2.9l-1.4-0.3l-0.6,3.4l-0.9-0.3l-0.3,3.5l-0.6-0.3v3.3
					l-0.2-0.3v-3.3 M64.1,153.4l0.2,1.1l-0.1-1l0.4,0.1l-0.3-1.3H65l-0.2-1.5h0.7l-0.2-1.4h0.8l0.3,1.4l1.1-0.1l0.3,1.5L69,152
					l0.4,1.6l1.3-0.2l0,0 M74,150.1l-0.2-1.2 M45.3,153.6l0.3-1.4l-0.5,0.1l0.3-1.5h-0.8l-0.2,1.4h0.7l-0.2,1.4H45.3L45.3,153.6
					l-0.1,1.5L45.3,153.6 M45.9,152.1l-0.5,1.4 M64.3,154.6l0.1,0.5l0.3,0.2l-0.1-1.6l0.6,0.1l-0.2-1.4h0.8l-0.3-1.5l0.9-0.1l0.3,1.5
					l1.1-0.1l0.4,1.6l1.3-0.2l0.5,1.9l1.5-0.2l-0.6-1.9 M73.6,150.6l0.5,1.7l0,0l-0.5,0.2l-0.6-1.7L73.6,150.6 M74.2,152.3l0.2-0.1
					l-0.3-1.8 M45.1,155L45.1,155l-0.3,0.1l0.1-1.6l-0.6,0.1l0.2-1.4h-0.8l-0.2,1.5h-1l-0.3,1.8l1.1-0.1l0.2-1.8h0.8l-0.1,1.7l0.6-0.1
					l-0.1,2.2l0.3-0.2L45.1,155 M64.4,155l0.2,2.2l0.3,0.2l-0.1-2.2l0.6,0.1l-0.1-1.7h0.8l-0.2-1.5h0.9l0.3,1.5h-1l0.2,1.8l-0.9-0.2
					l0.1,2.3l-0.6-0.2l0.1,2.8l-0.2-0.3l-0.1-2.7 M74.2,152.4l0.6,2.1l-0.4,0.1l-0.7-2l-0.7,0.2 M64.6,160l0.2,3.5l0.1,0.1l-0.1-3.4
					l0.6,0.3l-0.1-2.9l1,0.2l-0.1-2.3l1.1,0.1l-0.3-1.8l1.2-0.1l0.4,1.9l1.4-0.1l0.6,2.5l1.5-0.2l-0.7-2.5l1.3-0.2l0.8,2.4l1-0.2
					l-0.8-2.4l0.7-0.2l0,0 M74.9,154.3l0.7,2.5l-0.1,0.1l-0.7-2.4L74.9,154.3l-0.5-2.1 M45,157.2l-0.1,2.8l-0.2,0.2v-2.7l-0.6,0.2
					v-2.3l-0.9,0.1l-0.1,2.4l1-0.2v2.9l0.6-0.2v-0.1 M42.2,155.6L42.2,155.6l-1.3-0.1l-0.4,2.4l-1.6-0.1l-0.7,2.9l-1.6-0.2l-0.7,3.4
					l-1.3-0.3l-0.4,3.5l-0.9-0.3l-0.1,3.2l-0.5-0.3l0.1,3l-0.1-0.1l-0.2-3.1 M67.3,155.6l0.3,2.4H69l-0.4-2.4H67.3 M75.5,156.8
					L75.5,156.8l-0.4,0.2l-0.8-2.4 M76.2,159.4v0.2l-0.4,0.2l-0.7-2.8l-0.7,0.2l0.7,2.8l0.6-0.2l0,0 M75.5,156.8l0.7,2.8 M73.5,157.4
					l0.7,2.9l0,0l-1.3,0.3l-0.7-2.9L73.5,157.4 M32.9,172.8v0.1l0.5,0.3l-0.1-3.1l0.8,0.3l0.1-3.2l1.2,0.3l0.5-3.5l1.6,0.2l0.6-3.4
					l1.8,0.1l0.5-3h1.5l0.3-2.4 M44.1,160.5L44.1,160.5l-1,0.2l0.1-2.9l-1.3,0.1l-0.3,3l1.4-0.2l-0.2,3.5l1.1-0.2L44.1,160.5
					M65,163.6L65,163.6l0.6,0.4l-0.2-3.5l1,0.2l-0.2-2.9l1.3,0.1l0.3,2.9l-1.4-0.2l0.2,3.5l-1.1-0.2l0.3,3.5l-0.6-0.3V167l-0.4-3.7
					M65.3,167.1l-0.3-3.5 M74.2,160.3l0.6,3.4l-1.3,0.3l-0.6-3.4l-1.6,0.2l-0.7-3l-1.6,0.1l0.5,2.9h-1.7l0.3,3.5l-1.5-0.1l0.3,3.5
					l-1.1-0.2l0.7,3l-0.7-0.3l0,0l-0.7-3.1 M33.4,158.9l0.5-2.1 M76.2,159.6l0.3,1.9l-0.3-2l-0.6-2.8 M75.8,159.8l0.5,3.3l-0.6,0.3
					l-0.6-3.3l-1,0.2 M39.9,161l-0.5,3.4h1.9l0.3-3.5L39.9,161 M66,170.3l-0.7-3 M76.6,161.5l0.2,1.3l-0.4,0.3l0.3,3.4l-0.6,0.3
					l-0.3-3.5l-1,0.3l0.4,3.5l-1.2,0.3l-0.5-3.5l-1.6,0.2l-0.6-3.4l-1.7,0.1l0.5,3.4h-1.8l0.4,3.5l-1.5-0.2l0.6,3l-1-0.2l0.8,2.8
					l-0.6-0.3l0,0l-0.8-2.8 M44.7,160.3l-0.2,3.4l0.2-0.2l0.2-3.4 M44.7,163.5l-0.4,3.4l-0.1,0.1l0.3-3.4l-0.6,0.3l-0.4,3.5l0.6-0.3
					v-0.2 M33,172.9l0.2,2.9l0.1,0.1l0.4,0.3l-0.2-3l0.7,0.3v-3l1.1,0.3l0.3-3.2l1.6,0.2l0.5-3.5l1.9,0.1l-0.5,3.5l-1.9-0.1l-0.4,3.1
					l-1.4-0.2l-0.1,3l-1-0.2l0.2,3l-0.6-0.3l0.3,2.8l-0.4-0.3l-0.1-0.1l-0.2-2.6 M44.1,167.2l-0.7,2.9l-0.7,0.4l0.7-3l-1.1,0.2
					l0.4-3.5l-1.5,0.1l-0.3,3.5H39l-0.5,3.1l-1.8-0.1l-0.2,3l-1.3-0.2l0.1,3l-0.9-0.2l0.2,2.8L34,179l0.2,2.5l-0.4-0.3l-0.1-0.1
					l-0.2-2.3 M76.8,162.8l0.2,3.3l-0.3,0.3l0.1,3.2v0.1l-0.5,0.3l-0.1-3.3l-0.9,0.3l0.2,3.2l-1.2,0.3l-0.2-3.2l-1.6,0.2L72,164
					l-1.9,0.1l0.5,3.5l1.9-0.1l0.4,3.1l1.5-0.2l0.1,3l1-0.2l0,0v-3l0.8-0.3l-0.1,3.1l0.5-0.3l0.2-3.1 M44.2,166.9l-0.7,3.1l-1,3.2
					l-0.6,0.2l0.8-2.8l-1,0.2l0.6-3h-1.4l-0.6,3.1l1.5-0.2l0,0 M68.5,167.8l0.6,3h1.8l-0.5-3.1L68.5,167.8 M76.7,169.7l0.2-0.2v-3.3
					M43.6,170l-0.1,0.1l-1.7,6l0.9-2.9 M66.8,173l0.5,1.7l0.4,1.4l0.5,0.3l-0.8-3l1,0.2l1.3,0.2l-0.6-2.9l-1.5-0.2l0.8,2.8l0.6,3
					l-0.8-0.2l0.6,2.9l-0.3-0.2l-0.8-2.9 M33.9,181.2l0.1,2.1l0.1,0.2l0.4,0.3l-0.1-2.2l0.5,0.2l-0.3-2.6l0.8,0.2l-0.1-2.8l1.1,0.2
					l0.1-3h1.7l0.4-2.9h1.8l-0.6,2.8l1.3-0.2l0.7-2.8 M68.5,179.1l0.5,2.6l0.3,0.2l-0.5-2.6l0.6,0.2l-0.5-2.9l1.1,0.2l-0.4-3l1.7,0.1
					l-0.4-2.9l1.8-0.1l0.3,3l1.3-0.1v3l0.9-0.2l0.2-3 M76.8,172.6l-0.3,3l-0.1,0.2l0.2-2.9L76.8,172.6l0.2-3.2 M67.3,174.7l-0.5-1.7
					l-0.8-2.8 M42.1,173.3L42.1,173.3l-1,0.2l-0.6,3l0.8-0.2L42.1,173.3 M76.4,175.7v0.1l-0.4,0.3l0.2-3l-0.7,0.2 M40.6,176.5
					L40.6,176.5l-1.1,0.2l0.4-3l-1.7,0.1l-0.3,3h-1.5v2.8l-1-0.2l0.2,2.6l-0.7-0.2l0.2,2.3l-0.6-0.3l-0.1-0.1 M76.6,175.6l-0.3,2.8
					l-0.2,0.2v0.1l-0.4,0.3l0.3-2.8l-0.7,0.3l-0.2,2.8l-0.8,0.2l0.1-2.8l-1.1,0.1l-0.1-3h-1.6l0.2,2.9l-1.5-0.1l0.3,2.9l-1-0.1L70,182
					l-0.6-0.2v-0.1 M33.4,175.7l-0.1-0.1l-0.3-3 M42,176l-0.5,0.3l-0.6,2.8l0.4-0.2L42,176 M34.6,183.7l0.1,2.1l0.6,0.3l-0.2-2.1
					l0.6,0.3l-0.1-2.3l0.8,0.2v-2.6h1.5l0.1-2.8l1.5-0.1l-0.3,2.9l1-0.1l0.5-2.9 M69.4,181.9l0.4,2.3v0.1l0.5,0.2l-0.4-2.4l0.8,0.1
					l-0.3-2.6h1.4l-0.2-2.8h1.5v2.8l1-0.2L74,182l0.7-0.3l0.2-2.5l0.6-0.3l-0.2,2.5l0.4-0.3V181l0.2-2.4 M33.4,178.5l-0.1-0.1
					l-0.3-2.8 M75.9,178.5l0.3-2.7 M40.9,179l-0.5,2.4 M40.5,179.1L40.5,179.1l-0.6,0.2l-0.4,2.6l0.6-0.2L40.5,179.1 M33.8,183.3v1.9
					v0.1l0.5,0.3l0.1,1.9l0.7,0.3l-0.1-1.9l0.6,0.3l-0.1-2.1l0.8,0.2l-0.1-2.3l1.2,0.1l0.2-2.6h1.3l0,0 M75.6,181.1l-0.2,2.3l-0.5,0.3
					l0.2-2.3l-0.6,0.3l-0.2,2.3l-0.6,0.3l0.1-2.3l-0.9,0.2v-2.6h-1.4l0.3,2.7h-1.2l0.3,2.3l-0.7-0.1l0.4,2.1l-0.4-0.2l-0.4-2.2
					M75.8,181l0.1-0.2l0.2-2.4 M33.7,181l-0.1-0.1l-0.2-2.5 M40.1,183.8l0.4-2.3v0.2 M39.7,186.3l-0.1,1.3l0.1-0.2V186.3v-0.4l0.3-2
					l-0.1,0.2l-0.3,0.2l0.5-2.4l0.3-0.2L40,184 M69.1,181.6l0.3,1.3l0.1,0.8v0.1l-0.2-1 M40,183.9l-0.4,2.1l-0.3,0.2l0.4-2l-0.5,0.2
					l0.4-2.3l-0.9,0.1l0.3-2.6 M34,185.5v1.8v0.1l0.5,0.4v1.7l0.7,0.3v-1.7l0.6,0.3l-0.1-1.9l0.7,0.2v-2.1l1,0.1l1-0.1l0,0l0.3-2.3
					h-1.2l-0.1,2.3l-0.1,2.1l-0.9-0.1v1.9l-0.6-0.2v1.7l-0.6-0.2v1.6l-0.8-0.3l0.1-1.6l-0.6-0.3v-0.1l0.1-1.6 M71.9,182.3l0.2,2.3
					l1-0.1l0,0v-2.4L71.9,182.3 M75.8,183l-0.1,2.1l-0.2,0.3v-1.9L75.8,183l0.1-2.2 M33.9,183.2l-0.1-0.2l-0.2-2.2 M69.4,183.9
					L69.4,183.9l0.3,0.3 M75.1,183.7l-0.1,2.1v0.1l-0.6,0.3l0.2-2.1L75.1,183.7 M73.2,184.5l0.8-0.2l-0.1,2.1l0.6-0.3l-0.1,1.9
					l0.5-0.2l0.1-1.9 M39.2,186.3L39.2,186.3l-0.4,0.2l0.3-2.1l-0.7,0.1 M69.5,184l0.4,2v0.1l0.4,0.3l0.4,1.9l0.4,0.2l-0.3-1.9
					l0.6,0.1l-0.3-2.1h1l0.1,2.1h-0.9l0.2,1.9l-0.5-0.1l0.2,1.7l-0.3-0.2l-0.2-1.7l-0.4-0.2V188l-0.3-1.8 M34,185.4l-0.1-0.2l-0.1-2.1
					M75.1,185.9l0.4-0.3v-0.2 M69.8,186.5l0.2,1.1l-0.1-0.1L69.8,186.5l-0.1-0.7l-0.3-1.9 M69.7,185.8l0.2,0.2 M39.5,187.6v0.1
					l-0.3,0.3l0.3-1.9l0.2-0.3 M38.8,186.5L38.8,186.5l-0.6,0.1l0.2-2.1 M75.5,185.5v1.9l-0.6,0.4v1.8l-0.7,0.3v-1.8l-0.6,0.3l0.1-1.9
					l-0.7,0.2v-2.1 M33.9,189.2l-0.2,1.7l0.7,0.3l-0.1,1.6l1,0.1l-0.1-1.5l0.6,0.2V190l0.6,0.2v-1.7l0.8,0.1l0.1-1.9h0.9l-0.2,1.9
					l0.5-0.1l0.3-1.9 M70.3,188l0.1,1.7l0.5,0.3v1.5l0.4,0.2v-1.5l0.4,0.1l-0.1-1.7h0.8l-0.1-1.9l1-0.1v1.9l0.6-0.2v1.7l0.6-0.2v1.6
					l0.8-0.2v-1.6l0.6-0.4l-0.1-1.7l0.2-0.4v-1.9 M34.2,187.3L34,187v-1.9 M70.4,187.9l-0.2-0.2v-0.1 M39.7,187.6l-0.1,1.5v0.2
					l-0.4,0.3l0.2-1.7l-0.4,0.3l0.4-1.9 M76.1,188.7l0.3,1.6v0.1l-0.4,0.4l-0.2-1.7L76.1,188.7v-0.5l-0.1-1.3 M39.1,188.1l-0.2,1.7
					l-0.3,0.2l0.2-1.7L39.1,188.1 M73.3,188.4L73.3,188.4l-0.8,0.1l0.1,1.7h-0.7v1.5l-0.4-0.1l-0.4,1.3l-0.3-0.2l-0.6-0.3l0.2-1.2
					v-1.5l-0.3-0.3v-0.1l-0.1-1.5 M33.9,189.1l-0.3-0.3l0.2-1.7 M38.3,190.1L38.3,190.1l-0.4,0.2l0.1-1.7h-0.8v1.7l-0.8-0.1v1.5
					l-0.5-0.1L36,193l-0.6-0.1v1.5l-1.1-0.3l0.1-1.4l-0.9-0.2l0.2-1.8l-0.3-0.4v-0.2l-0.1-0.2l0.3-1.3l-0.3,1.5 M70.1,189.1l-0.1-0.2
					l-0.1-1.6 M39.5,189.1l0.1-0.2l0.1-1.7 M39.4,189.3l0.1,1.4l-0.4,0.3v-1.5l-0.4,0.3l-0.1,1.5l0.5-0.3l0.2,1.3l0.5-0.4v-0.1
					l0.1-0.2l-0.2-1.2v-1.3 M76.2,190.1l-0.4-1.8 M73.7,190L73.7,190l-0.6,0.2v-1.7 M76.2,190.3l0.1-0.3l0.1,0.2l0.4,1.2l0.1,0.2
					l0.1,0.2l0.6,0.7l0.5,0.3l-1.3-1.2 M38.3,190.1v1.5l-0.4,0.2v-1.5h-0.7v1.5l-0.7-0.1l0.1,1.4L36,193l0.2,1.4h0.7l-0.2-1.3h0.7
					l-0.2-1.3h0.7l0.2,1.3l0.3-0.1l-0.1-1.3l0.3-0.2l0.1,1.3l0.5-0.3l0.2,1.2l0.4-0.4v-0.5l-0.1-1.1 M70,189.1l-0.1,1.4l0.1,0.2v0.2
					l0.4,0.3l0.4,0.3l-0.1,1.3l-0.2,1.2l0.3,0.3l0.3-1.2l0.4,0.1l0.2-1.3h0.7v-1.5l0.7-0.1v1.5l0.5-0.1v-1.6 M70,190.6l0.1-1.3
					M39.6,190.4l-0.2,0.4l0.3,1.1 M32.6,191.7l-0.2,0.2l-0.3,0.3l0.4-0.4L32.6,191.7l0.1-0.1l0.2-0.6l0.3-1 M76.7,191.5v0.4l0,0
					l-0.2,0.3l-0.5,0.4l-0.3-1.7l-0.7,0.3l0.1,1.6l1-0.2l0.2,1.5l0.6-0.3v-0.1l-0.3-1.5l-0.4-1.8 M74.1,191.4L74.1,191.4l-0.6,0.2
					l-0.1,1.4l0.6-0.1L74.1,191.4 M70,190.8l-0.2,1.1l-0.1-0.3l0.2-1.2 M73,191.7L73,191.7l-0.7,0.1l-0.2,1.3l-0.7-0.1l-0.3,1.2
					l-0.3-0.2l-0.3,1.2l-0.2-0.3l0.3-1.2l-0.5-0.3l-0.5-0.4l0.2-1.1v-0.1 M33,191l-0.2,0.8l0,0v-0.3 M76.7,191.9l-0.4-1.6 M76.9,191.9
					l0.4,1l-0.4-0.5l-0.2-0.4 M33.4,190.5l-0.4,1.7l-0.2-0.3 M33,192.2L33,192.2l0.5,0.4l-0.2,1.5l-0.6-0.3l0,0l-0.3-0.4v-0.3l0.1-0.7
					l0.2-0.5l-0.2,1.2 M69.8,192l0.4,0.4l-0.2,1.2l-0.1,1.1l-0.3-0.4v-1.1l-0.1-0.5l0.2-1 M32.5,192l-0.9,0.8l0.4-0.2l0.2-0.3
					M32.6,192.4l-0.3,0.3l0.3-0.9 M31.9,192.6l-0.5,1.2l0.7-0.8l0.1-0.1 M77.6,192.6l0.5,1.2l-0.7-0.8v-0.1 M39.7,192l0.2,1.1v0.2v1
					l-0.3,0.4l-0.1-1.1l-0.5,0.3l-0.3,0.2l-0.3-1.2l0.3-0.2l0.2,1.2l0.3,1.1l0.4-0.4l0.2,1l0.1-0.2v-1.2 M74.1,192.9v1.5l1.1-0.3
					l0.2,0.9l0.7,0.1v-1h-1l-0.1-1.4L74.1,192.9 M30.3,193.6l0.2,0.1l-0.3,0.2l-0.2-0.1L30.3,193.6h0.3l0.7-0.6l0.2-0.2 M69.7,194.2
					v1.2l0.1,0.2v0.2l0.2,0.4l0.2,0.3l0.4-1.2l0.3,0.2l0.3-1.2l0.7,0.1l0.3-1.2h0.7l-0.2,1.3h0.6l0.1-1.4l-0.5,0.1l0.1-1.4 M38,193.1
					l0.3,1.2l0,0l-0.7,0.1l-0.3-1.2L38,193.1 M39.9,193.3v-0.5 M76.7,191.9l0.3,1.5l-0.1-1 M77.4,192.9l-0.1,0.8l-0.3,0.1v-0.4
					l-0.3,0.4 M79,193.6L79,193.6l0.1,0.1l0.2-0.1H79l-0.9-0.7l0.7,1l-0.7-0.1l0,0l-0.5,0.9l-0.2-0.9 M30.7,193.6l-0.1,0.1l-0.2,0.3
					l-0.1-0.1l-0.3,0.3v-0.1l0.2-0.3 M32.6,193.4v0.3h-0.3v-0.8 M79.1,193.7l0.2,0.2h0.2L79.1,193.7 M32.3,193.7l-0.2,0.9l-0.5-0.9
					l0,0l-0.7,0.1l0.6-0.8 M79.7,193.8l0.2,0.3l-0.1,0.2l-0.3-0.3L79.7,193.8l-0.3-0.2 M32.8,193.8l0.3-1.5 M79.3,193.9l0.6,0.8
					l-0.6,0.1l-0.5-1L79.3,193.9l0.2,0.2L79.3,193.9L79.3,193.9 M29.8,194v0.2h0.1l0.1,0.1l0.2-0.1l0.1-0.1l0.4-0.1l-0.5,1h-0.6
					l0.5-0.7 M79.8,194v0.2l0,0l0,0l-0.1,0.1l-0.1-0.1 M29.4,194.4h0.1l0.2-0.2 M29.8,194.2l0.1,0.1H30L29.8,194.2L29.8,194.2
					l-0.4,0.1l-0.1,0.1 M32.8,193.9l0.1,1.1l0.6,0.3v-1h1l-0.2,0.9l-0.7,0.1l0.2,0.7l-0.6-0.1v-0.1l-0.2-0.7l-0.3-0.5v-0.7
					M79.7,194.2L79.7,194.2h0.2 M79.9,194.2l0.2,0.2h0.2l0,0L79.9,194.2 M38.4,194.4l0.4-0.2l0.3,1.2l0.2-0.3l0.3,1.1l0.2-0.3v-0.2
					M79.8,194.3l0.1,0.1l0.1,0.1l0.2-0.1l0.2,0.3h0.2l-0.3-0.3l0,0l0.1,0.1l0.2,0.1l0.1-0.1l-0.4-0.1v0.1"/>
				<path class="st0" d="M29.9,194.2l-0.1,0.1l-0.1,0.1l-0.1-0.1l-0.2,0.3h-0.2l0.3-0.3 M29.3,194.3l-0.3,0.1l0.1,0.1l0.2-0.1
					L29.3,194.3 M69.8,195.6l0.2-1l0.4,0.4l-0.4,1.2l-0.4,1.2v0.1l0.3,0.4l0.4-1.4l0.4,0.2l0.3-1.3l0.8,0.2l0.3-1.2h0.8l-0.2,1.3
					l-0.9-0.1l-0.2,1.3l-0.9-0.2l-0.2,1.5l-0.6-0.3l-0.3,1.5l-0.4-0.5l0.1-0.4l0.3-1 M76.8,193.9l-0.1,1.1l0.3-0.5v-0.7 M79.9,194.5
					L79.9,194.5L79.9,194.5L79.9,194.5L79.9,194.5 M29.7,194.4L29.7,194.4l0.1-0.1 M37.7,194.4l0.3,1.2l-0.9,0.1l-0.2-1.3H37.7
					M34.2,195.1l0.4,0.4l0.9-1l0,0h0.8l0.1,1.4l0.7-0.1l0.1,1.4l1-0.1l-0.2-1.4l0.8-0.1l-0.3-1.2 M75.4,195.1l-0.4,0.5l-1-1l0,0
					l-0.7-0.1l-0.1,1.4h-0.7l-0.2,1.2l-1-0.1l-0.1,1.5l-1-0.2l-0.1,1.5l-0.7-0.3v1.4l-0.4-0.4v-1.4 M28.9,194.4l-0.2,0.2v0.1l0.3-0.2
					l0,0l0.1-0.1 M80.6,194.4l0.2,0.3L80.6,194.4l-0.1,0.1l-0.1-0.1 M29.5,194.4l-0.2,0.2h-0.1l-0.1,0.2v0.1l-0.5,0.6l0.5,0.1l0.7,0.3
					l0.4-1l0.8,0.3l0.7,0.5l0.3-1l0.6,0.5v-0.6 M79.9,194.4l0.2,0.2l0.1,0.1l0.2,0.1v-0.2h0.1l-0.1-0.1 M29.4,194.6l-0.2,0.1l-0.2,0.1
					v-0.2l-0.2,0.1l0.1-0.1 M80.6,194.9l0.2,0.1l0,0l-0.4-0.2l0.5,0.6l-0.5,0.1l-0.5-0.8l0.4,0.1l0,0v-0.1h-0.1 M80.7,194.7l0.1,0.2
					h-0.1V194.7L80.7,194.7L80.7,194.7l-0.1-0.1l0,0 M80.9,194.7L80.9,194.7v0.2h-0.1l0,0h0.1V194.7L80.9,194.7L80.9,194.7L80.9,194.7
					M28.8,194.6v0.2l0,0l0,0l0,0h-0.1l-0.2-0.1L28.8,194.6v-0.1l0,0l0,0l0.1-0.1L28.8,194.6l0.3-0.1 M28.8,194.5l-0.1,0.2L28.8,194.5
					l-0.1,0.2l0.4-0.2l0.4-0.1l-0.5,0.8l-0.4,0.7l-0.5-0.3l0.4-0.5h-0.4l0.4-0.4l-0.2,0.2l0,0l-0.1-0.1l0.2-0.1l-0.2,0.1h-0.1H28v0.1
					l0.1-0.1l-0.2,0.3H28l0.2-0.3l0,0 M80.9,194.7L80.9,194.7 M80.7,194.7L80.7,194.7h0.2l0,0l0.3,0.2l0.1-0.1l0,0l-0.3-0.2h0.2
					l0.1,0.1h0.1h0.1v0.2l-0.1-0.1l0.2,0.3h-0.2l-0.2-0.3 M28.7,195L28.7,195 M78.1,193.7l0.4,1.4l-0.7,0.5l-0.2-1l-0.6,0.5v-0.6
					M28.5,195.2L28.5,195.2L28.5,195.2l0.2-0.2l0,0 M28.7,196.2L28.7,196.2l0.8,0.5l0.4-0.9l0.8,0.4l0.3-1.1l0.4-1.4 M28.7,195
					L28.7,195L28.7,195 M79.4,194.7l0.4,1l-0.8,0.4l-0.3-1.1L79.4,194.7 M81.2,195.1L81.2,195.1l0.2,0.2l0.1,0.1h-0.2l0.4,0.3
					l-0.3,0.2l-0.4-0.5h0.3L81,195L81.2,195.1 M69.1,200.3l0.2,1.2l0.4,0.4l-0.3-1l0.8,0.4l-0.1-1.4l1.1,0.3v-1.5l1.1,0.2v-1.6
					l0.8,0.1v-1.5l0.7,0.2l0.9,0.4l0.2-0.9l0.9,0.4l0.3-0.7l0.6-0.2l-0.1,0.7l0.5-0.6l0.1,1l0.7-0.6l0.3,1.1l0.8-0.5l0.3,1l0.9-0.5
					l-0.4-0.9l0.7-0.3l0.4,0.7l0.5-0.3l0.4,0.5l0.4-0.4l-0.4-0.4l0.2-0.1l0,0h-0.1h-0.1v-0.2l0,0l-0.1-0.2l0.1,0.1h0.1l0.1-0.1
					l-0.2-0.1 M28.3,195.3l-0.2,0.2v-0.1v0.1H28v-0.2l-0.1,0.1l0.2-0.3l0,0l-0.1,0.1l-0.1,0.1l0,0l0.2-0.2 M81.9,195.2l0.2,0.3
					l-0.3-0.2l-0.2-0.1 M28.1,195.2l-0.2,0.1l-0.2,0.1l-0.1,0.2l0,0l0.2-0.2 M81.3,195.4l-0.2-0.1 M81.5,195.4v0.1V195.4L81.5,195.4
					M39,195.3l0.4,1.3l-0.4,0.2l-0.3-1.3L39,195.3 M28.1,195.5L28.1,195.5h0.2l-0.4,0.3l-0.2-0.1h-0.1l-0.2-0.1h-0.1H27l0.1,0.1h0.3
					h0.1l0,0 M81.7,195.5l0.2,0.2L81.7,195.5l0.2,0.2h-0.1L81.7,195.5l0.2,0.1L81.7,195.5 M27.7,195.7h0.1v0.1l0.1-0.2L27.7,195.7
					l0.3-0.3 M82,195.7L82,195.7L82,195.7L82,195.7 M81.9,195.8L81.9,195.8h0.2l0.4-0.1v0.1h-0.3 M81.6,195.9L81.6,195.9 M28,195.9
					l-0.1,0.1h-0.1h-0.2 M27.1,195.9l-0.3,0.1l0.1,0.2l0.3-0.2l0.1,0.1l0.1,0.1l0.4-0.2h0.1h-0.1l-0.4,0.1l-0.3,0.3v-0.3l-0.2,0.4
					v-0.2l0.2-0.3 M33.1,196l-0.5-0.6l-0.1,1.1l-0.7-0.6l-0.2,1.1l-0.8-0.4l-0.3,1l-0.9-0.5l-0.3,0.8l-0.8-0.6l0.3-0.7 M81.9,196
					L81.9,196h0.2 M82,196l0.3,0.1l0.1-0.1l0.3,0.2v-0.1V196l-0.3-0.1 M82.7,196l0.1,0.1l0.1,0.2l0,0l0.1,0.5l-0.1-0.1l-0.1-0.4v0.1
					L82.7,196 M76.5,196L76.5,196l-0.6,0.2l-0.1,0.8l-1-0.3v1.2l-0.9-0.2v-1.4l0.2-1.5 M81.8,196l0.4,0.2V196l0.3,0.3l0.1-0.2l0.2,0.2
					h0.1l0.1,0.3l0,0v0.4v-0.2 M33.1,196.2v0.8l0.7,0.1l0,0l-0.2-0.8l0.9-0.4l0.2,0.9l0.9-0.4l-0.2-1.5 M26.6,196.4l-0.1,0.1l-0.1,0.2
					l0,0l0.1,0.2l0.1-0.3v0.2l0.3-0.3v0.2l0.4-0.4l0.1,0.2l0.4,0.4l0.4-0.6l-0.3-0.2l-0.4,0.4l-0.3,0.6l-0.2-0.4l-0.3,0.5v-0.4
					l-0.1,0.4v-0.1v-0.2v-0.3 M36.3,196.2l0.1,1.4l-0.7,0.1v-1.4L36.3,196.2 M82.8,196.5l0.1,0.1v0.3l-0.1,0.3l-0.3-0.5l-0.2,0.4
					l-0.3-0.6l0.2-0.2l0.3,0.4l0.1-0.2v-0.1 M39.5,196.6L39.5,196.6l-0.2,0.4l0.4,1.4L40,198v-0.2l0.1-0.2L40,197l-0.1-1.2
					M26.5,196.9v0.2l0,0v-0.4l0.1-0.3 M83.2,197.3l-0.1,0.2L83.2,197.3v-0.1V197.3v-0.5v0.3h-0.1v-0.3l-0.1-0.3l-0.2-0.4 M26.6,196.7
					v0.1v0.1v0.3l0.1-0.4l0.2,0.5l0.3-0.7l0.4,0.4l0.3-0.7l0.5,0.4l-0.2,0.8L29,198l0.2-0.7l0.9,0.6l0.3-0.8l0.8,0.4l0.3-1l0.8,0.4
					l0.7,0.3l0.1-0.8l-0.6-0.4l-0.2,1l-0.3,1l-0.8-0.4l-0.3,1l-0.8-0.6l-0.1,0.8l-0.9-0.6l0,0 M83,196.5l-0.1-0.3 M82.8,196.7v0.1
					l-0.2,0.5l-0.3-0.7l-0.4,0.5l-0.3-0.7l-0.6,0.4l-0.3-0.7l-0.8,0.4l0.3,0.8l-0.9,0.6L79,197l-0.8,0.5l-0.3-1l-0.8,0.4l-0.2-1
					l-0.7,0.4l0.1-0.8 M40,196.6l-0.2-0.8 M69.7,195.8l-0.2,0.8l0.2-1.2 M33.8,196.6l0.9-0.3v1.2l0.9-0.2l-0.2,1.5h-1l0.2-1.3h-1
					L33.8,196.6 M76.3,196.5l0.1,0.8l-0.8,0.2l0.2,1.1l-1,0.1l-0.2-1.3h1v-0.9L76.3,196.5 M69.5,196.6l-0.1,0.6l0.1,0.2 M26.5,196.7
					L26.5,196.7v0.2 M40,197.2l0.3,1.4v0.3l-0.4,0.5l-0.3-1.5l-0.6,0.3l-0.2-1.4l-0.9,0.2l0.1,1.5l1-0.2l0.1,1.5l0.7-0.3v1.4l0.4-0.5
					V199 M37.1,197.1v1.6l-0.9,0.1l0.1-1.5L37.1,197.1 M82.8,196.8l0.2,0.5v-0.4 M69.6,201.9L69.6,201.9l0.8,0.4l-0.2-1.1l1.1,0.3
					l-0.1-1.4l1.2,0.2l-0.1-1.5l0.9,0.1l-0.1-1.5l0.7,0.1L74,199h-0.8l0.2,1.5l-1-0.1l0.1,1.4l-1.2-0.1l0.1,1.1l-1-0.2l0.4,0.8
					l-0.8-0.2l-0.1-0.1l-0.5-0.4l-0.3-0.8 M26.9,197.3L26.9,197.3l-0.3,0.4v-0.5 M82.8,197.3L82.8,197.3l0.3,0.3l0.1-0.3v0.3l-0.1,0.3
					v-0.3 M77.3,197l0.3,1l-0.8,0.3l-0.2-1L77.3,197 M81.2,196.9l0.2,0.8l-0.8,0.5l-0.2-0.8L81.2,196.9 M40.1,197.4l-0.4-1.2
					M33,197.4l-0.2,1l0.8,0.2l0.2-1.1L33,197.4 M26.6,197.8l0.1,0.4l0.3,0.1l-0.1-0.8l0.6,0.5l0.7,0.5l0.1-0.7l-0.7-0.6l-0.1,0.8v0.6
					l-0.5-0.3l0.2,0.4l-0.2-0.2l-0.2-0.3 M82,197.2l0.1,0.8l-0.7,0.5l-0.1-0.7L82,197.2 M82.7,197.4l-0.1,0.8l-0.5,0.3v-0.6
					L82.7,197.4 M82.9,198l-0.1,0.2L82.9,198 M78.4,197.7l0.3,1l-0.8,0.5l-0.3-1L78.4,197.7 M82.8,198.2l-0.3,0.1l-0.2,0.4l0.3-0.3
					L82.8,198.2 M31.9,198.1l-0.3,1l0.8,0.4l0.3-1L31.9,198.1 M79.4,198.1l0.1,0.8l-0.8,0.6l-0.2-0.8L79.4,198.1 M82.4,198.7l-0.6,0.4
					l0.2-0.4l0,0 M27.1,198.6l0.6,0.4l-0.2-0.4v-0.1 M69.1,198.7l0.3-1.3 M76.7,198.5l0.3,1.1l-0.9,0.3l-0.3-1.2L76.7,198.5
					M38.1,198.6v1.6l-1.2,0.2l0.1-1.5L38.1,198.6 M40.3,198.8l-0.3-1.1 M80.4,198.3v0.7l-0.9,0.5v-0.7L80.4,198.3 M33.6,198.6
					l-0.3,1.2l0.9,0.3l0.3-1.3L33.6,198.6 M35.5,198.8l-0.3,1.4l0.9,0.1l0.2-1.5H35.5 M82,198.5l-0.7,0.5l0.1-0.6l-0.9,0.5l-0.1,0.5
					h-0.1l-0.8,0.4l0.1-0.5l-0.7,0.5v-0.6l-0.7,0.6l-0.2-1l-0.8,0.5l0.2,1l-0.8,0.5l-0.2-1.1l-1,0.3l-0.5-1.2H74l0.3,1.4l1-0.2
					l0.3,1.2l0.9-0.4l0.1,1l0.8-0.6v-0.1l-0.1-0.8l0.8-0.5v0.8l0.7-0.6v-0.1 M29,198.2l-0.1,0.6l-0.8-0.5l0.1,0.6l-0.7-0.4 M29,199
					l0.1,0.5h0.1l0.8,0.4l-0.1-0.5l0.7,0.5v-0.6l0.8,0.6l0.2-1l-0.7-0.5l-0.2,0.8l-0.7-0.6v0.7L29,199L29,199 M28.1,199L28.1,199
					l0.3,0.4l-0.8-0.5 M81.3,199L81.3,199l-0.3,0.4l0.8-0.4 M32.3,199.4L32.3,199.4l0.8,0.4l-0.2,1.2l-0.8-0.5L32.3,199.4 M29,199.5
					l-0.8-0.4 M80.3,199.5l0.8-0.4 M28.4,199.4l0.9,0.4l-0.2-0.3 M80.9,199.4l-1,0.4l0.3-0.4 M39.2,199.8l-0.1,1.4l-1.1,0.3l0.1-1.4
					L39.2,199.8 M29.9,199.9l0.2,0.2l0.6,0.4l-0.1-0.4l-0.1-0.1 M79.9,199.9l-1,0.5l0.2-0.2l0.2-0.2 M30.6,200.1l0.6,0.6l0.1-0.7
					l0.8,0.5l-0.1,0.8v0.1l0.8,0.5l0.1-1l0.9,0.4l1,0.2l0.3-1.3l-1-0.2l-0.3,1.2l-0.1,1l-0.9-0.4l0.1,0.5l-0.8-0.6l-0.1-0.5
					M40.3,200.3l-0.2,1.2l-0.4,0.4l0.2-1.1l-0.8,0.4l-0.2,1.1l-1,0.2l0.1-1.1l-1.2,0.2l0.1-1.4l-1,0.1l-0.1,1.4l-1-0.1l-0.1,1.1
					l-1-0.3l0.2,0.6l-0.8-0.4l-0.2-0.1 M77.4,201.4l-0.1,0.5l-0.8,0.6l0.1-0.6l-0.9,0.4l-0.1-1l-1,0.2l-0.3-1.3l-0.9,0.1l0.2,1.4h-1.1
					v1.1l-1.1-0.2v0.6h-0.6l0.7,0.5l-1-0.3L70,203 M30.2,200.2l0.2,0.2l-1-0.5 M79.3,200.2l-0.6,0.4l0.1-0.4 M30.8,200.6L30.8,200.6
					l-0.4-0.2 M30.9,200.7l0.6,0.5l-0.2-0.5l0.7,0.6 M79.1,200.4l-0.6,0.4l0.2-0.2 M77.4,201.3l0.7-0.6l-0.2,0.4l0.5-0.4 M33.9,203
					L33.9,203l1.1,0.3l-0.3-0.6l1.1,0.1v-1.1h1.1v1.1l1.1-0.2v0.6h0.7l0.4-0.7l0.8-0.3l0.1-0.1 M71.5,203.2l0.8,0.2l0.3-0.6h1.1v-1.1
					l1-0.1l0.1,1.1l1-0.3l-0.2,0.6l0.9-0.5l-0.4,0.3l-0.9,0.4l0.3-0.3l0,0 M32.3,201.8l-0.7-0.7 M77.3,201.9l0.7-0.7 M39.8,202
					l-0.3,0.7l0.1-0.1l0.4-0.3l0.3-0.8 M38,203.1l-0.8,0.2l-0.3-0.6h-1.1l0.3,0.7l-1.1-0.1l0.4,0.3l-1.1-0.4l-0.2-0.2 M39.6,202.6
					l-0.3,0.3l0.1-0.1l0.1-0.1 M75.5,203l-1,0.3l0.3-0.6l-1,0.1l-0.4,0.7l-1-0.1l-0.6,0.2h0.1l1.1,0.1l0.4-0.3l1.1-0.1l-0.4,0.3
					l1.1-0.3 M70.1,202.7l-0.3-0.8 M39.3,202.9l-0.5,0.4l-1,0.3l0.8-0.5l0.7-0.3 M34.3,203.2l-0.8-0.4l-0.3-0.3 M37.9,203.5
					L37.9,203.5l-0.6-0.2l-1.1,0.1l0.4,0.3l-0.7-0.1h-0.4 M37.8,203.6L37.8,203.6l-1.2,0.1l0.1,0.1l-0.8-0.1 M74.1,203.6h-0.4
					l-0.8,0.1h-0.1l-1-0.2 M36.7,203.8l1-0.2 M73.7,203.6l-0.8,0.1"/>
			</g>
		</g>
		<g id="BODY_HIGHLIGHTS">
			<path id="back-of-left-shoulder" class="st1" d="M24.8,52.6c-0.3-0.3,0-0.8,0.6-2.3c0.8-2,0.6-1.9,1.1-3c0.5-1.2,0.8-1.8,1.3-2.6
				c1.1-1.6,2.7-2.3,4.1-3c0.8-0.4,0.5-0.2,4.2-1.5c2.9-1.1,3.6-1.4,3.9-1.5c1.2-0.6,1.4-0.8,2.8-1.4c1.4-0.6,1.5-0.5,2.3-1
				c1.3-0.7,1.9-1.4,2.3-1.2c0.3,0.2,0.2,0.7,0.2,0.9c-0.3,2.2-3.3,5.4-5.3,8.5c-1.3,2.1-2.8,5-4,6.9c-0.6,0.9-0.7,0.8-1.3,1.7
				c-0.5,0.7-0.7,1.2-1.2,1.3c-0.4,0.1-0.9-0.1-1.1-0.2c0,0,0,0-4.3-0.6c-4-0.6-4-0.6-4-0.6C25.6,52.9,25,53,24.8,52.6z"/>
			<path id="back-of-right-shoulder" class="st1" d="M84.7,52.4c0.3-0.4-0.3-1.1-1-3.1c-0.3-0.9-0.3-1.1-0.6-1.9c-0.1-0.2-0.4-1-1-1.9
				c-0.2-0.3-0.8-1.1-1.7-2c-0.9-0.8-1.8-1.3-2.4-1.7c-1.2-0.7-2.1-1-3-1.3c-0.6-0.2-0.5-0.1-1.8-0.6c-2.2-0.7-3.3-1.1-4-1.6
				c-0.2-0.2-0.6-0.4-1.2-0.7c-0.5-0.2-0.6-0.2-1.2-0.5s-0.7-0.4-1.4-0.7c-0.9-0.4-0.8-0.3-1.2-0.5c-1.3-0.7-1.6-1.6-2.2-1.5
				c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.5,0.6,0.9,1.3,1.8c0.5,0.7,0.7,1.1,1.1,1.8c1.3,2.2,3.3,4.4,5.9,8.4c1.3,1.9,2.9,3.5,3.9,5.5
				c0.3,0.6,0.9,1.8,2,2c0.5,0.1,0.6-0.1,2-0.5c1.3-0.4,2.3-0.6,3.2-0.8c1.1-0.3,1.7-0.4,2-0.4C84.2,52.6,84.5,52.7,84.7,52.4z"/>
			<path id="upper-back" class="st1" d="M37.5,54.2c5.1,0.4,17.5,0,17.5,0c1.7-0.1,0.9,0,10.5,0.2c1.5,0,6,0.1,7.3-0.2
				c0.3-0.1,0.8-0.2,1.5-0.1c0.5,0.1,0.7,0.2,0.8,0.1c0.2-0.2-0.6-1.3-1.4-2.2c-0.8-1-1.6-2.2-2.4-3.2c-1.5-1.8-1.1-1.7-3.7-5.2
				c-2.8-3.9-3.2-4-4-5.7c-0.8-1.6-0.7-2-1.3-2.4c-1.3-0.9-2.7,0-7,0.1c-0.9,0-1.9,0-1.9,0c-1.4,0-2,0-2.7,0c-2-0.1-2.6-0.5-3.2,0
				c-0.3,0.2-0.3,0.4-0.7,1.2s-0.9,1.7-1.3,2.5c-0.6,1.3-1.2,2-2.6,4.1c-3.2,4.8-2.4,4.2-4,6.3S36,53,36.4,53.8
				C36.6,54.1,37.2,54.1,37.5,54.2z"/>
			<path id="low-back" class="st1" d="M37.1,94.4c0.5,0.6,1.7,0.3,2,0.2c0.2,0,5.5-0.2,16.1-0.2c13.5,0,14.9,0.2,14.9,0.2l0,0
				c1.7,0,2.1-0.1,2.3-0.2c0.5-0.5-0.9-1.9-1.1-4.6c0-0.6,0-1.3,0-2.7c0-1,0.1-1.4,0-2.2c0-0.3,0-0.5-0.3-2c-0.2-1-0.3-2.1-0.4-2.9
				c-0.2-1.4-0.3-2.2-0.3-2.2c0-2,0.9-3,0.4-3.5c-0.2-0.2-0.6-0.1-1.3,0c0,0-1,0-3.1,0c-9,0-8.7,0-11.1,0c-6.7,0-6.5,0.1-8.1,0
				c-1.4-0.1-2.8,0-4.2-0.1c-2.5-0.1-4.2-0.3-4.5,0.4c-0.1,0.3,0.1,0.4,0.3,1.5c0.1,0.7,0.1,1.3,0.1,1.6c0,0.7-0.1,1.3-0.2,1.8
				c-0.3,1.6-0.5,2.2-0.7,3.2c-0.3,1.4,0,1.3,0,4.2c0,1.8-0.1,2.7-0.2,3.6C37.5,92.8,36.6,93.8,37.1,94.4z"/>
			<path id="back-of-left-hip" class="st1" d="M34.2,113.6c0.1,0.3,0.3,0.3,0.4,0.3c1.3-0.2,2.5,0.1,3.8-0.1l0,0
				c0.8-0.2,1.5,0.1,2.5,0.1c0.4,0,0.3,0,1.4-0.1c0.5,0,0.6,0,0.8-0.1c0.5-0.3,0.4-1.1,0.4-1.8c0-1,0.4-2,0.5-3
				c0.2-1.9,0.6-3.5,0.9-4.8c0.9-4.2,1.4-6.3,1.7-7.3c0.2-0.7,0.7-2,0.2-2.4c-0.3-0.2-0.8-0.1-1.1,0c-1.6,0.4-3.1,0.3-6,0.2
				c-1.4-0.1-2.6-0.3-2.8,0.2c-0.1,0.1,0,0.2,0,0.7c0,0.6-0.1,0.9-0.2,1.2c-0.2,0.8-0.2,1.7-0.9,6.3c-0.6,3.8-0.9,5.8-1,6.2
				c-0.4,1.3-0.2,2.7-0.6,3.9C34.2,112.9,34,113.2,34.2,113.6z"/>
			<path id="back-of-right-hip" class="st1" d="M75.1,113.6C75.1,113.7,75,113.7,75.1,113.6c-0.2,0.1-0.2,0.1-0.3,0.1
				c-1.4-0.1-2.7,0.2-4.1,0c-0.1,0-0.3-0.1-0.5-0.1s-0.4,0-0.9,0c-1.1,0-2.2,0-2.2,0l0,0c0,0-1-2.1-1.7-4.3c-0.2-0.8-0.3-1.2-0.6-2.5
				c-0.8-3.3-1.2-6.8-2.1-10.1c-0.3-1-0.5-1.8-0.1-2.3s1.1-0.3,3.7-0.1c2.5,0.1,3.7,0.2,4.4,0.1c0.9-0.2,1.4-0.4,1.8,0
				c0.5,0.4,0.4,1.1,0.3,2.2c0,0.7,0.1,1.8,0.4,4c0.1,1.2,0.3,2.1,0.7,3.7c0,0,1.2,5.1,1.2,6.7c0,0.3,0,0.4,0,0.4c0,0.3,0,0.4,0,0.7
				C75.1,112.1,75.3,113.3,75.1,113.6z"/>
			<path id="back-of-left-leg" class="st1" d="M36,142.5c0.4,0.6,1.7,0.3,4.7,0.1c1.4-0.1,2.1-0.1,2.9-0.1c1.8,0.1,2.5,0.1,3.8,0
				c0.3,0,1-0.1,1.3-0.5c0,0,0-0.1,0.1-0.1c0.1-0.3,0.1-0.6,0.2-2.2l0,0c0.2-0.8,0.4-1.7,0.6-2.8c0.1-0.8,0.2-1.4,0.2-1.5
				c0.3-2.7,1.6-6.3,2.6-11.3c0.4-1.7,0.6-3,0.7-4.6c0.2-2.7-0.2-2.5,0.2-3.8c0.2-0.6,0.4-1.3,0-1.7c-0.3-0.4-0.9-0.4-1.2-0.4
				c-3,0-4.5-0.1-7.2,0.1c-2.2,0.2-1.8-0.1-5.4,0.1c-2.8,0.1-3,0.2-3.8,0c-0.6-0.2-1.3-0.4-1.6-0.1c-0.2,0.2-0.1,0.4-0.1,1.4
				c0,0,0,1.1,0,2.1c0,0.5,0,0.4,0,1.5c0,0.2,0,0.7,0,1.2c0,1.1,0,2,0,2.3c0,1.8,0.3,4,0.4,4.9v0.3c0,0.3,0.1,0.6,0.1,0.9
				c0,0.1,0.1,1.4,0.2,1.5c0.1,0.5,0.2,0.9,0.2,1.1c0,0,0.4,2.2,0.4,3.2c0,0.1,0,0.3,0.1,0.6c0.1,0.5,0.1,0.8,0.4,2.9
				c0.2,1.6,0.2,2.1,0.2,2.3c0,0.7,0,1.2,0,1.5C36,142,36,142.4,36,142.5z"/>
			<path id="back-of-right-leg" class="st1" d="M73.4,142.3c-0.4,0.6-1.5,0.4-5,0.3c-1.6,0-2.4,0-3,0c-2.5,0.1-4.1,0.5-4.6-0.2
				c0,0,0,0-0.1-0.6c-0.2-1.2-0.3-1.3-0.3-2c-0.1-0.7-0.1-0.7-0.1-1.4c-0.1-0.5-0.1-1-0.3-1.5c-0.4-1.9-1.6-6.5-2.3-9.3
				c-0.4-1.5-0.5-2.2-0.6-2.7c-0.2-1.2-0.2-1.1-0.6-3.9c-0.3-2.2-0.4-2.5-0.4-3.2c0-0.9,0.1-1,0-1.7c-0.2-1.6-0.8-2.2-0.4-2.6
				c0.3-0.4,0.9-0.2,2.4,0c1.1,0.1,2.2,0.1,3.3,0.1c1.4,0.1,2.5,0,4.7,0c1.1,0,1.6,0,4.7-0.1c0.9,0,1.9,0.1,2.8,0.1
				c1,0,1.3-0.1,1.6,0.1c0.3,0.3,0.3,1,0.3,2.3c0,0.2,0,0.2,0,0.4c0,0.6,0,0.8,0.1,1.5c0,0.3,0,0.2,0,0.8c0,0.5,0,0.8,0,0.9
				c0,1.1,0.1,1.9,0,2.9c-0.1,0.4-0.1,0.5-0.1,1c-0.1,0.6-0.1,0.9-0.1,1.4c0,0.4,0,0.5-0.2,1.9c-0.2,1.8-0.2,1.7-0.2,2.2
				c-0.1,0.5-0.1,0.8-0.4,3.1c-0.2,1.3-0.4,2.4-0.6,4c-0.1,0.6-0.2,1.3-0.3,2.2c-0.1,0.9-0.2,1.4-0.2,1.9
				C73.4,141.9,73.6,142,73.4,142.3z"/>
			<path id="back-of-left-knee" class="st1" d="M34.1,155.9c0.2,0.4,1.2,0.4,2.6,0.3c1.6-0.1-6.4,0.3,3.2,0c3.3-0.1,4.6,0.4,5.2-0.5
				c0,0,0-0.1,0.1-0.1c0.1-0.3,0.1-0.6,0.1-0.7c0-0.7,0.2-1.4,0.2-2.1c0-0.4,0.1-0.4,1-2.5c0.5-1.3,0.8-2,1-2.6c0.3-1.1,0.2-1,0.6-2.3
				c0.5-1.8,0.9-2.5,0.6-2.9c-0.1-0.1-0.3-0.1-0.8-0.1c-0.9,0-1.4,0-2.1,0.1c-0.5,0.1-0.3,0-3.2,0.1c-2.3,0.1-2.1,0.1-2.8,0.1
				c-0.8,0-1.7,0-2.5-0.1c-0.8,0-1.1-0.1-1.3,0.1c-0.3,0.3-0.1,0.7-0.1,2.1c0,0.1-0.1,0.7-0.2,1.9c-0.1,0.7-0.1,1.2-0.2,1.9
				c-0.2,1.5-0.2,2.2-0.3,2.6c-0.2,1.3-0.4,2.2-0.4,2.4c-0.2,0.8-0.3,1.1-0.4,1.4C34.2,155.6,34,155.7,34.1,155.9z"/>
			<path id="back-of-right-knee" class="st1" d="M75.3,155.8c-0.2,0.3-1,0.3-2.6,0.2c-1.6,0,6.4,0.3-3.2,0c-1.7,0-5.1,0.2-5.2-0.4
				c0-0.1,0-0.2,0-0.6c0-0.3-0.1-0.4-0.1-0.5c-0.2-0.7-0.1-1-0.3-1.8c-0.1-0.5-0.2-0.8-0.4-1.2c-0.3-0.7-0.5-1.1-0.6-1.4
				c-0.2-0.4-0.5-1.4-1.3-3.4c-0.3-0.8-0.5-1.3-0.6-1.9c-0.1-0.4,0-0.5-0.1-1.2c-0.1-0.5-0.1-0.7,0-0.8c0.2-0.3,0.7-0.1,1.2-0.1
				c0.7,0,1.5,0,2.2,0c1.2,0,1.1-0.1,2.5-0.1c1.3,0,1.3,0,3.7,0c0.6,0,1.1,0.1,1.7,0c0.7-0.1,1.1-0.2,1.3,0.1c0.1,0.2,0.1,0.4,0,0.8
				c0,0.7,0.2,0.9,0.3,1.9c0.1,0.5,0.1,0.8,0.1,1.5c0,0,0.1,1.9,0.4,3.9c0.2,1.7,0.3,2.6,0.6,3.2C75.2,155,75.6,155.5,75.3,155.8z"/>
			<path id="left-calf" class="st1" d="M33.9,190.2c1.8,0.2,3.2,0.2,3.2,0.2c1.9-0.1,2.1,0.1,2.4-0.2c0.3-0.3,0.3-0.7,0.4-2.4
				c0-0.7,0.1-1.4,0.1-2.2c0.1-1.9,0.4-3.3,0.5-4.2c0.3-1.7,0.5-1.4,1.2-4.2c0.7-2.6,0.5-2.7,1.1-4.7c0.3-1.1,0.5-1.6,0.9-2.9
				c0.1-0.4,0.3-1.3,0.6-2.8c0.8-4.4,0.6-6.1,0.9-8.3c0.1-0.8-0.1-1.6,0.1-2.3c0-0.1,0.1-0.4-0.1-0.5c-0.2-0.2-0.8,0.1-1,0.2
				c-0.4,0.2-1.6,0.2-4.1,0.3c-3.2,0.1-3.2,0.1-3.7,0c-1.3-0.2-1.6-0.6-2.1-0.3c-0.3,0.2-0.7,0.6-1,3.8c-0.1,1.2-0.2,2.1-0.3,3.1
				c-0.3,3.6-0.3,3.6-0.3,3.6c-0.1,1.6,0.1,1.4,0.4,6.5c0,0.8,0.1,1.7,0.2,3.1c0.1,0.9,0.1,1.4,0.2,2.2c0.2,1.4,0.3,1.7,0.3,2.6
				c0,0.6,0,0.8,0,2.2c0,3.9,0.1,3.5,0,4.1c-0.2,1.4-0.7,2.4-0.2,2.8C33.6,190.2,33.7,190.2,33.9,190.2z"/>
			<path id="right-calf" class="st1" d="M76.2,190.1c-0.2,0.3-0.5,0.2-2.4,0.2c-0.7,0-1.1,0-1.5,0c-1.7,0-2.2,0.1-2.4-0.2
				c-0.1-0.1,0-0.1,0-1c0-0.2,0-0.4,0-0.6c0-0.5-0.1-0.9-0.2-1.2c-0.1-0.7-0.1-0.9-0.1-1.7c-0.1-0.8-0.2-1.1-0.4-2.3
				c-0.3-1.5-0.3-1.9-0.5-3c-0.3-1.6-0.6-2.7-1.1-4.4c-1.1-3.7-1.6-5.6-1.8-6.4c0,0-0.9-4-1.2-9c0-0.3-0.1-1.2-0.1-2.4
				c0-0.6-0.1-0.8-0.1-1.2c0-0.6,0-0.7,0-1c-0.1-0.5-0.1-0.6-0.1-0.7c0.2-0.3,1.1,0.2,2.2,0.4c1,0.2,0.9,0,3.1,0.1
				c1.8,0.1,1.6,0.2,2.4,0.1c1.5-0.1,2.3-0.2,2.4-0.2c0.2-0.1,0.6-0.2,0.8,0c0.1,0.1,0.1,0.2,0.2,0.4c0.3,0.8,0.2,0.8,0.3,0.8
				c0.1,0.6,0.2,1.1,0.2,1.3c0.1,0.6,0.1,0.7,0.4,1.7c0,0.2,0.3,1.2,0.4,2.4c0.1,0.6,0.1,1.2,0.2,2.5c0.1,2,0.2,3.1,0.1,4.5
				c0,1.5-0.1,1.1-0.4,4.7c-0.1,1.3-0.1,1.8-0.2,2.8c-0.1,1.2-0.2,1.3-0.4,2.7c-0.1,1.1-0.2,1.8-0.3,2.8c-0.1,0.8-0.1,1.2-0.1,1.8
				c0,0.5,0.1,0.6,0.1,1.4c0.2,1.7,0.1,1.6,0.2,2.7C76.2,189.6,76.4,189.8,76.2,190.1z"/>
			<path id="back-of-left-arm" class="st1" d="M23,68.9c1.8,0.1,4,0.3,6.1,1c1.2,0.4,1.9,0.8,2.5,0.4c0.4-0.2,0.4-0.6,0.8-1.7
				c0.7-1.8,0.8-1.5,1.3-3c0.3-0.8,0.4-1.5,0.7-2.9c0.1-0.7,0.1-0.7,0.4-2c0.4-1.8,0.5-2.1,0.6-3.2c0.5-2.7,0.2-3,0.1-3.1
				c-0.4-0.5-0.9-0.4-2.5-0.5c0,0-1.3-0.1-3.7-0.5c-3.2-0.6-4-1.3-4.8-0.7c-0.3,0.2-0.3,0.5-0.5,2.5c-0.2,1.8-0.3,3.1-0.5,4
				s-0.5,1.9-1.1,3.7c-0.7,2.2-1.1,3.3-1.2,3.5c-0.5,0.9-0.8,1.4-0.6,1.8C21,68.8,21.8,68.8,23,68.9z"/>
			<path id="back-of-right-arm" class="st1" d="M86.9,68.5c-2.5,0.5-3.7,0.7-4.8,1c-1.5,0.3-2.3,0.5-2.3,0.5c-1.1,0.3-1.5,0.6-2,0.3
				c-0.4-0.2-0.4-0.5-0.8-1.5c-0.6-1.6-0.6-1.2-1.1-2.5c-0.5-1.3-0.8-2-0.9-2.7c-0.2-0.9,0-0.9,0-3.7c0-1.4,0-2.1,0.1-3.4
				c0.1-1.2,0.2-1.8,0.5-2.1c0.5-0.5,1-0.5,3.6-0.9c1.2-0.2,1.8-0.3,2.5-0.5c2.1-0.5,2.6-0.9,3-0.6c0.4,0.3,0.2,0.7,0.6,3.3
				c0,0,0.1,0.7,0.2,1.6c0,0.3,0.3,1.6,0.6,2.9c0.6,2.5,0.9,3.8,1.7,5.3c0.6,1.1,1.1,1.7,0.8,2.2C88.4,68.2,87.9,68.3,86.9,68.5z"/>
			<path id="left-elbow" class="st1" d="M16.8,79.3c1,0.2,5.2,1,6.4,1.2c2.4,0.5,3.3,1.3,4.1,0.7c0.4-0.3,0.5-0.9,0.9-2.1
				c0.4-1.4,0.3-1.6,0.5-2.4c0.4-1.4,1-2.5,2.1-4.5c0.7-1.1,0.8-1.4,0.7-1.7c-0.2-0.6-1-0.6-4.3-1.1c-1.6-0.2-3.2-0.3-4.8-0.7
				c-0.9-0.2-1.3-0.4-1.7-0.2c-0.8,0.4-0.5,1.6-1.2,3.8c-0.3,0.9-0.7,2.3-1.7,3.7c-1.1,1.5-2.3,2.2-2,2.8
				C15.9,79.1,16.4,79.3,16.8,79.3z"/>
			<path id="right-elbow" class="st1" d="M91.3,79.6c-0.6,0.1-0.6,0.2-1.9,0.5c-3.4,0.9-3.5,0.5-4.9,1.1c-0.8,0.4-1.4,0.8-1.9,0.5
				c-0.4-0.2-0.5-0.7-0.5-1.1c0,0-0.3-1.5-1-4c-0.3-0.9-0.7-1.7-1.6-3.3c-1.3-2.2-2-2.9-1.7-3.4c0.2-0.4,0.9-0.3,2.6-0.4
				c0.4,0,2.7-0.2,4.9-0.8c2.1-0.6,2.9-1.2,3.5-0.8c0.4,0.3,0.2,0.8,0.7,2.3c0.2,0.5,0.5,1.2,1,2.6c0.7,1.7,1.1,2.5,1.5,3.1
				c0.2,0.2,0.6,0.8,1.4,1.9c0.6,0.8,0.8,1.1,0.7,1.3C93.7,79.6,92.8,79.4,91.3,79.6z"/>
			<path id="back-of-left-forearm" class="st1" d="M9.6,101c0.1,0.8,1.5,0.7,3.6,1.7c1.2,0.6,1.8,1.1,2.2,0.9s0.4-0.7,0.4-1.2
				c0.1-1,0.4-0.9,2-3.9c0.8-1.6,1.1-2.3,2.2-4.4c0-0.1,0.1-0.2,0.1-0.2c2.2-4.1,3.4-5.4,4.8-8.3c0.6-1.3,0.6-1.5,1.2-2.3
				c0.6-1,1.2-1.6,1-1.9s-0.7-0.1-1.6-0.2c-0.8-0.1-0.9-0.3-2.2-0.6c-1.3-0.4-1.4-0.2-3.8-0.8c-0.8-0.2-1.2-0.3-1.5-0.4
				c-1.7-0.4-2-0.7-2.5-0.5c-0.5,0.3-0.6,1-0.8,2.2c-0.3,1.4-0.3,0.5-1.2,3.8c-0.4,1.3-0.8,3-1.5,6.3c-0.4,1.8-0.6,2.7-0.7,3.2
				c-0.4,1.8-0.4,2.2-0.7,3.2c-0.4,1.3-0.6,2-0.8,2.4C9.6,100.7,9.6,100.8,9.6,101z"/>
			<path id="back-of-right-forearm" class="st1" d="M99.5,100.4c-0.3,0.8-1.3,0.7-3,1.7c-1.4,0.8-1.7,1.4-2.3,1.3
				c-0.6-0.2-0.6-1-1.3-2.4c-0.5-1.1-0.5-0.8-1.5-2.6c-0.8-1.4-1-2.1-2.2-4.3c0-0.1-0.1-0.1-0.1-0.2c-1.2-2.2-1.7-2.8-2.6-4.2
				c-0.5-0.9-0.3-0.6-1.7-3.3c-0.8-1.5-1-1.8-1.1-2.1c-1-1.5-1.5-1.7-1.4-2.1s0.6-0.6,5.2-1.6c0.7-0.2,0.6-0.1,0.8-0.2
				c3.9-0.8,4.7-1.5,5.5-1.1c0.4,0.2,0.7,1,1.1,2.5c0.5,1.6,0.7,2.8,0.9,3.6c0.3,1.7,0.2,0.8,1.3,5.5c0.9,3.7,0.7,3.4,1.1,4.6
				c0.3,1.2,0.5,2.4,0.9,3.5C99.5,99.7,99.7,100,99.5,100.4z"/>
			<path id="neck" class="st1" d="M47.2,27.2c0,0.2,0,0.5,0.1,0.8s0.2,0.4,0.3,0.6s0.1,0.5,0.1,0.9c0,0.7-0.1,1.2-0.2,1.7
				c-0.2,1.3,0.1,1.4-0.1,2.6c-0.2,0.9-0.4,1-0.3,1.3c0.3,1,3.4,0.6,8.8,0.5c3.9,0,5.6,0.2,6.2-0.9c0.3-0.5,0.2-1.4-0.1-3.2
				c-0.1-1.2-0.3-1.5-0.2-2.3c0.2-1.1,0.8-1.5,0.5-1.8c-0.2-0.4-1.1-0.2-2.5-0.1c-1.2,0.1-1.6-0.1-3.2-0.1
				C54.8,27.2,51.5,27.2,47.2,27.2z"/>
			<path id="head" class="st1" d="M54.7,5c-1.6,0-2.5,0-2.9,0.1c-0.9,0.2-1.6,0.6-2.3,1.1c-0.7,0.4-1.5,1-2.2,1.9
				c-0.6,0.8-0.8,1.5-1.3,2.8c-0.3,0.9-0.5,1.4-0.6,2.2c-0.1,0.7,0,1.3,0.2,2.5c0.1,0.8,0.1,1.1,0.2,1.3c0.1,0.4,0.3,0.8,0.1,0.9
				c-0.1,0.1-0.1,0-0.5,0c-0.3,0-0.4,0-0.5,0.1c-0.3,0.1-0.3,0.5-0.4,0.8c-0.2,1.3,0.3,3.1,0.6,4.8c0.1,0.5,0.3,1.4,0.8,1.5
				c0.2,0.1,0.3,0,0.5,0c0.6,0.3,0.2,1.7,0.8,2.1c0.3,0.2,0.6,0,0.7,0c0.3,0.1,0.7,0.1,1.3,0.2c0.9,0.1,1.4,0,3.1,0c0.6,0,1,0,1.7,0
				c1,0,1.7,0.1,2.5,0c0.4,0,0.6,0,1.5,0c2.5,0.1,4,0.4,4.5-0.4c0.2-0.4,0.5-1.6,0.4-1.9v-0.1c0,0,0,0,0,0c0.2-0.1,0.3,0.3,0.6,0.3
				c0.1,0,0.2,0,0.3-0.2c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.2,0.1-0.5c0.1-0.5,0.2-0.8,0.3-1c0.1-0.3,0.1-0.3,0.2-0.5
				c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.7,0.1-1.1,0.1-1.3c0-0.5,0.1-1,0-1.6c0-0.3-0.1-0.6-0.1-0.7c-0.2-0.1-0.4-0.2-0.7-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c0-0.1,0.1-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.5c0.3-1.3,0.3-2.4,0.2-3.2c-0.1-0.9-0.2-1.6-0.3-2.1
				c-0.2-0.7-0.4-1.5-0.9-2.3c-0.1-0.2-0.5-0.6-1.3-1.5c-0.9-1-1.1-1.2-1.4-1.3c-0.6-0.4-1-0.6-1.5-0.7C58,5.2,57.2,5,56.2,4.9
				C56,5,55.6,5,54.7,5z"/>
			<path id="mid-back" class="st1" d="M35.1,64.9c0.3,1.2,0.6,2.3,1.6,4.7c1.1,2.5,1.6,3.7,1.8,3.9c1.7,1.8,4.2,1.1,12.8,0.9
				c3.4-0.1,5.1-0.1,6.8,0s5.8,0.4,10.5,0c0.9-0.1,1.5-0.2,2.2-0.5c1.1-0.6,1.5-1.7,2.9-6.7c1-3.5,1.1-4.1,1.2-5
				c0.2-1.7,0-1.9,0.2-3.8c0.3-2.7,0.9-3.6,0.3-4.2c-0.6-0.6-1.7-0.2-3.4-0.1c-2.2,0.1-4.4,0.1-6.7,0.2c-2.7,0.1-3,0-8.5-0.2
				c-7.7-0.2-9,0.1-13.8,0c-1.9-0.1-3.8,0.3-5.7,0c-0.8-0.1-1.3-0.2-1.8,0.1c-0.9,0.6-0.1,2.1-0.7,5.3c-0.1,0.8-0.3,1.3-0.3,2.2
				C34.7,62.4,34.7,63.3,35.1,64.9z"/>
			<path id="buttocks" class="st1" d="M43.5,113.5c0.4,0.4,1.1,0.4,2.4,0.4c1.1,0,1.4-0.1,2.7-0.2c1.2-0.1,1.1,0,3.3,0
				c1.3,0,1.5,0,3.2-0.1c1.5,0,2.7,0,3.3,0c5.9,0,7.6,0.7,8.2-0.2c0.4-0.6-0.2-1.1-1.1-4.4c-0.7-2.4-1.1-4.6-1.5-6.9
				c-0.3-1.4-0.3-1.9-0.6-2.9c-0.4-1.5-0.6-1.8-0.7-2.8c-0.1-1,0.1-1.4-0.2-1.7c-0.5-0.6-1.5-0.4-2.4-0.3c0,0-1.8,0-5.3-0.1
				c-1.9,0-3.8,0.1-5.7,0c-0.7,0-1.5-0.1-2.2,0.4c-0.7,0.6-0.7,1.6-0.8,2.3c-0.1,2.1-1,4.1-1.4,6.1c-0.4,1.9-0.7,3.8-1.1,5.6
				C43.2,111.6,42.8,112.8,43.5,113.5z"/>
			<path id="left-hand-wrist" class="st1" d="M9.5,101.6c-0.7,0.1-1.2,0.5-1.4,0.6c-0.3,0.2-0.6,0.5-1.2,1.9c-0.2,0.5-0.2,0.6-1,2.7
				c-0.4,1.1-0.5,1.4-0.6,1.8c-0.1,0.6,0,0.8-0.2,1.5c-0.2,0.7-0.3,0.7-0.4,1.3c-0.1,0.7,0.1,0.7-0.1,1.6c-0.1,0.5-0.1,0.8-0.1,1.4
				c0,0.1,0,0.4,0.1,0.7c0.1,0.2,0.1,0.4,0.3,0.5s0.3,0.1,0.4,0c0.1,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.6c0.2-0.6,0.2-0.9,0.2-0.9
				c0.1,0,0.2,0.3,0.2,0.6c0,0.3,0,0.4,0,0.6c0,0.4,0.2,0.4,0.3,1c0.1,0.4,0,0.4,0.1,0.7c0.1,0.2,0.2,0.5,0.5,0.7
				c0.1,0,0.3,0.2,0.5,0.1c0.3,0,0.5-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.6c0.1-0.7-0.1-1.2,0-1.2c0.1,0,0.4,0.6,0.6,0.9
				s0.2,0.5,0.4,0.6s0.2,0,0.5,0.1s0.4,0.3,0.6,0.4c0.3,0,0.5-0.2,0.7-0.5c0-0.1,0.2-0.3,0.1-0.6c-0.1-0.3-0.4-0.3-0.6-0.6
				c-0.1-0.2-0.1-0.5-0.2-1.1c-0.1-0.7,0-0.7,0-0.7c0.1,0,0.4,0.4,0.5,0.9c0.1,0.3,0,0.4,0.2,0.6c0.1,0.3,0.4,0.5,0.6,0.7
				c0.3,0.3,0.4,0.2,0.8,0.5c0.3,0.2,0.3,0.3,0.5,0.3c0.3,0,0.5-0.4,0.5-0.4c0.1-0.3,0.1-0.6,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.4
				c-0.1-0.1-0.1-0.2-0.2-0.5c0-0.1-0.2-0.6-0.1-1.1c0.1-1.2,0.7-2.6,1.1-3.5c0.4-1.1,0.6-2,0.7-2.9c0.1-0.5,0.3-1.4,0.3-2.1
				c0-0.2,0-0.5,0.1-1.1c0-0.6,0.1-0.5,0-0.6c-0.1-0.6-1.1-0.8-2.6-1.3C10.7,101.8,10.4,101.4,9.5,101.6z"/>
			<path id="right-hand-wrist" class="st1" d="M99.5,100.4c0.3,0.2,0.2,0.6,0.7,1.1c0.4,0.4,0.8,0.4,1.3,0.7c0.5,0.3,0.7,0.9,1.2,1.9
				c0.2,0.5,0.2,0.6,1,2.7c0.4,1.1,0.5,1.4,0.6,1.8c0.1,0.6,0,0.8,0.2,1.5s0.3,0.7,0.4,1.3c0.1,0.7-0.1,0.7,0.1,1.6
				c0,0.2,0,0.4,0.1,0.5c0.1,0.6,0.1,0.7,0.1,0.9c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.4-0.3,0.5c0,0-0.2,0.2-0.4,0.2
				c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.6c-0.2-0.6-0.2-0.9-0.2-0.9c-0.1,0-0.2,0.3-0.2,0.6c0,0.3,0,0.4,0,0.6
				c0,0.4-0.2,0.8-0.3,1c-0.2,0.3-0.2,0.3-0.3,0.5c-0.2,0.4-0.1,0.7-0.3,0.8c-0.2,0.2-0.4,0.1-0.5,0.1c-0.3,0-0.5-0.2-0.5-0.3
				c-0.1-0.1-0.2-0.3-0.3-0.6c-0.1-0.7,0.1-1.2,0-1.2s-0.4,0.6-0.6,0.9s-0.2,0.5-0.4,0.6c-0.2,0.1-0.2,0-0.5,0.1s-0.4,0.3-0.6,0.4
				c-0.3,0-0.5-0.2-0.7-0.5c0-0.1-0.2-0.3-0.1-0.6c0.1-0.3,0.4-0.3,0.6-0.6c0.1-0.2,0.1-0.5,0.2-1.1s0-0.7,0-0.7l0,0
				c-0.1,0-0.4,0.2-0.5,0.4c-0.1,0.2,0,0.2-0.1,0.5c0,0.1,0,0.1-0.1,0.2c0,0.2,0,0.2-0.1,0.4c-0.1,0.3-0.4,0.5-0.6,0.7
				c-0.3,0.3-0.4,0.2-0.8,0.5c-0.3,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.4-0.5-0.4c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.1,0.1-0.2,0.2-0.4
				c0.1-0.1,0.1-0.2,0.2-0.5c0,0,0,0,0-0.1c0.1-0.6,0-1.3,0-1.3c-0.2-0.9-0.8-2.8-1-3.2c-0.4-1.3-0.6-2.3-0.7-2.9
				c-0.1-0.5-0.3-1.4-0.3-2.1c0-0.2,0-0.5-0.1-1.1c0-0.6,0-0.6,0-0.6c0-0.7,1.1-1,2.3-1.7C98.4,101,99,100.1,99.5,100.4z"/>
			<path id="left-ankle-foot" class="st1" d="M26.3,196.4c0,0.1,0,0.1,0,0.8
				c0.1,0.5,0.1,0.8,0.1,0.8c0.1,0.6,1,0.9,1.9,1.4c1.6,0.8,2.3,1.2,2.8,1.5c1,0.8,1,1.1,2,1.6c0.6,0.3,1.1,0.5,1.8,0.7
				c1,0.3,1.4,0.4,2.1,0.4c0.4,0,1.2,0,2-0.6c0.2-0.1,0.7-0.5,1-1.2c0.1-0.2,0.3-0.6,0.4-1.3c0-0.5,0-0.6-0.2-1.9
				c-0.1-0.5,0-0.3-0.2-1.5c-0.2-1.6-0.2-1.6-0.2-1.6c-0.1-1.2,0.1-1.5,0.1-2.8s0-2-0.3-2.4c-0.4-0.3-0.8-0.2-1.8-0.2
				c-3,0.1-3.8-0.7-4.6,0c-0.6,0.5-0.2,1.1-1.1,2.1c-0.4,0.4-0.8,0.7-1.3,1c-0.3,0.2-0.7,0.5-1.3,0.8c-0.7,0.4-1,0.4-1.4,0.7
				s-0.3,0.3-0.7,0.7C26.8,196.1,26.5,196.1,26.3,196.4z"/>
			<path id="right-ankle-foot" class="st1" d="M83.2,196.5c0.1,0.3,0,0.5,0,0.8
				c0,0-0.1,0.4-0.2,0.7c-0.3,0.7-1.4,1-2.2,1.5c-1,0.5-1.8,1.1-2.3,1.6c-1.1,0.9-1.1,1.2-2,1.6c-0.6,0.3-1.1,0.5-1.8,0.7
				c-1,0.3-1.4,0.4-2.1,0.4c-0.4,0-1.2,0-2-0.6c-0.2-0.1-0.7-0.5-1-1.2c-0.1-0.2-0.3-0.6-0.4-1.3c0-0.1,0-0.2,0-0.3
				c0-0.8,0.1-1.6,0.2-1.9c0.1-0.3,0.1-0.9,0.3-1.9c0.1-0.9,0.2-1.4,0.2-1.9c0-0.8-0.1-0.9-0.1-1.9c0-1.4,0-2.1,0.3-2.4
				c0.3-0.3,0.6-0.1,1.8-0.2c1.2-0.1,2.9-0.3,4.1-0.1c0.1,0,0.3,0,0.5,0.2c0.4,0.3,0.3,0.7,0.6,1.2c0.2,0.3,0.3,0.3,1,1.1
				c0.6,0.7,0.6,0.7,0.7,0.8c0.4,0.3,0.7,0.5,1.3,0.8c0.7,0.4,1,0.4,1.4,0.7s0.4,0.5,0.7,0.7c0.3,0.2,0.3,0,0.6,0.2
				C83.1,196.1,83.2,196.5,83.2,196.5z"/>
		</g>
		<g id="BODY_LINES">
			<line class="st2" x1="47.5" y1="35.9" x2="36" y2="54.3"/>
			<line class="st2" x1="62" y1="35.6" x2="75.1" y2="54.5"/>
			<line class="st3" x1="37.7" y1="94.4" x2="72.2" y2="94.4"/>
			<line class="st3" x1="33.9" y1="113.6" x2="75.4" y2="113.6"/>
			<line class="st3" x1="36" y1="142.6" x2="49.1" y2="142.5"/>
			<line class="st3" x1="61" y1="142.7" x2="73.8" y2="142.7"/>
			<line class="st4" x1="34.4" y1="155.9" x2="45.3" y2="155.9"/>
			<line class="st4" x1="64.8" y1="155.9" x2="75.6" y2="155.9"/>
			<line class="st3" x1="33.9" y1="190.2" x2="39.4" y2="190.2"/>
			<line class="st3" x1="70.1" y1="190.3" x2="76" y2="190.3"/>
			<line class="st3" x1="34.9" y1="54.1" x2="24.8" y2="52.6"/>
			<line class="st3" x1="75.1" y1="54.5" x2="84.7" y2="52.4"/>
			<line class="st3" x1="31.3" y1="70" x2="21.3" y2="68.4"/>
			<line class="st3" x1="87.8" y1="68.2" x2="78" y2="70"/>
			<line class="st3" x1="9.4" y1="100.9" x2="14.8" y2="103.3"/>
			<line class="st3" x1="94.2" y1="103.5" x2="99.1" y2="100.5"/>
			<line class="st3" x1="93.1" y1="79.3" x2="82.6" y2="81.1"/>
			<line class="st3" x1="16.4" y1="79.1" x2="27.4" y2="81.1"/>
			<line class="st3" x1="35.7" y1="54.3" x2="34.9" y2="63"/>
			<line class="st3" x1="75.4" y1="54.7" x2="74.8" y2="63.1"/>
			<line class="st5" x1="47.8" y1="27.3" x2="61.6" y2="27.3"/>
			<line class="st5" x1="46.6" y1="35.5" x2="63" y2="35.5"/>
			<line class="st3" x1="36" y1="54.3" x2="75.1" y2="54.3"/>
			<line class="st3" x1="38.6" y1="74.3" x2="70.9" y2="74.3"/>
			<line class="st3" x1="34.9" y1="63" x2="38.6" y2="74.3"/>
			<line class="st3" x1="74.8" y1="63.1" x2="70.9" y2="74.3"/>
			<line class="st3" x1="46.9" y1="94.8" x2="43.3" y2="111.6"/>
			<line class="st3" x1="62.6" y1="94.8" x2="66.1" y2="111.7"/>
		</g>
	</svg>
`;
export default postprocessBodySectionsSvg(BODY_SECTIONS_SVG);
