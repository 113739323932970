import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import { CSS_FONT_FAMILY } from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textfield: {
    id: 'textfield',
  },
};

class NebPopupTextField extends NebPopup {
  static get properties() {
    return {
      __error: Boolean,
      __text: String,
    };
  }

  initState() {
    super.initState();
    this.model = {
      title: '',
      label: '',
      confirmText: 'Ok',
      cancelText: 'Cancel',
    };

    this.__text = '';
    this.__error = false;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      ...this.__handlers,
      confirm: () => {
        this.validate();
        if (!this.__error) this.onClose(this.__text.trim());
      },
      cancel: () => this.onClose(false),
      change: ({ value }) => {
        this.__text = value;
        this.validate();
      },
    };
  }

  validate() {
    this.__error = !this.__text.trim();
  }

  modelChanged() {
    this.title = this.model.title;
    this.__text = this.model.text || '';
  }

  get __dirty() {
    return this.__text.trim() !== (this.model.text || '').trim();
  }

  firstUpdated() {
    super.firstUpdated();

    setTimeout(() => {
      this.shadowRoot.getElementById(ELEMENTS.textfield.id).focus();
    }, 0);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: space-between;
          height: 100%;
        }

        .button-container {
          display: flex;
          justify-content: start;
          margin-top: 30px;
        }

        .spacing {
          margin-right: 10px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <neb-textfield
          id="${ELEMENTS.textfield.id}"
          label="${this.model.label}"
          helper="Required"
          .maxLength="${this.model.maxLength || 255}"
          .error="${this.__error}"
          .value="${this.__text}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="spacing"
            ?disabled="${!this.__dirty || this.__error}"
            .label="${this.model.confirmText}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            .label="${this.model.cancelText}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-text-field', NebPopupTextField);
