export const DISK_COUNTS = {
  C: 7,
  T: 12,
  L: 5,
};

export const TOTAL_DISKS = Object.values(DISK_COUNTS).reduce(
  (accum, curr) => accum + curr,
  0,
);

export function createItem() {
  return {
    left: {
      issue: '',
      color: '#FFFFFF',
    },
    right: {
      issue: '',
      color: '#FFFFFF',
    },
  };
}

export function createModel() {
  return {
    occiput: createItem(),
    cDisks: new Array(7).fill(createItem()),
    tDisks: new Array(12).fill(createItem()),
    lDisks: new Array(5).fill(createItem()),
    sacrum: createItem(),
    coccyx: createItem(),
    pelvis: createItem(),
  };
}

export function buildChartNotesTableModel(model) {
  return [
    {
      name: 'OCC',
      ...model.occiput,
    },
    ...model.cDisks.map((item, index) => ({
      name: `C${index + 1}`,
      ...item,
    })),
    ...model.tDisks.map((item, index) => ({
      name: `T${index + 1}`,
      ...item,
    })),
    ...model.lDisks.map((item, index) => ({
      name: `L${index + 1}`,
      ...item,
    })),
    {
      name: 'S',
      ...model.sacrum,
    },
    {
      name: 'C',
      ...model.coccyx,
    },
    {
      name: 'P',
      ...model.pelvis,
    },
  ]
    .filter(item => item.left.issue || item.right.issue)
    .map(item => ({
      name: item.name,
      issue: [item.left.issue, item.right.issue]
        .filter(item => item)
        .join(', '),
    }));
}
