import DeprecatedApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'payments' });

const baseUrl = 'account';

export const createPayment = (account, body) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${baseUrl}/${account.id}/payments`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

export const getElectronicPayment = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `payments/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${id}-payment`,
  });

export const refundElectronicPayment = (accountId, id, body = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${baseUrl}/${accountId}/payments/${id}/refund`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

export const createGeniusPayment = (account, body) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${baseUrl}/${account.id}/genius/payments`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
