import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_BORDER_GREY_2,
} from '../../../../neb-styles/neb-variables';

const DEFAULT_TITLE = 'TITLE';

export const ELEMENTS = {
  title: { id: 'title' },
};

class NebPatientPanel extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      title: String,
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
      }

      .container {
        flex: 1 0 0;
        background-color: ${CSS_COLOR_WHITE};
        padding: 10px 0 14px;
        border: ${CSS_BORDER_GREY_2};
        border-radius: 5px;
      }

      :host([layout='small']) .container {
        width: 100%;
        padding: 10px;
      }

      .title {
        padding: 0px 10px;
        color: ${CSS_COLOR_HIGHLIGHT};
        display: flex;
        justify-content: space-between;
      }

      :host([layout='small']) .title {
        padding-left: 0px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.title = DEFAULT_TITLE;
    this.layout = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => this.onClick(e),
    };
  }

  render() {
    return html`
      <div class="container" @click="${this.__handlers.click}">
        <div id="${ELEMENTS.title.id}" class="title">
          <strong>${this.title}</strong><slot name="panel-title"></slot>
        </div>

        <slot></slot>
      </div>
    `;
  }
}

customElements.define('neb-patient-panel', NebPatientPanel);
