import { store } from '../../../../packages/neb-redux/neb-redux-store';

// deepcode ignore HardcodedNonCryptoSecret: This is actually just a key, not a secret
const CHART_NOTES_LOCAL_STORAGE_KEY = 'neb:charting:notes';

export const setNotesItemInLocalStorage = stateToAdd => {
  window.localStorage.setItem(
    CHART_NOTES_LOCAL_STORAGE_KEY,
    JSON.stringify(stateToAdd),
  );
};

export const removeNotesItemFromLocalStorage = () => {
  window.localStorage.removeItem(CHART_NOTES_LOCAL_STORAGE_KEY);
};

export const getNotesItemFromLocalStorage = () => {
  const src = window.localStorage.getItem(CHART_NOTES_LOCAL_STORAGE_KEY);

  return src ? JSON.parse(src) : null;
};

export const didRouteChange = () => {
  const { nextHash } = store.getState().route;

  return nextHash !== null;
};

export const isNotesDirty = notesItem => {
  const routeChanged = didRouteChange();

  if (notesItem.detail.dirty) {
    return routeChanged;
  }

  if (routeChanged) {
    removeNotesItemFromLocalStorage();
  }

  return false;
};
