import { html } from 'lit';
import moment from 'moment-timezone';

import { CLAIM_STATUS } from '../../packages/neb-utils/claims';
import { parseDate } from '../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../packages/neb-utils/formatters';

export const E_CLAIM_FILE_STATUS = Object.freeze({
  EMPTY: '',
  READY_TO_DOWNLOAD: 'Ready To Download',
  DOWNLOADED: 'Downloaded',
  FAILED: 'Failed',
  ERROR: 'Error',
  INVALID: 'Invalid',
  TRANSMITTED: 'Transmitted',
  PROCESSING: 'Processing',
  UPLOADING: 'Uploading to Clearinghouse',
  UPLOADED: 'Uploaded to Clearinghouse',
});

export const isValidStatus = status =>
  status === E_CLAIM_FILE_STATUS.READY_TO_DOWNLOAD ||
  status === CLAIM_STATUS.READY_TO_DOWNLOAD ||
  status === E_CLAIM_FILE_STATUS.DOWNLOADED ||
  status === E_CLAIM_FILE_STATUS.UPLOADING ||
  status === E_CLAIM_FILE_STATUS.UPLOADED;

export const allowDownload = updatedAt => {
  const currentMoment = parseDate();
  const timeApart = moment.duration(currentMoment.diff(updatedAt));

  return timeApart.asHours() >= 12;
};

const getPayerPlanName = (payerPlans, payerPlanId) => {
  const payerPlan = payerPlans.find(({ id }) => id === payerPlanId);

  if (!payerPlan) return '';

  return `${payerPlan.alias ? `(${payerPlan.alias})` : ''} ${
    payerPlan.payerName
  }`;
};

const getPatientName = (patients, patientId) => {
  const patient = patients.find(({ id }) => id === patientId);

  if (!patient) return '';

  return objToName(patient.name, DEFAULT_NAME_OPTS);
};

const getClearinghouseData = (clearinghouses, clearinghouseId) => {
  const clearinghouse = clearinghouses.find(({ id }) => id === clearinghouseId);

  return {
    customExtension: clearinghouse ? clearinghouse.customExtension : '',
    customFilenameTemplate: clearinghouse
      ? clearinghouse.customFilenameTemplate
      : '',
    clearinghouse: clearinghouse ? clearinghouse.partner : '',
    practiceIdentifierId: clearinghouse
      ? clearinghouse.practiceIdentifierId
      : '',
  };
};

const generateCustomFilename = ({
  clearinghousePartner,
  number,
  practiceIdentifierId,
  customExtension,
  customFilenameTemplate,
  createdAt,
}) => {
  const formattedCreatedAt = parseDate(createdAt).format('YYYYMMDDhhmmss');

  let customFilename = customFilenameTemplate;

  const masks = {
    '{clearinghouse}': clearinghousePartner,
    '{number}': number,
    '{date}': formattedCreatedAt,
    '{practice}': practiceIdentifierId,
  };

  Object.entries(masks).forEach(([mask, valueToReplace]) => {
    if (customFilename.includes(mask)) {
      customFilename = customFilename.replace(mask, valueToReplace);
    }
  });

  customFilename = `${customFilename}.${customExtension || 'txt'}`;

  return customFilename;
};

export const getDisplayClearinghouseName = clearinghouse =>
  !clearinghouse.alias
    ? clearinghouse.partner
    : `${clearinghouse.partner} (${clearinghouse.alias})`;

export const generateFileName = ({
  clearinghousePartner,
  claimNumber,
  batchId,
  practiceIdentifierId,
  customExtension,
  customFilenameTemplate,
  createdAt,
}) => {
  let filename;

  const number = batchId > 0 ? batchId : claimNumber;

  if (customFilenameTemplate) {
    filename = generateCustomFilename({
      clearinghousePartner,
      number,
      practiceIdentifierId,
      customExtension,
      customFilenameTemplate,
      createdAt,
    });
  } else {
    filename = `837x12_${clearinghousePartner}_${number}.${
      customExtension || 'txt'
    }`;
  }

  return filename;
};

// eslint-disable-next-line complexity
export const mapToModelEClaimFileDownload = ({
  raw,
  patients,
  payerPlans,
  clearinghouses,
}) => {
  const patientName =
    raw.patientId !== '-' ? getPatientName(patients, raw.patientId) : '-';

  const payer =
    raw.payerPlanId !== '-'
      ? getPayerPlanName(payerPlans, raw.payerPlanId)
      : '-';

  return {
    id: raw.id,
    checked: false,
    batchId: raw.batchId,
    claimNumber: raw.claimNumber || '-',
    dateOfService: raw.batchId ? null : raw.dateOfService,
    status: raw.status || '-',
    description: raw.description || '-',
    patientName,
    payer,
    updatedAt: raw.updatedAt,
    createdAt: raw.createdAt,
    s3KeyX12: raw.s3KeyX12,
    ...getClearinghouseData(clearinghouses, raw.clearingHouseId),
  };
};

export const TODAY = parseDate().toDate();
export const THIRTY_DAYS_AGO = parseDate()
  .subtract(30, 'days')
  .startOf('day')
  .toDate();

export const formatFilterQuery = model => {
  const query = Object.entries(model)
    .map(([k, v]) => {
      if (k !== 'dateFile' && k !== 'dateOfService') {
        const value = !v.length ? undefined : v;
        return { [k]: value };
      }

      return {};
    })
    .reduce((o, element) => ({ ...o, ...element }), {});

  return {
    ...query,
    dosFrom: model.dateOfService.from
      ? model.dateOfService.from.format('YYYY-MM-DD')
      : undefined,
    dosTo: model.dateOfService.to
      ? model.dateOfService.to.format('YYYY-MM-DD')
      : undefined,
    fileDateFrom: model.dateFile.from
      ? model.dateFile.from.format('YYYY-MM-DD')
      : undefined,
    fileDateTo: model.dateFile.to
      ? model.dateFile.to.format('YYYY-MM-DD')
      : undefined,
  };
};

export const buildDefaultQuery = query => {
  const dateFrom = parseDate(THIRTY_DAYS_AGO).format('YYYY-MM-DD');
  const dateTo = parseDate(TODAY).format('YYYY-MM-DD');

  return {
    ...query,
    status: E_CLAIM_FILE_STATUS.READY_TO_DOWNLOAD,
    dosFrom: dateFrom,
    dosTo: dateTo,
    fileDateFrom: dateFrom,
    fileDateTo: dateTo,
  };
};

export const CONFIRM_X12_CLAIM_DOWNLOAD = {
  title: 'Warning',
  message: html`
    <div>
      If you download this file, we will stop sending this claim to your
      clearinghouse on your behalf. You must log in to your clearinghouse and
      manually upload the claim
    </div>
    <br />
    <div>Do you wish to continue?</div>
  `,
  confirmText: 'Yes',
  cancelText: 'No',
};
