import { html, css } from 'lit';

import {
  ELEMENTS as BASE_ELEMENTS,
  List,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-list';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { padArray } from '../../../../packages/neb-utils/utils';

import NebSubstituteProviderItem from './neb-substitute-provider-item';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  items: {
    selector: 'neb-substitute-provider-item',
  },
};

export default class NebSubstituteProviderList extends List {
  static get properties() {
    return {
      helpers: Array,
      providerItems: Array,
      payerItems: Array,
      providerLabel: String,
      addLabel: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: grid;
        }
      `,
    ];
  }

  static createModel() {
    return [NebSubstituteProviderItem.createModel()];
  }

  static createSelectors(providerItems, payerItems) {
    return {
      format: v => padArray(v, NebSubstituteProviderItem.createModel()),
      unformat: v =>
        v.filter(item => item.providerId.label && item.payerIds.length),
      createItem: () => NebSubstituteProviderItem.createModel(),
      children: {
        $: NebSubstituteProviderItem.createSelectors(providerItems, payerItems),
      },
    };
  }

  initState() {
    super.initState();

    this.addLabel = 'Add Substitute Billing Provider';
    this.providerLabel = 'Substitute Billing Provider';
    this.helpers = {
      providerId: '',
      payerIds: '',
    };

    this.maxItems = 3;
    this.payerItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  __calculateMaxItems() {
    const totalPayersUsed = this.model.reduce((accum, item) => {
      accum += (item && item.payerIds && item.payerIds.length) || 0;
      return accum;
    }, 0);

    if (
      totalPayersUsed >=
      this.payerItems.filter(item => item.data.status === 'Active').length
    ) {
      return this.model.length;
    }
    return this.providerItems.filter(item => item.data.active).length || 1;
  }

  update(changedProps) {
    if (changedProps.has('model') || changedProps.has('providerItems')) {
      this.maxItems = this.__calculateMaxItems();
    }
    super.update(changedProps);
  }

  getProviderItems(item) {
    return [
      selectors.ITEM_EMPTY,
      ...this.providerItems.filter(provider => {
        if (provider.data.id === item.providerId.data.id) return true;
        return (
          this.model.findIndex(
            modelItem => provider.data.id === modelItem.providerId.data.id,
          ) === -1 && provider.data.active
        );
      }),
    ];
  }

  getPayerItems(item) {
    if (!item.payerIds) return this.payerItems;
    return [
      ...this.payerItems.filter(payer => {
        if (
          item.payerIds &&
          item.payerIds.find(itemPayer => itemPayer.data.id === payer.data.id)
        ) {
          return true;
        }
        return (
          this.model
            .flatMap(modelItem => modelItem.payerIds)
            .findIndex(modelPayer => payer.data.id === modelPayer.data.id) ===
            -1 && payer.data.status === 'Active'
        );
      }),
    ];
  }

  renderItem(item, index) {
    const providerItems = this.getProviderItems(item);
    return html`
      <neb-substitute-provider-item
        providerLabel="${this.providerLabel}"
        .name="${index}"
        .model="${item}"
        .errors="${this.errors[index]}"
        .helpers="${this.helpers}"
        .providerItems="${providerItems}"
        .payerItems="${this.getPayerItems(item)}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        ?showRemoveButton="${this.model.length > 1}"
      ></neb-substitute-provider-item>
    `;
  }
}

window.customElements.define(
  'neb-substitute-provider-list',
  NebSubstituteProviderList,
);
