import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';

import { openOverlay, OVERLAY_KEYS } from './overlay-constants';

export const openAppointmentPage = appointmentId =>
  openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
    appointmentId,
  });
export const openRescheduledAppointmentPage = (rescheduledId, appointmentId) =>
  openOverlay(OVERLAY_KEYS.RESCHEDULED_APPOINTMENT_PAGE, {
    rescheduledId,
    appointmentId,
  });

export const openEditAppointmentForm = appointmentId =>
  openOverlay(OVERLAY_KEYS.APPOINTMENT_EDIT_FORM, {
    appointmentId,
  });
export const openChangeRoomPopup = (appointmentId, openingDetail = {}) =>
  openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CHANGE_ROOM, {
    ...openingDetail,
    appointmentId,
  });
