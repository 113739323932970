export const APPOINTMENT_STATUS = Object.freeze({
  ACTIVE: 'Active',
  NO_SHOW: 'No-Show',
  CHECKED_IN: 'Checked-In',
  CHECKED_OUT: 'Checked-Out',
  CANCELED: 'Canceled',
  DELETED: 'Deleted',
});

export const KEPT_STATUSES = ['Active', 'Checked-In', 'Checked-Out'];

export const APPOINTMENT_DISPLAY_STATUSES = [
  {
    name: 'Checked In',
    status: APPOINTMENT_STATUS.CHECKED_IN,
  },
  {
    name: 'Checked Out',
    status: APPOINTMENT_STATUS.CHECKED_OUT,
  },
  {
    name: 'No Show',
    status: APPOINTMENT_STATUS.NO_SHOW,
  },
  {
    name: 'Canceled',
    status: APPOINTMENT_STATUS.CANCELED,
  },
  {
    name: 'Deleted',
    status: APPOINTMENT_STATUS.DELETED,
  },
];
export const APPOINTMENT_ACTIONS = Object.freeze({
  CHECK_IN: {
    action: 'checkIn',
    title: 'Check In Patient',
    message: 'Are you sure you want to check in this patient?',
    successMessage: 'Appointment checked in successfully',
    errorMessage: 'An error occurred when checking in the appointment',
    label: 'Check In',
  },
  CHECK_OUT: {
    action: 'checkOut',
    title: 'Check Out Patient',
    message: 'Are you sure you want to check out this patient?',
    successMessage: 'Appointment checked out successfully',
    errorMessage: 'An error occurred when checking out the appointment',
    label: 'Check Out',
  },
  CONFIRM: {
    action: 'confirm',
    title: 'Confirm Appointment',
    message: 'Are you sure you want to confirm this appointment?',
    successMessage: 'Appointment confirmed successfully',
    errorMessage: 'There was an error confirming the appointment',
    label: 'Confirm',
  },
  UNCONFIRM: {
    action: 'unconfirm',
    title: 'Return Appointment to Unconfirmed',
    message: 'Are you sure you want to return this appointment to unconfirmed?',
    successMessage: 'Appointment returned to unconfirmed successfully',
    errorMessage:
      'There was an error returning this appointment to unconfirmed',
    label: 'Return to Unconfirmed',
  },
  ARRIVED: {
    action: 'arrived',
    title: 'Confirm Arrival',
    message: 'Are you sure you want to mark this appointment as arrived?',
    successMessage: 'Appointment marked as arrived successfully',
    errorMessage: 'There was an error when marking the appointment as arrived',
    label: 'Arrived',
  },
  NO_SHOW: {
    action: 'noShow',
    title: 'No Show Appointment',
    message: 'Are you sure you want to mark this appointment as no show?',
    successMessage: 'Appointment Updated to No Show.',
    errorMessage: 'Can not Update Appointment to No Show.',
    label: 'No Show',
  },
  INSTANTIATE_RECURRENCE: {
    action: 'instantiateRecurrence',
  },
  DELETE: {
    action: 'delete',
    title: 'Delete Appointment',
    message: 'Are you sure you want to delete this appointment?',
    successMessage: 'Appointment Deleted.',
    errorMessage: 'Can not Delete Appointment.',
    label: 'Delete',
  },
  CANCEL: {
    action: 'cancel',
    title: 'Cancel Appointment',
    message: 'Are you sure you want to cancel this appointment?',
    successMessage: 'Appointment Canceled',
    errorMessage: 'Can not Cancel Appointment.',
    label: 'Cancel',
  },
  DELETE_SERIES: {
    action: 'deleteSeries',
    title: 'Delete Entire Series',
    message:
      'Are you sure you want to delete this and all following appointments created within this series?',
    successMessage: 'Appointments Deleted.',
    errorMessage: 'Can not Delete Appointments',
    label: 'Delete Entire Series',
  },
  EDIT: {
    action: 'edit',
    title: 'Edit Appointment',
    message: '',
    successMessage: 'Appointment Updated',
    errorMessage: 'Appointment was not successfully updated.',
    label: 'Edit',
  },
  RESCHEDULE: {
    action: 'reschedule',
    title: 'Reschedule Appointment',
    message: '',
    successMessage: 'Appointment Rescheduled Successfully',
    errorMessage: 'Appointment was not successfully rescheduled.',
    label: 'Reschedule',
  },
  RETURN_TO_SCHEDULED: {
    action: 'returnToScheduled',
    title: 'Return to Scheduled',
    message:
      'This will return the appointment to Scheduled status. Do you want to continue?',
    successMessage: 'Appointment returned to scheduled status successfully',
    errorMessage: 'Error when returning appointment to scheduled status',
    label: 'Return to Scheduled Status',
  },
  CHANGE_ROOM: {
    action: 'changeRoom',
    title: 'Change Checked-In Room',
    message: '',
    successMessage: 'Room changed successfully',
    errorMessage: 'Room was not successfully changed',
    label: 'Change Room',
  },
});

export const APPOINTMENT_ACTIONS_ENCOUNTER_CHECK = Object.freeze({
  CANCEL: {
    allowed: false,
    title: 'Associated Encounter',
    message:
      'This appointment is associated with an encounter. You must first delete the encounter from Charting to cancel this appointment.',
  },
  DELETE: {
    allowed: false,
    title: 'Associated Encounter',
    message:
      'This appointment is associated with an encounter. You must first delete the encounter from Charting to delete this appointment.',
  },
  EDIT: {
    allowed: false,
    title: 'Encounter Status',
    message:
      'This appointment is associated with an encounter that has posted charges. Return the charges to Charting from the Ledger to edit this appointment.',
  },
  NO_SHOW: {
    allowed: false,
    title: 'Associated Encounter',
    message:
      'This appointment is associated with an encounter. You must first delete the encounter from Charting to mark this appointment as no show.',
  },
  RESCHEDULE: {
    allowed: false,
    title: 'Encounter Status',
    message:
      'This appointment is associated with a signed encounter that has posted charges. In Charting, please reopen the encounter and remove the posted charges to change the appointment.',
  },
  RESCHEDULE_POSTED_CHARGES: {
    allowed: false,
    title: 'Encounter Status',
    message:
      'This appointment is associated with an encounter that has posted charges. In Charting, please delete the encounter or remove the posted charges to change the appointment.',
  },
  RESCHEDULE_SIGNED_ENCOUNTER: {
    allowed: false,
    title: 'Encounter Status',
    message:
      'This appointment is associated with a signed encounter. In Charting, please reopen the encounter to change the appointment.',
  },
  RESCHEDULE_PROVIDER: {
    allowed: false,
    title: 'Encounter Status',
    message:
      'This appointment is associated with an encounter. You must first delete the encounter from Charting to change this appointment with a different provider.',
  },
});
