const POPUP_CONFIRM_PAYER = 'confirm';
const POPUP_TITLE_TEXT_PAYER = 'Duplicate Insured ID';
const POPUP_BUTTON_TEXT_PAYER = 'Ok';
const DUPLICATE_INSURED_ID_MESSAGE_PAYER =
  'This patient already has insurance on file for this payer with the same Insured ID or Policy #. Are you sure you want to add a new insurance for this patient? Note: Entering an insurance plan multiple times can adversely affect reporting and billing.';
const POPUP_CONFIRM_PLAN_ID = 'confirm';
const POPUP_TITLE_TEXT_PLAN_ID = 'Duplicate Plan ID & Insured ID or Policy #';
const POPUP_BUTTON_TEXT_PLAN_ID = 'Ok';
const DUPLICATE_INSURED_ID_MESSAGE_PLAN_ID =
  'This patient already has insurance on file with this same Plan ID and Insured ID or Policy #. Are you sure you want to add a new insurance for this patient? Note: Entering an insurance plan multiple times can adversely affect reporting and billing.';
const POPUP_MSG_PAYER = {
  confirmText: 'YES',
  cancelText: 'NO',
  title: POPUP_TITLE_TEXT_PAYER,
  message: DUPLICATE_INSURED_ID_MESSAGE_PAYER,
  buttonText: POPUP_BUTTON_TEXT_PAYER,
};
const POPUP_MSG_PLAN_ID = {
  confirmText: 'YES',
  cancelText: 'NO',
  title: POPUP_TITLE_TEXT_PLAN_ID,
  message: DUPLICATE_INSURED_ID_MESSAGE_PLAN_ID,
  buttonText: POPUP_BUTTON_TEXT_PLAN_ID,
};
const REQUIRED_TEXT = 'Required';
const BLANK_HELPER_TEXT = ' ';

const getRequiredText = required =>
  required ? REQUIRED_TEXT : BLANK_HELPER_TEXT;

const findMatchingMemberAndPayerPlan = (
  patientInsurances,
  model,
  initialModel,
) => {
  const match = patientInsurances.find(
    item =>
      (item.memberIdentifier || item.planInfo.memberIdentifier) ===
        model.planInfo.memberIdentifier &&
      (item.payerPlan?.id || item.payerPlanId) === model.payerPlan.id,
  );

  if (!initialModel) {
    return match;
  }

  const hasChanged =
    model.planInfo.memberIdentifier !==
      initialModel.planInfo.memberIdentifier ||
    model.payerPlan.id !== initialModel.payerPlan.id;
  return hasChanged && match;
};

const findMatchingMemberAndGroup = (patientInsurances, model, initialModel) => {
  const match = patientInsurances.find(
    item =>
      item.memberIdentifier === model.planInfo.memberIdentifier &&
      item.groupIdentifier === model.planInfo.groupIdentifier,
  );

  if (!initialModel) {
    return match;
  }

  const hasChanged =
    model.planInfo.memberIdentifier !==
      initialModel.planInfo.memberIdentifier ||
    model.planInfo.groupIdentifier !== initialModel.planInfo.groupIdentifier;
  return hasChanged && match;
};

const INCOMPLETE_FIELD_COUNT = 2;

const rowValidationNetworkFields = (
  amount,
  remaining,
  start,
  alternateAmount,
) => {
  const completedFieldCount = [amount, remaining, start].reduce((acc, item) => {
    if (item !== '' && item !== null) {
      acc += 1;
    }
    return acc;
  }, 0);

  const checkCurrentRow =
    amount !== '' && completedFieldCount !== INCOMPLETE_FIELD_COUNT;
  const checkAlternateRow = alternateAmount !== '' && completedFieldCount === 0;

  return checkCurrentRow || checkAlternateRow;
};

export {
  POPUP_CONFIRM_PAYER,
  POPUP_CONFIRM_PLAN_ID,
  POPUP_MSG_PAYER,
  POPUP_MSG_PLAN_ID,
  REQUIRED_TEXT,
  BLANK_HELPER_TEXT,
  findMatchingMemberAndPayerPlan,
  findMatchingMemberAndGroup,
  getRequiredText,
  rowValidationNetworkFields,
};
