import { updateClaimStatus } from '../neb-api-client/src/claims';
import {
  ECLAIM_API_ERROR,
  submitEclaim,
  getS3EClaimFileJson,
} from '../neb-api-client/src/electronic-claims/claims';
import { formatToBilling } from '../neb-api-client/src/formatters/claims';
import { updateValidationErrors } from '../neb-api-client/src/validation-errors-api-client';
import { openError, openSuccess } from '../neb-dialog/neb-banner-state';
import { store } from '../neb-redux/neb-redux-store';

import { CLAIM_STATUS } from './claims';

export const __parseProcessClaimError = (err, nextActionLabel) => {
  if (err.responseBody && err.responseBody.errorType) {
    if (err.responseBody.errorType === ECLAIM_API_ERROR.BYOC_VALIDATION) {
      return {
        isValidationError: true,
        message: err.responseBody.description,
      };
    }

    if (err.responseBody.errorType === ECLAIM_API_ERROR.CHC_VALIDATION) {
      return {
        isValidationError: true,
        message:
          'Validation error has occurred. Please see Needs Attention Worklist.',
      };
    }

    if (err.responseBody.errorType === ECLAIM_API_ERROR.CHC_INTERNAL) {
      return {
        isValidationError: false,
        message: `An error occurred when submitting to the clearinghouse. Please click ${nextActionLabel} again.`,
      };
    }
  }

  return {
    isValidationError: false,
    message: `An unknown error occurred on submission. Please click ${nextActionLabel} again.`,
  };
};

// eslint-disable-next-line complexity
export const validateAndSubmitElectronicClaim = async (
  claim,
  entryPoint = 'form',
) => {
  let errClaimStatus;
  let errorResponseBody = {};

  try {
    const claimData = formatToBilling({ claim });

    await submitEclaim(claimData, true);

    store.dispatch(openSuccess('Claim submitted successfully'));
  } catch (err) {
    const errorInfo = __parseProcessClaimError(
      err,
      entryPoint === 'worklist' ? 'Generate and Submit' : 'Generate',
    );

    if (errorInfo.isValidationError) {
      errClaimStatus = await updateClaimStatus(claim.id, {
        status: CLAIM_STATUS.VALIDATION_ERROR,
      });

      await updateValidationErrors(errClaimStatus.claimStatus.id, {
        errors: err.responseBody.errors || [
          {
            field: 'Payer Primary Identifier',
            description: errorInfo.message,
          },
        ],
      });

      errorResponseBody = err.responseBody;
    } else {
      await updateClaimStatus(claim.id, {
        status: CLAIM_STATUS.ERROR,
      });
    }

    store.dispatch(openError(errorInfo.message));
  }
  return errorResponseBody;
};

export const getClaimJsonFromS3 = async eClaimFileId => {
  let s3ClaimJson;

  try {
    const claimJson = await getS3EClaimFileJson(eClaimFileId);
    s3ClaimJson = JSON.stringify(claimJson, null, '\t');
    store.dispatch(openSuccess('Clearinghouse Data retrieved successfully'));
  } catch (_) {
    store.dispatch(openError('Clearinghouse Data not found for claim'));
  }
  return s3ClaimJson;
};

export const openS3ClaimTab = ({
  claim,
  claimNumber,
  batchId,
  partnerName = 'Change Healthcare',
}) => {
  try {
    const s3Claim = window.open();
    s3Claim.document.write(`
  <pre>
    <div id="chcClaim" style="margin-top: 25px; width: 75%; font-family: sans-serif; color: #000000;">
      ${claim}
    </div>
  </pre>
  `);

    const number = batchId > 0 ? `Batch ${batchId} ` : `Claim ${claimNumber} `;

    s3Claim.document.title = `${partnerName} ${number}`;

    s3Claim.document.close();
  } catch (_) {
    store.dispatch(
      openError('Cannot open Claim Data tab, please check your pop-up blocker'),
    );
  }
};

export const openRawClaimStatusTab = (claimStatus, tabTitle) => {
  try {
    const s3ClaimStatus = window.open();
    s3ClaimStatus.document.write(`
  <pre>
    <div id="chcClaimStatus" style="margin-top: 25px; width: 75%; font-family: sans-serif; color: #000000;">
      ${claimStatus}
    </div>
  </pre>
  `);

    s3ClaimStatus.document.title = tabTitle;
    s3ClaimStatus.document.close();
  } catch (_) {
    store.dispatch(
      openError(
        'Cannot open CHC Claim Status Data tab, please check your pop-up blocker',
      ),
    );
  }
};
