import { UPDATE_USER } from '../../../../neb-redux/actions/booking-session-actions';

import {
  CREATE_ACCOUNT,
  NEW_APPOINTMENT,
  UPDATE_TENANT_SHORT_NAME,
  UPDATE_BOOKING_PAGE,
  RESET_BOOKING,
} from './bookingAction';

function handleCreateAccount(state, action) {
  switch (action.status) {
    case 'success':
      const newState = {
        ...state,
        isCreatingAccount: false,
        creatingAccountError: null,
        user: action.response.data[0],
      };
      return newState;

    case 'failed':
      return {
        ...state,
        isCreatingAccount: false,
        creatingAccountError: action.error,
      };

    default:
      return { ...state, isCreatingAccount: true, creatingAccountError: null };
  }
}

export const booking = (
  state = {
    page: null,
    tenantShortName: null,
    appointment: {},
    user: null,
    isCreatingAccount: false,
    creatingAccountError: null,
  },
  action,
) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return handleCreateAccount(state, action);

    case NEW_APPOINTMENT:
      return { ...state, ...action.booking };

    case UPDATE_TENANT_SHORT_NAME:
      return { ...state, ...action.booking };

    case UPDATE_BOOKING_PAGE:
      return { ...state, ...action.booking };

    case UPDATE_USER:
      return { ...state, ...action.booking };

    case RESET_BOOKING:
      return {
        ...state,
        appointment: {},
        user: null,
        isCreatingAccount: false,
        creatingAccountError: null,
      };

    default:
      return state;
  }
};
