import {
  SCAN_INTERFACE_TYPE,
  SCAN_ADD_PAGE_TYPE,
  SCAN_DEFAULT_CURRENT_PAGE,
} from '../enums/scan-enums';

export const mapJsonToFormData = (json, isUrlSearchString = false) => {
  const body = new FormData();
  Object.entries(json).forEach(([field, value]) => {
    body.append(field, value);
  });

  if (isUrlSearchString) {
    return new URLSearchParams(body).toString();
  }
  return body;
};

export const mapScanConfigToApi = config => ({
  twainId: config.scannerId,
  currentPage: SCAN_DEFAULT_CURRENT_PAGE,
  scanType: SCAN_ADD_PAGE_TYPE.AFTER_PAGE,
  pixelTypeId: config.colorId,
  resolutionId: config.resolutionId,
  pageSizeId: config.pageSizeId,
  interfaceType: SCAN_INTERFACE_TYPE.WEB_ONLY,
  documentSourceId: config.documentSourceId,
  duplexId: config.duplexId || '',
});
