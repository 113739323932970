import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import {
  E_CLAIM_FILE_STATUS,
  getDisplayClearinghouseName,
  THIRTY_DAYS_AGO,
  TODAY,
} from '../../utils/e-claim-files';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  dateRangeFile: { id: 'date-range-file' },
  dateRangeDOS: { id: 'date-range-dos' },
  selectSearchPatient: { id: 'select-search-patient' },
  selectSearchPayer: { id: 'select-search-payer' },
  selectStatus: { id: 'select-status' },
  textfieldBatchId: { id: 'textfield-batch-id' },
  textfieldClaimNumber: { id: 'textfield-claim-number' },
  selectClearinghouse: { id: 'select-clearinghouse' },
};

const FILTERS = Object.freeze({
  dateFile: 'dateFile',
  dateOfService: 'dateOfService',
  patientId: 'patientId',
  payerPlanId: 'payerPlanId',
  status: 'status',
  batchId: 'batchId',
  claimNumber: 'claimNumber',
  clearinghouseId: 'clearinghouseId',
});

class EclaimFileDownloadsFilters extends NebFilters {
  static get properties() {
    return {
      clearinghouses: Array,
      payerPlans: Array,

      __patientItems: Array,
      __payerItems: Array,
      __payerPlansState: Object,
      __clearinghouseItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(3, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    return {
      dateFile: { from: THIRTY_DAYS_AGO, to: TODAY },
      dateOfService: { from: THIRTY_DAYS_AGO, to: TODAY },
      patientId: selectors.ITEM_EMPTY,
      payerPlanId: selectors.ITEM_EMPTY,
      status: E_CLAIM_FILE_STATUS.READY_TO_DOWNLOAD,
      batchId: '',
      claimNumber: '',
      clearinghouseId: selectors.ITEM_EMPTY,
    };
  }

  createSelectors() {
    return {
      children: {
        dateFile: NebDateRange.createSelectors(),
        dateOfService: NebDateRange.createSelectors(),
        patientId: selectors.select([]),
        payerPlanId: selectors.select([]),
        clearinghouseId: selectors.select([]),
      },
    };
  }

  initState() {
    super.initState();

    this.clearinghouses = [];
    this.payerPlans = [];

    this.__clearinghouseItems = [];
    this.__payerPlanItems = [];
    this.__patientItems = [];
    this.__payerPlansState = FetchService.createModel();
    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          this.__payerPlansState = state;
        },
        onMapItem: data => ({
          label: `(${data.alias}) ${data.payerName}`,
          data,
        }),
      },
      payerPlansApi.fetchMany,
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeClearinghouse: e => this.__applySelectorState(e),
      changePatient: e => this.__applySelectorState(e),
      renderPatientItem: model => this.__renderPatientItem(model),
      requestMorePatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
      changePayer: e => this.__applySelectorState(e),
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),
      searchPayer: e => {
        this.__payerPlansService.search(e.value);
      },
      clearBatchId: () => this.formService.apply('batchId', ''),
      clearClaimNumber: () => this.formService.apply('claimNumber', ''),
      changeDateFile: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      changeDateOfService: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();

        this.onApply({
          ...model,
          dateFile: {
            from: model.dateFile.from
              ? parseDate(model.dateFile.from._i)
              : null,
            to: model.dateFile.to ? parseDate(model.dateFile.to._i) : null,
          },
          dateOfService: {
            from: model.dateOfService.from
              ? parseDate(model.dateOfService.from._i)
              : null,
            to: model.dateOfService.to
              ? parseDate(model.dateOfService.to._i)
              : null,
          },
        });
      },
    };
  }

  load() {
    this.__payerPlansService.fetch();

    this.__patientService = createServerPatientsCollection({
      onChange: ({ pageItems }) => {
        this.__patientItems = pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
    });
  }

  update(changedProps) {
    if (changedProps.has('payerPlans')) {
      this.__payerPlanItems = this.payerPlans.map(item => ({
        item,
        label: `(${item.alias}) ${item.payerName}`,
      }));
    }

    if (changedProps.has('clearinghouses')) {
      this.__clearinghouseItems = this.__formatClearinghouses(
        this.clearinghouses,
      );

      this.__clearinghouseItems.unshift(selectors.ITEM_EMPTY);
    }

    super.update(changedProps);
  }

  __applySelectorState(e) {
    if (e.value) {
      return this.formService.apply(e.name, e.value);
    }
    return this.formService.apply(e.name, selectors.ITEM_EMPTY);
  }

  __renderPatientItem(model) {
    return html`
      <neb-patient-card tabindex="0" .model="${model.data}"></neb-patient-card>
    `;
  }

  __renderPatientSelectSearch() {
    return html`
      <neb-select-search
        id="${ELEMENTS.selectSearchPatient.id}"
        name="${FILTERS.patientId}"
        label="Patient"
        itemHeight="80"
        .items="${this.__patientItems}"
        .value="${this.state.patientId}"
        .onChange="${this.handlers.changePatient}"
        .onSearch="${this.handlers.searchPatients}"
        .onRenderItem="${this.handlers.renderPatientItem}"
        .onRequest="${this.handlers.requestMorePatients}"
        showSearch
        forceDown
      ></neb-select-search>
    `;
  }

  __renderPayerSelectSearch() {
    return html`
      <neb-select-search
        id="${ELEMENTS.selectSearchPayer.id}"
        name="${FILTERS.payerPlanId}"
        label="Payer"
        helper=" "
        .value="${this.state.payerPlanId}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.changePayer}"
        .onSearch="${this.handlers.searchPayer}"
        .onRequest="${this.handlers.fetchMorePayers}"
        showSearch
        forceDown
      ></neb-select-search>
    `;
  }

  __formatClearinghouses(clearinghouses) {
    return clearinghouses.map(clearinghouse => ({
      label: `${getDisplayClearinghouseName(clearinghouse)}`,
      data: clearinghouse,
    }));
  }

  renderContent() {
    const sortClearinghouse = this.__clearinghouseItems.sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    const dateFile = {
      from: this.state.dateFile.from
        ? parseDate(this.state.dateFile.from)
        : null,
      to: this.state.dateFile.to ? parseDate(this.state.dateFile.to) : null,
    };

    const dateOfService = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.selectStatus.id}"
        name="${FILTERS.status}"
        label="File Status"
        .items="${Object.values(E_CLAIM_FILE_STATUS)}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dateRangeFile.id}"
        name="${FILTERS.dateFile}"
        label="File Date"
        .model="${dateFile}"
        .onChange="${this.handlers.changeDateFile}"
      ></neb-range-date>

      ${this.__renderPatientSelectSearch()} ${this.__renderPayerSelectSearch()}

      <neb-range-date
        id="${ELEMENTS.dateRangeDOS.id}"
        name="${FILTERS.dateOfService}"
        label="Date of Service"
        .model="${dateOfService}"
        .onChange="${this.handlers.changeDateOfService}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.textfieldBatchId.id}"
        name="${FILTERS.batchId}"
        label="Batch ID"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.batchId ? 'neb:clear' : ''}"
        .value="${this.state.batchId}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearBatchId}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.textfieldClaimNumber.id}"
        name="${FILTERS.claimNumber}"
        label="Claim #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.claimNumber ? 'neb:clear' : ''}"
        .value="${this.state.claimNumber}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearClaimNumber}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.selectClearinghouse.id}"
        name="${FILTERS.clearinghouseId}"
        label="Clearinghouse"
        .items="${this.__clearinghouseItems}"
        .value="${this.state.clearinghouseId}"
        .onChange="${this.handlers.changeClearinghouse}"
        .sort="${sortClearinghouse}"
      ></neb-select>
    `;
  }
}

window.customElements.define(
  'neb-filters-eclaim-file-download',
  EclaimFileDownloadsFilters,
);
