export const hidePropIfEncounterOnly = (model, prop, valueWhenHidden = '') =>
  model.encounterOnly ? valueWhenHidden : model[prop];

export const renderDateOrFullDateBasedOnEncounterOnly = model => {
  if (model.encounterOnly) {
    return model.formattedServiceDate;
  }
  return model.fullDate;
};

export const hideIfEncounterOnly = ({ encounterOnly } = {}) => !!encounterOnly;

export const disableSignIfEncounterOnly = ({ encounterOnly } = {}) =>
  encounterOnly !== false;
