import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../src/utils/locations/location-util';
import { getAppointmentById } from '../../neb-api-client/src/appointment-api-client';
import * as patientApiClient from '../../neb-api-client/src/patient-api-client';
import { computeTime } from '../../neb-input/nebFormatUtils';
import { parseDate } from '../../neb-utils/date-util';
import { objToName, DEFAULT_NAME_OPTS } from '../../neb-utils/formatters';

import { ConnectedService } from './connected-service';

export class AppointmentDetailsMomentService extends ConnectedService {
  constructor(callback, includeEncounterOnly = false) {
    super({
      appointmentTypes: 'appointmentTypes.items',
      providers: 'providers.item',
      allLocations: 'practiceInformation.item.locations',
    });

    this.appointmentId = '';
    this.onStateChanged = callback;
    this.includeEncounterOnly = includeEncounterOnly;
  }

  computeDurationDisplay(startDate, endDate) {
    const start = startDate.format('h:mm A');
    const end = endDate.format('h:mm A');
    const duration = computeTime(endDate.diff(startDate));
    const durationDisplay = `${start} - ${end} (${duration})`;
    return durationDisplay;
  }

  getAppointmentPatient(appointment) {
    return appointment.accountId
      ? patientApiClient.getOne(appointment.accountId, true)
      : patientApiClient.getOne(appointment.patientId, false);
  }

  // eslint-disable-next-line complexity
  async _stateChanged({ appointmentTypes, providers, allLocations }) {
    const appointment = this.fetchedAppointment
      ? this.fetchedAppointment
      : undefined;

    if (appointment) {
      const details = {};

      const patient = await this.getAppointmentPatient(appointment);

      appointment.patient = patient;
      appointment.patientId = patient.id;

      const apptType = appointmentTypes.find(
        x => x.id === appointment.appointmentTypeId,
      );

      if (apptType) {
        details.appointmentTypeName = apptType.name || '';
      }

      const provider = providers.find(x => x.id === appointment.providerId);

      if (provider) {
        details.providerDisplayName = objToName(
          provider.name,
          DEFAULT_NAME_OPTS,
        );
      }

      details.startDisplayDate = `${appointment.start.format(
        'dddd, MMMM D, YYYY',
      )}${appointment.walkIn ? ' (Walk-In)' : ''}`;

      details.durationDisplay = this.computeDurationDisplay(
        appointment.start,
        appointment.end,
      );

      const location = getLocationValue(
        allLocations.filter(loc => !loc.addressOnly),
        appointment.locationId,
        LOCATION_KEYS.NAME,
      );

      details.location = location;

      if (
        appointment.appointmentSplits &&
        appointment.appointmentSplits.length > 1
      ) {
        details.appointmentSplits = appointment.appointmentSplits
          .sort((a, b) => new Date(a.start) - new Date(b.start))
          .map(split => {
            const start = parseDate(split.start).format('h:mm A');
            const duration = computeTime(
              parseDate(split.end).diff(parseDate(split.start)),
            );

            return {
              start,
              roomName: split.calendarResource.name,
              duration: `(${duration})`,
            };
          });
      }

      appointment.details = details;
    }

    this.onStateChanged({
      appointment,
      appointmentTypes,
    });
  }

  async update(appointmentId) {
    this.appointmentId = appointmentId;

    try {
      const { appointment: fetchedAppointment } = await getAppointmentById(
        appointmentId,
        {
          include: ['encounter', 'recurrenceEvent', 'appointmentSplits'],
          includeEncounterOnly: this.includeEncounterOnly,
        },
        true,
      );

      this.fetchedAppointment = fetchedAppointment;

      if (this.fetchedAppointment) {
        this.fetchedAppointment.start = parseDate(
          this.fetchedAppointment.start,
        );

        this.fetchedAppointment.end = parseDate(this.fetchedAppointment.end);
      }
    } catch (err) {
      this.fetchedAppointment = undefined;
    }

    this.updateState();
  }
}
