import '../../../../packages/neb-lit-components/src/components/neb-table-header';
import '../../../../packages/neb-lit-components/src/components/neb-table-row';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-table-add';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { css, html } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../../packages/neb-styles/neb-variables';
import { normalizeForSearch } from '../../../../packages/neb-utils/formatters';
import { CollectionService } from '../../../../packages/neb-utils/services/collection';
import { deduplicate } from '../../../../packages/neb-www-booking/src/components/util/enable-booking-util';
import { getRules } from '../../../api-clients/claim-scrubbing';
import {
  buildSearchPlaceholder,
  getSelectToAssociate,
} from '../../../utils/user-message';

const ASSOCIATE_RULES = 'Associate Rules';
const RULES_SEARCH_ITEMS = ['Rule Name'];
const RULES = 'Rules';
const RULE_SET = 'Rule Set';
const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ELEMENTS = {
  header: { id: 'associate-rules-header' },
  searchField: {
    id: 'associate-rules-search-field',
    label: buildSearchPlaceholder(RULES_SEARCH_ITEMS),
  },
  description: { id: 'associate-rules-description' },
  actionBar: { id: 'associate-rules-action-bar' },
  noResultsMessage: { id: 'associate-rules-no-results-message' },
  noRulesMessage: { id: 'associate-rules-no-rules-message' },
  paginationControl: { id: 'associate-rules-pagination-control' },
  tableHeader: { id: 'table-header' },
  rows: {
    selector: 'neb-table-row',
  },
};

const TABLE_ROWS_KEYS = {
  RULE_NAME: 'name',
  DESCRIPTION: 'description',
};

const TABLE_CONFIG = [
  {
    key: TABLE_ROWS_KEYS.RULE_NAME,
    label: 'Rules Name',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.DESCRIPTION,
    label: 'Description',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
];

export const EMPTY_MODEL = {
  ruleSetRules: [],
  level: 'Payer',
};

class NebOverlayAssociateRules extends Overlay {
  static get properties() {
    return {
      __rules: Array,
      __searchText: String,
      __allAdded: Boolean,
      __currentPageIndex: Number,
      __pageCount: Number,
      associatedRules: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: flex;
          flex-direction: column;
        }

        .content {
          width: 1024px;
        }

        .icon-chevron {
          height: 12px;
          width: 12px;
          transform: rotate(90deg);
        }

        .main-header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .add-button {
          padding: ${CSS_SPACING};
        }

        .search-field {
          min-height: 70px;
          margin: 15px ${CSS_SPACING} 20px;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }

        .container-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__allAdded = false;
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__searchText = '';
    this.__headerAddLabel = '';
    this.__rules = [];
    this.associatedRules = [];
    this.model = EMPTY_MODEL;

    this.__initServices();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__rules = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },
        onCacheItem: ({ description, name }) =>
          normalizeForSearch([description, name].join(' ')),
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
        onSort: this.__onSortRules,
      },
      {
        hideInactive: false,
        sortParams: {
          key: TABLE_ROWS_KEYS.RULE_NAME,
          dir: SORT_DIR.ASC,
        },
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      search: ({ value }) => this.__collectionService.search(value),
      clearSearch: () => this.__collectionService.search(''),
      apply: () => {
        this.dismiss(this.associatedRules.sort(this.__onSortRules));
      },
      addAll: () => {
        const allItems = [
          ...this.associatedRules,
          ...this.__collectionService.getFilteredItems(),
        ];
        this.associatedRules = deduplicate(allItems, 'code');
      },
      add: (item, _index) => {
        this.associatedRules = [...this.associatedRules, item];
      },
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
    };
  }

  async firstUpdated() {
    const { data } = await getRules({
      ruleSetLevel: this.model.level || 'Payer',
    });
    this.__collectionService.setItems(data);
  }

  __onSortRules(a, b) {
    return a[TABLE_ROWS_KEYS.RULE_NAME].localeCompare(
      b[TABLE_ROWS_KEYS.RULE_NAME],
    );
  }

  __refreshAllAdded() {
    this.__allAdded = this.__rules.length
      ? this.__collectionService
          .getFilteredItems()
          .every(i => this.associatedRules.some(c => c.name === i.name))
      : null;

    this.__headerAddLabel = this.__allAdded
      ? 'All Rules Added'
      : 'Add All Rules';
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model) {
      const { ruleSetRules = [] } = this.model;
      this.associatedRules = ruleSetRules;
      this.__collectionService.setItems(this.__rules);
    }

    if (changedProps.has('__rules') || changedProps.has('associatedRules')) {
      this.__refreshAllAdded();
    }

    super.update(changedProps);
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="main-header"
        .title="${ASSOCIATE_RULES}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${getSelectToAssociate(RULES.toLowerCase(), RULE_SET.toLowerCase())}
      </div>
    `;
  }

  renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="search-field"
        leadingIcon="neb:search"
        label="${ELEMENTS.searchField.label}"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }

  __renderPaginationControl() {
    return html`
      <neb-pagination
        id="${ELEMENTS.paginationControl.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  __getTableConfig() {
    return {
      tableConfig: TABLE_CONFIG,
      itemName: 'rule',
      itemPluralName: 'rules',
      title: 'Associate Rule',
    };
  }

  __capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  __renderTableHeader() {
    return html`
      <neb-table-header
        id="${ELEMENTS.tableHeader.id}"
        class="header-table"
        .config="${this.__getTableConfig().tableConfig}"
        .layout="${this.layout}"
        .allAdded="${this.__allAdded}"
        .addAllLabel="${this.__headerAddLabel}"
        .onAddAll="${this.handlers.addAll}"
      ></neb-table-header>
    `;
  }

  __renderNoResultsMessage() {
    return html`
      <p id="${ELEMENTS.noResultsMessage.id}" class="no-items-message">
        No results.
      </p>
    `;
  }

  __renderNoRulesMessage() {
    return html`
      <p id="${ELEMENTS.noRulesMessage.id}" class="no-items-message">
        There are no rules available to associate.
      </p>
    `;
  }

  __renderNoItemsMessage() {
    return this.__searchText
      ? this.__renderNoResultsMessage()
      : this.__renderNoRulesMessage();
  }

  __renderTableRows() {
    return this.__rules.map((rule, index) => {
      const added = this.associatedRules.some(r => r.code === rule.code);
      return html`
        <neb-table-row
          class="row"
          .config="${this.__getTableConfig().tableConfig}"
          .layout="${this.layout}"
          .model="${rule}"
          .index="${index}"
          .topic="${this.__capitalize(this.__getTableConfig().itemName)}"
          .onAdd="${this.handlers.add}"
          .added="${added}"
        ></neb-table-row>
      `;
    });
  }

  renderTable() {
    return html`
      <div class="container-table">
        ${this.__renderTableHeader()} ${this.__renderTableRows()}
        ${!this.__rules.length ? this.__renderNoItemsMessage() : ''}
      </div>

      ${this.__renderPaginationControl()}
    `;
  }

  renderContent() {
    return html`
      <div class="container">
        ${this.renderHeader()} ${this.renderDescription()}
        ${this.renderSearchField()} ${this.renderTable()}
        ${this.renderActionBar()}
      </div>
    `;
  }
}

customElements.define('neb-overlay-associate-rules', NebOverlayAssociateRules);
