import '../../../components/controls/inputs/neb-simple-rich-text-editor';

import { css, html } from 'lit';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  editor: { id: 'editor' },
};

export class NebFormProviderNotes extends NebForm {
  static createModel() {
    return {
      note: '',
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          display: flex;
          flex-direction: column;

          flex: 1 0 0;
          padding: 0;
        }

        .editor {
          height: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.enableSaveAndClose = true;
    this.disableSaveIndicator = true;

    this.cancelLabel = 'Save and Close';
    this.removeLabel = 'Cancel';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  createSelectors() {
    return {};
  }

  renderContent() {
    return html`
      <neb-simple-rich-text-editor
        id="${ELEMENTS.editor.id}"
        class="editor"
        name="note"
        .value="${this.state.note}"
        .onChange="${this.handlers.change}"
      ></neb-simple-rich-text-editor>
    `;
  }
}

customElements.define('neb-form-provider-notes', NebFormProviderNotes);
