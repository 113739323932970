import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import { getLineItemDetails } from '../../../../../packages/neb-api-client/src/ledger/line-items';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../../packages/neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../../packages/neb-utils/enums';
import {
  getActivePatientPackages,
  getPatientPackagesWithCounts,
} from '../../../../../packages/neb-utils/patientPackage';
import { MODE } from '../../../../../packages/neb-utils/table';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';

export const ELEMENTS = {
  header: { id: 'header' },
  table: { id: 'table' },
};

class NebOverlayPatientPackagesSummary extends Overlay {
  static get properties() {
    return {
      __packageItems: Array,
      __packagesChanged: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
        }

        neb-table {
          width: auto;
          margin-top: ${CSS_SPACING};
          overflow-y: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.model = {
      patientId: '',
      lineItems: [],
      lineItem: {},
      showAllActivePackages: false,
    };

    this.__packagesChanged = false;

    this.__packageItems = [];
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      ...this.handlers,
      selectPackage: async (_, item) => {
        const result = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: item.id,
            patientId: this.model.patientId,
          },
        });

        if (result) {
          this.__packageItems = await this.__genPackageItems();
          this.__packagesChanged = true;
        }
      },
      dismiss: () => {
        this.dismiss(this.__packagesChanged);
      },
    };
  }

  __buildConfig() {
    return [
      {
        truncate: true,
        key: 'name',
        label: 'Name',
        flex: css`1 0 0`,
      },
      {
        key: 'summary',
        label: 'Summary',
        flex: css`1 0 0`,
      },
      {
        key: 'rendered',
        label: 'Rendered',
        flex: css`1 0 0`,
      },
      {
        key: 'remaining',
        label: 'Remaining',
        flex: css`1 0 0`,
      },
      !this.model.showAllActivePackages
        ? {
            key: 'appliedProcedures',
            label: 'Procedures',
            flex: css`1 0 0`,
          }
        : null,
    ].filter(item => item);
  }

  async __getMultiCarePackageItems() {
    const { patientId, lineItem, lineItems } = this.model;

    let lineItemsToInclude;
    let lineItemIds;

    if (lineItem) {
      const { invoiceId } = lineItem;

      const isOldInvoice = lineItems
        ? lineItems.some(li => li.invoiceId === invoiceId)
        : false;

      if (invoiceId && isOldInvoice) {
        lineItemIds = lineItems
          .filter(li => li.invoiceId === invoiceId)
          .map(li => li.id);

        lineItemsToInclude = await getLineItemDetails({}, { lineItemIds });
      } else {
        lineItemsToInclude = [lineItem];
      }
    } else {
      lineItemIds = lineItems.map(li => li.id);
      lineItemsToInclude = await getLineItemDetails({}, { lineItemIds });
    }

    const packages = await getPatientPackagesWithCounts(patientId);

    const filteredPackages = packages.filter(pkg =>
      lineItemsToInclude.some(item => item.patientPackageId === pkg.id),
    );

    const packageItems = filteredPackages.map(pkg => {
      const packageChargeIds = pkg.charges.map(({ chargeId }) => chargeId);

      const appliedProcedures = lineItemsToInclude.map(item =>
        item.patientPackageId === pkg.id &&
        packageChargeIds.includes(item.chargeId)
          ? item.description
          : null,
      );

      const isService = pkg.type === PACKAGE_TYPE.SERVICE;

      return {
        id: pkg.id,
        name: pkg.name,
        summary: pkg.summary,
        rendered: isService
          ? pkg.usedCount.unitsUsed
          : pkg.usedCount.visitsUsed,
        remaining: isService
          ? pkg.usedCount.unitsRemaining
          : pkg.usedCount.visitsRemaining,
        appliedProcedures: appliedProcedures
          .filter(charge => charge !== null)
          .join(', '),
      };
    });

    return packageItems;
  }

  async __getAllActivePackages() {
    const { patientId } = this.model;

    const activePackages = await getActivePatientPackages(patientId);

    const packageItems = activePackages.map(pkg => {
      const isService = pkg.type === PACKAGE_TYPE.SERVICE;

      return {
        id: pkg.id,
        name: pkg.name,
        summary: pkg.summary,
        rendered: isService
          ? pkg.usedCount.unitsUsed
          : pkg.usedCount.visitsUsed,
        remaining: isService
          ? pkg.usedCount.unitsRemaining
          : pkg.usedCount.visitsRemaining,
      };
    });

    return packageItems;
  }

  __genPackageItems() {
    return this.model.showAllActivePackages
      ? this.__getAllActivePackages()
      : this.__getMultiCarePackageItems();
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__packageItems = await this.__genPackageItems();
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          .onCancel="${this.__handlers.dismiss}"
          title="Patient Packages Summary"
          showCancelButton
        ></neb-popup-header>
      </div>
      <neb-table
        id="${ELEMENTS.table.id}"
        .model="${this.__packageItems}"
        .mode="${MODE.DETAIL}"
        .config="${this.__buildConfig()}"
        .onSelectRow="${this.__handlers.selectPackage}"
      ></neb-table>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-packages-summary',
  NebOverlayPatientPackagesSummary,
);
