import './neb-encounter-card';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import '../../../../../src/components/controls/inputs/neb-checkbox';

export const NO_ENCOUNTERS_MESSAGE =
  'There are no encounters for this patient.';
export const NO_RESULTS_MESSAGE = 'No results found.';
export const ELEMENTS = {
  encounterCard: {
    id: 'encounter-card',
  },
  encounterCheckbox: {
    id: 'encounter-checkbox',
  },
  message: {
    id: 'message',
  },
  items: {
    selector: '.item',
  },
  checkboxes: {
    selector: '.checkbox',
  },
};

class NebEncounterCardList extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      models: {
        type: Array,
      },
      message: {
        type: String,
      },
      readOnly: {
        type: Boolean,
        reflect: true,
      },
      selectedEncounterId: {
        type: String,
      },
      showArrowIcon: {
        type: Boolean,
      },
      showCheckbox: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.models = [];
    this.message = NO_ENCOUNTERS_MESSAGE;
    this.readOnly = false;
    this.showArrowIcon = false;
    this.selectedEncounterId = '';
    this.showCheckbox = false;

    this.onItemClicked = () => {};

    this.onItemCheck = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickCheckbox: e => e.stopPropagation(),
      clickLink: e => {
        e.stopPropagation();
        const key = e.currentTarget.getAttribute('key');
        const index = Number(e.currentTarget.getAttribute('index'));
        this.onClickLink(key, index);
      },
      itemClicked: ({ currentTarget: { model } }) => {
        this.onItemClicked({
          id: this.id,
          name: this.name,
          value: model,
        });
      },
      checkItem: e => {
        if (!this.disabled) {
          const attribute = e.currentTarget.getAttribute('encounterId');
          const { checked } = e.currentTarget;
          this.onItemCheck(attribute, checked);
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  updateMessage(message = NO_ENCOUNTERS_MESSAGE) {
    this.message = message;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }

        .checkbox {
          margin: 0 5px;
        }

        .list {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .text-caption {
          font-style: italic;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_GREY_1};
          padding: 0 ${CSS_SPACING};
        }

        :host([readOnly]) .item {
          cursor: unset;
        }
      `,
    ];
  }

  __renderCheckbox(index, encounter) {
    if (this.showCheckbox) {
      return html`
        <neb-checkbox
          id="${ELEMENTS.encounterCheckbox.id}-${index}"
          index="${index}"
          encounterId="${encounter.id}"
          class="checkbox"
          @click="${this.__handlers.checkItem}"
          ?checked="${encounter.checked}"
        >
        </neb-checkbox>
      `;
    }

    return '';
  }

  __renderEncounterCards() {
    return this.models.map(
      (model, index) => html`
        <div class="container">
          ${this.__renderCheckbox(index, model)}
          <neb-encounter-card
            id="${ELEMENTS.encounterCard.id}-${index}"
            class="item"
            .layout="${this.layout}"
            .model="${model}"
            .selected="${model.id === this.selectedEncounterId}"
            @click="${this.__handlers.itemClicked}"
            .showArrowIcon="${this.showArrowIcon}"
          >
          </neb-encounter-card>
        </div>
      `,
    );
  }

  __renderReadOnlyEncounterCards() {
    return this.models.map(
      (model, index) => html`
        <neb-encounter-card
          id="${ELEMENTS.encounterCard.id}-${index}"
          class="item read-only-item"
          .layout="${this.layout}"
          .model="${model}"
          .selected="${false}"
        >
        </neb-encounter-card>
      `,
    );
  }

  render() {
    return html`
      <div class="container">
        ${this.models.length === 0
          ? html`
              <p id="${ELEMENTS.message.id}" class="text-caption">
                ${this.message}
              </p>
            `
          : html`
              <div class="list">
                ${this.readOnly
                  ? this.__renderReadOnlyEncounterCards()
                  : this.__renderEncounterCards()}
              </div>
            `}
      </div>
    `;
  }
}

customElements.define('neb-encounter-card-list', NebEncounterCardList);
