import './neb-node-env';
import './actions/booking-session-actions';

// creating an initial store with no reducers, which can be
// lazily extended with reducers along with components that need them
import { popupReducer } from '@neb/popup';
import {
  compose,
  createStore,
  applyMiddleware,
  combineReducers,
} from 'redux/es/redux.mjs';
import thunk from 'redux-thunk/es';

import { chartingReducer } from '../../src/store/reducers/charting';
import { hotButtonsReducer } from '../../src/store/reducers/hot-buttons-reducer';
import { layoutMedia } from '../neb-app-layout/neb-layout-reducer';
import { navState } from '../neb-app-layout/neb-nav-store';
import { appointmentTypes } from '../neb-appointment-type/reducers/appointment-types-reducer';
import { availabilityReducer } from '../neb-availability/neb-availability-state';
import { appointmentsReducer } from '../neb-calendar/state/appointments/reducers/neb-appointment-reducer';
import { publicAppointmentReducer } from '../neb-calendar/state/appointments/reducers/neb-appointment-reducer-public';
import { bannerReducer } from '../neb-dialog/neb-banner-state';
import { sessionReducer } from '../neb-login/neb-session-state';
import { timeoutReducer } from '../neb-login/neb-timeout-reducer';
import { patients } from '../neb-patient/src/store/patientsReducer';
import { practiceInformation } from '../neb-practice-information/reducers/practice-information-reducer';
import { providersAvailabilityReducer } from '../neb-provider/neb-provider-availability-state';
import { providersReducer } from '../neb-provider/neb-provider-state';
import { providersAvailabilityPublicReducer } from '../neb-provider/state/providers-availabilities/reducers/all-providers-availability-public-reducers';
import { routeReducer } from '../neb-route/neb-route-state';
import { API_URL } from '../neb-utils/env';
import { booking } from '../neb-www-booking/src/store/booking/bookingReducer';
import { onlineBookingReducer } from '../neb-www-booking/src/store/online-booking/neb-online-booking-state';
import { practiceInfo } from '../neb-www-booking/src/store/practiceInfo/practiceInfoReducer';
import { services } from '../neb-www-booking/src/store/services/servicesReducer';

import { allItemsReducer } from './reducers/all-items/all-items';
import { globalLoadingReducer } from './reducers/global-loading-reducer';
import { itemReducer } from './reducers/item/item';
import { itemsReducer } from './reducers/items/items';
import { paginatedItemsReducer } from './reducers/paginated-items/paginated-items';
import { createNamedReducer } from './utils';

// Use devtools if installed (https://github.com/zalmoxisus/redux-devtools-extension)
const _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combineReducersSorted = reducers =>
  combineReducers(
    Object.keys(reducers)
      .sort()
      .reduce((a, c) => {
        a[c] = reducers[c];
        return a;
      }, {}),
  );

// Initial no-op reducer; all reducers will be lazily added using `addStore`
const reducer = (_state, _action) => ({});

export function resetAll() {
  return {
    type: 'RESET_ALL',
  };
}
const middlewareToApply = [thunk];

const entitiesReducer = (state = {}) => state; // Create basic store which can be extended with `addStore`

/**
 * This Redux "enhancer" adds a basic `store.addReducers()` method to the
 * store, so that top-level slice reducers can be lazily added to the store,
 * in the PRPL pattern spirit of "don't load anything the user didn't ask for"
 */
function lazyReducerEnhancer(nextCreator) {
  return (origReducer, preloadedState) => {
    let lazyReducers = {};

    const nextStore = nextCreator(origReducer, preloadedState);
    return {
      addReducers(newReducers) {
        const _combineReducers = Object.assign(lazyReducers, newReducers);

        const appReducers = combineReducersSorted(
          (lazyReducers = {
            globalLoading: globalLoadingReducer,
            popup: popupReducer,
            problemList: createNamedReducer(itemsReducer, 'problems'),
            encounter: createNamedReducer(itemReducer, 'encounters'),
            encounters: createNamedReducer(itemsReducer, 'encounters'),
            encounterDiagnosis: createNamedReducer(
              itemsReducer,
              'encounterDiagnosis',
            ),
            hotButtons: hotButtonsReducer,
            allEncounterDiagnosis: createNamedReducer(
              allItemsReducer,
              'encounterDiagnosis',
            ),
            priorEncounters: createNamedReducer(
              itemsReducer,
              'priorEncounters',
            ),
            priorEncounterPages: createNamedReducer(
              paginatedItemsReducer,
              'priorEncounters/paginated',
            ),
            chartingAppointments: createNamedReducer(
              itemsReducer,
              'appointment/charting',
            ),
            chartingAppointmentForDay: createNamedReducer(
              allItemsReducer,
              'appointment/charting/day',
            ),
            treatmentPlan: createNamedReducer(itemReducer, 'treatmentPlans'),
            treatmentPlans: createNamedReducer(itemsReducer, 'treatmentPlans'),
            treatmentPlansCompleted: createNamedReducer(
              allItemsReducer,
              'treatmentPlans/completed',
            ),
            alert: createNamedReducer(itemReducer, 'alerts'),
            alerts: createNamedReducer(itemsReducer, 'alerts'),
            allAlerts: createNamedReducer(allItemsReducer, 'alerts'),
            charting: chartingReducer,
            entities: entitiesReducer,
            availability: availabilityReducer,
            providersAvailabilityPublic: providersAvailabilityPublicReducer,
            providersAvailability: providersAvailabilityReducer,
            providers: providersReducer,
            onlineBooking: onlineBookingReducer,
            route: routeReducer,
            banners: bannerReducer,
            booking,
            navState,
            apiOverride: (
              state = {
                value: API_URL,
              },
            ) => state,
            appointmentTypes,
            practiceInformation,
            practiceInfo,
            services,
            patients,
            appointments: appointmentsReducer,
            publicAppointments: publicAppointmentReducer,
            session: sessionReducer,
            sessionTimeout: timeoutReducer,
            layoutMedia,
            ..._combineReducers,
          }),
        );

        const rootReducer = (state, action) => {
          if (action.type === 'RESET_ALL') {
            state = {
              // states to keep after reset
              apiOverride: state.apiOverride,
              layoutMedia: state.layoutMedia,
              route: state.route,
            };
          }

          return appReducers(state, action);
        };

        this.replaceReducer(rootReducer);
      },
      ...nextStore,
    };
  };
}

export const store = createStore(
  reducer,
  {},
  _compose(
    lazyReducerEnhancer,
    applyMiddleware(
      ...middlewareToApply,
      /* ...any other middleware... */
    ),
  ),
);

store.addReducers({});

export const connect = storeArg => baseElement =>
  class extends baseElement {
    connectedCallback() {
      super.connectedCallback();

      this.__storeUnsubscribe = storeArg.subscribe(() =>
        this._stateChanged(storeArg.getState()),
      );

      this._stateChanged(storeArg.getState());
    }

    disconnectedCallback() {
      this.__storeUnsubscribe();

      if (super.disconnectedCallback) super.disconnectedCallback();
    }

    _stateChanged(_state) {
      throw new Error('_stateChanged() not implemented', this);
    }
  };
