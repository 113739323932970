import '../../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../controls/inputs/neb-checkbox';
import '../../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { join } from 'lit/directives/join.js';

import { getLineItemDetails } from '../../../../../packages/neb-api-client/src/ledger/line-items';
import { getChartingPermissions } from '../../../../../packages/neb-api-client/src/permissions-api-client';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { openEncounterSummary } from '../../../../../packages/neb-lit-components/src/utils/encounter-overlays-util';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../packages/neb-utils/formatters';
import { CSS_COLOR_GREY_1 } from '../../../../styles';
import {
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../../../utils/diagnoses';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../utils/locations/location-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  dateOfService: { selector: '[id^=date-of-service-]' },
  encounterNumber: { selector: '[id^=link-encounterNumber-]' },
  code: { selector: '[id^=cell-code-]' },
  buttonModifiersEdit: { selector: '[id^=button-modifiers-edit-]' },
  modifiers: { selector: '[id^=modifiers-]', tag: 'neb-modifiers' },
  diagnosis: { selector: '[id^=diagnoses-]', tag: 'neb-select' },
  diagnosisPointers: { selector: '[id^=diagnosis-pointers-]' },
  provider: { selector: '[id^=cell-provider-]' },
  location: { selector: '[id^=location-]' },
  billedAmount: { selector: '[id^=cell-billedAmount-]' },
};

export const CONFIG = [
  {
    key: 'dateOfService',
    label: 'DOS',
    flex: css`0 0 80px`,
  },
  {
    key: 'encounterNumber',
    label: 'Encounter',
    flex: css`0 0 80px`,
  },
  {
    key: 'code',
    label: 'Procedure',
    flex: css`0 0 80px`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 240px`,
  },
  {
    key: 'diagnoses',
    label: 'Diagnosis Pointer',
    flex: css`2 0 200px`,
  },
  {
    key: 'provider',
    label: 'Provider',
    flex: css`0.5 0 60px`,
    truncate: true,
  },
  {
    key: 'location',
    label: 'Location',
    flex: css`1 0 60px`,
    truncate: true,
  },
  {
    key: 'billedAmount',
    label: 'Amount',
    flex: css`0 0 50px`,
    formatter: v => centsToCurrency(v),
  },
];
class NebTableClaimsWorklistViewClaimCharges extends NebTable {
  static get properties() {
    return {
      encounterNumber: Array,
      providers: Array,
      locations: Array,
      model: Array,
      chargesModel: Object,
      dirty: Boolean,
      index: Number,
      invoiceClaimIds: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-tx {
          text-wrap: wrap;
          align-self: center;
        }

        .row-header {
          padding: 10px 0px 0 0px;
          margin: 0px;
          border-top: 2px solid ${CSS_COLOR_GREY_1};
        }

        .row-data {
          padding: 0px;
          margin: 0px;
        }

        .cell-data {
          padding: 5px 0;
        }

        .cell {
          align-items: center;
        }

        .button-modifiers-edit {
          margin-right: 5px;
        }

        .content {
          height: 80px;
        }

        .cell-header {
          flex: 0 0 80px;
          padding-bottom: 10px;
        }

        .cell:not(:last-child) {
          margin-right: 10px;
        }

        .diagnoses-pointer {
          display: grid;
          grid-template-columns: 60% 40%;
          gap: 5px;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();
    this.locations = [];
    this.enablePatients = false;
    this.providers = [];
    this.encounterNumber = [];
    this.config = CONFIG;
    this.model = [];
    this.chargesModel = {};
    this.totalItemCount = 0;
    this.selectedItemCount = 0;
    this.index = 0;
    this.dirty = false;
    this.invoiceClaimIds = {};

    this.onLoad = () => {};

    this.onDirty = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDx: e => this.handlers.change({ ...e, index: this.index }),
      clickEncounter: ({ name }) => {
        const [, rowIndex] = name.split('.');
        const { encounterId, appointmentTypeId, patientId } =
          this.model[rowIndex];

        const callback = () =>
          this.openEncounterSummaryOverlay({
            encounterId,
            appointmentTypeId,
            patientId,
          });

        return this.dirty ? this.onDirty(callback) : callback();
      },
      openModifiersEditPopup: async name => {
        await this.__checkDirty();

        const [, rowIndex] = name.split('.');

        const { encounterId, invoiceId, encounterNumber, claimId, status } =
          this.model[rowIndex];

        const lineItems = await getLineItemDetails({ encounterId });

        const selectedLineItems = lineItems
          .filter(x => x.invoiceId === invoiceId)
          .sort(
            (a, b) =>
              a.encounterCharge.chargeOrder - b.encounterCharge.chargeOrder,
          );

        this.chargesModel = {
          items: selectedLineItems
            ? selectedLineItems.map(c => {
                this.chargeWithModifiers = {
                  ...c,
                  encounterNumber,
                  modifiers: [
                    c.modifier_1,
                    c.modifier_2,
                    c.modifier_3,
                    c.modifier_4,
                  ],
                  claimId,
                  status,
                };

                return this.chargeWithModifiers;
              })
            : [],
        };

        await this.openModifierPopup(invoiceId);
      },
    };
  }

  async __checkDirty() {
    if (this.dirty) {
      await this.onDirty();
    }
  }

  async openModifierPopup(invoiceId) {
    const popupResult = await openPopup(POPUP_RENDER_KEYS.MODIFIERS_EDIT, {
      ...this.chargesModel,
      invoiceClaimIds: this.invoiceClaimIds[invoiceId],
    });

    if (popupResult) {
      await this.onLoad();
    }
  }

  async openEncounterSummaryOverlay({
    encounterId,
    appointmentTypeId,
    patientId,
  }) {
    await openEncounterSummary({
      encounterId,
      appointmentTypeId,
      patient: { id: patientId },
    });

    return this.onLoad();
  }

  __renderDOS({ dateOfService, rowIndex }) {
    return html`
      <div>
        <neb-text id="date-of-service-${rowIndex}" class="date" bold
          >${parseDate(dateOfService).format('MM/DD/YYYY')}</neb-text
        >
      </div>
    `;
  }

  __renderEncounterNumber(value, rowIndex) {
    const { signed } = this.model[rowIndex];
    return html`
      <neb-text
        id="link-encounterNumber-${rowIndex}"
        name="link-encounterNumber.${rowIndex}"
        bold
        link
        .onClick="${this.handlers.clickEncounter}"
      >
        ${signed ? `${value} - Signed` : `${value}`}
      </neb-text>
    `;
  }

  __renderLocation(rowIndex) {
    const { locationId } = this.model[rowIndex];
    return html`
      <div class="text">
        <div class="location">
          <neb-text id="location-${rowIndex}" truncate
            >${getLocationValue(
              this.locations,
              locationId,
              LOCATION_KEYS.NAME,
            )}</neb-text
          >
        </div>
      </div>
    `;
  }

  __renderProvider(rowIndex) {
    const { providerId } = this.model[rowIndex];
    const found = this.providers.find(provider => provider.id === providerId);
    return found && found.name
      ? html`
          <span class="cell-tx"
            >${objToName(found.name, DEFAULT_NAME_OPTS)}<span></span
          ></span>
        `
      : '';
  }

  __renderModifiers(rowIndex) {
    const {
      modifier_1: modifier1,
      modifier_2: modifier2,
      modifier_3: modifier3,
      modifier_4: modifier4,
    } = this.model[rowIndex];
    const modifiers = [modifier1, modifier2, modifier3, modifier4];

    const { signed } = this.model[rowIndex];
    return html`
      <neb-button-icon
        .id="button-modifiers-edit-${rowIndex}"
        name="modifiers.${rowIndex}"
        class="button-modifiers-edit"
        icon="neb:edit"
        .onClick="${this.handlers.openModifiersEditPopup}"
        .model="${this.chargesModel}"
        ?disabled="${signed && !getChartingPermissions()}"
      ></neb-button-icon>

      <neb-modifiers
        .id="modifiers-${rowIndex}"
        name="modifiers.${rowIndex}"
        class="modifiers"
        label=" "
        helper=" "
        .values="${modifiers}"
        ?disabled="${true}"
      ></neb-modifiers>
    `;
  }

  __renderDiagnoses(rowIndex) {
    const { diagnosesPointers = [], diagnoses = [] } = this.model[rowIndex];
    const formatedDiagnoses = diagnoses
      .sort((a, b) => a.order - b.order)
      .map(({ code, description }) => ({
        label: `${code} - ${description}`,
      }));

    const formatedDiagnosesPointers = diagnosesPointers
      .filter(dx => Boolean(dx))
      .map(({ diagnosisCode }) => {
        const { description } = diagnoses.find(
          ({ code }) => code === diagnosisCode,
        );
        return {
          label: `${diagnosisCode} - ${description}`,
        };
      });

    const formatedDiagnosesPointersTx = diagnosesPointers
      .map(({ diagnosisCode }) => {
        const { order } = diagnoses.find(({ code }) => code === diagnosisCode);

        return {
          diagnosisCode,
          order,
        };
      })
      .sort((a, b) => a.order - b.order)
      .map(({ diagnosisCode }) => diagnosisCode);

    return html`
      <div class="diagnoses-pointer">
        <neb-select
          label=" "
          helper=" "
          multiSelect
          showFullText
          maxSelection="4"
          forceAlignMenu="right"
          .items="${formatedDiagnoses}"
          .value="${formatedDiagnosesPointers}"
          id="diagnoses-${rowIndex}"
          name="diagnosesPointers.${rowIndex}"
          wrapText
          itemHeight="${getDiagnosisItemHeight(formatedDiagnoses)}"
          itemMinWidth="${ITEM_MIN_WIDTH}"
          .onChange="${this.handlers.changeDx}"
        ></neb-select>
        <span class="cell-tx" id="diagnosis-pointers-${rowIndex}"
          >${join(formatedDiagnosesPointersTx, ', ')}</span
        >
      </div>
    `;
  }

  /* eslint-disable complexity */
  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'dateOfService':
        return this.__renderDOS({ dateOfService: value, rowIndex });

      case 'encounterNumber':
        return this.__renderEncounterNumber(value, rowIndex);

      case 'location':
        return this.__renderLocation(rowIndex);

      case 'provider':
        return this.__renderProvider(rowIndex);

      case 'modifiers':
        return this.__renderModifiers(rowIndex, value);

      case 'diagnoses':
        return this.__renderDiagnoses(rowIndex);

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-claim-worklist-view-claim-charges',
  NebTableClaimsWorklistViewClaimCharges,
);
