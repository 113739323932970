const filteredKeys = {
  locationIds: (value, defaultValue) => (!value.length ? defaultValue : value),
  showHidden: value => value,
  default: value => (!value.length ? undefined : value),
  completed: value => value,
};

const hasValue = value => value !== null && value !== undefined && value !== '';

const createDosFilterQueries = ({ dos }) => ({
  dosFrom: dos.from ? dos.from.format('YYYY-MM-DD') : undefined,
  dosTo: dos.to ? dos.to.format('YYYY-MM-DD') : undefined,
});

const createAmountFilterQueries = ({ amount }) => ({
  amountMin: hasValue(amount?.min) ? amount.min : undefined,
  amountMax: hasValue(amount?.max) ? amount.max : undefined,
});

const createBalanceFilterQueries = ({ balance }) => ({
  balanceMin: hasValue(balance?.min) ? balance.min : undefined,
  balanceMax: hasValue(balance?.max) ? balance.max : undefined,
});

const createRangeFilterQueries = model => ({
  ...createDosFilterQueries(model),
  ...createAmountFilterQueries(model),
  ...createBalanceFilterQueries(model),
});

const createFilterQueries = (model, userLocationIds) => {
  let value;

  const nonRangeQueries = Object.entries(model)
    .map(([k, v]) => {
      value = v;

      if (!['dos', 'amount', 'balance'].includes(k)) {
        const key = filteredKeys[k];
        value = key ? key(v, userLocationIds) : filteredKeys.default(v);
        return { [k]: value || undefined };
      }
      return {};
    })
    .reduce((o, element) => ({ ...o, ...element }), {});

  return {
    ...nonRangeQueries,
    ...createRangeFilterQueries(model),
  };
};

const getTotalDosFilters = ({ dos }) => (dos.from ? 1 : 0) + (dos.to ? 1 : 0);
const getTotalAmountFilters = ({ amount }) =>
  (hasValue(amount?.min) ? 1 : 0) + (hasValue(amount?.max) ? 1 : 0);
const getTotalBalanceFilters = ({ balance }) =>
  (hasValue(balance?.min) ? 1 : 0) + (hasValue(balance?.max) ? 1 : 0);

const getTotalNonRangeFilters = model =>
  getTotalDosFilters(model) +
  getTotalAmountFilters(model) +
  getTotalBalanceFilters(model);

const getTotalFiltersApplied = model => {
  const totalNonRangeFilters = Object.entries(model).reduce((acc, [k, v]) => {
    if (!['dos', 'amount', 'balance'].includes(k)) {
      const keyFunc = filteredKeys[k];

      const validFilter = keyFunc ? keyFunc(v) : filteredKeys.default(v);
      return acc + (validFilter ? 1 : 0);
    }
    return acc;
  }, 0);

  return totalNonRangeFilters + getTotalNonRangeFilters(model);
};

export { createFilterQueries, getTotalFiltersApplied };
