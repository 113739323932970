import { LitElement, html, css } from 'lit';
import '../../../../neb-lit-components/src/components/tables/neb-table';

import { baseStyles } from '../../../../neb-styles/neb-styles';

const TYPE_DICTIONARY = {
  acute: 'Acute',
  chronic: 'Chronic',
  'self-limiting': 'Self-Limiting',
};

const CONFIG = [
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`2 0 100px`,
    truncate: true,
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`1 0 60px`,
    truncate: true,
    formatter: v => TYPE_DICTIONARY[v],
  },
];

export const ELEMENTS = {
  table: { id: 'table' },
};
class NebChartingProblemsSummary extends LitElement {
  static get properties() {
    return {
      problemList: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.problemList = [];
  }

  render() {
    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        emptyMessage="There are no active problems for this patient."
        preview
        .model="${this.problemList}"
        .config="${CONFIG}"
        allowPropagation
      ></neb-table>
    `;
  }
}
customElements.define(
  'neb-charting-problems-summary',
  NebChartingProblemsSummary,
);
