import '../neb-toggle-button';
import '../../../../../src/components/misc/neb-icon';
import '../neb-text';

import { css, html } from 'lit';

import NebTable from './neb-table';

export const ELEMENTS = {
  addButton: {
    selector: '[id^=add-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
};

const CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 300px`,
  },
  {
    key: 'diagnosisCode',
    label: 'ICD10',
    flex: css`0 0 120px`,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`1 0 0`,
  },
];

const MOBILE_CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 50px`,
    mobile: true,
  },
  {
    key: 'diagnosisCode',
    label: 'ICD10',
    flex: css`1 0 70px`,
    mobile: true,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`3 0 400px`,
    mobile: true,
  },
];

class NebTableDiagnosesSelect extends NebTable {
  static get properties() {
    return {
      selectedItems: Array,
      maxSelectionReached: Boolean,
    };
  }

  initState() {
    super.initState();

    this.onAdd = () => {};
    this.selectedItems = [];
    this.maxSelectionReached = false;
  }

  update(changedProps) {
    if (changedProps.has('layout')) {
      this.config = this.layout === 'small' ? MOBILE_CONFIG : CONFIG;
    }

    super.update(changedProps);
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      add: (_, rowIndex) => this.onAdd(rowIndex),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .add-button {
          width: 280px;
          padding: 0;
          white-space: nowrap;
        }

        :host([layout='small']) .add-button {
          width: 30px;
          padding: 0;
          white-space: nowrap;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'selected':
        return this.__renderButton(rowIndex, value);
      default:
        return value;
    }
  }

  __renderButton(rowIndex, _value) {
    const isAdded = !!this.selectedItems.find(
      e => e.diagnosisCode === this.model[rowIndex].diagnosisCode,
    );

    return html`
      <neb-toggle-button
        id="add-button-${rowIndex}"
        class="add-button"
        .index="${rowIndex}"
        .buttonText="${isAdded ? 'Diagnosis Added' : 'Add Diagnosis'}"
        .layout="${this.layout}"
        .isToggled="${isAdded}"
        .onClick="${this.handlers.add}"
        ?disabled="${this.maxSelectionReached}"
      ></neb-toggle-button>
    `;
  }
}

customElements.define('neb-table-diagnoses-select', NebTableDiagnosesSelect);
