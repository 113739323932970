import { html, css } from 'lit';

import '../../../../../src/components/collection-pages/neb-collection-page-failed-payments';

import Overlay, { ELEMENTS as BASE_ELEMENTS } from './neb-overlay';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  failedPaymentsPage: { id: 'page-failed-payments' },
};
class NebOverlayFailedPayments extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-collection-page-failed-payments
        id="${ELEMENTS.failedPaymentsPage.id}"
        .layout="${this.layout}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-collection-page-failed-payments>
    `;
  }
}

customElements.define('neb-overlay-failed-payments', NebOverlayFailedPayments);
