import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

const HEADER_JSON = { 'Content-Type': 'application/json' };

const MINUTE = 60 * 1000;
export const chartingApiClient = new ApiClientV2({
  microservice: 'charting',
  cacheConfig: {
    max: 400,
    maxAge: 10 * MINUTE,
  },
});

export const fetchOne = async ({ code, icd10year }) => {
  const result = await chartingApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/clinical-codes/find',
    headers: HEADER_JSON,
    body: JSON.stringify({ code, icd10year }),
    optOutLoadingIndicator: true,
    cacheKey: `${code}-${icd10year}`,
  });

  return result;
};

export const fetchMany = async ({
  searchText,
  icd10year,
  offset = 0,
  limit = 10,
}) => {
  const result = await chartingApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/clinical-codes/list-codes',
    headers: HEADER_JSON,
    body: JSON.stringify({ searchText, icd10year, offset, limit }),
    optOutLoadingIndicator: true,
    cacheKey: `${searchText}-${icd10year}-${offset}-${limit}`,
  });

  return result;
};
