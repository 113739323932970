import './neb-ripple-button';

import { html, LitElement, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_BLACK,
  CSS_COLOR_DISABLED,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  checkbox: {
    id: 'checkbox',
  },
  checkmark: {
    id: 'checkmark',
  },
  label: {
    id: 'label',
  },
  rippleButton: {
    id: 'ripple-button',
  },
};

class NebCheckboxOld extends LitElement {
  static get properties() {
    return {
      checked: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      label: String,
      name: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.checked = false;
    this.disabled = false;
    this.label = '';
    this.name = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => {
        if (this.disabled) return;
        this.checked = !this.checked;
        this.onClick(this.checked, this);
      },
      keypress: e => {
        if (this.disabled) return;

        if (e.keyCode === 13 || e.keyCode === 32) {
          this.checked = !this.checked;
          this.onClick(this.checked, this);
        }
      },
    };
  }

  click() {
    this.__handlers.click();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([disabled]) {
          pointer-events: none;
          color: ${CSS_COLOR_DISABLED};
        }

        :host([disabled]) .label {
          color: ${CSS_COLOR_DISABLED};
        }

        .container {
          display: flex;
          align-items: center;
          width: fit-content;
          outline: none;
        }

        .checkbox {
          display: inline-block;
          cursor: pointer;
          position: relative;
          width: 18px;
          height: 18px;
          border: 2px solid ${CSS_COLOR_GREY_1};
          border-radius: 2px;
          margin: 11px 0 0 11px;
        }

        .checkbox[checked] {
          border: 2px solid ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .checkbox {
          border-color: ${CSS_COLOR_DISABLED};
        }

        :host([disabled]) .checkbox[checked] {
          background-color: ${CSS_COLOR_DISABLED};
          border: 2px solid ${CSS_COLOR_DISABLED};
        }

        .checkbox[checked] .checkmark {
          position: absolute;
          left: 4px;
          top: -1px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        :host([disabled]) .label {
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          color: var(--neb-checkbox-label-color, ${CSS_COLOR_DISABLED});
        }

        .label[checked] {
          color: ${CSS_COLOR_BLACK};
        }
      `,
    ];
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
        @keypress="${this.__handlers.keypress}"
      >
        <neb-ripple-button
          id="${ELEMENTS.rippleButton.id}"
          ?disabled="${this.disabled}"
        >
          <div
            slot="content"
            id="${ELEMENTS.checkbox.id}"
            class="checkbox"
            ?checked="${this.checked}"
          >
            <div id="${ELEMENTS.checkmark.id}" class="checkmark"></div>
          </div>
        </neb-ripple-button>

        <label
          id="${ELEMENTS.label.id}"
          class="label"
          ?checked="${this.checked}"
          >${this.label}</label
        >
      </div>
    `;
  }
}

window.customElements.define('neb-checkbox-old', NebCheckboxOld);
