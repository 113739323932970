import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';
import './neb-page-ar-hub-new-custom-view';

import { css, html, LitElement } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_WHITE, CSS_SMALL_SPACING } from '../../../styles';

export const ELEMENTS = {
  manageViewsButton: { id: 'manage-views-button' },
  saveViewAsButton: { id: 'save-view-as-button' },
  untitledViewButton: { id: 'untitled-view-button' },
  unsupportedPage: { id: 'unsupported-page' },
  customViewPage: { id: 'custom-view-page' },
};

const AR_HUB_RESOLUTION_MESSAGE =
  'You can access AR HUB by resizing your browser to fit the resolution of a desktop/laptop.';

class NebPageArHub extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      showNewView: Boolean,
    };
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        background-color: ${CSS_COLOR_WHITE};
      }

      .links {
        display: flex;
        padding: ${CSS_SMALL_SPACING};
        gap: ${CSS_SMALL_SPACING};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.showNewView = false;
  }

  __initHandlers() {
    this.__handlers = {
      openManageViews: async () => {
        this.showNewView = await openOverlay(OVERLAY_KEYS.MANAGE_VIEWS);
      },
    };
  }

  __renderUnsupportedPage() {
    return html`
      <neb-unsupported-page
        id="${ELEMENTS.unsupportedPage.id}"
        .layout="${this.layout}"
        icon="wallet"
        location="AR Hub"
        specificMessage="${AR_HUB_RESOLUTION_MESSAGE}"
      ></neb-unsupported-page>
    `;
  }

  __renderCustomViewPage() {
    return this.showNewView
      ? html`
          <neb-page-ar-hub-new-custom-view
            id="${ELEMENTS.customViewPage.id}"
          ></neb-page-ar-hub-new-custom-view>
        `
      : '';
  }

  __renderNewViewLink() {
    return this.showNewView
      ? html`
          <neb-text id="${ELEMENTS.untitledViewButton.id}" link
            >Untitled View</neb-text
          >
        `
      : '';
  }

  __renderButtonLinks() {
    return html`
      <neb-button-action
        id="${ELEMENTS.manageViewsButton.id}"
        label="Manage Views"
        .onClick="${this.__handlers.openManageViews}"
      ></neb-button-action>
      <neb-button-action
        id="${ELEMENTS.saveViewAsButton.id}"
        label="Save View As"
        leadingIcon="save"
      ></neb-button-action>
    `;
  }

  __renderLinks() {
    return html`
      <div class="links">
        ${this.__renderNewViewLink()} ${this.__renderButtonLinks()}
      </div>
    `;
  }

  render() {
    return this.layout !== 'large'
      ? html` ${this.__renderUnsupportedPage()} `
      : html` ${this.__renderLinks()} ${this.__renderCustomViewPage()} `;
  }
}

customElements.define('neb-page-ar-hub', NebPageArHub);
