/* 
    WARNING!!!!

  This component extends NebLightDom which does not use shadowDom.

  Any styling or element changes can have global effects.
*/

import '../../../../neb-material-design/src/components/neb-md-button';
import '../../../../../src/components/controls/inputs/neb-light-textfield';

import { html, css } from 'lit';

import { LightDom } from '../../../../../src/components/misc/neb-light-dom';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { BUTTON_ROLE } from '../neb-button';

import { validatePassword } from './password-validator';

export const ELEMENTS = {
  changePasswordButton: { id: 'neb-password-form-button-change-password' },
  passwordInput: { id: 'neb-password-form-input-password' },
  confirmPasswordInput: { id: 'neb-password-form-input-password-confirm' },
  email: { id: 'neb-password-form-email' },
};

export const TEXT_ERROR_REPEATED_PASSWORD =
  'This password is not allowed. Please try a different password.';

const TEXT_EMAIL = 'Email Address';
const TEXT_NEW_PASSWORD = 'New Password';
const TEXT_PASSWORD_CONFIRM = 'Confirm Password';

class NebPasswordForm extends LightDom {
  static get properties() {
    return {
      __confirmPassword: String,
      __newPassword: String,
      __passwordConfirmError: String,
      __passwordError: String,

      email: String,
      repeatedPassword: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-password-form-header {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .neb-password-form-container-input {
          margin: ${CSS_SPACING} 0;
          width: 309px;
        }

        .neb-password-form-spacer {
          height: 10px;
        }

        .neb-password-form-field {
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__confirmPassword = '';
    this.__newPassword = '';
    this.__passwordConfirmError = '';
    this.__passwordError = '';

    this.email = '';
    this.repeatedPassword = false;

    this.onChangePassword = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      passwordInputChanged: ({ value }) => {
        this.__passwordError = '';
        this.__newPassword = value;
        if (!value) this.__passwordError = 'Required';
      },
      confirmPasswordInputChanged: ({ value }) => {
        this.__passwordConfirmError = '';
        this.__confirmPassword = value;
        if (!value) this.__passwordConfirmError = 'Required';
      },
      changePassword: () => {
        if (this.__isChangePasswordButtonDisabled()) return;
        const validPassword = validatePassword(
          this.__newPassword,
          this.__confirmPassword,
        );

        if (validPassword.valid) {
          this.repeatedPassword = false;
          this.onChangePassword(this.__newPassword);
        } else {
          this.__passwordError = validPassword.errors.password;
          this.__passwordConfirmError = validPassword.errors.verifyPassword;
        }
      },
      validatePassword: () => !this.__passwordError,
      validateConfirmPassword: () => !this.__passwordConfirmError,
    };
  }

  __isChangePasswordButtonDisabled() {
    return !(this.__confirmPassword && this.__newPassword);
  }

  update(changedProps) {
    if (changedProps.has('repeatedPassword') && this.repeatedPassword) {
      this.__passwordError = ' ';
      this.__passwordConfirmError = TEXT_ERROR_REPEATED_PASSWORD;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <div class="neb-password-form-header">Please update your password</div>

      <div class="neb-password-form-container-input">
        <neb-light-textfield
          id="${ELEMENTS.email.id}"
          class="neb-password-form-field"
          helper=" "
          .value="${this.email}"
          .label="${TEXT_EMAIL}"
          disabled
        ></neb-light-textfield>

        <div class="neb-password-form-spacer"></div>

        <neb-light-textfield
          id="${ELEMENTS.passwordInput.id}"
          class="neb-password-form-field"
          name="password"
          autoComplete="current-password"
          helper="Required"
          type="password"
          inputType="password"
          .value="${this.__newPassword}"
          .label="${TEXT_NEW_PASSWORD}"
          .error="${this.__passwordError}"
          .onChange="${this.__handlers.passwordInputChanged}"
        ></neb-light-textfield>

        <div class="neb-password-form-spacer"></div>

        <neb-light-textfield
          id="${ELEMENTS.confirmPasswordInput.id}"
          class="neb-password-form-field"
          name="confirmPassword"
          autoComplete="new-password"
          helper="Required"
          inputType="password"
          type="password"
          .value="${this.__confirmPassword}"
          .label="${TEXT_PASSWORD_CONFIRM}"
          .error="${this.__passwordConfirmError}"
          .onChange="${this.__handlers.confirmPasswordInputChanged}"
        ></neb-light-textfield>
      </div>

      <neb-button
        id="${ELEMENTS.changePasswordButton.id}"
        label="Change Password"
        role="${BUTTON_ROLE.CONFIRM}"
        @click="${this.__handlers.changePassword}"
        ?disabled="${this.__isChangePasswordButtonDisabled()}"
      ></neb-button>
    `;
  }
}

customElements.define('neb-password-form', NebPasswordForm);
