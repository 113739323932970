/* eslint-disable complexity */
import { parseDate } from '../../../neb-utils/date-util';
import {
  createModelPatientAuth,
  rawPatientAuthorizationToModel,
  rawPatientAuthorizationsToModel,
} from '../../../neb-utils/patientAuthorization';

export const stringToDate = date => (date ? new Date(date) : date);

export const BILL_TYPES = {
  SELF: 'Self Pay',
  INSURANCE: 'Insurance',
  CARE_PACKAGE: 'Care Package',
};

export const createModelPayerInsurance = () => ({
  primaryPayerId: null,
  primaryInsuranceId: null,
  secondaryPayerId: null,
  secondaryInsuranceId: null,
});

export const createTwoDatesWithEnabled = () => ({
  date1: null,
  date2: null,
  enabled: false,
});

export const createProviderQualifierModel = () => ({
  label: '',
  value: '',
});

export const createAttorneysModel = () => [
  {
    name: '',
    type: '',
    phoneNumber: '',
    organizationId: null,
  },
];

const getAttorneysValue = attorneys => attorneys || '';

export const createModel = () => ({
  id: null,
  patientId: null,
  name: '',
  careType: '',
  guarantorId: null,
  onsetSymptomsDate: null,
  initialTxDate: null,
  lastSeenDate: null,
  accidentDate: null,
  accidentState: null,
  claimNumber: '',
  referringProviderFirstName: '',
  referringProviderMiddleName: '',
  referringProviderLastName: '',
  referringProviderCredentials: '',
  referringProviderNPI: '',
  referringProviderQualifier: createProviderQualifierModel(),
  referringProviderOtherId: '',
  orderingProviderFirstName: '',
  orderingProviderMiddleName: '',
  orderingProviderLastName: '',
  orderingProviderCredentials: '',
  orderingProviderNPI: '',
  orderingProviderQualifier: createProviderQualifierModel(),
  orderingProviderOtherId: '',
  attorneys: createAttorneysModel(),
  active: true,
  isDefault: false,
  note: '',
  spinalManipulationInfoCode: '',
  spinalManipulationInfoDescription: '',
  spinalManipulationInfoDescription2: '',
  additionalDates: [],
  payerInsurance: createModelPayerInsurance(),
  unableToWork: createTwoDatesWithEnabled(),
  hospitalized: createTwoDatesWithEnabled(),
  patientAuthorization: createModelPatientAuth(),
});

export const EMPTY_CARE_TYPE = {
  data: { code: '' },
  label: '',
};
export const CARE_TYPE_LABEL = Object.freeze({
  WELLNESS_CARE: 'Wellness Care',
  EMPLOYMENT_INJURY: 'Employment Injury',
  AUTO_ACCIDENT: 'Auto Accident',
  OTHER_ACCIDENT: 'Other Accident',
  ACUTE: 'Short Term Episode (Acute)',
  CHRONIC: 'Long Term Episode (Chronic)',
});
export const CARE_TYPE_CODE = Object.freeze({
  WELLNESS_CARE: 'wellcare',
  EMPLOYMENT_INJURY: 'empinj',
  AUTO_ACCIDENT: 'aa',
  OTHER_ACCIDENT: 'oa',
  ACUTE: 'stea',
  CHRONIC: 'ltec',
});
export const CARE_TYPES_ACCIDENT = Object.freeze([
  {
    data: { code: CARE_TYPE_CODE.EMPLOYMENT_INJURY },
    label: CARE_TYPE_LABEL.EMPLOYMENT_INJURY,
  },
  {
    data: { code: CARE_TYPE_CODE.AUTO_ACCIDENT },
    label: CARE_TYPE_LABEL.AUTO_ACCIDENT,
  },
  {
    data: { code: CARE_TYPE_CODE.OTHER_ACCIDENT },
    label: CARE_TYPE_LABEL.OTHER_ACCIDENT,
  },
]);
export const CARE_TYPES = Object.freeze([
  {
    data: { code: CARE_TYPE_CODE.WELLNESS_CARE },
    label: CARE_TYPE_LABEL.WELLNESS_CARE,
  },
  ...CARE_TYPES_ACCIDENT,
  {
    data: { code: CARE_TYPE_CODE.ACUTE },
    label: CARE_TYPE_LABEL.ACUTE,
  },
  {
    data: { code: CARE_TYPE_CODE.CHRONIC },
    label: CARE_TYPE_LABEL.CHRONIC,
  },
]);
export const ADDITIONAL_DATES = Object.freeze({
  acuteManifestationDate: 'acuteManifestationDate',
  lastXRayDate: 'lastXRayDate',
  prescriptionDate: 'prescriptionDate',
  reportStartDate: 'reportStartDate',
  reportEndDate: 'reportEndDate',
  firstVisitDate: 'firstVisitDate',
});

const AddDateFactory = (id, code, description) => ({
  id,
  label: `${code} - ${description}`,
});

export const ITEMS_ADDITIONAL_DATES = Object.freeze([
  AddDateFactory(
    'acuteManifestationDate',
    453,
    'Acute Manifestation of a Chronic Condition',
  ),
  AddDateFactory('lastXRayDate', 455, 'Last X-ray'),
  AddDateFactory('prescriptionDate', 471, 'Prescription'),
  AddDateFactory('reportStartDate', 90, 'Report Start (Assumed Care Date)'),
  AddDateFactory('reportEndDate', 91, 'Report End (Relinquished Care Date)'),
  AddDateFactory('firstVisitDate', 444, 'First Visit or Consultation'),
]);

const mapToAdditionalDate = (key, value) => {
  const addDate = ITEMS_ADDITIONAL_DATES.find(x => x.id === key);
  return {
    type: {
      id: key,
      label: addDate ? addDate.label : '',
    },
    date: parseDate(value).toISOString(),
  };
};

const getIsDefaultValue = defaultValue => defaultValue || false;

// eslint-disable-next-line complexity
export const mapToModel = model => {
  const patientCase = {
    id: model.id,
    patientId: model.patientId,
    name: model.name,
    careType: model.careType,
    guarantorId: model.guarantorId || '',
    guarantor: model.guarantor,
    onsetSymptomsDate: model.onsetSymptomsDate,
    initialTxDate: model.initialTxDate,
    lastSeenDate: model.lastSeenDate,
    accidentDate: model.accidentDate,
    accidentState: model.accidentState,
    claimNumber: model.claimNumber,
    referringProviderFirstName: model.referringProviderFirstName || '',
    referringProviderMiddleName: model.referringProviderMiddleName || '',
    referringProviderLastName: model.referringProviderLastName || '',
    referringProviderCredentials: model.referringProviderCredentials || '',
    referringProviderNPI: model.referringProviderNPI || '',
    referringProviderQualifier: model.referringProviderQualifier || null,
    referringProviderOtherId: model.referringProviderOtherId || '',
    orderingProviderFirstName: model.orderingProviderFirstName || '',
    orderingProviderMiddleName: model.orderingProviderMiddleName || '',
    orderingProviderLastName: model.orderingProviderLastName || '',
    orderingProviderCredentials: model.orderingProviderCredentials || '',
    orderingProviderNPI: model.orderingProviderNPI || '',
    orderingProviderQualifier: model.orderingProviderQualifier || null,
    orderingProviderOtherId: model.orderingProviderOtherId || '',
    attorneys: getAttorneysValue(model.attorneys),
    active: model.active,
    isDefault: getIsDefaultValue(model.isDefault),
    note: model.note,
    spinalManipulationInfoCode: model.spinalManipulationInfoCode || '',
    spinalManipulationInfoDescription:
      model.spinalManipulationInfoDescription || '',
    spinalManipulationInfoDescription2:
      model.spinalManipulationInfoDescription2 || '',
    additionalDates: Object.values(ADDITIONAL_DATES).reduce((memo, keyId) => {
      if (model[keyId]) {
        memo.push(mapToAdditionalDate(keyId, model[keyId]));
      }

      return memo;
    }, []),
    payerInsurance: {
      primaryPayerId: model.primaryPayerId,
      primaryInsuranceId: model.primaryInsuranceId || 'Self',
      secondaryPayerId: model.secondaryPayerId,
      secondaryInsuranceId: model.secondaryInsuranceId,
    },
    patientPackageId: model.patientPackageId || '',
    billType: model.billType || BILL_TYPES.SELF,
    unableToWork: {
      date1: model.disabilityBeginDate,
      date2: model.disabilityEndDate,
      enabled: !!model.disabilityBeginDate || !!model.disabilityEndDate,
    },
    hospitalized: {
      date1: model.admissionDate,
      date2: model.dischargeDate,
      enabled: !!model.admissionDate || !!model.dischargeDate,
    },
    primaryPayer: model.primaryPayer,
    ...(model.patientAuthorization
      ? {
          patientAuthorization: rawPatientAuthorizationToModel(
            model.patientAuthorization,
          ),
        }
      : {}),
    patientAuthorizations: rawPatientAuthorizationsToModel(
      model.patientAuthorizations,
    ),
  };
  return patientCase;
};

const getAdditionalDate = (keyId, patientCase) => {
  const item = patientCase.additionalDates.find(x => x.type.id === keyId);
  return item && item.date
    ? parseDate(item.date).startOf('day').toISOString()
    : null;
};

const getQualifierValue = qualifier => (qualifier ? qualifier.value : null);

export const mapToRaw = patientCase => {
  const model = {
    id: patientCase.id,
    patientId: patientCase.patientId,
    name: patientCase.name,
    careType: patientCase.careType,
    guarantorId: patientCase.guarantorId || null,
    onsetSymptomsDate: patientCase.onsetSymptomsDate,
    initialTxDate: patientCase.initialTxDate,
    lastSeenDate: patientCase.lastSeenDate,
    accidentDate: patientCase.accidentDate,
    accidentState: patientCase.accidentState,
    claimNumber: patientCase.claimNumber,
    referringProviderFirstName: patientCase.referringProviderFirstName,
    referringProviderMiddleName: patientCase.referringProviderMiddleName,
    referringProviderLastName: patientCase.referringProviderLastName,
    referringProviderCredentials: patientCase.referringProviderCredentials,
    referringProviderNPI: patientCase.referringProviderNPI,
    referringProviderQualifier: getQualifierValue(
      patientCase.referringProviderQualifier,
    ),
    referringProviderOtherId: patientCase.referringProviderOtherId,
    orderingProviderFirstName: patientCase.orderingProviderFirstName,
    orderingProviderMiddleName: patientCase.orderingProviderMiddleName,
    orderingProviderLastName: patientCase.orderingProviderLastName,
    orderingProviderCredentials: patientCase.orderingProviderCredentials,
    orderingProviderNPI: patientCase.orderingProviderNPI,
    orderingProviderQualifier: getQualifierValue(
      patientCase.orderingProviderQualifier,
    ),
    orderingProviderOtherId: patientCase.orderingProviderOtherId,
    attorneys: getAttorneysValue(patientCase.attorneys),
    active: patientCase.active,
    isDefault: getIsDefaultValue(patientCase.isDefault),
    note: patientCase.note || '',
    acuteManifestationDate: getAdditionalDate(
      ADDITIONAL_DATES.acuteManifestationDate,
      patientCase,
    ),
    lastXRayDate: getAdditionalDate(ADDITIONAL_DATES.lastXRayDate, patientCase),
    prescriptionDate: getAdditionalDate(
      ADDITIONAL_DATES.prescriptionDate,
      patientCase,
    ),
    reportStartDate: getAdditionalDate(
      ADDITIONAL_DATES.reportStartDate,
      patientCase,
    ),
    reportEndDate: getAdditionalDate(
      ADDITIONAL_DATES.reportEndDate,
      patientCase,
    ),
    firstVisitDate: getAdditionalDate(
      ADDITIONAL_DATES.firstVisitDate,
      patientCase,
    ),
    primaryPayerId: patientCase.payerInsurance.primaryPayerId,
    primaryInsuranceId:
      patientCase.payerInsurance.primaryInsuranceId &&
      patientCase.payerInsurance.primaryInsuranceId !== 'Self'
        ? patientCase.payerInsurance.primaryInsuranceId
        : null,
    secondaryPayerId: patientCase.payerInsurance.secondaryPayerId,
    secondaryInsuranceId: patientCase.payerInsurance.secondaryInsuranceId,
    patientPackageId: patientCase.patientPackageId || null,
    disabilityBeginDate: patientCase.unableToWork.enabled
      ? patientCase.unableToWork.date1
      : null,
    disabilityEndDate: patientCase.unableToWork.enabled
      ? patientCase.unableToWork.date2
      : null,
    admissionDate: patientCase.hospitalized.enabled
      ? patientCase.hospitalized.date1
      : null,
    dischargeDate: patientCase.hospitalized.enabled
      ? patientCase.hospitalized.date2
      : null,
    spinalManipulationInfoCode: patientCase.spinalManipulationInfoCode,
    spinalManipulationInfoDescription:
      patientCase.spinalManipulationInfoDescription
        ? patientCase.spinalManipulationInfoDescription.trim()
        : '',
    spinalManipulationInfoDescription2:
      patientCase.spinalManipulationInfoDescription2
        ? patientCase.spinalManipulationInfoDescription2.trim()
        : '',
  };

  return model;
};

export const mapToPayerPlan = model => ({
  id: model.id,
  label: `${model.alias ? `(${model.alias})` : ''} ${model.payerName}`,
  active: model.status === 'Active',
});

export const mapToPolicyHolder = model => ({
  ...model,
  label: `${model.lastName}, ${model.firstName} ${
    model.middleName ? ` ${model.middleName[0].toUpperCase()}.` : ''
  }`,
});

export const mapServerModelToInsurance = model => {
  const insurance = {
    id: model.id,
    defaultLevel: model.defaultLevel,
    active: model.active,
    payerPlan: mapToPayerPlan(model.payerPlan),
    planInfo: {
      planName: model.planName,
    },
    label: model.planName,
  };
  return insurance;
};

export const mapServerModelsToInsurances = models =>
  models.map(model => mapServerModelToInsurance(model));

export const mapToPatientCaseName = (name, onsetSymptomsDate = null) =>
  `${name} - ${
    onsetSymptomsDate
      ? parseDate(onsetSymptomsDate).format('MM/DD/YYYY')
      : 'Gradual'
  }`;
