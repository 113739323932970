import '../../../../neb-lit-components/src/components/neb-icd-code-table';

import { LitElement, html } from 'lit';

import { fetchMany } from '../../../../../src/api-clients/clinical-codes';
import Debouncer from '../../../../neb-utils/debouncer';

export const BUTTON_TEXT_ADD_DIAGNOSIS = 'Add Diagnosis';
export const BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS = 'Diagnosis Added';
export const ELEMENTS = {
  icdCodeTable: { id: 'add-icd10-code-table' },
};
export const DEBOUNCE_DELAY = 300;

class NebChartingDiagnosisICD10Controller extends LitElement {
  static get properties() {
    return {
      savedCodes: Array,
      searchText: String,
      buttonText: String,
      buttonToggledText: String,
      currentPageIndex: Number,
      pageCount: Number,
      visible: Boolean,
      icd10Year: Number,

      __searchResults: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.savedCodes = [];
    this.searchText = '';
    this.buttonText = BUTTON_TEXT_ADD_DIAGNOSIS;
    this.buttonToggledText = BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS;
    this.currentPageIndex = 0;
    this.pageCount = 0;
    this.visible = false;
    this.icd10Year = null;

    this.__searchResults = null;
    this.__offset = 0;
    this.__limit = 10;

    this.onAddDiagnosis = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addDiagnosis: icd10Info => this.onAddDiagnosis(icd10Info),
      pageChanged: async page => {
        this.__offset = page * this.__limit;

        const { data, count } = await fetchMany({
          searchText: this.searchText,
          icd10year: this.icd10Year,
          offset: this.__offset,
          limit: this.__limit,
        });

        this.__searchResults = data;
        this.currentPageIndex = page;
        this.pageCount = Math.ceil(count / this.__limit);
      },
    };
  }

  __initServices() {
    this.__debouncePageChanged = new Debouncer(
      () => this.__handlers.pageChanged(0),
      DEBOUNCE_DELAY,
    );
  }

  update(changedProps) {
    if (!this.searchText) {
      this.__searchResults = null;
      this.pageCount = 0;
    } else if (changedProps.has('searchText')) {
      this.__debouncePageChanged.debounce();
    }

    super.update(changedProps);
  }

  reset() {
    this.currentPageIndex = 0;
    this.pageCount = 0;
    this.savedCodes = [];
    this.__searchResults = null;
  }

  __renderTable() {
    return html`
      <neb-icd-code-table
        id="${ELEMENTS.icdCodeTable.id}"
        .savedCodes="${this.savedCodes}"
        .searchText="${this.searchText}"
        .searchResults="${this.__searchResults}"
        .onButtonClick="${this.__handlers.addDiagnosis}"
        .buttonText="${this.buttonText}"
        .buttonToggledText="${this.buttonToggledText}"
        .onPageChanged="${this.__handlers.pageChanged}"
        .currentPageIndex="${this.currentPageIndex}"
        .pageCount="${this.pageCount}"
      >
      </neb-icd-code-table>
    `;
  }

  render() {
    return html` ${this.visible ? this.__renderTable() : ''} `;
  }
}

customElements.define(
  'neb-charting-diagnosis-icd10-controller',
  NebChartingDiagnosisICD10Controller,
);
