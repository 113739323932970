import { html, css } from 'lit';

import { getAllEncounters } from '../../../../../packages/neb-api-client/src/encounters-api-client';
import NebOverlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { formatEncounterCards } from '../../../../../packages/neb-utils/neb-charting-util';
import { CSS_SPACING, layoutStyles } from '../../../../styles';

import '../../../filters/neb-filters-associate-encounters';
import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-card-list';

export const ELEMENTS = {
  header: { id: 'header' },
  description: { id: 'description' },
  encounterCardList: { id: 'encounter-card-list' },
  encounterCardListContainer: { id: 'list-container' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  actionBar: { id: 'action-bar' },
  filter: { id: 'filter' },
};

class NebOverlayAssociateEncounters extends NebOverlay {
  static get properties() {
    return {
      model: Object,
      __encounters: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .grid {
          padding: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
          height: 100%;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          position: relative;
        }
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      patientId: '',
      apptTypes: [],
      providers: [],
      title: '',
      description: '',
    };

    this.__encounters = [];
    this.__selectedEncounters = [];
  }

  __updateSelectedEncounters(updatedEncounters, checked) {
    if (checked) {
      this.__selectedEncounters.push(...updatedEncounters);
      this.__selectedEncounters = Array.from(
        new Set(this.__selectedEncounters.map(a => a.id)),
      ).map(id => this.__selectedEncounters.find(a => a.id === id));
    } else {
      this.__selectedEncounters = this.__selectedEncounters.filter(
        encounter => !updatedEncounters.some(({ id }) => id === encounter.id),
      );
    }
  }

  __initHandlers() {
    this.__handlers = {
      filterApply: async queryParams => {
        await this.__setEncounterCards(queryParams, 2);
      },
      checkItem: (encounterId, checked) => {
        this.__encounters = [
          ...this.__encounters.map(encounter =>
            encounter.id === encounterId
              ? { ...encounter, checked: !checked }
              : encounter,
          ),
        ];

        this.__updateSelectedEncounters(
          [{ ...this.__encounters.find(({ id }) => id === encounterId) }],
          !checked,
        );
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.__handlers.selectAll,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.__handlers.deselectAll,
        },
      ],
      selectAll: () => {
        this.__encounters = [
          ...this.__encounters.map(encounter => ({
            ...encounter,
            checked: true,
          })),
        ];

        this.__updateSelectedEncounters([...this.__encounters], true);
      },
      deselectAll: () => {
        this.__encounters = [
          ...this.__encounters.map(encounter => ({
            ...encounter,
            checked: false,
          })),
        ];

        this.__updateSelectedEncounters([...this.__encounters], false);
      },
      save: () => {
        this.onClose(this.__selectedEncounters);
      },
      cancel: () => {
        this.onClose();
      },
    };
  }

  async firstUpdated() {
    await this.__setEncounterCards();

    super.firstUpdated();
  }

  async __setEncounterCards(queryParams = {}, version = 1) {
    if (this.model) {
      const { patientId, apptTypes, providers } = this.model;
      const { data: encounters } = await getAllEncounters({
        patientId,
        queryParams,
        version,
      });

      this.__encounters = formatEncounterCards(
        encounters,
        providers,
        apptTypes,
      );

      this.__encounters = [
        ...this.__encounters.map(encounter => ({
          ...encounter,
          checked: this.__selectedEncounters.some(
            ({ id }) => id === encounter.id,
          ),
        })),
      ];
    }
  }

  __renderActionBar() {
    return this.__selectedEncounters.length > 0
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            confirmLabel="Save"
            cancelLabel="Cancel"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  __renderActionMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.bulkActionMenu.id}"
        align="left"
        class="action-menu"
        .forceDownMenu="${true}"
        .onClick="${this.__handlers.getBulkActions}"
      >
      </neb-button-actions>
    `;
  }

  renderContent() {
    return html`
      <div class="grid">
        <div>
          <neb-popup-header
            id="${ELEMENTS.header.id}"
            class="header"
            .title="${this.model.title}"
            .onCancel="${this.__handlers.cancel}"
            showCancelButton
          ></neb-popup-header>

          <neb-text id="${ELEMENTS.description.id}" class="description">
            ${this.model.description}
          </neb-text>
        </div>

        <neb-filters-associate-encounters
          id="${ELEMENTS.filter.id}"
          .patientId="${this.model.patientId}"
          .apptTypes="${this.model.apptTypes}"
          .providers="${this.model.providers}"
          .onApply="${this.__handlers.filterApply}"
        ></neb-filters-associate-encounters>

        ${this.__renderActionMenu()}

        <div class="container" id="${ELEMENTS.encounterCardListContainer.id}">
          <neb-encounter-card-list
            id="${ELEMENTS.encounterCardList.id}"
            class="encounter-card-list"
            .message="${'There are no encounters for this patient.'}"
            .models="${this.__encounters}"
            .layout="${this.layout}"
            .onItemCheck="${this.__handlers.checkItem}"
            ?showCheckbox="${true}"
          ></neb-encounter-card-list>
        </div>
      </div>
      ${this.__renderActionBar()}
    `;
  }
}

window.customElements.define(
  'neb-overlay-associate-encounters',
  NebOverlayAssociateEncounters,
);
