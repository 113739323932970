import { ACTION_NAVIGATE } from '../../../packages/neb-route/neb-route-state';

const INITIAL_STATE = {
  dirty: false,
};

const ACTION_TOGGLE_CHARTING_DIRTY = 'TOGGLE_CHARTING_DIRTY';
const ACTION_RESET_CHARTING = 'RESET_CHARTING;';

export const resetCharting = () => ({ type: ACTION_RESET_CHARTING });

export const toggleChartingDirty = isDirty => ({
  type: ACTION_TOGGLE_CHARTING_DIRTY,
  isDirty,
});

export const chartingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TOGGLE_CHARTING_DIRTY:
      return { ...state, dirty: action.isDirty };

    case ACTION_NAVIGATE:
    case ACTION_RESET_CHARTING:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
