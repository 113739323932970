import { format, differenceInYears } from 'date-fns';

import { PROVIDER_TYPE } from './enums';
import { ITEM_EMPTY } from './selectors';

const REGEX_NUMBERS_NEEDING_COMMAS = /(\d)(?=(\d{3})+(?!\d))/g;
const PARANTHESES = / *\([^)]*\) */g;
const SPECIAL_CHARACTERS = /[-()/]/g;
const BLANK_PHONES = [
  {
    number: '',
    type: '',
  },
];
export const getNow = () => new Date();

export function normalizeForSearch(str) {
  return str.replace(SPECIAL_CHARACTERS, '').toLowerCase();
}

export function splitIntoTerms(str) {
  return normalizeForSearch(str.trim()).split(' ');
}

export function getTimespan(ms) {
  const seconds = Math.floor(ms / 1000);
  return {
    seconds: Math.floor(seconds % 60),
    minutes: Math.floor((seconds / 60) % 60),
    hours: Math.floor(seconds / 3600),
  };
}

export function capitalize(str, forceSmallCapsTail = false) {
  return str
    ? `${str.charAt(0).toUpperCase()}${
        forceSmallCapsTail ? str.slice(1).toLowerCase() : str.slice(1)
      }`
    : '';
}

export const capitalizeEachWord = str =>
  str
    .split(' ')
    .map(word => capitalize(word, true))
    .join(' ');

export function toNumeric(str, allowDecimal = false) {
  return str.replace(allowDecimal ? /\$|,/g : /\D/g, '');
}

export function toCurrency(v) {
  return `$${Number(v)
    .toFixed(2)
    .replace(REGEX_NUMBERS_NEEDING_COMMAS, '$1,')}`;
}

export function centsToCurrency(v, allowEmpty = false) {
  if (allowEmpty && v === '') return '';

  return `$${Number(v / 100)
    .toFixed(2)
    .replace(REGEX_NUMBERS_NEEDING_COMMAS, '$1,')}`;
}

export function formatDollarAmount(amount) {
  return amount < 0 ? `(${centsToCurrency(-amount)})` : centsToCurrency(amount);
}

export function formatDollarAmountWithNegative(amount) {
  return amount < 0 ? `-${centsToCurrency(-amount)}` : centsToCurrency(amount);
}

export function currencyToCents(v) {
  return v ? Math.round(Number(v.replace(/[^0-9.]/g, '')) * 100) : 0;
}

export function currencyToCentsWithNegative(v) {
  if (!v) return 0;

  const isNegative = v.includes('-');
  const rawValue = Math.round(Number(v.replace(/[^0-9.]/g, '')) * 100);
  return isNegative && rawValue > 0 ? rawValue * -1 : rawValue;
}

function centsToCurrencyAdjustments(v, allowEmpty = false) {
  if (allowEmpty && v === '') return '';
  return `$${Number(v / 100)
    .toFixed(2)
    .replace(REGEX_NUMBERS_NEEDING_COMMAS, '$1,')}`;
}

export function centsToCurrencyWithNegative(v, allowEmpty = false) {
  if (allowEmpty && v === '') return '';
  return v < 0
    ? `-${centsToCurrencyAdjustments(-v)}`
    : centsToCurrencyAdjustments(v);
}

export function decimalToCents(v) {
  if (v === null) return null;
  return Math.round(Number(v) * 100);
}

export function toCamelCase(str) {
  return str.replace(/-([a-zA-Z])/g, g => g[1].toUpperCase());
}

const splitWordsRegex =
  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

export const toKebabCase = str =>
  str.match(splitWordsRegex).join('-').toLowerCase();

export function objToInitials(obj) {
  const firstInitial = obj.first ? obj.first.charAt(0) : '';
  const lastInitial = obj.last ? obj.last.charAt(0) : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
}

/**
 *
 * @param {{
 *  suffix: string,
 *  first: string,
 *  middle: string,
 *  last: string,
 *  preferred: string,
 * }} obj
 * @param {{
 *  middleInitial: boolean,
 *  reverse: boolean,
 *  preferred: boolean,
 * }} opts
 * @returns string
 */
export function objToName(obj, opts = {}) {
  let result = '';

  if (!obj.first && !obj.last) return result;

  const formattedLast = obj.suffix ? `${obj.last} ${obj.suffix}` : obj.last;

  if (obj.middle) {
    const formattedMiddle = opts.middleInitial
      ? ` ${obj.middle.charAt(0).toUpperCase()}.`
      : ` ${obj.middle}`;
    result = opts.reverse
      ? `${formattedLast}, ${obj.first}${formattedMiddle}`
      : `${obj.first}${formattedMiddle} ${formattedLast}`;
  } else {
    result = opts.reverse
      ? `${formattedLast}, ${obj.first}`
      : `${obj.first} ${formattedLast}`;
  }

  return opts.preferred && obj.preferred
    ? `${result} (${obj.preferred})`
    : result;
}

export function formatTime(date) {
  return format(date, 'h:mm\u00A0A');
}

export function formatDuration(ms) {
  const span = getTimespan(ms);
  return Object.entries(span)
    .filter(([_, v]) => v)
    .map(([k, v]) => [k, v, v === 1 ? k.substring(0, k.length - 1) : k])
    .map(([k, v, u]) => [k, `${v} ${capitalize(u)}`])
    .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {});
}

export function formatAge(date, condensedInfo = false) {
  const dateStr = `${format(date, 'MM/DD/YYYY')}`;
  const age = differenceInYears(getNow(), date);
  const ageStr = condensedInfo ? `(${age} yrs old)` : `(${age} years old)`;
  return `${dateStr} ${ageStr}`;
}

export function formatPhoneNumber(str) {
  return /^\d+$/.test(str)
    ? `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(6, 10)}`
    : str || '';
}

export function formatPhoneNumberToRaw(str) {
  const regex = /[()\s-]/g;
  return str ? str.replace(regex, '') : str;
}

export function formatAddress(address) {
  if (address) {
    const { address1, city, state, zipcode } = address;
    return [address1, city, state, zipcode]
      .filter(item => item !== '')
      .join(', ');
  }
  return '';
}

export function buildAddress(address) {
  return Object.entries(address).reduce(
    (memo, [field, value]) => ({
      ...memo,
      [field]: {
        value,
        valid: true,
      },
    }),
    {},
  );
}

export function formatPhones(phones = []) {
  return phones.length === 0
    ? BLANK_PHONES
    : phones.map(phone => ({
        type: phone.type,
        number: formatPhoneNumber(phone.number),
      }));
}

export function formatAddressFormObject(address = {}) {
  return {
    address1: {
      value: address.address1,
    },
    address2: {
      value: address.address2,
    },
    city: {
      value: address.city,
    },
    state: {
      value: address.state,
    },
    zipcode: {
      value: address.zipcode,
    },
  };
}

export function addressToRaw(address) {
  const rawAddress = {};
  Object.keys(address).forEach(key => {
    rawAddress[key] = address[key].value;
  });

  return rawAddress;
}

export function formatPhonesEmails(phones, emails) {
  const newEmails = emails.length === 0 ? [''] : emails;
  return {
    emails: newEmails,
    phones: formatPhones(phones),
  };
}

export function formatPercent(str) {
  return str && str.length > 0 ? `${parseInt(toNumeric(str), 10)}` : str;
}

export const sanitizePhones = phones =>
  phones.map(({ type, number }) => ({
    type,
    number: formatPhoneNumberToRaw(number),
  }));

export const removePreferredName = name =>
  name ? name.replace(PARANTHESES, '') : '';

export const DEFAULT_NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

export function mapAppointment(appointment) {
  return {
    id: appointment.id || '',
    patientId: appointment.patientId || '',
  };
}

export const HIDE_PREFERRED_OPTS = {
  reverse: true,
  preferred: false,
  middleInitial: true,
};

export function formatDisplayProviders(
  providers,
  opts = { allowEmpty: true, includeSpecialists: false },
) {
  const types = opts.includeSpecialists
    ? [PROVIDER_TYPE.PROVIDER, PROVIDER_TYPE.SPECIALIST]
    : [PROVIDER_TYPE.PROVIDER];

  let formattedProviders = providers
    .filter(p => types.includes(p.type))
    .sort((a, b) => a.lastName.localeCompare(b.lastName))
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .sort((a, b) => b.active - a.active)
    .map(data => ({
      data,
      label: data.active
        ? objToName(data.name, DEFAULT_NAME_OPTS)
        : `${objToName(data.name, DEFAULT_NAME_OPTS)} (Inactive)`,
    }));

  if (opts.allowEmpty) formattedProviders = [ITEM_EMPTY, ...formattedProviders];

  return formattedProviders;
}
