import '../neb-text';

import { css, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { MODE } from '../../../../neb-utils/table';

import NebTable from './neb-table';

import '../../../../../src/components/misc/neb-icon';

export const ELEMENTS = {
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
};

class NebTablePackagesSubscriptions extends NebTable {
  static get properties() {
    return {
      patientId: {
        type: String,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-header {
          padding-bottom: 10px;
        }

        .cell {
          overflow: hidden;
        }

        .link:focus,
        .link:hover {
          opacity: 0.65;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .link-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          inline-size: min-content;
          overflow: hidden;
          max-width: calc(100% - ${CSS_SPACING});
        }

        .patient-text {
          margin-right: 6px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .patient-link {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          max-width: calc(100% - ${CSS_SPACING});
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.mode = MODE.DETAIL;
    this.writable = false;
    this.patientId = '';

    this.onSelectSharedBy = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      onClickSharedBy: ({ name }) => {
        const rowIndex = name.split('.')[1];

        const { patientId } = this.model[rowIndex];

        return this.onSelectSharedBy({ patientId });
      },
    };
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'sharedByName':
        return this.patientId !== this.model[rowIndex].patientId &&
          value &&
          value.length
          ? html`
              <div class="link-button">
                <neb-text
                  id="patient-link-${rowIndex}"
                  class="patient-text patient-link"
                  bold
                  link
                  .name="sharedBy.${rowIndex}"
                  .onClick="${this.handlers.onClickSharedBy}"
                  >${value}</neb-text
                ><neb-icon class="icon" icon="neb:open"></neb-icon>
              </div>
            `
          : value;
      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-packages-subscriptions',
  NebTablePackagesSubscriptions,
);
