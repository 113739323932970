import '../../tables/neb-table-packages-subscriptions-history';
import '../../neb-popup-header';

import { html, css } from 'lit';

import { getPatientPackageShareHistory } from '../../../../../../src/api-clients/patient-package';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../../../neb-utils/formatters';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  table: {
    id: 'table',
  },
  form: {
    id: 'form',
  },
};

const uniq = arr => [...new Set(arr)];

class NebOverlayPatientPackageHistory extends Overlay {
  static get properties() {
    return {
      history: Array,
    };
  }

  initState() {
    super.initState();
    this.history = [];
  }

  __buildOwnerHistory(
    { effectiveDate: start, expirationDate: end, patientId },
    patientNameMap,
    primary,
  ) {
    const patientName = objToName(patientNameMap[patientId], DEFAULT_NAME_OPTS);

    return {
      start,
      end,
      patientName:
        patientId === primary
          ? `${patientName} - Primary, Owner`
          : `${patientName} - Owner`,
      owner: true,
    };
  }

  __sortByStartDate(keyA, keyB) {
    if (keyA.start === keyB.start) return 0;

    if (keyA.start === null && keyB.start !== null) return -1;
    if (keyA.start !== null && keyB.start === null) return 1;

    return keyA.start > keyB.start ? -1 : 1;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      const { activeRelationshipGroup, patientPackage } = this.model;

      const rawHistory = await getPatientPackageShareHistory(patientPackage.id);

      const patientIds = uniq([
        patientPackage.patientId,
        ...rawHistory.map(h => h.relatedPatientId),
      ]);

      const patients = await patientApiClient.fetchSome(patientIds);

      const patientNameMap = patients.reduce((memo, patient) => {
        memo[patient.id] = patient.name;
        return memo;
      }, {});

      const history = [
        this.__buildOwnerHistory(
          patientPackage,
          patientNameMap,
          activeRelationshipGroup.primary,
        ),
        ...rawHistory.map(row => {
          const { relatedPatientId } = row;

          const patientName = objToName(
            patientNameMap[relatedPatientId],
            DEFAULT_NAME_OPTS,
          );

          return {
            ...row,
            owner: false,
            patientName:
              relatedPatientId === activeRelationshipGroup.primary
                ? `${patientName} - Primary`
                : patientName,
          };
        }),
      ].sort((keyA, keyB) => {
        if (keyA.owner) return -1;
        if (keyB.owner) return 1;

        if (keyA.patientName.toLowerCase() !== keyB.patientName.toLowerCase()) {
          return keyA.patientName.localeCompare(keyB.patientName);
        }

        return this.__sortByStartDate(keyA, keyB);
      });

      this.history = history;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .table {
          margin: ${CSS_SPACING};
          overflow-y: auto;
        }

        .content {
          width: 860px;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Shared Patient Package/Subscription History"
        showBackButton
        showCancelButton
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>

      <neb-table-packages-subscriptions-history
        id="${ELEMENTS.table.id}"
        class="table"
        .model="${this.history}"
      ></neb-table-packages-subscriptions-history>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-package-history',
  NebOverlayPatientPackageHistory,
);
