import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { css, html, LitElement } from 'lit';

import { fetchManyRooms } from '../../../packages/neb-api-client/src/rooms-api-client';
import { LocationsService } from '../../../packages/neb-redux/services/locations';
import { CSS_SPACING } from '../../styles';

export const ELEMENTS = {
  locationsDropdown: { id: 'locations-dropdown' },
  roomsDropdown: { id: 'rooms-dropdown' },
};

function getSelectedLocationKey(tenantId) {
  return `room-scheduling-selected-location:${tenantId}`;
}

function getSelectedRoomsKey(tenantId) {
  return `room-scheduling-selected-rooms:${tenantId}`;
}

function getSelectedLocationFromLocalStorage(key, prop) {
  const storedProp = JSON.parse(localStorage.getItem(key));

  if (storedProp && prop.length) {
    return prop.find(p => p.data.id === storedProp.data.id);
  }

  return prop && prop[0];
}

function getSelectedRoomsFromLocalStorage(key, prop) {
  const storedProp = JSON.parse(localStorage.getItem(key));

  if (storedProp && prop.length) {
    return prop.filter(p => storedProp.find(v => v.data.id === p.data.id));
  }

  return prop;
}

class NebRoomCalendarFiltering extends LitElement {
  static get properties() {
    return {
      __locations: Array,
      __allRooms: Array,
      __locationRooms: Array,
      __selectedRooms: Array,
      __selectedLocation: String,
      tenantId: String,
    };
  }

  static get styles() {
    return css`
      .container {
        display: flex;
      }

      .dropdowns {
        margin-right: ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__locations = [];
    this.__allRooms = [];
    this.__locationRooms = [];
    this.__selectedRooms = [];
    this.__selectedLocation = '';

    this.onSelectLocation = () => {};

    this.onSelectRooms = () => {};

    this.tenantId = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectLocation: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedLocation = e.value;

          this.__setRoomsDropdown(false);

          localStorage.setItem(
            getSelectedLocationKey(this.tenantId),
            JSON.stringify(e.value),
          );

          localStorage.setItem(
            getSelectedRoomsKey(this.tenantId),
            JSON.stringify(this.__selectedRooms),
          );

          this.onSelectLocation(e.value);
        }
      },
      selectRooms: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedRooms = e.value;

          localStorage.setItem(
            getSelectedRoomsKey(this.tenantId),
            JSON.stringify(e.value),
          );

          this.onSelectRooms(e.value);
        }
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__locations = userLocations
        .filter(item => item.active)
        .map(data => ({
          label: data.name,
          data,
        }));

      this.__selectedLocation = getSelectedLocationFromLocalStorage(
        getSelectedLocationKey(this.tenantId),
        this.__locations,
      );

      this.onSelectLocation(this.__selectedLocation);

      this.__setRoomsDropdown(true);
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__allRooms = await fetchManyRooms();

    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __setRoomsDropdown(useLocalStorage = false) {
    this.__locationRooms = this.__allRooms
      .filter(
        room =>
          room.active &&
          room.scheduleAvailable &&
          room.locationId === this.__selectedLocation.data.id,
      )
      .map(data => ({
        label: data.name,
        data,
      }));

    this.__selectedRooms = useLocalStorage
      ? getSelectedRoomsFromLocalStorage(
          getSelectedRoomsKey(this.tenantId),
          this.__locationRooms,
        )
      : this.__locationRooms;

    this.onSelectRooms(this.__selectedRooms);
  }

  render() {
    return html`
      <div class="container">
        <neb-select
          id="${ELEMENTS.locationsDropdown.id}"
          class="dropdowns"
          name="locations"
          .value="${this.__selectedLocation}"
          .items="${this.__locations}"
          .onChange="${this.__handlers.selectLocation}"
          wrapText
        ></neb-select>

        <neb-select
          id="${ELEMENTS.roomsDropdown.id}"
          class="dropdowns"
          name="rooms"
          allLabel="Rooms"
          placeholder="No Rooms Selected"
          .value="${this.__selectedRooms}"
          .items="${this.__locationRooms}"
          .onChange="${this.__handlers.selectRooms}"
          multiSelect
          wrapText
        ></neb-select>
      </div>
    `;
  }
}

customElements.define('neb-room-calendar-filtering', NebRoomCalendarFiltering);
