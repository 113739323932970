import '../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import {
  getPatientRelationships,
  getPatientRelationshipsActiveGroup,
} from '../../../packages/neb-api-client/src/patient-relationship-api-client';
import { getRelationshipSharedPaymentConflicts } from '../../../packages/neb-api-client/src/payments/scheduled-payments-api-client';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
} from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import {
  capitalize,
  formatAge,
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../packages/neb-utils/formatters';
import { PATIENT_RELATIONSHIP_TYPES } from '../../../packages/neb-utils/patientRelationship';
import { map } from '../../../packages/neb-utils/utils';
import { required } from '../../../packages/neb-utils/validators';
import { getPatientPackages } from '../../api-clients/patient-package';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  labelAge: { id: 'label-age' },
  labelMRN: { id: 'label-mrn' },
  labelName: { id: 'label-name' },
  labelSex: { id: 'label-sex' },
  isPrimary: { id: 'isPrimary' },
  note: { id: 'note' },
  active: {
    id: 'active-switch',
  },
  type: { id: 'type' },
  textSearchPatient: { id: 'text-search-patient' },
};

export const DUPLICATE_PATIENT_RELATIONSHIP = {
  title: 'Duplicate Relationship',
  message: 'This person has an existing relationship with this patient.',
};

export const PATIENT_2_NOT_PRIMARY = {
  title: 'Cannot Add Relationship',
  message:
    'Additional relationships cannot be added to [Patient_B] since they are not a primary.',
};

export const PATIENTS_BOTH_PRIMARY = {
  title: 'Cannot Add Relationship',
  message:
    'This relationship cannot be added since [Patient_A] and [Patient_B] are both a primary.',
};

export const SHARED_PAYMENT = {
  title: 'Payment Method In Use By Relationship',
  message:
    'A scheduled payment for [Patient_A] is using a payment method that belongs to [Patient_B]. You must change the payment method for the scheduled payment or stop the future scheduled payment before inactivating this relationship.',
};

export const SHARED_CARE_PACKAGE = {
  title: 'Shared Care Package In Use By Relationship',
  message:
    'One of these patients is part of a shared care package. You must remove them from the care package or complete the care package before inactivating this relationship.',
};

export const PATIENT_IS_SECONDARY = {
  title: 'Cannot Activate Relationship',
  message:
    '[Patient_A] has an existing relationship with [Patient_C] who is primary. Additional relationships cannot be added to [Patient_A] since they are not a primary.',
};

export default class NebFormRelationship extends NebForm {
  static get properties() {
    return {
      __patientItems: Array,
      __primaryPatients: Array,
      __patientName: String,
      __allRelationships: Array,
      __patientHasPrimaryRelationships: Boolean,
      __relatedPatientActiveRelationships: Array,
      __patientHasSharedCarePackage: Boolean,
      patientId: String,
    };
  }

  static createModel() {
    return {
      id: '',
      type: 'Other',
      relatedPatient: null,
      note: '',
      active: true,
      isPrimary: true,
    };
  }

  createSelectors() {
    return this.model.id
      ? { children: {} }
      : {
          children: {
            relatedPatient: { clipPristine: true, validators: [required()] },
          },
        };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.__patientItems = [];
    this.__primaryPatients = [];
    this.__allRelationships = [];
    this.__patientHasPrimaryRelationships = false;
    this.__relatedPatientActiveRelationships = [];
    this.__patientHasSharedCarePackage = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      activeChange: async ({ value }) => {
        this.formService.apply('active', value);

        if (!this.model.active) {
          if (value) {
            await this.__setPrimaryPatientsInactiveToActive();
          } else {
            this.__setIsPrimaryDropdownForInactive();
          }
        }
      },
      patientChange: async p => {
        this.formService.apply('relatedPatient', p.value ? p.value.data : null);

        if (!p.value) {
          this.formService.apply('note', '');
          this.formService.apply('isPrimary', true);
          this.__primaryPatients = [];
        } else {
          await this.__setPrimaryPatients();
        }
      },
      primaryChange: ({ value }) => {
        if (value === this.__patientName) {
          this.formService.apply('isPrimary', true);
        } else this.formService.apply('isPrimary', false);
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      requestMorePatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
      save: async () => {
        if (
          this.formService.validate() &&
          (await this.__validateSharedCards(this.state.relatedPatient.id)) &&
          (await this.__validateSharedPackages()) &&
          (await this.__validatePrimarySecondary())
        ) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (__, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          await this.onSave(model);
          this.__saving = false;
        }
      },
    };
  }

  async __scheduledAndSharedPaymentConflicts(patientId1, patientId2) {
    const activeGroup = await getPatientRelationshipsActiveGroup(patientId1);

    const patientToRemoveId =
      patientId1 === activeGroup.primary ? patientId2 : patientId1;

    return getRelationshipSharedPaymentConflicts(
      patientToRemoveId,
      activeGroup,
    );
  }

  async __checkForSharedCard(patientId, relatedPatientId) {
    let patientA;
    let patientB;

    const conflicts = await this.__scheduledAndSharedPaymentConflicts(
      patientId,
      relatedPatientId,
    );

    const foundSharedCard = conflicts.length > 0;

    if (foundSharedCard) {
      const [conflict] = conflicts;

      const { scheduledPaymentPatientId, paymentMethodPatientId } = conflict;

      const [rawPatientA, rawPatientB] = await Promise.all([
        patientApiClient.fetchOne(scheduledPaymentPatientId),
        patientApiClient.fetchOne(paymentMethodPatientId),
      ]);

      patientA = objToName(rawPatientA.name, DEFAULT_NAME_OPTS);
      patientB = objToName(rawPatientB.name, DEFAULT_NAME_OPTS);
    }

    return { result: foundSharedCard, patientA, patientB };
  }

  async __validateSharedCards(relatedPatientId) {
    if (
      !this.model.id &&
      this.__allRelationships
        .map(r => r.relatedPatientId)
        .includes(relatedPatientId)
    ) {
      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        DUPLICATE_PATIENT_RELATIONSHIP,
      );

      return false;
    }

    if (!this.state.active) {
      const sharedCardCheck = await this.__checkForSharedCard(
        this.patientId,
        relatedPatientId,
      );

      if (sharedCardCheck.result) {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          ...SHARED_PAYMENT,
          message: SHARED_PAYMENT.message
            .replace('[Patient_A]', sharedCardCheck.patientA)
            .replace(
              '[Patient_B]',
              this.__removeTrailingPeriod(sharedCardCheck.patientB),
            ),
        });

        return false;
      }
    }

    return true;
  }

  async __validateSharedPackages() {
    if (!this.state.active && this.__patientHasSharedCarePackage) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: SHARED_CARE_PACKAGE.title,
        message: SHARED_CARE_PACKAGE.message,
      });

      return false;
    }

    return true;
  }

  async __validatePrimarySecondary() {
    if (!this.__primaryPatients.length) {
      if (this.__patientHasPrimaryRelationships) {
        const relatedHasPrimary = this.__relatedPatientActiveRelationships.find(
          relationship => relationship.isPrimary === true,
        );

        if (relatedHasPrimary) {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            ...PATIENTS_BOTH_PRIMARY,
            message: PATIENTS_BOTH_PRIMARY.message
              .replace('[Patient_A]', this.__patientName)
              .replace(
                '[Patient_B]',
                objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
              ),
          });
        } else {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            ...PATIENT_2_NOT_PRIMARY,
            message: PATIENT_2_NOT_PRIMARY.message.replace(
              '[Patient_B]',
              objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
            ),
          });
        }
      } else if (this.__activatingRelationship) {
        let result = await this.__hasSecondaryCheck(
          this.__relatedPatientActiveRelationships,
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        );

        if (!result) return false;

        const otherActiveRelationships = this.__allRelationships.filter(
          relationship => relationship.active === true,
        );

        result = await this.__hasSecondaryCheck(
          otherActiveRelationships,
          this.__patientName,
        );

        if (!result) return false;
      } else {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          ...PATIENT_2_NOT_PRIMARY,
          message: PATIENT_2_NOT_PRIMARY.message.replace(
            '[Patient_B]',
            objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
          ),
        });
      }

      return false;
    }

    return true;
  }

  __activatingRelationship() {
    return !this.model.active && this.state.active;
  }

  async __hasSecondaryCheck(relatedPatients, patientA) {
    const patientRelationships = relatedPatients;

    const currentPrimary = patientRelationships.filter(pr => !pr.isPrimary);

    if (currentPrimary.length > 0) {
      const patient = await patientApiClient.fetchOne(
        currentPrimary[0].relatedPatientId,
      );

      const patientC = objToName(patient.name, DEFAULT_NAME_OPTS);

      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        ...PATIENT_IS_SECONDARY,
        message: PATIENT_IS_SECONDARY.message
          .replaceAll('[Patient_A]', patientA)
          .replace('[Patient_C]', patientC),
      });

      return false;
    }
    return true;
  }

  __removeTrailingPeriod(name) {
    return name.substring(name.length - 1, name.length) === '.'
      ? name.substring(0, name.length - 1)
      : name;
  }

  async __setRelatedPatients() {
    const relatedPatientRelationships = await getPatientRelationships(
      this.state.relatedPatient.id,
      { active: true },
    );

    this.__relatedPatientActiveRelationships =
      relatedPatientRelationships.filter(
        r => r.relatedPatientId !== this.patientId,
      );
  }

  async __setPrimaryPatients() {
    await this.__setRelatedPatients();

    if (!this.__relatedPatientActiveRelationships.length) {
      if (this.__patientHasPrimaryRelationships) {
        this.__primaryPatients = [this.__patientName];

        this.formService.apply('isPrimary', true);
      } else {
        this.__primaryPatients = [
          this.__patientName,
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        ];

        this.formService.apply('isPrimary', true);
      }
    } else {
      const foundPrimary = this.__relatedPatientActiveRelationships.find(
        relationship => relationship.isPrimary === true,
      );

      if (this.__patientHasPrimaryRelationships) {
        this.__primaryPatients = [];

        this.formService.apply('isPrimary', false);
      } else if (foundPrimary) {
        this.__primaryPatients = [
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        ];

        this.formService.apply('isPrimary', false);
      } else {
        this.__primaryPatients = [];
      }
    }
  }

  async __setPrimaryPatientsForUpdate() {
    await this.__setRelatedPatients();

    const otherActiveRelationships = this.__allRelationships.filter(
      relationship =>
        relationship.active === true &&
        relationship.relatedPatientId !== this.state.relatedPatient.id,
    );

    if (!otherActiveRelationships.length) {
      if (this.__relatedPatientActiveRelationships.length) {
        this.__primaryPatients = [
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        ];
      } else {
        this.__primaryPatients = [
          this.__patientName,
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        ];
      }
    } else if (this.__patientHasPrimaryRelationships) {
      this.__primaryPatients = [this.__patientName];
    } else {
      this.__primaryPatients = [
        objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
      ];
    }
  }

  __setIsPrimaryDropdownForInactive() {
    if (this.model.isPrimary) {
      this.__primaryPatients = [this.__patientName];
    } else {
      this.__primaryPatients = [
        objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
      ];
    }
  }

  async __setPrimaryPatientsInactiveToActive() {
    await this.__setRelatedPatients();

    const otherActiveRelationships = this.__allRelationships.filter(
      relationship =>
        relationship.active === true &&
        relationship.relatedPatientId !== this.state.relatedPatient.id,
    );

    if (this.__relatedPatientActiveRelationships.length) {
      const foundPrimary = this.__relatedPatientActiveRelationships.find(
        relationship => relationship.isPrimary === true,
      );

      if (foundPrimary && !otherActiveRelationships.length) {
        this.__primaryPatients = [
          objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        ];

        this.formService.apply('isPrimary', false);
      } else {
        this.__primaryPatients = [];
      }
    } else if (!otherActiveRelationships.length) {
      this.__primaryPatients = [
        this.__patientName,
        objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
      ];
    } else if (this.__patientHasPrimaryRelationships) {
      this.__primaryPatients = [this.__patientName];

      this.formService.apply('isPrimary', true);
    } else {
      this.__primaryPatients = [];
    }
  }

  __disablePrimaryPatient() {
    if (this.__primaryPatients.length === 1) return true;

    return false;
  }

  __getSelectedPrimaryPatient() {
    if (!this.__primaryPatients.length) return null;

    if (this.state.isPrimary) return this.__primaryPatients[0];

    return this.__primaryPatients[this.__primaryPatients.length - 1];
  }

  __getPrimaryPatient() {
    if (this.state.isPrimary) {
      return { id: this.patientId, name: this.__patientName };
    }

    return {
      id: this.state.relatedPatientId,
      name: objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
    };
  }

  __getRelatedPatient() {
    if (this.state.isPrimary) {
      if (this.state.relatedPatient) {
        return {
          id: this.state.relatedPatientId,
          name: objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS),
        };
      }
      return null;
    }

    return { id: this.patientId, name: this.__patientName };
  }

  async load() {
    this.__allRelationships = await getPatientRelationships(this.patientId);

    this.__patientHasPrimaryRelationships = false;

    const activeRelationships = this.__allRelationships.filter(
      relationship => relationship.active === true,
    );

    if (activeRelationships.length > 0) {
      const foundPrimary = activeRelationships.find(
        relationship => relationship.isPrimary === true,
      );

      if (foundPrimary) this.__patientHasPrimaryRelationships = true;
    } else this.__patientHasPrimaryRelationships = false;

    const patient = await patientApiClient.fetchOne(this.patientId);
    this.__patientName = objToName(patient.name, DEFAULT_NAME_OPTS);

    if (!this.model.id) {
      this.__patientService = createServerPatientsCollection({
        onChange: ({ pageItems }) => {
          this.__patientItems = pageItems.map(data => ({
            label: objToName(data.name, DEFAULT_NAME_OPTS),
            data,
          }));
        },
        excludePatientId: this.patientId,
      });
    } else if (this.model.active) {
      await this.__setPrimaryPatientsForUpdate();
    } else {
      this.__setIsPrimaryDropdownForInactive();
    }

    if (this.model.id) {
      const relatedPatient = this.__getRelatedPatient();

      const rawPackages = await getPatientPackages(relatedPatient.id, {
        includeShared: true,
        hideInactive: true,
      });

      const sharedCarePackages = rawPackages.filter(
        p =>
          p.patientPackageRelatedPatients.length > 0 &&
          p.patientPackageRelatedPatients.some(pp => pp.active),
      );

      this.__patientHasSharedCarePackage = sharedCarePackages.length > 0;
    } else {
      this.__patientHasSharedCarePackage = false;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .text-line {
          display: block;
        }

        .switch {
          margin: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .content {
          overflow-x: hidden;
        }

        .primary {
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }
      `,
    ];
  }

  __renderBasicInfo() {
    return this.state.relatedPatient
      ? html`
          <neb-text id="${ELEMENTS.labelName.id}" class="text-line" bold>
            ${objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS)}
          </neb-text>
          <neb-text id="${ELEMENTS.labelSex.id}" class="text-line">
            ${capitalize(this.state.relatedPatient.sex)}
          </neb-text>
          <neb-text id="${ELEMENTS.labelAge.id}" class="text-line">
            ${formatAge(this.state.relatedPatient.dateOfBirth)}
          </neb-text>
          <neb-text id="${ELEMENTS.labelMRN.id}" class="text-line">
            MRN: ${this.state.relatedPatient.medicalRecordNumber}
          </neb-text>
        `
      : '';
  }

  __renderNote() {
    return this.state.relatedPatient
      ? html`
          <neb-textarea
            id="${ELEMENTS.note.id}"
            name="note"
            label="${'Note'}"
            .value="${this.state.note}"
            .onChange="${this.handlers.change}"
            maxlength="500"
            showCount
          >
          </neb-textarea>
        `
      : '';
  }

  __renderSwitch() {
    return this.state.relatedPatient
      ? html`
          <neb-switch
            id="${ELEMENTS.active.id}"
            class="switch"
            name="active"
            label="Active"
            .onChange="${this.handlers.activeChange}"
            ?on="${this.state.active}"
          ></neb-switch>
        `
      : '';
  }

  __renderPatientSearch() {
    return !this.model.id
      ? html`
          <neb-select-search
            id="${ELEMENTS.textSearchPatient.id}"
            name="relatedPatient"
            label="Search for an existing patient"
            itemHeight="80"
            helper="Required"
            emptyMessage="No patients found"
            .items="${this.__patientItems}"
            .value="${this.state.relatedPatient
              ? objToName(this.state.relatedPatient.name, DEFAULT_NAME_OPTS)
              : null}"
            .onChange="${this.handlers.patientChange}"
            .onSearch="${this.handlers.searchPatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            .onRequest="${this.handlers.requestMorePatients}"
            .error="${this.errors.relatedPatient}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  __renderPrimaryDropdown() {
    return this.state.relatedPatient
      ? html`
          <neb-select
            id="${ELEMENTS.isPrimary.id}"
            class="primary"
            name="isPrimary"
            label="${'Primary'}"
            helper="Required"
            .items="${this.__primaryPatients}"
            .value="${this.__getSelectedPrimaryPatient()}"
            .onChange="${this.handlers.primaryChange}"
            ?disabled="${this.__disablePrimaryPatient()}"
          ></neb-select>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="grid">
        <neb-select
          id="${ELEMENTS.type.id}"
          name="type"
          label="${'Relation to Patient'}"
          helper="Required"
          .items="${PATIENT_RELATIONSHIP_TYPES}"
          .value="${this.state.type}"
          .error="${this.errors.type}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        ${this.__renderPatientSearch()} ${this.__renderBasicInfo()}
        ${this.__renderPrimaryDropdown()} ${this.__renderNote()}
        ${this.__renderSwitch()}
      </div>
    `;
  }
}

customElements.define('neb-form-relationship', NebFormRelationship);
