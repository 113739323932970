import { html, css } from 'lit';

import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { QUESTION_ANSWER_TOKEN } from '../../../utils/macros';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/macros/neb-macro-region';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../forms/macros/choice-templates/neb-form-choice-template-choose';
import '../../forms/macros/choice-templates/neb-form-choice-template-region';

export const ELEMENTS = {
  regionForm: { id: 'region-form' },
  chooseForm: { id: 'choose-form' },
};

class NebPopupMacroEditChoiceTemplate extends NebPopup {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 996px;
        }

        .text-input {
          width: 100%;
        }

        .form {
          height: 610px;
        }

        .buttons-container {
          padding-top: ${CSS_SPACING};
        }

        .spacing {
          padding-right: 10px;
        }

        .container {
          padding: 0;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'Edit Choice Template';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      confirm: choiceTemplate => this.onClose(choiceTemplate),
    };
  }

  renderContent() {
    return this.model.type === QUESTION_ANSWER_TOKEN.region
      ? html`
          <neb-form-choice-template-region
            id="${ELEMENTS.regionForm.id}"
            class="form"
            .model="${this.model}"
            .onCancel="${this.handlers.cancel}"
            .onSave="${this.handlers.confirm}"
          ></neb-form-choice-template-region>
        `
      : html`
          <neb-form-choice-template-choose
            id="${ELEMENTS.chooseForm.id}"
            class="form"
            .model="${this.model}"
            .onCancel="${this.handlers.cancel}"
            .onSave="${this.handlers.confirm}"
          ></neb-form-choice-template-choose>
        `;
  }
}

customElements.define(
  'neb-popup-macro-edit-choice-template',
  NebPopupMacroEditChoiceTemplate,
);
