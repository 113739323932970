const SEPARATOR = '_';

const getPathAfter = str => str.slice(str.indexOf(SEPARATOR) + 1).toLowerCase();

const getLastLocationExistsAndIsNotPatientProfile = lastLocation =>
  lastLocation &&
  lastLocation !== 'PATIENT' &&
  lastLocation !== 'PATIENT_OVERVIEW' &&
  lastLocation !== 'PATIENT_OVERVIEW_PROFILE';

const buildPath = (id, lastLocation) => {
  const path = getPathAfter(lastLocation);
  return `${id}/${path.replaceAll('_', '/')}`;
};

export const getLastTrackedLocation = ({ id, lastLocation, isMobile }) => {
  const patientRoutes = {
    tabs: `${id}`,
    profile: `${id}/overview/profile`,
  };

  if (isMobile && !lastLocation) return patientRoutes.tabs;

  return getLastLocationExistsAndIsNotPatientProfile(lastLocation)
    ? buildPath(id, lastLocation)
    : patientRoutes.profile;
};

export const locationToEnum = (location, path) => {
  const normalizedPath = path.replace(/^\/+|\/+$/g, '').replace(/\/+/g, '_');
  const modifiedPath = normalizedPath ? `_${normalizedPath}` : '';
  return `${location.toUpperCase()}${modifiedPath.toUpperCase()}`;
};
