import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import * as ledgerStatementApiClient from '../../../packages/neb-api-client/src/ledger-statement-api-client';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import {
  getSupplementalInfoModel,
  getOrderingProviderInfoModel,
} from '../../../packages/neb-utils/claims';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import {
  EMPTY_NDC_ITEM,
  LINE_ITEM_BILLED_STATUSES,
  LINE_ITEM_EPSDT,
  LINE_ITEM_HOLD_STATUSES,
} from '../../formatters/encounter-charge-details';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_DISABLED,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../styles';
import './neb-icon';

export const ELEMENTS = {
  showHideDetails: { id: 'show-hide-details' },
  encounterChargeDetails: { id: 'encounter-charge-details' },
  chargeType: { id: 'charge-type' },
  holdStatus: { id: 'hold-status' },
  billedStatus: { id: 'billed-status' },
  statementCheckbox: { id: 'statement-checkbox' },
  statementLink: { id: 'statement-link' },
  invoice: { id: 'invoice' },
  invoiceLink: { id: 'invoice-link' },
  claimsContainer: { id: 'claims-container' },
  claims: { selector: '[id^=claim-' },
  claim: { id: 'claim' },
  encounter: { id: 'encounter' },
  encounterLink: { id: 'encounter-link' },
  location: { id: 'location' },
  fl24: { id: 'fl24' },
  epsdt: { id: 'epsdt' },
  ndcCheckbox: { id: 'ndc-checkbox' },
  textEditNationalDrugCode: { id: 'text-edit-national-drug-code' },
  supplementalInfoCheckbox: { id: 'supplemental-info-checkbox' },
  supplementalInfoLink: { id: 'supplemental-info-link' },
  orderingProviderCheckbox: { id: 'ordering-provider-checkbox' },
  textEditOrderingProvider: {
    id: 'text-edit-ordering-provider',
  },
};
class NebEncounterChargeDetails extends LitElement {
  static get properties() {
    return {
      icon: String,
      expanded: {
        type: Boolean,
        reflect: true,
      },
      model: Object,
    };
  }

  constructor() {
    super();
    this.initHandlers();
    this.initState();
  }

  initState() {
    this.expanded = false;

    this.model = {
      chargeType: '',
      holdStatus: [],
      billedStatus: [],
      invoice: '',
      claims: [],
      location: '',
      shaded24: null,
      EPSDTCode: false,
      EPSDTType: null,
      ndc: false,
      nationalDrugCodeQualifier: EMPTY_NDC_ITEM,
      nationalDrugCode: null,
      nationalDrugCodeDosage: null,
      nationalDrugCodeUnitOfMeasurement: EMPTY_NDC_ITEM,
      nationalDrugCodeNumberCategory: EMPTY_NDC_ITEM,
      nationalDrugCodeSequenceOrPrescription: null,
      supplementalInformation: false,
      identificationNumber: '',
      reportTypeCode: '',
      reportTransmissionCode: '',
      codeQualifier: '',
      orderingProvider: false,
      billedPatient: false,
      statementId: null,
    };

    this.onChangeHoldStatus = () => {};

    this.onChangeBilledStatus = () => {};

    this.onChangeEpsdtType = () => {};

    this.onChangeFl24 = () => {};

    this.onChangeNDC = () => {};

    this.onChangeNDCDetails = () => {};

    this.onOpenEncounterOverlay = () => {};

    this.onOpenInvoiceOverlay = () => {};

    this.onOpenClaimOverlay = () => {};

    this.onChangeSupplementalInfo = () => {};

    this.onChangeSupplementalInfoDetails = () => {};

    this.onChangeOrderingProvider = () => {};

    this.onChangeOrderingProviderDetails = () => {};

    this.onChangeBilledPatient = () => {};
  }

  initHandlers() {
    this.__handlers = {
      toggleExpand: () => {
        this.expanded = !this.expanded;
      },
      changeHoldStatus: e => {
        if (e.event === 'select') {
          this.onChangeHoldStatus(e.value);
        }
      },
      changeBilledStatus: e => {
        if (e.event === 'select') {
          this.onChangeBilledStatus(e.value);
        }
      },
      changeFl24: ({ value }) => {
        this.onChangeFl24(value || null);
      },
      changeEpsdtType: e => {
        if (e.event === 'select') {
          this.onChangeEpsdtType(e.value);
        }
      },
      changeNDC: e => {
        this.onChangeNDC(e);
      },
      openNDCPopUp: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.NATIONAL_DRUG_CODE,
          this.model,
        );

        if (result) {
          const { ndc, ...ndcDetails } = result;
          this.onChangeNDCDetails(ndcDetails);
        }
      },
      openEncounterOverlay: () => {
        this.onOpenEncounterOverlay();
      },
      openInvoiceOverlay: () => {
        this.onOpenInvoiceOverlay();
      },
      openClaimOverlay: ({ name }) => {
        const id = name;
        this.onOpenClaimOverlay(id);
      },
      changeSupplementalInfo: e => {
        this.onChangeSupplementalInfo(e);
      },
      openSupplementaInfoPopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.SUPPLEMENTAL_INFORMATION,
          getSupplementalInfoModel(this.model),
        );

        if (result) {
          this.onChangeSupplementalInfoDetails(result);
        }
      },
      changeOrderingProvider: e => {
        this.onChangeOrderingProvider(e);
      },
      openOrderingProviderPopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.ORDERING_PROVIDER,
          getOrderingProviderInfoModel(this.model),
        );

        if (result) {
          this.onChangeOrderingProviderDetails(result);
        }
      },
      changeBilledPatient: e => {
        this.onChangeBilledPatient(e);
      },
      openStatement: () =>
        printPdf(ledgerStatementApiClient.fetchOne(this.model.statementId)),
    };
  }

  static get styles() {
    return [
      css`
        :host([expanded]) .button-show-hide {
          margin-bottom: ${CSS_SPACING};
        }

        .container {
          padding: ${CSS_SPACING};
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .ellipse-field {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ellipse-block {
          width: 200px;
        }

        .billed-status {
          width: 245px;
        }

        .fields {
          display: flex;
          justify-content: space-between;
          gap: ${CSS_SPACING};
        }

        .checkbox-and-link-fields {
          display: flex;
          gap: 60px;
        }

        .long-flex-fields {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          white-space: nowrap;
        }

        .label-disabled {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_DISABLED};
        }

        .checkbox-link-container {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .margin-top {
          margin-top: 10px;
        }

        .checkbox {
          margin-top: 20px;
        }

        .info-field {
          margin-top: 20px;
        }
      `,
    ];
  }

  __getSelectedHoldStatuses() {
    return LINE_ITEM_HOLD_STATUSES.filter(({ value }) =>
      this.model.holdStatus.includes(value),
    );
  }

  __getSelectedBilledStatus() {
    const billingStatuses = LINE_ITEM_BILLED_STATUSES;

    return billingStatuses.filter(({ value }) =>
      this.model.billedStatus.includes(value),
    );
  }

  __getEPSDTType() {
    return this.model.EPSDTType === null
      ? LINE_ITEM_EPSDT[0]
      : LINE_ITEM_EPSDT.find(({ label }) => label === this.model.EPSDTType);
  }

  __renderShowHideDetails() {
    return html`
      <neb-button-action
        id="${ELEMENTS.showHideDetails.id}"
        class="button-show-hide"
        leadingIcon="${this.expanded ? 'visibilityOff' : 'visible'}"
        .label="${this.expanded ? 'Hide Details' : 'Show Details'}"
        .onClick="${this.__handlers.toggleExpand}"
      ></neb-button-action>
    `;
  }

  __renderClaims() {
    const claimsList = this.model.claims;

    return Array.from(claimsList).map((claim, index) => {
      const leadingComma = index === 0 ? '' : ', ';
      // prettier-ignore
      return html`${leadingComma}<neb-text
        link
        id="claim-${index}"
        .name="${claim.id}"
        .onClick="${this.__handlers.openClaimOverlay}"
      >${claim.label}</neb-text>`;
    });
  }

  __renderOrderingProviderLinkAndCheckbox(model) {
    return html`
      <div class="checkbox-link-container">
        <neb-checkbox
          id="${ELEMENTS.orderingProviderCheckbox.id}"
          label="Ordering Provider"
          name="orderingProvider"
          class="checkbox"
          .onChange="${this.__handlers.changeOrderingProvider}"
          ?checked="${model.orderingProvider}"
        ></neb-checkbox>
        <neb-text
          id="${ELEMENTS.textEditOrderingProvider.id}"
          link
          class="info-field"
          .onClick="${this.__handlers.openOrderingProviderPopup}"
          >Edit
        </neb-text>
      </div>
    `;
  }

  __renderStatementNumber() {
    return this.model.billedPatient
      ? html`
          <neb-text
            id="${ELEMENTS.statementLink.id}"
            .onClick="${this.__handlers.openStatement}"
            link
          >
            ${this.model.statementNumber}
          </neb-text>
        `
      : '';
  }

  __renderEncounterChargeDetails() {
    return this.expanded
      ? html`
          <div id="${ELEMENTS.encounterChargeDetails.id}">
            <div class="fields">
              <div>
                <div class="label">Charge Type</div>
                <div id="${ELEMENTS.chargeType.id}" class="margin-top">
                  Encounter Charge
                </div>
              </div>
              <div>
                <div class="label">Hold Status</div>
                <neb-select
                  id="${ELEMENTS.holdStatus.id}"
                  helper=" "
                  maxSelection="3"
                  multiSelect
                  .items="${LINE_ITEM_HOLD_STATUSES}"
                  .value="${this.__getSelectedHoldStatuses()}"
                  .onChange="${this.__handlers.changeHoldStatus}"
                ></neb-select>
              </div>
              <div>
                <div class="label">Billed Status</div>
                <neb-select
                  id="${ELEMENTS.billedStatus.id}"
                  helper=" "
                  class="${'billed-status'}"
                  maxSelection="${'5'}"
                  multiSelect
                  .items="${LINE_ITEM_BILLED_STATUSES}"
                  .enableClear="${true}"
                  .value="${this.__getSelectedBilledStatus()}"
                  .onChange="${this.__handlers.changeBilledStatus}"
                ></neb-select>
              </div>

              <div>
                <div class="label">On Statement</div>
                <div class="checkbox-link-container margin-top">
                  <neb-checkbox
                    id="${ELEMENTS.statementCheckbox.id}"
                    name="billedPatient"
                    ?checked="${this.model.billedPatient}"
                    .onChange="${this.__handlers.changeBilledPatient}"
                  ></neb-checkbox>
                  ${this.__renderStatementNumber()}
                </div>
              </div>

              <div>
                <div class="label">Invoice</div>
                <div id="${ELEMENTS.invoice.id}" class="margin-top">
                  <neb-text
                    id="${ELEMENTS.invoiceLink.id}"
                    .onClick="${this.__handlers.openInvoiceOverlay}"
                    link
                  >
                    ${this.model.invoice}
                  </neb-text>
                </div>
              </div>
              <div>
                <div class="label">Claims</div>
                <div id="${ELEMENTS.claimsContainer.id}" class="margin-top">
                  ${this.__renderClaims()}
                </div>
              </div>
              <div>
                <div class="label">Encounter</div>
                <div id="${ELEMENTS.encounter.id}" class="margin-top">
                  <neb-text
                    id="${ELEMENTS.encounterLink.id}"
                    .onClick="${this.__handlers.openEncounterOverlay}"
                    link
                    >${this.model.encounter}</neb-text
                  >
                </div>
              </div>
              <div class="ellipse-block">
                <div class="label">Location</div>
                <div
                  id="${ELEMENTS.location.id}"
                  class="ellipse-field margin-top"
                >
                  ${this.model.location}
                </div>
              </div>
            </div>
            <div class="fields">
              <div class="long-flex-fields">
                <div class="label">FL 24 Shaded</div>
                <neb-textfield
                  id="${ELEMENTS.fl24.id}"
                  .value="${this.model.shaded24}"
                  .onChange="${this.__handlers.changeFl24}"
                ></neb-textfield>
              </div>
              <div class="checkbox-link-container">
                <neb-checkbox
                  id="${ELEMENTS.ndcCheckbox.id}"
                  class="checkbox"
                  ?checked="${this.model.ndc}"
                  .onChange="${this.__handlers.changeNDC}"
                  name="ndc"
                  label="NDC"
                ></neb-checkbox>
                <neb-text
                  id="${ELEMENTS.textEditNationalDrugCode.id}"
                  class="info-field"
                  link
                  .onClick="${this.__handlers.openNDCPopUp}"
                  >Edit</neb-text
                >
              </div>
              <div class="checkbox-link-container">
                <neb-checkbox
                  id="${ELEMENTS.supplementalInfoCheckbox.id}"
                  class="checkbox"
                  ?checked="${this.model.supplementalInformation}"
                  .onChange="${this.__handlers.changeSupplementalInfo}"
                  name="supplementalInformation"
                  label="Supplemental Info"
                ></neb-checkbox>
                <neb-text
                  id="${ELEMENTS.supplementalInfoLink.id}"
                  class="info-field"
                  link
                  .onClick="${this.__handlers.openSupplementaInfoPopup}"
                  >Edit</neb-text
                >
              </div>
              ${this.__renderOrderingProviderLinkAndCheckbox(this.model)}
              <div class="long-flex-fields">
                <div
                  class="${this.model.EPSDTCode ? 'label' : 'label-disabled'}"
                >
                  EPSDT
                </div>
                <neb-select
                  id="${ELEMENTS.epsdt.id}"
                  .items="${LINE_ITEM_EPSDT}"
                  .value="${this.__getEPSDTType()}"
                  .disabled="${!this.model.EPSDTCode}"
                  .onChange="${this.__handlers.changeEpsdtType}"
                ></neb-select>
              </div>
            </div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderShowHideDetails()}
        ${this.__renderEncounterChargeDetails()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-encounter-charge-details',
  NebEncounterChargeDetails,
);
