import { formatAddress } from './address-util';

export const buildSelectedLocation = (selectedLocation, practiceName) => ({
  ...selectedLocation,
  name: selectedLocation.businessName || practiceName || '',
  address: formatAddress(selectedLocation),
  hideLogo: selectedLocation.hideLogo || false,
  email: selectedLocation.email || selectedLocation.emailAddress || '',
  phoneNumber: selectedLocation.phoneNumber || '',
});
