import { openPopup } from '@neb/popup';

import { getBillingCodesProviderAdjustments } from '../../../../../packages/neb-api-client/src/billing-codes';
import { getERAReport } from '../../../../../packages/neb-api-client/src/era-report-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { updateEraEobPaymentProviderAdjustment } from '../../../../api-clients/era-eob';

export const getReportData = ({ type: modelType, id }) =>
  modelType === 'ERA' ? getERAReport(id) : Promise.resolve();

const formatBillingCodes = codeWriteOffs =>
  codeWriteOffs.map(codes => ({ ...codes, codeGroup: '' }));

const generateInitialCodes = (providerAdjustments, codeWriteOffs) =>
  providerAdjustments
    ? formatBillingCodes(codeWriteOffs).flatMap(code =>
        providerAdjustments
          .filter(adj => adj.codeProviderAdjustmentId === code.id)
          .map(adjustment => ({ ...code, ...adjustment, codeId: code.id })),
      )
    : [];

const getPaymentAmount = ({
  payment: { paymentAmount, amount, providerAdjustments },
  unbalancedAmount,
}) => {
  const paidAmount = paymentAmount ?? amount;
  const remitOffsetAmount = providerAdjustments.reduce(
    (acc, adj) => acc + adj.amount,
    0,
  );
  return unbalancedAmount ? paidAmount + remitOffsetAmount : paidAmount;
};

export const editProviderAdjustment = async ({
  payments,
  index,
  postedEraData: { unbalancedAmount, ...postedEraData } = {},
}) => {
  const { providerAdjustments, paymentAllocated, allocated } = payments[index];

  const codeWriteOffs = await getBillingCodesProviderAdjustments();
  const initialCodes = unbalancedAmount
    ? [{ amount: unbalancedAmount }]
    : generateInitialCodes(providerAdjustments, codeWriteOffs);

  const result = await openPopup(POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT, {
    title: 'Remit Offsets',
    codeWriteOffs: formatBillingCodes(codeWriteOffs),
    displayPaidAmount: false,
    paymentAllocated: paymentAllocated ?? allocated,
    paymentAmount: getPaymentAmount({
      payment: payments[index],
      unbalancedAmount,
    }),
    initialCodes,
    ...(unbalancedAmount && { limit: 1 }),
  });

  if (result?.itemsToDelete?.length) {
    result.itemsToDelete = result.itemsToDelete.map(item => ({
      ...item,
      voided: true,
    }));
  }

  const adjustments = Object.values(result)
    .flat()
    .filter(adj => Object.keys(adj).length > 0);

  if (!adjustments.length) return false;

  return updateEraEobPaymentProviderAdjustment({
    paymentId: payments[index].id,
    body: adjustments.map(adj => ({
      ...(adj.individualRowId ? { id: adj.individualRowId } : {}),
      codeProviderAdjustmentId: adj.codeId,
      amount: adj.amount,
      ...(adj.voided ? { voided: adj.voided } : {}),
      ...(unbalancedAmount && { postedEraData }),
    })),
  });
};
