import '../../neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/patients/payment/neb-payment-card-on-file-form';

import { html, css } from 'lit';

import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';
import { SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH } from '../../neb-utils/payment-popup-constants';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  cardOnFileForm: {
    id: 'card-on-file-form',
  },
};

class NebPopupCardOnFile extends NebPopup {
  initState() {
    super.initState();
    this.showHeader = false;
    this.model = {
      amount: '',
      merchantAccounts: [],
      paymentMethods: [],
      holderId: '',
      holderType: '',
      postalCode: '',
      address1: '',
      logContent: {},
    };
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      save: result => this.onClose(result),
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: -20px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-content">
        <neb-payment-card-on-file-form
          id="${ELEMENTS.cardOnFileForm.id}"
          amount="${this.model.amount}"
          holderId="${this.model.holderId}"
          holderType="${this.model.holderType}"
          .logContent="${this.model.logContent}"
          postalCode="${this.model.postalCode}"
          address1="${this.model.address1}"
          address2="${this.model.address2}"
          city="${this.model.city}"
          state="${this.model.state}"
          firstName="${this.model.firstName}"
          lastName="${this.model.lastName}"
          .merchantAccounts="${this.model.merchantAccounts}"
          .paymentMethods="${this.model.paymentMethods}"
          .onSave="${this.__handlers.save}"
          .onCancel="${this.__handlers.cancel}"
        ></neb-payment-card-on-file-form>
      </div>
    `;
  }
}

customElements.define('neb-popup-card-on-file', NebPopupCardOnFile);
