import { CLAIM_STATUS } from '../../neb-utils/claims';

import {
  formatInvoicedClaims,
  formatToBilling,
  formatToPDF,
  formatToUI,
} from './formatters/claims';
import { filterQuery } from './invoice-api-client';
import DeprecatedApiClient, {
  buildQueryString,
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const BASE_URL = 'claims';

export const billingApiClient = new ApiClientV2({ microservice: 'billing' });

export const pdfApiClient = new DeprecatedApiClient({
  microservice: 'pdf',
  useTenant: true,
});

export const USER_ACTION_KEY = Object.freeze({
  REFRESH_AND_SUBMIT: 'refreshAndSubmit',
  CORRECT_CLAIM: 'correctClaim',
  REFRESH_CLAIM: 'refreshClaim',
  REBILL_CLAIM: 'rebillClaim',
  VOID_CANCEL_CLAIM: 'voidCancelClaim',
  CHC_GENERATE: 'chcGenerate',
});

const USER_ACTION_KEY_BY_DESCRIPTION = Object.freeze({
  'Claim corrected': USER_ACTION_KEY.CORRECT_CLAIM,
  'Claim rebilled': USER_ACTION_KEY.REBILL_CLAIM,
});

export function getUserActionKeyByDescription(description) {
  return USER_ACTION_KEY_BY_DESCRIPTION[description] || null;
}

export const createPDF = async (
  { offset, claims, printCmsBackground, bold },
  isElectronic = false,
) => {
  const res = await pdfApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify({
      offset,
      claims: claims.map(claim => formatToPDF(claim, isElectronic)),
      printCmsBackground,
      bold,
    }),
    headers: {
      'Content-Type': 'application/json',
    },

    version: 1,
  });

  return res.buffer;
};

export function bulkPrintClaims({ left, top, bold = false }, claimIds) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    queryParams: {
      offsetX: left,
      offsetY: top,
      bold,
      claimIds,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/claims/pdf',
  });
}

export const getClaim = async claimId => {
  const claims = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/claims/:id',
    replacements: { id: claimId },
    cacheKey: `${claimId}-claims`,
  });

  return formatToUI(claims);
};

export const getAllClaims = async params => {
  const claims = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/claims',
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
      ...(params && params.headers),
    },
    queryParams: params.query,
  });

  return claims;
};

export const getClaimFields = async ({
  patientId,
  invoiceId,
  patientInsuranceId,
  type,
}) => {
  const claimFields = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/invoices/:invoiceId/insurance/:insuranceId/claim-details',
    replacements: { invoiceId, insuranceId: patientInsuranceId },
    queryParams: { type },
    cacheKey: `${patientId}-${invoiceId}-${patientInsuranceId}-claims`,
  });

  return formatToUI({
    ...claimFields,
    claimStatuses: [{ status: CLAIM_STATUS.UNSAVED_DRAFT, updatedBy: '' }],
    paymentResponsibilityLevelCode: type,
    patientId,
    insuranceId: patientInsuranceId,
  });
};

export const getClaimCounts = ({ invoiceId, patientInsuranceId }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/invoices/:invoiceId/insurance/:insuranceId/claim-counts',
    replacements: { invoiceId, insuranceId: patientInsuranceId },
    cacheKey: `${invoiceId}-${patientInsuranceId}-claims-counts`,
  });

export const createClaim = ({
  claim,
  enableScrubbing = false,
  version = 4,
  actionKey = null,
  isCHC = false,
}) => {
  if (version === 4) {
    return billingApiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: '/api/v4/tenants/:tenantId/claims',
      method: Method.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formatToBilling({ claim })),
      queryParams: { enableScrubbing },
    });
  }

  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v5/tenants/:tenantId/claims',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formatToBilling({ claim })),
    queryParams: { enableScrubbing, actionKey, isCHC },
  });
};

export const deleteClaim = async claimId => {
  const result = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/claims/:id',
    replacements: { id: claimId },
    method: Method.DELETE,
    headers: { 'Content-Type': 'application/json' },
  });

  return result.count;
};

export const deleteClaims = async claimIds => {
  const result = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/claims/',
    method: Method.DELETE,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ claimIds }),
  });

  return result.count;
};

export const getClaimsByInvoice = async (
  invoiceId,
  optOutLoadingIndicator = false,
) => {
  const claims = await billingApiClient.makeRequest({
    path: `/api/v1/tenants/:tenantId/claims?invoiceId=${invoiceId}`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });

  return formatInvoicedClaims(claims);
};

export const refreshClaimData = async (
  claimId,
  version = 1,
  actionKey = null,
) => {
  let claim;

  if (version === 1) {
    claim = await billingApiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: '/api/v1/tenants/:tenantId/claims/:id/refresh',
      replacements: { id: claimId },
      method: Method.POST,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    claim = await billingApiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: '/api/v2/tenants/:tenantId/claims/:id/refresh',
      replacements: { id: claimId },
      method: Method.POST,
      queryParams: { actionKey },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  return formatToUI(claim);
};

export const updateClaimStatus = (claimId, status) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v2/tenants/:tenantId/claims/:id/status',
    replacements: { id: claimId },
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(status),
  });

export const updateClaim = (
  { claimId, claim },
  enableScrubbing = false,
  isCHC = false,
) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v5/tenants/:tenantId/claims/:id',
    replacements: { id: claimId },
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formatToBilling({ claim })),
    responseType: RESPONSE_TYPE.JSON,
    queryParams: { enableScrubbing, isCHC },
  });

export const getClaimsData = (
  table,
  {
    submissionMethods,
    statusCodes,
    payerIds,
    providerIds,
    locationIds,
    ...rest
  },
) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `/api/v3/tenants/:tenantId/claims-data${buildQueryString(
      filterQuery(rest),
    )}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(submissionMethods && { submissionMethods }),
      ...(statusCodes && { statusCodes }),
      ...(payerIds && { payerIds }),
      ...(providerIds && { providerIds }),
      ...(locationIds && { locationIds }),
    },
    cacheKey: `${rest.patientId || 'practice'}-get-${table}`,
  });

export const bulkRequestClaimStatus = data =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/solicited-claim-status',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
  });

export function refreshClaims(claimIds, version = 1) {
  if (version === 1) {
    return billingApiClient.makeRequest({
      optOutLoadingIndicator: false,
      body: JSON.stringify({ claimIds }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: Method.POST,
      path: '/api/v1/tenants/:tenantId/claims/refresh',
    });
  }

  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ claimIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v2/tenants/:tenantId/claims/refresh',
  });
}

export function rebillClaims(claimIds) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ claimIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/claims/rebill',
  });
}

export function validateRefreshClaims(claimIds) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ claimIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/claims/validate-refresh',
  });
}

export function refreshAndSubmitClaims(claimIds) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ claimIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/claims/refresh-and-submit',
  });
}

export function getClaimHistory(claimId, queryParams = {}) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: '/api/v3/tenants/:tenantId/claims/:id/history',
    replacements: { id: claimId },
    queryParams,
  });
}

export function correctClaim(body) {
  return billingApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body,
    path: '/api/v1/tenants/:tenantId/claims/correct',
    optOutLoadingIndicator: false,
  });
}

export async function voidClaim(body) {
  const claim = await billingApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body,
    path: '/api/v1/tenants/:tenantId/claims/void',
    optOutLoadingIndicator: false,
  });

  return formatToUI(claim);
}

export async function scrubClaims(body) {
  const res = await billingApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body,
    path: '/api/v1/tenants/:tenantId/claims/scrub',
    optOutLoadingIndicator: false,
  });

  return res.data[0];
}
