import '../controls/neb-switch';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-picker-color';
import '../inputs/neb-picker-color-advanced';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../neb-tooltip';
import '../inputs/neb-select';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import * as selectors from '../../../../neb-utils/selectors';
import { map } from '../../../../neb-utils/utils';
import { required } from '../../../../neb-utils/validators';
import { toMilliseconds, toTimespan } from '../../utils/misc';
import { isRequired as requiredDuration } from '../field-groups/neb-duration';

import Form, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkboxNewPatient: {
    id: 'checkbox-new-patient',
  },
  checkboxVisible: {
    id: 'checkbox-visible',
  },
  checkboxWalkIn: {
    id: 'checkbox-walk-in',
  },
  duration: {
    id: 'duration',
  },
  pickerColor: {
    id: 'picker-color',
  },
  pickerColorAdvanced: {
    id: 'picker-color-Advanced',
  },
  switchActive: {
    id: 'switch-active',
  },
  textareaDescription: {
    id: 'textarea-description',
  },
  textfieldName: {
    id: 'textfield-name',
  },
  locations: {
    id: 'locations',
  },
};

export default class FormAppointmentType extends Form {
  static get properties() {
    return {
      locations: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .field {
          width: fit-content;
        }

        .textarea-description {
          height: 200px;
        }

        .dropdowns-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .color-container {
          display: flex;
          align-items: center;
          gap: ${CSS_SPACING};
        }
      `,
    ];
  }

  static createModel() {
    return {
      active: true,
      color: '#C0E6FF',
      description: '',
      duration: 1000 * 60 * 30,
      name: '',
      visibleOnline: true,
      newPatient: false,
      walkInEnabled: true,
      locations: [],
    };
  }

  createSelectors() {
    return {
      children: {
        duration: {
          format: v => map(toTimespan(v), (_, value) => `${value}`),
          unformat: v => toMilliseconds(map(v, (_, value) => Number(value))),
          validators: [requiredDuration()],
        },
        name: [required()],
        locations: selectors.multiSelect(this.locations, this.locations, {
          validators: [required()],
        }),
      },
    };
  }

  initState() {
    super.initState();

    this.locations = [];
  }

  renderLocationsDropdown() {
    return html`
      <neb-select
        class="locations-dropdown"
        name="locations"
        id="${ELEMENTS.locations.id}"
        emptyMessage="No results found"
        label="Locations"
        helper="Required"
        .onChange="${this.handlers.change}"
        .value="${this.state.locations}"
        .error="${this.errors.locations}"
        .items="${this.locations}"
        pinLabel
        multiSelect
      ></neb-select>
    `;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        class="textfield textfield-name"
        name="name"
        label="Name"
        helper="Required"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
        pinLabel
      ></neb-textfield>

      <neb-textarea
        id="${ELEMENTS.textareaDescription.id}"
        class="textarea textarea-description"
        name="description"
        label="Description"
        helper=" "
        placeholder="Enter a description for the appointment type"
        .value="${this.state.description}"
        .error="${this.errors.description}"
        .onChange="${this.handlers.change}"
        pinLabel
      ></neb-textarea>

      ${this.renderLocationsDropdown()}

      <span>Duration</span>
      <div class="dropdowns-container">
        <neb-duration
          id="${ELEMENTS.duration.id}"
          class="field field-duration"
          name="duration"
          .model="${this.state.duration}"
          .error="${this.errors.duration}"
          .onChange="${this.handlers.change}"
        ></neb-duration>
      </div>

      <span>Appointment Color</span>
      <div class="color-container">
        <neb-picker-color
          id="${ELEMENTS.pickerColor.id}"
          class="field field-picker-color"
          name="color"
          .value="${this.state.color}"
          .onChange="${this.handlers.change}"
        ></neb-picker-color>

        <neb-picker-color-advanced
          id="${ELEMENTS.pickerColorAdvanced.id}"
          class="field field-picker-color"
          name="color"
          .value="${this.state.color}"
          .onChange="${this.handlers.change}"
        ></neb-picker-color-advanced>
      </div>

      <div class="grid grid-auto-left">
        <neb-checkbox
          id="${ELEMENTS.checkboxVisible.id}"
          name="visibleOnline"
          label="Allow Patients to Schedule Online"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.visibleOnline}"
        ></neb-checkbox>

        <neb-tooltip>
          <div slot="tooltip">
            If checked, this appointment type will appear on your online booking
            site for your patients to select and schedule an appointment for.
          </div>
        </neb-tooltip>
      </div>

      <neb-checkbox
        id="${ELEMENTS.checkboxWalkIn.id}"
        name="walkInEnabled"
        label="Allow Walk-in Appointments to be Booked Online"
        .onChange="${this.handlers.change}"
        ?checked="${this.state.walkInEnabled}"
      ></neb-checkbox>

      <neb-checkbox
        id="${ELEMENTS.checkboxNewPatient.id}"
        name="newPatient"
        label="New Patient Appointment"
        .onChange="${this.handlers.change}"
        ?checked="${this.state.newPatient}"
      ></neb-checkbox>

      <neb-switch
        id="${ELEMENTS.switchActive.id}"
        class="field"
        name="active"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }
}

window.customElements.define('neb-form-appointment-type', FormAppointmentType);
