import { isRequired } from '@neb/form-validators';

import { TRANSFER_BALANCE_TO as TRANSFER_BALANCE_PARTY } from '../../packages/neb-utils/neb-ledger-util';

import {
  ERROR_MISSING_PAYER,
  ERROR_SAME_PARTY,
  ERROR_ZERO_BALANCE,
} from './user-message';

export function isRequiredOrOptional() {
  return {
    error: 'Required',
    validate: (value, _keyPath, state) => {
      if (state.transferTo === TRANSFER_BALANCE_PARTY.PRIMARY) {
        return true;
      }

      return isRequired().validate(value);
    },
  };
}

export function isSameParty() {
  return {
    error: ERROR_SAME_PARTY,
    validate: (_value, _keyPath, state) =>
      state.transferFrom !== state.transferTo,
  };
}

export function isMissingSecondaryPayer() {
  return {
    error: ERROR_MISSING_PAYER(TRANSFER_BALANCE_PARTY.SECONDARY),
    validate: (value, _keyPath, state) => {
      if (value === TRANSFER_BALANCE_PARTY.SECONDARY) {
        return !!state.hasSecondaryPayer;
      }

      return true;
    },
  };
}

export function isMissingPrimaryPayer() {
  return {
    error: ERROR_MISSING_PAYER(TRANSFER_BALANCE_PARTY.PRIMARY),
    validate: (value, _keyPath, state) => {
      if (value === TRANSFER_BALANCE_PARTY.PRIMARY) {
        return !!state.hasPrimaryPayer;
      }

      return true;
    },
  };
}

export function isZeroBalance() {
  return {
    error: ERROR_ZERO_BALANCE,
    validate: (value, _keyPath, state) => {
      switch (value) {
        case TRANSFER_BALANCE_PARTY.PRIMARY:
          return state.balance.primary > 0;
        case TRANSFER_BALANCE_PARTY.SECONDARY:
          return state.balance.secondary > 0;
        case TRANSFER_BALANCE_PARTY.PATIENT:
          return state.balance.patient > 0;
        default:
          return true;
      }
    },
  };
}
