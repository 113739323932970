import { html, css } from 'lit';
import moment from 'moment-timezone';

import '../../../misc/neb-icon';
import '../../../controls/inputs/neb-checkbox';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../../packages/neb-lit-components/src/components/neb-text';
import {
  isValidStatus,
  allowDownload,
  E_CLAIM_FILE_STATUS,
} from '../../../../utils/e-claim-files';

export const CONFIG = [
  {
    key: 'checked',
    label: '',
    flex: css`0 0 28px`,
  },
  {
    key: 'updatedAt',
    label: 'File Date',
    flex: css`1 0 0`,
    formatter: d => moment(d).format('MM/DD/YYYY'),
  },
  {
    key: 'patientName',
    label: 'Patient',
    flex: css`1 0 0`,
  },
  {
    key: 'payer',
    label: 'Payer',
    flex: css`1 0 0`,
  },
  {
    key: 'dateOfService',
    label: 'Date of Service',
    flex: css`1 0 0`,
    formatter: d => (d ? moment(d).format('MM/DD/YYYY') : '-'),
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`2 0 0`,
  },
  {
    key: 'batchId',
    label: 'Batch ID',
    flex: css`1 0 0`,
    formatter: v => v || '-',
  },
  {
    key: 'claimNumber',
    label: 'Claim Number',
    flex: css`1 0 0`,
  },
  {
    key: 'clearinghouse',
    label: 'Clearinghouse',
    flex: css`1 0 0`,
  },
];

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  checkboxes: { selector: 'neb-checkbox' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  rowBatchId: { selector: '[id^=cell-batchId-]' },
  rowClaimNumber: { selector: '[id^=cell-claimNumber-]' },
  rowDateOfService: { selector: '[id^=cell-dateOfService-]' },
  rowClearinghouse: { selector: '[id^=cell-clearinghouse-]' },
  rowPatientName: { selector: '[id^=cell-patientName-]' },
  rowPayer: { selector: '[id^=cell-payer-]' },
  downloadButtons: {
    selector: '[id^=download-button-]',
  },
};

const BULK_ACTION_MENU = {
  selectAll: { id: 'selectAll', label: 'Select All' },
  deselectAll: { id: 'deselectAll', label: 'Deselect All' },
  downloadSelected: { id: 'download', label: 'Download Selected' },
  resendSelected: { id: 'resend', label: 'Resend Selected' },
  uploadSelected: { id: 'upload', label: 'Upload Selected' },
};

class NebTableClearinghouseFilesDownload extends NebTable {
  static get properties() {
    return {
      __showUploadAndResend: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-checkbox {
          padding: 0 5px;
        }

        .cell-header {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .label {
          margin-right: 5px;
        }

        .icon {
          margin-left: 4px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__showUploadAndResend = true;
    this.initState();
    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      download: e => this.onClickDownloadIcon(e),
      clickCheckbox: e => e.stopPropagation(),
      getBulkActions: () => {
        const checkedRowItems = this.model.filter(row => row.checked);
        const validRowItems = checkedRowItems.every(row =>
          isValidStatus(row.status),
        );

        if (checkedRowItems.length) {
          this.__showUploadAndResend = checkedRowItems.every(
            item => !item.batchId,
          );
        }

        return [
          {
            id: BULK_ACTION_MENU.selectAll.id,
            label: BULK_ACTION_MENU.selectAll.label,
            onSelect: this.onSelectAll,
          },
          {
            id: BULK_ACTION_MENU.deselectAll.id,
            label: BULK_ACTION_MENU.deselectAll.label,
            onSelect: this.onDeselectAll,
          },
          {
            id: BULK_ACTION_MENU.downloadSelected.id,
            label: BULK_ACTION_MENU.downloadSelected.label,
            onSelect:
              !!checkedRowItems.length && validRowItems
                ? this.onDownloadSelected
                : this.onOpenNoFilesPopup,
          },
          ...(this.__showUploadAndResend
            ? [
                {
                  id: BULK_ACTION_MENU.resendSelected.id,
                  label: BULK_ACTION_MENU.resendSelected.label,
                  onSelect: checkedRowItems.length
                    ? this.onResendSelected
                    : this.onOpenNoValidResends,
                },
                {
                  id: BULK_ACTION_MENU.uploadSelected.id,
                  label: BULK_ACTION_MENU.uploadSelected.label,
                  onSelect: checkedRowItems.length
                    ? this.onUploadSelected
                    : this.onOpenNoValidResends,
                },
              ]
            : []),
        ];
      },
    };
  }

  initState() {
    super.initState();

    this.config = CONFIG;

    this.onSelectAll = () => {};

    this.onDeselectAll = () => {};

    this.onDownloadSelected = () => {};

    this.onResendSelected = () => {};

    this.onUploadSelected = () => {};

    this.onOpenNoFilesPopup = () => {};

    this.onOpenNoValidResends = () => {};

    this.onClickDownloadIcon = () => {};
  }

  __renderCheckboxCell({ value, rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        .name="${rowIndex}"
        .onChange="${this.handlers.change}"
        ?checked="${value}"
        @click="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }

  renderHeaderCell(headerCell) {
    return headerCell.key === 'checked'
      ? html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .onClick="${this.handlers.getBulkActions}"
          ></neb-button-actions>
        `
      : super.renderHeaderCell(headerCell);
  }

  __shouldRenderDownload(value, item) {
    const checkDuration =
      item.status === E_CLAIM_FILE_STATUS.UPLOADING
        ? allowDownload(item.updatedAt)
        : true;

    return isValidStatus(value) && checkDuration;
  }

  __renderDownloadButton(value, rowIndex) {
    return this.__shouldRenderDownload(value, this.model[rowIndex])
      ? html`
          <neb-button-icon
            id="download-button-${rowIndex}"
            icon="neb:download"
            class="icon"
            .onClick="${() => this.handlers.download(this.model[rowIndex])}"
          ></neb-button-icon>
        `
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ value, rowIndex });

      case 'status':
        return html`
          <neb-text>${value}</neb-text>
          ${this.__renderDownloadButton(value, rowIndex)}
        `;

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

customElements.define(
  'neb-table-clearinghouse-files-download',
  NebTableClearinghouseFilesDownload,
);
