import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-macro-answer-preview';
import '../../neb-lit-components/src/components/macros/neb-macro-region';

import { html, css } from 'lit';

import { baseStyles } from '../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { arrayToSentence } from '../../neb-utils/array-to-sentence';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

export const ORDER = {
  CLICK: 'click',
  LIST: 'list',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  clickOrderRadio: {
    id: 'click-radio',
  },
  listOrderRadio: {
    id: 'list-radio',
  },
  regionDiagram: {
    id: 'region-diagram',
  },
};

class NebPopupMacrosBodyDiagram extends NebPopupMacros {
  static get properties() {
    return {
      __selectedIndices: Array,
      __orderBy: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      super.styles,
      css`
        .radio-container {
          display: flex;
          margin: 10px 0;
        }

        .list-radio {
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedIndices = [];
    this.__orderBy = ORDER.CLICK;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      clearSelection: () => {
        this.__selectedIndices = [];

        this.handlers.change(this.genAnswerFromSelections());
      },

      click: index => {
        this.__selectedIndices = this.__selectedIndices.includes(index)
          ? this.removeAtIndex(index)
          : [...this.__selectedIndices, index];

        this.handlers.change(this.genAnswerFromSelections());
      },

      select: orderBy => {
        this.__orderBy = orderBy;

        this.handlers.change(this.genAnswerFromSelections());
      },
    };
  }

  buildExtraAnswerProps() {
    return {
      order: this.__orderBy,
      selectedIndices: this.__selectedIndices,
    };
  }

  modelChanged() {
    super.modelChanged();

    if (this.model.answer) {
      this.__orderBy = this.model.answer.order;
      this.__selectedIndices = this.model.answer.selectedIndices;
    }
  }

  validateAnswer() {
    return (
      (this.__selectedIndices.length && this.__answer !== '') || !!this.__answer
    );
  }

  removeAtIndex(index) {
    const itemIndex = this.__selectedIndices.indexOf(index);

    return this.__selectedIndices
      .slice(0, itemIndex)
      .concat(this.__selectedIndices.slice(itemIndex + 1));
  }

  genAnswerFromSelections() {
    const indices =
      this.__orderBy === ORDER.CLICK
        ? this.__selectedIndices
        : this.__selectedIndices.slice().sort((a, b) => (a < b ? -1 : 1));

    const words = indices.map(index => this.model.options[index]);
    return arrayToSentence(words);
  }

  renderMacroContent() {
    return html`
      <neb-macro-region
        id="${ELEMENTS.regionDiagram.id}"
        .selectedIndices="${this.__selectedIndices}"
        .onClear="${this.handlers.clearSelection}"
        .onClick="${this.handlers.click}"
      ></neb-macro-region>
    `;
  }

  renderFooter() {
    return html`
      <div class="radio-container">
        <neb-radio-button
          id="${ELEMENTS.clickOrderRadio.id}"
          label="Display in click order"
          .value="${ORDER.CLICK}"
          .checked="${this.__orderBy === ORDER.CLICK}"
          .onSelect="${this.handlers.select}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.listOrderRadio.id}"
          class="list-radio"
          label="Head to toe, front; Head to toe, back"
          .value="${ORDER.LIST}"
          .checked="${this.__orderBy === ORDER.LIST}"
          .onSelect="${this.handlers.select}"
        ></neb-radio-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macros-body-diagram',
  NebPopupMacrosBodyDiagram,
);
