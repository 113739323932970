import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { genRequireSelect } from '../../../../packages/neb-utils/validators';
import { ADD_ONS } from '../../../utils/add-ons';
import {
  CLEARINGHOUSES,
  hasByocAddons,
} from '../../../utils/clearinghouse-settings';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  cancelButton: { id: 'button-cancel' },
  selectClearinghouse: { id: 'select-clearinghouse' },
  swapButton: { id: 'button-swap' },
};

class NebFormSwapClearinghouse extends NebForm {
  static get properties() {
    return {
      __clearinghouses: Array,
      __hasProClearTrizetto: Boolean,
      __hasProClearWaystar: Boolean,
      currentClearinghouse: Object,
    };
  }

  static createModel() {
    return {
      selectedClearinghouse: { label: '' },
    };
  }

  createSelectors() {
    return {
      children: {
        selectedClearinghouse: {
          validators: [genRequireSelect()],
          clipPristine: true,
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__clearinghouses = [];
    this.__hasProClearTrizetto = false;
    this.__hasProClearWaystar = false;
    this.currentClearinghouse = {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .container-radio {
          display: flex;
          align-items: baseline;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .input {
          flex: 1 0 0px;
        }
      `,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.currentClearinghouse.accessFromSupport) {
      this.__hasProClearTrizetto =
        this.currentClearinghouse.addOnsSupport.includes(
          ADD_ONS.CT_PROCLEAR_TRIZETTO,
        );

      this.__hasProClearWaystar =
        this.currentClearinghouse.addOnsSupport.includes(
          ADD_ONS.CT_PROCLEAR_WAYSTAR,
        );
    } else {
      this.__hasProClearTrizetto = (await hasByocAddons()).CT_PROCLEAR_TRIZETTO;
      this.__hasProClearWaystar = (await hasByocAddons()).CT_PROCLEAR_WAYSTAR;
    }

    this.__loadClearinghouses();
  }

  __loadClearinghouses() {
    this.__clearinghouses = Object.values(CLEARINGHOUSES)
      .filter(clearinghouse => {
        if (
          clearinghouse === this.currentClearinghouse.partner ||
          clearinghouse === CLEARINGHOUSES.CHC ||
          (!this.__hasProClearTrizetto &&
            clearinghouse === CLEARINGHOUSES.TRIZETTO) ||
          (!this.__hasProClearWaystar &&
            clearinghouse === CLEARINGHOUSES.WAYSTAR)
        ) {
          return false;
        }
        return true;
      })
      .map(clearinghouse => ({
        label: clearinghouse,
      }));
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.swapButton.id}"
          class="button add-button"
          .label="${'Swap'}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="container-radio">
        <neb-select
          id="${ELEMENTS.selectClearinghouse.id}"
          name="selectedClearinghouse"
          label="Clearinghouse"
          helper="${!this.state.selectedClearinghouse ? 'Required' : ' '}"
          class="input"
          .layout="${this.layout}"
          .items="${this.__clearinghouses}"
          .value="${this.state.selectedClearinghouse}"
          .error="${this.errors.selectedClearinghouse}"
          .onChange="${this.handlers.change}"
          required
        ></neb-select>
      </div>
    `;
  }
}

customElements.define('neb-form-swap-clearinghouse', NebFormSwapClearinghouse);
