import './neb-patient-appointments';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  appointments: {
    id: 'patient-appointments',
  },
};

class NebPatientOverviewPage extends LitElement {
  static get properties() {
    return {
      patientId: String,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.patientId = '';
    this.layout = '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          min-height: 0;
        }

        .container {
          overflow-y: auto;
          padding: ${CSS_SPACING};
          width: 100%;
          height: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .content {
          padding-right: 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-patient-appointments
        id="${ELEMENTS.appointments.id}"
        class="container"
        .patientId="${this.patientId}"
        active
        .layout="${this.layout}"
      ></neb-patient-appointments>
    `;
  }
}

customElements.define('neb-patient-overview-page', NebPatientOverviewPage);
