import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { RENDERER_SVGS } from '../../neb-styles/icons';
import {
  CSS_SPACING,
  CSS_COLOR_ERROR,
  CSS_BANNER_SUCCESS_COLOR,
} from '../../neb-styles/neb-variables';
import { centsToCurrency } from '../../neb-utils/formatters';
import { SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH } from '../../neb-utils/payment-popup-constants';
import { printBookingReceipt } from '../../neb-www-booking/src/components/util/receipt-util';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  title: {
    id: 'title',
  },
  subheader: {
    id: 'subheader',
  },
  message: {
    id: 'message',
  },
  cancelActionButton: {
    id: 'cancel-action-button',
  },
  confirmButton: {
    id: 'confirm-button',
  },
  icon: {
    id: 'icon',
  },
  hyperLink: {
    id: 'hyper-link',
  },
  payfieldsForm: {
    id: 'payfields-controller',
  },
  textHeader: {
    id: 'text-header',
  },
  iconSuccess: {
    id: 'icon-success',
  },
  iconWarning: {
    id: 'icon-warning',
  },
  paymentAmount: {
    id: 'payment-amount',
  },
  paymentId: {
    id: 'payment-id',
  },
  declinePaymentMessage: {
    id: 'decline-payment-message',
  },
};

const BUTTON_LABELS = {
  VIEW_RECIEPT: 'View Receipt',
  CLOSE: 'Close',
};

class NebPopupPatientOnlinePaymentStatus extends NebPopup {
  static get properties() {
    return {
      model: Object,
      dirty: Boolean,
      tenantId: String,
    };
  }

  initState() {
    super.initState();
    this.showHeader = true;
    this.model = {
      title: '',
      subheader: '',
      message: '',
      success: '',
      cancelLabel: '',
      confirmLabel: '',
      confirmLabelSave: '',
      action: '',
      tenantId: '',
      payment: {},
      patient: {},
      practiceInfo: {},
      billingAddress: {},
    };
  }

  constructor() {
    super();
    this.initState();

    this.__initHandlers();
  }

  __initHandlers() {
    this.handlers = {
      cancel: () => {
        this.model.action = 'close';
        this.onClose(this.model);
      },
      onCancel: () => {
        this.model.action = 'cancel';
        this.onClose(this.model);
      },
      onViewReciept: async () => {
        await printBookingReceipt(
          this.model.patient.tenantId,
          this.model.patient,
          this.model.payment,
          this.model.practiceInfo,
          this.model.billingAddress,
        );
      },
    };
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
        }

        :host .content {
          margin-top: 40px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .label {
          padding-bottom: ${CSS_SPACING};
        }

        .icon-container {
          width: 100%;
          padding-top: 50px;
          padding-bottom: 50px;
        }

        .icon-success {
          display: block;
          width: 128px;
          height: 128px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
          margin-left: auto;
          margin-right: auto;
        }

        .icon-warning {
          display: block;
          width: 128px;
          height: 128px;
          fill: ${CSS_COLOR_ERROR};
          margin-left: auto;
          margin-right: auto;
        }

        .field {
          display: grid;
          padding-bottom: ${CSS_SPACING};
          margin: 5px;
        }

        .payment-amount {
          padding-bottom: 15px;
        }

        .button {
          padding-right: ${CSS_SPACING};
          min-width: 150px;
        }

        .button:active {
          opacity: 0.65;
        }

        .container-button {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
        }
      `,
    ];
  }

  __renderViewReceipt() {
    return this.model.success
      ? html`
          <neb-button
            id="${ELEMENTS.confirmButton.id}"
            class="button"
            .label="${BUTTON_LABELS.VIEW_RECIEPT}"
            .onClick="${this.handlers.onViewReciept}"
          ></neb-button>
        `
      : '';
  }

  __renderCancel() {
    return html`
      <neb-button
        id="${ELEMENTS.cancelActionButton.id}"
        class="button"
        .label="${BUTTON_LABELS.CLOSE}"
        .onClick="${this.handlers.onCancel}"
        role="${BUTTON_ROLE.OUTLINE}"
      ></neb-button>
    `;
  }

  __renderLargeIcon() {
    if (this.model.success) {
      return html`
        <svg
          id="${ELEMENTS.iconSuccess.id}"
          class="icon-success"
          viewBox="${RENDERER_SVGS.checked.viewBox}"
        >
          ${RENDERER_SVGS.checked.src}
        </svg>
      `;
    }

    return html`
      <svg
        id="${ELEMENTS.iconWarning.id}"
        class="icon-warning"
        viewBox="${RENDERER_SVGS.warning.viewBox}"
      >
        ${RENDERER_SVGS.warning.src}
      </svg>
    `;
  }

  __renderPaymentDetails() {
    return this.model.success
      ? html`
          <div id="${ELEMENTS.paymentAmount.id}" class="payment-amount">
            Payment Amount: <b>${centsToCurrency(this.model.payment.amount)}</b>
          </div>
          <div id="${ELEMENTS.paymentId.id}" class="payment-id">
            Payment ID: <b>${this.model.payment.paymentNumber}</b>
          </div>
        `
      : '';
  }

  __renderDeclinePaymentMessage() {
    return !this.model.success
      ? html`
          <div id="${ELEMENTS.declinePaymentMessage.id}">
            ${this.model.message}
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderDeclinePaymentMessage()}
      <div class="icon-container">${this.__renderLargeIcon()}</div>
      <div class="field">${this.__renderPaymentDetails()}</div>
      <div class="container-button">
        ${this.__renderViewReceipt()} ${this.__renderCancel()}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-patient-online-payment-status',
  NebPopupPatientOnlinePaymentStatus,
);
