import '../../../neb-styles/neb-icons';
import './neb-calendar-popover';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  DAY,
  FULL_WEEK,
  MONTH,
  WORK_WEEK,
} from '../../../../src/utils/user-message';
import { baseStyles } from '../../../neb-styles/neb-styles';
import { CSS_COLOR_HIGHLIGHT } from '../../../neb-styles/neb-variables';
import {
  calendarPickerUtils,
  computeFullWeekRange,
  computeWorkWeekRange,
  formatDate,
} from '../../../neb-utils/moment-flag-conversion';

export const ELEMENTS = {
  dateLink: {
    id: 'date-link',
  },
  buttonNext: {
    id: 'button-next',
  },
  buttonPrevious: {
    id: 'button-previous',
  },
  popover: {
    id: 'popover',
  },
  popoverContainer: {
    id: 'popover-container',
  },
  blocker: {
    id: 'blocker',
  },
};

class NebCalendarPicker extends LitElement {
  static get properties() {
    return {
      momentFlag: {
        type: Boolean,
        reflect: true,
      },
      mode: String,
      selectedDate: Date,
      isToolbarVisible: Boolean,
      isPickerable: Boolean,
      __showCalendar: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.momentFlag = false;
    this.mode = DAY;
    this.selectedDate = null;
    this.isToolbarVisible = true;
    this.isPickerable = true;
    this.__showCalendar = false;

    this.isDateSelectable = () => true;

    this.onDateSelected = () => {};

    this.onTodaySelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      linkClicked: () => {
        this.__showCalendar = !this.__showCalendar;

        this.__elements.calendarPopover.reset();
      },
      dateSelected: date => {
        this.onDateSelected(date);
        this.__showCalendar = false;
      },
      clickNext: () => {
        this.onDateSelected(
          calendarPickerUtils.genericAdd(
            this.momentFlag,
            this.selectedDate,
            this.__getModeUnit(),
          ),
        );
      },
      clickPrevious: () => {
        this.onDateSelected(
          this.__genericSubtract(this.selectedDate, this.__getModeUnit()),
        );
      },
      todaySelected: date => {
        this.onDateSelected(date);
        this.__showCalendar = true;
      },
    };
  }

  __genericSubtract(date, [amount, unit]) {
    return calendarPickerUtils.genericAdd(this.momentFlag, date, [
      -1 * amount,
      unit,
    ]);
  }

  __getModeUnit() {
    switch (this.mode) {
      case DAY:
        return [1, 'day'];

      case WORK_WEEK:
      case FULL_WEEK:
        return [7, 'day'];

      case MONTH:
        return [1, 'month'];

      default:
        return undefined;
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .date-link {
          cursor: pointer;
          display: flex;
          justify-content: center;
          min-width: 240px;

          padding: 0 8px;
          text-decoration: underline;
          font-weight: bold;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .calendar-popover-container {
          display: flex;
          opacity: 0;
          transition: opacity 0.1s ease-out;
          pointer-events: none;
          position: relative;
          z-index: 5;
        }

        .calendar-popover-container[show] {
          opacity: 1;
          pointer-events: initial;
        }

        .content-container {
          height: 20px;
        }

        .icon {
          cursor: pointer;
          width: 12px;
          height: 12px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-chevron-left {
          transform: rotate(90deg);
        }

        .icon-chevron-right {
          transform: rotate(-90deg);
        }
      `,
    ];
  }

  getLabel() {
    switch (this.mode) {
      case DAY:
        return formatDate(
          this.momentFlag,
          this.selectedDate,
          'dddd, MMMM D, YYYY',
        );

      case WORK_WEEK:
        return this.__formatWorkWeek();

      case FULL_WEEK:
        return this.__formatWeek();

      case MONTH:
        return formatDate(this.momentFlag, this.selectedDate, 'MMMM YYYY');

      default:
        return undefined;
    }
  }

  __formatWorkWeek() {
    return calendarPickerUtils.weekFormat(
      this.momentFlag,
      computeWorkWeekRange(this.momentFlag, this.selectedDate),
    );
  }

  __formatWeek() {
    return calendarPickerUtils.weekFormat(
      this.momentFlag,
      computeFullWeekRange(this.momentFlag, this.selectedDate),
    );
  }

  firstUpdated() {
    this.__elements = {
      calendarPopover: this.shadowRoot.getElementById(ELEMENTS.popover.id),
    };
  }

  render() {
    return html`
      <div class="content-container">
        <div class="container">
          <neb-icon
            id="${ELEMENTS.buttonPrevious.id}"
            class="icon icon-chevron-left"
            icon="neb:chevron"
            @click="${this.__handlers.clickPrevious}"
          ></neb-icon>

          <div
            id="${ELEMENTS.dateLink.id}"
            class="date-link"
            @click="${this.__handlers.linkClicked}"
          >
            ${this.getLabel()}
          </div>

          <neb-icon
            id="${ELEMENTS.buttonNext.id}"
            class="icon icon-chevron-right"
            icon="neb:chevron"
            @click="${this.__handlers.clickNext}"
          ></neb-icon>
        </div>

        <div
          id="${ELEMENTS.popoverContainer.id}"
          class="calendar-popover-container"
          ?show="${this.__showCalendar}"
        >
          <neb-calendar-popover
            id="${ELEMENTS.popover.id}"
            .selectedDate="${this.__showCalendar ? this.selectedDate : null}"
            .onDateSelected="${this.__handlers.dateSelected}"
            .onTodaySelected="${this.__handlers.todaySelected}"
            .isDateSelectable="${this.isDateSelectable}"
            .isToolbarVisible="${this.isToolbarVisible}"
            .isPickerable="${this.isPickerable}"
            .onClosePopover="${this.__handlers.linkClicked}"
            ?momentFlag="${this.momentFlag}"
          ></neb-calendar-popover>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-calendar-picker', NebCalendarPicker);
