import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { defaultMask, number } from '../../../../../neb-utils/masks';

const getTextInputItems = (name, state) => {
  switch (name) {
    case 'authorizationNumber':
      return [
        {
          name: 'claimAuthorizations.0.authorizationNumber',
          maxLength: 29,
          label: 'Prior Authorization Number',
          value: state.claimAuthorizations.length
            ? state.claimAuthorizations[0].authorizationNumber
            : '',
          width: '100%',
          mask: defaultMask,
        },
      ];

    case 'claimCodes':
      return [
        {
          name: 'claimCodes',
          maxLength: 30,
          label: 'Claim Codes',
          value: state.claimCodes,
          width: '100%',
          mask: defaultMask,
        },
      ];

    case 'otherClaimNumber':
      return [
        {
          name: 'otherClaimQualifier',
          maxLength: 2,
          label: 'Qualifier',
          value: state.otherClaimQualifier,
          width: '25%',
          mask: defaultMask,
        },
        {
          name: 'otherClaimId',
          maxLength: 28,
          label: 'Other Claim ID',
          value: state.otherClaimId,
          width: '75%',
          mask: defaultMask,
        },
      ];

    case 'referringProviderNPI':
      return [
        {
          name: 'referringProviderNPI',
          maxLength: 10,
          label: 'NPI',
          value: state.referringProviderNPI,
          width: '100%',
          mask: number,
          validator: {
            error: 'Must be 10 characters',
            validate: v => v.length === 10 || !v.length,
          },
        },
      ];

    default:
      return undefined;
  }
};

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_TEXT_INPUT, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: getTextInputItems(claimCellData.name, state),
  });

  if (result) {
    Object.entries(result).forEach(([name, value]) => {
      if (name.includes('authorizationNumber')) {
        formService.apply('claimEditedField.authorizationNumber', true);
      } else {
        formService.apply(`claimEditedField.${name}`, true);
      }

      handlers.change({
        name,
        value,
      });

      return undefined;
    });
  }
};
