import { store } from '../neb-redux-store';

export const LAYOUT_TYPE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};
export class LayoutService {
  constructor(callback) {
    this.__onStateChanged = callback;
  }

  connect() {
    this.__storeUnsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );

    this._stateChanged(store.getState());
  }

  disconnect() {
    this.__storeUnsubscribe();
  }

  _stateChanged(state) {
    if (state.layoutMedia) {
      this.__onStateChanged(state.layoutMedia.layout);
    }
  }
}
