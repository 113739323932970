import { html, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { copyConversion } from '../../../api-clients/conversions';

import '../../forms/conversions/neb-form-copy-conversion';

const ELEMENTS = {
  copyConversionForm: { id: 'copyConversionForm' },
};

class NebControllerCopyConversion extends LitElement {
  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.onSave = () => {};

    this.onCancel = () => {};
  }

  initHandlers() {
    this.handlers = {
      cancel: () => this.__cancel(),
      save: model => this.__save(model),
    };
  }

  __cancel() {
    this.onCancel();
  }

  async __save(model) {
    try {
      model.conversionId = this.conversionId;
      model.tenantId = this.tenantId;
      const {
        data: [result],
      } = await copyConversion(model);

      if (result.status === 'Exists') {
        store.dispatch(
          openError('Not copied because conversion already exists'),
        );

        this.onCancel();
        return;
      }
      this.onSave({ ...result, active: true });
      store.dispatch(openSuccess('Conversion copy created successfully'));
    } catch (err) {
      console.log(err);
      store.dispatch(
        openError('An error occurred when creating the Conversion copy'),
      );

      this.onCancel();
    }
  }

  render() {
    return html`
      <neb-form-copy-conversion
        id="${ELEMENTS.copyConversionForm.id}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
      ></neb-form-copy-conversion>
    `;
  }
}

customElements.define(
  'neb-controller-copy-conversion',
  NebControllerCopyConversion,
);
