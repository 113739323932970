import '../misc/neb-card-switch';
import '../../../packages/neb-lit-components/src/components/neb-search-bar';

import { html, css } from 'lit';
import { keyed } from 'lit/directives/keyed.js';

import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  FEATURE_FLAGS_ON_PRODUCTION,
  RCM_FEATURE_FLAGS,
} from '../../../packages/neb-utils/feature-util';
import { CSS_COLOR_GREY_1 } from '../../styles';
import '../../../packages/neb-lit-components/src/components/controls/neb-switch';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  featureFlagCards: { selector: 'neb-card-switch' },
  noFeatureMessage: { id: 'no-feature-message' },
  rcmToggle: { id: 'rcm-toggle' },
};

const formatFeatureTitle = str => str.replace(/_/g, ' ');

export default class NebFormFeatureFlags extends Form {
  static get properties() {
    return {
      filteredFlags: Array,
      __rcmToggle: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding: ${CSS_SPACING} 0px;
          background-color: white;
        }

        .card-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
        }

        .spacing {
          padding: 0 ${CSS_SPACING};
        }

        .no-feature-message {
          color: ${CSS_COLOR_GREY_1};
        }

        .toggle-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .toggle-text {
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  static createModel() {
    return [];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.filteredFlags = [];
    this.__rcmToggle = false;
  }

  __initHandlers() {
    this.__handlers = {
      ...this.handlers,
      toggleAllRcmFlags: () => {
        const toggleStatus = this.__rcmToggle;

        const rcmFlagKeys = Object.keys(FEATURE_FLAGS).filter(key =>
          RCM_FEATURE_FLAGS.includes(FEATURE_FLAGS[key]),
        );

        rcmFlagKeys.forEach(key => {
          this.formService.apply(key, !toggleStatus);
        });
      },
    };
  }

  createSelectors() {
    return {
      format: v => {
        const state = Object.entries(FEATURE_FLAGS).reduce(
          (acc, [key, value]) => {
            acc[key] = v.includes(value);

            return acc;
          },
          {},
        );

        const featureFlagValues = Object.values(FEATURE_FLAGS);
        const featureFlags = v.filter(
          flag => !featureFlagValues.includes(flag),
        );

        return { ...state, featureFlags };
      },

      unformat: v => {
        const newFeatureFlags = Object.entries(v).reduce(
          (acc, [key, value]) => {
            if (value && key !== 'featureFlags') {
              acc.push(FEATURE_FLAGS[key]);
            }

            return acc;
          },
          [],
        );

        return [...newFeatureFlags, ...v.featureFlags];
      },
    };
  }

  updated() {
    this.__rcmToggle = this.__getRcmActiveStatus();
  }

  __filterFeatureFlags() {
    return this.filteredFlags.length
      ? Object.fromEntries(
          Object.entries(FEATURE_FLAGS).filter(([_, value]) =>
            this.filteredFlags.includes(value),
          ),
        )
      : FEATURE_FLAGS;
  }

  __renderIcon(flag) {
    if (RCM_FEATURE_FLAGS.includes(flag)) {
      return 'neb:billDollarSign';
    }

    if (FEATURE_FLAGS_ON_PRODUCTION.includes(flag)) {
      return 'neb:checkCircle';
    }

    return '';
  }

  __renderCardSwitch(key, value) {
    const icon = this.__renderIcon(value);

    return keyed(
      key,
      html`
        <neb-card-switch
          name="${key}"
          title="${formatFeatureTitle(key)}"
          icon="${icon || ''}"
          .active="${!!this.state[key]}"
          .onChange="${this.__handlers.change}"
        ></neb-card-switch>
      `,
    );
  }

  __getRcmActiveStatus() {
    const featureFlagKeys = Object.keys(FEATURE_FLAGS);

    const rcmFlagKeys = featureFlagKeys.filter(key =>
      RCM_FEATURE_FLAGS.includes(FEATURE_FLAGS[key]),
    );

    const rcmFlagStates = rcmFlagKeys.map(key => this.state[key]);

    return rcmFlagStates.every(flag => flag);
  }

  __renderFeatureFlagCards() {
    const filteredFeatureFlags = this.__filterFeatureFlags();

    const featureFlagEntries = Object.entries(filteredFeatureFlags).sort(
      ([keyA], [keyB]) => keyA.localeCompare(keyB),
    );

    return featureFlagEntries.length
      ? html`
          <div class="card-container spacing">
            ${featureFlagEntries.map(([key, value]) =>
              this.__renderCardSwitch(key, value),
            )}
          </div>
        `
      : html`
          <div
            id="${ELEMENTS.noFeatureMessage.id}"
            class="no-feature-message spacing"
          >
            There are no available Features at this time.
          </div>
        `;
  }

  __renderRcmToggle() {
    return html`
      <div class="toggle-container">
        <neb-switch
          id="${ELEMENTS.rcmToggle.id}"
          ?on="${this.__rcmToggle}"
          .disabled="${RCM_FEATURE_FLAGS.length === 0}"
          .onChange="${this.__handlers.toggleAllRcmFlags}"
        ></neb-switch
        ><span class="toggle-text">Toggle All RCM Flags</span>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderRcmToggle()} ${this.__renderFeatureFlagCards()}
    `;
  }
}

customElements.define('neb-form-feature-flags', NebFormFeatureFlags);
