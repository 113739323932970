import '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-macro-answer-preview';
import './neb-popup-action-buttons';

import { html, css } from 'lit';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ACTIONS = {
  BACK: 'back',
  CANCEL: 'cancel',
  NEXT: 'next',
  SAVE: 'save',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  preview: {
    id: 'preview',
  },
  actionButtons: {
    id: 'action-buttons',
  },
};

export class NebPopupMacros extends NebPopup {
  static get properties() {
    return {
      previewFocused: Boolean,
      answer: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 577px;
          height: 623px;
        }

        .content {
          height: max-content;
          flex: 1 0 0;
        }

        .content-container {
          display: flex;
          flex-direction: column;

          height: 100%;
          min-height: 0;
        }

        .scroll-container {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          overflow-y: auto;
        }

        .preview {
          display: flex;
          flex: 1 0 95px;

          min-height: 0;
          max-height: 95px;
        }

        .header {
          word-break: break-word;
          align-items: flex-start;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.previewFocused = false;
    this.answer = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      back: () => this.handleButtonAction(ACTIONS.BACK),

      blurPreview: () => {
        this.previewFocused = false;
      },

      cancel: () =>
        this.onClose({
          action: ACTIONS.CANCEL,
        }),

      change: value => {
        this.answer = value;
      },

      focusPreview: () => {
        this.previewFocused = true;
      },

      next: () => this.handleButtonAction(ACTIONS.NEXT),
      save: () => this.handleButtonAction(ACTIONS.SAVE),
    };
  }

  buildExtraAnswerProps() {
    return {};
  }

  validateAnswer() {
    return this.answer;
  }

  handleButtonAction(action) {
    const answer = this.validateAnswer()
      ? {
          text: this.answer,
          ...this.buildExtraAnswerProps(),
        }
      : null;

    this.onClose({
      action,
      answer,
    });

    this.answer = '';
    this.previewFocused = false;
  }

  modelChanged() {
    this.title = this.model.title;

    if (this.model.answer) {
      this.answer = this.model.answer.text || '';
    }
  }

  renderMacroContent() {
    return '';
  }

  renderFooter() {
    return '';
  }

  renderPreview() {
    return html`
      <neb-macro-answer-preview
        id="${ELEMENTS.preview.id}"
        class="preview"
        .content="${this.answer}"
        .onBlur="${this.handlers.blurPreview}"
        .onFocus="${this.handlers.focusPreview}"
        .onChange="${this.handlers.change}"
      ></neb-macro-answer-preview>
    `;
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="scroll-container">
          ${this.renderMacroContent()} ${this.renderPreview()}
          ${this.renderFooter()}
        </div>

        <neb-popup-action-buttons
          id="${ELEMENTS.actionButtons.id}"
          .isFirst="${this.model.isFirst}"
          .isLast="${this.model.isLast}"
          .onSaveClick="${this.handlers.save}"
          .onCancelClick="${this.handlers.cancel}"
          .onBackClick="${this.handlers.back}"
          .onNextClick="${this.handlers.next}"
        ></neb-popup-action-buttons>
      </div>
    `;
  }
}
