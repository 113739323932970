/**
 * Converts the time in 24-hour decimal form to AM/PM including minutes
 * where .5 is 30 minutes (60 minutes * .5) or .25 is 15 minutes (60 minutes * .25).
 * @param {Number} time in decimal form.
 */

function toTimeDisplayValue(time) {
  if (time === 0) {
    return '12:00 AM';
  }

  if (time === 24) {
    return '11:59 PM';
  }

  if (time) {
    let displayTime = '';
    let h = Math.floor(time);

    const isAM = h < 12;

    if (h === 0) {
      h = 12;
    }

    // eslint-disable-next-line no-nested-ternary
    h = isAM ? h : h - 12 === 0 ? 12 : h - 12;
    const m = Math.round((time - Math.trunc(time)) * 60).toFixed(0);
    // eslint-disable-next-line no-nested-ternary
    displayTime = `${h}:${m === 0 ? '00' : m < 10 ? `0${m}` : m}${
      isAM ? ' AM' : ' PM'
    }`;

    return displayTime;
  }

  return '';
}
/**
 * Returns the hour part of the time in fraction form.
 * Ex: 1 in 1.5, 13 in 13.25
 * @param {Number} time in fraction.
 */

function extractHourFromTimeFraction(time) {
  if (time == null) {
    return null;
  }

  return Math.trunc(time);
}
/**
 * Returns the minutes part of the time in fraction form.
 * Ex: 30 in 1.5, 15 in 5.25
 * @param {Number} time
 */

function extractMinutesFromTimeFraction(time) {
  if (time == null) {
    return null;
  }

  const minutesFrac = time % 1;
  const minutes = minutesFrac * 60;
  return Math.round(minutes);
}
/**
 * Computes hour and minutes into time in fraction.
 * @param {Number} hour Hour part of time in 24h format.
 * @param {Number} minutes Minutes part of the time.
 * @param {Boolean} noFixedPoint flag is true, return the whole number.
 */

function toFraction(hour, minutes, noFixedPoint) {
  if (hour == null || minutes == null) {
    return 0;
  }

  if (noFixedPoint) {
    return parseInt(hour, 10) + parseFloat(parseInt(minutes, 10) / 60);
  }

  return (
    parseInt(hour, 10) + parseFloat((parseInt(minutes, 10) / 60).toFixed(2))
  );
}

export {
  toTimeDisplayValue,
  extractHourFromTimeFraction,
  extractMinutesFromTimeFraction,
  toFraction,
};
