import { css, html } from 'lit';

import { layoutStyles, CSS_FONT_FAMILY } from '../../../src/styles';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';

import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/neb-text';

import Popup from './neb-popup';

export const ELEMENTS = {
  buttonCancel: { id: 'button-cancel' },
  buttonConfirm: { id: 'button-confirm' },
  textHelp: { id: 'text-help' },
  textQuestion: { id: 'text-question' },
  textareaInvoiceNumbers: { id: 'textarea-invoice-numbers' },
};

const uniq = arr => [...new Set(arr)];

class PopupClaimLineItems extends Popup {
  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        :host {
          max-width: 450px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 20px;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();
  }

  updated(changedProperties) {
    if (changedProperties.has('model')) {
      this.updateTitleBasedOnModel();
    }
  }

  updateTitleBasedOnModel() {
    if (this.model && this.model.title) {
      this.title = this.model.title;
    }
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      confirm: () => this.onClose(true),
    };
  }

  renderContent() {
    return html`
      <div class="grid grid-lean">
        <neb-textarea
          id="${ELEMENTS.textareaInvoiceNumbers.id}"
          helper=" "
          label="These invoices will not be ${this.model.actionPastTense}:"
          .value="${uniq(this.model.invoiceNumbers).join(', ')}"
          readonly
        ></neb-textarea>

        <neb-text id="${ELEMENTS.textHelp.id}"
          >${this.model.helperText}</neb-text
        >

        <neb-text id="${ELEMENTS.textQuestion.id}"
          >Would you still like to ${this.model.actionPresentTense} all other
          claims?</neb-text
        >

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="YES"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="NO"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-claim-line-items', PopupClaimLineItems);
