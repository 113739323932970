const inTimeText = (inTime, inTimeType) =>
  inTime === 1 ? `1 ${inTimeType.replace('s', '')}` : `${inTime} ${inTimeType}`;

const onDayText = onDay => {
  if (onDay === 'Daily' || onDay === 'Tomorrow' || onDay === 'As Needed') {
    return `${onDay}`;
  }
  return `on ${onDay}`;
};

const minutesToHours = minutes => {
  const hours = parseInt(minutes, 10) / 60;
  const roundedHours = Math.floor(hours);
  const convertedMinutes = (hours - roundedHours) * 60;
  const roundedconvertedMinutes = Math.round(convertedMinutes);
  if (roundedconvertedMinutes === 0) return `${roundedHours} hours`;

  if (roundedHours === 1) {
    return `${roundedHours} hour ${roundedconvertedMinutes} minute${
      roundedconvertedMinutes === 1 ? '' : 's'
    }`;
  }
  return `${roundedHours} hours ${roundedconvertedMinutes} minute${
    roundedconvertedMinutes === 1 ? '' : 's'
  }`;
};

const convertExtraMinutes = minutes => {
  if (parseInt(minutes, 10) <= 55) return `${minutes} minutes`;
  if (parseInt(minutes, 10) === 60) return '1 hour';
  return minutesToHours(minutes);
};

const generateOnDay = nextAppointmentData => {
  const { onDay, enableExtraTime, extraMinutes, note } = nextAppointmentData;

  if (enableExtraTime && extraMinutes) {
    return `Schedule next appointment ${onDayText(
      onDay,
    )}, include extra ${convertExtraMinutes(extraMinutes)}.${
      note ? ` ${note}` : ''
    }`;
  }
  if (note) return `Schedule next appointment ${onDayText(onDay)}. ${note}`;
  return `Schedule next appointment ${onDayText(onDay)}.`;
};

const generateInTime = nextAppointmentData => {
  const { inTime, inTimeType, enableExtraTime, extraMinutes, note } =
    nextAppointmentData;

  if (enableExtraTime && extraMinutes) {
    return `Schedule next appointment in ${inTimeText(
      inTime,
      inTimeType,
    )}, include extra ${convertExtraMinutes(extraMinutes)}.${
      note ? ` ${note}` : ''
    }`;
  }

  if (note) {
    return `Schedule next appointment in ${inTimeText(
      inTime,
      inTimeType,
    )}. ${note}`;
  }
  return `Schedule next appointment in ${inTimeText(inTime, inTimeType)}.`;
};

export const generateNextApointmentMessage = nextAppointmentData => {
  if (Object.keys(nextAppointmentData).length === 0) {
    return 'There is no requested next appointment.';
  }

  if (nextAppointmentData.onDay) {
    return generateOnDay(nextAppointmentData);
  }

  if (nextAppointmentData.inTime) {
    return generateInTime(nextAppointmentData);
  }

  return undefined;
};
