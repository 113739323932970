import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../pages/encounters/neb-page-add-code-bundle-to-encounter';

import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  actionBar: {
    id: 'action-bar',
  },
  codeBundlePage: {
    id: 'code-bundle-page',
  },
  description: {
    id: 'description',
  },
};

class NebOverlayAddCodeBundleToEncounter extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }

        .description {
          margin-left: ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      codeBundleAdded: () => {
        this.dismiss(true);
      },
    };
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Code Bundles"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>

      <div id="${ELEMENTS.description.id}" class="description">
        Select a code bundle to add associated diagnoses and/or charges to this
        encounter.
      </div>

      <neb-page-add-code-bundle-to-encounter
        id="${ELEMENTS.codeBundlePage.id}"
        .onAddCodeBundle="${this.handlers.codeBundleAdded}"
        .encounter="${this.model.encounter}"
      ></neb-page-add-code-bundle-to-encounter>

      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Close"
        .onCancel="${this.handlers.dismiss}"
      ></neb-action-bar>
    `;
  }
}

customElements.define(
  'neb-overlay-add-code-bundle-to-encounter',
  NebOverlayAddCodeBundleToEncounter,
);
