export const _deleting = (state, _action) => ({
  ...state,
  deleting: true,
  deleteError: null,
});
export const _deleted = (state, _action) => ({
  ...state,
  deleting: false,
  deleted: true,
  deleteError: null,
  item: '',
});
export const _deleteError = (state, action) => ({
  ...state,
  deleting: false,
  deleted: false,
  deleteError: action.deleteError,
});
