import '../../../../packages/neb-www-practice-charting/src/components/treatmentPlan/neb-charting-treatment-plan-summary';
import { html } from 'lit';

import { getEmptyTreatmentPlan } from '../../../../packages/neb-api-client/src/treatment-plans';
import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  view: { id: 'view' },
};

const TREATMENT_PLAN_ITEM_NAME = 'Treatment Plan';

class NebUnsignedEncounterTreatmentPlanSummary extends NebUnsignedEncounterSummaryPanel {
  createModel() {
    return getEmptyTreatmentPlan();
  }

  getTitle() {
    return this.model.treatmentPhases.length > 0
      ? `${TREATMENT_PLAN_ITEM_NAME} (${this.model.treatmentPhases.length})`
      : TREATMENT_PLAN_ITEM_NAME;
  }

  renderContent() {
    return html`
      <neb-charting-treatment-plan-summary
        id="${ELEMENTS.view.id}"
        .treatmentPlan="${this.model}"
      ></neb-charting-treatment-plan-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-treatment-plan-summary',
  NebUnsignedEncounterTreatmentPlanSummary,
);
