import '../inputs/neb-textfield';
import '../inputs/neb-select';

import { isRequiredIf } from '@neb/form-validators';
import { html } from 'lit';

import { STATES } from '../../../../neb-utils/enums';

import { ELEMENTS as BASE_ELEMENTS, Item } from './neb-item';

export const ELEMENTS = { ...BASE_ELEMENTS };

export default class LicenseItem extends Item {
  static get properties() {
    return {
      model: Object,
      errors: Object,
    };
  }

  static createModel() {
    return {
      license: '',
      state: '',
    };
  }

  static createSelectors() {
    return {
      children: {
        state: [isRequiredIf('license')],
      },
    };
  }

  initState() {
    super.initState();
    this.model = {
      license: '',
      state: '',
    };

    this.errors = {
      license: '',
      state: '',
    };
  }

  renderLeftContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.leftContent.id}"
        class="field"
        maxLength="50"
        name="license"
        label="License Number"
        helper=" "
        .value="${this.model.license}"
        .error="${this.errors.license}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  renderRightContent() {
    return html`
      <neb-select
        id="${ELEMENTS.rightContent.id}"
        class="field field-selector"
        name="state"
        label="License State"
        helper=" "
        .items="${STATES}"
        .value="${this.model.state}"
        .error="${this.errors.state}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }
}

window.customElements.define('neb-license-item', LicenseItem);
