import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const getAbandonedCount = () => {
  const path = 'abandoned-responses/count';

  return apiClient.makeRequest({
    path,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 2,
    cacheKey: path,
  });
};

export const getAbandonedResponses = () => {
  const path = 'abandoned-responses';

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 2,
    cacheKey: path,
  });
};

export const dismissAbandonedResponse = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `abandoned-responses/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const linkAbandonedResponse = (id, data = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `/abandoned-claim-status/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.PUT,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
