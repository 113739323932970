import { getEncounterCharges } from '../../charting/encounter-charge';
import { updateEncounterCharges } from '../../encounters-api-client';

export default async (encounterId, patientId) => {
  const savedCharges = await getEncounterCharges(encounterId);
  await updateEncounterCharges({
    patientId,
    encounterId,
    savedLineItems: [],
    charges: savedCharges.map(charge => ({ ...charge, posted: true })),
  });
};
