import cheerio from 'cheerio';

import { BLANK_LINE } from '../../../../neb-api-client/src/notes';
import { TAB_KEYS } from '../../../../neb-utils/macro-sets';
import {
  TOKEN_KEY,
  getTokenReplacement,
} from '../../macro-processor/token-replacement';
import { buildTokenTable } from '../shared/util/notes-token-table-builder';

export const initNotesState = () => ({
  [TAB_KEYS.SUBJECTIVE]: '',
  [TAB_KEYS.OBJECTIVE]: '',
  [TAB_KEYS.ASSESSMENT]: '',
  [TAB_KEYS.PLAN]: '',
});
export const MODE_OVERLAY = {
  NONE: 'none',
  SAVING_NOTES: 'saving notes',
  LOADING_NOTES: 'loading notes',
  LOADING: 'loading',
};
const TABKEY_INDEXES = {
  [TAB_KEYS.SUBJECTIVE]: 0,
  [TAB_KEYS.OBJECTIVE]: 1,
  [TAB_KEYS.ASSESSMENT]: 2,
  [TAB_KEYS.PLAN]: 3,
};

const parseLinks = (notes, attribute, handler) =>
  Object.entries(notes)
    .sort(([keyA], [keyB]) => TABKEY_INDEXES[keyA] - TABKEY_INDEXES[keyB])
    .map(([key, value]) => {
      const $ = cheerio.load(value);
      return $('body')
        .find(`span[${attribute}], div[${attribute}]`)
        .map((_, elem) => handler($, key, elem));
    });

export const parseFields = notes => {
  const result = [];
  parseLinks(notes, 'data-field', ($, key, elem) => {
    result.push({
      field: $(elem).attr('data-field'),
      section: key,
    });
  });

  return result;
};

export const parseQuestions = notes => {
  const result = [];
  parseLinks(notes, 'data-question', ($, key, elem) => {
    const json = $(elem).attr('data-question');
    const data = JSON.parse(json);

    if (data.answer.text) {
      result.push({
        section: key,
        question: data.question,
        answer: data.answer,
      });
    }
  });

  return result;
};

const getOffsetIndex = (notes, elements, index) => {
  const targetSection = elements[index].section;

  let offsetIndex = index;
  Object.values(TAB_KEYS)
    .sort(([keyA], [keyB]) => TABKEY_INDEXES[keyA] - TABKEY_INDEXES[keyB])
    .some(value => {
      const result = value === targetSection;

      if (!result) {
        offsetIndex -= elements.filter(
          ({ section }) => section === value,
        ).length;
      }

      return result;
    });

  return offsetIndex;
};

const replaceLink = (notes, attribute, elements, index, editorFunc) => {
  const targetSection = elements[index].section;
  const offsetIndex = getOffsetIndex(notes, elements, index);
  const $ = cheerio.load(notes[targetSection]);
  editorFunc(
    $('body').find(`span[${attribute}], div[${attribute}]`).eq(offsetIndex),
  );

  const resultNotes = { ...notes };
  resultNotes[targetSection] = $('body')
    .html()
    .replace(/&#xA0;/g, '&nbsp;');

  return resultNotes;
};

export const replaceQuestion = (notes, question, index) =>
  replaceLink(notes, 'data-question', parseQuestions(notes), index, elem => {
    if (!question.answer) {
      elem.remove();
    } else {
      elem
        .attr('data-question', JSON.stringify(question))
        .attr('style', '')
        .text(question.answer.text);
    }
  });

export const replaceField = async (notes, index, encounter) => {
  const fields = parseFields(notes);
  const currentField = fields[index].field;
  const tokenReplacement = (await getTokenReplacement(encounter, true))[
    currentField
  ];

  let noteReplacement = '&nbsp;';

  if (tokenReplacement) {
    switch (currentField) {
      case TOKEN_KEY.CURRENT_LISTINGS:
        noteReplacement = buildTokenTable(
          tokenReplacement,
          TOKEN_KEY.CURRENT_LISTINGS,
          false,
        );

        break;

      case TOKEN_KEY.CURRENT_TX:
        noteReplacement = buildTokenTable(
          tokenReplacement,
          TOKEN_KEY.CURRENT_TX,
          false,
        );

        break;

      case TOKEN_KEY.CURRENT_DX:
        noteReplacement = buildTokenTable(
          tokenReplacement,
          TOKEN_KEY.CURRENT_DX,
          false,
        );

        break;

      default:
        noteReplacement = tokenReplacement;
        break;
    }
  }

  return replaceLink(notes, 'data-field', fields, index, elem => {
    elem.attr('style', '').html(noteReplacement);
  });
};

export const replaceAllFields = (notes, encounter) => {
  const fields = parseFields(notes);
  return fields.reduce(async (promisedNotes, _, index) => {
    const notesA = await promisedNotes;
    return replaceField(notesA, index, encounter);
  }, Promise.resolve(notes));
};

export const insertOrReplaceTable = (initialNote, note, tableType) => {
  const $ = cheerio.load(initialNote);
  const noteToUpdate = cheerio.load(note);
  const table = $(`p[data-table-header="true"], *[data-type="${tableType}"]`);

  if (
    noteToUpdate('body').find(
      `p[data-table-header="true"], *[data-type="${tableType}"]`,
    ).length
  ) {
    noteToUpdate('body')
      .find(`p[data-table-header="true"], *[data-type="${tableType}"]`)
      .replaceWith(table)
      .html();
  } else if (table.html()) {
    if (!note) {
      noteToUpdate('body').prepend(BLANK_LINE);
    }
    noteToUpdate('body').prepend(table).prepend(BLANK_LINE).html();
  }

  return noteToUpdate('body')
    .html()
    .replace(/&#xA0;/g, '&nbsp;')
    .replace(/&apos;/g, "'");
};
