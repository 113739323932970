import '../../components/misc/neb-icon';
import './neb-fiserv-iframe';
import { openPopup } from '@neb/popup';
import { LitElement, css, html, nothing } from 'lit';
import moment from 'moment-timezone';

import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import { PAYMENT_RESULT_CONFIG } from '../../../packages/neb-lit-components/src/components/patients/payment/neb-patient-online-payment-payfields-form';
import { openDirtyPopup } from '../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import { CODE_PAYMENTS } from '../../../packages/neb-utils/constants';
import { PAYMENT_METHODS } from '../../../packages/neb-utils/enums';
import { currencyToCents } from '../../../packages/neb-utils/formatters';
import { postPayment } from '../../api-clients/booking/booking-patient';

import { FISERV_RESPSTAT, createFiservPayment } from './fiserv-api-client';
import { formatCreditCardDescription } from './utils';

export const ELEMENTS = {
  closeButton: {
    id: 'close-button',
  },
  textHeader: {
    id: 'text-header',
  },
  textSubheader: {
    id: 'text-subheader',
  },
  textAmount: {
    id: 'text-amount',
  },
  fiservIframe: {
    id: 'fiserv-iframe',
  },
};
class NebPayfacFiservBooking extends LitElement {
  static get properties() {
    return {
      layout: { type: String, reflect: true },
      __processing: Boolean,
      __dirty: Boolean,
      __token: String,
      __expiry: String,
      title: String,
      subheader: String,
      message: String,
      confirmLabel: String,
      amount: String,
      merchantAccount: Object,
      billingAddress: Object,
      practiceInfo: Object,
      patient: Object,
    };
  }

  static get styles() {
    return css`
      .container-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 20px;
      }

      .header {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
      }

      .text-header {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        padding-bottom: ${CSS_SPACING};
      }

      .text-message {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
        font-weight: bold;
      }

      .text-subheader {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
      }

      .icon-close {
        height: 24px;
        width: 24px;
        fill: ${CSS_COLOR_GREY_1};
        padding: 0;
        cursor: pointer;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__processing = false;
    this.__dirty = false;
    this.__token = '';
    this.__expiry = '';

    this.title = '';
    this.subheader = '';
    this.message = '';
    this.confirmLabel = '';

    this.amount = '$0.00';

    this.merchantAccount = {};
    this.billingAddress = {};
    this.patient = {};
    this.practiceInfo = {};

    this.onBack = () => {};

    this.onSave = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      submit: async ({ token, expiry }) => {
        this.__token = token;
        this.__expiry = expiry;

        await this.__createPayment();
      },
      back: async () => {
        if (!this.__dirty) {
          this.onBack();
          return;
        }

        if (await openDirtyPopup()) {
          this.onBack();
        }
      },
      setProcessing: processing => {
        this.__processing = processing;
      },
      setDirty: dirty => {
        this.__dirty = dirty;
      },
    };
  }

  async __createPayment() {
    const payload = {
      merchantAccountId: this.merchantAccount.id,
      amount: currencyToCents(this.amount),
      postal: this.patient.zipcode,
      address: this.patient.address1,
      address2: this.patient.address2,
      city: this.patient.city,
      region: this.patient.state,
      firstName: this.patient.firstName,
      lastName: this.patient.lastName,
      holderId: this.patient.patientId,
      holderType: 'patient',
      logContent: {
        action: 'payment - createPayment - neb-fiserv-booking-payfac',
        rawModel: {
          transactionAmount: `${currencyToCents(this.amount)}`,
          holderId: this.patient.patientId,
        },
      },
      expiry: this.__expiry,
      token: this.__token,
      ecomind: 'E',
    };

    let result;

    try {
      result = await createFiservPayment(payload);
    } catch {
      await openPopup(
        POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS,
        PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
      );

      return;
    }

    if (result.respstat !== FISERV_RESPSTAT.APPROVED) {
      await openPopup(
        POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS,
        PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
      );

      return;
    }

    const postPaymentPayload = {
      electronicPaymentId: result.id,
      cardSaleId: result.saleId,
      electronicReferenceId: result.referenceId,
      maskedCardDescription: formatCreditCardDescription(
        result.maskedCardNumber,
      ),
      codePaymentId: CODE_PAYMENTS.GENERAL_PAYMENT.id,
      amount: currencyToCents(this.amount),
      transactionDate: moment().startOf('day').toISOString(),
      patientId: this.patient.patientId,
      paymentMethod: PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD,
    };

    try {
      const postPaymentResponse = await postPayment(postPaymentPayload);

      store.dispatch(openSuccess('Payment completed successfully'));

      await openPopup(POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
        patient: this.patient,
        practiceInfo: this.practiceInfo,
        billingAddress: this.billingAddress,
        payment: {
          ...postPaymentResponse,
          appointmentId: null,
          codePayment: CODE_PAYMENTS.GENERAL_PAYMENT,
          dateOfServiceFrom: null,
          dateOfServiceTo: null,
          payerPlanId: null,
          refundPayment: null,
          refundedAt: null,
          transactionDate: moment(postPaymentResponse.transactionDate),
        },
      });
    } catch (e) {
      store.dispatch(openError('Payment unsuccessful', e));
      return;
    }

    this.onSave(true);
  }

  __renderCloseButton() {
    if (this.__processing) {
      return nothing;
    }

    return html`
      <neb-icon
        id="${ELEMENTS.closeButton.id}"
        class="icon-close"
        icon="neb:close"
        @click="${this.__handlers.back}"
      ></neb-icon>
    `;
  }

  __renderHeader() {
    return html`
      <div class="header spacer">
        <div class="text-header" id="${ELEMENTS.textHeader.id}">
          ${this.title}
        </div>
        ${this.__renderCloseButton()}
      </div>
    `;
  }

  __renderSubheader() {
    if (this.__processing) {
      return nothing;
    }

    return html`
      <div class="text-subheader" id="${ELEMENTS.textSubheader.id}">
        ${this.subheader}
      </div>
      <div class="text-message" id="${ELEMENTS.textAmount.id}">
        ${this.message}: ${this.amount}
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderHeader()}
      <div class="container-content">
        ${this.__renderSubheader()}
        <neb-fiserv-iframe
          id="${ELEMENTS.fiservIframe.id}"
          .layout="${this.layout}"
          .confirmLabel="${'Submit'}"
          .cancelLabel="${'Cancel'}"
          .isProcessing="${this.__processing}"
          .onProcessing="${this.__handlers.setProcessing}"
          .onDirty="${this.__handlers.setDirty}"
          .onConfirm="${this.__handlers.submit}"
          .onCancel="${this.__handlers.back}"
        >
        </neb-fiserv-iframe>
      </div>
    `;
  }
}

customElements.define('neb-payfac-fiserv-booking', NebPayfacFiservBooking);
