import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  DOCUMENT_TAGS_REPLACE_POPUP_TITLE,
  DOCUMENT_TAGS_REPLACE_POPUP_MESSAGE,
} from '../../../utils/user-message';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textMessage: {
    id: 'text-message',
  },
  selectDocumentTag: {
    id: 'select-document-tag',
  },
};

class NebPopupReplaceDocumentTag extends NebPopup {
  static get properties() {
    return {
      __selectedItem: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 450px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: ${CSS_SPACING};
        }

        .button:last-child {
          margin-left: 10px;
        }

        .message {
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedItem = '';

    this.title = DOCUMENT_TAGS_REPLACE_POPUP_TITLE();

    this.model = {
      items: [],
    };
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.__handlers,
      confirm: () => {
        this.onClose(this.__selectedItem);
      },
      cancel: () => this.onClose(false),
      change: ({ value }) => {
        this.__selectedItem = value;
      },
    };
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${DOCUMENT_TAGS_REPLACE_POPUP_MESSAGE()}
        </div>

        <neb-select
          id="${ELEMENTS.selectDocumentTag.id}"
          helper=" "
          .items="${this.model.items}"
          .value="${this.__selectedItem}"
          .onChange="${this.__handlers.change}"
        ></neb-select>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="OK"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="CANCEL"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-replace-document-tag',
  NebPopupReplaceDocumentTag,
);
