import '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-macro-answer-preview';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

const ID_BUTTON_PREFIX = 'input-button-';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  inputButtons: {
    selector: '.grid-item',
  },

  oneButton: { id: `${ID_BUTTON_PREFIX}number-1` },
  twoButton: { id: `${ID_BUTTON_PREFIX}number-2` },
  threeButton: { id: `${ID_BUTTON_PREFIX}number-3` },
  fourButton: { id: `${ID_BUTTON_PREFIX}number-4` },
  fiveButton: { id: `${ID_BUTTON_PREFIX}number-5` },
  sixButton: { id: `${ID_BUTTON_PREFIX}number-6` },
  sevenButton: { id: `${ID_BUTTON_PREFIX}number-7` },
  eightButton: { id: `${ID_BUTTON_PREFIX}number-8` },
  nineButton: { id: `${ID_BUTTON_PREFIX}number-9` },
  zeroButton: { id: `${ID_BUTTON_PREFIX}number-0` },
  dashButon: { id: `${ID_BUTTON_PREFIX}dash` },
  dotButton: { id: `${ID_BUTTON_PREFIX}dot` },
  commaButton: { id: `${ID_BUTTON_PREFIX}comma` },
  dollarButton: { id: `${ID_BUTTON_PREFIX}dollar` },
  deleteButton: { id: `${ID_BUTTON_PREFIX}delete` },
  clearButton: { id: `${ID_BUTTON_PREFIX}clear` },
};

const VALID_KEYS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  ',',
  '-',
  '$',
  'Backspace',
  'Clear',
  'Delete',
];

class NebPopupMacrosNumericEntry extends NebPopupMacros {
  static get styles() {
    return [
      super.styles,
      css`
        .grid-container {
          display: grid;
          grid-template-columns: 79px 79px 79px auto 79px 79px;
          grid-template-rows: repeat(4, 79px);
          grid-column-gap: 15px;
          grid-row-gap: 15px;
          margin-bottom: ${CSS_SPACING};
        }

        .grid-item {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
          border-radius: 5px;
          font-family: 'Open Sans';
          font-weight: 500;
          font-size: 14pt;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .grid-item:focus {
          outline: none;
          box-shadow: 0 0 3px ${CSS_COLOR_HIGHLIGHT};
        }

        .double-grid-item {
          grid-column: 5 / 7;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      click: ({ currentTarget }) => {
        currentTarget.blur();

        this.__handleInput(currentTarget.innerText);
      },

      keyDown: e => {
        if (this.__isValidInput(e.key) && !this.previewFocused) {
          e.preventDefault();
          e.stopPropagation();

          this.__handleInput(e.key);
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('keydown', this.handlers.keyDown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('keydown', this.handlers.keyDown);
  }

  __isValidInput(value) {
    return VALID_KEYS.indexOf(value) !== -1;
  }

  __handleInput(input) {
    const shouldDelete = ['Delete', 'Backspace'].indexOf(input) !== -1;

    if (shouldDelete) {
      return this.handlers.change(this.answer.slice(0, -1));
    }

    if (input === 'Clear') {
      return this.handlers.change('');
    }

    return this.handlers.change(this.answer.concat(input));
  }

  __buttonTemplate({ id, value, className }) {
    return html`
      <div
        class="${className || ''} grid-item"
        id="${id}"
        type="button"
        @click="${this.handlers.click}"
        tabindex="0"
      >
        ${value}
      </div>
    `;
  }

  renderMacroContent() {
    return html`
      <div class="grid-container">
        ${this.__buttonTemplate({
          id: ELEMENTS.oneButton.id,
          value: '1',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.twoButton.id,
          value: '2',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.threeButton.id,
          value: '3',
        })}
        <div></div>
        ${this.__buttonTemplate({
          id: ELEMENTS.dashButon.id,
          value: '-',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.dotButton.id,
          value: '.',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.fourButton.id,
          value: '4',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.fiveButton.id,
          value: '5',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.sixButton.id,
          value: '6',
        })}
        <div></div>
        ${this.__buttonTemplate({
          id: ELEMENTS.commaButton.id,
          value: ',',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.dollarButton.id,
          value: '$',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.sevenButton.id,
          value: '7',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.eightButton.id,
          value: '8',
        })}
        ${this.__buttonTemplate({
          id: ELEMENTS.nineButton.id,
          value: '9',
        })}
        <div></div>
        ${this.__buttonTemplate({
          id: ELEMENTS.clearButton.id,
          value: 'Clear',
          className: 'double-grid-item',
        })}
        <div></div>
        ${this.__buttonTemplate({
          id: ELEMENTS.zeroButton.id,
          value: '0',
        })}
        <div></div>
        ${this.__buttonTemplate({
          id: ELEMENTS.deleteButton.id,
          value: 'Backspace',
          className: 'double-grid-item',
        })}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macros-numeric-entry',
  NebPopupMacrosNumericEntry,
);
