import '../../neb-popup-header';
import '../../tables/neb-table-payer-plans';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import {
  getOverlayTitle,
  PAYER_GROUP,
} from '../../../../../../src/utils/user-message';
import { savePayerGroup } from '../../../../../neb-api-client/src/billing/payer-groups';
import { getPayerPlans } from '../../../../../neb-api-client/src/payer-plan-api-client';
import {
  openError,
  openSuccess,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import NebFormPayerGroups from '../../forms/neb-form-payer-groups';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

const BANNER_MESSAGE = {
  success: 'Payer Group saved successfully',
  error: 'An error occurred when saving the Payer Group',
};

class NebOverlayPayerGroups extends Overlay {
  static get properties() {
    return {
      __payers: Array,
      __payerGroup: Object,
      __savingError: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }

        .container {
          display: flex;
          flex-direction: column;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__payers = [];
    this.__payerGroup = NebFormPayerGroups.createModel();
    this.__savingError = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      savePayerGroup: async model => {
        try {
          this.__savingError = false;
          const {
            data: [savedPayerGroup],
          } = await savePayerGroup({
            ...model,
            payerPlanIds: model.payerPlanIds || [],
          });

          store.dispatch(openSuccess(BANNER_MESSAGE.success));
          this.isDirty = false;
          this.handlers.dismiss(savedPayerGroup);
        } catch (err) {
          this.__savingError = true;
          if (err.message.includes('409')) {
            await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Duplicate Name',
              message: `There is an existing record matching this Payer Groups name.
                Please review record details and create new or update existing payer group accordingly.`,
            });
          } else store.dispatch(openError(BANNER_MESSAGE.error));
        }
      },
    };
  }

  async __getPayers() {
    const { id: payerGroupId } = this.__payerGroup;
    const { payerPlan } = await getPayerPlans({
      payerGroupAvailableOnly: true,
      ...(payerGroupId && { payerGroupId }),
    });

    this.__payers = payerPlan;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      this.__payerGroup = {
        ...this.__payerGroup,
        ...this.model.payerGroup,
      };

      await this.__getPayers();
    }

    super.updated(changedProps);
  }

  renderContent() {
    const title = getOverlayTitle(this.__payerGroup, PAYER_GROUP);
    return html`
      <div class="container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          .title="${title}"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>
        <neb-form-payer-groups
          id="${ELEMENTS.form.id}"
          class="container"
          .model="${this.__payerGroup}"
          .payers="${this.__payers}"
          .layout="${this.layout}"
          .onChangeDirty="${this.handlers.dirty}"
          .onCancel="${this.handlers.dismiss}"
          .onSave="${this.handlers.savePayerGroup}"
          .savingError="${this.__savingError}"
        ></neb-form-payer-groups>
      </div>
    `;
  }
}

customElements.define('neb-overlay-payer-groups', NebOverlayPayerGroups);
