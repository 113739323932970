import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  textField: { id: 'text-field' },
  dropdown: { id: 'dropdown' },
};

class NebPopupClaimDropdownTextInput extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .text-container {
          display: grid;
          margin-bottom: 40px;
          grid-template-columns: auto auto;
          grid-column-gap: ${CSS_SPACING};
        }

        .flex-1 {
          flex: 1 0 0;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      renderItem: item =>
        item.label &&
        html`
          <p style="padding: 0 16px; width: max-content; max-width: 440px">
            ${item.label}
          </p>
        `,
    };
  }

  renderContent() {
    return html`
      <div class="text-container">
        <neb-select
          id="${ELEMENTS.dropdown.id}"
          class="flex-1"
          showFullText
          .name="${this.model.items[0].name}"
          .label="${this.model.items[0].label}"
          .value="${this.__state[this.model.items[0].name]}"
          .items="${this.model.items[0].items}"
          .onChange="${this.handlers.change}"
          .onRenderItem="${this.handlers.renderItem}"
        >
        </neb-select>
        <neb-textfield
          id="${ELEMENTS.textField.id}"
          class="flex-1"
          style="width: ${this.model.items[1].width}"
          .name="${this.model.items[1].name}"
          .label="${this.model.items[1].label}"
          .maxLength="${this.model.items[1].maxLength}"
          .value="${this.__state[this.model.items[1].name]}"
          .mask="${this.model.items[1].mask}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-dropdown-text-input',
  NebPopupClaimDropdownTextInput,
);
