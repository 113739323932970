import '../../../../../neb-material-design/src/components/neb-md-textfield';
import '../../../../../neb-material-design/src/components/neb-md-select';

import { html } from 'lit';

const renderDefaultEditable = ({ meta, value, onChange }) =>
  meta.input.renderer.render({
    meta,
    value,
    onChange,
  });

const renderDefaultReadOnly = ({ meta, value, validationNote }) => html`
  <div
    id="${meta.id}-cell-container"
    class="neb-table-cell ${meta.cellWidth}"
    ?invalid="${validationNote}"
  >
    <div id="${meta.id}" class="cell-value">${value}</div>
    ${validationNote
      ? html`
          <div id="${meta.id}-invalid-message" class="invalid-message">
            ${validationNote}
          </div>
        `
      : ''}
  </div>
`;

export const DEFAULT_CELL_RENDERER = Object.freeze({
  render: ({ editable, meta, value, onChange, validationNote }) => {
    if (editable) {
      return renderDefaultEditable({
        meta,
        value,
        onChange,
      });
    }
    return renderDefaultReadOnly({
      meta,
      value,
      onChange,
      validationNote,
    });
  },
});

export const ERROR_REQUIRED = 'Required';
export const InputRenderer = Object.freeze({
  text: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        helperText=" "
        .labelText="${meta.column}"
        .value="${value}"
        .required="${meta.input.options.required}"
        .errorText="${meta.input.options.required ? ERROR_REQUIRED : ''}"
        .onChange="${onChange}"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  date: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        helperText="MM/DD/YYYY"
        .value="${value}"
        .onChange="${onChange}"
        .required="${meta.input.options.required}"
        .errorText="${meta.input.options.required ? ERROR_REQUIRED : ''}"
        maxLength="10"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  ssn: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .value="${value}"
        .onChange="${onChange}"
        errorText="###-##-####"
        helperText=" "
        maxLength="11"
        validator="isSSN"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  number: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .value="${value}"
        .onChange="${onChange}"
        errorText="Must be a number"
        helperText=" "
        validator="isNumeric"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  singleSelect: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-select
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .items="${meta.input.options.items}"
        .value="${value}"
        .onChange="${onChange}"
        .dataProperty="${meta.data}"
      ></neb-md-select>
    `,
  },
  phoneNumber: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .value="${value}"
        .onChange="${onChange}"
        errorText="(222) 222-2222"
        helperText=" "
        maxLength="14"
        validator="isMobilePhone"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  email: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .value="${value}"
        .onChange="${onChange}"
        errorText="example@mail.com"
        helperText=" "
        validator="isEmail"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
  postalCode: {
    render: ({ meta, value, onChange }) => html`
      <neb-md-textfield
        id="${meta.id}"
        class="neb-table-cell ${meta.cellWidth}"
        .labelText="${meta.column}"
        .value="${value}"
        errorText="#####-####"
        helperText=" "
        .onChange="${onChange}"
        maxLength="10"
        validator="isPostalCode"
        .dataProperty="${meta.data}"
      ></neb-md-textfield>
    `,
  },
});
