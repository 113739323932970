import '../controls/neb-button-action';
import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../field-groups/neb-modifiers';

import { html, css, unsafeCSS } from 'lit';

import { CONFIG as CONFIG_ADJUSTMENTS } from '../../../../../src/components/tables/charges/neb-table-charges-adjustments';
import { ChargeResponsibilityTable } from '../../../../../src/components/tables/charges/neb-table-charges-responsibility';
import {
  formatBilled,
  formatHold,
} from '../../../../../src/formatters/line-items';
import {
  CSS_SPACING,
  CSS_FIELD_MARGIN,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  capitalize,
  objToName,
  centsToCurrency,
  currencyToCents,
  currencyToCentsWithNegative,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import {
  selectedChargesHasErrors,
  flattenErrors,
} from '../../../../neb-utils/neb-ledger-util';
import { mapPatientName } from '../../../../neb-utils/patient';
import { summate, map } from '../../../../neb-utils/utils';
import { CONFIG as CONFIG_ERRORS } from '../field-groups/neb-errors-cell';
import { NebPrimaryPayerCell } from '../field-groups/neb-primary-payer-cell';

import Table, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  checkboxes: { selector: '[id^=checkbox-]', tag: 'neb-checkbox' },
  errorsCells: { selector: '[id^=errors-cell-]', tag: 'neb-errors-cell' },
  providerModifiersCells: { selector: '[id^=provider-modifiers-cell-]' },
  allowedAmountCells: {
    selector: '[id^=allowed-amount-cell-]',
    tag: 'neb-textfield',
  },
  primaryCells: {
    selector: '[id^=primary-cell-]',
    tag: 'neb-primary-payer-cell',
  },
  responsibilitiesTables: {
    selector: '[id^=responsibilities-cell-]',
    tag: 'neb-table-charges-responsibility',
  },
  adjustmentsTables: {
    selector: '[id^=adjustment-cell-]',
    tag: 'neb-table-charges-adjustments',
  },
  balanceCells: { selector: '[id^=balance-cell-]' },
  encounterLinks: { selector: '[id^=encounter-]' },
  chargeLinks: { selector: '[id^=charge-]' },
  patientCells: { selector: '[id^=patient-cell-]' },
  addLineItemDebitButtons: { selector: '[id^=add-line-item-debit-button-]' },
  bulkActionMenu: { id: 'bulk-action-menu' },
};

const CONFIG_KEYS = {
  ERRORS: 'errors',
  CHARGE_ENCOUNTER: 'chargeEncounter',
  PROVIDER_MODIFIERS: 'providerModifiers',
  UNITS_TAX: 'unitsTax',
  PRIMARY_PAYER: 'primaryPayer',
  LINE_ITEM_DEBITS: 'lineItemDebits',
  ADJUSTMENTS: 'adjustments',
  BALANCE: 'balance',
};

const REMOVE_SPACER = {
  label: '',
  width: 20,
};

const buildConfig = hidePatient => [
  {
    key: CONFIG_KEYS.ERRORS,
    config: CONFIG_ERRORS,
  },
  {
    key: 'chargeEncounter',
    config: [
      {
        key: 'checked',
        label: '',
        width: 20,
      },
      {
        label: 'Charge ID',
        width: 96,
      },
      {
        label: 'Billed',
        width: 96,
      },
      {
        label: 'Hold',
        width: 96,
      },
      {
        label: 'MRN',
        width: 135,
      },
      ...(!hidePatient
        ? [
            {
              label: 'Patient',
              width: 96,
            },
          ]
        : []),
      {
        label: 'Encounter',
        width: 96,
      },
    ],
  },
  {
    key: 'providerModifiers',
    config: [
      {
        label: 'Provider',
        width: 96,
      },
      {
        label: 'DOS',
        width: 96,
      },
      {
        label: 'Proc + Mod',
        width: 145,
      },
    ],
  },
  {
    key: 'unitsTax',
    config: [
      {
        label: 'Units',
        width: 80,
      },
      {
        label: 'Billed',
        width: 80,
      },
      {
        label: 'Tax',
        width: 80,
      },
      {
        label: 'Allowed',
        width: 94,
      },
    ],
  },
  {
    key: 'primaryPayer',
    config: null,
  },
  {
    key: 'lineItemDebits',
    config: null,
  },
  {
    key: 'adjustments',
    config: null,
  },
  {
    key: 'balance',
    config: [
      {
        label: 'Balance',
        width: 80,
      },
    ],
  },
];

function flexBasisToWidth(flex) {
  const basis = flex.cssText.split(' ')[2];
  return Number(basis.replace('px', ''));
}

class AllocationChargesTable extends Table {
  static get properties() {
    return {
      allocatableId: String,
      patientId: String,
      itemsMap: Object,
      preallocated: Array,
      selectIndexes: Array,
      isPayerPaymentSelected: { type: Boolean, reflect: true },
      disabled: { type: Boolean, reflect: true },
      showPatient: Boolean,
      payers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding: ${CSS_SPACING} 0;
          --table-padding-left: ${CSS_SPACING};
        }

        .grid-3 {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-rows: min-content;
          grid-column: span 5;
        }

        .content {
          align-items: stretch;
        }

        .cell {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-auto-rows: min-content;
          align-items: center;
        }

        .cell-data {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .cell-data:last-child {
          border-right: none;
        }

        .cell-data[key='errors'] {
          display: flex;
        }

        .cell-sub {
          display: flex;
          height: 72px;
          align-items: center;
        }

        .cell-name {
          word-break: break-word;
        }

        .span-3 {
          grid-column: span 3;
        }

        .span-4 {
          grid-column: span 4;
        }

        .span-6 {
          grid-column: span 6;
        }

        .span-10 {
          grid-column: span 10;
        }

        .button-add {
          margin-top: 22px;
          margin-bottom: ${CSS_FIELD_MARGIN};
        }

        .button-add[showSecondary] {
          grid-column: 8;
        }

        .text-bold {
          font-weight: 700;
        }

        .grid-column-2 {
          grid-column: 2 / span 1;
        }

        .row-header,
        .row-data {
          padding-left: var(--table-padding-left);
        }

        .link {
          cursor: pointer;
          text-decoration: underline;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .ellipsis {
          padding-right: 20px;
          font-weight: normal;
        }
      `,
      ...buildConfig(false).map(
        item => css`
          .cell[key='${unsafeCSS(item.key)}'] {
            grid-template-columns: var(--columns-${unsafeCSS(item.key)});
          }
        `,
      ),
    ];
  }

  initState() {
    super.initState();

    this.writable = true;
    this.allocatableId = null;
    this.patientId = null;
    this.itemsMap = [];
    this.selectIndexes = [];
    this.isPayerPaymentSelected = false;
    this.disabled = false;
    this.showPatient = false;
    this.payers = [];

    this.onAdd = () => {};

    this.onItemCheck = () => {};

    this.onBlur = () => {};

    this.onSelectEncounter = () => {};

    this.onSelectCharge = () => {};

    this.onMinWidthUpdated = () => {};

    this.onSelectAll = () => {};

    this.onDeselectAll = () => {};

    this.onSelectPayerPlan = () => {};

    this.onClickPaymentId = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: e => this.onAdd(e.name),
      removeDebit: (path, item, index = -1) => {
        const rowIndex = path.split('.')[0];
        const newIndex = this.model[rowIndex].primaryPayerId
          ? index + 1
          : index;
        this.onRemove(path, newIndex);
      },
      removeAdjustment: (path, item, index = -1) => {
        this.onRemove(path, index);
      },
      checkItem: e => {
        if (!this.disabled) {
          const index = Number(e.currentTarget.getAttribute('index'));
          const { checked } = e.currentTarget;
          this.onItemCheck(index, checked);
        }
      },
      blur: e => this.onBlur(e),
      selectEncounter: e => {
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        const { encounterId, appointmentTypeId } =
          this.model[rowIndex].encounterCharge;
        this.onSelectEncounter(encounterId, appointmentTypeId, patientId);
      },
      selectCharge: e => {
        const rowIndex = e.currentTarget.getAttribute('index');
        const { id, invoiceId, patientId } = this.model[rowIndex];
        this.onSelectCharge(id, invoiceId, patientId);
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.onSelectAll,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.onDeselectAll,
        },
      ],
    };
  }

  __buildSubConfig(subConfig) {
    return [
      ...subConfig.map(item => ({
        label: item.label,
        width: item.width || flexBasisToWidth(item.flex),
      })),
      REMOVE_SPACER,
    ];
  }

  __hasErrors() {
    return selectedChargesHasErrors(this.errors, this.selectIndexes);
  }

  __computeWidths() {
    const PADDING = 20;
    const rawConfigs = {
      primaryPayer: NebPrimaryPayerCell.buildConfig(true),
      lineItemDebits: ChargeResponsibilityTable.buildConfig({
        showSecondary: this.showSecondaryPayers(),
        showAllocated: true,
        model: [],
      }),
      adjustments: CONFIG_ADJUSTMENTS,
    };

    this.config = buildConfig(!this.showPatient)
      .filter(item => {
        if (item.key === 'errors') {
          this.style.setProperty('--table-padding-left', 0);
        }
        return true;
      })
      .map(item =>
        !item.config
          ? { ...item, config: this.__buildSubConfig(rawConfigs[item.key]) }
          : item,
      )
      .map(item => {
        const widths = item.config.map(item => item.width);
        const columns = widths.map(item => `${item}px`).join(' ');
        this.style.setProperty(`--columns-${item.key}`, columns);
        return {
          ...item,
          flex: css`0 0 ${unsafeCSS(summate(widths) + PADDING)}px`,
        };
      });

    const widths = this.config.map(item => flexBasisToWidth(item.flex));
    const rawWidth = summate(widths) + PADDING * 2;
    const width = Math.round(rawWidth * 100) / 100;
    this.style.setProperty('width', `${width}px`);
    this.onMinWidthUpdated(width);
  }

  __debitInsuranceMatchesRowInsurance({ patientInsuranceId }, row) {
    return (
      patientInsuranceId.data.id &&
      patientInsuranceId.data.id !== row.primaryInsuranceId
    );
  }

  getSecondaryPlanItems(li) {
    return this.itemsMap && this.itemsMap.insurances
      ? this.itemsMap.insurances.filter(
          item =>
            item &&
            item.data &&
            item.data.active &&
            item.data.id !== li.primaryInsuranceId &&
            item.data.patientId === li.patientId,
        )
      : [];
  }

  showSecondaryPayers() {
    return this.model.some((li, index) =>
      li.lineItemDebits.some(
        lid =>
          lid.debit.payerId &&
          (lid.debit.payerId !== li.primaryPayerId ||
            this.__debitInsuranceMatchesRowInsurance(lid, this.model[index])),
      ),
    );
  }

  __checkAllocatableByGroup(payerId) {
    const { payerGroupId: allocatableGroupId = '' } = {
      ...this.payers.find(({ id }) => id === this.allocatableId),
    };

    const { payerGroupId = '' } = {
      ...this.payers.find(({ id }) => id === payerId),
    };

    return !!allocatableGroupId && payerGroupId === allocatableGroupId;
  }

  update(changedProps) {
    if (changedProps.has('model') || changedProps.has('errors')) {
      this.__computeWidths();
    }

    super.update(changedProps);
  }

  __renderErrorsCell(data_, errors_, name, rowIndex) {
    const isRowSelected = this.selectIndexes[rowIndex];
    const errors = flattenErrors(this.errors[rowIndex]);

    return isRowSelected
      ? html`
          <neb-errors-cell
            id="errors-cell-${rowIndex}"
            .name="${name}"
            .errors="${errors}"
          ></neb-errors-cell>
        `
      : '';
  }

  __renderPatientName(data, name, rowIndex) {
    return this.showPatient
      ? html`
          <span class="cell-sub cell-name" id="patient-cell-${rowIndex}"
            >${this.model[rowIndex].patient &&
            objToName(mapPatientName(this.model[rowIndex].patient), {
              reverse: true,
              middleInitial: true,
            })}</span
          >
        `
      : '';
  }

  __renderChargeEncounterCell(data, _, name, rowIndex) {
    return html`
      <neb-checkbox
        id="checkbox-${rowIndex}"
        class="cell-sub"
        index="${rowIndex}"
        .name="${name}"
        ?checked="${this.selectIndexes[rowIndex]}"
        ?disabled="${this.disabled}"
        @click="${this.handlers.checkItem}"
      ></neb-checkbox>

      <span
        class="cell-sub link"
        id="charge-${rowIndex}"
        index="${rowIndex}"
        @click="${this.handlers.selectCharge}"
        >${this.model[rowIndex].chargeNumber}</span
      >

      ${this.__renderChargeStatus(rowIndex)}

      <span class="cell-sub"
        >${this.model[rowIndex].patient &&
        this.model[rowIndex].patient.medicalRecordNumber}</span
      >

      ${this.__renderPatientName(data, name, rowIndex)}
      ${this.__renderEncounterCharge(rowIndex)}

      <span></span>
    `;
  }

  __renderChargeStatus(rowIndex) {
    const formattedBilled = formatBilled(this.model[rowIndex]);
    const formattedHold = formatHold(this.model[rowIndex]);

    return html`
      <span id="billed-status-${rowIndex}" class="cell-sub"
        >${formattedBilled}</span
      >

      <span id="hold-status-${rowIndex}" class="cell-sub"
        >${formattedHold}</span
      >
    `;
  }

  __renderEncounterCharge(rowIndex) {
    const { appointmentTypeId, encounterNumber } =
      this.model[rowIndex].encounterCharge || {};

    return appointmentTypeId
      ? html`
          <span
            class="cell-sub link"
            id="encounter-${rowIndex}"
            index="${rowIndex}"
            @click="${this.handlers.selectEncounter}"
            >${encounterNumber || ''}</span
          >
        `
      : html`
          <span class="cell-sub" id="encounter-${rowIndex}"
            >${encounterNumber || ''}</span
          >
        `;
  }

  __renderProviderModifiersCell(data, errors, name, rowIndex) {
    return html`
      <span
        >${this.model[rowIndex].provider
          ? objToName(this.model[rowIndex].provider.name, {
              reverse: true,
              middleInitial: true,
            })
          : ''}</span
      >

      <span class="cell-sub"
        >${parseDate(this.model[rowIndex].dateOfService).format(
          'MM/DD/YYYY',
        )}</span
      >

      <span class="cell-sub"
        >${this.model[rowIndex].code}
        ${[
          this.model[rowIndex].modifier_1,
          this.model[rowIndex].modifier_2,
          this.model[rowIndex].modifier_3,
          this.model[rowIndex].modifier_4,
        ]
          .filter(m => m)
          .join(', ')}</span
      >
    `;
  }

  __renderUnitsTaxCell(data, errors, name, rowIndex) {
    return html`
      <span class="cell-sub">${this.model[rowIndex].units}</span>

      <span class="cell-sub"
        >${centsToCurrency(this.model[rowIndex].billedAmount)}
      </span>

      <span class="cell-sub"
        >${centsToCurrency(this.model[rowIndex].taxAmount)}
      </span>

      <neb-textfield
        id="allowed-amount-cell-${rowIndex}"
        label=" "
        helper=" "
        name="${rowIndex}.allowedAmount"
        .value="${this.model[rowIndex].allowedAmount}"
        .error="${!!this.errors[rowIndex].allowedAmount}"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
      ></neb-textfield>
    `;
  }

  __renderPrimaryPayerCell(data, errors, name, rowIndex) {
    const row = this.model[rowIndex];

    let model;
    let modelErrors;

    const lidIndex = row.lineItemDebits.findIndex(
      lid =>
        row.primaryPayerId &&
        lid.debit.payerId === row.primaryPayerId &&
        (lid.patientInsuranceId.data.id === row.primaryInsuranceId ||
          !row.primaryInsuranceId),
    );

    if (lidIndex === -1) {
      model = ChargeResponsibilityTable.createItem();
      modelErrors = map(model, () => '');
    } else {
      model = this.model[rowIndex].lineItemDebits[lidIndex];
      modelErrors = this.errors[rowIndex].lineItemDebits[lidIndex];
    }

    const { id: payerId = '' } = { ...row.primaryPayer };
    const isAllocatableByGroup = this.__checkAllocatableByGroup(payerId);

    return html`
      <neb-primary-payer-cell
        class="span-4"
        id="primary-cell-${rowIndex}"
        showAllocated
        .name="${rowIndex}.lineItemDebits.${lidIndex}"
        .model="${model}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .errors="${modelErrors}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        .allocatableId="${this.allocatableId}"
        .isAllocatableByGroup="${isAllocatableByGroup}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
        .onSelectPayerPlan="${this.onSelectPayerPlan}"
        .onClickPaymentId="${this.onClickPaymentId}"
      ></neb-primary-payer-cell>
    `;
  }

  __renderLineItemDebitsCell(data, errors, name, rowIndex) {
    const showSecondary = this.showSecondaryPayers();
    const row = this.model[rowIndex];

    const lids = row.lineItemDebits.filter(
      lid =>
        !lid.debit.payerId ||
        lid.debit.payerId !== row.primaryPayerId ||
        this.__debitInsuranceMatchesRowInsurance(lid, row),
    );

    const errorLids = this.errors[rowIndex].lineItemDebits.filter(
      (lid, index) => {
        const lineItemDebit = row.lineItemDebits[index];
        return (
          !lineItemDebit.debit.payerId ||
          lineItemDebit.debit.payerId !== row.primaryPayerId ||
          this.__debitInsuranceMatchesRowInsurance(lineItemDebit, row)
        );
      },
    );

    const payerIds = lids.map(({ debit: { payerId = '' } }) => payerId);
    const isAllocatableByGroup = payerIds.map(payerId =>
      this.__checkAllocatableByGroup(payerId),
    );

    return html`
      <neb-table-charges-responsibility
        id="responsibilities-cell-${rowIndex}"
        class="${showSecondary ? 'span-10' : 'span-6'}"
        .name="${name}"
        .model="${lids}"
        .errors="${errorLids}"
        .allocatableId="${this.allocatableId}"
        .paymentTypes="${this.itemsMap.paymentTypes}"
        .secondaryPlanItems="${this.getSecondaryPlanItems(
          this.model[rowIndex],
        )}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        .onRemove="${this.handlers.removeDebit}"
        showAllocated
        ?showSecondary="${showSecondary}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
        .isAllocatableByGroup="${isAllocatableByGroup}"
        .onClickPaymentId="${this.onClickPaymentId}"
      ></neb-table-charges-responsibility>
      <span></span>

      <neb-button-action
        id="add-line-item-debit-button-${rowIndex}"
        class="button-add grid-column-2"
        label="Add Row"
        .name="${name}"
        .onClick="${this.handlers.addItem}"
        ?showSecondary="${showSecondary}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
      ></neb-button-action>
      <span></span>
    `;
  }

  __renderAdjustmentsCell(data, errors, name, rowIndex) {
    return html`
      <neb-table-charges-adjustments
        id="adjustment-cell-${rowIndex}"
        class="span-3"
        .name="${name}"
        .model="${this.model[rowIndex].adjustments}"
        .errors="${errors}"
        .writeOffTypes="${this.itemsMap.adjustments}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeAdjustment}"
        .onBlur="${this.handlers.blur}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
      ></neb-table-charges-adjustments>

      <neb-button-action
        id="add-adjustment-button-${rowIndex}"
        class="button-add"
        label="Add Row"
        .name="${name}"
        .onClick="${this.handlers.addItem}"
        ?disabled="${!this.selectIndexes[rowIndex]}"
      ></neb-button-action>
      <span></span>
    `;
  }

  __renderBalanceCell(data, errors, name, rowIndex) {
    const row = this.model[rowIndex];
    const balance =
      row.billedAmount +
      row.taxAmount -
      row.adjustments.reduce(
        (sum, a) => sum + currencyToCentsWithNegative(a.amount),
        0,
      ) -
      row.lineItemDebits.reduce(
        (sum, lid) =>
          sum +
          lid.debit.allocations.reduce((aSum, a) => aSum + a.amount, 0) +
          currencyToCents(lid.debit.allocated),
        0,
      );

    const preallocated =
      this.preallocated && this.preallocated.length
        ? this.preallocated[rowIndex]
        : 0;

    return html`
      <div id="balance-cell-${rowIndex}" class="cell-sub">
        ${centsToCurrency(balance + preallocated)}
      </div>
    `;
  }

  renderHeaderCell(columnConfig) {
    return columnConfig.config.map(item =>
      item.key === 'checked'
        ? html`
            <neb-button-actions
              id="${ELEMENTS.bulkActionMenu.id}"
              class="ellipsis"
              align="left"
              .forceDownMenu="${true}"
              .onClick="${this.handlers.getBulkActions}"
              ?disabled="${this.disabled}"
            ></neb-button-actions>
          `
        : html` <span>${item.label}</span> `,
    );
  }

  renderDataCell(value, columnConfig, rowIndex, name, errors) {
    const methodName = `__render${capitalize(columnConfig.key)}Cell`;

    return this[methodName](value, errors, name, rowIndex);
  }
}

window.customElements.define(
  'neb-table-allocation-charges',
  AllocationChargesTable,
);
