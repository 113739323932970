/* 
    WARNING!!!!

  This component extends NebLightDom which does not use shadowDom.

  Any styling or element changes can have global effects.
*/

import '../../../../../src/components/controls/inputs/neb-light-textfield';

import { html, css } from 'lit';

import { LightDom } from '../../../../../src/components/misc/neb-light-dom';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';
import { changePassword } from '../../../../neb-utils/cognito-util';
import { BUTTON_ROLE } from '../neb-button';

import {
  TEXT_SUCCESS_TOASTER_CONFIRM,
  TEXT_ERROR_TOASTER_CONFIRM,
} from './neb-forgot-password';
import { validatePassword } from './password-validator';

export const ELEMENTS = {
  emailInput: {
    id: 'neb-change-password-input-email',
  },
  oldPassword: {
    id: 'neb-change-password-old-password',
  },
  newPassword: {
    id: 'neb-change-password-new-password',
  },
  confirmNewPassword: {
    id: 'neb-change-password-confirm-new-password',
  },
  changePasswordButton: {
    id: 'neb-change-password-change-password-button',
  },
  cancelButton: {
    id: 'neb-change-password-cancel-button',
  },
};
export const TEXT_INCORRECT_PASSWORD = 'Your password is incorrect';
const TEXT_REQUIRED = 'Required';
const TEXT_HEADER = 'Change Password';
const LABEL_BUTTON_CONFIRM_PASSWORD = 'Confirm Password';
const LABEL_EMAIL = 'Email Address';
const LABEL_NEW_PASSWORD = 'New Password';
const LABEL_CURRENT_PASSWORD = 'Current Password';
const LABEL_CONFIRM_PASSWORD = 'Confirm Password';
const LABEL_BUTTON_ROLE_CANCEL = 'Cancel';

class NebChangePassword extends LightDom {
  static get properties() {
    return {
      email: String,
      small: {
        type: Boolean,
        reflect: true,
      },
      __confirmNewPassword: String,
      __errors: Object,
      __newPassword: String,
      __oldPassword: String,
      fitIntoPopup: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.email = '';

    this.onPasswordChanged = () => {};

    this.onCancel = () => {};

    this.__confirmNewPassword = '';
    this.__errors = {};
    this.__newPassword = '';
    this.__oldPassword = '';
    this.fitIntoPopup = false;
  }

  __initHandlers() {
    this.__handlers = {
      changePassword: async () => {
        if (!this.__validate()) {
          return undefined;
        }

        try {
          await changePassword(
            this.__oldPassword,
            this.__newPassword,
            this.email,
          );

          store.dispatch(openSuccess(TEXT_SUCCESS_TOASTER_CONFIRM));
          return this.onPasswordChanged();
        } catch (err) {
          console.error(err.code);

          if (err.code === 'NotAuthorizedException') {
            this.__errors.oldPassword = TEXT_INCORRECT_PASSWORD;
            return this.requestUpdate();
          }

          return store.dispatch(openError(TEXT_ERROR_TOASTER_CONFIRM));
        }
      },

      oldPasswordInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, oldPassword: '' };
        this.__oldPassword = value;
      },
      newPasswordInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, password: '' };
        this.__newPassword = value;
      },
      confirmNewPasswordInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, verifyPassword: '' };
        this.__confirmNewPassword = value;
      },
      cancel: () => this.onCancel(),
    };
  }

  __validate() {
    this.__errors = {
      oldPassword: this.__oldPassword ? '' : TEXT_REQUIRED,
      ...validatePassword(
        this.__newPassword,
        this.__confirmNewPassword,
        this.__oldPassword,
      ).errors,
    };

    return Object.values(this.__errors).every(v => !v);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-change-password-container {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          width: 100%;
        }

        .neb-change-password-header {
          margin-bottom: 20px;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .neb-change-password-password-form {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
        }

        .neb-change-password-input-fields {
          display: block;
        }

        .neb-change-password-input-fields:not(:first-child) {
          margin-top: 13px;
        }

        .neb-change-password-button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
        }

        .neb-change-password-button {
          display: flex;
          margin-top: 20px;
        }

        [small] .neb-change-password-container {
          padding: 20px;
          width: 100%;
        }

        [fitIntoPopup] {
          width: 350px;
        }

        .neb-change-password-button:not(:last-child) {
          margin-right: 20px;
        }
      `,
    ];
  }

  __renderEmailInput() {
    return this.fitIntoPopup
      ? ''
      : html`
          <neb-light-textfield
            id="${ELEMENTS.emailInput.id}"
            .label="${LABEL_EMAIL}"
            .value="${this.email}"
            helper=" "
            disabled
          ></neb-light-textfield>
        `;
  }

  __renderButtons() {
    return html`
      <div class="neb-change-password-button-container">
        <neb-button
          id="${ELEMENTS.changePasswordButton.id}"
          class="neb-change-password-button-primary-focus neb-change-password-button"
          role="${BUTTON_ROLE.CONFIRM}"
          .label="${this.fitIntoPopup
            ? TEXT_HEADER
            : LABEL_BUTTON_CONFIRM_PASSWORD}"
          @click="${this.__handlers.changePassword}"
        ></neb-button>
        ${this.fitIntoPopup
          ? html`
              <neb-button
                id="${ELEMENTS.cancelButton.id}"
                class="neb-change-password-button"
                .label="${LABEL_BUTTON_ROLE_CANCEL}"
                role="${BUTTON_ROLE.CANCEL}"
                @click="${this.__handlers.cancel}"
              ></neb-button>
            `
          : ''}
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="neb-change-password-container">
        ${this.fitIntoPopup
          ? ''
          : html`
              <div class="neb-change-password-header">${TEXT_HEADER}</div>
            `}
        <div class="password-form">
          ${this.__renderEmailInput()}
          <neb-light-textfield
            id="${ELEMENTS.oldPassword.id}"
            class="neb-change-password-input-fields"
            helper="${TEXT_REQUIRED}"
            type="password"
            autoComplete="current-password"
            .label="${LABEL_CURRENT_PASSWORD}"
            .error="${this.__errors.oldPassword}"
            .onChange="${this.__handlers.oldPasswordInputChanged}"
            .value="${this.__oldPassword}"
          ></neb-light-textfield>

          <neb-light-textfield
            id="${ELEMENTS.newPassword.id}"
            class="neb-change-password-input-fields"
            helper="${TEXT_REQUIRED}"
            type="password"
            autoComplete="new-password"
            .label="${LABEL_NEW_PASSWORD}"
            .error="${this.__errors.password}"
            .onChange="${this.__handlers.newPasswordInputChanged}"
            .value="${this.__newPassword}"
          ></neb-light-textfield>

          <neb-light-textfield
            id="${ELEMENTS.confirmNewPassword.id}"
            class="neb-change-password-input-fields"
            helper="${TEXT_REQUIRED}"
            type="password"
            autoComplete="new-password"
            .label="${LABEL_CONFIRM_PASSWORD}"
            .error="${this.__errors.verifyPassword}"
            .onChange="${this.__handlers.confirmNewPasswordInputChanged}"
            .value="${this.__confirmNewPassword}"
          ></neb-light-textfield>

          ${this.__renderButtons()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-change-password', NebChangePassword);
