import '../../../tables/neb-table-ledger-superbills';
import '../../../controls/neb-button-action';
import '../../../neb-tooltip';
import '../../../../../../../src/components/filters/neb-filters-ledger-superbills';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css, unsafeCSS } from 'lit';

import * as filters from '../../../../../../../src/utils/filters';
import { NO_ITEMS_INITIAL_LOAD } from '../../../../../../../src/utils/user-message';
import * as api from '../../../../../../neb-api-client/src/ledger-superbill-api-client';
import { MONTH_DAY_YEAR } from '../../../../../../neb-input/nebFormatUtils';
import { POPUP_RENDER_KEYS } from '../../../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../../neb-utils/date-util';
import { centsToCurrency } from '../../../../../../neb-utils/formatters';
import { printPdf } from '../../../../../../neb-utils/neb-pdf-print-util';
import { MODE } from '../../../../../../neb-utils/table';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../neb-page-collection';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  content: { id: 'content' },
  description: { id: 'superbill-description' },
  toolTip: { id: 'tooltip' },
  toolTipText: { id: 'tooltip-text' },
  buttonGenerate: { id: 'generate-new-superbill' },
  buttonViewBatches: { id: 'view-batches' },
  filters: { id: 'filters' },
};

export const MENU_HEIGHT = 416;

const TEXT_TOOLTIP =
  'To create a Superbill for specific charges, go to Ledger Charges, individually select charges and choose `Create Superbill` via the bulk actions command.';

const EMPTY_SUPERBILL_MESSAGE_PATIENT =
  'There are no superbills available for this patient.';

const EMPTY_SUPERBILL_MESSAGE_PRACTICE = 'There are no superbills available.';

const POLLING_DURATION = 120000;

class NebLedgerBalanceSuperBillsCollectionPage extends CollectionPage {
  static get config() {
    return {
      unifyForm: true,
      showInactiveFilter: null,
      initialSortKey: 'createdAt',
      initialSortOrder: 'asc',
      tableConfig: [],
    };
  }

  static get properties() {
    return {
      __marginBottom: Number,
      patientId: String,
      patient: Object,
      hasDisableInitialLoadFF: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__pollingInterval = null;
    this.__marginBottom = 0;

    this.patientId = '';
    this.patient = null;
    this.hasDisableInitialLoadFF = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      printSuperbill: (_, item) => printPdf(api.fetchPdf(item.s3key)),
      printSelectedBatch: batchId => printPdf(api.fetchBatch(batchId)),
      openNewSuperbillPopup: async () => {
        const generated = await openPopup(POPUP_RENDER_KEYS.NEW_SUPERBILL, {
          patientId: this.patientId,
          patient: this.patient,
        });

        if (generated) {
          await this.refetch();
        }
      },
      openViewBatchesOverlay: () => openOverlay(OVERLAY_KEYS.VIEW_BATCHES, api),
      sort: (_, params) => this.service.setSortParams(params[0]),
      clickPatient: e => {
        const { patientId } = e;
        return navigate(`/patients/${patientId}/ledger/balance`);
      },
      applyFilters: async model => {
        Object.entries(model).forEach(([k, v]) =>
          this.service.updateFilter(k, v),
        );

        if (this.__isInitialLoadDisabled()) {
          this.__hasAppliedFilters = true;

          await this.refetch();
        }
      },
      tableChange: updatedItem => {
        this.service.updateItem(updatedItem);
      },
    };
  }

  sort(a, b, key) {
    const configObj = this.getConfig().tableConfig.find(obj => key === obj.key);

    let { compare } = this;

    if (configObj && configObj.compare) compare = configObj.compare;

    const sortKey = compare(a[key], b[key]);
    return sortKey === 0 && key === 'date' ? compare(a.id, b.id) : sortKey;
  }

  async refetch() {
    const items = await this.fetch();

    this.service.setItems(items);
  }

  async fetch() {
    if (this.__isInitialLoadDisabled()) {
      return [];
    }

    const res = await api.fetchMany(this.patientId, true);

    return res.data;
  }

  __isInitialLoadDisabled() {
    return (
      this.hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  updated(changedProps) {
    if (changedProps.has('__tableState')) {
      this.__calculateMarginBottom();
    }

    super.updated(changedProps);
  }

  connectedCallback() {
    super.connectedCallback();

    this.__pollForItems();

    this.service
      .addFilter('superbill', filters.search('superbillNumber'), '')
      .addFilter('patientId', filters.singleSelectSingleTarget('patientId'), '')
      .addFilter('payerIds', filters.multiSelectSingleTarget('payerId'), [])
      .addFilter(
        'providerIds',
        filters.multiSelectSingleTarget('providerId'),
        [],
      )
      .addFilter('date', filters.dateRange('createdAt'), {
        from: null,
        to: null,
      })
      .addFilter('totalCharges', filters.numberRange('totalCharges'), {
        min: null,
        max: null,
      });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearInterval(this.__pollingInterval);

    this.service
      .removeFilter('superbill')
      .removeFilter('patientId')
      .removeFilter('payerIds')
      .removeFilter('providerIds')
      .removeFilter('date')
      .removeFilter('totalCharges');
  }

  __pollForItems() {
    this.__pollingInterval = setInterval(async () => {
      await this.refetch();
    }, POLLING_DURATION);
  }

  __getEmptyMessage() {
    if (this.__isInitialLoadDisabled()) {
      return NO_ITEMS_INITIAL_LOAD;
    }

    return this.patientId
      ? EMPTY_SUPERBILL_MESSAGE_PATIENT
      : EMPTY_SUPERBILL_MESSAGE_PRACTICE;
  }

  __buildConfig() {
    return [
      ...(!this.patientId
        ? [
            {
              sortable: true,
              truncate: true,
              key: 'patientName',
              label: 'Patient',
              flex: css`2 0 0`,
            },
          ]
        : []),
      {
        sortable: true,
        truncate: true,
        key: 'createdAt',
        label: 'Date',
        flex: css`1 0 0`,
        formatter: value =>
          value ? parseDate(value).format(MONTH_DAY_YEAR) : '',
      },
      {
        sortable: true,
        truncate: true,
        key: 'superbillNumber',
        label: 'Superbill ID',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        key: 'batchId',
        label: 'Batch ID',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`2 0 0`,
        formatter: value => value || '',
      },
      {
        sortable: true,
        truncate: true,
        key: 'providerName',
        label: 'Provider',
        flex: css`2 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'totalCharges',
        label: 'Total Charges',
        flex: css`1 0 0`,
        compare: (a, b) => {
          const A = parseInt(a, 10);
          const B = parseInt(b, 10);
          if (A !== B) return A < B ? -1 : 1;

          return 0;
        },
        formatter: amount => (amount ? centsToCurrency(amount) : '$0.00'),
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .description {
          padding-bottom: ${CSS_SPACING};
        }

        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          width: 100%;
        }

        .header-container {
          display: inline-grid;
          grid-template-columns: auto 1fr;
          column-gap: ${CSS_SPACING};
        }

        .inset {
          padding-left: ${CSS_SPACING};
        }

        .container-button {
          display: flex;
        }

        .header {
          margin-bottom: 0;
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .container {
          overflow: visible;
        }
      `,
    ];
  }

  __calculateMarginBottom() {
    const { length } = this.service.__pageItems;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    }

    if (length > 2) {
      this.__marginBottom = 0;
    } else {
      this.__marginBottom =
        MENU_HEIGHT -
        this.shadowRoot
          .getElementById(ELEMENTS.content.id)
          .getBoundingClientRect().height;
    }
  }

  __renderDescription() {
    return this.patientId
      ? 'View and generate Superbill for the patient.'
      : 'View and generate Superbills for patients.';
  }

  __renderViewBatches() {
    return !this.patientId
      ? html`
          <neb-button-action
            id="${ELEMENTS.buttonViewBatches.id}"
            class="inset"
            label="View Batches"
            leadingIcon="generateSubmit"
            .onClick="${this.handlers.openViewBatchesOverlay}"
          ></neb-button-action>
        `
      : '';
  }

  renderNoItemsContent() {
    return '';
  }

  renderHeader() {
    return html`
      <neb-page-collection-header
        id="${ELEMENTS.header.id}"
        class="header"
        .hideInactive="${null}"
        .layout="${this.layout}"
        .title="${this.getTitle()}"
        .description="${this.getConfig().description}"
      ></neb-page-collection-header>
    `;
  }

  renderTable() {
    const emptyMessage = this.__getEmptyMessage();

    return html`
      <div class="grid">
        <div class="inset header-container">
          <span class="description" id="${ELEMENTS.description.id}">
            ${this.__renderDescription()}
          </span>

          <neb-tooltip id="${ELEMENTS.toolTip.id}" defaultAnchor="bottom"
            ><div id="${ELEMENTS.toolTipText.id}" slot="tooltip">
              ${TEXT_TOOLTIP}
            </div></neb-tooltip
          >
        </div>

        <div class="container-button">
          <neb-button-action
            id="${ELEMENTS.buttonGenerate.id}"
            class="inset"
            label="Generate New Superbill"
            .onClick="${this.handlers.openNewSuperbillPopup}"
          ></neb-button-action>

          ${this.__renderViewBatches()}
        </div>

        <neb-filters-ledger-superbills
          id="${ELEMENTS.filters.id}"
          class="filters"
          .onApply="${this.handlers.applyFilters}"
          ?enablePatient="${!this.patientId}"
        ></neb-filters-ledger-superbills>

        <div
          id="${ELEMENTS.content.id}"
          class="content"
          style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
        >
          <neb-table-ledger-superbills
            id="${ELEMENTS.table.id}"
            mode="${MODE.DETAIL}"
            .model="${this.__tableState.pageItems}"
            .config="${this.__buildConfig()}"
            .emptyMessage="${emptyMessage}"
            .sortParams="${[this.__tableState.sortParams]}"
            .onSort="${this.handlers.sort}"
            .onSelectRow="${this.handlers.printSuperbill}"
            .onSelectPatient="${this.handlers.clickPatient}"
            .onSelectBatch="${this.handlers.printSelectedBatch}"
            .onChange="${this.handlers.tableChange}"
          >
          </neb-table-ledger-superbills>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-ledger-balance-superbills-collection-page',
  NebLedgerBalanceSuperBillsCollectionPage,
);
