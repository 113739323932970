export const ECLAIM_FILE_DOWNLOAD_STATUS = Object.freeze({
  DOWNLOAD_PENDING: {
    status: 'Download Pending',
    description: '',
  },
  READY_TO_DOWNLOAD: {
    status: 'Ready To Download',
    description: '',
  },
  DOWNLOADED: {
    status: 'Downloaded',
    description: '',
  },
  ERR_N002: {
    status: 'Error (N-002)',
    description: 'Zip file failed to process',
  },
});
