import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';

export const BASE_URL = 'claim-status';
const billingApiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const updateStatus = status =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_URL}/${status.id}`,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(status),
    version: 1,
  });

export const createStatus = data =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify(data),
    version: 1,
  });

export const bulkUpdateStatus = data =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_URL,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify(data),
    version: 3,
  });
