import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import { TABS } from '../../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ITEM_PAYER_SELF = {
  label: 'Self',
  data: { id: 'null' },
};

export const ITEM_NO_PROVIDER = {
  label: 'No Provider',
  data: { id: 'null' },
};

export const ITEM_NO_LOCATION = {
  label: 'No Location',
  data: { id: 'null' },
};

export const ELEMENTS = {
  notInvoicedCheckbox: { id: 'checkbox-not-invoiced' },
  invoiceTextfield: { id: 'textfield-invoice' },
  encounterTextfield: { id: 'textfield-encounter' },
  patientSearch: { id: 'search-patient' },
  providersSelect: { id: 'select-providers' },
  locationsSelect: { id: 'select-locations' },
  caseSelect: { id: 'select-case' },
  payersSelect: { id: 'select-payers' },
  dosRange: { id: 'range-date-dos' },
  billedRange: { id: 'range-billed' },
  balanceRange: { id: 'range-balance' },
};

class NebLedgerChargesFilters extends NebFilters {
  static get properties() {
    return {
      mode: String,
      locations: Array,
      defaultLocationId: String,
      cases: Array,

      __patientItems: Array,
      __providerItems: Array,
      __payerPlansState: Object,

      patientPayers: Array,

      enablePatient: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }
      `,
    ];
  }

  static createModel() {
    const today = parseDate().endOf('day');
    const thirtyDaysAgo = parseDate().startOf('day').subtract(1, 'month');

    const dateOfService = {
      ...NebDateRange.createModel(),
      from: new Date(thirtyDaysAgo),
      to: new Date(today),
    };

    return {
      patientId: '',
      notInvoiced: false,
      invoice: '',
      encounterNumber: '',
      caseIds: [],
      locationIds: [],
      providerIds: [],
      payerIds: [],
      dateOfService,
      billed: NebCurrencyRange.createModel(),
      balance: NebCurrencyRange.createModel(),
    };
  }

  initState() {
    super.initState();

    this.locations = [];
    this.cases = [];
    this.defaultLocationId = '';
    this.patientPayers = [];

    this.__patientItems = [];
    this.__providerItems = [];
    this.__payerPlansState = FetchService.createModel();

    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          this.__payerPlansState = state;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      payerPlansApi.fetchMany,
    );

    this.enablePatient = false;
    this.mode = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),
      clearEncounterSearch: () => this.formService.apply('encounterNumber', ''),
      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      changePatients: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: e => {
        const model = this.formService.build();
        const { from, to } = model.dateOfService;
        const isButtonPress = !!e;

        this.onApply(
          {
            ...model,
            dateOfService: {
              from: from ? parseDate(from._i) : null,
              to: to ? parseDate(to._i) : null,
            },
          },
          isButtonPress,
        );
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        dateOfService: NebDateRange.createSelectors(),
        billed: NebCurrencyRange.createSelectors(),
        balance: NebCurrencyRange.createSelectors(),
        locationIds: selectors.multiSelect([]),
        caseIds: selectors.multiSelect([]),
        providerIds: selectors.multiSelect([]),
        payerIds: selectors.multiSelect([]),
      },
    };
  }

  async load() {
    this.__payerPlansService.fetch();

    this.__patientsService = createServerPatientsCollection({
      onChange: this.handlers.changePatients,
    });

    const providers = await getProviderUsers();

    this.__providerItems = [
      ITEM_NO_PROVIDER,
      ...providers.map(data => ({
        label: objToName(data.name, DEFAULT_NAME_OPTS),
        data,
      })),
    ];
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      data: location,
    }));
  }

  firstUpdated() {
    this.reload();
  }

  update(changedProps) {
    if (changedProps.has('defaultLocationId')) {
      this.__getDefaultLocation();
      this.load();
    }

    if (changedProps.has('locations')) {
      if (this.locations.length) {
        this.handlers.apply();
      }
    }

    super.update(changedProps);
  }

  __getDefaultLocation() {
    if (this.defaultLocationId) {
      const location = this.locations.find(
        l => l.id === this.defaultLocationId,
      );

      if (location) {
        this.handlers.change({
          name: 'locationIds',
          value: this.__formatLocations([location]),
        });
      }
      this.handlers.apply();
    }
  }

  get payerItems() {
    return this.enablePatient
      ? [ITEM_PAYER_SELF, ...this.__payerPlansState.pageItems]
      : [ITEM_PAYER_SELF, ...this.patientPayers];
  }

  renderLocationsSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="select"
        name="locationIds"
        label="Locations"
        .items="${this.__formatLocations(this.locations)}"
        .value="${this.state.locationIds}"
        .onChange="${this.handlers.change}"
        multiSelect
      >
      </neb-select>
    `;
  }

  renderFirstFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : html`
          <neb-select
            id="${ELEMENTS.caseSelect.id}"
            class="select"
            name="caseIds"
            label="Cases"
            .items="${this.cases}"
            .value="${this.state.caseIds}"
            .onChange="${this.handlers.change}"
            multiSelect
            wrapText
          >
          </neb-select>
        `;
  }

  renderBalanceFilter() {
    return this.mode !== TABS.CLOSED
      ? html`
          <neb-range-currency
            id="${ELEMENTS.balanceRange.id}"
            name="balance"
            label="Balance"
            .model="${this.state.balance}"
            .onChange="${this.handlers.change}"
          ></neb-range-currency>
        `
      : '';
  }

  renderContent() {
    const dos = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };

    return html`
      ${this.renderFirstFilter()}

      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.payerItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.fetchMorePayers}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateOfService"
        label="Date"
        .model="${dos}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.encounterTextfield.id}"
        name="encounterNumber"
        label="Encounter #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.encounterNumber ? 'neb:clear' : ''}"
        .value="${this.state.encounterNumber}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearEncounterSearch}"
      ></neb-textfield>

      <div class="grid grid-auto-right">
        <neb-textfield
          id="${ELEMENTS.invoiceTextfield.id}"
          name="invoice"
          label="Invoice #"
          helper=" "
          leadingIcon="neb:search"
          .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
          .value="${this.state.invoice}"
          .mask="${number}"
          .onChange="${this.handlers.change}"
          .onClickIcon="${this.handlers.clearInvoiceSearch}"
          ?disabled="${this.state.notInvoiced}"
        ></neb-textfield>

        <neb-checkbox
          id="${ELEMENTS.notInvoicedCheckbox.id}"
          name="notInvoiced"
          label="Not Invoiced"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.notInvoiced}"
        ></neb-checkbox>
      </div>

      <neb-range-currency
        id="${ELEMENTS.billedRange.id}"
        name="billed"
        label="Billed"
        .model="${this.state.billed}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>

      ${this.renderBalanceFilter()}

      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        name="providerIds"
        label="Providers"
        helper=" "
        .value="${this.state.providerIds}"
        .items="${this.__providerItems}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      ${this.renderLocationsSelect()}
    `;
  }
}

window.customElements.define(
  'neb-filters-ledger-charges',
  NebLedgerChargesFilters,
);
