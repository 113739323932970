import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import Table from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';

export const CONFIG = [
  {
    key: 'name',
    label: 'Title',
    flex: css`1 0 0`,
  },
  {
    key: 'answers',
    label: 'Choices',
    flex: css`3 0 0`,
    truncate: true,
  },
  {
    key: 'choose',
    label: '',
    flex: css`0 0 110px`,
  },
  {
    key: 'actionMenu',
    label: '',
    flex: css`0 0 40px`,
  },
];

export const ELEMENTS = {
  buttons: { selector: 'neb-button' },
  actionMenu: { selector: 'neb-button-actions' },
};

export const POPUP_MESSAGE = html`
  <div>This choice template will be deleted.</div>
  <br />
  <div>Are you sure you want to delete this choice template?</div>
`;

class NebTableMacroLinkChoiceTemplate extends Table {
  static get styles() {
    return [
      super.styles,
      css`
        .cell-data {
          padding: 10px 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = CONFIG;

    this.onChoose = () => {};

    this.onDelete = () => {};

    this.onCopy = () => {};

    this.onEdit = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      choose: e => {
        const rowIndex = e.currentTarget.index;
        this.onChoose(this.model[rowIndex]);
      },
      delete: async ({ index }) => {
        const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Delete Choice Template',
          message: POPUP_MESSAGE,
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (!res) {
          return;
        }

        this.onDelete(this.model[index]);
      },

      copy: ({ index }) => this.onCopy(this.model[index]),

      edit: ({ index }) => this.onEdit(this.model[index]),
    };
  }

  __genMenuItems(index) {
    return [
      {
        icon: '',
        label: 'Edit Choice Template',
        index,
        onSelect: this.handlers.edit,
      },
      {
        icon: '',
        label: 'Copy Choice Template',
        index,
        onSelect: this.handlers.copy,
      },
      {
        icon: '',
        label: 'Delete Choice Template',
        index,
        onSelect: this.handlers.delete,
      },
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'name':
        return super.renderDataCell(value, columnConfig);

      case 'answers':
        return super.renderDataCell(
          value.map(a => a.value).join(', '),
          columnConfig,
        );

      case 'choose':
        return html`
          <neb-button
            label="Choose"
            .index="${rowIndex}"
            @click="${this.handlers.choose}"
          ></neb-button>
        `;

      case 'actionMenu':
        return html`
          <neb-button-actions
            tabindex="1"
            .value="${this.__genMenuItems(rowIndex)}"
          ></neb-button-actions>
        `;

      default:
        return '';
    }
  }
}

window.customElements.define(
  'neb-table-macro-link-choice-template',
  NebTableMacroLinkChoiceTemplate,
);
