import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonAppend: {
    id: 'button-append',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  buttonReplace: {
    id: 'button-replace',
  },
  container: {
    id: 'container',
  },
  textMessage: {
    id: 'text-message',
  },
};
export const APPEND_LABEL = 'append';
export const REPLACE_LABEL = 'replace';
export const CANCEL_LABEL = 'cancel';
export const ACTIONS = {
  APPEND: APPEND_LABEL,
  REPLACE: REPLACE_LABEL,
};

class NebPopupChartingNoteCopy extends NebPopup {
  initState() {
    super.initState();
    this.model = {
      title: '',
      message: '',
      confirmText: '',
      cancelText: '',
      disableReplace: false,
    };

    this.dynamic = true;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      append: () => this.onClose(ACTIONS.APPEND),
      replace: () => this.onClose(ACTIONS.REPLACE),
    };
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 450px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }

        .button-container {
          display: flex;
          justify-content: space-between;
          width: 100%;

          margin-top: 30px;
        }

        .confirm-buttons {
          display: flex;
          justify-content: start;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.container.id}" class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.model.message}
        </div>

        <div class="button-container">
          <div class="confirm-buttons">
            <neb-button
              id="${ELEMENTS.buttonAppend.id}"
              class="button"
              .label="${this.model.confirmText || APPEND_LABEL}"
              .role="${BUTTON_ROLE.CONFIRM}"
              .onClick="${this.__handlers.append}"
            ></neb-button>

            <neb-button
              id="${ELEMENTS.buttonReplace.id}"
              class="button"
              .label="${this.model.confirmText || REPLACE_LABEL}"
              .role="${BUTTON_ROLE.CONFIRM}"
              .onClick="${this.__handlers.replace}"
              ?disabled="${this.model.disableReplace}"
            ></neb-button>
          </div>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            .label="${this.model.cancelText || CANCEL_LABEL}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-charting-note-copy', NebPopupChartingNoteCopy);
