import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import * as filesApi from '../../../packages/neb-api-client/src/files-api-client';
import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import * as payerPlanApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { downloadURLtoFile } from '../../../packages/neb-utils/blobProcessor';
import { SORT_DIR } from '../../../packages/neb-utils/services/collection';
import * as clearinghouseApi from '../../api-clients/clearinghouse';
import * as eClaimFileApi from '../../api-clients/eclaim-file';
import { CSS_SPACING } from '../../styles';
import {
  formatFilterQuery,
  generateFileName,
  isValidStatus,
  mapToModelEClaimFileDownload,
  buildDefaultQuery,
  E_CLAIM_FILE_STATUS,
  CONFIRM_X12_CLAIM_DOWNLOAD,
} from '../../utils/e-claim-files';
import { CONFIG } from '../tables/practice/clearinghouse/neb-table-clearinghouse-files-download';
import '../filters/neb-filters-eclaim-file-download';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  filters: { id: 'filters' },
};

class CollectionPageClaimFile extends CollectionPage {
  static get config() {
    return {
      useFetch: true,
      tableConfig: CONFIG,
      pageSize: 1000,
      initialSortKey: 'updatedAt',
      initialSortOrder: SORT_DIR.DESC,
      showInactiveFilter: null,
    };
  }

  static get properties() {
    return {
      __clearinghouses: Array,
      __payerPlans: Array,
      __buildDefaultQuery: Boolean,

      layout: String,
      patients: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .container {
          padding: 0 0 ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__clearinghouses = [];
    this.__payerPlans = [];
    this.__buildDefaultQuery = true;
    this.patients = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      fetchData: async query => {
        const [clearinghouses, { payerPlan: payerPlans }] = await Promise.all([
          clearinghouseApi.fetchMany(),
          payerPlanApi.getPayerPlans({}, undefined),
        ]);

        this.__clearinghouses = clearinghouses.filter(
          clearinghouse => clearinghouse.active === true,
        );

        this.__payerPlans = payerPlans;

        const filterQuery = !this.__buildDefaultQuery
          ? query
          : buildDefaultQuery(query);

        const { count, data } = await eClaimFileApi.getEClaimFiles({
          ...filterQuery,
        });

        const patientIds = data.map(x => x.patientId).filter(id => id !== '-');

        const patients = await patientApiClient.fetchSome(
          patientIds,
          {},
          false,
          false,
          true,
        );

        const mappedEClaimFiles = data.map(file =>
          mapToModelEClaimFileDownload({
            raw: file,
            patients,
            payerPlans,
            clearinghouses,
          }),
        );

        const hasDosFilter = filterQuery.dosFrom || filterQuery.dosTo;

        return {
          count,
          data: hasDosFilter
            ? mappedEClaimFiles.filter(file => !file.batchId)
            : mappedEClaimFiles,
        };
      },
      applyFilters: model => {
        this.__buildDefaultQuery = false;
        const filterQueries = formatFilterQuery(model);
        Object.entries(filterQueries).forEach(([k, v]) => {
          if (v) this.service.setQuery(k, v);
          else this.service.unsetQuery(k);
        });

        this.service.setPageIndex(0);
      },
      tableChange: e => {
        const terms = e.name.split('.');
        const index = terms[0];

        const updatedItem = { ...this.__tableState.pageItems[index] };
        updatedItem.checked = e.value;

        this.__tableState.pageItems.splice(index, 1, updatedItem);
        this.__tableState = {
          ...this.__tableState,
          pageItems: [...this.__tableState.pageItems],
        };
      },
      selectAll: () => {
        this.__tableState = this.__setAllCheckboxes(true);
      },
      deselectAll: () => {
        this.__tableState = this.__setAllCheckboxes(false);
      },
      downloadItem: async item => {
        let allowDownload = true;

        if (item.status === E_CLAIM_FILE_STATUS.UPLOADING) {
          allowDownload = await this.__openClaimX12DownloadPopup();
        }
        const { id } = store.getState().session.item;

        if (allowDownload) {
          try {
            const url = await eClaimFileApi.downloadFile(item.id, {
              updatedBy: id,
            });

            const {
              clearinghouse,
              claimNumber,
              practiceIdentifierId,
              customExtension,
              customFilenameTemplate,
              createdAt,
              batchId,
            } = item;

            downloadURLtoFile(
              url,
              generateFileName({
                clearinghousePartner: clearinghouse,
                claimNumber,
                batchId,
                practiceIdentifierId,
                customExtension,
                customFilenameTemplate,
                createdAt,
              }),
            );

            this.service.fetch();
          } catch (e) {
            store.dispatch(
              openError(
                'An error occurred when attempting to download the file',
              ),
            );
          }
        }
      },

      downloadSelected: () => {
        const selectedItems = this.__tableState.pageItems.filter(
          item => item.checked && isValidStatus(item.status),
        );

        Promise.all(
          selectedItems.map(item => this.handlers.downloadItem(item)),
        );

        this.__tableState = this.__setAllCheckboxes(false);
      },

      resendSelected: async () => {
        const selectedItemIds = this.__tableState.pageItems
          .filter(item => item.checked)
          .map(item => item.id);

        try {
          await eClaimFileApi.resendClearinghouseClaims(selectedItemIds);

          store.dispatch(openSuccess('Resending'));
        } catch (e) {
          console.error(e);

          store.dispatch(
            openError('An error occurred when attempting to resend'),
          );
        }

        this.service.fetch();
      },
      uploadSelected: async () => {
        const selectedItemIds = this.__tableState.pageItems
          .filter(item => item.checked)
          .map(item => item.id);

        try {
          await filesApi.uploadClearinghouseSftp({
            eClaimFileIds: selectedItemIds,
          });

          store.dispatch(openSuccess('Uploading'));
        } catch (e) {
          console.error(e);

          store.dispatch(
            openError('An error occurred when attempting to upload'),
          );
        }

        this.service.fetch();
      },
      openNoFilesPopup: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Download Selected',
          message:
            'Please select files that are Ready To Download before performing an action.',
        }),
      openNoValidResends: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Resend Selected',
          message: 'Please select files to resend',
        }),
    };
  }

  __setAllCheckboxes(checked) {
    this.__tableState.pageItems = this.__tableState.pageItems.map(item => ({
      ...item,
      checked,
    }));

    return { ...this.__tableState };
  }

  async __openClaimX12DownloadPopup() {
    const result = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CONFIRM_X12_CLAIM_DOWNLOAD,
    );

    return result;
  }

  renderHeader() {
    return html`
      <neb-filters-eclaim-file-download
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.handlers.applyFilters}"
        .patients="${this.patients}"
        .clearinghouses="${this.__clearinghouses}"
      ></neb-filters-eclaim-file-download>
    `;
  }

  renderNoItemsContent() {
    return html` <neb-text>No items.</neb-text> `;
  }

  renderTable() {
    return html`
      <neb-table-clearinghouse-files-download
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .model="${this.__tableState.pageItems}"
        .onChange="${this.handlers.tableChange}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .onDownloadSelected="${this.handlers.downloadSelected}"
        .onResendSelected="${this.handlers.resendSelected}"
        .onUploadSelected="${this.handlers.uploadSelected}"
        .onClickDownloadIcon="${this.handlers.downloadItem}"
        .onOpenNoFilesPopup="${this.handlers.openNoFilesPopup}"
        .onOpenNoValidResends="${this.handlers.openNoValidResends}"
      ></neb-table-clearinghouse-files-download>
    `;
  }
}

window.customElements.define('neb-page-claim-file', CollectionPageClaimFile);
