const locationsSelectedRequired = () => {
  throw new Error('locationsSelected is required to be an array');
};

/**
 * @param {Array<{id}>} locationsSelected
 */
export default (locationsSelected = locationsSelectedRequired()) => {
  if (!(locationsSelected && Array.isArray(locationsSelected))) {
    return locationsSelectedRequired();
  }

  const locationIds = locationsSelected.map(x => x.id);

  return {
    /**
     * @param {Object} x
     * @param {string} x.locationId
     */
    execute(x) {
      return locationIds.includes(x.locationId);
    },
  };
};
