import '../patients/neb-patient-summary-controller';
import '../../../../neb-www-practice-charting/src/components/summary/neb-encounter-overlay-summary';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_FAMILY,
  CSS_BORDER_GREY_2,
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  encounterSummary: {
    id: 'encounter-summary',
  },
  patientSummary: {
    id: 'patient-summary',
  },
};

class NebEncounterSummaryView extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      patientId: String,
      encounterModel: Object,
      encounters: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.encounterModel = null;
    this.patientId = null;

    this.encounters = [];

    this.onUpdateSummary = () => {};

    this.onDelete = () => {};

    this.onDismiss = () => {};

    this.onEdit = () => {};

    this.openAlerts = () => {};

    this.onAppointmentSelect = () => {};

    this.onOpenVisitSummary = () => {};

    this.onPrint = () => {};

    this.onReopen = () => {};

    this.onSign = () => {};

    this.onViewHistory = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      updateSummary: encounterId => this.onUpdateSummary(encounterId),
      delete: encounterId => this.onDelete(encounterId),
      dismiss: () => this.onDismiss(),
      edit: encounterId => this.onEdit(encounterId),
      openAlerts: patientId =>
        openOverlay(OVERLAY_KEYS.PATIENT_ALERT, {
          patientId,
        }),
      openAppointment: appointmentId => this.onAppointmentSelect(appointmentId),
      openAppointmentHistory: patientId =>
        openOverlay(OVERLAY_KEYS.APPOINTMENT_HISTORY, {
          patientId,
        }),
      openVisitSummary: encounterId => this.onOpenVisitSummary(encounterId),
      print: encounterId => this.onPrint(encounterId),
      reopen: encounterId => this.onReopen(encounterId),
      sign: encounterId => this.onSign(encounterId),
      viewHistory: encounterId => this.onViewHistory(encounterId),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          flex: 1 0 0;
          display: flex;
          height: 100%;
          width: 1020px;

          font-family: ${CSS_FONT_FAMILY};
          border-top: ${CSS_BORDER_GREY_2};
          background-color: ${CSS_COLOR_WHITE};
        }

        .content-left {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding: ${CSS_SPACING};
        }

        .encounter-summary {
          display: flex;
          width: 100%;
          height: 100%;
          max-width: 800px;
        }
      `,
    ];
  }

  __renderPatientSummary() {
    return html`
      <neb-patient-summary-controller
        id="${ELEMENTS.patientSummary.id}"
        class="patient-summary"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .onAppointmentSelect="${this.__handlers.openAppointment}"
        .onOpenAlerts="${this.__handlers.openAlerts}"
        .onOpenAppointmentHistory="${this.__handlers.openAppointmentHistory}"
      ></neb-patient-summary-controller>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="content-left">${this.__renderPatientSummary()}</div>
        <neb-encounter-overlay-summary
          id="${ELEMENTS.encounterSummary.id}"
          class="encounter-summary"
          slot="content"
          .opened="${true}"
          .layout="${this.layout}"
          .model="${this.encounterModel}"
          .encounters="${this.encounters}"
          .onSelectedEncounter="${this.__handlers.updateSummary}"
          .onDelete="${this.__handlers.delete}"
          .onDismiss="${this.__handlers.dismiss}"
          .onEdit="${this.__handlers.edit}"
          .onOpenVisitSummary="${this.__handlers.openVisitSummary}"
          .onPrint="${this.__handlers.print}"
          .onReopen="${this.__handlers.reopen}"
          .onSign="${this.__handlers.sign}"
          .onViewHistory="${this.__handlers.viewHistory}"
        ></neb-encounter-overlay-summary>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-encounter-summary-view',
  NebEncounterSummaryView,
);
