import { store } from '../../packages/neb-redux/neb-redux-store';

export const ADD_ONS = {
  CT_ENGAGE: 'ct-engage',
  CT_INFORMS: 'ct-informs',
  CT_REMIND: 'ct-remind',
  CT_VERIFY: 'ct-verify',
  CT_INSIGHTS: 'ct-insights',
  CT_MAXCLEAR: 'ct-maxclear',
  CT_PROCLEAR_TRIZETTO: 'ct-proclear',
  CT_PROCLEAR_WAYSTAR: 'ct-proclear-waystar',
  CT_FLEXCLEAR: 'ct-flexclear',
  CT_FIFO: 'ct-fifo',
  CT_REV_ACCEL: 'ct-rev-accel',
};

export const hasAddOn = async addOn => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = store.getState().practiceInformation.item
      ? store.getState().practiceInformation.item
      : store.getState().practiceInfo.practiceInfoItems[0];

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return !!practiceInfo.addOns && practiceInfo.addOns.includes(addOn);
};

export const allAddOns = async () => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = store.getState().practiceInformation.item
      ? store.getState().practiceInformation.item
      : store.getState().practiceInfo.practiceInfoItems[0];

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return practiceInfo.addOns || [];
};
