import { html, css } from 'lit';

import Table from './neb-table';
import '../inputs/neb-textfield';
import '../neb-radio-button';
import '../../../../../src/components/controls/inputs/neb-checkbox';

export const CONFIG = [
  {
    key: 'default',
  },
  {
    key: 'value',
    flex: css`1 0 0`,
  },
];

export const ELEMENTS = {
  answerFields: { selector: 'neb-textfield' },
  checkBoxes: { selector: 'neb-checkbox' },
  radioButtons: { selector: 'neb-radio-button' },
};

export default class NebTableMacroChoose extends Table {
  static get properties() {
    return {
      disabled: Boolean,
      chooseOne: { type: Boolean, reflect: true },
    };
  }

  static createModel() {
    return { id: null, value: '', default: false };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row {
          border-bottom: none;
        }

        .row-data {
          padding: 0;
        }

        .cell-data {
          padding: 0;
        }

        :host([chooseOne]) .cell:nth-child(2) {
          margin: 0 10px 0 -10px;
        }

        .value {
          width: 100%;
          padding-top: 16px;
        }
      `,
    ];
  }

  static createSelectors() {
    return {
      createItem: () => this.createModel(),
      children: {
        $: {
          children: {
            value: [
              {
                error: 'Required',
                validate: v => v.trim(),
              },
            ],
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.chooseOne = false;
    this.config = CONFIG;
    this.hideHeader = true;
    this.reorder = true;
    this.showRemoveButton = true;
  }

  shouldEnableRemoveButton() {
    return this.model.length > 1 && !this.disabled ? true : null;
  }

  renderDataCell(value, columnConfig, _, name, error) {
    switch (columnConfig.key) {
      case 'default':
        return this.chooseOne
          ? html`
              <neb-radio-button
                .name="${name}"
                .onChange="${this.handlers.change}"
                .value="${value}"
                .checked="${value}"
              ></neb-radio-button>
            `
          : html`
              <neb-checkbox
                .name="${name}"
                .onChange="${this.handlers.change}"
                .checked="${value}"
              ></neb-checkbox>
            `;

      case 'value':
        return html`
          <neb-textfield
            class="value"
            helper="Required"
            maxLength="255"
            .name="${name}"
            .error="${error}"
            .onChange="${this.handlers.change}"
            .value="${value}"
            ?disabled="${this.disabled}"
          ></neb-textfield>
        `;

      default:
        return '';
    }
  }
}

window.customElements.define('neb-table-macro-choose', NebTableMacroChoose);
