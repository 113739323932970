export const getPlatformOS = () => {
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;
  return platform;
};

export const isSafariBrowser = useAgentValue => {
  const userAgent = useAgentValue
    ? useAgentValue.toLowerCase()
    : (window.navigator?.userAgent || '').toLowerCase();

  const isSafari =
    userAgent.includes('safari') && !userAgent.includes('chrome');

  return isSafari;
};
