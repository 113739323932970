import '../../../../neb-material-design/src/components/neb-md-button';
import '../neb-button';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  noPracticeText: {
    id: 'no-practice-text',
  },
  noPracticeText2: {
    id: 'no-practice-text-2',
  },
  logOut: {
    id: 'log-out',
  },
  noPracticeContainer: {
    id: 'no-practice-container',
  },
};

class NebNoPractice extends LitElement {
  static get properties() {
    return {
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.small = false;
  }

  __initHandlers() {
    this.__handlers = {
      logout: () =>
        this.dispatchEvent(
          new CustomEvent('neb-logout', {
            detail: {},
            bubbles: true,
            composed: true,
          }),
        ),
    };
  }

  static get styles() {
    return [
      css`
        .no-practice-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .no-practice-text {
          text-align: center;
          padding-bottom: 4%;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .no-practice-text ~ .no-practice-text {
          padding-bottom: 8%;
        }

        .log-out {
          flex-grow: 0;
        }

        :host([small]) .no-practice-container {
          padding-left: 11%;
          flex-wrap: nowrap;
          width: 75%;
        }
      `,
    ];
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.noPracticeContainer.id}"
        class="no-practice-container"
      >
        <div id="${ELEMENTS.noPracticeText.id}" class="no-practice-text">
          There are no practices associated with your account.
        </div>

        <div id="${ELEMENTS.noPracticeText2.id}" class="no-practice-text">
          Please contact your practice's Super User for assistance.
        </div>

        <neb-button
          id="${ELEMENTS.logOut.id}"
          class="log-out"
          label="Log Out"
          .onClick="${this.__handlers.logout}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-no-practice', NebNoPractice);
