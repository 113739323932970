import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners';
import * as Gestures from '@polymer/polymer/lib/utils/gestures';
import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin';
/**
 * Mixin to provides a way to listen to the user swiping left or right.
 */

const NebSwipeDetection = dedupingMixin(
  superClass =>
    class extends GestureEventListeners(superClass) {
      constructor() {
        super();
        this.swipeThreshold = 0.25;
      }

      connectedCallback() {
        super.connectedCallback();
        Gestures.addListener(this, 'track', this.__onTrack.bind(this));
      }

      disconnectedCallback() {
        if (super.disconnectedCallback) super.disconnectedCallback();
        Gestures.removeListener(this, 'track', this.__onTrack.bind(this));
      }

      static get properties() {
        return {
          /**
           * How far, as a number between 0 and 1, as a fraction of the total
           * page width before we consider it a 'swipe'.  Default to 0.25, which
           * means the user must swipe at least 25% of the screen width to register
           * it as a swipe.
           */
          swipeThreshold: Number,

          /** Set this to true in order to include some debug logging. */
          swipeDebug: Boolean,
          /**
           * Fired when the user swipes left (specifically when the user swipes at least threshold portion of total width of the page).
           * @event neb-swipe-left
           */

          /**
           * Fired when the user swipes right (specifically when the user swipes at least threshold portion of total width of the page).
           * @event neb-swipe-right
           */

          /**
           * Fired when the user swipes left or right, but not far enough to trigger a swipe-left or swipe-right.
           * @event neb-swipe-abort
           */
        };
      }

      _onAfterTouchStart(_e) {
        /** Expected to be overridden. */
      }

      _onAfterTouchMove(_e) {
        /** Expected to be overridden. */
      }

      _onAfterTouchEnd(_e) {
        /** Expected to be overridden. */
      }

      _getWidth() {
        return window.screen.width;
      }

      __onTouchStart(e) {
        this._onAfterTouchStart(e);
      }

      __onTouchMove(e) {
        this._onAfterTouchMove(e);
      }

      _shouldHandleTouchEnd() {
        return true;
      }

      __onTouchEnd(e) {
        if (!this._shouldHandleTouchEnd()) {
          return;
        }

        const { dx } = e.detail;
        const userIsSwipingLeftwards = dx < 0;
        const userIsSwipingRightwards = dx > 0;

        const width = this._getWidth();

        const thresholdCalc = Math.abs(dx) / width;

        const swipeThreshold = this.__getSwipeThreshold();

        const thresholdWasCrossed = thresholdCalc > swipeThreshold;

        if (this.swipeDebug) {
          console.log(
            `Debug: dx=${dx} width=${width} thresholdCalc=${thresholdCalc} swipeThreshold=${swipeThreshold}`,
          );
        }

        if (thresholdWasCrossed) {
          if (userIsSwipingRightwards) {
            this.dispatchEvent(
              new CustomEvent('neb-swipe-right', {
                bubbles: true,
                composed: true,
              }),
            );
          } else if (userIsSwipingLeftwards) {
            this.dispatchEvent(
              new CustomEvent('neb-swipe-left', {
                bubbles: true,
                composed: true,
              }),
            );
          }
        } else {
          this.dispatchEvent(new CustomEvent('neb-swipe-abort'), {
            bubbles: true,
            composed: true,
          });
        }

        this._onAfterTouchEnd(e);
      }

      __onTrack(e) {
        if (this.__isTouchEvent(e)) {
          switch (e.detail.state) {
            case 'start':
              this.__onTouchStart(e);
              break;

            case 'track':
              this.__onTouchMove(e);
              break;

            case 'end':
              this.__onTouchEnd(e);
              break;

            default:
          }
        }
      }

      __isTouchEvent(e) {
        return (
          e.detail.sourceEvent != null && e.detail.sourceEvent instanceof Touch
        );
      }

      __getSwipeThreshold() {
        return Math.max(0, Math.min(1, this.swipeThreshold));
      }
    },
);
export { NebSwipeDetection };
