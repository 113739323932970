import { format, parse } from 'date-fns';

import { required, isDate, isDateOfBirth } from './validators';

export function selectFormatters(items, opts = {}) {
  const key = opts.key || 'id';
  const empty = opts.empty || {
    [key]: '',
  };
  return {
    format: v => items.find(item => item.item[key] === v) || empty,
    unformat: v => v.item[key],
  };
}

export function multiSelectFormatters(items, opts = {}) {
  const key = opts.key || 'id';
  const empty = opts.empty || {
    [key]: '',
  };
  return {
    format: v => v.map(v => items.find(item => item.item[key] === v) || empty),
    unformat: v => v.map(v => v.item[key]),
  };
}

export function dateOfBirthSelector(require = true) {
  const validators = require
    ? [required, isDate, isDateOfBirth]
    : [isDate, isDateOfBirth];
  return {
    validators,
    format: v => (v ? format(v, 'MM/DD/YYYY') : ''),
    unformat: v => (v ? parse(v, 'MM/DD/YYYY') : null),
  };
}
