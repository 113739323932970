import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../../../neb-styles/neb-variables';
import { createModelNewPatientInsurance } from '../../../../../../../neb-utils/patientInsurance';

export const ELEMENTS = {
  noEligibilityInfoText: {
    id: 'no-eligibility-text',
  },
  eligibilityPhoneText: {
    id: 'eligibility-phone-text',
  },
  eligibilityExtensionText: {
    id: 'eligibility-extension-text',
  },
  eligibilityWebsiteText: {
    id: 'eligibility-website-text',
  },
  eligibilityWebsiteLink: {
    id: 'eligibility-website-link',
  },
  eligibilityContactNameText: {
    id: 'eligibility-contact-name-text',
  },
  noClaimsInfoText: {
    id: 'no-claims-text',
  },
  claimsPhoneText: {
    id: 'claims-phone-text',
  },
  claimsExtensionText: {
    id: 'claims-extension-text',
  },
  claimsWebsiteText: {
    id: 'claims-website-text',
  },
  claimsWebsiteLink: {
    id: 'claims-website-link',
  },
  claimsContactNameText: {
    id: 'claims-contact-name-text',
  },
  claimsFaxText: {
    id: 'claims-fax-text',
  },
};

class NebPatientInsuranceEligibilityClaimsDetails extends LitElement {
  static get properties() {
    return {
      patientInsurance: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.patientInsurance = createModelNewPatientInsurance();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
          display: flex;
          justify-content: space-between;
          width: 70%;
        }

        .header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .flex-one {
          flex: 1;
        }

        .no-contact-info {
          font-style: italic;
        }

        .website {
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `,
    ];
  }

  __renderExtension(extension) {
    if (extension && extension.length > 0) return ` Ext. ${extension}`;
    return '';
  }

  __renderFax(fax) {
    if (fax && fax.length > 0) return `Fax: ${fax}`;
    return '';
  }

  __checkAddHttp(website) {
    if (website && website.indexOf('http') === 0) {
      return website;
    }

    return `http://${website}`;
  }

  __renderEligibilityInfo() {
    if (
      this.patientInsurance.planInfo.phone.length === 0 &&
      this.patientInsurance.planInfo.website.length === 0 &&
      this.patientInsurance.planInfo.contactName.length === 0
    ) {
      return html`
        <div id="${ELEMENTS.noEligibilityInfoText.id}" class="no-contact-info">
          No contact info.
        </div>
      `;
    }

    return html`
      <div id="${ELEMENTS.eligibilityPhoneText.id}">
        ${this.patientInsurance.planInfo.phone}<span
          id="${ELEMENTS.eligibilityExtensionText.id}"
          >${this.__renderExtension(
            this.patientInsurance.planInfo.extension,
          )}</span
        >
      </div>
      <div id="${ELEMENTS.eligibilityWebsiteText.id}" class="website">
        <a
          id="${ELEMENTS.eligibilityWebsiteLink.id}"
          href="${this.__checkAddHttp(this.patientInsurance.planInfo.website)}"
          target="_blank"
          >${this.patientInsurance.planInfo.website}</a
        >
      </div>
      <div id="${ELEMENTS.eligibilityContactNameText.id}">
        ${this.patientInsurance.planInfo.contactName}
      </div>
    `;
  }

  __renderClaimsInfo() {
    if (
      this.patientInsurance.payerPlan.phone.length === 0 &&
      this.patientInsurance.payerPlan.website.length === 0 &&
      this.patientInsurance.payerPlan.contactName.length === 0 &&
      this.patientInsurance.payerPlan.fax.length === 0
    ) {
      return html`
        <div id="${ELEMENTS.noClaimsInfoText.id}" class="no-contact-info">
          No contact info.
        </div>
      `;
    }

    return html`
      <div id="${ELEMENTS.claimsPhoneText.id}">
        ${this.patientInsurance.payerPlan.phone}<span
          id="${ELEMENTS.claimsExtensionText.id}"
          >${this.__renderExtension(
            this.patientInsurance.payerPlan.extension,
          )}</span
        >
      </div>
      <div id="${ELEMENTS.claimsWebsiteText.id}" class="website">
        <a
          id="${ELEMENTS.claimsWebsiteLink.id}"
          href="${this.__checkAddHttp(this.patientInsurance.payerPlan.website)}"
          target="_blank"
          >${this.patientInsurance.payerPlan.website}
        </a>
      </div>

      <div id="${ELEMENTS.claimsContactNameText.id}">
        ${this.patientInsurance.payerPlan.contactName}
      </div>
      <div id="${ELEMENTS.claimsFaxText.id}">
        ${this.__renderFax(this.patientInsurance.payerPlan.fax)}
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="flex-one">
          <div class="header">Eligibility Contact Info</div>
          ${this.__renderEligibilityInfo()}
        </div>

        <div class="flex-one">
          <div class="header">Claims Contact Info</div>
          ${this.__renderClaimsInfo()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-eligibility-claims-details',
  NebPatientInsuranceEligibilityClaimsDetails,
);
