import { MDCCheckbox } from '@material/checkbox';
import { MDCFormField } from '@material/form-field';
import { LitElement, css, html } from 'lit';

import { CSS_COLOR_DISABLED } from '../../../neb-styles/neb-variables';
import checkboxStyle from '../../material_styles/mdc-checkbox-css';
import formFieldStyle from '../../material_styles/mdc-form-field-css';

export const ELEMENTS = {
  checkbox: {
    id: 'checkbox',
  },
  input: {
    id: 'input-checkbox',
  },
  formField: {
    id: 'form-field',
  },
  label: {
    id: 'label',
  },
};

class NebMDCheckbox extends LitElement {
  static get properties() {
    return {
      alignEnd: {
        type: Boolean,
      },
      bold: {
        type: Boolean,
      },
      checked: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      label: {
        type: String,
      },
      name: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this._valid = true;
    this.alignEnd = false;
    this.bold = false;
    this.checked = false;
    this.disabled = false;
    this.label = '';
    this.name = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      keyup: event => {
        const { key } = event;
        event.stopPropagation();
        event.preventDefault();
        this.checkbox.foundation_.adapter_.removeClass('mdc-ripple-upgraded');
        if (key !== ' ') return;

        if (!this.disabled) {
          this.onChange({
            value: !this.checked,
            id: this.id,
            name: this.name,
          });
        }
      },
      checked: event => {
        event.stopPropagation();
        event.preventDefault();

        if (!this.disabled) {
          this.onChange({
            value: !this.checked,
            id: this.id,
            name: this.name,
          });
        }
      },
    };
  }

  updateCheckbox() {
    this.checkbox.checked = this.checked;
    this.checkbox.disabled = this.disabled;
    this.checkbox.valid = this._valid;
  }

  firstUpdated() {
    this.formField = MDCFormField.attachTo(
      this.shadowRoot.querySelector('.mdc-form-field'),
    );

    this.checkbox = MDCCheckbox.attachTo(
      this.shadowRoot.querySelector('.mdc-checkbox'),
    );

    this.formField.input = this.checkbox;
    this.updateCheckbox();
  }

  updated() {
    this.updateCheckbox();
  }

  static customStyle() {
    return css`
      /* This is a fix for an iPhone specific checkmark defect NEB-16972 */
      .mdc-checkbox__checkmark {
        position: static;
        padding-left: 2px;
        min-width: 100%;
      }
      .mdc-form-field > label {
        cursor: pointer;
        font-size: 14px;
      }

      .mdc-form-field > label[disabled] {
        cursor: inherit;
      }

      label[disabled] {
        color: ${CSS_COLOR_DISABLED};
      }

      .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate)
        ~ .mdc-checkbox__background {
        border-color: ${CSS_COLOR_DISABLED};
      }
      .bold {
        font-weight: bold;
      }
    `;
  }

  static get styles() {
    return [checkboxStyle, formFieldStyle, this.customStyle()];
  }

  __formClasses() {
    const classes = ['mdc-form-field'];
    if (this.alignEnd) classes.push('mdc-form-field--align-end');
    if (this.bold) classes.push('bold');

    return classes.join(' ');
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.formField.id}"
        class="${this.__formClasses()}"
        @click="${this.__handlers.checked}"
        @keyup="${this.__handlers.keyup}"
      >
        <div class="mdc-checkbox" id="${ELEMENTS.checkbox.id}">
          <input
            id="${ELEMENTS.input.id}"
            type="checkbox"
            class="mdc-checkbox__native-control"
            style="pointer-events: none;"
          />

          <div class="mdc-checkbox__background">
            <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
              <path
                class="mdc-checkbox__checkmark-path"
                fill="none"
                d="M1.73,12.91 8.1,19.28 22.79,4.59"
              />
            </svg>
            <div class="mdc-checkbox__mixedmark"></div>
          </div>
        </div>
        <label
          class="mdc-label"
          id="${ELEMENTS.label.id}"
          for="${ELEMENTS.input.id}"
          ?disabled="${this.disabled}"
          >${this.label}</label
        >
      </div>
    `;
  }
}

customElements.define('neb-md-checkbox', NebMDCheckbox);
