import '../../../../../neb-lit-components/src/components/problem-list/neb-problem-list';

import { LitElement, html } from 'lit';

import { store } from '../../../../../neb-redux/neb-redux-store';
import { Dirty } from '../../../../../neb-redux/services/dirty';
import { manuallyWarn } from '../../../../../neb-route/neb-route-state';

const PATH = '/patients/:id/clinical/problem-list';
export const ELEMENTS = {
  problemList: {
    id: 'problem-list',
  },
};

class NebPatientProblemListController extends LitElement {
  static get properties() {
    return {
      problems: Array,
      small: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.onProblemListUpdate = () => {};

    this.onSave = () => {};

    this.small = false;
    this.__problems = [];
    this.__dirty = new Dirty(PATH, () => this.isDirty());
  }

  __initHandlers() {
    this.__handlers = {
      save: problems => this.onSave(problems),
      cancel: () => store.dispatch(manuallyWarn()),
      proceedFromDirty: () => {
        this.__elements.problemList.clear();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__dirty.connect();

    window.addEventListener(
      'neb-dirty-proceed',
      this.__handlers.proceedFromDirty,
    );

    window.addEventListener(
      'neb-dirty-manual-proceed',
      this.__handlers.proceedFromDirty,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__dirty.disconnect();

    window.removeEventListener(
      'neb-dirty-proceed',
      this.__handlers.proceedFromDirty,
    );

    window.removeEventListener(
      'neb-dirty-manual-proceed',
      this.__handlers.proceedFromDirty,
    );
  }

  firstUpdated() {
    this.__elements = {
      problemList: this.shadowRoot.getElementById(ELEMENTS.problemList.id),
    };
  }

  isDirty() {
    return this.__elements && this.__elements.problemList.isDirty();
  }

  render() {
    return html`
      <neb-problem-list
        id="${ELEMENTS.problemList.id}"
        .originalProblems="${this.__problems}"
        .small="${this.small}"
        .onSave="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
        .showCopyButton="${false}"
      ></neb-problem-list>
    `;
  }
}

customElements.define(
  'neb-patient-problem-list-controller',
  NebPatientProblemListController,
);
