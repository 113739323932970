import { matchRouteSwitch } from '@neb/router';

import { store } from '../neb-redux-store';

export class BlacklistService {
  constructor(blacklist, callback) {
    this.__blacklist = blacklist.map(this.__buildRouteItem);
    this.__previousHash = '';
    this.onRouteBlacklisted = callback;
  }

  connect() {
    this.__storeUnsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );

    this._stateChanged(store.getState());
  }

  disconnect() {
    this.__storeUnsubscribe();
  }

  _stateChanged(state) {
    if (state.route && state.route.hash !== this.__previousHash) {
      this.__previousHash = state.route.hash;
      this.onRouteBlacklisted(!!matchRouteSwitch(this.__blacklist));
    }
  }

  __buildRouteItem(path) {
    return {
      exact: false,
      path,
      resolver: () => true,
    };
  }
}
