const DEFAULT_MIN_CAP_SIZE = 30;

export const updateCardTextSize = (ctx, items) => {
  const cardEl = ctx.shadowRoot.querySelector('neb-card');
  const itemEl =
    cardEl && cardEl.shadowRoot && cardEl.shadowRoot.querySelector('.item');

  if (cardEl && itemEl) {
    const fontSize = items
      .map(v => ctx.__textService.fitWithElement(v, itemEl))
      .reduce((acc, curr, index) => (!index || curr < acc ? curr : acc), 0);

    const cappedSize = Math.min(fontSize, DEFAULT_MIN_CAP_SIZE);
    ctx.style.setProperty('--card-text-size', `${cappedSize}px`);
  }
};
