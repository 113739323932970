import { ConnectedService } from './connected-service';

export class LocationsService extends ConnectedService {
  constructor(callback) {
    super({
      allLocations: 'practiceInformation.item.locations',
      userLocationIds: 'session.item.locations',
      defaultLocationId: 'session.item.defaultLocationId',
      tier: 'practiceInformation.item.tier',
    });

    this.onStateChanged = callback;
  }

  _stateChanged({ allLocations, userLocationIds, defaultLocationId }) {
    const locations = allLocations.filter(location => !location.addressOnly);

    const userLocations = locations.filter(location =>
      userLocationIds.includes(location.id),
    );

    const activeLocations = locations.filter(location => location.active);
    const inactiveLocationIds = locations.filter(x => !x.active).map(x => x.id);
    const userHasAllLocations = locations.length === userLocations.length;

    this.onStateChanged({
      locations,
      activeLocations,
      inactiveLocationIds,
      userLocations,
      defaultLocationId,
      allLocations,
      userHasAllLocations,
    });
  }
}
