import { html, css } from 'lit';
import moment from 'moment-timezone';

import { getPatientPackageUsedCounts } from '../../../src/api-clients/patient-package';
import '../../../src/components/forms/neb-form-quick-check-in';
import { updateAppointment } from '../../neb-api-client/src/appointment-api-client';
import { APPOINTMENT_ACTIONS } from '../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { CSS_COLOR_WHITE } from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
  infoText: { id: 'info-text' },
};

const TITLE = 'Please Select to Continue';

class NebPopupQuickCheckIn extends NebPopup {
  static get properties() {
    return {
      __hasProvider: Boolean,
      __patientPackages: Array,
      __appointment: Object,
      __usedCounts: Array,
    };
  }

  initState() {
    super.initState();
    this.__usedCounts = [];
    this.__appointment = null;
    this.__hasProvider = true;
    this.__patientPackages = [];
    this.title = TITLE;
    this.dynamic = true;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: async model => {
        try {
          let result = {};

          if (model.providerId) {
            const res = await updateAppointment(
              this.__appointment.id,
              APPOINTMENT_ACTIONS.RESCHEDULE.action,
              {
                ...this.__appointment,
                providerId: model.providerId,
                custom: true,
                duration: moment(this.__appointment.end).diff(
                  this.__appointment.start,
                ),
              },
            );

            result = { data: res.data };
          }

          if (model.patientPackageId) {
            result = { ...result, patientPackageId: model.patientPackageId };
          }

          this.onClose(result);
        } catch (err) {
          console.error(err);
          this.onClose(false);
        }
      },
      cancel: () => {
        this.onClose(false);
      },
    };
  }

  async updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      if (this.model.appointment) {
        this.__appointment = { ...this.model.appointment };
        this.__hasProvider = !!this.model.appointment.providerId;
      }

      this.__patientPackages = this.model.patientPackages;
      this.__usedCounts = await getPatientPackageUsedCounts(
        null,
        this.__patientPackages.map(pkg => pkg.id),
        null,
        true,
      );
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 470px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }

        :host([dynamic]) .content {
          display: unset;
          flex: 0;
        }
      `,
    ];
  }

  __renderDescription() {
    if (!this.__hasProvider && this.__patientPackages.length) {
      return html`
        <div id="${ELEMENTS.infoText.id}" class="info-text">
          This appointment does not have a Provider scheduled and this patient
          has multiple Care Packages. Please select the Provider and Care
          Package to continue.
        </div>
      `;
    }

    if (this.__patientPackages.length) {
      return html`
        <div id="${ELEMENTS.infoText.id}" class="info-text">
          This patient has multiple active Care Packages. Please select a Care
          Package to continue.
        </div>
      `;
    }

    return html`
      <div id="${ELEMENTS.infoText.id}" class="info-text">
        This appointment does not have a Provider scheduled. Please select a
        Provider to continue.
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        ${this.__renderDescription()}

        <neb-form-quick-check-in
          id="${ELEMENTS.form.id}"
          .hasProvider="${this.__hasProvider}"
          .patientPackages="${this.__patientPackages}"
          .usedCounts="${this.__usedCounts}"
          .onSave="${this.__handlers.confirm}"
          .onCancel="${this.__handlers.cancel}"
        ></neb-form-quick-check-in>
      </div>
    `;
  }
}

customElements.define('neb-popup-quick-check-in', NebPopupQuickCheckIn);
