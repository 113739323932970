import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tab-old';

import { LitElement, html, css } from 'lit';

import { renderTypographyStyles } from '../../../../neb-styles/neb-typography';
import {
  CSS_BORDER_GREY_2,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

export const ROSTER_TABS_ID = 'roster-tabs';
export const READY_TAB = 'ready';
export const ATTENTION_TAB = 'attention';
export const DUPLICATE_TAB = 'duplicate';
export const EXISTING_TAB = 'existing';
export const READY_DEFAULT_LABEL = 'Ready to Import';
export const ATTENTION_DEFAULT_LABEL = 'Needs Attention';
export const DUPLICATE_DEFAULT_LABEL = 'Duplicate Records';
export const EXISTING_DEFAULT_LABEL = 'Existing Records';
const READY_COUNT_PROPERTY = 'readyCount';
const ATTENTION_COUNT_PROPERTY = 'attentionCount';
const DUPLICATE_COUNT_PROPERTY = 'duplicateCount';
const EXISTING_COUNT_PROPERTY = 'existingCount';

export const ELEMENTS = {
  attentionTab: {
    id: 'attention-tab',
  },
  duplicateTab: {
    id: 'duplicate-tab',
  },
  existingTab: {
    id: 'duplicate-tab',
  },
  readyTab: {
    id: 'ready-tab',
  },
};

class NebPatientRosterTabs extends LitElement {
  static get properties() {
    return {
      [READY_COUNT_PROPERTY]: {
        type: Number,
      },
      [ATTENTION_COUNT_PROPERTY]: {
        type: Number,
      },
      [DUPLICATE_COUNT_PROPERTY]: {
        type: Number,
      },
      [EXISTING_COUNT_PROPERTY]: {
        type: Number,
      },
      onTabChanged: {
        type: Function,
      },
      __selectedTab: {
        type: String,
      },
      __tabsSetup: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__setupTabs();

    this.onTabChanged = () => {};

    this.__selectedTab = ATTENTION_TAB;

    this.__initHandlers();
  }

  __setupTabs() {
    this.__tabsSetup = {
      [READY_TAB]: {
        name: READY_TAB,
        label: READY_DEFAULT_LABEL,
        countLabel: '',
      },
      [ATTENTION_TAB]: {
        name: ATTENTION_TAB,
        label: ATTENTION_DEFAULT_LABEL,
        countLabel: '',
      },
      [DUPLICATE_TAB]: {
        name: DUPLICATE_TAB,
        label: DUPLICATE_DEFAULT_LABEL,
        countLabel: '',
      },
      [EXISTING_TAB]: {
        name: EXISTING_TAB,
        label: EXISTING_DEFAULT_LABEL,
        countLabel: '',
      },
    };
  }

  __initHandlers() {
    this.__handlers = {
      tabSelection: selectedTab => {
        this.__selectedTab = selectedTab;
      },
    };
  }

  updated(changed) {
    if (changed.has('__selectedTab')) this.onTabChanged(this.__selectedTab);

    if (changed.has(READY_COUNT_PROPERTY)) {
      this.__onCountChanged(READY_TAB, READY_COUNT_PROPERTY);
    }

    if (changed.has(ATTENTION_COUNT_PROPERTY)) {
      this.__onCountChanged(ATTENTION_TAB, ATTENTION_COUNT_PROPERTY);
    }

    if (changed.has(DUPLICATE_COUNT_PROPERTY)) {
      this.__onCountChanged(DUPLICATE_TAB, DUPLICATE_COUNT_PROPERTY);
    }

    if (changed.has(EXISTING_COUNT_PROPERTY)) {
      this.__onCountChanged(EXISTING_TAB, EXISTING_COUNT_PROPERTY);
    }
  }

  static get styles() {
    return css`
      :host {
        display: block;
        border-bottom: ${CSS_BORDER_GREY_2};
      }

      .tabs {
        padding-left: ${CSS_SPACING};
      }
    `;
  }

  render() {
    return html`
      ${renderTypographyStyles()}
      <neb-tabs-old
        id="${ROSTER_TABS_ID}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.tabSelection}"
      >
        <neb-tab-old
          id="${ELEMENTS.attentionTab.id}"
          name="${this.__tabsSetup[ATTENTION_TAB].name}"
          >${this.__tabsSetup[ATTENTION_TAB].label}
          ${this.__tabsSetup[ATTENTION_TAB].countLabel}</neb-tab-old
        >
        <neb-tab-old
          id="${ELEMENTS.duplicateTab.id}"
          name="${this.__tabsSetup[DUPLICATE_TAB].name}"
          >${this.__tabsSetup[DUPLICATE_TAB].label}
          ${this.__tabsSetup[DUPLICATE_TAB].countLabel}</neb-tab-old
        >
        <neb-tab-old
          id="${ELEMENTS.existingTab.id}"
          name="${this.__tabsSetup[EXISTING_TAB].name}"
          >${this.__tabsSetup[EXISTING_TAB].label}
          ${this.__tabsSetup[EXISTING_TAB].countLabel}</neb-tab-old
        >
        <neb-tab-old
          id="${ELEMENTS.readyTab.id}"
          name="${this.__tabsSetup[READY_TAB].name}"
          >${this.__tabsSetup[READY_TAB].label}
          ${this.__tabsSetup[READY_TAB].countLabel}</neb-tab-old
        >
      </neb-tabs-old>
    `;
  }

  __onCountChanged(tabName, countProperty) {
    const tabSetup = this.__tabsSetup[tabName];
    tabSetup.countLabel = this.__generateCountLabel(this[countProperty]);
    this.__tabsSetup = {
      ...this.__tabsSetup,
      ...{
        [tabName]: tabSetup,
      },
    };
  }

  __generateCountLabel(count) {
    return count ? ` (${count})` : '';
  }
}

customElements.define('neb-patient-roster-tabs', NebPatientRosterTabs);
