import './neb-profile-photo';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_FAMILY,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';
import {
  readDataUrlFromBlob,
  validateBlob,
  validateImage,
} from '../../../neb-utils/blobProcessor';

import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
  file: {
    id: 'file',
  },
  label: {
    id: 'label',
  },
  profilePhoto: {
    id: 'profile-photo',
  },
  deleteButton: {
    id: 'button-delete',
  },
};
const MAX_FILE_SIZE = 10 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];
const IMG_CORRUPT_FILE_ERROR =
  'Your file is not formatted correctly or may be corrupt. Please try correcting the file and uploading again.';
const IMG_EXCEEDS_SIZE_ERROR =
  'File size must be less than 10 MB and the file type must be JPG or PNG, and file dimensions must be less than 3840 x 2160 pixels';
const KEY_PREVIEW = POPUP_RENDER_KEYS.PROFILE_PREVIEW;

class NebProfilePhotoUpload extends LitElement {
  static get properties() {
    return {
      model: Object,
      name: String,
      previewTitle: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 160px;
        }

        .custom-file-upload {
          outline: none;
          cursor: pointer;
          padding: 5px ${CSS_SPACING};
          border-radius: 15px;
          text-align: center;
          text-transform: uppercase;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-family: ${CSS_FONT_FAMILY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_WHITE};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .form-file-upload {
          text-align: center;
        }

        input[type='file'] {
          width: 1px;
          height: 1px;
        }

        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .item {
          margin-top: ${CSS_SPACING};
        }

        .photo {
          height: 108px;
          width: 108px;
          font-size: 60px;
          margin-bottom: 15px;
        }

        .file {
          display: none;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      initials: '',
      imgSrc: '',
    };

    this.name = '';
    this.previewTitle = 'Patient Photo';

    this.onChange = () => {};

    this.onInvalidFile = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      deletePhoto: () =>
        this.onChange({
          value: '',
          name: this.name,
        }),
      selectFile: async e => {
        const file = e.currentTarget.files[0];

        this.__elements.form.reset();

        const validSize = await validateBlob(
          file,
          MAX_FILE_SIZE,
          ALLOWED_FILE_TYPES,
        );

        if (!validSize) {
          this.onInvalidFile(IMG_EXCEEDS_SIZE_ERROR);
          return;
        }

        const imgSrc = await readDataUrlFromBlob(file);
        const validImage = await validateImage(imgSrc);

        if (!validImage) {
          this.onInvalidFile(IMG_CORRUPT_FILE_ERROR);
          return;
        }

        const resultSrc = await openPopup(KEY_PREVIEW, {
          imgSrc,
          title: this.previewTitle,
        });

        if (resultSrc) {
          this.onChange({
            value: resultSrc,
            name: this.name,
          });
        }
      },
    };
  }

  firstUpdated() {
    this.__elements = {
      form: this.shadowRoot.getElementById(ELEMENTS.form.id),
    };
  }

  render() {
    return html`
      <div class="container">
        <neb-profile-photo
          class="photo"
          id="${ELEMENTS.profilePhoto.id}"
          .model="${this.model}"
        ></neb-profile-photo>

        <form class="form-file-upload" id="${ELEMENTS.form.id}">
          <label class="custom-file-upload" id="${ELEMENTS.label.id}">
            <input
              id="${ELEMENTS.file.id}"
              class="file"
              type="file"
              accept="${ALLOWED_FILE_TYPES.join(',')}"
              @change="${this.__handlers.selectFile}"
            />
            Select Photo
          </label>
        <form>

        ${
          this.model.imgSrc
            ? html`
                <neb-button
                  id="${ELEMENTS.deleteButton.id}"
                  class="item"
                  label="Delete Photo"
                  .role="${BUTTON_ROLE.DELETE}"
                  .onClick="${this.__handlers.deletePhoto}"
                ></neb-button>
              `
            : ''
        }
      </div>
    `;
  }
}

window.customElements.define('neb-profile-photo-upload', NebProfilePhotoUpload);
