const getAspectRatio = (width, height) => width / height;

const heightByAspect = (width, aspectRatio) => Math.round(width / aspectRatio);

const widthByAspect = (height, aspectRatio) => Math.round(height * aspectRatio);
/**
 * Utility method to adjust the image and canvas to scale up
 * or down based on the parent container dimensions, while
 * keeping the correct image aspect ratio.
 *
 * NOTE: Coupled to NebImageViewer
 *
 * @param {*} containerWidth - the parent container widtch
 * @param {*} containerHeight - the parent container height
 * @param {*} imageWidth - image width
 * @param {*} imageHeight - image height
 * @param {*} nebImageViewer - the NebImageViewer
 */

export const adjustCanvasImageToContainer = (
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight,
  nebImageViewer,
) => {
  let adjustedImageWidth;
  let adjustedImageHeight;

  const imgAspectRatio = getAspectRatio(imageWidth, imageHeight);
  const widthOverflow = imageWidth - containerWidth;
  const heightOverflow = imageHeight - containerHeight;
  const widthOverflows = widthOverflow > 0;
  const heightOverflows = heightOverflow > 0;
  const imageOverflows = widthOverflows || heightOverflows;
  const widthLargerOverflow = widthOverflows
    ? widthOverflow >= heightOverflow
    : null;

  if (imageOverflows && widthLargerOverflow) {
    adjustedImageWidth = containerWidth;
    adjustedImageHeight = heightByAspect(adjustedImageWidth, imgAspectRatio);
  } else if (imageOverflows) {
    adjustedImageHeight = containerHeight;
    adjustedImageWidth = widthByAspect(adjustedImageHeight, imgAspectRatio);
  } else {
    // No overflows, assume one part is underflowing.
    const widthGreaterUnderflow = widthOverflow > heightOverflow;

    if (widthGreaterUnderflow) {
      adjustedImageHeight = containerHeight;
      adjustedImageWidth = widthByAspect(adjustedImageHeight, imgAspectRatio);
    } else {
      adjustedImageWidth = containerWidth;
      adjustedImageHeight = heightByAspect(adjustedImageWidth, imgAspectRatio);
    }
  }

  const adjustedWidthOverflow = adjustedImageWidth - containerWidth;
  const adjustedHeightOverflow = adjustedImageHeight - containerHeight;
  const adjustedWidthOverflows = adjustedWidthOverflow > 0;
  const adjustedHeightOverflows = adjustedHeightOverflow > 0;
  const adjustedImageOverflows =
    adjustedWidthOverflows || adjustedHeightOverflows;

  if (adjustedImageOverflows) {
    const { width, height } = adjustCanvasImageToContainer(
      containerWidth,
      containerHeight,
      adjustedImageWidth,
      adjustedImageHeight,
      nebImageViewer,
    );
    adjustedImageWidth = width;
    adjustedImageHeight = height;
  } else {
    nebImageViewer.adjustedImageWidth = adjustedImageWidth;
    nebImageViewer.adjustedImageHeight = adjustedImageHeight;
    adjustedImageWidth += 'px';
    adjustedImageHeight += 'px';
  }

  return {
    width: imageWidth ? adjustedImageWidth : null,
    height: imageHeight ? adjustedImageHeight : null,
  };
};
