import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'reports' });

export const getReport = reportName =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/v1/tenants/:tenantId/reports/:name',
    replacements: { name: reportName },
  });

export const getReports = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/v1/tenants/:tenantId/reports',
  });

export const getReportsV2 = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/v2/tenants/:tenantId/reports',
  });

export const getPatientDemographicReportUrl = async () => {
  const results = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/v1/tenants/:tenantId/reports/patient-demographic',
  });

  return { reportURL: results.reportURL, logoutURL: results.logoutURL };
};
