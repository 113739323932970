import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles, summaryStyles } from '../../../../neb-styles/neb-styles';
import { CSS_COLOR_HIGHLIGHT } from '../../../../neb-styles/neb-variables';
import {
  capitalize,
  formatAge,
  formatPhoneNumber,
} from '../../../../neb-utils/formatters';
import { buildPatientName } from '../../../../neb-utils/neb-patient-name-util';

export const ELEMENTS = {
  containerContact: {
    selector: '.phone-item',
  },
  containerEmail: {
    selector: '.email-item',
  },
  containerEdit: {
    id: 'container-edit',
  },
  containerGuarantor: {
    id: 'container-patient-profile',
  },
  iconEdit: {
    id: 'icon-edit',
  },
  linkAddress: {
    id: 'link-address',
  },
  linkEdit: {
    id: 'link-edit-patient',
  },
  textAge: {
    id: 'text-age',
  },
  textDateOfBirth: {
    id: 'text-date-of-birth',
  },
  textGender: {
    id: 'text-gender',
  },
  textMrn: {
    id: 'text-mrn',
  },
  textName: {
    id: 'text-name',
  },
  textPhone: {
    selector: '.phone-text',
  },
  textPhoneType: {
    selector: '.phone-type-text',
  },
  textAddress: {
    selector: '.address-text',
  },
};

class NebGuarantorSummary extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      showEdit: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = null;
    this.showEdit = true;

    this.onEdit = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      edit: () => this.onEdit(),
    };
  }

  __buildLine(memo, key, value) {
    const separator = key === 'zipcode' ? ' ' : ', ';
    return memo === '' ? value : `${memo}${separator}${value}`;
  }

  __formatAddress(address) {
    const { city, state, zipcode, ...addressLines } = address;
    const lines = Object.values(addressLines).map(line =>
      this.__getValue(line),
    );
    const line = Object.entries({
      city,
      state,
      zipcode,
    }).reduce((memo, [key, value]) => {
      const theValue = this.__getValue(value);

      if (!theValue) return memo;

      return this.__buildLine(memo, key, theValue);
    }, '');

    return [...lines, line];
  }

  __getValue(value) {
    return value.value !== undefined ? value.value : value;
  }

  static get styles() {
    return [
      baseStyles,
      summaryStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .icon {
          margin-right: 4px;
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  __renderName() {
    const name = this.model.firstName
      ? buildPatientName(this.model, true)
      : this.model.name;

    return html`
      <div>
        <p id="${ELEMENTS.textName.id}" class="text text-bold">${name}</p>
      </div>
    `;
  }

  __renderGender() {
    return this.model.sex
      ? html`
          <p id="${ELEMENTS.textGender.id}" class="text">
            ${capitalize(this.model.sex)}
          </p>
        `
      : '';
  }

  __renderDOB() {
    return this.model.dateOfBirth
      ? html`
          <p id="${ELEMENTS.textAge.id}" class="text">
            ${formatAge(this.model.dateOfBirth)}
          </p>
        `
      : '';
  }

  __renderMrn() {
    return this.model.medicalRecordNumber
      ? html`
          <p id="${ELEMENTS.textMrn.id}" class="text">
            MRN: ${this.model.medicalRecordNumber}
          </p>
        `
      : '';
  }

  __renderBasicInfo() {
    if (!this.model.sex && !this.model.dateOfBirth) return '';

    return html`
      <div class="spacer">
        ${this.__renderGender()} ${this.__renderDOB()} ${this.__renderMrn()}
      </div>
    `;
  }

  __renderPhoneNumbers() {
    return this.model.phones
      ? this.model.phones.map(phone => this.__renderPhoneNumber(phone))
      : '';
  }

  __renderPhoneNumber(phone) {
    return phone.number
      ? html`
          <div class="phone-item">
            <span class="text text-link">
              <a class="text phone-text" href="tel:+1${phone.number}">
                ${formatPhoneNumber(phone.number)}
              </a>
            </span>
            <span class="text phone-type-text">(${phone.type})</span>
          </div>
        `
      : '';
  }

  __renderEmailAddress() {
    return this.model.emails
      ? this.model.emails.map(
          email => html`
            <a class="text text-address email-item" href="mailto:${email}">
              <p class="text text-link email-text">${email}</p>
            </a>
          `,
        )
      : '';
  }

  __renderAddress() {
    const { address } = this.model;

    if (!address) {
      return '';
    }

    const lines = this.__formatAddress(this.model.address);

    return html`
      <div class="spacer"></div>
      <a
        id="${ELEMENTS.linkAddress.id}"
        class="text text-address"
        target="_blank"
        href="https://maps.google.com/maps?q=${lines.join(',')}"
      >
        ${lines.map(
          line => html` <p class="text text-link address-text">${line}</p> `,
        )}
      </a>
    `;
  }

  __renderEdit() {
    return html`
      <div class="spacer"></div>
      <div id="${ELEMENTS.containerEdit.id}" class="container-edit">
        <neb-icon
          id="${ELEMENTS.iconEdit.id}"
          class="icon"
          icon="neb:edit"
        ></neb-icon>

        <span
          id="${ELEMENTS.linkEdit.id}"
          class="text text-link text-highlight text-bold"
          @click="${this.__handlers.edit}"
          >EDIT</span
        >
      </div>
    `;
  }

  render() {
    return this.model
      ? html`
          <div id="${ELEMENTS.containerGuarantor.id}" class="container">
            ${this.__renderName()} ${this.__renderBasicInfo()}
            ${this.__renderPhoneNumbers()} ${this.__renderEmailAddress()}
            ${this.__renderAddress()}
            ${this.showEdit ? this.__renderEdit() : ''}
          </div>
        `
      : '';
  }
}

window.customElements.define('neb-guarantor-summary', NebGuarantorSummary);
