import { css } from 'lit';

import { COVERED_SERVICE_TYPE } from '../../../../neb-utils/patientInsurance';
import { formatCode } from '../patients/ledger/charges/neb-ledger-charges-util';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
};

const CONFIG = [
  {
    key: 'serviceTypeProcedureCode',
    label: 'Service Type/ Procedure Code',
    flex: css`1`,
    sortable: true,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`2`,
    sortable: true,
  },
];

class NebTableNonCoveredServices extends NebTable {
  initState() {
    super.initState();

    this.config = CONFIG;
    this.sortParams = [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }
      `,
    ];
  }

  renderDataCell(_, columnConfig, rowIndex) {
    const row = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'serviceTypeProcedureCode':
        if (row.type === COVERED_SERVICE_TYPE.ServiceType) {
          return row.serviceType.code;
        }
        return formatCode(row.charge.procedure, row.charge.modifiers);

      case 'description':
        if (row.type === COVERED_SERVICE_TYPE.ServiceType) {
          return row.serviceType.description;
        }
        return row.charge.description;

      default:
        return '';
    }
  }
}

customElements.define(
  'neb-table-non-covered-services',
  NebTableNonCoveredServices,
);
