import { saveToLocalStorage } from './local-storage-util';
import { getNavigationState } from './navigation-util';

export const LOG_DUPLICATE = Object.freeze({
  LOCAL_STORAGE: 'localStorage',
  CONSOLE_LOG: 'consoleLog',
  NONE: '',
});

const state = Object.freeze({
  apiCalls: [],
  duplicateApiCalls: [],
});

let logCall = false;

export const getHashFromString = text =>
  text
    .split('')
    .reduce((prev, curr) => Math.imul(31, prev) + curr.charCodeAt(0) || 0, 0);

const getHash = body => {
  if (!body) {
    return '';
  }

  let hash = 0;

  if (typeof body === 'string') {
    hash = getHashFromString(body);

    return hash.toString();
  }

  if (body instanceof Blob) {
    hash = body.size || 0;

    return hash.toString();
  }

  if (body instanceof FormData) {
    const valuesString = [...body.values()].join('');

    hash = getHashFromString(valuesString);

    return hash.toString();
  }
  return 'unknown';
};

const findDuplicates = items => {
  const itemData = {};

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item.url in itemData) {
      itemData[item.url].count += 1;
      itemData[item.url].dates.push(item.date);
    } else {
      itemData[item.url] = { count: 1, dates: [item.date] };
    }
  }

  const duplicates = [];
  Object.entries(itemData).forEach(([key, value]) => {
    if (value.count > 1) {
      duplicates.push({ apiUrl: key, ...value });
    }
  });

  return duplicates;
};

const logDuplicates = logType => {
  if (logType === LOG_DUPLICATE.CONSOLE_LOG) {
    console.log('duplicate api calls: ', state.duplicateApiCalls);
    return;
  }

  if (logType === LOG_DUPLICATE.LOCAL_STORAGE) {
    saveToLocalStorage(
      'duplicateApiCalls',
      JSON.stringify(state.duplicateApiCalls, null, 2),
    );
  }
};

export const addToApiCallLog = ({ method, url, body }) => {
  try {
    if (!logCall) {
      return null;
    }
    const hash = getHash(body);
    const hashText = hash ? ` body:${hash}` : '';
    const urlText = `${method.toLowerCase()} ${url}${hashText}`;
    const apiCall = { url: urlText, date: new Date().toISOString() };
    state.apiCalls.push(apiCall);

    return apiCall;
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const start = () => {
  state.apiCalls.length = 0;
  state.duplicateApiCalls.length = 0;
  logCall = true;
};

export const reset = () => {
  state.apiCalls.length = 0;
  state.duplicateApiCalls.length = 0;
  logCall = false;
};

export const logApiCalls = (logType = LOG_DUPLICATE.CONSOLE_LOG) => {
  try {
    const duplicates = findDuplicates(state.apiCalls);

    if (duplicates.length) {
      state.duplicateApiCalls.push({
        pageUrl: getNavigationState(),
        duplicates,
      });

      logDuplicates(logType);
    }

    state.apiCalls.length = 0;

    return duplicates;
  } catch (err) {
    console.error(err);
  }

  return null;
};
