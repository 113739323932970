/* eslint-disable no-await-in-loop */
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-text';
import '../../neb-lit-components/src/components/neb-loading-spinner-resizable';

import NebPopup from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  buttonCancel: {
    id: 'button-cancel',
  },
  title: {
    id: 'title',
  },
};

class NebPopupUploadProcessing extends NebPopup {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      dirty: {
        type: Boolean,
      },
      __currentFile: {
        type: Object,
      },
      __uploadedCounter: {
        type: Number,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
        }

        .spinner {
          padding-bottom: 50px;
          text-align: center;
          z-index: 1;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.model = {
      files: [],
      clearinghouse: {},
      uploadFile: () => {},
    };

    this.title = 'Upload Processing';
    this.showCancelButton = true;
    this.__currentFile = {};
    this.__cancelUpload = false;
    this.__uploadedCounter = 0;
  }

  initHandlers() {
    this.handlers = {
      cancel: async () => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Cancel Upload',
          message: 'Are you sure you want to cancel upload?',
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (accepted) {
          this.__cancelUpload = true;
          this.onClose(this.__uploadedCounter);
        }
      },
    };
  }

  firstUpdated() {
    Promise.resolve(this.__uploadFiles()).then(value => {
      if (value) this.onClose(this.__uploadedCounter);
    });
  }

  async __uploadFiles() {
    for (let i = 0; i < this.model.files.length; i++) {
      if (this.__cancelUpload) {
        return false;
      }

      this.__currentFile = this.model.files[i];
      this.title = `Upload Processing (${i + 1}/${this.model.files.length})`;

      try {
        await this.model.uploadFile(
          this.__currentFile,
          true,
          this.model.clearinghouse,
        );

        this.__uploadedCounter += 1;
      } catch (e) {
        console.log(e);
      }
    }

    return true;
  }

  renderContent() {
    const status = `${this.__currentFile.name || ''}`;

    return html`
      <neb-text>${status}</neb-text>

      <div class="container-content">
        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>
      </div>

      <neb-button
        id="${ELEMENTS.buttonCancel.id}"
        class="button"
        label="CANCEL"
        .role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }
}

customElements.define('neb-popup-upload-processing', NebPopupUploadProcessing);
