import { OVERLAY_KEYS } from '../../packages/neb-lit-components/src/utils/overlay-constants';

export const SCRUBBING_OVERLAYS = {
  ruleSetPayerGroups: OVERLAY_KEYS.ASSOCIATE_PAYER_GROUPS,
  ruleSetPayers: OVERLAY_KEYS.ASSOCIATE_RULE_SET_PAYER,
  ruleSetRules: OVERLAY_KEYS.ASSOCIATE_RULES,
  ruleSetCharges: OVERLAY_KEYS.ASSOCIATE_CHARGES,
};

export function getOverlayDetails(name, state) {
  return {
    [name]: [...state[name]],
    ...(name === 'ruleSetRules' ? { level: state.level } : {}),
  };
}
