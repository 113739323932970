import '../../../../packages/neb-www-practice-charting/src/components/treatmentPlan/neb-charting-treatment-plan-detail';

import { html } from 'lit';

import {
  getEmptyTreatmentPlan,
  getCompletedTreatmentPlans,
} from '../../../../packages/neb-api-client/src/treatment-plans';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  calculateTreatmentPlanForPatient,
  copyTreatmentPlan,
  saveTreatmentPlan,
} from '../../../utils/treatment-plan-processor';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
  DEFAULT_SAVE_OPTS,
} from '../neb-unsigned-encounter-detail';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

const TREATMENT_PLAN_ITEM_NAME = 'Treatment Plan';

class NebUnsignedEncounterTreatmentPlanDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      __completedTreatmentPlans: { type: Array },
      __patientId: { type: String },
    };
  }

  static get config() {
    return {
      itemName: TREATMENT_PLAN_ITEM_NAME,
      refetchKey: PANEL_KEYS.TREATMENT_PLAN,
    };
  }

  static createModel() {
    return getEmptyTreatmentPlan();
  }

  initState() {
    super.initState();
    this.__completedTreatmentPlans = [];
    this.__patientId = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      complete: async treatmentPlan => {
        treatmentPlan.status = 'Completed';
        await this.handlers.save(treatmentPlan, {
          ...DEFAULT_SAVE_OPTS,
          successAction: 'completed',
        });

        return this.__fetchCompletedTreatmentPlans();
      },
      calculate: treatmentPlan =>
        calculateTreatmentPlanForPatient(treatmentPlan, this.__patientId),
      deletePhaseError: message => {
        store.dispatch(openError(message));
      },
      copyTreatmentPlan: async treatmentPlan => {
        const success = await copyTreatmentPlan(
          treatmentPlan,
          this.encounter.id,
          this.encounter.providerId,
        );

        if (success) {
          this.onRefetch(PANEL_KEYS.CHART_NOTES);
        }
      },
    };
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  isDirty() {
    return this.__elements.view.isDirty();
  }

  save(model) {
    return saveTreatmentPlan(model, this.__patientId);
  }

  getTitle() {
    return this.model.treatmentPhases.length > 0
      ? `${TREATMENT_PLAN_ITEM_NAME} (${this.model.treatmentPhases.length})`
      : TREATMENT_PLAN_ITEM_NAME;
  }

  async __fetchCompletedTreatmentPlans() {
    this.__completedTreatmentPlans = await getCompletedTreatmentPlans(
      this.__patientId,
    );
  }

  updated(changedProps) {
    if (
      changedProps.has('encounter') &&
      this.encounter.id &&
      this.__patientId !== this.encounter.patientId
    ) {
      this.__patientId = this.encounter.patientId;
      this.__fetchCompletedTreatmentPlans();
    }
  }

  renderContent() {
    return html`
      <neb-charting-treatment-plan-detail
        slot="detail"
        id="${ELEMENTS.view.id}"
        .small="${this.layout === 'small'}"
        .copyTreatmentPlanButtonVisible="${true}"
        .patientId="${this.encounter.patientId}"
        .treatmentPlan="${this.model}"
        .completedTreatmentPlans="${this.__completedTreatmentPlans}"
        .onComplete="${this.handlers.complete}"
        .onSave="${this.handlers.save}"
        .onCalculate="${this.handlers.calculate}"
        .onDeletePhaseError="${this.handlers.deletePhaseError}"
        .onCopyTreatmentPlan="${this.handlers.copyTreatmentPlan}"
        .onChangeDirty="${this.handlers.changeDirty}"
        enable-save-and-close
      ></neb-charting-treatment-plan-detail>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-treatment-plan-detail',
  NebUnsignedEncounterTreatmentPlanDetail,
);
