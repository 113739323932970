import {
  KEY_LOCAL_PRACTICE_TIMEZONE,
  KEY_LOCAL_PRACTICE_ENTERPRISE_ID,
  requestPracticeInformation,
  requestPracticeInformationFailure,
  requestPracticeInformationSuccess,
} from '../../neb-practice-information/actions/practice-information';

import DeprecatedApiClient, { Method } from './utils/api-client-utils';

export const BASE_URL = 'tenants';

export const apiClient = new DeprecatedApiClient({ microservice: 'registry' });

export async function getPracticeInformation(
  tenantId,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `${BASE_URL}/${tenantId}`,
    version: 2,
  });

  return res.data.length ? res.data[0] : {};
}

export async function putPracticeInformation(
  model,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    optOutLoadingIndicator,
    path: `${BASE_URL}/${model.id}`,
    version: 2,
  });

  return res.data.length ? res.data[0] : {};
}

export function fetchPracticeInformation(
  tenantId,
  optOutLoadingIndicator = false,
) {
  return async dispatch => {
    dispatch(requestPracticeInformation());

    try {
      const res = await apiClient.makeRequest({
        method: Method.GET,
        path: `${BASE_URL}/${tenantId}`,
        version: 2,
        queryParams: { showAddressOnly: true },
        headers: {
          'Content-Type': 'application/json',
        },
        optOutLoadingIndicator,
      });

      if (res.data && res.data.length > 0) {
        window.localStorage.setItem(
          KEY_LOCAL_PRACTICE_TIMEZONE,
          res.data[0].timezone,
        );

        const { enterpriseId } = res.data[0];

        const enterpriseIdString = JSON.stringify(enterpriseId);

        window.localStorage.setItem(
          KEY_LOCAL_PRACTICE_ENTERPRISE_ID,
          enterpriseIdString,
        );
      }

      return dispatch(requestPracticeInformationSuccess(res));
    } catch (err) {
      const errObj = err.res ? err.res : err;
      console.log(errObj);
      return dispatch(requestPracticeInformationFailure(errObj));
    }
  };
}
