import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  dateRangeCell: { id: 'date-range-cell' },
  dateCell: { id: 'date-cell' },
};

class NebClaimFormCellDateRange extends LitElement {
  static get properties() {
    return {
      renderExpandIcon: { type: Boolean, reflect: true },
      fromDate: String,
      toDate: String,
      label: String,
      tooltipText: String,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.renderExpandIcon = false;
    this.fromDate = '';
    this.toDate = '';
    this.label = '';
    this.tooltipText = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => this.onClick(e),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .cell {
          display: grid;
          width: 100%;
        }

        .two-column-cell {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: ${CSS_SPACING};
        }

        .date-column {
          display: flex;
        }

        .label {
          padding-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderDateRange() {
    return this.fromDate
      ? html`
          <div id="${ELEMENTS.dateRangeCell.id}" class="two-column-cell">
            <div class="date-column">
              <div class="label">From</div>
              <div>${this.fromDate}</div>
            </div>
            <div class="date-column">
              <div class="label">To</div>
              <div>${this.toDate}</div>
            </div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <neb-claim-form-cell
        id="${ELEMENTS.dateCell.id}"
        class="cell"
        label="${this.label}"
        type="dateRange"
        borderBottom
        borderRight
        .tooltipText="${this.tooltipText}"
        .renderExpandIcon="${this.renderExpandIcon}"
        .onClick="${this.__handlers.click}"
      >
        ${this.__renderDateRange()}
      </neb-claim-form-cell>
    `;
  }
}

window.customElements.define(
  'neb-claim-form-cell-date-range',
  NebClaimFormCellDateRange,
);
