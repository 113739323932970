import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const ID_TITLE = 'title';

class NebAssociates extends LitElement {
  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {}

  __initHandlers() {
    this.__handlers = {};
  }

  static get styles() {
    return css`
      :host {
        display: block;
        border: ${CSS_BORDER_GREY_2};
        padding: 10px;
        margin: ${CSS_SPACING} 0;
      }

      .title {
        color: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  render() {
    return html` <div id="${ID_TITLE}" class="title">Associates</div> `;
  }
}

customElements.define('neb-associates', NebAssociates);
