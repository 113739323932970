import DeprecatedApiClient from '../neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});
export const coreApiClient = new DeprecatedApiClient({ microservice: 'core' });

export const ApiClientType = Object.freeze({
  DEFAULT: 'DEFAULT',
  BILLING: 'BILLING',
});

export const createNamedReducer =
  (reducerFunction, reducerName) => (state, action) => {
    const { name } = action;
    const isInitializationCall = state === undefined;
    return isInitializationCall ||
      reducerName === name ||
      reducerName.startsWith(name) ||
      (name && name.startsWith(reducerName))
      ? reducerFunction(state, action)
      : state;
  };

const __hasSession = getState => getState().session.item != null;

const getVersionAndClient = apiClientType => {
  if (apiClientType === ApiClientType.BILLING) {
    return { apiClient: billingApiClient, version: 1 };
  }

  return { apiClient: coreApiClient, version: 1 };
};

export const createTenantApiRequestAction =
  (requestOptions, actionCreators, apiClientType = ApiClientType.CORE) =>
  async (dispatch, getState) => {
    const { url, query, optOutLoadingIndicator, options } = requestOptions;
    const [startActionCreator, completeActionCreator, errorActionCreator] =
      actionCreators;
    if (!__hasSession(getState)) return undefined;
    dispatch(startActionCreator());

    try {
      const { apiClient, version } = getVersionAndClient(apiClientType);

      const json = await apiClient.makeRequest({
        path: url,
        queryParams: query,
        optOutLoadingIndicator,
        version,
        ...options,
      });
      return dispatch(completeActionCreator(json));
    } catch (err) {
      return dispatch(errorActionCreator(err));
    }
  };
