import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';

import '../../../../src/components/controls/field-groups/neb-pill';
import '../../../../src/components/controls/field-groups/neb-tags-list';
import '../../../neb-lit-components/src/components/inputs/neb-textarea';

const DATE_FORMAT = 'MM/DD/YYYY';

export const ELEMENTS = {
  documentDate: {
    id: 'document-date',
  },
  uploadDate: {
    id: 'upload-date',
  },
  note: {
    id: 'note',
  },
  documentDateTitle: {
    id: 'document-date-title',
  },
  uploadDateTitle: {
    id: 'upload-date-title',
  },
  noteTitle: {
    id: 'note-title',
  },
  tagsList: {
    id: 'tags-list',
  },
};
export class NebDocumentsInfoDetails extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  constructor() {
    super();
    this.model = {
      id: null,
      name: '',
      note: '',
      date: null,
      file: null,
      uploadDate: null,
      documentTags: [],
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          height: 100%;
          min-width: 0;
        }

        neb-tags-list {
          margin-top: 5px;
        }
      `,
    ];
  }

  __renderTags() {
    return this.model.documentTags.length
      ? html`
          <neb-tags-list
            id="${ELEMENTS.tagsList.id}"
            .model="${{ tags: this.model.documentTags }}"
          ></neb-tags-list>
        `
      : '';
  }

  render() {
    return html`
      <div>
        <span id="${ELEMENTS.uploadDateTitle.id}">Upload Date:</span>
        <span id="${ELEMENTS.uploadDate.id}"
          >${this.model.uploadDate.format(DATE_FORMAT)}</span
        >
      </div>
      <div>
        <span id="${ELEMENTS.documentDateTitle.id}">Document Date:</span>
        <span id="${ELEMENTS.documentDate.id}"
          >${this.model.documentDate.format(DATE_FORMAT)}</span
        >
      </div>
      ${this.__renderTags()}
      <neb-textarea
        id="${ELEMENTS.note.id}"
        label="Note"
        name="note"
        style="margin-top: ${this.model.note ? '10' : '-5'}px"
        .value="${this.model.note}"
        ?disabled="${true}"
      ></neb-textarea>
    `;
  }
}
customElements.define('neb-documents-info-details', NebDocumentsInfoDetails);
