import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/macros/neb-macro-spine';
import './neb-popup-action-buttons';

import { html, css } from 'lit';

import {
  formatSpineLabel,
  SPINE_LABEL_INDEX,
  SPINE_SECTION,
} from '../../neb-lit-components/src/components/macros/utils/spine';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { arrayToSentence } from '../../neb-utils/array-to-sentence';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

export const ORDER = {
  LIST: 'list',
  CLICK: 'click',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  clickOrderRadio: {
    id: 'click-radio',
  },
  listOrderRadio: {
    id: 'list-radio',
  },
  spine: {
    id: 'spine',
  },
};

class NebPopupMacrosSpine extends NebPopupMacros {
  static get properties() {
    return {
      __selectedSections: Array,
      __selectedSort: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .radio-container {
          display: flex;
          margin-top: 10px;
        }

        .list-radio {
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedSections = [];
    this.__selectedSort = ORDER.CLICK;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      toggleSection: (direction, section) => {
        const fullSectionName = formatSpineLabel(direction, section);

        if (section !== SPINE_SECTION.PELVIS) {
          this.__selectedSections = this.__selectedSections.filter(
            s => s === fullSectionName || !s.endsWith(section),
          );
        }

        const sectionIndex = this.__selectedSections.indexOf(fullSectionName);

        if (sectionIndex !== -1) {
          const newSelectedSections = [...this.__selectedSections];
          newSelectedSections.splice(sectionIndex, 1);
          this.__selectedSections = newSelectedSections;
        } else {
          this.__selectedSections = [
            ...this.__selectedSections,
            fullSectionName,
          ];
        }

        this.handlers.change(this.genAnswerFromSelections());
      },
      selectSort: sort => {
        this.__selectedSort = sort;
        this.handlers.change(this.genAnswerFromSelections());
      },
    };
  }

  buildExtraAnswerProps() {
    return {
      order: this.__selectedSort,
      selectedSections: this.__selectedSections,
    };
  }

  validateAnswer() {
    return (
      (this.__selectedSections.length && this.answer !== '') || !!this.answer
    );
  }

  modelChanged() {
    this.title = this.model.title;

    if (this.model.answer) {
      this.__selectedSort = this.model.answer.order;
      this.__selectedSections = this.model.answer.selectedSections;
      this.answer = this.model.answer.text;
    }
  }

  genAnswerFromSelections() {
    if (this.__selectedSort === 'list') {
      return arrayToSentence(
        this.__selectedSections
          .slice()
          .sort((a, b) => SPINE_LABEL_INDEX[a] - SPINE_LABEL_INDEX[b]),
      );
    }

    return arrayToSentence(this.__selectedSections);
  }

  renderMacroContent() {
    return html`
      <neb-macro-spine
        id="${ELEMENTS.spine.id}"
        .selectedSections="${this.__selectedSections}"
        .onClick="${this.handlers.toggleSection}"
      ></neb-macro-spine>
    `;
  }

  renderFooter() {
    return html`
      <div class="radio-container">
        <neb-radio-button
          id="${ELEMENTS.clickOrderRadio.id}"
          .label="${'Display in click order'}"
          .value="${ORDER.CLICK}"
          .checked="${this.__selectedSort === ORDER.CLICK}"
          .onSelect="${this.handlers.selectSort}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.listOrderRadio.id}"
          class="list-radio"
          .label="${'OCC to Coccyx'}"
          .checked="${this.__selectedSort === ORDER.LIST}"
          .value="${ORDER.LIST}"
          .onSelect="${this.handlers.selectSort}"
        ></neb-radio-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-macros-spine', NebPopupMacrosSpine);
