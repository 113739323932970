import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_BADGE,
  CSS_FONT_SIZE_BADGE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  count: {
    id: 'count',
  },
};

class NebBadge extends LitElement {
  static get properties() {
    return {
      count: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.count = 0;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .content {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 15px;
          height: 15px;

          border-radius: 50%;
          line-height: 25px;

          margin: 0;

          color: ${CSS_COLOR_WHITE};
          background-color: ${CSS_COLOR_BADGE};
          text-align: center;
          font-size: ${CSS_FONT_SIZE_BADGE};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  render() {
    return html`
      <p
        id="${ELEMENTS.count.id}"
        class="content"
        @click="${this.__handlers.click}"
      >
        ${this.count > 99 ? '99+' : this.count}
      </p>
    `;
  }
}

window.customElements.define('neb-badge', NebBadge);
