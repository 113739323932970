import { html, css } from 'lit';

import {
  createMerchantAccount,
  updateMerchantAccount,
} from '../../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import {
  openError,
  openSuccess,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import NebFormMerchantAccount from '../../forms/neb-form-merchant-account';
import NebOverlayFormItem, {
  ELEMENTS as ELEMENTS_BASE,
} from '../neb-overlay-form-item';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  form: {
    id: 'form',
  },
};

export const MERCHANT_ACCOUNT_SAVE_SUCCESS =
  'Merchant account saved successfully';
export const MERCHANT_ACCOUNT_SAVE_ERROR =
  'An error occurred when saving the merchant account';

const NULL_ACCOUNT_PASSWORD = null;

class NebOverlayMerchantAccount extends NebOverlayFormItem {
  static get properties() {
    return {
      __merchantAccount: Object,
      __cardReaders: Array,
    };
  }

  static get config() {
    return {
      form: NebFormMerchantAccount,
      itemName: 'Merchant Account',
      messageError: MERCHANT_ACCOUNT_SAVE_ERROR,
      messageSuccess: MERCHANT_ACCOUNT_SAVE_SUCCESS,
    };
  }

  initState() {
    super.initState();
    this.__merchantAccount = NebFormMerchantAccount.createModel();
    this.__cardReaders = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async item => {
        try {
          const result = await this.save(item);
          store.dispatch(openSuccess(this.constructor.config.messageSuccess));
          this.isDirty = false;
          this.dismiss(result);
        } catch (e) {
          if (e.responseBody?.fiservError) {
            store.dispatch(openError(e.responseBody.fiservError));
          } else if (e.statusCode !== 409) {
            store.dispatch(openError(this.constructor.config.messageError));
          }
        }
      },
    };
  }

  save(item) {
    if (!item.id) {
      return createMerchantAccount(item);
    }
    return updateMerchantAccount({
      ...item,
      cardReaders: item.cardReaders.map(r => r.id),
    });
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__cardReaders = this.model.context?.cardReaders || [];

      if (this.model.context.item) {
        this.__merchantAccount = {
          ...this.model.context.item,
          accountPassword: NULL_ACCOUNT_PASSWORD,
        };
      } else {
        this.__merchantAccount = { ...this.__merchantAccount };
      }
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }
      `,
    ];
  }

  renderForm() {
    return html`
      <neb-form-merchant-account
        id="${ELEMENTS.form.id}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .accounts="${this.model.context.accounts}"
        .allCardReaders="${this.__cardReaders}"
        .model="${this.__merchantAccount}"
      ></neb-form-merchant-account>
    `;
  }
}

customElements.define(
  'neb-overlay-merchant-account',
  NebOverlayMerchantAccount,
);
