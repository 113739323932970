import '../../neb-pagination';
import '../../neb-popup-header';
import '../../neb-action-bar';
import '../../inputs/neb-textfield';
import '../../tables/neb-table-patient-procedure-select';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../neb-styles/neb-variables';
import { normalizeForSearch } from '../../../../../neb-utils/formatters';
import {
  CollectionService,
  SORT_DIR,
} from '../../../../../neb-utils/services/collection';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  table: {
    id: 'table',
  },
  description: {
    id: 'description',
  },
  header: {
    id: 'header',
  },
  noChargesMessage: {
    id: 'no-charges-message',
  },
  noResultsMessage: {
    id: 'no-results',
  },
  searchField: {
    id: 'search',
  },
  chargesLink: {
    id: 'charges-link',
  },
  chargeCodesLink: {
    id: 'charge-codes-link',
  },
  actionBar: {
    id: 'action-bar',
  },
  paginationControl: {
    id: 'pagination-control',
  },
};

class NebOverlayPatientInsuranceAddCoveredProcedure extends Overlay {
  static get properties() {
    return {
      __currentPageIndex: {
        type: Number,
      },
      __items: {
        type: Array,
      },
      __pageCount: {
        type: Number,
      },
      __searchText: {
        type: String,
      },
      __selectedCharges: {
        type: Array,
      },
      __selectedSecondary: {
        type: Array,
      },
    };
  }

  initState() {
    super.initState();
    this.model = {
      items: [],
      selectedCharges: [],
      selectedSecondary: [],
    };

    this.__currentPageIndex = 0;
    this.__items = [];
    this.__pageCount = 0;
    this.__searchText = '';
    this.__selectedCharges = [];
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__items = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },

        onCacheItem: ({ procedure, description }) =>
          normalizeForSearch([procedure, description].join(' ')),

        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
        onSort: (a, b, key) => a[key].localeCompare(b[key]),
      },
      {
        hideInactive: false,
        sortParams: {
          key: 'procedure',
          dir: SORT_DIR.ASC,
        },
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      apply: () => this.dismiss(this.__selectedCharges),
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
      clearSearch: () => this.__collectionService.search(''),
      clickCharges: () => {
        window.location.href = '/settings/#/charges';
      },
      clickChargeCodes: () => {
        window.location.href = '/settings/#/billing-codes/charge';
      },
      search: ({ value }) => {
        if (value !== this.__searchText) this.__collectionService.search(value);
      },
      addCharge: rowIndex => {
        const newSelected = this.model.selectedCharges;
        newSelected.push({ id: this.__items[rowIndex].id });
        this.model = { ...this.model, selectedCharges: newSelected };
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__collectionService.setItems(this.model.items);
      this.__selectedCharges = [...this.model.selectedCharges] || [];
      this.__selectedSecondary = this.model.selectedSecondary || [];
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .icon-chevron {
          height: 12px;
          width: 12px;
          transform: rotate(90deg);
        }

        .main-header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING};
        }

        .header {
          display: flex;
          align-items: center;
          padding: ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .description {
          padding: 5px 0 0 ${CSS_SPACING};
        }

        .add-button {
          padding: ${CSS_SPACING};
        }

        .search-field {
          min-height: 70px;
          margin: 15px ${CSS_SPACING} 20px;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }

        .container-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="main-header"
        title="${this.model.coveredType
          ? 'Add Covered Service - Procedure Code'
          : 'Add Non-Covered Service - Procedure Code'}"
        showCancelButton
        .onCancel="${this.handlers.apply}"
      ></neb-popup-header>
    `;
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.model.coveredType
          ? 'Search for and select procedure codes, then proceed to add coverage details.'
          : 'Search for and select procedure codes and add as a non-covered service.'}
      </div>
    `;
  }

  __renderSearchField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="search-field"
        label="Enter code or description to filter list below."
        leadingIcon="neb:search"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .trailingVisible="${!!this.__searchText}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  __renderNoResultsMessage() {
    return html`
      <p id="${ELEMENTS.noResultsMessage.id}" class="no-items-message">
        No results.
      </p>
    `;
  }

  __renderNoChargesMessage() {
    return html`
      <p id="${ELEMENTS.noChargesMessage.id}" class="no-items-message">
        There are no Charges or Charge Codes. Please configure
        <span
          id="${ELEMENTS.chargesLink.id}"
          class="text-link"
          @click="${this.handlers.clickCharges}"
          >Charges
        </span>
        or
        <span
          id="${ELEMENTS.chargeCodesLink.id}"
          class="text-link"
          @click="${this.handlers.clickChargeCodes}"
          >Charge Codes
        </span>
        for the practice.
      </p>
    `;
  }

  __renderNoItemsMessage() {
    return this.__searchText
      ? this.__renderNoResultsMessage()
      : this.__renderNoChargesMessage();
  }

  __renderPaginationControl() {
    return html`
      <neb-pagination
        id="${ELEMENTS.paginationControl.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  __renderTable() {
    return this.__items.length > 0
      ? html`
          <div class="container-table">
            <neb-table-patient-procedure-select
              id="${ELEMENTS.table.id}"
              class="header-table"
              .layout="${this.layout}"
              .model="${this.__items}"
              .coveredType="${this.model.coveredType}"
              .onAddCharge="${this.handlers.addCharge}"
              .selectedItems="${this.__selectedCharges}"
              .selectedSecondary="${this.__selectedSecondary}"
            ></neb-table-patient-procedure-select>
          </div>

          ${this.__items.length ? this.__renderPaginationControl() : ''}
        `
      : html` ${this.__renderNoItemsMessage()} `;
  }

  __renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}${this.__renderDescription()}
      ${this.__renderSearchField()}${this.__renderTable()}
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define(
  'neb-overlay-patient-insurance-add-covered-procedure',
  NebOverlayPatientInsuranceAddCoveredProcedure,
);
