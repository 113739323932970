import '../neb-styles/neb-icons';
import '../../src/components/misc/neb-icon';

import { LitElement, html } from 'lit';

import { renderNebStyles } from '../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
} from '../neb-styles/neb-variables';

class NebSortableColumn extends LitElement {
  static get properties() {
    return {
      sortField: {
        type: String,
      },
      sortOrder: {
        type: String,
      },
      selected: {
        type: Boolean,
      },
      isDefault: {
        type: Boolean,
      },
      defaultOrder: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.defaultOrder = 'asc';

    this.onSort = () => {};
  }

  render() {
    return html`
      ${renderNebStyles()}
      <style>
        .container {
          display: flex;
          cursor: pointer;
        }

        slot {
          white-space: nowrap;
        }

        .icon-arrow {
          cursor: pointer;
          width: 10px;
          height: 10px;
          fill: ${CSS_COLOR_GREY_2};
        }

        .icon-arrow[selected] {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-arrow-up {
          transform: scaleY(-1);
        }

        .container-arrow {
          display: flex;
          margin-left: 10px;
          justify-content: flex-end;
          flex-flow: column nowrap;
        }
      </style>
      <div
        id="columnContainer"
        class="container"
        @click="${() => this.__selectColumn()}"
      >
        <slot></slot>

        <div id="${this.sortField}Icon" class="container-arrow">
          <neb-icon
            class="icon-arrow icon-arrow-up"
            icon="neb:arrow"
            ?selected="${this.sortOrder === 'asc'}"
          ></neb-icon>

          <neb-icon
            class="icon-arrow"
            icon="neb:arrow"
            ?selected="${this.sortOrder === 'desc'}"
          ></neb-icon>
        </div>
      </div>
    `;
  }
  /**
   * Set order to '' and selected to false.
   */

  deselect() {
    this.sortOrder = '';
    this.selected = false;
  }
  /**
   * Put column in default state and select column.
   * This will trigger dispatch of sort order change.
   */

  reset() {
    this.__handleSortOrderChange(this.defaultOrder);

    this.selected = true;
  }

  __selectColumn() {
    this.selected = true;

    this.__handleSortOrderChange(this.__getToggle(this.sortOrder));
  }

  __handleSortOrderChange(sortOrder) {
    this.sortOrder = sortOrder;
    this.dispatchEvent(
      new CustomEvent('neb-sort-order-changed', {
        bubbles: true,
        composed: true,
        detail: {
          element: this,
        },
      }),
    );

    this.onSort(this);
  }

  __getToggle(sortOrder) {
    if (sortOrder === 'asc') return 'desc';
    if (sortOrder === 'desc') return 'asc';
    return this.defaultOrder;
  }
}

customElements.define('neb-sortable-column', NebSortableColumn);
