import '../../neb-lit-components/src/components/patients/neb-patient-summary-controller';

import { LitElement, html, css } from 'lit';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
};

class NebPopupPatientSummary extends LitElement {
  static get properties() {
    return {
      patient: Object,
      layout: String,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.patient = {};
    this.layout = '';
  }

  static get styles() {
    return css`
      .patient-summary {
        padding: 20px 0 0 20px;
      }
    `;
  }

  render() {
    return this.layout === 'small'
      ? html`
          <neb-patient-summary-controller
            id="${ELEMENTS.patientSummary.id}"
            class="patient-summary"
            noLinks
            .layout="${this.layout}"
            .patientId="${this.patient.id}"
          ></neb-patient-summary-controller>
        `
      : html``;
  }
}

customElements.define('neb-popup-patient-summary', NebPopupPatientSummary);
