import '../neb-tooltip';
import '../inputs/neb-select';
import '../inputs/neb-select-search';
import '../inputs/neb-textarea';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-date-picker';
import '../ledger/neb-all-patients-card';
import '../patients/neb-patient-card';
import { isPropRequired, isRequired, isRequiredIf } from '@neb/form-validators';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { PAYER_SELF } from '../../../../neb-api-client/src/ledger-superbill-api-client';
import { getPayerPlans } from '../../../../neb-api-client/src/payer-plan-api-client';
import { getPatientPayers } from '../../../../neb-api-client/src/payers';
import { getProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../neb-api-client/src/services/practice-settings';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../../neb-utils/collections/server-patients';
import { parseDate } from '../../../../neb-utils/date-util';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { map } from '../../../../neb-utils/utils';
import { BUTTON_ROLE } from '../neb-button';

import Form from './neb-form';
import { ITEM_ALL_PATIENTS, formatToItems } from './neb-form-new-statement';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  patientSearch: {
    id: 'patient-search',
  },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
  },
  selectPayer: {
    id: 'select-payer',
  },
  toolTipPayers: {
    id: 'tooltip-payers',
  },
  toolTipPayerPaymentDetails: {
    id: 'tooltip-payer-payment-details',
  },
  textToolTipPayerPaymentDetails: {
    id: 'text-tooltip-payer-payment-details',
  },
  toolTipPatientPaymentDetails: {
    id: 'tooltip-patient-payment-details',
  },
  textToolTipPatientPaymentDetails: {
    id: 'text-tooltip-patient-payment-details',
  },
  checkboxUniqueSuperBill: {
    id: 'checkbox-unique-superbill',
  },
  selectProviders: {
    id: 'select-provider',
  },
  billingAddressLocations: {
    id: 'billing-address-locations',
  },
  checkboxExcludeZeroDollarCharges: {
    id: 'checkbox-exclude-zero-dollar-charges',
  },
  checkboxExcludeAdjustmentsColumn: {
    id: 'checkbox-exclude-adjustments-column',
  },
  checkboxExcludeDiscountsColumn: {
    id: 'checkbox-exclude-discounts-column',
  },
  checkboxBillingLocation: {
    id: 'checkbox-billing-location',
  },
  checkboxExcludePackageCharges: {
    id: 'checkbox-exclude-package-charges',
  },
  checkboxExcludeChargesWithoutBalance: {
    id: 'checkbox-exclude-charges-without-balance',
  },
  checkboxExcludePurchaseCharges: {
    id: 'checkbox-exclude-purchase-charges',
  },
  checkboxExcludeProviderSignature: {
    id: 'checkbox-exclude-provider-signature',
  },
  checkboxExcludePatientSignature: {
    id: 'checkbox-exclude-patient-signature',
  },
  checkboxExcludePayerPaymentDetails: {
    id: 'checkbox-exclude-payer-payment-details',
  },
  checkboxExcludePatientPaymentDetails: {
    id: 'checkbox-exclude-patient-payment-details',
  },
  textAreaComment: {
    id: 'text-area-comment',
  },
  buttonGenerate: {
    id: 'button-generate',
  },
  buttonPreview: {
    id: 'button-preview',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
};

export const PAYER_ITEM_SELF = { label: 'Self', data: { id: PAYER_SELF } };
export const SUPERBILL_SETTINGS_KEY = 'superbillSettings';

const PAYMENT_DETAILS_TOOLTIP_TEXT = type =>
  `${type} payment details can only be excluded if charges associated to care packages are excluded.`;

const PAYER_PAYMENT_DETAILS_TOOLTIP_TEXT =
  PAYMENT_DETAILS_TOOLTIP_TEXT('Payer');

const PATIENT_PAYMENT_DETAILS_TOOLTIP_TEXT =
  PAYMENT_DETAILS_TOOLTIP_TEXT('Patient');

export class NebFormNewSuperbill extends Form {
  static get properties() {
    return {
      __patientItems: Array,
      __payerItems: Array,
      __providerItems: Array,
      __activeLocations: Array,
      __showPatient: Boolean,
      __showBillingDropdown: Boolean,
      __disablePackageCheckbox: Boolean,
      patient: Object,
      isCheckOut: Boolean,
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  static createModel() {
    return {
      patientId: '',
      dateOfServiceFrom: '',
      dateOfServiceTo: '',
      payerIds: [undefined],
      uniqueSuperbill: true,
      providerIds: [undefined],
      excludeZeroDollarCharges: false,
      excludeAdjustments: false,
      excludeDiscounts: false,
      billingAddressId: '',
      excludePackageCharges: false,
      excludeChargesWithoutBalance: false,
      excludePurchaseCharges: false,
      excludeProviderSignature: false,
      excludePatientSignature: false,
      excludePayerPaymentDetails: false,
      excludePatientPaymentDetails: false,
      comment: '',
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select(
          this.__patientItems.flat(),
          this.state.patientId,
          {
            validators: [isPropRequired('data.id')],
          },
        ),
        dateOfServiceFrom: {
          clipPristine: true,
          validators: [
            isRequiredIf('dateOfServiceTo', false),
            {
              error: 'Required',
              validate: (v, _, state) =>
                this.__showPatient && equal(state.patientId, ITEM_ALL_PATIENTS)
                  ? v
                  : true,
            },
          ],

          unformat: v => (v ? parseDate(v).startOf('day').toISOString() : null),
        },
        dateOfServiceTo: {
          clipPristine: true,
          validators: [
            isRequiredIf('dateOfServiceFrom', false),
            {
              error: 'Required',
              validate: (v, _, state) =>
                this.__showPatient && equal(state.patientId, ITEM_ALL_PATIENTS)
                  ? v
                  : true,
            },
          ],
          unformat: v => (v ? parseDate(v).endOf('day').toISOString() : null),
        },
        payerIds: selectors.multiSelect(this.__payerItems, this.__payerItems, {
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) =>
                this.__showPatient && state.patientId ? v.length : true,
            },
          ],
        }),
        providerIds: selectors.multiSelect(
          this.__providerItems,
          this.__providerItems,
          { validators: [isRequired()] },
        ),
        billingAddressId: selectors.select(
          this.locationItems,
          selectors.ITEM_EMPTY,
        ),
        excludeDiscounts: { clipPristine: false },
      },
    };
  }

  initState() {
    super.initState();

    this.__patientItems = [];
    this.__patientState = CollectionService.createModel();
    this.__payerItems = [];
    this.__providerItems = [];
    this.__activeLocations = [];
    this.patient = null;

    this.__showPatient = false;
    this.__showBillingDropdown = false;
    this.__disablePackageCheckbox = false;

    this.onPreview = () => {};
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__activeLocations = this.__formatLocations(userLocations).filter(
        location => location.data.active,
      );
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      preview: () => {
        if (this.formService.validate()) {
          const model = this.__buildModel();

          this.__saving = true;
          this.onPreview(model);
        }
      },
      selectPatient: e => {
        this.formService.apply('patientId', e.value);

        const value = e.value ? e.value.data.id : null;
        this.__updatePayers(value);
      },
      searchPatients: e => this.__patientsService.search(e.value),
      requestPatients: () => this.__patientsService.fetchMore(),
      changePatients: state => {
        this.__patientState = state;

        const patientItems = formatToItems(state.pageItems);

        this.__patientItems = [ITEM_ALL_PATIENTS, ...patientItems];
      },
      selectDateOfServiceFrom: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : '');
        this.__updateDateOfServiceToSelectable();
      },
      selectDateOfServiceTo: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : '');
        this.__updateDateOfServiceFromSelectable();
      },
      selectPayer: ({ name, value }) => {
        this.__updateCheckboxOptions(value);
        this.formService.apply(name, value);
      },
      toggleBillingDropdown: () => {
        if (this.__showBillingDropdown) {
          this.formService.apply('billingAddressId', selectors.ITEM_EMPTY);
        }
        this.__showBillingDropdown = !this.__showBillingDropdown;
      },
      renderPatientItem: (model, index) =>
        model.label === ITEM_ALL_PATIENTS.label
          ? html` <neb-all-patients-card></neb-all-patients-card> `
          : html`
              <neb-patient-card
                id="item-${index}"
                tabindex="0"
                .model="${model.data}"
              ></neb-patient-card>
            `,
      save: (...args) => {
        if (!this.formService.validate()) return null;
        const model = this.__buildModel();
        this.__saving = true;
        return this.onSave(model, ...args);
      },
      changeExcludePackageCharges: e => {
        this.formService.apply(e.name, e.value);

        if (!e.value) {
          this.formService.apply('excludePatientPaymentDetails', false);
          this.formService.apply('excludePayerPaymentDetails', false);
        }
      },
    };

    this.__updateDateOfServiceFromSelectable();
    this.__updateDateOfServiceToSelectable();
  }

  __storeSuperbillSettings(rawModel) {
    const keysToRemove = [
      'patientId',
      'dateOfServiceFrom',
      'dateOfServiceTo',
      'payerIds',
      'providerIds',
      'comment',
      'billingAddressId',
    ];

    const settings = Object.fromEntries(
      Object.entries(rawModel).filter(([key]) => !keysToRemove.includes(key)),
    );

    localStorage.setItem(SUPERBILL_SETTINGS_KEY, JSON.stringify(settings));
  }

  __buildModel() {
    const rawModel = this.formService.build();
    this.__storeSuperbillSettings(rawModel);
    const model = map(rawModel, (keyPath, value) =>
      typeof value === 'string' ? value.trim() : value,
    );

    return model;
  }

  __updateDateOfServiceFromSelectable() {
    this.handlers.dateOfServiceFromSelectable = date =>
      (!this.state.dateOfServiceTo ||
        date.isSameOrBefore(parseDate(this.state.dateOfServiceTo))) &&
      date.isSameOrBefore(parseDate().startOf('day'));
  }

  __updateDateOfServiceToSelectable() {
    this.handlers.dateOfServiceToSelectable = date =>
      (!this.state.dateOfServiceFrom ||
        date.isSameOrAfter(parseDate(this.state.dateOfServiceFrom))) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __updateCheckboxOptions(payerIds) {
    if (payerIds.length !== this.state.payerIds.length) {
      this.formService.apply('uniqueSuperbill', true);
    }

    const selfPaySelected = payerIds.some(
      payer => payer.data.id === PAYER_SELF,
    );

    if (!selfPaySelected) {
      this.formService.apply('excludePackageCharges', true);
    }
    this.__disablePackageCheckbox = !selfPaySelected;
  }

  __getDescription() {
    const patientName = this.patient ? this.patient.name : '';

    return this.__showPatient
      ? 'Generate a new superbill using the parameters below.'
      : `Generate a new superbill for ${patientName} using the parameters below.`;
  }

  __getSelectPayerHelperText() {
    return this.__showPatient ? 'Required' : ' ';
  }

  __getDateOfServiceHelperText() {
    return this.__showPatient && equal(this.state.patientId, ITEM_ALL_PATIENTS)
      ? 'Required'
      : ' ';
  }

  __shouldDisableField() {
    return this.__showPatient && !this.state.patientId;
  }

  __shouldDisablePreview() {
    return (
      !this.state.patientId || equal(this.state.patientId, ITEM_ALL_PATIENTS)
    );
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  async __updatePayers(patientId) {
    if (patientId) {
      let version;

      const optOutLoadingIndicator = true;
      const allPatientsSelected = patientId === ITEM_ALL_PATIENTS.data.id;
      const payers = allPatientsSelected
        ? (await getPayerPlans({}, version, optOutLoadingIndicator)).payerPlan
        : await getPatientPayers(patientId, optOutLoadingIndicator);

      this.__payerItems = [
        PAYER_ITEM_SELF,
        ...payers.map(p => ({
          label: `(${p.alias}) ${p.payerName}`,
          data: p,
        })),
      ];
    } else {
      this.__payerItems = [];
    }

    this.formService.apply('payerIds', this.__payerItems);
  }

  __loadDefaultSettings() {
    if (
      this.__settings[PRACTICE_SETTINGS.ENABLE_DEFAULT_SUPERBILL_COMMENT] &&
      this.__settings[PRACTICE_SETTINGS.DEFAULT_SUPERBILL_COMMENT]
    ) {
      this.formService.apply(
        'comment',
        this.__settings[PRACTICE_SETTINGS.DEFAULT_SUPERBILL_COMMENT],
      );
    }
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.__loadDefaultSettings();
    this.__fillSuperbillFormFromLocalStorage();

    if (this.isCheckOut) {
      this.__handleDefaultDOS();
    }
  }

  __fillSuperbillFormFromLocalStorage() {
    const localStorageData = localStorage.getItem(SUPERBILL_SETTINGS_KEY);

    if (localStorageData) {
      const storedInfo = JSON.parse(localStorageData);

      Object.keys(storedInfo).forEach(paramName =>
        this.formService.apply(paramName, storedInfo[paramName]),
      );
    }
  }

  __handleDefaultDOS() {
    const dateOfServiceFrom = parseDate(this.dateOfServiceFrom);
    const dateOfServiceTo = parseDate(this.dateOfServiceTo);

    this.formService.apply(
      'dateOfServiceFrom',
      dateOfServiceFrom.format('YYYY-MM-DD'),
    );

    this.formService.apply(
      'dateOfServiceTo',
      dateOfServiceTo.format('YYYY-MM-DD'),
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  async load() {
    this.__patientsService = createServerPatientsCollection({
      onChange: this.handlers.changePatients,
      initialFetch: true,
    });

    if (this.model.patientId) {
      await this.__updatePayers(this.model.patientId);
    }

    this.__providerItems = formatToItems(await getProviderUsers(true));

    this.__settings = await getPracticeSettings();
  }

  update(changedProps) {
    if (changedProps.has('patient')) {
      this.__showPatient = !this.patient;
      this.build();
      this.state.patientId =
        this.patient && this.patient.id
          ? { data: { label: '', id: this.patient.id } }
          : '';
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex: 1 0 auto;
          overflow: visible;
        }

        .layout {
          padding-bottom: 0;
        }

        .grid-buttons {
          grid-template-columns: auto auto auto 1fr;
          padding-top: ${CSS_SPACING};
        }

        .date-picker {
          width: 100%;
        }

        .field-height {
          display: flex;
          align-items: center;
          height: 40px;
        }

        .item-empty {
          height: 40px;
        }

        .column-field {
          width: 100%;
        }
      `,
    ];
  }

  get locationItems() {
    return [selectors.ITEM_EMPTY, ...this.__activeLocations];
  }

  __renderPatient() {
    return this.__showPatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            class="span"
            name="patientId"
            label="Search for a Patient..."
            helper="Required"
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .error="${this.errors.patientId}"
            .onChange="${this.handlers.selectPatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestPatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
          >
          </neb-select-search>
        `
      : '';
  }

  __renderPayers() {
    return html`
      <div class="grid grid-auto-right container-payers">
        <neb-select
          id="${ELEMENTS.selectPayer.id}"
          name="payerIds"
          label="Payers"
          allLabel="Payers"
          helper="${this.__getSelectPayerHelperText()}"
          .items="${this.__payerItems}"
          .value="${this.state.payerIds}"
          .onChange="${this.handlers.selectPayer}"
          .error="${this.errors.payerIds}"
          ?disabled="${this.__shouldDisableField()}"
          multiSelect
        ></neb-select>

        <neb-tooltip
          id="${ELEMENTS.toolTipPayers.id}"
          ?disabled="${this.__shouldDisableField()}"
          defaultAnchor="right"
        >
          <div slot="tooltip">
            Select individual payers to produce Superbills listing only
            payer-specific charges, for each selection. A unique Superbill per
            payer will be created. Choose 'All Payers' and deselect 'Unique
            Superbill Per Payer' to produce a Superbill listing any matching
            charges.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderUniqueSuperbill() {
    return this.state.payerIds.length === this.__payerItems.length
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxUniqueSuperBill.id}"
            name="uniqueSuperbill"
            label="Unique Superbill Per Payer"
            .checked="${this.state.uniqueSuperbill}"
            ?disabled="${this.__shouldDisableField()}"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>
        `
      : html` <div></div> `;
  }

  __renderCheckboxBillingLocation() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxBillingLocation.id}"
        name="locations"
        class="field-height"
        label="Select billing location"
        ?disabled="${this.__shouldDisableField()}"
        .checked="${this.__showBillingDropdown}"
        .onChange="${this.handlers.toggleBillingDropdown}"
      ></neb-checkbox>
    `;
  }

  __renderCheckboxDiscountColumn() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxExcludeDiscountsColumn.id}"
        class="field-height"
        name="excludeDiscounts"
        label="Exclude discounts column"
        .checked="${this.state.excludeDiscounts}"
        ?disabled="${this.__shouldDisableField()}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderBillingDropdown() {
    return this.__showBillingDropdown
      ? html`
          <neb-select
            id="${ELEMENTS.billingAddressLocations.id}"
            name="billingAddressId"
            placeholder="Billing Address"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.billingAddressId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : html` <div class="item-empty"></div> `;
  }

  __renderExcludePackageCharges() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxExcludePackageCharges.id}"
        class="field-height"
        name="excludePackageCharges"
        label="Exclude charges associated to care packages"
        .checked="${this.state.excludePackageCharges}"
        ?disabled="${this.__shouldDisableField() ||
        this.__disablePackageCheckbox}"
        .onChange="${this.handlers.changeExcludePackageCharges}"
      ></neb-checkbox>
    `;
  }

  __renderExcludePatientSignatureCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxExcludePatientSignature.id}"
        class="field-height"
        name="excludePatientSignature"
        label="Exclude patient signature"
        .checked="${this.state.excludePatientSignature}"
        ?disabled="${this.__shouldDisableField()}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderExcludeProviderSignatureCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxExcludeProviderSignature.id}"
        class="field-height"
        name="excludeProviderSignature"
        label="Exclude provider signature"
        .checked="${this.state.excludeProviderSignature}"
        ?disabled="${this.__shouldDisableField()}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderExcludePurchaseCharges() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxExcludePurchaseCharges.id}"
        class="field-height"
        name="excludePurchaseCharges"
        label="Exclude charges associated to purchases"
        .checked="${this.state.excludePurchaseCharges}"
        ?disabled="${this.__shouldDisableField()}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __shouldDisablePaymentDetailsCheckboxes() {
    return this.__shouldDisableField() || !this.state.excludePackageCharges;
  }

  __renderExcludePayerPaymentDetails() {
    return html`
      <div class="grid grid-auto-right container-payments-details">
        <neb-checkbox
          id="${ELEMENTS.checkboxExcludePayerPaymentDetails.id}"
          class="field-height"
          name="excludePayerPaymentDetails"
          label="Exclude payer payment details"
          .checked="${this.state.excludePayerPaymentDetails}"
          ?disabled="${this.__shouldDisablePaymentDetailsCheckboxes()}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>
        <neb-tooltip
          id="${ELEMENTS.toolTipPayerPaymentDetails.id}"
          ?disabled="${this.__shouldDisableField()}"
          defaultAnchor="right"
          class="tooltip-payment-details"
        >
          <div
            id="${ELEMENTS.textToolTipPayerPaymentDetails.id}"
            slot="tooltip"
          >
            ${PAYER_PAYMENT_DETAILS_TOOLTIP_TEXT}
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderExcludePatientPaymentDetails() {
    return html`
      <div class="grid grid-auto-right container-payments-details">
        <neb-checkbox
          id="${ELEMENTS.checkboxExcludePatientPaymentDetails.id}"
          class="field-height"
          name="excludePatientPaymentDetails"
          label="Exclude patient payment details"
          .checked="${this.state.excludePatientPaymentDetails}"
          ?disabled="${this.__shouldDisablePaymentDetailsCheckboxes()}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>
        <neb-tooltip
          id="${ELEMENTS.toolTipPatientPaymentDetails.id}"
          ?disabled="${this.__shouldDisableField()}"
          defaultAnchor="right"
          class="tooltip-payment-details"
        >
          <div
            id="${ELEMENTS.textToolTipPatientPaymentDetails.id}"
            slot="tooltip"
          >
            ${PATIENT_PAYMENT_DETAILS_TOOLTIP_TEXT}
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderAddComment() {
    return html`
      <neb-textarea
        id="${ELEMENTS.textAreaComment.id}"
        class="span"
        label="Superbill Comment"
        name="comment"
        maxlength="500"
        showCount
        .disabled="${this.__shouldDisableField()}"
        .value="${this.state.comment}"
        .onChange="${this.handlers.change}"
      ></neb-textarea>
    `;
  }

  renderContent() {
    const dos = {
      from: parseDate(this.state.dateOfServiceFrom),
      to: parseDate(this.state.dateOfServiceTo),
    };

    return html`
      <div class="grid grid-2 grid-lean">
        <div id="${ELEMENTS.description.id}" class="span">
          ${this.__getDescription()}
        </div>

        ${this.__renderPatient()}

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceFrom.id}"
          class="column-field"
          name="dateOfServiceFrom"
          label="Date of Service From"
          placeholder="Select Date"
          helperText="${this.__getDateOfServiceHelperText()}"
          manualPopoverPosition="center"
          momentFlag
          .invalidText="${this.errors.dateOfServiceFrom}"
          .selectedDate="${dos.from !== null
            ? dos.from.startOf('day')
            : dos.from}"
          .isDateSelectable="${this.handlers.dateOfServiceFromSelectable}"
          .onChange="${this.handlers.selectDateOfServiceFrom}"
          ?invalid="${this.errors.dateOfServiceFrom}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceTo.id}"
          class="column-field "
          name="dateOfServiceTo"
          label="Date of Service To"
          placeholder="Select Date"
          helperText="${this.__getDateOfServiceHelperText()}"
          manualPopoverPosition="center"
          momentFlag
          .invalidText="${this.errors.dateOfServiceTo}"
          .selectedDate="${dos.to !== null ? dos.to.endOf('day') : dos.to}"
          .isDateSelectable="${this.handlers.dateOfServiceToSelectable}"
          .onChange="${this.handlers.selectDateOfServiceTo}"
          ?invalid="${this.errors.dateOfServiceTo}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-date-picker>

        ${this.__renderPayers()} ${this.__renderUniqueSuperbill()}

        <neb-select
          id="${ELEMENTS.selectProviders.id}"
          name="providerIds"
          label="Providers"
          allLabel="Providers"
          helper="Required"
          class="span"
          .items="${this.__providerItems}"
          .value="${this.state.providerIds}"
          .onChange="${this.handlers.change}"
          .error="${this.errors.providerIds}"
          ?disabled="${this.__shouldDisableField()}"
          multiSelect
        ></neb-select>

        <neb-checkbox
          id="${ELEMENTS.checkboxExcludeChargesWithoutBalance.id}"
          class="field-height"
          name="excludeChargesWithoutBalance"
          label="Exclude charges without a patient balance"
          .checked="${this.state.excludeChargesWithoutBalance}"
          ?disabled="${this.__shouldDisableField()}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>

        ${this.__renderExcludeProviderSignatureCheckbox()}

        <neb-checkbox
          id="${ELEMENTS.checkboxExcludeZeroDollarCharges.id}"
          class="field-height"
          name="excludeZeroDollarCharges"
          label="Exclude zero dollar charges"
          .checked="${this.state.excludeZeroDollarCharges}"
          ?disabled="${this.__shouldDisableField()}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>

        ${this.__renderExcludePatientSignatureCheckbox()}
        ${this.__renderExcludePurchaseCharges()}

        <neb-checkbox
          id="${ELEMENTS.checkboxExcludeAdjustmentsColumn.id}"
          class="field-height"
          name="excludeAdjustments"
          label="Exclude adjustments column"
          .checked="${this.state.excludeAdjustments}"
          ?disabled="${this.__shouldDisableField()}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>

        ${this.__renderExcludePackageCharges()}
        ${this.__renderCheckboxDiscountColumn()}
        ${this.__renderExcludePatientPaymentDetails()}
        ${this.__renderCheckboxBillingLocation()}
        ${this.__renderExcludePayerPaymentDetails()}
        ${this.__renderBillingDropdown()} ${this.__renderAddComment()}
      </div>
    `;
  }

  renderActionBar() {
    return html`
      <div class="grid grid-lean grid-buttons">
        <neb-button
          id="${ELEMENTS.buttonGenerate.id}"
          label="Generate"
          .onClick="${this.handlers.save}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonPreview.id}"
          label="Preview"
          .onClick="${this.handlers.preview}"
          ?disabled="${this.__shouldDisablePreview()}"
          .role="${BUTTON_ROLE.OUTLINE}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
          .role="${BUTTON_ROLE.OUTLINE}"
        ></neb-button>
      </div>
    `;
  }
}
customElements.define('neb-form-new-superbill', NebFormNewSuperbill);
