import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';
import pkg from 'validator';

import Address from '../../neb-lit-components/src/components/field-groups/neb-address';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { SERVICE_FACILITY_ADDRESS_TYPE } from '../../neb-utils/claims';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  buttonLocation: { id: 'button-location' },
  buttonOptions: { selector: '[id^=option-]' },
  buttonOther: { id: 'button-other' },
  addressOther: { id: 'address-other' },
  locationsSelect: { id: 'locations-select' },
  inputOtherFacilityName: { id: 'input-other-facility-name' },
  serviceFacilityInfo: { selector: '.service-facility-info' },
};

class NebPopupClaimServiceFacility extends NebPopupClaim {
  static get properties() {
    return {
      __locationIds: Array,
      __otherFacilityName: String,
      __otherFacilityAddress: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow-y: auto;
        }

        .content {
          flex: unset;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
        }

        .container-buttons {
          display: grid;
          grid-template-columns: 160px auto;
          row-gap: ${CSS_SPACING};
          align-items: flex-start;
          padding-bottom: 10px;
          grid-column-gap: 10px;
          overflow-y: auto;
          overflow-x: hidden;
          padding-right: 5px;
        }

        .service-facility-info {
          margin-top: 10px;
        }

        p {
          margin: 0;
        }

        .text-input,
        .select-input {
          width: 100%;
          padding-bottom: 10px;
          margin-top: 10px;
        }

        .container-other {
          display: grid;
          align-items: center;
          grid-column: 1 / -1;
          grid-template-columns: 160px auto;
          grid-column-gap: 10px;
        }

        .address {
          grid-column-start: 2;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__locationIds = [];
    this.__otherFacilityName = '';
    this.__otherFacilityAddress = Address.createModel();

    this.__errors = {
      facilityName: false,
      address1: false,
      city: false,
      state: false,
      zipcode: false,
    };

    this.model = {
      title: '',
      tooltipText: '',
      serviceFacilityAddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      serviceFacilityAddressType: '',
      serviceFacilityName: '',
      options: [],
      locationOptions: [],
      locations: [],
    };
  }

  __hasUnmatchedServiceFacilityValue() {
    return (
      this.model &&
      (this.model.serviceFacilityAddressType ===
        SERVICE_FACILITY_ADDRESS_TYPE.PRACTICE_ADDRESS ||
        (this.model.options.every(option => !equal(this.__state, option)) &&
          this.model.locationOptions.every(
            ({ data }) =>
              !(
                equal(
                  data.serviceFacilityAddress,
                  this.model.serviceFacilityAddress,
                ) &&
                data.serviceFacilityAddressType ===
                  this.model.serviceFacilityAddressType &&
                data.serviceFacilityName === this.model.serviceFacilityName
              ),
          )))
    );
  }

  isSelectValue(value) {
    return value && value.data;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        const [key, addressKey] = name.split('.');

        switch (key) {
          case 'address':
            this.__otherFacilityAddress = {
              ...this.__otherFacilityAddress,
              [addressKey]: value,
            };

            this.__state.serviceFacilityAddress = {
              address1: this.__otherFacilityAddress.address1,
              address2: this.__otherFacilityAddress.address2,
              city: this.__otherFacilityAddress.city,
              state: this.__otherFacilityAddress.state,
              zipCode: this.__otherFacilityAddress.zipcode,
            };

            break;

          case 'facilityName':
            this.__otherFacilityName = value;
            this.__state.serviceFacilityName = this.__otherFacilityName;

            break;

          case 'serviceFacility':
            if (
              this.__state.serviceFacilityAddressType ===
                SERVICE_FACILITY_ADDRESS_TYPE.OTHER &&
              value.serviceFacilityAddressType !==
                SERVICE_FACILITY_ADDRESS_TYPE.OTHER
            ) {
              this.__otherFacilityName = '';
              this.__otherFacilityAddress = Address.createModel();
            }

            if (this.isSelectValue(value)) {
              this.__state = {
                ...value.data,
              };
            } else {
              this.__state = {
                ...value,
              };
            }

            break;

          default:
            break;
        }

        this.__validate();
      },
      confirm: () => {
        const {
          serviceFacilityAddress,
          serviceFacilityAddressType,
          serviceFacilityName,
        } = this.__state;

        if (!this.__hasErrors()) {
          this.onClose({
            serviceFacilityAddress,
            serviceFacilityAddressType,
            serviceFacilityName,
          });
        }
      },
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      const {
        serviceFacilityName,
        serviceFacilityAddress,
        serviceFacilityAddressType,
      } = this.model;

      this.__state = {
        serviceFacilityName,
        serviceFacilityAddress,
        serviceFacilityAddressType,
      };

      if (serviceFacilityAddressType === SERVICE_FACILITY_ADDRESS_TYPE.OTHER) {
        const { address1, address2, city, state, zipCode } =
          serviceFacilityAddress;

        this.__otherFacilityName = serviceFacilityName;
        this.__otherFacilityAddress = {
          address1,
          address2,
          city,
          state,
          zipcode: zipCode,
        };

        this.__validate();
      }
    }
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model.locationOptions.length) {
      this.__locationIds = this.model.locationOptions.map(
        ({ data: { id } }) => id,
      );

      if (this.__hasUnmatchedServiceFacilityValue()) {
        const { address1, address2, city, state, zipCode } =
          this.model.serviceFacilityAddress;

        this.__otherFacilityAddress = {
          address1,
          address2,
          city,
          state,
          zipcode: zipCode,
        };

        this.__otherFacilityName = this.model.serviceFacilityName;

        this.__state = {
          ...this.__state,
          serviceFacilityAddressType: SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
        };

        this.__validate();
      }
    }
  }

  __validate() {
    const { address1, city, state, zipcode } = this.__otherFacilityAddress;

    this.__errors =
      this.__state.serviceFacilityAddressType ===
      SERVICE_FACILITY_ADDRESS_TYPE.OTHER
        ? {
            facilityName: !this.__otherFacilityName,
            address1: !address1,
            city: !city,
            state: !state,
            zipcode: !zipcode || !pkg.isPostalCode(zipcode, 'US'),
          }
        : {
            facilityName: false,
            address1: false,
            city: false,
            state: false,
            zipcode: false,
          };
  }

  __renderServiceFacilityInfo({ address, name }) {
    return html`
      <div class="service-facility-info">
        <p>${name}</p>
        <p>${address.address1} ${address.address2}</p>
        <p>${address.city} ${address.state} ${address.zipCode}</p>
      </div>
    `;
  }

  get __isOtherType() {
    return (
      this.__state.serviceFacilityAddressType ===
      SERVICE_FACILITY_ADDRESS_TYPE.OTHER
    );
  }

  get __otherValue() {
    return {
      serviceFacilityName: this.__otherFacilityName,
      serviceFacilityAddress: this.__otherFacilityAddress,
      serviceFacilityAddressType: SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
    };
  }

  get __isLocationSelected() {
    return (
      this.__state.serviceFacilityAddressType &&
      this.__locationIds.includes(this.__state.serviceFacilityAddressType)
    );
  }

  get __locationValue() {
    return (
      this.model.locationOptions.find(
        option => option.data.id === this.__state.serviceFacilityAddressType,
      ) || this.model.locationOptions[0]
    );
  }

  get __locationsOption() {
    return html`
      <neb-radio-button
        id="${ELEMENTS.buttonLocation.id}"
        label="Location"
        name="serviceFacility"
        .onChange="${this.handlers.change}"
        .value="${this.__locationValue}"
        .checked="${this.__isLocationSelected}"
      >
      </neb-radio-button>

      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="select-input"
        name="serviceFacility"
        .value="${this.__isLocationSelected ? this.__locationValue : ''}"
        .items="${this.model.locationOptions}"
        ?disabled="${!this.__isLocationSelected}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  get __otherOption() {
    return html`
      <div class="container-other">
        <neb-radio-button
          id="${ELEMENTS.buttonOther.id}"
          class="other-option"
          label="Other"
          name="serviceFacility"
          .value="${this.__otherValue}"
          .checked="${this.__isOtherType}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>

        <neb-textfield
          id="${ELEMENTS.inputOtherFacilityName.id}"
          class="text-input"
          label="Facility Name"
          name="facilityName"
          helper="Required"
          .error="${this.__errors.facilityName}"
          .value="${this.__otherFacilityName}"
          ?disabled="${!this.__isOtherType}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-address
          id="${ELEMENTS.addressOther.id}"
          class="address"
          name="address"
          .errors="${this.__errors}"
          .helpers="${{
            address1: 'Required',
            city: 'Required',
            state: 'Required',
            zipcode:
              !this.__otherFacilityAddress.zipcode || !this.__errors.zipcode
                ? 'Required'
                : '#####(-####)',
          }}"
          .model="${this.__otherFacilityAddress}"
          ?disabled="${!this.__isOtherType}"
          .onChange="${this.handlers.change}"
        ></neb-address>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        ${this.__locationsOption}
        ${this.model.options.map(
          (value, index) => html`
            <neb-radio-button
              id="option-${index}"
              label="${value.serviceFacilityAddressType}"
              name="serviceFacility"
              .value="${value}"
              .checked="${equal(this.__state, value)}"
              .onChange="${this.handlers.change}"
            ></neb-radio-button>
            ${this.__renderServiceFacilityInfo({
              address: value.serviceFacilityAddress,
              name: value.serviceFacilityName,
            })}
          `,
        )}
        ${this.__otherOption}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-service-facility',
  NebPopupClaimServiceFacility,
);
