import './neb-banner';

import { LitElement, html } from 'lit';

import {
  OVERLAY_KEYS,
  openOverlay,
} from '../neb-lit-components/src/utils/overlay-constants';
import { store, connect } from '../neb-redux/neb-redux-store';

import { closeBanner, MESSAGES_TYPE } from './neb-banner-state';

export const ELEMENTS = {
  banners: {
    id: 'banners',
  },
};
class NebBannerController extends connect(store)(LitElement) {
  static get properties() {
    return {
      __layout: {
        type: String,
      },
      __messages: {
        type: Array,
      },
    };
  }

  constructor() {
    super();
    this.__messages = [];

    this.__initHandlers();
  }

  __initHandlers() {
    this.__handlers = {
      closeMessage: messageId => this.__closeMessage(messageId),
      onClick: bannerMessageEl => this.__onClickMessage(bannerMessageEl),
    };
  }

  _stateChanged({ layoutMedia: { layout } = {}, banners: { items } = {} }) {
    this.__layout = layout;
    this.__messages = items;
  }

  __closeMessage(messageId) {
    store.dispatch(closeBanner(messageId));
  }

  async __onClickMessage(bannerMessageEl) {
    if (
      bannerMessageEl.messageType === MESSAGES_TYPE.WARNING &&
      bannerMessageEl.onBannerClick
    ) {
      bannerMessageEl.onBannerClick();
    } else if (
      bannerMessageEl.messageType === MESSAGES_TYPE.ALERT &&
      bannerMessageEl.patientId
    ) {
      await openOverlay(OVERLAY_KEYS.PATIENT_ALERT, {
        patientId: bannerMessageEl.patientId,
      });
    }
  }

  render() {
    return html`
      <neb-banner
        id="${ELEMENTS.banners.id}"
        .layout="${this.__layout}"
        .messages="${this.__messages}"
        .onCloseMessage="${this.__handlers.closeMessage}"
        .onClick="${this.__handlers.onClick}"
      ></neb-banner>
    `;
  }
}
customElements.define('neb-banner-controller', NebBannerController);
