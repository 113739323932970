import { openPopup } from '@neb/popup';
import { html, LitElement, css } from 'lit';

import {
  createBannerController,
  DISPLAY_ON,
} from '../../../packages/neb-alert/components/neb-alert-banner-controller';
import {
  postPayment,
  voidPayment,
  refundPayment,
  getPaymentDetail,
} from '../../../packages/neb-api-client/src/payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { TABS } from '../../../packages/neb-lit-components/src/components/forms/neb-form-allocation-charges';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { PAYMENT_ACTION_KEYS } from '../../../packages/neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
} from '../../../packages/neb-styles/neb-variables';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../forms/neb-form-payment';
import '../../../packages/neb-lit-components/src/components/patients/payment/neb-payer-payment-post';
import { centsToCurrency } from '../../../packages/neb-utils/formatters';
import {
  POST_PAYMENT_SUCCESS,
  VOID_PAYMENT_SUCCESS,
  REFUND_PAYMENT_SUCCESS,
  POST_PAYMENT_ERROR,
  VOID_PAYMENT_ERROR,
  REFUND_PAYMENT_ERROR,
} from '../../utils/user-message';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
  post: {
    id: 'post',
  },
  description: {
    id: 'description',
  },
};

export const PAGE_TITLE = {
  form: () => 'Add Payer Payment',
  post: ({ payerPlan: { alias } }) => `Payer Payment - ${alias}`,
};

const DESCRIPTION = patientId =>
  `Select and enter payment details to post against the ${
    patientId ? "patient's " : ''
  }ledger.`;

class NebPayerPaymentController extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      patientId: String,
      doNotDismissAll: Boolean,
      __postPayment: Object,
      __payers: Array,
      __voidPayment: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__alertBanner = createBannerController(DISPLAY_ON.payment);
    this.layout = 'large';
    this.postedPayment = null;
    this.patientId = '';
    this.doNotDismissAll = false;
    this.__postPayment = null;
    this.__payers = [];
    this.__query = {
      hideInactive: true,
      search: '',
    };

    this.__voidPayment = {};

    this.onDirtyChange = () => {};

    this.onDismiss = () => {};

    this.onDismissAll = () => {};

    this.onPostPaymentAction = () => {};

    this.onVoidPaymentAction = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      dirty: dirty => this.onDirtyChange(dirty),
      dismiss: () => this.onDismiss(),
      dismissAll: () =>
        !this.doNotDismissAll ? this.onDismissAll() : this.onDismiss(),
      save: payment => this.__savePayerPayment(payment),
      allocatePayment: async () => {
        await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
          patientId: this.patientId,
          payments: [this.__postPayment],
          selectedTab: TABS.OUTSTANDING,
        });

        try {
          this.__postPayment = await getPaymentDetail(this.__postPayment.id);
        } catch (e) {
          store.dispatch(
            openError('An error occurred when retrieving payment detail.'),
          );
        }
      },
      voidPayment: () => this.__voidPayerPayment(),
    };
  }

  firstUpdated() {
    if (this.patientId) {
      this.__alertBanner.connect();

      this.__alertBanner.update(this.patientId);
    } else this.__alertBanner.disconnect();
  }

  update() {
    if (this.postedPayment) this.__postPayment = this.postedPayment;
    super.update();
  }

  async __savePayerPayment(payment) {
    const formattedPayment = {
      ...payment,
      patientId: this.patientId,
    };

    try {
      this.__postPayment = await postPayment(formattedPayment);

      this.__handlers.dirty(false);

      this.scrollTop = 0;

      this.onPostPaymentAction(this.__postPayment);

      store.dispatch(openSuccess(POST_PAYMENT_SUCCESS));
    } catch (e) {
      store.dispatch(openError(POST_PAYMENT_ERROR));
    }
  }

  async __voidPayerPayment() {
    if (this.__postPayment.electronicPaymentId) {
      const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentMethod: `${this.__postPayment.paymentMethod} - ${
          this.__postPayment.maskedCardDescription
        }`,
        paymentId: this.__postPayment.paymentNumber,
        payer: this.__postPayment.payerPlan.payerName,
        amount: centsToCurrency(this.__postPayment.amount),
      });

      if (paymentAction && paymentAction.selectedReason) {
        const originalPaymentAmount = this.__postPayment.amount;

        const refundPaymentBody = {
          paymentId: this.__postPayment.id,
          codeRefundId: paymentAction.selectedReason.id,
          refundMethod: paymentAction.refundMethod,
          amount: paymentAction.amount,
        };

        try {
          const result = await refundPayment(refundPaymentBody);
          this.__voidPayment = result.refundPayment;
          this.onVoidPaymentAction(this.__voidPayment);
          store.dispatch(openSuccess(REFUND_PAYMENT_SUCCESS));

          this.__closeIfPartialRefund(
            originalPaymentAmount,
            refundPaymentBody.amount,
          );
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFUND_PAYMENT_ERROR));
        }
      }
    } else {
      const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.VOID,
        paymentId: this.__postPayment.paymentNumber,
        payer: this.__postPayment.payerPlan.payerName,
        amount: centsToCurrency(this.__postPayment.amount),
      });

      if (paymentAction.selectedReason) {
        const voidPaymentBody = {
          paymentId: this.__postPayment.id,
          codeRefundId: paymentAction.selectedReason.id,
        };

        try {
          const result = await voidPayment(voidPaymentBody);
          this.__voidPayment = result.voidPayment;
          this.onVoidPaymentAction(this.__voidPayment);
          store.dispatch(openSuccess(VOID_PAYMENT_SUCCESS));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(VOID_PAYMENT_ERROR));
        }
      }
    }
  }

  __closeIfPartialRefund(originalAmount, refundAmount) {
    if (originalAmount !== refundAmount) {
      this.__handlers.dismissAll();
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        :host([layout='small']) .header {
          padding: 10px ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .description {
          padding: 8px ${CSS_SPACING} ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  __renderHeader() {
    const title = PAGE_TITLE[this.__postPayment ? 'post' : 'form'];
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title(this.__postPayment)}"
        .onCancel="${this.__handlers.dismissAll}"
        .onBack="${this.__handlers.dismiss}"
        .showBackButton="${!this.__postPayment}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderForm() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${DESCRIPTION(this.patientId)}
      </div>
      <neb-form-payment
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .forPayer="${true}"
        .onChangeDirty="${this.__handlers.dirty}"
        .onCancel="${this.__handlers.dismiss}"
        .onSave="${this.__handlers.save}"
      ></neb-form-payment>
    `;
  }

  __renderPaymentPost() {
    const { firstName, lastName } = store.getState().session.item;

    return html`
      <neb-payer-payment-post
        id="${ELEMENTS.post.id}"
        .model="${{
          ...this.__postPayment,
          postedBy: `${lastName}, ${firstName}`,
        }}"
        .layout="${this.layout}"
        .onAllocatePayment="${this.__handlers.allocatePayment}"
        .onClose="${this.__handlers.dismissAll}"
        .onVoidPayment="${this.__handlers.voidPayment}"
        .voidPayment="${this.__voidPayment}"
      ></neb-payer-payment-post>
    `;
  }

  render() {
    return html`
      ${this.__renderHeader()}
      ${this.__postPayment ? this.__renderPaymentPost() : this.__renderForm()}
    `;
  }
}

customElements.define(
  'neb-payer-payment-controller',
  NebPayerPaymentController,
);
