import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonSuperUser: {
    id: 'button-super-user',
  },
  textClientId: {
    id: 'text-client-id',
  },
  textPracticeName: {
    id: 'text-practice-name',
  },
};

export class NebPopupClientId extends NebPopup {
  initState() {
    super.initState();
    this.title = 'New Practice - Client ID';
    this.model = {
      salesforceId: '',
      practiceName: '',
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () => this.onClose(true),
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 600px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <p id="${ELEMENTS.textPracticeName.id}">
        ${this.model.practiceName} has been created successfully. A Client ID
        has been generated for the practice. Please add this to the practice's
        Salesforce account:
      </p>

      <p id="${ELEMENTS.textClientId.id}"><b>${this.model.salesforceId}</b></p>

      <p>
        After adding the Client ID to the practice's Salesforce account, please
        continue by creating the practice's Super User.
      </p>

      <div>
        <neb-button
          id="${ELEMENTS.buttonSuperUser.id}"
          class="button-super-user"
          label="Create Super User"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.__handlers.confirm}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-client-id', NebPopupClientId);
