import { RESPONSE_TYPE, Method } from '../../utils/api-client-utils';
import ApiClientV2 from '../../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });
export const BASE_PATH = '/api/tenants/:tenantId/statements/batches/:batchId';

export const generateStatementBatch = batchId =>
  apiClient.makeRequest({
    path: BASE_PATH,
    method: Method.POST,
    replacements: { batchId },
    cacheKey: `statements/batches/${batchId}`,
    responseType: RESPONSE_TYPE.RAW,
  });
