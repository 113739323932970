import '../../../../packages/neb-lit-components/src/components/neb-text';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { formatDollarAmount } from '../../../../packages/neb-utils/formatters';
import { unlinkPayment as unlinkEobPayment } from '../../../api-clients/eob';
import { unlinkPayment as unlinkEraPayment } from '../../../api-clients/era';
import { CSS_SPACING } from '../../../styles';
import {
  TEXT_UNLINK_PAYMENT_SUCCESS,
  TEXT_UNLINK_PAYMENT_ERROR,
} from '../../../utils/user-message';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  rowDate: { selector: '[id^=cell-date-]' },
  rowPayer: { selector: '[id^=cell-payer-]' },
  rowPayerLink: { selector: '[id^=payer-link-]' },
  rowPayment: { selector: '[id^=cell-payment-]' },
  rowPaymentLink: { selector: '[id^=payment-link-]' },
  rowTransactionDate: { selector: '[id^=cell-transactionDate-]' },
  rowPaymentAmount: { selector: '[id^=cell-paymentAmount-]' },
  rowAvailableAmount: { selector: '[id^=cell-availableAmount-]' },
  rowUnlinkPayment: { selector: '[id^=cell-unlinkPayment-]' },
  iconUnlinkPayment: { selector: '[id^=icon-unlinkPayment-]' },
};

class NebTableEraEobManagementPaymentDetails extends NebTable {
  static get properties() {
    return {
      report: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .payment-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          cursor: pointer;
          z-index: 1;
        }

        .payer-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .label {
          margin-right: 5px;
        }

        .icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();

    this.onUpdateModel = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickLink: e => {
        e.stopPropagation();
        let key = e.currentTarget.getAttribute('key');
        key = key === 'payer' ? 'paymentPayer' : key;
        const index = Number(e.currentTarget.getAttribute('index'));
        this.onClickLink(key, index);
      },
      clickUnlinkPayment: async e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Payment Association',
          message: 'Are you sure you want to remove this payment association?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          const { id, type, reportId } = this.report;

          try {
            if (type === 'EOB') {
              await unlinkEobPayment(id, this.model[index].id);
            } else {
              await unlinkEraPayment(id, this.model[index].id, reportId);
            }

            store.dispatch(openSuccess(TEXT_UNLINK_PAYMENT_SUCCESS(type)));

            this.onUpdateModel();
          } catch (err) {
            store.dispatch(openError(TEXT_UNLINK_PAYMENT_ERROR(type)));
          }
        }
      },
    };
  }

  initState() {
    super.initState();
    this.report = {
      id: '',
      reportId: '',
      type: 'EOB',
      paymentAmount: 0,
    };

    this.config = this.__buildConfig();

    this.onClickLink = () => {};
  }

  __buildConfig() {
    return [
      {
        label: '',
        truncate: true,
        flex: css`0 0 ${CSS_SPACING}`,
      },
      {
        truncate: true,
        key: 'payer',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'payment',
        label: 'Payment ID',
        flex: css`1 0 0`,
      },
      {
        key: 'date',
        label: 'Transaction Date',
        flex: css`1 0 0`,
        formatter: d => moment(d).format('MM/DD/YYYY'),
      },
      {
        key: 'paymentAmount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
      {
        key: 'availableAmount',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
      {
        truncate: true,
        key: 'unlinkPayment',
        label: '',
        flex: css`0 0 ${CSS_SPACING}`,
      },
    ];
  }

  __renderPayer({ value, rowIndex, columnConfig }) {
    const { key } = columnConfig;

    return this.model[rowIndex].payer
      ? html`
          <div
            id="payer-link-${rowIndex}"
            key="${key}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickLink}"
          >
            <span class="payer-text">(${value.alias}) ${value.name}</span>
          </div>
        `
      : super.renderDataCell(value, columnConfig, rowIndex);
  }

  __renderPaymentNumber({ value, rowIndex, columnConfig }) {
    const { key } = columnConfig;

    return this.model[rowIndex].payment
      ? html`
          <div
            id="payment-link-${rowIndex}"
            key="${key}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickLink}"
          >
            <span class="payment-text">${value.number}</span>
          </div>
        `
      : super.renderDataCell(value, columnConfig, rowIndex);
  }

  __renderUnlinkPayment({ rowIndex, columnConfig }) {
    const { key } = columnConfig;

    let canUnlink = true;

    if (
      this.report.type === 'ERA' &&
      this.report.paymentAmount > 0 &&
      this.model.length === 1
    ) {
      canUnlink = false;
    }
    return canUnlink
      ? html`
          <neb-icon
            id="icon-unlinkPayment-${rowIndex}"
            key="${key}"
            class="icon"
            icon="neb:minus"
            index="${rowIndex}"
            @click="${this.handlers.clickUnlinkPayment}"
          ></neb-icon>
        `
      : '';
  }

  renderHeaderCell(headerCell) {
    return super.renderHeaderCell(headerCell);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'payer':
        return this.__renderPayer({ value, rowIndex, columnConfig });

      case 'payment':
        return this.__renderPaymentNumber({ value, rowIndex, columnConfig });

      case 'unlinkPayment':
        return this.__renderUnlinkPayment({ rowIndex, columnConfig });

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-era-eob-management-payment-details',
  NebTableEraEobManagementPaymentDetails,
);
