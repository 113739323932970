import { MDCTextField } from '@material/textfield';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';
import validators from 'validator';

import {
  CSS_COLOR_WHITE,
  CSS_COLOR_BLACK,
  CSS_COLOR_DISABLED,
  CSS_COLOR_DISABLED_TEXT,
  CSS_COLOR_GREY_5,
  CSS_FONT_FAMILY,
  CSS_FIELD_MARGIN,
} from '../../../neb-styles/neb-variables';
import Debouncer from '../../../neb-utils/debouncer';
import textFieldStyle from '../../material_styles/mdc-text-field-css';

const DEBOUNCE_DURATION = 300;
const STYLE_INPUT_BASE = 'textfield-base';
const BLUR_EVENT_NAME = 'blur';
const REGEX_ALL = /[\s\S]+/;
const REGEX_SSN = /^\d{3}-\d{2}-\d{4}$/g;
const REGEX_DATE = /^\d{2}\/\d{2}\/\d{4}$/;
const BASE_MASK = {
  pattern: REGEX_ALL,
  toRawPosition: origPos => origPos,
  toRawValue: origVal => origVal,
  toFormattedPosition: rawPos => rawPos,
  toFormattedValue: rawVal => rawVal,
};
const internalValidator = {
  isNumeric: value => validators.isNumeric(value),
  isAlphanumeric: value => validators.isAlphanumeric(value, 'en-US'),
  isMobilePhone: value => validators.isMobilePhone(value, 'en-US'),
  isPostalCode: value => validators.isPostalCode(value, 'US'),
  isURL: value => validators.isURL(value),
  isSSN: value => {
    const res = value.match(REGEX_SSN);

    return res ? res.length > 0 : false;
  },
  isNoSpace: value => !value.includes(' '),
  isDate: value => {
    if (!moment(value).isValid()) return false;
    const res = value.match(REGEX_DATE);

    return res ? res.length > 0 : false;
  },
  isDateOfBirth: value => {
    const valid = internalValidator.isDate(value);

    if (valid) {
      const validDate = moment(value).toDate();
      const oldestDateAllowed = new Date(1900, 0, 1);
      const isTooOld = validDate < oldestDateAllowed;
      const isFutureDate = validDate > new Date();

      return !(isTooOld || isFutureDate);
    }

    return valid;
  },
};
export const ELEMENTS = {
  label: {
    id: 'label',
  },
  helperText: {
    id: 'helper-text',
  },
  charCount: {
    id: 'char-count',
  },
  staticLabel: {
    id: 'static-label',
  },
};

const isMeta = e => e.altKey || e.ctrlKey || e.metaKey;

const isPrintableKeyboardEvent = e =>
  !e.defaultPrevented && !isMeta(e) && [...e.key].length === 1;

class NebMDBaseTextField extends LitElement {
  static get properties() {
    return {
      __selectionStart: Number,
      __selectionEnd: Number,
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      errorText: String,
      helperText: String,
      placeholder: String,
      validator: String,
      value: String,
      name: String,
      mask: Object,
      valid: {
        type: Boolean,
        reflect: true,
      },
      required: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      disableValidation: {
        type: Boolean,
        reflect: true,
      },
      noDebounce: {
        type: Boolean,
        reflect: true,
      },
      showCharacterCount: {
        type: Boolean,
        reflect: true,
      },
      isStaticLabel: {
        type: Boolean,
        reflect: true,
        attribute: 'is-static-label',
      },
      rightAligned: {
        type: Boolean,
        reflect: true,
        attribute: 'right-aligned',
      },
      noWrap: {
        type: Boolean,
        reflect: true,
        attribute: 'no-wrap',
      },
      labelText: {
        type: String,
        reflect: true,
      },
      maxLength: {
        reflect: true,
        type: Number,
      },
      minLength: {
        reflect: true,
        type: Number,
      },
      canClear: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  get shouldValidate() {
    return Boolean(!this.disableValidation);
  }

  get shouldDebounce() {
    return Boolean(!this.noDebounce && this.shouldValidate);
  }

  get helperTextContent() {
    return this.valid ? this.helperText : this.errorText;
  }

  get inputEl() {
    throw new Error('inputEl - property not implemented');
  }

  get textfieldClasses() {
    return this._textFieldClasses();
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__focused = false;
    this.__firstTime = true;
    this.valid = true;
    this.noDebounce = false;
    this.showCharacterCount = false;
    this.disableValidation = false;
    this.isStaticLabel = false;
    this.minLength = 0;
    this.value = '';
    this.errorText = '';
    this.helperText = '';
    this.labelText = '';
    this.placeholder = '';
    this.textField = {};
    this.canClear = false;
    this.mask = BASE_MASK;

    this.onBlur = () => {};

    this.onChange = () => {};

    this.externalValidator = () => true;
  }

  __applyMask({ mask, origPos, origVal }) {
    const rawPos = mask.toRawPosition(origPos, origVal);
    const rawVal = mask.toRawValue(origVal);
    const formatPos = mask.toFormattedPosition(rawPos, rawVal, origVal);
    const formatVal = mask.toFormattedValue(rawVal);

    let outputFormat;
    let skipSync = false;

    if (this.canClear && origVal.length === 0) {
      outputFormat = origVal;
      skipSync = true;
    } else {
      outputFormat = formatVal;
    }

    return {
      formatPos,
      outputFormat,
      skipSync,
    };
  }

  initHandlers() {
    this.handlers = {
      focus: () => {
        this.__focused = true;
      },
      blur: () => {
        this.__focused = false;
        this.onBlur();
      },
      keydown: e => {
        const printable = isPrintableKeyboardEvent(e);
        const passed = this.mask.pattern.test(e.key);

        if (printable && !passed) {
          e.preventDefault();
        }
      },
      input: e => {
        const { mask } = this;
        const { value: origVal, selectionEnd: origPos } = e.currentTarget;

        const { formatPos, outputFormat, skipSync } = this.__applyMask({
          mask,
          origPos,
          origVal,
        });

        this.value = outputFormat;

        if (this.shouldDebounce) {
          this.debouncer.debounce();
        } else {
          this.handlers.debounce();
        }

        if (!skipSync) this.__syncSelectionPosition(formatPos);
      },
      debounce: () => {
        this.onChange({
          id: this.id,
          value: this.value,
          name: this.name,
        });
      },
    };

    this.debouncer = new Debouncer(this.handlers.debounce, DEBOUNCE_DURATION);
  }

  connectedCallback() {
    super.connectedCallback(); // NEB-19642 - Don't apply focus listener if non-default mask is present

    if (this.mask === BASE_MASK) {
      this.addEventListener('focus', this.handlers.focus);
    }
    this.addEventListener('blur', this.handlers.blur);
  }

  focused() {
    return this.__focused;
  }

  __updateFocus(changedProps) {
    if (changedProps.has('__focused') && this.__focused) {
      this.inputEl.focus();
    }
  }

  firstUpdated() {
    this.textField = MDCTextField.attachTo(
      this.shadowRoot.querySelector('.mdc-text-field'),
    );

    this.__initTextField();

    if (this.shouldValidate) {
      this.validateOnBlur();
    }
  }

  async updated(changedProps) {
    this.__updateFocus(changedProps);

    this.__updateTextField(changedProps);

    if (this.shouldValidate) {
      if (!this.__firstTime) this.__validateTextField();
      await this.__renderLayout();
    }

    this.__firstTime = false;
  }

  validate() {
    return this.__validateTextField();
  }

  __syncSelectionPosition(pos) {
    const { selectionStart, selectionEnd } = this.inputEl;

    if (pos === selectionStart) {
      this.__selectionStart = selectionStart;
      this.__selectionEnd = selectionEnd;
    } else {
      this.__selectionStart = pos;
      this.__selectionEnd = pos;
    }
  }

  __initTextField() {
    if (this.textField.label_) this.textField.label_.shake(false);
    this.textField.useNativeValidation = false;
  }

  __updateTextField() {
    if (this.value !== undefined) {
      this.textField.value = this.value || '';
    }

    this.textField.required = this.required;
    this.textField.disabled = this.disabled;
    this.textField.valid = this.valid;
    this.textField.helperTextContent = this.helperTextContent;

    this.__updateSelectionStart();

    this.__updateSelectionEnd();
  }

  __updateSelectionStart() {
    if (
      this.__selectionStart !== undefined &&
      this.__selectionStart !== this.inputEl.selectionStart
    ) {
      this.inputEl.selectionStart = this.__selectionStart;
    }
  }

  __updateSelectionEnd() {
    if (
      this.__selectionEnd !== undefined &&
      this.__selectionEnd !== this.inputEl.selectionEnd
    ) {
      this.inputEl.selectionEnd = this.__selectionEnd;
    }
  }

  __renderTextFieldLayout() {
    this.textField.valid = this.valid;

    if (this.textField.layout) {
      this.textField.layout();
    }
  }

  __renderLayout() {
    const promise = new Promise((resolve, reject) =>
      setTimeout(() => {
        try {
          this.__renderTextFieldLayout();

          resolve();
        } catch (e) {
          reject(e);
        }
      }, 0),
    );
    Promise.resolve(promise);
  }

  __validateMinLength(value) {
    return value && value.length > 0 && this.minLength > value.length;
  }

  __validation(value) {
    if (!this.validator) return true;

    if (Object.keys(internalValidator).includes(this.validator)) {
      return internalValidator[this.validator](value);
    }

    return validators[this.validator](value);
  }

  __trimValue(value) {
    return typeof value === 'string' ? value.trim() : value;
  }

  __validateValue(value) {
    if (this.__validateMinLength(value)) return false;

    return this.__trimValue(value) ? this.__validation(value) : !this.required;
  }

  __evaluateValidation() {
    let valid = true;
    if (this.externalValidator) valid = this.externalValidator();
    if (valid) valid = this.__validateValue(this.value);

    return valid;
  }

  __validateTextField() {
    const valid = this.shouldValidate ? this.__evaluateValidation() : true;
    this.valid = valid;

    return valid;
  }

  validateOnBlur() {
    this.textField.foundation_.adapter_.registerInputInteractionHandler(
      BLUR_EVENT_NAME,
      evt => {
        if (evt.type === BLUR_EVENT_NAME) {
          if (this.shouldDebounce) this.debouncer.fireImmediate();
          window.setTimeout(() => this.__validateTextField(), 0);
        }
      },
    );
  }

  isPinned() {
    return Boolean(this.value) || this.__focused || this.isStaticLabel;
  }

  getPlaceholder() {
    return !this.labelText || this.isPinned() ? this.placeholder : '';
  }

  static get styles() {
    return [
      textFieldStyle,
      css`
        /* DO NOT TOUCH - Chirotouch Spec - Fixes issue with height not fitting child height */
        :host {
          display: flex;
          flex-direction: column;

          font-size: 14px;
        }

        .mdc-text-field {
          height: 40px;
          margin-top: ${CSS_FIELD_MARGIN};
          border-radius: 4px;
        }

        :host([labelText='']) .mdc-text-field {
          margin-top: 0;
        }

        :host([slim]) .mdc-text-field {
          margin: 0;
        }

        :host([is-static-label]) .mdc-text-field {
          margin-top: 5px;
        }

        :host([right-aligned]) .mdc-text-field__input {
          text-align: right;
          padding-right: 10px;
        }

        :host([no-wrap]) .mdc-text-field-helper-text {
          white-space: nowrap;
        }

        :host(:not([valid])) .text-field-static-label {
          color: var(--mdc-theme-error);
        }

        :host([disabled]) .text-field-static-label {
          color: ${CSS_COLOR_DISABLED};
        }

        .mdc-floating-label {
          top: 12px;
        }

        .mdc-text-field__input::placeholder {
          opacity: 1;
        }

        .mdc-text-field:not(.mdc-text-field--active) .mdc-floating-label {
          color: ${CSS_COLOR_GREY_5};
        }

        .mdc-text-field--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          color: ${CSS_COLOR_BLACK};
        }

        .mdc-text-field.mdc-text-field--disabled .mdc-floating-label {
          color: ${CSS_COLOR_DISABLED};
        }

        .mdc-text-field--disabled .mdc-text-field__input {
          color: ${CSS_COLOR_DISABLED_TEXT};
          -webkit-text-fill-color: ${CSS_COLOR_DISABLED_TEXT};
          -webkit-opacity: 1;
        }

        .mdc-text-field--disabled
          + .mdc-text-field-helper-line
          .mdc-text-field-helper-text {
          color: ${CSS_COLOR_DISABLED};
        }

        .mdc-text-field--focused:not(.mdc-text-field--disabled)
          .mdc-floating-label {
          color: var(--mdc-theme-primary);
        }

        .mdc-text-field--invalid:not(.mdc-text-field--disabled)
          .mdc-floating-label {
          color: var(--mdc-theme-error);
        }

        .mdc-text-field--focused
          .mdc-text-field__input:required
          ~ .mdc-floating-label::after,
        .mdc-text-field--focused
          .mdc-text-field__input:required
          ~ .mdc-notched-outline
          .mdc-floating-label::after {
          color: var(--mdc-theme-primary);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixes the default label text in the text area before select */
        .mdc-notched-outline .mdc-floating-label {
          top: 12px;
        }
        .mdc-text-field--outlined .mdc-floating-label {
          top: 12px;
        }
        .mdc-text-field--outlined .mdc-text-field__input {
          padding: 10px 0 10px 15px;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the default label text height floating on highlight */
        .mdc-text-field--outlined.mdc-notched-outline--upgraded
          .mdc-floating-label--float-above,
        .mdc-text-field--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          transform: translateY(-180%) translateX(-11px) scale(0.8);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed floating label animation */
        .mdc-text-field--outlined .mdc-floating-label--shake {
          animation: none;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the above missing line on highlight */
        .mdc-notched-outline--notched .mdc-notched-outline__notch {
          border-top: 1px solid rgba(0, 0, 0, 0.38);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed width of notching space for component */
        .mdc-notched-outline .mdc-notched-outline__notch {
          width: 100%;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed ellipsis from extending past container */
        .mdc-notched-outline .mdc-notched-outline__notch .mdc-floating-label {
          max-width: calc(100% - 36px);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed floating label from being ellipsisfied */
        .mdc-notched-outline--notched
          .mdc-notched-outline__notch
          .mdc-floating-label {
          max-width: unset;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the helper line padding */
        .mdc-text-field + .mdc-text-field-helper-line {
          padding-left: 6px;
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
          background-color: ${CSS_COLOR_WHITE};
        }

        .mdc-text-field--outlined.mdc-text-field--disabled
          .mdc-notched-outline__leading,
        .mdc-text-field--outlined.mdc-text-field--disabled
          .mdc-notched-outline__notch,
        .mdc-text-field--outlined.mdc-text-field--disabled
          .mdc-notched-outline__trailing {
          border-color: ${CSS_COLOR_DISABLED};
        }

        .textfield-base {
          width: 100%;
        }

        .mdc-text-field-helper-text::before {
          display: inline-block;
          width: 0;
          height: 10px;
          content: '';
          vertical-align: 0;
        }

        .mdc-text-field-character-counter::before {
          display: inline-block;
          width: 0;
          height: 10px;
          content: '';
          vertical-align: 0;
        }

        .text-field-static-label {
          font-size: 11.2px;
          font-family: ${CSS_FONT_FAMILY};
          color: ${CSS_COLOR_BLACK};
        }

        :focus {
          outline: none;
        }
      `,
    ];
  }

  _textFieldClasses() {
    return `${STYLE_INPUT_BASE} mdc-text-field mdc-text-field--outlined`;
  }

  __mdcNotchLabelHTML(ID_INPUT) {
    if (!this.labelText.length || this.isStaticLabel) return '';

    return html`
      <label
        id="${ELEMENTS.label.id}"
        for="${ID_INPUT}"
        class="mdc-floating-label"
        >${this.labelText}</label
      >
    `;
  }

  __mdcNotchHTML(ID_INPUT) {
    return html`
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>

        <div class="mdc-notched-outline__notch">
          ${this.__mdcNotchLabelHTML(ID_INPUT, ELEMENTS.label.id)}
        </div>

        <div class="mdc-notched-outline__trailing"></div>
      </div>
    `;
  }

  __mdcHelperTextHTML() {
    return this.helperTextContent
      ? html`
          <div class="mdc-text-field-helper-line">
            <div
              class="mdc-text-field-helper-text
          mdc-text-field-helper-text--persistent
          mdc-text-field-helper-text--validation-msg"
              id="${ELEMENTS.helperText.id}"
              aria-hidden="true"
            ></div>
            ${this.__mdcCharacterCountTextHTML()}
          </div>
        `
      : '';
  }

  __mdcCharacterCountTextHTML() {
    return this.showCharacterCount
      ? html`
          <div
            id="${ELEMENTS.charCount.id}"
            class="mdc-text-field-character-counter"
          ></div>
        `
      : '';
  }

  _renderMDCStaticLabel() {
    return this.isStaticLabel
      ? html`
          <label id="${ELEMENTS.staticLabel.id}" class="text-field-static-label"
            >${this.labelText}</label
          >
        `
      : '';
  }

  renderTextfield() {
    throw new Error('renderTextfield() - not implemented');
  }

  render() {
    return html`
      ${this._renderMDCStaticLabel()}

      <div class="${this.textfieldClasses}" tabindex="0">
        ${this.renderTextfield()}
      </div>

      ${this.__mdcHelperTextHTML()}
    `;
  }
}

export { NebMDBaseTextField, DEBOUNCE_DURATION };
