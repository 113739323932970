import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/inputs/neb-select';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';
import {
  FEDERAL_TAX_ID_SOURCE,
  FEDERAL_TAX_ID_TYPE,
} from '../../neb-utils/enums';
import { taxId as taxIdMask, ssn } from '../../neb-utils/masks';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  buttonOptions: { selector: '[id^=option-]' },
  buttonNone: { id: 'button-none' },
  buttonOther: { id: 'button-other' },
  buttonSSN: { id: 'button-ssn' },
  buttonEIN: { id: 'button-ein' },
  inputTaxId: { id: 'input-tax-id' },
  containerLocations: { id: 'container-locations' },
  buttonLocation: { id: 'button-location' },
  selectLocation: { id: 'select-location' },
};

const isSSN = type => type === FEDERAL_TAX_ID_TYPE.SSN;

const OTHER_LABEL = 'Other';
const NONE_LABEL = 'None';
const LOCATION_LABEL = 'Location';
const TAX_ID_LENGTH = 9;

const getRawNumber = string => string.replace(/-/g, '');

class NebPopupClaimFederalTaxId extends NebPopupClaim {
  static get properties() {
    return {
      __otherType: String,
      __otherNumber: String,
      __selectedLocation: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          margin-top: 10px;
        }

        .container-buttons {
          display: flex;
          flex-direction: column;
        }

        .container-other {
          display: flex;
          align-items: center;
        }

        .container-locations {
          display: flex;
          align-items: center;
        }

        .select-location {
          flex-grow: 1;
          padding-bottom: 10px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .text-input {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__otherType = FEDERAL_TAX_ID_TYPE.SSN;
    this.__otherNumber = '';
    this.__selectedLocation = {};

    this.__state = {
      federalTaxId: {
        federalTaxIdType: null,
        federalTaxIdNumber: null,
        federalTaxIdSource: null,
      },
    };

    this.__errors = {
      federalTaxIdNumber: '',
    };

    this.model = {
      title: '',
      tooltipText: '',
      federalTaxIdType: null,
      federalTaxIdNumber: null,
      federalTaxIdSource: null,
      options: [],
      practiceTaxId: '',
      locations: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        switch (name) {
          case 'otherType': {
            this.__selectedLocation = {};
            this.__otherType = value;
            this.__state.federalTaxId.federalTaxIdType = this.__otherType;

            const rawNumber = getRawNumber(this.__otherNumber);

            this.__otherNumber = isSSN(this.__otherType)
              ? ssn.toFormattedValue(rawNumber)
              : taxIdMask.toFormattedValue(rawNumber);

            this.__state.federalTaxId.federalTaxIdNumber = this.__otherNumber;

            break;
          }

          case 'otherNumber': {
            this.__selectedLocation = {};
            this.__otherNumber = value;
            this.__state.federalTaxId.federalTaxIdNumber = this.__otherNumber;

            const rawNumber = getRawNumber(this.__otherNumber);

            this.__errors =
              rawNumber.length === TAX_ID_LENGTH || !rawNumber.length
                ? { ...this.__errors, federalTaxIdNumber: '' }
                : {
                    ...this.__errors,
                    federalTaxIdNumber: `Must be ${TAX_ID_LENGTH} digits`,
                  };

            break;
          }

          case 'federalTaxId': {
            this.__selectedLocation = {};
            this.__errors = { ...this.__errors, federalTaxIdNumber: '' };

            if (
              this.__state.federalTaxId.federalTaxIdSource ===
                FEDERAL_TAX_ID_SOURCE.OTHER &&
              value.federalTaxIdSource !== FEDERAL_TAX_ID_SOURCE.OTHER
            ) {
              this.__otherNumber = '';
            }

            this.__state = {
              ...this.__state,
              federalTaxId: value,
            };

            break;
          }
          case ELEMENTS.buttonLocation.id:
          case ELEMENTS.selectLocation.id:
            this.__selectedLocation = value;
            this.__errors = { ...this.__errors, federalTaxIdNumber: '' };

            if (
              this.__state.federalTaxId.federalTaxIdSource ===
                FEDERAL_TAX_ID_SOURCE.OTHER &&
              value.federalTaxIdSource !== FEDERAL_TAX_ID_SOURCE.OTHER
            ) {
              this.__otherNumber = '';
            }
            this.__state = {
              ...this.__state,
              federalTaxId: value.value,
            };

            break;

          default:
            break;
        }
      },
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      const {
        federalTaxIdType,
        federalTaxIdNumber,
        federalTaxIdSource,
        practiceTaxId,
      } = this.model;

      this.__state = {
        federalTaxId: {
          federalTaxIdType,
          federalTaxIdNumber,
          federalTaxIdSource,
        },
      };

      if (!federalTaxIdSource && federalTaxIdNumber) {
        if (federalTaxIdNumber === practiceTaxId) {
          this.__federalTaxIdSource = FEDERAL_TAX_ID_SOURCE.PRACTICE_TAX_ID;
        } else {
          this.__federalTaxIdSource = FEDERAL_TAX_ID_SOURCE.OTHER;
        }
      }

      if (this.__federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.OTHER) {
        this.__otherType = federalTaxIdType;
        this.__otherNumber = federalTaxIdNumber;
      }
    }
  }

  get __federalTaxIdSource() {
    const {
      federalTaxId: { federalTaxIdSource },
    } = this.__state;
    return federalTaxIdSource;
  }

  set __federalTaxIdSource(value) {
    this.__state = {
      ...this.__state,
      federalTaxId: {
        ...this.__state.federalTaxId,
        federalTaxIdSource: value,
      },
    };
  }

  get __otherValue() {
    return {
      federalTaxIdType: this.__otherType,
      federalTaxIdNumber: this.__otherNumber,
      federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.OTHER,
    };
  }

  get __otherOption() {
    const isOther = equal(
      this.__federalTaxIdSource,
      FEDERAL_TAX_ID_SOURCE.OTHER,
    );
    const isTypeSSN = isSSN(this.__otherType);

    return html`
      <div class="container-other">
        <neb-radio-button
          id="${ELEMENTS.buttonOther.id}"
          name="federalTaxId"
          .label="${OTHER_LABEL}"
          .value="${this.__otherValue}"
          .checked="${isOther}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>
        <neb-textfield
          id="${ELEMENTS.inputTaxId.id}"
          class="text-input"
          helper=" "
          label="Tax ID"
          name="otherNumber"
          .mask="${isTypeSSN ? ssn : taxIdMask}"
          .inputMode="${'numeric'}"
          .maxLength="${isTypeSSN ? 11 : 10}"
          .value="${this.__otherNumber}"
          .error="${this.__errors.federalTaxIdNumber}"
          .onChange="${this.handlers.change}"
          ?disabled="${!isOther}"
        ></neb-textfield>
        <neb-radio-button
          id="${ELEMENTS.buttonSSN.id}"
          label="SSN"
          name="otherType"
          .value="${FEDERAL_TAX_ID_TYPE.SSN}"
          .checked="${isTypeSSN}"
          .onChange="${this.handlers.change}"
          ?disabled="${!isOther}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.buttonEIN.id}"
          label="EIN"
          name="otherType"
          .value="${FEDERAL_TAX_ID_TYPE.EIN}"
          .checked="${!isTypeSSN}"
          .onChange="${this.handlers.change}"
          ?disabled="${!isOther}"
        ></neb-radio-button>
      </div>
    `;
  }

  get __noneValue() {
    return {
      federalTaxIdType: '',
      federalTaxIdNumber: '',
      federalTaxIdSource: null,
    };
  }

  get __noneOption() {
    return html`
      <neb-radio-button
        id="${ELEMENTS.buttonNone.id}"
        name="federalTaxId"
        .label="${NONE_LABEL}"
        .value="${this.__noneValue}"
        .checked="${equal(this.__state.federalTaxId, this.__noneValue)}"
        .onChange="${this.handlers.change}"
      ></neb-radio-button>
    `;
  }

  __renderFederalTaxId() {
    const { locations } = this.model;
    const locationIds = locations.map(({ id }) => id);

    return html`
      ${this.model.options
        .filter(
          ({ value: { federalTaxIdSource } }) =>
            !locationIds.includes(federalTaxIdSource),
        )
        .map(
          ({ label, value }, index) => html`
            <neb-radio-button
              id="option-${index}"
              label="${label}"
              name="federalTaxId"
              .value="${value}"
              .checked="${equal(value, this.__state.federalTaxId)}"
              .onChange="${this.handlers.change}"
            ></neb-radio-button>
          `,
        )}
    `;
  }

  __renderLocationOptions() {
    const { locations } = this.model;
    const locationOptions = locations
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((acc, location) => {
        const { taxId, name, id } = location;
        if (!taxId) return acc;

        const label = `${name} Tax ID - ${taxId} (${FEDERAL_TAX_ID_TYPE.EIN})`;
        const value = {
          federalTaxIdType: FEDERAL_TAX_ID_TYPE.EIN,
          federalTaxIdNumber: taxId,
          federalTaxIdSource: id,
        };

        const hasTaxId = acc.some(
          ({ value: optionValue }) =>
            optionValue.federalTaxIdNumber === value.federalTaxIdNumber,
        );
        if (hasTaxId) return acc;

        const option = { label, value };

        if (
          value.federalTaxIdSource ===
          this.__state.federalTaxId.federalTaxIdSource
        ) {
          this.__selectedLocation = option;
        }

        return [...acc, option];
      }, []);

    const isLocationTaxIdChecked = locations
      .map(({ id }) => id)
      .includes(this.__federalTaxIdSource);

    return locationOptions.length
      ? html`
          <div
            id="${ELEMENTS.containerLocations.id}"
            class="container-locations"
          >
            <neb-radio-button
              id="${ELEMENTS.buttonLocation.id}"
              name="${ELEMENTS.buttonLocation.id}"
              .label="${LOCATION_LABEL}"
              .value="${isLocationTaxIdChecked
                ? this.__selectedLocation
                : locationOptions[0]}"
              .checked="${isLocationTaxIdChecked}"
              .onChange="${this.handlers.change}"
            ></neb-radio-button>
            <neb-select
              id="${ELEMENTS.selectLocation.id}"
              name="${ELEMENTS.selectLocation.id}"
              class="select select-location"
              label="Select location"
              .items="${locationOptions}"
              .value="${this.__selectedLocation}"
              .onChange="${this.handlers.change}"
              ?disabled="${!isLocationTaxIdChecked}"
              wrapText
            ></neb-select>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        ${this.__renderFederalTaxId()} ${this.__renderLocationOptions()}
        ${this.__otherOption} ${this.__noneOption}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-federal-tax-id',
  NebPopupClaimFederalTaxId,
);
