import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import './neb-calendar-time-column';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import { LitElement, html, css } from 'lit';

import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  baseStyles,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../styles';

export const ELEMENTS = {
  viewport: { id: 'viewport' },
  viewportTimeColumn: { id: 'viewport-time-column' },
  calendarTimeColumn: { id: 'calendar-time-column' },
  viewportTimeLine: { id: 'viewport-time-line' },
  viewportColumnHeaders: { id: 'viewport-column-headers' },
  buttonIconZoomIn: { id: 'button-icon-zoom-in' },
  buttonIconZoomOut: { id: 'button-icon-zoom-out' },
  loadingOverlay: { id: 'loading-overlay' },
};

const TIME_SLOT_HEIGHT = 70;

export const INTERVALS = [1, 2, 4, 6, 12];

export const MINIMUM_START = 0;
export const MAXIMUM_END = 1440;

export function getTimeLinePosition(
  viewport,
  date,
  pixelsPerMinute,
  paddingOffset,
  startMinute = MINIMUM_START,
  endMinute = MAXIMUM_END,
) {
  const currentMinute = date.hours() * 60 + date.minutes();

  if (currentMinute > endMinute || currentMinute < startMinute) return null;

  const startOffset = startMinute * pixelsPerMinute;

  const timeLineTopOffset =
    currentMinute * pixelsPerMinute +
    paddingOffset -
    viewport.scrollTop -
    startOffset;

  if (timeLineTopOffset < paddingOffset) {
    return paddingOffset;
  }

  const lowerThreshold = viewport.offsetHeight - 20;

  if (timeLineTopOffset > lowerThreshold) {
    return lowerThreshold;
  }

  return timeLineTopOffset;
}

const VIEWPORT_PADDING_BOTTOM = 8;
export class NebBaseCalendarView extends LitElement {
  static get properties() {
    return {
      model: Object,
      folds: Array,
      startMinute: Number,
      endMinute: Number,
      viewportTopOffset: Number,
      zoomInterval: Number,
      isDragging: Boolean,
      isLoading: Boolean,
      __mappedFolds: Array,
      __timeLineTopOffset: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .button-icon {
          width: 32px;
          height: 32px;
        }

        .columns-header-container-layout,
        .columns-container-layout {
          position: relative;
          display: flex;
        }

        .viewport-timeline {
          position: absolute;
          left: 80px;
          right: ${CSS_SPACING};
          display: flex;
          align-items: center;
          pointer-events: none;
          z-index: 4;
        }

        .timeline-circle {
          height: 8px;
          width: 8px;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          border-radius: 8px;
        }

        .timeline {
          height: 1px;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          flex: 1;
        }

        .container-button-icons {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          padding-left: 25px;
          z-index: 5;
          background-color: ${CSS_COLOR_WHITE};
          width: 100px;
        }

        .viewport-time-column {
          float: left;
          display: flex;
          position: sticky;
          left: 0px;
          z-index: 3;
          background-color: ${CSS_COLOR_WHITE};
          padding-bottom: 5px;
        }

        .viewport-column-headers {
          position: sticky;
          top: 0;
          bottom: 0;
          z-index: 2;
          background-color: ${CSS_COLOR_WHITE};
        }

        .viewport {
          position: absolute;
          overflow: auto;
          padding-bottom: ${VIEWPORT_PADDING_BOTTOM}px;
          right: ${CSS_SPACING};
          bottom: ${CSS_SPACING};
          top: 0px;
          left: 0px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.model = {};
    this.folds = [];
    this.endMinute = MAXIMUM_END;
    this.startMinute = MINIMUM_START;
    this.viewportTopOffset = 0;
    this.zoomInterval = 0;
    this.isDragging = false;
    this.isLoading = false;

    this.onUpdateCalendar = () => {};

    this.onChangeZoomInterval = () => {};

    this.onClickFold = () => {};

    this.__timeLineTopOffset = null;
    this.__timeLineInterval = null;
    this.__hasFolded = false;
  }

  initHandlers() {
    this.handlers = {
      scroll: () => {
        this.__updateTimeLinePosition();
      },
      incrementZoomLevel: () => {
        const intervalIndex = INTERVALS.findIndex(
          interval => interval === this.zoomInterval,
        );

        const nextInterval = INTERVALS[intervalIndex + 1];

        if (nextInterval) this.onChangeZoomInterval(nextInterval);
      },
      decrementZoomLevel: () => {
        const intervalIndex = INTERVALS.findIndex(
          interval => interval === this.zoomInterval,
        );

        const prevInterval = INTERVALS[intervalIndex - 1];

        if (prevInterval) this.onChangeZoomInterval(prevInterval);
      },
      updateDragging: isDragging => {
        this.isDragging = isDragging;
      },
      updateCalendar: () => {
        this.onUpdateCalendar();
      },
      clickFold: foldIndex => {
        this.onClickFold(foldIndex);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__timeLineInterval = setInterval(
      () => this.__updateTimeLinePosition(),
      15000,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearInterval(this.__timeLineInterval);
  }

  __getStartMinute() {
    const startFold = this.folds.find(fold => fold.start === 0);

    if (startFold) {
      return startFold.isFolded ? startFold.roundedEnd : startFold.roundedStart;
    }

    return MINIMUM_START;
  }

  __getEndMinute() {
    const endFold = this.folds.find(fold => fold.start !== 0);

    if (endFold) {
      return endFold.isFolded ? endFold.roundedStart : endFold.roundedEnd;
    }

    return MAXIMUM_END;
  }

  updated(changedProps) {
    if (changedProps.has('zoomInterval') && this.zoomInterval) {
      this.__scrollToNow();
    }

    if (changedProps.has('folds')) {
      this.startMinute = this.__getStartMinute();
      this.endMinute = this.__getEndMinute();

      if (!this.__hasFolded && this.folds.length) {
        this.__hasFolded = true;
        this.__scrollToNow();
      }
    }

    this.__updateTimeLinePosition();
  }

  firstUpdated() {
    this.__scrollToNow();
    this.__updateTimeLinePosition();
  }

  __updateTimeLinePosition() {
    const viewport = this.shadowRoot.getElementById(ELEMENTS.viewport.id);

    if (viewport) {
      const now = parseDate();

      const pixelsPerMinute = this.zoomInterval * (TIME_SLOT_HEIGHT / 60);

      this.__timeLineTopOffset = getTimeLinePosition(
        viewport,
        now,
        pixelsPerMinute,
        this.viewportTopOffset,
        this.startMinute,
        this.endMinute,
      );
    }
  }

  __scrollToNow() {
    const viewport = this.shadowRoot.getElementById(ELEMENTS.viewport.id);

    if (viewport) {
      const now = parseDate();

      const pixelsPerMinute = this.zoomInterval * (TIME_SLOT_HEIGHT / 60);

      const currentMinute = now.hours() * 60 + now.minutes() - this.startMinute;

      const halfOfWindowSize =
        (viewport.clientHeight - this.viewportTopOffset) / 2;

      const centerScreenPosition =
        currentMinute * pixelsPerMinute +
        this.viewportTopOffset -
        halfOfWindowSize;

      this.__scrollTimeout = setTimeout(() => {
        viewport.scrollTop = centerScreenPosition;
      }, 0);
    }
  }

  getEvents(date, primaryKey, secondaryKey) {
    if (
      this.model &&
      this.model[date] &&
      this.model[date][primaryKey] &&
      this.model[date][primaryKey][secondaryKey]
    ) {
      return this.model[date][primaryKey][secondaryKey];
    }

    return {};
  }

  renderColumns() {
    throw new Error('renderColumns() not implemented');
  }

  renderColumnHeaders() {
    throw new Error('renderColumnHeaders() not implemented');
  }

  __renderTimeLine() {
    return this.__timeLineTopOffset !== null
      ? html`
          <div
            id="${ELEMENTS.viewportTimeLine.id}"
            class="viewport-timeline"
            style="top: ${this.__timeLineTopOffset}px"
          >
            <div class="timeline-circle"></div>

            <div class="timeline"></div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container-button-icons">
        <neb-button-icon
          id="${ELEMENTS.buttonIconZoomIn.id}"
          class="button-icon"
          icon="neb:zoomIn"
          .onClick="${this.handlers.incrementZoomLevel}"
        ></neb-button-icon>

        <neb-button-icon
          id="${ELEMENTS.buttonIconZoomOut.id}"
          class="button-icon"
          icon="neb:zoomOut"
          .onClick="${this.handlers.decrementZoomLevel}"
        ></neb-button-icon>
      </div>

      <div
        id="${ELEMENTS.viewport.id}"
        class="viewport"
        @scroll="${this.handlers.scroll}"
      >
        <div
          id="${ELEMENTS.viewportTimeColumn.id}"
          class="viewport-time-column"
        >
          <neb-calendar-time-column
            id="${ELEMENTS.calendarTimeColumn.id}"
            .interval="${this.zoomInterval}"
            .cornerBlockHeight="${this.viewportTopOffset}"
            .start="${this.startMinute}"
            .end="${this.endMinute}"
            .folds="${this.folds}"
            .onClickFold="${this.handlers.clickFold}"
          ></neb-calendar-time-column>
        </div>

        <div
          id="${ELEMENTS.viewportColumnHeaders.id}"
          class="viewport-column-headers"
        >
          <div class="columns-header-container-layout">
            ${this.renderColumnHeaders()}
          </div>
        </div>
        <div class="columns-container-layout">${this.renderColumns()}</div>
      </div>

      ${this.__renderTimeLine()}

      <neb-loading-overlay
        id="${ELEMENTS.loadingOverlay.id}"
        title="Loading Appointments..."
        .show="${this.isLoading}"
      ></neb-loading-overlay>
    `;
  }
}
