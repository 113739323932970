import { isRequired, isEmailAddress } from '@neb/form-validators';
import { html, css } from 'lit';

import Address from '../../../../packages/neb-lit-components/src/components/field-groups/neb-address';
import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { phone } from '../../../../packages/neb-utils/masks';
import { TIMEZONES } from '../../../../packages/neb-utils/neb-timezones';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { TIERS } from '../utils/form-utils';

import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../packages/neb-lit-components/src/components/neb-page-collection-header';
import '../../../../packages/neb-lit-components/src/components/neb-header';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  address: { id: 'address' },
  bio: { id: 'bio' },
  dstDisabled: { id: 'dstDisabled' },
  email: { id: 'email' },
  name: { id: 'name' },
  phoneNumber: { id: 'phoneNumber' },
  radioAdvanced: { id: 'radioAdvanced' },
  radioCore: { id: 'radioCore' },
  tabGroup: { id: 'tabGroup' },
  timezone: { id: 'timezone' },
  website: { id: 'website' },
};

const ADDRESS_HELPERS = {
  address1: 'Required',
  city: 'Required',
  state: 'Required',
  zipcode: 'Required',
};

export default class FormPracticeInformation extends Form {
  static get properties() {
    return {
      showSupportFields: { reflect: true, type: Boolean },
      enableTimezone: { reflect: true, type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .logo {
          width: 100%;
        }
        .section {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  static createModel() {
    return {
      address: { ...Address.createModel(), id: '' },
      bio: '',
      dstDisabled: false,
      email: '',
      id: '',
      name: '',
      phoneNumber: '',
      tier: TIERS.CORE,
      timezone: '',
      website: '',
    };
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired()],
        address: Address.createSelectors(Object.keys(ADDRESS_HELPERS)),
        timezone: {
          validators: [isRequired()],
          clipPristine: true,
          format: v => (v ? TIMEZONES.find(tz => tz.value === v) : ''),
          unformat: v => v.value,
        },
        ...(this.showSupportFields && {
          email: [isRequired(), isEmailAddress()],
          phoneNumber: selectors.phoneNumber({ validators: [isRequired()] }),
        }),
      },
      unformat: v => {
        const { address, ...rest } = v;

        return {
          ...rest,
          address: { ...address, zipCode: address.zipcode },
        };
      },
    };
  }

  initState() {
    super.initState();

    this.showSupportFields = false;
    this.enableTimezone = false;
  }

  __renderRadioButtons() {
    return this.showSupportFields
      ? html`
          <div class="grid grid-2">
            <neb-radio-button
              id="${ELEMENTS.radioCore.id}"
              name="tier"
              class="radio-button"
              label="ChiroTouch Cloud - Core"
              .value="${TIERS.CORE}"
              .checked="${this.state.tier === TIERS.CORE}"
              .onChange="${this.handlers.change}"
            >
            </neb-radio-button>

            <neb-radio-button
              id="${ELEMENTS.radioAdvanced.id}"
              name="tier"
              class="radio-button"
              label="ChiroTouch Cloud - Advanced"
              .value="${TIERS.ADVANCED}"
              .checked="${this.state.tier === TIERS.ADVANCED}"
              .onChange="${this.handlers.change}"
            >
            </neb-radio-button>
          </div>
        `
      : '';
  }

  __renderDetails() {
    return this.showSupportFields
      ? html`
          <neb-address
            id="${ELEMENTS.address.id}"
            class="span"
            name="address"
            .helpers="${ADDRESS_HELPERS}"
            .model="${this.state.address}"
            .errors="${this.errors.address}"
            .onChange="${this.handlers.change}"
          ></neb-address>

          <neb-textfield
            id="${ELEMENTS.phoneNumber.id}"
            name="phoneNumber"
            label="Phone Number"
            helper="Required"
            .mask="${phone}"
            .value="${this.state.phoneNumber}"
            .error="${this.errors.phoneNumber}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.email.id}"
            name="email"
            label="Email Address"
            helper="Required"
            maxLength="100"
            .value="${this.state.email}"
            .error="${this.errors.email}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderRadioButtons()}
      <div class="section">
        <div class="grid grid-2 grid-span-mobile">
          <neb-textfield
            id="${ELEMENTS.name.id}"
            class="span"
            name="name"
            label="Practice Name"
            maxLength="255"
            helper="Required"
            .value="${this.state.name}"
            .error="${this.errors.name}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textarea
            id="${ELEMENTS.bio.id}"
            class="span"
            name="bio"
            helper=" "
            label="Enter a short bio for your practice"
            maxLength="500"
            .value="${this.state.bio}"
            .onChange="${this.handlers.change}"
          ></neb-textarea>

          ${this.__renderDetails()}

          <neb-select
            id="${ELEMENTS.timezone.id}"
            name="timezone"
            label="Time Zone"
            helper="Required"
            .items="${TIMEZONES}"
            .value="${this.state.timezone}"
            .error="${this.errors.timezone}"
            .onChange="${this.handlers.change}"
            .disabled="${!this.enableTimezone}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.website.id}"
            name="website"
            label="Website"
            helper=" "
            maxLength="255"
            .value="${this.state.website}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-form-practice-information', FormPracticeInformation);
