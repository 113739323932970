import {
  Method,
  RESPONSE_TYPE,
  getTenantShortName,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const CACHE_KEY = 'tenant-logo';
export const PATH = 'logo';

export function fetch() {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: '/api/v1/tenants/:tenantId/logo',
    responseType: RESPONSE_TYPE.DATA_URL,
  });
}

export function fetchPublic() {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: '/public/v1/:shortName/logo',
    replacements: { shortName: getTenantShortName() },
    responseType: RESPONSE_TYPE.DATA_URL,
  });
}

export function remove() {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/logo',
    responseType: RESPONSE_TYPE.RAW,
  });
}

export function update(blob) {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: blob,
    cacheKey: CACHE_KEY,
    headers: {
      'Content-Type': blob.type,
    },
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/logo',
    responseType: RESPONSE_TYPE.RAW,
  });
}
