import '../../neb-card-payment-add';
import '../../neb-popup-header';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { SCHEDULED_PAYMENT_TYPE } from '../../../../../neb-utils/enums';
import { OVERLAY_KEYS, openOverlay } from '../../../utils/overlay-constants';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  oneTimeCard: {
    id: 'one-time-card',
  },
  recurringCard: {
    id: 'recurring-card',
  },
  paymentPlanCard: {
    id: 'payment-plan-card',
  },
};

class NebOverlayAddScheduledPayment extends Overlay {
  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      select: async type => {
        const scheduledPayment = await openOverlay(
          OVERLAY_KEYS.SCHEDULED_PAYMENT,
          {
            type,
            patientId: this.model.context.patientId,
          },
        );

        if (scheduledPayment) {
          this.onClose(scheduledPayment);
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          width: 700px;
          grid-gap: ${CSS_SPACING};
          grid-auto-rows: min-content;
          overflow: auto;
        }

        .header-container {
          padding: ${CSS_SPACING};
        }

        .item {
          margin: 0 ${CSS_SPACING};
        }

        .payment-plan {
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          title="Add Scheduled Payment"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>

        <div>
          Choose the scheduled payment type and proceed to payment details.
        </div>
      </div>

      <div class="item">
        <neb-card-payment-add
          id="${ELEMENTS.oneTimeCard.id}"
          icon="neb:billSingle"
          title="Scheduled Payment"
          description="One-time"
          .value="${SCHEDULED_PAYMENT_TYPE.SINGLE}"
          .onClick="${this.handlers.select}"
        ></neb-card-payment-add>
      </div>

      <div class="item">
        <neb-card-payment-add
          id="${ELEMENTS.recurringCard.id}"
          icon="neb:billDollarSign"
          title="Scheduled Payment"
          description="Recurring"
          .value="${SCHEDULED_PAYMENT_TYPE.RECURRING}"
          .onClick="${this.handlers.select}"
        ></neb-card-payment-add>
      </div>

      <div class="item payment-plan">
        <neb-card-payment-add
          id="${ELEMENTS.paymentPlanCard.id}"
          icon="neb:billMultiple"
          title="Scheduled Payment"
          description="Payment Plan"
          .value="${SCHEDULED_PAYMENT_TYPE.PLAN}"
          .onClick="${this.handlers.select}"
        ></neb-card-payment-add>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-add-scheduled-payment',
  NebOverlayAddScheduledPayment,
);
