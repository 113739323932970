import '../../patients/neb-patient-list-controller';

import { html, css } from 'lit';

import { CSS_SECONDARY_NAV_SEARCH_WIDTH } from '../../../../../neb-styles/neb-variables';
import Overlay, { TRANSITION_SIDE } from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};
class NebPatientListOverlay extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .list {
          width: ${CSS_SECONDARY_NAV_SEARCH_WIDTH};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.side = TRANSITION_SIDE.LEFT;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      add: patient => {
        if (patient) {
          this.onClose(patient);
        }
      },
    };
  }

  firstUpdated() {
    this.__open = true;
  }

  renderContent() {
    return html`
      <neb-patient-list-controller
        id="${ELEMENTS.controller.id}"
        class="list"
        .layout="${this.layout}"
        .patientId="${this.model.patientId}"
        .onAdd="${this.handlers.add}"
        .onAction="${this.handlers.dismiss}"
        .onSelect="${this.handlers.dismiss}"
      ></neb-patient-list-controller>
    `;
  }
}
window.customElements.define('neb-overlay-patient-list', NebPatientListOverlay);
