export const sortPatients = patients =>
  // eslint-disable-next-line complexity
  patients.sort((a, b) => {
    if (a.name.last < b.name.last) return -1;
    if (a.name.last > b.name.last) return 1;
    if (a.name.first < b.name.first) return -1;
    if (a.name.first > b.name.first) return 1;
    if (a.name.middle < b.name.middle) return -1;
    if (a.name.middle > b.name.middle) return 1;
    if (a.name.preferred < b.name.preferred) return -1;
    if (a.name.preferred > b.name.preferred) return 1;
    return 0;
  });

export const sortRawPatients = patients =>
  // eslint-disable-next-line complexity
  patients.sort((a, b) => {
    if (a.lastName < b.lastName) return -1;
    if (a.lastName > b.lastName) return 1;
    if (a.firstName < b.firstName) return -1;
    if (a.firstName > b.firstName) return 1;
    if (a.middleName < b.middleName) return -1;
    if (a.middleName > b.middleName) return 1;
    if (a.preferredName < b.preferredName) return -1;
    if (a.preferredName > b.preferredName) return 1;
    return 0;
  });
