import { html, css } from 'lit';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { MONTH_DAY_YEAR } from '../../../../packages/neb-input/nebFormatUtils';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_DISABLED,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  formatDollarAmount,
  formatDollarAmountWithNegative,
} from '../../../../packages/neb-utils/formatters';
import { getProviderAdjustmentsTotal } from '../../../utils/payment-util';

const REGEX_ALL = /[\s\S]+/;

function genInitialMask() {
  return {
    pattern: REGEX_ALL,
    toRawPosition: origPos => origPos,
    toRawValue: origVal => origVal,
    toFormattedPosition: rawPos => rawPos,
    toFormattedValue: rawVal => rawVal,
  };
}

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  rowDate: { selector: '[id^=cell-date-]' },
  rowEOB: { selector: '[id^=cell-eob-]' },
  rowPayer: { selector: '[id^=cell-payer-]' },
  rowAuthEFT: { selector: '[id^=cell-authEFT-]' },
  rowEraAmount: { selector: '[id^=cell-eraAmount-]' },
  rowPaymentAllocated: { selector: '[id^=cell-paymentAllocated-]' },
  rowAdjustmentAmount: { selector: '[id^=cell-adjustmentAmount-]' },
  rowAdjustmentApplied: { selector: '[id^=cell-adjustmentApplied-]' },
  iconEdit: { selector: '[id^=icon-edit-]' },
  eobEditLink: { id: 'eob-edit' },
  eraEditPayerPayment: { id: 'era-edit-payer-payment' },
};

class NebTableEraEobManagementDetails extends NebTable {
  static get properties() {
    return {
      isEOB: Boolean,
      isLegacy: Boolean,
      isVoided: Boolean,
      isRefunded: Boolean,
      report: Object,
      hasRcmProviderAdjustmentsFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-checkbox {
          padding: 0 5px;
        }

        .icon {
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon[disabled] {
          fill: ${CSS_COLOR_DISABLED};
          cursor: default;
        }

        .icon-warning {
          display: block;
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_ERROR};
        }

        .icon-edit {
          position: relative;
          transition: 250ms ease-in-out;
          width: 25px;
          height: 15px;
          z-index: 1;
        }

        .icon-edit:hover {
          cursor: pointer;
          opacity: 0.6;
        }

        .payer-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .era-eob-link {
          cursor: pointer;
        }

        .label {
          margin-right: 5px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickLink: e => {
        e.stopPropagation();
        const key = e.currentTarget.getAttribute('key');
        const index = Number(e.currentTarget.getAttribute('index'));

        if (key === 'eob' && this.isRefunded) return;

        this.onClickLink(key, index);
      },
      editPayerPayment: () => this.onEditPayerPayment(),
      editProviderAdjustment: e => {
        e.stopPropagation();
        const rowIndex = Number(e.currentTarget.getAttribute('index'));
        return this.onEditProviderAdjustment(rowIndex);
      },
    };
  }

  initState() {
    super.initState();
    this.isEOB = false;
    this.isLegacy = false;
    this.isVoided = false;
    this.isRefunded = false;
    this.config = this.__buildConfig();
    this.report = { paymentAllocated: 0 };
    this.hasRcmProviderAdjustmentsFF = false;

    this.onClickLink = () => {};

    this.onEditPayerPayment = async () => {};

    this.onEditProviderAdjustment = async () => {};
  }

  update(changedProps) {
    if (
      changedProps.has('isEOB') ||
      changedProps.has('isLegacy') ||
      changedProps.has('isVoided') ||
      changedProps.has('isRefunded') ||
      changedProps.has('hasRcmProviderAdjustmentsFF')
    ) {
      this.config = this.__buildConfig();
    }
    super.update(changedProps);
  }

  __getEditIconHeader() {
    if ((this.isEOB || !this.isLegacy) && !this.isVoided) {
      return [
        {
          truncate: true,
          key: this.isEOB ? 'eob' : 'era',
          label: '',
          flex: css`0 0 ${CSS_SPACING}`,
        },
      ];
    }

    return [
      {
        label: '',
        truncate: true,
        flex: css`0 0 ${CSS_SPACING}`,
      },
    ];
  }

  __buildConfig() {
    return [
      ...this.__getEditIconHeader(),
      {
        truncate: true,
        key: 'payer',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      ...(!this.isLegacy
        ? [
            {
              truncate: true,
              key: 'paymentId',
              label: 'Payment ID',
              flex: css`1 0 0`,
            },
            {
              truncate: true,
              key: 'date',
              label: 'Transaction Date',
              flex: css`1 0 0`,
              formatter: v => (v ? parseDate(v).format(MONTH_DAY_YEAR) : ''),
            },
          ]
        : []),
      {
        truncate: true,
        key: 'authEFT',
        label: 'Auth/Check/EFT#',
        flex: css`1 0 0`,
        maxLength: 30,
        mask: genInitialMask(),
      },
      {
        key: 'eraAmount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
      ...(!this.hasRcmProviderAdjustmentsFF
        ? []
        : [
            {
              key: 'providerAdjustments',
              label: 'Remit Offset',
              flex: css`1 0 0`,
            },
          ]),
      {
        key: 'paymentAllocated',
        label: 'Payment Allocated',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
      ...(!this.isLegacy
        ? [
            {
              key: 'availableAmount',
              label: 'Payment Balance',
              flex: css`1 0 0`,
              formatter: v => html`
                <neb-text>${formatDollarAmount(v)}</neb-text>
              `,
            },
          ]
        : []),
      ...(!this.hasRcmProviderAdjustmentsFF
        ? [
            {
              key: 'adjustmentAmount',
              label: 'Adjustment Amount',
              flex: css`1 0 0`,
              formatter: v => html`
                <neb-text>${formatDollarAmount(v)}</neb-text>
              `,
            },
            {
              key: 'adjustmentApplied',
              label: 'Adjustment Applied',
              flex: css`1 0 0`,
              formatter: v => html`
                <neb-text>${formatDollarAmount(v)}</neb-text>
              `,
            },
          ]
        : []),
      ...(this.isLegacy
        ? [
            {
              label: '',
              truncate: true,
              flex: css`0 0 ${CSS_SPACING}`,
            },
          ]
        : []),
    ];
  }

  __renderPayerName({ rowIndex, columnConfig }) {
    const { key } = columnConfig;
    const { id, alias, name } = this.model[rowIndex].payer;

    return id
      ? html`
          <div
            id="payer-link-${rowIndex}"
            key="${key}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickLink}"
          >
            <span class="payer-text">(${alias}) ${name}</span>
          </div>
        `
      : super.renderDataCell(name, columnConfig, rowIndex);
  }

  __renderEraUpdatePayerPayment({ columnConfig }) {
    const { key } = columnConfig;
    return html`
      <neb-icon
        id="${ELEMENTS.eraEditPayerPayment.id}"
        class="icon era-eob-link"
        icon="neb:edit"
        key="${key}"
        @click="${this.handlers.editPayerPayment}"
      ></neb-icon>
    `;
  }

  __renderEobEdit({ columnConfig }) {
    const { key } = columnConfig;
    return html`
      <neb-icon
        id="${ELEMENTS.eobEditLink.id}"
        class="icon era-eob-link"
        icon="neb:edit"
        key="${key}"
        ?disabled="${this.isRefunded}"
        @click="${this.handlers.clickLink}"
      ></neb-icon>
    `;
  }

  __renderPaymentAllocated(rowIndex) {
    const { paymentAllocated } = this.report;
    return html`
      <div id="cell-paymentAllocated-${rowIndex}">
        <span>${formatDollarAmount(paymentAllocated || null)}</span>
      </div>
    `;
  }

  __isSplitPayment(rowIndex) {
    return (
      this.report.payments?.length &&
      this.report.payments[rowIndex].parentPaymentId
    );
  }

  __renderEditIcon(rowIndex) {
    return !this.__isSplitPayment(rowIndex) && !this.isVoided
      ? html`
          <neb-icon
            id="icon-edit-${rowIndex}"
            class="icon icon-edit"
            icon="neb:edit"
            @click="${this.handlers.editProviderAdjustment}"
          >
          </neb-icon>
        `
      : '';
  }

  __renderProviderAdjustments({ value, rowIndex }) {
    return html`
      ${this.__renderEditIcon(rowIndex)}
      <neb-text id="provider-adjustment-${rowIndex}">
        ${formatDollarAmountWithNegative(
          getProviderAdjustmentsTotal(value),
        )}</neb-text
      >
    `;
  }

  renderHeaderCell(headerCell) {
    return super.renderHeaderCell(headerCell);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'payer':
        return this.__renderPayerName({ rowIndex, columnConfig });

      case 'paymentAllocated':
        return this.__renderPaymentAllocated(rowIndex);

      case 'era':
        return this.__renderEraUpdatePayerPayment({ rowIndex, columnConfig });

      case 'eob':
        return this.__renderEobEdit({ columnConfig });

      case 'providerAdjustments':
        return this.__renderProviderAdjustments({ value, rowIndex });

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

customElements.define(
  'neb-table-era-eob-management-details',
  NebTableEraEobManagementDetails,
);
