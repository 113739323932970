import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  rows: {
    selector: '.row',
  },
  rowSection: {
    selector: '.item-section',
  },
  rowQuestion: {
    selector: '.item-question',
  },
  rowText: {
    selector: '.item-answer',
  },
};

class NebChartingNotesDataTable extends LitElement {
  static get properties() {
    return {
      noteAnswers: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.noteAnswers = [];

    this.onSelectQuestion = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      questionSelected: e =>
        this.onSelectQuestion(Number(e.currentTarget.index)),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      .flex-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .scroll-container {
        flex: 1 0 0;
        overflow-y: auto;
      }

      .row {
        display: flex;
        min-height: 50px;
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        align-items: center;
      }

      .item {
        padding: 10px 0;
        margin-right: ${CSS_SPACING};
      }

      .item:first-child {
        margin-left: ${CSS_SPACING};
      }

      .item-header {
        margin-bottom: ${CSS_SPACING};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .item-section {
        width: 168px;
        text-transform: capitalize;
      }

      .item-question {
        flex: 1 0 0;
      }

      .item-answer {
        flex: 1 0 0;
      }

      .item-link {
        cursor: pointer;
        flex: 1 0 0;
        text-decoration: underline;
        color: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  render() {
    return html`
      <div class="flex-container">
        <div class="row">
          <div class="item item-header item-section">Section</div>
          <div class="item item-header item-question">Question</div>
          <div class="item item-header item-answer">Text</div>
        </div>

        <div class="scroll-container">
          ${this.noteAnswers.map(
            (item, index) => html`
              <div id="row-${index}" class="row">
                <div class="item item-section">${item.section}</div>

                <div
                  class="item item-question item-link"
                  .index="${index}"
                  @click="${this.__handlers.questionSelected}"
                >
                  ${item.question.text}
                </div>

                <div class="item item-answer">${item.answer.text}</div>
              </div>
            `,
          )}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-charting-notes-data-table',
  NebChartingNotesDataTable,
);
