import '../../../packages/neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import './neb-payfac-reader';

import { html, css } from 'lit';

import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import { CSS_FONT_FAMILY } from '../../../packages/neb-styles/neb-variables';
import { SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH } from '../../../packages/neb-utils/payment-popup-constants';

export const ELEMENTS = {
  cardReaderForm: {
    id: 'card-reader-form',
  },
};

class NebPopupPayfacReader extends NebPopup {
  initState() {
    super.initState();
    this.showHeader = false;
    this.model = {
      title: '',
      message: '',
      subheader: '',
      amount: '',
      confirmLabel: '',
      merchantAccounts: [],
      holderId: '',
      holderType: '',
      postalCode: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      firstName: '',
      lastName: '',
      geniusSaleType: '',
    };
  }

  modelChanged() {
    this.title = this.model.title;
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      save: result => this.onClose(result),
      cancel: () => this.onClose(false),
      onRefundDeclined: result => this.onClose({ ...result, declined: true }),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: -20px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-content">
        <neb-payfac-reader
          id="${ELEMENTS.cardReaderForm.id}"
          .title="${this.model.title}"
          amount="${this.model.amount}"
          .merchantAccounts="${this.model.merchantAccounts}"
          holderId="${this.model.holderId}"
          holderType="${this.model.holderType}"
          .pendingModel="${this.model.pendingModel}"
          geniusSaleType="${this.model.geniusSaleType}"
          message="${this.model.message}"
          subheader="${this.model.subheader}"
          postalCode="${this.model.postalCode}"
          address1="${this.model.address1}"
          address2="${this.model.address2}"
          city="${this.model.city}"
          state="${this.model.state}"
          firstName="${this.model.firstName}"
          lastName="${this.model.lastName}"
          confirmLabel="${this.model.confirmLabel}"
          .onSave="${this.__handlers.save}"
          .onCancel="${this.__handlers.cancel}"
          .onRefundDeclined="${this.__handlers.onRefundDeclined}"
        ></neb-payfac-reader>
      </div>
    `;
  }
}

customElements.define('neb-popup-payfac-reader', NebPopupPayfacReader);
