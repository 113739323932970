import '../inputs/neb-text-helper';
import '../inputs/neb-select';
import '../neb-radio-button';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FIELD_MARGIN,
} from '../../../../neb-styles/neb-variables';
import * as selectors from '../../../../neb-utils/selectors';
import { PERIOD, objToHours, range } from '../../../../neb-utils/utils';

export const RANGES = {
  duration: {
    hours: range(10).map(item => `${item}`),
    minutes: range(12).map(item => `${item * 5}`),
  },
  fullDuration: {
    hours: range(24).map(item => `${item}`),
    minutes: range(12).map(item => `${item * 5}`),
  },
  time: {
    hours: range(12, 1).map(item => `${item}`),
    minutes: range(12).map(item => `${item * 5}`.padStart(2, '0')),
  },
};

export function isRequired(error = 'Required') {
  return {
    error,
    validate: v => objToHours(v),
  };
}

export const ELEMENTS = {
  label: { id: 'label' },
  colonText: { id: 'text-colon' },
  helperText: { id: 'text-helper' },
  hoursSelect: { id: 'select-hours' },
  minutesSelect: { id: 'select-minutes' },
  amRadioButton: { id: 'radio-button-am' },
  pmRadioButton: { id: 'radio-button-pm' },
};

export default class Duration extends LitElement {
  static get properties() {
    return {
      __timeOfDay: {
        reflect: true,
        type: Boolean,
        attribute: 'timeofday',
      },

      name: String,
      label: String,
      error: String,
      helper: String,
      model: Object,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      fullDuration: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: 300px;
        }

        :host([timeofday]) {
          width: auto;
        }

        .container {
          display: flex;
        }

        .container-vertical {
          flex-flow: column nowrap;
        }

        .container-sub {
          align-items: center;
        }

        .container-radio {
          height: 40px;
        }

        :host(:not([timeofday])) .container-radio {
          margin-top: ${CSS_FIELD_MARGIN};
        }

        .label {
          margin-right: ${CSS_SPACING};
        }

        .select {
          width: auto;
        }

        :host(:not([timeofday])) .select:first-child {
          margin-right: ${CSS_SPACING};
        }

        :host([timeofday]) .select {
          width: 72px;
        }

        .spacing {
          margin-left: 8px;
        }

        .helper {
          display: block;
        }
      `,
    ];
  }

  static createModel(hours, minutes = 0, period = null) {
    return {
      hours,
      minutes,
      period,
    };
  }

  static createSelectors(validators = []) {
    return {
      validators,
      children: {
        period: { ignorePristine: true },
        hours: selectors.numeric(),
        minutes: selectors.numeric(2),
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__timeOfDay = false;

    this.fullDuration = false;
    this.disabled = false;
    this.name = '';
    this.label = '';
    this.error = '';
    this.helper = '';
    this.model = {
      hours: '0',
      minutes: '0',
      period: null,
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e =>
        this.onChange({
          ...e,
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  getComponent(name) {
    let type;

    if (this.model.period) {
      type = 'time';
    } else {
      type = this.fullDuration ? 'fullDuration' : 'duration';
    }

    const range = RANGES[type];
    return range[name];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { period } = this.model;

      this.__timeOfDay = period !== null && period !== undefined;
    }

    super.update(changedProps);
  }

  renderLabel() {
    return this.label
      ? html`
          <span id="${ELEMENTS.label.id}" class="label">${this.label}</span>
        `
      : '';
  }

  renderColonText() {
    return this.__timeOfDay
      ? html`
          <span
            id="${ELEMENTS.colonText.id}"
            class="container container-sub container-radio spacing"
            >:</span
          >
        `
      : '';
  }

  renderPeriodRadioButtons() {
    return this.__timeOfDay
      ? html`
          <div class="container container-sub container-radio spacing">
            <neb-radio-button
              id="${ELEMENTS.amRadioButton.id}"
              name="period"
              label="AM"
              .value="${PERIOD.AM}"
              .checked="${this.model.period === PERIOD.AM}"
              .onChange="${this.__handlers.change}"
              ?disabled="${this.disabled}"
            ></neb-radio-button>

            <neb-radio-button
              id="${ELEMENTS.pmRadioButton.id}"
              class="spacing"
              name="period"
              label="PM"
              .value="${PERIOD.PM}"
              .checked="${this.model.period === PERIOD.PM}"
              .onChange="${this.__handlers.change}"
              ?disabled="${this.disabled}"
            ></neb-radio-button>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container container-vertical">
        <div class="container">
          <div class="container container-sub container-radio">
            ${this.renderLabel()}
          </div>

          <div class="container">
            <neb-select
              id="${ELEMENTS.hoursSelect.id}"
              class="select"
              name="hours"
              .label="${this.__timeOfDay ? '' : 'Hours'}"
              .items="${this.getComponent('hours')}"
              .value="${this.model.hours}"
              .error="${Boolean(this.error)}"
              .onChange="${this.__handlers.change}"
              ?disabled="${this.disabled}"
              pinLabel
            ></neb-select>

            ${this.renderColonText()}

            <neb-select
              id="${ELEMENTS.minutesSelect.id}"
              class="select spacing"
              name="minutes"
              .label="${this.__timeOfDay ? '' : 'Minutes'}"
              .items="${this.getComponent('minutes')}"
              .value="${this.model.minutes}"
              .error="${Boolean(this.error)}"
              .onChange="${this.__handlers.change}"
              ?disabled="${this.disabled}"
              pinLabel
            ></neb-select>
          </div>

          ${this.renderPeriodRadioButtons()}
        </div>

        <div class="container">
          <neb-text-helper
            id="${ELEMENTS.helperText.id}"
            .text="${this.error || this.helper}"
            ?invalid="${Boolean(this.error)}"
            ?disabled="${this.disabled}"
          ></neb-text-helper>
        </div>
      </div>
    `;
  }
}
window.customElements.define('neb-duration', Duration);
