import { centsToCurrency } from '../../packages/neb-utils/formatters';

const defaultItem = ({ isDefault }) => isDefault;

const formatCopay = copays => {
  const defaultCopay = copays.find(defaultItem);

  return defaultCopay ? centsToCurrency(defaultCopay.inNetworkAmount) : null;
};

const formatCoinsurance = coinsurances => {
  const defaultCoinsurance = coinsurances.find(defaultItem);

  return defaultCoinsurance ? `${defaultCoinsurance.inNetworkPercent}%` : null;
};

const formatDeductibles = deductibles => {
  const defaultDeductible = deductibles.find(defaultItem);

  return defaultDeductible
    ? {
        deductible: centsToCurrency(defaultDeductible.inNetworkAmount),
        deductibleRemaining: centsToCurrency(
          defaultDeductible.inNetworkRemaining,
        ),
      }
    : { deductible: null, deductibleRemaining: null };
};

const formatOutOfPockets = outOfPockets => {
  const defaultOutOfPocket = outOfPockets.find(defaultItem);

  return defaultOutOfPocket
    ? {
        maxOop: centsToCurrency(defaultOutOfPocket.inNetworkAmount),
        maxOopRemaining: centsToCurrency(defaultOutOfPocket.inNetworkRemaining),
      }
    : { maxOop: null, maxOopRemaining: null };
};

export const formatPatientInsurances = patientInsurances =>
  patientInsurances.map(insurance => ({
    id: insurance.id,
    planName: insurance.planName,
    defaultLevel: insurance.defaultLevel,
    copay: formatCopay(insurance.copays),
    coinsurance: formatCoinsurance(insurance.coinsurances),
    ...formatDeductibles(insurance.deductibles),
    ...formatOutOfPockets(insurance.outOfPockets),
  }));
