export const PAYMENT_METHOD_SUCCESS_MESSAGE =
  'Payment method added successfully';

export const PAYMENT_RESULT_CONFIG = {
  PAYMENT: {
    SUCCESS: {
      title: 'Payment Transaction Successful',
      subheader: '',
      message: '',
      success: true,
      allowSaveCardOnFile: false,
      confirmLabel: '',
      cancelLabel: '',
      cardOnFileName: '',
    },
    SUCCESS_AND_SAVE: {
      title: 'Payment Transaction Successful',
      subheader: '',
      message: '',
      success: true,
      allowSaveCardOnFile: true,
      confirmLabel: 'Done',
      confirmLabelSave: 'Save',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed.  Try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Different Payment Details',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE_RETRY: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed. Try again to re-submit the request, or try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Again',
      cancelLabel: 'Try Different Payment Details',
      cardOnFileName: '',
    },
    FAILURE_CREDENTIALS: {
      title: 'Payment Transaction Unsuccessful',
      subheader: 'Failed to Initialize Transaction',
      message:
        'Please verify your credentials are correct in Settings, Merchant Account Services for your Retail Service Account.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Different Payment Details',
      cancelLabel: '',
      cardOnFileName: '',
    },
  },
  PAYMENT_METHOD: {
    SUCCESS: {
      title: PAYMENT_METHOD_SUCCESS_MESSAGE,
      subheader: '',
      message: '',
      success: true,
      allowSaveCardOnFile: false,
      confirmLabel: '',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE: {
      title: 'Unable to Save Payment Method',
      message:
        'We were unable to save this payment method. Debit cards that require a PIN for processing cannot be stored. Please check that you have entered all details correctly and try again.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Different Payment Details',
      cancelLabel: '',
      cardOnFileName: '',
    },
  },
};
