import './neb-form-listings-v2';

import { css, html } from 'lit';

import * as listingsApi from '../../../../packages/neb-api-client/src/listings-api-client';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { buildChartNotesTableModel } from '../../../../packages/neb-utils/listings';
import {
  FIGURE_KEYS,
  updateNotesWithTable,
} from '../../../../packages/neb-www-practice-charting/src/components/shared/util/notes-figure-table-builder';
import { openError, openSuccess } from '../../../store';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

export const COPY_SUCCESS_MESSAGE = 'Successfully copied listings';
export const COPY_ERROR_MESSAGE = 'Unable to copy listings';
export const CONFIRM_LABEL = 'Save';
export const CANCEL_LABEL = 'Save and Close';
export const REMOVE_LABEL = 'Cancel';

const LISTINGS_ITEM_NAME = 'Listings';

class NebUnsignedEncounterListingsDetail extends NebUnsignedEncounterDetail {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: auto;
          height: auto;
        }
      `,
    ];
  }

  static get properties() {
    return {
      __confirmLabel: String,
      __cancelLabel: String,
      __removeLabel: String,
    };
  }

  static get config() {
    return {
      itemName: LISTINGS_ITEM_NAME,
      refetchKey: PANEL_KEYS.LISTINGS,
    };
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  getTitle() {
    return LISTINGS_ITEM_NAME;
  }

  initState() {
    super.initState();

    this.confirmLabel = CONFIRM_LABEL;
    this.cancelLabel = CANCEL_LABEL;
    this.removeLabel = REMOVE_LABEL;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      copy: async () => {
        try {
          const info = buildChartNotesTableModel(this.model);

          await updateNotesWithTable(
            this.encounter.id,
            info,
            FIGURE_KEYS.CURRENT_LISTINGS,
            this.encounter.providerId,
          );

          this.onRefetch(PANEL_KEYS.CHART_NOTES);

          store.dispatch(openSuccess(COPY_SUCCESS_MESSAGE));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(COPY_ERROR_MESSAGE));
        }
      },
    };
  }

  isDirty() {
    return this.__elements.view.isDirty();
  }

  async save(model) {
    this.model = await listingsApi.update(this.encounter.patientId, model);
  }

  renderContent() {
    return html`
      <neb-form-listings-v2
        id="${ELEMENTS.view.id}"
        .model="${this.model}"
        .onSave="${this.handlers.save}"
        .onCopy="${this.handlers.copy}"
        .onCancel="${this.handlers.cancel}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .confirmLabel="${this.confirmLabel}"
        .cancelLabel="${this.cancelLabel}"
        .removeLabel="${this.removeLabel}"
        enable-save-and-close
        disable-save-indicator
      ></neb-form-listings-v2>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-listings-detail',
  NebUnsignedEncounterListingsDetail,
);
