import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { ALLOWED_IDENTIFIERS } from '../../../../../neb-utils/claims';

import getPreviousIdentifierOptions from './get-previous-identifier-options';
import getProviderIdentifierOptions from './get-provider-identifier-options';

export default async ({
  claimCellData,
  state,
  model,
  handlers,
  formService,
}) => {
  const [key, index, name] = claimCellData.name.split('.');

  const {
    providerOtherId: currentProviderOtherId,
    providerOtherIdQualifier: currentProviderOtherIdQualifier,
  } = state.claimCharges[index];

  const {
    providerOtherId: originalOtherId,
    providerOtherIdQualifier: originalOtherIdQualifier,
  } = model.claimCharges[index];

  const previousOptions = getPreviousIdentifierOptions({
    name,
    currentProviderOtherId,
    currentProviderOtherIdQualifier,
    originalOtherId,
    originalOtherIdQualifier,
    key: 'provider',
  });

  const options = await getProviderIdentifierOptions({
    providerId: state.claimCharges[index].providerId,
    name,
    key: 'provider',
    allowedIdentifiers: ALLOWED_IDENTIFIERS.RENDERING_PROVIDER,
    previousOptions,
    billingProviderOtherIdQualifier: state.billingProviderOtherIdQualifier,
    billingProviderOtherId: state.billingProviderOtherId,
  });

  if (options.length < 2) {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: claimCellData.title,
      message: html`
        <div>
          There are no additional provider identifiers configured for this
          rendering provider.
        </div>
        <br />
        <div>
          You can configure these on the "Provider Identifiers" tab of this
          provider's user profile.
        </div>
      `,
    });
  }

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SINGLE_SELECT, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    options,
    items: [
      {
        name,
        value: {
          providerOtherId: state.claimCharges[index].providerOtherId || '',
          providerOtherIdQualifier:
            state.claimCharges[index].providerOtherIdQualifier || '',
        },
      },
    ],
  });

  if (result) {
    Object.entries(result[name]).forEach(([name, value]) => {
      const formServiceKey = `${key}.${index}.${name}`;

      formService.apply(`${formServiceKey}Edited`, true);

      handlers.change({
        name: formServiceKey,
        value,
      });
    });
  }

  return undefined;
};
