import '../../neb-lit-components/src/components/inputs/neb-select-search';
import { html, css } from 'lit';
import '../../neb-lit-components/src/components/patients/neb-patient-card';

import {
  MATCH_CLAIM,
  MATCH_CLAIM_POPUP_MESSAGES,
} from '../../../src/utils/user-message';
import { EMPTY_SELECT_VALUE } from '../../neb-lit-components/src/components/forms/utils';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { createServerPatientsCollection } from '../../neb-utils/collections/server-patients';
import { DEFAULT_NAME_OPTS, objToName } from '../../neb-utils/formatters';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  patientSearch: { id: 'patient-search' },
  buttonMatch: { id: 'button-match' },
  buttonCancel: { id: 'button-cancel' },
  patientCards: { selector: '[id^=item-]' },
};

class NebPopupMatchClaim extends NebPopup {
  static get properties() {
    return {
      __patients: String,
      __patient: Object,
    };
  }

  initState() {
    super.initState();
    this.title = MATCH_CLAIM;

    this.__patient = EMPTY_SELECT_VALUE;
    this.__patients = [];

    this.__initServices();
  }

  __initServices() {
    this.__patientsService = createServerPatientsCollection({
      onChange: ({ pageItems }) => {
        this.__patients = pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      initialFetch: true,
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      match: () => {
        if (!this.__patient.data.id) return;
        this.onClose(this.__patient.data);
      },
      selectPatient: ({ value }) => {
        this.__patient = value || EMPTY_SELECT_VALUE;
      },
      searchPatients: e => this.__patientsService.search(e.value),
      requestPatients: () => this.__patientsService.fetchMore(),
      renderPatientItem: (model, index) => html`
        <neb-patient-card
          id="item-${index}"
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .search-patient {
          width: 100%;
          margin: 20px 0px;
        }

        .match-buttons {
          margin-top: 20px;
        }
      `,
    ];
  }

  renderPatientSearch() {
    return html`
      <neb-select-search
        id="${ELEMENTS.patientSearch.id}"
        class="span search-patient"
        name="patientId"
        itemHeight="80"
        .items="${this.__patients}"
        .value="${this.__patient}"
        .onChange="${this.handlers.selectPatient}"
        .onSearch="${this.handlers.searchPatients}"
        .onRequest="${this.handlers.requestPatients}"
        .onRenderItem="${this.handlers.renderPatientItem}"
        showSearch
      >
      </neb-select-search>
    `;
  }

  renderContent() {
    return html`
      ${MATCH_CLAIM_POPUP_MESSAGES[0]}

      <div>${this.renderPatientSearch()}</div>

      ${MATCH_CLAIM_POPUP_MESSAGES[1]}

      <div class="container-buttons match-buttons">
        <neb-button
          id="${ELEMENTS.buttonMatch.id}"
          class="spacing-button"
          label="Ok"
          .onClick="${this.handlers.match}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}
customElements.define('neb-popup-match-claim', NebPopupMatchClaim);
