import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_DISABLED,
} from '../../../styles';

export const ELEMENTS = {
  container: { id: 'container' },
  label: { id: 'label' },
};

class Checkbox extends LitElement {
  static get properties() {
    return {
      __active: {
        reflect: true,
        type: Boolean,
        attribute: 'active',
      },

      name: {
        type: String,
      },
      label: {
        type: String,
      },
      checked: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline;
        }

        :host(:not([disabled])) {
          cursor: pointer;
        }

        .container {
          display: flex;
          align-items: center;
        }

        :host([disabled]) .container {
          -webkit-tap-highlight-color: transparent;
        }

        .checkbox {
          display: inline-block;
          position: relative;
          width: 18px;
          height: 18px;
          border: 2px solid ${CSS_COLOR_GREY_1};
          border-radius: 2px;
        }

        :host([checked]) .checkbox {
          border: 2px solid ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .checkbox {
          border: 2px solid ${CSS_COLOR_DISABLED};
        }

        :host([disabled][checked]) .checkbox {
          background-color: ${CSS_COLOR_DISABLED};
        }

        .checkmark {
          position: absolute;
          left: 4px;
          top: -1px;
          width: 6px;
          height: 13px;
          border: solid ${CSS_COLOR_WHITE};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        :host(:not([checked])) .checkmark {
          display: none;
        }

        .ripple {
          position: relative;
          width: 0;
          height: 0;
          opacity: 0;
          border-radius: 20px;
          z-index: -1;
          transform: translateX(-13px) translateY(-13px);
          transition: opacity 100ms;
        }

        :host([active]:not([disabled])) .ripple {
          border: 20px solid ${CSS_COLOR_GREY_1};
        }

        :host([active]) .ripple {
          opacity: 0.2;
          z-index: 0;
        }

        .label {
          user-select: none;
          padding-left: 8px;
          font-size: ${CSS_FONT_SIZE_BODY};
          pointer-events: none;
        }

        :host(:not([disabled])) .label {
          cursor: pointer;
        }

        :host([disabled]) .label {
          color: ${CSS_COLOR_DISABLED};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__active = false;

    this.checked = false;
    this.disabled = false;
    this.label = '';
    this.name = '';

    this.onChange = () => {};

    this.onFocus = () => {};

    this.onBlur = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      activate: () => {
        if (!this.disabled) {
          this.__active = true;

          this.onFocus({
            name: this.name,
            value: this.checked,
          });
        }
      },
      deactivate: () => {
        if (!this.disabled) {
          this.__active = false;

          this.onBlur({
            name: this.name,
            value: this.checked,
          });
        }
      },
      clicked: () => {
        if (!this.disabled) {
          this.onChange({
            name: this.name,
            value: !this.checked,
          });
        }
      },
      keypress: e => {
        if (!this.disabled && (e.key === 'Enter' || e.key === ' ')) {
          this.onChange({
            name: this.name,
            value: !this.checked,
          });
        }
      },
    };
  }

  __renderLabel() {
    return this.label
      ? html`
          <span id="${ELEMENTS.label.id}" class="label"> ${this.label} </span>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        tabindex="0"
        class="container"
        tabindex="${this.disabled ? -1 : 0}"
        @click="${this.__handlers.clicked}"
        @keypress="${this.__handlers.keypress}"
        @focus="${this.__handlers.activate}"
        @blur="${this.__handlers.deactivate}"
        @mouseover="${this.__handlers.activate}"
        @mouseleave="${this.__handlers.deactivate}"
      >
        <div class="checkbox">
          <div class="ripple"></div>

          <div class="checkmark"></div>
        </div>

        ${this.__renderLabel()}
      </div>
    `;
  }
}

window.customElements.define('neb-checkbox', Checkbox);
