import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  UNSUPPORTED_PAGE_DESKTOP_MESSAGE,
  UNSUPPORTED_PAGE_TABLET_MESSAGE,
} from '../../../../src/utils/user-message';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_ICON_SMALL_WIDTH,
  CSS_ICON_SMALL_HEIGHT,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  userMessage: {
    id: 'user-message',
  },
  icon: {
    id: 'icon',
  },
};

export const DEVICE_MESSAGES = [
  UNSUPPORTED_PAGE_DESKTOP_MESSAGE,
  UNSUPPORTED_PAGE_TABLET_MESSAGE,
];

class NebUnsupportedPage extends LitElement {
  static get properties() {
    return {
      icon: String,
      location: String,
      specificMessage: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container {
          margin-top: 50px;
        }

        .text {
          margin-bottom: 0;
        }

        .list {
          margin-top: 0;
        }

        .container-message {
          margin-left: 10px;
          text-align: left;
          width: fit-content;
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .centralized {
          text-align: center;
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          width: ${CSS_ICON_SMALL_WIDTH};
          height: ${CSS_ICON_SMALL_HEIGHT};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.icon = '';
    this.location = '';
  }

  __renderIcon() {
    if (!this.icon) {
      return '';
    }

    return html`
      <neb-icon
        id="${ELEMENTS.icon.id}"
        class="icon"
        icon="neb:${this.icon}"
      ></neb-icon>
    `;
  }

  __renderUserMessage() {
    if (!this.location) {
      return '';
    }

    return html`
      <div
        class="container-message${this.specificMessage ? ' centralized' : ''}"
      >
        <div id="${ELEMENTS.userMessage.id}">
          <p class="text">
            ${this.location} is not supported at this screen resolution
          </p>
          ${this.specificMessage
            ? html` <p class="text">${this.specificMessage}</p> `
            : html`
                <p class="text">You can access ${this.location} by:</p>
                <ul class="list">
                  ${DEVICE_MESSAGES.map(message => html` <li>${message}</li> `)}
                </ul>
              `}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderIcon()} ${this.__renderUserMessage()}
      </div>
    `;
  }
}

customElements.define('neb-unsupported-page', NebUnsupportedPage);
