import { css, html } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../misc/neb-icon';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import { ECLAIM_FILE_DOWNLOAD_STATUS } from '../../../../utils/eclaim-file-download';
import '../../../controls/inputs/neb-checkbox';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonDownloads: { selector: '[id^=download-button-]' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  checkbox: { selector: '.cell-checkbox' },
};

const BULK_ACTION_MENU = {
  selectAll: { id: 'selectAll', label: 'Select All' },
  deselectAll: { id: 'deselectAll', label: 'Deselect All' },
  deleteSelected: { id: 'deleteSelected', label: 'Delete Selected' },
};

export default class TableEclaimFileDownloads extends Table {
  static get config() {
    return [
      {
        key: 'checked',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'createdAt',
        label: 'File Date',
        flex: css`1 0 0`,
        formatter: v => parseDate(v).format('MM/DD/YYYY hh:mm:ss A'),
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`2 0 0`,
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          margin-left: 10px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .cell-checkbox {
          padding: 0 5px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.config = this.constructor.config;

    this.onClickDownloadIcon = () => {};

    this.onSelectAll = () => {};

    this.onDeselectAll = () => {};

    this.onDeleteSelected = () => {};

    this.onOpenNoFilesPopup = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),
      download: e => this.onClickDownloadIcon(e),
      getBulkActions: () => {
        const checkedRowItems = this.model.filter(row => row.checked);

        const menuItems = [
          {
            id: BULK_ACTION_MENU.selectAll.id,
            label: BULK_ACTION_MENU.selectAll.label,
            onSelect: this.onSelectAll,
          },
          {
            id: BULK_ACTION_MENU.deselectAll.id,
            label: BULK_ACTION_MENU.deselectAll.label,
            onSelect: this.onDeselectAll,
          },
          {
            id: BULK_ACTION_MENU.deleteSelected.id,
            label: BULK_ACTION_MENU.deleteSelected.label,
            onSelect: checkedRowItems.length
              ? this.onDeleteSelected
              : this.onOpenNoFilesPopup,
          },
        ];

        return menuItems;
      },
    };
  }

  __renderButtonIcon(value, rowIndex) {
    return value === ECLAIM_FILE_DOWNLOAD_STATUS.READY_TO_DOWNLOAD.status ||
      value === ECLAIM_FILE_DOWNLOAD_STATUS.DOWNLOADED.status
      ? html`
          <neb-button-icon
            id="download-button-${rowIndex}"
            icon="neb:download"
            class="icon"
            .onClick="${() => this.handlers.download(this.model[rowIndex])}"
          ></neb-button-icon>
        `
      : '';
  }

  __renderCheckboxCell({ value, rowIndex, name }) {
    const { status } = this.model[rowIndex];
    return html`
      <neb-checkbox
        id="cell-checkbox-${rowIndex}"
        class="cell-checkbox"
        .name="${name}"
        .onChange="${this.handlers.change}"
        ?checked="${value}"
        ?disabled="${status !== ECLAIM_FILE_DOWNLOAD_STATUS.DOWNLOADED.status}"
      ></neb-checkbox>
    `;
  }

  renderHeaderCell(headerCell) {
    return headerCell.key === 'checked'
      ? html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .onClick="${this.handlers.getBulkActions}"
          ></neb-button-actions>
        `
      : super.renderHeaderCell(headerCell);
  }

  renderDataCell(value, columnConfig, rowIndex, name, _error) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ value, rowIndex, name });

      case 'status': {
        return html`
          <neb-text>${value}</neb-text>
          ${this.__renderButtonIcon(value, rowIndex)}
        `;
      }

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

window.customElements.define(
  'neb-table-eclaim-file-downloads',
  TableEclaimFileDownloads,
);
