import '../../../../src/components/controls/inputs/neb-checkbox';
import './inputs/neb-textfield';
import './controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  title: {
    id: 'title',
  },
  description: {
    id: 'description',
  },
  searchField: {
    id: 'textfield-search',
  },
  inactiveCheckbox: {
    id: 'checkbox-inactive',
  },
  headerSideButton: {
    id: 'header-side-button',
  },
};

class PageCollectionHeader extends LitElement {
  static get properties() {
    return {
      hideInactive: Boolean || null,
      title: String,
      description: String,
      inactiveLabel: String,
      searchLabel: String,
      searchText: String,
      headerSideButtonConfig: Object,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.hideInactive = true;
    this.title = '';
    this.description = '';
    this.inactiveLabel = '';
    this.searchLabel = '';
    this.searchText = '';
    this.headerSideButtonConfig = null;

    this.onHideInactive = () => {};

    this.onSearch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      hideInactive: e => this.onHideInactive(e.value),
      search: e => this.onSearch(e.value),
      clearSearch: () => this.onSearch(''),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
        }

        .label {
          margin: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .label-title {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .textfield-search {
          width: 432px;
        }

        .w-fit {
          width: fit-content;
        }

        :host([layout='small']) .textfield-search {
          width: 100%;
        }

        :host([layout='small']) .header-side-button {
          display: none;
        }

        .description-bar {
          display: flex;
          justify-content: space-between;
        }
      `,
    ];
  }

  __renderTitle() {
    return this.title
      ? html`
          <p id="${ELEMENTS.title.id}" class="label label-title">
            ${this.title}
          </p>
        `
      : '';
  }

  __renderDescription() {
    return this.description
      ? html`
          <p id="${ELEMENTS.description.id}" class="label">
            ${this.description}
          </p>
        `
      : '';
  }

  __renderHeaderSideButton() {
    if (!this.headerSideButtonConfig) return '';

    const { label, leadingIcon, onClick } = this.headerSideButtonConfig;

    return html`
      <neb-button-action
        id="${ELEMENTS.headerSideButton.id}"
        class="header-side-button"
        .label="${label}"
        .leadingIcon="${leadingIcon}"
        .name="${ELEMENTS.headerSideButton.id}"
        .onClick="${onClick}"
      ></neb-button-action>
    `;
  }

  __renderHideInactiveCheckbox() {
    return this.hideInactive !== null
      ? html`
          <neb-checkbox
            id="${ELEMENTS.inactiveCheckbox.id}"
            class="w-fit"
            label="${this.inactiveLabel
              ? this.inactiveLabel
              : 'Hide inactive items'}"
            .onChange="${this.__handlers.hideInactive}"
            ?checked="${this.hideInactive}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderSearchBar() {
    return this.searchLabel
      ? html`
          <neb-textfield
            id="${ELEMENTS.searchField.id}"
            class="textfield textfield-search"
            leadingIcon="neb:search"
            .trailingIcon="${this.searchText ? 'neb:clear' : ''}"
            .placeholder="${this.searchLabel}"
            .value="${this.searchText}"
            .onChange="${this.__handlers.search}"
            .onClickIcon="${this.__handlers.clearSearch}"
          ></neb-textfield>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div>
          ${this.__renderTitle()}
          <span class="label description-bar">
            ${this.__renderDescription()} ${this.__renderHeaderSideButton()}
          </span>
        </div>

        ${this.__renderHideInactiveCheckbox()} ${this.__renderSearchBar()}
      </div>
    `;
  }
}
window.customElements.define(
  'neb-page-collection-header',
  PageCollectionHeader,
);
