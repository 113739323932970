export const genDefaultBodyDiagramLabels = () => [
  { id: null, value: 'anterior head' },
  { id: null, value: 'right TMJ' },
  { id: null, value: 'left TMJ' },
  { id: null, value: 'right upper shoulder' },
  { id: null, value: 'anterior neck' },
  { id: null, value: 'left upper shoulder' },
  { id: null, value: 'right anterior shoulder' },
  { id: null, value: 'left anterior shoulder' },
  { id: null, value: 'right anterior arm' },
  { id: null, value: 'left anterior arm' },
  { id: null, value: 'right anterior elbow' },
  { id: null, value: 'left anterior elbow' },
  { id: null, value: 'right anterior forearm' },
  { id: null, value: 'left anterior forearm' },
  { id: null, value: 'right anterior wrist' },
  { id: null, value: 'left anterior wrist' },
  { id: null, value: 'right anterior hand' },
  { id: null, value: 'left anterior hand' },
  { id: null, value: 'right chest' },
  { id: null, value: 'chest' },
  { id: null, value: 'left chest' },
  { id: null, value: 'upper abdomen' },
  { id: null, value: 'right abdomen' },
  { id: null, value: 'abdomen' },
  { id: null, value: 'left abdomen' },
  { id: null, value: 'right anterior hip' },
  { id: null, value: 'pubic' },
  { id: null, value: 'left anterior hip' },
  { id: null, value: 'right anterior leg' },
  { id: null, value: 'left anterior leg' },
  { id: null, value: 'right anterior knee' },
  { id: null, value: 'left anterior knee' },
  { id: null, value: 'right shin' },
  { id: null, value: 'left shin' },
  { id: null, value: 'right ankle' },
  { id: null, value: 'left ankle' },
  { id: null, value: 'right foot' },
  { id: null, value: 'left foot' },
  { id: null, value: 'posterior head' },
  { id: null, value: 'left side of neck' },
  { id: null, value: 'posterior neck' },
  { id: null, value: 'right side of neck' },
  { id: null, value: 'left cervical dorsal' },
  { id: null, value: 'upper thoracic' },
  { id: null, value: 'right cervical dorsal' },
  { id: null, value: 'left posterior shoulder' },
  { id: null, value: 'right posterior shoulder' },
  { id: null, value: 'left posterior arm' },
  { id: null, value: 'right posterior arm' },
  { id: null, value: 'left posterior elbow' },
  { id: null, value: 'right posterior elbow' },
  { id: null, value: 'left posterior forearm' },
  { id: null, value: 'right posterior forearm' },
  { id: null, value: 'left posterior wrist' },
  { id: null, value: 'right posterior wrist' },
  { id: null, value: 'left posterior hand' },
  { id: null, value: 'right posterior hand' },
  { id: null, value: 'left mid thoracic' },
  { id: null, value: 'mid thoracic' },
  { id: null, value: 'right mid thoracic' },
  { id: null, value: 'left lower thoracic' },
  { id: null, value: 'lower thoracic' },
  { id: null, value: 'right lower thoracic' },
  { id: null, value: 'left lumbar' },
  { id: null, value: 'lumbar' },
  { id: null, value: 'right lumbar' },
  { id: null, value: 'left sacroiliac' },
  { id: null, value: 'right sacroiliac' },
  { id: null, value: 'left posterior hip' },
  { id: null, value: 'sacral' },
  { id: null, value: 'right posterior hip' },
  { id: null, value: 'left buttock' },
  { id: null, value: 'right buttock' },
  { id: null, value: 'left posterior leg' },
  { id: null, value: 'right posterior leg' },
  { id: null, value: 'left posterior knee' },
  { id: null, value: 'right posterior knee' },
  { id: null, value: 'left calf' },
  { id: null, value: 'right calf' },
  { id: null, value: 'left ankle' },
  { id: null, value: 'right ankle' },
  { id: null, value: 'left foot' },
  { id: null, value: 'right foot' },
];
