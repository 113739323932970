import { html, css } from 'lit';

import { postTransferBalance } from '../../../packages/neb-api-client/src/billing/transfer-balance';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  fetchAdjustmentsWithDescription,
  fetchPaymentCodesWithDescription,
} from '../../../packages/neb-utils/neb-ledger-util';
import {
  TRANSFER_BALANCE_ERROR_MESSAGE,
  TRANSFER_BALANCE_SUCCESS_MESSAGE,
} from '../../utils/user-message';
import FormTransferBalance from '../forms/neb-form-transfer-balance';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

const TEXT_TITLE = 'Transfer Balance';

class NebPopupTransferBalance extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __formModel: Object,
      __adjustmentCodes: Array,
      __paymentCodes: Array,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;

    this.__formModel = FormTransferBalance.createModel();

    this.__adjustmentCodes = [];
    this.__paymentCodes = [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          align-items: center;
          justify-items: left;
          grid-column-gap: 20px;
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          width: 100%;
          height: 100%;
          min-height: unset;
          margin: 0;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: () => this.onClose(false),
      postTransfer: async formModel => {
        try {
          const lineItemIds =
            this.model.transferBalanceItems.selectedLineItemIds || [];

          const result = await postTransferBalance({
            lineItemIds,
            codeId: formModel.reasonCode,
            from: formModel.transferFrom,
            to: formModel.transferTo,
          });

          this.onClose(result);
          store.dispatch(openSuccess(TRANSFER_BALANCE_SUCCESS_MESSAGE));
        } catch (e) {
          console.error(e);

          this.onClose(false);
          store.dispatch(openError(TRANSFER_BALANCE_ERROR_MESSAGE));
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__adjustmentCodes = await fetchAdjustmentsWithDescription(true);
    this.__paymentCodes = await fetchPaymentCodesWithDescription(true);
  }

  modelChanged() {
    const { transferBalanceItems } = this.model;
    this.__formModel = {
      ...this.__formModel,
      hasPrimaryPayer: transferBalanceItems.hasPrimaryPayer || false,
      hasSecondaryPayer: transferBalanceItems.hasSecondaryPayer || false,
      balance: transferBalanceItems.balance || null,
    };
  }

  renderContent() {
    return html`
      <div class="popup-body">
        <neb-form-transfer-balance
          id="${ELEMENTS.form.id}"
          .adjustmentCodes="${this.__adjustmentCodes}"
          .paymentCodes="${this.__paymentCodes}"
          .model="${this.__formModel}"
          .onSave="${this.handlers.postTransfer}"
          .onCancel="${this.handlers.cancel}"
        ></neb-form-transfer-balance>
      </div>
    `;
  }
}

customElements.define('neb-popup-transfer-balance', NebPopupTransferBalance);
