import moment from 'moment-timezone';

const reformatDate = date => (date ? moment(date).format('MM/DD/YYYY') : '');

const wrapTable = (table, token) =>
  `<div contenteditable="false" data-field="${token}" class="table-wrapper">${table}</div>`;

const buildCurrentListingsTable = dataset => {
  const header = '<table><tbody><tr><th>Spine</th><th>Technique</th></tr>';

  const body = dataset.map(
    item => `<tr><td>${item.name}</td><td>${item.issue}</td></tr>`,
  );

  const footer = '</tbody></table>';

  return [header, ...body, footer].join('');
};

const buildCurrentDiagnosesTable = dataset => {
  const header = '<table><tbody>';

  const body = dataset.map(
    item => `<tr><td>${item.code}</td><td>${item.shortDescription}</td></tr>`,
  );

  const footer = '</tbody></table>';

  return [header, ...body, footer].join('');
};

const buildCurrentTreatmentPlanTable = dataset => {
  const header = `<table><tbody><tr><td>Start Date:</td><td>${reformatDate(
    dataset.startDate,
  )}</td><td></td><td></td></tr>`;

  const body = dataset.treatmentPhases.map((t, index) => {
    const phaseNum = index + 1;
    const infoStr = [
      `${t.occurrence}`,
      `${t.frequency}${t.frequencyType}`,
      `${t.duration}${t.durationType}`,
    ].join(' x ');

    return `<tr><td>Phase ${phaseNum}:</td><td>${infoStr}</td><td>Completed Date:</td><td>${reformatDate(
      t.completedDate,
    )}</td></tr>`;
  });

  const footer = `<tr><td>End Date:</td><td>${reformatDate(
    dataset.completedDate,
  )}</td><td></td><td></td></tr></tbody></table>`;

  return [header, ...body, footer].join('');
};

export const TOKEN_KEY = {
  CURRENT_LISTINGS: 'currentListings',
  CURRENT_DX: 'currentDx',
  CURRENT_TX: 'currentTx',
};
const TABLE_BUILDERS_TOKEN = {
  [TOKEN_KEY.CURRENT_LISTINGS]: buildCurrentListingsTable,
  [TOKEN_KEY.CURRENT_DX]: buildCurrentDiagnosesTable,
  [TOKEN_KEY.CURRENT_TX]: buildCurrentTreatmentPlanTable,
};

export const buildTokenTable = (data, token, append) => {
  const tableBuilder = TABLE_BUILDERS_TOKEN[token];
  const table = tableBuilder(data);
  return append ? wrapTable(table, token) : table;
};
