import { ConnectedService } from '../../packages/neb-redux/services/connected-service';

export class StoreController extends ConnectedService {
  constructor(host, { stateKeys = {}, onStateChanged = () => {} }) {
    super(stateKeys);

    host.addController(this);
    this.host = host;

    this.onStateChanged = onStateChanged;
  }

  hostConnected() {
    this.connect();
  }

  hostDisconnected() {
    this.disconnect();
  }

  _stateChanged(...args) {
    return this.onStateChanged(...args);
  }
}
