export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const NEW_APPOINTMENT = 'NEW_APPOINTMENT';
export const UPDATE_TENANT_SHORT_NAME = 'UPDATE_TENANT_SHORT_NAME';
export const UPDATE_BOOKING_PAGE = 'UPDATE_BOOKING_PAGE';
export const RESET_BOOKING = 'RESET_BOOKING';
export const updateTenantShortName = tenantShortName => ({
  type: UPDATE_TENANT_SHORT_NAME,
  booking: {
    tenantShortName,
  },
});
export const updateBookingPage = page => ({
  type: UPDATE_BOOKING_PAGE,
  booking: {
    page,
  },
});
export const newAppointment = appointment => ({
  type: NEW_APPOINTMENT,
  booking: {
    appointment,
  },
});
export const resetBooking = () => ({
  type: RESET_BOOKING,
});
export const requestCreateAccount = () => ({
  type: CREATE_ACCOUNT,
});
export const requestCreateAccountSuccess = response => ({
  type: CREATE_ACCOUNT,
  status: 'success',
  response,
});
export const requestCreateAccountFailure = error => ({
  type: CREATE_ACCOUNT,
  status: 'failed',
  error,
});
