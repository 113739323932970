import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_BORDER_GREY_1 } from '../../../../../neb-styles/neb-variables';
import { TOOLTIP_TEXT } from '../../../../../neb-utils/claims';

export const ELEMENTS = {
  employmentAccidentCell: { id: 'employment-accident-cell' },
  autoAccidentCell: { id: 'auto-accident-cell' },
  autoAccidentStateCell: { id: 'auto-accident-state-cell' },
  otherAccidentCell: { id: 'other-accident-cell' },
  claimCodesCell: { id: 'claim-codes-cell' },
  headerCell: { id: 'header-cell' },
};

export const RELATED_CAUSES_CODE = {
  AUTO_ACCIDENT: 'AA',
  EMPLOYMENT: 'EM',
  OTHER_ACCIDENT: 'OA',
};
class NebClaimFormCellFL10 extends LitElement {
  static get properties() {
    return {
      model: Object,
      errors: Object,
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandler();
  }

  __initState() {
    this.model = {
      patientConditionRelatedCausesCode: '',
      autoAccidentState: '',
      claimCodes: '',
    };

    this.errors = {
      claimCodes: '',
    };

    this.renderExpandIcon = false;

    this.onClick = () => {};
  }

  __initHandler() {
    this.__handlers = {
      click: e => this.onClick(e),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(5, 1fr);
          border: ${CSS_BORDER_GREY_1};
          border-top: 0;
        }

        .span-column-3 {
          grid-column: span 3;
        }

        .span-column-2 {
          grid-column: span 2;
        }

        .cell {
          display: grid;
          width: 100%;
        }
      `,
    ];
  }

  __formatCausesCode(code) {
    return this.model.patientConditionRelatedCausesCode === code ? 'Yes' : 'No';
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.headerCell.id}"
          class="cell span-column-3"
          label="10. Is Patient's Condition Related To:"
          .tooltipText="${TOOLTIP_TEXT.FL_10}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.employmentAccidentCell.id}"
          class="cell span-column-3"
          label="10a. Employment?"
          .data="${this.__formatCausesCode(RELATED_CAUSES_CODE.EMPLOYMENT)}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.autoAccidentCell.id}"
          class="cell span-column-2"
          label="10b. Auto Accident?"
          .data="${this.__formatCausesCode(RELATED_CAUSES_CODE.AUTO_ACCIDENT)}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.autoAccidentStateCell.id}"
          class="cell"
          label="Place (State)"
          .data="${this.model.autoAccidentState}"
          .problemText="${this.errors.autoAccidentState}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.otherAccidentCell.id}"
          class="cell span-column-3"
          label="10c. Other Accident?"
          .data="${this.__formatCausesCode(RELATED_CAUSES_CODE.OTHER_ACCIDENT)}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.claimCodesCell.id}"
          class="cell span-column-3"
          name="claimCodes"
          type="textInput"
          label="10d. Claim Codes (Designated by NUCC)"
          borderTop
          .data="${this.model.claimCodes}"
          .problemText="${this.errors.claimCodes}"
          .tooltipText="${TOOLTIP_TEXT.FL_10D}"
          .onClick="${this.__handlers.click}"
          ?renderExpandIcon="${this.renderExpandIcon}"
        >
        </neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-10', NebClaimFormCellFL10);
