import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { getLineItemsWithAllocationDetails } from '../../charting/encounter-charge';

const __getChargesThatNeedUnallocation = (charges, pristineCharges) =>
  charges.reduce(
    (accum, charge) => {
      const pristineCharge = pristineCharges.find(
        ({ id: pristineChargeId }) => pristineChargeId === charge.id,
      );

      if (charge.postedToLedgerId) {
        if (!charge.posted) accum.chargesToBeUnposted.push(charge);
        else if (pristineCharge && pristineCharge.units !== charge.units) {
          accum.chargesWithUnitsChanged.push(charge);
        }
      }

      return accum;
    },
    { chargesToBeUnposted: [], chargesWithUnitsChanged: [] },
  );

const UNPOST_PROMPT_UNALLOCATION = {
  message: html`
    <div>
      One or more of the charges that you are unposting has an allocated
      payment. Unposting the charges will remove all allocations and the charges
      will return to the encounter as Not Posted.
    </div>
    <br />
    <div>Are you sure you want to unpost the selected charges?</div>
  `,
  title: 'Unpost Charges',
  confirmText: 'Yes',
  cancelText: 'No',
};

const CHANGE_UNITS_PROMPT_UNALLOCATION = {
  message: html`
    <div>
      One or more of the charges that you are updating the units for has an
      allocated payment.
    </div>
    <br />
    <div>
      Updating the units will remove all payment allocations from these charges.
    </div>
    <br />
    <div>Are you sure you want to update the units for these charges?</div>
  `,
  title: 'Update Units',
  confirmText: 'Yes',
  cancelText: 'No',
};

const __evaluateIfChargesAreAllocated = async charges => {
  if (!charges.length) return false;

  const postedToLedgerIds = charges.map(
    ({ postedToLedgerId }) => postedToLedgerId,
  );

  const lineItems = await getLineItemsWithAllocationDetails(postedToLedgerIds);

  return lineItems.some(li =>
    li.lineItemDebits.some(lid => lid.debit.allocations.length),
  );
};

const __getUnallocationAuthorization = async ({
  chargesThatNeedUnallocation: { chargesToBeUnposted, chargesWithUnitsChanged },
  repostPostedEncounterCharges,
}) => {
  if (
    !(chargesToBeUnposted.length || chargesWithUnitsChanged.length) ||
    repostPostedEncounterCharges
  ) {
    return true;
  }

  const [
    chargesToBeUnpostedHasAllocations,
    chargesWithUnitsChangedHasAllocations,
  ] = await Promise.all([
    __evaluateIfChargesAreAllocated(chargesToBeUnposted),
    __evaluateIfChargesAreAllocated(chargesWithUnitsChanged),
  ]);

  if (
    !chargesToBeUnpostedHasAllocations &&
    !chargesWithUnitsChangedHasAllocations
  ) {
    return true;
  }

  return chargesToBeUnpostedHasAllocations
    ? openPopup(POPUP_RENDER_KEYS.CONFIRM, UNPOST_PROMPT_UNALLOCATION)
    : openPopup(POPUP_RENDER_KEYS.CONFIRM, CHANGE_UNITS_PROMPT_UNALLOCATION);
};

export default ({
  charges,
  pristineCharges,
  repostPostedEncounterCharges = false,
}) => {
  const chargesThatNeedUnallocation = __getChargesThatNeedUnallocation(
    charges,
    pristineCharges,
  );

  return __getUnallocationAuthorization({
    chargesThatNeedUnallocation,
    repostPostedEncounterCharges,
  });
};
