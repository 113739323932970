import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { colorFlip, fauxpaque } from '../../../../../src/styles';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_2,
  CSS_FONT_SIZE_CAPTION,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  buttonContainer: { id: 'button-container' },
  buttonMacro: { id: 'button' },
  text: { id: 'text' },
};

class NebMacroButton extends LitElement {
  static get properties() {
    return {
      index: {
        type: Number,
      },
      disableEmpty: {
        type: Boolean,
        reflect: true,
      },
      selected: {
        type: Boolean,
      },
      label: {
        type: String,
      },
      icon: {
        type: String,
      },
      color: {
        type: String,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      __hover: {
        type: Boolean,
      },
      __fauxColor: {
        type: String,
      },
      __fauxTextColor: {
        type: String,
      },
      __textColor: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.disableEmpty = false;
    this.selected = false;
    this.index = 0;
    this.label = '';
    this.icon = '';
    this.color = '';

    this.__fauxColor = '';
    this.__fauxTextColor = '';
    this.__textColor = '';

    this.__hover = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.handlers = {
      click: () => {
        if (!this.disabled) this.onClick(this.index);
      },
      enter: () => {
        this.__hover = true;
      },
      leave: () => {
        this.__hover = false;
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([disabled]) .button {
          opacity: 0.25;
          cursor: default;
        }

        .empty-button {
          cursor: pointer;
          border: 1px solid ${CSS_COLOR_GREY_2};
          height: 100%;
          border-radius: 3px;
          background: ${CSS_COLOR_WHITE};
        }

        :host([disableEmpty]) .empty-button {
          cursor: default;
        }

        .button-container {
          height: 36px;
          border-radius: 3px;
        }

        .button {
          border-radius: 3px;
          cursor: pointer;
          font-size: ${CSS_FONT_SIZE_CAPTION};
          max-height: 36px;
          height: 100%;
          min-width: 0;
          outline: none;
          display: flex;
          justify-content: center;
        }

        .text {
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          font-weight: bold;
          width: 100%;
          align-self: center;
          padding: 0 5px;
          word-break: break-word;
        }

        .icon {
          align-self: center;
          margin: 0 5px;
          width: 15px;
          height: 25px;
          flex-shrink: 0;
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('color') && this.color) {
      this.__fauxColor = fauxpaque(this.color, 0.6);
      this.__textColor = colorFlip(this.color);
      this.__fauxTextColor = colorFlip(this.__fauxColor);
    }

    super.update(changedProps);
  }

  __isColorDisplayed() {
    return !this.disabled && (this.__hover || this.selected);
  }

  __getStyles() {
    const styles = { background: '', text: '' };

    if (this.color) {
      const background = this.__isColorDisplayed()
        ? this.color
        : this.__fauxColor;

      const textColor = this.__isColorDisplayed()
        ? this.__textColor
        : this.__fauxTextColor;

      styles.background += `background-color: ${background}; border-left: 5px solid ${
        this.color
      };`;

      styles.text = `color: ${textColor};`;
    }

    return styles;
  }

  __renderIcon() {
    return this.icon
      ? html`
          <neb-icon
            id="icon-button"
            class="icon"
            icon="neb:${this.icon}"
          ></neb-icon>
        `
      : '';
  }

  __renderButton(styles) {
    return this.color
      ? html`
          <span id="${ELEMENTS.buttonMacro.id}" class="button" tabindex="0">
            ${this.__renderIcon()}

            <span id="${ELEMENTS.text.id}" class="text" style="${styles.text}">
              ${this.label}
            </span>
          </span>
        `
      : html` <div class="empty-button"></div> `;
  }

  render() {
    const styles = this.__getStyles();

    return html`
      <div
        id="${ELEMENTS.buttonContainer.id}"
        style="${styles.background}"
        class="button-container"
        .color="${this.color}"
        @click="${this.handlers.click}"
        @mouseenter="${this.handlers.enter}"
        @mouseleave="${this.handlers.leave}"
      >
        ${this.__renderButton(styles)}
      </div>
    `;
  }
}

customElements.define('neb-macro-button', NebMacroButton);
