import { formatAdjustmentAmount } from '../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import { parseDate } from '../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  formatDollarAmount,
} from '../../packages/neb-utils/formatters';
import { LINE_ITEM_TYPE } from '../../packages/neb-utils/neb-ledger-util';

import { formatBilled, formatHold } from './line-items';

export const LINE_ITEM_TYPE_LABEL = {
  [LINE_ITEM_TYPE.ENCOUNTER_CHARGE]: 'Enc. Charge',
  [LINE_ITEM_TYPE.PURCHASE]: 'Purchase',
  [LINE_ITEM_TYPE.FEE]: 'Fee',
};

export const addRunningBalance = ledgerItems => {
  let balance = 0;

  for (let index = ledgerItems.length - 1; index >= 0; index -= 1) {
    const item = ledgerItems[index];

    if (item.paymentId) {
      balance -= item.amount;
    } else {
      balance +=
        item.billedAmount +
        item.taxAmount -
        item.adjustmentAmount -
        item.packageCreditAmount;
    }

    item.balance = balance;
  }

  return ledgerItems;
};

const formatCodeAndModifiers = lineItem => {
  const { code, modifiers } = lineItem;

  if (modifiers.some(Boolean)) {
    return `${code} - ${modifiers.filter(Boolean).join(', ')}`;
  }

  return code;
};

const formatLineItem = (lineItem, patientName) => ({
  payer: lineItem.payerName || lineItem.guarantorName || patientName,
  id: lineItem.id,
  code: formatCodeAndModifiers(lineItem),
  dateOfService: parseDate(lineItem.dateOfService).format('MM/DD/YYYY'),
  billed: formatBilled(lineItem),
  hold: formatHold(lineItem),
  type: LINE_ITEM_TYPE_LABEL[lineItem.type],
  tax: centsToCurrency(lineItem.taxAmount),
  debit: centsToCurrency(lineItem.billedAmount),
  credit: lineItem.packageCreditAmount
    ? centsToCurrency(lineItem.packageCreditAmount)
    : '',
  adjustment: lineItem.adjustmentAmount
    ? formatAdjustmentAmount(lineItem.adjustmentAmount)
    : '',
  balance: formatDollarAmount(lineItem.balance),
  chargeHasNotes: lineItem.hasBillingNote,
});

const formatPayment = (payment, patientName) => ({
  payer: payment.payerName || payment.otherPatientName || patientName,
  paymentId: payment.paymentId,
  code: `Payment - ${payment.paymentMethod}`,
  dateOfService: parseDate(payment.transactionDate).format('MM/DD/YYYY'),
  type: payment.discountCode || payment.paymentCode || '',
  isDiscount: !!payment.discountCode,
  credit: centsToCurrency(payment.amount),
  balance: formatDollarAmount(payment.balance),
  isPayerPayment: !!payment.payerName,
  paymentHasNotes: payment.hasBillingNote,
});

export const formatLedgerItemsToTableModel = (ledgerItems, patientName) =>
  ledgerItems.map(item =>
    item.paymentId
      ? formatPayment(item, patientName)
      : formatLineItem(item, patientName),
  );

export const sortRunningLedger = rows =>
  // eslint-disable-next-line complexity
  rows.sort((a, b) => {
    if (a.dateOfService && b.dateOfService) {
      return b.dateOfService.localeCompare(a.dateOfService);
    }

    if (a.transactionDate && b.transactionDate) {
      return (
        b.transactionDate.localeCompare(a.transactionDate) ||
        b.createdAt.localeCompare(a.createdAt)
      );
    }

    if (a.dateOfService && b.transactionDate) {
      return (
        b.transactionDate.localeCompare(a.dateOfService) ||
        b.createdAt.localeCompare(a.dateOfService) ||
        1
      );
    }

    if (a.transactionDate && b.dateOfService) {
      return (
        b.dateOfService.localeCompare(a.transactionDate) ||
        b.dateOfService.localeCompare(a.createdAt) ||
        -1
      );
    }

    throw new Error('Item is neither a line item nor a payment');
  });

export const formatTotals = ledgerItems => {
  const totals = {
    debit: 0,
    credit: 0,
    adjustment: 0,
    tax: 0,
  };

  ledgerItems.forEach(item => {
    if (item.paymentId) {
      totals.credit += item.amount;
    } else {
      totals.debit += item.billedAmount;
      totals.credit += item.packageCreditAmount;
      totals.adjustment += item.adjustmentAmount;
      totals.tax += item.taxAmount;
    }
  });

  totals.balance =
    totals.debit + totals.tax - totals.credit - totals.adjustment;

  return {
    payer: 'Totals',
    debit: formatDollarAmount(totals.debit),
    credit: formatDollarAmount(totals.credit),
    adjustment: formatDollarAmount(totals.adjustment),
    tax: formatDollarAmount(totals.tax),
    balance: formatDollarAmount(totals.balance),
  };
};
