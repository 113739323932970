import moment from 'moment-timezone';

import { getAppointments } from '../../neb-api-client/src/appointment-api-client';
import { fetchOne } from '../../neb-api-client/src/patient-api-client';
import { getActiveProviderUsers } from '../../neb-api-client/src/practice-users-api-client';
import { computeTime } from '../../neb-input/nebFormatUtils';
import { store } from '../../neb-redux/neb-redux-store';
import { parseDate } from '../date-util';
import { HIDE_PREFERRED_OPTS, objToName } from '../formatters';

const DT_REQ_FORMAT = 'YYYY-MM-DDTHH:mm';
const DT_PDF_FORMAT = 'ddd MM/DD/YY h:mm A';

const getUpcomingAppointments = (patientId, limit, location = null) => {
  const params = {
    patientId,
    sortField: 'start',
    sortDir: 'asc',
    secondarySortField: 'start',
    secondarySortDir: 'asc',
    limit,
    expand: 'appointmentType',
    status: ['Active'],
    start: parseDate().format(DT_REQ_FORMAT),
    ...(location ? { locationId: location.id } : {}),
  };
  return getAppointments(params);
};

const getProviderName = (providerId, providers) => {
  const { name } = providers.find(({ id }) => providerId === id);
  return objToName(name, HIDE_PREFERRED_OPTS);
};

const getAppointmentsInfo = (upcomingAppts, locations, providers) =>
  upcomingAppts.map(appt => {
    const {
      end,
      start,
      locationId,
      appointmentType: { name: appointmentType },
      providerId,
    } = appt;

    const duration = computeTime(moment(end).diff(moment(start)));
    const { name: locationName } = locations.find(
      ({ id }) => id === locationId,
    );

    const formattedTime = parseDate(moment(start)).format(DT_PDF_FORMAT);
    const providerName = providerId
      ? getProviderName(providerId, providers)
      : '';

    return {
      appointmentType,
      providerName,
      duration,
      locationId,
      locationName,
      formattedTime,
    };
  });

const getPracticeInformation = ({ id, name, hideLogo }) => ({
  id,
  name,
  hideLogo,
});

const getAppointmentLocations = (apptsInfo, locations) => {
  const appointmentLocationIds = [
    ...new Set(apptsInfo.map(({ locationId }) => locationId)),
  ];
  return locations
    .filter(({ id }) => appointmentLocationIds.includes(id))
    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
    .map(
      ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }) => ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }),
    );
};

export const RECEIPT_APPOINTMENT_LIMIT = 3;

export const getAppointmentListData = async (
  patientId,
  location,
  limit = 20,
) => {
  const practiceInfo = {
    ...store.getState().practiceInformation.item,
    hideLogo: false,
  };

  if (location) {
    practiceInfo.name = location.businessName || practiceInfo.name;
    practiceInfo.hideLogo = location.hideLogo || practiceInfo.hideLogo;
    practiceInfo.selectedLocation = location;
  }

  const [patient, providers, upcomingAppointments] = await Promise.all([
    fetchOne(patientId),
    getActiveProviderUsers(),
    getUpcomingAppointments(patientId, limit, practiceInfo.selectedLocation),
  ]);

  const appointmentsInfo = getAppointmentsInfo(
    upcomingAppointments.data,
    practiceInfo.locations,
    providers,
  );

  return {
    appointmentsInfo,

    practiceInformation: getPracticeInformation(practiceInfo),
    patientName: objToName(patient.name, HIDE_PREFERRED_OPTS),

    totalAppointments: upcomingAppointments.count,
    remainingAppointments: Math.max(upcomingAppointments.count - limit, 0),

    locations: getAppointmentLocations(
      appointmentsInfo,
      practiceInfo.locations,
    ),
  };
};
