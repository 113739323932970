import '../inputs/neb-textfield';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

import NebForm from './neb-form';

export const ELEMENTS = {
  questionField: { id: 'question-field' },
};

export default class NebFormMacroNoDefault extends NebForm {
  static createModel() {
    return {
      question: {
        text: '',
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        question: {
          children: {
            text: [isRequired()],
          },
        },
      },
    };
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.questionField.id}"
        class="field-question"
        label="Question"
        helper="Required"
        name="question.text"
        maxLength="255"
        .value="${this.state.question.text}"
        .error="${this.errors.question.text}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }
}

window.customElements.define(
  'neb-form-macro-no-default',
  NebFormMacroNoDefault,
);
