export const RESET_TIMER = 'RESET_TIMER';
export const START_TIMER = 'START_TIMER';
export const TIMED_OUT = 'TIMED_OUT';
export const WARN_TIMEOUT = 'WARN_TIMEOUT';
export const CLEAR_TIMER = 'CLEAR_TIMER';

function handleDefaultState(state) {
  if (state.isRunning) {
    return { ...state, isTimedOut: false, isWarnTimeout: false };
  }
  return state;
}

export const timeoutReducer = (
  state = {
    duration: 300000,
    warnDuration: 60000,
    isTimedOut: false,
    isWarnTimeout: false,
    isRunning: false,
  },
  action,
) => {
  switch (action.type) {
    case RESET_TIMER:
      return { ...state, isTimedOut: false, isWarnTimeout: false };

    case START_TIMER:
      return {
        ...state,
        duration: action.duration,
        warnDuration: action.warnDuration,
        isTimedOut: false,
        isWarnTimeout: false,
        isRunning: true,
      };

    case WARN_TIMEOUT:
      return { ...state, isTimedOut: false, isWarnTimeout: true };

    case TIMED_OUT:
      return { ...state, isTimedOut: true, isWarnTimeout: false };

    case CLEAR_TIMER:
      return { ...state, isTimedOut: false, isWarnTimeout: false };

    default:
      return handleDefaultState(state);
  }
};
