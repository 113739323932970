import { store } from '../neb-redux/neb-redux-store';

import {
  RESET_TIMER,
  START_TIMER,
  TIMED_OUT,
  WARN_TIMEOUT,
  CLEAR_TIMER,
} from './neb-timeout-reducer';

let startTimeoutSession = null;
let startWarnTimeoutSession = null;

export const timeOut = () => ({
  type: TIMED_OUT,
});

export const warnTimeout = () => ({
  type: WARN_TIMEOUT,
});

function clearStartTimeoutSession() {
  if (startTimeoutSession) {
    window.clearTimeout(startTimeoutSession);
  }
}

function clearStartWarnTimeSession() {
  if (startWarnTimeoutSession) {
    window.clearTimeout(startWarnTimeoutSession);
  }
}

function startWarnTimeout(delay) {
  clearStartWarnTimeSession();
  startWarnTimeoutSession = window.setTimeout(() => {
    store.dispatch(timeOut());
  }, delay);
}

function startTimeout(duration, warnDuration) {
  clearStartTimeoutSession();
  const delay = duration - warnDuration;
  startTimeoutSession = window.setTimeout(() => {
    startWarnTimeout(warnDuration);
    store.dispatch(warnTimeout());
  }, delay);
}

/**
 * Start the timeout.
 * @param {Number} duration the timeout duration.
 * @param {Number} warnDuration the warning time before timed out.
 */

export const start = (duration, warnDuration) => {
  startTimeout(duration, warnDuration);

  return {
    type: START_TIMER,
    duration,
    warnDuration,
  };
};

export const reset = () => {
  const { duration } = store.getState().sessionTimeout;
  const { warnDuration } = store.getState().sessionTimeout;
  startTimeout(duration, warnDuration);

  return {
    type: RESET_TIMER,
  };
};

export const clear = () => {
  clearStartTimeoutSession();
  clearStartWarnTimeSession();

  return {
    type: CLEAR_TIMER,
  };
};
