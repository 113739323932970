import { openPopup } from '@neb/popup';

import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  getSignatureFile,
  getProviderSignatureS3Key,
} from '../../../../../neb-api-client/src/provider-signature-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import {
  formatLocationInformation,
  getIdentifierComponent,
  getBillingProviderIdentifier,
  getServiceFacilityIdentifier,
} from '../../../../../neb-utils/claims';

const formatBillingProviderOption = ({
  provider,
  billingProviderValues,
  billingInfo,
  practiceInfo,
}) => {
  const {
    id,
    NPI,
    name: { first, last, middle },
  } = provider;

  const formattedBillingInformation = formatLocationInformation({
    practiceInfo,
    billingInfo,
    billingProviderValues,
    billingProvider: provider,
  });

  const serviceFacilityOtherId = getServiceFacilityIdentifier(
    provider,
    billingProviderValues,
  );

  const billingProviderOtherId = getBillingProviderIdentifier(
    provider,
    billingProviderValues,
  );

  const result = {
    label: `${last}, ${first}`,
    value: {
      ...formattedBillingInformation,
      billingProviderFirstName: first,
      billingProviderId: id,
      billingProviderLastName: last,
      billingProviderMiddleName: middle,
      billingProviderNPI: NPI,
      billingProviderOtherId: getIdentifierComponent(
        billingProviderOtherId,
        'number',
      ),
      billingProviderOtherIdQualifier: getIdentifierComponent(
        billingProviderOtherId,
        'type',
      ),
      serviceFacilityOtherId: getIdentifierComponent(
        serviceFacilityOtherId,
        'number',
      ),
      serviceFacilityOtherIdQualifier: getIdentifierComponent(
        serviceFacilityOtherId,
        'type',
      ),
    },
  };

  return result;
};

const formatClaimProviders = ({
  claimProviderIds,
  providers,
  providerSpecificValues,
  billingInfo,
  practiceInfo,
}) =>
  providers.reduce(
    (acc, provider) =>
      claimProviderIds.includes(provider.id)
        ? [
            formatBillingProviderOption({
              provider,
              billingProviderValues: providerSpecificValues.find(
                v => v.providerId === provider.id,
              ),
              billingInfo,
              practiceInfo,
            }),
            ...acc,
          ]
        : acc,
    [],
  );

const formatAllActiveProviders = ({
  providers,
  providerSpecificValues,
  billingInfo,
  practiceInfo,
}) =>
  providers.reduce(
    (acc, provider) =>
      provider.active
        ? [
            formatBillingProviderOption({
              provider,
              billingProviderValues: providerSpecificValues.find(
                v => v.providerId === provider.id,
              ),
              billingInfo,
              practiceInfo,
            }),
            ...acc,
          ]
        : acc,
    [],
  );

const getBillingProviderOptions = ({
  state,
  providerSpecificValues,
  billingInfo,
  practiceInfo,
  allProviders,
}) => {
  const claimProviderIds = state.claimCharges.map(
    ({ providerId }) => providerId,
  );

  const {
    billingProviderAddress,
    billingProviderFirstName,
    billingProviderId,
    billingProviderLastName,
    billingProviderMiddleName,
    billingProviderNPI,
    billingProviderOtherId,
    billingProviderOtherIdQualifier,
    billingProviderPracticeName,
    federalTaxIdNumber,
    federalTaxIdSource,
    federalTaxIdType,
    serviceFacilityAddress,
    serviceFacilityAddressType,
    serviceFacilityNPI,
    serviceFacilityName,
    serviceFacilityOtherId,
    serviceFacilityOtherIdQualifier,
  } = state;

  const providers = allProviders.filter(
    ({ id, type }) => type === 'provider' && id !== billingProviderId,
  );

  const providerIds = new Set(providers.map(({ id }) => id));

  const hasNPIProvider =
    billingProviderId || claimProviderIds.some(id => providerIds.has(id));

  const previousOption = billingProviderId
    ? [
        {
          label: `${state.billingProviderLastName}, ${
            state.billingProviderFirstName
          }`,
          value: {
            billingProviderAddress,
            billingProviderFirstName,
            billingProviderId,
            billingProviderLastName,
            billingProviderMiddleName,
            billingProviderNPI,
            billingProviderOtherId,
            billingProviderOtherIdQualifier,
            billingProviderPracticeName,
            federalTaxIdNumber,
            federalTaxIdSource,
            federalTaxIdType,
            serviceFacilityAddress,
            serviceFacilityAddressType,
            serviceFacilityNPI,
            serviceFacilityName,
            serviceFacilityOtherId,
            serviceFacilityOtherIdQualifier,
          },
        },
      ]
    : [];

  const providerOptions = hasNPIProvider
    ? formatClaimProviders({
        claimProviderIds,
        providers,
        providerSpecificValues,
        billingInfo,
        practiceInfo,
      })
    : formatAllActiveProviders({
        providers,
        providerSpecificValues,
        billingInfo,
        practiceInfo,
      });

  return [...previousOption, ...providerOptions].sort((a, b) =>
    a.label.localeCompare(b.label),
  );
};

function setEditedField({ result, state, formService }) {
  const KEYS = [
    'billingProviderFirstName',
    'billingProviderLastName',
    'billingProviderMiddleName',
    'billingProviderNPI',
    'billingProviderOtherId',
    'billingProviderOtherIdQualifier',
    'billingProviderPracticeName',
    'federalTaxIdNumber',
    'federalTaxIdSource',
    'federalTaxIdType',
    'serviceFacilityAddressType',
    'serviceFacilityNPI',
    'serviceFacilityName',
    'serviceFacilityOtherId',
    'serviceFacilityOtherIdQualifier',
  ];

  KEYS.forEach(key => {
    if (state[key] !== result[key]) {
      formService.apply(`claimEditedField.${key}`, true);
    }
  });
}

export default async ({
  claimCellData,
  state,
  handlers,
  nebFormClaim,
  payerPlan,
  billingInfo,
  practiceInfo,
  formService,
}) => {
  const allProviders = await getProviderUsers();

  const options = getBillingProviderOptions({
    state,
    providerSpecificValues: payerPlan.providerSpecificValues,
    billingInfo,
    practiceInfo,
    allProviders,
  });

  const selectedOption = options.find(
    ({ value }) => value.billingProviderId === state.billingProviderId,
  );

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_BILLING_PROVIDER, {
    title: 'Select Billing Provider',
    tooltipText: claimCellData.tooltipText,
    label: 'Billing Provider',
    name: claimCellData.name,
    selectedOption,
  });

  if (result) {
    const { billingProviderId } = result;

    if (billingProviderId !== state.billingProviderId) {
      const [signatureSrc, s3Key] = await Promise.all([
        getSignatureFile(billingProviderId),
        getProviderSignatureS3Key(billingProviderId),
      ]);

      nebFormClaim.__signatureSrc = signatureSrc;

      formService.apply('claimEditedField.billingProviderId', true);

      handlers.change({
        name: 'billingProviderSignatureS3Key',
        value: s3Key,
      });
    }

    const billingProvider = allProviders.find(
      ({ id }) => id === result.billingProviderId,
    );

    state.claimCharges.forEach((claimCharge, index) => {
      if (claimCharge.isSpecialistCharge) {
        handlers.change({
          name: `claimCharges.${index}.providerOtherIdQualifier`,
          value: result.billingProviderOtherIdQualifier,
        });

        handlers.change({
          name: `claimCharges.${index}.providerOtherId`,
          value: result.billingProviderOtherId,
        });

        handlers.change({
          name: `claimCharges.${index}.providerNPI`,
          value: billingProvider && billingProvider.NPI,
        });

        handlers.change({
          name: `claimCharges.${index}.providerId`,
          value: result.billingProviderId,
        });
      }
    });

    Object.entries(result).forEach(([name, value]) => {
      if (['billingProviderAddress', 'serviceFacilityAddress'].includes(name)) {
        Object.entries(value).forEach(([addressKey, addressValue]) => {
          handlers.change({
            name: `${name}.${addressKey}`,
            value: addressValue,
          });
        });
      } else {
        setEditedField({ result, state, formService });

        handlers.change({
          name,
          value,
        });
      }
    });
  }
};
