import './neb-documents-list';
import './neb-documents-info';

import { LitElement, html } from 'lit';

import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

export const ID_LIST = 'list';
export const ID_INFO = 'info';
export const ELEMENTS = {
  list: {
    id: 'list',
    tag: 'neb-documents-list',
  },
  info: {
    id: 'info',
    tag: 'neb-documents-info',
  },
};
export class NebDocumentsDetail extends LitElement {
  static get properties() {
    return {
      expanded: Boolean,
      totalDocuments: Number,
      selectedIndex: Number,
      documents: Array,
      noDocuments: Boolean,
      selectedDocument: Object,
      imageSrcSpec: Object,
      loadingImageSrcSpec: Boolean,
      currentTab: String,
      checkedDocuments: Object,
      showLocalLoading: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.expanded = false;
    this.showLocalLoading = false;
    this.totalDocuments = 0;
    this.selectedIndex = -1;
    this.documents = [];
    this.noDocuments = false;
    this.selectedDocument = null;
    this.imageSrcSpec = null;
    this.loadingImageSrcSpec = false;
    this.currentTab = '';
    this.checkedDocuments = {};

    this.onOpenPopupUpload = () => {};

    this.onOpenPopupEdit = () => {};

    this.onRequestDocuments = () => {};

    this.onSelectDocumentIndex = () => {};

    this.onSearch = () => {};

    this.onDocumentListRendered = () => {};

    this.onGetBulkActions = () => {};

    this.onCheckDocument = () => {};

    this.onOpenPopupScan = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      openPopupUpload: () => this.onOpenPopupUpload(),
      openPopupEdit: () => this.onOpenPopupEdit(),
      openPopupScan: () => this.onOpenPopupScan(),
      requestDocuments: () => this.onRequestDocuments(),
      selectDocumentIndex: index => this.onSelectDocumentIndex(index),
      search: text => this.onSearch(text),
      docSrcSupplier: doc => this.getDocSrc(doc),
      documentListRendered: count => this.onDocumentListRendered(count),
      getBulkActions: () => this.onGetBulkActions(),
      checkDocument: (documentId, checked) =>
        this.onCheckDocument(documentId, checked),
    };
  }

  scrollToDocument(index) {
    this.__elements.list.scrollToDocument(index);
  }

  reset() {
    this.__elements.list.reset();

    this.__elements.info.reset();
  }

  firstUpdated() {
    this.__elements = {
      list: this.shadowRoot.getElementById(ID_LIST),
      info: this.shadowRoot.getElementById(ID_INFO),
    };
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          margin-top: 0;
          width: 100%;
          height: 100%;
        }

        :host([hasTabs]) {
          height: calc(100% - 54px);
        }

        .panel-left {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          flex: 0 0 298px;
        }

        .panel-right {
          margin: 0 ${CSS_SPACING} 0 40px;
          flex: 1 0 0;
          width: 100%;
          overflow-x: hidden;
        }
      </style>
    `;
  }

  __isOnEncounterDocumentsTab() {
    return this.currentTab === 'encounter-documents';
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <neb-documents-list
        id="${ID_LIST}"
        class="panel-left"
        .totalDocuments="${this.totalDocuments}"
        .selectedIndex="${this.selectedIndex}"
        .documents="${this.documents}"
        .expanded="${this.expanded}"
        .noDocuments="${this.noDocuments}"
        .onOpenPopupUpload="${this.__handlers.openPopupUpload}"
        .onRequestDocuments="${this.__handlers.requestDocuments}"
        .onSearch="${this.__handlers.search}"
        .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
        .onDocumentListRendered="${this.__handlers.documentListRendered}"
        .onGetBulkActions="${this.__handlers.getBulkActions}"
        .onCheckDocument="${this.__handlers.checkDocument}"
        .checkedDocuments="${this.checkedDocuments}"
        .isOnEncounterDocuments="${this.__isOnEncounterDocumentsTab()}"
        .onOpenPopupScan="${this.__handlers.openPopupScan}"
      ></neb-documents-list>

      <neb-documents-info
        id="${ID_INFO}"
        class="panel-right"
        .expanded="${this.expanded}"
        .noDocuments="${this.noDocuments}"
        .selectedDocument="${this.selectedDocument}"
        .showLocalLoading="${this.showLocalLoading}"
        .imageSrcSpec="${this.imageSrcSpec}"
        .loadingImageSrcSpec="${this.loadingImageSrcSpec}"
        .onOpenPopupEdit="${this.__handlers.openPopupEdit}"
        .isOnEncounterDocuments="${this.__isOnEncounterDocumentsTab()}"
      ></neb-documents-info>
    `;
  }
}
customElements.define('neb-documents-detail', NebDocumentsDetail);
