import { LitElement, html } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../grids/neb-grid-buttons';
import { getCategories } from '../../../api-clients/hot-buttons';
import { baseStyles, layoutStyles } from '../../../styles';
import { mapCategoryItemsToButtonModel } from '../../../utils/hot-buttons';

export const ELEMENTS = {
  selectProvider: { id: 'select-provider' },
  selectCategory: { id: 'select-category' },
  grid: { id: 'grid' },
};

export class ApplyHotButtons extends LitElement {
  static get properties() {
    return {
      model: Object,
      existingItems: Array,
      activeCharges: Array,
      __providerItems: Array,
      __hotButtonItems: Array,
      __selectedProvider: Object,
      __selectedCategory: Object,
      __gridModel: Array,
    };
  }

  static get styles() {
    return [baseStyles, layoutStyles];
  }

  static createModel() {
    return {
      providerItems: [],
      hotButtonItems: [],
      selectedProvider: '',
      selectedCategory: '',
      categoryType: '',
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {
      providerItems: [],
      hotButtonItems: [],
      selectedProvider: '',
      selectedCategory: '',
      categoryType: '',
    };

    this.existingItems = [];
    this.activeCharges = [];
    this.__providerItems = [];
    this.__hotButtonItems = [];
    this.__selectedProvider = '';
    this.__selectedCategory = '';
    this.__gridModel = [];

    this.onApplyHotButton = () => {};

    this.onSetModel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      providerChange: async ({ value: providerItem }) => {
        if (this.__selectedProvider.id !== providerItem.id) {
          this.__selectedProvider = providerItem;
          this.__selectedCategory = '';

          await this.__setCategories(providerItem);
        }
      },
      categoryChange: ({ value }) => {
        this.__selectedCategory = value;
        this.onSetModel({
          selectedCategory: this.__selectedCategory,
        });
      },
      selectItem: item => {
        if (item) this.onApplyHotButton(item.content);
      },
    };
  }

  async __setCategories(providerItem) {
    this.__hotButtonItems = (
      await getCategories(this.model.categoryType, providerItem.id, true)
    ).map(item => ({
      ...item,
      label: item.name,
    }));

    this.onSetModel({
      selectedCategory: this.__selectedCategory,
      selectedProvider: this.__selectedProvider,
      hotButtonItems: this.__hotButtonItems,
    });

    this.__selectedCategory = this.__hotButtonItems[0];
  }

  __setGridModel() {
    const items =
      this.__selectedCategory && this.model.categoryType
        ? this.__selectedCategory[this.model.categoryType] || []
        : [];

    this.__gridModel = mapCategoryItemsToButtonModel(
      items,
      this.model.categoryType,
      this.existingItems,
      this.activeCharges,
    );
  }

  update(changedProps) {
    super.update(changedProps);

    if (changedProps.has('model')) {
      this.__providerItems = this.model.providerItems;
      this.__hotButtonItems = this.model.hotButtonItems;
      this.__selectedProvider = this.model.selectedProvider;
      this.__selectedCategory = this.model.selectedCategory;
    }

    if (
      changedProps.has('existingItems') ||
      changedProps.has('__selectedCategory')
    ) {
      this.__setGridModel();
    }
  }

  render() {
    return html`
      <div class="layout">
        <div class="grid grid-auto-left pad spacer-top">
          <div class="grid grid-lean grid-2">
            <neb-select
              id="${ELEMENTS.selectProvider.id}"
              label="Provider"
              .items="${this.__providerItems}"
              .value="${this.__selectedProvider}"
              .onChange="${this.__handlers.providerChange}"
            ></neb-select>

            <neb-select
              id="${ELEMENTS.selectCategory.id}"
              label="Category"
              .items="${this.__hotButtonItems}"
              .value="${this.__selectedCategory}"
              .onChange="${this.__handlers.categoryChange}"
            ></neb-select>
          </div>

          <div></div>
        </div>

        <neb-grid-buttons
          id="${ELEMENTS.grid.id}"
          .model="${this.__gridModel}"
          .onSelectItem="${this.__handlers.selectItem}"
          disableEmpty="true"
        ></neb-grid-buttons>
      </div>
    `;
  }
}

window.customElements.define('neb-apply-hot-buttons', ApplyHotButtons);
