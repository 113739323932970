import './neb-patients-data-row';

import { LitElement, html, css } from 'lit';

import { renderNebTableStyles } from '../../../../../neb-styles/neb-table-styles';
import {
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
} from '../../../../../neb-styles/neb-variables';

import { FROM_RECORD } from './neb-patients-data-retriever';
import { recordDataSetter } from './neb-patients-data-setter';
import { DEFAULT_CELL_RENDERER } from './neb-patients-table-cell-renderer';
import { RECORD_TABLE_METADATA } from './neb-patients-table-meta';

class NebPatientsTable extends LitElement {
  static get properties() {
    return {
      patients: {
        type: Array,
      },
      metadata: {
        type: Array,
      },
      cellRenderer: {
        type: Object,
      },
      dataGetter: {
        type: Object,
      },
      dataSetter: {
        type: Object,
      },
      editable: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.patients = [];
    this.metadata = RECORD_TABLE_METADATA;
    this.cellRenderer = DEFAULT_CELL_RENDERER;
    this.dataGetter = FROM_RECORD;
    this.dataSetter = recordDataSetter;
    this.editable = false;
    this.__tableData = [];

    this.onPatientsChanged = _patients => {};
  }

  __initHandlers() {
    this.__handlers = {
      valueChange: (rowValueObject, index) =>
        this.__valueChange(rowValueObject, index),
    };
  }

  updated() {
    if (this.editable) {
      this.__tableData = JSON.parse(JSON.stringify(this.patients));
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        font-size: ${CSS_FONT_SIZE_BODY};
        background-color: white;
        word-break: break-all;
      }

      .empty {
        font-style: italic;
        padding: 15px 0 15px 25px;
        color: ${CSS_COLOR_GREY_1};
      }
    `;
  }

  render() {
    return html`
      <div>
        ${renderNebTableStyles()} ${this.__renderTableHeader()}
        ${this.__renderPatients()}
      </div>
    `;
  }

  __renderTableHeader() {
    return html`
      <div id="header" class="neb-table-row neb-table-header">
        ${this.metadata.map(
          meta => html`
            <div id="${meta.id}" class="neb-table-cell ${meta.cellWidth}">
              ${meta.column}
            </div>
          `,
        )}
      </div>
    `;
  }

  __renderPatients() {
    return this.patients.length > 0
      ? html`
          ${this.patients.map(
            (patient, index) => html`
              <neb-patients-data-row
                id="patient-${index}"
                .index="${index}"
                .patient="${patient}"
                .metadata="${this.metadata}"
                .cellRenderer="${this.cellRenderer}"
                .dataGetter="${this.dataGetter}"
                .dataSetter="${this.dataSetter}"
                .editable="${this.editable}"
                .onChange="${this.__handlers.valueChange}"
              ></neb-patients-data-row>
            `,
          )}
        `
      : html` <div class="empty">There is no patients data.</div> `;
  }

  __valueChange(rowValues, index) {
    this.__tableData[index] = JSON.parse(JSON.stringify(rowValues));
    this.onPatientsChanged(this.__tableData);
  }
}
customElements.define('neb-patients-table', NebPatientsTable);
