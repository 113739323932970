import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../tables/settings/referral-sources/neb-table-referral-sources';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { required } from '../../../../packages/neb-utils/validators';
import {
  CSS_COLOR_GREY_7,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../styles';

export const CONFIRM_CHANGE_SOURCE = {
  confirmText: 'Acknowledge',
  cancelText: 'Cancel',
  title: 'Warning',
  message: html`
    Changing the source or organization will update all patient records who were
    previously referred by this source.
  `,
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  textfieldName: {
    id: 'textfield-name',
  },
  sourcesHeader: {
    id: 'sources-header',
  },
  addReferralSourceButton: {
    id: 'add-referral-source-button',
  },
  table: {
    id: 'table',
  },
  activeToggle: {
    id: 'active-toggle',
  },
  hideInactiveCheckbox: {
    id: 'hide-inactive-checkbox',
  },
};

export default class NebFormReferralSourceCategory extends Form {
  static get properties() {
    return {
      categories: Array,
      __hideInactive: Boolean,
      organizations: Array,
      __popupAppeared: Array,
    };
  }

  static createModel() {
    return {
      id: '',
      name: '',
      sources: [],
      active: true,
    };
  }

  initState() {
    super.initState();

    this.categories = [];
    this.organizations = [];
    this.__popupAppeared = Array(this.model.sources.length).fill(false);
    this.__hideInactive = true;
  }

  createSelectors() {
    return {
      children: {
        name: [required(), this.duplicateNameValidation()],
        sources: {
          createItem: () => ({
            active: true,
            id: '',
            type: 'Source',
            name: '',
            organizationId: null,
            search: '',
            isUsed: false,
          }),
          children: {
            $: {
              children: {
                name: {
                  validators: [
                    required(),
                    this.duplicateSourceNameValidation(),
                  ],
                },
              },
            },
          },
        },
      },
    };
  }

  duplicateNameValidation(error = 'Duplicate') {
    return {
      error,
      validate: () => {
        if (this.model.name === this.state.name) {
          return true;
        }

        const categoryNames = this.model.categories.map(
          category => category.name,
        );
        return !categoryNames.includes(this.state.name);
      },
    };
  }

  duplicateSourceNameValidation(error = 'Duplicate') {
    return {
      error,
      validate: (v, keyPath, state) => {
        const { sources } = state;
        const currIndex = Number(keyPath[1]);

        const sourceNames = sources.map((source, index) =>
          currIndex !== index ? source.name : null,
        );

        return !sourceNames.includes(sources[currIndex].name);
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addItem: () => {
        this.formService.addItem('sources', 0);
      },
      changeSourceName: async ({
        value,
        event,
        index: originalIndex,
        item,
      }) => {
        const index = item.id
          ? this.state.sources.findIndex(source => source.id === item.id)
          : originalIndex;

        const showWarningPopup = this.__showConfirmReferralChangePopup({
          idExists: this.state.sources[index].id,
          usedInPatientRecord: this.state.sources[index].isUsed,
          index,
        });

        if (showWarningPopup && event !== 'focus' && event !== 'blur') {
          if (!(await this.__changeSourceInfo())) {
            const originalItemIndex = this.model.sources.findIndex(
              source => source.id === item.id,
            );

            this.formService.apply(
              `sources.${index}.name`,
              this.model.sources[originalItemIndex].name,
            );

            return;
          }

          this.__popupAppeared[index] = true;
        }

        this.formService.apply(`sources.${index}.name`, value);
      },
      changeSourceStatus: ({ value, item, index: originalIndex }) => {
        const index = item.id
          ? this.state.sources.findIndex(source => source.id === item.id)
          : originalIndex;

        this.formService.apply(`sources.${index}.active`, value);
      },
      changeSourceType: async ({ value, event, item }) => {
        if (event === 'select') {
          const index = this.state.sources.findIndex(
            source => source.id === item.id,
          );

          const originalItem = this.state.sources[index];

          const showWarningPopup = this.__showConfirmReferralChangePopup({
            idExists: originalItem.id,
            usedInPatientRecord: originalItem.isUsed,
            index,
          });

          if (showWarningPopup) {
            if (!(await this.__changeSourceInfo())) {
              return;
            }

            this.__popupAppeared[index] = true;
          }

          this.formService.apply(`sources.${index}.type`, value);
          this.formService.apply(`sources.${index}.name`, '');
          this.formService.apply(`sources.${index}.organizationId`, null);
          this.formService.apply(`sources.${index}.search`, '');
        }
      },
      changeOrganization: async ({ value, event, item }) => {
        const index = this.state.sources.findIndex(
          source => source.id === item.id,
        );

        const originalItem = this.state.sources[index];
        const showWarningPopup = this.__showConfirmReferralChangePopup({
          idExists: originalItem.id,
          usedInPatientRecord: originalItem.isUsed,
          index,
        });

        if (showWarningPopup && event !== 'focus' && event !== 'blur') {
          if (!(await this.__changeSourceInfo())) {
            return;
          }

          this.__popupAppeared[index] = true;
        }

        if (value) {
          const { id, name } = value;

          this.formService.apply(`sources.${index}.name`, name);
          this.formService.apply(`sources.${index}.organizationId`, id);
        } else {
          this.formService.apply(`sources.${index}.name`, '');
          this.formService.apply(`sources.${index}.organizationId`, null);
        }
      },
      removeItem: ({ index }) => {
        this.formService.removeItem('sources', index);
        this.formService.validate();
      },
      search: ({ value, index }) => {
        this.formService.apply(`sources.${index}.search`, value);
      },
      hideInactive: e => {
        this.__hideInactive = e.value;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .field {
          width: fit-content;
          width: -moz-fit-content;
        }

        .label {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .name-field {
          padding: 0 ${CSS_SPACING};
        }

        .sources-header {
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
          display: flex;
          gap: ${CSS_SPACING};
          padding: 0px ${CSS_SPACING} 10px;
        }

        .options-container {
          display: flex;
          align-items: center;
        }

        neb-checkbox {
          padding-left: ${CSS_SPACING};
        }

        neb-switch {
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  __showConfirmReferralChangePopup({ idExists, usedInPatientRecord, index }) {
    return idExists && usedInPatientRecord && !this.__popupAppeared[index];
  }

  async __changeSourceInfo() {
    const changeSourceInfo = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CONFIRM_CHANGE_SOURCE,
    );

    return changeSourceInfo;
  }

  __renderSourcesHeader() {
    return html`
      <div id="${ELEMENTS.sourcesHeader.id}" class="sources-header">
        <span class="label">Sources</span>
        <neb-button-action
          id="${ELEMENTS.addReferralSourceButton.id}"
          label="Add Referral Source"
          .onClick="${this.handlers.addItem}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-referral-sources
        id="${ELEMENTS.table.id}"
        .name="sources"
        .model="${this.state.sources}"
        .originalSources="${this.model.sources}"
        .hideInactive="${this.__hideInactive}"
        .organizationItems="${this.organizations}"
        .onAddItem="${this.handlers.addItem}"
        .onChangeSourceName="${this.handlers.changeSourceName}"
        .onChangeSourceType="${this.handlers.changeSourceType}"
        .onChangeOrganization="${this.handlers.changeOrganization}"
        .onChangeSourceStatus="${this.handlers.changeSourceStatus}"
        .onRemove="${this.handlers.removeItem}"
        .onSearch="${this.handlers.search}"
        .errors="${this.errors.sources}"
      ></neb-table-referral-sources>
    `;
  }

  __renderActiveSwitch() {
    return html`
      <neb-switch
        id="${ELEMENTS.activeToggle.id}"
        label="Active"
        name="active"
        .on="${this.state.active}"
        .onChange="${this.handlers.change}"
      ></neb-switch>
    `;
  }

  __renderHideInactiveSourcesCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.hideInactiveCheckbox.id}"
        label="Hide inactive items"
        .onChange="${this.handlers.hideInactive}"
        ?checked="${this.__hideInactive}"
      ></neb-checkbox>
    `;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        name="name"
        class="name-field"
        label="Name"
        helper="Required"
        placeholder="Name"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
      <div class="options-container">${this.__renderActiveSwitch()}</div>
      ${this.__renderSourcesHeader()}
      ${this.__renderHideInactiveSourcesCheckbox()} ${this.__renderTable()}
    `;
  }
}

customElements.define(
  'neb-form-referral-source-category',
  NebFormReferralSourceCategory,
);
