export const SFTP_CSS = Object.freeze({
  NOT_TESTED: '',
  ERROR_ROUTING: 'issue',
  ERROR_AUTHENTICATION: 'issue',
  ERROR_SERVER: 'issue',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export function getSftpStatusIcon(status) {
  switch (status) {
    case SFTP_CSS.ERROR_ROUTING:
      return 'neb:error';

    case SFTP_CSS.ERROR_AUTHENTICATION:
      return 'neb:error';

    case SFTP_CSS.ERROR_SERVER:
      return 'neb:error';

    case SFTP_CSS.WARNING:
      return 'neb:rteWarning';

    case SFTP_CSS.SUCCESS:
      return 'neb:checkCircle';

    case SFTP_CSS.NOT_TESTED:
      return '';

    default:
      return 'neb:rteWarning';
  }
}
