// import { store } from '../../packages/neb-redux/neb-redux-store';
import { ConnectedService } from '../../packages/neb-redux/services/connected-service';
import { setRumTenant, initRum } from '../library/datadog';

export class DatadogRumService extends ConnectedService {
  constructor() {
    super({
      tenant: 'practiceInformation.item',
      sessionItem: 'session.item',
    });

    this.__ddRumInitialized = false;
  }

  __initDatadogRUM(ddRetention) {
    if (!this.__ddRumInitialized) {
      initRum(ddRetention);
      this.__ddRumInitialized = true;
    }
  }

  _stateChanged({ tenant, sessionItem }) {
    this.__initDatadogRUM(sessionItem?.ddRetention);
    setRumTenant(tenant);
  }
}
