import '../../tables/recent-records/neb-table-recent-records';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { html, LitElement, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { getAllRecentRecords } from '../../../api-clients/recent-record';
import { baseStyles } from '../../../styles';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  header: {
    id: 'header',
    title: 'Recently Accessed Records',
  },
  table: {
    id: 'table',
    emptyMessage: 'No recent records',
  },
};

class NebPageRecentRecords extends LitElement {
  static get properties() {
    return {
      __recentRecords: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.__recentRecords = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: column;
          margin: ${CSS_SPACING};
        }

        .header {
          cursor: default;
          display: block;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  async __getRecentRecords() {
    const { data: records } = await getAllRecentRecords();

    return records.map(
      ({ additionalInformation = {}, patient = {}, lastVisitedDate }) => ({
        dateCreated: additionalInformation.dateCreated,
        description: additionalInformation.description || '',
        patient: !patient.id ? '' : objToName(patient.name, DEFAULT_NAME_OPTS),
        location: additionalInformation.location,
        lastAccessed: lastVisitedDate,
      }),
    );
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__recentRecords = await this.__getRecentRecords();
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-text id="${ELEMENTS.header.id}" class="header">
          ${ELEMENTS.header.title}
        </neb-text>

        <neb-table-recent-records
          id="${ELEMENTS.table.id}"
          class="table"
          emptyMessage="${ELEMENTS.table.emptyMessage}"
          .model="${this.__recentRecords}"
        >
        </neb-table-recent-records>
      </div>
    `;
  }
}

customElements.define('neb-page-recent-records', NebPageRecentRecords);
