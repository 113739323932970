export const EMPTY_SELECT_VALUE = { label: '', data: { id: '' } };

export const MERCHANT_PROVIDER_OPTIONS = [
  { label: 'Fiserv', data: 'Fiserv' },
  { label: 'Global Payments', data: 'Global Payments' },
];

export const RCM_OPTION = {
  label: 'RCM',
  value: 'RCM',
};

export const CLINIC_OPTION = {
  label: 'Clinic',
  value: 'Clinic',
};

export const ASSIGNED_TO_OPTIONS = [CLINIC_OPTION, RCM_OPTION];
