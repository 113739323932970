import { html } from 'lit';

import { genDefaultBodyDiagramLabels } from '../../../../../packages/neb-lit-components/src/components/macros/utils/default-body-diagram-labels';

import NebFormChoiceTemplate from './neb-form-choice-template';

import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/macros/neb-macro-region';

export const ELEMENTS = {
  macroRegion: { id: 'macro-region' },
  regionName: { id: 'region-name' },
};

class NebFormChoiceTemplateRegion extends NebFormChoiceTemplate {
  static get properties() {
    return {
      __selectedIndex: Number,
    };
  }

  static createModel() {
    return {
      ...super.createModel(),
      answers: genDefaultBodyDiagramLabels(),
    };
  }

  createSelectors() {
    return {
      children: {
        ...super.createSelectors().children,
        answers: {
          unformat: answers =>
            answers.map((answer, index) =>
              answer.value ? answer : this.model.answers[index],
            ),
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__selectedIndex = 0;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      clickRegion: index => {
        this.__selectedIndex = index;
      },

      changeRegionName: ({ name, value }) => {
        this.formService.apply(`${name}.${this.__selectedIndex}.value`, value);
      },
    };
  }

  renderChoices() {
    return html`
      <neb-macro-region
        id="${ELEMENTS.macroRegion.id}"
        .selectedIndices="${[this.__selectedIndex]}"
        .showClearButton="${false}"
        .onClick="${this.handlers.clickRegion}"
      ></neb-macro-region>

      <neb-textfield
        id="${ELEMENTS.regionName.id}"
        class="text-input"
        name="answers"
        value="${this.state.answers[this.__selectedIndex].value}"
        maxLength="255"
        .onChange="${this.handlers.changeRegionName}"
      ></neb-textfield>
    `;
  }
}

window.customElements.define(
  'neb-form-choice-template-region',
  NebFormChoiceTemplateRegion,
);
