import { ITEM_EMPTY } from './selectors';

export const findInSelect = (id, arr, defaultValue = ITEM_EMPTY) =>
  arr?.find(v => v.data.id === id) || defaultValue;

// eslint-disable-next-line complexity
export function isRequired(v, error = 'Required') {
  if (!v) {
    return error;
  }

  if (Array.isArray(v) && !v.length) {
    return error;
  }

  if (typeof v === 'string' && !v.trim()) {
    return error;
  }

  if (v.data && !v.data.id) {
    return error;
  }

  return '';
}

export const requiredSelect = (items, stateKey) => ({
  stateKey,
  toModel: v => v.data.id,
  toState: v => findInSelect(v, items),
  validate: isRequired,
});
