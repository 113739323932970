import '../../neb-lit-components/src/components/neb-loading-spinner-resizable';

import { html, css } from 'lit';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  cancelButton: {
    id: 'button-cancel-base',
  },
  title: {
    id: 'title',
  },
};

class NebPopupTransactionProcessing extends NebPopup {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      dirty: {
        type: Boolean,
      },
    };
  }

  initState() {
    super.initState();
    this.title = 'Transaction Processing';
    this.showCancelButton = false;
  }

  firstUpdated() {
    Promise.resolve(this.model.promise)
      .then(value => this.onClose(value))
      .catch(e => {
        console.error(e);
        this.onClose(null);
      });
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
        }

        .spinner {
          padding-bottom: 50px;
          text-align: center;
          z-index: 1;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-content">
        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-transaction-processing',
  NebPopupTransactionProcessing,
);
