import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  icon: {
    id: 'icon',
  },
};

class IconButton extends LitElement {
  static get properties() {
    return {
      icon: String,
      name: String,
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          cursor: pointer;
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) {
          cursor: default;
        }

        .icon {
          width: 100%;
          height: 100%;
        }

        :host([disabled]) .icon {
          opacity: 0.65;
          fill: ${CSS_COLOR_DISABLED};
        }

        :host(:not([disabled])) .icon:focus,
        :host(:not([disabled])) .icon:hover {
          opacity: 0.65;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.icon = '';
    this.name = '';
    this.disabled = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => {
        if (!this.disabled) {
          this.onClick(this.name);
          this.blur();
        }
      },
    };
  }

  render() {
    return html`
      <neb-icon
        id="${ELEMENTS.icon.id}"
        class="icon"
        tabindex="0"
        .icon="${this.icon}"
        @click="${this.__handlers.click}"
      ></neb-icon>
    `;
  }
}

window.customElements.define('neb-button-icon', IconButton);
