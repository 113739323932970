import * as pdfOptions from './neb-pdf-viewer-options';

export class RenderValidator {
  validate(changedProps, state) {
    for (const key of changedProps.keys()) {
      if (pdfOptions.VALIDATE_RENDER_KEYS.includes(key) && state.hasPdfSource) {
        if (this.__validateRender(key, changedProps.get(key), state)) {
          return true;
        }
      }
    }

    return false;
  }

  __validateRender(key, oldValue, state) {
    switch (key) {
      case pdfOptions.VALIDATE_RENDER_KEY.PAGE_NUMBER:
        return this.__validateRenderPage(oldValue, state.pageNumber);

      case pdfOptions.VALIDATE_RENDER_KEY.ZOOM:
        return this.__validateRenderZoom(oldValue, state.zoom);

      case pdfOptions.VALIDATE_RENDER_KEY.SIZE:
        return this.__validateRenderSize(oldValue, state);

      default:
        return false;
    }
  }

  __validateRenderPage(oldPageNumber, newPageNumber) {
    return this.__isValueChanged(oldPageNumber, newPageNumber);
  }

  __validateRenderZoom(oldZoom, zoom) {
    return this.__isValueChanged(
      this.__convertZoomToString(oldZoom),
      this.__convertZoomToString(zoom),
    );
  }

  __validateRenderSize(oldSize, state) {
    return (
      ((state.zoom.mode === pdfOptions.ZoomMode.PAGE_WIDTH &&
        this.__isValueChanged(oldSize.view.width, state.size.view.width)) ||
        state.zoom.mode === pdfOptions.ZoomMode.PAGE_FIT) &&
      this.__isSizeChanged(oldSize.view, state.size.view)
    );
  }

  __isSizeChanged(oldViewSize, newViewSize) {
    return (
      this.__isValueChanged(oldViewSize.width, newViewSize.width) ||
      this.__isValueChanged(oldViewSize.height, newViewSize.height)
    );
  }

  __isValueChanged(oldValue, newValue) {
    return newValue && (oldValue == null || newValue !== oldValue);
  }

  __convertZoomToString(zoom) {
    if (!zoom) {
      return null;
    }

    switch (zoom.mode) {
      case pdfOptions.ZoomMode.ACTUAL_SIZE:
      case pdfOptions.ZoomMode.CUSTOM_ZOOM:
      case pdfOptions.ZoomMode.ZOOM:
        return zoom.value ? `zoom-${zoom.value}` : null;

      case pdfOptions.ZoomMode.PAGE_FIT:
      case pdfOptions.ZoomMode.PAGE_WIDTH:
        return zoom.mode;

      default:
        return null;
    }
  }
}
