export const LOCATION_KEYS = {
  NAME: 'name',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'emailAddress',
};

export const getLocationValue = (
  locations,
  locationId,
  key,
  valueIfNotFound = '',
) => {
  if (!(locationId || locations.length)) return valueIfNotFound;

  const { [key]: value } = {
    ...{ [key]: valueIfNotFound },
    ...locations.find(location => location.id === locationId),
  };
  return value || valueIfNotFound;
};

export const findLocation = (locationId, locations) => {
  const foundLocation = locations.find(location => location.id === locationId);
  return foundLocation || null;
};
