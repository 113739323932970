import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-select';

import { isPropRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html } from 'lit';

import * as api from '../../../../../src/api-clients/fee-schedules';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import * as selectors from '../../../../neb-utils/selectors';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  feeScheduleSelect: { id: 'fee-schedule-select' },
  defaultCheckbox: { id: 'default-check' },
};

export default class NebFormAssociateFeeSchedule extends NebForm {
  static get properties() {
    return {
      __items: Array,
      associatedItems: Array,
    };
  }

  static createModel() {
    return {
      id: '',
      default: false,
    };
  }

  initState() {
    super.initState();

    this.__items = [];

    this.associatedItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDefault: async e => {
        if (e.value) {
          const accepted = await this.__openWarningPopup(
            'Default Fee Schedule',
            'This will now be the default fee schedule when posting charges. All active fee schedules, including the previous default, will be available to select if needed.',
          );

          if (accepted) {
            this.formService.apply(e.name, e.value);
          }
        } else {
          this.formService.apply(e.name, e.value);
        }
      },
    };
  }

  async load() {
    this.__items = (await api.fetchMany())
      .filter(
        data =>
          !this.associatedItems.find(item => item.id === data.id) &&
          data.active,
      )
      .map(data => ({
        label: data.name,
        data,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  createSelectors() {
    return {
      children: {
        id: selectors.select(this.__items, selectors.ITEM_EMPTY, {
          validators: [isPropRequired('data.id')],
        }),
      },
    };
  }

  __openWarningPopup(title, message) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title,
      confirmText: 'Yes',
      cancelText: 'No',
      message: html`
        <p>${message}</p>
        <p>Do you want to proceed?</p>
      `,
    });
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.feeScheduleSelect.id}"
        class="grid pad spacer-top"
        name="id"
        label="Fee Schedule"
        helper="Required"
        .value="${this.state.id}"
        .error="${this.errors.id}"
        .items="${this.__items}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-checkbox
        id="${ELEMENTS.defaultCheckbox.id}"
        class="grid grid-auto-left pad"
        name="default"
        label="Default"
        .checked="${this.associatedItems.length ? this.state.default : true}"
        .error="${this.errors.default}"
        .onChange="${this.handlers.changeDefault}"
        ?disabled="${!this.associatedItems.length}"
      ></neb-checkbox>
    `;
  }
}

customElements.define(
  'neb-form-associate-fee-schedule',
  NebFormAssociateFeeSchedule,
);
