export const saveToLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);

    return true;
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const getFromLocalStorage = key => {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    console.error(err);
  }
  return null;
};
