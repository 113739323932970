import { ConnectedService } from './connected-service';

export class ProviderService extends ConnectedService {
  constructor(callback) {
    super({
      providers: 'providers.item',
      user: 'session',
    });

    this.onStateChanged = callback;
  }

  _stateChanged({ providers, user }) {
    const { item } = user;
    const access =
      item && item.access && item.access.find
        ? item.access.find(a => a.tenantId === item.tenantId)
        : {};

    let selectedProvider;

    if (access.provider) {
      selectedProvider = user.item;
    } else if (providers.length === 1) {
      [selectedProvider] = providers;
    } else {
      selectedProvider = '';
    }

    this.onStateChanged({
      selectedProvider,
      providers,
    });
  }
}
