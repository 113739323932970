export const hexToDecimal = hex => parseInt(hex, 16);

export const pngHexSingature = ['89', '50', '4E', '47', '0D', '0A', '1A', '0A'];

export const jpgHexSignatures = [
  ['FF', 'D8', 'FF', 'DB'],
  ['FF', 'D8', 'FF', 'E0', '00', '10', '4A', '46', '49', '46', '00', '01'],
  ['FF', 'D8', 'FF', 'EE'],
  ['FF', 'D8', 'FF', 'E1', '??', '??', '45', '78', '69', '66', '00', '00'],
  ['FF', 'D8', 'FF', 'E0'],
  ['00', '00', '00', '0C', '6A', '50', '20', '20', '0D', '0A', '87', '0A'],
  ['FF', '4F', 'FF', '51'],
];

const fileIsJpg = fileSignature => {
  for (let i = 0; i < jpgHexSignatures.length; i++) {
    let validIdentifiers = 0;

    for (let j = 0; j < jpgHexSignatures[i].length; j++) {
      if (jpgHexSignatures[i][j] !== '??') {
        const signatureDecimal = hexToDecimal(jpgHexSignatures[i][j]);

        if (signatureDecimal === fileSignature[j]) {
          validIdentifiers += 1;
        }
      } else {
        validIdentifiers += 1;
      }
    }

    if (validIdentifiers === jpgHexSignatures[i].length) {
      return true;
    }
  }

  return false;
};

const fileIsPng = fileSignature => {
  for (let i = 0; i < fileSignature.length; i++) {
    const signatureDecimal = hexToDecimal(pngHexSingature[i]);

    if (signatureDecimal !== fileSignature[i]) {
      return false;
    }
  }

  return true;
};

export const validateFileType = async file => {
  let validType;

  try {
    const buffer = await file.arrayBuffer();
    const ia = new Uint8Array(buffer);

    switch (file.type) {
      case 'image/jpeg':
      case 'image/jpg':
        validType = fileIsJpg(ia.subarray(0, 12));
        break;

      case 'image/png':
        validType = fileIsPng(ia.subarray(0, 8));
        break;

      default:
        validType = true;
        break;
    }
  } catch (e) {
    return false;
  }

  return validType;
};
