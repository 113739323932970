/* eslint-disable complexity */
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from '../../../utils/local-storage-util';
import { getDevices, getDevice } from '../api/scan-api';
import { DOCUMENT_TYPES } from '../enums/scan-enums';
import {
  mapToScannerConfigType,
  mapToDocumentSources,
  mapToDocumentSourceLists,
} from '../mappers/device-mapper';

let scanners = [];

const SCAN_CONFIG_KEY = 'scanConfig';

const DOCUMENT_SOURCE_NAME = Object.freeze({
  FLATBED: 'Flatbed',
  FEEDER: 'Feeder',
});

const DEFAULT_CONFIG = Object.freeze({
  DOCUMENT_SOURCE_NAME: 'Flatbed',
  PAGE_SIZE_NAMES: ['US Letter', 'USLetter'],
  COLOR_NAME: 'RGB',
  RESOLUTION_NAME: '200',
  DUPLEX_NAME: 'None',
  DOCUMENT_TYPE: DOCUMENT_TYPES[0],
  PREVIEW: true,
});

const printObject = obj => JSON.stringify(obj, null, 2);

export const getScanners = async () => {
  const devices = await getDevices();
  scanners = devices.map(x => mapToScannerConfigType(x)).filter(x => x.id >= 0);
  const result = [...scanners];
  return result;
};

const findScanner = scannerInfo => {
  const scanner = scanners.find(
    x => x.id === scannerInfo.id && x.name === scannerInfo.name,
  );

  if (!scanner) {
    throw new Error(
      `Cannot find scanner ${printObject(scannerInfo)} in scanner list`,
    );
  }

  return scanner;
};

const findDocumentSource = (documentSources, id) => {
  const documentSource = documentSources.find(x => x.id === id);

  if (!documentSource) {
    throw new Error(
      `Cannot find id ${id} documentSources ${printObject(documentSources)} `,
    );
  }

  return documentSource;
};

const getDeviceDocumentSources = async scannerInfo => {
  const scanner = findScanner(scannerInfo);

  if (scanner.documentSources?.length) {
    return scanner.documentSources;
  }

  const device = await getDevice(scannerInfo.id);
  scanner.documentSources = mapToDocumentSources(device.documentSourceIds);
  return scanner.documentSources;
};

export const getDocumentSources = async scannerInfo => {
  const documentSources = await getDeviceDocumentSources(scannerInfo);
  return documentSources.map(x => mapToScannerConfigType(x));
};

export const getDocumentSourceLists = async (scannerInfo, documentSourceId) => {
  const documentSources = await getDeviceDocumentSources(scannerInfo);
  const documentSource = findDocumentSource(documentSources, documentSourceId);
  const documentSourceLists = mapToDocumentSourceLists(documentSource);

  return documentSourceLists;
};

const findByOrFirst = ({ items, key = 'name', value, values }) => {
  if (!items.length) {
    return null;
  }
  let item;

  if (value) {
    item = items.find(x => x[key] === value);
  }

  if (values && values.length) {
    for (let i = 0; i < values.length; i++) {
      item = items.find(x => x[key] === values[i]);

      if (item) {
        break;
      }
    }
  }

  return item || items[0];
};

const createArrayFromDefaultItem = (defaultItem, item = null) => {
  if (item) {
    return [item, defaultItem];
  }

  return [defaultItem];
};

const createArrayFromDefaultItems = (defaultItems, item = null) => {
  if (item) {
    return [item, ...defaultItems];
  }

  return defaultItems;
};

// eslint-disable-next-line complexity
export const getDefaultDocumentSourceListsValues = (
  documentSource,
  scanConfigLocalStorage = {},
) => {
  const result = {
    resolution: findByOrFirst({
      items: documentSource.resolutions,
      values: createArrayFromDefaultItem(
        DEFAULT_CONFIG.RESOLUTION_NAME,
        scanConfigLocalStorage.resolutionName,
      ),
    }),
    color: findByOrFirst({
      items: documentSource.colors,
      values: createArrayFromDefaultItem(
        DEFAULT_CONFIG.COLOR_NAME,
        scanConfigLocalStorage.colorName,
      ),
    }),
    pageSize: findByOrFirst({
      items: documentSource.pageSizes,
      values: createArrayFromDefaultItems(
        DEFAULT_CONFIG.PAGE_SIZE_NAMES,
        scanConfigLocalStorage.pageSizeName,
      ),
    }),
    duplex: findByOrFirst({
      items: documentSource.duplexes,
      values: createArrayFromDefaultItem(
        DEFAULT_CONFIG.DUPLEX_NAME,
        scanConfigLocalStorage.duplexName,
      ),
    }),
    documentType: scanConfigLocalStorage.documentType
      ? scanConfigLocalStorage.documentType
      : DEFAULT_CONFIG.DOCUMENT_TYPE,
    preview:
      typeof scanConfigLocalStorage.preview === 'boolean'
        ? scanConfigLocalStorage.preview
        : DEFAULT_CONFIG.PREVIEW,
  };
  return result;
};

export const saveScanConfigToLocalStorage = scanConfig => {
  try {
    const config = {
      ...scanConfig,
      lastUpdated: parseDate().format('MM/DD/YYYY'),
    };
    const providerId = store.getState().session.item.id;

    const localStorageConfig =
      JSON.parse(getFromLocalStorage(SCAN_CONFIG_KEY)) || {};

    localStorageConfig[providerId] = config;

    saveToLocalStorage(SCAN_CONFIG_KEY, JSON.stringify(localStorageConfig));

    return true;
  } catch (err) {
    console.error(err);
  }

  return false;
};

export const getScanConfigFromLocalStorage = () => {
  try {
    const scanConfigLocalStorageValue = getFromLocalStorage(SCAN_CONFIG_KEY);

    if (!scanConfigLocalStorageValue) {
      return {};
    }

    const scanConfigLocalStorage =
      JSON.parse(getFromLocalStorage(SCAN_CONFIG_KEY)) || {};

    if (Object.keys(scanConfigLocalStorage).length === 0) {
      return {};
    }

    const providerId = store.getState().session.item.id;

    if (scanConfigLocalStorage[providerId]) {
      return scanConfigLocalStorage[providerId];
    }

    const firstProviderSavedInLocalStorage = Object.keys(
      scanConfigLocalStorage,
    )[0];

    return scanConfigLocalStorage[firstProviderSavedInLocalStorage];
  } catch (err) {
    console.error(err);
  }

  return {};
};

const getDefaultDocumentSource = (documentSources, documentSourceName) => {
  if (!documentSources.length) {
    return null;
  }

  if (documentSourceName) {
    const defaultDocumentSourcePreviouslySaved = documentSources.find(
      x => documentSourceName && x.name === documentSourceName,
    );

    if (defaultDocumentSourcePreviouslySaved) {
      return defaultDocumentSourcePreviouslySaved;
    }
  }

  const defaultDocumentSource =
    documentSources.find(x => x.name === DOCUMENT_SOURCE_NAME.FLATBED) ||
    documentSources[0];

  return defaultDocumentSource;
};

const getDefaultScanner = scannerName => {
  if (!scanners.length) {
    return null;
  }

  if (!scannerName) {
    return scanners[0];
  }

  const scanner = scanners.find(x => x.name === scannerName);

  if (scanner) {
    return scanner;
  }

  return scanners[0];
};

export const getDefaultScannerConfig = async () => {
  const scanConfigLocalStorage = getScanConfigFromLocalStorage();

  const scanner = getDefaultScanner(scanConfigLocalStorage.scannerName);

  if (!scanner) {
    return null;
  }
  const documentSources = await getDeviceDocumentSources(scanner);
  const documentSource = getDefaultDocumentSource(
    documentSources,
    scanConfigLocalStorage.documentSourceName,
  );

  if (!documentSource) {
    return null;
  }

  const defaultScannerConfig = {
    scanner: mapToScannerConfigType(scanner),
    documentSource: mapToScannerConfigType(documentSource),
    ...getDefaultDocumentSourceListsValues(
      documentSource,
      scanConfigLocalStorage,
    ),
  };

  return defaultScannerConfig;
};

export const isScanConfigValid = config =>
  config &&
  config.scannerId !== null &&
  config.scannerId !== undefined &&
  config.documentSourceId !== null &&
  config.documentSourceId !== undefined &&
  config.documentType !== null &&
  config.documentType !== undefined;
