import querystring from 'querystring';

import LRU from 'lru-cache';

import { addToApiCallLog } from '../../../../src/utils/duplicate-api-calls-log';
import { updated } from '../../../../src/utils/update-notifications';
import {
  endRequest,
  startRequest,
} from '../../../neb-redux/actions/global-loading-actions';
import { readDataUrlFromBlob } from '../../../neb-utils/blobProcessor';
import {
  API_URL,
  APPOINTMENTS_API_URL,
  BILLING_API_URL,
  IMAGE_API_URL,
  CHARTING_API_URL,
  CONVERSION_API_URL,
  CORE_API_URL,
  ELECTRONIC_CLAIMS_API_URL,
  EMAIL_API_URL,
  FILES_API_URL,
  PDF_API_URL,
  MACRO_API_URL,
  PERMISSIONS_API_URL,
  REGISTRY_API_URL,
  REPORTS_API_URL,
  PAYMENTS_API_URL,
  PARTNER_API_URL,
} from '../../../neb-utils/env';
import {
  shouldRedirectForUserSecurityAccessError,
  handleRedirect,
} from '../../../neb-utils/neb-request-security';

import { initReduxStore, getStore } from './dynamic-imports/lazy-redux-store';

export const RESPONSE_TYPE = {
  RAW: 'raw',
  JSON: 'json',
  OBJECT_URL: 'objectUrl',
  DATA_URL: 'dataUrl',
};

export const getTenantId = () => {
  const { session, onlineBooking } = getStore().getState();

  if (session.item && session.item.tenantId) {
    return session.item.tenantId;
  }

  if (session.item && session.item.tenantIds && session.item.tenantIds.length) {
    return session.item.tenantIds[0];
  }

  return onlineBooking.item.tenantId;
};

export const getTenantShortName = () => {
  const { booking } = getStore().getState();

  return booking.tenantShortName;
};

function encodeArrayElement(name, value) {
  return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
}

export function buildQueryString(params) {
  if (!params) return '';

  const pairs = Object.entries(params)
    .filter(([_, v]) => v !== undefined)
    .map(([k, v]) =>
      Array.isArray(v)
        ? v.map(item => encodeArrayElement(k, item)).join('&')
        : encodeArrayElement(k, v),
    );

  return pairs.length ? `?${pairs.join('&')}` : '';
}

function __generateUrl(apiUrl, version, path, includeApi = true) {
  return `${apiUrl}${includeApi ? '/api' : ''}/${
    version ? `v${version}/` : ''
  }${path}`;
}

function __generateTenantUrl(apiUrl, version, path, includeApi = true) {
  const tenantId = getTenantId();
  return __generateUrl(
    apiUrl,
    version,
    `tenants/${tenantId}/${path}`,
    includeApi,
  );
}

const MICROSERVICE_REQUEST_FUNCS = {
  api: (version = 1, path) => {
    if (version.toString().startsWith('public')) {
      const tenantShortName = getTenantShortName();

      return `${API_URL}/api/v${version.replace(
        'public',
        '',
      )}/public/${tenantShortName}/${path}`;
    }

    return __generateTenantUrl(API_URL, version, path);
  },
  appointments: (version = 1, path) =>
    __generateTenantUrl(APPOINTMENTS_API_URL, version, path),
  billing: (version, path) => {
    if (version && version.toString().startsWith('public')) {
      const tenantId = getTenantId();
      return `${BILLING_API_URL}/public/v${version.replace(
        'public',
        '',
      )}/tenants/${tenantId}/${path}`;
    }
    return __generateTenantUrl(BILLING_API_URL, version, path);
  },
  charting: (version = 1, path, useTenant = false) =>
    (useTenant ? __generateTenantUrl : __generateUrl)(
      CHARTING_API_URL,
      version,
      path,
    ),
  conversion: (version = 1, path) =>
    __generateUrl(CONVERSION_API_URL, version, path),
  'electronic-claims': (version = 1, path, useTenant = false) =>
    (useTenant ? __generateTenantUrl : __generateUrl)(
      ELECTRONIC_CLAIMS_API_URL,
      version,
      path,
    ),
  core: (version = 1, path) => __generateTenantUrl(CORE_API_URL, version, path),
  email: (version = 1, path) =>
    __generateTenantUrl(EMAIL_API_URL, version, path, false),
  files: (version = 1, path, useTenant = false) =>
    (useTenant ? __generateTenantUrl : __generateUrl)(
      FILES_API_URL,
      version,
      path,
    ),
  image: (version = 1, path) =>
    __generateTenantUrl(IMAGE_API_URL, version, path),
  partner: (version, path) => `${PARTNER_API_URL}/${path}`,
  payments: (version, path) =>
    __generateTenantUrl(PAYMENTS_API_URL, version, path),
  pdf: (version, path, useTenant = false) =>
    (useTenant ? __generateTenantUrl : __generateUrl)(
      PDF_API_URL,
      version,
      path,
    ),
  permissions: (version = 1, path, useTenant = false) => {
    if (version.toString().startsWith('public')) {
      return `${PERMISSIONS_API_URL}/api/public/v${version.slice(6)}/${path}`;
    }

    if (version.toString().startsWith('tenant')) {
      return __generateUrl(
        PERMISSIONS_API_URL,
        version.slice(6),
        `${
          version.slice(6) === '1' ? 'tenant' : 'tenants'
        }/${getTenantId()}/${path}`,
      );
    }

    return (useTenant ? __generateTenantUrl : __generateUrl)(
      PERMISSIONS_API_URL,
      version,
      path,
    );
  },
  registry: (version = 1, path, useTenant = false) =>
    (useTenant ? __generateTenantUrl : __generateUrl)(
      REGISTRY_API_URL,
      version,
      path,
      false,
    ),
  reports: (version = 1, path) =>
    __generateTenantUrl(REPORTS_API_URL, version, path, false),
  macro: (version = 1, path) =>
    __generateTenantUrl(MACRO_API_URL, version, path),
};

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

/**
 * @deprecated please use ApiClientV2 instead
 */
export default class DeprecatedApiClient {
  constructor({
    microservice = 'api',
    maxParallelism = 0,
    cacheConfig = {
      max: 200,
      maxAge: 1000,
    },
    useTenant = false,
  } = {}) {
    initReduxStore();
    this.microservice = microservice;
    this.maxParallelism = maxParallelism;
    this.pendingRequests = [];
    this.processing = 0;
    this.requestCache = new LRU(cacheConfig);
    this.useTenant = useTenant;
  }

  clearCacheKey(cacheKey) {
    if (this.requestCache) this.requestCache.del(cacheKey);
  }

  clearCache() {
    if (this.requestCache) this.requestCache.reset();
  }

  async makeRequest({
    method = 'GET',
    path = this.path,
    replacements = {},
    queryParams = {},
    queryArrayFormat = 'bracket',
    headers = {
      'Content-Type': 'application/json',
    },
    cacheKey,
    clearCacheKeys,
    forceCache = false,
    version,
    body,
    responseType = RESPONSE_TYPE.JSON,
    optOutLoadingIndicator = true,
    updateNotificationDetails,
    signal,
  }) {
    await initReduxStore();

    const options = {
      method,
      path: Object.keys(queryParams).length
        ? this.__buildPath(path, queryParams, queryArrayFormat)
        : path,
      replacements,
      headers,
      version,
      cacheKey,
      clearCacheKeys,
      forceCache,
      body,
      responseType,
      optOutLoadingIndicator,
      updateNotificationDetails,
      signal,
    };

    const cacheResult = this.__loadFromCache(options);
    if (cacheResult) return cacheResult;

    if (this.maxParallelism < 1) {
      return this.__executeRequest(options);
    }

    return new Promise((resolve, reject) => {
      this.pendingRequests.push(async () => {
        try {
          resolve(await this.__executeRequest(options));
        } catch (e) {
          reject(e);
        }
      });

      this.__processWithParallelism();
    });
  }

  __buildPath(path, queryParams, queryArrayFormat) {
    return `${path}?${querystring.stringify(
      Object.fromEntries(
        Object.entries(queryParams).map(([key, value]) => {
          if (Array.isArray(value)) {
            if (queryArrayFormat === 'bracket') {
              return [`${key}[]`, value.length ? value : null];
            }
            return [key, value.join(',')];
          }

          return [key, value];
        }),
      ),
    )}`;
  }

  __loadFromCache({ method, cacheKey, forceCache }) {
    const useCache = method === 'GET' || forceCache;

    if (useCache && cacheKey && this.requestCache.get(cacheKey)) {
      return this.requestCache.get(cacheKey);
    }

    return null;
  }

  _resolveUrl({ path, version }) {
    return MICROSERVICE_REQUEST_FUNCS[this.microservice](
      version,
      path,
      this.useTenant,
    );
  }

  __executeRequest(options) {
    const cacheResult = this.__loadFromCache(options);
    if (cacheResult) return cacheResult;

    const {
      method,
      path,
      replacements,
      headers,
      version,
      cacheKey,
      clearCacheKeys,
      forceCache,
      body,
      responseType,
      optOutLoadingIndicator,
      signal,
    } = options;

    let state = getStore().getState();

    const extraHeaders = {};

    if (state.session && state.session.item) {
      extraHeaders['X-ACCESS-TOKEN'] =
        state.session.item.accessToken ||
        (state.session.item.idToken && state.session.item.idToken.jwtToken);
    }

    const initHash = state.route && state.route.hash;

    const url = this._resolveUrl({ path, version, replacements });

    const stringBody =
      body &&
      !(body instanceof Blob || body instanceof FormData) &&
      typeof body !== 'string'
        ? JSON.stringify(body)
        : body;

    addToApiCallLog({ method, url, body });

    const result = fetch(url, {
      method,
      ...(body ? { body: stringBody } : {}),
      headers: { ...headers, ...extraHeaders },
      ...(signal ? { signal } : {}),
    })
      .then(async response => {
        state = getStore().getState();

        if (shouldRedirectForUserSecurityAccessError(response)) {
          const currentHash = state.route && state.route.hash;
          return handleRedirect(initHash, currentHash);
        }

        if (!response.ok) {
          const errorMessage = `Error occurred while processing the request for ${url}. status: ${
            response.status
          }`;
          const error = new Error(errorMessage);
          error.response = response;
          error.statusCode = response.status;

          try {
            const errorJson = await response.json();
            error.message += errorJson.message;
            error.responseBody = errorJson;
            throw error;
          } catch (e) {
            throw error;
          }
        }

        if (options.updateNotificationDetails) {
          updated(options.updateNotificationDetails);
        }

        return this.__processResult(response, responseType);
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        if (!optOutLoadingIndicator) {
          getStore().dispatch(endRequest());
        }
      });

    if (clearCacheKeys) {
      clearCacheKeys.forEach(x => {
        this.clearCacheKey(x);
      });
    }

    if (cacheKey) {
      const useCache = method === 'GET' || forceCache;

      if (useCache) {
        this.requestCache.set(cacheKey, result);
      } else {
        this.clearCacheKey(cacheKey);
      }
    }

    if (!optOutLoadingIndicator) {
      getStore().dispatch(startRequest());
    }

    return result;
  }

  async __processResult(res, responseType) {
    if (responseType === RESPONSE_TYPE.OBJECT_URL) {
      const blob = await res.blob();

      const contentDisposition = res.headers.get('Content-Disposition');

      const fileName =
        contentDisposition && contentDisposition.includes('filename=')
          ? contentDisposition.match(/filename="(.+)"/)[1]
          : null;

      return { url: () => URL.createObjectURL(blob), fileName, res };
    }

    if (responseType === RESPONSE_TYPE.DATA_URL) {
      const blob = await res.blob();

      return readDataUrlFromBlob(blob);
    }

    if (responseType === RESPONSE_TYPE.JSON) {
      const t = await res.text();
      return t ? JSON.parse(t) : t;
    }

    return res;
  }

  __processWithParallelism() {
    if (this.processing >= this.maxParallelism) return;

    this.processing += 1;

    const runNextRequest = async () => {
      if (!this.pendingRequests.length) {
        this.processing -= 1;
        return Promise.resolve();
      }

      await this.pendingRequests.shift()();
      return runNextRequest();
    };

    runNextRequest();
  }
}
