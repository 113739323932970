import '../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_3,
  CSS_COLOR_GREY_5,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  card: {
    id: 'card',
  },
  icon: {
    id: 'icon',
  },
  title: {
    id: 'title',
  },
};
class NebSelectionCard extends LitElement {
  static get properties() {
    return {
      icon: String,
      title: String,
      name: String,
      disabled: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.disabled = false;
    this.icon = '';
    this.title = '';
    this.name = '';

    this.onClick = () => {};
  }

  initHandlers() {
    this.__handlers = {
      click: () => {
        if (!this.disabled) {
          this.onClick(this.name);
        }
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: auto;
        }

        :host([disabled]) .container {
          cursor: default;
          opacity: 0.67;
        }

        :host([disabled]) .container:hover {
          background: ${CSS_COLOR_WHITE};
        }

        :host([disabled]) .container-text,
        :host([disabled]) .container-icon {
          opacity: 0.67;
        }

        .container {
          background: ${CSS_COLOR_WHITE};
          padding: 15px;
          display: grid;
          grid-template-columns: 4fr 1fr;
          border-radius: 4px;
          height: 100%;
          box-shadow: 0 1px 3px #00000033;
          cursor: pointer;
        }

        .container-card {
          justify-content: space-between;
          display: flex;
          flex-direction: row;
        }

        .container:hover {
          background: ${CSS_COLOR_GREY_3};
        }

        .container-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .container-title {
          display: flex;
          align-items: center;
        }

        .container-icon {
          display: flex;
          place-self: center;
          justify-self: end;
        }

        .title {
          font-size: 14px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-right: 5px;
        }

        .icon {
          height: 28px;
          width: 28px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([icon-big]) .icon {
          height: 50px;
          width: 50px;
        }

        .icon-chevron {
          height: 24px;
          width: 24px;
          fill: ${CSS_COLOR_GREY_5};
        }
      `,
    ];
  }

  __renderTitle() {
    return this.title
      ? html`
          <div class="container-title">
            <div id="${ELEMENTS.title.id}" class="title">${this.title}</div>
            <neb-icon class="icon-chevron" icon="neb:chevronRight"></neb-icon>
          </div>
        `
      : '';
  }

  __renderIcon() {
    return this.icon
      ? html`
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon"
            icon="neb:${this.icon}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container" @click="${this.__handlers.click}">
        <div id="${ELEMENTS.card.id}" class="container-card">
          <div class="container-text">
            ${this.__renderTitle()} <slot></slot>
          </div>
        </div>
        <div class="container-icon">${this.__renderIcon()}</div>
      </div>
    `;
  }
}
window.customElements.define('neb-selection-card', NebSelectionCard);
