import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-selection-card';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_SPACING, layoutStyles } from '../../../styles';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import {
  CLEARINGHOUSE_ITEMS,
  CLEARINGHOUSES,
  hasByocAddons,
  CLEARINGHOUSE_PRESETS,
} from '../../../utils/clearinghouse-settings';

export const ELEMENTS = {
  header: { id: 'header' },
  cards: { selector: 'neb-selection-card' },
  selectCardChc: { id: 'select-card-chc' },
  selectCardTriZetto: { id: 'select-card-trizetto' },
  selectCardWaystar: { id: 'select-card-waystar' },
  selectCardOther: { id: 'select-card-other' },
};

class OverlayAddClearinghouse extends Overlay {
  static get properties() {
    return {
      model: Object,
      __byocAddons: Object,
      __hasCTVerify: Boolean,
      payerTableConfig: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .grid {
          padding: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
        }

        .content {
          width: 1024px;
        }
      `,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__byocAddons = await hasByocAddons();
    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  initState() {
    super.initState();

    this.__byocAddons = {};
    this.payerTableConfig = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addClearinghouse: async clearinghouse => {
        const item = {
          partner: clearinghouse,
          ...(CLEARINGHOUSE_PRESETS[clearinghouse]
            ? {
                sftpHost: CLEARINGHOUSE_PRESETS[clearinghouse].sftpHost,
                sftpPort: CLEARINGHOUSE_PRESETS[clearinghouse].sftpPort,
                sftpPathClaim:
                  CLEARINGHOUSE_PRESETS[clearinghouse].sftpPathClaim,
                sftpPathEra: CLEARINGHOUSE_PRESETS[clearinghouse].sftpPathEra,
                sftpPathAcknowledgement:
                  CLEARINGHOUSE_PRESETS[clearinghouse].sftpPathAcknowledgement,
                clearinghouseIdentifierId:
                  CLEARINGHOUSE_PRESETS[clearinghouse]
                    .clearinghouseIdentifierId,
                clearinghouseEtin:
                  CLEARINGHOUSE_PRESETS[clearinghouse].clearinghouseEtin,
                clearinghouseApplicationCode:
                  CLEARINGHOUSE_PRESETS[clearinghouse]
                    .clearinghouseApplicationCode,
                practiceIdentifierIdPlaceholder:
                  CLEARINGHOUSE_PRESETS[clearinghouse]
                    .practiceIdentifierIdPlaceholder,
                practiceEtinPlaceholder:
                  CLEARINGHOUSE_PRESETS[clearinghouse].practiceEtinPlaceholder,
                practiceApplicationCodePlaceholder:
                  CLEARINGHOUSE_PRESETS[clearinghouse]
                    .practiceApplicationCodePlaceholder,
              }
            : {}),
        };

        const model = {
          ...this.model,
          ...(clearinghouse ? { item } : undefined),
        };

        this.payerTableConfig = {
          showEtin: false,
          isChc: clearinghouse === CLEARINGHOUSES.CHC,
        };

        const result = await openOverlay(OVERLAY_KEYS.CLEARINGHOUSE, {
          ...model,
          payerTableConfig: this.payerTableConfig,
        });

        this.dismiss(result);
      },
    };
  }

  __renderCardChangeHealthcare() {
    const isDisabled =
      (!this.__byocAddons.CT_MAXCLEAR && !this.__hasCTVerify) ||
      this.model.context.existingClearinghouses.includes(CLEARINGHOUSES.CHC);

    return html`
      <neb-selection-card
        id="${ELEMENTS.selectCardChc.id}"
        title="${CLEARINGHOUSES.CHC}"
        name="${CLEARINGHOUSES.CHC}"
        .onClick="${this.handlers.addClearinghouse}"
        ?disabled="${isDisabled}"
      >
        <p>
          <strong>CT MaxClear & CT Verify</strong> - Request insurance
          eligibility, submit claims, receive claim status, and process ERAs
          with Change Healthcare.
        </p>
      </neb-selection-card>
    `;
  }

  __renderCardTriZetto() {
    const isDisabled = !this.__byocAddons.CT_PROCLEAR_TRIZETTO;

    return html`
      <neb-selection-card
        id="${ELEMENTS.selectCardTriZetto.id}"
        title="${CLEARINGHOUSES.TRIZETTO}"
        name="${CLEARINGHOUSES.TRIZETTO}"
        .onClick="${this.handlers.addClearinghouse}"
        ?disabled="${isDisabled}"
      >
        <p>
          <strong>CT ProClear - TriZetto</strong> - Submit claims and process
          ERAs via direct integration with TriZetto.
        </p>
      </neb-selection-card>
    `;
  }

  __renderCardWaystar() {
    const isDisabled = !this.__byocAddons.CT_PROCLEAR_WAYSTAR;

    return html`
      <neb-selection-card
        id="${ELEMENTS.selectCardWaystar.id}"
        title="${CLEARINGHOUSES.WAYSTAR}"
        name="${CLEARINGHOUSES.WAYSTAR}"
        .onClick="${this.handlers.addClearinghouse}"
        ?disabled="${isDisabled}"
      >
        <p>
          <strong>CT ProClear - Waystar</strong> - Submit claims and process
          ERAs via direct integration with Waystar.
        </p>
      </neb-selection-card>
    `;
  }

  renderContent() {
    const flexClearMessage = `Enable the ability to submit claims and process ERAs from ${CLEARINGHOUSE_ITEMS.flatMap(
      item => ` ${item}`,
    )}, and any
    clearinghouse that can consume X12 5010 EDI files.`;
    return html`
      <div class="grid">
        <div>
          <neb-popup-header
            id="${ELEMENTS.header.id}"
            title="Add Clearinghouse"
            .onCancel="${this.handlers.dismiss}"
            showCancelButton
          ></neb-popup-header>
        </div>

        ${this.__renderCardChangeHealthcare()} ${this.__renderCardTriZetto()}
        ${this.__renderCardWaystar()}

        <neb-selection-card
          id="${ELEMENTS.selectCardOther.id}"
          title="Other Clearinghouses"
          .onClick="${this.handlers.addClearinghouse}"
          ?disabled="${!this.__byocAddons.CT_FLEXCLEAR}"
        >
          <p>${flexClearMessage}</p>
        </neb-selection-card>
      </div>
    `;
  }
}

customElements.define('neb-overlay-add-clearinghouse', OverlayAddClearinghouse);
