import { openPopup } from '@neb/popup';
import removeAccents from 'remove-accents';

import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { MERCHANT_PROVIDERS } from '../../../packages/neb-utils/constants';
import { isProductionEnvironment } from '../../../packages/neb-utils/env';

export const ADD_PAYMENT_METHOD_TEMPLATE = {
  title: 'Add Payment Method',
  subheader:
    'Enter card details to store this card as a payment method for future transactions for this patient.',
  message: '',
  amount: '',
  confirmLabel: 'Save Payment Method',
  transactionAmount: '0',
  holderType: 'patient',
};

export const openPayfacIframePopup = model =>
  openPopup(POPUP_RENDER_KEYS.PAYFAC_IFRAME, model);

export const openPayfacReaderPopup = model =>
  openPopup(POPUP_RENDER_KEYS.PAYFAC_READER, model);

export const FISERV_IFRAME_CSS = `
  html, body {
    background: transparent;
    margin: 0;
    padding: 0;
  }

  #tokenform {
    padding-left: 20px;
    padding-top: 40px;
    width:484px;
    height:246px;
    color:white;
    box-sizing: border-box;
  }

  input {
    background: transparent;
    height:40px;
    font-size:16px;
    font-weight:bold;
    outline:none;
    border:none;
    border-bottom: 1px solid white;
    color: white;
    font-family:"Open Sans",sans-serif;
    letter-spacing: 2px;
    padding: 1px 2px;
    border-radius: 0;
  }

  input.error {
    border-bottom: 1px solid red;
  }

  input:focus {
    border-bottom: 1px solid #0daaff;
  }

  #ccnumfield {
    width:448px;
    letter-spacing: 5px;
    margin-bottom: 36px;
  }

  #cccvvfield {
    width:90px;
  }

  #ccexpiryfieldmonth {
    width:28px;
    margin-left: 242px;
  }

  #ccexpiryfieldyear {
    width:56px;
  }
`
  .replace(/\s+/g, ' ')
  .trim();

export const createFiservIframeUrl = () => {
  // for the Fiserv Iframe Tokenizer's docs go to README-Fiserv.md

  const FISERV_IFRAME_BASE_URL = isProductionEnvironment()
    ? 'https://chiro.cardconnect.com/itoke/ajax-tokenizer.html'
    : 'https://chiro-uat.cardconnect.com/itoke/ajax-tokenizer.html';

  const url = new URL(FISERV_IFRAME_BASE_URL);

  url.searchParams.append('useexpiry', 'true');
  url.searchParams.append('useexpiryfield', 'true');
  url.searchParams.append('formatinput', 'true');
  url.searchParams.append('usecvv', 'true');
  url.searchParams.append('sendcardtypingevent', 'true');
  url.searchParams.append('invalidcreditcardevent', 'true');
  url.searchParams.append('invalidcvvevent', 'true');
  url.searchParams.append('cardnumbernumericonly', 'true');
  url.searchParams.append('invalidexpiryevent', 'true');
  url.searchParams.append('expirylabel', '');
  url.searchParams.append('cardlabel', '');
  url.searchParams.append('cvvlabel', '');
  url.searchParams.append('orientation', 'horizontal');
  url.searchParams.append('cardinputmaxlength', '19');
  url.searchParams.append('placeholdermonth', 'MM');
  url.searchParams.append('placeholderyear', 'YYYY');
  url.searchParams.append('autofocus', 'true');
  url.searchParams.append('css', FISERV_IFRAME_CSS);

  return url;
};

// eslint-disable-next-line complexity
export const getCardType = maskedCardNumber => {
  switch (maskedCardNumber.substring(0, 2)) {
    case '34':
    case '37':
      return 'AMEX';

    case '35':
      return 'JCB';

    case '36':
      return 'DC';

    case '51':
    case '52':
    case '53':
    case '54':
    case '55':
      return 'MC';

    case '60':
    case '62':
    case '64':
    case '65':
      return 'DS';

    default:
      return maskedCardNumber.startsWith('4') ? 'VISA' : '';
  }
};

export const getCardTypeFromToken = token => {
  switch (true) {
    case /^9(2|5)/.test(token):
      return 'MC';
    case /^93(0|6|8|9)/.test(token):
      return 'DC';
    case /^935/.test(token):
      return 'JCB';
    case /^93/.test(token):
      return 'AMEX';
    case /^94/.test(token):
      return 'VISA';
    case /^96/.test(token):
      return 'DS';
    default:
      return '';
  }
};

export const formatCreditCardDescription = maskedCardNumber => {
  const creditCardType = getCardType(maskedCardNumber);

  return creditCardType
    ? `${creditCardType} ${maskedCardNumber}`
    : maskedCardNumber;
};

export const formatFiservElectronicPayment = fiservPayment => ({
  status: fiservPayment.status,
  id: fiservPayment.id,
  saleId: fiservPayment.saleId,
  referenceId: fiservPayment.referenceId,
  card: {
    maskedCardNumber: fiservPayment.maskedCardNumber,
    cardType: getCardType(fiservPayment.maskedCardNumber),
  },
});

export const getMerchantProvider = ({ merchantProvider }) =>
  merchantProvider || MERCHANT_PROVIDERS.GLOBAL_PAYMENTS;

export function filterPaymentMethodsByMerchantProvider(
  paymentMethods,
  merchantAccounts,
  selectedMerchantAccount,
) {
  return paymentMethods.filter(({ merchantAccountId }) => {
    const merchantAccount = merchantAccounts.find(
      ({ id }) => id === merchantAccountId,
    );

    return (
      getMerchantProvider(merchantAccount) ===
      getMerchantProvider(selectedMerchantAccount)
    );
  });
}

export const formatPostal = postal => {
  if (!postal) {
    return null;
  }

  const validPostalRegex = /\d{5}/;

  const firstFiveDigits = postal.substring(0, 5);

  return validPostalRegex.test(firstFiveDigits) ? firstFiveDigits : null;
};

export const formatPatientName = ({ firstName, lastName }) => {
  if (!firstName && !lastName) {
    return null;
  }

  return removeAccents([firstName, lastName].filter(Boolean).join(' ')).slice(
    0,
    30,
  );
};

export const formatPatientInfo = ({
  holderType,
  postal,
  address,
  address2,
  city,
  region,
  firstName,
  lastName,
}) => {
  if (holderType !== 'patient') {
    return {
      postal: null,
      address: null,
      address2: null,
      city: null,
      region: null,
      name: null,
    };
  }

  return {
    postal: formatPostal(postal),
    address: address ? address.slice(0, 30) : null,
    address2: address2 ? address2.slice(0, 30) : null,
    city: city ? city.slice(0, 30) : null,
    region: region ? region.slice(0, 20) : null,
    name: formatPatientName({ firstName, lastName }),
  };
};
