import { css, html, LitElement } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { formatDateTime } from '../../../utils/conversions/date-format';

export const ELEMENTS = {
  description: { id: 'description' },
  status: { id: 'status' },
  subJobs: { id: 'subJobs' },
  type: { id: 'type' },
};

class NebConversionJobPage extends LitElement {
  static get properties() {
    return {
      fetchingSubJobs: Boolean,
      model: Object,
      subJobs: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          flex-direction: column;
          padding: 0 ${CSS_SPACING};
        }

        .info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;

          padding-bottom: 20px;
        }

        .info .row-1 {
          grid-row-start: 1;
        }

        .info .row-2 {
          grid-row-start: 2;
        }

        .info .row-3 {
          grid-row-start: 3;
        }

        .info .column-1 {
          grid-column-start: 1;
        }

        .info .column-2 {
          grid-column-start: 2;
        }

        .field {
          padding: 0 0 20px 0;
        }

        .field label {
          font-size: 12px;
          font-weight: bold;
        }

        .field label:after {
          content: ':';
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
  }

  initState() {
    this.fetchingSubJobs = false;
    this.model = { conversion: {}, job: {} };
    this.subJobs = [];
    this.tableConfig = [
      { flex: '4', key: 'context', label: 'Context' },
      { flex: '3', key: 'description', label: 'Description' },
      { flex: '1', key: 'status', label: 'Status' },
      {
        flex: '2',
        formatter: v => formatDateTime(v),
        key: 'updatedAt',
        label: 'Last Updated',
      },
    ];
  }

  __getDescription() {
    return this.model.job.description || '';
  }

  render() {
    return html`
      <div class="container">
        <div class="info">
          <div class="field row-1 column-1">
            <label>Type</label>
            <div id="${ELEMENTS.type.id}">${this.model.job.type}</div>
          </div>
          <div class="field row-1 column-2">
            <label>Status</label>
            <div id="${ELEMENTS.status.id}">${this.model.job.status}</div>
          </div>
          <div class="field row-2 column-1">
            <label>Description</label>
            <div id="${ELEMENTS.description.id}">
              ${this.__getDescription()}
            </div>
          </div>
        </div>

        <neb-table
          id="${ELEMENTS.subJobs.id}"
          .config="${this.tableConfig}"
          .model="${this.subJobs}"
        ></neb-table>
      </div>
    `;
  }
}

customElements.define('neb-conversion-job-page', NebConversionJobPage);
