import '../../../src/components/misc/neb-icon';
import '../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import {
  updateSpecialistUser,
  updateProviderUser,
} from '../../neb-api-client/src/permissions-api-client';
import { getSignatureFile } from '../../neb-api-client/src/provider-signature-api-client';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../neb-styles/neb-variables';

import Popup from './neb-popup';

export const ELEMENTS = {
  showSignature: {
    id: 'show-signature',
    text: 'Do not show again',
  },
  buttonSign: {
    id: 'button-sign',
    text: 'SIGN ENCOUNTER',
  },
  buttonCancel: {
    id: 'button-cancel',
    text: 'CANCEL',
  },
  iconInfo: {
    id: 'icon-info',
  },
  textInfo: {
    id: 'text-info',
    text: 'Finalized encounters are permanent records of the patient’s chart and cannot be deleted.',
  },
  imageSignature: {
    id: 'image-signature',
  },
  redirectSignature: {
    id: 'redirect-signature',
  },
  textConfirmation: {
    id: 'text-confirmation',
    text: 'Please confirm that you would like to finalize this encounter. Upon signing, all unposted charges will be posted to the ledger. Click “Sign Encounter” to continue, or “Cancel” to return to the Encounter.',
  },
};
export const ROUTE_SIGNATURE_PAGE = '#/settings/provider-signature';
const TITLE_CONFIRM_SIGN = 'Confirm Encounter Signing';

class NebPopupEncounterSign extends Popup {
  static get properties() {
    return {
      signatureImage: {
        type: String,
      },
      showSignature: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 40%;
        }

        :host([layout='small']) {
          width: 100%;
        }

        .container {
          display: block;
        }

        .color-highlight {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .info {
          display: flex;
          justify-content: left;
        }

        .info-icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          height: 20px;
          flex: 0.15 0 0;
          margin-left: -5px;
        }

        .info-text {
          flex: 2 0 0;
        }

        :host([layout='small']) .info-icon {
          margin-right: 10px;
        }

        :host([layout='medium']) .skip-sign-checkbox {
          margin-bottom: ${CSS_SPACING};
        }

        .signature-image {
          max-height: 122px;
          max-width: 100%;
        }

        .redirect-signature:link,
        .redirect-signature:visited {
          text-decoration: underline;
        }

        .sign-button {
          padding-right: 5px;
        }

        .cancel-button {
          padding-left: 2px;
        }

        .skip-sign-checkbox {
          display: inline-block;
        }

        .button-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          white-space: nowrap;
          flex-flow: wrap-reverse;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.signatureImage = '';
    this.title = TITLE_CONFIRM_SIGN;
    this.showSignature = false;
    this.practiceUser = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.sign = async () => {
      await this.__handleSkipSignMessage();
      this.onClose({
        accepted: true,
      });
    };

    this.handlers.cancel = () =>
      this.onClose({
        accepted: false,
      });

    this.handlers.showSignature = () => {
      this.showSignature = !this.showSignature;
    };
  }

  async modelChanged() {
    this.signatureImage = await getSignatureFile(this.model.signerId);
  }

  async __handleSkipSignMessage() {
    if (!this.model.practiceUser) return;

    const practiceUser = this.model.practiceUser || {};

    if (this.showSignature === practiceUser.skipSignMessage) return;

    practiceUser.skipSignMessage = this.showSignature;
    practiceUser.organizationNPI = practiceUser.organizationNPI || null;

    if (practiceUser.type === 'provider') {
      await updateProviderUser(practiceUser);
    }

    if (practiceUser.type === 'specialist') {
      await updateSpecialistUser(practiceUser);
    }
  }

  __renderInfo() {
    return html`
      <neb-icon
        id="${ELEMENTS.iconInfo.id}"
        class="info-icon"
        icon="neb:info"
      ></neb-icon>
      <div id="${ELEMENTS.textInfo.id}" class="info-text">
        ${ELEMENTS.textInfo.text}
      </div>
    `;
  }

  __renderCheckBox() {
    const type = this.model.practiceUser && this.model.practiceUser.type;
    return ['provider', 'specialist'].includes(type)
      ? html`
          <neb-checkbox
            class="skip-sign-checkbox"
            id="${ELEMENTS.showSignature.id}"
            label="${ELEMENTS.showSignature.text}"
            .onChange="${this.handlers.showSignature}"
            ?checked="${this.showSignature}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderSignature() {
    return html`
      <img
        id="${ELEMENTS.imageSignature.id}"
        class="signature-image"
        src="${this.signatureImage}"
      />
    `;
  }

  __renderNoSignature() {
    return html`
      No signature on file. A signature can be added in the
      <a
        id="${ELEMENTS.redirectSignature.id}"
        class="color-highlight redirect-signature"
        href="${ROUTE_SIGNATURE_PAGE}"
      >
        Provider Signatures</a
      >
      settings page.
    `;
  }

  __renderButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonSign.id}"
        class="sign-button"
        .label="${ELEMENTS.buttonSign.text}"
        role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.sign}"
      ></neb-button>

      <neb-button
        id="${ELEMENTS.buttonCancel.id}"
        class="cancel-button"
        role="${BUTTON_ROLE.CANCEL}"
        .label="${ELEMENTS.buttonCancel.text}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-container">
        <div class="spacer color-highlight info">${this.__renderInfo()}</div>

        <div
          id="${ELEMENTS.textConfirmation.id}"
          class="spacer confirmation-text"
        >
          ${ELEMENTS.textConfirmation.text}
        </div>

        <div class="spacer color-highlight signature-image-container">
          ${this.signatureImage
            ? this.__renderSignature()
            : this.__renderNoSignature()}
        </div>

        <div class="button-container">
          <div class="button-column">${this.__renderButtons()}</div>
          <div class="button-column">${this.__renderCheckBox()}</div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-encounter-sign', NebPopupEncounterSign);
