import pako from 'pako';

export const readDataUrlFromBlob = blob =>
  new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = _e => resolve(reader.result);

    reader.readAsDataURL(blob);
  });

export const readBlobFromDataUrl = async dataUrl =>
  (await fetch(dataUrl)).blob();

const __loadImageFromDataUrl = dataUrl =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve();

    img.onerror = e => reject(e);

    img.src = dataUrl;
  });

export const validateImage = async dataUrl => {
  try {
    await __loadImageFromDataUrl(dataUrl);
  } catch (err) {
    console.error(err);
    return false;
  }

  return true;
};

export const validateImageResolution = async (
  file,
  { maxWidth = 3840, maxHeight = 2160 } = {},
) => {
  const { type } = file;
  const ALLOWED_IMAGE_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

  if (ALLOWED_IMAGE_FILE_TYPES.includes(type)) {
    const img = new Image();

    img.src = window.URL.createObjectURL(file);

    await img.decode();

    return img.width <= maxWidth && img.height <= maxHeight;
  }

  return true;
};

export const validateBlob = (file, maxFileSize, allowedFileTypes) => {
  const type =
    file.type || (file.name && file.name.slice(file.name.lastIndexOf('.') + 1));
  return file.size <= maxFileSize && allowedFileTypes.includes(type);
};

export const createObjectURL = async dataUrl => {
  const blob = await readBlobFromDataUrl(dataUrl);
  const windowUrl = window.URL || window.webkitURL;
  return windowUrl.createObjectURL(blob);
};

export const getMimeTypeFromDataUrl = dataUrl => {
  const trimmed = dataUrl.replace('data:', '');
  return trimmed.substring(0, trimmed.indexOf(';'));
};

export const dataURLtoFile = async dataUrl => {
  const type = getMimeTypeFromDataUrl(dataUrl);
  const blob = await readBlobFromDataUrl(dataUrl);
  const filename = `file.${type.split('/')[1]}`;
  return new File([blob], filename, {
    type,
  });
};

export const getNewURLforGzipFile = async url => {
  const blob = await readBlobFromDataUrl(url);
  const encodedText = pako.gzip(await blob.text());
  const encodedBlob = new Blob([encodedText]);
  const windowUrl = window.URL || window.webkitURL;
  return windowUrl.createObjectURL(encodedBlob);
};

export const downloadURLtoFile = (url, fileName) => {
  const anchorElem = document.createElement('a');
  document.body.appendChild(anchorElem);
  anchorElem.download = fileName;
  anchorElem.href = url;
  anchorElem.click();
  anchorElem.remove();
  setTimeout(() => {
    try {
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  }, 100);
};
