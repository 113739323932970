export const arrayToSentence = words => {
  switch (words.length) {
    case 0:
      return '';

    case 1:
      return words[0];

    case 2:
      return `${words[0]} and ${words[1]}`;

    default:
      const copy = [...words];
      copy[copy.length - 1] = `and ${copy[copy.length - 1]}`;
      return copy.join(', ');
  }
};
