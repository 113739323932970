import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { currency } from '../../neb-utils/masks';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  buttonYes: { id: 'button-yes' },
  buttonNo: { id: 'button-no' },
  inputCharges: { id: 'input-charges' },
};

class NebPopupClaimOutsideLabs extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .container-buttons {
          display: flex;
          align-items: baseline;
          padding-bottom: 12px;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        if (name === 'isOutsideLab' && !value) {
          this.__state = {
            ...this.__state,
            isOutsideLab: false,
            outsideLabChargesAmount: '$0.00',
          };
        } else {
          this.__state = { ...this.__state, [name]: value };
        }
      },
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = {
        isOutsideLab: this.model.isOutsideLab,
        outsideLabChargesAmount: this.model.outsideLabChargesAmount,
      };
    }
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        <neb-radio-button
          id="${ELEMENTS.buttonYes.id}"
          class="spacing"
          label="Yes"
          name="isOutsideLab"
          .value="${true}"
          .checked="${this.__state.isOutsideLab}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.buttonNo.id}"
          class="spacing"
          label="No"
          name="isOutsideLab"
          .value="${false}"
          .checked="${!this.__state.isOutsideLab}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>

        <neb-textfield
          id="${ELEMENTS.inputCharges.id}"
          label="Charges"
          name="outsideLabChargesAmount"
          maxlength="11"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${this.__state.outsideLabChargesAmount}"
          .onChange="${this.handlers.change}"
          ?disabled="${!this.__state.isOutsideLab}"
        ></neb-textfield>
      </div>
    `;
  }
}

customElements.define('neb-popup-claim-outside-labs', NebPopupClaimOutsideLabs);
