import '../../neb-document/src/components/neb-document-content';

import { html, css } from 'lit';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  content: {
    id: 'content',
  },
};

class NebPopupDocument extends NebPopup {
  static get properties() {
    return {
      state: Object,
    };
  }

  initState() {
    super.initState();
    this.showHeader = false;
    this.state = null;
    this.model = {
      patientId: '',
      touchDevice: false,
      data: {
        id: null,
        name: '',
        note: '',
        date: null,
        file: null,
        uploadDate: null,
        thumbnail: null,
        tags: [],
        encounterId: '',
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.close = result => this.onClose(result);

    this.handlers.updateModel = data => {
      this.state = { ...data };
    };
  }

  modelChanged() {
    if (!this.state) {
      this.state = { ...this.model.data };
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 440px;
          height: auto;
          margin: 0;
        }

        .content {
          margin: 0;
          width: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-document-content
        id="${ELEMENTS.content.id}"
        class="content"
        .model="${this.state}"
        .initialModel="${this.model.data}"
        .patientId="${this.model.patientId}"
        .touchDevice="${this.model.touchDevice}"
        .onClose="${this.handlers.close}"
        .onUpdateModel="${this.handlers.updateModel}"
      ></neb-document-content>
    `;
  }
}

customElements.define('neb-popup-document', NebPopupDocument);
