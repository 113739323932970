import {
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../neb-styles/neb-variables';

export function getTextWidthFromElement(str, el) {
  el.style.fontFamily = CSS_FONT_FAMILY;
  el.style.fontSize = CSS_FONT_SIZE_BODY;
  el.style.fontWeight = CSS_FONT_WEIGHT_BOLD;
  el.style.height = 'auto';
  el.style.width = 'auto';
  el.style.opacity = 0;
  el.style.position = 'absolute';
  el.style.left = 0;
  el.style.top = '-100px';
  el.style.whiteSpace = 'no-wrap';
  el.innerHTML = str;
  return Math.ceil(el.clientWidth) + 1;
}

export function getTextWidth(str) {
  const el = document.createElement('span');
  document.body.appendChild(el);
  const width = getTextWidthFromElement(str, el);
  document.body.removeChild(el);

  return width;
}
