import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../filters/neb-filters-failed-payments';
import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import { reprocessScheduledPayment } from '../../../packages/neb-api-client/src/payments/scheduled-payments-api-client';
import * as scheduledPaymentsApiClient from '../../../packages/neb-api-client/src/payments/scheduled-payments-api-client';
import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { objToName } from '../../../packages/neb-utils/formatters';
import { CSS_SPACING, CSS_COLOR_GREY_1 } from '../../styles';
import * as nebulaFilters from '../../utils/filters';
import * as paymentAlertFilters from '../../utils/filters/payment-alerts';
import {
  DISMISS_PAYMENT_ERROR,
  REPROCESS_ERROR,
  REPROCESS_SUCCESS,
} from '../../utils/user-message';
import {
  CONFIG,
  TABLE_ACTION,
} from '../tables/ledger/payments/neb-table-failed-payments';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  failedPaymentsFilter: { id: 'failed-payments-filter' },
  iconClose: { id: 'icon-close' },
};

const HIDE = null;

class CollectionPageFailedPayments extends CollectionPage {
  static get config() {
    return {
      ...CollectionPage.config,
      showInactiveFilter: false,
      tableConfig: CONFIG,
      initialSortKey: 'scheduled',
      pageSize: 10,
      pluralName: 'Payment Alerts',
    };
  }

  static get properties() {
    return {
      hideDismissedAlerts: Boolean,
      __dismissingStatuses: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .container-header {
          display: flex;
          justify-content: space-between;
        }

        .header {
          margin-top: ${CSS_SPACING};
        }

        .container-header-actions {
          display: grid;
          justify-items: end;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          margin-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.onCancel = () => {};
    this.hideDismissedAlerts = true;
    this.__dismissingStatuses = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: () => this.onCancel(),
      applyFilters: model => {
        Object.entries(model).forEach(([k, v]) => {
          if (k === 'paymentType') {
            this.service.updateFilter('scheduleType', v);
          }

          if (k === 'scheduled') {
            this.service.updateFilter('scheduled', v);
          }

          if (k === 'hideDismissedAlerts') {
            this.service.updateFilter('hideDismissedAlerts', v);
          }
        });
      },
      sort: (_, result) => this.service.setSortParams(result[0]),

      selectActionIcon: async (action, item) => {
        switch (action) {
          case TABLE_ACTION.CHEVRON:
            await this.__openFailedScheduledPaymentOverlay(item);
            break;

          case TABLE_ACTION.REFRESH:
            await this.__refreshFailedPayment(item);
            break;

          case TABLE_ACTION.DISMISS:
            await this.__dismissFailedPayment(item);

            break;
          default:
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.service
      .addFilter('scheduleType', paymentAlertFilters.paymentScheduleType(), '')
      .addFilter('scheduled', nebulaFilters.dateRange('scheduled'), {
        from: null,
        to: null,
      })
      .addFilter(
        'hideDismissedAlerts',
        paymentAlertFilters.dismissPaymentAlerts(),
        true,
      );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.service.removeFilter('paymentType');
    this.service.removeFilter('scheduled');
    this.service.removeFilter('hideDismissedAlerts');
  }

  async fetch() {
    const failedPayments = await scheduledPaymentsApiClient.getFailedPayments();

    if (failedPayments && failedPayments.length) {
      const patientIds = failedPayments.map(item => item.patientId);
      const patients = await patientApiClient.fetchSome(
        patientIds,
        {},
        false,
        false,
        true,
      );

      const formattedFailedPayments = failedPayments.map(payment => {
        const patient = patients.find(p => p.id === payment.patientId);

        return {
          ...payment,
          patientName: objToName(patient.name, { reverse: true }),
        };
      });

      this.__dismissingStatuses = new Array(failedPayments.length).fill(false);
      return formattedFailedPayments;
    }

    return [];
  }

  async __openFailedScheduledPaymentOverlay(item) {
    const result = await openOverlay(OVERLAY_KEYS.FAILED_SCHEDULED_PAYMENT, {
      type: item.scheduleType,
      patientId: item.patientId,
      item: {
        name: item.name,
        amount: item.amount,
        paymentTypeId: item.paymentTypeId,
        patientPackageId: item.patientPackageId,
        paymentMethodId: item.paymentMethodId,
        merchantAccountId: item.merchantAccountId,
        message: item.message,
      },
      schedule: { id: item.scheduleId },
    });

    if (result) {
      await this.__setItems();
    }
  }

  async __refreshFailedPayment(item) {
    const result = await openPopup(POPUP_RENDER_KEYS.TRANSACTION_PROCESSING, {
      promise: reprocessScheduledPayment({
        patientId: item.patientId,
        scheduleId: item.scheduleId,
        paymentMethodId: item.paymentMethodId,
        merchantAccountId: item.merchantAccountId,
      }),
    });

    if (result.status === 'Failed') {
      store.dispatch(openError(REPROCESS_ERROR));
    } else {
      store.dispatch(openSuccess(REPROCESS_SUCCESS));
      await this.__setItems();
    }
  }

  async __dismissFailedPayment(item) {
    const index = this.__tableState.pageItems.findIndex(
      payment => payment.scheduleId === item.scheduleId,
    );

    if (!this.__dismissingStatuses[index]) {
      this.__dismissingStatuses[index] = true;
      const dismissAction = item.dismissedAt === null ? 'dismiss' : 'undismiss';

      try {
        await scheduledPaymentsApiClient.dismissFailedPayment(
          item.scheduleId,
          dismissAction,
        );

        await this.__setItems();
      } catch (error) {
        store.dispatch(openError(DISMISS_PAYMENT_ERROR));
        console.log(error);
      }
    }
  }

  async __setItems() {
    const items = await this.fetch();

    this.service.setItems(items);
  }

  renderHeader() {
    return !this.getConfig().hideHeader
      ? html`
          <div class="container-header">
            <neb-page-collection-header
              id="${ELEMENTS.header.id}"
              class="header"
              .hideInactive="${HIDE}"
              .layout="${this.layout}"
              .title="${this.getTitle()}"
              .description="${this.getConfig().description}"
              .searchLabel="${this.getConfig().searchLabel}"
              .searchText="${this.__tableState.searchText}"
              .onCancel="${this.handlers.cancel}"
              .onHideInactive="${this.handlers.hideInactive}"
              .onSearch="${this.handlers.search}"
              ?showCancelButton="${this.getConfig().showCancelButton}"
            ></neb-page-collection-header>

            <div class="container-header-actions">
              <neb-icon
                id="${ELEMENTS.iconClose.id}"
                class="icon-close"
                icon="neb:close"
                @click="${this.handlers.cancel}"
              ></neb-icon>
            </div>
          </div>

          <neb-filters-failed-payments
            id="${ELEMENTS.failedPaymentsFilter.id}"
            class="filters"
            .onApply="${this.handlers.applyFilters}"
          >
          </neb-filters-failed-payments>
        `
      : '';
  }

  renderTable() {
    return html`
      <neb-table-failed-payments
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .model="${this.__tableState.pageItems}"
        .onChange="${this.handlers.tableChange}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onSort="${this.handlers.sort}"
        .onSelectActionIcon="${this.handlers.selectActionIcon}"
      ></neb-table-failed-payments>
    `;
  }
}
window.customElements.define(
  'neb-collection-page-failed-payments',
  CollectionPageFailedPayments,
);
