import { store } from '../../../neb-redux/neb-redux-store';
import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'payments' });

export const getScheduledPayments = (patientId, queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/schedule`,
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-scheduled-payments`,
  });

export const getFailedPayments = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: 'failed-payments',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const countScheduledPayments = patientId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/schedule/count`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-scheduled-payments-count`,
  });

export const countScheduledPaymentsByAccount = accountId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `account/${accountId}/schedule/count`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${accountId}-account-scheduled-payments`,
  });

export const countFailedPayments = () =>
  apiClient.makeRequest({
    method: Method.GET,
    path: 'failed-payments/count',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const dismissFailedPayment = async (id, action) => {
  const postBody = { action };
  await apiClient.makeRequest({
    method: Method.PUT,
    path: `failed-payments/${id}/dismiss`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const countPaymentMethodInSchedule = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/schedule/payment-method/${id}/count`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-payment-method-count`,
  });

export const createSinglePayment = (patientId, body) => {
  const postBody = {
    ...body,
    merchantAccountId: body.merchantAccount.id,
    paymentMethodId: body.paymentMethod.id,
    paymentTypeId: body.paymentType.id,
    createdBy: store.getState().session.item.id,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/single-payment`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const getSinglePayment = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/single-payment/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-payment`,
  });

export const updateSinglePayment = (patientId, body) => {
  const { id } = body;

  const postBody = {
    ...body,
    merchantAccountId: body.merchantAccount.id,
    paymentMethodId: body.paymentMethod.id,
    paymentTypeId: body.paymentType.id,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patient/${patientId}/single-payment/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const deleteSinglePayment = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/single-payment/${id}/delete`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createPaymentPlan = (patientId, body) => {
  const postBody = {
    ...body,
    merchantAccountId: body.merchantAccount.id,
    paymentMethodId: body.paymentMethod.id,
    paymentTypeId: body.paymentType.id,
    createdBy: store.getState().session.item.id,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/payment-plan`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const getPaymentPlan = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/payment-plan/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-payment-plan`,
  });

export const updatePaymentPlan = (patientId, body) => {
  const { id } = body;

  const postBody = {
    ...body,
    merchantAccountId: body.merchantAccount.id,
    paymentMethodId: body.paymentMethod.id,
    paymentTypeId: body.paymentType.id,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patient/${patientId}/payment-plan/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const deletePaymentPlan = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/payment-plan/${id}/delete`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createRecurringPayment = (patientId, body) => {
  const postBody = {
    ...body,
    merchantAccountId: body.merchantAccount.id,
    paymentMethodId: body.paymentMethod.id,
    paymentTypeId: body.paymentType.id,
    createdBy: store.getState().session.item.id,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/recurring-payment`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const getRecurringPayment = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/recurring-payment/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-recurring-payment`,
  });

export const updateRecurringPayment = (patientId, body) => {
  const { id } = body;

  const postBody = {
    ...body,
    merchantAccountId:
      body.merchantAccount && body.merchantAccount.id
        ? body.merchantAccount.id
        : body.merchantAccountId,
    paymentMethodId:
      body.paymentMethod && body.paymentMethod.id
        ? body.paymentMethod.id
        : body.paymentMethodId,
    paymentTypeId:
      body.paymentType && body.paymentType.id
        ? body.paymentType.id
        : body.paymentTypeId,
  };

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patient/${patientId}/recurring-payment/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  });
};

export const deleteRecurringPayment = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/recurring-payment/${id}/delete`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getRecurringPaymentByPatientPackageId = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/recurring-payment/subscription/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-recurring-payment-by-package`,
  });

export const deleteRecurringPaymentByPatientPackageId = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/recurring-payment/subscription/${id}/delete`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getRecurringPaymentExpirationDate = (patientId, id) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/recurring-payment/${id}/expiration-date`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-recurring-payment-expiration`,
  });

export const getScheduleStatus = (patientId, queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/schedule/status`,
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams,
    cacheKey: `${patientId}-schedule-status`,
  });

export const reprocessScheduledPayment = ({
  patientId,
  scheduleId,
  merchantAccountId,
  paymentMethodId,
}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patient/${patientId}/schedule/${scheduleId}/process`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ merchantAccountId, paymentMethodId }),
  });

export const markAsPaid = ({ patientId, scheduleId }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patient/${patientId}/schedule/${scheduleId}/mark-as-paid`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getScheduledBalancePayment = async ({ patientId }) => {
  const { scheduledPayment } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patient/${patientId}/schedule/balance-payment`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-scheduled-balance-payment`,
  });

  return scheduledPayment;
};

export const getRelationshipSharedPaymentConflicts = async (
  patientId,
  activeGroup,
) => {
  const conflicts = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patient/${patientId}/group-unlink`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(activeGroup),
  });

  return conflicts;
};
