import { LitElement, html, css } from 'lit';

import { CSS_FONT_WEIGHT_BOLD } from '../../../../neb-styles/neb-variables';
import { CSS_COLOR_BLUE_1 } from '../neb-button';

export const ELEMENTS = {
  picker: { id: 'picker' },
};

class NebPickerColorAdvanced extends LitElement {
  static get properties() {
    return {
      name: String,
      value: String,
    };
  }

  static get styles() {
    return [
      css`
        .picker {
          height: 30px;
          cursor: pointer;
        }

        label {
          cursor: pointer;
          display: inline-block;
          border: 1px solid ${CSS_COLOR_BLUE_1};
          border-radius: 18px;
          height: 100%;
          width: 185px;
          text-align: center;
          line-height: 34px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-transform: uppercase;
          color: ${CSS_COLOR_BLUE_1};
        }

        input[type='color'] {
          vertical-align: middle;
          opacity: 0%;
          margin-right: -55px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.value = '#0CAADC';
    this.name = '';

    this.onChange = () => {};
  }

  initHandlers() {
    this.handlers = {
      change: e => {
        this.onChange({ name: this.name, value: e.target.value });
      },
    };
  }

  render() {
    return html`
      <input
        id="${ELEMENTS.picker.id}"
        type="color"
        class="picker"
        .value="${this.value}"
        @change="${this.handlers.change}"
      />
      <label for="${ELEMENTS.picker.id}">Advanced Colors</label>
    `;
  }
}

customElements.define('neb-picker-color-advanced', NebPickerColorAdvanced);
