import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';

function setEditedField({ name, claimCellData, formService }) {
  if (name === 'acceptAssignment') {
    formService.apply(`claimEditedField.${name}`, true);
  }

  if (name.includes('isEmergency') || name.includes('isFamilyPlanning')) {
    formService.apply(`${claimCellData.name}Edited`, true);
  }
}

export default async ({ claimCellData, state, handlers, formService }) => {
  const fieldNames = claimCellData.name.split('.');
  const value = fieldNames.reduce((item, key) => item[key], state);

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_YES_NO, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: [
      {
        name: claimCellData.name,
        value,
      },
    ],
  });

  if (result) {
    const [name, value] = Object.entries(result)[0];

    setEditedField({ name, claimCellData, formService });

    handlers.change({
      name,
      value,
    });
  }
};
