import '../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_3,
  CSS_COLOR_GREY_5,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  card: { id: 'card' },
  description: { id: 'description' },
  image: { id: 'image' },
  title: { id: 'title' },
};

class NebImageSelectionCard extends LitElement {
  static get properties() {
    return {
      description: String,
      imgSrc: String,
      name: String,
      title: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: ${CSS_SPACING};
        }

        .container {
          background: ${CSS_COLOR_WHITE};
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: 3fr 1fr;
          grid-column-gap: ${CSS_SPACING};
          border-radius: 4px;
          height: 100%;
          box-shadow: 0 1px 3px #00000033;
          cursor: pointer;
        }

        .container:hover {
          background: ${CSS_COLOR_GREY_3};
        }

        .container-title {
          display: flex;
          align-items: center;
        }

        .title {
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-right: 5px;
        }

        .icon-chevron {
          height: 24px;
          width: 24px;
          fill: ${CSS_COLOR_GREY_5};
        }

        .description {
          padding: 10px ${CSS_SPACING} 10px 0;
        }

        .img {
          width: 100%;
          height: auto;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.description = '';
    this.imgSrc = '';
    this.name = '';
    this.title = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this.name),
    };
  }

  __renderTitle() {
    return html`
      <div class="container-title">
        <div id="${ELEMENTS.title.id}" class="title">${this.title}</div>
        <neb-icon class="icon-chevron" icon="neb:chevronRight"></neb-icon>
      </div>
    `;
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.description}
      </div>
    `;
  }

  __renderImage() {
    return this.imgSrc
      ? html`
          <img id="${ELEMENTS.image.id}" class="img" src="${this.imgSrc}" />
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.card.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <div>${this.__renderTitle()} ${this.__renderDescription()}</div>
        ${this.__renderImage()}
      </div>
    `;
  }
}
window.customElements.define('neb-image-selection-card', NebImageSelectionCard);
