import './neb-date-type-item';
import '../controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  addButton: {
    id: 'button-add',
  },
  items: {
    idPrefix: 'item',

    get selector() {
      return `[id|=${this.idPrefix}]`;
    },
  },
};

class NebDateTypeList extends LitElement {
  static get properties() {
    return {
      maxItems: Number,
      name: String,
      label: String,
      model: Array,
      dateTypes: Array,
      errors: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.maxItems = 6;
    this.name = '';
    this.label = 'Add Date';
    this.model = [];
    this.errors = [];
    this.dateTypes = [];

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      add: () => this.onAdd(this.name),
      remove: name => this.onRemove(this.name, Number(name)),
      change: ({ name, value }) =>
        this.onChange({ name: `${this.name}.${name}`, value }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .button-add {
          align-self: flex-start;
        }
      `,
    ];
  }

  getItems(selectedId) {
    const otherSelectedDates = this.model.filter(
      item => item.type.id !== selectedId,
    );
    return this.dateTypes.filter(
      item =>
        !item.id ||
        !otherSelectedDates.find(other => other.type.id === item.id),
    );
  }

  render() {
    return html`
      <div class="container">
        ${this.model.map(
          (item, index) => html`
            <neb-date-type-item
              id="${ELEMENTS.items.idPrefix}-${index}"
              .name="${index}"
              .model="${item}"
              .items="${this.getItems(item.type.id)}"
              .errors="${this.errors[index]}"
              .onRemove="${this.__handlers.remove}"
              .onChange="${this.__handlers.change}"
              ?showRemoveButton="${this.model.length > 1}"
            ></neb-date-type-item>
          `,
        )}
        ${this.model.length < this.maxItems
          ? html`
              <neb-button-action
                id="${ELEMENTS.addButton.id}"
                class="button-add"
                .label="${this.label}"
                .onClick="${this.__handlers.add}"
              ></neb-button-action>
            `
          : ''}
      </div>
    `;
  }
}

window.customElements.define('neb-date-type-list', NebDateTypeList);
