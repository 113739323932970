export const DOCUMENT_TYPE = Object.freeze({
  PDF: 'pdf',
  JPEG: 'jpeg',
});

export const DOCUMENT_TYPES = [DOCUMENT_TYPE.PDF, DOCUMENT_TYPE.JPEG];

export const SCAN_ACTION_WAIT_TIME = Object.freeze({
  AFTER_RESET: 1000,
});

export const SCAN_INTERFACE_TYPE = Object.freeze({
  HIDDEN: '0',
  VISIBLE: '1',
  WEB_ONLY: '2',
  TWAIN_ONLY: '3',
});

export const SCAN_ADD_PAGE_TYPE = Object.freeze({
  AFTER_PAGE: '1',
  BEFORE_PAGE: '2',
  REPLACE_PAGE: '3',
});

export const SCAN_DEFAULT_CURRENT_PAGE = '0';
