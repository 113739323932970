import '../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
} from '../neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  iconClear: { id: 'icon-clear' },
  iconSearch: { id: 'icon-search' },
  input: { id: 'input' },
};

class NebSearch extends LitElement {
  static get properties() {
    return {
      label: String,
      value: String,
      disabled: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__inputEl = null;

    this.label = 'Search...';
    this.value = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.focus(),
      clear: () => this.onChange(''),
      input: e => this.onChange(e.currentTarget.value),
    };
  }

  focus() {
    this.__inputEl.focus();
  }

  firstUpdated() {
    this.__inputEl = this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          flex: 1;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 10px 15px;
        }

        .icon-search {
          display: block;
          width: 28px;
          height: 28px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-clear {
          cursor: pointer;
          display: flex;
          width: 20px;
          height: 20px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .input {
          display: flex;
          flex: 1 0 0;
          padding: 0 10px;

          font-size: ${CSS_FONT_SIZE_BODY};
          border: none;
          outline: none;
        }
      `,
    ];
  }

  __renderClearIcon() {
    return this.value
      ? html`
          <neb-icon
            id="${ELEMENTS.iconClear.id}"
            class="icon icon-clear"
            icon="neb:clear"
            @click="${this.__handlers.clear}"
            ?disabled="${this.disabled}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <neb-icon
          id="${ELEMENTS.iconSearch.id}"
          class="icon icon-search"
          icon="neb:search"
        ></neb-icon>

        <input
          id="${ELEMENTS.input.id}"
          class="input"
          type="text"
          autocomplete="off"
          .placeholder="${this.label}"
          .value="${this.value}"
          @input="${this.__handlers.input}"
          @change="${this.__handlers.change}"
          ?disabled="${this.disabled}"
        />

        ${this.__renderClearIcon()}
      </div>
    `;
  }
}

customElements.define('neb-search', NebSearch);
