import { html, css } from 'lit';

import { getClaimsData } from '../../../../packages/neb-api-client/src/claims';
import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-claims-worklist';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import { SORT_DIR } from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  SUBMISSION_METHOD,
} from '../../../../packages/neb-utils/claims';
import { createServerPatientsCollection } from '../../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  centsToCurrency,
} from '../../../../packages/neb-utils/formatters';
import {
  findDateOfService,
  formatDate,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { CollectionService } from '../../../../packages/neb-utils/services/collection';
import { FetchService } from '../../../../packages/neb-utils/services/fetch';
import { MODE } from '../../../../packages/neb-utils/table';
import { layoutStyles } from '../../../styles';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: { id: 'header' },
  textSearchPatient: { id: 'search-patient' },
  table: { id: 'table' },
  responseInformationHeader: { id: 'response-information-header' },
  subscriberInformationHeader: { id: 'subscriber-information-header' },
  dependentInformationHeader: { id: 'dependent-information-header' },
  providerInfoHeader: { id: 'provider-info-header' },
  claimStatusHeader: { id: 'claim-status-header' },
  claimAmount: { id: 'claim-amount' },
  memberId: { id: 'member-id' },
  serviceDate: { id: 'service-date' },
  subscriberName: { id: 'subscriber-name' },
  subscriberGender: { id: 'subscriber-gender' },
  subscriberDOB: { id: 'subscriber-dob' },
  subscriberGroupNumber: { id: 'subscriber-group-number' },
  statusCode: { id: 'status-code' },
  codeValue: { id: 'code-value' },
  statusDate: { id: 'status-date' },
  statusDescription: { id: 'status-description' },
  descriptionValue: { id: 'description-value' },
  dependentGender: { id: 'dependent-gender' },
  dependentDOB: { id: 'dependent-dob' },
  dependentGroupNumber: { id: 'dependent-group-number' },
  dependentName: { id: 'dependent-name' },
  patientAccountNumber: { id: 'patient-control-number' },
  dateReceived: { id: 'date-received' },
  patientName: { id: 'patient-name' },
  payerName: { id: 'payer-name' },
  serviceLineDetailHeader: { id: 'service-line-detail-header' },
  serviceLineDate: { selector: '.sl-date', class: 'sl-date' },
  serviceLineProcMod: {
    selector: '.sl-procedure-modifiers',
    class: 'sl-procedure-modifiers',
  },
  serviceLineUnits: { selector: '.sl-units', class: 'sl-units' },
  serviceLineBilled: { selector: '.sl-billed', class: 'sl-billed' },
  serviceLinePaid: { selector: '.sl-paid', class: 'sl-paid' },
  serviceLineCode: { selector: '.sl-code', class: 'sl-code' },
  serviceLineDescription: {
    selector: '.sl-description',
    class: 'sl-description',
  },
  serviceLineStatusCode: {
    selector: '.sl-status-code',
    class: 'sl-status-code',
  },
  serviceLineStatusCodeValue: {
    selector: '.sl-status-code-value',
    class: 'sl-status-code-value',
  },
  payerInformationHeader: { id: 'payer-information-header' },
  payerInformationName: { id: 'payer-information-name' },
  payerId: { id: 'payer-id' },
  payerPlanId: { id: 'payer-plan-id' },
  payerContactName: { id: 'payer-contact-name' },
  payerFax: { id: 'payer-fax-number' },
  payerPhone: { id: 'payer-phone-number' },
  pagination: { id: 'pagination' },
  practiceName: { id: 'practice-name' },
  providerName: { id: 'provider-name' },
  etin: { id: 'etin' },
  npi: { id: 'npi' },
};

const CONFIG = [
  {
    key: 'radio',
    label: '',
    flex: css`0 0 40px`,
    truncate: true,
  },
  {
    key: 'dateOfService',
    label: 'Service Date',
    flex: css`1.5 0 0`,
    sortable: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`1 0 0`,
    truncate: true,
    sortable: true,
  },
  {
    key: 'claimNumber',
    label: 'Claim',
    flex: css`1 0 0`,
    truncate: true,
    sortable: true,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1.5 0 0`,
    truncate: true,
  },
  {
    key: 'primaryPayerAlias',
    label: 'Payer',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'primaryPlanName',
    label: 'Plan',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'providerId',
    label: 'Billing Provider',
    flex: css`1 0 0`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`1 0 0`,
    truncate: true,
    sortable: true,
  },
];
class NebFormAbandonedClaimStatus extends NebForm {
  static get properties() {
    return {
      __patientItems: Array,
      __providers: Array,
      __selectedItem: Object,
      __selectedPatient: Object,
      __tableState: Object,
      sortParams: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .bold {
          font-weight: bold;
        }
        .pagination-container {
          display: flex;
          justify-self: flex-end;
        }

        .pagination {
          padding-right: ${CSS_SPACING};
        }

        .grid-7 {
          grid-template-columns: 90px 145px 40px 95px 95px 85px 1fr;
        }

        .info-header {
          padding-top: ${CSS_SPACING};
        }

        .field {
          height: 100%;
        }
      `,
    ];
  }

  static createModel() {
    return {};
  }

  constructor() {
    super();
    this.initServices();
  }

  initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
        onSort: this.handlers.sort,
      },
      {
        pageSize: 10,
        sortParams: this.sortParams,
        hideInactive: false,
      },
    );

    const client = async query => {
      const dosTo = parseDate(this.model.effectiveDate).format('YYYY-MM-DD');
      const claims = await getClaimsData(this.status, {
        ...query,
        patientId: this.__selectedPatient.id,
        includeLedgerData: false,
        submissionMethods: SUBMISSION_METHOD.ELECTRONIC_CLAIMS,
        dosTo,
        statuses: [
          CLAIM_STATUS.TRANSMITTED,
          CLAIM_STATUS.NEEDS_ATTENTION,
          CLAIM_STATUS.DENIED,
          CLAIM_STATUS.REJECTED,
          CLAIM_STATUS.APPROVED,
          CLAIM_STATUS.ERA_RECEIVED,
          CLAIM_STATUS.ERROR,
          CLAIM_STATUS.RECEIVED,
          CLAIM_STATUS.CANCELED_RESUBMIT,
          CLAIM_STATUS.CANCELED_RESUBMITTED,
        ],
      });
      this.__expandFlags = new Array(claims.length).fill(false);

      return {
        count: claims.count,
        data: claims.data.map(claim => ({
          ...claim,
          dateOfService: findDateOfService(claim.claimCharges),
          invoiceNumber: claim.invoice.invoiceNumber,
          lastUpdated: formatDate(claim.lastUpdated),
          primaryPayerId: claim.claimCharges[0].lineItem.primaryPayerId,
          primaryInsuranceId: claim.claimCharges[0].lineItem.primaryInsuranceId,
        })),
      };
    };

    if (this.__service) {
      this.__service.cancel();
    }

    this.__service = new FetchService(
      { onChange: this.handlers.changeState },
      client,
      {
        filterCount: 0,
        pageCount: 0,
        pageSize: 10,
        hideInactive: false,
        sortParams: this.sortParams,
      },
    );
  }

  createSelectors() {
    return {
      children: {
        patient: { clipPristine: true },
      },
    };
  }

  initState() {
    super.initState();

    this.__providers = [];
    this.sortParams = { key: this.__initialSortKey, dir: SORT_DIR.ASC };
    this.__initialSortKey = 'claimNumber';
    this.__patientItems = [];

    this.__selectedItem = {};

    this.__tableState = FetchService.createModel();

    this.onDismissResponse = () => {};

    this.onLinkResponse = () => {};

    this.onClickLink = () => {};

    this.onClose = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      clickLink: name => {
        this.onClickLink(name, {
          claims: this.__tableState.pageItems,
          patient: this.__selectedPatient,
        });
      },
      confirm: () => {
        this.dismiss();
      },
      dismissResponse: () => this.onDismissResponse(),
      changeCollectionState: state => {
        this.__collectionState = state;
      },
      selectPage: pageIndex => this.__service.setPageIndex(pageIndex),
      changeState: state => {
        this.__tableState = state;
      },
      sort: () => {},
      save: () => this.onLinkResponse(this.__selectedItem),
      dismiss: () => this.dismiss(),
      refresh: () => this.__refresh(),
      changeSort: (_name, result) => {
        this.sortParams = result[0];
        this.__service.setPageIndex(0);
        this.__service.setQuery('sortKey', result[0].key);
        this.__service.setQuery('sortDir', result[0].dir);
      },
      radioChanged: item => {
        this.__tableState.pageItems.forEach(claim => {
          claim.radio = false;
        });

        this.__tableState.pageItems[item.name].radio = true;
        this.__tableState = {
          ...this.__tableState,
          pageItems: [...this.__tableState.pageItems],
        };

        this.__selectedItem = this.__tableState.pageItems[item.name];
      },
      patientChange: p => {
        this.__selectedPatient = p.value ? p.value.data : null;
        this.__selectedItem = {};

        if (p.value) {
          this.__service.fetch();
        } else this.__tableState.pageItems = [];
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      requestMorePatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
      closeForm: () => this.onClose(),
    };
  }

  getTableItems() {
    return this.__tableState.pageItems.map(item => ({
      added: this.__selectedItems.some(({ id }) => id === item.id),
      ...item,
    }));
  }

  async load() {
    this.__providers = await getProviderUsers();
    this.__patientService = createServerPatientsCollection({
      onChange: ({ pageItems }) => {
        this.__patientItems = pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
    });

    await this.__patientService.fetchComplete();
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__providers = await getProviderUsers();
  }

  __renderHeader(id, title) {
    return html`
      <neb-header
        id="${id}"
        class="info-header col-header"
        label="${title}"
      ></neb-header>
    `;
  }

  __renderField(fieldName, value, id) {
    return html`
      <div class="field">
        <p class="bold">${fieldName}</p>
        <p id="${id}">${value || '-'}</p>
      </div>
    `;
  }

  __renderServiceField(fieldName, value, selector, index) {
    return html`
      <div class="field">
        <p class="bold">${index === 0 ? fieldName : ''}</p>
        <p class="${selector}">${value || '-'}</p>
      </div>
    `;
  }

  __renderResponseInformation() {
    return html`
      <div class="grid grid-3">
        ${this.__renderField(
          'Date Received',
          parseDate(this.model.effectiveDate).format('MM/DD/YYYY'),
          ELEMENTS.dateReceived.id,
        )}
        ${this.__renderField(
          'Payer',
          this.model.payerName,
          ELEMENTS.payerName.id,
        )}
        ${this.__renderField(
          'Patient Name',
          this.model.patientName,
          ELEMENTS.patientName.id,
        )}
      </div>
    `;
  }

  __renderSubscriber() {
    return html`
      ${this.__renderHeader(
        ELEMENTS.subscriberInformationHeader.id,
        'Subscriber Information',
      )}
      <div class="grid grid-3">
        ${this.__renderField(
          'Name',
          this.model.subscriberName,
          ELEMENTS.subscriberName.id,
        )}
        ${this.__renderField(
          'Gender',
          this.model.subscriberGender,
          ELEMENTS.subscriberGender.id,
        )}
        ${this.__renderField(
          'Date of Birth',
          this.model.subscriberDOB
            ? parseDate(this.model.subscriberDOB).format('MM/DD/YYYY')
            : '',
          ELEMENTS.subscriberDOB.id,
        )}
      </div>

      <div class="grid grid-3">
        ${this.__renderField(
          'Group ID',
          this.model.subscriberGroupNumber,
          ELEMENTS.subscriberGroupNumber.id,
        )}
        ${this.__renderField(
          'Member ID',
          this.model.memberId,
          ELEMENTS.memberId.id,
        )}
        ${!this.model.dependentName
          ? this.__renderField(
              'Patient Account Number',
              this.model.patientAccountNumber,
              ELEMENTS.patientAccountNumber.id,
            )
          : ''}
      </div>
    `;
  }

  __renderDependent() {
    return this.model.dependentName
      ? html`
          ${this.model.dependentName
            ? this.__renderHeader(
                ELEMENTS.dependentInformationHeader.id,
                'Dependent Information',
              )
            : ''}
          <div class="grid grid-3">
            ${this.__renderField(
              'Name',
              this.model.dependentName,
              ELEMENTS.dependentName.id,
            )}
            ${this.__renderField(
              'Gender',
              this.model.dependentGender,
              ELEMENTS.dependentGender.id,
            )}
            ${this.__renderField(
              'Date of Birth',
              this.model.dependentDOB
                ? parseDate(this.model.dependentDOB).format('MM/DD/YYYY')
                : '',
              ELEMENTS.dependentDOB.id,
            )}
          </div>

          <div class="grid grid-3">
            ${this.__renderField(
              'Group ID',
              this.model.dependentGroupNumber,
              ELEMENTS.dependentGroupNumber.id,
            )}
            ${this.__renderField(
              'Patient Account Number',
              this.model.patientAccountNumber,
              ELEMENTS.patientAccountNumber.id,
            )}
          </div>
        `
      : '';
  }

  __renderClaimStatus() {
    return html`
      ${this.__renderHeader(
        ELEMENTS.claimStatusHeader.id,
        'Claim Status Detail',
      )}
      <div class="grid grid-3">
        ${this.__renderField(
          'Claim Service Date',
          this.model.serviceDate
            ? parseDate(this.model.serviceDate).format('MM/DD/YYYY')
            : '',
          ELEMENTS.serviceDate.id,
        )}
        ${this.__renderField(
          'Claim Amount',
          this.model.claimAmount ? centsToCurrency(this.model.claimAmount) : '',
          ELEMENTS.claimAmount.id,
        )}
      </div>

      <div class="grid grid-3">
        ${this.__renderField(
          'Status Date',
          this.model.effectiveDate
            ? parseDate(this.model.effectiveDate).format('MM/DD/YYYY')
            : '',
          ELEMENTS.statusDate.id,
        )}
        ${this.__renderField(
          'Status Code',
          this.model.code,
          ELEMENTS.codeValue.id,
        )}
        ${this.__renderField(
          'Status Description',
          this.model.description,
          ELEMENTS.descriptionValue.id,
        )}
        <div></div>
        ${this.__renderField('', this.model.statusCode, ELEMENTS.statusCode.id)}
        ${this.__renderField(
          '',
          this.model.statusCodeValue,
          ELEMENTS.statusDescription.id,
        )}
      </div>
    `;
  }

  __renderServiceLineStatuses(status, index, statusIndex) {
    const spacer = html`
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    `;

    return html`
      ${statusIndex > 0 ? spacer : ''}
      ${this.__renderServiceField(
        'Status Code',
        status.code,
        ELEMENTS.serviceLineCode.class,
        index + statusIndex,
      )}
      ${this.__renderServiceField(
        'Status Description',
        status.description,
        ELEMENTS.serviceLineDescription.class,
        index + statusIndex,
      )}
      ${spacer}
      ${this.__renderServiceField(
        '',
        status.statusCode,
        ELEMENTS.serviceLineStatusCode.class,
        index + statusIndex,
      )}
      ${this.__renderServiceField(
        '',
        status.statusCodeValue,
        ELEMENTS.serviceLineStatusCodeValue.class,
        index + statusIndex,
      )}
    `;
  }

  __renderServiceLine(serviceLine, index) {
    return html`
      ${this.__renderServiceField(
        'Service Date',
        serviceLine.effectiveDate
          ? parseDate(serviceLine.effectiveDate).format('MM/DD/YYYY')
          : '',
        ELEMENTS.serviceLineDate.class,
        index,
      )}
      ${this.__renderServiceField(
        'Proc + Mod',
        serviceLine.procMod,
        ELEMENTS.serviceLineProcMod.class,
        index,
      )}
      ${this.__renderServiceField(
        'Units',
        serviceLine.units,
        ELEMENTS.serviceLineUnits.class,
        index,
      )}
      ${this.__renderServiceField(
        'Billed',
        serviceLine.billedAmount
          ? centsToCurrency(serviceLine.billedAmount)
          : '',
        ELEMENTS.serviceLineBilled.class,
        index,
      )}
      ${this.__renderServiceField(
        'Amount Paid',
        serviceLine.paidAmount ? centsToCurrency(serviceLine.paidAmount) : '',
        ELEMENTS.serviceLinePaid.class,
        index,
      )}
      ${serviceLine.statuses.map((status, statusIndex) =>
        this.__renderServiceLineStatuses(status, index, statusIndex),
      )}
    `;
  }

  __renderServiceLineDetails() {
    return this.model.serviceLines
      ? html`
          ${this.__renderHeader(
            ELEMENTS.serviceLineDetailHeader.id,
            'Service Line Details',
          )}
          <div class="grid grid-7">
            ${this.model.serviceLines.map((serviceLine, index) =>
              this.__renderServiceLine(serviceLine, index),
            )}
          </div>
        `
      : '';
  }

  __renderPayer() {
    return html`
      ${this.__renderHeader(
        ELEMENTS.payerInformationHeader.id,
        'Payer Information',
      )}
      <div class="grid grid-3">
        ${this.__renderField(
          'Payer Name',
          this.model.payerName,
          ELEMENTS.payerInformationName.id,
        )}
        ${this.__renderField(
          'Payer ID',
          this.model.payerId,
          ELEMENTS.payerId.id,
        )}
        ${this.__renderField(
          'Group ID',
          this.model.payerPlanId,
          ELEMENTS.payerPlanId.id,
        )}
      </div>

      <div class="grid grid-3">
        ${this.__renderField(
          'Contact Name',
          this.model.payerContactName,
          ELEMENTS.payerContactName.id,
        )}
        ${this.__renderField(
          'Fax Number',
          this.model.payerFax,
          ELEMENTS.payerFax.id,
        )}
        ${this.__renderField(
          'Phone Number',
          this.model.payerPhone,
          ELEMENTS.payerPhone.id,
        )}
      </div>
    `;
  }

  __renderProviderInfo() {
    return html`
      ${this.__renderHeader(
        ELEMENTS.providerInfoHeader.id,
        'Provider Information',
      )}
      <div class="grid grid-3">
        ${this.__renderField(
          'Practice Name',
          this.model.practiceName,
          ELEMENTS.practiceName.id,
        )}
        ${this.__renderField(
          'Provider Name',
          this.model.providerName,
          ELEMENTS.providerName.id,
        )}
      </div>

      <div class="grid grid-3">
        ${this.__renderField('ETIN', this.model.etin, ELEMENTS.etin.id)}
        ${this.__renderField('NPI', this.model.npi, ELEMENTS.npi.id)}
      </div>
    `;
  }

  renderActionBar() {
    return this.model.dismissed
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${'Link Claim'}"
            .cancelLabel="${'Cancel'}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.closeForm}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .doubleConfirm="${true}"
            .confirmLabel="${'Link Claim'}"
            .cancelLabel="${'Dismiss Response'}"
            .removeLabel="${'Cancel'}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.dismissResponse}"
            .onRemove="${this.handlers.closeForm}"
          ></neb-action-bar>
        `;
  }

  renderPagination() {
    const pageCount = Math.ceil(this.__service.getFilterCount() / 10);
    return pageCount > 1
      ? html`
          <div class="pagination-container">
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              class="pagination"
              .pageCount="${pageCount}"
              .currentPage="${this.__tableState.pageIndex}"
              .onPageChanged="${this.handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader(ELEMENTS.header.id, 'Patient Claims')}
      <neb-select-search
        id="${ELEMENTS.textSearchPatient.id}"
        class="pad search"
        label="Search for patient"
        name="patient"
        itemHeight="80"
        helper="Required"
        emptyMessage="No patients found"
        .items="${this.__patientItems}"
        .value="${this.__selectedPatient
          ? objToName(this.__selectedPatient.name, DEFAULT_NAME_OPTS)
          : null}"
        .onChange="${this.handlers.patientChange}"
        .onSearch="${this.handlers.searchPatients}"
        .onRenderItem="${this.handlers.renderPatientItem}"
        .onRequest="${this.handlers.requestMorePatients}"
        showSearch
        forceDown
      ></neb-select-search>
      <neb-table-claims-worklist
        id="${ELEMENTS.table.id}"
        .onChange="${this.handlers.radioChanged}"
        .config="${CONFIG}"
        .initialSortKey="${this.sortParams.key}"
        .emptyMessage="${'There are no claims for this patient.'}"
        .model="${this.__tableState.pageItems}"
        .mode="${MODE.NONE}"
        .sortParams="${[this.sortParams]}"
        .onSort="${this.handlers.changeSort}"
        .onClickLink="${this.handlers.clickLink}"
        .providers="${this.__providers}"
      >
      </neb-table-claims-worklist>
      ${this.renderPagination()}
      ${this.__renderHeader(
        ELEMENTS.responseInformationHeader.id,
        'Response Information',
      )}
      ${this.__renderResponseInformation()} ${this.__renderSubscriber()}
      ${this.__renderDependent()} ${this.__renderClaimStatus()}
      ${this.__renderServiceLineDetails()} ${this.__renderPayer()}
      ${this.__renderProviderInfo()}
    `;
  }
}

customElements.define(
  'neb-form-abandoned-claim-status',
  NebFormAbandonedClaimStatus,
);
