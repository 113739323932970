import '../../../neb-tabs-old';
import '../../../neb-tab-old';
import '../../../../../../../src/components/pages/ledger/neb-page-charges';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../../../neb-styles/neb-variables';

import { TABS } from './neb-ledger-charges';

export const ELEMENTS = {
  chargesPage: { id: 'charges-page' },
  openCharges: { id: 'open-charges' },
  closedCharges: { id: 'closed-charges' },
  tabs: { id: 'tabs' },
  tabItems: { selector: 'neb-tab-old' },
};

const CLOSED_CHARGES_CONFIG = {
  description: 'Review closed charges.',
  noChargesMessage: {
    patient: 'There are no closed charges for this patient.',
    practice: 'There are no closed charges.',
  },
};

const OPEN_CHARGES_CONFIG = {
  noChargesMessage: {
    patient: 'There are no open charges for this patient.',
    practice: 'There are no open charges.',
  },
};

class NebLedgerChargesController extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __navItems: Array,
      patient: Object,
      layout: String,
      cases: Array,
      route: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.route = '';
    this.__selectedTab = '';
    this.__navItems = [];

    this.patient = null;

    this.cases = [];

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => navigate(this.__buildRoute(tab)),
      change: () => this.onChange(),
    };
  }

  __isPatientLedger() {
    return this.patient && this.patient.id;
  }

  __getRouteTail() {
    const segmentsToSlice = this.__isPatientLedger() ? 2 : 3;
    const segments = this.route.split('/');
    return segments.slice(segmentsToSlice).join('/');
  }

  __buildRoute(tab) {
    return this.__isPatientLedger()
      ? `#/patients/${this.patient.id}/ledger/charges/${tab}`
      : `#/practice-ledger/ledger/charges/${tab}`;
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();
    return this.layout === 'large'
      ? !!this.__navItems.find(({ name }) => name === routeTail)
      : routeTail === '';
  }

  __getCurrentPageId() {
    switch (this.__selectedTab) {
      case TABS.CHARGES:
        return ELEMENTS.chargesPage.id;
      case TABS.OPEN:
        return ELEMENTS.openCharges.id;
      case TABS.CLOSED:
        return ELEMENTS.closedCharges.id;
      default:
        return null;
    }
  }

  fetch() {
    const pageId = this.__getCurrentPageId();
    const chargesPage = this.shadowRoot?.getElementById(pageId);
    return chargesPage ? chargesPage.fetch() : '';
  }

  connectedCallback() {
    this.__navItems = this.genNavItems();
    super.connectedCallback();
  }

  __getHomeTab() {
    const [firstItem] = this.__navItems;
    return firstItem ? firstItem.name : '';
  }

  updated(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let tab = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        tab = this.layout === 'large' ? this.__getHomeTab() : '';
        redirect(this.__buildRoute(tab));
      }

      this.__selectedTab = tab;
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .tabs {
          padding: 0 ${CSS_SPACING};
          flex: 0 0 auto;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .charges {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __getNoOpenChargesMessage() {
    const { patient, practice } = OPEN_CHARGES_CONFIG.noChargesMessage;
    return this.__isPatientLedger() ? patient : practice;
  }

  __getNoClosedChargesMessage() {
    const { patient, practice } = CLOSED_CHARGES_CONFIG.noChargesMessage;
    return this.__isPatientLedger() ? patient : practice;
  }

  __renderClosedChargesPage() {
    return html`
      <neb-ledger-charges
        id="${ELEMENTS.closedCharges.id}"
        class="charges"
        .layout="${this.layout}"
        .patient="${this.patient}"
        .cases="${this.cases}"
        .mode="${TABS.CLOSED}"
        .description="${CLOSED_CHARGES_CONFIG.description}"
        .noChargesMessage="${this.__getNoClosedChargesMessage()}"
        .onChange="${this.__handlers.change}"
      ></neb-ledger-charges>
    `;
  }

  __renderOpenChargesPage() {
    return html`
      <neb-ledger-charges
        id="${ELEMENTS.openCharges.id}"
        class="charges"
        .layout="${this.layout}"
        .patient="${this.patient}"
        .cases="${this.cases}"
        .mode="${TABS.OPEN}"
        .noChargesMessage="${this.__getNoOpenChargesMessage()}"
        .onChange="${this.__handlers.change}"
      ></neb-ledger-charges>
    `;
  }

  __renderChargesPage() {
    return html`
      <neb-page-charges
        id="${ELEMENTS.chargesPage.id}"
        .patient="${this.patient}"
      ></neb-page-charges>
    `;
  }

  __getBasePath() {
    return `${this.__isPatientLedger() ? '' : '/ledger'}/charges`;
  }

  genNavItems() {
    const basePath = this.__getBasePath();

    const navItems = [
      {
        exact: false,
        label: 'Open Invoices',
        name: TABS.OPEN,
        path: `${basePath}/${TABS.OPEN}/`,
        resolver: () => this.__renderOpenChargesPage(),
      },
      {
        exact: false,
        label: 'Closed Invoices',
        name: TABS.CLOSED,
        path: `${basePath}/${TABS.CLOSED}/`,
        resolver: () => this.__renderClosedChargesPage(),
      },
    ];

    if (this.__isPatientLedger()) {
      return [
        {
          exact: false,
          label: 'Charges',
          name: TABS.CHARGES,
          path: `${basePath}/${TABS.CHARGES}/`,
          resolver: () => this.__renderChargesPage(),
        },
        ...navItems,
      ];
    }

    return navItems;
  }

  __renderTabs() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.selectTab}"
      >
        ${this.__navItems.map(
          item => html`
            <neb-tab-old name="${item.name}">${item.label}</neb-tab-old>
          `,
        )}
      </neb-tabs-old>
    `;
  }

  render() {
    return html`
      ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-ledger-charges-controller',
  NebLedgerChargesController,
);
