import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-material-design/src/components/neb-md-textfield-with-icon';

import { LitElement, html, css } from 'lit';

import { normalizeForSearch } from '../../../packages/neb-utils/formatters';
import { ITEM_EMPTY } from '../../../packages/neb-utils/selectors';
import { CollectionService } from '../../../packages/neb-utils/services/collection';
import { baseStyles } from '../../styles';

export const ELEMENTS = {
  container: { id: 'container' },
  selectSearch: { id: 'select-search' },
};

class NebItemSelectSearch extends LitElement {
  static get properties() {
    return {
      __itemSearch: String,
      __selectedItem: Object,
      __filteredItems: Array,
      __allFormattedItems: Array,

      items: Array,
      itemId: String,
      name: String,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      label: String,
      helper: String,
      emptyMessage: String,
      error: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__searchItems = [];
    this.__itemSearch = '';
    this.__selectedItem = null;
    this.__sortParams = {};
    this.__filteredItems = [];
    this.__allFormattedItems = [];

    this.item = [];
    this.itemId = null;
    this.disabled = false;
    this.name = '';
    this.label = '';
    this.helper = '';
    this.emptyMessage = '';
    this.error = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => {
        if (!e.value) {
          this.onChange({ name: e.name, value: ITEM_EMPTY });
        } else {
          this.onChange({
            name: e.name,
            value: {
              data: e.value,
              label: e.value.label,
            },
          });
        }
      },
      search: ({ value }) => {
        this.__itemSearch = value;
        this.__collectionService.search(value);
      },
    };
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageItems }) => {
          this.__filteredItems = pageItems;
        },

        onCacheItem: ({ label }) => normalizeForSearch(label),
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),

        onSort: (a, b, key) => {
          const A = a[key] != null ? a[key].toString().toUpperCase() : '';
          const B = b[key] != null ? b[key].toString().toUpperCase() : '';

          if (A !== B) {
            return A < B ? 1 : -1;
          }

          return 0;
        },
      },
      {
        hideInactive: false,
      },
    );
  }

  __setItems() {
    this.__allFormattedItems = this.items.map(item => ({
      ...item.data,
      label: item.label,
    }));

    this.__collectionService.setItems(this.__allFormattedItems);

    this.__collectionService.setPageSize(this.__allFormattedItems.length);
  }

  update(changedProps) {
    if (changedProps.has('items')) {
      this.__setItems();
    }

    if (changedProps.has('itemId')) {
      this.__selectedItem = this.itemId
        ? this.__allFormattedItems.find(x => x.id === this.itemId)
        : null;
    }

    if (changedProps.has('__filteredItems')) {
      this.__selectedItem = this.itemId
        ? this.__allFormattedItems.find(x => x.id === this.itemId)
        : null;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: block;
          width: 100%;
          height: 100%;
        }

        .select-search {
          display: block;
          position: relative;
          width: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-select-search
          id="${ELEMENTS.selectSearch.id}"
          class="select-search"
          name="${this.name}"
          emptyMessage="${this.emptyMessage}"
          .error="${this.error}"
          .label="${this.label}"
          .helper="${this.helper}"
          .items="${this.__filteredItems}"
          .search="${this.__itemSearch}"
          .value="${this.__selectedItem}"
          .onChange="${this.__handlers.change}"
          .onSearch="${this.__handlers.search}"
          ?disabled="${this.disabled}"
          showSearch
        ></neb-select-search>
      </div>
    `;
  }
}

window.customElements.define('neb-item-select-search', NebItemSelectSearch);
