import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_COLOR_BLACK,
  CSS_FONT_FAMILY,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  confirmButton: {
    id: 'button-confirm',
  },
  textConfirmation: {
    id: 'text-message',
  },
};

class NebPopupMessage extends NebPopup {
  initState() {
    super.initState();
    this.model = {
      title: '',
      message: '',
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () => this.onClose(),
    };
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }

        :host([layout='small']) {
          width: auto;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
        }

        .text-message {
          margin: 0;
          white-space: inherit;
          color: ${CSS_COLOR_BLACK};
        }

        .button {
          display: flex;
          margin-top: 40px;
          width: 74px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-content">
        <div id="${ELEMENTS.textConfirmation.id}" class="text-message">
          ${this.model.message}
        </div>

        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="button-primary-focus button"
          role="${BUTTON_ROLE.CONFIRM}"
          label="OK"
          .onClick="${this.__handlers.confirm}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-message', NebPopupMessage);
