import { store } from '../neb-redux-store';

export class ConnectedService {
  constructor(stateKeys) {
    this._oldValues = {};
    this._stateKeys = stateKeys;
  }

  connect() {
    if (this.__storeUnsubscribe) return;
    this.__storeUnsubscribe = store.subscribe(() =>
      this.__didStateKeysChangeWhilePresent(store.getState()),
    );

    this.__didStateKeysChangeWhilePresent(store.getState());
  }

  disconnect() {
    this.__storeUnsubscribe();
  }

  updateState() {
    if (!this.__storeUnsubscribe) return;

    if (Object.keys(this._oldValues).length) {
      this._stateChanged(this._oldValues);
    }

    this.__didStateKeysChangeWhilePresent(store.getState());
  }

  __didStateKeysChangeWhilePresent(state) {
    const stateKeyValues = Object.fromEntries(
      Object.entries(this._stateKeys).map(([name, key]) => {
        const keySegments = key.split('.');
        const finalKeyValue = keySegments.reduce(
          (stateKey, nextSegment) =>
            stateKey ? stateKey[nextSegment] : undefined,
          state,
        );
        return [name, finalKeyValue];
      }),
    );
    const allKeysHaveItems = !Object.values(stateKeyValues).some(
      v => v === null || v === undefined,
    );
    const atLeastOneValueHasChanged = Object.entries(stateKeyValues).some(
      ([name, value]) => this._oldValues[name] !== value,
    );

    if (allKeysHaveItems && atLeastOneValueHasChanged) {
      this._oldValues = stateKeyValues;

      this._stateChanged(stateKeyValues);
    }
  }

  _stateChanged() {
    throw new Error('_stateChanged is not implemented');
  }
}
