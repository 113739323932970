import {
  CHARGE_WARNINGS,
  CLAIM_WARNINGS,
  hasErrorMessage,
  PROCESSING_LINE_ITEMS_ERROR_MESSAGE,
} from '../utils';

const getErrorMessageBasedOnLineItems = lineItems => {
  const errorMessages = lineItems.map(({ errorMessage }) => errorMessage);
  const noErrors = errorMessages.every(
    errorMessage => !hasErrorMessage(errorMessage),
  );
  if (noErrors) return '';

  const allInsufficientRemainingPayment = errorMessages.every(errorMessage =>
    Array.isArray(errorMessage)
      ? errorMessage.includes(CHARGE_WARNINGS.INSUFFICIENT_REMAINING_PAYMENT)
      : errorMessage === CHARGE_WARNINGS.INSUFFICIENT_REMAINING_PAYMENT,
  );
  return allInsufficientRemainingPayment
    ? CLAIM_WARNINGS.INSUFFICIENT_REMAINING_PAYMENT
    : PROCESSING_LINE_ITEMS_ERROR_MESSAGE;
};

export default ({ errorMessage, lineItems, posted }, eraErrorMessage) => {
  if (posted) return '';

  if (errorMessage?.length) return errorMessage;

  return getErrorMessageBasedOnLineItems(lineItems) || eraErrorMessage || '';
};
