import '../../components/misc/neb-icon';
import '../../../packages/neb-styles/neb-icons';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../../packages/neb-lit-components/src/components/neb-button';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { getGeniusKeys } from '../../../packages/neb-api-client/src/payments/card-readers-api-client';
import { createGeniusPayment } from '../../../packages/neb-api-client/src/payments/electronic-payments-api-client';
import {
  createPaymentMethod,
  deleteToken,
} from '../../../packages/neb-api-client/src/payments/payment-methods-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../packages/neb-utils/constants';
import {
  ELECTRONIC_PAYMENT_TYPES,
  GENIUS_SALE_TYPES,
} from '../../../packages/neb-utils/electronic-payments-util';
import { currencyToCents } from '../../../packages/neb-utils/formatters';

import { fiservReaderActivate, STATE } from './fiserv-reader';
import { PAYMENT_RESULT_CONFIG } from './messages';

export const ELEMENTS = {
  cancelButton: {
    id: 'button-cancel',
  },
  closeButton: {
    id: 'button-close',
  },
  readerConnectionForm: {
    id: 'form-reader-connection',
  },
  readerConnectionCancelButton: {
    id: 'button-reader-connection-cancel',
  },
  readerConnectionCloseButton: {
    id: 'button-reader-connection-close',
  },
  confirmButton: {
    id: 'button-confirm',
  },
  textHeader: {
    id: 'text-header',
  },
  textSubheader: {
    id: 'text-subheader',
  },
  textAmount: {
    id: 'text-amount',
  },
  cardDescriptionField: {
    id: 'card-description',
  },
  payfieldsIframe: {
    id: 'payfields-iframe',
  },
  merchantAccountDropDown: {
    id: 'merchant-account-dropdown',
  },
  cardReaderDropDown: {
    id: 'card-reader-dropdown',
  },
};
const READER_TIMEOUT = 6000;

class NebPayfacReader extends LitElement {
  static get properties() {
    return {
      __initialized: Boolean,
      __processing: Boolean,
      __singleAccountConfig: Boolean,
      __dirty: Boolean,
      __connecting: Boolean,
      __connected: Boolean,
      __cardDescription: String,
      __readerProtocol: String,
      __selectedMerchantAccount: Object,
      __selectedCardReader: Object,
      amount: String,
      postalCode: String,
      address1: String,
      address2: String,
      city: String,
      state: String,
      firstName: String,
      lastName: String,
      merchantAccounts: Array,
      merchantAccountNames: Array,
      cardReaders: Array,
      cardReaderNames: Array,
      title: String,
      message: String,
      subheader: String,
      confirmLabel: String,
      holderType: String,
      pendingModel: Object,
      holderId: String,
      geniusSaleType: String,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return css`
      .container-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .header {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
      }

      .text-header {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        padding-bottom: ${CSS_SPACING};
      }

      .text-message {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
        font-weight: bold;
        padding: ${CSS_SPACING} 0;
      }

      .card-description {
        margin: 0;
        padding: 16px 0 ${CSS_SPACING};
        width: 50%;
      }

      .text-subheader {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
      }

      .icon-close {
        height: 24px;
        width: 24px;
        fill: ${CSS_COLOR_GREY_1};
        padding: 0;
        cursor: pointer;
      }

      .credit-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 160px;
      }

      .icon-credit-card {
        height: 96px;
        width: 96px;
        fill: #0b9fcb;
        padding: 0;
      }

      .iframe {
        display: flex;
        border: none;
        width: 100%;
      }

      .margin-left {
        margin-left: 16px;
      }

      .spinner {
        margin-top: ${CSS_SPACING};
        padding-bottom: 50px;
        text-align: center;
        z-index: 1;
      }

      .visible {
        opacity: 1;
      }

      .invisible {
        opacity: 0;
      }

      .merchant-accounts {
        padding-bottom: ${CSS_SPACING};
      }

      .button-next:active {
        opacity: 0.65;
      }

      .button-cancel:active {
        opacity: 0.65;
      }

      .flex-start {
        align-self: flex-start;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__initialized = false;
    this.__processing = false;
    this.__dirty = false;
    this.__connecting = false;
    this.__connected = false;
    this.__singleAccountConfig = false;
    this.__cardDescription = '';
    this.__readerProtocol = '';
    this.amount = '';
    this.holderType = '';
    this.pendingModel = {};
    this.holderId = '';
    this.postalCode = '';
    this.address1 = '';
    this.merchantAccounts = [];
    this.merchantAccountNames = [];
    this.cardReaderNames = [];
    this.geniusSaleType = '';

    this.onCancel = () => {};

    this.onSave = () => {};

    this.onRefundDeclined = () => {};
    this.__selectedMerchantAccount = null;
    this.__selectedCardReader = null;
  }

  __updateReaders() {
    this.cardReaderNames = this.__selectedMerchantAccount.cardReaders
      .map(v => ({
        id: v.id,
        label: v.name,
      }))
      .sort(this.__sortByLabel);

    this.__selectedCardReader = this.cardReaderNames[0];
  }

  __genRequestOpts() {
    return {
      method: 'GET',
    };
  }

  /* istanbul ignore next */
  __fetchWithTimeout(url, opts, timeout) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        timeout,
      );

      fetch(url, opts)
        .then(
          response => resolve(response),
          err => reject(err),
        )
        .finally(() => clearTimeout(timer));
    });
  }

  async __isReaderReady(reader) {
    try {
      const result = await this.__fetchWithTimeout(
        `https://${reader.hostName}:8443/v2/pos?Action=Status&Format=JSON`,
        this.__genRequestOpts(),
        READER_TIMEOUT,
      );

      const res = await result.json();

      if (res.Status === 'Online' && res.CurrentScreen === '00') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async __cancelReaderTransaction(reader) {
    await fetch(
      `https://${reader.hostName}:8443/v1/pos?Action=Cancel&Format=JSON`,
      this.__genRequestOpts(),
    );
  }

  __sortByLabel(a, b) {
    const A = a.label.toUpperCase();
    const B = b.label.toUpperCase();

    if (A < B) {
      return -1;
    }

    return 1;
  }

  __initHandlers() {
    this.__handlers = {
      getTransportKey: async () => {
        const body = {
          amount: `${this.__convertCurrency(this.amount)}`,
          userId: store.getState().session.item.id,
          type: this.geniusSaleType,
          pendingModel: this.pendingModel,
          logContent: {
            action: 'payment - cardReader - neb-payfac-reader',
            rawModel: {
              ...this.pendingModel,
              amount: `${this.__convertCurrency(this.amount)}`,
              userId: store.getState().session.item.id,
            },
          },
        };

        const result = await getGeniusKeys(
          this.__selectedMerchantAccount.id,
          body,
        );

        return result;
      },
      setState: state => {
        if (this.__state === state) {
          return;
        }

        this.__state = state;

        this.__connecting = this.__state === STATE.CONNECTING;
        this.__connected = this.__state === STATE.CONNECTED;
        this.__processing = this.__state === STATE.PROCESSING;

        if (this.__state === STATE.DEFAULT && this.__singleAccountConfig) {
          this.onCancel();
        }
      },
      // eslint-disable-next-line complexity
      activateReader: async () => {
        if (
          this.__selectedMerchantAccount.merchantProvider ===
          MERCHANT_PROVIDERS.FISERV
        ) {
          this.__fiservReader = fiservReaderActivate({
            readerId: this.__selectedCardReader.id,
            setState: this.__handlers.setState,
            onSave: this.onSave,
            paymentDetails: {
              merchantAccountId: this.__selectedMerchantAccount.id,
              amount: currencyToCents(this.amount),
              postal: this.postalCode,
              address: this.address1,
              address2: this.address2,
              city: this.city,
              region: this.state,
              firstName: this.firstName,
              lastName: this.lastName,
              holderId: this.holderId,
              holderType: this.holderType,
              logContent: {
                action: 'payment - cardReader - neb-payfac-reader',
                rawModel: {
                  ...this.pendingModel,
                  amount: currencyToCents(this.amount),
                  userId: store.getState().session.item.id,
                },
              },
            },
          });

          return;
        }

        this.__connecting = true;

        const reader = this.__selectedMerchantAccount.cardReaders.find(
          ({ id }) => id === this.__selectedCardReader.id,
        );

        const geniusKeys = await this.__handlers.getTransportKey();

        if (this.__validateGeniusKey(geniusKeys)) {
          const response = await openPopup(
            POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
            PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_CREDENTIALS,
          );

          if (response && response.action === 'confirm') {
            if (this.__singleAccountConfig) this.onCancel();

            this.__processing = false;
            this.__connected = false;
            this.__connecting = false;

            return;
          }

          this.onCancel();

          return;
        }

        if (await this.__isReaderReady(reader)) {
          try {
            this.__connecting = false;
            this.__connected = true;

            const readerURL = `https://${
              reader.hostName
            }:8443/v2/pos?TransportKey=${
              geniusKeys.keys.transport_key
            }&Format=JSON`;

            const result = await fetch(readerURL, this.__genRequestOpts());

            const res = await result.json();

            if (res.Status === 'FAILED') {
              const response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                {
                  ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
                  subheader: 'Service Unavailable',
                },
              );

              if (response) {
                if (response.action === 'confirm') {
                  this.__processing = false;
                  this.__connected = false;
                  this.__handlers.activateReader();

                  return;
                }
                if (this.__singleAccountConfig) this.onCancel();

                this.__processing = false;
                this.__connected = false;

                return;
              }
              this.onCancel();
            }

            if (
              this.geniusSaleType === GENIUS_SALE_TYPES.RETURN &&
              res.Status === 'DECLINED'
            ) {
              this.onRefundDeclined({ status: res.Status });
              return;
            }

            if (res.Status === 'DECLINED') {
              const response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                {
                  ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
                  subheader: res.Status,
                },
              );

              if (response) {
                if (response.action === 'confirm') {
                  if (this.__singleAccountConfig) this.onCancel();

                  this.__processing = false;
                  this.__connected = false;

                  return;
                }
              } else this.onCancel();
              if (this.__singleAccountConfig) this.onCancel();

              this.__processing = false;
              this.__connected = false;

              return;
            }

            if (
              res.Status === 'UserCancelled' ||
              res.Status === 'POSCancelled'
            ) {
              this.__connected = false;

              if (this.__singleAccountConfig) this.onCancel();

              return;
            }

            this.__processing = true;

            if (this.geniusSaleType === GENIUS_SALE_TYPES.RETURN) {
              this.onSave({
                ...res,
                referenceId: geniusKeys.reference_id,
                processedAs: 'refund',
                type: ELECTRONIC_PAYMENT_TYPES.DEBIT,
              });

              return;
            }

            const paymentResult = await createGeniusPayment(
              this.__selectedMerchantAccount,
              {
                ...res,
                referenceId: geniusKeys.reference_id,
                holderType: this.holderType,
                holderId: this.holderId,
                processorResponse: res.Status,
              },
            );

            if (paymentResult.status === 'Approved') {
              this.onSave(paymentResult);

              if (paymentResult.card.token && this.holderType !== 'payer') {
                const response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS_AND_SAVE,
                );

                try {
                  if (
                    response.action === 'confirm' &&
                    response.saveCardOnFile
                  ) {
                    await createPaymentMethod(this.__selectedMerchantAccount, {
                      ...paymentResult.card,
                      holderId: this.holderId,
                      holderType: this.holderType,
                      description: response.cardOnFileName,
                    });

                    store.dispatch(
                      openSuccess('Payment method stored successfully'),
                    );
                  } else {
                    deleteToken(
                      this.__selectedMerchantAccount,
                      paymentResult.card.token,
                      paymentResult.entryType,
                    );
                  }
                } catch (e) {
                  store.dispatch(
                    openError('An error occurred when storing payment method'),
                  );

                  console.log(e);
                }
                return;
              }
              await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
              );

              return;
            }

            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
                subheader: paymentResult.statusDescription,
              },
            );

            if (response) {
              if (response.action === 'confirm') {
                if (this.__singleAccountConfig) this.onCancel();

                this.__processing = false;
                this.__connected = false;

                return;
              }
            } else this.onCancel();
          } catch (e) {
            console.log(e);
          }

          this.onCancel();
        } else {
          const MESSAGE = {
            title: 'Error Connecting to Card Reader',
            subheader: '',
            message:
              "There was an error connecting to the card reader. Ensure you are connected to a valid network and try again, or click 'back' to select different payment transaction details.",
            success: false,
            allowSaveCardOnFile: false,
            confirmLabel: 'Try Again',
            cancelLabel: 'Back',
          };

          const response = await openPopup(
            POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
            MESSAGE,
          );

          if (response) {
            if (response.action === 'confirm') {
              this.__handlers.activateReader();

              return;
            }

            this.__connecting = false;

            if (this.__singleAccountConfig) this.onCancel();

            return;
          }

          this.onCancel();
        }
      },
      cancel: async () => {
        if (this.__dirty) {
          if (await openDirtyPopup()) this.onCancel();
        } else {
          this.onCancel();
        }
      },
      cancelReader: async () => {
        if (
          this.__selectedMerchantAccount.merchantProvider ===
          MERCHANT_PROVIDERS.FISERV
        ) {
          this.__fiservReader.cancel();

          return;
        }

        const reader = this.__selectedMerchantAccount.cardReaders.find(
          ({ id }) => id === this.__selectedCardReader.id,
        );

        await this.__cancelReaderTransaction(reader);
      },
      cardReaderChange: ({ value }) => {
        this.__dirty = true;
        this.__selectedCardReader = value;
      },
      merchantAccountChange: ({ value }) => {
        this.__dirty = true;

        if (this.__selectedMerchantAccount.id !== value.id) {
          this.__selectedMerchantAccount = value;
          this.__updateReaders(value);
        }
      },
    };
  }

  firstUpdated(props) {
    this.merchantAccountNames = this.merchantAccounts
      .filter(v => v.active)
      .map(v => ({
        ...v,
        id: v.id,
        label: v.name,
      }));

    this.__selectedMerchantAccount = this.merchantAccountNames[0];

    this.cardReaderNames = this.__selectedMerchantAccount.cardReaders
      .map(v => ({
        id: v.id,
        label: v.name,
      }))
      .sort(this.__sortByLabel);

    this.__selectedCardReader = this.cardReaderNames[0];

    this.__singleAccountConfig =
      this.merchantAccountNames.length === 1 &&
      this.cardReaderNames.length === 1;

    if (this.__singleAccountConfig) {
      this.__handlers.activateReader();
    }

    super.firstUpdated(props);
  }

  __validateGeniusKey(geniusKeys) {
    return Array.isArray(geniusKeys);
  }

  __renderTitle() {
    return html`
      <div class="header spacer">
        <div class="text-header" id="${ELEMENTS.textHeader.id}">
          ${this.title}
        </div>
        ${!this.__processing
          ? html`
              <neb-icon
                id="${ELEMENTS.closeButton.id}"
                class="icon-close"
                icon="neb:close"
                @click="${this.__handlers.cancel}"
              ></neb-icon>
            `
          : ''}
      </div>
    `;
  }

  __convertCurrency(amount) {
    return amount ? Number(amount.replace(/[^0-9.]/g, '')) : 0;
  }

  __renderAmount() {
    return html`
      <div id="${ELEMENTS.textAmount.id}" class="text-message">
        ${this.message}: ${this.amount}
      </div>
    `;
  }

  __hasMerchantAccounts() {
    return this.merchantAccountNames
      ? this.merchantAccountNames.length > 1
      : false;
  }

  __renderMerchantAccounts() {
    return this.__hasMerchantAccounts()
      ? html`
          <neb-select
            id="${ELEMENTS.merchantAccountDropDown.id}"
            class="merchant-accounts"
            label="Merchant Account"
            .items="${this.merchantAccountNames}"
            .value="${this.__selectedMerchantAccount}"
            .onChange="${this.__handlers.merchantAccountChange}"
          ></neb-select>
        `
      : '';
  }

  __renderCardReaders() {
    return html`
      <neb-select
        id="${ELEMENTS.cardReaderDropDown.id}"
        class="merchant-accounts"
        label="Card Reader"
        .items="${this.cardReaderNames}"
        .value="${this.__selectedCardReader}"
        .onChange="${this.__handlers.cardReaderChange}"
      ></neb-select>
    `;
  }

  __renderTransactionProcessing() {
    return html`
      <div class="container-content">
        <div class="header spacer">
          <div class="text-header" id="${ELEMENTS.textHeader.id}">
            Transaction Processing
          </div>
        </div>

        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>
      </div>
    `;
  }

  __renderConnectingToGlobalPaymentsReader() {
    return html`
      <div class="container-content">
        <div class="header spacer">
          <div class="text-header" id="${ELEMENTS.textHeader.id}">
            Connecting to Card Reader
          </div>
        </div>

        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>
      </div>
    `;
  }

  __renderConnectingToFiservReader() {
    return html`
      <div class="container-content">
        <div class="header spacer">
          <div class="text-header" id="${ELEMENTS.textHeader.id}">
            Connecting to Card Reader
          </div>
          <neb-icon
            id="${ELEMENTS.readerConnectionCloseButton.id}"
            class="icon-close"
            icon="neb:close"
            ?disabled="${false}"
            @click="${this.__handlers.cancelReader}"
          ></neb-icon>
        </div>

        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>

        <neb-button
          id="${ELEMENTS.readerConnectionCancelButton.id}"
          label="Cancel"
          role="cancel"
          class="flex-start"
          .onClick="${this.__handlers.cancelReader}"
        ></neb-button>
      </div>
    `;
  }

  __renderReaderConnection() {
    return html`
      <div id="${ELEMENTS.readerConnectionForm.id}">
        <div class="header spacer">
          <div class="text-header" id="${ELEMENTS.textHeader.id}">
            Follow Card Reader Prompts
          </div>
          ${!this.__processing
            ? html`
                <neb-icon
                  id="${ELEMENTS.readerConnectionCloseButton.id}"
                  class="icon-close"
                  icon="neb:close"
                  ?disabled="${false}"
                  @click="${this.__handlers.cancelReader}"
                ></neb-icon>
              `
            : ''}
        </div>
        <div class="text-subheader" id="${ELEMENTS.textHeader.id}">
          Please follow the prompts on the card reader. You can swipe, insert,
          or tap a card to complete the transaction.
        </div>
        <div class="credit-card-container">
          <neb-icon class="icon-credit-card" icon="neb:creditCard"></neb-icon>
        </div>

        <neb-button
          id="${ELEMENTS.readerConnectionCancelButton.id}"
          label="Cancel"
          role="cancel"
          .onClick="${this.__handlers.cancelReader}"
        ></neb-button>
      </div>
    `;
  }

  render() {
    if (this.__processing) {
      return html` ${this.__renderTransactionProcessing()} `;
    }

    if (this.__connected) {
      return html` ${this.__renderReaderConnection()} `;
    }

    if (this.__connecting) {
      return this.__selectedMerchantAccount?.merchantProvider ===
        MERCHANT_PROVIDERS.FISERV
        ? this.__renderConnectingToFiservReader()
        : this.__renderConnectingToGlobalPaymentsReader();
    }

    return html`
      ${this.__renderTitle()}
      <div class="container-content">
        <div id="${ELEMENTS.textSubheader.id}" class="text-subheader">
          ${this.subheader}
        </div>
        ${this.__renderAmount()} ${this.__renderMerchantAccounts()}
        ${this.__renderCardReaders()}
      </div>

      <neb-button
        id="${ELEMENTS.confirmButton.id}"
        label="Next"
        class="button-next"
        .onClick="${this.__handlers.activateReader}"
      ></neb-button>
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        class="margin-left button-cancel"
        label="Cancel"
        role="cancel"
        .onClick="${this.__handlers.cancel}"
      ></neb-button>
    `;
  }
}
customElements.define('neb-payfac-reader', NebPayfacReader);
