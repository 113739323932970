import { arrayToObject } from '../../../neb-utils/utils';
import * as actions from '../../actions/item-actions';

import * as deleteHandler from './handlers/delete';

const mergeItemstoStore = (state, action) => {
  const data = action.processor
    ? action.processor(action.res.data)
    : action.res.data;
  return { ...state, ...arrayToObject(data) };
};

export const itemsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ITEM_FETCHED:
    case actions.ITEM_CREATED:
    case actions.ITEM_UPDATED:
      return mergeItemstoStore(state, action);

    case actions.ITEM_DELETED:
      return deleteHandler._deleted(state, action);

    default:
      return state;
  }
};
