import '../neb-button-actions';
import '../neb-text';

import { css, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { DEFAULT_NAME_OPTS, objToName } from '../../../../neb-utils/formatters';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  buttonActions: { id: 'button-actions' },
  patientLink: { id: 'patient-link' },
  purchaseLink: { id: 'purchase-link' },
  invoiceLink: { id: 'invoice-link' },
};

class NebTableLedgerActivityPurchases extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: block;
          align-items: center;
          cursor: pointer;
        }

        .button-link-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.onViewCharges = () => {};

    this.onCollectPayment = () => {};

    this.onViewReceipt = () => {};

    this.onLink = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickLink: e => {
        const key = e.currentTarget.getAttribute('key');
        const index = Number(e.currentTarget.getAttribute('index'));
        const item = this.model[index];

        this.onLink(key, item);
      },
    };
  }

  __genMenuItems(row) {
    return [
      {
        icon: '',
        label: 'View Charges',
        onSelect: () => this.onViewCharges(row),
      },
      {
        icon: '',
        label: 'Collect Payment',
        onSelect: () => this.onCollectPayment(row),
      },
      {
        icon: '',
        label: 'View Receipt',
        onSelect: () => this.onViewReceipt(row),
      },
    ];
  }

  __buildMenuItems(row) {
    const allItems = this.__genMenuItems(row);
    const items = [];

    items.push(allItems[0]);

    if (row.balance > 0) items.push(allItems[1]);

    if (row.patientPaymentIds) items.push(allItems[2]);

    return items;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const row = this.model[rowIndex];
    const { type } = row;

    switch (columnConfig.key) {
      case 'lineItemId':
        return html`
          <neb-button-actions
            id="${ELEMENTS.buttonActions.id}-${rowIndex}"
            align="left"
            .value="${this.__buildMenuItems(row)}"
          ></neb-button-actions>
        `;

      case 'patient':
        return html`
          <neb-text
            id="${ELEMENTS.patientLink.id}-${rowIndex}"
            key="patient"
            index="${rowIndex}"
            @click="${this.handlers.clickLink}"
            bold
            link
          >
            ${objToName(value.name, DEFAULT_NAME_OPTS)}

            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </neb-text>
        `;

      case 'purchase':
        return type === 'Package' || type === 'Subscription'
          ? html`
              <neb-text
                id="${ELEMENTS.purchaseLink.id}-${rowIndex}"
                key="purchase"
                index="${rowIndex}"
                @click="${this.handlers.clickLink}"
                bold
                link
              >
                ${value || ''}
              </neb-text>
            `
          : value || '';

      case 'invoiceNumber':
        return value
          ? html`
              <neb-text
                id="${ELEMENTS.invoiceLink.id}-${rowIndex}"
                key="invoice"
                index="${rowIndex}"
                @click="${this.handlers.clickLink}"
                bold
                link
              >
                ${value}
              </neb-text>
            `
          : '-';

      case 'amount':
      case 'balance':
        return html` <neb-text bold>${value}</neb-text> `;

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-ledger-activity-purchases',
  NebTableLedgerActivityPurchases,
);
