import '@polymer/iron-icon/iron-icon';

import { LitElement, html } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  providers: {
    selector: '.provider',
  },
  serviceDates: {
    selector: '.service-date',
  },
  appointmentTypes: {
    selector: '.appt-type',
  },
};

class NebPriorEncountersList extends LitElement {
  static get properties() {
    return {
      model: {
        type: Array,
      },
      message: {
        type: String,
      },
      selectedEncounterId: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__setupHandlers();

    this.onItemClicked = () => {};

    this.model = [];
    this.selectedEncounterId = '';
  }

  __setupHandlers() {
    this.__handlers = {
      itemClicked: ({ currentTarget }) => {
        const { encounterId } = currentTarget.dataset;
        this.onItemClicked(this.model.find(item => item.id === encounterId));
        this.selectedEncounterId = encounterId;
      },
    };
  }

  resetSelected() {
    this.selectedEncounterId = '';
  }

  __renderStyles() {
    return html`
      <style>
        :host,
        .table {
          display: flex;
          flex-direction: column;
          min-width: 0;
        }
        .table-row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          padding: 13px 0;
          background-color: white;
        }
        .table-row span:first-of-type {
          margin-left: ${CSS_SPACING};
        }
        .table-header {
          font-weight: bold;
          line-height: 34px;
          overflow: hidden;
        }
        .service-date {
          flex: 0.28 0 0;
        }
        .provider {
          flex: 0.3 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 10px;
        }
        .appt-type {
          flex: 0.4 0 0;
        }
        .nav {
          flex: 0.02 0 0;
        }
        .link {
          text-decoration: underline;
          color: ${CSS_COLOR_HIGHLIGHT};
        }
        .item {
          box-sizing: border-box;
          cursor: pointer;
          align-items: center;
        }
        .selected {
          background-color: ${CSS_COLOR_GREY_4};
        }
        .arrow {
          height: 11px;
          width: 11px;
          color: ${CSS_COLOR_GREY_1};
          transform: rotate(0.5turn);
          padding-left: 3px;
        }
        .text-caption {
          margin: ${CSS_SPACING};
          font-style: italic;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_GREY_1};
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}
      <div class="table">
        <div class="table-row table-header" id="table-header">
          <span id="service-date-header" class="service-date"
            >Service Date
          </span>
          <span id="provider-header" class="provider">Provider</span>
          <span id="appt-type-header" class="appt-type">Appointment Type</span>
          <span class="nav"></span>
        </div>
        ${this.model.length > 0
          ? html`
              ${this.model.map(
                (item, index) => html`
                  <div
                    class="table-row item ${item.id === this.selectedEncounterId
                      ? 'selected'
                      : ''}"
                    id="row-${index}"
                    @click="${this.__handlers.itemClicked}"
                    data-encounter-id="${item.id}"
                  >
                    <span class="service-date link" id="service-date"
                      >${item.fullDate}
                    </span>
                    <span class="provider" id="provider">${item.provider}</span>
                    <span class="appt-type" id="appt-type"
                      >${item.appointmentType}</span
                    >
                    <div class="nav">
                      <iron-icon class="arrow" icon="neb:back"></iron-icon>
                    </div>
                  </div>
                `,
              )}
            `
          : html` <p id="message" class="text-caption">${this.message}</p> `}
      </div>
    `;
  }
}

customElements.define('neb-prior-encounters-list', NebPriorEncountersList);
