import '../../../../neb-document/src/components/neb-documents-list';
import '../../../../neb-document/src/components/neb-documents-info';
import '../../../../neb-document/src/components/neb-document-content';

import { openPopup } from '@neb/popup';
import { LitElement, html } from 'lit';

import { mapToDocumentModel } from '../../../../../src/utils/document-mapper';
import { BULK_SELECT_ONE_POPUP_MESSAGE } from '../../../../../src/utils/user-message';
import { associateEncounters } from '../../../../neb-api-client/src/encounters-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { NebBaseDocumentController } from '../../../../neb-document/src/components/neb-base-document-controller';
import * as popupOptions from '../../../../neb-document/src/components/neb-document-popup-options';
import * as documentRoutingUtil from '../../../../neb-document/src/utils/neb-document-routing-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store, connect } from '../../../../neb-redux/neb-redux-store';
import { Dirty } from '../../../../neb-redux/services/dirty';
import { connectNavigation } from '../../../../neb-route/neb-nav-mixin';
import { navigate } from '../../../../neb-route/neb-route-state';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import { setDocumentCount } from '../../store/patientsAction';

export const OVERLAY_TITLE = 'Associate Encounter';
export const OVERLAY_DESCRIPTION =
  'Choose the encounter(s) you would like associated to the selected document(s)';

const BANNER_SUCCESS = 'Document(s) and Encounter(s) associated successfully';
const BANNER_ERROR =
  'An error occurred when saving the Document(s) and Encounter(s) Association';

export const ID_DOCUMENT_CONTENT = 'document-content';

const path = '/patients/:patId/documents/:docId';

export const Page = Object.freeze({
  LIST: 'list',
  INFO: 'info',
  UPLOAD: 'upload',
  EDIT: 'edit',
});
export const ELEMENTS = {
  content: {
    id: 'content',
  },
  list: {
    id: 'list',
  },
  info: {
    id: 'info',
  },
};
const DOCUMENTS_BULK_SELECT_ONE_POPUP_MESSAGE =
  BULK_SELECT_ONE_POPUP_MESSAGE('documents');

export class NebPatientDocumentsController extends NebBaseDocumentController(
  connectNavigation(store, path)(connect(store)(LitElement)),
) {
  static get properties() {
    return {
      __touchDevice: Boolean,
      page: String,
      visible: {
        type: Boolean,
        reflect: true,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      medium: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__touchDevice = false;
    this.__dirty = new Dirty(
      store.getState().route.hash.replace('#', ''),
      () => !this._shouldNavigate(),
    );

    this.page = Page.LIST;
    this.elements = {};
    this.pageRenderMap = {
      [Page.LIST]: this.__renderList,
      [Page.INFO]: this.__renderInfo,
      [Page.UPLOAD]: this.__renderUpload,
      [Page.EDIT]: this.__renderEdit,
    };
  }

  __initHandlers() {
    this.__handlers = {
      openUploadPanel: () => {
        this.page = Page.UPLOAD;
      },
      closeUploadPanel: popupResult => {
        this.page = Page.LIST;
        this.baseHandlers.handlePopupUploadResult(popupResult.result);
      },
      openEditPanel: () => {
        this.page = Page.EDIT;
      },
      closeEditPanel: popupResult => {
        if (
          popupResult &&
          (popupResult.result === popupOptions.ActionResult.SAVED ||
            popupResult.result === popupOptions.ActionResult.DELETED)
        ) {
          this.page = Page.LIST;
          this.__selectDocumentOnListRendered = true;
          this.baseHandlers.handlePopupEditResult(popupResult.result);
          store.dispatch(navigate(`#/patients/${this.patientId}/documents`));
        } else {
          this.page = Page.INFO;
        }
      },
      selectDocumentIndex: async index => {
        if (this.documents[index]) {
          const documentId = this.documents[index].id;
          await this.baseHandlers.selectDocumentIndex(-1);
          await store.dispatch(
            navigate(`#/patients/${this.patientId}/documents/${documentId}`),
          );

          this.baseHandlers.selectDocumentIndex(index);
          this.page = Page.INFO;
        }
      },
      documentListRendered: count => {
        if (
          this.__selectDocumentOnListRendered &&
          this.documents.length === count
        ) {
          this.__selectDocumentOnListRendered = false;

          this._scrollToDocument(this.__getSelectedDocumentIndex());
        }
      },
      openEncountersOverlay: async () => {
        const { patientId } = this;
        const {
          appointmentTypes: { items: apptTypes },
          providers: { item: providers },
        } = store.getState();
        const checkedDocuments = this.__getCheckedDocuments();

        if (!checkedDocuments || checkedDocuments.length <= 0) {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Bulk Actions',
            message: DOCUMENTS_BULK_SELECT_ONE_POPUP_MESSAGE,
          });

          return;
        }

        const encounters = await openOverlay(
          OVERLAY_KEYS.ASSOCIATE_ENCOUNTERS,
          {
            patientId,
            apptTypes,
            providers,
            title: OVERLAY_TITLE,
            description: OVERLAY_DESCRIPTION,
          },
        );

        if (encounters) {
          const docs = this.__getAssociationDocuments(
            encounters,
            checkedDocuments,
          );

          try {
            await associateEncounters(patientId, docs);
            this.baseHandlers.deselectAll();

            store.dispatch(openSuccess(BANNER_SUCCESS));
          } catch (_) {
            store.dispatch(openError(BANNER_ERROR));
          }
        }
      },
      getBulkActions: () => [
        ...this.baseHandlers.getBulkActions(),
        {
          id: 'associateEncounters',
          label: 'Associate Encounter(s)',
          onSelect: this.__handlers.openEncountersOverlay,
        },
      ],
      openPopupScan: async () => {
        const popupResult = await openPopup(POPUP_RENDER_KEYS.SCAN, {
          patientId: this.patientId,
          encounterId: null,
        });

        await this.baseHandlers.handlePopupUploadResult(popupResult.result);
        return popupResult;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__dirty.connect();
  }

  disconnectedCallback() {
    this.__dirty.disconnect();

    super.disconnectedCallback();
  }

  __initialFetch() {
    this.searchTerms = '';
    this.advancedSearchModel = {};
    this.selectedIndex = -1;
    this.documents = [];
    return this.__getInitialDocuments();
  }

  _getTouchDevice() {
    return this.__touchDevice;
  }

  _selectIndexOnReset() {
    if (this.small) {
      this.selectedIndex = -1;
    } else {
      super._selectIndexOnReset();
    }
  }

  _scrollToDocument(index) {
    if (index < 0 || index >= this.documents.length) {
      return;
    }

    if (this.elements.list) {
      this.elements.list.scrollToDocument(index);
    }
  }

  _shouldNavigate() {
    if (this.small) {
      return !(
        this.elements.documentContent && this.elements.documentContent.isDirty
      );
    }

    return true;
  }

  _stateChanged({ layoutMedia, route }) {
    this.__touchDevice = layoutMedia.touchDevice;

    this.__updateDocumentByRoute(route.hash);
  }

  __updateDocumentByRoute(url) {
    if (this.patientId && this.__routeUrl === url) {
      return;
    }

    const newUrl = documentRoutingUtil.parseUrl(url);

    if (
      !this.small &&
      newUrl.navigationResult ===
        documentRoutingUtil.NavigationResult.PATIENT_DOCUMENT_INFO
    ) {
      this.__selectDocument(parseInt(newUrl.id, 10), false);
    }

    if (
      newUrl.navigationResult ===
      documentRoutingUtil.NavigationResult.PATIENT_DOCUMENT_LIST
    ) {
      this.page = Page.LIST;
    }

    if (this.small) {
      const oldUrl = documentRoutingUtil.parseUrl(this.__routeUrl);

      if (
        newUrl.navigationResult ===
          documentRoutingUtil.NavigationResult.PATIENT_DOCUMENT_LIST &&
        oldUrl.navigationResult ===
          documentRoutingUtil.NavigationResult.PATIENT_DOCUMENT_INFO
      ) {
        this.__selectDocumentOnListRendered = true;
      }
    }

    this.__routeUrl = url;
  }

  getAppointmentTypes() {
    const {
      appointmentTypes: { items: apptTypes },
    } = store.getState();

    return apptTypes;
  }

  async updated(changedProps) {
    if (changedProps.has('patientId') || changedProps.has('visible')) {
      if (this.patientId && this.visible) {
        await this.__initialFetch();
      }

      if (!this.visible) {
        const count = await super.getDocumentCount(this.patientId);

        this.__updateDocumentsCount(count);
      }

      this.__resolveElementReferences();
    }

    if (changedProps.has('page') && this.page) {
      this.__resolveElementReferences();
    }

    if (changedProps.has('totalDocuments')) {
      store.dispatch(setDocumentCount(this.totalDocuments));

      sendRefreshNotification([REFRESH_CHANGE_TYPE.DOCUMENTS], this.patientId);
    }

    return super.updated(changedProps);
  }

  __resolveElementReferences() {
    this.elements = {
      documentContent: this.shadowRoot.getElementById(ELEMENTS.content.id),
      list: this.shadowRoot.getElementById(ELEMENTS.list.id),
      info: this.shadowRoot.getElementById(ELEMENTS.info.id),
    };
  }

  __getAssociationDocuments(encounters, checkedDocuments) {
    return encounters.reduce((memo, encounter) => {
      checkedDocuments.map(documentId =>
        memo.push({
          documentId,
          encounterId: encounter.id,
        }),
      );

      return memo;
    }, []);
  }

  async __getAllDocumentsForSelectAll() {
    this.__isLoadingDocuments = true;

    const result = await this.__getDocuments(
      this.patientId,
      this.totalDocuments,
      0,
      this.searchTerms,
      null,
    );

    if (result) {
      this.documents = result.data;
    }

    this.__isLoadingDocuments = false;
  }

  async __setCheckboxSelection(checked) {
    if (this.documents.length < this.totalDocuments && checked) {
      await this.__getAllDocumentsForSelectAll();
    }
    this.__checkedDocuments = this.documents.reduce(
      (memo, document) => ({
        ...memo,
        [document.id]: checked,
      }),
      {},
    );
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host(:not([small])) .panel-left {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          flex: 0 0 315px;
        }

        .panel-right {
          width: 100%;
        }

        :host(:not([small])) .panel-right {
          padding: 0px 40px;
          flex: 1 0 0;
          overflow-x: hidden;
        }
      </style>
    `;
  }

  __renderInfo() {
    return html`
      ${this.__renderStyles()}

      <neb-documents-info
        id="${ELEMENTS.info.id}"
        class="panel-right"
        .expanded="${true}"
        .noDocuments="${this.__noDocuments}"
        .selectedDocument="${this.__selectedDocument}"
        .imageSrcSpec="${this.__imageSrcSpec}"
        .loadingImageSrcSpec="${this.__loadingImageSrc}"
        .showLocalLoading="${this.showLocalLoading}"
        .onOpenPopupEdit="${this.small
          ? this.__handlers.openEditPanel
          : this.baseHandlers.openPopupEdit}"
        .small="${this.small}"
        .medium="${this.medium}"
      ></neb-documents-info>
    `;
  }

  __renderUpload() {
    return html`
      <neb-document-content
        id="${ELEMENTS.content.id}"
        .model="${this.__getDefaultDocumentModel()}"
        .initialModel="${this.__getDefaultDocumentModel()}"
        .patientId="${this.patientId}"
        .small="${this.small}"
        .touchDevice="${this.__touchDevice}"
        .onClose="${this.__handlers.closeUploadPanel}"
      ></neb-document-content>
    `;
  }

  __renderEdit() {
    return html`
      <neb-document-content
        id="${ELEMENTS.content.id}"
        .model="${mapToDocumentModel(this.getSelectedItem())}"
        .initialModel="${mapToDocumentModel(this.getSelectedItem())}"
        .patientId="${this.patientId}"
        .small="${this.small}"
        .touchDevice="${this.__touchDevice}"
        .onClose="${this.__handlers.closeEditPanel}"
      ></neb-document-content>
    `;
  }

  __renderList() {
    return html`
      <neb-documents-list
        id="${ELEMENTS.list.id}"
        class="panel-left"
        ?small="${this.small}"
        .totalDocuments="${this.totalDocuments}"
        .selectedIndex="${this.selectedIndex}"
        .documents="${this.documents}"
        .expanded="${true}"
        .noDocuments="${this.__noDocuments}"
        .onSearch="${this.baseHandlers.search}"
        .searchText="${this.searchTerms}"
        .advancedSearchModel="${this.advancedSearchModel}"
        .onAdvancedSearch="${this.baseHandlers.advancedSearch}"
        .isLoadingDocuments="${this.__isLoadingDocuments}"
        .imageSrcSpec="${this.__imageSrcSpec}"
        .loadingImageSrcSpec="${this.__loadingImageSrc}"
        .onOpenPopupUpload="${this.small
          ? this.__handlers.openUploadPanel
          : this.baseHandlers.openPopupUpload}"
        .onOpenPopupScan="${this.__handlers.openPopupScan}"
        .onRequestDocuments="${this.baseHandlers.getMoreDocuments}"
        .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
        .onDocumentListRendered="${this.__handlers.documentListRendered}"
        .checkedDocuments="${this.__checkedDocuments}"
        .onGetBulkActions="${this.__handlers.getBulkActions}"
        .onCheckDocument="${this.baseHandlers.checkDocument}"
        .appointmentTypes="${this.getAppointmentTypes()}"
        .displayEncounterFilter="${true}"
      ></neb-documents-list>
    `;
  }

  render() {
    if (this.visible) {
      if (!this.small) {
        return html`
          ${this.__renderStyles()}
          <div class="content">
            ${this.__renderList()} ${this.__renderInfo()}
          </div>
        `;
      }
      return this.pageRenderMap[this.page].call(this);
    }
    return html``;
  }
}

customElements.define(
  'neb-patient-documents-controller',
  NebPatientDocumentsController,
);
