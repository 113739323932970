import { isMoment } from 'moment-timezone';

import { openDirtyDialog, SAVE_BUTTON, STAY_BUTTON } from '../neb-popup';

export const PERIOD = {
  AM: 'am',
  PM: 'pm',
};

export const arrayToObject = (arr, idProp = 'id') =>
  arr.reduce((result, x) => {
    result[x[idProp]] = x;
    return result;
  }, {});

export function extents(ext1, ext2) {
  return {
    min: ext1 < ext2 ? ext1 : ext2,
    max: ext1 > ext2 ? ext1 : ext2,
  };
}

export function clamp(value, extent1, extent2) {
  const min = extent1 < extent2 ? extent1 : extent2;
  const max = extent1 > extent2 ? extent1 : extent2;
  return Math.max(min, Math.min(value, max));
}

export function wrap(value, extent1, extent2) {
  const min = extent1 < extent2 ? extent1 : extent2;
  const max = extent1 > extent2 ? extent1 : extent2;
  const count = max - min;

  if (value >= max) {
    const normalizedValue = value - min;
    return (normalizedValue % count) + min;
  }

  if (value < min) {
    const normalizedValue = min - value;
    const remainder = normalizedValue % count;
    const compliment = count - remainder;
    return (compliment % count) + min;
  }

  return value;
}

export function hoursToObj(v) {
  return {
    hours: v >= 1 ? Math.floor(v >= 13 ? v - 12 : v) : 12,
    minutes: Math.round((v - Math.floor(v)) * 60),
    period: v >= 12 ? PERIOD.PM : PERIOD.AM,
  };
}

export function objToHours(v) {
  const hours =
    v.hours !== 12 || v.period === PERIOD.PM ? parseInt(v.hours, 10) : 0;
  const offset = v.period === PERIOD.PM && v.hours !== 12 ? 12 : 0;
  const minutes = v.minutes / 60;

  return hours + minutes + offset;
}

export function range(count, offset = 0) {
  return count
    ? new Array(count).fill(offset).map((offset, index) => index + offset)
    : [];
}

export function summateRange(items, startIndex, count, stride = 20) {
  const endIndex = startIndex + count;
  return (
    items
      .slice(startIndex, endIndex)
      .reduce((accum, curr, _index) => accum + curr + stride, 0) - stride
  );
}

export function summate(items, stride = 20) {
  return summateRange(items, 0, items.length, stride);
}

export function decompose(index, lists) {
  return lists.reduce((accum, curr, groupIndex) => {
    if (typeof accum === 'object') {
      return accum;
    }

    if (accum < curr.length) {
      return { groupIndex, itemIndex: accum };
    }

    return accum - curr.length;
  }, index);
}

export function padArray(arr, filler) {
  return arr.length ? arr : [filler];
}

export function insert(items, index, value) {
  if (clamp(index, 0, items.length) !== index) {
    throw new RangeError(`Invalid index: ${index}`);
  }

  const startItems = items.slice(0, index);
  const endItems = items.slice(index, items.length);
  return [...startItems, value, ...endItems];
}

export function moveItem(arr, fromIndex, toIndex) {
  const target = arr[fromIndex];
  const result = [...arr];

  result.splice(fromIndex, 1);
  result.splice(toIndex, 0, target);
  return result;
}

export function swapItems(arr, index1, index2) {
  const result = [...arr];

  result.splice(index1, 1);
  result.splice(index1, 0, arr[index2]);
  result.splice(index2, 1);
  result.splice(index2, 0, arr[index1]);
  return result;
}

export function getValueByPath(obj, keyPath) {
  return keyPath.reduce(
    (obj, key) => (obj !== undefined && obj !== null ? obj[key] : undefined),
    obj,
  );
}

export function setValueByPath(obj, keyPath, value) {
  keyPath.reduce((subObj, key, index) => {
    if (index === keyPath.length - 1) {
      subObj[key] = value;
    }
    return subObj[key];
  }, obj);
}

export function traverse(obj, onKey) {
  const path = [''];

  const fn = target => {
    Object.entries(target).forEach(([k, v]) => {
      path[path.length - 1] = k;
      const dateType = v instanceof Date || isMoment(v);
      const clip = onKey([...path], v) === false;
      const updatedVal = getValueByPath(obj, path);
      const nonNullObj = updatedVal !== null && typeof updatedVal === 'object';

      if (!clip && !dateType && nonNullObj) {
        path.push('');
        fn(updatedVal);
        path.pop();
      }
    });
  };

  fn(obj);
}

export function map(obj, onKey) {
  const result = Array.isArray(obj) ? [] : {};
  traverse(obj, (keyPath, value) => {
    const dateType = value instanceof Date || isMoment(value);

    if (!dateType && value !== null && typeof value === 'object') {
      setValueByPath(result, keyPath, Array.isArray(value) ? [] : {});
    } else {
      setValueByPath(result, keyPath, onKey(keyPath, value));
    }
  });

  return result;
}

export function deepCopy(obj) {
  return map(obj, (_, value) => value);
}

export function merge(obj, mods) {
  const copy = deepCopy(obj);

  traverse(mods, (keyPath, value) => {
    new Array(keyPath.length)
      .fill(0)
      .map((_, index) => index + 1)
      .map(endIndex => keyPath.slice(0, endIndex))
      .forEach(path => {
        const copyValue = getValueByPath(copy, path);
        const modValue = getValueByPath(mods, path);

        if (copyValue === undefined) {
          setValueByPath(copy, path, modValue);
        }
      });

    if (typeof value !== 'object') {
      setValueByPath(copy, keyPath, value);
    }
  });

  return copy;
}

export function hasErrors(errors) {
  if (typeof errors === 'object') {
    let result = false;

    traverse(errors, (_, value) => {
      if (!result && typeof value !== 'object') {
        result = !!value;
        return !result;
      }

      return undefined;
    });

    return result;
  }

  return !!errors;
}

export function getTabWithError(tabs, errors, selectedTab) {
  const tabsWithErrors = Object.entries(tabs)
    .reduce(
      (memo, [key, value]) => [
        ...memo,
        value.fields.some(field => hasErrors(errors[field])) ? key : '',
      ],
      [],
    )
    .filter(item => item);

  return tabsWithErrors.includes(selectedTab) ? selectedTab : tabsWithErrors[0];
}

export const uniq = arr => [...new Set(arr)];

export const uniqArrayWithObjects = arr => {
  const map = new Map();
  arr.forEach(obj => map.set(JSON.stringify(obj), obj));
  arr = [...map.values()];
  return arr;
};

export function timeToInt(time) {
  const { period } = time;
  const hours = parseInt(time.hours, 10);
  const minutes = parseInt(time.minutes, 10);

  const offsetHours = hours === 12;
  const intHours = offsetHours ? 0 : parseInt(hours, 10) * 12;
  const intMins = parseInt(minutes, 10) / 5;
  const int = intHours + intMins;

  return period === 'am' ? int : int + 144;
}

export function intToTime(int) {
  let hours = Math.floor(int / 12);

  const minutes = (int * 5) % 60;
  const period = int < 144 ? 'am' : 'pm';

  if (hours > 12) {
    hours -= 12;
  }

  const padMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return {
    hours: `${hours || 12}`,
    minutes: `${padMinutes}`,
    period,
  };
}

export const handleDirtyDialogLogic = async ({
  params = {},
  isDirty = false,
  formIsValid = true,
  onSave = () => {},
  onDiscard = () => {},
  onStay = () => {},
}) => {
  const option = !isDirty || (await openDirtyDialog({ formIsValid }));

  if (!option || option === STAY_BUTTON.name) {
    return onStay(params);
  }

  if (option === SAVE_BUTTON.name) {
    return onSave(params);
  }

  return onDiscard(params);
};
