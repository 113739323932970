/* istanbul ignore file */
import * as Userpilot from 'userpilot';

const { NEB_ENV: { USERPILOT_API_KEY, USERPILOT_ENABLED } = {} } = window;

const enabled = USERPILOT_ENABLED === 'true' && USERPILOT_API_KEY !== '';

function initialize() {
  if (!enabled) {
    return;
  }

  Userpilot.Userpilot.initialize(USERPILOT_API_KEY);
}

function identifyUser({
  email,
  firstName,
  lastName,
  practiceName,
  role,
  salesforceId,
  tenantId,
  tier,
  addOns,
  app,
}) {
  if (!enabled) {
    return;
  }

  const params = {
    name: `${firstName} ${lastName}`,
    email,
    company: {
      id: tenantId,
      name: practiceName,
      salesforceId,
      tier,
      addOns,
    },
    role,
    app,
  };

  Userpilot.Userpilot.identify(email, params);
}

export { initialize, identifyUser };
