import '../../../neb-material-design/src/components/neb-loading-spinner';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  label: { id: 'label' },
};

class NebProgressBlocker extends LitElement {
  static get properties() {
    return {
      label: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.6);
          z-index: 2;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-template-rows: 1fr auto auto 3fr;
          justify-items: center;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.label = '';
  }

  render() {
    return html`
      <div class="container">
        <div></div>
        <neb-loading-spinner></neb-loading-spinner>
        <span id="${ELEMENTS.label.id}">${this.label}</span>
        <div></div>
      </div>
    `;
  }
}

window.customElements.define('neb-progress-blocker', NebProgressBlocker);
