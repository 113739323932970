import { navigate } from '@neb/router';
import { html, css } from 'lit';

import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import {
  getPatientRelationships,
  getPatientRelationshipsActiveGroup,
} from '../../../../neb-api-client/src/patient-relationship-api-client';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../neb-lit-components/src/components/neb-page-collection';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_WHITE } from '../../../../neb-styles/neb-variables';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import '../../../../../src/components/tables/billing/neb-table-patient-relationships';
import { MODE } from '../../../../neb-utils/table';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  patientRelationshipsTable: { id: 'patient-relationships-table' },
};
export const NO_ITEMS_TEXT =
  'There are no relationships for this patient. Click "Add New Relationship" to add a relationship.';

export const LABEL_NONE_FOUND =
  'There are no active relationships for this patient.';

export const TABLE_CONFIG = [
  {
    truncate: true,
    key: 'patientName',
    label: 'Name',
    flex: css`1.5 0 0`,
  },
  {
    truncate: true,
    key: 'type',
    label: 'Relation',
    flex: css`1 0 0`,
  },
  {
    truncate: true,
    key: 'isPrimaryLabel',
    label: 'Primary',
    flex: css`1 0 0`,
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`1 0 0`,
    formatter: v => {
      if (v === null) return '';
      return v ? 'Active' : 'Inactive';
    },
  },
];

const uniq = arr => [...new Set(arr)];

class NebPatientRelationshipsPage extends CollectionPage {
  static get properties() {
    return {
      patientId: String,
      __disableAddButton: Boolean,
    };
  }

  initState() {
    super.initState();

    this.patientId = null;
    this.__disableAddButton = true;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: async () => {
        const resultItem = await openOverlay(this.getConfig().overlayKey, {
          context: this.buildContext(),
        });

        if (resultItem) {
          const items = await this.fetch();
          this.service.setItems(items);

          sendRefreshNotification([REFRESH_CHANGE_TYPE.PATIENT]);
        }
      },
      selectItem: async (_, item) => {
        const resultItem = await openOverlay(this.getConfig().overlayKey, {
          context: this.buildContext(),
          ...item,
        });

        if (resultItem) {
          const items = await this.fetch();
          this.service.setItems(items);

          sendRefreshNotification([REFRESH_CHANGE_TYPE.PATIENT]);
        }
      },
      navigateToPatientRelationships: async patientId => {
        navigate(`/patients/${patientId}/billing/relationships`);

        this.patientId = patientId;

        const items = await this.fetch();
        this.service.setItems(items);
      },
    };
  }

  async disconnectedCallback() {
    await this.changeOccurred(
      {
        hideInactive: true,
      },
      true,
    );

    super.disconnectedCallback();
  }

  buildContext() {
    return {
      patientId: this.patientId,
    };
  }

  static get config() {
    return {
      unifyForm: true,
      singularName: 'New Relationship',
      showInactiveFilter: true,
      description: '',
      searchLabel: '',
      tableConfig: TABLE_CONFIG,
      overlayKey: OVERLAY_KEYS.RELATIONSHIP,
    };
  }

  async fetch() {
    let relationshipsData = await getPatientRelationships(this.patientId);

    const isNotPrimary = !!relationshipsData.find(
      d => !d.isPrimary && d.active,
    );

    let primaryRelationshipsData = [];

    if (isNotPrimary) {
      const relationshipGroup = await getPatientRelationshipsActiveGroup(
        this.patientId,
      );

      primaryRelationshipsData = await getPatientRelationships(
        relationshipGroup.primary,
        {
          active: true,
        },
      );

      primaryRelationshipsData = primaryRelationshipsData.filter(
        r =>
          r.patientId !== this.patientId &&
          r.relatedPatientId !== this.patientId,
      );
    }

    let sortedRelationships = [];
    let relationships = [];

    if (relationshipsData.length > 0) {
      relationshipsData = [
        {
          active: !!relationshipsData.find(d => d.active),
          id: '',
          isPrimary: isNotPrimary,
          note: '',
          patientId: this.patientId,
          relatedPatientId: this.patientId,
          type: 'Self',
        },
        ...relationshipsData,
        ...primaryRelationshipsData.map(r => ({
          ...r,
          type: 'Via Primary',
        })),
      ];

      const patientIds = uniq(relationshipsData.map(x => x.relatedPatientId));

      const patients = await patientApiClient.fetchSome(patientIds);

      relationships = relationshipsData.map(relationship => {
        const patient = patients.find(
          ({ id }) => id === relationship.relatedPatientId,
        );
        const primaryLabel =
          relationship.isPrimary || !relationship.active ? 'No' : 'Yes';

        const patientName = objToName(patient.name, DEFAULT_NAME_OPTS);

        return {
          ...relationship,
          patientName,
          isPrimaryLabel: primaryLabel,
          relatedPatient: patient,
        };
      });

      const [firstRow, ...otherRows] = relationships;
      sortedRelationships = [firstRow, ...otherRows.sort(this.__sortCompare)];
    }

    this.__disableAddButton = this.__shouldDisableAddButton(relationships);

    return sortedRelationships;
  }

  __sortCompare(a, b) {
    let comparison = 0;

    if (a.patientName > b.patientName) {
      comparison = 1;
    } else if (a.patientName < b.patientName) {
      comparison = -1;
    }
    return comparison;
  }

  __shouldDisableAddButton(relationships) {
    if (relationships.length > 0) {
      const foundSecondary = relationships
        .filter(r => r.active)
        .find(
          (relationship, index) =>
            relationship.isPrimary === false && index > 0,
        );

      if (foundSecondary) return true;
    }

    return false;
  }

  async updated(changedProps) {
    if (changedProps.has('patientId')) {
      const items = await this.fetch();

      this.service.setItems(items);
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow-y: auto;
          background-color: ${CSS_COLOR_WHITE};
          height: 100%;
        }

        .row-form {
          padding-top: 0;
        }
      `,
    ];
  }

  renderTable() {
    return html`
      <neb-table-patient-relationships
        id="${ELEMENTS.patientRelationshipsTable.id}"
        class="cell-spacer"
        mode="${MODE.DETAIL}"
        emptyMessage="${this.__renderTableMessage()}"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .model="${this.__tableState.pageItems}"
        .sortParams="${this.__tableState.sortParams}"
        .onSelectRow="${this.handlers.selectItem}"
        .onSelectPatient="${this.handlers.navigateToPatientRelationships}"
        .onChange="${this.handlers.tableChange}"
      >
      </neb-table-patient-relationships>
    `;
  }

  __renderTableMessage() {
    return this.getMasterCount()
      ? LABEL_NONE_FOUND
      : this.renderNoItemsContent();
  }

  renderNoItemsContent() {
    return NO_ITEMS_TEXT;
  }

  changeOccurred({ hideInactive }, navigateAway = false) {
    this.onChange(
      hideInactive,
      this.__tableState.filteredCount > 0
        ? this.__tableState.filteredCount - 1
        : 0,
      navigateAway,
    );
  }

  renderContent() {
    return html`
      <div class="row row-margins">
        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          class="cell"
          .label="Add ${this.getConfig().singularName}"
          .onClick="${this.handlers.addItem}"
          ?disabled="${this.__disableAddButton}"
        ></neb-button-action>
        <div class="cell cell-spacer"></div>
      </div>

      <div class="row">${this.renderTable()}</div>

      ${this.renderFooter()} ${this.renderPagination()}
    `;
  }
}

customElements.define(
  'neb-patient-relationships-page',
  NebPatientRelationshipsPage,
);
