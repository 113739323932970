import { addNDCFields } from '../../src/api-clients/charges';
import * as feeSchedulesApi from '../../src/api-clients/fee-schedules';

export const TYPE = {
  FEE: 'feeForService',
  PERCENT: 'percentFeeForService',
  CAPITATED: 'capitated',
};

export const DISPLAY_TYPE = {
  [TYPE.FEE]: 'Fee for Service',
  [TYPE.PERCENT]: '% of Fee for Service',
  [TYPE.CAPITATED]: 'Capitated',
};

export const MENU_ITEMS = [
  {
    label: DISPLAY_TYPE[TYPE.FEE],
    data: { id: TYPE.FEE },
  },
  {
    label: DISPLAY_TYPE[TYPE.PERCENT],
    data: { id: TYPE.PERCENT },
  },
  {
    label: DISPLAY_TYPE[TYPE.CAPITATED],
    data: { id: TYPE.CAPITATED },
  },
];

export function mapToMenuItems(items) {
  return items.map(data => ({
    label: `${data.name}${!data.active ? ' (Inactive)' : ''}`,
    data,
  }));
}

function mapCharge(
  charge,
  feeScheduleCharge,
  feeScheduleName,
  activeCharges = [],
) {
  const amount = charge.amount || charge.unitCharge;
  const units = charge.units ? charge.units : 1;
  const foundCharge = activeCharges.find(c => c.id === charge.chargeId);
  const formattedCharge = {
    ...charge,
    ...(foundCharge ? addNDCFields(foundCharge) : undefined),
    feeScheduleName: null,
    feeScheduleCharge: null,
    allowedAmount: null,
    billedAmount: amount * units,
  };

  if (feeScheduleCharge) {
    return {
      ...formattedCharge,
      ...(feeScheduleCharge.feeScheduleChargeModifiers && {
        modifiers: feeScheduleCharge.feeScheduleChargeModifiers,
        suppressFromClaim: feeScheduleCharge.suppressFromClaim,
      }),
      feeScheduleName: feeScheduleCharge.name || feeScheduleName,
      feeScheduleCharge: feeScheduleCharge.amount,
      allowedAmount: feeScheduleCharge.allowedAmount,
      billedAmount: feeScheduleCharge.amount * units,
    };
  }

  return formattedCharge;
}

export function mapSettingsCharge(charge, feeSchedule) {
  const feeScheduleCharge = feeSchedule
    ? charge.feeScheduleCharges.find(
        fsc => fsc.feeScheduleId === feeSchedule.id,
      )
    : null;

  return mapCharge(charge, feeScheduleCharge);
}

export function mapSettingsCharges(charges, feeSchedule) {
  return charges.map(charge => mapSettingsCharge(charge, feeSchedule));
}

export function mapEncounterCharge(charge, feeSchedule, activeCharges = []) {
  if (!charge.postedToLedgerId) {
    const opts = feeSchedule
      ? {
          feeScheduleName: feeSchedule.name,
          feeScheduleCharge: feeSchedule.charges.find(
            c => c.id === charge.chargeId,
          ),
        }
      : {};

    return mapCharge(
      charge,
      opts.feeScheduleCharge,
      opts.feeScheduleName,
      activeCharges,
    );
  }

  return charge;
}

const __mapToFeeScheduleCharges = charges =>
  charges.map(charge => ({
    id: charge.id,
    active: charge.active,
    procedure: charge.procedure,
    description: charge.description,
    baseAmount: charge.baseAmount,
    amount: charge.amount,
    allowedAmount: charge.amount - charge.adjustmentAmount,
    adjustmentAmount: charge.adjustmentAmount,
    modifiers: charge.modifiers
      ? charge.modifiers
      : [
          charge.modifier_1,
          charge.modifier_2,
          charge.modifier_3,
          charge.modifier_4,
        ],
    suppressFromClaim: charge.suppressFromClaim,
  }));

export const mapToModel = raw => ({
  id: raw.id,
  referenceId: raw.referenceId || '',
  patientAdjustmentCodeId: raw.patientAdjustmentCodeId,
  payerAdjustmentCodeId: raw.payerAdjustmentCodeId,
  active: raw.active,
  type: raw.type,
  name: raw.name,
  rate: raw.rate || 0,
  charges: raw.charges ? __mapToFeeScheduleCharges(raw.charges) : [],
  payerPlans: raw.payerPlans,
  default: raw.default,
});

export const getFeeSchedule = (
  patientId,
  appointmentId,
  optOutLoadingIndicator = false,
) =>
  feeSchedulesApi.fetchDefaultForEncounter(
    patientId,
    appointmentId,
    optOutLoadingIndicator,
  );
