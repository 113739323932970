import '../../../../packages/neb-lit-components/src/components/encounter-diagnoses/neb-encounter-diagnoses-summary';

import { css, html } from 'lit';

import { CSS_COLOR_YELLOW } from '../../../styles';
import { formatDiagnosesTitle } from '../neb-charting-util';
import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
  warningIcon: { id: 'warning-icon' },
};

class NebUnsignedEncounterDiagnosesSummary extends NebUnsignedEncounterSummaryPanel {
  static get properties() {
    return {
      encounter: { type: Object },
      showWarnings: { type: Boolean },
      dateOfOnsetFF: { type: Boolean },

      __title: { type: String },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon-warning {
          width: 24px;
          height: 20px;
          padding-left: 5px;
          vertical-align: bottom;
          fill: ${CSS_COLOR_YELLOW};
        }
      `,
    ];
  }

  createModel() {
    return [];
  }

  getTitle() {
    return this.__title;
  }

  initState() {
    super.initState();

    this.encounter = {};
    this.showWarnings = false;
    this.dateOfOnsetFF = false;

    this.__title = '';
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (
      changedProps.has('encounter') ||
      changedProps.has('model') ||
      changedProps.has('dateOfOnsetFF')
    ) {
      this.__title = formatDiagnosesTitle({
        diagnosesLength: this.model.length,
        ...this.encounter,
        dateOfOnsetFF: this.dateOfOnsetFF,
      });
    }
  }

  renderWarnings() {
    return this.showWarnings
      ? html`
          <neb-icon
            id="${ELEMENTS.warningIcon.id}"
            class="icon-warning"
            icon="neb:warning"
          ></neb-icon>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-encounter-diagnoses-summary
        id="${ELEMENTS.view.id}"
        .model="${this.model}"
      ></neb-encounter-diagnoses-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-diagnoses-summary',
  NebUnsignedEncounterDiagnosesSummary,
);
