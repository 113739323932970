export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const SET_PATIENT_DOCUMENT_COUNT = 'SET_PATIENT_DOCUMENT_COUNT';
export const SET_PATIENT_CASE_COUNT = 'SET_PATIENT_CASE_COUNT';

export function searchPatients(search) {
  return {
    type: SEARCH_PATIENTS,
    search,
  };
}

export function setDocumentCount(count) {
  return {
    type: SET_PATIENT_DOCUMENT_COUNT,
    count,
  };
}

export function setCaseCount(count) {
  return {
    type: SET_PATIENT_CASE_COUNT,
    count,
  };
}
