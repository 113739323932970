import { getPracticeUser } from '../../../neb-api-client/src/permissions-api-client';
import { FULL_DATE_FORMAT } from '../../../neb-input/nebFormatUtils';
import { store } from '../../../neb-redux/neb-redux-store';
import { parseDate, FORMATS } from '../../../neb-utils/date-util';
import { objToName } from '../../../neb-utils/formatters';

import { isOverlayOpen } from './misc';
import { openOverlay, OVERLAY_KEYS } from './overlay-constants';

export const isEncounterSummaryOpened = () => {
  const {
    popup: { overlays: popupOverlays },
  } = store.getState();
  return isOverlayOpen(popupOverlays, OVERLAY_KEYS.ENCOUNTER_SUMMARY.name);
};

export const openEncounterSummary = ({
  patient,
  appointmentTypeId,
  encounterId,
  patientImageSrc,
  runningLedger = false,
}) => {
  const {
    appointmentTypes: { items: appointmentTypes } = {},
    providers: { item: providers } = {},
  } = store.getState();

  if (isEncounterSummaryOpened()) {
    return undefined;
  }

  return openOverlay(OVERLAY_KEYS.ENCOUNTER_SUMMARY, {
    ...(encounterId && { encounterId }),
    encounterAppointmentTypeId: appointmentTypeId,
    patient,
    ...(patientImageSrc && { patientImageSrc }),
    appointmentTypes,
    providers,
    runningLedger,
  });
};

const __getUser = async userId => {
  const providers = store.getState().providers.item;
  const user =
    providers.find(provider => provider.id === userId) ||
    (await getPracticeUser(userId));
  return user;
};

const __getEncounterUserName = user => (user ? objToName(user.name) : '');

const __computeEncounterStatusLine = ({ prefix, user, date }) => {
  const dateTime = parseDate(date);
  return `${prefix} ${__getEncounterUserName(user)} on ${dateTime.format(
    FULL_DATE_FORMAT,
  )} at ${dateTime.format(FORMATS.timeAmPm)}`;
};

export const getEncounterStatus = async encounter => {
  const openedUser = await __getUser(encounter.openedBy);

  const encounterStatus = __computeEncounterStatusLine({
    prefix: 'Opened by',
    user: openedUser,
    date: encounter.openedOn,
  });

  if (!encounter.signed) {
    return encounterStatus;
  }

  const signedUser = await __getUser(encounter.signerId);
  return `${encounterStatus}\n${__computeEncounterStatusLine({
    prefix: 'Signed by',
    user: signedUser,
    date: encounter.signedOn,
  })}`;
};
