import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { ERA_ACTIONS } from '../../../utils/era-eob';
import { associateCharges } from '../../era';

export default ({
  model: { id: eraId, reportId },
  lineItemId,
  lineItemReportId,
  claimReportId,
}) =>
  Promise.all([
    updateERAReport(eraId, {
      eraAction: ERA_ACTIONS.MATCH_CHARGE,
      lineItemReportId,
      claimReportId,
      lineItemId,
    }),
    associateCharges({
      version: 2,
      lineItemIds: [lineItemId],
      eraId,
      reportId,
    }),
  ]);
