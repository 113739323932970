import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../../../../neb-styles/neb-variables';
import {
  formatCurrency,
  isEmpty,
  getMatchingServiceTypes,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkAmountText: {
    id: 'in-network-amount-text',
  },
  outOfNetworkAmountText: {
    id: 'out-of-network-amount-text',
  },
  serviceTypesText: {
    id: 'service-types-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
};
export const NO_CONTENT_TEXT = 'There are no copays for this plan.';

class NebPatientInsuranceCopaysSummary extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      copays: {
        type: Array,
      },
      allServiceTypes: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.copays = [];
    this.allServiceTypes = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .box-container {
          padding: 0 10px;
        }

        :host([layout='small']) .box-container {
          padding: 0;
        }

        .copay-container {
          padding-top: ${CSS_SPACING};
        }

        .copay-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .cst-title {
          display: flex;
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 0;
        }
      `,
    ];
  }

  __renderInNetworkAmount(inNetworkAmount, idx) {
    if (isEmpty(inNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkAmountText.id}_${idx}">
        In Network: ${formatCurrency(inNetworkAmount)}
      </div>
    `;
  }

  __renderOutOfNetworkAmount(outOfNetworkAmount, idx) {
    if (isEmpty(outOfNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkAmountText.id}_${idx}">
        Out of Network: ${formatCurrency(outOfNetworkAmount)}
      </div>
    `;
  }

  __renderServiceTypes(serviceTypes, idx) {
    if (serviceTypes.length === 0) return '';
    return html`
      <div class="bold">Service Types</div>
      <div id="${ELEMENTS.serviceTypesText.id}_${idx}">
        ${getMatchingServiceTypes(serviceTypes, this.allServiceTypes)}
      </div>
    `;
  }

  __renderAdditionalDetails(copay, idx) {
    return html`
      <div class="flex-one">
        ${this.__renderServiceTypes(copay.serviceTypes, idx)}
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderCopays() {
    return this.copays.map((copay, idx) => {
      if (
        !copay.coverageLevel ||
        !copay.coverageLevel.code ||
        copay.coverageLevel.code === 'NA'
      ) {
        return html`
          <div id="${ELEMENTS.noContentText.id}" class="no-content">
            ${NO_CONTENT_TEXT}
          </div>
        `;
      }
      return html`
        <div class="copay-container">
          <div class="copay-row">
            <div class="flex-one">
              <div
                class="bold cst-title"
                id="${ELEMENTS.coverageLevelText.id}_${idx}"
              >
                ${copay.coverageLevel.description}
                ${this.__renderDefaultIndicator(copay)}
              </div>

              ${this.__renderInNetworkAmount(copay.inNetworkAmount, idx)}
              ${this.__renderOutOfNetworkAmount(copay.outOfNetworkAmount, idx)}
            </div>

            ${this.__renderAdditionalDetails(copay, idx)}
          </div>
        </div>
      `;
    });
  }

  render() {
    return html` <div class="box-container">${this.__renderCopays()}</div> `;
  }
}

customElements.define(
  'neb-patient-insurance-copays-summary',
  NebPatientInsuranceCopaysSummary,
);
