import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const getProblemList = async patientId => {
  const res = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/problem-list',
    replacements: { patientId },
  });

  return res.data;
};
