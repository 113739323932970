import moment from 'moment-timezone';

const TIMEZONES = [
  ...moment.tz.zonesForCountry('US'),
  ...moment.tz.zonesForCountry('VI'),
  ...moment.tz.zonesForCountry('AS'),
  'America/Halifax',
]
  .sort((a, b) =>
    moment
      .tz(b)
      .format(`Z[${b.replace(/^[^/]*\//, '').replace(/_/g, ' ')}]`)
      .localeCompare(
        moment
          .tz(a)
          .format(`Z[${a.replace(/^[^/]*\//, '').replace(/_/g, ' ')}]`),
      ),
  )
  .map(z => ({
    value: z,
    label: moment
      .tz(z)
      .format(`z[ - ${z.replace(/^[^/]*\//, '').replace(/_/g, ' ')} (GMT ]Z)`),
  }));

export { TIMEZONES };
