import { css, html } from 'lit';

import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import { CSS_COLOR_HIGHLIGHT } from '../../../styles';
import {
  LOCATION_KEYS,
  getLocationValue,
} from '../../../utils/locations/location-util';

const HIDDEN_ICON = 'neb:visibilityOff';

const DATE_FORMAT = 'MM/DD/YYYY';
const NOT_AVAILABLE = '-';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkbox: { selector: '[id^=checkbox-]' },
  claimLinks: { selector: '[id^=claim-link-]' },
  claimIcons: { selector: '[id^=claim-icon-]' },
  invoiceLinks: { selector: '[id^=invoice-link-]' },
  patientLinks: { selector: '[id^=patient-link-]' },
  payerLinks: { selector: '[id^=payer-link-]' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  iconHidden: { selector: '.icon-hidden' },
};

class NebTableAllClaims extends Table {
  static get properties() {
    return {
      patientId: String,
      locations: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .icon-hidden {
          width: 20px;
          height: 20px;
          margin-top: 5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          margin-top: 2px;
          margin-right: 5px;
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.mode = MODE.NONE;
    this.showDetail = true;
    this.isAllSelected = true;
    this.config = [];
    this.locations = [];
    this.patientId = '';

    this.onCheck = () => {};

    this.onSelectAll = () => {};

    this.onSelectAllOnCurrentPage = () => {};

    this.onDeselectAll = () => {};

    this.onConfirmHide = () => {};

    this.onConfirmShow = () => {};

    this.onOpenLink = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      checkItem: ({ idx, id, value }) => {
        this.onCheck({ idx, id, value });
      },
      click: e => e.stopPropagation(),
      openLink: overlayConfig => this.onOpenLink(overlayConfig),
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.onSelectAll,
        },
        {
          id: 'selectAllOnCurrentPage',
          label: 'Select All on Current Page',
          onSelect: this.onSelectAllOnCurrentPage,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.onDeselectAll,
        },
        {
          id: 'hide',
          label: 'Hide Worklist Items',
          icon: 'neb:visibilityOff',
          onSelect: this.onConfirmHide,
        },
        {
          id: 'show',
          label: 'Show Worklist Items',
          icon: 'neb:visible',
          onSelect: this.onConfirmShow,
        },
      ],
    };
  }

  __renderCheckbox(value, rowIndex) {
    const { id } = this.model[rowIndex];
    return html`
      <neb-checkbox
        id="checkbox-${rowIndex}"
        .name="${rowIndex}"
        .onChange="${() =>
          this.handlers.checkItem({ idx: rowIndex, id, value })}"
        @click="${this.handlers.click}"
        ?checked="${value}"
      ></neb-checkbox>
    `;
  }

  __renderMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.bulkActionMenu.id}"
        align="left"
        class="ellipsis"
        .forceDownMenu="${true}"
        .onClick="${this.handlers.getBulkActions}"
      >
      </neb-button-actions>
    `;
  }

  __getRenderLinkId(key, rowIndex) {
    let renderLinkKey;

    switch (key) {
      case 'claimNumber':
        renderLinkKey = 'claim';
        break;

      case 'patient':
        renderLinkKey = 'patient';
        break;

      case 'insurancePayerAlias':
        renderLinkKey = 'payer';
        break;

      case 'invoiceNumber':
        renderLinkKey = 'invoice';
        break;

      default:
    }
    return `${renderLinkKey}-link-${rowIndex}`;
  }

  __renderClaimIcon(rowIndex) {
    const icon = this.model[rowIndex].isElectronic
      ? 'neb:electronicClaims'
      : 'neb:paperClaims';

    return html`
      <neb-icon
        id="claim-icon-${rowIndex}"
        class="icon icon-claim"
        .icon="${icon}"
      ></neb-icon>
    `;
  }

  __renderLink(key, value, rowIndex) {
    const overlayConfig = {
      key,
      rowIndex,
    };

    const renderLinkId = this.__getRenderLinkId(key, rowIndex);

    return html`
      ${key === 'claimNumber' ? this.__renderClaimIcon(rowIndex) : ''}

      <neb-text
        id="${renderLinkId}"
        .name="${overlayConfig}"
        .onClick="${this.handlers.openLink}"
        link
        bold
        .trailingIcon="${this.__renderBillingNoteIcon({ rowIndex, key })}"
        >${value}</neb-text
      >
    `;
  }

  __renderBillingNoteIcon({ rowIndex, key }) {
    if (key !== 'invoiceNumber' && key !== 'claimNumber') return '';

    const hasBillingNote =
      this.model[rowIndex][
        key === 'invoiceNumber'
          ? 'hasInvoiceBillingNote'
          : 'hasClaimBillingNote'
      ];

    return hasBillingNote ? 'neb:editNote' : '';
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderMenu();

      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (true) {
      case columnConfig.key === 'checked':
        return this.__renderCheckbox(value, rowIndex);

      case [
        'claimNumber',
        'patient',
        'insurancePayerAlias',
        'invoiceNumber',
      ].includes(columnConfig.key):
        return this.__renderLink(columnConfig.key, value, rowIndex);

      case columnConfig.key === 'locationId': {
        return html`
          <neb-text
            >${getLocationValue(
              this.locations,
              value,
              LOCATION_KEYS.NAME,
            )}</neb-text
          >
        `;
      }

      case columnConfig.key === 'hidden':
        return value
          ? html`
              <neb-icon class="icon-hidden" .icon="${HIDDEN_ICON}"></neb-icon>
            `
          : '';

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }

  __getTableConfig() {
    return [
      {
        flex: css`.3 0 0`,
        key: 'checked',
      },
      {
        key: 'hidden',
        label: '',
        flex: css`0 0 24px`,
        truncate: true,
      },
      {
        flex: css`1.5 0 0`,
        key: 'invoiceNumber',
        label: 'Invoice ID',
        sortable: true,
      },
      {
        flex: css`1.5 0 0`,
        key: 'claimNumber',
        label: 'Claim ID',
        sortable: true,
      },
      {
        flex: css`1.5 0 0`,
        key: 'dateOfService',
        label: 'Service Date',
        sortable: true,
        formatter: value =>
          value ? parseDate(value).format(DATE_FORMAT) : NOT_AVAILABLE,
      },
      {
        flex: css`1.5 0 0`,
        key: 'dateGenerated',
        label: 'Date Created',
        sortable: true,
        formatter: value =>
          value ? parseDate(value).format(DATE_FORMAT) : NOT_AVAILABLE,
      },
      {
        flex: css`1.25 0 0`,
        key: 'locationId',
        label: 'Location',
      },
      ...(this.patientId
        ? []
        : [
            {
              flex: css`1.5 0 0`,
              key: 'patient',
              label: 'Patient',
              sortable: true,
              formatter: value => objToName(value, DEFAULT_NAME_OPTS),
            },
          ]),
      {
        flex: css`1 0 0`,
        key: 'insurancePayerAlias',
        label: 'Payer',
      },
      {
        flex: css`1 0 0`,
        key: 'provider',
        label: 'Provider',
        formatter: value => objToName(value, DEFAULT_NAME_OPTS),
      },
      {
        flex: css`1 0 0`,
        key: 'amount',
        label: 'Amount',
        formatter: value => centsToCurrency(value),
      },
      {
        flex: css`1 0 0`,
        key: 'batchId',
        label: 'Batch ID',
        sortable: true,
        formatter: value => value || NOT_AVAILABLE,
      },
      {
        flex: css`1 0 0`,
        key: 'status',
        label: 'Status',
      },
    ];
  }

  render() {
    this.config = this.__getTableConfig();

    return html`
      <div class="container">
        ${this.__renderHeader()} ${this.__renderContent()}
      </div>
    `;
  }
}

customElements.define('neb-table-all-claims', NebTableAllClaims);
