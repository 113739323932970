import { getAppointmentTypesPublic } from '../../../../neb-api-client/src/appointment-types';
import {
  getOnlineBookingPublic,
  getPracticeInfoPublic,
} from '../../../../neb-api-client/src/online-booking';

import { getAvailableProviders } from './availability-util';

export function deduplicate(arr, key) {
  const result = new Map();

  arr.forEach(item => {
    if (result.has(item[key])) return;

    result.set(item[key], item);
  });

  return [...result.values()];
}

/* eslint-disable complexity */
function servicesNotLoaded(state) {
  return (
    !state ||
    !state.services ||
    !state.services.serviceItems ||
    !state.services.serviceItems.length
  );
}

function practiceInfoNotLoaded(state) {
  return (
    !state ||
    !state.practiceInfo ||
    !state.practiceInfo.practiceInfoItems ||
    !state.practiceInfo.practiceInfoItems.length
  );
}

function onlineBookingNotLoaded(state) {
  return !state || !state.onlineBooking || !state.onlineBooking.item;
}

export async function getBookingWalkInOverridesV2(state) {
  if (!state || !state.booking || !state.booking.tenantShortName) {
    return { enableBooking: null, enableWalkIn: null, enableSelfCheckIn: null };
  }

  const appointmentTypes = servicesNotLoaded(state)
    ? await getAppointmentTypesPublic(state.booking.tenantShortName)
    : state.services.serviceItems;

  const onlineBooking = onlineBookingNotLoaded(state)
    ? await getOnlineBookingPublic(state.booking.tenantShortName)
    : state.onlineBooking.item;

  const { locations } = practiceInfoNotLoaded(state)
    ? await getPracticeInfoPublic(state.booking.tenantShortName)
    : state.practiceInfo.practiceInfoItems[0];

  const {
    allowOnlineBooking: enableBookingTenantLevel,
    walkInAppointments: enableWalkInTenantLevel,
    enableSelfCheckIn: enableSelfCheckInTenantLevel,
  } = onlineBooking;

  const enableBookingApptLevel = appointmentTypes.some(
    type => type.visibleOnline,
  );

  const enableWalkInApptLevel = appointmentTypes.some(
    type => type.walkInEnabled,
  );

  const enableBookingLocationLevel = locations.some(
    loc => loc.allowOnlineBooking,
  );

  const enableWalkInLocationLevel = locations.some(
    loc => loc.allowWalkInAppointments,
  );

  return {
    enableBooking:
      enableBookingTenantLevel &&
      enableBookingApptLevel &&
      enableBookingLocationLevel,
    enableWalkIn:
      enableWalkInTenantLevel &&
      enableWalkInApptLevel &&
      enableWalkInLocationLevel,
    enableSelfCheckIn: enableSelfCheckInTenantLevel,
  };
}

export function appointmentTypeCanBeScheduled(
  appointmentTypeId,
  appointmentTypeLocations,
  state,
) {
  const {
    providers: { item: providers },
    providersAvailability: { item: providersAvailability },
    services: { serviceItems },
  } = state;

  let validServices = [];

  if (providersAvailability && providers) {
    const servicesWithAvailableProviders = serviceItems.filter(service => {
      const availableProviders = getAvailableProviders(
        providers,
        providersAvailability,
        service.id,
      );

      return availableProviders.length;
    });

    const { locations } = state.practiceInfo.practiceInfoItems[0];

    validServices = servicesWithAvailableProviders
      .filter(service =>
        appointmentTypeLocations.some(
          relation =>
            relation.appointmentTypeId === service.id &&
            locations
              .filter(loc => loc.active && loc.allowOnlineBooking)
              .some(location => location.id === relation.locationId),
        ),
      )
      .filter(service => service.visibleOnline);
  }

  return !!validServices.find(item => item.id === appointmentTypeId);
}
