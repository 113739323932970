import Debouncer from '../../packages/neb-utils/debouncer';
import { REFETCH_INTERVAL } from '../../packages/neb-utils/env';

import { popupOpened } from './popup-util';

const USER_INACTIVE_TIMEOUT = 3600000;

export class PollController {
  constructor(host, cb, opts = { timeout: REFETCH_INTERVAL, force: false }) {
    this.host = host;
    this.cb = cb;
    this.timeout = opts.timeout || REFETCH_INTERVAL;
    this.userActive = true;
    this.force = opts.force || false;

    this.timeoutDebouncer = new Debouncer(() => {
      this.userActive = false;
    }, USER_INACTIVE_TIMEOUT);

    host.addController(this);
  }

  hostConnected() {
    this.host.addEventListener('mousemove', () => {
      this.userActive = true;
      this.timeoutDebouncer.debounce();
    });

    this._timer = setInterval(async () => {
      if (this.userActive) {
        try {
          if (!popupOpened() || this.force) {
            await this.cb();
          }
        } catch (e) {
          if (e.statusCode === 401) {
            this.userActive = false;
          }
        }
      }
    }, this.timeout);
  }

  hostDisconnected() {
    clearInterval(this._timer);

    this._timer = undefined;
  }
}
