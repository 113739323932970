import '../../components/misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { AnimateController, animate, fadeIn } from '@lit-labs/motion';
import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../styles';

export const BASE_ELEMENTS = {
  container: { id: 'container' },
  content: { id: 'content' },
  header: { id: 'header' },
  loadingSpinner: { id: 'loading-spinner' },
  title: { id: 'title' },
  toggle: { id: 'toggle' },
};

export class NebUnsignedEncounterSummaryPanel extends LitElement {
  static get properties() {
    return {
      __focus: { type: Boolean },

      loading: { type: Boolean },
      model: { type: Object },
      shouldAnimate: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          height: 100%;
          width: 100%;

          background-color: ${CSS_COLOR_WHITE};
          border-radius: 4px;
          border: 1px solid ${CSS_COLOR_WHITE};
          overflow: hidden;
        }

        .container {
          display: flex;
          flex-direction: column;

          height: 100%;
          width: 100%;

          cursor: pointer;
          overflow: none;
        }

        :host(:hover) {
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
        }

        :host(:hover) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .header {
          display: flex;
          justify-content: space-between;
          margin: 10px 10px 5px 10px;
        }

        .title {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          height: 24px;
          width: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .content {
          flex: 1 0 0;

          overflow-y: auto;
        }
      `,
    ];
  }

  createModel() {
    return {};
  }

  constructor() {
    super();

    this.initState();
    this.initControllers();
    this.initHandlers();
  }

  initState() {
    this.__focus = false;

    this.loading = false;
    this.model = this.createModel();
    this.shouldAnimate = false;
  }

  initControllers() {
    this.__animateController = new AnimateController(this, {
      defaultOptions: {
        keyframeOptions: {
          duration: 650,
          fill: 'both',
        },

        in: fadeIn,
      },
    });
  }

  initHandlers() {
    this.handlers = {
      blur: () => {
        this.__focus = false;
      },
      focus: () => {
        this.__focus = true;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('mouseover', this.handlers.focus);
    this.addEventListener('mouseleave', this.handlers.blur);
  }

  update(changedProps) {
    if (changedProps.has('shouldAnimate')) {
      this.__animateController.disabled = !this.shouldAnimate;
    }

    super.update(changedProps);
  }

  getTitle() {
    return '';
  }

  renderWarnings() {
    return '';
  }

  renderHeader() {
    return html`
      <div id="${BASE_ELEMENTS.title.id}" class="title">
        ${this.getTitle()}${this.renderWarnings()}
      </div>

      <neb-icon
        id="${BASE_ELEMENTS.toggle.id}"
        class="icon"
        icon="neb:expand"
        ?hover="${this.__focus}"
      ></neb-icon>
    `;
  }

  renderContent() {
    return html``;
  }

  renderLoadingSpinner() {
    return this.loading
      ? html`
          <neb-loading-overlay
            id="${BASE_ELEMENTS.loadingSpinner.id}"
            showDelay="0"
            .show="${this.loading}"
          ></neb-loading-overlay>
        `
      : '';
  }

  render() {
    return html`
      <div id="${BASE_ELEMENTS.container.id}" class="container">
        <div id="${BASE_ELEMENTS.header.id}" class="header" ${animate()}>
          ${this.renderHeader()}
        </div>

        <div id="${BASE_ELEMENTS.content.id}" class="content" ${animate()}>
          ${this.renderContent()} ${this.renderLoadingSpinner()}
        </div>
      </div>
    `;
  }
}
