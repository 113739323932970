import '../../inputs/neb-select';
import '../../neb-radio-button';

import equal from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../neb-styles/neb-variables';
import { ITEM_NONE, ITEM_SELF } from '../../../../../neb-utils/neb-ledger-util';
import { ITEM_EMPTY, select } from '../../../../../neb-utils/selectors';

export const INVOICE_TYPE = {
  EXISTING: 'existing',
  GENERATE: 'generate',
};
export const ELEMENTS = {
  generateInvoiceRadioButton: {
    id: 'generate-invoice-radio-button',
  },
  existingInvoiceRadioButton: {
    id: 'existing-invoice-radio-button',
  },
  guarantorDropdown: {
    id: 'guarantor-dropdown',
  },
  invoiceDropdown: {
    id: 'invoice-dropdown',
  },
  attributeToDropdown: {
    id: 'attribute-to-dropdown',
  },
};

export const NO_INVOICES_TEXT = 'No open invoices.';
export class NebLedgerInvoiceSection extends LitElement {
  static get properties() {
    return {
      __users: Array,

      isAutoGenerateInvoice: Boolean,
      invoiceId: String,
      itemsMap: Object,
      selectedAttributeTo: Object,
      model: Object,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__users = [];

    this.isAutoGenerateInvoice = false;
    this.invoiceId = '';
    this.itemsMap = {
      guarantors: [],
      invoices: [],
      attributeTo: [],
    };

    this.selectedAttributeTo = null;
    this.model = { radio: INVOICE_TYPE.GENERATE };
    this.layout = '';

    this.onChangeRadio = () => {};

    this.onChangeAttributeTo = () => {};

    this.onChangeGuarantor = () => {};

    this.onChangeInvoice = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeRadio: value => this.onChangeRadio(value),
      selectGuarantor: ({ value }) => this.onChangeGuarantor(value),
      selectAttributeTo: ({ value }) => this.onChangeAttributeTo(value),
      selectInvoice: ({ value }) => {
        if (value === NO_INVOICES_TEXT) {
          return undefined;
        }

        return this.onChangeInvoice(value);
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([layout='small']) .container-dropdown {
          grid-template-columns: repeat(1, 1fr);
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-dropdown {
          display: grid;
          padding: 10px ${CSS_SPACING} ${CSS_SPACING} 23px;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: ${CSS_SPACING};
        }

        .container-radio {
          margin: ${CSS_SPACING} 10px 0 15px;
        }
      `,
    ];
  }

  static createSelectors(itemsMap) {
    return {
      invoice: select(itemsMap.invoices, ITEM_EMPTY, {
        clipPristine: true,
        validators: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              state.radio === INVOICE_TYPE.EXISTING
                ? !equal(v, ITEM_EMPTY)
                : equal(v, ITEM_EMPTY),
          },
        ],
      }),
      guarantor: select(itemsMap.guarantors, ITEM_SELF, { validators: [] }),
      ...(itemsMap.attributeTo &&
        itemsMap.attributeTo.length && {
          attributeTo: select(itemsMap.attributeTo, ITEM_NONE, {
            clipPristine: true,
            validators: [],
          }),
        }),
    };
  }

  get __guarantors() {
    return this.itemsMap.guarantors.filter(
      guarantor => equal(ITEM_SELF, guarantor) || guarantor.data.active,
    );
  }

  __renderGuarantorDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.guarantorDropdown.id}"
        class="dropdown"
        label="Guarantor"
        helper="Required"
        .value="${this.model.guarantor}"
        .items="${this.__guarantors}"
        .onChange="${this.__handlers.selectGuarantor}"
        ?disabled="${this.model.radio === INVOICE_TYPE.EXISTING}"
      ></neb-select>
    `;
  }

  __getMessageRenderer() {
    return !this.itemsMap.invoices.length
      ? ({ label }) => html`
          <p
            style="margin: 0 0 0 16px; font-size: 16px; color: ${CSS_COLOR_HIGHLIGHT}"
          >
            ${label}
          </p>
        `
      : null;
  }

  __renderInvoiceDropdown() {
    const helper =
      this.model.radio === INVOICE_TYPE.EXISTING ? 'Required' : ' ';

    return html`
      <neb-select
        id="${ELEMENTS.invoiceDropdown.id}"
        class="dropdown"
        label="Invoice"
        .error="${this.model.radio === INVOICE_TYPE.EXISTING &&
        !this.model.invoice.label}"
        .value="${this.model.invoice}"
        .items="${this.itemsMap.invoices.length
          ? this.itemsMap.invoices
          : [NO_INVOICES_TEXT]}"
        .helper="${helper}"
        .onChange="${this.__handlers.selectInvoice}"
        .onRenderItem="${this.__getMessageRenderer()}"
        ?disabled="${this.model.radio === INVOICE_TYPE.GENERATE ||
        !!this.invoiceId}"
      ></neb-select>
    `;
  }

  __renderAttributeToDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.attributeToDropdown.id}"
        class="dropdown"
        helper=" "
        label="Attribute To"
        .value="${this.model.attributeTo}"
        .items="${this.itemsMap.attributeTo}"
        .onChange="${this.__handlers.selectAttributeTo}"
      ></neb-select>
    `;
  }

  render() {
    return html`
      <div class="container-radio">
        <neb-radio-button
          id="${ELEMENTS.generateInvoiceRadioButton.id}"
          label="Generate New Invoice"
          .value="${INVOICE_TYPE.GENERATE}"
          .onSelect="${this.__handlers.changeRadio}"
          ?checked="${this.model.radio === INVOICE_TYPE.GENERATE}"
          ?disabled="${this.isAutoGenerateInvoice || !!this.invoiceId}"
        >
        </neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.existingInvoiceRadioButton.id}"
          label="Add to Existing Invoice"
          .value="${INVOICE_TYPE.EXISTING}"
          .onSelect="${this.__handlers.changeRadio}"
          ?disabled="${this.isAutoGenerateInvoice || !!this.invoiceId}"
          ?checked="${this.model.radio === INVOICE_TYPE.EXISTING}"
        >
        </neb-radio-button>
      </div>

      <div class="container-dropdown">
        ${this.__renderGuarantorDropdown()}${this.__renderInvoiceDropdown()}${this
          .itemsMap.attributeTo && this.itemsMap.attributeTo.length > 0
          ? this.__renderAttributeToDropdown()
          : ''}
      </div>
    `;
  }
}
customElements.define('neb-ledger-invoice-section', NebLedgerInvoiceSection);
