import { html, css, LitElement } from 'lit';

import {
  CSS_COLOR_GREY_2,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  textArea: { id: 'textarea' },
  viewer: { id: 'viewer' },
};

class NebMacroAnswerPreview extends LitElement {
  static get properties() {
    return {
      content: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.content = '';

    this.onBlur = () => {};

    this.onFocus = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      textChanged: e => this.onChange(e.currentTarget.value),
      focusChanged: e => (e.type === 'focus' ? this.onFocus() : this.onBlur()),
    };
  }

  firstUpdated() {
    this.__elements = {
      textarea: this.shadowRoot.getElementById(ELEMENTS.textArea.id),
    };
  }

  updated(changedProps) {
    if (changedProps.has('content')) {
      this.__elements.textarea.value = this.content;
    }
  }

  static get styles() {
    return css`
      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      :host {
        display: block;
      }

      .container {
        outline: none;
        resize: none;
        width: 100%;
        padding: 8px;
        border: 1px solid ${CSS_COLOR_GREY_2};
        border-radius: 4px;
        font-family: ${CSS_FONT_FAMILY};
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      .container:focus {
        border-color: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  render() {
    return html`
      <textarea
        id="${ELEMENTS.textArea.id}"
        class="container"
        .value="${this.content}"
        @blur="${this.__handlers.focusChanged}"
        @focus="${this.__handlers.focusChanged}"
        @keyup="${this.__handlers.textChanged}"
      >
${this.content}</textarea
      >
    `;
  }
}

window.customElements.define('neb-macro-answer-preview', NebMacroAnswerPreview);
