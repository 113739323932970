import { html, LitElement } from 'lit';

export const SpinnerSize = Object.freeze({
  SMALL: 8,
  MEDIUM: 10,
  NORMAL: 20,
});
const SpinnerElementSize = [
  {
    radius: SpinnerSize.SMALL,
    circularLength: 24,
  },
  {
    radius: SpinnerSize.MEDIUM,
    circularLength: 50,
  },
  {
    radius: SpinnerSize.NORMAL,
    circularLength: 100,
  },
];
export const ID_SVG = 'circular-svg';
const ERROR_INVALID_RADIUS = `Invalid radius value. Supported values: ${
  SpinnerSize.SMALL
}, ${SpinnerSize.MEDIUM}, ${SpinnerSize.NORMAL}`;

class NebLoadingSpinnerResizable extends LitElement {
  static get properties() {
    return {
      radius: Number,
      __circularLength: Number,
      __centerPosition: Number,
      __radius: Number,
    };
  }

  constructor() {
    super();

    this.__updateSizeByRadius(SpinnerSize.NORMAL);
  }

  __updateSizeByRadius(radius) {
    this.__validateRadius(radius);

    const size = SpinnerElementSize.find(item => item.radius === radius);

    if (size) {
      this.__radius = radius;
      this.__circularLength = size.circularLength;
      this.__centerPosition = Math.round(this.__circularLength / 2);
    }
  }

  __validateRadius(radius) {
    const spinnerSizes = new Set([
      SpinnerSize.SMALL,
      SpinnerSize.MEDIUM,
      SpinnerSize.NORMAL,
    ]);
    if (spinnerSizes.has(radius)) return;

    throw new Error(ERROR_INVALID_RADIUS);
  }

  updated(changedProps) {
    if (changedProps.has('radius') && this.radius) {
      this.__updateSizeByRadius(this.radius);
    }
  }

  __renderStyles() {
    return html`
      <style>
        :host .circular {
          animation: rotate 2s linear infinite;
          height: ${this.__circularLength}px;
          position: relative;
          width: ${this.__circularLength}px;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .path {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
          animation: dash 1.5s ease-in-out infinite;
          stroke-linecap: round;
          stroke: var(--neb-color-primary-4);
        }

        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }

        @keyframes color {
          100%,
          0% {
            stroke: #d62d20;
          }
          40% {
            stroke: #0057e7;
          }
          66% {
            stroke: #008744;
          }
          80%,
          90% {
            stroke: #ffa700;
          }
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <svg id="${ID_SVG}" class="circular" height="100px" width="100px">
        <circle
          class="path"
          cx="${this.__centerPosition}"
          cy="${this.__centerPosition}"
          r="${this.__radius}"
          fill="none"
          stroke-width="3"
          stroke-miterlimit="10"
        />
      </svg>
    `;
  }
}

window.customElements.define(
  'neb-loading-spinner-resizable',
  NebLoadingSpinnerResizable,
);
