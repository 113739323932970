import { fetchOne } from '../settings-api-client';

export const AUTO_ALLOCATE_PATIENT_PAYMENT_MODE = Object.freeze({
  FIFO: 0,
  LIFO: 1,
});

export const PRACTICE_SETTINGS = Object.freeze({
  AUTO_INVOICE: 'autoInvoice',
  AUTO_PAT_RESPONSIBILITY: 'autoPatResponsibility',
  AUTO_ALLOCATE_PATIENT_PAYMENT: 'autoAllocatePatientPayment',
  AUTO_ALLOCATE_PATIENT_PAYMENT_MODE: 'autoAllocatePatientPaymentMode',
  AUTO_DIAGNOSIS_POINTING: 'autoDiagnosisPointing',
  ENABLE_DEFAULT_SUPERBILL_COMMENT: 'enableDefaultSuperbillComment',
  ENABLE_DEFAULT_STATEMENT_COMMENT: 'enableDefaultStatementComment',
  DEFAULT_SUPERBILL_COMMENT: 'defaultSuperbillComment',
  DEFAULT_STATEMENT_COMMENT: 'defaultStatementComment',
  AUTO_ALLOCATE_ERA_PAYMENT: 'autoAllocateERAPayment',
  AUTO_POST_CHARGES: 'autoPostCharges',
  MULTI_CARE_PACKAGE: 'multiCarePackage',
  CARE_PACKAGE_WITH_INSURANCE: 'carePackageWithInsurance',
  UPDATE_SCHEDULE_ROOM: 'updateScheduleRoom',
});

const PRACTICE_SETTINGS_DEFAULT_VALUES = Object.freeze({
  AUTO_INVOICE: true,
  AUTO_PAT_RESPONSIBILITY: true,
  AUTO_ALLOCATE_PATIENT_PAYMENT: false,
  AUTO_ALLOCATE_PATIENT_PAYMENT_MODE: AUTO_ALLOCATE_PATIENT_PAYMENT_MODE.FIFO,
  AUTO_DIAGNOSIS_POINTING: false,
  ENABLE_DEFAULT_SUPERBILL_COMMENT: false,
  ENABLE_DEFAULT_STATEMENT_COMMENT: false,
  DEFAULT_SUPERBILL_COMMENT: '',
  DEFAULT_STATEMENT_COMMENT: '',
  AUTO_ALLOCATE_ERA_PAYMENT: true,
  AUTO_POST_CHARGES: true,
  MULTI_CARE_PACKAGE: false,
  CARE_PACKAGE_WITH_INSURANCE: false,
  UPDATE_SCHEDULE_ROOM: true,
});

export const mapPracticeSettings = (practiceSettings = {}) =>
  Object.entries(PRACTICE_SETTINGS).reduce(
    (acc, [key, setting]) => ({
      ...acc,
      [setting]:
        Object.prototype.hasOwnProperty.call(practiceSettings, setting) &&
        practiceSettings[setting] !== null
          ? practiceSettings[setting]
          : PRACTICE_SETTINGS_DEFAULT_VALUES[key],
    }),
    {},
  );

export const PRACTICE_SETTINGS_MODEL = mapPracticeSettings();

export const getPracticeSettings = async () => {
  const { data: practiceSettings } = await fetchOne(true);
  return {
    ...mapPracticeSettings(practiceSettings),
    [PRACTICE_SETTINGS.AUTO_POST_CHARGES]:
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES],
  };
};
