/* eslint-disable complexity */
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../controls/inputs/neb-checkbox';

import { html, css, unsafeCSS } from 'lit';

import { fetchOne } from '../../../../packages/neb-api-client/src/ledger-statement-api-client';
import { CONFIG as CONFIG_ERRORS } from '../../../../packages/neb-lit-components/src/components/field-groups/neb-errors-cell';
import { getBaseConfig } from '../../../../packages/neb-lit-components/src/components/field-groups/neb-primary-payer-cell';
import {
  NebProviderModifiersCell,
  CONFIG,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-provider-modifiers-cell';
import {
  NebUnitsTaxCell,
  CONFIG as CONFIG_UNITS_TAX,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-units-tax-cell';
import Table from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_SPACING,
  CSS_FIELD_MARGIN,
  CSS_COLOR_GREY_2,
} from '../../../../packages/neb-styles/neb-variables';
import { getTextWidth } from '../../../../packages/neb-utils/dom';
import {
  capitalize,
  centsToCurrency,
  currencyToCents,
  currencyToCentsWithNegative,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { formatEncounterNumber } from '../../../../packages/neb-utils/neb-encounters-util';
import {
  flattenErrors,
  LINE_ITEM_TYPE,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { printPdf } from '../../../../packages/neb-utils/neb-pdf-print-util';
import { mapToPatientModel } from '../../../../packages/neb-utils/patient';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { summate } from '../../../../packages/neb-utils/utils';
import { BILLED_STATUS_LABELS } from '../../../formatters/encounter-charge-details';

import {
  ChargeAdjustmentsTable,
  CONFIG as CONFIG_ADJUSTMENTS,
} from './neb-table-charges-adjustments';
import { ChargeResponsibilityTable } from './neb-table-charges-responsibility';

const REMOVE_SPACER = {
  label: '',
  width: 20,
};

const CONFIG_KEYS = {
  ERRORS: 'errors',
  CHARGE_ENCOUNTER: 'chargeEncounter',
  PROVIDER_MODIFIERS: 'providerModifiers',
  UNITS_TAX: 'unitsTax',
  PRIMARY_PAYER: 'primaryPayer',
  LINE_ITEM_DEBITS: 'lineItemDebits',
  ADJUSTMENTS: 'adjustments',
  BALANCE: 'balance',
  ASSOCIATED_ERAS_AND_EOBS: 'associatedERAsAndEOBs',
};

const BILLED_STATUS_ITEM = Object.freeze({
  waiting: {
    label: BILLED_STATUS_LABELS.billedWaiting,
    data: { id: 'billedWaiting' },
  },
  primary: {
    label: BILLED_STATUS_LABELS.billedPrimary,
    data: { id: 'billedPrimary' },
  },
  secondary: {
    label: BILLED_STATUS_LABELS.billedSecondary,
    data: { id: 'billedSecondary' },
  },
  received: {
    label: BILLED_STATUS_LABELS.billedPaymentReceived,
    data: { id: 'billedPaymentReceived' },
  },
  paid: {
    label: BILLED_STATUS_LABELS.billedPaidInFull,
    data: { id: 'billedPaidInFull' },
  },
});

export const BILLED_STATUS_ITEMS = Object.values(BILLED_STATUS_ITEM);

const HOLD_STATUS_ITEM = Object.freeze({
  statement: { label: 'ST - Patient Statement', data: { id: 'holdStatement' } },
  superbill: { label: 'SB - Superbill', data: { id: 'holdSuperBill' } },
  claim: { label: 'C - Claim', data: { id: 'holdClaim' } },
});

const HOLD_STATUS_ITEMS = Object.values(HOLD_STATUS_ITEM);

export const ELEMENTS = {
  chargeNumbers: {
    selector: '[id^=charge-number-]',
  },
  billedStatus: {
    selector: '[id^=billed-status-]',
    tag: 'neb-select',
  },
  statementCheckbox: {
    selector: '[id^=statement-checkbox-]',
  },
  statementNumber: {
    selector: '[id^=statement-number-]',
  },
  holdStatus: {
    selector: '[id^=hold-status-]',
    tag: 'neb-select',
  },
  patientNames: {
    selector: '[id^=patient-name-]',
  },
  patientMRNs: {
    selector: '[id^=patient-mrn-]',
  },
  errorsCells: {
    selector: '[id^=errors-cell-]',
    tag: 'neb-errors-cell',
  },
  encounterNumbers: {
    selector: '[id^=encounter-number-]',
  },
  invoiceNumbers: {
    selector: '[id^=invoice-number-]',
    tag: 'neb-text',
  },
  claimNumbers: {
    selector: '[id^=claim-number-]',
    tag: 'neb-text',
  },
  claimStatuses: {
    selector: '[id^=claim-status-]',
    tag: 'neb-text',
  },
  providerModifiersCells: {
    selector: '[id^=provider-modifiers-cell-]',
    tag: 'neb-provider-modifiers-cell',
  },
  unitsTaxCells: {
    selector: '[id^=units-tax-cell-]',
    tag: 'neb-units-tax-cell',
  },
  primaryPayerCells: {
    selector: '[id^=primary-payer-cell-]',
    tag: 'neb-primary-payer-cell',
  },
  responsibilitiesCells: {
    selector: '[id^=responsibilities-cell-]',
    tag: 'neb-table-charges-responsibility',
  },
  addResponsibilitiesButtons: {
    selector: '[id^=add-responsibilities-button-]',
    tag: 'neb-button-action',
  },
  adjustmentCells: {
    selector: '[id^=adjustment-cell-]',
    tag: 'neb-table-charges-adjustments',
  },
  addAdjustmentButtons: {
    selector: '[id^=add-adjustment-button-]',
    tag: 'neb-button-action',
  },
  balanceCells: {
    selector: '[id^=balance-cell-]',
  },
};

function flexBasisToWidth(flex) {
  const basis = flex.cssText.split(' ')[2];
  return Number(basis.replace('px', ''));
}

export class EditChargeTable extends Table {
  static get properties() {
    return {
      hasErrors: Boolean,
      billType: String,
      isCarePackageWithInsurance: String,
      initialState: Object,
      itemsMap: Object,
      renderPatientColumn: Boolean,
      isEraEob: {
        type: Boolean,
        reflect: true,
      },
      hasRCMChangeSecondary: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --table-padding-left: ${CSS_SPACING};
          padding-bottom: 250px;
        }

        .content {
          align-items: stretch;
        }

        .cell {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-auto-rows: min-content;
          align-items: center;
        }

        .cell-data {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .cell-data:last-child {
          border-right: none;
        }

        .cell-data[key='errors'] {
          display: flex;
        }

        .cell-statement {
          display: flex;
          gap: 5px;
        }

        .cell-data[key='chargeEncounter'],
        .cell-data[key='providerModifiers'],
        .cell-data[key='unitsTax'] {
          grid-auto-rows: 72px;
        }

        .cell-data[key='balance'] {
          height: 40px;
          margin-top: ${CSS_FIELD_MARGIN};
          align-content: center;
        }

        .span-3 {
          grid-column: span 3;
        }

        .span-4 {
          grid-column: span 4;
        }

        .span-6 {
          grid-column: span 6;
        }

        .span-10 {
          grid-column: span 10;
        }

        .row-span {
          grid-row: span 2;
        }

        .button-add {
          margin-top: 19px;
          margin-bottom: ${CSS_FIELD_MARGIN};
        }

        .button-add[showSecondary] {
          grid-column: 7;
        }

        .text-bold {
          font-weight: 700;
        }

        .row-header,
        .row-data {
          padding-left: var(--table-padding-left);
        }

        .provider-modifiers-cell {
          --column-widths: var(--providerModifiers-widths);
        }
      `,
      ...Object.values(CONFIG_KEYS).map(
        item => css`
          .cell[key='${unsafeCSS(item)}'] {
            grid-template-columns: var(--columns-${unsafeCSS(item)});
          }
        `,
      ),
    ];
  }

  static createModel() {
    return [];
  }

  static createModelItemsMap() {
    return {
      adjustments: [],
      users: [],
      dx: [],
      paymentTypes: [],
      insurances: [],
      taxRates: [],
      epsdt: [],
      feeSchedules: [],
      billed: [],
      hold: [],
    };
  }

  static createSelectors(itemsMap) {
    return {
      children: {
        $: {
          format: v => {
            const billed = [];
            const hold = [];

            if (v.billedPrimary) billed.push(BILLED_STATUS_ITEM.primary);
            if (v.billedSecondary) billed.push(BILLED_STATUS_ITEM.secondary);

            if (v.billedWaiting) {
              billed.push(BILLED_STATUS_ITEM.waiting);
            }

            if (v.billedPaymentReceived) {
              billed.push(BILLED_STATUS_ITEM.received);
            }

            if (v.billedPaidInFull) {
              billed.push(BILLED_STATUS_ITEM.paid);
            }

            if (v.holdStatement) hold.push(HOLD_STATUS_ITEM.statement);
            if (v.holdSuperBill) hold.push(HOLD_STATUS_ITEM.superbill);
            if (v.holdClaim) hold.push(HOLD_STATUS_ITEM.claim);

            return {
              ...v,
              billed,
              hold,
            };
          },

          unformat: v => {
            const billedPrimary = v.billed.some(
              b => b.data.id === BILLED_STATUS_ITEM.primary.data.id,
            );

            const billedSecondary = v.billed.some(
              b => b.data.id === BILLED_STATUS_ITEM.secondary.data.id,
            );

            const billedWaiting = v.billed.some(
              b => b.data.id === BILLED_STATUS_ITEM.waiting.data.id,
            );

            const billedPaymentReceived = v.billed.some(
              b => b.data.id === BILLED_STATUS_ITEM.received.data.id,
            );

            const billedPaidInFull = v.billed.some(
              b => b.data.id === BILLED_STATUS_ITEM.paid.data.id,
            );

            const holdStatement = v.hold.some(
              h => h.data.id === HOLD_STATUS_ITEM.statement.data.id,
            );

            const holdSuperBill = v.hold.some(
              h => h.data.id === HOLD_STATUS_ITEM.superbill.data.id,
            );

            const holdClaim = v.hold.some(
              h => h.data.id === HOLD_STATUS_ITEM.claim.data.id,
            );

            return {
              ...v,
              billedWaiting,
              billedPaymentReceived,
              billedPaidInFull,
              billedPrimary,
              billedSecondary,
              holdStatement,
              holdSuperBill,
              holdClaim,
            };
          },

          children: {
            ...NebProviderModifiersCell.createSelectors(itemsMap),
            ...NebUnitsTaxCell.createSelectors(itemsMap),
            lineItemDebits: ChargeResponsibilityTable.createSelectors(
              itemsMap,
              {},
            ),
            adjustments: ChargeAdjustmentsTable.createSelectors(itemsMap),
            balance: selectors.currency(),
            billed: { clipPristine: true, unsafe: true },
            hold: { clipPristine: true, unsafe: true },
            billedPatient: selectors.createDefaultModifiers({}),
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.hasErrors = false;
    this.writable = true;
    this.billType = '';
    this.isCarePackageWithInsurance = false;
    this.initialState = EditChargeTable.createModel();
    this.itemsMap = EditChargeTable.createModelItemsMap();
    this.renderPatientColumn = false;
    this.isEraEob = false;
    this.hasRCMChangeSecondary = false;

    this.onAdd = () => {};

    this.onChange = () => {};

    this.onBlur = () => {};

    this.onRemove = () => {};

    this.onMinWidthUpdated = () => {};

    this.onSelectChargeNumber = () => {};

    this.onSelectEncounter = () => {};

    this.onSelectPayerPlan = () => {};

    this.onClickPaymentId = () => {};

    this.onClickERAsEOBs = () => {};

    this.onChangeFeeSchedule = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: e => this.onAdd(`${this.name}.${e.name}`),
      removeResponsibility: (name, item, index) => {
        const rowIndex = name.split('.')[0];
        const newIndex = this.model[rowIndex].primaryPayerId
          ? index + 1
          : index;
        this.onRemove(`${this.name}.${name}`, newIndex);
      },
      removeAdjustment: (name, item, index) =>
        this.onRemove(`${this.name}.${name}`, index),
      blur: e => {
        const namespace = [this.name, e.name]
          .filter(item => item !== '')
          .join('.');

        this.onBlur({ ...e, name: namespace });
      },
      clickChargeNumber: async ({ name = '' }) => {
        const [id, rowIndex] = name.split('.');
        const { patientId } = this.model[rowIndex];

        await this.onSelectChargeNumber({ patientId, id });
      },
      clickEncounterNumber: async ({ name = '' }) => {
        const [, rowIndex] = name.split('.');
        const {
          patientId,
          encounterCharge: { encounterId, appointmentTypeId },
        } = this.model[rowIndex];

        await this.onSelectEncounter({
          patientId,
          encounterId,
          appointmentTypeId,
        });
      },
      clickStatementNumber: statementId => printPdf(fetchOne(statementId)),
      clickERAsEOBs: rowIndex =>
        this.onClickERAsEOBs({
          associatedERAsAndEOBs: this.model[rowIndex].associatedERAsAndEOBs,
          lineItemId: this.model[rowIndex].id,
          refetchData: false,
        }),
      changeFeeSchedule: (index, feeSchedule) => {
        const result = this.onChangeFeeSchedule(index, feeSchedule);

        return result;
      },
    };
  }

  __buildConfig() {
    return [
      {
        key: CONFIG_KEYS.ERRORS,
        config: CONFIG_ERRORS,
      },
      {
        key: CONFIG_KEYS.CHARGE_ENCOUNTER,
      },
      {
        key: CONFIG_KEYS.PROVIDER_MODIFIERS,
        dynamicColumns: true,
      },
      {
        key: CONFIG_KEYS.UNITS_TAX,
        config: CONFIG_UNITS_TAX,
      },
      {
        key: CONFIG_KEYS.PRIMARY_PAYER,
        config: getBaseConfig,
      },
      {
        key: CONFIG_KEYS.LINE_ITEM_DEBITS,
      },
      {
        key: CONFIG_KEYS.ADJUSTMENTS,
      },
      {
        key: CONFIG_KEYS.BALANCE,
        config: [
          {
            label: 'Balance',
            width: 80,
          },
        ],
      },
    ];
  }

  __buildSubConfig(key) {
    const rawFlexConfigs = {
      lineItemDebits: ChargeResponsibilityTable.buildConfig({
        showSecondary: this.showSecondaryPayers(),
        showAllocated: false,
        model: this.model.flatMap(m => m.lineItemDebits),
      }),
      adjustments: CONFIG_ADJUSTMENTS,
    };

    if (rawFlexConfigs[key]) {
      return [
        ...rawFlexConfigs[key].map(item => ({
          label: item.label,
          width: flexBasisToWidth(item.flex),
        })),
        REMOVE_SPACER,
      ];
    }

    const rawWidthConfigs = {
      chargeEncounter: this.__buildChargeEncounterConfig(
        this.model.map(m => m.patient),
      ),
      providerModifiers: NebProviderModifiersCell.buildConfig(
        CONFIG(),
        this.model.map(m => m.provider),
      ),
    };

    return rawWidthConfigs[key];
  }

  __buildChargeEncounterConfig(patients) {
    const width = 96;
    const encounterNumberWidth = 100;
    const statusWidth = 230;

    let patientWidth = 96;

    patients.forEach(patient => {
      if (!patient.name) return;
      const newPatientWidth = getTextWidth(this.__getPatientName(patient));
      patientWidth = Math.max(patientWidth, newPatientWidth);
    });

    return [
      {
        label: '',
        width: 20,
      },
      {
        label: 'Charge',
        width,
      },
      ...(this.renderPatientColumn
        ? [
            {
              label: 'MRN',
              width,
            },
            {
              label: 'Patient',
              width: patientWidth,
            },
          ]
        : []),

      {
        label: 'Billed Status',
        width: 245,
      },
      {
        label: 'Hold Status',
        width: statusWidth,
      },
      {
        label: 'Encounter',
        width: encounterNumberWidth,
      },
      ...(this.isEraEob
        ? [
            {
              label: 'Invoice',
              width,
            },
            {
              label: 'Claim',
              width,
            },
            {
              label: 'Claim Status',
              width,
            },
          ]
        : []),
    ];
  }

  __computeWidths() {
    const PADDING = 20;
    const config = this.__buildConfig();

    this.config = config
      .filter(item => {
        if (item.key === 'errors') {
          this.style.setProperty('--table-padding-left', 0);
          return true;
        }
        return true;
      })
      .map(item =>
        !item.config
          ? { ...item, config: this.__buildSubConfig(item.key) }
          : item,
      )
      .map(item => {
        const dynamicWidths = [];

        const widths = item.config.map(subItem => {
          if (item.dynamicColumns) {
            dynamicWidths.push(`${subItem.width}px`);
          }

          return subItem.width;
        });

        const columns = widths.map(width => `${width}px`).join(' ');
        this.style.setProperty(`--columns-${item.key}`, columns);

        if (dynamicWidths.length) {
          this.style.setProperty(
            `--${item.key}-widths`,
            dynamicWidths.join(' '),
          );
        }

        return {
          ...item,
          flex: css`0 0 ${unsafeCSS(summate(widths) + PADDING)}px`,
        };
      });

    const widths = this.config.map(item => flexBasisToWidth(item.flex));
    const rawWidth = summate(widths) + PADDING * 2;
    const width = Math.round(rawWidth * 100) / 100;
    this.style.setProperty('width', `${width}px`);
    this.onMinWidthUpdated(width);
  }

  __getPatientName(patient) {
    const patientModel = mapToPatientModel(patient);
    return objToName(patientModel.name, {
      reverse: true,
      middleInitial: true,
    });
  }

  __debitInsuranceMatchesRowInsurance({ patientInsuranceId }, row) {
    return patientInsuranceId.data.id
      ? patientInsuranceId.data.id !== row.primaryInsuranceId
      : false;
  }

  showSecondaryPayers() {
    return this.model.some((li, index) =>
      li.lineItemDebits.some(
        lid =>
          lid.debit.payerId &&
          (lid.debit.payerId !== li.primaryPayerId ||
            this.__debitInsuranceMatchesRowInsurance(lid, this.model[index])),
      ),
    );
  }

  getSecondaryPlanItems(patientId) {
    return this.itemsMap.insurances.filter(
      item =>
        item.data.active &&
        item.data.id !== this.primaryInsuranceId &&
        item.data.patientId === patientId,
    );
  }

  getAttributeToItems(_targetItem) {
    const activeUsers = this.itemsMap.users.filter(
      u => u.data.status === 'Active',
    );
    return [selectors.ITEM_EMPTY, ...activeUsers];
  }

  getDiagnosisItems(index) {
    return this.itemsMap.dx.length
      ? this.itemsMap.dx
          .filter(
            dx =>
              this.model[index].encounterCharge &&
              dx.encounterId === this.model[index].encounterCharge.encounterId,
          )
          .map(item => ({
            item: { diagnosisCode: item.code },
            label: `${item.code} - ${item.description}`,
          }))
      : [];
  }

  firstUpdated() {
    this.__computeWidths();
  }

  update(changedProps) {
    if (
      changedProps.has('model') ||
      changedProps.has('errors') ||
      changedProps.has('hasErrors')
    ) {
      this.__computeWidths();
    }

    super.update(changedProps);
  }

  __renderErrorsCell(data_, errors_, name, index) {
    const errors = flattenErrors(this.errors[index]);

    return html`
      <neb-errors-cell
        id="errors-cell-${index}"
        name="${name}"
        .errors="${errors}"
      ></neb-errors-cell>
    `;
  }

  __renderLinkCell({ value, onClick, id, name }) {
    return html`
      <neb-text
        id="${id}"
        bold
        link
        .name="${name}"
        .onClick="${onClick}"
        class="link-cell"
        >${value}</neb-text
      >
    `;
  }

  __renderChargeNumber({ rowIndex, chargeNumber, chargeId, type }) {
    const id = `charge-number-${rowIndex}`;
    const name = `${chargeId}.${rowIndex}`;

    return type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE
      ? this.__renderLinkCell({
          id,
          name,
          value: chargeNumber,
          onClick: this.handlers.clickChargeNumber,
        })
      : html` <span id="${id}" name="${name}">${chargeNumber}</span> `;
  }

  __renderChargeEncounterCell(data, _errors, name, rowIndex) {
    const {
      chargeNumber,
      id: chargeId,
      encounterCharge,
      type,
    } = this.model[rowIndex];
    return html`
      <neb-checkbox checked disabled></neb-checkbox>

      ${this.__renderChargeNumber({
        rowIndex,
        chargeNumber,
        chargeId,
        type,
      })}
      ${this.renderPatientColumn
        ? html`
            <span id="patient-mrn-${rowIndex}"
              >${this.model[rowIndex].patient.medicalRecordNumber}</span
            >
            <span id="patient-name-${rowIndex}"
              >${this.__getPatientName(this.model[rowIndex].patient)}</span
            >
          `
        : ''}

      <neb-select
        id="billed-status-${rowIndex}"
        name="${rowIndex}.billed"
        label=""
        helper=""
        .items="${BILLED_STATUS_ITEMS}"
        .value="${this.model[rowIndex].billed}"
        .onChange="${this.handlers.change}"
        .enableClear="${true}"
        multiSelect
      ></neb-select>

      <neb-select
        id="hold-status-${rowIndex}"
        name="${rowIndex}.hold"
        label=""
        helper=""
        .items="${HOLD_STATUS_ITEMS}"
        .value="${this.model[rowIndex].hold}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      ${this.__renderLinkCell({
        id: `encounter-number-${rowIndex}`,
        value: encounterCharge ? formatEncounterNumber(encounterCharge) : '',
        name: `encounter-number.${rowIndex}`,
        onClick: this.handlers.clickEncounterNumber,
      })}

      <neb-text id="invoice-number-${rowIndex}">
        ${this.isEraEob
          ? html`
              ${this.model[rowIndex].invoiceNumber
                ? this.model[rowIndex].invoiceNumber
                : 'Not invoiced'}
            `
          : ''}
      </neb-text>

      ${this.__renderOnStatementField(rowIndex)}

      <neb-text id="claim-number-${rowIndex}">
        ${this.isEraEob ? html` ${this.model[rowIndex].claimNumber} ` : ''}
      </neb-text>

      <neb-text id="claim-status-${rowIndex}">
        ${this.isEraEob ? html` ${this.model[rowIndex].claimStatus} ` : ''}
      </neb-text>
    `;
  }

  __renderOnStatementField(rowIndex) {
    const { statement, statementId, billedPatient } = this.model[rowIndex];
    return html`
      <div
        class="cell-statement"
        style="grid-column-start: ${this.renderPatientColumn ? 5 : 3};"
      >
        <neb-checkbox
          id="statement-checkbox-${rowIndex}"
          name="${rowIndex}.billedPatient"
          ?checked="${billedPatient}"
          .onChange="${this.handlers.change}"
        >
        </neb-checkbox>
        On Statement
        <neb-text
          id="statement-number-${rowIndex}"
          link
          .onClick="${() => this.handlers.clickStatementNumber(statementId)}"
        >
          ${billedPatient ? statement?.statementNumber : ''}
        </neb-text>
      </div>
    `;
  }

  __renderProviderModifiersCell(data, errors, name, rowIndex) {
    return html`
      <neb-provider-modifiers-cell
        id="provider-modifiers-cell-${rowIndex}"
        class="span-6 row-span provider-modifiers-cell"
        name="${name}"
        .model="${this.model[rowIndex]}"
        .errors="${this.errors[rowIndex]}"
        .lineItemType="${this.model[rowIndex].type}"
        .attributeToItems="${this.model[rowIndex].purchase
          ? this.getAttributeToItems(
              this.model[rowIndex].purchase.attributeToId,
            )
          : []}"
        .diagnosisItems="${this.getDiagnosisItems(rowIndex)}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
      ></neb-provider-modifiers-cell>
    `;
  }

  __renderUnitsTaxCell(data, errors, name, rowIndex) {
    return html`
      <neb-units-tax-cell
        id="units-tax-cell-${rowIndex}"
        class="span-6 row-span"
        name="${name}"
        .isCarePackageWithInsurance="${this.isCarePackageWithInsurance}"
        .model="${this.model[rowIndex]}"
        .errors="${this.errors[rowIndex]}"
        .initialState="${this.initialState[rowIndex]}"
        .epsdtItems="${this.itemsMap.epsdt}"
        .taxRateItems="${this.itemsMap.taxRates}"
        .feeSchedules="${this.itemsMap.feeSchedules}"
        .charges="${this.itemsMap.charges}"
        .patientFeeSchedules="${this.itemsMap.patientFeeSchedules}"
        .onChangeFeeSchedule="${this.handlers.changeFeeSchedule}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
      ></neb-units-tax-cell>
    `;
  }

  __renderPrimaryPayerCell(data, errors, name, rowIndex) {
    const row = this.model[rowIndex];

    const lidIndex = this.model[rowIndex].lineItemDebits.findIndex(
      lid =>
        row.primaryPayerId &&
        lid.debit.payerId === row.primaryPayerId &&
        (lid.patientInsuranceId.data.id === row.primaryInsuranceId ||
          !row.primaryInsuranceId),
    );

    return html`
      <neb-primary-payer-cell
        id="primary-payer-cell-${rowIndex}"
        class="span-4"
        name="${rowIndex}.lineItemDebits.${lidIndex}"
        .model="${this.model[rowIndex].lineItemDebits[lidIndex]}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .errors="${this.errors[rowIndex].lineItemDebits[lidIndex]}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        .onSelectPayerPlan="${this.onSelectPayerPlan}"
        .onClickPaymentId="${this.onClickPaymentId}"
        .associatedERAsAndEOBs="${this.model[rowIndex].associatedERAsAndEOBs}"
        .onClickERAsEOBs="${() => this.handlers.clickERAsEOBs(rowIndex)}"
      ></neb-primary-payer-cell>
    `;
  }

  __renderLineItemDebitsCell(data, errors, name, rowIndex) {
    const showSecondary = this.showSecondaryPayers();
    const row = this.model[rowIndex];

    const lids = row.lineItemDebits.filter(
      lid =>
        !lid.debit.payerId ||
        lid.debit.payerId !== row.primaryPayerId ||
        this.__debitInsuranceMatchesRowInsurance(lid, row),
    );

    const errorLids = this.errors[rowIndex].lineItemDebits.filter(
      (lid, index) => {
        const lineItemDebit = row.lineItemDebits[index];

        return (
          !lineItemDebit.debit.payerId ||
          lineItemDebit.debit.payerId !== row.primaryPayerId ||
          this.__debitInsuranceMatchesRowInsurance(lineItemDebit, row)
        );
      },
    );

    return html`
      <neb-table-charges-responsibility
        id="responsibilities-cell-${rowIndex}"
        class="${showSecondary ? 'span-10' : 'span-6'}"
        name="${name}"
        .onClickPaymentId="${this.onClickPaymentId}"
        .paymentTypes="${this.itemsMap.paymentTypes}"
        .secondaryPlanItems="${this.getSecondaryPlanItems(
          this.model[rowIndex].patientId,
        )}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .billType="${this.billType}"
        .model="${lids}"
        .errors="${errorLids}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        .onRemove="${this.handlers.removeResponsibility}"
        ?showSecondary="${showSecondary}"
        .hasRCMChangeSecondary="${this.hasRCMChangeSecondary}"
      ></neb-table-charges-responsibility>
      <span></span>

      <neb-button-action
        id="add-responsibilities-button-${rowIndex}"
        class="button-add"
        name="${name}"
        label="Add Row"
        .onClick="${this.handlers.addItem}"
        ?showSecondary="${showSecondary}"
      ></neb-button-action>
    `;
  }

  __renderAdjustmentsCell(data, errors, name, rowIndex) {
    return html`
      <neb-table-charges-adjustments
        id="adjustment-cell-${rowIndex}"
        class="span-3"
        name="${name}"
        .model="${this.model[rowIndex].adjustments}"
        .errors="${errors}"
        .writeOffTypes="${this.itemsMap.adjustments}"
        .primaryPayer="${this.model[rowIndex].primaryPayer}"
        .onChange="${this.handlers.change}"
        .onBlur="${this.handlers.blur}"
        .onRemove="${this.handlers.removeAdjustment}"
      ></neb-table-charges-adjustments>

      <neb-button-action
        id="add-adjustment-button-${rowIndex}"
        class="button-add"
        name="${name}"
        label="Add Row"
        .onClick="${this.handlers.addItem}"
      ></neb-button-action>
      <span></span>
    `;
  }

  __renderBalanceCell(value, _errors, _name, rowIndex) {
    const row = this.model[rowIndex];

    const balance =
      currencyToCents(row.billedAmount) +
      currencyToCents(row.taxAmount) -
      row.adjustments.reduce(
        (sum, a) => sum + currencyToCentsWithNegative(a.amount),
        0,
      ) -
      row.lineItemDebits.reduce(
        (sum, lid) =>
          sum + lid.debit.allocations.reduce((aSum, a) => aSum + a.amount, 0),
        0,
      );

    return html`
      <span id="balance-cell-${rowIndex}">${centsToCurrency(balance)}</span>
    `;
  }

  renderHeaderCell(columnConfig) {
    return columnConfig.config.map(item => html` <span>${item.label}</span> `);
  }

  renderDataCell(value, columnConfig, rowIndex, name, errors) {
    const methodName = `__render${capitalize(columnConfig.key)}Cell`;

    return this[methodName](value, errors, name, rowIndex);
  }
}

window.customElements.define('neb-table-charges-edit', EditChargeTable);
