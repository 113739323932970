/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-ledger-charges';
import '../../../../packages/neb-lit-components/src/components/controls/neb-horizontal-scroll';
import '../../filters/neb-filters-eob-charges';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';

import { navigate } from '@neb/router';
import { html, css, LitElement, unsafeCSS } from 'lit';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import { getLineItems } from '../../../../packages/neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../packages/neb-api-client/src/patient-api-client';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-cases';
import { getPatientGuarantors } from '../../../../packages/neb-api-client/src/patient-guarantor-api-client';
import { getChartingPermissions } from '../../../../packages/neb-api-client/src/permissions-api-client';
import { openOverlayPatientInsuranceView } from '../../../../packages/neb-app-layout/neb-open-overlay';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import {
  formatResponsibility,
  formatCode,
  createProviderDict,
  getConfigs,
  getFormattedDate,
} from '../../../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import ExpandedTableService from '../../../../packages/neb-lit-components/src/services/expanded-table';
import SyncTableColumnsService from '../../../../packages/neb-lit-components/src/services/sync-table-columns';
import { openEncounterSummary } from '../../../../packages/neb-lit-components/src/utils/encounter-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyPopup } from '../../../../packages/neb-popup';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import { capitalize } from '../../../../packages/neb-utils/formatters';
import { getPatientDisplayName } from '../../../../packages/neb-utils/neb-charting-util';
import { URL_NO_ACCESS } from '../../../../packages/neb-utils/neb-request-security';
import { FetchService } from '../../../../packages/neb-utils/services/fetch';
import { formatBilled, formatHold } from '../../../formatters/line-items';
import { EDIT_MODE } from '../../../utils/neb-charges-util';
import sortRollups from '../../../utils/sort/rollups';
import { GETTING_CHARGES_FETCH_ERROR } from '../../../utils/user-message';

import {
  associateCharges,
  getFetchApi,
  isMatchEditMode,
  openNoChargesSelectedPopup,
} from './neb-era-eob-charges-controller/neb-era-eob-charges-controller-utils';

export const ELEMENTS = {
  filters: { id: 'filters' },
  content: { id: 'content' },
  table: { id: 'table' },
  footer: { id: 'footer' },
  footerCells: { selector: '.footer-cell' },
  description: { id: 'description' },
  scrollContainer: { id: 'scroll-container' },
  hoverHorizontalScrollBar: { id: 'hover-horizontal-scroll-bar' },
  nebTableLedgerChargeExpanded: {
    selector: 'neb-table-ledger-charge-expanded',
  },
  pagination: { id: 'pagination' },
  actionBar: { id: 'action-bar' },
};

const MENU_HEIGHT = 0;

const PAGE_SIZE = 100;

export const SELECT_CHARGES_TO_ASSOCIATE = {
  title: 'Associate charges',
  message: 'Select one or more charges to associate to the EOB.',
};

class NebEraEobChargesController extends LitElement {
  static get properties() {
    return {
      __tableState: Object,
      __firstScrollHandler: Boolean,
      __secondScrollHandler: Boolean,
      __locations: Array,
      __defaultLocationId: String,
      __patients: Array,

      __marginBottom: Number,
      __minWidth: Number,
      __totals: Object,
      __rollUps: Array,
      __expandedFlags: Array,
      __outerTableConfig: Array,
      __innerTableConfig: Array,
      __checkedLineItemIds: Array,
      __selectedCharges: Array,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
      chargesType: String,
      model: Object,
      mode: String,
      description: String,
      noChargesMessage: String,
      showResponsibilityBalance: Boolean,
      editMode: String,
      matchLineItemReportId: String,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          background-color: ${CSS_COLOR_WHITE};
          overflow: hidden;
          padding-bottom: 80px;
        }

        .description {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
          margin: 0;
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .scroll-horizontal {
          overflow-x: auto;
          scrollbar-width: none;

          /* Necessary styles for allowing popover to not intersect with top of div */
          padding-top: 182px;
          margin-top: -182px;
        }

        .scroll-horizontal::-webkit-scrollbar {
          width: 0;
          display: none;
        }

        .footer {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .pagination {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .footer-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .footer-cell:last-child {
          margin-right: 0;
        }

        .footer-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .footer-trailing-spacer {
          width: 12px;
        }

        .action-bar {
          position: relative;
          width: 100%;
          z-index: 3;
          position: fixed;
          bottom: 0%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__firstScrollHandler = false;
    this.__secondScrollHandler = false;
    this.__locations = [];
    this.__defaultLocationId = '';

    this.__minWidth = 1400;

    this.__totals = {
      invoiceNumber: '',
    };

    this.__tableState = {
      pageIndex: 0,
      pageCount: 1,
      pageSize: PAGE_SIZE,
      pageItems: [],
    };

    this.__rollUps = [];
    this.__expandedFlags = [];
    this.__checkedLineItemIds = [];
    this.__selectedCharges = [];
    this.__outerTableConfig = [];
    this.__innerTableConfig = [];
    this.__marginBottom = 0;
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.chargesType = '';
    this.showResponsibilityBalance = false;
    this.layout = '';
    this.mode = 'Open';
    this.description = 'Select charges to be added.';
    this.noChargesMessage = 'There are no charges.';
    this.model = {};
    this.editMode = EDIT_MODE.ASSOCIATE;
    this.matchLineItemReportId = '';

    this.onCancel = () => {};

    this.onMatchCharge = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectPage: pageIndex => this.__setTableState({ pageIndex }),

      changeChargeStatus: e => {
        this.mode = e.value;

        this.__buildFetchService();
      },
      filter: model => {
        const invoice = model.notInvoiced ? 'NOT_INVOICED' : model.invoice;
        const patientId = model.patientId || undefined;

        const payerIds = model.payerIds.length ? model.payerIds : undefined;

        const dosFrom = model.dateOfService.from
          ? model.dateOfService.from.format('YYYY-MM-DD')
          : undefined;

        const dosTo = model.dateOfService.to
          ? model.dateOfService.to.format('YYYY-MM-DD')
          : undefined;

        this.__fetchService.setQuery('invoice', invoice);
        this.__fetchService.setQuery('patientId', patientId);
        this.__fetchService.setQuery('payerIds', payerIds);
        this.__fetchService.setQuery('dosFrom', dosFrom);
        this.__fetchService.setQuery('dosTo', dosTo);
        this.__fetchService.setQuery('onlyInsuranceCharges', true);
      },

      toggleExpand: async (_name, item, _index, expand) => {
        if (expand && item.lineItems.length === 0) {
          const providerDict = createProviderDict();
          const lineItems = await getLineItems(item.lineItemIds);

          item.lineItems = lineItems.map(c => ({
            ...c,
            units: c.units || 1,
            billedAmount: c.billedAmount + c.taxAmount,
            patientResponsibility: formatResponsibility(
              c.patientPaid,
              c.patientOwed,
            ),
            primary: formatResponsibility(c.primaryPaid, c.primaryOwed),
            secondary: formatResponsibility(c.secondaryPaid, c.secondaryOwed),
            code: formatCode(c.code, c.modifiers),
            provider: providerDict[c.providerId] || '',
            encounter: c.encounterNumber || '',
            ...{ mrn: this.__getPatientInfo(c).mrn },
            billed: formatBilled(c),
            hold: formatHold(c),
          }));

          item.lineItems.forEach(lineItem => {
            lineItem.checked = item.checked;
          });
        }

        this.__expandedTableService.updateItem(item);

        if (expand) {
          this.__alignTableColumnWidths();
        }
      },

      viewCharges: async (_, lineItem) => {
        const { lineItemIds, patient } = this.__getOverlayLineItemIds(lineItem);

        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        this.fetch();
      },

      selectAll: () => {
        this.__tableState = this.__setChecked(true);
      },

      deselectAll: () => {
        this.__tableState = this.__setChecked(false);
      },

      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.__handlers.selectAll,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.__handlers.deselectAll,
        },
      ],

      changeTable: e => {
        const terms = e.name.split('.');
        const lastIndex = terms[terms.length - 1];

        switch (terms.length) {
          case 2:
            const rollup = this.__tableState.pageItems[lastIndex];

            rollup.checked = e.value;

            rollup.lineItems = rollup.lineItems.map(item => ({
              ...item,
              checked: e.value,
            }));

            this.__tableState = {
              ...this.__tableState,
              pageItems: [...this.__tableState.pageItems],
            };

            break;

          case 4:
            const rollupIndex = terms[1];

            const pageItems = isMatchEditMode(this.editMode)
              ? this.__tableState.pageItems.map(pageItem => ({
                  ...pageItem,
                  lineItems: pageItem.lineItems.map(li => ({
                    ...li,
                    checked: false,
                  })),
                }))
              : this.__tableState.pageItems;

            const lineItem = pageItems[rollupIndex].lineItems[lastIndex];

            lineItem.checked = isMatchEditMode(this.editMode) ? true : e.value;

            if (!isMatchEditMode(this.editMode)) {
              this.__tableState.pageItems[rollupIndex].checked =
                this.__tableState.pageItems[rollupIndex].lineItems.every(
                  item => item.checked === true,
                );
            }

            this.__tableState = {
              ...this.__tableState,
              pageItems: [...pageItems],
            };

            break;

          default:
            break;
        }

        this.__checkedLineItemIds = this.__getCheckedCharges();
      },

      resizeWindow: () => {
        this.__alignTableColumnWidths();
      },

      viewInvoice: async ({ item, patientId }) => {
        let patient = { id: patientId };

        if (this.__patients.length) {
          patient = {
            ...patient,
            ...this.__getPatientInfo(item),
          };
        }

        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds: item.lineItemIds,
          selectedIds: [],
        });

        this.fetch();
      },

      editCase: async ({ patientId, patientCaseId }) => {
        const [patientCase, guarantors] = await Promise.all([
          fetchOne(patientId, patientCaseId),
          getPatientGuarantors(patientId),
        ]);

        await openOverlay(OVERLAY_KEYS.CASE, {
          item: patientCase,
          context: {
            patientId,
            guarantors,
            onGuarantorChange: () => {},
            onAuthorizationChange: () => {},
          },
        });

        this.fetch();
      },

      editPayer: ({ payerId }) => this.__openPayerOverlay(payerId),

      editPackage: async ({ patientPackageId }) => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: patientPackageId,
            patientId: '',
          },
        });

        this.fetch();
      },

      editPlan: async ({ patientId, primaryInsuranceId }) => {
        await openOverlayPatientInsuranceView({
          patientId,
          patientInsurance: { id: primaryInsuranceId },
        });

        this.fetch();
      },

      viewEncounterSummary: async ({
        encounterId,
        appointmentTypeId,
        patientId,
      }) => {
        if (await getChartingPermissions()) {
          await openEncounterSummary({
            patient: { id: patientId },
            appointmentTypeId,
            encounterId,
          });

          this.fetch();
        } else {
          navigate(URL_NO_ACCESS);
        }
      },

      viewClaim: async ({ claimId }) => {
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, { claimId });
        this.fetch();
      },

      navigateToPatientLedger: ({ patientId }) => {
        navigate(`/patients/${patientId}/ledger/activity`);
      },

      horizontalScroll: e => {
        if (this.__firstScrollHandler) {
          this.__firstScrollHandler = false;
          return;
        }

        this.__secondScrollHandler = true;

        this.__elements.hoverHorizontalScrollBar.setScrollLeft(
          e.currentTarget.scrollLeft,
        );
      },

      hoverBarScroll: scrollLeft => {
        if (this.__secondScrollHandler) {
          this.__secondScrollHandler = false;
          return;
        }

        this.__firstScrollHandler = true;

        this.__elements.scrollContainer.scrollLeft = scrollLeft;
      },

      setYOffset: offset => this.setYOffset(offset),

      save: () => {
        if (
          !this.__checkedLineItemIds ||
          this.__checkedLineItemIds.length <= 0
        ) {
          return openNoChargesSelectedPopup(this.editMode);
        }

        return this.__save();
      },
      cancel: async () => {
        if (this.__checkedLineItemIds && this.__checkedLineItemIds.length > 0) {
          const confirmed = await openDirtyPopup();

          if (!confirmed) return;
        }

        this.__setChecked(false);
        this.onCancel();
      },
    };
  }

  __initServices() {
    this.__buildFetchService();

    this.__expandedTableService = new ExpandedTableService(flags => {
      this.__expandedFlags = flags;
    });

    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;
        this.__defaultLocationId = defaultLocationId;
      },
    );

    this.__syncInterTableColumnsService = new SyncTableColumnsService(
      getConfigs(),
    );
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__syncInterTableColumnsService.connect();

    window.addEventListener('resize', this.__handlers.resizeWindow);

    this.__syncInterTableColumnsService.setConfigs(getConfigs());

    this.__alignTableColumnWidths();

    this.__locationsService.connect();

    const features = await getFeatures();
    this.__hasFitInvoiceOverlayPerformanceFF = features.includes(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__syncInterTableColumnsService.disconnect();
    window.removeEventListener('resize', this.__handlers.resizeWindow);
    this.__locationsService.disconnect();
  }

  __save() {
    return isMatchEditMode(this.editMode)
      ? this.onMatchCharge(this.__checkedLineItemIds[0])
      : associateCharges({
          fetch: () => this.fetch(),
          chargesType: this.chargesType,
          checkedLineItemIds: this.__checkedLineItemIds,
          model: this.model,
        });
  }

  __setChecked(checked) {
    this.__tableState.pageItems = this.__tableState.pageItems.map(item => ({
      ...item,
      checked,
    }));

    this.__checkedLineItemIds = this.__getCheckedCharges();

    return { ...this.__tableState };
  }

  __getCheckedCharges() {
    return this.__tableState.pageItems.reduce((acc, curr, _i) => {
      const lineItemIds = curr.checked
        ? curr.lineItemIds
        : curr.lineItems.filter(li => li.checked).map(li => li.id);
      return [...acc, ...lineItemIds];
    }, []);
  }

  __getOverlayLineItemIds(lineItem) {
    const rollUp = this.__tableState.pageItems.find(r =>
      r.lineItemIds.includes(
        lineItem ? lineItem.id : this.__checkedLineItemIds[0],
      ),
    );

    const selectedLineItemIds = lineItem
      ? [lineItem.id]
      : this.__checkedLineItemIds;

    let patient = { id: rollUp.patientId };

    if (this.__patients.length) {
      patient = { ...patient, ...this.__getPatientInfo(rollUp) };
    }

    return {
      lineItemIds: rollUp.invoiceId ? rollUp.lineItemIds : selectedLineItemIds,
      patient,
    };
  }

  async __openPayerOverlay(payerId) {
    await openOverlay(OVERLAY_KEYS.PAYER_PLAN, { id: payerId });

    this.fetch();
  }

  __setPlanValue(rollUp) {
    let plan;

    if (rollUp.primaryInsuranceId) {
      plan = rollUp.primaryPlanName;
    }

    if (rollUp.patientPackageId) {
      plan = rollUp.patientPackageName;
    }

    return plan || '';
  }

  __getPatientInfo(item) {
    const patient = this.__patients.find(p => p.id === item.patientId);

    return {
      name: getPatientDisplayName(patient, {
        preferred: false,
        reverse: true,
        suffix: true,
        middleInitial: true,
      }),
      mrn: patient.medicalRecordNumber,
    };
  }

  __formatRollUps(rollUp) {
    const patient = this.__getPatientInfo(rollUp).name;

    return {
      ...rollUp,
      id: uuid(),
      checked: false,
      lineItems: [],
      billedAmountTotal: rollUp.billedAmountTotal + rollUp.taxAmountTotal,
      patientResponsibility: formatResponsibility(
        rollUp.patientPaidTotal,
        rollUp.patientOwedTotal,
        this.showResponsibilityBalance,
      ),
      dateOfAllServices: getFormattedDate(rollUp),
      payerResponsibility: formatResponsibility(
        rollUp.payerPaidTotal,
        rollUp.payerOwedTotal,
        this.showResponsibilityBalance,
      ),
      primaryPayer: rollUp.primaryPayerId
        ? `(${rollUp.primaryPayerAlias}) ${rollUp.primaryPayerName}`
        : patient,
      secondaryPayer: rollUp.secondaryPayerId
        ? `(${rollUp.secondaryPayerAlias}) ${rollUp.secondaryPayerName}`
        : '',
      case: rollUp.patientCaseId
        ? `${rollUp.caseName} - ${
            rollUp.caseOnsetSymptomsDate
              ? moment(rollUp.caseOnsetSymptomsDate).format('MM/DD/YYYY')
              : 'Gradual'
          }`
        : '',
      guarantor: rollUp.guarantorPerson
        ? `${getPatientDisplayName(rollUp.guarantorPerson)}`
        : rollUp.guarantorOrganization || '',
      plan: this.__setPlanValue(rollUp),
      patient,
    };
  }

  __calculateMarginBottom() {
    const { length } = this.__fetchService.__items;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    } else if (length > 2) {
      this.__marginBottom = 0;
    }
  }

  __getExpandedLineItems() {
    return this.__tableState.pageItems.reduce(
      (accumulator, item, index) =>
        this.__expandedFlags[index]
          ? [...accumulator, ...item.lineItems]
          : accumulator,
      [],
    );
  }

  __alignTableColumnWidths() {
    const lineItems = this.__getExpandedLineItems();

    const { minWidth, innerConfig, outerConfig } =
      this.__syncInterTableColumnsService.alignInnerOuterColumnWidths(
        lineItems,
        [...this.__tableState.pageItems, this.__totals],
      );

    this.__minWidth = minWidth;
    this.__innerTableConfig = innerConfig;
    this.__outerTableConfig = outerConfig;
  }

  __buildTotals() {
    const totals = this.__rollUps.reduce(
      (t, currentValue) => {
        t.billedAmountTotal += currentValue.billedAmountTotal;
        t.allowedAmountTotal =
          currentValue.allowedAmountTotal !== null
            ? t.allowedAmountTotal + currentValue.allowedAmountTotal
            : null;

        t.patientPaidTotal += currentValue.patientPaidTotal;
        t.patientOwedTotal += currentValue.patientOwedTotal;
        t.payerPaidTotal += currentValue.payerPaidTotal;
        t.payerOwedTotal += currentValue.payerOwedTotal;
        t.adjustmentTotal += currentValue.adjustmentTotal;
        t.balanceTotal += currentValue.balanceTotal;
        return t;
      },
      {
        billedAmountTotal: 0,
        allowedAmountTotal: 0,
        patientPaidTotal: 0,
        patientOwedTotal: 0,
        payerPaidTotal: 0,
        payerOwedTotal: 0,
        adjustmentTotal: 0,
        balanceTotal: 0,
      },
    );

    this.__totals = {
      ...totals,
      invoiceNumber: '',
      patientResponsibility: formatResponsibility(
        totals.patientPaidTotal,
        totals.patientOwedTotal,
        this.showResponsibilityBalance,
      ),
      payerResponsibility: formatResponsibility(
        totals.payerPaidTotal,
        totals.payerOwedTotal,
        this.showResponsibilityBalance,
      ),
    };
  }

  __setTableState({ pageIndex }) {
    const totalCount = this.__rollUps.length;
    const pageCount = Math.ceil(totalCount / PAGE_SIZE);

    const start = pageIndex * PAGE_SIZE;
    const end = start + Math.min(totalCount - start, PAGE_SIZE);

    const items = this.__rollUps.slice(start, end);

    this.__tableState = {
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      pageItems: items.map(item => ({
        ...item,
        checked: false,
        lineItems: item.lineItems.map(li => ({ ...li, checked: false })),
      })),
    };

    this.__checkedLineItemIds = [];
    this.__expandedTableService.setItems(this.__tableState.pageItems);
  }

  __buildFetchService() {
    const client = async query => {
      const rollups = await getFetchApi({
        query,
        editMode: this.editMode,
        mode: this.mode,
        model: this.model,
        chargesType: this.chargesType,
      });
      this.__patients = await patientApiClient.fetchSome(
        rollups.data.map(({ patientId }) => patientId),
        undefined,
        true,
        true,
        true,
      );

      return rollups;
    };

    if (this.__fetchService) {
      this.__fetchService.cancel();
    }

    this.__fetchService = new FetchService(
      {
        onChange: state => {
          const { pageItems } = state;

          const formatted = pageItems.map(ru => this.__formatRollUps(ru));
          this.__rollUps = sortRollups(formatted);

          this.__setTableState({ pageIndex: 0 });

          if (!isMatchEditMode(this.editMode)) return;

          this.__tableState.pageItems.map(item =>
            this.__handlers.toggleExpand('', item, '', true),
          );
        },
        onError: e => {
          console.error(e);
          store.dispatch(openError(GETTING_CHARGES_FETCH_ERROR));
        },
      },
      client,
    );
  }

  fetch() {
    this.__fetchService.fetch();
  }

  setYOffset(offset = 0) {
    if (this.__elements && this.__elements.hoverHorizontalScrollBar) {
      this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(offset);
    }
  }

  updated(changedProps) {
    if (changedProps.has('__tableState')) {
      this.__calculateMarginBottom();
    }

    super.updated(changedProps);
  }

  firstUpdated() {
    this.__elements = {
      hoverHorizontalScrollBar: this.shadowRoot.getElementById(
        ELEMENTS.hoverHorizontalScrollBar.id,
      ),
      scrollContainer: this.shadowRoot.getElementById(
        ELEMENTS.scrollContainer.id,
      ),
      content: this.shadowRoot.getElementById(ELEMENTS.content.id),
    };
  }

  __getMatchCharges() {
    if (!isMatchEditMode(this.editMode) || !this.matchLineItemReportId) {
      return;
    }

    this.__buildFetchService();

    this.__fetchService.setQuery('eraId', this.model.reportId);
    this.__fetchService.setQuery(
      'lineItemReportId',
      this.matchLineItemReportId,
    );
  }

  update(changed) {
    if (changed.has('__tableState') && this.__tableState.pageItems.length) {
      this.__selectedCharges = [];
      this.__buildTotals();
      this.__alignTableColumnWidths();
    }

    if (changed.has('editMode')) {
      this.__syncInterTableColumnsService.setConfigs(
        getConfigs({ showCheck: !isMatchEditMode(this.editMode) }),
      );

      this.__alignTableColumnWidths();

      this.__getMatchCharges();
    }

    if (changed.has('matchLineItemReportId') && this.matchLineItemReportId) {
      this.__getMatchCharges();
    }

    super.update(changed);
  }

  __renderFilters() {
    return !isMatchEditMode(this.editMode)
      ? html`
          <neb-filters-eob-charges
            id="${ELEMENTS.filters.id}"
            class="filters"
            .onApply="${this.__handlers.filter}"
            .onChangeChargeStatus="${this.__handlers.changeChargeStatus}"
            .defaultPayer="${this.model.payer}"
          ></neb-filters-eob-charges>
        `
      : html``;
  }

  renderHeader() {
    return html`
      <p class="description" id="${ELEMENTS.description.id}">
        ${this.description}
      </p>
      ${this.__renderFilters()}
    `;
  }

  renderFooter() {
    if (
      !this.__tableState.pageItems ||
      this.__tableState.pageItems.length <= 0
    ) {
      return '';
    }

    const cells = this.__outerTableConfig.slice(1).map(cell => {
      const value = cell.formatter
        ? cell.formatter(this.__totals[cell.key])
        : this.__totals[cell.key] || '';
      return html`
        <span
          class="footer-cell"
          style="flex: ${cell.flex}"
          ?truncate="${cell.truncate}"
          >${value}</span
        >
      `;
    });

    return html`
      <div
        id="${ELEMENTS.footer.id}"
        class="footer"
        style="min-width: ${unsafeCSS(this.__minWidth)}px; width: 100%"
      >
        <div class="footer-cell-spacer text-bold">Totals</div>
        ${cells}
        <div class="footer-trailing-spacer"></div>
      </div>
    `;
  }

  renderPagination() {
    return this.__tableState.pageCount > 1
      ? html`
          <div class="pagination" id="${ELEMENTS.pagination.id}">
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              .pageCount="${this.__tableState.pageCount}"
              .currentPage="${this.__tableState.pageIndex}"
              .onPageChanged="${this.__handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderBottomActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="${capitalize(this.editMode)}"
        cancelLabel="Close"
        .onConfirm="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
      ></neb-action-bar>
    `;
  }

  renderTable() {
    return html`
      <div
        id="${ELEMENTS.scrollContainer.id}"
        class="scroll-horizontal"
        @scroll="${this.__handlers.horizontalScroll}"
      >
        <neb-table-ledger-charges
          id="${ELEMENTS.table.id}"
          class="table"
          style="min-width: ${unsafeCSS(this.__minWidth)}px; width: 100%"
          name="charges"
          .layout="${this.layout}"
          .config="${this.__outerTableConfig}"
          .model="${this.__tableState.pageItems}"
          .expandFlags="${this.__expandedFlags}"
          .onChange="${this.__handlers.changeTable}"
          .onToggleExpand="${this.__handlers.toggleExpand}"
          .innerTableConfig="${this.__innerTableConfig}"
          .onSelectExpandedRow="${this.__handlers.viewCharges}"
          .onBulkActionClick="${this.__handlers.getBulkActions}"
          .onSelectCase="${this.__handlers.editCase}"
          .onSelectPatient="${this.__handlers.navigateToPatientLedger}"
          .onSelectPayer="${this.__handlers.editPayer}"
          .onSelectInvoice="${this.__handlers.viewInvoice}"
          .onSelectPlan="${this.__handlers.editPlan}"
          .onSelectPackage="${this.__handlers.editPackage}"
          .onSelectEncounter="${this.__handlers.viewEncounterSummary}"
          .onSelectClaim="${this.__handlers.viewClaim}"
          .emptyMessage="${this.noChargesMessage}"
          .matchCharge="${isMatchEditMode(this.editMode)}"
        ></neb-table-ledger-charges>
        ${this.renderFooter()} ${this.renderPagination()}
      </div>
      <neb-horizontal-scroll
        id="${ELEMENTS.hoverHorizontalScrollBar.id}"
        .onChange="${this.__handlers.hoverBarScroll}"
        .minWidth="${this.__minWidth}"
        .yOffset="${80}"
        .fixAtBottom="${true}"
      ></neb-horizontal-scroll>
    `;
  }

  render() {
    return html`
      ${this.renderHeader()} ${this.renderTable()}
      ${this.renderBottomActionBar()}
    `;
  }
}

customElements.define(
  'neb-era-eob-charges-controller',
  NebEraEobChargesController,
);
