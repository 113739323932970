import { LitElement, html, css } from 'lit';

import { arrayToSentence } from '../../../packages/neb-utils/array-to-sentence';
import { deepCopy } from '../../../packages/neb-utils/utils';
import {
  baseStyles,
  CSS_COLOR_ERROR,
  CSS_FONT_SIZE_CAPTION,
} from '../../styles';

import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/neb-toggle-button';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

export const ELEMENTS = {
  displayText: {
    id: 'displayText',
  },
  questionOptions: {
    selector: '.question-options',
    tag: 'neb-textarea',
  },
  descriptionText: { id: 'descriptionText' },
  additionalAnswer: { id: 'additionalTextboxEntry' },
};

class NebQuestion extends LitElement {
  static get properties() {
    return {
      error: Boolean,
      __state: Object,
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .question-titles {
          display: block;
        }

        .additional-textbox-entry {
          display: block;
          width: 100%;
        }

        .question-options-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 10px;
        }

        neb-toggle-button {
          width: unset;
        }

        :host(.incomplete-questionnaire) .unanswered {
          color: ${CSS_COLOR_ERROR};
        }

        .label {
          padding-top: 5px;
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.displayText = '';
    this.model = { displayText: '', type: '', narrativeText: null };
    this.__additionalAnswer = '';
    this.__state = {};

    this.onAnswerChanged = () => {};
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      change: ({ value }) => {
        this.__state.answer = value;

        this.__onAnswerChanged();
      },
      clickMultiSelect: (selected, index) => {
        this.__state.options[index].selected = selected;

        if (this.__state.options[index].hasAdditionalTextBoxEntry) {
          this.__state.additionalTextBoxEntryValue = '';
        }

        this.__state.answer = arrayToSentence(
          this.__calculateOptionNarrativeText(),
        );

        this.__onAnswerChanged();
      },
      clickSingleSelect: (selected, index) => {
        if (selected) {
          this.__state.options.forEach(option => {
            option.selected = false;
          });

          this.__state.options[index].selected = true;

          if (this.__state.options[index].hasAdditionalTextBoxEntry) {
            this.__state.additionalTextBoxEntryValue = '';
          }

          this.__state.answer = arrayToSentence(
            this.__calculateOptionNarrativeText(),
          );

          this.__onAnswerChanged();
        }
      },
      changeAdditionalTextBoxEntry: ({ value }) => {
        this.__state.additionalTextBoxEntryValue = value;

        this.__state.answer = arrayToSentence(
          this.__calculateOptionNarrativeText(),
        );

        this.__onAnswerChanged();
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = deepCopy(this.model);
    }
  }

  get narrative() {
    if (this.__state.narrativeText) {
      return this.__state.narrativeText.replace(
        '[patient answer]',
        this.__state.answer,
      );
    }

    return this.__state.answer || '';
  }

  get isAnswered() {
    switch (this.__state.type) {
      case 'multi-line-text-box-entry':
        return Boolean(this.__state.answer);

      case 'multi-select':
        return this.__isQuestionOptionsAnswered();

      case 'single-select':
        return this.__isQuestionOptionsAnswered();

      default:
        return true;
    }
  }

  __onAnswerChanged() {
    this.onAnswerChanged({
      ...this.__state,
      narrativeValue: this.narrative,
      isAnswered: this.isAnswered,
    });

    this.requestUpdate();
  }

  __isQuestionOptionsAnswered() {
    const questionOption =
      this.__getSelectedQuestionOptionWithAdditionalTextBoxEntry();

    return questionOption
      ? this.__state.options.some(
          option =>
            option.selected &&
            Boolean(this.__state.additionalTextBoxEntryValue),
        )
      : this.__state.options.some(option => option.selected);
  }

  __calculateOptionNarrativeText() {
    return this.__state.options.flatMap(option => {
      let narrativeValue;

      if (option.selected) {
        if (option.narrativeText) {
          narrativeValue = option.narrativeText.replace(
            '[patient answer]',
            option.hasAdditionalTextBoxEntry
              ? this.__state.additionalTextBoxEntryValue
              : option.displayText,
          );
        } else {
          narrativeValue = option.narrativeText
            ? option.narrativeText.replace(
                '[patient answer]',
                option.displayText,
              )
            : '';
        }
      }

      return narrativeValue || [];
    });
  }

  __getSelectedQuestionOptionWithAdditionalTextBoxEntry() {
    return (
      typeof this.__state.options !== 'undefined' &&
      this.__state.options.find(
        option => option.selected && option.hasAdditionalTextBoxEntry,
      )
    );
  }

  __renderDescriptionText() {
    if (this.__state.type === 'multi-select') {
      return html`
        <p id="${ELEMENTS.descriptionText.id}" class="label">
          Select all that apply.
        </p>
      `;
    }

    return html``;
  }

  __renderDisplayText() {
    return html`
      <neb-text
        bold
        class="question-titles ${this.isAnswered ? '' : 'unanswered'}"
        id="${ELEMENTS.displayText.id}"
        >${this.__state.displayText}</neb-text
      >
      ${this.__renderDescriptionText()}
    `;
  }

  __renderQuestionOptions() {
    switch (this.__state.type) {
      case 'multi-line-text-box-entry':
        return html`
          <neb-textarea
            class="question-options"
            maxLength="255"
            .onChange="${this.handlers.change}"
            .value="${this.__state.answer ? this.__state.answer : ''}"
            showCount
          ></neb-textarea>
        `;

      case 'multi-select':
        return this.__state.options.map(
          (questionOption, index) => html`
            <neb-toggle-button
              class="question-options"
              .buttonText="${questionOption.displayText}"
              .index="${index}"
              .hideIcon="${true}"
              .isToggled="${Boolean(questionOption.selected)}"
              .canToggle="${true}"
              .onClick="${this.handlers.clickMultiSelect}"
            ></neb-toggle-button>
          `,
        );

      case 'single-select':
        return this.__state.options.map(
          (questionOption, index) => html`
            <neb-toggle-button
              class="question-options"
              .buttonText="${questionOption.displayText}"
              .index="${index}"
              .hideIcon="${true}"
              .isToggled="${Boolean(questionOption.selected)}"
              .canToggle="${false}"
              .onClick="${this.handlers.clickSingleSelect}"
            ></neb-toggle-button>
          `,
        );

      default:
        return html``;
    }
  }

  __renderAdditionalTextBoxEntry() {
    const questionOption =
      this.__getSelectedQuestionOptionWithAdditionalTextBoxEntry();

    if (questionOption) {
      return html`
        <neb-textfield
          id="${ELEMENTS.additionalAnswer.id}"
          class="additional-textbox-entry"
          label="${questionOption.additionalTextBoxEntryLabel
            ? questionOption.additionalTextBoxEntryLabel
            : 'Please describe.'}"
          maxLength="255"
          value="${this.__state.additionalTextBoxEntryValue
            ? this.__state.additionalTextBoxEntryValue
            : ''}"
          .onChange="${this.handlers.changeAdditionalTextBoxEntry}"
        ></neb-textfield>
      `;
    }

    return '';
  }

  render() {
    return html`
      ${this.__renderDisplayText()}
      <div class="question-options-container">
        ${this.__renderQuestionOptions()}
        ${this.__renderAdditionalTextBoxEntry()}
      </div>
    `;
  }
}

customElements.define('neb-question', NebQuestion);
