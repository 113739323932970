import { RESPONSE_TYPE } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const apiClient = new ApiClientV2({
  microservice: 'billing',
});

const formatEmergencyContact = ({ name, ...rest }) => ({
  firstName: name.first,
  lastName: name.last,
  middleName: name.middle,
  suffix: name.suffix,
  ...rest,
});

export const createEmergencyContact = ({ patientId, ...emergencyContact }) =>
  apiClient.makeRequest({
    method: 'POST',
    path: '/api/v1/tenants/:tenantId/patients/:patientId/emergency-contacts',
    replacements: { patientId },
    body: formatEmergencyContact(emergencyContact),
    responseType: RESPONSE_TYPE.RAW,
  });

export const getEmergencyContacts = async patientId => {
  const { data } = await apiClient.makeRequest({
    method: 'GET',
    path: '/api/v1/tenants/:tenantId/patients/:patientId/emergency-contacts',
    replacements: { patientId },
  });

  return data;
};

export const updateEmergencyContact = ({
  id,
  patientId,
  ...emergencyContact
}) =>
  apiClient.makeRequest({
    method: 'PUT',
    path: '/api/v1/tenants/:tenantId/patients/:patientId/emergency-contacts/:id',
    replacements: { id, patientId },
    body: formatEmergencyContact(emergencyContact),
    responseType: RESPONSE_TYPE.RAW,
  });

export const deleteEmergencyContact = ({ id, patientId }) =>
  apiClient.makeRequest({
    method: 'DELETE',
    path: '/api/v1/tenants/:tenantId/patients/:patientId/emergency-contacts/:id',
    replacements: { id, patientId },
    responseType: RESPONSE_TYPE.RAW,
  });
