import { getAllEncountersDetails } from '../../../../packages/neb-api-client/src/encounters-api-client';
import { formatDateTime } from '../../../utils/conversions/date-format';

const formatEncounterDetails = encountersDetails =>
  encountersDetails.map(({ encounter }) => {
    const label = `${formatDateTime(encounter.serviceDate)} - ${
      encounter.encounterNumber
    }`;
    const caseName = encounter.case?.name;

    return {
      ...encounter,
      label: caseName ? `${label} - ${caseName}` : label,
    };
  });

export const buildEncounterDetails = async ({ patientId, invoiceId }) => {
  const { data: encountersDetails } = await getAllEncountersDetails(patientId);

  const encounters = formatEncounterDetails(encountersDetails);

  let encounterIndex = encountersDetails.findIndex(({ invoices }) =>
    invoices.includes(invoiceId),
  );

  if (encounterIndex === -1) {
    encounterIndex = 0;
  }

  const selectedEncounter = encounters[encounterIndex];
  const selectedEncounterDetails = encountersDetails[encounterIndex];

  return {
    encounters,
    selectedEncounter,
    encountersDetails,
    selectedEncounterDetails,
  };
};
