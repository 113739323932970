import { css, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_CAPTION,
  CSS_COLOR_GREY_4,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import {
  centsToCurrency,
  objToName,
  DEFAULT_NAME_OPTS,
  centsToCurrencyWithNegative,
} from '../../../../neb-utils/formatters';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  encounterNumberLink: { id: 'encounter-number-link' },
  encounterLinks: { selector: '[id^=encounter-number-link-]' },
  patientLink: { id: 'patient-link' },
  patientLinks: { selector: '[id^=patient-link-]' },
};

class NebTableEncounterChargesDetails extends NebTable {
  static get properties() {
    return {
      providers: Array,
    };
  }

  initState() {
    super.initState();

    this.onLinkClick = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      linkClick: (rowIndex, column) => this.onLinkClick(rowIndex, column),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .link {
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }

        .bold {
          font-weight: bold;
        }

        .cell-transaction-detail {
          flex-direction: column;
          padding-bottom: 18px;
        }

        .caption-text {
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .row-data[expanded] {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .patient-link {
          display: flex;
          align-items: center;
          cursor: pointer;
          inline-size: min-content;
          overflow: hidden;
          max-width: calc(100% - ${CSS_SPACING});
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  __getProviderName(providerId) {
    const provider = this.providers.find(({ id }) => id === providerId);

    return objToName(provider.name, DEFAULT_NAME_OPTS);
  }

  __renderProviderCell(index, row) {
    return this.config[0].key === 'patientName'
      ? html`
          <div class="cell cell-transaction-detail" style="flex: 4 0 0">
            <div class="bold caption-text">Provider</div>
            <div id="detail-provider-${index}" class="caption-text">
              ${this.__getProviderName(row.providerId)}
            </div>
          </div>
        `
      : html`
          <div class="cell cell-transaction-detail" style="flex: 2 0 0"></div>
        `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'encounterNumber':
        if (!value) return '-';

        return html`
          <div
            id="${ELEMENTS.encounterNumberLink.id}-${rowIndex}"
            class="link"
            @click="${() => this.handlers.linkClick(rowIndex, 'encounter')}"
          >
            ${value}
          </div>
        `;
      case 'providerId':
        return this.__getProviderName(value);
      case 'patientName':
        if (this.model[rowIndex].patientInContext) {
          return value;
        }

        return html`
          <div
            id="${ELEMENTS.patientLink.id}-${rowIndex}"
            @click="${() => this.handlers.linkClick(rowIndex, 'patient')}"
            class="patient-link"
          >
            <span class="patient-text">${value}</span>
            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </div>
        `;

      default:
        return value;
    }
  }

  renderExpandedRow(index, row) {
    return html`
      <div class="content">
        ${this.__renderProviderCell(index, row)}

        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[0].key === 'patientName'
            ? '2 0 0'
            : '4 0 0'}"
        >
          <div class="bold caption-text">Billed</div>
          <div id="detail-billed-${index}" class="caption-text">
            ${centsToCurrency(row.subtotal)}
          </div>
        </div>
        <div class="cell cell-transaction-detail" style="flex: 2 0 0"></div>
        <div class="cell cell-transaction-detail" style="flex: 3 0 0">
          <div class="bold caption-text">Patient Resp</div>
          <div id="detail-patResponsible-${index}" class="caption-text">
            ${centsToCurrency(row.owed)}
          </div>
        </div>
        <div class="cell cell-transaction-detail" style="flex: 1 0 0"></div>
        <div class="cell cell-transaction-detail" style="flex: 3 0 0">
          <div class="bold caption-text">Adjustment</div>
          <div id="detail-adjustment-${index}" class="caption-text">
            ${centsToCurrencyWithNegative(row.adjustment)}
          </div>
        </div>
        <div class="cell cell-transaction-detail" style="flex: 1 0 0"></div>
        <div class="cell cell-transaction-detail" style="flex: 3 0 0">
          <div class="bold caption-text">Balance</div>
          <div id="detail-balance-${index}" class="caption-text">
            ${centsToCurrency(row.balance)}
          </div>
        </div>
        <div class="cell cell-expand"></div>
      </div>
    `;
  }
}

customElements.define(
  'neb-table-encounter-charges-details',
  NebTableEncounterChargesDetails,
);
