import '../controls/neb-tab-group';

import { css, html } from 'lit';

import {
  getTabWithError,
  map,
  arrayToObject,
} from '../../../../neb-utils/utils';
import NebIdentifier from '../field-groups/neb-identifier';
import NebProfile from '../field-groups/neb-profile';
import NebPermissions from '../permissions/neb-permissions';

import Form, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const SPECIALIST_INFO = 'Specialist Professional Information';
export const ELEMENTS = {
  ...BASE_ELEMENTS,
  tabGroup: {
    id: 'tab-group',
  },
  tabItems: {
    selector: 'neb-tab-old',
  },
  permissions: {
    id: 'permissions',
  },
  profile: {
    id: 'profile',
  },
  identifiers: {
    id: 'identifiers',
  },
};
export default class NebUserSpecialistForm extends Form {
  static get properties() {
    return {
      __selectedTab: Boolean,
      payerItems: Array,
      providerItems: Array,
      activeLocations: Array,
      locations: Array,
    };
  }

  static createModel() {
    return {
      ...NebProfile.createModel(),
      ...NebIdentifier.createModel(),
      ...NebPermissions.createModel(),
    };
  }

  createSelectors() {
    return {
      children: {
        ...NebProfile.createSelectors({ locations: this.activeLocations }),
        ...NebIdentifier.createSelectors({
          providers: this.providerItems,
          payers: this.payerItems,
        }),
        ...NebPermissions.createSelectors(this.locations),
      },
    };
  }

  initState() {
    super.initState();
    this.__navItems = [
      {
        id: 'profile',
        label: 'Profile',
        renderer: () => this.__renderProfile(),
        fields: ['name', 'phones', 'email', 'ddRetention'],
      },
      {
        id: 'identifiers',
        label: 'Specialist Identifiers',
        renderer: () => this.__renderIdentifier(),
        fields: [
          'taxId',
          'licenses',
          'identifiers',
          'taxonomy',
          'substituteProviders',
        ],
      },
      {
        id: 'permissions',
        label: 'Permissions & Rights',
        renderer: () => this.__renderPermissionsAndRights(),
        fields: ['superUser', 'permissions', 'locations'],
      },
    ];

    this.__selectedTab = 'profile';
    this.confirmLabel = 'Save Specialist';
    this.locations = [];
    this.activeLocations = [];
    this.payerItems = [];
    this.providerItems = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      save: () => {
        if (this.formService.validate()) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model);
          this.__saving = false;
        } else {
          this.__selectedTab = getTabWithError(
            arrayToObject(this.__navItems),
            this.errors,
            this.__selectedTab,
          );
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('payerItems') || changedProps.has('providerItems')) {
      this.build();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          gap: 0;
          overflow-y: hidden;
          grid-template-rows: 45px auto;
        }

        .form {
          padding: 0;
        }

        .tabs {
          flex: 0 0 auto;
          padding-top: 12px;
        }

        .tab-content {
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  __renderProfile() {
    return html`
      <neb-profile
        id="${ELEMENTS.profile.id}"
        class="tab-content"
        .model="${this.state}"
        .locations="${this.activeLocations}"
        .errors="${this.errors}"
        .layout="${this.layout}"
        .disableEmail="${this.model.email}"
        .onSave="${this.handlers.save}"
        .onChange="${this.handlers.change}"
        .onAdd="${this.handlers.addItem}"
        .onRemove="${this.handlers.removeItem}"
        .onCancel="${this.handlers.cancel}"
        show-biography
      ></neb-profile>
    `;
  }

  __renderIdentifier() {
    return html`
      <neb-identifier
        id="${ELEMENTS.identifiers.id}"
        class="tab-content"
        .model="${this.state}"
        .header="${SPECIALIST_INFO}"
        .errors="${this.errors}"
        .onAdd="${this.handlers.addItem}"
        .onRemove="${this.handlers.removeItem}"
        .onChange="${this.handlers.change}"
        .providerItems="${this.providerItems}"
        .payerItems="${this.payerItems}"
      ></neb-identifier>
    `;
  }

  __renderPermissionsAndRights() {
    return html`
      <neb-permissions
        id="${ELEMENTS.permissions.id}"
        class="tab-content"
        .layout="${this.layout}"
        .model="${this.state}"
        .errors="${this.errors}"
        .locations="${this.locations}"
        .onChange="${this.handlers.change}"
      ></neb-permissions>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(i => i.id === this.__selectedTab);
    return item ? item.renderer() : '';
  }

  renderContent() {
    return html`
      <neb-tab-group
        class="tabs"
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
      ${this.__renderTabContent()}
    `;
  }
}
customElements.define('neb-form-user-specialist', NebUserSpecialistForm);
