import { html, css } from 'lit';

import {
  ELEMENTS as BASE_ELEMENTS,
  List,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-list';
import { padArray } from '../../../../packages/neb-utils/utils';

import NebFeeScheduleProviderItem from './neb-fee-schedule-provider-item';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  items: {
    selector: 'neb-fee-schedule-provider-item',
  },
};

export const formatFeeSchedulePayerPlans = (
  feeSchedulePayerPlans,
  providerItems,
) =>
  Object.values(
    feeSchedulePayerPlans.reduce((acc, item) => {
      if (!acc[item.feeScheduleId]) {
        acc[item.feeScheduleId] = {
          feeScheduleId: item.feeScheduleId,
          providerIds: item.providerId
            ? [item.providerId]
            : providerItems.map(provider => provider.data.id),
          sortIndex: item.sortIndex,
        };
      } else {
        acc[item.feeScheduleId].providerIds.push(item.providerId);
      }

      return acc;
    }, {}),
  ).sort((a, b) => a.sortIndex - b.sortIndex);

export default class NebFeeScheduleProviderList extends List {
  static get properties() {
    return {
      __availableProvidersList: Array,
      __availableFeeScheduleList: Array,
      helpers: Array,
      providerItems: Array,
      feeScheduleItems: Array,
      providerLabel: String,
      addLabel: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: grid;
        }
      `,
    ];
  }

  static createModel() {
    return [NebFeeScheduleProviderItem.createModel()];
  }

  static createSelectors(feeScheduleItems, providerItems) {
    return {
      format: feeSchedulePayerPlans =>
        padArray(
          formatFeeSchedulePayerPlans(feeSchedulePayerPlans, providerItems),
          NebFeeScheduleProviderItem.createModel(),
        ),
      unformat: feeSchedulePayerList =>
        feeSchedulePayerList.reduce((acc, item, index) => {
          if (!item.feeScheduleId.data.id) return acc;

          if (item.providerIds.length === providerItems.length) {
            acc.push({
              feeScheduleId: item.feeScheduleId,
              allProviders: true,
              providerId: null,
              sortIndex: index,
            });
          } else {
            acc.push(
              ...item.providerIds.map(providerId => ({
                feeScheduleId: item.feeScheduleId,
                providerId: providerId.data.id,
                allProviders: false,
                sortIndex: index,
              })),
            );
          }

          return acc;
        }, []),
      createItem: () => NebFeeScheduleProviderItem.createModel(),
      children: {
        $: NebFeeScheduleProviderItem.createSelectors(
          feeScheduleItems,
          providerItems,
        ),
      },
    };
  }

  initState() {
    super.initState();

    this.__availableFeeScheduleList = [];
    this.__availableProvidersList = [];

    this.addLabel = 'Add Fee Schedule';

    this.helpers = {
      feeScheduleId: '',
      providerIds: '',
    };

    this.maxItems = 1;
    this.providerItems = [];
    this.feeScheduleItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  __calculateMaxItems() {
    const totalProvidersUsed = this.model.reduce((accum, item) => {
      accum += (item && item.providerIds && item.providerIds.length) || 0;
      return accum;
    }, 0);

    if (totalProvidersUsed >= this.providerItems.length) {
      return this.model.length;
    }
    return this.feeScheduleItems.length - 1 || 1;
  }

  update(changedProps) {
    if (changedProps.has('model') || changedProps.has('feeScheduleItems')) {
      this.maxItems = this.__calculateMaxItems();

      this.__availableProvidersList = this.model.map(item =>
        this.__getAvailableProviderItems(item),
      );

      this.__availableFeeScheduleList = this.model.map((item, index) =>
        this.__getAvailableFeeScheduleItems(item, index),
      );
    }

    super.update(changedProps);
  }

  __getAvailableFeeScheduleItems(item, index) {
    return this.feeScheduleItems.filter(feeSchedule => {
      if (index !== 0 && !feeSchedule.data.id) return false;

      if (feeSchedule.data.id === item.feeScheduleId.data.id) return true;

      return this.model.every(
        modelItem => feeSchedule.data.id !== modelItem.feeScheduleId.data.id,
      );
    });
  }

  __getAvailableProviderItems(item) {
    return this.providerItems.filter(provider => {
      const {
        data: { id: providerId },
      } = provider;

      if (
        item?.providerIds.find(
          itemProvider => itemProvider.data.id === providerId,
        )
      ) {
        return true;
      }

      return this.model
        .flatMap(modelItem => modelItem.providerIds)
        .every(modelProvider => modelProvider.data.id !== providerId);
    });
  }

  renderItem(item, index) {
    const showAllProviderOptions =
      index === 0 &&
      this.__availableProvidersList[index].length === this.providerItems.length;

    return html`
      <neb-fee-schedule-provider-item
        .providerLabel="${'Providers'}"
        .name="${index}"
        .model="${item}"
        .errors="${this.errors[index]}"
        .helpers="${this.helpers}"
        .providerItems="${this.__availableProvidersList[index]}"
        .feeScheduleItems="${this.__availableFeeScheduleList[index]}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        .showAllProvidersOption="${showAllProviderOptions}"
        ?showRemoveButton="${this.model.length > 1}"
      ></neb-fee-schedule-provider-item>
    `;
  }
}

window.customElements.define(
  'neb-fee-schedule-provider-list',
  NebFeeScheduleProviderList,
);
