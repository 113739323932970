import { store } from '../../packages/neb-redux/neb-redux-store';

const formatPath = (path, separator = ', ') => {
  const popups = path.popups.length
    ? ` popups: [${path.popups.join(separator)}]`
    : '';
  const overlays = path.overlays.length
    ? ` overlays: [${path.overlays.join(separator)}]`
    : '';
  const result = `route: "${path.routeHash}"${popups}${overlays}`;
  return result;
};

export const getNavigationState = (returnAsString = true) => {
  const state = store.getState();

  const path = {
    routeHash: state.route.hash,
    popups: state.popup.main.map(x => x.key),
    overlays: state.popup.overlays.map(x => x.key),
  };

  if (!returnAsString) {
    return path;
  }

  return formatPath(path);
};

export const navigateToRow = row => {
  row.addEventListener(
    'animationend',
    () => {
      row.classList.remove('item-highlight');
    },
    { once: true },
  );

  row.scrollIntoView({ behavior: 'smooth', block: 'center' });
  row.classList.add('item-highlight');
};
