/**
 * Returns the value from an object specified by the given path.
 *
 * @param {Object} object
 * @param {String} path
 * @returns {*}
 */
export function getValueFromPath(object, path) {
  const parts = path.split('.');

  let current = object;

  for (let i = 0; i < parts.length; i++) {
    if (current == null) {
      break;
    }

    const part = parts[i];
    current = current[part];
  }

  return current;
}

function defaultComparison(value1, value2) {
  return value1 === value2;
}

export function watch(getState, path, comparison = defaultComparison) {
  let prevValue = getValueFromPath(getState(), path);

  return cb => () => {
    const newValue = getValueFromPath(getState(), path);

    if (comparison(prevValue, newValue)) {
      return;
    }

    const callbackPrevValue = prevValue;
    prevValue = newValue;
    cb(newValue, callbackPrevValue, path);
  };
}
