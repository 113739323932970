import '../../neb-lit-components/src/components/neb-action-bar';
import '../../neb-lit-components/src/components/tables/neb-table-ledger-activity-encounters-charge-groups';
import '../../neb-lit-components/src/components/neb-tooltip';

import { html, css } from 'lit';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  chargeGroup: { id: 'charge-group' },
  actionBar: { id: 'action-bar' },
  chargeGroupTable: { id: 'charge-group-table' },
  subText: { id: 'sub-text' },
  tooltip: { id: 'tooltip' },
};

export const POPUP_CONFIG_MAP = {
  View: {
    subText: 'To View Charges, select from one of the groups of charges below.',
    tooltip:
      'You can only perform this action for uninvoiced, posted charges with the same Guarantor, Case, Primary Payer, Plan, and Secondary Payer.',
  },
  Create: {
    subText:
      'To Create Invoice, select from one of the groups of charges below.',
    tooltip:
      'You can only perform this action for posted charges with the same Guarantor, Case, Primary Payer, Plan, Secondary Payer, and Invoice.',
  },
};

class NebPopupChargeGroup extends NebPopup {
  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__selectedGroup = {};

    this.title = 'Select Group of Charges';
    this.model = { confirmLabel: 'View', groups: [] };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      confirm: () => this.onClose(this.__selectedGroup),
      selectGroup: group => {
        this.__selectedGroup = group;
      },
    };
  }

  modelChanged() {
    [this.__selectedGroup] = this.model.groups;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: 500px;
          width: 900px;
        }
        .container {
          padding: 20px 0 0 0;
        }
        .title {
          padding-left: 30px;
        }
        .icon-cancel {
          margin: 0 20px 0 0;
        }
        .wrapper {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
        }
        .subText {
          display: flex;
          margin-top: -13px;
          padding-left: 30px;
          padding-bottom: 21px;
        }
        .chargeGroup {
          height: 327px;
          overflow-y: auto;
        }
        .actionBar {
          flex-shrink: 0;
          width: 100%;
        }
        .tooltip {
          margin-left: 5px;
        }
      `,
    ];
  }

  renderContent() {
    const { subText, tooltip } = POPUP_CONFIG_MAP[this.model.confirmLabel];

    return html`
      <div class="wrapper">
        <div class="subText">
          <span id="${ELEMENTS.subText.id}">${subText}</span>
          <neb-tooltip
            id="${ELEMENTS.tooltip.id}"
            class="tooltip spacing"
            defaultAnchor="right"
          >
            <div slot="tooltip">${tooltip}</div>
          </neb-tooltip>
        </div>
        <div class="chargeGroup" id="${ELEMENTS.chargeGroup.id}">
          <neb-table-ledger-activity-encounters-charge-groups
            id="${ELEMENTS.chargeGroupTable.id}"
            .model="${this.model.groups}"
            .patient="${this.model.patient}"
            .onSelect="${this.handlers.selectGroup}"
            ?includePatient="${this.model.includePatient}"
          >
          </neb-table-ledger-activity-encounters-charge-groups>
        </div>
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          class="actionBar"
          cancelLabel="Cancel"
          confirmLabel="${this.model.confirmLabel}"
          .onConfirm="${this.handlers.confirm}"
          .onCancel="${this.handlers.cancel}"
        ></neb-action-bar>
      </div>
    `;
  }
}

customElements.define('neb-popup-charge-group', NebPopupChargeGroup);
