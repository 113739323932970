import '../controls/neb-button-icon';
import '../inputs/neb-picker-color';
import '../inputs/neb-picker-color-advanced';
import '../inputs/neb-select';

import { isRequired } from '@neb/form-validators';
import { css, html, LitElement } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import * as selectors from '../../../../neb-utils/selectors';
import {
  getValueByPath,
  map,
  objToHours,
  PERIOD,
} from '../../../../neb-utils/utils';

import Duration from './neb-duration';

const EXT_OFFSETS = {
  start: -1,
  end: 1,
};

export const OPTIONS_SLOT_AMOUNT = new Array(10)
  .fill(0)
  .map((_, index) => ({ label: `${index + 1}`, data: { id: index + 1 } }));
export const OPTIONS_INTERVAL_AMOUNT = [1, 2, 3, 4, 5, 6, 12].map(v => ({
  label: v % 12 !== 0 ? `${v * 5} minutes` : '1 hour',
  data: { id: v },
}));

const toScalar = obj => objToHours(map(obj, (_, v) => Number(v) || v));

export function getOtherSegmentPath(keyPath) {
  const extKey = keyPath[keyPath.length - 1];
  const parentPath = keyPath.slice(0, keyPath.length - 1);
  const resKey = extKey === 'start' ? 'end' : 'start';

  return [...parentPath, resKey];
}

export function getOtherIntersectPath(keyPath) {
  const extKey = keyPath[keyPath.length - 1];
  const segmentIndex = Number(keyPath[keyPath.length - 2]);
  const targetIndex = `${segmentIndex + EXT_OFFSETS[extKey]}`;
  const rootPath = keyPath.slice(0, keyPath.length - 2);
  const targetKey = extKey === 'start' ? 'end' : 'start';

  return [...rootPath, targetIndex, targetKey];
}

export function segmentExtent() {
  return {
    error: 'Conflicting Time',
    validate: (ext, keyPath, state) => {
      const extKey = keyPath[keyPath.length - 1];
      const resPath = getOtherSegmentPath(keyPath);
      const extValue = toScalar(ext);

      const resState = getValueByPath(state, resPath);
      const resValue = toScalar(resState);

      return extKey === 'start' ? extValue <= resValue : resValue <= extValue;
    },
  };
}

export function intersectSegment() {
  return {
    error: 'Conflicting Time',
    validate: (ext, keyPath, state) => {
      const extKey = keyPath[keyPath.length - 1];
      const targetPath = getOtherIntersectPath(keyPath);
      const targetValue = getValueByPath(state, targetPath);

      if (targetValue) {
        const resValue = toScalar(targetValue);
        const extValue = toScalar(ext);

        return extKey === 'start' ? extValue >= resValue : extValue <= resValue;
      }

      return true;
    },
  };
}

export const ELEMENTS = {
  startDuration: { id: 'duration-start' },
  endDuration: { id: 'duration-end' },
  locationsSelect: { id: 'select-locations' },
  appointmentsSelect: { id: 'select-appointments' },
  removeButton: { id: 'button-remove' },
  timeSlotInterval: { id: 'select-slotInterval' },
  multiBookingCount: { id: 'select-booking-count' },
  pickerColor: { id: 'picker-color' },
  pickerColorAdvanced: { id: 'picker-color-advanced' },
};

export default class AvailabilityItem extends LitElement {
  static get properties() {
    return {
      name: String,
      model: Object,
      errors: Object,
      locations: Array,
      types: Array,
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: 1fr 3fr 0fr 0fr 3fr 1fr;
        }

        .item {
          display: flex;
          height: 40px;
          align-items: center;
        }

        .item-duration {
          display: grid;
          height: 40px;
          align-items: center;
          justify-content: end;
        }

        .dotted-line {
          height: 1px;
          border: 1px dashed ${CSS_COLOR_GREY_2};
          grid-column: 1 / span 6;
          margin-top: 10px;
        }

        .locations-select {
          margin-bottom: 10px;
        }

        .field {
          width: fit-content;
        }

        .textarea-description {
          height: 200px;
        }

        .dropdowns-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .color-container {
          display: flex;
          align-items: center;
          gap: ${CSS_SPACING};
        }

        .padding {
          padding: 10px 0px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      start: Duration.createModel(8, 0, PERIOD.AM),
      end: Duration.createModel(6, 0, PERIOD.PM),
      locations: [undefined],
      types: [],
      slotInterval: null,
      multiBookingCount: null,
      color: '#ffffff',
    };
  }

  static createSelectors(locations, types) {
    return {
      children: {
        start: Duration.createSelectors([segmentExtent(), intersectSegment()]),
        end: Duration.createSelectors([segmentExtent(), intersectSegment()]),
        locations: selectors.multiSelect(locations, locations, {
          validators: [isRequired()],
        }),
        types: selectors.multiSelect(types, types, {
          validators: [isRequired()],
        }),
        slotInterval: selectors.select(
          OPTIONS_INTERVAL_AMOUNT,
          OPTIONS_INTERVAL_AMOUNT[0],
          { validators: [isRequired()] },
        ),
        multiBookingCount: selectors.select(
          OPTIONS_SLOT_AMOUNT,
          OPTIONS_SLOT_AMOUNT[0],
          { validators: [isRequired()] },
        ),
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.name = '';
    this.locations = [];
    this.types = [];
    this.model = AvailabilityItem.createModel();
    this.errors = {
      start: '',
      end: '',
      locations: '',
      types: '',
    };

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
      remove: e => {
        if (!e.currentTarget.disabled) {
          this.onRemove(this.name);
        }
      },
    };
  }

  __renderLocationsDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="locations-select"
        allLabel="Locations"
        label="Locations"
        name="locations"
        helper="Required"
        .items="${this.locations}"
        .value="${this.model.locations}"
        .error="${this.errors.locations}"
        .onChange="${this.__handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  render() {
    return html`
      <div class="container">
        <span class="item-duration">Start</span>

        <neb-duration
          id="${ELEMENTS.startDuration.id}"
          helper=" "
          name="start"
          .model="${this.model.start}"
          .error="${this.errors.start}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.disabled}"
        ></neb-duration>

        <div class="spacer"></div>

        <span class="item-duration">End</span>

        <neb-duration
          id="${ELEMENTS.endDuration.id}"
          helper=" "
          name="end"
          .model="${this.model.end}"
          .error="${this.errors.end}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.disabled}"
        ></neb-duration>

        <neb-button-icon
          id="${ELEMENTS.removeButton.id}"
          class="item"
          icon="neb:minus"
          @click="${this.__handlers.remove}"
          ?disabled="${this.disabled}"
        ></neb-button-icon>

        <div class="spacer"></div>

        ${this.__renderLocationsDropdown()}

        <div class="spacer"></div>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.timeSlotInterval.id}"
          label="Time Slot Intervals"
          name="slotInterval"
          helper="Required"
          .items="${OPTIONS_INTERVAL_AMOUNT}"
          .value="${this.model.slotInterval}"
          .error="${this.errors.slotInterval}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.disabled}"
        ></neb-select>

        <div class="spacer"></div>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.appointmentsSelect.id}"
          allLabel="Appointments"
          label="Appointment Types"
          placeholder="None"
          name="types"
          helper="Required"
          .items="${this.types}"
          .value="${this.model.types}"
          .error="${this.errors.types}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.disabled}"
          multiSelect
        ></neb-select>

        <div class="spacer"></div>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.multiBookingCount.id}"
          label="Bookings per Time Slot"
          name="multiBookingCount"
          helper="Required"
          .items="${OPTIONS_SLOT_AMOUNT}"
          .value="${this.model.multiBookingCount}"
          .error="${this.errors.multiBookingCount}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.disabled}"
        ></neb-select>

        <div class="spacer"></div>

        <div class="spacer"></div>

        <div>
          <div class="padding">Background Color</div>

          <div class="color-container">
            <neb-picker-color
              id="${ELEMENTS.pickerColor.id}"
              class="field field-picker-color"
              name="color"
              .value="${this.model.color}"
              .onChange="${this.__handlers.change}"
            ></neb-picker-color>

            <neb-picker-color-advanced
              id="${ELEMENTS.pickerColorAdvanced.id}"
              class="field field-picker-color"
              name="color"
              .value="${this.model.color}"
              .onChange="${this.__handlers.change}"
            ></neb-picker-color-advanced>
          </div>
        </div>

        <div class="span dotted-line"></div>
      </div>
    `;
  }
}
window.customElements.define('neb-availability-item', AvailabilityItem);
