import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { CORE_API_URL } from '../../../../neb-utils/env';

export const ELEMENTS = {
  defaultTenantLogo: {
    id: 'default-tenant-logo',
  },
  tenantName: {
    id: 'tenant-name',
  },
  iconChevron: {
    id: 'icon-chevron',
  },
};

class NebTenantMenuItem extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.model = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          width: 100%;
        }

        .item-container {
          display: flex;
          align-items: center;
          padding: 0 16px;
        }

        .logo {
          max-width: 106px;
          max-height: 60px;
          text-align: center;
        }

        .name {
          padding-left: ${CSS_SPACING};
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 1 0 0;
        }

        .chevron {
          fill: ${CSS_COLOR_GREY_2};
          transform: rotate(270deg);
          width: 15px;
          height: 10px;
        }
      `,
    ];
  }

  __renderLogo() {
    return html`
      <img
        class="logo"
        id="${ELEMENTS.defaultTenantLogo.id}"
        src="${CORE_API_URL}/public/v1/${this.model.shortName}/logo"
        onerror="{
          const DEFAULT_IMAGE_SRC = '../src/chirotouch-logo-black.svg';
          if (this.src != DEFAULT_IMAGE_SRC) this.src = 
          DEFAULT_IMAGE_SRC
        };"
      />
    `;
  }

  render() {
    return html`
      <div class="item-container">
        ${this.__renderLogo()}
        <div id="${ELEMENTS.tenantName.id}" class="name">
          ${this.model.name}
        </div>
        <neb-icon
          id="${ELEMENTS.iconChevron.id}"
          class="chevron"
          icon="neb:chevron"
        ></neb-icon>
      </div>
    `;
  }
}

customElements.define('neb-tenant-menu-item', NebTenantMenuItem);
