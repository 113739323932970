const LOCAL_STORAGE_KEY_STATE = '__fetchTracking';

let inflightCount = 0;

const createOnce = cb => {
  let called = false;

  return () => {
    if (called) {
      return;
    }

    called = true;

    cb();
  };
};

const increment = ({ skipFetchCount }) => {
  if (skipFetchCount) {
    return;
  }

  inflightCount += 1;
};

const createDecrementOnce = ({ skipFetchCount }) =>
  skipFetchCount
    ? () => {}
    : createOnce(() => {
        inflightCount -= 1;
      });

const hasState = () =>
  Boolean(window.localStorage.getItem(LOCAL_STORAGE_KEY_STATE));

const getState = () => {
  const stateStr = window.localStorage.getItem(LOCAL_STORAGE_KEY_STATE);

  if (!stateStr) {
    return null;
  }

  return JSON.parse(stateStr);
};

const setState = state =>
  window.localStorage.setItem(LOCAL_STORAGE_KEY_STATE, JSON.stringify(state));

const clearState = () =>
  window.localStorage.removeItem(LOCAL_STORAGE_KEY_STATE);

const appendRequest = (start, { url, method }) => {
  const duration = new Date().getTime() - start.getTime();

  const state = getState();
  const { requests } = state;

  setState({
    ...state,
    requests: [...requests, { duration, method, url }],
  });
};

// https://performance.nebula.care/retro-api/api/v1/tenants/c5626798-993d-4006-8d97-2113068b0991/patients/18b3fff3-99b9-481c-ac3e-42f53a46a563/image/large

const fetchCountBlacklist = [
  ({ method, url }) =>
    method === 'GET' &&
    /\/retro-api\/api\/v1\/tenants\/[^/]+\/patients\/[^/]+\/image\/(large|small)/.test(
      url,
    ),
];

const getRequestContext = fetchArgs => {
  let [url] = fetchArgs;

  const [, { method = 'GET' } = {}] = fetchArgs;

  if (url && url.startsWith('data:')) {
    url = 'data:';
  }

  const context = { method, url };

  const skipFetchCount = fetchCountBlacklist.some(test => test(context));

  return {
    ...context,
    skipFetchCount,
  };
};

const trackFetch = () => {
  if (window.fetch.__isTracking) {
    return;
  }

  const origFetch = window.fetch;

  window.fetch = async (...args) => {
    const requestContext = getRequestContext(args);

    const decrementOnce = createDecrementOnce(requestContext);

    increment(requestContext);

    const start = new Date();

    let resp;

    try {
      resp = await origFetch.apply(window, args);
    } catch (err) {
      decrementOnce();
      throw err;
    }

    appendRequest(start, requestContext);

    const timeout = setTimeout(() => decrementOnce(), 100);

    const origJson = resp.json;

    resp.json = async (...args) => {
      clearTimeout(timeout);

      let jsonResp;

      try {
        jsonResp = await origJson.apply(resp, args);
      } catch (err) {
        decrementOnce();
        throw err;
      }

      setTimeout(() => decrementOnce(), 100);

      return jsonResp;
    };

    return resp;
  };

  window.fetch.__isTracking = true;
};

const startTracking = () => {
  if (!hasState()) {
    setState({ requests: [] });
  }

  trackFetch();
};

window.__fetchTracker = window.__fetchTracker || {};

Object.assign(window.__fetchTracker, {
  clearState,
  getInflightFetchCount: () => inflightCount,
  getState,
  startTracking: () => {
    clearState();
    startTracking();
  },
});

const attemptStartTracking = (maxAttempts = 5, interval = 1000) => {
  let attempts = 0;

  const tryStartTracking = () => {
    if (
      window.__fetchTracker &&
      typeof window.__fetchTracker.startTracking === 'function'
    ) {
      window.__fetchTracker.startTracking();
      console.log('Fetch tracking started successfully');
      return;
    }

    attempts++;

    if (attempts < maxAttempts) {
      console.log(`Attempt ${attempts} failed. Retrying in ${interval}ms...`);
      setTimeout(tryStartTracking, interval);
    } else {
      console.error('Failed to start fetch tracking after maximum attempts');
    }
  };

  if (hasState()) {
    tryStartTracking();
  }
};

attemptStartTracking();
