import { openPopup } from '@neb/popup';
import cheerio from 'cheerio';

import { ACTIONS } from '../../../neb-popup/src/neb-popup-macros';
import { ORDER as ORDER_BODY_DIAGRAM } from '../../../neb-popup/src/neb-popup-macros-body-diagram';
import { ORDER as ORDER_MULTI_SELECT } from '../../../neb-popup/src/neb-popup-macros-multi-select';
import { ORDER as ORDER_SPINE } from '../../../neb-popup/src/neb-popup-macros-spine';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { arrayToSentence } from '../../../neb-utils/array-to-sentence';

import { replaceStaticTokens } from './token-replacement';

export const QUESTION_TYPE_TO_POPUP = {
  freeText: POPUP_RENDER_KEYS.MACROS_FREE_TEXT_ENTRY,
  singleSelect: POPUP_RENDER_KEYS.MACROS_SINGLE_SELECT,
  multiSelect: POPUP_RENDER_KEYS.MACROS_MULTI_SELECT,
  numericEntry: POPUP_RENDER_KEYS.MACROS_NUMERIC_ENTRY,
  datePicker: POPUP_RENDER_KEYS.MACROS_DATE_PICKER,
  spine: POPUP_RENDER_KEYS.MACROS_SPINE,
  bodyDiagram: POPUP_RENDER_KEYS.MACROS_BODY_DIAGRAM,
};

const parseMultiSelectDefaultResponse = question => {
  const selected = question.defaultIndices.map(
    index => question.answers[index],
  );
  return arrayToSentence(selected);
};

const QUESTION_DEFAULT_PROCESSOR = {
  singleSelect: q => ({
    text: q.answers[q.defaultIndices[0]],
    selectedIndex: q.defaultIndices[0],
  }),
  multiSelect: q => ({
    text: parseMultiSelectDefaultResponse(q),
    order: ORDER_MULTI_SELECT.CLICK,
    selectedIndices: q.defaultIndices,
  }),
  bodyDiagram: q => ({
    text: parseMultiSelectDefaultResponse(q),
    order: ORDER_BODY_DIAGRAM.LIST,
    selectedIndices: q.defaultIndices,
  }),
  spine: q => ({
    text: parseMultiSelectDefaultResponse(q),
    order: ORDER_SPINE.LIST,
    selectedIndices: q.defaultIndices,
    selectedSections: q.defaultIndices.map(index => q.answers[index]),
  }),
};

const handleBasicQuestion = (popupKey, { question, answer, isFirst, isLast }) =>
  openPopup(popupKey, {
    title: question.text,
    default: question.defaultIndices,
    answer,
    isFirst,
    isLast,
  });

const handleSingleSelectQuestion = ({ question, answer, isFirst, isLast }) =>
  openPopup(QUESTION_TYPE_TO_POPUP.singleSelect, {
    title: question.text,
    options: question.answers,
    default: question.defaultIndices[0],
    answer,
    isFirst,
    isLast,
  });

const handleMultiSelectQuestion = ({ question, answer, isFirst, isLast }) =>
  openPopup(QUESTION_TYPE_TO_POPUP.multiSelect, {
    title: question.text,
    options: question.answers,
    default: question.defaultIndices,
    answer,
    isFirst,
    isLast,
  });

const handleBodyDiagramQuestion = ({ question, answer, isFirst, isLast }) =>
  openPopup(QUESTION_TYPE_TO_POPUP.bodyDiagram, {
    title: question.text,
    options: question.answers,
    default: question.defaultIndices,
    answer,
    isFirst,
    isLast,
  });

const QUESTION_TYPE_TO_POPUP_HANDLER = {
  freeText: detail =>
    handleBasicQuestion(QUESTION_TYPE_TO_POPUP.freeText, detail),
  singleSelect: detail => handleSingleSelectQuestion(detail),
  spine: detail => handleBasicQuestion(QUESTION_TYPE_TO_POPUP.spine, detail),
  multiSelect: detail => handleMultiSelectQuestion(detail),
  numericEntry: detail =>
    handleBasicQuestion(QUESTION_TYPE_TO_POPUP.numericEntry, detail),
  datePicker: detail =>
    handleBasicQuestion(QUESTION_TYPE_TO_POPUP.datePicker, detail),
  bodyDiagram: detail => handleBodyDiagramQuestion(detail),
};

const buildQuestionMetadataReplacements = metaData => {
  const { answer } = metaData;
  const payload = cheerio.load('<span></span>');
  payload('span').attr('data-question', JSON.stringify(metaData));
  payload('span').attr('contenteditable', 'false');
  payload('span').text(answer.text);
  return payload('body').html();
};

const replaceField =
  document =>
  ([field, replacement]) => {
    document(`*[data-macro-replace-field="${field}"]`).each((_idx, node) => {
      const item = document(node);
      const html = item.html();

      item.replaceWith(
        ['strong', 'i', 'u'].reduce(
          (acc, tag) =>
            html.includes(`<${tag}>`) ? `<${tag}>${acc}</${tag}>` : acc,
          replacement,
        ),
      );
    });
  };

const replaceFields = async (macro, responses, encounter) => {
  const document = cheerio.load(macro.content);
  const replacements = await replaceStaticTokens(encounter);
  responses.forEach((response, i) => {
    replacements[macro.questions[i].id] = response
      ? buildQuestionMetadataReplacements({
          question: macro.questions[i],
          answer: response,
        })
      : '';
  });

  Object.entries(replacements).forEach(replaceField(document));
  return document('body')
    .html()
    .replace(/&#xA0;/g, '&nbsp;');
};

const askQuestion = async (question, answer, isFirst, isLast) => {
  if (!question) {
    return null;
  }

  const processor = QUESTION_TYPE_TO_POPUP_HANDLER[question.type];

  if (!processor) {
    return console.warn('Unrecognized question type:', question.type);
  }

  const response = await processor({
    isFirst,
    isLast,
    question,
    answer: answer || null,
  });
  return response || null;
};

const partitionQuestionList = questions => {
  const nonDefaultedQuestionIndexList = [];
  const defaultedQuestionIndexList = [];
  questions.forEach((question, index) => {
    if (
      question.defaultIndices != null &&
      question.defaultIndices.toString().length > 0
    ) {
      defaultedQuestionIndexList.push(index);
    } else {
      nonDefaultedQuestionIndexList.push(index);
    }
  });

  return {
    nonDefaultedQuestionIndexList,
    defaultedQuestionIndexList,
  };
};

const processDefaultQuestions = (questions, defaultedQuestionIndexList) =>
  defaultedQuestionIndexList.map(index => {
    const question = questions[index];
    const processor = QUESTION_DEFAULT_PROCESSOR[question.type];

    return processor(question);
  });

const getQuestionPosition = (questionStep, totalQuestionSteps) => {
  const isFirst = questionStep === 0;
  const isLast = questionStep === totalQuestionSteps - 1;
  return {
    isFirst,
    isLast,
  };
};

const askMultipleQuestions = async questions => {
  const { nonDefaultedQuestionIndexList, defaultedQuestionIndexList } =
    partitionQuestionList(questions);
  const currentAnswerMetaData = [];
  const totalQuestionSteps = nonDefaultedQuestionIndexList.length;

  let currentQuestionStep = 0;

  while (currentQuestionStep < totalQuestionSteps) {
    const questionIndex = nonDefaultedQuestionIndexList[currentQuestionStep];
    const { isFirst, isLast } = getQuestionPosition(
      currentQuestionStep,
      totalQuestionSteps,
    );
    const { action, answer } = await askQuestion(
      questions[questionIndex],
      currentAnswerMetaData[questionIndex],
      isFirst,
      isLast,
    );

    switch (action) {
      case ACTIONS.CANCEL:
        return null;

      case ACTIONS.BACK:
        currentAnswerMetaData[questionIndex] = answer;
        currentQuestionStep -= 1;
        break;

      default:
        currentAnswerMetaData[questionIndex] = answer;
        currentQuestionStep += 1;
        break;
    }
  }

  const defaultQuestionMetaData = processDefaultQuestions(
    questions,
    defaultedQuestionIndexList,
  );
  defaultedQuestionIndexList.forEach((q, index) => {
    currentAnswerMetaData[q] = defaultQuestionMetaData[index];
  });

  return currentAnswerMetaData;
};

export const processMacro = async (macro, encounter) => {
  const responses = await askMultipleQuestions(macro.questions);
  return responses
    ? (await replaceFields(macro, responses, encounter)).trim()
    : null;
};

export const editQuestion = async ({ question, answer }) => {
  const { action, answer: updatedAnswer } = await askQuestion(
    question,
    answer,
    true,
    true,
  );
  return action !== ACTIONS.CANCEL
    ? {
        question,
        answer: updatedAnswer || null,
      }
    : null;
};
