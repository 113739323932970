import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import EmailList from '../../../../packages/neb-lit-components/src/components/field-groups/neb-email-list';
import Name from '../../../../packages/neb-lit-components/src/components/field-groups/neb-name';
import PhoneList from '../../../../packages/neb-lit-components/src/components/field-groups/neb-phone-list';
import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  PATIENT_RELATIONSHIP_TYPE,
  PATIENT_RELATIONSHIP_TYPES,
} from '../../../../packages/neb-utils/patientRelationship';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  name: { id: 'name' },
  relation: { id: 'relation' },
  phoneList: { id: 'phone-list' },
  emailList: { id: 'email-list' },
  isPrimary: { id: 'is-primary' },
};

const NAME_HELPERS = {
  first: 'Required',
  last: 'Required',
};

const PHONE_HELPERS = {
  number: 'Required',
  type: '',
};

export default class NebFormEmergencyContact extends Form {
  static createModel() {
    return {
      name: Name.createModel(),
      relation: PATIENT_RELATIONSHIP_TYPE.Other,
      phones: PhoneList.createModel(),
      emails: EmailList.createModel(),
      isPrimary: false,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
        .layout {
          grid-row-gap: 8px;
        }
        neb-email-list {
          margin-top: -8px;
        }
        neb-checkbox[name='isPrimary'] {
          margin-left: 1px;
          justify-self: start;
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        name: Name.createSelectors(),
        relation: [isRequired()],
        phones: PhoneList.createSelectors({ validators: [isRequired()] }),
        emails: EmailList.createSelectors(),
      },
    };
  }

  renderContent() {
    return html`
      <neb-name
        id="${ELEMENTS.name.id}"
        name="name"
        .errors="${this.errors.name}"
        .helpers="${NAME_HELPERS}"
        .model="${this.state.name}"
        .onChange="${this.handlers.change}"
        .showPreferred="${false}"
        showSuffix
      ></neb-name>

      <neb-select
        id="${ELEMENTS.relation.id}"
        name="relation"
        label="Relation to Patient"
        helper="Required"
        .items="${PATIENT_RELATIONSHIP_TYPES}"
        .value="${this.state.relation}"
        .error="${this.errors.relation}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-phone-list
        id="${ELEMENTS.phoneList.id}"
        name="phones"
        .errors="${this.errors.phones}"
        .helpers="${PHONE_HELPERS}"
        .model="${this.state.phones}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-phone-list>

      <neb-email-list
        id="${ELEMENTS.emailList.id}"
        name="emails"
        maxItems="3"
        condense
        .errors="${this.errors.emails}"
        .model="${this.state.emails}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-email-list>

      <neb-checkbox
        id="${ELEMENTS.isPrimary.id}"
        name="isPrimary"
        label="Primary Contact"
        .onChange="${this.handlers.change}"
        ?checked="${this.state.isPrimary}"
      ></neb-checkbox>
    `;
  }
}

window.customElements.define(
  'neb-form-emergency-contact',
  NebFormEmergencyContact,
);
