import {
  calculateTreatmentPlan,
  createTreatmentPlan,
  updateTreatmentPlan,
} from '../../packages/neb-api-client/src/treatment-plans';
import {
  updateNotesWithTable,
  FIGURE_KEYS,
} from '../../packages/neb-www-practice-charting/src/components/shared/util/notes-figure-table-builder';
import { openError, openSuccess } from '../store';

const ID_NEW = 'new';

const TEXT_APPEND_TREATMENT_PLAN_SUCCESS =
  'Treatment plan copied to chart note.';
const TEXT_APPEND_TREATMENT_PLAN_ERROR =
  'Treatment plan was not copied to chart note.';

const mapTreatmentPhaseToClient = treatmentPhase => {
  const phase = { ...treatmentPhase };

  if (phase.id === 'new') {
    if (!phase.occurrence) phase.occurrence = 0;
    if (!phase.frequency) phase.frequency = 0;
    if (!phase.duration) phase.duration = 0;
  }

  return phase;
};

const mapTreatmentPhaseToModel = treatmentPhase => {
  const phase = { ...treatmentPhase };

  if (phase.id === 'new') {
    if (phase.occurrence === 0) phase.occurrence = '';
    if (phase.frequency === 0) phase.frequency = '';
    if (phase.duration === 0) phase.duration = '';
  }

  return phase;
};

export const calculateTreatmentPlanForPatient = async (
  treatmentPlan,
  patientId,
) => {
  if (patientId && treatmentPlan.startDate) {
    try {
      const plan = JSON.parse(JSON.stringify(treatmentPlan));
      plan.treatmentPhases = plan.treatmentPhases.map(phase =>
        mapTreatmentPhaseToClient(phase),
      );

      const response = await calculateTreatmentPlan(patientId, plan);

      const calculatedPlan = response.data[0];

      calculatedPlan.treatmentPhases = calculatedPlan.treatmentPhases.map(
        phase => mapTreatmentPhaseToModel(phase),
      );

      return calculatedPlan;
    } catch (err) {
      console.error(
        'An error occurred while calculating the treatment plan: ',
        treatmentPlan,
        err,
      );

      openError('An error occurred while calculating the treatment plan.');
    }
  }
  return undefined;
};

const parseCurrentTreatmentPlan = treatmentPlan => {
  const treatmentPhases = treatmentPlan.treatmentPhases
    .filter(tp => tp.id !== ID_NEW)
    .map(
      ({
        completedDate,
        occurrence,
        frequency,
        frequencyType,
        duration,
        durationType,
      }) => ({
        completedDate,
        occurrence,
        frequency,
        frequencyType,
        duration,
        durationType,
      }),
    );
  return {
    startDate: treatmentPlan.startDate,
    completedDate: treatmentPlan.completedDate,
    note: treatmentPlan.note,
    treatmentPhases,
  };
};

export const copyTreatmentPlan = async (
  treatmentPlan,
  encounterId,
  providerId,
) => {
  try {
    const treatmentPlanInfo = parseCurrentTreatmentPlan(treatmentPlan);

    await updateNotesWithTable(
      encounterId,
      treatmentPlanInfo,
      FIGURE_KEYS.CURRENT_TREATMENT_PLAN,
      providerId,
    );

    openSuccess(TEXT_APPEND_TREATMENT_PLAN_SUCCESS);
  } catch (e) {
    console.warn(e);
    openError(TEXT_APPEND_TREATMENT_PLAN_ERROR);
    return false;
  }
  return true;
};

export const saveTreatmentPlan = (treatmentPlan, patientId) => {
  if (treatmentPlan.id) {
    treatmentPlan.note = treatmentPlan.note || null;
    return updateTreatmentPlan(patientId, treatmentPlan);
  }

  return createTreatmentPlan(patientId, treatmentPlan);
};
