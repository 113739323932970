import { warn as warnAction } from '../../neb-route/neb-route-state';
import { createRouteParser } from '../../neb-route/neb-route-util';
import { store } from '../neb-redux-store';

const WARNING_MESSAGE =
  'You have unsaved changes, are you sure you want to leave?';
export class Dirty {
  constructor(
    dirtyConfig,
    isDirty,
    params = {
      useNewDirtyDialog: false,
      formIsValid: () => false,
      onSave: null,
      onDiscard: null,
      onStay: null,
    },
  ) {
    this.__shouldCheckDirty = this.__createFunctionForDirtyContext(dirtyConfig);
    this.__isDirty = isDirty;

    const { useNewDirtyDialog, formIsValid, onSave, onDiscard, onStay } =
      params;

    this.__useNewDirtyDialog = useNewDirtyDialog;
    this.__formIsValid = formIsValid;
    this.__onSave = onSave;
    this.__onDiscard = onDiscard;
    this.__onStay = onStay;
  }

  __createFunctionForDirtyContext(dirtyConfig) {
    if (typeof dirtyConfig === 'string') {
      return this.__stringPatternStrategy(createRouteParser(dirtyConfig));
    }

    if (dirtyConfig instanceof RegExp) {
      return this.__regexPatternStrategy(dirtyConfig);
    }

    return dirtyConfig;
  }

  __stringPatternStrategy(routeParser) {
    return (hash, nextHash) => {
      const currentRoute = routeParser.parse(hash);

      if (!currentRoute.active) {
        return false;
      }

      const nextRoute = routeParser.parse(nextHash);

      if (!nextRoute.active) {
        return true;
      }

      return (
        JSON.stringify(currentRoute.data) !== JSON.stringify(nextRoute.data)
      );
    };
  }

  __regexPatternStrategy(regexp) {
    return (hash, nextHash) => {
      const currentMatch = regexp.exec(hash);

      if (!currentMatch) {
        return false;
      }

      const nextMatch = regexp.exec(nextHash);

      if (!nextMatch) {
        return true;
      }

      const currentCaptureGroups = currentMatch.slice(1);
      const nextCaptureGroups = nextMatch.slice(1);
      return (
        currentCaptureGroups.length !== nextCaptureGroups.length ||
        currentCaptureGroups.some(
          (_, i) => currentCaptureGroups[i] !== nextCaptureGroups[i],
        )
      );
    };
  }

  connect() {
    this.__unsubscribe = store.subscribe(() =>
      this._stateChanged(store.getState()),
    );

    this._stateChanged(store.getState());

    window.addEventListener(
      'beforeunload',
      (this.__onBeforeUnloadCallback = e => this.__onBeforeUnload(e)),
    );
  }

  disconnect() {
    if (this.__unsubscribe) {
      this.__unsubscribe();

      this.__unsubscribe = null;
    }

    if (this.__onBeforeUnloadCallback) {
      window.removeEventListener('beforeunload', this.__onBeforeUnloadCallback);
      this.__onBeforeUnloadCallback = null;
    }
  }

  _stateChanged({ route }) {
    if (!route) return;

    const { navigating, warn, hash, nextHash } = route;

    if (!navigating || warn) {
      return;
    }

    if (this.__shouldCheckDirty(hash, nextHash) && this.__isDirty()) {
      this.__warn();
    }
  }

  __warn() {
    store.dispatch(
      warnAction(false, {
        useNewDirtyDialog: this.__useNewDirtyDialog,
        isDirty: this.__isDirty(),
        formIsValid: this.__formIsValid(),
        ...(this.__onSave ? { onSave: this.__onSave } : {}),
        ...(this.__onDiscard ? { onDiscard: this.__onDiscard } : {}),
        ...(this.__onStay ? { onStay: this.__onStay } : {}),
      }),
    );
  }

  __onBeforeUnload(e) {
    if (window.nebDisableDirty) return '';

    if (this.__isDirty() && !this.__useNewDirtyDialog) {
      e.returnValue = WARNING_MESSAGE;
      return WARNING_MESSAGE;
    }

    return '';
  }
}
