import { getAvailability } from '../../../../neb-availability/neb-availability-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../../neb-utils/date-util';
import { objToName } from '../../../../neb-utils/formatters';

const fetchMonthAvailability = async (
  apptTypeId,
  providerId,
  shortName,
  date,
  reFetch = false,
) => {
  const params = {
    shortName,
    targetDate: date,
    providerId,
    apptTypeId,
    reFetch,
  };
  const getSingleProviderAvailability = getAvailability(params);
  await getSingleProviderAvailability(store.dispatch);
};

const getAvailableProviders = (
  providers,
  providersAvailability,
  appointmentTypeId,
) => {
  const activeProviders = providers.filter(prov => prov.active);
  const activeProvidersIds = activeProviders.map(prov => prov.id);

  const providersAvailabilityById = Object.entries(providersAvailability).map(
    ([id, values]) => ({ id, ...values }),
  );

  const activeProvidersAvailability = providersAvailabilityById.filter(item =>
    activeProvidersIds.includes(item.id),
  );

  const apptTypeFilter = prov => {
    const dayPeriods = Object.values(prov.availability)
      .filter(values => values.enabled)
      .map(values => values.periods);

    const result = dayPeriods.map(periods =>
      periods.filter(
        period => period.allTypes || period.types.includes(appointmentTypeId),
      ),
    );
    return result.some(item => item.length);
  };

  const filteredProvidersIds = activeProvidersAvailability
    .filter(apptTypeFilter)
    .map(prov => prov.id);

  const availableProviders = activeProviders.filter(prov =>
    filteredProvidersIds.includes(prov.id),
  );

  return availableProviders.map(prov => ({
    ...prov,
    label: objToName(prov.name, { reverse: true }),
  }));
};

const filterAvailability = (
  availability,
  advanceBookingDateCutOff,
  advanceNoticeDateCutOff,
) => {
  const advanceNoticeFormattedDate = parseDate(advanceNoticeDateCutOff).format(
    'YYYY-MM-DD',
  );

  const advanceBookingFormattedDate = parseDate(
    advanceBookingDateCutOff,
  ).format('YYYY-MM-DD');

  const advanceNoticeTime =
    Number(parseDate(advanceNoticeDateCutOff).format('HH')) +
    Number(parseDate(advanceNoticeDateCutOff).format('mm')) / 60;

  const filteredAvail = {};
  Object.keys(availability).forEach(day => {
    if (
      parseDate(day).isAfter(advanceNoticeFormattedDate) &&
      parseDate(day).isBefore(advanceBookingFormattedDate)
    ) {
      filteredAvail[day] = availability[day];
    } else if (day === advanceNoticeFormattedDate) {
      filteredAvail[day] = availability[day].filter(
        time => time > advanceNoticeTime,
      );
    }
  });

  return filteredAvail;
};

const toLowerCaseKeys = json =>
  Object.keys(json).reduce((accum, key) => {
    accum[key.toLowerCase()] = json[key];
    return accum;
  }, {});

const calcDateCutOff = timeInAdvance => {
  const dateCutOff = toLowerCaseKeys(timeInAdvance);

  if (dateCutOff.years) {
    return parseDate().add(dateCutOff.years, 'years').format();
  }

  if (dateCutOff.months) {
    return parseDate().add(dateCutOff.months, 'months').format();
  }

  if (dateCutOff.weeks) {
    return parseDate().add(dateCutOff.weeks, 'weeks').format();
  }

  if (dateCutOff.days) {
    return parseDate().add(dateCutOff.days, 'days').format();
  }

  if (dateCutOff.hours) {
    return parseDate().add(dateCutOff.hours, 'hours').format();
  }

  return undefined;
};

export {
  fetchMonthAvailability,
  filterAvailability,
  getAvailableProviders,
  calcDateCutOff,
};
