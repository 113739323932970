import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-selection-card';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  createRuleSet,
  getRuleSet,
  updateRuleSet,
} from '../../../api-clients/claim-scrubbing';
import { openError, openSuccess } from '../../../store';
import { CSS_SPACING, layoutStyles } from '../../../styles';
import FormChargeRuleSet from '../../forms/settings/neb-form-charge-rule-set';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class OverlayChargeRuleSet extends Overlay {
  static get properties() {
    return {
      __formModel: {
        type: Object,
      },
      __defaultRules: {
        type: Object,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .content {
          width: 1024px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = FormChargeRuleSet.createModel();
    this.__defaultRules = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          let res;

          const ruleSet = this.formatPayersAndGroups(model);

          if (model.id) {
            res = await updateRuleSet(model.id, ruleSet);
          } else {
            res = await createRuleSet(ruleSet);
          }

          await store.dispatch(
            openSuccess('Charge rule set saved successfully.'),
          );

          this.isDirty = false;
          this.dismiss(res);
        } catch (e) {
          console.error(e);
          await store.dispatch(
            openError('An error occurred when saving charge rule set.'),
          );

          this.isDirty = false;
          this.dismiss();
        }
      },
    };
  }

  async __fetchRuleSet() {
    if (this.model.id) {
      const { ruleSet, defaultRules } = await getRuleSet(this.model.id);

      this.__formModel = {
        id: ruleSet.id,
        name: ruleSet.name,
        level: ruleSet.level,
        active: ruleSet.active,
        ruleSetRules: ruleSet.ruleSetRules.map(r => r.ruleDetail),
        ruleSetPayers: ruleSet.ruleSetPayers.map(r => r.payerPlan),
        ruleSetPayerGroups: ruleSet.ruleSetPayerGroups.map(r => r.payerGroup),
        ruleSetCharges: ruleSet.ruleSetCharges.map(r => r.charge),
        allPayer: ruleSet.allPayer,
        medicarePayer: ruleSet.medicarePayer,
      };

      this.__defaultRules = defaultRules;
    }
  }

  formatPayersAndGroups(data) {
    const { id, ...ruleSet } = data;

    if (ruleSet.allPayer || ruleSet.medicarePayer) {
      ruleSet.ruleSetPayerGroups = [];
      ruleSet.ruleSetPayers = [];
    }
    return ruleSet;
  }

  async firstUpdated() {
    super.firstUpdated();

    await this.__fetchRuleSet();
  }

  __getTitle() {
    return `${this.model.id ? 'Update' : 'Add'} Charge Rule Set`;
  }

  renderContent() {
    return html`
      <div>
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="${this.__getTitle()}"
          subTitle="Charge specific rules will apply only to the configured charges and payers."
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>
      </div>

      <neb-form-charge-rule-set
        id="${ELEMENTS.form.id}"
        .names="${this.model.names}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .model="${this.__formModel}"
        .defaultRules="${this.__defaultRules}"
      ></neb-form-charge-rule-set>
    `;
  }
}

customElements.define('neb-overlay-charge-rule-set', OverlayChargeRuleSet);
