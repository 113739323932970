import '../neb-popup-header';

import { html, css } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

import Overlay, { ELEMENTS as BASE_ELEMENTS } from './neb-overlay';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  header: {
    id: 'header',
  },
};

export default class NebOverlayFormItem extends Overlay {
  static get config() {
    return {
      form: null,
      itemName: '',
      messageError: '',
      messageSuccess: '',
    };
  }

  static get properties() {
    return {
      showBackButton: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.showBackButton = false;
    this.model = {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async item => {
        try {
          const result = await this.save(item);
          store.dispatch(openSuccess(this.constructor.config.messageSuccess));
          this.isDirty = false;
          this.dismiss(result);
        } catch (e) {
          if (e.statusCode !== 409) {
            store.dispatch(openError(this.constructor.config.messageError));
          }
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.initialModel = { ...this.model };
      this.model = Object.keys(this.model).length
        ? this.getModel()
        : this.createModel();
    }

    super.update(changedProps);
  }

  save(_item) {
    throw new Error('getSaveApi() not implemented');
  }

  isEditing() {
    return Boolean(this.model.id);
  }

  createModel() {
    return this.constructor.config.form.createModel();
  }

  getModel() {
    if (this.model.item) {
      return this.model.item;
    }

    return Object.keys(this.model).includes('item')
      ? this.createModel()
      : this.model;
  }

  getTitle() {
    const { itemName } = this.constructor.config;
    return `${this.isEditing() ? 'Update' : 'Add'} ${itemName}`;
  }

  renderForm() {
    throw new Error('renderForm() not implemented');
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismiss}"
        ?showBackButton="${this.showBackButton}"
        showCancelButton
      ></neb-popup-header>

      ${this.renderForm()}
    `;
  }
}
