import './neb-calendar-day-column';

import { html, css } from 'lit';

import { CALENDAR_TYPES } from '../../../packages/neb-utils/calendar-resources-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
} from '../../styles';

import {
  NebBaseCalendarView,
  ELEMENTS as ELEMENTS_BASE,
} from './neb-base-calendar-view';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  calendarDayColumns: { selector: 'neb-calendar-day-column' },
  providerHeaders: { selector: '.provider-header' },
  locationHeaders: { selector: '.location-header' },
};

const VIEWPORT_OFFSET = 46;
const VIEWPORT_OFFSET_LOCATIONS = VIEWPORT_OFFSET + 40;

class NebProviderCalendarDayView extends NebBaseCalendarView {
  static get properties() {
    return {
      targetDate: String,
      startMinute: Number,
      endMinute: Number,
      locations: Array,
      providers: Array,
      appointmentDragAndDropSettings: Object,
      isMultiLocation: {
        type: Boolean,
        reflect: true,
        attribute: 'is-multi-location',
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .columns-header-container-layout,
        .columns-container-layout {
          position: relative;
          display: flex;
        }

        :host([is-multi-location]) .columns-header-container-layout,
        .columns-container-layout {
          flex: 1 1 0;
        }

        .column-header {
          flex: 1;
          min-width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: bold;
        }

        .day-column {
          flex: 1;
          min-width: 300px;
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          border-top: 1px solid ${CSS_COLOR_GREY_2};
          border-left: 1px solid ${CSS_COLOR_GREY_2};
          margin-left: 10px;
        }

        .day-column:first-child {
          margin-left: 0;
        }

        .container-column-header {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-column-header:not(:first-child) {
          margin-left: 10px;
        }

        :host([is-multi-location]) .container-column-header {
          height: 90px;
          box-shadow: 0 0 0 1px ${CSS_COLOR_GREY_2} inset;
        }

        :host([is-multi-location]) .container-column-header:first-child,
        :host([is-multi-location]) .location-header:first-child {
          margin-left: 0;
        }

        .container-location-header {
          display: flex;
          flex: 1 0 0;
        }

        :host(:not([is-multi-location])) .provider-header {
          margin-bottom: 31px;
        }

        .location-header {
          margin-left: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.locations = [];
    this.providers = [];
    this.targetDate = '';
    this.viewportTopOffset = 0;
    this.isMultiLocation = false;
    this.appointmentDragAndDropSettings = {};
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('locations') && this.locations) {
      this.isMultiLocation = this.locations.length > 1;

      this.viewportTopOffset = this.isMultiLocation
        ? VIEWPORT_OFFSET_LOCATIONS
        : VIEWPORT_OFFSET;
    }
  }

  firstUpdated() {
    super.firstUpdated();

    this.isMultiLocation = this.locations.length > 1;
  }

  __renderLocationHeaders() {
    return this.isMultiLocation
      ? html`
          <div class="container-location-header">
            ${this.locations.map(
              location => html`
                <div class="column-header location-header">
                  ${location.name}
                </div>
              `,
            )}
          </div>
        `
      : '';
  }

  renderColumnHeaders() {
    return this.providers.map(
      provider => html`
        <div class="container-column-header">
          <div class="provider-header column-header">
            ${objToName(provider.name, DEFAULT_NAME_OPTS)}
          </div>
          ${this.__renderLocationHeaders()}
        </div>
      `,
    );
  }

  renderColumns() {
    return this.providers.map(
      provider => html`
          ${this.locations.map(location => {
            const { events = null, masked = null } = this.getEvents(
              this.targetDate,
              provider.id,
              location.id,
            );

            return html`
              <neb-calendar-day-column
                class="day-column"
                type="${CALENDAR_TYPES.PROVIDER}"
                .events="${events}"
                .maskedSlots="${masked}"
                .locations="${this.locations}"
                .locationId="${location.id}"
                .providerId="${provider.id}"
                .providers="${this.providers}"
                .date="${this.targetDate}"
                .zoomInterval="${this.zoomInterval}"
                .isDragging="${this.isDragging}"
                .active="${location.active}"
                .start="${this.startMinute}"
                .end="${this.endMinute}"
                .appointmentDragAndDropSettings="${this
                  .appointmentDragAndDropSettings}"
                .onUpdateDragging="${this.handlers.updateDragging}"
                .onUpdateCalendar="${this.handlers.updateCalendar}"
              ></neb-calendar-day-column>
            `;
          })}
        </div>
      `,
    );
  }
}

customElements.define(
  'neb-provider-calendar-day-view',
  NebProviderCalendarDayView,
);
