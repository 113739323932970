import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});

export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});

export const getAlertsToDisplayByPatient = async patientId => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
    replacements: { patientId },
  });

  return res.data ? res.data : null;
};

export const getAllAlertsByPatient = async patientId => {
  const res = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
    replacements: { patientId },
  });

  return res.count ? res.data[0].alerts : null;
};

export const createAlert = async (patientId, alert) => {
  const res = await coreApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
    replacements: { patientId },
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const updateAlerts = async (patientId, alerts, isReorder = false) => {
  const res = await coreApiClient.makeRequest({
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
    replacements: { patientId },
    body: JSON.stringify({ alerts, isReorder }),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const deleteAlert = async (patientId, alert) => {
  const res = await coreApiClient.makeRequest({
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts/:id',
    replacements: { id: alert.id, patientId },
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};
