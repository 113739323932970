import { calculateTreatmentPlanForPatient } from '../../../src/utils/treatment-plan-processor';
import { openSuccess, openError } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';
import {
  createTreatmentPlan,
  updateTreatmentPlan,
} from '../actions/treatmentPlanActions';

import { NebTreatmentPlanState } from './neb-treatment-plan-state';

const HEADING = 'Treatment Plan';

export const NebTreatmentPlanBaseController = superClass =>
  class extends NebTreatmentPlanState(superClass) {
    static get properties() {
      return {
        _small: {
          type: Boolean,
          reflect: true,
        },
        heading: {
          type: String,
        },
      };
    }

    constructor() {
      super();

      this.__initState();

      this.__initHandlers();
    }

    __initState() {
      this.heading = HEADING;
    }

    __initHandlers() {
      this._actions = {
        complete: treatmentPlan => this.__completeTreatmentPlan(treatmentPlan),
        save: treatmentPlan => this.__saveTreatmentPlan(treatmentPlan),
        calculate: treatmentPlan =>
          calculateTreatmentPlanForPatient(treatmentPlan, this.patientId),
      };
    }

    async _stateChanged(state) {
      const {
        layoutMedia: { layout } = {},
        appointments: { created } = {},
        treatmentPlansCompleted: { fetching, stale } = {},
      } = state;
      this._small = layout === 'small';

      if (
        created &&
        this._activeTreatmentPlan &&
        this._activeTreatmentPlan.id &&
        !fetching &&
        stale
      ) {
        this._activeTreatmentPlan = await this._actions.calculate(
          this._activeTreatmentPlan,
        );
      }

      super._stateChanged(state);
    }

    _shouldNotifyParent() {
      throw new Error('_shouldNotifyParent() not implemented', this);
    }

    async updated(changed) {
      if (changed.has('_activeTreatmentPlan')) {
        this.__updateCountInHeading();
      }

      await super.updated(changed);
    }

    _deletePhaseError(message) {
      store.dispatch(openError(message));
    }

    async __saveTreatmentPlan(treatmentPlan, successMessage) {
      if (treatmentPlan.id) {
        this.__handleNote(treatmentPlan);

        await store.dispatch(
          updateTreatmentPlan(this.patientId, treatmentPlan),
        );

        return this.__handleResponse('updated', 'updateError', successMessage);
      }
      await store.dispatch(createTreatmentPlan(this.patientId, treatmentPlan));

      return this.__handleResponse('created', 'createError');
    }

    async __completeTreatmentPlan(treatmentPlan) {
      treatmentPlan.status = 'Completed';

      await this.__saveTreatmentPlan(
        treatmentPlan,
        'Treatment Plan Completed.',
      );
    }

    __updateCountInHeading() {
      const phasesCount = this._activeTreatmentPlan
        ? this._activeTreatmentPlan.treatmentPhases.length
        : 0;

      this.heading = `${HEADING}${phasesCount ? ` (${phasesCount})` : ''}`;
    }

    __handleNote(treatmentPlan) {
      treatmentPlan.note = treatmentPlan.note || null;
    }

    __handleResponse(successProp, errorMessageProp, successMessage) {
      const { treatmentPlan } = store.getState();

      const errorMessage = treatmentPlan[errorMessageProp];

      if (treatmentPlan[successProp] && !errorMessage) {
        return this.__doAfterSaveSuccess(successMessage);
      }
      store.dispatch(openError(errorMessage));
      return undefined;
    }

    __doAfterSaveSuccess(successMessage = 'Treatment Plan Saved Successfully') {
      store.dispatch(openSuccess(successMessage));
      return 'Success';
    }
  };
