import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { css, html, LitElement } from 'lit';

import { LocationsService } from '../../../packages/neb-redux/services/locations';
import { ProviderService } from '../../../packages/neb-redux/services/provider';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { CSS_SPACING } from '../../styles';

export const ELEMENTS = {
  locationsDropdown: { id: 'locations-dropdown' },
  providersDropdown: { id: 'providers-dropdown' },
};

function getSelectedProvidersKey(tenantId) {
  return `scheduling-selected-providers:${tenantId}`;
}

function getSelectedLocationsKey(tenantId) {
  return `scheduling-selected-locations:${tenantId}`;
}

function getSelectedPropertyFromLocalStorage(key, prop) {
  const storedProp = JSON.parse(localStorage.getItem(key));

  if (storedProp) {
    return prop.filter(p => storedProp.find(v => v.data.id === p.data.id));
  }

  return null;
}

const MAX_PROVIDER_LOCATION_PRODUCT = 30;

class NebCalendarFiltering extends LitElement {
  static get properties() {
    return {
      __providers: Array,
      __locations: Array,
      __selectedProviders: Array,
      __selectedLocations: Array,
      tenantId: String,
    };
  }

  static get styles() {
    return css`
      .container {
        display: flex;
      }

      .dropdowns {
        margin-right: ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__providers = [];
    this.__locations = [];
    this.__selectedProviders = [];
    this.__selectedLocations = [];

    this.onSelectLocations = () => {};

    this.onSelectProviders = () => {};

    this.tenantId = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectLocations: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedLocations = e.value;

          localStorage.setItem(
            getSelectedLocationsKey(this.tenantId),
            JSON.stringify(e.value),
          );

          this.onSelectLocations(e.value);
        }
      },
      selectProviders: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedProviders = e.value;

          localStorage.setItem(
            getSelectedProvidersKey(this.tenantId),
            JSON.stringify(e.value),
          );

          this.onSelectProviders(e.value);
        }
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__locations = userLocations
        .filter(item => item.active)
        .map(data => ({
          label: data.name,
          data,
        }));
    });

    this.__providersService = new ProviderService(({ providers }) => {
      this.__providers = providers
        .filter(item => item.active)
        .map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
    });
  }

  connectedCallback() {
    this.__locationsService.connect();
    this.__providersService.connect();
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
    this.__providersService.disconnect();
  }

  __setSelectedProvidersLocations() {
    const selectedProviders = getSelectedPropertyFromLocalStorage(
      getSelectedProvidersKey(this.tenantId),
      this.__providers,
    );

    const selectedLocations = getSelectedPropertyFromLocalStorage(
      getSelectedLocationsKey(this.tenantId),
      this.__locations,
    );

    this.__selectedProviders = selectedProviders || this.__providers;
    this.__selectedLocations = selectedLocations || this.__locations;

    if (!selectedProviders && !selectedLocations) {
      const providerLocationProduct =
        this.__providers.length * this.__locations.length;

      if (providerLocationProduct > MAX_PROVIDER_LOCATION_PRODUCT) {
        this.__selectedProviders = [];
        this.__selectedLocations = [];
      }
    }

    this.onSelectProviders(this.__selectedProviders);
    this.onSelectLocations(this.__selectedLocations);
  }

  updated(changedProps) {
    if (
      (changedProps.has('__locations') || changedProps.has('__providers')) &&
      this.__providers &&
      this.__locations
    ) {
      this.__setSelectedProvidersLocations();
    }
  }

  render() {
    return html`
      <div class="container">
        <neb-select
          id="${ELEMENTS.locationsDropdown.id}"
          class="dropdowns"
          name="locations"
          allLabel="Locations"
          placeholder="No Locations Selected"
          .value="${this.__selectedLocations}"
          .items="${this.__locations}"
          .onChange="${this.__handlers.selectLocations}"
          multiSelect
          wrapText
        ></neb-select>

        <neb-select
          id="${ELEMENTS.providersDropdown.id}"
          class="dropdowns"
          name="providers"
          allLabel="Providers"
          placeholder="No Providers Selected"
          .value="${this.__selectedProviders}"
          .items="${this.__providers}"
          .onChange="${this.__handlers.selectProviders}"
          multiSelect
          wrapText
        ></neb-select>
      </div>
    `;
  }
}

customElements.define('neb-calendar-filtering', NebCalendarFiltering);
