import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const billingApiClient = new ApiClientV2({ microservice: 'billing' });

export const getBookingAccountInviteById = async id => {
  if (!id) return null;

  try {
    const {
      data: [invite],
    } = await billingApiClient.makeRequest({
      method: Method.GET,
      path: '/public/v1/tenants/:tenantId/booking/account-invite/:id',
      replacements: { id },
      optOutLoadingIndicator: false,
      version: 1,
    });

    return invite;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const postBookingAccountInvite = body =>
  billingApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/booking/account-invite',
    replacements: {},
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    optOutLoadingIndicator: false,
    version: 1,
  });

export const matchBookingAccounts = (bookingAccountInviteId, body) =>
  billingApiClient.makeRequest({
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/booking/account-invite/:bookingAccountInviteId',
    replacements: { bookingAccountInviteId },
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator: false,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
