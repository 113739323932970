import DeprecatedApiClient, {
  Method,
} from '../../../packages/neb-api-client/src/utils/api-client-utils';
import { parseDate } from '../../../packages/neb-utils/date-util';

export const apiClient = new DeprecatedApiClient({
  microservice: 'permissions',
});
export const header = { 'Content-Type': 'application/json' };

const BASE_PATH = 'practice-users';

// eslint-disable-next-line complexity
export function mapToModel(raw) {
  return {
    active: raw.active,
    clientId: raw.clientId || '',
    bio: raw.biography,
    dateOfBirth: raw.dateOfBirth ? parseDate(raw.dateOfBirth) : null,
    email: raw.email,
    id: raw.id || '',
    identifiers: raw.identifiers,
    lastPasswordChangeAt: raw.lastPasswordChangeAt
      ? parseDate(raw.lastPasswordChangeAt)
      : null,
    licenses: raw.licenses.map(item => ({
      number: item.license,
      state: item.state,
    })),
    name: {
      first: raw.givenName || '',
      last: raw.familyName || '',
      middle: raw.middleName || '',
      suffix: raw.suffix || '',
    },
    npi: raw.NPI,
    phones: raw.phoneNumbers || '',
    profileImageUrl: raw.profileImageUrl,
    status: raw.status,
    taxonomies: raw.taxonomies,
    type: raw.type,
    inactiveLogout: raw.inactiveLogout,
    skipSignMessage: raw.skipSignMessage,
    createEncounterAtCheckIn: raw.createEncounterAtCheckIn,
    richTextFontSize: raw.richTextFontSize,
    richTextFontFamily: raw.richTextFontFamily,
  };
}

export async function fetchMany(
  queryParams = {},
  optOutLoadingIndicator = false,
) {
  const cacheKey = `providers-${JSON.stringify(queryParams)}`;

  const res = await apiClient.makeRequest({
    cacheKey,
    header,
    method: Method.GET,
    optOutLoadingIndicator,
    path: BASE_PATH,
    queryArrayFormat: 'comma',
    queryParams,
    version: 'tenant2',
  });

  return res.map(mapToModel);
}

export async function fetchOne(
  userId,
  { optOutLoadingIndicator = false } = {},
) {
  const res = await apiClient.makeRequest({
    cacheKey: userId,
    header,
    method: Method.GET,
    optOutLoadingIndicator,
    path: `${BASE_PATH}/${userId}`,
    version: 'tenant2',
  });

  return mapToModel(res);
}
