import { LitElement, html, css } from 'lit';

import { CSS_COLOR_HIGHLIGHT } from '../../../neb-styles/neb-variables';

export const COLOR = 'color';
export const FADE = 'fade';
const EFFECTS = [COLOR, FADE];
export const EFFECT_CONTAINER_ID = 'effect-container';
class NebEffect extends LitElement {
  static get properties() {
    return {
      [COLOR]: {
        type: Boolean,
        reflect: true,
      },
      [FADE]: {
        type: Boolean,
        reflect: true,
      },
      force: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return css`
      .effect {
        transition: all 500ms;
      }

      :host([color]) .effect {
        background-color: ${CSS_COLOR_HIGHLIGHT}bf;
      }

      :host([color]) .effect-in {
        background-color: inherit;
      }

      :host([fade]) .effect {
        opacity: 0;
      }

      :host([fade]) .effect-in {
        opacity: 1;
      }
    `;
  }

  constructor() {
    super();
    this.force = false;

    this.__initHandlers();
  }

  firstUpdated() {
    if (this.__hasEffect) this.__addEffect();
  }

  __initHandlers() {
    this.__handlers = {
      onSlotchange: () => {
        if (this.force && this.__hasEffect) this.forceUpdate();
      },
    };
  }

  __addEffect(delay = 0) {
    setTimeout(() => this.__effectContainerClassList.add('effect-in'), delay);
  }

  __removeEffect() {
    this.__effectContainerClassList.remove('effect-in');
  }

  get __hasEffect() {
    return EFFECTS.some(effect => this[effect]);
  }

  get __effectContainerClassList() {
    return this.shadowRoot.getElementById(EFFECT_CONTAINER_ID).classList;
  }

  forceUpdate() {
    this.__removeEffect();

    this.__addEffect(300);
  }

  render() {
    return html`
      <div id="${EFFECT_CONTAINER_ID}" class="effect">
        <slot @slotchange="${this.__handlers.onSlotchange}"></slot>
      </div>
    `;
  }
}
customElements.define('neb-effect', NebEffect);
