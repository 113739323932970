import '../../forms/settings/neb-form-swap-clearinghouse';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  infoText: {
    id: 'info-text',
  },
  providerSelect: {
    id: 'provider-select',
  },
  locationSelect: {
    id: 'location-select',
  },
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  providerCheckbox: {
    id: 'provider-checkbox',
  },
  locationCheckbox: {
    id: 'location-checkbox',
  },
};
const TEXT_TITLE = 'Update Provider/Location';

class NebPopupUpdatePaymentProviderLocation extends NebPopup {
  static get properties() {
    return {
      __locations: Array,
      __providers: Array,
      __amountSelected: String,
      __selectedProvider: Object,
      __selectedLocation: Object,
      __includeProvider: Boolean,
      __includeLocation: Boolean,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;

    this.__locations = [];
    this.__providers = [];
    this.__amountSelected = '';
    this.__selectedProvider = {};
    this.__selectedLocation = {};
    this.__includeProvider = true;
    this.__includeLocation = true;
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      selectProvider: e => {
        this.__selectedProvider = e.value;
      },
      selectLocation: e => {
        this.__selectedLocation = e.value;
      },
      confirm: () => {
        this.onClose({
          provider: this.__selectedProvider.item,
          location: this.__selectedLocation.item,
        });
      },
      cancel: () => {
        this.onClose(false);
      },
      checkItem: e => {
        if (e.name === 'providerCheckbox') {
          this.__handleProviderCheckbox(e);
        } else {
          this.__handleLocationCheckbox(e);
        }
      },
    };
  }

  __handleProviderCheckbox(e) {
    if (e.value && !this.__includeProvider) {
      this.__selectedProvider = this.model.providers[0];
    }

    if (!e.value) {
      this.__selectedProvider = {};
    }
    this.__includeProvider = !this.__includeProvider;
  }

  __handleLocationCheckbox(e) {
    if (e.value && !this.__includeLocation) {
      this.__selectedLocation = this.model.locations[0];
    }

    if (!e.value) {
      this.__selectedLocation = {};
    }
    this.__includeLocation = !this.__includeLocation;
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__providers = this.model.providers;
      this.__locations = this.model.locations;
      this.__amountSelected = this.model.amountSelected;
      this.__selectedProvider = this.model.providers[0];
      this.__selectedLocation = this.model.locations[0];
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .info-text {
          margin-bottom: ${CSS_SPACING};
          font: Regular 14px/19px Open Sans;
        }

        .spacer {
          padding-top: ${CSS_SPACING};
        }

        .button-container {
          display: flex;
          justify-content: flex-start;
          margin-top: ${CSS_SPACING};
        }

        .field-container {
          display: flex;
        }

        .checkbox-column {
          display: flex;
          align-items: center;
          flex: 0 1;
        }

        .select-column {
          flex: 1 1;
          padding-left: ${CSS_SPACING};
        }

        .save-button {
          padding-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    const TEXT_INFO = `Please select a provider and/or location to associate to (${
      this.__amountSelected
    }) selected patient payments`;

    const disableSave = !this.__includeLocation && !this.__includeProvider;

    return html`
      <div id="${ELEMENTS.infoText.id}" class="info-text">${TEXT_INFO}</div>
      <div class="field-container">
        <div class="checkbox-column">
          <neb-checkbox
            class="checkbox"
            id="${ELEMENTS.providerCheckbox.id}"
            name="providerCheckbox"
            .checked="${this.__includeProvider}"
            .onChange="${this.__handlers.checkItem}"
          >
          </neb-checkbox>
        </div>

        <neb-select
          id="${ELEMENTS.providerSelect.id}"
          class="select-column"
          name="providerSelect"
          label="Select Provider"
          .value="${this.__selectedProvider}"
          .items="${this.__providers}"
          .onChange="${this.__handlers.selectProvider}"
          .disabled="${!this.__includeProvider}"
        ></neb-select>
      </div>
      <div class="spacer"></div>
      <div class="field-container">
        <div class="checkbox-column">
          <neb-checkbox
            class="checkbox"
            id="${ELEMENTS.locationCheckbox.id}"
            name="locationCheckbox"
            .checked="${this.__includeLocation}"
            .onChange="${this.__handlers.checkItem}"
          ></neb-checkbox>
        </div>

        <neb-select
          class="select-column"
          id="${ELEMENTS.locationSelect.id}"
          name="locationSelect"
          label="Select Location"
          .value="${this.__selectedLocation}"
          .items="${this.__locations}"
          .onChange="${this.__handlers.selectLocation}"
          .disabled="${!this.__includeLocation}"
        ></neb-select>
      </div>
      <div class="button-container">
        <neb-button
          id="${ELEMENTS.buttonConfirm.id}"
          class="save-button"
          label="Save"
          .disabled="${disableSave}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.__handlers.confirm}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          class="button"
          label="Cancel"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-update-payment-provider-location',
  NebPopupUpdatePaymentProviderLocation,
);
