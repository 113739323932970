import '../../../../src/components/misc/neb-icon';
import './neb-tooltip';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLUE_BORDER,
  CSS_SPACING,
  CSS_COLOR_DISABLED,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  card: {
    id: 'card',
  },
  icon: {
    id: 'icon',
  },
  title: {
    id: 'title',
  },
  tooltip: {
    id: 'tooltip',
  },
};

class NebCardBigIcon extends LitElement {
  static get properties() {
    return {
      selected: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      title: {
        type: String,
      },
      tooltip: {
        type: String,
      },
      icon: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.selected = false;
    this.disabled = false;
    this.title = '';
    this.tooltip = '';
    this.icon = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickCard: e => this.__performClick(e),
      keyup: e => {
        if (e.key === 'Enter') {
          this.__performClick(e);
        }
      },
    };
  }

  __performClick(e) {
    e.stopPropagation();

    if (!this.disabled) {
      this.onClick();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keyup', this.__handlers.keyup);
    this.addEventListener('click', this.__handlers.clickCard);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          height: 170px;
          width: 100%;
          box-shadow: 0 1px 3px #00000033;
          border-radius: 4px;
          padding: ${CSS_SPACING} 0;
          border: 3px solid transparent;
          cursor: pointer;
        }

        :host([selected]) {
          cursor: default;
          border: 3px solid ${CSS_COLOR_BLUE_BORDER};
        }

        :host([disabled]) {
          cursor: default;
        }

        :host(:not([selected]):hover),
        :host(:not([selected]):focus) {
          background-color: #f5f5f5;
        }

        :host([disabled]:hover),
        :host([disabled]:focus) {
          background-color: ${CSS_COLOR_WHITE};
        }

        .card-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .content-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        .title {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          color: ${CSS_COLOR_HIGHLIGHT};
          padding-bottom: 15px;
        }

        :host([disabled]) .title {
          color: ${CSS_COLOR_DISABLED};
        }

        .icon {
          margin-top: 10px;
          width: 48px;
          height: 48px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .icon {
          fill: ${CSS_COLOR_DISABLED};
        }
      `,
    ];
  }

  __renderIcon() {
    return this.icon.length
      ? html`
          <div class="content-container">
            <neb-icon
              id="${ELEMENTS.icon.id}"
              class="icon"
              icon="neb:${this.icon}"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderTooltip() {
    return this.tooltip.length
      ? html`
          <div class="content-container">
            <neb-tooltip
              id="${ELEMENTS.tooltip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div slot="tooltip">${this.tooltip}</div>
            </neb-tooltip>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.card.id}" class="card-container">
        ${this.__renderIcon()}

        <div class="content-container">
          <div id="${ELEMENTS.title.id}" class="title">${this.title}</div>
        </div>

        ${this.__renderTooltip()}
      </div>
    `;
  }
}

customElements.define('neb-card-big-icon', NebCardBigIcon);
