import '@polymer/polymer/polymer-legacy';

import { html } from 'lit';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebCardStyles = () => html`
  <style>
    .neb-card {
      @apply --neb-card-base;
    }
    /* TODO add card structures like container, link and etc. */
  </style>
`;
_documentContainer.innerHTML = `
<custom-style>
  <style is="custom-style">
    html {
      --neb-card-color: var(--neb-color-dark-primary);
      --neb-card-width: var(--neb-secondary-nav-search-width);
      --neb-card-height: 79px;

      --neb-card-no-select: {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;                
      }

      --neb-card-base: {
        background-color: var(--neb-color-light-primary);
        color: var(--neb-font-color-primary);
        width: var(--neb-card-width);

        @apply --neb-card-no-select;
      }

    }
  </style>
</custom-style>

<dom-module id="neb-card-styles">
  <template>
    ${nebCardStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
