import '../../../packages/neb-lit-components/src/components/neb-list';

import { LitElement, html, css } from 'lit';
import { v4 as uuid } from 'uuid';

import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../packages/neb-api-client/src/patient-image-api-client';
import { fetchManyRooms } from '../../../packages/neb-api-client/src/rooms-api-client';
import { computeTime } from '../../../packages/neb-input/nebFormatUtils';
import {
  openAppointmentPage,
  openRescheduledAppointmentPage,
} from '../../../packages/neb-lit-components/src/utils/appointment-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { fetchAndOpenMatchPopup } from '../../../packages/neb-lit-components/src/utils/patients';
import { connect, store } from '../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { SERVICE_TIME_FORMAT } from '../../../packages/neb-utils/neb-charting-util';
import * as insuranceStatusApi from '../../api-clients/insurance-status';
import { ADD_ONS, hasAddOn } from '../../utils/add-ons';

import { DATE_FORMAT } from './neb-scheduling-calendar';

export const ELEMENTS = {
  list: { id: 'list' },
};

function mapAppointment(appointment) {
  return {
    id: appointment.id || '',
    patientId: appointment.patientId || '',
  };
}

const uniq = arr => [...new Set(arr)];

export const AGENDA_TYPE_HEADER = 'header';
export const AGENDA_TYPE_EVENT = 'event';
export const AGENDA_TYPE_EMPTY = 'empty';

class NebCalendarAgendaView extends connect(store)(LitElement) {
  static get properties() {
    return {
      __mappedEvents: Array,
      __appointmentTypes: Array,
      __itemIndex: Number,
      __providers: Array,
      events: Array,
      location: Object,
      provider: Object,
      targetDate: String,
    };
  }

  static get styles() {
    return css`
      :host {
        flex-basis: 0.000000001px;
        overflow: hidden;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__appointmentTypes = [];
    this.__mappedEvents = [];
    this.__itemIndex = -1;
    this.__hasAddOnCTVerify = false;
    this.__skipScrollToTargetDate = false;
    this.__providers = [];

    this.onUpdateTargetDate = () => {};

    this.onUpdateCalendar = () => {};

    this.events = [];
    this.location = {};
    this.provider = {};
    this.targetDate = '';
  }

  __initHandlers() {
    this.__handlers = {
      // eslint-disable-next-line complexity
      clickItem: async e => {
        if (!e.eventItem || !e.eventItem.event) return;

        const { event } = e.eventItem;

        if (event.groupId) {
          await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_PAGE, {
            appointmentId: event.id,
          });
        } else {
          let popup = {};

          if (event.accountId && !event.patient.id) {
            popup = await fetchAndOpenMatchPopup(event.accountId, event.id);
          }

          if (!popup.back) {
            if (event.isRescheduled) {
              await openRescheduledAppointmentPage(
                event.id,
                event.appointmentId,
              );
            } else {
              await openAppointmentPage(event.id);
            }
          }
        }

        this.onUpdateCalendar();
      },
      displayCard: model => {
        const { eventId, patient } = model;
        const selectedPatient = this.__patients[patient.id];

        if (selectedPatient) {
          const { hasPhoto, photoSrc } = selectedPatient;

          if (hasPhoto && (!photoSrc || patient.photoSrc !== photoSrc)) {
            this.__getProfilePicture(eventId, patient.id);
          }
        }
      },
      thresholdReached: () => {
        this.onUpdateCalendar();
      },
      topVisibleIndexChanged: index => {
        if (this.__mappedEvents.length && this.__mappedEvents[index]) {
          this.__currentItem = this.__mappedEvents[index];

          if (
            this.__currentItem &&
            this.__currentItem.day.valueOf() !==
              this.__stripOutTime(this.__targetDate).valueOf()
          ) {
            this.__skipScrollToTargetDate = true;

            this.onUpdateTargetDate(this.__currentItem.day.format(DATE_FORMAT));
          }
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  _stateChanged({
    appointmentTypes: { items: appointmentTypes },
    providers: { item: providers },
  }) {
    this.__providers = providers;
    this.__appointmentTypes = appointmentTypes;
  }

  updated(changedProps) {
    if (this.__appointmentTypes.length) {
      if (changedProps.has('targetDate') && !this.__skipScrollToTargetDate) {
        this.__itemIndex = this.__findIndexOfHeader();
        if (this.__itemIndex === -1) this.onUpdateCalendar();
      }

      if (changedProps.has('events')) {
        this.__mapEvents(this.events);
      }

      this.__skipScrollToTargetDate = false;
    }
  }

  __getPatientImage(patient) {
    return patient.hasPhoto && !patient.photoSrc
      ? getPatientImage(patient.id, 'small', true)
      : patient.photoSrc;
  }

  async __getProfilePicture(eventId, patientId) {
    const patient = await patientApiClient.fetchOne(patientId);
    patient.photoSrc = await this.__getPatientImage(patient);

    this.__patients[patientId] = patient;
    this.__patients = { ...this.__patients };

    const eventIndex = this.__mappedEvents.findIndex(e => {
      if (e.event) return e.event.eventId === eventId;

      return false;
    });

    if (eventIndex > -1) {
      this.__mappedEvents[eventIndex] = {
        ...this.__mappedEvents[eventIndex],
        event: {
          ...this.__mappedEvents[eventIndex].event,
          patient,
        },
      };
    }

    this.__mappedEvents = [...this.__mappedEvents];
  }

  __stripOutTime(date) {
    return parseDate(date).startOf('day');
  }

  __findIndexOfHeader() {
    if (this.__mappedEvents.length) {
      this.__stripOutTime(this.targetDate);

      return this.__mappedEvents.findIndex(
        eventItem =>
          eventItem.type === AGENDA_TYPE_HEADER &&
          this.__stripOutTime(eventItem.day).valueOf() ===
            this.__stripOutTime(this.targetDate).valueOf(),
      );
    }

    return -1;
  }

  __findIndexOfCurrentItem() {
    if (!this.__currentItem) {
      return -1;
    }
    const date =
      this.__currentItem.type === AGENDA_TYPE_EVENT
        ? parseDate(this.__currentItem.event.start)
        : this.__currentItem.day;

    if (this.__mappedEvents.length) {
      return this.__mappedEvents.findIndex(eventItem => {
        const eventTime =
          eventItem.type === AGENDA_TYPE_EVENT
            ? parseDate(eventItem.event.start)
            : eventItem.day;

        return eventTime.valueOf() === date.valueOf();
      });
    }

    return -1;
  }

  __buildPatientsMap(patients) {
    return patients.reduce((acc, patient) => {
      acc[patient.id] = patient;

      if (patient.bookingAccountId) {
        acc[patient.bookingAccountId] = acc[patient.id];
      }

      return acc;
    }, {});
  }

  __getAppointmentType({ appointmentTypeId }) {
    return (
      this.__appointmentTypes.find(
        appointmentType => appointmentType.id === appointmentTypeId,
      ) || {
        name: '',
        color: '',
      }
    );
  }

  __formatOnlineBookingPatient({ firstName: first, lastName: last }) {
    return { name: { first, last } };
  }

  __getProviderName(providerId) {
    const provider = this.__providers.find(p => p.id === providerId);

    return provider
      ? `${provider.name.last}, ${provider.name.first}`
      : 'No Provider Scheduled';
  }

  async __mapEvents(events) {
    let insuranceStatuses = [];
    let prevStart;
    let realTimeEligibilityStatus = null;

    const mappedEvents = [];

    insuranceStatuses = await insuranceStatusApi.fetchMany(
      events.map(a => mapAppointment(a)),
      true,
    );

    const patientIds = uniq(
      events.map(x => x.patientId || x.accountId).filter(x => x),
    );

    this.__patients = this.__buildPatientsMap(
      await patientApiClient.fetchSome(
        patientIds,
        { includeBooking: true, includeImage: true },
        false,
        true,
      ),
    );

    const rooms = await fetchManyRooms();

    // eslint-disable-next-line complexity
    events.forEach(appointment => {
      const appointmentStart = parseDate(appointment.start);
      const appointmentEnd = parseDate(appointment.end);

      const day = parseDate([
        appointmentStart.year(),
        appointmentStart.month(),
        appointmentStart.date(),
      ]);

      if (prevStart !== day.format()) {
        const prevEvent = mappedEvents[mappedEvents.length - 1];

        if (mappedEvents.length && prevEvent.type === AGENDA_TYPE_HEADER) {
          mappedEvents.push({ day, type: AGENDA_TYPE_EMPTY });
        }

        mappedEvents.push({ day, type: AGENDA_TYPE_HEADER });
        prevStart = day.format();
      }

      const patient =
        this.__patients[appointment.patientId] ||
        this.__patients[appointment.accountId];

      if (this.__hasAddOnCTVerify && insuranceStatuses.length) {
        ({ status: realTimeEligibilityStatus } = insuranceStatuses.find(
          s => s.appointmentId === appointment.id,
        ));
      }

      const resource = rooms.find(r => r.id === appointment.resourceId);
      const room = rooms.find(r => r.id === appointment.roomId);
      const appointmentType = this.__getAppointmentType(appointment);
      const duration = computeTime(
        appointmentEnd.valueOf() - appointmentStart.valueOf(),
      );

      const mappedEvent = {
        ...appointment,
        ...(appointment.encounter || { signed: false }),
        appointmentType: appointmentType.name,
        duration,
        eventId: uuid(),
        formattedServiceTime: appointmentStart.format(SERVICE_TIME_FORMAT),
        markerColor: appointmentType.color,
        patient: patient || this.__formatOnlineBookingPatient(appointment),
        provider: this.__getProviderName(appointment.providerId),
        realTimeEligibilityStatus,
        locationName: this.location ? this.location.name : '',
        resource: resource || null,
        room: room || null,
      };

      mappedEvents.push({ day, event: mappedEvent, type: AGENDA_TYPE_EVENT });
    });

    this.__mappedEvents = [...mappedEvents];

    const index =
      this.__itemIndex > -1
        ? this.__findIndexOfCurrentItem()
        : this.__findIndexOfHeader();

    if (index >= 0) {
      this.__itemIndex = index;
      this.requestUpdate();
      await this.updateComplete;
    }
  }

  render() {
    return html`
      <neb-list
        id="${ELEMENTS.list.id}"
        .items="${this.__mappedEvents}"
        .scrollToIndex="${this.__itemIndex}"
        .onDisplayCard="${this.__handlers.displayCard}"
        .onLowerThreshold="${this.__handlers.thresholdReached}"
        .onTopVisibleIndexChange="${this.__handlers.topVisibleIndexChanged}"
        .onUpperThreshold="${this.__handlers.thresholdReached}"
        .onClickItem="${this.__handlers.clickItem}"
      >
      </neb-list>
    `;
  }
}

customElements.define('neb-calendar-agenda-view', NebCalendarAgendaView);
