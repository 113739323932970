/* eslint-disable complexity */
import { LINE_ITEM_TYPE } from '../../packages/neb-utils/neb-ledger-util';

export const HOLD_STATUS = Object.freeze({
  CLAIM: 'claim',
  STATEMENT: 'statement',
  SUPERBILL: 'superbill',
});

export const BILLED_STATUS = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WAITING: 'waiting',
  RECEIVED: 'received',
  PAID: 'paid',
});

export const BILLED_STATUS_LABELS = Object.freeze({
  billedWaiting: 'W - Waiting to Bill to Ins.',
  billedPrimary: 'BP - Billed to Primary',
  billedSecondary: 'BS - Billed to Secondary',
  billedPaymentReceived: 'PR - Ins. Pmt. Received',
  billedPaidInFull: 'PD - Ins. Paid in Full',
});

export const BILLED_STATUS_WITHOUT_FF = Object.freeze({
  PATIENT: 'patient',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
});

export const EPSDT = Object.freeze({
  NO_REASON: 'No Reason',
  AV: 'AV',
  S2: 'S2',
  ST: 'ST',
  NU: 'NU',
});

export const LINE_ITEM_HOLD_STATUSES = Object.freeze([
  {
    label: 'C - Claim',
    value: HOLD_STATUS.CLAIM,
  },
  {
    label: 'ST - Patient Statement',
    value: HOLD_STATUS.STATEMENT,
  },
  {
    label: 'SB - Superbill',
    value: HOLD_STATUS.SUPERBILL,
  },
]);

export const LINE_ITEM_BILLED_STATUSES = Object.freeze([
  {
    label: BILLED_STATUS_LABELS.billedWaiting,
    value: BILLED_STATUS.WAITING,
  },
  {
    label: BILLED_STATUS_LABELS.billedPrimary,
    value: BILLED_STATUS.PRIMARY,
  },
  {
    label: BILLED_STATUS_LABELS.billedSecondary,
    value: BILLED_STATUS.SECONDARY,
  },
  {
    label: BILLED_STATUS_LABELS.billedPaymentReceived,
    value: BILLED_STATUS.RECEIVED,
  },
  {
    label: BILLED_STATUS_LABELS.billedPaidInFull,
    value: BILLED_STATUS.PAID,
  },
]);

export const LINE_ITEM_BILLED_STATUSES_WITHOUT_FF = Object.freeze([
  {
    label: 'PT - Patient',
    value: BILLED_STATUS_WITHOUT_FF.PATIENT,
  },
  {
    label: 'P - Primary Insurance',
    value: BILLED_STATUS_WITHOUT_FF.PRIMARY,
  },
  {
    label: 'S - Secondary Insurance',
    value: BILLED_STATUS_WITHOUT_FF.SECONDARY,
  },
]);

export const LINE_ITEM_EPSDT = Object.freeze([
  {
    label: EPSDT.NO_REASON,
    data: {
      description: 'No requirement to assign EPSDT reason',
    },
  },
  {
    label: EPSDT.AV,
    data: {
      description: 'Available - Not Used (Patient refused referral.)',
    },
  },
  {
    label: EPSDT.S2,
    data: {
      description:
        'Under Treatment (Patient is currently under treatment for referred diagnostic or corrective health problem.)',
    },
  },
  {
    label: EPSDT.ST,
    data: {
      description:
        'New Service Requested (Referral to another provider for diagnostic or corrective treatment/scheduled for another appointment with screening provider for diagnostic or corrective treatment for at least one health problem identified during an initial or periodic screening service, not including dental referrals.)',
    },
  },
  {
    label: EPSDT.NU,
    data: {
      description: 'Not Used (Used when no EPSDT patient referral was given.)',
    },
  },
]);

export const EMPTY_NDC_ITEM = Object.freeze({ label: '', data: { id: null } });

const formatType = ({ type }) => {
  switch (type) {
    case LINE_ITEM_TYPE.ENCOUNTER_CHARGE:
      return 'Encounter Charge';
    case LINE_ITEM_TYPE.FEE:
      return 'Fee';
    case LINE_ITEM_TYPE.PURCHASE:
      return 'Purchase';
    default:
      return null;
  }
};

const formatBilledStatus = ({
  billedWaiting,
  billedPrimary,
  billedSecondary,
  billedPaymentReceived,
  billedPaidInFull,
}) => {
  const billedStatus = [];

  if (billedPrimary) {
    billedStatus.push(BILLED_STATUS.PRIMARY);
  }

  if (billedSecondary) {
    billedStatus.push(BILLED_STATUS.SECONDARY);
  }

  if (billedWaiting) {
    billedStatus.push(BILLED_STATUS.WAITING);
  }

  if (billedPaymentReceived) {
    billedStatus.push(BILLED_STATUS.RECEIVED);
  }

  if (billedPaidInFull) {
    billedStatus.push(BILLED_STATUS.PAID);
  }

  return billedStatus;
};

const formatHoldStatus = ({ holdClaim, holdStatement, holdSuperBill }) => {
  const holdStatus = [];

  if (holdClaim) {
    holdStatus.push(HOLD_STATUS.CLAIM);
  }

  if (holdStatement) {
    holdStatus.push(HOLD_STATUS.STATEMENT);
  }

  if (holdSuperBill) {
    holdStatus.push(HOLD_STATUS.SUPERBILL);
  }

  return holdStatus;
};

const formatClaims = ({ claims }) => {
  if (!claims.length) {
    return [];
  }

  const claimsInfo = claims.map(claim => ({
    label: claim.claimNumber.toString(),
    id: claim.id,
  }));

  return claimsInfo;
};

const formatLocation = ({ encounterCharge, locations }) => {
  if (!locations.length) {
    return null;
  }
  const currentLocation = locations.find(
    location => location.id === encounterCharge.locationId,
  );

  return currentLocation.name;
};

export const formatEncounterChargeDetails = lineItem => ({
  chargeType: formatType(lineItem),
  holdStatus: formatHoldStatus(lineItem),
  billedStatus: formatBilledStatus(lineItem),
  invoice: lineItem.invoiceNumber ? lineItem.invoiceNumber.toString() : null,
  claims: formatClaims(lineItem),
  encounter: lineItem.encounterCharge.encounterNumber
    ? lineItem.encounterCharge.encounterNumber.toString()
    : null,
  location: formatLocation(lineItem),
  shaded24: lineItem.shaded24,
  EPSDTCode: lineItem.EPSDTCode,
  EPSDTType: lineItem.EPSDTType,
  ndc: lineItem.ndc,
  nationalDrugCode: lineItem.nationalDrugCode,
  nationalDrugCodeDosage: lineItem.nationalDrugCodeDosage,
  nationalDrugCodeNumberCategory:
    lineItem.nationalDrugCodeNumberCategory || EMPTY_NDC_ITEM,
  nationalDrugCodeQualifier:
    lineItem.nationalDrugCodeQualifier || EMPTY_NDC_ITEM,
  nationalDrugCodeSequenceOrPrescription:
    lineItem.nationalDrugCodeSequenceOrPrescription,
  nationalDrugCodeUnitOfMeasurement:
    lineItem.nationalDrugCodeUnitOfMeasurement || EMPTY_NDC_ITEM,
  supplementalInformation: lineItem.supplementalInformation,
  reportTypeCode: lineItem.reportTypeCode,
  reportTransmissionCode: lineItem.reportTransmissionCode,
  codeQualifier: lineItem.codeQualifier,
  identificationNumber: lineItem.identificationNumber,
  orderingProvider: lineItem.orderingProvider,
  orderingProviderCredentials: lineItem.orderingProviderCredentials,
  orderingProviderFirstName: lineItem.orderingProviderFirstName,
  orderingProviderLastName: lineItem.orderingProviderLastName,
  orderingProviderMiddleName: lineItem.orderingProviderMiddleName,
  orderingProviderNPI: lineItem.orderingProviderNPI,
  orderingProviderOtherId: lineItem.orderingProviderOtherId,
  orderingProviderQualifier: lineItem.orderingProviderQualifier,
  billedPatient: lineItem.billedPatient,
  statementId: lineItem.statementId,
  statementNumber: lineItem.statementNumber,
});
