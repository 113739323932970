import { css, html } from 'lit';

import '../../controls/inputs/neb-checkbox';
import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { MODE } from '../../../../packages/neb-utils/table';

const TABLE_CONFIG = [
  {
    flex: css`1 0 0`,
    key: 'checkbox',
  },
  {
    flex: css`2 0 0`,
    key: 'name',
    label: 'Category Name',
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`0.5 0 0`,
    formatter: value => (value ? 'Active' : 'Inactive'),
  },
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkboxAll: { id: 'checkbox-all' },
  checkbox: { selector: '[id^=checkbox-]' },
};

class NebTableAddCategories extends Table {
  initState() {
    super.initState();

    this.mode = MODE.NONE;
    this.showDetail = true;
    this.isAllSelected = true;
    this.config = TABLE_CONFIG;

    this.onCheck = () => {};

    this.onCheckAll = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      check: rowIndex => this.onCheck(rowIndex),
      checkAll: () => this.onCheckAll(),
    };
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'checkbox':
        return this.__renderCheckbox(null, null, true);

      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  __renderCheckbox(_value, rowIndex, selectAll = false) {
    return selectAll
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxAll.id}"
            .name="${rowIndex}"
            ?checked="${this.isAllSelected}"
            .onChange="${this.handlers.checkAll}"
          ></neb-checkbox>
        `
      : html`
          <neb-checkbox
            id="checkbox-${rowIndex}"
            .name="${rowIndex}"
            ?checked="${this.model[rowIndex].checked}"
            .onChange="${() => this.handlers.check(rowIndex)}"
          ></neb-checkbox>
        `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checkbox':
        return this.__renderCheckbox(value, rowIndex);

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

customElements.define('neb-table-add-categories', NebTableAddCategories);
