import '../../../../packages/neb-lit-components/src/components/inputs/neb-floating-label';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { CSS_FIELD_MARGIN } from '../../../../packages/neb-styles/neb-variables';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../packages/neb-utils/formatters';
import * as masks from '../../../../packages/neb-utils/masks';

export const ELEMENTS = {
  label: { id: 'label' },
  minTextfield: { id: 'textfield-min' },
  maxTextfield: { id: 'textfield-max' },
};

export class NebCurrencyRange extends LitElement {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },

      name: String,
      label: String,
      model: Object,
      errors: Object,
      helpers: Object,
      allowNegative: Object,
      minGap: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
        }

        .container {
          display: grid;
          position: relative;
          padding-top: ${CSS_FIELD_MARGIN};
          grid-gap: 0 12px;
          grid-template-columns: 1fr 1fr;
        }

        :host([minGap]) .container {
          grid-gap: 0 5px;
        }

        .field {
          width: 100%;
        }
      `,
    ];
  }

  static createModel() {
    return {
      min: null,
      max: null,
    };
  }

  static createSelectors(minValidators = [], maxValidators = []) {
    const builder = validators => ({
      format: v => (v !== null ? centsToCurrency(v) : ''),
      unformat: v => (v !== '' ? currencyToCents(v) : null),
      validators,
    });

    return {
      children: {
        min: builder(minValidators),
        max: builder(maxValidators),
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__focused = false;

    this.label = '';
    this.model = this.constructor.createModel();
    this.errors = { min: '', max: '' };
    this.helpers = { min: '', max: '' };
    this.allowNegative = false;
    this.minGap = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      focus: () => {
        this.__focused = true;
      },
      blur: () => {
        this.__focused = false;
      },
      clearMin: () => {
        const name = [this.name, 'min'].filter(item => item !== '').join('.');

        this.onChange({ name, value: '' });
      },
      clearMax: () => {
        const name = [this.name, 'max'].filter(item => item !== '').join('.');

        this.onChange({ name, value: '' });
      },
      change: e => {
        const name = [this.name, e.name].filter(item => item !== '').join('.');

        this.onChange({ ...e, name });
      },
    };
  }

  getTrailingIcon(key) {
    return this.model[key] ? 'neb:clear' : '';
  }

  getHelper(key) {
    return this.helpers[key] || ' ';
  }

  getValue(key) {
    return this.model[key] || '';
  }

  __getMask() {
    return this.allowNegative ? masks.currencyNegative : masks.currency;
  }

  render() {
    return html`
      <div class="container">
        <neb-textfield
          id="${ELEMENTS.minTextfield.id}"
          class="field"
          name="min"
          placeholder="Min"
          .mask="${this.__getMask()}"
          .trailingIcon="${this.getTrailingIcon('min')}"
          .helper="${this.getHelper('min')}"
          .value="${this.getValue('min')}"
          .error="${this.errors.min}"
          .onBlur="${this.__handlers.blur}"
          .onFocus="${this.__handlers.focus}"
          .onChange="${this.__handlers.change}"
          .onClickIcon="${this.__handlers.clearMin}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.maxTextfield.id}"
          class="field"
          name="max"
          placeholder="Max"
          .mask="${this.__getMask()}"
          .trailingIcon="${this.getTrailingIcon('max')}"
          .helper="${this.getHelper('max')}"
          .value="${this.getValue('max')}"
          .error="${this.errors.max}"
          .onBlur="${this.__handlers.blur}"
          .onFocus="${this.__handlers.focus}"
          .onChange="${this.__handlers.change}"
          .onClickIcon="${this.__handlers.clearMax}"
        ></neb-textfield>

        <neb-floating-label
          id="${ELEMENTS.label.id}"
          .text="${this.label}"
          ?focused="${this.__focused}"
          pinned
        ></neb-floating-label>
      </div>
    `;
  }
}

window.customElements.define('neb-range-currency', NebCurrencyRange);
