import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';

import { TAB_ROLE } from './neb-tab';

export const ELEMENTS = {
  tabs: { selector: 'neb-tab' },
};

class NebTabGroup extends LitElement {
  static get properties() {
    return {
      selectedId: String,
      items: Array,
      role: {
        reflect: true,
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          white-space: nowrap;
          overflow: auto hidden;
        }

        :host([role='highlight']) {
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .container {
          width: max-content;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: row nowrap;
        }

        :host([role='highlight']) .container {
          padding: 0 ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        :host([role='highlight']) .tab:not(:last-child) {
          margin-right: 40px;
        }

        :host([role='section']) .tab:not(:last-child) {
          margin-right: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedId = '';
    this.role = TAB_ROLE.HIGHLIGHT;
    this.items = [];

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      select: id => this.onSelect(id),
    };
  }

  render() {
    return html`
      <div class="container">
        ${this.items.map(
          item => html`
            <neb-tab
              id="${item.id}"
              class="tab"
              .role="${this.role}"
              .label="${item.label}"
              .onClick="${this.__handlers.select}"
              ?selected="${item.id === this.selectedId}"
              ?disabled="${item.disabled}"
            ></neb-tab>
          `,
        )}
      </div>
    `;
  }
}

window.customElements.define('neb-tab-group', NebTabGroup);
