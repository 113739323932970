import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/field-groups/neb-two-date-item';

import { html, css } from 'lit';

import { DATE_SELECT_RULES_UNABLE_TO_WORK } from '../../../src/components/forms/patients/cases/neb-form-patient-case';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  datePickers: { id: 'date-pickers' },
};

const LABELS = {
  date1: 'From',
  date2: 'To',
  helperText1: ' ',
  helperText2: ' ',
};

class NebPopupClaimDateRange extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .spacing {
          margin-bottom: -5px;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__state = { ...this.__state, [name.split('.')[1]]: value };
        this.__validate();
      },
    };
  }

  __validate() {
    const invalid = this.__state.date2 && !this.__state.date1;
    this.__errors = invalid ? { date1: 'Required' } : {};
    return !invalid;
  }

  renderContent() {
    return html`
      <neb-two-date-item
        id="${ELEMENTS.datePickers.id}"
        class="spacing"
        .showCheckBox="${false}"
        .model="${{ ...this.__state, enabled: true }}"
        .labels="${LABELS}"
        .dateSelectionRules="${DATE_SELECT_RULES_UNABLE_TO_WORK}"
        .errors="${this.__errors}"
        .onChange="${this.handlers.change}"
      ></neb-two-date-item>
    `;
  }
}

customElements.define('neb-popup-claim-date-range', NebPopupClaimDateRange);
