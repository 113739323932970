import { openPopup } from '@neb/popup';

import { openSuccess, openError } from '../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { formatGuarantors } from '../formatters/guarantor';
import {
  getPatientGuarantors,
  createPatientGuarantor,
  updatePatientGuarantor,
} from '../patient-guarantor-api-client';

export const SAVE_GUARANTOR_SUCCESS = 'Patient Guarantor saved';
export const SAVE_GUARANTOR_ERROR =
  'An error occurred when saving the Patient Guarantor';
export const DUPLICATE_PATIENT_GUARANTOR = {
  title: 'Duplicate Guarantor',
  message:
    'This person or organization is an existing guarantor for this patient.',
  confirmText: 'Ok',
};
export class GuarantorService {
  constructor(callback) {
    this.callback = callback;
    this.guarantors = [];
  }

  async update(id, queryParams = {}) {
    await this._getGuarantors(id, queryParams);
    this.callback(this.guarantors);
  }

  async _getGuarantors(id, queryParams) {
    const guarantors = await getPatientGuarantors(id, queryParams, true);
    this.guarantors = formatGuarantors(guarantors);
  }

  async __handleError(err) {
    if (err.statusCode === 409) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, DUPLICATE_PATIENT_GUARANTOR);
    } else store.dispatch(openError(SAVE_GUARANTOR_ERROR));

    this.callback();
  }

  async save(guarantorBody) {
    try {
      const response = guarantorBody.id
        ? await updatePatientGuarantor(guarantorBody)
        : await createPatientGuarantor(guarantorBody);
      this.callback(response);
      store.dispatch(openSuccess(SAVE_GUARANTOR_SUCCESS));
    } catch (err) {
      await this.__handleError(err);
    }
  }
}
