import { CSS_FONT_SIZE_BODY } from '../../neb-styles/neb-variables';

export default class TextService {
  static setDefaults(config) {
    TextService.defaultConfig = config;
  }

  constructor(styles) {
    this.__mounted = false;
    this.__el = document.createElement('span');

    this.__configure({
      ...TextService.defaultConfig,
      ...styles,
    });
  }

  connect() {
    document.body.appendChild(this.__el);
    this.__mounted = true;
  }

  disconnect() {
    document.body.removeChild(this.__el);
    this.__mounted = false;
  }

  isMounted() {
    return this.__mounted;
  }

  __configure(styles) {
    Object.entries(styles).forEach(([k, v]) => {
      this.__el.style[k] = v;
    });
  }

  __findSize(string) {
    const el = this.__el;
    el.innerHTML = string;

    return {
      width: el.clientWidth,
      height: el.clientHeight,
    };
  }

  __fitWithDimensions(string, dimensions) {
    if (!this.__mounted) {
      throw new Error(
        'Not mounted. Invoke connect() on your TextService instance first',
      );
    }

    let minPossibleFontSize = 1;
    let maxPossibleFontSize = dimensions.width;

    while (maxPossibleFontSize - minPossibleFontSize > 1) {
      const current = Math.ceil(
        (minPossibleFontSize + maxPossibleFontSize) / 2,
      );
      this.__configure({ fontSize: `${current}px` });
      const size = this.__findSize(string);

      if (size.width < dimensions.width && size.height < dimensions.height) {
        minPossibleFontSize = current;
      } else {
        maxPossibleFontSize = current;
      }
    }

    return minPossibleFontSize;
  }

  fitWithElement(string, el) {
    return this.__fitWithDimensions(string, {
      width: el.clientWidth,
      height: el.clientHeight,
    });
  }
}

TextService.defaultConfig = {
  position: 'absolute',
  top: '-100px',
  left: '0',
  height: 'auto',
  width: 'auto',
  opacity: '0',
  fontSize: CSS_FONT_SIZE_BODY,
  fontWeight: 700,
  whiteSpace: 'no-wrap',
};
