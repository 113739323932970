import { css, html } from 'lit';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { required } from '../../../../packages/neb-utils/validators';

const ELEMENTS = {
  ...BASE_ELEMENTS,
  textFieldCopyName: { id: 'textfield-copyName' },
  textFieldCopyTenantId: { id: 'textfield-copyTenantId' },
};

class NebFormCopyConversion extends NebForm {
  static createModel() {
    return {
      copyName: '',
      copyTenantId: '',
    };
  }

  createSelectors() {
    return {
      children: {
        copyTenantId: { validators: [required()] },
        copyName: { validators: [required()] },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textFieldCopyTenantId.id}"
        name="copyTenantId"
        label="Tenant ID"
        helper="Required"
        .value="${this.state.copyTenantId}"
        .error="${this.errors.copyTenantId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
      <neb-textfield
        id="${ELEMENTS.textFieldCopyName.id}"
        name="copyName"
        label="Name"
        helper="Required"
        .value="${this.state.copyName}"
        .error="${this.errors.copyName}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }
}

customElements.define('neb-form-copy-conversion', NebFormCopyConversion);
