import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import NebOverlayFormItem, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay-form-item';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import * as api from '../../../api-clients/tags';
import {
  DOCUMENT_TAGS_BANNER_SUCCESS,
  DOCUMENT_TAGS_BANNER_ERROR,
  DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_TITLE,
  DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_MESSAGE,
} from '../../../utils/user-message';
import NebFormDocumentTags from '../../forms/neb-form-document-tags';

export const ELEMENTS = { ...BASE_ELEMENTS, form: { id: 'form' } };

class NebOverlayDocumentTags extends NebOverlayFormItem {
  static get config() {
    return {
      itemName: 'Document Tag',
      form: NebFormDocumentTags,
    };
  }

  static get properties() {
    return {
      __formSaving: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          grid-template-rows: max-content;
          width: 52rem;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formSaving = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: model => this.__saveDocumentTag(model),
    };
  }

  async __saveDocumentTag(tag) {
    this.__formSaving = true;

    try {
      const result = tag.id ? await api.update(tag) : await api.save(tag);
      store.dispatch(openSuccess(DOCUMENT_TAGS_BANNER_SUCCESS));
      this.isDirty = false;
      this.dismiss(result);
    } catch (e) {
      this.__formSaving = false;

      if (e.statusCode === 409) {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_TITLE(),
          message: DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_MESSAGE(),
        });
      } else {
        store.dispatch(openError(DOCUMENT_TAGS_BANNER_ERROR));
      }
    }
  }

  renderForm() {
    return html`
      <neb-form-document-tags
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.getModel()}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .formSaving="${this.__formSaving}"
      ></neb-form-document-tags>
    `;
  }
}

window.customElements.define(
  'neb-overlay-document-tags',
  NebOverlayDocumentTags,
);
