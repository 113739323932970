import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingClient = new DeprecatedApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const getClaimScrubbing = async () => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: 'claim-scrubbing',
    headers: { 'Content-Type': 'application/json' },
    version: 1,
  });

  return res;
};

export const updateClaimScrubbing = async enabled => {
  const res = await billingClient.makeRequest({
    method: Method.PUT,
    optOutLoadingIndicator: true,
    path: 'claim-scrubbing',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ enabled }),
    version: 1,
  });

  return res;
};

export const getRuleSet = async id => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: `rule-sets/${id}`,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
  });

  return {
    ruleSet: res.data[0],
    defaultRules: res.defaultRules,
  };
};

export const getRuleSets = async () => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: 'rule-sets',
    headers: { 'Content-Type': 'application/json' },
    version: 1,
  });

  return res;
};

export const updateRuleSet = async (id, model) => {
  const res = await billingClient.makeRequest({
    method: Method.PUT,
    optOutLoadingIndicator: true,
    path: `rule-sets/${id}`,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(model),
    version: 1,
  });

  return res.data[0];
};

export const createRuleSet = async model => {
  const res = await billingClient.makeRequest({
    method: Method.POST,
    optOutLoadingIndicator: true,
    path: 'rule-sets',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
    version: 1,
  });

  return res;
};

export const getRules = async (queryParams = {}) => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: 'rule-sets/rules',
    headers: { 'Content-Type': 'application/json' },
    queryParams,
    version: 1,
  });

  return res;
};

export const getAssociatedRuleSets = async id => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: `claims/${id}/rule-sets`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return { ruleSets: res.data, names: res.names };
};
