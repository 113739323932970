import moment from 'moment-timezone';

import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { dateToRaw, parseDate } from '../../packages/neb-utils/date-util';
import { currencyToCents } from '../../packages/neb-utils/formatters';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });
export const headerJSON = { 'Content-Type': 'application/json' };

const BASE_URL = 'patients';
const PACKAGES_PATH = 'packages';

export const rawDateToModel = raw => (raw ? new Date(raw) : null);

export function formatForDefaultBillType(pp) {
  return {
    id: pp.id,
    patientId: pp.patientId,
    subscription: pp.subscription,
    frequency: pp.frequency,
    name: pp.name,
    type: pp.type,
    completedDate: pp.completedDate,
    sharedCarePackage: pp.sharedCarePackage,
    patientPackageRelatedPatients: pp.patientPackageRelatedPatients.map(
      ({ relatedPatientId }) => ({
        relatedPatientId,
      }),
    ),
    unlimited: pp.unlimited,
    totalVisits: parseInt(pp.totalVisits, 10),
    effectiveDate: pp.effectiveDate,
    expirationDate: pp.expirationDate,
    totalPrice: currencyToCents(pp.totalPrice),
    charges: pp.charges.map(c => ({ ...c, quantity: c.units.toString() })),
  };
}

const formatPatientPackageCharges = patientPackageCharges =>
  patientPackageCharges.map(ppc => {
    const result = {
      id: ppc.id,
      chargeId: ppc.chargeId,
      units: ppc.quantity || '',
    };

    if (ppc.charge) result.charge = ppc.charge;

    return result;
  });

const formatPatientPackageRelatedHistory = data => ({
  id: data.id,
  relatedPatientId: data.relatedPatientId,
  start: data.start,
  end: data.end,
});

export const formatPatientPackageRelatedHistories = (histories = []) =>
  histories.map(h => formatPatientPackageRelatedHistory(h));

export const formatPatientPackageRelatedPatients = (
  patientPackageRelatedPatients = [],
) =>
  patientPackageRelatedPatients.map(rp => ({
    id: rp.id,
    relatedPatientId: rp.relatedPatientId,
    active: rp.active === undefined ? true : rp.active,
    histories: formatPatientPackageRelatedHistories(rp.histories),
  }));

export const formatPatientPackageToUI = pp => {
  const totalPrice = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
  }).format(pp.totalPrice / 100.0);

  return {
    ...pp,
    effectiveDate: rawDateToModel(pp.effectiveDate),
    expirationDate: rawDateToModel(pp.expirationDate),
    completedDate: rawDateToModel(pp.completedDate),
    totalVisits: pp.totalVisits.toString(),
    totalPrice,
    charges: formatPatientPackageCharges(pp.charges),
    ...(pp.patientPackageRelatedPatients
      ? {
          patientPackageRelatedPatients: formatPatientPackageRelatedPatients(
            pp.patientPackageRelatedPatients,
          ),
        }
      : {}),
  };
};

export const formatPatientPackagesToUI = pps => {
  if (!pps) return [];
  return pps.map(pp => formatPatientPackageToUI(pp));
};

const formatForSave = pp => {
  const totalPrice = parseInt(pp.totalPrice.replace(/[^0-9.]/g, '') * 100, 10);
  const totalVisits = pp.totalVisits === '' ? 0 : parseInt(pp.totalVisits, 10);

  return {
    ...pp,
    name: pp.name.trim(),
    effectiveDate: dateToRaw(pp.effectiveDate),
    expirationDate: dateToRaw(pp.expirationDate, true),
    completedDate: dateToRaw(pp.completedDate, true),
    totalVisits,
    totalPrice,
    charges: pp.charges.map(c => (c.units ? { ...c, quantity: c.units } : c)),
  };
};

const filterRelatedPatients = pp =>
  pp.sharedCarePackage && pp.active && pp.patientPackageRelatedPatients
    ? pp.patientPackageRelatedPatients
        .filter(f => f.data.id !== pp.patientId)
        .map(r => r.data.id)
    : [];

export const formatPatientPackageForPost = pp => {
  const relatedPatientIds = filterRelatedPatients(pp);

  const postBody = { ...formatForSave(pp), relatedPatientIds };

  delete postBody.patientPackageRelatedPatients;

  return postBody;
};

export const formatPatientPackageForPut = pp => {
  const relatedPatientIds = filterRelatedPatients(pp);

  return {
    ...formatForSave(pp),
    patientPackageRelatedPatients: relatedPatientIds.map(relatedPatientId => ({
      relatedPatientId,
    })),
  };
};

export const historyDateToRaw = (date, isEnd = false) => {
  if (!date) return null;

  if (!moment.isMoment(date)) {
    return isEnd
      ? parseDate(date).endOf('day').format()
      : parseDate(date).startOf('day').format();
  }

  return isEnd ? date.endOf('day').format() : date.startOf('day').format();
};

export const formatPatientPackageRelatedHistoryForSave = data => ({
  id: data.id,
  relatedPatientId: data.relatedPatientId,
  start: historyDateToRaw(data.start, false),
  end: historyDateToRaw(data.end, true),
});

export const formatPatientPackageRelatedHistoriesForSave = (histories = []) =>
  histories.map(h => formatPatientPackageRelatedHistoryForSave(h));

export const getPatientPackageCount = async (patientId, queryParams = {}) => {
  const { count } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}/count`,
    queryParams,
    headers: headerJSON,
    version: 1,
    cacheKey: `${patientId}-patient-package-count-${JSON.stringify(
      queryParams,
    )}`,
  });
  return count;
};

export const getPatientPackageExists = (
  patientId,
  patientPackage,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}/exists`,
    body: JSON.stringify(patientPackage),
    headers: headerJSON,
    optOutLoadingIndicator,
  });

export const getPatientPackages = async (
  patientId,
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const patientPackages = await apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}`,
    queryParams,
    headers: headerJSON,
    version: 1,
    cacheKey: `${patientId}-patient-packages-${JSON.stringify(queryParams)}`,
    optOutLoadingIndicator,
  });

  return formatPatientPackagesToUI(patientPackages);
};

export const getPatientPackageUsedCounts = (
  patientId,
  ids,
  dateOfService = null,
  optOutLoadingIndicator = false,
) => {
  const body = dateOfService ? { ids, dateOfService } : { ids };

  return apiClient.makeRequest({
    method: Method.POST,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}/usedCount`,
    headers: headerJSON,
    body: JSON.stringify(body),
    version: 2,
    cacheKey: `${patientId}-patient-package-used-counts-${JSON.stringify(ids)}`,
    optOutLoadingIndicator,
  });
};

export const createPatientPackage = async (
  patientId,
  patientPackage,
  version = 2,
) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}`,
    headers: headerJSON,
    body: JSON.stringify({
      patientPackage,
    }),
    version,
  });

  return formatPatientPackageToUI(result);
};

export const getPatientPackage = async (id, queryParams = {}) => {
  const patientPackage = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${PACKAGES_PATH}/${id}`,
    queryParams,
    headers: headerJSON,
    version: 2,
    cacheKey: `${id}-patient-package-${JSON.stringify(queryParams)}`,
  });
  return formatPatientPackageToUI(patientPackage);
};

export const getPatientPackageUsedCount = (id, dateOfService = null) => {
  const body = dateOfService ? { dateOfService } : {};

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${PACKAGES_PATH}/${id}/usedCount`,
    headers: headerJSON,
    body: JSON.stringify(body),
    version: 2,
    cacheKey: `${id}-patient-package-used-count`,
  });
};

export const getPatientPackageEncounterCharges = (id, queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${PACKAGES_PATH}/${id}/encounterCharges`,
    queryParams,
    headers: headerJSON,
    version: 2,
    cacheKey: `${id}-patient-package-encounter-charges-${JSON.stringify(
      queryParams,
    )}`,
  });

export const updatePatientPackage = async (patientPackage, version = 3) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${PACKAGES_PATH}/${patientPackage.id}`,
    headers: headerJSON,
    body: JSON.stringify(patientPackage),
    updateNotificationDetails: { patientPackage: { id: patientPackage.id } },
    version,
  });

  return formatPatientPackageToUI(result);
};

export const completePatientPackage = async (patientPackage, patientId) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${PACKAGES_PATH}/${patientPackage.id}/complete`,
    headers: headerJSON,
    body: JSON.stringify({
      ...patientPackage,
      patientId,
    }),
    version: 3,
  });

  return {
    patientPackage: formatPatientPackageToUI(result.data[0]),
    newBillType: result.newBillType,
    caseOverrideUpdated: result.caseOverrideUpdated,
  };
};

export const expirePatientPackage = async patientPackage => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${PACKAGES_PATH}/${patientPackage.id}/expire`,
    headers: headerJSON,
    body: JSON.stringify(patientPackage),
    version: 2,
  });

  return formatPatientPackageToUI(result);
};

export const getPatientPackageShareHistory = async id => {
  const histories = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${PACKAGES_PATH}/${id}/history`,
    headers: headerJSON,
    version: 2,
    cacheKey: `${id}-patient-package-history`,
  });

  return formatPatientPackageRelatedHistories(histories);
};

export const putPatientPackageShareHistory = async (id, putBody) => {
  const payload = formatPatientPackageRelatedHistoriesForSave(putBody);

  const { histories } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${PACKAGES_PATH}/${id}/history`,
    headers: headerJSON,
    body: JSON.stringify({ histories: payload }),
    version: 2,
  });

  return formatPatientPackageRelatedHistories(histories);
};

export const reorderPatientPackages = async (patientId, packageIds) => {
  await apiClient.makeRequest({
    body: JSON.stringify({ ids: packageIds }),
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}/order`,
    headers: headerJSON,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const getActivePatientPackagesOrdered = async patientId => {
  const patientPackages = await apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${PACKAGES_PATH}/active`,
    headers: headerJSON,
    version: 1,
    cacheKey: `${patientId}-patient-packages-active`,
    optOutLoadingIndicator: false,
  });

  return formatPatientPackagesToUI(patientPackages);
};
