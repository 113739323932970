const { NEB_ENV: { GOOGLE_API_KEY } = {} } = window;

// eslint-disable-next-line no-template-curly-in-string
const API_KEY = GOOGLE_API_KEY !== '${GOOGLE_API_KEY}' ? GOOGLE_API_KEY : '';

export const ID_SCRIPT = 'script-google-maps-places';

const API_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;

let __loaded = false;
let __placesService = null;
let __autoCompleteService = null;

function getComponentValue(components, type) {
  const component = components.find(comp => comp.types.includes(type));
  return component ? component.short_name : '';
}

function getPlacePredictions(criteria) {
  return new Promise(resolve =>
    __autoCompleteService.getPlacePredictions(criteria, predictions => {
      resolve(predictions || []);
    }),
  );
}

function getDetails(placeId) {
  return new Promise(resolve => {
    __placesService.getDetails(
      {
        placeId,
      },
      res => {
        resolve(res);
      },
    );
  });
}

export async function loadApi() {
  if (!__loaded) {
    await new Promise(resolve => {
      const scriptEl = document.createElement('script');

      scriptEl.id = ID_SCRIPT;
      scriptEl.onload = () => resolve();
      scriptEl.src = API_URL;

      document.head.appendChild(scriptEl);
    });

    __autoCompleteService = new window.google.maps.places.AutocompleteService();
    __placesService = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    );

    __loaded = true;
  }
}

export async function fetchSuggestions(value) {
  const predictions = await getPlacePredictions({
    types: ['address'],
    input: value,
    componentRestrictions: {
      country: ['us'],
    },
  });

  return predictions.map(item => ({
    id: item.id,
    placeId: item.place_id,
    label: item.description,
  }));
}

export async function fetchPlace(id) {
  const results = await getDetails(id);
  const comps = results.address_components;
  const route = getComponentValue(comps, 'route');
  const streetNumber = getComponentValue(comps, 'street_number');

  return {
    address1: `${streetNumber} ${route}`,
    city: getComponentValue(comps, 'locality'),
    state: getComponentValue(comps, 'administrative_area_level_1'),
    zipcode: getComponentValue(comps, 'postal_code'),
  };
}
