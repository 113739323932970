import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  leftContent: {
    id: 'left-content',
  },
  rightContent: {
    id: 'right-content',
  },
  removeButton: {
    id: 'remove-button',
  },
};
export class Item extends LitElement {
  static get properties() {
    return {
      name: String,
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .field {
          width: inherit;
        }

        .container-selector {
          display: flex;
        }

        .field-selector {
          min-width: 0;
          flex: 1 0 0;
        }

        .icon {
          cursor: pointer;
          margin: auto 0 24px ${CSS_SPACING};
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.showRemoveButton = false;
    this.name = '';

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  initHandlers() {
    this.handlers = {
      remove: () => this.onRemove(this.name),
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  renderLeftContent() {
    throw new Error('renderLeftContent() - Unimplemented', this);
  }

  renderRightContent() {
    return '';
  }

  __renderRemoveItemButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeButton.id}"
            class="icon"
            icon="neb:minus"
            @click="${this.handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.renderLeftContent()}

        <div class="container-selector">
          ${this.renderRightContent()} ${this.__renderRemoveItemButton()}
        </div>
      </div>
    `;
  }
}
