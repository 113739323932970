import { html, css } from 'lit';

import {
  add,
  addBatch,
} from '../../neb-api-client/src/ledger-superbill-api-client';
import { openSuccess, openError } from '../../neb-dialog/neb-banner-state';
import { ITEM_ALL_PATIENTS } from '../../neb-lit-components/src/components/forms/neb-form-new-statement';
import { NebFormNewSuperbill } from '../../neb-lit-components/src/components/forms/neb-form-new-superbill';
import { store } from '../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_SPACING,
} from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

const BANNER_MESSAGE = {
  BATCH_SUCCESS:
    'Superbill batch generation started successfully. Click "View Batches" to check the batch status.',
  BATCH_ERROR: 'An error occurred when generating the Superbill batch.',
  SINGLE_SUCCESS: 'Superbill(s) saved successfully',
  SINGLE_ERROR: 'An error occurred when generating the Superbill',
  NO_SUPERBILLS_ERROR:
    'An error occurred when generating the Superbill. There are no charges for the selected parameters.',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

class NebPopupNewSuperbill extends NebPopup {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  initState() {
    super.initState();

    this.__formModel = NebFormNewSuperbill.createModel();
    this.title = 'Generate New Superbill';
    this.model = {
      patientId: '',
      patient: null,
      startDisplayDate: '',
      isCheckOut: false,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      preview: formData => this.__saveSuperbill(formData, true),
      generate: formData => this.__saveSuperbill(formData, false, true),
    };
  }

  // eslint-disable-next-line complexity
  async __saveSuperbill(
    model,
    preview = false,
    optOutLoadingIndicator = false,
  ) {
    const { patientId: formPatientId, ...formData } = model;
    const allPatientsSelected = formPatientId === ITEM_ALL_PATIENTS.data.id;
    const patientId = !allPatientsSelected ? formPatientId : null;

    try {
      const hasData = patientId
        ? await add(patientId, formData, preview)
        : await addBatch({
            model: formData,
            preview,
            optOutLoadingIndicator,
          });

      if (!hasData) {
        store.dispatch(openError(BANNER_MESSAGE.NO_SUPERBILLS_ERROR));
      } else {
        if (!patientId) {
          store.dispatch(openSuccess(BANNER_MESSAGE.BATCH_SUCCESS));
        } else if (!preview) {
          store.dispatch(openSuccess(BANNER_MESSAGE.SINGLE_SUCCESS));
        }

        if (!preview) this.onClose(true);
      }
    } catch (err) {
      if (!patientId) store.dispatch(openError(BANNER_MESSAGE.BATCH_ERROR));
      else store.dispatch(openError(BANNER_MESSAGE.SINGLE_ERROR));
    }

    this.__formModel = model;
  }

  modelChanged() {
    if (this.model.patientId) {
      this.__formModel = {
        ...this.__formModel,
        patientId: this.model.patientId,
      };
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 725px;
          overflow: auto;
        }

        .container {
          display: flex;
          padding: ${CSS_SPACING};
          min-height: 0;
          background-color: ${CSS_COLOR_WHITE};
          flex-direction: column;
          height: auto;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-new-superbill
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .patientId="${this.model.patientId}"
        .patient="${this.model.patient}"
        .dateOfServiceFrom="${parseDate(this.model.startDisplayDate).startOf(
          'day',
        )}"
        .dateOfServiceTo="${parseDate(this.model.startDisplayDate).endOf(
          'day',
        )}"
        .onSave="${this.handlers.generate}"
        .onPreview="${this.handlers.preview}"
        .onCancel="${this.handlers.cancel}"
        .isCheckOut="${this.model.isCheckOut}"
      ></neb-form-new-superbill>
    `;
  }
}

customElements.define('neb-popup-new-superbill', NebPopupNewSuperbill);
