import '@polymer/polymer/polymer-legacy';
import { importedFonts } from './neb-ckeditor-fonts';
import { CSS_COLOR_HIGHLIGHT } from './neb-variables';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const ckeditorStyles = `<style type="text/css">
    ${importedFonts}

    .table {
      position: relative;
      display: table;
      margin: 1em 0;
    }

    .table table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px double #b3b3b3;
    }

    .table td {
      min-width: 2em;
      padding: 0.4em;
      border: 1px solid #d9d9d9;
    }

    .table-wrapper {
      width: fit-content;
      user-select: none;
      display: table;
    }

    .grid-container {
      display: grid;
      cursor: pointer;
      user-select: all;
      text-decoration: underline;
      color: ${CSS_COLOR_HIGHLIGHT};
    }

    .grid-container-listings {
      color: black;
      text-decoration: none;
      grid-template-columns: minmax(auto, 100%) fit-content(700px);
    }

    .grid-container-diagnoses {
      grid-template-columns: minmax(auto, 90px) fit-content(710px);
    }

    .grid-container-treatment {
      grid-template-columns:
        fit-content(180px) fit-content(200px) fit-content(225px)
        minmax(102px, auto);
    }

    .grid-item {
      padding: 10px;
      text-align: left;
      outline: 1px solid #b3b3b3;
      margin: 1px 0 0 1px;
      user-select: none;
    }

    .grid-header {
      padding: 0.4em;
      font-weight: bold;
      background: hsla(0,0%,0%,5%);
    }

    .listing {
      padding: 0.4em;
    }

    p {
      margin: 0;
    }
  </style>
`;
