import { html, css } from 'lit';

import * as api from '../../../../neb-api-client/src/appointment-types';
import ApptTypeForm from '../forms/neb-form-appointment-type';

import NebOverlayFormItem from './neb-overlay-form-item';

export const ELEMENTS = { form: { id: 'form' } };

class OverlayAppointmentType extends NebOverlayFormItem {
  static get config() {
    return {
      itemName: 'Appointment Type',
      form: ApptTypeForm,
      messageSuccess: 'Appointment Type saved successfully',
      messageError: 'Appointment Type was not saved successfully,',
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          grid-template-rows: max-content;
          width: 52rem;
        }
      `,
    ];
  }

  firstUpdated() {
    super.firstUpdated();

    this.allLocations = this.__formatLocations(
      this.initialModel.context.allLocations,
    );
  }

  save(item) {
    const req = item.id ? api.update : api.add;

    return req(item);
  }

  __formatLocations(locations) {
    return locations
      ? locations.map(item => ({
          data: item,
          label: item.name,
        }))
      : '';
  }

  renderForm() {
    return html`
      <neb-form-appointment-type
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.getModel()}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .locations="${this.allLocations}"
      ></neb-form-appointment-type>
    `;
  }
}

window.customElements.define(
  'neb-overlay-appointment-type',
  OverlayAppointmentType,
);
