import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { invalidNPI } from '../../../src/components/forms/patients/cases/neb-form-patient-case';
import { buildQualifierIdTemplate } from '../../neb-lit-components/src/utils/claim-render-formatter';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { REFERRING_PROVIDER_QUALIFIERS } from '../../neb-utils/claims';
import { number } from '../../neb-utils/masks';

import NebPopupClaim from './neb-popup-claim';

export const QUALIFIERS = [
  {
    value: '',
    label: '',
  },
  ...REFERRING_PROVIDER_QUALIFIERS,
];

const getQualifierObject = qualifier =>
  typeof qualifier === 'string'
    ? QUALIFIERS.find(({ value }) => value === qualifier)
    : qualifier;

export const ELEMENTS = {
  firstNameTextField: { id: 'text-field-first-name' },
  lastNameTextField: { id: 'text-field-last-name' },
  middleNameTextField: { id: 'text-field-middle-name' },
  credentialsTextField: { id: 'text-field-credentials' },
  qualifierDropdown: { id: 'qualifier-dropdown' },
  otherIdTextField: { id: 'text-field-other-id' },
  npiTextField: { id: 'text-field-npi' },
  radioButtons: { selector: 'neb-radio-button' },
};

class NebPopupClaimRadioNameFields extends NebPopupClaim {
  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        const [qualifier, type] = name.split('.');

        this.__state = {
          ...this.__state,
          ...(!type ? value : { [type]: value }),
          qualifier,
        };
      },
    };
  }

  static get styles() {
    return [
      super.styles,

      css`
        .container-radio {
          display: flex;
          flex-direction: column;
        }

        .container-radio:first-of-type {
          margin-top: -10px;
        }

        .container-radio:last-of-type {
          margin-bottom: 35px;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .container-name {
          display: grid;
          grid-template: 1fr 1fr / 1fr 1fr;
          grid-gap: 10px;
          margin-left: 40px;
        }
      `,
    ];
  }

  __formatName({ firstName, middleName, lastName, credentials }) {
    const name = [firstName, middleName && middleName[0], lastName, credentials]
      .filter(Boolean)
      .join(' ');

    return name;
  }

  __formatProviderInfo({ npi, otherId, otherIdQualifier }) {
    const providerInfo = [
      buildQualifierIdTemplate(otherIdQualifier, otherId),
      npi && `NPI - ${npi}`,
    ]
      .filter(Boolean)
      .join(', ');

    return providerInfo;
  }

  __formatLabel(qualifier = '', label = '', name = {}) {
    const formattedName = this.__formatName(name);
    const formattedProvider = this.__formatProviderInfo(name);
    const hasDash = formattedName || formattedProvider;
    const hasComma = formattedName && formattedProvider;

    return label !== 'None'
      ? `${qualifier} (${label})${hasDash ? ' - ' : ''}${formattedName}${
          hasComma ? ', ' : ''
        }${formattedProvider}`
      : label;
  }

  __isChecked(qualifier, value, showNameFields) {
    const stateValue = {
      credentials: this.__state.credentials,
      firstName: this.__state.firstName,
      middleName: this.__state.middleName,
      lastName: this.__state.lastName,
      npi: this.__state.npi,
      otherId: this.__state.otherId,
      otherIdQualifier: this.__state.otherIdQualifier,
    };

    return (
      this.__state.qualifier === qualifier &&
      (equal(value, stateValue) || showNameFields)
    );
  }

  __renderNameFields(qualifier, showNameFields) {
    return showNameFields
      ? html`
          <div class="container-name">
            <neb-textfield
              id="${ELEMENTS.firstNameTextField.id}"
              label="First Name"
              maxLength="24"
              .name="${qualifier}.firstName"
              .value="${this.__state.qualifier === qualifier
                ? this.__state.firstName
                : ''}"
              .disabled="${this.__state.qualifier !== qualifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-textfield
              id="${ELEMENTS.middleNameTextField.id}"
              label="Middle Name"
              maxLength="24"
              .name="${qualifier}.middleName"
              .value="${this.__state.qualifier === qualifier
                ? this.__state.middleName
                : ''}"
              .disabled="${this.__state.qualifier !== qualifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-textfield
              id="${ELEMENTS.lastNameTextField.id}"
              label="Last Name"
              maxLength="24"
              .name="${qualifier}.lastName"
              .value="${this.__state.qualifier === qualifier
                ? this.__state.lastName
                : ''}"
              .disabled="${this.__state.qualifier !== qualifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-textfield
              id="${ELEMENTS.credentialsTextField.id}"
              label="Credentials"
              maxLength="24"
              .name="${qualifier}.credentials"
              .value="${this.__state.qualifier === qualifier
                ? this.__state.credentials
                : ''}"
              .disabled="${this.__state.qualifier !== qualifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            ${this.__renderProviderInfoFields(qualifier)}
          </div>
        `
      : '';
  }

  __renderProviderInfoFields(qualifier) {
    return html`
      <neb-select
        id="${ELEMENTS.qualifierDropdown.id}"
        label="Qualifier"
        .name="${qualifier}.otherIdQualifier"
        .items="${Object.keys(QUALIFIERS).map(type => QUALIFIERS[type])}"
        .value="${this.__state.qualifier === qualifier
          ? getQualifierObject(this.__state.otherIdQualifier)
          : ''}"
        .disabled="${this.__state.qualifier !== qualifier}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.otherIdTextField.id}"
        label="Other ID"
        maxLength="17"
        .name="${qualifier}.otherId"
        .value="${this.__state.qualifier === qualifier
          ? this.__state.otherId
          : ''}"
        .disabled="${this.__state.qualifier !== qualifier}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.npiTextField.id}"
        label="NPI"
        maxLength="10"
        .name="${qualifier}.npi"
        .value="${this.__state.qualifier === qualifier ? this.__state.npi : ''}"
        .disabled="${this.__state.qualifier !== qualifier}"
        .onChange="${this.handlers.change}"
        .error="${invalidNPI(this.__state.npi)}"
        helper="${invalidNPI(this.__state.npi) ? 'Must be 10 characters' : ''}"
        .mask="${number}"
      ></neb-textfield>
    `;
  }

  renderContent() {
    return html`
      <div>
        ${this.model.options.map(
          ({ qualifier, label, value, showNameFields }) => html`
            <div class="container-radio">
              <neb-radio-button
                class="spacing"
                label="${this.__formatLabel(qualifier, label, value)}"
                name="${qualifier}"
                .value="${value}"
                .checked="${this.__isChecked(qualifier, value, showNameFields)}"
                .onChange="${this.handlers.change}"
              ></neb-radio-button>

              ${this.__renderNameFields(qualifier, showNameFields)}
            </div>
          `,
        )}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-radio-name-fields',
  NebPopupClaimRadioNameFields,
);
