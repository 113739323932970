import '../../../misc/neb-icon';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { css, html } from 'lit';

import Table, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { currency } from '../../../../../packages/neb-utils/masks';
import { CSS_FONT_WEIGHT_BOLD, CSS_COLOR_HIGHLIGHT } from '../../../../styles';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  icons: { selector: '[id^=icon-row-]' },
  linkBatchId: { id: 'link-batch-id' },
  amountTextfield: {
    selector: '[id^=amount-textfield-]',
    tag: 'neb-textfield',
  },
  remainderAmount: { id: 'remainder-amount' },
  remainderCell: { id: 'remainder-cell' },
};

class NebTableLedgerSplitPayments extends Table {
  static get properties() {
    return {
      edit: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .remainder-cell {
          width: 100%;
          text-align: end;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .remainder-amount {
          padding-left: 13px;
        }

        .link {
          display: flex;
          align-items: center;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .patient-text {
          padding-right: 6px;
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .payment-number-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.emptyMessage =
      'There are no splits for this payment. Click "Edit Splits" to add a new split.';

    this.model = [];
    this.edit = false;

    this.onSelectPatient = () => {};

    this.onClickPaymentId = () => {};
    this.config = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
      clickPatient: e => {
        e.stopPropagation();
        const index = e.currentTarget.getAttribute('index');
        return this.onSelectPatient(Number.parseInt(index, 10));
      },
      clickPaymentId: e => {
        e.stopPropagation();
        const index = e.currentTarget.getAttribute('index');
        this.onClickPaymentId(index);
      },
    };
  }

  __isUneditable(item) {
    return (
      item.status !== 'Unallocated' &&
      item.status !== '' &&
      item.amount === item.preserve
    );
  }

  __buildConfig() {
    return [
      {
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
      },
      ...(this.model.some(s => s.payerPlanId && s.payerPlanId.data.id)
        ? [
            {
              key: 'payerPlanId',
              label: 'Payer',
              flex: css`1 0 0`,
            },
          ]
        : [
            {
              key: 'patientId',
              label: 'Payer',
              flex: css`1 0 0`,
            },
          ]),
      {
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        key: 'preserve',
        label: 'Minimum',
        flex: css`1 0 0`,
      },
      {
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
      },
    ];
  }

  updated(changed) {
    if (changed.has('model') && this.model) {
      this.config = this.__buildConfig();
    }
  }

  shouldEnableRemoveButton(item, index) {
    if (!item.progenitor && index !== this.model.length - 1) {
      return this.__isUneditable(item) ? null : true;
    }
    return null;
  }

  __renderPatientIdCell(value, index) {
    if (this.edit) {
      return value.label || '';
    }

    return html`
      <div
        id="patient-payer-link-${index}"
        class="link"
        index="${index}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value.label || ''}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderAmountCell(value, index, error) {
    if (this.edit) {
      if (index === this.model.length - 1) {
        return html`
          <div class="remainder-amount" id="remainder-amount">
            ${this.remainder}
          </div>
        `;
      }

      const disabled = this.__isUneditable(this.model[index]);
      return html`
        <neb-textfield
          id="amount-textfield-${index}"
          name="${`${index}.amount`}"
          label="Amount"
          inputMode="numeric"
          helper=" "
          .mask="${currency}"
          .value="${value}"
          .error="${error}"
          .disabled="${disabled}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      `;
    }
    return value;
  }

  __renderPaymentId({ value, index, columnConfig }) {
    return !this.edit
      ? html`
          <div
            id="payment-number-${index}"
            index="${index}"
            class="payment-number-container"
            @click="${this.handlers.clickPaymentId}"
          >
            ${value}
          </div>
        `
      : super.renderDataCell(value, columnConfig);
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, index, _, error) {
    switch (columnConfig.key) {
      case 'paymentNumber':
        return this.__renderPaymentId({ value, index, columnConfig });

      case 'payerPlanId': {
        return value.data ? value.data.payerName : '';
      }

      case 'patientId': {
        return this.__renderPatientIdCell(value, index);
      }

      case 'preserve': {
        return value === 'Remainder:'
          ? html`
              <div class="remainder-cell" id="remainder-cell">${value}</div>
            `
          : value;
      }

      case 'amount': {
        return this.__renderAmountCell(value, index, error);
      }

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

customElements.define(
  'neb-table-ledger-split-payments',
  NebTableLedgerSplitPayments,
);
