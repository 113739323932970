import '../../../packages/neb-lit-components/src/components/neb-text';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../styles';
import BODY_SECTIONS_BACK from '../../svg/body-sections-back';
import BODY_SECTIONS_FRONT from '../../svg/body-sections-front';

export const ELEMENTS = {
  bodyDiagramFront: { id: 'body-diagram-front' },
  bodyDiagramBack: { id: 'body-diagram-back' },
  textLinkFront: { id: 'text-link-front' },
  textLinkBack: { id: 'text-link-back' },
};

export const BODY_VIEW = {
  FRONT: 'front',
  BACK: 'back',
};

export const BODY_PARTS = {
  [BODY_VIEW.FRONT]: {
    abdomen: 'abdomen',
    chest: 'chest',
    'front-of-head': 'front of head',
    'front-of-left-arm': 'front of right arm',
    'front-of-left-elbow': 'front of right elbow',
    'front-of-left-forearm': 'front of right forearm',
    'front-of-left-hip': 'front of right hip',
    'front-of-left-leg': 'front of right leg',
    'front-of-left-shoulder': 'front of right shoulder',
    'front-of-left-wrist-hand': 'front of right wrist/hand',
    'front-of-neck': 'front of neck',
    'front-of-right-arm': 'front of left arm',
    'front-of-right-elbow': 'front of left elbow',
    'front-of-right-forearm': 'front of left forearm',
    'front-of-right-hip': 'front of left hip',
    'front-of-right-leg': 'front of left leg',
    'front-of-right-shoulder': 'front of left shoulder',
    'front-of-right-wrist-hand': 'front of left wrist/hand',
    'left-ankle-foot': 'right ankle/foot',
    'left-jaw': 'right jaw',
    'left-knee': 'right knee',
    'left-shin': 'right shin',
    'right-ankle-foot': 'left ankle/foot',
    'right-jaw': 'left jaw',
    'right-knee': 'left knee',
    'right-shin': 'left shin',
  },
  [BODY_VIEW.BACK]: {
    'back-of-left-arm': 'back of left arm',
    'back-of-left-forearm': 'back of left forearm',
    'back-of-left-hip': 'back of left hip',
    'back-of-left-knee': 'back of left knee',
    'back-of-left-leg': 'back of left leg',
    'back-of-left-shoulder': 'back of left shoulder',
    'back-of-right-arm': 'back of right arm',
    'back-of-right-forearm': 'back of right forearm',
    'back-of-right-hip': 'back of right hip',
    'back-of-right-knee': 'back of right knee',
    'back-of-right-leg': 'back of right leg',
    'back-of-right-shoulder': 'back of right shoulder',
    buttocks: 'buttocks',
    head: 'head',
    'left-ankle-foot': 'left ankle/foot',
    'left-calf': 'left calf',
    'left-elbow': 'left elbow',
    'left-hand-wrist': 'back of left wrist/hand',
    'low-back': 'low back',
    'mid-back': 'mid back',
    neck: 'neck',
    'right-ankle-foot': 'right ankle/foot',
    'right-calf': 'right calf',
    'right-elbow': 'right elbow',
    'right-hand-wrist': 'back of right wrist/hand',
    'upper-back': 'upper back',
  },
};

class NebBodyDiagram extends LitElement {
  static get properties() {
    return {
      selectedBodyView: String,
      selectedBodyPartId: String,
      selectedBodyPartName: String,
      selectedBodyPartIds: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container-buttons {
          display: flex;
          justify-content: center;
          gap: 10px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedBodyView = BODY_VIEW.BACK;
    this.selectedBodyPartId = '';
    this.selectedBodyPartName = '';
    this.selectedBodyPartIds = [];

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickFront: () => {
        this.__onChange({
          selectedBodyView: BODY_VIEW.FRONT,
        });
      },

      clickBack: () => {
        this.__onChange({
          selectedBodyView: BODY_VIEW.BACK,
        });
      },

      selectBodyPart: id => {
        const selectedBodyPart = Object.entries(
          BODY_PARTS[this.selectedBodyView],
        ).find(([bodyPartId]) => bodyPartId === id);

        if (!selectedBodyPart) {
          return;
        }

        const [selectedBodyPartId, selectedBodyPartName] = selectedBodyPart;

        if (this.selectedBodyPartIds.includes(selectedBodyPartId)) {
          return;
        }

        this.__onChange({
          selectedBodyPartId,
          selectedBodyPartName,
        });
      },
    };
  }

  __onChange(whatChanged) {
    this.onChange({
      selectedBodyView: this.selectedBodyView,
      selectedBodyPartId: this.selectedBodyPartId,
      selectedBodyPartName: this.selectedBodyPartName,
      ...whatChanged,
    });
  }

  firstUpdated() {
    this.shadowRoot.addEventListener('click', e =>
      this.__handlers.selectBodyPart(e.target.id),
    );

    let touchmoved;

    this.shadowRoot.addEventListener('touchstart', () => {
      touchmoved = false;
    });

    this.shadowRoot.addEventListener('touchmove', () => {
      touchmoved = true;
    });

    this.shadowRoot.addEventListener('touchend', e => {
      if (!touchmoved) {
        this.__handlers.selectBodyPart(e.target.id);
      }
    });
  }

  updated() {
    this.shadowRoot.querySelectorAll('.selected').forEach(element => {
      element.classList.remove('selected');
    });

    this.shadowRoot.querySelectorAll('.disabled').forEach(element => {
      element.classList.remove('disabled');
    });

    this.selectedBodyPartIds.forEach(id => {
      if (id === this.selectedBodyPartId) {
        return;
      }

      const elementToDisable = this.shadowRoot.getElementById(id);

      if (elementToDisable) {
        elementToDisable.classList.add('disabled');
      }
    });

    const elementToSelect = this.shadowRoot.getElementById(
      this.selectedBodyPartId,
    );

    if (elementToSelect) {
      elementToSelect.classList.add('selected');
    }
  }

  render() {
    return html`
      <div class="container-buttons">
        <neb-text
          id="${ELEMENTS.textLinkFront.id}"
          link
          .onClick="${this.__handlers.clickFront}"
          >Front</neb-text
        >
        <neb-text
          id="${ELEMENTS.textLinkBack.id}"
          link
          .onClick="${this.__handlers.clickBack}"
          >Back</neb-text
        >
      </div>
      ${this.__renderBodyDiagram()}
    `;
  }

  __renderBodyDiagram() {
    switch (this.selectedBodyView) {
      case BODY_VIEW.FRONT:
        return html`
          <div id="${ELEMENTS.bodyDiagramFront.id}">${BODY_SECTIONS_FRONT}</div>
        `;
      default:
        return html`
          <div id="${ELEMENTS.bodyDiagramBack.id}">${BODY_SECTIONS_BACK}</div>
        `;
    }
  }
}

customElements.define('neb-body-diagram', NebBodyDiagram);
