/* eslint-disable no-bitwise */
import DeprecatedApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const BASE_URL = 'code-payment';

export const toFlags = index => ({
  forPatient: Boolean((index + 1) & 0x01),
  forInsurance: Boolean((index + 1) & 0x02),
});

export const TYPE = {
  WRITEOFF: 'writeOff',
  PAYMENT: 'payment',
  CHARGE: 'charge',
  REFUND: 'refund',
  DISCOUNT: 'discount',
  PROVIDER_ADJUSTMENTS: 'provider-adjustments',
};

const toIndex = obj => {
  const PATIENT_BIT = Number(obj.forPatient);
  const INSURANCE_BIT = Number(obj.forInsurance) << 1;
  const index = (INSURANCE_BIT | PATIENT_BIT) - 1;

  return Math.max(0, index);
};

const toSub = obj => ({
  id: obj.id,
  active: obj.active,
  custom: obj.custom,
  code: obj.code,
  description: obj.description,
});

const toPaymentDefault = obj => ({
  patientDefault: obj.patientDefault,
  insuranceDefault: obj.insuranceDefault,
});

const profiles = {
  [TYPE.WRITEOFF]: {
    route: 'code-write-off',
    toModel: raw => ({
      ...toSub(raw),
      type: toIndex(raw),
      codeGroup: raw.codeGroup,
    }),
    toRaw: model => ({
      ...toSub(model),
      ...toFlags(model.type),
      codeGroup: model.codeGroup,
    }),
  },
  [TYPE.PAYMENT]: {
    route: 'code-payment',
    toModel: raw => ({
      ...toSub(raw),
      ...toPaymentDefault(raw),
      source: toIndex(raw),
      isSystemPaymentType: !(raw.forInsurance || raw.forPatient),
      requireProcedure: raw.requireProcedure,
    }),
    toRaw: model => ({
      ...toSub(model),
      ...toFlags(model.source),
      requireProcedure: model.requireProcedure,
    }),
  },
  [TYPE.CHARGE]: {
    route: 'code-charge',
    toModel: raw => ({
      ...toSub(raw),
      type: raw.type,
      autoInvoice: raw.autoInvoice,
      requirePayment: raw.requirePayment,
    }),
    toRaw: model => ({
      ...toSub(model),
      type: model.type,
      autoInvoice: model.autoInvoice,
      requirePayment: model.requirePayment,
    }),
  },
  [TYPE.REFUND]: {
    route: 'code-refund',
    toModel: raw => ({ ...toSub(raw), type: raw.type }),
    toRaw: model => ({ ...toSub(model), type: model.type }),
  },
  [TYPE.DISCOUNT]: {
    route: 'code-discount',
    toModel: raw => ({ ...toSub(raw), type: raw.type }),
    toRaw: model => ({ ...toSub(model), type: model.type }),
  },
  [TYPE.PROVIDER_ADJUSTMENTS]: {
    route: 'code-provider-adjustment',
    toModel: raw => ({ ...toSub(raw), type: raw.type }),
    toRaw: model => ({ ...toSub(model), type: model.type }),
  },
};

export const fetch = async (
  type,
  optOutLoadingIndicator = false,
  filterSystemPaymentTypes = true,
) => {
  const profile = profiles[type];

  let response = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profile.route,
    optOutLoadingIndicator,
  });

  if (filterSystemPaymentTypes && type === TYPE.PAYMENT) {
    response = response.filter(item => item.forInsurance || item.forPatient);
  }

  return response.map(item => profile.toModel(item));
};

export const getBillingCodesCharges = (queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profiles[TYPE.CHARGE].route,
    queryParams,
    cacheKey: `billing-codes-${TYPE.CHARGE}-${JSON.stringify(queryParams)}`,
  });

export const getBillingCodesPayments = async (
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  const paymentCodes = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profiles[TYPE.PAYMENT].route,
    queryParams,
    optOutLoadingIndicator,
    cacheKey: `billing-codes-${TYPE.PAYMENT}-${JSON.stringify(queryParams)}`,
  });

  return paymentCodes;
};

export const getBillingCodesRefunds = (queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profiles[TYPE.REFUND].route,
    queryParams,
    cacheKey: `billing-codes-${TYPE.REFUND}-${JSON.stringify(queryParams)}`,
  });

export const getBillingCodesProviderAdjustments = (queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profiles[TYPE.PROVIDER_ADJUSTMENTS].route,
    queryParams,
    cacheKey: `billing-codes-${TYPE.PROVIDER_ADJUSTMENTS}-${JSON.stringify(
      queryParams,
    )}`,
  });

export const getBillingCodesWriteOffs = (
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: profiles[TYPE.WRITEOFF].route,
    queryParams,
    optOutLoadingIndicator,
    cacheKey: `billing-codes-${TYPE.WRITEOFF}-${JSON.stringify(queryParams)}`,
  });

export const postBillingCodesCharges = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profiles[TYPE.CHARGE].route,
  });

export const postBillingCodesPayments = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profiles[TYPE.PAYMENT].route,
  });

export const postBillingCodesRefunds = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profiles[TYPE.REFUND].route,
  });

export const postBillingCodesWriteOffs = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profiles[TYPE.WRITEOFF].route,
  });

export const postBillingCodeProviderAdjustments = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profiles[TYPE.PROVIDER_ADJUSTMENTS].route,
  });

export const update = async (type, items) => {
  const profile = profiles[type];
  const body = items.map(item => profile.toRaw(item));
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: profile.route,
  });

  return response.map(item => profile.toRaw(item));
};

export const updateBillingCodesPaymentsDefault = body =>
  apiClient.makeRequest({
    optOutLoadingIndicator: true,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    version: 1,
    path: `${BASE_URL}/default`,
  });
