import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_CAPTION,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

import * as InViewService from './services/neb-in-view-service';

export const ELEMENTS = {
  image: {
    id: 'image',
  },
  label: {
    id: 'label',
  },
  blocker: {
    id: 'blocker',
  },
};

class NebProfilePhoto extends LitElement {
  static get properties() {
    return {
      model: Object,
      selected: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 64px;
          height: 64px;
          font-size: 28px;
          color: var(--outline-color);

          --outline-color: ${CSS_COLOR_GREY_1};
        }

        :host([selected]) {
          --outline-color: ${CSS_COLOR_WHITE};
        }

        .container {
          display: flex;
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
          color: var(--outline-color);
          border: 1px solid var(--outline-color);
          border-radius: 50%;
        }

        .content {
          display: flex;
          object-fit: cover;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .blocker {
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_CAPTION};
          font-style: italic;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          align-items: center;
          justify-content: center;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.model = {
      active: true,
      initials: '',
      imgSrc: '',
    };

    this.selected = false;

    this.onDisplay = () => {};
  }

  disconnectedCallback() {
    InViewService.remove(this);

    super.disconnectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('model') && !this.model.imgSrc) {
      InViewService.add(this, () => this.onDisplay());
    }

    super.update(changedProps);
  }

  renderInactive() {
    return (this.model.imgSrc || this.model.initials) &&
      this.model.active === false
      ? html`
          <div id="${ELEMENTS.blocker.id}" class="blocker">
            <span>INACTIVE</span>
          </div>
        `
      : '';
  }

  renderContent() {
    return this.model.imgSrc
      ? html`
          <img
            id="${ELEMENTS.image.id}"
            class="content"
            src="${this.model.imgSrc}"
          />
        `
      : html`
          <div id="${ELEMENTS.label.id}" class="content">
            <span>${this.model.initials}</span>
          </div>
        `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderContent()} ${this.renderInactive()}
      </div>
    `;
  }
}

window.customElements.define('neb-profile-photo', NebProfilePhoto);
