import { html, css } from 'lit';

import { OVERLAY_WIDTH_EXTRA_LARGE } from '../../../../neb-styles/neb-variables';

import '../collections/neb-collection-claim-batches';

import Overlay, { ELEMENTS as BASE_ELEMENTS } from './neb-overlay';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  collection: { id: 'collection' },
};

class OverlayClaimBatches extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-collection-claim-batches
        id="${ELEMENTS.collection.id}"
        .model="${this.model}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-collection-claim-batches>
    `;
  }
}

window.customElements.define('neb-overlay-claim-batches', OverlayClaimBatches);
