/* 
    WARNING!!!!

  This component extends NebLightDom which does not use shadowDom.

  Any styling or element changes can have global effects.
*/

import '../../../../../src/components/misc/neb-icon';
import '../inputs/neb-menu';
import './neb-password-form';
import './neb-tenant-menu-item';
import './neb-no-practice';
import './neb-login-page';
import './neb-change-password';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { LightDom } from '../../../../../src/components/misc/neb-light-dom';
import { resendUserInvitationByEmail } from '../../../../neb-api-client/src/permissions-api-client';
import { resetPracticeUserMeta } from '../../../../neb-api-client/src/practice-user-meta-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import {
  authenticate,
  postAuthentication,
  selectTenant,
} from '../../../../neb-login/neb-session-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store, connect } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import {
  checkSession,
  performLogout,
  storeSessionToLocalStorage,
} from '../../../../neb-utils/session';
import { BUTTON_ROLE } from '../neb-button';

import { TEXT_SUCCESS_TOASTER_CONFIRM } from './neb-forgot-password';

export const ELEMENTS = {
  loginPage: {
    id: 'neb-login-controller-login-page',
  },
  tenantSelection: {
    id: 'neb-login-controller-tenant-selection',
  },
  logoutButton: {
    id: 'neb-login-controller-logout-button',
  },
  passwordForm: {
    id: 'neb-login-controller-password-form',
  },
  forgotPasswordForm: {
    id: 'neb-login-controller-forgot-password-form',
  },
  changePasswordForm: {
    id: 'neb-login-controller-change-password-form',
  },
  noTenantUser: {
    id: 'neb-login-controller-no-tenant-user',
  },
};

export const ERROR_MISSING_INFO = 'Incorrect email and/or password.';
export const practiceType = 'practice';
const RECOMMEND_PASSWORD_CHANGE = {
  title: 'Password Change Recommended',
  message: html`
    As a security measure, we encourage you to change your password every 90
    days. <br />
    <br />
    If you do not wish to change your password at this time, select “Remind
    Later” to acknowledge that you understand our advice and wish to decline.
    You will be reminded again in 90 days. <br /><br />
    NOTE: This message will display every time you log in until you either
    change your password or indicate that you are opting not to change your
    password at this time.
  `,
  confirmText: 'Change Password',
  cancelText: 'Remind Later',
};
const ITEM_HEIGHT = 80;
const MAX_ITEMS = 3;
const DEFAULT_PAGE = 'default';
const FORGOT_PASSWORD_PAGE = 'forgotPassword';
const CHANGE_PASSWORD_PAGE = 'changePassword';
const PASSWORD_FORM = 'passwordForm';
const TENANT_SELECTION_PAGE = 'tenantSelection';
const NO_TENANT_USER_PAGE = 'noTenantUser';

class NebLoginController extends connect(store)(LightDom) {
  static get properties() {
    return {
      __errorText: String,
      __loading: Boolean,
      __authenticated: Boolean,
      __renewing: Boolean,
      __requiresTenantSelection: Boolean,
      __recommendPasswordChange: Boolean,
      __userHasNoPractice: Boolean,
      __session: Object,
      __availableTenants: Array,
      __email: String,
      __forgetPassword: Boolean,
      __loginPage: { type: String, reflect: true, attribute: 'loginPage' },
      __invalidError: String,
      __apiOverride: String,
      __byPassEmail: Boolean,
      __emailSent: { type: Boolean, reflect: true, attribute: 'emailSent' },

      layout: { type: String, reflect: true },
      cognitoType: { type: String },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-login-controller-container {
          display: flex;
          width: 100%;
          height: 100%;
          position: relative;
        }

        .neb-login-controller-container-logo {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 50%;

          background: url(../src/neb-login-screen.png);
          -o-background-size: cover;
          background-size: cover;
          -moz-background-size: cover;
        }

        .neb-login-controller-icon-logo {
          display: flex;
          align-self: center;
          justify-self: center;
          width: 560px;
          height: 50%;
          max-width: 100%;

          padding: 20px;
          stroke: ${CSS_COLOR_HIGHLIGHT};
        }

        .neb-login-controller-spacer {
          height: 50%;
        }

        .neb-login-controller-container-page {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .neb-login-controller-container-render {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          background-color: ${CSS_COLOR_WHITE};
          overflow-y: auto;
        }

        .neb-login-controller-menu-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 400px;
        }

        .neb-login-controller-select-tenant-label {
          display: flex;
          align-self: flex-start;
          height: 20px;
          margin-bottom: ${CSS_SPACING};

          color: ${CSS_COLOR_BLACK};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: 18px;
        }

        .neb-login-controller-tenant-menu {
          position: static;
          flex: 4 0 0;
          z-index: 11;
        }

        .neb-login-controller-logout {
          align-self: baseline;
          margin-top: 36px;
        }

        .neb-login-controller-login {
          display: flex;
          align-items: space-between;
          width: 400px;
          height: 250px;

          margin: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
          border-radius: 5px;
        }

        .neb-login-controller-password {
          width: 400px;
          padding: 30px;
          background-color: white;
          border-radius: 5px;
        }

        .neb-login-controller-change-password {
          max-height: 50%;
          max-width: 50%;
          width: 100%;
          flex: 1 0 0;
        }

        .neb-login-controller-forgot-password-form {
          display: flex;
          align-items: center;
          width: 400px;
          height: fit-content;

          margin: ${CSS_SPACING};

          background-color: ${CSS_COLOR_WHITE};
        }

        .neb-login-controller-container-links {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: fit-content;

          margin: ${CSS_SPACING};
          margin-bottom: 80px;
          text-align: center;
          background: ${CSS_COLOR_WHITE};
        }

        .neb-login-controller-container-links > a {
          padding: 0 10px;
          text-decoration: underline;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        [layout='small'] .neb-login-controller-container {
          flex-direction: column;
        }

        [layout='small'] .neb-login-controller-container-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 20%;
        }

        [layout='small'][loginPage='changePassword']
          .neb-login-controller-container-logo {
          height: 20%;
        }

        [layout='small'][loginPage='default']
          .neb-login-controller-container-logo {
          height: 80%;
        }

        [layout='small'][loginPage='forgotPassword']:not([emailSent])
          .neb-login-controller-container-logo {
          height: 80%;
        }

        [layout='small'] .neb-login-controller-icon-logo {
          width: 100%;
          height: 100px;
          padding: 30px;
        }

        [layout='small'] .neb-login-controller-container-page {
          width: 100%;
        }

        [layout='small'][loginPage='default']
          .neb-login-controller-container-render {
          justify-content: flex-start;

          margin-top: ${CSS_SPACING};
        }

        [layout='small'][loginPage='tenantSelection']
          .neb-login-controller-container-render {
          justify-content: center;

          margin-top: 0;
        }

        [layout='small'][loginPage='noTenantUser']
          .neb-login-controller-container-render {
          justify-content: center;

          margin-top: 0;
        }

        [layout='small'] .neb-login-controller-menu-container {
          align-self: center;
          width: 100%;
          padding: ${CSS_SPACING};
        }

        [layout='small'] .neb-login-controller-login {
          width: 320px;
          max-height: 250px;
        }

        [layout='small'] .password {
          width: 100%;
        }

        [layout='small'] .neb-login-controller-change-password {
          max-height: 100%;
          max-width: 100%;
        }

        [layout='small'] .neb-login-controller-forgot-password-form {
          width: 100%;
          height: 100%;

          margin: 0;
          padding: ${CSS_SPACING};
        }

        [layout='small'] .neb-login-controller-container-links {
          margin-top: 25px;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initConfig();
  }

  __initState() {
    this.__email = '';
    this.__emailSent = false;
    this.__errorText = '';
    this.__session = {};
    this.__loading = false;
    this.__authenticated = false;
    this.__invalidError = false;
    this.__renewing = true;
    this.__requiresTenantSelection = false;
    this.__recommendPasswordChange = false;
    this.__userHasNoPractice = false;
    this.__availableTenants = [];
    this.__forgetPassword = false;
    this.__loginPage = DEFAULT_PAGE;
    this.__byPassEmail = false;
    this.__firstOrResetLogin = false;

    this.layout = false;
    this.cognitoType = 'practice';
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => {
        this.__emailSent = false;
        this.__forgetPassword = false;
        this.__firstOrResetLogin = true;
        this.__loading = false;
        this.__loginPage = DEFAULT_PAGE;
      },
      emailSent: value => {
        this.__emailSent = value;
      },
      resetPasswordChange: () => {
        resetPracticeUserMeta(store.getState().session.item.id);

        this.__postAuth();
      },
      passwordResent: async email => {
        await resendUserInvitationByEmail(email);
        return this.__handlers.cancel();
      },

      passwordChanged: async pw => {
        this.__invalidError = false;
        let passwordChanged;

        try {
          passwordChanged = !!(await this.__session.changePassword(
            pw,
            this.__email,
          ));

          if (passwordChanged) {
            this.__firstOrResetLogin = true;
            await this.__handleLogin(this.__email, pw);
            await resetPracticeUserMeta(store.getState().session.item.id);

            this.__postAuth();

            return store.dispatch(openSuccess(TEXT_SUCCESS_TOASTER_CONFIRM));
          }

          this.__invalidError = !passwordChanged;
        } catch (err) {
          console.error(err);
          this.__logout();
          store.dispatch(
            openError('Error occurred. Please try logging in again.'),
          );
        }

        return undefined;
      },

      forgotPassword: () => {
        this.__forgetPassword = true;
      },
      loginAfterForgotPw: async (email, pw) => {
        this.__forgetPassword = false;
        this.__firstOrResetLogin = true;
        this.__emailSent = false;
        await this.__handleLogin(email, pw);
        await resetPracticeUserMeta(store.getState().session.item.id);

        this.__postAuth();
      },
      login: (user, pw) => this.__handleLogin(user, pw),
      logOut: () => this.__logout(),
      renderTenant: (item, _index) => html`
        <neb-tenant-menu-item
          .model="${{ ...item, apiOverride: this.__apiOverride }}"
        ></neb-tenant-menu-item>
      `,
      selectTenant: async index => {
        const tenantId = this.__availableTenants[index].id;
        this.__session.tenantId = tenantId;
        await store.dispatch(
          selectTenant({
            tenantId,
            item: this.__session,
            selectedTenant: this.__availableTenants[index],
            availableTenants: this.__availableTenants,
          }),
        );

        storeSessionToLocalStorage();
      },
    };
  }

  __initConfig() {
    this.__pages = {
      [DEFAULT_PAGE]: {
        render: () => this.__renderLoginPage(),
      },
      [FORGOT_PASSWORD_PAGE]: {
        render: () => this.__renderForgotPassword(),
      },
      [PASSWORD_FORM]: {
        render: () => this.__renderPasswordForm(),
      },
      [CHANGE_PASSWORD_PAGE]: {
        render: () => this.__renderChangePassword(),
      },
      [TENANT_SELECTION_PAGE]: {
        render: () => this.__renderTenantSelection(),
      },
      [NO_TENANT_USER_PAGE]: {
        render: () => this.__renderNoTenantUser(),
      },
    };

    this.__passwordChangeAnswer = {
      true: () => {
        this.__loginPage = CHANGE_PASSWORD_PAGE;
      },
      false: () => {
        resetPracticeUserMeta(store.getState().session.item.id);
        return this.__postAuth();
      },
      undefined: () => this.__postAuth(),
    };
  }

  __login(user, pw) {
    if (!user || !pw) {
      this.__errorText = ERROR_MISSING_INFO;
      return undefined;
    }

    this.__email = user;

    return store.dispatch(
      authenticate({
        clientId: user,
        secret: pw,
        type: this.cognitoType,
      }),
    );
  }

  async __handleLogin(email, pw) {
    await this.__login(email, pw);
    storeSessionToLocalStorage();
  }

  __postAuth() {
    storeSessionToLocalStorage();

    return store.dispatch(postAuthentication(store.getState().session.item));
  }

  connectedCallback() {
    super.connectedCallback();
    checkSession(this.cognitoType);
  }

  async __promptRecommendPassword() {
    const result = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      RECOMMEND_PASSWORD_CHANGE,
    );
    return this.__passwordChangeAnswer[result]();
  }

  __setMinHeight(length) {
    return length <= MAX_ITEMS
      ? `${length * ITEM_HEIGHT}px`
      : `${MAX_ITEMS * ITEM_HEIGHT + 16}px`;
  }

  __logout() {
    performLogout();
    this.__loginPage = DEFAULT_PAGE;
  }

  _stateChanged({
    apiOverride = {},
    session: {
      isAuthenticating,
      isAuthenticated,
      requiresTenantSelection,
      recommendPasswordChange,
      userHasNoPractice,
      availableTenants,
      item: session,
      message,
      isRenewing,
    },
  }) {
    this.__apiOverride = apiOverride.value;
    this.__loading = isAuthenticating;
    this.__renewing = isRenewing;
    this.__authenticated = isAuthenticated;
    this.__session = session || {};
    this.__errorText = message;
    this.__requiresTenantSelection = requiresTenantSelection;
    this.__recommendPasswordChange = recommendPasswordChange;
    this.__userHasNoPractice = userHasNoPractice;
    this.__availableTenants = availableTenants;
  }

  update(changed) {
    if (changed.has('__authenticated') && this.__authenticated) {
      storeSessionToLocalStorage();
    }

    if (changed.has('__userHasNoPractice') && this.__userHasNoPractice) {
      this.__loginPage = NO_TENANT_USER_PAGE;
    }

    if (
      changed.has('__requiresTenantSelection') &&
      this.__requiresTenantSelection
    ) {
      this.__loginPage = TENANT_SELECTION_PAGE;
    }

    if (changed.has('__session') && this.__session.changePassword) {
      this.__loginPage = PASSWORD_FORM;
    }

    if (changed.has('__session') && this.__session.expiredPassword) {
      this.__byPassEmail = true;
      this.__loginPage = FORGOT_PASSWORD_PAGE;
    }

    if (changed.has('__forgetPassword') && this.__forgetPassword) {
      this.__byPassEmail = false;
      this.__loginPage = FORGOT_PASSWORD_PAGE;
    }

    super.update(changed);
  }

  updated(changed) {
    if (
      changed.has('__recommendPasswordChange') &&
      this.__recommendPasswordChange &&
      !this.__firstOrResetLogin
    ) {
      return this.__promptRecommendPassword();
    }

    return undefined;
  }

  __renderLogo() {
    return html`
      <div class="neb-login-controller-container-logo">
        <neb-icon
          class="icon neb-login-controller-icon-logo"
          icon="neb:logo"
        ></neb-icon>

        ${this.layout !== 'small'
          ? html` <div class="neb-login-controller-spacer"></div> `
          : ''}
      </div>
    `;
  }

  __renderPage() {
    return html`
      <div class="neb-login-controller-container-page">
        <div class="neb-login-controller-container-render">
          ${this.__pages[this.__loginPage].render()}
        </div>

        <div class="neb-login-controller-container-links">
          <a href=" https://chirotouch.statuspage.io" target="_blank">Status</a>
          |
          <a href=" https://chirotouchcommunity.force.com/cloud" target="_blank"
            >Community</a
          >
        </div>
      </div>
    `;
  }

  __renderLoginPage() {
    return html`
      <neb-login-page
        id="${ELEMENTS.loginPage.id}"
        class="neb-login-controller-login"
        .error="${this.__errorText}"
        .layout="${this.layout}"
        .onForgetPassword="${this.__handlers.forgotPassword}"
        .onLogin="${this.__handlers.login}"
        ?loading="${this.__loading}"
      ></neb-login-page>
    `;
  }

  __renderChangePassword() {
    return html`
      <neb-change-password
        id="${ELEMENTS.changePasswordForm.id}"
        class="neb-login-controller-change-password"
        .email="${this.__email}"
        .small="${this.layout === 'small'}"
        .onPasswordChanged="${this.__handlers.resetPasswordChange}"
      ></neb-change-password>
    `;
  }

  __renderPasswordForm() {
    return html`
      <neb-password-form
        id="${ELEMENTS.passwordForm.id}"
        class="neb-login-controller-password"
        .email="${this.__email}"
        .repeatedPassword="${this.__invalidError}"
        .onChangePassword="${this.__handlers.passwordChanged}"
      ></neb-password-form>
    `;
  }

  __renderForgotPassword() {
    return html`
      <neb-forgot-password
        id="${ELEMENTS.forgotPasswordForm.id}"
        class="neb-login-controller-forgot-password-form"
        .byPassEmail="${this.__byPassEmail}"
        .email="${this.__email}"
        .type="${this.cognitoType}"
        .onCancel="${this.__handlers.cancel}"
        .onEmailSent="${this.__handlers.emailSent}"
        .onPasswordChanged="${this.__handlers.loginAfterForgotPw}"
        .onPasswordResent="${this.__handlers.passwordResent}"
      ></neb-forgot-password>
    `;
  }

  __renderNoTenantUser() {
    return html`
      <neb-no-practice
        id="${ELEMENTS.noTenantUser.id}"
        class="no-tenant-user"
        .small="${this.layout === 'small'}"
      ></neb-no-practice>
    `;
  }

  __renderTenantSelection() {
    return html`
      <div class="neb-login-controller-menu-container">
        <div class="neb-login-controller-select-tenant-label">
          Select Practice
        </div>

        <neb-menu
          id="${ELEMENTS.tenantSelection.id}"
          class="neb-login-controller-tenant-menu"
          style="min-height:${this.__setMinHeight(
            this.__availableTenants.length,
          )}"
          .items="${this.__availableTenants}"
          .itemHeight="${ITEM_HEIGHT}"
          .maxVisibleItems="${MAX_ITEMS}"
          .onRenderItem="${this.__handlers.renderTenant}"
          .onChange="${this.__handlers.selectTenant}"
          forceDown
          open
        >
        </neb-menu>

        <neb-button
          id="${ELEMENTS.logoutButton.id}"
          class="neb-login-controller-logout"
          label="Log Out"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.__handlers.logOut}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return this.__renewing || this.__authenticated
      ? html``
      : html`
          <div class="neb-login-controller-container">
            ${this.__renderLogo()} ${this.__renderPage()}
          </div>
        `;
  }
}

customElements.define('neb-login-controller', NebLoginController);
