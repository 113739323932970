import '../../../neb-pagination';
import '../../../neb-text';
import '../../../tables/neb-table-ledger-activity-purchases';
import '../../../../../../../src/components/filters/neb-filters-ledger-purchases';

import { navigate } from '@neb/router';
import { html, css, LitElement, unsafeCSS } from 'lit';
import moment from 'moment-timezone';

import * as ledgerActivityPurchasesApi from '../../../../../../../src/api-clients/ledger-activity-purchases';
import { baseStyles, layoutStyles } from '../../../../../../../src/styles';
import { NO_ITEMS_INITIAL_LOAD } from '../../../../../../../src/utils/user-message';
import { getLedgerInvoiceItems } from '../../../../../../neb-api-client/src/invoice-api-client';
import { getLineItemDetails } from '../../../../../../neb-api-client/src/ledger/line-items';
import { getElectronicPayment } from '../../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import {
  printReceipt,
  getPaymentDetail,
} from '../../../../../../neb-api-client/src/payments-api-client';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../../neb-utils/formatters';
import { CODE_TYPE } from '../../../../../../neb-utils/neb-ledger-util';
import { printPdf } from '../../../../../../neb-utils/neb-pdf-print-util';
import { NEBULA_REFRESH_EVENT } from '../../../../../../neb-utils/neb-refresh';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../../neb-utils/services/fetch';
import { OVERLAY_KEYS, openOverlay } from '../../../../utils/overlay-constants';
import { SORT_DIR } from '../../../tables/neb-table';

export const MENU_HEIGHT = 416;

export const PATIENT_HEADER_TEXT =
  'Review patient purchases, collect payment, and view receipts and invoices.';

export const PRACTICE_HEADER_TEXT =
  'Review invoice and billing status for purchases and perform updates.';

export const ELEMENTS = {
  content: { id: 'content' },
  header: { id: 'header' },
  filters: { id: 'filters' },
  table: { id: 'table' },
  pagination: { id: 'pagination-control' },
};

export const TABLE_CONFIG_ACTION = [
  {
    key: 'lineItemId',
    label: '',
    flex: css`1 0 0`,
  },
];

export const TABLE_CONFIG_CONTENT = [
  {
    key: 'transactionDate',
    label: 'Date',
    flex: css`2 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`3 0 0`,
  },
  {
    key: 'purchase',
    label: 'Purchase',
    flex: css`5 0 0`,
  },
  {
    key: 'details',
    label: 'Details',
    flex: css`3 0 0`,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`2 0 0`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`2 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`2 0 0`,
    formatter: v => centsToCurrency(v),
  },
];

export function tableConfig(patient) {
  return [
    ...TABLE_CONFIG_ACTION,
    ...(patient
      ? []
      : [
          {
            key: 'patient',
            label: 'Patient',
            flex: css`6 0 0`,
          },
        ]),
    ...TABLE_CONFIG_CONTENT,
  ];
}

class NebLedgerActivityPurchasesPage extends LitElement {
  static get properties() {
    return {
      patient: Object,
      layout: String,
      __patients: Array,
      __state: Object,
      __marginBottom: Number,
      __hasFitInvoiceOverlayPerformanceFF: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          padding-top: ${CSS_SPACING};
        }

        .pagination {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__state = FetchService.createModel();
    this.__marginBottom = 0;
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.layout = '';
    this.patient = null;
  }

  __initHandlers() {
    this.handlers = {
      pageChanged: index => this.__fetchService.setPageIndex(index),
      refresh: () => this.__fetchService.setPageIndex(0),
      clickLink: (key, item) => this.openLinkOverlay(key, item),
      viewCharges: item => this.__viewCharges(item),
      changeState: state => {
        this.__state = state;
      },
      collectPayment: async item => {
        const lineItemIds = { lineItemIds: [item.lineItemId] };
        const [lineItem] = await getLineItemDetails({}, lineItemIds);

        await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
          patientId: item.patient.id,
          patient: item.patient,
          autoAllocate: { lineItem },
          chargeInfo: {
            invoiceId: item.invoiceId,
            amount: item.balance,
            paymentType: { label: '' },
            alwaysShowActionBar: true,
            allowEditPaymentTypeAndAmount: true,
            hideVoidRefundButton: true,
          },
        });
      },
      viewReceipt: async item => {
        const ids = item.patientPaymentIds.split(',');

        const payments = ids.map(async id => {
          const detail = await getPaymentDetail(id);

          if (detail.electronicPaymentId) {
            const ePayment = await getElectronicPayment(
              detail.electronicPaymentId,
            );

            if (ePayment.receipt) {
              detail.receipt = {
                text: JSON.parse(ePayment.receipt).text.customer_receipt,
              };
            }
          }

          return detail;
        });

        const resolvedPayments = await Promise.all(payments);

        printPdf(printReceipt({ patientPayments: resolvedPayments }));
      },
      filter: model => {
        this.__hasAppliedFilters = true;

        const invoice = model.notInvoiced ? 'NOT_INVOICED' : model.invoice;
        const purchaseType = model.purchaseType || undefined;

        const patientId = model.patientId || undefined;

        const dateFrom = model.date.from
          ? model.date.from.format('YYYY-MM-DD')
          : undefined;

        const dateTo = model.date.to
          ? model.date.to.format('YYYY-MM-DD')
          : undefined;

        const balanceFrom =
          model.balance.min !== null ? model.balance.min : undefined;

        const balanceTo =
          model.balance.max !== null ? model.balance.max : undefined;

        this.__fetchService.setQuery('invoice', invoice);
        this.__fetchService.setQuery('purchaseType', purchaseType);
        this.__fetchService.setQuery('patientId', patientId);
        this.__fetchService.setQuery('dateFrom', dateFrom);
        this.__fetchService.setQuery('dateTo', dateTo);
        this.__fetchService.setQuery('balanceFrom', balanceFrom);
        this.__fetchService.setQuery('balanceTo', balanceTo);
        this.__fetchService.setPageIndex(0);
      },
    };
  }

  __initServices() {
    const client = query => {
      if (this.__isInitialLoadDisabled()) {
        return EMPTY_RESPONSE;
      }

      const patientId = this.patient ? this.patient.id : '';

      return ledgerActivityPurchasesApi.fetchMany(patientId, query);
    };

    if (this.__fetchService) {
      this.__fetchService.cancel();
    }

    this.__fetchService = new FetchService(
      { onChange: this.handlers.changeState },
      client,
    );
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    super.connectedCallback();

    window.addEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patient?.id
    );
  }

  __calculateMarginBottom() {
    const { length } = this.__fetchService.__items;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    } else if (length > 2) {
      this.__marginBottom = 0;
    }
  }

  async __viewCharges(item) {
    const patient = {
      ...item.patient,
      name: objToName(item.patient.name, DEFAULT_NAME_OPTS),
    };

    const lineItemIds = item.invoiceId
      ? (await getLedgerInvoiceItems(item.invoiceId)).data.map(li => li.id)
      : [item.lineItemId];

    const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
      ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
      : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

    await openOverlay(overlayKey, {
      selectedIds: [],
      lineItemIds,
      patient,
    });

    this.__fetchService.fetch();
  }

  async __editPackage(item) {
    const resultItem = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
      item: {
        id: item.patientPackageId,
        patientId: this.patient ? this.patient.id : '',
      },
    });

    if (resultItem) {
      this.__fetchService.fetch();
    }
  }

  openLinkOverlay(key, item) {
    const isCodeCharge = item.codeType === CODE_TYPE.CODE_CHARGE;
    const isCharge = item.codeType === CODE_TYPE.CHARGE;
    const isPkgOrSub = item.code === 'Pkg' || item.code === 'Sub';
    const notPkgOrSub = item.code !== 'Pkg' && item.code !== 'Sub';

    switch (key) {
      case 'patient':
        navigate(`/patients/${item.patient.id}/ledger/activity`);
        break;

      case 'invoice':
        if ((isCodeCharge || isCharge) && notPkgOrSub) {
          this.__viewCharges(item);
        } else if (isCodeCharge && isPkgOrSub) {
          this.__viewCharges(item);
        }
        break;

      default:
        if (isCodeCharge && isPkgOrSub) {
          this.__editPackage(item);
        }

        break;
    }
  }

  firstUpdated() {
    this.__fetchService.setQuery('sortField', 'transactionDate');
    this.__fetchService.setQuery('sortDir', SORT_DIR.DESC);
    this.__fetchService.fetch();
  }

  updated(changedProps) {
    if (changedProps.has('__state')) {
      this.__calculateMarginBottom();
    }

    super.updated(changedProps);
  }

  update(changed) {
    if (changed.has('patient')) {
      this.__fetchService.fetch();
    }

    super.update(changed);
  }

  renderPagination() {
    return this.__state.pageCount > 1
      ? html`
          <div class="grid grid-auto-right pagination">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              .pageCount="${this.__state.pageCount}"
              .currentPage="${this.__state.pageIndex}"
              .onPageChanged="${this.handlers.pageChanged}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  render() {
    const headerLabel = this.patient
      ? PATIENT_HEADER_TEXT
      : PRACTICE_HEADER_TEXT;

    let emptyMessage = this.patient
      ? 'There are no purchases for this patient.'
      : 'There are no purchases.';

    if (this.__isInitialLoadDisabled()) {
      emptyMessage = NO_ITEMS_INITIAL_LOAD;
    }

    return html`
      <div class="layout">
        <neb-text id="${ELEMENTS.header.id}" class="pad"
          >${headerLabel}</neb-text
        >

        <neb-filters-ledger-purchases
          id="${ELEMENTS.filters.id}"
          .onApply="${this.handlers.filter}"
          ?enablePatient="${!this.patient}"
        ></neb-filters-ledger-purchases>

        <div
          id="${ELEMENTS.content.id}"
          style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
        >
          <neb-table-ledger-activity-purchases
            id="${ELEMENTS.table.id}"
            .name="table-purchases"
            .model="${this.__state.pageItems}"
            .layout="${this.layout}"
            .emptyMessage="${emptyMessage}"
            .config="${tableConfig(this.patient)}"
            .onViewCharges="${this.handlers.viewCharges}"
            .onCollectPayment="${this.handlers.collectPayment}"
            .onViewReceipt="${this.handlers.viewReceipt}"
            .onLink="${this.handlers.clickLink}"
          ></neb-table-ledger-activity-purchases>

          ${this.renderPagination()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-ledger-activity-purchases-page',
  NebLedgerActivityPurchasesPage,
);
