import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color-advanced';
import { isRequired } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';

import {
  OPTIONS_SLOT_AMOUNT,
  OPTIONS_INTERVAL_AMOUNT,
  segmentExtent,
  intersectSegment,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-availability-item';
import Duration from '../../../../packages/neb-lit-components/src/components/field-groups/neb-duration';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { PERIOD } from '../../../../packages/neb-utils/utils';
import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../styles';

export const ELEMENTS = {
  startDuration: { id: 'duration-start' },
  endDuration: { id: 'duration-end' },
  removeButton: { id: 'remove-button' },
  multiBookingCount: { id: 'select-booking-count' },
  timeSlotInterval: { id: 'select-slot-interval' },
  appointmentTypes: { id: 'select-appointent-types' },
  pickerColor: { id: 'picker-color' },
  pickerColorAdvanced: { id: 'picker-color-advanced' },
};

export default class RoomAvailabilityItem extends LitElement {
  static get properties() {
    return {
      name: String,
      label: String,
      model: Object,
      errors: Object,
      appointmentTypes: Array,
    };
  }

  static get styles() {
    return [
      css`
        .container-duration {
          display: grid;
          grid-column: 2 / span 8;
          grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
          grid-template-rows: 1fr;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .button-container {
          display: flex;
          justify-content: center;
          padding-top: 12px;
        }

        .color-label {
          font-size: 12px;
          grid-column: span 2;
        }

        .color-container {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: auto 1fr;
          grid-column-gap: ${CSS_SPACING};
          align-items: flex-start;
        }

        .dotted-line {
          height: 1px;
          border-bottom: 1px dashed ${CSS_COLOR_GREY_2};
          grid-column: 1 / span 10;
          margin: 10px 0px;
        }

        .padding-top {
          padding-top: 11px;
        }

        .justify-end {
          justify-self: end;
        }

        .span-2 {
          grid-column: 1 / span 2;
          padding-bottom: 10px;
          width: fit-content;
        }

        .field {
          width: fit-content;
        }

        .field-picker-color {
          height: 36px;
        }

        .spacer {
          height: 40px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      start: Duration.createModel(8, 0, PERIOD.AM),
      end: Duration.createModel(6, 0, PERIOD.PM),
      slotInterval: 1,
      multiBookingCount: 1,
      allTypes: true,
      appointmentTypes: [],
      color: '#ffffff',
    };
  }

  static createSelectors(appointmentTypes) {
    return {
      children: {
        start: Duration.createSelectors([segmentExtent(), intersectSegment()]),
        end: Duration.createSelectors([segmentExtent(), intersectSegment()]),
        slotInterval: selectors.select(
          OPTIONS_INTERVAL_AMOUNT,
          OPTIONS_INTERVAL_AMOUNT[0],
          { validators: [isRequired()] },
        ),
        multiBookingCount: selectors.select(
          OPTIONS_SLOT_AMOUNT,
          OPTIONS_SLOT_AMOUNT[0],
          { validators: [isRequired()] },
        ),
        appointmentTypes: {
          ...selectors.multiSelect(appointmentTypes, appointmentTypes),
          format: v => {
            if (!v.length) return [];
            if (v[0].allTypes) return appointmentTypes;

            const selectedItems = v.map(type =>
              appointmentTypes.find(
                apptType => apptType.data.id === type.appointmentTypeId,
              ),
            );

            const itemsAreLoaded = selectedItems.every(
              item => item !== undefined,
            );

            return itemsAreLoaded ? selectedItems : appointmentTypes;
          },
          unformat: v =>
            v.length === appointmentTypes.length
              ? []
              : v.map(val => val.data.id),
          validators: [isRequired()],
        },
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.label = '';
    this.model = RoomAvailabilityItem.createModel();
    this.enabled = true;

    this.errors = {
      start: '',
      end: '',
      slotInterval: null,
      multiBookingCount: null,
      appointmentTypes: [],
      color: '',
    };

    this.appointmentTypes = [];

    this.onChange = () => {};

    this.onChangeTypes = () => {};

    this.onRemove = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => {
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
      changeTypes: e => {
        this.onChangeTypes({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
      remove: () => {
        this.onRemove(this.name);
      },
    };
  }

  render() {
    return html`
      <div class="container-duration padding-top">
        <span class="padding-top justify-end">Start</span>

        <neb-duration
          id="${ELEMENTS.startDuration.id}"
          helper=" "
          name="start"
          .model="${this.model.start}"
          .error="${this.errors.start}"
          .onChange="${this.__handlers.change}"
        ></neb-duration>

        <span class="padding-top justify-end">End</span>

        <neb-duration
          id="${ELEMENTS.endDuration.id}"
          helper=" "
          name="end"
          .model="${this.model.end}"
          .error="${this.errors.end}"
          .onChange="${this.__handlers.change}"
        ></neb-duration>

        <div class="button-container">
          <neb-button-icon
            id="${ELEMENTS.removeButton.id}"
            icon="neb:minus"
            @click="${this.__handlers.remove}"
          ></neb-button-icon>
        </div>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.multiBookingCount.id}"
          label="Bookings per Time Slot"
          name="multiBookingCount"
          helper="Required"
          .items="${OPTIONS_SLOT_AMOUNT}"
          .value="${this.model.multiBookingCount}"
          .error="${this.errors.multiBookingCount}"
          .onChange="${this.__handlers.change}"
        ></neb-select>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.timeSlotInterval.id}"
          label="Time Slot Intervals"
          name="slotInterval"
          helper="Required"
          .items="${OPTIONS_INTERVAL_AMOUNT}"
          .value="${this.model.slotInterval}"
          .error="${this.errors.slotInterval}"
          .onChange="${this.__handlers.change}"
        ></neb-select>

        <div class="spacer"></div>

        <div class="spacer"></div>

        <neb-select
          id="${ELEMENTS.appointmentTypes.id}"
          name="appointmentTypes"
          label="Appointment Types"
          allLabel="Appointment Types"
          helper="Required"
          .items="${this.appointmentTypes}"
          .value="${this.model.appointmentTypes}"
          .error="${this.errors.appointmentTypes}"
          .onChange="${this.__handlers.changeTypes}"
          multiSelect
        ></neb-select>

        <div class="spacer"></div>

        <div class="color-container">
          <span class="color-label">Background Colors</span>

          <neb-picker-color
            id="${ELEMENTS.pickerColor.id}"
            class="field"
            name="color"
            .value="${this.model.color}"
            .errors="${this.model.color}"
            .onChange="${this.__handlers.change}"
          ></neb-picker-color>

          <neb-picker-color-advanced
            id="${ELEMENTS.pickerColorAdvanced.id}"
            class="field field-picker-color"
            name="color"
            .value="${this.model.color}"
            .errors="${this.model.color}"
            .onChange="${this.__handlers.change}"
          ></neb-picker-color-advanced>
        </div>
      </div>
      <div class="dotted-line"></div>
    `;
  }
}

window.customElements.define(
  'neb-room-availability-item',
  RoomAvailabilityItem,
);
