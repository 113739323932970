import { FETCH_PRACTICE_INFORMATION } from '../actions/practice-information';

function handleFetchAction(state, action) {
  switch (action.status) {
    case 'success':
      return {
        ...state,
        isFetching: false,
        fetchingError: null,
        item: action.response.data[0],
      };

    case 'failed':
      return { ...state, isFetching: false, fetchingError: action.error };

    default:
      return { ...state, isFetching: true, fetchingError: null };
  }
}

export const practiceInformation = (
  state = {
    isFetching: false,
    fetchingError: null,
    isUpdating: false,
    updatingError: null,
    item: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_PRACTICE_INFORMATION:
      return handleFetchAction(state, action);

    default:
      return state;
  }
};
