import { css, html } from 'lit';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { required } from '../../../../packages/neb-utils/validators';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  textFieldName: { id: 'textfield-name' },
  textFieldTenantId: { id: 'textfield-tenantId' },
};

class NebFormNewConversion extends NebForm {
  static createModel() {
    return {
      name: '',
      tenantId: '',
    };
  }

  createSelectors() {
    return {
      children: {
        tenantId: { validators: [required()] },
        name: { validators: [required()] },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textFieldTenantId.id}"
        name="tenantId"
        label="Tenant ID"
        helper="Required"
        .value="${this.state.tenantId}"
        .error="${this.errors.tenantId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
      <neb-textfield
        id="${ELEMENTS.textFieldName.id}"
        name="name"
        label="Name"
        helper="Required"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }
}

customElements.define('neb-form-new-conversion', NebFormNewConversion);
