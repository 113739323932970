import '../guarantor/neb-guarantor-form-controller';
import '../neb-popup-header';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { openDirtyPopup } from '../../../../neb-popup';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  formController: {
    id: 'form',
  },
  header: {
    id: 'header',
  },
  description: {
    id: 'description',
    text: 'Choose a guarantor type and proceed to the guarantor details',
  },
};

export const ADD_HEADER = 'Add Patient Guarantor';
export const UPDATE_HEADER = 'Update Patient Guarantor';

class NebOverlayGuarantor extends Overlay {
  static get properties() {
    return {
      showBackButton: Boolean,
      __selection: String,
      __guarantor: Object,
    };
  }

  initState() {
    super.initState();
    this.showBackButton = false;
    this.model = {
      guarantor: {},
    };

    this.__selection = '';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      editGuarantor: guarantor => {
        this.__guarantor = guarantor;
      },
      selectType: selection => {
        this.__selection = selection;
        this.showBackButton = true;
      },
      back: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) {
            this.isDirty = false;
            this.__selection = '';
            this.showBackButton = false;
          }
        } else {
          this.__selection = '';
          this.showBackButton = false;
        }
      },
    };
  }

  update(changed) {
    if (changed.has('model')) {
      if (this.model.item) {
        this.__guarantor = { ...this.model.item };
        this.__selection = this.__guarantor.person ? 'person' : 'organization';
      } else {
        this.__guarantor = {
          ...this.__guarantor,
          patientId: this.model.context.patientId,
        };
      }
    }

    super.update(changed);
  }

  dismiss(result) {
    if (result) return super.dismiss(result);

    if (!equal(this.__guarantor, this.model.item)) {
      return super.dismiss(this.__guarantor);
    }
    return super.dismiss();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 700px;
          flex-flow: column nowrap;
          overflow-y: auto;
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px;
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  __getTitle() {
    return this.model.item ? UPDATE_HEADER : ADD_HEADER;
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          .title="${this.__getTitle()}"
          .onCancel="${this.handlers.dismiss}"
          .onBack="${this.handlers.back}"
          ?showBackButton="${this.showBackButton}"
          showCancelButton
        ></neb-popup-header>

        <div id="${ELEMENTS.description.id}">
          ${this.model.item ? '' : ELEMENTS.description.text}
        </div>
      </div>

      <neb-guarantor-form-controller
        id="${ELEMENTS.formController.id}"
        .selection="${this.__selection}"
        .guarantor="${this.__guarantor}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
        .onDirtyChange="${this.handlers.dirty}"
        .onGuarantorEdit="${this.handlers.editGuarantor}"
        .onTypeSelection="${this.handlers.selectType}"
        ?dirty="${this.isDirty}"
      ></neb-guarantor-form-controller>
    `;
  }
}

customElements.define('neb-overlay-guarantor', NebOverlayGuarantor);
