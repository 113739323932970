import '../../neb-lit-components/src/components/neb-button';

import { html, css } from 'lit';

import {
  CSS_BORDER_GREY_2,
  CSS_FONT_FAMILY,
} from '../../neb-styles/neb-variables';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  textarea: {
    id: 'textarea',
  },
  actionButtons: {
    id: 'action-buttons',
  },
};

class NebPopupMacrosFreeTextEntry extends NebPopupMacros {
  static get styles() {
    return [
      super.styles,
      css`
        .textarea {
          flex: 1 0 0;
          font-size: 14px;
          outline: none;
          padding: 8px;
          resize: none;
          width: 100%;
          border: ${CSS_BORDER_GREY_2};
          margin: 30px 0 60px;
          font-family: ${CSS_FONT_FAMILY};
          border-radius: 4px;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      change: ({ currentTarget }) => {
        this.answer = currentTarget.value;
      },
    };
  }

  renderPreview() {
    return '';
  }

  renderMacroContent() {
    return html`
      <textarea
        id="${ELEMENTS.textarea.id}"
        class="textarea"
        @input="${this.handlers.change}"
        autofocus
      >
${this.answer}</textarea
      >
    `;
  }
}

customElements.define(
  'neb-popup-macros-free-text-entry',
  NebPopupMacrosFreeTextEntry,
);
