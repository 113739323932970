import '../../../../src/components/misc/neb-icon';
import './inputs/neb-menu';
import './neb-profile-photo';

import { LitElement, html, css } from 'lit';

import { getImage } from '../../../neb-api-client/src/image-api-client';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_SECONDARY_NAV_HEIGHT,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  labelName: {
    id: 'label-name',
  },
  labelFirstName: {
    id: 'label-first-name',
  },
  labelLastInitial: {
    id: 'label-last-initial',
  },
  iconChevron: {
    id: 'icon-chevron',
  },
  iconUser: {
    id: 'icon-user',
  },
  menu: {
    id: 'menu',
  },
  userPicture: {
    id: 'user-picture',
  },
};

class NebUserMenu extends LitElement {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __profilePic: {
        type: String,
      },
      items: {
        type: Array,
      },
      layout: {
        type: String,
        reflect: true,
      },
      model: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__focused = false;
    this.__open = false;
    this.__profilePic = '';
    this.items = [];
    this.layout = '';
    this.model = null;

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__open = false;
        this.__focused = false;
      },
      focus: () => {
        this.__focused = true;
      },
      keydown: e => {
        if (!this.__open && (e.key === 'Enter' || e.key === ' ')) {
          setTimeout(() => {
            this.__open = true;
          });
        }

        if (e.key === 'Escape') {
          this.__open = false;
        }
      },
      select: index => {
        this.onSelect(this.items[index]);
        this.__open = false;
      },
      toggle: () => {
        this.__open = !this.__open;
      },
    };
  }

  async __setProfilePicture({ id, profileImgUrl }) {
    this.__profilePic = profileImgUrl
      ? {
          active: true,
          imgSrc: await getImage({
            id,
            imageUrl: profileImgUrl,
          }),
        }
      : null;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      await this.__setProfilePicture(this.model);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          cursor: pointer;
        }

        .container-content {
          position: relative;
          display: flex;
          align-items: center;
        }

        .user-image {
          height: 36px;
          width: 36px;
          margin-right: 10px;
        }

        .icon-user {
          height: 36px;
          width: 36px;
          margin-right: 10px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .container-label {
          display: flex;
          align-items: center;
        }

        .label-first-name {
          max-width: 125px;

          margin-right: 5px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_BODY};

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .label-last-initial {
          margin-right: ${CSS_SPACING};
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .icon-chevron {
          width: 14px;
          height: 14px;
          fill: ${CSS_COLOR_HIGHLIGHT};

          transition: 250ms ease-in-out;
        }

        :host([open]) .icon-chevron {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }

        :host([layout='small']) .icon-user,
        :host([layout='small']) .user-image {
          margin-right: 0;
        }
        :host([focused]) .icon-user,
        :host([focused]) .label-name,
        :host([focused]) .icon-chevron {
          opacity: 0.6;
        }

        .menu {
          position: absolute;
          top: ${CSS_SECONDARY_NAV_HEIGHT};
          right: 0;
          width: 237px;
        }

        :host([layout='small']) .menu {
          top: 60px;
        }
      `,
    ];
  }

  __renderProfilePic() {
    return this.__profilePic
      ? html`
          <neb-profile-photo
            id="${ELEMENTS.userPicture.id}"
            class="user-image"
            .model="${this.__profilePic}"
          ></neb-profile-photo>
        `
      : html`
          <neb-icon
            id="${ELEMENTS.iconUser.id}"
            class="icon icon-user"
            icon="neb:accountCircle"
          ></neb-icon>
        `;
  }

  __renderLabel() {
    if (!this.model.name) return '';

    const nameList = this.model.name.split(' ');
    const firstName = nameList[0];
    const lastInitial = nameList[1];

    return this.layout !== 'small'
      ? html`
          <div class="container-label">
            <div
              id="${ELEMENTS.labelFirstName.id}"
              class="label label-first-name"
            >
              ${firstName || ''}
            </div>

            <div
              id="${ELEMENTS.labelLastInitial.id}"
              class="label label-last-initial"
            >
              ${lastInitial || ''}
            </div>

            <neb-icon
              id="${ELEMENTS.iconChevron.id}"
              class="icon icon-chevron"
              icon="neb:chevron"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderMenu() {
    return html`
      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu"
        .items="${this.items}"
        .onChange="${this.__handlers.select}"
        ?open="${this.__open && this.items.length}"
      ></neb-menu>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        tabindex="0"
        @blur="${this.__handlers.blur}"
        @focus="${this.__handlers.focus}"
        @click="${this.__handlers.toggle}"
        @keydown="${this.__handlers.keydown}"
      >
        <div class="container-content">
          ${this.__renderProfilePic()} ${this.__renderLabel()}
        </div>

        ${this.__renderMenu()}
      </div>
    `;
  }
}

window.customElements.define('neb-user-menu', NebUserMenu);
