import { redirect } from '@neb/router';

export const URL_NO_ACCESS = '#/no-access';

const CONTEXT_HEADER = 'Context';
const CONTEXT_SECURITY_ERROR = 'UserSecurityAccessError';

export const shouldRedirectForUserSecurityAccessError = response =>
  response.status === 403 &&
  response.headers.get(CONTEXT_HEADER) === CONTEXT_SECURITY_ERROR;

export const handleRedirect = (hashOnRequest, currentHash) => {
  if (currentHash && currentHash.startsWith(hashOnRequest)) {
    redirect(URL_NO_ACCESS);
  }

  return Promise.resolve({
    data: [],
    count: 0,
  });
};
