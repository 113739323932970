import {
  FEATURE_FLAGS,
  hasFeatureOrBetaSync,
} from '../../../../packages/neb-utils/feature-util';
import { PLATFORM_OS } from '../mappers/platform-mapper';

const SCAN_TOOL_RELEASE = Object.freeze({
  R1: 'R1',
  R2: 'R2',
});

export const RELEASE_DATA = Object.freeze({
  R1: {
    mac: {
      version: '2.2.2',
      url: 'https://neb-scanner-prod.s3.amazonaws.com/install/v2.2.2/K1ScanServiceV2.2.2.pkg',
    },
    windows: {
      version: '2.2.1',
      url: 'https://neb-scanner-prod.s3.amazonaws.com/install/v2.2.1/K1ScanServiceV2.2.1.msi',
    },
  },
  R2: {
    mac: {
      version: '2.3.3',
      url: 'https://neb-scanner-prod.s3.amazonaws.com/install/v2.3.3/K1ScanServiceV2.3.3.pkg',
    },
    windows: {
      version: '2.3.3',
      url: 'https://neb-scanner-prod.s3.amazonaws.com/install/v2.3.3/K1ScanServiceV2.3.3.msi',
    },
  },
});

export const SCANNER_API_BASE_URL = 'http://localhost:9098';
const SCANNER_API_BASE_URL_WEB_TWAIN_SDK = 'https://local.webtwainsdk.com:9097';

export const getAlternativeBaseURL = baseUrl => {
  if (baseUrl === SCANNER_API_BASE_URL) {
    return SCANNER_API_BASE_URL_WEB_TWAIN_SDK;
  }

  return SCANNER_API_BASE_URL;
};

const getScanToolRelease = () =>
  hasFeatureOrBetaSync(FEATURE_FLAGS.LS_NEW_SCAN_VERSION)
    ? SCAN_TOOL_RELEASE.R2
    : SCAN_TOOL_RELEASE.R1;

export const getScanToolReleaseData = (
  platformOS = PLATFORM_OS.WINDOWS,
  isSafari = false,
) => {
  const releaseData = {
    release: '',
    version: '',
    downloadUrl: '',
    scanApiUrl: '',
  };

  releaseData.release = getScanToolRelease();
  const item = RELEASE_DATA[releaseData.release];
  const platformName = platformOS === PLATFORM_OS.WINDOWS ? 'windows' : 'mac';

  releaseData.version = item[platformName].version;
  releaseData.downloadUrl = item[platformName].url;

  releaseData.scanApiUrl =
    releaseData.release === SCAN_TOOL_RELEASE.R2 || isSafari
      ? SCANNER_API_BASE_URL_WEB_TWAIN_SDK
      : SCANNER_API_BASE_URL;

  return releaseData;
};
