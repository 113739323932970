import './neb-pdf-viewer';

import { LitElement, html } from 'lit';

import { PdfProcessor } from './core/neb-pdf-processor';
import * as pdfOptions from './core/neb-pdf-viewer-options';
import { PageNavigator } from './core/neb-pdf-viewer-page-navigator';
import { RenderValidator } from './core/neb-pdf-viewer-render-validator';
import { ZoomProcessor } from './core/neb-pdf-viewer-zoom-processor';

export const ELEMENTS = {
  pdfViewer: {
    id: 'pdf-viewer-container',
  },
};
export class NebPdfViewerController extends LitElement {
  static get properties() {
    return {
      src: String,
      __pageCount: Number,
      __pageNumber: Number,
      __zoom: Object,
      __isRenderPdf: Boolean,
      __size: Object,
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.src = '';
    this.__pdfDataUrl = '';
    this.__pdfProcessor = new PdfProcessor();
    this.__pageNavigator = new PageNavigator();
    this.__zoomProcessor = new ZoomProcessor();
    this.__renderValidator = new RenderValidator();
    this.__size = {
      page: {
        width: null,
        height: null,
      },
      view: {
        width: null,
        height: null,
      },
    };

    this.__reset();
  }

  __initHandlers() {
    this.__handlers = {
      renderPdf: contentInfo => this.__onRenderPdf(contentInfo),
      next: () => this.__onNext(),
      previous: () => this.__onPrevious(),
      pageNumberInput: number => this.__onPageNumberInput(number),
      zoomIn: () => this.__onZoomIn(),
      zoomOut: () => this.__onZoomOut(),
      setZoom: zoomInfo => this.__onSetZoom(zoomInfo),
      resize: sizeInfo => this.__onResize(sizeInfo),
    };
  }

  __reset() {
    this.__isRenderPdf = false;
    this.__size.page.width = null;
    this.__size.page.height = null;
    this.__pages = [];

    this.__setPageValues(0);

    this.__zoomProcessor.setZoom(pdfOptions.ZoomMode.ACTUAL_SIZE, this.__size);

    this.__updateZoom();
  }

  __setPageValues(count) {
    this.__pageNavigator.reset(count);

    this.__updatePageNumber();

    this.__pageCount = this.__pageNavigator.pageCount;
  }

  __hasPdfSource() {
    return this.__pdfDataUrl.length > 0;
  }

  async __onRenderPdf(contentInfo) {
    if (this.__hasPdfSource() && this.__pageNumber > 0) {
      const { canvas } = contentInfo;

      const scale = this.__zoomProcessor.getScale(
        this.__size.view.width,
        this.__size.view.height,
        this.__pages[this.__pageNumber - 1].width,
        this.__pages[this.__pageNumber - 1].height,
      );

      await this.__pdfProcessor.renderPage(canvas, this.__pageNumber, scale);
      this.__size.page.width = canvas.width;
      this.__size.page.height = canvas.height;
    }

    this.__isRenderPdf = false;
  }

  async __loadPdf() {
    if (this.__hasPdfSource()) {
      this.__reset();

      await this.__pdfProcessor.open(this.__pdfDataUrl);
      await this.__loadPages();

      this.__setPageValues(this.__pdfProcessor.getPageCount());
    }
  }

  async __loadPages() {
    if (this.__hasPdfSource()) {
      const pages = await this.__pdfProcessor.getPageInfos(this.__pdfDataUrl);

      if (pages) {
        this.__pages = pages.map(page => ({
          width: page.width,
          height: page.height,
        }));
      }
    }
  }

  __onNext() {
    this.__pageNavigator.next();

    this.__updatePageNumber();
  }

  __onPrevious() {
    this.__pageNavigator.previous();

    this.__updatePageNumber();
  }

  __onPageNumberInput(number) {
    this.__pageNavigator.goto(number);

    this.__updatePageNumber();
  }

  __updatePageNumber() {
    this.__pageNumber = this.__pageNavigator.pageNumber;
  }

  __onZoomIn() {
    this.__zoomProcessor.zoomIn();

    this.__updateZoom();
  }

  __onZoomOut() {
    this.__zoomProcessor.zoomOut();

    this.__updateZoom();
  }

  __onSetZoom(zoomInfo) {
    this.__zoomProcessor.setZoom(zoomInfo.mode, zoomInfo.value, this.__size);

    this.__updateZoom();
  }

  __updateZoom() {
    this.__zoom = {
      ...this.__zoom,
      mode: this.__zoomProcessor.zoomMode,
      value: this.__zoomProcessor.zoomValue,
    };
  }

  __onResize(sizeInfo) {
    this.__size = {
      ...this.__size,
      view: {
        width: sizeInfo.width,
        height: sizeInfo.height,
      },
    };
  }

  __getState() {
    return {
      size: this.__size,
      pageNumber: this.__pageNumber,
      zoom: this.__zoom,
      hasPdfSource: this.__hasPdfSource(),
    };
  }

  __validateRender(changedProps) {
    if (this.__renderValidator.validate(changedProps, this.__getState())) {
      this.__isRenderPdf = true;
    }
  }

  updated(changedProps) {
    if (changedProps.has('src')) {
      this.__pdfDataUrl = this.src;

      this.__loadPdf();
    }

    this.__validateRender(changedProps);
  }

  render() {
    return html`
      <neb-pdf-viewer
        id="${ELEMENTS.pdfViewer.id}"
        .onRenderPdf="${this.__handlers.renderPdf}"
        .onNext="${this.__handlers.next}"
        .onPrevious="${this.__handlers.previous}"
        .pageCount="${this.__pageCount}"
        .pageNumber="${this.__pageNumber}"
        .onPageNumberInput="${this.__handlers.pageNumberInput}"
        .zoom="${this.__zoom}"
        .onZoomIn="${this.__handlers.zoomIn}"
        .onZoomOut="${this.__handlers.zoomOut}"
        .onSetZoom="${this.__handlers.setZoom}"
        .onResize="${this.__handlers.resize}"
        .isRenderPdf="${this.__isRenderPdf}"
        .small="${this.small}"
      ></neb-pdf-viewer>
    `;
  }
}
customElements.define('neb-pdf-viewer-controller', NebPdfViewerController);
