import '../../../../packages/neb-lit-components/src/components/neb-file-select';

import { html, css } from 'lit';
import pako from 'pako';

import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { openError } from '../../../store';

export const ELEMENTS = {
  filePicker: { id: 'file-picker' },
};

const FILE_TYPES = ['ct'];
const MAX_FILE_SIZE = 5 * 1024 * 1024;

class NebPopupImportMacroSet extends NebPopup {
  initState() {
    super.initState();

    this.title = 'Import Macro Set';
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      ...this.__handlers,

      readFile: async file => {
        try {
          const data = await file.arrayBuffer();
          const json = pako.ungzip(data, { to: 'string' });
          const macroSet = JSON.parse(json);
          this.onClose({ macroSet });
        } catch (e) {
          console.error(e);
          openError('An error occurred while reading the macro set file');
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;
          top: 140px;
          height: auto;
          width: 525px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-file-select
        id="${ELEMENTS.filePicker.id}"
        invalidFileMessage="Only .ct files are supported."
        .allowedExtensions="${FILE_TYPES}"
        .validMimeTypes="${FILE_TYPES}"
        .maxFileSize="${MAX_FILE_SIZE}"
        .onSelectFile="${this.__handlers.readFile}"
      ></neb-file-select>
    `;
  }
}

customElements.define('neb-popup-import-macro-set', NebPopupImportMacroSet);
