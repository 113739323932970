import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { LocationsService } from '../../neb-redux/services/locations';
import { buildSelectedLocation } from '../../neb-utils/location-util';
import * as selectors from '../../neb-utils/selectors';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  textMessage: {
    id: 'text-message',
  },
  selectLocationsDropdown: {
    id: 'select-locations-dropdown',
  },
  buttonCancelLocation: {
    id: 'button-cancel-location',
  },
  buttonSaveLocation: {
    id: 'button-save-location',
  },
};

class NebPopupSelectLocation extends NebPopup {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      location: Object,
    };
  }

  constructor() {
    super();
    this.initServices();
  }

  initState() {
    super.initState();
    this.title = 'Select Location';
    this.model = {
      message: '',
    };

    this.__activeLocations = [];
    this.location = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ value }) => {
        if (value && value.label !== '') {
          this.location = buildSelectedLocation(value);
        } else {
          this.location = null;
        }
      },
      click: () => {
        this.onClose({ location: this.location, canceled: false });
      },
      cancel: () => {
        this.onClose({ canceled: true });
      },
    };
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      const activeLocations = userLocations.filter(location => location.active);
      this.__activeLocations = this.__formatLocations(activeLocations);
    });
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      ...location,
    }));
  }

  __getItems() {
    return [selectors.ITEM_EMPTY, ...this.__activeLocations];
  }

  connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model && this.model.locationId) {
      const selectedLocation = this.__activeLocations.find(
        location => location.id === this.model.locationId,
      );

      this.location = selectedLocation;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 372px;
        }

        @media (min-width: 1920px) {
          :host {
            width: 500px;
          }
        }

        :host([layout='small']) {
          width: 90%;
        }

        .content {
          display: flex;
          width: 100%;
        }

        .view {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .locations {
          width: 100%;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .container-buttons {
          display: flex;
          margin-top: 40px;
        }

        .button-cancel {
          margin-left: 10px;
        }

        .container {
          height: auto;
        }

        .message {
          margin-bottom: 14px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-content">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.model.message}
        </div>

        <neb-select
          id="${ELEMENTS.selectLocationsDropdown.id}"
          class="locations"
          name="locations"
          label="Location"
          select
          wrapText
          .items="${this.__getItems()}"
          .value="${this.location}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        <div class="container-buttons">
          <neb-button
            id="${ELEMENTS.buttonSaveLocation.id}"
            label="OK"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.click}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancelLocation.id}"
            class="button-cancel"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-select-location', NebPopupSelectLocation);
