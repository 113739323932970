import { formatPhonesEmails } from '../../../neb-utils/formatters';

export const formatOrganization = (organization = {}) => {
  const { emails = [], phones = [], isActive, ...props } = organization;
  return {
    ...props,
    ...formatPhonesEmails(phones, emails),
    active: isActive !== false,
  };
};
