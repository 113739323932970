import moment from 'moment-timezone';

import { getEncounterDiagnoses } from '../../../neb-api-client/src/encounters-api-client';
import * as listingsApiClient from '../../../neb-api-client/src/listings-api-client';
import * as patientApiClient from '../../../neb-api-client/src/patient-api-client';
import { store } from '../../../neb-redux/neb-redux-store';
import { getActiveTreatmentPlan } from '../../../neb-treatment-plan/actions/treatmentPlanActions';
import { buildChartNotesTableModel } from '../../../neb-utils/listings';
import { buildTokenTable } from '../components/shared/util/notes-token-table-builder';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'hh:mm A';

export const TOKEN_KEY = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  HIS_HER: 'hisHer',
  CAPITAL_HIS_HER: 'capitalHisHer',
  HE_SHE: 'heShe',
  CAPITAL_HE_SHE: 'capitalHeShe',
  HIM_HER: 'himHer',
  MR_MS: 'mrMs',
  MR_MRS: 'mrMrs',
  BIRTH_DATE: 'birthDate',
  AGE: 'age',
  GENDER: 'gender',
  ADDRESS: 'address',
  PHONE: 'phone',
  EMAIL: 'email',
  RELATIONSHIP_STATUS: 'relationshipStatus',
  EMPLOYMENT_STATUS: 'employmentStatus',
  CURRENT_LISTINGS: 'currentListings',
  CURRENT_TX: 'currentTx',
  CURRENT_DX: 'currentDx',
  CURRENT_DATE: 'currentDate',
  CURRENT_TIME: 'currentTime',
  CURRENT_USER: 'currentUser',
  INJURY_DATE: 'injuryDate',
};

export const TOKEN_KEYS = Object.values(TOKEN_KEY);

const wrapToken = (field, value) =>
  `<span contenteditable="false" data-field="${field}">${
    value || value === 0 ? value : ''
  }</span>`;

const buildDiagnosesInfo = (encounterDiagnoses = []) =>
  encounterDiagnoses.length > 0 ? encounterDiagnoses : null;

const determinePronoun = (sex, key) => {
  if (sex === 'Male' || sex === 'Female') {
    switch (key) {
      case TOKEN_KEY.HIS_HER:
        return sex === 'Male' ? 'his' : 'her';

      case TOKEN_KEY.CAPITAL_HIS_HER:
        return sex === 'Male' ? 'His' : 'Her';

      case TOKEN_KEY.HE_SHE:
        return sex === 'Male' ? 'he' : 'she';

      case TOKEN_KEY.CAPITAL_HE_SHE:
        return sex === 'Male' ? 'He' : 'She';

      case TOKEN_KEY.HIM_HER:
        return sex === 'Male' ? 'him' : 'her';

      case TOKEN_KEY.MR_MS:
        return sex === 'Male' ? 'Mr' : 'Ms';

      case TOKEN_KEY.MR_MRS:
        return sex === 'Male' ? 'Mr' : 'Mrs';

      default:
        return '';
    }
  } else {
    return '';
  }
};

const reformatDate = date => (date ? moment(date).format(DATE_FORMAT) : '');

const reformatTime = date => (date ? moment(date).format(TIME_FORMAT) : '');

const formatNumber = number => {
  const stringWithOnlyNumbers = `${number}`.replace(/\D/g, '');
  const match = stringWithOnlyNumbers.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
};

const formatAddress = addresses => {
  let address = [
    addresses[0].address1,
    addresses[0].address2,
    addresses[0].city,
    addresses[0].state,
    addresses[0].zipcode,
  ];
  address = address.filter(adr => adr);
  return address.join(', ');
};

export const getTokenReplacement = async (
  encounter,
  optOutLoadingIndicator = false,
) => {
  const {
    session: {
      item: { firstName: userFirstName, lastName: userLastName },
    },
  } = store.getState();

  const { currentIllnessDate, currentIllnessGradual, patientId } = encounter;

  const [patient, listings, encounterDiagnoses] = await Promise.all([
    patientApiClient.fetchOne(patientId, optOutLoadingIndicator, true),
    listingsApiClient.fetch(patientId, optOutLoadingIndicator),
    getEncounterDiagnoses(encounter.id, optOutLoadingIndicator),
  ]);

  const listingsModel = buildChartNotesTableModel(listings);

  await store.dispatch(
    getActiveTreatmentPlan(patient.id, optOutLoadingIndicator),
  );

  const {
    treatmentPlan: { item: treatmentPlanId },
    treatmentPlans,
  } = store.getState();

  return {
    [TOKEN_KEY.FIRST_NAME]: patient.firstName,
    [TOKEN_KEY.LAST_NAME]: patient.lastName,
    [TOKEN_KEY.HIS_HER]: determinePronoun(patient.sex, TOKEN_KEY.HIS_HER),
    [TOKEN_KEY.CAPITAL_HIS_HER]: determinePronoun(
      patient.sex,
      TOKEN_KEY.CAPITAL_HIS_HER,
    ),
    [TOKEN_KEY.HE_SHE]: determinePronoun(patient.sex, TOKEN_KEY.HE_SHE),
    [TOKEN_KEY.CAPITAL_HE_SHE]: determinePronoun(
      patient.sex,
      TOKEN_KEY.CAPITAL_HE_SHE,
    ),
    [TOKEN_KEY.HIM_HER]: determinePronoun(patient.sex, TOKEN_KEY.HIM_HER),
    [TOKEN_KEY.MR_MS]: determinePronoun(patient.sex, TOKEN_KEY.MR_MS),
    [TOKEN_KEY.MR_MRS]: determinePronoun(patient.sex, TOKEN_KEY.MR_MRS),
    [TOKEN_KEY.BIRTH_DATE]: reformatDate(patient.dateOfBirth),
    [TOKEN_KEY.AGE]: moment().diff(patient.dateOfBirth, 'years'),
    [TOKEN_KEY.GENDER]: patient.sex,
    [TOKEN_KEY.ADDRESS]:
      patient.addresses.length > 0 ? formatAddress(patient.addresses) : '',
    [TOKEN_KEY.PHONE]:
      patient.phoneNumbers.length > 0
        ? formatNumber(patient.phoneNumbers[0].number)
        : '',
    [TOKEN_KEY.EMAIL]:
      patient.emailAddresses && patient.emailAddresses.length > 0
        ? patient.emailAddresses[0]
        : '',
    [TOKEN_KEY.RELATIONSHIP_STATUS]: patient.relationshipStatus,
    [TOKEN_KEY.EMPLOYMENT_STATUS]: patient.employmentStatus,
    [TOKEN_KEY.CURRENT_TX]: treatmentPlans[treatmentPlanId],
    [TOKEN_KEY.CURRENT_DX]: buildDiagnosesInfo(encounterDiagnoses),
    [TOKEN_KEY.CURRENT_DATE]: reformatDate(new Date()),
    [TOKEN_KEY.CURRENT_TIME]: reformatTime(new Date()),
    [TOKEN_KEY.CURRENT_USER]: `${userFirstName} ${userLastName}`,
    [TOKEN_KEY.CURRENT_LISTINGS]: listingsModel.length ? listingsModel : null,
    [TOKEN_KEY.INJURY_DATE]: currentIllnessGradual
      ? 'Gradual'
      : reformatDate(currentIllnessDate),
  };
};

export const replaceStaticTokens = async encounter => {
  const replacements = await getTokenReplacement(encounter, true);
  Object.entries(replacements).forEach(([key, value]) => {
    switch (key) {
      case TOKEN_KEY.CURRENT_TX:
        replacements[key] = value
          ? buildTokenTable(value, TOKEN_KEY.CURRENT_TX, true)
          : '';

        break;

      case TOKEN_KEY.CURRENT_LISTINGS:
        replacements[key] = value
          ? buildTokenTable(value, TOKEN_KEY.CURRENT_LISTINGS, true)
          : '';

        break;

      case TOKEN_KEY.CURRENT_DX:
        replacements[key] = value
          ? buildTokenTable(value, TOKEN_KEY.CURRENT_DX, true)
          : '';

        break;

      default:
        replacements[key] = wrapToken(key, value);
        break;
    }
  });

  return replacements;
};
