import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { CSS_SPACING, OVERLAY_WIDTH_LARGE } from '../../../styles';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
};

class NebOverlayPreallocatePayment extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }
      `,
    ];
  }

  dismissWithBlocker() {
    const result = undefined;
    const dismissAll = true;
    this.dismiss(result, dismissAll);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Select Charges to Apply Payment"
        showBackButton
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismissAll}"
      ></neb-popup-header>
    `;
  }
}

customElements.define(
  'neb-overlay-preallocate-payment',
  NebOverlayPreallocatePayment,
);
