import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_OUTSIDE_LABS, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    isOutsideLab: state.isOutsideLab,
    outsideLabChargesAmount: state.outsideLabChargesAmount,
  });

  if (result) {
    Object.entries(result).forEach(([name, value]) => {
      formService.apply(`claimEditedField.${name}`, true);

      handlers.change({
        name,
        value,
      });
    });
  }
};
