import { MDCSlider } from '@material/slider';
import { LitElement, html } from 'lit';

import sliderStyle from '../../material_styles/mdc-slider-css';

export const ELEMENTS = {
  slider: {
    id: 'input-slider',
  },
  pinSlider: {
    id: 'pin-slider',
  },
};

class NebMDSlider extends LitElement {
  static get properties() {
    return {
      value: {
        type: String,
      },
      disabled: {
        type: Boolean,
      },
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      interval: {
        type: Number,
      },
      discrete: {
        type: Boolean,
      },
      ticks: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.disabled = false;
    this.interval = 1;
    this.discrete = false;
    this.tick = false;

    this.onChange = () => {};

    this.onInput = () => {};
  }

  valueChanged() {
    if (this.value === this.slider.value) return;
    this.onChange({
      value: this.slider.value,
      id: this.id,
    });
  }

  valueInput() {
    if (this.value === this.slider.value) return;
    this.onInput({
      value: this.slider.value,
      id: this.id,
    });
  }

  updateSlider() {
    this.slider.disabled = this.disabled;
  }

  firstUpdated() {
    this.slider = MDCSlider.attachTo(
      this.shadowRoot.querySelector('.mdc-slider'),
    );

    this.slider.listen('MDCSlider:change', () => this.valueChanged());
    this.slider.listen('MDCSlider:input', () => this.valueInput());
    this.updateSlider();
  }

  updated() {
    this.updateSlider();
  }

  __sliderClass() {
    const classes = 'mdc-slider';

    if (this.ticks) {
      return `${classes} mdc-slider--discrete mdc-slider--display-markers`;
    }

    if (this.discrete) return `${classes} mdc-slider--discrete`;

    return classes;
  }

  __tickHTML() {
    return this.ticks
      ? html` <div class="mdc-slider__track-marker-container"></div> `
      : '';
  }

  static get styles() {
    return sliderStyle;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.slider.id}"
        class="${this.__sliderClass()}"
        role="slider"
        aria-valuemin="${this.min}"
        aria-valuemax="${this.max}"
        aria-valuenow="${this.value}"
        data-step="${this.interval}"
        aria-label="Select Value"
      >
        <div class="mdc-slider__track-container">
          <div class="mdc-slider__track"></div>
          ${this.__tickHTML()};
        </div>

        <div class="mdc-slider__thumb-container">
          <div class="mdc-slider__pin" id="${ELEMENTS.pinSlider.id}">
            <span class="mdc-slider__pin-value-marker"></span>
          </div>

          <svg class="mdc-slider__thumb" width="21" height="21">
            <circle cx="10.5" cy="10.5" r="7.875"></circle>
          </svg>

          <div class="mdc-slider__focus-ring"></div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-md-slider', NebMDSlider);
