import { css, html } from 'lit';

import '../../../../../../src/components/controllers/claim-errors/neb-manage-encounter-controller';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  manageEncounterController: { id: 'manage-encounter-controller' },
};

class NebOverlayManageEncounter extends Overlay {
  static get properties() {
    return {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
  }

  renderContent() {
    return html`
      <neb-manage-encounter-controller
        id="${ELEMENTS.manageEncounterController.id}"
        .layout="${this.layout}"
        .model="${this.model}"
        .onCancel="${this.handlers.dismiss}"
        .onDismiss="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-manage-encounter-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-manage-encounter',
  NebOverlayManageEncounter,
);
