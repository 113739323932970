import { html, css } from 'lit';

import { OVERLAY_WIDTH_LARGE } from '../../../../../../neb-styles/neb-variables';
import { NebFormPurchaseCharges } from '../../../forms/neb-form-purchase-charges';
import { INVOICE_TYPE } from '../../../patients/ledger/neb-ledger-invoice-section';
import Overlay from '../../neb-overlay';

export const ELEMENTS = {
  form: { id: 'form' },
};

class NebOverlayLedgerPurchase extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  initState() {
    super.initState();

    this.__formModel = NebFormPurchaseCharges.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      dismissAll: result => {
        if (this.model.invoiceId) {
          this.dismiss(true);
        } else {
          this.dismiss(result, true);
        }
      },

      changeForm: dirty => {
        this.isDirty = dirty;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: auto;
          width: ${OVERLAY_WIDTH_LARGE};
        }
      `,
    ];
  }

  firstUpdated() {
    if (this.model.invoiceId) {
      this.__formModel = {
        ...this.__formModel,
        radio: INVOICE_TYPE.EXISTING,
        invoice: this.model.invoiceId,
      };
    }

    if (this.model.guarantorId) {
      this.__formModel = {
        ...this.__formModel,
        radio: INVOICE_TYPE.EXISTING,
        guarantor: this.model.guarantorId,
      };
    }
  }

  dismissWithBlocker() {
    this.handlers.dismissAll();
  }

  renderContent() {
    return html`
      <neb-form-purchase-charges
        id="${ELEMENTS.form.id}"
        saveBlockerLabel="Posting charge..."
        .layout="${this.layout}"
        .patientId="${this.model.patientId}"
        .invoiceId="${this.model.invoiceId}"
        .model="${this.__formModel}"
        .onDismiss="${this.handlers.dismiss}"
        .onDismissAll="${this.handlers.dismissAll}"
        .onChangeDirty="${this.handlers.changeForm}"
      ></neb-form-purchase-charges>
    `;
  }
}

customElements.define('neb-overlay-ledger-purchase', NebOverlayLedgerPurchase);
