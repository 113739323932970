import '../../forms/neb-form-patient-package-add';
import '../../neb-popup-header';

import { html, css } from 'lit';

import { getPackageTemplates } from '../../../../../neb-api-client/src/package-template-api-client';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  popupHeader: { id: 'popup-header' },
  form: { id: 'form' },
};

class NebOverlayPatientPackageAdd extends Overlay {
  static get properties() {
    return {
      __packageTemplates: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
          display: flex;
          flex-direction: column;
        }

        .form {
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__packageTemplates = [];

    this.model = {
      context: {},
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    const packageTemplates = await getPackageTemplates();
    this.__packageTemplates = packageTemplates.map(item => ({
      item,
      label: item.name,
    }));
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.popupHeader.id}"
          title="Add Patient Package/Subscription"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>

        <div class="description">
          Select a package template and customize for this patient, save details
          to post charge and create an invoice.
        </div>
      </div>

      <neb-form-patient-package-add
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .patientId="${this.model.context.patientId}"
        .packageTemplates="${this.__packageTemplates}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
        .disableDefault="${!this.model.context.filteredCount}"
      ></neb-form-patient-package-add>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-package-add',
  NebOverlayPatientPackageAdd,
);
