import '../field-groups/neb-duration';
import '../inputs/neb-textfield';
import '../inputs/neb-textarea';
import '../inputs/neb-select';
import '../neb-calendar-view';
import '../neb-radio-button';
import '../neb-appointment-card';
import '../neb-loading-overlay';
import '../inputs/neb-picker-color';
import '../inputs/neb-picker-color-advanced';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { isTimeLessThan } from '../../../../../src/components/controls/field-groups/neb-start-end-time';
import { NebSimpleForm } from '../../../../../src/components/forms/neb-simple-form';
import {
  createBlockedOffTime,
  updateBlockedOffTime,
} from '../../../../neb-api-client/src/blocked-off-time-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_BLACK,
  CSS_FONT_SIZE_HEADER,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';
import { CALENDAR_TYPES } from '../../../../neb-utils/calendar-resources-util';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  findInSelect,
  requiredSelect,
  isRequired,
} from '../../../../neb-utils/simple-form-util';
import { PERIOD } from '../../../../neb-utils/utils';
import NebFormRecurringAppointment from '../forms/neb-form-recurring-appointment';

export const ELEMENTS = {
  cardOneTimeBlockedOffTime: {
    id: 'one-time-appointment-card',
  },
  cardRecurringSeries: {
    id: 'recurring-series-card',
  },
  calendar: {
    id: 'calendar',
  },
  containerButtonAction: {
    id: 'container-button-action',
  },
  formRecurringAppointment: {
    id: 'form-recurring-appointment',
  },
  iconClose: {
    id: 'icon-close',
  },
  selectInputProvider: {
    id: 'dropdown-provider',
  },
  selectInputLocation: {
    id: 'dropdown-location',
  },
  roomsSelect: {
    id: 'rooms-select',
  },
  providerSelected: {
    id: 'provider-name',
  },
  spinnerLoading: {
    id: 'spinner-loading',
  },
  textHeader: {
    id: 'text-header',
  },
  textInputName: {
    id: 'text-input-name',
  },
  textInputNote: {
    id: 'text-input-note',
  },
  textSelectedDate: {
    id: 'text-selected-date',
  },
  providerTypeRadio: {
    id: 'provider-type-radio',
  },
  roomTypeRadio: {
    id: 'room-type-radio',
  },
  startEndTime: {
    id: 'start-end-time',
  },
  pickerColor: {
    id: 'picker-color',
  },
  pickerColorAdvanced: {
    id: 'picker-color-advanced',
  },
};
const TEXT_HEADER = 'Blocked Off Time';
const TEXT_NAME = 'Title';
const PROVIDER_DROPDOWN_ERROR = 'At least one provider required';
const ROOM_DROPDOWN_ERROR = 'At least one room required';

export const UPDATED_SUCCESS_MESSAGE = 'Blocked off time updated successfully';
const CREATED_SUCCESS_MESSAGE = 'Blocked off time created Successfully';

export const BLOCKED_OFF_TIME_APPT_TYPE_COLOR = '#A9A9A9';
export default class NebBlockedOffTimeForm extends NebSimpleForm {
  static get properties() {
    return {
      model: Object,
      layout: String,
      providers: Array,
      rooms: Array,
      locations: Array,
      __oneTimeAppointment: Boolean,
      __saving: Boolean,
      isDirty: Boolean,
      __name: String,
      __note: String,
      __selectedType: String,
      __dateStart: Object,
      __initialRecurringModel: Object,
      errors: Object,
      __selectedRooms: Array,
      __selectedProviders: Array,
      __selectedLocation: Array,
      __color: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          width: 100%;
          height: 100%;
        }

        .container {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 100%;
          height: 100%;
        }

        .content {
          height: 100%;

          padding: ${CSS_SPACING};

          overflow-y: scroll;
          overflow-x: hidden;
        }

        .content::-webkit-scrollbar {
          width: 0;
        }

        .input-name {
          width: 100%;
          margin-bottom: 16px;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          margin-bottom: ${CSS_SPACING};
        }

        .text-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;

          color: ${CSS_COLOR_BLACK};
        }

        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .type-container {
          display: grid;
          grid-template-columns: min-content min-content min-content 1fr;
        }

        .type-padding {
          align-self: center;
          padding-right: ${CSS_SPACING};
        }

        .appointment-card {
          height: 100px;
          margin-top: ${CSS_SPACING};
        }

        .calendar {
          width: 100%;
          margin: ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .display-selected-date {
          margin-bottom: 30px;
        }

        .provider-dropdown {
          width: 100%;
          margin: 12px 0 16px;
        }

        .location-dropdown {
          width: 100%;
          margin-bottom: 16px;
        }

        .text-label {
          font-size: 12px;
          padding-left: 5px;
        }

        .note {
          width: 100%;

          margin: ${CSS_SPACING} 0;
        }

        .container-action-buttons {
          position: sticky;
        }

        .button:not(:last-child) {
          margin-right: 5px;
        }

        .loading-overlay {
          position: absolute;

          width: 100%;
          height: 100%;
        }

        .form-recurring {
          height: auto;
        }

        .color-container-wrapper {
          padding: 10px 0;
        }

        .color-container {
          display: flex;
          align-items: center;
          gap: ${CSS_SPACING};
        }

        .field-picker-color {
          width: fit-content;
        }

        .padding {
          padding-bottom: 20px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
      note: '',
      start: null,
      end: null,
      locationId: null,
      resourceIds: [],
      providerId: null,
      providerIds: [],
      color: BLOCKED_OFF_TIME_APPT_TYPE_COLOR,
    };
  }

  initState() {
    super.initState();
    this.model = {};
    this.providers = [];
    this.rooms = [];
    this.locations = [];

    this.__dateStart = null;

    this.__selectedLocation = { item: null, label: null };

    this.__name = '';
    this.__note = '';
    this.__selectedType = CALENDAR_TYPES.PROVIDER;
    this.__selectedProviders = [];
    this.__filteredRooms = [];
    this.__selectedRooms = [];
    this.__saving = false;
    this.__time = {};
    this.__color = BLOCKED_OFF_TIME_APPT_TYPE_COLOR;

    this.__oneTimeAppointment = true;
    this.__initialRecurringModel = NebFormRecurringAppointment.createModel();

    this.__recurringState = {};

    this.onChangeDirty = () => {};

    this.onDismiss = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      cancel: () => {
        if (this.isDirty) {
          this.__openPopupDirty();
        } else {
          this.onDismiss();
        }
      },
      changeSelectedType: value => {
        if (value !== this.__selectedType) {
          this.__selectedType = value;

          if (this.__selectedType === CALENDAR_TYPES.PROVIDER) {
            this.__selectedProviders = this.providers;
            this.__selectedRooms = [];
          } else {
            if (this.__selectedLocation && this.__selectedLocation.data) {
              this.__filterRooms();
            }

            this.__selectedRooms = [];
            this.__selectedProviders = [];
          }
        }
      },
      isDateSelectable: date => this.__isDateSelectable(date),
      updateDate: date => this.__updateDate(date),
      recurringModelChange: model => {
        this.__recurringState = model;
      },
      cardClick: () => {
        this.__oneTimeAppointment = !this.__oneTimeAppointment;

        if (this.__oneTimeAppointment) {
          this.__dateStart = this.initialState.__dateStart;
        } else {
          this.__initialRecurringModel =
            NebFormRecurringAppointment.createModel(
              this.__dateStart,
              this.__time.start,
            );
        }
      },
      changeTime: e => {
        this.__time = e.value;
      },
      changeRecurringDirty: dirty =>
        this.handlers.changeDirty(dirty, 'recurringModel'),
    };
  }

  createSelectors() {
    return {
      start: {
        stateKey: '__dateStart',
        toModel: v => {
          const dateStart = this.__oneTimeAppointment
            ? v
            : this.__recurringState.startDate;

          const start = this.__getDateFromTime(dateStart, this.__time.start);

          return this.__formatDate(start);
        },
        toState: v => {
          if (!v) {
            const dateRoundCoeff = 1000 * 60 * 5;

            return parseDate(
              Math.ceil(new Date().getTime() / dateRoundCoeff) * dateRoundCoeff,
            )
              .startOf('minute')
              .toDate();
          }

          return parseDate(v).toDate();
        },
      },
      end: {
        toModel: () => {
          const dateEnd = this.__oneTimeAppointment
            ? this.__dateStart
            : this.__recurringState.startDate;

          const end = this.__getDateFromTime(dateEnd, this.__time.end);

          return this.__formatDate(end);
        },
      },
      name: {
        stateKey: '__name',
        toState: v => v || '',
        validate: isRequired,
      },
      note: {
        stateKey: '__note',
        toState: v => v || '',
      },
      locationId: requiredSelect(this.locations, '__selectedLocation'),
      providerIds: {
        stateKey: '__selectedProviders',
        toModel: v => v.map(provider => provider.data.id),
        toState: v => (v ? v.map(id => findInSelect(id, this.providers)) : []),
        validate: v => {
          if (this.__selectedType !== CALENDAR_TYPES.PROVIDER || v.length) {
            return '';
          }

          return PROVIDER_DROPDOWN_ERROR;
        },
      },
      resourceIds: {
        stateKey: '__selectedRooms',
        toModel: v => v.map(room => room.data.id),
        toState: v => (v ? v.map(id => findInSelect(id, this.rooms)) : []),
        validate: v => {
          if (this.__selectedType !== CALENDAR_TYPES.ROOM || v.length) {
            return '';
          }

          return ROOM_DROPDOWN_ERROR;
        },
      },
      color: {
        stateKey: '__color',
        toState: v => v || this.__color,
      },
    };
  }

  __isDateSelectable(date) {
    return date >= parseDate().startOf('day').toDate();
  }

  __updateDate(date) {
    this.__dateStart = this.__createDate(date, this.__dateStart);
  }

  __createDate(newDate, oldDate) {
    return parseDate(newDate)
      .hours(oldDate.getHours())
      .minutes(oldDate.getMinutes())
      .startOf('minute')
      .toDate();
  }

  __getDateFromTime(date, time) {
    let newHours;

    const { hours, minutes, period } = time;
    const hoursNumber = Number(hours);

    if (period === PERIOD.AM) {
      if (hoursNumber === 12) {
        newHours = 0;
      } else {
        newHours = hoursNumber;
      }
    } else if (hoursNumber === 12) {
      newHours = 12;
    } else {
      newHours = hoursNumber + 12;
    }

    return parseDate(date).hours(newHours).minutes(minutes).toDate();
  }

  __formatDate(date) {
    return parseDate(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  __getAppointmentNote() {
    return this.model.note || '';
  }

  save() {
    let validRecurrence = true;

    const formRecurringAppointment = this.shadowRoot.getElementById(
      ELEMENTS.formRecurringAppointment.id,
    );

    if (formRecurringAppointment) {
      validRecurrence = formRecurringAppointment.formService.validate();
    }

    if (validRecurrence && isTimeLessThan(this.__time.start, this.__time.end)) {
      this.setErrors();

      if (!this.__hasErrors) {
        const saveModel = this.convertStateToModel();

        this.saving = true;
        this.__saveBlockedOffTime(saveModel);
      }
    }
  }

  __saveBlockedOffTime(model) {
    const saveModel = {
      ...model,
      allSelected: this.__selectedProviders.length === this.providers.length,
      resourceNames: this.__selectedRooms.map(item => item.label),
      rrule: !this.__oneTimeAppointment
        ? this.__recurringState.rrule.toString()
        : '',
    };

    if (!this.model.providerId && !this.model.resourceId) {
      this.__createNewBlockedOffTime(saveModel);
    } else {
      this.__updateBlockedOffTime(saveModel);
    }
  }

  __onSuccess(model = {}) {
    if (this.model.providerId || this.model.resourceId) {
      store.dispatch(openSuccess(UPDATED_SUCCESS_MESSAGE));
    } else {
      store.dispatch(openSuccess(CREATED_SUCCESS_MESSAGE));
    }

    this.onChangeDirty(false);
    this.onDismiss(model);
  }

  __onError() {
    store.dispatch(openError('Blocked off time was not successfully created'));
  }

  async __createNewBlockedOffTime(model) {
    this.__saving = true;

    try {
      await createBlockedOffTime(model, true);
    } catch (e) {
      console.error(e);
      this.__onError();
      return;
    }

    this.__onSuccess();
    this.__saving = false;
  }

  async __updateBlockedOffTime(model) {
    this.__saving = true;
    const changeTypeToProvider =
      model.providerIds.length && !this.model.providerId;

    const changeTypeToRoom = model.resourceIds.length && this.model.providerId;

    let updateModel = { ...this.model, ...model };

    if (changeTypeToProvider) {
      updateModel = { ...updateModel, resourceId: null };
    }

    if (changeTypeToRoom) {
      updateModel = { ...updateModel, providerId: null };
    }

    try {
      await updateBlockedOffTime(
        this.model.groupId,
        updateModel,
        this.model.recurrenceEventId
          ? { action: 'updateSingleInstanceForAllProvider' }
          : {},
      );
    } catch (e) {
      console.error(e);
      this.__onError();
      return;
    }

    this.__onSuccess(model);

    this.__saving = false;
  }

  async __openPopupDirty() {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Warning',
      message:
        'You have unsaved changes that will be lost if you navigate away. Are you sure you wish to discard these changes?',
      confirmText: 'Discard Changes',
      cancelText: 'Stay on Page',
    });

    if (result) {
      this.onChangeDirty(false);
      this.onDismiss();
    }
  }

  __filterRooms() {
    this.__filteredRooms = this.rooms.filter(
      ({ data: r }) =>
        r.scheduleAvailable &&
        r.active &&
        r.locationId === this.__selectedLocation.data.id,
    );
  }

  update(changedProps) {
    if (changedProps.has('providers')) {
      if (this.model.providerIds && this.model.providerIds.length) {
        this.__selectedProviders = this.providers.filter(item =>
          this.model.providerIds.includes(item.data.id),
        );

        this.__selectedRooms = [];
      } else {
        this.__selectedProviders = this.providers;
      }
    }

    if (changedProps.has('locations') && this.locations.length === 1) {
      this.__selectedLocation = this.locations[0];
    }

    if (changedProps.has('rooms')) {
      if (
        this.model &&
        this.model.resourceIds &&
        this.model.resourceIds.length
      ) {
        this.__filterRooms();

        this.__selectedRooms = this.__filteredRooms.filter(({ data: room }) =>
          this.model.resourceIds.includes(room.id),
        );

        this.__selectedProviders = [];
      }
    }

    if (changedProps.has('__dateStart')) {
      this.__initialRecurringModel = NebFormRecurringAppointment.createModel(
        this.__dateStart,
        this.__time.start,
      );
    }

    if (
      !this.isInitializing &&
      changedProps.has('__selectedLocation') &&
      this.__selectedLocation?.data !==
        changedProps.get('__selectedLocation')?.data
    ) {
      this.__selectedRooms = [];

      this.__filterRooms();
    }

    if (this.isInitializing && this.model.start) {
      this.__selectedType = this.model.providerId
        ? CALENDAR_TYPES.PROVIDER
        : CALENDAR_TYPES.ROOM;
    }

    super.update(changedProps);
  }

  renderSelectedDate() {
    return this.__dateStart != null && this.__dateStart !== ''
      ? html`
          <div
            id="${ELEMENTS.textSelectedDate.id}"
            class="display-selected-date"
          >
            ${parseDate(this.__dateStart).format('dddd, MMMM D, YYYY')}
          </div>
        `
      : '';
  }

  renderActionButtons() {
    return this.isDirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.containerButtonAction.id}"
            class="container-action-buttons"
            confirmLabel="Save"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  __renderAppointmentCards() {
    const hasResourceId = this.model && this.model.resourceId;

    return !this.model.providerId && !hasResourceId
      ? html`
          <div class="appointment-card-container">
            <neb-appointment-card
              id="${ELEMENTS.cardOneTimeBlockedOffTime.id}"
              class="appointment-card"
              title="One-Time Blocked Off Time"
              icon="calendar"
              ?selected="${this.__oneTimeAppointment}"
              .onClick="${this.handlers.cardClick}"
            ></neb-appointment-card>
            <neb-appointment-card
              id="${ELEMENTS.cardRecurringSeries.id}"
              class="appointment-card"
              title="Recurring Series"
              ?selected="${!this.__oneTimeAppointment}"
              icon="loop"
              .onClick="${this.handlers.cardClick}"
            ></neb-appointment-card>
          </div>
        `
      : '';
  }

  __renderOneTimeAppointmentSection() {
    return html`
      <neb-calendar-view
        id="${ELEMENTS.calendar.id}"
        class="calendar"
        .isDateSelectable="${this.handlers.isDateSelectable}"
        .isPickerable="${this.layout === 'small'}"
        .isToolbarVisible="${false}"
        .selectedDate="${this.__dateStart}"
        .onChange="${this.handlers.updateDate}"
      ></neb-calendar-view>

      ${this.renderSelectedDate()}

      <neb-start-end-time
        id="${ELEMENTS.startEndTime.id}"
        name="__time"
        .model="${this.model}"
        .onChange="${this.handlers.change}"
        .onChangeDirty="${this.handlers.changeDirty}"
      ></neb-start-end-time>
    `;
  }

  __renderRecurringSeriesSection() {
    return html`
      <neb-form-recurring-appointment
        id="${ELEMENTS.formRecurringAppointment.id}"
        class="form-recurring"
        .layout="${this.layout}"
        .selectedDate="${this.__dateStart}"
        .model="${this.__initialRecurringModel}"
        .onRecurringModelChange="${this.handlers.recurringModelChange}"
        .onChangeTime="${this.handlers.changeTime}"
        .onChangeDirty="${this.handlers.changeRecurringDirty}"
        blockedOffTime
      ></neb-form-recurring-appointment>
    `;
  }

  __renderProviderLabel() {
    const target = this.providers.find(
      item => item.data.id === this.model.providerId,
    );
    return target ? ` - ${target.label}` : '';
  }

  __renderEditBlockedOffTimeProvider() {
    return this.model.recurrenceEventId ? this.__renderProviderLabel() : '';
  }

  __renderEditBlockedOffTimeResource() {
    const isRecurrence = this.model && this.model.recurrenceEventId;
    const hasResourceName =
      this.model.resourceNames && this.model.resourceNames.length;

    return isRecurrence && hasResourceName && this.__selectedLocation.label
      ? ` - ${this.__selectedLocation.label} - ${this.model.resourceNames[0]}`
      : '';
  }

  __renderNewBlockedOffTimeProviders() {
    return !this.model.recurrenceEventId
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputProvider.id}"
            class="provider-dropdown"
            name="__selectedProviders"
            label="Providers"
            allLabel="Providers"
            helper="Select any or all providers"
            .value="${this.__selectedProviders}"
            .items="${this.providers}"
            .error="${this.errors.providerIds}"
            .disabled="${this.__selectedType !== CALENDAR_TYPES.PROVIDER}"
            .onChange="${this.handlers.change}"
            multiSelect
            required
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderNewBlockedOffTimeRooms() {
    const disabled =
      !this.__filteredRooms.length ||
      this.__selectedType !== CALENDAR_TYPES.ROOM;
    return !this.model.recurrenceEventId
      ? html`
          <neb-select
            id="${ELEMENTS.roomsSelect.id}"
            class="location-dropdown"
            name="__selectedRooms"
            label="Scheduled Rooms"
            helper="Select any or all rooms"
            .value="${this.__selectedRooms}"
            .items="${this.__filteredRooms}"
            .error="${disabled ? '' : this.errors.resourceIds}"
            .disabled="${disabled}"
            .onChange="${this.handlers.change}"
            allLabel="Rooms"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderType() {
    return !this.model.recurrenceEventId
      ? html`
          <div class="type-container">
            <span class="type-padding">Type</span>
            <neb-radio-button
              id="${ELEMENTS.providerTypeRadio.id}"
              class="type-padding"
              label="Provider"
              .value="${CALENDAR_TYPES.PROVIDER}"
              .checked="${this.__selectedType === CALENDAR_TYPES.PROVIDER}"
              .onSelect="${this.handlers.changeSelectedType}"
            ></neb-radio-button>
            <neb-radio-button
              id="${ELEMENTS.roomTypeRadio.id}"
              class="type-padding"
              label="Room"
              .value="${CALENDAR_TYPES.ROOM}"
              .checked="${this.__selectedType === CALENDAR_TYPES.ROOM}"
              .onSelect="${this.handlers.changeSelectedType}"
            ></neb-radio-button>
          </div>
        `
      : '';
  }

  __renderLocation() {
    const recurringResourceEvent =
      this.model.resourceId && this.model.recurrenceEventId;
    return !recurringResourceEvent
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputLocation.id}"
            class="location-dropdown"
            label="Location"
            name="__selectedLocation"
            .value="${this.__selectedLocation}"
            .items="${this.locations}"
            .error="${this.errors.locationId}"
            helper="Required"
            .onChange="${this.handlers.change}"
            required
            wrapText
          ></neb-select>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div id="header" class="header">
            <div id="${ELEMENTS.textHeader.id}" class="text text-header">
              ${TEXT_HEADER}
              ${this.model.providerId
                ? this.__renderEditBlockedOffTimeProvider()
                : this.__renderEditBlockedOffTimeResource()}
            </div>

            <neb-icon
              id="${ELEMENTS.iconClose.id}"
              class="icon icon-close"
              icon="neb:close"
              @click="${this.handlers.cancel}"
            ></neb-icon>
          </div>

          <neb-textfield
            id="${ELEMENTS.textInputName.id}"
            class="input-name"
            name="__name"
            label="${TEXT_NAME}"
            helper="Required"
            .error="${this.errors.name}"
            .value="${this.__name}"
            .onChange="${this.handlers.change}"
            maxLength="50"
          ></neb-textfield>

          ${this.__renderType()} ${this.__renderNewBlockedOffTimeProviders()}
          ${this.__renderLocation()} ${this.__renderNewBlockedOffTimeRooms()}
          ${this.__renderAppointmentCards()}
          ${this.__oneTimeAppointment
            ? this.__renderOneTimeAppointmentSection()
            : this.__renderRecurringSeriesSection()}

          <div class="color-container-wrapper">
            <div class="padding">Background Color</div>

            <div class="color-container">
              <neb-picker-color
                id="${ELEMENTS.pickerColor.id}"
                class="field-picker-color"
                name="__color"
                .value="${this.__color}"
                .onChange="${this.handlers.change}"
              ></neb-picker-color>

              <neb-picker-color-advanced
                id="${ELEMENTS.pickerColorAdvanced.id}"
                class="field-picker-color"
                name="__color"
                .value="${this.__color}"
                .onChange="${this.handlers.change}"
              ></neb-picker-color-advanced>
            </div>
          </div>

          <neb-textarea
            id="${ELEMENTS.textInputNote.id}"
            class="note"
            label="Note"
            maxlength="500"
            name="__note"
            showCount
            .value="${this.__note}"
            .onChange="${this.handlers.change}"
          >
          </neb-textarea>
        </div>

        ${this.renderActionButtons()}

        <neb-loading-overlay
          id="${ELEMENTS.spinnerLoading.id}"
          title="Saving Blocked Off Time"
          showDelay="0"
          .show="${this.__saving}"
        ></neb-loading-overlay>
      </div>
    `;
  }
}

customElements.define('neb-blocked-off-time-form', NebBlockedOffTimeForm);
