import '../neb-button';
import '../controls/neb-tab-group';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../../../../../src/components/controls/inputs/neb-checkbox';

import { css, html, LitElement } from 'lit';

import {
  postClaimsSubmission,
  postClaimsValidation,
} from '../../../../neb-api-client/src/electronic-claims/claims';
import {
  getReports,
  getReport,
  deleteReport,
  updateReport,
  parseReport,
  parseReports,
} from '../../../../neb-api-client/src/electronic-claims/reports';
import { TEST_SAMPLE_CLAIM } from '../../../../neb-api-client/test/electronic-claims/test-sample-data';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

const ELEMENTS = {
  tabs: { id: 'tabs' },
  getReportsButton: { id: 'get-reports-button' },
  processReportsButton: { id: 'process-reports-button' },
  getReportButton: { id: 'parse-report-button' },
  parseReportButton: { id: 'parse-report-button' },
  deleteReportButton: { id: 'delete-report-button' },
  updateReportButton: { id: 'update-report-button' },
  reportTextfield: { id: 'report-textfield' },
  reportsTextArea: { id: 'reports-textarea' },
  reportJsonTextArea: { id: 'reports-json-textarea' },
  enabledCheckbox: { id: 'enabled-checkbox' },
  postValidationButton: { id: 'post-validation-button' },
  postSubmissionButton: { id: 'post-submission-button' },
  validationBodyTextArea: { id: 'validation-body-textarea' },
  validationJsonTextArea: { id: 'validation-json-textarea' },
  submissionBodyTextArea: { id: 'submission-body-textarea' },
  submissionJsonTextArea: { id: 'submission-json-textarea' },
};

const TABS = {
  REPORT_LIST: 'report-list',
  REPORT_PARSE: 'report-parse',
  VALIDATION: 'validation',
  SUBMISSION: 'submission',
};

class NebClaimsWorklistPlayground extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __reports: String,
      __reportJson: String,
      __playgroundEnabled: Boolean,
      __validationBody: String,
      __validationJson: String,
      __submissionBody: String,
      __submissionJson: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__navItems = [];
    this.__selectedTab = TABS.REPORT_LIST;
    this.__playgroundEnabled = false;

    this.__validationBody = JSON.stringify(TEST_SAMPLE_CLAIM, null, 2);
    this.__submissionBody = JSON.stringify(TEST_SAMPLE_CLAIM, null, 2);

    this.__resetPlaygroundValues();
  }

  __resetPlaygroundValues() {
    this.__reports = '';
    this.__reportJson = '';
    this.__reportId = '';
    this.__validationJson = '';
    this.__submissionJson = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        this.__selectedTab = tab;
        this.__resetPlaygroundValues();
      },
      getReports: async () => {
        this.__reports = JSON.stringify(await getReports(), null, 2);
      },
      processReports: async () => {
        this.__reports = JSON.stringify(await parseReports(), null, 2);
      },
      getReport: async () => {
        try {
          const report = await getReport(this.__reportId);
          this.__reportJson = JSON.stringify(report.data || report, null, 2);
        } catch (err) {
          this.__reportJson = err;
        }
      },
      parseReport: async () => {
        try {
          const report = await parseReport(this.__reportId);
          this.__reportJson = JSON.stringify(report, null, 2);
        } catch (err) {
          this.__reportJson = err;
        }
      },
      deleteReport: async () => {
        try {
          const deleteResult = await deleteReport(this.__reportId);
          this.__reportJson = `${deleteResult.message}! reportId ${
            this.__reportId
          } deleted`;
        } catch (err) {
          this.__reportJson = err;
        }
      },
      updateReport: async () => {
        try {
          const saveResult = await updateReport(
            this.__reportId,
            this.__reportJson,
          );
          this.__reportJson = `${saveResult.message}! reportId ${
            this.__reportId
          } saved`;
        } catch (err) {
          this.__reportJson = err;
        }
      },
      change: e => {
        this.__reportId = e.value;
      },
      changeReportJson: e => {
        this.__reportJson = e.value;
      },
      changeEnablePlayground: e => {
        this.__playgroundEnabled = e.value;
      },
      postValidation: async () => {
        try {
          this.__validationJson = JSON.stringify(
            await postClaimsValidation(JSON.parse(this.__validationBody)),
            null,
            2,
          );
        } catch (err) {
          this.__validationJson = err;
        }
      },
      postSubmission: async () => {
        try {
          this.__submissionJson = JSON.stringify(
            await postClaimsSubmission(JSON.parse(this.__submissionBody)),
            null,
            2,
          );
        } catch (err) {
          this.__submissionJson = err;
        }
      },
      changeValidationBody: e => {
        this.__validationBody = e.value;
      },
      changeSubmissionBody: e => {
        this.__submissionBody = e.value;
      },
    };
  }

  __genNavItems() {
    return [
      {
        id: TABS.REPORT_LIST,
        exact: false,
        label: 'List Reports',
        name: TABS.REPORT_LIST,
        path: '/report-list/',
        resolver: () => this.__renderReportList(),
      },
      {
        id: TABS.REPORT_PARSE,
        exact: false,
        label: 'Report Management',
        name: TABS.REPORT_PARSE,
        path: '/report-parse/',
        resolver: () => this.__renderReportJson(),
      },
      {
        id: TABS.VALIDATION,
        exact: false,
        label: 'Validation',
        name: TABS.VALIDATION,
        path: '/validation/',
        resolver: () => this.__renderValidation(),
      },
      {
        id: TABS.SUBMISSION,
        exact: false,
        label: 'Submission',
        name: TABS.SUBMISSION,
        path: '/submission/',
        resolver: () => this.__renderSubmission(),
      },
    ];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        h3 {
          padding-left: ${CSS_SPACING};
        }

        .tabs {
          border-radius: 5px 5px 0 0;
        }

        .container {
          padding: ${CSS_SPACING};
        }

        .textarea {
          margin-top: ${CSS_SPACING};
          width: 800px;
          height: 200px;
        }

        .parse-report {
          display: flex;
          align-items: center;
        }

        .checkbox {
          margin: ${CSS_SPACING};
        }

        .button {
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderReportList() {
    return html`
      <div class="container">
        <div class="parse-report">
          <neb-button
            id="${ELEMENTS.getReportsButton.id}"
            label="Get Reports"
            .onClick="${this.__handlers.getReports}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.processReportsButton.id}"
            class="button"
            label="Process All Reports"
            .onClick="${this.__handlers.processReports}"
          ></neb-button>
        </div>
        <neb-textarea
          id="${ELEMENTS.reportsTextArea.id}"
          .name="${ELEMENTS.reportsTextArea.id}"
          class="textarea"
          .value="${this.__reports}"
          maxLength="500"
          showCount
        ></neb-textarea>
      </div>
    `;
  }

  __renderReportJson() {
    return html`
      <div class="container">
        <div class="parse-report">
          <neb-textfield
            id="${ELEMENTS.reportTextfield.id}"
            placeholder="Report ID"
            .onChange="${this.__handlers.change}"
          ></neb-textfield>
          <neb-button
            id="${ELEMENTS.getReportButton.id}"
            class="button"
            label="Get Report"
            .onClick="${this.__handlers.getReport}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.parseReportButton.id}"
            class="button"
            label="Parse Report"
            .onClick="${this.__handlers.parseReport}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.deleteReportButton.id}"
            class="button"
            label="Delete Report"
            .onClick="${this.__handlers.deleteReport}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.updateReportButton.id}"
            class="button"
            label="Save Report"
            .onClick="${this.__handlers.updateReport}"
          ></neb-button>
        </div>
        <neb-textarea
          id="${ELEMENTS.reportJsonTextArea.id}"
          .name="${ELEMENTS.reportJsonTextArea.id}"
          class="textarea"
          .value="${this.__reportJson}"
          .onChange="${this.__handlers.changeReportJson}"
          maxLength="150000"
          showCount
        ></neb-textarea>
      </div>
    `;
  }

  __renderValidation() {
    return html`
      <div class="container">
        <neb-textarea
          id="${ELEMENTS.validationBodyTextArea.id}"
          .name="${ELEMENTS.validationBodyTextArea.id}"
          class="textarea"
          .value="${this.__validationBody}"
          .onChange="${this.__handlers.changeValidationBody}"
          showCount
        ></neb-textarea>
        <neb-button
          id="${ELEMENTS.postValidationButton.id}"
          label="Validate"
          .onClick="${this.__handlers.postValidation}"
        ></neb-button>
        <neb-textarea
          id="${ELEMENTS.validationJsonTextArea.id}"
          .name="${ELEMENTS.validationJsonTextArea.id}"
          class="textarea"
          .value="${this.__validationJson}"
          showCount
        ></neb-textarea>
      </div>
    `;
  }

  __renderSubmission() {
    return html`
      <div class="container">
        <neb-textarea
          id="${ELEMENTS.submissionBodyTextArea.id}"
          .name="${ELEMENTS.submissionBodyTextArea.id}"
          class="textarea"
          .value="${this.__submissionBody}"
          .onChange="${this.__handlers.changeSubmissionBody}"
          showCount
        ></neb-textarea>
        <neb-button
          id="${ELEMENTS.postSubmissionButton.id}"
          label="Submit"
          .onClick="${this.__handlers.postSubmission}"
        ></neb-button>
        <neb-textarea
          id="${ELEMENTS.submissionJsonTextArea.id}"
          .name="${ELEMENTS.submissionJsonTextArea.id}"
          class="textarea"
          .value="${this.__submissionJson}"
          showCount
        ></neb-textarea>
      </div>
    `;
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(({ id }) => id === this.__selectedTab);
    return item.resolver();
  }

  __renderPlayground() {
    return this.__playgroundEnabled
      ? html`
          <h3>Dev Playground (Change HealthCare API)</h3>
          ${this.__renderTabs()}${this.__renderTabContent()}
        `
      : '';
  }

  render() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.enabledCheckbox.id}"
        class="checkbox"
        name="enabled"
        label="Show Dev Playground"
        .checked="${this.__playgroundEnabled}"
        .onChange="${this.__handlers.changeEnablePlayground}"
      ></neb-checkbox>
      ${this.__renderPlayground()}
    `;
  }
}

customElements.define(
  'neb-claims-worklist-playground',
  NebClaimsWorklistPlayground,
);
