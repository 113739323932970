import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
} from '../../../neb-styles/neb-variables';

class NebNavList extends LitElement {
  static get properties() {
    return {
      selectedIndex: Number,
      items: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.selectedIndex = -1;
    this.items = [];

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      select: e => this.onSelect(Number(e.currentTarget.index)),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        background-color: ${CSS_COLOR_WHITE};

        --item-padding: 11px;
      }

      .container {
        display: flex;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        min-height: 0;
        flex-direction: column;
      }

      .item {
        display: flex;
        flex-shrink: 0;
        outline: none;
        cursor: pointer;
        padding: var(--item-padding) 8px var(--item-padding) 20px;
        color: ${CSS_COLOR_BLACK};
        align-items: center;
      }

      .item:hover {
        background-color: ${CSS_COLOR_GREY_4};
      }

      .item[selected] {
        background-color: ${CSS_COLOR_HIGHLIGHT};
        color: ${CSS_COLOR_WHITE};
      }

      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: ${CSS_SPACING};
        min-width: 0;
        flex: 1 0 0;
      }

      .icon {
        width: 12px;
        height: 12px;
        fill: ${CSS_COLOR_GREY_2};
        transform: rotate(-90deg);
      }

      .icon[selected] {
        fill: ${CSS_COLOR_WHITE};
      }
    `;
  }

  __renderItem(item, index) {
    return html`
      <div
        id="item-${index}"
        class="item"
        tabindex="0"
        .index="${index}"
        ?selected="${this.selectedIndex === index}"
        @click="${this.__handlers.select}"
      >
        <span class="text">${item}</span>
        <neb-icon
          class="icon"
          icon="neb:chevron"
          ?selected="${this.selectedIndex === index}"
        ></neb-icon>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.items.map((item, index) => this.__renderItem(item, index))}
      </div>
    `;
  }
}
customElements.define('neb-nav-list', NebNavList);
