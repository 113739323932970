import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-lit-components/src/components/neb-info';
import './neb-patients-roster-page';
import './neb-patients-ready-page';
import './neb-patients-attention-page';
import './neb-patients-duplicate-page';
import './neb-patients-existing-page';

import { LitElement, html, css } from 'lit';

import { getPatientRosterCounts } from '../../../../neb-api-client/src/patient-roster-api-client';
import { renderTypographyStyles } from '../../../../neb-styles/neb-typography';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

import {
  READY_TAB,
  ATTENTION_TAB,
  DUPLICATE_TAB,
  EXISTING_TAB,
} from './neb-patient-roster-tabs';

export const ID_PAGE_READY = 'ready';
export const ID_PAGE_ATTENTION = 'attention';
export const ID_PAGE_DUPLICATE = 'duplicate';
export const ID_PAGE_EXISTING = 'existing';
export const READY_INFO =
  'Only patient records listed on the "Ready to Import" tab will be created when you click on the "Create Patients" button.';
export const ATTENTION_INFO =
  'These patients may have formatting issues or do not meet the available choices. You can download these patients, fix the errors, and upload the CSV again.';
export const DUPLICATE_INFO =
  'These patients are duplicates in the file uploaded.';
export const EXISTING_INFO =
  'A medical record already exists for these patients.';

export const ELEMENTS = {
  tabsController: {
    id: 'roster-tabs-controller',
  },
  readyPage: {
    id: ID_PAGE_READY,
  },
  attentionPage: {
    id: ID_PAGE_ATTENTION,
  },
  duplicatePage: {
    id: ID_PAGE_DUPLICATE,
  },
  existingPage: {
    id: ID_PAGE_EXISTING,
  },
};

class NebPatientRosterUploadPages extends LitElement {
  static get properties() {
    return {
      importJobId: {
        type: Number,
      },
      __selectedTab: {
        type: String,
      },
      __readyCount: {
        type: Number,
      },
      __attentionCount: {
        type: Number,
      },
      __duplicateCount: {
        type: Number,
      },
      __existingCount: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__setupPages();

    this.__initHandlers();
  }

  __initState() {
    this.importJobId = 0;
    this.__readyCount = 0;
    this.__attentionCount = 0;
    this.__duplicateCount = 0;
    this.__existingCount = 0;
    this.__selectedTab = READY_TAB;

    this.onCancel = () => {};

    this.onSuccess = () => {};

    this.onAfterPatientSaved = () => {};

    this.onTabChange = () => {};
  }

  __setupPages() {
    this.__pages = {
      [READY_TAB]: {
        render: () => this.__renderReadyPage(),
        renderInfo: READY_INFO,
      },
      [ATTENTION_TAB]: {
        render: () => this.__renderAttentionPage(),
        renderInfo: ATTENTION_INFO,
      },
      [DUPLICATE_TAB]: {
        render: () => this.__renderDuplicatePage(),
        renderInfo: DUPLICATE_INFO,
      },
      [EXISTING_TAB]: {
        render: () => this.__renderExistingPage(),
        renderInfo: EXISTING_INFO,
      },
    };
  }

  __initHandlers() {
    this.__handlers = {
      tabChanged: tab => {
        this.__selectedTab = tab;
        this.onTabChange();
      },
      cancel: () => this.onCancel(),
      success: () => this.onSuccess(),
      error: () => this.__updateTabCounts(),
      afterPatientSaved: () => this.onAfterPatientSaved(),
    };
  }

  async __updateTabCounts() {
    const patientRosterCounts = await getPatientRosterCounts(this.importJobId);
    this.__readyCount = patientRosterCounts.readyCount;
    this.__attentionCount = patientRosterCounts.attentionCount;
    this.__duplicateCount = patientRosterCounts.duplicateCount;
    this.__existingCount = patientRosterCounts.existingCount;
  }

  update(changedProps) {
    if (changedProps.has('importJobId') && this.importJobId) {
      this.__updateTabCounts();
    }
    super.update(changedProps);
  }

  static get styles() {
    return css`
      .upload-page-container {
        min-height: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .tabs {
        margin-top: ${CSS_SPACING};
      }

      .tabs,
      .page {
        background: ${CSS_COLOR_WHITE};
      }

      .info {
        display: flex;
        flex-direction: row;
        color: ${CSS_COLOR_HIGHLIGHT};
        padding-bottom: ${CSS_SPACING};
      }

      .instruction-icon {
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        padding-right: 10px;
      }

      .page {
        min-height: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    `;
  }

  render() {
    return html`
      ${renderTypographyStyles()}
      <div class="upload-page-container">
        <neb-info>
          <span> ${this.__pages[this.__selectedTab].renderInfo} </span>
        </neb-info>
        <neb-patient-roster-tabs
          id="${ELEMENTS.tabsController.id}"
          class="tabs"
          .onTabChanged="${this.__handlers.tabChanged}"
          .readyCount="${this.__readyCount}"
          .attentionCount="${this.__attentionCount}"
          .duplicateCount="${this.__duplicateCount}"
          .existingCount="${this.__existingCount}"
        ></neb-patient-roster-tabs>
        ${this.__renderPage()}
      </div>
    `;
  }

  __renderPage() {
    const page = this.__pages[this.__selectedTab];

    return page.render();
  }

  __renderReadyPage() {
    return html`
      <neb-patients-ready-page
        id="${ELEMENTS.readyPage.id}"
        class="page"
        .importJobId="${this.importJobId}"
        .totalCount="${this.__readyCount}"
        .onCancel="${this.__handlers.cancel}"
        .onSuccess="${this.__handlers.success}"
        .onError="${this.__handlers.error}"
        .onAfterPatientSaved="${this.__handlers.afterPatientSaved}"
      ></neb-patients-ready-page>
    `;
  }

  __renderAttentionPage() {
    return html`
      <neb-patients-attention-page
        id="${ELEMENTS.attentionPage.id}"
        class="page"
        .importJobId="${this.importJobId}"
        .totalCount="${this.__attentionCount}"
        .onCancel="${this.__handlers.cancel}"
      ></neb-patients-attention-page>
    `;
  }

  __renderDuplicatePage() {
    return html`
      <neb-patients-duplicate-page
        id="${ELEMENTS.duplicatePage.id}"
        class="page"
        .importJobId="${this.importJobId}"
        .totalCount="${this.__duplicateCount}"
        .onCancel="${this.__handlers.cancel}"
      ></neb-patients-duplicate-page>
    `;
  }

  __renderExistingPage() {
    return html`
      <neb-patients-existing-page
        id="${ELEMENTS.existingPage.id}"
        class="page"
        .importJobId="${this.importJobId}"
        .totalCount="${this.__existingCount}"
        .onCancel="${this.__handlers.cancel}"
      ></neb-patients-existing-page>
    `;
  }
}

customElements.define(
  'neb-patient-roster-upload-pages',
  NebPatientRosterUploadPages,
);
