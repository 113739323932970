import {
  openAlert,
  closeAlertBanners,
} from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';

import { createAlertsForPatientState } from './neb-alerts-for-patient-state';

const wait = duration => new Promise(resolve => setTimeout(resolve, duration));

export const DISPLAY_ON = Object.freeze({
  scheduling: 'scheduling',
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  charting: 'charting',
  patientRecord: 'patientRecord',
  ledger: 'ledger',
  payment: 'payment',
});
export class NebAlertBannerController {
  constructor(displayOn, onChange) {
    const noOp = () => {};

    this.__patientId = null;
    this.__displayOn = displayOn;
    this.__onChange = onChange || noOp;
    this.__nebAlerts = createAlertsForPatientState((alerts, overlayOpened) =>
      this._onAlertsChange(alerts, overlayOpened),
    );
  }

  async _onAlertsChange(alerts, overlayOpened) {
    if (!this.__alertsOpened) {
      await store.dispatch(closeAlertBanners());

      if (!overlayOpened) {
        await this.__openAlerts(this.__getAlertsToDisplay(alerts));
      }
    }

    this.__onChange(alerts);
  }

  connect() {
    this.__alertsOpened = false;
  }

  disconnect() {
    return this.reset();
  }

  reset() {
    this.__patientId = null;
    this.__alertsOpened = false;
    return store.dispatch(closeAlertBanners());
  }

  async update(patientId) {
    if (!patientId || patientId === this.__patientId) return;
    this.__patientId = patientId;
    this.__alertsOpened = false;
    await store.dispatch(closeAlertBanners());

    await this.__nebAlerts.fetchBanner(patientId);
  }

  async __openAlerts(alerts) {
    for (let index = 0; index < alerts.length; index++) {
      const alert = alerts[index];
      await wait(5);
      await store.dispatch(openAlert(alert.message, this.__patientId));
      this.__alertsOpened = true;
    }
  }

  __getAlertsToDisplay(alerts) {
    return alerts.filter(alert => alert[this.__displayOn]);
  }
}

export const createBannerController = (displayOn, onChange) =>
  new NebAlertBannerController(displayOn, onChange);
