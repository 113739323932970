import { _fetching, _fetched, _fetchingError, _reset } from './actions/fetch';
import { _selected } from './actions/selected';

const prefix = 'PROVIDERS_AVAILABILITY_PUBLIC';
const PROVIDERS_AVAILABILITY_FETCHING = `${prefix}_FETCHING`;
const PROVIDERS_AVAILABILITY_FETCHED = `${prefix}_FETCHED`;
const PROVIDERS_AVAILABILITY_FETCH_FAILED = `${prefix}_FETCH_FAILED`;
const PROVIDERS_AVAILABILITY_ITEMS_RESET = `${prefix}_ITEMS_RESET`;
const SELECTED_APPOINTMENT_TYPE_PROVIDERS_AVAILABILITY =
  'SELECTED_APPOINTMENT_TYPE_PROVIDERS_AVAILABILITY';

export const providersAvailabilityPublicReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    items: undefined,
    message: undefined,
    selectedAppointmentTypeProviderAvailability: undefined,
  },
  action,
) => {
  switch (action.type) {
    case PROVIDERS_AVAILABILITY_FETCHING:
      return _fetching(state);

    case PROVIDERS_AVAILABILITY_FETCHED:
      return _fetched(state, action);

    case PROVIDERS_AVAILABILITY_FETCH_FAILED:
      return _fetchingError(state, action);

    case SELECTED_APPOINTMENT_TYPE_PROVIDERS_AVAILABILITY:
      return _selected(state, action);

    case PROVIDERS_AVAILABILITY_ITEMS_RESET:
      return _reset(state, action);

    default:
      return state;
  }
};
