import '@polymer/iron-icon/iron-icon';

import { html, LitElement } from 'lit';

import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  searchInput: {
    id: 'search-input',
  },
  searchBarContainer: {
    id: 'search-bar-container',
  },
  clearIcon: {
    id: 'clear-icon',
  },
};

const KEY_INPUT_DELAY = 1000;

class NebSearchBar extends LitElement {
  static get properties() {
    return {
      searchText: String,
      placeholderText: String,
      disabled: Boolean,
      debounceFilter: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.searchText = '';
    this.placeholderText = 'Search...';
    this.debounceFilter = false;

    this.onSearch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      input: () => {
        const searchText = this.shadowRoot.getElementById(
          ELEMENTS.searchInput.id,
        ).value;
        return this.debounceFilter
          ? this.__handlers.getSearchText(searchText)
          : this.onSearch(searchText);
      },
      clearSearchInput: () => {
        this.setSearchInputValue('');
      },
      getSearchText: text => {
        if (this.__timeout) clearTimeout(this.__timeout);

        const buildSearchText = async () => {
          this.__timeout = null;
          await this.__searchOnText(text);
        };

        if (text === '') {
          return this.__searchOnText(text);
        }

        this.__timeout = setTimeout(buildSearchText, KEY_INPUT_DELAY);

        return undefined;
      },
    };
  }

  firstUpdated() {
    this.elements = {
      input: this.shadowRoot.getElementById(ELEMENTS.searchInput.id),
    };
  }

  updated(changedProps) {
    if (changedProps.has('searchText')) {
      this.elements.input.value = this.searchText;
    }
  }

  async setFocus() {
    if (!this.elements) await this.updateComplete;
    this.elements.input.focus();
  }

  async setBlur() {
    if (!this.elements) await this.updateComplete;
    this.elements.input.blur();
  }

  setSearchInputValue(value) {
    const searchInput = this.shadowRoot.getElementById(ELEMENTS.searchInput.id);
    searchInput.value = value;
    this.searchText = value;
    this.__handlers.getSearchText(value);
  }

  clear() {
    this.__handlers.clearSearchInput();
  }

  __searchOnText(text) {
    const prevSearch = this.searchText || '';

    this.searchText = text !== prevSearch ? text : prevSearch;

    return this.onSearch(this.searchText);
  }

  __renderClearButton() {
    return this.searchText
      ? html`
          <iron-icon
            id="${ELEMENTS.clearIcon.id}"
            icon="neb:clear"
            class="clear-icon clear-search-icon"
            @click="${this.__handlers.clearSearchInput}"
          ></iron-icon>
        `
      : '';
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          width: 400px;
        }

        .search-bar-container {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          border-radius: 3px;
          padding: 8px 13px;
          height: 22px;
          background-color: ${CSS_COLOR_WHITE};
          border: 1px solid ${CSS_COLOR_GREY_2};
          color: ${CSS_COLOR_GREY_1};
          align-items: center;
        }

        .search-input {
          margin-left: 6px;
          outline: none;
          border: none;
          font-size: 14px;
          width: 100%;
          overflow: auto;
        }

        .search-input[disabled],
        .search-input[disabled]::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.247);
          background-color: white;
        }

        .icon-color.clear-search-icon {
          border: 1px solid ${CSS_COLOR_GREY_1};
          border-radius: 50%;
          height: 17px;
          width: 17px;
        }

        .search-icon {
          flex: 0 0 20px;
        }

        .clear-icon {
          flex: 0 0 20px;
          cursor: pointer;
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div
        id="${ELEMENTS.searchBarContainer.id}"
        class="search-bar-container"
        @click="${this.__handlers.click}"
      >
        <iron-icon class="search-icon" icon="neb:search"></iron-icon>
        <input
          id="${ELEMENTS.searchInput.id}"
          class="search-input"
          autocomplete="off"
          placeholder="${this.placeholderText}"
          @input="${this.__handlers.input}"
          value="${this.searchText}"
          ?disabled="${this.disabled}"
        />
        ${this.__renderClearButton()}
      </div>
    `;
  }
}

window.customElements.define('neb-search-bar', NebSearchBar);
