import '../../neb-popup-header';
import { html, css } from 'lit';

import Overlay from '../neb-overlay';
import '../../../../../../src/components/controllers/claim-errors/neb-payer-plan-controller';

export const ELEMENTS = {
  payerPlanController: { id: 'payer-plan-controller' },
};
class NebOverlayPayerPlan extends Overlay {
  static get properties() {
    return {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  renderContent() {
    return html`
      <neb-payer-plan-controller
        id="${ELEMENTS.payerPlanController.id}"
        .model="${this.model}"
        .layout="${this.layout}"
        .onCancel="${this.handlers.dismiss}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-payer-plan-controller>
    `;
  }
}

window.customElements.define('neb-overlay-payer-plan', NebOverlayPayerPlan);
