export const CHARGES_SELECTOR = Object.freeze({
  createItem: () => ({
    chargeId: '',
    procedure: '',
    description: '',
    modifiers: ['', '', '', ''],
    units: '1',
    taxName: '',
    taxRate: 0,
    diagnosisPointers: [],
    unitCharge: 0,
    feeScheduleName: null,
    feeScheduleCharge: null,
    allowedAmount: null,
    EPSDTCode: false,
    suppressFromClaim: false,
    billWithTreatmentInitiationDate: false,
    billWithXrayDate: false,
    billedAmount: 0,
    posted: false,
    repost: false,
  }),
});

export const filterEncounters = (encounters, { value }) => {
  if (!value) return encounters;
  const eachTerm = value.split('|');
  const regex = new RegExp(`${eachTerm}`);
  return encounters.filter(({ label }) => regex.test(label));
};
