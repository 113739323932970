import { html, css } from 'lit';

import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import '../../forms/ledger/neb-form-ordering-provider';

export const ELEMENTS = {
  form: { id: 'form' },
};

export const TEXT_TITLE = 'Edit Ordering Provider';

class NebPopupOrderingProvider extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __formModel: Object,
    };
  }

  initState() {
    super.initState();

    this.title = TEXT_TITLE;
    this.__formModel = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      saveOrderingProvider: e => this.onClose(e),
      cancel: () => this.onClose(false),
    };
  }

  modelChanged() {
    this.__formModel = {
      orderingProviderCredentials: this.model.orderingProviderCredentials,
      orderingProviderFirstName: this.model.orderingProviderFirstName,
      orderingProviderLastName: this.model.orderingProviderLastName,
      orderingProviderMiddleName: this.model.orderingProviderMiddleName,
      orderingProviderNPI: this.model.orderingProviderNPI,
      orderingProviderOtherId: this.model.orderingProviderOtherId,
      orderingProviderQualifier: this.model.orderingProviderQualifier,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;
          width: 600px;
        }

        :host([layout='small']) {
          width: 100%;
          height: 100%;
          top: -40px;
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          width: 100%;
          height: 100%;
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="popup-body">
        <neb-form-ordering-provider
          id="${ELEMENTS.form.id}"
          .model="${this.__formModel}"
          .onCancel="${this.handlers.cancel}"
          .onSave="${this.handlers.saveOrderingProvider}"
        >
        </neb-form-ordering-provider>
      </div>
    `;
  }
}

customElements.define('neb-popup-ordering-provider', NebPopupOrderingProvider);
