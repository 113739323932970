import './neb-claim-form-cell';
import '../../../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_4,
  CSS_BORDER_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_SIZE_CAPTION,
  CSS_COLOR_GREY_1,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
} from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  headerCells: { selector: '.header-cell' },
  dataCells: { selector: '.data-cell' },
  expandIcon: { id: 'expand-icon' },
  container: { id: 'container' },
  problemText: { id: 'problem-text' },
};

class NebClaimFormCellTwoColumn extends LitElement {
  static get properties() {
    return {
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
      problemText: {
        type: String,
        reflect: true,
        converter: {
          toAttribute: val => val || null,
        },
      },
      type: String,
      label: String,
      name: String,
      config: Array,
      errors: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([problemText]) .container {
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderRight][problemText]) .container {
          border-right: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderLeft][problemText]) .container {
          border-left: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderTop][problemText]) .container {
          border-top: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderBottom][problemText]) .container {
          border-bottom: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([renderExpandIcon]) .container:hover {
          background-color: ${CSS_COLOR_GREY_4};
          cursor: pointer;
        }

        .container {
          display: flex;

          width: 100%;
          padding: 10px 10px 10px ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_1};
          border-right: ${CSS_BORDER_GREY_1};
        }

        .header-cell {
          display: flex;
          font-size: ${CSS_FONT_SIZE_CAPTION};
          height: 24px;
        }

        .data-cell {
          padding-top: 13px;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .container:hover .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .flex-1 {
          flex: 1 0 0;
        }

        .icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .row {
          display: flex;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.config = [];
    this.renderExpandIcon = false;

    this.type = '';
    this.label = '';
    this.name = '';

    this.errors = {
      resubmissionCode: '',
      originalReferenceNumber: '',
    };

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => {
        this.onClick({
          name: this.name,
          type: this.type,
          title: this.label,
        });
      },
    };
  }

  get __bodyTemplate() {
    return this.config.map(
      ({ data }) => html` <div class="data-cell flex-1">${data}</div> `,
    );
  }

  get __expandButtonTemplate() {
    return this.renderExpandIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.expandIcon.id}"
            class="icon"
            icon="neb:expand"
          ></neb-icon>
        `
      : '';
  }

  get __headerTemplate() {
    return this.config.map(
      ({ label }) => html` <div class="header-cell flex-1">${label}</div> `,
    );
  }

  get __problemTextTemplate() {
    return this.problemText
      ? html` <div id="${ELEMENTS.problemText.id}">${this.problemText}</div> `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <div class="flex-1">
          <div class="row">${this.__headerTemplate}</div>
          <div class="row">${this.__bodyTemplate}</div>
          <div class="footer">${this.__problemTextTemplate}</div>
        </div>

        ${this.__expandButtonTemplate}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-claim-form-cell-two-column',
  NebClaimFormCellTwoColumn,
);
