import { css, html, LitElement } from 'lit';
import '../../misc/neb-icon';

import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import { getQuickActions } from '../../../../packages/neb-api-client/src/appointment-api-client';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_BANNER_SUCCESS_BACKGROUND_COLOR,
  CSS_BANNER_SUCCESS_BORDER_COLOR,
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLUE_BORDER,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_RED_BORDER,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import Debouncer from '../../../../packages/neb-utils/debouncer';
import {
  getAppointmentId,
  getQuickActionHandlers,
  handleSecondaryAction,
  isUnmatchedPatient,
  validQuickActionResponse,
} from '../../../utils/context/appointment-quick-action';
import { CONTEXT_KEYS, openContext } from '../../../utils/context/constants';
import '../../cards/neb-event-card';

export const ELEMENTS = {
  footer: { id: 'footer' },
  header: { id: 'header' },
  items: { selector: '[id^=item-]', class: 'items', tag: 'neb-event-card' },
  noItemsLabel: { id: 'label-no-items' },
  list: { id: 'list' },
  icon: { id: 'icon' },
  dragArea: { id: 'dragArea' },
  areaSlots: { selector: '.area-slots' },
};

const TRANSPARENT = 'transparent';
const CSS_COLOR_RED_OVERLAY = css`rgba(176, 0, 32, 0.15)`;

class NebListDragDrop extends LitElement {
  static get properties() {
    return {
      __contextOpen: Boolean,
      __draggedItem: Object,
      __dragover: { type: Boolean, reflect: true, attribute: 'dragover' },

      changeable: { type: Boolean, reflect: true },
      draggable: { type: Boolean, reflect: true },
      showFooter: { type: Boolean, reflect: true },
      layout: { type: String, reflect: true },
      header: String,
      items: Array,
      warning: { type: Boolean, reflect: true },
      areaConfig: Array,
      showDragArea: Boolean,
      simpleDrag: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          background: ${CSS_COLOR_WHITE};
          position: relative;
        }

        :host([changeable]) .header-underline {
          cursor: pointer;
        }

        .footer {
          padding: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_BLUE_BORDER};
        }

        .header {
          max-width: 150ch;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0px ${CSS_SPACING} 10px;
          color: ${CSS_COLOR_BLUE_BORDER};
          font-weight: bold;
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .header-underline {
          max-width: 150ch;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0px ${CSS_SPACING} 10px;
          color: ${CSS_COLOR_BLUE_BORDER};
          font-weight: bold;
          border-bottom: ${CSS_BORDER_GREY_2};
          text-decoration: underline;
        }

        .items {
          display: flex;
          flex-flow: column nowrap;
          overflow-y: auto;
          min-height: 0;
        }

        .items::-webkit-scrollbar {
          display: none;
        }

        .list {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr auto;
          border-top: ${CSS_BORDER_GREY_2};
          height: 100%;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        :host([dragover]) .items,
        .transparent {
          opacity: 40%;
        }

        :host([dragover]) .list {
          border: 2px dashed ${CSS_COLOR_BLUE_BORDER};
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([dragover][warning]) .list {
          border: 2px solid ${CSS_COLOR_RED_BORDER};
          background-color: ${CSS_COLOR_RED_OVERLAY};
        }

        .text {
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
          margin: 10px;
        }

        .icon {
          cursor: pointer;
          width: 12px;
          height: 10px;

          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(-90deg);
        }

        .area {
          z-index: 10;
          position: absolute;
          display: grid;
          align-items: stretch;
          width: 100%;
          height: 100%;
        }

        .area-slots {
          position: relative;
          background-color: ${CSS_COLOR_GREY_4};
          border: 2px dashed ${CSS_COLOR_BLUE_BORDER};
          border-bottom: none;
          text-align: center;
          height: 100%;
          border-radius: 5px;
          max-width: 150ch;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0px ${CSS_SPACING} 10px;
          color: ${CSS_COLOR_BLUE_BORDER};
          font-weight: bold;
        }

        .area-slots:last-child {
          border-bottom: 2px dashed ${CSS_COLOR_BLUE_BORDER};
        }

        .area-highlight {
          background-color: ${CSS_BANNER_SUCCESS_BACKGROUND_COLOR};
          border-color: ${CSS_BANNER_SUCCESS_BORDER_COLOR};
          color: ${CSS_BANNER_SUCCESS_BORDER_COLOR};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__contextOpen = false;
    this.__dragover = false;
    this.warning = false;
    this.simpleDrag = false;

    this.footer = '';
    this.header = '';
    this.items = [];
    this.areaConfig = [];
    this.showDragArea = false;

    this.onAddWalkIn = () => {};

    this.onDisplayCard = () => {};

    this.onDragEnd = () => {};

    this.onDrop = () => {};

    this.onSelectItem = () => {};

    this.onChangeTitle = () => {};

    this.onQuickAction = () => {};

    this.onShowArea = () => {};

    this.onPotentialMatch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addWalkIn: () => this.onAddWalkIn(),
      click: ({ target }) => {
        const idx = target.id.split('-')[1];

        this.onSelectItem(this.items[idx]);
      },
      displayCard: model => this.onDisplayCard(model),
      dragstart(ev) {
        this.__draggedItem = ev.target;

        this.__draggedItem.model.from = this.id;

        this.__draggedItem.classList.add(TRANSPARENT);

        ev.dataTransfer.effectAllowed = 'move';

        ev.dataTransfer.setData(
          'application/json',
          JSON.stringify(this.__draggedItem.model),
        );

        this.onShowArea(true, this.__draggedItem.model.status);
      },
      dragend(ev) {
        this.onDragEnd(this.__draggedItem.model, ev.dataTransfer.dropEffect);

        this.onShowArea(false, this.__draggedItem.model.status);

        this.__draggedItem.classList.remove(TRANSPARENT);

        this.__draggedItem = null;
      },
      dragleave: ev => this.__adjustDragOverStyle(ev, 'leave', true),
      dragover: ev => {
        if (!this.simpleDrag) return;

        ev.preventDefault();

        if (this.__draggedItem) return;

        this.__dragover = true;

        this.__debouncer.debounce();
      },
      drop: ev => {
        ev.preventDefault();

        if (this.__draggedItem) return;

        const data = JSON.parse(ev.dataTransfer.getData('application/json'));

        data.to = this.id;

        this.__adjustDragOverStyle(ev, 'leave');

        this.onDrop(data);
      },
      stopPropagation: ev => ev.stopPropagation(),
      changeTitle: () => {
        this.onChangeTitle();
      },
      openContext: async (e, item) => {
        if (item && item.id) {
          e.preventDefault();
          e.stopPropagation();

          if (isUnmatchedPatient(item)) {
            this.onPotentialMatch(item);
            return;
          }

          const appointmentId = getAppointmentId(item);

          const { appointment, ...actions } = await getQuickActions(
            appointmentId,
            true,
          );

          const items = getQuickActionHandlers(appointment, actions);

          if (items.length) {
            this.__contextOpen = true;
            const res = await openContext(CONTEXT_KEYS.MENU, {
              left: e.x,
              top: e.y,
              items,
              data: {},
            });

            await this.__handleQuickActionResponse(appointment, res);
          }
        }
      },
      dragoverArea: ev => {
        ev.preventDefault();

        if (this.__draggedItem) return;

        ev.target.classList.add('area-highlight');

        this.__debouncer.debounce();
      },
      dragleaveArea: ev => {
        ev.target.classList.remove('area-highlight');

        this.__adjustDragOverStyle(ev, 'leave', true);
      },
      dropArea: ev => {
        ev.preventDefault();

        if (this.__draggedItem) return;

        const data = JSON.parse(ev.dataTransfer.getData('application/json'));

        // toElement does not exist in firefox so we need to use the other one
        const target = ev.explicitOriginalTarget || ev.toElement;

        data.to = target.getAttribute('id');

        this.onDrop(data);
      },
    };
  }

  __initServices() {
    this.__debouncer = new Debouncer(() => this.__clearStyles(), 100);
  }

  __adjustDragOverStyle(ev, action, validateFrom) {
    ev.preventDefault();

    if (this.__draggedItem) return;

    if (validateFrom) {
      const target = ev.target.id.split('-')[0];

      if (target === 'item') return;
    }

    this.__dragover = action === 'enter';
  }

  __clearStyles() {
    this.__dragover = false;
  }

  async __handleQuickActionResponse(item, res) {
    if (validQuickActionResponse(res)) {
      this.__contextOpen = false;
      this.onQuickAction();
    }

    if (res && res.secondaryAction) {
      this.__contextOpen = false;

      const secondaryRes = await handleSecondaryAction(item, res);

      if (validQuickActionResponse(secondaryRes)) {
        this.onQuickAction();
      }
    }
  }

  __renderFooter() {
    return this.showFooter
      ? html`
          <neb-button-action
            id="${ELEMENTS.footer.id}"
            class="footer"
            label="Add Walk-In Patient"
            .onClick="${this.__handlers.addWalkIn}"
          ></neb-button-action>
        `
      : '';
  }

  __renderItems() {
    return this.items.length
      ? this.items.map((item, idx) => {
          const rightClick = e => this.__handlers.openContext(e, item);

          return html`
            <neb-event-card
              id="item-${idx}"
              class="item"
              draggable="${this.draggable}"
              .model="${item}"
              .layout="${this.layout}"
              .contextOpen="${this.__contextOpen}"
              .onDisplay="${this.__handlers.displayCard}"
              @click="${this.__handlers.click}"
              @dragstart="${this.__handlers.dragstart}"
              @dragend="${this.__handlers.dragend}"
              @dragleave="${this.__handlers.stopPropagation}"
              @contextmenu="${rightClick}"
            ></neb-event-card>
          `;
        })
      : html`
          <div id="${ELEMENTS.noItemsLabel.id}" class="text">
            There are no items in this list.
          </div>
        `;
  }

  __renderDragArea(config) {
    return this.showDragArea
      ? html`
          <div id="${ELEMENTS.dragArea.id}" class="area">
            ${config.map(
              item => html`
                <div
                  @drop="${this.__handlers.dropArea}"
                  @dragover="${this.__handlers.dragoverArea}"
                  @dragleave="${this.__handlers.dragleaveArea}"
                  id="${item.id}"
                  class="area-slots"
                >
                  ${item.label}
                </div>
              `,
            )}
          </div>
        `
      : '';
  }

  __renderIcon() {
    return this.changeable
      ? html`
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon"
            icon="neb:chevron"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      ${this.__renderDragArea(this.areaConfig)}
      <div
        id="${ELEMENTS.list.id}"
        class="list"
        @drop="${this.__handlers.drop}"
        @dragover="${this.__handlers.dragover}"
        @dragleave="${this.__handlers.dragleave}"
      >
        <div
          id="${ELEMENTS.header.id}"
          class="${this.changeable ? 'header-underline' : 'header'}"
          @click="${this.__handlers.changeTitle}"
        >
          ${this.header} ${this.__renderIcon()}
        </div>

        <div class="${ELEMENTS.items.class}">
          <div class="content">${this.__renderItems()}</div>
        </div>

        ${this.__renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-list-drag-drop', NebListDragDrop);
