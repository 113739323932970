import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import {
  NebActionBar,
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-action-bar';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  saveButton: { id: 'save-button' },
  createEncounterCheckbox: { id: 'create-encounter-checkbox' },
};

class NebActionBarCheckInOut extends NebActionBar {
  static get properties() {
    return {
      createEncounter: { type: Boolean },
      createEncounterDisabled: { type: Boolean },
      displayCreateEncounter: { type: Boolean },
      hideSaveButton: { type: Boolean },
    };
  }

  __initState() {
    super.__initState();
    this.createEncounter = false;
    this.createEncounterDisabled = false;
    this.displayCreateEncounter = false;
    this.hideSaveButton = false;

    this.onSave = () => {};

    this.onChangeCreateEncounter = () => {};
  }

  __initHandlers() {
    super.__initHandlers();

    this.__handlers = {
      ...this.__handlers,
      save: () => this.onSave(),
      changeCreateEncounter: state => {
        this.onChangeCreateEncounter(state);
        this.createEncounter = state.value;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([displayCreateEncounter]) {
          height: 110px;
        }

        :host([displayCreateEncounter]) .container {
          flex-wrap: wrap;
          gap: 15px;
        }

        :host([displayCreateEncounter]) .container-item {
          display: flex;
          align-items: center;
          flex-basis: 100%;
        }
      `,
    ];
  }

  __renderSaveButton() {
    return this.hideSaveButton
      ? ''
      : html`
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button"
            label="Save"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
            ?disabled="${this.confirmDisabled}"
            unelevated
          ></neb-button>
        `;
  }

  __renderConfirmButton() {
    return this.confirmLabel
      ? html`
          <neb-button
            id="${ELEMENTS.confirmButton.id}"
            class="button button-confirm"
            label="${this.confirmLabel}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
            ?disabled="${this.confirmDisabled}"
            unelevated
          ></neb-button>
        `
      : '';
  }

  __renderButtons() {
    return html`
      ${this.__renderSaveButton()}

      <div class="button-secondary-container">
        ${this.__renderConfirmButton()}

        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          label="${this.cancelLabel}"
          .role="${this.__getCancelButtonRole()}"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>

        ${this.__renderRemoveButton()}
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.displayCreateEncounter
          ? html`
              <div class="container-item">${this.__renderButtons()}</div>
              <div class="container-item">
                ${this.__renderCreateEncounterCheckbox()}
              </div>
            `
          : this.__renderButtons()}
      </div>
    `;
  }

  __renderCreateEncounterCheckbox() {
    return html`
      <div class="container-item">
        <neb-checkbox
          id="${ELEMENTS.createEncounterCheckbox.id}"
          class="checkbox"
          name="createEncounter"
          label="Create Encounter"
          .checked="${this.createEncounter}"
          .onChange="${this.__handlers.changeCreateEncounter}"
          .disabled="${this.createEncounterDisabled}"
        ></neb-checkbox>
      </div>
    `;
  }
}

customElements.define('neb-action-bar-check-in-out', NebActionBarCheckInOut);
