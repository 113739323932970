import { html } from 'lit';

import {
  NebMDBaseTextField,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-md-base-textfield';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  input: {
    id: 'textfield-input',
  },
};

class NebMDTextField extends NebMDBaseTextField {
  static get properties() {
    return {
      inputType: String,
      inputMode: String,
    };
  }

  get inputEl() {
    return this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  initState() {
    super.initState();
    this.inputType = 'text';
    this.maxLength = 255;
    this.inputMode = 'text';
  }

  renderTextfield() {
    return html`
      <input
        id="${ELEMENTS.input.id}"
        class="mdc-text-field__input"
        autocomplete="off"
        type="${this.inputType}"
        inputmode="${this.inputMode}"
        pattern="${this.inputMode === 'numeric' ? '[0-9]*' : '.*'}"
        maxlength="${this.maxLength}"
        minlength="${this.minLength}"
        placeholder="${this.getPlaceholder()}"
        aria-controls="${ELEMENTS.helperText.id}"
        aria-describedby="${ELEMENTS.helperText.id}"
        @input="${this.handlers.input}"
        @keydown="${this.handlers.keydown}"
        ?readonly="${this.disabled}"
      />

      ${this.__mdcNotchHTML(ELEMENTS.input.id)}
    `;
  }
}

customElements.define('neb-md-textfield', NebMDTextField);
