import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/neb-calendar-popover';

import { css, html } from 'lit';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  totalsHeader: { id: 'totals-header' },
  totalsFooter: { id: 'totals-footer' },
  billingNoteIcons: { selector: '[id^=billing-note-icon-]' },
  dateColumnHeader: { id: 'date-column-header' },
  calendarSearchIcon: { id: 'calendar-search-icon' },
  calendarSearchPopover: { id: 'calendar-search-popover' },
  calendarSearchPopoverBlocker: { id: 'calendar-search-popover-blocker' },
};

class NebTableCharges extends NebTable {
  static get properties() {
    return {
      totals: Object,
      __showCalendarSearchPopover: Boolean,
      __selectedDate: Object,
    };
  }

  initState() {
    super.initState();

    this.totals = {};

    this.__selectedDate = null;
    this.__showCalendarSearchPopover = false;

    this.onBillingNoteIconClick = () => {};

    this.isDateSelectable = () => true;

    this.onDateSelected = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      billingNoteIconClick: e => {
        e.stopPropagation();

        this.onBillingNoteIconClick(
          this.model[e.currentTarget.getAttribute('rowIndex')],
        );
      },

      calendarSearchPopoverClick: e => {
        e.stopPropagation();
      },

      showCalendarSearchPopover: e => {
        e.stopPropagation();
        this.__showCalendarSearchPopover = true;
      },

      hideCalendarSearchPopover: e => {
        e.stopPropagation();
        this.__showCalendarSearchPopover = false;
      },

      isDateSelectable: date => this.isDateSelectable(date),

      dateSelected: date => {
        this.__selectedDate = date;
        this.__showCalendarSearchPopover = false;
        this.onDateSelected(date);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header-container {
          background-color: white;
          position: sticky;
          z-index: 1;
          top: 0px;
        }

        .row-totals {
          display: flex;
          padding-right: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .cell-header {
          /* This matches neb-table existing padding-bottom for this class */
          padding-top: 18px;
        }

        .cell:not(:last-child) {
          /* This buys us enough horizontal space to make the table fit on */
          /* smallest supported desktop width without horizontal scrolling */
          margin-right: 0px;
        }

        .cell[truncate] {
          /* This fixes a slight vertical misalignment of truncated cells */
          /* relative to their non-truncated counterparts on the same row */
          display: inherit;
        }

        .row-header,
        .row-data {
          /* This makes room for the billing note icon column */
          padding-left: 0;
        }

        .billing-note-icon {
          cursor: pointer;
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: auto;
        }

        .date-column-header {
          position: relative;
          display: flex;
          align-items: flex-end;
          gap: 10px;
        }

        .container-arrow {
          /* This removes extra margin - parent is using flex gap instead */
          margin-left: 0;
        }

        .calendar-search-icon {
          width: 16px;
          height: 16px;
          align-self: center;
        }

        .calendar-search-popover-blocker {
          background-color: transparent;
          position: fixed;
          inset: 0;
        }

        .calendar-search-popover {
          --popover-caret-translate: -101px;
          translate: -30px 20px;
          position: absolute;
          left: 0;
          top: 0;
        }

        @keyframes fade {
          from {
            background: rgb(192, 230, 255);
          }
          to {
            background: transparent;
          }
        }

        .highlight {
          animation: fade 3s;
        }
      `,
    ];
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'dateOfService':
        return html`
          <div id="${ELEMENTS.dateColumnHeader.id}" class="date-column-header">
            ${super.renderHeaderCell(columnConfig)}

            <neb-icon
              id="${ELEMENTS.calendarSearchIcon.id}"
              class="calendar-search-icon"
              icon="neb:search"
              @click="${this.handlers.showCalendarSearchPopover}"
            ></neb-icon>

            ${this.__renderCalendarSearchPopover()}
          </div>
        `;

      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  __renderCalendarSearchPopover() {
    if (!this.__showCalendarSearchPopover) {
      return '';
    }

    return html`
      <div
        id="${ELEMENTS.calendarSearchPopoverBlocker.id}"
        class="calendar-search-popover-blocker"
        @click="${this.handlers.hideCalendarSearchPopover}"
      ></div>

      <neb-calendar-popover
        id="${ELEMENTS.calendarSearchPopover.id}"
        class="calendar-search-popover"
        .selectedDate="${this.__selectedDate}"
        .onTodaySelected="${this.handlers.dateSelected}"
        .onDateSelected="${this.handlers.dateSelected}"
        .isDateSelectable="${this.handlers.isDateSelectable}"
        .isPickerable="${true}"
        ?momentFlag="${true}"
        @click="${this.handlers.calendarSearchPopoverClick}"
      ></neb-calendar-popover>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    if (columnConfig.key === 'hasBillingNote') {
      return value
        ? html`
            <neb-icon
              id="billing-note-icon-${rowIndex}"
              class="billing-note-icon"
              icon="neb:editNote"
              rowIndex="${rowIndex}"
              @click="${this.handlers.billingNoteIconClick}"
            ></neb-icon>
          `
        : '';
    }

    return super.renderDataCell(value, columnConfig, rowIndex, name, error);
  }

  __renderTotalsRow(id) {
    if (!this.model.length) {
      return '';
    }

    return html`
      <div id="${id}" class="row-totals">
        ${this.config.map(
          cell => html`
            <span class="cell-header" style="flex: ${cell.flex}">
              ${this.totals[cell.key]}
            </span>
          `,
        )} <span class="cell-detail"></span>
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <div class="header-container">
        ${super.__renderHeader()}
        ${this.__renderTotalsRow(ELEMENTS.totalsHeader.id)}
      </div>
    `;
  }

  renderFooter() {
    return this.__renderTotalsRow(ELEMENTS.totalsFooter.id);
  }
}

customElements.define('neb-table-charges', NebTableCharges);
