import * as actions from '../../actions/item-actions';

import * as fetchHandler from './handlers/fetch';

const __getStaleState = state => ({ ...state, stale: true });

export const allItemsReducer = (
  state = {
    fetching: false,
    fetched: false,
    fetchingError: null,
    items: [],
    stale: false,
  },
  action,
) => {
  switch (action.type) {
    case actions.ITEM_FETCHING:
      return fetchHandler._fetching(state, action);

    case actions.ITEM_FETCHED:
      return fetchHandler._fetched(state, action);

    case actions.ITEM_FETCH_ERROR:
      return fetchHandler._fetchError(state, action);

    case actions.ITEM_CREATED:
    case actions.ITEM_UPDATED:
    case actions.ITEM_DELETED:
      return __getStaleState(state);

    default:
      return state;
  }
};
