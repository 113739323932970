import '../../../tables/neb-table-ledger-balance-statements';
import '../../../controls/neb-button-action';
import '../../../neb-text';
import '../../../../../../../src/components/filters/neb-filters-ledger-statements';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css } from 'lit';

import { NO_ITEMS_INITIAL_LOAD } from '../../../../../../../src/utils/user-message';
import * as ledgerStatementApiClient from '../../../../../../neb-api-client/src/ledger-statement-api-client';
import {
  toFormatDate,
  MONTH_DAY_YEAR,
} from '../../../../../../neb-input/nebFormatUtils';
import { POPUP_RENDER_KEYS } from '../../../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import { centsToCurrency } from '../../../../../../neb-utils/formatters';
import { currency } from '../../../../../../neb-utils/masks';
import { printPdf } from '../../../../../../neb-utils/neb-pdf-print-util';
import { EMPTY_RESPONSE } from '../../../../../../neb-utils/services/fetch';
import { MODE } from '../../../../../../neb-utils/table';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../neb-page-collection';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonGenerate: {
    id: 'generate-new-statement',
  },
  buttonViewBatches: {
    id: 'view-batches',
  },
  content: { id: 'content' },
  description: {
    id: 'statements-description',
  },
  filters: { id: 'filters' },
};

const POLLING_DURATION = 120000;

const EMPTY_STATEMENT_MESSAGE_PATIENT =
  'There are no statements available for this patient.';

const EMPTY_STATEMENT_MESSAGE_PRACTICE = 'There are no statements available.';
class NebLedgerBalanceStatementsCollectionPage extends CollectionPage {
  static get properties() {
    return {
      patientId: String,
      hasDisableInitialLoadFF: Boolean,
    };
  }

  static get config() {
    return {
      unifyForm: true,
      initialSortKey: 'createdAt',
      initialSortOrder: 'asc',
      tableConfig: [],
      useFetch: true,
    };
  }

  initState() {
    super.initState();

    this.patientId = '';

    this.__pollingInterval = null;
    this.hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      sort: (_, params) => this.service.setSortParams(params[0]),
      printSelectedStatement: (_, item) =>
        printPdf(ledgerStatementApiClient.fetchOne(item.id)),
      printSelectedBatch: batchId =>
        printPdf(ledgerStatementApiClient.fetchBatch(batchId)),
      openNewStatementPopup: async () => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.NEW_STATEMENT, {
          patientId: this.patientId,
        });

        if (accepted) {
          this.onChange();

          await this.service.fetch();
        }
      },
      openViewBatchesOverlay: () =>
        openOverlay(OVERLAY_KEYS.VIEW_BATCHES, ledgerStatementApiClient),
      navigateToPatientLedger: patientId => {
        navigate(`/patients/${patientId}/ledger/activity`);
      },
      applyFilters: model => {
        this.__hasAppliedFilters = true;

        const query = {
          dateFrom: model.date.from ? model.date.from.toISOString() : null,
          dateTo: model.date.to ? model.date.to.toISOString() : null,
          balanceMin: model.balance.min,
          balanceMax: model.balance.max,
          statementId: model.statementId,
          patientId: model.patientId,
          groupGuarantorName: model.guarantor,
        };

        Object.keys(query).forEach(key => {
          if (query[key]) {
            this.service.setQuery(key, query[key]);
          } else {
            this.service.unsetQuery(key);
          }
        });

        this.service.setPageIndex(0);
      },
      tableChange: () => this.service.fetch(),
      fetchData: query => {
        if (this.__isInitialLoadDisabled()) {
          return EMPTY_RESPONSE;
        }

        return ledgerStatementApiClient.fetchMany({
          ...query,
          ...(!query.patientId && this.patientId
            ? {
                patientId: this.patientId,
              }
            : {}),
        });
      },
    };
  }

  __buildConfig() {
    return [
      ...(!this.patientId
        ? [
            {
              sortable: true,
              truncate: true,
              key: 'patientName',
              label: 'Patient',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        sortable: true,
        truncate: true,
        key: 'createdAt',
        label: 'Date',
        flex: css`1 0 0`,
        formatter: value => (value ? toFormatDate(value, MONTH_DAY_YEAR) : ''),
      },
      {
        sortable: true,
        truncate: true,
        key: 'statementId',
        label: 'Statement ID',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        key: 'batchId',
        label: 'Batch ID',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        key: 'groupStatements',
        label: 'Grouped by Relationship',
        flex: css`1 0 0`,
        formatter: value => (value ? 'Yes' : 'No'),
      },
      {
        sortable: true,
        truncate: true,
        key: 'groupGuarantorName',
        label: 'Guarantor',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'balance',
        label: 'Balance',
        flex: css`1 0 0`,
        maxLength: 11,
        mask: currency,
        formatter: amount => (amount ? centsToCurrency(amount) : '$0.00'),
      },
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    this.__pollForItems();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearInterval(this.__pollingInterval);
  }

  fetch() {
    this.service.fetch();
  }

  __isInitialLoadDisabled() {
    return (
      this.hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  __pollForItems() {
    this.__pollingInterval = setInterval(async () => {
      await this.service.fetch();
    }, POLLING_DURATION);
  }

  __filterDates(statementBody) {
    if (statementBody.dateOfServiceFrom === null) {
      delete statementBody.dateOfServiceFrom;
    }

    if (statementBody.dateOfServiceTo === null) {
      delete statementBody.dateOfServiceTo;
    }
    return statementBody;
  }

  __getEmptyMessage() {
    if (this.__isInitialLoadDisabled()) {
      return NO_ITEMS_INITIAL_LOAD;
    }

    return this.patientId
      ? EMPTY_STATEMENT_MESSAGE_PATIENT
      : EMPTY_STATEMENT_MESSAGE_PRACTICE;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .description {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
          margin: 0;
        }

        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          width: 100%;
        }

        .inset {
          padding-left: ${CSS_SPACING};
        }

        .container-button {
          display: flex;
        }

        .header {
          margin-bottom: 0;
        }

        .container {
          overflow: visible;
        }
      `,
    ];
  }

  __renderDescription() {
    return this.patientId
      ? 'View and generate statements for the patient.'
      : 'View and generate batch statements for the selected patients.';
  }

  __renderViewBatches() {
    return !this.patientId
      ? html`
          <neb-button-action
            id="${ELEMENTS.buttonViewBatches.id}"
            class="inset"
            label="View Batches"
            leadingIcon="generateSubmit"
            .onClick="${this.handlers.openViewBatchesOverlay}"
          ></neb-button-action>
        `
      : '';
  }

  renderNoItemsContent() {
    return '';
  }

  renderHeader() {
    return html`
      <neb-page-collection-header
        id="${ELEMENTS.header.id}"
        class="header"
        .hideInactive="${null}"
        .layout="${this.layout}"
        .title="${this.getTitle()}"
      ></neb-page-collection-header>
    `;
  }

  renderTable() {
    const emptyMessage = this.__getEmptyMessage();

    return html`
      <div class="grid">
        <p class="description" id="${ELEMENTS.description.id}">
          ${this.__renderDescription()}
        </p>

        <div class="container-button">
          <neb-button-action
            id="${ELEMENTS.buttonGenerate.id}"
            class="inset"
            label="Generate New Statement"
            .onClick="${this.handlers.openNewStatementPopup}"
          ></neb-button-action>

          ${this.__renderViewBatches()}
        </div>

        <neb-filters-patient-ledger
          id="${ELEMENTS.filters.id}"
          class="filters"
          ?enablePatient="${!this.patientId}"
          .onApply="${this.handlers.applyFilters}"
        ></neb-filters-patient-ledger>

        <div
          id="${ELEMENTS.content.id}"
          class="content"
          style="margin-bottom: 0px;"
        >
          <neb-table-ledger-balance-statements
            id="${ELEMENTS.table.id}"
            class="cell-spacer"
            mode="${MODE.DETAIL}"
            emptyMessage="${emptyMessage}"
            .config="${this.__buildConfig()}"
            .model="${this.__tableState.pageItems}"
            .sortParams="${[this.__tableState.sortParams]}"
            .onSort="${this.handlers.sort}"
            .onSelect="${this.handlers.selectItem}"
            .onSelectRow="${this.handlers.printSelectedStatement}"
            .onSelectBatch="${this.handlers.printSelectedBatch}"
            .onSelectPatient="${this.handlers.navigateToPatientLedger}"
            .onChange="${this.handlers.tableChange}"
          >
          </neb-table-ledger-balance-statements>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-ledger-balance-statements-collection-page',
  NebLedgerBalanceStatementsCollectionPage,
);
