import { timesToDisplayFormat } from '../../neb-input/nebFormatUtils';

import { ConnectedService } from './connected-service';

export class ProviderHoursService extends ConnectedService {
  constructor(callback) {
    super({
      providers: 'providers.item',
      hours: 'providersAvailability.item',
    });

    this.onStateChanged = callback;
  }

  update(appointmentTypeId, roomAvailability) {
    this.__appointmentTypeId = appointmentTypeId;
    this.__roomAvailability = roomAvailability;

    this.updateState();
  }

  _stateChanged({ providers, hours }) {
    if (!this.__appointmentTypeId) return;

    let roomTimeFrames = null;
    let roomOnlyHours = [];

    if (this.__roomAvailability && this.__roomAvailability.length) {
      roomTimeFrames = this.__roomAvailability
        .filter(a => a.enabled)
        .flatMap(a => a.segments)
        .filter(
          segment =>
            segment.allTypes ||
            segment.appointmentTypes.find(
              type => type.appointmentTypeId === this.__appointmentTypeId,
            ),
        )
        .flatMap(segment => {
          const hoursToReturn = [];

          let start =
            segment.start.period === 'pm'
              ? parseInt(segment.start.hours, 10) + 12
              : parseInt(segment.start.hours, 10);

          let end =
            segment.end.period === 'pm'
              ? parseInt(segment.end.hours, 10) + 12
              : parseInt(segment.end.hours, 10);

          if (start === 12) start = 0;
          if (end === 12) end = 0;

          for (let i = start; i < end; i += 1) {
            hoursToReturn.push(i);
          }

          return hoursToReturn;
        })
        .sort((a, b) => (a > b ? 1 : -1));
    }

    roomOnlyHours = [...new Set(roomTimeFrames)].map(hour => ({
      duration: [hour, hour + 1],
      value: this.__getTimeFrame(hour),
    }));

    const providerTimeFrames = providers.reduce((memo, provider) => {
      const providerHasAvailability = hours[provider.id];

      let openHours;

      if (providerHasAvailability) {
        openHours = Object.values(hours[provider.id].availability)
          .filter(a => a.enabled)
          .flatMap(a => a.periods)
          .filter(
            period =>
              period.allTypes ||
              period.types.indexOf(this.__appointmentTypeId) !== -1,
          )
          .flatMap(filteredPeriod => {
            const hoursToReturn = [];

            for (
              let i = Math.floor(filteredPeriod.start);
              i < Math.ceil(filteredPeriod.end);
              i += 1
            ) {
              hoursToReturn.push(i);
            }

            return hoursToReturn;
          })
          .sort((a, b) => (a > b ? 1 : -1));
      }

      if (openHours && openHours.length > 0) {
        let uniqueHours = [...new Set(openHours)];

        if (this.__roomAvailability && this.__roomAvailability.length) {
          uniqueHours = uniqueHours.filter(
            hour => roomTimeFrames.indexOf(hour) !== -1,
          );
        }

        memo[provider.id] = uniqueHours.map(hour => ({
          duration: [hour, hour + 1],
          value: this.__getTimeFrame(hour),
        }));
      }

      return memo;
    }, {});
    this.onStateChanged({ ...providerTimeFrames, roomOnlyHours });
  }

  __convertHourToDisplayTime(hour) {
    return timesToDisplayFormat([hour])[0].display;
  }

  __getTimeFrame(hour) {
    return `${this.__convertHourToDisplayTime(
      hour,
    )} - ${this.__convertHourToDisplayTime(hour + 1)}`;
  }
}
