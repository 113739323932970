import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { parseDate } from '../../packages/neb-utils/date-util';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

function mapToModel(raw) {
  return {
    id: raw.id,
    description: raw.description,
    patientInsuranceId: raw.patientInsuranceId,
    status: raw.status,
    chcJSONExists: raw.chcJSONExists,
    submittedAt: parseDate(raw.submittedAt),
  };
}

export async function fetchMany(
  patientId,
  patientInsuranceId,
  optOutLoadingIndicator = false,
) {
  const { count, data } = await apiClient.makeRequest({
    cacheKey: patientInsuranceId,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `patients/${patientId}/insurance/${patientInsuranceId}/real-time-eligibility-records`,
    version: 1,
  });

  return { count, data: data.map(raw => mapToModel(raw)) };
}

export function fetchS3(
  patientId,
  patientInsuranceId,
  rteRecordId,
  optOutLoadingIndicator = false,
) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `patients/${patientId}/insurance/${patientInsuranceId}/real-time-eligibility-records/${rteRecordId}/s3`,
    version: 1,
  });
}

export function fetchEligibilityReport(
  patientId,
  patientInsuranceId,
  rteRecordId,
  optOutLoadingIndicator = true,
) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `patients/${patientId}/insurance/${patientInsuranceId}/real-time-eligibility-records/${rteRecordId}/readable-report`,
    version: 1,
  });
}
