import '../neb-header';
import '../neb-tooltip';
import '../neb-action-bar';
import '../neb-radio-button';
import '../inputs/neb-textfield';
import '../controls/neb-switch';
import '../controls/neb-tab-group';
import '../controls/neb-button-icon';
import '../controls/neb-button-action';
import '../tables/neb-table-payer-plans';
import '../inputs/neb-select-search';
import '../inputs/neb-select';
import '../neb-text';
import './neb-view-policy-holder-info';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { CSS_SPACING_ROW } from '../../../../../src/styles';
import { getPayerPlans } from '../../../../neb-api-client/src/payer-plan-api-client';
import { PersonService } from '../../../../neb-api-client/src/services/person';
import { parseDate } from '../../../../neb-utils/date-util';
import Debouncer from '../../../../neb-utils/debouncer';
import {
  DEFAULT_LEVEL,
  isWorkersComp,
} from '../../../../neb-utils/patientInsurance';
import {
  personToPolicyHolder,
  EMPTY_ADDRESS,
} from '../../../../neb-utils/policyHolder';
import * as selectors from '../../../../neb-utils/selectors';
import { map } from '../../../../neb-utils/utils';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import NebForm from './neb-form';

export const ELEMENTS = {
  selectPayer: { id: 'select-payer' },
  buttonAddPayer: { id: 'button-add-payer' },
  textfieldGroupId: { id: 'textfield-group-id' },
  tooltipGroupId: { id: 'tooltip-group-id' },
  textGroupId: { id: 'text-group-id' },
  selectDefaultLevel: { id: 'select-default-level' },
  policyHolderInfo: { id: 'policy-holder-info' },
  selectProvider: { id: 'select-provider' },
};

export class FormAddInsuranceRTE extends NebForm {
  static get properties() {
    return {
      providers: Array,

      __payerPlans: Array,
      __payerSearchValue: String,
      __searchResults: Array,
      __selectedPerson: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .tooltip {
          margin-top: ${CSS_SPACING_ROW};
        }
      `,
    ];
  }

  static createModel() {
    return {
      defaultLevel: 'Other',
      insuredRelationship: 'Self',
      patientId: '',
      payerPlanId: selectors.ITEM_EMPTY,
      providerId: selectors.ITEM_EMPTY,
      planInfo: {
        memberIdentifier: '',
        groupIdentifier: '',
      },
      policyHolder: {
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        sex: '',
        suffix: '',
        insuredName: '',
        dateOfBirth: null,
        address: { ...EMPTY_ADDRESS },
        phones: [],
        insuredSSN: '',
      },
    };
  }

  createSelectors() {
    return {
      children: {
        payerPlanId: selectors.select([], selectors.ITEM_EMPTY, {
          validateRaw: true,
          validators: [isRequired()],
        }),
        providerId: selectors.select([], selectors.ITEM_EMPTY, {
          validateRaw: true,
          validators: [],
        }),
        planInfo: {
          children: {
            memberIdentifier: [isRequired()],
          },
        },
        policyHolder: {
          children: {
            firstName: [isRequired()],
            lastName: [isRequired()],
            insuredName: [
              {
                error: 'Required',
                validate: (v, _, state) => {
                  const required = isWorkersComp(state.payerPlanId.data);

                  return required ? required && !!v : true;
                },
              },
            ],
            dateOfBirth: { unsafe: true },
            address: {
              clipPristine: true,
              unformat: v => ({
                id: v.id,
                parentId: v.parentId,
                parentType: v.parentType,
                sortIndex: v.sortIndex,
                address1: v.address1,
                address2: v.address2,
                city: v.city,
                state: v.state,
                zipCode: v.zipcode,
              }),
            },
            phones: {
              clipPristine: true,
              unsafe: true,
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.confirmLabel = 'Submit';
    this.providers = [];

    this.__payerPlans = [];
    this.__payerSearchValue = '';
    this.__selectedPerson = null;
    this.__searchDebouncer = new Debouncer(() => {
      this.__personService.search(
        this.__searchValue,
        this.state.patientId || '',
      );
    });

    this.__searchResults = [];

    this.onSave = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addPayerPlan: async () => {
        const payerPlan = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          context: {
            rteEnabled: true,
          },
        });

        if (payerPlan) {
          await this.__getPayerPlans();

          if (payerPlan.realTimeEligibilityEnabled) {
            this.formService.apply('payerPlanId', {
              data: payerPlan,
              label: `(${payerPlan.alias}) ${payerPlan.payerName}`,
            });
          }
        }
      },

      changePayer: e => {
        if (e.value) {
          return this.formService.apply(e.name, e.value);
        }

        return this.formService.apply(e.name, selectors.ITEM_EMPTY);
      },

      changeDate: date => {
        this.formService.apply(
          'policyHolder.dateOfBirth',
          date ? parseDate(date).startOf('day').toISOString() : '',
        );
      },

      selectPerson: person => {
        this.__selectedPerson = person || null;

        const policyHolder = personToPolicyHolder(this.__selectedPerson, {
          policyHolderId: this.__selectedPerson
            ? this.__selectedPerson.patientId
            : null,
        });

        this.formService.apply('policyHolder.id', policyHolder.id);
        this.formService.apply('policyHolder.sex', policyHolder.sex);
        this.formService.apply('policyHolder.lastName', policyHolder.lastName);
        this.formService.apply('policyHolder.suffix', policyHolder.suffix);
        this.formService.apply('policyHolder.address', policyHolder.address);
        this.formService.apply('policyHolder.phones', policyHolder.phones);

        this.formService.apply(
          'policyHolder.firstName',
          policyHolder.firstName,
        );

        this.formService.apply(
          'policyHolder.middleName',
          policyHolder.middleName,
        );

        this.formService.apply(
          'policyHolder.insuredName',
          policyHolder.insuredName,
        );

        this.formService.apply(
          'policyHolder.dateOfBirth',
          policyHolder.dateOfBirth,
        );
      },

      searchPerson: e => {
        this.__searchValue = e.value;
        this.__showResults = Boolean(this.__searchValue);
        this.__searchDebouncer.debounce();
      },

      searchPayer: e => {
        if (e.value !== this.__payerSearchValue) {
          this.__payerSearchValue = e.value;
          this.__getPayerPlans(this.__payerSearchValue);
        }
      },

      selfRelation: () => {
        this.__selectedPerson = null;

        const selfPolicyHolder = this.model.policyHolder;

        this.formService.apply('policyHolder.id', selfPolicyHolder.id);
        this.formService.apply('policyHolder.sex', selfPolicyHolder.sex);
        this.formService.apply('policyHolder.suffix', selfPolicyHolder.suffix);
        this.formService.apply('policyHolder.phones', selfPolicyHolder.phones);

        this.formService.apply(
          'policyHolder.address',
          selfPolicyHolder.address,
        );

        this.formService.apply(
          'policyHolder.lastName',
          selfPolicyHolder.lastName,
        );

        this.formService.apply(
          'policyHolder.firstName',
          selfPolicyHolder.firstName,
        );

        this.formService.apply(
          'policyHolder.middleName',
          selfPolicyHolder.middleName,
        );

        this.formService.apply(
          'policyHolder.insuredName',
          selfPolicyHolder.insuredName,
        );

        this.formService.apply(
          'policyHolder.dateOfBirth',
          selfPolicyHolder.dateOfBirth,
        );
      },

      changeProvider: e => {
        if (e.value) {
          return this.formService.apply(e.name, e.value);
        }

        return this.formService.apply(e.name, selectors.ITEM_EMPTY);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    await this.__getPayerPlans();

    this.__personService = new PersonService(data => {
      this.__searchResults = [...data];
    });
  }

  async __getPayerPlans(search = '') {
    const { payerPlan } = await getPayerPlans(
      {
        search,
        hideInactive: true,
        realTimeEligibility: true,
      },
      2,
    );

    this.__payerPlans = payerPlan.map(item => ({
      data: item,
      label: `(${item.alias}) ${item.payerName}`,
    }));
  }

  save() {
    if (this.formService.validate()) {
      const rawModel = this.formService.build();
      const model = map(rawModel, (keyPath, value) =>
        typeof value === 'string' ? value.trim() : value,
      );

      const payerPlan = this.__payerPlans.find(
        item => item.data.id === model.payerPlanId,
      );

      this.__saving = true;

      return this.onSave(model, payerPlan);
    }

    return this.onError();
  }

  __renderEligibilityInformation() {
    return this.providers
      ? html`
          <div class="grid grid-lean spacer-bottom">
            <neb-header
              label="Eligibility Information"
              description="Enter provider to use for eligibility checked outside of an appointment."
            >
            </neb-header>

            <div class="grid grid-2">
              <neb-select
                id="${ELEMENTS.selectProvider.id}"
                name="providerId"
                class="field"
                label="Provider"
                .items="${this.providers}"
                .value="${this.state.providerId}"
                .onChange="${this.handlers.changeProvider}"
              ></neb-select>
            </div>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-header
        label="Plan Information"
        description="Enter plan information to begin building your patient's insurance
        record."
      >
      </neb-header>

      <div class="grid grid-lean spacer-bottom">
        <div class="grid grid-3">
          <neb-select-search
            id="${ELEMENTS.selectPayer.id}"
            name="payerPlanId"
            class="search"
            label="Payer"
            helper="Required"
            .error="${this.errors.payerPlanId}"
            .items="${this.__payerPlans}"
            .search="${this.__payerSearchValue}"
            .value="${this.state.payerPlanId}"
            .onChange="${this.handlers.changePayer}"
            .onSearch="${this.handlers.searchPayer}"
            showSearch
          ></neb-select-search>

          <neb-button-action
            id="${ELEMENTS.buttonAddPayer.id}"
            label="Add New Payer"
            .onClick="${this.handlers.addPayerPlan}"
          ></neb-button-action>
        </div>

        <div class="grid grid-3">
          <div class="grid grid-auto-right">
            <neb-textfield
              id="${ELEMENTS.textfieldGroupId.id}"
              name="planInfo.groupIdentifier"
              label="Group ID"
              .value="${this.state.planInfo.groupIdentifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.tooltipGroupId.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <p id="${ELEMENTS.textGroupId.id}" slot="tooltip">
                Enter the group or plan ID if known.
              </p>
            </neb-tooltip>
          </div>

          <neb-select
            id="${ELEMENTS.selectDefaultLevel.id}"
            name="defaultLevel"
            label="Set As"
            .layout="${this.layout}"
            .items="${Object.values(DEFAULT_LEVEL)}"
            .value="${this.state.defaultLevel}"
            .onChange="${this.handlers.change}"
            required
          ></neb-select>
        </div>
      </div>

      <neb-header
        label="Policyholder Information"
        description="Enter policyholder details; if the policyholder is another patient,
          conduct a search."
      >
      </neb-header>

      <neb-view-policy-holder-info
        id="${ELEMENTS.policyHolderInfo.id}"
        .model="${this.state}"
        .errors="${this.errors}"
        .isWorkersComp="${isWorkersComp(this.state.payerPlanId.data)}"
        .selectedPerson="${this.__selectedPerson}"
        .searchResults="${this.__searchResults}"
        .showResults="${this.__showResults}"
        .onChange="${this.handlers.change}"
        .onChangeDate="${this.handlers.changeDate}"
        .onSearchPerson="${this.handlers.searchPerson}"
        .onSelectPerson="${this.handlers.selectPerson}"
        .onSelfRelation="${this.handlers.selfRelation}"
      >
      </neb-view-policy-holder-info>

      ${this.__renderEligibilityInformation()}
    `;
  }
}

customElements.define('neb-form-add-insurance-rte', FormAddInsuranceRTE);
