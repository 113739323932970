import '../inputs/neb-menu';
import '../neb-button';
import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  button: { id: 'button' },
  menu: { id: 'menu' },
};

class NebMenuButton extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },

      label: String,
      items: Array,
      align: {
        reflect: true,
        type: String,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      verticalOffset: {
        type: Number,
        attribute: 'vertical-offset',
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__open = false;
    this.disabled = false;
    this.verticalOffset = 0;
    this.align = 'left';
    this.items = [];

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__open = false;
      },
      select: index => {
        this.onSelect({
          index,
          value: this.items[index],
        });

        this.__open = false;
      },
      toggle: e => {
        this.__open = !this.__open;
        e.stopPropagation();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.body.addEventListener('click', this.__handlers.blur);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.body.removeEventListener('click', this.__handlers.blur);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          height: 36px;
          display: inline-block;
        }

        .container {
          position: absolute;
          display: flex;
          outline: none;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .menu {
          position: absolute;
          width: 210px;
          height: fit-content;
          top: 42px;
        }

        :host([align='left']) .menu {
          left: 0;
        }

        :host([align='right']) .menu {
          right: 0;
        }

        :host([open]) .icon-arrow {
          transform: rotate(180deg);
        }

        .button {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          outline: none;
          padding: 0 25px;
          border: 0;
          border-radius: 20px;
          text-align: center;
          text-transform: uppercase;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-family: ${CSS_FONT_FAMILY};
          font-weight: 700;
          color: ${CSS_COLOR_WHITE};
          justify-content: center;
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .button[disabled] {
          cursor: default;
          opacity: 0.5;
        }

        .button:focus {
          opacity: 0.65;
        }

        .icon-arrow {
          width: 14px;
          height: 14px;
          margin-left: 10px;
          fill: ${CSS_COLOR_WHITE};
          transition: 250ms ease-in-out;
        }
      `,
    ];
  }

  __renderMenu() {
    return html`
      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu"
        showFullText
        .items="${this.items}"
        .onChange="${this.__handlers.select}"
        ?open="${this.__open && this.items.length}"
      ></neb-menu>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <button
          id="${ELEMENTS.button.id}"
          class="button"
          tabindex="0"
          ?disabled="${this.disabled}"
          @click="${this.__handlers.toggle}"
        >
          ${this.label}
          <neb-icon class="icon-arrow" icon="neb:arrow"></neb-icon>
        </button>
        ${this.__renderMenu()}
      </div>
    `;
  }
}

customElements.define('neb-menu-button', NebMenuButton);
