import {
  errorOccurred,
  successfullyMessage,
} from '../../../../../src/utils/user-message';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { updateClaimsDataV2 } from '../../claims-data';

import buildUpdatedClaimsData from './build-updated-claims-data';

export default async (
  initState,
  formModel,
  patientCasesDict,
  hasRcmClaimFlagFF,
) => {
  try {
    const initialStateDictionary = initState.reduce((accum, item) => {
      accum[item.id] = {
        ...item,
        lineItems: item.lineItems.map(({ diagnosesPointers, ...li }) => ({
          ...li,
          diagnosesPointers: (diagnosesPointers || [])
            .map(({ diagnosisCode }) => diagnosisCode)
            .sort((a, b) => a.localeCompare(b)),
        })),
      };

      return accum;
    }, {});

    const itemsToUpdate = buildUpdatedClaimsData(
      formModel,
      initialStateDictionary,
      patientCasesDict,
    );

    let numOfClaimsUpdated = 0;

    numOfClaimsUpdated = await updateClaimsDataV2(itemsToUpdate);

    if (hasRcmClaimFlagFF && numOfClaimsUpdated > 0) {
      const claimWording = numOfClaimsUpdated === 1 ? 'Claim' : 'Claims';

      store.dispatch(
        openInfo(
          `${numOfClaimsUpdated} ${claimWording} moved to Needs Attention`,
        ),
      );
    }

    store.dispatch(openSuccess(successfullyMessage('Claims data')));
    return true;
  } catch (e) {
    console.error('e :>> ', e);
    store.dispatch(openError(errorOccurred('saving', 'claims data')));
    return false;
  }
};
