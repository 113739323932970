import '../../../../neb-treatment-plan/components/neb-treatment-plan-table';

import { LitElement, html } from 'lit';

export const ELEMENTS = {
  treatmentPlanTable: {
    id: 'table',
  },
};

class NebChartingTreatmentPlanSummary extends LitElement {
  static get properties() {
    return {
      treatmentPlan: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.treatmentPlan = {
      treatmentPhases: [],
    };
  }

  __getTreatmentPhases() {
    return this.treatmentPlan ? this.treatmentPlan.treatmentPhases : [];
  }

  render() {
    return html`
      <neb-treatment-plan-table
        .items="${this.__getTreatmentPhases()}"
        id="${ELEMENTS.treatmentPlanTable.id}"
        summary
        sticky-total
        ?hide-total="${!this.__getTreatmentPhases().length}"
      ></neb-treatment-plan-table>
    `;
  }

  updated(changed) {
    if (changed.has('treatmentPlan')) {
      this.shadowRoot.getElementById('table').resetTotals();
    }
  }
}

customElements.define(
  'neb-charting-treatment-plan-summary',
  NebChartingTreatmentPlanSummary,
);
