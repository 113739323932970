export const ZoomMode = Object.freeze({
  ACTUAL_SIZE: 'actual-size',
  PAGE_FIT: 'page-fit',
  PAGE_WIDTH: 'page-width',
  CUSTOM_ZOOM: 'custom-zoom',
  ZOOM: 'zoom',
});
export const ZoomModeNames = [
  {
    name: 'Actual Size',
    mode: ZoomMode.ACTUAL_SIZE,
  },
  {
    name: 'Page Fit',
    mode: ZoomMode.PAGE_FIT,
  },
  {
    name: 'Page Width',
    mode: ZoomMode.PAGE_WIDTH,
  },
  {
    name: 'Custom Zoom',
    mode: ZoomMode.CUSTOM_ZOOM,
  },
  {
    name: 'Zoom',
    mode: ZoomMode.ZOOM,
  },
];
export const DEFAULT_ZOOM_VALUES = [50, 75, 100, 125, 150, 200, 300, 400];
export const ZoomSteps = [
  {
    start: 10,
    end: 209,
    step: 10,
  },
  {
    start: 210,
    end: 409,
    step: 20,
  },
  {
    start: 410,
    end: 609,
    step: 30,
  },
  {
    start: 610,
    end: 809,
    step: 40,
  },
  {
    start: 810,
    end: 1000,
    step: 50,
  },
];
export const MAX_ZOOM = 1000;
export const MIN_ZOOM = 10;
export const DEFAULT_ZOOM_DELTA = 1.1;
export const DEFAULT_ZOOM_VALUE = 100;
export const DEFAULT_ZOOM_MODE = ZoomMode.ACTUAL_SIZE;
export const TEXT_ZOOM_MODE = 'Zoom mode';
export const TEXT_ZOOM_VALUE = 'Zoom value';
export const TEXT_IS_NOT_VALID = 'is not valid';
export const VALIDATE_RENDER_KEY = {
  PAGE_NUMBER: '__pageNumber',
  ZOOM: '__zoom',
  SIZE: '__size',
};
export const VALIDATE_RENDER_KEYS = [
  VALIDATE_RENDER_KEY.PAGE_NUMBER,
  VALIDATE_RENDER_KEY.ZOOM,
  VALIDATE_RENDER_KEY.SIZE,
];

export const getZoomId = (zoomMode, zoomValue) => {
  if (zoomMode === ZoomMode.ZOOM) {
    return `${zoomMode}-${zoomValue}`;
  }

  return zoomMode;
};

export const getDefaultZoomItems = () => {
  const items = [];
  ZoomModeNames.forEach(item => {
    if (item.mode === ZoomMode.ZOOM) {
      DEFAULT_ZOOM_VALUES.forEach(value => {
        items.push({
          code: ZoomMode.ZOOM,
          value,
          name: `${value}%`,
          id: `${getZoomId(item.mode, value)}`,
        });
      });
    } else {
      items.push({
        code: item.mode,
        value: 100,
        name: item.name,
        id: `${getZoomId(item.mode, item.value)}`,
      });
    }
  });

  return items;
};
