import '../../../../../../src/components/misc/neb-icon';
import '../../../../../neb-styles/neb-icons';
import '../../inputs/neb-select';
import '../../neb-loading-spinner-resizable';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { postPayment } from '../../../../../../src/api-clients/booking/booking-patient';
import { createPayment } from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import { getPayfieldsApiKey } from '../../../../../neb-api-client/src/payments/payfields-api-client';
import { deleteToken } from '../../../../../neb-api-client/src/payments/payment-methods-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import { CODE_PAYMENTS } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import { formatElectronicPayment } from '../../../../../neb-utils/electronic-payments-util';
import { PAYMENT_METHODS } from '../../../../../neb-utils/enums';
import { currencyToCents } from '../../../../../neb-utils/formatters';
import {
  SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH,
  SMALL_LAYOUT_WIDTH_PERCENT,
} from '../../../../../neb-utils/payment-popup-constants';

export const ELEMENTS = {
  cancelButton: {
    id: 'button-cancel',
  },
  closeButton: {
    id: 'button-close',
  },
  textHeader: {
    id: 'text-header',
  },
  textSubheader: {
    id: 'text-subheader',
  },
  textAmount: {
    id: 'text-amount',
  },
  cardDescriptionField: {
    id: 'card-description',
  },
  payfieldsIframe: {
    id: 'payfields-iframe',
  },
};

export const TIMEOUT = 1000;
export const PAYMENT_SUCCESS_MESSAGE = 'Payment completed successfully';
const PAYMENT_FAILURE_MESSAGE = 'Payment unsuccessful';

export const PAYMENT_RESULT_CONFIG = {
  PAYMENT: {
    SUCCESS: {
      title: 'Payment Transaction Successful',
      success: true,
    },
    FAILURE: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed.  Try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Different Payment Details',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE_RETRY: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed. Try again to re-submit the request, or try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Again',
      cancelLabel: 'Try Different Payment Details',
      cardOnFileName: '',
    },
  },
};

class NebPatientOnlinePaymentPayfieldsForm extends LitElement {
  static get properties() {
    return {
      __initialized: Boolean,
      __processing: Boolean,
      __dirty: Boolean,
      __cardDescription: String,
      __dirtyPopupOpen: { Boolean, reflect: true },
      amount: String,
      merchantAccount: Object,
      patient: Object,
      practiceInfo: Object,
      billingAddress: Object,
      title: String,
      message: String,
      subheader: String,
      confirmLabel: String,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return css`
      .container-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .header {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
      }

      .text-header {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        padding-bottom: ${CSS_SPACING};
      }

      .text-message {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
        font-weight: bold;
        padding: ${CSS_SPACING} 0;
      }

      .card-description {
        margin: 0;
        padding: 16px 0 ${CSS_SPACING};
        width: 50%;
      }

      .text-subheader {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
      }

      .icon-close {
        height: 24px;
        width: 24px;
        fill: ${CSS_COLOR_GREY_1};
        padding: 0;
        cursor: pointer;
      }

      .iframe {
        display: flex;
        border: none;
        width: 100%;
      }

      :host([layout='small']) .iframe {
        transform: scale(${SMALL_LAYOUT_WIDTH_PERCENT});
        transform-origin: 0 0;
      }

      :host([__dirtyPopupOpen='true']) .iframe {
        pointer-events: none;
      }

      .spinner {
        margin-top: ${CSS_SPACING};
        margin-bottom: -124px;
        text-align: center;
        z-index: 1;
      }

      .visible {
        opacity: 1;
      }

      .invisible {
        opacity: 0;
      }

      .merchant-accounts {
        padding-bottom: ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__initialized = false;
    this.__processing = false;
    this.__dirty = false;
    this.__cardDescription = '';
    this.amount = '';
    this.patient = {};

    this.merchantAccount = null;

    this.onBack = () => {};

    this.onCancel = () => {};

    this.onSave = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      initialize: async () => {
        if (!this.__initialized) {
          const { key, env } = await getPayfieldsApiKey();

          this.postMessage({
            action: 'initialize',
            key,
            env,
            opts: {
              confirmLabel: this.confirmLabel,
            },
          });
        }
      },
      messageChanged: ({ source, data }) => {
        if (source !== this.__elements.iframe.contentWindow) {
          return;
        }

        if (data.dimensions) {
          this.__elements.iframe.style.height = `${
            data.dimensions.height + 1
          }px`;

          let widthToSet = data.dimensions.width;

          if (this.layout === 'small') {
            // subtract 40 for left and right margins on this form
            widthToSet =
              (SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH - 40) /
              SMALL_LAYOUT_WIDTH_PERCENT;
          }

          this.__elements.iframe.style.width = `${widthToSet}px`;
        }

        if (data.dirty) this.__dirty = true;

        if (data.initialized) {
          window.setTimeout(() => {
            this.__initialized = true;
          }, TIMEOUT);
        }

        if (data.action === 'cancel') {
          this.__handlers.back();
        }

        if (data.errors) {
          this.__handleErrors(data);
        }

        if (data['temporary-token']) {
          this.tempToken = data['temporary-token'];
          this.convertedAmount = this.__convertCurrency(this.amount);
          this.title = this.amount
            ? 'Transaction Processing'
            : 'Authorizing Payment Method';

          this.__processing = true;

          this.__handlers.createPayment();
        }
      },
      createPayment: async _opts => {
        const { patientId, address1, postalCode } = this.patient;

        const result = await createPayment(this.merchantAccount, {
          temporary_token: this.tempToken,
          transactionAmount: `${this.convertedAmount}`,
          holderId: patientId,
          holderType: 'patient',
          postalCode: postalCode || undefined,
          address1: address1 || undefined,
          logContent: {
            action:
              'payment - createPayment - neb-patient-online-payment-payfields-form',
            rawModel: {
              transactionAmount: `${this.convertedAmount}`,
              holderId: patientId,
            },
          },
        });

        if (result) {
          if (result.status === 'Approved') {
            if (result.card.token) {
              deleteToken(
                this.merchantAccount,
                result.card.token,
                result.entryType,
              );
            }

            const electronicPayment = formatElectronicPayment(result);

            const postPaymentBody = {
              ...electronicPayment,
              codePaymentId: CODE_PAYMENTS.GENERAL_PAYMENT.id,
              amount: currencyToCents(this.amount),
              transactionDate: parseDate().startOf('day').toISOString(),
              patientId,
              paymentMethod: PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD,
            };

            try {
              const postResponse = await postPayment(postPaymentBody);

              this.__processing = false;

              store.dispatch(openSuccess(PAYMENT_SUCCESS_MESSAGE));

              await openPopup(POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS, {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
                payment: this.__mapToPdfModel(postResponse),
                patient: this.patient,
                practiceInfo: this.practiceInfo,
                billingAddress: this.billingAddress,
              });

              this.onSave(postResponse);
            } catch (err) {
              this.__processing = false;

              store.dispatch(openError(PAYMENT_FAILURE_MESSAGE));
            }

            return;
          }

          if (result.status === 'Failed') {
            this.__processing = false;

            console.log('card declined:', result);
            await openPopup(POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS, {
              ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
            });

            return;
          }

          this.onCancel();
        }
      },
      back: async () => {
        if (this.__dirty) {
          this.__dirtyPopupOpen = true;

          if (await openDirtyPopup()) {
            this.onBack();
          } else {
            this.__dirtyPopupOpen = false;
          }
        } else {
          this.onBack();
        }
      },
    };
  }

  __handlePaymentSuccess(response) {
    console.log(response);
  }

  __handlePaymentError(response) {
    console.log(response);
  }

  __handleErrors(data) {
    let errors;

    if (data.errors.code === 'invalid_card') {
      errors = ['card_number'];
    } else {
      errors = data.errors.detail
        .map(({ data_path: dataPath, description }) => {
          const [, , prop] = dataPath.split('/');

          if (!prop) {
            return undefined;
          }

          let field;

          if (prop.includes('expiry')) {
            field =
              description === 'Invalid data' ? 'expiration_data' : 'expiration';
          } else {
            field = prop;
          }

          return field;
        })
        .filter(prop => prop);

      if (errors.includes('expiration') && errors.includes('expiration_data')) {
        errors = errors.filter(prop => !prop.includes('expiration_data'));
      }
    }

    if (errors) {
      this.postMessage({
        action: 'errors',
        errors,
      });
    }
  }

  postMessage(message) {
    this.__elements.iframe.contentWindow.postMessage(message, '*');
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this.__handlers.messageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('message', this.__handlers.messageChanged);
  }

  firstUpdated(props) {
    this.__elements = {
      iframe: this.shadowRoot.getElementById('payfields-iframe'),
    };

    this.__elements.iframe.onload = this.__handlers.initialize;

    super.firstUpdated(props);
  }

  __renderSpinner() {
    return html`
      <neb-loading-spinner-resizable
        class="spinner"
      ></neb-loading-spinner-resizable>
    `;
  }

  __renderIframe() {
    return html`
      <iframe
        class="iframe ${this.__initialized && !this.__processing
          ? 'visible'
          : 'invisible'}"
        src="https://s3.amazonaws.com/neb-payfields-iframe/payfields-iframe.html"
        id="${ELEMENTS.payfieldsIframe.id}"
      ></iframe>
    `;
  }

  __renderTitle() {
    return html`
      <div class="header spacer">
        <div class="text-header" id="${ELEMENTS.textHeader.id}">
          ${this.title}
        </div>
        ${!this.__processing
          ? html`
              <neb-icon
                id="${ELEMENTS.closeButton.id}"
                class="icon-close"
                icon="neb:close"
                @click="${this.__handlers.back}"
              ></neb-icon>
            `
          : ''}
      </div>
    `;
  }

  __mapToPdfModel(postResponse) {
    return {
      ...postResponse,
      appointmentId: null,
      codePayment: CODE_PAYMENTS.GENERAL_PAYMENT,
      dateOfServiceFrom: null,
      dateOfServiceTo: null,
      payerPlanId: null,
      refundPayment: null,
      refundedAt: null,
      transactionDate: moment(postResponse.transactionDate),
    };
  }

  __convertCurrency(amount) {
    return amount ? Number(amount.replace(/[^0-9.]/g, '')) : 0;
  }

  __conditionalRender(renderElement) {
    return !this.__initialized || this.__processing ? renderElement() : '';
  }

  __renderAmount() {
    return html`
      <div id="${ELEMENTS.textAmount.id}" class="text-message">
        ${this.message}: ${this.amount}
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderTitle()}
      <div class="container-content">
        ${!this.__processing
          ? html`
              <div id="${ELEMENTS.textSubheader.id}" class="text-subheader">
                ${this.subheader}
              </div>
              ${this.__renderAmount()}
            `
          : ''}
        ${this.__conditionalRender(this.__renderSpinner)}
        <div>${this.__renderIframe()}</div>
      </div>
    `;
  }
}
customElements.define(
  'neb-patient-online-payment-payfields-form',
  NebPatientOnlinePaymentPayfieldsForm,
);
