import '../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import { deleteAppointment } from '../../../../packages/neb-api-client/src/appointment-api-client';
import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import { getPotentialMatches } from '../../../../packages/neb-api-client/src/patient-api-client';
import { getAccount } from '../../../../packages/neb-api-client/src/permissions-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { openSuccess } from '../../../../packages/neb-dialog/neb-banner-state';
import NebOverlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { SORT_DIR } from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { openMatchPatientAccountPopup } from '../../../../packages/neb-lit-components/src/utils/patients';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { objToName } from '../../../../packages/neb-utils/formatters';
import { FetchService } from '../../../../packages/neb-utils/services/fetch';
import { MODE } from '../../../../packages/neb-utils/table';
import { getUnmatchedAppointments } from '../../../api-clients/appointments';

const SUCCESS_MESSAGE = 'Online Booking Account linked';

const DELETE_UNMATCHABLE = {
  TITLE: 'Unable to Match Appointment',
  MESSAGE:
    'The Online Booking Account Details for this appointment no longer exists. This appointment must be deleted as it can no longer be associated. The patient can recreate their Online Booking account through your Online Booking site and schedule another appointment.',
  BANNER: 'Appointment Deleted.',
};

export const TABLE_CONFIG_MOBILE = [
  {
    key: 'firstName',
    label: 'Patient Name',
    flex: css`1 0 0`,
    formatter: (_, item) => `${item.lastName}, ${item.firstName}`,
  },
  {
    key: 'start',
    label: 'Appointment Date/Time',
    flex: css`1 0 0`,
    formatter: (_, item) =>
      `${parseDate(item.start).format(
        'dddd, MMMM Do, YYYY h:mm A',
      )} - ${parseDate(item.end).format('h:mm A')}`,
  },
];

export const TABLE_CONFIG = [
  ...TABLE_CONFIG_MOBILE,
  {
    key: 'appointmentTypeName',
    label: 'Appointment Type',
    flex: css`1 0 0`,
  },
  {
    key: 'providerName',
    label: 'Appointment Provider',
    flex: css`1 0 0`,
    formatter: value =>
      objToName(value, { middleInitial: true, reverse: true }),
  },
];

export const ELEMENTS = {
  header: { id: 'header' },
  pagination: { id: 'pagination' },
  table: { id: 'table' },
};

class NebOverlayUnmatchedAppointments extends NebOverlay {
  static get properties() {
    return {
      __pageCount: Number,
      __pageIndex: Number,
      __totalCount: Number,
      __pageItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .table {
          overflow-y: auto;
        }

        .pagination {
          align-self: flex-end;
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__pageCount = 0;
    this.__pageIndex = 0;
    this.__totalCount = 0;
    this.__pageItems = [];
    this.__providers = [];
    this.__appointmentTypes = [];

    this.__fetchService = new FetchService(
      {
        onChange: ({ pageIndex, pageItems, pageCount, filteredCount }) => {
          this.__pageCount = pageCount;
          this.__pageIndex = pageIndex;
          this.__pageItems = pageItems;
          this.__totalCount = filteredCount;
        },
        onMapItem: item => {
          const provider = this.__providers.find(p => p.id === item.providerId);
          const appointmentType = this.__appointmentTypes.find(
            a => a.id === item.appointmentTypeId,
          );

          return {
            ...item,
            providerName: provider ? provider.name : {},
            appointmentTypeName: appointmentType.name,
          };
        },
      },
      getUnmatchedAppointments,
      {
        sortParams: SORT_DIR.ASC,
      },
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      changePage: index => this.__fetchService.setPageIndex(index),

      selectRow: async (_, item) => {
        const account = await getAccount(item.accountId);

        if (!account) {
          const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: DELETE_UNMATCHABLE.TITLE,
            message: DELETE_UNMATCHABLE.MESSAGE,
            confirmText: 'Delete',
            cancelText: 'Cancel',
          });

          if (result) {
            await deleteAppointment(item.id);
            this.__fetchService.fetch();
            store.dispatch(openSuccess(DELETE_UNMATCHABLE.BANNER));
          }

          return;
        }
        const potentialMatches = await getPotentialMatches(account);

        const result = await openMatchPatientAccountPopup(
          account,
          potentialMatches,
          item.id,
        );

        if (result.data && result.data[0] && result.data[0].bookingAccountId) {
          this.__fetchService.fetch();

          store.dispatch(openSuccess(SUCCESS_MESSAGE));
        }

        if (result.deleted) {
          this.__fetchService.fetch();

          await this.dismiss(result);
        }
      },
    };
  }

  async firstUpdated(changedProps) {
    super.firstUpdated(changedProps);

    [this.__providers, { data: this.__appointmentTypes }] = await Promise.all([
      getProviderUsers(),
      getAppointmentTypes(),
    ]);

    return this.__fetchService.fetch();
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Unmatched Appointments (${this.__totalCount})"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-table
        id="${ELEMENTS.table.id}"
        name="appointments"
        class="table"
        .config="${this.layout === 'small'
          ? TABLE_CONFIG_MOBILE
          : TABLE_CONFIG}"
        .mode="${MODE.DETAIL}"
        .model="${this.__pageItems}"
        .onSelectRow="${this.handlers.selectRow}"
      ></neb-table>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .currentPage="${this.__pageIndex}"
        .pageCount="${this.__pageCount}"
        .onPageChanged="${this.handlers.changePage}"
      ></neb-pagination>
    `;
  }
}

customElements.define(
  'neb-overlay-unmatched-appointments',
  NebOverlayUnmatchedAppointments,
);
