import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../tables/statements/neb-table-statement-batch-preview';
import '../../collection-pages/neb-ledger-balance-statements-batch-collection-page';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import { html, css } from 'lit';

import * as ledgerStatementApiClient from '../../../../packages/neb-api-client/src/ledger-statement-api-client';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../packages/neb-styles/neb-variables';
import {
  successfullyMessage,
  errorOccurred,
} from '../../../utils/user-message';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  table: {
    id: 'table',
  },
};

class NebOverlayPreviewStatementBatch extends Overlay {
  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async (removedStatementsIds, excludedFromBatch, shouldDismiss) => {
        try {
          await ledgerStatementApiClient.removeOrAddStatementFromBatch(
            this.model.batchId,
            removedStatementsIds,
            excludedFromBatch,
          );

          store.dispatch(
            openSuccess(
              successfullyMessage(
                'Statement',
                excludedFromBatch ? 'removed' : 'added',
              ),
            ),
          );

          this.handlers.dirty(false);
          if (shouldDismiss) this.handlers.dismiss();
        } catch (error) {
          store.dispatch(
            openError(
              errorOccurred(
                excludedFromBatch ? 'removing' : 'adding',
                'statement',
              ),
            ),
          );
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING_ROW}
            ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Statement Batch - ${this.model.batchId}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-ledger-balance-statements-batch-collection-page
        id="${ELEMENTS.table.id}"
        .model="${this.model}"
        .onDirty="${this.handlers.dirty}"
        .onDismiss="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
      >
      </neb-ledger-balance-statements-batch-collection-page>
    `;
  }
}

customElements.define(
  'neb-overlay-preview-statement-batch',
  NebOverlayPreviewStatementBatch,
);
