import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { store } from '../../neb-redux/neb-redux-store';
import { parseDate } from '../../neb-utils/date-util';
import {
  getActiveTreatmentPlan,
  getCompletedTreatmentPlans,
} from '../actions/treatmentPlanActions';

export const NebTreatmentPlanState = superClass =>
  class extends superClass {
    static get properties() {
      return {
        patientId: {
          type: String,
        },
        _activeTreatmentPlan: {
          type: Object,
        },
        _completedTreatmentPlans: {
          type: Array,
        },
        __stale: {
          type: Boolean,
        },
        __session: {
          type: Object,
        },
        __appointmentChanged: {
          type: Boolean,
        },
        __encounterId: {
          type: String,
        },
      };
    }

    constructor() {
      super();

      this.__initActiveTreatmentPlan();

      this._completedTreatmentPlans = [];
    }

    __initActiveTreatmentPlan() {
      this._activeTreatmentPlan = {
        startDate: parseDate().startOf('day').format(ISO_DATE_FORMAT),
        treatmentPhases: [],
      };
    }

    _stateChanged(state) {
      const {
        session: { item: session } = {},
        treatmentPlans = {},
        treatmentPlansCompleted: { stale } = {},
        appointments: { created, updated } = {},
        charting: { encounterId } = {},
      } = state;
      this.__session = session;
      this.__treatmentPlans = treatmentPlans;
      this.__stale = stale;
      this.__appointmentChanged = created || updated;
      this.__encounterId = encounterId;
      if (this._shouldNotifyParent()) super._stateChanged(state);
    }

    _shouldNotifyParent() {
      throw new Error('_shouldNotifyParent() not implemented', this);
    }

    async updated(changed) {
      if (
        this.__session &&
        ((changed.has('patientId') && this.patientId) ||
          changed.has('__session') ||
          (changed.has('__stale') && this.__stale) ||
          (changed.has('__encounterId') && this.__encounterId) ||
          (changed.has('__appointmentChanged') && this.__appointmentChanged))
      ) {
        await this.__fetchData();
      }

      super.updated(changed);
    }

    async __fetchData() {
      await this.__fetchActiveTreatmentPlan();
      await this.__fetchCompletedTreatmentPlans();
    }

    async __fetchActiveTreatmentPlan() {
      if (this.patientId) {
        await store.dispatch(getActiveTreatmentPlan(this.patientId));
        const {
          treatmentPlan: { item: treatmentPlanId },
        } = store.getState();
        this.__activeTreatmentPlanId = treatmentPlanId;

        this.__syncActiveTreatmentPlanFromStore();
      }
    }

    async __fetchCompletedTreatmentPlans() {
      if (this.patientId) {
        await store.dispatch(getCompletedTreatmentPlans(this.patientId));
        const {
          treatmentPlansCompleted: { items: completedTreatmentPlans },
        } = store.getState();
        this.__completedTreatmentPlanIds = completedTreatmentPlans;

        this.__syncCompletedTreatmentPlansFromStore();
      }
    }

    __syncActiveTreatmentPlanFromStore() {
      if (this.__activeTreatmentPlanId) {
        this._activeTreatmentPlan =
          this.__treatmentPlans[this.__activeTreatmentPlanId];
      } else {
        this.__initActiveTreatmentPlan();
      }
    }

    __syncCompletedTreatmentPlansFromStore() {
      this.__resetCompletedTreatmentPlans();

      if (this.__completedTreatmentPlanIds) {
        this.__completedTreatmentPlanIds.forEach(tpId => {
          this._completedTreatmentPlans.push(this.__treatmentPlans[tpId]);
        });
      }
    }

    __resetCompletedTreatmentPlans() {
      this._completedTreatmentPlans = [];
    }
  };
