import { openPopup } from '@neb/popup';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { updateWorklistItems } from '../../../../../src/api-clients/worklist-items';
import {
  WORKLIST_ITEMS_HIDDEN_ERROR,
  WORKLIST_ITEMS_HIDDEN_SUCCESSFULLY,
  WORKLIST_ITEMS_SHOWN_ERROR,
  WORKLIST_ITEMS_SHOWN_SUCCESSFULLY,
  ECLAIM_STATUS_CHANGE_CANT_UPDATE_MESSAGE,
  errorOccurred,
  ECLAIM_STATUS_CHANGE_SUCCESS_MESSAGE,
  ECLAIM_STATUS_CHANGE_NO_CHARGES_SELECTED,
  ECLAIM_STATUS_CHANGE_CHARGES_NOT_ASSOCIATED_WITH_CLAIM,
  CONFIRM_GENERATE_CLAIM_BATCH_IF_PENDING,
  successfulGenerateBatch,
  generateInternalErrorMessage,
  generateValidationErrorMessage,
} from '../../../../../src/utils/user-message';
import * as claimBatchApi from '../../../../neb-api-client/src/claim-batches';
import { bulkUpdateStatus } from '../../../../neb-api-client/src/claim-status';
import {
  openError,
  openSuccess,
  openInfo,
  openWarning,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../../neb-utils/date-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

export const WORKLIST_ITEMS_POPUP_ACTIONS = {
  HIDE: 'hide',
  SHOW: 'show',
};

export const CLAIMS_NO_ITEMS_INITIAL_LOAD =
  'Please select apply to show results and update totals.';

const WORKLIST_ITEMS_POPUP_ITEMS = {
  hide: {
    popupTitle: 'Hide',
    popupMessage: 'hide',
    hidden: true,
    bannerSuccess: WORKLIST_ITEMS_HIDDEN_SUCCESSFULLY,
    bannerError: WORKLIST_ITEMS_HIDDEN_ERROR,
  },
  show: {
    popupTitle: 'Show',
    popupMessage: 'show',
    hidden: false,
    bannerSuccess: WORKLIST_ITEMS_SHOWN_SUCCESSFULLY,
    bannerError: WORKLIST_ITEMS_SHOWN_ERROR,
  },
};

export const CLAIMS_ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  refresh: 'neb:refresh',
  offset: 'neb:offset',
  print: 'neb:print',
  batch: 'neb:batch',
  visibilityOff: 'neb:visibilityOff',
  visible: 'neb:visible',
  generateSubmit: 'neb:generateSubmit',
  minus: 'neb:minus',
  refreshAndSubmit: 'neb:refreshAndSubmit',
  correctClaim: 'neb:correctClaim',
  voidClaim: 'neb:voidClaim',
  scrubClaim: 'neb:scrubClaim',
};

export const BULK_ACTION_OPTION_ID = {
  SELECTED_ALL_ON_CURRENT_PAGE: 'selectAllOnCurrentPage',
  SELECTED_AMOUNT: 'selectedAmount',
  SELECT_ALL: 'selectAll',
  DESELECT_ALL: 'deselectAll',
  ADJUST_PRINT_OFFSETS: 'adjustPrintOffsets',
  PRINT_CLAIMS: 'printClaims',
  CLAIMS_BATCHES: 'claimBatchesOverlay',
  REQUEST_STATUS_UPDATE: 'requestStatusUpdate',
  REFRESH_CLAIMS: 'refreshClaims',
  REFRESH_DRAFTS: 'refreshDrafts',
  CHANGE_STATUS: 'changeStatus',
  REBILL_CLAIMS: 'rebillClaims',
  HIDE_WORKLIST_ITEMS: 'hide',
  SHOW_WORKLIST_ITEMS: 'show',
  GENERATE_AND_SUBMIT: 'submit',
  DELETE_CLAIM_DRAFTS: 'delete',
  REFRESH_AND_SUBMIT_CLAIMS: 'refreshAndSubmit',
  CORRECT_CLAIM: 'correctClaim',
  VOID_CLAIM: 'voidClaim',
  SCRUB_CLAIMS: 'scrubClaims',
};

export const BULK_ACTION_OPTIONS = {
  [BULK_ACTION_OPTION_ID.SELECTED_AMOUNT]: {
    id: BULK_ACTION_OPTION_ID.SELECTED_AMOUNT,
    label: 'Select All',
  },
  [BULK_ACTION_OPTION_ID.SELECT_ALL]: {
    id: BULK_ACTION_OPTION_ID.SELECT_ALL,
    label: 'Select All',
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.DESELECT_ALL]: {
    id: BULK_ACTION_OPTION_ID.DESELECT_ALL,
    label: 'Deselect All',
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.ADJUST_PRINT_OFFSETS]: {
    id: BULK_ACTION_OPTION_ID.ADJUST_PRINT_OFFSETS,
    label: 'Adjust Print Offsets',
    icon: CLAIMS_ICON.offset,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.PRINT_CLAIMS]: {
    id: BULK_ACTION_OPTION_ID.PRINT_CLAIMS,
    label: 'Print Claims',
    icon: CLAIMS_ICON.print,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.CLAIMS_BATCHES]: {
    id: BULK_ACTION_OPTION_ID.CLAIMS_BATCHES,
    label: 'Claim Batches',
    icon: CLAIMS_ICON.batch,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.REQUEST_STATUS_UPDATE]: {
    id: BULK_ACTION_OPTION_ID.REQUEST_STATUS_UPDATE,
    label: 'Request Status Update',
    icon: CLAIMS_ICON.electronic,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.REFRESH_CLAIMS]: {
    id: BULK_ACTION_OPTION_ID.REFRESH_CLAIMS,
    label: 'Refresh Claims',
    icon: CLAIMS_ICON.refresh,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.REFRESH_DRAFTS]: {
    id: BULK_ACTION_OPTION_ID.REFRESH_DRAFTS,
    label: 'Refresh Drafts',
    icon: CLAIMS_ICON.refresh,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.CHANGE_STATUS]: {
    id: BULK_ACTION_OPTION_ID.CHANGE_STATUS,
    label: 'Change Status',
    icon: CLAIMS_ICON.paper,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.REBILL_CLAIMS]: {
    id: BULK_ACTION_OPTION_ID.REBILL_CLAIMS,
    label: 'Rebill Claims',
    icon: CLAIMS_ICON.generateSubmit,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS]: {
    id: BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS,
    label: 'Hide Worklist Items',
    icon: CLAIMS_ICON.visibilityOff,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS]: {
    id: BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS,
    label: 'Show Worklist Items',
    icon: CLAIMS_ICON.visible,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.GENERATE_AND_SUBMIT]: {
    id: BULK_ACTION_OPTION_ID.GENERATE_AND_SUBMIT,
    label: 'Generate and Submit',
    icon: CLAIMS_ICON.generateSubmit,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.DELETE_CLAIM_DRAFTS]: {
    id: BULK_ACTION_OPTION_ID.DELETE_CLAIM_DRAFTS,
    label: 'Delete Claim Drafts',
    icon: CLAIMS_ICON.minus,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.REFRESH_AND_SUBMIT_CLAIMS]: {
    id: BULK_ACTION_OPTION_ID.REFRESH_AND_SUBMIT_CLAIMS,
    label: 'Refresh and Submit',
    icon: CLAIMS_ICON.refreshAndSubmit,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.CORRECT_CLAIM]: {
    id: BULK_ACTION_OPTION_ID.CORRECT_CLAIM,
    label: 'Correct Claim',
    icon: CLAIMS_ICON.correctClaim,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.VOID_CLAIM]: {
    id: BULK_ACTION_OPTION_ID.VOID_CLAIM,
    label: 'Void/Cancel Claim',
    icon: CLAIMS_ICON.voidClaim,
    onSelect: () => {},
  },
  [BULK_ACTION_OPTION_ID.SCRUB_CLAIMS]: {
    id: BULK_ACTION_OPTION_ID.SCRUB_CLAIMS,
    label: 'Scrub Claims',
    icon: CLAIMS_ICON.scrubClaim,
    onSelect: () => {},
  },
};

const getSelectedWorklistItemIds = worklistItems =>
  worklistItems.reduce(
    ({ invoiceIds = [], claimIds = [] }, { invoiceId, claimId, id }) =>
      claimId || id
        ? { invoiceIds, claimIds: [...claimIds, claimId || id] }
        : { claimIds, invoiceIds: [...invoiceIds, invoiceId] },
    {},
  );

export const openWorklistItemPopup = async (action, worklistItems) => {
  const { popupTitle, popupMessage, hidden, bannerSuccess, bannerError } =
    WORKLIST_ITEMS_POPUP_ITEMS[action];

  const popupRes = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: `${popupTitle} Worklist Items`,
    message: `Are you sure you want to ${popupMessage} the selected worklist items?`,
    confirmText: 'Yes',
    cancelText: 'No',
  });

  if (popupRes) {
    const { invoiceIds, claimIds } = getSelectedWorklistItemIds(worklistItems);

    try {
      await updateWorklistItems({
        invoiceIds,
        claimIds,
        hidden,
      });

      store.dispatch(openSuccess(bannerSuccess));
    } catch (e) {
      console.error(e);
      store.dispatch(openError(bannerError));
    }
  }
  return popupRes;
};

export const displayBox24IJShaded = ({
  charge,
  billingProviderOtherIdQualifier,
  billingProviderOtherId,
}) =>
  !!(
    charge.providerOtherId &&
    charge.providerOtherIdQualifier &&
    `${charge.providerOtherIdQualifier}${charge.providerOtherId}` !==
      `${billingProviderOtherIdQualifier}${billingProviderOtherId}`
  );

export const MAX_INVOICE = {
  MAX_CHARGES: 6,
  get HELPER_TEXT() {
    return `Invoices that possess more than ${
      this.MAX_CHARGES
    } charges will remain in Ready to Submit. Separate each invoice into ${
      this.MAX_CHARGES
    } charges or less.`;
  },
  get HOVER_TEXT() {
    return `Invoices that possess more than ${
      this.MAX_CHARGES
    } charges will remain in Ready to Submit. Separate each invoice into ${
      this.MAX_CHARGES
    } charges or less.`;
  },
  TITLE: 'Invoices(s) Encountered Issues Generating & Submitting',
  ACTION_PAST_TENSE: 'Generated & Submitted',
  ACTION_PRESENT_TENSE: 'Generate & Submit',
};

export const DESIGNATED_CLEARINGHOUSE = {
  CORRECT_COUNT: 1,
  get HELPER_TEXT() {
    return `Invoices with payers that do not have ${
      this.CORRECT_COUNT
    } designated clearinghouse will remain in Ready to Submit. Please address your Payer Maintenance settings for these payers before submitting claims.`;
  },
  HOVER_TEXT: 'Payer Needs Designated Clearinghouse',
  TITLE: 'Payer Needs Designated Clearinghouse',
  ACTION_PAST_TENSE: 'Generated & Submitted',
  ACTION_PRESENT_TENSE: 'Generate & Submit',
};

export const isElectronicRow = row => {
  if (
    row.paymentResponsibilityLevelCode === 'Primary' ||
    row.paymentResponsibilityLevelCode === null
  ) {
    return row.submissionMethod === 'Electronic Claims';
  }

  if (row.paymentResponsibilityLevelCode === 'Secondary') {
    return row.submissionMethodSecondary === 'Electronic Claims';
  }

  return false;
};

const __dispatchClaimStatusUpdateErrorMessage = () => {
  store.dispatch(openError(errorOccurred('updating', 'claim status')));
};

const __dispatchClaimStatusUpdateSuccessMessage = (
  claimCount,
  submissionMethod = '',
) => {
  store.dispatch(
    openSuccess(
      ECLAIM_STATUS_CHANGE_SUCCESS_MESSAGE(claimCount, submissionMethod),
    ),
  );
};

const __bulkUpdateClaimStatus = async (submissionMethod, claims, status) => {
  try {
    const bulkUpdatePayload = {
      claims: claims.map(c => {
        if (submissionMethod === 'electronic') {
          const [{ effectiveDate: lastEffectiveDate }] = c.claimStatuses;

          let effectiveDate = parseDate().endOf('day');

          if (
            lastEffectiveDate &&
            parseDate(lastEffectiveDate) > effectiveDate
          ) {
            effectiveDate = parseDate(lastEffectiveDate).endOf('day');
          }

          return {
            id: c.id,
            effectiveDate: effectiveDate.toISOString(),
          };
        }
        return { id: c.id };
      }),
      claimStatus: status,
    };
    await bulkUpdateStatus(bulkUpdatePayload);

    __dispatchClaimStatusUpdateSuccessMessage(claims.length, submissionMethod);
  } catch (error) {
    __dispatchClaimStatusUpdateErrorMessage();
  }
};

export const __hasPendingBatches = async () => {
  const pendingBatches = await claimBatchApi.getPendingBatches();
  const hasPendingBatches = pendingBatches && pendingBatches.length > 0;

  return hasPendingBatches
    ? openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: 'Pending Batch',
        message: CONFIRM_GENERATE_CLAIM_BATCH_IF_PENDING(),
        confirmText: 'Yes',
        cancelText: 'No',
      })
    : true;
};

export const generateBatch = async (rowItems, patientId) => {
  try {
    const proceedIfPendingBatches = await __hasPendingBatches();

    if (!proceedIfPendingBatches) return;

    const { data: claimBatchResponse } = await claimBatchApi.add(rowItems, 2);

    if (claimBatchResponse[0].internalErrorCount) {
      store.dispatch(
        openWarning(
          generateInternalErrorMessage(
            claimBatchResponse[0].internalErrorCount,
          ),
        ),
      );
    }

    if (
      !claimBatchResponse[0].failedClaimsCount &&
      !claimBatchResponse[0].internalErrorCount
    ) {
      store.dispatch(openSuccess(`${successfulGenerateBatch}`));

      await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
        patientId,
      });

      return;
    }

    store.dispatch(
      openWarning(
        generateValidationErrorMessage(claimBatchResponse[0].failedClaimsCount),
      ),
    );
  } catch (e) {
    console.error(e);
    await store.dispatch(
      openError('An error occurred when generating Claim Batch.'),
    );
  }
};

export const validatePotentialClaimCharges = async lineItems => {
  let result = true;

  const title = 'A claim could not be generated for the following charge(s):';
  const messages = [];
  const chargeNumbers = list =>
    list.map(({ chargeNumber }) => chargeNumber).join(', ');
  const notInvoicedCharges = lineItems.filter(li => li.invoiceId === null);

  if (notInvoicedCharges.length) {
    messages.push(
      `Charge(s) <b>${chargeNumbers(
        notInvoicedCharges,
      )}</b> are not associated to an invoice.`,
    );

    result = false;
  }

  const notEncounterCharges = lineItems.filter(
    li => li.encounterNumber === null,
  );

  if (notEncounterCharges.length) {
    messages.push(
      `Charge(s) <b>${chargeNumbers(
        notEncounterCharges,
      )}</b> are not encounter charges.`,
    );

    result = false;
  }

  const suppressedOrHoldCharges = lineItems.filter(
    li => li.suppressFromClaim || li.holdClaim,
  );

  if (suppressedOrHoldCharges.length) {
    messages.push(
      `Charge(s) <b>${chargeNumbers(
        suppressedOrHoldCharges,
      )}</b> are either suppressed or on hold for claims.`,
    );

    result = false;
  }

  if (!result) {
    const message = html`
      <div>${messages.map(m => html` ${unsafeHTML(m)}<br /> `)}</div>
    `;
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title,
      message,
    });

    return false;
  }

  return true;
};

export const verifyClaimsWithPopups = async (
  excessChargesInvoiceNumbers,
  incorrectClearinghouseInvoiceNumbers,
  excessChargePopup,
  designatedClearinghousePopup,
) => {
  let greenLight = true;

  if (excessChargesInvoiceNumbers.length) {
    greenLight = await openPopup(POPUP_RENDER_KEYS.CLAIM_LINE_ITEMS, {
      title: excessChargePopup.TITLE,
      helperText: excessChargePopup.HELPER_TEXT,
      actionPastTense: excessChargePopup.ACTION_PAST_TENSE,
      actionPresentTense: excessChargePopup.ACTION_PRESENT_TENSE,
      invoiceNumbers: excessChargesInvoiceNumbers,
    });
  }

  if (incorrectClearinghouseInvoiceNumbers.length) {
    greenLight = await openPopup(POPUP_RENDER_KEYS.CLAIM_LINE_ITEMS, {
      title: designatedClearinghousePopup.TITLE,
      helperText: designatedClearinghousePopup.HELPER_TEXT,
      actionPastTense: designatedClearinghousePopup.ACTION_PAST_TENSE,
      actionPresentTense: designatedClearinghousePopup.ACTION_PRESENT_TENSE,
      invoiceNumbers: incorrectClearinghouseInvoiceNumbers,
    });
  }

  return greenLight;
};

export const verifyPotentialClaims = async claims => {
  const excessChargesInvoiceNumbers = claims
    .filter(c => c.lineItemCount > MAX_INVOICE.MAX_CHARGES)
    .map(c => c.invoiceNumber);

  const incorrectClearinghouseInvoiceNumbers = claims
    .filter(
      c =>
        c.designatedClearinghouseCount !==
          DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT && isElectronicRow(c),
    )
    .map(c => c.invoiceNumber);

  const greenLight = await verifyClaimsWithPopups(
    excessChargesInvoiceNumbers,
    incorrectClearinghouseInvoiceNumbers,
    MAX_INVOICE,
    DESIGNATED_CLEARINGHOUSE,
  );

  if (greenLight) {
    return claims.filter(
      r =>
        (r.lineItemCount <= MAX_INVOICE.MAX_CHARGES &&
          r.designatedClearinghouseCount ===
            DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT) ||
        !isElectronicRow(r),
    );
  }

  return false;
};

const filterDuplicateClaims = data =>
  Object.values(
    data.reduce((acc, item) => ({ ...acc, [item.claimNumber]: item }), {}),
  );

const validateClaims = claims => {
  if (!claims || !claims.length) {
    store.dispatch(openInfo(ECLAIM_STATUS_CHANGE_NO_CHARGES_SELECTED()));
    return false;
  }

  const containsNullClaim = claims.some(element => element.id === null);

  if (containsNullClaim) {
    store.dispatch(
      openError(ECLAIM_STATUS_CHANGE_CHARGES_NOT_ASSOCIATED_WITH_CLAIM()),
    );

    return false;
  }
  return true;
};

const validateClaimsToChangeStatus = claimsToChangeStatus => {
  if (
    claimsToChangeStatus.claimsCanNotBeChanged.claimNumber.length &&
    !claimsToChangeStatus.paper &&
    !claimsToChangeStatus.electronic
  ) {
    return !!store.dispatch(
      openInfo(ECLAIM_STATUS_CHANGE_CANT_UPDATE_MESSAGE()),
    );
  }

  return claimsToChangeStatus;
};

export const getClaimsToChangeStatus = async ({ claims, validate = true }) => {
  if (validate && !validateClaims(claims)) return false;

  const filteredClaims = filterDuplicateClaims(claims);

  const [paperClaims, eClaims] = filteredClaims.reduce(
    (accum, claim) => {
      if (claim.isElectronic) {
        accum[1].push(claim);
      } else {
        accum[0].push(claim);
      }

      return accum;
    },
    [[], []],
  );

  if (paperClaims.length || eClaims.length) {
    const claimsToChangeStatus = await openPopup(
      POPUP_RENDER_KEYS.CHANGE_PAPER_CLAIM_STATUS,
      { paperClaims, eClaims },
    );

    if (!claimsToChangeStatus) return false;

    return validateClaimsToChangeStatus(claimsToChangeStatus);
  }

  return false;
};

export const changeClaimStatus = async claims => {
  const claimsToChangeStatus = await getClaimsToChangeStatus({ claims });

  if (claimsToChangeStatus) {
    return Promise.all([
      __bulkUpdateClaimStatus(
        'electronic',
        claimsToChangeStatus.electronic.claims,
        claimsToChangeStatus.electronic.status,
      ),
      __bulkUpdateClaimStatus(
        'paper',
        claimsToChangeStatus.paper.claims,
        claimsToChangeStatus.paper.status,
      ),
    ]);
  }

  return false;
};

export const getNewClaimStatusEffectiveDate = currentEffectiveDate => {
  let effectiveDate = parseDate().endOf('day');

  if (currentEffectiveDate && parseDate(currentEffectiveDate) > effectiveDate) {
    effectiveDate = parseDate(currentEffectiveDate).endOf('day');
  }

  return effectiveDate.toISOString();
};

export const mapClaimTotalsChargesToCardTotals = claimTotalsCharges => {
  if (!claimTotalsCharges) {
    return {};
  }
  return {
    readyToSubmitCount: claimTotalsCharges.readyToSubmit.count,
    readyToSubmitAmount: claimTotalsCharges.readyToSubmit.claimCharge,
    needsAttentionCount: claimTotalsCharges.needsAttention.count,
    needsAttentionAmount: claimTotalsCharges.needsAttention.claimCharge,
    submittedCount: claimTotalsCharges.submitted.count,
    submittedAmount: claimTotalsCharges.submitted.claimCharge,
    deniedCount: claimTotalsCharges.denied.count,
    deniedAmount: claimTotalsCharges.denied.claimCharge,
    approvedCount: claimTotalsCharges.approved.count,
  };
};
