import { LitElement, html, css } from 'lit';

import NebIcon from '../../../../../src/components/misc/neb-icon';
import { RENDERER_SVGS } from '../../../../neb-styles/icons';
import { renderNebTableStyles } from '../../../../neb-styles/neb-table-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { formatIcdSelectorIdCode } from '../../../../neb-utils/neb-charting-util';

const MONTH_DAY_YEAR = 'MM/DD/YYYY';
export const ELEMENTS = {
  code: {
    id: 'code',
  },
  shortDescription: {
    id: 'short-description',
  },
  providerName: {
    id: 'provider-name',
  },
  type: {
    id: 'type',
  },
  status: {
    id: 'status',
  },
  onsetDate: {
    id: 'onset-date',
  },
  resolutionDate: {
    id: 'resolution-date',
  },
  handleIcon: {
    id: 'drag-handle',
  },
  moreIcon: {
    id: 'view-more',
  },
  row: {
    id: 'row',
  },
};
NebIcon.iconSets = {
  neb: RENDERER_SVGS,
};

class NebProblemListTableRow extends LitElement {
  static get properties() {
    return {
      filtered: Boolean,
      problem: Object,
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.index = 0;
    this.problem = {};
    this.small = false;

    this.onUpdateProblem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onUpdateProblem(this.index, this.problem),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      :host([small]) .drag-handle {
        flex: 0;
      }

      :host([small]) .neb-table-row .neb-table-cell:first-child {
        padding-left: 10px;
      }

      :host([small]) .neb-table-row .neb-table-cell:last-child {
        padding-right: 10px;
      }

      :host([small]) .cell-description {
        padding-right: 0;
      }

      :host([small]) .cell-diagnoses {
        flex: 0 0 75px;
      }

      :host([small]) .cell-view-problem {
        padding-left: 0;
      }

      .drag-handle {
        display: flex;
        justify-content: center;
        flex: 0 0 40px;
      }

      .drag-handle-icon {
        fill: ${CSS_COLOR_HIGHLIGHT};
        cursor: ns-resize;
        height: 20px;
        width: 20px;
      }

      .cell-diagnosis {
        max-width: 100px;
      }

      .cell-onset-date {
        min-width: 70px;
      }

      .cell-resolution-date {
        min-width: 70px;
      }

      .cell-view-problem {
        text-align: right;
        padding-right: 5px;
        flex: 0 0 40px;
      }

      .view-icon {
        padding: 5px;
        height: 12px;
        width: 12px;
        transform: rotate(-90deg);
        fill: ${CSS_COLOR_GREY_1};
      }

      .neb-table-cell {
        text-transform: capitalize;
        word-break: break-word;
      }

      .cell-description {
        text-transform: none;
      }

      .content-container {
        display: flex;
        width: 100%;
        cursor: pointer;
      }

      .neb-table-large-width {
        min-width: 100px;
      }

      .neb-table-small-width {
        min-width: 80px;
      }
    `;
  }

  render() {
    return html`
      ${renderNebTableStyles()}

      <div
        class="neb-table-row"
        id="code-${formatIcdSelectorIdCode(this.problem.code)}"
      >
        <div
          id="${ELEMENTS.handleIcon.id}"
          reorder
          class="neb-table-cell drag-handle"
        >
          <neb-icon class="drag-handle-icon" icon="neb:reorder"></neb-icon>
        </div>

        <div
          id="${ELEMENTS.row.id}"
          class="content-container"
          @click="${this.__handlers.click}"
        >
          <div
            id="${ELEMENTS.code.id}"
            class="neb-table-cell neb-table-small-width cell-diagnosis"
          >
            ${this.problem.code}
          </div>
          <div
            id="${ELEMENTS.shortDescription.id}"
            class="neb-table-cell neb-table-large-width cell-description"
          >
            ${this.problem.shortDescription}
          </div>
          ${!this.small
            ? html`
                <div
                  id="${ELEMENTS.providerName.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  ${this.problem.providerName}
                </div>
                <div
                  id="${ELEMENTS.type.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  ${this.problem.type}
                </div>
                <div
                  id="${ELEMENTS.status.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  ${this.problem.status}
                </div>
                <div
                  id="${ELEMENTS.onsetDate.id}"
                  class="neb-table-cell neb-table-small-width cell-onset-date"
                >
                  ${parseDate(this.problem.onsetDate).format(MONTH_DAY_YEAR)}
                </div>
                <div
                  id="${ELEMENTS.resolutionDate.id}"
                  class="neb-table-cell neb-table-small-width cell-resolution-date"
                >
                  ${this.problem.resolutionDate
                    ? parseDate(this.problem.resolutionDate).format(
                        MONTH_DAY_YEAR,
                      )
                    : ''}
                </div>
              `
            : ''}

          <div class="neb-table-cell cell-view-problem">
            <neb-icon
              id="${ELEMENTS.moreIcon.id}"
              class="view-icon"
              icon="neb:chevron"
            ></neb-icon>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-problem-list-table-row', NebProblemListTableRow);
