import '../neb-tooltip';
import '../inputs/neb-textfield';

import { css, html } from 'lit';

import { TYPE } from '../../../../neb-utils/fee-schedule';
import { currency } from '../../../../neb-utils/masks';

import NebTable from './neb-table';

export const ELEMENTS = {
  amountTooltip: { id: 'tooltip-amount' },
  amountCells: { selector: '[id^=amount-]' },
  allowedAmountCells: { selector: '[id^=allowedAmount-]' },
};

class NebTableChargeFeeSchedules extends NebTable {
  initState() {
    super.initState();

    this.writable = true;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .field {
          width: 96px;
        }

        .tooltip {
          padding-left: 7px;
        }
      `,
    ];
  }

  __renderTooltip(key) {
    return key === 'amount'
      ? html`
          <neb-tooltip id="${ELEMENTS[`${key}Tooltip`].id}" class="tooltip">
            <div slot="tooltip">
              This amount will override the base amount and will be used to
              calculate the Billed amount for the charge.
            </div>
          </neb-tooltip>
        `
      : '';
  }

  __renderTooltipHeader(columnConfig) {
    const { key } = columnConfig;

    return html`
      ${super.renderHeaderCell(columnConfig)} ${this.__renderTooltip(key)}
    `;
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'amount':
      case 'allowedAmount':
      case 'adjustmentAmount':
        return this.__renderTooltipHeader(columnConfig);

      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const { key } = columnConfig;
    const row = this.model[rowIndex];
    const disabled = key === 'amount' && row.type === TYPE.PERCENT;

    switch (key) {
      case 'amount':
      case 'allowedAmount':
        return html`
          <neb-textfield
            id="${key}-${rowIndex}"
            class="field"
            helper=" "
            label=" "
            .name="${name}"
            .value="${value}"
            .error="${error}"
            .mask="${currency}"
            .inputMode="numeric"
            .onChange="${this.handlers.change}"
            ?disabled="${disabled}"
          ></neb-textfield>
        `;

      default:
        return value;
    }
  }
}
customElements.define(
  'neb-table-charge-fee-schedules',
  NebTableChargeFeeSchedules,
);
