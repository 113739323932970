import { getBulkActionList } from '../../ledger/line-items';
import { PRACTICE_SETTINGS, getPracticeSettings } from '../practice-settings';

export default async ({ lineItemIds }) => {
  const res = await getBulkActionList({ lineItemIds });

  const practiceSettings = await getPracticeSettings();
  const autoPost = practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  if (!autoPost) return res;

  const actions = res.actions.filter(action => action.id !== 'unpost');
  return { ...res, actions };
};
