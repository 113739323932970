import '../../../neb-lit-components/src/components/inputs/neb-menu';

import { html, css } from 'lit';

import { RENDERER_SVGS } from '../../../neb-styles/icons';
import { CSS_COLOR_GREY_5 } from '../../../neb-styles/neb-variables';

import {
  NebMDBaseTextField,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-md-base-textfield';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  menu: {
    id: 'menu',
  },
  input: {
    id: 'textfield-input',
  },
  leadingIcon: {
    id: 'leading-icon',
  },
  trailingIcon: {
    id: 'trailing-icon',
  },
};

class NebMDTextFieldWithIcon extends NebMDBaseTextField {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      scrollOnKeydown: Boolean,
      maxVisibleItems: Number,
      itemHeight: Number,
      thresholdCount: Number,
      selectedIndex: Number,
      leadingIcon: String,
      trailingIcon: String,
      menuItems: Array,
      showFullText: {
        reflect: true,
        type: Boolean,
      },
      leadingButton: {
        reflect: true,
        type: Boolean,
      },
      leadingVisible: {
        reflect: true,
        type: Boolean,
      },
      trailingButton: {
        reflect: true,
        type: Boolean,
      },
      trailingVisible: {
        reflect: true,
        type: Boolean,
      },
      disappearingLabel: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  get inputEl() {
    return this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  get textfieldClasses() {
    let classes = this.__baseTextWithIconClasses();

    if (this.disappearingLabel) classes = `${classes} disappearing-label`;

    return classes;
  }

  initState() {
    super.initState();
    this.showFullText = false;
    this.leadingButton = false;
    this.trailingButton = false;
    this.leadingVisible = false;
    this.trailingVisible = false;
    this.disappearingLabel = false;
    this.scrollOnKeydown = true;
    this.maxVisibleItems = 4;
    this.itemHeight = 48;
    this.thresholdCount = 4;
    this.selectedIndex = -1;
    this.leadingIcon = '';
    this.trailingIcon = '';
    this.menuItems = [];

    this.onClickTrailingIcon = () => {};

    this.onClickLeadingIcon = () => {};

    this.onSelect = () => {};

    this.onRequest = () => {};

    this.onRenderItem = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickTrailingIcon: e => {
        this.inputEl.blur();
        this.onClickTrailingIcon(e);
      },
      clickLeadingIcon: () => this.onClickLeadingIcon(),
      select: index => {
        this.inputEl.blur();
        this.onSelect(this.menuItems[index], index);
      },
      request: () => this.onRequest(),
      preventFocusOnIosToFixKeyboardIssue: e => {
        if (this.selectedIndex !== -1) {
          this.inputEl.blur();
          e.preventDefault();
        }
      },
    };
  }

  shouldShowMenu() {
    return (
      this.focused() && this.menuItems.length > 0 && this.selectedIndex === -1
    );
  }

  __hasLeadingIcon() {
    return this.leadingIcon.length && this.leadingVisible;
  }

  __hasTrailingIcon() {
    return this.trailingIcon.length && this.trailingVisible;
  }

  __getMenuItems() {
    return [...this.menuItems];
  }

  update(changedProps) {
    if (changedProps.has('__focused') || changedProps.has('menuItems')) {
      this.__open = this.shouldShowMenu();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          flex-shrink: 0;

          --menu-content-max-width: unset;
          --menu-content-padding: 8px 0;
          --menu-item-padding: 0 16px;
        }

        :host([open]) {
          z-index: 1;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the default label text height floating on highlight */
        .mdc-text-field--with-leading-icon .mdc-text-field__icon,
        .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
          width: 23px;
          height: 22px;

          bottom: 9px;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the default label text height floating on highlight */
        .mdc-text-field--with-leading-icon.mdc-text-field--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          left: 0;
          transform: translateY(-180%) translateX(-7px) scale(0.8);
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          left: 0;
          transform: translateY(-180%) translateX(-7px) scale(0.8);
        }

        .mdc-text-field--with-leading-icon.mdc-text-field--outlined.disappearing-label
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          transform: scale(1);
          opacity: 0;
        }

        .mdc-text-field--with-trailing-icon.mdc-text-field--outlined.disappearing-label
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          transform: scale(1);
          opacity: 0;
        }

        .mdc-text-field.mdc-text-field--disabled .mdc-text-field__icon {
          fill: ${CSS_COLOR_GREY_5};
          stroke: ${CSS_COLOR_GREY_5};
          stroke-width: 1;
          opacity: 0.27;
        }

        .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
          fill: ${CSS_COLOR_GREY_5};
          stroke: ${CSS_COLOR_GREY_5};
          stroke-width: 1;
          opacity: 0.5;
        }

        .mdc-text-field--invalid:not(.mdc-text-field--disabled)
          .mdc-text-field__icon {
          fill: ${CSS_COLOR_GREY_5};
          stroke: ${CSS_COLOR_GREY_5};
          stroke-width: 2;
          opacity: 0.5;
        }
      `,
    ];
  }

  __iconButtonHTML(iconKey, isLeading) {
    return isLeading
      ? html`
          <svg
            id="${ELEMENTS.leadingIcon.id}"
            class="material-icons mdc-text-field__icon"
            aria-hidden="true"
            viewBox="${RENDERER_SVGS[iconKey].viewBox}"
            preserveAspectRatio
            tabindex="0"
            role="button"
            @click="${this.handlers.clickLeadingIcon}"
          >
            ${RENDERER_SVGS[iconKey].src}
          </svg>
        `
      : html`
          <svg
            id="${ELEMENTS.trailingIcon.id}"
            class="material-icons mdc-text-field__icon"
            aria-hidden="true"
            viewBox="${RENDERER_SVGS[iconKey].viewBox}"
            preserveAspectRatio
            tabindex="0"
            role="button"
            @click="${this.handlers.clickTrailingIcon}"
          >
            ${RENDERER_SVGS[iconKey].src}
          </svg>
        `;
  }

  __iconHTML(iconKey, isButton, isLeading) {
    return isButton
      ? this.__iconButtonHTML(iconKey, isLeading)
      : html`
          <svg
            id="${isLeading
              ? ELEMENTS.leadingIcon.id
              : ELEMENTS.trailingIcon.id}"
            class="material-icons mdc-text-field__icon"
            aria-hidden="true"
            viewBox="${RENDERER_SVGS[iconKey].viewBox}"
            preserveAspectRatio
          >
            ${RENDERER_SVGS[iconKey].src}
          </svg>
        `;
  }

  __getLeadingIcon() {
    if (this.__hasLeadingIcon()) {
      return this.__iconHTML(this.leadingIcon, this.leadingButton, true);
    }

    return '';
  }

  __getTrailingIcon() {
    if (this.__hasTrailingIcon()) {
      return this.__iconHTML(this.trailingIcon, this.trailingButton, false);
    }

    return '';
  }

  __baseTextWithIconClasses() {
    let classes = super._textFieldClasses();

    if (this.__hasLeadingIcon()) {
      classes = `${classes} mdc-text-field--with-leading-icon`;
    }

    if (this.__hasTrailingIcon()) {
      classes = `${classes} mdc-text-field--with-trailing-icon`;
    }

    return classes;
  }

  renderTextfield() {
    return html`
      ${this.__getLeadingIcon()} ${this.__getTrailingIcon()}

      <input
        id="${ELEMENTS.input.id}"
        class="mdc-text-field__input"
        type="text"
        autocomplete="off"
        autocapitalize="none"
        maxlength="${this.maxLength}"
        minlength="${this.minLength}"
        placeholder="${this.getPlaceholder()}"
        aria-controls="${ELEMENTS.helperText.id}"
        aria-describedby="${ELEMENTS.helperText.id}"
        @input="${this.handlers.input}"
        @touchstart="${this.handlers.preventFocusOnIosToFixKeyboardIssue}"
        ?readonly="${this.disabled || this.selectedIndex !== -1}"
      />

      ${this.__mdcNotchHTML(ELEMENTS.input.id)}

      <neb-menu
        id="${ELEMENTS.menu.id}"
        .scrollOnKeydown="${this.scrollOnKeydown}"
        .maxVisibleItems="${this.maxVisibleItems}"
        .itemHeight="${this.itemHeight}"
        .thresholdCount="${this.thresholdCount}"
        .selectedIndex="${this.selectedIndex}"
        .items="${this.__getMenuItems()}"
        .onChange="${this.handlers.select}"
        .onRequest="${this.handlers.request}"
        .onRenderItem="${this.onRenderItem}"
        ?showFullText="${this.showFullText}"
        ?open="${this.shouldShowMenu()}"
      ></neb-menu>
    `;
  }
}

customElements.define('neb-md-textfield-with-icon', NebMDTextFieldWithIcon);
