import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import { LitElement, html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { numberNoLeadingZero } from '../../neb-utils/masks';

const TYPE = Object.freeze([
  {
    label: 'Day(s)',
    value: 'D',
  },
  {
    label: 'Week(s)',
    value: 'W',
  },
  {
    label: 'Month(s)',
    value: 'M',
  },
]);

export const ELEMENTS = {
  cancelButton: {
    id: 'cancel',
  },
  frequencyDropdown: {
    id: 'frequency-type',
  },
  integerInputs: {
    selector: '.textfield',
    tag: 'neb-textfield',
  },
  durationDropdown: {
    id: 'duration-type',
  },
  textfieldDuration: {
    id: 'duration',
  },
  textfieldFrequency: {
    id: 'frequency',
  },
  textfieldOccurrence: {
    id: 'occurrence',
  },
  saveButton: {
    id: 'save',
  },
};

class NebTreatmentPhaseForm extends LitElement {
  static get properties() {
    return {
      __invalid: {
        type: Object,
      },

      index: {
        type: Number,
      },
      model: {
        type: Object,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__invalid = {};

    this.index = 0;
    this.model = null;
    this.small = false;

    this.onCancel = () => {};

    this.onEdit = () => {};

    this.onSave = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: ({ name, value }) => {
        if (!value) {
          this.__invalid = {
            ...this.__invalid,
            [name]: true,
          };
        }
      },
      cancel: () => {
        this.onCancel(this.index);
      },
      change: ({ name, value }) => {
        if (value) {
          this.model = {
            ...this.model,
            [name]: parseInt(value, 10),
          };

          this.onEdit(this.model, this.index);

          delete this.__invalid[name];
        }
      },
      changeType: ({ name, value: { value } }) => {
        this.model = {
          ...this.model,
          [name]: value,
        };
      },
      save: () => {
        if (this.validate()) {
          this.onSave(this.model, this.index);
        }
      },
    };
  }

  __isInvalid() {
    return Boolean(Object.keys(this.__invalid).length);
  }

  __getSelectedDurationType() {
    return TYPE.find(t => t.value === this.model.durationType);
  }

  __getSelectedFrequencyType() {
    return TYPE.find(t => t.value === this.model.frequencyType);
  }

  validate() {
    if (!this.model.occurrence) {
      this.__invalid = {
        ...this.__invalid,
        occurrence: true,
      };
    }

    if (!this.model.frequency) {
      this.__invalid = {
        ...this.__invalid,
        frequency: true,
      };
    }

    if (!this.model.duration) {
      this.__invalid = {
        ...this.__invalid,
        duration: true,
      };
    }

    return !this.__isInvalid();
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: fit-content;

        padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
      }

      :host([small]) .container {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: ${CSS_SPACING};
      }

      .label-phase {
        margin-right: ${CSS_SPACING};
      }

      :host([small]) .label-phase {
        margin: 0 0 ${CSS_SPACING} 0;
      }

      .container-inputs {
        display: flex;
        align-items: flex-start;
        width: fit-content;
        height: fit-content;

        margin-right: ${CSS_SPACING};
      }

      :host([small]) .container-inputs {
        flex-direction: column;
        width: 100%;
        margin-bottom: ${CSS_SPACING};
      }

      .container-duration,
      .container-occurrence {
        display: flex;
        align-items: flex-start;
        width: fit-content;
        height: 100%;
      }

      :host([small]) .container-duration {
        justify-content: space-between;
        width: 100%;
        height: 60px;
      }

      :host([small]) .container-occurrence {
        justify-content: space-between;
        width: 100%;
        height: 60px;

        margin-bottom: ${CSS_SPACING};
      }

      .container-buttons {
        display: flex;
        width: fit-content;
        height: fit-content;
      }

      .textfield-duration,
      .textfield-frequency {
        margin-right: ${CSS_SPACING};
      }

      .button-save {
        margin-right: 10px;
      }

      .label-article {
        height: fit-content;
        margin: 0 10px;
      }

      .label {
        margin-top: 10px;
      }

      .select {
        width: 110px;
      }

      .textfield {
        width: 60px;
      }

      :host([small]) .textfield {
        flex: 1 0 0;
      }

      :host([small]) .label-article-duration {
        flex: 1 0 0;
      }
    `;
  }

  __renderButtons() {
    return html`
      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.saveButton.id}"
          class="button button-save"
          label="Add Phase"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.__handlers.save}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button button-cancel"
          label="Cancel"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  __renderDuration() {
    return html`
      <div class="container-duration">
        <div class="label label-article label-article-duration">x</div>

        <neb-textfield
          id="${ELEMENTS.textfieldDuration.id}"
          class="textfield textfield-duration"
          name="duration"
          maxlength="2"
          .error="${this.__invalid.duration ? 'Required' : ''}"
          .mask="${numberNoLeadingZero}"
          .inputMode="${'numeric'}"
          .value="${this.model.duration.toString()}"
          .onBlur="${this.__handlers.blur}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.durationDropdown.id}"
          class="select select-duration"
          name="durationType"
          .items="${TYPE}"
          .value="${this.__getSelectedDurationType()}"
          .onChange="${this.__handlers.changeType}"
        ></neb-select>
      </div>
    `;
  }

  __renderPhaseLabel() {
    return html` <div class="label label-phase">P${this.index + 1}:</div> `;
  }

  __renderOccurrenceAndFrequency() {
    return html`
      <div class="container-occurrence">
        <neb-textfield
          id="${ELEMENTS.textfieldOccurrence.id}"
          class="textfield textfield-occurrence"
          name="occurrence"
          maxlength="2"
          .error="${this.__invalid.occurrence ? 'Required' : ''}"
          .mask="${numberNoLeadingZero}"
          .inputMode="${'numeric'}"
          .value="${this.model.occurrence.toString()}"
          .onBlur="${this.__handlers.blur}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <div class="label label-article">x</div>

        <neb-textfield
          id="${ELEMENTS.textfieldFrequency.id}"
          class="textfield textfield-frequency"
          name="frequency"
          maxlength="2"
          .error="${this.__invalid.frequency ? 'Required' : ''}"
          .mask="${numberNoLeadingZero}"
          .inputMode="${'numeric'}"
          .value="${this.model.frequency.toString()}"
          .onBlur="${this.__handlers.blur}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.frequencyDropdown.id}"
          class="select select-frequency"
          name="frequencyType"
          .items="${TYPE}"
          .value="${this.__getSelectedFrequencyType()}"
          .onChange="${this.__handlers.changeType}"
        ></neb-select>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderPhaseLabel()}

        <div class="container-inputs">
          ${this.__renderOccurrenceAndFrequency()} ${this.__renderDuration()}
        </div>

        ${this.__renderButtons()}
      </div>
    `;
  }
}

customElements.define('neb-treatment-phase-form', NebTreatmentPhaseForm);
