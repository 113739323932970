import DeprecatedApiClient, {
  buildQueryString,
  Method,
} from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });
export const headerJSON = { 'Content-Type': 'application/json' };

const baseUrl = 'ledger/activity';

export const getPracticeLedgerActivityTransactions = async (
  queryParams = {},
) => {
  const route = `${baseUrl}/transactions`;
  const path = `${route}${buildQueryString(queryParams)}`;

  const { count, results } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path,
    headers: headerJSON,
    version: 1,
  });

  return { count, results };
};
