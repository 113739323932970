import '../../neb-material-design/src/components/neb-md-select';
import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { openDirtyPopup } from '..';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { APPOINTMENT_ACTIONS } from '../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectInputReason: {
    id: 'select-reason',
  },
  textInputNote: {
    id: 'text-note',
  },
  textMessage: {
    id: 'text-message',
  },
};

export const EMPTY_CANCEL_REASON = {
  id: '',
  name: '',
};

class NebPopupAppointmentCancel extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __reason: {
        type: Object,
      },
      __note: {
        type: String,
      },
    };
  }

  initState() {
    super.initState();
    this.__note = '';
    this.__reason = EMPTY_CANCEL_REASON;
    this.__dirty = false;

    this.dynamic = true;
    this.title = APPOINTMENT_ACTIONS.CANCEL.title;
    this.message = APPOINTMENT_ACTIONS.CANCEL.message;
    this.model = {
      reasons: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () => {
        this.onClose({
          accepted: true,
          reason: this.__reason && this.__reason.id ? this.__reason.id : null,
          note: this.__note,
        });
      },
      cancel: async () => {
        if (this.isDirty()) {
          if (await openDirtyPopup()) this.onClose();
        } else this.onClose();
      },
      updateNote: e => {
        this.__note = e.value;
      },
      updateReason: e => {
        if (this.__reason.name !== e.value) this.__dirty = true;

        if (this.model.reasons && this.model.reasons.length) {
          this.__reason = this.model.reasons.find(
            reason => reason.name === e.value,
          );
        } else this.__reason = EMPTY_CANCEL_REASON;
      },
    };
  }

  isDirty() {
    return this.__dirty || this.__note !== '';
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      const { reasons } = this.model;
      if (reasons) [this.__reason] = reasons;

      this.model.reasons = [EMPTY_CANCEL_REASON, ...this.model.reasons];
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 650px;
          height: 100%;
        }

        :host([layout='small']) .content-container {
          width: 300px;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: ${CSS_SPACING};
        }

        .reason {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .note {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  __getReasons() {
    return this.model.reasons.map(reason => reason.name);
  }

  __renderCancelReason() {
    return this.model.reasons && this.model.reasons.length > 1
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputReason.id}"
            class="reason"
            name="${ELEMENTS.selectInputReason.id}"
            label="Cancel Reason"
            .items="${this.__getReasons()}"
            .value="${this.__reason ? this.__reason.name : ''}"
            .onChange="${this.__handlers.updateReason}"
          ></neb-select>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="content-container">
        ${this.__renderCancelReason()}

        <neb-textarea
          id="${ELEMENTS.textInputNote.id}"
          class="note"
          name="${ELEMENTS.textInputNote.id}"
          label="Cancel Note"
          maxlength="500"
          name="note"
          showCount
          .value="${this.__note}"
          .onChange="${this.__handlers.updateNote}"
        >
        </neb-textarea>

        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="YES"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="NO"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-appointment-cancel',
  NebPopupAppointmentCancel,
);
