import { _itemsGroupedById, _buildError, _sortItems } from './base';

export const _creating = state => ({
  ...state,
  created: false,
  creating: true,
});

export const _created = (state, { item }) => {
  const stateItems = state.items || [];

  const items = _sortItems([...stateItems, item]);

  return {
    ...state,
    creating: false,
    createError: null,
    created: true,
    itemsByGroupId: _itemsGroupedById(items),
    items,
  };
};

export const _createError = (state, action) => {
  const createError = _buildError(action, 'createError');

  return { ...state, creating: false, created: false, createError };
};
