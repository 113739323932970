import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';

import { html, css } from 'lit';

import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import createProviderCollection from '../../../packages/neb-utils/collections/providers';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import * as masks from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  patientSelectSearch: { id: 'patient-search' },
  lastDaysSeenSelect: { id: 'last-days-seen-select' },
  providerSelect: { id: 'provider-select' },
  locationsSelect: { id: 'locations-select' },
  statusSelect: { id: 'status-select' },
  caseSelect: { id: 'case-select' },
  invoiceNumberInput: { id: 'invoice-input' },
  encounterNumberInput: { id: 'encounter-input' },
  dateRangeInput: { id: 'date-range-input' },
  outstandingBalanceRangeInput: { id: 'outstanding-balance-range-input' },
};

export const LAST_DAYS_SEEN = Object.freeze({
  EMPTY: '',
  LAST_30: 'Last 30 Days',
  LAST_90: 'Last 90 Days',
  LAST_120: 'Last 120 Days',
});

export const LAST_DAYS_SEEN_ITEMS = Object.freeze([
  selectors.ITEM_EMPTY,
  { label: LAST_DAYS_SEEN.LAST_30, data: { id: 30 } },
  { label: LAST_DAYS_SEEN.LAST_90, data: { id: 90 } },
  { label: LAST_DAYS_SEEN.LAST_120, data: { id: 120 } },
]);

export const STATUS = Object.freeze({
  EMPTY: '',
  NONE: 'None Posted',
  SOME: 'Some Posted',
  ALL: 'All Posted',
});

export const FILTERS = Object.freeze({
  encounterNumber: 'encounterNumber',
  invoiceNumber: 'invoiceNumber',
  lastDaysSeen: 'lastDaysSeen',
  outstandingBalance: 'outstandingBalance',
  patientId: 'patientId',
  providerIds: 'providerIds',
  serviceDate: 'serviceDate',
  status: 'status',
  locations: 'locations',
  caseIds: 'caseIds',
});

class NebActivityEncounterFilters extends NebFilters {
  static get properties() {
    return {
      includePatient: Boolean,
      defaultLocationId: String,
      locations: Array,
      cases: Array,

      __patientItems: Array,
      __providerItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      encounterNumber: '',
      invoiceNumber: '',
      lastDaysSeen: '',
      outstandingBalance: NebCurrencyRange.createModel(),
      patientId: selectors.ITEM_EMPTY,
      providerIds: [],
      serviceDate: NebDateRange.createModel(),
      status: '',
      locations: [],
      caseIds: [],
    };
  }

  initState() {
    super.initState();

    this.includePatient = false;
    this.defaultLocationId = '';
    this.locations = [];
    this.cases = [];

    this.__patientItems = [];
    this.__providerItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clearInvoiceSearch: () => this.formService.apply('invoiceNumber', ''),
      clearEncounterSearch: () => this.formService.apply('encounterNumber', ''),

      apply: e => {
        const model = this.formService.build();
        const { from, to } = model.serviceDate;
        const isButtonPress = !!e;

        this.onApply(
          {
            ...model,
            serviceDate: {
              from: from ? parseDate(from._i) : null,
              to: to ? parseDate(to._i) : null,
            },
          },
          isButtonPress,
        );
      },

      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
        this.formService.apply(FILTERS.lastDaysSeen, LAST_DAYS_SEEN_ITEMS[0]);
      },

      changeLastDaysSeen: e => {
        this.formService.apply(e.name, e.value);
        this.formService.apply(`${FILTERS.serviceDate}.from`, null);
        this.formService.apply(`${FILTERS.serviceDate}.to`, null);
      },

      changePatient: e => {
        if (e.value) {
          return this.formService.apply(e.name, e.value);
        }

        return this.formService.apply(e.name, selectors.ITEM_EMPTY);
      },

      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,

      requestMorePatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        lastDaysSeen: selectors.select([]),
        providerIds: selectors.multiSelect([]),
        serviceDate: NebDateRange.createSelectors(),
        outstandingBalance: NebCurrencyRange.createSelectors(),
        locations: selectors.multiSelect([]),
        caseIds: selectors.multiSelect([]),
      },
    };
  }

  async load() {
    await createProviderCollection(
      ({ pageItems }) => {
        this.__providerItems = pageItems;
      },
      null,
      { hideInactive: false },
    );

    if (this.includePatient) {
      this.__patientService = createServerPatientsCollection({
        onChange: ({ pageItems }) => {
          this.__patientItems = pageItems.map(data => ({
            label: objToName(data.name, DEFAULT_NAME_OPTS),
            data,
          }));
        },
      });
    }
  }

  update(changedProps) {
    if (changedProps.has('defaultLocationId') && this.defaultLocationId) {
      this.__getDefaultLocation();
      this.load();
    }
    super.update(changedProps);
  }

  __getDefaultLocation() {
    if (this.defaultLocationId) {
      const location = this.locations.find(
        l => l.id === this.defaultLocationId,
      );

      if (location) {
        this.handlers.change({
          name: FILTERS.locations,
          value: this.__formatLocations([location]),
        });
      }
      this.handlers.apply();
    }
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      data: location,
    }));
  }

  __renderLocationsSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="select"
        name="${FILTERS.locations}"
        label="Locations"
        .items="${this.__formatLocations(this.locations)}"
        .value="${this.state.locations}"
        .onChange="${this.handlers.change}"
        multiSelect
      >
      </neb-select>
    `;
  }

  __renderPatientSelectSearch() {
    return this.includePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSelectSearch.id}"
            name="${FILTERS.patientId}"
            label="Patient"
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            .onRequest="${this.handlers.requestMorePatients}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  __renderCaseSelect() {
    return !this.includePatient
      ? html`
          <neb-select
            id="${ELEMENTS.caseSelect.id}"
            label="Cases"
            name="${FILTERS.caseIds}"
            .items="${this.cases}"
            .value="${this.state.caseIds}"
            .onChange="${this.handlers.change}"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  renderContent() {
    const serviceDate = {
      from: this.state.serviceDate.from
        ? parseDate(this.state.serviceDate.from)
        : null,
      to: this.state.serviceDate.to
        ? parseDate(this.state.serviceDate.to)
        : null,
    };

    return html`
      ${this.__renderCaseSelect()} ${this.__renderPatientSelectSearch()}
      <neb-select
        id="${ELEMENTS.lastDaysSeenSelect.id}"
        label="Last Days Seen"
        name="${FILTERS.lastDaysSeen}"
        .items="${LAST_DAYS_SEEN_ITEMS}"
        .value="${this.state.lastDaysSeen}"
        .onChange="${this.handlers.changeLastDaysSeen}"
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dateRangeInput.id}"
        label="Date"
        name="${FILTERS.serviceDate}"
        .model="${serviceDate}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        label="Providers"
        name="${FILTERS.providerIds}"
        multiSelect
        .items="${this.__providerItems}"
        .value="${this.state.providerIds}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        label="Charge Status"
        name="${FILTERS.status}"
        .items="${Object.values(STATUS)}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.invoiceNumberInput.id}"
        label="Invoice #"
        name="${FILTERS.invoiceNumber}"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoiceNumber ? 'neb:clear' : ''}"
        .value="${this.state.invoiceNumber}"
        .mask="${masks.number}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.encounterNumberInput.id}"
        label="Encounter #"
        name="${FILTERS.encounterNumber}"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.encounterNumber ? 'neb:clear' : ''}"
        .value="${this.state.encounterNumber}"
        .mask="${masks.number}"
        .onClickIcon="${this.handlers.clearEncounterSearch}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-range-currency
        id="${ELEMENTS.outstandingBalanceRangeInput.id}"
        label="Outstanding Balance"
        name="${FILTERS.outstandingBalance}"
        .model="${this.state.outstandingBalance}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>

      ${this.__renderLocationsSelect()}
    `;
  }
}

window.customElements.define(
  'neb-filters-activity-encounters',
  NebActivityEncounterFilters,
);
