import '../../../neb-material-design/src/components/neb-md-select';
import './inputs/neb-menu';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';
import Debouncer from '../../../neb-utils/debouncer';

const ACTIVE_PAGE_COUNT = 4;
export const ELEMENTS = {
  previousPageButton: {
    id: 'previous-page-button',
  },
  dropdownSection: {
    id: 'dropdown-section',
  },
  leftDoubleArrow: {
    id: 'left-double-arrow',
  },
  dropdownContainer: {
    id: 'dropdown-container',
  },
  dropdownButton: {
    id: 'dropdown-button',
  },
  menu: {
    id: 'menu',
  },
  listBox: {
    id: 'list-box',
  },
  pageButtons: {
    selector: '.page',
  },
  mobileDropdown: {
    id: 'mobile-dropdown',
  },
  nextPageButton: {
    id: 'next-page-button',
  },
  rightDoubleArrow: {
    id: 'right-double-arrow',
  },
};
/**
 * Component for generic pagination.
 *
 * @class
 * @property {Number} pageCount - set the number of pages for the pager.
 * @property {Number} currentPage - set the current page (0 indexed).
 * @property {String} layout - set the layout ('small', 'medium', or 'large')
 *
 * @type {Object}
 * @property {Number} event.detail.pageNumber - the new pageNumber (0 indexed); page label is 1 indexed.
 */

class NebPagination extends LitElement {
  static get properties() {
    return {
      __menuOpened: {
        type: Boolean,
      },
      currentPage: {
        type: Number,
      },
      pageCount: {
        type: Number,
      },
      layout: {
        type: String,
      },
      debouncerDelay: {
        type: Number,
      },
      warnPageChange: { type: Boolean },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__menuOpened = false;
    this.currentPage = 0;
    this.pageCount = 0;
    this.layout = 'large';
    this.debouncerDelay = 0;
    this.warnPageChange = false;

    this.onPageChanged = () => {};

    this.onWarnPageChange = () => {};

    this.__initDebouncer();
  }

  __initDebouncer() {
    this.__pageChangeDebouncer = new Debouncer(
      () => this.onPageChanged(this.currentPage),
      this.debouncerDelay,
    );
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__menuOpened = false;
      },
      setCurrentPage: ({ target }) => {
        const pageNumber = Number(target.getAttribute('page'));

        return this.__setCurrentPage(pageNumber);
      },
      previousPage: () => this.__previousPage(),
      openMenu: () => {
        this.__menuOpened = !this.__menuOpened;

        this.shadowRoot.getElementById(ELEMENTS.dropdownButton.id).focus();
      },
      nextPage: () => this.__nextPage(),
      mobileSelectChanged: e => this.__mobileSelectChanged(e),
      dropdownSelectPage: e => this.__dropdownSelectPage(e),
    };
  }

  __computeDisplayedPages(dropdownPages) {
    return dropdownPages.map(page => ({ label: page + 1 }));
  }

  __computePageLabel(pageIndex) {
    return pageIndex + 1;
  }

  __mobileSelectChanged({ target: { selectedIndex } }) {
    return this.__setCurrentPage(selectedIndex);
  }

  __getPageArray(pageCount) {
    if (!this.__pageArray || this.__pageArray.length !== pageCount) {
      this.__pageArray = pageCount > 0 ? [...Array(pageCount).keys()] : [];
    }

    return this.__pageArray;
  }

  __computeActiveAndDropdown(pageCount, currentPage) {
    const pageArray = this.__getPageArray(pageCount);

    const dropdownPages = pageArray;

    let activePages;

    if (pageCount > ACTIVE_PAGE_COUNT) {
      activePages = this.__activePages;

      if (!activePages) {
        // Initialize
        activePages = pageArray.slice(0, ACTIVE_PAGE_COUNT);
      }

      if (!activePages.includes(currentPage)) {
        if (currentPage < activePages[0]) {
          activePages = pageArray.slice(
            currentPage,
            ACTIVE_PAGE_COUNT + currentPage,
          );
        } else {
          activePages = pageArray.slice(
            currentPage - ACTIVE_PAGE_COUNT + 1,
            currentPage + 1,
          );
        }
      }

      this.__activePages = activePages;
    } else {
      activePages = pageArray;
    }

    return {
      dropdownPages,
      activePages,
    };
  }

  __computeActivePageButtonClass(pageNumber) {
    return pageNumber === this.currentPage
      ? 'page button selected'
      : 'page button';
  }

  __dropdownSelectPage(pageIndex) {
    this.__menuOpened = false;

    return this.__setCurrentPage(pageIndex);
  }

  changePage(pageNumber) {
    if (pageNumber !== this.currentPage) {
      this.currentPage = pageNumber;
      this.__pageChangeDebouncer.debounce();
    }
  }

  __setCurrentPage(pageNumber) {
    const callback = () => this.changePage(pageNumber);

    return this.warnPageChange ? this.onWarnPageChange(callback) : callback();
  }

  __nextPage() {
    if (this.currentPage === this.pageCount - 1) return null;

    return this.__setCurrentPage(this.currentPage + 1);
  }

  __previousPage() {
    if (this.currentPage === 0) return null;

    return this.__setCurrentPage(this.currentPage - 1);
  }

  update(changedProps) {
    if (changedProps.has('debouncerDelay')) {
      this.__initDebouncer();
    }

    super.update(changedProps);
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        --neb-mdc-select__selected-text: 40px;
      }

      ul {
        display: flex;
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        margin: 0;
        padding: 0;
      }

      li:first-of-type button {
        border-left: 1px solid ${CSS_COLOR_GREY_2};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      li:last-of-type button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      /** Normalize button across browsers; clear the border. */
      button {
        overflow: visible;
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        text-transform: none;
        -webkit-appearance: button;
        border: none;
      }

      .button {
        border-right: 1px solid ${CSS_COLOR_GREY_2};
        border-top: 1px solid ${CSS_COLOR_GREY_2};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        background-color: #fff;
        cursor: pointer;
        font-size: ${CSS_FONT_SIZE_BODY};
        height: 32px;
        min-width: 36px;
        text-decoration: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .button:focus {
        outline: 0;
      }

      .highlight-text {
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .button:active {
        color: ${CSS_COLOR_WHITE};
        background-color: ${CSS_COLOR_HIGHLIGHT};
      }

      .selected {
        color: ${CSS_COLOR_WHITE};
        background-color: ${CSS_COLOR_HIGHLIGHT};
      }

      .dropdown-container {
        display: flex;
      }

      .dropdown-section {
        position: relative;
      }

      .dropdown-content {
        overflow-x: hidden;
      }

      .left-double-arrow {
        transform: scale(0.39) rotate(180deg);
      }

      .right-double-arrow {
        transform: scale(0.39);
      }

      .icon-arrow {
        width: 24px;
        height: 24px;
        margin-top: 2px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .mobile-dropdown {
        color: transparent;
        border: none;
        background-color: transparent;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        height: 80px;
        width: calc(100% + 2px);
        position: relative;
        bottom: 80px;
        font-size: 16px;
      }
    `;
  }

  __renderDropdown(dropdownPages) {
    return this.layout === 'small'
      ? html`
          <select
            id="${ELEMENTS.mobileDropdown.id}"
            class="mobile-dropdown"
            @change="${this.__handlers.mobileSelectChanged}"
          >
            ${dropdownPages.map(
              pageNumber => html`
                <option
                  id="mobilePage_${this.__computePageLabel(pageNumber)}"
                  list-value="${pageNumber}"
                  .selected="${pageNumber === this.currentPage}"
                  .value="${pageNumber}"
                >
                  ${this.__computePageLabel(pageNumber)}
                </option>
              `,
            )}
          </select>
        `
      : html`
          <neb-menu
            id="${ELEMENTS.menu.id}"
            forceAlign="left"
            .selectedIndex="${this.currentPage}"
            .items="${this.__computeDisplayedPages(dropdownPages)}"
            .onChange="${this.__handlers.dropdownSelectPage}"
            ?open="${this.__menuOpened}"
            showFullText
          ></neb-menu>
        `;
  }

  render() {
    if (this.pageCount < 1) {
      return html``;
    }

    const { dropdownPages, activePages } = this.__computeActiveAndDropdown(
      this.pageCount,
      this.currentPage,
    );

    return html`
      <ul>
        <li>
          <button
            class="button highlight-text"
            id="${ELEMENTS.previousPageButton.id}"
            @click="${this.__handlers.previousPage}"
          >
            <neb-icon
              id="${ELEMENTS.leftDoubleArrow.id}"
              class="left-double-arrow icon-arrow"
              icon="neb:chevronDouble"
            ></neb-icon>
          </button>
        </li>

        ${activePages.map(
          pageNumber => html`
            <li>
              <button
                class="${this.__computeActivePageButtonClass(pageNumber)}"
                id="page_${this.__computePageLabel(pageNumber)}"
                page="${pageNumber}"
                @click="${this.__handlers.setCurrentPage}"
              >
                ${this.__computePageLabel(pageNumber)}
              </button>
            </li>
          `,
        )}

        <li>
          <div id="${ELEMENTS.dropdownSection.id}" class="dropdown-section">
            <div
              id="${ELEMENTS.dropdownContainer.id}"
              class="dropdown-container"
            >
              <button
                id="${ELEMENTS.dropdownButton.id}"
                @click="${this.__handlers.openMenu}"
                @blur="${this.__handlers.blur}"
                class="button highlight-text"
              >
                ...
              </button>
            </div>

            ${this.__renderDropdown(dropdownPages)}
          </div>
        </li>

        <li>
          <button
            class="button highlight-text"
            id="${ELEMENTS.nextPageButton.id}"
            @click="${this.__handlers.nextPage}"
          >
            <neb-icon
              id="${ELEMENTS.rightDoubleArrow.id}"
              class="right-double-arrow icon-arrow"
              icon="neb:chevronDouble"
            ></neb-icon>
          </button>
        </li>
      </ul>
    `;
  }
}

customElements.define('neb-pagination', NebPagination);
