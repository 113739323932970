import { getPracticeUser } from '../../../../../neb-api-client/src/permissions-api-client';
import { OTHER_ID_QUALIFIER } from '../../../../../neb-utils/claims';

export default async ({
  providerId,
  name,
  key,
  allowedIdentifiers,
  previousOptions,
  billingProviderOtherIdQualifier,
  billingProviderOtherId,
}) => {
  const uniqueOptions = new Set(previousOptions.map(({ label }) => label));

  const { identifiers } = await getPracticeUser(providerId);

  const options = identifiers.reduce((acc, { number, type: { value } }) => {
    const label = `${value} (${OTHER_ID_QUALIFIER[value]}) - ${number}`;

    if (uniqueOptions.has(label) || !allowedIdentifiers.includes(value)) {
      return acc;
    }

    uniqueOptions.add(label);

    return [
      ...acc,
      {
        label,
        value: {
          [`${key}OtherIdQualifier`]: value,
          [`${key}OtherId`]: number,
        },
        name,
      },
    ];
  }, []);

  const sortedOptions = [...previousOptions, ...options]
    .filter(
      ({ value: { providerOtherIdQualifier, providerOtherId } }) =>
        providerOtherIdQualifier !== billingProviderOtherIdQualifier ||
        providerOtherId !== billingProviderOtherId,
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return [
    ...sortedOptions,
    {
      label: 'None',
      value: {
        [`${key}OtherIdQualifier`]: '',
        [`${key}OtherId`]: '',
      },
      name,
    },
  ];
};
