import '../../neb-styles/neb-icons';
import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/controls/neb-button-action';
import './neb-treatment-phase-row';
import './neb-treatment-phase-form';

import { LitElement, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { renderNebButtonStyles } from '../../neb-styles/neb-button-styles';
import { renderNebTableStyles } from '../../neb-styles/neb-table-styles';
import { renderTypographyStyles } from '../../neb-styles/neb-typography';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';

import {
  CSS_FIXED_CELL_WIDTH,
  CSS_PHASE_CELL_MIN_WIDTH,
  CSS_LAST_COLUMN_FLEX,
  CSS_CELL_PADDING_SUMMARY,
  CSS_NUMBER_COLUMN_WIDTH,
} from './neb-treatment-plan-table-css-variables';

const dayConversion = {
  D: x => x,
  W: x => x * 7,
  M: x => x * 30,
  WM: x => x * 28,
};
export const ELEMENTS = {
  treatmentPhaseRows: {
    selector: 'neb-treatment-phase-row',
  },
  treatmentPhaseForms: {
    selector: 'neb-treatment-phase-form',
  },
  addButton: {
    id: 'add-phase',
  },
};

export const tooltipText = {
  APT_TOOLTIP_TEXT: 'Number of appointments this phase includes.',
  COM_TOOLTIP_TEXT: 'Number of appointments currently completed in this phase.',
  SCH_TOOLTIP_TEXT: 'Number of scheduled appointments remaining in this phase.',
  TBS_TOOLTIP_TEXT:
    'Number of appointments yet to be scheduled for this phase.',
};

class NebTreatmentPlanTable extends LitElement {
  static get properties() {
    return {
      __inValidPhases: {
        type: Object,
      },
      __treatmentTotals: {
        type: Object,
      },

      items: {
        type: Array,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      summary: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__treatmentTotals = {};
    this.__inValidPhases = {};

    this.items = [];

    this.__initHandlers();

    this.resetTotals();
  }

  __initHandlers() {
    this.__handlers = {
      cancelPhase: index => {
        this.items.splice(index, 1);
        this.requestUpdate();

        this.__sendEvent('neb-cancel-add-phase');
      },
      editPhase: (model, index) => {
        this.items[index] = model;
      },
      savePhase: async (model, index) => {
        const phase = { ...model };

        delete phase.id;
        phase.appointmentCount = this.__calculateAppointmentCount(phase);
        phase.tbsCount = phase.appointmentCount;

        this.items[index] = {
          ...phase,
        };

        this.__setTotals();

        this.requestUpdate();

        await this.updateComplete;

        this.__sendEvent('neb-save-add-phase');
      },
    };
  }

  __setTotals() {
    this.resetTotals();
    this.items.forEach(item => {
      this.__treatmentTotals.appointmentCount += item.appointmentCount;
      this.__treatmentTotals.encounterCount += item.encounterCount;
      this.__treatmentTotals.scheduledCount += item.scheduledCount;
      this.__treatmentTotals.tbsCount += item.tbsCount;
    });
  }

  __getPhaseText(phase, index) {
    if (!phase) return '';
    return `P${index + 1}: ${phase.occurrence} x ${phase.frequency}${
      phase.frequencyType
    } x ${phase.duration}${phase.durationType}`;
  }

  __sendEvent(eventName) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: this.items,
      }),
    );
  }

  __calculateAppointmentCount(phase) {
    const { frequencyType, durationType } = phase;

    const frequency = dayConversion[frequencyType](phase.frequency);

    let duration = dayConversion[durationType](phase.duration);

    if (durationType === 'M' && frequencyType === 'W') {
      duration = dayConversion[`${frequencyType}${durationType}`](
        phase.duration,
      );
    }

    return Math.ceil(phase.occurrence * (duration / frequency));
  }

  _isInvalid() {
    return Boolean(Object.keys(this.__inValidPhases).length);
  }

  __getInvalidType(startDate, endDate, index, isCompleteTP) {
    const item = this.items[index];

    if (!item.completedDate) return isCompleteTP ? 'Required' : null;
    let invalidType = this.__validatePrevPhaseDates(index, item.completedDate)
      ? null
      : 'beforePrevPhaseDate';

    if (!invalidType) {
      invalidType = this.__validateFuturePhaseDates(index, item.completedDate)
        ? null
        : 'afterFutureDate';
    }

    if (!invalidType && startDate) {
      invalidType = this.__compareDates(startDate, item.completedDate)
        ? null
        : 'beforeStartDate';
    }

    if (!invalidType && endDate) {
      invalidType = parseDate(item.completedDate).isAfter(endDate)
        ? 'afterEndDate'
        : null;
    }

    return invalidType;
  }

  __compareDates(comparableDate, comparedDate) {
    return parseDate(comparableDate).isBefore(parseDate(comparedDate));
  }

  __validatePrevPhaseDates(currentIndex, comparableDate) {
    for (let index = 0; index < currentIndex; index++) {
      const currentPhaseDate = this.items[index].completedDate;

      if (currentPhaseDate) {
        const isValid = this.__compareDates(currentPhaseDate, comparableDate);

        if (!isValid) {
          return false;
        }
      }
    }

    return true;
  }

  __validateFuturePhaseDates(currentIndex, comparableDate) {
    for (let index = currentIndex + 1; index < this.items.length; index++) {
      const currentPhaseDate = this.items[index].completedDate;

      if (currentPhaseDate) {
        const isValid = this.__compareDates(comparableDate, currentPhaseDate);

        if (!isValid) {
          return false;
        }
      }
    }

    return true;
  }

  deletePhase(index) {
    this.items.splice(index, 1);

    this.__setTotals();

    this.items.forEach(item => delete item.isNew);
    this.requestUpdate();
  }

  __addPhase() {
    const allItems = this.items;
    const blankPhase = {
      id: 'new',
      frequencyType: 'W',
      durationType: 'W',
      appointmentCount: 0,
      encounterCount: 0,
      scheduledCount: 0,
      tbsCount: 0,
      completedDate: null,
      isNew: true,
      occurrence: '',
      frequency: '',
      duration: '',
    };
    allItems.push(blankPhase);
    this.items = allItems.slice();

    this.__sendEvent('neb-add-phase');
  }

  async __updateField({ detail: { value } } = {}, index, fieldName) {
    this.items[index][fieldName] = value;
    await this.updateComplete;
  }

  validatePhases(startDate, endDate, isCompleteTP) {
    this.items.forEach((item, index) => {
      delete this.__inValidPhases[index];
      const invalidType = this.__getInvalidType(
        startDate,
        endDate,
        index,
        isCompleteTP,
      );

      if (invalidType) this.__inValidPhases[index] = invalidType;
      this.requestUpdate();
    });

    return !this._isInvalid();
  }

  getAppointmentCount() {
    return this.__treatmentTotals.appointmentCount;
  }

  getPhaseCount() {
    return this.items.length;
  }

  resetTotals() {
    this.__treatmentTotals = {
      appointmentCount: 0,
      encounterCount: 0,
      scheduledCount: 0,
      tbsCount: 0,
    };
  }

  hasEndDateConflict() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.__inValidPhases[i] === 'afterEndDate') return true;
    }

    return false;
  }

  hasUnsavedPhase() {
    let isInvalid;

    this.items.forEach((item, index) => {
      if (item.id === 'new') {
        const phaseForm = this.shadowRoot.getElementById(`phase${index}`);
        isInvalid = true;

        phaseForm.validate();
      }
    });

    return isInvalid;
  }

  resetInvalid() {
    this.__inValidPhases = {};
  }

  updated(changed) {
    if (changed.has('items')) {
      if (this.items.length) {
        this.__setTotals();
      }
    }
  }

  __renderTooltips(id, textId, text) {
    return !this.summary && !this.small
      ? html`
          <neb-tooltip id="${id}" class="tooltip" defaultAnchor="bottom"
            ><div id="${textId}" slot="tooltip">${text}</div>
          </neb-tooltip>
        `
      : '';
  }

  __renderMobileTooltips(id, textId) {
    return !this.summary && this.small
      ? html`
          <neb-tooltip id="${id}" class="tooltip" ?small="${this.small}"
            ><div id="${textId}" slot="tooltip">
              Apt - ${tooltipText.APT_TOOLTIP_TEXT} <br />
              Com - ${tooltipText.COM_TOOLTIP_TEXT} <br />
              Sch - ${tooltipText.SCH_TOOLTIP_TEXT} <br />
              TBS - ${tooltipText.TBS_TOOLTIP_TEXT}
            </div>
          </neb-tooltip>
        `
      : '';
  }

  render() {
    return html`
      ${renderNebTableStyles()} ${renderNebButtonStyles()}
      ${renderTypographyStyles()}
      <style>
        :host {
          --icon-height: ${CSS_SPACING};
          display: block;
        }

        .new-phase-item {
          height: 80px;
        }

        :host([small]) .new-phase-item {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .button {
          width: var(--icon-height);
          height: var(--icon-height);
          padding-top: 13px;
        }

        #addPhaseText {
          margin-left: 10px;
        }

        #totalContainer {
          display: flex;
        }

        .input {
          width: 35px;
          margin-top: -24.5px;
          height: 35px;
        }

        .dropdown {
          width: 130px;
        }

        #no-treatment-plan-msg {
          font-style: italic;
          padding-left: ${CSS_SPACING};
        }

        :host([sticky-total]) #no-treatment-plan-msg {
          padding-left: 10px;
        }

        .fixed-cell {
          width: ${CSS_FIXED_CELL_WIDTH};
        }

        #spacer {
          flex: ${CSS_LAST_COLUMN_FLEX};
        }

        .with-tooltip {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          white-space: nowrap;
        }

        .tooltip {
          font-weight: normal;
          margin-left: 8px;
          word-break: break-word;
        }

        .row-container {
          display: grid;
        }

        :host([small]) .tooltip {
          margin-left: 2px;
          width: 10px;
        }

        :host([small]) #spacer {
          display: none;
        }

        :host([sticky-total]) #totalContainer {
          position: sticky;
          position: -webkit-sticky;
          bottom: 0;
          background-color: white;
        }

        :host([summary]) .neb-table-header {
          padding-bottom: 0;
        }

        :host([summary]) .neb-table-cell {
          padding: ${CSS_CELL_PADDING_SUMMARY};
        }

        :host([small]) .neb-table-cell {
          padding: 5px;
        }

        :host([summary]) .header {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        :host([hide-total]) #totalContainer {
          display: none;
        }

        :host([summary]) .number {
          min-width: ${CSS_NUMBER_COLUMN_WIDTH};
        }

        :host([summary]) .neb-table-row .neb-table-cell:first-child {
          padding-left: 10px;
        }

        :host([summary]) .neb-table-row .neb-table-cell:last-child {
          padding-right: 10px;
        }

        [hidden] {
          display: none;
        }

        :host([small]) .neb-table-row {
          padding-left: ${CSS_SPACING};
        }

        :host([small]) .hide-when-small {
          display: none;
        }

        .button-add-phase {
          margin: ${CSS_SPACING};
        }

        .phase-text {
          min-width: ${CSS_PHASE_CELL_MIN_WIDTH};
        }
      </style>
      <div id="header" class="neb-table-row neb-table-header">
        <div id="phase" class="with-tooltip neb-table-cell phase-text header">
          Phase
          ${this.__renderMobileTooltips(
            'mobile-tooltip',
            'mobile-tooltip-text',
          )}
        </div>
        <div
          id="apt"
          class="with-tooltip neb-table-cell neb-table-small-width header number"
        >
          Apt
          ${this.__renderTooltips(
            'apt-tooltip',
            'apt-tooltip-text',
            tooltipText.APT_TOOLTIP_TEXT,
          )}
        </div>
        <div
          id="com"
          class="with-tooltip neb-table-cell neb-table-small-width header number"
        >
          Com
          ${this.__renderTooltips(
            'com-tooltip',
            'com-tooltip-text',
            tooltipText.COM_TOOLTIP_TEXT,
          )}
        </div>
        <div
          id="sch"
          class="with-tooltip neb-table-cell neb-table-small-width header number"
        >
          Sch
          ${this.__renderTooltips(
            'sch-tooltip',
            'sch-tooltip-text',
            tooltipText.SCH_TOOLTIP_TEXT,
          )}
        </div>
        <div
          id="tbs"
          class="with-tooltip neb-table-cell neb-table-small-width header number"
        >
          TBS
          ${this.__renderTooltips(
            'tbs-tooltip',
            'tbs-tooltip-text',
            tooltipText.TBS_TOOLTIP_TEXT,
          )}
        </div>
        ${this.small
          ? html`
              <div
                id="spacer-chevron"
                class="neb-table-cell neb-table-small-width"
              ></div>
            `
          : ''}
        <div
          id="completedDate"
          ?hidden="${this.summary}"
          class="neb-table-cell fixed-cell header hide-when-small"
        >
          Completed Date
        </div>
        <div
          id="spacer"
          ?hidden="${this.summary}"
          class="neb-table-cell neb-table-large-width"
        ></div>
      </div>

      <div id="rowContainer" class="row-container">
        ${this.items.length > 0
          ? html``
          : html`
              <p id="no-treatment-plan-msg" class="neb-font-regular-secondary">
                There is no active treatment plan for this patient.
              </p>
            `}
        ${repeat(this.items, (phase, index) => {
          if (phase.id === 'new') {
            return html`
              <neb-treatment-phase-form
                id="phase${index}"
                class="phaseItem new-phase-item"
                ?small="${this.small}"
                .index="${index}"
                .model="${phase}"
                .onCancel="${this.__handlers.cancelPhase}"
                .onEdit="${this.__handlers.editPhase}"
                .onSave="${this.__handlers.savePhase}"
              >
              </neb-treatment-phase-form>
            `;
          }
          return html`
            <neb-treatment-phase-row
              id="phase${index}"
              class="phaseItem"
              ?expandable="${this.small}"
              ?small="${this.small}"
              ?summary="${this.summary}"
              .index="${index}"
              .phase="${phase}"
              .onDeletePhase="${this.onDeletePhaseRow}"
              .onClearDate="${this.onClearDate}"
              .onClick="${this.onClickDate}"
              .invalidType="${this.__inValidPhases[index]}"
            ></neb-treatment-phase-row>
          `;
        })}
      </div>
      ${this.summary
        ? ''
        : html`
            <neb-button-action
              id="${ELEMENTS.addButton.id}"
              class="button-add-phase"
              label="Add a Phase"
              .onClick="${() => this.__addPhase()}"
            ></neb-button-action>
          `}

      <neb-treatment-phase-row
        id="totalContainer"
        class="phaseItem"
        ?summary="${this.summary}"
        ?small="${this.small}"
        .phase="${this.__treatmentTotals}"
        .onSelectDate="${this.onCompletedDate}"
        is-total
      ></neb-treatment-phase-row>
    `;
  }
}

customElements.define('neb-treatment-plan-table', NebTreatmentPlanTable);
