import './neb-charting-diagnosis-problem-list-controller';
import './neb-charting-diagnosis-prior-encounters-controller';
import { LitElement, html } from 'lit';

import { NebHotButtonDataController } from '../../../../../src/features/charting/shared/neb-hot-button-data-controller';
import { HOT_BUTTON_CATEGORY_TYPE } from '../../../../../src/utils/hot-buttons';

import {
  ICD10_TAB,
  PROBLEM_LIST_TAB,
  PRIOR_ENCOUNTERS_TAB,
  HOT_BUTTONS_TAB,
} from './neb-charting-diagnosis-detail-tabs';
import {
  BUTTON_TEXT_ADD_DIAGNOSIS,
  BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS,
} from './neb-charting-diagnosis-icd10-controller';

export const ELEMENTS = {
  diagnosisTabs: {
    id: 'diagnosis-tabs',
  },
  icd10Controller: {
    id: 'icd10-controller',
  },
  priorEncountersController: {
    id: 'prior-encounters-controller',
  },
  problemListController: {
    id: 'problem-list-controller',
  },
  hotButtons: {
    id: 'hot-buttons',
  },
};

class NebDiagnosisTabsController extends LitElement {
  static get properties() {
    return {
      providerId: String,
      selectedTab: String,
      savedCodes: Array,
      savedProblems: Array,
      encounter: Object,
      visible: Boolean,
      priorEncounterId: String,
      icd10SearchText: String,
      priorEncountersSearchText: String,
      problemListSearchText: String,
      searchText: String,
      layout: String,
      icd10Year: Number,
      initialIcd10Year: Number,
      __defaultTab: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState(skip = false) {
    this.savedCodes = [];
    this.savedProblems = [];
    this.encounter = null;
    this.priorEncounterId = '';
    this.icd10SearchText = '';
    this.priorEncountersSearchText = '';
    this.problemListSearchText = '';
    this.searchText = '';
    this.layout = '';
    this.providerId = '';
    this.icd10Year = null;

    if (!skip) {
      this.selectedTab = HOT_BUTTONS_TAB;
      this.__defaultTab = HOT_BUTTONS_TAB;
    }

    this.__hotButtonDataController = new NebHotButtonDataController(this, {
      categoryType: HOT_BUTTON_CATEGORY_TYPE.DIAGNOSES,
      onHasHotButtonItems: () => {
        this.selectedTab = HOT_BUTTONS_TAB;
        this.__defaultTab = HOT_BUTTONS_TAB;
      },
      onDoesNotHaveHotButtonItems: () => {
        this.selectedTab = ICD10_TAB;
        this.__defaultTab = ICD10_TAB;
      },
    });

    this.onAddDiagnosis = () => {};

    this.onAddAllDiagnosis = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      search: text => {
        this.searchText = text;

        switch (this.selectedTab) {
          case ICD10_TAB:
            this.icd10SearchText = text;
            break;

          case PROBLEM_LIST_TAB:
            this.problemListSearchText = text;
            break;

          case PRIOR_ENCOUNTERS_TAB:
            this.priorEncountersSearchText = text;
            break;

          case HOT_BUTTONS_TAB:
            break;

          default:
            console.error('TAB NOT HANDLED');
        }
      },
      tabSelected: selectedTab => {
        this.selectedTab = selectedTab;
      },
      icd10YearSelected: year => {
        if (this.icd10Year !== year) {
          this.icd10Year = year;
          this.__clearSearchText();
        }
      },
      addDiagnosis: icd10Info =>
        this.onAddDiagnosis({ ...icd10Info, isNew: true }),
      addDiagnosisWithoutUpdatingDescription: icd10Info =>
        this.onAddDiagnosis({ ...icd10Info, isNew: true }, false),
      applyHotButton: ({ diagnosisCode: code, shortDescription }) =>
        this.onAddDiagnosis({ code, shortDescription, isNew: true }),
      addAllDiagnosis: icd10Infos =>
        this.onAddAllDiagnosis(
          icd10Infos.map(code => ({
            ...code,
            isNew: true,
          })),
        ),
      clear: () => this.onClear(),
      setPriorEncounterId: encounterId => {
        this.priorEncounterId = encounterId;
      },
      updateHotButtonModel: x => {
        this.__hotButtonDataController.updateHotButtonModel(x);
      },
    };
  }

  firstUpdated() {
    this.__elements = {
      tabs: this.shadowRoot.getElementById(ELEMENTS.diagnosisTabs.id),
      icd10Controller: this.shadowRoot.getElementById(
        ELEMENTS.icd10Controller.id,
      ),
      priorEncountersController: this.shadowRoot.getElementById(
        ELEMENTS.priorEncountersController.id,
      ),
      problemListController: this.shadowRoot.getElementById(
        ELEMENTS.problemListController.id,
      ),
    };
  }

  clear() {
    this.__clearSearchText();
    this.__initState(true);

    this.selectedTab = this.__defaultTab;
    this.icd10Year = this.initialIcd10Year;
    this.__elements.icd10Controller.reset();
    this.__elements.problemListController.reset();
    this.__elements.priorEncountersController.reset();
  }

  updated(changedProps) {
    if (changedProps.has('visible') && changedProps.get('visible')) {
      this.clear();
    }
  }

  __clearSearchText() {
    this.searchText = '';

    if (this.selectedTab !== HOT_BUTTONS_TAB) {
      this.__setSearchText(this.searchText);
    }

    this.currentPageIndex = 0;
    this.pageCount = 0;
  }

  __setSearchText(value) {
    this.shadowRoot
      .querySelector('neb-charting-diagnosis-detail-tabs')
      .shadowRoot.querySelector('neb-search-bar')
      .setSearchInputValue(value);
  }

  getSavedCodeIDs() {
    return this.savedCodes.map(({ code }) => code);
  }

  __resolveSearchText() {
    switch (this.selectedTab) {
      case ICD10_TAB:
        return this.icd10SearchText;

      case PROBLEM_LIST_TAB:
        return this.problemListSearchText;

      case PRIOR_ENCOUNTERS_TAB:
        return this.priorEncountersSearchText;

      case HOT_BUTTONS_TAB:
        return '';

      default:
        console.error('TAB NOT HANDLED');
        return undefined;
    }
  }

  __renderHotButtons() {
    return this.selectedTab === HOT_BUTTONS_TAB
      ? html`
          <neb-apply-hot-buttons
            id="${ELEMENTS.hotButtons.id}"
            .model="${this.__hotButtonDataController.hotButtonModel}"
            .existingItems="${this.savedCodes}"
            .onApplyHotButton="${this.__handlers.applyHotButton}"
            .onSetModel="${this.__handlers.updateHotButtonModel}"
          ></neb-apply-hot-buttons>
        `
      : '';
  }

  render() {
    return html`
      <neb-charting-diagnosis-detail-tabs
        id="${ELEMENTS.diagnosisTabs.id}"
        .layout="${this.layout}"
        .searchText="${this.__resolveSearchText()}"
        .onSearch="${this.__handlers.search}"
        .onTabSelected="${this.__handlers.tabSelected}"
        .onIcd10YearSelected="${this.__handlers.icd10YearSelected}"
        .selectedTab="${this.selectedTab}"
        .icd10Year="${this.icd10Year}"
        .visible="${this.visible}"
      ></neb-charting-diagnosis-detail-tabs>

      <neb-charting-diagnosis-icd10-controller
        id="${ELEMENTS.icd10Controller.id}"
        .savedCodes="${this.savedCodes}"
        .searchText="${this.icd10SearchText}"
        .onAddDiagnosis="${this.__handlers
          .addDiagnosisWithoutUpdatingDescription}"
        .buttonText="${BUTTON_TEXT_ADD_DIAGNOSIS}"
        .buttonToggledText="${BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS}"
        .visible="${this.selectedTab === ICD10_TAB}"
        .icd10Year="${this.icd10Year}"
      ></neb-charting-diagnosis-icd10-controller>

      <neb-charting-diagnosis-problem-list-controller
        id="${ELEMENTS.problemListController.id}"
        .searchText="${this.problemListSearchText}"
        .savedCodes="${this.savedCodes}"
        .savedProblems="${this.savedProblems}"
        .onAddDiagnosis="${this.__handlers.addDiagnosis}"
        .visible="${this.selectedTab === PROBLEM_LIST_TAB}"
      ></neb-charting-diagnosis-problem-list-controller>

      <neb-charting-diagnosis-prior-encounters-controller
        id="${ELEMENTS.priorEncountersController.id}"
        .codes="${this.getSavedCodeIDs()}"
        .encounter="${this.encounter}"
        .onAddCode="${this.__handlers.addDiagnosis}"
        .onAddAllCodes="${this.__handlers.addAllDiagnosis}"
        .searchText="${this.priorEncountersSearchText}"
        .onSetPriorEncounterId="${this.__handlers.setPriorEncounterId}"
        .visible="${this.selectedTab === PRIOR_ENCOUNTERS_TAB}"
      ></neb-charting-diagnosis-prior-encounters-controller>

      ${this.__renderHotButtons()}
    `;
  }
}

customElements.define(
  'neb-diagnosis-tabs-controller',
  NebDiagnosisTabsController,
);
