import LRU from 'lru-cache';

import {
  dataURLtoFile,
  readDataUrlFromBlob,
} from '../../neb-utils/blobProcessor';

import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';

const FIVE_MINS = 1000 * 60 * 5;
const smallImgCache = new LRU({
  max: 128,
  maxAge: FIVE_MINS,
});
/** Exposed for testing. */
export const apiClient = new DeprecatedApiClient({ microservice: 'image' });

export function __resetCache() {
  smallImgCache.reset();
}

export const saveImage = async (path, src, addToBody = {}) => {
  const data = new FormData();
  const image = await dataURLtoFile(src);
  data.append('file', image);
  Object.entries(addToBody).forEach(([key, value]) => {
    data.append(key, value);
  });

  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path,
    body: data,
    headers: {},
  });
  smallImgCache.del(addToBody.parentId);
  return response;
};

export const deleteImage = async (path, queryParams = {}) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path,
    queryParams,
    responseType: RESPONSE_TYPE.RAW,
  });
  smallImgCache.del(queryParams.parentId);
  return response;
};

export const getImage = async (params = {}) => {
  let result;

  try {
    const { id, ...queryParams } = params;

    if (id) {
      result = await smallImgCache.get(id);
      if (result) return result;
    }

    const response = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: 'image',
      queryParams,
      responseType: RESPONSE_TYPE.RAW,
    });
    result = readDataUrlFromBlob(await response.blob());
    if (id) smallImgCache.set(id, result);
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};
