import { openPopup } from '@neb/popup';

import { getMatchCharges } from '../../../../../packages/neb-api-client/src/billing/era/era-line-item-charges-api-client';
import { fetchRollups } from '../../../../../packages/neb-api-client/src/ledger/charges';
import {
  openError,
  openSuccess,
} from '../../../../../packages/neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { associateCharges as associateChargesToEOB } from '../../../../api-clients/eob';
import { associateCharges as associateChargesToERA } from '../../../../api-clients/era';
import { CHARGES_ORIGIN, EDIT_MODE } from '../../../../utils/neb-charges-util';
import {
  ASSOCIATE_CHARGES_ERROR,
  ASSOCIATE_CHARGES_SUCCESSFULLY,
  SELECT_CHARGE_TO_MATCH,
  UNABLE_TO_MATCH_CHARGE,
} from '../../../../utils/user-message';
import { SELECT_CHARGES_TO_ASSOCIATE } from '../neb-era-eob-charges-controller';

export const isMatchEditMode = editMode => editMode === EDIT_MODE.MATCH;

const isERA = chargesType => chargesType === CHARGES_ORIGIN.ERA;

const getPopupContent = editMode =>
  isMatchEditMode(editMode)
    ? {
        title: UNABLE_TO_MATCH_CHARGE,
        message: SELECT_CHARGE_TO_MATCH,
      }
    : SELECT_CHARGES_TO_ASSOCIATE;

export const openNoChargesSelectedPopup = editMode =>
  openPopup(POPUP_RENDER_KEYS.MESSAGE, getPopupContent(editMode));

export const getFetchApi = ({ query, editMode, mode, model, chargesType }) =>
  isMatchEditMode(editMode)
    ? getMatchCharges(query)
    : fetchRollups(mode, '', {
        ...query,
        ...(isERA(chargesType)
          ? { eraToIgnoreCharges: model.eClaimERAReportId }
          : { eobToIgnoreCharges: model.reportId }),
      });

const getApiAndPayload = ({ chargesType, checkedLineItemIds, model }) => {
  const isEra = isERA(chargesType);
  const commonPayload = {
    version: 2,
    lineItemIds: checkedLineItemIds,
  };

  const payload = isEra
    ? {
        eraId: model.reportId,
        reportId: model.eClaimERAReportId,
      }
    : { eobId: model.reportId };

  const api = isEra ? associateChargesToERA : associateChargesToEOB;

  return { api, payload: { ...commonPayload, ...payload } };
};

export const associateCharges = async ({
  fetch,
  chargesType,
  checkedLineItemIds,
  model,
}) => {
  try {
    const { api, payload } = getApiAndPayload({
      chargesType,
      checkedLineItemIds,
      model,
    });
    await api(payload);

    fetch();
    store.dispatch(
      openSuccess(ASSOCIATE_CHARGES_SUCCESSFULLY(chargesType.toUpperCase())),
    );
  } catch (error) {
    store.dispatch(
      openError(ASSOCIATE_CHARGES_ERROR(chargesType.toUpperCase())),
    );
  }
};
