import '@polymer/paper-dialog/paper-dialog';
import './neb-banner-message';

import { LitElement, html, css } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

const ID_BANNER_MESSAGE_PREFIX = 'banner-message';
export const ELEMENTS = {
  bannerMessage: {
    selector: '.banner-message',
    tag: 'neb-banner-message',
  },
};
class NebBanner extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
      },
      messages: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__timers = {};
    this.layout = '';
    this.messages = [];

    this.onClick = _bannerMessageEl => {};

    this.onCloseMessage = _messageId => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: bannerMessageEl => this.onClick(bannerMessageEl),
      close: messageId => this.__closeMessage(messageId),
      pauseTimer: e => this.__pauseTimer(e),
      resumeTimer: e => this.__resumeTimer(e),
    };
  }

  updated(changed) {
    if (changed.has('messages')) {
      if (this.messages && this.messages.length > 0) {
        this.__openMessageDialog();
      } else {
        this.__closeMessageDialog();
      }
    }
  }

  __getSize(large) {
    if (this.layout === 'small') return 'small';
    if (large) return 'large';
    return 'normal';
  }

  __getBannerMessage(messageId) {
    return this.shadowRoot.getElementById(
      `${ID_BANNER_MESSAGE_PREFIX}-${messageId}`,
    );
  }

  __openMessageDialog() {
    const dialog = this.shadowRoot.getElementById('message-dialog');
    dialog.positionTarget = document.body;
    dialog.open();
    this.messages.forEach(message => {
      this.__getBannerMessage(message.messageId).show();

      if (message.delay && !this.__timers[message.messageId]) {
        this.__timers[message.messageId] = {
          remaining: message.delay,
        };

        this.__startTimer(message.messageId, message.delay);
      }
    });
  }

  __startTimer(messageId, delay) {
    let timer = this.__timers[messageId];

    if (!timer) timer = {};
    timer.start = new Date();
    timer.timerId = window.setTimeout(
      this.__handlers.close.bind(this, messageId),
      delay,
    );
  }

  __pauseTimer(e) {
    const bannerMessageEl = e.target;

    if (bannerMessageEl.delay) {
      const timer = this.__timers[bannerMessageEl.messageId];

      if (timer) {
        window.clearTimeout(timer.timerId);
        timer.remaining -= new Date() - timer.start;
      }
    }
  }

  __resumeTimer(e) {
    const bannerMessageEl = e.target;

    if (bannerMessageEl.delay) {
      const timer = this.__timers[bannerMessageEl.messageId];

      this.__startTimer(
        bannerMessageEl.messageId,
        timer ? timer.remaining : bannerMessageEl.delay,
      );
    }
  }

  async __closeMessage(messageId) {
    delete this.__timers[messageId];

    const bannerMessage = this.__getBannerMessage(messageId);

    if (bannerMessage) {
      await bannerMessage.hide();
    }

    this.onCloseMessage(messageId);
  }

  __closeMessageDialog() {
    const dialog = this.shadowRoot.getElementById('message-dialog');
    dialog.close();
  }

  static get styles() {
    return css`
      .banner-message,
      .message-dialog {
        margin: 0;
        padding: 0;

        z-index: 110;
      }
    `;
  }

  render() {
    return html`
      <paper-dialog
        id="message-dialog"
        class="message-dialog"
        vertical-align="top"
        no-cancel-on-outside-click
      >
        ${repeat(
          this.messages,
          (message, index) => html`
            <neb-banner-message
              id="${ID_BANNER_MESSAGE_PREFIX}-${message.messageId}"
              class="banner-message"
              banner-index="index${index}"
              .delay="${message.delay}"
              .index="${index}"
              .layout="${this.layout}"
              .link="${message.link}"
              .linkMessage="${message.linkMessage}"
              .message="${message.message}"
              .messageId="${message.messageId}"
              .messageType="${message.messageType}"
              .onClick="${this.__handlers.click}"
              .onClose="${this.__handlers.close}"
              .onBannerClick="${message.onBannerClick}"
              .patientId="${message.patientId}"
              @mouseenter="${this.__handlers.pauseTimer}"
              @mouseleave="${this.__handlers.resumeTimer}"
            ></neb-banner-message>
          `,
        )}
      </paper-dialog>
    `;
  }
}
customElements.define('neb-banner', NebBanner);
