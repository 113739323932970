import { html } from 'lit';

import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import '../controllers/neb-encounter-info-controller';

export const ELEMENTS = {
  encounterController: {
    id: 'controller-encounter',
  },
};

export class NebOverlayEncounterInfo extends Overlay {
  static get properties() {
    return {
      model: Object,
    };
  }

  renderContent() {
    return html`
      <neb-encounter-info-controller
        id="${ELEMENTS.encounterController.id}"
        .layout="${this.layout}"
        .model="${this.model}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-encounter-info-controller>
    `;
  }
}

customElements.define('neb-overlay-encounter-info', NebOverlayEncounterInfo);
