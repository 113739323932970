import '../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../packages/neb-lit-components/src/components/neb-header';
import '../misc/neb-card-switch';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { ADD_ONS } from '../../utils/add-ons';
import {
  POPUP_MESSAGE_DISABLE_CT_VERIFY,
  POPUP_MESSAGE_DISABLE_CT_MAXCLEAR,
} from '../../utils/user-message';

import { EMPTY_TENANT_MODEL, TIERS } from './utils/form-utils';

const {
  CT_ENGAGE,
  CT_INFORMS,
  CT_REMIND,
  CT_VERIFY,
  CT_INSIGHTS,
  CT_MAXCLEAR,
  CT_PROCLEAR_TRIZETTO,
  CT_PROCLEAR_WAYSTAR,
  CT_FLEXCLEAR,
  CT_FIFO,
  CT_REV_ACCEL,
} = ADD_ONS;

const DEACTIVATE_FOR_CORE = [
  CT_MAXCLEAR,
  CT_PROCLEAR_TRIZETTO,
  CT_PROCLEAR_WAYSTAR,
  CT_FLEXCLEAR,
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  advancedRadio: { id: 'radio-advanced' },
  coreRadio: { id: 'radio-core' },
  verifyCardSwitch: { id: 'card-switch-verify' },
  engageCardSwitch: { id: 'card-switch-engage' },
  informsCardSwitch: { id: 'card-switch-informs' },
  remindCardSwitch: { id: 'card-switch-remind' },
  maxclearCardSwitch: { id: 'card-switch-maxclear' },
  trizettoProclearCardSwitch: { id: 'card-switch-proclear' },
  waystarProclearCardSwitch: { id: 'card-switch-proclear-waystar' },
  flexclearCardSwitch: { id: 'card-switch-flexclear' },
  insightsCardSwitch: { id: 'card-switch-insights' },
  fifoCardSwitch: { id: 'card-switch-fifo' },
  revAccelCardSwitch: { id: 'card-switch-rev-accel' },
};

export default class NebPracticePackageForm extends Form {
  static get properties() {
    return {
      __partnerships: { type: Array },
      __hasFeatureOrBetaFlagBYOC: { type: Boolean },
    };
  }

  static get styles() {
    return [
      ...super.styles,
      css`
        .card-switch-wrapper {
          white-space: nowrap;
        }

        .card-switch {
          height: 100%;
          display: flex;
        }
      `,
    ];
  }

  static createModel() {
    return EMPTY_TENANT_MODEL;
  }

  createSelectors() {
    return {
      format: v => ({
        tier: v.tier,
        [CT_ENGAGE]: v.addOns.includes(CT_ENGAGE),
        [CT_INFORMS]: v.addOns.includes(CT_INFORMS),
        [CT_REMIND]: v.addOns.includes(CT_REMIND),
        [CT_VERIFY]: v.addOns.includes(CT_VERIFY),
        [CT_MAXCLEAR]: v.addOns.includes(CT_MAXCLEAR),
        [CT_PROCLEAR_TRIZETTO]: v.addOns.includes(CT_PROCLEAR_TRIZETTO),
        [CT_PROCLEAR_WAYSTAR]: v.addOns.includes(CT_PROCLEAR_WAYSTAR),
        [CT_FLEXCLEAR]: v.addOns.includes(CT_FLEXCLEAR),
        [CT_INSIGHTS]: v.addOns.includes(CT_INSIGHTS),
        [CT_FIFO]: v.addOns.includes(CT_FIFO),
        [CT_REV_ACCEL]: v.addOns.includes(CT_REV_ACCEL),
        tenantId: v.tenantId,
      }),

      // eslint-disable-next-line complexity
      unformat: v => ({
        tier: v.tier,
        addOns: [
          v[CT_ENGAGE] && CT_ENGAGE,
          v[CT_INFORMS] && CT_INFORMS,
          v[CT_REMIND] && CT_REMIND,
          v[CT_VERIFY] && CT_VERIFY,
          v[CT_MAXCLEAR] && CT_MAXCLEAR,
          v[CT_PROCLEAR_TRIZETTO] && CT_PROCLEAR_TRIZETTO,
          v[CT_PROCLEAR_WAYSTAR] && CT_PROCLEAR_WAYSTAR,
          v[CT_FLEXCLEAR] && CT_FLEXCLEAR,
          v[CT_INSIGHTS] && CT_INSIGHTS,
          v[CT_FIFO] && CT_FIFO,
          v[CT_REV_ACCEL] && CT_REV_ACCEL,
        ].filter(item => item),
        tenantId: v.tenantId,
      }),
    };
  }

  initState() {
    super.initState();

    this.__partnerships = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      // eslint-disable-next-line complexity
      changeAddOn: async ({ name, value }) => {
        if (!value && name === ADD_ONS.CT_VERIFY) {
          const confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Disable CT Verify',
            message: POPUP_MESSAGE_DISABLE_CT_VERIFY(),
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          });

          if (!confirmed) {
            return;
          }
        }

        if (!value && name === ADD_ONS.CT_MAXCLEAR) {
          const confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Disable CT MaxClear',
            message: POPUP_MESSAGE_DISABLE_CT_MAXCLEAR(),
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          });

          if (!confirmed) {
            return;
          }
        }

        this.formService.apply(name, value);

        if (name === 'tier' && value === 'Core') {
          DEACTIVATE_FOR_CORE.map(addOn =>
            this.formService.apply(addOn, false),
          );
        }
      },

      enableFlexClear: () => {
        this.formService.apply([CT_FLEXCLEAR], true);
      },
    };
  }

  __renderSwitchCardCTInforms() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.informsCardSwitch.id}"
        class="card-switch"
        name="${CT_INFORMS}"
        icon="neb:informs"
        title="CT InForms (Doctible)"
        disabled
        .active="${this.state[CT_INFORMS]}"
      >
      </neb-card-switch>
    `;
  }

  __renderSwitchCardCTVerify() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.verifyCardSwitch.id}"
        class="card-switch"
        name="${CT_VERIFY}"
        icon="neb:selfCheckIn"
        title="CT Verify"
        ?disabled="${this.state.tier === TIERS.CORE}"
        .active="${this.state[CT_VERIFY] && this.state.tier === TIERS.ADVANCED}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>
    `;
  }

  __renderSwitchCardBYOC() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.maxclearCardSwitch.id}"
        class="card-switch"
        name="${CT_MAXCLEAR}"
        icon="neb:electronicClaims"
        title="CT MaxClear"
        ?disabled="${this.state.tier === TIERS.CORE}"
        .active="${this.state[CT_MAXCLEAR]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>

      <neb-card-switch
        id="${ELEMENTS.trizettoProclearCardSwitch.id}"
        class="card-switch"
        name="${CT_PROCLEAR_TRIZETTO}"
        icon="neb:electronicClaims"
        title="CT ProClear - TriZetto"
        ?disabled="${this.state.tier === TIERS.CORE}"
        .active="${this.state[CT_PROCLEAR_TRIZETTO]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>

      <neb-card-switch
        id="${ELEMENTS.waystarProclearCardSwitch.id}"
        class="card-switch"
        name="${CT_PROCLEAR_WAYSTAR}"
        icon="neb:electronicClaims"
        title="CT ProClear - Waystar"
        ?disabled="${this.state.tier === TIERS.CORE}"
        .active="${this.state[CT_PROCLEAR_WAYSTAR]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>

      <neb-card-switch
        id="${ELEMENTS.flexclearCardSwitch.id}"
        class="card-switch"
        name="${CT_FLEXCLEAR}"
        icon="neb:electronicClaims"
        title="CT FlexClear"
        ?disabled="${this.state.tier === TIERS.CORE}"
        .active="${this.state[CT_FLEXCLEAR]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>
    `;
  }

  __renderSwitchCardCTInsights() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.insightsCardSwitch.id}"
        class="card-switch"
        name="${CT_INSIGHTS}"
        icon="neb:insights"
        title="CT Insights"
        .active="${this.state[CT_INSIGHTS]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>
    `;
  }

  __renderSwitchCardCTFifo() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.fifoCardSwitch.id}"
        class="card-switch"
        name="${CT_FIFO}"
        icon="neb:flashAuto"
        title="FIFO"
        .active="${this.state[CT_FIFO]}"
        .onChange="${this.handlers.changeAddOn}"
      >
      </neb-card-switch>
    `;
  }

  __renderSwitchCardCTRevAccel() {
    return html`
      <neb-card-switch
        id="${ELEMENTS.revAccelCardSwitch.id}"
        class="card-switch"
        name="${CT_REV_ACCEL}"
        icon="neb:revAccel"
        title="RevAccel"
        .active="${this.state[CT_REV_ACCEL]}"
        disabled
      >
      </neb-card-switch>
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-lean">
        <neb-header label="ChiroTouch Package"></neb-header>

        <div class="grid grid-2">
          <neb-radio-button
            id="${ELEMENTS.coreRadio.id}"
            name="tier"
            label="ChiroTouch Cloud - Core"
            .value="${TIERS.CORE}"
            .checked="${this.state.tier === TIERS.CORE}"
            .onChange="${this.handlers.changeAddOn}"
          ></neb-radio-button>

          <neb-radio-button
            id="${ELEMENTS.advancedRadio.id}"
            name="tier"
            label="ChiroTouch Cloud - Advanced"
            .value="${TIERS.ADVANCED}"
            .checked="${this.state.tier === TIERS.ADVANCED}"
            .onChange="${this.handlers.changeAddOn}"
            .onSelect="${this.handlers.enableFlexClear}"
          ></neb-radio-button>
        </div>

        <neb-header label="Ancillary Add-ons"></neb-header>

        <div class="grid grid-2 card-switch-wrapper">
          <neb-card-switch
            id="${ELEMENTS.engageCardSwitch.id}"
            class="card-switch"
            name="${CT_ENGAGE}"
            icon="neb:engage"
            title="CT Engage (Doctible)"
            disabled
            .active="${this.state[CT_ENGAGE]}"
          >
          </neb-card-switch>

          <neb-card-switch
            id="${ELEMENTS.remindCardSwitch.id}"
            class="card-switch"
            name="${CT_REMIND}"
            icon="neb:engage"
            title="CT Remind (Doctible)"
            disabled
            .active="${this.state[CT_REMIND]}"
          >
          </neb-card-switch>
          ${this.__renderSwitchCardCTInforms()}
          ${this.__renderSwitchCardCTVerify()} ${this.__renderSwitchCardBYOC()}
          ${this.__renderSwitchCardCTInsights()}
          ${this.__renderSwitchCardCTFifo()}
          ${this.__renderSwitchCardCTRevAccel()}
        </div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-form-practice-package',
  NebPracticePackageForm,
);
