import '../neb-tooltip';
import '../controls/neb-switch';
import '../inputs/neb-textfield';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../../neb-utils/constants';
import { required } from '../../../../neb-utils/validators';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';
import { MERCHANT_PROVIDER_OPTIONS } from './utils/index';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  nameField: {
    id: 'card-reader-name',
  },
  ipAddressField: {
    id: 'ip-address',
  },
  ipAddressTooltip: {
    id: 'ip-address-tooltip',
  },
  hostNameField: {
    id: 'host-name',
  },
  hostNameTooltip: {
    id: 'host-name-tooltip',
  },
  activeSwitch: {
    id: 'active',
  },
  merchantProviderSelect: {
    id: 'merchant-provider-select',
  },
  hsnField: {
    id: 'hsn',
  },
  hsnOwnerSelect: {
    id: 'hsn-owner-select',
  },
};
const OPEN_EDGE_TOOLTIP_TEXT =
  'This is a credential used to identify your card reader. Global Payments (OpenEdge) will provide you with your card reader credentials, and will help configure them for your practice. Contact Global Payments (OpenEdge) to set up your card reader.';
const DUPLICATE_MSG = {
  title: 'Duplicate Card Reader Name',
  message:
    'There is an existing card reader for this practice with the same name. Please change the name to save this card reader.',
};
export class NebFormCardReaders extends NebForm {
  static get properties() {
    return {
      cardReaders: Array,
      merchantAccounts: Array,
    };
  }

  static createModel() {
    return {
      name: '',
      ipAddress: '',
      hostName: '',
      active: true,
      merchantAccounts: [],
      merchantProvider: null,
      hsn: '',
      hsnOwnerMerchantAccountId: null,
    };
  }

  initState() {
    super.initState();
    this.cardReaders = [];
    this.merchantAccounts = [];
    this.confirmLabel = 'Save Card Reader';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeMerchantProvider: e => {
        if (
          e.event === 'select' &&
          e.value.data !== this.state.merchantProvider.data
        ) {
          this.__clearFields();
          this.formService.apply('merchantProvider', e.value);
        }
      },
      save: async () => {
        if (this.formService.validate()) {
          const model = this.formService.build();
          this.onSave(model);
        } else {
          if (this.errors.name === 'Duplicate') {
            await openPopup('message', DUPLICATE_MSG);
          }

          this.__blurActionBar();
        }
      },
    };
  }

  firstUpdated() {
    this.__sideLoading = false;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__setDefaultMerchantProvider();
      this.build();
      this.__saving = false;
    }

    super.update(changedProps);
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [required(), this.__duplicate()],
        },
        ipAddress: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        hostName: [this.__required(MERCHANT_PROVIDERS.GLOBAL_PAYMENTS)],
        merchantProvider: {
          clipPristine: true,
          format: v =>
            MERCHANT_PROVIDER_OPTIONS.find(item => item.data === v) ||
            MERCHANT_PROVIDER_OPTIONS[1],
          unformat: v => v.data,
        },
        hsn: [this.__required(MERCHANT_PROVIDERS.FISERV)],
        hsnOwnerMerchantAccountId: {
          clipPristine: true,
          format: v =>
            this.__formatFiservMerchantAccounts().find(
              item => item.data.id === v,
            ) || null,
          unformat: v => v?.data.id || null,
          validators: [this.__required(MERCHANT_PROVIDERS.FISERV)],
        },
      },
    };
  }

  __setDefaultMerchantProvider() {
    if (this.model.merchantProvider === null) {
      if (this.model.id) {
        this.model.merchantProvider = MERCHANT_PROVIDERS.GLOBAL_PAYMENTS;
      } else {
        this.model.merchantProvider = MERCHANT_PROVIDERS.FISERV;
      }
    }
  }

  __clearFields() {
    this.formService.apply('ipAddress', '');
    this.formService.apply('hostName', '');
    this.formService.apply('hsn', '');
    this.formService.apply('hsnOwnerMerchantAccountId', null);
  }

  __merchantProviderGlobalPayments() {
    return (
      this.state.merchantProvider.data === MERCHANT_PROVIDERS.GLOBAL_PAYMENTS
    );
  }

  __blurActionBar() {
    this.shadowRoot.getElementById(ELEMENTS.actionBar.id).blur();
  }

  __duplicate(error = 'Duplicate') {
    return {
      error,
      validate: () =>
        this.cardReaders.filter(
          ({ id, name }) => id !== this.state.id && name === this.state.name,
        ).length === 0,
    };
  }

  __required(forMerchantProvider) {
    return {
      error: 'Required',
      validate: (v, _, state) =>
        state.merchantProvider.data === forMerchantProvider ? v : true,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
        }

        .layout {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: min-content;
          padding: ${CSS_SPACING};
        }

        .span-tooltip {
          display: grid;
          grid-gap: 8px;
          grid-template-columns: 1fr auto;
          align-items: center;
        }

        .item-span {
          grid-column: span 2;
        }
      `,
    ];
  }

  __renderMerchantProvider() {
    return html`
      <neb-select
        id="${ELEMENTS.merchantProviderSelect.id}"
        name="merchantProvider"
        label="Merchant Provider"
        helper=" "
        .items="${MERCHANT_PROVIDER_OPTIONS}"
        .value="${this.state.merchantProvider}"
        .onChange="${this.handlers.changeMerchantProvider}"
        ?disabled="${this.model.id}"
      ></neb-select>
    `;
  }

  __renderGlobalPaymentsFields() {
    return html`
      <div class="span-tooltip">
        <neb-textfield
          id="${ELEMENTS.ipAddressField.id}"
          name="ipAddress"
          label="IP Address"
          helper="Required"
          maxLength="50"
          .value="${this.state.ipAddress}"
          .error="${this.errors.ipAddress}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip
          id="${ELEMENTS.ipAddressTooltip.id}"
          defaultAnchor="bottom"
        >
          <div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>
      <div class="span-tooltip">
        <neb-textfield
          id="${ELEMENTS.hostNameField.id}"
          name="hostName"
          label="Host Name"
          helper="Required"
          maxLength="50"
          .value="${this.state.hostName}"
          .error="${this.errors.hostName}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
        <neb-tooltip id="${ELEMENTS.hostNameTooltip.id}" defaultAnchor="left">
          <div slot="tooltip">${OPEN_EDGE_TOOLTIP_TEXT}</div>
        </neb-tooltip>
      </div>
    `;
  }

  __formatFiservMerchantAccounts() {
    return this.merchantAccounts.reduce((acc, merchantAccount) => {
      if (merchantAccount.merchantProvider === MERCHANT_PROVIDERS.FISERV) {
        delete merchantAccount.cardReaders;
        acc.push({
          data: { ...merchantAccount },
          label: merchantAccount.name,
        });
      }

      return acc;
    }, []);
  }

  __renderFiservFields() {
    return html`
      <neb-textfield
        id="${ELEMENTS.hsnField.id}"
        name="hsn"
        label="Hardware Serial Number (HSN)"
        helper="Required"
        maxLength="32"
        .value="${this.state.hsn}"
        .error="${this.errors.hsn}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.hsnOwnerSelect.id}"
        name="hsnOwnerMerchantAccountId"
        label="HSN Owner"
        helper="Required"
        .items="${this.__formatFiservMerchantAccounts()}"
        .value="${this.state.hsnOwnerMerchantAccountId}"
        .error="${this.errors.hsnOwnerMerchantAccountId}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  renderActionBar() {
    return this.__dirty || this.model.id
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderMerchantProvider()}

      <neb-textfield
        id="${ELEMENTS.nameField.id}"
        name="name"
        label="Card Reader Name"
        helper="Required"
        maxLength="50"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      ${this.__merchantProviderGlobalPayments()
        ? this.__renderGlobalPaymentsFields()
        : this.__renderFiservFields()}

      <neb-switch
        id="${ELEMENTS.activeSwitch.id}"
        label="Active"
        name="active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }
}
customElements.define('neb-form-card-readers', NebFormCardReaders);
