import { downloadURLtoFile } from '../../../neb-utils/blobProcessor';

const __getFileName = headers => {
  const disposition = headers.get('Content-Disposition');
  const regex = /filename="?([A-Za-z _0-9]*.csv)"?/g;
  const [[, filename]] = [...disposition.matchAll(regex)];
  return filename;
};

export default async response => {
  const fileName = __getFileName(response.headers);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  return downloadURLtoFile(url, fileName);
};
