import { PAYMENT_RESPONSIBILITY_LEVEL_CODE } from '../../../../../neb-utils/claims';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  currency,
  phoneNumber,
  createDefaultModifiers,
} from '../../../../../neb-utils/selectors';
import { required } from '../../../../../neb-utils/validators';
import { SCRUB_CODE } from '../../../utils/claim-validation';

const EMPTY_ADDITIONAL_CLAIM_INFO = {
  enabled: true,
  sortOrder: 0,
  readOnly: true,
  code: '',
  codeGroup: '',
  description: '',
  note: '',
};

export const CLAIM_FORM_ERROR_TYPES = {
  REQUIRED: 'Required',
  RECOMMENDED: 'Recommended',
  EMPTY: ' ',
};

export const createScrubbingValidators = scrubbingErrors => {
  const testChargeWithScrubbingError = (keyPath, state, error) => {
    if (!error) return true;

    const chargeIndex = keyPath[1];
    const claimCharge = state.claimCharges[chargeIndex];

    const {
      scrubbingDetails: { claimChargeIds },
    } = error;

    return !claimChargeIds.includes(claimCharge.id);
  };

  const testFieldWithScrubbingError = (keyPath, error) => {
    if (!error) return true;

    const {
      scrubbingDetails: { violatingField },
    } = error;

    if (
      [
        'referringProviderQualifier',
        'referringProviderFirstName',
        'referringProviderLastName',
      ].includes(keyPath[0])
    ) {
      return !violatingField.includes('17');
    }

    if (
      [
        'referringProviderOtherIdQualifier',
        'referringProviderOtherId',
      ].includes(keyPath[0])
    ) {
      return !violatingField.includes('17a');
    }

    return !violatingField.includes('17b');
  };

  const scrubbingError = rule => ({
    error: CLAIM_FORM_ERROR_TYPES.EMPTY,
    validate: () => !rule,
  });

  const chargeScrubbingError = rule => ({
    error: CLAIM_FORM_ERROR_TYPES.EMPTY,
    validate: (v, keyPath, state) =>
      testChargeWithScrubbingError(keyPath, state, rule),
  });

  return {
    [SCRUB_CODE.CL001]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL001]),
    [SCRUB_CODE.CL002]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL002]),
    [SCRUB_CODE.CL003]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL003]),
    [SCRUB_CODE.CL004]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL004]),
    [SCRUB_CODE.CL005]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL005]),
    [SCRUB_CODE.CL006]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL006]),
    [SCRUB_CODE.CL007]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL007]),
    [SCRUB_CODE.CL008]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.CL008]),
    [SCRUB_CODE.PL001]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL001]),
    [SCRUB_CODE.PL002]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL002]),
    [SCRUB_CODE.PL003]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL003]),
    [SCRUB_CODE.PL005]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL005]),
    [SCRUB_CODE.PL006]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL006]),
    [SCRUB_CODE.PL007]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL007]),
    [SCRUB_CODE.PL008]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL008]),
    [SCRUB_CODE.PL009]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL009]),
    [SCRUB_CODE.PL010]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL010]),
    [SCRUB_CODE.PL012]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL012]),
    [SCRUB_CODE.PL013]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL013]),
    [SCRUB_CODE.PL014]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL014]),
    [SCRUB_CODE.PL015]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL015]),
    [SCRUB_CODE.PL016]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL016]),
    [SCRUB_CODE.PL017]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL017]),
    [SCRUB_CODE.PL018]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL018]),
    [SCRUB_CODE.PL019]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL019]),
    [SCRUB_CODE.PL020]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL020]),
    [SCRUB_CODE.PL021]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL021]),
    [SCRUB_CODE.PL022]: {
      error: CLAIM_FORM_ERROR_TYPES.EMPTY,
      validate: (_, keyPath) =>
        testFieldWithScrubbingError(keyPath, scrubbingErrors[SCRUB_CODE.PL022]),
    },
    [SCRUB_CODE.PL023]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL023]),
    [SCRUB_CODE.PL024]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL024]),
    [SCRUB_CODE.PL025]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL025]),
    [SCRUB_CODE.PL026]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL026]),
    [SCRUB_CODE.PL027]: chargeScrubbingError(scrubbingErrors[SCRUB_CODE.PL027]),
    [SCRUB_CODE.PL028]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL028]),
    [SCRUB_CODE.PL031]: {
      error: CLAIM_FORM_ERROR_TYPES.EMPTY,
      validate: (_, keyPath) =>
        testFieldWithScrubbingError(keyPath, scrubbingErrors[SCRUB_CODE.PL031]),
    },
    [SCRUB_CODE.PL032]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL032]),
    [SCRUB_CODE.PL033]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL033]),
    [SCRUB_CODE.PL034]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL034]),
    [SCRUB_CODE.PL035]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL035]),
    [SCRUB_CODE.PL036]: scrubbingError(scrubbingErrors[SCRUB_CODE.PL036]),
  };
};

const address = (validators = []) => ({
  children: {
    phone: phoneNumber(),
    address1: { validators },
    city: { validators },
    state: { validators },
    zipCode: { validators },
  },
});

const date = (opts = {}) => ({
  ...createDefaultModifiers(opts),
  clipPristine: true,
  format: v => (v ? parseDate(v).format() : ''),
  unformat: v => v || null,
  validators: opts.validators || [],
});

export const createRecommendedSelectors = opts => {
  const recommended = required(CLAIM_FORM_ERROR_TYPES.RECOMMENDED);
  const { scrubbingValidators } = opts;

  return {
    patientAddress: address([
      scrubbingValidators[SCRUB_CODE.PL008],
      recommended,
    ]),
    insuredAddress: address([
      scrubbingValidators[SCRUB_CODE.PL023],
      recommended,
    ]),
    insuredDateOfBirth: date({
      validators: [scrubbingValidators[SCRUB_CODE.PL009], recommended],
    }),
    federalTaxIdNumber: {
      validators: [scrubbingValidators[SCRUB_CODE.PL025], recommended],
    },
    medicalRecordNumber: {
      validators: [recommended],
    },
    claimDiagnoses: {
      validators: [recommended],
      clipPristine: true,
      unsafe: true,
    },
  };
};

export const createRequiredSelectors = opts => {
  const requiredForSecondary = {
    error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
    validate: v =>
      opts.type !== PAYMENT_RESPONSIBILITY_LEVEL_CODE.SECONDARY || v,
  };

  const { scrubbingValidators } = opts;

  return {
    insuredIdNumber: {
      validators: [scrubbingValidators[SCRUB_CODE.PL019], required()],
    },
    patientFirstName: {
      validators: [scrubbingValidators[SCRUB_CODE.PL036], required()],
    },
    patientLastName: {
      validators: [scrubbingValidators[SCRUB_CODE.PL036], required()],
    },
    patientDateOfBirth: date({ validators: [required()] }),

    totalChargeAmount: currency(),

    paidAmount: currency({
      validators: [
        {
          error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
          validate: v => v === 0 || !!v,
        },
      ],
      validateRaw: true,
    }),

    serviceFacilityAddress: address([required()]),
    serviceFacilityNPI: {
      validators: [required()],
    },
    billingProviderAddress: {
      children: {
        ...address([scrubbingValidators[SCRUB_CODE.PL003], required()])
          .children,
        zipCode: {
          validators: [
            scrubbingValidators[SCRUB_CODE.PL003],
            scrubbingValidators[SCRUB_CODE.PL005],
            required(),
          ],
        },
        phone: phoneNumber({
          validators: [scrubbingValidators[SCRUB_CODE.PL003]],
        }),
      },
    },

    billingProviderNPI: {
      validators: [required()],
    },

    insuredFirstName: [scrubbingValidators[SCRUB_CODE.PL035]],
    insuredLastName: [scrubbingValidators[SCRUB_CODE.PL035]],

    otherInsuredFirstName: {
      validators: [requiredForSecondary],
    },
    otherInsuredLastName: {
      validators: [requiredForSecondary],
    },
    otherInsuredGroupIdNumber: {
      validators: [requiredForSecondary],
    },
    otherInsurancePlanName: {
      validators: [requiredForSecondary],
    },
  };
};

export const createOptionalSelectors = opts => {
  const { scrubbingValidators } = opts;

  return {
    billingProviderOtherId: {
      validators: [scrubbingValidators[SCRUB_CODE.PL002]],
    },
    billingProviderOtherIdQualifier: {
      validators: [scrubbingValidators[SCRUB_CODE.PL001]],
    },
    serviceFacilityOtherId: {
      validators: [scrubbingValidators[SCRUB_CODE.PL028]],
    },
    serviceFacilityOtherIdQualifier: {
      validators: [scrubbingValidators[SCRUB_CODE.PL028]],
    },
    autoAccidentState: {
      validators: [scrubbingValidators[SCRUB_CODE.PL014]],
    },
    insuredRelationToPatient: {
      validators: [scrubbingValidators[SCRUB_CODE.PL017]],
    },
    claimCharges: {
      children: {
        $: {
          children: {
            dateOfService: {
              ...date(),
              validators: [scrubbingValidators[SCRUB_CODE.PL018]],
            },
            chargeAmount: {
              ...currency(),
              validators: [scrubbingValidators[SCRUB_CODE.PL016]],
            },
            modifier_1: {
              validators: [
                scrubbingValidators[SCRUB_CODE.CL001],
                scrubbingValidators[SCRUB_CODE.CL002],
                scrubbingValidators[SCRUB_CODE.CL003],
                scrubbingValidators[SCRUB_CODE.CL004],
                scrubbingValidators[SCRUB_CODE.PL015],
              ],
            },
            modifier_2: {
              validators: [
                scrubbingValidators[SCRUB_CODE.CL001],
                scrubbingValidators[SCRUB_CODE.CL002],
                scrubbingValidators[SCRUB_CODE.CL003],
                scrubbingValidators[SCRUB_CODE.CL004],
                scrubbingValidators[SCRUB_CODE.PL015],
              ],
            },
            modifier_3: {
              validators: [
                scrubbingValidators[SCRUB_CODE.CL001],
                scrubbingValidators[SCRUB_CODE.CL002],
                scrubbingValidators[SCRUB_CODE.CL003],
                scrubbingValidators[SCRUB_CODE.CL004],
                scrubbingValidators[SCRUB_CODE.PL015],
              ],
            },
            modifier_4: {
              validators: [
                scrubbingValidators[SCRUB_CODE.CL001],
                scrubbingValidators[SCRUB_CODE.CL002],
                scrubbingValidators[SCRUB_CODE.CL003],
                scrubbingValidators[SCRUB_CODE.CL004],
                scrubbingValidators[SCRUB_CODE.PL015],
              ],
            },
            diagnosisPointers: {
              ...createDefaultModifiers({}),
              validators: [scrubbingValidators[SCRUB_CODE.PL013]],
            },
            pos: {
              ...createDefaultModifiers({}),
              validators: [
                scrubbingValidators[SCRUB_CODE.PL012],
                scrubbingValidators[SCRUB_CODE.CL008],
              ],
            },
            providerOtherIdQualifier: {
              validators: [scrubbingValidators[SCRUB_CODE.PL026]],
            },
            providerOtherId: {
              validators: [scrubbingValidators[SCRUB_CODE.PL027]],
            },
            shaded24: {
              validators: [
                scrubbingValidators[SCRUB_CODE.CL006],
                scrubbingValidators[SCRUB_CODE.CL007],
              ],
            },
            code: {
              validators: [scrubbingValidators[SCRUB_CODE.CL005]],
            },
          },
        },
      },
    },
    patientSex: {
      ...createDefaultModifiers({}),
      clipPristine: true,
      format: v => (v !== 'Unknown' ? v : ''),
      unformat: v => v || 'Unknown',
    },
    claimStatuses: {
      ...createDefaultModifiers({}),
      createItem: () => ({
        id: '',
        claimId: '',
        status: '',
        code: '',
        description: '',
        updatedAt: '',
        createdAt: '',
        updatedBy: '',
      }),
      children: {
        $: {
          children: { status: { clipPristine: true, ignorePristine: true } },
        },
      },
    },
    dateOfCurrentIllness: date({
      validators: [scrubbingValidators[SCRUB_CODE.PL007]],
    }),
    disabilityFromDate: date(),
    disabilityToDate: date(),
    hospitalizationFromDate: date(),
    hospitalizationToDate: date(),
    outsideLabChargesAmount: currency(),
    dateGenerated: date(),
    otherDate: date({
      validators: [
        scrubbingValidators[SCRUB_CODE.PL021],
        scrubbingValidators[SCRUB_CODE.PL020],
        scrubbingValidators[SCRUB_CODE.PL010],
      ],
    }),
    claimAdditionalInfos: {
      createItem: () => ({
        ...EMPTY_ADDITIONAL_CLAIM_INFO,
      }),
    },
    insuredSex: {
      ...createDefaultModifiers({}),
      clipPristine: true,
      validators: [scrubbingValidators[SCRUB_CODE.PL006]],
    },
    otherClaimId: {
      validators: [scrubbingValidators[SCRUB_CODE.PL024]],
    },
    referringProviderQualifier: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    referringProviderFirstName: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    referringProviderLastName: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    referringProviderOtherIdQualifier: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    referringProviderOtherId: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    referringProviderNPI: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL022],
        scrubbingValidators[SCRUB_CODE.PL031],
      ],
    },
    reportTypeCode: {
      validators: [scrubbingValidators[SCRUB_CODE.PL032]],
    },
    reportTransmissionCode: {
      validators: [scrubbingValidators[SCRUB_CODE.PL032]],
    },
    resubmissionCode: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL033],
        scrubbingValidators[SCRUB_CODE.PL034],
      ],
    },
    originalReferenceNumber: {
      validators: [
        scrubbingValidators[SCRUB_CODE.PL033],
        scrubbingValidators[SCRUB_CODE.PL034],
      ],
    },
  };
};

export const createConfigurableSelectors = () => ({
  claimCodes: {
    validators: [
      {
        error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
        validate: (_, __, state) =>
          !state.claimCodeRequired ||
          (state.claimCodes && state.claimCodes.length),
      },
    ],
  },
  spinalManipulationInfoCode: {
    validators: [
      {
        error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
        validate: (_, __, state) =>
          !state.spinalManipInfoRequired ||
          (state.spinalManipulationInfoCode &&
            state.spinalManipulationInfoCode.length),
      },
    ],
  },
  spinalManipulationInfoDescription: {
    validators: [
      {
        error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
        validate: (_, __, state) =>
          !state.spinalManipInfoRequired ||
          (state.spinalManipulationInfoDescription &&
            state.spinalManipulationInfoDescription.length),
      },
    ],
  },
  claimAuthorizations: {
    createItem: () => ({
      order: 0,
      authorizationNumber: '',
    }),
    children: {
      $: {
        children: {
          authorizationNumber: {
            validators: [
              {
                error: CLAIM_FORM_ERROR_TYPES.REQUIRED,
                validate: (_, __, state) =>
                  !state.priorAuthorizationRequired ||
                  (state.claimAuthorizations.length &&
                    state.claimAuthorizations[0].authorizationNumber),
              },
            ],
          },
        },
      },
    },
  },
});
