import DeprecatedApiClient, { Method } from '../utils/api-client-utils';

export const BASE_URL = 'tenants';
export const apiClient = new DeprecatedApiClient({ microservice: 'registry' });
export const headerJSON = { 'Content-Type': 'application/json' };

function formatAddress(address) {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zipCode: address.zipCode,
  };
}

export function mapToRaw(model) {
  return {
    address: formatAddress(model.locations[0]),
    addOns: model.addOns,
    bio: model.bio,
    dstDisabled: model.dstDisabled,
    email: model.email,
    name: model.name,
    phoneNumber: model.phoneNumber,
    tier: model.tier,
    timezone: model.timezone,
    website: model.website,
  };
}

export function createNewPractice(practice) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify(mapToRaw(practice)),
    headers: headerJSON,
  });
}

export function updatePractice(practice) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${practice.id}`,
    body: JSON.stringify(mapToRaw(practice)),
    headers: headerJSON,
    version: 2,
  });
}
