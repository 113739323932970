import { getCheckInSummary } from '../../../../packages/neb-api-client/src/appointment-api-client';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../../packages/neb-utils/feature-util';

export const openOverlayCheckInOut = async (overlayKey, model) => {
  const hasDHCheckInFF = await hasFeatureOrBeta(FEATURE_FLAGS.DH_CHECK_IN);

  if (hasDHCheckInFF && model.mode === 'checkIn') {
    const checkInSummary = await getCheckInSummary(model.appointment.id);

    return openOverlay(OVERLAY_KEYS.CHECK_IN, {
      ...checkInSummary,
      patientSummaryModel: model.patientSummaryModel,
    });
  }

  return openOverlay(overlayKey, model);
};
