import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import {
  REFERRING_PROVIDER_QUALIFIERS,
  RESUBMISSION_CODES,
} from '../../../../../neb-utils/claims';
import { defaultMask } from '../../../../../neb-utils/masks';

const getDropdownTextItems = (name, state) => {
  let items;

  switch (name) {
    case 'referringProviderOtherId':
      items = [
        {
          label: '',
          value: '',
        },
        ...REFERRING_PROVIDER_QUALIFIERS,
      ];

      return [
        {
          name: 'referringProviderOtherIdQualifier',
          label: 'Qualifier',
          value: items.find(
            ({ value }) => value === state.referringProviderOtherIdQualifier,
          ),
          items,
        },
        {
          name: 'referringProviderOtherId',
          maxLength: 17,
          label: 'Other ID',
          value: state.referringProviderOtherId,
          width: '100%',
          mask: defaultMask,
        },
      ];

    case 'resubmissionCode':
      items = [
        {
          label: '',
          value: '',
        },
        ...RESUBMISSION_CODES,
      ];

      return [
        {
          name: 'resubmissionCode',
          label: 'Resubmission Code',
          value: items.find(({ value }) => value === state.resubmissionCode),
          items,
        },
        {
          name: 'originalReferenceNumber',
          maxLength: 18,
          label: 'Original Reference Number',
          value: state.originalReferenceNumber,
          width: '100%',
          mask: defaultMask,
        },
      ];

    default:
      return undefined;
  }
};

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_DROPDOWN_TEXT_INPUT, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: getDropdownTextItems(claimCellData.name, state),
  });

  if (result) {
    Object.entries(result).forEach(([name, value]) => {
      formService.apply(`claimEditedField.${name}`, true);

      const isObject = typeof value === 'object';
      handlers.change({
        name,
        value: isObject ? value.value : value,
      });
    });
  }
};
