import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { isRequiredIf } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import Table, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { PKG_SUB_CODES } from '../../../../packages/neb-lit-components/src/utils/package-subscription-charge-codes';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { CSS_FIELD_MARGIN } from '../../../../packages/neb-styles/neb-variables';
import { currencyToCentsWithNegative } from '../../../../packages/neb-utils/formatters';
import * as masks from '../../../../packages/neb-utils/masks';
import {
  owedGrandTotalValidator,
  PACKAGE_CODES,
} from '../../../../packages/neb-utils/neb-ledger-util';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { searchItems } from '../../../utils/filters';

export const CONFIG = [
  {
    key: 'codeId',
    label: 'Adjustment',
    flex: css`0 0 144px`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`0 0 96px`,
  },
];

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  codeDropdowns: { selector: '[id^=code-dropdown-]', tag: 'neb-select-search' },
  amounts: { selector: '[id^=amount-]', tag: 'neb-textfield' },
};

const REMOVE_ADJUSTMENT_MESSAGE =
  'Are you sure you wish to remove this adjustment entry?';

export class ChargeAdjustmentsTable extends Table {
  static get properties() {
    return {
      __searchResult: Array,
      __showResults: Boolean,
      __searchItems: Array,

      writeOffTypes: Array,
      primaryPayer: Object,
      disabled: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_FIELD_MARGIN};
        }

        .row {
          border-bottom: none;
        }

        .row-data {
          padding: 0;
        }

        .cell-data {
          overflow: initial;
          padding: 6px 0;
        }

        .row-data:first-of-type .cell-data {
          padding-top: 0;
        }

        .row-data:last-of-type .cell-data {
          padding-bottom: 0;
        }
      `,
    ];
  }

  static createModel() {
    return {
      codeId: '',
      amount: 0,
      id: '',
    };
  }

  static createSelectors({ adjustments = [] } = {}) {
    return {
      createItem: () => this.createModel(),
      children: {
        $: {
          children: {
            codeId: selectors.select(adjustments, selectors.ITEM_EMPTY, {
              validateManually: true,
              validateRaw: true,
              validators: [
                {
                  error:
                    'Adjustment code is invalid for this payment type, please select a new adjustment code from the list',
                  validate: (value, _, state) => {
                    if (state.payerInfo) {
                      const { billType } = state.payerInfo[0];
                      const adjustmentCode = adjustments.find(
                        ({ data }) => data.id === value,
                      );

                      if (adjustmentCode) {
                        switch (billType) {
                          case 'selfPay':
                            return adjustmentCode.data.forPatient;
                          case 'insurance':
                            return (
                              adjustmentCode.data.forInsurance ||
                              state.payerInfo[0].packageId
                            );
                          default:
                        }
                      }

                      return true;
                    }

                    return true;
                  },
                },
                isRequiredIf(
                  'amount',
                  true,
                  'Adjustment is required if Adjustment Amount is populated',
                ),
              ],
            }),
            amount: selectors.currencyWithNegative({
              validateManually: true,
              validateRaw: true,
              validators: [
                isRequiredIf(
                  'codeId',
                  true,
                  'Adjustment Amount is required if Adjustment is populated',
                ),
                owedGrandTotalValidator(
                  "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax",
                ),
              ],
            }),
          },
        },
      },
    };
  }

  initState() {
    super.initState();
    this.__searchResult = [];
    this.__showResults = false;
    this.__searchItems = [];

    this.hideHeader = true;
    this.showRemoveButton = true;
    this.disabled = false;
    this.config = CONFIG;
    this.writeOffTypes = [];
    this.primaryPayer = null;

    this.onChange = () => {};

    this.onBlur = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeAdjustmentsSearch: e => {
        const namespace = [this.name, e.name].filter(item => item).join('.');
        this.onChange({ ...e, name: namespace });
      },
      removeRow: async e => {
        const { currentTarget } = e;
        e.stopPropagation();

        const index = Number(currentTarget.index);
        const item = this.model[index];
        const amount = currencyToCentsWithNegative(this.model[index].amount);

        if (!this.disabled && !currentTarget.disabled) {
          if (amount) {
            const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Remove Adjustment',
              message: REMOVE_ADJUSTMENT_MESSAGE,
              confirmText: 'YES',
              cancelText: 'NO',
            });

            if (accepted) {
              this.onRemove(this.name, item, index);
            }
          } else {
            this.onRemove(this.name, item, index);
          }
        }
      },
      blur: e => {
        const namespace = [this.name, e.name].filter(item => item).join('.');
        this.onBlur({ ...e, name: namespace });
      },
      searchCode: ({ value }) => {
        if (!value) this.__showResults = true;
        this.__searchResult = searchItems(value, this.__searchItems);
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('writeOffTypes')) {
      this.getWriteOffTypesForSearch();
    }
  }

  shouldEnableRemoveButton(item) {
    return this.model.length > 1 && item.codeId && item.codeId.data
      ? item.codeId.data.code !== PKG_SUB_CODES[0]
      : null;
  }

  getWriteOffTypesForSearch() {
    const items = this.writeOffTypes
      .filter(
        ({ data }) =>
          (this.primaryPayer ? data.forInsurance : data.forPatient) &&
          data.active &&
          data.code !== PACKAGE_CODES[0],
      )
      .map(item => ({
        label: `${item.label} - ${item.data.description}`,
        data: item.data,
      }));

    this.__searchResult = [selectors.ITEM_EMPTY, ...items];
    this.__searchItems = [selectors.ITEM_EMPTY, ...items];
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, index, name, error) {
    const hasCarePackageAdjustment =
      this.model[index].codeId &&
      this.model[index].codeId.data &&
      this.model[index].codeId.data.code === PKG_SUB_CODES[0];

    switch (columnConfig.key) {
      case 'codeId':
        return html`
          <neb-select-search
            id="code-dropdown-${index}"
            forceAlignMenu="right"
            itemHeight="65"
            .name="${name}"
            .value="${value}"
            .error="${!!error}"
            .items="${this.__searchResult}"
            .onChange="${this.handlers.changeAdjustmentsSearch}"
            .showMenu="${this.__showResults}"
            ?disabled="${this.disabled || hasCarePackageAdjustment}"
            .onSearch="${this.handlers.searchCode}"
            itemMinWidth="500"
            showSearch
            showFullText
            wrapText
            itemHeight="60"
          ></neb-select-search>
        `;

      case 'amount':
        return html`
          <neb-textfield
            id="amount-${index}"
            .name="${name}"
            .mask="${masks.currencyNegative}"
            .inputMode="${'numeric'}"
            .value="${value}"
            .error="${!!error}"
            .onChange="${this.handlers.change}"
            .onBlur="${this.handlers.blur}"
            ?disabled="${this.disabled || hasCarePackageAdjustment}"
          ></neb-textfield>
        `;

      default:
        throw new Error(`Unknown key found: ${columnConfig.key}`);
    }
  }
}

window.customElements.define(
  'neb-table-charges-adjustments',
  ChargeAdjustmentsTable,
);
