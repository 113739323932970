import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../src/components/misc/neb-icon';

import { css, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_WARNING_COLOR,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_3,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { formatPaymentNumber } from '../../../../neb-utils/neb-ledger-util';
import { MODE } from '../../../../neb-utils/table';

import NebTable from './neb-table';

export const ELEMENTS = {
  subHeader: { id: 'sub-header' },
  subHeaderCells: { selector: '.sub-header-cell' },
  checkboxes: {
    selector: 'neb-checkbox',
  },
  bulkActionMenu: {
    id: 'bulk-action-menu',
  },
  editNoteIcons: {
    selector: '[id^=edit-note-icon-]',
  },
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
  payerLinks: {
    selector: '[id^=payer-link-]',
  },
  paymentNumbers: {
    selector: '[id^=payment-number-]',
  },
};

class NebTableLedgerPayments extends NebTable {
  static get properties() {
    return {
      patientId: String,
      subHeaderConfig: Array,
      body: Object,
    };
  }

  initState() {
    super.initState();
    this.mode = MODE.DETAIL;
    this.writable = false;
    this.emptyMessage = 'There are no payments.';
    this.subHeaderConfig = [];
    this.body = {};

    this.onBulkActionClick = () => {};

    this.onClickNoteIcon = () => {};

    this.onSelectPatient = () => {};

    this.onSelectPayer = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      bulkActionClick: () => this.onBulkActionClick(),
      clickNoteIcon: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const {
          id: parentId,
          amount,
          paymentNumber,
          payerName,
          transactionDate,
        } = this.model[rowIndex];

        return this.onClickNoteIcon({
          amount,
          parentId,
          paymentNumber,
          payerName,
          transactionDate,
        });
      },
      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return this.onSelectPatient({ patientId });
      },
      clickPayer: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { payerPlanId, patientId: paymentPatientId } =
          this.model[rowIndex];

        if (payerPlanId) {
          return this.onSelectPayer({ payerId: payerPlanId });
        }

        if (this.__isSharedPayment(rowIndex)) {
          return this.onSelectPatient({
            patientId: paymentPatientId,
            path: 'payments',
          });
        }

        return undefined;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-checkbox {
          padding: 0 5px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .container-status {
          display: flex;
          align-items: center;
        }

        .icon-warning {
          width: 22px;
          height: 22px;
          margin-right: 4px;
          fill: ${CSS_WARNING_COLOR};
        }

        .icon-split {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }

        .icon-era {
          width: 20px;
          height: 20px;
          margin-left: 3px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-note {
          width: 18px;
          height: 18px;
          margin-left: 3px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .label {
          width: min-content;
          margin-right: 5px;
        }

        .payment-number-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .sub-header {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .sub-header-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .sub-header-cell:last-child {
          margin-right: 0;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .sub-header-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .sub-header-trailing-spacer {
          width: 12px;
        }
      `,
    ];
  }

  __isSharedPayment(rowIndex) {
    return this.patientId && this.patientId !== this.model[rowIndex].patientId;
  }

  __renderPayerName({ value, rowIndex, columnConfig }) {
    return this.model[rowIndex].payerPlanId || this.__isSharedPayment(rowIndex)
      ? this.__renderPayerCell({
          value,
          rowIndex,
        })
      : super.renderDataCell(value, columnConfig, rowIndex);
  }

  __renderPaymentType({ value, rowIndex, columnConfig }) {
    const { codeDiscount } = this.model[rowIndex];

    let paymentTypeValue = value;

    if (codeDiscount) {
      paymentTypeValue = columnConfig.formatter
        ? columnConfig.formatter(codeDiscount)
        : codeDiscount.code;
    }

    return super.renderDataCell(paymentTypeValue, columnConfig, rowIndex);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ value, rowIndex });

      case 'patientName':
        return this.__renderPatientCell({ value, rowIndex });

      case 'status':
        return this.__renderStatus(value);

      case 'payerName':
        return this.__renderPayerName({ value, rowIndex, columnConfig });

      case 'paymentNumber':
        return this.__renderPaymentId({ value, rowIndex, columnConfig });

      case 'codePayment':
        return this.__renderPaymentType({ value, rowIndex, columnConfig });

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }

  renderSubHeader() {
    if (
      this.subHeaderConfig &&
      Object.keys(this.body).length &&
      this.body.totals
    ) {
      const cells = this.subHeaderConfig.slice(1).map(cell => {
        const value = cell.formatter
          ? cell.formatter(this.body.totals[cell.key])
          : '';

        return html`
          <span class="sub-header-cell text-bold" style="flex: ${cell.flex}"
            >${value}</span
          >
        `;
      });

      return html`
        <div id="${ELEMENTS.subHeader.id}" class="sub-header">
          <div class="sub-header-cell-spacer text-bold">Totals</div>
          ${cells}
          <div class="sub-header-trailing-spacer"></div>
        </div>
      `;
    }

    return '';
  }

  renderHeaderCell(headerCell) {
    switch (headerCell.key) {
      case 'checked':
        return html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .onClick="${this.handlers.bulkActionClick}"
          ></neb-button-actions>
        `;
      default:
        return super.renderHeaderCell(headerCell);
    }
  }

  __renderStatus(value) {
    return value === 'Warning'
      ? html`
          <div class="container-status">
            <neb-icon class="icon-warning" icon="neb:warning"></neb-icon>

            ${value}
          </div>
        `
      : value;
  }

  __renderCheckboxCell({ value, rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        name="checkbox.${rowIndex}"
        ?checked="${value}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderIcon() {
    return html` <neb-icon class="icon" icon="neb:open"></neb-icon> `;
  }

  __renderPayerCell({ value, rowIndex }) {
    return html`
      <div
        id="payer-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.clickPayer}"
      >
        <span class="patient-text">${value}</span>${this.__isSharedPayment(
          rowIndex,
        )
          ? this.__renderIcon()
          : ''}
      </div>
    `;
  }

  __renderPatientCell({ value, rowIndex }) {
    return value
      ? html`
          <div
            id="patient-link-${rowIndex}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickPatient}"
          >
            <span class="patient-text">${value}</span>${this.__renderIcon()}
          </div>
        `
      : '-';
  }

  __renderPaymentId({ value, rowIndex }) {
    const paymentNumber = formatPaymentNumber(this.model[rowIndex]);

    return value
      ? html`
          <div id="payment-number-${rowIndex}" class="payment-number-container">
            ${paymentNumber} ${this.__renderERAPaymentIcon(rowIndex)}
            ${this.__renderParentPaymentIcon(rowIndex)}
            ${this.model[rowIndex].hasBillingNote
              ? this.__renderEditNoteIcon(rowIndex)
              : ''}
          </div>
        `
      : '-';
  }

  __renderERAPaymentIcon(rowIndex) {
    return this.model.length && this.model[rowIndex].paymentMethod === 'ERA'
      ? html`
          <neb-icon
            id="icon-era-${rowIndex}"
            class="icon-era"
            icon="neb:receipt"
          ></neb-icon>
        `
      : '';
  }

  __renderParentPaymentIcon(rowIndex) {
    return this.model.length && this.model[rowIndex].parentPaymentId
      ? html`
          <neb-icon
            id="icon-split-${rowIndex}"
            class="icon-split"
            icon="neb:split"
          ></neb-icon>
        `
      : '';
  }

  __renderEditNoteIcon(rowIndex) {
    return html`
      <neb-icon
        id="edit-note-icon-${rowIndex}"
        class="icon-note"
        icon="neb:editNote"
        index="${rowIndex}"
        @click="${this.handlers.clickNoteIcon}"
      ></neb-icon>
    `;
  }
}

customElements.define('neb-table-ledger-payments', NebTableLedgerPayments);
