import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CLAIM_FORM_ERROR_TYPES } from '../../forms/neb-form-claim/create-selectors';

export const ELEMENTS = {
  address: { id: 'address' },
  city: { id: 'city' },
  state: { id: 'state' },
  zipCode: { id: 'zipCode' },
  phone: { id: 'phone' },
};

class NebClaimFormCellAddress extends LitElement {
  static get properties() {
    return {
      label: String,
      tooltipText: String,
      address: Object,
      errors: Object,
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.label = '';
    this.tooltipText = '';
    this.address = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
    };

    this.errors = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: repeat(3, minmax(90px, auto));
        }

        .span-column-5 {
          grid-column: span 5;
        }

        .span-column-4 {
          grid-column: span 4;
        }

        .span-column-3 {
          grid-column: span 3;
        }

        .span-column-2 {
          grid-column: span 2;
        }

        .cell {
          display: grid;
          width: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.address.id}"
          class="cell span-column-5"
          label="${this.label}"
          borderBottom
          ?warn="${this.errors.address1 === CLAIM_FORM_ERROR_TYPES.RECOMMENDED}"
          .data="${this.address.address1} ${this.address.address2}"
          .problemText="${this.errors.address1}"
          .tooltipText="${this.tooltipText}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.city.id}"
          class="cell span-column-4"
          label="City"
          borderRight
          borderBottom
          ?warn="${this.errors.city === CLAIM_FORM_ERROR_TYPES.RECOMMENDED}"
          .data="${this.address.city}"
          .problemText="${this.errors.city}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.state.id}"
          class="cell"
          label="State"
          borderBottom
          ?warn="${this.errors.state === CLAIM_FORM_ERROR_TYPES.RECOMMENDED}"
          .data="${this.address.state}"
          .problemText="${this.errors.state}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.zipCode.id}"
          class="cell span-column-2"
          label="Zip Code"
          borderRight
          borderBottom
          ?warn="${this.errors.zipCode === CLAIM_FORM_ERROR_TYPES.RECOMMENDED}"
          .data="${this.address.zipCode}"
          .problemText="${this.errors.zipCode}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.phone.id}"
          class="cell span-column-3"
          label="Telephone (Includes Area Code)"
          .data="${this.address.phone}"
          borderBottom
        >
        </neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-claim-form-cell-address',
  NebClaimFormCellAddress,
);
