import { LitElement, html, css } from 'lit';
import '../tables/neb-table';

import { baseStyles } from '../../../../neb-styles/neb-styles';

const CONFIG = [
  {
    key: 'code',
    label: 'ICD10',
    flex: css`1 0 80px`,
    truncate: true,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`2 0 100px`,
    truncate: true,
  },
];

export const ELEMENTS = {
  table: { id: 'table' },
};
class NebEncounterDiagnosesSummary extends LitElement {
  static get properties() {
    return {
      model: Array,
      small: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.model = [];
    this.small = false;
  }

  render() {
    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        emptyMessage="There are no diagnoses for this encounter."
        preview
        .model="${this.model}"
        .config="${CONFIG}"
        allowPropagation
      ></neb-table>
    `;
  }
}
customElements.define(
  'neb-encounter-diagnoses-summary',
  NebEncounterDiagnosesSummary,
);
