import '../../neb-lit-components/src/components/inputs/neb-menu';
import '../../neb-lit-components/src/components/login/neb-tenant-menu-item';

import { html, css } from 'lit';

import { getPractices } from '../../../src/api-clients/registry';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  tenantMenu: {
    id: 'tenant-menu',
  },
  tenantMenuItems: {
    selector: 'tenant-menu-item',
  },
  buttonCancel: {
    id: 'button-cancel',
    text: 'CANCEL',
  },
};

const ITEM_HEIGHT = 80;
const MAX_ITEMS = 3;

class NebPopupChangePractice extends NebPopup {
  static get properties() {
    return {
      __menuItems: {
        type: Array,
      },
    };
  }

  initState() {
    super.initState();
    this.title = 'Change Practice';
    this.__currentTenantIndex = '';
    this.__menuItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.renderTenant = item => html`
      <neb-tenant-menu-item
        .model="${{ ...item, apiOverride: this.model.apiOverride }}"
      ></neb-tenant-menu-item>
    `;

    this.handlers.selectTenant = index => {
      this.onClose(this.__menuItems[index].id);
    };
  }

  async firstUpdated() {
    this.__menuItems = await getPractices(this.model.tenantIds);
  }

  __setMinHeight(length) {
    return length <= MAX_ITEMS
      ? `${length * ITEM_HEIGHT}px`
      : `${MAX_ITEMS * ITEM_HEIGHT + 16}px`;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: block;
        }

        .menu-container {
          margin-bottom: ${CSS_SPACING};
        }

        .tenant-menu {
          position: static;
          width: 400px;
        }

        :host([layout='small']) .tenant-menu {
          width: 300px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div
        class="menu-container"
        style="min-height:${this.__setMinHeight(this.__menuItems.length)}"
      >
        <neb-menu
          id="${ELEMENTS.tenantMenu.id}"
          class="tenant-menu"
          .items="${this.__menuItems}"
          .itemHeight="${ITEM_HEIGHT}"
          .maxVisibleItems="${MAX_ITEMS}"
          .onRenderItem="${this.handlers.renderTenant}"
          .onChange="${this.handlers.selectTenant}"
          forceDown
          open
        ></neb-menu>
      </div>

      <neb-button
        id="${ELEMENTS.buttonCancel.id}"
        role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.buttonCancel.text}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }
}

customElements.define('neb-popup-change-practice', NebPopupChangePractice);
