import '../../../../src/components/misc/neb-icon';
import '../../../neb-styles/neb-icons';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  icon: {
    id: 'icon',
  },
  label: {
    id: 'label',
  },
};
class NebContentInstruction extends LitElement {
  static get properties() {
    return {
      icon: String,
      label: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex: 1 0 auto;
        }

        .container {
          display: flex;
          padding-top: 150px;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
        }

        .icon {
          width: 50px;
          height: 50px;
          margin-bottom: ${CSS_SPACING};
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .label {
          margin: 0;
          padding: 0;
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-icon
          id="${ELEMENTS.icon.id}"
          class="icon"
          .icon="${this.icon}"
        ></neb-icon>

        <p id="${ELEMENTS.label.id}" class="label">${this.label}</p>
      </div>
    `;
  }
}
customElements.define('neb-content-instruction', NebContentInstruction);
