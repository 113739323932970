import moment from 'moment-timezone';
import { RRule } from 'rrule';

export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';
export const YEARLY = 'YEARLY';
export const VISITS = 'Visits';
export const TIMES = 'Times';
export const DATE = 'Date';

export const OCCURRENCE_OPTIONS = Array(10)
  .fill(0)
  .map((_, index) => index + 1);

export const toRecurringText = (rrule, replaceText = 'visits') =>
  rrule.toText().replace(/(time)s*/, replaceText);

export const toRecurringTextFromString = rruleString =>
  toRecurringText(RRule.fromString(rruleString));

export const toDayDate = firstOccurrence =>
  moment(firstOccurrence).format('dddd, MMMM D, YYYY');

export const createOccurrenceNarrative = (rruleString, firstOccurrence) => {
  const narrative = toRecurringTextFromString(rruleString);
  const forOrUntil = narrative.includes('for') ? 'for' : 'until';
  return `Occurs ${narrative.slice(
    0,
    narrative.indexOf(forOrUntil),
  )}effective ${toDayDate(firstOccurrence)} ${narrative.slice(
    narrative.indexOf(forOrUntil),
    narrative.length,
  )}`;
};
