import '../../../../neb-lit-components/src/components/neb-action-bar';
import '../../../../neb-lit-components/src/components/macros/neb-macro-grid';
import '../../../../neb-material-design/src/components/neb-loading-spinner';
import './neb-charting-notes-editor-controller';
import './neb-charting-notes-data-table';
import './neb-charting-notes-header';
import '../../../../neb-lit-components/src/components/neb-loading-overlay';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { UNSUPPORTED_ADVANCED_TOKENS } from '../../../../../src/utils/macros';
import { BUTTON_ROLE } from '../../../../neb-lit-components/src/components/neb-button';
import { MacroSetService } from '../../../../neb-lit-components/src/services/macro-set';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_OVERLAY_LOADING,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { MACRO_BUTTON_TYPE, TAB_KEYS } from '../../../../neb-utils/macro-sets';
import { editQuestion, processMacro } from '../../macro-processor';

import {
  MODE_OVERLAY,
  initNotesState,
  parseQuestions,
  replaceQuestion,
  replaceField,
  replaceAllFields,
  insertOrReplaceTable,
} from './neb-charting-notes-util';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  actionBar: {
    id: 'action-bar',
  },
  macros: {
    id: 'macros',
  },
  editorController: {
    id: 'editor-controller',
  },
  answersTable: {
    id: 'table-answers',
  },
  spinnerOverlayText: {
    id: 'overlay-text',
  },
  chargeCodesButton: {
    id: 'button-codes-diagnoses',
  },
  diagnosesCodesButton: {
    id: 'button-codes-charges',
  },
  caption: {
    id: 'label-caption',
  },
  toggleButton: {
    id: 'button-toggle',
  },
};

const MACRO_TABS = [
  TAB_KEYS.SUBJECTIVE,
  TAB_KEYS.OBJECTIVE,
  TAB_KEYS.ASSESSMENT,
  TAB_KEYS.PLAN,
];

class NebChartingNotesDetail extends LitElement {
  static get properties() {
    return {
      __macrosExpanded: Boolean,
      __selectedTab: String,
      __slideDirection: String,
      __folderStack: Array,
      __selectedMacroSet: Object,
      __notes: Object,
      __currentFolder: Object,

      docsEnabled: Boolean,
      encounter: Object,
      expanded: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      overlayMode: String,
      macroSets: Array,
      initialNotes: Object,
      encounters: Object,
      currentEncounterApptsWithNarratives: Array,
      documents: Array,
      dataReady: Boolean,
      hasFutureCopyAnyEncounterFF: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__folderStack = [];
    this.__macrosExpanded = true;
    this.__notes = initNotesState();
    this.__selectedMacroSet = { id: '' };
    this.__selectedTab = TAB_KEYS.SUBJECTIVE;
    this.__soapSectionKey = TAB_KEYS.SUBJECTIVE;
    this.__slideDirection = '';
    this.__checkIfInitialNotesDoNotMatch = false;
    this.__editorReady = true;

    this.docsEnabled = false;
    this.expanded = false;
    this.encounter = {};
    this.initialNotes = initNotesState();
    this.overlayMode = MODE_OVERLAY.NONE;
    this.layout = '';
    this.macroSets = [];
    this.encounters = [];
    this.currentEncounterApptsWithNarratives = [];
    this.documents = [];
    this.dataReady = false;
    this.hasFutureCopyAnyEncounterFF = false;

    this.macroSetService = new MacroSetService(
      ({ folder, stack, slideDirection, macroSet }) => {
        this.__currentFolder = folder;
        this.__folderStack = stack;
        this.__slideDirection = slideDirection;
        this.__selectedMacroSet = macroSet;
      },
    );

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onMacroSetSelected = () => {};

    this.onNavButtonClicked = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      saveAndClose: () => this.onSave(this.__notes, { closeAfterSave: true }),
      save: () => {
        this.onSave(this.__notes, { closeAfterSave: false });

        this.__checkIfInitialNotesDoNotMatch = false;
      },
      cancel: () => this.onCancel(),
      chargesButtonClicked: () => this.onNavButtonClicked('charges'),
      diagnosesButtonClicked: () => this.onNavButtonClicked('diagnoses'),
      noteChanged: (key, value) => {
        this.__notes = { ...this.__notes, [key]: value };

        this.onChangeDirty(this.isDirty());
      },
      selectItem: async item => {
        if (!item) {
          return;
        }

        if (item.type === MACRO_BUTTON_TYPE.FOLDER) {
          this.macroSetService.selectFolder({ folderId: item.id });
        } else {
          const result = await processMacro(item, this.encounter);

          if (result) {
            this.__editorReady = false;
            this.__elements.editor.insertText(result);

            const containUnsupported = Object.keys(
              UNSUPPORTED_ADVANCED_TOKENS,
            ).some(tokenKey =>
              result.includes(`data-macro-replace-field="${tokenKey}"`),
            );

            if (containUnsupported) {
              await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
                title: 'Unsupported Advanced Macros Inserted',
                message: html`
                  This macro contains unsupported advanced macros, which were
                  inserted into the chart note. Please review and resolve all
                  unsupported advanced macros prior to completing the encounter.
                  <br /><br />Unsupported advanced macros can be deleted using
                  the backspace key. To permanently delete unsupported advanced
                  macros, update the macro set in Practice Settings.
                `,
              });
            }
          }
        }
      },
      selectTab: key => {
        const currentNotes = this.__notes[this.__selectedTab];

        if (this.__editorReady && (this.dataReady || currentNotes === '')) {
          this.__setSelectedTab(key);

          this.__handlers.selectSOAPFolder(key);
        }
      },
      selectSOAPFolder: sectionKey => {
        if (MACRO_TABS.indexOf(sectionKey) !== -1) {
          const folderId = this.__selectedMacroSet[`${sectionKey}FolderId`];

          this.macroSetService.selectFolder({
            folderId,
            resetFolderStack: true,
          });

          this.__soapSectionKey = sectionKey;
        }
      },
      macroSetSelected: id => {
        const selectedMacroSet = this.macroSets.find(
          macroSet => macroSet.id === id,
        );

        this.macroSetService.setMacroSet({
          macroSet: selectedMacroSet,
          folderId: selectedMacroSet[`${this.__soapSectionKey}FolderId`],
        });
      },
      breadcrumbsSelected: index => {
        if (index < this.__folderStack.length - 1) {
          const selectedFolder = this.__folderStack[index];

          this.macroSetService.selectFolder({ folderId: selectedFolder.id });
        }
      },
      questionSelected: async index => {
        const metadata = parseQuestions(this.__notes)[index];
        const newMetadata = await editQuestion(metadata);

        if (newMetadata !== null) {
          this.__notes = replaceQuestion(this.__notes, newMetadata, index);
        }
      },
      fieldSelected: async index => {
        const { action } = await openPopup(
          POPUP_RENDER_KEYS.MACROS_BASIC_FIELDS,
        );

        switch (action) {
          case 'one':
            this.__notes = await replaceField(
              this.__notes,
              index,
              this.encounter,
            );

            break;

          case 'all':
            this.__notes = await replaceAllFields(this.__notes, this.encounter);
            break;

          case 'cancel':
          default:
            break;
        }
      },
      unsupportedFieldSelected: async () => {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Unsupported Advanced Macro',
          message:
            'The selected advanced macro is currently unsupported and can be deleted using the backspace key.  To permanently delete unsupported advanced macros, update the macro set in Practice Settings.',
        });
      },
      resetAnimation: () => {
        this.__slideDirection = '';
      },
      allowEditing: () => {
        this.__editorReady = true;
      },

      toggle: () => {
        this.__macrosExpanded = !this.__macrosExpanded;
      },

      dataReady: value => {
        this.__editorReady = value;
        this.dataReady = value;
      },
    };
  }

  get __elements() {
    return {
      editor: this.shadowRoot.getElementById(ELEMENTS.editorController.id),
    };
  }

  __setSelectedTab(value) {
    this.__markEditorAsBusy(value);
    this.__selectedTab = value;
  }

  __isSoapSection(targetTab) {
    return Object.keys(this.__notes).includes(targetTab);
  }

  __markEditorAsBusy(targetTab) {
    if (
      targetTab === TAB_KEYS.DATA ||
      (this.__isSoapSection(targetTab) &&
        (!this.__notes[targetTab].length ||
          this.__notes[targetTab] === this.__notes[this.__selectedTab]))
    ) {
      return;
    }

    this.__editorReady = false;
  }

  /*
   * Called from parent when reading from local storage after navigating from charges or diagnoses.
   *   When in this scenario and anytime the initialNotes change, we need to always replace the
   *   diagnoses and charges tables within __notes with the ones that come from initialNotes.
   *
   *   The reason for this is because initialNotes will contain the most up to date table data
   *   after the diagnoses and charges lists have been updated.
   *         __checkIfInitialNotesDoNotMatch is the driver of this flow.
   */
  setState(payload) {
    this.macroSetService.setMacroSet({
      macroSet: payload.macroSet,
      folderStack: payload.folderStack,
    });

    this.__setSelectedTab(payload.selectedTab);
    this.__notes = payload.notes;
    this.__checkIfInitialNotesDoNotMatch = true;
    // editor may not exist at this point. Used later in lifecycle
    this.__editorPayload = payload.editor;

    return undefined;
  }

  getState() {
    const payload = {
      dirty: this.isDirty(),
      macroSet: this.__selectedMacroSet,
      selectedTab: this.__selectedTab,
      notes: this.__notes,
      folderStack: this.__folderStack,
    };

    if (this.__elements.editor) {
      payload.editor = this.__elements.editor.getLocalState();
    }

    return payload;
  }

  __replaceExistingTablesWithIncoming() {
    const updatedPlanNote = insertOrReplaceTable(
      this.initialNotes[TAB_KEYS.PLAN],
      this.__notes[TAB_KEYS.PLAN],
      'charge',
    );
    const updatedAssessmentNote = insertOrReplaceTable(
      this.initialNotes[TAB_KEYS.ASSESSMENT],
      this.__notes[TAB_KEYS.ASSESSMENT],
      'diagnosis',
    );
    this.__notes = {
      ...this.__notes,
      [TAB_KEYS.PLAN]: updatedPlanNote,
      [TAB_KEYS.ASSESSMENT]: updatedAssessmentNote,
    };
  }

  getBreadcrumbNames() {
    return this.__folderStack.map(({ name }) => name);
  }

  getDropdownItems() {
    return this.macroSets.map(macroSet => ({
      id: macroSet.id,
      displayValue: macroSet.name,
      value: `macroset-${macroSet.name.replace(/\s+/g, '')}`,
    }));
  }

  isDirty() {
    return this.__currentNotesDoNotMatch(this.initialNotes);
  }

  __currentNotesDoNotMatch(oldNotes) {
    return !!Object.keys(oldNotes).find(
      key =>
        oldNotes[key].length !== this.__notes[key].length ||
        oldNotes[key] !== this.__notes[key],
    );
  }

  __selectDefaultMacroSet() {
    if (this.macroSets.length > 0 && !this.macroSetService.macroSet) {
      this.__handlers.macroSetSelected(this.macroSets[0].id);
    }
  }

  reset() {
    this.__setSelectedTab(TAB_KEYS.SUBJECTIVE);
    this.__editorReady = true;
    this.__soapSectionKey = TAB_KEYS.SUBJECTIVE;
    this.__notes = { ...this.initialNotes };

    this.__selectDefaultMacroSet();

    if (this.__elements) {
      if (this.__elements.editor) {
        this.__elements.editor.reset();
      }
    }
  }

  update(changedProps) {
    if (changedProps.has('macroSets')) {
      this.__selectDefaultMacroSet();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('initialNotes')) {
      if (this.__editorPayload) {
        this.__elements.editor.setLocalState(this.__editorPayload);
        this.__editorPayload = null;
      }

      if (
        this.__checkIfInitialNotesDoNotMatch &&
        this.__currentNotesDoNotMatch(this.initialNotes)
      ) {
        this.__replaceExistingTablesWithIncoming();
      } else {
        this.__notes = { ...this.initialNotes };
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          overflow: auto;
        }

        .editor-controller,
        .data-table {
          flex: 1;
          min-height: 0;
        }

        .text-loading {
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        .overlay {
          display: flex;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          flex-direction: column;
          align-items: center;
          background-color: ${CSS_COLOR_OVERLAY_LOADING};
          z-index: 1;
        }

        .action-bar {
          z-index: 1;
        }

        .row-buttons {
          position: absolute;
          top: 10px;
          right: 40px;
          z-index: 2;
        }

        .cell-button-first {
          margin-right: 10px;
        }

        .header {
          margin-top: 30px;
        }

        .icon {
          bottom: 10px;
          width: 10px;
          height: 10px;
          transform: rotate(-90deg);
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon[expanded] {
          top: 10px;
          bottom: 0;
          width: 10px;
          order: -1;
          transform: rotate(90deg);
        }

        .button {
          cursor: pointer;
          outline: none;
          border: none;
          margin: 0;
          padding: 0;
        }

        .button-toggle {
          display: flex;
          border-top: 3px solid ${CSS_COLOR_HIGHLIGHT};
          border-bottom: 0;
          color: ${CSS_COLOR_HIGHLIGHT};
          flex-flow: column nowrap;
          align-items: center;
          background: ${CSS_COLOR_WHITE};
          margin-bottom: ${CSS_SPACING};
        }

        .button-toggle[expanded] {
          border-top: 0;
          border-bottom: 3px solid ${CSS_COLOR_HIGHLIGHT};
          margin-top: 14px;
        }

        .caption {
          margin: 0;
          font-size: 10px;
        }

        .row-border {
          position: absolute;
          top: 32px;
          height: 24px;
          width: 100%;
          z-index: 1;
        }
      `,
    ];
  }

  __renderOverlay(caption) {
    return html`
      <neb-loading-overlay
        id="${ELEMENTS.spinnerOverlayText.id}"
        class="spinner"
        title="${caption}"
        showDelay="0"
        .show="${true}"
      ></neb-loading-overlay>
    `;
  }

  __renderOverlayMode() {
    switch (this.overlayMode) {
      case MODE_OVERLAY.NONE:
        return '';

      case MODE_OVERLAY.SAVING_NOTES:
        return this.__renderOverlay('Saving Notes...');

      case MODE_OVERLAY.LOADING_NOTES:
        return this.__renderOverlay('Loading Notes...');

      default:
        return undefined;
    }
  }

  __renderExpander() {
    return html`
      <button
        id="${ELEMENTS.toggleButton.id}"
        class="button button-toggle"
        ?expanded="${this.__macrosExpanded}"
        @click="${this.__handlers.toggle}"
      >
        <p id="${ELEMENTS.caption.id}" class="caption">
          ${this.__macrosExpanded ? 'Hide MACROs' : 'Show MACROs'}
        </p>

        <neb-icon
          class="icon"
          icon="neb:back"
          ?expanded="${this.__macrosExpanded}"
        ></neb-icon>
      </button>
    `;
  }

  __renderMacroGrid() {
    return this.__macrosExpanded
      ? html`
          <neb-macro-grid
            id="${ELEMENTS.macros.id}"
            class="macros"
            disableEmpty="true"
            .folder="${this.__currentFolder}"
            .onSelectItem="${this.__handlers.selectItem}"
            .slideDirection="${this.__slideDirection}"
            .onAnimationEnd="${this.__handlers.resetAnimation}"
          ></neb-macro-grid>
        `
      : '';
  }

  __renderTabContent() {
    return this.__selectedTab !== TAB_KEYS.DATA
      ? html`
          ${this.__renderMacroGrid()} ${this.__renderExpander()}

          <neb-charting-notes-editor-controller
            id="${ELEMENTS.editorController.id}"
            class="editor-controller"
            .layout="${this.layout}"
            .notes="${this.__notes}"
            .selectedTab="${this.__selectedTab}"
            .encounter="${this.encounter}"
            .expanded="${this.expanded}"
            .docsEnabled="${this.docsEnabled}"
            .documents="${this.documents}"
            .encounters="${this.encounters}"
            .currentEncounterApptsWithNarratives="${this
              .currentEncounterApptsWithNarratives}"
            .onNoteChange="${this.__handlers.noteChanged}"
            .onSelectQuestion="${this.__handlers.questionSelected}"
            .onSelectField="${this.__handlers.fieldSelected}"
            .onSelectUnsupportedField="${this.__handlers
              .unsupportedFieldSelected}"
            .onSectionChanged="${this.__handlers.selectSOAPFolder}"
            .onNotesSynced="${this.__handlers.allowEditing}"
            .onDataReady="${this.__handlers.dataReady}"
            .hasFutureCopyAnyEncounterFF="${this.hasFutureCopyAnyEncounterFF}"
          ></neb-charting-notes-editor-controller>
        `
      : html`
          <neb-charting-notes-data-table
            id="${ELEMENTS.answersTable.id}"
            class="data-table"
            .noteAnswers="${parseQuestions(this.__notes)}"
            .onSelectQuestion="${this.__handlers.questionSelected}"
          ></neb-charting-notes-data-table>
        `;
  }

  render() {
    return this.expanded
      ? html`
          <div class="container">
            <div class="row-buttons">
              <neb-button
                class="cell-button-first"
                role="${BUTTON_ROLE.OUTLINE}"
                label="Diagnosis Codes"
                id="${ELEMENTS.diagnosesCodesButton.id}"
                .onClick="${this.__handlers.diagnosesButtonClicked}"
              ></neb-button>

              <neb-button
                role="${BUTTON_ROLE.OUTLINE}"
                label="Charge Codes"
                id="${ELEMENTS.chargeCodesButton.id}"
                .onClick="${this.__handlers.chargesButtonClicked}"
              ></neb-button>
            </div>
            <div class="row-border"></div>

            <neb-charting-notes-header
              id="${ELEMENTS.header.id}"
              class="header"
              .selectedMacroSetId="${this.__selectedMacroSet.id}"
              .selectedTab="${this.__selectedTab}"
              .macroSets="${this.getDropdownItems()}"
              .breadcrumbs="${this.getBreadcrumbNames()}"
              .onTabSelected="${this.__handlers.selectTab}"
              .onMacroSetSelected="${this.__handlers.macroSetSelected}"
              .onBreadcrumbSelected="${this.__handlers.breadcrumbsSelected}"
            ></neb-charting-notes-header>

            ${this.__renderTabContent()} ${this.__renderOverlayMode()}
          </div>

          ${this.isDirty()
            ? html`
                <neb-action-bar
                  id="${ELEMENTS.actionBar.id}"
                  class="action-bar"
                  confirmLabel="Save"
                  cancelLabel="Save and Close"
                  removeLabel="Cancel"
                  .doubleConfirm="${true}"
                  .onConfirm="${this.__handlers.save}"
                  .onCancel="${this.__handlers.saveAndClose}"
                  .onRemove="${this.__handlers.cancel}"
                ></neb-action-bar>
              `
            : ''}
        `
      : html``;
  }
}

customElements.define('neb-charting-notes-detail', NebChartingNotesDetail);
