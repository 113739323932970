import '../../../../neb-lit-components/src/components/neb-pagination';
import './neb-charting-diagnosis-problem-row';

import { LitElement, html } from 'lit';

import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { formatIcdSelectorIdCode } from '../../../../neb-utils/neb-charting-util';

export const NO_RESULTS_TEXT = 'No results found.';
export const EMPTY_PROBLEM_LIST_TEXT =
  'This patient does not have any problems in their problem list.';
export const ELEMENTS = {
  chartingDiagnosisProblemRow: {
    selector: 'neb-charting-diagnosis-problem-row',
  },
};

class NebChartingDiagnosisProblemTable extends LitElement {
  static get properties() {
    return {
      savedProblems: Array,
      buttonText: String,
      buttonToggledText: String,
      savedCodes: Array,
      hasSearchText: Boolean,
      currentPageIndex: Number,
      pageCount: Number,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.hasSearchText = false;
    this.buttonText = '';
    this.buttonToggledText = '';
    this.savedProblems = [];
    this.savedCodes = [];
    this.currentPageIndex = 0;
    this.pageCount = 0;

    this.onButtonClick = () => {};

    this.onPageChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: pageNumber => this.onPageChanged(pageNumber),
      buttonClick: problem => {
        this.onButtonClick({
          code: problem.code,
          shortDescription: problem.shortDescription,
        });
      },
    };
  }

  __renderStyles() {
    return html`
      <style>
        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .cell {
          margin: 0 10px 0 0;
          min-width: 0;
        }

        .cell:first-child {
          margin: 0 10px 0 ${CSS_SPACING};
        }

        .cell:last-child {
          margin: 0 ${CSS_SPACING} 0 0;
        }

        .cell-button {
          flex: 1 0 180px;
        }

        .cell-code {
          flex: 1 0 70px;
        }

        .cell-description {
          flex: 6 0 138px;
          text-align: left;
        }

        .cell-provider {
          flex: 1 0 70px;
        }

        .cell-onset {
          flex: 1 0 70px;
        }

        .cell-resolution {
          flex: 1 0 75px;
        }

        .cell-status {
          flex: 1 0 70px;
        }

        .table-header {
          display: flex;
          font-weight: 700;
          padding: ${CSS_SPACING};
          border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        }

        .no-problems-text {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .table-container {
          margin-top: 11px;
        }
      </style>
    `;
  }

  __renderEmptyText() {
    return html`
      <div id="no-problems-text" class="no-problems-text">
        ${this.hasSearchText ? NO_RESULTS_TEXT : EMPTY_PROBLEM_LIST_TEXT}
      </div>
    `;
  }

  isCodeAdded(icd10Code) {
    return !!this.savedCodes.find(
      currentInfo => currentInfo.code === icd10Code,
    );
  }

  __renderRows() {
    const offset = this.currentPageIndex * 10;
    return this.savedProblems
      .slice(offset, offset + 10)
      .map(
        problem => html`
          <neb-charting-diagnosis-problem-row
            id="icd10-${formatIcdSelectorIdCode(problem.code)}"
            .problemInfo="${problem}"
            .buttonText="${this.buttonText}"
            .buttonToggledText="${this.buttonToggledText}"
            .isAdded="${this.isCodeAdded(problem.code)}"
            .onButtonClick="${this.__handlers.buttonClick}"
          ></neb-charting-diagnosis-problem-row>
        `,
      );
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="problem-list-pagination"
        class="pagination"
        .pageCount="${this.pageCount}"
        .currentPage="${this.currentPageIndex}"
        .onPageChanged="${this.__handlers.pageChanged}"
      ></neb-pagination>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="table-container">
        <div id="header" class="table-header">
          <div class="cell cell-button"></div>
          <div class="cell cell-code">ICD10</div>
          <div class="cell cell-description">Description</div>
          <div class="cell cell-provider">Provider</div>
          <div class="cell cell-onset">Onset Date</div>
          <div class="cell cell-resolution">Resolution Date</div>
          <div class="cell cell-status">Status</div>
        </div>

        <div>
          ${this.savedProblems.length > 0
            ? this.__renderRows()
            : this.__renderEmptyText()}
        </div>
      </div>

      ${this.pageCount > 0 ? this.__renderPagination() : ''}
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-problem-table',
  NebChartingDiagnosisProblemTable,
);
