import '../../../../neb-styles/neb-styles';
import '../../../../../src/components/misc/neb-icon';
import '../neb-profile-photo';

import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { getPatientImage } from '../../../../neb-api-client/src/patient-image-api-client';
import {
  toDisplayAge,
  toFormatDate,
} from '../../../../neb-input/nebFormatUtils';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_SIZE_CAPTION,
  CSS_ICON_SMALL_WIDTH,
  CSS_ICON_SMALL_HEIGHT,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { capitalize, objToName } from '../../../../neb-utils/formatters';
import { getPhotoModel } from '../../../../neb-utils/patient';

const NAME_OPTS = {
  reverse: true,
  middleInitial: true,
  preferred: true,
};
export const ELEMENTS = {
  profileContainer: {
    id: 'profile-container',
  },
  profilePhoto: {
    id: 'profile-photo',
  },
  profileInfo: {
    id: 'profile-info',
  },
  subtitle: {
    id: 'profile-other-details',
  },
  iconExpand: {
    id: 'icon-expand',
  },
  iconTodaysEncounter: {
    id: 'icon-todays-encounter',
  },
  todaysEncounter: {
    id: 'todays-encounter',
  },
};

class NebPatientCondensedInfo extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      expanded: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      showTodaysEncounter: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initHandler();

    this.__initState();
  }

  __initState() {
    this.expanded = false;
    this.model = {
      dateOfBirth: null,
      hasPhoto: false,
      name: {
        first: '',
        middle: '',
        last: '',
        preferred: '',
        suffix: '',
      },
      photoSrc: '',
    };

    this.layout = '';
    this.showTodaysEncounter = true;

    this.onOpenOverlay = () => {};
  }

  __initHandler() {
    this.__handlers = {
      openOverlay: () => this.onOpenOverlay(this.model.photoSrc),
      todaysEncounter: e => {
        navigate(`/charting/${this.model.todaysEncounter.id}`);
        e.stopPropagation();
      },
    };
  }

  __computedFullname() {
    const { last, first, middle, preferred, suffix } = this.model.name;

    return objToName(
      {
        first,
        last,
        middle,
        preferred,
        suffix,
      },
      NAME_OPTS,
    );
  }

  __computeSubtitle() {
    if (!this.model.statuses) return html``;
    const {
      dateOfBirth,
      sex,
      statuses: { patient: patientStatus },
    } = this.model;
    const displayAge = toDisplayAge(dateOfBirth);
    const displayDOB = `${toFormatDate(dateOfBirth, 'MM/DD/YYYY')}`;

    return html`
      ${sex}, ${displayDOB} (${displayAge}) <b>${capitalize(patientStatus)}</b>
    `;
  }

  async __fetchPhoto() {
    if (!this.model.photoSrc) {
      const photoSrc = this.model.hasPhoto
        ? await getPatientImage(this.model.id, 'small', true)
        : '';

      if (this.model.photoSrc !== photoSrc) {
        this.model = { ...this.model, photoSrc };
      }
    }
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__fetchPhoto();
    }

    super.update(changedProps);
  }

  static get styles() {
    return css`
      :host {
        cursor: pointer;
        display: flex;

        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .profile-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .alert-badge {
        position: absolute;

        width: 20px;
        height: 20px;

        margin-left: 28px;
      }

      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        width: 100%;
      }

      .profile-info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .profile-name {
        font-size: ${CSS_FONT_SIZE_BODY};
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .profile-details {
        display: flex;
        align-items: center;
      }

      .profile-other-details {
        font-size: ${CSS_FONT_SIZE_CAPTION};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .photo {
        align-self: center;
        margin-right: ${CSS_SPACING};
        width: ${CSS_ICON_SMALL_WIDTH};
        height: ${CSS_ICON_SMALL_HEIGHT};
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      .icon-expand {
        width: 14px;
        height: 14px;
        margin: 0 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        transition: 250ms ease-in-out;
      }

      .todays-encounter {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: ${CSS_FONT_SIZE_CAPTION};

        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .icon-todays-encounter {
        width: 18px;
        height: 18px;
        margin: 0 5px 0 ${CSS_SPACING};

        fill: ${CSS_COLOR_HIGHLIGHT};

        transition: 250ms ease-in-out;
      }

      :host([expanded]) .icon-expand {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    `;
  }

  __renderTodaysEncounter() {
    return this.model.todaysEncounter && this.showTodaysEncounter
      ? html`
          <div
            class="todays-encounter"
            id="${ELEMENTS.todaysEncounter.id}"
            @click="${this.__handlers.todaysEncounter}"
          >
            <neb-icon
              id="${ELEMENTS.iconTodaysEncounter.id}"
              class="icon icon-todays-encounter"
              icon="neb:unsigned"
            ></neb-icon>

            ${this.layout === 'large' ? "Today's Encounter" : ''}
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.profileContainer.id}"
        class="profile-container"
        @click="${this.__handlers.openOverlay}"
      >
        <div class="profile">
          <neb-profile-photo
            id="${ELEMENTS.profilePhoto.id}"
            class="photo"
            .model="${getPhotoModel(this.model)}"
          ></neb-profile-photo>

          <div class="profile-info">
            <div id="${ELEMENTS.profileInfo.id}" class="profile-name">
              ${this.__computedFullname()}
            </div>

            <div class="profile-details">
              <div id="${ELEMENTS.subtitle.id}" class="profile-other-details">
                ${this.__computeSubtitle()}
              </div>

              ${this.__renderTodaysEncounter()}
            </div>
          </div>
        </div>

        <neb-icon
          id="${ELEMENTS.iconExpand.id}"
          class="icon icon-expand"
          icon="neb:chevron"
        ></neb-icon>
      </div>
    `;
  }
}

customElements.define('neb-patient-condensed-info', NebPatientCondensedInfo);
