import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { getBillingInfo } from '../../neb-api-client/src/billing-information-api-client';
import { getProviderUsers } from '../../neb-api-client/src/practice-users-api-client';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { PROVIDER_TYPE } from '../../neb-utils/enums';
import { DEFAULT_NAME_OPTS, objToName } from '../../neb-utils/formatters';

import NebPopupClaim, { ELEMENTS as BASE_ELEMENTS } from './neb-popup-claim';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  billingProviderDropdown: { id: 'billing-provider-dropdown' },
  npiDropdown: { id: 'npi-dropdown' },
  otherIdDropdown: { id: 'other-id-dropdown' },
};

class NebPopupClaimBillingProvider extends NebPopupClaim {
  static get properties() {
    return {
      __allProviders: Array,
      __billingInfo: Object,
      __npiOptions: Array,
      __otherIdOptions: Array,
      __providerItems: Array,
      __selectedProvider: Object,
      __selectedNPI: Object,
      __selectedOtherID: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .dropdown {
          width: 100%;
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__allProviders = [];
    this.__billingInfo = {};
    this.__npiOptions = [];
    this.__otherIdOptions = [];
    this.__providerItems = [];
    this.__selectedProvider = null;
    this.__selectedNPI = null;
    this.__selectedOtherID = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: ({ name, value, event }) => {
        if (event === 'select') {
          this[name] = value;
        }
      },

      changeProvider: ({ value, event }) => {
        if (event === 'select') {
          this.__selectedProvider = value;
          const provider = this.__selectedProvider;

          this.__selectedNPI = null;
          this.__selectedOtherID = null;

          this.__npiOptions = this.__getNpiFromProvider(provider);
          this.__otherIdOptions = this.__getOtherIdsFromProvider(provider);
        }
      },

      confirm: () => {
        if (this.__selectedProvider && this.__selectedNPI) {
          this.onClose({
            billingProviderId: this.__selectedProvider.item.id,
            billingProviderFirstName: this.__selectedProvider.item.firstName,
            billingProviderLastName: this.__selectedProvider.item.lastName,
            billingProviderNPI: this.__selectedNPI.item.npi,
            billingProviderNPISource: this.__selectedNPI.item.source,
            billingProviderOtherId: this.__selectedOtherID
              ? this.__selectedOtherID.item.billingProviderOtherId
              : '',
            billingProviderOtherIdQualifier: this.__selectedOtherID
              ? this.__selectedOtherID.item.billingProviderOtherIdQualifier
              : '',
          });
        }
      },

      renderItem: item =>
        item.label &&
        html`
          <p style="padding: 0 16px; width: max-content; max-width: 440px">
            ${item.label}
          </p>
        `,
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = this.model;
    }
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = this.model;

      if (this.model.selectedOption) {
        const previousProvider = this.__providerItems.find(
          provider =>
            provider.item.id ===
            this.model.selectedOption.value.billingProviderId,
        );

        if (previousProvider) {
          this.__selectedProvider = previousProvider;
        } else {
          const item = this.__allProviders.find(
            provider =>
              provider.id === this.model.selectedOption.value.billingProviderId,
          );

          this.__selectedProvider = {
            label: objToName(item.name, DEFAULT_NAME_OPTS),
            item,
          };

          this.__providerItems.push(this.__selectedProvider);
        }

        const provider = this.__selectedProvider;
        this.__npiOptions = this.__getNpiFromProvider(provider);
        this.__otherIdOptions = this.__getOtherIdsFromProvider(provider);

        if (this.model.selectedOption.value.billingProviderNPI) {
          const modelNPIString = String(
            this.model.selectedOption.value.billingProviderNPI,
          );

          this.__selectedNPI = this.__npiOptions.find(
            ({ item }) => item.npi === modelNPIString,
          );
        }

        if (this.model.selectedOption.value.billingProviderOtherIdQualifier) {
          this.__selectedOtherID = this.__otherIdOptions.find(
            x =>
              x.item.billingProviderOtherIdQualifier ===
              this.model.selectedOption.value.billingProviderOtherIdQualifier,
          );
        }
      }
    }
  }

  __isProviderIdSelected() {
    return this.__selectedProvider !== null;
  }

  __getNpiFromProvider(provider) {
    if (!provider) {
      return [];
    }

    const npiOptions = [];
    const { NPI, organizationNPI } = provider.item;

    npiOptions.push(...this.__getPracticeOrganizationNPI());

    npiOptions.push({
      label: `Provider Individual NPI - ${NPI}`,
      item: {
        npi: NPI.toString(),
        source: 'Provider Individual',
      },
    });

    if (organizationNPI) {
      npiOptions.push({
        label: `Provider Organization NPI - ${organizationNPI}`,
        item: {
          npi: organizationNPI.toString(),
          source: 'Provider Organization',
        },
      });
    }

    npiOptions.push(...this.__getPracticeLocationNPIs());

    return npiOptions;
  }

  __getOtherIdsFromProvider(provider) {
    if (!provider) {
      return [];
    }

    const ALLOWED_OTHER_IDS = ['0B', 'EI', 'G2', 'ZZ'];

    return provider.item.identifiers
      .map(x => ({
        label: `${x.type.value} - ${x.number}`,
        item: {
          billingProviderOtherIdQualifier: x.type.value,
          billingProviderOtherId: x.number,
        },
      }))
      .filter(x =>
        ALLOWED_OTHER_IDS.includes(x.item.billingProviderOtherIdQualifier),
      );
  }

  __getPracticeOrganizationNPI() {
    if (this.__billingInfo) {
      const { organizationNPI } = this.__billingInfo;

      return organizationNPI
        ? [
            {
              label: `Practice Organization NPI - ${organizationNPI}`,
              item: {
                npi: organizationNPI,
                source: 'Practice Organization',
              },
            },
          ]
        : [];
    }

    return [];
  }

  __getPracticeLocationNPIs() {
    const options = [];
    const { locations } = store.getState().practiceInformation.item;
    const activeLocations = locations.filter(
      location => location.active === true,
    );

    activeLocations.forEach(location => {
      if (location.organizationNPI) {
        options.push({
          label: `${location.name} - ${location.organizationNPI}`,
          item: {
            npi: location.organizationNPI.toString(),
            source: location.name,
          },
        });
      }
    });

    return options;
  }

  async connectedCallback() {
    await Promise.all([this.__fetchBillingInfo(), this.__fetchProviders()]);

    super.connectedCallback();
  }

  async __fetchProviders() {
    this.__allProviders = await getProviderUsers();

    this.__providerItems = this.__allProviders
      .filter(
        item => item.active === true && item.type === PROVIDER_TYPE.PROVIDER,
      )
      .map(item => ({
        label: objToName(item.name, DEFAULT_NAME_OPTS),
        item,
      }));

    if (this.model.selectedOption) {
      this.__selectedProvider = this.__providerItems.find(
        provider =>
          provider.item.id ===
          this.model.selectedOption.value.billingProviderId,
      );
    }
  }

  async __fetchBillingInfo() {
    this.__billingInfo = await getBillingInfo();
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.billingProviderDropdown.id}"
        class="dropdown"
        showFullText
        label="Billing Provider"
        name="providerId"
        helper="Required"
        .error="${!this.__selectedProvider}"
        .value="${this.__selectedProvider}"
        .items="${this.__providerItems}"
        .onChange="${this.handlers.changeProvider}"
        .onRenderItem="${this.handlers.renderItem}"
      >
      </neb-select>
      <neb-select
        id="${ELEMENTS.npiDropdown.id}"
        class="dropdown"
        label="33a. Organization or Individual NPI#"
        showFullText
        pinLabel
        ?disabled="${!this.__isProviderIdSelected()}"
        name="__selectedNPI"
        helper="Required"
        .error="${!this.__selectedNPI}"
        .value="${this.__selectedNPI}"
        .items="${this.__npiOptions}"
        .onChange="${this.handlers.change}"
        .onRenderItem="${this.handlers.renderItem}"
      >
      </neb-select>
      <neb-select
        id="${ELEMENTS.otherIdDropdown.id}"
        class="dropdown"
        label="33b. Billing Provider Other ID/Qualifier"
        showFullText
        pinLabel
        ?disabled="${!this.__isProviderIdSelected()}"
        name="__selectedOtherID"
        .value="${this.__selectedOtherID}"
        .items="${this.__otherIdOptions}"
        .onChange="${this.handlers.change}"
        .onRenderItem="${this.handlers.renderItem}"
      >
      </neb-select>
    `;
  }
}

customElements.define(
  'neb-popup-claim-billing-provider',
  NebPopupClaimBillingProvider,
);
