import { ETIN_CLEARINGHOUSES } from '../../src/components/collection-pages/neb-collection-page-clearinghouse-settings';
import { CLEARINGHOUSES } from '../../src/utils/clearinghouse-settings';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../neb-lit-components/src/utils/overlay-constants';

import { FEDERAL_TAX_ID_SOURCE } from './enums';

export const EMPTY_ADDITIONAL_CLAIM_INFO = {
  additionalClaimCodeId: '',
  codeGroup: '',
  code: '',
  description: '',
  id: '',
  payerPlanId: '',
  sortOrder: 0,
  note: '',
};

export const BLANK_IDENTIFIER = {
  label: '',
  type: { value: '', label: '' },
  number: '',
};

export const BLANK_OTHER_TAX_ID = {
  federalTaxIdOtherNumber: '',
  federalTaxIdOtherType: '',
  federalTaxIdSource: '',
  federalTaxIdProviderIdentifierId: '',
};

export const BLANK_ADDRESS = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
};

export const BLANK_PROVIDER_ADDRESS = {
  name: '',
  phone: '',
  address: {
    ...BLANK_ADDRESS,
  },
};

const PROVIDER_ADDRESS_TYPE = Object.freeze({
  ServiceFacility: 'serviceFacility',
  BillingProvider: 'billingProvider',
});

export const mapProviderAddressToRaw = (model, type) => {
  if (
    !model ||
    !model.address ||
    !model.address.address1 ||
    model.address.address1 === ''
  ) {
    return null;
  }

  return {
    id: model.id,
    type,
    name: model.name,
    phone: model.phone,
    address1: model.address.address1,
    address2: model.address.address2,
    city: model.address.city,
    state: model.address.state,
    zipCode: model.address.zipcode,
  };
};

const formatProviderInOutOfNetwork = providerInOutOfNetwork => ({
  providerId: providerInOutOfNetwork.providerId,
  isInNetwork: providerInOutOfNetwork.isInNetwork,
});

const formatProviderInOutOfNetworks = entries =>
  entries ? entries.map(entry => formatProviderInOutOfNetwork(entry)) : [];

const mapProviderSpecificValuesToRaw = providerSpecificValues =>
  providerSpecificValues
    ? providerSpecificValues.flatMap(v =>
        v.items.map((item, index) => ({
          ...item,
          sortOrder: index,
          providerId: v.providerId || '',
          locations: item.locations || [],
          serviceFacilityAddress: mapProviderAddressToRaw(
            item.serviceFacilityAddress,
            PROVIDER_ADDRESS_TYPE.ServiceFacility,
          ),
          billingProviderAddress: mapProviderAddressToRaw(
            item.billingProviderAddress,
            PROVIDER_ADDRESS_TYPE.BillingProvider,
          ),
          providerOtherIdentifierId:
            item.providerOtherIdentifierId &&
            item.providerOtherIdentifierId.length
              ? item.providerOtherIdentifierId
              : null,
          serviceFacilityOtherIdentifierId:
            item.serviceFacilityOtherIdentifierId &&
            item.serviceFacilityOtherIdentifierId.length
              ? item.serviceFacilityOtherIdentifierId
              : null,
          billingProviderOtherIdentifierId:
            item.billingProviderOtherIdentifierId &&
            item.billingProviderOtherIdentifierId.length
              ? item.billingProviderOtherIdentifierId
              : null,
          federalTaxIdSource:
            item.federalTaxIdSource && item.federalTaxIdSource.length
              ? item.federalTaxIdSource
              : null,
          federalTaxIdProviderIdentifierId:
            item.federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN &&
            item.federalTaxIdProviderIdentifierId &&
            item.federalTaxIdProviderIdentifierId.length
              ? item.federalTaxIdProviderIdentifierId
              : null,
          federalTaxIdOtherNumber:
            item.federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.OTHER &&
            item.federalTaxIdOtherNumber &&
            item.federalTaxIdOtherNumber.length
              ? item.federalTaxIdOtherNumber
              : null,
          federalTaxIdOtherType:
            item.federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.OTHER &&
            item.federalTaxIdOtherType &&
            item.federalTaxIdOtherType.length
              ? item.federalTaxIdOtherType
              : null,
        })),
      )
    : [];

export const mapToV2Raw = model => ({
  ...model,
  billingProviderAddress: model.billingProviderAddress || null,
  providerSpecificValues: mapProviderSpecificValuesToRaw(
    model.providerSpecificValues,
  ),
  feeScheduleId: model.feeScheduleId || null,
  payerGroupId: model.payerGroupId || null,
});

export const mapToV3Raw = (model, activeClearinghouses = []) => {
  const { clearinghousePayerId, ...modelV2 } = mapToV2Raw(model);

  if (model.clearinghouseId) {
    const selectedClearinghouse = activeClearinghouses.find(
      x => x.id === model.clearinghouseId,
    );

    if (
      selectedClearinghouse &&
      selectedClearinghouse.partner === CLEARINGHOUSES.CHC
    ) {
      return {
        ...modelV2,
        chcId: selectedClearinghouse.id,
        chcPayerId: clearinghousePayerId,
        clearinghouseId: null,
        primaryIdentifier: null,
        payerEtin: null,
      };
    }

    if (selectedClearinghouse) {
      const payerEtin = ETIN_CLEARINGHOUSES.includes(
        selectedClearinghouse.partner,
      )
        ? modelV2.payerEtin
        : null;

      return {
        ...modelV2,
        chcId: null,
        chcPayerId: null,
        clearinghouseId: selectedClearinghouse.id,
        primaryIdentifier: clearinghousePayerId,
        payerId: null,
        payerEtin,
      };
    }
  }

  return {
    ...modelV2,
    chcId: null,
    chcPayerId: clearinghousePayerId || null,
    clearinghouseId: null,
    primaryIdentifier: null,
    payerEtin: null,
    payerId: null,
  };
};

const mapProviderAddressToModel = raw =>
  raw
    ? {
        ...BLANK_PROVIDER_ADDRESS,
        ...raw,
        address: {
          ...BLANK_PROVIDER_ADDRESS.address,
          address1: raw.address1 || '',
          address2: raw.address2 || '',
          city: raw.city || '',
          state: raw.state || '',
          zipcode: raw.zipCode || '',
        },
      }
    : BLANK_PROVIDER_ADDRESS;

const mapProviderSpecificValuesToModel = payerPlan => {
  const providers = payerPlan.providerSpecificValues
    ? payerPlan.providerSpecificValues.reduce((memo, value) => {
        if (memo[value.providerId]) {
          memo[value.providerId].push(value);
        } else {
          memo[value.providerId] = [value];
        }

        return memo;
      }, {})
    : {};

  const providerValues = [];

  Object.entries(providers).forEach(([key, value]) => {
    const items = [];

    value.forEach(v => {
      const item = {
        ...v,
        providerOtherIdentifierId: v.providerOtherIdentifierId || '',
        serviceFacilityOtherIdentifierId:
          v.serviceFacilityOtherIdentifierId || '',
        billingProviderOtherIdentifierId:
          v.billingProviderOtherIdentifierId || '',
        serviceFacilityAddress: mapProviderAddressToModel(
          v.serviceFacilityAddress,
        ),
        billingProviderAddress: mapProviderAddressToModel(
          v.billingProviderAddress,
        ),
        federalTaxIdSource: v.federalTaxIdSource || '',
        federalTaxIdProviderIdentifierId:
          v.federalTaxIdProviderIdentifierId || '',
        federalTaxIdOtherNumber: v.federalTaxIdOtherNumber || '',
        federalTaxIdOtherType: v.federalTaxIdOtherType || '',
        locations: v.locations || [],
      };

      delete item.providerId;

      items.push(item);
    });

    providerValues.push({
      providerId: key !== 'undefined' ? key : '',
      items,
    });
  });

  return providerValues;
};

export const EMPTY_ERA_PAYER_NAMES = [{ name: '' }];

export const mapToModel = payerPlan => ({
  ...payerPlan,
  billingProviderAddress: payerPlan.billingProviderAddress || '',
  timelyFiling: payerPlan.timelyFiling ? `${payerPlan.timelyFiling}` : '',
  additionalClaimInfos:
    payerPlan.additionalClaimInfos && payerPlan.additionalClaimInfos.length
      ? payerPlan.additionalClaimInfos.sort((a, b) => a.sortOrder - b.sortOrder)
      : [{ ...EMPTY_ADDITIONAL_CLAIM_INFO }],
  claimCodes: payerPlan.claimCodes || '',
  claimCodeRequired:
    payerPlan.claimCodeRequired !== null ? payerPlan.claimCodeRequired : false,
  providerSpecificValues: mapProviderSpecificValuesToModel(payerPlan),
  feeScheduleId: payerPlan.feeScheduleId || '',
  chcPayerId: payerPlan.chcPayerId || null,
  realTimeEligibilityEnabled: payerPlan.realTimeEligibilityEnabled || false,
  realTimeEligibilityPayerId: payerPlan.realTimeEligibilityPayerId || '',
  realTimeEligibilityPortalId: payerPlan.realTimeEligibilityPortalId || '',
  realTimeEligibilityPortalPassword:
    payerPlan.realTimeEligibilityPortalPassword || '',
  realTimeEligibilityPortalUsername:
    payerPlan.realTimeEligibilityPortalUsername || '',
  useRealTimeEligibilityPortal: payerPlan.useRealTimeEligibilityPortal || false,
  realTimeEligibilityNoFutureDates:
    payerPlan.realTimeEligibilityNoFutureDates || false,
  providerInOutOfNetwork: formatProviderInOutOfNetworks(
    payerPlan.providerInOutOfNetwork,
  ),
  payerGroupId: payerPlan.payerGroupId || '',
  chcId: '',
  eraPayerNames: payerPlan.eraPayerNames || EMPTY_ERA_PAYER_NAMES,
});

export const rteEnabledInsurance = payerPlan =>
  (payerPlan.realTimeEligibilityEnabled &&
    payerPlan.realTimeEligibilityPayerId) ||
  (payerPlan.useRealTimeEligibilityPortal &&
    payerPlan.realTimeEligibilityPortalId &&
    payerPlan.realTimeEligibilityPortalUsername &&
    payerPlan.realTimeEligibilityPortalPassword);

function getClearinghousePayerId(payerPlan) {
  if (payerPlan.chcPayerId) return payerPlan.chcPayerId;

  if (payerPlan.primaryIdentifier) return payerPlan.primaryIdentifier;

  return '';
}

export const mapToModelV3 = payerPlan => ({
  ...mapToModel(payerPlan),
  clearinghouseId: payerPlan.chcId
    ? payerPlan.chcId
    : payerPlan.clearinghouseId || '',
  clearinghousePayerId: getClearinghousePayerId(payerPlan),
  payerEtin: payerPlan.payerEtin || '',
  payerAddressOverride: payerPlan.payerAddressOverride || false,
});

export const openPayerPlanOverlay = payerPlan =>
  openOverlay(OVERLAY_KEYS.PAYER_PLAN, payerPlan);
