import '../../neb-tab-old';
import '../../neb-popup-header';
import '../../patients/neb-patient-form-controller';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { createModel } from '../../../../../neb-utils/patient';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  formController: {
    id: 'controller-form',
  },
};
const PATIENT_EMPTY = createModel();

class NebPatientOverlay extends Overlay {
  initState() {
    super.initState();
    this.__patient = PATIENT_EMPTY;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.changePatient = dirty => {
      this.isDirty = dirty;
    };
  }

  getTitle() {
    return this.model.id ? 'Update Patient' : 'Add Patient';
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__patient = this.model.id ? this.model : PATIENT_EMPTY;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 800px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .page {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-patient-form-controller
        id="${ELEMENTS.formController.id}"
        class="page"
        .layout="${this.layout}"
        .patient="${this.__patient}"
        .onChangeDirty="${this.handlers.changePatient}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
        slim
      ></neb-patient-form-controller>
    `;
  }
}

window.customElements.define('neb-overlay-patient', NebPatientOverlay);
