import '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-moment-calendar-view';
import '../../neb-lit-components/src/components/neb-macro-answer-preview';
import './neb-popup-action-buttons';

import { html, css } from 'lit';
import moment from 'moment-timezone';

import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';
import { getDatePickerLocaleDate } from '../../neb-utils/moment-flag-conversion';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  actionButtons: { id: 'action-buttons' },
  preview: { id: 'preview' },
  calendarView: { id: 'calendar' },
};

const ANSWER_FORMAT = 'MM/DD/YYYY';

class NebPopupMacrosDatePicker extends NebPopup {
  static get properties() {
    return {
      __answer: String,
      __date: Object,
    };
  }

  initState() {
    super.initState();
    this.__answer = moment().format(ANSWER_FORMAT);
    this.__date = parseDate();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: () => this.handleClick('save'),

      next: () => this.handleClick('next'),

      back: () => this.handleClick('back'),

      cancel: () =>
        this.onClose({
          action: 'cancel',
        }),

      dateSelected: date => {
        this.__date = date;
        this.__answer = getDatePickerLocaleDate(true, date);
      },

      previewChanged: text => {
        this.__answer = text;
      },
    };
  }

  __isValidAnswer() {
    return this.__answer !== '' || !!this.__answer;
  }

  handleClick(action) {
    const answer = this.__isValidAnswer()
      ? {
          text: this.__answer,
          date: this.__date,
        }
      : null;
    this.onClose({
      action,
      answer,
    });
  }

  modelChanged() {
    this.title = this.model.title;

    if (this.model.answer) {
      this.__date = moment(this.model.answer.date);
      this.__answer = this.model.answer.text;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 577px;
          height: 623px;
        }

        .content {
          height: max-content;
          flex: 1 0 0;
        }

        .content-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .item {
          margin-bottom: 4px;
        }

        .preview {
          flex: 1 0 0;
          max-height: 85px;
          min-height: 40px;
          margin-bottom: 5px;
        }

        .calendar-container {
          display: flex;
          justify-content: center;
          flex-shrink: 0; /* Needed for safari */
        }

        .calendar {
          margin: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .option-content {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          overflow-y: auto;
        }

        .preview-container {
          display: flex;
          flex: 1 0 0;
        }

        .action-buttons {
          margin-top: ${CSS_SPACING};
        }

        .calendar {
          --container-calendar-padding: 0 0 10px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="option-content">
          <div class="calendar-container">
            <neb-moment-calendar-view
              id="${ELEMENTS.calendarView.id}"
              class="calendar"
              .selectedDate="${this.__date}"
              .isPickerable="${true}"
              .onChange="${this.handlers.dateSelected}"
              .onTodaySelected="${this.handlers.dateSelected}"
            ></neb-moment-calendar-view>
          </div>

          <neb-macro-answer-preview
            id="${ELEMENTS.preview.id}"
            class="preview"
            .content="${this.__answer}"
            .onChange="${this.handlers.previewChanged}"
          ></neb-macro-answer-preview>
        </div>

        <neb-popup-action-buttons
          id="${ELEMENTS.actionButtons.id}"
          class="action-buttons"
          .isFirst="${this.model.isFirst}"
          .isLast="${this.model.isLast}"
          .onSaveClick="${this.handlers.save}"
          .onCancelClick="${this.handlers.cancel}"
          .onBackClick="${this.handlers.back}"
          .onNextClick="${this.handlers.next}"
        ></neb-popup-action-buttons>
      </div>
    `;
  }
}

customElements.define('neb-popup-macros-date-picker', NebPopupMacrosDatePicker);
