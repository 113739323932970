import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';

import NebPopup from './neb-popup';

export const TITLE = 'Update Patient Information';
export const MESSAGE =
  'Would you like to refresh the patient information for only this field, or for all patient fields in the chart note?';
export const ID_BUTTON_ONE = 'button-one';
export const ID_BUTTON_ALL = 'button-all';
export const ID_BUTTON_CANCEL = 'button-cancel';
export const ID_MESSAGE = 'message';

export const ELEMENTS = {
  message: {
    id: 'message',
  },
  buttonOne: {
    id: 'button-one',
  },
  buttonAll: {
    id: 'button-all',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
};

class NebPopupMacrosBasicFields extends NebPopup {
  initState() {
    super.initState();
    this.title = TITLE;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () => {
      this.onClose({
        action: 'cancel',
      });
    };

    this.handlers.one = () => {
      this.onClose({
        action: 'one',
      });
    };

    this.handlers.all = () => {
      this.onClose({
        action: 'all',
      });
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 446px;
          height: 186px;
        }

        .content {
          height: max-content;
          flex: 1 0 0;
        }

        .content-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .button-container {
          display: flex;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.message.id}" class="message">${MESSAGE}</div>
        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonOne.id}"
            class="button"
            role="${BUTTON_ROLE.CONFIRM}"
            .label="${'This Field'}"
            .onClick="${this.handlers.one}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonAll.id}"
            class="button"
            role="${BUTTON_ROLE.CONFIRM}"
            .label="${'All Fields'}"
            .onClick="${this.handlers.all}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            role="${BUTTON_ROLE.CANCEL}"
            .label="${'Cancel'}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macros-basic-fields',
  NebPopupMacrosBasicFields,
);
