import equal from 'fast-deep-equal';

import { OTHER_ID_QUALIFIER } from '../../../../../neb-utils/claims';

export default ({
  name,
  currentProviderOtherId,
  currentProviderOtherIdQualifier,
  originalOtherId,
  originalOtherIdQualifier,
  key,
}) => {
  const previousOptions = [];

  const originalOption =
    originalOtherIdQualifier && originalOtherId
      ? {
          label: `${originalOtherIdQualifier} (${
            OTHER_ID_QUALIFIER[originalOtherIdQualifier]
          }) - ${originalOtherId}`,
          value: {
            [`${key}OtherIdQualifier`]: originalOtherIdQualifier,
            [`${key}OtherId`]: originalOtherId,
          },
          name,
        }
      : null;

  const currentOption =
    currentProviderOtherIdQualifier && currentProviderOtherId
      ? {
          label: `${currentProviderOtherIdQualifier} (${
            OTHER_ID_QUALIFIER[currentProviderOtherIdQualifier]
          }) - ${currentProviderOtherId}`,
          value: {
            [`${key}OtherIdQualifier`]: currentProviderOtherIdQualifier,
            [`${key}OtherId`]: currentProviderOtherId,
          },
          name,
        }
      : null;

  if (originalOption && !equal(originalOption, currentOption)) {
    previousOptions.push(originalOption);
  }

  if (currentOption) {
    previousOptions.push(currentOption);
  }

  return previousOptions;
};
