import '../neb-popup-header';
import '../neb-selection-card';
import '../inputs/neb-textfield';
import '../guarantor/neb-guarantor-form-controller';

import FormService from '@neb/form-service';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { createUser } from '../../../../../src/api-clients/registry';
import { PROVIDER_CARD_DISABLED_WARNING } from '../../../../../src/utils/user-message';
import {
  saveImage,
  getImage,
  deleteImage,
} from '../../../../neb-api-client/src/image-api-client';
import { getPayerPlans } from '../../../../neb-api-client/src/payer-plan-api-client';
import {
  createProviderUser,
  updateProviderUser,
  createStaffUser,
  updateStaffUser,
  createSpecialistUser,
  updateSpecialistUser,
} from '../../../../neb-api-client/src/permissions-api-client';
import { getProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { COGNITO_TYPES } from '../../../../neb-utils/cognito-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBetaSync,
} from '../../../../neb-utils/feature-util';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import { number } from '../../../../neb-utils/masks';
import NebIdentifier from '../field-groups/neb-identifier';
import NebFormUserProvider from '../forms/neb-form-user-provider';
import NebUserSpecialistForm from '../forms/neb-form-user-specialist';
import NebUserStaffForm from '../forms/neb-form-user-staff';
import { PERMISSIONS } from '../permissions/neb-permissions';

import Overlay, { ELEMENTS as BASE_ELEMENTS } from './neb-overlay';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  header: {
    id: 'header',
  },
  staffCard: {
    id: 'staff-card',
  },
  staffForm: {
    id: 'staff-form',
  },
  providerCard: {
    id: 'provider-card',
  },
  providerInput: {
    id: 'input-npi',
  },
  providerForm: {
    id: 'provider-form',
  },
  specialistCard: {
    id: 'specialist-card',
  },
  specialistForm: {
    id: 'specialist-form',
  },
};

const STAFF_TITLE = 'Add Staff';
const PROVIDER_TITLE = 'Add Provider';
const SPECIALIST_TITLE = 'Add Specialist';
const INPUT_PLACEHOLDER = 'NPI';
const SECONDARY_HEADER = 'Choose a new user type.';
const PROFILE_IMAGE_PATH = 'profile-image';
export const BANNER_SUCCESS = 'saved successfully';
export const BANNER_ERROR_GENERIC = 'An error occurred when saving the';
export const BANNER_ERROR_EMAIL =
  'A user with this email address already exists.';
export const ADD_HEADER = 'Add New User';
export const UPDATE_HEADER = 'Update User';
const MAP_USER_TYPE = {
  staff: 'Staff',
  provider: 'Provider',
  specialist: 'Specialist',
};

export const PROVIDER_DISABLED_POPUP_TEXT = {
  title: 'Add Provider',
  message: PROVIDER_CARD_DISABLED_WARNING(),
};

const FORCE_NPI = true;

class NebOverlayUser extends Overlay {
  static get properties() {
    return {
      __formModel: {
        type: Object,
      },
      __locations: { type: Array },
      __activeLocations: { type: Array },
      __npi: {
        type: String,
      },
      __npiError: {
        type: String,
      },
      __payerItems: {
        type: Array,
      },
      __providerItems: {
        type: Array,
      },
      __superUser: {
        type: Boolean,
      },
      __userType: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
    this.initServices();
  }

  initState() {
    super.initState();
    this.__userType = 'Initial';
    this.__npi = '';
    this.__formModel = {};
    this.__superUser = false;
    this.__npiError = '';
    this.__payerItems = [];
    this.__providerItems = [];
    this.__locations = [];
    this.__activeLocations = [];
    this.__config = {
      Staff: {
        header: `${ADD_HEADER} - Staff`,
        bannerSuccess: `Staff ${BANNER_SUCCESS}`,
        bannerError: `${BANNER_ERROR_GENERIC} staff`,
        render: () => this.__renderStaff(),
        create: model => this.__createUser(model),
        update: model => updateStaffUser(model),
      },
      Provider: {
        header: `${ADD_HEADER} - Provider`,
        bannerSuccess: `Provider ${BANNER_SUCCESS}`,
        bannerError: `${BANNER_ERROR_GENERIC} provider`,
        render: () => this.__renderProvider(),
        create: model => this.__createUser(model),
        update: model => updateProviderUser(model),
      },
      Specialist: {
        header: `${ADD_HEADER} - Specialist`,
        bannerSuccess: `Specialist ${BANNER_SUCCESS}`,
        bannerError: `${BANNER_ERROR_GENERIC} specialist`,
        render: () => this.__renderSpecialist(),
        create: model => this.__createUser(model),
        update: model => updateSpecialistUser(model),
      },
      Initial: {
        header: ADD_HEADER,
        render: () => this.__renderCards(),
      },
    };

    const {
      session: {
        item: { type: cognitoType },
      },
    } = store.getState();

    this.__cognitoType = cognitoType;

    const disableProviderActions =
      this.__cognitoType !== COGNITO_TYPES.SUPPORT &&
      !hasFeatureOrBetaSync(FEATURE_FLAGS.ENABLE_PROVIDER_ACTIONS);

    this.__configCard = {
      provider: {
        id: ELEMENTS.providerCard.id,
        title: PROVIDER_TITLE,
        icon: 'userProvider',
        handler: 'addProviderHandler',
        content: () => this.__renderProviderCardContent(),
        ...(disableProviderActions && {
          disabled: true,
          handler: 'disabledProviderHandler',
          content: () => this.__renderDisabledProviderCardContent(),
        }),
      },
      specialist: {
        id: ELEMENTS.specialistCard.id,
        title: SPECIALIST_TITLE,
        icon: 'userSpecialist',
        handler: 'addSpecialistHandler',
        content: () => this.__renderSpecialistCardContent(),
      },
      staff: {
        id: ELEMENTS.staffCard.id,
        title: STAFF_TITLE,
        icon: 'userStaff',
        handler: 'addStaffHandler',
        content: () => this.__renderStaffCardContent(),
      },
    };
  }

  __getLocations(active = false) {
    const locations = this.__locations.length
      ? this.__locations
      : this.model.context.locations;

    if (!active) return locations;
    return this.__activeLocations.length
      ? this.__activeLocations
      : locations.filter(({ active: isLocationActive }) => isLocationActive);
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addStaffHandler: () => {
        this.__userType = 'Staff';
        this.__formModel = {
          ...NebUserStaffForm.createModel(),
          superUser: this.__superUser,
          locations: this.__getLocations(),
          type: 'staff',
        };
      },
      addProviderHandler: () => {
        const npi = {
          name: 'NPI',
          value: this.__npi,
        };

        this.handlers.change(npi);

        if (this.__validateNPI()) {
          this.__userType = 'Provider';
          this.__formModel = {
            ...NebFormUserProvider.createModel(),
            NPI: this.__npi,
            superUser: this.__superUser,
            substituteProviders: [],
            locations: this.__getLocations(),
            type: 'provider',
          };

          this.__getPayers();
          this.__getProviders();
        } else {
          this.handlers.change(npi);
        }
      },
      disabledProviderHandler: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, PROVIDER_DISABLED_POPUP_TEXT),
      addSpecialistHandler: () => {
        this.__userType = 'Specialist';
        this.__formModel = {
          ...NebUserSpecialistForm.createModel(),
          superUser: this.__superUser,
          locations: this.__getLocations(),
          type: 'specialist',
        };

        this.__getPayers();
        this.__getProviders();
      },
      clickBlocker: e => {
        // TODO cgenest - Update clickBlocker to not need this override.
        if (e.target.id === ELEMENTS.container.id) {
          this.dismiss(undefined, false);
        }
      },
      clickInput: e => e.stopPropagation(),
      onSave: model => this.__saveUser(model),
      photoInvalid: err => {
        store.dispatch(openError(err));
      },
      change: e => this.__formService.apply(e.name, e.value),
    };
  }

  initServices() {
    this.__formService = new FormService(
      { NPI: '' },
      { children: NebIdentifier.NPISelector(FORCE_NPI) },
      (_, state, errors) => {
        this.__npi = state.NPI;
        this.__npiError = errors.NPI;
      },
    );

    this.__locationsService = new LocationsService(
      ({ activeLocations, locations }) => {
        this.__activeLocations = activeLocations;
        this.__locations = locations;
      },
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  __createUser(model) {
    const {
      session: {
        item: { type: cognitoType },
      },
    } = store.getState();

    if (cognitoType === COGNITO_TYPES.SUPPORT && this.__superUser) {
      model = { ...model, type: this.__userType.toLowerCase() };
      return createUser(model);
    }

    switch (this.__userType) {
      case MAP_USER_TYPE.provider:
        return createProviderUser(model);
      case MAP_USER_TYPE.specialist:
        return createSpecialistUser(model);
      default:
        return createStaffUser(model);
    }
  }

  async __saveUser(model) {
    const { create, update, bannerSuccess, bannerError } =
      this.__config[this.__userType];

    try {
      if (this.__cognitoType === COGNITO_TYPES.SUPPORT && this.model.item) {
        delete model.photo;
        model.substituteProviders = this.model.item.substituteProviders;
      }

      const result = model.id ? await update(model) : await create(model);

      if (model.photo && model.photo.imageUrl) {
        this.__handleUserPhoto(result.id, model.photo);
      }

      this.isDirty = false;
      this.dismiss(result);
      store.dispatch(openSuccess(bannerSuccess));
    } catch (e) {
      console.log(e);
      this.__handleErrorBanner(e.statusCode, bannerError);
    }
  }

  __handleUserPhoto(parentId, { src, imageUrl }) {
    const payload = { parentId };

    return src
      ? saveImage(PROFILE_IMAGE_PATH, src, { ...payload, imageUrl })
      : deleteImage(PROFILE_IMAGE_PATH, payload);
  }

  __handleErrorBanner(statusCode, bannerError) {
    if (statusCode === 409) {
      store.dispatch(openError(BANNER_ERROR_EMAIL));
    } else {
      store.dispatch(openError(bannerError));
    }
  }

  __validateNPI() {
    const reg = /^[0-9]+$/;

    return this.__npi.length === 10 && reg.test(this.__npi);
  }

  __getTitle() {
    return this.model.item
      ? `${UPDATE_HEADER} - ${this.__userType}`
      : `${this.__config[this.__userType].header}`;
  }

  async __getPayers() {
    if (this.__cognitoType === COGNITO_TYPES.SUPPORT) {
      this.__payerItems = [];
    } else {
      const { payerPlan } = await getPayerPlans({
        search: '',
      });

      this.__payerItems = payerPlan.map(item => ({
        label: `(${item.alias}) ${item.payerName}${
          item.status === 'Inactive' ? ' (Inactive)' : ''
        }`,
        data: item,
      }));
    }
  }

  async __getProviders() {
    if (this.__cognitoType === COGNITO_TYPES.SUPPORT) {
      this.__providerItems = [];
    } else {
      const providers = (await getProviderUsers()).filter(
        provider => provider.id !== this.__formModel.id,
      );

      this.__providerItems = providers.map(item => ({
        label: `${objToName(item.name, DEFAULT_NAME_OPTS)}${
          !item.active ? ' (Inactive)' : ''
        }`,
        data: item,
      }));
    }
  }

  __updateFormModel() {
    const model = this.model.item;

    let permissions = PERMISSIONS.map(x => ({ ...x }));

    if (model.permissions && model.permissions.length > 0) {
      for (let i = 0; i < model.permissions.length; i += 1) {
        const userPermssion = model.permissions[i];
        permissions = permissions.map(x =>
          x.name === userPermssion.name
            ? { ...x, access: userPermssion.access }
            : x,
        );
      }
    } // TODO: remove once API implemented

    if (!Object.prototype.hasOwnProperty.call(model, 'superUser')) {
      model.superUser = false;
    }

    this.__formModel = {
      ...model,
      permissions,
    };
  }

  __formatItems(items) {
    return items.map(item => ({ data: item, label: item.name }));
  }

  update(changed) {
    if (changed.has('model')) {
      if (this.model.item) {
        this.__userType = MAP_USER_TYPE[this.model.item.type];
        this.__updateFormModel();

        if (
          this.__userType === MAP_USER_TYPE.provider ||
          this.__userType === MAP_USER_TYPE.specialist
        ) {
          this.__getPayers();
          this.__getProviders();
        }
      }

      if (this.model.context && this.model.context.superUser) {
        this.__superUser = this.model.context.superUser;
      }
    }

    super.update(changed);
  }

  async updated(changed) {
    if (changed.has('model') && this.model.item) {
      if (
        this.__cognitoType !== COGNITO_TYPES.SUPPORT &&
        this.model.item.photo.imageUrl
      ) {
        const { id, photo } = this.model.item;
        const { imageUrl } = photo;
        photo.src = await getImage({
          id,
          imageUrl,
        });

        this.__formModel = { ...this.__formModel, photo };
      }

      if (this.model.item.photo.src === undefined) {
        const { photo } = this.model.item;
        photo.src = '';

        this.__formModel = { ...this.__formModel, photo };
      }

      if (this.model.item.taxonomy.label === undefined) {
        const { taxonomy } = this.model.item;
        taxonomy.label = '';

        this.__formModel = { ...this.__formModel, taxonomy };
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .secondary-header {
          padding: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }

        .content {
          display: flex;
          width: 800px;
          flex-flow: column nowrap;
        }

        .card {
          padding: 20px;
        }

        .user-content {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  __formatLocations(active = false) {
    const locations = this.__getLocations(active);
    return this.__formatItems(locations);
  }

  __renderStaff() {
    return html`
      <neb-form-user-staff
        id="${ELEMENTS.staffForm.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .locations="${this.__formatLocations()}"
        .activeLocations="${this.__formatLocations(true)}"
        .onSave="${this.handlers.onSave}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-form-user-staff>
    `;
  }

  __renderProvider() {
    return html`
      <neb-form-user-provider
        id="${ELEMENTS.providerForm.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .locations="${this.__formatLocations()}"
        .activeLocations="${this.__formatLocations(true)}"
        .providerItems="${this.__providerItems}"
        .payerItems="${this.__payerItems}"
        .onSave="${this.handlers.onSave}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onInvalidPhoto="${this.handlers.photoInvalid}"
      ></neb-form-user-provider>
    `;
  }

  __renderSpecialist() {
    return html`
      <neb-form-user-specialist
        id="${ELEMENTS.specialistForm.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .locations="${this.__formatLocations()}"
        .activeLocations="${this.__formatLocations(true)}"
        .providerItems="${this.__providerItems}"
        .payerItems="${this.__payerItems}"
        .onSave="${this.handlers.onSave}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-form-user-specialist>
    `;
  }

  __renderProviderCardContent() {
    return html`
      <p>
        The Provider role is a user who provides care to patients, and who has
        an NPI number.
        <b
          >Providers can sign encounters and bill insurance for their
          services</b
        >.
      </p>
      <neb-textfield
        id="${ELEMENTS.providerInput.id}"
        label="${INPUT_PLACEHOLDER}"
        maxLength="10"
        helper="Required"
        name="NPI"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .error="${this.__npiError}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderDisabledProviderCardContent() {
    return html`
      <p>
        The Provider role is a user who provides care to patients, and who has
        an NPI number.
        <b
          >To add a new Provider user to your account, please contact ChiroTouch
          Support, who can assist you with setting up an Additional Provider
          subscription.</b
        >.
      </p>
    `;
  }

  __renderSpecialistCardContent() {
    return html`
      <p>
        The Specialist role is a user who provides care to patients, but does
        not have an NPI number.
        <b
          >Specialists can sign encounters, but cannot bill insurance for their
          services</b
        >.
      </p>
    `;
  }

  __renderStaffCardContent() {
    return html`
      <p>
        The Staff role is a user who performs non-patient-care related services
        such as scheduling, front desk, billing, etc.
        <b>Staff cannot sign encounters</b>.
      </p>
    `;
  }

  __renderCard(card) {
    return html`
      <neb-selection-card
        id="${card.id}"
        class="card"
        .title="${card.title}"
        .icon="${card.icon}"
        ?disabled="${card.disabled}"
        @click="${this.handlers[card.handler]}"
        icon-big
      >
        ${card.content()}
      </neb-selection-card>
    `;
  }

  __renderCards() {
    return html`
      <div class="secondary-header">${SECONDARY_HEADER}</div>
      ${Object.keys(this.__configCard).map(key => {
        const card = this.__configCard[key];
        return this.__renderCard(card);
      })}
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.__getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <div class="user-content">${this.__config[this.__userType].render()}</div>
    `;
  }
}

customElements.define('neb-overlay-user', NebOverlayUser);
