/* eslint-disable complexity */
import '../../neb-popup-header';
import '../../patients/neb-patient-summary-controller';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import * as feeSchedulesApi from '../../../../../../src/api-clients/fee-schedules';
import {
  getPatientPackageUsedCounts,
  getPatientPackages,
} from '../../../../../../src/api-clients/patient-package';
import { repostPostedEncounterCharges } from '../../../../../../src/utils/check-in-out';
import {
  SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS,
  SAVE_ENCOUNTER_SUCCESS,
  PATIENT_INSURANCE_VISIT_LIMIT_NEAR_OR_AT_LIMIT,
  PATIENT_INSURANCE_VISIT_LIMIT_EXCEEDED,
  UPDATE_ENCOUNTER_DATE_OF_ONSET_SUCCESS,
  UPDATE_ENCOUNTER_DATE_OF_ONSET_ERROR,
} from '../../../../../../src/utils/user-message';
import {
  createBannerController,
  DISPLAY_ON,
} from '../../../../../neb-alert/components/neb-alert-banner-controller';
import { getAppointmentBillingInfo } from '../../../../../neb-api-client/src/appointment-billing-info-api-client';
import { getEncounterCharges } from '../../../../../neb-api-client/src/charting/encounter-charge';
import * as encounterApi from '../../../../../neb-api-client/src/encounters-api-client';
import { formatGuarantors } from '../../../../../neb-api-client/src/formatters/guarantor';
import { getLineItemDetails } from '../../../../../neb-api-client/src/ledger/line-items';
import {
  mapAppointmentBillingInfoToServerModel,
  APPOINTMENT_BILLING_TYPE,
} from '../../../../../neb-api-client/src/mappers/appointment-billing-info-mapper';
import {
  BILL_TYPES,
  mapToPatientCaseName,
} from '../../../../../neb-api-client/src/mappers/patient-case-mapper';
import { getNextAppointment } from '../../../../../neb-api-client/src/nextAppointment-api-client';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import * as caseApi from '../../../../../neb-api-client/src/patient-cases';
import * as patientFeeSchedulesApi from '../../../../../neb-api-client/src/patient-fee-schedules';
import { getPatientGuarantors } from '../../../../../neb-api-client/src/patient-guarantor-api-client';
import {
  getMaxVisits,
  getPatientInsurances,
} from '../../../../../neb-api-client/src/patient-insurance-api-client';
import {
  getChartingPermissions,
  getPracticeUser,
} from '../../../../../neb-api-client/src/permissions-api-client';
import { fetchManyRooms } from '../../../../../neb-api-client/src/rooms-api-client';
import createEncounter from '../../../../../neb-api-client/src/services/encounter/create-encounter';
import {
  getPracticeSettings,
  PRACTICE_SETTINGS,
} from '../../../../../neb-api-client/src/services/practice-settings';
import { getTreatmentPlan } from '../../../../../neb-api-client/src/treatment-plans';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { AppointmentStoreService } from '../../../../../neb-redux/services/appointment-store';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../neb-utils/date-util';
import { CARE_PACKAGE_STATUS } from '../../../../../neb-utils/enums';
import {
  mapEncounterCharge,
  mapToMenuItems,
  mapToModel,
} from '../../../../../neb-utils/fee-schedule';
import { objToName } from '../../../../../neb-utils/formatters';
import { fetchPracticeUsers } from '../../../../../neb-utils/neb-ledger-util';
import { buildPatientName } from '../../../../../neb-utils/neb-patient-name-util';
import { mapPatientName } from '../../../../../neb-utils/patient';
import { filterAuthorizationsFromCases } from '../../../../../neb-utils/patientAuthorization';
import { rawPatientInsuranceVisitLimitToModel } from '../../../../../neb-utils/patientInsurance';
import {
  getDescriptionLines,
  getCarePackageStatus,
  getSharedCarePackageHistory,
  getActivePatientPackages,
} from '../../../../../neb-utils/patientPackage';
import {
  AppointmentBillingInfoDefaultsValidator,
  createModelBillingInfo,
} from '../../../utils/appointment-billing-info-defaults-validator';
import { NebFormCheckInOut } from '../../scheduling/neb-form-check-in-out';
import nebFormCheckInOutService from '../../scheduling/neb-form-check-in-out-service';
import Overlay from '../neb-overlay';

export const MODE = Object.freeze({
  checkIn: 'checkIn',
  checkOut: 'checkOut',
});

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  formController: {
    id: 'form-controller',
  },
  patientSummary: {
    id: 'patient-summary',
  },
};

const NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

const ASSUMED_VISITS_TODAY = 1;

const CASE_BILL_TYPE_MAPPER = {
  [BILL_TYPES.SELF]: 'selfPay',
  [BILL_TYPES.INSURANCE]: 'insurance',
  [BILL_TYPES.CARE_PACKAGE]: 'carePackage',
};

export const SELF_GUARANTOR = {
  id: null,
  label: 'Self',
  default: false,
  active: true,
};

export const EMPTY_PACKAGE = {
  label: '',
  data: {
    id: null,
    descriptionLines: [],
    isDefault: false,
  },
};
class NebOverlayCheckInOut extends Overlay {
  static get properties() {
    return {
      __savingError: Boolean,
      __cases: Array,
      __authorizations: Array,
      __feeScheduleItems: Array,
      __allFeeScheduleItems: Array,
      __guarantors: Array,
      __insuranceItems: Array,
      __nextAppointment: Object,
      __appointmentStart: Date,
      __appointmentModel: Object,
      __activePackages: Array,
      __packages: Array,
      __allPackages: Array,
      __charges: Array,
      __usedCounts: Array,
      __codes: Array,
      __lineItems: Array,
      __balance: Number,
      __rooms: Array,
      __encounterCharges: Array,
      __isLoading: Boolean,
      __updateScheduleRoom: Boolean,
      __chartingPermission: Boolean,
      __createEncounterAtCheckIn: Boolean,
      __forceEncounterCreation: Boolean,
      __patientInsuranceVisitLimit: Object,
      __treatmentPlanPhases: Array,
      __appointmentBillingInfo: Object,
      __serverAppointmentBillingInfo: Object,
      __carePackageWithInsuranceEnabled: Boolean,
      __practiceSettings: Object,
      __initialAppointmentModel: Object,
    };
  }

  initState() {
    super.initState();

    this.__savingError = false;
    this.__appointmentModel = NebFormCheckInOut.createModel();
    this.__initialAppointmentModel = NebFormCheckInOut.createModel();
    this.__apiCases = [];
    this.__cases = [];
    this.__authorizations = [];
    this.__checkInBanner = createBannerController(DISPLAY_ON.checkIn);
    this.__checkOutBanner = createBannerController(DISPLAY_ON.checkOut);
    this.__feeScheduleItems = [];
    this.__allFeeScheduleItems = [];
    this.__guarantors = [];
    this.__insuranceItems = [];
    this.__nextAppointment = {};
    this.__appointmentStart = null;
    this.__packages = [];
    this.__allPackages = [];
    this.__charges = [];
    this.__codes = [];
    this.__lineItems = [];
    this.__rooms = [];
    this.__updateScheduleRoom = false;
    this.__serverAppointmentBillingInfo = null;
    this.__appointmentBillingInfo = {
      ...createModelBillingInfo(),
      billType: 'selfPay',
    };

    this.__encounterCharges = [];
    this.__isLoading = false;

    this.__appointmentStoreService = new AppointmentStoreService();
    this.__chartingPermission = false;
    this.__createEncounterAtCheckIn = false;
    this.__forceEncounterCreation = false;
    this.__treatmentPlanPhases = [];

    this.__patientInsuranceVisitLimit = null;
    this.__carePackageWithInsuranceEnabled = false;
    this.__practiceSettings = {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addGuarantor: () => this.__addGuarantor(),
      save: ({ model, shouldRepost }) => this.__save({ model, shouldRepost }),
      repostPostedEncounterCharges: async ({ model, shouldRepost }) => {
        await this.__handleRepostPostedCharges({
          model,
          shouldRepost,
        });
      },
      updateAppointment: appointment =>
        this.__setAppointmentIdHackForRecurringPrePayment(appointment),
      refresh: ({ isAddingCase = false } = {}) => this.__refresh(isAddingCase),
      authorizationDetailChanged: () => {
        this.__loadCases(this.model.patientId);
      },
      providerChanged: providerId => this.__handleProvider(providerId),
      createEncounterChanged: value =>
        this.__handleCreateEncounterAtCheckIn(value),
      guarantorsChanged: () => this.__loadGuarantors(this.model.patientId),
      insurancesChanged: () => this.__loadInsurances(this.model.patientId),
      packageTemplatesChanged: () =>
        this.__loadPackages(this.model.patientId, this.model.appointment.start),
      feeSchedulesChanged: () => this.__loadFeeSchedules(this.model.patientId),
      cancel: () =>
        this.dismiss({
          success: true,
          cancelled: true,
          model: {
            id: this.__getAppointmentIdHackForRecurringAppointmentPrePayment(),
          },
        }),
      updateLoading: (isLoading = true) => {
        this.__isLoading = isLoading;
      },
      updateEncounterCurrentIllnessDate: async ({ success, model }) => {
        await this.__syncEncounterDateOfOnsetWithCase({ success, model });
        this.__initialAppointmentModel = { ...model };
      },
    };
  }

  async __syncEncounterDateOfOnsetWithCase({ success, model }) {
    if (
      success &&
      !equal(this.__initialAppointmentModel.caseId, model.caseId)
    ) {
      try {
        await encounterApi.updateEncounterCurrentIllnessDateWithCaseDOO(
          model.encounterId,
          {
            patientCaseId: model.caseId,
          },
        );

        await store.dispatch(
          openSuccess(UPDATE_ENCOUNTER_DATE_OF_ONSET_SUCCESS),
        );
      } catch (e) {
        console.log('e :>> ', e);

        await store.dispatch(openError(UPDATE_ENCOUNTER_DATE_OF_ONSET_ERROR));
      }
    }
  }

  async __save({ model, shouldRepost = false }) {
    this.__savingError = false;

    const billingInfo = mapAppointmentBillingInfoToServerModel(
      model.billingInfo,
      model.id,
    );

    const room = this.__rooms.find(r => r.id === model.roomId);

    const appointment = {
      id: this.__getAppointmentIdHackForRecurringAppointmentPrePayment(),
      appointmentTypeId: model.appointmentTypeId,
      providerId: model.providerId,
      roomId: model.roomId,
      resourceId: this.__shouldUpdateResourceId(room)
        ? model.roomId
        : model.resourceId,
      caseId: model.caseId,
      patientAuthorizationId: model.patientAuthorizationId,
      note: model.note,
      billingInfo,
      locationId: model.locationId,
      invoiceIds: model.invoiceIds,
    };

    const method = this.model.mode === MODE.checkOut ? 'checkOut' : 'checkIn';

    const result = await this.__appointmentStoreService[method](appointment);

    await this.__handleRepostPostedCharges({
      model,
      shouldRepost,
    });

    if (model.encounterId) {
      const updateSuccess = await this.__updateEncounter(model);

      await this.handlers.updateEncounterCurrentIllnessDate({
        success: updateSuccess && result.success,
        model,
      });
    }

    await this.__handleSaveResult(result, model);
  }

  async createEncounterFromCheckIn(providerId, appointmentId) {
    const selectedProvider = store
      .getState()
      .providers.item.find(provider => provider.id === providerId);

    const currentUser = store.getState().session.item.id;

    const encounterResponse = await createEncounter(
      appointmentId,
      currentUser,
      `${selectedProvider.name.last}, ${selectedProvider.name.first}`,
    );

    if (encounterResponse) {
      const banner = encounterResponse.res.withAutoSalt
        ? SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS
        : SAVE_ENCOUNTER_SUCCESS;
      store.dispatch(openSuccess(banner));
    } else store.dispatch(openError('Error creating the encounter'));

    return encounterResponse;
  }

  async __handleRepostPostedCharges({ model, shouldRepost }) {
    if (model && model.encounterId) {
      if (shouldRepost) {
        await repostPostedEncounterCharges({
          model,
          signed: this.model.appointment.encounter
            ? this.model.appointment.encounter.signed
            : false,
          charges: model.charges,
          billingEncounterCharges: this.__encounterCharges,
          pristineCharges: this.__charges,
          diagnoses: model.codes,
          diagnosesUpdated: !equal(this.__codes, model.codes),
        });

        await this.__refresh();
      }
    }
  }

  __shouldUpdateResourceId(room) {
    return this.__updateScheduleRoom && room && room.scheduleAvailable;
  }

  __getAppointmentIdHackForRecurringAppointmentPrePayment() {
    return this.__appointmentIdHack || this.model.appointment.id;
  }

  __setAppointmentIdHackForRecurringPrePayment(appointment) {
    this.__appointmentIdHack = appointment.id;
  }

  __getTitle() {
    return this.model.mode === MODE.checkOut ? 'Check Out' : 'Check In';
  }

  __loadAppointmentModelData(applyBillingInfoDefaults = true) {
    const { appointment, patientId } = this.model;

    const appointmentModel = {
      id: appointment.id,
      patientId,
      patient: appointment.patient,
      rrule: appointment.rrule ? appointment.rrule : null,
      encounterId: appointment.encounter ? appointment.encounter.id : null,
      appointmentTypeId: appointment.appointmentTypeId,
      caseId: appointment.caseId ? appointment.caseId : null,
      patientAuthorizationId: appointment.patientAuthorizationId
        ? appointment.patientAuthorizationId
        : null,
      providerId: appointment.providerId,
      note: appointment.note,
      details: { ...appointment.details },
      billingInfo: { ...this.__appointmentBillingInfo },
      locationId: appointment.locationId,
      roomId: appointment.roomId,
      resourceId: appointment.resourceId,
      signed: appointment.encounter ? appointment.encounter.signed : false,
      selfCheckIn: appointment.selfCheckIn ? appointment.selfCheckIn : false,
      start: appointment.start,
    };

    if (
      applyBillingInfoDefaults &&
      !this.__appointmentBillingInfo.id &&
      this.model.mode === MODE.checkIn
    ) {
      this.__applyBillingInfoDefaults(appointmentModel, appointment.start);
    }

    const updatedCharges = this.__charges.map(ch => {
      let updatedCharge = ch;

      if (ch.postedToLedgerId) {
        const lineItem = this.__lineItems.find(
          li => li.encounterCharge.id === ch.postedToLedgerId,
        );

        if (lineItem) {
          updatedCharge = {
            ...ch,
            billedAmount: lineItem.allowedAmount,
          };
        }
      }

      return mapEncounterCharge(updatedCharge, this.__getFeeSchedule());
    });

    this.__charges = updatedCharges;

    this.__appointmentModel = {
      ...appointmentModel,
      charges: this.__charges,
      codes: this.__codes,
    };
  }

  async __loadEncounterCharges(charges, encounterId) {
    const { encounterCharges } = await nebFormCheckInOutService({
      charges,
      encounterId,
    });
    this.__encounterCharges = encounterCharges;
  }

  async __loadData() {
    const { patientId, appointment } = this.model;
    const encounterId = appointment.encounter ? appointment.encounter.id : null;

    const users = await fetchPracticeUsers();
    const { id } = store.getState().session.item;
    const currentUser = users.find(user => user.data.id === id);
    this.__chartingPermission = currentUser.data.permissions.find(
      p => p.name === 'charting',
    ).access;

    await this.__loadPracticeSettings();

    await this.__loadEncounterDiagnoses(encounterId);
    await Promise.all([
      this.__loadCases(patientId),
      this.__loadFeeSchedules(patientId),
      this.__loadAllFeeSchedules(),
      this.__loadGuarantors(patientId),
      this.__loadNextAppointment(encounterId),
      this.__loadInsurances(patientId),
      this.__loadLineItems(encounterId),
      this.__loadPackages(patientId, appointment.start),
      this.__loadAppointmentBillingInfo(appointment.id),
      this.__loadRooms(),
      this.__loadMaxVisits(patientId),
      this.__loadTreatmentPlan(),
      this.__loadEncounterChargesFromCharting(encounterId),
    ]);

    this.__loadAppointmentModelData();

    await this.__loadEncounterCharges(this.__charges, encounterId);
    this.__isLoading = false;
  }

  __syncPatientCaseAndAuthorizationId() {
    const { caseId: modelCaseId, patientAuthorizationId: modelAuthId } =
      this.__appointmentModel;
    const {
      patientCaseId: serverCaseId,
      patientAuthorizationId: serverAuthId,
    } = this.__appointmentBillingInfo;

    if (!equal(modelCaseId, serverCaseId)) {
      this.__appointmentModel.caseId = serverCaseId;
    }

    if (!equal(modelAuthId, serverAuthId)) {
      this.__appointmentModel.patientAuthorizationId = serverAuthId;
    }
  }

  async __refresh(isAddingCase = false) {
    const { patientId, appointment } = this.model;
    const encounterId = appointment.encounter ? appointment.encounter.id : null;

    await this.__loadEncounterDiagnoses(encounterId);
    await Promise.all([
      this.__loadEncounterChargesFromCharting(encounterId),
      this.__loadCases(patientId),
      this.__loadAppointmentBillingInfo(appointment.id),
      this.__loadLineItems(encounterId),
      this.__loadEncounterCharges(this.__charges, encounterId),
    ]);

    if (!isAddingCase) this.__loadAppointmentModelData(false);
    this.__syncPatientCaseAndAuthorizationId();

    if (
      this.__appointmentBillingInfo.billType ===
      APPOINTMENT_BILLING_TYPE.CarePackage
    ) {
      await this.__loadPackages(patientId, appointment.start);
    }
  }

  async __loadLineItems(encounterId) {
    if (encounterId) {
      this.__lineItems = await getLineItemDetails({ encounterId });
    }
  }

  async __loadEncounterDiagnoses(encounterId) {
    if (!encounterId) {
      return;
    }

    this.__codes = (await encounterApi.getEncounterDiagnoses(encounterId)).map(
      diag => ({
        ...diag,
        label: `${diag.code} - ${diag.shortDescription}`,
      }),
    );
  }

  async __loadCases(patientId) {
    const allApiCases = await caseApi.fetchMany(patientId);
    this.__apiCases = allApiCases.filter(c => c.active === true);

    this.__cases = allApiCases.map(c => ({
      id: c.id,
      label: mapToPatientCaseName(c.name, c.onsetSymptomsDate),
      primaryPayer: c.primaryPayer,
      payerInsurance: { ...c.payerInsurance },
      billType: CASE_BILL_TYPE_MAPPER[c.billType],
      guarantorId: c.guarantorId || null,
      isDefault: c.isDefault,
      patientPackageId: c.patientPackageId || null,
      active: c.active,
    }));

    this.__authorizations = filterAuthorizationsFromCases(this.__apiCases);
  }

  async __getSharedPackageOwner(carePackage) {
    if (
      this.model.appointment?.patient &&
      carePackage.patientId === this.model.appointment.patient.id
    ) {
      const name = mapPatientName(this.model.appointment.patient);

      return objToName(name, NAME_OPTS);
    }

    const owner = await patientApiClient.fetchOne(carePackage.patientId);

    return objToName(owner.name, NAME_OPTS);
  }

  async __loadPackages(patientId, appointmentStart) {
    const buildPackages = async (packageItems, usedCountsList) => {
      const packages = await Promise.all(
        packageItems.map(async packageItem => {
          const ownerName = await this.__getSharedPackageOwner(packageItem);

          return {
            data: {
              id: packageItem.id,
              isDefault: !!packageItem.isDefault,
              descriptionLines: getDescriptionLines(
                { ...packageItem, ownerName },
                usedCountsList.find(
                  usage => usage.patientPackageId === packageItem.id,
                ),
              ),
              active: packageItem.active,
              charges: packageItem.charges,
            },
            label: packageItem.name,
          };
        }),
      );

      return [EMPTY_PACKAGE, ...packages];
    };

    // TODO: convert care package to use timezone correctly
    const rawDate = appointmentStart.toDate();
    this.__appointmentStart = rawDate;

    const rawPackages = await getPatientPackages(patientId, {
      includeShared: true,
    });
    const activePackages = await getActivePatientPackages(patientId);

    this.__allPackages = rawPackages;

    let usedCounts = [];
    let validActivePackages = [];

    if (rawPackages.length > 0) {
      usedCounts = await getPatientPackageUsedCounts(
        patientId,
        rawPackages.map(pkg => pkg.id),
        rawDate,
      );

      validActivePackages = activePackages.filter(p => {
        const carePackageStatus = getCarePackageStatus(p, rawDate);

        if (p.patientId === this.model.patientId) {
          return carePackageStatus === CARE_PACKAGE_STATUS.VALID;
        }

        const sharedCarePackageHistory = getSharedCarePackageHistory(
          p,
          [rawDate],
          this.model.patientId,
        );

        return (
          carePackageStatus === CARE_PACKAGE_STATUS.VALID &&
          sharedCarePackageHistory
        );
      });
    }

    this.__usedCounts = usedCounts;

    [this.__packages, this.__activePackages] = await Promise.all([
      buildPackages(rawPackages, usedCounts),
      buildPackages(validActivePackages, usedCounts),
    ]);

    await this.requestUpdate();
  }

  async __loadNextAppointment(encounterId) {
    if (encounterId) {
      const nextAppointment = await getNextAppointment(encounterId);

      if (nextAppointment) {
        this.__nextAppointment = nextAppointment;
      }
    }
  }

  async __loadAppointmentBillingInfo(appointmentId) {
    const appointmentBillingInfo =
      await getAppointmentBillingInfo(appointmentId);

    this.__serverAppointmentBillingInfo = appointmentBillingInfo;

    if (appointmentBillingInfo) {
      this.__appointmentBillingInfo = appointmentBillingInfo;
    }
  }

  async __loadFeeSchedules(patientId) {
    const feeSchedules = await patientFeeSchedulesApi.fetchMany(patientId);
    const mappedFeeSchedules = feeSchedules.map(f => mapToModel(f));

    this.__feeScheduleItems = mapToMenuItems(mappedFeeSchedules);
    this.__feeScheduleItems.unshift({ label: '', data: { id: null } });
  }

  async __loadAllFeeSchedules() {
    const allFeeSchedules = await feeSchedulesApi.fetchManyV4();

    this.__allFeeScheduleItems = mapToMenuItems(allFeeSchedules);
  }

  async __handleProvider(providerId) {
    const encounterId = this.model.appointment.encounter
      ? this.model.appointment.encounter.id
      : null;

    if (!encounterId) {
      const { createEncounterAtCheckIn } = await getPracticeUser(providerId);

      this.__handleCreateEncounterAtCheckIn(createEncounterAtCheckIn);
    }
  }

  __handleCreateEncounterAtCheckIn(createEncounterAtCheckIn) {
    this.__createEncounterAtCheckIn =
      this.__forceEncounterCreation || createEncounterAtCheckIn;
  }

  async __loadGuarantors(patientId) {
    const guarantors = formatGuarantors(await getPatientGuarantors(patientId));
    this.__guarantors = [
      SELF_GUARANTOR,
      ...guarantors.map(g => ({
        id: g.id,
        label: g.person
          ? buildPatientName(g.person, true)
          : g.organization.name,
        default: g.default,
        active: g.active,
      })),
    ];
  }

  async __updateEncounter(model) {
    let result;

    const encounter = await encounterApi.getEncounter(
      this.__appointmentModel.encounterId,
    );

    if (!encounter.signed) {
      const updatedEncounter = {
        ...encounter,
        appointmentTypeId: model.appointmentTypeId,
        providerId: model.providerId,
      };

      try {
        await encounterApi.updateEncounter(
          updatedEncounter.id,
          updatedEncounter,
        );

        result = true;
      } catch (err) {
        console.error(err);
        store.dispatch(openError('Encounter was not successfully updated.'));

        result = false;
      }
    }

    return result;
  }

  async __loadInsurances(patientId) {
    this.__insuranceItems = (await getPatientInsurances(patientId)).map(
      data => ({
        label: data.planName,
        data,
      }),
    );
  }

  async __loadMaxVisits(patientId) {
    const { data: insuranceVisitLimit } = await getMaxVisits(patientId);

    if (insuranceVisitLimit && insuranceVisitLimit.length) {
      this.__patientInsuranceVisitLimit = rawPatientInsuranceVisitLimitToModel({
        patientInsuranceVisitLimit: insuranceVisitLimit[0],
      });
    }
  }

  async __loadPracticeSettings() {
    this.__practiceSettings = await getPracticeSettings();
    this.__carePackageWithInsuranceEnabled =
      this.__practiceSettings[PRACTICE_SETTINGS.CARE_PACKAGE_WITH_INSURANCE];
  }

  async __loadTreatmentPlan() {
    if (this.model.mode === MODE.checkIn || !getChartingPermissions()) return;

    const treatmentPlan = await getTreatmentPlan(this.model.patientId);

    this.__treatmentPlanPhases = treatmentPlan
      ? treatmentPlan.treatmentPhases
      : [];
  }

  async __loadRooms() {
    const { appointment } = this.model;

    this.__rooms = (await fetchManyRooms())
      .filter(room => room.locationId === appointment.locationId && room.active)
      .map(room => ({
        ...room,
        label: room.name,
      }));

    this.__updateScheduleRoom =
      this.__practiceSettings[PRACTICE_SETTINGS.UPDATE_SCHEDULE_ROOM];
  }

  async __loadEncounterChargesFromCharting(encounterId) {
    if (!encounterId) {
      return;
    }

    this.__charges = (await getEncounterCharges(encounterId)).map(ch => ({
      ...ch,
      diagnosisPointers: this.__codes.filter(dx =>
        ch.diagnosisPointers.find(dp => dp.diagnosisCode === dx.code),
      ),
      posted: !!ch.postedToLedgerId,
      repost: !!ch.repost,
    }));
  }

  __applyBillingInfoDefaults(appointmentModel, appointmentDate) {
    const options = {
      feeSchedules: this.__feeScheduleItems
        .filter(item => item.data.id)
        .map(({ data }) => data),
      allFeeSchedules: this.__allFeeScheduleItems.map(({ data }) => data),
      guarantors: this.__guarantors.filter(x => x.id),
      insurances: this.__insuranceItems.map(({ data }) => data),
      carePackages: this.__activePackages.filter(x => x.data.id),
      patientCase: this.__cases.find(x => x.id === appointmentModel.caseId),
      patientAuthorizationId: appointmentModel.patientAuthorizationId,
      appointmentBillingInfo: { ...appointmentModel.billingInfo },
      billType: appointmentModel.patient
        ? appointmentModel.patient.billType
        : BILL_TYPES.SELF,
      caseBillTypeOverride: !!appointmentModel.patient?.caseBillTypeOverride,
      lineItems:
        this.__lineItems && this.__lineItems.length ? this.__lineItems : [],
      appointmentDate,
      providerId: appointmentModel.providerId,
    };

    const validator = new AppointmentBillingInfoDefaultsValidator(options);
    appointmentModel.billingInfo = validator.getAppointmentBillingInfo(
      this.__carePackageWithInsuranceEnabled,
    );
  }

  __getFeeSchedule() {
    const feeSchedule =
      this.__appointmentBillingInfo.billType === 'insurance' &&
      this.model.mode === MODE.checkOut
        ? this.__allFeeScheduleItems.find(
            item =>
              item.data.id === this.__appointmentBillingInfo.feeScheduleId,
          )
        : this.__feeScheduleItems.find(
            item =>
              item.data.id === this.__appointmentBillingInfo.feeScheduleId,
          );

    return feeSchedule && feeSchedule.data && feeSchedule.data.id
      ? feeSchedule.data
      : null;
  }

  __calculateIfInsuranceHasRemainingVisits(patientInsuranceVisitLimit) {
    return (
      patientInsuranceVisitLimit &&
      patientInsuranceVisitLimit.maxVisits !== '' &&
      patientInsuranceVisitLimit.totalRemaining !== null
    );
  }

  __handlePatientInsuranceVisitLimit() {
    if (this.__patientInsuranceVisitLimit) {
      if (
        this.__calculateIfInsuranceHasRemainingVisits(
          this.__patientInsuranceVisitLimit,
        )
      ) {
        const { maxVisits, totalRemaining, asOf } =
          this.__patientInsuranceVisitLimit;

        if (asOf) {
          const today = parseDate().startOf('day');
          const momentAsOf = parseDate(asOf);
          if (momentAsOf.isAfter(today)) return;
        }

        const visitsRemaining = totalRemaining - ASSUMED_VISITS_TODAY;

        this.__dispatchVisitLimitInfoBanner(visitsRemaining, maxVisits);
      }
    }
  }

  async __handleSaveResult(result, model) {
    let encounterCreated = null;

    if (result && result.success) {
      if (this.__createEncounterAtCheckIn && !model.encounterId) {
        encounterCreated = await this.createEncounterFromCheckIn(
          model.providerId,
          result.model.id,
        );
      }
      this.isDirty = false;
      this.dismiss({
        ...result,
        encounterCreated,
        cancelled: false,
        mode: this.model.mode,
      });
    } else {
      this.__savingError = true;
    }
  }

  async firstUpdated() {
    const { patientId, appointment } = this.model;

    await this.__loadData();
    this.__initialAppointmentModel = { ...this.__appointmentModel };

    const encounterId = appointment.encounter ? appointment.encounter.id : null;

    this.__forceEncounterCreation =
      !!this.model.forceEncounterCreation &&
      this.model.mode === MODE.checkIn &&
      !encounterId;

    if (!encounterId && appointment.providerId) {
      await this.__handleProvider(appointment.providerId);
    } else {
      this.__handleCreateEncounterAtCheckIn(false);
    }

    await this[`__${this.model.mode}Banner`].update(patientId);

    this.__handlePatientInsuranceVisitLimit();

    super.firstUpdated();
  }

  connectedCallback() {
    this.style.setProperty(
      '--content-width',
      this.model.mode === MODE.checkOut ? '100%' : '80rem',
    );

    super.connectedCallback();

    this.__checkInBanner.connect();

    this.__checkOutBanner.connect();
  }

  disconnectedCallback() {
    this.__checkOutBanner.disconnect();

    this.__checkInBanner.disconnect();

    super.disconnectedCallback();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .patient-summary {
          padding: ${CSS_SPACING};
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content {
          flex-flow: row nowrap;
          width: var(--content-width);
        }

        .content-right {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .controller {
          flex: 1 0 0;
        }
      `,
    ];
  }

  __dispatchVisitLimitInfoBanner(visitsRemaining, maxVisits) {
    if (visitsRemaining <= 2 && visitsRemaining >= 0) {
      store.dispatch(
        openInfo(
          PATIENT_INSURANCE_VISIT_LIMIT_NEAR_OR_AT_LIMIT(
            visitsRemaining,
            maxVisits,
          ),
        ),
      );
    }

    if (visitsRemaining === -1 || visitsRemaining === -2) {
      store.dispatch(openInfo(PATIENT_INSURANCE_VISIT_LIMIT_EXCEEDED));
    }
  }

  renderContent() {
    if (!this.__appointmentModel.patient) {
      this.__appointmentModel.patient = this.model.appointment.patient;
    }

    return html`
      ${this.layout !== 'small'
        ? html`
            <neb-patient-summary-controller
              id="${ELEMENTS.patientSummary.id}"
              class="patient-summary"
              .patientId="${this.model.patientId}"
              .showTreatmentPlanLink="${true}"
              .initialModel="${this.model.patientSummaryModel}"
            >
            </neb-patient-summary-controller>
          `
        : ''}

      <div class="content-right">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="${this.__getTitle()}"
          .onBack="${this.handlers.cancel}"
          .onCancel="${this.handlers.cancel}"
          .showBackButton="${this.layout === 'small'}"
          .showCancelButton="${this.layout !== 'small'}"
        ></neb-popup-header>

        <neb-form-check-in-out
          id="${ELEMENTS.formController.id}"
          class="controller"
          .layout="${this.layout}"
          .model="${this.__appointmentModel}"
          .serverAppointmentBillingInfo="${this.__serverAppointmentBillingInfo}"
          .cases="${this.__cases}"
          .authorizations="${this.__authorizations}"
          .rooms="${this.__rooms}"
          .feeScheduleItems="${this.__feeScheduleItems}"
          .allFeeScheduleItems="${this.__allFeeScheduleItems}"
          .guarantors="${this.__guarantors}"
          .insuranceItems="${this.__insuranceItems}"
          .codes="${this.__codes}"
          .charges="${this.__charges}"
          .lineItems="${this.__lineItems}"
          .activePackages="${this.__activePackages}"
          .packages="${this.__packages}"
          .unmodifiedPackages="${this.__allPackages}"
          .usedCounts="${this.__usedCounts}"
          .nextAppointment="${this.__nextAppointment}"
          .appointmentStart="${this.__appointmentStart}"
          .savingError="${this.__savingError}"
          .onCancel="${this.handlers.cancel}"
          .onConfirm="${this.handlers.save}"
          .onRepostPostedEncounterCharges="${this.handlers
            .repostPostedEncounterCharges}"
          .onAppointmentModelUpdated="${this.handlers.updateAppointment}"
          .onChangeDirty="${this.handlers.dirty}"
          .onProviderChanged="${this.handlers.providerChanged}"
          .onGuarantorsChanged="${this.handlers.guarantorsChanged}"
          .onInsurancesChanged="${this.handlers.insurancesChanged}"
          .onPackageTemplatesChanged="${this.handlers.packageTemplatesChanged}"
          .onFeeSchedulesChanged="${this.handlers.feeSchedulesChanged}"
          .onRefresh="${this.handlers.refresh}"
          .onAuthorizationDetailChanged="${this.handlers
            .authorizationDetailChanged}"
          .onUpdateEncounterCurrentIllnessDate="${this.handlers
            .updateEncounterCurrentIllnessDate}"
          ?checkedIn="${this.model.mode === MODE.checkOut}"
          .encounterCharges="${this.__encounterCharges}"
          .isLoading="${this.__isLoading}"
          .onUpdateLoading="${this.handlers.updateLoading}"
          .chartingPermission="${this.__chartingPermission}"
          .onCreateEncounterChanged="${this.handlers.createEncounterChanged}"
          .createEncounterAtCheckIn="${this.__createEncounterAtCheckIn}"
          .forceEncounterCreation="${this.model.forceEncounterCreation}"
          .treatmentPlanPhases="${this.__treatmentPlanPhases}"
          .practiceSettings="${this.__practiceSettings}"
        ></neb-form-check-in-out>
      </div>
    `;
  }
}

customElements.define('neb-overlay-check-in-out', NebOverlayCheckInOut);
