import '../../../../neb-lit-components/src/components/neb-tooltip';

import { html, css } from 'lit';

import NebTableOld, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../neb-lit-components/src/components/tables/neb-table-old';
import {
  objToName,
  formatDollarAmount,
} from '../../../../neb-utils/formatters';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  relationCell: {
    id: 'relation-self',
  },
  tooltip: {
    id: 'relation-tooltip',
  },
};
const SELF_TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Patient',
    flex: css`2 0 0`,
    formatter: name =>
      objToName(name, {
        reverse: true,
        middleInitial: true,
        preferred: true,
      }),
  },
  {
    key: 'relation',
    label: 'Relation',
    flex: css`1 0 0`,
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 0`,
    formatter: v => formatDollarAmount(v),
  },
];
class NebSelfGuarantorTable extends NebTableOld {
  static get properties() {
    return {
      patient: {
        type: Object,
      },
      balance: {
        type: Number,
      },
    };
  }

  initState() {
    super.initState();
    this.patient = {
      name: {},
    };

    this.balance = 0;
    this.preview = true;
    this.config = SELF_TABLE_CONFIG;
  }

  update(changed) {
    if (changed.has('patient') || changed.has('balance')) {
      this.model = [
        { ...this.patient, relation: 'Self', balance: this.balance },
      ];
    }

    super.update(changed);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell {
          white-space: normal;
          word-break: normal;
        }

        .cell-value {
          margin-right: 5px;
        }
      `,
    ];
  }

  renderCell(value, key) {
    return key === SELF_TABLE_CONFIG[1].key
      ? html`
          <span id="${ELEMENTS.relationCell.id}" class="cell-value"
            >${value}</span
          >
          <neb-tooltip id="${ELEMENTS.tooltip.id}"
            ><span slot="tooltip"
              >The patient will always have a relation of "Self" and will be the
              default guarantor unless assigned to a different person or
              organization below.</span
            ></neb-tooltip
          >
        `
      : super.renderCell(value);
  }
}
window.customElements.define('neb-self-guarantor-table', NebSelfGuarantorTable);
