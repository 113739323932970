import '../../neb-popup-header';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import {
  savePackageTemplate,
  updatePackageTemplate,
  deletePackageTemplate,
} from '../../../../../neb-api-client/src/package-template-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  PACKAGE_TYPE,
  SELECT_CHARGES_OVERLAY_TYPE,
} from '../../../../../neb-utils/enums';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { MODEL_KEYS as COVERED_MODEL_KEYS } from '../../settings/package-templates/neb-package-template-covered-charges';
import { EMPTY_MODEL } from '../../settings/package-templates/neb-packages-template-form';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
  actionBar: {
    id: 'action-bar',
  },
};
const MODE = {
  ADD: 'add',
  UPDATE: 'update',
};
const POPUP_MESSAGE_DUPLICATE_NAME =
  'There is an existing package template in the system with the same name. Please change the name to save this package template, or find and use the existing package template.';
const POPUP_MESSAGE_CHARGES =
  'You must have at least one charge to create a package. Please add at least one charge and save again.';

class NebPackageTemplateOverlay extends Overlay {
  static get properties() {
    return {
      __mode: {
        type: Boolean,
      },
      __packageTemplate: {
        type: Object,
      },
      __packageTemplates: {
        type: Object,
      },
      isDirty: {
        type: Boolean,
      },
      model: {
        type: Object,
      },
    };
  }

  initState() {
    super.initState();
    this.__mode = MODE.ADD;
    this.__packageTemplate = { ...EMPTY_MODEL };
    this.__packageTemplates = [];
    this.isDirty = false;
    this.model = {
      packageTemplates: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,

      addCharge: async () => {
        const selectedCharges = await openOverlay(OVERLAY_KEYS.SELECT_CHARGES, {
          charges: this.__packageTemplate.charges,
          type:
            this.__packageTemplate.type === PACKAGE_TYPE.VISIT
              ? SELECT_CHARGES_OVERLAY_TYPE.NO_UNITS
              : SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS,
        });
        const selectedChargesWithUnits = selectedCharges.map(u =>
          !u.units ? { ...u, units: '' } : u,
        );

        this.__updateModel({
          key: COVERED_MODEL_KEYS.CHARGES,
          value: selectedChargesWithUnits,
        });
      },

      changePackage: memo => this.__updateModel(memo),

      save: async () => {
        const isFormValid = await this.__validate();

        const packageTemplateNames = this.__packageTemplates.map(({ name }) =>
          name.toLowerCase(),
        );

        if (
          packageTemplateNames.includes(
            this.__packageTemplate.name.toLowerCase(),
          )
        ) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Duplicate Package Template',
            message: POPUP_MESSAGE_DUPLICATE_NAME,
          });
        }

        if (!this.__packageTemplate.charges.length) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Package Charges',
            message: POPUP_MESSAGE_CHARGES,
          });
        }

        if (isFormValid) {
          const upsertFunc = this.__packageTemplate.id
            ? updatePackageTemplate
            : savePackageTemplate;

          try {
            const result = await upsertFunc(this.__packageTemplate);
            store.dispatch(openSuccess('Package Template saved successfully'));
            this.isDirty = false;
            this.handlers.dismiss(result);
          } catch (e) {
            store.dispatch(
              openError('An error occurred when saving the package template.'),
            );
          }
        }

        return undefined;
      },

      delete: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Delete Package Template',
          message: 'Are you sure you want to delete this package template?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          try {
            this.isDirty = false;
            await deletePackageTemplate(this.__packageTemplate.id);
            store.dispatch(openSuccess('Package Template deleted'));
            this.handlers.dismiss(this.__packageTemplate);
          } catch (e) {
            store.dispatch(
              openError(
                'An error occurred when deleting the package template.',
              ),
            );
          }
        }
      },
    };
  }

  firstUpdated() {
    const { packageTemplates, packageTemplate } = this.model;
    this.__packageTemplates = packageTemplates;
    const initialModel =
      !!packageTemplate && Object.keys(packageTemplate).length
        ? { ...packageTemplate }
        : EMPTY_MODEL;
    this.__initialPackageTemplate = { ...initialModel };
    this.__packageTemplate = { ...initialModel };

    if (this.__initialPackageTemplate.id) {
      this.__mode = MODE.UPDATE;
    }

    super.firstUpdated();
  }

  updated() {
    this.__elements = {
      form: this.shadowRoot.getElementById(ELEMENTS.form.id),
    };
  }

  get __title() {
    const prefix = this.__mode === MODE.ADD ? 'Add' : 'Update';
    return `${prefix} Package Template`;
  }

  __updateModel({ key, value }) {
    this.__packageTemplate = { ...this.__packageTemplate, [key]: value };
    this.isDirty = !equal(
      this.__initialPackageTemplate,
      this.__packageTemplate,
    );
  }

  __validate() {
    return this.__elements.form.validate();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 880px;
          flex-flow: column nowrap;
        }

        .form {
          height: auto;
          flex: 1 0 0;
          overflow: auto;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.__title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderBody() {
    return html`
      <neb-packages-template-form
        id="${ELEMENTS.form.id}"
        class="form"
        .model="${this.__packageTemplate}"
        .onAddCharge="${this.handlers.addCharge}"
        .onChange="${this.handlers.changePackage}"
      ></neb-packages-template-form>
    `;
  }

  __renderFooter() {
    const isUpdate = !!this.__packageTemplate.id;
    return this.isDirty || isUpdate
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Save Package"
            .removeLabel="${isUpdate ? 'DELETE' : ''}"
            .onRemove="${this.handlers.delete}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.dismiss}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderBody()} ${this.__renderFooter()}
    `;
  }
}

customElements.define(
  'neb-overlay-package-template',
  NebPackageTemplateOverlay,
);
