import equal from 'fast-deep-equal';

import { getLowInventoryMessage } from '../../../../../src/api-clients/inventory';
import {
  CHARGES_UNABLE_TO_SAVE,
  SAVE_CHARGES_BANNER_SUCCESS,
} from '../../../../../src/utils/user-message';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { getEncounterDiagnosis } from '../../../../neb-www-practice-charting/src/store/actions/encounterDiagnosisActions';
import { getEncounterCharges } from '../../charting/encounter-charge';
import {
  createEncounterHistory,
  startEncounterHistory,
  updateEncounterCharges,
  updateEncounterDiagnoses,
} from '../../encounters-api-client';
import { formatToBilling } from '../../formatters/line-item-details';

const getModifier = (charge, i) =>
  charge.modifiers ? charge.modifiers[i - 1] : charge[`modifier_${i}`] || null;

export const getLineItemsToBeSaved = (charges, billingEncounterCharges) => {
  const lineItems = charges
    .filter(({ postedToLedgerId }) => postedToLedgerId)
    .map(charge => {
      const billingCharge = billingEncounterCharges.find(
        ({ id }) => id === charge.postedToLedgerId,
      );

      return billingCharge
        ? {
            ...billingCharge.lineItem,
            modifier_1: getModifier(charge, 1),
            modifier_2: getModifier(charge, 2),
            modifier_3: getModifier(charge, 3),
            modifier_4: getModifier(charge, 4),
            modifiers: undefined,
            encounterCharge: {
              diagnosisPointers: charge.diagnosisPointers.map(
                ({ diagnosisCode }) => diagnosisCode,
              ),
            },
          }
        : null;
    })
    .filter(Boolean);

  return formatToBilling(lineItems, false);
};

const getEncounterChargeDiagnosis = diagnosis =>
  diagnosis.length
    ? diagnosis.map(({ diagnosisCode, code }) => ({
        diagnosisCode: diagnosisCode || code,
      }))
    : [];

const __getChargesEditableFields = charges => {
  if (charges.some(value => value.modifiers)) {
    return charges.map(({ modifiers, units, order, diagnosisPointers }) => ({
      modifiers,
      units,
      order,
      diagnosisPointers,
    }));
  }
  return charges.map(charge => ({
    modifier_1: getModifier(charge, 1),
    modifier_2: getModifier(charge, 2),
    modifier_3: getModifier(charge, 3),
    modifier_4: getModifier(charge, 4),
    units: charge.units,
    order: charge.order,
    diagnosisPointers: charge.diagnosisPointers,
  }));
};

const fieldsChanged = (pristineCharges, charges) =>
  pristineCharges.length !== charges.length ||
  !equal(
    __getChargesEditableFields(pristineCharges),
    __getChargesEditableFields(charges),
  );

const getChargesToBePosted = ({ charges }) => {
  const encounterCharges = charges
    .filter(charge => charge.posted && !charge.postedToLedgerId)
    .map(({ posted, ...encounterCharge }) => encounterCharge);

  return encounterCharges;
};

const checkUpdateEncounterCharges = ({ encounterCharges, pristineCharges }) => {
  const formattedPristineCharges = pristineCharges.map(ch => {
    const { modifiers, billedAmount, ...charge } = ch;
    return {
      ...charge,
      diagnosisPointers: getEncounterChargeDiagnosis(charge.diagnosisPointers),
    };
  });

  const formattedEncounterCharges = encounterCharges.map(ec => {
    const { modifiers, billedAmount, ...charge } = ec;
    return charge;
  });

  return (
    encounterCharges.length !== pristineCharges.length ||
    !equal(formattedEncounterCharges, formattedPristineCharges)
  );
};

const unitsChangedOnPostedCharge = (encounterCharge, pristineCharges) => {
  const pristineCharge = pristineCharges.find(
    charge => charge.id === encounterCharge.id,
  );

  return (
    !!encounterCharge.posted &&
    !!encounterCharge.postedToLedgerId &&
    pristineCharge &&
    encounterCharge.units !== pristineCharge.units
  );
};

const calculateRepostValue = (encounterCharge, pristineCharges) =>
  encounterCharge.repost ||
  unitsChangedOnPostedCharge(encounterCharge, pristineCharges);

const formatEncounterCharges = ({
  encounterCharges,
  pristineCharges,
  encounterId,
}) =>
  encounterCharges.map(charge => ({
    ...charge,
    modifier_1: getModifier(charge, 1),
    modifier_2: getModifier(charge, 2),
    modifier_3: getModifier(charge, 3),
    modifier_4: getModifier(charge, 4),
    modifiers: undefined,
    nationalDrugCodeQualifier: charge.nationalDrugCodeQualifier || null,
    nationalDrugCode: charge.nationalDrugCode || null,
    nationalDrugCodeDosage: charge.nationalDrugCodeDosage || null,
    nationalDrugCodeUnitOfMeasurement:
      charge.nationalDrugCodeUnitOfMeasurement || null,
    nationalDrugCodeNumberCategory:
      charge.nationalDrugCodeNumberCategory || null,
    nationalDrugCodeSequenceOrPrescription:
      charge.nationalDrugCodeSequenceOrPrescription || null,
    diagnosisPointers: getEncounterChargeDiagnosis(charge.diagnosisPointers),
    encounterId,
    repost: calculateRepostValue(charge, pristineCharges),
  }));

const getEncounterChargesToBeSaved = (
  charges,
  pristineCharges,
  encounterId,
) => {
  const encounterCharges = formatEncounterCharges({
    encounterCharges: charges,
    pristineCharges,
    encounterId,
  });

  return encounterCharges;
};

export const checkCreateEncounterHistory = async (
  encounterId,
  shouldCreateEH,
  encounterHistoryFn,
) => {
  if (shouldCreateEH) {
    await encounterHistoryFn(encounterId, true);
  }
};

const updateCharges = async ({
  encounterId,
  patientId,
  pristineCharges,
  savedCharges,
  billingEncounterCharges,
  charges,
}) => {
  const savedLineItems = getLineItemsToBeSaved(
    charges,
    billingEncounterCharges,
  );

  const postedCharges = getChargesToBePosted({
    savedCharges,
    charges,
    encounterId,
    patientId,
  });

  await updateEncounterCharges({
    charges: savedCharges,
    savedLineItems,
    encounterId,
    patientId,
  });

  await store.dispatch(getEncounterDiagnosis(encounterId, true));

  store.dispatch(openSuccess(SAVE_CHARGES_BANNER_SUCCESS));

  if (postedCharges.length) {
    const pristinePostedChargeIds = pristineCharges
      .filter(({ postedToLedgerId }) => postedToLedgerId)
      .map(({ chargeId }) => chargeId);

    const chargeIds = savedCharges
      .filter(
        ({ chargeId }) =>
          chargeId && !pristinePostedChargeIds.includes(chargeId),
      )
      .map(({ chargeId }) => chargeId);

    const inventoryMessage = await getLowInventoryMessage({
      chargeIds,
      codes: [],
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  }
};

const __saveDiagnoses = async ({ encounterId, diagnoses }) => {
  try {
    await updateEncounterDiagnoses(encounterId, diagnoses);

    store.dispatch(openSuccess('Encounter diagnoses updated'));
  } catch (err) {
    console.log('err :>> ', err);
    store.dispatch(openError('Unable to save diagnoses'));
  }
};

export default async ({
  pristineCharges,
  charges: originalCharges,
  encounterId,
  billingEncounterCharges,
  patientId,
  signed,
  diagnoses,
  diagnosesUpdated = false,
}) => {
  try {
    const dbEncounterCharges = (
      await getEncounterCharges(encounterId, true)
    ).reduce((accum, charge) => {
      accum[charge.id] = { ...charge };
      return accum;
    }, {});

    const charges = originalCharges.map(charge => {
      const postedToLedgerId = dbEncounterCharges[charge.id]
        ? dbEncounterCharges[charge.id].postedToLedgerId
        : charge.postedToLedgerId;

      charge.postedToLedgerId = postedToLedgerId;
      return charge;
    });

    const savedCharges = getEncounterChargesToBeSaved(
      charges,
      pristineCharges,
      encounterId,
    );

    const shouldUpdateCharges = fieldsChanged(pristineCharges, charges);

    const shouldCreateEH = signed && (shouldUpdateCharges || diagnosesUpdated);

    await checkCreateEncounterHistory(
      encounterId,
      shouldCreateEH,
      startEncounterHistory,
    );

    if (
      checkUpdateEncounterCharges({
        encounterCharges: savedCharges,
        pristineCharges: formatEncounterCharges({
          encounterCharges: pristineCharges,
          pristineCharges,
          encounterId,
        }),
      })
    ) {
      await updateCharges({
        encounterId,
        patientId,
        pristineCharges,
        savedCharges,
        billingEncounterCharges,
        charges,
      });
    }

    if (diagnosesUpdated) {
      await __saveDiagnoses({
        diagnoses,
        encounterId,
      });
    }

    await checkCreateEncounterHistory(
      encounterId,
      shouldCreateEH,
      createEncounterHistory,
    );

    return true;
  } catch (e) {
    console.error(e);
    store.dispatch(openError(CHARGES_UNABLE_TO_SAVE));
    return false;
  }
};
