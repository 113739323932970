import '../../../packages/neb-www-practice-charting/src/neb-charting-top-bar';
import './neb-page-signed-encounter';

import { html, LitElement, css } from 'lit';

import {
  createBannerController,
  DISPLAY_ON,
} from '../../../packages/neb-alert/components/neb-alert-banner-controller';
import { getAppointmentById } from '../../../packages/neb-api-client/src/appointment-api-client';
import { mapToPatientCaseName } from '../../../packages/neb-api-client/src/mappers/patient-case-mapper';
import * as patientCaseApiClient from '../../../packages/neb-api-client/src/patient-cases';
import { getPatientImage } from '../../../packages/neb-api-client/src/patient-image-api-client';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../packages/neb-utils/feature-util';
import { mapToPatientModel } from '../../../packages/neb-utils/patient';
import { filterValidAuthorizations } from '../../../packages/neb-utils/patientAuthorization';
import { getEncounterById } from '../../api-clients/encounters';
import { UpdateNotificationService } from '../../services/update-notifications';
import { baseStyles } from '../../styles';
import { ANY } from '../../utils/update-notifications';

import { FORCE_RELOAD_ENCOUNTER } from './neb-charting-util';
import { NebPageUnsignedEncounter } from './neb-page-unsigned-encounter';
import { safeRequest } from './shared/safe-request';

export const ELEMENTS = {
  chartingTopBar: { id: 'top-bar' },
  signedEncounterPage: { id: 'signed-encounter-page' },
  unsignedEncounterPage: { id: 'unsigned-encounter-page' },
};

const getEncounterPatientCase = async (patientId, patientCaseId) => {
  const patientCase = await patientCaseApiClient.fetchOne(
    patientId,
    patientCaseId,
    true,
  );

  return {
    ...patientCase,
    patientAuthorization: filterValidAuthorizations(
      patientCase.patientAuthorizations,
    )[0],
    label: mapToPatientCaseName(
      patientCase.name,
      patientCase.onsetSymptomsDate,
    ),
  };
};

class NebEncounterController extends LitElement {
  static get properties() {
    return {
      __encounterIsCurrentlyBeingReopened: { type: Boolean },
      __encounterData: { type: Object },
      __patient: { type: Object },
      __topBarInfo: { type: Object },
      __dateOfOnSetFF: { type: Boolean },

      encounterId: { type: String },
      layout: { type: String },
      route: { type: String },
      shouldRenderSignedViewFirst: { type: Boolean },
      user: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .container {
          display: flex;
          flex-direction: column;

          height: 100%;
          width: 100%;
        }

        .encounter-page {
          z-index: 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__encounterData = NebPageUnsignedEncounter.createModel();
    this.__patient = mapToPatientModel({}, 0);
    this.__topBarInfo = {};
    this.__encounterIsCurrentlyBeingReopened = false;
    this.__dateOfOnSetFF = false;

    this.encounterId = '';
    this.layout = '';
    this.route = '/';
    this.shouldRenderSignedViewFirst = false;
    this.user = {};

    this.onEncounterLoaded = () => {};

    this.onEncounterSigned = () => {};

    this.onUserMenuOptions = () => {};
  }

  __initServices() {
    this.__alertController = createBannerController(DISPLAY_ON.charting);
    this.__patientCaseNotificationService = new UpdateNotificationService({
      defaultQuery: {
        patientCase: ANY,
      },
      callback: async () => {
        await safeRequest(async () => {
          await this.__loadEncounterPatientCase();
        }, 'patient case');
      },
    });

    this.__updateEncounterNotificationService = new UpdateNotificationService({
      defaultQuery: {
        insurance: ANY,
        patientPackage: ANY,
        patient: ANY,
        ...FORCE_RELOAD_ENCOUNTER,
      },
      callback: () => this.__loadEncounter(),
    });
  }

  __initHandlers() {
    this.__handlers = {
      handleUserMenuOption: (...args) => this.onUserMenuOptions(...args),

      encounterReopened: () => {
        this.__encounterData = NebPageUnsignedEncounter.createModel();
        this.__encounterIsCurrentlyBeingReopened = true;

        this.__loadEncounter();
      },

      encounterSigned: () => {
        this.onEncounterSigned();

        this.__encounterIsCurrentlyBeingSigned = true;
        this.__encounterData = NebPageUnsignedEncounter.createModel();

        this.__loadEncounter();
      },

      updateEncounter: () => this.__loadEncounter(),
    };
  }

  async __loadPatientImage() {
    const photoSrc = await getPatientImage(
      this.__encounterData.patientId,
      'small',
      true,
    );

    this.__patient = mapToPatientModel(
      this.__encounterData.patient,
      0,
      photoSrc,
    );
  }

  async __loadEncounter() {
    if (this.encounterId) {
      await safeRequest(async () => {
        const encounterData = await getEncounterById(this.encounterId);
        this.__encounterHasNeverBeenLoaded = false;
        this.__encounterIsCurrentlyBeingSigned = false;
        this.__encounterIsCurrentlyBeingReopened = false;

        this.__alertController.update(encounterData.patientId);

        if (encounterData.caseId) {
          await this.__loadEncounterPatientCase(encounterData);
        } else {
          this.__encounterData = {
            ...encounterData,
          };
        }

        this.__topBarInfo = {
          appointmentTypeId: this.__encounterData.appointmentTypeId,
          encounterId: this.encounterId,
        };

        this.onEncounterLoaded(this.__encounterData);

        this.__loadPatientImage();
      }, 'encounter');
    }
  }

  async __loadEncounterPatientCase(encounterData = this.__encounterData) {
    if (encounterData.caseId) {
      const appointmentInfo = await getAppointmentById(
        encounterData.appointmentId,
      );

      const patientCase = await getEncounterPatientCase(
        encounterData.patientId,
        encounterData.caseId,
      );

      this.__encounterData = {
        ...encounterData,
        case: { ...patientCase },
        patientAuthorizationId:
          appointmentInfo.appointment?.patientAuthorizationId || '',
      };
    }
  }

  async connectedCallback() {
    this.__alertController.connect();
    this.__patientCaseNotificationService.connect();
    this.__updateEncounterNotificationService.connect();

    const features = await getFeatures();

    this.__dateOfOnSetFF = features.includes(FEATURE_FLAGS.EGG_DATE_OF_ONSET);

    super.connectedCallback();
  }

  disconnectedCallback() {
    this.__alertController.disconnect();
    this.__patientCaseNotificationService.disconnect();
    this.__updateEncounterNotificationService.disconnect();

    super.disconnectedCallback();
  }

  async updated(changedProps) {
    if (changedProps.has('encounterId') && this.encounterId) {
      this.__encounterData = NebPageUnsignedEncounter.createModel();

      await this.__loadEncounter();
    }
  }

  __shouldUseParentHint() {
    return (
      this.__encounterData.id !== this.encounterId &&
      this.shouldRenderSignedViewFirst
    );
  }

  __shouldRenderSignedView() {
    if (this.__encounterIsCurrentlyBeingReopened) {
      return false;
    }

    return (
      this.__encounterData.signed ||
      this.__encounterIsCurrentlyBeingSigned ||
      this.__shouldUseParentHint()
    );
  }

  __renderEncounter() {
    return this.__shouldRenderSignedView()
      ? html`
          <neb-page-signed-encounter
            id="${ELEMENTS.signedEncounterPage.id}"
            class="encounter-page"
            .encounterId="${this.encounterId}"
            .model="${this.__encounterData}"
            .dateOfOnsetFF="${this.__dateOfOnSetFF}"
            .onEncounterReopened="${this.__handlers.encounterReopened}"
            .onUpdateEncounter="${this.__handlers.updateEncounter}"
          ></neb-page-signed-encounter>
        `
      : html`
          <neb-page-unsigned-encounter
            id="${ELEMENTS.unsignedEncounterPage.id}"
            class="encounter-page"
            .encounterId="${this.encounterId}"
            .layout="${this.layout}"
            .patientId="${this.__patient.id}"
            .model="${this.__encounterData}"
            .dateOfOnsetFF="${this.__dateOfOnSetFF}"
            .route="${this.route}"
            .onEncounterSigned="${this.__handlers.encounterSigned}"
            .onUpdateEncounter="${this.__handlers.updateEncounter}"
          ></neb-page-unsigned-encounter>
        `;
  }

  render() {
    return html`
      <div class="container">
        <neb-charting-top-bar
          id="${ELEMENTS.chartingTopBar.id}"
          .patient="${this.__patient}"
          .info="${this.__topBarInfo}"
          .layout="${this.layout}"
          .user="${this.user}"
          .onUpdateEncounter="${this.__handlers.updateEncounter}"
          .onUserMenuOptions="${this.__handlers.handleUserMenuOption}"
        ></neb-charting-top-bar>

        ${this.__renderEncounter()}
      </div>
    `;
  }
}

customElements.define('neb-encounter-controller', NebEncounterController);
