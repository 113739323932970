import './neb-charting-encounter-header';

import { openPopup } from '@neb/popup';
import { LitElement, html } from 'lit';

import { getSchedulingPermissions } from '../../../packages/neb-api-client/src/permissions-api-client';
import { fetchManyRooms } from '../../../packages/neb-api-client/src/rooms-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { APPOINTMENT_ACTIONS } from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  handleDeleteEncounter,
  handleReopenEncounter,
  handleSignEncounter,
  openEncounterHistory,
  openVisitSummary,
  printEncounterSummary,
} from '../../../packages/neb-lit-components/src/utils/encounter-actions-util';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { navigate } from '../../../packages/neb-route/neb-route-state';
import { roomConflictOverridePopup } from '../../../packages/neb-utils/calendar-resources-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import { URL_NO_ACCESS } from '../../../packages/neb-utils/neb-request-security';
import { updateAppointmentRoom } from '../../api-clients/appointments';
import NebFormAppointment from '../../components/forms/appointments/neb-form-appointment';

import { forceReloadEncounter } from './neb-charting-util';
import { PANEL_KEYS } from './neb-unsigned-encounter-data-controller';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
};

class NebChartingEncounterHeaderController extends LitElement {
  static get properties() {
    return {
      historyExists: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
      user: Object,
      __hasFutureEncounterFF: Boolean,
      __hasChangeAppointmentTypeAfterCheckInFF: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.charges = [];
    this.diagnoses = [];
    this.historyExists = false;
    this.layout = '';
    this.model = null;
    this.paymentsExist = false;
    this.user = null;

    this.__hasFutureEncounterFF = false;
    this.__hasChangeAppointmentTypeAfterCheckInFF = false;

    this.onAuthorizationDetailChanged = () => {};

    this.onReopenEncounter = () => {};

    this.onSignEncounter = () => {};

    this.onUpdateEncounter = () => {};

    this.onRefetch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addNewAppointment: () => this.__openOverlayAddNewAppointment(),
      changeCheckedInRoom: () => this.__openChangeRoomPopup(),
      closeDashboard: () => this.__closeDashboard(),
      deleteEncounter: () => this.__deleteEncounter(),
      onAuthorizationDetailChanged: () => this.onAuthorizationDetailChanged(),
      openCopyEncounter: () => this.__openCopyEncounter(),
      openReopenEncounterPopup: () => this.__reopenEncounter(),
      openSignEncounterPopup: () => this.__signEncounter(),
      openVisitSummary: () => this.__openVisitSummaryOverlay(),
      print: () => this.__print(),
      requestNextAppointment: () => this.__openOverlayRequestNextAppointment(),
      viewHistory: () => this.__openHistoryOverlay(),
      updateEncounter: () => this.onUpdateEncounter(),
      openManageEncounter: () => this.__openManageEncounterOverlay(),
    };
  }

  async connectedCallback() {
    this.__hasFutureEncounterFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.EGG_FUTURE_COPY_ANY_ENCOUNTER,
    );

    this.__hasChangeAppointmentTypeAfterCheckInFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_CHANGE_APPOINTMENT_TYPE_AFTER_CHECK_IN,
    );

    super.connectedCallback();
  }

  __closeDashboard() {
    store.dispatch(navigate('#/charting'));
  }

  __print() {
    return printEncounterSummary({
      patientId: this.model.patientId,
      encounterId: this.model.id,
    });
  }

  async __openCopyEncounter() {
    const popupKey = this.__hasFutureEncounterFF
      ? POPUP_RENDER_KEYS.COPY_ENCOUNTER
      : POPUP_RENDER_KEYS.COPY_PRIOR_ENCOUNTER;

    const result = await openPopup(popupKey, {
      encounter: this.model,
    });

    if (result) {
      forceReloadEncounter();
    }
  }

  async __openHistoryOverlay() {
    await openEncounterHistory(this.model.id);
  }

  async __openVisitSummaryOverlay() {
    await openVisitSummary({
      patientId: this.model.patientId,
      encounterId: this.model.id,
    });
  }

  async __signEncounter() {
    const data = await handleSignEncounter({
      providerId: this.model.providerId,
      encounterId: this.model.id,
      patientId: this.model.patientId,
      charges: this.charges,
    });

    if (data && data.res) {
      this.onSignEncounter(this.model.id);
    }
  }

  async __openOverlayAddNewAppointment() {
    if (getSchedulingPermissions()) {
      await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
        ...NebFormAppointment.createModel(),
        patientId: this.model.patientId,
        providerId: this.model.providerId,
      });
    } else {
      store.dispatch(navigate(URL_NO_ACCESS));
    }
  }

  async __reopenEncounter() {
    const data = await handleReopenEncounter(this.model.id);

    if (data && data.res) {
      this.onReopenEncounter();
    }
  }

  async __openOverlayRequestNextAppointment() {
    await openOverlay(OVERLAY_KEYS.APPOINTMENT_REQUEST, {
      initialPatientId: this.model.patientId,
      providerId: this.model.providerId,
      encounterId: this.model.id,
    });
  }

  async __openChangeRoomPopup() {
    const { appointment } = this.model;

    const rooms = (await fetchManyRooms()).filter(
      room =>
        room.active &&
        room.checkInAvailable &&
        room.locationId === appointment.locationId,
    );

    const result = await openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CHANGE_ROOM, {
      appointmentId: appointment.id,
      rooms,
      roomId: appointment.roomId,
    });

    return this.__updateRoomIdFromPopupResult(result, appointment);
  }

  async __openManageEncounterOverlay() {
    const result = await openOverlay(OVERLAY_KEYS.MANAGE_ENCOUNTER, {
      patientId: this.model.patientId,
      encounterIds: [this.model.id],
    });

    if (result) {
      this.onRefetch(PANEL_KEYS.CHARGES);
    }
  }

  async __updateRoomIdFromPopupResult(result, appointment) {
    const appointmentAction = APPOINTMENT_ACTIONS.CHANGE_ROOM;
    const roomId = result.room.id;

    if (result && appointment.roomId !== roomId) {
      try {
        if (await roomConflictOverridePopup(roomId)) {
          await updateAppointmentRoom({
            id: appointment.id,
            body: { roomId },
          });

          store.dispatch(openSuccess(appointmentAction.successMessage));

          this.onUpdateEncounter();
        }
      } catch (_) {
        store.dispatch(openError(appointmentAction.errorMessage));
      }
    }
  }

  async __deleteEncounter() {
    const data = await handleDeleteEncounter({
      encounterId: this.model.id,
      charges: this.charges,
      diagnoses: this.diagnoses,
      patientId: this.model.patientId,
    });

    if (data && data.res) {
      this.dispatchEvent(
        new CustomEvent('neb-encounter-deleted', {
          detail: {
            deletedEncounterId: this.model.id,
          },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  render() {
    return html`
      <neb-charting-encounter-header
        id="${ELEMENTS.header.id}"
        .historyExists="${this.historyExists}"
        .layout="${this.layout}"
        .model="${this.model}"
        .hasFutureEncounterFF="${this.__hasFutureEncounterFF}"
        .hasChangeAppointmentTypeAfterCheckInFF="${this
          .__hasChangeAppointmentTypeAfterCheckInFF}"
        .onAddNewAppointment="${this.__handlers.addNewAppointment}"
        .onDeleteEncounter="${this.__handlers.deleteEncounter}"
        .onRequestNextAppointment="${this.__handlers.requestNextAppointment}"
        .onChangeRoom="${this.__handlers.changeCheckedInRoom}"
        .onCloseDashboard="${this.__handlers.closeDashboard}"
        .onOpenCopyPriorEncounter="${this.__handlers.openCopyEncounter}"
        .onOpenCopyEncounter="${this.__handlers.openCopyEncounter}"
        .onOpenReopenEncounterPopup="${this.__handlers
          .openReopenEncounterPopup}"
        .onOpenSignEncounterPopup="${this.__handlers.openSignEncounterPopup}"
        .onViewHistory="${this.__handlers.viewHistory}"
        .onPrint="${this.__handlers.print}"
        .onAuthorizationDetailChanged="${this.__handlers
          .onAuthorizationDetailChanged}"
        .onOpenVisitSummary="${this.__handlers.openVisitSummary}"
        .onUpdateEncounter="${this.__handlers.updateEncounter}"
        .onOpenManageEncounter="${this.__handlers.openManageEncounter}"
      ></neb-charting-encounter-header>
    `;
  }
}

customElements.define(
  'neb-charting-encounter-header-controller',
  NebChartingEncounterHeaderController,
);
