import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-picker-color';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import Spine from '../../../../packages/neb-lit-components/src/components/neb-spine';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { capitalize } from '../../../../packages/neb-utils/formatters';
import { DISK_COUNTS } from '../../../../packages/neb-utils/listings';
import { traverse, getValueByPath } from '../../../../packages/neb-utils/utils';

const METADATA = {
  C: {
    key: 'cDisks',
    prefix: 'C',
    range: new Array(DISK_COUNTS.C).fill(0).map((_, index) => index),
  },
  T: {
    key: 'tDisks',
    prefix: 'T',
    range: new Array(DISK_COUNTS.T).fill(0).map((_, index) => index),
  },
  L: {
    key: 'lDisks',
    prefix: 'L',
    range: new Array(DISK_COUNTS.L).fill(0).map((_, index) => index),
  },
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonClear: { id: 'button-clear' },
  buttonCopy: { id: 'button-copy' },
  colorPickers: { selector: 'neb-picker-color' },
  textfields: { selector: 'neb-textfield' },
  spine: { id: 'neb-spine' },
};

class ListingsForm extends Form {
  static get styles() {
    return [
      super.styles,
      css`
        .grid-fields {
          grid-gap: 6px;
        }

        .grid-content {
          grid-template-columns: 1fr auto 1fr;
          align-items: start;
        }

        .grid-copy {
          grid-template-columns: 1fr 20px auto 20px 1fr;
        }

        .spine {
          width: 384.45px;
        }

        [class^='pelvis'] {
          margin-top: 32px;
        }

        [class^='coccyx'] {
          margin-top: 20px;
        }
      `,
    ];
  }

  static createModel() {
    return Spine.createModel();
  }

  initState() {
    super.initState();

    this.onCopy = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      copy: () => this.onCopy(),
      clear: async () => {
        if (
          await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Reset Listings',
            message: 'Are you sure you want to reset all current listings?',
            yesLabel: 'Yes',
            noLabel: 'No',
          })
        ) {
          const model = ListingsForm.createModel();
          const state = this.formService.convert(model, 'format');

          traverse(state, (keyPath, value) => {
            if (typeof value !== 'object') {
              this.formService.apply(keyPath.join('.'), value);
            }
          });
        }
      },
    };
  }

  createSelectors() {
    return {};
  }

  isCopyDisabled() {
    const items = [
      this.state.occiput,
      ...this.state.cDisks,
      ...this.state.tDisks,
      ...this.state.lDisks,
      this.state.sacrum,
      this.state.coccyx,
      this.state.pelvis,
    ].reduce((accum, curr) => [...accum, curr.left, curr.right], []);

    return this.isDirty() || items.every(item => !item.issue);
  }

  isDirty() {
    return this.__dirty;
  }

  getFieldData(keyPath) {
    return {
      name: keyPath.join('.'),
      value: getValueByPath(this.state, keyPath),
      error: getValueByPath(this.errors, keyPath),
    };
  }

  renderColorPicker(keyPath) {
    const data = this.getFieldData([...keyPath, 'color']);
    const kabab = data.name.split('.').join('-');

    return html`
      <neb-picker-color
        id="${kabab}"
        class="${kabab}"
        .name="${data.name}"
        .value="${data.value}"
        .error="${data.error}"
        .onChange="${this.handlers.change}"
      ></neb-picker-color>
    `;
  }

  renderTextfield(keyPath, placeholder) {
    const data = this.getFieldData([...keyPath, 'issue']);
    const kabab = data.name.split('.').join('-');

    return html`
      <neb-textfield
        id="${kabab}"
        class="${kabab}"
        .placeholder="${placeholder}"
        .name="${data.name}"
        .value="${data.value}"
        .error="${data.error}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  renderLeftFields(rootPath, placeholder) {
    return html`
      ${this.renderTextfield([...rootPath, 'left'], placeholder)}
      ${this.renderColorPicker([...rootPath, 'left'])}
    `;
  }

  renderRightFields(rootPath, placeholder) {
    return html`
      ${this.renderColorPicker([...rootPath, 'right'])}
      ${this.renderTextfield([...rootPath, 'right'], placeholder)}
    `;
  }

  renderDisks(side) {
    const methodName = `render${capitalize(side)}Fields`;

    return Object.values(METADATA).reduce(
      (accum, curr) => [
        accum,
        ...curr.range.map(i =>
          this[methodName]([curr.key, i], `${curr.prefix}${i + 1}`),
        ),
      ],
      [],
    );
  }

  renderContent() {
    return html`
      <div class="grid grid-auto-left pad">
        <div class="grid grid-auto-right">
          <span>Spine</span>

          <neb-button-action
            id="${ELEMENTS.buttonClear.id}"
            icon=""
            label="Clear All"
            .onClick="${this.handlers.clear}"
          ></neb-button-action>
        </div>
      </div>

      <div class="grid grid-content">
        <div class="grid grid-fields grid-auto-right">
          ${this.renderLeftFields(['occiput'], 'Occiput')}
          ${this.renderDisks('left')}
          ${this.renderLeftFields(['sacrum'], 'Sacrum')}
          ${this.renderLeftFields(['pelvis'], 'Pelvis')}
          ${this.renderLeftFields(['coccyx'], 'Coccyx')}
        </div>

        <neb-spine
          id="${ELEMENTS.spine.id}"
          class="spine"
          .model="${this.state}"
          .errors="${this.errors}"
          .onChange="${this.handlers.change}"
        ></neb-spine>

        <div class="grid grid-fields grid-auto-left">
          ${this.renderRightFields(['occiput'], 'Occiput')}
          ${this.renderDisks('right')}
          ${this.renderRightFields(['sacrum'], 'Sacrum')}
          ${this.renderRightFields(['pelvis'], 'Pelvis')}
          ${this.renderRightFields(['coccyx'], 'Coccyx')}
        </div>
      </div>

      <div class="grid grid-copy">
        <div></div>
        <div></div>

        <neb-button
          id="${ELEMENTS.buttonCopy.id}"
          label="Copy Listings to Chart Note"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.copy}"
          ?disabled="${this.isCopyDisabled()}"
        ></neb-button>

        <neb-tooltip>
          <div slot="tooltip">
            This will place a copy of the patient's listings data in the chart
            note for this encounter. This action is only active when there are
            no unsaved changes on this page.
          </div>
        </neb-tooltip>

        <div></div>
      </div>
    `;
  }
}

window.customElements.define('neb-form-listings-v2', ListingsForm);
