import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(
    POPUP_RENDER_KEYS.CLAIM_SUPPLEMENTAL_INFORMATION,
    {
      title: claimCellData.title,
      items: [
        {
          name: 'additionalCheckbox',
          value: state.additionalCheckbox,
        },
        {
          name: 'additionalClaimInformation',
          value: state.additionalClaimInformation,
        },
        {
          name: 'reportTypeCode',
          value: state.reportTypeCode || '',
        },
        {
          name: 'reportTransmissionCode',
          value: state.reportTransmissionCode || '',
        },
        {
          name: 'codeQualifier',
          value: state.codeQualifier || '',
        },
        {
          name: 'identificationNumber',
          value: state.identificationNumber || '',
        },
      ],
    },
  );

  if (result) {
    formService.apply('claimEditedField.additionalClaimInformation', true);

    handlers.change({
      name: 'additionalClaimInformation',
      value: result.additionalCheckbox ? result.additionalClaimInformation : '',
    });
  }
};
