import { html } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { deepCopy } from '../../../packages/neb-utils/utils';
import { TOKEN_KEY } from '../../../packages/neb-www-practice-charting/src/macro-processor/token-replacement';

export const QUESTION_ANSWER_TOKEN = {
  ask: 'freeText',
  chooseAny: 'multiSelect',
  chooseOne: 'singleSelect',
  date: 'datePicker',
  number: 'numericEntry',
  region: 'bodyDiagram',
  spine: 'spine',
};

export const ADVANCED_TOKENS = {
  [TOKEN_KEY.ADDRESS]: 'ADDRESS',
  [TOKEN_KEY.AGE]: 'AGE',
  [TOKEN_KEY.BIRTH_DATE]: 'BIRTH_DATE',
  [TOKEN_KEY.CURRENT_DATE]: 'CURR_DATE',
  [TOKEN_KEY.CURRENT_DX]: 'CURR_DX',
  [TOKEN_KEY.CURRENT_LISTINGS]: 'CURR_LISTINGS',
  [TOKEN_KEY.CURRENT_TIME]: 'CURR_TIME',
  [TOKEN_KEY.CURRENT_TX]: 'CURR_TX',
  [TOKEN_KEY.CURRENT_USER]: 'CURR_USER',
  [TOKEN_KEY.EMAIL]: 'EMAIL',
  [TOKEN_KEY.EMPLOYMENT_STATUS]: 'EMP_STATUS',
  [TOKEN_KEY.FIRST_NAME]: 'FIRST_NAME',
  [TOKEN_KEY.HE_SHE]: 'he/she',
  [TOKEN_KEY.CAPITAL_HE_SHE]: 'He/She',
  [TOKEN_KEY.HIM_HER]: 'him/her',
  [TOKEN_KEY.HIS_HER]: 'his/her',
  [TOKEN_KEY.CAPITAL_HIS_HER]: 'His/Her',
  [TOKEN_KEY.INJURY_DATE]: 'INJURY_DATE',
  [TOKEN_KEY.LAST_NAME]: 'LAST_NAME',
  [TOKEN_KEY.RELATIONSHIP_STATUS]: 'MAR_STATUS',
  [TOKEN_KEY.MR_MRS]: 'Mr/Mrs',
  [TOKEN_KEY.MR_MS]: 'Mr/Ms',
  [TOKEN_KEY.PHONE]: 'PHONE',
  [TOKEN_KEY.GENDER]: 'SEX',
};

export const UNSUPPORTED_ADVANCED_TOKENS = {
  patientEmployerField: 'PATIENT_EMPLOYER_FIELD',
  patientOccupationField: 'PATIENT_OCCUPATION_FIELD',
  patientReferPhysField: 'PATIENT_REFER_PHYS_FIELD',
  patientInitDxField: 'PATIENT_INIT_DX_FIELD',
  patientCurrSmartFitRx: 'PATIENT_CURR_SMARTFIT_RX',
  patientMedCurField: 'PATIENT_MEDCUR_FIELD',
  patientMedHistField: 'PATIENT_MEDHIST_FIELD',
  patientMedalrgyehrField: 'PATIENT_MEDALRGYEHR_FIELD',
  patientVitalsField: 'PATIENT_VITALS_FIELD',
  patientPcpOfficeNameField: 'PATIENT_PCP_OFFICE_NAME_FIELD',
  patientPcpNameField: 'PATIENT_PCP_NAME_FIELD',
  patientPcpDegreeField: 'PATIENT_PCP_DEGREE_FIELD',
  patientPcpSpecialtyField: 'PATIENT_PCP_SPECIALTY_FIELD',
  patientPcpNpiType1Field: 'PATIENT_PCP_NPITYPE1_FIELD',
  patientPcpAddressField: 'PATIENT_PCP_ADDRESS_FIELD',
  patientPcpPhoneField: 'PATIENT_PCP_PHONE_FIELD',
  patientPcpEmailField: 'PATIENT_PCP_EMAIL_FIELD',
  patientPcpFaxField: 'PATIENT_PCP_FAX_FIELD',
  patientNeckTextField: 'PATIENT_NECKTEXT_FIELD',
  patientInitNeckScoreField: 'PATIENT_INIT_NECKSCORE_FIELD',
  patientCurrNeckScoreField: 'PATIENT_CURR_NECKSCORE_FIELD',
  patientNeckChangeField: 'PATIENT_NECKCHANGE_FIELD',
  patientLbTextField: 'PATIENT_LBTEXT_FIELD',
  patientInitLbScoreField: 'PATIENT_INIT_LBSCORE_FIELD',
  patientCurrLbScoreField: 'PATIENT_CURR_LBSCORE_FIELD',
  patientLbChangeField: 'PATIENT_LBCHANGE_FIELD',
  patientUpperExTextField: 'PATIENT_UPPEREXTEXT_FIELD',
  patientInitUpperExScoreField: 'PATIENT_INIT_UPPEREXSCORE_FIELD',
  patientCurrUpperExScoreField: 'PATIENT_CURR_UPPEREXSCORE_FIELD',
  patientUpperExchangeField: 'PATIENT_UPPEREXCHANGE_FIELD',
  patientLowerExTextField: 'PATIENTLOWEREXTEXT_FIELD',
  patientInitLowerExScoreField: 'PATIENT_INIT_LOWEREXSCORE_FIELD',
  patientCurrLowerExScoreField: 'PATIENT_CURR_LOWEREXSCORE_FIELD',
  patientLowerExchangeField: 'PATIENT_LOWEREXCHANGE_FIELD',
  patientFriTextField: 'PATIENT_FRITEXT_FIELD',
  patientInitFriScoreField: 'PATIENT_INIT_FRISCORE_FIELD',
  patientCurrFriScoreField: 'PATIENT_CURR_FRISCORE_FIELD',
  patientFriChangeField: 'PATIENT_FRICHANGE_FIELD',
  patientCurrWellness: 'PATIENT_CURR_WELLNESS',
  patientPrevWellness: 'PATIENT_PREV_WELLNESS',
  patientWellnessChange: 'PATIENT_WELLNESS_CHANGE',
  currentCarePlan: 'CURRENT_CARE_PLAN',
  currListingsVert: 'CURR_LISTINGS_VERT',
  visitsSinceCurrInjury: 'VISITS_SINCE_CURRINJURY',
  patientIntake: 'PATIENT_INTAKE',
};

export const OVERLAY_KEY_MAP = {
  [QUESTION_ANSWER_TOKEN.ask]: OVERLAY_KEYS.MACRO_NO_DEFAULT,
  [QUESTION_ANSWER_TOKEN.chooseAny]: OVERLAY_KEYS.MACRO_CHOOSE,
  [QUESTION_ANSWER_TOKEN.chooseOne]: OVERLAY_KEYS.MACRO_CHOOSE,
  [QUESTION_ANSWER_TOKEN.date]: OVERLAY_KEYS.MACRO_NO_DEFAULT,
  [QUESTION_ANSWER_TOKEN.number]: OVERLAY_KEYS.MACRO_NO_DEFAULT,
  [QUESTION_ANSWER_TOKEN.region]: OVERLAY_KEYS.MACRO_REGION,
  [QUESTION_ANSWER_TOKEN.spine]: OVERLAY_KEYS.MACRO_SPINE,
};

export const openQuestionAnswerOverlay = ({
  question,
  answerSets,
  defaultBodyAnswerSetId,
  defaultSpineAnswerSetId,
  type = question.type,
  showBackButton = true,
  macroSetId,
}) =>
  openOverlay(OVERLAY_KEY_MAP[type], {
    question: question && deepCopy(question),
    answerSets: deepCopy(answerSets),
    defaultBodyAnswerSetId,
    defaultSpineAnswerSetId,
    type,
    showBackButton,
    macroSetId,
  });

export const mergeUniqueAnswerSets = (arrayA, arrayB) => {
  const uniqueSet = new Set();

  const mergedAnswerSets = [];

  arrayA.forEach(a => {
    uniqueSet.add(a.id);
    mergedAnswerSets.push(a);
  });

  arrayB.forEach(b => {
    if (!uniqueSet.has(b.id)) {
      uniqueSet.add(b.id);
      mergedAnswerSets.push(b);
    }
  });

  return mergedAnswerSets.sort((a, b) => a.name.localeCompare(b.name));
};

export const mergeFolders = (objectA, objectB) =>
  Object.entries(objectB).reduce(
    (acc, [key, value]) =>
      !acc[key] || !acc[key].items ? { ...acc, [key]: value } : acc,
    objectA,
  );

export const getUnlinkPopupConfig = name => ({
  title: 'Unlink Choice Template',
  message: html`
    <div>This will unlink this question from the choice template: ${name}.</div>
    <br />
    <div>
      The current choices for this question will be retained, and you can update
      them as needed.
    </div>
    <br />
    <div>Do you want to continue?</div>
  `,
});

export const getLinkedChoiceTemplateName = answerSet => `Linked Choice Template:
  ${answerSet.name}${answerSet.deleted ? ' (Deleted)' : ''}`;
