import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonCancel: { id: 'button-cancel' },
  buttonCopy: { id: 'button-copy' },
  selectProvider: { id: 'select-provider' },
};

class PopupCopyAutoSalt extends NebPopup {
  static get properties() {
    return {
      __selectedProvider: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          margin-top: -${CSS_SPACING};
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-basis: auto;
          min-width: 460px;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedProvider = null;

    this.title = 'Copy Auto SALT from Existing Provider';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: () => this.onClose(null),
      change: ({ value }) => {
        this.__selectedProvider = value;
      },
      confirm: () => this.onClose(this.__selectedProvider),
    };
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.selectProvider.id}"
        label="Provider"
        .items="${this.model.providers}"
        .value="${this.__selectedProvider}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <div class="button-container">
        <neb-button
          id="${ELEMENTS.buttonCopy.id}"
          class="button"
          label="COPY"
          .onClick="${this.handlers.confirm}"
          ?disabled="${!this.__selectedProvider}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          class="button"
          label="CANCEL"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-copy-autosalt', PopupCopyAutoSalt);
