import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_MEDIUM,
} from '../../../../packages/neb-styles/neb-variables';
import {
  createEmergencyContact,
  updateEmergencyContact,
} from '../../../api-clients/emergency-contacts';
import { openSuccess, openError } from '../../../store';
import NebFormEmergencyContact from '../../forms/patient/neb-form-emergency-contact';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class NebOverlayEmergencyContact extends Overlay {
  static get properties() {
    return {
      __savingError: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_MEDIUM};
        }
        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.model = {
        ...NebFormEmergencyContact.createModel(),
        ...this.model,
      };
    }

    super.update(changedProps);
  }

  initState() {
    super.initState();

    this.__savingError = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      save: async emergencyContact => {
        try {
          const save = this.model.id
            ? updateEmergencyContact
            : createEmergencyContact;

          await save(emergencyContact);
          openSuccess('Emergency Contact saved successfully');
          this.isDirty = false;
          this.dismiss(true);
        } catch (e) {
          console.error(e);
          this.__savingError = e;
          openError('An error occurred when saving Emergency Contact');
        }
      },
    };
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.model.id ? 'Edit' : 'Add'} Emergency Contact"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>

      <neb-form-emergency-contact
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .savingError="${this.__savingError}"
      ></neb-form-emergency-contact>
    `;
  }
}

window.customElements.define(
  'neb-overlay-emergency-contact',
  NebOverlayEmergencyContact,
);
