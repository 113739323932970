import {
  SEARCH_PATIENTS,
  SET_PATIENT_DOCUMENT_COUNT,
  SET_PATIENT_CASE_COUNT,
} from './patientsAction';

export const DEFAULT_STATE = {
  documentCount: 0,
  caseCount: 0,
  search: '',
};

export const patients = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SEARCH_PATIENTS:
      return { ...state, search: action.search };

    case SET_PATIENT_DOCUMENT_COUNT:
      return { ...state, documentCount: action.count };

    case SET_PATIENT_CASE_COUNT:
      return { ...state, caseCount: action.count };

    default:
      return state;
  }
};
