import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/neb-action-bar';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { updateClaimsDataV1 } from '../../neb-api-client/src/claims-data';
import {
  startEncounterHistory,
  createEncounterHistory,
} from '../../neb-api-client/src/encounters-api-client';
import { saveLineItemsModifiers } from '../../neb-api-client/src/ledger/line-items';
import { checkCreateEncounterHistory } from '../../neb-api-client/src/services/encounter-charge/save-charges';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

import '../../../src/components/tables/claims-worklist/claim-charges/neb-table-modifiers-edit';
import '../../../src/components/forms/neb-form-modifiers-edit';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

class NebPopupModifiersEdit extends NebPopup {
  static get properties() {
    return {
      model: Object,
      __scrollMode: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {
      items: [
        {
          encounterCharge: { encounterId: '', signed: false },
          encounterNumber: '',
          modifiers: ['', '', '', ''],
          claimId: null,
          status: null,
        },
      ],
    };

    this.__scrollMode = false;

    this.onSave = () => {};
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      save: async charges => {
        const {
          encounterCharge: { encounterId, signed },
          status,
          claimId,
        } = this.model.items[0];

        const editedLineItems = charges.reduce((accum, charge, index) => {
          if (!equal(charge.modifiers, this.model.items[index].modifiers)) {
            accum.push({
              id: charge.id,
              modifier_1: charge.modifiers[0],
              modifier_2: charge.modifiers[1],
              modifier_3: charge.modifiers[2],
              modifier_4: charge.modifiers[3],
            });
          }

          return accum;
        }, []);

        try {
          await checkCreateEncounterHistory(
            encounterId,
            !!signed,
            startEncounterHistory,
          );

          await saveLineItemsModifiers(charges);

          if (claimId && status === 'Draft') {
            await updateClaimsDataV1({
              claimDrafts: this.model.invoiceClaimIds.map(invoiceClaimId => ({
                id: invoiceClaimId,
                lineItems: editedLineItems,
              })),
            });
          }

          await checkCreateEncounterHistory(
            encounterId,
            !!signed,
            createEncounterHistory,
          );

          store.dispatch(openSuccess('Modifiers saved successfully'));

          await this.onClose(true);
        } catch (e) {
          console.error(e);
          store.dispatch(openError('An error occurred when saving modifiers'));
        }
      },
      cancel: () => this.onClose(),
    };
  }

  connectedCallback() {
    const { encounterNumber } = this.model.items[0];

    this.title = `Encounter - ${encounterNumber}`;
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([layout='small']) {
          width: 90%;
        }
        .content {
          display: flex;
          width: 100%;
        }
        .view {
          display: flex;
          width: 100%;
          height: 100%;
        }
        .modifiers {
          width: 100%;
        }
        .header {
          padding-top: 15px;
          padding-right: 5px;
          margin-left: 15px;
        }
        .container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding: 15px 15px 0px;
        }
        .padding {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        :host([__scrollMode]) .header {
          padding-top: 35px;
          padding-right: 5px;
          margin-left: 35px;
        }

        :host([__scrollMode]) .container {
          padding: 0px 10px 5px 0px;
        }

        :host([__scrollMode]) .padding {
          padding-left: 25px;
          padding-bottom: 20px;
        }
      `,
    ];
  }

  __setScrollMode() {
    const items = this.model.items.filter(m => m.modifiers).length;
    this.__scrollMode = items > 6;
  }

  renderContent() {
    this.__setScrollMode();
    return html`
      <div
        class="container padding ${this.__scrollMode ? 'relative' : 'fixed'}"
      >
        <neb-form-modifiers-edit
          .id="${ELEMENTS.form.id}"
          .model="${this.model.items}"
          ?isScrollable="${this.__scrollMode}"
          .onSave="${this.handlers.save}"
          .onCancel="${this.handlers.cancel}"
        ></neb-form-modifiers-edit>
      </div>
    `;
  }
}

customElements.define('neb-popup-modifiers-edit', NebPopupModifiersEdit);
