import '../../controls/neb-button-action';
import '../../tables/neb-table';

import { LitElement, html, css } from 'lit';

import { getPatientAuthorizations } from '../../../../../neb-api-client/src/patient-authorization-api-client';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_7,
} from '../../../../../neb-styles/neb-variables';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';

export const ELEMENTS = {
  addAuthorizationButton: {
    id: 'add-authorization-button',
  },
  authorizationTable: {
    id: 'authorization-table',
  },
};

export const NO_ITEMS_TEXT = 'There is no authorization linked to this case.';

const CONFIG = [
  {
    key: 'authorizationNumber',
    label: 'Authorization # (Or Name)',
    flex: css`5 0 0`,
  },
  {
    key: 'authorizedDates',
    label: 'Authorized Dates',
    flex: css`5 0 0`,
  },
  {
    key: 'totalAuthorizedSummary',
    label: 'Total Authorized',
    flex: css`5 0 0`,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`5 0 0`,
  },
];

class NebAuthorizationDetails extends LitElement {
  static get properties() {
    return {
      __authorizations: {
        type: Array,
      },
      patientId: {
        type: String,
      },
      caseId: {
        type: String,
      },
      hidden: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__authorizations = [];
    this.patientId = '';
    this.caseId = '';
    this.hidden = false;
    this.layout = '';

    this.onAuthorizationChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addAuthorization: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          patientId: this.patientId,
          patientCaseId: this.caseId,
          id: null,
          add: true,
        });

        if (result) {
          this.__authorizations = await getPatientAuthorizations(
            this.patientId,
            this.caseId,
          );

          this.onAuthorizationChange(this.__authorizations);
        }
      },
      selectAuthorization: async (_, authorizationData) => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          patientId: authorizationData.patientId,
          patientCaseId: authorizationData.patientCaseId,
          id: authorizationData.id,
        });

        if (result) {
          this.__authorizations = await getPatientAuthorizations(
            authorizationData.patientId,
            authorizationData.patientCaseId,
          );

          this.onAuthorizationChange(this.__authorizations);
        }
      },
    };
  }

  async firstUpdated(changedProps) {
    this.__authorizations = await getPatientAuthorizations(
      this.patientId,
      this.caseId,
    );

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      this.__authorizations = await getPatientAuthorizations(
        this.patientId,
        this.caseId,
      );

      super.updated(changedProps);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
          width: unset;
          height: unset;
        }

        .authorization-form {
          width: 100%;
          height: 100%;
          overflow: auto;
        }

        .button-container {
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .add-authorization-button {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .table {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderAddAuthorizationButton() {
    return html`
      <div class="button-container">
        <neb-button-action
          id="${ELEMENTS.addAuthorizationButton.id}"
          class="add-authorization-button"
          label="Add Authorization"
          .onClick="${this.__handlers.addAuthorization}"
        ></neb-button-action>
      </div>
    `;
  }

  render() {
    return html`
      <div class="authorization-form">
        ${this.__renderAddAuthorizationButton()}

        <neb-table
          id="${ELEMENTS.authorizationTable.id}"
          class="table"
          mode="detail"
          emptyMessage="${NO_ITEMS_TEXT}"
          .config="${CONFIG}"
          .onSelectRow="${this.__handlers.selectAuthorization}"
          .model="${this.__authorizations}"
        ></neb-table>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-authorization-details',
  NebAuthorizationDetails,
);
