import '../../../../packages/neb-www-practice-charting/src/components/problems/neb-charting-problems-summary';

import { html } from 'lit';

import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  view: { id: 'view' },
};

const PROBLEM_LIST_ITEM_NAME = 'Problem List';

class NebUnsignedEncounterProblemListSummary extends NebUnsignedEncounterSummaryPanel {
  static get properties() {
    return {
      __activeProblems: Array,
    };
  }

  createModel() {
    return [];
  }

  initState() {
    this.__activeProblems = [];
  }

  getTitle() {
    if (!this.model) return PROBLEM_LIST_ITEM_NAME;

    const activeCount = this.__activeProblems.length;

    return activeCount > 0
      ? `${PROBLEM_LIST_ITEM_NAME} (${activeCount})`
      : PROBLEM_LIST_ITEM_NAME;
  }

  __getActiveProblems() {
    return this.model.filter(p => p.status === 'active');
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__activeProblems = this.__getActiveProblems();
    }
  }

  renderContent() {
    return html`
      <neb-charting-problems-summary
        id="${ELEMENTS.view.id}"
        class="problems"
        .problemList="${this.__activeProblems}"
      ></neb-charting-problems-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-problem-list-summary',
  NebUnsignedEncounterProblemListSummary,
);
