import { APPOINTMENTS_API_URL } from '../../neb-utils/env';

import DeprecatedApiClient, {
  buildQueryString,
} from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const billingClient = new DeprecatedApiClient({
  microservice: 'billing',
});

export const appointmentsClient = new ApiClientV2({
  microservice: 'appointments',
});

function parseAvailability(payload) {
  return Object.entries(payload).reduce(async (accP, [key, value]) => {
    const acc = await accP;

    return { ...acc, [key]: value.map(i => i / 12) };
  }, Promise.resolve({}));
}

export async function get(query, signal) {
  const res = await appointmentsClient.makeRequest({
    path: `/api/v3/tenants/:tenantId/availability${buildQueryString(query)}`,
    signal,
  });

  return parseAvailability(res.data[0]);
}

export async function getPublic(shortName, query) {
  const url = `${APPOINTMENTS_API_URL}/public/v2/${shortName}/availability${buildQueryString(
    query,
  )}`;

  const res = await fetch(url, {
    method: 'GET',
  });

  const json = await res.json();

  return parseAvailability(json.data[0]);
}

export async function getWalkInPublic(shortName, query) {
  const url = `${APPOINTMENTS_API_URL}/public/v2/${shortName}/availability/walkIn${buildQueryString(
    query,
  )}`;

  const res = await fetch(url, {
    method: 'GET',
  });

  const json = await res.json();

  return json.data;
}

export async function getValidAvailability(query, signal) {
  const res = await appointmentsClient.makeRequest({
    path: '/api/v2/tenants/:tenantId/availability/validate',
    body: JSON.stringify(query),
    method: 'POST',
    signal,
  });

  return res;
}
