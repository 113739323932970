import '../../../neb-text';

import { css, html } from 'lit';
import moment from 'moment-timezone';

import { store } from '../../../../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../../../../neb-utils/date-util';
import {
  centsToCurrency,
  objToName,
  DEFAULT_NAME_OPTS,
  centsToCurrencyWithNegative,
} from '../../../../../../neb-utils/formatters';

const NO_INVOICE = 'Not Invoiced';

export const formatAdjustmentAmount = value => {
  const adjustmentAmount = centsToCurrencyWithNegative(value);

  const isValueNegative = adjustmentAmount.charAt(0) === '-';

  return isValueNegative
    ? `($${adjustmentAmount.slice(2)})`
    : `${adjustmentAmount}`;
};

// eslint-disable-next-line complexity
export const getConfigs = ({ patient, showCheck = true } = {}) => {
  const outerConfig = [
    ...(showCheck
      ? [
          {
            key: 'checked',
            label: '',
            flex: css`0 0 28px`,
          },
        ]
      : []),
    ...(!patient
      ? [
          {
            key: 'patient',
            label: 'Patient',
            flex: css`1 0 95px`,
            iconWidth: 21,
          },
        ]
      : []),
    {
      key: 'case',
      label: 'Case',
      flex: css`1 0 60px`,
    },
    {
      key: 'primaryPayer',
      label: 'Prim. Payer',
      flex: css`1 0 95px`,
      truncate: true,
    },
    {
      key: 'plan',
      label: 'Plan Name',
      flex: css`1 0 90px`,
    },
    {
      key: 'secondaryPayer',
      label: 'Sec. Payer',
      flex: css`1 0 90px`,
    },
    {
      key: 'carePackage',
      label: 'Care Pkg',
      flex: css`1 0 80px`,
    },
    {
      key: 'invoiceNumber',
      label: 'Invoice',
      flex: css`0 0 80px`,
      formatter: v => (v !== null ? v : NO_INVOICE),
    },
    {
      key: 'dateOfAllServices',
      label: 'Dates of Service    ',
      flex: css`0 0 80px`,
      formatter: v => html` <div>${v}</div> `,
    },
    {
      key: 'billedAmountTotal',
      label: 'Billed',
      flex: css`0 0 80px`,
      formatter: v => html` <neb-text bold>${centsToCurrency(v)}</neb-text> `,
    },
    {
      key: 'allowedAmountTotal',
      label: 'Allowed',
      flex: css`0 0 80px`,
      formatter: v => html`
        <neb-text bold>${v !== null ? centsToCurrency(v) : ''}</neb-text>
      `,
    },

    {
      key: 'payerResponsibility',
      label: 'Payer Paid / Owed',
      flex: css`0 0 140px`,
      formatter: v => html` <neb-text bold>${v}</neb-text> `,
    },
    {
      key: 'patientResponsibility',
      label: 'Patient Paid / Owed',
      flex: css`0 0 140px`,
      formatter: v => html` <neb-text bold>${v}</neb-text> `,
    },
    {
      key: 'adjustmentTotal',
      label: 'Adj.',
      flex: css`1 0 70px`,
      formatter: v => html`
        <neb-text bold>${formatAdjustmentAmount(v)}</neb-text>
      `,
    },
    {
      key: 'balanceTotal',
      label: 'Balance',
      flex: css`0 0 70px`,
      formatter: v => html` <neb-text bold>${centsToCurrency(v)}</neb-text> `,
    },
  ];

  const innerConfig = [
    {
      key: 'checked',
      label: '',
      flex: css`0 0 28px`,
    },
    ...(!patient
      ? [
          {
            key: 'mrn',
            label: 'MRN',
            flex: css`1 0 95px`,
          },
        ]
      : []),
    {
      key: 'billed',
      label: 'Billed',
      flex: css`1 0 56px`,
      mobile: false,
    },
    {
      key: 'hold',
      label: 'Hold',
      flex: css`1 0 56px`,
      mobile: false,
    },
    {
      key: 'chargeNumber',
      label: 'Charge',
      flex: css`1 0 65px`,
      mobile: true,
    },
    {
      key: 'encounter',
      label: 'Encounter   ',
      flex: css`1 0 65px`,
    },
    {
      key: 'claimNumber',
      label: 'Claim',
      flex: css`1 0 50px`,
      mobile: true,
    },

    {
      key: 'associatedERAsAndEOBs',
      label: 'ERA/EOB',
      flex: css`1 0 50px`,
      mobile: true,
    },
    {
      key: 'provider',
      label: 'Provider',
      flex: css`1 0 65px`,
    },
    ...[
      {
        key: 'location',
        label: 'Location',
        flex: css`1 0 65px`,
      },
    ],
    {
      key: 'primaryDiagnosis',
      label: 'Dx',
      flex: css`1 0 45px`,
    },
    {
      key: 'dateOfService',
      label: 'DOS',
      flex: css`1 0 55px`,
      formatter: v => parseDate(v).format('MM/DD/YYYY'),
    },
    {
      key: 'code',
      label: 'Proc + Mod',
      flex: css`1 0 70px`,
    },
    {
      key: 'units',
      label: 'Units',
      flex: css`1 0 40px`,
    },
    {
      key: 'billedAmount',
      label: 'Billed',
      flex: css`0 0 100px`,
      formatter: v => centsToCurrency(v),
    },
    {
      key: 'allowedAmount',
      label: 'Allowed',
      flex: css`0 0 100px`,
      formatter: v => (v !== null ? centsToCurrency(v) : ''),
    },

    {
      key: 'primary',
      label: 'Primary',
      flex: css`0 0 0`,
    },
    {
      key: 'secondary',
      label: 'Secondary',
      flex: css`0 0 0`,
    },
    {
      key: 'patientResponsibility',
      label: 'Patient Responsible',
      flex: css`0 0 0`,
    },
    {
      key: 'adjustment',
      label: 'Adj.',
      flex: css`0 0 90px`,
      formatter: v => formatAdjustmentAmount(v),
    },
    {
      key: 'balance',
      label: 'Balance',
      flex: css`0 0 90px`,
      formatter: v => centsToCurrency(v),
    },
    {
      key: 'detail',
      label: ' ',
      flex: css`0 0 12px`,
    },
  ];

  const segments = [
    {
      innerCount: innerConfig.length,
      outerCount: outerConfig.length,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
    {
      innerCount: 2,
      outerCount: 1,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
    {
      innerCount: 1,
      outerCount: 1,
    },
  ];

  return {
    outerConfig,
    innerConfig,
    segments,
  };
};

export const formatResponsibility = (paid, owed, showBalance = false) => {
  if (!owed) return '';

  const responsibility = `${centsToCurrency(paid)} / ${centsToCurrency(owed)}`;

  return showBalance
    ? `${responsibility} = ${centsToCurrency(owed - paid)}`
    : responsibility;
};

export const formatCode = (code, modifiers) => {
  const modStr = modifiers ? modifiers.filter(m => !!m).join(', ') : '';
  return [code, modStr].filter(item => item).join(' - ');
};

export const createProviderDict = () => {
  const {
    providers: { item: providers },
  } = store.getState();

  return providers.reduce(
    (acc, p) => ({
      ...acc,
      [p.id]: objToName(p.name, DEFAULT_NAME_OPTS),
    }),
    Object.create(null),
  );
};

export const getFormattedDate = ({ dateOfAllServices = [] }) => {
  if (dateOfAllServices.length === 0) return '';

  const formatDate = date =>
    parseDate(moment.utc(date.toString())).format('MM/DD/YYYY');

  const dateOfService = Array.from(new Set(dateOfAllServices));

  if (dateOfService && dateOfService.length > 1) {
    const convertToObjects = dateOfService.map((str, index) => ({
      value: str,
      id: index + 1,
    }));

    const findMinDate = new Date(
      Math.min(...convertToObjects.map(element => new Date(element.value))),
    );
    const formattedMinDate = formatDate(findMinDate);

    const findMaxDate = new Date(
      Math.max(...convertToObjects.map(element => new Date(element.value))),
    );
    const formattedMaxDate = formatDate(findMaxDate);

    return html`
      <span class="min-dos">${formattedMinDate}</span>
      <span class="separator-dos">-</span>
      <span class="max-dos">${formattedMaxDate}</span>
    `;
  }

  return formatDate(dateOfService);
};
