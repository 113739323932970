const DATE_REGEX = /(\d{4})-(\d{2})-(\d{2})/;

export const TIME_SLOT_HEIGHT = 70;

export function calcMinPerInterval(interval) {
  return 60 / interval;
}

export function format24Time(hour, min) {
  const hourStr = (hour < 10 ? '0' : '') + hour;
  const minStr = (min < 10 ? '0' : '') + min;
  return `${hourStr}:${minStr}`;
}

export function formatLocaleTime(hour, min) {
  let _hour = hour % 24;

  const amOrPm = _hour < 12 ? 'AM' : 'PM';
  _hour = _hour === 0 ? 12 : _hour;
  const hourStr = _hour > 12 ? _hour - 12 : _hour;
  const minStr = (min < 10 ? '0' : '') + min;
  return `${hourStr}:${minStr} ${amOrPm}`;
}

export function calcIntervalArray(interval, start, end) {
  function isMinuteInRange(min, hour, firstMin, lastMin, firstHour, lastHour) {
    return (
      (min >= firstMin || hour !== firstHour) &&
      (lastMin === 0 || min < lastMin || hour !== lastHour - 1)
    );
  }

  const minPerInterval = calcMinPerInterval(interval);
  const intervalArray = [];

  const firstHour = Math.floor(start / 60);
  const lastHour = Math.ceil(end / 60);
  const firstMin = start % 60;
  const lastMin = end % 60;

  for (let hour = firstHour; hour < lastHour; hour++) {
    for (let i = 0; i < interval; i++) {
      const min = minPerInterval * i;

      if (isMinuteInRange(min, hour, firstMin, lastMin, firstHour, lastHour)) {
        intervalArray.push({
          hour,
          min,
          time24: format24Time(hour, min),
          time: formatLocaleTime(hour, min),
        });
      }
    }
  }

  return intervalArray;
}

export function roundUpTo5MinuteIncrement(date) {
  let min = date.minutes();

  const minMod = min % 5;

  let hour = date.hours();
  min = minMod ? min + (5 - minMod) : min;

  if (min === 60) {
    hour += 1;
    min = 0;
  }
  const newDate = date.clone().hour(hour).minutes(min);

  return {
    hour,
    min,
    time: newDate.format('h:mm A'),
  };
}

export function parseDateTime(dateTimeString) {
  const match = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/.exec(dateTimeString);
  return new Date(
    parseInt(match[1], 10),
    parseInt(match[2] - 1, 10),
    parseInt(match[3], 10),
    parseInt(match[4], 10),
    parseInt(match[5], 10),
  );
}

export function createDateTime(dateString, time24) {
  const match = DATE_REGEX.exec(dateString);
  const hour = Math.trunc(time24);
  const minutesFrac = time24 % 1;
  const minutes = Math.round(minutesFrac * 60);
  return new Date(
    parseInt(match[1], 10),
    parseInt(match[2] - 1, 10),
    parseInt(match[3], 10),
    hour,
    minutes,
  );
}

/**
 *
 * @param {Date} date
 */
export const toDateString = date => {
  const year = `${date.getFullYear()}`;
  const month =
    date.getMonth() >= 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;
  const day = date.getDate() >= 10 ? `${date.getDate()}` : `0${date.getDate()}`;
  return `${year}-${month}-${day}T00:00`;
};

export const today = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
