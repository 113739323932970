export const CHARGES_ORIGIN = Object.freeze({
  LEDGER: 'ledger',
  ERA: 'era',
  EOB: 'eob',
});

export const EDIT_MODE = Object.freeze({
  DISABLED: 'disabled',
  HEADER: 'header',
  TABLE: 'table',
  ASSOCIATE: 'associate',
  MATCH: 'match',
  MANUAL_POST: 'manual-post',
});

export const API_VERSION = Object.freeze({
  ledger: 3,
  era: 1,
  eob: 1,
});

export const getModifiersFromChargeItem = item => {
  const { charge } = item;

  return charge && charge.modifiers
    ? charge.modifiers.filter(m => m).join(', ')
    : '';
};
