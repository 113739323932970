import '../../../../packages/neb-www-practice-charting/src/components/notes/neb-charting-notes-summary';

import { html } from 'lit';

import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

class NebUnsignedEncounterNotesSummary extends NebUnsignedEncounterSummaryPanel {
  createModel() {
    return {
      subjective: '',
      objective: '',
      assessment: '',
      plan: '',
    };
  }

  getTitle() {
    return 'Chart Notes';
  }

  renderContent() {
    return html`
      <neb-charting-notes-summary
        id="${ELEMENTS.view.id}"
        .notes="${this.model}"
      ></neb-charting-notes-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-notes-summary',
  NebUnsignedEncounterNotesSummary,
);
