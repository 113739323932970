import '../../filters/neb-filters-era-eob-management';
import '../../tables/era-eob/neb-table-era-eob-management';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';
import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FetchService,
  SORT_DIR,
} from '../../../../packages/neb-utils/services/fetch';
import { setValueByPath } from '../../../../packages/neb-utils/utils';
import * as eraApi from '../../../api-clients/era';
import * as eraEobApi from '../../../api-clients/era-eob';
import { ERA_EOB_STATUS } from '../../../utils/era';
import {
  CLOSED,
  ERA_REPROCESSED_ERROR_MESSAGE,
  ERA_REPROCESSED_SUCCESS_MESSAGE,
  NO,
  OPEN,
  REPROCESSING_ERA_INFO,
  REPROCESSING_ERA_TITLE,
  REVIEW_CLOSED_ERAS,
  REVIEW_OPEN_ERAS,
  YES,
} from '../../../utils/user-message';

export const ELEMENTS = {
  header: { id: 'header' },
  table: { id: 'table' },
  pagination: { id: 'pagination' },
  filters: { id: 'filters' },
  casesUnsupportedPage: { id: 'cases-unsupported-page' },
  tabs: {
    id: 'tabs',
  },
  description: { id: 'description' },
  buttonBar: { id: 'button-bar' },
};

export const TABS = {
  CLOSED,
  OPEN,
};

export const reprocessEraConfirmText = {
  title: REPROCESSING_ERA_TITLE,
  message: REPROCESSING_ERA_INFO,
  confirmText: YES,
  cancelText: NO,
};

class NebPracticeDetailsEra extends LitElement {
  static get properties() {
    return {
      __model: Array,
      __state: Object,
      __sortParams: Object,
      __selectedTab: String,

      description: String,
      isSupportApp: { type: Boolean, reflect: true },
      refreshing: { type: Boolean },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          flex: 1 0 0;
          overflow-y: auto;
        }

        .description {
          padding: ${CSS_SPACING};
          margin: 0;
        }

        .header-container {
          display: flex;
          align-items: center;
          margin: 5px ${CSS_SPACING} ${CSS_SPACING};
        }

        .header-text {
          flex: 1 0 0;
          height: fit-content;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .pagination {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .span {
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__selectedTab = TABS.OPEN;
    this.__state = FetchService.createModel();
    this.__initialSortKey = 'number';

    this.onRefreshTable = () => {};
    this.__sortParams = {
      key: this.__initialSortKey,
      dir: SORT_DIR.DESC,
    };

    this.layout = '';
    this.isSupportApp = true;
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: index => {
        this.__service.setPageIndex(index);
      },
      changeState: state => {
        this.__state = state;
      },
      change: e => {
        setValueByPath(this.__state.pageItems, e.name.split('.'), e.value);
        this.__state = { ...this.__state };
      },
      changeSort: (_name, result) => {
        const sortKey = 'sortField';
        this.__sortParams = result[0];
        this.__service.setQuery(sortKey, result[0].key);
        this.__service.setQuery('sortDir', result[0].dir);
      },
      tableChange: e => this.__handleRowCheck(e),
      selectItemCell: (_, key, index) => this.__selectCell(key, index),
      deselectAll: () => {
        this.__state = this.__setChecked(false);
      },
      reprocessEra: async ({ reportId }) => {
        const accepted = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          reprocessEraConfirmText,
        );

        if (accepted) {
          try {
            await eraApi.reprocessEra(reportId);

            store.dispatch(openSuccess(ERA_REPROCESSED_SUCCESS_MESSAGE));
          } catch (e) {
            console.error(e);
            store.dispatch(openError(ERA_REPROCESSED_ERROR_MESSAGE));
          }
        }
      },
      applyFilters: model => {
        const filterQueries = this.__createFilterQueries(model);

        Object.entries(filterQueries).forEach(([k, v]) => {
          this.__service.setQuery(k, v);
        });
      },
      selectTab: selectedTab => {
        this.__service.setPageIndex(0);
        this.__selectedTab = selectedTab;
      },
    };
  }

  __initServices() {
    const client = async query => {
      const payload = {
        ...query,
        status: this.__selectedTab,
        type: 'ERA',
      };

      const data = await eraEobApi.getData({ ...payload });
      return data;
    };

    if (this.__service) {
      this.__service.cancel();
    }

    this.__service = new FetchService(
      { onChange: this.__handlers.changeState },
      client,
      {
        hideInactive: false,
        sortParams: this.sortParams,
      },
    );

    this.__load();
  }

  // eslint-disable-next-line complexity
  __createFilterQueries(model) {
    let value;

    const excludedProperties = [
      'date',
      'payerAmount',
      'balanceAmount',
      'reportId',
      'paymentId',
    ];

    const filteredQuery = Object.entries(model)
      .map(([k, v]) => {
        value = v;

        if (excludedProperties.includes(k)) return {};

        if (Array.isArray(v)) value = !v.length ? undefined : v;
        return { [k]: value || undefined };
      })
      .reduce((o, element) => ({ ...o, ...element }), {});

    return {
      ...filteredQuery,
      dateFrom: model.date.from
        ? model.date.from.format('YYYY-MM-DD')
        : undefined,
      dateTo: model.date.to ? model.date.to.format('YYYY-MM-DD') : undefined,
      payerAmountMin:
        model.payerAmount.min !== null ? model.payerAmount.min : undefined,
      payerAmountMax:
        model.payerAmount.max !== null ? model.payerAmount.max : undefined,
      balanceAmountMin:
        model.balanceAmount.min !== null ? model.balanceAmount.min : undefined,
      balanceAmountMax:
        model.balanceAmount.max !== null ? model.balanceAmount.max : undefined,
      status: this.__selectedTab,
      reportNumber: model.reportId.length ? model.reportId : undefined,
      paymentNumber: model.paymentId.length ? model.paymentId : undefined,
    };
  }

  __load() {
    this.__currentTabMessages();
    this.__service.fetch();
  }

  __currentTabMessages() {
    const currentTabIsOpen = this.__selectedTab === TABS.OPEN;

    this.statusToUpdate = currentTabIsOpen
      ? ERA_EOB_STATUS.CLOSED
      : ERA_EOB_STATUS.OPEN;

    this.description = currentTabIsOpen ? REVIEW_OPEN_ERAS : REVIEW_CLOSED_ERAS;
  }

  updated(changedProps) {
    if (changedProps.has('__selectedTab')) {
      this.__load();
    }

    super.updated(changedProps);
  }

  __refresh() {
    this.__load();
    this.onRefreshTable();
  }

  async __selectCell(key, index) {
    if (key !== 'checked') {
      const { reportId } = this.__state.pageItems[index];
      await openOverlay(OVERLAY_KEYS.REPORT_LOGS, {
        reportId,
      });
    }
  }

  __setChecked(checked) {
    this.__state.pageItems = this.__state.pageItems.map(item => ({
      ...item,
      checked,
    }));

    return { ...this.__state };
  }

  __handleRowCheck(e) {
    const terms = e.name.split('.');
    const index = terms[0];

    const updatedItem = { ...this.__state.pageItems[index] };
    updatedItem.checked = e.value;
    this.__state.pageItems.splice(index, 1, updatedItem);
    this.__state = {
      ...this.__state,
      pageItems: [...this.__state.pageItems],
    };
  }

  __getTabs() {
    return [
      {
        id: TABS.OPEN,
        label: TABS.OPEN,
      },
      {
        id: TABS.CLOSED,
        label: TABS.CLOSED,
      },
    ];
  }

  __renderFilters() {
    return html`
      <neb-filters-era-eob-management
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.__handlers.applyFilters}"
        .isSupportApp="${this.isSupportApp}"
      ></neb-filters-era-eob-management>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-era-eob-management
        id="${ELEMENTS.table.id}"
        class="table"
        .sortParams="${[this.__sortParams]}"
        .model="${this.__state.pageItems}"
        .onChange="${this.__handlers.tableChange}"
        .onDeselectAll="${this.__handlers.deselectAll}"
        .onReprocessEra="${this.__handlers.reprocessEra}"
        .onSort="${this.__handlers.changeSort}"
        .onSelectCell="${this.__handlers.selectItemCell}"
        .isSupportApp="${this.isSupportApp}"
      ></neb-table-era-eob-management>
    `;
  }

  __renderTitle() {
    return html`
      <div class="header-container">
        <div id="${ELEMENTS.header.id}" class="header-text">
          ERA Charge Management
        </div>
      </div>
    `;
  }

  __renderPagination() {
    return this.__state.pageCount > 1
      ? html`
          <neb-pagination
            id="${ELEMENTS.pagination.id}"
            class="pagination"
            .currentPage="${this.__state.pageIndex}"
            .onPageChanged="${this.__handlers.pageChanged}"
            .pageCount="${this.__state.pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        .layout="${this.layout}"
        .items="${this.__getTabs()}"
        .selectedId="${this.__selectedTab}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>

      <p class="description" id="${ELEMENTS.description.id}">
        ${this.description}
      </p>
    `;
  }

  render() {
    return html`
      ${this.__renderTitle()} ${this.__renderTabs()} ${this.__renderFilters()}
      ${this.__renderTable()} ${this.__renderPagination()}
    `;
  }
}

customElements.define('neb-practice-details-era', NebPracticeDetailsEra);
