import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-date-picker';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { DATE_OF_CURRENT_ILLNESS_QUALIFIER } from '../../neb-utils/claims';
import { parseDate } from '../../neb-utils/date-util';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  radioButtons: { selector: 'neb-radio-button' },
  datePickers: { selector: 'neb-date-picker' },
};

class NebPopupClaimRadioDatePicker extends NebPopupClaim {
  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__state = {
          qualifier: name,
          date: value,
        };

        this.__errors = name && !value ? { [name]: 'Required' } : {};
      },
      isDateSelectable: date => date <= parseDate(),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-radio {
          display: flex;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = {
        qualifier: this.model.qualifier,
        date: this.model.date,
      };
    }
  }

  __isChecked(qualifier, date) {
    return (
      this.__state.qualifier === qualifier &&
      (qualifier === '431' ? date.isSame(this.__state.date) : true)
    );
  }

  __formatLabel(key, date) {
    if (key === 'none') return 'None';
    const { label, value } = DATE_OF_CURRENT_ILLNESS_QUALIFIER[key];
    return key === 'pregnancyLastMenstrualPeriod'
      ? `${value} (${label})`
      : `${value} (${label}) - ${date && date.format('MM/DD/YYYY')}`;
  }

  __renderDatePicker(qualifier, showDatePicker) {
    return showDatePicker
      ? html`
          <neb-date-picker
            placeholder="Date"
            momentFlag
            .name="${qualifier}"
            .selectedDate="${this.__state.qualifier === qualifier
              ? this.__state.date
              : ''}"
            .isDateSelectable="${this.handlers.isDateSelectable}"
            .invalidText="${this.__errors[qualifier]}"
            .disabled="${this.__state.qualifier !== qualifier}"
            .onChange="${this.handlers.change}"
            .invalid="${!!this.__errors[qualifier]}"
          ></neb-date-picker>
        `
      : '';
  }

  renderContent() {
    return html`
      <div>
        ${this.model.items.map(
          ({ name, qualifier, date, showDatePicker }) => html`
            <div class="container-radio">
              <neb-radio-button
                class="spacing"
                label="${this.__formatLabel(name, date)}"
                name="${qualifier}"
                .value="${date}"
                .checked="${this.__isChecked(qualifier, date)}"
                .onChange="${this.handlers.change}"
              ></neb-radio-button>
              ${this.__renderDatePicker(qualifier, showDatePicker)}
            </div>
          `,
        )}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-radio-date-picker',
  NebPopupClaimRadioDatePicker,
);
