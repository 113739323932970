const DEFAULT_ITEM_HEIGHT = 48;
const WRAP_LIMIT = 36;
const SPACING = DEFAULT_ITEM_HEIGHT - WRAP_LIMIT;

export const ITEM_MIN_WIDTH = 300;

export const getDiagnosisItemHeight = diagnoses =>
  diagnoses.reduce((acc, diagnosis) => {
    if (!diagnosis.label) return DEFAULT_ITEM_HEIGHT;

    const { length } = diagnosis.label;

    return acc > length ? acc : length + SPACING;
  }, DEFAULT_ITEM_HEIGHT);

export function formatDiagnoses(diagnoses) {
  return diagnoses.map(({ code, description, encounterId }) => ({
    label: `${code} - ${description}`,
    data: {
      code,
      description,
      encounterId,
    },
  }));
}
