import '../../../neb-text';
import '../../../controls/neb-button-bar';
import '../../../tables/neb-table-selected-line-items';
import '../../../controls/neb-tab-group';
import '../../../controls/neb-menu-button';
import '../../../neb-tooltip';
import '../../../../../../../src/components/tables/ledger/neb-table-ledger-charges-claim';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import { BILL_TYPE } from '../../../../../../neb-utils/neb-ledger-util';

export const ELEMENTS = {
  addClaimButton: { id: 'button-add-claim' },
  tableClaims: { id: 'table-claims' },
};

const TEXT_TOOLTIP =
  'To add a new claim, the charge group must be invoiced, there must be at least one encounter charge on the Invoice, and there must be a primary insurance plan selected.';

class NebLedgerChargesClaimSection extends LitElement {
  static get properties() {
    return {
      isEditing: { type: Boolean },
      hasProvider: Boolean,
      hasEncounterCharges: { type: Boolean },
      hasSecondaryHoldClaim: { type: Boolean },
      insurances: { type: Array },
      payerInfo: { type: Object },
      model: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          align-items: center;
          padding-bottom: ${CSS_SPACING};
        }

        .tooltip {
          padding-left: 10px;
        }

        .button-add-claim {
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = [];
    this.isEditing = false;
    this.hasProvider = false;
    this.hasEncounterCharges = false;
    this.hasSecondaryHoldClaim = false;

    this.insurances = [];
    this.layout = 'large';

    this.payerInfo = {
      billType: BILL_TYPE.SELF,
      invoiceId: null,
      primaryInsuranceId: null,
      secondaryInsuranceIds: [],
    };

    this.onAddClaim = () => {};

    this.onSelectClaim = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addClaim: selectedItem => this.onAddClaim(selectedItem),

      selectClaim: (_name, key, index) => {
        this.onSelectClaim(key, index);
      },
    };
  }

  get __isDisabled() {
    return (
      this.isEditing ||
      !this.payerInfo.insuranceId ||
      !this.payerInfo.invoiceId ||
      !this.hasEncounterCharges
    );
  }

  __formatOption(insuranceType, insuranceId) {
    const insurance = this.insurances.find(
      ({ data }) => data.id === insuranceId,
    );

    return {
      label: `Add ${insuranceType} Claim - ${
        insurance.data.planInfo.planName
      } (${insurance.data.payerPlan.payerName})`,
      id: insurance.data.id,
    };
  }

  __buildClaimOptions() {
    if (this.__isDisabled) {
      return [];
    }

    const { insuranceId: primaryInsuranceId } = this.payerInfo;

    const options = [this.__formatOption('Primary', primaryInsuranceId)];

    if (!this.hasSecondaryHoldClaim) {
      const secondaryOptions = this.payerInfo.secondaryInsuranceIds
        .map(id => this.__formatOption('Secondary', id))
        .sort((a, b) => a.label.localeCompare(b.label));

      options.push(...secondaryOptions);
    }

    return options;
  }

  render() {
    return html`
      <div class="container">
        <neb-menu-button
          id="${ELEMENTS.addClaimButton.id}"
          class="button-add-claim"
          label="Add"
          align="left"
          .items="${this.__buildClaimOptions()}"
          .onSelect="${this.__handlers.addClaim}"
          ?disabled="${this.__isDisabled}"
        >
        </neb-menu-button>

        <neb-tooltip class="tooltip"
          ><div slot="tooltip">${TEXT_TOOLTIP}</div>
        </neb-tooltip>
      </div>

      <neb-table-ledger-charges-claim
        id="${ELEMENTS.tableClaims.id}"
        .model="${this.model}"
        .onSelectCell="${this.__handlers.selectClaim}"
        preview
      ></neb-table-ledger-charges-claim>
    `;
  }
}

customElements.define(
  'neb-ledger-charges-claim-section',
  NebLedgerChargesClaimSection,
);
