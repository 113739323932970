import '../controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  addButton: {
    id: 'button-add',
  },
};

export class List extends LitElement {
  static get properties() {
    return {
      maxItems: Number,
      addLabel: String,
      name: String,
      errors: Array,
      model: Array,
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.maxItems = 0;
    this.addLabel = '';
    this.name = '';
    this.errors = [];
    this.model = [];

    this.onAdd = () => {};

    this.onChange = () => {};

    this.onRemove = () => {};
  }

  initHandlers() {
    this.handlers = {
      add: () => this.onAdd(this.name),
      remove: name => this.onRemove(this.name, Number(name)),
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .button-add {
          align-self: flex-start;
          margin: 0.4rem 0 12px 0;
          width: fit-content;
        }
      `,
    ];
  }

  renderItem() {
    throw new Error('renderItem() - Unimplemented');
  }

  renderAddButton() {
    return !this.maxItems || this.model.length < this.maxItems
      ? html`
          <neb-button-action
            id="${ELEMENTS.addButton.id}"
            class="button-add"
            label="${this.addLabel}"
            .onClick="${this.handlers.add}"
          ></neb-button-action>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.model.map((item, index) => this.renderItem(item, index))}
        ${this.renderAddButton()}
      </div>
    `;
  }
}
