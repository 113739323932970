import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';

import { LitElement, html, css } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { BILL_TYPES } from '../../../packages/neb-api-client/src/mappers/patient-case-mapper';
import { formatAuthorizationLabel } from '../../../packages/neb-utils/patientAuthorization';
import {
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_WARNING_COLOR,
} from '../../styles';
import './neb-icon';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-bar';

export const ELEMENTS = {
  container: { id: 'container' },
  title: { id: 'title' },
  buttonBar: { id: 'button-bar' },
  editButton: { id: 'edit-button' },
  billTypeLabel: { id: 'bill-type-label' },
  billType: { id: 'bill-type' },
  payerLabel: { id: 'payer-label' },
  payer: { id: 'payer' },
  planLabel: { id: 'plan-label' },
  plan: { id: 'plan' },
  secondaryInsuranceLabel: { id: 'secondaryInsurance-label' },
  secondaryInsurance: { id: 'secondary-insurance' },
  guarantorLabel: { id: 'guarantor-label' },
  guarantor: { id: 'guarantor' },
  caseLabel: { id: 'case-label' },
  case: { id: 'case' },
  authorizationLabel: { id: 'authorization-label' },
  authorization: { id: 'authorization' },
  warningIcon: { id: 'warning-icon' },
  carePackage: { id: 'care-package' },
};

class NebBillingHeader extends LitElement {
  static get properties() {
    return {
      authorizations: Array,
      model: Object,
      buttonConfig: Array,
      showWarningIcon: Boolean,
      multiCarePackageEnabled: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {
      authorizationId: '',
      authorizationNumber: '',
      billType: '',
      case: '',
      caseId: '',
      guarantor: '',
      guarantorId: '',
      payerId: '',
      payerName: '',
      primaryInsurance: '',
      primaryInsuranceId: '',
      secondaryInsurance: '',
      secondaryInsuranceId: '',
      patientPackageName: '',
      patientPackageId: '',
    };

    this.authorizations = [];
    this.buttonConfig = [];
    this.showWarningIcon = false;
    this.multiCarePackageEnabled = false;

    this.onOpenEditBillingHeaderPopup = () => {};

    this.onOpenPayerOverlay = () => {};

    this.onOpenInsuranceOverlay = () => {};

    this.onOpenCaseOverlay = () => {};

    this.onOpenAuthorizationOverlay = () => {};

    this.onOpenCarePackageOverlay = () => {};

    this.onOpenCarePackagesSummaryOverlay = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      editBillingHeader: () => {
        this.onOpenEditBillingHeaderPopup();
      },
      openPayerOverlay: () => {
        if (this.model.billType === BILL_TYPES.INSURANCE) {
          this.onOpenPayerOverlay();
        }
      },
      openPrimaryInsuranceOverlay: () => {
        this.onOpenInsuranceOverlay(this.model.primaryInsuranceId);
      },
      openSecondaryInsuranceOverlay: () => {
        this.onOpenInsuranceOverlay(this.model.secondaryInsuranceId);
      },
      openCaseOverlay: () => {
        this.onOpenCaseOverlay();
      },
      openAuthorizationOverlay: () => {
        this.onOpenAuthorizationOverlay();
      },
      openCarePackageOverlay: () =>
        this.multiCarePackageEnabled
          ? this.onOpenCarePackagesSummaryOverlay()
          : this.onOpenCarePackageOverlay(this.model.patientPackageId),
      keydown: e => {
        if (e.key === 'Enter') {
          this.onOpenEditBillingHeaderPopup();
        }
      },
    };
  }

  static get styles() {
    return [
      css`
        .container {
          padding: ${CSS_SPACING};
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .container-authorization {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: ${CSS_SPACING};
        }

        .title {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .fields {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        .field {
          min-width: 130px;
        }

        .field-authorization {
          display: flex;
          align-items: flex-end;
        }

        .bill-type {
          display: flex;
        }

        .edit-icon {
          align-self: center;
          margin-right: ${CSS_SPACING};
        }

        .icon-warning {
          height: 24px;
          width: 24px;
          fill: ${CSS_WARNING_COLOR};
          margin-right: 5px;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          white-space: nowrap;
        }

        .plain-text-value {
          margin-top: 10px;
        }
      `,
    ];
  }

  __renderCarePackageLinkOrCheckMark() {
    return this.multiCarePackageEnabled
      ? html`
          <neb-text
            id="${ELEMENTS.carePackage.id}"
            class="plain-text-value"
            link
            .onClick="${this.__handlers.openCarePackageOverlay}"
            >✓</neb-text
          >
        `
      : html`
          <neb-text
            id="${ELEMENTS.carePackage.id}"
            class="plain-text-value"
            bold
            link
            truncate
            .onClick="${this.__handlers.openCarePackageOverlay}"
          >
            ${this.model.patientPackageName}
          </neb-text>
        `;
  }

  __renderWarningIcon() {
    return this.showWarningIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.warningIcon.id}"
            icon="neb:warning"
            class="icon-warning"
          ></neb-icon>
        `
      : '';
  }

  __renderCarePackage() {
    return html`
      <div class="field">
        <div class="label">Care Package</div>
        ${this.__renderCarePackageLinkOrCheckMark()}
      </div>
    `;
  }

  __renderPlan() {
    const { billType } = this.model;
    return billType === BILL_TYPES.CARE_PACKAGE
      ? this.__renderCarePackage()
      : html`
          <div class="field">
            <div id="${ELEMENTS.planLabel.id}" class="label">Plan</div>
            <neb-text
              id="${ELEMENTS.plan.id}"
              class="plain-text-value"
              bold
              truncate
              link
              .onClick="${this.__handlers.openPrimaryInsuranceOverlay}"
            >
              ${this.model.primaryInsurance}
            </neb-text>
          </div>
        `;
  }

  __renderInsuranceCarePackageField() {
    const { billType, patientPackageId } = this.model;

    return billType === BILL_TYPES.INSURANCE && patientPackageId
      ? this.__renderCarePackage()
      : '';
  }

  __renderAuthorizationNumber() {
    let authorizationLabel = this.model.authorizationNumber;

    const currentAuthorization = this.authorizations.find(
      auth => auth.id === this.model.authorizationId,
    );

    if (currentAuthorization) {
      authorizationLabel = formatAuthorizationLabel(currentAuthorization);
    }

    return html`
      <div class="field">
        <div id="${ELEMENTS.authorizationLabel.id}" class="label">
          Authorization
        </div>
        <div class="field-authorization">
          ${this.__renderWarningIcon()}
          <neb-text
            id="${ELEMENTS.authorization.id}"
            class="plain-text-value"
            bold
            link
            truncate
            .onClick="${this.__handlers.openAuthorizationOverlay}"
            title="${authorizationLabel}"
          >
            ${authorizationLabel}
          </neb-text>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="header">
          <div id="${ELEMENTS.title.id}" class="title">Billing Information</div>
          <neb-button-bar
            id="${ELEMENTS.buttonBar.id}"
            class="button-bar"
            .config="${this.buttonConfig}"
          ></neb-button-bar>
        </div>
        <div class="fields">
          <div class="field bill-type">
            <neb-button-icon
              id="${ELEMENTS.editButton.id}"
              icon="neb:edit"
              class="edit-icon"
              .onClick="${this.__handlers.editBillingHeader}"
              @keydown="${this.__handlers.keydown}"
            ></neb-button-icon>
            <div>
              <div id="${ELEMENTS.billTypeLabel.id}" class="label">
                Bill Type
              </div>
              <div id="${ELEMENTS.billType.id}" class="plain-text-value">
                ${this.model.billType || unsafeHTML('&nbsp;')}
              </div>
            </div>
          </div>
          <div class="field">
            <div id="${ELEMENTS.payerLabel.id}" class="label">Payer</div>
            <neb-text
              id="${ELEMENTS.payer.id}"
              class="plain-text-value"
              truncate
              ?bold="${this.model.billType === BILL_TYPES.INSURANCE}"
              ?link="${this.model.billType === BILL_TYPES.INSURANCE}"
              .onClick="${this.__handlers.openPayerOverlay}"
              >${this.model.payerName}</neb-text
            >
          </div>
          ${this.__renderPlan()}
          <div class="field">
            <div id="${ELEMENTS.secondaryInsuranceLabel.id}" class="label">
              Secondary
            </div>
            <neb-text
              id="${ELEMENTS.secondaryInsurance.id}"
              class="plain-text-value"
              bold
              truncate
              link
              .onClick="${this.__handlers.openSecondaryInsuranceOverlay}"
            >
              ${this.model.secondaryInsurance}
            </neb-text>
          </div>
          ${this.__renderInsuranceCarePackageField()}
          <div class="field">
            <div id="${ELEMENTS.guarantorLabel.id}" class="label">
              Guarantor
            </div>
            <neb-text
              id="${ELEMENTS.guarantor.id}"
              class="plain-text-value"
              truncate
            >
              ${this.model.guarantor}
            </neb-text>
          </div>
          <div class="field">
            <div id="${ELEMENTS.caseLabel.id}" class="label">Case</div>
            <neb-text
              id="${ELEMENTS.case.id}"
              class="plain-text-value"
              bold
              truncate
              link
              .onClick="${this.__handlers.openCaseOverlay}"
              >${this.model.case}</neb-text
            >
          </div>
          ${this.__renderAuthorizationNumber()}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-billing-header', NebBillingHeader);
