/* eslint-disable import/no-unused-modules */
import { css } from 'lit';

import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../..';

export const reportStyles = css`
  :host {
    overflow-y: scroll;
    display: block;
  }

  .container {
    margin: 20px;
  }

  .header-container {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: end;
    align-items: center;
    padding: 20px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  .header-buttons {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    align-items: center;
  }

  #backToTop {
    position: fixed;
    bottom: 10px;
    right: 900px;
    opacity: 0.7;
    transition: opacity 0.3s;
    background-color: ${CSS_COLOR_HIGHLIGHT};
    cursor: pointer;
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chevron-back-to-top {
    fill: ${CSS_COLOR_WHITE};
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }

  h1 {
    font-weight: ${CSS_FONT_WEIGHT_BOLD};
    color: ${CSS_COLOR_WHITE};
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  h2 {
    font-weight: ${CSS_FONT_WEIGHT_BOLD};
    font-size: 16px;
    padding-left: 10px;
  }

  .h2-container h2 {
    cursor: pointer;
  }

  .h2-container neb-icon {
    cursor: pointer;
  }

  .h2-container {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  .secondary-benefit-header {
    margin-top: 30px;
  }

  .benefit-table-header {
    display: flex;
    align-items: end;
    margin-left: 10px;
    justify-content: space-between;
    cursor: pointer;
  }

  .collapsed-header {
    border-bottom: 2px solid ${CSS_COLOR_BLACK};
    width: 50%;
  }

  h3 {
    font-weight: ${CSS_FONT_WEIGHT_BOLD};
    font-size: 14px;
    margin-bottom: 5px;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    padding-left: 10px;
  }

  ul {
    text-align: left;
    padding-inline-start: 20px;
  }

  .table-left {
    background-color: ${CSS_COLOR_GREY_4};
    width: 100px;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .flex-item {
    flex: 1;
    margin: 0 10px;
  }

  .main-info-section {
    padding-bottom: 20px;
  }

  .main-info-section-hidden {
    display: none;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-bottom: 20px;
  }

  .secondary-table {
    text-align: center;
  }

  .service-type-table {
    width: 100%;
    font-size: 12px;
  }

  .close-icon {
    display: inline-block;
    cursor: pointer;
    min-width: 24px;
    width: 24px;
    height: 24px;
    fill: ${CSS_COLOR_GREY_1};
    padding-right: 10px;
    padding-left: 50px;
  }
  :host([attached-to-edit]) .close-icon {
    display: none;
  }

  .chevron-icon {
    min-width: 10px;
    width: 10px;
    height: 10px;
    fill: ${CSS_COLOR_BLACK};
    margin-bottom: 5px;
    margin-right: 15px;
    transform: rotate(0);
  }

  .collapsed-header .chevron-icon {
    transform: rotate(0deg);
  }

  .expanded-header .chevron-icon {
    transform: rotate(180deg);
  }

  .id-info-table {
    border-collapse: collapse;
    width: 100%;
  }

  .id-info-table td {
    border: none;
    padding: 8px;
  }

  .service-type-table td {
    border: 1px solid ${CSS_COLOR_GREY_2};
    padding: 8px;
  }

  .id-info-table tr:first-child td {
    border-top: 2px solid ${CSS_COLOR_BLACK};
  }

  .id-info-table tr td:first-child {
    border-left: 1px solid ${CSS_COLOR_GREY_2};
  }

  .id-info-table tr td:last-child {
    border-right: 1px solid ${CSS_COLOR_GREY_2};
  }

  .id-info-table tr:last-child td {
    border-bottom: 1px solid ${CSS_COLOR_GREY_2};
  }

  .service-type-table td:last-child {
    width: 33%;
  }

  .service-type-table td:nth-child(2) {
    width: 10%;
  }

  .last-array-table-item {
    border-bottom: 2px solid ${CSS_COLOR_GREY_2};
  }

  .chevron-icon-practice {
    min-width: 15px;
    width: 15px;
    height: 15px;
    fill: ${CSS_COLOR_BLACK};
    transform: rotate(180deg);
    transition: transform 200ms;
  }

  :host(:not([collapse-practice])) .chevron-icon-practice {
    transform: rotate(180deg);
  }

  :host([collapse-practice]) .chevron-icon-practice {
    transform: rotate(0deg);
  }

  .chevron-icon-all-other {
    min-width: 15px;
    width: 15px;
    height: 15px;
    fill: ${CSS_COLOR_BLACK};
    transform: rotate(180deg);
    transition: transform 200ms;
  }

  :host(:not([collapse-all-other])) .chevron-icon-all-other {
    transform: rotate(180deg);
  }

  :host([collapse-all-other]) .chevron-icon-all-other {
    transform: rotate(0deg);
  }

  .chevron-icon-large {
    min-width: 20px;
    width: 20px;
    height: 20px;
    fill: ${CSS_COLOR_WHITE};
    transform: rotate(180deg);
    transition: transform 200ms;
  }

  .collapsed-main-header .chevron-icon-large {
    transform: rotate(0deg);
  }

  .expanded-main-header .chevron-icon-large {
    transform: rotate(180deg);
  }
  .main-header-container {
    display: flex;
    background-color: ${CSS_COLOR_HIGHLIGHT};
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .secondary-info-section {
    display: block;
  }

  .secondary-info-section-hidden {
    display: none;
  }

  .no-data {
    padding-left: 10px;
    font-style: italic;
    color: ${CSS_COLOR_GREY_1};
  }

  .amount {
    white-space: pre-line;
    text-wrap: nowrap;
  }
`;
