import { html, css } from 'lit';

import * as cardReaderApi from '../../../../../neb-api-client/src/payments/card-readers-api-client';
import { NebFormCardReaders } from '../../forms/neb-form-card-readers';
import NebOverlayFormItem from '../neb-overlay-form-item';

export const CARD_READER_SUCCESS_BANNER = 'Card reader saved successfully';
export const CARD_READER_ERROR_BANNER =
  'An error occurred when saving the card reader';
export const ELEMENTS = {
  form: {
    id: 'form',
  },
};

class NebOverlayCardReaders extends NebOverlayFormItem {
  static get properties() {
    return {
      __cardReader: Object,
      __cardReaders: Array,
      __merchantAccounts: Array,
    };
  }

  static get config() {
    return {
      form: NebFormCardReaders,
      itemName: 'Card Reader',
      messageError: CARD_READER_ERROR_BANNER,
      messageSuccess: CARD_READER_SUCCESS_BANNER,
    };
  }

  initState() {
    super.initState();
    this.__cardReader = NebFormCardReaders.createModel();
    this.__cardReaders = [];
    this.__merchantAccounts = [];
    this.model = {
      context: {
        items: [],
      },
      item: {},
    };
  }

  save(cardReader) {
    const reqKey = cardReader.id ? 'updateCardReader' : 'createCardReader';
    return cardReaderApi[reqKey](cardReader);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.item) {
        this.__cardReader = { ...this.model.item };
      } else {
        this.__cardReader = { ...this.__cardReader };
      }

      if (this.model.context.items) {
        this.__cardReaders = [...this.model.context.items];
        this.__merchantAccounts = [...this.model.context.merchantAccounts];
      }
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 875px;
        }
      `,
    ];
  }

  renderForm() {
    return html`
      <neb-form-card-readers
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.__cardReader}"
        .cardReaders="${this.__cardReaders}"
        .merchantAccounts="${this.__merchantAccounts}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
      ></neb-form-card-readers>
    `;
  }
}

window.customElements.define('neb-overlay-card-readers', NebOverlayCardReaders);
