import { deepCopy } from '../../packages/neb-utils/utils';

function hidePracticeInfo(model) {
  delete model.practiceInfo;
  delete model.practiceLogo;
  return model;
}

function hidePatientInfo(model) {
  delete model.patient;
  return model;
}

function hideAppointmentInfo(model) {
  model.encounters.forEach(ec => {
    delete ec.appointmentDetails;
  });

  return model;
}

function hideDiagnoses(model) {
  model.encounters.forEach(ec => {
    delete ec.encounterDiagnoses;
  });

  return model;
}

function hideCharges(model) {
  model.encounters.forEach(ec => {
    delete ec.lineItems;
  });

  return model;
}

function hideFeesAndPurchases(model) {
  delete model.feesAndPurchases;
  return model;
}

function hideAcknowledgement(model) {
  delete model.legalAcknowledgement;
  return model;
}

function hideSignatures(model) {
  delete model.providers;
  delete model.patientSignature;
  return model;
}

function hideCreatedDate(model) {
  delete model.createdAt;

  return model;
}

const FORMATTER = {
  hidePracticeInfo,
  hidePatientInfo,
  hideAppointmentInfo,
  hideDiagnoses,
  hideCharges,
  hideAcknowledgement,
  hideSignatures,
  hideCreatedDate,
  hideFeesAndPurchases,
};

export const formatToPdf = model => {
  let data = deepCopy(model);

  Object.entries(data.opts).forEach(opt => {
    const [key, value] = opt;
    if (value) data = FORMATTER[key](data);
  });

  return data;
};
