import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { TOOLTIP_TEXT } from '../../../../../neb-utils/claims';

export const ELEMENTS = {
  otherInsuredNameCell: { id: 'other-insured-name-cell' },
  otherInsuredPolicyCell: { id: 'other-insured-policy-cell' },
  insurancePlanNameCell: { id: 'other-insured-plan-name-cell' },
  fl9bCell: { id: 'fl-9b-cell' },
  fl9cCell: { id: 'fl-9c-cell' },
};

class NebClaimFormCellFL9 extends LitElement {
  static get properties() {
    return {
      errors: Object,
      model: Object,
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.errors = {
      otherInsuredFirstName: '',
      otherInsuredGroupIdNumber: '',
      otherInsurancePlanName: '',
    };

    this.model = {
      otherInsuredName: '',
      otherInsuredGroupIdNumber: '',
      otherInsurancePlanName: '',
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(5, 1fr);
        }

        .cell {
          display: grid;
          width: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.otherInsuredNameCell.id}"
          class="cell"
          label="9. Other Insured's Name (Last Name, First Name, Middle Initial)"
          borderBottom
          .tooltipText="${TOOLTIP_TEXT.FL_9}"
          .data="${this.model.otherInsuredName}"
          .problemText="${this.errors.otherInsuredFirstName}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.otherInsuredPolicyCell.id}"
          class="cell"
          label="9a. Other Insured's Policy or Group Number"
          borderBottom
          .tooltipText="${TOOLTIP_TEXT.FL_9A}"
          .data="${this.model.otherInsuredGroupIdNumber}"
          .problemText="${this.errors.otherInsuredGroupIdNumber}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.fl9bCell.id}"
          class="cell"
          label="9b. Reserved for NUCC Use"
          borderBottom
          .tooltipText="${TOOLTIP_TEXT.FL_9B}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.fl9cCell.id}"
          class="cell"
          label="9c. Reserved for NUCC Use"
          borderBottom
          .tooltipText="${TOOLTIP_TEXT.FL_9C}"
        >
        </neb-claim-form-cell>
        <neb-claim-form-cell
          id="${ELEMENTS.insurancePlanNameCell.id}"
          class="cell"
          label="9d. Insurance Plan Name or Program Name"
          borderBottom
          .tooltipText="${TOOLTIP_TEXT.FL_9D}"
          .data="${this.model.otherInsurancePlanName}"
          .problemText="${this.errors.otherInsurancePlanName}"
        >
        </neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-9', NebClaimFormCellFL9);
