import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {};

class Textbox extends LitElement {
  static get properties() {
    return {
      focused: {
        reflect: true,
        type: Boolean,
      },
      invalid: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.focused = false;
    this.invalid = false;
    this.disabled = false;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 40px;
          border: 1px solid ${CSS_COLOR_GREY_1};
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 0 12px;
        }

        :host([focused]) {
          padding: 0 11px;
        }

        :host(:hover:not([invalid]):not([disabled])) {
          border-color: ${CSS_COLOR_BLACK};
        }

        :host([invalid]:not([disabled])) {
          border-color: ${CSS_COLOR_ERROR};
        }

        :host([focused]) {
          border-width: 2px;
        }

        :host([focused]:not([invalid]):not([disabled])) {
          border-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) {
          border-color: ${CSS_COLOR_DISABLED};
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  render() {
    return html` <div class="container"><slot></slot></div> `;
  }
}

window.customElements.define('neb-textbox', Textbox);
