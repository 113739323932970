import DeprecatedApiClient, {
  buildQueryString,
  Method,
} from './utils/api-client-utils';

export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});

const baseUrl = 'ledger/activity';

export const getPatientLedgerActivityTransactions = async (
  patientId,
  queryParams = {},
) => {
  const route = `patients/${patientId}/${baseUrl}/transactions`;
  const path = `${route}${buildQueryString(queryParams)}`;

  const { count, results } = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return { count, results };
};

export const getPatientLedgerActivityEncounters = queryParams =>
  billingApiClient.makeRequest({
    path: `${baseUrl}/encounters`,
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    cacheKey: `encounters${JSON.stringify(queryParams)}`,
  });
