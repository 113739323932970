import { NAME_OPTS } from '../../../neb-lit-components/src/components/guarantor/guarantor-util';
import { objToName } from '../../../neb-utils/formatters';

import { formatOrganization } from './organization';
import { formatPerson } from './person';

const formatGuarantor = guarantor => {
  const personOrOrganization = guarantor.personId
    ? {
        person: formatPerson(guarantor.person),
      }
    : {
        organization: formatOrganization(guarantor.organization),
      };
  return { ...guarantor, ...personOrOrganization };
};

export const formatGuarantors = guarantors => {
  if (!guarantors) return [];
  return guarantors.map(guarantor =>
    guarantor.relation === 'Self' ? guarantor : formatGuarantor(guarantor),
  );
};

export const formatGuarantorName = ({ person, organization }) =>
  person
    ? objToName(
        {
          first: person.firstName,
          last: person.lastName,
          middle: person.middleName,
          suffix: person.suffix,
        },
        NAME_OPTS,
      )
    : `${organization.name}`;
