import { LitElement, html, css } from 'lit';

import { baseStyles, layoutStyles, CSS_SPACING } from '../../../../src/styles';

export const ELEMENTS = {
  content: { id: 'content' },
};

export class NebLayout extends LitElement {
  static get properties() {
    return {
      page: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .content {
          display: flex;
          overflow-y: auto;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
        }

        .header {
          margin: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        :host(:not([page])) .header,
        :host([layout='small']) .header {
          margin: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.page = false;
    this.layout = '';

    this.onScroll = () => {};
  }

  initHandlers() {
    this.handlers = {
      scroll: e => this.onScroll(e),
    };
  }

  renderHeader() {
    return html``;
  }

  renderFooter() {
    return html``;
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  render() {
    return html`
      <div class="container">
        <div
          id="${ELEMENTS.content.id}"
          class="content"
          @scroll="${this.handlers.scroll}"
        >
          ${this.renderHeader()}

          <div class="layout">${this.renderContent()}</div>
        </div>

        ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define('neb-layout', NebLayout);
