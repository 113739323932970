import '../../neb-lit-components/src/components/problem-list/neb-problems-form';

import { html, css } from 'lit';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

class NebPopupProblems extends NebPopup {
  initHandlers() {
    super.initHandlers();

    this.handlers.save = result => {
      this.onClose(result);
    };

    this.handlers.cancel = result => {
      this.onClose(result);
    };

    this.handlers.delete = result => {
      this.onClose(result);
    };
  }

  initState() {
    super.initState();
    this.dynamic = true;
  }

  modelChanged() {
    this.title = this.model.title;
  }

  firstUpdated() {
    this.__elements = {
      form: this.shadowRoot.getElementById(ELEMENTS.form.id),
    };
  }

  updated() {
    if (this.layout === 'small') {
      this.onClose({
        info: this.__elements.form.getCurrentInfo(),
        resize: true,
      });
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 544px;
          display: flex;
          max-height: 100%;
          flex-direction: column;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-problems-form
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onDelete="${this.handlers.delete}"
        ?small="${this.layout === 'small'}"
      ></neb-problems-form>
    `;
  }
}

customElements.define('neb-popup-problems', NebPopupProblems);
