import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_FAMILY,
} from '../../../neb-styles/neb-variables';

class NebInfo extends LitElement {
  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: row;
        color: ${CSS_COLOR_HIGHLIGHT};
        font-size: ${CSS_FONT_FAMILY};
      }

      .info-icon {
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        margin-right: 10px;
        flex: none;
      }
    `;
  }

  render() {
    return html`
      <neb-icon class="info-icon" icon="neb:info"></neb-icon>
      <div class="info"><slot></slot></div>
    `;
  }
}

customElements.define('neb-info', NebInfo);
