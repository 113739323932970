/* eslint-disable complexity */
import '../tables/statements/neb-table-statement-batch-preview';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import '../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import { navigate } from '@neb/router';
import { html, css } from 'lit';

import * as ledgerStatementApiClient from '../../../packages/neb-api-client/src/ledger-statement-api-client';
import getStatementBatchesCsv from '../../../packages/neb-api-client/src/services/statement/get-statement-batches-csv';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../packages/neb-styles/neb-variables';
import {
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { formatDate } from '../../../packages/neb-utils/neb-ledger-util';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import { mapToPatientModel } from '../../../packages/neb-utils/patient';
import * as selectors from '../../../packages/neb-utils/selectors';
import { MODE } from '../../../packages/neb-utils/table';
import { handleDirtyDialogLogic } from '../../../packages/neb-utils/utils';
import { openError } from '../../store';
import { fetchPhoto, sortPatients, searchPatients } from '../../utils/patients';
import { ERROR_EXPORTING_STATEMENT_BATCH_CSV_MESSAGE } from '../../utils/user-message';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  content: { id: 'content' },
  tabs: { id: 'tabs' },
  table: { id: 'table' },
  spinner: { id: 'spinner' },
  actionBar: { id: 'action-bar' },
  footer: {
    id: 'footer',
  },
  batchTotal: {
    id: 'batch-total',
  },
  exportCsv: {
    id: 'export-csv',
  },
  patientSearch: { id: 'search-patient' },
  dateRange: {
    id: 'date-range',
  },
  createdOn: {
    id: 'created-on',
  },
  statementEmail: {
    id: 'statement-email',
  },
};

export const EMPTY_STATEMENT_MESSAGE_PRACTICE =
  'There are no statements available.';

export const EMPTY_STATEMENT_MESSAGE_PRACTICE_PATIENT =
  'There are no statements available for this patient.';

export const TABS = {
  BATCH_PREVIEW: 'Batch Preview',
  EXCLUDED_REMOVED: 'Excluded Removed',
};

class NebLedgerBalanceStatementsBatchCollectionPage extends CollectionPage {
  static get properties() {
    return {
      __patientItems: Array,
      __allPatients: Array,
      __selectedPatient: Object,
      __balanceEmail: Boolean,
      __createdAt: Date,
      __patientId: String,
      __excludedFromBatch: Boolean,
      __batchTotal: Number,
      __tableState: Object,
      __navItems: Array,
      __selectedTab: String,
      __isLoading: Boolean,
      __statementIds: Array,
    };
  }

  static get config() {
    return {
      unifyForm: true,
      initialSortKey: 'patientName',
      initialSortOrder: 'asc',
      tableConfig: [],
      optOutCollectionPagination: true,
      useFetch: true,
      ignoreRowFormPadding: true,
      hideHeader: true,
    };
  }

  initState() {
    super.initState();
    this.__patientItems = [];
    this.__allPatients = [];
    this.__balanceEmail = false;
    this.__createdAt = new Date();
    this.__selectedPatient = selectors.ITEM_EMPTY;
    this.__patientId = null;
    this.__excludedFromBatch = false;

    this.__batchTotal = 0;
    this.__isDirty = false;
    this.__statementIds = [];
    this.__originalState = {};
    this.__query = {};
    this.__selectedTab = TABS.BATCH_PREVIEW;
    this.__navItems = [
      {
        id: TABS.BATCH_PREVIEW,
        label: 'Batch Preview',
        renderer: () => this.__renderStatements(),
      },
      {
        id: TABS.EXCLUDED_REMOVED,
        label: 'Excluded & Removed',
        renderer: () => this.__renderStatements(),
      },
    ];

    this.__isLoading = false;

    this.onDirty = () => {};

    this.onDismiss = () => {};

    this.onSave = () => {};
  }

  __switchTab(tab) {
    this.__selectedTab = tab;
    this.__excludedFromBatch = tab === TABS.EXCLUDED_REMOVED;
    this.handlers.applyFilter({
      name: 'excludedFromBatch',
      value: this.__excludedFromBatch,
    });
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectTab: async tab => {
        if (!this.__isDirty) {
          this.__switchTab(tab);
          return;
        }

        await handleDirtyDialogLogic({
          isDirty: this.__isDirty,
          formIsValid: true,
          onSave: async () => {
            await this.handlers.save(false);
            this.__switchTab(tab);
            this.__discardChanges();
          },
          onDiscard: () => {
            this.__switchTab(tab);
            this.__discardChanges();
          },
        });
      },
      changeState: state => {
        this.__tableState = state;
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      search: e => {
        if (!e.value) {
          this.__patientItems = this.__allPatients;
          return;
        }

        this.__patientItems = searchPatients(this.__allPatients, e.value);
      },
      changePatient: e => {
        this.__selectedPatient = e.value || selectors.ITEM_EMPTY;
        this.handlers.applyFilter({
          name: 'patientId',
          value: this.__selectedPatient.data.id,
        });
      },
      sort: (_, params) => this.service.setSortParams(params[0]),
      printSelectedStatement: id =>
        printPdf(ledgerStatementApiClient.fetchOne(id)),
      navigateToPatientLedger: patientId =>
        navigate(`/patients/${patientId}/ledger/activity`),
      applyFilter: ({ name, value }) => {
        if (value || typeof value === 'boolean') {
          this.service.setQuery(name, value);
        } else {
          this.service.unsetQuery(name);
        }
        this.service.setPageIndex(0);
      },
      tableChange: () => {
        this.service.fetch();
      },
      fetchData: async query => {
        this.__isLoading = true;

        const buildedQuery = {
          ...query,
          ...(!query.patientId && this.__patientId
            ? {
                patientId: this.__patientId,
              }
            : {}),
          ...(!query.excludedFromBatch
            ? {
                excludedFromBatch: this.__excludedFromBatch,
              }
            : {}),
        };

        try {
          const { data, count, patients, totalBatchBalance } =
            await ledgerStatementApiClient.getBatchStatements(
              this.model.batchId,
              buildedQuery,
            );

          this.__batchTotal = totalBatchBalance;

          if (count > 0) {
            this.__balanceEmail = data[0].balanceEmail;
            this.__createdAt = data[0].createdAt;
          }

          if (patients && patients.length) {
            this.__allPatients = await Promise.all(
              patients.map(async item => {
                const photoSrc = await fetchPhoto(item.id);
                const mappedPatient = {
                  data: mapToPatientModel(item, null, photoSrc),
                  label: objToName(
                    { first: item.firstName, last: item.lastName },
                    DEFAULT_NAME_OPTS,
                  ),
                };

                return mappedPatient;
              }),
            );

            this.__allPatients = sortPatients(this.__allPatients);
            this.__patientItems = this.__allPatients;
          }

          this.__originalState = {
            table: data,
            batchTotal: totalBatchBalance,
          };

          this.__isLoading = false;

          return { data, count };
        } catch (error) {
          console.error(error);
          this.__isLoading = false;
        }

        return null;
      },
      addOrRemoveStatement: (statementId, isRemove) => {
        this.__isDirty = true;
        this.onDirty(true);
        this.__statementIds.push(statementId);

        if (isRemove) {
          const statement = this.__tableState.pageItems.find(
            st => st.id === statementId,
          );
          this.__batchTotal -= statement.balance;
        }

        const filteredItems = this.__tableState.pageItems.filter(
          e => e.id !== statementId,
        );

        this.__tableState = { ...this.__tableState, pageItems: filteredItems };
      },
      exportCsv: async () => {
        await handleDirtyDialogLogic({
          isDirty: this.__isDirty,
          formIsValid: true,
          onSave: async () => {
            await this.handlers.save();
            await this.downloadCsv();
          },
          onDiscard: async () => {
            this.__discardChanges();
            await this.downloadCsv();
          },
        });
      },
      save: async (shouldDismiss = true) => {
        if (this.__statementIds.length) {
          await this.onSave(
            this.__statementIds,
            !this.__excludedFromBatch,
            shouldDismiss,
          );
        }
      },
      dismiss: () => this.onDismiss(),
    };
  }

  __discardChanges() {
    this.__isDirty = false;
    this.onDirty(false);
    this.__statementIds = [];

    this.__tableState = {
      ...this.__tableState,
      pageItems: this.__originalState.table,
    };

    this.__batchTotal = this.__originalState.batchTotal;
  }

  async downloadCsv() {
    try {
      await getStatementBatchesCsv(this.model.batchId);
    } catch (error) {
      store.dispatch(openError(ERROR_EXPORTING_STATEMENT_BATCH_CSV_MESSAGE));
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow-y: hidden;
        }

        .content {
          overflow-y: visible;
        }

        .description {
          padding-left: ${CSS_SPACING};
          margin: 0;
        }

        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          width: 100%;
        }

        .inset {
          padding-left: np;
        }

        .container-button {
          display: flex;
        }

        .header {
          margin-bottom: 0;
        }

        .footer-container {
          height: 100%;
        }

        .footer {
          left: 0;
          bottom: 0;
          position: absolute;
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          display: flex;
          flex-direction: row;
          background-color: #fff;
          padding-left: 10px;
          width: 100%;
        }

        .action-bar {
          width: 80%;
        }

        .batchTotal {
          width: 100%;
          justify-content: flex-end;
          display: flex;
          align-items: center;
          margin-right: 70px;
          margin-left: auto;
          padding: 20px 0;
        }

        #export-csv {
          padding-right: 20px;
        }

        .empty-container {
          display: flex;
          height: 80px;
        }

        .row {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .tabs {
          border-radius: 5px 5px 0 0;
        }

        .row-margins {
          margin-left: ${CSS_SPACING};
          margin-right: ${CSS_SPACING};
        }

        .cell-spacer {
          flex: 1 0 0;
        }

        .patientFilter {
          margin-left: ${CSS_SPACING};
          width: 50%;
        }

        .subHeader {
          display: inline-block;
          padding-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderNoItemsContent() {
    return '';
  }

  __renderLoading() {
    return this.__isLoading
      ? html`
          <neb-loading-overlay
            id="${ELEMENTS.spinner.id}"
            showDelay="0"
            .show="${true}"
          ></neb-loading-overlay>
        `
      : html``;
  }

  __renderExportCsv() {
    return html`
      <neb-button-action
        id="${ELEMENTS.exportCsv.id}"
        leadingIcon="export"
        label="Export"
        .onClick="${this.handlers.exportCsv}"
      ></neb-button-action>
    `;
  }

  __renderBatchTotal() {
    return this.__excludedFromBatch
      ? ''
      : html`
          <div class="batchTotal" id="${ELEMENTS.batchTotal.id}">
            ${this.__renderExportCsv()}
            <strong>Batch Total: ${centsToCurrency(this.__batchTotal)}</strong>
          </div>
        `;
  }

  renderFooter() {
    return html`
      <div id="${ELEMENTS.footer.id}" class="footer-container">
        <div class="footer">
          ${this.__renderActionBar()} ${this.__renderBatchTotal()}
        </div>
      </div>
    `;
  }

  __renderActionBar() {
    return this.__isDirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.dismiss}"
            .hideBorderTop="${true}"
            confirmLabel="Save"
            cancelLabel="Cancel"
          ></neb-action-bar>
        `
      : html` <div class="action-bar empty-container"><p></p></div> `;
  }

  __renderPatientFilter() {
    return html`
      <neb-select-search
        id="${ELEMENTS.patientSearch.id}"
        class="patientFilter"
        name="patient"
        label="Patient"
        helper=" "
        itemHeight="80"
        .items="${this.__patientItems}"
        .value="${this.__selectedPatient}"
        .onChange="${this.handlers.changePatient}"
        .onSearch="${this.handlers.search}"
        .onRenderItem="${this.handlers.renderPatientItem}"
        showSearch
        forceDown
      ></neb-select-search>
    `;
  }

  __renderStatementEmail() {
    const text = this.__balanceEmail ? 'Yes' : 'No';

    return html`
      <div id="${ELEMENTS.statementEmail.id}" class="subHeader">
        <strong> Send Statement Email:</strong> ${text}
      </div>
    `;
  }

  __renderCreated() {
    return html`
      <div id="${ELEMENTS.createdOn.id}" class="subHeader">
        <strong> Created On:</strong> ${formatDate(this.__createdAt)}
      </div>
    `;
  }

  __renderDateRange() {
    const formattedDateTo = formatDate(this.model.dateOfServiceTo);
    const formattedDateFrom = formatDate(this.model.dateOfServiceFrom);

    return html`
      <div id="${ELEMENTS.dateRange.id}" class="subHeader">
        <strong> Date Range:</strong> ${formattedDateFrom} - ${formattedDateTo}
      </div>
    `;
  }

  renderTable() {
    const item = this.__navItems.find(e => e.id === this.__selectedTab);
    return item.renderer();
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .layout="${this.layout}"
        .items="${this.__navItems}"
        .selectedId="${this.__selectedTab}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderStatements() {
    return html`
      ${this.__renderLoading()}
      <div class="grid">
        <div id="${ELEMENTS.content.id}" class="content">
          <neb-table-statement-batch-preview
            id="${ELEMENTS.table.id}"
            class="cell-spacer"
            mode="${MODE.NONE}"
            emptyMessage="${this.__patientId
              ? EMPTY_STATEMENT_MESSAGE_PRACTICE_PATIENT
              : EMPTY_STATEMENT_MESSAGE_PRACTICE}"
            .model="${this.__tableState.pageItems}"
            .sortParams="${[this.__tableState.sortParams]}"
            .onSort="${this.handlers.sort}"
            .onSelect="${this.handlers.selectItem}"
            .onPrintStatement="${this.handlers.printSelectedStatement}"
            .onSelectPatient="${this.handlers.navigateToPatientLedger}"
            .onChange="${this.handlers.tableChange}"
            .onAddOrRemoveStatement="${this.handlers.addOrRemoveStatement}"
            .excludedFromBatch="${this.__excludedFromBatch}"
          >
          </neb-table-statement-batch-preview>
        </div>
      </div>
    `;
  }

  renderHeader() {
    return html`
      <div>
        <div class="description">
          ${this.__renderDateRange()} ${this.__renderCreated()}
          ${this.__renderStatementEmail()}
        </div>

        <div>${this.__renderPatientFilter()}</div>
        ${this.__renderTabs()}
      </div>
    `;
  }
}

customElements.define(
  'neb-ledger-balance-statements-batch-collection-page',
  NebLedgerBalanceStatementsBatchCollectionPage,
);
