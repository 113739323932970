import '../../neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  confirmButton: { id: 'add' },
  cancelButton: { id: 'cancel' },
  closeButton: { id: 'close' },
  header: { id: 'header' },
  title: { id: 'title' },
};

export default class NebPopupClaim extends NebPopup {
  static get properties() {
    return {
      __errors: Object,
      __state: Object,

      model: Object,
    };
  }

  initState() {
    super.initState();
    this.__errors = {};
    this.__state = {};
    this.model = {
      title: '',
      items: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      change: ({ name, value }) => {
        const { validator } = this.model.items.find(item => item.name === name);
        this.__state = { ...this.__state, [name]: value };

        if (validator) {
          this.__errors = validator.validate(value)
            ? { ...this.__errors, [name]: '' }
            : { ...this.__errors, [name]: validator.error };
        }
      },
      confirm: () => {
        if (!this.__hasErrors()) {
          this.onClose(this.__state);
        }
      },
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          min-width: 525px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .add-button {
          padding-right: 10px;
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .spacer {
          flex: 1 0 0;
        }
      `,
    ];
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      ({ state: this.__state, errors: this.__errors } = this.model.items.reduce(
        (acc, item) => {
          acc.state[item.name] = item.value;
          acc.errors[item.name] = '';
          return acc;
        },
        { state: {}, errors: {} },
      ));
    }
  }

  __hasErrors() {
    return Object.values(this.__errors).some(v => v);
  }

  renderHeader() {
    return html`
      <div id="${ELEMENTS.header.id}" class="header">
        <h2 id="${ELEMENTS.title.id}" class="title">${this.model.title}</h2>
        <div class="spacer"></div>

        <button
          id="${ELEMENTS.closeButton.id}"
          class="button-cancel"
          @click="${this.handlers.cancel}"
        >
          <neb-icon class="icon-cancel" icon="neb:close"></neb-icon>
        </button>
      </div>
    `;
  }

  renderButtons() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="add-button"
          label="Ok"
          .onClick="${this.handlers.confirm}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHeader()}
        <div class="content">
          ${this.renderContent()} ${this.renderButtons()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-claim', NebPopupClaim);
