import '../../../../../src/components/misc/neb-icon';
import '../controls/neb-button-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_3,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { wrap } from '../../../../neb-utils/utils';
import { NebScrollThreshold } from '../../services/neb-scroll-threshold';

export const BUFFER_HEIGHT = 8;
const DIVIDER_HEIGHT = 17;
export const ELEMENTS = {
  container: {
    id: 'container',
  },
  scroll: {
    id: 'scroll',
  },
  content: {
    id: 'content',
  },
  items: {
    selector: '.item',
  },
  labels: {
    selector: '.label',
  },
  icons: {
    selector: '.icon',
  },
  actionButtons: {
    selector: '.action-icon',
  },
};
const PADDING = 20;

class Menu extends LitElement {
  static get properties() {
    return {
      __softSelectedIndex: Number,
      __up: {
        reflect: true,
        type: Boolean,
        attribute: 'up',
      },
      __right: {
        reflect: true,
        type: Boolean,
        attribute: 'right',
      },
      __dismissing: {
        reflect: true,
        type: Boolean,
        attribute: 'dismissing',
      },
      __customRenderer: {
        reflect: true,
        type: Boolean,
        attribute: 'customrenderer',
      },
      scrollOnKeydown: Boolean,
      maxVisibleItems: Number,
      itemHeight: Number,
      itemMinWidth: Number,
      selectedIndex: Number,
      thresholdCount: Number,
      onRenderItem: Function,
      items: Array,
      open: {
        reflect: true,
        type: Boolean,
      },
      showFullText: {
        reflect: true,
        type: Boolean,
      },
      forceDown: {
        reflect: true,
        type: Boolean,
        attribute: 'forcedown',
      },
      forceAlign: {
        reflect: true,
        type: String,
      },
      wrapText: {
        reflect: true,
        type: Boolean,
      },
      selectSearchMulti: {
        reflect: true,
        type: Boolean,
      },
      hasNewItemOption: {
        reflect: true,
        type: Boolean,
      },
      startingSoftSelectedIndex: Number,
      actionIcon: String,
      showTitle: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__up = false;
    this.__right = false;
    this.__firstRun = true;
    this.__dismissing = false;
    this.__softSelectedIndex = -1;
    this.open = false;
    this.forceDown = false;
    this.showFullText = false;
    this.wrapText = false;
    this.scrollOnKeydown = true;
    this.selectSearchMulti = false;
    this.selectedIndex = -1;
    this.maxVisibleItems = 4;
    this.thresholdCount = 4;
    this.itemHeight = 48;
    this.itemMinWidth = 0;
    this.forceAlign = null;
    this.items = [];
    this.hasNewItemOption = false;
    this.startingSoftSelectedIndex = -1;
    this.actionIcon = '';
    this.showTitle = false;

    this.onChange = () => {};

    this.onRequest = () => {};

    this.onClickActionIcon = () => {};

    this.onRenderItem = null;
  }

  __initHandlers() {
    this.__handlers = {
      dismiss: () => {
        this.__dismissing = false;
      },
      softDeselect: () => {
        this.__softSelectedIndex = this.startingSoftSelectedIndex;
      },
      softSelect: e => {
        this.__softSelectedIndex = Number(e.currentTarget.index);
      },
      keydown: e => {
        if (this.open) {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            if (this.__softSelectedIndex === -1) {
              this.__softSelectedIndex =
                this.selectedIndex !== -1 ? this.selectedIndex : 0;
            } else {
              const increment = e.key === 'ArrowDown';
              this.__softSelectedIndex = this.__offsetSoftSelect(increment);

              this.__scrollToSelection(this.__softSelectedIndex);
            }

            e.preventDefault();
            e.stopPropagation();
          }

          if (e.key === 'Enter') {
            const index =
              this.__softSelectedIndex !== -1
                ? this.__softSelectedIndex
                : this.selectedIndex;
            e.stopPropagation();
            this.onChange(index);
          }

          if (e.key.length === 1 && this.scrollOnKeydown) {
            const key = e.key.toLowerCase();
            this.__softSelectedIndex = this.items.findIndex(item =>
              typeof item === 'string'
                ? item.toLowerCase().startsWith(key)
                : item.label.toLowerCase().startsWith(key),
            );

            this.__scrollToSelection(this.__softSelectedIndex);

            if (!this.selectSearchMulti) {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }
      },
      mousedown: e => {
        if (this.open) {
          e.stopImmediatePropagation();
          e.preventDefault();
        }
      },
      select: e => {
        e.stopPropagation();
        this.onChange(e.currentTarget.index);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('keydown', this.__handlers.keydown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('keydown', this.__handlers.keydown);
  }

  __offsetSoftSelect(increment) {
    const raw = this.__softSelectedIndex + (increment ? 1 : -1);
    return wrap(raw, 0, this.getItemCount());
  }

  __scrollToSelection(index, forceToTop = false) {
    const scrollEl = this.shadowRoot.getElementById(ELEMENTS.scroll.id);
    const dividerOffset = this.getDividerCount(index) * DIVIDER_HEIGHT;
    const yPos = this.itemHeight * index + dividerOffset + BUFFER_HEIGHT;
    const yRelPos = yPos - scrollEl.scrollTop;
    const yRelBottom = yRelPos + this.itemHeight;

    if (forceToTop) {
      scrollEl.scrollTop = 0;
      scrollEl.scrollTo(0, yPos - BUFFER_HEIGHT);
      return;
    }

    if (yRelPos < BUFFER_HEIGHT) {
      scrollEl.scrollTo(0, yPos - BUFFER_HEIGHT);
      return;
    }

    if (yRelBottom > this.getMenuHeight()) {
      const extraContent = this.getMenuHeight() - this.itemHeight;
      scrollEl.scrollTo(0, yPos - extraContent - BUFFER_HEIGHT);
    }
  }

  hasDividers() {
    if (!this.items.length) {
      return false;
    }

    return Array.isArray(this.items[0]);
  }

  shouldOpenMenuUpwards() {
    const parentRect = this.parentElement.getBoundingClientRect();
    const yBottom = parentRect.y + parentRect.height;
    const yTotal = this.getMenuHeight() + yBottom + PADDING;
    return yTotal > window.innerHeight;
  }

  shouldOpenMenuFromRight() {
    const parentRect = this.parentElement.getBoundingClientRect();
    const x = parentRect.x + parentRect.width;
    const scrollRect = this.shadowRoot
      .getElementById(ELEMENTS.scroll.id)
      .getBoundingClientRect();
    const xTotal = scrollRect.width + x + PADDING;
    return xTotal > window.innerWidth;
  }

  getItemCount() {
    return this.hasDividers()
      ? this.items.reduce((accum, curr) => curr.length + accum, 0)
      : this.items.length;
  }

  getMenuHeight() {
    return this.maxVisibleItems * this.itemHeight;
  }

  getDividerCount(index) {
    if (!this.hasDividers()) {
      return 0;
    }

    let remainder = index;
    return this.items.reduce((accum, subItems) => {
      if (remainder >= subItems.length) {
        remainder -= subItems.length;
        return accum + 1;
      }

      return accum;
    }, 0);
  }

  getRequestThreshold() {
    let startOffset = 0;

    const dividerCount = this.hasDividers()
      ? this.items
          .slice()
          .reverse()
          .filter(list => {
            const result = list.length + startOffset;
            startOffset += list.length;
            return result <= this.thresholdCount;
          }).length
      : 0;
    const thresholdHeight = this.thresholdCount * this.itemHeight;
    const dividerHeight = dividerCount * DIVIDER_HEIGHT;
    return thresholdHeight + dividerHeight;
  }

  update(changedProps) {
    this.__customRenderer = Boolean(this.onRenderItem);

    if (changedProps.has('maxVisibleItems')) {
      this.style.setProperty('--max-visible-items', this.maxVisibleItems);
    }

    if (changedProps.has('items')) {
      const value =
        this.items && this.items.length > this.maxVisibleItems
          ? 'scroll'
          : 'hidden';

      if (this.open) {
        this.__up = this.shouldOpenMenuUpwards() && !this.forceDown;
      }
      this.style.setProperty('--content-overflow', value);
    }

    if (changedProps.has('itemHeight')) {
      this.style.setProperty('--item-height', `${this.itemHeight}px`);
    }

    if (changedProps.has('itemMinWidth')) {
      this.style.setProperty('--item-min-width', `${this.itemMinWidth}px`);
    }

    if (changedProps.has('open')) {
      this.__softSelectedIndex = this.startingSoftSelectedIndex;

      if (!this.open) {
        this.__softSelectedIndex = -1;
        this.__dismissing = !this.__firstRun;
        this.__firstRun = false;
      } else {
        this.__up = this.shouldOpenMenuUpwards() && !this.forceDown;
      }
    }

    if (changedProps.has('__dismissing') && !this.__dismissing && !this.open) {
      this.__up = false;
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    const KEYS = ['open', 'items', 'thresholdCount'];

    if (KEYS.some(key => changedProps.has(key))) {
      if (this.open && this.getItemCount() > this.maxVisibleItems) {
        const scrollElem = this.shadowRoot.getElementById(ELEMENTS.scroll.id);
        this.__scrollService = new NebScrollThreshold(scrollElem, {
          lowerThreshold: this.getRequestThreshold(),
          onLowerThreshold: () => this.onRequest(),
        });

        if (this.selectedIndex !== -1) {
          this.__right =
            this.shouldOpenMenuFromRight() && this.forceAlign !== 'left';

          this.__scrollToSelection(this.selectedIndex, true);
        }
      } else {
        this.__scrollService = null;
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          position: absolute;
          top: auto;
          bottom: 0;
          width: 100%;
          z-index: -1;
          transition: z-index 200ms;
        }

        :host([open]) {
          z-index: 1000;
        }

        :host([up]) {
          top: 0;
          bottom: auto;
        }

        .container {
          position: relative;
          transform: scale(0.9);
          opacity: 0;
          z-index: 1000;
          transition:
            transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
            opacity 200ms;
        }

        :host([open]) .container {
          transform: scale(1);
          opacity: 1;
        }

        :host([showFullText]) .scroll {
          min-width: 100%;
          width: auto;
          white-space: nowrap;
        }

        .scroll {
          overflow-x: auto;
          overflow-y: var(--content-overflow);
          position: absolute;
          width: 100%;
          max-width: unset;
          min-height: 0;
          max-height: calc(
            var(--item-height) * var(--max-visible-items) + 16px
          );
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          box-shadow:
            0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);
        }

        :host([up]) .scroll {
          bottom: 0;
        }

        :host([right]) .scroll,
        :host([forcealign='right']) .scroll {
          right: 0;
        }

        :host([forcealign='left']) .scroll {
          left: 0;
        }

        .content {
          display: flex;
          padding: 8px 0;
          width: 100%;
          flex-flow: column nowrap;
          transition: transform 200ms ease-out;
        }

        :host([customrenderer]) .content {
          padding: 0;
        }

        .divider {
          margin: 8px 0;
          height: 1px;
          background-color: ${CSS_COLOR_GREY_2};
        }

        .action-icon {
          width: 16px;
          height: 16px;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: ${CSS_SPACING};
        }

        .action-icon,
        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .item {
          min-width: var(--item-min-width);
          display: flex;
          cursor: pointer;
          padding: 0 16px;
          height: var(--item-height);
          font-size: ${CSS_FONT_SIZE_BODY};
          align-items: center;
        }

        :host([customrenderer]) .item {
          padding: 0;
        }

        .item[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          fill: ${CSS_COLOR_WHITE};
          color: ${CSS_COLOR_WHITE};
        }

        .item[selected] > div > .action-icon {
          fill: ${CSS_COLOR_WHITE};
        }

        .item[softselected]:not([selected]) {
          background-color: ${CSS_COLOR_GREY_3};
        }

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
          min-width: 0;
        }

        :host([wrapText]) p {
          white-space: normal;
        }

        :host([showFullText]) p {
          text-overflow: clip;
        }

        :host([hasNewItemOption]) .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        .new-item-label {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .action-icon-content {
          width: 100%;
          display: grid;
          align-items: center;
          grid-template-columns: auto 1fr;
          gap: 8px;
        }
      `,
    ];
  }

  renderDivider(index) {
    return index ? html` <div class="divider"></div> ` : '';
  }

  __renderDefaultItem({ item, labelClass }) {
    const icon = item.icon
      ? html`
          <neb-button-icon
            class="icon"
            .icon="${item.icon}"
            ?disabled="${item.disabled}"
          ></neb-button-icon>
        `
      : '';

    return html`
      ${icon}
      <p class="${labelClass}">${item.label}</p>
    `;
  }

  __renderItemWithActionIcon({ item, labelClass, index }) {
    return html`
      <div class="action-icon-content">
        <neb-button-icon
          class="action-icon"
          icon="${this.actionIcon}"
          @click="${e => {
            this.onClickActionIcon({ ...item, index });
            e.stopPropagation();
          }}"
        ></neb-button-icon>
        ${this.__renderDefaultItem({ item, labelClass })}
      </div>
    `;
  }

  renderItem(item, index) {
    const lastItem = index === this.items.length - 1;
    const isAddNewItemOption = lastItem && this.hasNewItemOption;
    const labelClass = isAddNewItemOption ? 'new-item-label' : 'label';

    if (this.onRenderItem && !isAddNewItemOption) {
      return this.onRenderItem(item, index);
    }

    const options = { item, labelClass, index };

    return item.label && !isAddNewItemOption && this.actionIcon
      ? this.__renderItemWithActionIcon(options)
      : this.__renderDefaultItem(options);
  }

  renderList(items, index) {
    const startOffset = this.hasDividers()
      ? this.items
          .slice(0, index)
          .reduce((accum, curr) => curr.length + accum, 0)
      : 0;
    return items.map((item, index) => {
      const outerIndex = index + startOffset;
      return html`
        <div
          class="item"
          .index="${outerIndex}"
          ?selected="${outerIndex === this.selectedIndex}"
          ?softselected="${outerIndex === this.__softSelectedIndex}"
          @click="${this.__handlers.select}"
          @mousemove="${this.__handlers.softSelect}"
          title="${this.showTitle ? item.label : ''}"
        >
          ${this.renderItem(item, index)}
        </div>
      `;
    });
  }

  renderItems() {
    return this.hasDividers()
      ? this.items.map(
          (item, index) => html`
            ${this.renderDivider(index)} ${this.renderList(item, index)}
          `,
        )
      : this.renderList(this.items);
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @mousedown="${this.__handlers.mousedown}"
        @transitionend="${this.__handlers.dismiss}"
      >
        ${this.open || this.__dismissing
          ? html`
              <div id="${ELEMENTS.scroll.id}" class="scroll">
                <div
                  id="${ELEMENTS.content.id}"
                  class="content"
                  @mouseleave="${this.__handlers.softDeselect}"
                >
                  ${this.renderItems()}
                </div>
              </div>
            `
          : ''}
      </div>
    `;
  }
}

window.customElements.define('neb-menu', Menu);
