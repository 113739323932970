import '../tables/neb-table-authorized-procedure-select';
import '../neb-action-bar';
import '../neb-pagination';
import '../neb-popup-header';
import '../inputs/neb-textfield';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { normalizeForSearch } from '../../../../neb-utils/formatters';
import {
  CollectionService,
  SORT_DIR,
} from '../../../../neb-utils/services/collection';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  searchField: {
    id: 'search',
  },
  table: {
    id: 'table',
  },
  noChargesMessage: {
    id: 'no-charges-message',
  },
  noResultsMessage: {
    id: 'no-results',
  },
  chargesLink: {
    id: 'charges-link',
  },
  chargeCodesLink: {
    id: 'charge-codes-link',
  },
  pagination: {
    id: 'pagination',
  },
  actionBar: {
    id: 'action-bar',
  },
};

class NebOverlayAddAuthorizedProcedures extends Overlay {
  static get properties() {
    return {
      __currentPageIndex: {
        type: Number,
      },
      __items: {
        type: Array,
      },
      __pageCount: {
        type: Number,
      },
      __searchText: {
        type: String,
      },
      __selectedCharges: {
        type: Array,
      },
    };
  }

  initState() {
    super.initState();

    this.__currentPageIndex = 0;
    this.__items = [];
    this.__pageCount = 0;
    this.__searchText = '';
    this.__selectedCharges = [];

    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems, searchText }) => {
          this.__currentPageIndex = pageIndex;
          this.__items = pageItems;
          this.__pageCount = pageCount;
          this.__searchText = searchText;
        },
        onSort: (a, b, key) => a[key].localeCompare(b[key]),
        onCacheItem: ({ procedure, description }) =>
          normalizeForSearch([procedure, description].join(' ')),
        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
      },
      {
        hideInactive: false,
        sortParams: {
          key: 'procedure',
          dir: SORT_DIR.ASC,
        },
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      apply: () => this.dismiss(this.__selectedCharges),
      clearSearch: () => this.__collectionService.search(''),
      search: ({ value }) => {
        if (value !== this.__searchText) this.__collectionService.search(value);
      },
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
      addCharge: rowIndex => {
        this.__selectedCharges = [
          ...this.__selectedCharges,
          { ...this.__items[rowIndex] },
        ];
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__collectionService.setItems(this.model.items);
      this.__selectedCharges = [...this.model.selectedCharges];
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 860px;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 25px ${CSS_SPACING};
        }

        .description {
          padding-left: ${CSS_SPACING};
        }

        .search-field {
          margin: 15px ${CSS_SPACING} ${CSS_SPACING};
        }

        .container-table {
          overflow-y: auto;
        }

        .no-items-message {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: 10px ${CSS_SPACING};
        }

        .text-link {
          cursor: pointer;
          font-style: none;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }
      `,
    ];
  }

  __renderNoItemsMessage() {
    return this.__searchText
      ? html`
          <p id="${ELEMENTS.noResultsMessage.id}" class="no-items-message">
            No results.
          </p>
        `
      : html`
          <p id="${ELEMENTS.noChargesMessage.id}" class="no-items-message">
            There are no Charges or Charge Codes. Please configure
            <span
              id="${ELEMENTS.chargesLink.id}"
              class="text-link"
              @click="${this.handlers.clickCharges}"
              >Charges
            </span>
            or
            <span
              id="${ELEMENTS.chargeCodesLink.id}"
              class="text-link"
              @click="${this.handlers.clickChargeCodes}"
              >Charge Codes
            </span>
            for the practice.
          </p>
        `;
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  __renderTable() {
    return this.__items.length > 0
      ? html`
          <div class="container-table">
            <neb-table-authorized-procedure-select
              id="${ELEMENTS.table.id}"
              .layout="${this.layout}"
              .model="${this.__items}"
              .onAddCharge="${this.handlers.addCharge}"
              .selectedItems="${this.__selectedCharges}"
            ></neb-table-authorized-procedure-select>
          </div>

          ${this.__renderPagination()}
        `
      : html` ${this.__renderNoItemsMessage()} `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Procedure"
        .onCancel="${this.handlers.apply}"
        showCancelButton
      ></neb-popup-header>

      <div class="description">
        Search for and select procedure codes to add as an authorized procedure.
      </div>

      <neb-textfield
        id="${ELEMENTS.searchField.id}"
        class="search-field"
        label="Enter code or description to filter list below."
        leadingIcon="neb:search"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .trailingVisible="${!!this.__searchText}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>

      ${this.__renderTable()}

      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }
}

customElements.define(
  'neb-overlay-add-authorized-procedures',
  NebOverlayAddAuthorizedProcedures,
);
