export const PLATFORM_OS = Object.freeze({
  MAC: 'Mac',
  WINDOWS: 'Windows',
  OTHER: 'Other',
  UNKNOWN: 'Unknown',
});

export const mapPlatformOSToEnum = platform => {
  if (!platform) {
    return PLATFORM_OS.UNKNOWN;
  }

  const platformText = platform.toLowerCase();

  if (platformText.startsWith('win')) {
    return PLATFORM_OS.WINDOWS;
  }

  if (platformText.startsWith('mac')) {
    return PLATFORM_OS.MAC;
  }

  return PLATFORM_OS.OTHER;
};
