import {
  mapClientModelToUser,
  mapClientModelToProvider,
} from './mappers/practice-user-mapper';
import DeprecatedApiClient, { getTenantId } from './utils/api-client-utils';
import { initReduxStore } from './utils/dynamic-imports/lazy-redux-store';

const MINUTE = 60 * 1000;

export const apiClient = new DeprecatedApiClient({
  microservice: 'permissions',
  cacheConfig: {
    max: 200,
    maxAge: 10 * MINUTE,
  },
});

const getUsers = ({
  cacheKey,
  queryParams = {},
  optOutLoadingIndicator = false,
}) =>
  apiClient.makeRequest({
    path: 'practice-users',
    queryParams,
    version: 'tenant2',
    cacheKey,
    optOutLoadingIndicator,
  });

const getProviders = async ({
  cacheKey,
  queryParams = {},
  optOutLoadingIndicator = false,
}) =>
  (
    await getUsers({
      cacheKey,
      queryParams: { type: 'provider,specialist', ...queryParams },
      optOutLoadingIndicator,
    })
  ).map(user => mapClientModelToProvider(user));

export const getPracticeUsers = async () => {
  await initReduxStore();
  const tenantId = getTenantId();

  return (
    await getUsers({
      cacheKey: `${tenantId}-all-users`,
      optOutLoadingIndicator: true,
    })
  ).map(user => mapClientModelToUser(user));
};

export const getProviderUsers = (optOutLoadingIndicator = false) =>
  getProviders({
    cacheKey: 'all-providers',
    optOutLoadingIndicator,
  });

export const getActiveProviderUsers = (optOutLoadingIndicator = false) =>
  getProviders({
    cacheKey: 'active-providers',
    queryParams: { active: true },
    optOutLoadingIndicator,
  });
