import '../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_3,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  card: {
    id: 'card',
  },
  icon: {
    id: 'icon',
  },
  title: {
    id: 'title',
  },
};

class NebAppointmentCard extends LitElement {
  static get properties() {
    return {
      icon: { type: String },
      title: { type: String },
      selected: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.icon = '';
    this.title = '';
    this.selected = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        e.stopPropagation();
        if (!this.selected) this.onClick();
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: auto;
        }

        :host(:not([selected])) {
          border: 3px solid transparent;
          cursor: pointer;
        }

        :host([selected]) {
          cursor: default;
        }

        :host([selected]) {
          cursor: default;
          border: 3px solid ${CSS_COLOR_HIGHLIGHT};
          border-radius: 4px;
        }

        :host(:not([selected]):hover),
        :host(:not([selected]):focus) {
          background-color: #f5f5f5;
        }

        .container {
          display: flex;
          align-items: center;
          justify-items: left;
          height: 100%;
          padding: 15px;
          border-radius: 4px;
          box-shadow: 0 1px 3px #00000033;
          background: ${CSS_COLOR_WHITE};
        }

        .container:hover {
          background: ${CSS_COLOR_GREY_3};
        }

        .title {
          font-size: 14px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-left: 10px;
        }

        .icon {
          height: 50px;
          width: 50px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  render() {
    return html`
      <div
        class="container"
        id="${ELEMENTS.card.id}"
        @click="${this.__handlers.click}"
      >
        <neb-icon
          id="${ELEMENTS.icon.id}"
          class="icon"
          icon="neb:${this.icon}"
        ></neb-icon>
        <div id="${ELEMENTS.title.id}" class="title">${this.title}</div>
      </div>
    `;
  }
}
window.customElements.define('neb-appointment-card', NebAppointmentCard);
