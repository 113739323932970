import { PACKAGE_TYPE } from './enums';
import { formatFrequencyPhrase } from './patientPackage';

const checkVisitSubscriptionFields = packageTemplate => {
  const { totalPrice, totalVisits, unlimited, frequency } = packageTemplate;
  return [totalPrice, totalVisits || unlimited, frequency].every(t => !!t);
};

const checkVisitFields = packageTemplate => {
  const { totalPrice, totalVisits } = packageTemplate;
  return Boolean(totalPrice && totalVisits);
};

const calculateVisitCount = packageTemplate => {
  const { unlimited, subscription, totalVisits } = packageTemplate;
  const visitCount = unlimited && subscription ? 'Unlimited' : totalVisits;
  return `${visitCount} ${visitCount > 1 || unlimited ? 'Visits' : 'Visit'}`;
};

const buildVisitSummary = packageTemplate => {
  const { subscription, totalPrice } = packageTemplate;
  const allFieldsEntered = subscription
    ? checkVisitSubscriptionFields(packageTemplate)
    : checkVisitFields(packageTemplate);
  return allFieldsEntered
    ? `${calculateVisitCount(packageTemplate)} ${
        totalPrice ? `for ${totalPrice}` : ''
      }${formatFrequencyPhrase(packageTemplate)}`
    : '';
};

const checkServiceSubscriptionFields = packageTemplate => {
  const { totalPrice, frequency, charges } = packageTemplate;
  return (
    !!totalPrice &&
    !!frequency &&
    charges.length > 0 &&
    charges.every(c => !!c.units)
  );
};

const checkServiceFields = packageTemplate => {
  const { totalPrice, charges } = packageTemplate;
  return !!totalPrice && charges.length > 0 && charges.every(c => !!c.units);
};

const calculateServiceUnits = packageTemplate => {
  const units = packageTemplate.charges.reduce(
    (total, c) => total + parseInt(c.units, 10),
    0,
  );
  return `${units} ${units > 1 ? 'Services' : 'Service'}`;
};

const buildServiceSummary = packageTemplate => {
  const { subscription, totalPrice } = packageTemplate;
  const allFieldsEntered = subscription
    ? checkServiceSubscriptionFields(packageTemplate)
    : checkServiceFields(packageTemplate);
  return allFieldsEntered
    ? `${calculateServiceUnits(packageTemplate)} ${
        totalPrice ? `for ${totalPrice}` : ''
      }${formatFrequencyPhrase(packageTemplate)}`
    : '';
};

export const buildPackageTemplateSummary = packageTemplate =>
  packageTemplate.type === PACKAGE_TYPE.VISIT
    ? buildVisitSummary(packageTemplate).trim()
    : buildServiceSummary(packageTemplate).trim();
