import '../../../packages/neb-lit-components/src/components/controls/neb-switch';
import './neb-icon';
import '../../../packages/neb-lit-components/src/components/neb-button';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  icon: {
    id: 'icon',
  },
  generateCredentialsSlot: {
    id: 'generate-credentials-slot',
  },
  labelAllUsers: {
    id: 'label-all-users',
  },
  labelDescription: {
    id: 'label-description',
  },
  labelTitle: {
    id: 'label-title',
  },
  switch: {
    id: 'switch',
  },
};
class NebCardSwitch extends LitElement {
  static get properties() {
    return {
      active: {
        type: Boolean,
      },
      alwaysOn: {
        type: Boolean,
        reflect: true,
      },
      description: {
        type: String,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      icon: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      name: {
        type: String,
      },
      title: {
        type: String,
      },
      isDirty: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.active = false;
    this.alwaysOn = false;
    this.description = '';
    this.disabled = false;
    this.icon = '';
    this.layout = '';
    this.name = '';
    this.title = '';
    this.isDirty = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      switch: ({ name, value }) =>
        this.onChange({
          id: this.id,
          name,
          value,
        }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: auto auto;
          min-height: 100px;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          padding: ${CSS_SPACING};

          border-radius: 4px;
          border: 1px solid ${CSS_COLOR_GREY_2};
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }

        .content {
          display: flex;
          align-items: center;
        }

        .label-title {
          width: 100px;
          margin-right: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .label-description {
          flex: 1 0 0;
          width: auto;
          max-width: 460px;
          margin-right: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .label-all {
          width: 70px;
          font-style: italic;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon {
          flex: 0 0 24px;
          display: flex;
          width: 28px;
          height: 28px;
          margin-right: ${CSS_SPACING};
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([layout='small']) .title {
          margin-right: 10px;
        }

        :host([layout='small']) .label-description {
          max-width: 85px;
          margin-right: ${CSS_SPACING};
        }

        :host([layout='small']) .label-all {
          width: 30px;
          margin-right: ${CSS_SPACING};
        }

        :host([layout='small']) .icon {
          margin-right: 10px;
        }
      `,
    ];
  }

  __renderSwitch() {
    return !this.alwaysOn
      ? html`
          <neb-switch
            id="${ELEMENTS.switch.id}"
            class="switch"
            name="${this.name}"
            .onChange="${this.__handlers.switch}"
            ?on="${this.active}"
            ?disabled="${this.disabled}"
            >${this.active}</neb-switch
          >
        `
      : html`
          <div id="${ELEMENTS.labelAllUsers.id}" class="label label-all">
            All Users
          </div>
        `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="content">
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon"
            .icon="${this.icon}"
          ></neb-icon>

          <div id="${ELEMENTS.labelTitle.id}" class="label label-title">
            ${this.title}
          </div>

          <div
            id="${ELEMENTS.labelDescription.id}"
            class="label label-description"
          >
            ${this.description}
          </div>
        </div>

        ${this.__renderSwitch()}
        <slot
          id="${ELEMENTS.generateCredentialsSlot.id}"
          name="generateCredentials"
        ></slot>
      </div>
    `;
  }
}
customElements.define('neb-card-switch', NebCardSwitch);
