import './neb-textbox';
import './neb-floating-label';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_FIELD_MARGIN } from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  textbox: {
    id: 'textbox',
  },
  input: {
    id: 'input',
  },
  floatingLabel: {
    id: 'floating-label',
  },
};

export class TextInput extends LitElement {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      __invalid: {
        reflect: true,
        type: Boolean,
        attribute: 'invalid',
      },
      maxLength: Number,
      placeholder: String,
      helper: String,
      error: Boolean || String,
      value: String,
      name: String,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      readonly: {
        reflect: true,
        type: Boolean,
      },
      pinLabel: {
        reflect: true,
        type: Boolean,
      },
      label: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;

          --text-indent: 12px;
        }

        .container {
          display: flex;
          cursor: text;
          position: relative;
          padding-top: ${CSS_FIELD_MARGIN};
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        :host([label='']) .container {
          padding-top: 0;
        }

        .textbox {
          --indent: var(--text-indent);
        }

        .content {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__focused = false;
    this.__invalid = false;
    this.error = false;
    this.disabled = false;
    this.readonly = false;
    this.pinLabel = false;
    this.maxLength = null;
    this.placeholder = '';
    this.helper = '';
    this.label = '';
    this.value = '';
    this.name = '';

    this.onFocus = () => {};

    this.onBlur = () => {};

    this.onChange = null;
  }

  initHandlers() {
    this.handlers = {
      stopClick: e => e.stopPropagation(),
      focus: e => {
        this.__focused = !this.readonly;
        this.onFocus({
          name: this.name,
          value: e.currentTarget.value,
        });

        if (this.onChange) {
          this.onChange({
            name: this.name,
            value: e.currentTarget.value,
            event: 'focus',
          });
        }
      },
      blur: e => {
        this.__focused = false;
        this.onBlur({
          name: this.name,
          value: e.currentTarget.value,
        });

        if (this.onChange) {
          this.onChange({
            name: this.name,
            value: e.currentTarget.value,
            event: 'blur',
          });
        }
      },
    };
  }

  isFocused() {
    return this.__focused;
  }

  isPinned() {
    return (
      (this.value && this.value.length > 0) || this.__focused || this.pinLabel
    );
  }

  getLabel() {
    const useAsterisk = this.label.trim() && this.helper === 'Required';
    return useAsterisk ? `${this.label}*` : this.label;
  }

  getHelperText() {
    if (this.error) {
      switch (typeof this.error) {
        case 'boolean':
          return this.error ? this.helper : '';

        case 'string':
          return this.error || '';

        default:
      }
    }

    return this.helper;
  }

  getPlaceholder() {
    const show =
      this.placeholder.length > 0 &&
      (this.label.trim().length === 0 || this.isPinned());

    return show ? this.placeholder : '';
  }

  update(changedProps) {
    if (changedProps.has('error')) {
      this.__invalid = Boolean(this.error);
    }

    super.update(changedProps);
  }

  firstUpdated() {
    this.elements = {
      textbox: this.shadowRoot.getElementById(ELEMENTS.textbox.id),
      input: this.shadowRoot.getElementById(ELEMENTS.input.id),
      floatingLabel: this.shadowRoot.getElementById(ELEMENTS.floatingLabel.id),
    };
  }

  renderInputElement() {
    throw new Error('renderInputElement() - Unimplemented', this);
  }

  renderHeader() {
    return html``;
  }

  renderFooter() {
    return html``;
  }

  renderLabel() {
    return this.label
      ? html`
          <neb-floating-label
            id="${ELEMENTS.floatingLabel.id}"
            .text="${this.getLabel()}"
            ?focused="${this.__focused}"
            ?invalid="${Boolean(this.error)}"
            ?pinned="${this.isPinned()}"
            ?disabled="${this.disabled}"
          ></neb-floating-label>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.handlers.stopClick}"
      >
        ${this.renderHeader()}

        <neb-textbox
          id="${ELEMENTS.textbox.id}"
          class="textbox"
          ?focused="${this.__focused}"
          ?invalid="${Boolean(this.error)}"
          ?disabled="${this.disabled}"
        >
          <div class="content">${this.renderInputElement()}</div>
        </neb-textbox>

        ${this.renderLabel()} ${this.renderFooter()}
      </div>
    `;
  }
}
