import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../filters/neb-filters-case-statements';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/neb-pagination';

import { fetchPdf } from '../../../../../packages/neb-api-client/src/ledger-superbill-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import { currency } from '../../../../../packages/neb-utils/masks';
import { printPdf } from '../../../../../packages/neb-utils/neb-pdf-print-util';
import { SORT_DIR } from '../../../../../packages/neb-utils/services/fetch';
import { getAllCaseStatements } from '../../../../api-clients/patient-case-statements';
import { NO_CASE_STATEMENTS_AVAILABLE } from '../../../../utils/user-message';

export const ELEMENTS = {
  caseStatementsContainer: {
    id: 'case-statements-container',
  },
  caseStatementsTable: {
    id: 'case-statements-table',
  },
  caseStatementsPagination: {
    id: 'case-statements-pagination',
  },
  generateCaseStatementButton: {
    id: 'generate-case-statement-button',
  },
  caseStatementsFilter: {
    id: 'case-statements-filter',
  },
  tabDescription: {
    id: 'tab-description',
  },
};

const CONFIG = [
  {
    key: 'createdAt',
    label: 'Date',
    sortable: true,
    flex: css`1 0 0`,
  },
  {
    key: 'statementNumber',
    label: 'Case Statement ID',
    sortable: true,
    flex: css`1 0 0`,
  },
  {
    key: 'attorneyName',
    label: 'Attorney',
    sortable: true,
    flex: css`1 0 0`,
  },
  {
    key: 'organizationName',
    label: 'Organization',
    sortable: true,
    flex: css`1 0 0`,
  },
  {
    key: 'providerName',
    label: 'Provider',
    sortable: true,
    flex: css`1 0 0`,
  },
  {
    key: 'totalCharges',
    label: 'Total Charges',
    sortable: true,
    flex: css`1 0 0`,
  },
];

class NebCaseStatements extends LitElement {
  static get properties() {
    return {
      model: Object,
      organizations: Array,
      preferredProviderId: String,
      __sortParams: Object,
      __caseStatements: Array,
      __providers: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.organizations = [];
    this.preferredProviderId = '';
    this.__caseStatements = [];
    this.__providers = [];

    this.__sortParams = {
      key: CONFIG[0].key,
      dir: SORT_DIR.DESC,
    };

    this.__pageSize = 10;
    this.__currentPage = 0;
    this.__pageCount = 1;
    this.__totalCaseStatements = 0;

    this.__queryParams = {
      limit: this.__pageSize,
      offset: 0,
    };

    this.model = {
      id: '',
      patientId: '',
      attorneys: [],
    };
  }

  connectedCallback() {
    const {
      providers: { item },
    } = store.getState();

    this.__providers = item;

    super.connectedCallback();
  }

  __resetPagination() {
    this.__queryParams.offset = 0;
    this.__currentPage = 0;
  }

  async __getCaseStatements(queryParams = {}) {
    const { data, count } = await getAllCaseStatements(
      this.model.patientId,
      this.model.id,
      {
        ...queryParams,
        sortField: this.__sortParams.key,
        sortDir: this.__sortParams.dir,
      },
    );

    this.__totalCaseStatements = count;

    if (count > 10) this.__pageCount = Math.ceil(count / this.__pageSize);

    this.__caseStatements = data.map(row => ({
      attorneyName: row.attorneyName,
      organizationName: row.organizationName,
      statementNumber: row.statementNumber,
      providerName: row.providerName,
      s3key: row.s3key,
      totalCharges: currency.toFormattedValue(row.totalCharges),
      createdAt: parseDate(row.createdAt).format('MM/DD/YYYY'),
    }));
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      await this.__getCaseStatements(this.__queryParams);
    }
  }

  __initHandlers() {
    this.__handlers = {
      openGenerateCaseStamentPopup: async () => {
        const model = {
          ...this.model,
          organizations: this.organizations,
          providers: this.__providers,
          preferredProviderId: this.preferredProviderId,
        };

        const result = await openPopup(
          POPUP_RENDER_KEYS.NEW_CASE_STATEMENT,
          model,
        );

        this.__resetPagination();

        if (result) this.__getCaseStatements(this.__queryParams);
      },
      sort: async (_, result) => {
        this.__sortParams = result[0];
        this.__resetPagination();
        await this.__getCaseStatements(this.__queryParams);
      },
      filterApply: async filterParams => {
        this.__currentPage = 0;
        this.__queryParams = {
          limit: this.__queryParams.limit,
          offset: 0,
          ...filterParams,
        };

        await this.__getCaseStatements(this.__queryParams);
      },
      selectPage: async pageIndex => {
        this.__currentPage = pageIndex;
        this.__queryParams.offset = this.__pageSize * pageIndex;
        await this.__getCaseStatements(this.__queryParams);
      },
      openPdf: (_, caseStatement) => printPdf(fetchPdf(caseStatement.s3key)),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
          width: unset;
          height: unset;
        }

        .container {
          width: 100%;
          height: 100%;
          overflow: auto;
        }

        .table {
          padding-top: ${CSS_SPACING};
        }

        .tab-description {
          padding-left: ${CSS_SPACING};
        }

        .button-add {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .pagination {
          float: right;
          padding-bottom: ${CSS_SPACING};
          margin: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderTabDescription() {
    return html`
      <p id="${ELEMENTS.tabDescription.id}" class="tab-description">
        View and generate case statements for the patient.
      </p>
    `;
  }

  __renderGenerateCaseStatementsButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.generateCaseStatementButton.id}"
        class="button-add"
        label="Generate New Case Statement"
        .onClick="${this.__handlers.openGenerateCaseStamentPopup}"
      ></neb-button-action>
    `;
  }

  __renderPagination() {
    return this.__totalCaseStatements > 10
      ? html`
          <div class="pagination">
            <neb-pagination
              id="${ELEMENTS.caseStatementsPagination.id}"
              .pageCount="${this.__pageCount}"
              .currentPage="${this.__currentPage}"
              .onPageChanged="${this.__handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.caseStatementsContainer.id}" class="container">
        ${this.__renderTabDescription()}
        ${this.__renderGenerateCaseStatementsButton()}
        <neb-filters-case-statements
          id="${ELEMENTS.caseStatementsFilter.id}"
          .providers="${this.__providers}"
          .organizations="${this.organizations}"
          .attorneys="${this.model.attorneys}"
          .onApply="${this.__handlers.filterApply}"
        ></neb-filters-case-statements>
        <neb-table
          class="table"
          id="${ELEMENTS.caseStatementsTable.id}"
          emptyMessage="${NO_CASE_STATEMENTS_AVAILABLE}"
          mode="detail"
          .sortParams="${[this.__sortParams]}"
          .onSort="${this.__handlers.sort}"
          .config="${CONFIG}"
          .model="${this.__caseStatements}"
          .onSelectRow="${this.__handlers.openPdf}"
        ></neb-table>
        ${this.__renderPagination()}
      </div>
    `;
  }
}

window.customElements.define('neb-case-statements', NebCaseStatements);
