import '../../neb-popup-header';
import '../../patients/neb-patient-form-controller';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  createModel,
  mapToPatientModel,
} from '../../../../../neb-utils/patient';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  controller: {
    id: 'controller',
  },
};
const TOASTER_SUCCESS = 'Patient Profile Saved Successfully';
const PATIENT_EMPTY = createModel();

class NebCreatePatientOverlay extends Overlay {
  initState() {
    super.initState();
    this.model = {
      patient: null,
      onSuccessMessage: TOASTER_SUCCESS,
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.changePatient = dirty => {
      this.isDirty = dirty;
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__patient = this.model.patient
        ? mapToPatientModel(this.model.patient)
        : PATIENT_EMPTY;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 800px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .controller {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Create New Patient Record"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-patient-form-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .layout="${this.layout}"
        .successBanner="${this.model.onSuccessMessage
          ? this.model.onSuccessMessage
          : TOASTER_SUCCESS}"
        .patient="${this.__patient}"
        .onChangeDirty="${this.handlers.changePatient}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
        slim
      ></neb-patient-form-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-create',
  NebCreatePatientOverlay,
);
