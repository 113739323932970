import { navigate } from '@neb/router';
import { html, css, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { capitalize } from '../../../../packages/neb-utils/formatters';
import { getActivePatientPackages } from '../../../../packages/neb-utils/patientPackage';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-packages-subscriptions';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import { deepCopy } from '../../../../packages/neb-utils/utils';
import { reorderPatientPackages } from '../../../api-clients/patient-package';
import { CSS_COLOR_WHITE, CSS_SPACING } from '../../../styles';

export const ELEMENTS = {
  buttonAddPackage: { id: 'button-add-package' },
  table: { id: 'table' },
};

const NO_ITEMS_TEXT =
  'There are no packages. Click “Add New Package” to add a package for this patient.';

export const REORDER_ERROR_MESSAGE = 'Could not reorder packages.';
export const REORDER_SUCCESS_MESSAGE = 'Reorder of packages successful';

class NebPageActiveCarePackagesSubscriptions extends LitElement {
  static get properties() {
    return {
      patientId: String,
      packageItems: Array,
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.patientId = '';
    this.packageItems = [];

    this.onPackagesSubscriptionsChange = () => {};
  }

  initHandlers() {
    this.handlers = {
      addCarePackage: async () => {
        const filteredCount = this.packageItems.filter(
          p => p.isDefault === true,
        ).length;

        const result = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_ADD, {
          context: {
            patientId: this.patientId,
            filteredCount,
          },
        });

        if (result) {
          await this.__getActivePatientPackages();
        }

        this.onPackagesSubscriptionsChange({
          hideInactive: true,
          count: this.packageItems.length,
          navigateAway: false,
        });
      },
      selectCarePackage: async (_, item) => {
        const result = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: item.id,
            patientId: this.patientId,
          },
        });

        if (result) {
          await this.__getActivePatientPackages();
        }
      },
      selectSharedBy: async ({ patientId }) => {
        navigate(`/patients/${patientId}/billing/packages-subscriptions`);

        this.patientId = patientId;
        await this.__getActivePatientPackages(patientId);
      },
      reorder: async (fromIndex, toIndex) => {
        const oldPackageItems = deepCopy(this.packageItems);
        const newPackageItems = deepCopy(this.packageItems);
        const fromItem = newPackageItems[fromIndex];

        newPackageItems.splice(fromIndex, 1);
        newPackageItems.splice(toIndex, 0, fromItem);

        newPackageItems.forEach(p => {
          p.isDefault = false;
        });

        newPackageItems[0].isDefault = true;

        this.packageItems = newPackageItems;

        try {
          await reorderPatientPackages(
            this.patientId,
            newPackageItems.map(i => i.id),
          );

          store.dispatch(openSuccess(REORDER_SUCCESS_MESSAGE));
        } catch (e) {
          console.error('Could not reorder list: ', e);
          this.packageItems = oldPackageItems;
          store.dispatch(openError(REORDER_ERROR_MESSAGE));
        }
      },
    };
  }

  __buildConfig() {
    return [
      {
        sortable: false,
        truncate: true,
        key: 'name',
        label: 'Name',
        flex: css`1.5 0 0`,
      },
      {
        sortable: false,
        truncate: true,
        key: 'summary',
        label: 'Summary',
        flex: css`1.5 0 0`,
      },
      {
        truncate: true,
        key: 'remaining',
        label: 'Remaining',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'subscription',
        label: 'Subscription',
        flex: css`1 0 0`,
        formatter: val => (val ? 'Yes' : 'No'),
      },
      {
        truncate: true,
        key: 'type',
        label: 'Type',
        flex: css`1 0 0`,
        formatter: val => capitalize(val),
      },
      {
        key: 'displayPackage',
        label: 'Status',
        flex: css`.5 0 0`,
        formatter: () => 'Active',
      },
      {
        truncate: true,
        key: 'isDefault',
        label: 'Default',
        flex: css`.75 0 0`,
        formatter: val => (val ? 'Default' : ''),
      },
      {
        truncate: true,
        key: 'patientPackageRelatedPatients',
        label: 'Shared',
        flex: css`1 0 0`,
        formatter: val =>
          val && val.filter(p => p.active).length > 0
            ? `Yes (${val.filter(p => p.active).length + 1})`
            : 'No',
      },
      {
        truncate: true,
        key: 'sharedByName',
        label: 'Shared By',
        flex: css`1 0 0`,
        link: 'true',
      },
    ];
  }

  updated(changedProps) {
    if (changedProps.has('patientId')) {
      this.__getActivePatientPackages();
    }
  }

  async __getActivePatientPackages() {
    this.packageItems = await getActivePatientPackages(this.patientId);
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.__getActivePatientPackages();
  }

  static get styles() {
    return [
      css`
        :host {
          overflow-y: auto;
          background-color: ${CSS_COLOR_WHITE};
          height: 100%;
        }

        .content {
          display: flex;
          flex-direction: column;
        }

        neb-button-action {
          display: flex;
          padding: ${CSS_SPACING} 0 ${CSS_SPACING} ${CSS_SPACING};
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="content">
        <neb-button-action
          id="${ELEMENTS.buttonAddPackage.id}"
          class="button-add-package"
          label="Add New Package"
          .onClick="${this.handlers.addCarePackage}"
        ></neb-button-action>
        <neb-table-packages-subscriptions
          id="${ELEMENTS.table.id}"
          .config="${this.__buildConfig()}"
          .model="${this.packageItems}"
          .patientId="${this.patientId}"
          .emptyMessage="${NO_ITEMS_TEXT}"
          .onSelectRow="${this.handlers.selectCarePackage}"
          .onSelectSharedBy="${this.handlers.selectSharedBy}"
          .onReorder="${this.handlers.reorder}"
          reorder
        >
        </neb-table-packages-subscriptions>
      </div>
    `;
  }
}

customElements.define(
  'neb-page-active-care-packages-subscriptions',
  NebPageActiveCarePackagesSubscriptions,
);
