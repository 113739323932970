import '../../../neb-material-design/src/components/neb-md-textfield';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
} from '../../../neb-styles/neb-variables';
import { number } from '../../../neb-utils/masks';

export const ELEMENTS = {
  maxUnitsField: {
    id: 'field-max-units',
  },
  bulkUpdateIcon: {
    id: 'bulk-update-icon',
  },
};
const ERROR_TEXT_UNITS = '1 - 999';

class NebBulkUpdate extends LitElement {
  static get properties() {
    return {
      maxUnits: String,
      disabled: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.maxUnits = '';
    this.disabled = false;

    this.onBulkUpdate = () => {};

    this.onUpdateMaxUnits = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      bulkUpdate: () => {
        if (!this.disabled) this.onBulkUpdate();
      },
      updateMaxUnits: ({ value }) => {
        this.onUpdateMaxUnits(value);
      },
      validateMaxUnits: () => this.maxUnits && parseInt(this.maxUnits, 10) > 0,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .max-units-container {
          display: flex;
          align-items: center;
        }

        .max-units-field {
          width: 100px;
        }

        .max-units-label {
          padding-right: 10px;
        }

        .icon {
          height: 28px;
          width: 28px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon[disabled] {
          fill: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  __renderUnitsField() {
    return html`
      <neb-md-textfield
        id="${ELEMENTS.maxUnitsField.id}"
        class="max-units-field"
        maxLength="3"
        labelText=" "
        helperText=" "
        .value="${this.maxUnits}"
        .errorText="${ERROR_TEXT_UNITS}"
        .externalValidator="${this.__handlers.validateMaxUnits}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .onChange="${this.__handlers.updateMaxUnits}"
      ></neb-md-textfield>
    `;
  }

  __renderIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.bulkUpdateIcon.id}"
        class="icon"
        icon="neb:updateAll"
        style="${this.disabled ? 'cursor: default' : ''}"
        ?disabled="${this.disabled}"
        @click="${this.__handlers.bulkUpdate}"
      ></neb-icon>
    `;
  }

  render() {
    return html`
      <div class="max-units-container">
        <div class="max-units-label">Bulk Max Unit Update</div>
        ${this.__renderUnitsField()} ${this.__renderIcon()}
      </div>
    `;
  }
}

customElements.define('neb-bulk-update', NebBulkUpdate);
