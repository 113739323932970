import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  code: { id: 'dropdown-code' },
  description: { id: 'textfield-description' },
  description2: { id: 'textfield-description2' },
};

class NebPopupClaimSpinalManipulation extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .text-container {
          display: block;
          gap: ${CSS_SPACING};
        }

        .text-input {
          width: 100%;
          padding-bottom: 16px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="text-container">
        <neb-select
          id="${ELEMENTS.code.id}"
          class="text-input"
          showFullText
          .name="${this.model.items[0].name}"
          .label="${this.model.items[0].label}"
          .value="${this.__state[this.model.items[0].name]}"
          .items="${this.model.items[0].items}"
          .onChange="${this.handlers.change}"
          .onRenderItem="${this.handlers.renderItem}"
        >
        </neb-select>

        <neb-textfield
          id="${ELEMENTS.description.id}"
          class="text-input"
          style="width: ${this.model.items[1].width}"
          .name="${this.model.items[1].name}"
          .label="${this.model.items[1].label}"
          .maxLength="${this.model.items[1].maxLength}"
          .value="${this.__state[this.model.items[1].name]
            ? this.__state[this.model.items[1].name].trim()
            : ''}"
          .mask="${this.model.items[1].mask}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.description2.id}"
          class="text-input"
          style="width: ${this.model.items[2].width}"
          .name="${this.model.items[2].name}"
          .label="${this.model.items[2].label}"
          .maxLength="${this.model.items[2].maxLength}"
          .value="${this.__state[this.model.items[2].name]
            ? this.__state[this.model.items[2].name].trim()
            : ''}"
          .mask="${this.model.items[2].mask}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-spinal-manipulation',
  NebPopupClaimSpinalManipulation,
);
