const ARMY_POST_OFFICE = ['AA', 'AE', 'AP'];

export const STATES = [
  '',
  ARMY_POST_OFFICE[0],
  ARMY_POST_OFFICE[1],
  'AK',
  'AL',
  ARMY_POST_OFFICE[2],
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const PACKAGE_TYPE = Object.freeze({
  VISIT: 'visit',
  SERVICE: 'service',
});

export const IDENTIFIERS = Object.freeze({
  '0B': {
    value: '0B',
    label: '0B - State License Number',
  },
  '1B': {
    value: '1B',
    label: '1B - Blue Shield Provider Number',
  },
  '1C': {
    value: '1C',
    label: '1C - Medicare Provider Number',
  },
  '1D': {
    value: '1D',
    label: '1D - Medicaid Provider Number',
  },
  '1G': {
    value: '1G',
    label: '1G - Provider UPIN Number',
  },
  '1H': {
    value: '1H',
    label: '1H - CHAMPUS Identification Number',
  },
  EI: {
    value: 'EI',
    label: 'EI - Employer’s Identification Number',
  },
  G2: {
    value: 'G2',
    label: 'G2 - Provider Commercial Number',
  },
  LU: {
    value: 'LU',
    label: 'LU - Location Number',
  },
  N5: {
    value: 'N5',
    label: 'N5 - Provider Plan Network Identification Number',
  },
  SY: {
    value: 'SY',
    label:
      'SY - Social Security Number (The social security number may not be used for Medicare.)',
  },
  X5: {
    value: 'X5',
    label: 'X5 - State Industrial Accident Provider Number',
  },
  ZZ: {
    value: 'ZZ',
    label: 'ZZ - Provider Taxonomy',
  },
});

export const PAYMENT_METHODS = {
  CASH: 'Cash',
  CHECK: 'Check',
  DEBIT_OR_CREDIT_CARD: 'Debit or Credit Card',
  DIRECT_DEPOSIT: 'Direct Deposit',
  ERA: 'ERA',
  DISCOUNT: 'Discount',
};

export const CARE_PACKAGE_STATUS = Object.freeze({
  VALID: 'Valid',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
  NOTVALID: 'Not Valid',
});

export const SCHEDULED_PAYMENT_TYPE = Object.freeze({
  EMPTY: '',
  SINGLE: 'singlePayment',
  RECURRING: 'recurringPayment',
  PLAN: 'paymentPlan',
});

export const SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP = Object.freeze({
  [SCHEDULED_PAYMENT_TYPE.EMPTY]: '',
  [SCHEDULED_PAYMENT_TYPE.SINGLE]: 'One Time',
  [SCHEDULED_PAYMENT_TYPE.RECURRING]: 'Recurring',
  [SCHEDULED_PAYMENT_TYPE.PLAN]: 'Payment Plan',
});

export const SCHEDULE_PAYMENT_STATUS = Object.freeze({
  SCHEDULED: null,
  FAILED: 'Failed',
  PAID: 'Paid',
  PAID_MANUALLY: 'Paid Manually',
});

export const SCHEDULE_RUN_STATUS = Object.freeze({
  SCHEDULED: null,
  FAILED: 'Failed',
  PAID: 'Paid',
});

export const TRANSACTION_TYPE = Object.freeze({
  PAYMENT: 'payment',
  ADJUSTMENT: 'adjustment',
  VOID_PAYMENT: 'voidPayment',
  REFUND_PAYMENT: 'refundPayment',
  LINE_ITEM: 'lineItem',
});

export const WRITE_OFF_CODE = Object.freeze({
  PACKAGE: 'Pkg',
});

export const WRITE_OFF_TYPE = Object.freeze({
  CONTRACTUAL_OBLIGATION: 'CO',
  OTHER_ADJUSTMENT: 'OA',
  PAYOR_INITIATED: 'PI',
  PATIENT_RESPONSIBILITY: 'PR',
});

export const PAYMENT_FREQUENCY = Object.freeze({
  WEEKLY: 'Weekly',
  BI_WEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
});

export const PACKAGE_FREQUENCY_DESCRIPTION_MAP = Object.freeze({
  [PAYMENT_FREQUENCY.WEEKLY]: 'per week',
  [PAYMENT_FREQUENCY.BI_WEEKLY]: 'every two weeks',
  [PAYMENT_FREQUENCY.MONTHLY]: 'per month',
});

export const PAYMENT_FREQUENCY_OPTIONS = Object.freeze(
  Object.values(PAYMENT_FREQUENCY),
);

export const FEDERAL_TAX_ID_TYPE = Object.freeze({
  SSN: 'SSN',
  EIN: 'EIN',
});

export const FEDERAL_TAX_ID_SOURCE = Object.freeze({
  PRACTICE_TAX_ID: 'Practice Tax ID',
  PROVIDER_TAX_ID: 'Provider Tax ID',
  LOCATION_TAX_ID: 'Location Tax Id',
  PROVIDER_SSN: 'Provider SSN',
  OTHER: 'Other',
});

export const PROVIDER_TYPE = Object.freeze({
  PROVIDER: 'provider',
  SPECIALIST: 'specialist',
});

export const FL33_TYPES = Object.freeze({
  BILLING: 'Billing',
  PAY_TO: 'PayTo',
});

export const CLAIM_RESUBMISSION_CODE = Object.freeze({
  CANCEL_RESUBMIT: '7',
  VOID_CANCEL: '8',
});

export const PROVIDER_SIGNATURE_OPTIONS = Object.freeze({
  ProviderSignatureOrSOF: 'Provider Signature or SOF',
});

export const SELECT_CHARGES_OVERLAY_TYPE = Object.freeze({
  NO_UNITS: 'no units',
  SHOW_UNITS: 'show units',
  SHOW_TYPE: 'show type',
});
