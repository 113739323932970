import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const BASE_PATH = 'payer-payment';

export const apiClient = new DeprecatedApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const post = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_PATH,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result.data[0];
};

export const put = async (paymentId, data) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${paymentId}`,
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result.data[0];
};

export const get = async (paymentId, eobId) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_PATH}/${paymentId}?eobId=${eobId}`,
    version: 1,
  });

  return result.data[0];
};
