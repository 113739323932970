import './neb-encounter-summary-view';

import { LitElement, html, css } from 'lit';

import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import { getAllEncounters } from '../../../../neb-api-client/src/encounters-api-client';
import { EncounterDataService } from '../../../../neb-api-client/src/services/encounter-data';
import { forceFetchAppointments } from '../../../../neb-calendar/neb-appointments-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LegacyHashService } from '../../../../neb-redux/services/legacy-hash';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { formatEncounterCards } from '../../../../neb-utils/neb-charting-util';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import {
  navigateToCharting,
  printEncounterSummary,
  openVisitSummary,
  openEncounterHistory,
  handleReopenEncounter,
  handleSignEncounter,
  handleDeleteEncounter,
} from '../../utils/encounter-actions-util';

export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

class NebEncounterSummaryController extends LitElement {
  static get properties() {
    return {
      layout: String,
      patientId: String,
      encounterId: String,
      encounterAppointmentTypeId: String,
      providers: Array,
      appointmentTypes: Array,
      runningLedger: Boolean,

      __locations: Array,
      __encounterModel: Object,
      __encounterCards: Array,
    };
  }

  static get styles() {
    return css`
      .view {
        display: flex;
        width: 100%;
        height: 100%;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.route = '';
    this.layout = '';
    this.encounterId = '';
    this.encounterAppointmentTypeId = '';
    this.patientId = null;
    this.providers = [];
    this.appointmentTypes = [];
    this.runningLedger = false;
    this.__encounterModel = null;
    this.__locations = [];
    this.__encounterCards = [];

    this.__encounterDataService = new EncounterDataService(async data => {
      this.__encounterModel = data;

      await this.__updateEncounterCards(
        this.patientId,
        this.appointmentTypes,
        this.providers,
      );
    });

    this.onDismiss = () => {};
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__legacyHashService = new LegacyHashService(({ hash }) => {
      this.baseHash = hash;
    });

    this.__notificationService = new UpdateNotificationService({
      defaultQuery: {},
      callback: () => this.__updateEncounterModel(this.encounterId),
    });
  }

  __initHandlers() {
    this.__handlers = {
      deleteEncounter: async encounterId => {
        const { charges, diagnoses } = this.__encounterModel;
        const data = await handleDeleteEncounter({
          encounterId,
          charges,
          diagnoses,
          patientId: this.patientId,
        });

        if (data && data.res) {
          await this.__handleEncounterAfterDelete(encounterId);
        }
      },
      dismiss: () => this.onDismiss(),
      editEncounter: encounterId => {
        navigateToCharting(encounterId);

        return this.onDismiss();
      },
      updateSummary: encounterId => this.__updateEncounterModel(encounterId),
      openAppointment: appointmentId =>
        openAppointmentPage(appointmentId, store, this.baseHash),
      openVisitSummary: encounterId =>
        openVisitSummary({
          patientId: this.patientId,
          encounterId,
        }),
      printEncounter: encounterId => {
        printEncounterSummary({
          patientId: this.patientId,
          encounterId,
        });
      },
      reopenEncounter: async encounterId => {
        await handleReopenEncounter(encounterId);

        this.__updateEncounterModel(encounterId);
      },
      signEncounter: async encounterId => {
        const {
          charges,
          notes: {
            encounter: { providerId },
          },
        } = this.__encounterModel;

        await handleSignEncounter({
          providerId,
          encounterId,
          patientId: this.patientId,
          charges,
        });

        this.__updateEncounterModel(encounterId);
      },
      viewEncounterHistory: encounterId => openEncounterHistory(encounterId),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
    this.__legacyHashService.connect();
    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
    this.__legacyHashService.disconnect();
    this.__notificationService.disconnect();
  }

  async __updateEncounterCards(patientId, appointmentTypes, providers) {
    const { data: priorEncounters } = await getAllEncounters({ patientId });

    const formattedPriorEncounters = formatEncounterCards(
      priorEncounters,
      providers,
      appointmentTypes,
      this.__locations,
    );

    this.__encounterCards = formattedPriorEncounters.map(data => ({
      label: data.formattedServiceDate,
      data,
    }));

    if (!this.encounterId) {
      if (priorEncounters.length) {
        const [{ id: encounterId }] = priorEncounters;
        this.encounterId = encounterId;
      } else {
        this.__encounterModel = {};
      }
    }
  }

  __isInputDataValid() {
    return (
      this.__isInputIdDataValid() &&
      this.providers.length > 0 &&
      this.appointmentTypes.length > 0
    );
  }

  __isInputIdDataValid() {
    return this.patientId && this.encounterId;
  }

  __updateEncounterModel(encounterId) {
    this.__encounterDataService.update(encounterId);
  }

  async updated(changedProps) {
    if (changedProps.has('encounterId') && this.encounterId) {
      this.__updateEncounterModel(this.encounterId);
    }

    if (changedProps.has('patientId') && this.patientId) {
      this.__notificationService.update({
        patient: { id: this.patientId },
      });

      await this.__updateEncounterCards(
        this.patientId,
        this.appointmentTypes,
        this.providers,
      );
    }
  }

  async __handleEncounterAfterDelete(deletedEncounterId) {
    const storeDate = store.getState().appointments.targetDate;
    const validEncounters = this.__encounterCards.filter(
      ({ data: { id } }) => id !== deletedEncounterId,
    );

    if (!validEncounters.length) {
      this.onDismiss('Encounter deleted');
    } else {
      this.__updateEncounterModel(validEncounters[0].data.id);
      await store.dispatch(forceFetchAppointments(storeDate));

      if (this.runningLedger) {
        this.onDismiss('Encounter deleted');
      }
    }

    this.dispatchEvent(
      new CustomEvent('neb-encounter-deleted', {
        detail: {
          deletedEncounterId,
          onDismiss: () => this.onDismiss('Encounter deleted'),
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`
      <neb-encounter-summary-view
        id="${ELEMENTS.view.id}"
        class="view"
        .encounters="${this.__encounterCards}"
        .encounterModel="${this.__encounterModel}"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .onAppointmentSelect="${this.__handlers.openAppointment}"
        .onDelete="${this.__handlers.deleteEncounter}"
        .onDismiss="${this.__handlers.dismiss}"
        .onEdit="${this.__handlers.editEncounter}"
        .onOpenVisitSummary="${this.__handlers.openVisitSummary}"
        .onPrint="${this.__handlers.printEncounter}"
        .onReopen="${this.__handlers.reopenEncounter}"
        .onSign="${this.__handlers.signEncounter}"
        .onUpdateSummary="${this.__handlers.updateSummary}"
        .onViewHistory="${this.__handlers.viewEncounterHistory}"
      ></neb-encounter-summary-view>
    `;
  }
}

window.customElements.define(
  'neb-encounter-summary-controller',
  NebEncounterSummaryController,
);
