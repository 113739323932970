import { isPropRequired } from '@neb/form-validators';
import { html } from 'lit';

import * as MENU_ITEMS from '../../../../../src/utils/menu-items';
import { date } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import Address from '../field-groups/neb-address';
import EmailList from '../field-groups/neb-email-list';
import Name from '../field-groups/neb-name';
import PhoneList from '../field-groups/neb-phone-list';

import '../inputs/neb-select';
import '../inputs/neb-textfield';

import Form, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  address: { id: 'address' },
  listEmail: { id: 'list-email' },
  listPhone: { id: 'list-phone' },
  name: { id: 'name' },
  selectEmploymentStatus: { id: 'select-employment-status' },
  selectSex: { id: 'select-sex' },
  textfieldDOB: { id: 'textfield-dob' },
};

const HELPERS = {
  name: {
    first: 'Required',
    last: 'Required',
  },
  sex: 'Required',
};

export default class FormPerson extends Form {
  static createModel() {
    return {
      address: Address.createModel(),
      dateOfBirth: '',
      emails: EmailList.createModel(),
      employmentStatus: '',
      name: Name.createModel(),
      phones: PhoneList.createModel(),
      sex: '',
    };
  }

  createSelectors() {
    return {
      children: {
        address: Address.createSelectors([]),
        dateOfBirth: {
          ...selectors.dateOfBirth(),
          format: v => v,
          unformat: v => v,
        },
        emails: EmailList.createSelectors(),
        employmentStatus: selectors.select(MENU_ITEMS.EMPLOYMENT_STATUS),
        name: Name.createSelectors(),
        phones: PhoneList.createSelectors(),
        sex: selectors.select(MENU_ITEMS.SEX, MENU_ITEMS.SEX[0], {
          validators: [isPropRequired('data.id')],
        }),
      },
    };
  }

  renderContent() {
    return html`
      <neb-name
        id="${ELEMENTS.name.id}"
        class="pad"
        name="name"
        .errors="${this.errors.name}"
        .helpers="${HELPERS.name}"
        .model="${this.state.name}"
        .onChange="${this.handlers.change}"
        showSuffix
      ></neb-name>

      <div class="grid grid-3">
        <neb-textfield
          id="${ELEMENTS.textfieldDOB.id}"
          name="dateOfBirth"
          helper=" "
          label="Date of Birth"
          maxLength="10"
          .mask="${date}"
          .error="${this.errors.dateOfBirth}"
          .value="${this.state.dateOfBirth}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.selectSex.id}"
          name="sex"
          label="Sex"
          .error="${this.errors.sex}"
          .helper="${HELPERS.sex}"
          .items="${MENU_ITEMS.SEX}"
          .value="${this.state.sex}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        <neb-select
          id="${ELEMENTS.selectEmploymentStatus.id}"
          name="employmentStatus"
          helper=" "
          label="Employment Status"
          .items="${MENU_ITEMS.EMPLOYMENT_STATUS}"
          .value="${this.state.employmentStatus}"
          .onChange="${this.handlers.change}"
        ></neb-select>
      </div>

      <neb-phone-list
        id="${ELEMENTS.listPhone.id}"
        class="pad"
        name="phones"
        .errors="${this.errors.phones}"
        .model="${this.state.phones}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-phone-list>

      <neb-email-list
        id="${ELEMENTS.listEmail.id}"
        class="pad"
        name="emails"
        maxItems="3"
        .errors="${this.errors.emails}"
        .model="${this.state.emails}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-email-list>

      <neb-address
        id="${ELEMENTS.address.id}"
        class="pad"
        name="address"
        .errors="${this.errors.address}"
        .model="${this.state.address}"
        .onChange="${this.handlers.change}"
      ></neb-address>
    `;
  }
}

window.customElements.define('neb-form-person', FormPerson);
