import { subscribe } from '../utils/update-notifications';

export class UpdateNotificationService {
  constructor({ callback, defaultQuery = {} }) {
    this.__onChange = callback;

    this.__query = defaultQuery;
  }

  connect() {
    this.__disconnect = subscribe(this.__query, this.__onChange);
  }

  disconnect() {
    this.__disconnect();
  }

  update(query) {
    if (!this.__disconnect) {
      throw new Error('Must be connected before updating query');
    }

    this.__disconnect();
    this.__disconnect = subscribe(query, this.__onChange);
  }
}
