import { html, css } from 'lit';

import {
  createInventory,
  updateInventory,
} from '../../../../../../src/api-clients/inventory';
import NebFormInventoryItem from '../../forms/neb-form-inventory-item';
import NebOverlayFormItem, {
  ELEMENTS as ELEMENTS_BASE,
} from '../neb-overlay-form-item';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  form: {
    id: 'form',
  },
};

class NebOverlayInventoryItem extends NebOverlayFormItem {
  static get properties() {
    return {
      __invModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__invModel = this.createModel();
  }

  static get config() {
    return {
      form: NebFormInventoryItem,
      itemName: 'Item',
      messageError: 'An error occurred when saving this item',
      messageSuccess: 'Item successfully saved',
    };
  }

  save(item) {
    return !item.id ? createInventory(item) : updateInventory(item);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }
      `,
    ];
  }

  __formatVendor({ vendor }) {
    return {
      vendorId: {
        label: vendor.name,
        data: {
          id: vendor.id,
          website: vendor.website || '',
        },
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.id) {
      this.__invModel = {
        ...this.model,
        ...this.__formatVendor(this.model),
      };
    }

    super.update(changedProps);
  }

  renderForm() {
    return html`
      <neb-form-inventory-item
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .model="${this.__invModel}"
      ></neb-form-inventory-item>
    `;
  }
}

customElements.define('neb-overlay-inventory-item', NebOverlayInventoryItem);
