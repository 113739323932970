import { getMany } from '../../../../src/api-clients/charges';
import { getBillingCodesCharges } from '../../../neb-api-client/src/billing-codes';

export const BLACKLISTED_CHARGE_CODES = ['Pkg', 'Sub'];

export const CODE_CHARGE_TYPE = Object.freeze({
  PURCHASE: 'Purchase',
  FEE: 'Fee',
});

export const getPurchaseCharges = async ({
  requireAvailableForPurchase = true,
  mapBillingCodeToCharge = false,
} = {}) => {
  const charges = (await getMany({ hideInactive: true }))
    .filter(c => !requireAvailableForPurchase || c.availableForPurchase)
    .map(c => ({ ...c, code: c.procedure }));

  const chargeCodes = (
    await getBillingCodesCharges({
      hideInactive: true,
    })
  )
    .filter(
      cc =>
        cc.active &&
        cc.type === CODE_CHARGE_TYPE.PURCHASE &&
        !BLACKLISTED_CHARGE_CODES.includes(cc.code),
    )
    .map(c => ({
      ...c,
      ...(mapBillingCodeToCharge
        ? {
            procedure: c.code,
            modifiers: ['', '', '', ''],
            availableForPurchase: true,
          }
        : {}),
    }));

  return [...charges, ...chargeCodes]
    .sort((a, b) => a.code.localeCompare(b.code))
    .map(c => ({
      ...c,
      ...(mapBillingCodeToCharge
        ? { amount: c.amount || 0 }
        : { amount: c.amount || null }),
    }));
};
