const __getOrInitializePageFromState = (state, page) =>
  state.pages[page]
    ? { ...state.pages[page] }
    : {
        fetching: false,
        fetchingError: null,
        items: [],
      };

export const _fetching = (state, action) => {
  if (!action.page) return state;

  const page = __getOrInitializePageFromState(state, action.page);

  page.fetching = true;
  page.fetchingError = null;
  return {
    ...state,
    currentPage: action.page,
    pages: { ...state.pages, [action.page]: page },
  };
};

export const _fetched = (state, action) => {
  if (!action.page) return state;
  const pageIds = action.res.data.map(item => item.id);

  const page = __getOrInitializePageFromState(state, action.page);

  page.fetching = false;
  page.fetchingError = null;
  page.items = pageIds;
  return {
    ...state,
    pages: { ...state.pages, [action.page]: page },
    totalCount: action.res.total,
  };
};

export const _fetchError = (state, action) => {
  if (!action.page) return state;

  const page = __getOrInitializePageFromState(state, action.page);

  page.fetching = false;
  page.fetchingError = action.fetchError;
  return {
    ...state,
    pages: { ...state.pages, [action.page]: page },
  };
};
