import { MDCLinearProgress } from '@material/linear-progress';
import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_FAMILY,
} from '../../../neb-styles/neb-variables';
import linearProgressStyle from '../../material_styles/mdc-linear-progress-css';

export const ELEMENTS = {
  linearProgress: {
    id: 'linear-progress',
  },
  label: {
    id: 'label',
  },
};

class NebMDLinearProgress extends LitElement {
  static get properties() {
    return {
      value: {
        type: Number,
      },
      label: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.value = 0;
    this.label = '';
  }

  static customStyle() {
    return css`
      .top-container {
        display: flex;
        flex-direction: column;
      }

      .label {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-family: ${CSS_FONT_FAMILY};
        color: ${CSS_COLOR_HIGHLIGHT};
        padding: 0 5px ${CSS_SPACING} 5px;
        align-self: center;
      }
    `;
  }

  static get styles() {
    return [linearProgressStyle, this.customStyle()];
  }

  firstUpdated() {
    this.progress = MDCLinearProgress.attachTo(
      this.shadowRoot.querySelector('.mdc-linear-progress'),
    );
  }

  updated(changed) {
    if (changed.has('value')) this.progress.foundation_.setProgress(this.value);
  }

  render() {
    return html`
      <div class="top-container">
        <div id="${ELEMENTS.label.id}" class="label">${this.label}</div>
        <div
          id="${ELEMENTS.linearProgress.id}"
          role="progressbar"
          class="mdc-linear-progress"
        >
          <div class="mdc-linear-progress__buffer"></div>
          <div
            class="mdc-linear-progress__bar mdc-linear-progress__primary-bar"
          >
            <span class="mdc-linear-progress__bar-inner"></span>
          </div>
          <div
            class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar"
          >
            <span class="mdc-linear-progress__bar-inner"></span>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-md-linear-progress', NebMDLinearProgress);
