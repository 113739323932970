import '../../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../neb-lit-components/src/components/tables/neb-table-ledger-payments';
import '../../../../../../src/components/filters/neb-filters-ledger-payments';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css, unsafeCSS } from 'lit';
import moment from 'moment-timezone';

import { autoAllocatePayments } from '../../../../../../src/api-clients/auto-allocation';
import { getLocations } from '../../../../../../src/api-clients/locations';
import { openPayfacReaderPopup } from '../../../../../../src/features/payfac/utils';
import { ADD_ONS, hasAddOn } from '../../../../../../src/utils/add-ons';
import { getAutoAllocationBanner } from '../../../../../../src/utils/auto-allocation';
import { openPayment } from '../../../../../../src/utils/payment-util';
import {
  ERROR_AUTO_ALLOCATE_PAYMENT,
  NO_ITEMS_INITIAL_LOAD,
  plural,
} from '../../../../../../src/utils/user-message';
import { getPatientPaymentPayers } from '../../../../../neb-api-client/src/payers';
import {
  getElectronicPayment,
  refundElectronicPayment,
} from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import {
  getPayments,
  refundPayment,
  voidPayment,
  printReceipts,
  bulkUpdatePaymentAssociations,
} from '../../../../../neb-api-client/src/payments-api-client';
import { getActiveProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { TABS } from '../../../../../neb-lit-components/src/components/forms/neb-form-allocation-charges';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../neb-lit-components/src/components/neb-page-collection';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../neb-lit-components/src/utils/overlay-constants';
import { PAYMENT_ACTION_KEYS } from '../../../../../neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_3,
} from '../../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  ELECTRONIC_PAYMENT_TYPES,
  REFUND_MESSAGES,
  GENIUS_SALE_TYPES,
} from '../../../../../neb-utils/electronic-payments-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  objToName,
} from '../../../../../neb-utils/formatters';
import {
  is500Error,
  isElectronicPayment,
  isRefunded,
} from '../../../../../neb-utils/neb-payment-util';
import { printPdf } from '../../../../../neb-utils/neb-pdf-print-util';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../neb-utils/services/fetch';

import { VOIDED_BANNER_MESSAGE } from './neb-patient-payment-controller';

export const MENU_HEIGHT = 416;

const NO_BULK_ACTIONS_AVAILABLE = {
  title: 'Bulk Actions',
  message: 'There are no bulk actions that can be taken on these payments.',
};

export const REFUNDED_BANNER_MESSAGE = {
  success: 'Payment refunded successfully',
  error: 'An error occurred when refunding the payment',
};

export const UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE = {
  success: 'Payments associated successfully',
  error: 'An error occurred when associating provider/location to payments',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  content: { id: 'content' },
  checkBox: {
    id: 'checkbox',
  },
  filters: {
    id: 'filters',
  },
  footer: { id: 'footer' },
  footerCells: { selector: '.footer-cell' },
};

class NebPaymentsListPage extends CollectionPage {
  static get properties() {
    return {
      __hasFifo: Boolean,
      __marginBottom: Number,
      __patientPaymentPayers: Array,

      mode: String,
      patientId: String,
      getDynamicLeadingHeaderActions: {
        type: Function,
      },
    };
  }

  static get config() {
    return {
      unifyForm: true,
      useFetch: true,
      showInactiveFilter: null,
      initialSortKey: 'transactionDate',
      initialSortOrder: 'desc',
      description: 'Review payments and perform updates.',
      tableConfig: [],
    };
  }

  initState() {
    super.initState();

    this.__tableState = {
      body: {},
      hideInactive: true,
      filteredCount: 0,
      pageIndex: 0,
      pageCount: 1,
      pageSize: 10,
      searchText: '',
      pageItems: [],
      itemsTotal: {},
      sortParams: {
        key: this.getConfig().initialSortKey,
        dir: this.getConfig().initialSortOrder,
      },
    };

    this.patientId = null;
    this.__patientPaymentPayers = [];
    this.__marginBottom = 0;
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;

    this.getDynamicLeadingHeaderActions = () => this.__getHeaderActions();

    this.onPaymentAction = () => {};

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      filter: (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        const patientId = model.patientId || undefined;
        const paymentId = model.paymentId || undefined;
        const payerIds = model.payerIds.length ? model.payerIds : undefined;
        const dosFrom = model.dateOfService.from
          ? model.dateOfService.from.format('YYYY-MM-DD')
          : undefined;
        const dosTo = model.dateOfService.to
          ? model.dateOfService.to.format('YYYY-MM-DD')
          : undefined;
        const statuses = model.statuses.length ? model.statuses : undefined;

        const paymentMethods = model.paymentMethods.length
          ? model.paymentMethods
          : undefined;

        const paymentTypes = model.paymentTypes.length
          ? model.paymentTypes
          : undefined;

        const paymentAmountFrom =
          model.paymentAmount.min || model.paymentAmount.min === 0
            ? model.paymentAmount.min
            : undefined;

        const paymentAmountTo =
          model.paymentAmount.max || model.paymentAmount.max === 0
            ? model.paymentAmount.max
            : undefined;

        const availableAmountFrom =
          model.availableAmount.min || model.availableAmount.min === 0
            ? model.availableAmount.min
            : undefined;

        const availableAmountTo =
          model.availableAmount.max || model.availableAmount.max === 0
            ? model.availableAmount.max
            : undefined;

        const providers = model.providers.length ? model.providers : undefined;

        const locations = model.locations.length ? model.locations : undefined;

        this.service.setQuery('providers', providers);
        this.service.setQuery('locations', locations);
        this.service.setQuery('patientId', patientId);
        this.service.setQuery('paymentId', paymentId);
        this.service.setQuery('payerIds', payerIds);
        this.service.setQuery('dosFrom', dosFrom);
        this.service.setQuery('dosTo', dosTo);
        this.service.setQuery('statuses', statuses);
        this.service.setQuery('paymentMethods', paymentMethods);
        this.service.setQuery('paymentTypes', paymentTypes);
        this.service.setQuery('paymentAmountFrom', paymentAmountFrom);
        this.service.setQuery('paymentAmountTo', paymentAmountTo);
        this.service.setQuery('availableAmountFrom', availableAmountFrom);
        this.service.setQuery('availableAmountTo', availableAmountTo);

        this.service.setPageIndex(0);
      },
      checkBoxToggle: e => {
        e.stopPropagation();
      },
      openBillingNotesOverlay: async ({
        amount,
        parentId,
        paymentNumber,
        payerName,
        transactionDate,
      }) => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.PAYMENT,
          parentId,
          parentData: {
            transactionDate: parseDate(transactionDate)
              .startOf('day')
              .format('MM/DD/YYYY'),
            paymentId: paymentNumber,
            amount,
            payer: payerName,
          },
          patientId: this.patientId,
        });

        if (result) this.fetch();
      },
      sort: (_, params) => this.service.setSortParams(params[0]),
      fetchData: query => {
        if (this.__isInitialLoadDisabled()) {
          return EMPTY_RESPONSE;
        }

        const updatedQuery = {
          ...query,
          excludeUnboundPayments: true,
        };

        return getPayments(this.patientId, updatedQuery);
      },
      selectItemCell: (_, key, index) => this.__selectCell(key, index),
      tableChange: e => this.__handleRowCheck(e),
      refundPayment: () => this.__refundPayment(),
      voidPayment: () => this.__voidPayment(),
      allocatePayment: () => this.__allocatePayment(),
      splitPayment: () => this.__splitPayment(),
      printPayment: async () => {
        const patientPayments =
          await this.__getCheckedRowsWithOpenEdgeReceipts();
        printPdf(printReceipts({ patientPayments }));
      },

      editPayer: ({ payerId }) => this.__openPayerOverlay(payerId),
      autoAllocateAll: async () => {
        const paymentIds = this.__getValidCheckedPaymentIds();

        try {
          const { data: allocations } = await autoAllocatePayments({
            paymentIds,
          });

          this.fetch();
          this.onPaymentAction();
          store.dispatch(getAutoAllocationBanner(allocations));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(ERROR_AUTO_ALLOCATE_PAYMENT));
        }
      },
      onSelectAllOnCurrentPage: () => {
        this.__setAllCheckboxes(true);
      },
      onDeselectAll: () => {
        this.__setAllCheckboxes(false);
      },
      onUpdateProviderLocation: () => this.__associatePaymentProviderLocation(),
      navigateToPatientLedger: ({ patientId, path = 'activity' }) => {
        navigate(`/patients/${patientId}/ledger/${path}`);
      },
    };
  }

  initService() {
    this.service = new FetchService(
      {
        onChange: state => {
          this.__tableState = state;
          this.changeOccurred(state);
        },
      },
      this.handlers.fetchData,
      {
        ...this.__tableState,
        initialQuery: {
          dosFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          dosTo: moment().format('YYYY-MM-DD'),
        },
      },
    );
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    super.connectedCallback();

    this.__hasFifo = await hasAddOn(ADD_ONS.CT_FIFO);

    if (this.patientId) {
      this.__getPatientPaymentPayers();

      this.__headerSideButtonConfig = {
        label: 'Add New Discount',
        leadingIcon: 'plus',
        onClick: () => this.__openDiscountsOverlay(this.__patientId),
      };
    }

    this.fetch();
  }

  __buildConfig() {
    return [
      {
        flex: css`0 0 30px`,
        key: 'checked',
        label: '',
      },
      ...(!this.patientId
        ? [
            {
              truncate: true,
              key: 'patientName',
              label: 'Patient',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        sortable: true,
        truncate: true,
        key: 'transactionDate',
        label: 'Date',
        flex: css`1 0 0`,
        formatter: d => d.format('MM/DD/YYYY'),
      },
      {
        truncate: true,
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
        link: true,
      },
      {
        sortable: true,
        truncate: true,
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        sortable: false,
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'codePayment',
        label: 'Type',
        flex: css`1 0 0`,
        formatter: c => c.code,
      },
      {
        truncate: true,
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'amount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: (a, payment) =>
          centsToCurrency(
            !this.patientId || payment.patientId
              ? a
              : payment.credits[0].amount,
          ),
      },
      {
        sortable: true,
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: (a, payment) =>
          !this.patientId ||
          (payment.patientId &&
            this.patientId &&
            this.patientId === payment.patientId)
            ? centsToCurrency(a)
            : '-',
      },
    ];
  }

  __totalsConfig() {
    return [
      {
        flex: css`0 0 30px`,
        key: 'checked',
        label: '',
      },
      ...(!this.patientId
        ? [
            {
              key: 'patientName',
              label: 'Patient',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        key: 'transactionDate',
        label: 'Date',
        flex: css`1 0 0`,
      },
      {
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        key: 'codePayment',
        label: 'Type',
        flex: css`1 0 0`,
      },
      {
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        key: 'amount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a),
      },
      {
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a),
      },
    ];
  }

  fetch() {
    this.service.fetch();
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  __calculateMarginBottom() {
    const { length } = this.__tableState.pageItems;

    if (length <= 2) {
      this.__marginBottom = MENU_HEIGHT;
    } else {
      this.__marginBottom = 0;
    }
  }

  async __getCheckedRowsWithOpenEdgeReceipts() {
    const rows = this.__getCheckedRows();
    const rowsWithReceipts = rows.map(async row => {
      if (isElectronicPayment(row)) {
        const ePayment = await getElectronicPayment(row.electronicPaymentId);

        if (ePayment && ePayment.receipt) {
          row.receipt = {
            text: JSON.parse(ePayment.receipt).text.customer_receipt,
          };
        }
      }

      return row;
    });

    const resolvedRows = await Promise.all(rowsWithReceipts);

    return resolvedRows;
  }

  __setAllCheckboxes(checked) {
    const pageItems = this.__tableState.pageItems.map(item => {
      item.checked = checked;
      return item;
    });

    this.__tableState = {
      ...this.__tableState,
      pageItems: [...pageItems],
    };
  }

  __getCheckedRows() {
    return this.__tableState.pageItems.filter(item => item.checked);
  }

  async __openPayerOverlay(payerId) {
    await openOverlay(OVERLAY_KEYS.PAYER_PLAN, { id: payerId });
  }

  async __openDiscountsOverlay(patientId) {
    const res = await openOverlay(OVERLAY_KEYS.DISCOUNT, {
      patientId,
      transactionDateFrom: parseDate().add(-1, 'month'),
      transactionDateTo: parseDate(),
    });

    if (res) {
      this.fetch();
      this.onPaymentAction();
    }
  }

  async __voidPayment() {
    const payment = this.__getCheckedRows()[0];

    const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.VOID,
      paymentId: payment.paymentNumber,
      payer: payment.payerName,
      amount: centsToCurrency(payment.amount),
    });

    if (paymentAction) {
      const voidPaymentBody = {
        paymentId: payment.id,
        codeRefundId: paymentAction.selectedReason.id,
      };

      try {
        await voidPayment(voidPaymentBody);
        this.fetch();
        this.onPaymentAction();
        store.dispatch(openSuccess(VOIDED_BANNER_MESSAGE.success));
      } catch (e) {
        store.dispatch(openError(VOIDED_BANNER_MESSAGE.error));
      }
    }
  }

  async __refundElectronicPayment(payment, opts = {}) {
    const ePayment = await getElectronicPayment(payment.electronicPaymentId);

    let refundPaymentAction;

    if (payment.payerPlan) {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        amount: centsToCurrency(payment.amount),
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
      });

      if (refundPaymentAction && refundPaymentAction.selectedReason) {
        const refundPaymentBody = {
          paymentId: payment.id,
          codeRefundId: refundPaymentAction.selectedReason.id,
          refundMethod: refundPaymentAction.refundMethod,
          amount: refundPaymentAction.amount,
        };

        try {
          const result = await refundPayment(refundPaymentBody);
          this.__paymentDetail = result;

          this.fetch();
          this.onPaymentAction();
          store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

          return undefined;
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));

          return undefined;
        }
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        amount: centsToCurrency(payment.amount),
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
        ePayment,
      });
    }

    if (refundPaymentAction) {
      if (refundPaymentAction.action === PAYMENT_ACTION_KEYS.REFUND) {
        if (refundPaymentAction && refundPaymentAction.selectedReason) {
          const refundPaymentBody = {
            paymentId: payment.id,
            codeRefundId: refundPaymentAction.selectedReason.id,
            refundMethod: refundPaymentAction.refundMethod,
            amount: refundPaymentAction.amount,
          };

          try {
            await refundPayment(refundPaymentBody);
            this.fetch();
            this.onPaymentAction();
            store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
          } catch (e) {
            console.error(e);
            store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
          }
        }

        return undefined;
      }

      let done = false;
      let paymentSuccess;
      let geniusRefund;

      while (!done) {
        done = true;

        if (ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT) {
          const payerPlanId = this.__paymentDetail
            ? this.__paymentDetail.payerPlanId || null
            : null;

          geniusRefund = await openPayfacReaderPopup({
            amount: refundPaymentAction.amount / 100,
            merchantAccounts: [
              {
                id: ePayment.merchantAccountId,
                cardReaders: [refundPaymentAction.selectedReader],
                active: true,
              },
            ],
            holderId: this.patientId,
            title: 'Merchant Account Details',
            subheader:
              'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
            message: 'Payment Amount',
            confirmLabel: 'Submit',
            holderType: 'patient',
            geniusSaleType: GENIUS_SALE_TYPES.RETURN,
            logContent: {
              ...ePayment,
              patientId: this.patientId || null,
              payerPlanId,
              action: 'refund - cardReader - neb-payments-list-page',
            },
            pendingModel: {
              action: 'refund',
              patientId: this.patientId || null,
              payerPlanId,
              amount: refundPaymentAction.amount,
              referenceId: ePayment.referenceId,
              electronicPaymentId: ePayment.id,
              electronicReferenceId: ePayment.referenceId,
              codeRefundId: refundPaymentAction.selectedReason,
            },
          });

          if (!geniusRefund) {
            this.__refundElectronicPayment(payment, refundPaymentAction);

            return {};
          }

          if (geniusRefund.status === 'DECLINED') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: geniusRefund.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              },
            );

            if (response.action === 'link') {
              this.__refundPayment(true);
            }
            return {};
          }
        }

        try {
          const result = await openPopup(
            POPUP_RENDER_KEYS.TRANSACTION_PROCESSING,
            {
              promise: refundElectronicPayment(
                ePayment.merchantAccountId,
                ePayment.id,
                {
                  ...geniusRefund,
                  amount: refundPaymentAction.amount,
                  logContent: {
                    action: 'cardOnFile - neb-payments-list-page',
                    rawModel: ePayment,
                  },
                },
              ),
            },
          );

          if (result.success) {
            paymentSuccess = true;

            const refundPaymentBody = {
              paymentId: payment.id,
              codeRefundId: refundPaymentAction.selectedReason.id,
              refundMethod: refundPaymentAction.refundMethod,
              cardRefundId: result.refund.returnId || result.refund.saleId,
              amount: refundPaymentAction.amount,
            };

            await refundPayment(refundPaymentBody);
            this.fetch();
            this.onPaymentAction();
          }

          if (result) {
            let response;

            if (result.success) {
              if (isRefunded(result.status)) {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.APPROVED,
                );
              } else {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.VOIDED,
                );
              }
            } else if (is500Error(result.code)) {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...REFUND_MESSAGES.FAILED_500,
                subheader: result.status,
              });

              if (response.action === 'confirm') {
                done = false;
              }
            } else {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: result.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              });

              if (response.action === 'link') {
                this.__refundPayment(true);
              }
            }
          }
        } catch (e) {
          store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
        }
      }

      if (paymentSuccess) {
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
      }
    }

    return refundPaymentAction;
  }

  async __refundPayment(skipElectronic = false) {
    let refundPaymentAction;

    const [payment] = this.__getCheckedRows();

    if (payment.cardSaleId && !skipElectronic) {
      refundPaymentAction = await this.__refundElectronicPayment(payment);

      if (
        refundPaymentAction &&
        refundPaymentAction.action !== PAYMENT_ACTION_KEYS.REFUND
      ) {
        return;
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: payment.electronicPaymentId
          ? `${payment.paymentMethod} - ${payment.maskedCardDescription}`
          : payment.paymentMethod,
        amount: centsToCurrency(payment.amount),
        patientId: payment.patientId,
        parentPaymentId: payment.parentPaymentId,
        id: payment.id,
      });
    }

    if (refundPaymentAction) {
      const refundPaymentBody = {
        paymentId: payment.id,
        codeRefundId: refundPaymentAction.selectedReason.id,
        refundMethod: refundPaymentAction.refundMethod,
        amount: refundPaymentAction.amount,
      };

      try {
        await refundPayment(refundPaymentBody);
        this.fetch();
        this.onPaymentAction();
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
      } catch (e) {
        store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      }
    }
  }

  async __allocatePayment() {
    await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
      patientId: this.patientId,
      payments: this.__getCheckedRows(),
      selectedTab: TABS.OUTSTANDING,
    });

    this.fetch();
    this.onPaymentAction();
  }

  async __splitPayment() {
    await openOverlay(OVERLAY_KEYS.SPLIT_PAYMENT, {
      payment: this.__getCheckedRows()[0],
    });

    this.fetch();
    this.onPaymentAction();
  }

  async __associatePaymentProviderLocation() {
    const patientPayments = this.__getCheckedRows().filter(
      payment => !payment.payerPlan,
    );

    const providers = (await getActiveProviderUsers()).map(provider => ({
      item: provider,
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
    }));

    const { locations } = store.getState().session.item;

    const allLocations = await getLocations({ hideInactive: true });

    const userLocations = allLocations.reduce((acc, location) => {
      if (locations.includes(location.id)) {
        acc.push({
          item: location,
          label: location.name,
        });
      }
      return acc;
    }, []);

    const result = await openPopup(
      POPUP_RENDER_KEYS.UPDATE_PAYMENT_PROVIDER_LOCATION,
      {
        providers,
        locations: userLocations,
        amountSelected: patientPayments.length,
      },
    );

    if (result) {
      const { provider, location } = result;
      const paymentIds = patientPayments.map(x => x.id);

      const body = {
        paymentIds,
        locationId: location ? location.id : null,
        providerId: provider ? provider.id : null,
      };

      try {
        await bulkUpdatePaymentAssociations({ body });
        this.fetch();
        store.dispatch(
          openSuccess(UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE.success),
        );
      } catch (e) {
        store.dispatch(
          openError(UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE.error),
        );
      }
    }
  }

  __getRefundPaymentActionDetail() {
    return {
      label: 'Refund Payment',
      onSelect: this.handlers.refundPayment,
    };
  }

  __getVoidPaymentActionDetail() {
    return {
      label: 'Void Payment',
      onSelect: this.handlers.voidPayment,
    };
  }

  __getAllocatePaymentActionDetail() {
    return {
      label: 'Allocate Payment',
      onSelect: this.handlers.allocatePayment,
    };
  }

  __getSplitPaymentActionDetail() {
    return {
      label: 'Split Payment',
      onSelect: this.handlers.splitPayment,
    };
  }

  __getPrintPaymentActionDetail() {
    return {
      label: 'Print Receipt',
      onSelect: this.handlers.printPayment,
    };
  }

  __getUpdateProviderLocationDetail() {
    return {
      label: 'Update Provider/Location',
      onSelect: this.handlers.onUpdateProviderLocation,
    };
  }

  __getSelectAllOnCurrentPageActionDetail() {
    return {
      label: `Select ${plural(
        this.service.__items.length,
        'Item',
      )} on This Page`,
      onSelect: this.handlers.onSelectAllOnCurrentPage,
    };
  }

  __getDeselectAllActionDetail() {
    return {
      label: `Deselect All - ${plural(
        this.__getCheckedRows().length,
        'Item',
      )} Selected`,
      onSelect: this.handlers.onDeselectAll,
    };
  }

  __getAutoAllocateValidActionDetail() {
    return {
      label: `Auto Allocate - ${plural(
        this.__getValidCheckedPaymentIds().length,
        'Valid Item',
      )} Selected`,
      onSelect: this.handlers.autoAllocateAll,
    };
  }

  __getSelectProviderAndLocationDetail() {
    return {
      label: `Auto Allocate - ${plural(
        this.__getValidCheckedPaymentIds().length,
        'Valid Item',
      )} Selected`,
      onSelect: this.handlers.autoAllocateAll,
    };
  }

  __getValidCheckedPaymentIds() {
    return this.__tableState.pageItems
      .filter(
        item =>
          item.checked && item.status === 'Unallocated' && !item.payerPlan,
      )
      .map(item => item.id);
  }

  __handleRowCheck(e) {
    const terms = e.name.split('.');
    const index = terms[terms.length - 1];

    const updatedItem = { ...this.__tableState.pageItems[index] };
    updatedItem.checked = e.value;
    this.__tableState.pageItems.splice(index, 1, updatedItem);
    this.__tableState = {
      ...this.__tableState,
      pageItems: [...this.__tableState.pageItems],
    };
  }

  __getHeaderActions() {
    const checkedRows = this.__getCheckedRows();

    if (!checkedRows.length) {
      if (this.__hasFifo) {
        return [
          this.__getSelectAllOnCurrentPageActionDetail(),
          this.__getDeselectAllActionDetail(),
        ];
      }
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Bulk Actions',
        message:
          'Please select one or more payments before performing an action.',
      });
    }

    const electronic = isElectronicPayment(checkedRows[0]);
    const hasERA = checkedRows[0].eRA;
    const hasSplit = checkedRows[0].parentPaymentId;
    const disableSplit = !hasSplit && checkedRows[0].status !== 'Unallocated';
    const unallocatable = ['Unallocated', 'Allocated'].includes(
      checkedRows[0].status,
    );
    const disableVoid = electronic || hasSplit || !unallocatable;
    const hasDiscount = checkedRows[0].codeDiscountId;

    const actions = [];

    if (this.__hasFifo) {
      actions.push(this.__getSelectAllOnCurrentPageActionDetail());
      actions.push(this.__getDeselectAllActionDetail());
    } else if (!checkedRows.length) {
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Bulk Actions',
        message:
          'Please select one or more payments before performing an action.',
      });
    }

    if (checkedRows.length === 1) {
      if (checkedRows[0].available === checkedRows[0].amount) {
        if (!hasDiscount && !hasERA) {
          actions.push(this.__getRefundPaymentActionDetail());
        }
      }

      if (!hasDiscount && !disableVoid) {
        actions.push(this.__getVoidPaymentActionDetail());
      }
    }

    if (this.__getValidCheckedPaymentIds().length > 0 && this.__hasFifo) {
      actions.push(this.__getAutoAllocateValidActionDetail());
    }

    if (checkedRows.every(p => p.available > 0)) {
      actions.push(this.__getAllocatePaymentActionDetail());
    }

    if (checkedRows.every(p => !p.codeDiscountId)) {
      if (!disableSplit && checkedRows.length === 1) {
        actions.push(this.__getSplitPaymentActionDetail());
      }

      if (checkedRows.every(p => !p.payerPlanId && !hasSplit)) {
        actions.push(this.__getPrintPaymentActionDetail());
      }
    }

    const hasPatientPayments = !checkedRows.every(p => p.payerPlanId);

    if (checkedRows.length && hasPatientPayments) {
      actions.push(this.__getUpdateProviderLocationDetail());
    }

    if (!actions.length) {
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: NO_BULK_ACTIONS_AVAILABLE.title,
        message: NO_BULK_ACTIONS_AVAILABLE.message,
      });
    }

    return actions;
  }

  __renderCheckBox(item, index, changeHandler) {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkBox.id}"
        .name="${index}"
        .onChange="${changeHandler}"
        ?checked="${item.checked}"
        @click="${this.handlers.checkBoxToggle}"
      ></neb-checkbox>
    `;
  }

  async __selectPayment(item) {
    if (item.codeDiscountId) {
      await openOverlay(OVERLAY_KEYS.DISCOUNT, {
        id: item.id,
        patientId: item.patientId,
      });
    } else {
      await openPayment({ payment: item, readonly: false });
    }

    this.fetch();
    this.onPaymentAction();
  }

  __selectCell(key, index) {
    if (key !== 'checked') {
      this.__selectPayment(this.__tableState.pageItems[index]);
    }
  }

  async __getPatientPaymentPayers() {
    const patientPaymentPayers = await getPatientPaymentPayers(this.patientId);
    const uniquePaymentPayers = new Map();

    patientPaymentPayers.forEach(payer => {
      uniquePaymentPayers.set(payer.alias, { label: payer.alias, data: payer });
    });

    this.__patientPaymentPayers = Array.from(uniquePaymentPayers.values());
  }

  __getEmptyMessage() {
    if (this.__isInitialLoadDisabled()) {
      return NO_ITEMS_INITIAL_LOAD;
    }

    return this.patientId
      ? 'There are no payments for this patient.'
      : 'There are no payments.';
  }

  firstUpdated() {
    if (this.getConfig().showInactiveFilter !== undefined) {
      this.service.hideInactive(this.getConfig().showInactiveFilter);
    }
  }

  updated(changed) {
    if (changed.has('__tableState')) {
      this.__calculateMarginBottom();

      if (this.patientId) {
        this.__getPatientPaymentPayers();
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-spacer {
          --custom-icon-spacer-max-height: ${CSS_SPACING};
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
          display: flex;
        }

        .row {
          flex-direction: column;
        }

        .container {
          overflow: visible;
        }

        .footer {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .footer-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .footer-cell:last-child {
          margin-right: 0;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .footer-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .footer-trailing-spacer {
          width: 12px;
        }
      `,
    ];
  }

  renderPagination() {
    return this.__tableState.pageCount > 1
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                .debouncerDelay="${300}"
                .pageCount="${this.__tableState.pageCount}"
                .currentPage="${this.__tableState.pageIndex}"
                .onPageChanged="${this.handlers.selectPage}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderTableFooter() {
    if (
      Object.keys(this.__tableState.body).length &&
      Object.keys(this.__tableState.body.totals).length
    ) {
      const cells = this.__totalsConfig()
        .slice(1)
        .map(cell => {
          const value = cell.formatter
            ? cell.formatter(this.__tableState.body.totals[cell.key])
            : '';

          return html`
            <span class="footer-cell text-bold" style="flex: ${cell.flex}"
              >${value}</span
            >
          `;
        });

      return html`
        <div id="${ELEMENTS.footer.id}" class="footer">
          <div class="footer-cell-spacer text-bold">Totals</div>
          ${cells}
          <div class="footer-trailing-spacer"></div>
        </div>
      `;
    }

    return '';
  }

  renderTable() {
    const emptyMessage = this.__getEmptyMessage();

    return html`
      <neb-filters-ledger-payments
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.handlers.filter}"
        ?enablePatient="${!this.patientId}"
        .patientPayers="${this.__patientPaymentPayers}"
      ></neb-filters-ledger-payments>

      <div
        id="${ELEMENTS.content.id}"
        style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
      >
        <neb-table-ledger-payments
          id="${ELEMENTS.table.id}"
          class="cell-spacer"
          .layout="${this.layout}"
          .config="${this.__buildConfig()}"
          .subHeaderConfig="${this.__totalsConfig()}"
          .model="${this.__tableState.pageItems}"
          .body="${this.__tableState.body}"
          .patientId="${this.patientId}"
          .sortParams="${[this.__tableState.sortParams]}"
          .emptyMessage="${emptyMessage}"
          .onChange="${this.handlers.tableChange}"
          .onSort="${this.handlers.sort}"
          .onClickNoteIcon="${this.handlers.openBillingNotesOverlay}"
          .onSelectCell="${this.handlers.selectItemCell}"
          .onSelectPatient="${this.handlers.navigateToPatientLedger}"
          .onSelectPayer="${this.handlers.editPayer}"
          .onBulkActionClick="${this.getDynamicLeadingHeaderActions}"
        ></neb-table-ledger-payments>
        ${this.renderTableFooter()}
      </div>
    `;
  }
}

customElements.define('neb-payments-list-page', NebPaymentsListPage);
