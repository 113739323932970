import '../../../../../packages/neb-lit-components/src/components/controls/neb-pill-switch';
import '../../packages-subscriptions/neb-page-active-care-packages-subscriptions';
import '../../../collection-pages/neb-page-completed-care-packages-subscriptions';

import { LitElement, html, css } from 'lit';

import { baseStyles, layoutStyles, CSS_SPACING } from '../../../../styles';

export const ELEMENTS = {
  pillSwitch: { id: 'pill-switch' },
  activePackagesPage: { id: 'active-packages-page' },
  completedPackagesPage: { id: 'completed-packages-page' },
};

const PACKAGE_DISPLAY_TYPE = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

const PACKAGE_SWITCH_LABELS = {
  leftSwitch: 'Active',
  rightSwitch: 'Completed',
};
class NebPageCarePackagesSubscriptions extends LitElement {
  static get properties() {
    return {
      patientId: String,
      packageDisplayType: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.patientId = '';
    this.packageDisplayType = PACKAGE_DISPLAY_TYPE.ACTIVE;

    this.onPackagesSubscriptionsChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      toggle: e => {
        this.packageDisplayType = e
          ? PACKAGE_DISPLAY_TYPE.ACTIVE
          : PACKAGE_DISPLAY_TYPE.COMPLETED;
      },
      updatePackagesSubscriptions: ({
        hideInactive,
        count,
        navigateAway = false,
      }) =>
        this.onPackagesSubscriptionsChange(hideInactive, count, navigateAway),
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: flex;
          height: 100%;
        }

        .container {
          width: 100%;
        }

        neb-pill-switch {
          display: flex;
          height: 38px;
          width: 200px;
          margin: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderPillSwitch() {
    return html`
      <neb-pill-switch
        id="${ELEMENTS.pillSwitch.id}"
        .on="${this.packageDisplayType === PACKAGE_DISPLAY_TYPE.ACTIVE}"
        .labels="${PACKAGE_SWITCH_LABELS}"
        .onToggle="${this.__handlers.toggle}"
      ></neb-pill-switch>
    `;
  }

  __renderPackagePage() {
    return this.packageDisplayType === PACKAGE_DISPLAY_TYPE.ACTIVE
      ? html`
          <neb-page-active-care-packages-subscriptions
            id="${ELEMENTS.activePackagesPage.id}"
            class="active-header"
            .patientId="${this.patientId}"
            .onPackagesSubscriptionsChange="${this.__handlers
              .updatePackagesSubscriptions}"
          ></neb-page-active-care-packages-subscriptions>
        `
      : html`
          <neb-page-completed-care-packages-subscriptions
            id="${ELEMENTS.completedPackagesPage.id}"
            .patientId="${this.patientId}"
          ></neb-page-completed-care-packages-subscriptions>
        `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderPillSwitch()} ${this.__renderPackagePage()}
      </div>
    `;
  }
}
customElements.define(
  'neb-page-care-packages-subscriptions',
  NebPageCarePackagesSubscriptions,
);
