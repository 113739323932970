import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/neb-radio-button';

import { html, css } from 'lit';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  buttonYes: { id: 'button-yes' },
  buttonNo: { id: 'button-no' },
};

class NebPopupClaimYesNo extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          margin-top: 10px;
        }

        .container-buttons {
          display: flex;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        <neb-radio-button
          id="${ELEMENTS.buttonYes.id}"
          label="Yes"
          name="${this.model.items[0].name}"
          .value="${true}"
          .checked="${this.__state[this.model.items[0].name]}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.buttonNo.id}"
          label="No"
          name="${this.model.items[0].name}"
          .value="${false}"
          .checked="${!this.__state[this.model.items[0].name]}"
          .onChange="${this.handlers.change}"
        ></neb-radio-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-claim-yes-no', NebPopupClaimYesNo);
