import { CLAIM_WARNINGS } from '../utils';

import { itemHasSpecificWarning } from './utils';

export default claimWarnings => {
  const warnings = [
    CLAIM_WARNINGS.CLAIM_IS_A_REVERSAL,
    CLAIM_WARNINGS.UNMATCHED_PATIENT_CONTROL_NUMBER,
  ];

  return warnings.some(warning =>
    itemHasSpecificWarning(claimWarnings, warning),
  );
};
