import '../../neb-www-practice-charting/src/components/copy-encounter/neb-copy-prior-encounter';

import { html, css } from 'lit';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  viewCopyPriorEncounter: {
    id: 'view-copy-prior-encounter',
  },
};

class NebPopupCopyPriorEncounter extends NebPopup {
  initState() {
    super.initState();
    this.title = 'Copy Prior Encounter';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      copy: () => {
        this.onClose(true);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 90%;
          height: 90%;
        }

        .container {
          display: block;
        }

        .content {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .view {
          display: flex;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-copy-prior-encounter
        id="${ELEMENTS.viewCopyPriorEncounter.id}"
        class="view"
        .layout="${this.layout}"
        .encounter="${this.model.encounter}"
        .onCancel="${this.handlers.cancel}"
        .onCopy="${this.handlers.copy}"
      ></neb-copy-prior-encounter>
    `;
  }
}

customElements.define(
  'neb-popup-copy-prior-encounter',
  NebPopupCopyPriorEncounter,
);
