import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import ChargeForm from '../../../../packages/neb-lit-components/src/components/forms/neb-form-charge';
import NebOverlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import * as chargesApi from '../../../api-clients/charges';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class NebOverlayCharge extends NebOverlay {
  static get properties() {
    return {
      __saveError: Object,
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          width: 880px;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = ChargeForm.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          this.__formModel = await chargesApi.save(model);
          this.__saveError = null;
          this.isDirty = false;
          this.dismiss(this.__formModel);
          store.dispatch(openSuccess('Charge saved successfully'));
        } catch (err) {
          const duplicated = err.statusCode === 409;

          this.__saveError = duplicated ? err : null;

          if (!duplicated) {
            store.dispatch(openError('Charge was not saved successfully'));
            console.error(err);
          }
        }
      },
    };
  }

  getTitle() {
    return this.model.item ? 'Edit Charge' : 'Add Charge';
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = this.model.item
        ? this.model.item
        : ChargeForm.createModel();
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-charge
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .savingError="${this.__saveError}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-charge>
    `;
  }
}

customElements.define('neb-overlay-charge', NebOverlayCharge);
