import dumbPasswords from 'dumb-passwords';
import PasswordValidator from 'password-validator';

const REGEX_FORBIDDEN_CHARACTERS =
  /[^a-zA-Z0-9+^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/gm;
const REQUIRED = 'Required';
export const TEXT_ERROR_NO_MATCH_PASSWORDS =
  'The new password and confirmation password do not match.';
export const TEXT_ERROR_SHORT_PASSWORD =
  'Password must be at least 8 characters long';
export const TEXT_ERROR_BAD_PASSWORD =
  'This password is not allowed. Please try a different password.';
export const VALID = {
  valid: true,
  errors: {
    password: '',
    verifyPassword: '',
  },
};
export const INVALID = {
  valid: false,
  errors: {
    password: ' ',
    verifyPassword: TEXT_ERROR_BAD_PASSWORD,
  },
};
const INVALID_CONFIG = {
  min: {
    valid: false,
    errors: {
      password: ' ',
      verifyPassword: TEXT_ERROR_SHORT_PASSWORD,
    },
  },
  oneOf: {
    valid: false,
    errors: {
      password: ' ',
      verifyPassword: TEXT_ERROR_NO_MATCH_PASSWORDS,
    },
  },
  max: INVALID,
  not: INVALID,
};

export const validatePassword = (password, verifyPassword, currentPassword) => {
  if (!password || !verifyPassword) {
    return {
      valid: false,
      errors: {
        password: password ? ' ' : REQUIRED,
        verifyPassword: verifyPassword ? ' ' : REQUIRED,
      },
    };
  }
  if (currentPassword && currentPassword === password) return INVALID;
  if (dumbPasswords.check(password)) return INVALID;
  const schema = new PasswordValidator();
  schema
    .is()
    .min(8)
    .is()
    .max(64)
    .is()
    .oneOf([verifyPassword])
    .not(REGEX_FORBIDDEN_CHARACTERS);

  const valid = schema.validate(password, {
    list: true,
  });
  return valid.length ? INVALID_CONFIG[valid[0]] : VALID;
};
