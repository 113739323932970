import { getBillingCodesCharges } from '../../../neb-api-client/src/billing-codes';

export const PKG_SUB_CODES = ['Pkg', 'Sub'];

export const getPackageSubscriptionChargeCodes = async () => {
  const purchaseCodes = (await getBillingCodesCharges()).filter(
    cc =>
      cc.active && cc.type === 'Purchase' && PKG_SUB_CODES.includes(cc.code),
  );

  return purchaseCodes;
};
