import {
  PAYMENT_METHODS,
  SCHEDULED_PAYMENT_TYPE,
  SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP,
} from './enums';
import { centsToCurrency } from './formatters';

export const is500Error = code => code.substring(0, 1) === '5';

export const isDebitOrCreditCard = ({ paymentMethod }) =>
  paymentMethod === PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD;

export const paymentHasAuthEFT = ({ paymentMethod }) =>
  paymentMethod === PAYMENT_METHODS.CHECK ||
  paymentMethod === PAYMENT_METHODS.DIRECT_DEPOSIT ||
  paymentMethod === PAYMENT_METHODS.ERA;

export const isPaymentRefunded = payment => !!payment.refundedAt;

export const isPaymentRefundedOrVoided = payment =>
  !!payment.refundedAt || !!payment.voidedAt;

export const isElectronicPayment = payment => !!payment.cardSaleId;

export const isPaymentPackageOrSubscription = paymentTypeCode => {
  if (!paymentTypeCode) return false;

  return paymentTypeCode === 'Pkg' || paymentTypeCode === 'Sub';
};

export const isPayerPayment = payment => payment && !!payment.payerPlanId;

export const isRefunded = code => code === 'Approved';

export const isPartiallyOrFullyAllocated = payment =>
  Boolean(payment.allocated);

export const formatRefundedSplitPayment = (payment, splits, receipt) => {
  const refundedSplits = splits.filter(split => split.refundedAt);

  if (refundedSplits.length > 0) {
    const refundedAmount = refundedSplits
      .map(split => split.amount)
      .reduce((acc, splitAmount) => acc + splitAmount, 0);

    const refundedAt =
      refundedSplits.length !== 1 ? 'Various' : refundedSplits[0].refundedAt;

    const refundMethod =
      refundedSplits.length !== 1
        ? 'Various'
        : refundedSplits[0].refundPayment.refundMethod;

    return {
      ...payment,
      receipt,
      refundedAt,
      refundPayment: {
        amount: centsToCurrency(refundedAmount),
        refundMethod,
      },
    };
  }

  return { ...payment, receipt };
};

export const formatPaymentMethodInfo = model => {
  const { paymentMethod, maskedCardDescription } = model;

  if (isDebitOrCreditCard(model) && maskedCardDescription) {
    return `${paymentMethod} - ${maskedCardDescription}`;
  }

  return paymentMethod;
};

export function mergeCardReaders(merchantAccounts, cardReaders) {
  return merchantAccounts.reduce((memo, merchant) => {
    const merchantWithCard = {
      ...merchant,
      cardReaders: merchant.cardReaders
        .map(readerId =>
          cardReaders.find(cr => cr.id === readerId && cr.active),
        )
        .filter(a => a),
    };

    if (merchantWithCard.cardReaders.length > 0) memo.push(merchantWithCard);

    return memo;
  }, []);
}

export const formatScheduleType = type => {
  switch (type) {
    case SCHEDULED_PAYMENT_TYPE.SINGLE:
      type = SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP.singlePayment;
      break;
    case SCHEDULED_PAYMENT_TYPE.RECURRING:
      type = SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP.recurringPayment;
      break;
    case SCHEDULED_PAYMENT_TYPE.PLAN:
      type = SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP.paymentPlan;
      break;
    default:
  }

  return type;
};
