import { css, html } from 'lit';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-ledger-activity-encounters';
import '../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-card-list';

import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import { getAllEncounters } from '../../../../packages/neb-api-client/src/encounters-api-client';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/neb-page-collection';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  SERVICE_DATE_FORMAT,
  SERVICE_TIME_FORMAT,
} from '../../../../packages/neb-utils/neb-charting-util';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  encountersTable: { id: 'encounters' },
};

class NebPageCaseEncounters extends CollectionPage {
  static get config() {
    return {
      ...CollectionPage.config,
      hideDetailArrow: false,
      showInactiveFilter: null,
      optOutCollectionPagination: false,
      hideHeader: true,
    };
  }

  static get properties() {
    return {
      __locations: Array,
      layout: {
        type: String,
        reflect: true,
      },
      caseId: {
        type: String,
      },
      patientId: {
        type: String,
      },
      providers: {
        type: Array,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row {
          display: unset;
        }

        .row-form {
          padding-top: 0;
        }

        .list-encounters {
          padding-bottom: ${CSS_SPACING};
        }

        neb-pagination {
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.layout = '';
    this.caseId = '';
    this.patientId = '';
    this.providers = [];
    this.__locations = [];
  }

  initService() {
    super.initService();

    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();

    super.disconnectedCallback();
  }

  async fetch() {
    const { data: encounters } = await getAllEncounters({
      patientId: this.patientId,
      queryParams: {
        caseId: this.caseId,
      },
    });

    const { data: appointmentTypes } = await getAppointmentTypes();

    return encounters.map(encounter => {
      const { color = '', name: appointmentType = '' } = appointmentTypes.find(
        apptType => apptType.id === encounter.appointmentTypeId,
      );

      const { label: provider = '' } = this.providers.find(
        p => p.id === encounter.providerId,
      );

      const serviceDateTime = parseDate(encounter.serviceDate);

      const location = this.__locations.find(
        l => l.id === encounter.locationId,
      );

      const locationName = location ? location.name : '';

      return {
        ...encounter,
        active: encounter.signed,
        appointmentType,
        markerColor: color,
        provider,
        formattedServiceDate: serviceDateTime.format(SERVICE_DATE_FORMAT),
        formattedServiceTime: serviceDateTime.format(SERVICE_TIME_FORMAT),
        locationName,
      };
    });
  }

  __buildConfig() {
    return [
      { key: 'color', label: '' },
      { key: 'serviceDate', label: 'Date', flex: css`2 0 0` },
      {
        key: 'locationId',
        label: 'Location',
        flex: css`3 0 0`,
      },
      { key: 'appointmentType', label: 'Type', flex: css`3 0 0` },
      { key: 'number', label: 'Encounter', flex: css`3 0 0` },
    ];
  }

  renderTable() {
    return html`
      <neb-table-ledger-activity-encounters
        id="${ELEMENTS.encountersTable.id}"
        name="table-encounters"
        class="table-encounters"
        .model="${this.__tableState.pageItems}"
        .config="${this.__buildConfig()}"
        .patientId="${this.patientId}"
        .fromCase="${true}"
      ></neb-table-ledger-activity-encounters>
    `;
  }
}

window.customElements.define('neb-page-case-encounters', NebPageCaseEncounters);
