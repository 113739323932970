import '../../neb-header';
import '../../neb-tooltip';
import '../../neb-radio-button';
import '../../inputs/neb-select';
import '../../inputs/neb-select-search';
import '../../inputs/neb-textfield';
import '../../inputs/neb-textarea';
import '../../../../../../src/components/controls/inputs/neb-checkbox';
import '../../controls/neb-switch';
import '../../controls/neb-tab-group';
import '../../controls/neb-button-action';
import './neb-view-real-time-eligibility';
import '../../../../../../src/components/misc/neb-view-associated-rules';

import {
  isRequired,
  isPhoneNumber,
  matchesPattern,
} from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { map } from 'lit/directives/map.js';
import { when } from 'lit/directives/when.js';

import * as feeSchedulesApi from '../../../../../../src/api-clients/fee-schedules';
import { ETIN_CLEARINGHOUSES } from '../../../../../../src/components/collection-pages/neb-collection-page-clearinghouse-settings';
import NebFeeScheduleProviderList, {
  formatFeeSchedulePayerPlans,
} from '../../../../../../src/components/controls/field-groups/neb-fee-schedule-provider-list';
import { TIERS } from '../../../../../../src/components/forms/utils/form-utils';
import { CSS_SMALL_SPACING } from '../../../../../../src/styles';
import { ADD_ONS, hasAddOn } from '../../../../../../src/utils/add-ons';
import { CLEARINGHOUSES } from '../../../../../../src/utils/clearinghouse-settings';
import {
  ERA_PAYER_NAME,
  PAYER_GROUP,
  getOverlayTitle,
} from '../../../../../../src/utils/user-message';
import { getAdditionalClaimCodes } from '../../../../../neb-api-client/src/additional-claim-code-api-client';
import { getBillingInfo } from '../../../../../neb-api-client/src/billing-information-api-client';
import { getPayerPlan } from '../../../../../neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_GREY_5,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../neb-styles/neb-variables';
import {
  AUTO_INSURANCE_INDICATORS,
  BLUE_CROSS_BLUE_SHIELD_INDICATORS,
  CLAIM_FILING_INDICATOR,
  COMMERCIAL_INSURANCE_INDICATORS,
  MEDICAID_INDICATORS,
  MEDICARE_INDICATORS,
  OTHER_GOVERNMENT_INDICATORS,
  OTHER_LIABILITY_INDICATORS,
  VETERANS_AFFAIRS_INDICATORS,
  WORKERS_COMPENSATION_INDICATORS,
  SUBMISSION_METHODS,
  SUBMISSION_METHOD,
} from '../../../../../neb-utils/claims';
import {
  FEDERAL_TAX_ID_SOURCE,
  FEDERAL_TAX_ID_TYPE,
  PROVIDER_TYPE,
} from '../../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import { mapToMenuItems } from '../../../../../neb-utils/fee-schedule';
import {
  objToName,
  buildAddress,
  formatDisplayProviders,
} from '../../../../../neb-utils/formatters';
import { phone, number, taxId, ssn } from '../../../../../neb-utils/masks';
import { FINANCIAL_CLASSES } from '../../../../../neb-utils/patientInsurance';
import {
  EMPTY_ADDITIONAL_CLAIM_INFO,
  BLANK_IDENTIFIER,
  BLANK_OTHER_TAX_ID,
  EMPTY_ERA_PAYER_NAMES,
} from '../../../../../neb-utils/payer-plan-util';
import * as selectors from '../../../../../neb-utils/selectors';
import {
  getTabWithError,
  getValueByPath,
  padArray,
} from '../../../../../neb-utils/utils';
import {
  isZipCode,
  range,
  maxLength,
} from '../../../../../neb-utils/validators';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import Address from '../../field-groups/neb-address';
import { BUTTON_ROLE } from '../../neb-button';
import NebForm from '../neb-form';

import NebProviderSpecificValuesFieldsList from './neb-provider-specific-values-fields-list';

const MEDICARE = 'Medicare';

const STATUS_TYPE = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const EMPTY_ITEM = {
  label: '',
  data: { id: '', active: true },
};

const FINANCIAL_CLASS_ITEMS = [
  'Commercial Insurance',
  'BCBS',
  MEDICARE,
  'Medicaid',
  'Veterans Affairs',
  'Other Government',
  'Workers Compensation',
  'Auto-Insurance',
  'Other Liability',
];

const DEFAULT_CLAIM_FILE_INDICATORS = {
  'Commercial Insurance':
    COMMERCIAL_INSURANCE_INDICATORS.COMMERCIAL_INSURANCE_CO,
  BCBS: BLUE_CROSS_BLUE_SHIELD_INDICATORS.BLUE_CROSS_BLUE_SHIELD,
  Medicare: MEDICARE_INDICATORS.HEALTH_MAINTENANCE_ORGANIZATION,
  Medicaid: MEDICAID_INDICATORS.MEDICAID,
  'Veterans Affairs': VETERANS_AFFAIRS_INDICATORS.VETERANS_AFFAIRS_PLAN,
  'Other Government': OTHER_GOVERNMENT_INDICATORS.OTHER_FEDERAL_PROGRAM,
  'Workers Compensation':
    WORKERS_COMPENSATION_INDICATORS.WORKERS_COMPENSATION_HEALTH_CLAIM,
  'Auto-Insurance': AUTO_INSURANCE_INDICATORS.AUTOMOBILE_MEDICAL,
  'Other Liability': OTHER_LIABILITY_INDICATORS.LIABILITY_MEDICAL,
};

const ITEMS_FILING = [
  {
    key: 'claimFilingIndicator',
    label: 'Claim Filing Indicator (FL1)',
    initialValue: 'CI - Commercial Insurance Co.',
    tip: 'The selected value will populate in FL1 and as the claim filing indicator code for electronic claims.',
    items: [
      '12 - Preferred Provider Organization (PPO)',
      '13 - Point of Service (POS)',
      '14 - Exclusive Provider Organization (EPO)',
      '17 - Dental Maintenance Organization',
      'CI - Commercial Insurance Co.',
      'HM - Health Maintenance Organization',
    ],
  },
  {
    key: 'providerSignature',
    label: 'Provider Signature (FL31)',
    initialValue: 'Provider Signature or SOF',
    tip: 'The selected value will populate in FL31 on paper forms.',
    items: ['Provider Signature or SOF'],
  },
];

const ITEMS_OPTIONS = [
  {
    initialValue: false,
    key: 'chartNotesRequired',
    label: 'Chart Notes Required',
  },
  {
    initialValue: false,
    key: 'spinalManipInfoRequired',
    label: 'Spinal Manipulation Information Required',
  },
  {
    initialValue: false,
    key: 'priorAuthorizationRequired',
    label: 'Prior Authorization Required',
  },
  {
    initialValue: true,
    key: 'patientSignature',
    label: 'Signature on File (FL12)',
  },
  {
    initialValue: true,
    key: 'insuredSignature',
    label: 'Signature on File (FL13)',
  },
  {
    initialValue: true,
    key: 'acceptAssignment',
    label: 'Accept Assignment (FL27)',
  },
  {
    initialValue: false,
    key: 'printCmsBackground',
    label: 'Print CMS-1500 Background',
  },
];

const CONFIRM_OVERWRITE_PAYER = {
  title: 'Copy Details from Existing Payer',
  message: alias => `Are you sure you want to copy payer details of
    ${alias} and overwrite existing payer details for this payer?`,
  confirmText: 'YES',
  cancelText: 'NO',
};

export const CONFIRM_REMOVE_PROVIDER_SPECIFIC_VALUES = {
  title: 'Remove Provider Values',
  message: 'Are you sure you want to remove these provider values?',
  confirmText: 'Yes',
  cancelText: 'No',
};

const CUSTOM_CODE_ITEM = {
  item: {
    additionalClaimCodeId: null,
    codeGroup: '',
    code: '',
    description: '',
    id: '',
    payerPlanId: '',
    sortOrder: 0,
  },
  label: 'Custom Code',
};

export const ELEMENTS = {
  buttonCopyPayer: { id: 'button-copy-payer' },
  generalHeader: {
    id: 'general',
    title: 'General',
  },
  claimHeader: {
    id: 'claim',
    title: 'Defaults',
    description: 'Set claim submission defaults for this payer.',
  },
  submissionMethodsHeader: {
    id: 'submission-methods-header',
    title: 'Submission Methods',
  },
  tabGroup: {
    id: 'tab-group',
  },
  agreementDetailsHeader: {
    id: 'agreement-details',
    title: 'Agreement Details',
  },
  providerDetailsHeader: {
    id: 'provider-details',
    title: 'Providers In and Out of Network',
  },
  optPayerValuesHeader: {
    id: 'opt-payer-values',
    title: 'Optional Payer Specific Form Values',
    description:
      'Below are payer specific form fields, that if required by the payer, may be entered or identified here for use in claim submission. Confer with your payer to determine desired values.',
  },
  alias: { id: 'alias' },
  payerName: { id: 'payer-name' },
  attentionLine: { id: 'attention-line' },
  financialClass: { id: 'financial-class' },
  addressDisplay: { id: 'address-display' },
  addressForm: { id: 'address' },
  contactName: { id: 'contact-name' },
  phone: { id: 'phone' },
  extension: { id: 'extension' },
  fax: { id: 'fax' },
  website: { id: 'website' },
  notes: { id: 'notes' },
  status: { id: 'status' },
  claimFilingIndicator: {
    id: 'claim-filing-indicator',
  },
  billingProviderAddress: {
    id: 'billing-provider-address',
  },
  providerSignature: {
    id: 'provider-signature',
  },
  submissionMethodPrimary: {
    id: 'submission-method-primary',
  },
  submissionMethodSecondary: {
    id: 'submission-method-secondary',
  },
  selectClearinghouses: {
    id: 'select-clearinghouse-id',
  },
  clearinghousePayerId: {
    id: 'clearinghouse-payer-id',
  },
  timelyFiling: { id: 'timely-filing' },
  payerEtin: { id: 'payer-etin' },
  payerId: { id: 'payer-id' },
  additionalClaimInformation: {
    id: 'additional-claim-information',
    label: 'Additional Claim Information (FL19)',
    tip: 'Choose from a list of codes or select "Custom Code" to enter values to populate into claim form field 19, if required for this Payer.',
  },
  additionalClaimNote: { id: 'additional-claim-note' },
  addAdditionalClaimButton: {
    id: 'add-additional-claim-button',
  },
  removeAdditionalClaimButton: {
    ref: 'remove-additional-claim-button',
    id: 'remove-additional-claim-button',
  },
  claimCodes: {
    id: 'claim-codes',
    tip: 'Write in a Claim Code to enter a fixed value to populate into claim form field 10d, if desired for this Payer. Otherwise, you can enter in a unique claim code during claim generation.',
  },
  claimCodeRequired: {
    id: 'claim-code-required',
    tip: "Select 'Required' to be reminded that Claim Codes are required for this Payer upon claim generation.",
    tag: 'neb-checkbox',
  },
  chartNotesRequired: {
    id: 'chart-notes-required',
    tag: 'neb-checkbox',
  },
  spinalManipInfoRequired: {
    id: 'spinal-manipulation-required',
    tag: 'neb-checkbox',
  },
  priorAuthorizationRequired: {
    id: 'prior-authorization-required',
    tag: 'neb-checkbox',
  },
  providerSpecificValuesHeader: {
    id: 'provider-specific-values-header',
    title: 'Optional Provider Specific Form Values',
    description:
      'For a selected practice provider indicate specific claim filing values, otherwise defaults will be used.',
  },
  renderingProvider: { id: 'rendering-provider', label: 'Rendering Provider' },
  ctInFormsSwitch: {
    id: 'ct-in-forms-switch',
  },
  insuredSignature: { id: 'checkbox-insured-signature' },
  patientSignature: { id: 'checkbox-patient-signature' },
  acceptAssignment: { id: 'checkbox-accept-assignment' },
  realTimeEligibility: { id: 'real-time-eligibility' },
  associatedRules: { id: 'associated-rules' },
  providerInNetwork: { id: 'provider-in-network' },
  providerOutOfNetwork: { id: 'provider-out-of-network' },
  providerSpecificValues: { id: 'provider-specific-values' },
  fl4MedicareOverride: {
    id: 'checkbox-fl4-override',
    label:
      'Populate FL 4 as blank when primary payer or “SAME” when secondary payer',
  },
  fl29AmountPaidOverride: {
    id: 'checkbox-fl29-override',
    label: 'Populate $0.00 in Amount Paid (FL 29)',
  },
  serviceFacilityOverride: {
    id: 'checkbox-service-facility-override',
    label:
      'Do not output the Service Facility on electronic claims when it is the same as the Billing Provider entity',
  },
  payerAddressOverride: {
    id: 'payer-address-override',
    label: 'Output Payer Address on electronic claims',
  },
  payerGroup: {
    id: 'payer-group',
  },
  newPayerGroup: {
    id: 'new-payer-group',
  },
  eraPayerNamesHeader: {
    id: 'era-payer-names-header',
    title: `${ERA_PAYER_NAME}s`,
    description:
      'Associate alternate ERA payer names to this payer for processing.',
  },
  eraPayerNames: {
    selector: '[id^=era-payer-names-]',
  },
  removeEraPayerNameButton: {
    ref: 'remove-era-payer-names-button-',
    selector: '[id^=remove-era-payer-names-button-]',
  },
  addEraPayerNameButton: {
    id: 'add-era-payer-name-button',
    label: `${getOverlayTitle({}, ERA_PAYER_NAME)}`,
  },
  wcbCaseNumberOverride: {
    id: 'checkbox-wcb-case-number',
    label: "Enable WCB Case Number for New York Workers' Compensation (FL9a)",
  },
  printCmsBackground: { id: 'checkbox-print-cms-background' },
  feeScheduleProviderList: {
    id: 'fee-schedule-provider-list',
  },
  headerTitle: { id: 'header-title' },
  copyButtonLink: { id: 'copy-button-link' },
};

const isElectronic = state =>
  state.submissionMethodPrimary === SUBMISSION_METHOD.ELECTRONIC_CLAIMS ||
  state.submissionMethodSecondary === SUBMISSION_METHOD.ELECTRONIC_CLAIMS;

const formatSelectValues = (items, labelKey = 'name') =>
  items.map(data => ({
    data,
    label: data[`${labelKey}`],
  }));

const requiredElectronic = () => ({
  error: 'Required',
  validate: (v, _, state) => {
    if (isElectronic(state)) {
      return v && v.data && v.data.id;
    }

    return true;
  },
});

const requiredPayerId = () => ({
  error: 'Required',
  validate: (v, _, state) => (isElectronic(state) ? !!v : true),
});

export default class FormPayerPlan extends NebForm {
  static get properties() {
    return {
      __selectedTab: String,
      __additionalClaimInformationSearchValues: Array,
      __allAdditionalClaimInformationItems: Array,
      __filteredAdditionalClaimInformationItems: Array,
      __providersInOutOfNetwork: Array,
      __providersWithSpecialists: Array,
      __selectedRenderingProvider: Object,
      __selectedRenderingProviderIdentifiers: Array,
      __providers: Array,
      __formProviders: Array,
      __billingInfo: Object,
      __feeScheduleItems: Array,
      __hasAddOnCTVerify: Boolean,
      __hasAddOnCTInForms: Boolean,
      __tier: String,
      __navItems: Array,
      __payerGroupItems: Array,
      __activePayerGroupItems: Array,
      __activeClearinghouseItems: Array,

      activeLocations: Array,
      activeServiceLocations: Array,
      payerGroups: Array,
      payers: Array,
      rules: Array,
      names: Array,
      activeClearinghouses: Array,
      condensedHeader: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow: unset;
        }

        .layout {
          overflow: auto;
        }

        .grid-profile {
          grid-template-columns: repeat(6, 1fr);
        }

        .grid-claim {
          grid-template-columns: 5fr 4fr;
        }

        .grid-tooltip-space {
          padding-right: 40px;
        }

        .span-2 {
          grid-column: span 2;
        }

        .span-3 {
          grid-column: span 3;
        }

        .span-4 {
          grid-column: span 4;
        }

        .flex-row {
          display: flex;
        }

        .add-payer-button {
          width: fit-content;
        }

        .column-three {
          grid-column: 1 / 4;
        }

        .container-groups {
          display: grid;
          flex-direction: column;
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 1fr;
          flex: 1 0 0;
        }

        .spacer {
          height: ${CSS_SPACING};
        }

        .padding-left,
        .row-padding {
          padding-left: ${CSS_SPACING};
        }

        .row-padding {
          padding-bottom: 12px;
        }

        .general-header {
          align-items: center;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          border-bottom: 0.5px solid ${CSS_COLOR_GREY_5};
        }

        .general-header-title {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .subsection {
          padding: 0 ${CSS_SPACING};
        }

        .claim-code-required-checkbox {
          padding-right: 10px;
        }

        .bottom-section {
          padding-bottom: ${CSS_SPACING};
        }

        .tab-header {
          display: flex;
        }

        .tabs {
          flex-grow: 1;
        }

        .button-copy {
          padding-right: ${CSS_SPACING};
        }

        .underline {
          padding-bottom: 10px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .icon-remove {
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
        }

        .limit-width {
          width: 300px;
        }

        .container-default-section {
          align-items: unset;
        }

        .claim-options-column {
          margin-top: 15px;
        }

        .section-header {
          padding-top: ${CSS_SPACING};
        }

        .checkbox-override {
          padding-left: ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }

        .condensed-header {
          display: flex;
          padding: ${CSS_SMALL_SPACING} ${CSS_SPACING};
          justify-content: space-between;
        }

        .header-title {
          font-size: 16px;
          font-weight: bold;
        }
      `,
    ];
  }

  static createModel() {
    return {
      payerId: '',
      status: STATUS_TYPE.ACTIVE,
      alias: '',
      payerName: '',
      attentionLine: '',
      financialClass: 'Commercial Insurance',
      contactName: '',
      phone: '',
      extension: '',
      fax: '',
      website: '',
      notes: '',
      submissionMethodPrimary: 'Paper Claims',
      submissionMethodSecondary: 'Paper Claims',
      timelyFiling: null,
      realTimeEligibilityEnabled: false,
      realTimeEligibilityPayerId: '',
      realTimeEligibilityPortalId: '',
      realTimeEligibilityPortalPassword: '',
      realTimeEligibilityPortalUsername: '',
      useRealTimeEligibilityPortal: false,
      realTimeEligibilityNoFutureDates: false,
      billingProviderAddress: selectors.ITEM_EMPTY,
      address: Address.createModel(),
      ...ITEMS_FILING.reduce(
        (accum, curr) => ({
          ...accum,
          [curr.key]: curr.initialValue,
        }),
        {},
      ),
      ...ITEMS_OPTIONS.reduce(
        (accum, curr) => ({
          ...accum,
          [curr.key]: curr.initialValue,
        }),
        {},
      ),
      additionalClaimInfos: [
        {
          ...EMPTY_ADDITIONAL_CLAIM_INFO,
        },
      ],
      claimCodes: '',
      claimCodeRequired: false,
      providerSpecificValues: [],
      providerInOutOfNetwork: [],
      informsEnabled: true,
      fl4MedicareOverride: false,
      fl29AmountPaidOverride: false,
      serviceFacilityOverride: false,
      payerAddressOverride: false,
      wcbCaseNumberOverride: false,
      payerGroupId: '',
      clearinghouseId: '',
      clearinghousePayerId: '',
      payerEtin: '',
      chcId: '',
      chcPayerId: '',
      eraPayerNames: EMPTY_ERA_PAYER_NAMES,
      printCmsBackground: false,
      feeSchedulePayerPlans: [],
      autoUpdateCopay: true,
      autoUpdateCoinsurance: true,
      autoUpdateDeductible: true,
      autoUpdateOutOfPocket: true,
    };
  }

  createSelectors() {
    return {
      children: {
        alias: [
          isRequired('Required, up to 10 characters'),
          matchesPattern(/^\S*$/, 'No whitespaces allowed'),
          maxLength(10, 'Invalid, up to 10 characters'),
        ],
        payerName: [isRequired()],
        address: {
          children: {
            zipcode: [isZipCode],
          },
        },
        phone: [isPhoneNumber('(###) ###-####')],
        fax: [isPhoneNumber('(###) ###-####')],
        billingProviderAddress: selectors.select(
          [
            selectors.ITEM_EMPTY,
            ...this.__formatLocations(this.activeLocations),
          ],
          selectors.ITEM_EMPTY,
          { validators: [] },
        ),
        timelyFiling: {
          format: v => v || '',
          unformat: v => (v === '' ? null : v),
          validators: [
            range(1, 999, {
              error: '1-999',
            }),
          ],
        },
        chcPayerId: {
          format: v => v || '',
          unformat: v => (v === '' ? null : v),
        },
        clearinghousePayerId: [requiredPayerId()],
        payerEtin: {
          format: v => v || '',
          unformat: v => (v === '' ? null : v),
        },
        payerId: {
          format: v => v || '',
          unformat: v => (v === '' ? null : v),
        },
        additionalClaimInfos: {
          createItem: () => ({
            ...EMPTY_ADDITIONAL_CLAIM_INFO,
          }),
          children: {
            $: {
              unformat: v => (v.additionalClaimCodeId === '' ? '' : v),
              children: {
                additionalClaimCodeId: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, _, state) =>
                        v === null ||
                        v.length ||
                        (state.additionalClaimInfos.length === 1 &&
                          state.additionalClaimInfos[0].note.length === 0),
                    },
                  ],
                },
                note: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, _, state) =>
                        v.length ||
                        (state.additionalClaimInfos.length === 1 &&
                          state.additionalClaimInfos[0]
                            .additionalClaimCodeId !== null &&
                          !state.additionalClaimInfos[0].additionalClaimCodeId
                            .length),
                    },
                  ],
                },
              },
            },
          },
        },
        providerSpecificValues: {
          createItem: () =>
            NebProviderSpecificValuesFieldsList.createModel(
              this.__selectedRenderingProvider.id,
              this.__formatLocations(this.activeLocations),
            ),
          children: {
            $: NebProviderSpecificValuesFieldsList.createSelectors(
              this.__selectedRenderingProvider.id,
              this.__formatLocations(this.activeLocations),
            ),
          },
        },
        providerInOutOfNetwork: {
          children: {
            $: {
              children: {
                providerId: {
                  unsafe: true,
                  clipPristine: true,
                },
                isInNetwork: {
                  unsafe: true,
                  clipPristine: true,
                },
              },
            },
          },
        },
        clearinghouseId: {
          ...selectors.select(
            [selectors.ITEM_EMPTY, ...this.__activeClearinghouseItems],
            selectors.ITEM_EMPTY,
            {
              validators: [requiredElectronic()],
            },
          ),
        },
        realTimeEligibilityPayerId: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v ||
              !state.realTimeEligibilityEnabled ||
              state.useRealTimeEligibilityPortal,
          },
        ],
        realTimeEligibilityPortalId: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v ||
              !state.realTimeEligibilityEnabled ||
              !state.useRealTimeEligibilityPortal,
          },
        ],
        realTimeEligibilityPortalPassword: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v ||
              !state.realTimeEligibilityEnabled ||
              !state.useRealTimeEligibilityPortal,
          },
        ],
        realTimeEligibilityPortalUsername: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v ||
              !state.realTimeEligibilityEnabled ||
              !state.useRealTimeEligibilityPortal,
          },
        ],
        payerGroupId: {
          ...selectors.select(
            [selectors.ITEM_EMPTY, ...this.__payerGroupItems],
            selectors.ITEM_EMPTY,
            { validators: [] },
          ),
        },
        eraPayerNames: {
          createItem: () => EMPTY_ERA_PAYER_NAMES[0],
          unformat: v => v.filter(v => v),
          children: {
            $: {
              format: v => v.name,
              unformat: v => ({
                name: v,
              }),
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, { eraPayerNames }) =>
                    v || eraPayerNames.length === 1,
                },
                {
                  error: 'Duplicated Value',
                  validate: (v, _, { eraPayerNames }) =>
                    eraPayerNames.filter(
                      eraPayerName =>
                        eraPayerName.toLowerCase() === v.toLowerCase(),
                    ).length === 1,
                },
                {
                  error: 'This name is already associated with another payer',
                  validate: v => !this.__checkAlreadyLinkedEraPayerName(v),
                },
              ],
            },
          },
        },
        feeSchedulePayerPlans: NebFeeScheduleProviderList.createSelectors(
          this.__feeScheduleItems,
          this.__formProviders,
        ),
      },
    };
  }

  initState() {
    super.initState();

    this.__hasAddOnCTVerify = false;
    this.__hasAddOnCTInForms = false;
    this.__hasRteFF = false;
    this.__selectedTab = 'profile';
    this.__navItems = [
      {
        id: 'profile',
        label: 'Payer Profile',
        renderer: () => this.renderProfileTab(),
      },
      {
        id: 'claim',
        label: 'Claim Submission',
        renderer: () => this.renderClaimTab(),
      },
    ];

    this.__additionalClaimInformationSearchValues = [''];
    this.__allAdditionalClaimInformationItems = [];
    this.__filteredAdditionalClaimInformationItems = [];
    this.__billingInfo = {};
    this.__providersInOutOfNetwork = [];
    this.__providersWithSpecialists = [];
    this.__selectedRenderingProvider = {};
    this.__selectedRenderingProviderIdentifiers = [];
    this.__providerOtherTaxIds = [];
    this.__providers = [];
    this.__formProviders = [];
    this.__feeScheduleItems = [];
    this.__tier = '';
    this.__payerGroupItems = [];
    this.__activePayerGroupItems = [];
    this.__activeClearinghouseItems = [];

    this.clearinghousePayerId = '';
    this.activeLocations = [];
    this.activeServiceLocations = [];
    this.payerGroups = [];
    this.payers = [];
    this.rules = [];
    this.names = [];
    this.activeClearinghouses = [];
    this.condensedHeader = false;

    this.onUpdateRuleSet = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeTab: tab => {
        this.__selectedTab = tab;
      },
      copy: async () => {
        let formattedPayer;

        const result = await openPopup(POPUP_RENDER_KEYS.COPY_EXISTING_PAYER);

        if (result) {
          const { accepted, payerPlanId } = result;

          if (!accepted) return;

          const rawPayerPlan = await getPayerPlan(payerPlanId, 4);

          formattedPayer = this.__formatCopyExistingPayer(rawPayerPlan);

          if (this.formService.isDirty) {
            this.__handleConfirmPopup(formattedPayer);
          } else {
            this.__copyPayerPlan(formattedPayer);
          }
        }
      },
      searchAdditionalClaim: async ({ name, value }) => {
        const idx = name.split('.')[1];

        this.formService.validateKey(
          ['additionalClaimInfos', `${idx}`, 'additionalClaimCodeId'],
          true,
        );

        if (this.__additionalClaimInformationSearchValues[idx] === value) {
          return;
        }

        this.__additionalClaimInformationSearchValues[idx] = value;

        const filteredItems = this.__filteredAdditionalClaimInformationItems;

        const { additionalClaimCode } = await getAdditionalClaimCodes({
          search: value,
        });

        filteredItems[idx] =
          this.__mapAdditionalClaimCodes(additionalClaimCode);

        if (!value.length) filteredItems[idx].unshift(CUSTOM_CODE_ITEM);

        this.__filteredAdditionalClaimInformationItems = [...filteredItems];
      },
      changeAdditionalClaim: e => {
        const idx = e.name.split('.')[1];

        let newItem;

        if (e.value) {
          newItem = e.value.item;
        } else {
          newItem = { ...EMPTY_ADDITIONAL_CLAIM_INFO };
        }

        this.formService.apply(
          `additionalClaimInfos.${idx}.additionalClaimCodeId`,
          newItem.additionalClaimCodeId,
        );

        this.formService.apply(
          `additionalClaimInfos.${idx}.code`,
          newItem.code,
        );

        this.formService.apply(
          `additionalClaimInfos.${idx}.codeGroup`,
          newItem.codeGroup,
        );

        this.formService.apply(
          `additionalClaimInfos.${idx}.description`,
          newItem.description,
        );

        this.formService.apply(
          `additionalClaimInfos.${idx}.sortOrder`,
          parseInt(idx, 10),
        );

        this.formService.validateKey(
          ['additionalClaimInfos', `${idx}`, 'note'],
          true,
        );
      },
      changeAdditionalClaimNote: ({ name, value }) => {
        const idx = name.split('.')[1];

        this.formService.apply(name, value);

        if (idx === '0') {
          this.formService.validateKey(
            ['additionalClaimInfos', `${idx}`, 'additionalClaimCodeId'],
            true,
          );
        }
      },
      changeProviderInNetwork: e => {
        if (e.event === 'select') {
          this.__clearIsInNetworkValue(e.name);

          e.value.forEach(v => {
            const idxOfValue = this.state.providerInOutOfNetwork.findIndex(
              p => p.providerId === v.providerId,
            );

            this.formService.apply(
              `providerInOutOfNetwork.${idxOfValue}.isInNetwork`,
              true,
            );
          });
        }
      },
      changeProviderOutOfNetwork: e => {
        if (e.event === 'select') {
          this.__clearIsInNetworkValue(e.name);

          e.value.forEach(v => {
            const idxOfValue = this.state.providerInOutOfNetwork.findIndex(
              p => p.providerId === v.providerId,
            );

            this.formService.apply(
              `providerInOutOfNetwork.${idxOfValue}.isInNetwork`,
              false,
            );
          });
        }
      },
      changeSubmissionMethod: ({ name, value }) => {
        if (value !== this.state[name]) {
          this.formService.apply(name, value);
        }

        const { submissionMethodPrimary, submissionMethodSecondary } =
          this.state;

        if (
          isElectronic({
            submissionMethodPrimary,
            submissionMethodSecondary,
          }) &&
          this.state.clearinghouseId === '' &&
          this.__activeClearinghouseItems.length === 1
        ) {
          this.formService.apply(
            'clearinghouseId',
            this.__activeClearinghouseItems[0],
          );
        }
      },
      financialClassChanged: ({ name, value }) => {
        if (value !== FINANCIAL_CLASSES.WorkersCompensation) {
          this.formService.apply('wcbCaseNumberOverride', false);
        }

        if (value === this.state.financialClass) return;

        if (!this.model.id) {
          this.formService.apply('fl4MedicareOverride', value === MEDICARE);
        }

        this.formService.apply(
          'claimFilingIndicator',
          DEFAULT_CLAIM_FILE_INDICATORS[value],
        );

        this.formService.apply(name, value);
      },
      statusChanged: ({ name, value }) => {
        this.formService.apply(
          name,
          value ? STATUS_TYPE.ACTIVE : STATUS_TYPE.INACTIVE,
        );
      },
      addAdditionalClaim: () => {
        this.__additionalClaimInformationSearchValues.push('');
        this.__filteredAdditionalClaimInformationItems.push(
          this.__allAdditionalClaimInformationItems,
        );

        this.formService.addItem('additionalClaimInfos');
      },
      removeAdditionalClaim: idx => {
        this.__additionalClaimInformationSearchValues.splice(idx, 1);
        this.__filteredAdditionalClaimInformationItems.splice(idx, 1);

        this.formService.removeItem('additionalClaimInfos', idx);

        if (this.__filteredAdditionalClaimInformationItems.length === 1) {
          this.formService.validateKey(
            ['additionalClaimInfos', '0', 'additionalClaimCodeId'],
            true,
          );

          this.formService.validateKey(
            ['additionalClaimInfos', '0', 'note'],
            true,
          );
        }
      },
      save: async () => {
        if (this.formService.validate()) {
          if (
            !(this.state.status === STATUS_TYPE.ACTIVE) &&
            this.state.feeSchedulePayerPlans[0].feeScheduleId.data.id
          ) {
            if (this.model.id && this.model.status === STATUS_TYPE.ACTIVE) {
              const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
                title: 'Inactivate Payer Plan',
                message:
                  'Inactivating this payer will remove associated fee schedules from this payer.  Do you wish to continue?',
                confirmText: 'Yes',
                cancelText: 'No',
              });

              if (!res) return;
            } else {
              await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
                title: 'Notice',
                message:
                  'A fee schedule can only be associated to an active payer. Please reactivate the payer to add the fee schedule.',
              });

              return;
            }
          }

          let model = this.formService.build();

          if (model.additionalClaimInfos[0] === '') {
            model = {
              ...model,
              additionalClaimInfos: [],
            };
          }

          this.onSave(model);
        } else {
          this.__selectedTab = getTabWithError(
            this.__genNavItems(),
            this.errors,
            this.__selectedTab,
          );
        }
      },
      selectRenderingProvider: ({ value }) => {
        const index = this.__getProviderSpecificValuesIndex();

        const hasProviderSpecificValues =
          this.state.providerSpecificValues[index] &&
          this.state.providerSpecificValues[index].items.length;

        if (!hasProviderSpecificValues) {
          this.__setSelectedRenderingProvider(value);
        } else if (
          this.__validateProviderSpecificValues(
            index,
            this.__selectedRenderingProvider,
          )
        ) {
          this.__setSelectedRenderingProvider(value);
        }
      },
      addProviderSpecificValues: ({ name }) => {
        let [providerIndex] = name.split('.');
        let itemIndex = 0;

        if (providerIndex === '-1') {
          this.formService.addItem('providerSpecificValues');

          providerIndex = this.state.providerSpecificValues.length - 1;

          this.formService.apply(
            `providerSpecificValues.${providerIndex}.items.${itemIndex}.locations`,
            this.__formatLocations(this.activeServiceLocations),
          );
        } else {
          this.formService.addItem(
            `providerSpecificValues.${providerIndex}.items`,
          );

          itemIndex =
            this.state.providerSpecificValues[providerIndex].items.length - 1;

          const locations = this.state.providerSpecificValues[
            providerIndex
          ].items.flatMap(item => item.locations);

          const unusedLocations = this.activeServiceLocations.filter(
            location =>
              locations.findIndex(
                itemLocation => itemLocation.data.id === location.id,
              ) === -1,
          );

          this.formService.apply(
            `providerSpecificValues.${providerIndex}.items.${itemIndex}.locations`,
            this.__formatLocations(unusedLocations),
          );
        }
      },
      removeProviderSpecificValues: async ({ name }) => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_REMOVE_PROVIDER_SPECIFIC_VALUES,
        );

        const itemIndex = name.split('.')[0];

        if (confirmed) {
          const providerIndex = this.__getProviderSpecificValuesIndex();

          if (
            this.state.providerSpecificValues[providerIndex].items.length === 1
          ) {
            this.formService.removeItem(
              'providerSpecificValues',
              providerIndex,
            );
          } else {
            this.formService.removeItem(
              `providerSpecificValues.${providerIndex}.items`,
              itemIndex,
            );
          }
        }
      },
      providerSpecificValuesChanged: ({ name, value }) => {
        this.formService.apply(`providerSpecificValues.${name}`, value);
      },
      providerSpecificTaxIdValueChanged: ({ event, name, value }) => {
        if (event !== 'select') return;

        const index = name.split('.')[0];
        const itemIndex = name.split('.')[2];

        if (
          value.federalTaxIdSource ===
          this.state.providerSpecificValues[index].items[itemIndex]
            .federalTaxIdSource
        ) {
          return;
        }

        this.formService.apply(
          `providerSpecificValues.${name}Source`,
          value.federalTaxIdSource,
        );

        this.formService.apply(
          `providerSpecificValues.${name}ProviderIdentifierId`,
          value.federalTaxIdProviderIdentifierId,
        );

        if (value.federalTaxIdSource !== FEDERAL_TAX_ID_SOURCE.OTHER) {
          this.formService.apply(
            `providerSpecificValues.${name}OtherNumber`,
            '',
          );

          this.formService.apply(`providerSpecificValues.${name}OtherType`, '');
        } else {
          if (value.federalTaxIdOtherNumber !== '') {
            this.formService.apply(
              `providerSpecificValues.${name}OtherNumber`,
              value.federalTaxIdOtherNumber,
            );
          }

          this.formService.apply(
            `providerSpecificValues.${name}OtherType`,
            value.federalTaxIdOtherType,
          );
        }
      },
      providerSpecificIdentifierChanged: ({ event, name, value }) => {
        if (event !== 'select') return;

        const { id } = value;

        this.formService.apply(`providerSpecificValues.${name}`, id || '');
      },
      providerSpecificNPIChanged: ({ event, name, value }) => {
        if (event !== 'select') return;

        this.formService.apply(`providerSpecificValues.${name}`, value.data);
      },
      locationChanged: ({ name, value, event }) => {
        this.formService.apply(`providerSpecificValues.${name}`, value);

        if (event === 'select' && value !== 'Other') {
          this.__clearProviderSpecificAddress(name);
        }
      },
      providerOtherTaxIdTypeChanged: ({ name, value }) => {
        this.formService.apply(`providerSpecificValues.${name}`, value);

        const providerIndex = name.split('.')[0];
        const itemIndex = name.split('.')[2];

        this.formService.apply(
          `providerSpecificValues.${providerIndex}.items.${itemIndex}.federalTaxIdOtherNumber`,
          value !== FEDERAL_TAX_ID_TYPE.EIN
            ? ssn.toFormattedValue(
                this.state.providerSpecificValues[providerIndex].items[
                  itemIndex
                ].federalTaxIdOtherNumber.replaceAll('-', ''),
              )
            : taxId.toFormattedValue(
                this.state.providerSpecificValues[providerIndex].items[
                  itemIndex
                ].federalTaxIdOtherNumber.replaceAll('-', ''),
              ),
        );
      },
      addNewPayerGroup: async () => {
        const payerGroup = await openOverlay(OVERLAY_KEYS.PAYER_GROUPS, {
          context: {},
          payers: this.payers,
        });

        if (!payerGroup) return null;

        const [formattedPayerGroup] = formatSelectValues([payerGroup]);

        const payerGroupItems = [
          ...this.__payerGroupItems,
          formattedPayerGroup,
        ].sort((a, b) => a.label.localeCompare(b.label));

        this.__updatePayerGroupItems(payerGroupItems);

        return this.formService.apply('payerGroupId', formattedPayerGroup);
      },
      addEraPayerName: () => this.formService.addItem('eraPayerNames'),
      removeEraPayerName: idx =>
        this.formService.removeItem('eraPayerNames', idx),
      fetchRules: () => this.onUpdateRuleSet(),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasRteFF = await hasFeatureOrBeta(FEATURE_FLAGS.ENHANCE_RTE);

    const { item } = store.getState().practiceInformation;

    this.__tier = item ? item.tier : '';

    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    this.__hasAddOnCTInForms = await hasAddOn(ADD_ONS.CT_INFORMS);

    if (this.__hasAddOnCTVerify) {
      this.__navItems = [
        ...this.__navItems,
        {
          id: 'rte',
          label: 'Real-Time Eligibility',
          renderer: () => this.renderRealTimeEligibilityTab(),
        },
      ];
    }

    const { additionalClaimCode } = await getAdditionalClaimCodes();

    this.__allAdditionalClaimInformationItems =
      this.__mapAdditionalClaimCodes(additionalClaimCode);

    this.__allAdditionalClaimInformationItems.unshift(CUSTOM_CODE_ITEM);

    this.__filteredAdditionalClaimInformationItems.push(
      this.__allAdditionalClaimInformationItems,
    );
  }

  async load() {
    this.__billingInfo = await getBillingInfo();

    const allProviders = await getProviderUsers();

    this.__providers = allProviders
      .filter(provider => provider.type === PROVIDER_TYPE.PROVIDER)
      .map(provider => this.__formatProvider(provider));

    this.__providersWithSpecialists = allProviders.filter(
      provider =>
        provider.type === PROVIDER_TYPE.PROVIDER ||
        provider.type === PROVIDER_TYPE.SPECIALIST,
    );

    this.__formProviders = formatDisplayProviders(
      this.__providersWithSpecialists,
      { allowEmpty: false, includeSpecialists: true },
    );

    if (this.__providers.length) {
      this.__matchSelectedRenderingProvider(this.model.providerSpecificValues);
    }

    const items = await feeSchedulesApi.fetchMany();

    this.__feeScheduleItems = mapToMenuItems(items).sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    this.__feeScheduleItems.unshift(EMPTY_ITEM);

    this.__mapProvidersInOutOfNetwork();
  }

  __insertRuleSetTab() {
    const hasRuleSetTab = this.__navItems.some(item => item.id === 'ruleSet');

    if (this.model.id && !hasRuleSetTab) {
      this.__navItems.splice(2, 0, {
        id: 'ruleSet',
        label: 'Associated Rules',
        renderer: () => this.renderRulesTab(),
      });

      this.__navItems = [...this.__navItems];
    }
  }

  __formatSelectedPayerGroup(payerGroupId) {
    const selectedItem = this.__payerGroupItems.find(
      ({ data: { id } }) => id === payerGroupId,
    );
    return selectedItem || selectors.ITEM_EMPTY;
  }

  __clearIsInNetworkValue(name) {
    for (
      let idx = this.state.providerInOutOfNetwork.length - 1;
      idx >= 0;
      idx--
    ) {
      if (
        name === 'providerInNetwork' &&
        this.state.providerInOutOfNetwork[idx].isInNetwork === true
      ) {
        this.formService.apply(
          `providerInOutOfNetwork.${idx}.isInNetwork`,
          null,
        );
      } else if (
        name === 'providerOutOfNetwork' &&
        this.state.providerInOutOfNetwork[idx].isInNetwork === false
      ) {
        this.formService.apply(
          `providerInOutOfNetwork.${idx}.isInNetwork`,
          null,
        );
      }
    }
  }

  __mapProvidersInOutOfNetwork() {
    this.__providersInOutOfNetwork = this.__providersWithSpecialists.map(p => {
      const res = this.model.providerInOutOfNetwork.find(
        entry => entry.providerId === p.id,
      );

      return {
        label: objToName(p.name, {
          middleInitial: true,
          reverse: true,
          preferred: true,
        }),
        isInNetwork: res ? res.isInNetwork : null,
        providerId: p.id,
      };
    });

    this.model.providerInOutOfNetwork = this.__providersInOutOfNetwork;

    this.formService.refresh(this.model);
  }

  __formatProvider(provider) {
    return {
      ...provider,
      label: objToName(provider.name, {
        middleInitial: true,
        reverse: true,
        preferred: true,
      }),
    };
  }

  __formatProviderForForm(provider) {
    return {
      data: provider,
      label: objToName(provider.name, {
        middleInitial: true,
        reverse: true,
        preferred: true,
      }),
    };
  }

  __formatLocation(location) {
    return { data: location, label: location.name };
  }

  __formatLocations(locations) {
    return locations.map(location => this.__formatLocation(location));
  }

  __formatLocationsFromIds(locationIds, locations) {
    return this.__formatLocations(
      locations.filter(location => locationIds.includes(location.id)),
    );
  }

  __formatCopyExistingPayer(payerPlan) {
    return {
      ...payerPlan,
      additionalClaimInfos: payerPlan.additionalClaimInfos,
      address: buildAddress(payerPlan.address),
      claimCodes: payerPlan.claimCodes || '',
      providerSpecificValues: payerPlan.providerSpecificValues || [],
      providerInOutOfNetwork: payerPlan.providerInOutOfNetwork || [],
    };
  }

  __formatSelectedClearinghouse(clearinghouseId) {
    const selectedItem = this.__activeClearinghouseItems.find(
      ({ data: { id } }) => id === clearinghouseId,
    );
    return selectedItem || selectors.ITEM_EMPTY;
  }

  __formatClearinghouses(items) {
    return items.map(data => ({
      data,
      label:
        data.partner === CLEARINGHOUSES.CHC
          ? data.partner
          : `${data.partner} (${data.alias})`,
    }));
  }

  __genNavItems() {
    const navItems = {
      profile: {
        ...this.__navItems[0],
        fields: [
          'alias',
          'payerName',
          'phone',
          'address',
          'fax',
          'eraPayerNames',
          'feeSchedulePayerPlans',
        ],
      },
      claim: {
        ...this.__navItems[1],
        fields: [
          'additionalClaimInfos',
          'providerSpecificValues',
          'timelyFiling',
          'clearinghouseId',
          'clearinghousePayerId',
          'payerId',
          'payerEtin',
        ],
      },
    };

    if (this.__hasAddOnCTVerify) {
      if (this.model.id) {
        navItems.rte = {
          ...this.__navItems[3],
          fields: [
            'realTimeEligibilityPayerId',
            'realTimeEligibilityPortalId',
            'realTimeEligibilityPortalPassword',
            'realTimeEligibilityPortalUsername',
          ],
        };
      } else {
        navItems.rte = {
          ...this.__navItems[2],
          fields: [
            'realTimeEligibilityPayerId',
            'realTimeEligibilityPortalId',
            'realTimeEligibilityPortalPassword',
            'realTimeEligibilityPortalUsername',
          ],
        };
      }
    }

    return navItems;
  }

  async __handleConfirmPopup(payerPlan) {
    const confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      ...CONFIRM_OVERWRITE_PAYER,
      message: CONFIRM_OVERWRITE_PAYER.message(payerPlan.alias),
    });

    if (confirmed) {
      this.__copyPayerPlan(payerPlan);
    }
  }

  __addLabel(additionalClaimInfo) {
    if (additionalClaimInfo.additionalClaimCodeId === null) {
      return {
        ...additionalClaimInfo,
        label: 'Custom Code',
      };
    }

    if (additionalClaimInfo.code.length) {
      return {
        ...additionalClaimInfo,
        label: `${additionalClaimInfo.codeGroup} - (${
          additionalClaimInfo.code
        }) ${additionalClaimInfo.description}`,
      };
    }

    return null;
  }

  __getProviderSpecificValuesIndex() {
    if (
      !this.state.providerSpecificValues ||
      !this.__selectedRenderingProvider
    ) {
      return -1;
    }

    return this.state.providerSpecificValues.findIndex(
      entry => entry.providerId === this.__selectedRenderingProvider.id,
    );
  }

  __setSelectedRenderingProvider(provider) {
    this.__selectedRenderingProvider = provider;

    const identifiers = [BLANK_IDENTIFIER];

    if (provider.identifiers) {
      provider.identifiers.forEach(entry =>
        identifiers.push({
          ...entry,
          label: `${entry.type.value} - ${entry.number}`,
        }),
      );
    }

    this.__selectedRenderingProviderIdentifiers = identifiers;

    const providerOtherTaxIds = [{ ...BLANK_OTHER_TAX_ID, label: '' }];

    providerOtherTaxIds.push({
      label: `Practice Tax ID - ${this.__billingInfo.taxId || ''} (EIN)`,
      federalTaxIdOtherNumber: '',
      federalTaxIdOtherType: '',
      federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PRACTICE_TAX_ID,
      federalTaxIdProviderIdentifierId: '',
    });

    if (provider && provider.taxId) {
      providerOtherTaxIds.push({
        label: `Provider ID - ${provider.taxId} (${
          provider.taxIdType === FEDERAL_TAX_ID_TYPE.EIN ? 'EIN' : 'SSN'
        })`,
        federalTaxIdOtherNumber: '',
        federalTaxIdOtherType: '',
        federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_TAX_ID,
        federalTaxIdProviderIdentifierId: '',
      });
    }

    if (provider.identifiers) {
      provider.identifiers.forEach(entry => {
        if (entry.type && entry.type.value === 'SY') {
          providerOtherTaxIds.push({
            label: `Provider Other ID - ${entry.number} (SSN)`,
            federalTaxIdOtherNumber: '',
            federalTaxIdOtherType: '',
            federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN,
            federalTaxIdProviderIdentifierId: entry.id,
          });
        }
      });
    }

    this.activeServiceLocations.forEach(location => {
      if (location.taxId) {
        providerOtherTaxIds.push({
          label: `${location.name} - ${location.taxId} (EIN)`,
          federalTaxIdOtherNumber: '',
          federalTaxIdOtherType: '',
          federalTaxIdSource: location.id,
          federalTaxIdProviderIdentifierId: '',
        });
      }
    });

    providerOtherTaxIds.push({
      ...BLANK_OTHER_TAX_ID,
      label: 'Other',
      federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.OTHER,
      federalTaxIdOtherType: FEDERAL_TAX_ID_TYPE.SSN,
    });

    this.__providerOtherTaxIds = providerOtherTaxIds;
  }

  __matchSelectedRenderingProvider(providerSpecificValues) {
    let selectedProvider = this.__providers[0];

    if (providerSpecificValues && providerSpecificValues.length) {
      const providerIds = providerSpecificValues.map(v => v.providerId);

      const providersWithValues = this.__providers.filter(p =>
        providerIds.includes(p.id),
      );

      if (providersWithValues.length) selectedProvider = providersWithValues[0];
    }

    this.__setSelectedRenderingProvider(selectedProvider);
  }

  __mapAdditionalClaimCodes(additionalClaimCode) {
    return additionalClaimCode.map(item => ({
      item: {
        additionalClaimCodeId: item.id,
        codeGroup: item.codeGroup,
        code: item.code,
        description: item.description,
        id: '',
        payerPlanId: '',
        sortOrder: 0,
      },
      label: `${item.codeGroup} - (${item.code}) ${item.description}`,
    }));
  }

  __clearProviderSpecificAddress(name) {
    const [providerIndex, , itemIndex, key] = name.split('.');

    let keys = [
      'serviceFacilityAddress.name',
      'serviceFacilityAddress.phone',
      'serviceFacilityAddress.address.address1',
      'serviceFacilityAddress.address.address2',
      'serviceFacilityAddress.address.city',
      'serviceFacilityAddress.address.state',
      'serviceFacilityAddress.address.zipcode',
    ];

    if (key === 'billingProviderLocation') {
      keys = [
        'billingProviderAddress.name',
        'billingProviderAddress.phone',
        'billingProviderAddress.address.address1',
        'billingProviderAddress.address.address2',
        'billingProviderAddress.address.city',
        'billingProviderAddress.address.state',
        'billingProviderAddress.address.zipcode',
      ];
    }

    keys.forEach(k => {
      this.formService.apply(
        `providerSpecificValues.${providerIndex}.items.${itemIndex}.${k}`,
        '',
      );
    });
  }

  __clearSectionData(section, key) {
    if (section.length === 0) return;
    const len = section.length - 1;

    for (let i = len; i >= 0; i -= 1) {
      this.formService.removeItem(key, i);
    }
  }

  __clearClaimInfosSearchBuffer(count) {
    this.__filteredAdditionalClaimInformationItems = [];
    this.__additionalClaimInformationSearchValues = [];

    while (this.__filteredAdditionalClaimInformationItems.length < count) {
      this.__additionalClaimInformationSearchValues.push('');
      this.__filteredAdditionalClaimInformationItems.push(
        this.__allAdditionalClaimInformationItems,
      );
    }
  }

  __copyProviderSpecificValuesEntry(entry, idx) {
    this.formService.apply(
      `providerSpecificValues.${idx}.providerId`,
      entry.providerId,
    );

    const keyPaths = entry.items.flatMap((_, itemIndex) => {
      if (itemIndex > 0) {
        this.formService.addItem(`providerSpecificValues.${idx}.items`);
      }
      return [
        `items.${itemIndex}.serviceFacilityNPISource`,
        `items.${itemIndex}.billingProviderNPISource`,
        `items.${itemIndex}.serviceFacilityLocation`,
        `items.${itemIndex}.billingProviderLocation`,
        `items.${itemIndex}.providerOtherIdentifierId`,
        `items.${itemIndex}.billingProviderOtherIdentifierId`,
        `items.${itemIndex}.serviceFacilityOtherIdentifierId`,
        `items.${itemIndex}.federalTaxIdSource`,
        `items.${itemIndex}.federalTaxIdProviderIdentifierId`,
        `items.${itemIndex}.federalTaxIdOtherNumber`,
        `items.${itemIndex}.federalTaxIdOtherType`,
        `items.${itemIndex}.billingProviderAddress`,
        `items.${itemIndex}.serviceFacilityAddress`,
        `items.${itemIndex}.locations`,
      ];
    });

    keyPaths.forEach(path => {
      const itemIndex = path.split('.')[1];
      const key = path.split('.')[2];

      if (
        key === 'billingProviderAddress' ||
        key === 'serviceFacilityAddress'
      ) {
        this.formService.apply(
          `providerSpecificValues.${idx}.items.${itemIndex}.${key}.name`,
          entry.items[itemIndex][key].name,
        );

        this.formService.apply(
          `providerSpecificValues.${idx}.${path}.phone`,
          entry.items[itemIndex][key].phone,
        );

        Object.entries(entry.items[itemIndex][key].address).forEach(
          ([addressKey, addressValue]) => {
            this.formService.apply(
              `providerSpecificValues.${idx}.${path}.address.${addressKey}`,
              addressValue,
            );
          },
        );
      } else if (
        key === 'billingProviderLocation' ||
        key === 'serviceFacilityLocation'
      ) {
        const location = this.activeLocations.find(
          l => l.id === entry.items[itemIndex][key],
        );

        if (location) {
          this.formService.apply(
            `providerSpecificValues.${idx}.${path}`,
            this.__formatLocation(location),
          );
        } else {
          this.formService.apply(`providerSpecificValues.${idx}.${path}`, {
            data: { id: entry.items[itemIndex][key] },
            label: entry.items[itemIndex][key],
          });
        }
      } else if (key === 'locations') {
        this.formService.apply(
          `providerSpecificValues.${idx}.${path}`,
          this.__formatLocationsFromIds(
            entry.items[itemIndex][key],
            this.activeServiceLocations,
          ),
        );
      } else {
        this.formService.apply(
          `providerSpecificValues.${idx}.${path}`,
          entry.items[itemIndex][key],
        );
      }
    });
  }

  __copyPayerPlan(payerPlan) {
    Object.entries(payerPlan).forEach(([key, value]) => {
      switch (key) {
        case 'id':
        case 'label':
        case 'showPaidAmountOnForm':
        case 'printAccidentDateOnForm':
        case 'priorAuthorizationRequired':
        case 'payerId':
        case 'chcPayerId':
        case 'displayPayerID':
        case 'primaryIdentifier':
        case 'eraPayerNames':
        case 'feeScheduleId':
          break;

        case 'additionalClaimInfos': {
          let count = 0;

          this.__clearSectionData(this.state[key], key);

          if (value.length === 0) {
            this.formService.addItem('additionalClaimInfos');
            count = 1;
          } else {
            count = value.length;

            value.forEach((entry, idx) => {
              this.formService.addItem('additionalClaimInfos');

              [
                'additionalClaimCodeId',
                'code',
                'codeGroup',
                'description',
                'note',
                'sortOrder',
              ].forEach(k =>
                this.formService.apply(
                  `additionalClaimInfos.${idx}.${k}`,
                  entry[k],
                ),
              );
            });
          }

          this.__clearClaimInfosSearchBuffer(count);
          break;
        }

        case 'providerSpecificValues':
          this.__clearSectionData(this.state[key], key);

          value.forEach((entry, idx) => {
            const len = this.state.providerSpecificValues.length;
            if (idx >= len) this.formService.addItem('providerSpecificValues');

            this.__copyProviderSpecificValuesEntry(entry, idx);
          });

          this.__matchSelectedRenderingProvider(value);
          break;

        case 'providerInOutOfNetwork': {
          this.state.providerInOutOfNetwork.forEach((entry, idx) => {
            const match = value.find(v => v.providerId === entry.providerId);

            this.formService.apply(
              `providerInOutOfNetwork.${idx}.isInNetwork`,
              match ? match.isInNetwork : null,
            );
          });

          break;
        }

        case 'feeSchedulePayerPlans': {
          const feeSchedulePayerPlans = value.length
            ? padArray(formatFeeSchedulePayerPlans(value, this.__formProviders))
            : [];

          this.__clearSectionData(this.state[key], key);

          if (feeSchedulePayerPlans.length) {
            feeSchedulePayerPlans.forEach((entry, idx) => {
              const len = this.state.feeSchedulePayerPlans.length;
              if (idx >= len) this.formService.addItem('feeSchedulePayerPlans');

              const feeSchedule =
                this.__feeScheduleItems.find(
                  ({ data }) => data.id === entry.feeScheduleId,
                ) || selectors.ITEM_EMPTY;

              this.formService.apply(
                `feeSchedulePayerPlans.${idx}.feeScheduleId`,
                feeSchedule,
              );

              const providers = entry.providerIds.map(id => {
                const provider = this.__formProviders.find(
                  ({ data }) => data.id === id,
                );

                return provider;
              });

              this.formService.apply(
                `feeSchedulePayerPlans.${idx}.providerIds`,
                providers,
              );
            });
          } else {
            this.formService.addItem('feeSchedulePayerPlans');
          }

          break;
        }

        case 'timelyFiling':
          this.formService.apply(key, value === null ? '' : `${value}`);
          break;

        case 'address':
          Object.entries(value).forEach(([addressKey, addressValue]) =>
            this.formService.apply(`address.${addressKey}`, addressValue.value),
          );

          break;

        case 'alias':
        case 'payerName':
          this.formService.apply(key, `Copy of ${value}`);
          break;

        case 'billingProviderAddress': {
          const location = this.activeLocations.find(({ id }) => id === value);

          if (location) {
            this.formService.apply(key, this.__formatLocation(location));
          } else this.formService.apply(key, selectors.ITEM_EMPTY);
          break;
        }

        case 'payerGroupId': {
          const payerGroup = this.__formatSelectedPayerGroup(value);
          this.formService.apply(key, payerGroup);
          break;
        }

        case 'clearinghouseId': {
          const clearinghouse = this.__formatSelectedClearinghouse(value);
          this.formService.apply(key, clearinghouse);
          break;
        }

        default:
          this.formService.apply(key, value);
          break;
      }
    });

    this.formService.validate();
  }

  __getProviderSpecificValuesKeys(index, provider) {
    return this.state.providerSpecificValues[index].items.flatMap(
      (item, index) => {
        let keys = [
          `items.${index}.locations`,
          `items.${index}.serviceFacilityAddress.name`,
          `items.${index}.serviceFacilityAddress.phone`,
          `items.${index}.serviceFacilityAddress.address.address1`,
          `items.${index}.serviceFacilityAddress.address.city`,
          `items.${index}.serviceFacilityAddress.address.state`,
          `items.${index}.serviceFacilityAddress.address.zipcode`,
          `items.${index}.federalTaxIdOtherNumber`,
        ];

        if (provider.type === PROVIDER_TYPE.PROVIDER) {
          keys = [
            ...keys,
            `items.${index}.billingProviderAddress.name`,
            `items.${index}.billingProviderAddress.phone`,
            `items.${index}.billingProviderAddress.address.address1`,
            `items.${index}.billingProviderAddress.address.city`,
            `items.${index}.billingProviderAddress.address.state`,
            `items.${index}.billingProviderAddress.address.zipcode`,
            `items.${index}.federalTaxIdOtherNumber`,
          ];
        }

        return keys;
      },
    );
  }

  __validateProviderSpecificValues(index, provider) {
    const keys = this.__getProviderSpecificValuesKeys(index, provider);
    const validationResult = [];

    keys.forEach(key => {
      const subKeyPath = key.split('.');
      const keyPath = ['providerSpecificValues', index].concat(subKeyPath);

      this.formService.validateKey(keyPath, true);

      validationResult.push(getValueByPath(this.errors, keyPath) === '');
    });

    return validationResult.every(x => x);
  }

  __updatePayerGroupItems(payerGroupItems) {
    this.__payerGroupItems = payerGroupItems;

    this.__activePayerGroupItems = this.__payerGroupItems.filter(
      ({ label, data: { active } }) => !label || active,
    );
  }

  __checkAlreadyLinkedEraPayerName(eraPayerName) {
    const alreadyLinkedEraPayerNames = this.payers
      .filter(({ id }) => id !== this.model.id)
      .flatMap(({ eraPayerNames = [] }) =>
        eraPayerNames.map(({ name }) => name),
      );
    return alreadyLinkedEraPayerNames.filter(
      alreadyLinkedEraPayerName =>
        alreadyLinkedEraPayerName.toLowerCase() === eraPayerName.toLowerCase(),
    ).length;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      while (
        this.__filteredAdditionalClaimInformationItems.length <
        this.model.additionalClaimInfos.length - 1
      ) {
        this.__additionalClaimInformationSearchValues.push('');
        this.__filteredAdditionalClaimInformationItems.push(
          this.__allAdditionalClaimInformationItems,
        );
      }
    }

    if (changedProps.has('payerGroups')) {
      const payerGroupItems = [
        selectors.ITEM_EMPTY,
        ...formatSelectValues(this.payerGroups),
      ];
      this.__updatePayerGroupItems(payerGroupItems);
    }

    if (changedProps.has('activeClearinghouses')) {
      const clearinghouseItems = [
        selectors.ITEM_EMPTY,
        ...this.__formatClearinghouses(this.activeClearinghouses),
      ];

      this.__activeClearinghouseItems = clearinghouseItems.filter(
        ({ label, data: { active } }) => !label || active,
      );
    }

    this.__insertRuleSetTab();

    super.update(changedProps);
  }

  __renderAgreementDetailsSection() {
    return html`
      <neb-header
        .label="${ELEMENTS.agreementDetailsHeader.title}"
      ></neb-header>
      ${html`
        <div class="grid">
          <neb-fee-schedule-provider-list
            id="${ELEMENTS.feeScheduleProviderList.id}"
            name="feeSchedulePayerPlans"
            .model="${this.state.feeSchedulePayerPlans}"
            .providerItems="${this.__formProviders}"
            .feeScheduleItems="${this.__feeScheduleItems}"
            .errors="${this.errors.feeSchedulePayerPlans}"
            .onAdd="${this.handlers.addItem}"
            .onRemove="${this.handlers.removeItem}"
            .onChange="${this.handlers.change}"
          ></neb-fee-schedule-provider-list>
        </div>
      `}
    `;
  }

  __mapInNetworkProviders() {
    return this.state.providerInOutOfNetwork.filter(
      p => p.isInNetwork === true || p.isInNetwork === null,
    );
  }

  __mapOutOfNetworkProviders() {
    return this.state.providerInOutOfNetwork.filter(
      p => p.isInNetwork === false || p.isInNetwork === null,
    );
  }

  __filterUnselectedProviders(providers) {
    return providers.filter(p => p.isInNetwork !== null);
  }

  __renderProviderDetailsSection() {
    return html`
      <neb-header
        class="section-header"
        label="${ELEMENTS.providerDetailsHeader.title}"
        description="Select which providers are in or out of network."
      ></neb-header>

      <div class="grid grid-2">
        <neb-select
          id="${ELEMENTS.providerInNetwork.id}"
          name="providerInNetwork"
          label="In Network"
          .items="${this.__mapInNetworkProviders()}"
          .value="${this.__filterUnselectedProviders(
            this.__mapInNetworkProviders(),
          )}"
          .onChange="${this.handlers.changeProviderInNetwork}"
          multiSelect
        ></neb-select>

        <neb-select
          id="${ELEMENTS.providerOutOfNetwork.id}"
          name="providerOutOfNetwork"
          label="Out of Network"
          .items="${this.__mapOutOfNetworkProviders()}"
          .value="${this.__filterUnselectedProviders(
            this.__mapOutOfNetworkProviders(),
          )}"
          .onChange="${this.handlers.changeProviderOutOfNetwork}"
          multiSelect
        ></neb-select>
      </div>
    `;
  }

  __renderAttentionLine() {
    return html`
      <neb-textfield
        id="${ELEMENTS.attentionLine.id}"
        class="span-3"
        label="Attention Line"
        name="attentionLine"
        maxLength="80"
        helper=" "
        .value="${this.state.attentionLine}"
        .error="${this.errors.attentionLine}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderPayerGroups() {
    return html`
      <div class="span-3 container-groups">
        <neb-select
          id="${ELEMENTS.payerGroup.id}"
          class="span-3"
          name="payerGroupId"
          helper=" "
          label="${PAYER_GROUP}"
          .error="${this.errors.payerGroupId}"
          .items="${this.__activePayerGroupItems}"
          .value="${this.state.payerGroupId}"
          .onChange="${this.handlers.change}"
        ></neb-select>
        <neb-button-action
          id="${ELEMENTS.newPayerGroup.id}"
          class="column-three add-payer-button"
          label="Add New Payer Group"
          .onClick="${this.handlers.addNewPayerGroup}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderFinancialClass() {
    return html`
      <div class="span-3 container-groups">
        <neb-select
          id="${ELEMENTS.financialClass.id}"
          class="span-3"
          name="financialClass"
          helper=" "
          label="Financial Classification"
          .error="${this.errors.financialClass}"
          .items="${FINANCIAL_CLASS_ITEMS}"
          .value="${this.state.financialClass}"
          .onChange="${this.handlers.financialClassChanged}"
        ></neb-select>
        <div class="spacer"></div>
      </div>
    `;
  }

  __renderContactName() {
    return html`
      <neb-textfield
        id="${ELEMENTS.contactName.id}"
        class="span-3"
        name="contactName"
        helper=" "
        label="Contact Name"
        maxLength="80"
        .error="${this.errors.contactName}"
        .value="${this.state.contactName}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderPhone() {
    return html`
      <neb-textfield
        id="${ELEMENTS.phone.id}"
        class="span-3"
        name="phone"
        helper=" "
        inputMode="numeric"
        label="Phone"
        maxLength="14"
        .error="${this.errors.phone}"
        .mask="${phone}"
        .value="${this.state.phone}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderExtension() {
    return html`
      <neb-textfield
        id="${ELEMENTS.extension.id}"
        class="span-3"
        name="extension"
        helper=" "
        inputMode="numeric"
        label="Extension"
        maxLength="10"
        .error="${this.errors.extension}"
        .value="${this.state.extension}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderFax() {
    return html`
      <neb-textfield
        id="${ELEMENTS.fax.id}"
        class="span-3"
        label="Fax"
        name="fax"
        .inputMode="${'numeric'}"
        maxLength="14"
        helper=" "
        .mask="${phone}"
        .value="${this.state.fax}"
        .error="${this.errors.fax}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderWebsite() {
    return html`
      <neb-textfield
        id="${ELEMENTS.website.id}"
        class="span-3"
        label="Website"
        name="website"
        maxLength="255"
        helper=" "
        .value="${this.state.website}"
        .error="${this.errors.website}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderGeneralSectionDetails() {
    return html`
      ${this.__renderPayerGroups()}${this.__renderFinancialClass()}
      ${this.__renderAttentionLine()}
      ${this.__renderContactName()}${this.__renderPhone()}${this.__renderExtension()}${this.__renderFax()}${this.__renderWebsite()}
    `;
  }

  __renderGeneralSection() {
    return html`
      <neb-header
        class="section-header"
        .label="${ELEMENTS.generalHeader.title}"
      ></neb-header>

      ${this.__hasAddOnCTInForms
        ? html`
            <neb-switch
              id="${ELEMENTS.ctInFormsSwitch.id}"
              class="padding-left"
              name="informsEnabled"
              label="Include Payer Plan within CT InForms"
              .onChange="${this.handlers.change}"
              ?on="${this.state.informsEnabled}"
            ></neb-switch>
          `
        : ''}

      <div class="grid grid-profile">
        <neb-textfield
          id="${ELEMENTS.alias.id}"
          class="span-3"
          label="Alias"
          name="alias"
          helper="Required"
          maxLength="10"
          .value="${this.state.alias}"
          .error="${this.errors.alias}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.payerName.id}"
          class="span-3"
          label="Name"
          name="payerName"
          maxLength="100"
          helper="Required"
          .value="${this.state.payerName}"
          .error="${this.errors.payerName}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        ${this.__renderGeneralSectionDetails()}

        <neb-address
          id="${ELEMENTS.addressForm.id}"
          class="span"
          name="address"
          .model="${this.state.address}"
          .errors="${this.errors.address}"
          .onChange="${this.handlers.change}"
        ></neb-address>
      </div>
    `;
  }

  __renderTextareaActiveSwitch() {
    return html`
      <div class="subsection bottom-section">
        <neb-textarea
          id="${ELEMENTS.notes.id}"
          name="notes"
          label="Notes"
          rows="4"
          helper=" "
          .value="${this.state.notes}"
          .error="${this.errors.notes}"
          .onChange="${this.handlers.change}"
        ></neb-textarea>

        <neb-switch
          id="${ELEMENTS.status.id}"
          name="status"
          label="Active"
          .onChange="${this.handlers.statusChanged}"
          ?on="${this.state.status === STATUS_TYPE.ACTIVE}"
        ></neb-switch>
      </div>
    `;
  }

  __renderBillingProviderAddressDropdown() {
    const BILLING_PROVIDER_ADDRESS_CONFIG = {
      key: 'billingProviderAddress',
      label: 'Billing Provider Address (FL33)',
      initialValue: selectors.ITEM_EMPTY,
      tip: 'The selected value will populate in FL33 on paper forms and as Billing Provider info for electronic claims.',
      items: [
        selectors.ITEM_EMPTY,
        ...this.__formatLocations(this.activeLocations),
      ],
    };
    return html`
      <neb-select
        class="spacer-bottom"
        id="${ELEMENTS.billingProviderAddress.id}"
        .name="${BILLING_PROVIDER_ADDRESS_CONFIG.key}"
        .label="${BILLING_PROVIDER_ADDRESS_CONFIG.label}"
        .items="${BILLING_PROVIDER_ADDRESS_CONFIG.items}"
        .value="${this.state[BILLING_PROVIDER_ADDRESS_CONFIG.key]}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-tooltip>
        <div slot="tooltip">${BILLING_PROVIDER_ADDRESS_CONFIG.tip}</div>
      </neb-tooltip>
    `;
  }

  __renderBillingProviderAddress() {
    if (
      this.state.billingProviderAddress &&
      this.state.billingProviderAddress.data.id
    ) {
      const location = this.activeLocations.find(
        ({ id }) => this.state.billingProviderAddress.data.id === id,
      );

      if (location) {
        const { address1, address2, city, state, zipcode, zipCode } = location;

        return html`
          <div id="${ELEMENTS.addressDisplay.id}">
            <i>
              ${address1}<br />
              ${address2 ? html` ${address2}<br /> ` : ''}
              ${city ? `${city},` : ''} ${state} ${zipCode || zipcode}
            </i>
          </div>
        `;
      }
    }

    return '';
  }

  __renderClaimFilingColumn() {
    return html`
      <neb-select
        id="${ELEMENTS[ITEMS_FILING[0].key].id}"
        class="spacer-bottom"
        helper=" "
        .name="${ITEMS_FILING[0].key}"
        .label="${ITEMS_FILING[0].label}"
        .items="${CLAIM_FILING_INDICATOR[this.state.financialClass]}"
        .value="${this.state[ITEMS_FILING[0].key]}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-tooltip class="spacer-bottom">
        <div slot="tooltip">${ITEMS_FILING[0].tip}</div>
      </neb-tooltip>

      <neb-select
        id="${ELEMENTS[ITEMS_FILING[1].key].id}"
        class="spacer-bottom"
        helper=" "
        .name="${ITEMS_FILING[1].key}"
        .label="${ITEMS_FILING[1].label}"
        .items="${ITEMS_FILING[1].items}"
        .value="${this.state[ITEMS_FILING[1].key]}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-tooltip class="spacer-bottom">
        <div slot="tooltip">${ITEMS_FILING[1].tip}</div>
      </neb-tooltip>

      ${this.__renderBillingProviderAddressDropdown()}
      ${this.__renderBillingProviderAddress()}
    `;
  }

  __renderClaimOptionsColumn() {
    const claimOptions = ITEMS_OPTIONS;

    return claimOptions.map(
      item => html`
        <neb-checkbox
          id="${ELEMENTS[item.key].id}"
          class="spacer-bottom"
          .name="${item.key}"
          .label="${item.label}"
          .onChange="${this.handlers.change}"
          ?checked="${this.state[item.key]}"
        ></neb-checkbox>
      `,
    );
  }

  __renderProviderSpecificValuesSection() {
    const index = this.__getProviderSpecificValuesIndex();

    return html`
      <neb-header
        id="${ELEMENTS.providerSpecificValuesHeader.id}"
        .label="${ELEMENTS.providerSpecificValuesHeader.title}"
        .description="${ELEMENTS.providerSpecificValuesHeader.description}"
      ></neb-header>

      <div class="bottom-section">
        <div class="grid grid-2">
          <neb-select
            class="grid-tooltip-space"
            id="${ELEMENTS.renderingProvider.id}"
            label="${ELEMENTS.renderingProvider.label}"
            name="renderingProvider"
            helper=" "
            .items="${this.__providers}"
            .value="${this.__selectedRenderingProvider}"
            .onChange="${this.handlers.selectRenderingProvider}"
          ></neb-select>

          <div></div>
        </div>

        ${this.__renderProviderSpecificValueFields(index)}
      </div>
    `;
  }

  __renderProviderSpecificValueFields(index) {
    return html`
      <div>
        <neb-provider-specific-values-fields-list
          id="${ELEMENTS.providerSpecificValues.id}"
          .model="${this.state.providerSpecificValues}"
          .errors="${this.errors.providerSpecificValues}"
          .selectedRenderingProvider="${this.__selectedRenderingProvider}"
          .selectedRenderingProviderIdentifiers="${this
            .__selectedRenderingProviderIdentifiers}"
          .billingInfo="${this.__billingInfo}"
          .activeServiceLocations="${this.__formatLocations(
            this.activeServiceLocations,
          )}"
          .activeLocations="${this.__formatLocations(this.activeLocations)}"
          .onProviderSpecificValuesChanged="${this.handlers
            .providerSpecificValuesChanged}"
          .onProviderSpecificTaxIdValueChanged="${this.handlers
            .providerSpecificTaxIdValueChanged}"
          .onProviderSpecificIdentifierChanged="${this.handlers
            .providerSpecificIdentifierChanged}"
          .onProviderSpecificNPIChanged="${this.handlers
            .providerSpecificNPIChanged}"
          .onLocationChanged="${this.handlers.locationChanged}"
          .onProviderOtherTaxIdTypeChanged="${this.handlers
            .providerOtherTaxIdTypeChanged}"
          .providerIndex="${index}"
          .onAdd="${this.handlers.addProviderSpecificValues}"
          .onRemove="${this.handlers.removeProviderSpecificValues}"
          .providerOtherTaxIds="${this.__providerOtherTaxIds}"
        ></neb-provider-specific-values-fields-list>
      </div>
    `;
  }

  get removeButtonHandlers() {
    return {
      [ELEMENTS.removeAdditionalClaimButton.ref]: idx =>
        this.handlers.removeAdditionalClaim(idx),
      [ELEMENTS.removeEraPayerNameButton.ref]: idx =>
        this.handlers.removeEraPayerName(idx),
    };
  }

  __renderRemoveIcon(elementRef, idx) {
    return html`
      <neb-icon
        id="${elementRef}-${idx}"
        class="icon-remove"
        icon="neb:minus"
        @click="${() => this.removeButtonHandlers[elementRef](idx)}"
      ></neb-icon>
    `;
  }

  __renderAdditionalClaimRows() {
    return this.state.additionalClaimInfos.map(
      (_, idx) => html`
        <div class="grid grid-auto-right">
          <neb-select-search
            id="${ELEMENTS.additionalClaimInformation.id}-${idx}"
            name="additionalClaimInfos.${idx}"
            emptyMessage="No results found"
            .error="${this.errors.additionalClaimInfos[idx]
              .additionalClaimCodeId}"
            .helper="${this.state.additionalClaimInfos.length > 1 ||
            this.state.additionalClaimInfos[0].note.length > 0
              ? 'Required'
              : ' '}"
            .label="${ELEMENTS.additionalClaimInformation.label}"
            .items="${this.__filteredAdditionalClaimInformationItems[idx]}"
            .search="${this.__additionalClaimInformationSearchValues[idx]}"
            .value="${this.__addLabel(this.state.additionalClaimInfos[idx])}"
            .onSearch="${this.handlers.searchAdditionalClaim}"
            .onChange="${this.handlers.changeAdditionalClaim}"
            showSearch
          ></neb-select-search>

          <neb-tooltip>
            <div slot="tooltip">${ELEMENTS.additionalClaimInformation.tip}</div>
          </neb-tooltip>
        </div>

        <div class="grid grid-auto-right">
          <neb-textfield
            id="${ELEMENTS.additionalClaimNote.id}-${idx}"
            name="additionalClaimInfos.${idx}.note"
            label="Additional Claim Information Note"
            helper="${this.state.additionalClaimInfos.length > 1 ||
            this.state.additionalClaimInfos[idx].additionalClaimCodeId
              ? 'Required'
              : ' '}"
            maxLength="71"
            .value="${this.state.additionalClaimInfos[idx].note}"
            .error="${this.errors.additionalClaimInfos[idx].note}"
            .onChange="${this.handlers.changeAdditionalClaimNote}"
          ></neb-textfield>

          ${this.state.additionalClaimInfos.length > 1
            ? this.__renderRemoveIcon(
                ELEMENTS.removeAdditionalClaimButton.ref,
                idx,
              )
            : ''}
        </div>
      `,
    );
  }

  __renderClaimCodes() {
    return html`
      <div class="grid grid-auto-right">
        <neb-textfield
          id="${ELEMENTS.claimCodes.id}"
          label="Claim Codes (FL10d)"
          name="claimCodes"
          helper=" "
          maxLength="30"
          .value="${this.state.claimCodes}"
          .error="${this.errors.claimCodes}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip>
          <div slot="tooltip">${ELEMENTS.claimCodes.tip}</div>
        </neb-tooltip>
      </div>

      <div class="flex-row">
        <neb-checkbox
          id="${ELEMENTS.claimCodeRequired.id}"
          label="Claim Code Required"
          name="claimCodeRequired"
          class="claim-code-required-checkbox"
          .value="${this.state.claimCodeRequired}"
          .checked="${this.state.claimCodeRequired === true}"
          .error="${this.errors.claimCodeRequired}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>

        <neb-tooltip defaultAnchor="top">
          <div slot="tooltip">${ELEMENTS.claimCodeRequired.tip}</div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderWcbCaseNumberOverride() {
    return this.state.financialClass === FINANCIAL_CLASSES.WorkersCompensation
      ? html`
          <div class="checkbox-override">
            <neb-checkbox
              id="${ELEMENTS.wcbCaseNumberOverride.id}"
              label="${ELEMENTS.wcbCaseNumberOverride.label}"
              name="wcbCaseNumberOverride"
              .onChange="${this.handlers.change}"
              ?checked="${this.state.wcbCaseNumberOverride}"
            ></neb-checkbox>
          </div>
        `
      : '';
  }

  __renderOptPayerValuesSection() {
    return html`
      <neb-header
        .label="${ELEMENTS.optPayerValuesHeader.title}"
        .description="${ELEMENTS.optPayerValuesHeader.description}"
      ></neb-header>

      <div class="grid grid-2">
        ${this.__renderAdditionalClaimRows()}

        <neb-button-action
          id="${ELEMENTS.addAdditionalClaimButton.id}"
          label="Add Additional Claim Information"
          .onClick="${this.handlers.addAdditionalClaim}"
        ></neb-button-action>

        <div></div>

        ${this.__renderClaimCodes()}
      </div>

      <div class="checkbox-override">
        <neb-checkbox
          id="${ELEMENTS.fl4MedicareOverride.id}"
          label="${ELEMENTS.fl4MedicareOverride.label}"
          name="fl4MedicareOverride"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.fl4MedicareOverride}"
        ></neb-checkbox>
      </div>
      <div class="checkbox-override">
        <neb-checkbox
          id="${ELEMENTS.fl29AmountPaidOverride.id}"
          label="${ELEMENTS.fl29AmountPaidOverride.label}"
          name="fl29AmountPaidOverride"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.fl29AmountPaidOverride}"
        ></neb-checkbox>
      </div>
      <div class="checkbox-override">
        <neb-checkbox
          id="${ELEMENTS.serviceFacilityOverride.id}"
          label="${ELEMENTS.serviceFacilityOverride.label}"
          name="serviceFacilityOverride"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.serviceFacilityOverride}"
        ></neb-checkbox>
      </div>
      <div class="checkbox-override">
        <neb-checkbox
          id="${ELEMENTS.payerAddressOverride.id}"
          label="${ELEMENTS.payerAddressOverride.label}"
          name="payerAddressOverride"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.payerAddressOverride}"
        ></neb-checkbox>
      </div>

      ${this.__renderWcbCaseNumberOverride()}
    `;
  }

  __renderDefaultSection() {
    return html`
      <neb-header
        class="section-header"
        .label="${ELEMENTS.claimHeader.title}"
        .description="${ELEMENTS.claimHeader.description}"
      ></neb-header>

      <div class="grid grid-2 container-default-section">
        <div class="grid grid-auto-right">
          ${this.__renderClaimFilingColumn()}
        </div>

        <div class="grid claim-options-column">
          ${this.__renderClaimOptionsColumn()}
        </div>
      </div>
    `;
  }

  __renderDesignatedClearinghouse() {
    const helper = isElectronic(this.state) ? 'Required' : ' ';

    this.formService.validateKey(['clearinghouseId']);

    return html`
      <neb-select
        id="${ELEMENTS.selectClearinghouses.id}"
        name="clearinghouseId"
        label="Designated Clearinghouse"
        .helper="${helper}"
        .items="${this.__activeClearinghouseItems}"
        .value="${this.state.clearinghouseId}"
        .error="${this.errors.clearinghouseId}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderClearinghousePayerId() {
    const helper = isElectronic(this.state) ? 'Required' : ' ';

    this.formService.validateKey(['clearinghousePayerId']);

    return html`
      <neb-textfield
        id="${ELEMENTS.clearinghousePayerId.id}"
        name="clearinghousePayerId"
        label="Payer ID"
        .helper="${helper}"
        .value="${this.state.clearinghousePayerId}"
        .error="${this.errors.clearinghousePayerId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderOtherCHCId() {
    const showSecondaryId =
      this.state.clearinghouseId.data.partner === CLEARINGHOUSES.CHC;

    return showSecondaryId
      ? html`
          <neb-textfield
            id="${ELEMENTS.payerId.id}"
            label="Other Payer Identifier"
            name="payerId"
            helper=" "
            .value="${this.state.payerId}"
            .error="${this.errors.payerId}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `
      : '';
  }

  __renderPayerEtin() {
    const showEtin = ETIN_CLEARINGHOUSES.includes(
      this.state.clearinghouseId.data.partner,
    );

    return showEtin
      ? html`
          <neb-textfield
            id="${ELEMENTS.payerEtin.id}"
            label="Payer ETIN"
            name="payerEtin"
            helper=" "
            .value="${this.state.payerEtin}"
            .error="${this.errors.payerEtin}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `
      : '';
  }

  __renderDesignatedClearinghouseItems() {
    return html`
      ${this.__renderDesignatedClearinghouse()}
      ${this.__renderClearinghousePayerId()} ${this.__renderOtherCHCId()}
      ${this.__renderPayerEtin()}
    `;
  }

  __renderSubmissionMethods() {
    const items =
      this.__tier === TIERS.ADVANCED
        ? SUBMISSION_METHODS
        : [SUBMISSION_METHODS[0], SUBMISSION_METHODS[2]];

    return html`
      <neb-header
        class="section-header"
        .label="${ELEMENTS.submissionMethodsHeader.title}"
      ></neb-header>

      <div class="grid grid-2">
        <neb-select
          id="${ELEMENTS.submissionMethodPrimary.id}"
          name="submissionMethodPrimary"
          label="As Primary Payer"
          helper=" "
          .items="${items}"
          .value="${this.state.submissionMethodPrimary}"
          .error="${this.errors.submissionMethodPrimary}"
          .onChange="${this.handlers.changeSubmissionMethod}"
        ></neb-select>

        <neb-select
          id="${ELEMENTS.submissionMethodSecondary.id}"
          name="submissionMethodSecondary"
          label="As Secondary Payer"
          helper=" "
          .items="${items}"
          .value="${this.state.submissionMethodSecondary}"
          .error="${this.errors.submissionMethodSecondary}"
          .onChange="${this.handlers.changeSubmissionMethod}"
        ></neb-select>

        ${this.__renderDesignatedClearinghouseItems()}

        <div class="grid grid-auto-right">
          <neb-textfield
            id="${ELEMENTS.timelyFiling.id}"
            name="timelyFiling"
            label="Timely Filing"
            helper=" "
            .inputMode="${'numeric'}"
            maxLength="3"
            .mask="${number}"
            .value="${this.state.timelyFiling}"
            .error="${this.errors.timelyFiling}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <p>days</p>
        </div>
      </div>
    `;
  }

  renderClaimTab() {
    return html`
      ${this.__renderDefaultSection()} ${this.__renderSubmissionMethods()}
      ${this.__renderOptPayerValuesSection()}
      ${this.__renderProviderSpecificValuesSection()}
    `;
  }

  __renderEraPayerName() {
    return html`
      <neb-header
        class="section-header"
        .label="${ELEMENTS.eraPayerNamesHeader.title}"
        .description="${ELEMENTS.eraPayerNamesHeader.description}"
      ></neb-header>
      <div class="grid grid-2">
        ${map(
          this.state.eraPayerNames,
          (_, i) => html`
            <div class="grid grid-auto-right">
              <neb-textfield
                id="era-payer-names-${i}"
                name="eraPayerNames.${i}"
                label="${ERA_PAYER_NAME}"
                helper=" "
                maxLength="71"
                .value="${this.state.eraPayerNames[i]}"
                .error="${this.errors.eraPayerNames[i]}"
                .onChange="${this.handlers.change}"
              ></neb-textfield>

              ${when(this.state.eraPayerNames.length > 1, () =>
                this.__renderRemoveIcon(
                  ELEMENTS.removeEraPayerNameButton.ref,
                  i,
                ),
              )}
            </div>
            <div>&nbsp;</div>
          `,
        )}
        <neb-button-action
          id="${ELEMENTS.addEraPayerNameButton.id}"
          .label="${ELEMENTS.addEraPayerNameButton.label}"
          .onClick="${this.handlers.addEraPayerName}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderCopyExistingButton() {
    return !this.condensedHeader
      ? html`
          <neb-button
            id="${ELEMENTS.buttonCopyPayer.id}"
            class="button-copy"
            label="Copy Existing Payer"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.copy}"
          ></neb-button>
        `
      : '';
  }

  __renderCondensedHeader() {
    return this.condensedHeader
      ? html`
          <div class="condensed-header">
            <neb-text id="${ELEMENTS.headerTitle.id}" class="header-title"
              >Update Payer Plan</neb-text
            >
            <neb-text
              id="${ELEMENTS.copyButtonLink.id}"
              link
              .onClick="${this.handlers.copy}"
              >Copy Existing Payer</neb-text
            >
          </div>
        `
      : '';
  }

  renderProfileTab() {
    return html`
      ${this.__renderGeneralSection()} ${this.__renderAgreementDetailsSection()}
      ${this.__renderProviderDetailsSection()} ${this.__renderEraPayerName()}
      ${this.__renderTextareaActiveSwitch()}
    `;
  }

  renderRealTimeEligibilityTab() {
    return html`
      <neb-view-real-time-eligibility
        id="${ELEMENTS.realTimeEligibility.id}"
        .errors="${this.errors}"
        .model="${this.state}"
        .hasRteFF="${this.__hasRteFF}"
        .onChange="${this.handlers.change}"
      ></neb-view-real-time-eligibility>
    `;
  }

  renderRulesTab() {
    return html`
      <neb-view-associated-rules
        id="${ELEMENTS.associatedRules.id}"
        .model="${this.rules}"
        .names="${this.names}"
        .onUpdate="${this.handlers.fetchRules}"
      ></neb-view-associated-rules>
    `;
  }

  renderHeader() {
    return html`
      ${this.__renderCondensedHeader()}
      <div class="tab-header">
        <neb-tab-group
          id="${ELEMENTS.tabGroup.id}"
          class="tabs"
          .items="${this.__navItems}"
          .selectedId="${this.__selectedTab}"
          .onSelect="${this.handlers.changeTab}"
        ></neb-tab-group>

        <div>
          ${this.__renderCopyExistingButton()}

          <div class="underline"></div>
        </div>
      </div>
    `;
  }

  renderContent() {
    const item = this.__navItems.find(
      navItem => navItem.id === this.__selectedTab,
    );

    return item ? item.renderer() : html``;
  }
}

customElements.define('neb-form-payer-plan', FormPayerPlan);
