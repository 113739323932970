import {
  NebTreatmentPlanForm,
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../neb-treatment-plan/components/neb-treatment-plan-form';

export const ELEMENTS = { ...BASE_ELEMENTS };

class NebChartingTreatmentPlanDetail extends NebTreatmentPlanForm {}

customElements.define(
  'neb-charting-treatment-plan-detail',
  NebChartingTreatmentPlanDetail,
);
