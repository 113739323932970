import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });
export const headers = { 'Content-Type': 'application/json' };

export const getERAReport = async eraId => {
  try {
    const response = await apiClient.makeRequest({
      method: Method.GET,
      path: '/api/v1/tenants/:tenantId/era/:eraId',
      replacements: { eraId },
      headers,
      cacheKey: `${eraId}-era-report-data`,
    });

    return response.data[0];
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const updateERAReport = (eraId, body) =>
  apiClient.makeRequest({
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/era/:eraId',
    replacements: { eraId },
    headers,
    cacheKey: `${eraId}-era-report-data`,
    body,
  });

export const postERAReportItems = (eraId, body) =>
  apiClient.makeRequest({
    method: Method.PUT,
    headers,
    path: '/api/v1/tenants/:tenantId/era/:eraId/line-items',
    replacements: { eraId },
    body,
    responseType: RESPONSE_TYPE.RAW,
  });

export const resetERAReportItems = ({
  eraId,
  lineItemReportId,
  claimReportId,
}) =>
  apiClient.makeRequest({
    method: Method.PUT,
    headers,
    path: '/api/v1/tenants/:tenantId/era/:eraId/line-items/:lineItemReportId',
    replacements: { eraId, lineItemReportId },
    body: { claimReportId },
    responseType: RESPONSE_TYPE.RAW,
  });

export const manuallyPostLineItems = ({
  body,
  eraId,
  lineItemReportId,
  claimReportId,
}) =>
  apiClient.makeRequest({
    method: Method.PUT,
    headers,
    path: '/api/v1/tenants/:tenantId/era/:eraId/claims/:claimReportId/line-items/:lineItemReportId',
    replacements: { eraId, claimReportId, lineItemReportId },
    body,
    responseType: RESPONSE_TYPE.RAW,
  });

export const manuallyPostClaim = ({ body, eraId, claimReportId }) =>
  apiClient.makeRequest({
    method: Method.PUT,
    headers,
    path: '/api/v1/tenants/:tenantId/era/:eraId/claims/:claimReportId',
    replacements: { eraId, claimReportId },
    body,
    responseType: RESPONSE_TYPE.RAW,
  });
