import '../../../../neb-lit-components/src/components/neb-card';
import '../../../../neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-controller';
import '../../../../neb-lit-components/src/components/patients/ledger/balance/neb-ledger-balance-controller';
import '../../../../neb-lit-components/src/components/patients/ledger/activity/neb-ledger-activity-controller';
import './payment/neb-payments-list-page';

import { openPopup } from '@neb/popup';
import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import {
  createBannerController,
  DISPLAY_ON,
} from '../../../../neb-alert/components/neb-alert-banner-controller';
import * as ledgerStatementApiClient from '../../../../neb-api-client/src/ledger-statement-api-client';
import { fetchMany } from '../../../../neb-api-client/src/patient-cases';
import { getPatientBillingTotals } from '../../../../neb-api-client/src/patient-totals-api-client';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { CARDS } from '../../../../neb-lit-components/src/components/patients/ledger/neb-ledger-cards';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import {
  centsToCurrency,
  formatDollarAmount,
} from '../../../../neb-utils/formatters';
import { DEFAULT_PATIENT_LEDGER_TOTALS } from '../../../../neb-utils/neb-ledger-util';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import { NEBULA_REFRESH_EVENT } from '../../../../neb-utils/neb-refresh';

export const ELEMENTS = {
  ledgerCards: {
    id: 'ledger-cards',
  },
  activityPage: {
    id: 'page-activity',
  },
  balancePage: {
    id: 'page-balance',
  },
  chargesPage: {
    id: 'page-charges',
  },
  paymentsPage: {
    id: 'page-payments',
  },
};

const CARD_HANDLERS = {
  [CARDS.BALANCE]: () => {},
  [CARDS.CHARGES]: patientId =>
    openOverlay(OVERLAY_KEYS.PATIENT_ADD_CHARGE, {
      patientId,
    }),
  [CARDS.PAYMENTS]: (patientId, patient) =>
    openOverlay(OVERLAY_KEYS.ADD_PAYMENT, {
      patientId,
      patient,
    }),
};

export const PATIENT_LEDGER_ERROR_BANNER =
  'An error occurred while fetching patient totals';

const NO_CASE_ITEM = {
  data: { id: 'null' },
  label: 'No Case Assigned',
};

class NebPatientLedgerController extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      route: {
        type: String,
      },
      patient: {
        type: Object,
      },
      __selectedCard: {
        type: String,
      },
      __totals: {
        type: Object,
      },
      __cases: {
        type: Array,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__alertBanner = createBannerController(DISPLAY_ON.ledger);
    this.__navItems = this.genNavItems();
    this.__selectedCard = CARDS.ACTIVITY;
    this.__totals = DEFAULT_PATIENT_LEDGER_TOTALS;
    this.__currentPatientId = null;
    this.__cases = [];
    this.route = '';
    this.patient = {
      id: '',
      name: '',
    };
  }

  __initHandlers() {
    this.__handlers = {
      addPatientPayment: async () => {
        await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
          patientId: this.patient.id,
          patient: this.patient,
        });

        this.__getPatientTotals();
      },
      ledgerButtonClicked: async cardType => {
        const cardHandler = CARD_HANDLERS[cardType];

        if (cardType === CARDS.PAYMENTS) {
          await cardHandler(this.patient.id, this.patient);
        } else {
          await cardHandler(this.patient.id);
        }

        return this.__handleAddCardType(cardType);
      },
      selectCard: card => navigate(this.__buildRoute(card)),
      refresh: () => this.__loadData(),
    };
  }

  async __handleAddCardType(cardType) {
    if (cardType === CARDS.PAYMENTS && this.__elements.paymentsPage) {
      this.__elements.paymentsPage.service.fetch();
    }

    if (
      (cardType === CARDS.CHARGES || cardType === CARDS.PAYMENTS) &&
      this.__elements.chargesPage
    ) {
      this.__elements.chargesPage.fetch();
    }

    if (
      (cardType === CARDS.CHARGES || cardType === CARDS.PAYMENTS) &&
      this.__elements.activityPage
    ) {
      this.__elements.activityPage.fetch();

      if (this.__elements.activityPage.getRunningLedgerPage()) {
        this.__elements.activityPage.refetchLedgerItems();
      }

      if (this.__elements.activityPage.getTransactionsPage()) {
        this.__elements.activityPage.refetchTransactionItems();
      }
    }

    if (
      (cardType === CARDS.CHARGES || cardType === CARDS.PAYMENTS) &&
      this.__elements.balancePage
    ) {
      this.__elements.balancePage.fetch();
    }

    if (cardType === CARDS.BALANCE) {
      await this.__handlePrintLastStatement();
    }

    return this.__getPatientTotals();
  }

  async __handlePrintLastStatement() {
    if (this.__totals.balance && this.__totals.balance.lastStatementId) {
      await printPdf(
        ledgerStatementApiClient.fetchOne(
          this.__totals.balance.lastStatementId,
        ),
      );
    } else {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Print Last Statement',
        message: 'There are no statements available for this patient to print.',
      });
    }
  }

  __buildRoute(card) {
    return `#/patients/${this.patient.id}/ledger/${card}`;
  }

  __getRouteTail() {
    return this.route.split('/')[1];
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();

    return this.layout === 'large'
      ? !!this.__navItems.find(navItem => navItem.card === routeTail)
      : routeTail === '';
  }

  connectedCallback() {
    super.connectedCallback();

    this.__alertBanner.connect();
    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
  }

  disconnectedCallback() {
    this.__alertBanner.disconnect();
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);

    super.disconnectedCallback();
  }

  __loadData() {
    this.__getPatientTotals();
    this.__getCases();
  }

  async __getPatientTotals() {
    try {
      this.__totals = await getPatientBillingTotals(this.patient.id);
    } catch (e) {
      console.error(e);
      store.dispatch(openError(PATIENT_LEDGER_ERROR_BANNER));
      this.__totals = { ...DEFAULT_PATIENT_LEDGER_TOTALS };
    }
  }

  async __getCases() {
    if (this.patient && this.patient.id) {
      const cases = await fetchMany(this.patient.id, true);

      this.__cases = [
        NO_CASE_ITEM,
        ...cases.map(data => ({
          data,
          label: `${data.name} (${data.active ? 'Active' : 'Inactive'})`,
        })),
      ];
    }
  }

  __buildCardValues() {
    const {
      payments: unallocated = 0,
      charges: { open = 0, responsible = 0 } = {},
      balance: {
        patientOwed = 0,
        patientPaid = 0,
        payerOwed = 0,
        payerPaid = 0,
      } = {},
      activity: { encountersNotBalanced = 0, numberOfPurchases = 0 } = {},
    } = this.__totals;

    return [
      {
        encountersNotBalanced,
        numberOfPurchases,
      },
      {
        open: centsToCurrency(open),
        responsible: centsToCurrency(responsible),
      },
      {
        unallocated: centsToCurrency(unallocated),
      },
      {
        payers: formatDollarAmount(payerOwed - payerPaid),
        patient: formatDollarAmount(patientOwed - patientPaid),
      },
    ];
  }

  update(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let card = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        card = this.layout === 'large' ? CARDS.ACTIVITY : '';
        redirect(this.__buildRoute(card));
      }

      this.__selectedCard = card;
    }

    if (
      changedProps.has('patient') &&
      this.patient &&
      this.__currentPatientId !== this.patient.id
    ) {
      this.__currentPatientId = this.patient.id;
      this.__loadData();

      this.__alertBanner.update(this.patient.id);
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    this.__elements = {
      chargesPage: this.shadowRoot.getElementById(ELEMENTS.chargesPage.id),
      paymentsPage: this.shadowRoot.getElementById(ELEMENTS.paymentsPage.id),
      balancePage: this.shadowRoot.getElementById(ELEMENTS.balancePage.id),
      activityPage: this.shadowRoot.getElementById(ELEMENTS.activityPage.id),
    };

    if (changedProps.has('patient') && this.patient) {
      if (this.__elements.paymentsPage) {
        this.__elements.paymentsPage.service.fetch();
      }

      if (this.__elements.chargesPage) {
        this.__elements.chargesPage.fetch();
      }

      if (this.__elements.balancePage) {
        this.__elements.balancePage.fetch();
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .header {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: fit-content;
          min-height: 250px;

          padding: ${CSS_SPACING} ${CSS_SPACING} 12px ${CSS_SPACING};
        }

        .header-text {
          display: flex;
          width: 100%;
          height: fit-content;

          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  genNavItems() {
    return [
      {
        card: CARDS.ACTIVITY,
        exact: false,
        path: '/activity/',
        resolver: () => html`
          <neb-ledger-activity-controller
            id="${ELEMENTS.activityPage.id}"
            .patient="${this.patient}"
            .route="${this.route}"
            .layout="${this.layout}"
            .cases="${this.__cases}"
            .onReloadData="${this.__handlers.refresh}"
          ></neb-ledger-activity-controller>
        `,
      },
      {
        card: CARDS.BALANCE,
        exact: false,
        path: '/balance/',
        resolver: () =>
          this.patient && this.patient.id
            ? html`
                <neb-ledger-balance-controller
                  id="${ELEMENTS.balancePage.id}"
                  .patient="${this.patient}"
                  .layout="${this.layout}"
                  .route="${this.route}"
                  .cases="${this.__cases}"
                  .balance="${this.__totals.balance}"
                  .onChange="${this.__handlers.refresh}"
                ></neb-ledger-balance-controller>
              `
            : '',
      },
      {
        card: CARDS.CHARGES,
        exact: false,
        path: '/charges/',
        resolver: () => html`
          <neb-ledger-charges-controller
            id="${ELEMENTS.chargesPage.id}"
            .patient="${this.patient}"
            .cases="${this.__cases}"
            .layout="${this.layout}"
            .route="${this.route}"
            .onChange="${this.__handlers.refresh}"
          ></neb-ledger-charges-controller>
        `,
      },
      {
        card: CARDS.PAYMENTS,
        exact: false,
        path: '/payments/',
        resolver: () => html`
          <neb-payments-list-page
            id="${ELEMENTS.paymentsPage.id}"
            .patientId="${this.patient.id}"
            .layout="${this.layout}"
            .onPaymentAction="${this.__handlers.refresh}"
          ></neb-payments-list-page>
        `,
      },
    ];
  }

  render() {
    return html`
      <div class="header">
        <div class="header-text">Patient Totals</div>
        <neb-ledger-cards
          id="${ELEMENTS.ledgerCards.id}"
          .patient="${this.patient}"
          .cardValues="${this.__buildCardValues()}"
          .selectedCard="${this.__selectedCard}"
          .onCardSelect="${this.__handlers.selectCard}"
          .onButtonClick="${this.__handlers.ledgerButtonClicked}"
          .layout="${this.layout}"
          .onAddPatientPayment="${this.__handlers.addPatientPayment}"
          ?disableCardClick="${this.layout !== 'large'}"
          ?loading="${this.__totals === DEFAULT_PATIENT_LEDGER_TOTALS}"
        ></neb-ledger-cards>
      </div>

      ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-patient-ledger-controller',
  NebPatientLedgerController,
);
