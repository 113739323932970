import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { getEncounterHistory } from '../../../../neb-api-client/src/encounters-api-client';
import { getPracticeUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import { FULL_DATE_FORMAT } from '../../../../neb-input/nebFormatUtils';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { FORMATS, parseDate } from '../../../../neb-utils/date-util';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  iconStatus: {
    id: 'icon-status',
  },
  labelStatus: {
    id: 'label-status',
  },
  link: {
    id: 'link',
  },
};
export const ENCOUNTER_STATUS_HEADER = 'Encounter Status';
const ENCOUNTER_STATUS_LINK = 'View Encounter Summary';
const RENDER_STATUS = {
  signed: {
    icon: 'neb:signed',
    label: 'Signed',
  },
  unsigned: {
    icon: 'neb:unsigned',
    label: 'Created',
  },
  reopened: {
    label: 'Reopened',
  },
  selfCheckIn: {
    label: 'Created via online check in',
  },
};

class NebEncounterStatus extends LitElement {
  static get properties() {
    return {
      __practiceUsers: {
        type: Array,
      },
      model: {
        type: Object,
      },
      __encounterStatus: {
        type: String,
      },
      selfCheckIn: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__practiceUsers = [];
    this.model = null;
    this.__encounterStatus = '';
    this.selfCheckIn = false;

    this.onClickLink = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      link: () => this.onClickLink(),
    };
  }

  __getAuthor() {
    const { openedBy, signerId } = this.model;

    let name;

    if (this.model.signed) {
      ({ name } = this.__practiceUsers.find(u => u.id === signerId) || {
        name: null,
      });
    } else {
      ({ name } = this.__practiceUsers.find(u => u.id === openedBy) || {
        name: null,
      });
    }

    return name
      ? ` by ${objToName(name, DEFAULT_NAME_OPTS)}
        `
      : '';
  }

  __getDate() {
    if (this.model.signed && this.model.signedOn) {
      return parseDate(this.model.signedOn).format(FULL_DATE_FORMAT);
    }

    if (this.model.createdAt !== this.model.updatedAt) {
      return parseDate(this.model.updatedAt).format(FULL_DATE_FORMAT);
    }

    return parseDate(this.model.createdAt).format(FULL_DATE_FORMAT);
  }

  __getIcon() {
    if (this.model.signed && this.model.signedOn) {
      return RENDER_STATUS.signed.icon;
    }

    return RENDER_STATUS.unsigned.icon;
  }

  __isSelfCheckIn() {
    return this.selfCheckIn && this.model.openedBy
      ? this.model.openedBy === this.model.patientId
      : false;
  }

  __getStatus() {
    if (this.model.signed && this.model.signedOn) {
      return RENDER_STATUS.signed.label;
    }

    if (this.__isSelfCheckIn()) {
      return RENDER_STATUS.selfCheckIn.label;
    }

    if (this.__encounterStatus.length) {
      return RENDER_STATUS.reopened.label;
    }

    return RENDER_STATUS.unsigned.label;
  }

  __getTime() {
    if (this.model.signed && this.model.signedOn) {
      return parseDate(this.model.signedOn).format(FORMATS.timeAmPm);
    }

    if (this.model.createdAt !== this.model.updatedAt) {
      return parseDate(this.model.updatedAt).format(FORMATS.timeAmPm);
    }

    return parseDate(this.model.createdAt).format(FORMATS.timeAmPm);
  }

  async __fetchUsers() {
    this.__practiceUsers = await getPracticeUsers();
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      this.__fetchUsers();
    }

    if (!this.__encounterStatus) {
      this.__encounterStatus = await getEncounterHistory(this.model.id);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .label-header {
          margin-bottom: 15px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .container-status {
          display: flex;
          align-items: center;

          margin-bottom: 15px;
        }

        .icon-status {
          display: flex;
          width: 24px;
          height: 24px;

          margin-right: 10px;
        }

        .link {
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }
      `,
    ];
  }

  __renderEncounterSummaryLink() {
    return html`
      <div
        id="${ELEMENTS.link.id}"
        class="link"
        @click="${this.__handlers.link}"
      >
        ${ENCOUNTER_STATUS_LINK}
      </div>
    `;
  }

  __renderContent() {
    const icon = this.__getIcon();

    return html`
      <div class="label label-header">${ENCOUNTER_STATUS_HEADER}</div>

      <div class="container-status">
        ${icon
          ? html`
              <neb-icon
                id="${ELEMENTS.iconStatus.id}"
                class="icon icon-status"
                .icon="${icon}"
              ></neb-icon>
            `
          : ''}
        <span id="${ELEMENTS.labelStatus.id}" class="label label-status">
          ${`${this.__getStatus()}${this.__getAuthor()} on ${this.__getDate()} at ${this.__getTime()}`}
        </span>
      </div>

      ${this.__renderEncounterSummaryLink()}
    `;
  }

  render() {
    return this.model && this.__practiceUsers.length
      ? html` <div class="container">${this.__renderContent()}</div> `
      : html``;
  }
}

customElements.define('neb-encounter-status', NebEncounterStatus);
