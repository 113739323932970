export const _fetching = (state, _action) => ({
  ...state,
  fetching: true,
  fetchError: null,
});
export const _fetched = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  fetchError: null,
  stale: false,
  items: action.res.data.map(item => item.id),
});
export const _fetchError = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  fetchError: action.fetchError,
  items: [],
});
