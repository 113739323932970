import '../neb-loading-overlay';
import '../forms/neb-form-patient';
import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import {
  BASE_CATEGORIES,
  BASE_CATEGORY_SOURCE_TYPES,
  getReferralCategories,
  getReferralCategoryById,
} from '../../../../../src/api-clients/referrals-api-client';
import { formatReferralSources } from '../../../../../src/formatters/referral-sources';
import {
  PATIENT_WARNING_WITH_SAME_MRN,
  PATIENT_CONFIRM_UPDATE_OR_CREATE,
  PATIENT_WARNING_DUPLICATE_LAST_AND_DOB,
  PATIENT_UNABLE_TO_SAVE,
  PATIENT_PROFILE_SAVED_SUCCESS,
} from '../../../../../src/utils/user-message';
import { getNextMRN } from '../../../../neb-api-client/src/patient-api-client';
import {
  upsertPatientImage,
  deletePatientImage,
} from '../../../../neb-api-client/src/patient-image-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import {
  getActiveProviderUsers,
  getPracticeUsers,
} from '../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { connect, store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  formatPhoneNumber,
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../../neb-utils/formatters';
import {
  createModel,
  fetchPatient,
  mapToPatientModel,
} from '../../../../neb-utils/patient';
import { addPatient, savePatient } from '../../utils/patients';

export const ELEMENTS = {
  form: { id: 'form' },
  loadingOverlay: { id: 'overlay-loading' },
};

const INIT_PHONE = [
  {
    type: '',
    number: '',
  },
];

export const UPDATE_PINS_CONFIG = {
  title: 'Assign same PIN to Relationships?',
  confirmText: 'Yes',
  cancelText: 'No',
  message: html`
    <p>
      Would you like to assign this Self Check In PIN to
      <span style="font-weight:bold">all</span> patients associated in the
      relationship?
    </p>
  `,
};

export const DUPLICATE_PIN_CONFIG = {
  title: 'Duplicate PIN',
  message:
    'This PIN is already assigned to another patient. Please enter a different PIN.',
};

class NebPatientFormController extends connect(store)(LitElement) {
  static get properties() {
    return {
      __saving: Boolean,
      __session: Object,
      __model: Object,
      __initialModel: Object,
      __locations: Array,
      __activeLocations: Array,
      __referralCategories: Array,
      __referralCategorySources: Array,
      __referralCategorySource: Object,

      patient: Object,
      slim: { reflect: true, type: Boolean },
      layout: { reflect: true, type: String },
      providers: Array,
      restrictedView: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .form {
          width: 100%;
          height: 100%;
        }

        :host([slim]) .form,
        :host([layout='small']) .form {
          margin: 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__session = null;
    this.__model = null;
    this.__initialModel = null;
    this.__locations = [];
    this.__activeLocations = [];
    this.__referralCategories = [];
    this.__referralCategorySources = [];
    this.__referralCategorySource = null;

    this.slim = false;
    this.layout = '';
    this.patient = createModel();
    this.providers = [];
    this.successBanner = PATIENT_PROFILE_SAVED_SUCCESS();
    this.restrictedView = false;

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onChangeDirty = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      invalidPhoto: err => store.dispatch(openError(err)),
      cancel: () => this.onCancel(),
      save: model => this.__savePatient(model),
      dirty: dirty => this.onChangeDirty(dirty),
      onChangeReferralCategorySource: item => {
        this.__referralCategorySource = item;
      },
      onFetchCategorySources: ({ sourceType, categoryId }) => {
        this.__fetchReferralCategorySource({ sourceType, categoryId });
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ activeLocations, locations }) => {
        this.__locations = this.__formatLocations(locations);
        this.__activeLocations = this.__formatLocations(activeLocations);
      },
    );
  }

  async __fetchReferralCategorySource({ sourceId, sourceType, categoryId }) {
    const isBaseSourceType = BASE_CATEGORY_SOURCE_TYPES.includes(sourceType);

    let sources;
    let formattedSources;

    switch (true) {
      case sourceType === BASE_CATEGORIES.patient.name:
        this.__referralCategorySource = sourceId
          ? await fetchPatient(sourceId)
          : null;

        break;
      case sourceType === BASE_CATEGORIES.staff.name:
        sources = await getPracticeUsers();

        this.__referralCategorySources = sources.map(item => ({
          label: objToName(item.name, DEFAULT_NAME_OPTS),
          data: item,
        }));

        if (sourceId) {
          this.__referralCategorySource = this.__referralCategorySources.find(
            x => x.data.id === sourceId,
          );
        }

        break;
      case sourceType && !isBaseSourceType:
        sources = await getReferralCategoryById(categoryId);

        formattedSources = formatReferralSources(sources.sources);

        this.__referralCategorySources = formattedSources.map(item => ({
          label: item.name,
          data: item,
        }));

        if (sourceId) {
          this.__referralCategorySource = this.__referralCategorySources.find(
            x => x.data.id === sourceId,
          );
        }

        break;
      default:
    }
  }

  async connectedCallback() {
    const referralCategories = await getReferralCategories();

    this.__referralCategories = referralCategories.map(item => ({
      label: item.name,
      data: item,
    }));

    this.__locationsService.connect();
    super.connectedCallback();
  }

  async disconnectedCallback() {
    await super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  _stateChanged({ session }) {
    this.__session = session.item || null;
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      item: location,
      label: location.name,
    }));
  }

  async __savePatient(model, force = false) {
    const result = this.__toPatient(model);
    result.updateAllPINs = false;

    let relationshipIds = [];

    if (
      result.id &&
      result.selfCheckInPIN !== this.__initialModel.selfCheckInPIN
    ) {
      const data = await getPatientRelationshipsActiveGroup(this.patient.id);

      if (data && data.related && data.related.length) {
        const isPrimary = data.primary === this.patient.id;

        relationshipIds = isPrimary
          ? data.related
          : [data.primary, ...data.related.filter(r => r !== this.patient.id)];

        result.relationshipIds = relationshipIds;

        const updatePINs = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          UPDATE_PINS_CONFIG,
        );

        if (updatePINs) {
          result.updateAllPINs = true;
        }

        if (updatePINs === undefined) {
          return;
        }
      }
    }

    this.__saving = true;

    try {
      const payload = result.id
        ? await savePatient(result, { force })
        : await addPatient(result, { force });

      if (payload.error) {
        const patientError = new Error(payload.error.message);
        patientError.statusCode = payload.error.statusCode;
        patientError.model = model;
        throw patientError;
      }

      const patient = mapToPatientModel(payload, 0, model.photoSrc);

      if (model.photoSrc !== this.__initialModel.photoSrc) {
        if (model.photoSrc) {
          await upsertPatientImage(patient.id, model.photoSrc);
        } else {
          await deletePatientImage(patient.id);
        }
      }

      this.onChangeDirty(false);
      this.onSave(patient);
      store.dispatch(openSuccess(this.successBanner));
    } catch (err) {
      if (err.statusCode === 409) {
        if (await this.__handleDuplicate(err, model)) {
          this.__savePatient(model, true);
        }
      } else store.dispatch(openError(PATIENT_UNABLE_TO_SAVE()));
    }

    this.__saving = false;
  }

  async __handleDuplicate({ message }, item) {
    let accept = false;

    if (message.includes('MRN')) return this.__duplicateMRN();

    if (message.includes('SSN')) {
      accept =
        this.__model.ssn === item.ssn
          ? true
          : await this.__duplicateSSN(item.id);

      if (!accept) {
        return false;
      }
    }

    if (message.includes('dob')) {
      accept =
        this.__model.name.last === item.name.last &&
        this.__model.dateOfBirth === item.dateOfBirth
          ? true
          : await this.__duplicateModel(item.id);
    }

    if (message.includes('PIN')) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, DUPLICATE_PIN_CONFIG);
      return false;
    }

    return accept;
  }

  async __duplicateMRN() {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Duplicate MRN',
      message: PATIENT_WARNING_WITH_SAME_MRN(),
    });

    this.shadowRoot.getElementById(ELEMENTS.form.id).focusMedicalRecordNumber();

    return false;
  }

  async __duplicateSSN(id = '') {
    const action = id ? 'update' : 'create';

    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Duplicate SSN',
      message: html`
        <p>
          There is another patient in the system with the same Social Security
          Number as this patient.
        </p>
        <p>${PATIENT_CONFIRM_UPDATE_OR_CREATE(action)}</p>
      `,
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (accepted) {
      return true;
    }

    this.shadowRoot
      .getElementById(ELEMENTS.form.id)
      .focusSocialSecurityNumber();

    return false;
  }

  __duplicateModel(id = '') {
    const action = id ? 'update' : 'create';

    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Potential Duplicate Patient',
      message: html`
        <p>${PATIENT_WARNING_DUPLICATE_LAST_AND_DOB()}</p>
        <p>${PATIENT_CONFIRM_UPDATE_OR_CREATE(action)}</p>
      `,
      confirmText: 'Yes',
      cancelText: 'No',
    });
  }

  __toPatient({ dateOfBirth, address, ...model }) {
    return {
      ...model,
      dateOfBirth: moment(dateOfBirth, 'MM/DD/YYYY'),
      addresses: [address],
      statuses: {
        ...model.statuses,
        patient: model.statuses.patient,
        deceased:
          model.statuses.patient === 'Active' && model.statuses.deceased
            ? false
            : model.statuses.deceased,
        dateOfDeath:
          model.statuses.patient === 'Active' && model.statuses.dateOfDeath
            ? ''
            : model.statuses.dateOfDeath,
      },
    };
  }

  __formatPhoneNumbers(phones) {
    return phones.map(({ type, number }) => ({
      type,
      number: formatPhoneNumber(number),
    }));
  }

  __resetModel(key) {
    const dateOfBirth = this.patient.dateOfBirth
      ? moment(this.patient.dateOfBirth).format('MM/DD/YYYY')
      : '';
    const emailAddresses = this.patient.emailAddresses.length
      ? [...this.patient.emailAddresses]
      : [''];
    const phoneNumbers = this.patient.phoneNumbers.length
      ? this.__formatPhoneNumbers(this.patient.phoneNumbers)
      : INIT_PHONE;
    const address = this.patient.addresses[0]
      ? { ...this.patient.addresses[0] }
      : { address1: '', address2: '', city: '', state: '', zipcode: '' };

    this[key] = {
      id: this.patient.id,
      tenantId: this.patient.tenantId,
      bookingAccountId: this.patient.bookingAccountId,
      medicalRecordNumber: this.patient.medicalRecordNumber,
      billType: this.patient.billType,
      caseBillTypeOverride: this.patient.caseBillTypeOverride,
      insurance: this.patient.insurance,
      sex: this.patient.sex,
      photoSrc: this.patient.photoSrc,
      ssn: this.patient.ssn,
      dateOfBirth,
      emailAddresses,
      phoneNumbers,
      address,
      name: { ...this.patient.name },
      statuses: { ...this.patient.statuses },
      preferredProviderId: this.patient.preferredProviderId,
      preferredLocationId: this.patient.preferredLocationId,
      previousPatient: this.patient.previousPatient,
      selfCheckInPIN: this.patient.selfCheckInPIN,
      patientReferralSourceId: this.patient.patientReferralSourceId,
      referralSource: { ...this.patient.referralSource },
    };
  }

  __updatePatient() {
    this.__resetModel('__initialModel');

    this.__resetModel('__model');
  }

  update(changedProps) {
    if (changedProps.has('patient')) {
      this.__updatePatient();
    }

    super.update(changedProps);
  }

  async firstUpdated(changed) {
    if (
      changed.has('__model') &&
      !this.__model.id &&
      !this.__model.medicalRecordNumber
    ) {
      const { medicalRecordNumber } = (await getNextMRN())[0];
      this.__initialModel = { ...this.__model, medicalRecordNumber };
      this.__model = { ...this.__initialModel };
    }

    if (changed.has('__model') && this.__model.patientReferralSourceId) {
      const { sourceId, sourceType, categoryId } = this.__model.referralSource;

      this.__fetchReferralCategorySource({ sourceId, sourceType, categoryId });
    }

    this.providers = (await getActiveProviderUsers()).map(provider => ({
      item: provider,
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
    }));
  }

  render() {
    return html`
      <div class="container">
        <neb-form-patient
          id="${ELEMENTS.form.id}"
          class="form"
          .layout="${this.layout}"
          .model="${this.__model}"
          .onSave="${this.__handlers.save}"
          .onCancel="${this.__handlers.cancel}"
          .onInvalidPhoto="${this.__handlers.invalidPhoto}"
          .onChangeDirty="${this.__handlers.dirty}"
          .providers="${this.providers}"
          .locations="${this.__locations}"
          .activeLocations="${this.__activeLocations}"
          .referralCategories="${this.__referralCategories}"
          .referralCategorySources="${this.__referralCategorySources}"
          .referralCategorySource="${this.__referralCategorySource}"
          .onChangeReferralCategorySource="${this.__handlers
            .onChangeReferralCategorySource}"
          .onFetchCategorySources="${this.__handlers.onFetchCategorySources}"
          .showPatientStatus="${true}"
          ?slim="${this.slim}"
          ?restrictedView="${this.restrictedView}"
        ></neb-form-patient>

        <neb-loading-overlay
          id="${ELEMENTS.loadingOverlay.id}"
          title="Saving Patient"
          showDelay="0"
          .show="${this.__saving}"
        ></neb-loading-overlay>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-form-controller',
  NebPatientFormController,
);
