import '../neb-action-bar';
import '../neb-progress-blocker';

import FormService from '@neb/form-service';
import equal from 'fast-deep-equal';
import { html } from 'lit';

import { map } from '../../../../neb-utils/utils';
import { NebLayout } from '../neb-layout';

export const ELEMENTS = {
  loadingBlocker: { id: 'blocker-loading', tag: 'neb-progress-blocker' },
  saveBlocker: { id: 'blocker-save', tag: 'neb-progress-blocker' },
  actionBar: { id: 'action-bar', tag: 'neb-action-bar' },
};

export default class NebForm extends NebLayout {
  static get properties() {
    return {
      __dirty: Boolean,
      __saving: Boolean,
      __sideLoading: Boolean,
      __state: Object,
      __errors: Object,
      __pristine: Object,

      alwaysRenderActionBar: Boolean,
      saveBlockerLabel: String,
      confirmLabel: String,
      cancelLabel: String,
      removeLabel: String,
      model: Object,
      savingError: Object,
      enableSaveAndClose: {
        type: Boolean,
        reflect: true,
        attribute: 'enable-save-and-close',
      },
      disableSaveIndicator: {
        type: Boolean,
        reflect: true,
        attribute: 'disable-save-indicator',
      },
      loading: {
        reflect: true,
        type: Boolean,
      },
      actionBarHideBorderTop: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get empty() {
    return this.createModel();
  }

  static createModel() {
    throw new Error('createModel() not implemented');
  }

  set state(_value) {
    throw new Error(
      'Cannot mutate state directly. Did you mean to use this.formService.apply()?',
    );
  }

  get state() {
    return this.__state;
  }

  set errors(_value) {
    throw new Error('Cannot mutate directly. Managed by formService.');
  }

  get errors() {
    return this.__errors;
  }

  set pristine(_value) {
    throw new Error('Cannot mutate directly. Managed by formService.');
  }

  get pristine() {
    return this.__pristine;
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
    this.build();
  }

  initState() {
    super.initState();

    this.__dirty = false;
    this.__saving = false;
    this.__sideLoading = true;
    this.__state = {};
    this.__errors = {};
    this.__pristine = {};

    this.loading = false;
    this.saveBlockerLabel = 'Saving';
    this.confirmLabel = 'Save';
    this.cancelLabel = 'Cancel';
    this.removeLabel = 'Remove';

    this.model = this.constructor.createModel();

    this.savingError = null;
    this.enableSaveAndClose = false;
    this.disableSaveIndicator = false;
    this.actionBarHideBorderTop = false;
    this.alwaysRenderActionBar = false;

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onChangeDirty = () => {};

    this.onError = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: e => this.formService.apply(e.name, e.value),
      addItem: (name, index = -1) => this.formService.addItem(name, index),
      removeItem: (name, index) => this.formService.removeItem(name, index),
      moveItem: (name, fromIndex, toIndex) =>
        this.formService.moveItem(name, fromIndex, toIndex),
      swapItems: (name, index1, index2) =>
        this.formService.swapItems(name, index1, index2),
      cancel: () => this.onCancel(),
      save: (...args) => this.save(...args),
      saveAndClose: (...args) => this.save({ ...args, closeAfterSave: true }),
    };
  }

  save(...args) {
    if (this.formService.validate()) {
      const rawModel = this.formService.build();
      const model = map(rawModel, (keyPath, value) =>
        typeof value === 'string' ? value.trim() : value,
      );

      this.__saving = true;
      return this.onSave(model, ...args);
    }
    return this.onError();
  }

  async load() {
    await Promise.resolve();
  }

  async reload() {
    this.__sideLoading = true;

    try {
      await this.load();
      this.build();
    } finally {
      this.__sideLoading = false;
    }
  }

  build() {
    this.formService = new FormService(
      this.model,
      this.createSelectors(),
      (dirty, state, errors, pristine) => {
        this.__dirty = dirty;
        this.__state = state;
        this.__errors = errors;
        this.__pristine = pristine;
      },
    );
  }

  createSelectors() {
    throw new Error('createSelectors() not implemented');
  }

  isLoaded() {
    return !this.loading && !this.__sideLoading;
  }

  isAdding() {
    return equal(this.model, this.constructor.empty);
  }

  isEditing() {
    return !equal(this.model, this.constructor.empty);
  }

  async firstUpdated() {
    await this.reload();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.build();
      this.__saving = false;
    }

    if (changedProps.has('__dirty')) {
      this.onChangeDirty(this.__dirty);
    }

    if (changedProps.has('savingError') && this.savingError) {
      this.__saving = false;
    }

    super.update(changedProps);
  }

  renderSaveBlocker() {
    return !this.disableSaveIndicator && this.__saving
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.saveBlocker.id}"
            .label="${this.saveBlockerLabel}"
          ></neb-progress-blocker>
        `
      : '';
  }

  renderLoadingBlocker() {
    return !this.isLoaded()
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.loadingBlocker.id}"
            label="Loading"
          ></neb-progress-blocker>
        `
      : '';
  }

  __renderActionBar() {
    return this.enableSaveAndClose
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.saveAndClose}"
            .onRemove="${this.handlers.cancel}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            removeLabel="${this.removeLabel}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            ?hideBorderTop="${this.actionBarHideBorderTop}"
          ></neb-action-bar>
        `;
  }

  renderActionBar() {
    return this.__dirty || this.alwaysRenderActionBar
      ? this.__renderActionBar()
      : '';
  }

  renderFooter() {
    return html`
      ${this.renderActionBar()} ${this.renderSaveBlocker()}
      ${this.renderLoadingBlocker()}
    `;
  }
}

window.customElements.define('neb-form', NebForm);
