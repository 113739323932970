import '../../../neb-patient-panel';

import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  visitLimitPanel: {
    id: 'visit-limit-panel',
  },
  maxVisitsText: {
    id: 'max-visits-text',
  },
  maxVisitsValue: {
    id: 'max-visits-value',
  },
  currentVisitsText: {
    id: 'current-visits-text',
  },
  currentVisitsValue: {
    id: 'current-visits-value',
  },
  visitsRenderedOutOfOfficeText: {
    id: 'visits-rendered-out-of-office-text',
  },
  visitsRenderedOutOfOfficeValue: {
    id: 'visits-rendered-out-of-office-value',
  },
  asOfText: {
    id: 'as-of-text',
  },
  asOfValue: {
    id: 'as-of-value',
  },
  totalRemainingText: {
    id: 'total-remaining-text',
  },
  totalRemainingValue: {
    id: 'total-remaining-value',
  },
  noContentText: {
    id: 'no-content-text',
  },
  title: {
    id: 'title',
  },
};

const NO_CONTENT_TEXT = 'There are no visit limits for this plan.';
const PANEL_TITLE = 'Visit Limits';
const TOTAL_REMAINING_TITLE = 'Total Remaining';
const AS_OF_TITLE = 'As of';
const RENDERED_OUTSIDE_PRACTICE_TITLE = 'Rendered Outside Practice';
const RENDERED_AT_THIS_PRACTICE_TITLE = 'Rendered at this Practice';
const ANNUAL_VISIT_LIMIT_TITLE = 'Annual Visit Limit';

class NebPatientInsuranceVisitLimitCoverageDetails extends LitElement {
  static get properties() {
    return {
      visitLimit: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.visitLimit = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .panel {
          border-style: solid;
          border-width: 1px;
          border-color: ${CSS_COLOR_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 10px 0 14px;
          height: 100%;
        }

        .visit-limit-container {
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .visit-limit-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .italic {
          font-style: italic;
        }

        .max-visits-value {
          padding-bottom: ${CSS_SPACING};
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .title {
          display: flex;
          padding-left: 10px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderAnnualVisitLimit(maxVisits) {
    return html`
      <div id="${ELEMENTS.maxVisitsText.id}">
        <span class="bold">${ANNUAL_VISIT_LIMIT_TITLE}</span>
        <div id="${ELEMENTS.maxVisitsValue.id}" class="max-visits-value">
          ${maxVisits || '-'}
        </div>
      </div>
    `;
  }

  __renderVisitUsed(currentVisits) {
    return html`
      <div id="${ELEMENTS.currentVisitsText.id}">
        <span class="bold">${RENDERED_AT_THIS_PRACTICE_TITLE}</span>
        <div id="${ELEMENTS.currentVisitsValue.id}">
          ${currentVisits || '-'}
        </div>
      </div>
    `;
  }

  __renderRenderedOutOfOffice(visitsRenderedOutOfOffice) {
    return html`
      <div id="${ELEMENTS.visitsRenderedOutOfOfficeText.id}">
        <span class="bold">${RENDERED_OUTSIDE_PRACTICE_TITLE}</span>
        <div
          id="${ELEMENTS.visitsRenderedOutOfOfficeValue.id}"
          class="max-visits-value"
        >
          ${typeof visitsRenderedOutOfOffice === 'number'
            ? html` ${visitsRenderedOutOfOffice} ${this.__renderAsOf()} `
            : '-'}
        </div>
      </div>
    `;
  }

  __renderAsOf() {
    return html`
      <div id="${ELEMENTS.asOfText.id}">
        <span id="${ELEMENTS.asOfValue.id}" class="italic"
          >${AS_OF_TITLE}
          ${moment(this.visitLimit.asOf).utc().format('MM/DD/YYYY')}</span
        >
      </div>
    `;
  }

  __renderTotalRemaining(total) {
    return html`
      <div id="${ELEMENTS.totalRemainingText.id}">
        <span class="bold">${TOTAL_REMAINING_TITLE}</span>
        <div id="${ELEMENTS.totalRemainingValue.id}">
          ${total || total === 0 ? total : '-'}
        </div>
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="visit-limit-container">
        <div class="visit-limit-row">
          <div class="flex-one">
            ${this.__renderAnnualVisitLimit(this.visitLimit.maxVisits)}
          </div>
          <div class="flex-one">
            ${this.__renderRenderedOutOfOffice(
              this.visitLimit.visitsRenderedOutOfOffice,
            )}
          </div>
        </div>

        <div class="visit-limit-row">
          <div class="flex-one">
            ${this.__renderVisitUsed(this.visitLimit.currentVisits)}
          </div>
          <div class="flex-one">
            ${this.__renderTotalRemaining(
              this.visitLimit.displayTotalRemaining,
            )}
          </div>
        </div>
      </div>
    `;
  }

  __renderNoContent() {
    return html`
      <div id="${ELEMENTS.noContentText.id}" class="no-content">
        ${NO_CONTENT_TEXT}
      </div>
    `;
  }

  __renderTitle() {
    return html`
      <div id="${ELEMENTS.title.id}" class="title">${PANEL_TITLE}</div>
    `;
  }

  __hasVisitLimit() {
    return (
      this.visitLimit &&
      (this.visitLimit.maxVisits ||
        this.visitLimit.asOf ||
        this.visitLimit.visitsRenderedOutOfOffice ||
        this.visitLimit.currentVisits)
    );
  }

  __renderVisitLimit() {
    return html`
      <div id="${ELEMENTS.visitLimitPanel.id}" class="panel">
        ${this.__renderTitle()}
        ${this.__hasVisitLimit
          ? this.__renderContent()
          : this.__renderNoContent()}
      </div>
    `;
  }

  render() {
    return html` ${this.__renderVisitLimit()} `;
  }
}

customElements.define(
  'neb-patient-insurance-visit-limit-coverage-details',
  NebPatientInsuranceVisitLimitCoverageDetails,
);
