import { formatAdjustmentAmount } from '../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import { parseDate } from '../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  formatDollarAmount,
} from '../../packages/neb-utils/formatters';

import { formatBilled } from './line-items';

const formatAmounts = charge => ({
  billedAmount: centsToCurrency(charge.billedAmount),
  allowedAmount: centsToCurrency(charge.allowedAmount),
  taxAmount: centsToCurrency(charge.taxAmount),
  adjustmentAmount: formatAdjustmentAmount(charge.adjustmentAmount),

  primaryPaidAmount: centsToCurrency(charge.primaryPaidAmount),
  primaryBalanceAmount: formatDollarAmount(
    charge.primaryOwedAmount - charge.primaryPaidAmount,
  ),

  secondaryPaidAmount: centsToCurrency(charge.secondaryPaidAmount),
  secondaryBalanceAmount: formatDollarAmount(
    charge.secondaryOwedAmount - charge.secondaryPaidAmount,
  ),

  patientPaidAmount: centsToCurrency(charge.patientPaidAmount),
  patientBalanceAmount: formatDollarAmount(
    charge.patientOwedAmount - charge.patientPaidAmount,
  ),
});

const formatCharge = charge => ({
  id: charge.id,
  type: charge.type,
  dateOfService: parseDate(charge.dateOfService).format('MM/DD/YYYY'),
  code: charge.code,
  billed: formatBilled(charge),
  hasBillingNote: Boolean(charge.hasBillingNote),
  ...formatAmounts(charge),
});

export const formatCharges = charges =>
  charges.map(charge => formatCharge(charge));

export const formatTotals = charges => {
  let billedAmount = 0;
  let allowedAmount = 0;
  let taxAmount = 0;
  let adjustmentAmount = 0;
  let primaryOwedAmount = 0;
  let primaryPaidAmount = 0;
  let secondaryOwedAmount = 0;
  let secondaryPaidAmount = 0;
  let patientOwedAmount = 0;
  let patientPaidAmount = 0;

  for (let i = 0; i < charges.length; i += 1) {
    const charge = charges[i];
    billedAmount += charge.billedAmount;
    allowedAmount += charge.allowedAmount;
    taxAmount += charge.taxAmount;
    adjustmentAmount += charge.adjustmentAmount;
    primaryOwedAmount += charge.primaryOwedAmount;
    primaryPaidAmount += charge.primaryPaidAmount;
    secondaryOwedAmount += charge.secondaryOwedAmount;
    secondaryPaidAmount += charge.secondaryPaidAmount;
    patientOwedAmount += charge.patientOwedAmount;
    patientPaidAmount += charge.patientPaidAmount;
  }

  return {
    dateOfService: 'Totals',
    ...formatAmounts({
      billedAmount,
      allowedAmount,
      taxAmount,
      adjustmentAmount,
      primaryOwedAmount,
      primaryPaidAmount,
      secondaryOwedAmount,
      secondaryPaidAmount,
      patientOwedAmount,
      patientPaidAmount,
    }),
  };
};
