import { css } from 'lit';

import { UpdateNotificationService } from '../../../../../../src/services/update-notifications';
import { ANY } from '../../../../../../src/utils/update-notifications';
import {
  CARE_TYPES,
  CARE_TYPES_ACCIDENT,
} from '../../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as caseApi from '../../../../../neb-api-client/src/patient-cases';
import { parseDate } from '../../../../../neb-utils/date-util';
import { normalizeForSearch } from '../../../../../neb-utils/formatters';
import { OVERLAY_KEYS } from '../../../utils/overlay-constants';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../neb-page-collection';
import { SORT_DIR } from '../../neb-table-header';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

const EMPTY_DATE = new Date(0);
class NebPatientCasesPage extends CollectionPage {
  static get properties() {
    return {
      patientId: {
        type: String,
      },
      patientName: {
        type: Object,
      },
      preferredProviderId: {
        type: String,
      },
    };
  }

  getConfig() {
    return {
      unifyForm: true,
      singularName: 'Case',
      pluralName: '',
      initialSortKey: 'onsetSymptomsDate',
      initialSortOrder: SORT_DIR.DESC,
      overlayKey: OVERLAY_KEYS.CASE,
      fetchAllAfterAddOrUpdate: true,
      description:
        'Create a case to track and manage treatments or episodes outside of routine care.',
      searchLabel: 'Enter name to filter list below.',
      tableConfig: [
        {
          sortable: true,
          truncate: true,
          key: 'name',
          label: 'Name',
          flex: css`2 0 0`,
        },
        {
          sortable: true,
          key: 'careType',
          label: 'Type',
          flex: css`1 0 40px`,
          formatter: (careType, patientCase) =>
            `${CARE_TYPES.find(item => item.data.code === careType).label}${
              CARE_TYPES_ACCIDENT.some(x => x.data.code === careType)
                ? ` (${patientCase.accidentState})`
                : ''
            }`,
        },
        {
          key: 'guarantor',
          label: 'Guarantor',
          flex: css`1 0 60px`,
          formatter: v => {
            if (!v) return 'Self';

            if (v.person) {
              return `${v.person.lastName}, ${v.person.firstName}`;
            }

            if (v.organization) {
              return v.organization.name;
            }

            return '';
          },
        },
        {
          sortable: true,
          key: 'onsetSymptomsDate',
          label: 'Date of Condition/Injury',
          flex: css`2 0 80px`,
          formatter: onsetSymptomsDate =>
            onsetSymptomsDate
              ? parseDate(onsetSymptomsDate).format('MM/DD/YYYY')
              : 'Gradual',
          compare: (a, b) => {
            const A = a ? new Date(a) : EMPTY_DATE;
            const B = b ? new Date(b) : EMPTY_DATE;

            if (A.toString() === B.toString()) {
              return 0;
            }

            return A < B ? -1 : 1;
          },
        },

        {
          key: 'patientAuthorizations',
          label: 'Authorizations',
          flex: css`1.8 0 80px`,
          formatter: patientAuthorizations =>
            patientAuthorizations
              .map(({ authorizationNumber }) => authorizationNumber)
              .join(', '),
        },
        {
          key: 'active',
          label: 'Status',
          flex: css`0 0 80px`,
          formatter: status => (status ? 'Active' : 'Inactive'),
        },
        {
          key: 'isDefault',
          label: 'Default',
          flex: css`0 0 80px`,
          formatter: def => (def ? 'Default' : ''),
        },
      ],
    };
  }

  initState() {
    super.initState();

    this.onGuarantorChange = () => {};

    this.onUpdateCaseCount = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      refetch: async () => {
        const items = await this.fetch();
        this.service.setItems(items);
      },
    };
  }

  initService() {
    super.initService();
    this.__updateNotificationService = new UpdateNotificationService({
      defaultQuery: {
        patientCase: ANY,
      },
      callback: () => this.__handlers.refetch(),
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__updateNotificationService.connect();
  }

  disconnectedCallback() {
    this.__updateNotificationService.connect();
    super.disconnectedCallback();
  }

  fetch() {
    return caseApi.fetchMany(this.patientId);
  }

  changeOccurred(state) {
    this.onUpdateCaseCount(state.filteredCount);
  }

  formatSearchItem({ name, onsetSymptomsDate }) {
    onsetSymptomsDate = onsetSymptomsDate
      ? parseDate(onsetSymptomsDate).format('MMDDYYYY')
      : 'gradual';

    return normalizeForSearch([name, onsetSymptomsDate].join(' '));
  }

  filterSearchItem({ terms, item }) {
    return terms.every(term => item.includes(term));
  }

  buildContext() {
    const totalItems = this.service.getItems().length;
    const activeItems =
      totalItems === 0
        ? 0
        : this.service.getItems().filter(el => el.active).length;
    const isFirstCase = totalItems === 0 || activeItems === 0;

    return {
      patientId: this.patientId,
      patientName: this.patientName,
      preferredProviderId: this.preferredProviderId,
      isFirstCase,
      isFromPatientCasePage: true,
      onAuthorizationChange: this.__handlers.refetch,
    };
  }

  renderNoItemsContent() {
    return 'There are no cases. Click "Add Case" to create a new case.';
  }

  async updated(changedProps) {
    if (changedProps.has('patientId')) {
      const items = await this.fetch();
      this.service.setItems(items);
    }
  }
}

customElements.define('neb-patient-cases-page', NebPatientCasesPage);
