import '../../scheduling/neb-rescheduled-appointment-page-controller';
import { html, css } from 'lit';

import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebOverlayRescheduledAppointmentPage extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        :host([layout='small']) .content {
          width: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-rescheduled-appointment-page-controller
        id="${ELEMENTS.controller.id}"
        .rescheduledId="${this.model.rescheduledId}"
        .appointmentId="${this.model.appointmentId}"
        .layout="${this.layout}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-rescheduled-appointment-page-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-rescheduled-appointment-page',
  NebOverlayRescheduledAppointmentPage,
);
