/* eslint-disable complexity */
import '../../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../controls/inputs/neb-checkbox';

import { css, html } from 'lit';

import NebTable from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { TYPE } from '../../../../../packages/neb-utils/fee-schedule';
import { currency } from '../../../../../packages/neb-utils/masks';
import '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';

export const ELEMENTS = {
  amountCells: { selector: '[id^=amount-]', tag: 'neb-textfield' },
  amountTooltip: { id: 'tooltip-amount' },
  allowedAmountCells: {
    selector: '[id^=allowedAmount-]',
    tag: 'neb-textfield',
  },
  modifiersCells: {
    selector: '[id^=modifiers-]',
    tag: 'neb-modifiers',
  },
  nonCoveredCheckbox: { selector: '[id^=non-covered-]', tag: 'neb-checkbox' },
  suppressFromClaimCheckbox: {
    selector: '[id^=suppress-from-claim-]',
    tag: 'neb-checkbox',
  },
};

class NebTableFeeScheduleCharges extends NebTable {
  static get properties() {
    return {
      type: String,
      __wrapModifiers: { type: Boolean, reflect: true },
    };
  }

  initState() {
    super.initState();

    this.__wrapModifiers = false;

    this.writable = true;
    this.type = TYPE.FEE;

    this.onClickCheckbox = () => {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host(:not([__wrapModifiers])) .modifiers {
          --modifier-columns: repeat(4, 50.4px);
        }

        :host([__wrapModifiers]) .modifiers {
          --modifier-columns: repeat(2, 50.4px);
        }

        .field {
          width: 96px;
        }

        .select {
          width: 236px;
        }

        .tooltip {
          padding-left: 7px;
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('resize', this.__resize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('resize', this.__resize);
  }

  firstUpdated() {
    this.__resize();
  }

  __resize() {
    const windowSize = window.innerWidth;

    this.__wrapModifiers = windowSize < 1450;

    return this.__wrapModifiers;
  }

  isDisabled(key) {
    return (
      (key === 'amount' && this.type === TYPE.PERCENT) ||
      key === 'adjustmentAmount'
    );
  }

  renderTooltip(key) {
    return key === 'amount'
      ? html`
          <neb-tooltip id="${ELEMENTS.amountTooltip.id}" class="tooltip">
            <div slot="tooltip">
              This amount will override the base amount and will be used to
              calculate the Billed amount for the charge.
            </div>
          </neb-tooltip>
        `
      : '';
  }

  renderHeaderCell(columnConfig) {
    const { key } = columnConfig;

    switch (key) {
      case 'amount':
      case 'allowedAmount':
      case 'adjustmentAmount':
        return html`
          ${super.renderHeaderCell(columnConfig)} ${this.renderTooltip(key)}
        `;

      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const { key } = columnConfig;
    const disabled = this.isDisabled(key);

    let checkboxId;

    switch (key) {
      case 'suppressFromClaim':
      case 'nonCovered':
        checkboxId =
          key === 'nonCovered' ? 'non-covered' : 'suppress-from-claim';

        return html`
          <neb-checkbox
            id="${checkboxId}-${rowIndex}"
            .name="${name}"
            .onChange="${this.onClickCheckbox}"
            ?checked="${value}"
            .disabled="${this.type === TYPE.PERCENT ||
            this.type === TYPE.CAPITATED}"
          ></neb-checkbox>
        `;
      case 'amount':
      case 'allowedAmount':
        return html`
          <neb-textfield
            id="${key}-${rowIndex}"
            class="field"
            helper=" "
            label=" "
            .name="${name}"
            .mask="${currency}"
            .value="${value}"
            .error="${error}"
            .inputMode="numeric"
            .onChange="${this.handlers.change}"
            ?disabled="${disabled}"
          ></neb-textfield>
        `;

      case 'modifiers':
        return html`
          <neb-modifiers
            id="${key}-${rowIndex}"
            class="modifiers"
            helper=" "
            label=" "
            .name="${name}"
            .values="${value}"
            .errors="${error}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.type === TYPE.PERCENT}"
          ></neb-modifiers>
        `;

      default:
        return value;
    }
  }
}
customElements.define(
  'neb-table-fee-schedule-charges',
  NebTableFeeScheduleCharges,
);
