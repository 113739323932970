import { openPopup } from '@neb/popup';
import { LitElement, css, html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { openBulkUpdatePopup } from '../../../utils/charge-overlays-util';

import { MODEL_KEYS } from './neb-package-template-covered-charges';

export const ELEMENTS = {
  coveredCharges: {
    id: 'covered-charges',
  },
};

class NebPackageTemplateCoveredChargesController extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      __pageCount: {
        type: Number,
      },
      __currentPage: {
        type: Number,
      },
      __searchText: {
        type: String,
      },
      __charges: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.__elements = {};
    this.__pageCount = 0;
    this.__currentPage = 0;
    this.__searchText = '';
    this.__charges = [];

    this.onAddCharge = () => {};

    this.onChange = () => {};

    this.onRemoveCharge = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addCharge: () => this.onAddCharge(),
      updateCharge: updatedCharge => {
        const updatedCharges = this.model.charges.map(c =>
          c.chargeId === updatedCharge.chargeId ? updatedCharge : c,
        );
        return this.onChange({
          key: MODEL_KEYS.CHARGES,
          value: updatedCharges,
        });
      },
      changeFilter: text => {
        this.__searchText = text;
      },
      changePage: page => {
        this.__currentPage = page;
      },
      removeCharge: async (item, index) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Charge',
          message:
            'This will remove the charge from the package. Are you sure that you want to proceed?',
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (result) {
          this.onRemoveCharge(item, index);
        }
      },
      bulkUpdate: async maxUnits => {
        const result = await openBulkUpdatePopup(maxUnits);

        if (result) {
          const charges = this.model.charges.map(charge => {
            if (this.__doesChargeMatchFilter(charge)) {
              return { ...charge, units: maxUnits };
            }
            return charge;
          });
          this.onChange({
            key: MODEL_KEYS.CHARGES,
            value: charges,
          });
        }
      },
    };
  }

  __getCurrentCharges(charges) {
    return charges.slice(this.__currentPage * 10, this.__currentPage * 10 + 10);
  }

  __doesChargeMatchFilter(charge) {
    const searchTerms = this.__searchText.trim().split(' ');

    return searchTerms.every(
      text =>
        charge.procedure.toLowerCase().includes(text.toLowerCase()) ||
        charge.description.toLowerCase().includes(text.toLowerCase()),
    );
  }

  __filterCharges() {
    return this.model.charges
      .filter(charge => this.__doesChargeMatchFilter(charge))
      .sort((a, b) => {
        if (a.procedure > b.procedure) {
          return 1;
        }

        if (a.procedure < b.procedure) {
          return -1;
        }

        if (a.procedure === b.procedure) {
          if (a.description > b.description) {
            return 1;
          }

          if (a.description < b.description) {
            return -1;
          }
        }

        return 0;
      });
  }

  __changeChargesPage() {
    if (!this.model.charges) return [];

    const charges = this.__filterCharges();

    return this.__getCurrentCharges(charges);
  }

  __setCharges() {
    if (!this.model.charges) return [];

    const charges = this.__filterCharges();

    this.__pageCount = Math.ceil(charges.length / 10);

    if (charges.length > 10) {
      return this.__getCurrentCharges(charges);
    }
    return charges;
  }

  __setFilteredCharges() {
    this.__charges = this.__setCharges();
    this.__currentPage = 0;
  }

  update(changedProps) {
    if (changedProps.has('model') && changedProps.get('model')) {
      this.__charges = this.__setCharges();

      if (
        changedProps.get('model').charges &&
        changedProps.get('model').charges.length !== this.model.charges.length
      ) {
        this.__currentPage = 0;
      }
    }

    if (changedProps.has('__searchText')) {
      this.__setFilteredCharges();
    }

    if (changedProps.has('__currentPage')) {
      this.__charges = this.__changeChargesPage();
    }

    super.update(changedProps);
  }

  updated() {
    this.__elements = {
      coveredCharges: this.shadowRoot.getElementById(
        ELEMENTS.coveredCharges.id,
      ),
    };
  }

  __hasCharges() {
    return this.model.charges && this.model.charges.length > 0;
  }

  validate() {
    return this.__elements.coveredCharges.validate();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-package-template-covered-charges
        id="${ELEMENTS.coveredCharges.id}"
        .tableType="${this.model.type}"
        .currentPage="${this.__currentPage}"
        .pageCount="${this.__pageCount}"
        .searchText="${this.__searchText}"
        .charges="${this.__charges}"
        .hasCharges="${this.__hasCharges()}"
        .onAddCharge="${this.__handlers.addCharge}"
        .onUpdateCharge="${this.__handlers.updateCharge}"
        .onRemoveCharge="${this.__handlers.removeCharge}"
        .onFilterChanged="${this.__handlers.changeFilter}"
        .onPageChanged="${this.__handlers.changePage}"
        .onBulkUpdate="${this.__handlers.bulkUpdate}"
      ></neb-package-template-covered-charges>
    `;
  }
}

customElements.define(
  'neb-package-template-covered-charges-controller',
  NebPackageTemplateCoveredChargesController,
);
