import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';
import moment from 'moment-timezone';

import { getBillingInfo } from '../../../../packages/neb-api-client/src/billing-information-api-client';
import { postDocument } from '../../../../packages/neb-api-client/src/document-api-client';
import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { printPdf } from '../../../../packages/neb-utils/neb-pdf-print-util';
import { fetch as getPracticeLogo } from '../../../api-clients/practice-logo';
import {
  tagAndAssociateVisitSummary,
  createVisitSummaryPdf,
} from '../../../api-clients/visit-summary';
import { CSS_SPACING, layoutStyles } from '../../../styles';
import { formatToPdf } from '../../../utils/visit-summary';
import FormVisitSummaryPatientDetail from '../../forms/encounters/neb-form-visit-summary-patient-detail';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class OverlayVisitSummaryPatientDetail extends Overlay {
  static get properties() {
    return {
      __formLoading: {
        type: Boolean,
      },
      __savingError: {
        type: Object,
      },
      __formModel: {
        type: Object,
      },
      layout: {
        reflect: true,
        type: String,
      },
      model: {
        type: Object,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .content {
          display: grid;
          width: 100%;
          height: 100%;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__formLoading = true;
    this.__savingError = null;
    this.__formModel = FormVisitSummaryPatientDetail.createModel();
    this.layout = '';
    this.model = {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      print: async model => {
        try {
          const pdfInfo = formatToPdf(model);
          const pdf = await createVisitSummaryPdf(pdfInfo);
          printPdf(Promise.resolve(pdf.buffer));

          this.__savingError = null;
          this.__formModel = model;
          this.isDirty = false;
          this.dismiss(model);
          store.dispatch(openSuccess('Visit summary created successfully'));
        } catch (e) {
          this.__savingError = e;
          store.dispatch(
            openError('An error occurred while creating Visit summary'),
          );
        }
      },
      save: async model => {
        try {
          const pdfInfo = formatToPdf(model);
          const pdf = await createVisitSummaryPdf(pdfInfo);
          printPdf(Promise.resolve(pdf.buffer));

          const blob = new Blob([Uint8Array.from(pdf.buffer.data).buffer], {
            type: 'application/pdf',
          });

          const documentModel = {
            date: moment(),
            uploadDate: moment(),
            name: `visits-summary-${model.createdAt}`,
            file: blob,
            type: 'application/pdf',
            tags: [],
          };

          const { documentId } = await postDocument(
            documentModel,
            model.patientId,
          );

          await tagAndAssociateVisitSummary({
            body: { encounters: model.encounters, documentId },
            patientId: model.patientId,
          });

          this.__savingError = null;
          this.__formModel = model;
          this.isDirty = false;
          this.dismiss(model);
          store.dispatch(
            openSuccess('Visit summary saved and associated successfully'),
          );
        } catch (e) {
          this.__savingError = e;
          store.dispatch(
            openError('An error occurred when saving Visit summary'),
          );
        }
      },
    };
  }

  async __getLogo(practiceInfoLocation) {
    let practiceLogo;

    if (!practiceInfoLocation.hideLogo || !this.model.locationId) {
      try {
        practiceLogo = await getPracticeLogo();
      } catch (_) {
        practiceLogo = '';
      }
    }
    return practiceLogo;
  }

  __getLocationName(practiceInfoLocation, practiceInformation) {
    if (this.model.locationId) {
      return practiceInfoLocation.businessName || practiceInformation.name;
    }
    return practiceInformation.name;
  }

  async __formatFormModel(practiceInfoLocation, practiceInformation) {
    this.__formModel = {
      ...this.__formModel,
      ...this.model,
      practiceLogo: (await this.__getLogo(practiceInfoLocation)) || '',
      practiceInfo: {
        address: {
          address1: practiceInfoLocation.address1,
          address2: practiceInfoLocation.address2,
          city: practiceInfoLocation.city,
          state: practiceInfoLocation.state,
          zipcode: practiceInfoLocation.zipCode,
        },
        name: this.__getLocationName(practiceInfoLocation, practiceInformation),
        emailAddress: practiceInfoLocation.emailAddress,
        phoneNumber: practiceInfoLocation.phoneNumber,
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    const practiceInformation = store.getState().practiceInformation.item;
    const billingInformation = await getBillingInfo(true);
    const { locationId } = billingInformation.billingAddress;

    if (this.model.locationId) {
      const practiceInfoLocation = practiceInformation.locations.find(
        l => l.id === this.model.locationId,
      );
      this.__formatFormModel(practiceInfoLocation, practiceInformation);
    } else {
      const practiceInfoLocation = locationId
        ? practiceInformation.locations.find(l => l.id === locationId)
        : practiceInformation.locations[0];
      this.__formatFormModel(practiceInfoLocation, practiceInformation);
    }

    this.__formLoading = false;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Visit Summary"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-visit-summary-patient-detail
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .layout="${this.layout}"
        .savingError="${this.__savingError}"
        .loading="${this.__formLoading}"
        .onSave="${this.handlers.save}"
        .onPrint="${this.handlers.print}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-form-visit-summary-patient-detail>
    `;
  }
}

customElements.define(
  'neb-overlay-visit-summary-patient-detail',
  OverlayVisitSummaryPatientDetail,
);
