import { html } from 'lit';

import {
  centsToCurrencyWithNegative,
  currencyToCents,
  currencyToCentsWithNegative,
} from '../../../../../neb-utils/formatters';

export const ROW_ELEMENTS = {
  unitsField: {
    id: 'field-units',
  },
  unitChargeField: {
    id: 'field-charge',
  },
  adjustmentField: {
    id: 'field-adjustment',
  },
  adjustmentAmountField: {
    id: 'field-adjustment-amount',
  },
  owedField: {
    id: 'field-owed',
  },
  taxField: {
    id: 'field-tax',
  },
  adjustmentAmountError: {
    id: 'adjustment-amount-error',
  },
};
export const KEYS = {
  CODE: 'code',
  DESCRIPTION: 'description',
  UNITS: 'units',
  UNIT_CHARGE: 'unitCharge',
  ADJUSTMENT: 'adjustment',
  ADJUSTMENT_AMOUNT: 'adjustmentAmount',
  OWED: 'owed',
  TAX: 'tax',
};
export const buildHandlers = instance => ({
  changeUnits: ({ value }) => {
    instance.change(KEYS.UNITS, value);
  },
  changeUnitCharge: ({ value }) => {
    instance.change(KEYS.UNIT_CHARGE, currencyToCents(value));
  },
  changeAdjustmentAmount: ({ value }) => {
    instance.change(KEYS.ADJUSTMENT_AMOUNT, currencyToCentsWithNegative(value));
  },
  validateUnits: () => !!instance.model.units && instance.model.units > 0,
  validateUnitCharge: () =>
    !!instance.model.unitCharge && instance.model.unitCharge > 0,
  validateAdjustmentAmount: () => {
    const amount = parseFloat(instance.model.adjustmentAmount);
    return (
      !instance.model.adjustment.id ||
      (amount > 0 && amount <= instance.model.unitCharge * instance.model.units)
    );
  },
  changeRate: ({ value }) => {
    instance.change(
      'taxId',
      instance.model.taxRates.find(tax => tax.name === value).id,
    );
  },
  changeAdjustment: ({ value }) => {
    if (value.code === 'None') {
      instance.onChange({
        ...instance.model,
        adjustment: value,
        adjustmentAmount: 0,
      });
    } else {
      instance.change(KEYS.ADJUSTMENT, value);
    }
  },
  renderAdjustmentItems: item => {
    const text =
      item.data.id === ''
        ? item.label
        : ` ${item.label} - ${item.data.description}`;
    return html`
      <p style="margin: 0 16px; width: max-content; max-width: 440px">
        ${text}
      </p>
    `;
  },

  renderMobileAdjustmentItems: item => {
    const text =
      item.data.id === ''
        ? item.label
        : ` ${item.label} - ${item.data.description}`;
    return html`
      <p style="margin: 0 16px; width: max-content; max-width: 300px">
        ${text}
      </p>
    `;
  },
});
export const calculateOwed = ({ units, unitCharge, adjustmentAmount }) =>
  centsToCurrencyWithNegative(
    units * currencyToCents(unitCharge) -
      currencyToCentsWithNegative(adjustmentAmount),
  );
