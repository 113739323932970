import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

const BASE_URL = 'clearinghouse';

export function updateDefault(body) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    responseType: RESPONSE_TYPE.RAW,
    version: 2,
  });
}
