import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { SPINAL_MANIPULATION_OPTIONS } from '../../../../../neb-utils/claims';
import { defaultMask } from '../../../../../neb-utils/masks';

export const SPINAL_MANIPULATION_ITEMS = [
  {
    value: '',
    label: '',
  },
  ...SPINAL_MANIPULATION_OPTIONS,
];

function setEditedField({ result, state, formService }) {
  const KEYS = [
    'spinalManipulationInfoDescription',
    'spinalManipulationInfoDescription2',
  ];

  if (
    state.spinalManipulationInfoCode !== result.spinalManipulationInfoCode.value
  ) {
    formService.apply('claimEditedField.spinalManipulationInfoCode', true);
  }

  KEYS.forEach(key => {
    if (state[key] !== result[key]) {
      formService.apply(`claimEditedField.${key}`, true);
    }
  });
}

export default async ({ claimCellData, state, handlers, formService }) => {
  const selectedSpinalManipulation = SPINAL_MANIPULATION_ITEMS.find(
    item => item.value === state.spinalManipulationInfoCode,
  );

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SPINAL_MANIPULATION, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: [
      {
        name: 'spinalManipulationInfoCode',
        label: 'NOC (Nature of Condition)',
        value: selectedSpinalManipulation || SPINAL_MANIPULATION_ITEMS[0],
        items: SPINAL_MANIPULATION_ITEMS,
      },
      {
        name: 'spinalManipulationInfoDescription',
        maxLength: 80,
        label: 'Description 1',
        value: state.spinalManipulationInfoDescription,
        width: '100%',
        mask: defaultMask,
      },
      {
        name: 'spinalManipulationInfoDescription2',
        maxLength: 80,
        label: 'Description 2',
        value: state.spinalManipulationInfoDescription2,
        width: '100%',
        mask: defaultMask,
      },
    ],
  });

  if (result) {
    setEditedField({ result, state, formService });

    handlers.change({
      name: 'spinalManipulationInfoCode',
      value: result.spinalManipulationInfoCode.value,
    });

    handlers.change({
      name: 'spinalManipulationInfoDescription',
      value: result.spinalManipulationInfoDescription,
    });

    handlers.change({
      name: 'spinalManipulationInfoDescription2',
      value: result.spinalManipulationInfoDescription2,
    });
  }
};
