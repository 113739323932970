import { filter } from '../../neb-utils/filterUtil';
import {
  FETCH_APPOINTMENT_TYPES,
  CREATE_APPOINTMENT_TYPE,
  UPDATE_APPOINTMENT_TYPE,
  DELETE_APPOINTMENT_TYPE,
  UPDATE_APPOINTMENT_TYPES_ORDER,
} from '../actions/appointment-type';

const filters = [
  {
    statePath: 'activeTypes',
    filter: type => type.active,
  },
];

function filterTypes(state) {
  filter(state, 'items', filters);
}

function handleFetchAction(state, action) {
  switch (action.status) {
    case 'success': {
      const newState = {
        ...state,
        isFetching: false,
        isFetched: true,
        fetchingError: null,
        items: action.response.data,
      };
      filterTypes(newState);
      return newState;
    }
    case 'failed':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        fetchingError: action.error,
      };

    default:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        fetchingError: null,
      };
  }
}

function handleCreateAction(state, action) {
  switch (action.status) {
    case 'success': {
      const newState = {
        ...state,
        isCreating: false,
        creatingError: null,
        items: state.items.slice(),
      };
      newState.items.push(action.response.data[0]);
      filterTypes(newState);
      return newState;
    }
    case 'failed':
      return { ...state, isCreating: false, creatingError: action.error };

    default:
      return { ...state, isCreating: true, creatingError: null };
  }
}

function handleUpdateAction(state, action) {
  switch (action.status) {
    case 'success': {
      let foundAppointmentType;
      let foundIndex = -1;

      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === action.response.data[0].id) {
          foundIndex = i;
          foundAppointmentType = state.items[i];
          break;
        }
      }

      const newState = {
        ...state,
        isUpdating: false,
        updatingError: null,
        items: state.items.slice(),
      };

      if (foundAppointmentType) {
        newState.items.splice(foundIndex, 1, action.response.data[0]);
      }

      filterTypes(newState);
      return newState;
    }
    case 'failed':
      return { ...state, isUpdating: false, updatingError: action.error };

    default:
      return { ...state, isUpdating: true, updatingError: null };
  }
}

function handleDeleteAction(state, action) {
  switch (action.status) {
    case 'success': {
      let foundIndex = -1;

      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === action.apptTypeId) {
          foundIndex = i;
          break;
        }
      }

      const newState = {
        ...state,
        isDeleting: false,
        deletingError: null,
        items: state.items.slice(),
      };

      if (foundIndex !== -1) {
        newState.items.splice(foundIndex, 1);
      }

      filterTypes(newState);
      return newState;
    }
    case 'failed':
      return { ...state, isDeleting: false, deletingError: action.error };

    default:
      return { ...state, isDeleting: true, deletingError: null };
  }
}

function handleUpdateOrderAction(state, action) {
  switch (action.status) {
    case 'success': {
      const newState = {
        ...state,
        isUpdatingOrder: false,
        updatingOrderError: null,
        items: action.response.data,
      };
      filterTypes(newState);
      return newState;
    }
    case 'failed':
      return {
        ...state,
        isUpdatingOrder: false,
        updatingOrderError: action.error,
      };

    default:
      return { ...state, isUpdatingOrder: true, updatingOrderError: null };
  }
}

export const appointmentTypes = (
  state = {
    isFetching: false,
    isFetched: false,
    fetchingError: null,
    isCreating: false,
    creatingError: null,
    isUpdating: false,
    updatingError: null,
    isDeleting: false,
    deletingError: null,
    isUpdatingOrder: false,
    updatingOrderError: null,
    items: [],
    activeTypes: [],
  },
  action,
) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_TYPES:
      return handleFetchAction(state, action);

    case CREATE_APPOINTMENT_TYPE:
      return handleCreateAction(state, action);

    case UPDATE_APPOINTMENT_TYPE:
      return handleUpdateAction(state, action);

    case DELETE_APPOINTMENT_TYPE:
      return handleDeleteAction(state, action);

    case UPDATE_APPOINTMENT_TYPES_ORDER:
      return handleUpdateOrderAction(state, action);

    default:
      return state;
  }
};
