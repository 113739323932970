import './neb-grid-button';
import '../misc/neb-icon';

import { LitElement, html, css } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

import {
  slideLeft,
  slideRight,
} from '../../../packages/neb-styles/neb-animation';
import { baseStyles, CSS_COLOR_HIGHLIGHT, CSS_SPACING } from '../../styles';

export const ELEMENTS = {
  items: { selector: '[id^=item-]' },
};

class NebGridButtons extends LitElement {
  static get properties() {
    return {
      disableEmpty: { type: Boolean },
      selectedIndex: { type: Number },
      slideDirection: { type: String },
      model: { type: Array },
      reorder: { reflect: true, type: Boolean },

      row: { type: Number },
      column: { type: Number },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.reorder = false;
    this.disableEmpty = false;
    this.slideDirection = '';
    this.selectedIndex = -1;
    this.row = 5;
    this.column = 5;
    this.model = [];

    this.onReorder = () => {};

    this.onSelectItem = () => {};

    this.onDoubleClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      animationEnd: () => this.onAnimationEnd(),
      itemSelected: index => this.onSelectItem(this.model[index], index),
      dragStart: e => {
        e.dataTransfer.effectAllowed = 'move';

        const { index } = e.currentTarget;
        this.__startDragIndex = index;
        this.__dragIndex = index;
        this.__dragging = true;

        e.stopPropagation();
      },

      dragEnd: () => {
        if (
          this.__startDragIndex !== this.__dragIndex &&
          this.__dragIndex !== null
        ) {
          this.onReorder(this.__startDragIndex, this.__dragIndex);
        }

        this.__startDragIndex = null;
        this.__dragIndex = null;
        this.__dragging = false;
      },

      dragOver: e => {
        this.__dragIndex = e.currentTarget.index;

        if (this.__startDragIndex !== this.__dragIndex) {
          e.currentTarget.classList.add('is-hovered-over');

          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
        }
      },

      dragLeave: e => {
        this.__dragIndex = null;
        e.currentTarget.classList.remove('is-hovered-over');
      },

      drop: e => {
        e.preventDefault();
        e.currentTarget.classList.remove('is-hovered-over');
      },

      doubleClick: () => this.onDoubleClick(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .grid[slide='right'] {
          animation: slide-right 0.2s linear forwards;
          -webkit-animation: slide-right 0.2s linear forwards;
        }

        .grid[slide='left'] {
          animation: slide-left 0.2s linear forwards;
          -webkit-animation: slide-left 0.2s linear forwards;
        }

        .button {
          border: 1px solid transparent;
          border-radius: 3px;
        }

        .is-hovered-over {
          border: 1px dashed ${CSS_COLOR_HIGHLIGHT};
        }

        [draggable='true'] {
          position: relative;
          z-index: 1;
        }

        ${slideRight} ${slideLeft}
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    const handler = this.__handlers.animationEnd;
    this.shadowRoot.addEventListener('animationend', handler);
    this.shadowRoot.addEventListener('webkitAnimationEnd', handler);
  }

  __isSelected(item, index) {
    return item ? this.selectedIndex === index : false;
  }

  getStyling() {
    const styles = {
      display: 'grid',
      margin: `0 ${CSS_SPACING}`,
      gridGap: '3px',
      gridTemplateColumns: `repeat(${this.row}, 1fr)`,
      gridTemplateRows: `repeat(${this.column}, 1fr)`,
    };

    return styleMap(styles);
  }

  renderItem(item, index) {
    return html`
      <neb-grid-button
        id="item-${index}"
        class="button"
        draggable="${Boolean(this.reorder && item).toString()}"
        @dragstart="${this.__handlers.dragStart}"
        @dragend="${this.__handlers.dragEnd}"
        @dragover="${this.__handlers.dragOver}"
        @dragleave="${this.__handlers.dragLeave}"
        @drop="${this.__handlers.drop}"
        @dblclick="${this.__handlers.doubleClick}"
        .index="${index}"
        .label="${item.name}"
        .icon="${item.icon}"
        .color="${item.color}"
        .disableEmpty="${this.disableEmpty}"
        .selected="${this.__isSelected(item, index)}"
        .onClick="${this.__handlers.itemSelected}"
        .disabled="${item ? item.disabled : false}"
      >
      </neb-grid-button>
    `;
  }

  renderEmptyItem(index) {
    return html`
      <neb-grid-button
        id="item-${index}"
        class="button"
        draggable="false"
        @dragend="${this.__handlers.dragEnd}"
        @dragover="${this.__handlers.dragOver}"
        @dragleave="${this.__handlers.dragLeave}"
        @drop="${this.__handlers.drop}"
        .index="${index}"
        .disableEmpty="${this.disableEmpty}"
        .onClick="${this.__handlers.itemSelected}"
      >
      </neb-grid-button>
    `;
  }

  render() {
    const styles = this.getStyling();
    const length = this.row * this.column;

    return html`
      <div class="grid" style="${styles}" slide="${this.slideDirection}">
        ${Array(length)
          .fill(null)
          .map((_, index) =>
            this.model[index]
              ? this.renderItem(this.model[index], index)
              : this.renderEmptyItem(index),
          )}
      </div>
    `;
  }
}

customElements.define('neb-grid-buttons', NebGridButtons);
