import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_BLUE_BORDER,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  dayName: {
    id: 'day-name',
  },
};

class NebRecurringDayBubble extends LitElement {
  static get properties() {
    return {
      day: {
        type: String,
      },
      selected: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.day = '';
    this.selected = false;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          width: 38px;
          height: 38px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 11px;
          background-color: ${CSS_COLOR_GREY_2};
          cursor: pointer;
          user-select: none;
        }

        :host([selected]) {
          background-color: ${CSS_COLOR_BLUE_BORDER}; /* will revisit later */
          color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  render() {
    return html`
      <span id="${ELEMENTS.dayName.id}">${this.day.charAt(0)}</span>
    `;
  }
}

window.customElements.define('neb-recurring-day-bubble', NebRecurringDayBubble);
