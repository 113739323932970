import './inputs/neb-select-search';
import './inputs/neb-textfield';
import './patients/neb-patient-card';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import { createServerPatientsCollection } from '../../../neb-utils/collections/server-patients';
import Debouncer from '../../../neb-utils/debouncer';
import { objToName, DEFAULT_NAME_OPTS } from '../../../neb-utils/formatters';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  textfield: {
    id: 'textfield',
  },
};

class NebPatientSearch extends LitElement {
  static get properties() {
    return {
      __patientItems: Array,
      __searchText: {
        type: String,
      },

      disabled: {
        type: Boolean,
        reflect: true,
      },
      elements: {
        type: Object,
      },
      error: {
        type: String,
      },
      helper: {
        type: String,
      },
      label: {
        type: String,
      },
      layout: {
        type: String,
      },
      maxVisibleItems: {
        type: Number,
      },
      patient: {
        type: Object,
      },
      required: {
        type: Boolean,
        reflect: true,
      },
      shouldFocus: {
        type: Boolean,
      },
      excludePatientId: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__patientItems = [];
    this.__searchText = '';

    this.disabled = false;
    this.elements = null;
    this.error = '';
    this.helper = '';
    this.label = '';
    this.layout = '';
    this.maxVisibleItems = 5;
    this.patient = null;
    this.required = false;
    this.shouldFocus = false;
    this.excludePatientId = '';

    this.__focusDebouncer = new Debouncer(() => {
      this.setSearchBarFocus();
    }, 300);

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: ({ value }) => {
        if (value) {
          this.error = '';
          this.onChange(value.data);
        } else {
          this.__searchText = '';
          this.error = this.required ? 'Required' : '';
          this.onChange(null);
        }
      },
      requestMorePatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
    };
  }

  __getPatientName() {
    return objToName(this.patient.name, DEFAULT_NAME_OPTS);
  }

  validate() {
    this.error = this.required && !this.patient ? 'Required' : '';
  }

  setSearchBarFocus() {
    if (this.shouldFocus) {
      this.elements.searchBar.setFocus();
    }
  }

  firstUpdated() {
    this.elements = {
      searchBar: this.shadowRoot.getElementById(ELEMENTS.textfield.id),
    };

    this.__patientService = createServerPatientsCollection({
      onChange: ({ pageItems }) => {
        this.__patientItems = pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      excludePatientId: this.excludePatientId || undefined,
    });
  }

  update(changedProps) {
    if (changedProps.has('patient') && this.patient) {
      this.__searchText = this.__getPatientName();
      this.error = '';
    }

    if (changedProps.has('elements') && this.elements) {
      this.__focusDebouncer.debounce();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          width: 100%;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .textfield {
          display: flex;
          width: 100%;
        }
      `,
    ];
  }

  __renderPatient(patient, index) {
    return html`
      <neb-patient-card
        id="item-${index}"
        class="item"
        tabindex="0"
        .model="${patient.data}"
        ?selected="${this.patient ? this.patient.id === patient.id : false}"
      ></neb-patient-card>
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-select-search
          id="${ELEMENTS.textfield.id}"
          class="textfield"
          emptyMessage="No patients found."
          itemHeight="88"
          maxVisibleItems="${this.maxVisibleItems}"
          .error="${this.error}"
          .helper="${this.helper}"
          .items="${this.__patientItems}"
          .label="${this.label}"
          .placeholder="${this.label}"
          .search="${this.__searchText}"
          .value="${this.patient ? this.__getPatientName() : ''}"
          .onChange="${this.__handlers.change}"
          .onSearch="${this.__handlers.searchPatients}"
          .onRequest="${this.__handlers.requestMorePatients}"
          .onRenderItem="${this.__renderPatient}"
          ?disabled="${this.disabled}"
          ?showSearch="${true}"
        ></neb-select-search>
      </div>
    `;
  }
}

customElements.define('neb-patient-search', NebPatientSearch);
