import { BILL_TYPE_DEFAULT_INSURANCE } from '../../src/utils/user-message';
import { ECLAIM_API_ERROR } from '../neb-api-client/src/electronic-claims/claims';
import {
  createInsurance,
  updateInsurance,
} from '../neb-api-client/src/real-time-eligibility-api-client';
import {
  openError,
  openInfo,
  openSuccess,
} from '../neb-dialog/neb-banner-state';
import { store } from '../neb-redux/neb-redux-store';

const parseCHCErrorRTE = err => {
  if (
    err.responseBody &&
    err.responseBody.error &&
    err.responseBody.error.errorType
  ) {
    if (err.responseBody.error.errorType === ECLAIM_API_ERROR.CHC_VALIDATION) {
      return {
        isValidationError: true,
        message:
          'No eligibility data returned. See RTE History Log for details.',
      };
    }

    if (err.responseBody.error.errorType === ECLAIM_API_ERROR.CHC_INTERNAL) {
      return {
        isValidationError: false,
        message: 'An error occurred when requesting eligibility',
      };
    }
  }

  return {
    isValidationError: false,
    message: 'An internal error occurred when requesting eligibility',
  };
};

export const createInsuranceFromRTE = async model => {
  let insurance;
  let res;

  try {
    res = await createInsurance(model);
    insurance = res.data;

    store.dispatch(openSuccess('Eligibility request sent successfully'));
  } catch (err) {
    const errorInfo = parseCHCErrorRTE(err);
    res = err.responseBody;
    insurance = res.data;

    store.dispatch(openError(errorInfo.message));
  }

  if (insurance.isBillTypeUpdated) {
    store.dispatch(openInfo(BILL_TYPE_DEFAULT_INSURANCE));
  }

  return insurance;
};

export const updateInsuranceFromRTE = async (
  patientInsuranceId,
  patientId,
  data = {},
) => {
  let insurance;
  let res;

  try {
    res = await updateInsurance(patientInsuranceId, patientId, data);

    insurance = res.data;

    store.dispatch(openSuccess('Eligibility request sent successfully'));
  } catch (err) {
    const errorInfo = parseCHCErrorRTE(err);
    res = err.responseBody;
    insurance = res.data;

    store.dispatch(openError(errorInfo.message));
  }

  if (insurance.newBillType === 'Insurance') {
    store.dispatch(openInfo(BILL_TYPE_DEFAULT_INSURANCE));
  }

  return insurance;
};
