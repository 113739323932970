/* eslint-disable complexity */
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { AppointmentStoreService } from '../../../../packages/neb-redux/services/appointment-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { MdcProviderService } from '../../../../packages/neb-redux/services/mdc-provider';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  EMPTY_RESCHEDULE_REASON,
  SELECT_BY_TYPE,
} from '../../../utils/scheduling/appointments';
import NebFormAppointment from '../../forms/appointments/neb-form-appointment';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
};

const SERVICE_NAMES = ['__providerService', '__locationsService'];

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

class NebOverlayAppointmentForm extends Overlay {
  static get properties() {
    return {
      __appointmentTypes: Array,
      __disableSaveIndicator: Boolean,
      __locations: Array,
      __providers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          overflow-y: auto;
        }
        :host(:not([layout='small'])).content {
          display: grid;
          overflow-y: auto;
          min-width: 800px;
        }

        .controller {
          overflow: hidden;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initServices();
  }

  initState() {
    super.initState();

    this.__appointmentTypes = [];
    this.__providers = [];
    this.__locations = [];
    this.__disableSaveIndicator = false;
    this.model = NebFormAppointment.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          this.__disableSaveIndicator = false;
          const custom = model.type === SELECT_BY_TYPE.CUSTOM || !!model.rrule;

          let body = {
            ...model,
            resourceId: model.resourceId || null,
            roomId: model.roomId || null,
            providerId: model.providerId || null,
            rrule: model.rrule ? model.rrule.toString() : '',
            caseId: model.caseId || null,
            patientAuthorizationId: model.patientAuthorizationId || null,
            custom,
          };

          let method;

          if (model.id) {
            body = {
              ...body,
              id: model.id,
              cancelRescheduleReasonId:
                model.cancelRescheduleReasonId ||
                EMPTY_RESCHEDULE_REASON.data.id,
            };

            method = 'rescheduleAppointment';
          } else {
            body = {
              ...body,
              walkIn: !model.rrule ? model.walkIn : false,
            };

            method = 'createAppointment';
          }

          let result;

          result = await this.__appointmentStoreService[method]({
            ...body,
            checkDuplicate: true,
          });

          if (result.model && result.model.isDuplicate) {
            const appointmentType = this.__appointmentTypes.find(
              apptType => apptType.data.id === result.model.appointmentTypeId,
            );

            const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Possible Duplicate Appointment',
              message: html`
                <p>
                  This patient already has an appointment of the same type
                  (${appointmentType.label}) scheduled for
                  ${parseDate(result.model.start).format('dddd, MMM DD, YYYY')}
                  at
                  ${this.__formatLocationsForPopup(result.model.locationIds)}.
                </p>

                <p>
                  Are you sure you want to schedule another appointment for this
                  patient?
                </p>
              `,
              confirmText: 'Yes',
              cancelText: 'No',
            });

            if (accepted) {
              result = await this.__appointmentStoreService[method](body);

              this.isDirty = false;
              this.dismiss(result);
            }

            this.__disableSaveIndicator = true;
          } else {
            this.isDirty = false;
            this.dismiss(result);
          }
        } catch (e) {
          console.error(e);
        }
      },
    };
  }

  initServices() {
    this.__providerService = new MdcProviderService(({ activeProviders }) => {
      this.__providers = activeProviders.map(data => ({
        data,
        label: data.value,
      }));
    });

    this.__locationsService = new LocationsService(({ activeLocations }) => {
      this.__locations = activeLocations.map(data => ({
        data,
        label: data.name,
      }));
    });

    this.__appointmentStoreService = new AppointmentStoreService();
  }

  __connectServices() {
    SERVICE_NAMES.forEach(name => this[name].connect());
  }

  __disconnectServices() {
    SERVICE_NAMES.forEach(name => {
      try {
        this[name].disconnect();
      } catch (_) {
        // noop
      }
    });
  }

  __formatLocationsForPopup(locationIds) {
    const locations = this.__locations.filter(loc =>
      locationIds.includes(loc.data.id),
    );

    return formatter.format(locations.map(({ label }) => label));
  }

  async __loadAppointmentTypes() {
    const res = await getAppointmentTypes();

    this.__appointmentTypes = res.data.map(a => ({ data: a, label: a.name }));
  }

  async connectedCallback() {
    this.__connectServices();

    await this.__loadAppointmentTypes();
    super.connectedCallback();
  }

  disconnectedCallback() {
    this.__disconnectServices();
    super.disconnectedCallback();
  }

  renderContent() {
    return html`
      <neb-form-appointment
        id="${ELEMENTS.form.id}"
        class="form"
        saveBlockerLabel="${this.model.appointmentId
          ? 'Rescheduling Appointment'
          : 'Saving Appointment'}"
        .layout="${this.layout}"
        .model="${this.model}"
        .providers="${this.__providers}"
        .locations="${this.__locations}"
        .disableSaveIndicator="${this.__disableSaveIndicator}"
        .appointmentTypes="${this.__appointmentTypes}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onSave="${this.handlers.save}"
      ></neb-form-appointment>
    `;
  }
}

window.customElements.define(
  'neb-overlay-appointment-form',
  NebOverlayAppointmentForm,
);
