const ACTION_SAVE_MODEL = 'ACTION_SAVE_MODEL';

export const saveModel = ({ store, encounterId, categoryType, model }) =>
  store.dispatch({
    type: ACTION_SAVE_MODEL,
    encounterId,
    categoryType,
    model,
  });

export const loadModel = ({ encounterId, categoryType, store }) => {
  const { hotButtons } = store.getState();

  if (hotButtons.encounterId !== encounterId) {
    return null;
  }

  return hotButtons.panelName[categoryType];
};

const EMPTY_STATE = {
  panelName: {},
};

export const hotButtonsReducer = (state = EMPTY_STATE, action) => {
  switch (action.type) {
    case ACTION_SAVE_MODEL: {
      const { encounterId, categoryType, model } = action;

      const oldState =
        state.encounterId === encounterId ? { ...state } : { ...EMPTY_STATE };

      const newState = {
        encounterId,
        panelName: { ...oldState.panelName, [categoryType]: model },
      };

      return {
        ...oldState,
        ...newState,
      };
    }
    default:
      return state;
  }
};
