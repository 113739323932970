import '../../neb-spine';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { capitalize } from '../../../../../neb-utils/formatters';
import { DISK_COUNTS } from '../../../../../neb-utils/listings';
import { getValueByPath } from '../../../../../neb-utils/utils';

export const ELEMENTS = {
  spine: { id: 'spine' },
  texts: { selector: 'text' },
};

const METADATA = {
  C: {
    key: 'cDisks',
    prefix: 'C',
    range: new Array(DISK_COUNTS.C).fill(0).map((_, index) => index),
  },
  T: {
    key: 'tDisks',
    prefix: 'T',
    range: new Array(DISK_COUNTS.T).fill(0).map((_, index) => index),
  },
  L: {
    key: 'lDisks',
    prefix: 'L',
    range: new Array(DISK_COUNTS.L).fill(0).map((_, index) => index),
  },
};

class ListingsSummary extends LitElement {
  static get properties() {
    return {
      model: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
        }

        .container-labels {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
          height: fit-content;
          max-height: 95%;
        }

        .container-labels-left {
          left: 0;
          padding: 0 35px 0 10px;
        }

        .container-labels-right {
          right: 0;
          padding: 0 10px 0 35px;
        }

        text {
          width: 100%;
          height: 1.29vh;

          font-size: 0.9vh;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 1.29vh;
        }

        text[class*='occiput'] {
          height: 1.6vh;
        }

        text[class*='cDisks'] {
          height: 1.7vh;
        }

        text[class*='tDisks'] {
          height: 1.7vh;
        }

        text[class*='lDisks'] {
          height: 1.7vh;
        }

        text[class*='sacrum'] {
          height: 1.7vh;
          margin-top: 0.2vh;
        }

        text[class*='pelvis'] {
          height: 1.7vh;
          margin-top: 0.8vh;
        }

        text[class*='coccyx'] {
          height: 1.7vh;
          margin-top: 0.3vh;
        }
      `,
    ];
  }

  __getFieldData(keyPath) {
    return {
      name: keyPath.join('.'),
      value: getValueByPath(this.model, keyPath),
    };
  }

  __renderText(keyPath) {
    const data = this.__getFieldData([...keyPath, 'issue']);
    const kabab = data.name.split('.').join('-');

    return html`
      <text id="${kabab}" class="text ${kabab}">${data.value}</text>
    `;
  }

  __renderLeftField(rootPath) {
    return html` ${this.__renderText([...rootPath, 'left'])} `;
  }

  __renderRightField(rootPath) {
    return html` ${this.__renderText([...rootPath, 'right'])} `;
  }

  __renderDisks(side) {
    const methodName = `__render${capitalize(side)}Field`;

    return Object.values(METADATA).reduce(
      (accum, curr) => [
        accum,
        ...curr.range.map(i => this[methodName]([curr.key, i])),
      ],
      [],
    );
  }

  render() {
    return this.model
      ? html`
          <div class="container">
            <div class="container-labels container-labels-left">
              ${this.__renderLeftField(['occiput'], 'Occiput')}
              ${this.__renderDisks('left')}
              ${this.__renderLeftField(['sacrum'], 'Sacrum')}
              ${this.__renderLeftField(['pelvis'], 'Pelvis')}
              ${this.__renderLeftField(['coccyx'], 'Coccyx')}
            </div>

            <neb-spine
              id="${ELEMENTS.spine.id}"
              .model="${this.model}"
            ></neb-spine>

            <div class="container-labels container-labels-right">
              ${this.__renderRightField(['occiput'], 'Occiput')}
              ${this.__renderDisks('right')}
              ${this.__renderRightField(['sacrum'], 'Sacrum')}
              ${this.__renderRightField(['pelvis'], 'Pelvis')}
              ${this.__renderRightField(['coccyx'], 'Coccyx')}
            </div>
          </div>
        `
      : html``;
  }
}

window.customElements.define('neb-listings-summary', ListingsSummary);
