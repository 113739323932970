import { html } from 'lit';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';
import '../../controllers/claim-errors/neb-manage-encounter-controller';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  manageEncounterController: { id: 'manage-encounter-controller' },
};

class NebOverlayClaimErrorManageEncounter extends NebOverlayClaimError {
  static get properties() {
    return {
      __formattedEncounterModel: { type: Object },
    };
  }

  initState() {
    super.initState();
    this.__formattedEncounterModel = {};
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('model')) {
      const invoiceEncounters = this.model.claim.claimCharges.map(
        charge => charge.encounterId,
      );

      this.__formattedEncounterModel = {
        encounterIds: invoiceEncounters,
        patientId: this.model.patientId,
      };
    }
  }

  renderForm() {
    return html`
      <neb-manage-encounter-controller
        id="${ELEMENTS.manageEncounterController.id}"
        .model="${this.__formattedEncounterModel}"
        .onCancel="${this.handlers.dismiss}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .hideEncounterTableDetails="${true}"
      ></neb-manage-encounter-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-manage-encounter',
  NebOverlayClaimErrorManageEncounter,
);
