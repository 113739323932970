import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
  CSS_COLOR_GREY_1,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
  baseStyles,
} from '../../../../../src/styles';
import { getRTEStatusIcon } from '../../../../../src/utils/real-time-eligibility';
import { hidePropIfEncounterOnly } from '../../utils/encounter-only-util';

import '../../../../../src/components/misc/neb-icon';

export const ELEMENTS = {
  encounterNumber: { id: 'encounter-number' },
  icon: { id: 'icon' },
  iconArrow: { id: 'icon-arrow' },
  iconConfirmedAt: { id: 'icon-confirmed' },
  iconLoop: { id: 'icon-loop' },
  iconRTEStatus: { id: 'icon-rte-status' },
  iconStatus: { id: 'icon-status' },
  labelDuration: { id: 'label-duration' },
  markerColor: { id: 'marker-color' },
  providerName: { id: 'provider-name' },
  serviceDate: { id: 'service-date' },
  serviceTime: { id: 'service-time' },
  serviceType: { id: 'service-type' },
  title: { id: 'title' },
  profilePhoto: { id: 'profile-photo' },
  patientLabel: { id: 'patient-label' },
  appointmentType: { id: 'appointment-type' },
  location: { id: 'location' },
};

class NebEncounterCard extends LitElement {
  static get properties() {
    return {
      default: Boolean,
      arrived: {
        type: Boolean,
        reflect: true,
      },
      selected: {
        type: Boolean,
        reflect: true,
      },
      showArrowIcon: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          cursor: pointer;
          display: block;
          border-bottom: ${CSS_BORDER_GREY_2};
          width: 100%;
        }

        :host([selected]) {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        :host(:not([selected])):hover {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .container {
          display: grid;
          grid-template-columns: auto 1fr;
        }

        .marker {
          display: block;
          width: 12px;
        }

        .content {
          display: grid;
          grid-template-columns: minmax(65px, 2fr) minmax(70px, 3fr) auto;
          grid-template-rows: auto ${CSS_SPACING};
          row-gap: ${CSS_SPACING_ROW_LARGE};
          align-items: center;
          padding: ${CSS_SPACING} 12px;
        }

        :host([showArrowIcon]) .content {
          grid-template-columns: minmax(65px, 2fr) minmax(70px, 3fr) auto auto;
        }

        :host([arrived]) .content {
          font-style: italic;
        }

        .padding-right {
          padding-right: 5px;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .overflow-ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .location {
          grid-column: span 2;
        }

        .container-icons {
          display: grid;
          grid-auto-rows: min-content;
          grid-gap: ${CSS_SPACING_ROW_LARGE};
          grid-template-columns: 1fr 1fr;
          align-items: center;
        }

        .icon {
          display: flex;
          align-self: center;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-rte-status {
          display: flex;
          align-self: center;
          width: 20px;
          height: 20px;

          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated {
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .icon-arrow {
          display: flex;
          align-self: center;
          height: 14px;
          width: 14px;
          grid-row: span 2;

          transform: rotate(-90deg);
          margin-left: 10px;
          fill: ${CSS_COLOR_GREY_1};
        }

        :host([selected]) .icon,
        :host([selected]) .icon-arrow {
          fill: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.arrived = false;
    this.default = false;
    this.selected = false;
    this.showArrowIcon = false;
    this.layout = '';
    this.model = {};

    this.onDisplay = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      display: () => this.onDisplay(this.model),
    };
  }

  __getPatientName({ patient, firstName, lastName }) {
    this.model = {
      ...this.model,
      patient: {
        ...this.model.patient,
        name: patient.name || {
          first: firstName,
          last: lastName,
          middle: null,
        },
      },
    };

    return this.model.patient.name;
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.arrived = !!this.model.arrivedAt && this.model.status === 'Active';
    }
  }

  __renderIconArrow() {
    return this.showArrowIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.iconArrow.id}"
            class="icon-arrow"
            icon="neb:chevron"
          ></neb-icon>
        `
      : '';
  }

  __renderIconConfirmedAt() {
    return this.model.confirmedAt
      ? html`
          <neb-icon
            id="${ELEMENTS.iconConfirmedAt.id}"
            class="icon"
            icon="neb:confirmed"
          ></neb-icon>
        `
      : '';
  }

  __renderIconLoop() {
    return this.model.recurrenceEventId
      ? html`
          <neb-icon
            id="${ELEMENTS.iconLoop.id}"
            class="icon icon-loop"
            icon="neb:loop"
          ></neb-icon>
        `
      : '';
  }

  __renderIconRTEStatus() {
    const { realTimeEligibilityStatus } = this.model;

    return realTimeEligibilityStatus
      ? html`
          <neb-icon
            id="${ELEMENTS.iconRTEStatus.id}"
            class="icon-rte-status icon-${realTimeEligibilityStatus}"
            .icon="${getRTEStatusIcon(realTimeEligibilityStatus)}"
          ></neb-icon>
        `
      : html``;
  }

  __renderIconSigned() {
    return this.model.encounterNumber
      ? html`
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon icon-signed"
            .icon="${this.model.signed ? 'neb:signed' : 'neb:unsigned'}"
          ></neb-icon>
        `
      : '';
  }

  __renderIconStatus() {
    const { status, arrivedAt } = this.model;

    let icon;

    switch (status) {
      case 'Checked-In':
        icon = 'neb:checkedIn';
        break;

      case 'Checked-Out':
        icon = 'neb:checkedOut';
        break;

      case 'Active':
        icon = arrivedAt ? 'neb:arrived' : '';
        break;

      default:
        icon = '';
    }

    return icon
      ? html`
          <neb-icon
            id="${ELEMENTS.iconStatus.id}"
            class="icon icon-status"
            .icon="${icon}"
          ></neb-icon>
        `
      : '';
  }

  __renderMarker() {
    return html`
      <div
        id="${ELEMENTS.markerColor.id}"
        class="marker"
        style="background-color: ${this.model.markerColor};"
      ></div>
    `;
  }

  __encounterNumber() {
    return html`
      ${this.model.encounterNumber}${this.model.signed
        ? html` - <i>Signed</i> `
        : ''}
    `;
  }

  __renderCard() {
    return html`
      ${this.__renderMarker()}

      <div class="content">
        <div class="padding-right overflow-ellipsis">
          <div id="${ELEMENTS.encounterNumber.id}" class="bold">
            ${this.__encounterNumber()}
          </div>

          <div id="${ELEMENTS.serviceDate.id}" class="bold overflow-ellipsis">
            ${this.model.formattedServiceDate}
          </div>

          <div id="${ELEMENTS.serviceTime.id}">
            ${hidePropIfEncounterOnly(this.model, 'formattedServiceTime')}
          </div>
        </div>
        <div class="padding-right overflow-ellipsis">
          <div id="${ELEMENTS.providerName.id}" class="bold overflow-ellipsis">
            ${this.model.provider}
          </div>

          <div
            id="${ELEMENTS.serviceType.id}"
            class="service-type overflow-ellipsis"
          >
            ${hidePropIfEncounterOnly(this.model, 'appointmentType')}
          </div>
        </div>

        <div class="container-icons">
          ${this.__renderIconRTEStatus()} ${this.__renderIconStatus()}
          ${this.__renderIconLoop()} ${this.__renderIconSigned()}
        </div>

        ${this.__renderIconArrow()}

        <div id="${ELEMENTS.location.id}" class="location overflow-ellipsis">
          ${this.model.locationName}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.default
          ? html`
              ${this.__renderMarker()}

              <div class="content">
                <div id="${ELEMENTS.title.id}" class="title">
                  ${this.model.title}
                </div>
              </div>
            `
          : this.__renderCard()}
      </div>
    `;
  }
}

customElements.define('neb-encounter-card', NebEncounterCard);
