import { css } from 'lit';

import { MONTH_DAY_YEAR } from '../../../../packages/neb-input/nebFormatUtils';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { CSS_BANNER_SUCCESS_COLOR, CSS_WARNING_COLOR } from '../../../styles';
import {
  ADJUSTMENT_CODE,
  ADJUSTMENT_IDENTIFIER,
} from '../../../utils/user-message';

import getClaimErrorMessage from './neb-era-report-data/get-claim-error-message';
import hasReversalOrUnmatchedClaimWarning from './neb-era-report-data/has-reversal-or-unmatched-claim-warning';

const columnFormatter = (v, isCurrency = false) => {
  if (!v) return '-';

  if (isCurrency) return `$${v}`;

  return v;
};

export const PROCESSING_CLAIMS_ERROR_MESSAGE =
  'Error while processing claims. Please check claims for more details.';

export const PROCESSING_LINE_ITEMS_ERROR_MESSAGE =
  'Error while processing line items. Please check line items for more details.';

export const CLAIM_WARNINGS = {
  CLAIM_IS_A_REVERSAL: 'Claim is a reversal.',
  UNMATCHED_PATIENT_CONTROL_NUMBER:
    'Patient control number does not match to a claim in the system.',
  INSUFFICIENT_REMAINING_PAYMENT:
    'Check line items for insufficient payment balance to post',
  REVERSAL_ZERO_PAYMENT:
    'Includes $0 issued, resulting in no money to reverse.',
};

export const CHARGE_WARNINGS = {
  LINE_ITEM_IS_OUT_OF_BALANCE: 'Line item is out of balance.',
  MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM:
    'Matching charge cannot be found in system',
  INSUFFICIENT_REMAINING_PAYMENT:
    'Insufficient remaining payment balance to fully allocate charge.',
};

export const ERA_STATUSES = {
  POSTED: 'Posted',
  WARNING: 'Warning',
  READY_TO_POST: 'Ready to Post',
  MATCH: 'Match',
  UPDATING: 'Updating',
};

export const iconByStatus = {
  [ERA_STATUSES.POSTED]: {
    icon: 'neb:priceCheck',
    css: css`
      fill: ${CSS_BANNER_SUCCESS_COLOR};
    `,
  },
  [ERA_STATUSES.WARNING]: {
    icon: 'neb:warning',
    css: css`
      fill: ${CSS_WARNING_COLOR};
    `,
  },
  [ERA_STATUSES.READY_TO_POST]: {
    icon: 'neb:allocate',
    css: css`
      fill: ${CSS_BANNER_SUCCESS_COLOR};
    `,
  },
  [ERA_STATUSES.UPDATING]: {
    icon: 'updating',
  },
};

export const ERA_DETAILS_TYPES = {
  ERA_LEVEL: 'ERA Level',
  CLAIM_LEVEL: 'Claim Level',
  LINE_ITEM_LEVEL: 'Line Item Level',
};

export const WRITTEN_OFF_STATUS_MESSAGE = {
  [ERA_DETAILS_TYPES.CLAIM_LEVEL]:
    'One or more charges are being fully written off in this claim.',
  [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]:
    'This line item is being fully written off.',
};

export function ERA_DETAILS_TABLE_CONFIG() {
  return {
    [ERA_DETAILS_TYPES.ERA_LEVEL]: [
      {
        key: 'eraPayerName',
        label: 'ERA payer',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'date',
        label: 'Date',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'authEFT',
        label: 'Auth/Check/EFT#',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'providerName',
        label: 'Provider',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'providerNPI',
        label: 'Provider #',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
    ],
    [ERA_DETAILS_TYPES.CLAIM_LEVEL]: [
      {
        key: 'patientName',
        label: 'Patient Name',
        flex: css`2 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'insuredName',
        label: 'Insured Name',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'memberId',
        label: 'Member ID #',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'insuredId',
        label: 'Insured ID #',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'patientMedicalRecordNumber',
        label: 'Patient Acct. #',
        flex: css`2 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
    ],
    [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: [
      {
        key: 'serviceDate',
        label: 'Service Date',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'procedureCode',
        label: 'Proc + Mod',
        flex: css`1.5 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'units',
        label: 'Units',
        flex: css`0.3 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'billed',
        label: 'Billed',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'allowed',
        label: 'Allowed',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'paid',
        label: 'Paid',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'renderingNPI',
        label: 'Rendering NPI',
        flex: css`0.7 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
    ],
  };
}

export const CLAIM_DETAILS_TABLE_CONFIG = [
  {
    key: 'claimId',
    label: 'Claim ID',
    flex: css`2 0 0`,
    truncate: false,
    formatter: v => columnFormatter(v),
  },
  {
    key: 'claimStatus',
    label: 'Claim Status',
    flex: css`2.7 0 0`,
    truncate: false,
    formatter: v => columnFormatter(v),
  },
];

export function ERA_PAYMENT_DETAILS_TABLE_CONFIG() {
  return {
    [ERA_DETAILS_TYPES.ERA_LEVEL]: [
      {
        key: 'checkAmount',
        label: 'Check Amount',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'allocated',
        label: 'Total Allocated In File',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'billed',
        label: 'Total Billed',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'allowed',
        label: 'Total Allowed',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'adjusted',
        label: 'Total Claim Adjusted',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
    ],
    [ERA_DETAILS_TYPES.CLAIM_LEVEL]: [
      {
        key: 'providerName',
        label: 'Rendering Provider',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'providerNPI',
        label: 'Provider #',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'adjusted',
        label: 'Claim Adj.',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'issued',
        label: 'Issued Amt.',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'patientResponsibility',
        label: 'Patient Resp.',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'patientResponsibilityReason',
        label: 'Patient Resp. Reason',
        flex: css`1.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'spacer',
        label: '',
        flex: css`.1 0 0`,
      },
    ],
    [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: [
      {
        key: 'deduct',
        label: 'Ded',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'coins',
        label: 'Coins',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'copay',
        label: 'Copay',
        flex: css`0.8 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v, true),
      },
      {
        key: 'patientAdjustments',
        label: 'Other Pat. Resp.',
        flex: css`1.5 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'adjustments',
        label: 'Adjustment',
        flex: css`4 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
      {
        key: 'remarkCodes',
        label: 'Remark Codes',
        flex: css`1 0 0`,
        truncate: true,
        formatter: v => columnFormatter(v),
      },
    ],
  };
}

export const ERA_ADJUSTMENTS_TABLE_CONFIG = [
  {
    key: 'adjustmentAmount',
    label: 'Remit Level Adjustment',
    flex: css`1 0 0`,
    truncate: true,
    formatter: v => columnFormatter(v, true),
  },
  {
    key: 'adjustmentCode',
    label: `${ADJUSTMENT_CODE}`,
    flex: css`2 0 0`,
    truncate: true,
  },
  {
    key: 'adjustmentIdentifier',
    label: `${ADJUSTMENT_IDENTIFIER}`,
    flex: css`1 0 0`,
    truncate: true,
  },
];

export const hasErrorMessage = errorMessage =>
  Array.isArray(errorMessage) ? errorMessage.length : errorMessage;

const checkClaimFailed = ({ errorMessage = '', lineItems = [] } = {}) =>
  hasErrorMessage(errorMessage) ||
  lineItems.some(({ errorMessage: failed }) => hasErrorMessage(failed));

const checkERAFailed = ({ errorMessage, claims }) =>
  hasErrorMessage(errorMessage) || claims.some(checkClaimFailed);

const checkERAPosted = ({ claims = [], posted }) => {
  const lineItems = claims.flatMap(({ lineItems: li }) => li);
  return (
    posted ||
    claims.every(claim => !!claim.posted) ||
    lineItems.every(lineItem => !!lineItem.posted)
  );
};

export const getERAStatus = era => {
  const posted = checkERAPosted(era);
  if (posted) return ERA_STATUSES.POSTED;
  if (era.isUpdating) return ERA_STATUSES.UPDATING;

  const failed = checkERAFailed(era);
  if (failed) return ERA_STATUSES.WARNING;

  return ERA_STATUSES.READY_TO_POST;
};

const checkClaimPosted = ({ lineItems = [], posted }) =>
  posted || lineItems.every(lineItem => lineItem.posted);

export const getClaimStatus = (eraFailed, claim) => {
  const posted = checkClaimPosted(claim);
  if (posted) return ERA_STATUSES.POSTED;
  if (claim.isUpdating) return ERA_STATUSES.UPDATING;

  if (hasErrorMessage(eraFailed)) return ERA_STATUSES.WARNING;

  const failed = checkClaimFailed(claim);
  if (failed) return ERA_STATUSES.WARNING;

  return ERA_STATUSES.READY_TO_POST;
};

export const getLineItemStatus = ({
  eraFailed,
  claimFailed,
  lineItemFailed,
  posted,
  isUpdating,
}) => {
  if (posted) return ERA_STATUSES.POSTED;
  if (isUpdating) return ERA_STATUSES.UPDATING;

  if (
    hasErrorMessage(eraFailed) ||
    hasReversalOrUnmatchedClaimWarning(claimFailed) ||
    hasErrorMessage(lineItemFailed)
  ) {
    return ERA_STATUSES.WARNING;
  }

  return ERA_STATUSES.READY_TO_POST;
};

const getEraErrorMessage = ({ claims, errorMessage, posted }) => {
  if (posted) return '';

  if (errorMessage?.length) return errorMessage;

  const claimFailed = claims.some(checkClaimFailed);
  return claimFailed ? PROCESSING_CLAIMS_ERROR_MESSAGE : '';
};

const buildModelForERALevel = ({
  eraPayerName,
  date,
  authEFT,
  providerName,
  providerNPI,
  checkAmount,
  reversals,
  allocated,
  billed,
  allowed,
  adjusted,
  claims,
  errorMessage,
  posted,
  eraAdjustments = [],
  isUpdating,
}) => [
  {
    eraPayerName,
    date: date && parseDate(date).format(MONTH_DAY_YEAR),
    authEFT,
    providerName,
    providerNPI,
    checkAmount,
    reversals,
    allocated,
    billed,
    allowed,
    adjusted,
    status: getERAStatus({ claims, errorMessage, posted }),
    statusMessage: getEraErrorMessage({ claims, errorMessage, posted }),
    eraAdjustments,
    ...(isUpdating && { isUpdating }),
  },
];

const formatPatientResponsibilityReason = reasons =>
  reasons ? [...new Set(reasons.split(', '))].join(', ') : reasons;

const buildModelForClaimLevel = ({
  id,
  patientName,
  insuredName,
  claimId,
  claimStatus,
  memberId,
  insuredId,
  patientMedicalRecordNumber,
  providerName,
  providerNPI,
  adjusted,
  issued,
  patientResponsibility,
  patientResponsibilityReason,
  lineItems,
  errorMessage,
  posted,
  isUpdating,
}) => [
  {
    patientName,
    insuredName,
    claimId,
    claimStatus,
    memberId,
    insuredId,
    patientMedicalRecordNumber,
    providerName,
    providerNPI,
    adjusted,
    issued,
    patientResponsibility,
    patientResponsibilityReason: formatPatientResponsibilityReason(
      patientResponsibilityReason,
    ),
    status: getClaimStatus(errorMessage, {
      id,
      errorMessage,
      lineItems,
      posted,
    }),
    statusMessage: getClaimErrorMessage({
      lineItems,
      errorMessage,
      posted,
    }),
    ...(isUpdating && { isUpdating }),
  },
];

const CALCULATED_ADJ_CODES = ['PR-1', 'PR-2', 'PR-3'];

const buildAdj = adjs => {
  const [adjAmount, adjCodes] = adjs
    .filter(({ code }) => !CALCULATED_ADJ_CODES.includes(code))
    .reduce(
      ([totalAmount, codes], { code, amount }) => [
        totalAmount + parseFloat(amount),
        [...codes, code],
      ],
      [0, []],
    );
  if (!adjAmount) return '';
  return `$${adjAmount.toFixed(2)} - ${adjCodes.join(', ')}`;
};

const buildModelForLineItemLevel = ({
  id,
  serviceDate,
  procedureCode,
  units,
  paymentInfo,
  renderingNPI,
  patientAdjustments,
  adjustments,
  remarkCodes,
  errorMessage: statusMessage,
  posted,
  claimStatus,
  claim: { patientMedicalRecordNumber, errorMessage: claimErrorMessage },
  isUpdating,
}) => [
  {
    id,
    serviceDate,
    procedureCode,
    units,
    ...paymentInfo,
    renderingNPI,
    patientAdjustments: buildAdj(patientAdjustments || []),
    adjustments: buildAdj(adjustments || []),
    remarkCodes: remarkCodes?.join(', '),
    status: getLineItemStatus({
      claimFailed: claimErrorMessage,
      lineItemFailed: statusMessage,
      posted,
    }),
    statusMessage,
    claimStatus,
    patientMedicalRecordNumber,
    ...(isUpdating && { isUpdating }),
  },
];

export const buildERADetailsModel = ({
  model = {},
  selectedNodeType = ERA_DETAILS_TYPES.ERA_LEVEL,
}) => {
  if (selectedNodeType === ERA_DETAILS_TYPES.ERA_LEVEL) {
    return buildModelForERALevel(model);
  }

  if (selectedNodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL) {
    return buildModelForClaimLevel(model);
  }

  if (selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL) {
    return buildModelForLineItemLevel(model);
  }

  return [];
};

export const LEVEL_DESCRIPTION_BY_SELECTED_NODE_TYPE = {
  [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: 'Line Item',
  [ERA_DETAILS_TYPES.CLAIM_LEVEL]: 'Claim',
  [ERA_DETAILS_TYPES.ERA_LEVEL]: 'ERA',
};
