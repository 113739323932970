import hasReversalOrUnmatchedClaimWarning from './has-reversal-or-unmatched-claim-warning';

const reuseClaimWarnings = claim =>
  hasReversalOrUnmatchedClaimWarning(claim.errorMessage)
    ? claim.errorMessage
    : '';

export default ({ posted, errorMessage, claim }) => {
  const lineItemErrorMessage =
    !Array.isArray(errorMessage) || errorMessage.length ? errorMessage : '';
  return posted ? '' : lineItemErrorMessage || reuseClaimWarnings(claim);
};
