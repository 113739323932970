import { html, css } from 'lit';

import '../tables/era-eob/neb-table-select-era-eob';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../styles';
import '../../../packages/neb-lit-components/src/components/neb-action-bar';

const SUBTITLE_TEXT =
  'This charge is associated with multiple ERAs/EOBs, select from the list below to view an ERA/EOB.';
export const ELEMENTS = {
  ...BASE_ELEMENTS,
  actionBar: { id: 'action-bar' },
  subText: { id: 'sub-text' },
  table: { id: 'table' },
};
class NebPopupSelectEraEob extends NebPopup {
  static get properties() {
    return {
      __selectedIndex: Number,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.title = 'Select ERA/EOB';
    this.model = { data: [] };

    this.__selectedIndex = 0;
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      selectItem: index => {
        this.__selectedIndex = index;
      },
      confirm: () => {
        this.onClose({ selectedIndex: this.__selectedIndex });
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content-container {
          padding: 0px;
        }

        .table {
          overflow-y: auto;
        }

        .container {
          padding: 0px;
        }

        .header {
          padding: ${CSS_SPACING};
          padding-bottom: 0px;
        }

        .content {
          margin-top: 0px;
        }
        .sub-text {
          padding-left: ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="sub-text" id="${ELEMENTS.subText.id}">
          <span>${SUBTITLE_TEXT}</span>
        </div>
        <div class="table">
          <neb-table-select-era-eob
            id="${ELEMENTS.table.id}"
            .model="${this.model.data}"
            .onSelectItem="${this.handlers.selectItem}"
          ></neb-table-select-era-eob>
        </div>
      </div>
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="View"
        cancelLabel="Cancel"
        .onCancel="${this.onClose}"
        .onConfirm="${this.handlers.confirm}"
      ></neb-action-bar>
    `;
  }
}

customElements.define('neb-popup-select-era-eob', NebPopupSelectEraEob);
