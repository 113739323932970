const isEmpty = value =>
  value === null || value === undefined || value.toString().length === 0;

const isMet = value => value !== null && value !== '' && value === '$0.00';

const isNumeric = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

const convertCurrency = amount => {
  if (isEmpty(amount)) return '';
  return amount ? Number(amount.replace(/[^0-9.]/g, '')) : 0;
};

const formatCurrency = amount => {
  if (isEmpty(amount)) return '';
  return amount
    ? convertCurrency(String(amount)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      })
    : '$0.00';
};

const __convertUpperCase = description => {
  const converted = description
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return converted;
};

const getMatchingServiceTypes = (serviceTypes, allServiceTypes) => {
  const matchingServiceTypes = serviceTypes.reduce(
    (memo, { serviceTypeId }) => {
      const match = allServiceTypes.find(({ id }) => id === serviceTypeId);
      if (match) memo.push(match);
      return memo;
    },
    [],
  );
  return matchingServiceTypes
    .map(
      ({ code, description }) => `${code} - ${__convertUpperCase(description)}`,
    )
    .join(', ');
};

const INSURANCE_LEVELS = Object.freeze({
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary',
});

const sortServiceTypes = serviceTypes =>
  serviceTypes.sort(({ code: a }, { code: b }) =>
    (isNumeric(a) ? `0${a}` : a).localeCompare(
      isNumeric(b) ? `0${b}` : b,
      'en',
      { numeric: true },
    ),
  );

export {
  convertCurrency,
  formatCurrency,
  getMatchingServiceTypes,
  sortServiceTypes,
  isEmpty,
  isMet,
  INSURANCE_LEVELS,
};
