import '../../../../src/components/misc/neb-icon';
import '../../../neb-lit-components/src/components/neb-user-menu';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import {
  USER_MENU_OPTIONS,
  LOGOUT,
} from '../../../neb-lit-components/src/components/neb-nav-secondary-base';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { LAYOUT_TYPE } from '../../../neb-redux/services/layout';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  toggleOpenButton: {
    id: 'button-toggle-open',
  },
  userMenu: {
    id: 'user-menu',
  },
  logoIcon: {
    id: 'icon-logo',
  },
};
export const ID_BUTTON_TOGGLE_OPEN = 'button-toggle-open';
const { LARGE, SMALL } = LAYOUT_TYPE;

class NebNavPrimaryHeader extends LitElement {
  static get properties() {
    return {
      __menuItems: { type: Array },
      expanded: {
        type: Boolean,
        reflect: true,
      },
      user: { type: Object },
      apiOverride: { type: String },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__menuItems = [...USER_MENU_OPTIONS];
    this.__menuOptions = {
      [USER_MENU_OPTIONS[0].label]: () =>
        this.onUserMenuOptions(USER_MENU_OPTIONS[0].label, {
          userId: this.user.id,
        }),
      [USER_MENU_OPTIONS[1].label]: () => this.__promptChangePractice(),
      [USER_MENU_OPTIONS[2].label]: () => this.__promptLogOut(),
    };

    this.apiOverride = '';
    this.expanded = true;
    this.layout = LARGE;
    this.user = {
      tenantIds: [],
    };

    this.onMenuToggled = () => {};

    this.onUserMenuOptions = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      menuToggled: () => this.onMenuToggled(),
      action: ({ label }) => this.__menuOptions[label](),
    };
  }

  async __promptChangePractice() {
    const { tenantId, tenantIds } = this.user;
    const selectedTenantId = await openPopup(
      POPUP_RENDER_KEYS.CHANGE_PRACTICE,
      { tenantIds, apiOverride: this.apiOverride },
    );

    if (selectedTenantId && selectedTenantId !== tenantId) {
      this.onUserMenuOptions(USER_MENU_OPTIONS[1].label, {
        tenantId: selectedTenantId,
      });
    }
  }

  async __promptLogOut() {
    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, LOGOUT);
    if (accepted) this.onUserMenuOptions(USER_MENU_OPTIONS[2].label);
  }

  update(changedProps) {
    if (changedProps.has('user')) {
      this.__menuItems =
        this.user.tenantIds && this.user.tenantIds.length > 1
          ? [...USER_MENU_OPTIONS]
          : [USER_MENU_OPTIONS[0], USER_MENU_OPTIONS[2]];
    }

    super.update(changedProps);
  }

  static get styles() {
    return css`
      .container {
        display: flex;
        align-items: center;
        height: 75px;
        width: 100%;

        margin-bottom: 15px;
        padding: 0 15px;
        box-sizing: border-box;
      }

      :host([layout='small']) .container {
        justify-content: space-between;
        padding: 0 20px;
        margin: 0;
      }

      .icon-logo {
        display: flex;
        width: 100%;
        height: 40px;
      }

      :host([layout='small']) .icon-logo {
        width: 240px;
        margin-left: 9px;
      }

      :host([expanded]) .logo,
      :host([layout='small']) .logo {
        height: auto;
        width: 240px;
      }

      .title-label {
        color: ${CSS_COLOR_WHITE};
        font-size: 20px;
        font-weight: bold;
        margin-left: 8px;
      }

      .icon-menu {
        height: 25px;
        width: 25px;
        cursor: pointer;
      }

      .icon-user {
        height: 30px;
        width: 30px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  __renderHamburgerButton() {
    return this.layout === SMALL
      ? html`
          <neb-icon
            id="${ELEMENTS.toggleOpenButton.id}"
            class="icon-menu"
            icon="neb:menu"
            @click="${this.__handlers.menuToggled}"
          ></neb-icon>
        `
      : '';
  }

  __getLogo() {
    return this.expanded || this.layout === SMALL ? 'neb:logo' : 'neb:logoMark';
  }

  __renderUserMenu() {
    return this.layout === SMALL
      ? html`
          <neb-user-menu
            id="${ELEMENTS.userMenu.id}"
            class="icon-user"
            .items="${this.__menuItems}"
            .layout="${this.layout}"
            .model="${this.user}"
            .onSelect="${this.__handlers.action}"
          ></neb-user-menu>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderHamburgerButton()}

        <neb-icon
          id="${ELEMENTS.logoIcon.id}"
          class="icon icon-logo"
          .icon="${this.__getLogo()}"
        ></neb-icon>

        ${this.__renderUserMenu()}
      </div>
    `;
  }
}

customElements.define('neb-nav-primary-header', NebNavPrimaryHeader);
