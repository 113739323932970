import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_ERROR,
  CSS_COLOR_DISABLED,
  CSS_FIELD_MARGIN,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  text: {
    id: 'text',
  },
};

class HelperText extends LitElement {
  static get properties() {
    return {
      invalid: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      text: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.text = '';
    this.invalid = false;
    this.disabled = false;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          min-width: 0;
        }

        .container {
          display: flex;
          min-height: ${CSS_FIELD_MARGIN};
          height: auto;
          width: 100%;
        }

        :host([text='']) {
          height: 0;
        }

        .row-data: {
          align-items: center;
        }

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: normal;
          margin: 0 6px;
          width: 100%;
          min-width: 0;
          font-size: 10.5px;
          letter-spacing: 0.35px;
          color: ${CSS_COLOR_GREY_1};
        }

        :host([text=' ']) .text {
          opacity: 0;
        }

        :host([invalid]:not([disabled])) .text {
          color: ${CSS_COLOR_ERROR};
        }

        :host([disabled]) .text {
          color: ${CSS_COLOR_DISABLED};
        }
      `,
    ];
  }

  render() {
    return this.text
      ? html`
          <div class="container">
            <span id="${ELEMENTS.text.id}" class="text"
              >${this.text !== ' ' ? this.text : '_'}
            </span>
          </div>
        `
      : html``;
  }
}

window.customElements.define('neb-text-helper', HelperText);
