import { openPopup } from '@neb/popup';

import { formatClaimLine } from '../../../../../neb-api-client/src/formatters/claims';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { defaultMask } from '../../../../../neb-utils/masks';

const formatClaimInformation = items => {
  const itemFormat = {
    name: '',
    maxLength: 71,
    label: '',
    value: { displayLabel: '', displayValue: '', enabled: true },
  };

  const customCode = 'Custom Code';

  const results = items.map((a, index) => ({
    ...itemFormat,
    name: `row${index}`,
    label: a.readOnly ? a.label : customCode,
    sortOrder: a.sortOrder,
    readOnly: a.readOnly,
    value: {
      displayLabel: a.codeGroup
        ? `${a.codeGroup} - (${a.code}) ${a.description} - ${a.note}`
        : a.note,
      displayValue: formatClaimLine(a),
      enabled: a.enabled,
    },
  }));

  if (results.filter(x => x.readOnly === false).length === 0) {
    results.push({
      ...itemFormat,
      name: `row${results.length}`,
      label: customCode,
      sortOrder: results.length,
      readOnly: false,
      value: {
        displayLabel: '',
        displayValue: '',
        enabled: false,
      },
    });
  }

  return results;
};

export default async ({ claimCellData, state, handlers, formService }) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_MULTI_SELECT_INPUT, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    items: [
      {
        name: 'additionalClaimInformation',
        maxLength: 71,
        label: 'Additional Claim Information',
        value: state.additionalClaimInformation,
        items: formatClaimInformation(state.claimAdditionalInfos),
        width: '100%',
        mask: defaultMask,
      },
    ],
  });

  if (result) {
    if (claimCellData.name === 'additionalClaimInformation') {
      const additionalClaim = Object.values(result).reduce(
        (memo, val) =>
          val.value.enabled
            ? `${memo}${memo.length > 0 ? '   ' : ''}${val.value.displayValue}`
            : `${memo}`,
        '',
      );

      formService.apply('claimEditedField.additionalClaimInformation', true);

      handlers.change({
        name: 'additionalClaimInformation',
        value: additionalClaim,
      });

      Object.entries(result).forEach((rowItem, idx) => {
        if (!state.claimAdditionalInfos[idx]) {
          formService.addItem('claimAdditionalInfos');
        }
        formService.apply(
          `claimAdditionalInfos.${idx}.sortOrder`,
          rowItem[1].sortOrder,
        );

        formService.apply(
          `claimAdditionalInfos.${idx}.readOnly`,
          rowItem[1].readOnly,
        );

        formService.apply(
          `claimAdditionalInfos.${idx}.enabled`,
          rowItem[1].value.enabled,
        );

        if (!rowItem[1].readOnly) {
          formService.apply(
            `claimAdditionalInfos.${idx}.note`,
            rowItem[1].value.enabled ? rowItem[1].value.displayValue : '',
          );
        }
      });
    }
  }
};
