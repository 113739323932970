import { openPopup } from '@neb/popup';

import * as client from '../../../../packages/neb-api-client/src/document-api-client';
import {
  associateDocumentsToEncounter,
  removeDocumentsFromEncounter,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import * as documentOptions from '../../../../packages/neb-document/src/components/neb-document-options';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import * as nebTabsDocuments from '../../../components/tabs/neb-tabs-documents';
import { openSuccess, openError } from '../../../store';
import {
  mapToDocumentModel,
  getDefaultDocumentModel,
} from '../../../utils/document-mapper';
import {
  BULK_SELECT_ONE_POPUP_MESSAGE,
  ASSOCIATE_DOCUMENT_TO_ENCOUNTER_SUCCESSFULL,
  ASSOCIATE_DOCUMENT_TO_ENCOUNTER_ERROR,
  REMOVE_DOCUMENT_FROM_ENCOUNTER_SUCCESSFULL,
  REMOVE_DOCUMENT_FROM_ENCOUNTER_ERROR,
  CONFIRM_REMOVE_DOCUMENTS_MESSAGE,
} from '../../../utils/user-message';

const ENCOUNTER_DOCUMENTS_TAB =
  nebTabsDocuments.ELEMENTS.encounterDocumentsTab.id;

const { ActionResult } = documentOptions;

const TEXT_DELETE_DOCUMENT_SUCCESS = 'Document Successfully Deleted';
const TEXT_DELETE_DOCUMENT_ERROR = 'Unable to Delete Document';
const DOCUMENTS_BULK_SELECT_ONE_POPUP_MESSAGE =
  BULK_SELECT_ONE_POPUP_MESSAGE('documents');
export class UnsignedEncounterDocumentsReactiveController {
  constructor(host) {
    host.addController(this);
    this.host = host;

    this.__initState();
  }

  __initState() {
    this.__isLoadingDocuments = false;
    this.__loaded = false;
    this.searchTerms = '';
    this.__checkedDocuments = {};
  }

  get state() {
    return {
      documents: this.isEncounterDocuments()
        ? this.host.encounterDocuments.data
        : this.host.model.data,
      totalDocuments: this.isEncounterDocuments()
        ? this.host.encounterDocuments.count
        : this.host.model.count,
      noDocuments: this.isEncounterDocuments()
        ? this.host.encounterDocuments.count === 0
        : this.host.model.count === 0,
      checkedDocuments: this.__checkedDocuments,
    };
  }

  get __encounterId() {
    return this.host.encounter.id;
  }

  get __patientId() {
    return this.host.encounter.patientId;
  }

  isEncounterDocuments() {
    return this.host.selectedTab === ENCOUNTER_DOCUMENTS_TAB;
  }

  checkDocument(documentId, checked) {
    this.__checkedDocuments[documentId] = checked;
  }

  resetCheckedDocuments() {
    this.__checkedDocuments = {};
  }

  search(text) {
    this.searchTerms = text;
    return this.getMoreDocuments(true, null, true);
  }

  async openPopupUpload() {
    let model = getDefaultDocumentModel();

    if (this.isEncounterDocuments()) {
      model = { ...model, encounterId: this.__encounterId };
    }
    const popupResult = await this.__openPopup('Upload Document', model);

    if (popupResult === ActionResult.SAVED) {
      await this.getMoreDocuments(true, null, true);
      await this.updateInactiveTabCount();
    }
  }

  async openPopupScan() {
    const popupResult = await openPopup(POPUP_RENDER_KEYS.SCAN, {
      patientId: this.__patientId,
      encounterId: this.isEncounterDocuments() ? this.__encounterId : null,
    });

    if (popupResult.result === ActionResult.SAVED) {
      await this.getMoreDocuments(true, null, true);
      await this.updateInactiveTabCount();
    }
  }

  async openPopupEdit() {
    const document = this.__getSelectedItem();

    const model = mapToDocumentModel(this.__getSelectedItem());
    const result = await this.__openPopup('Edit Document', model);

    const documentId = model.id;

    if (result === ActionResult.SAVED) {
      await this.getMoreDocuments(true, documentId, false);
      await this.setDocument(this.host.selectedDocumentIndex);
    }

    if (result === ActionResult.DELETED) {
      try {
        await client.deleteDocument(documentId);
        store.dispatch(openSuccess(TEXT_DELETE_DOCUMENT_SUCCESS));
      } catch (err) {
        console.error(err);
        store.dispatch(openError(TEXT_DELETE_DOCUMENT_ERROR));
      }

      if (document) {
        this.checkDocument(document.id, false);
      }
      await this.updateInactiveTabCount();
      await this.getMoreDocuments(true, null, true);
    }

    return result;
  }

  async setDocument(index) {
    this.host.imageSrcSpec = null;

    this.host.selectedDocument =
      index >= 0 ? { ...this.state.documents[index] } : null;

    this.host.imageSrcSpec = await this.getImageSrc(
      this.host.selectedDocument,
      this.host.imageSrcSpec,
    );
  }

  async associateToEncounter() {
    const checkedDocuments = this.__getCheckedDocuments();

    if (!checkedDocuments || checkedDocuments.length <= 0) {
      openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Bulk Actions',
        message: DOCUMENTS_BULK_SELECT_ONE_POPUP_MESSAGE,
      });

      return;
    }

    try {
      await associateDocumentsToEncounter(this.__encounterId, checkedDocuments);

      this.setAllCheckboxesSelection(false);
      this.host.encounterDocuments.count =
        await this.__getTotalEncounterDocuments(this.__encounterId);

      await this.getMoreDocuments(true, null, true);

      store.dispatch(openSuccess(ASSOCIATE_DOCUMENT_TO_ENCOUNTER_SUCCESSFULL));
    } catch (error) {
      console.log(error);
      store.dispatch(openError(ASSOCIATE_DOCUMENT_TO_ENCOUNTER_ERROR));
    }
  }

  async removeFromEncounter() {
    const checkedDocuments = this.__getCheckedDocuments();

    if (!checkedDocuments || checkedDocuments.length <= 0) {
      openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Bulk Actions',
        message: DOCUMENTS_BULK_SELECT_ONE_POPUP_MESSAGE,
      });

      return;
    }

    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Remove From Encounter',
      message: CONFIRM_REMOVE_DOCUMENTS_MESSAGE,
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (!result) return;

    try {
      await removeDocumentsFromEncounter(this.__encounterId, checkedDocuments);

      this.setAllCheckboxesSelection(false);
      await this.getMoreDocuments(true, null, true);

      store.dispatch(openSuccess(REMOVE_DOCUMENT_FROM_ENCOUNTER_SUCCESSFULL));
    } catch (error) {
      store.dispatch(openError(REMOVE_DOCUMENT_FROM_ENCOUNTER_ERROR));
    }
  }

  async updateInactiveTabCount() {
    if (this.isEncounterDocuments()) {
      this.host.model.count = await this.__getTotalEncounterDocuments();
    } else {
      this.host.encounterDocuments.count =
        await this.__getTotalEncounterDocuments(this.__encounterId);
    }
  }

  __getCheckedDocuments() {
    return Object.keys(this.__checkedDocuments).reduce((memo, documentId) => {
      if (this.__checkedDocuments[documentId]) memo.push(documentId);
      return memo;
    }, []);
  }

  async __loadAllDocumentsForSelectAll() {
    this.__isLoadingDocuments = true;

    const result = await client.getDocumentsWithThumbnails({
      patientId: this.__patientId,
      limit: this.state.totalDocuments,
      offset: 0,
      searchTerms: this.searchTerms,
      documentId: null,
      encounterId: this.isEncounterDocuments() ? this.__encounterId : null,
    });

    if (result) {
      this.__updateDocumentsModel(result.data, result.count);
    }
    this.__isLoadingDocuments = false;
  }

  async setAllCheckboxesSelection(checked) {
    if (this.state.documents.length < this.state.totalDocuments && checked) {
      await this.__loadAllDocumentsForSelectAll();
    }
    this.__checkedDocuments = this.state.documents.reduce(
      (memo, document) => ({
        ...memo,
        [document.id]: checked,
      }),
      {},
    );

    this.host.requestUpdate();
  }

  __updateDocumentsModel(data, count) {
    if (this.isEncounterDocuments()) {
      this.host.encounterDocuments = { data, count };
      return;
    }
    this.host.model = { data, count };
  }

  async getMoreDocuments(
    resetBeforeFetch,
    documentId = null,
    resetIndex = false,
  ) {
    let documents = [];

    if (!resetBeforeFetch) documents = this.state.documents;
    this.__isLoadingDocuments = true;

    const result = await client.getDocumentsWithThumbnails({
      patientId: this.__patientId,
      limit: 10,
      offset: documents.length,
      searchTerms: this.searchTerms,
      documentId,
      encounterId: this.isEncounterDocuments() ? this.__encounterId : null,
    });

    const { data, count } = result;

    this.__updateDocumentsModel([...documents, ...data], count);

    if (resetIndex) {
      const newIndex = count > 0 ? 0 : -1;

      if (this.host.selectedDocumentIndex === newIndex) {
        this.setDocument(newIndex);
      } else {
        this.host.handlers.selectDocumentIndex(newIndex);
      }

      this.__scrollToDocument(newIndex);
    }

    this.__isLoadingDocuments = false;

    return result;
  }

  __getSelectedItem() {
    return this.state.documents.length > 0 &&
      this.host.selectedDocumentIndex !== -1
      ? this.state.documents[this.host.selectedDocumentIndex]
      : null;
  }

  __scrollToDocument(index) {
    if (!this.host.elements || index < 0) {
      return;
    }
    this.host.elements.detailView.scrollToDocument(index);
  }

  __getDocumentIndex(documentId) {
    if (documentId) {
      return this.state.documents.findIndex(
        document => document.id === documentId,
      );
    }

    return -1;
  }

  async __openPopup(title, model) {
    const { result } = await openPopup(POPUP_RENDER_KEYS.DOCUMENT, {
      title,
      patientId: this.__patientId,
      touchDevice: this.host.touchDevice,
      data: model,
    });
    return result;
  }

  async __getTotalEncounterDocuments(encounterId = null) {
    const LIMIT_TO_GET_DOCUMENT_COUNT_ONLY = 1;

    const encounterDocuments = await client.getDocuments(
      this.__patientId,
      LIMIT_TO_GET_DOCUMENT_COUNT_ONLY,
      0,
      null,
      null,
      encounterId,
      null,
      true,
    );

    return encounterDocuments.count;
  }

  async getImageSrc(selectedDocument, currentImageSrc) {
    if (!selectedDocument) {
      return null;
    }
    let imageSrc = currentImageSrc;

    const newImageSrc = {
      id: selectedDocument.id,
      mimeType: selectedDocument.mimeType,
    };

    if (
      this.host.showLocalLoading ||
      !currentImageSrc ||
      currentImageSrc.id !== newImageSrc.id
    ) {
      this.host.showLocalLoading = true;
      const src = await client.getDocSrc(selectedDocument);
      imageSrc = {
        src,
        ...newImageSrc,
      };
    }
    this.host.showLocalLoading = false;

    return imageSrc;
  }

  hostUpdate() {
    if (!this.__loaded && this.__encounterId) {
      this.__loaded = true;

      this.__getTotalEncounterDocuments(this.__encounterId).then(count => {
        this.host.encounterDocuments.count = count;

        if (this.host.selectedDocumentIndex >= 0) {
          this.__scrollToDocument(this.host.selectedDocumentIndex);
        }
        this.host.requestUpdate();
      });
    }
  }
}
