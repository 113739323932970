import { getMacroSetFolderById } from '../../../neb-api-client/src/macro-set-api-client';
import {
  BUTTON_TYPE_TO_COLOR,
  MACRO_BUTTON_TYPE,
} from '../../../neb-utils/macro-sets';

export class MacroSetService {
  constructor(callback) {
    this.onChange = callback;

    this.__answerSetCache = {};
    this.__folderCache = {};
    this.__folderStack = [];
    this.__macroSet = null;
  }

  get macroSet() {
    return this.__macroSet;
  }

  __formatFolderItems({ items }) {
    return items.map(item => {
      if (!item) return null;

      const { color, icon } = BUTTON_TYPE_TO_COLOR[item.type];

      if (item.type === MACRO_BUTTON_TYPE.MACRO) {
        const questions = item.questions.map(q => {
          let answers = [];

          if (q.answerSetId) {
            ({ answers } = this.__answerSetCache[q.answerSetId]);
          }

          return { ...q, answers: answers.map(a => a.value) };
        });

        return { ...item, color, questions };
      }

      const { name } = this.__folderCache[item.id];

      return { ...item, color, icon, name };
    });
  }

  __mergeCache(dictionary) {
    this.__folderCache = { ...this.__folderCache, ...dictionary.folders };
    this.__answerSetCache = {
      ...this.__answerSetCache,
      ...dictionary.answerSets,
    };
  }

  __shiftFolderStack(folderId, folderName) {
    let slideDirection = '';

    const index = this.__folderStack.findIndex(f => f.id === folderId);

    if (index >= 0) {
      this.__folderStack.splice(index + 1);

      slideDirection = 'left';
    } else {
      this.__folderStack.push({
        id: folderId,
        name: folderName,
      });

      slideDirection = 'right';
    }

    return slideDirection;
  }

  async selectFolder({
    folderId,
    resetFolderStack = false,
    shouldSlide = true,
  }) {
    if (!this.__folderCache[folderId] || !this.__folderCache[folderId].items) {
      const result = await getMacroSetFolderById(folderId, this.__macroSet.id);

      this.__mergeCache(result);
    }

    const folder = this.__folderCache[folderId];
    folder.items = this.__formatFolderItems(folder);

    if (resetFolderStack) {
      this.__folderStack = [];
    }

    const slideDirection = this.__shiftFolderStack(
      folderId,
      folder.name,
      shouldSlide,
    );

    this.onChange({
      folder,
      stack: this.__folderStack,
      slideDirection: !resetFolderStack && shouldSlide ? slideDirection : '',
      macroSet: this.__macroSet,
    });
  }

  setMacroSet({ macroSet, folderId, folderStack = null }) {
    this.__macroSet = macroSet;

    if (folderStack) {
      this.__folderStack = folderStack;

      return this.selectFolder({
        folderId: folderStack[folderStack.length - 1].id,
        shouldSlide: false,
      });
    }

    return this.selectFolder({
      folderId,
      resetFolderStack: true,
      shouldSlide: false,
    });
  }
}
