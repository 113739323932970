import { css } from 'lit';

import { parseDate } from '../../../../neb-utils/date-util';

import Table, { ELEMENTS as BASE_ELEMENTS } from './neb-table';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  historyLinks: {
    selector: '[id^=history-link-]',
  },
};

const CONFIG = [
  {
    truncate: true,
    key: 'patientName',
    label: 'Patient',
    flex: css`1 0 0`,
  },
  {
    key: 'start',
    label: 'Start Date',
    flex: css`0 0 200px`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'end',
    label: 'End Date',
    flex: css`0 0 200px`,
    formatter: v => (v ? parseDate(v).format('MM/DD/YYYY') : ''),
  },
];

class NebTablePackagesSubscriptionsHistory extends Table {
  static get properties() {
    return {
      patientId: String,
    };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.emptyMessage = 'No history to display for this package';
    this.config = CONFIG;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
    };
  }
}

customElements.define(
  'neb-table-packages-subscriptions-history',
  NebTablePackagesSubscriptionsHistory,
);
