import '../../../src/components/popups/neb-popup-text-field';
import './neb-popup-add-to-invoice';
import './neb-popup-appointment-cancel';
import './neb-popup-appointment-change-room';
import './neb-popup-appointment-drag-drop-confirm';
import '../../../src/components/popups/ledger/neb-popup-billing-header';
import './neb-popup-card-on-file';
import './neb-popup-change-password';
import './neb-popup-change-practice';
import './neb-popup-change-paper-claim-status';
import './neb-popup-charge-group';
import './neb-popup-charting-note-copy';
import './neb-popup-claim-billing-provider';
import './neb-popup-claim-date-range';
import './neb-popup-claim-dropdown';
import './neb-popup-claim-dropdown-text-input';
import './neb-popup-claim-federal-tax-id';
import './neb-popup-claim-line-items';
import './neb-popup-claim-outside-labs';
import './neb-popup-claim-print-settings';
import './neb-popup-claim-radio-date-picker';
import './neb-popup-claim-radio-name-fields';
import './neb-popup-claim-service-facility';
import './neb-popup-claim-billing-provider-information';
import './neb-popup-claim-single-select';
import './neb-popup-claim-spinal-manipulation';
import './neb-popup-claim-text-input';
import './neb-popup-claim-yes-no';
import './neb-popup-client-id';
import './neb-popup-confirm';
import './neb-popup-copy';
import './neb-popup-copy-autosalt';
import './neb-popup-copy-existing-payer';
import './neb-popup-copy-prior-encounter';
import '../../../src/components/popups/neb-popup-copy-encounter';
import './neb-popup-delete-blocked-off-time';
import './neb-popup-delete-encounter';
import './neb-popup-document';
import './neb-popup-email-receipt';
import '../../../src/components/popups/neb-popup-transfer-balance';
import './neb-popup-encounter-provider-selection';
import './neb-popup-encounter-sign';
import './neb-popup-encounter-reopen';
import './neb-popup-file-upload';
import './neb-popup-fullscreen-content';
import './neb-popup-invite-patient';
import './neb-popup-quick-check-in';
import './neb-popup-ledger-insurance-out-of-coverage';
import '../../../src/components/popups/ledger/neb-popup-individual-code-and-amount';
import '../../../src/components/popups/macros/neb-popup-import-macro-set';
import '../../../src/components/popups/macros/neb-popup-macro-edit-choice-template';
import '../../../src/components/popups/macros/neb-popup-macro-link-choice-template';
import '../../../src/components/popups/macros/neb-popup-macro-region-choice-setup';
import './neb-popup-macros-basic-fields';
import './neb-popup-macros-body-diagram';
import './neb-popup-macros-date-picker';
import './neb-popup-macros-free-text-entry';
import './neb-popup-macros-multi-select';
import './neb-popup-macros-numeric-entry';
import './neb-popup-macros-single-select';
import './neb-popup-macros-spine';
import './neb-popup-match-patient-account';
import './neb-popup-matching-online-account';
import './neb-popup-matching-review-selection';
import './neb-popup-merchant-response';
import './neb-popup-message';
import './neb-popup-national-drug-code';
import './neb-popup-new-statement';
import './neb-popup-new-superbill';
import '../../../src/components/popups/neb-popup-generate-case-statement';
import '../../../src/components/popups/neb-popup-select-era-eob';
import '../../../src/components/popups/neb-popup-patient-demographic-report-params';
import './neb-popup-patient-online-payment';
import './neb-popup-patient-online-payment-status';
import './neb-popup-payment-action';
import './neb-popup-problems';
import './neb-popup-profile-preview';
import './neb-popup-signature-create';
import './neb-popup-soap-note-append-replace';
import './neb-popup-soap-note-copy';
import './neb-popup-timeout';
import './neb-popup-timeout-warning';
import './neb-popup-locations-warning';
import './neb-popup-transaction-processing';
import './neb-popup-upload-processing';
import './neb-popup-claim-multi-select-input';
import './neb-popup-select-location';
import './neb-popup-subjective-questionnaire';
import './neb-popup-subjective-questionnaire-preview';
import './neb-popup-subjective-questionnaire-copy';
import './neb-popup-subjective-questionnaire-provider-copy';
import './neb-popup-subjective-questionnaire-append-replace';
import './neb-popup-swap-clearinghouse';
import './neb-popup-modifiers-edit';
import './neb-popup-match-claim';
import './neb-popup-claim-action';
import '../../../src/components/popups/neb-popup-update-associated-case';
import '../../../src/components/popups/neb-popup-update-appointment-type';
import '../../../src/components/popups/document-tags/neb-popup-replace-document-tag';
import '../../../src/components/popups/ledger/neb-popup-update-payment';
import '../../../src/components/popups/ledger/neb-popup-supplemental-information';
import '../../../src/components/popups/ledger/neb-popup-ordering-provider';
import '../../../src/components/popups/ledger/neb-popup-claim-supplemental-information';
import '../../../src/components/popups/neb-popup-dialog';
import '../../../src/components/popups/neb-popup-bill-type-set-default';
import '../../../src/components/popups/password/neb-popup-password';
import '../../../src/components/overlays/encounters/neb-overlay-visit-summary-patient-detail';
import '../../../src/features/scan/neb-popup-scan';
import '../../../src/components/popups/ledger/neb-popup-update-payment-provider-location';
import '../../../src/components/popups/ledger/neb-popup-original-reference-number';
import '../../../src/features/payfac/neb-popup-payfac-iframe';
import '../../../src/features/payfac/neb-popup-payfac-reader';
import '../../../src/components/popups/ledger/neb-popup-allocated-payments';

import { html } from 'lit';

import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  popupAddToInvoice: {
    id: 'popup-add-to-invoice',
  },
  popupAppointmentCancel: {
    id: 'popup-appointment-cancel',
  },
  popupBillingHeader: {
    id: 'popup-billing-header',
  },
  popupCardOnFile: {
    id: 'popup-card-on-file',
  },
  popupCardReader: {
    id: 'popup-card-reader',
  },
  popupChangePractice: {
    id: 'popup-change-practice',
  },
  popupChargeGroup: {
    id: 'popup-charge-group',
  },
  popupChartingNoteCopy: {
    id: 'popup-charting-note-copy',
  },
  popupClaimBillingProvider: {
    id: 'popup-claim-billing-provider',
  },
  popupClaimDateRange: {
    id: 'popup-claim-date-range',
  },
  popupClaimDropdownTextInput: {
    id: 'popup-claim-dropdown-text-input',
  },
  popupClaimFederalTaxId: {
    id: 'popup-claim-federal-tax-id',
  },
  popupClaimOutsideLabs: {
    id: 'popup-claim-outside-labs',
  },
  popupClaimPrintSettings: {
    id: 'popup-claim-print-settings',
  },
  popupClaimRadioDatePicker: {
    id: 'popup-claim-radio-date-picker',
  },
  popupClaimServiceFacility: {
    id: 'popup-claim-service-facility',
  },
  popupClaimRadioNameFields: {
    id: 'popup-claim-radio-name-fields',
  },
  popupClaimSupplementalFields: {
    id: 'popup-claim-supplemental-fields',
  },
  popupClaimSingleSelect: {
    id: 'popup-claim-single-select',
  },
  popupClaimSpinalManipulation: {
    id: 'popup-claim-spinal-manipulation',
  },
  popupClaimTextInput: {
    id: 'popup-claim-text-input',
  },
  popupClaimYesNo: {
    id: 'popup-claim-yes-no',
  },
  popupConfirm: {
    id: 'popup-confirm',
  },
  popupCopyAutoSalt: {
    id: 'popup-copy-auto-salt',
  },
  popupCopyExistingPayer: {
    id: 'popup-copy-existing-payer',
  },
  popupCopyExistingProvider: {
    id: 'popup-copy-provider',
  },
  popupCopyPriorEncounter: {
    id: 'popup-copy-prior-encounter',
  },
  popupCopyEncounter: {
    id: 'popup-copy-encounter',
  },
  popupDeleteBlockedOffTime: {
    id: 'popup-delete-blocked-off-time',
  },
  popupDeleteEncounter: {
    id: 'popup-delete-encounter',
  },
  popupDocument: {
    id: 'popup-document',
  },
  popupMerchantResponse: {
    id: 'popup-merchant-response',
  },
  popupClaimMultiSelectInput: {
    id: 'popup-claim-multi-select-input',
  },
  popupMessage: {
    id: 'popup-message',
  },
  popupNewSuperbill: {
    id: 'popup-new-superbill',
  },
  popupNationalDrugCode: {
    id: 'popup-national-drug-code',
  },
  popupGenerateCaseStatement: {
    id: 'popup-generate-case-statement',
  },
  popupNewStatement: {
    id: 'popup-new-statement',
  },
  popupPatientDemographicReportParams: {
    id: 'popup-patient-demographic-report-params',
  },
  popupPatientOnlinePayment: {
    id: 'popup-patient-online-payment',
  },
  popupPatientOnlinePaymentStatus: {
    id: 'popup-patient-online-payment-status',
  },
  popupPayfields: {
    id: 'popup-payfields',
  },
  popupPayfac: {
    id: 'popup-payfac',
  },
  popupPayfacReader: {
    id: 'popup-payfac-reader',
  },
  popupPaymentAction: {
    id: 'popup-payment-action',
  },
  popupProblems: {
    id: 'popup-problems',
  },
  popupSelectLocation: {
    id: 'popup-select-location',
  },
  popupSubjectiveQuestionnaire: {
    id: 'popup-subjective-questionnaire',
  },
  popupSubjectiveQuestionnairePreview: {
    id: 'popup-subjective-questionnaire-preview',
  },
  popupSubjectiveQuestionnaireCopy: {
    id: 'popup-subjective-questionnaire-copy',
  },
  popupSubjectiveQuestionnaireProviderCopy: {
    id: 'popup-subjective-questionnaire-provider-copy',
  },
  popupSubjectiveQuestionnaireAppendReplace: {
    id: 'popup-subjective-questionnaire-append-replace',
  },
  popupTextField: {
    id: 'popup-text-field',
  },
  popupTransferBalance: {
    id: 'popup-transfer-balance',
  },
  popupUpdatePayment: {
    id: 'popup-update-payment',
  },
  popupBillingProviderInformation: {
    id: 'popup-billing-provider-information',
  },
  popupPassword: {
    id: 'popup-password',
  },
  popupAppointmentChangeRoom: {
    id: 'popup-appointment-change-room',
  },
  popupAppointmentDragDropConfirm: {
    id: 'popup-appointment-drag-drop-confirm',
  },
  popupEmailReceipt: {
    id: 'neb-popup-email-receipt',
  },
  popupEncounterProviderSelection: {
    id: 'popup-encounter-provider-selection',
  },
  popupSupplementalFields: {
    id: 'popup-supplemental-fields',
  },
  popupEditOrderingProvider: {
    id: 'popup-edit-ordering-provider',
  },
  popupEditAppointmentType: {
    id: 'popup-edit-appointment-type',
  },
  popupBillTypeSetDefault: {
    id: 'popup-bill-type-set-default',
  },
  popupAddEditCase: {
    id: 'popup-add-edit-case',
  },
  popupModifiersEdit: {
    id: 'popup-modifiers-edit',
  },
  popupScan: {
    id: 'popup-scan',
  },
  popupSelectERAEOB: {
    id: 'popup-select-era-eob',
  },
  popupUpdatePaymentProviderLocation: {
    id: 'popup-update-payment-provider-location',
  },
  popupOriginalReferenceNumber: {
    id: 'popup-original-reference-number',
  },
  popupMatchClaim: {
    id: 'popup-match-claim',
  },
  popupAllocatedPayments: {
    id: 'popup-allocated-payments',
  },
};

const RENDERER_POPUPS = {
  [POPUP_RENDER_KEYS.ADD_TO_INVOICE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-add-to-invoice
        id="${ELEMENTS.popupAddToInvoice.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-add-to-invoice>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.APPOINTMENT_CANCEL]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-appointment-cancel
        id="${ELEMENTS.popupAppointmentCancel.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-appointment-cancel>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.APPOINTMENT_CHANGE_ROOM]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-appointment-change-room
        id="${ELEMENTS.popupAppointmentChangeRoom.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-appointment-change-room>
    `,
  },
  [POPUP_RENDER_KEYS.APPOINTMENT_DRAG_DROP_CONFIRM]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-appointment-drag-drop-confirm
        id="${ELEMENTS.popupAppointmentDragDropConfirm.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-appointment-drag-drop-confirm>
    `,
  },
  [POPUP_RENDER_KEYS.ARRIVED]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-confirm
        id="${ELEMENTS.popupConfirm.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-confirm>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.ALLOCATED_PAYMENTS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-allocated-payments
        id="${ELEMENTS.popupAllocatedPayments.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-allocated-payments>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.BILLING_HEADER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-billing-header
        id="${ELEMENTS.popupBillingHeader.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-billing-header>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.CARD_ON_FILE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-card-on-file
        id="${ELEMENTS.popupCardOnFile.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-card-on-file>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CHANGE_PRACTICE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-change-practice
        id="${ELEMENTS.popupChangePractice.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-change-practice>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CHANGE_PAPER_CLAIM_STATUS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-change-paper-claim-status
        id="${ELEMENTS.popupChangePractice.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-change-paper-claim-status>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CHARGE_GROUP]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-charge-group
        id="${ELEMENTS.popupChargeGroup.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-charge-group>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CHARTING_NOTE_COPY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-charting-note-copy
        id="${ELEMENTS.popupChartingNoteCopy.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-charting-note-copy>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_BILLING_PROVIDER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-billing-provider
        id="${ELEMENTS.popupClaimBillingProvider.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-billing-provider>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_DATE_RANGE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-date-range
        id="${ELEMENTS.popupClaimDateRange.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-date-range>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_DROPDOWN]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-dropdown
        id="${ELEMENTS.popupClaimDropdownTextInput.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-dropdown>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_DROPDOWN_TEXT_INPUT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-dropdown-text-input
        id="${ELEMENTS.popupClaimDropdownTextInput.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-dropdown-text-input>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_FEDERAL_TAX_ID]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-federal-tax-id
        id="${ELEMENTS.popupClaimFederalTaxId.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-federal-tax-id>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_LINE_ITEMS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-line-items
        id="${ELEMENTS.popupClaimDateRange.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-line-items>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_MULTI_SELECT_INPUT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-multi-select-input
        id="${ELEMENTS.popupClaimMultiSelectInput.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-multi-select-input>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_PRINT_SETTINGS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-print-settings
        id="${ELEMENTS.popupClaimPrintSettings.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-print-settings>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_RADIO_NAME_FIELDS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-radio-name-fields
        id="${ELEMENTS.popupClaimRadioNameFields.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-radio-name-fields>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_SUPPLEMENTAL_INFORMATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-supplemental-information
        id="${ELEMENTS.popupClaimSupplementalFields.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-supplemental-information>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUPPLEMENTAL_INFORMATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-supplemental-information
        id="${ELEMENTS.popupSupplementalFields.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-supplemental-information>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.ORDERING_PROVIDER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-ordering-provider
        id="${ELEMENTS.popupEditOrderingProvider.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-ordering-provider>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_RADIO_DATE_PICKER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-radio-date-picker
        id="${ELEMENTS.popupClaimRadioDatePicker.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-radio-date-picker>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_SERVICE_FACILITY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-service-facility
        id="${ELEMENTS.popupClaimSingleSelect.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-service-facility>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_BILLING_PROVIDER_INFORMATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-billing-provider-information
        id="${ELEMENTS.popupBillingProviderInformation.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-billing-provider-information>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_SINGLE_SELECT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-single-select
        id="${ELEMENTS.popupClaimSingleSelect.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-single-select>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_TEXT_INPUT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-text-input
        id="${ELEMENTS.popupClaimTextInput.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-text-input>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_YES_NO]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-yes-no
        id="${ELEMENTS.popupClaimYesNo.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-yes-no>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_OUTSIDE_LABS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-outside-labs
        id="${ELEMENTS.popupClaimOutsideLabs.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-outside-labs>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_SPINAL_MANIPULATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-spinal-manipulation
        id="${ELEMENTS.popupClaimSpinalManipulation.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-spinal-manipulation>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CONFIRM]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-confirm
        id="${ELEMENTS.popupConfirm.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-confirm>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.DIALOG]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-dialog
        id="${ELEMENTS.popupConfirm.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-dialog>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.COPY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-copy
        id="${ELEMENTS.popupCopyExistingProvider.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-copy>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.COPY_AUTOSALT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-copy-autosalt
        id="${ELEMENTS.popupCopyAutoSalt.id}"
        .index="${index}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-copy-autosalt>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.COPY_EXISTING_PAYER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-copy-existing-payer
        id="${ELEMENTS.popupCopyExistingPayer.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-copy-existing-payer>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.COPY_PRIOR_ENCOUNTER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-copy-prior-encounter
        id="${ELEMENTS.popupCopyPriorEncounter.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-copy-prior-encounter>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.COPY_ENCOUNTER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-copy-encounter
        id="${ELEMENTS.popupCopyEncounter.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-copy-encounter>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.DELETE_BLOCKED_OFF_TIME]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-delete-blocked-off-time
        id="${ELEMENTS.popupDeleteBlockedOffTime.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-delete-blocked-off-time>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.DOCUMENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-document
        id="${ELEMENTS.popupDocument.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-document>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.DELETE_ENCOUNTER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-delete-encounter
        id="${ELEMENTS.popupDeleteEncounter.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-delete-encounter>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.EMAIL_RECEIPT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-email-receipt
        id="${ELEMENTS.popupEmailReceipt.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-email-receipt>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.ENCOUNTER_PROVIDER_SELECTION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-encounter-provider-selection
        id="${ELEMENTS.popupEncounterProviderSelection.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-encounter-provider-selection>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.ENCOUNTER_SIGN]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-encounter-sign
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-encounter-sign>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.ENCOUNTER_REOPEN]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-encounter-reopen
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-encounter-reopen>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.FILE_UPLOAD]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-file-upload
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-file-upload>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.FULLSCREEN_CONTENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-fullscreen-content
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-fullscreen-content>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.IMPORT_MACRO_SET]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-import-macro-set
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-import-macro-set>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.INVITE_PATIENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-invite-patient
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-invite-patient>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-individual-code-and-amount
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        .allowNegative="${true}"
        ?hidden="${hide}"
      ></neb-popup-individual-code-and-amount>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.MACRO_EDIT_CHOICE_TEMPLATE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macro-edit-choice-template
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macro-edit-choice-template>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACRO_LINK_CHOICE_TEMPLATE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macro-link-choice-template
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macro-link-choice-template>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACRO_REGION_CHOICE_SETUP]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macro-region-choice-setup
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macro-region-choice-setup>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_BASIC_FIELDS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-basic-fields
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-basic-fields>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_BODY_DIAGRAM]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-body-diagram
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-body-diagram>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_DATE_PICKER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-date-picker
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
      ></neb-popup-macros-date-picker>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_FREE_TEXT_ENTRY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-free-text-entry
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-free-text-entry>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_MULTI_SELECT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-multi-select
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-multi-select>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_NUMERIC_ENTRY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-numeric-entry
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-numeric-entry>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_SINGLE_SELECT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-single-select
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-single-select>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MACROS_SPINE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-macros-spine
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-macros-spine>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MATCH_PATIENT_ACCOUNT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-match-patient-account
        .index="${index}"
        collapsing
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-match-patient-account>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.MERCHANT_RESPONSE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-merchant-response
        id="${ELEMENTS.popupPayfields.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-merchant-response>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PATIENT_DEMOGRAPHIC_REPORT_PARAMS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-patient-demographic-report-params
        id="${ELEMENTS.popupPatientDemographicReportParams.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-patient-demographic-report-params>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-patient-online-payment
        id="${ELEMENTS.popupPatientOnlinePayment.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-patient-online-payment>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PATIENT_ONLINE_PAYMENT_STATUS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-patient-online-payment-status
        id="${ELEMENTS.popupPatientOnlinePaymentStatus.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-patient-online-payment-status>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MESSAGE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-message
        id="${ELEMENTS.popupMessage.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-message>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.NATIONAL_DRUG_CODE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-national-drug-code
        id="${ELEMENTS.popupNationalDrugCode.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-national-drug-code>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.NEW_STATEMENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-new-statement
        id="${ELEMENTS.popupNewStatement.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-new-statement>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.NEW_SUPERBILL]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-new-superbill
        id="${ELEMENTS.popupNewSuperbill.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-new-superbill>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.NEW_CASE_STATEMENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-generate-case-statement
        id="${ELEMENTS.popupGenerateCaseStatement.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-generate-case-statement>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PAYFAC_IFRAME]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-payfac-iframe
        id="${ELEMENTS.popupPayfac.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-payfac-iframe>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PAYFAC_READER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-payfac-reader
        id="${ELEMENTS.popupPayfacReader.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-payfac-reader>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PAYMENT_ACTION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-payment-action
        id="${ELEMENTS.popupPaymentAction.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-payment-action>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PROBLEMS]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-problems
        id="${ELEMENTS.popupProblems.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-problems>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PROFILE_PREVIEW]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-profile-preview
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-profile-preview>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.QUICK_CHECK_IN]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-quick-check-in
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-quick-check-in>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SELECT_LOCATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-select-location
        id="${ELEMENTS.popupSelectLocation.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-select-location>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.BILL_TYPE_SET_DEFAULT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-bill-type-set-default
        id="${ELEMENTS.popupBillTypeSetDefault.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-bill-type-set-default>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SIGNATURE_CREATE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-signature-create
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-signature-create>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SOAP_NOTE_APPEND_REPLACE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-soap-note-append-replace
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-soap-note-append-replace>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SOAP_NOTE_COPY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-soap-note-copy
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-soap-note-copy>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-subjective-questionnaire
        id="${ELEMENTS.popupSubjectiveQuestionnaire.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-subjective-questionnaire>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_PREVIEW]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-subjective-questionnaire-preview
        id="${ELEMENTS.popupSubjectiveQuestionnairePreview.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-subjective-questionnaire-preview>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_APPEND_REPLACE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-subjective-questionnaire-append-replace
        id="${ELEMENTS.popupSubjectiveQuestionnaireAppendReplace.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-subjective-questionnaire-append-replace>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_COPY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-subjective-questionnaire-copy
        id="${ELEMENTS.popupSubjectiveQuestionnaireCopy.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-subjective-questionnaire-copy>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_PROVIDER_COPY]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-subjective-questionnaire-provider-copy
        id="${ELEMENTS.popupSubjectiveQuestionnaireProviderCopy.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-subjective-questionnaire-provider-copy>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SWAP_CLEARINGHOUSE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-swap-clearinghouse
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-swap-clearinghouse>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MATCH_ONLINE_BOOKING_ACCOUNT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-matching-online-account
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-matching-online-account>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.REVIEW_MATCH_SELECTION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-matching-review-selection
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-matching-review-selection>
    `,
    collapsing: true,
  },
  [POPUP_RENDER_KEYS.TEXT_FIELD]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-text-field
        id="${ELEMENTS.popupTextField.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-text-field>
    `,
  },
  [POPUP_RENDER_KEYS.TIMEOUT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-timeout
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-timeout>
    `,
  },
  [POPUP_RENDER_KEYS.TIMEOUT_WARNING]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-timeout-warning
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-timeout-warning>
    `,
  },
  [POPUP_RENDER_KEYS.TRANSFER_BALANCE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-transfer-balance
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-transfer-balance>
    `,
  },
  [POPUP_RENDER_KEYS.LOCATIONS_WARNING]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-locations-warning
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-locations-warning>
    `,
  },
  [POPUP_RENDER_KEYS.TRANSACTION_PROCESSING]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-transaction-processing
        id="${ELEMENTS.popupPayfields.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-transaction-processing>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CHANGE_PASSWORD]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-change-password
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-change-password>
    `,
  },
  [POPUP_RENDER_KEYS.CLIENT_ID]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-client-id
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-client-id>
    `,
  },
  [POPUP_RENDER_KEYS.REPLACE_DOCUMENT_TAG]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-replace-document-tag
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-replace-document-tag>
    `,
  },
  [POPUP_RENDER_KEYS.UPDATE_ERA_PAYMENT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-update-payment
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-update-payment>
    `,
  },

  [POPUP_RENDER_KEYS.UPDATE_PAYMENT_PROVIDER_LOCATION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-update-payment-provider-location
        id="${ELEMENTS.popupUpdatePaymentProviderLocation.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-update-payment-provider-location>
    `,
  },
  [POPUP_RENDER_KEYS.UPLOAD_PROCESSING]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-upload-processing
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-upload-processing>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.PASSWORD]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-password
        id="${ELEMENTS.popupPassword.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-popup-password>
    `,
  },
  [POPUP_RENDER_KEYS.VISIT_SUMMARY_PATIENT_DETAIL]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-overlay-visit-summary-patient-detail
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
        collapsing
      ></neb-overlay-visit-summary-patient-detail>
    `,
  },
  [POPUP_RENDER_KEYS.LEDGER_INSURANCE_OUT_OF_COVERAGE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <ledger-insurance-out-of-coverage
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></ledger-insurance-out-of-coverage>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.UPDATE_ASSOCIATED_CASE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-update-associated-case
        id="${ELEMENTS.popupAddEditCase.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-update-associated-case>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SCAN]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-scan
        id="${ELEMENTS.popupScan.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-scan>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MODIFIERS_EDIT]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-modifiers-edit
        id="${ELEMENTS.popupModifiersEdit.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-modifiers-edit>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.SELECT_ERA_EOB]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-select-era-eob
        id="${ELEMENTS.popupSelectERAEOB.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-select-era-eob>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.ORIGINAL_REFERENCE_NUMBER]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-original-reference-number
        id="${ELEMENTS.popupOriginalReferenceNumber.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-original-reference-number>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.MATCH_CLAIM]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-match-claim
        id="${ELEMENTS.popupMatchClaim.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-match-claim>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.CLAIM_ACTION]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-claim-action
        id="${ELEMENTS.popupMessage.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-claim-action>
    `,
    collapsing: false,
  },
  [POPUP_RENDER_KEYS.UPDATE_APPOINTMENT_TYPE]: {
    renderer: (hide, index, layout, model, closeHandler) => html`
      <neb-popup-update-appointment-type
        id="${ELEMENTS.popupEditAppointmentType.id}"
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-popup-update-appointment-type>
    `,
    collapsing: false,
  },
};
export const mapPopupStackRenderers = () =>
  Object.entries(RENDERER_POPUPS).reduce(
    (acc, [popupKey, { renderer }]) => ({ ...acc, [popupKey]: renderer }),
    {},
  );
export const isPopupCollapsible = popupName =>
  !!RENDERER_POPUPS[popupName].collapsing;
