import '../../components/misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { LitElement, html, css } from 'lit';

import { store } from '../../../packages/neb-redux/neb-redux-store';
import { Dirty } from '../../../packages/neb-redux/services/dirty';
import { LocationsService } from '../../../packages/neb-redux/services/locations';
import { manuallyWarn } from '../../../packages/neb-route/neb-route-state';
import { openError, openSuccess } from '../../store';
import { toggleChartingDirty } from '../../store/reducers/charting';
import {
  baseStyles,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../styles';
import { errorOccurred, successfullyMessage } from '../../utils/user-message';

export const BASE_ELEMENTS = {
  content: { id: 'content' },
  title: { id: 'title' },
  closeIcon: { id: 'close-icon' },
  spinnerOverlay: { id: 'spinner-overlay' },
};

const DIRTY_EVENTS = ['neb-dirty-proceed', 'neb-dirty-manual-proceed'];

export const DEFAULT_SAVE_OPTS = {
  closeAfterSave: false,
  suppressSaveBanner: false,
  successAction: 'saved',
  errorAction: 'saving',
};

export class NebUnsignedEncounterDetail extends LitElement {
  static get properties() {
    return {
      __locations: { type: Array },
      __saving: { type: Boolean },

      encounter: { type: Object },
      layout: { type: String },
      model: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          height: 100%;
          width: 100%;

          background-color: ${CSS_COLOR_WHITE};
          border-radius: 4px;
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
          overflow: hidden;
        }

        .container {
          display: flex;
          flex-direction: column;

          height: 100%;
          overflow: auto;
        }

        .header {
          display: flex;
          justify-content: space-between;
          margin: 10px 10px 5px 20px;
        }

        .title {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          height: 24px;
          width: 24px;

          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
          cursor: pointer;
        }

        .content {
          height: 100%;
        }

        .spinner {
          z-index: 5;
        }
      `,
    ];
  }

  static get config() {
    return {
      itemName: '',
      refetchKey: '',
    };
  }

  static createModel() {
    return {};
  }

  constructor() {
    super();

    this.initState();
    this.initServices();
    this.initHandlers();
  }

  initState() {
    this.__config = this.constructor.config;
    this.__locations = [];
    this.__preventDirtyChecking = false;
    this.__saving = false;

    this.encounter = {
      patientId: '',
    };

    this.layout = '';
    this.model = this.constructor.createModel();

    this.onClose = () => {};

    this.onRefetch = () => {};
  }

  initServices() {
    this.__dirtyService = new Dirty(
      () => !this.__preventDirtyChecking,
      () => this.isDirty(),
    );

    this.__locationService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  initHandlers() {
    this.handlers = {
      cancel: () => {
        if (this.isDirty()) {
          return store.dispatch(manuallyWarn());
        }

        return this.close();
      },

      changeDirty: isDirty => {
        store.dispatch(toggleChartingDirty(isDirty));
      },

      proceedFromDirty: () => {
        this.__preventDirtyChecking = true;

        this.proceededFromDirty();
      },

      deleteEncounterFromOutside: event => {
        const { deletedEncounterId } = event.detail;

        if (deletedEncounterId === this.encounter.id) {
          this.__preventDirtyChecking = true;
        }
      },

      save: async (itemToSave, opts = DEFAULT_SAVE_OPTS) => {
        try {
          if (!this.__saving) {
            this.__saving = true;

            await this.save(itemToSave);

            if (!opts.suppressSaveBanner) {
              openSuccess(
                successfullyMessage(
                  this.__config.itemName,
                  opts.successAction || DEFAULT_SAVE_OPTS.successAction,
                ),
              );
            }

            this.onRefetch(this.__config.refetchKey);

            if (opts.closeAfterSave) {
              this.__preventDirtyChecking = true;

              this.close();
            }
          }
        } catch (e) {
          console.error(e);

          openError(
            errorOccurred(
              opts.errorAction || DEFAULT_SAVE_OPTS.errorAction,
              this.__config.itemName,
            ),
          );

          this.__saving = false;
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__dirtyService.connect();
    this.__locationService.connect();

    DIRTY_EVENTS.forEach(eventName =>
      window.addEventListener(eventName, this.handlers.proceedFromDirty),
    );

    window.addEventListener(
      'neb-encounter-deleted',
      this.handlers.deleteEncounterFromOutside,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__dirtyService.disconnect();
    this.__locationService.disconnect();

    DIRTY_EVENTS.forEach(eventName =>
      window.removeEventListener(eventName, this.handlers.proceedFromDirty),
    );

    window.removeEventListener(
      'neb-encounter-deleted',
      this.handlers.deleteEncounterFromOutside,
    );
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__saving = false;
    }

    super.update(changedProps);
  }

  isDirty() {}

  close() {
    return this.onClose();
  }

  save() {}

  proceededFromDirty() {
    return this.close();
  }

  getTitle() {
    return '';
  }

  renderContent() {
    return '';
  }

  renderSpinner() {
    return html`
      <neb-loading-overlay
        id="${BASE_ELEMENTS.spinnerOverlay.id}"
        class="spinner"
        .show="${!!this.__saving}"
        .showDelay="${500}"
      ></neb-loading-overlay>
    `;
  }

  renderWarnings() {
    return '';
  }

  render() {
    return html`
      <div class="container">
        <div class="header">
          <div id="${BASE_ELEMENTS.title.id}" class="title">
            ${this.getTitle()}${this.renderWarnings()}
          </div>

          <neb-icon
            id="${BASE_ELEMENTS.closeIcon.id}"
            class="icon"
            icon="neb:expand"
            @click="${this.handlers.cancel}"
          ></neb-icon>
        </div>

        <div id="${BASE_ELEMENTS.content.id}" class="content">
          ${this.renderContent()}
        </div>

        ${this.renderSpinner()}
      </div>
    `;
  }
}
