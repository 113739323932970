import '../../../packages/neb-www-practice-charting/src/components/notes/neb-charting-notes-summary';
import '../../../packages/neb-lit-components/src/components/encounter-diagnoses/neb-encounter-diagnoses-summary';
import '../../../packages/neb-lit-components/src/components/encounter-charges/neb-encounter-charges-summary';
import '../../../packages/neb-lit-components/src/components/encounter/neb-encounter-summary';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import './neb-charting-encounter-header-controller';

import { html, LitElement, css } from 'lit';

import { StoreController } from '../../store/neb-store-controller';
import {
  baseStyles,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../styles';

import { formatDiagnosesTitle } from './neb-charting-util';
import { SignedEncounterDataController } from './neb-signed-encounter-data-controller';

export const ELEMENTS = {
  chargesSummary: { id: 'charges-summary' },
  encounterSummary: { id: 'encounter-summary' },
  diagnosesSummary: { id: 'diagnoses-summary' },
  headerController: { id: 'header-controller' },

  diagnosesTitle: { id: 'diagnoses-title' },
  chargesTitle: { id: 'charges-title' },
  encounterSummaryTitle: { id: 'encounter-summary-title' },

  diagnosesLoadingSpinner: { id: 'diagnoses-loading-spinner' },
  chargesLoadingSpinner: { id: 'charges-loading-spinner' },
  summaryLoadingSpinner: { id: 'summary-loading-spinner' },
};

const formatTitle = (name, items) => {
  const count = items.length > 0 ? ` (${items.length})` : '';

  return `${name}${count}`;
};

export class NebPageSignedEncounter extends LitElement {
  static get properties() {
    return {
      __practiceInfo: { type: Object },

      model: { type: Object },
      dateOfOnsetFF: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }

        .container {
          display: flex;
          flex-direction: column;

          margin: ${CSS_SPACING};

          flex: 1 0 0;
        }

        .panel-container {
          display: flex;

          margin-top: ${CSS_SPACING};

          flex: 1 0 0;
          gap: ${CSS_SPACING};
        }

        .encounter-summary-panel {
          display: flex;
          flex-direction: column;

          background-color: ${CSS_COLOR_WHITE};
          border-radius: 4px;

          flex: 2 0 0;
        }

        .diagnoses-charges-panel {
          display: flex;
          flex-direction: column;

          gap: ${CSS_SPACING};

          border-radius: 4px;

          flex: 1 0 0;
        }

        .panel {
          display: flex;
          flex-direction: column;

          background-color: ${CSS_COLOR_WHITE};
          border-radius: 4px;

          height: calc(100% - 40px);
          overflow: hidden;

          flex: 1 0 0;
        }

        .fade-support {
          min-height: 0;
        }

        .title {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_BODY};
          margin: 10px;
        }

        .summary {
          padding: 10px;

          overflow: auto;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: null,
      hasHistory: false,
      signed: false,
      appointmentTypeId: null,
      locationId: null,
      patientId: null,
      provider: '',
      appointmentType: '',
      fullDate: '',
      formattedServiceDate: '',
      case: null,
      patientAuthorizationId: null,
      patient: null,
      encounterInfo: {},
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = NebPageSignedEncounter.createModel();
    this.dateOfOnsetFF = false;

    this.__practiceInfo = {};

    this.__dataController = new SignedEncounterDataController(this);

    this.__practiceInfoController = new StoreController(this, {
      stateKeys: { practiceInfo: 'practiceInformation.item' },
      onStateChanged: ({ practiceInfo }) => {
        this.__practiceInfo = practiceInfo;
      },
    });

    this.onEncounterReopened = () => {};

    this.onUpdateEncounter = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      encounterReopened: () => this.onEncounterReopened(),
      updateEncounter: () => this.onUpdateEncounter(),
      refetch: () => this.__dataController.__fetchEncounterDependentData(),
    };
  }

  formatSummaryModel() {
    return {
      chartNotes: this.__dataController.state.chartNotes,
      encounter: this.model,
      patient: this.model.patient,
      practiceInfo: this.__practiceInfo,
    };
  }

  renderLoadingSpinner(loadingFlag, elementId) {
    return loadingFlag
      ? html`
          <neb-loading-overlay
            id="${elementId}"
            showDelay="0"
            .show="${loadingFlag}"
          ></neb-loading-overlay>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <neb-charting-encounter-header-controller
          id="${ELEMENTS.headerController.id}"
          .charges="${this.__dataController.state.charges}"
          .diagnoses="${this.__dataController.state.diagnoses}"
          .model="${this.model}"
          .historyExists="${this.model.hasHistory}"
          .onReopenEncounter="${this.__handlers.encounterReopened}"
          .onUpdateEncounter="${this.__handlers.updateEncounter}"
          .onRefetch="${this.__handlers.refetch}"
        ></neb-charting-encounter-header-controller>

        <div class="panel-container">
          <div class="encounter-summary-panel">
            <div id="${ELEMENTS.encounterSummaryTitle.id}" class="title">
              Encounter Summary
            </div>

            <neb-encounter-summary
              id="${ELEMENTS.encounterSummary.id}"
              class="summary"
              .model="${this.formatSummaryModel()}"
            ></neb-encounter-summary>

            ${this.renderLoadingSpinner(
              this.__dataController.loadingFlags.chartNotes,
              ELEMENTS.summaryLoadingSpinner.id,
            )}
          </div>

          <div class="diagnoses-charges-panel">
            <div class="panel">
              <div id="${ELEMENTS.diagnosesTitle.id}" class="title">
                ${formatDiagnosesTitle({
                  diagnosesLength: this.__dataController.state.diagnoses.length,
                  ...this.model,
                  dateOfOnsetFF: this.dateOfOnsetFF,
                })}
              </div>

              <neb-encounter-diagnoses-summary
                id="${ELEMENTS.diagnosesSummary.id}"
                class="fade-support"
                .model="${this.__dataController.state.diagnoses}"
              ></neb-encounter-diagnoses-summary>

              ${this.renderLoadingSpinner(
                this.__dataController.loadingFlags.diagnoses,
                ELEMENTS.diagnosesLoadingSpinner.id,
              )}
            </div>

            <div class="panel">
              <div id="${ELEMENTS.chargesTitle.id}" class="title">
                ${formatTitle('Charges', this.__dataController.state.charges)}
              </div>

              <neb-encounter-charges-summary
                id="${ELEMENTS.chargesSummary.id}"
                class="fade-support"
                .savedCharges="${this.__dataController.state.charges}"
              ></neb-encounter-charges-summary>

              ${this.renderLoadingSpinner(
                this.__dataController.loadingFlags.charges,
                ELEMENTS.chargesLoadingSpinner.id,
              )}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-page-signed-encounter', NebPageSignedEncounter);
