import '../neb-action-bar';
import '../neb-progress-blocker';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import FormService from '../../../../neb-utils/services/form';

export const ELEMENTS = {
  loadingBlocker: { id: 'blocker-loading' },
  saveBlocker: { id: 'blocker-save' },
  actionBar: { id: 'action-bar', tag: 'neb-action-bar' },
};

export class NebFormOld extends LitElement {
  static get properties() {
    return {
      __dirty: Boolean,
      __saving: Boolean,
      __sideLoading: Boolean,

      model: Object,
      state: Object,
      errors: Object,
      removeLabel: String,
      confirmLabel: String,
      cancelLabel: String,
      layout: {
        reflect: true,
        type: String,
      },
      enableSaveAndClose: {
        type: Boolean,
        reflect: true,
        attribute: 'enable-save-and-close',
      },
      disableSaveIndicator: {
        type: Boolean,
        reflect: true,
        attribute: 'disable-save-indicator',
      },
      loading: {
        reflect: true,
        type: Boolean,
      },
      loadingComplete: Promise,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .content {
          display: flex;
          overflow: auto;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
        }

        .form {
          display: flex;
          padding: ${CSS_SPACING};
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          flex-flow: column nowrap;
          flex: 1 0 0;
        }
      `,
    ];
  }

  static createModel() {
    throw new Error('createModel() not implemented');
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
    this.initForm();
  }

  initState() {
    this.__dirty = false;
    this.__saving = false;
    this.__sideLoading = false;

    this.removeLabel = 'Remove';
    this.confirmLabel = 'Save';
    this.cancelLabel = 'Cancel';
    this.layout = '';
    this.loading = false;
    this.disableSaveIndicator = false;
    this.enableSaveAndClose = false;
    this.state = {};
    this.errors = {};
    this.model = this.constructor.createModel();

    this.onCancel = () => {};

    this.onChangeDirty = () => {};

    this.onSave = () => {};
  }

  initHandlers() {
    this.handlers = {
      change: ({ name, value }) => this.formService.apply(name, value),
      addItem: (name, index = -1) => this.formService.addItem(name, index),
      removeItem: (name, index) => this.formService.removeItem(name, index),
      cancel: () => this.onCancel(),
      save: () => this.doSave(),
      saveAndClose: () => this.doSave({ closeAfterSave: true }),
    };
  }

  doSave(...args) {
    if (this.formService.validate()) {
      const model = this.formService.buildModel();

      this.__saving = true;
      this.onSave(model, ...args);
    }
  }

  initForm() {
    this.formService = new FormService(
      this.model,
      this.buildSelectors(),
      (dirty, state, errors) => {
        this.__dirty = dirty;
        this.state = state;
        this.errors = errors;
      },
    );

    this.__saving = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.reload();
  }

  buildSelectors() {
    throw new Error('buildSelectors() not implemented');
  }

  isDirty() {
    return this.__dirty;
  }

  isLoaded() {
    return !this.loading && !this.__sideLoading;
  }

  load() {
    return Promise.resolve();
  }

  reload() {
    this.__sideLoading = true;
    this.loadingComplete = this.load();

    this.loadingComplete.then(() => {
      this.__sideLoading = false;
      this.loadingComplete = null;
    });
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.formService.refresh(this.model);
      this.__saving = false;
    }

    if (changedProps.has('__dirty')) {
      this.onChangeDirty(this.__dirty);
    }

    super.update(changedProps);
  }

  renderSaveBlocker() {
    return !this.disableSaveIndicator && this.__saving
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.saveBlocker.id}"
            label="Saving"
          ></neb-progress-blocker>
        `
      : '';
  }

  renderLoadingBlocker() {
    return !this.isLoaded()
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.loadingBlocker.id}"
            label="Loading"
          ></neb-progress-blocker>
        `
      : '';
  }

  renderHeader() {
    return '';
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  __renderActionBar() {
    return this.enableSaveAndClose
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .removeLabel="${this.removeLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.saveAndClose}"
            .onRemove="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `;
  }

  renderActionBar() {
    return this.__dirty ? this.__renderActionBar() : '';
  }

  renderFooter() {
    return html`
      ${this.renderActionBar()} ${this.renderSaveBlocker()}
      ${this.renderLoadingBlocker()}
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          ${this.renderHeader()}

          <div class="form" layout="${this.layout}">
            ${this.renderContent()}
          </div>
        </div>

        ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define('neb-form-old', NebFormOld);
