import '../../../collection-pages/neb-collection-page-associate-payments';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import NebOverlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { CSS_SPACING } from '../../../../styles';

export const ELEMENTS = {
  header: { id: 'header', title: 'Associate Payment' },
  associatePaymentPage: { id: 'associate-payment-page' },
};

class NebOverlayAssociatePayment extends NebOverlay {
  static get properties() {
    return {
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header-container {
          padding: ${CSS_SPACING};
        }

        .content {
          display: grid;
          width: 80rem;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.model = {
      defaultPayer: {},
      eClaimERAReportId: null,
      reportId: null,
      reportType: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismiss: hasNewPayments => this.dismiss(hasNewPayments),
    };
  }

  __getHeaderDescription() {
    return `Select payments to be associated to the ${this.model.reportType}.`;
  }

  __renderHeader() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          showCancelButton
          title="Associate Payment"
          .onCancel="${this.handlers.dismiss}"
        ></neb-popup-header>

        <div>${this.__getHeaderDescription()}</div>
      </div>
      <neb-collection-page-associate-payments
        id="${ELEMENTS.associatePaymentPage.id}"
        .model="${this.model}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-collection-page-associate-payments>
    `;
  }

  renderContent() {
    return html` ${this.__renderHeader()} `;
  }
}

customElements.define(
  'neb-overlay-associate-payment',
  NebOverlayAssociatePayment,
);
