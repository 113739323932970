import { openPopup } from '@neb/popup';

import {
  mapToDocumentModel,
  getDefaultDocumentModel,
} from '../../../../src/utils/document-mapper';
import * as client from '../../../neb-api-client/src/document-api-client';
import { openSuccess, openError } from '../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../neb-utils/date-util';

import * as documentOptions from './neb-document-options';

export const TEXT_DELETE_DOCUMENT_SUCCESS = 'Document Successfully Deleted';
export const TEXT_DELETE_DOCUMENT_ERROR = 'Unable to Delete Document';
export const NebBaseDocumentController = superClass =>
  class extends superClass {
    static get properties() {
      return {
        patientId: String,
        totalDocuments: Number,
        searchTerms: String,
        selectedIndex: Number,
        documents: Array,
        advancedSearchModel: Object,
        __selectedDocument: Object,
        __loadingImageSrc: Boolean,
        __isLoadingDocuments: Boolean,
        __noDocuments: Boolean,
        __selectDocumentOnListRendered: Boolean,
        __routeUrl: String,
        __checkedDocuments: Object,
      };
    }

    constructor() {
      super();

      this.__initBaseState();

      this.__initBaseHandlers();
    }

    __initBaseState() {
      this.patientId = '';
      this.__selectedDocument = null;
      this.__loadingImageSrc = null;
      this.__noDocuments = false;
      this.searchTerms = '';
      this.selectedIndex = -1;
      this.documents = [];
      this.heading = 'Documents';
      this.totalDocuments = 0;
      this.__selectDocumentOnListRendered = false;
      this.__routeUrl = '';
      this.__checkedDocuments = {};
      this.showLocalLoading = true;
      this.advancedSearchModel = {};
    }

    __initBaseHandlers() {
      this.baseHandlers = {
        getMoreDocuments: () => this.__getMoreDocuments(false),
        selectDocumentIndex: index => {
          this.selectedIndex = index;
        },
        openPopupUpload: async encounterId => {
          let model = getDefaultDocumentModel();

          if (encounterId) {
            model = { ...model, encounterId };
          }
          const popupResult = await this.__openPopup('Upload Document', model);
          await this.baseHandlers.handlePopupUploadResult(popupResult);
          return popupResult;
        },
        openPopupEdit: async () => {
          const model = mapToDocumentModel(this.getSelectedItem());

          const popupResult = await this.__openPopup('Edit Document', model);
          return this.__handlePopupEditResult(popupResult, model.id);
        },
        handlePopupUploadResult: async popupResult => {
          if (popupResult === documentOptions.ActionResult.SAVED) {
            await this.__getInitialDocuments();
          }
        },
        handlePopupEditResult: popupResult => {
          const model = mapToDocumentModel(this.getSelectedItem());

          return this.__handlePopupEditResult(popupResult, model.id);
        },
        search: text => this.search(text),
        advancedSearch: advancedSearchModel => {
          const { serviceDate, ...searchModel } = advancedSearchModel;
          const dosFrom = serviceDate.from
            ? serviceDate.from.format(client.DATE_FORMAT)
            : undefined;
          const dosTo = serviceDate.to
            ? serviceDate.to.format(client.DATE_FORMAT)
            : undefined;

          return this.advancedSearch({
            ...searchModel,
            dosFrom,
            dosTo,
          });
        },
        checkDocument: (documentId, checked) => {
          this.__checkedDocuments[documentId] = checked;
        },
        selectAll: () => {
          this.__setCheckboxSelection(true);
        },
        deselectAll: () => {
          this.__setCheckboxSelection(false);
        },
        getBulkActions: () => [
          {
            id: 'selectAll',
            label: 'Select All',
            onSelect: this.baseHandlers.selectAll,
          },
          {
            id: 'deselectAll',
            label: 'Deselect All',
            onSelect: this.baseHandlers.deselectAll,
          },
        ],
      };
    }

    __getCheckedDocuments() {
      return Object.keys(this.__checkedDocuments).reduce((memo, documentId) => {
        if (this.__checkedDocuments[documentId]) memo.push(documentId);
        return memo;
      }, []);
    }

    __setCheckboxSelection(_checked) {
      throw new Error(
        '__setCheckboxSelection() not implemented in subclass',
        this,
      );
    }

    __getInitialDocuments(documentId = null) {
      return this.__getMoreDocuments(true, documentId);
    }

    __getMoreDocuments(resetBeforeFetch, documentId = null) {
      const documents = resetBeforeFetch ? [] : this.documents;

      const promise = this.__getDocuments(
        this.patientId,
        10,
        documents.length,
        this.searchTerms,
        documentId,
        null,
        this.advancedSearchModel,
      );

      this.__currentFetch = promise;
      this.__isLoadingDocuments = true;
      promise.then(({ data, count }) => {
        if (promise === this.__currentFetch) {
          this.documents = [...documents, ...data];

          this.__updateDocumentsCount(count);

          if (resetBeforeFetch) {
            this._selectIndexOnReset();
          }

          this.__isLoadingDocuments = false;
        }
      });

      return promise;
    }

    __updateDocumentsCount(count) {
      this.totalDocuments = count;
      this.heading = count > 0 ? `Documents (${count})` : 'Documents';
    }

    __selectDocument(documentId, isScrollToDocument = true) {
      const index = Math.max(0, this.__getDocumentIndex(documentId));
      this.showLocalLoading = true;
      this.selectedIndex = index;

      if (isScrollToDocument && index >= 0) {
        this._scrollToDocument(index);
      }
    }

    __getDocumentIndex(documentId) {
      if (documentId) {
        return this.documents.findIndex(document => document.id === documentId);
      }

      return -1;
    }

    getSelectedItem() {
      return this.documents.length > 0 && this.selectedIndex !== -1
        ? this.documents[this.selectedIndex]
        : null;
    }

    __getSelectedDocumentIndex() {
      if (this.__selectedDocument) {
        return this.__getDocumentIndex(this.__selectedDocument.id);
      }

      return -1;
    }

    shouldRequestSrc(newImageSrcSpec) {
      return (
        this.__loadingImageSrc ||
        !this.__imageSrcSpec ||
        this.__imageSrcSpec.id !== newImageSrcSpec.id
      );
    }

    loadImageSrcIfChanged() {
      const newImageSrcSpec = {
        id: this.__selectedDocument.id,
        mimeType: this.__selectedDocument.mimeType,
      };

      if (this.shouldRequestSrc(newImageSrcSpec)) {
        const optOutLoadingIndicator = this.showLocalLoading;
        this.__loadingImageSrc = true;
        const promise = client.getDocSrc(
          this.__selectedDocument,
          optOutLoadingIndicator,
        );
        this.__currentGetDocSrcFetch = promise;
        promise.then(src => {
          if (promise === this.__currentGetDocSrcFetch) {
            this.__imageSrcSpec = {
              src,
              ...newImageSrcSpec,
            };

            this.__loadingImageSrc = false;
          }
        });
      }
    }

    resetImageSrc() {
      this.__imageSrcSpec = null;
    }

    __getDefaultDocumentModel() {
      return {
        id: null,
        name: '',
        note: '',
        date: parseDate().startOf('day'),
        file: null,
        uploadDate: new Date(),
        thumbnail: null,
        tags: [],
      };
    }

    search(text) {
      this.searchTerms = text;
      return this.__getInitialDocuments();
    }

    advancedSearch(advancedSearchModel) {
      this.advancedSearchModel = advancedSearchModel;
      return this.__getInitialDocuments();
    }

    _selectIndexOnReset() {
      this.showLocalLoading = false;
      this.selectedIndex = 0;
    }

    _scrollToDocument(_index) {
      throw new Error('_scrollToDocument() not implemented in subclass', this);
    }

    _shouldNavigate() {
      throw new Error('_shouldNavigate() not implemented in subclass', this);
    }

    _getTouchDevice() {
      throw new Error('_getTouchDevice() not implemented in subclass', this);
    }

    __parseHash(hash, PATH_REGEX) {
      if (hash != null && hash.match(PATH_REGEX) != null) {
        return hash.match(PATH_REGEX)[1];
      }

      return null;
    }

    update(changedProps) {
      if (changedProps.has('documents') || changedProps.has('selectedIndex')) {
        this.__selectedDocument = this.getSelectedItem();
      }

      if (
        changedProps.has('documents') ||
        changedProps.has('__isLoadingDocuments')
      ) {
        this.__noDocuments =
          this.documents.length === 0 && !this.__isLoadingDocuments;
      }

      super.update(changedProps);
    }

    updated(changedProps) {
      if (changedProps.has('__selectedDocument')) {
        if (this.__selectedDocument) {
          this.loadImageSrcIfChanged();
        } else {
          this.resetImageSrc();
        }
      }
    }

    async __openPopup(title, model) {
      const { result } = await openPopup(POPUP_RENDER_KEYS.DOCUMENT, {
        title,
        patientId: this.patientId,
        touchDevice: this._getTouchDevice(),
        data: model,
      });
      return result;
    }

    async __handlePopupEditResult(popupResult, documentId) {
      if (popupResult === documentOptions.ActionResult.SAVED) {
        await this.__getInitialDocuments(documentId);

        this.__selectDocument(documentId, true);
      }

      if (popupResult === documentOptions.ActionResult.DELETED) {
        try {
          await client.deleteDocument(documentId);
          store.dispatch(openSuccess(TEXT_DELETE_DOCUMENT_SUCCESS));
        } catch (err) {
          console.error(err);
          store.dispatch(openError(TEXT_DELETE_DOCUMENT_ERROR));
        }

        await this.__getInitialDocuments();
      }

      return popupResult;
    }

    __getDocuments(
      patientId,
      limit,
      offset,
      searchTerms,
      documentId,
      encounterId = null,
      advancedFilters = null,
    ) {
      try {
        return client.getDocumentsWithThumbnails({
          patientId,
          limit,
          offset,
          searchTerms,
          documentId,
          encounterId,
          advancedFilters,
        });
      } catch (err) {
        console.error(
          `An issue occurred getting document metadata: ${err}, patientId: ${patientId}`,
        );

        return {
          data: [],
          count: 0,
        };
      }
    }

    async getDocumentCount(patientId) {
      if (!patientId) {
        return 0;
      }

      try {
        const documentCount = await client.getDocumentCount(patientId);
        return documentCount;
      } catch (err) {
        console.error(
          `An issue occurred getting document count: ${err}, patientId: ${patientId}`,
        );

        return 0;
      }
    }

    async firstUpdated() {
      await super.firstUpdated();

      this.showLocalLoading = false;
    }
  };
