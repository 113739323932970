import '../inputs/neb-textfield';

import { html, css } from 'lit';

import * as encountersApiClient from '../../../../neb-api-client/src/encounters-api-client';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import {
  currencyToCents,
  centsToCurrency,
} from '../../../../neb-utils/formatters';
import { number, currency } from '../../../../neb-utils/masks';
import { AUTHORIZATION_TYPE } from '../../../../neb-utils/patientAuthorization';
import { openEncounterSummary } from '../../utils/encounter-overlays-util';
import { formatCode } from '../patients/ledger/charges/neb-ledger-charges-util';

import NebTable from './neb-table';

const calculateRemaining = (allowed, rendered) => {
  let remaining = allowed - rendered;
  if (remaining < 0) remaining = 0;
  return remaining;
};

export const formatRemaining = ({ allowed, rendered }) => {
  if (allowed.length) {
    const isDollar = allowed[0] === '$';

    let allowedNumber = allowed;
    let renderedNumber = rendered;

    if (isDollar) {
      allowedNumber = currencyToCents(allowed);
      renderedNumber = currencyToCents(rendered);
    }

    const remaining = calculateRemaining(allowedNumber, renderedNumber);

    return isDollar ? centsToCurrency(remaining) : `${remaining}`;
  }

  if (rendered.length && rendered[0] === '$') return '$0.00';

  return '0';
};

const CONFIG = [
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`1 0 0`,
    formatter: (_, row) => formatCode(row.procedure, row.modifiers),
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'allowed',
    label: 'Allowed',
    flex: css`1 0 0`,
  },
  {
    key: 'rendered',
    label: 'Rendered',
    flex: css`1 0 0`,
  },
  {
    key: 'remaining',
    label: 'Remaining',
    flex: css`1 0 0`,
    formatter: (_, row) => formatRemaining(row),
  },
  {
    key: 'encounters',
    label: 'Encounters',
    flex: css`1 0 0`,
  },
];

export const ELEMENTS = {
  allowedField: {
    selector: '[id^=allowed-]',
  },
  remainingField: { selector: '[key=remaining]' },
  encounterInfo: { selector: '[id^=encounter-info-]' },
  encounterLink: { selector: '[id^=encounter-link-]' },
};

class NebTableAuthorizedProcedures extends NebTable {
  static get properties() {
    return {
      authorizationType: String,
      patientId: String,
    };
  }

  initState() {
    super.initState();

    this.config = CONFIG;
    this.authorizationType = AUTHORIZATION_TYPE.DollarAmount;
    this.writable = true;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,

      clickEncounter: async ({ name }) => {
        const [, rowIndex, index] = name.split('.');

        const { encounterId } = this.model[rowIndex].encounters[index];

        const encounter = await encountersApiClient.getEncounter(encounterId);

        const patient = await patientApiClient.fetchOne(this.patientId, true);

        return openEncounterSummary({
          encounterId,
          appointmentTypeId: encounter.appointmentTypeId,
          patient,
        });
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .allowed {
          margin-top: 10px;
        }

        .tooltip {
          margin-left: 10px;
          font-weight: 400;
        }

        .description {
          padding: 10px 0;
          word-wrap: break-word;
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('authorizationType')) {
      this.config = CONFIG.filter(({ key }) => {
        switch (key) {
          case 'allowed':
          case 'remaining':
            return (
              this.authorizationType !== AUTHORIZATION_TYPE.Visits &&
              this.authorizationType !== AUTHORIZATION_TYPE.DateRange
            );
          default:
            return true;
        }
      });
    }

    super.update(changedProps);
  }

  renderHeaderCell(columnConfig) {
    if (columnConfig.key === 'rendered') {
      return html`Rendered`;
    }

    return columnConfig.label;
  }

  __renderLink({ value, rowIndex, onClick, id, name, index }) {
    return html`
      <neb-text
        id="${id}-${rowIndex}-${index}"
        bold
        link
        .name="${name}"
        .onClick="${onClick}"
        >${value}</neb-text
      >
    `;
  }

  __renderEncounterLinks(rowIndex) {
    const { encounters } = this.model[rowIndex];
    return encounters
      ? encounters
          .sort((a, b) => b.encounterNumber - a.encounterNumber)
          .map((encounter, index, encArray) => {
            const { encounterNumber } = encounter;
            return this.__renderLink({
              value: `${encounterNumber}${
                index === encArray.length - 1 ? '' : ','
              }`,
              rowIndex,
              onClick: this.handlers.clickEncounter,
              id: `encounter-link-${rowIndex}-${index}`,
              name: `encounter-link.${rowIndex}.${index}`,
              index,
            });
          })
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    switch (columnConfig.key) {
      case 'description':
        return html`<neb-text class="description">${value}</neb-text>`;

      case 'allowed':
        return html`
          <neb-textfield
            id="allowed-${rowIndex}"
            name="${name}"
            class="allowed"
            helper="Required"
            maxLength="${this.authorizationType ===
            AUTHORIZATION_TYPE.DollarAmount
              ? '11'
              : '3'}"
            .mask="${this.authorizationType === AUTHORIZATION_TYPE.DollarAmount
              ? currency
              : number}"
            .inputMode="${'numeric'}"
            .value="${value}"
            .error="${error}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `;

      case 'encounters': {
        return html`
          <div id="encounter-info-${rowIndex}">
            ${this.__renderEncounterLinks(rowIndex)}
          </div>
        `;
      }

      default:
        return value;
    }
  }
}
customElements.define(
  'neb-table-authorized-procedures',
  NebTableAuthorizedProcedures,
);
