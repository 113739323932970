import '../../../../../src/components/misc/neb-icon';
import '../neb-table-header';
import '../neb-button-actions';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_DISABLED,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import DragAndDropService from '../../services/drag-and-drop';
import { DEFAULT_TOGGLE_ICON } from '../neb-toggle-button';

export const ELEMENTS = {
  buttonActions: { selector: 'neb-button-actions' },
  header: { id: 'table-header', tag: 'neb-table-header' },
  noContentContainer: { id: 'no-content-container' },
  buttonAdd: { selector: '.button-add', tag: 'neb-toggle-button' },
  removeIcon: { selector: '.icon-remove', tag: 'neb-icon' },
  dragIcon: { selector: '.icon-drag' },
  detailIcon: { selector: '.icon-detail' },
  rows: { selector: '.content' },
  cells: { selector: '.cell' },
  clickableRows: { selector: 'div[clickable]' },
  dragIcons: { selector: 'neb-icon.icon-drag' },
  expandIcons: { selector: 'neb-icon.cell-icon-expand' },
};
export default class NebTableOld extends LitElement {
  static get properties() {
    return {
      allAdded: {
        reflect: true,
        type: Boolean,
      },
      addAllLabel: String,
      addAllToggledIcon: String,
      addLabel: String,
      forceDownMenu: Boolean,
      name: String,
      sortParams: Object,
      params: Array,
      config: Array,
      errors: Array,
      model: Array,
      expandedFlags: Array,
      maxVisibleItems: Number,
      onRenderExpandableRow: Function,
      onRenderLeadingHeaderCustom: Function,
      onRenderLeadingCustom: Function,
      onClickDetailIcon: Function,
      getLeadingHeaderActions: Function,
      getTrailingHeaderActions: Function,
      getLeadingActions: Function,
      getTrailingActions: Function,
      forceHeaderActionsDown: {
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      reorder: {
        type: Boolean,
        reflect: true,
      },
      reorderDisabled: {
        type: Boolean,
        reflect: true,
      },
      preview: {
        type: Boolean,
        reflect: true,
      },
      writable: {
        type: Boolean,
        reflect: true,
      },
      showDetailArrow: {
        type: Boolean,
        reflect: true,
      },
      showRemoveButton: {
        type: Boolean,
        reflect: true,
      },
      showRemoveAllButton: Boolean,
      condensed: {
        type: Boolean,
        reflect: true,
      },
      addAllAlwaysEnabled: Boolean,
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
    this.initServices();
  }

  initState() {
    this.writable = false;
    this.showDetailArrow = false;
    this.showRemoveButton = false;
    this.preview = false;
    this.reorder = false;
    this.reorderDisabled = false;
    this.forceHeaderActionsDown = false;
    this.condensed = false;
    this.forceDownMenu = false;
    this.allAdded = null;
    this.addAllLabel = '';
    this.addAllToggledIcon = DEFAULT_TOGGLE_ICON;
    this.addLabel = '';
    this.name = '';
    this.layout = '';
    this.config = [];
    this.errors = [];
    this.model = [];
    this.expandedFlags = [];
    this.params = {};
    this.sortParams = {};
    this.addAllAlwaysEnabled = false;

    this.onChange = () => {};

    this.onSelect = () => {};

    this.onSort = () => {};

    this.onRemove = () => {};

    this.onToggleExpand = () => {};

    this.onAddAll = () => {};

    this.onClickDetailIcon = null;
    this.onRenderLeadingHeaderCustom = null;
    this.onRenderExpandableRow = null;
    this.onRenderLeadingCustom = null;
    this.getLeadingActions = null;
    this.getTrailingActions = null;
    this.getLeadingHeaderActions = null;
    this.getTrailingHeaderActions = null;
    this.maxVisibleItems = 5;
  }

  initHandlers() {
    this.handlers = {
      sort: params => this.onSort(params),
      change: e => {
        if (e.event) {
          this.onChange({
            name: `${this.name}.${e.name}`,
            value: e.value,
            event: e.event,
          });
        } else {
          this.onChange({
            name: `${this.name}.${e.name}`,
            value: e.value,
          });
        }
      },
      remove: e => {
        e.stopPropagation();
        const index = Number(e.currentTarget.index);
        const item = this.model[index];

        if (this.shouldEnableRemoveButton(item, index)) {
          this.onRemove(this.name, Number(e.currentTarget.index));
        }
      },
      removeAll: () => {
        [...this.model].map(() => this.onRemove(this.name));
      },
      clickRow: e => {
        const index = Number(e.currentTarget.index);
        const item = this.model[index];

        if (this.onRenderExpandableRow) {
          this.onToggleExpand(index, item, !this.expandedFlags[index]);
        } else {
          this.onSelect(index, item);
        }
      },
      rearrangeItems: items => {
        this.onChange({
          name: this.name,
          value: items,
        });
      },
      showDetail: e => {
        const index = Number(e.currentTarget.index);
        const item = this.model[index];

        if (this.onClickDetailIcon) {
          e.stopPropagation();
          this.onClickDetailIcon(index, item);
        }
      },
      addAll: () => this.onAddAll(this.model),
    };
  }

  initServices() {
    this.__dragAndDropService = new DragAndDropService(
      this,
      '.icon-drag',
      '.content',
      this.handlers.rearrangeItems,
    );
  }

  updated(_changedProps) {
    if (this.reorder) {
      this.__dragAndDropService.setItems(this.model);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;

          --add-button-column-width: 180px;
          --custom-add-all-column-width: 180px;
          --custom-icon-spacer-max-height: none;
          --content-background-color: ${CSS_COLOR_WHITE};
          --content-padding: 18px ${CSS_SPACING};
          --action-menu-min-width: 210px;
        }

        :host([dragging]) .content {
          background-color: ${CSS_COLOR_WHITE};
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
        }

        :host([condensed]) .content {
          font-size: 12px;
          padding: 0;
          border: none;
        }

        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-flow: nowrap column;
          position: relative;
        }

        :host([overflow]) .container {
          min-width: var(--minWidth);
        }

        .content {
          display: flex;
          min-height: 30px;
          font-size: ${CSS_FONT_SIZE_BODY};
          padding: var(--content-padding);
          flex-direction: column;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          background-color: var(--content-background-color);
        }

        .header {
          --add-all-column-width: var(--custom-add-all-column-width);
          --icon-spacer-max-height: var(--custom-icon-spacer-max-height);
        }

        :host([preview]) .content {
          padding: 10px;
        }

        .content[expanded] {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .row[clickable] {
          cursor: pointer;
        }

        .row {
          display: flex;
          align-items: center;
        }

        .cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: pre-line;
          word-break: break-word;
        }

        .cell:last-child {
          margin-right: 0;
        }

        .cell[truncate] {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .cell[link] {
          cursor: pointer;
          text-decoration: underline;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .cell-custom {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          justify-content: center;
          width: 28px;
        }

        .cell-spacer {
          width: 28px;
        }

        .cell-icon {
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .cell-icon-expand[expanded] {
          transform: rotate(-180deg);
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .cell-actionable {
          white-space: unset;
          margin-right: 8px;
          height: 20px;
          width: 40px;
          position: relative;
        }

        .button-add {
          min-width: var(--add-button-column-width);
          max-width: var(--add-button-column-width);
          white-space: nowrap;
          margin-right: ${CSS_SPACING};
        }

        .button-actions {
          height: 20px;
          position: absolute;
          top: 3px;
          left: 0;
          --menu-min-width: var(--action-menu-min-width);
        }

        .container-no-content {
          margin: ${CSS_SPACING};
        }

        .text-no-items {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .icon-remove {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .icon-remove[disabled] {
          cursor: default;
          opacity: 0.5;
          fill: ${CSS_COLOR_DISABLED};
        }

        .icon-drag {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: ns-resize;
        }

        :host([reorderDisabled]) .icon-drag {
          cursor: default;
          opacity: 0.5;
          fill: ${CSS_COLOR_DISABLED};
          pointer-events: none;
        }

        .icon-detail[clickable] {
          cursor: pointer;
        }

        .icon-detail {
          transform: rotate(-90deg);
        }
      `,
    ];
  }

  renderCell(value, _key, _rowIndex) {
    return value;
  }

  shouldEnableRemoveButton(_item, _index) {
    return true;
  }

  shouldEnableRemoveAllButton() {
    return true;
  }

  renderAddButton(_, index) {
    return html`
      <neb-toggle-button
        class="button-add"
        buttonText="Add"
        index="${index}"
      ></neb-toggle-button>
    `;
  }

  __renderRemoveButton(item, index) {
    const enabled = this.shouldEnableRemoveButton(item, index);
    return enabled !== null
      ? html`
          <neb-icon
            class="cell icon-remove"
            icon="neb:minus"
            .index="${index}"
            ?disabled="${!enabled}"
            @click="${this.handlers.remove}"
          ></neb-icon>
        `
      : html` <div class="cell icon-remove"></div> `;
  }

  __renderActionButton(row, identifier) {
    const callback = this[identifier];
    return html`
      <div class="cell cell-actionable">
        <neb-button-actions
          id="${ELEMENTS.buttonActions.id}"
          class="button-actions"
          .value="${callback(row)}"
          vertical
        ></neb-button-actions>
      </div>
    `;
  }

  __renderRow(item, index) {
    const rowCells = this.config
      .filter(item => this.layout !== 'small' || item.mobile)
      .map(cell => {
        const value = cell.formatter
          ? cell.formatter(item[cell.key], item)
          : item[cell.key];
        return html`
          <span
            class="cell cell-${cell.key}"
            style="flex: ${cell.flex}"
            ?truncate="${cell.truncate}"
            ?link="${cell.link}"
            >${this.renderCell(value, cell.key, index)}</span
          >
        `;
      });
    const itemIsExpanded = this.expandedFlags[index];

    let rowContent = [];

    if (this.reorder) {
      rowContent.push(html`
        <neb-icon class="cell icon-drag" icon="neb:reorder"></neb-icon>
      `);
    }

    if (
      this.getLeadingHeaderActions ||
      this.getDynamicLeadingHeaderActions ||
      this.onRenderLeadingHeaderCustom
    ) {
      if (this.onRenderLeadingCustom) {
        rowContent.push(html`
          <div class="cell-custom">
            ${this.onRenderLeadingCustom(item, index, this.handlers.change)}
          </div>
        `);
      } else {
        rowContent.push(html` <div class="cell cell-spacer"></div> `);
      }
    }

    if (this.getLeadingActions) {
      rowContent.push(this.__renderActionButton(item, 'getLeadingActions'));
    }

    if (this.allAdded !== null) {
      rowContent.push(this.renderAddButton(item, index));
    }

    rowContent = [...rowContent, ...rowCells];

    if (this.getTrailingHeaderActions || this.getDynamicTrailingHeaderActions) {
      rowContent.push(html` <div class="cell cell-spacer"></div> `);
    }

    if (this.getTrailingActions) {
      rowContent.push(this.__renderActionButton(item, 'getTrailingActions'));
    }

    if (this.showRemoveButton) {
      rowContent.push(this.__renderRemoveButton(item, index));
    }

    if (this.showDetailArrow) {
      rowContent.push(html`
        <neb-icon
          class="cell cell-icon icon-detail"
          icon="neb:chevron"
          .index="${index}"
          ?clickable="${this.onClickDetailIcon}"
          @click="${this.handlers.showDetail}"
        ></neb-icon>
      `);
    }

    if (this.onRenderExpandableRow) {
      rowContent.push(html`
        <neb-icon
          class="cell cell-icon cell-icon-expand"
          icon="neb:chevron"
          ?expanded="${itemIsExpanded}"
        ></neb-icon>
      `);
    }

    return html`
      <div class="content" ?expanded="${itemIsExpanded}">
        <div
          class="row"
          .index="${index}"
          ?clickable="${(!this.onClickDetailIcon && this.showDetailArrow) ||
          !!this.onRenderExpandableRow}"
          @click="${this.handlers.clickRow}"
        >
          ${rowContent}
        </div>
        <div class="row">
          ${itemIsExpanded
            ? this.onRenderExpandableRow(
                this.model[index],
                this.errors[index],
                index,
                this.handlers.change,
              )
            : ''}
        </div>
      </div>
    `;
  }

  __renderContent() {
    return this.model && this.model.length
      ? this.model.map((item, index) => this.__renderRow(item, index))
      : html`
          <div
            id="${ELEMENTS.noContentContainer.id}"
            class="container-no-content"
          >
            <slot class="text-no-items"></slot>
          </div>
        `;
  }

  render() {
    return html`
      <div class="container">
        <neb-table-header
          id="${ELEMENTS.header.id}"
          class="header"
          .layout="${this.layout}"
          .config="${this.config}"
          .sortParams="${this.sortParams}"
          .onSort="${this.handlers.sort}"
          .onAddAll="${this.handlers.addAll}"
          .getLeadingHeaderActions="${this.getLeadingHeaderActions}"
          .getTrailingHeaderActions="${this.getTrailingHeaderActions}"
          .getDynamicLeadingHeaderActions="${this
            .getDynamicLeadingHeaderActions}"
          .getDynamicTrailingHeaderActions="${this
            .getDynamicTrailingHeaderActions}"
          .onRenderLeadingHeaderCustom="${this.onRenderLeadingHeaderCustom}"
          .allAdded="${this.allAdded}"
          .addAllLabel="${this.addAllLabel}"
          .addAllToggledIcon="${this.addAllToggledIcon}"
          .reorder="${this.reorder}"
          .condensed="${this.condensed}"
          .forceDownMenu="${this.forceDownMenu}"
          ?preview="${this.preview}"
          ?showDetailIcon="${this.showDetailArrow}"
          ?showExpandButton="${this.onRenderExpandableRow}"
          ?showRemoveButton="${this.showRemoveButton}"
          ?showLeadingActions="${!!this.getLeadingActions}"
          ?showTrailingActions="${!!this.getTrailingActions}"
          ?showRemoveAllButton="${this.showRemoveAllButton}"
          .forceHeaderActionsDown="${this.forceHeaderActionsDown}"
          .maxVisibleItems="${this.maxVisibleItems}"
          .addAllAlwaysEnabled="${this.addAllAlwaysEnabled}"
          .onRemoveAll="${this.handlers.removeAll}"
          .removeAllDisabled="${!this.shouldEnableRemoveAllButton()}"
        ></neb-table-header>

        ${this.__renderContent()}
      </div>
    `;
  }
}
customElements.define('neb-table-old', NebTableOld);
