import { createEncounter } from '../../../../neb-redux/actions/encounterActions';
import { store } from '../../../../neb-redux/neb-redux-store';
import { PRACTICE_SETTINGS, getPracticeSettings } from '../practice-settings';

import postAllCharges from './post-all-charges';

const autoPostCharges = async ({
  res: {
    data: [{ id: encounterId, patientId }],
    withAutoSalt,
  },
}) => {
  if (!withAutoSalt) return;

  const practiceSettings = await getPracticeSettings();
  const autoPost = practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];

  if (!autoPost) return;

  await postAllCharges(encounterId, patientId);
};

export default async (appointmentId, currentUser, providerName) => {
  const res = await store.dispatch(
    createEncounter(appointmentId, currentUser, providerName),
  );

  if (res) {
    await autoPostCharges(res);
  }

  return res;
};
