import './neb-patients-roster-page';
import '../../../../neb-lit-components/src/components/neb-action-bar';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { createPatients } from '../../../../neb-api-client/src/patient-roster-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';

export const ID_PAGE = 'id-page';
export const ID_ACTION_BAR = 'action-bar';
const BUTTON_LABEL_CONFIRM = 'Create Patients';
export const STATUS = 'Ready';
export const PAGE_SIZE = 50;

export const ELEMENTS = {
  actionBar: {
    id: ID_ACTION_BAR,
  },
};
class NebPatientsReadyPage extends LitElement {
  static get properties() {
    return {
      importJobId: {
        type: Number,
      },
      totalCount: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.totalCount = 0;

    this.onCancel = () => {};

    this.onSuccess = () => {};

    this.onError = () => {};

    this.onAfterPatientSaved = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => this.__handleSave(),
      cancel: () => this.onCancel(),
      success: () => this.onSuccess(),
      error: () => this.onError(),
      afterPatientSaved: () => this.onAfterPatientSaved(),
    };
  }

  async __handleSave() {
    const accepted = await this.__openCreateDialog();
    if (accepted) this.__handleCreatePatient();
  }

  __fetchPatientRoster() {
    const rosterPage = this.shadowRoot.getElementById(ID_PAGE);

    return rosterPage.fetchPatientRoster();
  }

  async __handleCreatePatient() {
    try {
      await createPatients(this.importJobId);

      this.__handlers.success();

      this.__handlers.afterPatientSaved();

      store.dispatch(openSuccess('Patient records created.'));
    } catch (error) {
      await this.__handlers.error();
      await this.__fetchPatientRoster();
      store.dispatch(openError('Error'));
    }
  }

  async __openCreateDialog() {
    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Create Patients',
      message: html`
        You are about to create patient records in the system for each entry
        listed on the "Ready to Import" tab.<br />
        <br />${this.totalCount} patient records will be created. <br />
        <br />Do you wish to proceed?
      `,
      confirmText: 'YES',
      cancelText: 'NO',
    });

    return accepted;
  }

  static get styles() {
    return css`
      .roster-page {
        min-height: 0;
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      <neb-patients-roster-page
        id="${ID_PAGE}"
        class="roster-page"
        .importStatus="${STATUS}"
        .importJobId="${this.importJobId}"
        .totalCount="${this.totalCount}"
        .pageSize="${PAGE_SIZE}"
      >
      </neb-patients-roster-page>
      ${this.totalCount === 0
        ? ''
        : html`
            <neb-action-bar
              id="${ELEMENTS.actionBar.id}"
              .onConfirm="${this.__handlers.save}"
              .onCancel="${this.__handlers.cancel}"
              confirmLabel="${BUTTON_LABEL_CONFIRM}"
            ></neb-action-bar>
          `}
    `;
  }
}
customElements.define('neb-patients-ready-page', NebPatientsReadyPage);
