const FULL_TIME_RANGE = [...Array(24).keys()];
const CALCULATE_FOR_ALL_PROVIDERS = null;

/**
 * Computes the appropraite time ranges for a particular provider, based on the aggregate availability from all available days.
 * @param {Object} providerAvailability - provider availability from the server
 * @param {String} [providerId] - optional specific provider id, or if not specified, updates all.
 */
function calculateTimeRange(
  providerAvailability,
  providerId = CALCULATE_FOR_ALL_PROVIDERS,
) {
  const providerKeys = Object.keys(providerAvailability);

  for (let i = 0; i < providerKeys.length; i++) {
    const providerKey = providerKeys[i];

    if (
      providerId === CALCULATE_FOR_ALL_PROVIDERS ||
      providerKey === providerId
    ) {
      const individualProviderAvailability = providerAvailability[providerKey];

      if (
        individualProviderAvailability &&
        individualProviderAvailability.availability
      ) {
        const unavailable = FULL_TIME_RANGE.slice();
        const available = [];
        const enabledDayAvailabilities = Object.keys(
          individualProviderAvailability.availability,
        )
          .map(day => individualProviderAvailability.availability[day])
          .filter(dayAvailability => dayAvailability.enabled);
        enabledDayAvailabilities.forEach(dayAvailability => {
          dayAvailability.periods.forEach(period => {
            const hourMapping = [];

            for (let j = 0; j < unavailable.length; j++) {
              const hour = unavailable[j]; // Need to truncate this in case a start period is not on top of the hour.  For example, if 5:30 PM,
              //  and there are no other periods to account for it, need to truncate to 5 to match unavailable time slot.
              // This is because the unavailable times always start at the top of an hour.

              const startToConsider = Math.trunc(period.start);

              if (startToConsider <= hour && hour < period.end) {
                hourMapping.push(hour);
              }
            }

            hourMapping.forEach(hour => {
              available.push(hour); // Delete hour from unavailable as optimization, and preventing duplicates

              const index = unavailable.indexOf(hour);

              if (index > -1) {
                unavailable.splice(index, 1);
              }
            });
          });
        });

        available.sort((a, b) => a - b); // Numeric sort

        individualProviderAvailability.timeRange = available;
      }
    }
  }
}

export { calculateTimeRange };
