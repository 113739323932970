import '../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/neb-tooltip';

import { html, css } from 'lit';

import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  DARK_COLORS,
  ROW_LAYOUT,
} from '../../../packages/neb-lit-components/src/components/inputs/neb-picker-color';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { required } from '../../../packages/neb-utils/validators';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  pickerColor: {
    id: 'picker-color',
  },
  textareaDescription: {
    id: 'textarea-description',
  },
  textfieldName: {
    id: 'textfield-name',
  },
};

export default class NebFormDocumentTags extends Form {
  static get properties() {
    return {
      formSaving: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: ${CSS_SPACING};
        }

        .field {
          width: fit-content;
          width: -moz-fit-content;
        }

        .textarea {
          height: 200px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
      description: '',
      color: DARK_COLORS[0],
    };
  }

  createSelectors() {
    return {
      children: {
        name: [required()],
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('formSaving')) {
      this.__saving = this.formSaving;
    }
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        name="name"
        label="Name"
        helper="Required"
        placeholder="Document Tag Name"
        maxLength="20"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
        pinLabel
      ></neb-textfield>

      <neb-textarea
        id="${ELEMENTS.textareaDescription.id}"
        class="textarea"
        name="description"
        label="Description"
        helper=" "
        maxLength="500"
        .value="${this.state.description}"
        .error="${this.errors.description}"
        .onChange="${this.handlers.change}"
        pinLabel
        showCount
      ></neb-textarea>

      <neb-picker-color
        id="${ELEMENTS.pickerColor.id}"
        class="field"
        name="color"
        label="Document Tag Color"
        .value="${this.state.color}"
        .onChange="${this.handlers.change}"
        .colorPalette="${DARK_COLORS}"
        .rowLayout="${ROW_LAYOUT.SINGLE_ROW}"
      ></neb-picker-color>
    `;
  }
}

window.customElements.define('neb-form-document-tags', NebFormDocumentTags);
