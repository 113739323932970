import '../../../neb-lit-components/src/components/controls/neb-tab-group';
import '../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../neb-lit-components/src/components/neb-nav-list';
import '../../../../src/components/pages/patients/profile/neb-patient-profile-page';
import './neb-patient-overview-page';

import { matchRouteSwitch, getRoute, redirect } from '@neb/router';
import { html, css, LitElement } from 'lit';

import { ADD_ONS, hasAddOn } from '../../../../src/utils/add-ons';
import { openDoctibleSsoLink } from '../../../../src/utils/sso';
import { store } from '../../../neb-redux/neb-redux-store';
import { navigate } from '../../../neb-route/neb-route-state';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  tabs: {
    id: 'tabs',
  },
  profileTab: {
    id: 'profile-tab',
  },
  appointmentsTab: {
    id: 'appointments-tab',
  },
  ctEngageLink: {
    id: 'ct-engage-link',
  },
  navList: {
    id: 'list',
  },
};
const TAB_PROFILE = 'profile';
const TAB_APPOINTMENTS = 'appointments';

class NebPatientTabsOverviewController extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __hasAddOnCTEngage: Boolean,
      __navItems: Array,
      route: String,
      patientId: String,
      layout: {
        type: String,
        reflect: true,
      },
      guarantors: {
        type: Array,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      .nav-container {
        display: flex;
      }

      .button-container {
        display: flex;
        flex: 1 0 0;
        margin: 10px 0;
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
      }

      .button {
        display: flex;
        cursor: pointer;
        padding-left: ${CSS_SPACING};
      }

      .content {
        flex: 1 0 0;
      }

      .icon {
        width: 15px;
        height: 15px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        padding-top: 2px;
      }

      .link-text {
        color: ${CSS_COLOR_HIGHLIGHT};
        text-decoration: underline;
        padding-right: 5px;
      }

      .list {
        width: 100%;
        height: 100%;
      }

      .tabs {
        padding: 10px 0;
        background-color: ${CSS_COLOR_WHITE};
        flex: 0 0 auto;
      }

      :host([layout='small']) .tabs {
        border: 0;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__hasAddOnCTEngage = false;
    this.__selectedTab = '';
    this.route = '';
    this.layout = '';
    this.patientId = '';
    this.guarantors = [];
    this.__navItems = [];

    this.onEditPatient = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickLink: () => openDoctibleSsoLink('/providers/messaging'),
      editClicked: () => this.onEditPatient(),
      selectTab: selectedTab =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/overview/${selectedTab}`),
        ),
      selectNavItem: index => {
        const { id } = this.__navItems[index];
        store.dispatch(
          navigate(`#/patients/${this.__patientId}/overview/${id}`),
        );
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCTEngage = await hasAddOn(ADD_ONS.CT_ENGAGE);

    this.__navItems = this.genNavItems();
  }

  genNavItems() {
    return [
      {
        exact: false,
        id: TAB_PROFILE,
        label: 'Profile',
        path: `/${TAB_PROFILE}/`,
        resolver: () => html`
          <neb-patient-profile-page
            id="${ELEMENTS.profileTab.id}"
            class="content"
            .layout="${this.layout}"
            .patientId="${this.patientId}"
            .guarantors="${this.guarantors}"
            .onEditPatient="${this.__handlers.editClicked}"
          ></neb-patient-profile-page>
        `,
      },
      {
        exact: false,
        id: TAB_APPOINTMENTS,
        label: 'Appointments',
        path: `/${TAB_APPOINTMENTS}/`,
        resolver: () => html`
          <neb-patient-overview-page
            id="${ELEMENTS.appointmentsTab.id}"
            class="content"
            .layout="${this.layout}"
            .patientId="${this.patientId}"
          ></neb-patient-overview-page>
        `,
      },
    ];
  }

  getSelectedIndex() {
    return this.__navItems.findIndex(item => `/${item.id}` === this.route);
  }

  update(changedProps) {
    if (changedProps.has('layout') || changedProps.has('route')) {
      this.__selectedTab = this.route.split('/')[1] || '';

      if (this.layout !== 'small' && this.route === '/') {
        redirect(`#${getRoute()}/profile`);
      }
    }

    super.update(changedProps);
  }

  __renderBig() {
    return this.__hasAddOnCTEngage
      ? html`
          <div class="nav-container">
            <neb-tab-group
              id="${ELEMENTS.tabs.id}"
              class="tabs"
              .selectedId="${this.__selectedTab}"
              .onSelect="${this.__handlers.selectTab}"
              .items="${this.__navItems}"
            >
            </neb-tab-group>

            <div class="button-container">
              <div
                id="${ELEMENTS.ctEngageLink.id}"
                class="button"
                @click="${this.__handlers.clickLink}"
              >
                <neb-text class="link-text"> Messages </neb-text>
                <neb-icon class="icon" icon="neb:open"></neb-icon>
              </div>
            </div>
          </div>
        `
      : html`
          <neb-tab-group
            id="${ELEMENTS.tabs.id}"
            class="tabs"
            .selectedId="${this.__selectedTab}"
            .onSelect="${this.__handlers.selectTab}"
            .items="${this.__navItems}"
          >
          </neb-tab-group>
        `;
  }

  __renderSmall() {
    const selectedIndex = this.getSelectedIndex();

    const items = this.__navItems.map(item => item.label);

    return selectedIndex === -1
      ? html`
          <neb-nav-list
            id="${ELEMENTS.navList.id}"
            class="list"
            .selectedIndex="${selectedIndex}"
            .items="${items}"
            .onSelect="${this.__handlers.selectNavItem}"
          ></neb-nav-list>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-tabs-overview-controller',
  NebPatientTabsOverviewController,
);
