import { openPopup, clearPopups } from '@neb/popup';
import { navigate } from '@neb/router';

import { hasPermission } from '../../../packages/neb-api-client/src/permissions-api-client';
import { URL_NO_ACCESS } from '../../../packages/neb-utils/neb-request-security';

export const CONTEXT_STACK_KEY = 'context';

export const clearContexts = () => clearPopups(CONTEXT_STACK_KEY);

export const openContext = (key, model) => {
  clearContexts();

  if (!key.permission || hasPermission(key.permission)) {
    return openPopup(key.name, model, CONTEXT_STACK_KEY);
  }

  navigate(URL_NO_ACCESS);
  return Promise.resolve();
};

export const CONTEXT_KEYS = {
  MENU: { name: 'menu', permission: '' },
};
