import * as actions from '../../../../neb-redux/actions/action-creator';

const generateBaseUrl = encounterId => `encounters/${encounterId}/diagnoses`;

const reducerName = 'encounterDiagnosis';
export const getEncounterDiagnosis = (
  encounterId,
  optOutLoadingIndicator = false,
) =>
  actions.createFetchItemsAction(
    reducerName,
    generateBaseUrl(encounterId),
    undefined,
    undefined,
    optOutLoadingIndicator,
  );
