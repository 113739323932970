import '@polymer/paper-button/paper-button';

import '../../neb-lit-components/src/components/neb-toggle-chevron';
import './neb-treatment-plan-table';

import { LitElement, html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { renderNebButtonStyles } from '../../neb-styles/neb-button-styles';
import { renderNebTableStyles } from '../../neb-styles/neb-table-styles';
import { renderTypographyStyles } from '../../neb-styles/neb-typography';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';

export const ELEMENTS = {
  copyButtons: {
    selector: 'paper-button',
  },
  treatmentPlanTable: {
    selector: 'neb-treatment-plan-table',
  },
  rows: {
    selector: '.neb-table-row',
  },
  chevrons: {
    selector: 'neb-toggle-chevron',
  },
  copyButtonsDesktop: {
    selector: '.copy-desktop',
  },
  copyButtonsMobile: {
    selector: '.copy-mobile',
  },
};

class NebTreatmentPlansTable extends LitElement {
  static get properties() {
    return {
      treatmentPlans: {
        type: Array,
      },
      disableCopy: {
        type: Boolean,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.treatmentPlans = [];
    this.__handleToggle = this.__toggle.bind(this);
  }

  render() {
    return html`
      ${renderNebTableStyles()} ${renderNebButtonStyles()}
      ${renderTypographyStyles()}
      <style>
        .spacer {
          width: 230px;
          white-space: wrap;
        }
        :host([small]) .spacer {
          width: 40px;
        }
        .cell-text {
          line-height: 25px;
        }
        #no-treatment-plan-msg {
          font-style: italic;
          padding-left: ${CSS_SPACING};
        }
        .neb-table-row[expanded] {
          border-bottom: 0;
        }
        .neb-table-row .neb-table-cell:last-child {
          padding-right: ${CSS_SPACING};
        }

        .thin-button {
          white-space: wrap;
          height: 30px;
          font-weight: 100;
          text-transform: none;
        }
        :host([small]) .neb-table-row {
          padding: 10px ${CSS_SPACING};
        }

        :host([small]) .prior-plan-container {
          flex-direction: column;
        }

        :host(:not([small])) .neb-table-small-width {
          flex: 1;
        }

        :host([small]) .neb-table-cell {
          white-space: nowrap;
          padding: 0;
        }

        :host([small]) .thin-button {
          max-width: 180px;
          margin-top: 8px;
          margin-bottom: 10px;
        }

        [hidden],
        :host(:not([small])) .hide-when-not-small,
        :host([small]) .hide-when-small {
          display: none;
        }
      </style>
      <div id="header" class="neb-table-row neb-table-header">
        <div class="neb-table-cell spacer hide-when-small"></div>
        <div id="start-date" class="neb-table-cell neb-table-small-width">
          Start Date
        </div>
        <div id="apt" class="neb-table-cell spacer hide-when-not-small"></div>
        <div id="end-date" class="neb-table-cell neb-table-small-width">
          End Date
        </div>
        <div
          id="phases"
          class="neb-table-cell neb-table-small-width hide-when-small"
        >
          Phases
        </div>
        <div
          id="appointments"
          class="neb-table-cell neb-table-small-width hide-when-small"
        >
          Appointments
        </div>
        <div class="neb-table-cell neb-table-medium-width"></div>
        <div class="neb-table-cell neb-table-align-end"></div>
      </div>
      ${this.treatmentPlans.length > 0
        ? html``
        : html`
            <p id="no-treatment-plan-msg" class="neb-font-regular-secondary">
              There are no previous treatment plans for this patient.
            </p>
          `}
      ${repeat(
        this.treatmentPlans,
        (plan, index) => html`
            <div id="row${index}" class="plan neb-table-row">
              <div
                id="apt"
                class="neb-table-cell spacer hide-when-small"
              >
                <paper-button
                  id="copy-button${index}"
                  class="copy-desktop thin-button neb-button-primary-focus hide-when-small"
                  .disabled="${this.disableCopy}"
                  @click="${() => this.__copyPlan(index)}"
                  >Copy Treatment Plan</paper-button
                >
              </div>
              <div
                id="start-date${index}"
                class="neb-table-cell neb-table-small-width cell-text"
              >
                ${this.__setDate(plan.startDate)}
              </div>
              <div
                id="apt"
                class="neb-table-cell spacer hide-when-not-small"
              ></div>
                <div
                  id="end-date${index}"
                  class="neb-table-cell neb-table-small-width cell-text"
                >
                  ${this.__setDate(plan.completedDate)}
                </div>
                <div
                  id="phases${index}"
                  class="neb-table-cell neb-table-small-width cell-text hide-when-small"
                >
                  ${plan.phaseCount}
                </div>
                <div
                  id="appointments${index}"
                  class="neb-table-cell neb-table-small-width cell-text hide-when-small"
                >
                  ${plan.appointmentCount}
                </div>
                <div class="neb-table-cell neb-table-medium-width"></div>
                <neb-toggle-chevron
                  id="toggle${index}"
                  class="neb-table-cell neb-table-align-end"
                  .index="${index}"
                  .onToggle="${this.__handleToggle}"
                ></neb-toggle-chevron>
              </div>
              <div
                id="treatmentPlan${index}"
                class="neb-table-row prior-plan-container"
                hidden
              >
                <div
                  class="neb-table-cell spacer hide-when-small"
                ></div>
                <neb-treatment-plan-table
                  id="table${index}"
                  class="neb-table-cell neb-table-medium-width"
                  .items="${plan.treatmentPhases}"
                  .readOnly
                  summary
                ></neb-treatment-plan-table>
                <div class="neb-table-cell neb-table-large-width spacer"></div>
                <paper-button
                  id="copy-button${index}"
                  class="copy-mobile thin-button neb-button-primary-focus hide-when-not-small"
                  .disabled="${this.disableCopy}"
                  @click="${() => this.__copyPlan(index)}"
                  >Copy Treatment Plan</paper-button
                >
              </div>
            </div>
          `,
      )}
    `;
  }

  __setDate(planDate) {
    const date = parseDate(planDate);
    return !date ? '' : date.format('MM/DD/YYYY');
  }

  __getTreatmentPlan(index) {
    return this.shadowRoot.getElementById(`treatmentPlan${index}`);
  }

  __getRow(index) {
    return this.shadowRoot.getElementById(`row${index}`);
  }

  __expandRow(index) {
    this.__getTreatmentPlan(index).removeAttribute('hidden');

    this.__getRow(index).setAttribute('expanded', '');
  }

  __collapseRow(index) {
    this.__getTreatmentPlan(index).setAttribute('hidden', '');

    this.__getRow(index).removeAttribute('expanded');
  }

  __toggle(toggle) {
    if (toggle.expanded) {
      this.__expandRow(toggle.index);
    } else {
      this.__collapseRow(toggle.index);
    }
  }

  __copyPlan(index) {
    if (this.onCopy) {
      this.onCopy(JSON.parse(JSON.stringify(this.treatmentPlans[index])));
    }
  }
}

customElements.define('neb-treatment-plans-table', NebTreatmentPlansTable);
