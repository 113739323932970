import { css, html } from 'lit';
import '../../../controls/inputs/neb-checkbox';

import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import {
  CLINIC_OPTION,
  RCM_OPTION,
} from '../../../../../packages/neb-lit-components/src/components/forms/utils';
import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import Debouncer from '../../../../../packages/neb-utils/debouncer';
import { CSS_COLOR_HIGHLIGHT } from '../../../../styles';
import { CLAIM_ERROR_DICT } from '../../../../utils/claim-errors/claim-errors';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  field: { selector: '[id^=cell-field-]' },
  value: { selector: '[id^=cell-value-]' },
  location: { selector: '[id^=cell-location-]' },
  description: { selector: '[id^=cell-description-]' },
  code: { selector: '[id^=cell-code-]' },
  assignedToCheckbox: { selector: '[id^=assigned-to-checkbox-]' },
  completedCheckbox: { selector: '[id^=completed-checkbox-]' },
  fixClaimErrorIcon: { selector: '[id^=fix-claim-error-icon-]' },
};

const DEBOUNCE_DELAY = 300;
class NebTableClaimValidationErrors extends NebTable {
  static get properties() {
    return {
      __errors: Array,

      claimRowIndex: Number,
      hasRcmItdDooClaimErrorOverlay: Boolean,
      hasRcmManageEncounterClaimErrorOverlay: Boolean,
      hasRcmInsuranceClaimErrorOverlay: Boolean,
      hasRcmPatientInfoClaimErrorOverlayFF: Boolean,
      hasRcmPayerClaimErrorOverlayFF: Boolean,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__errors = [];

    this.claimRowIndex = 0;
    this.model = {};
    this.emptyMessage = 'There are no Validation Errors.';
    this.hasRcmItdDooClaimErrorOverlay = false;
    this.hasRcmManageEncounterClaimErrorOverlay = false;
    this.hasRcmInsuranceClaimErrorOverlay = false;
    this.hasRcmPatientInfoClaimErrorOverlayFF = false;
    this.hasRcmPayerClaimErrorOverlayFF = false;

    this.onChange = () => {};

    this.onOpenClaimErrorOverlay = () => {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .completed-check-box {
          padding-left: 30px;
        }

        .icon-fix-claim-error {
          height: 24px;
          width: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 30px;
        }

        .icon-fix-claim-error:hover {
          cursor: pointer;
          opacity: 0.65;
        }
      `,
    ];
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      checkCompleted: (e, idx) => {
        const { value } = e;
        const userId = store.getState().session.item.id;
        const completedBy = value ? userId : null;
        const completedAt = value ? parseDate() : null;

        const errors = this.model;

        errors[idx] = {
          ...errors[idx],
          completedAt,
          completedBy,
        };

        this.__errors = errors;

        this.__updatededDebouncer.debounce();
      },
      changeAssignedTo: (e, idx) => {
        const { value } = e;
        const errors = this.model;

        errors[idx] = {
          ...errors[idx],
          assignedTo: value ? CLINIC_OPTION.value : RCM_OPTION.value,
        };

        this.__errors = errors;

        this.__updatededDebouncer.debounce();
      },
      openClaimErrorOverlay: idx => {
        this.onOpenClaimErrorOverlay(this.claimRowIndex, idx);
      },
    };
  }

  __initServices() {
    this.__updatededDebouncer = new Debouncer(
      () => this.__updatedCompleted(this.claimRowIndex, this.__errors),
      DEBOUNCE_DELAY,
    );
  }

  __updatedCompleted(idx, errors) {
    this.onChange(idx, errors);
  }

  __shouldShowIconForItdDoo(errorCode) {
    return (
      this.hasRcmItdDooClaimErrorOverlay &&
      CLAIM_ERROR_DICT.ITD_DOO.errorCodes.includes(errorCode)
    );
  }

  __shouldShowIconForManageEncounter(errorCode) {
    return (
      this.hasRcmManageEncounterClaimErrorOverlay &&
      CLAIM_ERROR_DICT.MANAGE_ENCOUNTER.errorCodes.includes(errorCode)
    );
  }

  __shouldShowIconForInsurance(errorCode) {
    return (
      this.hasRcmInsuranceClaimErrorOverlay &&
      CLAIM_ERROR_DICT.INSURANCE.errorCodes.includes(errorCode)
    );
  }

  __shouldShowIconForPatientIcon(errorCode) {
    return (
      this.hasRcmPatientInfoClaimErrorOverlayFF &&
      CLAIM_ERROR_DICT.PATIENT_INFO.errorCodes.includes(errorCode)
    );
  }

  __shouldShowIconForPayer(errorCode) {
    return (
      this.hasRcmPayerClaimErrorOverlayFF &&
      CLAIM_ERROR_DICT.PAYER.errorCodes.includes(errorCode)
    );
  }

  __hasValidRcmClaimErrors(rowIndex) {
    const errorCode = this.model[rowIndex].code;

    const showIconForItdDoo = this.__shouldShowIconForItdDoo(errorCode);
    const showIconForManageEncounter =
      this.__shouldShowIconForManageEncounter(errorCode);
    const showIconForInsurance = this.__shouldShowIconForInsurance(errorCode);
    const showIconForPatientInfo =
      this.__shouldShowIconForPatientIcon(errorCode);
    const showIconForPayer = this.__shouldShowIconForPayer(errorCode);

    return (
      showIconForItdDoo ||
      showIconForManageEncounter ||
      showIconForInsurance ||
      showIconForPatientInfo ||
      showIconForPayer
    );
  }

  __renderCheckBox(rowIndex) {
    return html`
      <neb-checkbox
        id="completed-checkbox-${rowIndex}"
        name="completedAt"
        class="completed-check-box"
        .checked="${this.model[rowIndex].completedAt}"
        .onChange="${e => this.handlers.checkCompleted(e, rowIndex)}"
      >
      </neb-checkbox>
    `;
  }

  __renderAssignedTo(value, rowIndex) {
    return html`
      <neb-checkbox
        id="assigned-to-checkbox-${rowIndex}"
        name="assignedTo"
        label="${CLINIC_OPTION.label}"
        .checked="${value === CLINIC_OPTION.value}"
        .onChange="${e => this.handlers.changeAssignedTo(e, rowIndex)}"
      >
      </neb-checkbox>
    `;
  }

  __renderFixClaimErrorIcon(rowIndex) {
    const showIcon = this.__hasValidRcmClaimErrors(rowIndex);

    return showIcon
      ? html`
          <neb-icon
            id="fix-claim-error-icon-${rowIndex}"
            class="icon-fix-claim-error"
            icon="neb:fixClaimError"
            @click="${() => {
              this.handlers.openClaimErrorOverlay(rowIndex);
            }}"
          ></neb-icon>
        `
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex, name) {
    switch (columnConfig.key) {
      case 'assignedTo':
        return this.__renderAssignedTo(value, rowIndex);
      case 'completedAt':
        return this.__renderCheckBox(rowIndex);
      case 'fixClaimError':
        return this.__renderFixClaimErrorIcon(rowIndex);
      default:
        return super.renderDataCell(value, columnConfig, rowIndex, name);
    }
  }
}

customElements.define(
  'neb-table-claim-validation-errors',
  NebTableClaimValidationErrors,
);
