/* eslint-disable complexity */
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { getLineItems } from '../../../../packages/neb-api-client/src/ledger/line-items';
import { getPaymentDetail } from '../../../../packages/neb-api-client/src/payments-api-client';
import { getPracticeUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_MEDIUM,
} from '../../../../packages/neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import {
  createBillingNotes,
  upsertBillingNotes,
  deleteBillingNotes,
  getBillingNotes,
} from '../../../api-clients/billing-note';
import { getEob } from '../../../api-clients/eob';
import { NebFormBillingNote } from '../../forms/ledger/neb-form-billing-note';

export const BANNER_SUCCESS = 'Ledger Note saved successfully';
export const BANNER_ERROR = 'An error occurred when saving the Ledger Note';
export const BANNER_DELETE_SUCCESS = 'Ledger Note deleted successfully';
export const BANNER_DELETE_ERROR =
  'An error occurred when deleting the Ledger Note';

export const ELEMENTS = {
  header: { id: 'header' },
  billingNoteForm: { id: 'billing-note-form' },
};

class NebOverlayBillingNote extends Overlay {
  static get properties() {
    return {
      __savingError: Boolean,
      __noteModel: Object,
      __practiceUsers: Array,
      __parentData: Object,
    };
  }

  initState() {
    super.initState();

    this.__savingError = false;
    this.__noteModel = NebFormBillingNote.createModel();
    this.__practiceUsers = [];
    this.__parentData = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        this.__savingError = false;

        const body = {
          ...model,
          parentId: this.model.parentId,
          parentType: this.model.parentType,
          patientId: this.model.patientId,
        };

        try {
          let result;

          if (this.__noNotesExist()) {
            result = await createBillingNotes(body);
          } else result = await upsertBillingNotes(body);

          this.isDirty = false;
          store.dispatch(openSuccess(BANNER_SUCCESS));
          this.dismiss(result);
        } catch (e) {
          console.error(e);
          store.dispatch(openError(BANNER_ERROR));
          this.__savingError = true;
        }
      },
      delete: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Delete Ledger Note',
          message: 'Are you sure you want to delete this ledger note?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          const body = {
            parentId: this.model.parentId,
            parentType: this.model.parentType,
          };

          try {
            await deleteBillingNotes(body);

            this.isDirty = false;
            store.dispatch(openSuccess(BANNER_DELETE_SUCCESS));
            this.dismiss(true);
          } catch (e) {
            console.error(e);
            store.dispatch(openError(BANNER_DELETE_ERROR));
            this.__savingError = true;
          }
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_MEDIUM};
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .form {
          height: auto;
          flex: 1 0 0;
        }
      `,
    ];
  }

  __noNotesExist() {
    return !this.__noteModel.entries[0].id.length;
  }

  __getTitle() {
    let base = 'Ledger Note';

    if (this.__noNotesExist()) {
      base = 'Add Ledger Note';
    }

    switch (this.model.parentType) {
      case BILLING_NOTE_TYPES.CLAIM:
        return `${base} - Claim`;

      case BILLING_NOTE_TYPES.INVOICE:
        return `${base} - Invoice`;

      case BILLING_NOTE_TYPES.STATEMENT:
        return `${base} - Statement`;

      case BILLING_NOTE_TYPES.SUPERBILL:
        return `${base} - Superbill`;

      case BILLING_NOTE_TYPES.PAYMENT: {
        return this.model.parentData.paymentType === 'Discount'
          ? `${base} - Discount`
          : `${base} - ${this.model.parentData.paymentType || 'Payment'}`;
      }

      case BILLING_NOTE_TYPES.CHARGE:
        return `${base} - Charge`;

      default:
        return base;
    }
  }

  async __getBillingNoteDetails() {
    if (
      (this.model.parentData.paymentType === 'ERA' ||
        this.model.parentData.paymentType === 'EOB') &&
      (!this.model.parentData.eobEraId || !this.model.parentData.paymentId)
    ) {
      const paymentDetails = await getPaymentDetail(this.model.parentId);
      const eobInfo = await getEob(paymentDetails.eobPayments[0].eOBId);

      return {
        ...this.model.parentData,
        eobEraId: eobInfo.data[0].reportNumber,
        paymentId: paymentDetails.paymentNumber,
      };
    }

    if (
      this.model.parentType === BILLING_NOTE_TYPES.PAYMENT &&
      !this.model.parentData.paymentType &&
      !this.model.parentData.paymentId
    ) {
      const paymentDetails = await getPaymentDetail(this.model.parentId);

      return {
        ...this.model.parentData,
        paymentId: paymentDetails.paymentNumber,
      };
    }

    if (
      this.model.parentData.paymentType === 'Discount' &&
      !this.model.parentData.paymentId
    ) {
      const paymentDetails = await getPaymentDetail(this.model.parentId);

      return {
        ...this.model.parentData,
        paymentId: `D-${paymentDetails.paymentNumber}`,
      };
    }

    if (
      this.model.parentType === BILLING_NOTE_TYPES.CHARGE &&
      (!this.model.parentData.description ||
        !this.model.parentData.chargeNumber)
    ) {
      const lineItems = await getLineItems([this.model.parentId]);

      return {
        ...this.model.parentData,
        description: lineItems[0].description,
        chargeNumber: lineItems[0].chargeNumber,
      };
    }

    return this.model.parentData;
  }

  async connectedCallback() {
    const notes = await getBillingNotes({
      parentId: this.model.parentId,
      parentType: this.model.parentType,
    });

    this.__parentData = await this.__getBillingNoteDetails();

    if (notes.length) {
      this.__practiceUsers = await getPracticeUsers();
      this.__noteModel = { ...this.__noteModel, entries: notes };
    } else {
      this.__noteModel = { ...this.__noteModel };
    }

    super.connectedCallback();
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.__getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        .onDelete="${this.handlers.delete}"
        ?showDeleteButton="${!this.__noNotesExist()}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-billing-note
        id="${ELEMENTS.billingNoteForm.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__noteModel}"
        .practiceUsers="${this.__practiceUsers}"
        .parentType="${this.model.parentType}"
        .parentId="${this.model.parentId}"
        .parentData="${this.__parentData}"
        .savingError="${this.__savingError}"
        .onChangeDirty="${this.handlers.dirty}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
      >
      </neb-form-billing-note>
    `;
  }
}

customElements.define('neb-overlay-billing-note', NebOverlayBillingNote);
