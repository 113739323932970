import '../../../packages/neb-lit-components/src/components/neb-content-instruction';
import '../../../packages/neb-www-practice-charting/src/components/agenda/neb-charting-agenda-view-overlay';

import './neb-encounter-controller';

import { openPopup } from '@neb/popup';
import { getParams, matchRouteSwitch } from '@neb/router';
import { html, LitElement, css } from 'lit';
import moment from 'moment-timezone';

import { updateAppointment } from '../../../packages/neb-api-client/src/appointment-api-client';
import createEncounter from '../../../packages/neb-api-client/src/services/encounter/create-encounter';
import { APPOINTMENT_ACTIONS } from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { INACTIVE_MESSAGES } from '../../components/pages/encounters/neb-page-add-code-bundle-to-encounter';
import { navigate, openInfo, openSuccess, openWarning } from '../../store';
import { baseStyles } from '../../styles';
import {
  SAVE_ENCOUNTER_SUCCESS,
  SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS,
} from '../../utils/user-message';

import { forceReloadEncounter } from './neb-charting-util';

export const ELEMENTS = {
  appointmentList: { id: 'appointment-list' },
  encounterController: { id: 'encounter-controller' },
  instruction: { id: 'instruction' },
};

const ACCOUNT_NOT_MATCHED_MESSAGE = {
  title: 'Account Not Matched',
  message: html`
    This appointment was booked online and has not been matched to a patient
    account in your practice.
    <p>
      To match the account, navigate to scheduling and click on the appointment
      from there. This will open the "Match Accounts" dialog, which will allow
      you to match the online booking account to an account in your practice.
    </p>
  `,
};

export const INACTIVE_DIAGNOSES_MESSAGE =
  'One or more diagnosis codes is not active for the date of service';

export const DIAGNOSES_DESCRIPTION_CHANGED_MESSAGE =
  'One or more of the diagnosis descriptions has been updated to match the date of service';

const displaySuccessBanners = createEncounterRes => {
  const {
    inactiveDiagnosis,
    withAutoSalt,
    codeExpiredCount,
    codeDescriptionChangeCount,
  } = createEncounterRes;

  openSuccess(
    withAutoSalt
      ? SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS
      : SAVE_ENCOUNTER_SUCCESS,
  );

  if (inactiveDiagnosis) {
    openInfo(
      inactiveDiagnosis > 1
        ? INACTIVE_MESSAGES.plural(inactiveDiagnosis, 'Diagnosis')
        : INACTIVE_MESSAGES.single('Diagnosis'),
    );
  }

  if (codeExpiredCount) {
    openWarning(INACTIVE_DIAGNOSES_MESSAGE);
  }

  if (codeDescriptionChangeCount) {
    openInfo(DIAGNOSES_DESCRIPTION_CHANGED_MESSAGE);
  }
};

class NebPageCharting extends LitElement {
  static get properties() {
    return {
      appointmentTypes: { type: Array },
      layout: { type: String },
      route: { type: String },
      user: { type: Object },
      __filteredPatient: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .encounter-list {
          width: 500px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.appointmentTypes = [];
    this.layout = '';
    this.route = '';
    this.user = null;

    this.__selectedEncounterServiceDate = null;
    this.__filteredPatient = null;
    this.__selectedEncounterIsSigned = false;

    this.__navItems = [
      {
        path: '/:id/',
        exact: false,
        resolver: (tail, { params: { id } }) => html`
          <neb-encounter-controller
            id="${ELEMENTS.encounterController.id}"
            .route="${tail}"
            .encounterId="${id}"
            .layout="${this.layout}"
            .shouldRenderSignedViewFirst="${this.__selectedEncounterIsSigned}"
            .user="${this.user}"
            .onUserMenuOptions="${this.__handlers.handleUserMenuOption}"
            .onEncounterLoaded="${this.__handlers.setSelectedServiceDate}"
            .onEncounterSigned="${this.__handlers.encounterSigned}"
          ></neb-encounter-controller>
        `,
      },
      {
        path: '/',
        exact: true,
        resolver: () => html`
          <neb-charting-agenda-view-overlay
            id="${ELEMENTS.appointmentList.id}"
            class="encounter-list"
            .appointmentTypes="${this.appointmentTypes}"
            .layout="${this.layout}"
            .onSelect="${this.__handlers.selectEncounter}"
            .filteredPatient="${this.__filteredPatient}"
            .onUpdateFilteredPatient="${this.__handlers.updateFilteredPatient}"
          ></neb-charting-agenda-view-overlay>

          <neb-content-instruction
            id="${ELEMENTS.instruction.id}"
            label="Select a patient to open an encounter"
            icon="neb:patients"
          ></neb-content-instruction>
        `,
      },
    ];

    this.onUserMenuOptions = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      encounterSigned: () => this.openEncounterList(),
      handleUserMenuOption: (...args) => this.onUserMenuOptions(...args),
      selectEncounter: ({ value }) => {
        if (!value.patient) {
          return openPopup(
            POPUP_RENDER_KEYS.MESSAGE,
            ACCOUNT_NOT_MATCHED_MESSAGE,
          );
        }

        if (value.encounter) {
          return this.__navigateToEncounter(
            value.encounter.id,
            value.encounter.signed,
          );
        }

        return this.__createEncounter(value);
      },
      setSelectedServiceDate: ({ serviceDate }) => {
        this.__selectedEncounterServiceDate = serviceDate;
      },
      updateFilteredPatient: patient => {
        this.__filteredPatient = patient;
      },
    };
  }

  async __createEncounter(appointment) {
    const currentUserId = store.getState().session.item.id;
    const providers = store.getState().providers.item;

    let selectedProvider = providers.find(
      provider => provider.id === appointment.providerId,
    );

    if (!selectedProvider) {
      selectedProvider = await openPopup(
        POPUP_RENDER_KEYS.ENCOUNTER_PROVIDER_SELECTION,
        {
          providers,
          providerId: currentUserId,
        },
      );

      if (!selectedProvider) {
        return null;
      }

      try {
        await updateAppointment(
          appointment.id,
          APPOINTMENT_ACTIONS.RESCHEDULE.action,
          {
            ...appointment,
            providerId: selectedProvider.id,
            custom: true,
            duration: moment(appointment.end).diff(appointment.start),
          },
        );
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    const { res } = await createEncounter(
      appointment.id,
      currentUserId,
      `${selectedProvider.name.last}, ${selectedProvider.name.first}`,
    );

    displaySuccessBanners(res);

    const {
      encounter: { item: encounterId },
    } = store.getState();

    return this.__navigateToEncounter(encounterId);
  }

  __navigateToEncounter(id, isEncounterSigned = false) {
    this.__selectedEncounterIsSigned = isEncounterSigned;

    return navigate(`#/charting/${id}`);
  }

  async openEncounterList() {
    const { id } = getParams('/:subApp/:id');

    const result = await openOverlay(OVERLAY_KEYS.ENCOUNTER_LIST, {
      encounterId: id,
      selectedDate: parseDate(this.__selectedEncounterServiceDate),
      appointmentTypes: this.appointmentTypes,
      filteredPatient: this.__filteredPatient,
    });

    if (result) {
      const { encounter } = result;

      if (encounter && encounter.id === id) {
        return forceReloadEncounter();
      }

      return this.__handlers.selectEncounter({ value: result });
    }

    return forceReloadEncounter();
  }

  render() {
    return html`
      <div class="container">
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

customElements.define('neb-page-charting', NebPageCharting);
