import './neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  buttonActionsContainer: { selector: 'neb-button-action-container' },
  buttonActions: { selector: 'neb-button-action' },
};

class NebButtonBar extends LitElement {
  static get properties() {
    return {
      config: Array,
      stretch: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.config = [];
    this.stretch = false;
  }

  __initHandlers() {
    this.__handlers = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-gap: 18px;
          grid-auto-columns: max-content;
          grid-auto-flow: column;
          width: 100%;
          height: 100%;
        }
        .stretch {
          grid-gap: var(--stretch-grid-gap, 10px);
        }
      `,
    ];
  }

  __renderButtons() {
    return this.config.map(
      button => html`
        <neb-button-action
          id="${ELEMENTS.buttonActions.selector}"
          .name="${button.name}"
          .leadingIcon="${button.icon}"
          .label="${button.label}"
          .onClick="${button.onClick}"
          ?disabled="${button.disabled}"
          ?warn="${button.warn}"
        ></neb-button-action>
      `,
    );
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.buttonActionsContainer.selector}"
        class="container${this.stretch ? ' stretch' : ''}"
      >
        ${this.__renderButtons()}
      </div>
    `;
  }
}

customElements.define('neb-button-bar', NebButtonBar);
