import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';

export default async ({ claimCellData, state, handlers, formService }) => {
  const { name } = claimCellData;

  const options = [
    { label: state.paidAmount, name, value: false },
    { label: '$0.00', name, value: true },
  ];

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SINGLE_SELECT, {
    title: claimCellData.title,
    options,
    items: [{ name, value: state.fl29AmountPaidOverride }],
  });

  if (result) {
    formService.apply('claimEditedField.paidAmount', true);

    handlers.change({ name, value: result.fl29AmountPaidOverride });
  }
};
