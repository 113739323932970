import '../../../../packages/neb-www-practice-charting/src/components/documents/neb-charting-documents-summary';

import { html } from 'lit';

import {
  NebUnsignedEncounterSummaryPanel,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-summary-panel';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  view: { id: 'view' },
};

class NebUnsignedEncounterDocumentsSummary extends NebUnsignedEncounterSummaryPanel {
  static get properties() {
    return {
      selectedDocumentIndex: { type: String },
    };
  }

  initState() {
    super.initState();
    this.selectedDocumentIndex = -1;

    this.onRequestDocuments = () => {};

    this.onSelectDocumentIndex = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      requestDocuments: () => this.onRequestDocuments(),
      selectDocumentIndex: index => {
        this.onSelectDocumentIndex(index);
      },
    };
  }

  createModel() {
    return {
      count: 0,
      data: [],
    };
  }

  getTitle() {
    return `All Documents (${this.model.count})`;
  }

  openDetail(index) {
    if (!index) {
      index = -1;
    }
    this.onOpenDetail(index);
  }

  renderContent() {
    return html`
      <neb-charting-documents-summary
        id="${ELEMENTS.view.id}"
        .documents="${this.model.data}"
        .noDocuments="${this.model.count === 0}"
        .totalDocuments="${this.model.count}"
        .onRequestDocuments="${this.handlers.requestDocuments}"
        .onSelectDocumentIndex="${this.handlers.selectDocumentIndex}"
        .selectedDocumentIndex="${this.selectedDocumentIndex}"
        .isOnEncounterDocuments="${false}"
      ></neb-charting-documents-summary>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-documents-summary',
  NebUnsignedEncounterDocumentsSummary,
);
