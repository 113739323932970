import moment from 'moment-timezone';

import {
  formatPhonesEmails,
  formatAddressFormObject,
} from '../../../neb-utils/formatters';

export const formatPerson = person => {
  const { emails, phones, address = {} } = person;
  const dateOfBirth = moment(person.dateOfBirth);

  return {
    ...person,
    ...formatPhonesEmails(phones, emails),
    address: formatAddressFormObject(address),
    dateOfBirth: dateOfBirth.isValid() ? dateOfBirth.format('MM/DD/YYYY') : '',
  };
};
export const formatPersonForPatientCard = person => ({
  ...person,
  name: {
    first: person.firstName || '',
    last: person.lastName || person.name,
    middle: person.middleName,
    suffix: person.suffix,
  },
  phoneNumbers: person.phones.filter(
    phone => phone.number !== '' && phone.type !== '',
  ),
});
