import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { dismissAbandonedResponse } from '../../../../packages/neb-api-client/src/electronic-claims/abandoned-responses';
import { fetchPdf } from '../../../../packages/neb-api-client/src/ledger-superbill-api-client';
import {
  PAYER_PLAN_VERSION_CLAIMS,
  getPayerPlans,
} from '../../../../packages/neb-api-client/src/payer-plan-api-client';
import { postERAPayment } from '../../../../packages/neb-api-client/src/payments-api-client';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import '../../forms/abandoned-response/neb-form-abandoned-era';
import { printPdf } from '../../../../packages/neb-utils/neb-pdf-print-util';
import {
  DISMISS_CLAIM_RESPONSE_SUCCESS,
  DISMISSING_CLAIM_RESPONSE_ERROR,
  POST_PAYMENT_SUCCESS,
  POST_PAYMENT_ERROR,
} from '../../../utils/user-message';

export const ABANDONED_ERA_RESPONSE = {
  POSTED: 'POSTED',
  DISMISSED: 'DISMISSED',
};

const POST_PAYMENT = {
  title: 'Post Payment',
  message: 'Are you sure you want to post a payment?',
  confirmText: 'Yes',
  cancelText: 'No',
};

const DISMISS_RESPONSE = {
  title: 'Dismiss Response',
  message: 'Are you sure you want to dismiss the response?',
  confirmText: 'Yes',
  cancelText: 'No',
};

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};
const TITLE = 'View Unlinked Claim Response';

class NebOverlayAbandonedERA extends Overlay {
  static get properties() {
    return {
      __payerPlans: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 750px;
          flex-flow: column nowrap;
          height: 100%;
          overflow-y: auto;
        }

        .container {
          padding-top: ${CSS_SPACING};
          display: flex;
          flex-direction: column;
        }

        .header {
          padding: 0 ${CSS_SPACING} 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__payerPlans = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      postPayment: form => this.__postERAPayment(form),
      dismissResponse: async () => {
        try {
          if (await openPopup(POPUP_RENDER_KEYS.CONFIRM, DISMISS_RESPONSE)) {
            await dismissAbandonedResponse(this.model.id);
            store.dispatch(openSuccess(DISMISS_CLAIM_RESPONSE_SUCCESS));

            this.dismiss(ABANDONED_ERA_RESPONSE.DISMISSED);
          }
        } catch (err) {
          store.dispatch(openError(DISMISSING_CLAIM_RESPONSE_ERROR));
        }
      },
      printERA: () => printPdf(fetchPdf(this.model.s3key)),
    };
  }

  async __getPayerPlans() {
    const { payerPlan } = await getPayerPlans(
      {
        hideInactive: true,
      },
      PAYER_PLAN_VERSION_CLAIMS,
    );

    this.__payerPlans = payerPlan.map(item => ({
      data: item,
      label: `(${item.alias}) ${item.payerName}`,
    }));
  }

  async __postERAPayment({ id, payer, ...data }) {
    try {
      if (await openPopup(POPUP_RENDER_KEYS.CONFIRM, POST_PAYMENT)) {
        const postedPayment = await postERAPayment({
          ...data,
          eClaimEraId: id,
          payerPlanId: payer.id,
        });

        store.dispatch(openSuccess(POST_PAYMENT_SUCCESS));

        if (!this.model.showBackButton) return;

        await openOverlay(OVERLAY_KEYS.ADD_PAYER_PAYMENT, {
          doNotDismissAll: true,
          postedPayment,
        });
      }
    } catch (err) {
      store.dispatch(openError(POST_PAYMENT_ERROR));
    } finally {
      this.dismiss(ABANDONED_ERA_RESPONSE.POSTED);
    }
  }

  firstUpdated() {
    return this.__getPayerPlans();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.model = { showBackButton: true, ...this.model, payer: '' };
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <div class="container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="${TITLE}"
          .onCancel="${this.handlers.dismiss}"
          .onBack="${this.handlers.dismiss}"
          .showBackButton="${this.model.showBackButton}"
          showCancelButton
        ></neb-popup-header>

        <neb-form-abandoned-era
          id="${ELEMENTS.form.id}"
          .model="${this.model}"
          .payerPlans="${this.__payerPlans}"
          .onSave="${this.handlers.postPayment}"
          .onDismissResponse="${this.handlers.dismissResponse}"
          .onClose="${this.handlers.dismiss}"
          .onPrint="${this.handlers.printERA}"
          .loading="${!this.__payerPlans.length}"
        ></neb-form-abandoned-era>
      </div>
    `;
  }
}

customElements.define('neb-overlay-abandoned-era', NebOverlayAbandonedERA);
