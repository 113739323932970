import '../neb-search-bar';
import '../neb-dropdown';
import '../inputs/neb-select';

import { html, css } from 'lit';

import { NebDateRange } from '../../../../../src/components/controls/field-groups/neb-range-date';
import {
  NebFilters,
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../src/components/filters/neb-filters';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import * as selectors from '../../../../neb-utils/selectors';
import { POPOVER_POSITION } from '../neb-popover';

const ENCOUNTER_STATUSES = ['All', 'Open', 'Signed'];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  appointmentTypeDropdown: {
    id: 'appointment-type-dropdown',
  },
  caseDropdown: {
    id: 'case-dropdown',
  },
  fromPicker: {
    id: 'from-picker',
  },
  toPicker: {
    id: 'to-picker',
  },
  statusDropdown: {
    id: 'status-dropdown',
  },
  providerDropdown: {
    id: 'provider-dropdown',
  },
};

class NebEncounterFilter extends NebFilters {
  static get properties() {
    return {
      appointmentTypeItems: Array,
      caseItems: Array,
      providerItems: Array,
    };
  }

  static get styles() {
    return css`
      :host {
        width: 100%;
      }

      .container {
        display: flex;
        flex-direction: column;
      }

      .container-buttons {
        margin-top: 10px;
        display: flex;
        gap: 10px;
      }

      .content {
        margin-top: ${CSS_SPACING};
      }

      .fields {
        display: flex;
        gap: 10px;
        margin-bottom: ${CSS_SPACING};
      }

      .date-fields {
        display: flex;
        gap: 10px;
        margin: 0;
      }

      .field {
        flex: 1 0 0;
      }

      .title {
        font-weight: bold;
        margin: 10px 0px;
      }
    `;
  }

  static createModel() {
    return {
      serviceDate: NebDateRange.createModel(),
      status: '',
      providers: [],
      appointmentTypes: [],
      cases: [],
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.appointmentTypeItems = [];
    this.caseItems = [];
    this.providerItems = [];

    this.onApply = () => {};
  }

  __initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      changeDate: e => this.formService.apply(e.name, e.value ? e.value : null),
    };
  }

  createSelectors() {
    return {
      children: {
        providers: selectors.multiSelect([]),
        appointmentTypes: selectors.multiSelect([]),
        cases: selectors.multiSelect([]),
      },
    };
  }

  renderContent() {
    return html`
      <div class="container">
        <div class="date-fields">
          <neb-date-picker
            id="${ELEMENTS.fromPicker.id}"
            class="field"
            name="serviceDate.from"
            placeholder="From"
            .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .selectedDate="${this.state.serviceDate.from}"
            .onChange="${this.__handlers.changeDate}"
            momentFlag
          ></neb-date-picker>
          <neb-date-picker
            id="${ELEMENTS.toPicker.id}"
            class="field"
            name="serviceDate.to"
            placeholder="To"
            .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .selectedDate="${this.state.serviceDate.to}"
            .onChange="${this.__handlers.changeDate}"
            momentFlag
          ></neb-date-picker>
        </div>

        <div class="fields">
          <neb-select
            id="${ELEMENTS.statusDropdown.id}"
            label="Status"
            class="field"
            name="status"
            .items="${ENCOUNTER_STATUSES}"
            .onChange="${this.__handlers.change}"
            .value="${this.state.status}"
          ></neb-select>
          <neb-select
            id="${ELEMENTS.providerDropdown.id}"
            label="Provider"
            class="field"
            name="providers"
            .items="${this.providerItems}"
            .value="${this.state.providers}"
            .onChange="${this.__handlers.change}"
            multiSelect
          ></neb-select>
        </div>

        <div class="fields">
          <neb-select
            id="${ELEMENTS.appointmentTypeDropdown.id}"
            label="Appointment Type"
            class="field"
            name="appointmentTypes"
            .items="${this.appointmentTypeItems}"
            .value="${this.state.appointmentTypes}"
            .onChange="${this.__handlers.change}"
            multiSelect
          ></neb-select>
          <neb-select
            id="${ELEMENTS.caseDropdown.id}"
            label="Case"
            class="field"
            name="cases"
            .items="${this.caseItems}"
            .value="${this.state.cases}"
            .onChange="${this.__handlers.change}"
            multiSelect
          ></neb-select>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-encounter-filter', NebEncounterFilter);
