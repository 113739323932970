export default class {
  constructor(callback, items, lookupKey) {
    this.__callback = callback;
    this.__items = items || [];
    this.__lookupKey = lookupKey || 'id';
    this.__expandedState = {};
  }

  __change() {
    const flags = this.__items.map(
      item => !!this.__expandedState[item[this.__lookupKey]],
    );

    this.__callback(flags);
  }

  __pruneExpandFlags() {
    this.__expandedState = Object.entries(this.__expandedState)
      .filter(([k, _v]) =>
        this.__items.find(item => item[this.lookupKey] === k),
      )
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {});
  }

  setItems(items) {
    if (items !== this.__items) {
      this.__pruneExpandFlags();

      this.__items = items || [];

      this.__change();
    }
  }

  updateItem(item) {
    const key = item[this.__lookupKey];
    const flag = this.__expandedState[key];
    this.__expandedState[key] = flag == null || !flag;

    this.__change();
  }
}
