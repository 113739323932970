import '../../../packages/neb-lit-components/src/components/neb-text';

import { LitElement, html, css } from 'lit';

import {
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../styles';

export const ELEMENTS = {
  container: { id: 'container' },
  defaultLevel: { id: 'defaultLevel' },
  planName: { id: 'planName' },
  copay: { id: 'copay' },
  coinsurance: { id: 'coinsurance' },
  deductible: { id: 'deductible' },
  deductibleRemaining: { id: 'deductibleRemaining' },
  maxOop: { id: 'maxOop' },
  maxOopRemaining: { id: 'maxOopRemaining' },
};

class NebInsuranceCard extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.model = {
      id: '',
      planName: '',
      defaultLevel: '',
      copay: '',
      coinsurance: '',
      deductible: '',
      deductibleRemaining: '',
      maxOop: '',
      maxOopRemaining: '',
    };

    this.onOpenInsuranceOverlay = () => {};
  }

  initHandlers() {
    this.__handlers = {
      openInsuranceOverlay: () => {
        this.onOpenInsuranceOverlay(this.model.id);
      },
    };
  }

  static get styles() {
    return [
      css`
        .container {
          padding: ${CSS_SPACING};
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .fields {
          padding-top: 10px;
          display: flex;
          justify-content: space-between;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          white-space: nowrap;
        }

        .plain-text-value {
          margin-top: 10px;
        }

        .default-level {
          margin-bottom: 5px;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-text id="${ELEMENTS.defaultLevel.id}" class="default-level" bold
        >${this.model.defaultLevel}</neb-text
      >
      <div class="container">
        <neb-text
          id="${ELEMENTS.planName.id}"
          class="label"
          link
          bold
          .onClick="${this.__handlers.openInsuranceOverlay}"
        >
          ${this.model.planName}
        </neb-text>
        <div class="fields">
          <div>
            <div class="label">Copay</div>
            <div id="${ELEMENTS.copay.id}" class="plain-text-value">
              ${this.model.copay || '-'}
            </div>
          </div>
          <div>
            <div class="label">Co-ins.</div>
            <div id="${ELEMENTS.coinsurance.id}" class="plain-text-value">
              ${this.model.coinsurance || '-'}
            </div>
          </div>
          <div>
            <div class="label">Deduc.</div>
            <div id="${ELEMENTS.deductible.id}" class="plain-text-value">
              ${this.model.deductible || '-'}
            </div>
          </div>
          <div>
            <div class="label">Deduc. Rem.</div>
            <div
              id="${ELEMENTS.deductibleRemaining.id}"
              class="plain-text-value"
            >
              ${this.model.deductibleRemaining || '-'}
            </div>
          </div>
          <div>
            <div class="label">Max OOP</div>
            <div id="${ELEMENTS.maxOop.id}" class="plain-text-value">
              ${this.model.maxOop || '-'}
            </div>
          </div>
          <div>
            <div class="label">Max OOP Rem.</div>
            <div id="${ELEMENTS.maxOopRemaining.id}" class="plain-text-value">
              ${this.model.maxOopRemaining || '-'}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-insurance-card', NebInsuranceCard);
