import { getBillingInfo } from '../billing-information-api-client';
import { formatGuarantorName } from '../formatters/guarantor';
import * as patientApiClient from '../patient-api-client';

const getPatient = (patients, patientId) =>
  patients.find(({ id }) => id === patientId);

const generateLineItems = (statements, providerItem) => {
  const updatedStatements = statements.map(statement => {
    const { lineItems, groupedLineItems } = statement;

    let guarantorName;
    let providerNameDetails;
    let newLineItems = [];
    let newGroupedLineItems = {};
    let lineItemProviderMap = {};

    if (statement.guarantor) {
      guarantorName = formatGuarantorName(statement.guarantor);
    }

    lineItems.forEach(lineItem => {
      const provider = providerItem.find(p => p.id === lineItem.providerId);
      providerNameDetails = provider ? provider.name : null;

      lineItemProviderMap = {
        ...lineItemProviderMap,
        [lineItem.id]: providerNameDetails,
      };

      newLineItems = [
        ...newLineItems,
        {
          ...lineItem,
          providerNameDetails,
        },
      ];
    });

    const patientIds = [
      statement.patientId,
      ...(statement.statementRelatedPatients
        ? statement.statementRelatedPatients.map(p => p.relatedPatientId)
        : []),
    ];

    patientIds.forEach(patientId => {
      if (groupedLineItems && groupedLineItems[patientId]) {
        const updatedLineItems = groupedLineItems[patientId].lineItems.map(
          lineItem => ({
            ...lineItem,
            providerNameDetails: lineItemProviderMap[lineItem.id],
          }),
        );

        newGroupedLineItems = {
          ...newGroupedLineItems,
          [patientId]: {
            ...groupedLineItems[patientId],
            lineItems: updatedLineItems,
          },
        };
      }
    });

    return {
      ...statement,
      guarantorName,
      lineItems: newLineItems,
      ...(groupedLineItems ? { groupedLineItems: newGroupedLineItems } : {}),
    };
  });

  return updatedStatements;
};

const getStatementPatientIds = statements => {
  const patientIds = [];

  let relatedPatientIds = [];
  statements.forEach(statement => {
    const { groupStatements } = statement;

    patientIds.push(statement.patientId);

    if (groupStatements) {
      relatedPatientIds = [
        ...relatedPatientIds,
        ...statement.statementRelatedPatients.map(s => s.relatedPatientId),
      ];
    }
  });

  const statementPatientIds = [
    ...new Set([...patientIds, ...relatedPatientIds]),
  ];

  return statementPatientIds;
};

function findStatementPrimaryPatient(statements, patientInContextId) {
  const statementPatientIds = [...new Set(statements.map(s => s.patientId))];

  if (statementPatientIds.length === 1) return statementPatientIds[0];

  const sample = statements.find(s => s.groupStatements);

  if (sample) return sample.patientId;

  return patientInContextId;
}

export async function buildStatementBody(
  patientId,
  statements,
  preview,
  comment,
  {
    practiceInformation: { item: practiceInformation },
    providers,
    session: {
      item: { tenantId },
    },
  },
) {
  const formattedStatements = generateLineItems(statements, providers.item);

  const statementPatientIds = getStatementPatientIds(formattedStatements);
  const statementPrimaryPatientId = findStatementPrimaryPatient(
    statements,
    patientId,
  );
  const patients = await patientApiClient.fetchSome(
    statementPatientIds,
    {},
    true,
  );

  return {
    tenantId,
    statements: formattedStatements,
    patient: getPatient(patients, statementPrimaryPatientId),
    preview,
    practiceInformation,
    billingInformation: await getBillingInfo(),
    patients: statementPatientIds.map(id => getPatient(patients, id)),
    comment,
  };
}

export const buildSuperbillBody = async ({
  practiceInformation: { item: practiceInformation },
  session: {
    item: { tenantId },
  },
}) => ({
  tenantId,
  practiceInformation: { ...practiceInformation },
  billingInformation: await getBillingInfo(),
});
