import '../../../../../src/components/misc/neb-scheduling-calendar';
import '../../../../../src/components/misc/neb-scheduling-calendar-mobile';
import '../../../../../src/components/pages/scheduling/neb-page-patient-flow';
import '../../../../../src/components/pages/scheduling/neb-scheduling-rooms';
import '../../../../neb-lit-components/src/components/neb-nav-list';
import '../../../../neb-lit-components/src/components/controls/neb-tab-group';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { saveLastLocation } from '../../../../../src/api-clients/recent-record';
import { DAY } from '../../../../../src/utils/user-message';
import { getTenantId } from '../../../../neb-api-client/src/utils/api-client-utils';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import {
  formatSchedulingView,
  normalizeViewName,
  SCHEDULER_VIEW_INTERVAL,
} from '../../../../neb-utils/calendar-resources-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';

export const ELEMENTS = {
  tabs: { id: 'tabs' },
  navList: { id: 'nav-list' },
  calendarDesktop: { id: 'calendar-desktop' },
  calendarMobile: { id: 'calendar-mobile' },
  patientFlow: { id: 'patient-flow' },
  rooms: { id: 'rooms' },
};

const TABS = {
  CALENDAR: 'calendar',
  PATIENT_FLOW: 'patient-flow',
  ROOMS: 'rooms',
};

class NebScheduling extends LitElement {
  static get properties() {
    return {
      __tenantId: String,
      __hasRecentRecordsFeature: Boolean,
      route: String,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return css`
      :host([layout='small']) {
        background-color: ${CSS_COLOR_WHITE};
      }

      .container {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        margin: ${CSS_SPACING};
      }

      :host([layout='small']) .container {
        margin: 0;
      }

      .scheduling {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .rooms,
      .patient-flow {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-bottom: 40px;
      }

      :host([layout='small']) .rooms {
        margin-bottom: 20px;
      }

      :host([layout='large']) .rooms,
      .patient-flow {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__tenantId = '';
    this.__hasRecentRecordsFeature = false;
    this.layout = '';
    this.route = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        navigate(`#/scheduling/${tab}`);
      },
      selectNavItem: index => {
        const item = this.__genNavItems()[index];

        navigate(`#/scheduling/${item.name}`);
      },
    };
  }

  async connectedCallback() {
    this.__hasRecentRecordsFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.RECENT_RECORDS,
    );

    this.__tenantId = getTenantId();

    super.connectedCallback();
  }

  __genNavItems() {
    return [
      {
        id: TABS.CALENDAR,
        exact: false,
        label: 'Calendar',
        name: 'calendar',
        path: '/calendar/',
        resolver: () =>
          this.layout === 'small'
            ? html`
                <neb-scheduling-calendar-mobile
                  id="${ELEMENTS.calendarMobile.id}"
                  class="scheduling"
                  .tenantId="${this.__tenantId}"
                ></neb-scheduling-calendar-mobile>
              `
            : html`
                <neb-scheduling-calendar
                  id="${ELEMENTS.calendarDesktop.id}"
                  class="scheduling"
                  .route="${this.route}"
                  .view="${this.__preferredView}"
                  .tenantId="${this.__tenantId}"
                  .layout="${this.layout}"
                ></neb-scheduling-calendar>
              `,
      },
      {
        id: TABS.PATIENT_FLOW,
        exact: false,
        label: 'Patient Flow',
        name: 'patient-flow',
        path: '/patient-flow/',
        resolver: () => html`
          <neb-page-patient-flow
            id="${ELEMENTS.patientFlow.id}"
            class="patient-flow"
            .layout="${this.layout}"
            .tenantId="${this.__tenantId}"
          ></neb-page-patient-flow>
        `,
      },
      {
        id: TABS.ROOMS,
        exact: false,
        label: 'Rooms',
        name: 'rooms',
        path: '/rooms/',
        resolver: () => html`
          <neb-scheduling-rooms
            id="${ELEMENTS.rooms.id}"
            class="rooms"
            .layout="${this.layout}"
            .tenantId="${this.__tenantId}"
          ></neb-scheduling-rooms>
        `,
      },
    ];
  }

  __navigateToCalendar(view = '') {
    redirect(`#/scheduling/${TABS.CALENDAR}/${view || this.__getView()}`);
  }

  __handleRouteChange() {
    const segments = this.route.split('/');
    const [, tab, view] = segments;

    if (this.__hasRecentRecordsFeature) {
      this.__postLastVisitedLocation({
        patientId: null,
        route: tab || TABS.CALENDAR,
        additionalInformation: null,
      });
    }

    switch (tab) {
      case TABS.PATIENT_FLOW:
        redirect(`#/scheduling/${tab}`);
        break;
      case TABS.CALENDAR:
        this.__navigateToCalendar(view);
        break;

      default:
        if (!tab) {
          this.__navigateToCalendar();
        }
    }
  }

  async __postLastVisitedLocation({ patientId, route, additionalInformation }) {
    const locationToEnum = `SCHEDULING_${route
      .toUpperCase()
      .replaceAll('/', '_')}`;
    await saveLastLocation({
      patientId,
      lastLocation: locationToEnum,
      additionalInformation,
    });
  }

  __handleMobileRouteChange() {
    const segments = this.route.split('/');
    const [, tab] = segments;

    if (
      tab === TABS.PATIENT_FLOW ||
      tab === TABS.CALENDAR ||
      tab === TABS.ROOMS
    ) {
      redirect(`#/scheduling/${tab}`);

      if (this.__hasRecentRecordsFeature) {
        this.__postLastVisitedLocation({
          patientId: null,
          route: tab,
          additionalInformation: null,
        });
      }
    } else {
      redirect('#/scheduling');
    }
  }

  get __preferredView() {
    const preferredView = localStorage.getItem(SCHEDULER_VIEW_INTERVAL) || DAY;
    return normalizeViewName(preferredView);
  }

  __getView() {
    return formatSchedulingView(this.__preferredView);
  }

  __getSelectedTab() {
    const segments = this.route.split('/');

    return segments[1];
  }

  __getSelectedIndex() {
    return this.__genNavItems().findIndex(
      item => item.name === this.__getSelectedTab(),
    );
  }

  firstUpdated() {
    if (
      this.layout !== 'small' &&
      !this.route.includes('rooms') &&
      !this.route.includes('patient-flow')
    ) {
      redirect(`#/scheduling/${TABS.CALENDAR}/${this.__getView()}`);
    }
  }

  update(changedProps) {
    if (changedProps.has('route')) {
      if (this.layout !== 'small') {
        this.__handleRouteChange();
      } else {
        this.__handleMobileRouteChange();
      }
    }

    super.update(changedProps);
  }

  __renderMobile() {
    const selectedIndex = this.__getSelectedIndex();
    const items = this.__genNavItems()
      .filter(item => item.label)
      .map(item => item.label);

    return selectedIndex === -1
      ? html`
          <neb-nav-list
            id="${ELEMENTS.navList.id}"
            class="list"
            .selectedIndex="${selectedIndex}"
            .items="${items}"
            .onSelect="${this.__handlers.selectNavItem}"
          ></neb-nav-list>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.layout === 'small'
          ? this.__renderMobile()
          : html`
              <neb-tab-group
                id="${ELEMENTS.tabs.id}"
                class="tabs"
                .selectedId="${this.__getSelectedTab()}"
                .items="${this.__genNavItems()}"
                .onSelect="${this.__handlers.selectTab}"
                role="section"
              ></neb-tab-group>
            `}
        ${matchRouteSwitch(this.__genNavItems(), this.route)}
      </div>
    `;
  }
}

customElements.define('neb-scheduling', NebScheduling);
