/* istanbul ignore file */

const { NEB_ENV: { PENDO_API_KEY, PENDO_ENABLED } = {} } = window;

const pendoEnabled =
  PENDO_ENABLED === 'true' &&
  PENDO_API_KEY &&
  // eslint-disable-next-line no-template-curly-in-string
  PENDO_API_KEY !== '${PENDO_API_KEY}';

const loadPendo = () => {
  if (!pendoEnabled) {
    return;
  }

  // eslint-disable-next-line func-names
  (function (p, e, n, d, o) {
    p[d] = p[d] || {};
    o = p[d];
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];

    // eslint-disable-next-line curly
    for (let w = 0; w < v.length; ++w) {
      // eslint-disable-next-line func-names
      (function (m) {
        o[m] =
          o[m] ||
          // eslint-disable-next-line func-names
          function (...args) {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(args, 0)),
            );
          };
      })(v[w]);
    }
    const y = e.createElement(n);
    y.async = !0;
    y.src = `https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js`;
    const z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};

const initializePendo = ({
  email,
  firstName,
  lastName,
  practiceName,
  role,
  salesforceId,
  tenantId,
  tier,
  addOns,
}) => {
  if (!pendoEnabled) {
    return;
  }

  const { pendo } = window;

  // Call this whenever information about your visitors becomes available
  // Please use Strings, Numbers, or Bools for value types.
  pendo.initialize({
    visitor: {
      id: email, // Required if user is logged in
      email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: `${firstName} ${lastName}`, // Recommended if using Pendo Feedback
      role, // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: salesforceId || tenantId, // Highly recommended
      name: practiceName, // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
      tier,
      add_ons: addOns,
    },
  });
};

export { initializePendo, loadPendo };
