import { parseDate } from '../../neb-utils/date-util';

import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const DATE_FORMAT = 'YYYY-MM-DD';
export const FetchType = Object.freeze({
  THUMBNAIL: 'thumbnail',
  SRC: 'src',
});

async function getDocFile(model, fetchType, optOutLoadingIndicator = false) {
  try {
    const path =
      fetchType === FetchType.THUMBNAIL
        ? '/api/v1/tenants/:tenantId/documents/:id/thumbnail'
        : '/api/v1/tenants/:tenantId/documents/:id/src';

    const data = await coreApiClient.makeRequest({
      cacheKey: `${model.id}-${fetchType}-doc-file`,
      headers: {
        'Content-Type':
          fetchType === FetchType.THUMBNAIL ? 'image/jpeg' : model.mimeType,
      },
      method: Method.GET,
      path,
      replacements: { id: model.id },
      optOutLoadingIndicator,
      responseType: RESPONSE_TYPE.DATA_URL,
    });

    return data;
  } catch (err) {
    const info = () => (model ? `${model.id} id` : 'null model');

    console.error(`An issue occurred getting image with ${info()}:`, err);

    return null;
  }
}

/**
 * Return the thumbnail for a document
 * @param {*} model - the document model
 */
export const getDocThumbnail = (model, optOutLoadingIndicator = false) =>
  getDocFile(model, FetchType.THUMBNAIL, optOutLoadingIndicator);

/**
 * Return the original document src
 * @param {*} model - the document model
 */
export const getDocSrc = (model, optOutLoadingIndicator) =>
  getDocFile(model, FetchType.SRC, optOutLoadingIndicator);

/**
 * Post (create) document
 *
 * @param {Object} model - document model
 * @param {String} model.name - name
 * @param {Date} model.uploadDate - upload date
 * @param {Date} model.date - document date
 * @param {String} model.note - note
 * @param {Blob} model.file - file content
 * @param {String} patientId - patient id
 * @param {String} model.encounterId - enconunterId
 */
export function postDocument(model, patientId, optOutLoadingIndicator = false) {
  const queryParams = {
    uploadDate: model.uploadDate.format(DATE_FORMAT),
    documentDate: model.date.format(DATE_FORMAT),
    name: model.name,
    note: model.note,
    encounterId: model.encounterId,
  };
  const tags = model.tags.map(tag => tag.data.id).join(',');

  return coreApiClient.makeRequest({
    method: Method.POST,
    queryParams,
    body: model.file.slice(),
    path: '/api/v1/tenants/:tenantId/patients/:patientId/documents',
    replacements: { patientId },
    headers: {
      'Content-Type': model.file.type,
      tags,
    },
    optOutLoadingIndicator,
  });
}

/**
 * Put (update) document
 *
 * @param {Object} model - document model
 * @param {String} model.id - document id
 * @param {String} model.name - name
 * @param {Date} model.date - document date
 * @param {String} model.note - note
 */
export function putDocument(model, optOutLoadingIndicator = false) {
  const queryParams = {
    id: model.id,
    name: model.name,
    documentDate: model.date.format(DATE_FORMAT),
    note: model.note,
  };

  return coreApiClient.makeRequest({
    method: Method.PUT,
    body: { ...queryParams, tags: model.tags.map(tag => tag.data.id) },
    responseType: RESPONSE_TYPE.RAW,
    path: '/api/v1/tenants/:tenantId/documents/:id',
    replacements: { id: model.id },
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });
}

function buildAdvancedFiltersParams({
  searchText,
  encounterNumber,
  appointmentTypeId,
  dosFrom,
  dosTo,
}) {
  return {
    ...(searchText && { search: searchText }),
    ...(encounterNumber && { encounterNumber }),
    ...(appointmentTypeId && { appointmentTypeId }),
    ...(dosFrom && { dosFrom }),
    ...(dosTo && { dosTo }),
  };
}

function buildQueryParams(
  limit,
  offset,
  search = null,
  documentId = null,
  encounterId = null,
  advancedFilters = null,
) {
  const queryParams = {
    limit,
    offset,
    ...(search && { search }),
    ...(documentId && { documentId }),
    ...(encounterId && { encounterId }),
  };

  if (!advancedFilters) {
    return queryParams;
  }

  const advancedQueryParams = buildAdvancedFiltersParams(advancedFilters);
  return { ...queryParams, ...advancedQueryParams };
}

/**
 * Get documents
 *
 * @param {Numeric} patientId - Patient Id.
 * @param {Numeric} limit - Limit the number of results to return.
 * @param {Numeric} offset - Number of rows to skip, then query.
 * @param {String} search (optional)- A space separated list of search terms to be applied to the query.
 * @param {Numeric} documentId (optional) - Document Id.
 */
export function getDocuments(
  patientId,
  limit,
  offset,
  search = null,
  documentId = null,
  encounterId = null,
  advancedFilters = null,
  optOutLoadingIndicator = false,
) {
  const queryParams = buildQueryParams(
    limit,
    offset,
    search,
    documentId,
    encounterId,
    advancedFilters,
  );

  return coreApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/documents',
    replacements: { patientId },
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function getDocumentsWithThumbnails({
  patientId,
  limit,
  offset,
  searchTerms = null,
  documentId = null,
  encounterId = null,
  advancedFilters = null,
  optOutLoadingIndicator = false,
}) {
  const { data, count } = await getDocuments(
    patientId,
    limit,
    offset,
    searchTerms,
    documentId,
    encounterId,
    advancedFilters,
    optOutLoadingIndicator,
  );
  const thumbnailPromises = data.map(item =>
    getDocThumbnail(item, optOutLoadingIndicator),
  );
  const thumbnails = await Promise.all(thumbnailPromises);
  const updatedData = data.map((item, index) => {
    item.img = thumbnails[index];
    item.documentDate = parseDate(item.documentDate);
    item.uploadDate = parseDate(item.uploadDate);
    return item;
  });

  return {
    data: updatedData,
    count,
  };
}

/**
 * Delete document
 *
 * @param {Numeric} patientId - Patient Id.
 * @param {Numeric} documentId - Document Id.
 */
export function deleteDocument(documentId) {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/documents/:id',
    replacements: { id: documentId },
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Get document count
 *
 * @param {Numeric} patientId - Patient Id.
 */
export const getDocumentCount = async (
  patientId,
  optOutLoadingIndicator = false,
) => {
  const response = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/documents/count',
    replacements: { patientId },
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-document-count`,
    optOutLoadingIndicator,
  });

  return response.data[0].documentCount;
};
