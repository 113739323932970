export const getQueryParams = (name, items) =>
  items.map(i => `${name}[]=${i}`).join('&');

export const strFromQuery = query => {
  if (query.sortDir && query.sortField && query.sortParams) {
    delete query.sortParams;
  }

  return Object.entries(query)
    .filter(([_, v]) => v || v === 0)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
};
