import '../../patients/ledger/activity/neb-ledger-activity-transactions-page';
import '../../patients/ledger/activity/neb-ledger-activity-encounters-page';
import '../../patients/ledger/activity/neb-ledger-activity-purchases-page';
import '../../controls/neb-tab-group';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  tabs: {
    id: 'tabs',
  },
  header: {
    id: 'header',
  },
  encountersPage: {
    id: 'encounters',
  },
  purchasesPage: {
    id: 'purchases',
  },
  transactionsPage: {
    id: 'transactions',
  },
};

export const TABS = {
  ENCOUNTERS: 'encounters',
  PURCHASES: 'purchases',
  TRANSACTIONS: 'transactions',
};

class NebPracticeLedgerActivityController extends LitElement {
  static get properties() {
    return {
      layout: String,
      route: String,
      __selectedTab: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.route = '';
    this.layout = '';

    this.__navItems = this.genNavItems();
    this.__selectedTab = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => navigate(this.__buildRoute(tab)),
    };
  }

  __getRouteTail() {
    const segments = this.route.split('/');
    return segments.slice(3).join('/');
  }

  __buildRoute(tab) {
    return `#/practice-ledger/ledger/activity/${tab}`;
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();

    return this.layout === 'large'
      ? !!this.__navItems.find(navItem => navItem.id === routeTail)
      : routeTail === '';
  }

  fetch() {
    const encountersPage = this.shadowRoot.getElementById(
      ELEMENTS.encountersPage.id,
    );
    return encountersPage ? encountersPage.fetch() : '';
  }

  update(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let tab = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        tab = this.layout === 'large' ? TABS.ENCOUNTERS : '';
        redirect(this.__buildRoute(tab));
      }

      this.__selectedTab = tab;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .tabs {
          padding-top: 10px;
          flex: 0 0 auto;
        }

        .left {
          margin-left: ${CSS_SPACING};
        }

        .no-shrink {
          flex-shrink: 0;
        }

        .header {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  genNavItems() {
    return [
      {
        label: 'Encounters',
        id: TABS.ENCOUNTERS,
        path: `/ledger/activity/${TABS.ENCOUNTERS}/`,
        resolver: () => html`
          <neb-ledger-activity-encounters-page
            id="${ELEMENTS.encountersPage.id}"
            .layout="${this.layout}"
            includePatient
          ></neb-ledger-activity-encounters-page>
        `,
      },
      {
        label: 'Purchases',
        id: TABS.PURCHASES,
        path: `/ledger/activity/${TABS.PURCHASES}/`,
        resolver: () => html`
          <neb-ledger-activity-purchases-page
            id="${ELEMENTS.purchasesPage.id}"
            .layout="${this.layout}"
          ></neb-ledger-activity-purchases-page>
        `,
      },
      {
        label: 'Transaction History',
        id: TABS.TRANSACTIONS,
        path: `/ledger/activity/${TABS.TRANSACTIONS}/`,
        resolver: () => html`
          <neb-ledger-activity-transactions-page
            id="${ELEMENTS.transactionsPage.id}"
            .layout="${this.layout}"
          ></neb-ledger-activity-transactions-page>
        `,
      },
    ];
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

customElements.define(
  'neb-practice-ledger-activity-controller',
  NebPracticeLedgerActivityController,
);
