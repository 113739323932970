export const DURATION_DEFAULT = 200;
export default class Debouncer {
  get isRunning() {
    return this.__timer !== null;
  }

  constructor(callback, duration = DURATION_DEFAULT) {
    this.__duration = duration;
    this.__timer = null;
    this.onTimeout = callback;
  }

  reset(timer) {
    if (this.__timer) {
      clearTimeout(timer);
      this.__timer = null;
    }
  }

  debounce() {
    this.reset(this.__timer);
    this.__timer = setTimeout(() => {
      this.onTimeout();
      this.reset(this.__timer);
    }, this.__duration);
  }

  fireImmediate() {
    this.reset(this.__timer);
    this.onTimeout();
  }
}
