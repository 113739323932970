import '../../../packages/neb-lit-components/src/components/neb-ripple-button';
import '../misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_BORDER_GREY_2,
  CSS_BORDER_HIGHLIGHT,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  layoutStyles,
} from '../../styles';

export const ELEMENTS = {
  clickCard: { id: 'click-card' },
};

export class NebEventClickCard extends LitElement {
  static get properties() {
    return {
      __active: {
        reflect: true,
        type: Boolean,
        attribute: 'active',
      },
      item: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .grid {
          padding: ${CSS_SPACING};
          border: 0;
          border-radius: 5px;
          border: ${CSS_BORDER_GREY_2};
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          cursor: pointer;
        }

        :host([active]) .grid {
          border: ${CSS_BORDER_HIGHLIGHT};
        }

        .collapse-button-ripple {
          width: 20px;
          height: 20px;
          z-index: 0;
        }

        .icon {
          cursor: pointer;
          margin: 8px 6px;
          width: 24px;
          height: 24px;
          fill: #555555;
          cursor: default;
        }

        :host([active]) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.handlers.click);
    this.addEventListener('mouseover', this.handlers.hover);
    this.addEventListener('mouseleave', this.handlers.leave);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.handlers.click);
    this.removeEventListener('mouseover', this.handlers.hover);
    this.removeEventListener('mouseleave', this.handlers.leave);
    super.disconnectedCallback();
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__active = false;
    this.item = {};

    this.onClick = () => {};
  }

  initHandlers() {
    this.handlers = {
      hover: () => {
        this.__active = true;
      },
      leave: () => {
        this.__active = false;
      },
      click: () => this.onClick(this.item),
    };
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  render() {
    return html`
      <div id="${ELEMENTS.clickCard.id}" class="grid">
        <div>${this.renderContent()}</div>
        <neb-ripple-button class="collapse-button-ripple">
          <neb-icon
            slot="content"
            class="icon"
            icon="neb:expand"
            ?hover="${this.__active}"
          ></neb-icon>
        </neb-ripple-button>
      </div>
    `;
  }
}

customElements.define('neb-event-click-card', NebEventClickCard);
