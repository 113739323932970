import '../neb-effect';
import './neb-problem-list-table-row';

import { LitElement, html, css } from 'lit';

import { renderNebTableStyles } from '../../../../neb-styles/neb-table-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import {
  getTableRowInfo,
  formatIcdSelectorIdCode,
} from '../../../../neb-utils/neb-charting-util';
import { ELEMENTS as CHECKBOX_ELEMENTS } from '../neb-checkbox-old';
import { NebDnDVerticalOrder } from '../neb-dnd-vertical-order';

const RESOLVED = 'resolved';
const TEXT_NO_PROBLEMS = 'There are no problems for this patient.';
export const ELEMENTS = {
  header: {
    id: 'header',
  },
  noProblemText: {
    id: 'text-no-problems',
  },
  tableRows: {
    selector: 'neb-problem-list-table-row',
  },
  diagnosesColumn: {
    id: 'column-diagnoses',
  },
  descriptionColumn: {
    id: 'column-description',
  },
  providerColumn: {
    id: 'column-provider',
  },
  typeColumn: {
    id: 'column-type',
  },
  statusColumn: {
    id: 'column-status',
  },
  onsetColumn: {
    id: 'column-onset',
  },
  resolutionColumn: {
    id: 'column-resolution',
  },
  checkbox: {
    id: CHECKBOX_ELEMENTS.checkbox.id,
  },
};

class NebProblemListTable extends NebDnDVerticalOrder(LitElement) {
  static get properties() {
    return {
      problems: Array,
      isFiltered: Boolean,
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.problems = [];
    this.isFiltered = false;

    this.onReorderProblemList = () => {};

    this.onUpdateProblemInList = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      toggleResolveFilter: bool => {
        this.isFiltered = bool;
      },
      updateProblem: (index, problem) => {
        if (this.isFiltered) {
          this.onUpdateProblemInList(
            this.__getProblemOriginIndex(index),
            problem,
            this.problems,
          );
        } else {
          this.onUpdateProblemInList(index, problem, this.problems);
        }
      },
      reorderProblemList: problems => this.onReorderProblemList(problems),
    };
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.elements = {
      checkbox: this.shadowRoot.getElementById(CHECKBOX_ELEMENTS.checkbox.id),
    };
  }

  updated() {
    this._items = this.isFiltered
      ? this.problems.filter(p => p.status !== RESOLVED)
      : this.problems.slice();

    this._update();
  }

  __getProblemOriginIndex(index) {
    return this.problems.indexOf(this._items[index]);
  }

  __customReorder() {
    let problemDestinationIndex;

    const problemOriginIndex = this.__getProblemOriginIndex(
      this.__drag.origIndex,
    );

    if (this.__drag.origIndex < this.__drag.moveToIndex) {
      problemDestinationIndex = this.problems.indexOf(
        this._items[this.__drag.moveToIndex],
      );
    } else {
      problemDestinationIndex =
        this.problems.indexOf(this._items[this.__drag.moveToIndex - 1]) + 1;
    }

    const movedItem = this.problems.splice(problemOriginIndex, 1)[0];
    this.problems.splice(problemDestinationIndex, 0, movedItem);
  }

  _rearrangeItems() {
    if (this.isFiltered) {
      this.__customReorder();
    } else {
      super._rearrangeItems();

      this.problems = this._items;
    }

    this.__handlers.reorderProblemList(this.problems);
  }

  __renderRow(problem, index) {
    const { isNew } = getTableRowInfo(problem);
    return html`
      <neb-effect ?color="${isNew}" force>
        <neb-problem-list-table-row
          reorder-row
          id="code-${formatIcdSelectorIdCode(problem.code)}"
          .problem="${problem}"
          .index="${index}"
          .onUpdateProblem="${this.__handlers.updateProblem}"
          .small="${this.small}"
        ></neb-problem-list-table-row>
      </neb-effect>
    `;
  }

  __renderResults() {
    if (this.isFiltered) {
      return this.problems
        .filter(p => p.status !== RESOLVED)
        .map((problem, index) => this.__renderRow(problem, index));
    }
    return this.problems.map((problem, index) =>
      this.__renderRow(problem, index),
    );
  }

  clear() {
    this.elements.checkbox.checked = false;
    this.isFiltered = false;
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      :host([small]) .drag-handle-header {
        flex: 0 0 15px;
      }

      :host([small]) .neb-table-row .neb-table-cell:first-child {
        padding-left: 10px;
      }

      :host([small]) .neb-table-row .neb-table-cell:last-child {
        padding-right: 10px;
      }

      :host([small]) .diagnosis-header {
        flex: 0 0 65px;
      }

      :host([small]) .description-header {
        padding-right: 0;
      }

      :host([small]) .view-problem-header {
        padding-left: 0;
      }

      .drag-handle-header {
        flex: 0 0 40px;
      }

      .diagnosis-header {
        max-width: 100px;
      }

      .description-header {
        text-align: left;
      }

      .view-problem-header {
        flex: 0 0 40px;
      }

      .no-problems-text {
        margin: ${CSS_SPACING};
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }

      neb-checkbox-old {
        margin: 10px 0 0 15px;
      }

      :host([dragging]) [reorder-row] {
        background-color: ${CSS_COLOR_WHITE};
      }

      .content-container {
        display: flex;
        width: 100%;
      }

      .neb-table-large-width {
        min-width: 100px;
      }

      .neb-table-small-width {
        min-width: 80px;
      }

      .neb-table-cell {
        word-break: break-word;
      }
    `;
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.header.id}" class="neb-table-row neb-table-header">
        <div class="drag-handle-header neb-table-cell"></div>
        <div class="content-container">
          <div
            id="${ELEMENTS.diagnosesColumn.id}"
            class="neb-table-cell neb-table-small-width diagnosis-header"
          >
            Diagnosis
          </div>
          <div
            id="${ELEMENTS.descriptionColumn.id}"
            class="neb-table-cell neb-table-large-width description-header"
          >
            Description
          </div>
          ${!this.small
            ? html`
                <div
                  id="${ELEMENTS.providerColumn.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  Provider
                </div>
                <div
                  id="${ELEMENTS.typeColumn.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  Type
                </div>
                <div
                  id="${ELEMENTS.statusColumn.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  Status
                </div>
                <div
                  id="${ELEMENTS.onsetColumn.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  Onset Date
                </div>
                <div
                  id="${ELEMENTS.resolutionColumn.id}"
                  class="neb-table-cell neb-table-small-width"
                >
                  Resolution Date
                </div>
                <div class="neb-table-cell view-problem-header"></div>
              `
            : ''}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${renderNebTableStyles()}

      <neb-checkbox-old
        .onClick="${this.__handlers.toggleResolveFilter}"
        label="Hide resolved problems"
        id="${CHECKBOX_ELEMENTS.checkbox.id}"
      ></neb-checkbox-old>
      <div class="table-container">
        ${this.__renderHeader()}

        <div>
          ${this.problems.length > 0
            ? this.__renderResults()
            : html`
                <div id="${ELEMENTS.noProblemText.id}" class="no-problems-text">
                  ${TEXT_NO_PROBLEMS}
                </div>
              `}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-problem-list-table', NebProblemListTable);
