import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import {
  CODE_PAYMENTS,
  CODE_WRITE_OFFS,
} from '../../../../packages/neb-utils/constants';
import {
  centsToCurrency,
  currencyToCentsWithNegative,
  currencyToCents,
  centsToCurrencyWithNegative,
  formatDollarAmountWithNegative,
} from '../../../../packages/neb-utils/formatters';
import * as masks from '../../../../packages/neb-utils/masks';
import {
  BILL_TYPE,
  isActivePatientPaymentCode,
  mapInsurancePlansToEditPopupDropdown,
  validateAdjustmentCode,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { deepCopy } from '../../../../packages/neb-utils/utils';
import {
  CSS_COLOR_ERROR,
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING_ROW,
  CSS_FONT_SIZE_CAPTION,
  CSS_FIELD_MARGIN,
} from '../../../styles';
import { searchItems } from '../../../utils/filters';

export const PATIENT_HEADER = ['Type', 'Amount', 'Pt. Paid'];
export const ADJUSTMENT_HEADER = ['Reason', 'Amount'];
export const SECONDARY_HEADER = ['Type', 'Amount', 'Paid'];

export const PAYMENT_BALANCE_ERROR_MESSAGE =
  'Payment Balance must be greater than or equal to $0.00.';

export const BLANK_TYPE_DROPDOWN_ITEM = {
  label: '',
  data: {
    id: '',
    code: '',
    description: '',
    label: '',
  },
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  typeSearchSelect: { selector: '[id^=type-search-]' },
  plansDropdown: { id: 'plans-dropdown' },
  amountTextfields: { selector: '[id^=amount-textfield-]' },
  buttonSave: { id: 'save' },
  buttonCancel: { id: 'cancel' },
  paidAmountValue: { selector: '[id^=paid-amount-value-]', tag: 'neb-text' },
  displayedErrors: { id: 'displayed-errors' },
  fieldHeader: { selector: '[id^=field-header-]' },
  nebMinusIcons: { selector: '[id^=remove-icon-]' },
  paymentBalance: { id: 'payment-balance' },
  paymentBalanceError: { id: 'payment-balance-error' },
  fieldContainer: { id: 'field-container' },
};

class NebPopupIndividualCodeAndAmount extends NebPopup {
  static get properties() {
    return {
      __typeState: Array,
      __errors: Array,
      __selectedPlan: Object,
      __planError: Boolean,
      __scrollMode: {
        reflect: true,
        type: Boolean,
      },
      __planDropdownItems: Array,
      __searchResult: Array,
      __showResults: Boolean,
      __searchItems: Array,
      allowNegative: Boolean,
      displayPaidAmountCol: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  initState() {
    super.initState();
    this.model = {
      title: '',
      initialCodes: [],
      displayPaidAmount: false,
      fieldHeaders: [],
      billType: '',
      codeWriteOffs: [],
      paymentCodes: [],
      plans: [],
      enablePlansDropdown: false,
      isCarePackageWithInsurance: false,
      selectedPlan: {},
      paymentAmount: 0,
      paymentAllocated: 0,
      hasRCMSecondaryField: false,
      hasRCMChangeSecondary: false,
      limit: 0,
    };

    this.isResponsibility = true;
    this.paidAmountIsRemoved = false;
    this.displayPaidAmountCol = false;
    this.allowNegative = false;
    this.initialTypeState = [];

    this.__packageDropdownItem = [];
    this.__planDropdownItems = [];
    this.__selectedPlan = {};
    this.__typeState = [];
    this.__errors = [];
    this.__planError = false;
    this.__paymentBalanceError = false;
    this.__itemsToDelete = [];
    this.__scrollMode = false;
    this.__searchResult = [];
    this.__showResults = false;
    this.__searchItems = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      changeAmount: ({ name, value }) => {
        const rowIndex = Number(name.split('-')[2]);
        const isValueNegative = value.charAt(0) === '-';

        if (value) {
          const stateCopy = [...this.__typeState];

          stateCopy[rowIndex] = {
            ...stateCopy[rowIndex],
            amount: isValueNegative
              ? currencyToCentsWithNegative(value)
              : currencyToCents(value),
          };

          this.__typeState = stateCopy;

          const lastRowIndex = this.__typeState.length - 1;

          if (value !== '$0.00' && rowIndex === lastRowIndex) {
            this.__addNewRow();
          }

          if (this.__isRowEmpty(rowIndex)) {
            this.__removeRow(rowIndex);
            return;
          }
        }

        this.__validate(rowIndex);
      },
      changeType: ({ name, value }) => {
        const rowIndex = Number(name.split('-')[2]);

        if (!value) {
          const stateCopy = [...this.__typeState];
          stateCopy[rowIndex] = {
            ...stateCopy[rowIndex],
            codeId: '',
          };

          this.__typeState = stateCopy;
        }

        const { data } = value;

        if (data) {
          const stateCopy = [...this.__typeState];

          stateCopy[rowIndex] = {
            ...stateCopy[rowIndex],
            codeId: data.id,
          };

          this.__typeState = stateCopy;

          const lastRowIndex = this.__typeState.length - 1;

          if (rowIndex === lastRowIndex) {
            this.__addNewRow();
          }
        }

        if (this.__isRowEmpty(rowIndex)) {
          this.__removeRow(rowIndex);
          return;
        }
        this.__validate(rowIndex);
      },
      changePlan: ({ value }) => {
        this.__selectedPlan = value;
        this.__planError = !value.id;
      },
      save: () => {
        if (!this.__hasErrors()) {
          const result = this.__resolvePopupResult();

          if (this.model.enablePlansDropdown && this.model.plans.length) {
            result.patientInsurance = this.__getPlanById(
              this.__selectedPlan.id,
            );
          }

          this.onClose(result);
        }
      },
      cancel: () => {
        this.onClose(false);
      },
      keydown: e => {
        if (e.key === 'Escape') {
          this.handlers.cancel();
        }
      },
      searchCode: ({ value }) => {
        if (!value) this.__showResults = true;
        this.__searchResult = searchItems(value, this.__searchItems);
      },
      renderTypeDropdownItem: item =>
        item && item.code && item.description
          ? html`
              <div style="padding: 0 16px;width: max-content;max-width: 600px;">
                <p>${item.code} - ${item.description}</p>
              </div>
            `
          : '',
      renderPlanDropdownItem: item =>
        item && item.label
          ? html`
              <div style="padding: 0 16px;width: max-content;max-width: 440px;">
                <p>${item.label}</p>
              </div>
            `
          : '',
      removeRow: index => {
        this.__removeRow(index);
        this.requestUpdate();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.handlers.keydown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.handlers.keydown);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 370px;
        }

        :host([displayPaidAmountCol]) {
          width: 445px;
        }

        :host([__scrollMode]) {
          width: 400px;
        }

        :host([displayPaidAmountCol][__scrollMode]) {
          width: 465px;
        }

        .content {
          margin-top: 0;
        }

        .scrollable {
          max-height: 400px;
          overflow-y: scroll;
        }

        .relative {
          position: relative;
        }

        .spacing-button {
          margin-right: 10px;
        }

        neb-button:focus {
          opacity: 0.8;
        }

        .container-buttons {
          margin-top: ${CSS_SPACING};
          display: flex;
        }

        .type-container {
          display: flex;
          gap: ${CSS_SPACING};
          align-items: start;
        }

        .paid-amount {
          padding-top: 26px;
        }

        .neb-width {
          width: 155px;
        }

        neb-select-search {
          padding-top: 16px;
          width: 100%;
        }

        .error-area {
          display: flex;
          flex-direction: column;
        }

        .paid-amount-error {
          color: ${CSS_COLOR_ERROR};
          padding-top: ${CSS_SPACING};
        }

        .paid-removal-error {
          padding-top: ${CSS_SPACING};
        }

        .plan-dropdown {
          width: 405px;
          margin: 15px 0;
        }

        .label-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          padding-top: 15px;
          grid-template-rows: auto;
        }

        .label-container-paid {
          display: grid;
          grid-template-columns: 1fr 1fr 50px;
          grid-gap: ${CSS_SPACING};
          padding-top: 15px;
          grid-template-rows: auto;
        }

        .field-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          grid-template-rows: auto;
        }

        .field-container-paid {
          display: grid;
          grid-template-columns: 1fr 1fr 50px;
          grid-gap: ${CSS_SPACING};
          grid-template-rows: auto;
        }

        .field-container-remit-offset {
          display: grid;
          grid-gap: ${CSS_FIELD_MARGIN};
          column-gap: ${CSS_FIELD_MARGIN};
          grid-template-columns: 140px 140px auto;
        }

        .no-gap {
          grid-gap: 0;
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .icon-remove {
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          margin: 25px 15px 0 0;
        }

        .payment-balance {
          display: flex;
          width: 100%;
          margin: ${CSS_SPACING_ROW} 0;
        }

        .text-error {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: normal;
          width: 100%;
          min-width: 0px;
          font-size: ${CSS_FONT_SIZE_CAPTION};
          letter-spacing: 0.35px;
          color: ${CSS_COLOR_ERROR};
        }

        .spacer {
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          margin: 25px 35px 0 0;
        }
      `,
    ];
  }

  firstUpdated() {
    this.__focusOnFirstEnabledDropdown();
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__initializePopupState();

      let formattedDropDownItems;

      if (this.isResponsibility) {
        formattedDropDownItems = this.model.paymentCodes
          .filter(isActivePatientPaymentCode)
          .map(({ data }) => ({
            label: `${data.code} - ${data.description}`,
            data: {
              id: data.id,
              code: data.code,
              description: data.description,
              label: data.code,
            },
          }));

        this.__packageDropdownItem = [
          BLANK_TYPE_DROPDOWN_ITEM,
          {
            label: `${CODE_PAYMENTS.PACKAGE.code} - ${
              CODE_PAYMENTS.PACKAGE.description
            }`,
            data: {
              id: CODE_PAYMENTS.PACKAGE.id,
              code: CODE_PAYMENTS.PACKAGE.code,
              description: CODE_PAYMENTS.PACKAGE.description,
              label: CODE_PAYMENTS.PACKAGE.code,
            },
          },
        ];
      } else {
        const writeOffsOfCurrentBillType =
          this.__filterWriteOffsOfCurrentBillType(this.model.codeWriteOffs);

        formattedDropDownItems = this.__formatWriteOffs(
          writeOffsOfCurrentBillType,
        );

        this.__packageDropdownItem = [
          BLANK_TYPE_DROPDOWN_ITEM,
          ...this.__formatWriteOffs([CODE_WRITE_OFFS.PACKAGE]),
        ];
      }

      this.__searchItems = [
        BLANK_TYPE_DROPDOWN_ITEM,
        ...formattedDropDownItems,
      ];

      this.__searchResult = [
        BLANK_TYPE_DROPDOWN_ITEM,
        ...formattedDropDownItems,
      ];

      if (this.model.plans) {
        this.__planDropdownItems = this.model.plans.length
          ? mapInsurancePlansToEditPopupDropdown(this.model.plans)
          : [{ id: '', label: '' }];
      }

      this.__selectedPlan = this.__planDropdownItems[0];
    }
  }

  __isProviderAdjustments() {
    return this.title === 'Remit Offsets';
  }

  __checkPopupChanged() {
    const stateEql = !equal(this.__typeState, this.initialTypeState);
    const planChanged = !equal(this.__selectedPlan, this.model.selectedPlan);

    return stateEql || planChanged;
  }

  __resolvePopupResult() {
    const { hasRCMChangeSecondary, selectedPlan } = this.model;
    const planChanged =
      selectedPlan && selectedPlan.data.id !== this.__selectedPlan.id;

    if (hasRCMChangeSecondary && planChanged) {
      const nonEmptyRows = this.__typeState.filter(row => row.amount);

      const itemsToAdd = nonEmptyRows.map(row => ({
        codeId: row.codeId,
        amount: row.amount,
        individualRowId: '',
      }));

      const itemsToDelete = nonEmptyRows.map(row => ({
        individualRowId: row.individualRowId,
        codeId: row.codeId,
        amount: row.amount,
      }));

      return {
        itemsToAdd,
        itemsToDelete,
        itemsToEdit: [],
        popupChanged: this.__checkPopupChanged(),
      };
    }

    this.__typeState.forEach(row => delete row.allocations);
    this.__itemsToDelete.forEach(row => delete row.allocations);

    if (!this.__typeState[this.__typeState.length - 1].amount) {
      this.__typeState.splice(-1);
    }

    return {
      itemsToEdit: this.__typeState.filter(item => item.individualRowId),
      itemsToDelete: this.__itemsToDelete,
      itemsToAdd: this.__typeState.filter(item => !item.individualRowId),
      popupChanged: !equal(this.__typeState, this.initialTypeState),
    };
  }

  __focusOnFirstEnabledDropdown() {
    setTimeout(() => {
      const searchSelects = this.shadowRoot.querySelectorAll(
        'neb-select-search:not([disabled])',
      );
      const lastIndex = searchSelects.length - 1;

      if (searchSelects[lastIndex]) {
        searchSelects[lastIndex].setFocus();
      }
    }, 100);
  }

  __formatWriteOffs(writeOffs) {
    return writeOffs.map(writeOff => ({
      label: `${writeOff.codeGroup} ${writeOff.code} - ${writeOff.description}`,
      data: {
        id: writeOff.id,
        code: writeOff.code,
        description: writeOff.description,
        label: `${writeOff.codeGroup} ${writeOff.code}`,
      },
    }));
  }

  __filterWriteOffsOfCurrentBillType(writeOffs) {
    if (this.__isProviderAdjustments()) return writeOffs;

    switch (this.model.billType) {
      case BILL_TYPE.INSURANCE:
        return writeOffs.filter(
          ({ active, forInsurance }) => active && forInsurance,
        );

      case BILL_TYPE.SELF:
      case BILL_TYPE.PACKAGE:
        return writeOffs.filter(
          ({ active, forPatient, code, codeGroup }) =>
            active && forPatient && !(code === 'Pkg' && codeGroup === 'OA'),
        );

      default:
        return [];
    }
  }

  __setScrollMode() {
    this.__scrollMode = this.__typeState.length > 5;
  }

  __initializePopupState() {
    this.title = this.model.title;
    this.displayPaidAmountCol = this.model.displayPaidAmount;

    this.isResponsibility =
      this.title === 'Patient Responsibility' ||
      this.title === 'Secondary Responsibility';

    this.__typeState = this.model.initialCodes.map(code => ({
      individualRowId: code.id,
      codeId: this.isResponsibility ? code.codePaymentId : code.codeId,
      amount: this.isResponsibility ? code.debit.amount : code.amount,
      allocations: this.displayPaidAmountCol ? code.debit.allocations : [],
    }));

    if (this.isResponsibility && !this.model.hasRCMSecondaryField) {
      this.__typeState = this.__typeState.filter(
        ({ codeId, amount }) => codeId && amount,
      );
    }

    this.initialTypeState = this.__typeState.map(item =>
      deepCopy({
        individualRowId: item.individualRowId,
        codeId: item.codeId,
        amount: item.amount,
      }),
    );

    this.__errors = Array(this.__typeState.length).fill({
      codeId: false,
      amount: false,
    });

    this.__addNewRow();

    this.__typeState.forEach((_, index) => {
      this.__validate(index);
    });

    this.__setScrollMode();
  }

  __addNewRow() {
    if (this.model.limit === this.__typeState.length) {
      return;
    }

    this.__typeState = [
      ...this.__typeState,
      {
        individualRowId: '',
        codeId: '',
        amount: 0,
        allocations: [],
      },
    ];

    this.__errors = [
      ...this.__errors,
      {
        codeId: false,
        amount: false,
        paidAmount: false,
      },
    ];

    this.__setScrollMode();
  }

  __removeRow(rowIndex) {
    if (this.__typeState[rowIndex].individualRowId) {
      this.__itemsToDelete.push(this.__typeState[rowIndex]);
    }

    const rowPaidAmount = this.__getPaidAmount(this.__typeState[rowIndex]);

    if (rowPaidAmount > 0) {
      this.paidAmountIsRemoved = true;
    }

    this.__typeState.splice(rowIndex, 1);
    this.__errors.splice(rowIndex, 1);
    this.__setScrollMode();
  }

  __isRowEmpty(rowIndex) {
    const typeDropdown = this.shadowRoot.getElementById(
      `type-dropdown-${rowIndex}`,
    );

    if (typeDropdown === this.__packageDropdownItem) {
      return false;
    }

    const { codeId, amount } = this.__typeState[rowIndex];
    const lastRowIndex = this.__typeState.length - 1;

    return (
      (codeId === '' || codeId === null) &&
      amount === 0 &&
      rowIndex !== lastRowIndex
    );
  }

  __hasErrors() {
    return (
      this.__errors.some(
        e => e.codeId === true || e.amount === true || e.paidAmount === true,
      ) ||
      this.__planError ||
      this.__paymentBalanceError
    );
  }

  // eslint-disable-next-line complexity
  __validate(rowIndex) {
    if (this.__typeState[rowIndex]) {
      const lastRowIndex = this.__typeState.length - 1;
      const { codeId, amount } = this.__typeState[rowIndex];
      const errorsCopy = [...this.__errors];

      const invalidCode =
        this.isResponsibility || this.__isProviderAdjustments()
          ? codeId === '' || codeId === null
          : !validateAdjustmentCode(
              this.model.codeWriteOffs,
              codeId,
              this.model.billType,
              this.model.isCarePackageWithInsurance,
            );

      const noType = invalidCode && amount !== 0;
      const noAmount = codeId && amount === 0;
      const isLastRow = rowIndex === lastRowIndex;

      errorsCopy[rowIndex] = {
        ...errorsCopy[rowIndex],
        codeId: noType && !isLastRow,
        amount: (amount === 0 || noAmount) && !isLastRow,
      };

      this.__errors = errorsCopy;
    }
  }

  __getBillingCodesById(codeId) {
    if (!codeId) {
      return '';
    }

    if (this.__isCodePackage(codeId)) {
      return this.__packageDropdownItem[1];
    }

    return this.__searchItems.find(item => item.data.id === codeId) || '';
  }

  __getPlanById(planId) {
    if (!planId) {
      return '';
    }

    return this.model.plans.find(plan => plan.id === planId);
  }

  __getPaidAmount(initialCode) {
    return initialCode.allocations.reduce((sum, a) => sum + a.amount, 0);
  }

  __displayPaidAmount(initialCode, index) {
    const paidAmount = this.__getPaidAmount(initialCode);

    const paidAmountError = this.__errors[index].paidAmount;
    return html`
      <neb-text
        id="paid-amount-value-${index}"
        class="paid-amount"
        style="${paidAmountError ? `color:${CSS_COLOR_ERROR}` : ''}"
        >${centsToCurrency(paidAmount)}</neb-text
      >
    `;
  }

  __displayMinusIcon(index) {
    const isNotLastRow = index !== this.__typeState.length - 1;
    const hasLength = this.__typeState.length > 1;

    return hasLength && isNotLastRow
      ? html`
          <neb-icon
            id="remove-icon-${index}"
            class="icon icon-remove"
            icon="neb:minus"
            @click="${() => this.handlers.removeRow(index)}"
          ></neb-icon>
        `
      : html` <div class="spacer"></div> `;
  }

  __displayPaymentBalance() {
    if (!this.__isProviderAdjustments()) return '';

    const adjustments = this.__typeState.reduce(
      (acc, { amount }) => acc + amount,
      0,
    );

    const paymentBalance =
      this.model.paymentAmount - this.model.paymentAllocated;

    const newBalance = paymentBalance + adjustments;

    const error = newBalance < 0 && PAYMENT_BALANCE_ERROR_MESSAGE;

    this.__paymentBalanceError = !!error;

    return html`
      <neb-text id="${ELEMENTS.paymentBalance.id}" class="payment-balance">
        Payment Balance: ${formatDollarAmountWithNegative(newBalance)}
      </neb-text>
      <p id="${ELEMENTS.paymentBalanceError.id}" class="text-error">
        ${error ? PAYMENT_BALANCE_ERROR_MESSAGE : ''}
      </p>
    `;
  }

  __showPaidAmountError() {
    const responsible =
      this.title === 'Patient Responsibility' ? 'Patient' : 'Secondary';
    return this.__errors.some(e => e.paidAmount === true)
      ? html`
          <span class="paid-amount-error"
            >The ${responsible} Owed amount must be equal to or greater than the
            Paid amount</span
          >
        `
      : '';
  }

  __showPaidRemovalError() {
    const { hasRCMChangeSecondary, selectedPlan } = this.model;

    if (
      hasRCMChangeSecondary &&
      selectedPlan &&
      selectedPlan.data.id !== this.__selectedPlan.id
    ) {
      return html`
        <span class="paid-removal-error"
          >(WARNING) Changing the payer will remove all allocations</span
        >
      `;
    }

    return this.paidAmountIsRemoved
      ? html`
          <span class="paid-removal-error"
            >(WARNING) Selecting update will remove one or more
            allocations</span
          >
        `
      : '';
  }

  __renderPaidAmountError() {
    return html`
      <div class="error-area" id="${ELEMENTS.displayedErrors.id}">
        ${this.__showPaidAmountError()}${this.__showPaidRemovalError()}
      </div>
    `;
  }

  __renderFieldHeader() {
    let fieldHeader;

    if (this.displayPaidAmountCol) {
      fieldHeader =
        this.title === 'Patient Responsibility'
          ? PATIENT_HEADER
          : SECONDARY_HEADER;
    } else {
      fieldHeader = ADJUSTMENT_HEADER;
    }

    const labelClass = this.displayPaidAmountCol
      ? 'label-container-paid'
      : 'label-container';
    const noGapClass = this.__isProviderAdjustments() ? 'no-gap' : '';

    return html`
      <div class="${labelClass} ${noGapClass}">
        ${fieldHeader.map(
          (header, index) => html`
            <span id="field-header-${index}">${header}</span>
          `,
        )}
      </div>
    `;
  }

  __isCodePackage(code) {
    return this.isResponsibility
      ? code === CODE_PAYMENTS.PACKAGE.id
      : code === CODE_WRITE_OFFS.PACKAGE.id;
  }

  __shouldDisableField(code) {
    return this.isResponsibility
      ? !this.model.isCarePackageWithInsurance &&
          code === CODE_PAYMENTS.PACKAGE.id
      : code === CODE_WRITE_OFFS.PACKAGE.id;
  }

  __renderPlansDropdown() {
    return this.__planDropdownItems && this.__planDropdownItems.length
      ? html`
          <neb-select
            id="${ELEMENTS.plansDropdown.id}"
            label="Secondary Plan"
            name="plan-dropdown"
            class="plan-dropdown neb-width"
            .value="${this.__selectedPlan}"
            .error="${this.__planError}"
            .items="${this.__planDropdownItems}"
            .helper="${this.__planError ? 'A plan must be selected' : ''}"
            .onChange="${this.handlers.changePlan}"
            .onRenderItem="${this.handlers.renderPlanDropdownItem}"
            .useStartingSoftSelectIndex="${true}"
            ?disabled="${!this.model.enablePlansDropdown}"
            itemMinWidth="500"
            showFullText
            wrapText
            itemHeight="60"
          ></neb-select>
        `
      : '';
  }

  __getMask() {
    return !this.isResponsibility && this.allowNegative
      ? masks.currencyNegative
      : masks.currency;
  }

  __getAmountTextfieldError(index) {
    return this.__errors[index].amount || this.__errors[index].paidAmount;
  }

  __getAmountTextfieldHelper(index) {
    const { amount } = this.__errors[index];

    if (amount) {
      return this.isResponsibility
        ? 'Must be greater than $0.00'
        : 'Cannot be equal to $0.00';
    }

    return '';
  }

  __renderRows() {
    let fieldClass = 'field-container';

    if (this.__isProviderAdjustments()) {
      fieldClass = 'field-container-remit-offset';
    } else if (this.displayPaidAmountCol) {
      fieldClass = 'field-container-paid';
    }

    return html`
      <div id="${ELEMENTS.fieldContainer.id}" class="${fieldClass}">
        ${this.__typeState.map((initialCode, index) => {
          const isCodePackage = this.__isCodePackage(initialCode.codeId, index);
          const disableField = this.__shouldDisableField(initialCode.codeId);

          return html`
            ${html`
              <neb-select-search
                id="type-search-${index}"
                name="type-search-${index}"
                .helper="${this.__errors[index].codeId
                  ? 'Must have a type selected'
                  : ''}"
                .error="${this.__errors[index].codeId}"
                .items="${isCodePackage
                  ? this.__packageDropdownItem
                  : this.__searchResult}"
                .value="${this.__getBillingCodesById(initialCode.codeId)}"
                .onSearch="${this.handlers.searchCode}"
                .disabled="${disableField}"
                .showMenu="${this.__showResults}"
                .onChange="${this.handlers.changeType}"
                itemMinWidth="${this.__isProviderAdjustments() ? 300 : 600}"
                showSearch
                showFullText
                wrapText
                itemHeight="60"
              ></neb-select-search>
            `}
            <div>
              <neb-textfield
                id="amount-textfield-${index}"
                label=" "
                name="amount-textfield-${index}"
                .value="${centsToCurrencyWithNegative(initialCode.amount)}"
                .error="${this.__getAmountTextfieldError(index)}"
                .helper="${this.__getAmountTextfieldHelper(index)}"
                .mask="${this.__getMask()}"
                .inputMode="${'numeric'}"
                maxLength="11"
                .onChange="${this.handlers.changeAmount}"
                ?disabled="${disableField}"
              ></neb-textfield>
            </div>
            ${this.displayPaidAmountCol
              ? this.__displayPaidAmount(initialCode, index)
              : ''}
            ${this.__isProviderAdjustments()
              ? this.__displayMinusIcon(index)
              : ''}
          `;
        })}
      </div>
    `;
  }

  __renderButtons() {
    return html`
      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.buttonSave.id}"
          class="spacing-button"
          label="Update"
          .onClick="${this.handlers.save}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderPlansDropdown()} ${this.__renderFieldHeader()}
      ${this.__renderRows()} ${this.__renderPaidAmountError()}
      ${this.__renderButtons()}
    `;
  }

  render() {
    return html`
      <div class="container ${this.__scrollMode ? 'relative' : ''}">
        ${this.renderHeader()} ${this.__displayPaymentBalance()}
        <div class="content ${this.__scrollMode ? 'scrollable' : ''}">
          ${this.renderContent()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-individual-code-and-amount',
  NebPopupIndividualCodeAndAmount,
);
