import { openPopup } from '@neb/popup';

import { getEncountersDetails } from '../../../../../neb-api-client/src/encounters-api-client';
import * as apiPatientCases from '../../../../../neb-api-client/src/patient-cases';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { DATE_OF_CURRENT_ILLNESS_QUALIFIER } from '../../../../../neb-utils/claims';
import { parseDate } from '../../../../../neb-utils/date-util';

const getOnsetSymptomsDateItem = date =>
  date
    ? [
        {
          name: 'onsetOfSymptoms',
          qualifier: DATE_OF_CURRENT_ILLNESS_QUALIFIER.onsetOfSymptoms.value,
          date,
        },
      ]
    : [];

const getDateOfCurrentIllnessItems = async (
  onsetOfSymptomsDate,
  claimCharges,
) => {
  const encounterDetails = await getEncountersDetails([
    ...new Set(claimCharges.map(cc => cc.encounterId)),
  ]);

  const uniqueDatesOfCurrentIllness = [
    ...new Set(
      encounterDetails.map(({ currentIllnessDate }) => currentIllnessDate),
    ),
  ];

  const datesOfCurrentIllness = uniqueDatesOfCurrentIllness
    .reduce((dates, current) => {
      const date = parseDate(current);
      return date && date.isValid() && !date.isSame(onsetOfSymptomsDate)
        ? [...dates, date]
        : dates;
    }, [])
    .sort();

  return datesOfCurrentIllness.map(date => ({
    name: 'dateOfCurrentIllness',
    qualifier: DATE_OF_CURRENT_ILLNESS_QUALIFIER.dateOfCurrentIllness.value,
    date,
  }));
};

const getRadioDatePickerItems = async (state, model) => {
  const patientCase = state.patientCaseId
    ? await apiPatientCases.fetchOne(state.patientId, state.patientCaseId)
    : {};

  const onsetSymptomsDate = patientCase.onsetSymptomsDate
    ? parseDate(patientCase.onsetSymptomsDate)
    : null;

  const items = [
    ...(await getDateOfCurrentIllnessItems(
      onsetSymptomsDate,
      state.claimCharges,
    )),
    ...getOnsetSymptomsDateItem(onsetSymptomsDate),
    {
      name: 'pregnancyLastMenstrualPeriod',
      qualifier:
        DATE_OF_CURRENT_ILLNESS_QUALIFIER.pregnancyLastMenstrualPeriod.value,
      date: null,
      showDatePicker: true,
    },
    {
      name: 'none',
      qualifier: '',
      date: null,
    },
  ];

  const selectedOptionExists = items.find(
    ({ qualifier, date }) =>
      model.dateOfCurrentIllnessQualifier === qualifier &&
      model.dateOfCurrentIllness &&
      parseDate(model.dateOfCurrentIllness).isSame(date),
  );

  return !selectedOptionExists &&
    model.dateOfCurrentIllnessQualifier ===
      DATE_OF_CURRENT_ILLNESS_QUALIFIER.onsetOfSymptoms.value
    ? [
        {
          name: 'onsetOfSymptoms',
          qualifier: model.dateOfCurrentIllnessQualifier,
          date: parseDate(model.dateOfCurrentIllness),
        },
        ...items,
      ]
    : items;
};

const NAME_TO_KEY = Object.freeze({
  dateOfCurrentIllness: 'date',
  dateOfCurrentIllnessQualifier: 'qualifier',
});

function setEditedField({ result, state, formService }) {
  Object.entries(NAME_TO_KEY).forEach(([name, key]) => {
    if (state[name] !== result[key]) {
      formService.apply(`claimEditedField.${name}`, true);
    }
  });
}

export default async ({
  claimCellData,
  state,
  model,
  handlers,
  formService,
}) => {
  const currentDate = state.dateOfCurrentIllness
    ? parseDate(state.dateOfCurrentIllness)
    : null;

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_RADIO_DATE_PICKER, {
    title: claimCellData.title,
    tooltipText: claimCellData.tooltipText,
    qualifier: state.dateOfCurrentIllnessQualifier || '',
    date: currentDate,
    items: await getRadioDatePickerItems(state, model),
  });

  if (result) {
    setEditedField({ result, state, formService });

    handlers.change({
      name: 'dateOfCurrentIllness',
      value: result.date ? parseDate(result.date).format() : null,
    });

    handlers.change({
      name: 'dateOfCurrentIllnessQualifier',
      value: result.qualifier,
    });
  }
};
