import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../cards/neb-practice-location-card';
import { html, css, LitElement } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  layoutStyles,
} from '../../../styles';

export const ELEMENTS = {
  location: { selector: '[id^=location-]' },
  clientId: { id: 'client-id' },
};

class NebPracticeDetailsSummary extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          padding: 0 ${CSS_SPACING};
        }

        .grid {
          padding: ${CSS_SPACING};
          border: 0;
          border-radius: 5px;
          border: ${CSS_BORDER_GREY_2};
        }

        .header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: ${CSS_FONT_SIZE_BODY};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.model = {
      name: '',
      locations: [],
      phoneNumber: '',
      email: '',
      salesforceId: '',
    };
  }

  initHandlers() {
    this.handlers = {
      openLocation: location => this.__openItemOverlay(location),
    };
  }

  async __openItemOverlay(item) {
    const { billingInfo, locations } = this.model;
    await openOverlay(OVERLAY_KEYS.PRACTICE_LOCATION, {
      context: {
        billingInfo,
        locations,
        readOnly: true,
      },
      item,
    });
  }

  __renderLocation(location, index) {
    return html`
      <neb-practice-location-card
        id="location-${index}"
        .item="${location}"
        .onClick="${this.handlers.openLocation}"
      ></neb-practice-location-card>
    `;
  }

  __renderClientId(index) {
    return index === 0
      ? html`
          <div class="grid">
            <div class="header">Client ID</div>

            <div id="${ELEMENTS.clientId.id}">
              ${this.model.salesforceId || 'No ID found'}
            </div>
          </div>
        `
      : html` <div></div> `;
  }

  render() {
    const { locations } = this.model;
    return html`
      <div class="container">
        ${locations.map(
          (location, index) => html`
            ${this.__renderLocation(location, index)}
            ${this.__renderClientId(index)}
          `,
        )}
      </div>
    `;
  }
}

customElements.define(
  'neb-practice-details-summary',
  NebPracticeDetailsSummary,
);
