import '../../neb-action-bar';
import '../../neb-popup-header';
import '../../tables/neb-table-payer-plans';

import { html, css } from 'lit';

import { fetchClearinghousePayerPlan } from '../../../../../../src/api-clients/clearinghouse';
import { layoutStyles } from '../../../../../../src/styles';
import * as payerPlansApi from '../../../../../neb-api-client/src/payer-plan-api-client';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { FetchService } from '../../../../../neb-utils/services/fetch';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  search: { id: 'search' },
  table: { id: 'table' },
  titleMessage: { id: 'title-message' },
  pagination: { id: 'pagination' },
  actionBar: { id: 'action-bar' },
};

export const CLEARINGHOUSE_MESSAGE =
  'Select payer plans to associate with the clearinghouse.';

const __getPayerPlans = async query => {
  const { payerPlan, count } = await payerPlansApi.getPayerPlans(query);
  return {
    data: payerPlan,
    count,
  };
};
class NebOverlayAddPayerPlans extends Overlay {
  static get properties() {
    return {
      __selectedItems: Array,
      __tableState: Object,
      __titleMessage: String,
      __clearinghousePayerPlans: Array,
    };
  }

  initState() {
    super.initState();

    this.__selectedItems = [];
    this.__masterCount = 0;
    this.__titleMessage = '';
    this.__tableState = FetchService.createModel();
    this.__clearinghousePayerPlans = [];
    this.__service = new FetchService(
      {
        onChange: state => {
          this.__tableState = state;
        },
      },
      __getPayerPlans,
    );

    this.model = { items: [], clearinghouseId: '' };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      apply: () => {
        const items = this.__selectedItems.filter(
          item => !this.model.items.some(modelItem => modelItem.id === item.id),
        );

        const uniqItems = [...new Set(items)];

        this.onClose({
          items: uniqItems,
          clearinghousePayerPlans: this.__clearinghousePayerPlans,
        });
      },
      addItem: index => {
        const item = this.__tableState.pageItems[index];

        this.__selectedItems = [...this.__selectedItems, item];
      },
      search: ({ value }) => this.__service.search(value),
      clearSearch: () => this.__service.search(''),
      selectPage: pageIndex => this.__service.setPageIndex(pageIndex),
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__service.setQuery('payerClearinghouseAvailability', true);

    const res = await __getPayerPlans({
      payerClearinghouseAvailability: true,
    });

    if (this.model.clearinghouseId) {
      this.__clearinghousePayerPlans = await fetchClearinghousePayerPlan(
        this.model.clearinghouseId,
      );
    }

    this.__masterCount = res.count;
    this.__service.fetch();
  }

  getTableItems() {
    return this.__tableState.pageItems.map(item => ({
      added: this.__selectedItems.some(({ id }) => id === item.id),
      ...item,
    }));
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__selectedItems = this.model.items;
      this.__titleMessage = this.model.titleMessage;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .content {
          padding-top: ${CSS_SPACING};
          width: 880px;
        }

        .scroll {
          overflow-y: auto;
          height: 100%;
          min-height: 0;
          flex: 1 0 0;
        }
      `,
    ];
  }

  __renderPagination() {
    const pageCount = Math.ceil(this.__service.getFilterCount() / 10);

    return pageCount > 1
      ? html`
          <div class="grid grid-auto-right pad">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              .pageCount="${pageCount}"
              .currentPage="${this.__tableState.pageIndex}"
              .onPageChanged="${this.handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderContent() {
    const emptyMessage = this.__masterCount
      ? 'No results.'
      : 'There are no payer plans to associate with the clearinghouse.';

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="pad"
        title="Add Payer Plans"
        .onCancel="${this.handlers.apply}"
        showCancelButton
      ></neb-popup-header>

      <div id="${ELEMENTS.titleMessage.id}" class="grid grid-lean scroll">
        <div class="pad">
          ${this.__titleMessage ? this.__titleMessage : CLEARINGHOUSE_MESSAGE}
        </div>

        <neb-textfield
          id="${ELEMENTS.search.id}"
          class="pad"
          label=" "
          helper=" "
          placeholder="Enter alias, payer ID, or payer name to filter list below."
          leadingIcon="neb:search"
          .trailingIcon="${this.__tableState.searchText ? 'neb:clear' : ''}"
          .value="${this.__tableState.searchText}"
          .onChange="${this.handlers.search}"
          .onClickIcon="${this.handlers.clearSearch}"
        ></neb-textfield>

        <neb-table-payer-plans
          id="${ELEMENTS.table.id}"
          .model="${this.getTableItems()}"
          .emptyMessage="${emptyMessage}"
          .onAddButtonClick="${this.handlers.addItem}"
          .showStatus="${false}"
          showAddButton
        ></neb-table-payer-plans>

        ${this.__renderPagination()}
      </div>

      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel=""
        cancelLabel="Done"
        .onCancel="${this.handlers.apply}"
      ></neb-action-bar>
    `;
  }
}

customElements.define('neb-overlay-add-payer-plans', NebOverlayAddPayerPlans);
