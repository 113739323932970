import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonSave: { id: 'save' },
  buttonCancel: { id: 'cancel' },
  originalReferenceNumberTextfield: {
    id: 'original-reference-number-textfield',
  },
};

class NebPopupOriginalReferenceNumber extends NebPopup {
  static get properties() {
    return {
      __error: Boolean,
      __originalReferenceNumber: String,
    };
  }

  initState() {
    super.initState();

    this.title = 'FL 22 - Original Reference Number';

    this.__error = false;
    this.__originalReferenceNumber = '';
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      change: e => {
        this.__originalReferenceNumber = e.value;
        this.__error = !this.__originalReferenceNumber;
      },
      confirm: () => {
        this.onClose({
          originalReferenceNumber: this.__originalReferenceNumber,
        });
      },
      cancel: () => {
        this.onClose(false);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 370px;
        }

        .content {
          margin-top: 0;
        }

        .spacing-button {
          margin-right: 10px;
        }

        .container-buttons {
          margin-top: ${CSS_SPACING};
          display: flex;
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }

        .text-input {
          width: 100%;
        }
      `,
    ];
  }

  __renderOriginalReferenceNumber() {
    return html`
      <neb-textfield
        id="${ELEMENTS.originalReferenceNumberTextfield.id}"
        class="text-input"
        label="Original Reference Number"
        name="originalReferenceNumber"
        maxLength="18"
        helper="Required"
        .value="${this.__originalReferenceNumber}"
        .error="${this.__error}"
        .onChange="${this.__handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderButtons() {
    return html`
      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.buttonSave.id}"
          class="spacing-button"
          role="${BUTTON_ROLE.CONFIRM}"
          label="Save"
          .onClick="${this.__handlers.confirm}"
          ?disabled="${!this.__originalReferenceNumber}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="grid padding-top">
        ${this.__renderOriginalReferenceNumber()}
      </div>
      ${this.__renderButtons()}
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHeader()}
        <div class="content">${this.renderContent()}</div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-original-reference-number',
  NebPopupOriginalReferenceNumber,
);
