import { store } from '../neb-redux-store';
import { ApiClientType } from '../utils';

import * as actions from './action-creator';

const baseUrl = 'encounters';

export const getEncounter = (encounterId, force = false) =>
  actions.createFetchSingleAction(
    'encounters',
    encounterId,
    `${baseUrl}/${encounterId}`,
    undefined,
    force,
  );

export const createEncounter = (appointmentId, openedBy, providerName) =>
  actions.createCreateItemsAction({
    name: 'encounters',
    url: baseUrl,
    item: {},
    query: {
      providerName,
      appointmentId,
      openedBy,
    },
  });

export const getEncounterForAppt = appointmentId =>
  actions.createFetchItemsAction(
    'encounters',
    baseUrl,
    {
      appointmentId,
    },
    undefined,
    true,
    ApiClientType.CORE,
  );

export const updateEncounter = (id, encounter) =>
  actions.createUpdateItemsAction({
    name: 'encounters',
    url: `${baseUrl}/${id}`,
    item: encounter,
  });

export const reopenEncounter = id =>
  actions.createUpdateItemsAction({
    name: 'encounters',
    url: `${baseUrl}/${id}/reopen`,
    item: {
      openedBy: store.getState().session.item.id,
    },
  });

export const deleteEncounter = id =>
  actions.createDeleteItemsAction({
    name: 'encounters',
    url: `${baseUrl}/${id}`,
  });
