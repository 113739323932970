import '../../neb-popup-header';
import '../../patients/neb-patient-summary-controller';

import { html, css } from 'lit';

import { mapServerModelToNextAppointment } from '../../../../../neb-api-client/src/mappers/next-appointment-mapper';
import * as nextAppointmentApi from '../../../../../neb-api-client/src/nextAppointment-api-client';
import { RequestAppointmentService } from '../../../../../neb-api-client/src/services/request-appointment';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../../neb-styles/neb-variables';
import { NebFormRequestNextAppointment } from '../../forms/neb-form-request-next-appointment';
import Overlay, { TRANSITION_SIDE } from '../neb-overlay';

export const BANNER_SUCCESS = 'Next appointment requested successfully';
const BANNER_ERROR = 'An error occurred when requesting next appointment';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  formRequest: {
    id: 'form-request',
  },
  patientSummary: {
    id: 'patient-summary',
  },
};

class NebOverlayRequestAppointment extends Overlay {
  static get properties() {
    return {
      __nextAppointment: Object,
    };
  }

  initState() {
    super.initState();

    this.__nextAppointment = NebFormRequestNextAppointment.createModel();

    this.layout = '';
    this.side = TRANSITION_SIDE.RIGHT;

    this.initServices();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.onDirtyChange = dirty => {
      this.isDirty = dirty;
    };

    this.handlers.save = async nextAppointment => {
      const reqKey = nextAppointment.id
        ? 'updateNextAppointment'
        : 'createNextAppointment';

      try {
        const result = await nextAppointmentApi[reqKey](
          nextAppointment,
          this.model.encounterId,
        );
        this.isDirty = false;
        this.dismiss(result);
        store.dispatch(openSuccess(BANNER_SUCCESS));
      } catch (e) {
        console.error(e);
        store.dispatch(openError(BANNER_ERROR));
      }
    };
  }

  initServices() {
    this.service = new RequestAppointmentService(nextAppointment => {
      if (nextAppointment) {
        this.__nextAppointment = {
          ...mapServerModelToNextAppointment(nextAppointment),
        };
      }
    });
  }

  firstUpdated() {
    this.service.fetch(this.model.encounterId);
    super.firstUpdated();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: row nowrap;
        }

        :host(:not([layout='large'])) .content {
          width: 100%;
        }

        .patient-summary {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING} ${CSS_SPACING};
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content-right {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .controller {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-patient-summary-controller
        id="${ELEMENTS.patientSummary.id}"
        class="patient-summary"
        .patientId="${this.model.initialPatientId}"
      >
      </neb-patient-summary-controller>

      <div class="content-right">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="Request Next Appointment"
          .onBack="${this.handlers.dismiss}"
          .onCancel="${this.handlers.dismiss}"
          .showCancelButton="${this.layout !== 'small'}"
        ></neb-popup-header>

        <neb-form-request-next-appointment
          id="${ELEMENTS.formRequest.id}"
          class="controller"
          .layout="${this.layout}"
          .model="${this.__nextAppointment}"
          .onCancel="${this.handlers.dismiss}"
          .onConfirm="${this.handlers.dismiss}"
          .onChangeDirty="${this.handlers.dirty}"
          .onSave="${this.handlers.save}"
        ></neb-form-request-next-appointment>
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-request-appointment',
  NebOverlayRequestAppointment,
);
