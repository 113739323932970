import { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});
export const headerJSON = { 'Content-Type': 'application/json' };

export function update(items) {
  return appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/cancel-reschedule-reasons',
    method: Method.PUT,
    headers: headerJSON,
    body: { cancelRescheduleReasons: items },
  });
}

export async function fetchMany() {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/cancel-reschedule-reasons',
    cacheKey: 'cancel-reschedule-reasons',
  });

  return res.data;
}
