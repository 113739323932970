export const APPOINTMENT_BILLING_TYPE = Object.freeze({
  SelfPay: 'selfPay',
  CarePackage: 'carePackage',
  Insurance: 'insurance',
});
export const APPOINTMENT_BILLING_TYPES = Object.values(
  APPOINTMENT_BILLING_TYPE,
);

const getGuarantorIdByBillType = billingInfo => {
  switch (billingInfo.billType) {
    case APPOINTMENT_BILLING_TYPE.SelfPay:
      return billingInfo.guarantorId;
    case APPOINTMENT_BILLING_TYPE.Insurance:
      return billingInfo.insuranceGuarantorId;
    default:
      return null;
  }
};

export const mapAppointmentBillingInfoToServerModel = (
  model,
  appointmentId,
) => ({
  id: model.id,
  appointmentId,
  billType: model.billType,
  caseBillTypeOverride: model.caseBillTypeOverride ? 1 : 0,
  guarantorId: getGuarantorIdByBillType(model),
  patientPackageId:
    model.billType === APPOINTMENT_BILLING_TYPE.CarePackage ||
    model.billType === APPOINTMENT_BILLING_TYPE.Insurance
      ? model.patientPackageId
      : null,
  primaryInsuranceId:
    model.billType === APPOINTMENT_BILLING_TYPE.Insurance
      ? model.primaryInsuranceId
      : null,
  secondaryInsuranceId:
    model.billType === APPOINTMENT_BILLING_TYPE.Insurance &&
    model.primaryInsuranceId
      ? model.secondaryInsuranceId
      : null,
  tertiaryInsuranceId:
    model.billType === APPOINTMENT_BILLING_TYPE.Insurance &&
    model.primaryInsuranceId &&
    model.secondaryInsuranceId
      ? model.tertiaryInsuranceId
      : null,
  feeScheduleId: model.feeScheduleId || null,
  patientCaseId: model.patientCaseId || null,
  patientAuthorizationId: model.patientAuthorizationId || null,
  multiCarePackage: model.multiCarePackage,
});

export const mapAppointmentBillingInfoToClientModel = model => ({
  id: model.id,
  billType: model.billType,
  caseBillTypeOverride: model.caseBillTypeOverride,
  guarantorId: model.guarantorId,
  patientPackageId: model.patientPackageId,
  insuranceGuarantorId: model.guarantorId,
  primaryInsuranceId: model.primaryInsuranceId,
  secondaryInsuranceId: model.secondaryInsuranceId,
  tertiaryInsuranceId: model.tertiaryInsuranceId,
  feeScheduleId: model.feeScheduleId,
  patientCaseId: model.patientCaseId,
  patientAuthorizationId: model.patientAuthorizationId || null,
  multiCarePackage: model.multiCarePackage,
});
