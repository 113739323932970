import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import { NebScrollThreshold } from '../services/neb-scroll-threshold';

import '../../../../src/components/misc/neb-calendar-agenda-item';

export const ELEMENTS = {
  container: { id: 'container' },
  cards: { selector: '[id^=card-]' },
};

class NebList extends LitElement {
  static get properties() {
    return {
      items: Array,
      scrollToIndex: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          height: 100%;
          overflow: hidden;
        }

        .container {
          position: relative;
          height: 100%;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__didScroll = false;
    this.__itemTops = [];
    this.__topVisibleIndex = 0;

    this.items = [];
    this.scrollToIndex = -1;

    this.onClickItem = () => {};

    this.onDisplayCard = () => {};

    this.onLowerThreshold = () => {};

    this.onRenderItem = () => {};

    this.onTopVisibleIndexChange = () => {};

    this.onUpperThreshold = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => this.onClickItem(e.target),
      displayCard: model => this.onDisplayCard(model),
      scroll: () => {
        // TODO - bcalisch: Look into Twitter performance fix for scrolling.
        this.__didScroll = true;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    window.setInterval(() => {
      if (this.__didScroll) {
        this.__didScroll = false;

        const container = this.shadowRoot.getElementById(ELEMENTS.container.id);
        const { scrollTop } = container;
        const index = this.__itemTops.findIndex(item => item > scrollTop) - 1;

        if (index !== this.__topVisibleIndex) {
          this.onTopVisibleIndexChange(index);
        }

        this.__topVisibleIndex = index;
      }
    }, 250);
  }

  async __recalculateItemTops() {
    const items = this.shadowRoot.querySelectorAll(ELEMENTS.cards.selector);
    await this.updateComplete;
    await this.updateComplete;

    if (items.length) {
      this.__itemTops = [...items].map(item => item.offsetTop);
    }
  }

  firstUpdated() {
    const container = this.shadowRoot.getElementById(ELEMENTS.container.id);

    this.__scrollThreshold = new NebScrollThreshold(container, {
      upperThreshold: 200,
      lowerThreshold: 200,
      onLowerThreshold: this.onLowerThreshold,
      onUpperThreshold: this.onUpperThreshold,
    });
  }

  async updated(changedProps) {
    if (changedProps.has('scrollToIndex') && this.scrollToIndex >= 0) {
      const card = this.shadowRoot.getElementById(`card-${this.scrollToIndex}`);

      if (card) {
        await card.updateComplete;
        await this.updateComplete;

        const container = this.shadowRoot.getElementById(ELEMENTS.container.id);

        container.style['-webkit-overflow-scrolling'] = 'auto';
        card.scrollIntoView();
        container.style['-webkit-overflow-scrolling'] = 'touch';
      }
    }

    if (changedProps.has('items')) await this.__recalculateItemTops();
  }

  __renderItems() {
    return this.items.map(
      (item, index) => html`
        <neb-calendar-agenda-item
          id="card-${index}"
          .eventItem="${item}"
          .onDisplayCard="${this.__handlers.displayCard}"
        ></neb-calendar-agenda-item>
      `,
    );
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
        @scroll="${this.__handlers.scroll}"
      >
        ${this.__renderItems()}
      </div>
    `;
  }
}

customElements.define('neb-list', NebList);
