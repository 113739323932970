import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-date-time';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import './neb-form-split-appointment';
import '../../../../packages/neb-lit-components/src/components/controls/neb-pill-switch';
import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary-controller';
import '../../misc/neb-appointment-calendar-view';
import '../../misc/neb-item-select-search';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import { css, html, LitElement } from 'lit';

import { APPOINTMENT_STATUS } from '../../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_BUTTON_SPACING_ROW,
  CSS_WARNING_COLOR,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { hasAuthorizationRemaining } from '../../../../packages/neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import { baseStyles } from '../../../styles';
import {
  getDurationDisplay,
  getPreviewAppointment,
  SELECT_BY_TYPE,
} from '../../../utils/scheduling/appointments';
import { DATE_FORMAT } from '../../misc/neb-scheduling-calendar';
import { EDIT_MODE } from '../../overlays/appointments/neb-overlay-edit-reschedule-appointment';

import { getSplitsDuration } from './neb-form-reschedule-controller';

export const ELEMENTS = {
  actionBar: {
    id: 'action-bar',
  },
  viewPatientSummary: {
    id: 'view-patient-summary',
  },
  containerHeader: {
    id: 'container-header',
  },
  iconClose: {
    id: 'icon-close',
  },
  selectInputProvider: {
    id: 'select-input-provider',
  },
  selectInputLocation: {
    id: 'select-input-location',
  },
  selectInputAppointmentType: {
    id: 'select-input-appointment-type',
  },
  dateTime: {
    id: 'date-time',
  },
  startDate: {
    id: 'start-date',
  },
  timeDuration: {
    id: 'time-duration',
  },
  selectRooms: {
    id: 'select-rooms',
  },
  addSplitButton: {
    id: 'add-split-button',
  },
  splitForm: {
    id: 'split-form',
  },
  pillSwitch: {
    id: 'pill-switch',
  },
  selectAuthorization: {
    id: 'select-authorization',
  },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
  textareaAppointmentNote: {
    id: '',
  },
  textareaRescheduleReason: {
    id: 'textarea-reschedule-reason',
  },
  selectInputRescheduleReason: {
    id: 'select-input-reschedule-reason',
  },
  textNoteReschedule: {
    id: 'text-note-reschedule',
  },
  containerRescheduleReason: {
    id: 'container-reschedule-reason',
  },
  tooltipAuth: {
    id: 'tooltip-auth',
  },
  tooltipCase: {
    id: 'tooltip-case',
  },
  buttonCase: {
    id: 'button-case',
  },
  caseSearch: { id: 'case-search' },
  calendarView: { id: 'calendar-view' },
  textHeader: { id: 'text-header' },
  errorStart: { id: 'error-start' },
  iconStartError: { id: 'icon-start-error' },
};

class NebFormRescheduleView extends LitElement {
  static get properties() {
    return {
      selectedProvider: { type: Object },
      selectedLocation: { type: Object },
      selectedAppointmentType: { type: Object },
      selectedDate: { type: Object },
      selectedDuration: { type: Object },
      selectedRoom: { type: Object },
      selectedCase: { type: Object },
      selectedAuthorization: { type: Object },
      appointmentNote: { type: String },
      selectedRescheduleReason: { type: Object },
      rescheduleNote: { type: String },
      selectByType: { type: String },
      selectedSplits: { type: Array },
      timeFrameAvailabilities: { type: Array },
      walkIn: { type: Boolean },
      mode: { type: String },
      providers: { type: Array },
      locations: { type: Array },
      appointmentTypes: { type: Array },
      rooms: { type: Array },
      cases: { type: Array },
      authorizations: { type: Array },
      rescheduleReasons: { type: Array },
      availability: { type: Object },
      calendarSummary: { type: Object },
      providerRequired: { type: Boolean },
      resourceRequired: { type: Boolean },
      confirmLabel: { type: String },
      patientId: { type: String },
      appointmentId: { type: String },
      layout: { type: String, reflect: true },
      colorView: { type: String },
      preventFetching: { type: Boolean },
      errors: { type: Object },
      status: { type: String },
      hasEditRescheduleFF: { type: Boolean },
      appointmentDragAndDropSettings: { type: Object },
      hasEncounter: { type: Boolean },
      hasClaims: { type: Boolean },
      hasChangeProviderAfterCheckInFF: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          overflow: hidden;
        }

        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .content {
          display: grid;
          overflow: auto;
          grid-template-columns:
            minmax(min-content, 1fr) minmax(0, max-content)
            minmax(200px, 800px);
        }

        .calendar-view {
          grid-column: 2;
          overflow-y: auto;
          display: flex;
          user-select: none;
        }

        :host([layout='small']) .content {
          display: grid;
          grid-template-columns: 1fr;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-content {
          grid-column: 3;
          overflow: hidden auto;
        }

        .content-summary {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          grid-column: 1;
        }

        .search {
          width: 100%;
        }

        .patient-summary {
          padding: ${CSS_SPACING};
        }

        :host([layout='small']) .patient-summary {
          padding: 0;
        }

        .content-right {
          padding: ${CSS_SPACING};
          display: grid;
        }

        .content-form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0px ${CSS_SPACING};
        }

        :host([layout='small']) .content-form {
          padding-top: ${CSS_SPACING};
        }

        .span-2 {
          grid-column: span 2;
        }

        .button-add-patient {
          padding: ${CSS_SPACING} 0 13px;
        }

        .button {
          padding: ${CSS_BUTTON_SPACING_ROW} 0;
        }

        .container-reschedule-reason {
          padding-top: ${CSS_SPACING};
        }

        .text-note-reschedule {
          margin-top: ${CSS_SPACING};
        }

        .container-header {
          display: grid;
          grid-template-columns: 1fr min-content;
          padding-bottom: ${CSS_SPACING};
        }

        .text-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;
        }

        .tooltip {
          padding-top: 16px;
          align-content: center;
        }

        .appointment-card-container {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-column-gap: ${CSS_SPACING};
          grid-column: span 2;
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }

        :host(:not([layout='large'])) .appointment-card-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-column: span 2;
        }

        .container-note {
          grid-column: span 2;
          padding-bottom: ${CSS_SPACING};
        }

        .appointment-card {
          height: 120px;
        }

        .date-time-page,
        .recurring-appointment-page {
          display: grid;
          grid-column: span 2;
          padding-top: ${CSS_SPACING_ROW};
        }

        .icon-authorization-warning {
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_WARNING_COLOR};
        }

        .icon-close {
          grid-column: 2;
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .spacer {
          height: ${CSS_SPACING};
        }

        .start-error-container {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-column: span 2;
          grid-gap: ${CSS_SPACING_ROW_LARGE};
          color: ${CSS_COLOR_ERROR};
          align-items: center;
        }

        .start-error-icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_ERROR};
        }

        .select {
          width: 100%;
          padding-top: 10px;
        }

        .select-row {
          display: flex;
          grid-column: span 2;
          gap: 20px;
          justify-content: space-between;
          align-items: center;
        }

        .neb-pill-switch {
          display: flex;
          height: 40px;
          margin-bottom: 10px;
        }

        .label {
          margin: 0;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        neb-textarea {
          padding-top: 10px;
        }

        .split-icon {
          width: 24px;
          align-self: center;
          height: 24px;
          margin-top: 10px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedProvider = ITEM_EMPTY;
    this.selectedLocation = ITEM_EMPTY;
    this.selectedAppointmentType = ITEM_EMPTY;
    this.selectedDate = {};
    this.selectedDuration = {};
    this.selectedRoom = ITEM_EMPTY;
    this.selectedCase = ITEM_EMPTY;
    this.appointmentDragAndDropSettings = {};
    this.selectedAuthorization = ITEM_EMPTY;
    this.appointmentNote = '';
    this.selectedRescheduleReason = ITEM_EMPTY;
    this.rescheduleNote = '';
    this.selectByType = SELECT_BY_TYPE.DATE;
    this.selectedSplits = [];
    this.timeFrameAvailabilities = [];
    this.walkIn = false;
    this.status = APPOINTMENT_STATUS.ACTIVE;
    this.mode = EDIT_MODE.RESCHEDULE;
    this.providers = [];
    this.locations = [];
    this.appointmentTypes = [];
    this.rooms = [];
    this.cases = [];
    this.authorizations = [];
    this.rescheduleReasons = [];
    this.availability = {};
    this.calendarSummary = {};
    this.providerRequired = false;
    this.resourceRequired = false;
    this.confirmLabel = 'Save';
    this.patientId = '';
    this.appointmentId = '';
    this.hasEncounter = false;
    this.errors = {};
    this.hasEditRescheduleFF = false;
    this.hasClaims = false;
    this.hasChangeProviderAfterCheckInFF = false;

    this.renderFooter = () => '';

    this.onCancel = () => {};

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onToggleMode = () => {};

    this.onAddCase = () => {};

    this.onAuthorizationWarningClick = () => {};

    this.onAddSplit = () => {};

    this.onChangeSplit = () => {};

    this.onChangeAppointmentRoom = () => {};

    this.onRemoveSplit = () => {};

    this.onRemoveSplits = () => {};

    this.onChangeDate = () => {};

    this.onChangeMonth = () => {};

    this.onChangeDateType = () => {};

    this.onChangeDuration = () => {};

    this.onResizeUp = () => {};

    this.onUpdatePreventFetching = () => {};

    this.onUpdateCalendar = () => {};

    this.onToggleColorView = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onCancel(),
      change: (...args) => this.onChange(...args),
      toggleMode: () => this.onToggleMode(),
      addCase: () => this.onAddCase(),
      authorizationWarningClick: () => this.onAuthorizationWarningClick(),
      addSplit: () => this.onAddSplit(),
      changeSplit: e => this.onChangeSplit(e),
      changeAppointmentRoom: e => this.onChangeAppointmentRoom(e),
      removeSplit: e => this.onRemoveSplit(e),
      removeSplits: e => this.onRemoveSplits(e),
      changeDate: e => this.onChangeDate(e),
      changeMonth: e => this.onChangeMonth(e),
      changeDateType: e => this.onChangeDateType(e),
      changeDuration: e => this.onChangeDuration(e),
      resizeUp: e => this.onResizeUp(e),
      updatePreventFetching: e => this.onUpdatePreventFetching(e),
      updateCalendar: e => this.onUpdateCalendar(e),
      toggleColorView: e => this.onToggleColorView(e),
    };
  }

  __shouldDisable() {
    if (this.hasChangeProviderAfterCheckInFF) return this.hasClaims;

    return (
      this.status !== APPOINTMENT_STATUS.ACTIVE &&
      this.status !== APPOINTMENT_STATUS.NO_SHOW
    );
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.containerHeader.id}" class="container-header">
        <div id="${ELEMENTS.textHeader.id}" class="text-header">
          ${this.mode === EDIT_MODE.EDIT
            ? 'Edit Appointment'
            : 'Reschedule Appointment'}
        </div>

        <neb-icon
          id="${ELEMENTS.iconClose.id}"
          class="icon icon-close"
          icon="neb:close"
          @click="${this.__handlers.cancel}"
        ></neb-icon>
      </div>
    `;
  }

  __renderModeToggle() {
    return html`
      <neb-pill-switch
        id="${ELEMENTS.pillSwitch.id}"
        class="neb-pill-switch"
        .on="${this.mode === EDIT_MODE.EDIT}"
        .labels="${{
          leftSwitch: EDIT_MODE.EDIT,
          rightSwitch: EDIT_MODE.RESCHEDULE,
        }}"
        .onToggle="${this.__handlers.toggleMode}"
        ?disabled="${this.status === APPOINTMENT_STATUS.CHECKED_OUT}"
      ></neb-pill-switch>
    `;
  }

  __renderDetails() {
    const startDate = parseDate(this.selectedDate);
    const endDate = startDate.clone().add(this.selectedDuration, 'ms');
    const durationDisplay = getDurationDisplay(startDate, endDate);
    const startDisplayDate = `${startDate.format('dddd, MMMM D, YYYY')}${
      this.walkIn ? ' (Walk-In)' : ''
    }`;

    return html`
      <div class="container-date-time">
        <p id="${ELEMENTS.startDate.id}" class="label">${startDisplayDate}</p>

        <p id="${ELEMENTS.timeDuration.id}" class="label">${durationDisplay}</p>
      </div>
    `;
  }

  __renderDateTime() {
    return html`
      <neb-date-time
        id="${ELEMENTS.dateTime.id}"
        class="date-time-page"
        .availability="${this.availability}"
        .shouldRenderSelectBy="${this.mode === EDIT_MODE.RESCHEDULE}"
        .duration="${this.selectedDuration}"
        .hasSplits="${!!this.selectedSplits.length}"
        .layout="${this.layout}"
        .disableCalendarView="${this.__shouldDisable()}"
        .selectedDate="${parseDate(this.selectedDate)}"
        .type="${this.selectByType}"
        .enableWalkIn="${true}"
        .walkIn="${this.walkIn}"
        .timeFrameAvailabilities="${this.timeFrameAvailabilities}"
        .onChange="${this.__handlers.change}"
        .onChangeDate="${this.__handlers.changeDate}"
        .onChangeMonth="${this.__handlers.changeMonth}"
        .onChangeType="${this.__handlers.changeDateType}"
        .hasEditRescheduleFF="${this.hasEditRescheduleFF}"
      ></neb-date-time>

      ${this.errors.start && this.__availability
        ? html`
            <div id="${ELEMENTS.errorStart.id}" class="start-error-container">
              <neb-icon
                id="${ELEMENTS.iconStartError.id}"
                class="start-error-icon"
                icon="neb:warning"
              ></neb-icon>

              ${this.errors.start}
            </div>
          `
        : ''}
    `;
  }

  __shouldDisabledProviderSelect() {
    if (this.hasChangeProviderAfterCheckInFF) return this.__shouldDisable();

    const test = this.hasEncounter || this.__shouldDisable();

    return test;
  }

  __renderProvider() {
    const prop = 'selectedProvider';
    const helper = this.providerRequired ? 'Required' : ' ';

    return html`
      <neb-item-select-search
        id="${ELEMENTS.selectInputProvider.id}"
        class="select select-provider span-2"
        label="Provider"
        emptyMessage="No providers available"
        helper="${helper}"
        name="${prop}"
        .error="${this.errors.providerId}"
        .itemId="${this[prop]?.data.id}"
        .items="${this.providers}"
        .onChange="${this.__handlers.change}"
        ?disabled="${this.__shouldDisabledProviderSelect()}"
      >
      </neb-item-select-search>
    `;
  }

  __renderLocation() {
    const prop = 'selectedLocation';

    return html`
      <neb-item-select-search
        id="${ELEMENTS.selectInputLocation.id}"
        class="select select-location span-2"
        label="Location"
        helper="Required"
        emptyMessage="No locations available"
        name="${prop}"
        .items="${this.locations}"
        .error="${this.errors.locationId}"
        .itemId="${this[prop]?.data.id}"
        .onChange="${this.__handlers.change}"
        ?disabled="${!this.locations.length}"
      >
      </neb-item-select-search>
    `;
  }

  __renderRoom() {
    const prop = 'selectedRoom';
    const helper = this.resourceRequired ? 'Required' : ' ';
    const disabled = this.resourceRequired
      ? this.rooms.length < 1
      : this.rooms.length < 2;

    return !this.selectedSplits.length
      ? html`
          <div class="select-row">
            <neb-select
              id="${ELEMENTS.selectRooms.id}"
              class="select select-room span-2"
              label="Scheduled Room"
              name="${prop}"
              helper="${helper}"
              .value="${this[prop]}"
              .items="${this.rooms}"
              .error="${this.errors.resourceId}"
              .onChange="${this.__handlers.change}"
              ?disabled="${disabled}"
            ></neb-select>

            <neb-button-icon
              id="${ELEMENTS.addSplitButton.id}"
              class="split-icon"
              icon="neb:splitAppointment"
              .onClick="${this.__handlers.addSplit}"
              ?disabled="${disabled}"
            ></neb-button-action>
          </div>
        `
      : this.__renderSplits();
  }

  __renderSplits() {
    return html`
      <neb-form-split-appointment
        id="${ELEMENTS.splitForm.id}"
        class="span-2"
        name="selectedSplits"
        layout="${this.layout}"
        .errors="${{
          splits: this.errors.splits,
        }}"
        .appointmentRoom="${this.selectedRoom}"
        .rooms="${this.rooms.filter(room => room.data.id)}"
        .splits="${this.selectedSplits}"
        .totalDuration="${getSplitsDuration(this.selectedSplits)}"
        .enableConflictCheck="${this.mode === EDIT_MODE.RESCHEDULE}"
        .onAddSplit="${this.__handlers.addSplit}"
        .onChange="${this.__handlers.changeSplit}"
        .onChangeAppointmentRoom="${this.__handlers.changeAppointmentRoom}"
        .onRemoveSplit="${this.__handlers.removeSplit}"
        .onRemoveSplits="${this.__handlers.removeSplits}"
      ></neb-form-split-appointment>
    `;
  }

  __renderAppointmentType() {
    const prop = 'selectedAppointmentType';

    return html`
      <neb-item-select-search
        id="${ELEMENTS.selectInputAppointmentType.id}"
        class="select select-appointment-type span-2"
        label="Appointment Type"
        helper="Required"
        emptyMessage="No appointment types available"
        .items="${this.appointmentTypes}"
        .name="${prop}"
        .itemId="${this[prop]?.data.id}"
        .error="${this.errors.appointmentTypeId}"
        .onChange="${this.__handlers.change}"
        ?disabled="${!this.appointmentTypes.length}"
      >
      </neb-item-select-search>
    `;
  }

  __renderCase() {
    const prop = 'selectedCase';

    return html`
      <neb-select
        id="${ELEMENTS.caseSearch.id}"
        class="select select-appointment-type"
        name="${prop}"
        label="Case"
        addNewItemLabel="Case"
        .items="${this.cases}"
        .value="${this[prop]}"
        .onChange="${this.__handlers.change}"
        .onAddNewItem="${this.__handlers.addCase}"
        ?disabled="${this.__shouldDisable()}"
        wrapText
      ></neb-select>
    `;
  }

  __renderAuthorization() {
    const prop = 'selectedAuthorization';

    return html`
        <neb-select
          id="${ELEMENTS.selectAuthorization.id}"
          class="select select-authorization span-2"
          label="Authorization"
          name="${prop}"
          .value="${this[prop]}"
          .items="${this.authorizations}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.__shouldDisable()}"
        ></neb-select>

        ${
          !hasAuthorizationRemaining(this[prop]?.data)
            ? html`
                <div class="tooltip">
                  <neb-icon
                    id="${ELEMENTS.iconAuthorizationWarning.id}"
                    class="icon-authorization-warning"
                    icon="neb:warning"
                    @click="${this.__handlers.authorizationWarningClick}"
                  ></neb-icon>
                </div>
              `
            : ''
        }
      </div>
    `;
  }

  __renderAppointmentNote() {
    const prop = 'appointmentNote';

    return html`
      <neb-textarea
        id="${ELEMENTS.textareaAppointmentNote.id}"
        class="span-2"
        label="Appointment Note"
        name="${prop}"
        .value="${this[prop]}"
        .onChange="${this.__handlers.change}"
      ></neb-textarea>
    `;
  }

  __renderRescheduleReason() {
    const prop = 'selectedRescheduleReason';

    if (this.rescheduleReasons.length < 2 || this.mode === EDIT_MODE.EDIT) {
      return '';
    }

    return html`
      <neb-select
        id="${ELEMENTS.selectInputRescheduleReason.id}"
        class="select span-2"
        label="Reschedule Reason"
        .items="${this.rescheduleReasons}"
        name="${prop}"
        .value="${this[prop]}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderRescheduleNote() {
    const prop = 'rescheduleNote';

    if (this.mode === EDIT_MODE.EDIT) return '';

    return html`
      <neb-textarea
        id="${ELEMENTS.textNoteReschedule.id}"
        class="span-2"
        label="Reschedule Note"
        name="${prop}"
        .value="${this[prop]}"
        .onChange="${this.__handlers.change}"
        maxLength="500"
        showCount
      >
      </neb-textarea>
    `;
  }

  __renderPatientSummary() {
    return html`
      <div class="content-summary">
        <neb-patient-summary-controller
          id="${ELEMENTS.viewPatientSummary.id}"
          class="patient-summary"
          .layout="${this.layout}"
          .patientId="${this.patientId}"
        >
        </neb-patient-summary-controller>
      </div>
    `;
  }

  __renderCalendarColumn() {
    const start = this.selectedDate && parseDate(this.selectedDate);

    if (
      !start ||
      !this.selectedLocation?.data.id ||
      !(this.selectedProvider?.data.id || this.selectedRoom?.data.id)
    ) {
      return '';
    }

    const previewAppointment = getPreviewAppointment(
      this.appointmentId,
      start,
      this.selectedDuration,
      this.selectedAppointmentType,
    );

    return html`
      <neb-appointment-calendar-view
        id="${ELEMENTS.calendarView.id}"
        class="calendar-view"
        .layout="${this.layout}"
        .appointment="${previewAppointment}"
        .model="${this.calendarSummary}"
        .start="${start}"
        .targetDate="${start.format(DATE_FORMAT)}"
        .providers="${this.providers}"
        .rooms="${this.rooms}"
        .locations="${this.locations}"
        .appointmentDragAndDropSettings="${this.appointmentDragAndDropSettings}"
        .providerId="${this.selectedProvider.data.id}"
        .locationId="${this.selectedLocation.data.id}"
        .resourceId="${this.selectedRoom.data.id}"
        .colorView="${this.colorView}"
        .hasSplits="${!!this.selectedSplits.length}"
        .isResizing="${this.preventFetching}"
        .onClickTimeSlot="${this.__handlers.changeDate}"
        .onResizeDown="${this.__handlers.changeDuration}"
        .onResizeUp="${this.__handlers.resizeUp}"
        .onUpdateResizing="${this.__handlers.updatePreventFetching}"
        .onUpdateCalendar="${this.__handlers.updateCalendar}"
        .onToggleColorView="${this.__handlers.toggleColorView}"
      ></neb-appointment-calendar-view>
    `;
  }

  __renderContent() {
    return html`
      ${this.__renderDetails()} ${this.__renderModeToggle()}
      ${this.__renderDateTime()} ${this.__renderProvider()}

      <div class="select-row">
        ${this.__renderLocation()} ${this.__renderAppointmentType()}
      </div>

      ${this.__renderRoom()}

      <div class="select-row">
        ${this.__renderCase()} ${this.__renderAuthorization()}
      </div>

      ${this.__renderAppointmentNote()} ${this.__renderRescheduleReason()}
      ${this.__renderRescheduleNote()}
    `;
  }

  __renderSmall() {
    return html`
      <div class="content">
        ${this.__renderHeader()} ${this.__renderPatientSummary()}

        <div class="content-form">${this.__renderContent()}</div>
      </div>
    `;
  }

  __renderBig() {
    return html`
      <div class="content">
        ${this.__renderPatientSummary()} ${this.__renderCalendarColumn()}

        <div class="container-content">
          <div class="content-right">
            ${this.__renderHeader()}
            <div class="content-form">${this.__renderContent()}</div>
          </div>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
    `;
  }
}

window.customElements.define('neb-form-reschedule-view', NebFormRescheduleView);
