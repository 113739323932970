export class PageNavigator {
  constructor(number = 0, count = 0) {
    this.__pageNumber = number;
    this.__pageCount = count;
  }

  get pageNumber() {
    return this.__pageNumber;
  }

  goto(number) {
    if (this.__validatePageNumber(number)) {
      this.__pageNumber = number;
    }
  }

  reset(count) {
    if (count >= 0) {
      this.__pageCount = count;
      this.__pageNumber = this.__pageCount > 0 ? 1 : 0;
    }
  }

  get pageCount() {
    return this.__pageCount;
  }

  next() {
    if (this.__pageNumber < this.__pageCount) {
      this.__pageNumber++;
    }
  }

  previous() {
    if (this.__pageNumber > 1) {
      this.__pageNumber--;
    }
  }

  __validatePageNumber(number) {
    return (
      (this.__pageCount > 0 && number > 0 && number <= this.pageCount) ||
      (this.__pageCount === 0 && number === 0)
    );
  }
}
