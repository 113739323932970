/* istanbul ignore file */
// Above 'ignore' is temporary - TODO write comprehensive Unit Tests

import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const apiClient = new DeprecatedApiClient({ microservice: 'conversion' });

const getConversions = () =>
  apiClient.makeRequest({
    path: 'conversions',
    optOutLoadingIndicator: false,
  });

const postConversion = ({ tenantId, ...rest }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(rest),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: `tenants/${tenantId}/conversions`,
  });

const putConversionSshKey = ({ tenantId, id }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `tenants/${tenantId}/conversions/${id}/ssh-key`,
  });

const getConversionJobs = ({ tenantId, id }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `tenants/${tenantId}/conversions/${id}/jobs`,
  });

const getConversionSubJobs = ({ conversionId, id, tenantId }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `tenants/${tenantId}/conversions/${conversionId}/jobs/${id}/subJobs`,
  });

const copyConversion = ({ tenantId, conversionId, copyTenantId, copyName }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({
      copyTenantId: `${copyTenantId}`,
      copyName: `${copyName}`,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: `tenants/${tenantId}/conversions/${conversionId}/copy`,
  });

const deleteConversion = ({ tenantId, id }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `tenants/${tenantId}/conversions/${id}`,
    responseType: RESPONSE_TYPE.RAW,
  });

export {
  apiClient,
  copyConversion,
  deleteConversion,
  getConversionJobs,
  getConversionSubJobs,
  getConversions,
  postConversion,
  putConversionSshKey,
};
