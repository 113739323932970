import '../../../../../neb-lit-components/src/components/problem-list/neb-active-problems-panel';
import '../../../../../neb-treatment-plan/components/neb-active-treatment-plan';
import '../../../../../neb-lit-components/src/components/associates/neb-associates';
import '../../../../../neb-lit-components/src/components/recent-encounters/neb-recent-encounters';

import { LitElement, html, css } from 'lit';

import { LayoutService } from '../../../../../neb-redux/services/layout';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  problemList: {
    id: 'problem-list',
  },
  recentEncountersList: {
    id: 'recent-encounters-list',
  },
  treatmentPlan: {
    id: 'treatment-plan',
  },
};

class NebPatientChartController extends LitElement {
  static get properties() {
    return {
      layoutSize: {
        type: String,
        reflect: true,
      },
      activeProblems: Array,
      recentEncounters: {
        type: Array,
      },
      patientId: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layoutSize = '';
    this.activeProblems = [];
    this.recentEncounters = [];
    this.__layoutService = new LayoutService(layoutSize => {
      this.layoutSize = layoutSize;
    });

    this.__initCallbacks();
  }

  __initCallbacks() {
    this.onAddProblem = () => {};

    this.onNavigateToProblemList = () => {};

    this.onNavigateToEncounters = () => {};

    this.onNavigateToTreatmentPlan = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addProblem: () => this.onAddProblem(),
      sectionClicked: () => this.onNavigateToProblemList(),
      recentEncountersClicked: () => this.onNavigateToEncounters(),
      navigateToTreatmentPlan: () => this.onNavigateToTreatmentPlan(),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__layoutService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__layoutService.disconnect();
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        cursor: pointer;
      }

      .column > * {
        border-radius: 5px;
      }

      :host([layoutsize='small']) {
        flex-direction: column;
      }

      .column {
        flex: 1 0 0;
        margin: 0 ${CSS_SPACING};
      }

      :host([layoutsize='small']) .column {
        margin: 0;
      }
      .column:first-child {
        margin-right: 0;
      }
    `;
  }

  render() {
    return html`
      <div class="column">
        <neb-active-problems-panel
          id="${ELEMENTS.problemList.id}"
          layoutsize="${this.layoutSize}"
          .activeProblems="${this.activeProblems}"
          .onAddProblem="${this.__handlers.addProblem}"
          .onSectionClicked="${this.__handlers.sectionClicked}"
        ></neb-active-problems-panel>
        <neb-active-treatment-plan
          id="${ELEMENTS.treatmentPlan.id}"
          .patientId="${this.patientId}"
          @click="${this.__handlers.navigateToTreatmentPlan}"
        ></neb-active-treatment-plan>
        <neb-associates></neb-associates>
      </div>
      <div class="column">
        <neb-recent-encounters
          id="${ELEMENTS.recentEncountersList.id}"
          .layout="${this.layoutSize}"
          .recentEncounters="${this.recentEncounters}"
          @click="${this.__handlers.recentEncountersClicked}"
        ></neb-recent-encounters>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-chart-controller',
  NebPatientChartController,
);
