import { getActiveProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { getCategories } from '../../../api-clients/hot-buttons';
import { ApplyHotButtons } from '../../../components/controls/field-groups/neb-apply-hot-buttons';
import {
  saveModel,
  loadModel,
} from '../../../store/reducers/hot-buttons-reducer';
import { getDefaultProvider } from '../../../utils/hot-buttons';

import { safeRequest } from './safe-request';

export class NebHotButtonDataController {
  constructor(
    host,
    {
      categoryType,
      onHasHotButtonItems = () => {},
      onDoesNotHaveHotButtonItems = () => {},
    },
  ) {
    host.addController(this);
    this.host = host;
    this.__categoryType = categoryType;
    this.onHasHotButtonItems = onHasHotButtonItems;
    this.onDoesNotHaveHotButtonItems = onDoesNotHaveHotButtonItems;

    this.__initState();
  }

  __initState() {
    this.__hotButtonModel = ApplyHotButtons.createModel();

    this.__state = this.__getState({});
  }

  get hotButtonModel() {
    return this.__hotButtonModel;
  }

  updateHotButtonModel({
    selectedCategory = null,
    selectedProvider = null,
    hotButtonItems = null,
  }) {
    let changed = false;

    if (selectedCategory !== null) {
      this.__hotButtonModel.selectedCategory = selectedCategory;
      changed = true;
    }

    if (selectedProvider !== null) {
      this.__hotButtonModel.selectedProvider = selectedProvider;
      changed = true;
    }

    if (hotButtonItems !== null) {
      this.__hotButtonModel.hotButtonItems = hotButtonItems;
      changed = true;
    }

    if (changed) {
      const { encounterId } = this.__state;

      saveModel({
        store,
        encounterId,
        categoryType: this.__categoryType,
        model: { ...this.__hotButtonModel },
      });

      this.host.requestUpdate();
    }
  }

  __updateHotButtons(data) {
    this.__hotButtonModel = {
      ...data,
    };

    if (this.__hotButtonModel.hotButtonItems.length) {
      this.onHasHotButtonItems();
    } else {
      this.onDoesNotHaveHotButtonItems();
    }

    this.host.requestUpdate();
  }

  __loadHotButtonModel() {
    const { providerId, encounterId } = this.__state;

    if (providerId && encounterId) {
      const data = loadModel({
        encounterId,
        categoryType: this.__categoryType,
        store,
      });

      if (data) {
        this.__updateHotButtons(data);
        return;
      }

      this.__loadingPromise = safeRequest(async () => {
        const providerItems = (await getActiveProviderUsers()).map(item => ({
          id: item.id,
          label: objToName(item.name, DEFAULT_NAME_OPTS),
        }));

        const selectedProvider = getDefaultProvider(providerItems, providerId);

        const hotButtonItems = selectedProvider
          ? (
              await getCategories(
                this.__categoryType,
                selectedProvider.id,
                true,
              )
            ).map(item => ({
              ...item,
              label: item.name,
            }))
          : [];

        const model = {
          providerItems,
          hotButtonItems,
          selectedProvider,
          selectedCategory: hotButtonItems[0] || '',
          categoryType: this.__categoryType,
        };

        saveModel({
          store,
          encounterId,
          categoryType: this.__categoryType,
          model,
        });

        this.__updateHotButtons(model);
      }, 'Hot Buttons Data');
    }
  }

  __getState(encounter) {
    return {
      encounterId: encounter.id,
      providerId: encounter.providerId,
    };
  }

  __isStateChanged(state) {
    return JSON.stringify(state) !== JSON.stringify(this.__state);
  }

  hostUpdate() {
    const { encounter } = this.host;
    if (!encounter) return;

    const state = this.__getState(encounter);

    if (this.__isStateChanged(state)) {
      this.__state = {
        ...state,
      };

      this.__loadHotButtonModel();
    }
  }
}
