import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonClose: {
    id: 'close-button',
  },

  textMessage: {
    id: 'text-message',
  },
};
export const TIMEOUT_TITLE = 'Logged Out';
const TIMEOUT_MESSAGE = 'Your session was ended due to lack of activity.';
const CLOSE_TEXT = 'close';

class NebPopupTimeout extends NebPopup {
  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.showCancelButton = false;
    this.title = TIMEOUT_TITLE;
    this.message = TIMEOUT_MESSAGE;
  }

  __initHandlers() {
    super.initHandlers();
    this.__handlers = {
      close: () => this.onClose(),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 450px;
          font-family: ${CSS_FONT_FAMILY};
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .container {
          height: auto;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonClose.id}"
            class="button"
            .label="${CLOSE_TEXT}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.close}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-timeout', NebPopupTimeout);
