import { LitElement, html } from 'lit';

const PAGE_PADDING = 10;
export const ELEMENTS = {
  contentContainer: {
    id: 'pdf-content-container',
  },
  canvas: {
    id: 'pdf-canvas',
  },
  page: {
    id: 'page',
  },
};
export class NebPdfViewerContent extends LitElement {
  static get properties() {
    return {
      isRenderPdf: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.isRenderPdf = false;

    this.onRenderPdf = () => {};

    this.onResize = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      resize: () => this.onResize(this.__getSize()),
    };
  }

  __getSize() {
    return {
      width: this.__elements.pdfViewerContainer.clientWidth - PAGE_PADDING * 2,
      height:
        this.__elements.pdfViewerContainer.clientHeight - PAGE_PADDING * 2,
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.__handlers.resize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.__handlers.resize);
  }

  firstUpdated() {
    this.__elements = {
      canvas: this.shadowRoot.getElementById(ELEMENTS.canvas.id),
      pdfViewerContainer: this.shadowRoot.getElementById(
        ELEMENTS.contentContainer.id,
      ),
      pdfPage: this.shadowRoot.getElementById(ELEMENTS.page.id),
    };

    this.__handlers.resize();
  }

  async __renderPdf() {
    await this.onRenderPdf({
      canvas: this.__elements.canvas,
    });
  }

  updated(changedProps) {
    if (changedProps.has('isRenderPdf') && this.isRenderPdf) {
      this.__renderPdf();
    }
  }

  __renderStyles() {
    return html`
      <style>
        .content-container {
          background-color: grey;
          display: flex;
        }

        .pdf {
          height: calc(100% - 50px);
          width: 100%;
          align-items: flex-start;
          align-self: center;
          overflow: scroll;
        }

        .page {
          display: grid;
          grid-template-areas: '. a a .' '. a a .';
          grid-auto-columns: auto;
          text-align: center;
          width: auto;
          height: auto;
          padding: ${PAGE_PADDING}px;
          margin: 0 auto;
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div id="${ELEMENTS.contentContainer.id}" class="content-container pdf">
        <div id="${ELEMENTS.page.id}" class="page">
          <canvas id="${ELEMENTS.canvas.id}"></canvas>
        </div>
      </div>
    `;
  }
}
customElements.define('neb-pdf-viewer-content', NebPdfViewerContent);
