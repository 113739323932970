import { html } from 'lit';

import { parseDate } from './date-util';
import {
  FEDERAL_TAX_ID_TYPE,
  FEDERAL_TAX_ID_SOURCE,
  PROVIDER_TYPE,
  FL33_TYPES,
} from './enums';
import { formatPhoneNumber } from './formatters';
import { INSURED_RELATIONSHIP } from './patientInsurance';

export const CLAIM_STATUS = Object.freeze({
  SUBMITTING: 'Submitting',
  DOWNLOADED: 'Downloaded',
  UPLOADING_TO_CLEARINGHOUSE: 'Uploading to Clearinghouse',
  UPLOADED_TO_CLEARINGHOUSE: 'Uploaded to Clearinghouse',
  ACCEPTED: 'Accepted',
  UNSAVED_DRAFT: 'Unsaved Draft',
  DRAFT: 'Draft',
  GENERATED: 'Generated',
  TRANSMITTED: 'Transmitted',
  NEEDS_ATTENTION: 'Needs Attention',
  PRINTED: 'Printed',
  MAILED: 'Mailed',
  CANCELED: 'Canceled',
  CANCELED_RESUBMIT: 'Canceled-Resubmit',
  CANCELED_RESUBMITTED: 'Canceled - Resubmitted',
  CANCELED_REBILLED: 'Canceled - Rebilled',
  CANCELED_VOIDED: 'Canceled - Voided',
  DENIED: 'Denied',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  ERROR: 'Error',
  PENDING: 'Pending',
  RECEIVED: 'Received',
  ERA_RECEIVED: 'ERA Received',
  VALIDATION_ERROR: 'Validation Error',
  ERA_EOB_RECEIVED_APPROVED: 'ERA/EOB Received (Approved)',
  ERA_EOB_RECEIVED_DENIED: 'ERA/EOB Received (Denied)',
  READY_TO_DOWNLOAD: 'Ready To Download',
  OVERRIDDEN: 'Overridden',
});

const manualUpdateNotAllowedStatusesForPaperClaims = [
  CLAIM_STATUS.TRANSMITTED,
  CLAIM_STATUS.PENDING,
  CLAIM_STATUS.RECEIVED,
  CLAIM_STATUS.READY_TO_DOWNLOAD,
  CLAIM_STATUS.SUBMITTING,
  CLAIM_STATUS.DOWNLOADED,
  CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE,
  CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
];

export const CLAIM_STATUS_PAPER = Object.fromEntries(
  Object.entries({ ...CLAIM_STATUS }).filter(
    ([, value]) =>
      !manualUpdateNotAllowedStatusesForPaperClaims.includes(value),
  ),
);

export const EXCESS_CHARGE_POPUP = {
  MAX_CHARGES: 6,
  get HELPER_TEXT() {
    return `Invoices with more than ${
      this.MAX_CHARGES
    } charges will remain in Needs Attention. Separate each invoice into ${
      this.MAX_CHARGES
    } charges or less.`;
  },
  TITLE: 'Invoice(s) Encountered Issues Refreshing & Submitting',
  ACTION_PAST_TENSE: 'Refreshed & Submitted',
  ACTION_PRESENT_TENSE: 'Refresh & Submit',
};

export const DESIGNATED_CLEARINGHOUSE_POPUP = {
  CORRECT_COUNT: 1,
  get HELPER_TEXT() {
    return `Invoices with payers that do not have ${
      this.CORRECT_COUNT
    } designated clearinghouse will remain in Needs Attention. Please address your Payer Maintenance settings for these payers before submitting claims.`;
  },
  TITLE: 'Payer Needs Designated Clearinghouse',
  ACTION_PAST_TENSE: 'Refreshed & Submitted',
  ACTION_PRESENT_TENSE: 'Refresh & Submit',
};

export const PAYMENT_RESPONSIBILITY_LEVEL_CODE = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
};

export const COMMERCIAL_INSURANCE_INDICATORS = {
  PREFERRED_PROVIDER_ORGANIZATION: '12 - Preferred Provider Organization (PPO)',
  POINT_OF_SERVICE: '13 - Point of Service (POS)',
  EXCLUSIVE_PROVIDER_ORGANIZATION: '14 - Exclusive Provider Organization (EPO)',
  DENTAL_MAINTENANCE_ORGANIZATION: '17 - Dental Maintenance Organization',
  COMMERCIAL_INSURANCE_CO: 'CI - Commercial Insurance Co.',
  HEALTH_MAINTENANCE_ORGANIZATION: 'HM - Health Maintenance Organization',
};

export const BLUE_CROSS_BLUE_SHIELD_INDICATORS = {
  BLUE_CROSS_BLUE_SHIELD: 'BL - Blue Cross/Blue Shield',
};

export const MEDICARE_INDICATORS = {
  HEALTH_MAINTENANCE_ORGANIZATION:
    '16 - Health Maintenance Organization (HMO) Medicare Risk',
  MEDICARE_PART_A: 'MA - Medicare Part A',
  MEDICARE_PART_B: 'MB - Medicare Part B',
};

export const MEDICAID_INDICATORS = {
  MEDICAID: 'MC - Medicaid',
};

export const VETERANS_AFFAIRS_INDICATORS = {
  VETERANS_AFFAIRS_PLAN: 'VA - Veterans Affairs Plan',
};

export const OTHER_GOVERNMENT_INDICATORS = {
  OTHER_NON_FEDERAL_PROGRAMS: '11 - Other Non-Federal Programs',
  CHAMPUS: 'CH - Champus',
  FEDERAL_EMPLOYEES_PROGRAM: 'FI - Federal Employees Program',
  OTHER_FEDERAL_PROGRAM: 'OF - Other Federal Program',
  TITLE_V: 'TV - Title V',
};

export const WORKERS_COMPENSATION_INDICATORS = {
  WORKERS_COMPENSATION_HEALTH_CLAIM: "WC - Workers' Compensation Health Claim",
};

export const AUTO_INSURANCE_INDICATORS = {
  AUTOMOBILE_MEDICAL: 'AM - Automobile Medical',
};

export const OTHER_LIABILITY_INDICATORS = {
  INDEMNITY_INSURANCE: '15 - Indemnity Insurance',
  DISABILITY: 'DS - Disability',
  LIABILITY_MEDICAL: 'LM - Liability Medical',
};

export const CLAIM_FILING_INDICATOR = {
  'Commercial Insurance': Object.values(COMMERCIAL_INSURANCE_INDICATORS),
  BCBS: Object.values(BLUE_CROSS_BLUE_SHIELD_INDICATORS),
  Medicare: Object.values(MEDICARE_INDICATORS),
  Medicaid: Object.values(MEDICAID_INDICATORS),
  'Veterans Affairs': Object.values(VETERANS_AFFAIRS_INDICATORS),
  'Other Government': Object.values(OTHER_GOVERNMENT_INDICATORS),
  'Workers Compensation': Object.values(WORKERS_COMPENSATION_INDICATORS),
  'Auto-Insurance': Object.values(AUTO_INSURANCE_INDICATORS),
  'Other Liability': Object.values(OTHER_LIABILITY_INDICATORS),
};

export const CROSS_WALK = {
  claimFilingIndicator: claimFilingIndicator => {
    if (CLAIM_FILING_INDICATOR.Medicare.includes(claimFilingIndicator)) {
      return 'Medicare';
    }

    if (CLAIM_FILING_INDICATOR.Medicaid.includes(claimFilingIndicator)) {
      return 'Medicaid';
    }

    return 'Other';
  },

  patientRelationToInsured: relationship => {
    switch (relationship) {
      case INSURED_RELATIONSHIP.Self:
      case INSURED_RELATIONSHIP.Spouse:
        return relationship;
      case INSURED_RELATIONSHIP.Parent:
        return 'Child';
      default:
        return INSURED_RELATIONSHIP.Other;
    }
  },
};

export const DATE_OF_CURRENT_ILLNESS_QUALIFIER = Object.freeze({
  dateOfCurrentIllness: {
    label: 'Encounter - “Date of Onset”',
    value: '431',
  },
  onsetOfSymptoms: {
    label: 'Case - “Date of Onset”',
    value: '431',
  },
  pregnancyLastMenstrualPeriod: {
    label: 'Pregnancy, Last Menstrual Period',
    value: '484',
  },
});

export const DATE_OF_CURRENT_ILLNESS_QUALIFIERS = Object.values(
  DATE_OF_CURRENT_ILLNESS_QUALIFIER,
);

export const OTHER_ID_QUALIFIER = {
  '0B': 'State License Number',
  '1B': 'Blue Shield Provider Number',
  '1C': 'Medicare Provider Number',
  '1D': 'Medicaid Provider Number',
  '1G': 'Provider UPIN Number',
  EI: 'Employer’s Identification Number',
  G2: 'Provider Commercial Number',
  LU: 'Location Number',
  ZZ: 'Provider Taxonomy',
};

const MAP_OF_REPORT_TYPE_CODES = new Map([
  ['03', 'Report Justifying Treatment Beyond Utilization'],
  ['04', 'Drugs Administered'],
  ['05', 'Treatment Diagnosis'],
  ['06', 'Assessment'],
  ['07', 'Functional Goals'],
  ['08', 'Plan of Treatment'],
  ['09', 'Progress Report'],
  ['10', 'Continued Treatment'],
  ['11', 'Chemical Analysis'],
  ['13', 'Certified Test Report'],
  ['15', 'Justification for Admission'],
  ['21', 'Recovery Plan'],
  ['A3', 'Allergies/Sensitivities Document'],
  ['A4', 'Autopsy Report'],
  ['AM', 'Ambulance Certification'],
  ['AS', 'Admission Summary'],
  ['B2', 'Prescription'],
  ['B3', 'Physician Order'],
  ['B4', 'Referral Form'],
  ['BR', 'Benchmark Testing Results'],
  ['BS', 'Baseline'],
  ['BT', 'Blanket Test Results'],
  ['CB', 'Chiropractic Justification'],
  ['CK', 'Consent Form(s)'],
  ['CT', 'Certification'],
  ['D2', 'Drug Profile Document'],
  ['DA', 'Dental Models'],
  ['DB', 'Durable Medical Equipment Prescription'],
  ['DG', 'Diagnostic Report'],
  ['DJ', 'Discharge Monitoring Report'],
  ['DS', 'Discharge Summary'],
  [
    'EB',
    'Explanation of Benefits (Coordination of Benefits or Medicare Secondary Payor)',
  ],
  ['HC', 'Health Certificate'],
  ['HR', 'Health Clinic Records'],
  ['I5', 'Immunization Record'],
  ['IR', 'State School Immunization Records'],
  ['LA', 'Laboratory Results'],
  ['M1', 'Medical Record Attachment'],
  ['MT', 'Models'],
  ['NN', 'Nursing Notes'],
  ['OB', 'Operative Note'],
  ['OC', 'Oxygen Content Averaging Report'],
  ['OD', 'Orders and Treatments Document'],
  ['OE', 'Objective Physical Examination (including vital signs) Document'],
  ['OX', 'Oxygen Therapy Certification'],
  ['OZ', 'Support Data for Claim'],
  ['P4', 'Pathology Report'],
  ['P5', 'Patient Medical History Document'],
  ['PE', 'Parenteral or Enteral Certification'],
  ['PN', 'Physical Therapy Notes'],
  ['PO', 'Prosthetics or Orthotic Certification'],
  ['PQ', 'Paramedical Results'],
  ['PY', 'Physician’s Report'],
  ['PZ', 'Physical Therapy Certification'],
  ['RB', 'Radiology Films'],
  ['RR', 'Radiology Reports'],
  ['RT', 'Report of Tests and Analysis Report'],
  ['RX', 'Renewable Oxygen Content Averaging Report'],
  ['SG', 'Symptoms Document'],
  ['V5', 'Death Notification'],
  ['XP', 'Photographs'],
]);

export const REPORT_TYPE_CODE = Object.fromEntries(MAP_OF_REPORT_TYPE_CODES);

export const REPORT_TRANSMISSION_CODE = {
  AA: 'Available on Request at Provider Site ',
  BM: 'By Mail',
  EL: 'Electronically Only',
  EM: 'E-mail',
  FT: 'File Transfer',
  FX: 'By Fax',
};

export const CODE_QUALIFIER = {
  AC: 'Attachment Control Number',
};

export const ALLOWED_IDENTIFIERS = {
  SERVICE_FACILITY: ['0B', 'EI', 'G2', 'LU'],
  REFERRING_PROVIDER: ['0B', '1G', 'EI', 'G2', 'LU'],
  BILLING_PROVIDER: ['0B', 'G2', 'EI', 'ZZ'],
  RENDERING_PROVIDER: ['0B', '1B', '1C', '1D', 'EI', '1G', 'G2', 'LU', 'ZZ'],
};

export const REFERRING_PROVIDER_QUALIFIERS = Object.entries(OTHER_ID_QUALIFIER)
  .map(([qualifier, description]) => ({
    label: `${qualifier} - ${description}`,
    value: qualifier,
  }))
  .filter(({ value }) =>
    ALLOWED_IDENTIFIERS.REFERRING_PROVIDER.includes(value),
  );

export const RESUBMISSION_CODE = Object.freeze({
  7: {
    label: '7 - Replacement of prior claim',
    value: '7',
  },
  8: {
    label: '8 - Void/cancel of prior claim',
    value: '8',
  },
});

export const CODE_QUALIFIERS = Object.entries(CODE_QUALIFIER).map(
  ([code, description]) => ({
    label: `${code} - ${description}`,
    data: { id: code },
  }),
);

export const REPORTS_TRANSMISSION_CODE = Object.entries(
  REPORT_TRANSMISSION_CODE,
).map(([code, description]) => ({
  label: `${code} - ${description}`,
  data: { id: code },
}));

export const REPORTS_TYPE_CODE = Array.from(
  MAP_OF_REPORT_TYPE_CODES,
  ([code, description]) => ({
    label: `${code} - ${description}`,
    data: { id: code },
  }),
);

export const RESUBMISSION_CODES = Object.values(RESUBMISSION_CODE);

export const OTHER_DATE_QUALIFIER = {
  454: 'Initial Treatment',
  304: 'Latest Visit or Consultation',
  453: 'Acute Manifestation of a Chronic Condition',
  439: 'Accident',
  455: 'Last X-ray',
  471: 'Prescription',
  '090': 'Report Start, Assumed Care Date',
  '091': 'Report End, Relinquished Care Date',
  444: 'First Visit or Consultation',
};

export const SERVICE_FACILITY_ADDRESS_TYPE = Object.freeze({
  PRACTICE_ADDRESS: 'Practice Address',
  BILLING_ADDRESS: 'Billing Address',
  PAY_TO_ADDRESS: 'Pay To Address',
  OTHER: 'Other',
});

export const SERVICE_FACILITY_NPI_SOURCE = Object.freeze({
  ORGANIZATION_NPI: 'Organization NPI',
  PROVIDER_NPI: 'Provider NPI',
  PROVIDER_ORGANIZATION_NPI: 'Provider Organization NPI',
});

export const REFERRING_PROVIDER_QUALIFIER_LABEL = Object.freeze({
  DK: {
    qualifier: 'DK',
    label: 'Ordering Provider',
  },
  DN: {
    qualifier: 'DN',
    label: 'Referring Provider',
  },
  DQ: {
    qualifier: 'DQ',
    label: 'Supervising Provider',
  },
});

export const TOOLTIP_TEXT = {
  FL_1: html`
    <div>
      "Type of Health Insurance Coverage" indicates the type of insurance for
      which the claim is being submitted. <br /><br />
      This value can be set by populating the "Claim Filing Indicator" for the
      payer of the claim.
    </div>
  `,
  FL_1A: html`
    <div>
      "Insured's ID Number" is the identification number of the insured party,
      as listed on their policy. <br /><br />
      This value can be set by populating the "Subscriber ID" field for the
      patient's insurance plan. If "Subscriber ID" is not populated, the
      "Insured ID or Policy #" field will be used.
    </div>
  `,
  FL_2: html`
    <div>
      "Patient's Name" is the last name, first name, and middle initial of the
      patient for which the claim is being submitted. <br /><br />
      This value can be set by populating the "First Name", "Last Name", and
      "Middle Name" fields on the patient's profile.
    </div>
  `,
  FL_3: html`
    <div>
      "Patient's Date Of Birth and Sex" is the date of birth and sex of the
      patient for which the claim is being submitted. <br /><br />
      These values can be set by populating the "Date of Birth" and "Sex" fields
      on the patient's profile.
    </div>
  `,
  FL_4: html`
    <div>
      "Insured's Name" is the last name, first name, and middle initial of the
      insured party. <br /><br />
      This value can be set by populating the policyholder "First Name", "Last
      Name", and "Middle Name" fields for the patient's insurance plan.
    </div>
  `,
  FL_5: html`
    <div>
      "Patient's Address" is the address, city, state, zip code, and telephone
      number of the patient for which the claim is being submitted. <br /><br />
      These values can be set by populating the "Address 1", "Address 2",
      "City", "State", "Zip Code", and "Phone Number" fields on the patient's
      profile.
    </div>
  `,
  FL_6: html`
    <div>
      "Patient's Relationship to Insured" indicates the relationship between the
      patient and the insured party. <br /><br />
      This value can be set by populating the "Policyholder Relation to Patient"
      field for the patient's insurance plan.
    </div>
  `,
  FL_7: html`
    <div>
      "Insured's Address" is the address, city, state, zip code, and telephone
      number of the insured party.<br /><br />
      These values can be set by populating the policyholder "Address 1",
      "Address 2", "City", "State", "Zip Code", and "Phone Number" fields for
      the patient's insurance plan.
    </div>
  `,
  FL_8: html`
    <div>This field is not editable because it is reserved for NUCC use.</div>
  `,
  FL_9: html`
    <div>
      "Other Insured's Name" is the name of policyholder for the insurance plan
      listed in 9d. (Could be other than the patient.)
    </div>
  `,
  FL_9A: html`
    <div>
      "Other Insured's Policy or Group Number" is the identifier for any other
      insurance plan not billed on this claim. This field references another
      policy being billed for part of this invoice.
    </div>
  `,
  FL_9B: html`
    <div>This field is not editable because it is reserved for NUCC use.</div>
  `,
  FL_9C: html`
    <div>This field is not editable because it is reserved for NUCC use.</div>
  `,
  FL_9D: html`
    <div>
      "Insurance Plan Name or Program Name" is the name of an additional
      insurance plan not billed on this claim. This field references another
      policy being billed for part of this invoice.
    </div>
  `,
  FL_10: html`
    <div>
      "Is Patient's Condition Related To" indicates whether the illness or
      injury for which the claim is being submitted is related to employment,
      auto accident, or other accident. <br /><br />
      These values can be set by populating the "Care Type" and "Place (State)"
      fields in the case that is associated with the invoice for this claim.
    </div>
  `,
  FL_10D: html`
    <div>
      "Claim Codes" indicates additional information about the claim or the
      patient's condition. <br /><br />
      This value can be set by clicking on the field to open the popup and
      populating the value in the "Claim Codes" field.
    </div>
  `,
  FL_11: html`
    <div>
      "Insured's Policy Group or FECA Number" is the identifier for the
      insurance plan, as listed on the insured party's policy. <br /><br />
      This value can be set by populating the "Plan ID" field for the patient's
      insurance plan.
    </div>
  `,
  FL_11A: html`
    <div>
      "Insured's Date of Birth and Sex" is the date of birth and sex of the
      insured party. <br /><br />
      These values can be set by populating the policyholder "Date of Birth",
      and "Sex" fields for the patient's insurance plan.
    </div>
  `,
  FL_11B: html`
    <div>
      "Other Claim ID" indicates additional claim identification issued by
      another entity. <br /><br />
      This value can be set by clicking on the field to open the popup and
      populating the value in the "Qualifier", and "Claim Codes" fields.
    </div>
  `,
  FL_11C: html`
    <div>
      "Insurance Plan Name or Program Name" is the name of the insurance plan,
      as listed on the insured party's policy. <br /><br />
      This value can be set by populating the "Plan Name" field for the
      patient's insurance plan.
    </div>
  `,
  FL_11D: html` <div>If yes, #9 a-d should be complete.</div> `,
  FL_12: html`
    <div>
      "Patient's or Authorized Person's Signature" indicates that there is an
      authorization on file for the release of medical or other information
      necessary to process the claim.<br /><br />
      This value will be set to SOF by the system.
    </div>
  `,
  FL_13: html`
    <div>
      "Insured's or Authorized Person's Signature" indicates that there is a
      signature on file authorizing payment of medical benefits. <br /><br />
      This value will be set to SOF by the system.
    </div>
  `,
  FL_14: html`
    <div>
      "Date of Current Illness, Injury, or Pregnancy (LMP)" is the date of onset
      of illness, date of injury, or LMP for pregnancy. <br /><br />
      This value can be set by populating the encounter “Date of Onset” field.
      If there is no date set in the encounter and a case exists it will default
      to the case “Date of Onset”. If both dates exist the encounter will be
      defaulted but you can choose which to use by clicking on the field to open
      the popup and selecting one of the options. “Pregnancy, Last Menstrual
      Period” date can be set by selecting the option and populating the value
      in the “Date” field.
    </div>
  `,
  FL_15: html`
    <div>
      "Other Date" indicates additional date information about the patient's
      condition. <br /><br />
      This value can be set by populating one of the date fields in the case
      that is associated with the invoice for this claim. By default, the system
      will use the "Accident Date" if populated. If "Accident Date" is not
      populated, the system will use the most recent date from the case. If
      multiple values exist, you can choose which to use by clicking on the
      field to open the popup and selecting one of the options.
    </div>
  `,
  FL_16: html`
    <div>
      "Dates Patient Unable to Work in Current Occupation" indicates the period
      of time that the patient will be, or was previously unable to work.
      <br /><br />
      These values can be set by populating the "Disability Begin Date" and
      "Disability End Date" fields in the case that is associated with the
      invoice for this claim. These values can also be set by clicking on the
      field to open the popup and populating the values in the "From" and "To"
      fields.
    </div>
  `,
  FL_17: html`
    <div>
      "Name of Referring Provider or Other Source" is the name of the referring,
      ordering, or supervising provider. <br /><br />
      This value can be set by populating the Referring Provider or Ordering
      Provider "First Name", "Middle Name", Last Name", and "Credentials" fields
      in the case that is associated with the invoice for this claim. If
      multiple values exist, you can choose which to use by clicking on the
      field to open the popup and selecting one of the options. "Supervising
      Provider" can be set by selecting the option and populating the values in
      the "First Name", "Middle Name", Last Name", and "Credentials" fields.
    </div>
  `,
  FL_17A: html`
    <div>
      "Referring Provider or Other Source - Other ID" is the non-NPI ID number
      of the referring, ordering, or supervising provider. <br /><br />
      This value can be set by clicking on the field to open the popup and
      populating the values in the "Qualifier" and "Other ID" fields.
    </div>
  `,
  FL_17B: html`
    <div>
      "Referring Provider or Other Source - NPI" is the NPI number of the
      referring, ordering, or supervising provider. <br /><br />
      This value can be set by clicking on the field to open the popup and
      populating the value in the "NPI" field.
    </div>
  `,
  FL_18: html`
    <div>
      "Hospitalization Dates Related to Current Services" indicates the
      admission and discharge dates of an inpatient hospital stay associated
      with the charges on the claim. <br /><br />
      These values can be set by populating the "Hospital Admission Date" and
      "Hospital Discharge Date" fields in the case that is associated with the
      invoice for this claim.
    </div>
  `,
  FL_19: html`
    <div>
      "Additional Claim Information (Designated by NUCC)" indicates additional
      information about the claim or the patient's condition. <br /><br />
      This value can be set by populating the "Additional Claim Information" and
      "Additional Claim Information Note" fields for the payer of the claim.
      This value can also be set by clicking on the field to open the popup and
      populating the value in the "Custom Code" field.
    </div>
  `,

  FL_20: html`
    <div>
      "Outside Labs?" indicates that an independent provider has rendered
      services and the related costs.

      <br /><br />
      These values can be set by clicking on the field to open the popup and
      populating the values in the "Yes/No" and "Charges" fields.
    </div>
  `,
  FL_21_DIAGNOSIS: html`
    <div>
      "Diagnosis or Nature of Illness or Injury" is the sign, symptom,
      complaint, or condition of the patient as related to the charges on the
      claim. <br /><br />
      These values will be populated by the system based on the encounters
      associated with the charges on the claim. The system will populate up to a
      maximum of 12 diagnoses ordered by date of service and encounter diagnosis
      order.
    </div>
  `,
  FL_21_ICD_INDICATOR: html`
    <div>
      "Diagnosis or Nature of Illness or Injury - ICD Indicator" indicates the
      ICD code set being reported. <br /><br />
      This value will be set to 0 by the system, to indicate that the ICD-10
      code set is being used.
    </div>
  `,
  FL_22_RESUBMISSION_CODE: html`
    <div>
      "Resubmission Code" is the code and reference number provided by the payer
      to indicate a previously submitted claim. <br /><br />
      These values can be set by clicking on the field to open the popup and
      populating the values in the "Resubmission Code" and "Original Reference
      Number" fields.
    </div>
  `,
  FL_23: html`
    <div>
      "Prior Authorization Number" is the number assigned by the payer that
      authorizes the services. <br /><br />
      This value can be set by clicking on the field to open the popup and
      populating the value in the "Prior Authorization Number" field.
    </div>
  `,
  FL_24A: html`
    <div>
      "Date(s) of Service" is the month, day, and year that the service was
      provided. <br /><br />
      These values will be populated based on the date of service of the charge
      for this line.
    </div>
  `,
  FL_24B: html`
    <div>
      "Place of Service" is the location where the service was provided.
      <br /><br />
      This value will be populated based on the place of service of the charge
      for this line. This value can be set by populating the "POS" field when
      editing the charge from the "Update Charges" screen. By default, the
      system will populate this field with a value of 11 to indicate that the
      place of service was an office.
    </div>
  `,
  FL_24C: html`
    <div>
      "EMG" indicates whether the service was an emergency. <br /><br />
      This value can be set by clicking on the field to open the popup and
      selecting "Yes" or "No". By default, the system will populate this field
      with a value of no.
    </div>
  `,
  FL_24D: html`
    <div>
      "Procedures, Services, or Supplies" is the medical service or procedure
      performed on the patient. <br /><br />
      This value will be populated based on the procedure code and modifiers of
      the charge for this line. Modifiers can be set by populating the
      "Modifiers" field when editing the charge from the "Update Charges"
      screen.
    </div>
  `,
  FL_24E: html`
    <div>
      "Diagnosis Pointer" is the association between the service or procedure
      and the diagnoses listed in field 24. Each procedure or service can be
      associated with a maximum of four diagnoses. <br /><br />
      This value will be populated based on the diagnosis pointer(s) of the
      charge for this line. This value can be set by populating the "DX" field
      when editing the charge from the "Update Charges" screen.
    </div>
  `,
  FL_24F: html`
    <div>
      "$ Charges" is the total billed amount for the service or procedure.
      <br /><br />
      This value will be populated based on the billed amount of the charge for
      this line. This value can be set by populating the "Units", "Fee Sch
      Chrg", and "Tax Rate" fields when editing the charge from the "Update
      Charges" screen.
    </div>
  `,
  FL_24G: html`
    <div>
      "Days or Units" is the number of days covered by the dates of service, or
      the number of units for the service or procedure. <br /><br />
      This value will be populated based on the units of the charge for this
      line. This value can be set by populating the "Units" field when editing
      the charge from the "Update Charges" screen.
    </div>
  `,
  FL_24H: html`
    <div>
      "EPSDT/Family Plan" indicates the referral status of an EPSDT service on
      the claim, and if a service is required to be reported as a family
      planning service. <br /><br />
      EPSDT will be populated based on the EPSDT value of the charge for this
      line. EPSDT can be set by populating the "EDPST" field when editing the
      charge from the "Update Charges" screen. Family Plan can be set by
      clicking on the field to open the popup and selecting "Yes" or "No". By
      default, the system will populate the family plan field with a value of
      no.
    </div>
  `,
  FL_24I: html`
    <div>
      "ID. Qual." is the qualifier for the non-NPI ID number of the rendering
      provider. <br /><br />
      This value will be populated based on the rendering provider ID selected
      in 24J.
    </div>
  `,
  FL_24J: html`
    <div>
      "Rendering Provider ID Number" is the NPI or non-NPI ID number of the
      rendering provider. The shaded area is used for the non-NPI number, and
      the unshaded area is used for the NPI number. <br /><br />
      Non-NPI ID number can be set by populating the "Other Identifier" fields
      in the rendering provider's profile. The supported identifier types are 0B
      - State License Number, 1B - Blue Shield Provider Number, 1C - Medicare
      Provider Number, 1D - Medicaid Provider Number, 1G - Provider UPIN Number,
      G2 - Provider Commercial Number, LU - Location Number, and ZZ - Provider
      Taxonomy. If multiple values exist, you can choose which to use by
      clicking on the field to open the popup and selecting one of the options.
      NPI number can be set by populating the "NPI" field in the provider's
      profile.
    </div>
  `,
  FL_25: html`
    <div>
      "Federal Tax I.D. Number" is the identification number assigned by a
      federal or state agency to uniquely identify you for tax purposes.
      <br /><br />
      This value can be set by populating the "Tax ID" field in the practice
      settings area, the "Tax ID" field in the billing provider's profile, or
      the "Other Identifiers" field in the billing provider's profile of type SY
      - Social Security Number. If multiple values exist, you can choose which
      to use by clicking on the field to open the popup and selecting one of the
      options.
    </div>
  `,
  FL_26: html`
    <div>
      "Patient's Account Number" is the patient identifier that is assigned by
      the provider.

      <br /><br />
      This value can be set by populating the "Medical Record Number" field on
      the patient's profile.
    </div>
  `,
  FL_27: html`
    <div>
      "Accept Assignment?" indicates if the provider will accept assignment
      based on the terms of the payer's program. <br /><br />
      This value can be set by clicking on the field to open the popup and
      selecting "Yes" or "No". By default, the system will populate this field
      with a value of yes.
    </div>
  `,
  FL_28: html`
    <div>
      "Total Charges" is the sum of the charges entered in 24F lines 1-6.
      <br /><br />
      This value will be populated based on the total billed amounts of the
      charges for this claim.
    </div>
  `,
  FL_29: html`
    <div>
      "Amount Paid" is the amount that has been paid by the patient or other
      payers for the charges on this claim. <br /><br />
      This value will be populated based on the total paid amounts of the
      charges for this claim. This value can be set by allocating primary,
      secondary, or patient payments to the charges on this claim.
    </div>
  `,
  FL_30: html`
    <div>This field is not editable because it is reserved for NUCC use.</div>
  `,
  FL_31: html`
    <div>
      "Signature of Physician" is the signature of the authorized or accountable
      person. <br /><br />
      This can be set by configuring a provider signature in the provider
      settings area for the billing provider of the claim. If no signature is
      configured for the billing provider, the system will populate "SOF" in
      this field.
    </div>
  `,
  FL_32: html`
    <div>
      "Service Facility Location Information" is the name and address of the
      facility where services were provided. <br /><br />
      These values can be set by populating the "Practice Address" fields in the
      practice settings area.
    </div>
  `,
  FL_32A: html`
    <div>
      "Service Facility Location - NPI" is the NPI number of the service
      facility location. <br /><br />
      This value can be set by populating the "Organization NPI" field in the
      practice settings area.
    </div>
  `,
  FL_32B: html`
    <div>
      "Service Facility Location - Other ID" is the non-NPI ID number of the
      service facility location. <br /><br />
      This value can be set by populating the "Other Identifier" fields in the
      billing provider's profile. The supported identifier types are 0B - State
      License Number, G2 - Provider Commercial Number, and LU - Location Number.
      If multiple values exist, you can choose which to use by clicking on the
      field to open the popup and selecting one of the options.
    </div>
  `,
  FL_33: html`
    <div>
      "Billing Provider Information and Phone Number" is the name, address, and
      phone number of the billing provider's office location. <br /><br />
      These values can be set by populating the "Practice Name" and "Phone
      Number" fields, and the "Billing Address" or "Pay to Address" fields in
      the practice settings area.
    </div>
  `,
  FL_33A: html`
    <div>
      "Billing Provider - NPI" is the NPI number of the billing provider.
      <br /><br />
      This value can be set by populating the "NPI" field in the billing
      provider's profile.
    </div>
  `,
  FL_33B: html`
    <div>
      "Billing Provider - Other ID" is the non-NPI ID number of the billing
      provider. <br /><br />
      This value can be set by populating the "Other Identifier" fields in the
      billing provider's profile. The supported identifier types are 0B - State
      License Number, G2 - Provider Commercial Number, and ZZ - Provider
      Taxonomy. If multiple values exist, you can choose which to use by
      clicking on the field to open the popup and selecting one of the options.
    </div>
  `,
  FL_SPINAL_MANIPULATION_INFORMATION:
    'Select a Nature of Condition code and input additional information pertaining to spinal manipulation for the patient.',
  PRINT_OFFSETS: html`
    <div>
      You can adjust the printing offsets if claims are not printing on the form
      correctly.
      <p>
        Positive values in the top offset will move the form content down,
        negative values will move it up.
      </p>
      Positive values in the left offset will move the form content right,
      negative values will move it left.
    </div>
  `,
};

export const isEditable = status =>
  status === CLAIM_STATUS.DRAFT || status === CLAIM_STATUS.UNSAVED_DRAFT;

export const isCancelable = status => {
  const cancelableStatuses = [
    CLAIM_STATUS.NEEDS_ATTENTION,
    CLAIM_STATUS.GENERATED,
    CLAIM_STATUS.PRINTED,
    CLAIM_STATUS.MAILED,
    CLAIM_STATUS.TRANSMITTED,
    CLAIM_STATUS.RECEIVED,
    CLAIM_STATUS.PENDING,
    CLAIM_STATUS.DOWNLOADED,
    CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
    CLAIM_STATUS.DENIED,
    CLAIM_STATUS.REJECTED,
    CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
  ];

  return cancelableStatuses.includes(status);
};

export const isCanceled = status =>
  [
    CLAIM_STATUS.CANCELED,
    CLAIM_STATUS.CANCELED_RESUBMIT,
    CLAIM_STATUS.CANCELED_RESUBMITTED,
    CLAIM_STATUS.CANCELED_REBILLED,
    CLAIM_STATUS.CANCELED_VOIDED,
  ].includes(status);

export const hasStatus = (statuses = [], targetStatus) =>
  [...statuses].includes(targetStatus);

export const formatServiceFacilityNPI = claim => {
  if (claim.isElectronic) {
    return String(claim.serviceFacilityNPI);
  }

  if (String(claim.serviceFacilityNPI) === String(claim.billingProviderNPI)) {
    return '';
  }
  return String(claim.serviceFacilityNPI);
};

export const SUBMISSION_METHOD = Object.freeze({
  PAPER_CLAIMS: 'Paper Claims',
  ELECTRONIC_CLAIMS: 'Electronic Claims',
  NO_CLAIMS: 'No Claims',
});

export const SUBMISSION_METHODS = Object.values(SUBMISSION_METHOD);

export const SPINAL_MANIPULATION_OPTION = Object.freeze({
  A: 'A - Acute Condition',
  C: 'C - Chronic Condition',
  D: 'D - Non-Acute',
  E: 'E - Non-Life Threatening',
  F: 'F - Routine',
  G: 'G - Symptomatic',
  M: 'M - Acute Manifestation of a Chronic Condition',
});

export const SPINAL_MANIPULATION_OPTIONS = Object.values(
  SPINAL_MANIPULATION_OPTION,
).map(value => ({ value, label: value }));

export const PRINT_OFFSETS_KEY = 'print-offsets';
export const PRINT_BOLD_KEY = 'print-bold';

export const setPrintSettings = ({ top, left, bold = false }) => {
  window.localStorage.setItem(PRINT_OFFSETS_KEY, JSON.stringify({ top, left }));
  window.localStorage.setItem(PRINT_BOLD_KEY, JSON.stringify({ bold }));
};

const getPrintOffsets = () => {
  const offsets =
    window.localStorage.getItem(PRINT_OFFSETS_KEY) ||
    '{"top": "0", "left": "0"}';

  return JSON.parse(offsets);
};

const getPrintBold = () => {
  const settings =
    window.localStorage.getItem(PRINT_BOLD_KEY) || '{"bold": false }';

  return JSON.parse(settings);
};

export const getPrintSettings = () => {
  const offsets = getPrintOffsets();
  const bold = getPrintBold();

  return { ...offsets, ...bold };
};

const validTaxIdProvider = billingProvider =>
  billingProvider.taxId
    ? {
        federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_TAX_ID,
        federalTaxIdNumber: billingProvider.taxId,
        federalTaxIdType: billingProvider.taxIdType,
      }
    : {};

const validTaxIdOtherIdentifer = (
  billingProvider,
  federalTaxIdProviderIdentifierId,
) => {
  const match = billingProvider.identifiers.find(
    x => x.id === federalTaxIdProviderIdentifierId && x.type.value === 'SY',
  );

  if (match) {
    return {
      federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN,
      federalTaxIdNumber: match.number,
      federalTaxIdType: FEDERAL_TAX_ID_TYPE.SSN,
    };
  }

  return {};
};

const validTaxIdOther = (federalTaxIdOtherNumber, federalTaxIdOtherType) =>
  federalTaxIdOtherNumber && federalTaxIdOtherType
    ? {
        federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.OTHER,
        federalTaxIdNumber: federalTaxIdOtherNumber,
        federalTaxIdType: federalTaxIdOtherType,
      }
    : {};

export const getCustomTaxIdInfo = ({
  billingProviderValues,
  billingProvider,
  defaultValue,
}) => {
  const result = { ...defaultValue };

  if (!billingProviderValues) return result;

  const {
    federalTaxIdSource,
    federalTaxIdOtherNumber,
    federalTaxIdOtherType,
    federalTaxIdProviderIdentifierId,
  } = billingProviderValues;

  let overrides = {};

  if (federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.PROVIDER_TAX_ID) {
    overrides = validTaxIdProvider(billingProvider);
  }

  if (federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.PROVIDER_SSN) {
    overrides = validTaxIdOtherIdentifer(
      billingProvider,
      federalTaxIdProviderIdentifierId,
    );
  }

  if (federalTaxIdSource === FEDERAL_TAX_ID_SOURCE.OTHER) {
    overrides = validTaxIdOther(federalTaxIdOtherNumber, federalTaxIdOtherType);
  }

  return { ...result, ...overrides };
};

export const getCustomNPIInfo = ({
  billingProviderValues,
  billingProvider,
  defaultValue,
}) => {
  if (
    billingProviderValues &&
    billingProviderValues.serviceFacilityNPISource ===
      SERVICE_FACILITY_NPI_SOURCE.PROVIDER_NPI &&
    billingProvider.NPI &&
    billingProvider.NPI.length
  ) {
    return billingProvider.NPI;
  }

  return defaultValue;
};

export const getCustomOverride = ({ overrides, key, defaultValue = null }) =>
  overrides && key && overrides[key] ? overrides[key] : defaultValue;

export const LOCATION_VALUES = Object.freeze({
  PRACTICE_ADDRESS: 'Practice Address',
  BILLING_ADDRESS: 'Billing Address',
  PAY_TO_ADDRESS: 'Pay To Address',
  OTHER: 'Other',
});

const formatZipCode = item => item.zipCode || item.zipcode || '';

export const formatAddressForClaim = (item = {}) => ({
  address1: item.address1 || '',
  address2: item.address2 || '',
  city: item.city || '',
  state: item.state || '',
  zipCode: formatZipCode(item),
});

export const getLocationAddresses = (practiceInfo, billingInfo) => ({
  [LOCATION_VALUES.PRACTICE_ADDRESS]: {
    ...formatAddressForClaim(practiceInfo.address),
    phone: practiceInfo.phoneNumber,
  },
  [LOCATION_VALUES.BILLING_ADDRESS]: {
    ...formatAddressForClaim(billingInfo.billingAddress),
    phone: practiceInfo.phoneNumber,
  },
  [LOCATION_VALUES.PAY_TO_ADDRESS]: {
    ...formatAddressForClaim(billingInfo.payToAddress),
    phone: practiceInfo.phoneNumber,
  },
});

export const getCustomLocationInfo = ({
  billingProviderValues,
  location,
  key,
  practiceInfo,
  billingInfo,
  defaultValue,
  hidePhone,
}) => {
  if (!billingProviderValues) return defaultValue;

  const locationKey = `${location}Location`;
  const selectedLocation = billingProviderValues[locationKey];
  const locationAddress = getLocationAddresses(practiceInfo, billingInfo);

  if (selectedLocation === LOCATION_VALUES.OTHER) {
    const otherAddress = billingProviderValues[`${location}Address`];

    if (key === 'address') {
      return {
        ...formatAddressForClaim(otherAddress),
        phone: otherAddress.phone,
      };
    }

    return otherAddress.name;
  }

  if (key === 'name') return practiceInfo.name;

  if (hidePhone) {
    return {
      ...locationAddress[selectedLocation],
      phone: '',
    };
  }

  return locationAddress[selectedLocation];
};

export const formatLocationInformation = ({
  practiceInfo,
  billingInfo,
  billingProviderValues,
  billingProvider,
}) => {
  const serviceFacilityName = getCustomLocationInfo({
    billingProviderValues,
    location: 'serviceFacility',
    key: 'name',
    practiceInfo,
    billingInfo,
    defaultValue: practiceInfo.name,
  });

  const serviceFacilityAddress = getCustomLocationInfo({
    billingProviderValues,
    location: 'serviceFacility',
    key: 'address',
    practiceInfo,
    billingInfo,
    defaultValue: {
      ...formatAddressForClaim(practiceInfo.address),
      phone: '',
    },
    hidePhone: true,
  });

  const serviceFacilityAddressType = billingProviderValues
    ? billingProviderValues.serviceFacilityLocation
    : LOCATION_VALUES.PRACTICE_ADDRESS;

  const billingProviderPracticeName = getCustomLocationInfo({
    billingProviderValues,
    location: 'billingProvider',
    key: 'name',
    practiceInfo,
    billingInfo,
    defaultValue: practiceInfo.name,
  });

  const billingProviderAddress = getCustomLocationInfo({
    billingProviderValues,
    location: 'billingProvider',
    key: 'address',
    practiceInfo,
    billingInfo,
    defaultValue: {
      ...formatAddressForClaim(
        billingInfo.fl33 === FL33_TYPES.BILLING
          ? billingInfo.billingAddress
          : billingInfo.payToAddress,
      ),
      phone: practiceInfo.phoneNumber,
    },
  });

  const federalTaxIdInfo = getCustomTaxIdInfo({
    billingProviderValues,
    billingProvider,
    defaultValue: {
      federalTaxIdNumber: billingInfo.taxId,
      federalTaxIdType: FEDERAL_TAX_ID_TYPE.EIN,
      federalTaxIdSource: FEDERAL_TAX_ID_SOURCE.PRACTICE_TAX_ID,
    },
  });

  const serviceFacilityNPI = getCustomNPIInfo({
    billingProviderValues,
    billingProvider,
    defaultValue: billingInfo.organizationNPI || null,
  });

  return {
    ...federalTaxIdInfo,
    billingProviderPracticeName,
    billingProviderAddress,
    serviceFacilityAddress,
    serviceFacilityAddressType,
    serviceFacilityName,
    serviceFacilityNPI,
  };
};

export const getIdentifierComponent = (identifier, key) => {
  if (!identifier) return '';
  if (key === 'type') return identifier.type.value;
  return identifier[key];
};

export const firstAllowedIdentifier = (identifiers, type) => {
  if (!identifiers) return null;

  const allowed = identifiers.filter(n => type.includes(n.type.value));

  if (allowed.length === 0) return null;

  return allowed[0];
};

const IDENTIFIER_FIELD_TYPE = {
  RENDERING_PROVIDER: 'RENDERING_PROVIDER',
  SERVICE_FACILITY: 'SERVICE_FACILITY',
  BILLING_PROVIDER: 'BILLING_PROVIDER',
  PROVIDER_SSN: 'PROVIDER_SSN',
};

const isBillingProvider = (provider, billingProviderId) =>
  provider.type === PROVIDER_TYPE.PROVIDER || billingProviderId === provider.id;

const hasNoIdentifiers = provider =>
  !provider.identifiers || provider.identifiers.length === 0;

const invalidBillingProvider = billingProvider =>
  !billingProvider ||
  billingProvider.type !== PROVIDER_TYPE.PROVIDER ||
  hasNoIdentifiers(billingProvider);

const findMatchingServiceFacilityOtherId = (billingProvider, providerValues) =>
  providerValues && providerValues.serviceFacilityOtherIdentifierId
    ? billingProvider.identifiers.find(
        x =>
          x.id === providerValues.serviceFacilityOtherIdentifierId &&
          ALLOWED_IDENTIFIERS.SERVICE_FACILITY.includes(x.type.value),
      )
    : null;

const findMatchingBilingProviderOtherId = (billingProvider, providerValues) =>
  providerValues && providerValues.billingProviderOtherIdentifierId
    ? billingProvider.identifiers.find(
        x =>
          x.id === providerValues.billingProviderOtherIdentifierId &&
          ALLOWED_IDENTIFIERS.BILLING_PROVIDER.includes(x.type.value),
      )
    : null;

const findMatchingRenderingProviderOtherId = (provider, providerValues) =>
  providerValues && providerValues.providerOtherIdentifierId
    ? provider.identifiers.find(
        x =>
          x.id === providerValues.providerOtherIdentifierId &&
          ALLOWED_IDENTIFIERS.RENDERING_PROVIDER.includes(x.type.value),
      )
    : null;

const getValidRenderingProviderIdentifier = provider =>
  firstAllowedIdentifier(
    provider.identifiers,
    ALLOWED_IDENTIFIERS[IDENTIFIER_FIELD_TYPE.RENDERING_PROVIDER],
  ) || null;

const getValidBillingProviderIdentifier = provider =>
  firstAllowedIdentifier(
    provider.identifiers,
    ALLOWED_IDENTIFIERS[IDENTIFIER_FIELD_TYPE.BILLING_PROVIDER],
  ) || null;

export const getServiceFacilityIdentifier = (
  billingProvider,
  billingProviderValues,
) => {
  if (invalidBillingProvider(billingProvider)) return null;

  return (
    findMatchingServiceFacilityOtherId(
      billingProvider,
      billingProviderValues,
    ) || null
  );
};

export const getBillingProviderIdentifier = (
  billingProvider,
  billingProviderValues,
) => {
  if (invalidBillingProvider(billingProvider)) return null;

  const customValue = findMatchingBilingProviderOtherId(
    billingProvider,
    billingProviderValues,
  );

  return customValue || getValidBillingProviderIdentifier(billingProvider);
};

export const getRenderingProviderIdentifier = (
  provider,
  providerValues,
  billingProviderId,
) => {
  if (hasNoIdentifiers(provider)) return null;

  const customValue = findMatchingRenderingProviderOtherId(
    provider,
    providerValues,
  );

  if (customValue) return customValue;

  if (isBillingProvider(provider, billingProviderId)) return null;

  return getValidRenderingProviderIdentifier(provider);
};

export const formatDateForAdditionalInfo = date =>
  date ? parseDate(date).format('YYYYMMDD') : '';

export const formatPracticeInfoForClaims = ({
  name,
  locations: [{ address1, address2, city, state, zipCode, zipcode }],
  phoneNumber,
}) => ({
  name,
  address: {
    address1,
    address2,
    city,
    state,
    zipCode: zipCode || zipcode || '',
  },
  phoneNumber: formatPhoneNumber(phoneNumber),
});

const formatClaimStatusLineItem = cs => [
  `${cs.code} - ${cs.description}`,
  `${cs.statusCode} - ${cs.statusCodeValue}`,
];

export const getClaimChargesWithLineItemInfo = (
  claimCharges,
  claimStatusLineItems,
) =>
  claimCharges.map(cc => {
    const lineItems = claimStatusLineItems
      ? claimStatusLineItems.reduce((accum, cs) => {
          if (cs.procedureCode === cc.code) {
            accum.push(formatClaimStatusLineItem(cs));
          }
          return accum;
        }, [])
      : [];

    return {
      ...cc,
      ...(lineItems.length && { claimStatusLineItems: lineItems }),
    };
  });

export const isClaimElectronic = invoiceClaimItem => {
  if (
    invoiceClaimItem.claimId &&
    invoiceClaimItem.paymentResponsibilityLevelCode ===
      PAYMENT_RESPONSIBILITY_LEVEL_CODE.SECONDARY &&
    invoiceClaimItem.submissionMethodSecondary
  ) {
    return (
      invoiceClaimItem.submissionMethodSecondary ===
      SUBMISSION_METHOD.ELECTRONIC_CLAIMS
    );
  }

  return (
    invoiceClaimItem.submissionMethod === SUBMISSION_METHOD.ELECTRONIC_CLAIMS
  );
};

export const isClaimScrubbed = claim => {
  if (claim.scrubbedAt) {
    return true;
  }

  return false;
};

const manualUpdateAllowedStatuses = [
  CLAIM_STATUS.ACCEPTED,
  CLAIM_STATUS.GENERATED,
  CLAIM_STATUS.TRANSMITTED,
  CLAIM_STATUS.RECEIVED,
  CLAIM_STATUS.PENDING,
  CLAIM_STATUS.DENIED,
  CLAIM_STATUS.REJECTED,
  CLAIM_STATUS.APPROVED,
  CLAIM_STATUS.ERA_EOB_RECEIVED_APPROVED,
  CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
  CLAIM_STATUS.DOWNLOADED,
  CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
];

export const manualUpdateNotAllowedStatuses = [
  CLAIM_STATUS.DRAFT,
  CLAIM_STATUS.NEEDS_ATTENTION,
  CLAIM_STATUS.ERROR,
  CLAIM_STATUS.VALIDATION_ERROR,
  CLAIM_STATUS.ERA_RECEIVED,
  CLAIM_STATUS.SUBMITTING,
  CLAIM_STATUS.READY_TO_DOWNLOAD,
  CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE,
];

const generateFromToStatusObject = statusFrom => ({
  from: statusFrom,
  to: manualUpdateAllowedStatuses.filter(status => status !== statusFrom),
});

const fromToTableEClaimStatuses = manualUpdateAllowedStatuses.map(status =>
  generateFromToStatusObject(status),
);

export const checkUnchangeableEclaimStatus = status =>
  manualUpdateNotAllowedStatuses.includes(status);

export const checkUnchangeablePaperClaimStatus = status =>
  isEditable(status) ||
  isCanceled(status) ||
  manualUpdateNotAllowedStatusesForPaperClaims.includes(status);

const filterElectronicClaimStatusAvailable = (statusesFrom, chcPayerId) => {
  const hasNoChangeableStatuses = statusesFrom.filter(status =>
    checkUnchangeableEclaimStatus(status),
  );

  if (hasNoChangeableStatuses && hasNoChangeableStatuses.length > 0) return [];

  const statusesTo = statusesFrom.map(status => {
    const statusFound = fromToTableEClaimStatuses.find(f => f.from === status);
    return statusFound ? statusFound.to : [];
  });

  let calculatedClaimStatuses = statusesTo.reduce(
    (previousStatus, currentStatus) =>
      previousStatus.filter(statusToSearch =>
        currentStatus.includes(statusToSearch),
      ),
  );

  if (chcPayerId) {
    calculatedClaimStatuses = calculatedClaimStatuses.reduce((acc, item) => {
      if (
        ![
          CLAIM_STATUS.DOWNLOADED,
          CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
        ].includes(item)
      ) {
        acc.push(item);
      }

      return acc;
    }, []);
  }

  return calculatedClaimStatuses;
};

const filterPaperClaimStatusAvailable = statusesFrom => {
  const hasNoChangeableStatuses = statusesFrom.filter(status =>
    isCanceled(status),
  );
  if (hasNoChangeableStatuses && hasNoChangeableStatuses.length > 0) return [];

  const allowedStatuses = Object.entries(CLAIM_STATUS).filter(
    status => !manualUpdateNotAllowedStatusesForPaperClaims.includes(status[1]),
  );

  const allowedStatusesAfterDiscardFrom = allowedStatuses
    .filter(status => !statusesFrom.includes(status[1]))
    .map(status => status[1]);

  return allowedStatusesAfterDiscardFrom.filter(
    status =>
      !isEditable(status) &&
      !isCanceled(status) &&
      status !== CLAIM_STATUS_PAPER.ERA_RECEIVED,
  );
};

export function filterStatusAvailableToChangeFromTo(
  isElectronicClaim,
  statusesFrom,
  chcPayerId,
) {
  if (
    !(statusesFrom && Array.isArray(statusesFrom) && statusesFrom.length > 0)
  ) {
    return [];
  }

  return isElectronicClaim
    ? filterElectronicClaimStatusAvailable(statusesFrom, chcPayerId)
    : filterPaperClaimStatusAvailable(statusesFrom);
}

const createSelectModel = ({ value, fieldType }) => ({
  label: value ? `${value} - ${fieldType[value]}` : '',
  data: { id: value || null },
});

export function getSupplementalInfoModel(model) {
  return {
    reportTypeCode: createSelectModel({
      value: model.reportTypeCode,
      fieldType: REPORT_TYPE_CODE,
    }),
    reportTransmissionCode: createSelectModel({
      value: model.reportTransmissionCode,
      fieldType: REPORT_TRANSMISSION_CODE,
    }),
    codeQualifier: createSelectModel({
      value: model.codeQualifier,
      fieldType: CODE_QUALIFIER,
    }),
    identificationNumber: model.identificationNumber,
  };
}

export function getOrderingProviderInfoModel(model) {
  return {
    orderingProviderCredentials: model.orderingProviderCredentials,
    orderingProviderFirstName: model.orderingProviderFirstName,
    orderingProviderLastName: model.orderingProviderLastName,
    orderingProviderMiddleName: model.orderingProviderMiddleName,
    orderingProviderNPI: model.orderingProviderNPI,
    orderingProviderOtherId: model.orderingProviderOtherId,
    orderingProviderQualifier: createSelectModel({
      value: model.orderingProviderQualifier,
      fieldType: OTHER_ID_QUALIFIER,
    }),
  };
}
