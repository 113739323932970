import '../inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/field-groups/neb-duration';
import { html, css } from 'lit';

import { CSS_COLOR_DISABLED } from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { PERIOD } from '../../../../packages/neb-utils/utils';
import { NebSimpleForm } from '../../forms/neb-simple-form';

export const ELEMENTS = {
  timeStart: {
    id: 'time-start',
  },
  timeEnd: {
    id: 'time-end',
  },
  checkboxAllDay: {
    id: 'checkbox-all-day',
  },
};

const dateRoundCoeff = 1000 * 60 * 5;

const START_OF_DAY_TIME = { hours: '12', minutes: '00', period: PERIOD.AM };
const END_OF_DAY_TIME = { hours: '11', minutes: '55', period: PERIOD.PM };

const getTime = date => {
  const dateValue = date || undefined;

  return {
    hours: (parseDate(dateValue).hours() % 12 || 12).toString(),
    minutes:
      (parseDate(dateValue).minutes() < 10 ? '0' : '') +
      parseDate(dateValue).minutes(),
    period: parseDate(dateValue).format('a'),
  };
};

const isEndDateNextDay = (date1, date2) => {
  const startDay = parseDate(date1).startOf('day');
  const endDay = parseDate(date2).startOf('day');

  return endDay.isAfter(startDay, 'day');
};

// eslint-disable-next-line complexity
export const isTimeLessThan = (time1, time2) => {
  const hours1 = time1.hours === '12' ? 0 : Number(time1.hours);
  const hours2 = time2.hours === '12' ? 0 : Number(time2.hours);
  const minutes1 = Number(time1.minutes);
  const minutes2 = Number(time2.minutes);

  if (time1.period === PERIOD.PM && time2.period === PERIOD.AM) {
    return false;
  }

  if (time1.period === PERIOD.AM && time2.period === PERIOD.PM) {
    return true;
  }

  return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2);
};

class NebStartEndTime extends NebSimpleForm {
  static get properties() {
    return {
      model: { type: Object },
      name: { type: String },
      __timeStart: { type: Object, state: true },
      __timeEnd: { type: Object, state: true },
      __allDay: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.__timeStart = START_OF_DAY_TIME;
    this.__timeEnd = START_OF_DAY_TIME;
    this.__allDay = false;

    this.name = '';
    this.model = {
      start: '',
      end: '',
    };

    this.onChange = () => {};

    this.onChangeAllDay = () => {};
  }

  static get styles() {
    return css`
      .container-input-time {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }

      .input {
        width: 100%;
      }

      .text-label {
        font-size: 12px;
        padding-left: 5px;
      }

      .container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      neb-checkbox {
        width: fit-content;
      }
    `;
  }

  __resetTimes() {
    this.__timeStart = { ...this.initialState.__timeStart };
    this.__timeEnd = { ...this.initialState.__timeEnd };
  }

  update(changedProperties) {
    if (!this.isInitializing) {
      if (changedProperties.has('__allDay')) {
        if (this.__allDay) {
          this.__timeStart = START_OF_DAY_TIME;
          this.__timeEnd = END_OF_DAY_TIME;
        } else {
          this.__resetTimes();
        }
      }
    } else if (
      [
        this.__timeStart.hours === '12',
        this.__timeStart.minutes === '00',
        this.__timeStart.period === PERIOD.AM,
        this.__timeEnd.hours === '11',
        this.__timeEnd.minutes === '55',
        this.__timeEnd.period === PERIOD.PM,
      ].every(Boolean)
    ) {
      this.__allDay = true;
    }

    this.onChange({
      name: this.name,
      value: {
        start: this.__timeStart,
        end: this.__timeEnd,
      },
    });

    super.update(changedProperties);
  }

  createSelectors() {
    const currentRoundedDate = parseDate(
      Math.ceil(new Date().getTime() / dateRoundCoeff) * dateRoundCoeff,
    ).startOf('minute');

    return {
      start: {
        stateKey: '__timeStart',
        toState: value => {
          if (!value) {
            const date = currentRoundedDate.toDate();

            return getTime(date);
          }

          return getTime(value);
        },
        alwaysValidate: true,
        validate: value =>
          isTimeLessThan(value, this.__timeEnd) ? '' : 'Conflicting Time',
      },
      end: {
        stateKey: '__timeEnd',
        toState: value => {
          if (!value) {
            const date = currentRoundedDate.add(30, 'minutes').toDate();

            const endOfDay = parseDate()
              .endOf('day')
              .minutes(55)
              .seconds(0)
              .toDate();

            return getTime(date > endOfDay ? endOfDay : date);
          }

          if (isEndDateNextDay(this.model.start, this.model.end)) {
            return END_OF_DAY_TIME;
          }

          return getTime(value);
        },
      },
    };
  }

  render() {
    return html`
      <div class="container">
        <neb-checkbox
          id="${ELEMENTS.checkboxAllDay.id}"
          label="All Day"
          name="__allDay"
          .checked="${this.__allDay}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>

        <div class="container-input-time">
          <div>
            <span
              class="text-label"
              style="${this.__allDay ? `color: ${CSS_COLOR_DISABLED};` : ''}"
            >
              Start Time
            </span>

            <neb-duration
              id="${ELEMENTS.timeStart.id}"
              class="input"
              name="__timeStart"
              .model="${this.__timeStart}"
              .onChange="${this.handlers.change}"
              .helper="${' '}"
              .error="${this.errors.start}"
              ?disabled="${this.__allDay}"
            >
            </neb-duration>
          </div>

          <div>
            <span
              class="text-label"
              style="${this.__allDay ? `color: ${CSS_COLOR_DISABLED};` : ''}"
            >
              End Time
            </span>

            <neb-duration
              id="${ELEMENTS.timeEnd.id}"
              class="input"
              name="__timeEnd"
              .model="${this.__timeEnd}"
              .onChange="${this.handlers.change}"
              .helper="${' '}"
              .error="${this.errors.start}"
              ?disabled="${this.__allDay}"
            >
            </neb-duration>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-start-end-time', NebStartEndTime);
