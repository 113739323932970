import '../../inputs/neb-select';
import '../../inputs/neb-textfield';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_BADGE,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../../neb-styles/neb-variables';
import {
  currency,
  currencyNegative,
  number,
} from '../../../../../neb-utils/masks';
import {
  NebExpandableRow,
  ELEMENTS as BASE_ELEMENTS,
} from '../../neb-expandable-row';

import {
  buildHandlers,
  calculateOwed,
  KEYS,
  ROW_ELEMENTS,
} from './neb-ledger-purchase-charges-row-util';

export const ELEMENTS = { ...BASE_ELEMENTS, ...ROW_ELEMENTS };
const ITEM_HEIGHT = 80;

class NebLedgerPurchaseChargesMobileRow extends NebExpandableRow {
  static get properties() {
    return {
      ...super.properties,
      error: Object,
      itemsMap: Object,
      model: Object,
      __invalidAdjustmentAmount: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  initState() {
    super.initState();

    this.error = {
      [KEYS.UNIT_CHARGE]: ' ',
      [KEYS.ADJUSTMENT_AMOUNT]: ' ',
    };

    this.itemsMap = {
      adjustments: [],
      taxRates: [],
    };

    this.model = {
      code: '',
      description: '',
      units: '1',
      unitCharge: 0,
      adjustment: '',
      adjustmentAmount: '$0.00',
      owed: '$0.00',
      tax: '',
    };

    this.__invalidAdjustmentAmount = false;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      ...buildHandlers(this),
      change: e => this.onChange(e),
    };
  }

  change(key, value) {
    this.onChange({ ...this.model, [key]: value });
  }

  updated() {
    this.__elements = {
      unitChargeField: this.shadowRoot.getElementById(
        ELEMENTS.unitChargeField.id,
      ),
      unitsField: this.shadowRoot.getElementById(ELEMENTS.unitsField.id),
      adjustmentAmountField: this.shadowRoot.getElementById(
        ELEMENTS.adjustmentAmountField.id,
      ),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .column-container {
          display: contents;
          flex: 1 0 0;
        }

        .description {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .flex {
          flex: 1 0 0;
        }

        .cell:first-child {
          overflow-wrap: break-word;
          display: block;
        }

        .cell:nth-child(2) {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          min-width: auto;
          overflow: hidden;
        }

        .grid {
          display: grid;
          grid-template-columns: minMax(60px, auto) minMax(80px, auto) minMax(
              160px,
              auto
            );
          grid-gap: 20px;
          padding-top: ${CSS_SPACING};
        }

        :host([__invalidAdjustmentAmount]) .adjustment-amount-container {
          display: flex;
          flex-direction: column;
        }

        .adjustment-amount-error {
          color: ${CSS_COLOR_BADGE};
          font-size: ${CSS_FONT_SIZE_CAPTION};
          white-space: nowrap;
        }

        .row-header {
          display: flex;
          justify-content: space-between;
        }

        .row-text {
          font-weight: bold;
        }

        .text-center {
          display: flex;
          align-items: center;
        }

        .owed {
          padding-bottom: 14px;
        }
      `,
    ];
  }

  __renderTopRow() {
    return html`
      <div class="row-text">Units</div>
      <div class="row-text">Unit Charge</div>
      <div class="row-text">Adjustment</div>

      ${this.__renderUnitsField()} ${this.__renderUnitChargeField()}
      ${this.__renderAdjustmentField()}
    `;
  }

  __renderUnitsField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.unitsField.id}"
        name="${this.index}.units"
        maxLength="3"
        labelText=" "
        inputMode="numeric"
        .mask="${number}"
        .value="${this.model.units}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderUnitChargeField() {
    const { unitCharge, type } = this.model;
    return type
      ? html`
          <neb-textfield
            id="${ELEMENTS.unitChargeField.id}"
            name="${this.index}.unitCharge"
            labelText=" "
            maxLength="11"
            helper="Required"
            inputMode="numeric"
            .mask="${currency}"
            .value="${unitCharge}"
            .onChange="${this.handlers.change}"
            .error="${this.error[KEYS.UNIT_CHARGE]}"
          ></neb-textfield>
        `
      : html`
          <div id="${ELEMENTS.unitChargeField.id}" class="text-center">
            ${unitCharge}
          </div>
        `;
  }

  __renderAdjustmentField() {
    return html`
      <neb-select
        id="${ELEMENTS.adjustmentField.id}"
        class="flex"
        name="${this.index}.adjustment"
        forceAlignMenu="right"
        .items="${this.itemsMap.adjustments}"
        .value="${this.model.adjustment}"
        .onChange="${this.handlers.change}"
        .onRenderItem="${this.handlers.renderMobileAdjustmentItems}"
        .itemHeight="${ITEM_HEIGHT}"
        showFullText
        wrapText
      ></neb-select>
    `;
  }

  __renderAdjustmentAmountField() {
    const { adjustmentAmount, adjustment } = this.model;
    const isDisabled = adjustment ? adjustment.data.id === '' : true;
    return html`
      <div class="adjustment-amount-container">
        <neb-textfield
          id="${ELEMENTS.adjustmentAmountField.id}"
          name="${this.index}.adjustmentAmount"
          labelText=" "
          inputMode="numeric"
          no-wrap
          maxLength="10"
          .mask="${currencyNegative}"
          .value="${adjustmentAmount}"
          .onChange="${this.handlers.change}"
          .helper="${isDisabled ? ' ' : 'Required'}"
          .error="${this.error[KEYS.ADJUSTMENT_AMOUNT]}"
          ?disabled="${isDisabled}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderOwedField() {
    return html`
      <div id="${ELEMENTS.owedField.id}" class="owed text-center">
        ${calculateOwed(this.model)}
      </div>
    `;
  }

  __renderTaxField() {
    return html`
      <neb-select
        id="${ELEMENTS.taxField.id}"
        class="flex"
        name="${this.index}.taxId"
        .items="${this.itemsMap.taxRates}"
        .value="${this.model.taxId}"
        .onChange="${this.handlers.change}"
        showFullText
      ></neb-select>
    `;
  }

  __renderBottomRow() {
    return html`
      <div class="row-text">Amount</div>
      <div class="row-text">Owed</div>
      <div class="row-text">Tax</div>

      ${this.__renderAdjustmentAmountField()} ${this.__renderOwedField()}
      ${this.__renderTaxField()}
    `;
  }

  renderExpandedContent() {
    return html`
      <div class="flex grid">
        ${this.__renderTopRow()} ${this.__renderBottomRow()}
      </div>
    `;
  }
}

customElements.define(
  'neb-ledger-purchase-charges-mobile-row',
  NebLedgerPurchaseChargesMobileRow,
);
