import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_3,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
  label: {
    id: 'label',
  },
};

class NebSwitch extends LitElement {
  static get properties() {
    return {
      __active: {
        reflect: true,
        type: Boolean,
        attribute: 'active',
      },

      disabled: {
        type: Boolean,
        reflect: true,
      },
      label: String,
      name: String,
      on: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          user-select: none;
          width: max-content;
        }

        :host(:not([disabled])) {
          cursor: pointer;
        }

        .container {
          display: flex;
          height: 28px;
          align-items: center;
          background-color: transparent;
          justify-content: space-around;
        }

        .track {
          width: 38px;
          height: 14px;
          border-radius: 9.8px;
          transition: all 100ms;
        }

        :host(:not([on])[disabled]) .track {
          background-color: #9f9f9f;
          opacity: 0.38;
        }

        :host([on][disabled]) .track {
          background-color: #7ccbe3;
          opacity: 0.38;
        }

        :host([on]:not([disabled])) .track {
          background-color: #7ccbe3;
          opacity: 1;
        }

        :host(:not([on]):not([disabled])) .track {
          background-color: #9f9f9f;
          opacity: 1;
        }

        :host(:not([disabled])) .dial {
          cursor: pointer;
        }

        .dial {
          position: relative;
          top: -4.2px;
          left: 0;
          width: 22px;
          height: 22px;
          border-radius: 14px;
          background-color: ${CSS_COLOR_GREY_3};
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.6);
          transition: all 100ms;
        }

        :host([on]) .dial {
          left: 16px;
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .dial {
          background-color: ${CSS_COLOR_GREY_3};
        }

        :host([on][disabled]) .dial {
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .ripple {
          position: relative;
          width: 0;
          height: 0;
          opacity: 0;
          border: 24px solid ${CSS_COLOR_GREY_1};
          border-radius: 24px;
          z-index: -1;
          transform: translate(-13px, -13px);
          transition: opacity 200ms;
        }

        :host([on]:not([disabled])) .ripple {
          border: 24px solid ${CSS_COLOR_HIGHLIGHT};
        }

        :host([active]:not([disabled])) .ripple {
          opacity: 0.2;
          z-index: 1;
        }

        :host([disabled]) .button {
          cursor: default;
        }

        :host(:not([disabled])) .label {
          cursor: pointer;
        }

        .label {
          user-select: none;
          margin-right: auto;
          padding-left: 11.2px;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        :host([on]) .label {
          color: ${CSS_COLOR_BLACK};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__active = false;

    this.disabled = false;
    this.label = '';
    this.name = '';
    this.on = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      activate: () => {
        this.__active = true;
      },
      deactivate: () => {
        this.__active = false;
      },
      click: e => {
        e.stopPropagation();

        if (!this.disabled) {
          this.onChange({
            name: this.name,
            value: !this.on,
          });
        }
      },
      keypress: e => {
        e.stopPropagation();

        if (e.key === 'Enter' || e.key === ' ') {
          if (!this.disabled) {
            this.onChange({
              name: this.name,
              value: !this.on,
            });
          }
        }
      },
    };
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.button.id}"
        class="container"
        tabindex="${this.disabled ? -1 : 0}"
        @click="${this.__handlers.click}"
        @keypress="${this.__handlers.keypress}"
        @focus="${this.__handlers.activate}"
        @blur="${this.__handlers.deactivate}"
        @mouseover="${this.__handlers.activate}"
        @mouseleave="${this.__handlers.deactivate}"
      >
        <div class="track">
          <div class="dial"><div class="ripple"></div></div>
        </div>

        <label id="${ELEMENTS.label.id}" class="label">${this.label}</label>
      </div>
    `;
  }
}

customElements.define('neb-switch', NebSwitch);
