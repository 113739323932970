export const NavigationResult = Object.freeze({
  PATIENT_DOCUMENT_INFO: 'patient-document-info',
  PATIENT_DOCUMENT_LIST: 'patient-document-list',
  CHARTING_DOCUMENT_INFO: 'charting-document-info',
  CHARTING_DOCUMENT_LIST: 'charting-document-list',
  UNKNOWN: 'unknown',
});
const REGEX_PATIENT_DOCUMENTS_GROUP_1 = 'patients';
const REGEX_PATIENT_DOCUMENTS_GROUP_2 = 'documents';
const REGEX_CHARTING_DOCUMENTS_GROUP_1 = 'charting';
const REGEX_CHARTING_DOCUMENTS_GROUP_2 = 'documents';
export const REGEX_PATIENT_DOCUMENTS_ROUTE =
  /#\/(patients)+\/[^/]+\/(documents)+\/?([^/]*)*/;
export const REGEX_CHARTING_DOCUMENTS_ROUTE =
  /#\/(charting)+\/[^/]+\/(documents)+\/?([^/]*)*/;

const __isDocumentMatch = (match, group1, group2) =>
  match &&
  match.length === 4 &&
  match[0] &&
  match[1] === group1 &&
  match[2] === group2;

const __isChartingtDocumentMatch = match =>
  __isDocumentMatch(
    match,
    REGEX_CHARTING_DOCUMENTS_GROUP_1,
    REGEX_CHARTING_DOCUMENTS_GROUP_2,
  );

const __isPatientDocumenMatch = match =>
  __isDocumentMatch(
    match,
    REGEX_PATIENT_DOCUMENTS_GROUP_1,
    REGEX_PATIENT_DOCUMENTS_GROUP_2,
  );

const __parseChartingDocumenMatch = url => {
  const match = url.match(REGEX_CHARTING_DOCUMENTS_ROUTE);

  if (__isChartingtDocumentMatch(match)) {
    if (match[3] != null) {
      return {
        navigationResult: NavigationResult.CHARTING_DOCUMENT_INFO,
        id: match[3],
      };
    }

    return {
      navigationResult: NavigationResult.CHARTING_DOCUMENT_LIST,
      id: null,
    };
  }

  return null;
};

const __parsePatientDocumenMatch = url => {
  const match = url.match(REGEX_PATIENT_DOCUMENTS_ROUTE);

  if (__isPatientDocumenMatch(match)) {
    if (match[3] != null) {
      return {
        navigationResult: NavigationResult.PATIENT_DOCUMENT_INFO,
        id: match[3],
      };
    }

    return {
      navigationResult: NavigationResult.PATIENT_DOCUMENT_LIST,
      id: null,
    };
  }

  return null;
};

export const parseUrl = url => {
  let result;

  const defaultResult = {
    navigationResult: NavigationResult.UNKNOWN,
    id: null,
  };

  if (!url) {
    return defaultResult;
  }

  result = __parsePatientDocumenMatch(url);

  if (result) {
    return result;
  }

  result = __parseChartingDocumenMatch(url);

  if (result) {
    return result;
  }

  return defaultResult;
};

export const removeNonASCII = string =>
  string.replace(/[\u{0080}-\u{FFFF}]/gu, '');
