import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  notInvoicedCheckbox: { id: 'checkbox-not-invoiced' },
  invoiceTextfield: { id: 'textfield-invoice' },
  dateRange: { id: 'range-date' },
  balanceRange: { id: 'range-balance' },
  patientSearch: { id: 'search-patient' },
  purchaseTypeTextfield: { id: 'textfield-purchase-type' },
};

class NebFiltersLedgerPurchases extends NebFilters {
  static get properties() {
    return {
      __patientItems: Array,
      enablePatient: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    return {
      notInvoiced: false,
      invoice: '',
      patientId: '',
      date: NebDateRange.createModel(),
      balance: NebCurrencyRange.createModel(),
      purchaseType: '',
    };
  }

  initState() {
    super.initState();
    this.__patientItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      changePatients: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        date: NebDateRange.createSelectors(),
        balance: NebCurrencyRange.createSelectors(),
      },
    };
  }

  initPatientsService() {
    this.__patientsService = createServerPatientsCollection({
      onChange: this.handlers.changePatients,
    });
  }

  load() {
    return Promise.all([this.initPatientsService()]);
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      ${this.renderPatientFilter()}

      <neb-textfield
        id="${ELEMENTS.purchaseTypeTextfield.id}"
        name="purchaseType"
        label="Purchase Type"
        helper=" "
        .value="${this.state.purchaseType}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-range-date
        id="${ELEMENTS.dateRange.id}"
        name="date"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <div class="grid grid-auto-right">
        <neb-textfield
          id="${ELEMENTS.invoiceTextfield.id}"
          name="invoice"
          label="Invoice #"
          helper=" "
          leadingIcon="neb:search"
          .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
          .value="${this.state.invoice}"
          .mask="${number}"
          .onChange="${this.handlers.change}"
          .onClickIcon="${this.handlers.clearInvoiceSearch}"
          ?disabled="${this.state.notInvoiced}"
        ></neb-textfield>

        <neb-checkbox
          id="${ELEMENTS.notInvoicedCheckbox.id}"
          name="notInvoiced"
          label="Not Invoiced"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.notInvoiced}"
        ></neb-checkbox>
      </div>

      <neb-range-currency
        id="${ELEMENTS.balanceRange.id}"
        name="balance"
        label="Balance"
        .model="${this.state.balance}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>
    `;
  }
}

window.customElements.define(
  'neb-filters-ledger-purchases',
  NebFiltersLedgerPurchases,
);
