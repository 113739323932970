import DeprecatedApiClient, {
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClientBilling = new DeprecatedApiClient({
  microservice: 'billing',
});

const getPath = (patientId, caseId) =>
  `patients/${patientId}/cases/${caseId}/statements`;

function buildRequestor(apiClient) {
  return (method, path, body = null, opts = {}) => {
    const {
      version,
      query,
      responseType = RESPONSE_TYPE.JSON,
      includeContentType = true,
      optOutLoadingIndicator = true,
    } = opts;

    return apiClient.makeRequest({
      path,
      method,
      version: version || undefined,
      responseType,
      queryParams: query || {},
      body: body ? JSON.stringify(body) : undefined,
      ...(includeContentType && {
        headers: { 'Content-Type': 'application/json' },
      }),
      optOutLoadingIndicator,
    });
  };
}

const makeBillingRequest = buildRequestor(apiClientBilling);

export async function getAllCaseStatements(patientId, caseId, query = {}) {
  try {
    const opts = {
      headers: {
        'Content-Type': 'application/json',
      },
      version: 1,
      query,
    };

    const allCaseStatements = await makeBillingRequest(
      'GET',
      getPath(patientId, caseId),
      null,
      opts,
    );

    return allCaseStatements;
  } catch (err) {
    return null;
  }
}

export async function generateCaseStatements(patientId, caseId, data) {
  const opts = {
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    responseType: RESPONSE_TYPE.JSON,
  };

  const buffer = await makeBillingRequest(
    'POST',
    getPath(patientId, caseId),
    data,
    opts,
  );

  return buffer;
}
