import '../../inputs/neb-select-search';
import '../../../../../neb-material-design/src/components/neb-md-textfield-with-icon';

import { LitElement, html, css } from 'lit';

import * as caseApi from '../../../../../neb-api-client/src/patient-cases';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { parseDate } from '../../../../../neb-utils/date-util';
import { normalizeForSearch } from '../../../../../neb-utils/formatters';
import { CollectionService } from '../../../../../neb-utils/services/collection';

export const ELEMENTS = {
  container: { id: 'container' },
  selectSearch: { id: 'select-search' },
};

class NebPatientCaseSearch extends LitElement {
  static get properties() {
    return {
      __cases: Array,
      __caseSearch: String,
      __selectedCase: Object,

      patientId: String,
      caseId: String,
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__allCases = [];
    this.__cases = [];
    this.__caseSearch = '';
    this.__selectedCase = null;
    this.__sortParams = {
      key: 'onsetSymptomsDate',
      dir: 'asc',
    };

    this.patientId = null;
    this.caseId = null;
    this.disabled = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: ({ value }) => {
        this.onChange(value ? value.id : null);
      },
      search: ({ value }) => {
        this.__caseSearch = value;
        this.__collectionService.search(value);
      },
    };
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageItems, sortParams }) => {
          this.__cases = pageItems;
          this.sortParams = sortParams;
        },

        onCacheItem: item => this.__searchTermsNameDate(item),

        onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),

        onSort: (a, b, key) => {
          const A = a[key] != null ? a[key].toString().toUpperCase() : '';
          const B = b[key] != null ? b[key].toString().toUpperCase() : '';

          if (A !== B) {
            return A < B ? 1 : -1;
          }

          return 0;
        },
      },
      {
        hideInactive: true,
        sortParams: this.__sortParams,
      },
    );
  }

  async __loadCases(patientId) {
    const cases = await caseApi.fetchMany(patientId, true);
    this.__allCases = cases.map(patientCase => ({
      ...patientCase,
      label: `${patientCase.name} - ${this.__formatGradualDate(
        patientCase.onsetSymptomsDate,
      )}`,
    }));

    this.__collectionService.setItems(this.__allCases);

    this.__collectionService.setPageSize(this.__allCases.length);
  }

  __formatGradualDate(date, dateFormat = 'MM/DD/YYYY') {
    return date ? parseDate(date).format(dateFormat) : 'Gradual';
  }

  __searchTermsNameDate(item) {
    return normalizeForSearch(
      [item.name, this.__formatGradualDate(item.onsetSymptomsDate, 'MMDDYYYY')]
        .filter(term => term)
        .join(' '),
    );
  }

  update(changedProps) {
    if (changedProps.has('patientId') && this.patientId) {
      this.__loadCases(this.patientId);
    }

    if (changedProps.has('caseId')) {
      if (this.patientId) {
        this.__loadCases(this.patientId);
      }

      this.__selectedCase = this.caseId
        ? this.__allCases.find(x => x.id === this.caseId)
        : null;
    }

    if (changedProps.has('__cases')) {
      this.__selectedCase = this.caseId
        ? this.__allCases.find(x => x.id === this.caseId)
        : null;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          z-index: 1;
        }

        .container {
          display: block;
          width: 100%;
          height: 100%;
        }

        .select-search {
          display: block;
          position: relative;
          width: 100%;
          z-index: 1;
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-select-search
          id="${ELEMENTS.selectSearch.id}"
          class="select-search"
          name="selectCase"
          emptyMessage="There are no cases for this patient."
          label="Case"
          .items="${this.__cases}"
          .search="${this.__caseSearch}"
          .value="${this.__selectedCase}"
          .onChange="${this.__handlers.change}"
          .onSearch="${this.__handlers.search}"
          ?disabled="${this.disabled}"
          showSearch
        ></neb-select-search>
      </div>
    `;
  }
}

window.customElements.define('neb-patient-case-search', NebPatientCaseSearch);
