import { QUESTION_ANSWER_TOKEN } from '../../utils/macros';

export const formatAnswerSet = (answerSets, type) => {
  const namedAnswerSets = Object.entries(answerSets).reduce(
    (acc, [key, value]) => (value.name ? [...acc, { id: key, ...value }] : acc),
    [],
  );

  switch (type) {
    case 'choose':
      return namedAnswerSets.filter(
        a =>
          a.type === QUESTION_ANSWER_TOKEN.chooseAny ||
          a.type === QUESTION_ANSWER_TOKEN.chooseOne,
      );

    case QUESTION_ANSWER_TOKEN.region:
      return namedAnswerSets.filter(
        a => a.type === QUESTION_ANSWER_TOKEN.region,
      );

    default:
      return namedAnswerSets;
  }
};

export const formatChooseAnswerSet = (answerSet, type) => ({
  ...answerSet,
  answers: answerSet.answers.map(({ id, value }) => ({
    id: id || null,
    value,
  })),
  type,
});

export const formatAnswerSetsToDict = answerSets =>
  answerSets.reduce(
    (acc, { id, ...answerSet }) => ({
      ...acc,
      [id]: answerSet,
    }),
    {},
  );
