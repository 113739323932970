import '../../../packages/neb-lit-components/src/components/neb-button';
import '../../../packages/neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../components/misc/neb-icon';

import { LitElement, css, html, nothing } from 'lit';

import { SMALL_LAYOUT_WIDTH_PERCENT } from '../../../packages/neb-utils/payment-popup-constants';

import { createFiservIframeUrl, getCardTypeFromToken } from './utils';

export const ELEMENTS = {
  iframeSpinner: {
    id: 'iframe-spinner',
  },
  fiservIframe: {
    id: 'fiserv-iframe',
  },
  cardLabel: {
    id: 'card-label',
  },
  expirationLabel: {
    id: 'expiration-label',
  },
  cvvLabel: {
    id: 'cvv-label',
  },
  confirmButton: {
    id: 'confirm-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
  creditCardBrand: {
    id: 'credit-card-brand',
  },
};

class NebFiservIframe extends LitElement {
  static get properties() {
    return {
      layout: { type: String, reflect: true },
      isProcessing: Boolean,
      confirmLabel: String,
      cancelLabel: String,
      __token: String,
    };
  }

  static get styles() {
    return css`
      iframe {
        border: none;
      }

      :host([layout='small']) .iframe-wrapper {
        transform: scale(${SMALL_LAYOUT_WIDTH_PERCENT});
        transform-origin: 0 0;
        width: 485px;
      }

      :host([layout='small']) .action-buttons {
        transform: translateY(-85px);
      }

      .opacity-0 {
        opacity: 0;
      }

      .label {
        font-size: 12px;
        letter-spacing: 2px;
        font-family: 'Open Sans', sans-serif;
        color: white;
        position: absolute;
      }

      .iframe-wrapper {
        position: relative;
        border-radius: 16px;
        background: linear-gradient(
          90deg,
          rgba(0, 28, 36, 1) 36%,
          rgba(2, 48, 67, 1) 55%,
          rgba(4, 70, 100, 1) 100%,
          rgba(13, 170, 255, 1) 100%
        );
      }

      #credit-card-brand {
        position: absolute;
        top: 58.5px;
        left: 400px;
        height: 40px;
        width: 75.6px;
      }

      #card-label {
        top: 38.5px;
        left: 20px;
      }

      #expiration-label {
        left: 262px;
        top: 140px;
      }

      #cvv-label {
        left: 378.45px;
        top: 140px;
      }

      .action-buttons {
        padding-top: 20px;
        display: flex;
        gap: 20px;
      }

      .spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 246px;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__fiservIframeUrl = createFiservIframeUrl();
    this.__token = '';
    this.__expiry = '';
    this.__waitingForToken = false;
    this.__iframeRenderedAt = 0;

    this.confirmLabel = '';
    this.cancelLabel = '';
    this.isProcessing = false;

    this.onDirty = () => {};

    this.onCancel = () => {};

    this.onConfirm = async () => {};

    this.onProcessing = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      iframeOnLoad: ({ target }) => {
        const timeDiff = Date.now() - this.__iframeRenderedAt;
        setTimeout(() => target.classList.remove('opacity-0'), timeDiff);
      },

      confirm: async () => {
        if (this.__waitingForToken) {
          return;
        }

        try {
          if (!this.__token && !(await this.__waitForToken())) {
            return;
          }
        } finally {
          this.__waitingForToken = false;
        }

        this.onProcessing(true);

        try {
          await this.onConfirm({ token: this.__token, expiry: this.__expiry });
        } finally {
          this.__token = '';
          this.__expiry = '';
          this.onProcessing(false);
        }
      },

      cancel: () => this.onCancel(),

      messageReceived: ({ origin, data }) => {
        if (origin !== this.__fiservIframeUrl.origin) return;

        const parsedData = JSON.parse(data);

        if (parsedData.cardTyping || parsedData.validationError) {
          this.onDirty(true);
        }

        if ('message' in parsedData) {
          this.__token = parsedData.message;
        }

        if ('expiry' in parsedData) {
          this.__expiry = parsedData.expiry;
        }
      },
    };
  }

  async __waitForToken(round = 1) {
    this.__waitingForToken = true;
    await new Promise(resolve => setTimeout(resolve, 500));

    if (this.__token) {
      return true;
    }

    if (round >= 10) {
      return false;
    }

    return this.__waitForToken(round + 1);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this.__handlers.messageReceived);
  }

  disconnectedCallback() {
    window.removeEventListener('message', this.__handlers.messageReceived);
    super.disconnectedCallback();
  }

  __renderCardBrand(token) {
    let icon = '';

    switch (getCardTypeFromToken(token)) {
      case 'VISA':
        icon = 'neb:logoVisa';
        break;
      case 'MC':
        icon = 'neb:logoMasterCard';
        break;
      case 'AMEX':
        icon = 'neb:logoAmex';
        break;
      case 'DS':
        icon = 'neb:logoDiscovery';
        break;
      default:
        return nothing;
    }

    return html`
      <neb-icon id="${ELEMENTS.creditCardBrand.id}" icon="${icon}"></neb-icon>
    `;
  }

  render() {
    if (this.isProcessing) {
      return html`
        <div class="spinner-wrapper">
          <neb-loading-spinner-resizable
            id="${ELEMENTS.iframeSpinner.id}"
          ></neb-loading-spinner-resizable>
        </div>
      `;
    }

    this.__iframeRenderedAt = Date.now();

    return html`
      <div class="iframe-wrapper">
        <iframe
          id="${ELEMENTS.fiservIframe.id}"
          src="${this.__fiservIframeUrl.toString()}"
          class="opacity-0"
          height="246px"
          width="485px"
          @load="${this.__handlers.iframeOnLoad}"
        ></iframe>
        ${this.__renderCardBrand(this.__token)}
        <label class="label" id="${ELEMENTS.cardLabel.id}">Card Number</label>
        <label class="label" id="${ELEMENTS.expirationLabel.id}"
          >Expiration</label
        >
        <label class="label" id="${ELEMENTS.cvvLabel.id}">CVV</label>
      </div>
      <div class="action-buttons">
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          role="confirm"
          .label="${this.confirmLabel}"
          .onClick="${this.__handlers.confirm}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          role="outline"
          .label="${this.cancelLabel}"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-fiserv-iframe', NebFiservIframe);
