import '../../../../packages/neb-document/src/components/neb-documents-detail';

import { html, css } from 'lit';

import { ELEMENTS as TAB_ELEMENTS } from '../../../components/tabs/neb-tabs-documents';
import { StoreController } from '../../../store/neb-store-controller';
import { CSS_COLOR_WHITE } from '../../../styles';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';

import { UnsignedEncounterDocumentsReactiveController } from './neb-unsigned-encounter-documents-reactive-controller';

const PATIENT_DOCUMENTS_TAB = TAB_ELEMENTS.patientDocumentsTab.id;
const DOCUMENTS_ITEM_NAME = 'Documents';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  detailView: {
    id: 'detail',
  },
  documentsTabs: {
    id: 'documents-tabs',
  },
};

export class NebUnsignedEncounterDocumentsDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      selectedDocumentIndex: { type: Number },
      selectedDocument: { type: Object },
      imageSrcSpec: { type: String },
      initialModel: { type: Object },
      selectedTab: { type: String },
      encounterDocuments: { type: Array },
      touchDevice: { type: Boolean },
      showLocalLoading: { type: Boolean },
    };
  }

  static get config() {
    return {
      itemName: DOCUMENTS_ITEM_NAME,
      refetchKey: PANEL_KEYS.DOCUMENTS,
    };
  }

  createModel() {
    return {
      count: 0,
      data: [],
    };
  }

  getTitle() {
    return DOCUMENTS_ITEM_NAME;
  }

  initState() {
    super.initState();
    this.__controller = new UnsignedEncounterDocumentsReactiveController(this);

    this.selectedDocumentIndex = -1;
    this.selectedDocument = null;
    this.initialModel = this.createModel();
    this.selectedTab = PATIENT_DOCUMENTS_TAB;
    this.encounterDocuments = this.createModel();
    this.touchDevice = false;
    this.showLocalLoading = false;

    this.onSelectDocumentIndex = () => {};
    this.__storeController = new StoreController(this, {
      stateKeys: {
        layoutMedia: 'layoutMedia',
      },
      onStateChanged: ({ layoutMedia }) => {
        this.touchDevice = layoutMedia.touchDevice;
      },
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      onUpdateSelectedTab: async selectedTab => {
        if (this.selectedTab !== selectedTab) {
          this.selectedTab = selectedTab;
          this.__controller.resetCheckedDocuments();
          await this.__controller.getMoreDocuments(true, null, true);
        }
      },
      openPopupUpload: () => this.__controller.openPopupUpload(),

      openPopupEdit: () => this.__controller.openPopupEdit(),

      openPopupScan: () => this.__controller.openPopupScan(),

      getMoreDocuments: () =>
        this.__controller.getMoreDocuments(false, null, false),
      selectDocumentIndex: index => this.setIndex(index),
      search: text => this.__controller.search(text),
      checkDocument: (documentId, checked) =>
        this.__controller.checkDocument(documentId, checked),
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: () => {
            this.__controller.setAllCheckboxesSelection(true);
          },
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: () => {
            this.__controller.setAllCheckboxesSelection(false);
          },
        },
        this.__getEncounterBulkAction(),
      ],
      cancel: () => {
        this.onRefetch(this.__config.refetchKey);

        return this.onClose();
      },
    };
  }

  setIndex(index) {
    return this.onSelectDocumentIndex(index);
  }

  get elements() {
    return {
      detailView: this.shadowRoot.getElementById(ELEMENTS.detailView.id),
      documentsTabs: this.shadowRoot.getElementById(ELEMENTS.documentsTabs.id),
    };
  }

  __getEncounterBulkAction() {
    if (this.__controller.isEncounterDocuments()) {
      return {
        id: 'removeFromEncounter',
        label: 'Remove from Current Encounter',
        onSelect: () => {
          this.__controller.removeFromEncounter();
        },
      };
    }

    return {
      id: 'associateToCurrentEncounter',
      label: 'Associate to Current Encounter',
      onSelect: () => {
        this.__controller.associateToEncounter();
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('initialModel')) {
      this.model = { ...this.initialModel };

      if (this.model.count > 0 && this.selectedDocumentIndex === -1) {
        this.setIndex(0);
      }
    }

    if (changedProps.has('selectedDocumentIndex') && this.encounter.id) {
      this.__controller.setDocument(this.selectedDocumentIndex);
    }
    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          margin: 0;
          flex: 1 0 0;
          min-height: 0;
          height: 54px;
          background-color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-tabs-documents
        id="${ELEMENTS.documentsTabs.id}"
        class="content"
        .layout="${this.layout}"
        .small="${this.layout === 'small'}"
        .onUpdateSelectedTab="${this.handlers.onUpdateSelectedTab}"
        .documentCount="${this.model.count}"
        .encounterDocumentCount="${this.encounterDocuments.count}"
        .selectedTab="${this.selectedTab}"
        .firstTab="${PATIENT_DOCUMENTS_TAB}"
      ></neb-tabs-documents>

      <neb-documents-detail
        slot="detail"
        id="${ELEMENTS.detailView.id}"
        .documents="${this.__controller.state.documents}"
        .expanded="${true}"
        .totalDocuments="${this.__controller.state.totalDocuments}"
        .selectedIndex="${this.selectedDocumentIndex}"
        .selectedDocument="${this.selectedDocument}"
        .imageSrcSpec="${this.imageSrcSpec}"
        .loadingImageSrcSpec="${this.showLocalLoading}"
        .showLocalLoading="${this.showLocalLoading}"
        .onOpenPopupUpload="${this.handlers.openPopupUpload}"
        .onOpenPopupEdit="${this.handlers.openPopupEdit}"
        .onRequestDocuments="${this.handlers.getMoreDocuments}"
        .onSelectDocumentIndex="${this.handlers.selectDocumentIndex}"
        .onSearch="${this.handlers.search}"
        .noDocuments="${this.__controller.state.noDocuments}"
        ?hasTabs="${true}"
        .currentTab="${this.selectedTab}"
        .checkedDocuments="${this.__controller.state.checkedDocuments}"
        .onGetBulkActions="${this.handlers.getBulkActions}"
        .onCheckDocument="${this.handlers.checkDocument}"
        .onOpenPopupScan="${this.handlers.openPopupScan}"
      ></neb-documents-detail>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-documents-detail',
  NebUnsignedEncounterDocumentsDetail,
);
