import '../../neb-lit-components/src/components/inputs/neb-select-search';
import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/neb-tooltip';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getBillingCodesRefunds } from '../../neb-api-client/src/billing-codes';
import { fetchOne } from '../../neb-api-client/src/patient-api-client';
import { getCardReaders } from '../../neb-api-client/src/payments/card-readers-api-client';
import { fetchParentPaymentById } from '../../neb-api-client/src/payments-api-client';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_FONT_FAMILY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';
import { ELECTRONIC_PAYMENT_TYPES } from '../../neb-utils/electronic-payments-util';
import { PAYMENT_METHODS } from '../../neb-utils/enums';
import { currencyToCents } from '../../neb-utils/formatters';
import { currency } from '../../neb-utils/masks';
import { SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH } from '../../neb-utils/payment-popup-constants';

import NebPopup from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  textMessage: {
    id: 'text-message',
  },
  paymentId: {
    id: 'payment-id',
  },
  payer: {
    id: 'payer',
  },
  paymentMethod: {
    id: 'payment-method',
  },
  amount: {
    id: 'amount',
  },
  refundAmount: {
    id: 'refund-amount',
  },
  refundAmountText: {
    id: 'refund-amount-text',
  },
  selectReason: {
    id: 'select-reason',
  },
  selectMethod: {
    id: 'select-method',
  },
  buttonSave: {
    id: 'button-save',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  cardReaders: {
    id: 'card-readers',
  },
  linkProcessManually: {
    id: 'link-process-manually',
  },
  tooltip: {
    id: 'tooltip',
  },
};
export const PAYMENT_ACTION_KEYS = {
  VOID: 'void',
  REFUND: 'refund',
  REFUND_ELECTRONIC: 'refund-electronic',
};
const CARD_PROCESSED_MANUALLY = 'Card Processed Manually';
const CARD_USED_FOR_PAYMENT = 'Card Used for Payment';

const PAYMENT_ACTIONS = {
  [PAYMENT_ACTION_KEYS.VOID]: {
    type: 'Void',
    message: 'void',
  },
  [PAYMENT_ACTION_KEYS.REFUND]: {
    type: 'Refund',
    message: 'refund',
  },
  [PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC]: {
    type: 'Refund',
    message: 'refund',
  },
};
const REFUND_METHODS = [
  CARD_PROCESSED_MANUALLY,
  PAYMENT_METHODS.CASH,
  PAYMENT_METHODS.CHECK,
];

export const REFUND_AMOUNT_ERROR = 'Must be less than original amount';
const ZERO_AMOUNT = '$0.00';

class NebPopupPaymentAction extends NebPopup {
  static get properties() {
    return {
      __amountError: {
        type: String,
      },
      __refundAmount: {
        type: String,
      },
      __reasonError: {
        type: String,
      },
      __searchReasonValue: {
        type: String,
      },
      __searchReasonResults: {
        type: Array,
      },
      __displayedReason: {
        type: String,
      },
      __displayedMethod: {
        type: String,
      },
      __selectedMethod: {
        type: String,
      },
      __selectedReader: {
        type: String,
      },
      __cardReaders: {
        type: Array,
      },
    };
  }

  initState() {
    super.initState();
    this.__reasons = [];
    this.__amountError = '';
    this.__reasonError = '';
    this.__searchReasonValue = '';
    this.__searchReasonResults = [];
    this.__displayedReason = '';
    this.__selectedMethod = '';
    this.__selectedReader = '';
    this.__cardReaders = [];
    this.__refundAmount = ZERO_AMOUNT;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      save: async () => {
        this.__validate();

        if (this.model.parentPaymentId) {
          const splitData = await fetchParentPaymentById(this.model.id);
          const { patientId } = splitData;

          if (patientId && this.model.patientId !== patientId) {
            const parentPatient = await fetchOne(patientId);

            const confirm = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Refunding Split Payment',
              message: html`
                <div>
                  ${this.__refundAmount} will be refunded to the original payer
                  of this payment,
                </div>
                <b> ${parentPatient.name.last}, ${parentPatient.name.first}</b>
              `,
              confirmText: 'confirm',
              cancelText: 'cancel',
            });

            if (!confirm) {
              return;
            }
          }
        }

        if (!this.__reasonError && !this.__amountError) {
          this.onClose({
            selectedReason: this.__selectedReason,
            refundMethod: this.__selectedMethod,
            selectedReader: this.__selectedReader,
            action: this.model.action,
            amount: currencyToCents(this.__refundAmount),
          });
        }
      },
      cancel: () => this.onClose(false),
      toggleOpenEdge: () => {
        this.__selectedMethod =
          this.model.action === PAYMENT_ACTION_KEYS.REFUND
            ? PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD
            : PAYMENT_METHODS.CASH;

        this.model = {
          ...this.model,
          action:
            this.model.action === PAYMENT_ACTION_KEYS.REFUND
              ? PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC
              : PAYMENT_ACTION_KEYS.REFUND,
        };
      },
      searchReason: ({ value }) => {
        this.__searchReasonValue = value;
        this.__searchReasonResults = this.__searchReasonValue
          ? this.__reasons.filter(reason =>
              Array.from(this.__searchReasonValue.toLowerCase()).every(query =>
                `${reason.code} - ${reason.description}`
                  .toLowerCase()
                  .includes(query),
              ),
            )
          : this.__reasons;

        this.__validate();
      },
      selectReason: ({ value }) => {
        this.__displayedReason = '';

        if (value) {
          this.__selectedReason = value;
          this.__displayedReason = `${value.code} - ${value.description}`;
        }

        this.__validate();
      },
      selectMethod: ({ value }) => {
        this.__displayedMethod = '';

        if (value) {
          this.__displayedMethod = value;
          this.__selectedMethod =
            value === CARD_PROCESSED_MANUALLY
              ? PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD
              : value;
        }
      },
      selectReader: ({ value }) => {
        if (value) {
          this.__selectedReader = value;
        }
      },
      changeRefundAmount: e => {
        this.__refundAmount = e.value;
        this.__validate();
      },
      renderItem: (item, index) => html`
        <span
          id="${item.label ? 'method' : 'reason'}-${index}"
          style="padding-left: ${CSS_SPACING}"
          >${item.label || `${item.code} - ${item.description}`}</span
        >
      `,
    };
  }

  __validate() {
    this.__reasonError = !this.__displayedReason ? 'Required' : '';

    if (
      currencyToCents(this.__refundAmount) > currencyToCents(this.model.amount)
    ) {
      this.__amountError = REFUND_AMOUNT_ERROR;
    } else {
      this.__amountError = '';
    }
  }

  __paymentDiscountLabel() {
    return this.model.isDiscount ? 'Discount' : 'Payment';
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__refundAmount = this.model.amount;

    this.__reasons = (
      await getBillingCodesRefunds({
        hideInactive: true,
      })
    ).sort((a, b) => a.code.localeCompare(b.code));

    if (this.model.ePayment) this.__selectedMethod = CARD_USED_FOR_PAYMENT;

    if (
      this.model.ePayment &&
      this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
    ) {
      await this.__setElectronicRefundDefaultState();
    }

    this.__searchReasonResults = this.__reasons;
  }

  modelChanged() {
    this.__action = PAYMENT_ACTIONS[this.model.action];
    this.__refundAmount = this.model.amount;
    this.title = `${this.__action.type} ${this.__paymentDiscountLabel()}`;

    if (this.model.action === PAYMENT_ACTION_KEYS.REFUND) {
      this.__setDefaultMethod();
    }

    if (
      this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC &&
      this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
    ) {
      this.__setElectronicRefundDefaultState();
    }
  }

  async __setElectronicRefundDefaultState() {
    this.__cardReaders = (await getCardReaders({ hideInactive: true }))
      .filter(({ merchantAccounts }) =>
        merchantAccounts.includes(this.model.ePayment.merchantAccountId),
      )
      .map(reader => ({ ...reader, label: reader.name }))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.__selectedReader =
      this.model.selectedReader ||
      (this.__cardReaders.length && this.__cardReaders[0]);

    this.__selectedMethod = CARD_USED_FOR_PAYMENT;

    if (this.model.selectedReason) {
      this.__selectedReason = this.model.selectedReason;
      this.__displayedReason = `${this.model.selectedReason.code} - ${
        this.model.selectedReason.description
      }`;
    }

    if (
      this.model.ePayment &&
      this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC &&
      this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT &&
      this.__cardReaders.length === 0
    ) {
      this.model = {
        ...this.model,
        action: PAYMENT_ACTION_KEYS.REFUND,
      };
    }
  }

  __generateMethods() {
    return this.model.paymentMethod !== PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD
      ? REFUND_METHODS.filter(methods => methods !== CARD_PROCESSED_MANUALLY)
      : REFUND_METHODS;
  }

  __setDefaultMethod() {
    this.__selectedMethod =
      this.model.paymentMethod !== PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD
        ? PAYMENT_METHODS.CASH
        : PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD;

    this.__displayedMethod =
      this.model.paymentMethod === PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD
        ? CARD_PROCESSED_MANUALLY
        : PAYMENT_METHODS.CASH;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
        }

        :host([layout='small']) {
          margin: 40px 0;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        :host([layout='small']) .flex-1 {
          max-width: 300px;
          padding-right: 5%;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 460px;
        }

        :host([layout='small']) .container-content {
          min-width: auto;
        }

        .container-info {
          display: flex;
          padding-right: 10px;
          flex-shrink: 2;
        }

        .container-button {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: 30px;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .spacer {
          padding-top: 15px;
        }

        .flex-1 {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .link-open-edge {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          text-decoration: underline;
          padding-top: 8px;
          margin-left: auto;
        }

        .description {
          width: 460px;
        }

        :host([layout='small']) .description {
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH - 40}px;
        }

        .tooltip-grid {
          display: grid;
          grid-template-columns: auto 1fr;
        }

        .tooltip {
          padding-left: 10px;
        }

        .grid-row {
          display: grid;
        }

        .refund {
          padding: 10px 10px 0px 0px;
        }

        .disclaimer {
          margin-bottom: 10px;
          font-weight: bold;
        }
      `,
    ];
  }

  __renderOpenEdgeLink() {
    return this.model.ePayment &&
      !(
        this.model.ePayment &&
        this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT &&
        this.__cardReaders.length === 0
      )
      ? html`
          <div
            id="${ELEMENTS.linkProcessManually.id}"
            class="link-open-edge flex-row form-row"
            @click="${this.__handlers.toggleOpenEdge}"
          >
            Process Refund
            ${this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC
              ? ' Manually'
              : ' Electronically'}
          </div>
        `
      : '';
  }

  renderDebitRefundTooltip() {
    return this.model.action === PAYMENT_ACTION_KEYS.REFUND &&
      this.model.ePayment &&
      this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT &&
      this.__cardReaders.length === 0
      ? html`
          <neb-tooltip
            id="${ELEMENTS.tooltip.id}"
            class="grid-row tooltip"
            defaultAnchor="right"
          >
            <div slot="tooltip">
              In order to process this refund to the card used for payment, a
              card swipe is required. Please ensure at least one active Card
              Reader is configured to the Merchant Account associated with this
              payment.
            </div>
          </neb-tooltip>
        `
      : '';
  }

  __renderPaymentAmount() {
    return html`
      <div class="container-info">
        <span class="text-bold"
          >${this.__paymentDiscountLabel()} Amount:&nbsp</span
        >
        <span id="${ELEMENTS.amount.id}">${this.model.amount}</span>
      </div>

      <div class="spacer"></div>
    `;
  }

  __renderAmount() {
    return html`
      ${this.__action.type === 'Refund'
        ? html`
            <neb-textfield
              id="${ELEMENTS.refundAmount.id}"
              name="amount"
              label="Refund Amount"
              helper=""
              maxLength="11"
              .mask="${currency}"
              .error="${this.__amountError}"
              .value="${this.__refundAmount}"
              .onChange="${this.__handlers.changeRefundAmount}"
            ></neb-textfield>
          `
        : html`
            <div class="container-info">
              <span class="text-bold">${this.__action.type} Amount:&nbsp</span>

              <span id="${ELEMENTS.refundAmountText.id}">
                ${this.__refundAmount}
              </span>
            </div>
          `}
    `;
  }

  renderContent() {
    return html`
      <div class="container-content">
        <div id="${ELEMENTS.textMessage.id}" class="tooltip-grid description">
          <div class="grid-row">
            Please select a reason for ${this.__action.message}ing this
            ${this.__paymentDiscountLabel().toLowerCase()}.
            ${this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC &&
            this.model.ePayment.type !== ELECTRONIC_PAYMENT_TYPES.DEBIT
              ? "To process this refund manually, select 'Process Refund Manually'."
              : ''}
          </div>
          ${this.renderDebitRefundTooltip()}
        </div>

        <div class="spacer"></div>

        <div class="container-info">
          <span class="text-bold"
            >${this.__paymentDiscountLabel()} ID:&nbsp</span
          >
          <span id="${ELEMENTS.paymentId.id}"
            >${this.model.isDiscount ? 'D-' : ''}${this.model.paymentId}</span
          >
        </div>

        <div class="spacer"></div>

        <div class="container-info">
          <span class="text-bold">Payer:&nbsp</span>
          <span id="${ELEMENTS.payer.id}">${this.model.payer}</span>
        </div>

        <div class="spacer"></div>

        ${this.model.action === PAYMENT_ACTION_KEYS.REFUND ||
        this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC
          ? html`
              <div class="container-info">
                <span class="text-bold">Payment Method:&nbsp</span>
                <span id="${ELEMENTS.paymentMethod.id}"
                  >${this.model.paymentMethod}</span
                >
              </div>

              <div class="spacer"></div>
            `
          : ''}
        ${this.__renderPaymentAmount()} ${this.__renderAmount()}

        <div class="spacer"></div>

        ${this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC
          ? html`
              <div class="container-info">
                <span class="text-bold">Refund Method:&nbsp</span>
                <span id="${ELEMENTS.selectMethod.id}">
                  Card Used for Payment
                </span>
              </div>

              <div class="spacer"></div>
            `
          : ''}

        <neb-select-search
          id="${ELEMENTS.selectReason.id}"
          class="flex-1"
          name="code"
          label="${this.__action.type} Reason"
          helper="Required"
          emptyMessage="No results found"
          .search="${this.__searchReasonValue}"
          .items="${this.__searchReasonResults}"
          .value="${this.__displayedReason}"
          .error="${this.__reasonError}"
          .onSearch="${this.__handlers.searchReason}"
          .onChange="${this.__handlers.selectReason}"
          .onRenderItem="${this.__handlers.renderItem}"
          showFullText
          showSearch
        ></neb-select-search>

        ${this.model.action === PAYMENT_ACTION_KEYS.REFUND
          ? html`
              <neb-select
                id="${ELEMENTS.selectMethod.id}"
                class="flex-1 spacer"
                name="method"
                label="Refund Method"
                helper="Required"
                .items="${this.__generateMethods()}"
                .value="${this.__displayedMethod}"
                .onChange="${this.__handlers.selectMethod}"
                .onRenderItem="${this.__handlers.renderItem}"
                showFullText
                showSearch
              ></neb-select>
            `
          : ''}
        ${this.model.action === PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC &&
        this.model.ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
          ? html`
              <neb-select
                id="${ELEMENTS.cardReaders.id}"
                class="flex-1 spacer"
                name="reader"
                label="Card Reader"
                helper="Required"
                .items="${this.__cardReaders}"
                .value="${this.__selectedReader}"
                .onChange="${this.__handlers.selectReader}"
                showFullText
              ></neb-select>
            `
          : ''}

        <div class="container-button">
          <neb-button
            id="${ELEMENTS.buttonSave.id}"
            class="button"
            .label="${this.__action.type}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>

          ${this.__renderOpenEdgeLink()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-payment-action', NebPopupPaymentAction);
