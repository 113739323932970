import '../../../src/components/forms/settings/neb-form-swap-clearinghouse';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { CSS_COLOR_WHITE } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  form: { id: 'form' },
  mobileHeader: {
    id: 'mobile-header',
  },
};
const TEXT_TITLE = 'Swap Clearinghouse';
const TEXT_INFO = 'Please select a clearinghouse to swap to';

class NebPopupSwapClearinghouse extends NebPopup {
  static get properties() {
    return {
      __currentClearinghouse: Object,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;

    this.__currentClearinghouse = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      swapClearinghouse: ({ selectedClearinghouse }) => {
        const { label: partner } = selectedClearinghouse;
        const { id: clearinghouseId } = this.__currentClearinghouse;

        this.onClose({ partner, clearinghouseId });
      },
    };
  }

  modelChanged() {
    this.__currentClearinghouse = this.model.currentClearinghouse;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.mobileHeader.id}" class="header">${this.title}</div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        ${this.layout === 'small' ? this.__renderHeader() : ''}
        <div class="info-text">${TEXT_INFO}</div>

        <neb-form-swap-clearinghouse
          id="${ELEMENTS.form.id}"
          .onCancel="${this.handlers.cancel}"
          .onSave="${this.handlers.swapClearinghouse}"
          .currentClearinghouse="${this.__currentClearinghouse}"
        ></neb-form-swap-clearinghouse>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-swap-clearinghouse',
  NebPopupSwapClearinghouse,
);
