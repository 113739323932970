import '../../pages/alerts/neb-page-alerts';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { createAlertsForPatientState } from '../../../../packages/neb-alert/components/neb-alerts-for-patient-state';
import { getPracticeUsersAttributes } from '../../../../packages/neb-api-client/src/permissions-api-client';
import {
  openSuccess,
  openError,
  closeAlertBanners,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../packages/neb-popup/index';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  LayoutService,
  LAYOUT_TYPE,
} from '../../../../packages/neb-redux/services/layout';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { objToName } from '../../../../packages/neb-utils/formatters';
import * as api from '../../../api-clients/alerts';

const REORDER_SUCCESS = 'Alerts and Notes successfully saved';
export const ELEMENTS = {
  alertForm: {
    id: 'alert-form',
  },
};
const { SMALL } = LAYOUT_TYPE;
class NebPatientAlertController extends LitElement {
  static get properties() {
    return {
      _small: {
        type: Boolean,
        reflect: true,
        attribute: 'small',
      },
      _patientAlerts: {
        type: Array,
      },
      patientId: {
        type: String,
      },
      __editAlertId: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  onAlertsChange(alerts) {
    this._patientAlerts = alerts;
    this.setUserNamesOnAlerts();
    this.onCountChanged(alerts.length);
  }

  async connectedCallback() {
    this.__layoutService.connect();

    store.dispatch(closeAlertBanners());

    const practiceUsers = await getPracticeUsersAttributes([
      'givenName',
      'familyName',
      'middleName',
      'suffix',
      'nickname',
    ]);

    practiceUsers.forEach(user => {
      this.practiceUserNames.set(
        user.id,
        objToName(
          {
            first: user.givenName,
            middle: user.middleName,
            last: user.familyName,
            suffix: user.suffix,
            preferred: user.nickname,
          },
          {
            middleInitial: true,
            preferred: true,
          },
        ),
      );
    });

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__layoutService.disconnect();
  }

  __initState() {
    this.onDirty = () => {};

    this.onCountChanged = () => {};

    this.__layoutService = new LayoutService(layout => {
      this._small = layout === SMALL;
    });

    this.__alertsForPatient = createAlertsForPatientState(alerts =>
      this.onAlertsChange(alerts),
    );

    this.__editAlertId = null;

    this.practiceUserNames = new Map();
  }

  __initHandlers() {
    this.__handlers = {
      save: async alert => {
        try {
          if (alert.id) {
            this._patientAlerts = await api.updateAlerts(this.patientId, [
              alert,
            ]);
          } else {
            this._patientAlerts = await api.createAlert(this.patientId, alert);
          }
          store.dispatch(
            openSuccess(this.__calculateSuccessMessage(alert.alert)),
          );

          this.setUserNamesOnAlerts();

          this.__editAlertId = null;
          this.__dirty = false;
          this.onDirty(false);

          this.onCountChanged(this._patientAlerts.length);
        } catch (err) {
          store.dispatch(openError('Unable to save Alert/Note'));
          console.error(err);
        }
      },
      delete: async alert => {
        if (
          await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Delete Alert or Note',
            message: 'Are you sure that you wish to delete this Alert or Note?',
            confirmText: 'YES',
            cancelText: 'NO',
          })
        ) {
          try {
            await api.deleteAlert(this.patientId, alert);
            store.dispatch(
              openSuccess(
                this.__calculateSuccessMessage(alert.alert, 'deleted'),
              ),
            );

            this._patientAlerts = this._patientAlerts.filter(
              patientAlert => patientAlert.id !== alert.id,
            );

            this.onCountChanged(this._patientAlerts.length);

            this.__editAlertId = null;
            this.__dirty = false;
            this.onDirty(false);
          } catch (err) {
            store.dispatch(openError('Unable to delete Alert/Note'));
            console.error(err);
          }
        }
      },
      reorder: async alerts => {
        if (!this.__shouldReorder(alerts)) {
          return;
        }

        try {
          this._patientAlerts = await api.updateAlerts(
            this.patientId,
            alerts,
            true,
          );

          this.setUserNamesOnAlerts();

          store.dispatch(openSuccess(REORDER_SUCCESS));
        } catch (err) {
          store.dispatch(openError('Unable to save Alerts and Notes'));
          console.error(err);
        }
      },
      dirty: dirty => {
        this.__dirty = dirty;
        this.onDirty(dirty);
      },
      cancelEdit: async () => {
        if (this.__dirty) {
          if (await openDirtyPopup()) {
            this.__dirty = false;
            this.onDirty(false);
            this.__editAlertId = null;
          }
        } else {
          this.__editAlertId = null;
          this.__dirty = false;
          this.onDirty(false);
        }
      },
      edit: alertId => {
        this.__editAlertId = alertId;
      },
      add: () => {
        this.__editAlertId = 0;
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          position: relative;
        }

        :host([small]) .alert-form {
          padding: 10px;
        }
      `,
    ];
  }

  setUserNamesOnAlerts() {
    this._patientAlerts = this._patientAlerts.map(alert => ({
      ...alert,
      createdByUserName: this.practiceUserNames.get(alert.createdBy),
      editedByUserName: this.practiceUserNames.get(alert.editedBy),
    }));
  }

  async updated(changed) {
    if (changed.has('patientId')) {
      await this.__alertsForPatient.fetchAll(this.patientId);
    }

    super.updated(changed);
  }

  __calculateSuccessMessage(isAlert, successProp) {
    const action =
      successProp === 'deleted' ? 'deleted successfully' : 'saved successfully';
    return isAlert ? `Alert ${action}` : `Note ${action}`;
  }

  __shouldReorder(alerts) {
    const order = this._patientAlerts.map(x => x.order);
    const newOrder = alerts.map(x => x.order);

    return order.some((item, index) => item !== newOrder[index]);
  }

  render() {
    return html`
      <neb-page-alerts
        id="${ELEMENTS.alertForm.id}"
        class="alert-form"
        .small="${this._small}"
        .patientId="${this.patientId}"
        .alerts="${this._patientAlerts}"
        .onSave="${this.__handlers.save}"
        .onReorder="${this.__handlers.reorder}"
        .onDirty="${this.__handlers.dirty}"
        .onDelete="${this.__handlers.delete}"
        .onEdit="${this.__handlers.edit}"
        .onCancelEdit="${this.__handlers.cancelEdit}"
        .onAdd="${this.__handlers.add}"
        .editAlertId="${this.__editAlertId}"
        hide-header
      ></neb-page-alerts>
    `;
  }
}

customElements.define(
  'neb-patient-alert-controller',
  NebPatientAlertController,
);
