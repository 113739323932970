import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  layoutStyles,
} from '../../../styles';

export const ELEMENTS = {
  tenantId: { id: 'tenant-id' },
};

class NebPracticeDetailsDataMigration extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          padding: 0 ${CSS_SPACING};
        }

        .grid {
          padding: ${CSS_SPACING};
          border: 0;
          border-radius: 5px;
          border: ${CSS_BORDER_GREY_2};
        }

        .header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: ${CSS_FONT_SIZE_HEADER};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.model = {};
  }

  renderTenantId() {
    return this.model.id || '';
  }

  render() {
    return html`
      <div class="container">
        <div class="grid">
          <div class="header">Conversions</div>

          <div>Coming soon!</div>
        </div>
        <div class="grid">
          <div class="header">Tenant ID</div>

          <div id="${ELEMENTS.tenantId.id}">${this.renderTenantId()}</div>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-practice-details-data-migration',
  NebPracticeDetailsDataMigration,
);
