import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_7,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  description: { id: 'description' },
};

class NebHeader extends LitElement {
  static get properties() {
    return {
      label: String,
      description: String,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.label = '';
    this.description = '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          width: 100%;
          min-height: 30px;
          padding: 0 ${CSS_SPACING} 10px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .description {
          display: block;
          width: 100%;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .label {
          display: flex;
          width: 100%;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  renderDescription() {
    if (this.description.length !== 0) {
      return html`
        <div id="${ELEMENTS.description.id}" class="description">
          ${this.description}
        </div>
      `;
    }

    return '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="label">${this.label}</div>
        ${this.renderDescription()}
      </div>
    `;
  }
}

customElements.define('neb-header', NebHeader);
