export const SORT_DIR = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export const SORT_BY = Object.freeze({
  NAME: 'name',
  NUMBER: 'number',
  DATE: 'date',
});

const SORT_DEFAULT_VALUE = Object.freeze({
  [SORT_BY.NAME]: '',
  [SORT_BY.NUMBER]: 0,
  [SORT_BY.DATE]: new Date(0, 0, 1),
});

const validateNull = ({ values: [object1, object2], key, by }) => [
  object1[key] || SORT_DEFAULT_VALUE[by],
  object2[key] || SORT_DEFAULT_VALUE[by],
];

export const sort = ({ by, array, key, dir }) => {
  const compareFunction = (a, b) => {
    const values = dir === SORT_DIR.ASC ? [a, b] : [b, a];
    const [value1, value2] = validateNull({ values, key, by });

    switch (by) {
      case SORT_BY.NUMBER:
        return value1 - value2;
      case SORT_BY.NAME:
        return value1.localeCompare(value2);
      case SORT_BY.DATE:
        return new Date(value1).getTime() - new Date(value2).getTime();
      default:
        return 'Invalid argument(s)';
    }
  };

  return array.sort(compareFunction);
};
