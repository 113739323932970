import '../neb-popover';
import '../inputs/neb-textfield';
import '../../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_COLOR_ERROR,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { COLOR } from '../neb-text';

export const CONFIG = [
  {
    label: '',
    width: 120,
  },
];

export const ELEMENTS = {
  container: { id: 'container' },
  errors: { selector: '.error' },
  errorsContainer: { id: 'error-container' },
  errorText: { id: 'error-text' },
  errorTextContainer: { id: 'error-text-container' },
  popover: { id: 'popover' },
};

class NebErrorsCell extends LitElement {
  static get properties() {
    return {
      __displayed: Boolean,

      name: String,
      errors: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,

      css`
        :host {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .popover-container {
          width: 0;
          position: relative;
          white-space: nowrap;
        }

        .container {
          display: flex;
          background: ${CSS_ERROR_BACKGROUND_COLOR};
          color: ${CSS_COLOR_ERROR};
          padding-top: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
          width: 100%;
        }

        .container-error-text {
          display: grid;
          height: fit-content;
          grid-template-columns: 20px 1fr;
          column-gap: 10px;
          cursor: pointer;
        }

        .error {
          margin: 10px 0;
          flex-shrink: 0;
        }

        .error:first-of-type {
          margin-top: 0;
        }

        .error:last-of-type {
          margin-bottom: 0;
        }

        .error-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .popover {
          left: ${CSS_SPACING};
          position: absolute;
        }

        .container-errors {
          display: flex;
          flex-direction: column;
          max-height: 130px;
          overflow: auto;
          padding: ${CSS_SPACING};
        }

        .icon {
          fill: ${CSS_COLOR_ERROR};
          height: 20px;
          width: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__displayed = false;

    this.name = '';
    this.errors = [];
  }

  __initHandlers() {
    this.__handlers = {
      toggle: () => {
        this.__displayed = !this.__displayed;
      },
    };
  }

  __renderErrors() {
    return html`
      <div class="container-errors">
        ${this.errors.map(
          t => html`
            <neb-text class="error" .color="${COLOR.ALERT}">
              &bull; ${t}
            </neb-text>
          `,
        )}
      </div>
    `;
  }

  __getErrorText() {
    if (this.errors.length === 1) {
      return '1 error';
    }
    return `${this.errors.length} errors`;
  }

  __renderPopover() {
    return this.__displayed
      ? html`
          <div id="${ELEMENTS.popover.id}" class="popover-container">
            <neb-popover
              class="popover"
              .showCaret="${false}"
              .onBlockerClicked="${this.__handlers.toggle}"
            >
              ${this.__renderErrors()}
            </neb-popover>
          </div>
        `
      : '';
  }

  render() {
    return this.errors.length
      ? html`
          <div id="${ELEMENTS.container.id}" class="container">
            <div
              id="${ELEMENTS.errorTextContainer.id}"
              class="container-error-text"
              @click="${this.__handlers.toggle}"
            >
              <neb-icon class="icon" icon="neb:warning"></neb-icon>

              <span id="${ELEMENTS.errorText.id}" class="error-text">
                ${this.__getErrorText()}
              </span>
            </div>

            ${this.__renderPopover()}
          </div>
        `
      : html` <div id="${ELEMENTS.container.id}"></div> `;
  }
}

window.customElements.define('neb-errors-cell', NebErrorsCell);
