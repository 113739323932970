import '../../../../../src/components/controls/inputs/neb-light-textfield';
import '../../../../../src/components/misc/neb-icon';
import '../neb-loading-spinner-resizable';
import './neb-forgot-password';

import { detect } from 'detect-browser';
import { html, css } from 'lit';

import { LightDom } from '../../../../../src/components/misc/neb-light-dom';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_COLOR_HIGHLIGHT } from '../../../../neb-styles/neb-variables';
import { BUTTON_ROLE } from '../neb-button';

export const ELEMENTS = {
  email: {
    id: 'neb-login-page-email-address',
  },
  password: {
    id: 'neb-login-page-password',
  },
  loginButton: {
    id: 'neb-login-page-login-button',
  },
  errorText: {
    id: 'neb-login-page-error-text',
  },
  loadingSpinner: {
    id: 'neb-login-page-loading-spinner',
  },
  container: {
    id: 'neb-login-page-login-container',
  },
  forgotPasswordLink: {
    id: 'neb-login-page-forgot-password-link',
  },
};

const BAD_AUTOFILL_BROWSERS = ['chrome', 'edge-chromium'];

class NebLoginPage extends LightDom {
  static get properties() {
    return {
      __email: String,
      __enabled: Boolean,
      __manuallyPinLabels: Boolean,
      __password: String,

      error: String,
      layout: { type: String, reflect: true },
      loading: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-login-page-container {
          display: block;
          width: 100%;
          height: 100%;
        }

        .neb-login-page-container-email {
          display: flex;
          align-items: flex-end;
          height: fit-content;

          margin-bottom: 28px;
        }

        .neb-login-page-container-password {
          display: flex;
          align-items: flex-end;
          height: fit-content;

          margin-bottom: 43px;
        }

        .neb-login-page-textfield {
          display: flex;
          width: 100%;
        }

        .neb-login-page-icon {
          display: flex;
          width: 40px;
          height: 40px;

          margin-right: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .neb-login-page-container-login {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: fit-content;

          padding-left: 50px;
          margin-bottom: 30px;
        }

        .neb-login-page-button-login {
          display: flex;
        }

        .neb-login-page-link-forgot-password {
          cursor: pointer;
          display: flex;
          align-self: center;
          justify-content: flex-end;
          width: fit-content;

          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          text-align: end;
        }

        .neb-login-page-container-loading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: fit-content;
        }

        .neb-login-page-label-error {
          display: flex;
          align-self: center;
          margin: 0;
          color: red;
        }

        .neb-login-page-spinner {
          display: flex;
          align-self: center;
          width: 100px;
          height: 100px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__email = '';
    this.__enabled = false;
    this.__manuallyPinLabels = false;
    this.__password = '';

    this.error = '';
    this.layout = '';
    this.loading = false;

    this.onLogin = () => {};

    this.onForgetPassword = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      login: () => this.onLogin(this.__email, this.__password),
      change: e => {
        this[e.name] = e.value;
      },
      keydown: e => {
        if (e.code === 'Enter') this.__handlers.login();
      },
      forgotPassword: () => {
        this.onForgetPassword();
      },

      handleChromiumAutofill: () => {
        this.__manuallyPinLabels = false;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    const { name } = detect();

    /* chromium based browsers don't pass autofilled values to input fields 
       until the user interacts with the window. This is a work around
       to fix the floating labels from overlapping with autofilled text.
    */
    if (BAD_AUTOFILL_BROWSERS.includes(name)) {
      this.__manuallyPinLabels = true;

      window.addEventListener('click', this.__handlers.handleChromiumAutofill);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('click', this.__handlers.handleChromiumAutofill);
  }

  __renderEmail() {
    return html`
      <div class="neb-login-page-container-email">
        <neb-icon
          class="neb-login-page-icon neb-login-page-icon-user"
          icon="neb:accountCircle"
        ></neb-icon>

        <neb-light-textfield
          id="${ELEMENTS.email.id}"
          class="neb-login-page-textfield neb-login-page-textfield-email"
          autoComplete="email"
          name="__email"
          type="email"
          label="Email"
          .manuallyPinLabel="${this.__manuallyPinLabels}"
          .value="${this.__email}"
          .onChange="${this.__handlers.change}"
        ></neb-light-textfield>
      </div>
    `;
  }

  __renderPassword() {
    return html`
      <div class="neb-login-page-container-password">
        <neb-icon class="neb-login-page-icon" icon="neb:lock"></neb-icon>

        <neb-light-textfield
          id="${ELEMENTS.password.id}"
          class="neb-login-page-textfield neb-login-page-textfield-password"
          autoComplete="password"
          name="__password"
          type="password"
          label="Password"
          .manuallyPinLabel="${this.__manuallyPinLabels}"
          .value="${this.__password}"
          .onChange="${this.__handlers.change}"
        ></neb-light-textfield>
      </div>
    `;
  }

  __renderSpinner() {
    return this.loading
      ? html`
          <neb-loading-spinner-resizable
            id="${ELEMENTS.loadingSpinner.id}"
            class="neb-login-page-spinner"
            .radius="${20}"
          ></neb-loading-spinner-resizable>
        `
      : '';
  }

  __renderError() {
    return this.error
      ? html`
          <p
            id="${ELEMENTS.errorText.id}"
            class="neb-login-page-label neb-login-page-label-error"
          >
            ${this.error}
          </p>
        `
      : '';
  }

  renderContent() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="neb-login-page-container"
        @keydown="${this.__handlers.keydown}"
      >
        ${this.__renderEmail()} ${this.__renderPassword()}

        <div class="neb-login-page-container-login">
          <neb-button
            id="${ELEMENTS.loginButton.id}"
            class="neb-login-page-button neb-login-page-button-login"
            label="Log In"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.login}"
          ></neb-button>

          <div
            id="${ELEMENTS.forgotPasswordLink.id}"
            class="neb-login-page-link neb-login-page-link-forgot-password"
            @click="${this.__handlers.forgotPassword}"
          >
            Forgot password?
          </div>
        </div>

        <div class="neb-login-page-container-loading">
          ${this.__renderError()} ${this.__renderSpinner()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-login-page', NebLoginPage);
