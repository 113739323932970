import { html, css } from 'lit';

import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { MdcProviderService } from '../../../../neb-redux/services/mdc-provider';
import NebFormBlockedOffTime from '../scheduling/neb-blocked-off-time-form';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  blockedOffTimeForm: {
    id: 'neb-blocked-off-time-form',
  },
};

class NebOverlayBlockedOffTimeForm extends Overlay {
  static get properties() {
    return {
      __providers: { type: Array },
      __rooms: { type: Array },
      __locations: { type: Array },
      __formModel: { type: Object },
    };
  }

  constructor() {
    super();

    this.__initServices();
    this.initState();
  }

  initState() {
    super.initState();
    this.model = {
      appointment: null,
      providerId: '',
      providerIds: [],
    };

    this.__providers = [];
    this.__rooms = [];
    this.__locations = [];
    this.__formModel = {};
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      dirty: dirty => {
        this.isDirty = dirty;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 491px;
        }

        :host([layout='small']) .content {
          width: 100%;
        }
      `,
    ];
  }

  __initServices() {
    this.__providerService = new MdcProviderService(({ activeProviders }) => {
      this.__providers = activeProviders.map(provider => ({
        data: provider,
        label: provider.value,
      }));
    });

    this.__locationsService = new LocationsService(({ activeLocations }) => {
      this.__locations = activeLocations.map(location => ({
        data: location,
        label: location.name,
      }));
    });
  }

  async connectedCallback() {
    this.__providerService.connect();
    this.__locationsService.connect();

    const rooms = await fetchManyRooms();

    this.__rooms = rooms.map(room => ({
      label: room.name,
      data: room,
    }));

    this.__setFormModel();

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    try {
      this.__locationsService.disconnect();
    } catch (e) {
      // no-op
    }
  }

  __setFormModel() {
    this.__formModel = {
      ...NebFormBlockedOffTime.createModel(),
      ...this.model.appointment,
      providerId: this.model.providerId,
      providerIds: this.model.providerIds,
    };
  }

  renderContent() {
    return html`
      <neb-blocked-off-time-form
        id="${ELEMENTS.blockedOffTimeForm.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .providers="${this.__providers}"
        .rooms="${this.__rooms}"
        .locations="${this.__locations}"
        .onChangeDirty="${this.__handlers.dirty}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-blocked-off-time-form>
    `;
  }
}

window.customElements.define(
  'neb-overlay-blocked-off-time-form',
  NebOverlayBlockedOffTimeForm,
);
