import './neb-text-helper';

import { html, css } from 'lit';

import {
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_3,
} from '../../../../neb-styles/neb-variables';

import { ELEMENTS as ELEMENTS_BASE, TextInput } from './neb-text-input';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  countHelper: {
    id: 'helper-count',
  },
};

class Textarea extends TextInput {
  static get properties() {
    return {
      showCount: {
        reflect: true,
        type: Boolean,
      },
      grow: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .textbox-area {
          resize: none;
          border: none;
          border-radius: 4px;
          padding: 12px;
          width: 100%;
          height: 100%;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .textbox-area:focus {
          padding: 11px;
        }

        :host([focused]) .textbox-area {
          caret-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([invalid]) .textbox-area {
          caret-color: ${CSS_COLOR_ERROR};
        }

        .footer {
          display: grid;
          grid-gap: 12px;
          grid-template-columns: 1fr auto;
        }

        .textbox[disabled] {
          background-color: ${CSS_COLOR_GREY_3};
        }

        neb-textbox {
          min-height: var(--neb-textbox-min-height, 80px);
          flex: 1 0 0;
          padding: 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.showCount = false;
    this.grow = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      input: e =>
        this.onChange({
          name: this.name,
          value: e.currentTarget.value,
        }),
      keydown: e => {
        if (!this.onChange) {
          e.preventDefault();
        }
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('value') && this.elements) {
      this.elements.input.value = this.value;

      if (this.grow) {
        if (this.elements.input.scrollHeight) {
          this.__updateHeight();
        } else {
          setTimeout(() => this.__updateHeight(), 0);
        }
      }
    }
  }

  __updateHeight() {
    // Important: we must first set the height of the textarea to auto.
    // This allows the component to shrink if the content gets smaller.
    this.style.height = 'auto';

    const newHeight =
      this.getBoundingClientRect().height +
      (this.elements.input.scrollHeight - this.elements.input.clientHeight);

    this.style.height = `${newHeight}px`;
  }

  getCountText() {
    const showMax = this.maxLength !== null;
    const max = showMax ? ` / ${this.maxLength}` : '';
    const current = this.value ? this.value.length : 0;
    return `${current}${max}`;
  }

  renderCount() {
    return this.showCount
      ? html`
          <neb-text-helper
            id="${ELEMENTS.countHelper.id}"
            .text="${this.getCountText()}"
            ?disabled="${this.disabled}"
            ?invalid="${Boolean(this.error)}"
          ></neb-text-helper>
        `
      : '';
  }

  renderInputElement() {
    return html`
      <textarea
        id="${ELEMENTS.input.id}"
        class="textbox textbox-area"
        maxlength="${this.maxLength}"
        .placeholder="${this.getPlaceholder()}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        @focus="${this.handlers.focus}"
        @blur="${this.handlers.blur}"
        @input="${this.handlers.input}"
        @keydown="${this.handlers.keydown}"
      >
${this.value}</textarea
      >
    `;
  }

  renderFooter() {
    return html`
      <div class="footer">
        <neb-text-helper
          .text="${this.getHelperText()}"
          ?disabled="${this.disabled}"
          ?invalid="${Boolean(this.error)}"
        ></neb-text-helper>

        ${this.renderCount()}
      </div>
    `;
  }
}

window.customElements.define('neb-textarea', Textarea);
