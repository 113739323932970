import {
  CSS_COLOR_BLUE,
  CSS_COLOR_GREEN,
  getHexColorFromCSS,
} from '../../src/styles';

export const TAB_KEYS = {
  SUBJECTIVE: 'subjective',
  OBJECTIVE: 'objective',
  ASSESSMENT: 'assessment',
  PLAN: 'plan',
  SOAP: 'soap',
  DATA: 'data',
};

export const TABS = [
  {
    id: TAB_KEYS.SUBJECTIVE,
    label: 'Subjective',
  },
  {
    id: TAB_KEYS.OBJECTIVE,
    label: 'Objective',
  },
  {
    id: TAB_KEYS.ASSESSMENT,
    label: 'Assessment',
  },
  {
    id: TAB_KEYS.PLAN,
    label: 'Plan',
  },
];

export const buildFolderItems = newItems =>
  newItems.reduce((items, { index, value }) => {
    items[index] = value;
    return items;
  }, Array(20).fill(null));

export const MACRO_SET_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const MACRO_BUTTON_TYPE = {
  FOLDER: 'folder',
  EXISTING_FOLDER: 'existing-folder',
  MACRO: 'macro',
};

export const BUTTON_TYPE_TO_COLOR = {
  [MACRO_BUTTON_TYPE.FOLDER]: {
    color: getHexColorFromCSS(CSS_COLOR_BLUE),
    icon: 'folderLine',
  },
  [MACRO_BUTTON_TYPE.MACRO]: {
    color: getHexColorFromCSS(CSS_COLOR_GREEN),
  },
};
