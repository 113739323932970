const commonDataProperties = [
  'id',
  'lastName',
  'firstName',
  'middleName',
  'preferredName',
  'dateOfBirth',
  'ssn',
  'medicalRecordNumber',
  'relationshipStatus',
  'employmentStatus',
  'sex',
  'status',
];
const csvDataProperties = [
  ...commonDataProperties,
  'phoneNumberOne',
  'phoneNumberOneType',
  'phoneNumberTwo',
  'phoneNumberTwoType',
  'phoneNumberThree',
  'phoneNumberThreeType',
  'emailAddressOne',
  'emailAddressTwo',
  'emailAddressThree',
  'addressOne',
  'addressTwo',
  'city',
  'state',
  'zipcode',
];

const getProperty = property => patient => patient[property];

const createDataGetterFromArray = propertyArray =>
  Object.fromEntries(
    propertyArray.map(property => [property, getProperty(property)]),
  );

const COMMON_GETTER = createDataGetterFromArray(commonDataProperties);
export const FROM_CSV = createDataGetterFromArray(csvDataProperties);
export const FROM_RECORD = {
  ...COMMON_GETTER,
  phoneNumberOne: patient => {
    const phone = patient.phoneNumbers[0];
    return phone ? phone.number : '';
  },
  phoneNumberOneType: patient => {
    const phone = patient.phoneNumbers[0];
    return phone ? phone.type : '';
  },
  phoneNumberTwo: patient => {
    const phone = patient.phoneNumbers[1];
    return phone ? phone.number : '';
  },
  phoneNumberTwoType: patient => {
    const phone = patient.phoneNumbers[1];
    return phone ? phone.type : '';
  },
  phoneNumberThree: patient => {
    const phone = patient.phoneNumbers[2];
    return phone ? phone.number : '';
  },
  phoneNumberThreeType: patient => {
    const phone = patient.phoneNumbers[2];
    return phone ? phone.type : '';
  },
  emailAddressOne: patient =>
    patient.emailAddresses[0] ? patient.emailAddresses[0] : '',
  emailAddressTwo: patient =>
    patient.emailAddresses[1] ? patient.emailAddresses[1] : '',
  emailAddressThree: patient =>
    patient.emailAddresses[2] ? patient.emailAddresses[2] : '',
  addressOne: patient => patient.addresses[0].address1,
  addressTwo: patient => patient.addresses[0].address2,
  city: patient => patient.addresses[0].city,
  state: patient => patient.addresses[0].state,
  zipcode: patient => patient.addresses[0].zipcode,
};
