import { getMany } from '../../../../src/api-clients/charges';
import { toNumeric } from '../../../neb-utils/formatters';

const formatPackageTemplateCharges = (packageTemplateCharges, charges) =>
  packageTemplateCharges.map(ptc => {
    const charge = charges.find(c => c.id === ptc.chargeId);

    const formatted = {
      ...charge,
      chargeId: ptc.chargeId,
      units: ptc.quantity || '',
    };

    delete formatted.id;

    return formatted;
  });

const formatPackageTemplateToUI = (pt, charges) => {
  const totalPrice = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
  }).format(pt.totalPrice / 100);

  return {
    ...pt,
    totalPrice,
    charges: formatPackageTemplateCharges(pt.charges, charges),
  };
};

export const formatPackageTemplatesToUI = async pts => {
  const charges = await getMany();
  return pts.map(pt => formatPackageTemplateToUI(pt, charges));
};
export const formatPackageTemplateToAPI = pt => ({
  ...pt,
  name: pt.name.trim(),
  totalVisits: pt.unlimited && pt.totalVisits === '' ? 0 : pt.totalVisits,
  totalPrice: toNumeric(pt.totalPrice),
  charges: pt.charges.map(c => (c.units ? { ...c, quantity: c.units } : c)),
});
