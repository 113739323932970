import { openPopup } from '@neb/popup';

import * as patientApiClient from '../../../neb-api-client/src/patient-api-client';
import { getPotentialMatches } from '../../../neb-api-client/src/patient-api-client';
import { getAccount } from '../../../neb-api-client/src/permissions-api-client';
import { openSuccess } from '../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { store } from '../../../neb-redux/neb-redux-store';
import { patientToRaw } from '../../../neb-utils/patient';

import { OVERLAY_KEYS, openOverlay } from './overlay-constants';

export const SUCCESS_MESSAGE = 'Online Booking Account linked';

export async function addPatient(patient, queryParams) {
  const raw = patientToRaw(patient);
  const result = await patientApiClient.createPatient(raw, queryParams, true);
  return result.data[0];
}

export async function savePatient(patient, queryParams) {
  const raw = patientToRaw(patient);
  const result = await patientApiClient.updatePatient(raw, queryParams, true);

  return result.data[0];
}

export async function openMatchPatientAccountPopup(
  cognitoAccount,
  accountMatches,
  appointmentId,
) {
  let result = await openPopup(POPUP_RENDER_KEYS.MATCH_PATIENT_ACCOUNT, {
    title: 'Match Accounts',
    account: cognitoAccount,
    matches: accountMatches,
    appointmentId,
  });

  if (result.createNewPatient) {
    const patient = {
      bookingAccountId: cognitoAccount.cognitoId,
      firstName: cognitoAccount.firstName,
      lastName: cognitoAccount.lastName,
      emailAddresses: [cognitoAccount.emailAddress],
      sex: cognitoAccount.sex,
      phoneNumbers: [
        {
          type: cognitoAccount.phoneType,
          number: cognitoAccount.phoneNumber,
        },
      ],
      dateOfBirth: cognitoAccount.dateOfBirth,
    };
    result = {
      data: [
        await openOverlay(OVERLAY_KEYS.PATIENT_CREATE, {
          patient,
          onSuccessMessage:
            'Online booking account linked to new patient record',
        }),
      ],
    };
  }

  return result;
}

export async function fetchAndOpenMatchPopup(accountId, appointmentId) {
  const cognitoAccount = await getAccount(accountId);

  if (!cognitoAccount) return false;

  const accountMatches = await getPotentialMatches(cognitoAccount);

  const result = await openMatchPatientAccountPopup(
    cognitoAccount,
    accountMatches,
    appointmentId,
  );

  if (result && result.data && result.data[0].bookingAccountId) {
    store.dispatch(openSuccess(SUCCESS_MESSAGE));
  }

  return result;
}

export const PATIENT_PAYMENT_TYPE = Object.freeze({
  SELF_PAY: 'Self Pay',
  INSURANCE: 'Primary',
});
