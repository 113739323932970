import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_DISABLED,
  CSS_FONT_SIZE_BODY,
  CSS_WARNING_COLOR,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
  leadingIcon: {
    id: 'leading-icon',
  },
  trailingIcon: {
    id: 'trailing-icon',
  },
};

class NebButtonAction extends LitElement {
  static get properties() {
    return {
      disabled: {
        type: Boolean,
        reflect: true,
      },
      leadingIcon: {
        type: String,
      },
      trailingIcon: {
        type: String,
      },
      label: {
        type: String,
      },
      name: {
        type: String,
      },
      showUnderline: {
        type: Boolean,
        reflect: true,
      },
      warn: {
        type: Boolean,
        reflect: true,
      },
      iconHeight: { type: String },
      iconWidth: { type: String },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.leadingIcon = 'plus';
    this.trailingIcon = '';
    this.label = 'Sample Label';
    this.name = '';
    this.iconHeight = '20px';
    this.iconWidth = '20px';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        e.stopPropagation();

        this.__elements.button.blur();

        if (!this.disabled) {
          this.onClick({
            id: this.id,
            name: this.name,
          });
        }
      },
      keyUp: e => e.key === 'Enter' && this.__handlers.click(e),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
        }

        .button {
          cursor: pointer;
          outline: none;
          display: flex;
          margin: 0;
          padding: 0;
          border: 0;
          background: none;
          align-items: center;
        }

        :host([disabled]) .button {
          cursor: default;
        }

        :host(:not([disabled])) .button:focus {
          text-decoration: underline ${CSS_COLOR_HIGHLIGHT};
        }

        :host(:not([disabled])) .button::-moz-focus-inner {
          border: 0;
        }

        :host([showUnderline]) .text {
          text-decoration: underline;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .icon {
          fill: ${CSS_COLOR_DISABLED};
        }

        :host([warn]) .icon {
          fill: ${CSS_WARNING_COLOR};
        }

        .text {
          margin: 0 6px;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .text {
          color: ${CSS_COLOR_DISABLED};
        }

        :host([warn]) .text {
          color: ${CSS_WARNING_COLOR};
        }
      `,
    ];
  }

  firstUpdated() {
    this.__elements = {
      button: this.shadowRoot.getElementById(ELEMENTS.button.id),
    };
  }

  renderLeadingIcon() {
    return !this.trailingIcon && this.leadingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.leadingIcon.id}"
            class="icon"
            .icon="${`neb:${this.leadingIcon}`}"
            style="height: ${this.iconHeight}; width: ${this.iconWidth};"
          ></neb-icon>
        `
      : '';
  }

  renderTrailingIcon() {
    return this.trailingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.trailingIcon.id}"
            class="icon"
            .icon="${`neb:${this.trailingIcon}`}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.button.id}"
        class="button"
        tabindex="${this.disabled ? -1 : 0}"
        @click="${this.__handlers.click}"
        @keyup="${this.__handlers.keyUp}"
      >
        ${this.renderLeadingIcon()}

        <span class="text">${this.label}</span>

        ${this.renderTrailingIcon()}
      </div>
    `;
  }
}

customElements.define('neb-button-action', NebButtonAction);
