import '../inputs/neb-textfield';
import '../inputs/neb-select';
import '../neb-text';

import { html, css } from 'lit';

import { PAYER_PLAN_VERSION_CLAIMS } from '../../../../neb-api-client/src/payer-plan-api-client';
import { PayerPlanQueryService } from '../../../../neb-api-client/src/services/payer-plan';
import {
  CSS_SPACING,
  CSS_COLOR_DISABLED,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { PAYMENT_METHODS } from '../../../../neb-utils/enums';
import { centsToCurrency, objToName } from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import {
  paymentHasAuthEFT,
  isDebitOrCreditCard,
  isPayerPayment,
} from '../../../../neb-utils/neb-payment-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import NebTableOld, { ELEMENTS as ELEMENTS_BASE } from './neb-table-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  transactionDate: {
    id: 'transaction-date',
  },
  payer: {
    id: 'payer',
  },
  patientName: {
    id: 'patient-name',
  },
  paymentType: {
    id: 'payment-type',
  },
  amount: {
    id: 'amount',
  },
  cardSaleId: {
    id: 'card-sale-id',
  },
  postedBy: {
    id: 'posted-by',
  },
  method: {
    id: 'method',
  },
  cardNumber: {
    id: 'card-number',
  },
  authEFT: {
    id: 'authEFT',
  },
  referenceId: {
    id: 'reference-id',
  },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
  },
  edit: {
    id: 'edit',
  },
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
  patientPayerLinks: {
    selector: '[id^=patient-payer-link-]',
  },
};
const REGEX_ALL = /[\s\S]+/;

function genInitialMask() {
  return {
    pattern: REGEX_ALL,
    toRawPosition: origPos => origPos,
    toRawValue: origVal => origVal,
    toFormattedPosition: rawPos => rawPos,
    toFormattedValue: rawVal => rawVal,
  };
}

class NebTablePaymentTransaction extends NebTableOld {
  static get properties() {
    return {
      readOnlyDetails: {
        type: Boolean,
        reflect: true,
      },
      paymentTypes: Array,
      errors: Array,
      payerPlans: Array,
      practiceUsers: Array,
    };
  }

  initState() {
    super.initState();
    this.readOnlyDetails = false;
    this.paymentTypes = [];
    this.errors = [];
    this.payerPlans = [];
    this.practiceUsers = [];
    this.config = this.genConfig();
    this.__payerSearchValue = '';

    this.__setupPayerPlanQueryService();

    this.onEdit = () => {};

    this.onSelectPatient = () => {};

    this.onPayerTransactionUpdate = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      edit: () => {
        if (!this.readOnlyDetails) {
          this.onEdit();
        }
      },
      selectPrimaryPayer: async () => {
        const payer = this.paymentDetail.payerPlan;

        const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payer.id,
        });

        if (res) this.onPayerTransactionUpdate();
      },
      clickPatient: e => {
        e.stopPropagation();
        return this.onSelectPatient();
      },
    };
  }

  __shouldGenCardSaleId() {
    return (
      this.model.length &&
      this.model[0].cardSaleId &&
      isDebitOrCreditCard({ paymentMethod: this.model[0].method })
    );
  }

  __computeConfigDate(date) {
    return date ? parseDate(date).format('L') : null;
  }

  __computeSelectedDate(date) {
    return date ? parseDate(date) : null;
  }

  genConfig() {
    return [
      {
        key: 'icon',
        label: '',
        flex: css`0 0 20px`,
      },
      {
        id: ELEMENTS.transactionDate.id,
        key: 'transactionDate',
        label: 'Transaction Date',
        flex: css`1 0 0`,
        formatter: v => this.__computeConfigDate(v),
      },
      {
        id: ELEMENTS.payer.id,
        key: 'payer',
        label: 'Payer',
        flex: css`1 0 0`,
        truncate: true,
      },
      {
        id: ELEMENTS.patientName.id,
        key: 'patientName',
        label: 'Patient',
        flex: css`1 0 0`,
        truncate: true,
      },
      {
        id: ELEMENTS.paymentType.id,
        key: 'paymentType',
        label: 'Type',
        flex: css`1 0 0`,
        formatter: v => (v ? v.split(' - ')[0] : v),
        menuItems: this.__getFilteredPaymentType(),
      },
      {
        id: ELEMENTS.method.id,
        key: 'method',
        label: 'Method',
        flex: css`1 0 0`,
        menuItems: isPayerPayment(this.model[0])
          ? [PAYMENT_METHODS.CHECK, PAYMENT_METHODS.DIRECT_DEPOSIT]
          : [PAYMENT_METHODS.CASH, PAYMENT_METHODS.CHECK],
        truncate: true,
      },
      ...(isDebitOrCreditCard({
        paymentMethod: this.model.length && this.model[0].method,
      })
        ? [
            {
              id: ELEMENTS.cardNumber.id,
              key: 'maskedCardDescription',
              label: 'Card Number',
              flex: css`1 0 60px`,
            },
          ]
        : []),
      {
        id: ELEMENTS.amount.id,
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
        maxLength: 11,
        mask: currency,
        formatter: a => centsToCurrency(a),
      },
      ...(this.__shouldGenCardSaleId()
        ? [
            {
              id: ELEMENTS.cardSaleId.id,
              key: 'cardSaleId',
              label: 'Card Sale ID',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        id: ELEMENTS.postedBy.id,
        key: 'postedById',
        label: 'Posted By',
        flex: css`1 0 0`,
        formatter: (postedById, item) => {
          if (item.patientOnline) {
            return 'Patient Online';
          }

          const user = this.practiceUsers.find(u => u.id === postedById);

          return user ? objToName(user.name, { reverse: true }) : '-';
        },
      },
      ...(isPayerPayment(this.model[0])
        ? [
            {
              id: ELEMENTS.referenceId.id,
              key: 'referenceId',
              label: 'Reference ID',
              flex: css`1 0 0`,
              truncate: true,
            },
            {
              id: ELEMENTS.dateOfServiceFrom.id,
              key: 'dateOfServiceFrom',
              label: 'DOS From',
              flex: css`1 0 0`,
              formatter: v => this.__computeConfigDate(v),
            },
            {
              id: ELEMENTS.dateOfServiceTo.id,
              key: 'dateOfServiceTo',
              label: 'DOS To',
              flex: css`1 0 0`,
              formatter: v => this.__computeConfigDate(v),
            },
          ]
        : []),
      ...(paymentHasAuthEFT({
        paymentMethod: this.model.length && this.model[0].method,
      })
        ? [
            {
              id: ELEMENTS.authEFT.id,
              key: 'authEFT',
              label: 'Auth / Check / EFT#',
              flex: css`1 0 0`,
              maxLength: 30,
              mask: genInitialMask(),
            },
          ]
        : []),
    ];
  }

  update(changedProps) {
    if (changedProps.has('model') || changedProps.has('layout')) {
      this.config = this.genConfig();
    }

    if (changedProps.has('payerPlans')) {
      this.__payerPlanItems = this.payerPlans.map(item => ({
        item: {
          id: item.id,
          payerName: item.payerName,
        },
        label: `(${item.alias}) ${item.payerName}`,
      }));
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow-x: hidden;
        }

        .content {
          padding: 11px ${CSS_SPACING};
        }

        .edit-icon {
          width: 100%;
          height: 20px;
          cursor: pointer;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([readOnlyDetails]) .edit-icon {
          fill: ${CSS_COLOR_DISABLED};
          cursor: auto;
        }

        .cell {
          align-items: center;
          white-space: nowrap;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  __setupPayerPlanQueryService() {
    this.__payerPlanQueryService = new PayerPlanQueryService(({ model }) => {
      this.payerPlans = model;
    }, PAYER_PLAN_VERSION_CLAIMS);
  }

  __getFilteredPaymentType() {
    if (!this.model.length) {
      return undefined;
    }

    const type = isPayerPayment(this.model[0]) ? 'forInsurance' : 'forPatient';

    return this.paymentTypes
      .filter(payment => payment.active && payment[type])
      .map(payment => `${payment.code} - ${payment.description}`);
  }

  renderCell(value, key, rowIndex) {
    if (key === 'icon') {
      return html`
        <neb-icon
          id="${ELEMENTS.edit.id}"
          class="edit-icon"
          icon="neb:edit"
          @click="${this.handlers.edit}"
        ></neb-icon>
      `;
    }

    if (key === 'payer') {
      if (isPayerPayment(this.model[0])) {
        return html`
          <neb-text
            id="${ELEMENTS.payer.id}"
            bold
            link
            truncate
            .onClick="${this.handlers.selectPrimaryPayer}"
            >${value.item.payerName}</neb-text
          >
        `;
      }
      return this.__renderPatientPayerCell(value, rowIndex);
    }

    if (key === 'patientName') return this.__renderPatientCell(value, rowIndex);

    return value || '-';
  }

  __renderPatientPayerCell(value, rowIndex) {
    return html`
      <div
        id="patient-payer-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderPatientCell(value, rowIndex) {
    return value
      ? html`
          <div
            id="patient-link-${rowIndex}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickPatient}"
          >
            <span class="patient-text">${value}</span>
            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </div>
        `
      : '-';
  }
}
customElements.define(
  'neb-table-payment-transaction',
  NebTablePaymentTransaction,
);
