import { getEncounterCharges } from '../../../packages/neb-api-client/src/charting/encounter-charge';
import { getEncounterDiagnoses } from '../../../packages/neb-api-client/src/encounters-api-client';
import { fetchNotes } from '../../../packages/neb-api-client/src/notes';
import { UpdateNotificationService } from '../../services/update-notifications';

import { FORCE_RELOAD_ENCOUNTER } from './neb-charting-util';
import { safeRequest } from './shared/safe-request';

const NOTES = {
  subjective: '',
  objective: '',
  assessment: '',
  plan: '',
};

export class SignedEncounterDataController {
  constructor(host) {
    host.addController(this);
    this.host = host;

    this.__initState();
    this.__initServices();
  }

  __initState() {
    this.__loadingFlags = {
      chartNotes: false,
      diagnoses: false,
      charges: false,
    };

    this.__state = {
      chartNotes: {
        subjective: '',
        objective: '',
        assessment: '',
        plan: '',
      },

      charges: [],
      diagnoses: [],
    };

    this.__encounterId = null;
  }

  __initServices() {
    this.__forceReloadService = new UpdateNotificationService({
      defaultQuery: FORCE_RELOAD_ENCOUNTER,
      callback: () => {
        this.__setAllPanelsToLoading();
        this.__fetchEncounterDependentData();
      },
    });
  }

  get loadingFlags() {
    return this.__loadingFlags;
  }

  get state() {
    return this.__state;
  }

  __setAllPanelsToLoading() {
    this.__loadingFlags = {
      chartNotes: true,
      diagnoses: true,
      charges: true,
    };

    this.host.requestUpdate();
  }

  __fetchEncounterDependentData() {
    safeRequest(async () => {
      const chartNotes = await fetchNotes(NOTES, this.__encounterId, true);

      this.__loadingFlags.chartNotes = false;
      this.__state.chartNotes = chartNotes;

      this.host.requestUpdate();
    }, 'chart notes');

    safeRequest(async () => {
      const diagnoses = await getEncounterDiagnoses(this.__encounterId, true);

      this.__loadingFlags.diagnoses = false;
      this.__state.diagnoses = diagnoses;

      this.host.requestUpdate();
    }, 'diagnoses');

    safeRequest(async () => {
      const charges = await getEncounterCharges(this.__encounterId, true);

      this.__loadingFlags.charges = false;
      this.__state.charges = charges;

      this.host.requestUpdate();
    }, 'charges');
  }

  hostConnected() {
    this.__forceReloadService.connect();
  }

  hostDisconnected() {
    this.__forceReloadService.disconnect();
  }

  hostUpdate() {
    const { encounterId } = this.host;

    if (this.__encounterId !== encounterId) {
      this.__encounterId = encounterId;

      this.__setAllPanelsToLoading();
      this.__fetchEncounterDependentData();
    }
  }
}
