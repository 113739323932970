const __updateState = (oldState, newState) => ({ ...oldState, ...newState });

const _fetching = state =>
  __updateState(state, {
    isFetching: true,
    isFetched: false,
  });

const _fetched = (state, action) =>
  __updateState(state, {
    isFetching: false,
    isFetched: true,
    items: action.values,
  });

const _fetchingError = (state, action) =>
  __updateState(state, {
    isFetching: false,
    isFetched: false,
    message: action.message,
  });

const _reset = state =>
  __updateState(state, {
    items: {},
  });

export { _fetching, _fetched, _fetchingError, _reset };
