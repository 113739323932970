import { LitElement, html, css } from 'lit';

import { getSignatureFile } from '../../../neb-api-client/src/provider-signature-api-client';

export const ELEMENTS = {
  containerSignature: {
    id: 'container-signature',
  },
  imageSignature: {
    id: 'image-signature',
  },
  infoSignature: {
    id: 'info-signature',
  },
  providerName: {
    id: 'provider-name',
  },
  signedDateTime: {
    id: 'signed-date-time',
  },
};

class NebProviderSignature extends LitElement {
  static get properties() {
    return {
      __signatureImage: {
        type: String,
      },
      model: {
        type: Object,
      },
    };
  }

  static get styles() {
    return css`
      .signature-image {
        max-height: 122px;
        max-width: 100%;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.__signatureImage = '';
    this.model = {};
  }

  async updated(changed) {
    if (changed.has('model') && this.model.signerId) {
      if (this.model.signedFileS3Key) {
        this.__signatureImage = await getSignatureFile(
          this.model.signerId,
          {
            signatureKey: this.model.signedFileS3Key,
          },
          true,
        );
      } else {
        this.__signatureImage = '';
      }
    }
  }

  __renderSignature() {
    return this.__signatureImage
      ? html`
          <img
            id="${ELEMENTS.imageSignature.id}"
            class="signature-image"
            src="${this.__signatureImage}"
          />
        `
      : '';
  }

  __renderSignatureInfo() {
    return this.model.signerName && this.model.signedDateTime
      ? html`
          <div id="${ELEMENTS.infoSignature.id}">
            <div id="${ELEMENTS.providerName.id}">${this.model.signerName}</div>
            <div id="${ELEMENTS.signedDateTime.id}">
              ${this.model.signedDateTime}
            </div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.containerSignature.id}">
        ${this.__renderSignature()} ${this.__renderSignatureInfo()}
      </div>
    `;
  }
}

window.customElements.define('neb-provider-signature', NebProviderSignature);
