import '../controls/neb-switch';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import { taxId } from '../../../../neb-utils/masks';
import { isTaxID, isZipCode, required } from '../../../../neb-utils/validators';
import Address from '../field-groups/neb-address';
import EmailList from '../field-groups/neb-email-list';
import PhoneList from '../field-groups/neb-phone-list';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  address: { id: 'address' },
  emailItems: { id: 'email-items' },
  phoneItems: { id: 'phone-items' },
  switchActive: { id: 'switch-active' },
  textareaNote: { id: 'textarea-note' },
  textfieldName: { id: 'textfield-name' },
  textfieldTaxId: { id: 'textfield-tax-id' },
};

export default class FormOrganization extends NebForm {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }

        .layout {
          gap: ${CSS_SPACING_ROW};
        }

        .textarea-note {
          height: 180px;
        }

        .switch {
          width: max-content;
        }
      `,
    ];
  }

  static createModel() {
    return {
      active: true,
      address: Address.createModel(),
      emails: EmailList.createModel(),
      name: '',
      note: '',
      phones: PhoneList.createModel(),
      taxId: '',
    };
  }

  createSelectors() {
    return {
      children: {
        address: {
          children: {
            zipcode: {
              validators: [isZipCode],
            },
          },
        },
        emails: EmailList.createSelectors(),
        name: {
          validators: [required()],
        },
        phones: PhoneList.createSelectors(),
        taxId: {
          validators: [isTaxID],
        },
      },
    };
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        name="name"
        helper="Required"
        label="Name"
        .error="${this.errors.name}"
        .value="${this.state.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.textfieldTaxId.id}"
        name="taxId"
        helper=" "
        label="Tax ID"
        maxlength="10"
        .error="${this.errors.taxId}"
        .mask="${taxId}"
        .inputMode="${'numeric'}"
        .value="${this.state.taxId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-phone-list
        id="${ELEMENTS.phoneItems.id}"
        name="phones"
        addLabel="Add Phone Number"
        maxItems="3"
        .errors="${this.errors.phones}"
        .model="${this.state.phones}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-phone-list>

      <neb-email-list
        id="${ELEMENTS.emailItems.id}"
        name="emails"
        addLabel="Add Email Address"
        maxItems="3"
        .errors="${this.errors.emails}"
        .model="${this.state.emails}"
        .onAdd="${this.handlers.addItem}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
        condense
      ></neb-email-list>

      <neb-address
        id="${ELEMENTS.address.id}"
        name="address"
        .errors="${this.errors.address}"
        .model="${this.state.address}"
        .onChange="${this.handlers.change}"
      ></neb-address>

      <neb-textarea
        id="${ELEMENTS.textareaNote.id}"
        class="textarea-note"
        name="note"
        label="Notes"
        .value="${this.state.note}"
        .onChange="${this.handlers.change}"
        showCount
      ></neb-textarea>

      <neb-switch
        id="${ELEMENTS.switchActive.id}"
        class="switch"
        name="active"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }
}

window.customElements.define('neb-form-organization', FormOrganization);
