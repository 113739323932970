import './neb-patient-encounter';

import { getParams } from '@neb/router';
import { html, LitElement, css } from 'lit';

import { getAppointmentTypes } from '../../../../../neb-api-client/src/appointment-types';
import { fetchMany as getPatientCases } from '../../../../../neb-api-client/src/patient-cases';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import { EncounterDataService } from '../../../../../neb-api-client/src/services/encounter-data';
import { forceFetchAppointments } from '../../../../../neb-calendar/neb-appointments-state';
import {
  navigateToCharting,
  openEncounterHistory,
  handleReopenEncounter,
  handleSignEncounter,
  openVisitSummary,
  printEncounterSummary,
  handleDeleteEncounter,
} from '../../../../../neb-lit-components/src/utils/encounter-actions-util';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { navigate } from '../../../../../neb-route/neb-route-state';
import { objToName } from '../../../../../neb-utils/formatters';
import {
  filterEncountersByStatus,
  cloneObjectArray,
  ENCOUNTER_STATUS,
  filterEncountersByServiceDate,
  filterEncountersByProviders,
  filterEncountersByAppointmentTypes,
  filterEncountersByCases,
} from '../../../../../neb-utils/neb-encounters-util';

export const EMPTY_ENCOUNTER_DATA = {
  charges: [],
  diagnoses: [],
  history: [],
};

export const ELEMENTS = {
  patientEncounter: {
    id: 'patient-encounter',
  },
};

class NebPatientEncounterController extends LitElement {
  static get properties() {
    return {
      __filter: {
        type: Object,
      },
      encountersModel: {
        type: Array,
      },
      encounterDataModels: {
        type: Object,
      },
      layout: {
        type: String,
        reflect: true,
      },
      route: {
        type: String,
      },
      __providers: Array,
      __appointmentTypes: Array,
      __cases: Array,
    };
  }

  static get styles() {
    return css`
      :host(:not([layout='small'])) {
        display: flex;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.encountersModel = [];
    this.__filter = {
      serviceDate: {
        from: null,
        to: null,
      },
      status: 'All',
      providers: [],
      appointmentTypes: [],
      cases: [],
    };

    this.encounterDataModels = EMPTY_ENCOUNTER_DATA;
    this.__selectedEncounterId = '';
    this.__prevEncounterId = '';
    this.__encounterDataService = new EncounterDataService(data => {
      this.encounterDataModels = data;
    });

    this.__appointmentTypes = [];
    this.__cases = [];
    this.__providers = [];

    this.onEncounterStatusChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      encounterSelected: (id, forceUpdate = false) => {
        if (id !== this.__selectedEncounterId || forceUpdate) {
          this.__handleEncounterSelected(id, forceUpdate);
        }
      },
      sign: async () => {
        const {
          charges,
          encounter: { providerId },
        } = this.encounterDataModels;

        const data = await handleSignEncounter({
          providerId,
          encounterId: this.__selectedEncounterId,
          patientId: this.patientId,
          charges,
        });

        if (data && data.res) {
          const { res } = data;

          if (res.data && res.data.length) {
            this.__updateEncounterStatus(res.data[0]);
          }
        }
      },
      edit: () => navigateToCharting(this.__selectedEncounterId),
      delete: async () => {
        const { charges, diagnoses } = this.encounterDataModels;
        const data = await handleDeleteEncounter({
          encounterId: this.__selectedEncounterId,
          charges,
          diagnoses,
          patientId: this.patientId,
        });

        if (data && data.res) {
          await this.__handleDeleteWithLayout(this.__selectedEncounterId);

          const storeDate = store.getState().appointments.targetDate;
          await store.dispatch(forceFetchAppointments(storeDate));
        }
      },
      reopen: async () => {
        const data = await handleReopenEncounter(this.__selectedEncounterId);

        if (data && data.res) {
          const { res } = data;

          if (res.data && res.data.length) {
            this.__updateEncounterStatus(res.data[0]);
          }
        }
      },
      filterEncounters: filter => {
        this.__filter = filter;
      },
      viewHistory: () => this.__handleViewHistory(),
      print: () =>
        printEncounterSummary({
          patientId: this.patientId,
          encounterId: this.__selectedEncounterId,
        }),
      openVisitSummary: () =>
        openVisitSummary({
          patientId: this.patientId,
          encounterId: this.__selectedEncounterId,
        }),
      updateEncounterCaseAuth: ({
        encounterIds,
        caseId,
        patientAuthorizationId,
      }) => {
        this.__updateEncounterCaseAuth({
          encounterIds,
          caseId,
          patientAuthorizationId,
        });
      },
    };
  }

  async firstUpdated() {
    this.__elements = {
      patientEncounter: this.shadowRoot.getElementById(
        ELEMENTS.patientEncounter.id,
      ),
    };

    const [appointmentTypes, cases, providers] = await Promise.all([
      getAppointmentTypes(),
      getPatientCases(this.patientId),
      getProviderUsers(),
    ]);

    this.__appointmentTypes = appointmentTypes.data;
    this.__cases = cases;
    this.__providers = providers;
  }

  update(changed) {
    this.__routeChanged(changed);

    this.__encountersModelChanged(changed);

    if (this.encountersModel.length > 0) {
      this.__updateEncounterDataModels(this.__selectedEncounterId);
    }
    super.update(changed);
  }

  __encountersModelChanged(changed) {
    if (changed.has('encountersModel')) {
      this.__handleSelectFirstEncounter(this.__selectedEncounterId);
    }
  }

  __routeChanged(changed) {
    if (changed.has('route')) {
      const { encounterId } = getParams('encounters/:encounterId', this.route);
      this.__selectedEncounterId = encounterId;

      this.__handleSelectFirstEncounter(encounterId);
    }
  }

  __handleDeleteWithLayout(id) {
    return this.layout !== 'small'
      ? this.__handleEncounterAfterDelete(id)
      : this.__handleEncounterAfterDeleteMobile(id);
  }

  __updateEncounterDataModels(id, forceUpdate = false) {
    if ((id && this.__prevEncounterId !== id) || forceUpdate) {
      this.__encounterDataService.update(id);

      this.__prevEncounterId = id;
    }
  }

  __handleSelectFirstEncounter(encounterId = '') {
    if (this.__shouldSelectFirstEncounter(encounterId)) {
      this.__handleEncounterSelected(this.encountersModel[0].id);
    }
  }

  __shouldSelectFirstEncounter(encounterId) {
    return (
      this.layout !== 'small' && !encounterId && this.encountersModel.length > 0
    );
  }

  // eslint-disable-next-line complexity
  __filterEncounters() {
    if (this.encountersModel.length === 0) {
      return [];
    }

    const { appointmentTypes, cases, providers, serviceDate, status } =
      this.__filter;

    let filteredEncounters = cloneObjectArray(this.encountersModel);

    if (
      status === ENCOUNTER_STATUS.SIGNED ||
      status === ENCOUNTER_STATUS.OPEN
    ) {
      filteredEncounters = filterEncountersByStatus(
        this.encountersModel,
        status,
      );
    }

    const { from, to } = serviceDate;

    if (from || to) {
      filteredEncounters = filterEncountersByServiceDate(
        filteredEncounters,
        serviceDate,
      );
    }

    if (providers.length) {
      filteredEncounters = filterEncountersByProviders(
        filteredEncounters,
        providers,
      );
    }

    if (appointmentTypes.length) {
      filteredEncounters = filterEncountersByAppointmentTypes(
        filteredEncounters,
        appointmentTypes,
      );
    }

    if (cases.length) {
      filteredEncounters = filterEncountersByCases(filteredEncounters, cases);
    }

    this.__handleNoResults(filteredEncounters);

    return filteredEncounters;
  }

  __filterAppointmentTypes() {
    return this.__appointmentTypes
      .filter(type =>
        this.encountersModel.some(
          encounter => encounter.appointmentTypeId === type.id,
        ),
      )
      .map(type => ({
        label: type.name,
        data: type,
      }));
  }

  __filterCases() {
    return this.__cases
      .filter(caseItem =>
        this.encountersModel.some(
          encounter => encounter.caseId === caseItem.id,
        ),
      )
      .map(caseItem => ({
        label: caseItem.name,
        data: caseItem,
      }));
  }

  __filterProviders() {
    return this.__providers
      .filter(provider =>
        this.encountersModel.some(
          encounter => encounter.providerId === provider.id,
        ),
      )
      .map(provider => ({
        label: objToName(provider.name, { reverse: true }),
        data: provider,
      }));
  }

  __handleNoResults(filtered) {
    if (filtered.length === 0) {
      this.__elements.patientEncounter.showNoResults();

      this.encounterDataModels = EMPTY_ENCOUNTER_DATA;
    }
  }

  __handleEncounterSelected(id, forceUpdate = false) {
    store.dispatch(
      navigate(`#/patients/${this.patientId}/clinical/encounters/${id}`),
    );

    this.__selectedEncounterId = id;

    if (id) {
      this.__updateEncounterDataModels(this.__selectedEncounterId, forceUpdate);
    } else {
      this.encounterDataModels = EMPTY_ENCOUNTER_DATA;
    }
  }

  async __handleViewHistory() {
    await this.__openHistoryOverlay(this.__selectedEncounterId);
  }

  async __openHistoryOverlay(id) {
    await openEncounterHistory(id);
  }

  __handleEncounterAfterDelete(deletedEncounterId) {
    const validEncounters = this.encountersModel.filter(
      encounter => encounter.id !== deletedEncounterId,
    );
    this.onEncounterStatusChanged(validEncounters, true);

    if (validEncounters.length > 0) {
      this.__handleEncounterSelected(validEncounters[0].id);
    } else {
      this.__handleEncounterSelected('');
    }
  }

  __handleEncounterAfterDeleteMobile(deletedEncounterId) {
    const validEncounters = this.encountersModel.filter(
      encounter => encounter.id !== deletedEncounterId,
    );
    this.onEncounterStatusChanged(validEncounters, true);

    store.dispatch(
      navigate(`#/patients/${this.patientId}/clinical/encounters/`),
    );

    this.__selectedEncounterId = '';
  }

  __updateEncounterStatus(updatedEncounter) {
    const clonedEncounters = this.encountersModel.map(encounter => ({
      ...encounter,
      signed:
        updatedEncounter.id === encounter.id
          ? updatedEncounter.signed
          : encounter.signed,
    }));
    this.__prevEncounterId = '';
    this.onEncounterStatusChanged(clonedEncounters);
  }

  __updateEncounterCaseAuth({ encounterIds, caseId, patientAuthorizationId }) {
    const updatedEncounters = [
      ...this.encountersModel.map(encounter => ({
        ...encounter,
        checked: false,
        caseId: encounterIds.includes(encounter.id) ? caseId : encounter.caseId,
        patientAuthorizationId: encounterIds.includes(encounter.id)
          ? patientAuthorizationId
          : encounter.patientAuthorizationId,
      })),
    ];

    this.onEncounterStatusChanged(updatedEncounters);
  }

  render() {
    return html`
      <neb-patient-encounter
        id="${ELEMENTS.patientEncounter.id}"
        .patientId="${this.patientId}"
        .encountersModel="${this.__filterEncounters()}"
        .encounterDataModels="${this.encounterDataModels}"
        .onFilter="${this.__handlers.filterEncounters}"
        .layout="${this.layout}"
        .selectedEncounterId="${this.__selectedEncounterId}"
        .onEncounterSelect="${this.__handlers.encounterSelected}"
        .onSign="${this.__handlers.sign}"
        .onEdit="${this.__handlers.edit}"
        .onDelete="${this.__handlers.delete}"
        .onReopen="${this.__handlers.reopen}"
        .onViewHistory="${this.__handlers.viewHistory}"
        .onPrint="${this.__handlers.print}"
        .onOpenVisitSummary="${this.__handlers.openVisitSummary}"
        .onUpdateEncounterCaseAuth="${this.__handlers.updateEncounterCaseAuth}"
        .appointmentTypes="${this.__filterAppointmentTypes()}"
        .cases="${this.__filterCases()}"
        .providers="${this.__filterProviders()}"
      ></neb-patient-encounter>
    `;
  }
}

customElements.define(
  'neb-patient-encounter-controller',
  NebPatientEncounterController,
);
