import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});
export const BASE_URL = 'visit-summary';

export const pdfApiClient = new DeprecatedApiClient({
  microservice: 'pdf',
  useTenant: true,
});

export const getVisitSummary = (
  { body, patientId },
  optOutLoadingIndicator = false,
) =>
  billingApiClient.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/encounters/visit-summary`,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    version: 1,
    optOutLoadingIndicator,
  });

export const tagAndAssociateVisitSummary = (
  { body, patientId },
  optOutLoadingIndicator = false,
) =>
  billingApiClient.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/encounters/visit-summary/tag-associate`,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });

export const createVisitSummaryPdf = async (
  body,
  optOutLoadingIndicator = true,
) => {
  const res = await pdfApiClient.makeRequest({
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return res;
};
