import {
  SCRUB_CODE,
  CLAIM_VIEW_ELEMENTS,
} from '../neb-lit-components/src/utils/claim-validation';

const FIELD_NAMES = [
  'code',
  'description',
  'field',
  'followupAction',
  'location',
  'value',
];

const SCRUBBING_FIELD_ELEMENTS = {
  [SCRUB_CODE.CL001]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL002]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL003]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL004]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL006]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL007]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.CL008]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL001]: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  [SCRUB_CODE.PL002]: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  [SCRUB_CODE.PL003]: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  [SCRUB_CODE.PL005]: CLAIM_VIEW_ELEMENTS.billingInfoCell.id,
  [SCRUB_CODE.PL006]: CLAIM_VIEW_ELEMENTS.insuredInfoCell.id,
  [SCRUB_CODE.PL007]: CLAIM_VIEW_ELEMENTS.dateOfCurrentIllnessCell.id,
  [SCRUB_CODE.PL008]: CLAIM_VIEW_ELEMENTS.patientAddressCell.id,
  [SCRUB_CODE.PL009]: CLAIM_VIEW_ELEMENTS.insuredInfoCell.id,
  [SCRUB_CODE.PL010]: CLAIM_VIEW_ELEMENTS.otherDateCell.id,
  [SCRUB_CODE.PL012]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL013]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL014]: CLAIM_VIEW_ELEMENTS.patientsConditionRelatedToCell.id,
  [SCRUB_CODE.PL015]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL016]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL017]: CLAIM_VIEW_ELEMENTS.patientRelationshipCell.id,
  [SCRUB_CODE.PL018]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL019]: CLAIM_VIEW_ELEMENTS.insuredNumberCell.id,
  [SCRUB_CODE.PL020]: CLAIM_VIEW_ELEMENTS.otherDateCell.id,
  [SCRUB_CODE.PL021]: CLAIM_VIEW_ELEMENTS.otherDateCell.id,
  [SCRUB_CODE.PL022]: CLAIM_VIEW_ELEMENTS.referringProviderCell.id,
  [SCRUB_CODE.PL023]: CLAIM_VIEW_ELEMENTS.insuredAddressCell.id,
  [SCRUB_CODE.PL024]: CLAIM_VIEW_ELEMENTS.insuredInfoCell.id,
  [SCRUB_CODE.PL025]: CLAIM_VIEW_ELEMENTS.federalTaxIdCell.id,
  [SCRUB_CODE.PL026]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL027]: CLAIM_VIEW_ELEMENTS.claimChargesCell.id,
  [SCRUB_CODE.PL028]: CLAIM_VIEW_ELEMENTS.serviceFacilityInfoCell.id,
  [SCRUB_CODE.PL031]: CLAIM_VIEW_ELEMENTS.referringProviderCell.id,
  [SCRUB_CODE.PL032]: CLAIM_VIEW_ELEMENTS.fl19Cell.id,
  [SCRUB_CODE.PL033]: CLAIM_VIEW_ELEMENTS.resubmissionCodeCell.id,
  [SCRUB_CODE.PL034]: CLAIM_VIEW_ELEMENTS.resubmissionCodeCell.id,
  [SCRUB_CODE.PL035]: CLAIM_VIEW_ELEMENTS.insuredNameCell.id,
  [SCRUB_CODE.PL036]: CLAIM_VIEW_ELEMENTS.patientNameCell.id,
};

export const validationErrorFormatter = (errors, hasRcmRelease2) => {
  const cleanedErrors = errors.map(error => {
    const removeNullKeys = { ...error };

    delete removeNullKeys.scrubbingDetails;

    FIELD_NAMES.forEach(key => {
      if (removeNullKeys[key] === null) delete removeNullKeys[key];
    });

    if (!hasRcmRelease2) {
      delete removeNullKeys.assignedTo;
      delete removeNullKeys.completedAt;
      delete removeNullKeys.completedBy;
    }

    return removeNullKeys;
  });

  const formattedErrors = cleanedErrors.map(error => {
    const errorItem = {
      validationErrors: { ...error },
    };

    return errorItem;
  });

  return formattedErrors;
};

export const scrubbingErrorFormatter = errors => {
  const formattedErrors = Object.values(errors).map(error => {
    const errorItem = {
      idToScrollTo: SCRUBBING_FIELD_ELEMENTS[error.code],
      title: error.field ? error.field.split(' -')[0] : '',
      description: error.description,
    };

    return errorItem;
  });

  const getFieldNumber = field =>
    field === 'FL N/A' ? field : parseInt(field.match(/\d+/)[0], 10);

  formattedErrors.sort((a, b) => {
    if (getFieldNumber(a.title) > getFieldNumber(b.title)) return 1;
    if (getFieldNumber(a.title) < getFieldNumber(b.title)) return -1;

    return a.title.localeCompare(b.title);
  });

  return formattedErrors;
};
