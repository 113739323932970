import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';
import {
  objToName,
  formatPhones,
  formatAge,
  capitalize,
} from '../../../packages/neb-utils/formatters';

export const ELEMENTS = {
  sectionBasic: {
    id: 'section-basic',
    title: 'Basic Information',
  },
  sectionAdditional: {
    id: 'section-additional',
    title: 'Additional Information',
  },
  sectionContact: {
    id: 'section-contact',
    title: 'Contact Information',
  },
  patientName: {
    id: 'patient-name',
  },
  sex: {
    id: 'sex',
  },
  DOB: {
    id: 'date-of-birth',
  },
  pinLabel: {
    id: 'pin-label',
  },
  pin: {
    id: 'pin',
  },
  MRN: {
    id: 'medical-record-number',
  },
  SSN: {
    id: 'social-security-number',
  },
  relationshipStatus: {
    id: 'relationship-status',
  },
  employmentStatus: {
    id: 'employment-status',
  },
  preferredProvider: {
    id: 'preferred-provider',
  },
  address: {
    id: 'address',
  },
  addressHeader: {
    id: 'address-header',
  },
  addressFooter: {
    id: 'address-footer',
  },
  phones: {
    selector: '.phones',
    idPrefix: 'phones',
  },
  emails: {
    selector: '.emails',
    idPrefix: 'emails',
  },
  patientStatus: {
    id: 'patient-status',
  },
  preferredLocation: {
    id: 'preferred-location',
  },
  referralSourceInfo: {
    id: 'referral-source',
    title: 'Referred By',
  },
  referralCategory: {
    id: 'referral-source-category',
  },
  referralDescription: {
    id: 'referral-source-description',
  },
  referralCategorySource: {
    id: 'referral-category-source',
  },
};

const NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

class NebPatientDemographics extends LitElement {
  static get properties() {
    return {
      patient: { type: Object },
      preferredProviderName: { type: String },
      preferredLocationName: { type: String },
      referralCategorySource: { type: Object },
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.patient = {
      medicalRecordNumber: '',
      firstName: '',
      middleName: '',
      lastName: '',
      preferredName: null,
      dateOfBirth: '',
      emailAddresses: [],
      phoneNumbers: [],
      addresses: [],
      ssn: null,
      sex: null,
      relationshipStatus: null,
      employmentStatus: null,
    };

    this.preferredProviderName = '-';
    this.preferredLocationName = '-';
    this.referralCategorySource = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          padding: 10px 10px 0;
        }

        .flex-row {
          display: flex;
          padding-bottom: ${CSS_SPACING};
        }

        :host([layout='small']) .flex-row {
          flex-direction: column;
        }

        .column-padding {
          padding-top: 35px;
        }

        :host([layout='small']) .column-padding {
          padding-top: 0px;
        }

        .last-row {
          padding-bottom: 0;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        :host(:not([layout='small'])) .right-column {
          padding-left: ${CSS_SPACING};
        }

        .flex-1 {
          flex: 1;
        }

        .title {
          text-decoration: underline;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .text-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :host([layout='small']) .wrapper-grid {
          display: grid;
          grid-template-columns: 1fr;
          padding-bottom: ${CSS_SPACING};
        }

        .wrapper-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: ${CSS_SPACING};
          grid-row: 1;
        }

        .left-column-grid {
          grid-column: 1;
          padding-bottom: ${CSS_SPACING};
        }

        :host(:not([layout='small'])) .right-column-grid {
          grid-column: 2;
          padding-bottom: ${CSS_SPACING};
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __formatHeader(address) {
    if (address) {
      const { address1, address2 } = address;
      return [address1, address2].filter(item => item !== '');
    }
    return '';
  }

  __formatFooter(address) {
    let addressBuilder = '';

    if (address) {
      const { city, state, zipcode } = address;

      if (city) {
        addressBuilder += city;
      }

      if (state) {
        addressBuilder += `, ${state}`;
      } else {
        addressBuilder += ' ';
      }

      if (zipcode) {
        addressBuilder += ` ${zipcode}`;
      }
    }
    return addressBuilder;
  }

  __renderPatientInfo() {
    const {
      lastName: last,
      firstName: first,
      middleName: middle,
      preferredName: preferred,
      suffix,
      sex,
      dateOfBirth,
      selfCheckInPIN,
    } = this.patient;
    const name = objToName(
      { first, last, middle, suffix, preferred },
      NAME_OPTS,
    );
    return html`
      <div id="${ELEMENTS.patientName.id}" class="bold">${name || '-'}</div>
      <div id="${ELEMENTS.sex.id}">${name ? sex || 'Unknown' : '-'}</div>
      <div id="${ELEMENTS.DOB.id}">${name ? formatAge(dateOfBirth) : '-'}</div>
      <div id="${ELEMENTS.pinLabel.id}" class="bold">Self Check In PIN</div>
      <div id="${ELEMENTS.pin.id}">
        ${selfCheckInPIN ? 'Assigned' : 'Unassigned'}
      </div>
    `;
  }

  __renderPatientStatus() {
    return html`
      <div class="bold">Status</div>
      <div id="${ELEMENTS.patientStatus.id}" class="text-overflow">
        ${this.patient.status ? capitalize(this.patient.status) : '-'}
      </div>
    `;
  }

  __renderBasicInfo({ id, title }) {
    return html`
      <div id="${id}" class="flex-column flex-1">
        <div class="title">${title}</div>
        <div class="flex-row">
          <div class="flex-1">${this.__renderPatientInfo()}</div>
          <div class="flex-1 right-column text-overflow">
            <div class="bold">Medical Record Number</div>
            <div id="${ELEMENTS.MRN.id}" class="text-overflow">
              ${this.patient.medicalRecordNumber || '-'}
            </div>
            ${this.__renderPatientStatus()}
          </div>
        </div>
      </div>
    `;
  }

  __renderAdditionalInfo({ id, title }) {
    return html`
      <div id="${id}" class="flex-column flex-1">
        <div class="title">${title}</div>
        <div class="flex-row">
          <div class="flex-1">
            <div class="bold">Social Security Number</div>
            <div id="${ELEMENTS.SSN.id}">
              ${this.patient.ssn ? this.patient.ssn : '-'}
            </div>
            <div class="bold">Relationship Status</div>
            <div id="${ELEMENTS.relationshipStatus.id}">
              ${
                this.patient.relationshipStatus
                  ? this.patient.relationshipStatus
                  : '-'
              }
            </div>
            <div class="bold">Preferred Provider</div>
            <div id="${ELEMENTS.preferredProvider.id}">
              ${this.preferredProviderName}
            </div>
          </div>
          <div class="flex-1 right-column">
            <div class="bold">Employment Status</div>
            <div id="${ELEMENTS.employmentStatus.id}">
              ${
                this.patient.employmentStatus
                  ? this.patient.employmentStatus
                  : '-'
              }
            </div>
              ${html`
                <div class="column-padding">
                  <div class="bold">Preferred Location</div>
                  <div id="${ELEMENTS.preferredLocation.id}">
                    ${this.preferredLocationName}
                  </div>
                </div>
              `}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  __renderPhoneList() {
    const phones = formatPhones(this.patient.phoneNumbers);
    return phones.map(({ number, type }, index) => {
      const id = `${ELEMENTS.phones.idPrefix}-${index}`;
      return number
        ? html` <div id="${id}" class="phones">${number} (${type})</div> `
        : '';
    });
  }

  __renderEmailList() {
    const emails = this.patient.emailAddresses || [];
    return emails.map((email, index) => {
      const id = `${ELEMENTS.emails.idPrefix}-${index}`;
      return html` <div id="${id}" class="emails">${email}</div> `;
    });
  }

  __renderEmailSection() {
    return html`
      <div class="bold">Email</div>
      ${this.patient.emailAddresses.length === 0
        ? html` <div id="${ELEMENTS.emails.idPrefix}" class="emails">-</div> `
        : this.__renderEmailList()}
    `;
  }

  __renderReferralSource() {
    const hasReferralSource = this.patient.referralSource;

    return html`
      <div id="${ELEMENTS.referralSourceInfo.id}" class="title">
        ${ELEMENTS.referralSourceInfo.title}
      </div>
      <div id class="bold">Category</div>
      <div id="${ELEMENTS.referralCategory.id}">
        ${hasReferralSource && this.referralCategorySource.category
          ? this.referralCategorySource.category
          : '-'}
      </div>
      <div id class="bold">Source</div>
      <div id="${ELEMENTS.referralCategorySource.id}">
        ${hasReferralSource && this.referralCategorySource.source
          ? this.referralCategorySource.source
          : '-'}
      </div>
      <div id class="bold">Description</div>
      <div id="${ELEMENTS.referralDescription.id}">
        ${hasReferralSource && this.referralCategorySource.description
          ? this.referralCategorySource.description
          : '-'}
      </div>
    `;
  }

  __renderContactInfo() {
    return html`
      <div id="${ELEMENTS.sectionContact.id}" class="wrapper-grid">
        <div class="left-column-grid">
          <div class="title">${ELEMENTS.sectionContact.title}</div>
          <div class="bold">Address</div>
          <div id="${ELEMENTS.address.id}">
            <div id="${ELEMENTS.addressHeader.id}">
              ${this.patient.addresses.length === 0
                ? '-'
                : this.__formatHeader(...this.patient.addresses).map(
                    i => html` <div>${i}</div> `,
                  )}
            </div>
            <div id="${ELEMENTS.addressFooter.id}">
              ${this.patient.addresses.length === 0
                ? '-'
                : this.__formatFooter(...this.patient.addresses)}
            </div>
          </div>
          <div class="bold">Phone Number</div>
          ${this.patient.phoneNumbers.length === 0
            ? html`
                <div id="${ELEMENTS.phones.idPrefix}" class="phones">-</div>
              `
            : this.__renderPhoneList()}
          ${this.__renderEmailSection()}
        </div>

        <div class="right-column-grid">${this.__renderReferralSource()}</div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="flex-column container">
        ${this.__renderBasicInfo(ELEMENTS.sectionBasic)}
        ${this.__renderAdditionalInfo(ELEMENTS.sectionAdditional)}
        ${this.__renderContactInfo(ELEMENTS.sectionContact)}
      </div>
    `;
  }
}
customElements.define('neb-patient-demographics', NebPatientDemographics);
