import { html } from 'lit';

import '../../controllers/claim-errors/neb-payer-plan-controller';
import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  payerPlanController: { id: 'payer-plan-controller' },
};

class NebOverlayClaimErrorPayer extends NebOverlayClaimError {
  static get properties() {
    return {};
  }

  initState() {
    super.initState();
    this.widthRatio = '50/50';
  }

  initHandlers() {
    super.initHandlers();
  }

  __reformatModel() {
    const { payerPlanId } = this.model.claim.insurance;
    return { id: payerPlanId };
  }

  renderForm() {
    return html`
      <neb-payer-plan-controller
        id="${ELEMENTS.payerPlanController.id}"
        .model="${this.__reformatModel()}"
        .showCancelButton="${false}"
        .condensedHeader="${true}"
        .layout="${this.layout}"
        .onCancel="${this.handlers.dismiss}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-payer-plan-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-payer',
  NebOverlayClaimErrorPayer,
);
