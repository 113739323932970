import DeprecatedApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({
  microservice: 'files',
  useTenant: true,
});

export const uploadFile = async (
  file,
  optOutLoadingIndicator = false,
  clearinghouse,
) => {
  const data = new FormData();
  data.append('file', file);
  data.append('clearinghousePartnerName', clearinghouse.partner);
  data.append('clearinghouseId', clearinghouse.id);
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: '835/upload',
    body: data,
    headers: {},
    version: 1,
    optOutLoadingIndicator,
  });

  return res;
};

export const testConnection = async (body, optOutLoadingIndicator = false) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: 'sftp/test-connection',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return res;
};

export const uploadClearinghouseSftp = async (
  body,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: 'sftp',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return res;
};
