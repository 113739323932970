import '../../patients/insurance/view/neb-patient-insurance-view-controller';

import { html, css } from 'lit';

import { getPatientInsurance } from '../../../../../neb-api-client/src/patient-insurance-api-client';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../../neb-utils/patientInsurance';
import Overlay from '../neb-overlay';
import '../../../../../../src/components/pages/real-time-eligibility/neb-page-eligibility-benefits-viewer';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
  benefitsViewer: {
    id: 'benefits-viewer',
  },
};

class NebOverlayPatientInsuranceView extends Overlay {
  static get properties() {
    return {
      __patientInsurance: Object,

      patientId: String,
      patientInsuranceId: String,
      rteJSON: Object,
      jsonExists: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  initState() {
    super.initState();

    this.__patientInsurance = { ...createModelNewPatientInsurance() };

    this.patientId = '';
    this.rteJSON = {
      idInfo: { subscriber: {}, provider: {}, payer: {} },
      benefitInfo: { activeServiceTypes: {}, inactiveServiceTypes: {} },
    };

    this.jsonExists = false;
    this.patientInsuranceId = null;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: () => {
        this.result = true;
        this.fetch();
      },
      dismiss: () => this.dismiss(this.result),
      openReport: report => {
        this.jsonExists = true;
        this.rteJSON = report;
      },
      closeReport: () => {
        this.jsonExists = false;
      },
    };
  }

  dismissWithBlocker() {
    this.dismiss(this.result);
  }

  async fetch() {
    if (!this.patientInsuranceId) return;

    const rawInsurance = await getPatientInsurance(
      this.patientId,
      this.patientInsuranceId,
      2,
    );

    this.__patientInsurance = { ...mapToPatientInsuranceModel(rawInsurance) };
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      const { patientId, patientInsurance } = this.model;

      this.patientId = patientId || '';
      this.patientInsuranceId = patientInsurance.id;

      await this.fetch();
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        :host([jsonExists]) .content {
          width: 1750px;
        }

        :host([layout='small']) .content {
          width: 875px;
        }

        .controller {
          flex: 1 0 0;
        }

        :host([layout='small']) .controller {
          display: contents;
        }

        .expandable-container {
          display: grid;
          grid-template-columns: 1fr;
          height: 100%;
        }

        :host([layout='small']) .expandable-container {
          display: flex;
        }

        :host([jsonExists]) .expandable-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          height: 100%;
        }

        :host(:not([jsonExists])) .benefits {
          display: none;
        }

        :host([layout='small']) .benefits {
          display: none;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="content-container" class="expandable-container">
        <neb-page-eligibility-benefits-viewer
          id="${ELEMENTS.benefitsViewer.id}"
          class="benefits"
          .reportData="${this.rteJSON}"
          .onCloseReport="${this.handlers.closeReport}"
        >
        </neb-page-eligibility-benefits-viewer>
        <neb-patient-insurance-view-controller
          id="${ELEMENTS.controller.id}"
          class="controller"
          .layout="${this.layout}"
          .patientId="${this.patientId}"
          .patientInsurance="${this.__patientInsurance}"
          .rteJSON="${this.rteJSON}"
          .jsonExists="${this.jsonExists}"
          .onClose="${this.handlers.dismiss}"
          .onChange="${this.handlers.change}"
          .onOpenReport="${this.handlers.openReport}"
          slim
        ></neb-patient-insurance-view-controller>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-view',
  NebOverlayPatientInsuranceView,
);
