import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_COLOR_GREY_1 } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  message: {
    id: 'message',
    label:
      "The chart note for today's encounter already has contents in one or more sections. Would you like to append the copied content to the existing note or replace the content of the existing note?",
  },
  buttonAppend: { id: 'button-append' },
  buttonReplace: { id: 'button-replace' },
  buttonCancel: { id: 'button-cancel' },
};

class NebPopupSoapNoteAppendReplace extends NebPopup {
  initState() {
    super.initState();
    this.showHeader = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () => {
      this.onClose({
        action: 'cancel',
      });
    };

    this.handlers.append = () => {
      this.onClose({
        action: 'append',
      });
    };

    this.handlers.replace = () => {
      this.onClose({
        action: 'replace',
      });
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 650px;
          height: 180px;
        }

        .content-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          margin: 0 10px;
          padding-bottom: 10px;
          max-height: 1151px;
          max-width: 662px;
        }

        .button-container {
          display: flex;
          justify-content: space-between;
        }

        .message {
          color: ${CSS_COLOR_GREY_1};
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.message.id}" class="message">
          ${ELEMENTS.message.label}
        </div>
        <div class="button-container">
          <div class="action-buttons">
            <neb-button
              id="${ELEMENTS.buttonAppend.id}"
              class="button"
              role="${BUTTON_ROLE.CONFIRM}"
              .label="${'Append'}"
              .onClick="${this.handlers.append}"
            ></neb-button>

            <neb-button
              id="${ELEMENTS.buttonReplace.id}"
              class="button"
              role="${BUTTON_ROLE.CONFIRM}"
              .label="${'Replace'}"
              .onClick="${this.handlers.replace}"
            ></neb-button>
          </div>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            role="${BUTTON_ROLE.CANCEL}"
            .label="${'Cancel'}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-soap-note-append-replace',
  NebPopupSoapNoteAppendReplace,
);
