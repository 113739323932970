import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  dropdown: { id: 'dropdown' },
};

class NebPopupClaimDropdown extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .dropdown {
          width: 100%;
          padding-bottom: 10px;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__state = { ...this.__state, [name]: value };
      },

      confirm: () => this.onClose(this.__state[this.model.name].value),

      renderItem: item =>
        item.label &&
        html`
          <p style="padding: 0 16px; width: max-content; max-width: 440px">
            ${item.label}
          </p>
        `,
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = { [this.model.name]: this.model.selectedOption };
    }
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.dropdown.id}"
        class="dropdown"
        showFullText
        .name="${this.model.name}"
        .label="${this.model.label}"
        .value="${this.__state[this.model.name]}"
        .items="${this.model.options}"
        .onChange="${this.handlers.change}"
        .onRenderItem="${this.handlers.renderItem}"
      >
      </neb-select>
    `;
  }
}

customElements.define('neb-popup-claim-dropdown', NebPopupClaimDropdown);
