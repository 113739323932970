import '../inputs/neb-textfield';
import '../neb-text';
import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';
import { v4 as uuid } from 'uuid';

import { formatAnswerSet } from '../../../../../src/formatters/macros';
import {
  mergeUniqueAnswerSets,
  QUESTION_ANSWER_TOKEN,
  getUnlinkPopupConfig,
  getLinkedChoiceTemplateName,
} from '../../../../../src/utils/macros';
import { getChoiceTemplates } from '../../../../neb-api-client/src/macro-set-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { deepCopy } from '../../../../neb-utils/utils';
import { REGION_LENGTH } from '../macros/neb-macro-region';
import { genDefaultBodyDiagramLabels } from '../macros/utils/default-body-diagram-labels';
import { BUTTON_ROLE } from '../neb-button';

import NebForm from './neb-form';

export const ELEMENTS = {
  choiceTemplateName: { id: 'choice-template-name' },
  linkChoiceTemplateButton: { id: 'link-choice-template-button' },
  saveChoiceTemplateButton: { id: 'save-choice-template-button' },
  unlinkChoiceTemplateButton: { id: 'unlink-choice-template-button' },
  editChoiceTemplateButton: { id: 'edit-choice-template-button' },
  questionField: { id: 'question-field' },
  macroRegion: { id: 'macro-region' },
  choiceSetupButton: { id: 'choice-setup-button' },
};

export default class NebFormMacroRegion extends NebForm {
  static get properties() {
    return {
      defaultBodyAnswerSetId: String,
      macroSetId: String,
      answerSetDict: Object,
    };
  }

  static createModel(defaultBodyAnswerSetId = '') {
    return {
      question: {
        text: '',
        defaultIndices: new Array(REGION_LENGTH).fill(null),
        answerSetId: defaultBodyAnswerSetId,
      },

      answerSet: {
        name: '',
        deleted: false,
        answers: genDefaultBodyDiagramLabels(),
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }

        .button {
          display: flex;
          justify-content: flex-end;
        }

        .container-button {
          display: grid;
          justify-content: flex-end;
          grid-template-columns: auto auto;
          grid-gap: 10px;
        }

        .linked-container {
          display: flex;
          flex: 1 0 0;
          justify-content: space-between;
          align-items: center;
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        question: {
          children: {
            text: [isRequired()],
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.defaultBodyAnswerSetId = '';
    this.macroSetId = '';
    this.answerSetDict = {};

    this.onChangeAnswerSets = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickRegion: index => {
        if (this.state.question.defaultIndices[index] !== null) {
          this.formService.apply(`question.defaultIndices.${index}`, null);
        } else {
          this.formService.apply(`question.defaultIndices.${index}`, index);
        }
      },
      clear: () => {
        this.state.question.defaultIndices.forEach((_, index) => {
          this.formService.apply(`question.defaultIndices.${index}`, null);
        });
      },
      saveChoices: async () => {
        const name = await openPopup(POPUP_RENDER_KEYS.TEXT_FIELD, {
          title: 'Save Choice Template',
          label: 'Choice Template Title',
          confirmText: 'Done',
          cancelText: 'Cancel',
        });

        if (name) {
          this.formService.apply('question.answerSetId', uuid());
          this.formService.apply('answerSet.name', name);

          this.state.answerSet.answers.forEach((_, index) => {
            this.formService.apply(`answerSet.answers.${index}.id`, null);
          });
        }
      },
      openChoiceSetup: async () => {
        const res = await openPopup(
          POPUP_RENDER_KEYS.MACRO_REGION_CHOICE_SETUP,
          {
            regionLabels: deepCopy(this.state.answerSet.answers),
          },
        );

        if (!res || equal(res, this.state.answerSet.answers)) {
          return;
        }

        if (this.state.question.answerSetId === this.defaultBodyAnswerSetId) {
          this.formService.apply('question.answerSetId', uuid());
        }
        this.state.answerSet.answers.forEach((_, index) => {
          this.formService.apply(
            `answerSet.answers.${index}.value`,
            res[index].value,
          );
        });
      },

      openLinkChoiceTemplatePopup: async () => {
        const filteredAnswerSet = formatAnswerSet(
          this.answerSetDict,
          QUESTION_ANSWER_TOKEN.region,
        );

        const templates = this.macroSetId
          ? await getChoiceTemplates(
              this.macroSetId,
              QUESTION_ANSWER_TOKEN.region,
            )
          : [];

        const orderedTemplates = mergeUniqueAnswerSets(
          filteredAnswerSet,
          templates,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_LINK_CHOICE_TEMPLATE,
          {
            templates: orderedTemplates,
          },
        );

        if (!result) {
          return;
        }

        const { answerSets, selectedAnswerSetId } = result;

        this.__setAnswerSet(answerSets, selectedAnswerSetId);

        this.onChangeAnswerSets(answerSets);
      },

      unlinkChoiceTemplate: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          getUnlinkPopupConfig(this.state.answerSet.name),
        );

        if (!result) {
          return;
        }

        this.onChangeAnswerSets({
          [this.state.question.answerSetId]: {
            ...deepCopy(this.state.answerSet),
            type: QUESTION_ANSWER_TOKEN.region,
          },
        });

        this.formService.apply('answerSet.name', '');
        this.formService.apply('answerSet.deleted', false);
        this.formService.apply('question.answerSetId', uuid());

        this.state.answerSet.answers.forEach((_, index) => {
          this.formService.apply(`answerSet.answers.${index}.id`, null);
        });
      },

      editChoiceTemplate: async () => {
        const { name, answers } = this.state.answerSet;
        const { answerSetId } = this.state.question;

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_EDIT_CHOICE_TEMPLATE,
          {
            name,
            answers: deepCopy(answers),
            type: QUESTION_ANSWER_TOKEN.region,
          },
        );

        if (!result) {
          return;
        }

        this.__setAnswerSet({ [answerSetId]: result }, answerSetId);
      },
    };
  }

  __setAnswerSet(answerSets, selectedAnswerSetId) {
    if (!selectedAnswerSetId) {
      return;
    }

    const selectedAnswerSet = answerSets[selectedAnswerSetId];

    this.formService.apply('answerSet.name', selectedAnswerSet.name);

    selectedAnswerSet.answers.forEach((a, index) => {
      this.formService.apply(`answerSet.answers.${index}.value`, a.value);
      this.formService.apply(`answerSet.answers.${index}.id`, a.id);
    });

    this.formService.apply('question.answerSetId', selectedAnswerSetId);
  }

  __renderChoiceTemplateSection() {
    return this.state.answerSet.name
      ? html`
          <div class="linked-container">
            <neb-text id="${ELEMENTS.choiceTemplateName.id}" bold>
              ${getLinkedChoiceTemplateName(this.state.answerSet)}</neb-text
            >

            <div class="container-button">
              <neb-button
                id="${ELEMENTS.unlinkChoiceTemplateButton.id}"
                label="Unlink Choice Template"
                .role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.handlers.unlinkChoiceTemplate}"
              ></neb-button>

              <neb-button
                id="${ELEMENTS.editChoiceTemplateButton.id}"
                label="Edit Choice Template"
                .role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.handlers.editChoiceTemplate}"
              ></neb-button>
            </div>
          </div>
        `
      : html`
          <div class="container-button">
            <neb-button
              id="${ELEMENTS.linkChoiceTemplateButton.id}"
              label="Link Choice Template"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.handlers.openLinkChoiceTemplatePopup}"
            ></neb-button>

            <neb-button
              id="${ELEMENTS.saveChoiceTemplateButton.id}"
              label="Save Choice Template"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.handlers.saveChoices}"
            ></neb-button>
          </div>
        `;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.questionField.id}"
        class="field-question"
        label="Question"
        helper="Required"
        name="question.text"
        maxLength="255"
        .value="${this.state.question.text}"
        .error="${this.errors.question.text}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      ${this.__renderChoiceTemplateSection()}

      <neb-macro-region
        id="${ELEMENTS.macroRegion.id}"
        .selectedIndices="${this.state.question.defaultIndices}"
        .onClick="${this.handlers.clickRegion}"
        .onClear="${this.handlers.clear}"
      ></neb-macro-region>
      <div class="button">
        <neb-button
          id="${ELEMENTS.choiceSetupButton.id}"
          label="Choice Setup"
          .disabled="${!!this.state.answerSet.name}"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.openChoiceSetup}"
        ></neb-button>
      </div>
    `;
  }
}

window.customElements.define('neb-form-macro-region', NebFormMacroRegion);
