import '../../../../../src/components/misc/neb-icon';
import '../inputs/neb-select';
import '../neb-date-picker';
import '../../../../neb-material-design/src/components/neb-md-checkbox';

import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';

export const DATE_SELECTION_RULES = Object.freeze({
  DATE1_NOT_FUTURE_DATE: 'date1-not-future-date',
  DATE2_NOT_FUTURE_DATE: 'date2-not-future-date',
  DATE1_LESS_OR_EQUAL_DATE2: 'date1-less-or-equal-date2',
  DATE2_GREATER_OR_EQUAL_DATE1: 'date2-greater-or-equal-date1',
});

const DATES = Object.freeze({
  DATE1: 'date1',
  DATE2: 'date2',
});
export const ELEMENTS = {
  date1Field: {
    id: 'textfield-date1',
  },
  date2Field: {
    id: 'textfield-date2',
  },
  enableCheckBox: {
    id: 'checkbox-enable',
  },
  container: {
    id: 'container',
  },
};

class NebTwoDateItem extends LitElement {
  static get properties() {
    return {
      name: {
        type: String,
      },
      model: {
        type: Object,
      },
      errors: {
        type: Object,
      },
      labels: {
        type: Object,
      },
      showCheckBox: {
        reflect: true,
        type: Boolean,
      },
      dateSelectionRules: {
        type: Object,
      },
      momentFlag: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.model = {
      date1: null,
      date2: null,
      enabled: false,
    };

    this.errors = {
      date1: '',
      date2: '',
      enabled: '',
    };

    this.labels = {
      date1: '',
      date2: '',
      checkbox: '',
      helperText1: '',
      helperText2: '',
    };

    this.showCheckBox = true;
    this.dateSelectionRules = [];
    this.__today = new Date();

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: ({ name, value }) => {
        this.__changeModelValue(name, value);

        if (name === DATES.DATE1) {
          this.__updateDependencyDate2 = value ? value.toISOString() : value;
        }

        if (name === DATES.DATE2) {
          this.__updateDependencyDate1 = value ? value.toISOString() : value;
        }
      },
      checkbox: ({ name, value }) => {
        this.__changeModelValue(name, value);

        this.__changeModelValue(DATES.DATE1, value ? this.model.date1 : null);

        this.__changeModelValue(DATES.DATE2, value ? this.model.date2 : null);

        this.__updateDependencyDate1 = null;
        this.__updateDependencyDate2 = null;
      },
      selectDate1: value =>
        this.dateSelectionRules.every(rule =>
          this.__validateSelectionRuleDate1(rule, value),
        ),
      selectDate2: value =>
        this.dateSelectionRules.every(rule =>
          this.__validateSelectionRuleDate2(rule, value),
        ),
    };
  }

  __changeModelValue(name, value) {
    this.onChange({
      name: `${this.name}.${name}`,
      value,
    });
  }

  __validateSelectionRuleDate1(rule, value) {
    switch (rule) {
      case DATE_SELECTION_RULES.DATE1_NOT_FUTURE_DATE:
        return this.__getDate(value) <= this.__today;

      case DATE_SELECTION_RULES.DATE1_LESS_OR_EQUAL_DATE2:
        return (
          !this.model.date2 ||
          this.__getDate(value) <= this.__getDate(this.model.date2)
        );

      default:
        return true;
    }
  }

  __validateSelectionRuleDate2(rule, value) {
    switch (rule) {
      case DATE_SELECTION_RULES.DATE2_NOT_FUTURE_DATE:
        return this.__getDate(value) <= this.__today;

      case DATE_SELECTION_RULES.DATE2_GREATER_OR_EQUAL_DATE1:
        return (
          !this.model.date1 ||
          this.__getDate(value) >= parseDate(this.model.date1)
        );

      default:
        return true;
    }
  }

  __getDate(date) {
    return parseDate(date);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__updateDependencyDate2 =
        this.model.date1 instanceof moment
          ? this.model.date1.toISOString()
          : this.model.date1;

      this.__updateDependencyDate1 =
        this.model.date2 instanceof moment
          ? this.model.date2.toISOString()
          : this.model.date2;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          margin: 5px 0;
        }

        .container {
          width: 100%;
          height: 100%;
        }

        .grid {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 5px;
          grid-column-gap: ${CSS_SPACING};
        }

        :host([showCheckBox]) .grid {
          grid-template-rows: auto 72px;
        }

        .span {
          grid-column: 1 / span 2;
        }

        .checkbox {
          position: relative;
          left: -10px;
        }

        .picker {
          width: auto;
          flex: 1 0 0;
        }
      `,
    ];
  }

  __renderEnableCheckbox() {
    return this.showCheckBox
      ? html`
          <neb-md-checkbox
            id="${ELEMENTS.enableCheckBox.id}"
            name="enabled"
            class="span checkbox"
            .label="${this.labels.checkbox}"
            ?checked="${this.model.enabled}"
            .onChange="${this.__handlers.checkbox}"
          ></neb-md-checkbox>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="grid">
          ${this.__renderEnableCheckbox()}

          <neb-date-picker
            id="${ELEMENTS.date1Field.id}"
            name="date1"
            class="picker"
            placeholder="Select Date"
            helperText="${this.labels.helperText1}"
            momentFlag
            ?invalid="${!!this.errors.date1}"
            .label="${this.labels.date1}"
            .selectedDate="${this.__getDate(this.model.date1)}"
            .disabled="${!this.model.enabled}"
            .onChange="${this.__handlers.change}"
            .isDateSelectable="${this.__handlers.selectDate1}"
            .invalidText="${this.errors.date1}"
            .updateDependency="${this.__updateDependencyDate1}"
          ></neb-date-picker>

          <neb-date-picker
            id="${ELEMENTS.date2Field.id}"
            name="date2"
            class="picker"
            momentFlag
            placeholder="Select Date"
            helperText="${this.labels.helperText2}"
            ?invalid="${!!this.errors.date2}"
            .label="${this.labels.date2}"
            .selectedDate="${this.__getDate(this.model.date2)}"
            .disabled="${!this.model.enabled}"
            .onChange="${this.__handlers.change}"
            .isDateSelectable="${this.__handlers.selectDate2}"
            .invalidText="${this.errors.date2}"
            .updateDependency="${this.__updateDependencyDate2}"
          ></neb-date-picker>
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-two-date-item', NebTwoDateItem);
