import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../controls/inputs/neb-checkbox';
import { html, css } from 'lit';

import {
  CSS_BORDER_GREY_2,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  SCHEDULED_PAYMENT_TYPE,
  SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP,
} from '../../../packages/neb-utils/enums';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  scheduled: { id: 'scheduled-date' },
  selectPaymentType: { id: 'select-payment-type' },
  hideDismissedCheckbox: { id: 'hide-dismissed-checkbox' },
};

export const FILTERS = Object.freeze({
  scheduled: 'scheduled',
  paymentType: 'paymentType',
});

class FailedPaymentsFilters extends NebFilters {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }
        .content {
          grid-template-columns: repeat(3, 1fr);
        }
        .checkbox {
          padding: 0 ${CSS_SPACING};
          display: inline-grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: auto auto;
        }
      `,
    ];
  }

  static get properties() {
    return {
      paymentTypes: Array,
      hideDismissedAlerts: Boolean,
    };
  }

  static createModel() {
    return {
      scheduled: NebDateRange.createModel(),
      paymentType: SCHEDULED_PAYMENT_TYPE.EMPTY,
      hideDismissedAlerts: true,
    };
  }

  createSelectors() {
    return {
      children: {
        scheduled: NebDateRange.createSelectors(),
      },
    };
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,

      changeScheduledDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();

        this.onApply({
          ...model,
          scheduled: {
            from: model.scheduled.from
              ? parseDate(model.scheduled.from._i).startOf('day')
              : null,
            to: model.scheduled.to
              ? parseDate(model.scheduled.to._i).startOf('day')
              : null,
          },
        });
      },
    };
  }

  renderCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.hideDismissedCheckbox.id}"
        name="hideDismissedAlerts"
        class="checkbox"
        label="Hide Dismissed Alerts"
        ?checked="${this.state.hideDismissedAlerts}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  renderContent() {
    const scheduled = {
      from: this.state.scheduled.from
        ? parseDate(this.state.scheduled.from)
        : null,
      to: this.state.scheduled.to ? parseDate(this.state.scheduled.to) : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.selectPaymentType.id}"
        name="${FILTERS.paymentType}"
        label="Payment Type"
        .items="${Object.values(SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP)}"
        .value="${this.state.paymentType}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.scheduled.id}"
        name="${FILTERS.scheduled}"
        label="Scheduled Date"
        .onChange="${this.handlers.changeScheduledDate}"
        .model="${scheduled}"
      ></neb-range-date>

      ${this.renderCheckbox()}
    `;
  }
}
window.customElements.define(
  'neb-filters-failed-payments',
  FailedPaymentsFilters,
);
