import { html, css, LitElement } from 'lit';

import '../views/neb-encounter-info-view';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-summary-controller';

import { getPatientGuarantors } from '../../../packages/neb-api-client/src/patient-guarantor-api-client';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../packages/neb-styles/neb-variables';
import { formatAuthorizationLabel } from '../../../packages/neb-utils/patientAuthorization';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
  encounterInfoView: {
    id: 'encounter-info-view',
  },
};

export class NebEncounterInfoController extends LitElement {
  static get properties() {
    return {
      model: Object,
      layout: {
        type: String,
        reflect: true,
      },

      __viewModel: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .content {
          display: flex;
          height: 100%;
          width: 100%;
          max-width: 70rem;
        }

        .content-left {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding: ${CSS_SPACING};
        }

        .content-right {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.model = {
      encounterId: null,
      patientId: null,
    };

    this.__viewModel = null;

    this.onDismiss = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onDismiss(),
      openCase: async () => {
        const guarantors = await getPatientGuarantors(this.model.patientId);
        await openOverlay(OVERLAY_KEYS.CASE, {
          item: this.model.case,
          context: {
            patientId: this.model.patientId,
            guarantors,
            onGuarantorChange: () => {},
            onAuthorizationChange: () => {},
          },
        });
      },
      openAuthorization: async () => {
        await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          patientId: this.model.patientId,
          patientCaseId: this.model.case.id,
        });
      },
    };
  }

  __getViewModel() {
    this.__viewModel = {
      case: this.model.case ? this.model.case.label : null,
      authorization:
        this.model.case && this.model.case.patientAuthorization
          ? formatAuthorizationLabel(this.model.case.patientAuthorization)
          : null,
    };
  }

  willUpdate(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__getViewModel();
    }
  }

  __renderPatientSummary() {
    return this.model.patientId
      ? html`
          <neb-patient-summary-controller
            id="${ELEMENTS.patientSummary.id}"
            class="patient-summary"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
          >
          </neb-patient-summary-controller>
        `
      : html``;
  }

  __renderEncounterInfoView() {
    return html`
      <neb-encounter-info-view
        id="${ELEMENTS.encounterInfoView.id}"
        .layout="${this.layout}"
        .model="${this.__viewModel}"
        .onClose="${this.__handlers.close}"
        .onOpenCase="${this.__handlers.openCase}"
        .onOpenAuthorization="${this.__handlers.openAuthorization}"
      >
      </neb-encounter-info-view>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div class="content-left">${this.__renderPatientSummary()}</div>
          <div class="content-right">${this.__renderEncounterInfoView()}</div>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-encounter-info-controller',
  NebEncounterInfoController,
);
