import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { mapCategoryModelToRaw } from '../utils/hot-buttons';

export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});

export const getCategories = async (
  type,
  providerId,
  hideInactive = false,
  optOutLoadingIndicator = true,
) => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    queryParams: { type, hideInactive },
    path: `providers/${providerId}/hot-buttons`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const createCategory = async (providerId, body) => {
  const res = await billingApiClient.makeRequest({
    method: Method.POST,
    path: `providers/${providerId}/hot-buttons`,
    body: JSON.stringify(mapCategoryModelToRaw(body)),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });

  return res;
};

export const updateCategory = async (categoryId, body) => {
  const res = await billingApiClient.makeRequest({
    method: Method.PUT,
    path: `hot-buttons/${categoryId}`,
    body: JSON.stringify(mapCategoryModelToRaw(body)),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });

  return res;
};

export const updateIndexOrder = (hotButtonCategoryIds, providerId) =>
  billingApiClient.makeRequest({
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `providers/${providerId}/hot-buttons/update-index-order`,
    body: JSON.stringify({ hotButtonCategoryIds }),
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const copyCategoriesFromProvider = async body => {
  const res = await billingApiClient.makeRequest({
    method: Method.POST,
    path: 'hot-buttons-copy',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });

  return res;
};
