import DeprecatedApiClient, {
  getTenantId,
  Method,
} from './utils/api-client-utils';

export const BASE_URL = 'settings';
export const billingApiClient = new DeprecatedApiClient({
  microservice: 'billing',
});

export const fetchOne = async optOutLoadingIndicator => {
  const tenantId = await getTenantId();

  return billingApiClient.makeRequest({
    path: BASE_URL,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    cacheKey: `${tenantId}-${BASE_URL}`,
  });
};

export const upsert = (model, optOutLoadingIndicator) =>
  billingApiClient.makeRequest({
    path: BASE_URL,
    method: Method.PUT,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });
