import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';

export const ELEMENTS = {
  canvas: {
    id: 'canvas',
  },
};

const clamp = (num, min, max) => {
  if (num <= min) {
    return min;
  }

  if (num >= max) {
    return max;
  }

  return num;
};

class NebProfilePhotoEditor extends LitElement {
  static get properties() {
    return {
      zoom: Number,
      rotate: Number,
      __panPos: Object,
      minZoom: Number,
      maxZoom: Number,
      step: Number,
      src: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__ctx = null;
    this.__image = new Image();

    this.__image.onload = () => {
      this.__elements.canvas.width = this.__elements.canvas.clientHeight;
      this.__elements.canvas.height = this.__elements.canvas.clientWidth;

      this.__redraw();
    };

    this.src = '';
    this.minZoom = 1;
    this.maxZoom = 2;
    this.step = 0.01;
    this.identity();

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      dragStart: e => {
        e.preventDefault();
        this.__startMousePos = this.getMousePosition(e);
        this.__dragging = true;
      },
      dragEnd: _e => {
        this.__mouseOffset = {
          x: 0,
          y: 0,
        };

        this.__startMousePos = {
          x: 0,
          y: 0,
        };

        this.__startPanPos = { ...this.__panPos };
        this.__dragging = false;

        const data = this.__elements.canvas.toDataURL('image/jpeg');

        this.onChange(data);
      },
      dragMove: e => {
        e.preventDefault();

        if (!this.__dragging) {
          return false;
        }

        const currentPos = this.getMousePosition(e);
        this.__mouseOffset = {
          x: currentPos.x - this.__startMousePos.x,
          y: currentPos.y - this.__startMousePos.y,
        };

        this.__boundPosition();

        this.__redraw();

        return true;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mouseup', this.__handlers.dragEnd);
    document.addEventListener('mousemove', this.__handlers.dragMove);
    document.addEventListener('touchend', this.__handlers.dragEnd);
    document.addEventListener('touchmove', this.__handlers.dragMove);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mouseup', this.__handlers.dragEnd);
    document.removeEventListener('mousemove', this.__handlers.dragMove);
    document.removeEventListener('touchend', this.__handlers.dragEnd);
    document.removeEventListener('touchmove', this.__handlers.dragMove);
  }

  identity() {
    this.__dragging = false;
    this.__panPos = {
      x: 0,
      y: 0,
    };

    this.__startPanPos = {
      x: 0,
      y: 0,
    };

    this.__mouseOffset = {
      x: 0,
      y: 0,
    };

    this.__startMousePos = {
      x: 0,
      y: 0,
    };

    this.zoom = this.minZoom;
    this.rotate = 0;

    if (this.__ctx) {
      this.__redraw();
    }
  }

  __boundPosition() {
    const resolution = this.getResolution();
    const scaledMouseOffset = this.getMouseOffsetForCanvas();
    const width = this.__image.width * this.getImageScale();
    const height = this.__image.height * this.getImageScale();
    const pos = {
      x: this.__startPanPos.x + scaledMouseOffset.x,
      y: this.__startPanPos.y + scaledMouseOffset.y,
    };
    const scaledImageDims = {
      width: width * this.zoom,
      height: height * this.zoom,
    };
    const aspectOverflow = {
      width: width > height ? width - resolution : 0,
      height: height > width ? height - resolution : 0,
    };
    const maxDiff = {
      width: (scaledImageDims.width - width + aspectOverflow.width) / 2,
      height: (scaledImageDims.height - height + aspectOverflow.height) / 2,
    };

    if (this.rotate % 2) {
      const widthMaxDiff = maxDiff.width;
      maxDiff.width = maxDiff.height;
      maxDiff.height = widthMaxDiff;
    }

    this.__panPos.x = clamp(pos.x, -maxDiff.width, maxDiff.width);
    this.__panPos.y = clamp(pos.y, -maxDiff.height, maxDiff.height);

    if (!this.__dragging) {
      this.__startPanPos = { ...this.__panPos };
    }
  }

  getResolution() {
    return this.__elements.canvas.clientHeight;
  }

  getImageScale() {
    const canvasSize = this.__elements.canvas.clientHeight;
    const imageSize =
      this.__image.width < this.__image.height
        ? this.__image.width
        : this.__image.height;
    return canvasSize / imageSize;
  }

  getMouseOffsetForCanvas() {
    const resolution = this.getResolution();

    const canvasSize = this.__elements.canvas.getBoundingClientRect().width;

    const factor = resolution / canvasSize;
    return {
      x: this.__mouseOffset.x * factor,
      y: this.__mouseOffset.y * factor,
    };
  }

  getMousePosition(e) {
    const rect = this.getBoundingClientRect();
    const isTouch = e.type.includes('touch');
    const clientPos = {
      x: isTouch ? e.touches[0].clientX : e.clientX,
      y: isTouch ? e.touches[0].clientY : e.clientY,
    };
    return {
      x: clientPos.x - rect.x,
      y: clientPos.y - rect.y,
    };
  }

  firstUpdated() {
    this.__elements = {
      canvas: this.shadowRoot.getElementById(ELEMENTS.canvas.id),
    };

    this.__ctx = this.__elements.canvas.getContext('2d');
  }

  update(changedProps) {
    if (changedProps.has('src')) {
      this.__image.src = this.src;
      this.zoom = this.minZoom;
      this.rotate = 0;
    }

    super.update(changedProps);
  }

  updated() {
    this.__boundPosition();

    this.__redraw();
  }

  getAngle() {
    return (-this.rotate * Math.PI) / 2;
  }

  __redraw() {
    const resolution = this.getResolution();
    const { width, height } = this.__image;
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const halfResolution = resolution / 2;
    const pos = this.__panPos;

    this.__ctx.save();

    this.__ctx.clearRect(0, 0, resolution, resolution);

    this.__ctx.translate(halfResolution + pos.x, halfResolution + pos.y);

    this.__ctx.scale(this.getImageScale(), this.getImageScale());

    this.__ctx.rotate(this.getAngle());

    this.__ctx.scale(this.zoom, this.zoom);

    this.__ctx.drawImage(this.__image, -halfWidth, -halfHeight, width, height);

    this.__ctx.restore();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .canvas {
          cursor: move;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <canvas
        id="${ELEMENTS.canvas.id}"
        class="canvas"
        @mousedown="${this.__handlers.dragStart}"
        @touchstart="${this.__handlers.dragStart}"
      ></canvas>
    `;
  }
}
customElements.define('neb-profile-photo-editor', NebProfilePhotoEditor);
