import equal from 'fast-deep-equal';
import { css, html, LitElement } from 'lit';

import { getProviderAppointments } from '../../../../packages/neb-api-client/src/appointment-api-client';
import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import * as patientApiClient from '../../../../packages/neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../../packages/neb-api-client/src/patient-image-api-client';
import { getAccount } from '../../../../packages/neb-api-client/src/permissions-api-client';
import { getActiveProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { fetchManyRooms } from '../../../../packages/neb-api-client/src/rooms-api-client';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { computeTime } from '../../../../packages/neb-input/nebFormatUtils';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-card';
import { openMatchPatientAccountPopup } from '../../../../packages/neb-lit-components/src/utils/patients';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLUE_BORDER,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  mapAppointment,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { roundUpTo5MinuteIncrement } from '../../../../packages/neb-utils/neb-cal-util';
import { SERVICE_TIME_FORMAT } from '../../../../packages/neb-utils/neb-charting-util';
import { BILL_TYPE_ITEMS } from '../../../../packages/neb-utils/neb-ledger-util';
import { printPdf } from '../../../../packages/neb-utils/neb-pdf-print-util';
import { getAppointmentTotals } from '../../../api-clients/appointments';
import * as apptBillingInfoApi from '../../../api-clients/appointments-billing-info';
import * as insuranceStatusApi from '../../../api-clients/insurance-status';
import { printSchedule } from '../../../api-clients/print-schedule';
import { openOverlayCheckInOut } from '../../../features/check-in-out/utils/open-overlay-check-in-out';
import { UpdateNotificationService } from '../../../services/update-notifications';
import { CSS_FONT_FAMILY, layoutStyles } from '../../../styles';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import {
  quickReturnToScheduled,
  quickCheckIn,
  quickCheckOut,
  quickCheckInUpdate,
} from '../../../utils/context/appointment-quick-action';
import { clearContexts } from '../../../utils/context/constants';
import { PollController } from '../../../utils/poll-controller';
import '../../lists/scheduling/neb-list-drag-drop';
import { SELECT_BY_TYPE } from '../../../utils/scheduling/appointments';
import { ANY } from '../../../utils/update-notifications';
import NebFormAppointment from '../../forms/appointments/neb-form-appointment';

export const ELEMENTS = {
  addAppointmentButton: { id: 'button-add-appointment' },
  printButton: { id: 'button-print' },
  date: { id: 'date' },
  locationsSelect: { id: 'select-locations' },
  providersSelect: { id: 'select-providers' },
  appointmentStatusSelect: { id: 'select-appointment-status' },
  activeList: { id: 'list-active' },
  checkedInList: { id: 'list-checked-in' },
  checkedOutList: { id: 'list-checked-out' },
  totalsCard: { id: 'card-totals' },
};

export const NO_PROVIDER_SCHEDULED = {
  label: 'No Provider Scheduled',
  data: {
    id: null,
  },
};

const DEFAULT_APPOINTMENT_TYPE = {
  name: '',
  color: '',
};

export const DRAG_ACTIONS = {
  QUICK_CHECK_IN: 'quickCheckIn',
  QUICK_CHECK_OUT: 'quickCheckOut',
  QUICK_RETURN_SCHEDULED: 'quickReturnToScheduled',
  OPEN_CHECK_IN_OUT_OVERLAY: 'checkInCheckOut',
  OPEN_APPT_OVERLAY: 'apptDetails',
};

const uniq = arr => [...new Set(arr)];

const SUCCESS_MESSAGE = 'Online Booking Account linked';

export function getSelectedLocationsKey(tenantId) {
  return `patient-flow-selected-locations:${tenantId}`;
}

export function getSelectedProvidersKey(tenantId) {
  return `patient-flow-selected-providers:${tenantId}`;
}

function mergeTotals(a, b) {
  return Object.entries(a).reduce((memo, [key, val]) => {
    memo[key] = val + b[key];

    return memo;
  }, {});
}

const DRAG_AREA_CONFIG = {
  activeList: () => [
    { label: 'Update Appointment', id: DRAG_ACTIONS.OPEN_APPT_OVERLAY },
    {
      label: 'Quick Return to Scheduled',
      id: DRAG_ACTIONS.QUICK_RETURN_SCHEDULED,
    },
  ],
  checkedInList: date =>
    parseDate(date).startOf('day').isAfter(parseDate().startOf('day'))
      ? []
      : [
          { label: 'Check-In', id: DRAG_ACTIONS.OPEN_CHECK_IN_OUT_OVERLAY },
          { label: 'Quick Check-In', id: DRAG_ACTIONS.QUICK_CHECK_IN },
        ],
  checkedOutList: () => [
    { label: 'Check-Out', id: DRAG_ACTIONS.OPEN_CHECK_IN_OUT_OVERLAY },
    { label: 'Quick Check-Out', id: DRAG_ACTIONS.QUICK_CHECK_OUT },
  ],
};

const genEmptyTotals = () => ({
  total: 0,
  newPatients: 0,
  scheduled: 0,
  walkIn: 0,
  kept: 0,
  rescheduled: 0,
  canceled: 0,
  noShow: 0,
  arrived: 0,
  checkedIn: 0,
  checkedOut: 0,
});

export const TOTALS = {
  total: 'Total Appointments',
  newPatients: 'New Patients',
  scheduled: 'Scheduled',
  walkIn: 'Walk-In',
  kept: 'Kept',
  rescheduled: 'Rescheduled',
  canceled: 'Canceled',
  noShow: 'No Show',
  arrived: 'Arrived',
  checkedIn: 'Checked In',
  checkedOut: 'Checked Out',
};

export const TOTALS_ORDER = {
  total: 0,
  newPatients: 1,
  scheduled: 2,
  walkIn: 3,
  kept: 4,
  rescheduled: 5,
  canceled: 6,
  noShow: 7,
  arrived: 8,
  checkedIn: 9,
  checkedOut: 10,
};

export const STATUS = {
  ACTIVE: { label: 'Scheduled', value: 'Active' },
  CHECKED_IN: { label: 'Checked In', value: 'Checked-In' },
  CHECKED_OUT: { label: 'Checked Out', value: 'Checked-Out' },
};

function createModelAppointments() {
  return {
    [STATUS.ACTIVE.value]: [],
    [STATUS.CHECKED_IN.value]: [],
    [STATUS.CHECKED_OUT.value]: [],
  };
}

function computeDurationDisplay(startDate, endDate) {
  const start = startDate.format('h:mm A');
  const end = endDate.format('h:mm A');
  const duration = computeTime(endDate.diff(startDate));
  const durationDisplay = `${start} - ${end} (${duration})`;

  return durationDisplay;
}

class NebPagePatientFlow extends LitElement {
  static get properties() {
    return {
      __date: String,
      __apptFlows: Object,
      __patients: Object,
      __potentialMatches: Object,
      __totals: Array,
      __appointmentTypes: Array,
      __providers: Array,
      __userLocations: Array,
      __selectedProviders: Array,
      __selectedApptStatus: Array,
      __selectedLocations: Array,
      __singleLocation: Object,
      __practiceNameOverride: String,
      __rooms: Array,
      __renderArea: Object,

      tenantId: String,
      layout: { type: String, reflect: true },
      reloadImages: { type: String, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .align-right {
          justify-self: end;
        }

        .container {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-auto-rows: min-content;
          align-items: center;
          background: ${CSS_COLOR_WHITE};
          padding-top: ${CSS_SPACING};
          width: 100%;
          height: 100%;
        }

        :host([layout='large']) .container {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: auto 1fr auto;
          padding: ${CSS_SPACING};
        }

        .date {
          min-width: 0px;
          color: ${CSS_COLOR_BLUE_BORDER};
          background: ${CSS_COLOR_WHITE};
          font: ${CSS_FONT_WEIGHT_BOLD} ${CSS_FONT_SIZE_HEADER}
            ${CSS_FONT_FAMILY};
        }

        .padding {
          padding: 0 ${CSS_SPACING};
        }

        .card {
          grid-column: span 3;
          border: ${CSS_BORDER_GREY_2};
          box-shadow: none;
          padding: 10px;
          height: auto;
          --text-size: 30px;
        }

        .print {
          width: max-content;
          padding: 0 ${CSS_SPACING};
        }

        :host([layout='large']) .print {
          justify-self: end;
          padding: 0;
        }

        .flex-between {
          display: flex;
          justify-content: space-between;
        }

        .flex-centered {
          display: flex;
          justify-content: center;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__date = parseDate();
    this.__apptFlows = createModelAppointments();
    this.__totals = [];
    this.__patients = {};
    this.__potentialMatches = {};
    this.__appointmentTypes = [];
    this.__providers = [];
    this.__userLocations = [];
    this.__selectedProviders = [];
    this.__selectedLocations = [];
    this.__singleLocation = {};
    this.__selectedApptStatus = STATUS.ACTIVE.label;
    this.__rooms = [];
    this.__renderArea = {
      active: false,
      checkin: false,
      checkout: false,
    };

    this.reloadImages = true;
    this.tenantId = '';
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ locations, userLocations }) => {
        this.__userLocations = userLocations
          .filter(item => item.active)
          .map(data => ({
            label: `${data.name}`,
            data,
          }));

        if (this.layout === 'large') {
          this.__selectedLocations =
            this.__getLocationsFromLocalStorageLarge() || [
              ...this.__userLocations,
            ];
        } else {
          this.__selectedLocations = this.__getLocationsFromLocalStorage() || [
            this.__userLocations[0],
          ];
        }

        [this.__singleLocation] = locations;
      },
    );

    this.__notificationService = new UpdateNotificationService({
      defaultQuery: {
        patient: ANY,
      },
      callback: () => {
        this.__patients = {};
        this.__potentialMatches = {};

        this.__loadApptFlows();
      },
    });

    this.__pollController = new PollController(this, async () => {
      await this.__reloadData(true);
    });
  }

  __initHandlers() {
    this.__handlers = {
      addWalkIn: async () => {
        const time = roundUpTo5MinuteIncrement(parseDate());
        const start = parseDate()
          .hours(time.hour)
          .minutes(time.min)
          .toISOString();

        await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
          ...NebFormAppointment.createModel(),
          start,
          type: SELECT_BY_TYPE.CUSTOM,
          walkIn: true,
        });

        this.__reloadData();
      },
      createAppointment: async () => {
        await openOverlay(
          OVERLAY_KEYS.APPOINTMENT_FORM,
          NebFormAppointment.createModel(),
        );

        this.__reloadData();
      },
      displayCard: appointment => {
        if (this.__patients[appointment.patient.id]) {
          this.__getProfilePicture(appointment);
        }
      },
      selectAppointment: async item => {
        const start = parseDate(item.start);

        const appointment = {
          ...item,
          id: item.appointmentId || item.id,
          start,
          details: {
            startDisplayDate: start.format('dddd, MMMM DD, YYYY'),
            durationDisplay: computeDurationDisplay(start, parseDate(item.end)),
            location: item.locationName,
          },
        };

        const patientId = appointment.patientId || appointment.patient.id;

        if (!patientId) {
          await this.__openPatientMatchPopup(appointment);

          return;
        }

        this.__executeDragAction(item.to, appointment, patientId);
      },
      selectDate: date => {
        this.__date = date;

        this.__reloadData();
      },
      selectProviders: e => {
        if (e.value && equal(e.value, this.__selectedProviders)) {
          return;
        }

        this.__selectedProviders = e.value;

        if (e.value && e.value.length) {
          localStorage.setItem(
            getSelectedProvidersKey(this.tenantId),
            JSON.stringify(e.value),
          );

          this.__reloadData();
        } else {
          this.__clearData();
        }
      },
      selectLocation: e => {
        if (
          this.__selectedLocations[0] &&
          this.__selectedLocations[0].data.id === e.value.data.id
        ) {
          return;
        }

        this.__selectedLocations = [e.value];

        if (e.value) {
          localStorage.setItem(
            getSelectedLocationsKey(this.tenantId),
            JSON.stringify([e.value]),
          );
        }

        this.__reloadData();
      },
      selectLocations: e => {
        if (e.value && equal(e.value, this.__selectedLocations)) {
          return;
        }

        this.__selectedLocations = e.value;

        if (e.value && e.value.length) {
          localStorage.setItem(
            getSelectedLocationsKey(this.tenantId),
            JSON.stringify(e.value),
          );

          this.__reloadData();
        } else {
          this.__clearData();
        }
      },
      selectApptStatus: e => {
        this.__selectedApptStatus = e.value;
      },
      updateShowArea: (val, status) => {
        if (status === STATUS.ACTIVE.value) {
          this.__renderArea = { ...this.__renderArea, checkin: val };
        }

        if (status === STATUS.CHECKED_IN.value) {
          this.__renderArea = {
            ...this.__renderArea,
            checkout: val,
            active: val,
          };
        }

        if (status === STATUS.CHECKED_OUT.value) {
          this.__renderArea = { ...this.__renderArea, active: val };
        }
      },
      print: async () => {
        const apptIds = [
          ...this.__apptFlows[STATUS.CHECKED_IN.value],
          ...this.__apptFlows[STATUS.CHECKED_OUT.value],
        ].map(a => a.appointmentId || a.id);

        const billingInfo = apptIds.length
          ? await apptBillingInfoApi.fetchMany(apptIds)
          : [];

        this.__printMultiLocation(billingInfo);
      },
      quickAction: () => {
        this.__loadAppointments();
        this.__loadTotals();
      },
      openPotentialMatch: appt => {
        this.__openPatientMatchPopup(appt);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__appointmentTypes = (await getAppointmentTypes()).data;

    this.__locationsService.connect();

    this.__notificationService.connect();

    await this.__loadTenantMeta();

    await this.__loadProviders();

    await this.__loadRooms();

    await this.__loadAppointments();

    await this.__loadTotals();
  }

  disconnectedCallback() {
    try {
      this.__locationsService.disconnect();
      this.__notificationService.disconnect();
    } catch (_) {
      // noop
    }

    super.disconnectedCallback();
  }

  // Patient Flow is user action driven
  // DO NOT add items to updated unless absolutely necessary
  updated(changedProps) {
    if (changedProps.has('layout') && this.layout !== 'large') {
      this.__selectedLocations = [
        this.__selectedLocations[0] || this.__userLocations[0],
      ];

      this.__reloadData();
    }
  }

  async __openPatientMatchPopup(appt) {
    try {
      if (this.__potentialMatches[appt.accountId]) {
        const result = await openMatchPatientAccountPopup(
          this.__potentialMatches[appt.accountId].cognitoAccount,
          this.__potentialMatches[appt.accountId].accountMatches,
          appt.id,
        );

        if (result && result.data && result.data[0].bookingAccountId) {
          this.__potentialMatches[appt.accountId] = null;

          store.dispatch(openSuccess(SUCCESS_MESSAGE));
        }
      }
    } catch (e) {
      // noop
    }

    this.__reloadData();
  }

  __getAppointmentType(appointment) {
    return (
      this.__appointmentTypes.find(
        type => type.id === appointment.appointmentTypeId,
      ) || DEFAULT_APPOINTMENT_TYPE
    );
  }

  __getAppointmentProvider(appointment) {
    return this.__selectedProviders.find(
      p => p.data.id === appointment.providerId,
    );
  }

  __getBillType(appt, billingInfo) {
    const data = billingInfo.find(b => {
      const id = appt.appointmentId || appt.id;
      return b.appointmentId === id;
    });

    return data
      ? BILL_TYPE_ITEMS.find(i => i.data.id === data.billType).label
      : '';
  }

  __getLocationsFromLocalStorage() {
    const storedLocations = JSON.parse(
      localStorage.getItem(getSelectedLocationsKey(this.tenantId)),
    );

    const validLocation =
      storedLocations &&
      storedLocations[0].data.id &&
      this.__userLocations.some(
        loc => loc.data.id === storedLocations[0].data.id,
      );

    return validLocation && storedLocations;
  }

  __getLocationsFromLocalStorageLarge() {
    const storedLocations = JSON.parse(
      localStorage.getItem(getSelectedLocationsKey(this.tenantId)),
    );

    const locations =
      storedLocations && storedLocations.length
        ? storedLocations.filter(stored =>
            this.__userLocations.some(
              current => current.data.id === stored.data.id,
            ),
          )
        : [];

    return locations.length ? locations : [...this.__userLocations];
  }

  __getPhoneNumber(patient) {
    return patient.phoneNumbers && patient.phoneNumbers.length
      ? `${patient.phoneNumbers[0].type}: ${patient.phoneNumbers[0].number}`
      : '';
  }

  __getProvidersFromLocalStorage() {
    const storedProviders = JSON.parse(
      localStorage.getItem(getSelectedProvidersKey(this.tenantId)),
    );

    const providers =
      storedProviders && storedProviders.length
        ? storedProviders.filter(stored =>
            this.__providers.some(
              current => current.data.id === stored.data.id,
            ),
          )
        : [];
    return providers.length ? providers : [...this.__providers];
  }

  async __quickCheckOut(appt, patientId) {
    const res = await quickCheckOut(appt.id);

    if (res.success) return;

    if (res.forceStandardCheckOut) {
      await openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
        appointment: {
          ...appt,
          patientId,
          details: {
            ...appt.details,
            appointmentTypeName: appt.appointmentType,
            providerDisplayName: appt.provider,
          },
        },
        patientId,
        mode: 'checkOut',
      });
    }
  }

  async __quickCheckin(appt, patientId) {
    const res = await quickCheckIn(appt);

    if (res.success) return;

    const patientPackages = res.patientPackages ? res.patientPackages : [];

    if (res.forceStandardCheckIn) {
      await openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
        appointment: {
          ...appt,
          patientId,
          details: {
            ...appt.details,
            appointmentTypeName: appt.appointmentType,
            providerDisplayName: appt.provider,
          },
        },
        patientId,
        mode: 'checkIn',
      });
    } else {
      await quickCheckInUpdate(appt, patientPackages);
    }
  }

  async __executeDragAction(actionTaken, appointment, patientId) {
    switch (actionTaken) {
      case DRAG_ACTIONS.OPEN_CHECK_IN_OUT_OVERLAY:
        await openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
          appointment: {
            ...appointment,
            patientId,
            details: {
              ...appointment.details,
              appointmentTypeName: appointment.appointmentType,
              providerDisplayName: appointment.provider,
            },
          },
          patientId,
          mode: appointment.status === 'Checked-In' ? 'checkOut' : 'checkIn',
        });

        break;
      case DRAG_ACTIONS.QUICK_CHECK_IN:
        await this.__quickCheckin(appointment, patientId);

        break;
      case DRAG_ACTIONS.QUICK_CHECK_OUT:
        await this.__quickCheckOut(appointment, patientId);

        break;
      case DRAG_ACTIONS.QUICK_RETURN_SCHEDULED:
        await quickReturnToScheduled(appointment.id);

        break;
      default:
        await openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
          appointmentId: appointment.id,
        });
    }

    this.__reloadData();
  }

  async __loadTenantMeta() {
    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    this.__practiceNameOverride =
      store.getState().practiceInformation.item.name;
  }

  async __loadPatients(ids) {
    const query = { includeBooking: true, includeImage: true };
    const patients = await patientApiClient.fetchSome(ids, query);

    this.__patients = {
      ...this.__patients,
      ...patients.reduce((acc, patient) => {
        acc[patient.id] = patient;

        if (patient.bookingAccountId) {
          acc[patient.bookingAccountId] = acc[patient.id];
        }

        return acc;
      }, {}),
    };
  }

  __getPatientImage(patient) {
    return patient.hasPhoto && !patient.photoSrc
      ? getPatientImage(patient.id, 'small', true)
      : patient.photoSrc;
  }

  async __getProfilePicture(appointment) {
    const { patient: appointmentPatient, status } = appointment;

    const patient = this.__patients[appointmentPatient.id];

    const photoSrc = await this.__getPatientImage(patient);

    this.__patients[patient.id].photoSrc = photoSrc;
    this.__patients = { ...this.__patients };

    const selectedIndex = this.__apptFlows[status].findIndex(
      appt => appt.id === appointment.id,
    );

    this.__apptFlows[status][selectedIndex].patient.photoSrc = photoSrc;
    this.__apptFlows[status] = [...this.__apptFlows[status]];
  }

  __formatPatient(appointment) {
    const patientId = appointment.patientId || appointment.accountId;

    const patient = this.__patients[patientId] || {
      name: { first: appointment.firstName, last: appointment.lastName },
    };

    return patient;
  }

  __formatApptForPdf(appointment, billingInfo) {
    const patient = this.__formatPatient(appointment);
    const start = parseDate(appointment.start);
    const end = parseDate(appointment.end);
    const duration = computeTime(end.valueOf() - start.valueOf());
    const provider = this.__getAppointmentProvider(appointment);
    const type = this.__getAppointmentType(appointment);

    return {
      patient: objToName(patient.name, {
        reverse: true,
        middleInitial: true,
      }),
      duration: duration.replace('Hour', 'hr').replace('Minutes', 'min'),
      phoneNumber: this.__getPhoneNumber(patient),
      provider: provider.label,
      appointmentType: type.name,
      billType: this.__getBillType(appointment, billingInfo),
      time: start.format(SERVICE_TIME_FORMAT),
    };
  }

  __formatApptForFlow(appointment, insuranceStatuses = []) {
    let realTimeEligibilityStatus = null;

    const patient = this.__formatPatient(appointment);
    const start = parseDate(appointment.start);
    const end = parseDate(appointment.end);
    const duration = computeTime(end.valueOf() - start.valueOf());
    const provider = this.__getAppointmentProvider(appointment);
    const type = this.__getAppointmentType(appointment);
    const location = this.__userLocations.find(
      l => l.data.id === appointment.locationId,
    );

    const resource = this.__rooms.find(
      item => item.id === appointment.resourceId,
    );
    const room = this.__rooms.find(item => item.id === appointment.roomId);

    if (this.__hasAddOnCTVerify) {
      ({ status: realTimeEligibilityStatus } = insuranceStatuses.find(
        s => s.appointmentId === appointment.id,
      ));
    }

    return {
      ...appointment,
      ...(appointment.encounter || { signed: false }),
      patient,
      duration,
      provider: provider.label,
      appointmentType: type.name,
      markerColor: type.color,
      formattedServiceTime: start.format(SERVICE_TIME_FORMAT),
      locationName: location ? location.data.name : '',
      realTimeEligibilityStatus: this.__hasAddOnCTVerify
        ? realTimeEligibilityStatus
        : null,
      resource: resource || null,
      room: room || null,
    };
  }

  async __loadProviders() {
    const providers = await getActiveProviderUsers();

    this.__providers = providers.map(data => ({
      label: `${data.lastName}, ${data.firstName}`,
      data,
    }));

    this.__providers = [NO_PROVIDER_SCHEDULED, ...this.__providers];

    this.__selectedProviders = this.__getProvidersFromLocalStorage();
  }

  async __loadRooms() {
    this.__rooms = await fetchManyRooms();
  }

  async __validatePatients(appointments) {
    const appointmentsMissingPatients = appointments.filter(appt => {
      if (appt.patientId) {
        return !this.__patients[appt.patientId];
      }

      if (appt.patient) {
        return !this.__patients[appt.patient.id];
      }

      if (appt.accountId) {
        return !(
          this.__patients[appt.accountId] ||
          this.__potentialMatches[appt.accountId]
        );
      }

      return true;
    });

    appointmentsMissingPatients.forEach(async appt => {
      if (appt.accountId) {
        if (
          !this.__patients[appt.accountId] &&
          !this.__potentialMatches[appt.accountId]
        ) {
          try {
            const cognitoAccount = await getAccount(appt.accountId);

            if (cognitoAccount) {
              const accountMatches =
                await patientApiClient.getPotentialMatches(cognitoAccount);

              this.__potentialMatches[appt.accountId] = {
                cognitoAccount,
                accountMatches,
              };
            } else {
              this.__potentialMatches[appt.accountId] = {
                cognitoAccount: null,
                accountMatches: [],
              };
            }
          } catch (e) {
            // noop
          }
        }
      }
    });

    if (appointmentsMissingPatients.length) {
      const patientIds = appointmentsMissingPatients
        .map(appt => appt.patientId || appt.accountId)
        .filter(item => item);

      await this.__loadPatients(uniq(patientIds));
    }
  }

  __clearData() {
    this.__appointments = [];
    this.__apptFlows = createModelAppointments();
    this.__totals = this.__formatTotals(genEmptyTotals());
  }

  async __reloadData(optOutLoadingIndicator = false) {
    if (this.__selectedLocations.length && this.__selectedProviders.length) {
      await Promise.all([
        this.__loadAppointments(optOutLoadingIndicator),
        this.__loadTotals(),
      ]);
    }
  }

  async __loadApptFlows() {
    let insuranceStatuses;

    await this.__validatePatients(this.__appointments);

    if (this.__hasAddOnCTVerify) {
      insuranceStatuses = await insuranceStatusApi.fetchMany(
        this.__appointments.map(a => mapAppointment(a)),
        true,
      );
    }

    this.__apptFlows = this.__appointments.reduce((acc, curr) => {
      const key = curr.status;
      const appt = this.__formatApptForFlow(curr, insuranceStatuses);
      return { ...acc, [key]: [...acc[key], appt] };
    }, createModelAppointments());
  }

  async __loadAppointments(optOutLoadingIndicator) {
    clearContexts();

    const startOfDay = parseDate(this.__date).startOf('day');
    const startBefore = parseDate(this.__date).endOf('day');
    const filterEnd = parseDate(this.__date).add(1, 'days').endOf('day');

    const res = await getProviderAppointments(
      {
        start: startOfDay.format(),
        startBefore: startBefore.format(),
        end: filterEnd.format(),
        expand: 'encounter,splits',
        sortParams: {
          key: 'start',
          dir: 'asc',
        },
      },
      optOutLoadingIndicator,
    );

    this.__appointments = res.data.filter(
      appt =>
        !appt.name &&
        Object.values(STATUS)
          .map(v => v.value)
          .includes(appt.status) &&
        this.__getAppointmentProvider(appt),
    );

    this.__appointments = this.__appointments.filter(
      appt =>
        this.__selectedLocations.length &&
        this.__selectedLocations.some(l => l.data.id === appt.locationId),
    );

    await this.__loadApptFlows();
  }

  __formatTotals(totals) {
    return Object.entries(totals)
      .sort(([keyA], [keyB]) => TOTALS_ORDER[keyA] - TOTALS_ORDER[keyB])
      .map(([key, value]) => ({
        value,
        description: TOTALS[key],
      }));
  }

  __filterTotals(totals) {
    const locationIds = this.__selectedLocations.map(({ data }) => data.id);

    return locationIds.reduce(
      (memo, locationId) =>
        mergeTotals(memo, totals[locationId] || genEmptyTotals()),
      genEmptyTotals(),
    );
  }

  async __loadTotals() {
    const locationIds = this.__selectedLocations.map(({ data }) => data.id);
    const providerIds = this.__selectedProviders.map(({ data }) => data.id);

    const totals = await getAppointmentTotals({
      date: this.__date.format('YYYY-MM-DD'),
      locationIds,
      providerIds,
    });

    this.__totals = this.__formatTotals(totals);
  }

  async __printSingleLocation(billingInfo) {
    const data = this.__appointments.map(a =>
      this.__formatApptForPdf(a, billingInfo),
    );

    const scheduleInfo = {
      header: {
        date: this.__date.format('dddd, MMMM DD, YYYY'),
        total: data.length,
        providers: this.__selectedProviders.map(p => p.label).join(';'),
      },
      data,
    };

    try {
      await printPdf(
        printSchedule(scheduleInfo, {
          ...this.__singleLocation,
          name: this.__practiceNameOverride,
        }),
      );
    } catch (e) {
      store.dispatch(
        openError('Cannot open PDF tab, please check your pop-up blocker'),
      );
    }
  }

  __printMultiLocation(billingInfo) {
    this.__selectedLocations.forEach(async loc => {
      const locationAppointments = this.__appointments
        .filter(item => item.locationId === loc.data.id)
        .map(item => this.__formatApptForPdf(item, billingInfo));

      try {
        await printPdf(
          printSchedule(
            {
              header: {
                date: this.__date.format('dddd, MMMM DD, YYYY'),
                total: locationAppointments.length,
                providers: this.__selectedProviders
                  .map(p => p.label)
                  .join('; '),
              },
              data: locationAppointments,
            },
            { ...loc.data, name: this.__practiceNameOverride },
          ),
        );
      } catch (e) {
        store.dispatch(
          openError('Cannot open PDF tab, please check your pop-up blocker'),
        );
      }
    });
  }

  __renderPrintButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.printButton.id}"
        class="print"
        label="Daily Schedule"
        leadingIcon="print"
        .disabled="${!this.__selectedProviders.length}"
        .onClick="${this.__handlers.print}"
      ></neb-button-action>
    `;
  }

  __renderLargeHeader() {
    return html`
      <neb-calendar-picker
        id="${ELEMENTS.date.id}"
        class="date"
        .selectedDate="${this.__date}"
        .onDateSelected="${this.__handlers.selectDate}"
        momentFlag
      >
      </neb-calendar-picker>

      <div class="flex-between">
        <neb-button-action
          id="${ELEMENTS.addAppointmentButton.id}"
          class="align-right"
          label="Add New Appointment"
          .onClick="${this.__handlers.createAppointment}"
        ></neb-button-action>
        ${this.__renderPrintButton()}
      </div>

      <div class="grid grid-2 grid-lean">
        ${html`
          <neb-select
            id="${ELEMENTS.locationsSelect.id}"
            placeholder="No Locations Selected"
            allLabel="Locations"
            .items="${this.__userLocations}"
            .value="${this.__selectedLocations}"
            .onChange="${this.__handlers.selectLocations}"
            multiSelect
          ></neb-select>
        `}

        <neb-select
          id="${ELEMENTS.providersSelect.id}"
          placeholder="No Providers Selected"
          allLabel="Providers"
          .items="${this.__providers}"
          .value="${this.__selectedProviders}"
          .onChange="${this.__handlers.selectProviders}"
          multiSelect
        ></neb-select>
      </div>
    `;
  }

  __renderSmallHeader() {
    return html`
      <div class="flex-centered">
        <neb-calendar-picker
          id="${ELEMENTS.date.id}"
          class="date"
          .selectedDate="${this.__date}"
          .onDateSelected="${this.__handlers.selectDate}"
          momentFlag
        >
        </neb-calendar-picker>
      </div>

      <div class="flex-between">
        <neb-button-action
          id="${ELEMENTS.addAppointmentButton.id}"
          class="padding"
          label="Add New Appointment"
          .onClick="${this.__handlers.createAppointment}"
        ></neb-button-action>

        ${this.__renderPrintButton()}
      </div>

      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        class="padding"
        placeholder="No Providers Selected"
        label="Providers"
        allLabel="Providers"
        .items="${this.__providers}"
        .value="${this.__selectedProviders}"
        .onChange="${this.__handlers.selectProviders}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.appointmentStatusSelect.id}"
        class="padding"
        label="Appointment Status"
        .items="${Object.values(STATUS).map(v => v.label)}"
        .value="${this.__selectedApptStatus}"
        .onChange="${this.__handlers.selectApptStatus}"
      ></neb-select>

      ${html`
        <neb-select
          id="${ELEMENTS.locationsSelect.id}"
          class="padding"
          placeholder="No Locations Selected"
          label="Locations"
          .items="${this.__userLocations}"
          .value="${this.__selectedLocations[0]}"
          .onChange="${this.__handlers.selectLocation}"
        ></neb-select>
      `}
    `;
  }

  __renderHeader() {
    return this.layout === 'large'
      ? this.__renderLargeHeader()
      : this.__renderSmallHeader();
  }

  __renderActiveList() {
    return this.layout === 'large' ||
      this.__selectedApptStatus === STATUS.ACTIVE.label
      ? html`
          <neb-list-drag-drop
            id="${ELEMENTS.activeList.id}"
            class="${this.layout === 'large' ? '' : 'padding'}"
            header="Today's Appointments"
            .layout="${this.layout}"
            .items="${this.__apptFlows[STATUS.ACTIVE.value]}"
            .onAddWalkIn="${this.__handlers.addWalkIn}"
            .onDisplayCard="${this.__handlers.displayCard}"
            .onDrop="${this.__handlers.selectAppointment}"
            .onSelectItem="${this.__handlers.selectAppointment}"
            .onQuickAction="${this.__handlers.quickAction}"
            .onShowArea="${this.__handlers.updateShowArea}"
            .showDragArea="${this.__renderArea.active}"
            .areaConfig="${DRAG_AREA_CONFIG.activeList()}"
            .onPotentialMatch="${this.__handlers.openPotentialMatch}"
            ?draggable="${this.layout === 'large'}"
            ?showFooter="${this.layout === 'large'}"
          ></neb-list-drag-drop>
        `
      : '';
  }

  __renderCheckedInList() {
    return this.layout === 'large' ||
      this.__selectedApptStatus === STATUS.CHECKED_IN.label
      ? html`
          <neb-list-drag-drop
            id="${ELEMENTS.checkedInList.id}"
            header="Patients Checked In"
            .layout="${this.layout}"
            .items="${this.__apptFlows[STATUS.CHECKED_IN.value]}"
            .onDisplayCard="${this.__handlers.displayCard}"
            .onDrop="${this.__handlers.selectAppointment}"
            .onSelectItem="${this.__handlers.selectAppointment}"
            .onQuickAction="${this.__handlers.quickAction}"
            .onShowArea="${this.__handlers.updateShowArea}"
            .showDragArea="${this.__renderArea.checkin}"
            .areaConfig="${DRAG_AREA_CONFIG.checkedInList(this.__date)}"
            .onPotentialMatch="${this.__handlers.openPotentialMatch}"
            ?draggable="${this.layout === 'large'}"
          ></neb-list-drag-drop>
        `
      : '';
  }

  __renderCheckedOutList() {
    return this.layout === 'large' ||
      this.__selectedApptStatus === STATUS.CHECKED_OUT.label
      ? html`
          <neb-list-drag-drop
            id="${ELEMENTS.checkedOutList.id}"
            header="Patients Seen Today"
            .layout="${this.layout}"
            .items="${this.__apptFlows[STATUS.CHECKED_OUT.value]}"
            .onDisplayCard="${this.__handlers.displayCard}"
            .onDrop="${this.__handlers.selectAppointment}"
            .onSelectItem="${this.__handlers.selectAppointment}"
            .onQuickAction="${this.__handlers.quickAction}"
            .onShowArea="${this.__handlers.updateShowArea}"
            .showDragArea="${this.__renderArea.checkout}"
            .areaConfig="${DRAG_AREA_CONFIG.checkedOutList()}"
            .onPotentialMatch="${this.__handlers.openPotentialMatch}"
            ?draggable="${this.layout === 'large'}"
          ></neb-list-drag-drop>
        `
      : '';
  }

  __renderTotals() {
    return this.layout === 'large'
      ? html`
          <neb-card
            id="${ELEMENTS.totalsCard.id}"
            class="card"
            .items="${this.__totals}"
            disableCardClick
          ></neb-card>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderHeader()} ${this.__renderActiveList()}
        ${this.__renderCheckedInList()} ${this.__renderCheckedOutList()}
        ${this.__renderTotals()}
      </div>
    `;
  }
}

customElements.define('neb-page-patient-flow', NebPagePatientFlow);
