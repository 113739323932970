import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonThisProvider: {
    id: 'button-this-provider',
    label: 'SELECTED',
  },
  buttonAllProviders: {
    id: 'button-all-providers',
    label: 'ALL',
  },
  buttonCancel: {
    id: 'button-cancel',
    label: 'CANCEL',
  },
  textMessage: {
    id: 'text-message',
    message:
      'Do you want to delete this blocked off time for the selected provider or all providers created within this time block?',
  },
};

class NebPopupDeleteBlockedOffTime extends NebPopup {
  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      thisProvider: () =>
        this.onClose({
          one: true,
        }),
      allProviders: () =>
        this.onClose({
          all: true,
        }),
      cancel: () =>
        this.onClose({
          cancel: true,
        }),
    };
  }

  modelChanged() {
    this.title = 'Delete Blocked Off Time';
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 420px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${ELEMENTS.textMessage.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonThisProvider.id}"
            class="button"
            .label="${ELEMENTS.buttonThisProvider.label}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.thisProvider}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonAllProviders.id}"
            class="button"
            .label="${ELEMENTS.buttonAllProviders.label}"
            .role="${this.layout === 'small'
              ? BUTTON_ROLE.OUTLINE
              : BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.allProviders}"
          ></neb-button>

          ${this.__renderCancelButton()}
        </div>
      </div>
    `;
  }

  __renderCancelButton() {
    return this.layout !== 'small'
      ? html`
          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            .label="${ELEMENTS.buttonCancel.label}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        `
      : '';
  }
}

customElements.define(
  'neb-popup-delete-blocked-off-time',
  NebPopupDeleteBlockedOffTime,
);
