import { displayBox24IJShaded } from '../../../neb-lit-components/src/components/claims/utils';
import {
  CROSS_WALK,
  formatServiceFacilityNPI,
  PAYMENT_RESPONSIBILITY_LEVEL_CODE,
  WORKERS_COMPENSATION_INDICATORS,
} from '../../../neb-utils/claims';
import { parseDate } from '../../../neb-utils/date-util';
import { objToName } from '../../../neb-utils/formatters';
import { INSURED_RELATIONSHIP } from '../../../neb-utils/patientInsurance';
import { map } from '../../../neb-utils/utils';

const formatAddress = (address, defaultValue = '') => ({
  ...address,
  address1: (address && address.address1) || defaultValue,
  address2: (address && address.address2) || defaultValue,
  city: (address && address.city) || defaultValue,
  state: (address && address.state) || defaultValue,
  zipCode: (address && address.zipCode) || defaultValue,
  phone: (address && address.phone) || defaultValue,
});

const dosageConversion = charge => charge.nationalDrugCodeDosage / 1000;

const formatDosage = charge => {
  if (charge.nationalDrugCodeUnitOfMeasurement === 'ME') {
    return `ML${dosageConversion(charge).toFixed(3)}`;
  }
  return `${
    charge.nationalDrugCodeUnitOfMeasurement
  }${charge.nationalDrugCodeDosage.toFixed(3)}`;
};

export function getClaimEditedFieldModel() {
  return {
    claimCodes: false,
    otherClaimQualifier: false,
    otherClaimId: false,
    dateOfCurrentIllness: false,
    dateOfCurrentIllnessQualifier: false,
    otherDate: false,
    otherDateQualifier: false,
    disabilityFromDate: false,
    disabilityToDate: false,
    referringProviderQualifier: false,
    referringProviderFirstName: false,
    referringProviderLastName: false,
    referringProviderMiddleName: false,
    referringProviderCredentials: false,
    referringProviderOtherIdQualifier: false,
    referringProviderOtherId: false,
    referringProviderNPI: false,
    additionalClaimInformation: false,
    isOutsideLab: false,
    outsideLabChargesAmount: false,
    resubmissionCode: false,
    originalReferenceNumber: false,
    authorizationNumber: false,
    federalTaxIdSource: false,
    federalTaxIdType: false,
    federalTaxIdNumber: false,
    acceptAssignment: false,
    paidAmount: false,
    serviceFacilityName: false,
    serviceFacilityAddressId: false,
    serviceFacilityAddressType: false,
    serviceFacilityNPI: false,
    serviceFacilityOtherId: false,
    serviceFacilityOtherIdQualifier: false,
    billingProviderId: false,
    billingProviderPracticeName: false,
    billingProviderAddressId: false,
    billingProviderNPI: false,
    billingProviderOtherIdQualifier: false,
    billingProviderOtherId: false,
    billingProviderFirstName: false,
    billingProviderLastName: false,
    billingProviderMiddleName: false,
    billingProviderSuffix: false,
    spinalManipulationInfoCode: false,
    spinalManipulationInfoDescription: false,
    spinalManipulationInfoDescription2: false,
  };
}

export const formatClaimNameField = (key, model) => {
  const {
    [`${key}FirstName`]: firstName,
    [`${key}LastName`]: lastName,
    [`${key}Suffix`]: suffix,
    [`${key}MiddleName`]: middleName,
  } = model;

  if (!firstName) return '';

  const last = suffix ? `${lastName} ${suffix}` : lastName;
  const name = [last, firstName];

  if (middleName) {
    name.push(middleName.charAt(0).toUpperCase());
  }

  return name.join(', ');
};

export const formatFL4 = model => {
  if (
    model.fl4MedicareOverride &&
    model.insuredRelationToPatient === INSURED_RELATIONSHIP.Self
  ) {
    return model.paymentResponsibilityLevelCode ===
      PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY
      ? ''
      : 'SAME';
  }

  if (
    model.insuredName &&
    model.claimFilingIndicator ===
      WORKERS_COMPENSATION_INDICATORS.WORKERS_COMPENSATION_HEALTH_CLAIM &&
    model.insuredRelationToPatient === INSURED_RELATIONSHIP.Employer
  ) {
    return model.insuredName;
  }

  return formatClaimNameField('insured', model);
};

export const formatToBilling = ({ claim }) =>
  map(claim, (keys, value) => {
    if (keys.includes('isOutsideLab')) {
      return Boolean(value);
    }

    if (keys.includes('claimAdditionalInfos')) {
      return String(value);
    }

    if (
      keys.some(key => key.includes('NPI')) &&
      !keys.includes('claimEditedField')
    ) {
      return value ? String(value) : null;
    }

    if (keys.includes('claimAuthorizations')) {
      if (keys.includes('authorizationNumber')) {
        return value || '';
      }

      if (keys.includes('order')) {
        return value ? Number(value) : 0;
      }
    }

    return value === '' ? null : value;
  });

export const formatClaimLine = item =>
  item.codeGroup
    ? `${item.codeGroup}${item.code} ${item.note}`
    : `${item.note}`;

export const formatClaimInformation = (
  additionalClaimInfos,
  defaultValue = '',
) =>
  additionalClaimInfos
    ? additionalClaimInfos
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(a => formatClaimLine(a))
        .join('   ')
    : defaultValue;

export const formatClaimInformationItems = (
  additionalClaimInfos,
  claimAdditionalInfos,
) => {
  if (claimAdditionalInfos) {
    return claimAdditionalInfos;
  }
  const results = additionalClaimInfos
    ? additionalClaimInfos.map(a => ({
        enabled: true,
        sortOrder: a.sortOrder,
        readOnly: true,
        code: a.code,
        codeGroup: a.codeGroup,
        description: a.description,
        note: a.note,
      }))
    : [];
  return results;
};

export const formatNdc = charge => {
  if (charge.ndc) {
    return `${charge.nationalDrugCodeQualifier}${
      charge.nationalDrugCode
    } ${formatDosage(charge)}`;
  }
  return '';
};

const formatClaimAuthorizations = claimAuthorizations =>
  claimAuthorizations.length
    ? claimAuthorizations.map(v => ({
        order: v.order || 0,
        authorizationNumber: v.authorizationNumber || '',
      }))
    : [{ order: 0, authorizationNumber: '' }];

export const formatToUI = (c, defaultValue = '') => ({
  ...c,
  insuredIdNumber: c.insuredIdNumber || defaultValue,
  patientFirstName: c.patientFirstName || defaultValue,
  patientLastName: c.patientLastName || defaultValue,
  patientMiddleName: c.patientMiddleName || defaultValue,
  patientDateOfBirth: c.patientDateOfBirth
    ? parseDate(c.patientDateOfBirth).format()
    : defaultValue,
  patientDateOfDeath: c.patientDateOfDeath
    ? parseDate(c.patientDateOfDeath).format()
    : defaultValue,
  patientSuffix: c.patientSuffix || defaultValue,
  insuredFirstName: c.insuredFirstName || defaultValue,
  insuredLastName: c.insuredLastName || defaultValue,
  insuredMiddleName: c.insuredMiddleName || defaultValue,
  insuredSuffix: c.insuredSuffix || defaultValue,
  insuredName: c.insuredName || defaultValue,
  otherInsuredFirstName: c.otherInsuredFirstName || defaultValue,
  otherInsuredLastName: c.otherInsuredLastName || defaultValue,
  otherInsuredMiddleName: c.otherInsuredMiddleName || defaultValue,
  otherInsuredSuffix: c.otherInsuredSuffix || defaultValue,
  otherInsuredGroupIdNumber: c.otherInsuredGroupIdNumber || defaultValue,
  otherInsurancePlanName: c.otherInsurancePlanName || defaultValue,
  autoAccidentState: c.autoAccidentState || defaultValue,
  insuredGroupIdNumber: c.insuredGroupIdNumber || defaultValue,
  otherClaimQualifier: c.otherClaimQualifier || defaultValue,
  otherClaimId: c.otherClaimId || defaultValue,
  insurancePlanName: c.insurancePlanName || defaultValue,
  dateOfCurrentIllnessQualifier:
    c.dateOfCurrentIllnessQualifier || defaultValue,
  otherDateQualifier: c.otherDateQualifier || defaultValue,
  referringProviderQualifier: c.referringProviderQualifier || defaultValue,
  referringProviderFirstName: c.referringProviderFirstName || defaultValue,
  referringProviderMiddleName: c.referringProviderMiddleName || defaultValue,
  referringProviderLastName: c.referringProviderLastName || defaultValue,
  referringProviderCredentials: c.referringProviderCredentials || defaultValue,
  federalTaxIdNumber: c.federalTaxIdNumber || defaultValue,
  medicalRecordNumber: c.medicalRecordNumber || defaultValue,
  serviceFacilityName: c.serviceFacilityName || defaultValue,
  serviceFacilityNPI: c.serviceFacilityNPI || defaultValue,
  billingProviderNPI: c.billingProviderNPI || defaultValue,
  billingProviderNPISource: c.billingProviderNPISource || defaultValue,
  billingProviderFirstName: c.billingProviderFirstName || defaultValue,
  billingProviderMiddleName: c.billingProviderMiddleName || defaultValue,
  billingProviderLastName: c.billingProviderLastName || defaultValue,
  billingProviderOtherIdQualifier:
    c.billingProviderOtherIdQualifier || defaultValue,
  billingProviderOtherId: c.billingProviderOtherId || defaultValue,
  patientAddress: formatAddress(c.patientAddress, defaultValue),
  insuredAddress: formatAddress(c.insuredAddress, defaultValue),
  serviceFacilityAddress: formatAddress(c.serviceFacilityAddress, defaultValue),
  billingProviderAddress: formatAddress(c.billingProviderAddress, defaultValue),
  claimCodes: c.claimCodes || defaultValue,
  referringProviderOtherIdQualifier:
    c.referringProviderOtherIdQualifier || defaultValue,
  referringProviderOtherId: c.referringProviderOtherId || defaultValue,
  referringProviderNPI: c.referringProviderNPI || defaultValue,
  additionalClaimInformation:
    c.additionalClaimInformation ||
    formatClaimInformation(c.additionalClaimInfos, defaultValue),
  claimAdditionalInfos: formatClaimInformationItems(
    c.additionalClaimInfos,
    c.claimAdditionalInfos,
  ),
  isOutsideLab: c.isOutsideLab || defaultValue,
  outsideLabChargesAmount: c.outsideLabChargesAmount || 0,
  resubmissionCode: c.resubmissionCode || defaultValue,
  originalReferenceNumber: c.originalReferenceNumber || defaultValue,
  claimAuthorizations: formatClaimAuthorizations(c.claimAuthorizations),
  federalTaxIdType: c.federalTaxIdType || defaultValue,
  acceptAssignment: c.acceptAssignment === undefined || c.acceptAssignment,
  patientSignature: c.patientSignature || defaultValue,
  insuredSignature: c.insuredSignature || defaultValue,
  dateGenerated: c.dateGenerated || defaultValue,
  serviceFacilityOtherId: c.serviceFacilityOtherId || defaultValue,
  serviceFacilityOtherIdQualifier:
    c.serviceFacilityOtherIdQualifier || defaultValue,
  insuredSex: c.insuredSex || defaultValue,
  claimCharges: c.claimCharges.map(cc => ({
    ...cc,
    isEmergency: !!cc.isEmergency,
    isEmergencyEdited: cc.isEmergencyEdited || false,
    isFamilyPlanning: !!cc.isFamilyPlanning,
    isFamilyPlanningEdited: cc.isFamilyPlanning || false,
    providerOtherIdQualifierEdited: cc.providerOtherIdQualifierEdited || false,
    providerOtherIdEdited: cc.providerOtherIdEdited || false,
    shaded24: formatNdc(cc),
  })),
  spinalManipulationInfoCode: c.spinalManipulationInfoCode || defaultValue,
  spinalManipulationInfoDescription:
    c.spinalManipulationInfoDescription || defaultValue,
  spinalManipulationInfoDescription2:
    c.spinalManipulationInfoDescription2 || defaultValue,
  fl29AmountPaidOverride: c.fl29AmountPaidOverride || false,
  serviceFacilityOverride: c.serviceFacilityOverride || false,
  payerAddressOverride: c.payerAddressOverride || false,
  hasNotes: c.hasNotes || false,
  claimEditedField: c.claimEditedField || getClaimEditedFieldModel(),
});

export const formatToPDF = (claim, isElectronic = false) => {
  const billingProviderOtherIdQualifier =
    claim.billingProviderOtherIdQualifier || '';
  const billingProviderOtherId = claim.billingProviderOtherId || '';

  const formattedClaim = formatToUI(claim, null);
  delete formattedClaim.claimEditedField;

  return {
    ...formattedClaim,
    billingProviderOtherId: billingProviderOtherId
      ? `${billingProviderOtherIdQualifier}${billingProviderOtherId}`
      : billingProviderOtherId,
    serviceFacilityOtherId: claim.serviceFacilityOtherId
      ? `${claim.serviceFacilityOtherIdQualifier}${
          claim.serviceFacilityOtherId
        }`
      : claim.serviceFacilityOtherId,
    claimFilingIndicator: CROSS_WALK.claimFilingIndicator(
      claim.claimFilingIndicator,
    ),
    internalClaimFilingIndicator: claim.claimFilingIndicator,
    patientRelationToInsured: CROSS_WALK.patientRelationToInsured(
      claim.insuredRelationToPatient,
    ),
    icdIndicator: '0',
    patientSignature: claim.patientSignature ? 'SOF' : '',
    insuredSignature: claim.insuredSignature ? 'SOF' : '',
    providerSignatureText: claim.providerSignatureImage ? null : 'SOF',
    serviceFacilityNPI: formatServiceFacilityNPI(claim),
    billingProviderNPI: String(claim.billingProviderNPI),
    referringProviderNPI: String(claim.referringProviderNPI),
    claimDiagnoses: claim.claimDiagnoses.map(cd => cd.code.replace(/\./g, '')),
    claimCharges: claim.claimCharges.map(cc => {
      const displayBox24IJ = displayBox24IJShaded({
        charge: cc,
        billingProviderOtherIdQualifier,
        billingProviderOtherId,
      });
      return {
        ...cc,
        providerNPI:
          !cc.providerNPI || cc.providerNPI === claim.billingProviderNPI
            ? ''
            : String(cc.providerNPI),
        providerOtherIdQualifier: displayBox24IJ
          ? cc.providerOtherIdQualifier
          : '',
        providerOtherId: displayBox24IJ ? cc.providerOtherId : '',
        shaded24: formatNdc(cc),
      };
    }),
    isThereAnotherHealthBenefitPlan: Boolean(
      claim.otherInsurancePlanName ||
        claim.otherInsuredGroupIdNumber ||
        claim.otherInsuredLastName,
    ),
    payerName: claim.insurancePayerName,
    insurancePayerName: undefined,
    payerAddress: claim.insurancePayerAddress,
    insurancePayerAddress: undefined,
    isOutsideLab: !!claim.isOutsideLab,
    outsideLabChargesAmount: claim.isOutsideLab
      ? claim.outsideLabChargesAmount
      : undefined,
    priorAuthorizationNumber: claim.claimAuthorizations.length
      ? claim.claimAuthorizations[0].authorizationNumber
      : null,

    dateOfCurrentIllnessQualifier:
      CROSS_WALK.claimFilingIndicator(claim.claimFilingIndicator) ===
        'Medicare' && !isElectronic
        ? undefined
        : claim.dateOfCurrentIllnessQualifier,

    paidAmount:
      claim.paymentResponsibilityLevelCode ===
        PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY &&
      claim.fl29AmountPaidOverride
        ? 0
        : claim.paidAmount,
  };
};

export const formatInvoicedClaims = claims => ({
  ...claims,
  data: claims.data.map(c => ({
    ...c,
    batchId: c.batchId ? c.batchId.toString() : '',
    billingProviderName: c.billingProviderFirstName
      ? objToName(
          {
            last: c.billingProviderLastName,
            first: c.billingProviderFirstName,
            middle: c.billingProviderMiddleName,
            suffix: c.billingProviderSuffix,
          },
          {
            reverse: true,
            middleInitial: true,
          },
        )
      : 'No Billing Provider',
    payer: `(${c.insurancePayerAlias}) ${c.insurancePayerName}`,
  })),
});
