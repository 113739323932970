import DeprecatedApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

const buildBaseUrl = claimStatusId =>
  `claim-status/${claimStatusId}/validation-errors`;

export function fetchMany(claimStatusId, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    cacheKey: claimStatusId,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: buildBaseUrl(claimStatusId),
  });
}

export const updateValidationErrors = (claimStatusId, body) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: buildBaseUrl(claimStatusId),
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
