import { html, css } from 'lit';

import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import '../../forms/ledger/neb-form-supplemental-information';

export const ELEMENTS = {
  form: { id: 'form' },
};

export const TEXT_TITLE = 'Edit Supplemental Information';

class NebPopupSupplementalInformation extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __formModel: Object,
    };
  }

  initState() {
    super.initState();

    this.title = TEXT_TITLE;
    this.__formModel = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      saveSupplementalInformation: e => this.onClose(e),
      cancel: () => this.onClose(false),
    };
  }

  modelChanged() {
    this.__formModel = {
      reportTypeCode: this.model.reportTypeCode,
      reportTransmissionCode: this.model.reportTransmissionCode,
      codeQualifier: this.model.codeQualifier,
      identificationNumber: this.model.identificationNumber,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
        }

        .content {
          width: 100%;
          height: 100%;
          min-height: unset;
          margin: 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-supplemental-information
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.saveSupplementalInformation}"
      >
      </neb-form-supplemental-information>
    `;
  }
}

customElements.define(
  'neb-popup-supplemental-information',
  NebPopupSupplementalInformation,
);
