import { v4 as uuid } from 'uuid';

export const OPEN_SUCCESS = 'OPEN_SUCCESS';
export const OPEN_ERROR = 'OPEN_ERROR';
export const OPEN_ALERT = 'OPEN_ALERT';
export const OPEN_INFO = 'OPEN_INFO';
export const OPEN_SUCCESS_DETAILED = 'OPEN_SUCCESS_DETAILED';
export const OPEN_ERROR_DETAILED = 'OPEN_ERROR_DETAILED';
export const OPEN_INFO_DETAILED = 'OPEN_INFO_DETAILED';
export const OPEN_WARNING = 'OPEN_WARNING';
export const CLOSE_BANNER = 'CLOSE_BANNER';
export const CLOSE_ALERT_BANNERS = 'CLOSE_ALERT_BANNERS';
export const MESSAGES_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  ALERT: 'alert',
  INFO: 'info',
  WARNING: 'warning',
};

const isDuplicateBanner = (banner, banners) =>
  banners.some(
    ({ patientId, messageType, message }) =>
      banner.patientId === patientId &&
      banner.messageType === messageType &&
      banner.message === message,
  );

function addToState(state, banner) {
  const newState = { ...state, items: state.items.slice() };

  if (!isDuplicateBanner(banner, state.items)) {
    banner.messageId = uuid();
    newState.items.push(banner);
  }

  return newState;
}

function handleSuccess(state, action) {
  return addToState(state, {
    delay: 5000,
    link: null,
    linkMessage: null,
    message: action.message,
    messageType: MESSAGES_TYPE.SUCCESS,
  });
}

function handleError(state, action) {
  return addToState(state, {
    delay: null,
    link: null,
    linkMessage: null,
    message: action.message,
    messageType: MESSAGES_TYPE.ERROR,
  });
}

function handleAlert(state, action) {
  return addToState(state, {
    delay: null,
    link: null,
    linkMessage: null,
    patientId: action.patientId,
    message: action.message,
    messageType: MESSAGES_TYPE.ALERT,
  });
}

function handleInfo(state, action) {
  return addToState(state, {
    delay: 5000,
    link: null,
    linkMessage: null,
    large: action.large,
    message: action.message,
    messageType: MESSAGES_TYPE.INFO,
  });
}

function handleDetailedSuccess(state, action) {
  return addToState(state, {
    delay: 10000,
    link: action.link,
    linkMessage: action.linkMessage,
    message: action.message,
    messageType: MESSAGES_TYPE.SUCCESS,
  });
}

function handleDetailedError(state, action) {
  return addToState(state, {
    delay: null,
    link: action.link,
    linkMessage: action.linkMessage,
    message: action.message,
    messageType: MESSAGES_TYPE.ERROR,
  });
}

function handleDetailedInfo(state, action) {
  return addToState(state, {
    delay: 5000,
    link: action.link,
    linkMessage: action.linkMessage,
    message: action.message,
    messageType: MESSAGES_TYPE.INFO,
  });
}

function handleWarning(state, action) {
  return addToState(state, {
    delay: 5000,
    link: null,
    linkMessage: null,
    message: action.message,
    messageType: MESSAGES_TYPE.WARNING,
    onBannerClick: action.onBannerClick,
  });
}

function handleCloseBanner(state, action) {
  let foundIndex = -1;

  for (let i = 0; i < state.items.length; i++) {
    if (state.items[i].messageId === action.messageId) {
      foundIndex = i;
      break;
    }
  }

  const newState = { ...state, items: state.items.slice() };

  if (foundIndex !== -1) {
    newState.items.splice(foundIndex, 1);
  }

  return newState;
}

function handleCloseAlertBanners(state) {
  const foundIndexes = [];

  for (let i = 0; i < state.items.length; i++) {
    if (state.items[i].messageType === MESSAGES_TYPE.ALERT) {
      foundIndexes.push(i);
    }
  }

  foundIndexes.reverse();
  const newState = { ...state, items: state.items.slice() };

  if (foundIndexes.length > 0) {
    foundIndexes.forEach(index => {
      newState.items.splice(index, 1);
    });
  }

  return newState;
}

export const bannerReducer = (
  state = {
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case OPEN_SUCCESS:
      return handleSuccess(state, action);

    case OPEN_ERROR:
      return handleError(state, action);

    case OPEN_ALERT:
      return handleAlert(state, action);

    case OPEN_INFO:
      return handleInfo(state, action);

    case OPEN_SUCCESS_DETAILED:
      return handleDetailedSuccess(state, action);

    case OPEN_ERROR_DETAILED:
      return handleDetailedError(state, action);

    case OPEN_INFO_DETAILED:
      return handleDetailedInfo(state, action);

    case OPEN_WARNING:
      return handleWarning(state, action);

    case CLOSE_BANNER:
      return handleCloseBanner(state, action);

    case CLOSE_ALERT_BANNERS:
      return handleCloseAlertBanners(state, action);

    default: {
      return state;
    }
  }
};

export const openSuccess = message => ({
  type: OPEN_SUCCESS,
  message,
});

export const openError = (message, exception = null) => {
  if (exception) {
    console.error(exception);
  }

  return {
    type: OPEN_ERROR,
    message,
  };
};

export const openAlert = (message, patientId) => ({
  type: OPEN_ALERT,
  message,
  patientId,
});

export const openInfo = (message, large = false) => ({
  type: OPEN_INFO,
  message,
  large,
});

export const openSuccessDetailed = (message, link, linkMessage) => {
  message += ': ';
  return {
    type: OPEN_SUCCESS_DETAILED,
    message,
    link,
    linkMessage,
  };
};

export const openErrorDetailed = (message, link, linkMessage) => {
  message += ': ';
  return {
    type: OPEN_ERROR_DETAILED,
    message,
    link,
    linkMessage,
  };
};

export const openInfoDetailed = (message, link, linkMessage) => {
  message += ': ';
  return {
    type: OPEN_INFO_DETAILED,
    message,
    link,
    linkMessage,
  };
};

export const openWarning = (message, onBannerClick) => ({
  type: OPEN_WARNING,
  message,
  onBannerClick,
});

export const closeBanner = messageId => ({
  type: CLOSE_BANNER,
  messageId,
});

export const closeAlertBanners = () => ({
  type: CLOSE_ALERT_BANNERS,
});
