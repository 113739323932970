/* eslint-disable complexity */
import '../../../neb-popup-header';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { getLowInventoryMessage } from '../../../../../../../src/api-clients/inventory';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../../../src/utils/locations/location-util';
import {
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR,
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS,
} from '../../../../../../../src/utils/user-message';
import {
  createEncounterHistory,
  startEncounterHistory,
} from '../../../../../../neb-api-client/src/encounters-api-client';
import * as encounterApi from '../../../../../../neb-api-client/src/encounters-api-client';
import { formatDetailedLineItems } from '../../../../../../neb-api-client/src/formatters/line-item-details';
import { getLedgerInvoiceItems } from '../../../../../../neb-api-client/src/invoice-api-client';
import { getInvoiceClaims } from '../../../../../../neb-api-client/src/ledger/invoices';
import {
  getLineItems,
  savePayerInfo,
  getLineItemDetails,
  saveLineItems,
} from '../../../../../../neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../../../neb-api-client/src/patient-api-client';
import { getPayerPlans } from '../../../../../../neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../../../../neb-api-client/src/practice-users-api-client';
import { getPracticeSettings } from '../../../../../../neb-api-client/src/services/practice-settings';
import {
  openError,
  openSuccess,
  openInfo,
} from '../../../../../../neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import {
  LINE_ITEM_TYPE,
  fetchPracticeUsers,
} from '../../../../../../neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../../neb-utils/neb-refresh';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import {
  EDIT_MODE,
  LedgerChargesFormV2,
} from '../../../forms/neb-form-ledger-charges-v2';
import Overlay from '../../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

const uniq = arr => [...new Set(arr)];

function pushSuccess(message) {
  store.dispatch(openSuccess(message));
}

function pushError(message, err) {
  console.error(err);

  store.dispatch(openError(message));
}

class NebOverlayLedgerViewSelectedChargesV2 extends Overlay {
  static get properties() {
    return {
      __lineItemIds: Array,
      __editMode: String,
      __inputModel: Object,
      __displayItems: Array,
      __detailItems: Array,
      __claims: Object,
      __locations: Array,
      __hasPopupCallback: Boolean,
      __chartingPermission: Boolean,
      __title: String,
      __invoiceHasNotes: Boolean,
      __multiCarePackageEnabled: Boolean,
      __hasRCMChangeSecondary: Boolean,

      __practiceUsers: Array,
      __providers: Array,
      __payerPlans: Array,
      __lineItems: Array,
      __lineItemDetails: Array,
      __settings: Object,
    };
  }

  constructor() {
    super();
    this.__initServices();
  }

  initState() {
    super.initState();

    this.__setEditMode(EDIT_MODE.DISABLED);
    this.__inputModel = LedgerChargesFormV2.createModel();
    this.__displayItems = [];
    this.__detailItems = [];
    this.__claims = {};
    this.__locations = [];
    this.__lineItemIds = [];
    this.__hasPopupCallback = true;
    this.__chartingPermission = false;
    this.__invoiceHasNotes = false;
    this.__carePackageWithInsuranceEnabled = false;
    this.__multiCarePackageEnabled = false;
    this.__hasRCMChangeSecondary = false;

    this.__practiceUsers = [];
    this.__providers = [];
    this.__payerPlans = [];
    this.__lineItems = [];
    this.__lineItemDetails = [];
    this.__settings = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addCharge: async () => {
        const [{ invoiceId, guarantorId }] = this.__displayItems;
        const encounterIds = this.getEncounterIds();
        const addedCharges = await openOverlay(
          OVERLAY_KEYS.PATIENT_ADD_CHARGE,
          {
            patientId: this.model.patient.id
              ? this.model.patient.id
              : this.patient.id,
            addToLedgerGrouping: true,
            charges: this.__displayItems,
            invoiceId,
            encounterIds,
            guarantorId,
            chartingPermission: this.__chartingPermission,
          },
        );

        if (addedCharges && addedCharges.length) {
          this.__fetch({
            fetchClaims: true,
            lineItemIds: [
              ...this.__displayItems.map(item => item.id),
              ...addedCharges,
            ],
            selectedIds: this.getSelectedIds(),
          });
        }
      },

      cancel: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) {
            this.__inputModel = { ...this.__inputModel };
            this.__setEditMode(EDIT_MODE.DISABLED);
          }
        } else {
          this.__setEditMode(EDIT_MODE.DISABLED);
        }
      },

      editCharges: () => {
        this.__setEditMode(EDIT_MODE.TABLE);
      },

      editPayerInfo: () => {
        this.__setEditMode(EDIT_MODE.HEADER);
      },
      changeEditMode: editMode => {
        this.__setEditMode(editMode);
      },
      refreshLineItems: async lineItemIds => {
        this.__lineItemIds = lineItemIds;
        this.lineItemIds = lineItemIds;
        this.model.lineItemIds = lineItemIds;

        await this.__fetch({
          fetchClaims: true,
          lineItemIds,
          selectedIds: this.model.selectedIds,
        });
      },
      refreshPayerInfo: async () => {
        await this.__fetch({
          fetchClaims: false,
          lineItemIds: this.__displayItems.map(item => item.id),
          selectedIds: this.getSelectedIds(),
        });
      },
      fetchClaims: async (refetchNeeded = false) => {
        const invoiceId = this.getInvoiceId();

        this.__claims = invoiceId
          ? await getInvoiceClaims(invoiceId, true)
          : { count: 0, data: [] };

        if (refetchNeeded) {
          await this.__fetch({
            fetchClaims: false,
            lineItemIds: this.__displayItems.map(item => item.id),
            selectedIds: this.getSelectedIds(),
          });
        }
      },
      save: async (
        model,
        updateSignedEncounters,
        previousCaseId,
        currentCaseId,
      ) => {
        let postedChargeIds = [];
        let postedChargeCodes = [];

        try {
          if (!equal(model, this.__inputModel)) {
            if (!equal(model.payerInfo, this.__inputModel.payerInfo)) {
              if (updateSignedEncounters) {
                const signedEncounterIds = this.getEncounterIds(true);

                if (signedEncounterIds.length) {
                  await Promise.all(
                    signedEncounterIds.map(id =>
                      startEncounterHistory(id, true, true),
                    ),
                  );

                  await this.__savePayerInfo(model.payerInfo[0]);

                  await Promise.all(
                    signedEncounterIds.map(id =>
                      createEncounterHistory(id, true, true),
                    ),
                  );
                }
              } else {
                await this.__savePayerInfo(model.payerInfo[0]);
              }

              const form = this.shadowRoot.getElementById(ELEMENTS.form.id);
              form.reload();
            } else if (!equal(model.items, this.__inputModel.items)) {
              ({ postedChargeIds, postedChargeCodes } = await saveLineItems(
                model.items,
              ));
            }

            this.__fetch({
              fetchClaims: true,
              selectedIds: this.getSelectedIds(),
            });

            this.__inputModel = model;
          }

          this.__setEditMode(EDIT_MODE.DISABLED);
          pushSuccess('Charges saved successfully');

          this.__syncEncounterDateOfOnsetWithCase(
            previousCaseId,
            currentCaseId,
          );

          await this.__lowInventoryMessage({
            postedChargeIds,
            postedChargeCodes,
          });

          sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);
        } catch (e) {
          this.__inputModel = { ...model };
          pushError('An error has occurred when saving charges', e);
        }
      },

      scroll: e => {
        const form = this.shadowRoot.getElementById(ELEMENTS.form.id);

        if (form) {
          form.setYOffset(e.currentTarget.scrollTop);
        }
      },

      toggleItem: e => {
        const index = Number(e.name.split('.')[0]);

        this.__displayItems[index].checked = e.value;
        this.__displayItems = [...this.__displayItems];
      },
      changeBillingNotes: result => {
        this.__invoiceHasNotes = result !== true;
      },
    };
  }

  get invoice() {
    const { invoiceId, invoiceNumber } = this.__displayItems[0];

    return {
      id: invoiceId,
      number: invoiceNumber,
    };
  }

  get patient() {
    return this.model.patient;
  }

  async __syncEncounterDateOfOnsetWithCase(previousCaseId, currentCaseId) {
    const encounterIds = this.getEncounterIds();

    if (
      !equal(previousCaseId, currentCaseId) &&
      currentCaseId &&
      encounterIds.length
    ) {
      try {
        const invoiceId = this.getInvoiceId();

        if (invoiceId) {
          await encounterApi.updateBulkEncounterCurrentIllnessDateWithCaseDOO({
            invoiceId: this.getInvoiceId(),
            patientCaseId: currentCaseId,
          });
        } else {
          const [encounterId] = encounterIds;
          await encounterApi.updateEncounterCurrentIllnessDateWithCaseDOO(
            encounterId,
            { patientCaseId: currentCaseId },
          );
        }

        await store.dispatch(
          openSuccess(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS),
        );
      } catch (e) {
        console.log('e :>> ', e);
        await store.dispatch(
          openError(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR),
        );
      }
    }
  }

  async __lowInventoryMessage(args) {
    const { postedChargeIds, postedChargeCodes } = args;

    const inventoryMessage = await getLowInventoryMessage({
      chargeIds: postedChargeIds,
      codes: postedChargeCodes,
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  }

  async __getPatientsHash(patientIds) {
    const uniqueIds = uniq(patientIds);

    const patientsList = await patientApiClient.fetchSome(
      uniqueIds,
      {},
      true,
      true,
    );

    return patientsList.reduce((memo, patient) => {
      memo[patient.id] = patient;
      return memo;
    }, {});
  }

  async __fetchLineItems(lineItemIds) {
    const [lineItems, lineItemDetails] = await Promise.all([
      getLineItems(lineItemIds),
      getLineItemDetails({}, { lineItemIds }, 3),
    ]);

    this.__lineItems = lineItems;
    this.__lineItemDetails = lineItemDetails;

    this.__invoiceHasNotes = lineItems[0].invoiceHasNotes;
    this.__multiCarePackageEnabled = lineItemDetails[0].multiCarePackage;
  }

  async __loadItems() {
    const [providers, { payerPlan: payerPlans }, practiceUsers, settings] =
      await Promise.all([
        getProviderUsers(true),
        getPayerPlans({ hideInactive: false }, undefined, true),
        fetchPracticeUsers(),
        getPracticeSettings(),
      ]);

    this.__providers = providers;
    this.__practiceUsers = practiceUsers;
    this.__payerPlans = payerPlans;
    this.__settings = settings;

    const { id } = store.getState().session.item;
    const currentUser = practiceUsers.find(user => user.data.id === id);
    this.__chartingPermission = currentUser.data.permissions.find(
      p => p.name === 'charting',
    ).access;

    this.__carePackageWithInsuranceEnabled = settings.carePackageWithInsurance;
  }

  async __fetch({
    fetchClaims = true,
    lineItemIds = this.__displayItems.map(({ id }) => id),
    selectedIds = [],
  } = {}) {
    await this.__fetchLineItems(lineItemIds);

    const patientIds = uniq(this.__lineItemDetails.map(li => li.patientId));

    const patients = await this.__getPatientsHash(patientIds);

    const formattedLineItems = formatDetailedLineItems(
      this.__lineItemDetails,
      this.__providers,
      this.__payerPlans,
      null,
      patients,
    );

    this.__detailItems = formattedLineItems.map(item => ({
      ...item,
      location: getLocationValue(
        this.__locations,
        item.encounterCharge.locationId,
        LOCATION_KEYS.NAME,
      ),
    }));

    this.__displayItems = this.__lineItems.map(li => ({
      ...li,
      ...(this.model.patient.name && {
        patientName: this.model.patient.name,
        patientMRN: this.model.patient.mrn,
      }),
      checked: selectedIds.includes(li.id),
    }));

    if (fetchClaims) {
      await this.handlers.fetchClaims();
    }

    this.__inputModel = {
      payerInfo: this.getPayerInfo(),
      items: this.getSelectedDetailItems(),
    };
  }

  async __savePayerInfo(model) {
    const patientId = this.model.patient.id;
    const invoiceId = this.getInvoiceId();
    const ledgerInvoiceItems = await getLedgerInvoiceItems(invoiceId);

    const lineItemIds = ledgerInvoiceItems.data.length
      ? ledgerInvoiceItems.data.map(item => item.id)
      : this.__displayItems.map(item => item.id);

    await savePayerInfo({
      patientId,
      invoiceId,
      lineItemIds,
      model,
      multiCarePackageEnabled: this.__multiCarePackageEnabled,
    });

    if (model.guarantorId !== this.__inputModel.payerInfo[0].guarantorId) {
      sendRefreshNotification([REFRESH_CHANGE_TYPE.GUARANTORS], patientId);
    }
  }

  __renderLocationsWarningPopup(invoice) {
    const selectedCharges = this.__displayItems
      .filter(item => item.checked)
      .map(item => item);

    const locationIds = selectedCharges.map(c => c.locationId);

    const hasDiffChargeLocations = locationIds.some(
      locationId => locationId !== locationIds[0],
    );

    if (hasDiffChargeLocations) {
      return openPopup(POPUP_RENDER_KEYS.LOCATIONS_WARNING, {
        invoice,
      });
    }

    return true;
  }

  getInvoiceId() {
    return this.__displayItems[0].invoiceId;
  }

  getSelectedItems() {
    return this.__displayItems.filter(item => item.checked);
  }

  getSelectedIds() {
    const selectedItems = this.getSelectedItems();
    return selectedItems.map(item => item.id);
  }

  getSelectedDetailItems() {
    const selectedIds = this.getSelectedIds();

    return this.__detailItems.filter(item => selectedIds.includes(item.id));
  }

  getPayerInfo() {
    const [item] = this.__displayItems;

    const allocations = this.__detailItems.flatMap(curr =>
      curr.lineItemDebits.flatMap(lid => lid.debit.allocations),
    );

    const datesOfService = this.__detailItems.map(
      ({ dateOfService }) => dateOfService,
    );

    const secondaryInsuranceIdsSet = new Set();
    this.__detailItems.forEach(lineItem => {
      lineItem.lineItemDebits.forEach(({ patientInsuranceId }) => {
        if (patientInsuranceId && patientInsuranceId !== item.primaryPlanId) {
          secondaryInsuranceIdsSet.add(patientInsuranceId);
        }
      });
    });

    const { patientAuthorizationId } = this.__detailItems[0];

    return [
      {
        patientAuthorizationId,
        billType: item.billType,
        payerId: item.primaryPayerId,
        caseId: item.patientCaseId,
        packageId: item.patientPackageId,
        insuranceId: item.primaryPlanId,
        guarantorId: item.guarantorId,
        invoiceId: item.invoiceId,
        secondaryInsuranceId: item.secondaryPlanId,
        allocations,
        datesOfService,
        secondaryInsuranceIds: Array.from(secondaryInsuranceIdsSet),
      },
    ];
  }

  getEncounterIds(signedOnly = false) {
    return Array.from(
      new Set(
        this.__detailItems.reduce((acc, curr) => {
          const isEncounterCharge =
            curr.type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE;

          if (signedOnly) {
            return isEncounterCharge && curr.encounterCharge.signed
              ? [...acc, curr.encounterCharge.encounterId]
              : acc;
          }

          return isEncounterCharge
            ? [...acc, curr.encounterCharge.encounterId]
            : acc;
        }, []),
      ),
    );
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    this.__hasRCMChangeSecondary = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
    );
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  get __editModeFn() {
    return {
      [EDIT_MODE.DISABLED]: () => {
        this.__editMode = EDIT_MODE.DISABLED;
        this.__title = 'Charges';
      },
      [EDIT_MODE.HEADER]: () => {
        this.__editMode = EDIT_MODE.HEADER;
        this.__title = 'Update Charges';
      },
      [EDIT_MODE.TABLE]: () => {
        this.__editMode = EDIT_MODE.TABLE;
        this.__title = 'Update Charges';
      },
    };
  }

  __setEditMode(editMode) {
    this.__editModeFn[editMode]();
  }

  firstUpdated(changedProps) {
    super.firstUpdated(changedProps);

    if (changedProps.has('model') && this.model.editTable) {
      this.__setEditMode(EDIT_MODE.TABLE);
    }
  }

  async update(changedProps) {
    if (changedProps.has('model')) {
      this.__lineItemIds = this.model.lineItemIds;

      await this.__loadItems();

      this.__fetch({
        fetchClaims: true,
        lineItemIds: this.__lineItemIds,
        selectedIds: this.model.selectedIds,
      });
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.__title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-ledger-charges-v2
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .patient="${this.model.patient}"
        .editMode="${this.__editMode}"
        .model="${this.__inputModel}"
        .lineItemIds="${this.__lineItemIds}"
        .displayItems="${this.__displayItems}"
        .detailItems="${this.__detailItems}"
        .claims="${this.__claims}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onChangeDirty="${this.handlers.dirty}"
        .onEditPayerInfo="${this.handlers.editPayerInfo}"
        .onToggleItem="${this.handlers.toggleItem}"
        .onScroll="${this.handlers.scroll}"
        .onChangeClaims="${this.handlers.fetchClaims}"
        .onDismiss="${this.handlers.dismiss}"
        .onUpdate="${this.handlers.refreshLineItems}"
        .onRefreshPayerInfo="${this.handlers.refreshPayerInfo}"
        .onChangeEditMode="${this.handlers.changeEditMode}"
        .onChangeBillingNotes="${this.handlers.changeBillingNotes}"
        .closeOnSave="${this.model.closeOnSave}"
        .invoiceHasNotes="${this.__invoiceHasNotes}"
        .carePackageWithInsuranceEnabled="${this
          .__carePackageWithInsuranceEnabled}"
        .multiCarePackageEnabled="${this.__multiCarePackageEnabled}"
        .hasRCMChangeSecondary="${this.__hasRCMChangeSecondary}"
        .payerPlans="${this.__payerPlans}"
        .practiceUsers="${this.____practiceUsers}"
      ></neb-form-ledger-charges-v2>
    `;
  }
}

customElements.define(
  'neb-overlay-ledger-view-selected-charges-v2',
  NebOverlayLedgerViewSelectedChargesV2,
);
