import { openPopup } from '@neb/popup';

import { getLowInventoryMessage } from '../../../../src/api-clients/inventory';
import { postUnpostedEncounterChargesToLedger } from '../../../neb-api-client/src/charting/encounter-charge';
import {
  printEncounterSummaries,
  updateEncounterCharges,
  updateEncounterDiagnoses,
} from '../../../neb-api-client/src/encounters-api-client';
import { getPracticeUser } from '../../../neb-api-client/src/permissions-api-client';
import { getEncounterCharges } from '../../../neb-api-client/src/services/encounter-charge';
import { getLineItemsToBeSaved } from '../../../neb-api-client/src/services/encounter-charge/save-charges';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import {
  deleteEncounter,
  reopenEncounter,
  updateEncounter,
} from '../../../neb-redux/actions/encounterActions';
import { store } from '../../../neb-redux/neb-redux-store';
import { navigate } from '../../../neb-route/neb-route-state';
import { printPdf } from '../../../neb-utils/neb-pdf-print-util';

import { openOverlay, OVERLAY_KEYS } from './overlay-constants';

export const BANNER_MESSAGE = {
  sign: {
    success: 'Encounter signed',
    error: 'An error occurred when signing encounter',
    postingError: 'An error occurred when posting charges to ledger',
  },
  reopen: {
    success: 'Encounter reopened',
    error: 'An error occurred when reopening encounter',
  },
  delete: {
    success: 'Encounter deleted successfully',
    error: 'Error when deleting encounter',
  },
};

export const MATCHING_PROVIDER_REQUIRED = {
  title: 'Unable to Sign Encounter',
  message: 'Only the Rendering Provider can sign this encounter.',
};

const formatCharge = charge => ({
  ...charge,
  posted: !!charge.postedToLedgerId,
  repost: !!charge.repost,
  modifiers: [
    charge.modifier_1,
    charge.modifier_2,
    charge.modifier_3,
    charge.modifier_4,
  ],
});

const formatCharges = charges => charges.map(charge => formatCharge(charge));

const __handleBanner = ({ data, action }) => {
  let updateError = true;
  let fetchError = true;

  if (data) {
    ({ updateError, fetchError } = data);
  }

  if (!updateError && !fetchError) {
    store.dispatch(openSuccess(action.success));
    return data;
  }
  store.dispatch(openError(action.error));
  return undefined;
};

const __postUnpostedEncounterChargesToLedger = async ({
  charges,
  encounterId,
  patientId,
}) => {
  if (charges.length) {
    const { postedChargeIds } = await postUnpostedEncounterChargesToLedger(
      encounterId,
      {
        patientId,
      },
    );

    const inventoryMessage = await getLowInventoryMessage({
      chargeIds: postedChargeIds,
      codes: [],
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  }
};

const __signEncounter = async ({
  encounterId,
  accepted,
  encounter,
  patientId,
  charges,
}) => {
  if (accepted) {
    try {
      await __postUnpostedEncounterChargesToLedger({
        encounterId,
        patientId,
        charges,
      });
    } catch (e) {
      console.log('e :>> ', e);
      store.dispatch(openError(BANNER_MESSAGE.sign.postingError));

      return undefined;
    }

    return __handleBanner({
      data: await store.dispatch(updateEncounter(encounterId, encounter)),
      action: BANNER_MESSAGE.sign,
    });
  }

  return undefined;
};

const __reopenEncounter = async encounterId =>
  __handleBanner({
    data: await store.dispatch(reopenEncounter(encounterId)),
    action: BANNER_MESSAGE.reopen,
  });

export const handleDeleteEncounter = async ({
  encounterId,
  charges = [],
  diagnoses = [],
  patientId,
}) => {
  const result = await openPopup(POPUP_RENDER_KEYS.DELETE_ENCOUNTER);

  if (result && result.confirm) {
    if (charges.length) {
      try {
        const formattedCharges = formatCharges(charges);
        const billingEncounterCharges = await getEncounterCharges(
          charges,
          encounterId,
        );

        const savedLineItems = getLineItemsToBeSaved(
          formattedCharges,
          billingEncounterCharges,
        );

        await updateEncounterCharges({
          charges: [],
          encounterId,
          savedLineItems,
          patientId,
        });
      } catch (e) {
        console.log('e :>> ', e);
        return store.dispatch(openError(BANNER_MESSAGE.delete.error));
      }
    }

    if (diagnoses.length) {
      try {
        await updateEncounterDiagnoses(encounterId, [], true);
      } catch (e) {
        console.log('e :>> ', e);
        return store.dispatch(openError(BANNER_MESSAGE.delete.error));
      }
    }

    return __handleBanner({
      data: await store.dispatch(deleteEncounter(encounterId)),
      action: BANNER_MESSAGE.delete,
    });
  }

  return undefined;
};

export const handleSignEncounter = async ({
  providerId,
  encounterId,
  patientId,
  charges,
}) => {
  const {
    session: {
      item: { id: signerId },
    },
  } = store.getState();

  if (providerId !== signerId) {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, MATCHING_PROVIDER_REQUIRED);
  }

  const practiceUser = await getPracticeUser(signerId);

  let accepted = (practiceUser && practiceUser.skipSignMessage) || false;

  if (!practiceUser.skipSignMessage) {
    const popUpSignature = await openPopup(POPUP_RENDER_KEYS.ENCOUNTER_SIGN, {
      signerId,
      practiceUser,
    });
    accepted = popUpSignature.accepted;
  }

  return __signEncounter({
    encounterId,
    accepted,
    encounter: {
      signed: true,
      signerId,
    },
    patientId,
    charges,
  });
};

export const navigateToCharting = encounterId => {
  store.dispatch(navigate(`#/charting/${encounterId}`));
};

export const printEncounterSummary = ({ patientId, encounterId }) => {
  printPdf(
    printEncounterSummaries({
      patientId,
      encounterIds: [encounterId],
    }),
  );
};

export const openVisitSummary = ({ patientId, encounterId }) =>
  openOverlay(OVERLAY_KEYS.VISIT_SUMMARY, { patientId, encounterId });

export const openEncounterHistory = encounterId =>
  openOverlay(OVERLAY_KEYS.ENCOUNTER_HISTORY, { encounterId });

export const handleReopenEncounter = async encounterId => {
  const { accepted } = await openPopup(POPUP_RENDER_KEYS.ENCOUNTER_REOPEN);

  if (!accepted) return undefined;
  return __reopenEncounter(encounterId);
};
