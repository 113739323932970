import { _itemsGroupedById } from './base';

export const _fetching = state => ({ ...state, fetching: true });
export const _fetched = (state, { targetDate, start, end, items }) => ({
  ...state,
  fetching: false,
  fetchError: null,
  targetDate,
  start,
  end,
  itemsByGroupId: _itemsGroupedById(items),
  items,
});
export const _setTargetDate = (state, { targetDate }) => ({
  ...state,
  fetching: false,
  fetchError: null,
  targetDate,
});
export const _fetchingError = (state, { fetchError }) => ({
  ...state,
  fetching: false,
  fetchError,
});
