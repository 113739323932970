import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';

import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  statementIdTextfield: { id: 'textfield-statementId' },
  balanceCurrencyRange: { id: 'currencyrange-balance' },
  guarantorTextfield: { id: 'textfield-guarantor' },
  statementDateDateRange: { id: 'daterange-statementDate' },
  patientSearch: { id: 'search-patient' },
};

class NebPatientLedgerFilters extends NebFilters {
  static get properties() {
    return {
      enablePatient: {
        reflect: true,
        type: Boolean,
      },
      __patientItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    return {
      statementId: '',
      balance: NebCurrencyRange.createModel(),
      date: NebDateRange.createModel(),
      guarantor: '',
      patientId: '',
    };
  }

  initState() {
    super.initState();

    this.enablePatient = false;
    this.__patientItems = [];
    this.initPatientsService();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      clearStatementSearch: () => this.formService.apply('statementId', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        date: NebDateRange.createSelectors(),
        balance: NebCurrencyRange.createSelectors(),
      },
    };
  }

  initPatientsService() {
    this.__patientsService = createServerPatientsCollection({
      onChange: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      alwaysShowInactive: true,
    });
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };
    return html`
      ${this.renderPatientFilter()}

      <neb-textfield
        id="${ELEMENTS.statementIdTextfield.id}"
        name="statementId"
        label="Statement ID"
        helper=" "
        leadingIcon="neb:search"
        inputMode="numeric"
        .value="${this.state.statementId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-range-date
        id="${ELEMENTS.statementDateDateRange.id}"
        name="date"
        label="Date"
        helper=" "
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.guarantorTextfield.id}"
        name="guarantor"
        label="Guarantor"
        helper=" "
        leadingIcon="neb:search"
        .value="${this.state.guarantor}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-range-currency
        id="${ELEMENTS.balanceCurrencyRange.id}"
        name="balance"
        label="Balance"
        .model="${this.state.balance}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>
    `;
  }
}

window.customElements.define(
  'neb-filters-patient-ledger',
  NebPatientLedgerFilters,
);
