import { getHideInactivePatients } from '../../../neb-utils/patient';
import { formatPerson } from '../formatters/person';
import { getPerson } from '../person-api-client';

export const DUPLICATE_GUARANTOR = {
  title: 'Possible Duplicate Guarantor',
  message:
    'There is an existing patient or guarantor with the same first and last name. Do you want to continue with the creation of this guarantor?',
  confirmText: 'Create New Guarantor',
  cancelText: 'Cancel',
};

export class PersonService {
  constructor(callback) {
    this.callback = callback;
  }

  async search(query, patientId) {
    let results = [];

    if (!(query.trim() === '')) {
      const hideInactive = getHideInactivePatients();

      const queryParams = { search: query, hideInactive };

      if (patientId) {
        queryParams.patientId = patientId;
      }

      results = (await getPerson(queryParams, true)).map(person =>
        formatPerson(person),
      );
    }

    this.callback(results);
  }
}
