import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonContinue: {
    id: 'button-continue',
  },
  buttonLogout: {
    id: 'button-logout',
  },
  textMessage: {
    id: 'text-message',
  },
};
const CONTINUE_TEXT = 'Continue';
const LOGOUT_TEXT = 'Logout';
export const WARNING_TITLE = 'Your online session will expire in: ';
export const WARNING_MESSAGE =
  'Please click "Continue" to keep working or click "Logout" to end your session.';

class NebPopupTimeoutWarning extends NebPopup {
  static get properties() {
    return {
      __countdownMinute: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.model = {
      countdownDuration: '',
    };

    this.showCancelButton = false;
    this.countdownMinute = '';
    this.title = WARNING_TITLE;
    this.message = WARNING_MESSAGE;
  }

  __initHandlers() {
    super.initHandlers();
    this.__handlers = {
      continue: () =>
        this.onClose({
          logout: false,
          forcedLogout: false,
        }),
      logout: () =>
        this.onClose({
          logout: true,
          forcedLogout: false,
        }),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__setCountdownMinute(this.model.countdownDuration);

    this.__countDown();
  }

  disconnectedCallback() {
    window.clearInterval(this.__interval);
    super.disconnectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('__countdownMinute')) {
      this.title = WARNING_TITLE.concat(this.__countdownMinute, ' minutes.');
    }

    super.update(changedProps);
  }

  __countDown() {
    this.__interval = window.setInterval(() => {
      this.model.countdownDuration -= 1000;

      this.__setCountdownMinute(this.model.countdownDuration);

      if (this.model.countdownDuration <= 0) {
        window.clearInterval(this.__interval);
        this.onClose({
          logout: true,
          forcedLogout: true,
        });
      }
    }, 1000);
  }

  __setCountdownMinute(millisecond) {
    let minute = Math.floor(millisecond / 1000 / 60);

    if (minute < 1) {
      minute = 0;
    }

    let second = Math.floor((millisecond / 1000) % 60);

    if (second < 1) {
      second = 0;
    }

    this.__countdownMinute = `${minute}:${second <= 9 ? `0${second}` : second}`;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 450px;
          font-family: ${CSS_FONT_FAMILY};
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .container {
          height: auto;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonContinue.id}"
            class="button"
            .label="${CONTINUE_TEXT}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.continue}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonLogout.id}"
            class="button"
            .label="${LOGOUT_TEXT}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.logout}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-timeout-warning', NebPopupTimeoutWarning);
