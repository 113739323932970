import { store } from '../../neb-redux/neb-redux-store';
import { printPdf } from '../../neb-utils/neb-pdf-print-util';

import DeprecatedApiClient, { RESPONSE_TYPE } from './utils/api-client-utils';
import { buildSuperbillBody } from './utils/ledger-utils';

const BASE_URL = 'superbills';
const OPTS_V1 = { version: 1 };
const OPTS_V3 = { version: 3 };
const OPTS_V5 = { version: 5 };

export const PAYER_SELF = 'self';

export function formatAddress(address) {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zipcode: address.zipCode,
  };
}

function buildRequestor(apiClient) {
  return (method, path, body = null, opts = {}) => {
    const {
      version,
      query,
      responseType = RESPONSE_TYPE.JSON,
      includeContentType = true,
      optOutLoadingIndicator = false,
    } = opts;

    return apiClient.makeRequest({
      path,
      method,
      version: version || undefined,
      responseType,
      queryParams: query || {},
      body: body ? JSON.stringify(body) : undefined,
      ...(includeContentType && {
        headers: { 'Content-Type': 'application/json' },
      }),
      optOutLoadingIndicator,
    });
  };
}

export const apiClientBilling = new DeprecatedApiClient({
  microservice: 'billing',
});
export const apiClientPDF = new DeprecatedApiClient({
  microservice: 'pdf',
  useTenant: true,
});

const makeBillingRequest = buildRequestor(apiClientBilling);
const makePdfRequest = buildRequestor(apiClientPDF);

export async function fetchMany(
  patientId = '',
  optOutLoadingIndicator = false,
) {
  const opts = {
    version: 1,
    ...(patientId && { query: { patientId } }),
    optOutLoadingIndicator,
  };

  const { data, count } = await makeBillingRequest('GET', BASE_URL, null, opts);

  return { data, count };
}

export async function add(patientId, model, preview) {
  const path = `patients/${patientId}/${BASE_URL}`;
  const selfPay = model.payerIds.includes(PAYER_SELF);
  const body = {
    ...model,
    payerIds: selfPay
      ? model.payerIds.filter(p => p !== PAYER_SELF)
      : model.payerIds,
    preview,
    selfPay,
  };

  const { buffer } = await makeBillingRequest('POST', path, body, OPTS_V3);

  if (buffer) {
    await printPdf(Promise.resolve(buffer));
    return true;
  }
  return false;
}

const buildPracticeInformation = ({
  selectedLocation,
  practiceInformation,
}) => ({
  ...practiceInformation,
  phoneNumber: selectedLocation.phoneNumber || practiceInformation.phoneNumber,
  name:
    selectedLocation && selectedLocation.businessName
      ? selectedLocation.businessName
      : practiceInformation.name,
  email: selectedLocation.emailAddress || practiceInformation.email,
});

const buildBillingInformation = ({ selectedLocation, billingInformation }) => ({
  ...billingInformation,
  billingAddress: selectedLocation
    ? { ...formatAddress(selectedLocation), locationId: selectedLocation.id }
    : billingInformation.billingAddress,
  organizationNPI:
    selectedLocation && selectedLocation.organizationNPI
      ? selectedLocation.organizationNPI
      : billingInformation.organizationNPI,
  taxId:
    selectedLocation && selectedLocation.taxId
      ? selectedLocation.taxId
      : billingInformation.taxId,
});

const getBillingAndPracticeInfoFromLocation = ({
  body,
  locations,
  billingAddressId,
}) => {
  const { billingInformation, practiceInformation } = body;

  let updatedBillingInformation = { ...billingInformation };
  let updatedPracticeInformation = { ...practiceInformation };

  if (
    billingAddressId ||
    (billingInformation.billingAddress &&
      billingInformation.billingAddress.locationId)
  ) {
    const selectedLocation = billingAddressId
      ? locations.find(location => location.id === billingAddressId)
      : locations.find(
          location =>
            location.id === billingInformation.billingAddress.locationId,
        );

    updatedPracticeInformation = buildPracticeInformation({
      selectedLocation,
      practiceInformation: updatedPracticeInformation,
    });

    updatedBillingInformation = buildBillingInformation({
      selectedLocation,
      billingInformation: updatedBillingInformation,
    });
  }

  return {
    billingInformation: updatedBillingInformation,
    practiceInformation: updatedPracticeInformation,
  };
};

const formatProviderLicenses = provider => ({
  ...provider,
  licenses: provider.licenses.map(license => license.license),
});

const filterProviderLicenses = ({ provider, billingInformation }) => {
  if (
    billingInformation &&
    billingInformation.billingAddress &&
    billingInformation.billingAddress.state
  ) {
    const filteredLicenses = provider.licenses.filter(
      license => license.state === billingInformation.billingAddress.state,
    );

    return {
      ...provider,
      licenses: filteredLicenses.map(license => license.license),
    };
  }
  return formatProviderLicenses(provider);
};

export async function print(formData) {
  const superbillBody = await buildSuperbillBody(store.getState());
  const { billingAddressId, ...rest } = formData;

  let body = { ...superbillBody, ...rest };

  const { locations } = body.practiceInformation;

  const { billingInformation, practiceInformation } =
    getBillingAndPracticeInfoFromLocation({
      body,
      locations,
      billingAddressId,
    });

  body = {
    ...body,
    ...(body.superbills && {
      superbills: body.superbills.map(superbill => ({
        ...superbill,
        provider: filterProviderLicenses({
          provider: superbill.provider,
          billingInformation,
        }),
      })),
    }),
    billingInformation,
    practiceInformation,
  };

  return makePdfRequest('POST', BASE_URL, body, OPTS_V5);
}

export async function fetchPdf(s3key) {
  const path = 'pdf';
  const opts = {
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
    query: { s3key },
    includeContentType: false,
  };

  const res = await makePdfRequest('GET', path, null, opts);

  return res.arrayBuffer();
}

export async function addFromLineItems({ lineItems }) {
  const result = await makeBillingRequest(
    'POST',
    BASE_URL,
    { lineItems },
    OPTS_V1,
  );

  if (result?.buffer) {
    return printPdf(Promise.resolve(result.buffer));
  }
  return result;
}

export function addBatch({ model, preview, optOutLoadingIndicator = false }) {
  const path = `${BASE_URL}/batches`;
  const selfPay = model.payerIds.includes(PAYER_SELF);

  const body = {
    ...model,
    payerIds: selfPay
      ? model.payerIds.filter(p => p !== PAYER_SELF)
      : model.payerIds,
    preview,
    selfPay,
  };

  return makeBillingRequest('POST', path, body, {
    optOutLoadingIndicator,
    version: OPTS_V3.version,
  });
}

export function fetchBatches() {
  const path = `${BASE_URL}/batches`;

  return makeBillingRequest('GET', path);
}

export async function fetchBatch(batchId) {
  const path = `${BASE_URL}/batches/${batchId}`;
  const opts = {
    responseType: RESPONSE_TYPE.RAW,
  };
  const res = await makeBillingRequest('GET', path, null, opts);

  return res.arrayBuffer();
}

export function deleteBatch(batchId) {
  const path = `${BASE_URL}/batches/${batchId}`;

  return makeBillingRequest('DELETE', path);
}
