import moment from 'moment-timezone';

import { APPOINTMENT_BILLING_TYPE } from '../../../neb-api-client/src/mappers/appointment-billing-info-mapper';
import { BILL_TYPE } from '../../../neb-utils/neb-ledger-util';

import { INSURANCE_LEVELS } from './insurance-util';

export const createModelBillingInfo = () => ({
  id: null,
  billType: APPOINTMENT_BILLING_TYPE.SelfPay,
  caseBillTypeOverride: 0,
  guarantorId: null,
  insuranceGuarantorId: null,
  patientPackageId: null,
  primaryInsuranceId: null,
  secondaryInsuranceId: null,
  tertiaryInsuranceId: null,
  feeScheduleId: null,
  patientCaseId: null,
  patientAuthorizationId: null,
  providerId: null,
});

export class AppointmentBillingInfoDefaultsValidator {
  constructor(data) {
    if (!data) {
      throw new Error('options parameter is required');
    }

    if (!data.billType) {
      throw new Error('options billType parameter is required');
    }

    if (!data.appointmentDate) {
      throw new Error('options appointment date parameter is required');
    }

    if (!moment.isMoment(data.appointmentDate)) {
      throw new Error('options appointment date must be a moment object');
    }

    this.__defaultAppointmentBillingInfo = createModelBillingInfo();
    this.__data = data;
  }

  __getFeeSchedule(payerPlanId) {
    const { allFeeSchedules, providerId } = this.__data;

    return allFeeSchedules.find(
      f =>
        f.active &&
        f.payerPlans?.find(
          pp =>
            pp.id === payerPlanId &&
            (pp.allProviders || pp.providerIds.find(pi => pi === providerId)),
        ),
    );
  }

  __getLineItemValues() {
    const lineItem = this.__getLineItemWithLowestChargeNumber(
      this.__data.lineItems,
    );

    const values = {
      id: null,
      billType: lineItem.billType,
      guarantorId: lineItem.guarantorId,
      insuranceGuarantorId: lineItem.guarantorId,
      patientPackageId: lineItem.patientPackageId,
      primaryInsuranceId: lineItem.primaryInsuranceId,
      secondaryInsuranceId: lineItem.secondaryInsuranceId,
      tertiaryInsuranceId: lineItem.tertiaryInsuranceId,
      feeScheduleId: lineItem.feeScheduleId || null,
      patientCaseId: lineItem.patientCaseId || null,
      patientAuthorizationId: lineItem.patientAuthorizationId || null,
      caseBillTypeOverride: 0,
    };

    return values;
  }

  __getLineItemWithLowestChargeNumber(lineItems) {
    return lineItems.reduce((a, b) =>
      a.chargeNumber < b.chargeNumber ? a : b,
    );
  }

  __getCaseDefaults(caseData, billType) {
    const { feeSchedules, insurances, patientAuthorizationId } = this.__data;

    let feeSchedule;
    let payerPlanId;

    if (caseData) {
      if (caseData.primaryPayer) {
        payerPlanId = caseData.primaryPayer.id;
      } else if (caseData.payerInsurance) {
        const caseInsurance = insurances.find(
          i => i.id === caseData.payerInsurance.primaryInsuranceId,
        );

        payerPlanId = caseInsurance ? caseInsurance.payerPlanId : null;
      }
    }

    if (billType === BILL_TYPE.INSURANCE) {
      feeSchedule = this.__getFeeSchedule(payerPlanId);
    } else {
      feeSchedule = feeSchedules.find(f => f.default && f.active);
    }

    return caseData && billType
      ? {
          id: null,
          billType,
          caseBillTypeOverride: 1,
          guarantorId:
            billType === BILL_TYPE.SELF ? caseData.guarantorId : null,
          insuranceGuarantorId:
            billType === BILL_TYPE.INSURANCE ? caseData.guarantorId : null,
          patientPackageId:
            billType === BILL_TYPE.PACKAGE ? caseData.patientPackageId : null,
          primaryInsuranceId:
            billType === BILL_TYPE.INSURANCE
              ? caseData.payerInsurance.primaryInsuranceId
              : null,
          secondaryInsuranceId:
            billType === BILL_TYPE.INSURANCE
              ? caseData.payerInsurance.secondaryInsuranceId
              : null,
          tertiaryInsuranceId: null,
          feeScheduleId: feeSchedule ? feeSchedule.id : null,
          patientCaseId: caseData.id,
          patientAuthorizationId,
        }
      : {
          id: null,
          billType: BILL_TYPE.SELF,
          caseBillTypeOverride: 1,
          guarantorId: null,
          insuranceGuarantorId: null,
          patientPackageId: null,
          primaryInsuranceId: null,
          secondaryInsuranceId: null,
          tertiaryInsuranceId: null,
          feeScheduleId: feeSchedule ? feeSchedule.id : null,
          patientCaseId: null,
          patientAuthorizationId: null,
        };
  }

  __getInsuranceDefaults(carePackageWithInsuranceEnabled) {
    const { insurances, guarantors, carePackages } = this.__data;

    const primaryInsurance =
      insurances.find(
        i => i.defaultLevel === INSURANCE_LEVELS.PRIMARY && i.active,
      ) || null;

    const feeSchedule = primaryInsurance
      ? this.__getFeeSchedule(primaryInsurance.payerPlanId)
      : null;

    const secondaryInsurance = primaryInsurance
      ? insurances.find(
          i => i.defaultLevel === INSURANCE_LEVELS.SECONDARY && i.active,
        )
      : null;
    const tertiaryInsurance = secondaryInsurance
      ? insurances.find(
          i => i.defaultLevel === INSURANCE_LEVELS.TERTIARY && i.active,
        )
      : null;

    const guarantor = guarantors.find(g => g.active && g.default);

    const carePackage = carePackages.find(c => c.isDefault && c.active);

    return {
      id: null,
      billType: BILL_TYPE.INSURANCE,
      caseBillTypeOverride: 0,
      guarantorId: null,
      insuranceGuarantorId: guarantor ? guarantor.id : null,
      patientPackageId:
        carePackage && carePackageWithInsuranceEnabled ? carePackage.id : null,
      primaryInsuranceId: primaryInsurance ? primaryInsurance.id : null,
      secondaryInsuranceId: secondaryInsurance ? secondaryInsurance.id : null,
      tertiaryInsuranceId: tertiaryInsurance ? tertiaryInsurance.id : null,
      feeScheduleId: feeSchedule ? feeSchedule.id : null,
      patientCaseId: null,
      patientAuthorizationId: null,
    };
  }

  __getCarePackageDefaults() {
    const { carePackages, feeSchedules } = this.__data;
    const carePackage = carePackages.find(
      c => c.data.isDefault && c.data.active,
    );
    const feeSchedule = feeSchedules.find(f => f.default && f.active);

    return {
      id: null,
      billType: BILL_TYPE.PACKAGE,
      caseBillTypeOverride: 0,
      guarantorId: null,
      insuranceGuarantorId: null,
      patientPackageId:
        carePackage && carePackage.data && carePackage.data.id
          ? carePackage.data.id
          : null,
      primaryInsuranceId: null,
      secondaryInsuranceId: null,
      tertiaryInsuranceId: null,
      feeScheduleId: feeSchedule ? feeSchedule.id : null,
      patientCaseId: null,
      patientAuthorizationId: null,
    };
  }

  __getSelfPayDefaults() {
    const { guarantors, feeSchedules } = this.__data;
    const guarantor = guarantors.find(g => g.active && g.default);
    const feeSchedule = feeSchedules.find(f => f.default && f.active);

    return {
      id: null,
      billType: BILL_TYPE.SELF,
      caseBillTypeOverride: 0,
      guarantorId: guarantor ? guarantor.id : null,
      insuranceGuarantorId: null,
      patientPackageId: null,
      primaryInsuranceId: null,
      secondaryInsuranceId: null,
      tertiaryInsuranceId: null,
      feeScheduleId: feeSchedule ? feeSchedule.id : null,
      patientCaseId: null,
      patientAuthorizationId: null,
    };
  }

  __setDefaults(carePackageWithInsuranceEnabled) {
    if (this.__data.lineItems.length > 0) {
      this.__defaultAppointmentBillingInfo = this.__getLineItemValues();
    } else if (this.__data.caseBillTypeOverride) {
      const { patientCase } = this.__data;
      const caseData = patientCase && patientCase.id ? patientCase : null;
      const billType = caseData ? caseData.billType : null;

      this.__defaultAppointmentBillingInfo = this.__getCaseDefaults(
        caseData,
        billType,
      );
    } else {
      switch (this.__data.billType) {
        case 'Insurance':
          this.__defaultAppointmentBillingInfo = this.__getInsuranceDefaults(
            carePackageWithInsuranceEnabled,
          );

          break;

        case 'Care Package':
          this.__defaultAppointmentBillingInfo =
            this.__getCarePackageDefaults();

          break;

        default:
          this.__defaultAppointmentBillingInfo = this.__getSelfPayDefaults();

          break;
      }
    }
  }

  getAppointmentBillingInfo(carePackageWithInsuranceEnabled) {
    this.__setDefaults(carePackageWithInsuranceEnabled);

    return this.__defaultAppointmentBillingInfo;
  }
}
