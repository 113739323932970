import '../../../../neb-lit-components/src/components/encounter/neb-encounter-list-controller';

import { html, LitElement, css } from 'lit';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebChartingAgendaViewOverlay extends LitElement {
  static get properties() {
    return {
      appointmentTypes: { type: Array },
      layout: { type: String, reflect: true },
      filteredPatient: { type: Object },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.appointmentTypes = [];
    this.layout = '';
    this.filteredPatient = null;

    this.onSelect = () => {};

    this.onUpdateFilteredPatient = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      select: e => this.onSelect(e),
      updateFilteredPatient: patient => {
        this.onUpdateFilteredPatient(patient);
      },
    };
  }

  __buildAppointmentTypesMap(appointmentTypes) {
    return appointmentTypes.reduce((acc, appointmentType) => {
      acc[appointmentType.id] = appointmentType;

      return acc;
    }, {});
  }

  static get styles() {
    return css`
      .controller {
        width: 525px;
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      <neb-encounter-list-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .appointmentTypes="${this.__buildAppointmentTypesMap(
          this.appointmentTypes,
        )}"
        .encounterId="${''}"
        .filteredPatient="${this.filteredPatient}"
        .onUpdateFilteredPatient="${this.__handlers.updateFilteredPatient}"
        .layout="${this.layout}"
        .onSelect="${this.__handlers.select}"
      ></neb-encounter-list-controller>
    `;
  }
}

customElements.define(
  'neb-charting-agenda-view-overlay',
  NebChartingAgendaViewOverlay,
);
