import '../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../packages/neb-lit-components/src/components/neb-text';

import { css, html, LitElement } from 'lit';
import moment from 'moment-timezone';

import { SEVEN_YEARS_AGO } from '../../../packages/neb-lit-components/src/components/forms/neb-form-patient-insurance-add';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { CollectionService } from '../../../packages/neb-utils/services/collection';
import { baseStyles, CSS_SPACING, layoutStyles } from '../../styles';

export const ELEMENTS = {
  header: { id: 'header' },
  description: { id: 'description' },
  dateRangeFrom: { id: 'date-range-from' },
  dateRangeTo: { id: 'date-range-to' },
  content: { id: 'content' },
  table: { id: 'table' },
  pagination: { id: 'pagination' },
  actionBar: { id: 'action-bar' },
};

const TABLE_CONFIG = [
  {
    key: 'hidden',
  },
  {
    key: 'start',
    label: 'Date',
    flex: css`0.5 0 0`,
    formatter: v => {
      const date = parseDate(v).format('MM/DD/YYYY h:mm A');
      const [str1, ...str2] = date.split(' ');

      return html`
        ${str1} <br />
        ${str2}
      `;
    },
  },
  {
    key: 'appointmentType',
    label: 'Type',
    flex: css`1 0 0`,
  },
  {
    key: 'provider',
    label: 'Provider',
    flex: css`1 0 0`,
    truncate: true,
    formatter: v => {
      const [str1, str2] = v.split(', ');

      return html`
        ${str1} <br />
        ${str2}
      `;
    },
  },
  {
    key: 'billType',
    label: 'Billing',
    flex: css`1 0 0`,
    truncate: true,
    formatter: v => {
      const [str1, str2] = v.split(' - ');

      return html`
        ${str1} <br />
        ${str2}
      `;
    },
  },
];

class NebViewPatientInsuranceBillTypeUpdate extends LitElement {
  static get properties() {
    return {
      __currentPageIndex: Number,
      __pageCount: Number,
      __appointments: Array,
      __selectedAppointmentsForCurrentPage: Array,
      __selectedAppointments: Object,

      layout: { type: String, reflect: true },
      model: Array,
      names: Array,
      dateRange: Object,
      insuranceDates: Object,
      payerPlan: String,
      insurance: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .date-info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
          padding: 0 ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .description {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .content {
          display: grid;
          grid-gap: 20px;
          padding-bottom: 20px;
          overflow: auto;
        }

        .spacing {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .pagination {
          justify-self: flex-end;
          padding-top: unset;
        }

        .date-range {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
        }

        neb-table::part(cell-data) {
          padding: 10px 0;
        }

        neb-table::part(row-header) {
          padding: unset;
        }

        neb-table::part(row-data) {
          padding: unset;
        }

        :host([layout='small']) .date-info {
          grid-template-rows: auto 1fr;
          grid-template-columns: unset;
        }

        :host([layout='small']) .picker {
          width: unset;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initServices();
    this.__initState();
    this.__initHandlers();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: ({ pageIndex, pageCount, pageItems }) => {
          this.__currentPageIndex = pageIndex;
          this.__appointments = pageItems;
          this.__pageCount = pageCount;
        },
        onSort: (a, b) => {
          const startA = parseDate(a.start);
          const startB = parseDate(b.start);

          if (startA.isBefore(startB)) {
            return -1;
          }

          if (startA.isAfter(startB)) {
            return 1;
          }

          return 0;
        },
      },
      {
        hideInactive: false,
        pageSize: 10,
        sortParams: {
          key: 'start',
          dir: 'asc',
        },
      },
    );
  }

  __initState() {
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__appointments = [];
    this.__selectedAppointments = new Map();
    this.__selectedAppointmentsForCurrentPage = new Array(10).fill(false);

    this.model = [];
    this.layout = '';
    this.names = [];
    this.dateRange = {};
    this.insuranceDates = {};
    this.payerPlan = '';
    this.insurance = '';

    this.onSave = () => {};

    this.onFetch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
      selectItem: (_, idx) => {
        const globalIndex =
          idx + this.__currentPageIndex * this.__getPageSize();

        if (!this.__selectedAppointments.has(globalIndex)) {
          this.__selectedAppointments.set(
            globalIndex,
            this.__appointments[idx].appointmentId,
          );
        } else {
          this.__selectedAppointments.delete(globalIndex);
        }

        this.requestUpdate('__selectedAppointments');
      },
      selectAllForCurrentPage: () => {
        const isAllSelected =
          this.__selectedAppointmentsForCurrentPage.every(Boolean);

        this.__appointments.forEach((appointment, idx) => {
          const globalIndex =
            idx + this.__currentPageIndex * this.__getPageSize();

          if (isAllSelected) {
            this.__selectedAppointments.delete(globalIndex);
          } else {
            this.__selectedAppointments.set(
              globalIndex,
              appointment.appointmentId,
            );
          }
        });

        this.requestUpdate('__selectedAppointments');
      },
      save: () => this.onSave(Array.from(this.__selectedAppointments.values())),
      cancel: () => this.onSave([]),
      isFromDateSelectable: date => {
        const fromDate = this.insuranceDates.from
          ? moment.max(
              SEVEN_YEARS_AGO,
              parseDate(this.insuranceDates.from).startOf('day'),
            )
          : SEVEN_YEARS_AGO;

        return (
          date >= fromDate &&
          date <= (this.dateRange.to || parseDate().startOf('day'))
        );
      },
      changeDate: async ({ name, value }) => {
        if (
          !value ||
          !this.dateRange[name] ||
          !this.dateRange[name].isSame(value, 'day')
        ) {
          this.dateRange[name] = value;

          if (this.dateRange.from && this.dateRange.to) {
            this.__selectedAppointments.clear();

            await this.onFetch();
            this.requestUpdate('__selectedAppointments');
          }
        }
      },
      isToDateSelectable: date => {
        const today = parseDate().startOf('day');

        const toDate = this.insuranceDates.to
          ? moment.min(parseDate(this.insuranceDates.to).startOf('day'), today)
          : today;

        return (
          date <= toDate && date >= (this.dateRange.from || SEVEN_YEARS_AGO)
        );
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__collectionService.setItems(this.model);
    }

    if (
      changedProps.has('__appointments') ||
      changedProps.has('__selectedAppointments')
    ) {
      this.__selectedAppointmentsForCurrentPage = this.__appointments.map(
        (_, idx) => {
          const globalIndex =
            idx + this.__currentPageIndex * this.__getPageSize();

          return this.__selectedAppointments.has(globalIndex);
        },
      );
    }

    if (changedProps.has('layout')) {
      this.__collectionService.setPageSize(this.__getPageSize());
    }

    super.update(changedProps);
  }

  __getPageSize() {
    return this.layout === 'small' ? 5 : 10;
  }

  __renderDateRange() {
    return html`
      <div class="date-range">
        <neb-date-picker
          id="${ELEMENTS.dateRangeFrom.id}"
          name="from"
          label="From"
          placeholder="From"
          class="picker"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${parseDate(this.dateRange.from)}"
          .onChange="${this.__handlers.changeDate}"
          .isDateSelectable="${this.__handlers.isFromDateSelectable}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateRangeTo.id}"
          name="to"
          label="To"
          placeholder="To"
          class="picker"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${parseDate(this.dateRange.to)}"
          .onChange="${this.__handlers.changeDate}"
          .isDateSelectable="${this.__handlers.isToDateSelectable}"
          momentFlag
        ></neb-date-picker>
      </div>
    `;
  }

  renderContent() {
    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        class="spacing"
        .showSelectAll="${true}"
        .config="${TABLE_CONFIG}"
        .model="${this.__appointments}"
        .selectedItems="${this.__selectedAppointmentsForCurrentPage}"
        .onSelectAll="${this.__handlers.selectAllForCurrentPage}"
        .onSelectCheckbox="${this.__handlers.selectItem}"
      ></neb-table>

      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="spacing pagination"
        .currentPage="${this.__currentPageIndex}"
        .onPageChanged="${this.__handlers.changePage}"
        .pageCount="${this.__pageCount}"
      ></neb-pagination>
    `;
  }

  renderFooter() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        .confirmLabel="${'Update'}"
        .onConfirm="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
      ></neb-action-bar>
    `;
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Update Previous Appointments"
        .onCancel="${this.__handlers.cancel}"
        showCancelButton
      ></neb-popup-header>

      <div id="${ELEMENTS.description.id}" class="description">
        A different bill type or insurance plan was used for previous checked
        out appointments within this insurance plan's coverage dates. Would you
        like to update previous appointments to use this insurance instead?
      </div>

      <div class="date-info">
        <div>
          <b>Payer Plan:</b> ${this.payerPlan} <br />
          <b>Insurance Plan:</b> ${this.insurance}
        </div>

        ${this.__renderDateRange()}
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHeader()}

        <div id="${ELEMENTS.content.id}" class="content">
          ${this.renderContent()}
        </div>

        ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-view-patient-insurance-bill-type-update',
  NebViewPatientInsuranceBillTypeUpdate,
);
