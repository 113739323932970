import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_COLOR_DISABLED,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_CAPTION,
  CSS_SPACING,
} from '../../../../src/styles';

export const ELEMENTS = {
  text: {
    id: 'text',
  },
  trailingIcon: {
    id: 'icon-trailing',
  },
};

export const COLOR = {
  DEFAULT: 'default',
  ALERT: 'alert',
};

class NebText extends LitElement {
  static get properties() {
    return {
      bold: {
        reflect: true,
        type: Boolean,
      },
      italic: {
        reflect: true,
        type: Boolean,
      },
      link: {
        reflect: true,
        type: Boolean,
      },
      color: {
        reflect: true,
        type: String,
      },
      caption: {
        reflect: true,
        type: String,
      },
      trailingIcon: String,
      name: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          min-height: 0;
          min-width: 0;
          word-break: break-word;
        }

        .text {
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        :host([bold]) .text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        :host([italic]) .text {
          font-style: italic;
        }

        :host([link]) .text {
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          cursor: pointer;
        }

        :host([link]) .text:focus,
        :host([link]) .text:hover {
          opacity: 0.65;
        }

        :host([link]) .text::-moz-focus-inner {
          border: 0;
        }

        :host([color='alert']) .text {
          color: ${CSS_COLOR_ERROR};
        }

        :host([color='disabled']) .text {
          color: ${CSS_COLOR_DISABLED};
        }

        :host([truncate]) {
          width: 100%;
        }

        :host([truncate]) .text {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        :host([caption]) .text {
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .container {
          display: grid;
          grid-template-columns: auto auto;
        }

        .icon-trailing {
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 3px;
          cursor: pointer;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.bold = false;
    this.italic = false;
    this.link = false;
    this.truncate = false;
    this.color = '';
    this.name = '';
    this.trailingIcon = '';

    this.onClick = null;
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        if (this.onClick) {
          e.stopPropagation();

          this.__elements.text.blur();

          this.onClick({ name: this.name, id: e.currentTarget.id });
        }
      },
      keyUp: e => e.key === 'Enter' && this.__handlers.click(e),
    };
  }

  firstUpdated() {
    this.__elements = {
      text: this.shadowRoot.getElementById(ELEMENTS.text.id),
    };
  }

  __renderTrailingIcon() {
    return this.trailingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.trailingIcon.id}"
            class="icon icon-trailing"
            .icon="${this.trailingIcon}"
            @click="${this.__handlers.click}"
            @keyup="${this.__handlers.keyUp}"
          ></neb-icon>
        `
      : '';
  }

  __renderText() {
    return html`
      <span
        id="${ELEMENTS.text.id}"
        class="text"
        tabIndex="${this.link ? 0 : -1}"
        @click="${this.__handlers.click}"
        @keyup="${this.__handlers.keyUp}"
      >
        <slot></slot>
      </span>
    `;
  }

  render() {
    return this.trailingIcon
      ? html`
          <div class="container">
            ${this.__renderText()} ${this.__renderTrailingIcon()}
          </div>
        `
      : this.__renderText();
  }
}

window.customElements.define('neb-text', NebText);
