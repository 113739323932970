import '../../../packages/neb-www-practice/src/components/scheduling/neb-scheduling-error-handling-page';
import './neb-calendar-agenda-view';

import { css, html, LitElement } from 'lit';

import { getAppointments } from '../../../packages/neb-api-client/src/appointment-api-client';
import { getCalendarSummary } from '../../../packages/neb-api-client/src/calendar-summary-api-client';
import * as settingsApiClient from '../../../packages/neb-api-client/src/settings-api-client';
import { SchedulingRequirementService } from '../../../packages/neb-redux/services/scheduling-requirement';
import { CALENDAR_TYPES } from '../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { CSS_COLOR_WHITE } from '../../styles';
import { PollController } from '../../utils/poll-controller';

import { ZOOM_INTERVAL } from './neb-calendar-day-view-mobile';
import { STATUS_ITEMS } from './neb-calendar-header-mobile';
import {
  DATE_FORMAT,
  roundFoldToNearestInterval,
} from './neb-scheduling-calendar';

export const ELEMENTS = {
  agendaView: { id: 'agenda-view' },
  calendarHeader: { id: 'calendar-header' },
  dayView: { id: 'day-view' },
  schedulingError: { id: 'scheduling-error' },
};

function getStatusesKey(tenantId) {
  return `scheduling-statuses:${tenantId}`;
}

const DEFAULT_STATUSES = [
  STATUS_ITEMS[0],
  STATUS_ITEMS[1],
  STATUS_ITEMS[2],
  STATUS_ITEMS[4],
];

function getStatusesFromLocalStorage(tenantId) {
  const statuses = JSON.parse(localStorage.getItem(getStatusesKey(tenantId)));

  return statuses || DEFAULT_STATUSES;
}

class NebSchedulingCalendarMobile extends LitElement {
  static get properties() {
    return {
      view: String,
      tenantId: String,
      __agendaEvents: Array,
      __calendarSummary: Object,
      __folds: Array,
      __location: Object,
      __provider: Object,
      __room: Object,
      __statuses: Array,
      __targetDate: String,
      __displayAgendaView: Boolean,
      __displayProviderFilter: Boolean,
      __schedulingDisabled: Boolean,
      __autoFoldCalendar: Boolean,
    };
  }

  static get styles() {
    return css`
      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        background-color: ${CSS_COLOR_WHITE};
      }

      .header {
        background: ${CSS_COLOR_WHITE};
        transition: 200ms ease;
      }

      .agenda,
      .day {
        display: block;
        flex: 1 0 0;
        width: 100%;
        height: 100%;

        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      .scheduling-error {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.view = '';
    this.tenantId = '';

    this.__targetDate = parseDate().format(DATE_FORMAT);
    this.__location = {};
    this.__provider = {};
    this.__room = {};
    this.__statuses = [];
    this.__agendaEvents = [];
    this.__calendarSummary = {};
    this.__displayAgendaView = false;
    this.__displayProviderFilter = true;
    this.__schedulingDisabled = false;
    this.__folds = [];
    this.__autoFoldCalendar = false;
  }

  __initHandlers() {
    this.__handlers = {
      changeView: () => {
        this.__displayAgendaView = !this.__displayAgendaView;
      },
      selectDate: date => {
        this.__targetDate = date;
      },
      selectLocation: location => {
        this.__location = location ? location.data : {};
      },
      selectProvider: provider => {
        this.__provider = provider ? provider.data : {};
      },
      selectRoom: room => {
        this.__room = room ? room.data : {};
      },
      selectStatuses: statuses => {
        this.__statuses = statuses;

        localStorage.setItem(
          getStatusesKey(this.tenantId),
          JSON.stringify(statuses),
        );
      },
      toggleFilter: value => {
        this.__displayProviderFilter = value;
      },
      updateCalendar: () => {
        this.__fetch();
      },
      foldCalendar: foldIndex => {
        const fold = this.__folds[foldIndex];

        fold.isFolded = !fold.isFolded;

        this.__folds = [...this.__folds];
      },
    };
  }

  __initServices() {
    this.__schedulingRequirementService = new SchedulingRequirementService(
      requirementsMet => {
        this.__schedulingDisabled = !requirementsMet;
      },
    );

    this.__pollController = new PollController(this, async () => {
      await this.__fetch();
    });
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__statuses = getStatusesFromLocalStorage(this.tenantId);

    const { data: settings } = await settingsApiClient.fetchOne(true);

    this.__autoFoldCalendar = settings.autoFoldCalendar;
  }

  update(changedProps) {
    if (changedProps.has('__autoFoldCalendar')) {
      this.__updateCalendarFolds(this.__folds);
    }

    super.update(changedProps);
  }

  // eslint-disable-next-line complexity
  updated(changedProps) {
    if (
      changedProps.has('__provider') ||
      changedProps.has('__location') ||
      changedProps.has('__room') ||
      changedProps.has('__displayAgendaView') ||
      changedProps.has('__displayProviderFilter') ||
      (changedProps.has('__targetDate') && !this.__displayAgendaView) ||
      changedProps.has('__statuses')
    ) {
      this.__fetch();
    }
  }

  async __fetch() {
    if (this.__displayAgendaView) {
      await this.__fetchAgendaEvents();
    } else {
      await this.__fetchCalendarSummary();
    }
  }

  __shouldFetch() {
    return (
      ((this.__provider.id && this.__displayProviderFilter) ||
        (this.__room.id && !this.__displayProviderFilter)) &&
      this.__location.id &&
      this.__statuses.length
    );
  }

  async __fetchAgendaEvents() {
    const providerId = this.__provider.id;
    const locationId = this.__location.id;
    const resourceId = this.__room.id;

    if (this.__shouldFetch()) {
      const targetDate = parseDate(this.__targetDate);

      const start = parseDate()
        .year(targetDate.year())
        .month(targetDate.month())
        .date(-7)
        .startOf('day')
        .format(DATE_FORMAT);

      const end = parseDate()
        .year(targetDate.year())
        .month(targetDate.month() + 1)
        .date(7)
        .startOf('day')
        .format(DATE_FORMAT);

      const includeRescheduled = !!this.__statuses.find(
        s => s.value === 'Rescheduled',
      );

      const events = await getAppointments(
        {
          start,
          end,
          locationId,
          status: this.__statuses.map(s => s.value),
          standardSort: true,
          expand: 'splits',
          ...(this.__displayProviderFilter
            ? {
                providerId,
              }
            : {
                resourceId,
              }),
          includeRescheduled,
        },
        true,
      );

      this.__agendaEvents = events.data;
    } else {
      this.__agendaEvents = [];
    }
  }

  __updateCalendarFolds(folds = []) {
    const foldedArray = this.__folds.map(fold => fold.isFolded);

    this.__folds = folds.map((fold, idx) => {
      const { roundedStart, roundedEnd } = roundFoldToNearestInterval(
        fold,
        ZOOM_INTERVAL,
      );

      return {
        ...fold,
        isFolded:
          foldedArray[idx] !== undefined
            ? foldedArray[idx]
            : this.__autoFoldCalendar,
        roundedStart,
        roundedEnd,
      };
    });
  }

  // eslint-disable-next-line complexity
  async __fetchCalendarSummary() {
    const providerId = this.__provider.id;
    const locationId = this.__location.id;
    const resourceId = this.__room.id;

    if (this.__shouldFetch()) {
      const start = parseDate(this.__targetDate).format(DATE_FORMAT);

      const end = parseDate(this.__targetDate).format(DATE_FORMAT);

      if (this.__abortController) {
        this.__abortController.abort();
      }
      this.__abortController = new AbortController();

      try {
        const res = await getCalendarSummary({
          start,
          end,
          locationIds: [locationId],
          ...(this.__displayProviderFilter
            ? {
                type: CALENDAR_TYPES.PROVIDER,
                providerIds: [providerId],
              }
            : {
                type: CALENDAR_TYPES.ROOM,
                resourceIds: [resourceId],
                includeSplits: true,
              }),
          status: this.__statuses.map(s => s.value),
          signal: this.__abortController.signal,
        });

        this.__calendarSummary = res && res.data && res.data[0];
        this.__updateCalendarFolds(res.folds);
      } catch (error) {
        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    } else {
      this.__calendarSummary = {};
      this.__folds = [];
    }
  }

  __renderSchedulingErrorPage() {
    return this.__schedulingDisabled
      ? html`
          <neb-scheduling-error-handling-page
            id="${ELEMENTS.schedulingError.id}"
            class="scheduling-error"
            small
          ></neb-scheduling-error-handling-page>
        `
      : '';
  }

  __renderView() {
    return this.__displayAgendaView
      ? html`
          <neb-calendar-agenda-view
            id="${ELEMENTS.agendaView.id}"
            class="day"
            .provider="${this.__provider}"
            .location="${this.__location}"
            .events="${this.__agendaEvents}"
            .targetDate="${this.__targetDate}"
            .onUpdateTargetDate="${this.__handlers.selectDate}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
          ></neb-calendar-agenda-view>
        `
      : html`
          <neb-calendar-day-view-mobile
            id="${ELEMENTS.dayView.id}"
            class="day"
            .targetDate="${this.__targetDate}"
            .providerId="${this.__provider.id}"
            .locationId="${this.__location.id}"
            .resourceId="${this.__room.id}"
            .isProvider="${this.__displayProviderFilter}"
            .folds="${this.__folds}"
            .model="${this.__calendarSummary}"
            .onSwipe="${this.__handlers.selectDate}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
            .onFold="${this.__handlers.foldCalendar}"
          ></neb-calendar-day-view-mobile>
        `;
  }

  render() {
    return html`
      <div class="container">
        <neb-calendar-header-mobile
          id="${ELEMENTS.calendarHeader.id}"
          class="header"
          .targetDate="${this.__targetDate}"
          .displayProviderFilter="${this.__displayProviderFilter}"
          .displayAgendaView="${this.__displayAgendaView}"
          .statuses="${this.__statuses}"
          .onChangeView="${this.__handlers.changeView}"
          .onSelectDate="${this.__handlers.selectDate}"
          .onSelectLocation="${this.__handlers.selectLocation}"
          .onSelectProvider="${this.__handlers.selectProvider}"
          .onSelectRoom="${this.__handlers.selectRoom}"
          .onSelectStatuses="${this.__handlers.selectStatuses}"
          .onToggleFilter="${this.__handlers.toggleFilter}"
          .onUpdateCalendar="${this.__handlers.updateCalendar}"
        ></neb-calendar-header-mobile>
        ${this.__renderView()} ${this.__renderSchedulingErrorPage()}
      </div>
    `;
  }
}

customElements.define(
  'neb-scheduling-calendar-mobile',
  NebSchedulingCalendarMobile,
);
