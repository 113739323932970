import '../../../src/components/forms/neb-form-email-patient';
import { html, css } from 'lit';

import { postBookingAccountInvite } from '../../neb-api-client/src/booking-account-invite-api-client';
import * as patientApiClient from '../../neb-api-client/src/patient-api-client';
import { inviteBookingPatient } from '../../neb-api-client/src/patient-api-client';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_COLOR_WHITE } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  form: { id: 'form' },
  mobileHeader: {
    id: 'mobile-header',
  },
};
const TEXT_TITLE = 'Send Invitation Email';
const TEXT_INFO =
  'Please select or enter an email below to send the online booking account invitation to.';

const ERROR_BANNER_MESSAGE =
  'There was a problem sending the invitation. Please check the email address provided or review matching accounts';

const SUCCESS_BANNER = 'Invitation email sent';

class NebPopupInvitePatient extends NebPopup {
  static get properties() {
    return {
      __emailAddresses: Array,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;
    this.__patient = {};
    this.__emailAddresses = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () =>
      this.onClose({
        cancel: true,
      });

    this.handlers.sendInvite = async ({ emailAddress, newEmailAddress }) => {
      try {
        const email = newEmailAddress || emailAddress;

        const {
          data: [bookingAccountInvite],
        } = await postBookingAccountInvite({
          patientId: this.__patient.id,
          email,
        });

        const inviteBookingPatientPayload = {
          patient: this.__patient,
          email,
          bookingAccountInviteId: bookingAccountInvite.id,
        };

        await inviteBookingPatient(inviteBookingPatientPayload, {}, false, 2);

        if (newEmailAddress) {
          const patient = {
            ...this.__patient,
            emailAddresses: [...this.__emailAddresses, newEmailAddress],
          };

          await patientApiClient.updatePatient(patient, undefined, true);
        }

        this.onClose({ success: true });

        store.dispatch(openSuccess(SUCCESS_BANNER));
      } catch (e) {
        console.log(e);
        store.dispatch(openError(ERROR_BANNER_MESSAGE));
      }
    };
  }

  modelChanged() {
    this.__patient = this.model.patient;
    this.__emailAddresses = this.__patient.emailAddresses || [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.mobileHeader.id}" class="header">${this.title}</div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        ${this.layout === 'small' ? this.__renderHeader() : ''}
        <div class="info-text">${TEXT_INFO}</div>

        <neb-form-email-patient
          id="${ELEMENTS.form.id}"
          .onCancel="${this.handlers.cancel}"
          .onSave="${this.handlers.sendInvite}"
          .emailAddresses="${this.__emailAddresses}"
        ></neb-form-email-patient>
      </div>
    `;
  }
}

customElements.define('neb-popup-invite-patient', NebPopupInvitePatient);
