import '../../neb-table-header';
import '../../neb-pagination';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
} from '../../../../../neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../../neb-utils/enums';
import { CONFIG } from '../charges/neb-charge-table-row';

export const CONFIG_UNIT = {
  mobile: true,
  key: 'units',
  label: 'Max Units',
  flex: css`0 0 120px`,
};
export const ELEMENTS = {
  noItemsText: {
    id: 'text-no-items',
  },
  rows: {
    selector: 'neb-charge-table-row',
  },
  pagination: {
    id: 'pagination',
  },
};
export const NO_CHARGES_MESSAGE = {
  NO_RESULTS: 'No results.',
  NO_CHARGES_ADDED:
    'There are no charges for this package. Click "Add Charges" to include covered services.',
};
class NebPackagesChargesTable extends LitElement {
  static get properties() {
    return {
      items: Array,
      tableType: String,
      hasSearchText: Boolean,
      hasCharges: Boolean,
      currentPage: Number,
      pageCount: Number,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__elements = {};
    this.items = [];
    this.tableType = PACKAGE_TYPE.VISIT;
    this.hasSearchText = false;
    this.hasCharges = false;
    this.currentPage = 0;
    this.pageCount = 0;

    this.onChange = () => {};

    this.onPageChanged = () => {};

    this.onRemoveCharge = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeCharge: charge => this.onChange(charge),
      pageChanged: index => this.onPageChanged(index),
      removeCharge: (item, index) => this.onRemoveCharge(item, index),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .text-no-items {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .pageCount="${this.pageCount}"
        .currentPage="${this.currentPage}"
        .onPageChanged="${this.__handlers.pageChanged}"
      ></neb-pagination>
    `;
  }

  __renderNoMessagesText() {
    return html`
      <p id="${ELEMENTS.noItemsText.id}" class="text-no-items">
        ${this.hasSearchText && this.hasCharges
          ? NO_CHARGES_MESSAGE.NO_RESULTS
          : NO_CHARGES_MESSAGE.NO_CHARGES_ADDED}
      </p>
    `;
  }

  updated() {
    this.__elements = {
      rows: this.shadowRoot.querySelectorAll('neb-charge-table-row'),
    };
  }

  async validate() {
    const rows = Array.from(this.__elements.rows).map(r => r.validate());
    const validValues = await Promise.all(rows);
    return validValues.every(v => !!v);
  }

  __renderTable(config) {
    return html`
      ${this.items.map(
        (item, index) => html`
          <neb-charge-table-row
            .index="${index}"
            .model="${item}"
            .config="${config}"
            .onChange="${this.__handlers.changeCharge}"
            .onRemove="${this.__handlers.removeCharge}"
            showRemoveButton
          ></neb-charge-table-row>
        `,
      )}
      ${this.pageCount > 0 ? this.__renderPagination() : ''}
    `;
  }

  render() {
    const config =
      this.tableType === PACKAGE_TYPE.VISIT ? CONFIG : [...CONFIG, CONFIG_UNIT];
    return html`
      <neb-table-header .config="${config}" showDetailIcon></neb-table-header>

      ${this.items.length > 0
        ? this.__renderTable(config)
        : this.__renderNoMessagesText()}
    `;
  }
}
customElements.define('neb-packages-charges-table', NebPackagesChargesTable);
