import { html, css } from 'lit';
import moment from 'moment-timezone';

import { getClaimScrubbing } from '../../../../../../../src/api-clients/claim-scrubbing';
import { hasByocAddons } from '../../../../../../../src/utils/clearinghouse-settings';
import {
  NO_CLAIMS_ERROR_PRIMARY,
  NO_CLAIMS_ERROR_SECONDARY,
  errorOccurred,
} from '../../../../../../../src/utils/user-message';
import {
  createClaim,
  getClaim,
  getClaimFields,
  updateClaim,
  updateClaimStatus,
  refreshClaimData,
  voidClaim,
  USER_ACTION_KEY,
  getUserActionKeyByDescription,
  scrubClaims,
} from '../../../../../../neb-api-client/src/claims';
import { getPatientInsurance } from '../../../../../../neb-api-client/src/patient-insurance-api-client';
import * as claimsValidationErrorsApi from '../../../../../../neb-api-client/src/validation-errors-api-client';
import {
  openError,
  openSuccess,
} from '../../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  isCanceled,
  isEditable,
  PAYMENT_RESPONSIBILITY_LEVEL_CODE,
} from '../../../../../../neb-utils/claims';
import { BILLING_NOTE_TYPES } from '../../../../../../neb-utils/constants';
import { parseDate } from '../../../../../../neb-utils/date-util';
import { validateAndSubmitElectronicClaim } from '../../../../../../neb-utils/electronic-claims';
import { CLAIM_RESUBMISSION_CODE } from '../../../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import { SCRUB_CODE } from '../../../../utils/claim-validation';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import { NebFormClaim } from '../../../forms/neb-form-claim';
import NebOverlayFormItem, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../neb-overlay-form-item';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  description: { id: 'description' },
  electronicClaimDescription: { id: 'electronicClaimDescription' },
  originalClaimLink: { id: 'textOriginalClaim' },
  form: { id: 'form' },
};

export const SUBMISSION_METHOD = Object.freeze({
  PAPER: 'Paper Claims',
  ELECTRONIC: 'Electronic Claims',
});

const isScrubbingError = error => error.code && SCRUB_CODE[error.code];

class NebOverlayLedgerGenerateClaim extends NebOverlayFormItem {
  static get config() {
    return {
      form: NebFormClaim,
      itemName: '',
      draftMessageError: 'An error occurred when saving the claim draft',
      submittingMessageError:
        'An error occurred when submitting the claim draft',
      draftMessageSuccess: 'Claim draft saved successfully',
      draftCorrectClaimSuccess:
        'Original claim canceled, corrected draft saved',
      draftRebillClaimSuccess: 'Original claim canceled, rebilled draft saved',
      generateMessageError: 'An error occurred when generating the claim',
      generateMessageSuccess: 'Claim generated successfully',
      generateMessageSuccessRebill: 'Claim rebilled successfully',
      generateMessageSuccessRebillCHC:
        'Claim rebilled successfully and is now being submitted',
      generateMessageSuccessCorrectCHC:
        'Claim corrected successfully and is now being submitted',
      generateMessageCHCQueue:
        'Claim generated successfully and is now being submitted',
      generateMessageSuccessCorrect: 'Claim corrected successfully',
      cancelMessageError: 'An error occurred when canceling the claim',
      cancelMessageSuccess: 'Claim voided successfully',
      overriddenMessageSuccess: 'Claim overridden successfully',
      claimSubmitMessageSuccess: 'Claim submitted successfully',
    };
  }

  static get properties() {
    return {
      __claimType: String,
      __formModel: Object,
      __isElectronic: Boolean,
      __validationErrors: Object,
      __clearinghouseErrors: Object,
      __otherChcErrors: Object,
      __payerPlan: Object,
      __hasAddOnCtBYOC: Boolean,
      __hasMaxClear: Boolean,
      __claimScrubbing: Boolean,
      __scrubbingErrors: Object,
      __originalClaimNumber: String,
      __singleCHCQueueFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
          overflow-y: auto;
        }

        .container-header {
          display: flex;
          justify-content: space-between;
        }

        .original-claim-link {
          padding-top: 15px;
          padding-right: 100px;
        }

        .description {
          padding: 0 ${CSS_SPACING};
        }

        .electronic-claim-description {
          white-space: pre-wrap;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = NebFormClaim.createModel();
    this.__validationErrors = {};
    this.__clearinghouseErrors = {};
    this.__otherChcErrors = {};
    this.__payerPlan = { providerSpecificValues: [] };
    this.__claimType = PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY;
    this.__hasMaxClear = false;
    this.__isElectronic = false;
    this.__claimScrubbing = false;
    this.__scrubbingErrors = {};
    this.__originalClaimNumber = '';
    this.__singleCHCQueueFF = false;

    this.model = {
      patientId: '',
      invoiceId: '',
      patientInsuranceId: '',
      type: '',
      claimId: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      save: async (
        claim,
        isUpdateOnly = false,
        overrideScrubbing = false,
        actionKey = null,
      ) => {
        let result;
        let isError = false;

        const isCHC = !!this.__payerPlan.chcPayerId;

        if (!actionKey) {
          actionKey = this.__getDraftActionKey(claim.claimStatuses);
        }

        try {
          if (
            claim.claimStatuses[0].status === CLAIM_STATUS.GENERATED ||
            (claim.claimStatuses[0].status === CLAIM_STATUS.DRAFT && !claim.id)
          ) {
            const submissionMethod = await this.__getSubmissionMethod(claim);
            claim.isElectronic =
              submissionMethod === SUBMISSION_METHOD.ELECTRONIC;
          }

          this.__resetErrors();

          result = await (claim.id
            ? updateClaim(
                {
                  claimId: claim.id,
                  claim,
                },
                this.__claimScrubbing && !overrideScrubbing,
                isCHC,
              )
            : createClaim({
                claim,
                enableScrubbing: this.__claimScrubbing && !overrideScrubbing,
                version: 5,
                actionKey,
                isCHC,
              }));

          if (result && result.scrubbingErrors) {
            const scrubbedClaim = await getClaim(result.id);

            await this.__getValidationErrors(scrubbedClaim.claimStatuses[0].id);

            isError = true;

            this.__formModel = scrubbedClaim;

            return undefined;
          }

          if (result && result.sqsError) {
            this.__formModel = await getClaim(result.id);

            this.__displayBanner({
              status: CLAIM_STATUS.SUBMITTING,
              success: false,
            });

            isError = true;

            return undefined;
          }

          if (!claim.id && claim.originalClaimId) {
            await updateClaimStatus(claim.originalClaimId, {
              status: CLAIM_STATUS.CANCELED_RESUBMIT,
            });
          }

          this.isDirty = false;

          if (isUpdateOnly) {
            return undefined;
          }

          if (
            claim.claimStatuses.length > 1 &&
            claim.claimStatuses[1].status === CLAIM_STATUS.OVERRIDDEN
          ) {
            this.__displayBanner({ status: claim.claimStatuses[1].status });
          }

          this.__displayBanner({
            status: claim.claimStatuses[0].status,
            actionKey,
          });

          if (claim.claimStatuses[0].status === CLAIM_STATUS.DRAFT) {
            return this.dismiss(result);
          }

          if (!claim.isElectronic) {
            this.__formModel = await getClaim(result.id || claim.id);
          }
        } catch (e) {
          isError = true;
          this.__displayBanner({
            status: claim.claimStatuses[0].status,
            success: false,
          });

          this.__formModel = { ...this.__formModel };
        }

        if (claim.isElectronic && !isError) {
          if (!isCanceled(claim.claimStatuses[0].status)) {
            if (!this.__payerPlan.chcPayerId || !this.__singleCHCQueueFF) {
              this.__validationErrors = await validateAndSubmitElectronicClaim(
                {
                  ...claim,
                  claimNumber: claim.claimNumber || result.claimNumber,
                  id: claim.id || result.id,
                },
                undefined,
              );
            }
          }

          this.__formModel = await getClaim(result.id || claim.id);
        }

        return undefined;
      },

      reloadData: async (refreshClaim = false, actionKey = null) => {
        if (refreshClaim) {
          await this.__refreshClaim(actionKey);
        } else {
          this.__formModel = await getClaim(this.__formModel.id);
        }
      },
      cancelResubmit: async claimId => {
        const newClaimFields = await this.__getNewClaimFields(claimId);

        this.__formModel = {
          ...newClaimFields,
          resubmissionCode: CLAIM_RESUBMISSION_CODE.CANCEL_RESUBMIT,
        };
      },

      rebillClaim: async originalClaimId => {
        const newClaimFields = await this.__getNewClaimFields(originalClaimId);

        this.__formModel = { ...newClaimFields, originalClaimId };

        const { claimNumber } = await getClaim(
          this.__formModel.originalClaimId,
        );

        this.__originalClaimNumber = claimNumber;
      },

      correctClaim: async correctClaim => {
        const newClaimFields = await this.__getNewClaimFields(
          correctClaim.claimId,
        );

        this.__formModel = {
          ...newClaimFields,
          resubmissionCode: CLAIM_RESUBMISSION_CODE.CANCEL_RESUBMIT,
          originalReferenceNumber: correctClaim.originalReferenceNumber,
        };

        const { claimNumber } = await getClaim(
          this.__formModel.originalClaimId,
        );
        this.__originalClaimNumber = claimNumber;
      },
      voidClaim: async voidedClaim => {
        const body = {
          claimId: voidedClaim.id,
          paymentResponsibilityLevelCode:
            voidedClaim.paymentResponsibilityLevelCode,
          originalReferenceNumber: voidedClaim.originalReferenceNumber,
          isElectronic: voidedClaim.isElectronic,
        };

        const claim = await voidClaim(body);

        this.__displayBanner({ status: CLAIM_STATUS.CANCELED });

        this.__formModel = claim;
      },
      scrubClaim: async claim => {
        const { invoiceId, insuranceId, id } = claim;

        const claimBody = {
          invoiceId,
          insuranceId,
          isElectronic: this.__isElectronic,
          ...(id && { claimId: id }),
        };

        try {
          const { claimIds, ...result } = await scrubClaims({
            claims: [claimBody],
          });

          const scrubbedClaim = await getClaim(claimIds[0]);

          this.__formModel = scrubbedClaim;

          if (result.successCount) {
            store.dispatch(openSuccess('Claim passed scrubbing'));

            return;
          }

          await this.__getValidationErrors(scrubbedClaim.claimStatuses[0].id);

          store.dispatch(
            openError('Claim failed scrubbing and moved to Needs Attention'),
          );
        } catch (e) {
          console.log(e);
          store.dispatch(openError('An error occurred when scrubbing claim'));
        }
      },
      clickOriginalClaim: async () => {
        const claimId = this.__formModel.originalClaimId;
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, { claimId });
      },
      clickNoteIcon: async () => {
        const datesOfService = this.__formatDatesOfService();

        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CLAIM,
          parentId: this.__formModel.id,
          parentData: {
            datesOfService,
            claimNumber: this.__formModel.claimNumber,
            amount: this.__formModel.totalChargeAmount,
            payer: `(${this.__formModel.insurancePayerAlias}) ${
              this.__formModel.insurancePayerName
            }`,
          },
          patientId: this.__formModel.patientId,
        });

        if (result) {
          this.__formModel = await getClaim(this.__formModel.id);
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasMaxClear = (await hasByocAddons()).CT_MAXCLEAR;

    this.__singleCHCQueueFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.SINGLE_CHC_QUEUE,
    );

    this.__claimScrubbing = (await getClaimScrubbing()).enabled || false;

    if (this.__formModel.originalClaimId) {
      const { claimNumber } = await getClaim(this.__formModel.originalClaimId);
      this.__originalClaimNumber = claimNumber;
    }
  }

  __resetErrors() {
    this.__scrubbingErrors = {};
    this.__validationErrors = {};
  }

  async __refreshClaim(actionKey = null) {
    try {
      this.__formModel = await refreshClaimData(
        this.__formModel.id,
        2,
        actionKey,
      );

      this.__resetErrors();

      store.dispatch(openSuccess('Claim refreshed successfully'));
    } catch (e) {
      if (e.message.includes('generation of primary claims.')) {
        store.dispatch(openError(NO_CLAIMS_ERROR_PRIMARY));
      } else if (e.message.includes('generation of secondary claims.')) {
        store.dispatch(openError(NO_CLAIMS_ERROR_SECONDARY));
      } else {
        console.error(e);
        store.dispatch(openError(errorOccurred('refreshing', 'claim')));
      }
    }
  }

  async __getNewClaimFields(claimId) {
    const {
      patientId,
      insuranceId,
      paymentResponsibilityLevelCode,
      originalReferenceNumber,
      invoiceId,
    } = this.__formModel;

    let { type } = this.__formModel;
    if (!type) type = this.__formModel.paymentResponsibilityLevelCode;

    const claim = await getClaimFields({
      patientId,
      invoiceId,
      patientInsuranceId: insuranceId,
      type,
    });

    return {
      ...claim,
      patientId,
      insuranceId,
      paymentResponsibilityLevelCode,
      originalClaimId: claimId,
      originalReferenceNumber,
    };
  }

  __getDraftActionKey(claimStatuses) {
    if (claimStatuses.length > 1 && !claimStatuses[0].id) {
      return getUserActionKeyByDescription(claimStatuses[1].description);
    }

    return null;
  }

  __getMessageSuccessDraft(actionKey) {
    switch (actionKey) {
      case USER_ACTION_KEY.CORRECT_CLAIM:
        return this.constructor.config.draftCorrectClaimSuccess;
      case USER_ACTION_KEY.REBILL_CLAIM:
        return this.constructor.config.draftRebillClaimSuccess;
      default:
        return this.constructor.config.draftMessageSuccess;
    }
  }

  __getMessageSuccessGenerated(actionKey) {
    const isCHCEnabled = this.__payerPlan.chcPayerId && this.__singleCHCQueueFF;

    if (CLAIM_STATUS.GENERATED && isCHCEnabled && !actionKey) {
      actionKey = USER_ACTION_KEY.CHC_GENERATE;
    }

    switch (actionKey) {
      case USER_ACTION_KEY.CORRECT_CLAIM:
        return isCHCEnabled
          ? this.constructor.config.generateMessageSuccessCorrectCHC
          : this.constructor.config.generateMessageSuccessCorrect;

      case USER_ACTION_KEY.REBILL_CLAIM:
        return isCHCEnabled
          ? this.constructor.config.generateMessageSuccessRebillCHC
          : this.constructor.config.generateMessageSuccessRebill;

      case USER_ACTION_KEY.CHC_GENERATE:
        return this.constructor.config.generateMessageCHCQueue;

      default:
        return this.constructor.config.generateMessageSuccess;
    }
  }

  __displayBanner({ status, success = true, actionKey = null }) {
    let message;

    if (success) {
      switch (status) {
        case CLAIM_STATUS.GENERATED:
          message = this.__getMessageSuccessGenerated(actionKey);
          break;
        case CLAIM_STATUS.CANCELED:
          message = this.constructor.config.cancelMessageSuccess;
          break;
        case CLAIM_STATUS.OVERRIDDEN:
          message = this.constructor.config.overriddenMessageSuccess;
          break;
        default:
          message = this.__getMessageSuccessDraft(actionKey);
          break;
      }

      store.dispatch(openSuccess(message));
    } else {
      switch (status) {
        case CLAIM_STATUS.GENERATED:
          message = this.constructor.config.generateMessageError;
          break;
        case CLAIM_STATUS.CANCELED:
          message = this.constructor.config.cancelMessageError;
          break;
        case CLAIM_STATUS.SUBMITTING:
          message = this.constructor.config.submittingMessageError;
          break;
        default:
          message = this.constructor.config.draftMessageError;
          break;
      }

      store.dispatch(openError(message));
    }
  }

  async __getPayerPlan({ patientId, insuranceId }) {
    const insurance = await getPatientInsurance(
      patientId,
      insuranceId,
      undefined,
      true,
    );

    return insurance.payerPlan;
  }

  __formatDatesOfService() {
    const dateOfAllServices = Array.from(
      new Set(this.__formModel.claimCharges.map(item => item.dateOfService)),
    );

    const dateOfService = dateOfAllServices.map(x =>
      parseDate(x).startOf('day'),
    );

    if (dateOfService && dateOfService.length > 1) {
      const minDate = moment.min(dateOfService).format('MM/DD/YYYY');

      const maxDate = moment.max(dateOfService).format('MM/DD/YYYY');

      return `${minDate} - ${maxDate}`;
    }

    return dateOfService[0].format('MM/DD/YYYY');
  }

  async __getSubmissionMethod(claim) {
    const payerPlan = await this.__getPayerPlan(claim);

    if (payerPlan) {
      return this.__claimType === PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY
        ? payerPlan.submissionMethodPrimary
        : payerPlan.submissionMethodSecondary;
    }

    return SUBMISSION_METHOD.PAPER;
  }

  async __getValidationErrors(claimStatusId) {
    const claimErrors = await claimsValidationErrorsApi.fetchMany(
      claimStatusId,
      true,
    );

    this.__validationErrors = {
      errorType: 'CHCValidation',
      errors: claimErrors.filter(ce => !isScrubbingError(ce)),
    };

    this.__scrubbingErrors = claimErrors.reduce((acc, ce) => {
      if (!isScrubbingError(ce)) return acc;

      acc[ce.code] = ce;

      if (ce.scrubbingDetails) {
        acc[ce.code].scrubbingDetails = JSON.parse(ce.scrubbingDetails);
      }

      return acc;
    }, {});
  }

  createModel() {
    return this.constructor.config.form.createModel();
  }

  getTitle() {
    return this.__formModel.claimNumber
      ? `Claim ${
          this.__formModel.claimStatuses[0].status === 'Draft' ? 'Draft ' : ''
        }(${this.__formModel.claimNumber})`
      : 'Add New Claim';
  }

  async firstUpdated() {
    const model = await (this.model.claimId
      ? getClaim(this.model.claimId)
      : getClaimFields({
          patientId: this.model.patientId,
          invoiceId: this.model.invoiceId,
          patientInsuranceId: this.model.patientInsuranceId,
          type: this.model.type,
        }));

    if (
      model.claimStatuses[0].status === CLAIM_STATUS.VALIDATION_ERROR &&
      !this.__validationErrors.length
    ) {
      await this.__getValidationErrors(model.claimStatuses[0].id);
    }
    this.__formModel = model;

    this.__claimType =
      this.model.type || this.__formModel.paymentResponsibilityLevelCode;

    this.__payerPlan = await this.__getPayerPlan(this.__formModel);

    this.__isElectronic = this.__payerPlan
      ? this.__payerPlan.submissionMethodPrimary ===
        SUBMISSION_METHOD.ELECTRONIC
      : false;

    if (this.__formModel.originalClaimId) {
      const { claimNumber } = await getClaim(this.__formModel.originalClaimId);
      this.__originalClaimNumber = claimNumber;
    }
    super.firstUpdated();
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        <span
          >${isEditable(this.__formModel.claimStatuses[0].status)
            ? 'Review the claim information for the selected invoice. The information corresponds to the Field Location of the CMS 1500 Form.'
            : 'Review the previously generated claim information. Print, Resubmit, or Update Claim Status.'}</span
        >

        <span
          id="${ELEMENTS.electronicClaimDescription.id}"
          class="electronic-claim-description"
          >${this.__isElectronic
            ? '\nGenerating the claim will also submit the claim (E-Claim only).'
            : ''}</span
        >
      </div>
    `;
  }

  __renderOriginalClaimLink() {
    const TEXT_LINK_ORIGINAL_CLAIM = `Original Claim (${
      this.__originalClaimNumber
    })`;

    return this.__formModel.originalClaimId
      ? html`
          <neb-text
            id="${ELEMENTS.originalClaimLink.id}"
            class="original-claim-link"
            link
            header
            bold
            .onClick="${this.handlers.clickOriginalClaim}"
            >${TEXT_LINK_ORIGINAL_CLAIM}</neb-text
          >
        `
      : '';
  }

  renderForm() {
    return html`
      <div class="container-header">
        ${this.__renderDescription()} ${this.__renderOriginalClaimLink()}
      </div>

      <neb-form-claim
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .isElectronic="${this.__isElectronic}"
        .validationErrors="${this.__validationErrors}"
        .scrubbingErrors="${this.__scrubbingErrors}"
        .clearinghouseErrors="${this.__clearinghouseErrors}"
        .otherChcErrors="${this.__otherChcErrors}"
        .confirmLabel="Save Draft"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onRefresh="${this.handlers.reloadData}"
        .onCancelResubmit="${this.handlers.cancelResubmit}"
        .onRebill="${this.handlers.rebillClaim}"
        .onCorrectClaim="${this.handlers.correctClaim}"
        .onVoidClaim="${this.handlers.voidClaim}"
        .onScrubClaim="${this.handlers.scrubClaim}"
        .type="${this.__claimType}"
        .payerPlan="${this.__payerPlan}"
        .hasMaxClear="${this.__hasMaxClear}"
      ></neb-form-claim>
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismiss}"
        ?showBackButton="${this.showBackButton}"
        .onClickNoteIcon="${this.handlers.clickNoteIcon}"
        ?showAddNoteIcon="${!this.__formModel.hasNotes &&
        this.__formModel.claimNumber}"
        ?showEditNoteIcon="${!!this.__formModel.hasNotes &&
        this.__formModel.claimNumber}"
        showCancelButton
      ></neb-popup-header>

      ${this.renderForm()}
    `;
  }
}

customElements.define(
  'neb-overlay-ledger-generate-claim',
  NebOverlayLedgerGenerateClaim,
);
