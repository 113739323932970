import '../inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import { LitElement, html, css } from 'lit';

import {
  DAY_INT,
  DAY_NAME,
} from '../../../../packages/neb-api-client/src/rooms-api-client';

import RoomAvailabilityItem from './neb-room-availability-item';

export const ELEMENTS = {
  enabledCheckbox: { id: 'checkbox-enabled' },
  addItem: { id: 'add-segment-button' },
  segments: { selector: '[id^=segment-]' },
};

export default class RoomAvailabilityList extends LitElement {
  static get properties() {
    return {
      name: String,
      label: String,
      model: Object,
      errors: Object,
      appointmentTypes: Array,
    };
  }

  static get styles() {
    return [
      css`
        .container {
          display: grid;
          grid-template-columns: 1fr 9fr;
          grid-template-rows: 1fr;
        }

        .span-2 {
          grid-column: 1 / span 2;
          padding-bottom: 10px;
          width: fit-content;
        }

        .spacer {
          height: 40px;
        }
      `,
    ];
  }

  static createModel(day = '1') {
    return {
      day,
      enabled: false,
      segments: [],
    };
  }

  static createSelectors(appointmentTypes) {
    return {
      children: {
        day: {
          format: v => DAY_NAME[v],
          unformat: v => DAY_INT[v],
        },
        segments: {
          createItem: () => RoomAvailabilityItem.createModel(),
          children: {
            $: RoomAvailabilityItem.createSelectors(appointmentTypes),
          },
        },
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.label = '';
    this.model = RoomAvailabilityList.createModel();
    this.enabled = true;

    this.errors = {
      day: '',
      enabled: '',
      segments: [
        {
          start: '',
          end: '',
          slotInterval: null,
          multiBookingCount: null,
          appointmentTypes: [],
        },
      ],
    };

    this.appointmentTypes = [];

    this.onChange = () => {};

    this.onChangeTypes = () => {};

    this.onRemove = () => {};

    this.onAddItem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addItem: () => {
        this.onAddItem(`${this.name}.segments`);
      },
      remove: name => {
        const index = Number(name.split('.')[1]);

        this.onRemove(`${this.name}.segments`, index);
      },
      change: e => {
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
      changeTypes: e => {
        this.onChangeTypes({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
    };
  }

  renderSegments() {
    return this.model.segments.map(
      (segment, index) => html`
        <neb-room-availability-item
          id="segment-${index}"
          .name="segments.${index}"
          .model="${segment}"
          .appointmentTypes="${this.appointmentTypes}"
          .errors="${this.errors.segments[index]}"
          .enabled="${this.model.enabled || this.enabled}"
          .onChange="${this.__handlers.change}"
          .onChangeTypes="${this.__handlers.changeTypes}"
          .onRemove="${this.__handlers.remove}"
        ></neb-room-availability-item>
      `,
    );
  }

  render() {
    return html`
      <div class="container">
        <neb-checkbox
          id="${ELEMENTS.enabledCheckbox.id}"
          class="checkbox"
          name="enabled"
          .label="${this.label}"
          .checked="${this.model.enabled}"
          .onChange="${this.__handlers.change}"
        ></neb-checkbox>
        <div>
          ${this.model.enabled
            ? this.renderSegments()
            : html` <div class="spacer"></div> `}
        </div>
        <neb-button-action
          id="${ELEMENTS.addItem.id}"
          class="span-2"
          label="Add New Row"
          .onClick="${this.__handlers.addItem}"
          ?disabled="${!this.model.enabled}"
        ></neb-button-action>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-room-availability-list',
  RoomAvailabilityList,
);
