import '../inputs/neb-textfield';
import '../inputs/neb-select';

import { html } from 'lit';

import { IDENTIFIERS } from '../../../../neb-utils/enums';
import { getValueByPath } from '../../../../neb-utils/utils';

import { ELEMENTS as BASE_ELEMENTS, Item } from './neb-item';

const ITEM_HEIGHT = 64;
export const ELEMENTS = { ...BASE_ELEMENTS };

const EMPTY_ERRORS = {
  number: '',
  type: {
    value: '',
    label: '',
  },
};

export default class IdItem extends Item {
  static get properties() {
    return {
      model: Object,
      errors: Object,
    };
  }

  static createModel() {
    return {
      number: '',
      type: {
        value: '',
        label: '',
      },
    };
  }

  static createSelectors() {
    const validator = {
      error: 'Required',
      validate: (v, keyPath, state) => {
        const numberPath = [...keyPath.slice(0, keyPath.length - 1), 'number'];
        const number = getValueByPath(state, numberPath);

        return !number || v.value;
      },
    };

    return {
      children: {
        number: {
          clipPristine: true,
          validators: [
            {
              error: 'Required',
              validate: (v, keyPath, state) => {
                const numberPath = [
                  ...keyPath.slice(0, keyPath.length - 1),
                  'type',
                ];
                const type = getValueByPath(state, numberPath);

                return !type.value || v;
              },
            },
          ],
        },
        type: {
          clipPristine: true,
          validators: [validator],
        },
      },
    };
  }

  initState() {
    super.initState();
    this.model = this.constructor.createModel();
    this.errors = EMPTY_ERRORS;
  }

  renderLeftContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.leftContent.id}"
        class="field"
        maxLength="50"
        name="number"
        label="Other Identifier"
        helper=" "
        .value="${this.model.number}"
        .error="${this.errors.number}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  renderRightContent() {
    return html`
      <neb-select
        id="${ELEMENTS.rightContent.id}"
        class="field field-selector"
        name="type"
        label="Other Identifier Type"
        helper=" "
        .items="${Object.keys(IDENTIFIERS).map(type => IDENTIFIERS[type])}"
        .value="${this.model.type}"
        .error="${this.errors.type}"
        .onChange="${this.handlers.change}"
        .itemHeight="${ITEM_HEIGHT}"
        wrapText
      ></neb-select>
    `;
  }
}

window.customElements.define('neb-id-item', IdItem);
