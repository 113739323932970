import { PageSizes } from 'pdf-lib';

import { DOCUMENT_TYPES } from '../enums/scan-enums';

const objectToObjectArray = obj => {
  const items = Object.entries(obj).map(([key, value]) => ({
    id: key,
    name: value,
  }));

  return items;
};
export const mapToScannerConfigType = model => ({
  id: model.id,
  name: model.name,
});

export const mapToDocumentSources = documentSourcesObject => {
  const items = Object.values(documentSourcesObject).map(item => ({
    id: item.id,
    name: item.name,
    resolutions: objectToObjectArray(item.resolutionIds),
    colors: objectToObjectArray(item.pixelTypeIds),
    pageSizes: objectToObjectArray(item.pageSizeIds),
    duplexes: objectToObjectArray(item.duplexIds),
  }));

  return items;
};

export const mapToDocumentSourceLists = documentSource => ({
  resolutions: documentSource.resolutions,
  colors: documentSource.colors,
  pageSizes: documentSource.pageSizes,
  duplexes: documentSource.duplexes,
  documentTypes: [...DOCUMENT_TYPES],
});

export const mapScanToPdfPageSize = pageSizeName => {
  if (!pageSizeName) {
    return PageSizes.Letter;
  }

  const pageSizeNameLowerCase = pageSizeName.toLowerCase();

  if (pageSizeNameLowerCase.includes('letter')) {
    return PageSizes.Letter;
  }

  if (pageSizeNameLowerCase.includes('legal')) {
    return PageSizes.Legal;
  }

  if (pageSizeNameLowerCase.includes('executive')) {
    return PageSizes.Executive;
  }

  if (Object.prototype.hasOwnProperty.call(PageSizes, pageSizeName)) {
    return PageSizes[pageSizeName];
  }

  return PageSizes.Letter;
};
