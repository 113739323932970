import { html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../neb-text';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  patientLinks: { selector: '[id^=patient-]' },
  batchLinks: { selector: '[id^=batch-]' },
  superbillNumbers: { selector: '[id^=superbill-]' },
};

const EMPTY_STATEMENT_MESSAGE =
  'There are no superbills available for this patient.';

class NebTableLedgerSuperbills extends NebTable {
  static get properties() {
    return {
      showPatientColumn: Boolean,
    };
  }

  initState() {
    super.initState();

    this.showPatientColumn = false;
    this.emptyMessage = EMPTY_STATEMENT_MESSAGE;

    this.onSelectPatient = () => {};

    this.onSelectBatch = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return this.onSelectPatient({ patientId });
      },
      clickBatch: value => this.onSelectBatch(value.name),
      openBillingNotesOverlay: async e => {
        const rowIndex = e.name;

        const patientName = this.__removePreferredName(
          this.model[rowIndex].patientName,
        );

        if (e.id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
            parentType: BILLING_NOTE_TYPES.SUPERBILL,
            parentId: this.model[rowIndex].id,
            patientId: this.model[rowIndex].patientId,
            parentData: {
              transactionDate: parseDate(this.model[rowIndex].createdAt)
                .startOf('day')
                .format('MM/DD/YYYY'),
              superbill: this.model[rowIndex].superbillNumber,
              amount: this.model[rowIndex].totalCharges,
              payer: patientName,
            },
          });

          if (result) {
            const updatedItem = this.model[rowIndex];

            updatedItem.hasBillingNote = result !== true;

            this.onChange(updatedItem);
          }
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING}));
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  __removePreferredName(name) {
    if (name.includes('(')) name = name.substring(0, name.indexOf('('));
    return name;
  }

  __renderPatientCell(value, rowIndex) {
    return html`
      <div
        id="patient-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderBatchCell(value, rowIndex) {
    return this.model[rowIndex].batchId
      ? html`
          <neb-text
            id="batch-link-${rowIndex}"
            name="${value}"
            .onClick="${this.handlers.clickBatch}"
            bold
            link
            >B:${value}</neb-text
          >
        `
      : '';
  }

  __renderSuperbillCell({ value, rowIndex }) {
    if (this.model[rowIndex].superbillNumber) {
      return html`
        <neb-text
          id="superbill-id-${rowIndex}"
          trailingIcon="${this.model[rowIndex].hasBillingNote
            ? 'neb:editNote'
            : 'neb:addNote'}"
          .name="${rowIndex}"
          .onClick="${this.handlers.openBillingNotesOverlay}"
          >${value}</neb-text
        >
      `;
    }
    return '';
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patientName':
        return this.__renderPatientCell(value, rowIndex);
      case 'batchId':
        return this.__renderBatchCell(value, rowIndex);
      case 'superbillNumber':
        return this.__renderSuperbillCell({ value, rowIndex });
      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

customElements.define('neb-table-ledger-superbills', NebTableLedgerSuperbills);
