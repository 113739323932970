import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import { css, html } from 'lit';
import { map } from 'lit/directives/map.js';

import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../../../../packages/neb-lit-components/src/components/neb-text';
import NebTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import { currency } from '../../../../packages/neb-utils/masks';

export const ELEMENTS = {
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
  statementIds: {
    selector: '[id^=statement-id-]',
  },
  statementButtons: {
    selector: '[id^=statement-button-]',
  },
};

const CONFIG = [
  {
    sortable: true,
    truncate: true,
    key: 'patientName',
    label: 'Patient',
    flex: css`1.5 0 0`,
  },
  {
    sortable: true,
    truncate: true,
    key: 'medicalRecordNumber',
    label: 'MRN',
    flex: css`0.5 0 0`,
  },
  {
    sortable: true,
    truncate: true,
    key: 'statementId',
    label: 'Statement ID',
    flex: css`1 0 0`,
  },
  {
    sortable: true,
    key: 'groupStatements',
    label: 'Grouped by Relationship',
    flex: css`0.8 0 0`,
    formatter: value => (value ? 'Yes' : 'No'),
  },
  {
    sortable: true,
    truncate: true,
    key: 'groupGuarantorName',
    label: 'Guarantor',
    flex: css`1.5 0 0`,
  },
  {
    sortable: true,
    truncate: true,
    key: 'balance',
    label: 'Balance',
    flex: css`0.8 0 0`,
    maxLength: 11,
    mask: currency,
    formatter: amount => (amount ? centsToCurrency(amount) : '$0.00'),
  },
  {
    key: 'action-buttons',
    label: '',
    flex: css`1.3 0 0`,
  },
];

class NebTableStatementBatchPreview extends NebTable {
  static get properties() {
    return {
      excludedFromBatch: Boolean,
      __availableActionButtons: Array,
    };
  }

  initState() {
    super.initState();
    this.config = CONFIG;

    this.onSelectPatient = () => {};

    this.onPrintStatement = () => {};

    this.onAddOrRemoveStatement = () => {};

    this.excludedFromBatch = false;

    this.__availableActionButtons = [
      {
        icon: () => 'preview',
        action: 'view',
      },
      {
        icon: () => 'minus',
        action: 'remove',
      },
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return this.onSelectPatient(patientId);
      },
      openBillingNotesOverlay: async e => {
        const rowIndex = e.name;

        if (e.id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
            parentType: BILLING_NOTE_TYPES.STATEMENT,
            parentId: this.model[rowIndex].id,
            patientId: this.model[rowIndex].patientId,
            parentData: {
              transactionDate: parseDate(this.model[rowIndex].createdAt)
                .startOf('day')
                .format('MM/DD/YYYY'),
              statement: this.model[rowIndex].statementId,
              amount: this.model[rowIndex].balance,
              payer: this.model[rowIndex].patientName,
            },
          });

          if (result) {
            this.onChange();
          }
        }
      },
      view: e => this.onPrintStatement(this.__getId(e)),
      remove: e => this.onAddOrRemoveStatement(this.__getId(e), true),
      add: e => this.onAddOrRemoveStatement(this.__getId(e), false),
    };
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('excludedFromBatch')) {
      if (this.excludedFromBatch) {
        this.__availableActionButtons = [
          this.__availableActionButtons[0],
          { icon: () => 'plus', action: 'add' },
        ];
      } else {
        this.__availableActionButtons = [
          this.__availableActionButtons[0],
          {
            icon: () => 'minus',
            action: 'remove',
          },
        ];
      }
    }
  }

  __getId(item) {
    const { id } = item;
    const index = Number(id.split('-')[2]);
    return this.model[index].id;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        #header {
          padding-top: ${CSS_SPACING};
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .container {
          margin-bottom: 66px;
        }

        .action-button {
          margin-left: 10px;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patientName':
        return this.__renderPatientCell({ value, rowIndex });
      case 'statementId':
        return this.__renderStatementCell({ value, rowIndex });
      case 'action-buttons':
        return this.__renderButtons(rowIndex);
      default:
        return super.renderDataCell(value, columnConfig);
    }
  }

  __renderPatientCell({ value, rowIndex }) {
    return html`
      <div
        id="patient-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderButtons(rowIndex) {
    return map(this.__availableActionButtons, (button, i) => {
      const label = `${button.action
        .charAt(0)
        .toUpperCase()}${button.action.slice(1)}`;
      return html`
        <neb-button-action
          id="statement-button-${rowIndex}-${i}"
          class="action-button"
          .leadingIcon="${button.icon()}"
          .label="${label}"
          .onClick="${this.handlers[`${button.action}`]}"
        ></neb-button-action>
      `;
    });
  }

  __renderStatementCell({ value, rowIndex }) {
    if (!this.model[rowIndex].statementId) return '';

    return html`
      <neb-text
        id="statement-id-${rowIndex}"
        trailingIcon="${this.model[rowIndex].hasBillingNote
          ? 'neb:editNote'
          : 'neb:addNote'}"
        .name="${rowIndex}"
        .onClick="${this.handlers.openBillingNotesOverlay}"
        >${value}</neb-text
      >
    `;
  }
}

customElements.define(
  'neb-table-statement-batch-preview',
  NebTableStatementBatchPreview,
);
