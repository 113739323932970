import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';

import './neb-pill';

export const ELEMENTS = {
  pills: {
    selector: 'neb-pill',
  },
};

class NebTagsList extends LitElement {
  static get properties() {
    return {
      model: Object,
    };
  }

  constructor() {
    super();

    this.model = {
      tags: [],
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
        }

        .container .tag:not(:last-of-type) {
          margin-right: 5px;
        }

        .tag {
          margin: 2px 0;
          --max-width: unset;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        ${this.model.tags.map(
          ({ tag }) => html`
            <neb-pill
              class="tag"
              style="--background-color: ${tag.color}"
              readonly
              >${tag.name}</neb-pill
            >
          `,
        )}
      </div>
    `;
  }
}
customElements.define('neb-tags-list', NebTagsList);
