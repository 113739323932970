import { css, html } from 'lit';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../../../../packages/neb-utils/formatters';
import { formatScheduleType } from '../../../../../packages/neb-utils/neb-payment-util';
import { CSS_SPACING } from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../../packages/neb-lit-components/src/components/neb-text';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  iconRefresh: { id: 'button-icon-refresh' },
  iconChevron: { id: 'button-icon-chevron' },
  iconDismiss: { id: 'button-icon-dismiss' },
};

export const TABLE_ACTION = Object.freeze({
  CHEVRON: 'chevron',
  REFRESH: 'refresh',
  DISMISS: 'dismiss',
});

export const CONFIG = [
  {
    sortable: true,
    key: 'patientName',
    label: 'Patient',
    flex: css`1 0 0`,
  },
  {
    key: 'scheduleType',
    label: 'Type',
    flex: css`1 0 0`,
    formatter: v => `${formatScheduleType(v)}`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    sortable: true,
    key: 'scheduled',
    label: 'Scheduled',
    flex: css`1 0 0`,
    formatter: v => parseDate(v).startOf('day').format('MM/DD/YYYY'),
  },
  {
    key: 'card',
    label: 'Card',
    flex: css`1 0 0`,
  },
  {
    key: 'message',
    label: 'Description',
    flex: css`2 0 0`,
  },
  {
    label: '',
    flex: css`1 0 0`,
  },
];
class NebTableFailedPayments extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 100%;
        }

        .row[preview] {
          padding: 0 ${CSS_SPACING};
        }

        .cell-header {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .label {
          margin-right: 5px;
        }

        .button-icons {
          display: inline-flex;
          width: 25px;
          height: 25px;
          margin-left: 15px;
        }

        .chevron-rotation {
          width: 17px;
          transform: rotate(-90deg);
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.config = CONFIG;

    this.onSelectActionIcon = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickActionIcon: (action, rowIndex) =>
        this.onSelectActionIcon(action, this.model[rowIndex]),
    };
  }

  __getDismissIcon(item) {
    return item.dismissedAt ? 'neb:visible' : 'neb:visibilityOff';
  }

  __renderRefresh(rowIndex) {
    return html`
      <neb-button-icon
        class="button-icons"
        id="${ELEMENTS.iconRefresh.id}-${rowIndex}"
        icon="neb:refresh"
        .onClick="${() =>
          this.handlers.clickActionIcon(TABLE_ACTION.REFRESH, rowIndex)}"
      ></neb-button-icon>
    `;
  }

  __renderChevron(rowIndex) {
    return html`
      <neb-button-icon
        class="chevron-rotation button-icons"
        id="${ELEMENTS.iconChevron.id}-${rowIndex}"
        icon="neb:chevron"
        .onClick="${() =>
          this.handlers.clickActionIcon(TABLE_ACTION.CHEVRON, rowIndex)}"
      ></neb-button-icon>
    `;
  }

  __renderDismiss(rowIndex) {
    return html`
      <neb-button-icon
        class="button-icons"
        id="${ELEMENTS.iconDismiss.id}-${rowIndex}"
        icon="${this.__getDismissIcon(this.model[rowIndex])}"
        .onClick="${() =>
          this.handlers.clickActionIcon(TABLE_ACTION.DISMISS, rowIndex)}"
      ></neb-button-icon>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.label) {
      case '':
        return html`
          ${this.__renderRefresh(rowIndex)}
          ${this.__renderChevron(rowIndex)}${this.__renderDismiss(rowIndex)}
        `;
      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}
window.customElements.define(
  'neb-table-failed-payments',
  NebTableFailedPayments,
);
