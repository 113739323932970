import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { centsToCurrency } from '../../../../../neb-utils/formatters';

import { formatAdjustmentAmount } from './charges/neb-ledger-charges-util';

export const ELEMENTS = {
  subtotal: {
    id: 'subtotal',
  },
  adjustments: {
    id: 'adjustments',
  },
  tax: {
    selector: '.tax',
  },
  taxName: {
    selector: '.tax-name',
  },
  total: {
    id: 'total',
  },
};
class NebLedgerPurchaseTotalSection extends LitElement {
  static get properties() {
    return {
      calculations: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.calculations = {};
  }

  __renderTaxes() {
    return this.calculations.taxInfo.taxes.map(
      t => html`
        <div class="tax-name">
          ${t.name} - ${t.rate}% on ${centsToCurrency(t.value)}
        </div>
        <div class="tax text">${centsToCurrency(t.taxedValue)}</div>
      `,
    );
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          padding-top: ${CSS_SPACING};
          display: block;
        }

        .container {
          display: flex;
        }

        .content {
          display: grid;
          grid-template-columns: auto auto;
          text-align: right;
          column-gap: ${CSS_SPACING};
          width: fit-content;
          padding-right: ${CSS_SPACING};
        }

        .border {
          margin: 10px 0;
          border-bottom: ${CSS_BORDER_GREY_2};
          grid-column: span 2;
        }

        .text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .spacer {
          display: flex;
          flex: 1 0 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <div class="spacer"></div>
        <div class="content">
          <div>Subtotal</div>
          <div id="${ELEMENTS.subtotal.id}" class="text">
            ${centsToCurrency(this.calculations.subtotal)}
          </div>
          ${this.__renderTaxes()}
          <div>Adjustments</div>
          <div id="${ELEMENTS.adjustments.id}" class="text">
            -${formatAdjustmentAmount(this.calculations.adjustments)}
          </div>
          <div class="border"></div>
          <div class="text">Total</div>
          <div id="${ELEMENTS.total.id}" class="text">
            ${this.calculations.totalDisplay}
          </div>
        </div>
      </div>
    `;
  }
}
customElements.define(
  'neb-ledger-purchase-total-section',
  NebLedgerPurchaseTotalSection,
);
