import { matchRouteSwitch, navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { CARDS } from '../../../../packages/neb-lit-components/src/components/claims/neb-claims-worklist-cards';
import { LAYOUT_TYPE } from '../../../../packages/neb-redux/services/layout';
import { CSS_COLOR_WHITE, baseStyles } from '../../../styles';
import '../../../../packages/neb-lit-components/src/components/claims/neb-claims-worklist-controller';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../pages/ledger/neb-page-all-claims';

export const ELEMENTS = {
  tabs: { id: 'tabs' },
  claimsWorklistController: { id: 'claims-worklist-controller' },
  pageAllClaims: { id: 'page-all-claims' },
};

export const TABS = {
  CLAIMS_WORKLIST: 'claims-worklist',
  ALL_CLAIMS: 'all-claims',
};

class NebLedgerClaimsController extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      patientId: String,
      layout: String,
      route: String,
      tabOpts: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          flex: 1 0 0;
        }

        .tabs {
          padding-top: 10px;
          flex: 0 0 auto;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.route = '';
    this.patientId = '';

    this.__navItems = [];
    this.__selectedTab = '';
    this.tabOpts = {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: (tab, opts) => {
        this.tabOpts = opts;
        this.__selectedTab = tab;
      },
    };
  }

  __getWorklistCard() {
    const card = this.route.split('/worklist/')[1];
    return card || CARDS.READY.name;
  }

  __getBaseRoute() {
    return this.patientId
      ? `/patients/${this.patientId}/claims`
      : '#/practice-ledger/claims';
  }

  __buildRoute(tab) {
    return tab === TABS.ALL_CLAIMS
      ? `${this.__getBaseRoute()}/all-claims`
      : `${this.__getBaseRoute()}/worklist/${this.__getWorklistCard()}`;
  }

  updated(changedProps) {
    if (changedProps.has('__selectedTab') && this.__selectedTab) {
      navigate(this.__buildRoute(this.__selectedTab));
    } else if (this.layout !== LAYOUT_TYPE.LARGE) {
      this.__selectedTab = TABS.CLAIMS_WORKLIST;
    } else {
      const tab = this.route.slice('/');
      this.__selectedTab = tab.includes(TABS.ALL_CLAIMS)
        ? TABS.ALL_CLAIMS
        : TABS.CLAIMS_WORKLIST;
    }

    super.updated(changedProps);
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  genNavItems() {
    return [
      {
        id: TABS.CLAIMS_WORKLIST,
        exact: false,
        label: 'Claims Worklist',
        name: 'claims-worklist',
        permission: 'billing',
        path: '/worklist/',
        resolver: tail => html`
          <neb-claims-worklist-controller
            id="${ELEMENTS.claimsWorklistController.id}"
            .layout="${this.layout}"
            .route="${tail}"
            .baseRoute="${this.patientId
              ? `#/patients/${this.patientId}/claims/worklist/`
              : '#/practice-ledger/claims/worklist/'}"
            .patientId="${this.patientId ? this.patientId : ''}"
            .onSelect="${this.__handlers.selectTab}"
          >
          </neb-claims-worklist-controller>
        `,
      },
      ...(this.layout === LAYOUT_TYPE.LARGE
        ? [
            {
              id: TABS.ALL_CLAIMS,
              exact: false,
              label: 'All Claims',
              name: 'all-claims',
              permission: 'billing',
              path: '/all-claims/',
              resolver: () => html`
                <neb-page-all-claims
                  id="${ELEMENTS.pageAllClaims.id}"
                  .patientId="${this.patientId ? this.patientId : ''}"
                  .filterDefaults="${this.tabOpts ? this.tabOpts : {}}"
                ></neb-page-all-claims>
              `,
            },
          ]
        : []),
    ];
  }

  render() {
    this.__navItems = this.genNavItems();

    return html`
      ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-ledger-claims-controller',
  NebLedgerClaimsController,
);
