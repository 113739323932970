import { buildSelectedLocation } from './location-util';

const isObjectEmpty = obj => !obj || !Object.keys(obj).length;

// eslint-disable-next-line complexity
export const buildPracticeInformation = ({
  practiceInformation,
  selectedLocation = null,
  billingInfo = {},
}) => {
  const useSelectedLocation = !isObjectEmpty(selectedLocation);

  let selectedLocationInfo = {};

  if (useSelectedLocation) {
    selectedLocationInfo = buildSelectedLocation(selectedLocation);
  }

  const name =
    selectedLocationInfo.name ||
    practiceInformation.name ||
    practiceInformation.practiceName;

  const organizationNPI =
    selectedLocationInfo.useBillingNPI && billingInfo?.organizationNPI
      ? billingInfo.organizationNPI
      : (selectedLocation?.organizationNPI ?? null);

  const taxId =
    selectedLocationInfo.useBillingTaxId && billingInfo?.taxId
      ? billingInfo.taxId
      : (selectedLocation?.taxId ?? null);

  return {
    ...practiceInformation,
    name,
    practiceName: name,
    ...(useSelectedLocation ? selectedLocationInfo : {}),
    organizationNPI,
    taxId,
    id: practiceInformation.id,
  };
};
