import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { layoutStyles } from '../../../styles';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textfieldPassword: {
    id: 'textfield-password',
  },
  textfieldConfirmPassword: {
    id: 'textfield-confirm-password',
  },
};

export class PopupPassword extends NebPopup {
  static get properties() {
    return {
      __errors: Object,
    };
  }

  static createModel() {
    return {
      password: '',
      confirmPassword: '',
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        :host {
          width: 390px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.title = 'Password';

    this.__errors = {
      password: '',
      confirmPassword: '',
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      ...this.__handlers,
      confirm: () => {
        if (this.__validate()) this.onClose(this.model.password);
      },
      cancel: () => this.onClose(false),
      change: e => {
        this.model[e.name] = e.value;
      },
    };
  }

  __validate() {
    this.__errors = {
      password:
        this.model.password &&
        this.model.confirmPassword === this.model.password
          ? ''
          : 'Required',
      confirmPassword:
        this.model.confirmPassword &&
        this.model.confirmPassword === this.model.password
          ? ''
          : 'Required',
    };

    return !Object.values(this.__errors).some(item => !!item);
  }

  renderContent() {
    return html`
      <div class="grid grid-lean">
        <neb-textfield
          id="${ELEMENTS.textfieldPassword.id}"
          name="password"
          label="Password"
          helper="Required"
          type="password"
          .error="${this.__errors.password}"
          .value="${this.model.password}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.textfieldConfirmPassword.id}"
          name="confirmPassword"
          label="Confirm Password"
          helper="Required"
          type="password"
          .error="${this.__errors.confirmPassword}"
          .value="${this.model.confirmPassword}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>

        <div class="grid grid-2">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="spacing"
            label="Confirm"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-password', PopupPassword);
