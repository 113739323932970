import './neb-pdf-viewer-header';
import './neb-pdf-viewer-content';

import { LitElement, html } from 'lit';

export const ELEMENTS = {
  pdfViewerContent: {
    id: 'pdf-viewer-content-container',
  },
  pdfViewerHeader: {
    id: 'pdf-viewer-header-container',
  },
};
export class NebPdfViewer extends LitElement {
  static get properties() {
    return {
      pageCount: Number,
      pageNumber: Number,
      zoom: Object,
      isRenderPdf: Boolean,
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.pageCount = 0;
    this.pageNumber = 0;
    this.isRenderPdf = false;

    this.onRenderPdf = () => {};

    this.onNext = () => {};

    this.onPrevious = () => {};

    this.onPageNumberInput = () => {};

    this.zoom = {
      mode: null,
      value: 0,
    };

    this.onZoomIn = () => {};

    this.onZoomOut = () => {};

    this.onSetZoom = () => {};

    this.onResize = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      renderPdf: contentInfo => this.onRenderPdf(contentInfo),
      next: () => this.onNext(),
      previous: () => this.onPrevious(),
      pageNumberInput: number => this.onPageNumberInput(number),
      zoomIn: () => this.onZoomIn(),
      zoomOut: () => this.onZoomOut(),
      setZoom: zoomInfo => this.onSetZoom(zoomInfo),
      resize: sizeInfo => this.onResize(sizeInfo),
    };
  }

  firstUpdated() {
    this.__elements = {
      pdfViewerContent: this.shadowRoot.getElementById(
        ELEMENTS.pdfViewerContent.id,
      ),
    };
  }

  __renderStyles() {
    return html`
      <style>
        .content-container {
          height: 100%;
          width: 100%;
        }

        :host([small]) .content-container {
          width: auto;
          padding: 0 22px;
        }

        .view-container {
          height: 100%;
          width: auto;
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="content-container">
        <neb-pdf-viewer-header
          id="${ELEMENTS.pdfViewerHeader.id}"
          .pageCount="${this.pageCount}"
          .pageNumber="${this.pageNumber}"
          .onNext="${this.__handlers.next}"
          .onPrevious="${this.__handlers.previous}"
          .onPageNumberInput="${this.__handlers.pageNumberInput}"
          .zoom="${this.zoom}"
          .onZoomIn="${this.__handlers.zoomIn}"
          .onZoomOut="${this.__handlers.zoomOut}"
          .onSetZoom="${this.__handlers.setZoom}"
        ></neb-pdf-viewer-header>
        <neb-pdf-viewer-content
          id="${ELEMENTS.pdfViewerContent.id}"
          .onRenderPdf="${this.__handlers.renderPdf}"
          class="view-container"
          .pageNumber="${this.pageNumber}"
          .isRenderPdf="${this.isRenderPdf}"
          .onResize="${this.__handlers.resize}"
        ></neb-pdf-viewer-content>
      </div>
    `;
  }
}
customElements.define('neb-pdf-viewer', NebPdfViewer);
