import { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClientV2({
  microservice: 'appointments',
});

export const getCalendarSummary = async ({
  type,
  start,
  end,
  providerIds,
  resourceIds,
  locationIds,
  includeSplits = false,
  status,
  signal,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/calendar-summary',
    queryParams: {
      start,
      end,
      type: type.toLowerCase(),
      status,
      includeSplits,
    },
    body: JSON.stringify({ providerIds, locationIds, resourceIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  });

  return res;
};

export const getCalendarSummaryCondensed = async ({
  type,
  start,
  end,
  providerIds,
  resourceIds,
  locationIds,
  status,
  signal,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/calendar-summary/condensed',
    queryParams: { start, end, type: type.toLowerCase(), status },
    body: JSON.stringify({ providerIds, locationIds, resourceIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  });

  return res && res.data[0];
};

export const getCalendarSummaryIntegrated = async ({
  start,
  end,
  providerId,
  resourceId,
  locationId,
  signal,
  colorView,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/calendar-summary/integrated',
    queryParams: { start, end, providerId, locationId, resourceId, colorView },
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  });

  return res && res.data;
};
