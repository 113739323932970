import { getLidPaymentIds } from '../../../../../packages/neb-utils/neb-ledger-util';

const isPrimaryPayer = ({
  primaryPayerId,
  payerId,
  lineItemPatientInsuranceId,
  patientInsuranceId,
}) =>
  primaryPayerId &&
  payerId === primaryPayerId &&
  (lineItemPatientInsuranceId === patientInsuranceId || !patientInsuranceId);

const isSecondaryPayer = ({ secondaryPayerId, payerId, patientInsuranceId }) =>
  !!secondaryPayerId &&
  (payerId === secondaryPayerId || patientInsuranceId === secondaryPayerId);

const isInsurancePayer = ({
  payerId,
  primaryPayerId,
  lineItemPatientInsuranceId,
  patientInsuranceId,
  secondaryPayerId,
}) =>
  isPrimaryPayer({
    payerId,
    primaryPayerId,
    lineItemPatientInsuranceId,
    patientInsuranceId,
  }) || isSecondaryPayer({ secondaryPayerId, payerId, patientInsuranceId });

export const getPayerPayments = (li, detailItems) =>
  getLidPaymentIds(
    detailItems
      .find(di => di.id === li.id)
      .lineItemDebits.filter(
        lid =>
          !!lid.debit.payerId &&
          isInsurancePayer({
            payerId: lid.debit.payerId,
            primaryPayerId: li.primaryPayerId,
            lineItemPatientInsuranceId: li.primaryPlanId,
            patientInsuranceId: lid.patientInsuranceId,
            secondaryPayerId: li.secondaryPlanId,
          }),
      ),
  );

export const getPatientPayments = (li, detailItems) =>
  getLidPaymentIds(
    detailItems
      .find(di => di.id === li.id)
      .lineItemDebits.filter(
        lid =>
          !lid.debit.payerId &&
          !isInsurancePayer({
            payerId: lid.debit.payerId,
            primaryPayerId: li.primaryPayerId,
            lineItemPatientInsuranceId: li.primaryPlanId,
            patientInsuranceId: lid.patientInsuranceId,
            secondaryPayerId: li.secondaryPlanId,
          }),
      ),
  );
