export const PRIMARY_KEY_ID = 'id';
export const LASTNAME_ID = 'last-name';
export const FIRSTNAME_ID = 'first-name';
export const MIDDLENAME_ID = 'middle-name';
export const PREFERREDNAME_ID = 'preferred-name';
export const SUFFIX_ID = 'suffix';
export const DOB_ID = 'dob';
export const SSN_ID = 'ssn';
export const MEDICALRECORDNUMBER_ID = 'medical-record-number';
export const RELATIONSHIP_ID = 'relationship-status';
export const EMPLOYMENT_ID = 'employment-status';
export const SEX_ID = 'sex';
export const PHONE1_ID = 'phone1';
export const PHONETYPE1_ID = 'phone-type1';
export const PHONE2_ID = 'phone2';
export const PHONETYPE2_ID = 'phone-type2';
export const PHONE3_ID = 'phone3';
export const PHONETYPE3_ID = 'phone-type3';
export const EMAIL1_ID = 'email1';
export const EMAIL2_ID = 'email2';
export const EMAIL3_ID = 'email3';
export const ADDRESSLINE1_ID = 'address-line-1';
export const ADDRESSLINE2_ID = 'address-line-2';
export const CITY_ID = 'city';
export const STATE_ID = 'state';
export const ZIPCODE_ID = 'zipcode';
export const STATUS_ID = 'status';
