import { css, html } from 'lit';

import { formatCode } from '../../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../../packages/neb-utils/formatters';
import { formatBilled, formatHold } from '../../formatters/line-items';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  textBilled: { selector: '[id^=text-billed-]' },
  textHold: { selector: '[id^=text-hold-]' },
  chargeLinks: { selector: '[id^=charge-link-]' },
  invoiceLinks: { selector: '[id^=invoice-link-]' },
};

const CONFIG = [
  {
    key: 'chargeNumber',
    label: 'Charge',
    flex: css`1 0 40px`,
  },
  {
    key: 'billed',
    label: 'Billed',
    flex: css`0 0 38px`,
    mobile: true,
  },
  {
    key: 'hold',
    label: 'Hold',
    flex: css`0 0 38px`,
    mobile: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`2 0 0px`,
  },
  {
    key: 'primaryDiagnosis',
    label: 'Dx',
    flex: css`1 0 40px`,
  },
  {
    key: 'dateOfService',
    label: 'DOS',
    flex: css`1 0 40px`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'procMod',
    label: 'Proc + Mod',
    flex: css`1 0 85px`,
    formatter: (_, row) => formatCode(row.code, row.modifiers),
  },
  {
    key: 'units',
    label: 'Units',
    flex: css`1 0 40px`,
  },
  {
    key: 'billedWithTaxAmount',
    label: 'Billed',
    flex: css`1 0 60px`,
    formatter: (_, row) => centsToCurrency(row.billedAmount + row.taxAmount),
  },
];

class NebTableCaseCharges extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        #header {
          padding-top: ${CSS_SPACING};
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .text-billed-patient,
        .text-hold-claim {
          width: max-content;
        }

        .cell-data {
          padding: 12px 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = CONFIG;
    this.emptyMessage = 'There are no charges linked to this case.';

    this.onSelectCharge = () => {};

    this.onSelectInvoice = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      clickCharge: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { id, type } = this.model[rowIndex];

        return this.onSelectCharge({ id, type });
      },
      clickInvoice: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { id, invoiceId } = this.model[rowIndex];

        return this.onSelectInvoice({ id, invoiceId });
      },
    };
  }

  __renderLinkCell({ value, onClick, id, name }) {
    return html`
      <neb-text
        id="${id}"
        bold
        link
        .name="${name}"
        .onClick="${onClick}"
        class="link-cell"
        ?italic="${value === 'Not Invoiced'}"
        >${value}</neb-text
      >
    `;
  }

  __renderChargeNumber({ rowIndex }) {
    const { chargeNumber, id: chargeId } = this.model[rowIndex];

    const id = `charge-link-${rowIndex}`;
    const name = `${chargeId}.${rowIndex}`;

    return this.__renderLinkCell({
      id,
      name,
      value: chargeNumber,
      onClick: this.handlers.clickCharge,
    });
  }

  __renderInvoiceNumber({ rowIndex }) {
    const { invoiceNumber } = this.model[rowIndex];
    const id = `invoice-link-${rowIndex}`;
    const linkName = `${invoiceNumber || 'not-invoiced'}.${rowIndex}`;

    return this.__renderLinkCell({
      id,
      name: linkName,
      value: invoiceNumber || 'Not Invoiced',
      onClick: this.handlers.clickInvoice,
    });
  }

  __renderBilled({ rowIndex }) {
    const id = `text-billed-${rowIndex}`;
    const formattedString = formatBilled(this.model[rowIndex]);
    return html`
      <neb-text id="${id}" class="text-billed-patient"
        >${formattedString}</neb-text
      >
    `;
  }

  __renderHold({ rowIndex }) {
    const id = `text-hold-${rowIndex}`;
    const formattedString = formatHold(this.model[rowIndex]);
    return html`
      <neb-text id="${id}" class="text-hold-claim">${formattedString}</neb-text>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    switch (columnConfig.key) {
      case 'chargeNumber':
        return this.__renderChargeNumber({ rowIndex });
      case 'invoiceNumber':
        return this.__renderInvoiceNumber({
          rowIndex,
          columnConfig,
          name,
          error,
        });
      case 'billed':
        return this.__renderBilled({ rowIndex });
      case 'hold':
        return this.__renderHold({ rowIndex });
      default:
        return value;
    }
  }
}

customElements.define('neb-table-case-charges', NebTableCaseCharges);
