import { parseDate } from '../../packages/neb-utils/date-util';
import { TIME_SLOT_HEIGHT } from '../../packages/neb-utils/neb-cal-util';

function nearestFiveMinuteIntervalInMs(minutes, roundUp) {
  return roundUp
    ? Math.ceil(minutes / 300000) * 300000
    : Math.floor(minutes / 300000) * 300000;
}

export class ResizeController {
  constructor(host, callbacks) {
    this.host = host;
    this.callbacks = callbacks;

    host.addController(this);

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__isResizingDown = false;
    this.__isResizingUp = false;
    this.__startingY = 0;
    this.__startingScroll = 0;
    this.__startingDuration = 0;

    this.onResizeDown = this.callbacks.onResizeDown;
    this.onResizeUp = this.callbacks.onResizeUp;
    this.onUpdateResizing = this.callbacks.onUpdateResizing;

    this.zoomInterval = 1;
  }

  __initHandlers() {
    this.handlers = {
      mouseMove: e => {
        if (!this.__isResizingDown && !this.__isResizingUp) return;

        const event = e.touches ? e.touches[0] : e;

        const pxPerMin = (TIME_SLOT_HEIGHT * this.zoomInterval) / 60;
        const yDelta = event.clientY - this.__startingY;
        const currentScroll = this.host.getBoundingClientRect().y;
        const scrollDelta = currentScroll - this.__startingScroll;

        const combinedDelta = this.__isResizingDown
          ? yDelta - scrollDelta
          : scrollDelta - yDelta;

        const durationMinutes = Math.ceil(combinedDelta / pxPerMin);

        const roundedDurationMS = Math.max(
          nearestFiveMinuteIntervalInMs(
            durationMinutes * 60000 + this.__startingDuration,
            this.__isResizingDown,
          ),
          300000,
        );

        if (this.__isResizingDown) {
          this.onResizeDown(roundedDurationMS);
        }

        if (this.__isResizingUp) {
          this.onResizeUp(roundedDurationMS);
        }
      },

      eventResizeDown: e => {
        const pxPerMin = (TIME_SLOT_HEIGHT * this.zoomInterval) / 60;

        this.__isResizingDown = true;

        this.__startingY = e.getBoundingClientRect().bottom;

        this.__startingDuration =
          (e.getBoundingClientRect().height / pxPerMin) * 60000;

        this.__startingScroll = this.host.getBoundingClientRect().y;

        this.onUpdateResizing(true);
      },
      eventResizeUp: e => {
        this.__isResizingUp = true;

        this.__startingY = e.getBoundingClientRect().top;

        this.__startingDuration =
          parseDate(e.model.end) - parseDate(e.model.start);

        this.__startingScroll = this.host.getBoundingClientRect().y;

        this.onUpdateResizing(true);
      },
      stopResizing: () => {
        this.__isResizingDown = false;
        this.__isResizingUp = false;
        this.onUpdateResizing(false);
      },
    };
  }

  get isResizingDown() {
    return this.__isResizingDown;
  }

  get isResizingUp() {
    return this.__isResizingUp;
  }

  hostConnected() {
    this.host.addEventListener('mousemove', this.handlers.mouseMove);
    this.host.addEventListener('mouseup', this.handlers.stopResizing);
    this.host.addEventListener('mouseleave', this.handlers.stopResizing);

    this.host.addEventListener('touchmove', this.handlers.mouseMove);
    this.host.addEventListener('touchend', this.handlers.stopResizing);
    this.host.addEventListener('touchcancel', this.handlers.stopResizing);
  }

  hostDisconnected() {
    this.host.removeEventListener('mousemove', this.handlers.mouseMove);
    this.host.removeEventListener('mouseup', this.handlers.stopResizing);
    this.host.removeEventListener('mouseleave', this.handlers.stopResizing);

    this.host.removeEventListener('touchmove', this.handlers.mouseMove);
    this.host.removeEventListener('touchend', this.handlers.stopResizing);
    this.host.removeEventListener('touchcancel', this.handlers.stopResizing);
  }
}
