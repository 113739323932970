import '../../neb-material-design/src/components/neb-md-slider';
import '../../neb-lit-components/src/components/neb-profile-photo-editor';
import '../../../src/components/misc/neb-icon';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  saveButton: {
    id: 'button-save',
  },
  cancelButton: {
    id: 'button-cancel',
  },
  rotateButton: {
    id: 'button-rotate',
  },
  slider: {
    id: 'slider',
  },
  img: {
    id: 'img',
  },
};

class NebPopupProfilePreview extends NebPopup {
  static get properties() {
    return {
      __zoom: Number,
      __rotate: Number,
    };
  }

  initState() {
    super.initState();
    this.__zoom = 1;
    this.__rotate = 0;
    this.__output = '';
    this.title = 'Patient Photo';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.save = () => this.onClose(this.__output);

    this.handlers.zoom = data => {
      this.__zoom = data.value;
    };

    this.handlers.changeCanvas = data => {
      this.__output = data;
    };

    this.handlers.rotate = () => {
      this.__rotate = (this.__rotate + 1) % 4;
    };
  }

  modelChanged() {
    this.__output = this.model.imgSrc;
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 500px;
          height: 460px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          height: 375px;
        }

        .row {
          display: flex;
          margin: 0;
          padding: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .row-center {
          justify-content: center;
        }

        .row-caption {
          color: ${CSS_COLOR_GREY_1};
        }

        .row-photo {
          flex: 1 0 0;
          align-items: center;
          justify-content: center;
        }

        .row-photo-container {
          display: flex;
          width: 230px;
          justify-content: flex-end;
        }

        .row-photo-rotate-button {
          display: flex;
          height: 200px;
        }

        .row-slider {
          margin: 8px 0 ${CSS_SPACING} 0;
        }

        .row-buttons {
          display: flex;
          margin-bottom: 0;
        }

        .container-photo {
          position: relative;
          width: 200px;
          height: 200px;
        }

        .photo {
          width: 100%;
          height: 100%;
        }

        .photo-filter {
          pointer-events: none;
          position: absolute;
          top: 0;
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 100px,
            rgba(255, 255, 255, 0.8) 100px
          );
        }

        .photo-border {
          pointer-events: none;
          position: absolute;
          top: 0;
          border-radius: 50%;
          border: 2px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .photo-rotate-container {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .icon-rotateCCW {
          height: 40px;
          width: 40px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          padding: 0 0 0 15px;
          cursor: pointer;
        }

        .slider {
          width: 230px;
          touch-action: none;
        }

        .button-save {
          margin-right: 12px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <p class="row row-caption">Drag to reposition photo.</p>

        <div class="row row-photo">
          <div class="row row-photo-container">
            <div class="container-photo">
              <neb-profile-photo-editor
                id="${ELEMENTS.img.id}"
                class="photo"
                minZoom="1"
                maxZoom="2"
                .zoom="${this.__zoom}"
                .rotate="${this.__rotate}"
                .src="${this.model.imgSrc}"
                .onChange="${this.handlers.changeCanvas}"
              ></neb-profile-photo-editor>
              <div class="photo photo-filter"></div>
              <div class="photo photo-border"></div>
            </div>
          </div>
          <div class="row row-photo-rotate-button">
            <div class="photo-rotate-container">
              <neb-icon
                id="${ELEMENTS.rotateButton.id}"
                class="icon-rotateCCW"
                icon="neb:rotateCCW"
                @click="${this.handlers.rotate}"
              >
              </neb-icon>
            </div>
          </div>
        </div>

        <div class="row row-center"><span>Zoom</span></div>
        <div class="row row-center row-slider">
          <neb-md-slider
            id="${ELEMENTS.slider.id}"
            class="slider"
            min="1"
            max="2"
            interval="0.01"
            .onInput="${this.handlers.zoom}"
          ></neb-md-slider>
        </div>

        <div class="row row-buttons">
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button-save"
            label="Select"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.save}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.cancelButton.id}"
            label="Cancel"
            .role="${BUTTON_ROLE.CANCEL}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-profile-preview', NebPopupProfilePreview);
