import '../../forms/neb-form-patient-insurance-edit';

import { html, css } from 'lit';

import { getPatientInsurances } from '../../../../../neb-api-client/src/patient-insurance-api-client';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../../neb-utils/patientInsurance';
import Overlay, { ELEMENTS as OVERLAY_ELEMENTS } from '../neb-overlay';

export const ELEMENTS = {
  ...OVERLAY_ELEMENTS,
  formEditInsurance: {
    id: 'form-edit-insurance',
  },
};

class NebOverlayPatientInsuranceEdit extends Overlay {
  static get properties() {
    return {
      jsonExists: {
        reflect: true,
        type: Boolean,
      },
      rteJSON: Object,
      __patientId: String,
      __patientInsurances: Array,
      __patientInsurance: Object,
    };
  }

  initState() {
    super.initState();
    this.rteJSON = {
      idInfo: { subscriber: {}, payer: {}, provider: {} },
      benefitInfo: { activeServiceTypes: {}, inactiveServiceTypes: {} },
    };

    this.jsonExists = false;
    this.__patientId = '';
    this.__patientInsurances = [];
    this.__patientInsurance = { ...createModelNewPatientInsurance() };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: obj => {
        this.__result = obj;
        this.handlers.dirty(false);
        this.handlers.dismiss(obj);
      },
      closeReport: () => {
        this.jsonExists = false;
      },
    };
  }

  async __load() {
    this.__patientInsurances = (
      await getPatientInsurances(this.__patientId)
    ).map(ins => mapToPatientInsuranceModel(ins));
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { patientId = '', patientInsurance, context } = this.model;

      this.__patientId = patientId;
      this.__patientInsurance = patientInsurance;

      this.jsonExists = context.jsonExists || this.jsonExists;
      this.rteJSON = context.rteJSON || this.rteJSON;

      this.__load();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.context.existingNode) {
        this.model.context.existingNode.attachedToEdit = true;
        const contentContainer =
          this.shadowRoot.querySelector('#content-container');
        contentContainer.insertBefore(
          this.model.context.existingNode,
          contentContainer.firstChild,
        );
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([jsonExists]) .content {
          display: flex;
          width: 1750px;
          flex-flow: column nowrap;
        }

        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .form {
          flex: 1 0 0;
        }

        :host([jsonExists]) .expandable-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          height: 100%;
        }

        .expandable-container {
          display: grid;
          grid-template-columns: 1fr;
          height: 100%;
        }

        :host(:not([jsonExists])) .benefits {
          display: none;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="content-container" class="expandable-container">
        <neb-form-patient-insurance-edit
          id="${ELEMENTS.formEditInsurance.id}"
          class="form"
          .layout="${this.layout}"
          .patientId="${this.__patientId}"
          .patientInsurances="${this.__patientInsurances}"
          .patientInsurance="${this.__patientInsurance}"
          .hasAddOnCTVerify="${this.model.context.hasAddOnCTVerify}"
          .providers="${this.model.context.providers}"
          .onCancel="${this.handlers.dismiss}"
          .onSave="${this.handlers.save}"
          .onChangeDirty="${this.handlers.dirty}"
        ></neb-form-patient-insurance-edit>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-edit',
  NebOverlayPatientInsuranceEdit,
);
