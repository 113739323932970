import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-material-design/src/components/neb-md-button';
import '../../neb-lit-components/src/components/neb-button';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  select: { id: 'select' },
  copyButton: { id: 'button-copy' },
  cancelButton: { id: 'button-cancel' },
};

class CopyPopup extends NebPopup {
  static get properties() {
    return {
      __selectedItem: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 480px;
        }

        .content {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: auto auto 1fr;
        }

        .controls {
          grid-column: span 3;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedItem = null;

    this.model = {
      name: '',
      items: [],
      dirty: false,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      select: e => {
        this.__selectedItem = e.value;
      },
      cancel: () => this.onClose(),
      copy: async () => {
        if (this.model.dirty) {
          const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: `Copy Details from ${this.model.name}`,
            confirmText: 'Yes',
            cancelText: 'No',
            message: html`
              <p>
                This will overwrite existing details for this
                ${this.model.name}.
              </p>
              <p>Are you sure that you want to proceed?</p>
            `,
          });

          if (accepted) {
            this.onClose(this.__selectedItem);
          } else {
            this.onClose();
          }
        } else {
          this.onClose(this.__selectedItem);
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.title = `Copy Existing ${this.model.name}`;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.select.id}"
        class="controls"
        placeholder="Select"
        .items="${this.model.items}"
        .value="${this.__selectedItem}"
        .onChange="${this.handlers.select}"
      ></neb-select>

      <neb-button
        id="${ELEMENTS.copyButton.id}"
        label="Copy"
        .onClick="${this.handlers.copy}"
        ?disabled="${!this.__selectedItem}"
        unelevated
      ></neb-button>

      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        label="Cancel"
        .onClick="${this.handlers.cancel}"
        outlined
      ></neb-button>
    `;
  }
}

window.customElements.define('neb-popup-copy', CopyPopup);
