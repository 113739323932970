import Emittery from 'emittery';

const emitter = new Emittery();

const UPDATE_EVENT = Symbol('refresh');

export const ANY = Symbol('any');

const checkUpdateMatchesQuery = (update, query) =>
  Object.entries(query).some(([model, filter]) => {
    const filters = Array.isArray(filter) ? filter : [filter];
    if (!Object.prototype.hasOwnProperty.call(update, model)) return false;

    return (
      filter === ANY ||
      filters.some(f =>
        Object.entries(f).every(([field, value]) => {
          if (!Object.prototype.hasOwnProperty.call(update[model], field)) {
            return false;
          }

          return value === ANY || update[model][field] === value;
        }),
      )
    );
  });

export const subscribe = (query, inputCallback) => {
  const callback = update =>
    checkUpdateMatchesQuery(update, query) ? inputCallback() : null;

  emitter.on(UPDATE_EVENT, callback);
  return () => emitter.off(UPDATE_EVENT, callback);
};

export const updated = update => emitter.emit(UPDATE_EVENT, update);
