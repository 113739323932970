import * as pdfjsLib from '@neb/pdfjs-legacy/webpack.mjs';

/**
 * Helper class to handle pdf file processing.
 * Wraps pdf.js library providing  API for pdf processing. See more https://mozilla.github.io/pdf.js/
 */

export class PdfProcessor {
  constructor() {
    this.__pdfDocument = null;
    this.__pdfDataUrl = null;
    this.__scale = 1;
  }
  /**
   * Loads pdf file provided in data-url format.
   *
   * @param {String} pdfDataUrl - pdf data-utl.
   */

  async open(pdfDataUrl) {
    if (this.__pdfDataUrl === pdfDataUrl) {
      return;
    }

    this.__pdfDataUrl = pdfDataUrl;
    this.__pdfDocument = await pdfjsLib.getDocument(pdfDataUrl).promise;
  }
  /**
   * Renders pdf page on provided canvas.
   *
   * @param {*} canvas - canvas element.
   * @param {*} pageNum - page number (starts with 1).
   */

  async renderPage(canvas, pageNum, scale = 1) {
    const page = await this.__getPage(pageNum);
    const context = canvas.getContext('2d');
    const viewport = page.getViewport({
      scale,
    });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext: context,
      viewport,
    };
    return page.render(renderContext).promise;
  }
  /*
   * Render pdf viewport for preview; i.e. page 1 with defined canvas size.
   * @param {*} canvas - canvas element
   */

  async renderPageForPreview(canvas) {
    const page = await this.__getPage(1);
    const context = canvas.getContext('2d');
    const defaultViewport = page.getViewport({
      scale: this.__scale,
    });
    const scale = Math.min(
      canvas.height / defaultViewport.height,
      canvas.width / defaultViewport.width,
    );
    const scaledViewport = page.getViewport({
      scale,
    });
    const renderContext = {
      canvasContext: context,
      viewport: scaledViewport,
    };
    return page.render(renderContext).promise;
  }
  /**
   * Returns total number of pages from pdf file.
   */

  getPageCount() {
    if (this.__pdfDocument) {
      return this.__pdfDocument.numPages;
    }

    return 0;
  }

  __getPage(pageNum) {
    return this.__pdfDocument.getPage(pageNum);
  }

  async getPageInfos() {
    const pages = [];
    const pageCount = this.getPageCount();

    for (let i = 1; i <= pageCount; i++) {
      const page = await this.__getPage(i);
      const viewport = await page.getViewport({
        scale: 1,
      });
      pages.push({
        width: viewport.width,
        height: viewport.height,
      });
    }

    return pages;
  }
}
