import { getAppointmentById } from '../../../../../neb-api-client/src/appointment-api-client';
import { fetchOne } from '../../../../../neb-api-client/src/patient-cases';

const getAuthorizedProcedures = async appointmentId => {
  const { appointment } = await getAppointmentById(appointmentId);
  const { patientId, caseId } = { ...appointment };
  if (!caseId) return null;

  const { patientAuthorization = null } = await fetchOne(patientId, caseId);
  if (!patientAuthorization) return null;
  const { charges } = patientAuthorization;

  return charges.map(({ procedure }) => procedure);
};

export default async ({ appointmentId = '' } = {}) => {
  const authorizedProcedures = await getAuthorizedProcedures(appointmentId);
  return {
    authorizedProcedures,
  };
};
