export const SPINE_LABELS = [
  'left Occiput',
  'Occiput',
  'right Occiput',
  'left C1',
  'C1',
  'right C1',
  'left C2',
  'C2',
  'right C2',
  'left C3',
  'C3',
  'right C3',
  'left C4',
  'C4',
  'right C4',
  'left C5',
  'C5',
  'right C5',
  'left C6',
  'C6',
  'right C6',
  'left C7',
  'C7',
  'right C7',
  'left T1',
  'T1',
  'right T1',
  'left T2',
  'T2',
  'right T2',
  'left T3',
  'T3',
  'right T3',
  'left T4',
  'T4',
  'right T4',
  'left T5',
  'T5',
  'right T5',
  'left T6',
  'T6',
  'right T6',
  'left T7',
  'T7',
  'right T7',
  'left T8',
  'T8',
  'right T8',
  'left T9',
  'T9',
  'right T9',
  'left T10',
  'T10',
  'right T10',
  'left T11',
  'T11',
  'right T11',
  'left T12',
  'T12',
  'right T12',
  'left L1',
  'L1',
  'right L1',
  'left L2',
  'L2',
  'right L2',
  'left L3',
  'L3',
  'right L3',
  'left L4',
  'L4',
  'right L4',
  'left L5',
  'L5',
  'right L5',
  'left Sacrum',
  'Sacrum',
  'right Sacrum',
  'left Pelvis',
  'right Pelvis',
  'left Coccyx',
  'Coccyx',
  'right Coccyx',
];

export const SPINE_LABEL_INDEX = Object.fromEntries(
  SPINE_LABELS.map((label, index) => [label, index]),
);

export const DIRECTION = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const SPINE_SECTION = {
  PELVIS: 'Pelvis',
  OCCIPUT: 'Occiput',
  SACRUM: 'Sacrum',
  COCCYX: 'Coccyx',
};

export const formatSpineLabel = (direction, section) =>
  direction === DIRECTION.CENTER ? section : `${direction} ${section}`;
