import '../../../../../src/components/misc/neb-icon';
import './neb-patient-card';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

const ITEMS = [
  {
    icon: 'neb:folder',
    label: 'Patient Record',
  },
  {
    icon: 'neb:calendar',
    label: 'View Appointments',
  },
  {
    icon: 'neb:books',
    label: 'View Encounters',
  },
  {
    icon: 'neb:wallet',
    label: 'Review Ledger',
  },
];
export const ELEMENTS = {
  card: {
    id: 'card',
  },
  items: {
    selector: '[id^=item-]',
  },
  toggleButton: {
    id: 'button-toggle',
  },
};

class PatientListItem extends LitElement {
  static get properties() {
    return {
      model: Object,
      selected: {
        reflect: true,
        type: Boolean,
      },
      expanded: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: 0 4px;
          grid-template-columns: 1fr auto;
          grid-template-rows: repeat(2, auto);
        }

        .item {
          display: flex;
          background-color: transparent;
          border: 1px solid transparent;
          transition: border 200ms;
        }

        .item:hover,
        :host([expanded]) .item {
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([selected]) .item {
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .item-expand {
          cursor: pointer;
          display: flex;
          justify-content: center;
          width: 38px;
          grid-row: span 2;
        }

        :host(:not([selected])) .item-expand:hover {
          border: 1px solid ${CSS_COLOR_GREY_2};
        }

        .item-menu {
          display: flex;
          overflow: hidden;
          padding: 0;
          height: 0;
          min-width: 0;
          flex-flow: column nowrap;
          transition: height 200ms;
        }

        :host([expanded]) .item-menu {
          height: 160px;
        }

        .icon-arrow {
          cursor: pointer;
          width: 12px;
          height: 12px;
          align-self: center;
          fill: ${CSS_COLOR_GREY_1};
          transition: transform 200ms;
        }

        :host([expanded]) .icon-arrow {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(180deg);
        }

        :host([selected]) .icon-arrow {
          fill: ${CSS_COLOR_WHITE};
        }

        .icon-item {
          margin-left: 80px;
          margin-right: 12px;
          padding: 0;
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([selected]) .icon-item {
          fill: ${CSS_COLOR_WHITE};
        }

        .label-item {
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([selected]) .label-item {
          color: ${CSS_COLOR_WHITE};
        }

        .row-action {
          display: flex;
          min-height: 40px;
          align-items: center;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.selected = false;
    this.expanded = false;
    this.model = {
      id: '',
      medicalRecordNumber: '',
      sex: '',
      photoSrc: '',
      dateOfBirth: new Date(),
      phones: [],
      name: {
        first: '',
        last: '',
        middle: '',
      },
    };

    this.onClick = () => {};

    this.onToggle = () => {};

    this.onAction = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this.model),
      toggle: () => this.onToggle(this.model, !this.selected),
      action: e => {
        const index = Number(e.currentTarget.index);
        this.onAction(this.model, index);
      },
    };
  }

  renderItems() {
    return ITEMS.map(
      (item, index) => html`
        <div
          id="item-${index}"
          class="row-action"
          .index="${index}"
          @click="${this.__handlers.action}"
        >
          <neb-icon class="icon-item" .icon="${item.icon}"></neb-icon>
          <span class="label-item">${item.label}</span>
        </div>
      `,
    );
  }

  render() {
    return html`
      <div class="container">
        <neb-patient-card
          id="${ELEMENTS.card.id}"
          class="item"
          .model="${this.model}"
          @click="${this.__handlers.click}"
          ?selected="${this.selected}"
        ></neb-patient-card>

        <div
          id="${ELEMENTS.toggleButton.id}"
          class="item item-expand"
          @click="${this.__handlers.toggle}"
        >
          <neb-icon class="icon-arrow" icon="neb:chevron"></neb-icon>
        </div>

        <div class="item item-menu">${this.renderItems()}</div>
      </div>
    `;
  }
}

window.customElements.define('neb-patient-list-item', PatientListItem);
