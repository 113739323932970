import {
  getAlertsToDisplayByPatient,
  getAllAlertsByPatient,
} from '../../../src/api-clients/alerts';

export class NebAlertsForPatientState {
  constructor(onAlertChange) {
    this.__onAlertChange = onAlertChange;
  }

  async fetchAll(patientId) {
    const patientAllAlerts = await getAllAlertsByPatient(patientId);

    this.__onAlertChange(patientAllAlerts);
  }

  async fetchBanner(patientId) {
    const patientAlerts = await getAlertsToDisplayByPatient(patientId);

    this.__onAlertChange(patientAlerts);
  }
}

export const createAlertsForPatientState = onAlertChange =>
  new NebAlertsForPatientState(onAlertChange);
