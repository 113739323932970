/* istanbul ignore file */
// Above 'ignore' is temporary - TODO write comprehensive Unit Tests

import { css, html, LitElement } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { MODE } from '../../../../packages/neb-utils/table';
import { formatDateTime } from '../../../utils/conversions/date-format';

export const ELEMENTS = {
  atfPrivateKey: { id: 'atfPrivateKey' },
  atfUser: { id: 'atfUser' },
  copyButton: { id: 'copy' },
  created: { id: 'created' },
  deleteButton: { id: 'delete' },
  generateSshKeyButton: { id: 'generateSshKey' },
  id: { id: 'id' },
  jobs: { id: 'jobs' },
  name: { id: 'name' },
  refreshButton: { id: 'refresh' },
  status: { id: 'status' },
  tenantId: { id: 'tenantId' },
  clearCacheButton: { id: 'clearCache' },
};

class NebConversionPage extends LitElement {
  static get properties() {
    return {
      jobs: Array,
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          flex-direction: column;
          padding: 0 ${CSS_SPACING};
        }

        .info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;

          padding-bottom: 20px;
        }

        .info .row-1 {
          grid-row-start: 1;
        }

        .info .row-2 {
          grid-row-start: 2;
        }

        .info .row-3 {
          grid-row-start: 3;
        }

        .info .column-1 {
          grid-column-start: 1;
        }

        .info .column-2 {
          grid-column-start: 2;
        }

        .field {
          padding: 0 0 20px 0;
        }

        .field label {
          font-size: 12px;
          font-weight: bold;
        }

        .field label:after {
          content: ':';
        }

        .buttons-container {
          padding-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .private-key-container {
          padding-bottom: 40px;
        }

        .private-key-container pre {
          padding: 0;
          margin: 0;
        }

        neb-table {
          padding-bottom: 20px;
        }

        .copy-delete-container {
          display: flex;
          flex-direction: row;
          align-self: flex-end;
          gap: ${CSS_SPACING};
        }

        .button-spacing {
          display: flex;
          gap: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.model = {};

    this.onCopy = () => {};

    this.onDelete = () => {};

    this.onGeneratePrivateKey = () => {};

    this.onRefreshJobs = () => {};

    this.onSelectJob = () => {};

    this.onClearCache = () => {};

    this.tableConfig = [
      { flex: '2', key: 'type', label: 'Job' },
      { flex: '2', key: 'description', label: 'Description' },
      { flex: '1', key: 'status', label: 'Status' },
      {
        flex: '1',
        formatter: (_, rowModel) => this.__formatProgress(rowModel),
        key: 'subJobStatuses',
        label: 'Progress',
      },
      {
        flex: '2',
        formatter: v => formatDateTime(v),
        key: 'updatedAt',
        label: 'Last Updated',
      },
    ];
  }

  initHandlers() {
    this.handlers = {
      copy: () => this.onCopy(this.model),
      delete: () => this.onDelete(this.model),
      generatePrivateKey: () => this.onGeneratePrivateKey(),
      refreshJobs: () => this.onRefreshJobs(),
      selectJob: (_, job) => this.onSelectJob(job),
      clearCache: () => this.onClearCache(),
    };
  }

  __formatProgress({ subJobCount, subJobStatuses }) {
    if (!subJobCount) {
      return '';
    }

    const { complete = 0, error = 0 } = subJobStatuses;

    let progress = `${complete + error}/${subJobCount}`;

    if (error) {
      progress = `${progress} (${error})`;
    }

    return progress;
  }

  render() {
    return html`
      <div class="container">
        <div class="info">
          <div class="field row-1 column-1">
            <label>Name</label>
            <div id="${ELEMENTS.name.id}">${this.model.name}</div>
          </div>
          <div class="field row-1 column-2">
            <label>Status</label>
            <div id="${ELEMENTS.status.id}">${this.model.status}</div>
          </div>
          <div class="field row-2 column-1">
            <label>ID</label>
            <div id="${ELEMENTS.id.id}">${this.model.id}</div>
          </div>
          <div class="field row-2 column-2">
            <label>Tenant ID</label>
            <div id="${ELEMENTS.tenantId.id}">${this.model.tenantId}</div>
          </div>
          <div class="field row-3 column-1">
            <label>Upload Username</label>
            <div id="${ELEMENTS.atfUser.id}">${this.model.atfUser}</div>
          </div>
          <div class="field row-3 column-2">
            <label>Created</label>
            <div id="${ELEMENTS.created.id}">
              ${formatDateTime(this.model.createdAt)}
            </div>
          </div>
        </div>

        ${this.model.atfPrivateKey
          ? html`
              <div class="field private-key-container">
                <label>Upload SSH Private Key</label>
                <pre id="${ELEMENTS.atfPrivateKey.id}">
${this.model.atfPrivateKey}</pre
                >
              </div>
            `
          : ''}

        <div class="buttons-container">
          <neb-button
            id="${ELEMENTS.refreshButton.id}"
            label="Refresh"
            .onClick="${this.handlers.refreshJobs}"
          ></neb-button>
          <div class="button-spacing">
            <neb-button
              id="${ELEMENTS.clearCacheButton.id}"
              label="Clear Tenant Cache"
              .onClick="${this.handlers.clearCache}"
            ></neb-button>
            ${this.model.atfPrivateKey
              ? ''
              : html`
                  <neb-button
                    id="${ELEMENTS.generateSshKeyButton.id}"
                    label="Generate Private Key"
                    .onClick="${this.handlers.generatePrivateKey}"
                  ></neb-button>
                `}
          </div>
        </div>

        <neb-table
          id="${ELEMENTS.jobs.id}"
          .config="${this.tableConfig}"
          .mode="${MODE.DETAIL}"
          .model="${this.jobs}"
          .onSelectRow="${this.handlers.selectJob}"
        ></neb-table>

        <div class="copy-delete-container">
          <neb-button
            id="${ELEMENTS.copyButton.id}"
            label="Copy"
            .onClick="${this.handlers.copy}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.deleteButton.id}"
            label="Delete"
            .onClick="${this.handlers.delete}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-conversion-page', NebConversionPage);
