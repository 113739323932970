import { ConnectedService } from './connected-service';
/**
 * Provides access to the hash in the route.  In general, should only be used
 * for legacy code (e.g. old overlays), as most new code should not access the hash.
 */

export class LegacyHashService extends ConnectedService {
  constructor(callback) {
    super({
      hash: 'route.hash',
    });

    this.onStateChanged = callback;
  }

  _stateChanged({ hash }) {
    this.onStateChanged({
      hash,
    });
  }
}
