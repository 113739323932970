export const _updating = (state, _action) => ({
  ...state,
  updating: true,
  updateError: null,
});
export const _updated = (state, action) => ({
  ...state,
  updating: false,
  updated: true,
  updateError: null,
  item: action.res.data[0].id,
});
export const _updateError = (state, action) => ({
  ...state,
  updating: false,
  updated: false,
  updateError: action.updateError,
});
