import '../../../neb-material-design/src/components/neb-md-select';
import '../../../../src/components/controls/inputs/neb-checkbox';
import './neb-duration-field-lit';
import './neb-radio-button';
import './neb-time';
import './neb-time-list';

import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../src/styles';
import { SELECT_BY_TYPE } from '../../../../src/utils/scheduling/appointments';
import { parseDate } from '../../../neb-utils/date-util';

import { TYPE } from './neb-moment-calendar-view';

export const ELEMENTS = {
  checkboxWalkIn: { id: 'checkbox-walk-in' },
  radioCustom: { id: 'radio-custom' },
  radioDate: { id: 'radio-date' },
  radioTime: { id: 'radio-time' },
  selectInputDuration: { id: 'select-duration' },
  selectInputTimeCustom: { id: 'select-time-custom' },
  selectInputTimeFrame: { id: 'select-time-frame' },
  textDateTime: { id: 'text-date-time' },
  viewCalendar: { id: 'view-calendar' },
  viewTime: { id: 'view-time' },
};

class DateTime extends LitElement {
  static get properties() {
    return {
      __displayPage: Object,
      __selectedTimeRange: Object,
      __timeRangeAvailability: Object,
      __tempDisplayPage: Object,
      __timeFrames: Array,

      availability: Object,
      duration: Number,
      enableWalkIn: Boolean,
      disableCalendarView: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      selectedDate: Object,
      timeFrameAvailabilities: Array,
      type: String,
      walkIn: Boolean,
      hasSplits: Boolean,
      renderSelectBy: Boolean,
      hasEditRescheduleFF: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;
        }

        :host([layout='small']) {
          height: fit-content;
          flex-direction: column;
        }

        :host([hasEditRescheduleFF]) {
          display: block;
        }

        .container-details {
          display: flex;
          flex-wrap: wrap;
          gap: ${CSS_SPACING};
        }

        :host([hasEditRescheduleFF]) .container-details {
          flex-direction: row;
          justify-content: space-between;
        }

        .container-left {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        :host([hasEditRescheduleFF]) .container-left {
          width: 50%;
        }

        :host([layout='small']) .container-left {
          height: fit-content;
          margin: 0 0 ${CSS_SPACING} 0;
        }

        :host([hasEditRescheduleFF]) .container-right {
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: 70px;
          flex: 1;
          min-width: 320px;
        }

        :host(:not([hasEditRescheduleFF])) .container-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }

        .duration-and-time {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }

        :host([layout='small']) .container-right {
          height: fit-content;
          margin: ${CSS_SPACING} 0 ${CSS_SPACING} 0;
        }

        .container-find-by {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 16px;
        }

        .calendar {
          display: flex;
          width: 100%;
          height: auto;
          min-width: 320px;
        }

        .input-time-custom {
          margin-bottom: ${CSS_SPACING};
        }

        .time {
          display: flex;
          max-width: 360px;
          width: 100%;
          height: 100%;
          min-height: 210px;
          margin-top: ${CSS_SPACING};
        }

        :host([layout='small']) .time {
          justify-self: center;
          height: auto;
          margin-bottom: ${CSS_SPACING};
        }

        .duration {
          display: block;
          width: 100%;
          margin-bottom: ${CSS_SPACING};
        }

        :host([hasEditRescheduleFF]) .duration {
          margin-bottom: 0;
        }

        .text-date-time {
          display: flex;
          justify-content: center;
          margin-bottom: ${CSS_SPACING};
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: 16px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        :host([hasEditRescheduleFF]) .text-date-time {
          margin-bottom: 0;
          margin-top: ${CSS_SPACING};
        }

        .icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-info {
          margin: 5px 10px 0 0;
        }

        .container-custom-info {
          display: flex;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .max-content {
          width: max-content;
        }

        .text-custom-info {
          display: flex;
          width: fit-content;
        }

        .walk-in-checkbox {
          width: max-content;
          padding-bottom: 5px;
        }

        .small-walkin {
          padding-bottom: 15px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    const newDate = parseDate();

    this.__displayPage = {
      month: newDate.month() + 1,
      year: newDate.year(),
    };

    this.__selectedTimeRange = null;
    this.__tempDisplayPage = null;
    this.__timeRangeAvailability = null;
    this.__timeFrames = [];

    this.availability = null;
    this.disableCalendarView = false;
    this.duration = 0;
    this.enableWalkIn = false;
    this.layout = 'large';
    this.selectedDate = newDate;
    this.timeFrameAvailabilities = [];
    this.type = SELECT_BY_TYPE.DATE;
    this.walkIn = false;
    this.hasSplits = false;
    this.shouldRenderSelectBy = true;
    this.hasEditRescheduleFF = false;

    this.onChange = () => {};

    this.onChangeDate = () => {};

    this.onChangeMonth = () => {};

    this.onChangeType = () => {};

    this.onChangeTimeFrame = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      isDateSelectable: date => this.__isDateAvailable(date),
      showCurrentDate: date => this.__showCurrentDateOnly(date),
      updateDate: date => {
        const selectedDateTime = date.clone();
        selectedDateTime.hours(this.selectedDate.hours());
        selectedDateTime.minutes(this.selectedDate.minutes());
        this.onChangeDate(selectedDateTime);
      },
      updateDuration: duration => {
        this.onChange({ name: 'duration', value: duration });
      },
      updateMonth: displayPage => {
        this.__tempDisplayPage = displayPage;
        this.onChangeMonth(displayPage);
      },
      updateSelectBy: type => {
        this.onChangeType({ name: 'type', value: type });
      },
      updateTime: time => {
        const hours = Math.floor(time.value);
        const minutes = Math.round((time.value % 1) * 60);
        const selectedDateTime = this.selectedDate
          .clone()
          .hour(hours)
          .minute(minutes);

        this.onChangeDate(selectedDateTime);
      },
      updateTimeByDropdown: date => {
        const selectedDateTime = this.selectedDate
          .clone()
          .hour(date.hours())
          .minute(date.minutes());

        this.onChangeDate(selectedDateTime);
      },
      updateTimeFrame: e => {
        this.__selectedTimeRange = e.value;
      },
      updateWalkIn: e => {
        this.onChange(e);
      },
    };
  }

  __isDateAvailable(date) {
    if (this.type === SELECT_BY_TYPE.CUSTOM) {
      return true;
    }

    if (this.type === SELECT_BY_TYPE.DATE && this.availability) {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayAvailability = this.availability[formattedDate];
      return dayAvailability && dayAvailability.length > 0;
    }

    if (
      this.type === SELECT_BY_TYPE.TIME &&
      this.availability &&
      this.__timeRangeAvailability
    ) {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayAvailability = this.__timeRangeAvailability[formattedDate];
      return dayAvailability && dayAvailability.length > 0;
    }

    return false;
  }

  __isHourWithinRange(hour) {
    return (
      hour >= this.__selectedTimeRange.duration[0] &&
      hour <= this.__selectedTimeRange.duration[1]
    );
  }

  __getAvailabilityTimes() {
    if (this.selectedDate) {
      if (this.selectedDate.month() + 1 !== this.__displayPage.month) return [];

      if (this.availability) {
        const formattedDate = this.selectedDate.format('YYYY-MM-DD');
        const dayAvailability = this.availability[formattedDate];

        if (dayAvailability) {
          return this.__selectedTimeRange &&
            this.__selectedTimeRange.duration &&
            this.type === SELECT_BY_TYPE.TIME
            ? dayAvailability.filter(hour => this.__isHourWithinRange(hour))
            : dayAvailability;
        }
      }
    }

    return [];
  }

  __getDateTimeDisplay() {
    return this.selectedDate
      ? this.selectedDate.format('dddd, MMMM DD, YYYY')
      : '';
  }

  update(changedProps) {
    if (changedProps.has('availability') && this.availability) {
      if (this.__tempDisplayPage) {
        this.__displayPage = this.__tempDisplayPage;
      }

      const formattedDate = this.selectedDate.format('YYYY-MM-DD');

      if (this.availability[formattedDate]) {
        this.__timeFrames = this.__getAvailabilityTimes();
      } else {
        this.__timeFrames = [];
      }
    }

    if (changedProps.has('selectedDate') && this.selectedDate) {
      const month = this.selectedDate.month() + 1;
      const year = this.selectedDate.year();
      this.__displayPage = {
        month,
        year,
      };

      const formattedDate = this.selectedDate.format('YYYY-MM-DD');

      if (this.availability && this.availability[formattedDate]) {
        this.__timeFrames = this.__getAvailabilityTimes();
      } else {
        this.__timeFrames = [];
      }
    }

    if (
      changedProps.has('type') ||
      changedProps.has('timeFrameAvailabilities')
    ) {
      if (!this.__selectedTimeRange && this.timeFrameAvailabilities) {
        this.__selectedTimeRange =
          this.timeFrameAvailabilities.find(
            timeFrame => timeFrame.duration[0] === this.selectedDate.hours(),
          ) || this.timeFrameAvailabilities[0];

        this.__timeFrames = this.__getAvailabilityTimes();
      }
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (
      (changedProps.has('availability') ||
        changedProps.has('__selectedTimeRange')) &&
      this.__selectedTimeRange &&
      this.availability
    ) {
      this.__timeRangeAvailability = { ...this.availability };

      Object.entries(this.__timeRangeAvailability).forEach(([date, hours]) => {
        this.__timeRangeAvailability[date] = hours.filter(hour =>
          this.__isHourWithinRange(hour),
        );
      });

      this.__timeFrames = this.__getAvailabilityTimes();
    }
  }

  __renderWalkInCheckbox() {
    return this.enableWalkIn
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxWalkIn.id}"
            name="walkIn"
            class="${this.hasEditRescheduleFF
              ? 'walk-in-checkbox'
              : 'max-content'}"
            label="Walk-In"
            .onChange="${this.__handlers.updateWalkIn}"
            ?checked="${this.walkIn}"
          ></neb-checkbox>
        `
      : '';
  }

  __showCurrentDateOnly(date) {
    return date.format('DD') === this.selectedDate.format('DD');
  }

  __isDateSelectable() {
    if (this.disableCalendarView) {
      return this.__handlers.showCurrentDate;
    }

    return this.type === SELECT_BY_TYPE.CUSTOM
      ? () => true
      : this.__handlers.isDateSelectable;
  }

  __renderCalendar() {
    return html`
      <neb-moment-calendar-view
        id="${ELEMENTS.viewCalendar.id}"
        class="calendar"
        .availability="${this.availability || this.type}"
        .disableMonthSelection="${(!this.availability &&
          this.type !== SELECT_BY_TYPE.CUSTOM) ||
        this.disableCalendarView}"
        .disableCalendarView="${this.disableCalendarView}"
        .displayPage="${this.__displayPage}"
        .selectedDate="${this.selectedDate}"
        .updateDependency="${this.__timeRangeAvailability}"
        .type="${TYPE.BUBBLES}"
        .isDateSelectable="${this.__isDateSelectable()}"
        .isToolbarVisible="${false}"
        .onChange="${this.__handlers.updateDate}"
        .onMonthChanged="${this.__handlers.updateMonth}"
      ></neb-moment-calendar-view>
    `;
  }

  __renderCustomTime() {
    return html`
      <neb-time
        id="${ELEMENTS.selectInputTimeCustom.id}"
        class="input input-time-custom"
        label="Select time"
        .time="${this.selectedDate}"
        .onChange="${this.__handlers.updateTimeByDropdown}"
        required
      >
      </neb-time>

      <div class="container-custom-info">
        <neb-icon class="icon icon-info" icon="neb:info"></neb-icon>

        <div class="text text-custom-info">
          Creating override appointments may result in conflicts with other
          appointments, provider availability, and scheduling rules.
        </div>
      </div>
    `;
  }

  __renderDateTime() {
    return html`
      <div class="text-date-time" id="${ELEMENTS.textDateTime.id}">
        ${this.__getDateTimeDisplay()}
      </div>
    `;
  }

  __renderSelectBy() {
    if (!this.shouldRenderSelectBy) return '';

    return html`
      <div class="container-find-by">
        <span>Select by</span>

        <neb-radio-button
          id="${ELEMENTS.radioDate.id}"
          class="radio-button"
          label="Date"
          .value="${SELECT_BY_TYPE.DATE}"
          .checked="${this.type === SELECT_BY_TYPE.DATE}"
          .onSelect="${this.__handlers.updateSelectBy}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioTime.id}"
          class="radio-button"
          label="Time"
          .value="${SELECT_BY_TYPE.TIME}"
          .checked="${this.type === SELECT_BY_TYPE.TIME}"
          .onSelect="${this.__handlers.updateSelectBy}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioCustom.id}"
          class="radio-button"
          label="Override"
          .value="${SELECT_BY_TYPE.CUSTOM}"
          .checked="${this.type === SELECT_BY_TYPE.CUSTOM}"
          .onSelect="${this.__handlers.updateSelectBy}"
        >
        </neb-radio-button>
      </div>
    `;
  }

  __renderSelectDuration() {
    return !this.hasSplits
      ? html`
          <neb-duration-field-lit
            id="${ELEMENTS.selectInputDuration.id}"
            class="duration"
            .duration="${this.duration}"
            .onChange="${this.__handlers.updateDuration}"
            .hasEditRescheduleFF="${this.hasEditRescheduleFF}"
          >
          </neb-duration-field-lit>
        `
      : '';
  }

  __renderSelectTimeFrame() {
    return html`
      <neb-md-select
        id="${ELEMENTS.selectInputTimeFrame.id}"
        class="select"
        labelText="Time Range"
        .disabled="${!this.timeFrameAvailabilities ||
        this.timeFrameAvailabilities.length === 0}"
        .items="${this.timeFrameAvailabilities || []}"
        .value="${this.__selectedTimeRange}"
        .onChange="${this.__handlers.updateTimeFrame}"
      ></neb-md-select>
    `;
  }

  __renderTimeList() {
    return this.availability
      ? html`
          <neb-time-list
            id="${ELEMENTS.viewTime.id}"
            class="time"
            .layout="${this.layout}"
            .selectedTime="${this.selectedDate}"
            .times="${this.__timeFrames}"
            .onTimeSelected="${this.__handlers.updateTime}"
            momentFlag
          ></neb-time-list>
        `
      : '';
  }

  __renderBig() {
    const renderSelectTimeFrame =
      this.type === SELECT_BY_TYPE.TIME
        ? this.__renderSelectTimeFrame()
        : html` <div class="select"></div> `;

    const renderCustomTime =
      this.type === SELECT_BY_TYPE.CUSTOM
        ? this.__renderCustomTime()
        : this.__renderTimeList();

    return html`
      <div class="container">
        ${this.hasEditRescheduleFF ? '' : this.__renderWalkInCheckbox()}

        <div class="container-details">
          <div class="container-left">
            ${this.hasEditRescheduleFF
              ? html`
                  ${this.__renderWalkInCheckbox()}${this.__renderSelectBy()}
                `
              : html` ${this.__renderSelectBy()} ${renderSelectTimeFrame} `}
            ${this.__renderCalendar()}
          </div>

          <div class="container-right">
            ${this.hasEditRescheduleFF
              ? renderSelectTimeFrame
              : html`
                  ${this.__renderSelectDuration()} ${this.__renderDateTime()}
                  ${renderCustomTime}
                `}
            ${this.hasEditRescheduleFF
              ? html`
                  <div class="duration-and-time">
                    ${this.__renderSelectDuration()} ${this.__renderDateTime()}
                    ${renderCustomTime}
                  </div>
                `
              : ''}
          </div>
        </div>
      </div>
    `;
  }

  __renderSmall() {
    return html`
      <div class="container">
        <div class="small-walkin">${this.__renderWalkInCheckbox()}</div>
        ${this.__renderSelectDuration()} ${this.__renderSelectBy()}
        ${this.type === SELECT_BY_TYPE.TIME
          ? this.__renderSelectTimeFrame()
          : ''}
        ${this.__renderCalendar()}
        <div class="container-right">${this.__renderDateTime()}</div>
        ${this.type === SELECT_BY_TYPE.CUSTOM
          ? this.__renderCustomTime()
          : this.__renderTimeList()}
      </div>
    `;
  }

  render() {
    return this.layout === 'small' ? this.__renderSmall() : this.__renderBig();
  }
}

customElements.define('neb-date-time', DateTime);
