import { html, css } from 'lit';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';

import NebOverlayFormItem, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay-form-item';
import {
  saveReferralCategory,
  updateReferralCategory,
} from '../../../api-clients/referrals-api-client';
import { formatReferralCategoryToUpdate } from '../../../formatters/referral-sources';
import { openError, openSuccess } from '../../../store';
import '../../forms/settings/neb-form-referral-source-category';
import { OVERLAY_WIDTH_LARGE } from '../../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
};

class NebOverlayReferralSourceCategory extends NebOverlayFormItem {
  static get config() {
    return {
      itemName: 'Referral Category',
    };
  }

  static get properties() {
    return {
      __savingError: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__savingError = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          const { id, name, active, sources } = model;

          if (id) {
            const formattedCategory = formatReferralCategoryToUpdate(model);

            await updateReferralCategory(formattedCategory);
          } else {
            await saveReferralCategory({ name, active, sources });
          }
          this.isDirty = false;
          this.dismiss(model);
          openSuccess('Referral Category saved successfully');
          this.__savingError = null;
        } catch (err) {
          openError('An error occurred saving the Referral Category');
          this.__savingError = err;
          console.error(err);
        }
      },
    };
  }

  renderForm() {
    return html`
      <neb-form-referral-source-category
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .categories="${this.model.categories}"
        .organizations="${this.model.organizations}"
        .savingError="${this.__savingError}"
        .onChange="${this.handlers.change}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
      >
      </neb-form-referral-source-category>
    `;
  }
}

customElements.define(
  'neb-overlay-referral-source-category',
  NebOverlayReferralSourceCategory,
);
