import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/neb-radio-button';
import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { formatDollarAmount } from '../../../../packages/neb-utils/formatters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  radios: { selector: '.radio' },
  payers: {
    selector: '[id^=payer-link-]',
  },
  numbers: {
    selector: '[id^=cell-number-]',
  },
  dates: {
    selector: '[id^=cell-date-]',
  },
  types: {
    selector: '[id^=cell-type-]',
  },
  paymentAmounts: {
    selector: '[id^=cell-paymentAmount-]',
  },
  payments: {
    selector: '[id^=cell-payment-]',
  },
};
class NebTableSelectEraEob extends NebTable {
  static get properties() {
    return {
      __selectedRow: Number,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.config = [];
    this.__selectedRow = 0;

    this.onSelectItem = () => {};
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      selectItem: index => {
        this.__selectedRow = index;
        this.onSelectItem(this.__selectedRow);
      },
    };
  }

  __buildConfig() {
    return [
      { key: 'radio', label: '', flex: css`0 0 60px` },
      {
        key: 'date',
        label: 'Date',
        flex: css`1 0 0`,
        truncate: true,
        formatter: d => parseDate(d).format('MM/DD/YYYY'),
      },
      {
        truncate: true,
        key: 'type',
        label: 'Type',
        flex: css`0.50 0 0`,
      },
      {
        truncate: true,
        key: 'number',
        label: 'ID',
        flex: css`.5 0 0`,
      },
      {
        key: 'payer',
        label: 'Payer',
        flex: css`1.50 0 0`,
      },
      {
        key: 'payment',
        label: 'Payment ID',
        flex: css`0.75 0 0`,
      },
      {
        key: 'paymentAmount',
        label: 'ERA/EOB Amount',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: 500px;
          width: 900px;
        }
        .content-container {
          padding: 0px;
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.config = this.__buildConfig();
    }

    super.update(changedProps);
  }

  __getPaymentInfo(rowIndex) {
    if (
      !this.model[rowIndex].payments ||
      this.model[rowIndex].payments.length !== 1
    ) {
      return undefined;
    }

    const [payment] = this.model[rowIndex].payments;
    return payment;
  }

  __renderPayerName({ rowIndex, columnConfig }) {
    const {
      type,
      payer: { name },
    } = this.model[rowIndex];
    const paymentInfo =
      type === 'ERA' ? this.__getPaymentInfo(rowIndex) : { payerName: name };
    return paymentInfo
      ? html`
          <div id="payer-link-${rowIndex}" key="${columnConfig.key}">
            <span class="payer-text">${paymentInfo.payerName}</span>
          </div>
        `
      : super.renderDataCell('-', columnConfig, rowIndex);
  }

  __renderPaymentNumber({ rowIndex, columnConfig }) {
    const paymentInfo = this.__getPaymentInfo(rowIndex);

    if (paymentInfo) {
      return html`
        <div
          id="payment-text-${rowIndex}"
          key="${columnConfig.key}"
          index="${rowIndex}"
        >
          <span>${paymentInfo.paymentNumber}</span>
        </div>
      `;
    }
    return super.renderDataCell('-', columnConfig, rowIndex);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'radio':
        return html`
          <neb-radio-button
            class="radio"
            .value="${rowIndex}"
            .onSelect="${this.handlers.selectItem}"
            .checked="${this.__selectedRow === rowIndex}"
          ></neb-radio-button>
        `;

      case 'payer':
        return this.__renderPayerName({ rowIndex, columnConfig });

      case 'payment':
        return this.__renderPaymentNumber({ rowIndex, columnConfig });
      default:
        return value;
    }
  }
}

customElements.define('neb-table-select-era-eob', NebTableSelectEraEob);
