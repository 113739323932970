import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../misc/neb-view-patient-insurance-bill-type-update';
import '../forms/neb-form-payment';
import { html, css } from 'lit';

import { getPatientAppointments } from '../../../packages/neb-api-client/src/patient-api-client';
import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { OVERLAY_WIDTH_LARGE } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';

export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

export class NebOverlayPatientInsuranceBillTypeUpdate extends Overlay {
  static get properties() {
    return {
      __appointments: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: column nowrap;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .view {
          height: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__appointments = [];

    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: appointmentIds => {
        this.onClose({
          appointmentIds,
        });
      },
      fetch: async () => {
        await this.__getPatientInsuranceBillTypeAppointments();
      },
    };
  }

  async __getPatientInsuranceBillTypeAppointments() {
    this.__appointments = await getPatientAppointments(this.model.patientId, {
      from: parseDate(this.model.dateRange.from).startOf('day').utc(),
      to: parseDate(this.model.dateRange.to).endOf('day').utc(),
      memberIdentifier: this.model.memberIdentifier,
    });
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.appointments) {
      this.__appointments = this.model.appointments;
    }

    super.update(changedProps);
  }

  dismissWithBlocker() {
    this.onClose({
      appointmentIds: [],
    });
  }

  renderContent() {
    return html`
      <neb-view-patient-insurance-bill-type-update
        id="${ELEMENTS.view.id}"
        class="view"
        .layout="${this.layout}"
        .model="${this.__appointments}"
        .insurance="${this.model.insurance}"
        .payerPlan="${this.model.payerPlan}"
        .dateRange="${this.model.dateRange}"
        .insuranceDates="${this.model.insuranceDates}"
        .onFetch="${this.handlers.fetch}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.save}"
      ></neb-view-patient-insurance-bill-type-update>
    `;
  }
}

customElements.define(
  'neb-overlay-patient-insurance-bill-type-update',
  NebOverlayPatientInsuranceBillTypeUpdate,
);
