import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { CSS_FONT_FAMILY } from '../../../packages/neb-styles/neb-variables';
import { openError } from '../../store';
import { CSS_SPACING } from '../../styles';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectDefault: {
    id: 'select-default',
  },
};

class NebPopupBillTypeSetDefault extends NebPopup {
  static get properties() {
    return {
      __error: Boolean,
      __selectedItem: Object,
    };
  }

  initState() {
    super.initState();
    this.model = {
      title: 'Set Default',
      message:
        'This patient does not have a default. Please set a default to continue',
      confirmText: 'Save',
      cancelText: 'Cancel',
      name: 'Item',
      items: [],
      bannerConfirm: '',
      bannerError: '',
      saveAction: () => {},
      patientId: '',
    };

    this.__selectedItem = null;
    this.__error = false;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      ...this.__handlers,
      confirm: async () => {
        this.validate();

        if (!this.__error) {
          try {
            await this.model.saveAction(
              this.__selectedItem,
              this.model.patientId,
            );

            this.onClose(true);
          } catch (e) {
            console.error(e);
            const errorMessage = `Error when saving default ${this.model.name.toLowerCase()}.`;
            store.dispatch(openError(errorMessage));
          }
        }
      },
      cancel: () => this.onClose(false),
      change: e => {
        if (e.value && e.value !== this.__selectedItem) {
          this.__selectedItem = e.value;
        }
      },
    };
  }

  validate() {
    this.__error = equal(this.__selectedItem, null);
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: space-between;
          height: 100%;
        }

        .button-container {
          display: flex;
          justify-content: start;
          margin-top: 30px;
        }

        .spacing {
          margin-right: 10px;
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div>${this.model.message}</div>
        <neb-select
          id="${ELEMENTS.selectDefault.id}"
          class="padding-top"
          helper="Required"
          .label="${`Default ${this.model.name}`}"
          .items="${this.model.items}"
          .value="${this.__selectedItem}"
          .error="${this.__error}"
          .onChange="${this.__handlers.change}"
        ></neb-select>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="spacing"
            .label="${this.model.confirmText}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            .label="${this.model.cancelText}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-bill-type-set-default',
  NebPopupBillTypeSetDefault,
);
