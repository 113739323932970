import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import NebForm from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { openDirtyPopup } from '../../../../../packages/neb-popup';
import { CSS_SPACING } from '../../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  nameField: { id: 'name-field' },
};

export default class NebFormChoiceTemplate extends NebForm {
  static createModel() {
    return {
      name: '',
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired()],
      },
    };
  }

  initState() {
    super.initState();

    this.confirmLabel = 'Done';
    this.cancelLabel = 'Cancel';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      addItem: ({ name }) => this.formService.addItem(name),

      removeItem: (name, _, index) => this.formService.removeItem(name, index),

      cancel: async () => {
        if (await openDirtyPopup()) {
          this.onCancel();
        }
      },
    };
  }

  renderChoices() {
    return html``;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.nameField.id}"
        class="field-name"
        label="Choice Template Title"
        helper="Required"
        name="name"
        maxLength="255"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      ${this.renderChoices()}
    `;
  }
}

window.customElements.define('neb-form-choice-template', NebFormChoiceTemplate);
