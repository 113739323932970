import '../../pages/blocked-off-time/neb-blocked-off-time-page';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getBlockedOffTimeById } from '../../../../packages/neb-api-client/src/appointment-api-client';
import {
  deleteBlockedOffTime,
  updateBlockedOffTime,
} from '../../../../packages/neb-api-client/src/blocked-off-time-api-client';
import { recurringBlockedOffTimeActions } from '../../../../packages/neb-calendar/neb-appointments-state';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { ProviderService } from '../../../../packages/neb-redux/services/provider';

export const ELEMENTS = {
  blockedOffTimePage: {
    id: 'blocked-off-time-page',
  },
};

const MESSAGE_DELETE_SINGLE_PROVIDER =
  'Are you sure you want to delete this blocked off time?';

const MESSAGE_DELETE_SINGLE_PROVIDER_ENTIRE_RECURRING =
  'Are you sure you want to delete this and all blocked off times created within this series?';

export const bannerMessage = {
  success: isSingleDelete =>
    `Blocked off time${isSingleDelete ? '' : 's'} deleted successfully`,
  error: isSingleDelete =>
    `Blocked off time${
      isSingleDelete ? ' was' : 's were'
    } not successfully deleted`,
};

const generateDeleteBlockedOffTimePopupDetail = message => ({
  title: 'Delete Blocked Off Time',
  message,
  confirmText: 'DELETE',
  cancelText: 'CANCEL',
});

class NebOverlayBlockedOffTimePage extends Overlay {
  static get properties() {
    return {
      __blockedOffTime: Object,
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 400px;
        }

        :host([layout='small']) .content {
          width: 100%;
        }

        .page {
          display: flex;
          overflow-y: hidden;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();

    this.model = {
      appointmentId: null,
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      edit: async () => {
        const res = await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_FORM, {
          appointment: this.__blockedOffTime,
          providerId: this.__blockedOffTime.providerId,
          ...(!this.__blockedOffTime.recurrenceEventId
            ? { providerIds: this.__blockedOffTime.groupProviderIds }
            : {}),
        });

        if (res) {
          this.__blockedOffTime = {
            ...this.__blockedOffTime,
            name: res.name,
            locationId: res.locationId,
            start: res.start,
            end: res.end,
            note: res.note,
            groupProviderIds: res.providerIds,
            resourceIds: res.resourceIds,
            resourceId: res.resourceIds.length ? res.resourceIds[0] : null,
            resourceNames: res.resourceNames,
            recurrenceEvent: this.__blockedOffTime.recurrenceEvent || {},
            providerId: res.providerIds.length ? res.providerIds[0] : null,
          };
        }
      },
      delete: async () => {
        const deleteConfirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          generateDeleteBlockedOffTimePopupDetail(
            MESSAGE_DELETE_SINGLE_PROVIDER,
          ),
        );

        if (deleteConfirmed) {
          try {
            if (this.__blockedOffTime.recurrenceEventId) {
              await updateBlockedOffTime(
                this.__blockedOffTime.groupId,
                this.__blockedOffTime,
                {
                  action:
                    recurringBlockedOffTimeActions.deleteSingleInstanceForSingleProvider,
                },
              );
            } else {
              await deleteBlockedOffTime(this.__blockedOffTime.groupId);
            }

            store.dispatch(openSuccess(bannerMessage.success(true)));
          } catch (error) {
            store.dispatch(openError(bannerMessage.error(true)));
          }

          this.handlers.dismiss(this.__blockedOffTime);
        }
      },
      deleteEntireSeries: async () => {
        const deleteConfirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          generateDeleteBlockedOffTimePopupDetail(
            MESSAGE_DELETE_SINGLE_PROVIDER_ENTIRE_RECURRING,
          ),
        );

        if (deleteConfirmed) {
          try {
            await updateBlockedOffTime(
              this.__blockedOffTime.groupId,
              this.__blockedOffTime,
              {
                action:
                  recurringBlockedOffTimeActions.deleteSeriesForSingleProvider,
              },
            );

            store.dispatch(openSuccess(bannerMessage.success(false)));
          } catch (error) {
            store.dispatch(openError(bannerMessage.error(false)));
          }

          this.handlers.dismiss(this.__blockedOffTime);
        }
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__allLocations = locations.reduce((acc, location) => {
        acc[location.id] = location;

        return acc;
      }, {});
    });

    this.__providersService = new ProviderService(({ providers }) => {
      this.__providers = providers.reduce((acc, provider) => {
        acc[provider.id] = provider;

        return acc;
      }, {});
    });
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
    this.__providersService.connect();

    this.__blockedOffTime = await getBlockedOffTimeById(
      this.model.appointmentId,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
    this.__providersService.disconnect();
  }

  renderContent() {
    return html`
      <neb-blocked-off-time-page
        id="${ELEMENTS.blockedOffTimePage.id}"
        class="page"
        .model="${this.__blockedOffTime}"
        .allLocations="${this.__allLocations}"
        .providers="${this.__providers}"
        .onDismiss="${this.handlers.dismiss}"
        .onEdit="${this.handlers.edit}"
        .onDelete="${this.handlers.delete}"
        .onDeleteEntireSeries="${this.handlers.deleteEntireSeries}"
      >
      </neb-blocked-off-time-page>
    `;
  }
}

window.customElements.define(
  'neb-overlay-blocked-off-time-page',
  NebOverlayBlockedOffTimePage,
);
