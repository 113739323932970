import { bulkUpdateStatus } from '../../../../packages/neb-api-client/src/claim-status';
import { CLAIM_STATUS } from '../../../../packages/neb-utils/claims';

export default ({ claimId, effectiveDate }) =>
  bulkUpdateStatus({
    claims: [
      {
        id: claimId,
        effectiveDate,
      },
    ],
    claimStatus: CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
    manuallyCreated: true,
  });
