import { formatAnswerSet } from '../../../src/formatters/macros';

import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'macro' });
export const pdfApiClient = new DeprecatedApiClient({
  microservice: 'pdf',
  useTenant: true,
});

export const saveMacroSet = macroSet => {
  delete macroSet.answerSets[macroSet.bodyAnswerSetId];
  delete macroSet.answerSets[macroSet.spineAnswerSetId];

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: macroSet.id ? Method.PUT : Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    path: macroSet.id ? `macro-set/${macroSet.id}` : 'macro-set',
    body: JSON.stringify(macroSet),
    responseType: RESPONSE_TYPE.RAW,
    version: 3,
  });
};

export const copyMacroSet = macroSetId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `macro-set/${macroSetId}`,
    responseType: RESPONSE_TYPE.RAW,

    version: 3,
  });

export const updateMacroSetStatus = (macroSetId, status) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `macro-set/${macroSetId}/status`,
    body: JSON.stringify({ status }),
    responseType: RESPONSE_TYPE.RAW,
  });

export const getMacroSetList = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: 'macro-set',
    cacheKey: 'macro-set-list',
    version: 3,
  });

export const getMacroSetById = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `macro-set/${id}`,
    version: 3,
  });

export const getMacroSetFolderById = (folderId, macroSetId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `macro-set/${macroSetId}/folder/${folderId}`,
    version: 3,
  });

export const getFolderItems = (queryParams = {}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: 'folder-items',
    queryParams,
  });

export const updateMacroSetOrder = macroSetIds =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: 'macro-set-order',
    body: JSON.stringify({ macroSetIds }),
    responseType: RESPONSE_TYPE.RAW,
  });

export const getChoiceTemplates = async (macroSetId, type) => {
  const { answerSets } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `macro-set/${macroSetId}/answer-set`,
    queryParams: { choiceTemplate: true },
    version: 3,
  });

  return formatAnswerSet(answerSets, type);
};

export const downloadMacroSet = async macroSetId => {
  const { url, fileName } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `macro-set/${macroSetId}/file`,
    responseType: RESPONSE_TYPE.OBJECT_URL,
    version: 3,
  });

  return { url: url(), fileName };
};

export const getUnsupportedMacroCommands = async macroSetId => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `macro-set/${macroSetId}/unsupported-macros`,
  });

  return res;
};

export const printUnsupportedMacroCommandsPdf = async unsupportedCommands => {
  const res = await pdfApiClient.makeRequest({
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    path: 'macroset',
    body: JSON.stringify(unsupportedCommands),
    version: 1,
  });

  return res.buffer;
};
