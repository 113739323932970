import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { formatAdjustmentAmount } from '../../../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-util';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { formatDollarAmount } from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import { CSS_WARNING_COLOR } from '../../../styles';
import { getProviderAdjustmentsTotal } from '../../../utils/payment-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  bulkActionMenu: { id: 'bulk-action-menu' },
  checkboxes: { selector: 'neb-checkbox' },
  editNoteIcon: { selector: '[id^=edit-note-icon-]' },
  rowDate: { selector: '[id^=cell-date-]' },
  rowNumber: { selector: '[id^=cell-number-]' },
  rowType: { selector: '[id^=cell-type-]' },
  rowPayer: { selector: '[id^=cell-payer-]' },
  rowPaymentNumber: { selector: '[id^=cell-paymentNumber-]' },
  rowPaymentAmount: { selector: '[id^=cell-paymentAmount-]' },
  rowPaymentAllocated: { selector: '[id^=cell-paymentAllocated-]' },
  rowPaymentBalance: { selector: '[id^=cell-paymentBalance-]' },
  rowAdjustmentAmount: { selector: '[id^=cell-adjustmentAmount-]' },
  rowAdjustmentApplied: { selector: '[id^=cell-adjustmentApplied-]' },
  splitIcons: {
    selector: '[id^=icon-split-]',
  },
  warningIcons: {
    selector: '[id^=warning-icon-]',
  },
};

class NebTableEraEobManagement extends NebTable {
  static get properties() {
    return {
      buttonLabel: String,
      isSupportApp: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-checkbox {
          padding: 0 5px;
        }

        .icon {
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-warning {
          display: block;
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_ERROR};
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .label {
          margin-right: 5px;
        }

        :host(:not([isSupportApp])) .payer-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }

        .payer-text {
          margin-right: 6px;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :host(:not([isSupportApp])) .payment-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }

        .payment-text {
          display: inline-block;
        }

        .era-eob-number-container {
          display: flex;
          align-items: center;
        }

        .icon-split {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }

        .edit-note {
          cursor: pointer;
          width: 9px;
          height: 9px;
          scale: 2.2;
          margin-left: 6px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .warning-icon {
          fill: ${CSS_WARNING_COLOR};
          height: 20px;
          width: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),
      clickLink: e => {
        e.stopPropagation();
        const key = e.currentTarget.getAttribute('key');
        const index = Number(e.currentTarget.getAttribute('index'));
        this.onClickLink(key, index);
      },
      getBulkActions: () => {
        const checkedRowItems = this.model.filter(row => row.checked);

        return !this.isSupportApp
          ? this.__bulkActionsPractice(checkedRowItems)
          : this.__bulkActionsSupport(checkedRowItems);
      },
      openBillingNotesOverlay: async e => {
        this.onClickNote(true);

        const type = e.currentTarget.getAttribute('type');
        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        const paymentType = e.currentTarget.getAttribute('paymentType');
        const row = this.model[rowIndex];

        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: type,
          parentId: row.payments.length
            ? row.payments[0].id
            : row.voidedPayment.id,
          parentData: {
            paymentType,
            transactionDate: parseDate(row.date).format('MM/DD/YYYY'),
            amount: row.paymentAmount,
            payer: row.payer.name,
            eobEraId: row.number,
            paymentId: row.payments.length
              ? row.payments[0].paymentNumber
              : '-',
          },
          patientId: row.payments.length
            ? row.payments[0].patientId
            : row.voidedPayment.patientId,
        });

        if (result === true) this.onUpdateBillingNotes();

        this.onClickNote(false);
      },
    };
  }

  initState() {
    super.initState();
    this.isSupportApp = false;
    this.mode = MODE.DETAIL;

    this.config = this.__buildConfig();

    this.buttonLabel = '';

    this.onClickLink = () => {};

    this.onClickNote = () => {};

    this.onDeselectAll = () => {};

    this.onSelectAll = () => {};

    this.onUpdateStatus = () => {};

    this.onReprocessEra = () => {};

    this.onUpdateBillingNotes = () => {};
  }

  __buildConfig() {
    return [
      {
        key: 'checked',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'hidden',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        flex: css`1 0 0`,
        formatter: (d, rowData) => {
          const { date } = this.__getPaymentInfo(rowData);
          const paymentDate = date || d;
          return parseDate(paymentDate).format('MM/DD/YYYY');
        },
      },
      ...(!this.isSupportApp
        ? [
            {
              truncate: true,
              key: 'type',
              label: 'Type',
              flex: css`0.50 0 0`,
            },
          ]
        : []),
      {
        truncate: true,
        sortable: true,
        key: 'number',
        label: 'ID',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        sortable: true,
        key: 'payer',
        label: 'Payer',
        flex: css`1.50 0 0`,
      },
      {
        key: 'payment',
        label: 'Payment ID',
        flex: css`0.75 0 0`,
      },
      {
        key: 'paymentAmount',
        label: !this.isSupportApp ? 'ERA/EOB Amount' : 'ERA Amount',
        flex: css`1 0 0`,
        formatter: v => html` <neb-text>${formatDollarAmount(v)}</neb-text> `,
      },
      {
        key: 'paymentAllocated',
        label: 'Payment Allocated',
        flex: css`1 0 0`,
      },
      {
        key: 'paymentBalance',
        label: 'Payment Balance',
        flex: css`1 0 0`,
      },
      {
        key: 'adjustmentAmount',
        label: 'Adjustment Amount',
        flex: css`1 0 0`,
        formatter: v => html`
          <neb-text>${formatAdjustmentAmount(v, true)}</neb-text>
        `,
      },
      {
        key: 'adjustmentApplied',
        label: 'Adjustment Applied',
        flex: css`1 0 0`,
        formatter: v => html`
          <neb-text>${formatAdjustmentAmount(v, true)}</neb-text>
        `,
      },
    ];
  }

  __bulkActionsPractice(checkedRowItems) {
    return [
      {
        id: 'selectAll',
        label: 'Select All',
        onSelect: this.onSelectAll,
      },
      {
        id: 'deselectAll',
        label: 'Deselect All',
        onSelect: this.onDeselectAll,
      },
      {
        id: 'updateStatus',
        label: this.buttonLabel,
        onSelect: () => this.onUpdateStatus(checkedRowItems),
      },
    ];
  }

  __bulkActionsSupport(checkedRowItems) {
    return [
      {
        id: 'deselectAll',
        label: 'Deselect All',
        onSelect: this.onDeselectAll,
      },
      ...(checkedRowItems.length === 1
        ? [
            {
              id: 'reprocessEra',
              label: 'Reprocess',
              onSelect: () => this.onReprocessEra(checkedRowItems[0]),
            },
          ]
        : []),
    ];
  }

  update(changedProps) {
    if (changedProps.has('isSupportApp')) {
      this.config = this.__buildConfig();
    }

    super.update(changedProps);
  }

  __getPaymentInfo(rowData) {
    if (!rowData.payments || rowData.payments.length !== 1) {
      return {};
    }

    const [payment] = rowData.payments;
    return payment;
  }

  __renderPayerName({ rowIndex, columnConfig }) {
    const {
      type,
      payer: { name },
    } = this.model[rowIndex];
    const { payerName } =
      type === 'ERA'
        ? this.__getPaymentInfo(this.model[rowIndex])
        : { payerName: name };
    return payerName
      ? html`
          <div
            id="payer-link-${rowIndex}"
            key="${columnConfig.key}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickLink}"
          >
            <span class="payer-text">${payerName}</span>
          </div>
        `
      : super.renderDataCell('-', columnConfig, rowIndex);
  }

  __renderPaymentNumber({ rowIndex, columnConfig }) {
    const { paymentNumber } = this.__getPaymentInfo(this.model[rowIndex]);

    if (paymentNumber) {
      return this.model[rowIndex].isLegacy
        ? html`
            <div
              id="payment-link-${rowIndex}"
              class="button"
              key="${columnConfig.key}"
              index="${rowIndex}"
              @click="${this.handlers.clickLink}"
            >
              <span class="payment-text">${paymentNumber}</span>
            </div>
          `
        : html`
            <div
              id="payment-text-${rowIndex}"
              key="${columnConfig.key}"
              index="${rowIndex}"
            >
              <span>${paymentNumber}</span>
            </div>
          `;
    }
    return super.renderDataCell('-', columnConfig, rowIndex);
  }

  __reportHasPayments(rowIndex) {
    return (
      this.model[rowIndex].payments && this.model[rowIndex].payments.length > 0
    );
  }

  __renderPaymentValues({ value, rowIndex, columnConfig }) {
    return this.__reportHasPayments(rowIndex)
      ? html` <neb-text>${formatDollarAmount(value || 0)}</neb-text> `
      : super.renderDataCell('-', columnConfig, rowIndex);
  }

  __calculatePaymentBalance(rowIndex) {
    let paymentBalance = 0;

    if (
      this.model[rowIndex].payments &&
      this.model[rowIndex].payments.length &&
      this.model[rowIndex].payments[0].providerAdjustments
    ) {
      const payment = this.model[rowIndex].payments[0];
      const providerAdjustments = payment.providerAdjustments ?? [];
      const paymentAmount = payment.paymentAmount ?? payment.amount;
      const paymentAllocated =
        payment.paymentAllocated ?? paymentAmount - payment.available;

      paymentBalance =
        paymentAmount -
        paymentAllocated +
        getProviderAdjustmentsTotal(providerAdjustments);
    } else {
      paymentBalance = this.model[rowIndex].paymentBalance;
    }

    return formatDollarAmount(paymentBalance);
  }

  __renderPaymentBalance({ rowIndex, columnConfig }) {
    return this.__reportHasPayments(rowIndex)
      ? html` <neb-text>${this.__calculatePaymentBalance(rowIndex)}</neb-text> `
      : super.renderDataCell('-', columnConfig, rowIndex);
  }

  __isSplitPayment(rowIndex) {
    return (
      this.model[rowIndex].payments &&
      this.model[rowIndex].payments.length &&
      this.model[rowIndex].payments[0].parentPaymentId
    );
  }

  __renderParentPaymentIcon(rowIndex) {
    return this.__isSplitPayment(rowIndex)
      ? html`
          <neb-icon
            id="icon-split-${rowIndex}"
            class="icon-split"
            icon="neb:split"
          ></neb-icon>
        `
      : '';
  }

  __renderNoteIcon(rowIndex) {
    const row = this.model[rowIndex];

    if (
      (row.payments && row.payments[0] && row.payments[0].hasBillingNote) ||
      (row.voidedPayment && row.voidedPayment.hasBillingNote)
    ) {
      return html`
        <neb-icon
          id="edit-note-icon-${rowIndex}"
          class="edit-note"
          icon="neb:editNote"
          type="${BILLING_NOTE_TYPES.PAYMENT}"
          rowIndex="${rowIndex}"
          paymentType="${row.type}"
          @click="${this.handlers.openBillingNotesOverlay}"
        ></neb-icon>
      `;
    }

    return '';
  }

  __renderEraEobId({ value, rowIndex, columnConfig }) {
    return html`
      <div
        id="payment-number-${rowIndex}"
        class="era-eob-number-container"
        key="${columnConfig.key}"
      >
        ${value} ${this.__renderParentPaymentIcon(rowIndex)}
        ${this.__renderNoteIcon(rowIndex)}
      </div>
    `;
  }

  __renderHidden(rowIndex) {
    const { hasWarnings } = this.model[rowIndex];

    if (hasWarnings) {
      return html`
        <neb-icon
          id="warning-icon-${rowIndex}"
          class="warning-icon"
          icon="neb:warning"
        ></neb-icon>
      `;
    }

    return '';
  }

  renderHeaderCell(headerCell) {
    return headerCell.key === 'checked'
      ? html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .onClick="${this.handlers.getBulkActions}"
          ></neb-button-actions>
        `
      : super.renderHeaderCell(headerCell);
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex, name) {
    switch (columnConfig.key) {
      case 'checked':
        return html`
          <neb-checkbox
            class="cell-checkbox"
            .name="${name}"
            .onChange="${this.handlers.change}"
            ?checked="${value}"
            @click="${this.handlers.clickCheckbox}"
          ></neb-checkbox>
        `;

      case 'hidden':
        return this.__renderHidden(rowIndex);

      case 'number':
        return this.__renderEraEobId({ value, rowIndex, columnConfig });

      case 'payer':
        return this.__renderPayerName({ rowIndex, columnConfig });

      case 'payment':
        return this.__renderPaymentNumber({
          value,
          rowIndex,
          columnConfig,
        });

      case 'paymentAllocated':
        return this.__renderPaymentValues({ value, rowIndex, columnConfig });

      case 'paymentBalance':
        return this.__renderPaymentBalance({ rowIndex, columnConfig });

      default:
        return value;
    }
  }
}

customElements.define('neb-table-era-eob-management', NebTableEraEobManagement);
