import { fiservReadCard } from '../fiserv-api-client';

import { handleCardReaderError } from './handle-card-reader-error';

export const readCard = async (reader, amount) => {
  const { readerId, sessionKey } = reader;

  try {
    const { fiserv } = await fiservReadCard(readerId, { sessionKey, amount });
    return reader.isCanceled ? null : fiserv;
  } catch (error) {
    return reader.isCanceled
      ? null
      : handleCardReaderError({
          error,
          message: 'Cannot read card',
          retryCallback: readCard,
          retryCallbackArgs: [reader, amount],
        });
  }
};
