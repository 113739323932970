import { getLineItemDetails } from '../../../packages/neb-api-client/src/ledger/line-items';
import { getEobSecondaryClaimDraftsToBeCreated } from '../../api-clients/eob';
import { getEraSecondaryClaimDraftsToBeCreated } from '../../api-clients/era';
import {
  getSecondaryOwedTotal,
  isSecondaryRespLineItemDebit,
} from '../ledger-form/neb-ledger-form-util';

const getLineItemDebitPaidSumTotal = lineItemDebit =>
  lineItemDebit.debit.allocations.reduce((sum, a) => sum + a.amount, 0);

const getSecondaryPaidSumTotal = lineItem => {
  const totalDebitCents = lineItem.lineItemDebits.reduce(
    (total, lineitemDebit) =>
      isSecondaryRespLineItemDebit(lineitemDebit, lineItem)
        ? total + getLineItemDebitPaidSumTotal(lineitemDebit)
        : total,
    0,
  );

  return totalDebitCents;
};

export const shouldCreateSecondaryClaims = async ({
  isEra,
  id,
  lineItemIds,
  primaryPaymentFinancialClass,
}) => {
  const lineItems = await getLineItemDetails({}, { lineItemIds });

  const lineItemsWithSecondaryRespAndHasBalance = lineItems.filter(lineItem =>
    lineItem.lineItemDebits.some(
      lineitemDebit =>
        isSecondaryRespLineItemDebit(lineitemDebit, lineItem) &&
        getSecondaryOwedTotal(lineItem) - getSecondaryPaidSumTotal(lineItem) >
          0,
    ),
  );

  if (!lineItemsWithSecondaryRespAndHasBalance.length) {
    return false;
  }

  const secondaryClaimsDrafts = isEra
    ? await getEraSecondaryClaimDraftsToBeCreated(id, lineItemIds)
    : await getEobSecondaryClaimDraftsToBeCreated(id, lineItemIds);

  if (secondaryClaimsDrafts.count === 0) return false;

  if (primaryPaymentFinancialClass === 'Medicare') return false;

  return true;
};
