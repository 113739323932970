import '../neb-radio-button';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_ERROR,
  CSS_FONT_SIZE_CAPTION,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';

import { SVG } from './utils/spine-svgs';

export const ID_LABEL = 'label';
export const ID_RADIO_LEFT = 'radio-left';
export const ID_RADIO_RIGHT = 'radio-right';
export const ID_SVG = 'svg';
export const ID_SVG_CONTAINER = 'svg-container';
export const ID_SVG_LEFT = 'svg-left';
export const ID_SVG_RIGHT = 'svg-right';
export const FILL_COLOR = CSS_COLOR_ERROR;
export const SVG_TYPES = {
  OCCIPUT: 'OCCIPUT',
  C_DISKS: 'C_DISKS',
  T_DISKS: 'T_DISKS',
  L_DISKS: 'L_DISKS',
  SACRUM: 'SACRUM',
  PELVIS: 'PELVIS',
  COCCYX: 'COCCYX',
};
export const ELEMENTS = {
  options: {
    selector: 'neb-radio-button',
  },
};

class NebMacroSpineSection extends LitElement {
  static get properties() {
    return {
      label: String,
      value: String,
      selectedDirection: String,
      svgType: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.label = '';
    this.value = '';
    this.selectedDirection = '';
    this.svgType = SVG_TYPES.OCCIPUT;

    this.onSectionSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      sideSelected: side => {
        this.onSectionSelected(side, this.value);
      },
      centerSelected: () => {
        this.onSectionSelected('center', this.value);
      },
    };
  }

  getColorForSection(direction) {
    return this.selectedDirection === 'center' ||
      this.selectedDirection === direction
      ? FILL_COLOR
      : 'none';
  }

  static get styles() {
    return css`
      .spine-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 25px;
      }

      :host([svgType='SACRUM']) .spine-section {
        height: 70px;
        align-items: flex-start;
      }

      :host([svgType='COCCYX']) .spine-section {
        margin-top: 2px;
        height: 28px;
      }

      .section {
        margin: 10px;
      }

      :host([svgType='SACRUM']) .section {
        margin: 5px;
      }

      :host([svgType='COCCYX']) .section {
        margin: 5px;
      }

      .spine-section-label {
        user-select: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: ${CSS_FONT_SIZE_CAPTION};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      svg {
        height: 22px;
      }

      .svg-container {
        position: relative;
        cursor: pointer;
      }
    `;
  }

  render() {
    return html`
      <div class="spine-section">
        <neb-radio-button
          id="${ID_RADIO_LEFT}"
          class="section"
          .onSelect="${this.__handlers.sideSelected}"
          .value="${'left'}"
          .checked="${this.selectedDirection === 'left'}"
          noInk
        ></neb-radio-button>

        <div
          id="${ID_SVG_CONTAINER}"
          class="svg-container"
          @click="${this.__handlers.centerSelected}"
        >
          ${SVG[this.svgType](
            ID_SVG,
            this.getColorForSection('left'),
            this.getColorForSection('right'),
          )}

          <div id="${ID_LABEL}" class="spine-section-label">${this.label}</div>
        </div>
        <neb-radio-button
          id="${ID_RADIO_RIGHT}"
          class="section"
          .value="${'right'}"
          .checked="${this.selectedDirection === 'right'}"
          .onSelect="${this.__handlers.sideSelected}"
          noInk
        ></neb-radio-button>
      </div>
    `;
  }
}

customElements.define('neb-macro-spine-section', NebMacroSpineSection);
