import '../../neb-lit-components/src/components/neb-file-select';
import '../../neb-lit-components/src/components/neb-file-preview';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import mime from 'mime-types';

import { openError } from '../../neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_SPACING, CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';
import {
  readDataUrlFromBlob,
  validateBlob,
  validateImageResolution,
} from '../../neb-utils/blobProcessor';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  closeButton: {
    id: 'button-close',
  },
  saveButton: {
    id: 'button-save',
  },
  contentPreview: {
    id: 'content-preview-image',
  },
  fileSelect: {
    id: 'content-file-select',
  },
  imagePreview: {
    id: 'preview-image',
  },
};
export const IMG_BAD_FILE_ERROR =
  'Bad or corrupt file. Try fixing file and upload again.';
const MAX_FILE_SIZE = 10 * 1024 * 1024;

class NebPopupFileUpload extends NebPopup {
  static get properties() {
    return {
      __allowedFileExtensions: Object,
      __dataUrl: {
        type: String,
      },
      __fileBlob: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      allowedFileTypes: [],
      maxFileSize: 0,
      title: '',
      touchDevice: false,
      validationFailureMessage: '',
    };

    this.__allowedFileExtensions = {};
    this.__dataUrl = '';
    this.__fileBlob = null;

    this.onClose = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => this.onClose(this.__dataUrl),
      invalidFile: () => this.__invalidFile(),
    };
  }

  firstUpdated() {
    this.__allowedFileExtensions = [
      'jpg',
      'svg',
      ...this.model.allowedFileTypes.map(mimeType => mime.extension(mimeType)),
    ];
  }

  modelChanged() {
    this.title = this.model.title;
  }

  async __displayBlobValidationError() {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Invalid Selection',
      message: this.model.validationFailureMessage,
    });
  }

  async __readFile(blob) {
    this.__fileBlob = blob;
    this.__dataUrl = await readDataUrlFromBlob(blob);
  }

  async __processFileBlobForPreview(fileBlob) {
    if (
      validateBlob(
        fileBlob,
        this.model.maxFileSize,
        this.model.allowedFileTypes,
      ) &&
      (await validateImageResolution(fileBlob))
    ) {
      this.__readFile(fileBlob);
    } else {
      this.__displayBlobValidationError();
    }
  }

  __invalidFile() {
    store.dispatch(openError(IMG_BAD_FILE_ERROR));
    this.__fileBlob = null;
    this.__dataUrl = '';
  }

  __renderPreview() {
    return html`
      <div id="${ELEMENTS.contentPreview.id}" class="content-container">
        <neb-file-preview
          id="${ELEMENTS.imagePreview.id}"
          class="preview"
          .fileBlob="${this.__fileBlob}"
          .onInvalidFile="${this.__handlers.invalidFile}"
        ></neb-file-preview>
      </div>
    `;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;

          width: 540px;
          height: 280px;

          top: 140px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .container-file-upload {
          display: flex;
          flex-direction: column;

          box-sizing: border-box;
        }

        .content-container {
          height: 100%;
        }

        .preview {
          height: 100%;
        }

        .container-button {
          display: flex;
          flex-direction: row;

          margin-top: ${CSS_SPACING};
        }

        .input-file-upload {
          margin: 0;
        }

        .button:not(:last-child) {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="containerFileUpload" class="container-file-upload">
        <neb-file-select
          id="${ELEMENTS.fileSelect.id}"
          class="input-file-upload"
          .allowedExtensions="${this.__allowedFileExtensions}"
          .onSelectFile="${this.__processFileBlobForPreview.bind(this)}"
          .touchDevice="${this.model.touchDevice}"
          .showPreview="${this.__fileBlob !== null}"
          .maxFileSize="${MAX_FILE_SIZE}"
          .validMimeTypes="${this.model.allowedFileTypes}"
          .invalidFileMessage="${this.model.validationFailureMessage}"
        >
          ${this.__fileBlob ? this.__renderPreview() : ''}
        </neb-file-select>

        <div class="container-button">
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button-primary-focus button button-save"
            label="SAVE"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
            ?disabled="${!this.__dataUrl}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.closeButton.id}"
            class="button-secondary button button-cancel"
            label="CANCEL"
            .role="${BUTTON_ROLE.CANCEL}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-file-upload', NebPopupFileUpload);
