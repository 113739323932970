import '../../../../../src/components/misc/neb-icon';
import './neb-encounter-history-list';
import './neb-encounter-summary';

import { html, LitElement, css } from 'lit';

import {
  getEncounterVersion,
  printEncounterVersion,
} from '../../../../neb-api-client/src/encounters-api-client';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPracticeUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  EncounterDataService,
  HISTORY,
} from '../../../../neb-api-client/src/services/encounter-data';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';
import { objToName } from '../../../../neb-utils/formatters';
import {
  formatProviderName,
  getFormatedNameOptions,
} from '../../../../neb-utils/neb-charting-util';
import { formatEncounterSummaryData } from '../../../../neb-utils/neb-encounters-util';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import { BUTTON_ROLE } from '../neb-button';

export const ELEMENTS = {
  containerList: {
    id: 'container-list',
  },
  containerSummary: {
    id: 'container-summary',
  },
  buttonPrint: {
    id: 'button-print',
    label: 'PRINT',
  },
  iconDismiss: {
    id: 'icon-dismiss',
    icon: 'neb:close',
  },
  historyList: {
    id: 'history-list',
  },
  encounterSummary: {
    id: 'encounter-summary',
  },
};

class NebEncounterHistoryController extends LitElement {
  static get properties() {
    return {
      encounterId: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      __encounterHistory: {
        type: Array,
      },
      __selectedDataModel: {
        type: Object,
      },
      __selectedHistoryId: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.encounterId = '';
    this.layout = '';
    this.__encounterHistory = [];
    this.__selectedDataModel = {};
    this.__selectedHistoryId = '';
    this.__encounterDataService = new EncounterDataService(({ history }) =>
      this.__setEncounterHistory(history),
    );

    this.onDismiss = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      dismiss: () => this.onDismiss(),
      print: () => this.__handlePrint(),
      selectHistoryId: id => this.__handleSelectHistoryId(id),
    };
  }

  updated(changed) {
    this.__encounterIdChanged(changed);
  }

  __encounterIdChanged(changed) {
    if (changed.has('encounterId')) {
      this.__encounterDataService.update(this.encounterId, [HISTORY]);
    }
  }

  async __handleSelectHistoryVersion(selectedId) {
    if (
      this.__shouldSelectLatestVersion(
        this.__encounterHistory,
        selectedId,
        this.layout,
      )
    ) {
      await this.__handleSelectHistoryId(this.__encounterHistory[0].id);
    }
  }

  __shouldSelectLatestVersion(encounterHistory, selectedId, layout) {
    return encounterHistory.length > 0 && !selectedId && layout !== 'small';
  }

  async __getEncounterSummaryData() {
    const encounterVersion = await getEncounterVersion(
      this.encounterId,
      this.__selectedHistoryId,
    );
    const patient = await patientApiClient.fetchOne(
      encounterVersion.patientId,
      false,
      true,
    );

    this.__selectedDataModel = formatEncounterSummaryData({
      encounter: encounterVersion,
      notes: encounterVersion.notes,
      patient,
      state: store.getState(),
    });
  }

  async __setEncounterHistory(histories) {
    const {
      providers: { item: providers },
    } = store.getState();

    const isChangedBy = histories.find(history => !!history.changedBy);
    const users = {};
    const summary = true;

    if (isChangedBy) {
      const practiceUsers = await getPracticeUsers();
      practiceUsers.forEach(practiceUser => {
        users[practiceUser.id] = practiceUser;
      });
    }

    this.__encounterHistory = histories
      .map(history => {
        const providerName = formatProviderName(
          history.signerId,
          providers,
          summary,
        );

        if (history.changedBy) {
          const user = users[history.changedBy];

          if (user) {
            const formatNameOptions = getFormatedNameOptions(summary);
            history.changedName = objToName(user.name, formatNameOptions);
          }
        }

        return { ...history, providerName };
      })
      .sort((a, b) => b.id - a.id);

    await this.__handleSelectHistoryVersion(this.__selectedHistoryId);
  }

  async __handleSelectHistoryId(id) {
    this.__selectedHistoryId = id;
    await this.__getEncounterSummaryData();
  }

  __handlePrint() {
    const encounterHistory = this.__encounterHistory.find(
      encHis => encHis.id === this.__selectedHistoryId,
    );

    printPdf(
      printEncounterVersion(this.encounterId, this.__selectedHistoryId, {
        providerName: encounterHistory.providerName,
      }),
    );
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        width: 70vw;
        height: 100%;
        font-family: ${CSS_FONT_FAMILY};
        border-top: ${CSS_BORDER_GREY_2};
      }
      .container-list,
      .container-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .container-list {
        flex: 1 0 0;
        min-width: 400px;
      }
      .container-summary {
        flex: 2 0 0;
        border-left: ${CSS_BORDER_GREY_2};
        overflow-y: auto;
        overflow-x: hidden;
      }
      .title {
        font-size: ${CSS_FONT_SIZE_HEADER};
        padding: ${CSS_SPACING};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }
      .container-summary-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: ${CSS_SPACING} 0;
      }
      .encounter-summary {
        padding: 0 ${CSS_SPACING};
      }
      .button-print {
        margin-left: 10px;
      }
      .icon-clear {
        height: 24px;
        width: 24px;
        fill: ${CSS_COLOR_GREY_1};
        margin-right: ${CSS_SPACING};
        cursor: pointer;
      }
      :host([layout='small']) {
        width: 100%;
      }
      :host([layout='medium']) {
        width: 90vw;
      }
    `;
  }

  __renderList() {
    if (this.layout === 'small' && this.__selectedHistoryId) return '';

    return html`
      <div id="${ELEMENTS.containerList.id}" class="container-list">
        <span class="title">Previous Versions</span>
        <neb-encounter-history-list
          id="${ELEMENTS.historyList.id}"
          .models="${this.__encounterHistory}"
          .selectedId="${this.__selectedHistoryId}"
          .onClickItem="${this.__handlers.selectHistoryId}"
        ></neb-encounter-history-list>
      </div>
    `;
  }

  __renderSummary() {
    if (this.layout === 'small' && !this.__selectedHistoryId) return '';

    return html`
      <div id="${ELEMENTS.containerSummary.id}" class="container-summary">
        <div class="container-summary-header">
          <neb-button
            id="${ELEMENTS.buttonPrint.id}"
            class="button-print"
            .role="${BUTTON_ROLE.OUTLINE}"
            .label="${ELEMENTS.buttonPrint.label}"
            .onClick="${this.__handlers.print}"
          ></neb-button>
          <neb-icon
            id="${ELEMENTS.iconDismiss.id}"
            icon="${ELEMENTS.iconDismiss.icon}"
            class="icon-clear"
            @click="${this.__handlers.dismiss}"
          ></neb-icon>
        </div>
        <neb-encounter-summary
          id="${ELEMENTS.encounterSummary.id}"
          class="encounter-summary"
          .layout="${this.layout}"
          .model="${this.__selectedDataModel}"
        ></neb-encounter-summary>
      </div>
    `;
  }

  render() {
    return html` ${this.__renderList()} ${this.__renderSummary()} `;
  }
}

customElements.define(
  'neb-encounter-history-controller',
  NebEncounterHistoryController,
);
