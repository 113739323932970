import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/patients/cases/neb-patient-case-search';

import { html, css } from 'lit';

import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import * as masks from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { CSS_FIELD_MARGIN } from '../../styles';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  providerSelect: { id: 'provider-select' },
  encounterNumberInput: { id: 'encounter-number-input' },
  serviceDateInput: { id: 'service-date-input' },
  appointmentTypeIdInput: { id: 'appointment-type-input' },
  caseIdInput: { id: 'case-id-input' },
};

const FILTERS = Object.freeze({
  providerIds: 'providerIds',
  encounterNumber: 'encounterNumber',
  serviceDate: 'serviceDate',
  appointmentTypeId: 'appointmentTypeId',
  caseId: 'caseId',
});

class NebFiltersAssociateEncounters extends NebFilters {
  static get properties() {
    return {
      model: {
        apptTypes: { type: Array },
        providers: { type: Array },
        patientId: { type: String },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            '. .'
            'd d'
            '. .';

          grid-gap: 0 12px;
          align-items: flex-start;
          margin-bottom: ${CSS_FIELD_MARGIN};
        }

        .date {
          margin-top: 16px;
          grid-area: d;
        }
      `,
    ];
  }

  static createModel() {
    return {
      encounterNumber: '',
      providerIds: [],
      serviceDate: NebDateRange.createModel(),
      appointmentTypeId: {},
      caseId: '',
    };
  }

  initState() {
    super.initState();

    this.model = {
      apptTypes: [],
      providers: [],
      patientId: '',
    };
  }

  deleteEmptyValues(object) {
    Object.entries(object).forEach(([key, value]) => {
      if (!value || value.length === 0) delete object[key];
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      clearEncounterSearch: () => this.formService.apply('encounterNumber', ''),
      apply: () => {
        let model = this.formService.build();

        const { from, to } = model.serviceDate;

        model = {
          ...model,
          dateFrom: from ? from._i : null,
          dateTo: to ? to._i : null,
        };

        this.deleteEmptyValues(model);
        delete model.serviceDate;

        this.onApply(model);
      },
      changeCase: caseId => {
        this.formService.apply(FILTERS.caseId, caseId);
      },
    };
  }

  createSelectors() {
    return {
      children: {
        encounterNumber: selectors.numeric(),
        providerIds: selectors.multiSelect([]),
        serviceDate: NebDateRange.createSelectors(),
        appointmentTypeId: selectors.select([]),
      },
    };
  }

  formatProvidersToItems(items) {
    return items.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));
  }

  formatAppointmentsToItems(items) {
    const providerItems = items.map(data => ({
      label: data.name,
      data,
    }));

    providerItems.unshift(selectors.ITEM_EMPTY);

    return providerItems;
  }

  renderContent() {
    const serviceDate = {
      from: this.state.serviceDate.from
        ? parseDate(this.state.serviceDate.from)
        : null,
      to: this.state.serviceDate.to
        ? parseDate(this.state.serviceDate.to)
        : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        label="Providers"
        name="${FILTERS.providerIds}"
        multiSelect
        .items="${this.formatProvidersToItems(this.providers)}"
        .value="${this.state.providerIds}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-select
        id="${ELEMENTS.appointmentTypeIdInput.id}"
        label="Appointment Type"
        name="${FILTERS.appointmentTypeId}"
        .items="${this.formatAppointmentsToItems(this.apptTypes)}"
        .value="${this.state.appointmentTypeId}"
        .onChange="${this.handlers.change}"
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.serviceDateInput.id}"
        name="${FILTERS.serviceDate}"
        class="date"
        label="Date of Service"
        .model="${serviceDate}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.encounterNumberInput.id}"
        label="Encounter #"
        name="${FILTERS.encounterNumber}"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.encounterNumber ? 'neb:clear' : ''}"
        .value="${this.state.encounterNumber}"
        .mask="${masks.number}"
        .onClickIcon="${this.handlers.clearEncounterSearch}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-patient-case-search
        id="${ELEMENTS.caseIdInput.id}"
        name="${FILTERS.caseId}"
        .patientId="${this.patientId}"
        .caseId="${this.state.caseId}"
        .onChange="${this.handlers.changeCase}"
      ></neb-patient-case-search>
    `;
  }
}

window.customElements.define(
  'neb-filters-associate-encounters',
  NebFiltersAssociateEncounters,
);
