import { getBillingCodesCharges } from '../../../neb-api-client/src/billing-codes';

export const getFeeCharges = async () =>
  (await getBillingCodesCharges())
    .filter(cc => cc.active && cc.type === 'Fee')
    .sort((a, b) =>
      a.code === b.code
        ? a.description.localeCompare(b.description)
        : a.code.localeCompare(b.code),
    );
