const ZOOM_INCREMENT_VALUE = 10;
const ZOOM_MAX_VALUE = 1000;
const ZOOM_MIN_VALUE = 10;

const IMAGE_JPEG = 'image/jpeg';
const DATA_URL_PNG_START = `data:${IMAGE_JPEG}`;

const loadImageSrc = imageSrc =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      resolve(image);
    };

    image.onerror = e => {
      reject(e);
    };
  });

export const loadImage = async ({ canvas, imageSrc, contentSize }) => {
  const ctx = canvas.getContext('2d');
  canvas.width = contentSize.width;
  canvas.height = contentSize.height;

  const image = await loadImageSrc(imageSrc);

  const scaleFactor = Math.min(
    canvas.width / image.width,
    canvas.height / image.height,
  );

  const newWidth = image.width * scaleFactor;
  const newHeight = image.height * scaleFactor;

  const x = canvas.width / 2 - newWidth / 2;
  const y = canvas.height / 2 - newHeight / 2;

  ctx.drawImage(image, x, y, newWidth, newHeight);

  return ctx;
};

export const getNextZoomInValue = zoomValue =>
  Math.min(zoomValue + ZOOM_INCREMENT_VALUE, ZOOM_MAX_VALUE);

export const getNextZoomOutValue = zoomValue =>
  Math.max(zoomValue - ZOOM_INCREMENT_VALUE, ZOOM_MIN_VALUE);

export const rotateImage = async (imageSrc, clockwise) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = await loadImageSrc(imageSrc);

  canvas.width = image.height;
  canvas.height = image.width;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  const degree = clockwise ? 90 : 270;
  ctx.rotate((degree * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  const base64Image = canvas.toDataURL(IMAGE_JPEG);

  return base64Image;
};

export const base64ImageToJpeg = async imageSrc => {
  if (imageSrc.startsWith(DATA_URL_PNG_START)) {
    return imageSrc;
  }
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = await loadImageSrc(imageSrc);

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const base64Image = canvas.toDataURL(IMAGE_JPEG);

  return base64Image;
};
