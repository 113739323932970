import './neb-form-provider-notes';

import { html } from 'lit';

import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { upsertProviderNote } from '../../../api-clients/provider-notes';
import { toggleChartingDirty } from '../../../store/reducers/charting';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
};

const PROVIDER_NOTES_ITEM_NAME = 'Provider Notes';

class NebUnsignedEncounterProviderNotesDetail extends NebUnsignedEncounterDetail {
  static get config() {
    return {
      itemName: PROVIDER_NOTES_ITEM_NAME,
      refetchKey: PANEL_KEYS.PROVIDER_NOTES,
    };
  }

  static createModel() {
    return {
      note: '',
    };
  }

  initState() {
    super.initState();

    this.__dirty = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      changeDirty: v => {
        this.__dirty = v;

        store.dispatch(toggleChartingDirty(v));
      },
    };
  }

  isDirty() {
    return this.__dirty;
  }

  async save(providerNote) {
    await upsertProviderNote(providerNote);
  }

  getTitle() {
    return PROVIDER_NOTES_ITEM_NAME;
  }

  renderContent() {
    return html`
      <neb-form-provider-notes
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
      ></neb-form-provider-notes>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-provider-notes-detail',
  NebUnsignedEncounterProviderNotesDetail,
);
