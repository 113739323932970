import './neb-user-menu';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_SECONDARY_NAV_HEIGHT,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_SECONDARY_NAV_SMALL_HEIGHT,
} from '../../../neb-styles/neb-variables';
import './inputs/neb-menu';

export const ELEMENTS = {
  userMenu: { id: 'user-menu', tag: 'neb-user-menu' },
  helpIcon: { id: 'neb-help-icon', tag: 'neb-icon' },
  quickNavContainer: { id: 'quick-nav-container' },
  quickNav: { id: 'quick-nav', tag: 'neb-menu' },
};

export const USER_MENU_OPTIONS = [
  { label: 'Edit Profile', icon: 'neb:edit' },
  { label: 'Change Practice', icon: 'neb:changePractice' },
  { label: 'Log Out', icon: 'neb:logout' },
];

export const LOGOUT = {
  title: 'Log Out',
  message: 'Are you sure you want to log out?',
  confirmText: 'Log Out',
};

const QUICK_NAV_OPTIONS = [{ label: 'ChiroTouch Community' }];

export const QUICK_NAV_URLS = ['https://chirotouchcommunity.force.com/cloud'];

export class NebNavSecondaryBase extends LitElement {
  static get properties() {
    return {
      __menuItems: { type: Array },
      __open: { reflect: true, type: Boolean, attribute: 'open' },

      user: { type: Object },
      layout: { reflect: true, type: String },
      apiOverride: { type: String },
      showQuickNav: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__menuItems = [...USER_MENU_OPTIONS];
    this.__menuOptions = {
      [USER_MENU_OPTIONS[0].label]: () =>
        this.onUserMenuOptions(USER_MENU_OPTIONS[0].label, {
          userId: this.user.id,
        }),
      [USER_MENU_OPTIONS[1].label]: () => this.__promptChangePractice(),
      [USER_MENU_OPTIONS[2].label]: () => this.__promptLogOut(),
    };

    this.user = {};
    this.layout = '';
    this.apiOverride = '';
    this.showQuickNav = true;

    this.__open = false;

    this.onUserMenuOptions = () => {};
  }

  initHandlers() {
    this.handlers = {
      action: ({ label }) => this.__menuOptions[label](),
      blur: () => {
        this.__open = false;
      },
      select: index => window.open(QUICK_NAV_URLS[index], '_blank'),
      toggleQuickNav: () => {
        this.__open = !this.__open;
      },
    };
  }

  async __promptChangePractice() {
    const { tenantId, tenantIds } = this.user;
    const selectedTenantId = await openPopup(
      POPUP_RENDER_KEYS.CHANGE_PRACTICE,
      { tenantIds, apiOverride: this.apiOverride },
    );

    if (selectedTenantId && selectedTenantId !== tenantId) {
      this.onUserMenuOptions(USER_MENU_OPTIONS[1].label, {
        tenantId: selectedTenantId,
      });
    }
  }

  async __promptLogOut() {
    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, LOGOUT);
    if (accepted) this.onUserMenuOptions(USER_MENU_OPTIONS[2].label);
  }

  updated(changedProps) {
    if (changedProps.has('user')) {
      this.__menuItems =
        this.user.tenantIds && this.user.tenantIds.length > 1
          ? [...USER_MENU_OPTIONS]
          : [USER_MENU_OPTIONS[0], USER_MENU_OPTIONS[2]];
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          height: ${CSS_SECONDARY_NAV_HEIGHT};
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) {
          min-height: ${CSS_SECONDARY_NAV_SMALL_HEIGHT};
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          border: solid 1px ${CSS_COLOR_GREY_2};
        }

        .right-container {
          display: flex;
          align-items: center;
          padding: 0 ${CSS_SPACING};
        }

        :host(:not([layout='small'])) .right-container {
          flex: 1;
          justify-content: flex-end;
        }

        .user-menu {
          display: flex;
          align-items: center;
          width: max-content;
          height: 100%;

          max-width: 230px;
          min-width: 130px;

          margin-right: ${CSS_SPACING};
        }

        .quick-nav-container {
          display: flex;
        }

        .icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
          cursor: pointer;
        }

        .menu {
          position: absolute;
          top: ${CSS_SECONDARY_NAV_HEIGHT};
          right: 0;
          width: 237px;
        }
      `,
    ];
  }

  __renderQuickNav() {
    if (!this.showQuickNav) return html``;

    return html`
      <div
        id="${ELEMENTS.quickNavContainer.id}"
        class="quick-nav-container"
        tabindex="0"
        @blur="${this.handlers.blur}"
        @click="${this.handlers.toggleQuickNav}"
      >
        <neb-icon
          id="${ELEMENTS.helpIcon.id}"
          class="icon icon-help"
          icon="neb:help"
        ></neb-icon>

        <neb-menu
          id="${ELEMENTS.quickNav.id}"
          class="menu"
          .items="${QUICK_NAV_OPTIONS}"
          .maxVisibleItems="${QUICK_NAV_OPTIONS.length}"
          .onChange="${this.handlers.select}"
          ?open="${this.__open}"
        ></neb-menu>
      </div>
    `;
  }

  __renderUserMenu() {
    return this.layout !== 'small'
      ? html`
          <neb-user-menu
            id="${ELEMENTS.userMenu.id}"
            class="user-menu"
            .items="${this.__menuItems}"
            .layout="${this.layout}"
            .model="${this.user}"
            .onSelect="${this.handlers.action}"
          ></neb-user-menu>
        `
      : '';
  }

  __renderRightContent() {
    return html` ${this.__renderUserMenu()} ${this.__renderQuickNav()} `;
  }

  renderActionContent() {
    return '';
  }

  renderLeftContent() {
    return '';
  }

  render() {
    return html`
      <div class="container">
        ${this.renderLeftContent()}
        <div class="right-container">
          ${this.renderActionContent()} ${this.__renderRightContent()}
        </div>
      </div>
    `;
  }
}
