import '../../../../../src/components/misc/neb-icon';
import './neb-floating-label';
import './neb-menu';
import './neb-text-helper';

import { html, css } from 'lit';

import {
  CSS_COLOR_WHITE,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_3,
  CSS_COLOR_DISABLED,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';
import { defaultMask } from '../../../../neb-utils/masks';
import { getValueByPath, decompose } from '../../../../neb-utils/utils';

import { ELEMENTS as ELEMENTS_BASE, TextInput } from './neb-text-input';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  leadingIcon: {
    id: 'icon-leading',
  },
  trailingIcon: {
    id: 'icon-trailing',
  },
  menu: {
    id: 'menu',
  },
  helperText: {
    id: 'text-helper',
  },
};

class Textfield extends TextInput {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __selectTextfield: Boolean,
      forceDown: {
        reflect: true,
        type: Boolean,
      },
      scrollOnKeydown: Boolean,
      maxVisibleItems: Number,
      itemHeight: Number,
      inputMode: String,
      type: String,
      mask: Object,
      menuItems: Array,
      itemMinWidth: Number,
      leadingIcon: {
        reflect: true,
        type: String,
      },
      trailingIcon: {
        reflect: true,
        type: String,
      },
      showFullText: {
        reflect: true,
        type: Boolean,
      },
      wrapText: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: inline-block;

          --text-align: left;
        }

        .textbox {
          position: relative;
        }

        .icon {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-leading {
          margin-right: 12px;
        }

        :host([focused]:not([disabled])) .icon-leading {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) .icon-leading {
          fill: ${CSS_COLOR_GREY_3};
        }

        .icon-trailing {
          cursor: pointer;
          margin-left: 12px;
        }

        :host([disabled]) .icon-trailing {
          cursor: default;
        }

        .input {
          border: none;
          width: 100%;
          height: 100%;
          min-width: 0;
          background-color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_BODY};
          flex: 1 0 0;
          text-align: var(--text-align);
          padding: 0;
        }

        .input:-moz-ui-invalid {
          box-shadow: none !important;
        }

        :host([disabled]) .input {
          color: ${CSS_COLOR_DISABLED};
          opacity: 1;
        }

        :host([focused]) .input {
          caret-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([invalid]) .input {
          caret-color: ${CSS_COLOR_ERROR};
        }

        .input::-webkit-outer-spin-button,
        .input::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        :host(:not([leadingIcon=''])) neb-floating-label {
          --left-docked: 44px;
        }

        .menu {
          left: 0;
        }
      `,
    ];
  }

  get inputEl() {
    return this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  initState() {
    super.initState();

    this.__open = false;
    this.__items = [];
    this.__resetPosition = -1;
    this.__selectTextfield = false;

    this.scrollOnKeydown = false;
    this.forceDown = false;
    this.maxVisibleItems = 4;
    this.itemHeight = 48;
    this.itemMinWidth = 100;
    this.type = 'text';
    this.leadingIcon = '';
    this.trailingIcon = '';
    this.menuItems = [];
    this.mask = defaultMask;
    this.inputMode = 'text';
    this.showFullText = false;
    this.wrapText = false;

    this.onClickIcon = () => {};

    this.onSelect = () => {};

    this.onRequest = () => {};

    this.onKeydown = () => {};

    this.onClick = () => {};

    this.onRenderItem = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      close: () => {
        this.__open = false;
      },
      clickIcon: () => {
        if (this.disabled) return;

        this.onClickIcon(this.name);
      },
      keydown: e => {
        this.onKeydown({ key: e.key, name: this.name, event: e });

        if (e.key === 'Escape') {
          this.__open = false;
        }

        const isMeta = e.altKey || e.ctrlKey || e.metaKey;

        if (!this.onChange && !isMeta && e.key !== 'Tab') {
          e.preventDefault();
          return;
        }

        const keyLength = e.key ? [...e.key].length : 0;
        const isChar = !e.defaultPrevented && !isMeta && keyLength === 1;
        const passed = this.mask.pattern.test(e.key);

        if (isChar && !passed) {
          e.preventDefault();
        }
      },
      input: e => {
        const { value: origVal, selectionEnd: origPos } = e.currentTarget;
        const element = e.currentTarget;
        const rawPos = this.mask.toRawPosition(origPos, origVal);
        const rawVal = this.mask.toRawValue(origVal);
        const pos = this.mask.toFormattedPosition(rawPos, rawVal, origVal);
        const val = this.mask.toFormattedValue(rawVal);

        element.value = val;

        if (element.selectionStart != null && element.selectionEnd != null) {
          element.selectionStart = pos;
          element.selectionEnd = pos;
          this.__resetPosition = pos;
        }

        this.onChange({
          name: this.name,
          value: val,
        });
      },
      request: () => this.onRequest(),
      selectItem: index => {
        const hasDividers = Array.isArray(this.menuItems[0]);
        const event = {
          name: this.name,
          value: this.menuItems[index],
          index,
        };

        if (hasDividers) {
          const { groupIndex, itemIndex } = decompose(index, this.menuItems);
          event.value = this.menuItems[groupIndex][itemIndex];
        }

        this.onSelect(event);

        this.__open = false;
      },
      click: e => {
        this.onClick({ key: e.type, name: this.name });
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this.handlers.close);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this.handlers.close);
  }

  focus() {
    this.inputEl.focus();
  }

  select() {
    this.__selectTextfield = true;
  }

  isPinned() {
    const elementValue = getValueByPath(this, ['elements', 'input', 'value']);
    return super.isPinned() || this.__open || elementValue;
  }

  update(changedProps) {
    if (changedProps.has('value') && this.elements) {
      this.elements.input.value = this.value;
    }

    if (changedProps.has('menuItems')) {
      this.__items = this.menuItems.map(item =>
        typeof item === 'object'
          ? item
          : {
              label: item,
            },
      );
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    const TARGETS = ['__focused', 'readonly', 'menuItems'];

    if (TARGETS.some(item => changedProps.has(item))) {
      this.__open = this.isFocused() && !this.readonly;
    }

    if (this.__resetPosition > -1) {
      this.elements.input.setSelectionRange(
        this.__resetPosition,
        this.__resetPosition,
      );

      this.__resetPosition = -1;
    }

    if (this.__selectTextfield) {
      this.elements.input.select();
      this.__selectTextfield = false;
    }
  }

  renderLeadingIcon() {
    return this.leadingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.leadingIcon.id}"
            class="icon icon-leading"
            .icon="${this.leadingIcon}"
          ></neb-icon>
        `
      : '';
  }

  renderTrailingIcon() {
    return this.trailingIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.trailingIcon.id}"
            class="icon icon-trailing"
            .icon="${this.trailingIcon}"
            @click="${this.handlers.clickIcon}"
          ></neb-icon>
        `
      : '';
  }

  autoComplete() {
    return this.type === 'password' ? 'new-password' : 'off';
  }

  renderInputElement() {
    return html`
      ${this.renderLeadingIcon()}

      <input
        id="${ELEMENTS.input.id}"
        class="input"
        autocomplete="${this.autoComplete()}"
        maxlength="${this.maxLength}"
        inputmode="${this.inputMode}"
        pattern="${this.inputMode === 'numeric' ? '[0-9]*' : '.*'}"
        .type="${this.type}"
        .value="${this.value}"
        .placeholder="${this.getPlaceholder()}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        @focus="${this.handlers.focus}"
        @blur="${this.handlers.blur}"
        @input="${this.handlers.input}"
        @keydown="${this.handlers.keydown}"
        @click="${this.handlers.click}"
      />

      ${this.renderTrailingIcon()}

      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu"
        .scrollOnKeydown="${this.scrollOnKeydown}"
        .maxVisibleItems="${this.maxVisibleItems}"
        .itemHeight="${this.itemHeight}"
        .items="${this.__items}"
        .onChange="${this.handlers.selectItem}"
        .onRenderItem="${this.onRenderItem}"
        .onRequest="${this.handlers.request}"
        .itemMinWidth="${this.itemMinWidth}"
        ?open="${this.__open && this.menuItems.length}"
        ?forceDown="${this.forceDown}"
        ?showFullText="${this.showFullText}"
        ?wrapText="${this.wrapText}"
      ></neb-menu>
    `;
  }

  renderFooter() {
    return html`
      <neb-text-helper
        id="${ELEMENTS.helperText.id}"
        .text="${this.getHelperText()}"
        ?disabled="${this.disabled}"
        ?invalid="${Boolean(this.error)}"
      ></neb-text-helper>
    `;
  }
}

window.customElements.define('neb-textfield', Textfield);
