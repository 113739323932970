import { CORE_API_URL } from '../../../../neb-utils/env';

const FETCHING_ONLINE_BOOKING = 'FETCHING_ONLINE_BOOKING';
const FETCH_ONLINE_BOOKING_SUCCESS = 'FETCH_ONLINE_BOOKING_SUCCESS';
const FETCH_ONLINE_BOOKING_FAILED = 'FETCH_ONLINE_BOOKING_FAILED';

export const onlineBookingReducer = (
  state = {
    isFetching: false,
    fetchingError: null,
    item: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCHING_ONLINE_BOOKING:
      return { ...state, isFetching: true, fetchingError: null };

    case FETCH_ONLINE_BOOKING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchingError: null,
        item: action.response.data[0],
      };

    case FETCH_ONLINE_BOOKING_FAILED:
      return { ...state, isFetching: false, fetchingError: action.error };

    default:
      return state;
  }
};

/**
 * @typedef FetchOnlineBookingOptions
 * @property shortName - The tenant short name
 */

/**
 * Lazy load the online booking options.
 * @param {FetchOnlineBookingOptions} options Options
 */

export const fetchOnlineBookingLazy = options => async (dispatch, getState) => {
  const state = getState();
  const onlineBookingStore = state.onlineBooking;

  if (
    onlineBookingStore &&
    (onlineBookingStore.isFetching || onlineBookingStore.fetchingError)
  ) {
    return;
  }

  dispatch({
    type: FETCHING_ONLINE_BOOKING,
  });

  const url = `${CORE_API_URL}/public/v1/${options.shortName}/online-booking`;

  try {
    const res = await fetch(url, {
      method: 'GET',
    });
    const json = await res.json();

    if (!res.ok) {
      const err = new Error();
      err.res = json;
      throw err;
    }

    dispatch({
      type: FETCH_ONLINE_BOOKING_SUCCESS,
      response: json,
    });
  } catch (err) {
    const errObj = err.res ? err.res : err;
    console.log(errObj);
    dispatch({
      type: FETCH_ONLINE_BOOKING_FAILED,
      error: errObj,
    });
  }
};
