import '../../neb-material-design/src/components/neb-md-select';
import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { APPOINTMENT_ACTIONS } from '../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectRoom: {
    id: 'select-room',
  },
};

const EMPTY_ROOM = { id: null, label: '' };

class NebPopupAppointmentChangeRoom extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      model: Object,
      __selectedRoom: Object,
    };
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = APPOINTMENT_ACTIONS.CHANGE_ROOM.title;

    this.__selectedRoom = {};
    this.__rooms = [];

    this.model = {
      rooms: [],
      roomId: null,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      change: e => {
        this.__selectedRoom = e.value;
      },
      confirm: () => {
        this.onClose({
          room: this.__selectedRoom,
        });
      },
      cancel: () => {
        this.onClose(false);
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__rooms = [
        EMPTY_ROOM,
        ...this.model.rooms
          .filter(room => room.checkInAvailable)
          .map(room => ({
            ...room,
            label: room.name,
          })),
      ];

      this.__selectedRoom =
        this.__rooms.find(room => room.id === this.model.roomId) || EMPTY_ROOM;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 650px;
          height: 100%;
        }

        :host([layout='small']) .content-container {
          width: 300px;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .rooms {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="container-select">
          <neb-select
            id="${ELEMENTS.selectRoom.id}"
            class="rooms"
            name="roomId"
            label="Check-In Room"
            .items="${this.__rooms}"
            .value="${this.__selectedRoom}"
            .onChange="${this.__handlers.change}"
          ></neb-select>
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="Save"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-appointment-change-room',
  NebPopupAppointmentChangeRoom,
);
