import '../../../misc/neb-icon';
import '../../../../../packages/neb-lit-components/src/components/neb-toggle-button';
import '../../../../../packages/neb-lit-components/src/components/neb-text';

import { html, css } from 'lit';

import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../../packages/neb-styles/neb-variables';
import { centsToCurrency } from '../../../../../packages/neb-utils/formatters';
import { currency } from '../../../../../packages/neb-utils/masks';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  button: {
    id: 'button',
  },
  checkMarkIcon: {
    id: 'icon-check-mark',
  },
  plusIcon: {
    id: 'icon-plus',
  },
  text: {
    id: 'text',
  },
  payerLinks: { selector: '[id^=payer-]' },
  transactionDates: { selector: '[id^=transactionDate-]' },
  addButton: {
    selector: '[id^=add-button-]',
  },
};

class NebTableAssociatePayments extends NebTable {
  static get properties() {
    return {
      selectedItems: Array,
      reportType: String,
    };
  }

  initState() {
    super.initState();

    this.selectedItems = [];
    this.reportType = '';

    this.onToggle = () => {};

    this.onPayerUpdate = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      toggle: (state, rowIndex) => {
        const { id } = this.model[rowIndex];
        this.onToggle(state, id);
      },
      selectPayer: async e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { payerPlanId } = this.model[rowIndex];

        if (payerPlanId) {
          const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
            id: payerPlanId,
          });

          if (res) await this.onPayerUpdate();
        }
      },
    };
  }

  getConfig() {
    return [
      {
        key: 'checked',
        label: '',
        flex: css`0 0 150px`,
      },
      {
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'transactionDate',
        label: 'Date',
        flex: css`0 0 120px`,
        formatter: d => (d ? d.format('MM/DD/YYYY') : ''),
      },
      {
        sortable: true,
        truncate: true,
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`0 0 120px`,
      },
      {
        truncate: true,
        sortable: true,
        key: 'amount',
        label: 'Amount',
        flex: css`0 0 140px`,
        maxLength: 11,
        mask: currency,
        formatter: a => centsToCurrency(a),
      },
      {
        truncate: true,
        key: 'authEFT',
        label: 'Check/Auth/EFT#',
        flex: css`0 0 140px`,
      },
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.config = this.getConfig();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-data {
          padding: 4px;
        }

        .add-button {
          width: 120px;
          padding: 0;
          white-space: nowrap;
        }

        .link {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .text {
          margin-right: 6px;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
          width: inherit;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    if (columnConfig.key === 'checked') {
      return this.__renderButton(rowIndex, value);
    }

    if (columnConfig.key === 'payerName') {
      const { payerPlanId } = this.model[rowIndex];

      return this.__renderPayerCell({ value, rowIndex }, payerPlanId);
    }

    return html`
      <neb-text
        id="${columnConfig.key}-${rowIndex}"
        ?truncate="${columnConfig.truncate}"
        >${value}</neb-text
      >
    `;
  }

  __renderButton(rowIndex, _value) {
    const isAdded = !!this.selectedItems.find(
      e => e === this.model[rowIndex].id,
    );
    const buttonAddMessage = `Add to ${this.reportType}`;

    const buttonSelectedMessage = 'Remove';

    return html`
      <neb-toggle-button
        id="add-button-${rowIndex}"
        class="add-button"
        .canToggle="${true}"
        .index="${rowIndex}"
        .layout="${this.layout}"
        .isToggled="${isAdded}"
        icon="neb:plus"
        toggledIcon="neb:minus"
        .onClick="${this.handlers.toggle}"
        .buttonText="${isAdded ? buttonSelectedMessage : buttonAddMessage}"
      ></neb-toggle-button>
    `;
  }

  __renderPayerCell({ value, rowIndex }, payerPlanId) {
    return html`
      <div
        id="payer-${rowIndex}"
        class="${payerPlanId ? 'button' : 'cell'}"
        index="${rowIndex}"
        @click="${this.handlers.selectPayer}"
      >
        <span
          id="payer-values-${rowIndex}"
          class="${payerPlanId ? 'link' : 'text'}"
          >${value}</span
        >
      </div>
    `;
  }
}

customElements.define(
  'neb-table-associate-payments',
  NebTableAssociatePayments,
);
