import { html, LitElement } from 'lit';

import { CSS_FONT_FAMILY } from '../../../styles';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../misc/neb-icon';
import '../../controls/inputs/neb-checkbox';
import { reportStyles } from '../../../styles/components/pages/real-time-eligibility/report-styles';

export const ELEMENTS = {
  contentToPrint: {
    id: 'content-to-print',
  },
  mainHeaders: {
    selector: '.main-header-container',
  },
  hideCollapsedSectionsCheckbox: {
    id: 'hide-collapsed-sections-checkbox',
  },
  benefitsSection: {
    id: 'benefits-information',
  },
  printButton: {
    id: 'print-button',
  },
  collapseAllButton: {
    id: 'collapse-all-button',
  },
  expandAllButton: {
    id: 'expand-all-button',
  },
  closeIcon: {
    id: 'close-icon',
  },
  backToTopButton: {
    id: 'backToTop',
  },
  idInfoTables: {
    selector: '.id-info-table',
  },
  serviceTypeTables: {
    selector: '.service-type-table',
  },
  mainPracticeServiceTypeHeader: {
    selector: '.practice-service-types-header',
  },
  practiceServiceTypeHeaders: {
    selector: '.practice-service-type',
  },
  practiceServiceTypeTables: {
    selector: '.practice-service-type-table',
  },
  mainOtherServiceTypeHeader: {
    selector: '.secondary-benefit-header',
  },
  otherServiceTypeHeaders: {
    selector: '.all-other-service-type',
  },
  otherServiceTypeTables: {
    selector: '.all-other-service-type-table',
  },
};

class NebPageEligibilityBenefitsViewer extends LitElement {
  static get properties() {
    return {
      attachedToEdit: {
        type: Boolean,
        reflect: true,
        attribute: 'attached-to-edit',
      },
      reportData: Object,

      __collapsePracticeServiceTypes: {
        type: Boolean,
        reflect: true,
        attribute: 'collapse-practice',
      },
      __collapseAllOtherServiceTypes: {
        type: Boolean,
        reflect: true,
        attribute: 'collapse-all-other',
      },
      __removeCollapsedHeaders: Boolean,
    };
  }

  static get styles() {
    return [reportStyles];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__collapsePracticeServiceTypes = false;
    this.__collapseAllOtherServiceTypes = true;
    this.__removeCollapsedHeaders = true;
    this.reportData = {
      idInfo: { subscriber: {}, provider: {}, payer: {} },
      benefitInfo: { activeServiceTypes: {}, inactiveServiceTypes: {} },
    };

    this.onCloseReport = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      scrollToTop: () => {
        this.scrollTo({ top: 0, behavior: 'smooth' });
      },
      toggleTableVisibility: event => {
        const div = event.currentTarget;
        const content = div.nextElementSibling;

        const isContentHidden = content.style.display === 'none';
        this.__toggleDisplay(div, content, isContentHidden);

        if (div.classList.contains('practice-service-type')) {
          this.__handleSectionHeader(
            'practice-service-type',
            '__collapsePracticeServiceTypes',
            '.practice-service-types-header',
          );
        }

        if (div.classList.contains('all-other-service-type')) {
          this.__handleSectionHeader(
            'all-other-service-type',
            '__collapseAllOtherServiceTypes',
            '.secondary-benefit-header',
          );
        }
      },
      toggleServiceTypes: event => {
        this.__toggleServiceTypeVisibility(event);
      },
      collapseMainHeader: event => {
        const div = event.currentTarget;
        const content = div.nextElementSibling;

        if (content.classList.contains('main-info-section-hidden')) {
          content.classList.remove('main-info-section-hidden');
          content.classList.add('main-info-section');
          div.classList.remove('collapsed-main-header');
          div.classList.add('expanded-main-header');
        } else {
          content.classList.remove('main-info-section');
          content.classList.add('main-info-section-hidden');
          div.classList.remove('expanded-main-header');
          div.classList.add('collapsed-main-header');
        }
      },
      collapseSecondaryHeader: event => {
        const div = event.currentTarget;
        const content = div.nextElementSibling;

        if (content.classList.contains('secondary-info-section-hidden')) {
          content.classList.remove('secondary-info-section-hidden');
          content.classList.add('secondary-info-section');
          div.classList.remove('collapsed-main-header');
          div.classList.add('expanded-main-header');
        } else {
          content.classList.remove('secondary-info-section');
          content.classList.add('secondary-info-section-hidden');
          div.classList.remove('expanded-main-header');
          div.classList.add('collapsed-main-header');
        }
      },
      printContent: () => {
        const content = this.shadowRoot
          .querySelector('#content-to-print')
          .cloneNode(true);

        const collapsedHeaders = content.querySelectorAll('.collapsed-header');
        const collapsedSections =
          content.querySelectorAll('.collapsed-section');

        const mainInfoSection = content.querySelector(
          '.main-info-section-hidden',
        );

        if (mainInfoSection) {
          mainInfoSection.classList.remove('main-info-section-hidden');
          mainInfoSection.classList.add('main-info-section');
        }

        if (this.__removeCollapsedHeaders) {
          collapsedHeaders.forEach(header => header.remove());
          collapsedSections.forEach(section => section.remove());

          const benefitsSection = content.querySelector(
            `#${ELEMENTS.benefitsSection.id}`,
          );
          const classList = Array.from(benefitsSection.classList);

          if (classList.includes('collapsed-main-header')) {
            benefitsSection.remove();
          }
        }

        const printContents = content.outerHTML;
        const styles = `
          <style>
            body {
              font-family: ${CSS_FONT_FAMILY};
            }

            ${reportStyles.cssText}

            * {
              cursor: default !important;
            }

            #print-button {
              cursor: pointer !important;
            }
          </style>
        `;
        const printWindow = window.open('Print', '_blank');
        printWindow.document.write(
          '<html><head><title>Patient Eligibility Benefits</title>',
        );

        printWindow.document.write(styles);
        printWindow.document.write('</head><body>');
        printWindow.document.write(`
          <div id="print-button-container" style="padding: 20px; text-align: right;">
            <button id="print-button" style="padding: 10px 20px; font-size: 16px;">
              Print
            </button>
          </div>
        `);

        printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        printWindow.onload = () => {
          const printButton =
            printWindow.document.getElementById('print-button');
          const printButtonContainer = printWindow.document.getElementById(
            'print-button-container',
          );

          printButton.addEventListener('click', () => {
            printWindow.print();
          });

          printWindow.addEventListener('beforeprint', () => {
            printButtonContainer.style.display = 'none';
          });

          printWindow.addEventListener('afterprint', () => {
            printButtonContainer.style.display = 'block';
          });
        };
      },
      removeCollapsedHeaders: () => {
        this.__removeCollapsedHeaders = !this.__removeCollapsedHeaders;
      },
      closeReport: () => {
        this.onCloseReport();
      },
      collapseAll: () => {
        const allServiceTypeHeaders =
          this.shadowRoot.querySelectorAll('.h2-container');

        allServiceTypeHeaders.forEach(header => {
          header.classList.add('collapsed-section');
        });

        this.__collapsePracticeServiceTypes = true;
        this.__collapseAllOtherServiceTypes = true;

        const allBenefitHeaders = this.shadowRoot.querySelectorAll(
          '.benefit-table-header',
        );
        allBenefitHeaders.forEach(header => {
          const content = header.nextElementSibling;
          content.style.display = 'none';
          header.classList.add('collapsed-header');
          header.classList.remove('expanded-header');
        });
      },
      expandAll: () => {
        const allServiceTypeHeaders =
          this.shadowRoot.querySelectorAll('.h2-container');

        allServiceTypeHeaders.forEach(header => {
          header.classList.remove('collapsed-section');
        });

        this.__collapsePracticeServiceTypes = false;
        this.__collapseAllOtherServiceTypes = false;

        const allBenefitHeaders = this.shadowRoot.querySelectorAll(
          '.benefit-table-header',
        );
        allBenefitHeaders.forEach(header => {
          const content = header.nextElementSibling;
          content.style.display = 'block';
          header.classList.add('expanded-header');
          header.classList.remove('collapsed-header');
        });
      },
    };
  }

  __toggleDisplay(element1, element2, show) {
    element2.style.display = show ? 'block' : 'none';
    element1.classList.toggle('collapsed-header', !show);
    element1.classList.toggle('expanded-header', show);
  }

  __handleSectionHeader(className, collapseProperty, headerSelector) {
    const allElements = this.shadowRoot.querySelectorAll(`.${className}`);
    const allCollapsed = Array.from(allElements).every(el =>
      el.classList.contains('collapsed-header'),
    );

    if (allCollapsed) {
      this[collapseProperty] = true;
      const sectionHeader = this.shadowRoot.querySelector(headerSelector);
      sectionHeader.classList.add('collapsed-section');
    } else {
      this[collapseProperty] = false;
      const sectionHeader = this.shadowRoot.querySelector(headerSelector);
      sectionHeader.classList.remove('collapsed-section');
    }
  }

  __collapseSection(section) {
    const benefitHeaders = section.querySelectorAll('.benefit-table-header');
    benefitHeaders.forEach(div => {
      div.classList.remove('expanded-header');
      div.classList.add('collapsed-header');
    });

    const collapsibleContents = section.querySelectorAll(
      '.collapsible-content',
    );
    collapsibleContents.forEach(div => {
      div.style.display = 'none';
    });
  }

  __expandSection(section) {
    const benefitHeaders = section.querySelectorAll('.benefit-table-header');
    benefitHeaders.forEach(div => {
      div.classList.remove('collapsed-header');
      div.classList.add('expanded-header');
    });

    const collapsibleContents = section.querySelectorAll(
      '.collapsible-content',
    );
    collapsibleContents.forEach(div => {
      div.style.display = 'block';
    });
  }

  __toggleServiceTypeVisibility(event) {
    const serviceTypeSection = event.currentTarget;
    const classes = serviceTypeSection.classList;
    const isPracticeServiceTypes = classes.contains(
      'practice-service-types-header',
    );
    const nextDiv = serviceTypeSection.nextElementSibling;

    if (isPracticeServiceTypes) {
      this.__collapsePracticeServiceTypes =
        !this.__collapsePracticeServiceTypes;

      if (this.__collapsePracticeServiceTypes) {
        serviceTypeSection.classList.add('collapsed-section');
        this.__collapseSection(nextDiv);
      } else {
        serviceTypeSection.classList.remove('collapsed-section');
        this.__expandSection(nextDiv);
      }
    } else {
      this.__collapseAllOtherServiceTypes =
        !this.__collapseAllOtherServiceTypes;

      if (this.__collapseAllOtherServiceTypes) {
        serviceTypeSection.classList.add('collapsed-section');
        this.__collapseSection(nextDiv);
      } else {
        serviceTypeSection.classList.remove('collapsed-section');
        this.__expandSection(nextDiv);
      }
    }
  }

  __resetSections() {
    const mainHeaders = this.shadowRoot.querySelectorAll(
      '.main-header-container',
    );
    mainHeaders.forEach(header => {
      header.classList.add('expanded-main-header');
      header.classList.remove('collapsed-main-header');
    });

    const mainInfoSection = this.shadowRoot.querySelector(
      '.main-info-section-hidden',
    );

    if (mainInfoSection) {
      mainInfoSection.classList.remove('main-info-section-hidden');
      mainInfoSection.classList.add('main-info-section');
    }

    const secondaryInfoSection = this.shadowRoot.querySelector(
      '.secondary-info-section-hidden',
    );

    if (secondaryInfoSection) {
      secondaryInfoSection.classList.remove('secondary-info-section-hidden');
      secondaryInfoSection.classList.add('secondary-info-section');
    }

    const practiceServiceTypeHeaders = this.shadowRoot.querySelectorAll(
      '.practice-service-type',
    );
    practiceServiceTypeHeaders.forEach(header => {
      const content = header.nextElementSibling;
      content.style.display = 'block';
      header.classList.remove('collapsed-header');
      header.classList.add('expanded-header');
    });

    this.__collapsePracticeServiceTypes = false;

    const allOtherServiceTypeHeaders = this.shadowRoot.querySelectorAll(
      '.all-other-service-type',
    );
    allOtherServiceTypeHeaders.forEach(header => {
      const content = header.nextElementSibling;
      content.style.display = 'none';
      header.classList.add('collapsed-header');
      header.classList.remove('expanded-header');
    });

    this.__collapseAllOtherServiceTypes = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('scroll', this.__toggleBackToTopButton);
    window.addEventListener('resize', this.__updateButtonPosition.bind(this));
  }

  disconnectedCallback() {
    this.removeEventListener('scroll', this.__toggleBackToTopButton);
    window.removeEventListener(
      'resize',
      this.__updateButtonPosition.bind(this),
    );

    super.disconnectedCallback();
  }

  firstUpdated() {
    this.__toggleBackToTopButton();
  }

  updated(changedProps) {
    if (changedProps.has('reportData')) {
      this.__resetSections();
      this.__updateButtonPosition();
    }
  }

  __toggleBackToTopButton() {
    const button = this.shadowRoot.getElementById('backToTop');

    if (this.scrollTop > 100) {
      button.style.display = 'flex';
    } else {
      button.style.display = 'none';
    }
  }

  __updateButtonPosition() {
    const button = this.shadowRoot.getElementById('backToTop');
    const hostWidth = this.offsetWidth;
    button.style.right = `${hostWidth + 25}px`;
  }

  __renderHeaderSection() {
    return html`
      <div class="header-container">
        <div class="header-buttons">
          <neb-checkbox
            id="${ELEMENTS.hideCollapsedSectionsCheckbox.id}"
            label="Hide Collapsed Service Types in Print"
            .onChange="${this.__handlers.removeCollapsedHeaders}"
            ?checked="${this.__removeCollapsedHeaders}"
          ></neb-checkbox>
          <neb-button-action
            id="${ELEMENTS.printButton.id}"
            leadingIcon="print"
            label="Print Preview"
            .onClick="${this.__handlers.printContent}"
          ></neb-button-action>
          <neb-button
            id="${ELEMENTS.collapseAllButton.id}"
            class="button button-delete"
            role="confirm"
            label="Collapse All"
            .onClick="${this.__handlers.collapseAll}"
          >
          </neb-button>
          <neb-button
            id="${ELEMENTS.expandAllButton.id}"
            class="button button-delete"
            role="confirm"
            label="Expand All"
            .onClick="${this.__handlers.expandAll}"
          >
          </neb-button>
        </div>
        <neb-icon
          id="${ELEMENTS.closeIcon.id}"
          class="close-icon"
          icon="neb:close"
          @click="${this.__handlers.closeReport}"
        ></neb-icon>
      </div>
    `;
  }

  __createIdInfoTable(values) {
    return html`
      <table class="id-info-table">
        <tbody>
          ${Array.isArray(values)
            ? values.map(
                (item, index1) => html`
                  ${Object.entries(item).map(
                    ([key, value], index2, entries) => html`
                      <tr
                        class="${index1 !== values.length - 1 &&
                        index2 === entries.length - 1
                          ? 'last-array-table-item'
                          : ''}"
                      >
                        <td class="table-left">${key}:</td>
                        <td>${value}</td>
                      </tr>
                    `,
                  )}
                `,
              )
            : Object.entries(values).map(
                ([key, value]) => html`
                  <tr>
                    <td class="table-left">${key}:</td>
                    <td>${value}</td>
                  </tr>
                `,
              )}
          <tr>
            <td class="table-left"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    `;
  }

  __getAmountRows(value) {
    if (value.inPlanNetworkIndicatorCode === 'Y') {
      return html`
        <td class="secondary-table amount">${value.amount}</td>
        <td class="secondary-table"></td>
      `;
    }

    if (value.inPlanNetworkIndicatorCode === 'N') {
      return html`
        <td class="secondary-table"></td>
        <td class="secondary-table amount">${value.amount}</td>
      `;
    }

    if (value.inPlanNetworkIndicatorCode === 'W') {
      return html`
        <td class="secondary-table amount" colspan="2">
          ${value.amount ? `${value.amount}\n(Benefit applies to both)` : ''}
        </td>
      `;
    }

    if (value.inPlanNetworkIndicatorCode === 'U') {
      return html`
        <td class="secondary-table amount" colspan="2">
          ${value.amount
            ? `${value.amount}\n(Unknown if benefit applicable)`
            : ''}
        </td>
      `;
    }

    return html`
      <td class="secondary-table"></td>
      <td class="secondary-table"></td>
    `;
  }

  __compareCodes(codeA, codeB, numericA, numericB) {
    if (numericA && numericB) {
      return parseInt(codeA, 10) - parseInt(codeB, 10);
    }

    if (!numericA && !numericB) {
      return codeA.localeCompare(codeB);
    }
    return numericA ? -1 : 1;
  }

  __sortServiceTypes([keyA], [keyB]) {
    if (keyA === 'Other') return 1;
    if (keyB === 'Other') return -1;

    const codeA = keyA.split('-')[0].trim();
    const codeB = keyB.split('-')[0].trim();

    const numericA = codeA.match(/^\d+$/);
    const numericB = codeB.match(/^\d+$/);

    return this.__compareCodes(codeA, codeB, numericA, numericB);
  }

  __createServiceTypeTable(values, type) {
    const tableStyle = `service-type-table ${type || ''}`;
    return html`
      <table class="${tableStyle}">
        <tbody>
          <tr>
            <td class="table-left secondary-table">Benefit</td>
            <td class="table-left secondary-table">Coverage Level</td>
            <td class="table-left secondary-table">In Network</td>
            <td class="table-left secondary-table">Out Of Network</td>
            <td class="table-left secondary-table">Time Period</td>
            <td class="table-left secondary-table">Insurance Type</td>
            <td class="table-left secondary-table">Additional Information</td>
          </tr>
          ${values.map(
            value => html`
              <tr>
                <td class="secondary-table">${value.name}</td>
                <td class="secondary-table">${value.coverageLevel}</td>
                ${this.__getAmountRows(value)}
                <td class="secondary-table">${value.timeInfo}</td>
                <td class="secondary-table">${value.insuranceType}</td>
                <td class="secondary-table">
                  ${value.additionalInfo
                    ? html`
                        <ul>
                          ${value.additionalInfo.map(
                            info => html` <li>${info}</li> `,
                          )}
                        </ul>
                      `
                    : ''}
                </td>
              </tr>
            `,
          )}
        </tbody>
      </table>
    `;
  }

  __renderActiveServiceTypes(activeServiceTypes) {
    return activeServiceTypes && Object.entries(activeServiceTypes).length > 0
      ? html`
          <div
            class="practice-service-types-header h2-container"
            @click="${this.__handlers.toggleServiceTypes}"
          >
            <h2>Practice Service Types</h2>
            <neb-icon
              class="chevron-icon-practice"
              icon="neb:chevron"
            ></neb-icon>
          </div>
          <div class="service-types-content">
            ${Object.entries(activeServiceTypes)
              .sort(this.__sortServiceTypes.bind(this))
              .map(
                ([key, values]) => html`
                  <div
                    class="benefit-table-header practice-service-type"
                    @click="${this.__handlers.toggleTableVisibility}"
                  >
                    <h3>${key}</h3>
                    <neb-icon
                      class="chevron-icon"
                      icon="neb:chevron"
                    ></neb-icon>
                  </div>
                  <div class="collapsible-content">
                    ${this.__createServiceTypeTable(
                      values,
                      'practice-service-type-table',
                    )}
                  </div>
                `,
              )}
          </div>
        `
      : html`
          <div class="practice-service-types-header h2-container">
            <h2>Practice Service Types</h2>
            <neb-icon
              class="chevron-icon-practice"
              icon="neb:chevron"
            ></neb-icon>
          </div>
          <div class="service-types-content">
            <div class="no-data">
              None of the returned service types are active.
            </div>
          </div>
        `;
  }

  __renderInactiveServiceTypes(inactiveServiceTypes) {
    return inactiveServiceTypes &&
      Object.entries(inactiveServiceTypes).length > 0
      ? html`
          <div
            class="secondary-benefit-header h2-container collapsed-section"
            @click="${this.__handlers.toggleServiceTypes}"
          >
            <h2>All Other Service Types</h2>
            <neb-icon
              class="chevron-icon-all-other"
              icon="neb:chevron"
            ></neb-icon>
          </div>
          <div class="service-types-content">
            ${Object.entries(this.reportData.benefitInfo.inactiveServiceTypes)
              .sort(this.__sortServiceTypes.bind(this))
              .map(
                ([key, values]) => html`
                  <div
                    class="benefit-table-header all-other-service-type"
                    @click="${this.__handlers.toggleTableVisibility}"
                  >
                    <h3>${key}</h3>
                    <neb-icon
                      class="chevron-icon"
                      icon="neb:chevron"
                    ></neb-icon>
                  </div>
                  <div class="collapsible-content" style="display: none;">
                    ${this.__createServiceTypeTable(
                      values,
                      'all-other-service-type-table',
                    )}
                  </div>
                `,
              )}
          </div>
        `
      : html`
          <div class="secondary-benefit-header h2-container">
            <h2>All Other Service Types</h2>
          </div>
          <div class="service-types-content">
            <div class="no-data">No other returned service types.</div>
          </div>
        `;
  }

  render() {
    const { idInfo, benefitInfo, dateString } = this.reportData;
    const { activeServiceTypes, inactiveServiceTypes } = benefitInfo;

    const entries = Object.entries(idInfo);
    const midIndex = Math.ceil(entries.length / 2);
    const firstHalf = entries.slice(0, midIndex);
    const secondHalf = entries.slice(midIndex);

    return html`
      ${this.__renderHeaderSection()}
      <div id="content-to-print" class="container">
        <div class="main-header-container" @click="${
          this.__handlers.collapseMainHeader
        }">
        <h1>${`Patient Eligibility Benefits - ${dateString}`}</h1>
        <neb-icon class="chevron-icon-large" icon="neb:chevron"></neb-icon>
        </div>
        <div class="main-info-section">
          <div class="flex-container">
            <div class="flex-item">
              ${firstHalf.map(
                ([key, values]) => html`
                  <div class="table-section">
                    <h2>${key}</h2>
                    ${this.__createIdInfoTable(values)}
                  </div>
                `,
              )}
            </div>
            <div class="flex-item">
              ${secondHalf.map(
                ([key, values]) => html`
                  <div class="table-section">
                    <h2>${key}</h2>
                    ${this.__createIdInfoTable(values)}
                  </div>
                `,
              )}
            </div>
          </div>
        </div>
        <div class="table-section">
        <div id="${
          ELEMENTS.benefitsSection.id
        }" class="main-header-container" @click="${
          this.__handlers.collapseSecondaryHeader
        }">
        <h1>Benefits Information (2110C/D)</h2>
        <neb-icon class="chevron-icon-large" icon="neb:chevron"></neb-icon>
        </div>
        <div class="secondary-info-section">
          ${this.__renderActiveServiceTypes(activeServiceTypes)}
          ${this.__renderInactiveServiceTypes(inactiveServiceTypes)}
          </div>
        </div>
      </div>
      <div id="${ELEMENTS.backToTopButton.id}" @click="${
        this.__handlers.scrollToTop
      }">
        <neb-icon class="chevron-back-to-top" icon="neb:chevron"></neb-icon>
        </div>
    `;
  }
}

customElements.define(
  'neb-page-eligibility-benefits-viewer',
  NebPageEligibilityBenefitsViewer,
);
