import '../../../../neb-lit-components/src/components/patients/table/neb-patients-table';
import '../../../../neb-lit-components/src/components/neb-pagination';
import '../../../../neb-styles/neb-icons';
import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  getPatientRoster,
  downloadPatientRoster,
} from '../../../../neb-api-client/src/patient-roster-api-client';
import { FROM_CSV } from '../../../../neb-lit-components/src/components/patients/table/neb-patients-data-retriever';
import { CSV_TABLE_METADATA } from '../../../../neb-lit-components/src/components/patients/table/neb-patients-table-meta';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const ID_ACTION_BAR = 'action-bar';
export const ID_TABLE = 'table';
export const ID_DOWNLOAD_LINK = 'download';
export const ID_PAGINATION = 'pagination';
export const DOWNLOAD_LABELS = Object.freeze({
  'Needs Attention': 'Download Needs Attention',
  Duplicate: 'Download Duplicate Records',
  Existing: 'Download Existing Records',
});
class NebPatientsRosterPage extends LitElement {
  static get properties() {
    return {
      importJobId: {
        type: String,
      },
      importStatus: {
        type: String,
      },
      editable: {
        type: Boolean,
      },
      pageSize: {
        type: Number,
      },
      totalCount: {
        type: Number,
      },
      __patients: {
        type: Array,
      },
      __pageCount: {
        type: Number,
      },
      __currentPage: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.editable = false;
    this.pageSize = 0;
    this.__patients = [];
    this.__pageCount = 0;
    this.__currentPage = 0;
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: pageIndex => {
        this.__currentPage = pageIndex;

        this.__retrievePatientRosterPage();
      },
      download: this.__downloadRoster,
    };
  }

  update(changed) {
    if (changed.has('totalCount') || changed.has('pageSize')) {
      this.__calculatePageCount();
    }

    super.update(changed);
  }

  updated(changed) {
    if (changed.has('importJobId') && this.importJobId) {
      this.fetchPatientRoster();
    }
  }

  __downloadRoster() {
    return downloadPatientRoster(this.importJobId, this.importStatus);
  }

  fetchPatientRoster() {
    this.__retrievePatientRosterPage({
      offset: 0,
    });
  }

  async __retrievePatientRosterPage(pageOptions) {
    this.__patients = await getPatientRoster(this.importJobId, {
      importStatus: this.importStatus,
      limit: this.pageSize,
      offset: this.__currentPage * this.pageSize,
      ...pageOptions,
    });
  }

  __calculatePageCount() {
    if (this.totalCount > 0 && this.pageSize) {
      this.__pageCount = Math.ceil(this.totalCount / this.pageSize);
    } else {
      this.__pageCount = 0;
    }
  }

  static get styles() {
    return css`
      :host {
        display: grid;
      }

      .main-container {
        display: flex;
        flex-direction: column;
        min-width: 0;
        min-height: 0;
        height: 100%;
        width: 100%;
      }

      .table-container {
        display: flex;
        flex-direction: column;
        min-height: 0;
        width: 100%;
        height: 100%;
        position: relative;
      }

      .table {
        overflow: auto;
        width: 100%;
        max-height: 100%;
        position: absolute;
        background-color: white;
      }

      .download-container {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: ${CSS_SPACING};
        align-items: flex-end;
      }

      .download-icon-label-container {
        display: flex;
        flex-direction: row;
        cursor: pointer;
      }

      .download-icon {
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .download-label {
        padding: 0 0 0 4px;
        color: ${CSS_COLOR_HIGHLIGHT};
        text-decoration: underline;
      }

      .pagination-container {
        display: flex;
        flex-direction: column;
      }

      .pagination {
        align-self: flex-end;
        margin: ${CSS_SPACING};
      }
    `;
  }

  __renderDownloadLink() {
    return this.importStatus !== 'Ready'
      ? html`
          <div class="download-container">
            <div
              id="${ID_DOWNLOAD_LINK}"
              class="download-icon-label-container"
              @click="${this.__handlers.download}"
            >
              <neb-icon
                id="download-icon"
                class="download-icon"
                icon="neb:download"
              ></neb-icon>
              <div id="download-label" class="download-label">
                ${DOWNLOAD_LABELS[this.importStatus]}
              </div>
            </div>
          </div>
        `
      : '';
  }

  __renderPagination() {
    return this.totalCount > 0
      ? html`
          <div class="pagination-container">
            <neb-pagination
              id="${ID_PAGINATION}"
              class="pagination"
              .pageCount="${this.__pageCount}"
              .currentPage="${this.__currentPage}"
              .onPageChanged="${this.__handlers.pageChanged}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="main-container">
        ${this.__renderDownloadLink()}

        <div class="table-container">
          <neb-patients-table
            id="${ID_TABLE}"
            class="table"
            .patients="${this.__patients}"
            .metadata="${CSV_TABLE_METADATA}"
            .dataGetter="${FROM_CSV}"
            .editable="${this.editable}"
          ></neb-patients-table>
        </div>

        ${this.__renderPagination()}
      </div>
    `;
  }
}
customElements.define('neb-patients-roster-page', NebPatientsRosterPage);
