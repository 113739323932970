import { html, LitElement, css } from 'lit';

import { ckeditorStyles } from '../../../neb-styles/neb-ckeditor-styles';
import { CSS_BORDER_GREY_2 } from '../../../neb-styles/neb-variables';

export const ID_RICH_TEXT_VIEWER = 'rich-text-viewer';

export const ELEMENTS = {
  richTextViewer: { id: 'rich-text-viewer' },
};

const RICH_TEXT_VIEWER_TEMPLATE = `
  ${ckeditorStyles}

  <style>
    html {
      height: 100%;
    }

    body {
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      opacity: 0.5;
    }

    .ck.ck-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ck.ck-editor__main {
      flex: 1;
      overflow-y: auto;
      font-family: sans-serif, Arial, Verdana, 'Trebuchet MS',
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border: none;
      border-top: ${CSS_BORDER_GREY_2} !important;
    }

    .ck.ck-editor__editable {
      height: 100% !important;
      box-sizing: border-box;
    }

    .ck-content .table {
      margin: 1em 0 !important;
    }

    span[contenteditable='false'] {
      user-select: all;
      -moz-user-select: all;
      -webkit-user-select: all;
    }
  </style>

  <textarea name="content" id="editor"></textarea>

  <script src="../assets/ckeditor/ckeditor.js"></script>
  <script src="../ckeditor-scripts/neb-rich-text-viewer-script.js"></script>
`;

class NebRichTextViewer extends LitElement {
  static get properties() {
    return {
      value: String,
    };
  }

  constructor() {
    super();
    this.value = '';
  }

  sync() {
    this.__elements.iframe.contentWindow.postMessage(
      {
        body: this.value,
      },
      '*',
    );
  }

  firstUpdated() {
    this.__elements = {
      iframe: this.shadowRoot.getElementById(ELEMENTS.richTextViewer.id),
    };

    window.onmessage = ({ data }) => {
      if (data.iframeReady) {
        this.sync();
      }
    };
  }

  updated(_changedProps) {
    this.sync();
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        display: block;
      }

      .rich-text-viewer {
        height: 100%;
        width: 100%;
        border: none;
      }
    `;
  }

  render() {
    return html`
      <iframe
        id="${ELEMENTS.richTextViewer.id}"
        class="rich-text-viewer"
        title="rich text editor"
        srcdoc="${RICH_TEXT_VIEWER_TEMPLATE}"
      >
      </iframe>
    `;
  }
}

window.customElements.define('neb-rich-text-viewer', NebRichTextViewer);
