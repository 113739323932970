import * as actions from '../../actions/item-actions';

import * as createHandler from './handlers/create';
import * as deleteHandler from './handlers/delete';
import * as fetchHandler from './handlers/fetch';
import * as updateHandler from './handlers/update';

export const itemReducer = (
  state = {
    fetching: false,
    fetched: false,
    fetchError: null,
    creating: false,
    created: false,
    createError: null,
    updating: false,
    updated: false,
    updateError: null,
    deleting: false,
    deleted: false,
    deleteError: null,
    item: '',
  },
  action,
) => {
  switch (action.type) {
    case actions.ITEM_FETCHING:
      return fetchHandler._fetching(state, action);

    case actions.ITEM_FETCHED:
      return fetchHandler._fetched(state, action);

    case actions.ITEM_FETCH_EXISTS:
      return fetchHandler._fetchExists(state, action);

    case actions.ITEM_FETCH_ERROR:
      return fetchHandler._fetchError(state, action);

    case actions.ITEM_CREATING:
      return createHandler._creating(state, action);

    case actions.ITEM_CREATED:
      return createHandler._created(state, action);

    case actions.ITEM_CREATE_ERROR:
      return createHandler._createError(state, action);

    case actions.ITEM_UPDATING:
      return updateHandler._updating(state, action);

    case actions.ITEM_UPDATED:
      return updateHandler._updated(state, action);

    case actions.ITEM_UPDATE_ERROR:
      return updateHandler._updateError(state, action);

    case actions.ITEM_DELETING:
      return deleteHandler._deleting(state, action);

    case actions.ITEM_DELETED:
      return deleteHandler._deleted(state, action);

    case actions.ITEM_DELETE_ERROR:
      return deleteHandler._deleteError(state, action);

    default:
      return state;
  }
};
