import { parse, isBefore } from 'date-fns';
import pkg from 'validator';

import { currencyToCents } from './formatters';
import { extents, getValueByPath } from './utils';

export const required = (error = 'Required') => ({
  error,
  validate: v => (Array.isArray(v) ? v.length : v),
});

export const genRequireSelect = (defaultLabel = '', error = 'Required') => ({
  error,
  validate: v => v.label !== defaultLabel,
});

export const isSsn = {
  error: '###-##-####',
  validate: v => {
    const REGEX_SSN = /^\d{3}-\d{2}-\d{4}$/g;
    const result = v.match(REGEX_SSN);
    return !v || Boolean(result && result.length);
  },
};

export const isDate = {
  error: 'mm/dd/yyyy',
  validate: v => {
    const REGEX_DATE = /^\d{2}\/\d{2}\/\d{4}$/;
    const validMatch = v.match(REGEX_DATE);
    const validRanges = parse(v).toString() !== 'Invalid Date';
    return !v || Boolean(validMatch && validRanges);
  },
};

export const isDateOfBirth = {
  error: 'Invalid Date of Birth',
  validate: v => {
    const DATE_OLDEST = new Date(1900, 0, 1);
    const date = parse(v);

    if (date.toString() !== 'Invalid Date') {
      const tooOld = isBefore(date, DATE_OLDEST);
      const future = date.getTime() > new Date().getTime();
      return !tooOld && !future;
    }

    return false;
  },
};

export const isEmail = {
  error: 'email@mail.com',
  validate: v => !v || pkg.isEmail(v),
};

export const isPhoneNumber = {
  error: '(###) ###-####',
  validate: v => !v || pkg.isMobilePhone(v),
};

export const isTaxID = {
  error: 'Must be 9 digits',
  validate: v => !v || (v.length && v.length === 10),
};

export const isZipCode = {
  error: '#####(-####)',
  validate: v => !v || pkg.isPostalCode(v, 'US'),
};

export function requireIf(primaryKey, secondaryKey) {
  return {
    error: {
      [secondaryKey]: 'Required',
    },
    validate: v => !v[primaryKey] || v[secondaryKey],
  };
}

export function duplicate(error = 'Duplicate') {
  return {
    error,
    validate: (v, keyPath, state) => {
      const revPath = [...keyPath].reverse();
      const lastKey = revPath[0];
      const targetIndex = Number(revPath[1]);
      const rootPath = keyPath.slice(0, keyPath.length - 2);
      const items = getValueByPath(state, rootPath);
      const values = items
        .map(item => item[lastKey])
        .filter((_, index) => index !== targetIndex);
      return values.indexOf(v) === -1;
    },
  };
}

export function uniquePair({ key, path }, error = 'Duplicate') {
  return {
    error,
    validate: (v, keyPath, state) => {
      const revPath = [...keyPath].reverse();
      const lastKey = revPath[0];
      const targetIndex = Number(revPath[1]);
      const rootPath = keyPath.slice(0, keyPath.length - 2);
      const items = getValueByPath(state, rootPath);
      const targetItem = items[targetIndex];

      const result = items.filter(item => {
        let siblingValue = item[key];
        let targetSiblingValue = targetItem[key];

        if (path) {
          path.split('.').forEach(key => {
            siblingValue = siblingValue[key];
            targetSiblingValue = targetSiblingValue[key];
          });
        }

        return (
          item !== targetItem &&
          item[lastKey] === v &&
          siblingValue === targetSiblingValue
        );
      });

      return result.length === 0;
    },
  };
}

export function min(extent, inclusive = true, error = true) {
  return {
    error,
    validate: v => v === '' || (inclusive ? v >= extent : v > extent),
  };
}

export function max(extent, inclusive = true, error = true) {
  return {
    error,
    validate: v => v === '' || (inclusive ? v <= extent : v < extent),
  };
}

export function range(ext1, ext2, opts = {}) {
  const incMin = opts.incMin === undefined ? true : opts.incMin;
  const incMax = opts.incMax === undefined ? true : opts.incMax;
  const { min, max } = extents(Number(ext1), Number(ext2));
  const error = opts.error || `${min} - ${max}`;

  return {
    error,
    validate: v => {
      const value =
        typeof v === 'string' && v.charAt(0) === '$' ? currencyToCents(v) : v;
      const passesMin = incMin ? value >= min : value > min;
      const passesMax = incMax ? value <= max : value < max;
      return value === '' || (passesMin && passesMax);
    },
  };
}

export function noSpaces(error = 'Invalid') {
  return {
    error,
    validate: v => v.indexOf(' ') < 0,
  };
}

export function maxLength(length, error = 'Invalid') {
  return {
    error,
    validate: v => v.length <= length,
  };
}
