import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';

import * as apiPatientCases from '../../../../../neb-api-client/src/patient-cases';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { REFERRING_PROVIDER_QUALIFIER_LABEL } from '../../../../../neb-utils/claims';

const isEmpty = value => value === null;

const normalizeProviderObject = providerObject => ({
  ...providerObject,
  value: Object.fromEntries(
    Object.entries(providerObject.value).map(([key, value]) => {
      if (isEmpty(value)) return [key, ''];
      return [key, value];
    }),
  ),
});

const getProviderObject = (qualifier, name, model) => {
  const providerObject = {
    ...REFERRING_PROVIDER_QUALIFIER_LABEL[qualifier],
    value: {
      credentials: model[`${name}Credentials`],
      firstName: model[`${name}FirstName`],
      middleName: model[`${name}MiddleName`],
      lastName: model[`${name}LastName`],
      npi: model[`${name}NPI`],
      otherIdQualifier:
        model[`${name}OtherIdQualifier`] === undefined
          ? model[`${name}Qualifier`]
          : model[`${name}OtherIdQualifier`],
      otherId: model[`${name}OtherId`],
    },
  };

  if (Object.values(providerObject.value).some(value => isEmpty(value))) {
    return normalizeProviderObject(providerObject);
  }

  return providerObject;
};

const getReferringProviderOptions = async (state, model) => {
  const patientCase = state.patientCaseId
    ? await apiPatientCases.fetchOne(state.patientId, state.patientCaseId)
    : {};

  const referringProvider = getProviderObject(
    REFERRING_PROVIDER_QUALIFIER_LABEL.DN.qualifier,
    'referringProvider',
    patientCase,
  );

  const orderingProvider = getProviderObject(
    REFERRING_PROVIDER_QUALIFIER_LABEL.DK.qualifier,
    'orderingProvider',
    patientCase,
  );

  const existingOption = getProviderObject(
    model.referringProviderQualifier,
    'referringProvider',
    model,
  );

  const options = [];

  if (
    (model.referringProviderQualifier === 'DK' &&
      !equal(orderingProvider, existingOption)) ||
    (model.referringProviderQualifier === 'DN' &&
      !equal(referringProvider, existingOption))
  ) {
    options.push(existingOption);
  }

  if (Object.values(referringProvider.value).some(v => v)) {
    options.push(referringProvider);
  }

  if (Object.values(orderingProvider.value).some(v => v)) {
    options.push(orderingProvider);
  }

  options.push(
    {
      ...REFERRING_PROVIDER_QUALIFIER_LABEL.DQ,
      showNameFields: true,
      value: {
        credentials: '',
        firstName: '',
        middleName: '',
        lastName: '',
        npi: '',
        otherId: '',
        otherIdQualifier: '',
      },
    },
    {
      qualifier: '',
      label: 'None',
      value: {
        credentials: '',
        firstName: '',
        middleName: '',
        lastName: '',
        npi: '',
        otherId: '',
        otherIdQualifier: '',
      },
    },
  );

  return options;
};

const NAME_TO_KEY = Object.freeze({
  referringProviderFirstName: 'firstName',
  referringProviderLastName: 'lastName',
  referringProviderMiddleName: 'middleName',
  referringProviderCredentials: 'credentials',
  referringProviderQualifier: 'qualifier',
  referringProviderNPI: 'npi',
  referringProviderOtherId: 'otherId',
});

function setEditedField({ result, state, formService }) {
  Object.entries(NAME_TO_KEY).forEach(([name, key]) => {
    if (state[name] !== result[key]) {
      formService.apply(`claimEditedField.${name}`, true);
    }
  });
}

export default async ({
  claimCellData,
  state,
  model,
  handlers,
  formService,
}) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_RADIO_NAME_FIELDS, {
    title: claimCellData.title,
    options: await getReferringProviderOptions(state, model),
    items: [
      {
        name: 'qualifier',
        value: state.referringProviderQualifier,
      },
      {
        name: 'firstName',
        value: state.referringProviderFirstName,
      },
      {
        name: 'lastName',
        value: state.referringProviderLastName,
      },
      {
        name: 'middleName',
        value: state.referringProviderMiddleName,
      },
      {
        name: 'credentials',
        value: state.referringProviderCredentials,
      },
      {
        name: 'npi',
        value: state.referringProviderNPI,
      },
      {
        name: 'otherId',
        value: state.referringProviderOtherId,
      },
      {
        name: 'otherIdQualifier',
        value: state.referringProviderOtherIdQualifier,
      },
    ],
  });

  if (result) {
    setEditedField({ result, state, formService });

    handlers.change({
      name: 'referringProviderFirstName',
      value: result.firstName,
    });

    handlers.change({
      name: 'referringProviderLastName',
      value: result.lastName,
    });

    handlers.change({
      name: 'referringProviderMiddleName',
      value: result.middleName,
    });

    handlers.change({
      name: 'referringProviderCredentials',
      value: result.credentials,
    });

    handlers.change({
      name: 'referringProviderQualifier',
      value: result.qualifier,
    });

    handlers.change({
      name: 'referringProviderNPI',
      value: result.npi,
    });

    handlers.change({
      name: 'referringProviderOtherId',
      value: result.otherId,
    });

    handlers.change({
      name: 'referringProviderOtherIdQualifier',
      value:
        typeof result.otherIdQualifier === 'object'
          ? result.otherIdQualifier.value
          : result.otherIdQualifier,
    });
  }
};
