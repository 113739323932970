import { css } from 'lit';

import {
  ASSOCIATED_PAYER,
  buildNoItems,
} from '../../../../../src/utils/user-message';

import NebTable, { SORT_DIR } from './neb-table';

export const ELEMENTS = {
  row: {
    selector: '[id^=row-]',
  },
};

export const TABLE_ROWS_KEYS = {
  ALIAS: 'alias',
  PAYERID: 'payerId',
  STATUS: 'status',
  PAYERNAME: 'payerName',
};

const TABLE_CONFIG = [
  {
    key: TABLE_ROWS_KEYS.ALIAS,
    label: 'Alias',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.PAYERID,
    label: 'Payer ID',
    flex: css`1 0 0`,
    mobile: true,
  },
  {
    key: TABLE_ROWS_KEYS.PAYERNAME,
    label: 'Payer Name',
    flex: css`3 0 0`,
    mobile: true,
    truncate: true,
  },
  {
    key: TABLE_ROWS_KEYS.STATUS,
    label: 'Status',
    flex: css`1 0 0`,
    mobile: true,
    truncate: true,
  },
];

const SORT_PARAMS = {
  key: TABLE_ROWS_KEYS.ALIAS,
  dir: SORT_DIR.ASC,
};

class NebTablePayerGroupsSelectPayers extends NebTable {
  initState() {
    super.initState();
    this.config = TABLE_CONFIG;
    this.sortParams = SORT_PARAMS;
    this.emptyMessage = buildNoItems(ASSOCIATED_PAYER);
  }
}

customElements.define(
  'neb-table-payer-groups-select-payers',
  NebTablePayerGroupsSelectPayers,
);
