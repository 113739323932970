import * as patientApiClient from '../../neb-api-client/src/patient-api-client';
import * as patientPhotoApi from '../../neb-api-client/src/patient-image-api-client';
import { getHideInactivePatients } from '../patient';
import { FetchService } from '../services/fetch';

const PAGE_SIZE = 20;
export const PATIENT_SEARCH_DEBOUNCE_DURATION = 500;

function fetchPhoto(item, service, onChange) {
  item.photoSrcP = patientPhotoApi
    .getPatientImage(item.id, 'large', true)
    .then(photoSrc => {
      service.updateItem({ ...item, photoSrc });
      onChange(service.getState());
    });
}

function fetchPhotos(service, onChange) {
  service.getState().pageItems.forEach((item, _index) => {
    if (item.hasPhoto && !item.photoSrcP) {
      fetchPhoto(item, service, onChange);
    }
  });
}

const fetchMorePatients = queryParams =>
  patientApiClient.fetchMany(queryParams);

export const createServerPatientsModel = () => ({
  ...FetchService.createModel(),
  body: { isRecentPatientList: false },
});

class PatientFetchService extends FetchService {
  /*
   * Fixes a bug in super.__enqueue task where the task guard causes bugs with searching due
   * to the way it has "first promise wins".  Fixing FetchService itself is more desireable
   * but currently is causing too many unit test issues due to suspect api design of class
   */
  __enqueueTask(append = false) {
    this.__task = Promise.resolve(append).then(async a => {
      await this.__fetch(a);
      this.__task = null;
    });
  }

  /**
   * Allow caller to await for fetch calls, e.g. initialization
   */
  async fetchComplete() {
    if (this.__task) {
      await this.__task;
    }
  }
}

export const createServerPatientsCollection = ({
  onChange,
  alwaysShowInactive = false,
  initialFetch = false,
  excludePatientId = undefined,
  recentList = false,
}) => {
  const hideInactive = getHideInactivePatients() && !alwaysShowInactive;

  const initialQuery = recentList ? { recentRecords: true } : {};
  const service = new PatientFetchService(
    {
      onChange: state => {
        fetchPhotos(service, onChange);
        onChange(state);
      },
    },
    fetchMorePatients,
    {
      hideInactive,
      pageSize: PAGE_SIZE,
      debounceDuration: PATIENT_SEARCH_DEBOUNCE_DURATION,
      initialQuery,
    },
  );

  if (excludePatientId) {
    service.setQuery('excludePatientId', excludePatientId);
  }

  if (initialFetch) {
    service.fetch();
  }
  return service;
};
