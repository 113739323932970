import { html, css } from 'lit';
import moment from 'moment-timezone';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../controls/inputs/neb-checkbox';

import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../../packages/neb-lit-components/src/components/neb-text';

export const CONFIG = [
  {
    key: 'checked',
    label: '',
    flex: css`0 0 28px`,
  },
  {
    key: 'createdAt',
    label: 'Uploaded',
    flex: css`1 0 0`,
    sortable: true,
    formatter: value =>
      value ? moment(value).format('MM/DD/YYYY h:mm:ss A') : '',
  },
  {
    key: 'fileName',
    label: 'File Name',
    flex: css`1 0 0`,
  },
  {
    key: 'clearinghouseId',
    label: 'Clearinghouse',
    flex: css`1 0 0`,
  },
  {
    key: 'createdBy',
    label: 'CreatedBy',
    flex: css`0 0 100px`,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`0 0 100px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`2 0 0`,
  },
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkboxes: { selector: 'neb-checkbox' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  dates: { selector: '.date' },
  label: { id: 'label' },
};

const BULK_ACTION_MENU = {
  selectAll: {
    id: 'selectAll',
    label: 'Select All Files',
  },
  deselectAll: { id: 'deselectAll', label: 'Deselect All' },
  resendSelected: { id: 'resend', label: 'Resend Selected' },
};

class NebTableFileUpload extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        .cell-checkbox {
          padding: 0 5px;
        }

        .cell-header {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .label {
          margin-right: 5px;
        }

        .icon {
          margin-left: 4px;
        }

        .reportNumberText {
          display: inline-block;
          max-width: 100px;
        }

        .reportsContainer {
          overflow-wrap: normal;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),
      getBulkActions: () => {
        const checkedRowItems = this.model.filter(row => row.checked);

        return [
          {
            id: BULK_ACTION_MENU.selectAll.id,
            label: BULK_ACTION_MENU.selectAll.label,
            onSelect: this.onSelectAll,
          },
          {
            id: BULK_ACTION_MENU.deselectAll.id,
            label: BULK_ACTION_MENU.deselectAll.label,
            onSelect: this.onDeselectAll,
          },
          {
            id: BULK_ACTION_MENU.resendSelected.id,
            label: BULK_ACTION_MENU.resendSelected.label,
            onSelect: checkedRowItems.length
              ? this.onResendSelected
              : this.onOpenNoValidResends,
          },
        ];
      },
    };
  }

  initState() {
    super.initState();

    this.config = CONFIG;

    this.onSelectAll = () => {};

    this.onDeselectAll = () => {};

    this.onResendSelected = () => {};

    this.onOpenNoValidResends = () => {};
  }

  __renderCheckboxCell({ value, rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        .name="${rowIndex}"
        .onChange="${this.handlers.change}"
        ?checked="${value}"
        @click="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }

  renderHeaderCell(headerCell) {
    return headerCell.key === 'checked'
      ? html`
          <neb-button-actions
            id="${ELEMENTS.bulkActionMenu.id}"
            class="ellipsis"
            align="left"
            .onClick="${this.handlers.getBulkActions}"
          ></neb-button-actions>
        `
      : super.renderHeaderCell(headerCell);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ value, rowIndex });

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

customElements.define('neb-table-file-upload', NebTableFileUpload);
