import { LitElement, html } from 'lit';
import SignaturePad from 'signature_pad';

class NebDrawableCanvas extends LitElement {
  render() {
    return html`
      <style>
        :host {
          height: 100%;
          width: 100%;
        }
        canvas {
          height: 100%;
          width: 100%;
        }
        #container {
          height: 100%;
          width: 100%;
        }
      </style>
      <div id="container"><canvas></canvas></div>
    `;
  }

  firstUpdated() {
    // getBoundingClientRect is always 0 width here, so we use requestAnimationFrame()
    // to wait until the element is fully rendered to the page.
    const canvas = this.shadowRoot.querySelector('canvas');
    window.requestAnimationFrame(() => {
      this.signaturePad = new SignaturePad(canvas);

      this.__resizeCanvas(canvas);
    });

    this.resizeHandler = () => {
      this.__resizeCanvas(canvas);
    };

    window.addEventListener('resize', this.resizeHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.resizeHandler);
  }

  saveCanvas() {
    return this.signaturePad.toDataURL('png');
  }

  __resizeCanvas(canvas) {
    const clientRect = this.shadowRoot
      .querySelector('#container')
      .getBoundingClientRect();
    const image = this.signaturePad.toData();
    canvas.height = clientRect.height;
    canvas.width = clientRect.width;

    if (this.onResizeCanvas) {
      this.onResizeCanvas();
    }

    this.signaturePad.clear();
    this.signaturePad.fromData(image);
  }
}

customElements.define('neb-drawable-canvas', NebDrawableCanvas);
