import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { SERVICE_FACILITY_ADDRESS_TYPE } from '../../../../../neb-utils/claims';

const formatServiceFacilityAddress = address => ({
  address1: address.address1 || '',
  address2: address.address2 || '',
  city: address.city || '',
  state: address.state || '',
  zipCode: address.zipcode || address.zipCode || '',
  phone: address.phone || '',
});

const getLocationOptions = locations =>
  locations.map(location => ({
    data: {
      id: location.id,
      serviceFacilityAddress: formatServiceFacilityAddress(location),
      serviceFacilityAddressType: location.id,
      serviceFacilityName: location.businessName || location.name,
    },
    label: location.name,
  }));

const getServiceFacilityOptions = (billingInfo, practiceInfo, locations) => {
  const { billingAddress, payToAddress } = billingInfo;

  let billingLocation;
  let billingLocationName;
  let payToLocation;
  let payToLocationName;

  if (billingAddress && billingAddress.locationId) {
    billingLocation = locations.find(
      loc => loc.id === billingAddress.locationId,
    );

    if (billingLocation) {
      billingLocationName =
        billingLocation.businessName || billingLocation.name;
    }
  }

  if (payToAddress && payToAddress.locationId) {
    payToLocation = locations.find(loc => loc.id === payToAddress.locationId);

    if (payToLocation) {
      payToLocationName = payToLocation.businessName || payToLocation.name;
    }
  }

  return [
    {
      serviceFacilityAddress: formatServiceFacilityAddress(billingAddress),
      serviceFacilityAddressType: SERVICE_FACILITY_ADDRESS_TYPE.BILLING_ADDRESS,
      serviceFacilityName: billingLocationName || practiceInfo.name,
    },
    {
      serviceFacilityAddress: formatServiceFacilityAddress(payToAddress),
      serviceFacilityAddressType: SERVICE_FACILITY_ADDRESS_TYPE.PAY_TO_ADDRESS,
      serviceFacilityName: payToLocationName || practiceInfo.name,
    },
  ];
};

export default async ({
  claimCellData,
  state,
  handlers,
  billingInfo,
  practiceInfo,
  locations,
  formService,
}) => {
  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SERVICE_FACILITY, {
    title: `${claimCellData.title} Information`,
    tooltipText: claimCellData.tooltipText,
    serviceFacilityAddress: formatServiceFacilityAddress(
      state.serviceFacilityAddress,
    ),
    serviceFacilityAddressType: state.serviceFacilityAddressType,
    serviceFacilityName: state.serviceFacilityName,
    options: await getServiceFacilityOptions(
      billingInfo,
      practiceInfo,
      locations,
    ),
    locationOptions: getLocationOptions(locations.filter(l => !l.addressOnly)),
  });

  if (result) {
    Object.entries(result).forEach(([name, value]) => {
      if (name === 'serviceFacilityAddress') {
        Object.entries(value).forEach(([addressName, addressValue]) => {
          handlers.change({
            name: `${name}.${addressName}`,
            value: addressValue,
          });
        });

        return;
      }

      formService.apply(`claimEditedField.${name}`, true);

      handlers.change({
        name,
        value,
      });
    });
  }
};
