import '../../neb-action-bar';
import '../../tables/neb-table-encounter-charges';
import '../../../../../neb-www-practice-charting/src/components/encounter-charges/neb-encounter-charges-add';
import { inRange, isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { NO_ENCOUNTER_CHARGES } from '../../../../../../src/utils/user-message';
import {
  buildModel,
  getUpdatedBilledAmount,
} from '../../../../../neb-api-client/src/services/encounter-charge';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../../neb-api-client/src/services/practice-settings';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_DISABLED,
} from '../../../../../neb-styles/neb-variables';
import { mapSettingsCharge } from '../../../../../neb-utils/fee-schedule';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { modifiersSelector } from '../../field-groups/neb-modifiers';
import {
  ELEMENTS as BASE_ELEMENTS,
  NebFormOld,
} from '../../forms/neb-form-old';

import '../../controls/neb-button-action';
import {
  filterDx,
  formatAddedCharge,
} from './neb-encounter-charges-detail-util';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  chargeTable: { id: 'charge-table' },
  chargesAdd: { id: 'add-charges' },
  addCodeBundleButton: {
    id: 'add-code-bundle-button',
  },
  autoPointDiagnosesButton: {
    id: 'auto-point-diagnoses-button',
  },
  reviewLedgerButton: {
    id: 'add-review-ledger-button',
    text: 'Review Ledger',
    leadingIcon: 'wallet',
    trailingIcon: 'open',
  },
};

class NebEncounterChargesDetail extends NebFormOld {
  static get properties() {
    return {
      activeCharges: Array,
      diagnoses: Array,
      addChargesModel: Object,
      visible: Boolean,
      layout: String,
      authorizedProcedures: Array,
      reviewLedgerPermission: Boolean,
      providerId: String,
      __autoDiagnosisPointing: Boolean,
      __autoPostCharges: Boolean,
    };
  }

  static createModel() {
    return buildModel(['items']);
  }

  initState() {
    super.initState();

    this.layout = '';
    this.activeCharges = [];
    this.providerId = '';
    this.diagnoses = [];
    this.encounter = null;
    this.addChargesModel = {};
    this.visible = false;
    this.reviewLedgerPermission = false;
    this.authorizedProcedures = null;
    this.feeSchedule = {};

    this.__autoDiagnosisPointing = false;
    this.__autoPostCharges = false;

    this.onFilterChanged = () => {};

    this.onPageChanged = () => {};

    this.onCodeBundleAdded = () => {};

    this.onReviewLedger = () => {};

    this.onCheckingUnallocationAuth = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addCodeBundle: async () => {
        const result = await openOverlay(
          OVERLAY_KEYS.ADD_CODE_BUNDLE_TO_ENCOUNTER,
          { encounter: this.encounter },
        );

        if (result) {
          this.onCodeBundleAdded();
        }
      },
      autoPointDiagnoses: () => {
        if (!this.diagnoses.length) {
          return;
        }
        const diags4 = this.diagnoses.slice(
          0,
          Math.min(this.diagnoses.length, 4),
        );
        this.state.items.forEach((item, index) => {
          if (!item.diagnosisPointers.length) {
            this.formService.apply(`items.${index}.diagnosisPointers`, [
              ...diags4,
            ]);
          }
        });
      },
      reviewLedger: () => this.onReviewLedger(),
      changePage: pageIndex => this.onPageChanged(pageIndex + 1),
      changeFilter: searchText => this.onFilterChanged(searchText),
      postAllToLedger: async () => {
        const postAll = this.__stateCharges.some(({ posted }) => !posted);

        this.__stateCharges.forEach((_, idx) =>
          this.formService.apply(`items.${idx}.posted`, postAll),
        );

        await this.handlers.save();
      },
      updateCharge: ({ name, value }) => {
        this.formService.apply(name, value);

        const { billedAmountKey, billedAmountValue } = getUpdatedBilledAmount({
          name,
          value,
          charges: this.__stateCharges,
          formField: 'items',
        });
        if (!billedAmountKey) return;

        this.formService.apply(billedAmountKey, billedAmountValue);
      },
      removeCharge: (name, index) => {
        this.formService.removeItem(name, index);
      },
      addAllPriorEncounterCharges: charges =>
        charges.forEach(c => {
          const model = { ...c, id: c.chargeId };
          this.handlers.chargeAdded({
            detail: {
              model,
              priorCharge: true,
            },
          });
        }),
      chargeAdded: ({ detail: { model, priorCharge } }) => {
        const totalCharges = this.state.items.length;

        let charge = model;

        if (priorCharge) {
          charge = {
            ...this.activeCharges.find(
              c => c.id === model.chargeId || c.id === model.id,
            ),
            units: model.units,
            modifiers: model.modifiers,
            diagnosisPointers: model.diagnosisPointers,
          };

          charge = mapSettingsCharge(charge, this.feeSchedule);
        }

        const formServiceMappedCharges = formatAddedCharge({
          charge,
          stateTotalCharges: totalCharges,
          diagnoses: this.diagnoses,
          autoDiagnosisPointing: this.__autoDiagnosisPointing,
          autoPost: this.__autoPostCharges,
        });

        formServiceMappedCharges.forEach(mappedCharge => {
          this.formService.addItem('items');
          Object.entries(mappedCharge).forEach(([key, value]) =>
            this.formService.apply(key, value),
          );
        });
      },
      save: async () => {
        if (this.formService.validate()) {
          const model = this.formService.buildModel();

          const authUnallocation = await this.onCheckingUnallocationAuth(
            this.__stateCharges,
          );
          if (!authUnallocation) return;

          this.__saving = true;
          this.onSave(model, { closeAfterSave: false });
        }
      },
      saveAndClose: async () => {
        if (this.formService.validate()) {
          const model = this.formService.buildModel();

          const authUnallocation = await this.onCheckingUnallocationAuth(
            this.__stateCharges,
          );
          if (!authUnallocation) return;

          this.__saving = true;
          this.onSave(model, { closeAfterSave: true });
        }
      },
    };
  }

  get __stateCharges() {
    const { items } = this.formService.buildModel();
    return items;
  }

  buildSelectors() {
    return {
      items: {
        genItem: () => ({
          chargeId: '',
          procedure: '',
          description: '',
          modifiers: ['', '', '', ''],
          units: '1',
          taxName: null,
          taxRate: null,
          diagnosisPointers: [],
          order: this.state.items.length || 0,
          unitCharge: 0,
          feeScheduleName: '',
          feeScheduleCharge: 0,
          allowedAmount: 0,
          billedAmount: 0,
          EPSDTCode: false,
          suppressFromClaim: false,
          billWithTreatmentInitiationDate: false,
          billWithXrayDate: false,
          postedToLedgerId: null,
          posted: false,
          repost: false,
          nationalDrugCodeEnabled: false,
          nationalDrugCodeQualifier: '',
          nationalDrugCode: '',
          nationalDrugCodeDosage: '',
          nationalDrugCodeUnitOfMeasurement: '',
          nationalDrugCodeNumberCategory: '',
          nationalDrugCodeSequenceOrPrescription: '',
        }),
        format: charges =>
          charges.map(c => ({
            id: c.id,
            chargeId: c.chargeId,
            procedure: c.procedure,
            description: c.description,
            units: c.units,
            taxName: c.taxName,
            taxRate: c.taxRate,
            diagnosisPointers: c.diagnosisPointers,
            order: c.order,
            modifiers: [
              c.modifier_1 || '',
              c.modifier_2 || '',
              c.modifier_3 || '',
              c.modifier_4 || '',
            ],
            unitCharge: c.unitCharge,
            feeScheduleName: c.feeScheduleName,
            feeScheduleCharge: c.feeScheduleCharge,
            allowedAmount: c.allowedAmount,
            billedAmount: c.billedAmount,
            EPSDTCode: c.EPSDTCode,
            suppressFromClaim: c.suppressFromClaim,
            billWithTreatmentInitiationDate: c.billWithTreatmentInitiationDate,
            billWithXrayDate: c.billWithXrayDate,
            postedToLedgerId: c.postedToLedgerId,
            posted: !!c.posted,
            repost: !!c.repost,
            nationalDrugCodeEnabled: c.nationalDrugCodeEnabled,
            nationalDrugCodeQualifier: c.nationalDrugCodeQualifier,
            nationalDrugCode: c.nationalDrugCode,
            nationalDrugCodeDosage: c.nationalDrugCodeDosage,
            nationalDrugCodeUnitOfMeasurement:
              c.nationalDrugCodeUnitOfMeasurement,
            nationalDrugCodeNumberCategory: c.nationalDrugCodeNumberCategory,
            nationalDrugCodeSequenceOrPrescription:
              c.nationalDrugCodeSequenceOrPrescription,
          })),
        unformat: charges =>
          charges.map(c => ({
            id: c.id,
            chargeId: c.chargeId,
            procedure: c.procedure,
            description: c.description,
            units: parseInt(c.units, 10),
            taxName: c.taxName,
            taxRate: c.taxRate,
            diagnosisPointers: c.diagnosisPointers,
            order: c.order,
            modifier_1: c.modifiers[0] || null,
            modifier_2: c.modifiers[1] || null,
            modifier_3: c.modifiers[2] || null,
            modifier_4: c.modifiers[3] || null,
            unitCharge: c.unitCharge,
            feeScheduleName: c.feeScheduleName,
            feeScheduleCharge: c.feeScheduleCharge,
            allowedAmount: c.allowedAmount,
            EPSDTCode: c.EPSDTCode,
            suppressFromClaim: c.suppressFromClaim,
            billWithTreatmentInitiationDate: c.billWithTreatmentInitiationDate,
            billWithXrayDate: c.billWithXrayDate,
            postedToLedgerId: c.postedToLedgerId,
            billedAmount: c.billedAmount,
            posted: !!c.posted,
            repost: !!c.repost,
            nationalDrugCodeEnabled: c.nationalDrugCodeEnabled,
            nationalDrugCodeQualifier: c.nationalDrugCodeQualifier,
            nationalDrugCode: c.nationalDrugCode,
            nationalDrugCodeDosage: c.nationalDrugCodeDosage,
            nationalDrugCodeUnitOfMeasurement:
              c.nationalDrugCodeUnitOfMeasurement,
            nationalDrugCodeNumberCategory: c.nationalDrugCodeNumberCategory,
            nationalDrugCodeSequenceOrPrescription:
              c.nationalDrugCodeSequenceOrPrescription,
          })),
        children: {
          diagnosisPointers: {
            clipErrors: true,
            clipPristine: true,
            format: diagnosisPointers =>
              filterDx(this.diagnoses, diagnosisPointers),
            unformat: v =>
              v.map(dx => ({
                diagnosisCode: dx.code,
              })),
          },
          modifiers: modifiersSelector,
          units: {
            format: v => v.toString(),
            unformat: v => parseInt(v, 10),
            validators: [isRequired('1 - 999'), inRange(1, 999)],
          },
        },
      },
    };
  }

  async connectedCallback() {
    this.addEventListener(
      'neb-encounter-charge-added',
      this.handlers.chargeAdded,
    );

    const practiceSettings = await getPracticeSettings();

    this.__autoDiagnosisPointing =
      practiceSettings[PRACTICE_SETTINGS.AUTO_DIAGNOSIS_POINTING];

    this.__autoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];

    super.connectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { items } = this.model;

      if (items && items.length) {
        const newModel = {
          items: items.sort((a, b) => a.order - b.order),
        };
        this.formService.refresh(newModel);
      }
    }

    super.update(changedProps);
  }

  updated() {
    this.__elements = {
      chargesAdd: this.shadowRoot.getElementById(ELEMENTS.chargesAdd.id),
    };
  }

  clear() {
    this.formService.reset();

    if (this.__elements) {
      this.__elements.chargesAdd.clear();
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .form {
          padding: 0;
          position: relative;
        }

        .title,
        .buttons-group {
          padding-bottom: ${CSS_SPACING};
        }

        .header-buttons,
        .title {
          margin-left: ${CSS_SPACING};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .align-items-center {
          align-items: center;
        }

        .charge-table {
          padding-top: 10px;
        }

        .d-flex {
          display: flex;
        }

        .icon-item {
          padding: 0;
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .pointer {
          cursor: pointer;
        }

        .pointer[disabled] {
          cursor: default;
        }

        .icon-item[disabled] {
          fill: ${CSS_COLOR_DISABLED};
        }

        div[disabled] {
          pointer-events: none;
        }
      `,
    ];
  }

  __renderTable() {
    return html`
      <neb-table-encounter-charges
        id="${ELEMENTS.chargeTable.id}"
        class="charge-table"
        name="items"
        .layout="${this.layout}"
        .diagnoses="${this.diagnoses}"
        .dirty="${this.__dirty}"
        .errors="${this.errors.items}"
        .model="${this.state.items}"
        .hasAutoPostCharges="${this.__autoPostCharges}"
        .onChange="${this.handlers.updateCharge}"
        .onAddAll="${this.handlers.postAllToLedger}"
        .onRemove="${this.handlers.removeCharge}"
        .authorizedProcedures="${this.authorizedProcedures}"
        .forceSelectMenuDown="${true}"
        >${NO_ENCOUNTER_CHARGES}</neb-table-encounter-charges
      >
    `;
  }

  __renderAddChargeTable() {
    return html`
      <neb-encounter-charges-add
        id="${ELEMENTS.chargesAdd.id}"
        .model="${this.addChargesModel}"
        .providerId="${this.providerId}"
        .encounter="${this.encounter}"
        .encounterCharges="${this.state.items}"
        .activeCharges="${this.activeCharges}"
        .visible="${this.visible}"
        .onFilterChanged="${this.handlers.changeFilter}"
        .onPageChanged="${this.handlers.changePage}"
        .onAllPriorEncounterChargesSelected="${this.handlers
          .addAllPriorEncounterCharges}"
      ></neb-encounter-charges-add>
    `;
  }

  __renderReviewLedger(isDirty) {
    return this.reviewLedgerPermission
      ? html`
          <div
            class="pointer d-flex align-items-center"
            ?disabled="${isDirty}"
            @click="${this.handlers.reviewLedger}"
          >
            <neb-button-action
              id="${ELEMENTS.reviewLedgerButton.id}"
              class="header-buttons"
              leadingIcon="${ELEMENTS.reviewLedgerButton.leadingIcon}"
              label="${ELEMENTS.reviewLedgerButton.text}"
              ?disabled="${isDirty}"
              .onClick="${this.handlers.reviewLedger}"
            >
            </neb-button-action>
            <neb-icon
              class="icon-item pointer"
              icon="neb:open"
              ?disabled="${isDirty}"
            ></neb-icon>
          </div>
        `
      : html``;
  }

  __renderAddCodeBundle(isDirty) {
    return html`
      <neb-button-action
        id="${ELEMENTS.addCodeBundleButton.id}"
        class="header-buttons"
        leadingIcon="codeBundle"
        label="Add Code Bundle"
        ?disabled="${isDirty}"
        .onClick="${this.handlers.addCodeBundle}"
      ></neb-button-action>
    `;
  }

  __renderAutoPointDiagnoses() {
    return html`
      <neb-button-action
        id="${ELEMENTS.autoPointDiagnosesButton.id}"
        class="header-buttons"
        leadingIcon="autoPoint"
        label="Auto Point Diagnoses"
        .onClick="${this.handlers.autoPointDiagnoses}"
      ></neb-button-action>
    `;
  }

  __renderButtonGroup() {
    const isDirty = this.isDirty();

    return html`
      <div class="d-flex buttons-group">
        ${this.__renderAddCodeBundle(isDirty)}
        ${this.__renderAutoPointDiagnoses()}
        ${this.__renderReviewLedger(isDirty)}
      </div>
    `;
  }

  renderContent() {
    return html`
      <span class="title">Encounter Charges</span>

      ${this.__renderButtonGroup()} ${this.__renderTable()}
      ${this.__renderAddChargeTable()}
    `;
  }
}

customElements.define(
  'neb-encounter-charges-detail',
  NebEncounterChargesDetail,
);
