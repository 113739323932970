import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import '../../tables/hot-buttons/neb-table-add-categories';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { capitalize } from '../../../../packages/neb-utils/formatters';
import { CollectionService } from '../../../../packages/neb-utils/services/collection';
import * as hotButtonsApi from '../../../api-clients/hot-buttons';
import { CSS_SPACING, OVERLAY_WIDTH_LARGE } from '../../../styles';
import {
  formatCheckboxCategories,
  HOT_BUTTON_SINGULAR_TYPE,
} from '../../../utils/hot-buttons';

export const ELEMENTS = {
  table: { id: 'table' },
  header: { id: 'header' },
  description: { id: 'description' },
  pagination: { id: 'pagination' },
  selectProvider: { id: 'select-provider' },
  actionBar: { id: 'action-bar' },
};

export const POPUP_LINE_BREAK = html` <br /> `;

class NebOverlayCopyCategories extends Overlay {
  static get properties() {
    return {
      ...super.properties,
      __selectedProvider: Object,
      __providerItems: Array,
      __isAllSelected: Boolean,
      __collectionState: Object,
    };
  }

  initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: state => {
          this.__collectionState = state;
        },
      },
      {
        preventAutoSort: true,
        hideInactive: true,
      },
    );
  }

  initState() {
    super.initState();

    this.__collectionState = CollectionService.createModel();
    this.__selectedProvider = null;
    this.__providerItems = [];
    this.__isAllSelected = false;

    this.initServices();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      apply: () => {
        const result = {
          selectedCategoryIds: this.__selectedCategories,
          fromProviderId: this.__selectedProvider.item.id,
        };

        this.dismiss(result);
      },
      changeProvider: async ({ value }) => {
        if (value && value.item.id !== this.__selectedProvider.item.id) {
          if (this.__selectedCategories.length) {
            const dialog = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
              title: 'Change Provider',
              message: [
                'Changing the provider will clear your selected categories.',
                POPUP_LINE_BREAK,
                'Do you wish to proceed?',
              ],
              buttons: [
                { name: 'confirm', label: 'OK' },
                { name: 'cancel', label: 'Cancel' },
              ],
            });

            if (!dialog || dialog === 'cancel') return;
          }

          this.__selectedProvider = value;
          await this.refreshData();
        }
      },
      checkItem: rowIndex => {
        const { pageItems } = this.__collectionState;
        this.__collectionService.updateItem({
          ...pageItems[rowIndex],
          checked: !pageItems[rowIndex].checked,
        });

        this.__selectedCategories = this.getSelectedItems();

        if (
          this.__selectedCategories.length ===
          this.__collectionState.allItems.length
        ) {
          this.__isAllSelected = true;
        }

        if (
          !this.__selectedCategories.length ||
          (this.__selectedCategories.length &&
            this.__selectedCategories.length !==
              this.__collectionState.allItems.length)
        ) {
          this.__isAllSelected = false;
        }
      },
      checkAllItems: () => {
        this.__isAllSelected = !this.__isAllSelected;
        this.__collectionState = {
          ...this.__collectionState,
          allItems: this.__collectionState.allItems.map(item => ({
            ...item,
            checked: this.__isAllSelected,
          })),
        };

        const page = this.__collectionState.pageIndex;

        this.__collectionService.setItems(this.__collectionState.allItems);
        this.__collectionService.setPageIndex(page);
        this.__selectedCategories = this.getSelectedItems();
      },
      changePage: pageIndex => this.__collectionService.setPageIndex(pageIndex),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: column nowrap;
          overflow-y: auto;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .container-table {
          overflow-y: auto;
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          margin: ${CSS_SPACING};
          flex-shrink: 0;
        }

        .select {
          width: 250px;
          margin-left: ${CSS_SPACING};
        }

        .description {
          padding-left: ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }

        .cell-spacer {
          margin-top: ${CSS_SPACING};
          flex: 1 0 0;
        }
      `,
    ];
  }

  async fetch() {
    let categoryItems;

    if (!this.__selectedProvider) {
      this.__providerItems = this.model.providerItems;
      this.__selectedProvider = this.__providerItems && this.__providerItems[0];
    }

    if (this.__selectedProvider) {
      const categories = await hotButtonsApi.getCategories(
        this.model.type,
        this.__selectedProvider.item.id,
        true,
        false,
      );

      categoryItems = formatCheckboxCategories(categories);
    }

    return categoryItems || [];
  }

  async refreshData() {
    const items = await this.fetch();
    this.__collectionService.setItems(items);
    this.__selectedCategories = this.getSelectedItems();
    this.__isAllSelected = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.refreshData();
  }

  getSelectedItems() {
    return this.__collectionState.allItems
      .filter(item => item.checked)
      .map(selectedItem => selectedItem.id);
  }

  __renderHeader() {
    const title = `Copy ${capitalize(
      HOT_BUTTON_SINGULAR_TYPE[this.model.type],
    )} Categories`;

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderDescription() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${`Choose the provider and ${
          HOT_BUTTON_SINGULAR_TYPE[this.model.type]
        } categories you would like to copy`}
      </div>
    `;
  }

  __renderSelectProvider() {
    return html`
      <neb-select
        id="${ELEMENTS.selectProvider.id}"
        class="select"
        label="Provider"
        .value="${this.__selectedProvider}"
        .items="${this.__providerItems}"
        .onChange="${this.handlers.changeProvider}"
        pinLabel
        wrapText
      ></neb-select>
    `;
  }

  __renderTable() {
    return html`
      <div class="container-table">
        <neb-table-add-categories
          id="${ELEMENTS.table.id}"
          class="cell-spacer"
          .model="${this.__collectionState.pageItems}"
          .onCheck="${this.handlers.checkItem}"
          .onCheckAll="${this.handlers.checkAllItems}"
          .isAllSelected="${this.__isAllSelected}"
        ></neb-table-add-categories>
      </div>

      ${this.__renderPagination()}
    `;
  }

  __renderPagination() {
    return this.__collectionState.allItems.length
      ? html`
          <neb-pagination
            id="${ELEMENTS.pagination.id}"
            class="pagination"
            .currentPage="${this.__collectionState.pageIndex}"
            .onPageChanged="${this.handlers.changePage}"
            .pageCount="${this.__collectionState.pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  __renderActionBar() {
    const hasItems =
      this.__selectedCategories && this.__selectedCategories.length;

    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="Copy"
        cancelLabel="Cancel"
        .onCancel="${this.handlers.dismiss}"
        .onConfirm="${this.handlers.apply}"
        .confirmDisabled="${!hasItems}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}${this.__renderDescription()}${this.__renderSelectProvider()}${this.__renderTable()}${this.__renderActionBar()}
    `;
  }
}

customElements.define('neb-overlay-copy-categories', NebOverlayCopyCategories);
