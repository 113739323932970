import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { formatPhoneNumber } from '../../../../neb-utils/formatters';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  name: {
    id: 'name',
  },
  phone: {
    id: 'phone',
  },
  email: {
    id: 'email',
  },
  arrowIcon: {
    id: 'arrow-icon',
  },
};

class NebOrganizationCard extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      enableIcon: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      id: '',
      name: '',
      phones: [],
      emails: [],
    };

    this.enableIcon = true;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this.model.id),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          cursor: pointer;
          display: block;
          outline: none;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0 ${CSS_SPACING};
        }

        .container-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          height: 100%;
        }

        .text-name {
          display: flex;
          color: ${CSS_COLOR_BLACK};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .text-phone,
        .text-email {
          display: flex;
          color: ${CSS_COLOR_GREY_1};
        }

        .icon-arrow {
          align-items: center;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  __renderPhoneNumber() {
    return this.model.phones.length > 0
      ? html`
          <div id="${ELEMENTS.phone.id}" class="text text-phone">
            ${formatPhoneNumber(this.model.phones[0].number)}
          </div>
        `
      : '';
  }

  __renderEmail() {
    return this.model.emails.length > 0
      ? html`
          <div id="${ELEMENTS.email.id}" class="text text-email">
            ${this.model.emails[0]}
          </div>
        `
      : '';
  }

  __renderIcon() {
    return this.enableIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.arrowIcon.id}"
            class="icon-arrow"
            icon="neb:chevronRight"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <div class="container-info">
          <div id="${ELEMENTS.name.id}" class="text text-name">
            ${this.model.name}
          </div>
          ${this.__renderPhoneNumber()} ${this.__renderEmail()}
        </div>
        ${this.__renderIcon()}
      </div>
    `;
  }
}

window.customElements.define('neb-organization-card', NebOrganizationCard);
