const formatItems = selectedItems => {
  const result = selectedItems.reduce(
    (acc, item) => {
      acc.selectedLineItemIds.push(item.id);
      acc.balance = {
        primary: (acc.balance.primary += item.primaryOwed - item.primaryPaid),
        secondary: (acc.balance.secondary +=
          item.secondaryOwed - item.secondaryPaid),
        patient: (acc.balance.patient += item.patientOwed - item.patientPaid),
      };

      return acc;
    },
    {
      selectedLineItemIds: [],
      balance: { primary: 0, secondary: 0, patient: 0 },
    },
  );

  const hasPrimaryPayer = !selectedItems.some(item => !item.primaryPlanId);
  const hasSecondaryPayer = !selectedItems.some(item => !item.secondaryPlanId);

  return { ...result, hasPrimaryPayer, hasSecondaryPayer };
};

export const formatToTransferBalanceModel = items => formatItems(items);
