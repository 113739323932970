/* eslint-disable complexity */
import { html, css } from 'lit';

import { CARDS } from '../../../packages/neb-lit-components/src/components/claims/neb-claims-worklist-cards';
import { CLAIM_STATUS } from '../../../packages/neb-utils/claims';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import { CSS_BORDER_GREY_2 } from '../../styles';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import {
  ELEMENTS as BASE_ELEMENTS,
  NebClaimsWorklistFilters,
  STATUS_CONFIG,
  SUBMISSION_METHODS,
} from './neb-filters-claims-worklist';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  batchId: { id: 'batch-id' },
  createdDateRange: { id: 'created-date-range' },
  procedureCode: { id: 'procedure-code' },
};

export const ALL_STATUS_CONFIG = Object.keys(STATUS_CONFIG).reduce(
  (acc, key) => {
    const { statuses, statusCodes } = STATUS_CONFIG[key];

    if (statuses && statuses.length) {
      acc.statuses.push(...statuses);
    }

    if (statusCodes && statusCodes.length) {
      acc.statusCodes.push(...statusCodes);
    }

    return acc;
  },
  { statuses: [{ label: 'Draft', data: { id: 'Draft' } }], statusCodes: [] },
);

class NebFiltersAllClaims extends NebClaimsWorklistFilters {
  static get properties() {
    return {
      filterDefaults: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: none;
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .checkbox-practice {
          padding-top: 0;
          padding-bottom: 25px;
        }

        .checkbox {
          padding-top: 27px;
        }
      `,
    ];
  }

  static createModel() {
    const { statusCodes, ...model } = super.createModel();

    return {
      ...model,
      dateCreated: NebDateRange.createModel(),
      batchId: '',
      procedureCode: '',
    };
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (
      changedProperties.has('filterDefaults') &&
      this.filterDefaults.batchId &&
      this.formService
    ) {
      this.formService.apply('batchId', this.filterDefaults.batchId);
    }
  }

  createSelectors() {
    const { statusCodes, ...children } = super.createSelectors().children;

    return {
      children: {
        ...children,
        dateCreated: NebDateRange.createSelectors(),
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__formService = null;

    this.status = 'needs-attention';
    this.filterDefaults = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      clearBatchSearch: () => {
        this.formService.apply('batchId', '');
      },

      clearProcedureCodesSearch: () =>
        this.formService.apply('procedureCode', ''),

      apply: e => {
        const isButtonPress = !!e;
        const model = this.formService.build();

        const {
          dos: { from: dosFrom, to: dosTo },
          dateCreated: { from: dateCreatedFrom, to: dateCreatedTo },
          amount: { min: amountMin, max: amountMax },
          patientId,
          statuses,
          submissionMethods,
          procedureCode,
          invoice,
          batchId,
          claim,
          showHidden,
        } = model;

        this.onApply(
          {
            params: {
              ...(patientId && { patientId }),
              ...(dosFrom && {
                dosFrom: parseDate(dosFrom._i),
              }),
              ...(dosTo && {
                dosTo: parseDate(dosTo._i),
              }),
              ...(dateCreatedFrom && {
                dateCreatedFrom: parseDate(dateCreatedFrom._i),
              }),
              ...(dateCreatedTo && {
                dateCreatedTo: parseDate(dateCreatedTo._i),
              }),
              ...(amountMin && { amountMin }),
              ...(amountMax && { amountMax }),
              ...(statuses.length && { statuses }),
              ...(submissionMethods.length && { submissionMethods }),
              ...(invoice && { invoice }),
              ...(batchId && { batchId }),
              ...(claim && { claim }),
              showHidden,
            },
            headers: {
              ...(procedureCode && { procedureCode }),
              ...(model.payerIds.length && { payerids: model.payerIds }),
              ...(model.locationIds.length && {
                locationids: model.locationIds,
              }),
              ...(model.providerIds.length && {
                providerids: model.providerIds,
              }),
            },
          },
          isButtonPress,
        );
      },
    };
  }

  getProviderItems() {
    const providers = this.providers.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));

    return providers;
  }

  __getSortedStatusItems(statuses) {
    const unsortedStatuses = [
      ...statuses,
      {
        label: CLAIM_STATUS.CANCELED_REBILLED,
        data: { id: CLAIM_STATUS.CANCELED_REBILLED },
      },
      {
        label: CLAIM_STATUS.CANCELED_RESUBMITTED,
        data: { id: CLAIM_STATUS.CANCELED_RESUBMITTED },
      },
      {
        label: CLAIM_STATUS.CANCELED_VOIDED,
        data: { id: CLAIM_STATUS.CANCELED_VOIDED },
      },
    ];

    return unsortedStatuses.sort((a, b) => a.label.localeCompare(b.label));
  }

  renderCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.showHiddenCheckbox.id}"
        name="showHidden"
        class="${this.enablePatient ? 'checkbox' : 'checkbox-practice'}"
        label="Show Hidden Items"
        ?checked="${this.state.showHidden}"
        value="${this.state.showHidden}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  renderCurrencyFilter() {
    const balance =
      this.state && this.state.balance
        ? this.state.balance
        : NebCurrencyRange.createModel();

    const amount =
      this.state && this.state.amount
        ? this.state.amount
        : NebCurrencyRange.createModel();

    return this.status === CARDS.APPROVED.name
      ? html`
          <neb-range-currency
            id="${ELEMENTS.balanceRange.id}"
            name="balance"
            label="Balance"
            .model="${balance}"
            .onChange="${this.handlers.change}"
          ></neb-range-currency>
        `
      : html`
          <neb-range-currency
            id="${ELEMENTS.amountRange.id}"
            name="amount"
            label="Amount"
            .model="${amount}"
            .onChange="${this.handlers.change}"
          ></neb-range-currency>
        `;
  }

  renderContent() {
    const dosDates = {
      from:
        this.state.dos && this.state.dos.from
          ? parseDate(this.state.dos.from)
          : null,
      to:
        this.state.dos && this.state.dos.to
          ? parseDate(this.state.dos.to)
          : null,
    };

    const dateCreatedDates = {
      from:
        this.state.dateCreated && this.state.dateCreated.from
          ? parseDate(this.state.dateCreated.from)
          : null,
      to:
        this.state.dateCreated && this.state.dateCreated.to
          ? parseDate(this.state.dateCreated.to)
          : null,
    };
    return html`
      ${this.renderPatientFilter()}

      <neb-textfield
        id="${ELEMENTS.invoiceTextfield.id}"
        name="invoice"
        label="Invoice #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
        .value="${this.state.invoice}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
      ></neb-textfield>

      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dos"
        label="Date of Service"
        .model="${dosDates}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-select
        id="${ELEMENTS.statusesSelect.id}"
        name="statuses"
        label="Status"
        helper=" "
        .value="${this.state.statuses}"
        .items="${this.__getSortedStatusItems(ALL_STATUS_CONFIG.statuses)}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.claimTextfield.id}"
        name="claim"
        label="Claim #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.claim ? 'neb:clear' : ''}"
        .value="${this.state.claim}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearClaimSearch}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.submissionMethodsSelect.id}"
        name="submissionMethods"
        label="Submission Method"
        helper=" "
        .value="${this.state.submissionMethods}"
        .items="${SUBMISSION_METHODS}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.createdDateRange.id}"
        name="dateCreated"
        label="Date Created"
        .model="${dateCreatedDates}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.procedureCode.id}"
        name="procedureCode"
        label="Procedure Codes"
        helper=" "
        maxLength="9"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.procedureCode ? 'neb:clear' : ''}"
        .value="${this.state.procedureCode}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearProcedureCodesSearch}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.requestMorePayers}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        name="providerIds"
        label="Providers"
        helper=" "
        .value="${this.state.providerIds}"
        .items="${this.getProviderItems()}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      ${this.renderCurrencyFilter()}${this.renderLocationsFilter()}

      <neb-textfield
        id="${ELEMENTS.batchId.id}"
        name="batchId"
        label="Batch #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.batchId ? 'neb:clear' : ''}"
        .value="${this.state.batchId}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearBatchSearch}"
      ></neb-textfield>

      ${this.renderCheckbox()}
    `;
  }
}

window.customElements.define('neb-filters-all-claims', NebFiltersAllClaims);
