import '../../../../../../src/components/controllers/neb-payer-payment-controller';

import { html, css } from 'lit';

import { OVERLAY_WIDTH_LARGE } from '../../../../../neb-styles/neb-variables';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../neb-utils/neb-refresh';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

const getPaymentResult = paymentResult => {
  const { postPayment, voidPayment } = paymentResult;

  let paid = false;

  if (postPayment && postPayment.id && (!voidPayment || !voidPayment.id)) {
    paid = true;
  }

  return {
    paid,
    payment: postPayment || null,
    voidPayment: voidPayment || null,
  };
};

class NebOverlayAddPayerPayment extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: column nowrap;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .controller {
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.doNotDismissAll = false;
    this.__paymentResult = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismiss: () => {
        const paymentResult = getPaymentResult(this.__paymentResult);

        return this.dismiss(paymentResult);
      },
      dismissAll: () => {
        const paymentResult = getPaymentResult(this.__paymentResult);

        if (this.doNotDismissAll) {
          return this.dismiss(paymentResult);
        }
        return this.dismiss(paymentResult, true);
      },
      postPayment: () => sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]),
      voidPayment: () => sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]),
    };
  }

  dismissWithBlocker() {
    const paymentResult = getPaymentResult(this.__paymentResult);

    if (this.doNotDismissAll) {
      return this.dismiss(paymentResult);
    }
    return this.dismiss(paymentResult, true);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { doNotDismissAll } = this.model;

      this.doNotDismissAll = doNotDismissAll;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-payer-payment-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .layout="${this.layout}"
        .patientId="${this.model.patientId}"
        .postedPayment="${this.model.postedPayment}"
        .onPostPaymentAction="${this.handlers.postPayment}"
        .onVoidPaymentAction="${this.handlers.voidPayment}"
        .onDirtyChange="${this.handlers.dirty}"
        .onDismiss="${this.handlers.dismiss}"
        .onDismissAll="${this.handlers.dismissAll}"
      ></neb-payer-payment-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-add-payer-payment',
  NebOverlayAddPayerPayment,
);
