import { html, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { postConversion } from '../../../api-clients/conversions';

import '../../forms/conversions/neb-form-new-conversion';

export const ELEMENTS = {
  newConversionForm: { id: 'newConversionForm' },
};

class NebControllerNewConversion extends LitElement {
  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.onSave = () => {};

    this.onCancel = () => {};
  }

  initHandlers() {
    this.handlers = {
      cancel: () => this.__cancel(),
      save: model => this.__save(model),
    };
  }

  __cancel() {
    this.onCancel();
  }

  async __save(model) {
    try {
      const {
        data: [result],
      } = await postConversion(model);
      this.onSave({ ...result, active: true });
      store.dispatch(openSuccess('Conversion created successfully!'));
    } catch (err) {
      console.log(err);
      store.dispatch(
        openError('An error occurred when creating the Conversion'),
      );
    }
  }

  render() {
    return html`
      <neb-form-new-conversion
        id="${ELEMENTS.newConversionForm.id}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
      ></neb-form-new-conversion>
    `;
  }
}

customElements.define(
  'neb-controller-new-conversion',
  NebControllerNewConversion,
);
