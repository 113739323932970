import { resolveRoute } from '@neb/route-resolver';

import {
  API_URL,
  APPOINTMENTS_API_URL,
  BILLING_API_URL,
  IMAGE_API_URL,
  CHARTING_API_URL,
  CONVERSION_API_URL,
  CORE_API_URL,
  ELECTRONIC_CLAIMS_API_URL,
  EMAIL_API_URL,
  FILES_API_URL,
  PDF_API_URL,
  MACRO_API_URL,
  PERMISSIONS_API_URL,
  REGISTRY_API_URL,
  REPORTS_API_URL,
  PAYMENTS_API_URL,
  PARTNER_API_URL,
} from '../../../neb-utils/env';

import DeprecatedApiClient, { getTenantId } from './api-client-utils';

const MICROSERVICE_TO_URL_MAP = {
  api: API_URL,
  appointments: APPOINTMENTS_API_URL,
  billing: BILLING_API_URL,
  charting: CHARTING_API_URL,
  conversion: CONVERSION_API_URL,
  core: CORE_API_URL,
  'electronic-claims': ELECTRONIC_CLAIMS_API_URL,
  email: EMAIL_API_URL,
  files: FILES_API_URL,
  image: IMAGE_API_URL,
  partner: PARTNER_API_URL,
  payments: PAYMENTS_API_URL,
  pdf: PDF_API_URL,
  permissions: PERMISSIONS_API_URL,
  registry: REGISTRY_API_URL,
  reports: REPORTS_API_URL,
  macro: MACRO_API_URL,
};

/**
 * Attempt to improve upon the DeprecatedApiClient with the following changes to makeRequest:
 * - path is the fully qualified path with replacement placeholders in a microservice, i.e.
 * path: /api/v1/tenants/:tenantId/patients/:patientId
 *
 * - replacements is now used in order to enable replacing variables:
 * replacements: { patientId: '1234' }
 * tenantId is still replaced automatically
 *
 * - version is now ignored
 */
export default class ApiClientV2 extends DeprecatedApiClient {
  _resolveUrl({ path, replacements = {} }) {
    const BASE_MS_URL = MICROSERVICE_TO_URL_MAP[this.microservice];
    const fullReplacements = { ...replacements, tenantId: () => getTenantId() };

    return resolveRoute(`${BASE_MS_URL}${path}`, fullReplacements);
  }
}
