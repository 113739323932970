import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../src/components/misc/neb-icon';
import '../neb-text';
import '../neb-radio-button';

import { css, html } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_4,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { formatEncounterNumber } from '../../../../neb-utils/neb-encounters-util';

import NebTable from './neb-table';

export const ELEMENTS = {
  checkboxes: {
    selector: 'neb-checkbox',
  },
  radioButtons: {
    selector: 'neb-radio-button',
  },
  claimLinks: { selector: '[id^=claim-]' },
  encounterLinks: { selector: '[id^=encounter-]' },
  textBilled: { id: 'text-billed' },
  textHold: { id: 'text-hold' },
  chargeNumber: { selector: '[id^=chargeNumber-]' },
  associatedERAsAndEOBs: { selector: '[id^=era-eob-]' },
};

class NebTableLedgerChargeExpanded extends NebTable {
  static get properties() {
    return {
      __hasSignedEncounter: {
        type: Boolean,
        reflect: true,
        attribute: 'has-signed-encounter',
      },
      matchCharge: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
          padding-bottom: ${CSS_SPACING};
        }

        .text-billed-patient,
        .text-hold-claim,
        .text-encounter {
          width: max-content;
        }

        .row-header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding: 0;
        }

        .row {
          background-color: ${CSS_COLOR_GREY_4};
          font-size: 12px;
          border-bottom: none;
        }

        .row-data {
          min-height: 30px;
          padding: 0;
        }

        .cell-header {
          padding-bottom: 5px;
        }

        .cell-data {
          padding: 0;
        }

        .cell-checkbox {
          padding: 0 5px;
        }

        .cell-detail {
          cursor: pointer;
        }

        .cell-detail-spacer {
          width: 12px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__hasSignedEncounter = false;

    this.matchCharge = false;

    this.onSelectEncounter = () => {};

    this.onSelectClaim = () => {};

    this.onClickChargeNumber = () => {};

    this.onClickERAsEOBs = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),
      clickDetail: e => {
        const index = Number(e.currentTarget.getAttribute('row'));
        const item = this.model[index];
        this.onSelectRow(this.name, item, index);
        e.stopPropagation();
      },
      clickEncounter: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { encounterId, appointmentTypeId, patientId } =
          this.model[rowIndex];

        return this.onSelectEncounter({
          encounterId,
          appointmentTypeId,
          patientId,
        });
      },
      clickClaim: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { claimId } = this.model[rowIndex];

        return this.onSelectClaim({ claimId });
      },
      clickChargeNumber: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const lineItem = this.model[rowIndex];

        return this.onClickChargeNumber(lineItem);
      },
      clickERAsEOBs: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { associatedERAsAndEOBs, id } = this.model[rowIndex];

        return this.onClickERAsEOBs({ associatedERAsAndEOBs, lineItemId: id });
      },
    };
  }

  update(changedProps) {
    this.__hasSignedEncounter = this.model
      ? this.model.some(({ signed }) => signed)
      : false;

    super.update(changedProps);
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.matchCharge
          ? this.__renderRadioButtonCell(value, rowIndex)
          : this.__renderCheckboxCell(value, rowIndex);

      case 'claimNumber':
        return this.__renderClaimCell(value, rowIndex);

      case 'associatedERAsAndEOBs':
        return this.__renderERAsAndEOBsCell(value, rowIndex);

      case 'chargeNumber':
        return this.__renderChargeNumberCell(value, rowIndex);

      case 'billed': {
        return html`
          <neb-text id="${ELEMENTS.textBilled.id}" class="text-billed-patient"
            >${value}</neb-text
          >
        `;
      }

      case 'hold': {
        return html`
          <neb-text id="${ELEMENTS.textHold.id}" class="text-hold-claim"
            >${value}</neb-text
          >
        `;
      }

      case 'encounter':
        return this.__renderEncounterCell(value, rowIndex);

      case 'detail':
        return this.__renderDetailCell(
          this.model[rowIndex].invoiceId,
          rowIndex,
        );

      default:
        return value;
    }
  }

  __renderClaimCell(data, rowIndex) {
    return html`
      <neb-text
        id="claim-${rowIndex}"
        bold
        link
        .name="lineItems.${rowIndex}"
        .onClick="${this.handlers.clickClaim}"
        >${data}</neb-text
      >
    `;
  }

  __renderERAsAndEOBsCell(data, rowIndex) {
    return html`
      <neb-text
        id="era-eob-${rowIndex}"
        bold
        link
        .name="era-eob.${rowIndex}"
        .onClick="${this.handlers.clickERAsEOBs}"
        >${data && data.length ? '✓' : ''}</neb-text
      >
    `;
  }

  __renderChargeNumberCell(data, rowIndex) {
    return html`
      <neb-text
        id="chargeNumber-${rowIndex}"
        bold
        link
        .name="lineItems.${rowIndex}"
        .onClick="${this.handlers.clickChargeNumber}"
        >${data}</neb-text
      >
    `;
  }

  __renderEncounterCell(encounterNumber, rowIndex) {
    const { signed } = this.model[rowIndex];
    const encounter = { encounterNumber, signed };

    return html`
      <neb-text
        id="encounter-${rowIndex}"
        bold
        link
        class="text-encounter"
        .name="lineItems.${rowIndex}"
        .onClick="${this.handlers.clickEncounter}"
        >${formatEncounterNumber(encounter)}</neb-text
      >
    `;
  }

  __renderCheckboxCell(data, rowIndex) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        .name="${rowIndex}"
        .onChange="${this.handlers.change}"
        ?checked="${data}"
        @click="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }

  __renderRadioButtonCell(value, rowIndex) {
    return html`
      <neb-radio-button
        class="cell-checkbox"
        .name="${rowIndex}"
        .value="${value}"
        .checked="${value}"
        .onChange="${this.handlers.change}"
      >
      </neb-radio-button>
    `;
  }

  __renderDetailCell(data, rowIndex) {
    return !data || rowIndex === this.model.length - 1
      ? html`
          <neb-icon
            id="icon-detail-${rowIndex}"
            class="cell cell-detail"
            icon="neb:chevron"
            key="SPECIAL.detail"
            row="${rowIndex}"
            @click="${this.handlers.clickDetail}"
          ></neb-icon>
        `
      : html` <div class="cell cell-detail-spacer"></div> `;
  }
}

customElements.define(
  'neb-table-ledger-charge-expanded',
  NebTableLedgerChargeExpanded,
);
