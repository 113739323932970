import { css, html, LitElement } from 'lit';

import { phone } from '../../../../packages/neb-utils/masks';
import { baseStyles, layoutStyles, CSS_SPACING } from '../../../styles';
import '../inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';

export const ELEMENTS = {
  tabContentSettings: { id: 'tab-content-settings' },
  submitterContactCheckbox: { id: 'submitter-contact-checkbox' },
  submitterContactEmail: { id: 'submitter-contact-email' },
  submitterContactName: { id: 'submitter-contact-name' },
  submitterContactNumber: { id: 'submitter-contact-number' },
  submitterContactType: { id: 'submitter-contact-type' },
  submitterContactTooltip: { id: 'submitter-contact-tool-tip' },
};

const CONTACT_INFORMATION_TYPES = ['', 'Phone', 'Fax'];

class ClearinghouseSettings extends LitElement {
  static get properties() {
    return {
      errors: Object,
      model: Object,
      isCHC: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .grid-gap {
          grid-gap: ${CSS_SPACING} ${CSS_SPACING};
        }

        .tooltip {
          padding-top: 15px;
        }

        .grid-two {
          grid-template-columns: 2fr 1fr;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.errors = {
      submitterContactEmail: '',
      submitterContactName: '',
      submitterContactNumber: '',
      submitterContactOverride: '',
      submitterContactType: '',
    };

    this.model = {
      submitterContactEmail: '',
      submitterContactName: '',
      submitterContactNumber: '',
      submitterContactOverride: false,
      submitterContactType: '',
    };

    this.isCHC = false;

    this.onChange = () => {};

    this.onChangeContactInformation = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),
      changeContactInformation: e => this.onChangeContactInformation(e),
    };
  }

  __renderContactEmail() {
    return this.isCHC
      ? html`
          <neb-textfield
            id="${ELEMENTS.submitterContactEmail.id}"
            name="submitterContactEmail"
            label="Email"
            .value="${this.model.submitterContactEmail}"
            .error="${this.errors.submitterContactEmail}"
            .helper="${' '}"
            .onChange="${this.__handlers.changeContactInformation}"
          ></neb-textfield>
        `
      : html``;
  }

  render() {
    return html`
      <div id="${ELEMENTS.tabContentSettings.id}" class="grid grid-lean">
        <neb-header label="Contact Information Override"> </neb-header>

        <div class="grid grid-gap">
          <div class="grid grid-lean grid-two">
            <neb-textfield
              id="${ELEMENTS.submitterContactName.id}"
              name="submitterContactName"
              label="Contact Name"
              maxLength="60"
              .value="${this.model.submitterContactName}"
              .error="${this.errors.submitterContactName}"
              .onChange="${this.__handlers.changeContactInformation}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.submitterContactTooltip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div slot="tooltip">
                All fields included here will override contact information
              </div>
            </neb-tooltip>
          </div>

          <div class="grid grid-lean grid-two">
            <neb-textfield
              id="${ELEMENTS.submitterContactNumber.id}"
              class="field"
              maxLength="14"
              name="submitterContactNumber"
              label="Contact Number"
              .inputMode="${'numeric'}"
              .mask="${phone}"
              .value="${this.model.submitterContactNumber}"
              .error="${this.errors.submitterContactNumber}"
              .helper="${' '}"
              .onChange="${this.__handlers.changeContactInformation}"
            ></neb-textfield>

            <neb-select
              id="${ELEMENTS.submitterContactType.id}"
              class="field field-selector"
              name="submitterContactType"
              label="Type"
              .items="${CONTACT_INFORMATION_TYPES}"
              .value="${this.model.submitterContactType}"
              .error="${this.errors.submitterContactType}"
              .helper="${' '}"
              .onChange="${this.__handlers.changeContactInformation}"
              ?disabled="${!this.isCHC}"
            ></neb-select>
          </div>

          ${this.__renderContactEmail()}

          <neb-checkbox
            id="${ELEMENTS.submitterContactCheckbox.id}"
            name="submitterContactOverride"
            label="Do not output Submitter Contact Name when it is the same as Organization Name"
            .checked="${this.model.submitterContactOverride}"
            .error="${this.errors.submitterContactOverride}"
            .onChange="${this.__handlers.change}"
          ></neb-checkbox>
        </div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-clearinghouse-settings',
  ClearinghouseSettings,
);
