import { openPopup } from '@neb/popup';

import { getMany } from '../../../../src/api-clients/charges';
import { fetchOne } from '../../../../src/api-clients/fee-schedules';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import {
  getFeeSchedule,
  mapSettingsCharges,
} from '../../../neb-utils/fee-schedule';
import { capitalize } from '../../../neb-utils/formatters';
import { formatLineItemBase } from '../formatters/line-item-details';
import { getBillingEncounterCharges } from '../ledger/encounter-charges';
import { getLineItemDetails } from '../ledger/line-items';
import { getPayerPlans } from '../payer-plan-api-client';

export const buildModel = (fieldNames = ['charges', 'diagnoses']) =>
  fieldNames.reduce((memo, field) => ({ ...memo, [field]: [] }), {});

export const mapAddedChargeToEncounterCharge = ({
  charges,
  diagnosisPointers = [],
  autoPost: posted = false,
}) =>
  charges.map(charge => ({
    chargeId: charge.chargeId || charge.id,
    procedure: charge.procedure,
    description: charge.description,
    modifiers: charge.modifiers,
    units: charge.units || '1',
    taxName: charge.taxName || '',
    taxRate: charge.taxRate || 0,
    diagnosisPointers,
    unitCharge: charge.amount,
    feeScheduleName: charge.feeScheduleName,
    feeScheduleCharge: charge.feeScheduleCharge,
    allowedAmount: charge.allowedAmount,
    EPSDTCode: charge.EPSDTCode,
    suppressFromClaim: charge.suppressFromClaim,
    billWithTreatmentInitiationDate: charge.billWithTreatmentInitiationDate,
    billWithXrayDate: charge.billWithXrayDate,
    posted,
    repost: false,
    postedToLedgerId: null,
    nationalDrugCodeEnabled: charge.nationalDrugCodeEnabled,
    nationalDrugCodeQualifier: charge.nationalDrugCodeQualifier,
    nationalDrugCode: charge.nationalDrugCode,
    nationalDrugCodeDosage: charge.nationalDrugCodeDosage,
    nationalDrugCodeUnitOfMeasurement: charge.nationalDrugCodeUnitOfMeasurement,
    nationalDrugCodeNumberCategory: charge.nationalDrugCodeNumberCategory,
    nationalDrugCodeSequenceOrPrescription:
      charge.nationalDrugCodeSequenceOrPrescription,
  }));

export const openRemovePopup = (item = 'charge') =>
  openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: `Remove ${capitalize(item)}`,
    message: `Are you sure you want to remove this ${item}?`,
    confirmText: 'Yes',
    cancelText: 'No',
  });

export const getUpdatedBilledAmount = ({
  name,
  value,
  charges,
  formField = 'charges',
}) => {
  const path = name.split('.');
  const key = path[2];
  if (key !== 'units' || !value) return {};

  const index = path[1];
  const billedAmountKey = `${formField}.${index}.billedAmount`;

  const units = parseInt(value, 10);
  const { feeScheduleName, feeScheduleCharge, unitCharge } = charges[index];
  const billedAmountValue =
    units * (feeScheduleName ? feeScheduleCharge : unitCharge);

  return {
    billedAmountKey,
    billedAmountValue,
  };
};

export const getEncounterCharges = async (charges, encounterId) => {
  const postedCharges = charges.filter(
    ({ postedToLedgerId }) => postedToLedgerId,
  );
  if (!postedCharges.length || !encounterId) return [];
  const encounterCharges = await getBillingEncounterCharges(encounterId);
  const lineItemIds = encounterCharges.map(({ lineItemId }) => lineItemId);

  const [{ payerPlan: payerPlans }, lineItems] = await Promise.all([
    getPayerPlans({}, undefined, true),
    getLineItemDetails({}, { lineItemIds }),
  ]);

  return encounterCharges.reduce((memo, { id, lineItemId }) => {
    const lineItem = lineItems.find(li => li.id === lineItemId);
    if (!lineItem) return memo;
    return [
      ...memo,
      {
        id,
        lineItem: formatLineItemBase(lineItem, [], payerPlans),
      },
    ];
  }, []);
};

export const getSettingCharges = async (
  patientId,
  appointmentId,
  opts = {},
) => {
  let feeSchedule;

  if (opts.useFeeSchedule) {
    feeSchedule = opts.feeScheduleId
      ? await fetchOne(opts.feeScheduleId)
      : null;
  } else {
    feeSchedule = await getFeeSchedule(patientId, appointmentId);
  }

  return mapSettingsCharges(
    (await getMany())
      .filter(c => c.active)
      .map(c => ({
        ...c,
        code: c.procedure,
        allowedAmount: null,
      })),
    feeSchedule,
  );
};
