import { getProvidersPublic } from '../neb-api-client/src/permissions-api-client';
import { getProviderUsers } from '../neb-api-client/src/practice-users-api-client';

const PROVIDER_FETCHING = 'PROVIDER_FETCHING';
const PROVIDER_FETCHED = 'PROVIDER_FETCHED';
const PROVIDER_FETCH_FAILED = 'PROVIDER_FETCH_FAILED';

/*
 * Provides sorting functionality.
 * @param items - list of items to sort.
 * @param [toSortKey] - the key to sort by.
 */
function sort(items, toSortKey = 'lastName') {
  if (items && Array.isArray(items) && typeof toSortKey === 'string') {
    items.sort((a, b) => {
      const aKey = a[toSortKey];
      const bKey = b[toSortKey];
      if (aKey == null) return -1;
      if (bKey == null) return 1;
      return String(aKey).localeCompare(String(bKey));
    });
  }
}

export const providersReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    item: [],
    message: undefined,
  },
  action,
) => {
  switch (action.type) {
    case PROVIDER_FETCHING:
      return { ...state, isFetching: true, isFetched: false };

    case PROVIDER_FETCHED: {
      const newState = {
        ...state,
        isFetching: false,
        isFetched: true,
        item: action.value,
      }; // See function comment

      sort(newState.item);
      return newState;
    }

    case PROVIDER_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        message: action.message,
      };

    default:
      return state;
  }
};

export const fetchProviders = tenantId => async dispatch => {
  dispatch({
    type: PROVIDER_FETCHING,
  });

  try {
    const providers = await getProvidersPublic(tenantId);
    dispatch({
      type: PROVIDER_FETCHED,
      value: providers,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_FETCH_FAILED,
      message: 'An error occurred.',
    });
  }
};

export const authFetchProviders = () => async dispatch => {
  dispatch({
    type: PROVIDER_FETCHING,
  });

  try {
    const users = await getProviderUsers(true);

    if (users.length) {
      dispatch({
        type: PROVIDER_FETCHED,
        value: users,
      });
    } else {
      dispatch({
        type: PROVIDER_FETCH_FAILED,
        message: 'Failed',
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_FETCH_FAILED,
      message: 'An error occurred.',
    });
  }
};
