import { getPartnerJwt } from '../api-clients/partnerships/partner-sso-api-client';

const { NEB_ENV: { DOCTIBLE_API_URL } = {} } = window;

export const DOCTIBLE_LINKS = {
  locationSettings: '/providers/location_settings',
  appointments: '/providers/appointments/settings',
  campaigns: '/providers/campaigns/settings',
  patientRecall: '/providers/recall/settings',
  reviewManagement: '/providers/review_management/settings',
  ctInforms: '/providers/intake_sessions/new',
};

export const openDoctibleSsoLink = async (redirectTo = '') => {
  const newWindow = window.open();
  const jwt = await getPartnerJwt();

  newWindow.location = `${DOCTIBLE_API_URL}/sso?jwt=${
    jwt.data[0]
  }&redirect_to=${redirectTo}`;
};
