import '../neb-popup-header';
import '../tables/neb-table-batches';
import '../neb-pagination';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  REMOVE_READY_TO_GENERATE_BATCH_MESSAGE,
  GENERATE_BATCH_FAILED_MESSAGE,
  ERROR_EXPORTING_STATEMENT_BATCH_CSV_MESSAGE,
} from '../../../../../src/utils/user-message';
import { generateStatementBatch } from '../../../../neb-api-client/src/billing/statement/statement-batches-api-client';
import getStatementBatchCSV from '../../../../neb-api-client/src/services/statement/get-statement-batches-csv';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
  OVERLAY_WIDTH_LARGE,
} from '../../../../neb-styles/neb-variables';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  table: {
    id: 'table',
  },
  pagination: {
    id: 'pagination',
  },
};
export const REMOVE_BATCH_MESSAGE = {
  message: html`
    <p>
      Removing the failed batch will remove the batch entry from within this
      section. Failed batches will need to be re-processed.
    </p>
    <p>Are you sure you want to remove the failed batch?</p>
  `,
  title: 'Remove Failed Batch',
};

const POLLING_DURATION = 5000;
class NebOverlayViewBatches extends Overlay {
  static get properties() {
    return {
      __batchState: Object,
      __generatingBatchId: {
        type: Number,
        reflect: true,
      },
    };
  }

  initState() {
    super.initState();

    this.__pollingHandle = null;
    this.__batchState = CollectionService.createModel();
    this.__generatingBatchId = null;

    this.__batchService = new CollectionService(
      {
        onChange: state => {
          this.__batchState = state;
        },
      },
      {
        hideInactive: false,
      },
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      print: batchId => printPdf(this.model.fetchBatch(batchId)),
      delete: async id => {
        const { failed } = this.__batchState.pageItems.find(
          ({ batchId }) => batchId === id,
        );

        const accepted = await this.__callDeletePopup(failed);

        if (accepted) {
          await this.model.deleteBatch(id);
          await this.__syncItems();
        }
      },
      setPage: index => this.__batchService.setPageIndex(index),
      export: id => this.downloadCsv(id),
      preview: statement =>
        openOverlay(OVERLAY_KEYS.PREVIEW_STATEMENT_BATCH, statement),
      generate: async batchId => {
        this.__generatingBatchId = batchId;

        try {
          await generateStatementBatch(batchId);
          this.__batchState.pageItems.find(x => x.batchId === batchId).status =
            'Completed';
        } catch (error) {
          await store.dispatch(openError(GENERATE_BATCH_FAILED_MESSAGE));
        }
        this.__generatingBatchId = null;
      },
    };
  }

  async downloadCsv(id) {
    try {
      await getStatementBatchCSV(id);
    } catch (error) {
      store.dispatch(openError(ERROR_EXPORTING_STATEMENT_BATCH_CSV_MESSAGE));
    }
  }

  async connectedCallback() {
    super.connectedCallback();

    await this.__syncItems();

    this.__pollingHandle = setInterval(
      () => this.__syncItems(),
      POLLING_DURATION,
    );
  }

  disconnectedCallback() {
    clearInterval(this.__pollingHandle);
    this.__pollingHandle = null;

    super.disconnectedCallback();
  }

  __callDeleteReadyToGeneratePopup() {
    return openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      REMOVE_READY_TO_GENERATE_BATCH_MESSAGE,
    );
  }

  __callDeleteFailedPopup() {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, REMOVE_BATCH_MESSAGE);
  }

  __callDeletePopup(failed) {
    return failed
      ? this.__callDeleteFailedPopup()
      : this.__callDeleteReadyToGeneratePopup();
  }

  async __syncItems() {
    if (!this.model || !this.model.fetchBatches) return;

    const items = await this.model.fetchBatches();

    const index = this.__batchState.pageIndex;

    this.__batchService.setItems(items);

    this.__batchService.setPageIndex(index);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: ${OVERLAY_WIDTH_LARGE};
          flex-flow: column nowrap;
          overflow-y: auto;
        }

        :host .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .description {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .row {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .row-margins {
          margin-left: ${CSS_SPACING};
          margin-right: ${CSS_SPACING};
        }

        .cell-spacer {
          flex: 1 0 0;
        }
      `,
    ];
  }

  __renderPagination() {
    return this.__batchState.pageCount > 1
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                .currentPage="${this.__batchState.pageIndex}"
                .pageCount="${this.__batchState.pageCount}"
                .onPageChanged="${this.handlers.setPage}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="View Batches"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showBackButton
        showCancelButton
      ></neb-popup-header>

      <div class="description">
        View batch status and download completed items.
      </div>

      <neb-table-batches
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .model="${this.__batchState.pageItems}"
        .onPrint="${this.handlers.print}"
        .onDelete="${this.handlers.delete}"
        .onExport="${this.handlers.export}"
        .onPreview="${this.handlers.preview}"
        .onGenerate="${this.handlers.generate}"
        .generatingBatchId="${this.__generatingBatchId}"
      ></neb-table-batches>

      ${this.__renderPagination()}
    `;
  }
}

customElements.define('neb-overlay-view-batches', NebOverlayViewBatches);
