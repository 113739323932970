import { html, css } from 'lit';

import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { CSS_FONT_FAMILY } from '../../../../packages/neb-styles/neb-variables';
import { POPUP_UPDATE_PAYMENT_HELPER } from '../../../utils/user-message';

import '../../forms/ledger/neb-form-era-payment';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
  textHelper: { id: 'text-helper' },
};

class NebPopupUpdatePayment extends NebPopup {
  static get properties() {
    return {
      model: Object,
    };
  }

  initState() {
    super.initState();

    this.title = 'Update ERA Amount';
    this.model = { amount: 0, upperBound: 0, lowerBound: 0 };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: model => this.onClose({ amount: model.amount, warn: model.warn }),
      close: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }
        .content {
          flex: unset;
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.textHelper.id}">${POPUP_UPDATE_PAYMENT_HELPER()}</div>
      <neb-form-era-payment
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .onCancel="${this.handlers.close}"
        .onSave="${this.handlers.save}"
      ></neb-form-era-payment>
    `;
  }
}

customElements.define('neb-popup-update-payment', NebPopupUpdatePayment);
