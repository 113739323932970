import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../misc/neb-view-associated-rule-sets';
import '../forms/neb-form-payment';
import { html, css } from 'lit';

import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../packages/neb-styles/neb-variables';
import { getAssociatedRuleSets } from '../../api-clients/claim-scrubbing';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  header: {
    id: 'header',
  },
  view: {
    id: 'view',
  },
};

class NebOverlayAssociatedRuleSets extends Overlay {
  static get properties() {
    return {
      __ruleSets: Array,
      __names: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: column nowrap;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .description {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__ruleSets = [];
    this.__names = [];

    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      refetch: () => this.__fetchRuleSets(),
    };
  }

  async __fetchRuleSets() {
    const { ruleSets, names } = await getAssociatedRuleSets(this.model.id);

    this.__ruleSets = ruleSets;
    this.__names = names;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.id) {
        await this.__fetchRuleSets();
      }
    }

    super.updated(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Associated Rule Sets from Claim Scrubbing Errors"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <div id="${ELEMENTS.description.id}" class="description">
        View and manage rule sets that caused this claim to fail
      </div>

      <neb-view-associated-rule-sets
        id="${ELEMENTS.view.id}"
        class="view"
        .layout="${this.layout}"
        .model="${this.__ruleSets}"
        .names="${this.__names}"
        .onUpdate="${this.handlers.refetch}"
      ></neb-view-associated-rule-sets>
    `;
  }
}

customElements.define(
  'neb-overlay-associated-rule-sets',
  NebOverlayAssociatedRuleSets,
);
