import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL } from '../../../../neb-utils/patientAuthorization';

const __checkProceduresAuthorization = (authorizedProcedures, charges) =>
  authorizedProcedures.length
    ? charges.every(item =>
        authorizedProcedures.find(procedure => procedure === item.procedure),
      )
    : false;

export default (authorizedProcedures, charges) => {
  const proceduresAuthorized = __checkProceduresAuthorization(
    authorizedProcedures,
    charges,
  );
  if (proceduresAuthorized) return true;

  return openPopup(
    POPUP_RENDER_KEYS.CONFIRM,
    PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL,
  );
};
