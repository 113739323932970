import { css, html } from 'lit';

import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-form-controller';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  patientController: { id: 'patient-controller' },
};

class NebOverlayClaimErrorPatientInfo extends NebOverlayClaimError {
  static get properties() {
    return {
      __patientModel: Object,
    };
  }

  initState() {
    super.initState();

    this.__patientModel = {};

    this.widthRatio = '50/50';
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .controller {
          flex: 1 0 0;
        }
      `,
    ];
  }

  async connectedCallback() {
    this.__patientModel = await fetchOne(this.model.patient.id);
    super.connectedCallback();
  }

  renderForm() {
    return html`
      <neb-patient-form-controller
        id="${ELEMENTS.patientController.id}"
        class="controller"
        .patient="${this.__patientModel}"
        .onSave="${this.__handlers.dismiss}"
        .onCancel="${this.__handlers.dismiss}"
        .onChangeDirty="${this.__handlers.dirty}"
        .restrictedView="${true}"
      >
      </neb-patient-form-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-patient-info',
  NebOverlayClaimErrorPatientInfo,
);
