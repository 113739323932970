import './neb-patient-list-item';
import '../neb-button-actions';
import '../controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { store } from '../../../../neb-redux/neb-redux-store';
import { navigate } from '../../../../neb-route/neb-route-state';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
  CSS_FIELD_MARGIN,
} from '../../../../neb-styles/neb-variables';
import { NebScrollThreshold } from '../../services/neb-scroll-threshold';

export const ID_ITEM_PREFIX = 'item-';
export const ELEMENTS = {
  header: {
    id: 'header',
  },
  list: {
    id: 'list',
  },
  listItems: {
    selector: 'neb-patient-list-item',
  },
  noPatientsLabel: {
    id: 'label-patients',
  },
  addButton: {
    id: 'button-add',
  },
  actionButton: {
    id: 'button-action',
  },
  toolbar: {
    id: 'toolbar',
  },
};

class PatientList extends LitElement {
  static get properties() {
    return {
      __toggledIndex: Number,
      hideInactive: Boolean,
      scrollThreshold: Number,
      selectedId: String,
      items: Array,
      hideToolbar: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      isRecentPatientList: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__fetching = false;
    this.__scrollService = null;
    this.hideToolbar = false;
    this.hideInactive = false;
    this.scrollThreshold = 320;
    this.selectedId = '';
    this.layout = '';
    this.items = [];
    this.isRecentPatientList = false;

    this.onAdd = () => {};

    this.onSelect = () => {};

    this.onRequest = () => {};

    this.onUploadRoster = () => {};

    this.onDownloadRoster = () => {};

    this.onToggleInactive = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      uploadRoster: () => this.onUploadRoster(),
      downloadRoster: () => this.onDownloadRoster(),
      toggleInactive: () => this.onToggleInactive(!this.hideInactive),
      add: () => this.onAdd(),
      select: item => this.onSelect(item),

      toggle: item => {
        const index = this.items.findIndex(patient => patient.id === item.id);

        if (this.__toggledIndex === index) {
          this.__toggledIndex = index === -1 ? this.__toggledIndex : -1;
        } else {
          this.__toggledIndex = index;
        }
      },

      action: (item, index) => {
        const root = `#/patients/${item.id}`;
        const isMobile = this.layout === 'small';

        switch (index) {
          case 0:
            if (isMobile) return store.dispatch(navigate(`${root}`));

            return store.dispatch(navigate(`${root}/overview/profile`));

          case 1:
            return store.dispatch(navigate(`${root}/overview/appointments`));

          case 2:
            return store.dispatch(navigate(`${root}/clinical/encounters`));

          case 3:
            return store.dispatch(navigate(`${root}/ledger/activity`));

          default:
            return undefined;
        }
      },
    };
  }

  __updateMenuItems() {
    const menuItemsMobile = [
      {
        icon: `neb:${this.hideInactive ? 'visible' : 'visibilityOff'}`,
        label: `${this.hideInactive ? 'Show' : 'Hide'} Inactive`,
        onSelect: this.__handlers.toggleInactive,
      },
    ];

    this.__menuItems =
      this.layout === 'small'
        ? menuItemsMobile
        : [
            ...menuItemsMobile,
            {
              icon: 'neb:uploadNew',
              label: 'Upload Patient Roster',
              onSelect: this.__handlers.uploadRoster,
            },
            {
              icon: 'neb:download',
              label: 'Download Patient Roster',
              onSelect: this.__handlers.downloadRoster,
            },
          ];
  }

  update(changedProps) {
    if (changedProps.has('layout') || changedProps.has('hideInactive')) {
      this.__updateMenuItems();
    }

    if (changedProps.has('items')) {
      this.__fetching = false;
    }

    super.update(changedProps);
  }

  firstUpdated() {
    const listElem = this.shadowRoot.getElementById(ELEMENTS.list.id);
    this.__scrollService = new NebScrollThreshold(listElem, {
      lowerThreshold: this.scrollThreshold,
      onLowerThreshold: () => {
        if (!this.isRecentPatientList && !this.__fetching) {
          this.onRequest();
          this.__fetching = true;
        }
      },
    });
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          height: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container {
          display: flex;
          height: 100%;
          flex-flow: column nowrap;
        }

        .container-list {
          display: flex;
          overflow-y: auto;
          min-height: 0;
          flex: 1 0 0;
          flex-flow: column nowrap;
        }

        .container-label {
          display: flex;
          align-items: center;
          flex: 1 0 0;
        }

        .toolbar {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
        }

        .button-add {
          margin-left: ${CSS_SPACING};
        }

        .button-ellipsis {
          height: 16px;
          z-index: 1;
        }

        :host([layout='small']) .button-ellipsis {
          margin-right: 10px;
        }

        .label {
          margin-top: 160px;
          font-size: ${CSS_FONT_SIZE_HEADER};
          text-align: center;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .header {
          text-align: center;
          margin-top: ${CSS_FIELD_MARGIN};
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  renderToolbar() {
    return !this.hideToolbar
      ? html`
          <div id="${ELEMENTS.toolbar.id}" class="toolbar">
            <neb-button-action
              id="${ELEMENTS.addButton.id}"
              tabindex="0"
              class="button-add"
              label="Add New Patient"
              .onClick="${this.__handlers.add}"
            ></neb-button-action>

            ${this.renderActionButtons()}
          </div>
        `
      : '';
  }

  renderRecentPatientListHeader() {
    return this.isRecentPatientList
      ? html` <p id="${ELEMENTS.header.id}" class="header">Recent Patients</p> `
      : '';
  }

  renderActionButtons() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.actionButton.id}"
        tabindex="1"
        class="button-ellipsis"
        align="right"
        .value="${this.__menuItems}"
      ></neb-button-actions>
    `;
  }

  renderItems() {
    return this.items.length
      ? this.items.map(
          (item, index) => html`
            <neb-patient-list-item
              id="${ID_ITEM_PREFIX}${index}"
              class="item"
              tabindex="0"
              .model="${item}"
              .onClick="${this.__handlers.select}"
              .onToggle="${this.__handlers.toggle}"
              .onAction="${this.__handlers.action}"
              ?selected="${this.selectedId && this.selectedId === item.id}"
              ?expanded="${this.__toggledIndex === index}"
            ></neb-patient-list-item>
          `,
        )
      : html`
          <p id="${ELEMENTS.noPatientsLabel.id}" class="label">
            No patients found
          </p>
        `;
  }

  render() {
    return html`
      <div class="container">
        ${this.renderToolbar()} ${this.renderRecentPatientListHeader()}
        <div id="${ELEMENTS.list.id}" class="container-list">
          ${this.renderItems()}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-patient-list', PatientList);
