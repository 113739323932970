import { parseDate } from '../../packages/neb-utils/date-util';

const validNumericalvalue = value => {
  if (value && Number.isInteger(value)) return value;
  return 0;
};

const ordinalSuffix = value => {
  const tenth = value % 10;
  const hundredth = value % 100;

  if (tenth === 1 && hundredth !== 11) {
    return 'st';
  }

  if (tenth === 2 && hundredth !== 12) {
    return 'nd';
  }

  if (tenth === 3 && hundredth !== 13) {
    return 'rd';
  }

  return 'th';
};

const formatOccurrenceText = ({
  initialVisitDate,
  type,
  numOfDays,
  numOfVisits,
}) => {
  const dateStr = initialVisitDate
    ? parseDate(initialVisitDate).format('dddd, MMMM DD, YYYY')
    : '';

  switch (validNumericalvalue(type)) {
    case 0: {
      return `Always starting on ${dateStr}.`;
    }

    case 1: {
      return `On the next visit on or after ${dateStr}.`;
    }

    case 2: {
      return `Starting on the ${validNumericalvalue(
        numOfVisits,
      )}${ordinalSuffix(numOfVisits)} visit on or after ${dateStr}.`;
    }

    case 3: {
      return `Every ${validNumericalvalue(numOfVisits)}${ordinalSuffix(
        numOfVisits,
      )} visit on or after ${dateStr}.`;
    }

    case 4: {
      return `Every 1st visit occurring ${validNumericalvalue(
        numOfDays,
      )} days on or after ${dateStr}.`;
    }

    default: {
      throw new Error('Invalid type');
    }
  }
};
export { formatOccurrenceText };
