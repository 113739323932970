import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textMessage: {
    id: 'text-message',
  },
};

export default class NebPopupConfirm extends NebPopup {
  initState() {
    super.initState();
    this.model = {
      title: '',
      message: '',
      confirmText: '',
      cancelText: '',
    };

    this.dynamic = true;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () => this.onClose(true),
      cancel: () => this.onClose(false),
    };
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 450px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        .message {
          flex: 1 0 0;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.model.message}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            .label="${this.model.confirmText || 'OK'}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            .label="${this.model.cancelText || 'CANCEL'}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-confirm', NebPopupConfirm);
