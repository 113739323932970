import * as actions from '../../neb-redux/actions/action-creator';

const generateUrl = (patientId, suffix) =>
  `patients/${patientId}/treatment-plans${suffix ? `/${suffix}` : ''}`;

export const getCompletedTreatmentPlans = patientId =>
  actions.createFetchItemsAction(
    'treatmentPlans/completed',
    generateUrl(patientId),
    {
      status: 'Completed',
    },
  );

export const createTreatmentPlan = (patientId, treatmentPlan) =>
  actions.createCreateItemsAction({
    name: 'treatmentPlans',
    url: generateUrl(patientId),
    item: treatmentPlan,
  });

export const updateTreatmentPlan = (patientId, treatmentPlan) =>
  actions.createUpdateItemsAction({
    name: 'treatmentPlans',
    url: generateUrl(patientId, treatmentPlan.id),
    item: treatmentPlan,
  });

export const getActiveTreatmentPlan = (
  patientId,
  optOutLoadingIndicator = false,
) =>
  actions.createFetchSingleAction(
    'treatmentPlans',
    patientId,
    generateUrl(patientId),
    undefined,
    false,
    optOutLoadingIndicator,
  );
