import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { html, css } from 'lit';

import '../../misc/neb-icon';
import NebTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_COLOR_YELLOW } from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  actionButtons: { selector: 'neb-button-actions' },
  popoverContainer: {
    id: 'popover-container',
  },
  popover: {
    id: 'popover',
  },
  tooltip: {
    id: 'tooltip',
  },
};

const toolTipText =
  'Automated logout has been disabled for this user. We strongly recommend leaving this setting enabled based on HIPAA regulations.';

class NebTableUserMaintenance extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        .button-action {
          height: auto;
          --menu-min-width: var(--action-menu-min-width);
        }

        :host {
          --action-menu-min-width: 210px;
        }

        .popover-text {
          overflow-wrap: break-word;
          max-width: 300px;
          height: 100%;
          white-space: normal;
        }

        .icon-warn-container {
          display: flex;
          flex-direction: row;
        }

        .tooltip-icon {
          display: flex;
          flex: 0 0 30px;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const row = this.model[rowIndex];

    if (columnConfig.key === 'actionMenu') {
      return this.__renderActionButton(row);
    }

    if (row.inactiveLogout === false && columnConfig.key === 'name') {
      return this.__renderAutoLogoutWarningIcon({
        rowIndex,
        row,
        value,
        columnConfig,
      });
    }
    return super.renderDataCell(value, columnConfig);
  }

  __renderActionButton(row) {
    return html`
      <neb-button-actions
        tabindex="1"
        class="cell button-action"
        .value="${this.getTrailingActions(row)}"
        vertical
      ></neb-button-actions>
    `;
  }

  __renderAutoLogoutWarningIcon({ value, columnConfig }) {
    return html`
      <div class="icon-warn-container">
        <div class="tooltip-icon">
          <neb-tooltip
            id="${ELEMENTS.popover.id}"
            class="icon-warn"
            icon="neb:warning"
            iconColor="${CSS_COLOR_YELLOW}"
            defaultAnchor="right"
            large="${true}"
            ><div
              slot="tooltip"
              id="${ELEMENTS.tooltip.id}"
              class="popover-text"
            >
              ${toolTipText}
            </div></neb-tooltip
          >
        </div>
        <neb-text class="text" ?truncate="${columnConfig.truncate}"
          >${value}</neb-text
        >
      </div>
    `;
  }
}
customElements.define('neb-table-user-maintenance', NebTableUserMaintenance);
