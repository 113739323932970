import '../inputs/neb-select';
import '../inputs/neb-textfield';

import { isPostalCode, isRequired } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import DebounceService from '../../../../neb-utils/debouncer';
import { STATES } from '../../../../neb-utils/enums';
import {
  loadApi as loadMapsApi,
  fetchSuggestions,
  fetchPlace,
} from '../../../../neb-utils/geo-map';
import { zipCode } from '../../../../neb-utils/masks';
import { required, isZipCode } from '../../../../neb-utils/validators';

export const ELEMENTS = {
  address1Field: {
    id: 'textfield-address1',
  },
  address2Field: {
    id: 'textfield-address2',
  },
  cityField: {
    id: 'textfield-city',
  },
  stateField: {
    id: 'select-state',
  },
  zipcodeField: {
    id: 'textfield-zip',
  },
};

const NO_ADDRESS_FOUND = 'Address not found';

export default class Address extends LitElement {
  static get properties() {
    return {
      __searchResults: Array,

      name: String,
      model: Object,
      errors: Object,
      helpers: Object,
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: repeat(2, 1fr);
        }

        .field {
          width: 100%;
        }

        .field-col-2 {
          grid-column: 1 / span 2;
        }
      `,
    ];
  }

  static createModel() {
    return {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
    };
  }

  static createSelectors(keys) {
    const children = keys.reduce(
      (accum, curr) => ({
        ...accum,
        [curr]: [isRequired()],
      }),
      {},
    );

    children.zipcode = [
      ...(children.zipcode || []),
      isPostalCode('US', '#####(-####)'),
    ];

    return { children };
  }

  static genValidators(keys = []) {
    const validators = keys.reduce(
      (accum, curr) => ({ ...accum, [curr]: [required()] }),
      {},
    );
    const zipCodeValidators = validators.zipcode || [];
    validators.zipcode = [...zipCodeValidators, isZipCode];

    return validators;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__itemSelected = false;
    this.__searchResults = [];

    this.disabled = false;
    this.name = '';
    this.model = this.constructor.createModel();
    this.errors = this.constructor.createModel();
    this.helpers = Object.entries(this.errors)
      .map(kvp => [kvp[0], ' '])
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }));

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => {
        this.__itemSelected = false;

        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });

        this.__debounceService.debounce();
      },
      select: async e => {
        if (this.hasNoSearchResults()) {
          return;
        }

        const result = this.__searchResults[e.index];
        const place = await fetchPlace(result.placeId);

        this.__itemSelected = true;

        this.onChange({
          name: `${this.name}.address1`,
          value: place.address1,
        });

        this.onChange({
          name: `${this.name}.city`,
          value: place.city,
        });

        this.onChange({
          name: `${this.name}.state`,
          value: place.state,
        });

        this.onChange({
          name: `${this.name}.zipcode`,
          value: place.zipcode,
        });
      },
      renderEmpty: item => html`
        <p
          style="
          pointer-events: none;
          margin: 0 16px;
          font-size: ${CSS_FONT_SIZE_HEADER};
          color: ${CSS_COLOR_HIGHLIGHT};
        "
        >
          ${item.label}
        </p>
      `,
    };
  }

  __initServices() {
    this.__debounceService = new DebounceService(async () => {
      this.__searchResults = this.model.address1
        ? await fetchSuggestions(this.model.address1)
        : null;
    });
  }

  connectedCallback() {
    super.connectedCallback();

    loadMapsApi();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__debounceService.reset();
  }

  getMenuItems() {
    if (!this.model.address1 || this.__itemSelected || !this.__searchResults) {
      return [];
    }

    return this.__searchResults.length
      ? this.__searchResults
      : [NO_ADDRESS_FOUND];
  }

  getItemRenderer() {
    return this.hasNoSearchResults() ? this.__handlers.renderEmpty : null;
  }

  hasNoSearchResults() {
    const noResults = this.__searchResults && !this.__searchResults.length;
    const hasSearch = Boolean(this.model.address1);

    return hasSearch && noResults;
  }

  render() {
    return html`
      <div class="container">
        <neb-textfield
          id="${ELEMENTS.address1Field.id}"
          class="field field-col-2"
          name="address1"
          label="Address 1"
          leadingIcon="neb:search"
          maxLength="100"
          .value="${this.model.address1}"
          .error="${this.errors.address1}"
          .helper="${this.helpers.address1 || ' '}"
          .menuItems="${this.getMenuItems()}"
          .onChange="${this.__handlers.change}"
          .onRenderItem="${this.getItemRenderer()}"
          .onSelect="${this.__handlers.select}"
          ?valid="${!this.errors.address1}"
          ?disabled="${this.disabled}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.address2Field.id}"
          class="field field-col-2"
          name="address2"
          label="Address 2"
          maxLength="100"
          .value="${this.model.address2}"
          .error="${this.errors.address2}"
          .helper="${this.helpers.address2 || ' '}"
          .onChange="${this.__handlers.change}"
          ?valid="${!this.errors.address2}"
          ?disabled="${this.disabled}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.cityField.id}"
          class="field"
          name="city"
          label="City"
          maxLength="100"
          .value="${this.model.city}"
          .error="${this.errors.city}"
          .helper="${this.helpers.city || ' '}"
          .onChange="${this.__handlers.change}"
          ?valid="${!this.errors.city}"
          ?disabled="${this.disabled}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.stateField.id}"
          class="field"
          name="state"
          label="State"
          .items="${STATES}"
          .value="${this.model.state}"
          .error="${this.errors.state}"
          .helper="${this.helpers.state || ' '}"
          .onChange="${this.__handlers.change}"
          ?valid="${!this.errors.state}"
          ?disabled="${this.disabled}"
        ></neb-select>

        <neb-textfield
          id="${ELEMENTS.zipcodeField.id}"
          class="field"
          name="zipcode"
          label="ZIP Code"
          maxLength="10"
          .inputMode="${'numeric'}"
          .mask="${zipCode}"
          .value="${this.model.zipcode}"
          .error="${this.errors.zipcode}"
          .helper="${this.helpers.zipcode || ' '}"
          .onChange="${this.__handlers.change}"
          ?valid="${!this.errors.zipcode}"
          ?disabled="${this.disabled}"
        ></neb-textfield>
      </div>
    `;
  }
}
window.customElements.define('neb-address', Address);
