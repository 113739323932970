import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';

import { getValueByPath } from '@neb/form-validators/src/utils';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import {
  getOtherIntersectPath,
  getOtherSegmentPath,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-availability-item';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { required } from '../../../../packages/neb-utils/validators';
import { CSS_COLOR_GREY_2, CSS_SPACING } from '../../../styles';
import RoomAvailabilityList from '../../controls/field-groups/neb-room-availability-list';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  checkboxCheckIn: {
    id: 'checkbox-check-in',
  },
  checkboxSchedule: {
    id: 'checkbox-schedule',
  },
  pickerColor: {
    id: 'picker-color',
  },
  pickerColorAdvanced: {
    id: 'picker-color-Advanced',
  },
  switchActive: {
    id: 'switch-active',
  },
  textareaDescription: {
    id: 'textarea-description',
  },
  textfieldName: {
    id: 'textfield-name',
  },
  selectMaxOccupancy: {
    id: 'select-max-occupancy',
  },
  appointmentTypes: {
    id: 'appointment-types',
  },
  items: { selector: '[id^=list-]' },
};

const MAX_OCCUPANCY_ITEMS = [
  { data: { id: 1 }, label: '1' },
  { data: { id: 2 }, label: '2' },
  { data: { id: 3 }, label: '3' },
  { data: { id: 4 }, label: '4' },
  { data: { id: 5 }, label: '5' },
  { data: { id: 6 }, label: '6' },
  { data: { id: 7 }, label: '7' },
  { data: { id: 8 }, label: '8' },
  { data: { id: 9 }, label: '9' },
  { data: { id: 10 }, label: '10' },
  { data: { id: 999 }, label: 'Unlimited' },
];

export default class NebFormRooms extends NebForm {
  static get properties() {
    return {
      __appointmentTypes: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .field {
          width: fit-content;
        }

        .field-picker-color {
          height: 36px;
        }

        .select-occupancy {
          width: fit-content;
          margin-left: 15px;
        }

        .select-type {
          width: 50%;
        }

        .textarea-description {
          height: 200px;
        }

        .color-label {
          font-size: 12px;
          grid-column: span 2;
        }

        .color-container {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: auto 1fr;
          grid-column-gap: ${CSS_SPACING};
          align-items: center;
        }

        .border-bottom {
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .border-bottom:last-child {
          border-bottom: unset;
        }

        .w-fit {
          width: fit-content;
        }

        .flex-row {
          display: flex;
          align-items: center;
          padding: 15px 0px 25px 0px;
        }

        .mr-30 {
          margin-right: 30px;
        }

        .mb-25 {
          margin-bottom: 25px;
        }

        .layout {
          display: grid;
          padding-bottom: 20px;
          background-color: #fff;
          grid-gap: 5px 0;
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          flex: 1 0 0;
        }

        .pt-10 {
          padding: 10px 0px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__appointmentTypes = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: (name, index = -1) => {
        this.formService.addItem(name, index);
        this.__validateSegments(name);
      },
      removeItem: (name, index = -1) => {
        this.formService.removeItem(name, index);
        this.__validateSegments(name);
      },
      changeTypes: ({ name, value }) => {
        this.formService.apply(name, value);
        const keyPath = name.split('.');
        const dayIdx = keyPath[1];
        const segmentIdx = keyPath[3];

        if (this.isAllTypesSelected(value)) {
          this.formService.apply(
            `availability.${dayIdx}.segments.${segmentIdx}.allTypes`,
            true,
          );
        } else {
          this.formService.apply(
            `availability.${dayIdx}.segments.${segmentIdx}.allTypes`,
            false,
          );
        }
      },
      changeAvailability: e => {
        this.formService.apply(e.name, e.value);

        const fullPath = e.name.split('.');
        const branchKey = fullPath[2];
        const keyPath = fullPath.slice(0, fullPath.length - 1);

        switch (branchKey) {
          case 'enabled': {
            if (!e.value) {
              const dayIndex = Number(fullPath[1]);
              const count = this.state.availability[dayIndex].segments.length;

              for (let i = 0; i < count; ++i) {
                this.formService.removeItem(
                  `availability.${dayIndex}.segments`,
                );
              }
            } else {
              const copiedPath = [...keyPath];
              copiedPath.push('segments');
              const segmentPath = copiedPath.join('.');

              this.handlers.addItem(segmentPath, -1);
            }
            break;
          }

          case 'segments': {
            const otherSegPath = [...getOtherSegmentPath(keyPath), 'period'];
            const otherIntPath = [...getOtherIntersectPath(keyPath), 'period'];
            const otherSegValue = getValueByPath(this.state, otherSegPath);
            const otherIntValue = getValueByPath(this.state, otherIntPath);

            if (otherSegValue) {
              this.formService.validateKey(otherSegPath);
            }

            if (otherIntValue) {
              this.formService.validateKey(otherIntPath);
            }
            break;
          }

          default:
        }
      },
    };
  }

  isAllTypesSelected(segmentAppointmentTypes) {
    return equal(segmentAppointmentTypes, this.__appointmentTypes);
  }

  static createModel() {
    return {
      id: '',
      locationId: '',
      active: true,
      color: '#C0E6FF',
      description: '',
      name: '',
      checkInAvailable: true,
      scheduleAvailable: true,
      maxOccupancy: 1,
      availability: ['1', '2', '3', '4', '5', '6', '7'].map(day =>
        RoomAvailabilityList.createModel(day),
      ),
    };
  }

  createSelectors() {
    return {
      children: {
        name: [required()],
        maxOccupancy: {
          ...selectors.select(MAX_OCCUPANCY_ITEMS, MAX_OCCUPANCY_ITEMS[0]),
        },
        availability: {
          children: {
            $: RoomAvailabilityList.createSelectors(this.__appointmentTypes),
          },
        },
      },
    };
  }

  async load() {
    const apptTypes = await getAppointmentTypes();

    this.__appointmentTypes = apptTypes.data
      .filter(
        type =>
          type.active &&
          type.locations.some(id => id === this.model.locationId),
      )
      .map(data => ({
        label: data.name,
        data,
      }));
  }

  __validateSegments(name) {
    const path = name.split('.');
    const segments = getValueByPath(this.state, path);

    segments.forEach((_, index) => {
      this.formService.validateKey([...path, `${index}`, 'start'], true);
      this.formService.validateKey([...path, `${index}`, 'end'], true);
    });
  }

  __renderAvailability() {
    return this.state.scheduleAvailable
      ? html`
          <div class="pt-10"><b>Scheduling Availability</b></div>

          ${this.state.availability.map(
            (model, index) => html`
              <neb-room-availability-list
                id="list-${index}"
                class="border-bottom"
                .name="availability.${index}"
                .label="${model.day}"
                .model="${model}"
                .appointmentTypes="${this.__appointmentTypes}"
                .onChange="${this.handlers.changeAvailability}"
                .onChangeTypes="${this.handlers.changeTypes}"
                .errors="${this.errors.availability[index]}"
                .onAddItem="${this.handlers.addItem}"
                .onRemove="${this.handlers.removeItem}"
                .enabled="${this.state.scheduleAvailable}"
              ></neb-room-availability-list>
            `,
          )}
        `
      : '';
  }

  renderContent() {
    return html`
      <b>Details</b>
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        class="textfield textfield-name"
        name="name"
        label="Name"
        helper="Required"
        .value="${this.state.name}"
        .error="${this.errors.name}"
        .onChange="${this.handlers.change}"
        pinLabel
      ></neb-textfield>

      <neb-textarea
        id="${ELEMENTS.textareaDescription.id}"
        class="textarea textarea-description"
        name="description"
        label="Description"
        helper=" "
        placeholder="Enter a description for the room"
        .value="${this.state.description}"
        .onChange="${this.handlers.change}"
        pinLabel
      ></neb-textarea>

      <div class="w-fit flex-row">
        <neb-checkbox
          id="${ELEMENTS.checkboxCheckIn.id}"
          class="w-fit mr-30"
          name="checkInAvailable"
          label="Available for Check-In"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.checkInAvailable}"
        ></neb-checkbox>

        <neb-select
          id="${ELEMENTS.selectMaxOccupancy.id}"
          class="select-occupancy mr-30"
          name="maxOccupancy"
          label="Max Occupancy"
          .value="${this.state.maxOccupancy}"
          .items="${MAX_OCCUPANCY_ITEMS}"
          .onChange="${this.handlers.change}"
          ?disabled="${!this.state.checkInAvailable}"
        ></neb-select>

        <div class="color-container mr-30">
          <span class="color-label">Room Color</span>

          <neb-picker-color
            id="${ELEMENTS.pickerColor.id}"
            class="field"
            name="color"
            .value="${this.state.color}"
            .onChange="${this.handlers.change}"
          ></neb-picker-color>

          <neb-picker-color-advanced
            id="${ELEMENTS.pickerColorAdvanced.id}"
            class="field field-picker-color"
            name="color"
            .value="${this.state.color}"
            .onChange="${this.handlers.change}"
          ></neb-picker-color-advanced>
        </div>
      </div>

      <neb-checkbox
        id="${ELEMENTS.checkboxSchedule.id}"
        class="w-fit mb-25"
        name="scheduleAvailable"
        label="Available for Scheduling"
        .onChange="${this.handlers.change}"
        ?checked="${this.state.scheduleAvailable}"
      ></neb-checkbox>

      ${this.__renderAvailability()}

      <neb-switch
        id="${ELEMENTS.switchActive.id}"
        class="field"
        name="active"
        label="Active"
        .onChange="${this.handlers.change}"
        ?on="${this.state.active}"
      ></neb-switch>
    `;
  }
}

window.customElements.define('neb-form-rooms', NebFormRooms);
