import '../neb-search-bar';
import '../neb-icd-code-table';

import { LitElement, html, css } from 'lit';

import { fetchMany } from '../../../../../src/api-clients/clinical-codes';
import { LayoutService } from '../../../../neb-redux/services/layout';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import Debouncer from '../../../../neb-utils/debouncer';
import { dateToIcd10Year } from '../../../../neb-utils/icd10-util';

export const DEBOUNCE_DELAY = 300;

export const ELEMENTS = {
  searchBar: {
    id: 'search-bar',
  },
  icdCodeTable: {
    id: 'icd-code-table',
  },
};

class NebProblemListAddProblemController extends LitElement {
  static get properties() {
    return {
      __searchText: String,
      __searchResults: Array,
      __currentPageIndex: Number,
      __pageCount: Number,
      __icd10Year: Number,

      savedProblems: Array,
      visible: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.layout = '';
    this.savedProblems = [];
    this.visible = false;

    this.__searchText = '';
    this.__searchResults = null;
    this.__currentPageIndex = 0;
    this.__pageCount = 0;
    this.__icd10Year = dateToIcd10Year(parseDate());
    this.__offset = 0;
    this.__limit = 10;
  }

  __initHandlers() {
    this.__handlers = {
      addProblem: icd10Info => this.onAddNewProblemToList(icd10Info),
      search: text => {
        this.__searchText = text;
        this.__debounceSearchInput.debounce();
      },
      pageChanged: pageIndex => {
        this.__currentPageIndex = pageIndex;
        return this.__performSearch();
      },
    };
  }

  __initServices() {
    this.__layoutService = new LayoutService(layout => {
      this.layout = layout;
    });

    this.__debounceSearchInput = new Debouncer(
      () => this.__handleSearchInput(),
      DEBOUNCE_DELAY,
    );
  }

  async __performSearch() {
    this.__offset = this.__currentPageIndex * this.__limit;

    const { data, count } = await fetchMany({
      searchText: this.__searchText,
      icd10year: this.__icd10Year,
      offset: this.__offset,
      limit: this.__limit,
    });

    this.__searchResults = data;
    this.__pageCount = Math.ceil(count / this.__limit);
  }

  __setSearchBarUnfocus() {
    this.elements.searchBar.blur();
  }

  __handleSearchInput() {
    this.__currentPageIndex = 0;

    if (!this.__searchText) {
      this.__searchResults = null;
      this.__pageCount = 0;
    } else {
      this.__performSearch();
    }
  }

  clear() {
    this.elements.searchBar.clear();
  }

  async firstUpdated() {
    await super.firstUpdated();

    this.elements = {
      searchBar: this.shadowRoot.getElementById(ELEMENTS.searchBar.id),
    };
  }

  async updated(changedProps) {
    await super.updated();

    if (changedProps.has('visible') && !this.visible) {
      this.__setSearchBarUnfocus();
    }
  }

  connectedCallback() {
    super.connectedCallback();

    this.__layoutService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__layoutService.disconnect();
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 0 0;
      }

      :host([layout='small']) .search-bar-container {
        width: initial;
        padding: 0 20px;
      }

      .search-bar-container {
        padding: 0 ${CSS_SPACING};
        width: 420px;
      }
    `;
  }

  render() {
    return html`
      <div class="search-bar-container">
        <h4>Add Problem</h4>
        <neb-search-bar
          id="${ELEMENTS.searchBar.id}"
          placeholderText="Search Diagnoses"
          .searchText="${this.__searchText}"
          .onSearch="${this.__handlers.search}"
        ></neb-search-bar>
      </div>

      <neb-icd-code-table
        id="${ELEMENTS.icdCodeTable.id}"
        .savedCodes="${this.savedProblems}"
        .searchText="${this.__searchText}"
        .searchResults="${this.__searchResults}"
        .buttonText="${'Add to Problems'}"
        .buttonToggledText="${'Added to Problems'}"
        .currentPageIndex="${this.__currentPageIndex}"
        .pageCount="${this.__pageCount}"
        .layout="${this.layout}"
        .onButtonClick="${this.__handlers.addProblem}"
        .onPageChanged="${this.__handlers.pageChanged}"
      ></neb-icd-code-table>
    `;
  }
}
customElements.define(
  'neb-problem-list-add-problem-controller',
  NebProblemListAddProblemController,
);
