import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../controls/inputs/neb-checkbox';

import { inRange } from '@neb/form-validators';
import { html, css } from 'lit';

import Form from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import * as masks from '../../../../packages/neb-utils/masks';
import * as selectors from '../../../../packages/neb-utils/selectors';

export const ELEMENTS = {
  inputAmount: { id: 'input-amount' },
  checkWarn: { id: 'check-warn' },
  buttonUpdate: { id: 'button-update' },
  buttonCancel: { id: 'button-cancel' },
};

class NebFormERAPayment extends Form {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex: 1 0 auto;
          overflow: visible;
        }

        .field {
          margin-top: ${CSS_SPACING};
        }

        .grid-buttons {
          grid-template-columns: auto auto auto 1fr;
        }
      `,
    ];
  }

  static createModel() {
    return {
      amount: 0,
      warn: false,
      upperBound: 0,
      lowerBound: 0,
    };
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      saveAmount: e => {
        this.formService.apply('amount', e.value);
      },
    };
  }

  createSelectors() {
    return {
      children: {
        amount: selectors.currency({
          validateRaw: true,
          validators: [
            inRange(
              this.model.lowerBound,
              this.model.upperBound,
              true,
              true,
              this.__getHelper(this.model.upperBound, this.model.lowerBound),
            ),
          ],
        }),
      },
    };
  }

  __getHelper(upperBound, lowerBound) {
    return `Enter an amount between ${centsToCurrency(
      lowerBound,
    )} and ${centsToCurrency(upperBound)}`;
  }

  renderActionBar() {
    return html`
      <div class="grid grid-lean grid-buttons">
        <neb-button
          id="${ELEMENTS.buttonUpdate.id}"
          label="Update"
          .onClick="${this.handlers.save}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
          .role="${BUTTON_ROLE.OUTLINE}"
        ></neb-button>
      </div>
    `;
  }

  __amountsEqual() {
    return this.model.upperBound === this.model.lowerBound;
  }

  renderContent() {
    return html`
      <div class="grid grid-1 grid-lean">
        <neb-textfield
          id="${ELEMENTS.inputAmount.id}"
          class="field"
          name="amount"
          placeholder="Amount"
          .helper="${this.__getHelper(
            this.model.upperBound,
            this.model.lowerBound,
          )}"
          .value="${this.state.amount}"
          .error="${this.errors.amount}"
          .onChange="${this.handlers.change}"
          .onClickIcon="${''}"
          .mask="${masks.currency}"
          .disabled="${this.__amountsEqual()}"
        ></neb-textfield>
        <neb-checkbox
          id="${ELEMENTS.checkWarn.id}"
          class="spacer-bottom"
          name="warn"
          label="Show Warning Flag"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.warn}"
        ></neb-checkbox>
      </div>
    `;
  }
}

customElements.define('neb-form-era-payment', NebFormERAPayment);
