import '../../../neb-patient-panel';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../../../neb-utils/date-util';
import {
  formatCurrency,
  isEmpty,
  isMet,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkAmountText: {
    id: 'in-network-amount-text',
  },
  inNetworkRemainingText: {
    id: 'in-network-remaining-text',
  },
  inNetworkStartText: {
    id: 'in-network-start-text',
  },
  inNetworkOOPMetText: {
    id: 'in-network-oop-met-text',
  },
  outOfNetworkRemainingText: {
    id: 'out-of-network-remaining-text',
  },
  outOfNetworkAmountText: {
    id: 'out-of-network-amount-text',
  },
  outOfNetworkStartText: {
    id: 'out-of-network-start-text',
  },
  outOfNetworkOOPMetText: {
    id: 'out-of-network-oop-met-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
  title: {
    id: 'title',
  },
};
export const NO_CONTENT_TEXT =
  'There are no out of pocket maximums for this plan.';
const IN_NETWORK_AMOUNT_HEADER = 'In Network Amount';
const OUT_OF_NETWORK_AMOUNT_HEADER = 'Out Of Network Amount';
const IN_NETWORK_REMAINING_HEADER = 'In Network Remaining';
const OUT_OF_NETWORK_REMAINING_HEADER = 'Out Of Network Remaining';
const AS_OF_HEADER = 'As of';
export const MET_TEXT = 'OOP Met';

class NebPatientInsuranceOutOfPocketsCoverageDetails extends LitElement {
  static get properties() {
    return {
      outOfPocket: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.outOfPocket = {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .panel {
          border-style: solid;
          border-width: 1px;
          border-color: ${CSS_COLOR_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 10px 0 14px;
          height: 100%;
        }

        .out-of-pocket-container {
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .container {
          padding-top: ${CSS_SPACING};
        }

        .out-of-pocket-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .italic {
          font-style: italic;
        }

        .bold-italic-red {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-style: italic;
          color: #bf3131;
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .title {
          display: flex;
          padding-left: 10px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderCurrencyAmount(value) {
    if (isEmpty(value)) return '-';
    return formatCurrency(value);
  }

  __renderInNetworkAmount() {
    return html`
      <div class="bold">${IN_NETWORK_AMOUNT_HEADER}</div>
      <div id="${ELEMENTS.inNetworkAmountText.id}">
        ${this.__renderCurrencyAmount(this.outOfPocket.inNetworkAmount)}
      </div>
    `;
  }

  __renderOutOfNetworkAmount() {
    return html`
      <div class="bold">${OUT_OF_NETWORK_AMOUNT_HEADER}</div>
      <div id="${ELEMENTS.outOfNetworkAmountText.id}">
        ${this.__renderCurrencyAmount(this.outOfPocket.outOfNetworkAmount)}
      </div>
    `;
  }

  __renderInNetworkRemaining() {
    return html`
      <div class="bold">${IN_NETWORK_REMAINING_HEADER}</div>
      <div id="${ELEMENTS.inNetworkRemainingText.id}">
        ${this.__renderCurrencyAmount(this.outOfPocket.inNetworkRemaining)}
      </div>
    `;
  }

  __renderOutOfNetworkRemaining() {
    return html`
      <div class="bold">${OUT_OF_NETWORK_REMAINING_HEADER}</div>
      <div id="${ELEMENTS.outOfNetworkRemainingText.id}">
        ${this.__renderCurrencyAmount(this.outOfPocket.outOfNetworkRemaining)}
      </div>
    `;
  }

  __renderInNetworkStart() {
    if (isEmpty(this.outOfPocket.inNetworkRemaining)) return '';
    if (isEmpty(this.outOfPocket.inNetworkStart)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkStartText.id}">
        <span class="italic"
          >${AS_OF_HEADER}
          ${parseDate(this.outOfPocket.inNetworkStart).format(
            'MM/DD/YYYY',
          )}</span
        >
      </div>
    `;
  }

  __renderOutOfNetworkStart() {
    if (isEmpty(this.outOfPocket.outOfNetworkRemaining)) return '';
    if (isEmpty(this.outOfPocket.outOfNetworkStart)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkStartText.id}">
        <span class="italic"
          >${AS_OF_HEADER}
          ${parseDate(this.outOfPocket.outOfNetworkStart).format(
            'MM/DD/YYYY',
          )}</span
        >
      </div>
    `;
  }

  __renderInNetworkOOPMet() {
    return isMet(this.outOfPocket.inNetworkRemaining)
      ? html`
          <div id="${ELEMENTS.inNetworkOOPMetText.id}" class="bold-italic-red">
            ${MET_TEXT}
          </div>
        `
      : '';
  }

  __renderOutOfNetworkOOPMet() {
    return isMet(this.outOfPocket.outOfNetworkRemaining)
      ? html`
          <div
            id="${ELEMENTS.outOfNetworkOOPMetText.id}"
            class="bold-italic-red"
          >
            ${MET_TEXT}
          </div>
        `
      : '';
  }

  __renderInNetwork() {
    return html`
      <div class="flex-one">${this.__renderInNetworkAmount()}</div>

      <div class="flex-one">
        ${this.__renderInNetworkRemaining()} ${this.__renderInNetworkStart()}
        ${this.__renderInNetworkOOPMet()}
      </div>
    `;
  }

  __renderOutOfNetwork() {
    return html`
      <div class="flex-one">${this.__renderOutOfNetworkAmount()}</div>

      <div class="flex-one">
        ${this.__renderOutOfNetworkRemaining()}
        ${this.__renderOutOfNetworkStart()} ${this.__renderOutOfNetworkOOPMet()}
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="out-of-pocket-container">
        <div class="flex-one out-of-pocket-row">
          ${this.__renderInNetwork()}
        </div>
      </div>

      <div class="out-of-pocket-container">
        <div class="flex-one out-of-pocket-row">
          ${this.__renderOutOfNetwork()}
        </div>
      </div>
    `;
  }

  __renderNoContent() {
    return html`
      <div id="${ELEMENTS.noContentText.id}" class="no-content">
        ${NO_CONTENT_TEXT}
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderTitle(outOfPocket) {
    const title = `Max Out Of Pockets${
      outOfPocket.coverageLevel.code === 'NA'
        ? ''
        : ` - ${outOfPocket.coverageLevel.description}`
    }`;

    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        ${title} ${this.__renderDefaultIndicator(outOfPocket)}
      </div>
    `;
  }

  __renderOutOfPocket() {
    return html`
      <div id="${ELEMENTS.coverageLevelText.id}" class="panel">
        ${this.__renderTitle(this.outOfPocket)}
        ${this.outOfPocket.coverageLevel.code === 'NA'
          ? this.__renderNoContent()
          : this.__renderContent()}
      </div>
    `;
  }

  render() {
    return html` ${this.__renderOutOfPocket()} `;
  }
}

customElements.define(
  'neb-patient-insurance-out-of-pockets-coverage-details',
  NebPatientInsuranceOutOfPocketsCoverageDetails,
);
