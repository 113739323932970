import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import * as caseApi from '../../../../packages/neb-api-client/src/patient-cases';
import {
  isElectronicRow,
  DESIGNATED_CLEARINGHOUSE,
  MAX_INVOICE,
  BULK_ACTION_OPTION_ID,
  BULK_ACTION_OPTIONS,
} from '../../../../packages/neb-lit-components/src/components/claims/utils';
import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import {
  CSS_SPACING,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import {
  isClaimElectronic,
  isClaimScrubbed,
} from '../../../../packages/neb-utils/claims';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { mapFilteredCaseAuthorizations } from '../../../../packages/neb-utils/neb-ledger-util';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import { MODE } from '../../../../packages/neb-utils/table';
import { CSS_COLOR_GREY_1 } from '../../../styles';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../utils/locations/location-util';
import { plural } from '../../../utils/user-message';
import './claim-charges/neb-table-claim-worklist-view-claim-charges';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  iconBatches: { selector: '.icon-batch' },
  batchIds: { selector: '[id^=batch-]' },
  invoiceNumber: { selector: '[id^=link-invoiceNumber-]' },
  claimNumber: { selector: '[id^=link-claim-]' },
  status: { selector: '.status' },
  iconHidden: { selector: '.icon-hidden' },
  iconFlag: { selector: '.icon-flag' },
  claimIcons: { selector: '.icon-claim' },
  patientLinks: { selector: '[id^=link-patient-name-]' },
  primaryPayerAlias: { selector: '[id^=link-payer-]' },
  primaryPlanName: { selector: '[id^=link-primaryPlanName-]' },
  provider: { selector: '[id^=cell-provider-]' },
  secondaryPayer: { selector: '.sec-payer' },
  location: { selector: '.location' },
  expandedRow: { selector: '.expanded-row' },
  dateOfOnset: { selector: '[id^=date-of-onset-]', tag: 'neb-date-picker' },
  initialTreatmentDate: {
    selector: '[id^=initial-treatment-date-]',
    tag: 'neb-date-picker',
  },
  case: { selector: '[id^=case-]', tag: 'neb-select' },
  authorization: { selector: '[id^=authorization-]', tag: 'neb-select' },
  viewClaimCharges: { selector: '[id^=view-claim-charges-]' },
  editClaimNoteIcon: { selector: '[id^=edit-claim-note-icon-]' },
  editInvoiceNoteIcon: { selector: '[id^=edit-invoice-note-icon-]' },
  scrubClaimIcon: { selector: '[id^=isScrubbed-]' },
};

const CLAIMS_ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  scrubbed: 'neb:scrubClaim',
};

const HIDDEN_ICON = 'neb:visibilityOff';
const OUTLINED_FLAG = 'neb:outlinedFlag';
const SOLID_FLAG = 'neb:solidFlag';

class NebTableClaimsWorklistReadyToSubmit extends NebTable {
  static get properties() {
    return {
      enablePatients: Boolean,
      providers: Array,
      locations: Array,
      totalItemCount: Number,
      selectedItemCount: Number,
      excludedClaimOrInvoiceIds: Array,
      patientCases: Array,
      currentPage: Number,
      pageSize: Number,
      dirty: { type: Boolean },
      patientCasesDict: Object,
      authorizationsDict: Object,
      pageCount: Number,
      claimMetaData: Object,
      hasRcmClaimFlagFF: Boolean,
      hasRcmRelease2FF: Boolean,
      __hasRcmEasyBillingNotesFF: Boolean,
      __hasCTRevAccelAddOn: Boolean,
      __hasRTSScrubFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        #header {
          padding-top: ${CSS_SPACING};
          padding-bottom: 18px;
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .cell-header {
          padding: 0;
        }

        @media (max-width: 1887px) {
          #header {
            padding-top: 10px;
            padding-bottom: 11px;
          }
        }

        .cell-data {
          padding: 10px 0px;
        }

        .row-data[expanded] {
          border-bottom: 5px solid ${CSS_COLOR_GREY_1};
        }

        .cell[key='primaryPayerAlias'],
        .cell[key='invoiceNumber'],
        .cell[key='claimNumber'],
        .cell[key='patient.name'] {
          width: 100%;
          display: flex;
          align-items: center;
          width: max-content;
          column-gap: 4px;
        }

        .cell[key='lineItemCount'],
        .cell[key='designatedClearinghouseCount'],
        .cell[key='hidden'],
        .cell[key='rcmClaimFlag'] {
          display: flex;
          justify-content: center;
          margin-right: 0px;
          margin-left: 10px;
        }

        .cell[key='hidden'],
        .cell[key='rcmClaimFlag'] {
          margin-right: 10px;
        }

        .icon {
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-hidden,
        .icon-flag {
          width: 20px;
          height: 20px;
          margin-top: 5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-warning {
          display: block;
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_ERROR};
        }

        .icon-note {
          margin-top: 1px;
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .claim-info {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 20px;
        }

        .date-picker {
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();
    this.locations = [];
    this.enablePatients = false;
    this.mode = MODE.DETAIL;
    this.providers = [];
    this.config = this.__buildConfig();
    this.totalItemCount = 0;
    this.selectedItemCount = 0;
    this.excludedClaimOrInvoiceIds = [];
    this.patientCases = [];
    this.patientCasesDict = {};
    this.authorizationsDict = {};
    this.dirty = false;
    this.showBulkActionMenu = true;
    this.showSelectAll = true;
    this.showExpandAll = true;
    this.mode = MODE.EXPAND;
    this.pageCount = 0;
    this.claimMetaData = { selectAll: false };
    this.hasRcmClaimFlagFF = false;
    this.hasRcmRelease2FF = false;

    this.__today = new Date();
    this.__hasRcmEasyBillingNotesFF = false;
    this.__hasCTRevAccelAddOn = false;
    this.__hasRTSScrubFF = false;

    this.onSelectAllItems = () => {};

    this.onSaveAndSubmit = () => {};

    this.onConfirmHide = () => {};

    this.onConfirmShow = () => {};

    this.onScrubClaim = () => {};

    this.onConfirmDelete = () => {};

    this.onClickLink = () => {};

    this.onSelect = () => {};

    this.onEdit = () => {};

    this.onUpdatePatientCase = () => {};

    this.onAddPatientCase = () => {};

    this.onLoad = () => {};

    this.onDirty = () => {};

    this.onRefreshDrafts = () => {};
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.locations = locations;
    });
  }

  getSelectAllOnCurrentPageLabel() {
    const excludedCount = this.model.reduce((acc, value) => {
      if (this.excludedClaimOrInvoiceIds.includes(value.claimId)) {
        acc++;
      } else if (this.excludedClaimOrInvoiceIds.includes(value.invoiceId)) {
        acc++;
      }

      return acc;
    }, 0);

    return `Select ${plural(
      this.model.length - excludedCount,
      'Item',
    )} on This Page`;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      load: () => this.onLoad(),
      dirty: callback => this.onDirty(callback),
      openBillingNotesOverlay: e => {
        e.stopPropagation();

        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        const type = e.currentTarget.getAttribute('type');

        if (type === 'claim') {
          return this.onClickLink('claimBillingNote', parseInt(rowIndex, 10));
        }

        return this.onClickLink('invoiceBillingNote', parseInt(rowIndex, 10));
      },
      clickLink: ({ name }) => {
        const index = name.split('.')[0];
        const key = name.split('.')[1];

        return this.onClickLink(key, parseInt(index, 10));
      },
      clickExpanded: e => {
        e.stopPropagation();
      },
      clearDate: (index, name) => {
        this.onEdit({ name: `${index}.${name}`, value: null });
      },
      changeDate: (e, index) => {
        this.onEdit({
          name: `${index}.${e.name}`,
          value: e.value ? e.value.toISOString() : null,
        });
      },
      changeCase: (e, index) => {
        if (e.event !== 'select') return;
        this.onEdit({
          name: `${index}.${e.name}`,
          value: e.value.data.id || null,
        });

        this.onEdit({
          name: `${index}.initialTxDate`,
          value: e.value.data.initialTxDate || null,
        });

        if (e.value.data.id !== this.model[0].patientCaseId) {
          let [authorization] = e.value.data.id
            ? this.__getPatientCaseAuthorizationItems(e.value.data)
            : [];

          if (!authorization) {
            [authorization] = this.__getAuthorizationItems(index);
          }

          this.onEdit({
            name: `${index}.patientAuthorizationId`,
            value: authorization?.data?.id || null,
          });
        }
      },
      changeAuthorization: (e, index) => {
        if (e.event !== 'select') return;
        this.onEdit({
          name: `${index}.${e.name}`,
          value: e.value.data.id || null,
        });
      },
      onAddNewItem: async index => {
        const { patientId } = this.model[index];
        const patientCases = this.patientCasesDict[patientId] || [];

        const patientCase = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId,
            isFirstCase: !patientCases.length,
          },
        });

        if (!patientCase) return;

        this.onEdit({
          name: `${index}.patientCaseId`,
          value: patientCase.id || null,
        });

        this.onEdit({
          name: `${index}.initialTxDate`,
          value: patientCase.initialTxDate,
        });

        this.onAddPatientCase(patientId, patientCase);
      },
      onEditItem: async ({ index, data: { id: patientCaseId } = {} }) => {
        let updatedAuth;

        const { patientId } = this.model[index];
        const patientCases = this.patientCasesDict[patientId] || [];
        const patientCase = await caseApi.fetchOne(patientId, patientCaseId);

        const updatedCase = await openOverlay(OVERLAY_KEYS.CASE, {
          item: patientCase,
          context: {
            patientId: this.model[index].patientId,
            isFirstCase: !patientCases.length,
            onAuthorizationChange: result => {
              updatedAuth = result;
            },
          },
        });

        if (!updatedCase && !updatedAuth) return;

        this.onUpdatePatientCase(patientId, {
          ...(updatedCase ? updatedCase.patientCase : patientCase),
          ...(updatedAuth && { patientAuthorization: updatedAuth }),
        });

        if (
          updatedAuth &&
          !this.model[index].claimId &&
          this.model[index].patientCaseId === patientCaseId
        ) {
          this.onEdit({
            name: `${index}.patientAuthorizationId`,
            value: updatedAuth.id || null,
          });
        }

        this.requestUpdate();
      },
      changeLineItem: e => this.__onChangeLineItem(e),
      isNotFutureDate: date => date <= this.__today,
      isInitialTxDateSelectable: (date, index) => {
        if (!this.model[index].patientCaseId) {
          return date <= this.__today;
        }

        const patientCases = this.patientCasesDict[this.model[index].patientId];
        const selectedPatientCase = patientCases.find(
          c => c.id === this.model[index].patientCaseId,
        );

        if (selectedPatientCase.lastSeenDate) {
          return date <= parseDate(selectedPatientCase.lastSeenDate);
        }

        return date <= this.__today;
      },
      clickClaimFlag: async (e, index) => {
        e.stopPropagation();

        const value = !this.model[index].claimFlag;

        this.onEdit({
          index,
          name: `${index}.claimFlag`,
          value,
        });

        await this.__handleClaimFlagDetails(value, index);
      },
    };
  }

  __setClaimFlagDetails(index, claimFlagDetails = {}) {
    const { assignedTo = '', description = '' } = claimFlagDetails;

    this.onEdit({
      name: `${index}.claimFlagDetails.description`,
      value: description,
    });

    this.onEdit({
      name: `${index}.claimFlagDetails.assignedTo`,
      value: assignedTo,
    });
  }

  async __handleClaimFlagDetails(claimFlag, index) {
    if (!this.hasRcmRelease2FF) return;

    if (!claimFlag) {
      this.__setClaimFlagDetails(index);
      return;
    }

    const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_ACTION);

    if (result) {
      this.__setClaimFlagDetails(index, result);
      return;
    }

    this.onEdit({
      index,
      name: `${index}.claimFlag`,
      value: false,
    });

    this.__setClaimFlagDetails(index);
  }

  __flaggedClaimTotal() {
    return this.model.filter(item => item.claimFlag).length;
  }

  __checkSelectAllItems() {
    return (
      this.claimMetaData.selectAll ||
      this.totalItemCount === this.selectedItemCount ||
      this.selectedItemCount +
        (this.hasRcmClaimFlagFF ? this.__flaggedClaimTotal() : 0) <
        this.model.length ||
      this.pageCount <= 1
    );
  }

  __getBulkActions() {
    return [
      ...(this.selectedItemCount
        ? [
            {
              id: BULK_ACTION_OPTION_ID.SELECTED_ALL_ON_CURRENT_PAGE,
              label: `${plural(this.selectedItemCount, 'Item')} Selected.`,
              showTextOnly: true,
            },
          ]
        : []),
      ...(this.__checkSelectAllItems()
        ? []
        : [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SELECT_ALL],
              onSelect: this.onSelectAllItems,
              label: `Select All ${plural(
                (this.hasRcmClaimFlagFF
                  ? this.totalItemCount - this.__flaggedClaimTotal()
                  : this.totalItemCount) -
                  this.excludedClaimOrInvoiceIds.length,
                'Item',
              )}`,
            },
          ]),
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.GENERATE_AND_SUBMIT],
        onSelect: this.onSaveAndSubmit,
        disabled: this.selectedItemCount === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.REFRESH_DRAFTS],
        onSelect: this.onRefreshDrafts,
        disabled: this.selectedItemCount === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.DELETE_CLAIM_DRAFTS],
        onSelect: this.onConfirmDelete,
        disabled: this.selectedItemCount === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS],
        onSelect: this.onConfirmHide,
        disabled: this.selectedItemCount === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS],
        onSelect: this.onConfirmShow,
        disabled: this.selectedItemCount === 0,
      },
      ...(this.__hasCTRevAccelAddOn && this.__hasRTSScrubFF
        ? [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SCRUB_CLAIMS],
              onSelect: this.onScrubClaim,
              disabled: this.selectedItemCount === 0,
            },
          ]
        : []),
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    this.__hasRcmEasyBillingNotesFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.EASY_BILLING_NOTES,
    );

    this.__hasRTSScrubFF = await hasFeatureOrBeta(FEATURE_FLAGS.RTS_SCRUB);

    this.__hasCTRevAccelAddOn = await hasAddOn(ADD_ONS.CT_REV_ACCEL);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __buildConfig() {
    return [
      {
        key: 'rcmClaimFlag',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'lineItemCount',
        label: '',
        flex: css`0 0 24px`,
        truncate: true,
      },
      {
        key: 'designatedClearinghouseCount',
        label: '',
        flex: css`0 0 24px`,
        truncate: true,
      },
      {
        key: 'hidden',
        label: '',
        flex: css`0 0 28px`,
        truncate: true,
      },
      {
        sortable: true,
        key: 'dateOfService',
        label: 'Service Date',
        flex: css`1.5 0 0`,
      },
      {
        key: 'locationName',
        label: 'Location',
        flex: css`1.25 0 0`,
      },
      {
        truncate: true,
        sortable: true,
        key: 'invoiceNumber',
        label: 'Invoice',
        flex: css`1.25 0 0`,
      },
      {
        truncate: true,
        sortable: true,
        key: 'claimNumber',
        label: 'Claim',
        flex: css`1.25 0 0`,
      },
      {
        truncate: true,
        key: 'status',
        label: 'Status',
        flex: css`1.5 0 0`,
      },
      ...(this.enablePatients
        ? [
            {
              key: 'patient.name',
              label: 'Patient',
              flex: css`2 0 0`,
            },
          ]
        : []),
      {
        sortable: true,
        truncate: true,
        key: 'primaryPayerAlias',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'primaryPlanName',
        label: 'Plan',
        flex: css`1 0 0`,
      },
      {
        key: 'provider',
        label: 'Billing Provider',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        sortable: true,
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
      },
    ];
  }

  __getClaimIcon(claim, index) {
    const isElectronic = isClaimElectronic(claim);

    return {
      icon: isElectronic ? CLAIMS_ICON.electronic : CLAIMS_ICON.paper,
      id: isElectronic ? `isElectronic-${index}` : `isPaper-${index}`,
    };
  }

  __getScrubIcon(claim, index) {
    const isScrubbed = isClaimScrubbed(claim);

    if (isScrubbed) {
      return {
        id: `isScrubbed-${index}`,
        icon: CLAIMS_ICON.scrubbed,
      };
    }

    return false;
  }

  __changePropsForBulkAction(changedProps) {
    return (
      changedProps.has('totalItemCount') ||
      changedProps.has('excludedClaimOrInvoiceIds') ||
      changedProps.has('showBulkActionMenu') ||
      changedProps.has('selectedItemCount') ||
      changedProps.has('claimMetaData') ||
      changedProps.has('model')
    );
  }

  update(changedProps) {
    if (changedProps.has('enablePatients')) {
      this.config = this.__buildConfig();
    }

    if (this.__changePropsForBulkAction(changedProps)) {
      this.bulkActionItems = this.__getBulkActions();
    }

    super.update(changedProps);
  }

  __onChangeDiagnoses(index, liIndex, selectedIndices) {
    if (!selectedIndices) return;

    const value = this.model[index].lineItems[liIndex].diagnoses
      .filter((_, idx) => selectedIndices.includes(idx))
      .map(({ code: diagnosisCode }) => ({ diagnosisCode }));

    this.onEdit({
      name: `${index}.lineItems.${liIndex}.diagnosesPointers`,
      value,
    });
  }

  __onChangeLineItem({ id, index, selectedIndices }) {
    const [key, liIndex] = id.split('-');

    if (key === 'diagnoses') {
      this.__onChangeDiagnoses(index, liIndex, selectedIndices);
    }
  }

  __getPatientCaseAuthorizationItems(associatedCase) {
    return associatedCase
      ? mapFilteredCaseAuthorizations([{ data: associatedCase }])
      : [];
  }

  __getAuthorizationItems(index) {
    const caseAuthorizations = this.__getPatientCaseAuthorizationItems(
      this.__getAssociatedPatientCase(index),
    );

    const claimAuthorizations = this.__getClaimAuthorizationItems(index).filter(
      item =>
        !caseAuthorizations.find(
          auth =>
            auth.data.authorizationNumber === item.data.authorizationNumber,
        ),
    );

    return [...caseAuthorizations, ...claimAuthorizations].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  }

  __getClaimAuthorizationItems(index) {
    if (
      this.model[index].claimAuthorizations &&
      this.model[index].claimAuthorizations.length
    ) {
      return this.model[index].claimAuthorizations.map(authorization => ({
        data: authorization,
        label: authorization.authorizationNumber,
      }));
    }

    return [];
  }

  __getAssociatedPatientCase(index) {
    const { patientId, patientCaseId } = this.model[index];
    const patientCases = this.patientCasesDict[patientId] || [];

    return patientCases.find(c => c.id === patientCaseId);
  }

  __getPatientAuthorizationValue(index) {
    let authorization = this.__getAuthorizationItems(index).find(
      auth => auth.data.id === this.model[index].patientAuthorizationId,
    );

    if (!authorization) {
      const claimAuthorization = this.__getClaimAuthorizationItems(index).find(
        auth => auth.data.id === this.model[index].patientAuthorizationId,
      );

      if (claimAuthorization) {
        authorization = this.__getAuthorizationItems(index).find(
          auth =>
            auth.data.authorizationNumber ===
            claimAuthorization.data.authorizationNumber,
        );
      }
    }
    return authorization || null;
  }

  __renderClaimsDetails(index) {
    const { patientId } = this.model[index];
    const patientCases = this.patientCasesDict[patientId] || [];

    const formatCase = c => ({
      data: c,
      label: `${c.name} - ${
        c.onsetSymptomsDate
          ? moment.utc(c.onsetSymptomsDate).format('MM/DD/YYYY')
          : 'Gradual'
      }`,
    });

    const formattedPatientCasesForSelect = patientCases
      .filter(c => c.active)
      .map(c => formatCase(c));

    const associatedPatientCase = this.__getAssociatedPatientCase(index);

    const initialSelectedCase = associatedPatientCase
      ? formatCase(associatedPatientCase)
      : null;

    return html`
      <div class="claim-info">
        <neb-date-picker
          id="date-of-onset-${index}"
          class="date-picker"
          label="Date of Onset"
          name="dateOfCurrentIllness"
          .onChange="${e => this.handlers.changeDate(e, index)}"
          .onClear="${() =>
            this.handlers.clearDate(index, 'dateOfCurrentIllness')}"
          .selectedDate="${this.model[index].dateOfCurrentIllness
            ? parseDate(this.model[index].dateOfCurrentIllness)
            : null}"
          .isDateSelectable="${this.handlers.isNotFutureDate}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="case-${index}"
          class="case-dropdown"
          label="Case"
          name="patientCaseId"
          .items="${[ITEM_EMPTY, ...formattedPatientCasesForSelect]}"
          .onChange="${e => this.handlers.changeCase(e, index)}"
          .value="${initialSelectedCase}"
          addNewItemLabel="Case"
          .onAddNewItem="${() => this.handlers.onAddNewItem(index)}"
          actionIcon="neb:edit"
          .onClickActionIcon="${e => this.handlers.onEditItem({ ...e, index })}"
        ></neb-select>

        <neb-date-picker
          id="initial-treatment-date-${index}"
          class="date-picker"
          label="Initial Treatment Date"
          name="initialTxDate"
          .selectedDate="${this.model[index].initialTxDate
            ? parseDate(this.model[index].initialTxDate)
            : null}"
          .onChange="${e => this.handlers.changeDate(e, index)}"
          .onClear="${() => this.handlers.clearDate(index, 'initialTxDate')}"
          momentFlag
          .isDateSelectable="${date =>
            this.handlers.isInitialTxDateSelectable(date, index)}"
          ?disabled="${!associatedPatientCase}"
        ></neb-date-picker>

        <neb-select
          id="authorization-${index}"
          class="authorization-dropdown"
          label="Authorization"
          name="patientAuthorizationId"
          .items="${this.__getAuthorizationItems(index)}"
          .value="${this.__getPatientAuthorizationValue(index)}"
          .onChange="${e => this.handlers.changeAuthorization(e, index)}"
          ?disabled="${!associatedPatientCase}"
        ></neb-select>
      </div>
    `;
  }

  __renderClaimsCharges(index) {
    const { lineItems: charges = [], claimId, status } = this.model[index];
    const tableCharges = charges.map(c => ({ ...c, claimId, status }));
    const invoiceClaimIds = this.model.reduce((accum, item) => {
      if (!item.claimId && item.status !== 'Draft') return accum;
      if (!accum[item.invoiceId]) {
        accum[item.invoiceId] = [item.claimId];
      } else accum[item.invoiceId].push(item.claimId);

      return accum;
    }, {});

    return html`
      <neb-table-claim-worklist-view-claim-charges
        id="view-claim-charges-${index}"
        .model="${tableCharges}"
        .invoiceClaimIds="${invoiceClaimIds}"
        .index="${index}"
        .providers="${this.providers}"
        .locations="${this.locations}"
        .onChange="${this.handlers.changeLineItem}"
        .onLoad="${this.handlers.load}"
        .onDirty="${this.handlers.dirty}"
        .dirty="${this.dirty}"
      ></neb-table-claim-worklist-view-claim-charges>
    `;
  }

  __getInvoiceBillingNoteIcon(rowIndex) {
    return this.model[rowIndex].hasInvoiceBillingNote
      ? 'neb:editNote'
      : 'neb:addNote';
  }

  clickCheckbox(isClaimFlagChecked, index, e) {
    if (!this.hasRcmClaimFlagFF || !isClaimFlagChecked) {
      this.handlers.selectCheckbox(this.model[index], index);
      e.stopPropagation();
    }
  }

  isAllItemsSelected() {
    if (this.hasRcmClaimFlagFF) {
      if (this.model.length === 1 && this.model[0].claimFlag) {
        return false;
      }

      return this.selectedItems
        .map((item, index) => item || this.model[index].claimFlag)
        .every(Boolean);
    }

    return this.selectedItems.length > 0 && this.selectedItems.every(Boolean);
  }

  __renderNoteIcon(type, rowIndex) {
    if (!this.__hasRcmEasyBillingNotesFF) {
      return this.model[rowIndex].hasInvoiceBillingNote
        ? html`
            <neb-icon
              id="edit-${type}-note-icon-${rowIndex}"
              class="icon-note"
              icon="neb:editNote"
              type="${type}"
              rowIndex="${rowIndex}"
              @click="${this.handlers.openBillingNotesOverlay}"
            ></neb-icon>
          `
        : '';
    }

    return html`
      <neb-icon
        id="edit-${type}-note-icon-${rowIndex}"
        class="icon-note"
        icon="${this.__getInvoiceBillingNoteIcon(rowIndex)}"
        type="${type}"
        rowIndex="${rowIndex}"
        @click="${this.handlers.openBillingNotesOverlay}"
      ></neb-icon>
    `;
  }

  __renderSelectAll() {
    return this.showSelectAll && this.model && this.model.length
      ? html`
          <neb-checkbox
            class="cell-header cell-checkbox"
            id="${ELEMENTS.selectAllRows.id}"
            ?checked="${this.isAllItemsSelected()}"
            @click="${e => {
              this.handlers.selectAll();
              e.stopPropagation();
            }}"
          ></neb-checkbox>
        `
      : this.__renderSpacerCell(this.showSelectAll, 'cell-icon');
  }

  __renderSelectCheckboxCell(index) {
    const isClaimFlagChecked = this.model[index].claimFlag;

    return this.showSelectAll && this.model && this.model.length
      ? html`
          <neb-checkbox
            id="select-checkbox-${index}"
            class="cell-checkbox"
            .name="${index}"
            ?checked="${this.selectedItems[index] || false}"
            @click="${e => {
              this.clickCheckbox(isClaimFlagChecked, index, e);
            }}"
            ?disabled="${this.hasRcmClaimFlagFF && isClaimFlagChecked}"
          ></neb-checkbox>
        `
      : '';
  }

  renderExpandedRow(index) {
    return html`
      <div
        id="row-${index}"
        class="expanded-row"
        @click="${this.handlers.clickExpanded}"
      >
        ${this.__renderClaimsDetails(index)}${this.__renderClaimsCharges(index)}
      </div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name) {
    const row = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'hidden':
        return value
          ? html`
              <neb-icon class="icon-hidden" .icon="${HIDDEN_ICON}"></neb-icon>
            `
          : '';

      case 'lineItemCount':
        return value > MAX_INVOICE.MAX_CHARGES
          ? html`
              <neb-icon
                class="icon-warning"
                title="${MAX_INVOICE.HOVER_TEXT}"
                icon="neb:warning"
              ></neb-icon>
            `
          : '';

      case 'designatedClearinghouseCount':
        return !(row.lineItemCount > MAX_INVOICE.MAX_CHARGES) &&
          value !== DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT &&
          isElectronicRow(row)
          ? html`
              <neb-icon
                class="icon-warning"
                title="${DESIGNATED_CLEARINGHOUSE.HOVER_TEXT}"
                icon="neb:warning"
              ></neb-icon>
            `
          : '';

      case 'rcmClaimFlag': {
        return this.hasRcmClaimFlagFF
          ? html`
              <neb-icon
                class="icon-flag"
                .icon="${row.claimFlag ? SOLID_FLAG : OUTLINED_FLAG}"
                @click="${e => {
                  this.handlers.clickClaimFlag(e, rowIndex);
                }}"
              ></neb-icon>
            `
          : '';
      }

      case 'dateOfService': {
        const dates = value ? value.split('-') : '';

        return dates.length > 1
          ? html`
              <div>
                <neb-text class="date" bold>${dates[0]} -</neb-text>
                <neb-text class="date" bold>${dates[1]}</neb-text>
              </div>
            `
          : html` <div><neb-text class="date" bold>${value}</neb-text></div> `;
      }

      case 'locationName':
        return html`
          <div class="text">
            <div class="location">
              <neb-text
                >${getLocationValue(
                  this.locations,
                  row.locationIds[0],
                  LOCATION_KEYS.NAME,
                )}</neb-text
              >
            </div>
          </div>
        `;

      case 'invoiceNumber':
        return html`
          <neb-text
            id="link-invoiceNumber-${rowIndex}"
            bold
            link
            name="${name}"
            .onClick="${this.handlers.clickLink}"
          >
            ${value}
          </neb-text>

          ${this.__renderNoteIcon('invoice', rowIndex)}
        `;

      case 'claimNumber': {
        const claimIcon = this.__getClaimIcon(this.model[rowIndex], rowIndex);

        return value
          ? html`
              <neb-icon
                id="${claimIcon.id}"
                class="icon icon-claim"
                .icon="${claimIcon.icon}"
              ></neb-icon>

              <neb-text
                id="link-claim-${rowIndex}"
                bold
                link
                name="${name}"
                .onClick="${this.handlers.clickLink}"
                >${value}</neb-text
              >

              ${this.model[rowIndex].hasClaimBillingNote
                ? this.__renderNoteIcon('claim', rowIndex)
                : ''}
            `
          : html`
              <neb-icon
                class="icon icon-claim"
                .icon="${claimIcon.icon}"
              ></neb-icon>
            `;
      }

      case 'status': {
        const scrubClaimIcon = this.__getScrubIcon(
          this.model[rowIndex],
          rowIndex,
        );

        return html`
          ${scrubClaimIcon && this.__hasCTRevAccelAddOn && this.__hasRTSScrubFF
            ? html`
                <neb-icon
                  id="${scrubClaimIcon.id}"
                  class="icon icon-claim"
                  .icon="${scrubClaimIcon.icon}"
                ></neb-icon>
              `
            : html``}

          <neb-text truncate>${value || 'Ready To Submit'}</neb-text>
        `;
      }

      case 'patient.name':
        return html`
          <neb-text
            id="link-patient-name-${rowIndex}"
            bold
            link
            name="${name}"
            .onClick="${this.handlers.clickLink}"
            >${objToName(value, DEFAULT_NAME_OPTS)}</neb-text
          >

          <neb-icon class="icon" icon="neb:open"></neb-icon>
        `;

      case 'primaryPayerAlias':
        return html`
          ${row.paymentResponsibilityLevelCode === 'Secondary'
            ? html` <span class="sec-payer">(S)</span> `
            : ''}

          <neb-text
            id="link-payer-${rowIndex}"
            class="button"
            bold
            link
            truncate
            name="${name}"
            .onClick="${this.handlers.clickLink}"
          >
            ${value}
          </neb-text>
        `;

      case 'primaryPlanName':
        return value
          ? html`
              <neb-text
                id="link-primaryPlanName-${rowIndex}"
                truncate
                bold
                link
                name="${name}"
                .onClick="${this.handlers.clickLink}"
                >${value}</neb-text
              >
            `
          : '';

      case 'provider':
        return value ? objToName(value.name, DEFAULT_NAME_OPTS) : '';

      case 'amount':
        return centsToCurrency(value);

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-claims-worklist-ready-to-submit',
  NebTableClaimsWorklistReadyToSubmit,
);
