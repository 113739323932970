import '../../../../../../src/components/controllers/alerts/neb-patient-alert-controller';
import '../../patients/neb-patient-summary-controller';
import '../../neb-popup-header';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  OVERLAY_WIDTH_LARGE,
} from '../../../../../neb-styles/neb-variables';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
  popupHeader: {
    id: 'popup-header',
    title: 'Alerts & Notes',
  },
  patientAlert: {
    id: 'patient-alert',
  },
  patientAlertDiv: {
    id: 'patient-alert-div',
  },
};

class NebOverlayPatientAlert extends Overlay {
  static get properties() {
    return {
      alertCount: {
        type: Number,
      },
    };
  }

  initState() {
    super.initState();
    this.disableAlertsOverlayLink = true;
    this.model = {
      patientId: '',
    };

    this.__alertChanged = false;
    this.alertCount = 0;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      updateCount: count => {
        if (count !== this.alertCount) {
          this.__alertChanged = true;
        }

        this.alertCount = count;
      },
      dismiss: () => {
        this.dismiss(this.__alertChanged);
      },
    };
  }

  dismissWithBlocker() {
    this.dismiss(this.__alertChanged);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .alert-form-content {
          display: flex;
          flex: 1 0 0;
          overflow: auto;
        }

        .content {
          display: flex;
        }

        .demographic {
          padding: ${CSS_SPACING};
          border-right: ${CSS_BORDER_GREY_2};
        }

        .patient-alert {
          padding: ${CSS_SPACING} 0;
          overflow-y: auto;
          width: 710px;
        }

        .patient-alert-new {
          padding: ${CSS_SPACING} 0;
          overflow-y: auto;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .popup-header {
          margin-bottom: 10px;
          padding: 0 25px;
        }

        :host([layout='small']) .content {
          flex-direction: column;
        }

        :host([layout='small']) .alert-form-content {
          flex-direction: column;
        }

        :host([layout='small']) .demographic {
          max-width: 100%;
          padding: 0;
          border-right: none;
        }

        :host([layout='small']) .patient-alert {
          width: auto;
        }

        :host([layout='small']) .patient-alert-new {
          width: auto;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="alert-form-content">
        <neb-patient-summary-controller
          id="${ELEMENTS.patientSummary.id}"
          class="demographic"
          .patientId="${this.model.patientId}"
          .disableAlertsOverlayLink="${this.disableAlertsOverlayLink}"
          .alertCount="${this.alertCount}"
        >
        </neb-patient-summary-controller>
        <div id="${ELEMENTS.patientAlertDiv.id}" class="patient-alert-new">
          <neb-popup-header
            id="${ELEMENTS.popupHeader.id}"
            class="popup-header"
            title="${ELEMENTS.popupHeader.title} ${this.alertCount === 0
              ? ''
              : `(${this.alertCount})`}"
            .onCancel="${this.handlers.dismiss}"
          ></neb-popup-header>
          <neb-patient-alert-controller
            id="${ELEMENTS.patientAlert.id}"
            .patientId="${this.model.patientId}"
            .onCountChanged="${this.handlers.updateCount}"
            .onDirty="${this.handlers.dirty}"
          ></neb-patient-alert-controller>
        </div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-alert',
  NebOverlayPatientAlert,
);
