import {
  isDate,
  isDateBefore,
  isDateAfter,
  isPhoneNumber,
} from '@neb/form-validators';
import { format, parse } from 'date-fns';

import {
  toNumeric,
  centsToCurrency,
  currencyToCents,
  formatPhoneNumber,
  formatDollarAmountWithNegative,
  currencyToCentsWithNegative,
} from './formatters';

export const ITEM_EMPTY = {
  label: '',
  data: { id: '' },
};

export function createDefaultModifiers(opts) {
  return {
    unsafe: opts.unsafe || false,
    clipPristine: opts.clipPristine || false,
    ignorePristine: opts.ignorePristine || false,
    validateRaw: opts.validateRaw || false,
    validateManually: opts.validateManually || false,
    validators: opts.validators ? opts.validators : null,
  };
}

export function select(items, defaultItem = ITEM_EMPTY, opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    unsafe: true,
    clipPristine: true,
    format: v => items.find(item => item.data.id === v) || defaultItem,
    unformat: v => (v.data ? v.data.id : ''),
  };
}

export function multiSelect(items, defaultItems = [], opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    unsafe: true,
    clipPristine: true,
    format: v => {
      const selectedItems = v.map(v => items.find(item => item.data.id === v));
      const itemsAreLoaded = selectedItems.every(item => item !== undefined);

      return itemsAreLoaded ? selectedItems : defaultItems;
    },
    unformat: v => v.map(v => v.data.id),
  };
}

export function nonStrictMultiSelect(items, defaultItems = [], opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    unsafe: true,
    clipPristine: true,
    format: v => {
      const selectedItems = items.filter(item => v.includes(item.data.id));

      return selectedItems.length ? selectedItems : defaultItems;
    },
    unformat: v => v.map(v => v.data.id),
  };
}

export function date(opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => (v ? format(v, 'MM/DD/YYYY') : ''),
    unformat: v => (v ? parse(v, 'MM/DD/YYYY') : null),
    validators: [...(opts.validators || []), isDate()],
  };
}

export function dateOfBirth(opts = {}) {
  const now = format(new Date(), 'YYYY-MM-DD');

  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => (v ? format(v, 'MM/DD/YYYY') : ''),
    unformat: v => (v ? parse(v, 'MM/DD/YYYY') : null),
    validators: [
      ...(opts.validators || []),
      isDate(),
      isDateBefore(now),
      isDateAfter('1900-01-01'),
    ],
  };
}

export function phoneNumber(opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => formatPhoneNumber(v),
    unformat: v => toNumeric(v),
    validators: [...(opts.validators || []), isPhoneNumber('(###) ###-####')],
  };
}

export function numeric(padCount = 0, opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => (padCount ? `${v}`.padStart(padCount, '0') : `${v}`),
    unformat: v => Number(v),
  };
}

export function currency(opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => centsToCurrency(v),
    unformat: v => currencyToCents(v),
  };
}

export function currencyWithNegative(opts = {}) {
  return {
    ...createDefaultModifiers(opts),
    clipPristine: true,
    format: v => formatDollarAmountWithNegative(v),
    unformat: v => currencyToCentsWithNegative(v),
  };
}
