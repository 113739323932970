import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  textMessage: {
    id: 'text-message',
  },
  textConfirmation: {
    id: 'text-confirmation',
  },
};
const CONFIRM_TEXT = 'Yes';
const CANCEL_TEXT = 'No';
const WARNING_MESSAGE =
  'You are adding charges from multiple locations to this invoice. When generating claims for this invoice, the location of the first charge will be used.';
const WARNING_CONFIRMATION = 'Do you want to continue?';

class NebPopupLocationsWarning extends NebPopup {
  static get properties() {
    return { hasInvoices: Boolean };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.model = [];
    this.hasInvoices = false;

    this.title = 'Warning';
    this.message = WARNING_MESSAGE;
    this.confirmation = WARNING_CONFIRMATION;
  }

  __initHandlers() {
    super.initHandlers();
    this.__handlers = {
      confirm: () => this.onClose(this.hasInvoices || true),
      cancel: () => this.onClose(false),
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.hasInvoices = this.model.invoices && this.model.invoices.length > 0;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 450px;
          font-family: ${CSS_FONT_FAMILY};
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .container {
          height: auto;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: 30px;
        }

        .message {
          flex: 1 0 0;
        }

        .confirmation {
          flex: 1 0 0;
          margin-top: 12px;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        :host([layout='small']) .button:not(:last-child) {
          margin: 0 10px 12px 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.textMessage.id}" class="message">
          ${this.message}
        </div>

        <div id="${ELEMENTS.textConfirmation.id}" class="confirmation">
          ${this.confirmation}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            .label="${CONFIRM_TEXT}"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            .label="${CANCEL_TEXT}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-locations-warning', NebPopupLocationsWarning);
