import { css, unsafeCSS } from 'lit';

const FRONT_BODY_STYLES = [
  {
    top: css`-5px`,
    left: css`77px`,
  },
  {
    top: css`22px`,
    left: css`61px`,
  },
  {
    top: css`22px`,
    left: css`94px`,
  },
  {
    top: css`47px`,
    left: css`51px`,
  },
  {
    top: css`47px`,
    left: css`77px`,
  },
  {
    top: css`47px`,
    left: css`103px`,
  },
  {
    top: css`60px`,
    left: css`31px`,
  },
  {
    top: css`60px`,
    left: css`123px`,
  },
  {
    top: css`84px`,
    left: css`35px`,
  },
  {
    top: css`84px`,
    left: css`120px`,
  },
  {
    top: css`108px`,
    left: css`25px`,
  },
  {
    top: css`108px`,
    left: css`130px`,
  },
  {
    top: css`129px`,
    left: css`16px`,
  },
  {
    top: css`129px`,
    left: css`139px`,
  },
  {
    top: css`151px`,
    left: css`7px`,
  },
  {
    top: css`151px`,
    left: css`148px`,
  },
  {
    top: css`175px`,
    left: css`-1px`,
  },
  {
    top: css`175px`,
    left: css`156px`,
  },
  {
    top: css`69px`,
    left: css`53px`,
  },
  {
    top: css`69px`,
    left: css`77px`,
  },
  {
    top: css`69px`,
    left: css`101px`,
  },
  {
    top: css`92px`,
    left: css`77px`,
  },
  {
    top: css`115px`,
    left: css`55px`,
  },
  {
    top: css`125px`,
    left: css`77px`,
  },
  {
    top: css`115px`,
    left: css`100px`,
  },
  {
    top: css`151px`,
    left: css`50px`,
  },
  {
    top: css`151px`,
    left: css`77px`,
  },
  {
    top: css`151px`,
    left: css`105px`,
  },
  {
    top: css`182px`,
    left: css`59px`,
  },
  {
    top: css`182px`,
    left: css`96px`,
  },
  {
    top: css`227px`,
    left: css`55px`,
  },
  {
    top: css`227px`,
    left: css`100px`,
  },
  {
    top: css`258px`,
    left: css`51px`,
  },
  {
    top: css`258px`,
    left: css`104px`,
  },
  {
    top: css`292px`,
    left: css`48px`,
  },
  {
    top: css`292px`,
    left: css`107px`,
  },
  {
    top: css`314px`,
    left: css`37px`,
  },
  {
    top: css`314px`,
    left: css`119px`,
  },
];

const BACK_BODY_STYLES = [
  {
    top: css`-5px`,
    left: css`73px`,
  },
  {
    top: css`28px`,
    left: css`51px`,
  },
  {
    top: css`28px`,
    left: css`73px`,
  },
  {
    top: css`28px`,
    left: css`95px`,
  },
  {
    top: css`49px`,
    left: css`51px`,
  },
  {
    top: css`49px`,
    left: css`73px`,
  },
  {
    top: css`49px`,
    left: css`95px`,
  },
  {
    top: css`59px`,
    left: css`29px`,
  },
  {
    top: css`59px`,
    left: css`117px`,
  },
  {
    top: css`84px`,
    left: css`30px`,
  },
  {
    top: css`84px`,
    left: css`116px`,
  },
  {
    top: css`106px`,
    left: css`22px`,
  },
  {
    top: css`106px`,
    left: css`124px`,
  },
  {
    top: css`127px`,
    left: css`13px`,
  },
  {
    top: css`127px`,
    left: css`134px`,
  },
  {
    top: css`150px`,
    left: css`3px`,
  },
  {
    top: css`150px`,
    left: css`143px`,
  },
  {
    top: css`174px`,
    left: css`-5px`,
  },
  {
    top: css`174px`,
    left: css`151px`,
  },
  {
    top: css`71px`,
    left: css`51px`,
  },
  {
    top: css`71px`,
    left: css`73px`,
  },
  {
    top: css`71px`,
    left: css`95px`,
  },
  {
    top: css`93px`,
    left: css`51px`,
  },
  {
    top: css`93px`,
    left: css`73px`,
  },
  {
    top: css`93px`,
    left: css`95px`,
  },
  {
    top: css`116px`,
    left: css`51px`,
  },
  {
    top: css`116px`,
    left: css`73px`,
  },
  {
    top: css`116px`,
    left: css`95px`,
  },
  {
    top: css`138px`,
    left: css`60px`,
  },
  {
    top: css`138px`,
    left: css`87px`,
  },
  {
    top: css`155px`,
    left: css`42px`,
  },
  {
    top: css`155px`,
    left: css`73px`,
  },
  {
    top: css`155px`,
    left: css`104px`,
  },
  {
    top: css`172px`,
    left: css`60px`,
  },
  {
    top: css`172px`,
    left: css`87px`,
  },
  {
    top: css`197px`,
    left: css`54px`,
  },
  {
    top: css`197px`,
    left: css`93px`,
  },
  {
    top: css`226px`,
    left: css`51px`,
  },
  {
    top: css`226px`,
    left: css`95px`,
  },
  {
    top: css`258px`,
    left: css`46px`,
  },
  {
    top: css`258px`,
    left: css`100px`,
  },
  {
    top: css`290px`,
    left: css`43px`,
  },
  {
    top: css`290px`,
    left: css`103px`,
  },
  {
    top: css`313px`,
    left: css`33px`,
  },
  {
    top: css`313px`,
    left: css`113px`,
  },
];

export const renderBodyButtonStyles = () =>
  [...FRONT_BODY_STYLES, ...BACK_BODY_STYLES].map(
    ({ top, left }, index) => css`
      .option-${unsafeCSS(`${index}`)} {
        top: ${top};
        left: ${left};
      }
    `,
  );
