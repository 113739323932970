import '../../neb-material-design/src/components/neb-md-select';
import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  errorIcon: {
    id: 'error-icon',
  },
  previousAppointmentText: {
    id: 'previous-appointment-text',
  },
  newAppointmentText: {
    id: 'new-appointment-text',
  },
};

const TITLE = 'Edit Appointment';

class NebPopupAppointmentDragDropConfirm extends NebPopup {
  static get properties() {
    return {
      model: Object,
      __appointment: Object,
      __newAppointment: Object,
      __error: String,
    };
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = TITLE;
    this.__error = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      confirm: () => {
        this.onClose(true);
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.title = `${this.model.title} Appointment`;
      this.__error = !this.model.isValidAvailability;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 650px;
          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: ${CSS_SPACING};
        }

        .middle {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .error {
          display: flex;
          font-style: italic;
        }

        .left {
          max-width: 265px;
          flex: 0 1 auto;
          overflow: hidden;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
        }

        .right {
          width: 265px;
          flex: 0 0 auto;
          overflow: hidden;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
        }

        .highlight {
          font-weight: bold;
          color: green;
        }

        .error-icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }

        .icon {
          width: 40px;
          height: 40px;
        }

        .text-container {
          display: flex;
          flex-direction: row;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  __renderPreviousAppointmentDetails() {
    return html`
      <p>
        ${this.model.appointment.patient}<br />
        ${this.model.appointment.date} <br />
        ${this.model.appointment.durationDisplay}<br />
        ${this.model.appointment.provider &&
        html`
          <br />
          ${this.model.appointment.provider}
        `} <br />
        ${this.model.appointment.location}<br />
        ${this.model.appointment.appointmentType}<br />
        ${this.model.appointment.room}
      </p>
    `;
  }

  __isDifferent(key) {
    return this.model.appointment[key] !== this.model.newAppointment[key];
  }

  __renderNewAppointmentDetails() {
    return html`
      <p>
        ${Object.keys(this.model.newAppointment).map(key => {
          if (!this.model.newAppointment[key]) return '';

          return html`
            <span class="${this.__isDifferent(key) ? 'highlight' : ''}">
              ${this.model.newAppointment[key]} </span
            ><br />
            ${key === 'durationDisplay' ? html` <br /> ` : ''}
          `;
        })}
      </p>
    `;
  }

  __renderValidationError() {
    return this.__error
      ? html`
          <div class="error">
            <neb-icon
              id="${ELEMENTS.errorIcon.id}"
              class="error-icon"
              icon="neb:notification"
            >
            </neb-icon>

            <span>
              Change conflicts with configured availability, do you want to
              proceed?
            </span>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="text-container">
          <div id="${ELEMENTS.previousAppointmentText.id}" class="left">
            <p><u>Previous Appointment</u></p>

            ${this.__renderPreviousAppointmentDetails()}
          </div>

          <div class="middle">
            <neb-icon class="icon" icon="neb:forwardArrow"> </neb-icon>
          </div>

          <div id="${ELEMENTS.newAppointmentText.id}" class="right">
            <p><u>New Appointment</u></p>

            ${this.__renderNewAppointmentDetails()}
          </div>
        </div>

        ${this.__renderValidationError()}

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="Confirm"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-appointment-drag-drop-confirm',
  NebPopupAppointmentDragDropConfirm,
);
