import '../neb-header';
import '../inputs/neb-textfield';
import '../controls/neb-switch';
import '../controls/neb-button-action';
import '../tables/neb-table-payer-groups-select-payers';

import { isRequired } from '@neb/form-validators';
import { css, html } from 'lit';

import {
  ACTIVE,
  ASSOCIATE_PAYER,
  PAYER_GROUP,
  PAYER_GROUP_NAME_LABEL,
} from '../../../../../src/utils/user-message';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import NebForm, { ELEMENTS as ELEMENTS_FORM } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_FORM,
  groupHeader: {
    id: 'general',
    title: 'General',
  },
  associatedPayers: {
    id: 'associated-payers',
    title: 'Associated Payers',
  },
  associatedPayersTable: { id: 'associated-payers-table' },
  name: { id: 'name' },
  activeSwitch: { id: 'active' },
  addButton: { id: 'add-button', label: ASSOCIATE_PAYER },
};

export default class NebFormPayerGroups extends NebForm {
  static get properties() {
    return {
      payers: Array,
      __associatedPayers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow: unset;
        }

        .layout {
          overflow: auto;
        }

        .grid-group {
          grid-template-columns: repeat(6, 1fr);
        }

        .span-3 {
          grid-column: span 3;
        }
      `,
    ];
  }

  static createModel() {
    return {
      active: true,
      name: '',
      payerPlanIds: [],
    };
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired('Required')],
        payerPlanIds: {
          createItem: () => '',
        },
      },
    };
  }

  initState() {
    super.initState();

    this.payers = [];
    this.__associatedPayers = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      onRemovePayer: (name, _, index) => {
        this.formService.removeItem(name, index);
        this.__reloadPayerPlans();
      },
      addAssociatedPayer: async () => {
        const selectedPayers = await openOverlay(OVERLAY_KEYS.ASSOCIATE_PAYER, {
          associatedPayers: this.__associatedPayers,
          payers: this.payers,
          parentItem: PAYER_GROUP.toLowerCase(),
        });

        if (selectedPayers) {
          selectedPayers.forEach(({ id }, index) => {
            if (index > this.state.payerPlanIds.length - 1) {
              this.formService.addItem('payerPlanIds', index);
            }
            this.formService.apply(`payerPlanIds.${index}`, id);
          });

          this.__reloadPayerPlans();
        }
      },
      removeAll: () => {
        const { payerPlanIds } = this.formService.build();
        payerPlanIds.forEach(_ =>
          this.handlers.onRemovePayer('payerPlanIds', '', 0),
        );
      },
    };
  }

  __reloadPayerPlans() {
    this.__associatedPayers = this.state.payerPlanIds.map(id =>
      this.payers.find(payer => payer.id === id),
    );
  }

  update(changedProps) {
    super.update(changedProps);

    if (
      (changedProps.has('model') && this.model) ||
      changedProps.has('payers')
    ) {
      this.__reloadPayerPlans();
    }
  }

  __renderPayersTable() {
    return html`
      <div class="grid grid-lean">
        <neb-header
          id="${ELEMENTS.associatedPayers.id}"
          class="section-header"
          .label="${ELEMENTS.associatedPayers.title}"
        ></neb-header>

        <div class="grid grid-auto-left">
          <neb-button-action
            id="${ELEMENTS.addButton.id}"
            label="${ELEMENTS.addButton.label}"
            class="pad"
            .onClick="${this.handlers.addAssociatedPayer}"
          ></neb-button-action>
        </div>

        <neb-table-payer-groups-select-payers
          id="${ELEMENTS.associatedPayersTable.id}"
          name="payerPlanIds"
          layout="${this.layout}"
          .model="${this.__associatedPayers}"
          .showRemoveButton="${true}"
          .onRemove="${this.handlers.onRemovePayer}"
          .showRemoveAll="${true}"
          .onRemoveAll="${this.handlers.removeAll}"
        ></neb-table-payer-groups-select-payers>
      </div>
    `;
  }

  __renderGroupSection() {
    return html`
      <neb-header
        id="${ELEMENTS.groupHeader.id}"
        class="section-header"
        .label="${ELEMENTS.groupHeader.title}"
      ></neb-header>

      <div class="grid grid-group">
        <neb-textfield
          id="${ELEMENTS.name.id}"
          class="span-3"
          label="${PAYER_GROUP_NAME_LABEL}"
          name="name"
          helper="Required"
          maxLength="255"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>

      ${this.__renderPayersTable()}

      <div class="grid">
        <neb-switch
          id="${ELEMENTS.activeSwitch.id}"
          name="active"
          label="${ACTIVE}"
          .onChange="${this.handlers.change}"
          ?on="${this.state.active}"
        ></neb-switch>
      </div>
    `;
  }

  renderContent() {
    return html` ${this.__renderGroupSection()} `;
  }
}

customElements.define('neb-form-payer-groups', NebFormPayerGroups);
