import '@polymer/polymer/polymer-legacy';

import { html } from 'lit';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebDialogStyles = () => html`
  <style include="neb-button-styles">
    .neb-dialog-modal  {
      background-color: var(--neb-color-light-primary);
      box-shadow: 0 2px 20px #000000;
      padding: 24px;
    }
    .neb-dialog-modal h2 {
      font-size: var(--neb-font-size-header);
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
    .neb-dialog-modal .neb-dialog-body {
      var(--neb-font-size-body);
      margin: 0;
      padding: 14px 0 33px 0;
    }
    .neb-dialog-modal .neb-dialog-buttons {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }
    .neb-dialog-modal .neb-dialog-buttons paper-button {
      margin: 10px 15px 0 0;
    }

    .neb-dialog-close-button {
      color: var(--neb-color-grey-1);
      width: 100%;
      height: 100%;
      padding: 0;
      position: absolute;
      left: 4px;
    }

    .neb-dialog-close-button-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: visible;
    }

    .neb-dialog-save-button {
      margin: 0 10px 0 0;
    }
  </style>
`;
_documentContainer.innerHTML = `
<dom-module id="neb-dialog-styles">
  <template>
    ${nebDialogStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
