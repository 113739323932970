import { navigate } from '@neb/router';
import { css, html } from 'lit';

import Table, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { summaryStyles } from '../../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../styles';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  payerLinks: { selector: '[id^=payer-link-]' },
};

const CONFIG = [
  {
    key: 'createdAt',
    label: 'Date/Time Split',
    flex: css`1 0 0`,
  },
  {
    key: 'userName',
    label: 'User',
    flex: css`1 0 0`,
  },
  {
    key: 'actionType',
    label: 'Action',
    flex: css`1 0 0`,
  },
  {
    key: 'paymentNumber',
    label: 'Payment ID',
    flex: css`1 0 0`,
  },
  {
    key: 'payerName',
    label: 'Payer',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'preValue',
    label: 'Previous Amount',
    flex: css`1 0 0`,
  },
  {
    key: 'postValue',
    label: 'New Amount',
    flex: css`1 0 0`,
  },
];

class NebTableLedgerSplitHistory extends Table {
  static get properties() {
    return {
      isPatientPayment: Boolean,
      model: Array,
      config: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      summaryStyles,
      css`
        .payer-name-text {
          margin-right: 6px;
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .link {
          display: flex;
          align-items: center;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          cursor: pointer;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = CONFIG;
    this.emptyMessage = 'No history';
    this.isPatientPayment = true;
    this.model = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickPayer: rowIndex => {
        navigate(`/patients/${this.model[rowIndex].patientId}/ledger`);
      },
    };
  }

  renderDataCell(value, columnConfig, rowIndex) {
    if (columnConfig.key === 'payerName' && this.isPatientPayment) {
      return html`
        <div
          class="link"
          @click="${() => this.handlers.clickPayer(rowIndex)}"
          id="payer-link-${rowIndex}"
        >
          <div class="text-highlight payer-name-text">${value}</div>

          <neb-icon class="icon" icon="neb:open"></neb-icon>
        </div>
      `;
    }

    return super.renderDataCell(value, columnConfig, rowIndex);
  }
}

customElements.define(
  'neb-table-ledger-split-history',
  NebTableLedgerSplitHistory,
);
