import '../controls/neb-switch';
import '../controls/neb-tab-group';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-select';
import '../tables/neb-table';
import '../tables/neb-table-charge-fee-schedules';
import '../neb-pagination';

import FormService from '@neb/form-service';
import { atMin, atMax, isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as taxesApi from '../../../../../src/api-clients/tax-rates';
import {
  dosageSelector,
  isDosageInRange,
  NDC_NUMBER_CATEGORY_ITEMS,
  NDC_QUALIFIER_ITEMS,
  NDC_UNIT_OF_MEASUREMENT_ITEMS,
} from '../../../../../src/utils/national-drug-code';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { TYPE, DISPLAY_TYPE } from '../../../../neb-utils/fee-schedule';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { NebModifiers } from '../field-groups/neb-modifiers';

import NebForm from './neb-form';

const isEdiReservedCharacter = () => ({
  error: 'Unsupported Characters: * : ~ ^ |',
  validate: v => {
    const pattern = /[*:|~^]/g;
    const result = v.match(pattern);
    return !(result && result.length);
  },
});

export const ELEMENTS = {
  table: { id: 'table' },
  tabGroup: { id: 'tab-group' },
  procedure: { id: 'procedure' },
  description: { id: 'description' },
  amount: { id: 'amount' },
  modifiers: { id: 'modifiers' },
  taxRate: { id: 'taxRate' },
  switch: { id: 'switch' },
  details: { id: 'details' },
  feeSchedules: { id: 'fee-schedules' },
  pagination: { id: 'pagination' },
  bulkUpdate: { id: 'bulk-update' },
  bulkUpdateAmountTextfield: { id: 'textfield-bulk-update-amount' },
  bulkUpdateAllowedAmountTextfield: { id: 'textfield-bulk-update-allowed' },
  bulkUpdateAmountButton: { id: 'button-bulk-update-amount' },
  bulkUpdateAllowedAmountButton: { id: 'button-bulk-update-allowed' },
  amountFeeCharge: { id: 'amount' },
  amountBulkUpdate: { id: 'bulk-update-amount' },
  allowedAmount: { id: 'allowed-amount' },
  allowedBulkUpdate: { id: 'bulk-update-allowed' },
  checkboxDurable: { id: 'checkbox-durable' },
  checkboxXray: { id: 'checkbox-xray' },
  checkboxSuppress: { id: 'checkbox-suppress' },
  checkboxEPSDT: { id: 'checkbox-EPSDT' },
  checkboxPurchase: { id: 'checkbox-purchase' },
  checkboxTreatmentDate: { id: 'checkbox-treatment-date' },
  checkboxTimedProcedure: { id: 'checkbox-timed-procedure' },
  checkboxIncludeChargeDescription: { id: 'include-charge-description' },
  checkboxNationalDrugCodeEnabled: {
    id: 'checkbox-national-drug-code-enabled',
  },
  selectNationalDrugCodeQualifier: {
    id: 'select-national-drug-code-qualifier',
  },
  textfieldNationalDrugCode: { id: 'textfield-national-drug-code' },
  textfieldNationalDrugCodeDosage: {
    id: 'textfield-national-drug-code-dosage',
  },
  selectNationalDrugCodeUnitOfMeasurement: {
    id: 'select-national-drug-code-unit-of-measurement',
  },
  selectNationalDrugCodeNumberCategory: {
    id: 'select-national-drug-code-number-category',
  },
  textfieldNationalDrugCodeSequenceOrPrescription: {
    id: 'textfield-national-drug-code-sequence-or-prescription',
  },
};

export const TABS = {
  DETAILS: 'details',
  FEE_SCHEDULES: 'fee-schedules',
};

const CHECKBOX_DATA = [
  {
    id: ELEMENTS.checkboxDurable.id,
    name: 'durableMedicalEquipment',
    label: 'Durable Medical Equipment',
  },
  {
    id: ELEMENTS.checkboxXray.id,
    name: 'billWithXrayDate',
    label: 'Bill With X-ray Date (FL 15)',
  },
  {
    id: ELEMENTS.checkboxSuppress.id,
    name: 'suppressFromClaim',
    label: 'Suppress from Claims',
  },
  {
    id: ELEMENTS.checkboxTreatmentDate.id,
    name: 'billWithTreatmentInitiationDate',
    label: 'Bill With Treatment Initiation Date (FL 15)',
  },
  {
    id: ELEMENTS.checkboxPurchase.id,
    name: 'availableForPurchase',
    label: 'Available for Purchase',
  },
  {
    id: ELEMENTS.checkboxEPSDT.id,
    name: 'EPSDTCode',
    label: 'EPSDT Code',
  },
  {
    id: ELEMENTS.checkboxTimedProcedure.id,
    name: 'timedProcedure',
    label: 'Timed Procedure',
  },
  {
    id: ELEMENTS.checkboxIncludeChargeDescription.id,
    name: 'eClaimIncludeChargeDescription',
    label: 'Include Charge Description for Electronic Claims',
  },
  {
    id: ELEMENTS.checkboxNationalDrugCodeEnabled.id,
    name: 'nationalDrugCodeEnabled',
    label: 'National Drug Code (NDC)',
  },
];

const EMPTY_ITEM = {
  data: { id: '' },
  label: '',
};

export const ITEM_DEFAULT_TAX = {
  ...EMPTY_ITEM,
  label: 'None',
};

const TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Fee Schedule',
    flex: css`1 0 0`,
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`1 0 0`,
    formatter: v => DISPLAY_TYPE[v],
  },
  {
    key: 'payerPlans',
    label: 'Payer Plans',
    flex: css`1 0 0`,
    formatter: v => v.join(', '),
  },
  {
    key: 'amount',
    label: 'Fee Sch Charge',
    flex: css`0 0 106px`,
  },
  {
    key: 'allowedAmount',
    label: 'Allowed Amount',
    flex: css`0 0 106px`,
  },
  {
    key: 'adjustmentAmount',
    label: 'Adjustment Amount',
    flex: css`0 0 96px`,
  },
  {
    key: 'spacer',
    label: '',
    flex: css`0 0 ${CSS_SPACING}`,
  },
];

export const DUPLICATE_CHARGE_MESSAGE =
  'There is an existing charge in the system with the same procedure code, description and modifiers as this one. Please change the procedure code, description or modifiers to save this charge.';

const ndcFieldsSelector = [
  {
    error: 'Required',
    validate: (value, _, state) => {
      if (state.ndc) {
        return !!value;
      }

      return true;
    },
  },
];

const dosageValidator = [
  {
    error: 'Required',
    validate: (value, _, state) => {
      if (state.nationalDrugCodeEnabled) return !!value;

      return true;
    },
  },
];

function isLessThanOrEqualToFsc() {
  return {
    error: '≤ Fee Sch Chrg',
    validate: (v, keyPath, model) => {
      const index = keyPath.slice(1, 2);
      const { amount } = model.feeScheduleCharges[index];

      return v <= amount;
    },
  };
}

export default class ChargeForm extends NebForm {
  static get properties() {
    return {
      __selectedTab: String,
      __allTaxItems: Array,
      __chargesState: Object,
      __bulkUpdateState: Object,
      __bulkUpdateErrors: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .textfield {
          width: 210px;
        }

        .textarea {
          height: 145px;
        }

        .grid-checkboxes {
          display: grid;
          grid-auto-flow: row dense;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 30px;
          grid-gap: 1rem;
        }

        .grid-ndc-fields {
          display: grid;
          width: 100%;
          column-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .grid-bulk-update {
          grid-gap: 0;
          grid-template-columns: 1fr 96px 30px 96px 30px 96px 30px 10px;
        }

        .field {
          width: 96px;
        }

        .long-textfield {
          width: 65%;
        }

        .icon {
          height: 30px;
          width: 30px;
          margin-bottom: 15px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: '',
      taxId: '',
      procedure: '',
      description: '',
      modifiers: ['', '', '', ''],
      amount: 0,
      active: true,
      EPSDTCode: false,
      eClaimIncludeChargeDescription: false,
      availableForPurchase: false,
      billWithTreatmentInitiationDate: false,
      billWithXrayDate: false,
      timedProcedure: false,
      suppressFromClaim: false,
      durableMedicalEquipment: false,
      feeScheduleCharges: [],
      nationalDrugCodeEnabled: false,
      nationalDrugCodeQualifier: EMPTY_ITEM,
      nationalDrugCode: null,
      nationalDrugCodeDosage: null,
      nationalDrugCodeUnitOfMeasurement: EMPTY_ITEM,
      nationalDrugCodeNumberCategory: EMPTY_ITEM,
      nationalDrugCodeSequenceOrPrescription: null,
    };
  }

  initState() {
    super.initState();

    this.__allTaxItems = [];
    this.__selectedTab = TABS.DETAILS;
    this.__navItems = [
      {
        id: 'details',
        label: 'Details',
        renderer: () => this.renderDetails(),
      },
      {
        id: 'fee-schedules',
        label: 'Fee Schedules',
        renderer: () => this.renderFeeSchedules(),
      },
    ];

    this.__chargesState = CollectionService.createModel();
    this.__chargesService = new CollectionService({
      onChange: state => {
        this.__chargesState = state;
      },
    });

    this.__initBulkUpdateService();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeNDCField: e => this.formService.apply(e.name, e.value || null),
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      changeTableItem: e => {
        const key = e.name.split('.')[2];
        const keyPath = e.name.split('.');
        const prefix = keyPath[0];
        const localIndex = Number(keyPath[1]);
        const fieldName = keyPath[2];
        const row = this.__chargesState.pageItems[localIndex];
        const index = this.getChargeIndex(row);
        const path = `${prefix}.${index}.${fieldName}`;
        const { pageIndex } = this.__chargesState;
        const otherKey = key === 'amount' ? 'allowedAmount' : 'amount';

        this.formService.apply(path, e.value);
        this.formService.validateKey(
          ['feeScheduleCharges', index, otherKey],
          true,
        );

        this.__updateAdjustmentAmount(
          this.state.feeScheduleCharges[index],
          index,
        );

        this.__syncTableItems();
        this.__chargesService.setPageIndex(pageIndex);
      },
      selectChargesPage: index => this.__chargesService.setPageIndex(index),
      changeBulkUpdate: e => this.__bulkUpdateService.apply(e.name, e.value),
      bulkUpdateAmount: () => {
        this.__bulkUpdate('amount', 'Fee Schedule Charge');
      },
      bulkUpdateAllowedAmount: () =>
        this.__bulkUpdate('allowedAmount', 'Allowed'),
    };
  }

  async load() {
    this.__allTaxItems = (await taxesApi.getTaxRates()).map(data => ({
      label: data.name,
      data,
    }));
  }

  createSelectors() {
    return {
      children: {
        procedure: [isRequired()],
        description: [isRequired(), isEdiReservedCharacter()],
        modifiers: NebModifiers.createSelectors(),
        taxId: selectors.select(this.getTaxItems(), ITEM_DEFAULT_TAX),
        amount: selectors.currency({
          validateRaw: true,
          validators: [
            atMin(0, true, 'Must be greater or equal to $0.00'),
            atMax(99999999, true, 'Must be less than $1,000,000.00'),
          ],
        }),
        feeScheduleCharges: {
          children: {
            $: {
              children: {
                amount: selectors.currency(),
                allowedAmount: selectors.currency({
                  validateRaw: true,
                  validators: [isLessThanOrEqualToFsc()],
                }),
                adjustmentAmount: selectors.currency(),
              },
            },
          },
        },
        nationalDrugCode: [
          {
            error: 'Required',
            validate: v => {
              if (this.state.nationalDrugCodeEnabled) return !!v;
              return true;
            },
          },
        ],
        nationalDrugCodeQualifier: selectors.select(
          NDC_QUALIFIER_ITEMS,
          EMPTY_ITEM,
          {
            validators: [
              {
                error: 'Required',
                validate: v => {
                  if (this.state.nationalDrugCodeEnabled) {
                    return v.label !== '';
                  }
                  return true;
                },
              },
            ],
          },
        ),
        nationalDrugCodeDosage: dosageSelector({
          validators: [
            ...ndcFieldsSelector,
            ...dosageValidator,
            isDosageInRange(),
          ],
        }),
        nationalDrugCodeUnitOfMeasurement: selectors.select(
          NDC_UNIT_OF_MEASUREMENT_ITEMS,
          EMPTY_ITEM,
          {
            validators: [
              {
                error: 'Required',
                validate: v => {
                  if (this.state.nationalDrugCodeEnabled) {
                    return v.label !== '';
                  }
                  return true;
                },
              },
            ],
          },
        ),
        nationalDrugCodeNumberCategory: selectors.select(
          NDC_NUMBER_CATEGORY_ITEMS,
          EMPTY_ITEM,
        ),
      },
    };
  }

  __initBulkUpdateService() {
    const MODIFIERS = selectors.currency({
      validateRaw: true,
      validators: [isRequired('Over $0.00')],
    });

    this.__bulkUpdateService = new FormService(
      {
        amount: 0,
        allowedAmount: 0,
      },
      {
        children: {
          amount: MODIFIERS,
          allowedAmount: MODIFIERS,
        },
      },
      (_, state, errors) => {
        this.__bulkUpdateState = state;
        this.__bulkUpdateErrors = errors;
      },
    );
  }

  __updateAdjustmentAmount(item, index) {
    const amount = currencyToCents(item.amount);
    const allowedAmount = currencyToCents(item.allowedAmount);
    const adjustmentAmount = centsToCurrency(amount - allowedAmount);

    this.formService.apply(
      `feeScheduleCharges.${index}.adjustmentAmount`,
      adjustmentAmount,
    );
  }

  __updateItem(item, key, value) {
    const index = this.getChargeIndex(item);
    const otherKey = key === 'amount' ? 'allowedAmount' : 'amount';
    const updatedItem = { ...item, [key]: value };

    this.formService.apply(`feeScheduleCharges.${index}.${key}`, value);
    this.formService.validateKey(['feeScheduleCharges', index, key], true);
    this.formService.validateKey(['feeScheduleCharges', index, otherKey], true);
    this.__updateAdjustmentAmount(updatedItem, index);
  }

  async __bulkUpdate(key, label) {
    const value = this.__bulkUpdateState[key];
    const accepted = await this.__openWarningPopup(
      `Bulk Update - ${label}`,
      `This action will update the ${label} amounts for all currently displayed charges to ${value}.`,
    );

    if (accepted) {
      const { pageIndex } = this.__chargesState;

      this.__chargesService.getFilteredItems().forEach(item => {
        switch (key) {
          case 'amount':
            if (item.type !== TYPE.PERCENT) {
              this.__updateItem(item, key, value);
            }
            break;

          case 'allowedAmount':
            this.__updateItem(item, key, value);
            break;

          default:
        }
      });

      this.__syncTableItems();
      this.__chargesService.setPageIndex(pageIndex);
    }
  }

  __openWarningPopup(title, message) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title,
      confirmText: 'Yes',
      cancelText: 'No',
      message: html`
        <p>${message}</p>
        <p>Are you sure that you want to proceed?</p>
      `,
    });
  }

  __processDuplicateChargeError() {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Duplicate Charge',
      message: DUPLICATE_CHARGE_MESSAGE,
    });
  }

  __syncTableItems() {
    this.__chargesService.setItems([...this.state.feeScheduleCharges]);
  }

  getChargeIndex(localItem) {
    return this.__chargesState.allItems.findIndex(c => c.id === localItem.id);
  }

  getTaxItems() {
    const items = this.__allTaxItems.filter(
      item => item.data.active || item.data.id === this.model.taxId,
    );

    return [ITEM_DEFAULT_TAX, ...items];
  }

  update(changedProps) {
    if (changedProps.has('savingError') && this.savingError) {
      this.__processDuplicateChargeError();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__syncTableItems();
    }
  }

  renderTabs() {
    return this.isEditing()
      ? html`
          <neb-tab-group
            id="${ELEMENTS.tabGroup.id}"
            class="tabs"
            .selectedId="${this.__selectedTab}"
            .items="${this.__navItems}"
            .onSelect="${this.handlers.selectTab}"
          ></neb-tab-group>
        `
      : '';
  }

  renderFields() {
    return html`
      <neb-textfield
        id="${ELEMENTS.procedure.id}"
        class="textfield"
        label="Procedure"
        helper="Required"
        name="procedure"
        maxLength="9"
        .value="${this.state.procedure}"
        .error="${this.errors.procedure}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textarea
        id="${ELEMENTS.description.id}"
        class="textarea"
        label="Description"
        helper="Required"
        name="description"
        maxLength="500"
        showCount
        .value="${this.state.description}"
        .error="${this.errors.description}"
        .onChange="${this.handlers.change}"
      ></neb-textarea>

      <div class="grid grid-auto-left">
        <neb-modifiers
          id="${ELEMENTS.modifiers.id}"
          label="Modifiers"
          helper=" "
          name="modifiers"
          .errors="${this.errors.modifiers}"
          .values="${this.state.modifiers}"
          .onChange="${this.handlers.change}"
        ></neb-modifiers>
      </div>

      <neb-textfield
        id="${ELEMENTS.amount.id}"
        class="textfield"
        label="Amount"
        helper=" "
        name="amount"
        maxLength="15"
        .value="${this.state.amount}"
        .error="${this.errors.amount}"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.taxRate.id}"
        class="textfield"
        label="Tax Rate"
        helper=" "
        name="taxId"
        .items="${this.getTaxItems()}"
        .value="${this.state.taxId}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  renderCheckboxes() {
    return CHECKBOX_DATA.map(
      item => html`
        <neb-checkbox
          id="${item.id}"
          class="spacer-bottom"
          .name="${item.name}"
          .label="${item.label}"
          .onChange="${this.handlers.change}"
          ?checked="${!!this.state[item.name]}"
        ></neb-checkbox>
      `,
    );
  }

  renderNDCFields() {
    return this.state.nationalDrugCodeEnabled
      ? html`
          <div class="grid-ndc-fields">
            <div class="grid">
              <neb-select
                id="${ELEMENTS.selectNationalDrugCodeQualifier.id}"
                helper="Required"
                name="nationalDrugCodeQualifier"
                .label="${'NDC Qualifier'}"
                .items="${NDC_QUALIFIER_ITEMS}"
                .value="${this.state.nationalDrugCodeQualifier}"
                .error="${this.errors.nationalDrugCodeQualifier}"
                .onChange="${this.handlers.changeNDCField}"
              ></neb-select>
            </div>

            <div class="grid">
              <neb-textfield
                id="${ELEMENTS.textfieldNationalDrugCode.id}"
                label="NDC Code"
                helper="Required"
                name="nationalDrugCode"
                .value="${this.state.nationalDrugCode}"
                .error="${this.errors.nationalDrugCode}"
                .onChange="${this.handlers.changeNDCField}"
              ></neb-textfield>
            </div>

            <div class="grid">
              <neb-textfield
                id="${ELEMENTS.textfieldNationalDrugCodeDosage.id}"
                label="Dosage"
                helper="Required"
                name="nationalDrugCodeDosage"
                .value="${this.state.nationalDrugCodeDosage}"
                .error="${this.errors.nationalDrugCodeDosage}"
                .onChange="${this.handlers.changeNDCField}"
              ></neb-textfield>
            </div>

            <div class="grid">
              <neb-select
                id="${ELEMENTS.selectNationalDrugCodeUnitOfMeasurement.id}"
                helper="Required"
                name="nationalDrugCodeUnitOfMeasurement"
                .label="${'Unit of Measurement'}"
                .items="${NDC_UNIT_OF_MEASUREMENT_ITEMS}"
                .value="${this.state.nationalDrugCodeUnitOfMeasurement}"
                .error="${this.errors.nationalDrugCodeUnitOfMeasurement}"
                .onChange="${this.handlers.change}"
              ></neb-select>
            </div>

            <div class="grid">
              <neb-select
                id="${ELEMENTS.selectNationalDrugCodeNumberCategory.id}"
                name="nationalDrugCodeNumberCategory"
                .label="${'Number Category'}"
                .items="${NDC_NUMBER_CATEGORY_ITEMS}"
                .value="${this.state.nationalDrugCodeNumberCategory}"
                .onChange="${this.handlers.change}"
              ></neb-select>
            </div>

            <div class="grid">
              <neb-textfield
                id="${ELEMENTS.textfieldNationalDrugCodeSequenceOrPrescription
                  .id}"
                name="nationalDrugCodeSequenceOrPrescription"
                label="Link Sequence or Prescription Number"
                .value="${this.state.nationalDrugCodeSequenceOrPrescription}"
                .onChange="${this.handlers.changeNDCField}"
              ></neb-textfield>
            </div>
          </div>
        `
      : '';
  }

  renderDetails() {
    return html`
      <div id="${ELEMENTS.details.id}" class="grid">
        ${this.renderFields()}

        <div class="grid grid-auto-left">
          <div class="grid grid-checkboxes">${this.renderCheckboxes()}</div>
        </div>

        ${this.renderNDCFields()}

        <neb-switch
          id="${ELEMENTS.switch.id}"
          label="Active"
          name="active"
          .onChange="${this.handlers.change}"
          ?on="${this.state.active}"
        ></neb-switch>
      </div>
    `;
  }

  renderBulkUpdate() {
    const state = this.__bulkUpdateState;
    const errors = this.__bulkUpdateErrors;
    const noCharges = !this.__chargesState.allItems.length;

    const disableAmountButton = noCharges || errors.amount;

    const disableAllowedAmountButton = noCharges || errors.allowedAmount;

    return html`
      <div id="${ELEMENTS.bulkUpdate.id}" class="grid grid-bulk-update">
        <span class="label">
          Bulk Update Fee Schedule Charge and Allowed Amounts
        </span>

        <neb-textfield
          id="${ELEMENTS.bulkUpdateAmountTextfield.id}"
          class="field"
          name="amount"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${state.amount}"
          .error="${errors.amount}"
          .onChange="${this.handlers.changeBulkUpdate}"
          ?disabled="${noCharges}"
        ></neb-textfield>

        <neb-button-icon
          id="${ELEMENTS.bulkUpdateAmountButton.id}"
          class="icon"
          name="amount"
          icon="neb:updateAll"
          .onClick="${this.handlers.bulkUpdateAmount}"
          ?disabled="${disableAmountButton}"
        ></neb-button-icon>

        <neb-textfield
          id="${ELEMENTS.bulkUpdateAllowedAmountTextfield.id}"
          class="field"
          name="allowedAmount"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${state.allowedAmount}"
          .error="${errors.allowedAmount}"
          .onChange="${this.handlers.changeBulkUpdate}"
          ?disabled="${noCharges}"
        ></neb-textfield>

        <neb-button-icon
          id="${ELEMENTS.bulkUpdateAllowedAmountButton.id}"
          class="icon"
          name="allowedAmount"
          icon="neb:updateAll"
          .onClick="${this.handlers.bulkUpdateAllowedAmount}"
          ?disabled="${disableAllowedAmountButton}"
        ></neb-button-icon>
      </div>
    `;
  }

  renderPagination() {
    return this.__chargesState.pageCount > 1
      ? html`
          <div class="grid grid-auto-right pad">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              .pageCount="${this.__chargesState.pageCount}"
              .currentPage="${this.__chargesState.pageIndex}"
              .onPageChanged="${this.handlers.selectChargesPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderFeeSchedules() {
    const tableItems = this.state.feeScheduleCharges;
    const tableErrors = this.__chargesState.pageItems.map(item => {
      const index = tableItems.findIndex(fsc => fsc.id === item.id);

      return this.errors.feeScheduleCharges[index];
    });

    return html`
      ${this.renderBulkUpdate()}

      <neb-table-charge-fee-schedules
        id="${ELEMENTS.table.id}"
        name="feeScheduleCharges"
        emptyMessage="No associated fee schedules."
        .layout="${this.layout}"
        .model="${this.__chargesState.pageItems}"
        .errors="${tableErrors}"
        .config="${TABLE_CONFIG}"
        .onChange="${this.handlers.changeTableItem}"
      ></neb-table-charge-fee-schedules>

      ${this.renderPagination()}
    `;
  }

  renderSelectedTab() {
    const item = this.__navItems.find(item => item.id === this.__selectedTab);

    return item.renderer();
  }

  renderContent() {
    return html` ${this.renderTabs()} ${this.renderSelectedTab()} `;
  }
}
customElements.define('neb-form-charge', ChargeForm);
