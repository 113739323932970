import '../../../neb-pagination';
import '../../../neb-text';
import '../../../tables/neb-table-ledger-activity-encounters';
import '../../../neb-loading-overlay';

import { html, css, LitElement, unsafeCSS } from 'lit';
import moment from 'moment-timezone';

import { STATUS } from '../../../../../../../src/components/filters/neb-filters-activity-encounters';
import { getAppointmentTypes } from '../../../../../../neb-api-client/src/appointment-types';
import { getPatientLedgerActivityEncounters } from '../../../../../../neb-api-client/src/patient-ledger-activity-api-client';
import {
  getBillingPermissions,
  getChartingPermissions,
} from '../../../../../../neb-api-client/src/permissions-api-client';
import { getProviderUsers } from '../../../../../../neb-api-client/src/practice-users-api-client';
import { openError } from '../../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../../neb-utils/formatters';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../../neb-utils/services/fetch';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import { SORT_DIR } from '../../../tables/neb-table';

const HEADER_TEXT_PRACTICE =
  'Review invoice and billing status for encounters and perform updates.';

const HEADER_TEXT_PATIENT =
  'Review invoice and billing status by patient encounter and perform updates.';

export const ELEMENTS = {
  content: { id: 'content' },
  encountersTable: { id: 'encounters' },
  filters: { id: 'filters' },
  header: { id: 'header' },
  paginationControl: { id: 'pagination-control' },
  loadingOverlay: { id: 'loading-overlay' },
};

export const MENU_HEIGHT = 416;

const formatDateQuery = model => {
  let dateFrom = model.serviceDate.from
    ? model.serviceDate.from.toISOString()
    : null;

  let dateTo = model.serviceDate.to ? model.serviceDate.to.toISOString() : null;

  if (model.lastDaysSeen) {
    dateFrom = parseDate()
      .startOf('day')
      .subtract(model.lastDaysSeen, 'days')
      .toISOString();

    dateTo = parseDate().endOf('day').toISOString();
  }

  return { dateFrom, dateTo };
};

const formatOutstandingBalanceQuery = ({ min, max }) => ({
  balanceFrom: min || min === 0 ? min : null,
  balanceTo: max || max === 0 ? max : null,
});

const calculateStatus = ({ postedChargeCount, unpostedChargeCount }) => {
  if (!postedChargeCount) {
    return STATUS.NONE;
  }

  if (postedChargeCount && unpostedChargeCount) {
    return STATUS.SOME;
  }

  return STATUS.ALL;
};

const calculateOutstandingBalance = ({ groups }) => {
  if (!groups) return 0;

  return groups.reduce(
    (sum, g) => sum + g.payerResponsibility + g.patientResponsibility,
    0,
  );
};

const getNullOrValue = (model, key) => model[key] || null;
const getNullOrValues = (model, key) => (model[key].length ? model[key] : null);

class NebLedgerActivityEncountersPage extends LitElement {
  static get properties() {
    return {
      __locations: Array,
      __defaultLocationId: String,
      __pageItems: Array,
      __pageCount: Number,
      __pageIndex: Number,
      __marginBottom: Number,
      __chartingPermission: Boolean,
      __loading: Boolean,

      includePatient: {
        reflect: true,
        type: Boolean,
      },

      layout: String,
      patientId: String,
      cases: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          position: relative;
        }

        .header {
          margin: ${CSS_SPACING};
        }

        .table {
          border-radius: 5px;
          padding: ${CSS_SPACING} 0;
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .text {
          display: flex;
          flex-direction: column;
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__appointmentTypes = [];
    this.__locations = [];
    this.__pageCount = 0;
    this.__pageIndex = 0;
    this.__pageItems = [];
    this.__patients = [];
    this.__providers = [];
    this.__defaultLocationId = '';
    this.__marginBottom = 0;
    this.__chartingPermission = false;
    this.__billingPermission = false;
    this.__loading = false;
    this.__hasAppliedFilters = false;

    this.includePatient = false;
    this.layout = '';
    this.patientId = '';
    this.cases = [];

    this.onReloadData = () => {};
  }

  __getNullOrValueModel(model) {
    return {
      chargeStatus: getNullOrValue(model, 'status'),
      invoiceNumber: getNullOrValue(model, 'invoiceNumber'),
      encounterNumber: getNullOrValue(model, 'encounterNumber'),
      providerIds: getNullOrValues(model, 'providerIds'),
      caseIds: getNullOrValues(model, 'caseIds'),
    };
  }

  __getLocationIds(model) {
    const selectedLocationIds = getNullOrValues(model, 'locations');
    return !selectedLocationIds && this.__locations.length
      ? this.__locations.map(({ id }) => id)
      : selectedLocationIds;
  }

  __initHandlers() {
    this.handlers = {
      pageChanged: index => {
        this.__fetchService.setPageIndex(index);
        this.__pageIndex = index;
      },

      reloadData: async () => {
        await this.fetch();
        return this.onReloadData();
      },

      filter: (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        const { dateFrom, dateTo } = formatDateQuery(model);
        const { balanceFrom, balanceTo } = formatOutstandingBalanceQuery(
          model.outstandingBalance,
        );

        const formattedModel = {
          patientId: model.patientId || this.patientId || null,
          dateFrom,
          dateTo,
          balanceFrom,
          balanceTo,
          ...this.__getNullOrValueModel(model),
          locationIds: this.__getLocationIds(model),
        };

        Object.entries(formattedModel).forEach(([filterName, value]) => {
          if (value || value === 0) {
            this.__fetchService.setQuery(filterName, value);
          } else {
            this.__fetchService.unsetQuery(filterName);
          }
        });

        return this.__fetchService.setPageIndex(0);
      },

      editCase: async caseId => {
        const caseItem = this.cases.find(({ data: { id } }) => id === caseId);

        await openOverlay(OVERLAY_KEYS.CASE, {
          item: caseItem.data,
          context: {
            patientId: this.patientId,
            onAuthorizationChange: () => {},
          },
        });

        return this.onReloadData();
      },
    };
  }

  __initServices() {
    this.__fetchService = new FetchService(
      {
        onChange: ({ pageCount, pageIndex, pageItems }) => {
          this.__pageCount = pageCount;
          this.__pageIndex = pageIndex;
          this.__pageItems = pageItems;
        },

        onMapItem: item => {
          const appointmentType = this.__appointmentTypes.find(
            ({ id }) => id === item.appointmentTypeId,
          );

          const provider = this.__providers.find(
            ({ id }) => id === item.providerId,
          );

          const caseItem = this.cases.find(
            ({ data: { id } }) => id === item.caseId,
          );

          const caseName = caseItem
            ? `${caseItem.data.name} - ${
                caseItem.data.onsetSymptomsDate
                  ? moment(caseItem.data.onsetSymptomsDate).format('MM/DD/YYYY')
                  : 'Gradual'
              }`
            : '';

          return {
            ...item,
            status: calculateStatus(item),
            outstandingBalance: calculateOutstandingBalance(item),
            appointmentType: appointmentType ? appointmentType.name : '',
            color: appointmentType ? appointmentType.color : '',
            provider: provider
              ? objToName(provider.name, DEFAULT_NAME_OPTS)
              : '',
            caseName,
          };
        },

        onError: error => {
          this.__loading = false;
          console.error(error);
          store.dispatch(
            openError('An error occurred when fetching encounters'),
          );
        },
      },

      async (...args) => {
        if (this.__isInitialLoadDisabled()) {
          return EMPTY_RESPONSE;
        }

        let encounters;
        this.__loading = true;

        [encounters, this.__providers, { data: this.__appointmentTypes }] =
          await Promise.all([
            getPatientLedgerActivityEncounters(...args),
            getProviderUsers(true),
            getAppointmentTypes(true),
          ]);

        this.__loading = false;
        return encounters;
      },

      {
        pageSize: 10,
        hideInactive: false,
        sortParams: {
          key: 'serviceDate',
          dir: SORT_DIR.DESC,
        },
      },
    );

    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;
        this.__defaultLocationId = defaultLocationId;
      },
    );
  }

  fetch() {
    return this.__fetchService.fetch();
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  __calculateMarginBottom() {
    const { length } = this.__pageItems;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    } else if (length > 2) {
      this.__marginBottom = 0;
    }
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__locationsService.connect();
    super.connectedCallback();
  }

  firstUpdated() {
    this.__chartingPermission = getChartingPermissions();
    this.__billingPermission = getBillingPermissions();
    this.fetch();
  }

  updated(changedProps) {
    if (changedProps.has('__pageItems')) {
      this.__calculateMarginBottom();
    }

    if (changedProps.has('patientId')) {
      if (this.patientId) {
        this.__fetchService.setQuery('patientId', this.patientId);
      } else this.__fetchService.unsetQuery('patientId');
    }

    if (changedProps.has('cases')) {
      this.fetch();
    }
  }

  __buildConfig() {
    return [
      { key: 'color', label: '' },
      { key: 'ellipsis', label: '', flex: css`1 0 0` },
      ...(this.includePatient
        ? [
            {
              key: 'patient',
              label: 'Patient',
              flex: css`4 0 0`,
              truncate: true,
            },
          ]
        : [
            {
              key: 'caseName',
              label: 'Case',
              flex: css`1 0 60px`,
            },
          ]),
      { key: 'serviceDate', label: 'Date', flex: css`2 0 0` },
      ...[
        {
          key: 'locationId',
          label: 'Location',
          flex: css`3 0 0`,
        },
      ],

      { key: 'appointmentType', label: 'Type', flex: css`3 0 0` },
      { key: 'number', label: 'Encounter', flex: css`3 0 0` },
      { key: 'billingDetails', label: 'Billing Details', flex: css`5 0 0` },
      { key: 'carePackage', label: 'Care Pkg', flex: css`2 0 0` },
      { key: 'charges', label: 'Charges', flex: css`3 0 0` },
      { key: 'balance', label: 'Balance', flex: css`3 0 0` },
      { key: 'outstandingBalance', label: 'Outstanding', flex: css`2 0 0` },
    ];
  }

  renderHeader() {
    return html`
      <div class="header">
        <neb-text id="${ELEMENTS.header.id}"
          >${!this.patientId
            ? HEADER_TEXT_PRACTICE
            : HEADER_TEXT_PATIENT}</neb-text
        >
      </div>

      <neb-filters-activity-encounters
        id="${ELEMENTS.filters.id}"
        class="filters"
        .includePatient="${this.includePatient}"
        .patientId="${this.patientId}"
        .locations="${this.__locations}"
        .defaultLocationId="${this.__defaultLocationId}"
        .cases="${this.cases}"
        .onApply="${this.handlers.filter}"
      ></neb-filters-activity-encounters>
    `;
  }

  renderPagination() {
    return this.__pageItems.length
      ? html`
          <neb-pagination
            id="${ELEMENTS.paginationControl.id}"
            class="pagination"
            .debouncerDelay="${300}"
            .currentPage="${this.__pageIndex}"
            .onPageChanged="${this.handlers.pageChanged}"
            .pageCount="${this.__pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  renderTable() {
    return html`
      <div
        id="${ELEMENTS.content.id}"
        style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
      >
        <neb-table-ledger-activity-encounters
          id="${ELEMENTS.encountersTable.id}"
          name="table-encounters"
          .includePatient="${this.includePatient}"
          .model="${this.__pageItems}"
          .config="${this.__buildConfig()}"
          .onPaymentCollected="${this.handlers.reloadData}"
          .onEditCase="${this.handlers.editCase}"
          .onDataChanged="${this.handlers.reloadData}"
          .hasChartingPermission="${this.__chartingPermission}"
          .hasBillingPermission="${this.__billingPermission}"
          .isInitialLoadDisabled="${this.__isInitialLoadDisabled()}"
        ></neb-table-ledger-activity-encounters>
        ${this.renderPagination()}
      </div>
    `;
  }

  render() {
    return html`
      ${this.renderHeader()} ${this.renderTable()}
      <neb-loading-overlay
        id="${ELEMENTS.loadingOverlay.id}"
        .show="${this.__loading}"
      ></neb-loading-overlay>
    `;
  }
}

customElements.define(
  'neb-ledger-activity-encounters-page',
  NebLedgerActivityEncountersPage,
);
