import '../../encounter/neb-encounter-summary-controller';

import { html, css } from 'lit';

import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebOverlayEncounterSummary extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        :host([layout='small']) .content {
          width: 100%;
        }

        .controller {
          height: inherit;
          width: inherit;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-encounter-summary-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .patientId="${this.model.patient.id}"
        .encounterId="${this.model.encounterId}"
        .providers="${this.model.providers}"
        .appointmentTypes="${this.model.appointmentTypes}"
        .encounterAppointmentTypeId="${this.model.encounterAppointmentTypeId}"
        .runningLedger="${this.model.runningLedger}"
        .onDismiss="${this.handlers.dismiss}"
        .layout="${this.layout}"
      ></neb-encounter-summary-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-encounter-summary',
  NebOverlayEncounterSummary,
);
