import DeprecatedApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });
export const DEFAULT_VERSION = 1;
export const baseRelationshipUrl = patientId =>
  `patients/${patientId}/relationship`;

export const createPatientRelationship = async (
  patientId,
  patientRelationship,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: baseRelationshipUrl(patientId),
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientRelationship),
  });

  return res;
};

export const putPatientRelationship = async (
  relationshipId,
  patientId,
  patientRelationship,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${baseRelationshipUrl(patientId)}/${relationshipId}`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientRelationship),
  });

  return res;
};

export const getPatientRelationships = async (
  patientId,
  queryParams = {},
  version = DEFAULT_VERSION,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: baseRelationshipUrl(patientId),
    queryParams,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res;
};

export const getPatientRelationshipsCount = async (
  patientId,
  queryParams = {},
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${baseRelationshipUrl(patientId)}/count`,
    queryParams,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res;
};

export const getPatientRelationshipsExists = async (
  queryParams,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: 'relationships/exists',
    queryParams,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res;
};

export const getPatientRelationshipsActiveGroup = async (
  patientId,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${baseRelationshipUrl(patientId)}/activeGroup`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res;
};
