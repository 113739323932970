import {
  Method,
  RESPONSE_TYPE,
  buildQueryString,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getData = async query => {
  const basePath = '/api/v3/tenants/:tenantId/era-eob';
  const path = `${basePath}${buildQueryString(query)}`;
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    method: Method.GET,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
};

export const getDataById = async ({ id, type, reportId }) => {
  const basePath = '/api/v3/tenants/:tenantId/era-eob/:id';
  const query = { type, reportId };
  const path = `${basePath}${buildQueryString(query)}`;
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    replacements: { id },
    method: Method.GET,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
};

export const getDataByLineItemId = lineItemId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/era-eob/line-item/:lineItemId',
    replacements: { lineItemId },
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateEraEobStatus = ({ id, body }) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/era-eob/:id/status',
    replacements: { id },
    method: Method.PUT,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

export const updateEraEobPaymentProviderAdjustment = ({ body, paymentId }) =>
  apiClient.makeRequest({
    method: Method.PUT,
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/era-eob/:paymentId/provider-adjustment',
    replacements: { paymentId },
    headers: { 'Content-Type': 'application/json' },
    responseType: RESPONSE_TYPE.RAW,
    body: JSON.stringify(body),
  });
