import '../../tables/neb-table-add-code-bundle-to-encounter';

import { html, css } from 'lit';

import { getActiveProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import postAllCharges from '../../../../packages/neb-api-client/src/services/encounter/post-all-charges';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../packages/neb-api-client/src/services/practice-settings';
import {
  openSuccess,
  openError,
  openInfo,
  openWarning,
} from '../../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/neb-page-collection';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import {
  getCodeBundles,
  addCodeBundleToEncounter,
} from '../../../api-clients/code-bundles';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

const DUPLICATE_MESSAGES = {
  single: type =>
    `1 ${type} was not added because it already exists in the encounter`,
  plural: (count, types) =>
    `${count} ${types} were not added because they already exist in the encounter`,
  combined: (diagnoses, charges) =>
    `${diagnoses} and ${charges} were not added because they already exist in the encounter`,
};

export const INACTIVE_MESSAGES = {
  single: type => `1 ${type} was not added because it is now inactive`,
  plural: (count, types) =>
    `${count} ${types} were not added because they are now inactive`,
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  providerSelect: {
    id: 'select-provider',
  },
};
class NebPageAddCodeBundleToEncounter extends CollectionPage {
  static get properties() {
    return {
      __disableAdd: Boolean,
      __selectedProvider: Object,
      __providerItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow: auto;
        }

        .container {
          padding: 0px;
        }

        .header {
          margin: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .row {
          padding: 0px 0px;
        }

        .select {
          width: 250px;
          margin-left: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        .table {
          flex: 1 0 0;
        }
      `,
    ];
  }

  static get config() {
    return {
      overlayKey: OVERLAY_KEYS.ADD_CODE_BUNDLE,
      useFetch: true,
      searchLabel: 'Enter bundle name, diagnosis/charge code or description.',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      fetchData: ({ search }) =>
        getCodeBundles(this.__selectedProvider.item.id, {
          hideInactive: true,
          ...(search && { search }),
        }),

      changeProvider: ({ value }) => {
        if (value.item.id !== this.__selectedProvider.item.id) {
          this.__selectedProvider = value;
          this.service.fetch();
        }
      },

      addCodeBundle: async codeBundleId => {
        this.__disableAdd = true;
        const { id: encounterId, patientId } = this.getEncounter();

        try {
          const {
            duplicateDiagnosisCount,
            duplicateChargeCount,
            inactiveChargeCount,
            codeExpiredCount,
            codeUpdatedCount,
          } = await addCodeBundleToEncounter({
            encounterId,
            codeBundleId,
          });

          const practiceSettings = await getPracticeSettings();
          const autoPost =
            practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];

          if (autoPost) {
            await postAllCharges(encounterId, patientId);
          }

          store.dispatch(openSuccess('Code Bundle added successfully'));

          this.__handleDuplicateInfoBanner(
            duplicateDiagnosisCount,
            duplicateChargeCount,
          );

          this.__handleInactiveInfoBanner(inactiveChargeCount);

          if (codeExpiredCount && codeExpiredCount > 0) {
            store.dispatch(
              openWarning(
                'One or more diagnosis codes is not active for the date of service',
              ),
            );
          }

          if (codeUpdatedCount && codeUpdatedCount > 0) {
            store.dispatch(
              openInfo(
                'One or more of the diagnosis descriptions has been updated to match the date of service',
              ),
            );
          }

          this.onAddCodeBundle();
        } catch (e) {
          console.error(e);
          store.dispatch(openError('Unable to add Code Bundle'));
          this.__disableAdd = false;
        }
      },
      viewCodeBundle: (_, row) => {
        openOverlay(OVERLAY_KEYS.VIEW_CODE_BUNDLE_DETAIL, {
          codeBundleId: row.id,
        });
      },
      search: searchTerm => {
        this.service.search(searchTerm);
      },
    };
  }

  initState() {
    super.initState();

    this.__disableAdd = false;
    this.__providerItems = [];
    this.__selectedProvider = {};
    this.__tableState.hideInactive = null;

    const { session, encounter, encounters } = store.getState();

    this.__legacyEncounter = encounters[encounter.item];
    this.encounter = null;
    this.__loggedInUserId = session.item.id;

    this.onAddCodeBundle = () => {};
  }

  async connectedCallback() {
    await this.__fetchProviders();

    super.connectedCallback();
  }

  getEncounter() {
    // Resolve v2 vs v1 encounter (fetch vs. redux)
    return this.encounter ? this.encounter : this.__legacyEncounter;
  }

  async __fetchProviders() {
    const providers = await getActiveProviderUsers();

    this.__providerItems = providers.map(item => ({
      label: objToName(item.name, DEFAULT_NAME_OPTS),
      item,
    }));

    this.__selectedProvider = this.__getDefaultProvider(this.__providerItems);
  }

  buildContext() {
    return { providerId: this.__selectedProvider.item.id };
  }

  __handleDuplicateInfoBanner(duplicateDiagnosisCount, duplicateChargeCount) {
    if (duplicateDiagnosisCount > 0 && duplicateChargeCount > 0) {
      this.__displayDuplicateCombinedInfoBanner(
        duplicateDiagnosisCount,
        duplicateChargeCount,
      );
    } else if (duplicateDiagnosisCount > 1 || duplicateChargeCount > 1) {
      this.__displayDuplicatePluralInfoBanner(
        duplicateDiagnosisCount,
        duplicateChargeCount,
      );
    } else if (duplicateDiagnosisCount || duplicateChargeCount) {
      this.__displayDuplicateSingleInfoBanner(
        duplicateDiagnosisCount,
        duplicateChargeCount,
      );
    }
  }

  __displayDuplicateCombinedInfoBanner(
    duplicateDiagnosisCount,
    duplicateChargeCount,
  ) {
    const diagnosesMsg = `${duplicateDiagnosisCount} ${
      duplicateDiagnosisCount > 1 ? 'Diagnoses' : 'Diagnosis'
    }`;
    const chargesMsg = `${duplicateChargeCount} ${
      duplicateChargeCount > 1 ? 'Charges' : 'Charge'
    }`;

    store.dispatch(
      openInfo(DUPLICATE_MESSAGES.combined(diagnosesMsg, chargesMsg)),
    );
  }

  __displayDuplicatePluralInfoBanner(
    duplicateDiagnosisCount,
    duplicateChargeCount,
  ) {
    if (duplicateDiagnosisCount > 1) {
      store.dispatch(
        openInfo(
          DUPLICATE_MESSAGES.plural(duplicateDiagnosisCount, 'Diagnoses'),
        ),
      );
    } else {
      store.dispatch(
        openInfo(DUPLICATE_MESSAGES.plural(duplicateChargeCount, 'Charges')),
      );
    }
  }

  __displayDuplicateSingleInfoBanner(duplicateDiagnosisCount) {
    const type = duplicateDiagnosisCount ? 'Diagnosis' : 'Charge';

    store.dispatch(openInfo(DUPLICATE_MESSAGES.single(type)));
  }

  __handleInactiveInfoBanner(inactiveChargeCount) {
    if (inactiveChargeCount > 1) {
      store.dispatch(
        openInfo(INACTIVE_MESSAGES.plural(inactiveChargeCount, 'Charges')),
      );
    } else if (inactiveChargeCount === 1) {
      store.dispatch(openInfo(INACTIVE_MESSAGES.single('Charge')));
    }
  }

  __getDefaultProvider(providers) {
    const loggedInProvider = providers.find(
      x => x.item.id === this.__loggedInUserId,
    );

    const encounterProvider = providers.find(
      x => x.item.id === this.getEncounter().providerId,
    );

    return loggedInProvider || encounterProvider;
  }

  renderContent() {
    return html`
      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        class="select"
        label="Provider"
        .value="${this.__selectedProvider}"
        .items="${this.__providerItems}"
        .onChange="${this.handlers.changeProvider}"
        pinLabel
        wrapText
      ></neb-select>

      ${this.renderTable()}
    `;
  }

  renderTable() {
    return html`
      <div class="row">
        <neb-table-add-code-bundle-to-encounter
          id="${ELEMENTS.table.id}"
          class="table"
          .disableAdd="${this.__disableAdd}"
          .emptyMessage="${this.__tableState.searchText
            ? 'No results.'
            : 'There are currently no Code Bundles for this provider.'}"
          .model="${this.__tableState.pageItems}"
          .onSelectRow="${this.handlers.viewCodeBundle}"
          .onAddCodeBundle="${this.handlers.addCodeBundle}"
          .mode="${MODE.DETAIL}"
        ></neb-table-add-code-bundle-to-encounter>
      </div>
    `;
  }
}

customElements.define(
  'neb-page-add-code-bundle-to-encounter',
  NebPageAddCodeBundleToEncounter,
);
