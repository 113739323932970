import { _creating, _created, _createError } from './actions/create';
/**
 * @typedef {Object} Appointment
 */

/**
 * @param {boolean} state.creating
 * @param {Object} state.createError
 * @param {boolean} state.created
 * @param {Date} state.targetDate
 * @param {Date} state.start
 * @param {Date} state.end
 * @param {Array<Appointment>} state.items
 */
export const publicAppointmentReducer = (
  state = {
    creating: false,
    createError: null,
    created: false,
    targetDate: new Date(),
    start: new Date(new Date().getFullYear(), new Date().getMonth(), -7),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 7),
    items: null,
    itemsByGroupId: null,
  },
  action,
) => {
  switch (action.type) {
    case 'APPOINTMENT_CREATING_PUBLIC':
      return _creating(state);

    case 'APPOINTMENT_CREATED_PUBLIC':
      return _created(state, action);

    case 'APPOINTMENT_CREATE_ERROR_PUBLIC':
      return _createError(state, action);

    default:
      return state;
  }
};
