const API_URL = NEB_API_URL;
/* global NEB_API_URL */

const APPOINTMENTS_API_URL = NEB_APPOINTMENTS_API_URL;
/* global NEB_APPOINTMENTS_API_URL */

const MACRO_API_URL = NEB_MACRO_API_URL;
/* global NEB_MACRO_API_URL */

const BILLING_API_URL = NEB_BILLING_API_URL;
/* global NEB_BILLING_API_URL */

const IMAGE_API_URL = NEB_IMAGE_API_URL;
/* global NEB_IMAGE_API_URL */

const CHARTING_API_URL = NEB_CHARTING_API_URL;
/* global NEB_CHARTING_API_URL */

const CORE_API_URL = NEB_CORE_API_URL;
/* global NEB_CORE_API_URL */

const PARTNER_API_URL = NEB_PARTNER_API_URL;
/* global NEB_PARTNER_API_URL */

const PDF_API_URL = NEB_PDF_API_URL;
/* global NEB_PDF_API_URL */

const PERMISSIONS_API_URL = NEB_PERMISSION_API_URL;
/* global NEB_PERMISSION_API_URL */

const REGISTRY_API_URL = NEB_REGISTRY_API_URL;
/* global NEB_REGISTRY_API_URL */

const REPORTS_API_URL = NEB_REPORTS_API_URL;
/* global NEB_REPORTS_API_URL */

const ELECTRONIC_CLAIMS_API_URL = NEB_ELECTRONIC_CLAIMS_API_URL;
/* global NEB_ELECTRONIC_CLAIMS_API_URL */

const EMAIL_API_URL = NEB_EMAIL_API_URL;
/* global NEB_EMAIL_API_URL */

const FILES_API_URL = NEB_FILES_API_URL;
/* global NEB_FILES_API_URL */

const PAYMENTS_API_URL = NEB_PAYMENTS_API_URL;
/* global NEB_PAYMENTS_API_URL */

const CONVERSION_API_URL = NEB_CONVERSION_API_URL;
/* global NEB_CONVERSION_API_URL */

const GLOBAL_INDICATOR_MIN_SHOW_TIME = NEB_GLOBAL_INDICATOR_MIN_SHOW_TIME;
/* global NEB_GLOBAL_INDICATOR_MIN_SHOW_TIME */

const REFETCH_INTERVAL = NEB_REFETCH_INTERVAL;
/* global NEB_REFETCH_INTERVAL */

const PRODUCTION_URL = 'https://practice.chirotouch.com';

export const isProductionEnvironment = () =>
  window.location.origin === PRODUCTION_URL;

export {
  API_URL,
  CORE_API_URL,
  APPOINTMENTS_API_URL,
  MACRO_API_URL,
  BILLING_API_URL,
  IMAGE_API_URL,
  CHARTING_API_URL,
  ELECTRONIC_CLAIMS_API_URL,
  EMAIL_API_URL,
  FILES_API_URL,
  PARTNER_API_URL,
  PDF_API_URL,
  PERMISSIONS_API_URL,
  REGISTRY_API_URL,
  REPORTS_API_URL,
  CONVERSION_API_URL,
  PAYMENTS_API_URL,
  GLOBAL_INDICATOR_MIN_SHOW_TIME,
  REFETCH_INTERVAL,
  PRODUCTION_URL,
};
