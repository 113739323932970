import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { ALLOWED_IDENTIFIERS } from '../../../../../neb-utils/claims';

import getPreviousIdentifierOptions from './get-previous-identifier-options';
import getProviderIdentifierOptions from './get-provider-identifier-options';

export default async ({ claimCellData, state, handlers, formService }) => {
  const key =
    claimCellData.name === 'billingProviderOtherId'
      ? 'billingProvider'
      : 'serviceFacility';

  const {
    [`${key}OtherId`]: currentProviderOtherId,
    [`${key}OtherIdQualifier`]: currentProviderOtherIdQualifier,
  } = state;

  const previousOptions = getPreviousIdentifierOptions({
    name: claimCellData.name,
    currentProviderOtherId,
    currentProviderOtherIdQualifier,
    key,
  });

  const options = await getProviderIdentifierOptions({
    providerId: state.billingProviderId,
    name: claimCellData.name,
    key,
    allowedIdentifiers:
      key === 'serviceFacility'
        ? ALLOWED_IDENTIFIERS.SERVICE_FACILITY
        : ALLOWED_IDENTIFIERS.BILLING_PROVIDER,
    previousOptions,
    billingProviderOtherIdQualifier: state.billingProviderOtherIdQualifier,
    billingProviderOtherId: state.billingProviderOtherId,
  });

  if (options.length < 2) {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: `${claimCellData.title} - Other ID`,
      message: html`
        <div>
          There are no additional provider identifiers configured for this
          billing provider.
        </div>
        <br />
        <div>
          You can configure these on the "Provider Identifiers" tab of this
          provider's user profile.
        </div>
      `,
    });
  }

  const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_SINGLE_SELECT, {
    title: `${claimCellData.title} - Other ID`,
    tooltipText: claimCellData.tooltipText,
    options,
    items: [
      {
        name: claimCellData.name,
        value: {
          [`${key}OtherId`]: state[`${key}OtherId`],
          [`${key}OtherIdQualifier`]: state[`${key}OtherIdQualifier`],
        },
      },
    ],
  });

  if (result) {
    Object.entries(result[claimCellData.name]).forEach(([name, value]) => {
      formService.apply(`claimEditedField.${name}`, true);

      handlers.change({
        name,
        value,
      });
    });
  }

  return undefined;
};
