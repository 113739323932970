import { mapAddedChargeToEncounterCharge } from '../../../../../neb-api-client/src/services/encounter-charge';
import formServiceSetupCharges from '../../../../../neb-api-client/src/services/encounter-charge/form-service-setup-charges';

export const filterDx = (
  diagnoses,
  dxPointers,
  autoDiagnosisPointing = false,
) => {
  if (!diagnoses || !diagnoses.length) {
    return [];
  }

  if (dxPointers) {
    const diagnosisPointers = diagnoses.filter(dx =>
      dxPointers.find(dp => dp.diagnosisCode === dx.code),
    );

    if (diagnosisPointers.length) {
      return diagnosisPointers;
    }
  }

  if (!autoDiagnosisPointing) {
    return [];
  }

  return diagnoses.slice(0, Math.min(diagnoses.length, 4));
};

const checkZeroAmount = item => item.amount === 0 || item.unitCharge === 0;

const getUnitChargeAmount = item =>
  checkZeroAmount(item) ? 0 : item.unitCharge || item.amount;

const getBilledAmount = item => {
  const units = item.units || 1;
  const unitCharge = getUnitChargeAmount(item);
  if (unitCharge === 0) return 0;
  const charge = item.feeScheduleName ? item.feeScheduleCharge : unitCharge;
  return units * charge || item.billedAmount;
};

export const formatAddedCharge = ({
  charge,
  stateTotalCharges,
  diagnoses,
  autoDiagnosisPointing = false,
  autoPost,
}) => {
  const mappedCharges = mapAddedChargeToEncounterCharge({
    charges: [charge],
    autoPost,
  }).map(ch => ({
    ...ch,
    diagnosisPointers: filterDx(
      diagnoses,
      charge.diagnosisPointers,
      autoDiagnosisPointing,
    ),
    billedAmount: getBilledAmount(charge),
    taxName: ch.taxName || null,
    taxRate: ch.taxRate || null,
    unitCharge: getUnitChargeAmount(charge),
  }));

  return formServiceSetupCharges({
    charges: mappedCharges,
    stateTotalCharges,
    formField: 'items',
  });
};
