import '../inputs/neb-textfield';
import '../macros/neb-macro-spine';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import {
  SPINE_LABELS,
  SPINE_LABEL_INDEX,
  DIRECTION,
  SPINE_SECTION,
  formatSpineLabel,
} from '../macros/utils/spine';

import NebForm from './neb-form';

export const ELEMENTS = {
  questionField: { id: 'question-field' },
  spine: { id: 'spine' },
};

export default class NebFormMacroSpine extends NebForm {
  static createModel() {
    return {
      question: {
        text: '',
        defaultIndices: Array(SPINE_LABELS.length).fill(null),
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeSpine: (direction, section) => {
        const spineLabel = formatSpineLabel(direction, section);

        if (
          this.state.question.defaultIndices[SPINE_LABEL_INDEX[spineLabel]] !==
          null
        ) {
          this.formService.apply(
            `question.defaultIndices.${SPINE_LABEL_INDEX[spineLabel]}`,
            null,
          );

          return;
        }

        if (section !== SPINE_SECTION.PELVIS) {
          this.__deselectSpineSection(section);
        }

        this.formService.apply(
          `question.defaultIndices.${SPINE_LABEL_INDEX[spineLabel]}`,
          SPINE_LABEL_INDEX[spineLabel],
        );
      },
    };
  }

  __deselectSpineSection(section) {
    const leftSection = `${DIRECTION.LEFT} ${section}`;
    const rightSection = `${DIRECTION.RIGHT} ${section}`;

    this.formService.apply(
      `question.defaultIndices.${SPINE_LABEL_INDEX[leftSection]}`,
      null,
    );

    this.formService.apply(
      `question.defaultIndices.${SPINE_LABEL_INDEX[section]}`,
      null,
    );

    this.formService.apply(
      `question.defaultIndices.${SPINE_LABEL_INDEX[rightSection]}`,
      null,
    );
  }

  createSelectors() {
    return {
      children: {
        question: {
          children: {
            text: [isRequired()],
          },
        },
      },
    };
  }

  get __selectedSections() {
    return this.state.question.defaultIndices
      .filter(i => i !== null)
      .map(index => SPINE_LABELS[index]);
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.questionField.id}"
        class="field-question"
        label="Question"
        helper="Required"
        name="question.text"
        maxLength="255"
        .value="${this.state.question.text}"
        .error="${this.errors.question.text}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-macro-spine
        id="${ELEMENTS.spine.id}"
        .selectedSections="${this.__selectedSections}"
        .onClick="${this.handlers.changeSpine}"
      ></neb-macro-spine>
    `;
  }
}

window.customElements.define('neb-form-macro-spine', NebFormMacroSpine);
