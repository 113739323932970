import { ITEM_EMPTY } from '../../packages/neb-utils/selectors';

export const EMPLOYMENT_STATUS = [
  ITEM_EMPTY,
  { label: 'Employed', data: { id: 'Employed' } },
  { label: 'Student', data: { id: 'Student' } },
  { label: 'Not Employed', data: { id: 'Not Employed' } },
  { label: 'Retired', data: { id: 'Retired' } },
  { label: 'Unknown', data: { id: 'Unknown' } },
];

export const SEX = [
  ITEM_EMPTY,
  { label: 'Male', data: { id: 'Male' } },
  { label: 'Female', data: { id: 'Female' } },
  { label: 'Other', data: { id: 'Other' } },
];
