import * as bannerState from '../../packages/neb-dialog/neb-banner-state';
import { store } from '../../packages/neb-redux/neb-redux-store';
import * as routeState from '../../packages/neb-route/neb-route-state';

export const navigate = route => store.dispatch(routeState.navigate(route));

export const openError = msg => store.dispatch(bannerState.openError(msg));
export const openSuccess = msg => store.dispatch(bannerState.openSuccess(msg));
export const openWarning = msg => store.dispatch(bannerState.openWarning(msg));
export const openInfo = msg => store.dispatch(bannerState.openInfo(msg));
