class RouteParser {
  static escapeRegex(value) {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  constructor(pattern) {
    const regexp = [];
    const capturingGroupNames = [];
    pattern.split('/').forEach(part => {
      if (part === '') {
        return;
      }

      if (part.startsWith(':')) {
        regexp.push('([^/]*)');
        capturingGroupNames.push(part.substring(1));
      } else {
        regexp.push(RouteParser.escapeRegex(part));
      }
    });

    this.__regexp = new RegExp(`^#?(/${regexp.join('/')})(.*)$`);
    this.__capturingGroupNames = capturingGroupNames;
  }

  parse(path) {
    const match = this.__regexp.exec(path);

    const route = {
      path,
      active: Boolean(match),
    };

    if (!route.active) {
      return route;
    }

    [, route.matched] = match;
    route.data = {};

    for (let i = 0; i < this.__capturingGroupNames.length; i++) {
      const name = this.__capturingGroupNames[i];
      route.data[name] = match[i + 2];
    }

    route.tail = match[this.__capturingGroupNames.length + 2];
    return route;
  }
}

export const createRouteParser = pattern => new RouteParser(pattern);
