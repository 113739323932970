import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
};

class NebPatientInsuranceLastUpdatedByContent extends LitElement {
  static get properties() {
    return {
      updatedAt: {
        type: String,
      },
      updatedBy: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.updatedAt = null;
    this.updatedBy = null;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          padding-left: ${CSS_SPACING};
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-style: italic;
        }

        .description {
          font-style: italic;
        }
      `,
    ];
  }

  render() {
    return this.updatedAt && this.updatedBy
      ? html`
          <div class="container">
            <div class="label">Last Updated</div>
            <div class="description" id="${ELEMENTS.description.id}">
              ${this.updatedAt} by ${this.updatedBy}
            </div>
          </div>
        `
      : html``;
  }
}

customElements.define(
  'neb-patient-insurance-last-updated-by-content',
  NebPatientInsuranceLastUpdatedByContent,
);
