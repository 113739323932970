import './neb-icon';

import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import { formatPhoneNumber } from '../../../packages/neb-utils/formatters';
import { renderMatchStrength } from '../../../packages/neb-utils/patient';

export const ELEMENTS = {
  matchButton: {
    id: 'button-match',
  },
  firstName: {
    id: 'first-name',
  },
  lastName: {
    id: 'last-name',
  },
  dob: {
    id: 'date-of-birth',
  },
  email: {
    id: 'email',
  },
  phoneNumber: {
    id: 'phone-number',
  },
  matchStrength: {
    id: 'match-strength',
  },
  moreIcon: {
    id: 'icon-more',
  },
};
export const ROW_TYPE = {
  DEFAULT: 'default',
  HEADER: 'header',
  MATCH: 'match',
  REVIEW: 'review',
  PATIENT: 'patient',
  PATIENT_HEADER: 'patient-header',
};

class NebPatientOnlineBookingMatchRow extends LitElement {
  static get properties() {
    return {
      model: Object,
      layout: {
        reflect: true,
        type: String,
      },
      type: {
        reflect: true,
        type: String,
      },
      __hasMultiple: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.layout = '';
    this.model = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      emailAddresses: '',
    };

    this.type = ROW_TYPE.DEFAULT;
    this.__hasMultiple = false;

    this.onMatch = () => {};
  }

  initHandlers() {
    this.__handlers = {
      matchButtonClicked: () => this.onMatch(this.model),
      rowClicked: () =>
        this.layout === 'small' &&
        this.type === ROW_TYPE.MATCH &&
        this.onMatch(this.model),
    };
  }

  updated() {
    this.__hasMultiple = !!(
      (this.model.phoneNumbers && this.model.phoneNumbers.length > 1) ||
      (this.model.emailAddresses && this.model.emailAddresses.length > 1)
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.__handlers.rowClicked);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          padding: 15px 17px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          padding-left: 17px;
        }

        :host([__hasmultiple]) {
          align-items: flex-start;
        }

        :host([layout='small']) {
          cursor: pointer;
        }

        :host([layout='small']) .patient-info:last-of-type {
          padding-right: 5px;
        }

        :host([type='header']) .patient-info {
          font-weight: bold;
        }

        :host([type='patient-header']) .patient-info {
          font-weight: bold;
        }

        .patient-info {
          align-items: space-between;
          display: flex;
          word-break: normal;
          padding-right: 10px;
        }

        .patient-info:last-child {
          padding-right: 0;
        }

        .box-small {
          flex: 1 0 0;
        }

        .box-large {
          flex: 2 0 0;
        }

        .view-icon {
          margin: 5px ${CSS_SPACING};
          height: 12px;
          width: 12px;
          transform: rotate(-90deg);
          fill: ${CSS_COLOR_GREY_1};
        }

        :host([layout='small']) .view-icon {
          margin: 0 ${CSS_SPACING} 0 0;
        }

        .match-strength {
          display: flex;
          align-items: center;
        }

        .text-match {
          flex: 1 0 0;
        }

        .match-email,
        .match-phone {
          display: block;
          flex-direction: column;
        }
      `,
    ];
  }

  __renderPhoneNumbers() {
    return this.model.phoneNumbers.map(
      phone => html`
        <div class="container-phone">${formatPhoneNumber(phone.number)}</div>
      `,
    );
  }

  __renderPhoneNumber() {
    return [ROW_TYPE.HEADER, ROW_TYPE.PATIENT_HEADER].indexOf(this.type) === -1
      ? formatPhoneNumber(this.model.phoneNumber)
      : this.model.phoneNumber;
  }

  __renderDateOfBirth() {
    return [ROW_TYPE.HEADER, ROW_TYPE.PATIENT_HEADER].indexOf(this.type) === -1
      ? moment(this.model.dateOfBirth).format('MM/DD/YYYY')
      : this.model.dateOfBirth;
  }

  __renderMatchStrength() {
    return [ROW_TYPE.HEADER, ROW_TYPE.PATIENT_HEADER].indexOf(this.type) === -1
      ? renderMatchStrength(this.model.matches)
      : this.model.matches;
  }

  __renderEmailAddresses() {
    return this.model.emailAddresses.map(
      email => html` <div class="container-email">${email}</div> `,
    );
  }

  __renderLargeColumns() {
    return html`
      <div
        id="${ELEMENTS.phoneNumber.id}"
        class="match-phone patient-info box-small"
      >
        ${this.model.phoneNumbers && this.model.phoneNumbers.length > 0
          ? this.__renderPhoneNumbers()
          : this.__renderPhoneNumber()}
      </div>

      <div id="${ELEMENTS.email.id}" class="match-email patient-info box-large">
        ${this.model.emailAddresses && this.model.emailAddresses.length > 0
          ? this.__renderEmailAddresses()
          : html` ${this.model.emailAddress} `}
      </div>
    `;
  }

  __renderMatchColumn() {
    return html`
      <div id="${ELEMENTS.matchStrength.id}" class="text-match">
        ${this.__renderMatchStrength()}
      </div>

      ${this.layout === 'small' && this.type === ROW_TYPE.MATCH
        ? html`
            <neb-icon
              id="${ELEMENTS.moreIcon.id}"
              class="view-icon"
              icon="neb:chevron"
            ></neb-icon>
          `
        : ''}
    `;
  }

  __renderButtonColumn() {
    return html`
      <div class="patient-header box-small">
        ${this.type === ROW_TYPE.MATCH
          ? html`
              <neb-button
                id="${ELEMENTS.matchButton.id}"
                class="button"
                role="${BUTTON_ROLE.CONFIRM}"
                label="Match"
                .onClick="${this.__handlers.matchButtonClicked}"
              ></neb-button>
            `
          : ''}
      </div>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.firstName.id}"
        class="match-first-name patient-info box-small"
      >
        ${this.model.firstName}
      </div>
      <div
        id="${ELEMENTS.lastName.id}"
        class="match-last-name patient-info box-small"
      >
        ${this.model.lastName}
      </div>

      ${this.layout !== 'small' ||
      [ROW_TYPE.PATIENT, ROW_TYPE.PATIENT_HEADER].indexOf(this.type) !== -1
        ? html`
            <div
              id="${ELEMENTS.dob.id}"
              class="match-dob patient-info box-small"
            >
              ${this.__renderDateOfBirth()}
            </div>
          `
        : ''}
      ${this.layout !== 'small' ? this.__renderLargeColumns() : ''}
      ${[ROW_TYPE.PATIENT, ROW_TYPE.PATIENT_HEADER].indexOf(this.type) === -1
        ? html`
            <div class="match-strength patient-info box-small">
              ${[ROW_TYPE.DEFAULT, ROW_TYPE.PATIENT].indexOf(this.type) === -1
                ? this.__renderMatchColumn()
                : ''}
            </div>
          `
        : ''}
      ${this.layout !== 'small' ? this.__renderButtonColumn() : ''}
    `;
  }
}

customElements.define(
  'neb-patient-online-booking-match-row',
  NebPatientOnlineBookingMatchRow,
);
