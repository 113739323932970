import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import '../../../../packages/neb-lit-components/src/components/macros/neb-macro-region';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import { deepCopy } from '../../../../packages/neb-utils/utils';

export const ELEMENTS = {
  macroRegion: { id: 'macro-region' },
  regionName: { id: 'region-name' },
  doneButton: { id: 'save-button' },
  cancelButton: { id: 'cancel-button' },
};

class NebPopupMacroRegionChoiceSetup extends NebPopup {
  static get properties() {
    return {
      __selectedIndex: Number,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
        }

        .region-name {
          display: flex;
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .spacing {
          margin-right: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__selectedIndex = 0;
    this.__state = [];
    this.title = 'Region - Custom Choices';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickRegion: index => {
        this.__selectedIndex = index;
      },
      confirm: () => {
        const choices = this.__state.map((choice, index) =>
          choice.value ? choice : this.model.regionLabels[index],
        );

        this.onClose(choices);
      },
      change: ({ value }) => {
        this.__state[this.__selectedIndex].value = value;
      },
    };
  }

  modelChanged() {
    this.__state = deepCopy(this.model.regionLabels);
  }

  renderContent() {
    return html`
      <neb-macro-region
        id="${ELEMENTS.macroRegion.id}"
        .selectedIndices="${[this.__selectedIndex]}"
        .showClearButton="${false}"
        .onClick="${this.handlers.clickRegion}"
      ></neb-macro-region>
      <neb-textfield
        id="${ELEMENTS.regionName.id}"
        class="region-name"
        value="${this.__state[this.__selectedIndex].value}"
        maxLength="255"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.doneButton.id}"
          class="spacing"
          label="Done"
          .onClick="${this.handlers.confirm}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          label="Cancel"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macro-region-choice-setup',
  NebPopupMacroRegionChoiceSetup,
);
