const elements = new Map();

let intervalId = null;

function inView(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function start() {
  if (intervalId) return;

  intervalId = setInterval(() => {
    const doneEls = [];

    elements.forEach((callback, element) => {
      if (inView(element)) {
        callback();
        doneEls.push(element);
      }
    });

    doneEls.forEach(element => elements.delete(element));
  }, 1000);
}

function stop() {
  if (intervalId) clearInterval(intervalId);
  intervalId = null;
}

function add(element, callback) {
  if (elements.get(element)) {
    console.warn(
      'Element is already part of element map. The previous entry is being overridden.',
    );
  }

  if (elements.size === 0) {
    start();
  }

  elements.set(element, callback);
}

function remove(element) {
  elements.delete(element);

  if (elements.size === 0) {
    stop();
  }
}

export { add, remove };
