import '../../neb-material-design/src/components/neb-md-select';
import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { TEXT_REQUIRED } from '../../neb-lit-components/src/components/login/neb-forgot-password';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectProvider: {
    id: 'select-provider',
  },
};

const EMPTY_PROVIDER = { id: null, label: '' };

class NebPopupEncounterProviderSelection extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      model: Object,
      __selectedProvider: Object,
      __error: Boolean,
    };
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = 'Select Provider to Continue';

    this.__selectedProvider = {};
    this.__error = false;
    this.__providers = [];

    this.model = {
      providers: [],
      providerId: null,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      change: e => {
        this.__selectedProvider = e.value;

        if (this.__selectedProvider.id) {
          this.__error = false;
        }
      },
      confirm: () => {
        if (!this.__selectedProvider.id) {
          this.__error = true;
          return;
        }
        this.onClose(this.__selectedProvider);
      },
      cancel: () => {
        this.onClose(false);
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__providers = this.model.providers
        .map(prov => ({
          ...prov,
          label: `${prov.name.last}, ${prov.name.first}`,
        }))
        .filter(prov => prov.active);

      this.__selectedProvider =
        this.__providers.find(prov => prov.id === this.model.providerId) ||
        EMPTY_PROVIDER;
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;

          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 650px;
          height: 100%;
        }

        :host([layout='small']) .content-container {
          width: 300px;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .providers {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="container-select">
          <neb-select
            id="${ELEMENTS.selectProvider.id}"
            class="providers"
            name="providerId"
            label="Providers"
            .items="${this.__providers}"
            .value="${this.__selectedProvider}"
            .error="${this.__error}"
            .helper="${TEXT_REQUIRED}"
            .onChange="${this.__handlers.change}"
          ></neb-select>
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="Save"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-encounter-provider-selection',
  NebPopupEncounterProviderSelection,
);
