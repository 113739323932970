export const NEBULA_REFRESH_EVENT = 'nebula:refresh';

export const REFRESH_PATIENT_KEY = 'patients';

export const REFRESH_CHANGE_TYPE = {
  PATIENT: 'patient',
  PATIENT_INSURANCE: 'patientInsurance',
  PATIENT_PACKAGE: 'patientPackage',
  SCHEDULED_PAYMENTS: 'scheduledPayments',
  SCHEDULING: 'scheduling',
  LEDGER: 'ledger',
  DOCUMENTS: 'documents',
  GUARANTORS: 'guarantors',
};

/**
 * Method to dispatch an event indicating a change was made
 * @property {Array} changed - array of strings that indicates what was changed
 * @property {String} patientId
 */
export const sendRefreshNotification = (changed = [], patientId) => {
  window.dispatchEvent(
    new CustomEvent(NEBULA_REFRESH_EVENT, {
      detail: {
        changed,
        ...(patientId
          ? {
              [REFRESH_PATIENT_KEY]: {
                [patientId]: {
                  changed,
                },
              },
            }
          : {}),
      },
    }),
  );
};
