import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';

import NebPopup from './neb-popup';

import '../../neb-lit-components/src/components/neb-checkbox-old';

export const DISABLED_HINT = '(Section has no content.)';

export const ELEMENTS = {
  title: {
    id: 'title',
    label: 'Copy SOAP Note',
  },
  message: {
    id: 'message',
    label: 'Please select the SOAP note sections you would like to copy.',
  },
  header: { id: 'header' },
  subjectiveCheckbox: { id: 'checkbox-subjective', tag: 'neb-checkbox-old' },
  objectiveCheckbox: { id: 'checkbox-objective', tag: 'neb-checkbox-old' },
  assessmentCheckbox: { id: 'checkbox-assessment', tag: 'neb-checkbox-old' },
  planCheckbox: { id: 'checkbox-plan', tag: 'neb-checkbox-old' },
  buttonCopy: { id: 'button-copy' },
  buttonCancel: { id: 'button-cancel' },
};

class NebPopupSoapNoteCopy extends NebPopup {
  static get properties() {
    return {
      toCopy: Array,
    };
  }

  initState() {
    super.initState();
    this.title = ELEMENTS.title.label;
    this.toCopy = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () => {
      this.onClose({
        toCopy: [],
      });
    };

    this.handlers.copy = () => {
      this.onClose({
        toCopy: this.toCopy,
      });
    };

    this.handlers.checkbox = (checked, { index }) => {
      if (checked) {
        this.toCopy = [...this.toCopy, index];
      } else {
        const copy = this.toCopy.slice();
        copy.splice(this.toCopy.indexOf(index), 1);
        this.toCopy = copy;
      }
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 577px;
          height: 385px;
        }

        .content-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .button-container {
          display: flex;
        }

        .checkbox-container {
          flex: 1 0 0;
          margin-left: -10px;
          margin-top: 25px;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  __renderCheckbox(idName, sectionName) {
    const labelPrefix = `Copy ${sectionName[0].toUpperCase()}${sectionName.slice(
      1,
    )}`;
    const label = this.model.notes[sectionName]
      ? labelPrefix
      : `${labelPrefix} ${DISABLED_HINT}`;
    const isSelected = this.toCopy.indexOf(sectionName) !== -1;
    return html`
      <neb-checkbox-old
        id="${ELEMENTS[idName].id}"
        .label="${label}"
        .index="${sectionName}"
        .disabled="${!this.model.notes[sectionName]}"
        .checked="${isSelected}"
        .onClick="${this.handlers.checkbox}"
      ></neb-checkbox-old>
    `;
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.message.id}">${ELEMENTS.message.label}</div>

        <div class="checkbox-container">
          ${this.__renderCheckbox('subjectiveCheckbox', 'subjective')}
          ${this.__renderCheckbox('objectiveCheckbox', 'objective')}
          ${this.__renderCheckbox('assessmentCheckbox', 'assessment')}
          ${this.__renderCheckbox('planCheckbox', 'plan')}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonCopy.id}"
            class="button"
            role="${BUTTON_ROLE.CONFIRM}"
            .label="${'Copy'}"
            .onClick="${this.handlers.copy}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            role="${BUTTON_ROLE.CANCEL}"
            .label="${'Cancel'}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-soap-note-copy', NebPopupSoapNoteCopy);
