import '../../../../../src/components/misc/neb-icon';
import '../../../../../src/components/cards/neb-event-card';

import { LitElement, html, css } from 'lit';

import {
  getAppointmentId,
  getQuickActionHandlers,
  handleSecondaryAction,
  isUnmatchedPatient,
  validQuickActionResponse,
} from '../../../../../src/utils/context/appointment-quick-action';
import {
  CONTEXT_KEYS,
  openContext,
} from '../../../../../src/utils/context/constants';
import {
  CREATE,
  CURRENT,
  ENCOUNTER,
  NO_PRIOR_ENCOUNTERS,
  PATIENT_RECORD,
  PRIOR,
  VIEW,
} from '../../../../../src/utils/user-message';
import { getQuickActions } from '../../../../neb-api-client/src/appointment-api-client';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import { hideIfEncounterOnly } from '../../utils/encounter-only-util';
import { fetchAndOpenMatchPopup } from '../../utils/patients';
import { APPOINTMENT_STATUS } from '../scheduling/neb-appointment-options';

import { ENCOUNTER_ACTIONS } from './neb-encounter-list';

export const ELEMENTS = {
  card: {
    id: 'card',
  },
  items: {
    selector: '[id^=item-]',
  },
  toggleButton: {
    id: 'button-toggle',
  },
  itemCheckInOut: {
    id: 'item-check-in-out',
  },
  itemEncounter: {
    id: 'item-encounter',
  },
  itemAppointmentDetails: {
    id: 'item-appointment-details',
  },
  itemChangeRoom: {
    id: 'item-change-room',
  },
  itemPatientRecord: {
    id: 'patient-record',
  },
  itemPriorEncounter: {
    id: 'item-prior-encounter',
  },
};

const ENCOUNTER_OPTIONS = {
  VIEW: {
    title: `${VIEW} ${CURRENT}`,
    icon: 'neb:unsigned',
  },
  CREATE: {
    title: CREATE,
    icon: 'neb:books',
  },
};

class NebEncounterListCard extends LitElement {
  static get properties() {
    return {
      index: {
        type: Number,
      },
      expanded: {
        reflect: true,
        type: Boolean,
      },
      selected: {
        reflect: true,
        type: Boolean,
      },
      __contextOpen: Boolean,
      model: Object,
      hasPriorEncounters: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          width: 100%;
          height: 100%;
          grid-gap: 0 4px;
          grid-template-columns: 1fr auto;
          grid-template-rows: repeat(2, auto);
        }

        .item {
          background-color: transparent;
          border: 1px solid transparent;
          transition: border 200ms;
          width: 100%;
        }

        .item:hover,
        :host([expanded]) .item {
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([selected]) .item {
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .item-expand {
          cursor: pointer;
          display: flex;
          justify-content: center;
          width: 38px;
          grid-row: span 2;
        }

        :host(:not([selected])) .item-expand:hover {
          border: 1px solid ${CSS_COLOR_GREY_2};
        }

        .item-menu {
          display: flex;
          overflow: hidden;
          padding: 0;
          height: 0;
          min-width: 0;
          flex-flow: column nowrap;
          transition: height 200ms;
        }

        :host([expanded]) .item-menu {
          height: auto;
        }

        .icon-arrow {
          cursor: pointer;
          width: 12px;
          height: 12px;
          align-self: center;
          fill: ${CSS_COLOR_GREY_1};
          transition: transform 200ms;
        }

        :host([expanded]) .icon-arrow {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(180deg);
        }

        :host([selected]) .icon-arrow {
          fill: ${CSS_COLOR_WHITE};
        }

        :host([selected]) .icon-item {
          fill: ${CSS_COLOR_WHITE};
        }

        .icon-item {
          margin-left: 80px;
          margin-right: 12px;
          padding: 0;
          width: 20px;
          height: auto;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .label-item {
          padding: 0;
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([selected]) .label-item {
          color: ${CSS_COLOR_WHITE};
        }

        .row-action {
          display: flex;
          margin-left: 60px;
          height: 40px;
          align-items: center;
        }

        .icon-open {
          margin-left: 12px;
          margin-right: 0px;
          width: 14px;
          height: auto;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__contextOpen = false;

    this.index = 0;
    this.expanded = false;
    this.selected = false;
    this.model = {};
    this.hasPriorEncounters = true;

    this.onSelect = () => {};

    this.onToggle = () => {};

    this.onAction = () => {};

    this.onDisplayCard = () => {};

    this.onQuickAction = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      select: () => this.onSelect(this.model),
      toggle: () => this.onToggle(this.model, !this.selected),
      action: e => {
        this.onAction(this.model, e.currentTarget.action);
      },
      cardDisplayed: model => {
        this.onDisplayCard(model);
      },
      openContext: async e => {
        if (this.model && this.model.id) {
          e.preventDefault();
          e.stopPropagation();

          if (isUnmatchedPatient(this.model)) {
            const popup = await fetchAndOpenMatchPopup(
              this.model.accountId,
              this.model.id,
            );

            if (!popup.back) {
              await openAppointmentPage(this.model.id);
              this.onQuickAction();
            }
            if (popup.deleted) this.onQuickAction();

            return;
          }

          const appointmentId = getAppointmentId(this.model);

          const { appointment, ...actions } = await getQuickActions(
            appointmentId,
            true,
          );

          const items = getQuickActionHandlers(appointment, actions);

          if (items.length) {
            this.__contextOpen = true;
            const res = await openContext(CONTEXT_KEYS.MENU, {
              left: e.x,
              top: e.y,
              items,
              data: {},
            });

            await this.__handleQuickActionResponse(appointment, res);
          }
        }
      },
    };
  }

  async __handleQuickActionResponse(item, res) {
    if (validQuickActionResponse(res)) {
      this.__contextOpen = false;
      this.onQuickAction();
    }

    if (res && res.secondaryAction) {
      this.__contextOpen = false;

      const secondaryRes = await handleSecondaryAction(item, res);

      if (validQuickActionResponse(secondaryRes)) {
        this.onQuickAction();
      }
    }
  }

  __renderCheckInOut() {
    if (hideIfEncounterOnly(this.model)) {
      return html``;
    }

    const { status } = this.model;

    let appointmentStatus;

    switch (status) {
      case APPOINTMENT_STATUS.ACTIVE:
        appointmentStatus = 'In';
        break;
      case APPOINTMENT_STATUS.CHECKED_IN:
        appointmentStatus = 'Out';
        break;
      default:
        break;
    }

    return appointmentStatus
      ? html`
          <div
            id="${ELEMENTS.itemCheckInOut.id}"
            class="row-action"
            .action="${ENCOUNTER_ACTIONS.CHECK_IN_OUT}"
            @click="${this.__handlers.action}"
          >
            <neb-icon
              class="icon-item"
              .icon="${`neb:checked${appointmentStatus}`}"
            ></neb-icon>
            <span class="label-item">Check ${appointmentStatus}</span>
          </div>
        `
      : html``;
  }

  __renderViewAppointmentDetails() {
    return hideIfEncounterOnly(this.model)
      ? html``
      : html`
          <div
            id="${ELEMENTS.itemAppointmentDetails.id}"
            class="row-action"
            .action="${ENCOUNTER_ACTIONS.VIEW_APPOINTMENT_DETAILS}"
            @click="${this.__handlers.action}"
          >
            <neb-icon class="icon-item" .icon="${'neb:calendar'}"></neb-icon>
            <span class="label-item">View Appointment Details</span>
          </div>
        `;
  }

  __renderCheckInChangeRoom() {
    const { status } = this.model;

    return status === APPOINTMENT_STATUS.CHECKED_IN
      ? html`
          <div
            id="${ELEMENTS.itemChangeRoom.id}"
            class="row-action"
            .action="${ENCOUNTER_ACTIONS.CHANGE_ROOM}"
            @click="${this.__handlers.action}"
          >
            <neb-icon class="icon-item" .icon="${'neb:roomDoor'}"></neb-icon>
            <span class="label-item">Change Room</span>
          </div>
        `
      : html``;
  }

  __renderEncounterOption() {
    const { encounterNumber } = this.model;
    const encounterOption = encounterNumber
      ? ENCOUNTER_OPTIONS.VIEW
      : ENCOUNTER_OPTIONS.CREATE;

    return html`
      <div
        id="${ELEMENTS.itemEncounter.id}"
        class="row-action"
        .action="${ENCOUNTER_ACTIONS.CREATE_OPEN_ENCOUNTER}"
        @click="${this.__handlers.action}"
      >
        <neb-icon class="icon-item" .icon="${encounterOption.icon}"></neb-icon>
        <span class="label-item">${encounterOption.title} ${ENCOUNTER}</span>
      </div>
    `;
  }

  __renderPatientRecord() {
    return html`
      <div
        id="${ELEMENTS.itemPatientRecord.id}"
        class="row-action"
        .action="${ENCOUNTER_ACTIONS.PATIENT_RECORD}"
        @click="${this.__handlers.action}"
      >
        <neb-icon class="icon-item" .icon="${'neb:folder'}"></neb-icon>
        <span class="label-item">${VIEW} ${PATIENT_RECORD}</span>
        <neb-icon class="icon-item icon-open" .icon="${'neb:open'}"></neb-icon>
      </div>
    `;
  }

  __renderPriorEncounter() {
    const priorEncounterLabel = this.hasPriorEncounters
      ? `${VIEW} ${PRIOR} ${ENCOUNTER}s`
      : NO_PRIOR_ENCOUNTERS;

    return html`
      <div
        id="${ELEMENTS.itemPriorEncounter.id}"
        class="row-action"
        .action="${ENCOUNTER_ACTIONS.PRIOR_ENCOUNTER}"
        @click="${this.__handlers.action}"
      >
        <neb-icon class="icon-item" .icon="${'neb:unsigned'}"></neb-icon>
        <span class="label-item">${priorEncounterLabel}</span>
      </div>
    `;
  }

  renderItems() {
    return html`
      ${this.__renderCheckInOut()} ${this.__renderEncounterOption()}
      ${this.__renderPriorEncounter()} ${this.__renderViewAppointmentDetails()}
      ${this.__renderCheckInChangeRoom()} ${this.__renderPatientRecord()}
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-event-card
          id="${ELEMENTS.card.id}"
          class="item"
          .model="${this.model}"
          .contextOpen="${this.__contextOpen}"
          .onDisplay="${this.__handlers.cardDisplayed}"
          @click="${this.__handlers.select}"
          @contextmenu="${this.__handlers.openContext}"
          ?selected="${this.selected}"
        ></neb-event-card>

        <div
          id="${ELEMENTS.toggleButton.id}"
          class="item item-expand"
          @click="${this.__handlers.toggle}"
        >
          <neb-icon class="icon-arrow" icon="neb:chevron"></neb-icon>
        </div>

        <div class="item item-menu">${this.renderItems()}</div>
      </div>
    `;
  }
}

window.customElements.define('neb-encounter-list-card', NebEncounterListCard);
