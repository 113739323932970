import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../neb-profile-photo-upload';
import '../controls/neb-button-action';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../controls/neb-switch';
import '../neb-header';
import '../inputs/neb-select-search';
import '../neb-patient-search';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import {
  BASE_CATEGORIES,
  BASE_CATEGORY_SOURCE_TYPES,
} from '../../../../../src/api-clients/referrals-api-client';
import { CSS_SMALL_SPACING } from '../../../../../src/styles';
import { getNextMRN } from '../../../../neb-api-client/src/patient-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { alphanumeric, date, number, ssn } from '../../../../neb-utils/masks';
import { createModel, getPhotoModel } from '../../../../neb-utils/patient';
import * as selectors from '../../../../neb-utils/selectors';
import { selectFormatters } from '../../../../neb-utils/selectors-old';
import {
  required,
  isDate,
  isSsn,
  isZipCode,
} from '../../../../neb-utils/validators';
import Address from '../field-groups/neb-address';
import { emailListSelectorOld } from '../field-groups/neb-email-list';
import { phoneListSelectorOld } from '../field-groups/neb-phone-list';
import { POPOVER_POSITION } from '../neb-date-picker';
import * as FORM_DATA from '../patients/neb-patient-profile-data';

import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from './neb-form-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  ssnField: { id: 'input-ssn' },
  dobField: { id: 'input-dateOfBirth' },
  dodField: { id: 'input-dateOfDeath' },
  sexField: { id: 'input-sex' },
  pinField: { id: 'input-pin' },
  mrnField: { id: 'input-medical-record' },
  firstNameField: { id: 'input-name-first' },
  lastNameField: { id: 'input-name-last' },
  middleNameField: { id: 'input-name-middle' },
  preferredNameField: { id: 'input-name-preferred' },
  suffixNameField: { id: 'input-name-suffix' },
  employmentField: { id: 'input-status-employment' },
  relationshipField: { id: 'input-status-relationship' },
  preferredProviderField: { id: 'input-preferred-provider' },
  preferredLocationField: { id: 'input-preferred-Location' },
  phoneItems: { id: 'input-phone-numbers' },
  emailItems: { id: 'input-email-addresses' },
  photoUpload: { id: 'upload-photo' },
  actionBar: { id: 'action-bar' },
  addressForm: { id: 'input-address-form' },
  switchInactivePatient: { id: 'switch-inactive-patient' },
  deceasedCheckbox: { id: 'checkbox-deceased' },
  switchPreviousPatient: { id: 'switch-previous-patient' },
  referralCategoryField: { id: 'input-referralCategory' },
  referralDescriptionField: { id: 'input-referralDescription' },
  referralSourceSearchPatient: { id: 'select-patient-search-referralSource' },
  referralCategorySourceSelect: { id: 'select-search-referralCategorySource' },
};

export const CLEAR_MRN_POPUP = {
  title: 'Warning',
  message:
    'Clearing the MRN will leave the field blank. Saving without entering a new MRN will generate a system-assigned MRN. You can also manually enter an MRN before saving. Would you like to clear the MRN or cancel?',
  confirmText: 'Clear MRN',
  cancelText: 'Cancel',
};

export const INVALID_MRN = {
  title: 'Invalid MRN',
  message:
    'Medical Record Numbers (MRN) cannot be created using the prefix "CT." This is reserved for system generated MRNs. Choose a new option.',
};

const EMPTY_ITEM = {
  item: { id: '' },
  label: '',
};

const MAX_VISIBLE_ITEMS = 5;

class NebFormPatient extends NebFormOld {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      __nextMRN: String,

      providers: { type: Array },
      locations: { type: Array },
      activeLocations: { type: Array },
      slim: { reflect: true, type: Boolean },
      showPatientStatus: { reflect: true, type: Boolean },
      referralCategories: { type: Array },
      referralCategorySources: { type: Array },
      referralCategorySource: { type: Object },
      restrictedView: { type: Boolean },
    };
  }

  static createModel() {
    return {
      ...createModel(),
      photoSrc: '',
      dateOfBirth: '',
      dateOfDeath: '',
      phoneNumbers: [phoneListSelectorOld.genItem()],
      emailAddresses: [emailListSelectorOld.genItem()],
      address: Address.createModel(),
    };
  }

  initState() {
    super.initState();
    this.__focused = false;
    this.__nextMRN = '';
    this.slim = false;
    this.providers = [];
    this.locations = [];
    this.activeLocations = [];
    this.referralCategories = [];
    this.referralCategorySources = [];
    this.referralCategorySource = null;
    this.errors = { dateOfDeath: '' };
    this.helpers = { dateOfDeath: '' };
    this.restrictedView = false;

    this.onInvalidPhoto = () => {};

    this.onChange = () => {};

    this.onChangeReferralCategorySource = () => {};

    this.onFetchCategorySources = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async () => {
        await this.__handleMedicalRecordNumber();

        if (this.formService.validate()) {
          const model = this.formService.buildModel();
          this.onSave(model);
        } else if (
          this.errors.medicalRecordNumber === 'Required' &&
          this.state.medicalRecordNumber
        ) {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, INVALID_MRN);
        }
      },
      photoInvalid: err => this.onInvalidPhoto(err),
      clearMedicalRecordNumber: () => this.__clearMedicalRecordNumber(),
      clearPIN: () => {
        this.formService.apply('selfCheckInPIN', '');
      },
      focus: () => {
        this.__focused = true;
      },
      blur: () => {
        this.__focused = false;
      },
      changeStatus: e => {
        e = {
          ...e,
          value: e.value ? 'Active' : 'Inactive',
        };

        this.formService.apply(e.name, e.value);

        if (e.value === 'Active') {
          this.formService.apply('statuses.deceased', false);
          this.formService.apply('statuses.dateOfDeath', null);
        }
      },
      changeDeceased: e => {
        this.formService.apply(e.name, e.value);

        if (!e.value) {
          this.formService.apply('statuses.dateOfDeath', null);
        }
      },
      clearDate: () => {
        const name = 'statuses.dateOfDeath';
        this.onChange({ name, value: null });
      },
      uploadDateSelectable: uploadDate =>
        uploadDate <= parseDate().startOf('day'),
      changeReferralCategory: e => {
        if (e.event === 'select') {
          const sourceType = e.value.label;
          const categoryId = e.value.data.id;
          const isBaseSourceType =
            BASE_CATEGORY_SOURCE_TYPES.includes(sourceType);

          this.onChangeReferralCategorySource(null);
          this.formService.apply('referralSource.sourceId', '');

          switch (true) {
            case sourceType === BASE_CATEGORIES.staff.name:
              this.onFetchCategorySources({ sourceType });
              break;
            case sourceType && !isBaseSourceType:
              this.onFetchCategorySources({ categoryId, sourceType });
              break;
            case !sourceType && !categoryId:
              this.__clearReferralSource();
              break;
            default:
          }

          this.formService.apply(e.name, e.value);
          this.formService.apply('referralSource.sourceType', e.value.label);
        }
      },
      updateCategoryReferralSource: item => {
        const { sourceType } = this.state.referralSource;

        if (sourceType === BASE_CATEGORIES.patient.name) {
          this.onChangeReferralCategorySource(item);
          this.formService.apply('referralSource.sourceId', item);
        } else if (item.event === 'select') {
          this.onChangeReferralCategorySource(item.value);
          this.formService.apply('referralSource.sourceId', item.value.data);
        }
      },
    };
  }

  __getName(e) {
    return [this.name, e.name].filter(item => item !== '').join('.');
  }

  __clearReferralSource() {
    this.formService.apply('referralSource.description', '');
    this.formService.apply('referralSource.sourceId', '');
    this.formService.apply('referralSource.sourceType', '');
  }

  buildSelectors() {
    return {
      name: {
        children: {
          first: {
            validators: [required()],
            format: v => (v ? v.trim() : v),
            unformat: v => (v ? v.trim() : v),
          },
          last: {
            validators: [required()],
            format: v => v.trim(),
            unformat: v => v.trim(),
          },
          middle: {
            format: v => v.trim(),
            unformat: v => v.trim(),
          },
          suffix: {
            format: v => v.trim(),
            unformat: v => v.trim(),
          },
          preferred: {
            format: v => v.trim(),
            unformat: v => v.trim(),
          },
        },
      },
      ssn: [isSsn],
      dateOfBirth: [isDate, required(isDate.error)],
      dateOfDeath: [isDate, required(isDate.error)],
      address: {
        children: {
          zipcode: {
            validators: [isZipCode],
          },
        },
      },
      medicalRecordNumber: [this.validateMRN()],
      phoneNumbers: phoneListSelectorOld,
      preferredProviderId: selectFormatters(this.providers, {
        empty: { label: '', item: { id: '' } },
      }),
      preferredLocationId: selectFormatters(this.locations, {
        empty: { label: '', item: { id: '' } },
      }),
      emailAddresses: emailListSelectorOld,
      sex: [required()],
      selfCheckInPIN: {
        validators: [
          {
            error: 'PIN must be between 4 and 20 characters',
            validate: v => (v ? v.length >= 4 && v.length <= 20 : true),
          },
        ],
        format: v => (v ? v.trim() : ''),
        unformat: v => (v ? v.trim() : ''),
      },
      referralSource: {
        children: {
          categoryId: {
            ...selectors.select(this.referralCategories, selectors.ITEM_EMPTY),
            format: v => {
              if (v && this.referralCategories.length) {
                return this.referralCategories.find(item => item.data.id === v);
              }
              return selectors.ITEM_EMPTY;
            },
            unformat: v => (v && v.data ? v.data.id : ''),
          },
          sourceId: {
            format: v => v || null,
            unformat: v => {
              if (v) {
                return v.data ? v.data.id : v.id;
              }
              return '';
            },
          },
        },
      },
    };
  }

  async __handleMedicalRecordNumber() {
    if (this.state.medicalRecordNumber) return;

    let medicalRecordNumber = '';

    try {
      if (
        this.__isAutoGeneratedMedicalRecordNumber(
          this.model.medicalRecordNumber,
        )
      ) {
        medicalRecordNumber = this.model.medicalRecordNumber;
      } else {
        const [nextPatientMRN] = await getNextMRN();
        medicalRecordNumber = nextPatientMRN.medicalRecordNumber;
      }
    } catch (error) {
      console.error(error);
    }

    this.__nextMRN = medicalRecordNumber;
    await this.formService.apply('medicalRecordNumber', this.__nextMRN);
    this.__elements.mrnField.inputEl.value = this.__nextMRN;
  }

  async __clearMedicalRecordNumber() {
    const accepted = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CLEAR_MRN_POPUP,
    );

    if (accepted) {
      await this.formService.apply('medicalRecordNumber', '');
    }
  }

  __isAutoGeneratedMedicalRecordNumber(v) {
    return v.match(/^CT[0-9]+$/);
  }

  validateMRN() {
    return {
      error: 'Required',
      validate: v =>
        v === this.model.medicalRecordNumber ||
        v === this.__nextMRN ||
        !this.__isAutoGeneratedMedicalRecordNumber(v),
    };
  }

  focusSocialSecurityNumber() {
    this.shadowRoot.getElementById(ELEMENTS.ssnField.id).focus();
  }

  focusMedicalRecordNumber() {
    this.shadowRoot.getElementById(ELEMENTS.mrnField.id).focus();
  }

  __getActiveReferralCategories() {
    const filteredReferralCategories = this.referralCategories.filter(
      rc => rc.data.active,
    );
    return [selectors.ITEM_EMPTY, ...filteredReferralCategories];
  }

  __getActiveCategorySources() {
    const filteredCategorySources = this.referralCategorySources.filter(
      rc => rc.data.active,
    );

    return [selectors.ITEM_EMPTY, ...filteredCategorySources];
  }

  firstUpdated() {
    this.__elements = {
      mrnField: this.shadowRoot.getElementById(ELEMENTS.mrnField.id),
    };
  }

  updated(changedProps) {
    if (changedProps.has('providers')) {
      this.initForm();
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .form {
          padding: 0;
        }

        :host([layout='small']) .form {
          padding-bottom: ${CSS_SPACING};
        }

        :host([slim]) .form {
          margin: 0;
        }

        :host([layout='small']) .form {
          margin: 0;
          flex-flow: column nowrap;
        }

        .list {
          grid-column: 1 / span 2;
        }

        :host([layout='small']) .list {
          grid-column: 1;
        }

        .switch {
          margin-bottom: ${CSS_SPACING_ROW_LARGE};
        }

        .col-header {
          margin-bottom: 7px;
        }

        :host([restrictedView]) .col-header {
          padding-top: ${CSS_SMALL_SPACING};
        }

        .additional-info {
          padding-top: ${CSS_SPACING};
        }

        .info-section {
          margin-bottom: 14px;
          padding-top: 10px;
        }

        .section {
          display: grid;
          padding: 0 ${CSS_SPACING};
          grid-template-columns: 1fr auto;
          grid-gap: 0 ${CSS_SPACING};
        }

        .col-fields {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .form-fields {
          flex: 1 0 0;
        }

        .status {
          width: 260px;
        }

        :host([layout='small']) .col-fields {
          margin-right: 0;
          grid-template-columns: 1fr;
        }

        .field {
          width: auto;
        }

        .select {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
        }

        .spacer {
          width: 160px;
        }

        .address {
          margin-bottom: 13px;
        }

        .photo {
          margin-top: ${CSS_SPACING};
        }

        :host([layout='small']) .photo {
          margin-left: ${CSS_SPACING};
        }

        .deceased-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
        }

        .padding-bottom {
          padding-bottom: 10px;
        }

        .description-full {
          grid-column: 1/3;
          padding-top: 10px;
        }
      `,
    ];
  }

  __disableReferralDescription() {
    return (
      !this.state.referralSource.categoryId ||
      !this.state.referralSource.categoryId.label
    );
  }

  __renderBasicInformation() {
    return html`
      <neb-header class="col-header" label="Basic Information"></neb-header>

      <div class="section">
        <div class="col-fields">
          <neb-textfield
            id="${ELEMENTS.firstNameField.id}"
            name="name.first"
            class="field"
            label="First Name"
            helper="Required"
            .error="${this.errors.name.first}"
            .value="${this.state.name.first}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.lastNameField.id}"
            name="name.last"
            class="field"
            label="Last Name"
            helper="Required"
            .error="${this.errors.name.last}"
            .value="${this.model.name.last}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.dobField.id}"
            name="dateOfBirth"
            class="field"
            label="Date of Birth"
            helper="Required"
            maxLength="10"
            .inputMode="${'numeric'}"
            .mask="${date}"
            .error="${this.errors.dateOfBirth}"
            .value="${this.state.dateOfBirth}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.mrnField.id}"
            name="medicalRecordNumber"
            class="field"
            label="Medical Record Number (MRN)"
            maxLength="14"
            .mask="${alphanumeric}"
            .error="${this.errors.medicalRecordNumber}"
            .value="${this.state.medicalRecordNumber}"
            .trailingIcon="${this.state.medicalRecordNumber ? 'neb:clear' : ''}"
            .onChange="${this.handlers.change}"
            .onClickIcon="${this.handlers.clearMedicalRecordNumber}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.sexField.id}"
            name="sex"
            class="field"
            label="Sex"
            helper="Required"
            .items="${FORM_DATA.SEX}"
            .error="${this.errors.sex}"
            .value="${this.state.sex}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.pinField.id}"
            name="selfCheckInPIN"
            class="field"
            label="Self Check In PIN"
            helper=" "
            maxLength="20"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .trailingIcon="${this.state.selfCheckInPIN ? 'neb:clear' : ''}"
            .value="${this.state.selfCheckInPIN}"
            .error="${this.errors.selfCheckInPIN}"
            .onChange="${this.handlers.change}"
            .onClickIcon="${this.handlers.clearPIN}"
          ></neb-textfield>
        </div>

        ${this.layout !== 'small' && !this.restrictedView
          ? this.__renderProfilePhoto()
          : ''}
      </div>
    `;
  }

  __renderAdditionalInformation() {
    return html`
      <neb-header
        class="col-header additional-info"
        label="Additional Information"
      ></neb-header>

      <div class="section">
        <div class="col-fields">
          <neb-textfield
            id="${ELEMENTS.middleNameField.id}"
            name="name.middle"
            class="field"
            label="Middle Name"
            helper=" "
            .value="${this.state.name.middle}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.preferredNameField.id}"
            name="name.preferred"
            class="field"
            label="Preferred Name"
            helper=" "
            .value="${this.state.name.preferred}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.suffixNameField.id}"
            name="name.suffix"
            class="field"
            label="Suffix"
            helper=" "
            maxLength="10"
            .value="${this.state.name.suffix}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.ssnField.id}"
            name="ssn"
            class="field"
            label="Social Security Number"
            helper=" "
            maxLength="11"
            .inputMode="${'numeric'}"
            .mask="${ssn}"
            .error="${this.errors.ssn}"
            .value="${this.state.ssn}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.relationshipField.id}"
            name="statuses.relationship"
            class="field"
            label="Relationship Status"
            helper=" "
            .items="${FORM_DATA.RELATIONSHIP_STATUS}"
            .value="${this.state.statuses.relationship}"
            .onChange="${this.handlers.change}"
            .maxVisibleItems="${MAX_VISIBLE_ITEMS}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.employmentField.id}"
            name="statuses.employment"
            class="field"
            label="Employment Status"
            helper=" "
            .items="${FORM_DATA.EMPLOYMENT_STATUS}"
            .value="${this.state.statuses.employment}"
            .onChange="${this.handlers.change}"
            .maxVisibleItems="${MAX_VISIBLE_ITEMS}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.preferredProviderField.id}"
            class="field"
            label="Preferred Provider"
            helper=" "
            name="preferredProviderId"
            .items="${[EMPTY_ITEM, ...this.providers]}"
            .value="${this.state.preferredProviderId}"
            .onChange="${this.handlers.change}"
            wrapText
          ></neb-select>

          ${this.__renderPreferredLocationField()}
        </div>

        ${this.__renderSpacer()}
      </div>
    `;
  }

  __renderSourceInputs() {
    const { sourceType } = this.state.referralSource;

    const searchLabel =
      sourceType === BASE_CATEGORIES.staff.name
        ? BASE_CATEGORIES.staff.name
        : 'Source';

    switch (sourceType) {
      case BASE_CATEGORIES.patient.name:
        return html`
          <neb-patient-search
            id="${ELEMENTS.referralSourceSearchPatient.id}"
            name="referralSource.sourceId"
            label="Select Patient"
            maxVisibleItems="4"
            .excludePatientId="${this.model.id}"
            .patient="${this.referralCategorySource}"
            .onChange="${this.handlers.updateCategoryReferralSource}"
          ></neb-patient-search>
        `;
      case BASE_CATEGORIES.other.name:
      case '':
        return '';
      default:
        return html`
          <neb-select
            id="${ELEMENTS.referralCategorySourceSelect.id}"
            name="referralSource.sourceId"
            label="Select ${searchLabel}"
            maxVisibleItems="4"
            .items="${this.__getActiveCategorySources()}"
            .value="${this.referralCategorySource}"
            .onChange="${this.handlers.updateCategoryReferralSource}"
          ></neb-select>
        `;
    }
  }

  __renderReferralSource() {
    const { sourceType } = this.state.referralSource;
    const addClass = !(
      !sourceType || sourceType === BASE_CATEGORIES.other.name
    );

    const referralClasses = {
      'description-full': addClass,
      field: true,
    };

    return html`
      <neb-header
        class="col-header info-section"
        label="Referred By"
      ></neb-header>

      <div class="section padding-bottom">
        <div class="col-fields">
          <neb-select
            id="${ELEMENTS.referralCategoryField.id}"
            name="referralSource.categoryId"
            label="Referral Category"
            class="select"
            .items="${this.__getActiveReferralCategories()}"
            .value="${this.state.referralSource.categoryId}"
            .onChange="${this.handlers.changeReferralCategory}"
          ></neb-select>

          ${this.__renderSourceInputs()}

          <neb-textfield
            id="${ELEMENTS.referralDescriptionField.id}"
            name="referralSource.description"
            class="${classMap(referralClasses)}"
            label="Description"
            maxLength="255"
            .error="${this.errors.referralSource.description}"
            .value="${this.state.referralSource.description}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.__disableReferralDescription()}"
          ></neb-textfield>
        </div>

        ${this.__renderSpacer()}
      </div>
    `;
  }

  __renderContactInformation() {
    return html`
      <neb-header
        class="col-header info-section"
        label="Contact Information"
      ></neb-header>

      <div class="section">
        <neb-address
          id="${ELEMENTS.addressForm.id}"
          name="address"
          class="address"
          .model="${this.state.address}"
          .errors="${this.errors.address}"
          .onChange="${this.handlers.change}"
        ></neb-address>
        ${this.__renderSpacer()}
      </div>

      <div class="section">
        <div class="col-fields">
          <neb-phone-list
            id="${ELEMENTS.phoneItems.id}"
            name="phoneNumbers"
            class="list"
            .model="${this.state.phoneNumbers}"
            .errors="${this.errors.phoneNumbers}"
            .onAdd="${this.handlers.addItem}"
            .onRemove="${this.handlers.removeItem}"
            .onChange="${this.handlers.change}"
          ></neb-phone-list>

          <neb-email-list
            id="${ELEMENTS.emailItems.id}"
            name="emailAddresses"
            class="list"
            addLabel="Add Email Address"
            maxItems="3"
            .model="${this.state.emailAddresses}"
            .errors="${this.errors.emailAddresses}"
            .onAdd="${this.handlers.addItem}"
            .onRemove="${this.handlers.removeItem}"
            .onChange="${this.handlers.change}"
            condense
          ></neb-email-list>
        </div>

        ${this.__renderSpacer()}
      </div>
    `;
  }

  __renderSpacer() {
    return this.layout !== 'small' && !this.restrictedView
      ? html` <div class="spacer"></div> `
      : '';
  }

  __renderPreferredLocationField() {
    return html`
      <neb-select
        id="${ELEMENTS.preferredLocationField.id}"
        class="field"
        label="Preferred Location"
        helper=""
        name="preferredLocationId"
        .items="${this.activeLocations}"
        .value="${this.state.preferredLocationId}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderDeceasedFields(on) {
    const selectedDate = this.state.statuses.dateOfDeath
      ? parseDate(this.state.statuses.dateOfDeath)
      : null;

    return !on
      ? html`
          <div class="deceased-container">
            <neb-checkbox
              id="${ELEMENTS.deceasedCheckbox.id}"
              class="field"
              name="statuses.deceased"
              label="Patient is deceased"
              .value="${this.state.statuses.deceased}"
              .onChange="${this.handlers.changeDeceased}"
              ?checked="${this.state.statuses.deceased}"
            ></neb-checkbox>

            <neb-date-picker
              id="${ELEMENTS.dodField.id}"
              class="field"
              name="statuses.dateOfDeath"
              placeholder="Date of Death"
              .manualPopoverPosition="${POPOVER_POSITION.TOP}"
              .helperText="${this.helpers.dateOfDeath || ' '}"
              .selectedDate="${selectedDate}"
              .error="${this.errors.dateOfDeath}"
              .isDateSelectable="${this.handlers.uploadDateSelectable}"
              .onBlur="${this.handlers.blur}"
              .onFocus="${this.handlers.focus}"
              .onChange="${this.handlers.change}"
              .onClear="${this.handlers.clearDate}"
              momentFlag
              ?disabled="${!this.state.statuses.deceased}"
            ></neb-date-picker>
          </div>
        `
      : '';
  }

  __renderPatientStatus() {
    const on = this.state.statuses.patient === 'Active';

    return this.showPatientStatus
      ? html`
          <div class="section">
            <div class="col-fields">
              <neb-switch
                id="${ELEMENTS.switchInactivePatient.id}"
                name="statuses.patient"
                class="list switch"
                label="${this.state.statuses.patient}"
                .onChange="${this.handlers.changeStatus}"
                .value="${this.state.statuses.patient}"
                .on="${on}"
              ></neb-switch>

              ${this.__renderDeceasedFields(on)}
            </div>
          </div>
        `
      : '';
  }

  __renderPreviousPatientSwitch() {
    return html`
      <div class="section">
        <div class="col-fields">
          <neb-switch
            id="${ELEMENTS.switchPreviousPatient.id}"
            name="previousPatient"
            class="list switch"
            label="Previous Patient"
            .on="${this.state.previousPatient}"
            .onChange="${this.handlers.change}"
          ></neb-switch>
        </div>
      </div>
    `;
  }

  __renderProfilePhoto() {
    return html`
      <neb-profile-photo-upload
        id="${ELEMENTS.photoUpload.id}"
        name="photoSrc"
        class="photo"
        .model="${getPhotoModel(this.state)}"
        .onChange="${this.handlers.change}"
        .onInvalidFile="${this.handlers.photoInvalid}"
      ></profile-photo-upload>
        `;
  }

  renderActionBar() {
    return this.__dirty || this.baseURI.includes('scheduling')
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderBasicInformation()} ${this.__renderAdditionalInformation()}
      ${this.restrictedView ? '' : this.__renderReferralSource()}
      ${this.__renderContactInformation()}${this.restrictedView
        ? ''
        : this.__renderPreviousPatientSwitch()}
      ${this.__renderPatientStatus()}
      ${this.layout === 'small' ? this.__renderProfilePhoto() : ''}
    `;
  }
}

customElements.define('neb-form-patient', NebFormPatient);
