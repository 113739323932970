import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});

export function createBlockedOffTime(
  appointment,
  optOutLoadingIndicator = false,
) {
  return appointmentsApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/blocked-off-time',
    body: JSON.stringify(appointment),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
    updateNotificationDetails: { blockedOffTime: { ...appointment } },
  });
}

export function updateBlockedOffTime(groupId, appointment, queryParams) {
  return appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/blocked-off-time/:groupId',
    replacements: { groupId },
    queryParams,
    body: JSON.stringify(appointment),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: { blockedOffTime: { ...appointment } },
  });
}

export function deleteBlockedOffTime(groupId) {
  return appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/blocked-off-time/:groupId',
    replacements: { groupId },
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    updateNotificationDetails: { blockedOffTime: { groupId } },
  });
}
