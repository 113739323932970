import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../inputs/neb-select';
import '../../../../../src/components/misc/neb-icon';
import '../neb-profile-photo-upload';
import '../neb-tab-old';
import '../neb-tabs-old';
import './neb-name';
import '../neb-header';

import { isRequired, isEmailAddress, atMin, atMax } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { saveImage } from '../../../../neb-api-client/src/image-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { COGNITO_TYPES } from '../../../../neb-utils/cognito-util';
import { objToInitials } from '../../../../neb-utils/formatters';
import * as selectors from '../../../../neb-utils/selectors';

import PhoneList from './neb-phone-list';

export const ELEMENTS = {
  skipSignMessage: {
    id: 'checkbox-skip-sign-message',
    text: 'Do not display encounter signing confirmation dialog',
  },
  createEncounterAtCheckIn: {
    id: 'checkbox-create-encounter-at-check-in',
    text: 'Create an encounter at check in',
  },
  names: {
    id: 'names',
  },
  email: {
    id: 'email',
  },
  phones: {
    id: 'phone-list',
  },
  photoUpload: {
    id: 'photo-upload',
  },
  biography: {
    id: 'biography',
  },
  changePasswordContainer: {
    id: 'change-password-container',
  },
  changePasswordIcon: {
    id: 'change-password-icon',
  },
  locationsSelect: { id: 'select-locations' },
  ddRetention: {
    id: 'ddRetention',
    text: 'DD RUM Retention (0-100%) or empty (use default config value)',
  },
};

export const EMPTY_ERRORS = {
  name: {
    first: '',
    last: '',
    middle: '',
    preferred: '',
    suffix: '',
  },
  phones: [
    {
      number: '',
      type: '',
    },
  ],
  email: '',
  active: '',
  biography: '',
  photo: {
    imageUrl: '',
    src: '',
  },
  skipSignMessage: false,
  createEncounterAtCheckIn: false,
  ddRetention: '',
};

const PROFILE_PHOTO_PREVIEW_TITLE = 'User Photo';
const HELPERS_REQUIRED = {
  first: 'Required',
  last: 'Required',
};

export default class NebProfile extends LitElement {
  static get properties() {
    return {
      errors: Object,
      disableEmail: Boolean,
      model: Object,
      showBiography: {
        attribute: 'show-biography',
        reflect: true,
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      locations: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  static createModel() {
    return {
      name: {
        first: '',
        last: '',
        middle: '',
        preferred: '',
        suffix: '',
      },
      phones: [
        {
          number: '',
          type: '',
        },
      ],
      email: '',
      active: true,
      biography: '',
      photo: {
        imageUrl: '',
        src: '',
      },
      defaultLocationId: '',
      skipSignMessage: false,
      createEncounterAtCheckIn: false,
      ddRetention: null,
    };
  }

  static createSelectors(items) {
    return {
      name: {
        children: {
          first: [isRequired()],
          last: [isRequired()],
        },
      },
      email: [isRequired(), isEmailAddress('email@email.com')],
      phones: PhoneList.createSelectors(),
      photo: { clipPristine: true },
      defaultLocationId: selectors.select(items.locations),
      ddRetention: {
        format: v => (v == null ? null : v.toString()),
        unformat: v => (v === '' ? null : parseInt(v, 10)),
        validators: [
          atMin(0, true, 'Must be greater or equal to 0'),
          atMax(100, true, 'Must be less or equal to 100'),
        ],
      },
    };
  }

  __initState() {
    this.layout = '';
    this.disableEmail = false;
    this.showBiography = false;
    this.maxLength = 500;
    this.model = this.constructor.createModel();
    this.errors = EMPTY_ERRORS;
    this.locations = [];
    this.__defaultLocation = '';
    this.__allAccessLocations = [];
    this.__cognitoType = null;

    this.onChange = () => {};

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onPhotoInvalid = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      add: name => this.onAdd(name),
      change: e => this.onChange(e),
      photoChange: e => this.__handlePhotoChange(e),
      photoInvalid: err => this.onPhotoInvalid(err),
      remove: (name, index) => this.onRemove(name, index),
      changePassword: async () => {
        await openPopup(POPUP_RENDER_KEYS.CHANGE_PASSWORD, {
          email: this.model.email,
        });
      },
    };
  }

  async __handlePhotoChange({ name, value }) {
    let photo = { ...this.model.photo, src: value };

    if (value) {
      const { imageUrl } = await saveImage('profile-image', value);
      photo = { ...photo, imageUrl };
    } else if (!this.__initialPhotoModel.imageUrl) {
      photo = { ...this.__initialPhotoModel };
    }

    this.onChange({
      name,
      value: photo,
    });
  }

  __getPhotoModel() {
    return {
      active: this.model.active,
      imgSrc: this.model.photo.src || '',
      initials: objToInitials(this.model.name),
    };
  }

  __filterLocationsAccess(items, accessLocations = []) {
    const filteredLocations = !items
      ? selectors.ITEM_EMPTY
      : items.filter(location =>
          accessLocations.some(l => l.data.id === location.data.id),
        );

    return !filteredLocations.length
      ? [selectors.ITEM_EMPTY]
      : filteredLocations;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__defaultLocation = this.__filterLocationsAccess(
        [this.model.defaultLocationId],
        this.model.locations,
      )[0];

      this.__allAccessLocations = this.__filterLocationsAccess(
        this.locations,
        this.model.locations,
      );
    }

    super.update(changedProps);
  }

  firstUpdated() {
    this.__initialPhotoModel = this.model.photo;
  }

  connectedCallback() {
    super.connectedCallback();
    this.__cognitoType = store.getState().session?.item?.type;
  }

  __renderProfilePhotoUpload() {
    return this.__cognitoType === COGNITO_TYPES.SUPPORT
      ? ''
      : html`
          <neb-profile-photo-upload
            id="${ELEMENTS.photoUpload.id}"
            class="photo"
            name="photo"
            .model="${this.__getPhotoModel()}"
            .previewTitle="${PROFILE_PHOTO_PREVIEW_TITLE}"
            .onChange="${this.__handlers.photoChange}"
            .onInvalidFile="${this.__handlers.photoInvalid}"
          ></neb-profile-photo-upload>
        `;
  }

  __renderChangePassword() {
    const state = store.getState();
    return this.model.id === state.session.item.id
      ? html`
          <div
            id="${ELEMENTS.changePasswordContainer.id}"
            class="change-password-container"
            @click="${this.__handlers.changePassword}"
          >
            <neb-icon
              id="${ELEMENTS.changePasswordIcon.id}"
              class="change-password-icon"
              icon="neb:edit"
            ></neb-icon>
            <div class="change-password-label">Change Password</div>
          </div>
        `
      : '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .name {
          --row-gap: 13px;
        }

        .container {
          display: flex;
          flex-flow: column nowrap;
        }

        :host([layout='small']) .content-form {
          flex-flow: column-reverse nowrap;
          align-items: center;
        }

        .content-form {
          display: flex;
          flex-flow: row nowrap;
          padding: ${CSS_SPACING};
        }

        .col-fields {
          display: grid;
          grid-gap: 13px ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          flex: 1 0 0;
        }

        .switch {
          grid-column-start: 1;
          padding-top: 20px;
        }

        .photo {
          margin-left: 12px;
        }

        .container-email {
          display: grid;
          grid-gap: ${CSS_SPACING} ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .bio {
          height: 170px;
        }

        .change-password-container {
          display: flex;
          margin: ${CSS_SPACING};
          cursor: pointer;
        }

        .change-password-icon {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .change-password-label {
          padding: 0 0 0 4px;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .checkbox {
          margin-top: ${CSS_SPACING};
        }

        .default-location {
          display: grid;
          grid-gap: ${CSS_SPACING} ${CSS_SPACING};
          grid-template-columns: 2fr 0.56fr;
        }

        .preferences {
          display: flex;
          flex-flow: column;
          padding: ${CSS_SPACING};
        }

        .support {
          display: flex;
          flex-flow: column;
          padding: ${CSS_SPACING};
        }

        .container-dd-retention {
          display: grid;
          grid-gap: ${CSS_SPACING} ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }
      `,
    ];
  }

  __renderBiography() {
    return this.showBiography
      ? html`
          <neb-textarea
            id="${ELEMENTS.biography.id}"
            class="bio"
            label="Biography"
            name="biography"
            .maxLength="${this.maxLength}"
            .value="${this.model.biography}"
            .error="${this.errors.biography}"
            .onChange="${this.__handlers.change}"
            showCount
          ></neb-textarea>
        `
      : '';
  }

  __renderLocationsDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="locations-select"
        label="Default Location"
        name="defaultLocationId"
        .items="${[selectors.ITEM_EMPTY, ...this.__allAccessLocations]}"
        .value="${this.__defaultLocation}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderCheckBox() {
    return ['provider', 'specialist'].includes(this.model.type)
      ? html`
          <neb-checkbox
            id="${ELEMENTS.skipSignMessage.id}"
            label="${ELEMENTS.skipSignMessage.text}"
            name="skipSignMessage"
            .onChange="${this.__handlers.change}"
            ?checked="${this.model.skipSignMessage}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderCreateEncounterCheckbox() {
    return ['provider', 'specialist'].includes(this.model.type)
      ? html`
          <neb-checkbox
            id="${ELEMENTS.createEncounterAtCheckIn.id}"
            label="${ELEMENTS.createEncounterAtCheckIn.text}"
            name="createEncounterAtCheckIn"
            .onChange="${this.__handlers.change}"
            ?checked="${this.model.createEncounterAtCheckIn}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderSupport() {
    if (this.__cognitoType !== COGNITO_TYPES.SUPPORT) return html``;

    return html`
      <neb-header label="Support"> </neb-header>
      <div class="support col-fields">
        <div class="container-dd-retention">
          <neb-textfield
            id="${ELEMENTS.ddRetention.id}"
            name="ddRetention"
            maxLength="3"
            label="${ELEMENTS.ddRetention.text}"
            .value="${this.model.ddRetention}"
            .error="${this.errors.ddRetention}"
            .onChange="${this.__handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content-form">
          <div class="col-fields">
            <neb-name
              id="${ELEMENTS.names.id}"
              class="name"
              name="name"
              .model="${this.model.name}"
              .errors="${this.errors.name}"
              .helpers="${HELPERS_REQUIRED}"
              .onChange="${this.__handlers.change}"
              showSuffix
            >
            </neb-name>

            <neb-phone-list
              id="${ELEMENTS.phones.id}"
              class="list"
              name="phones"
              .model="${this.model.phones}"
              .errors="${this.errors.phones}"
              .onAdd="${this.__handlers.add}"
              .onRemove="${this.__handlers.remove}"
              .onChange="${this.__handlers.change}"
            ></neb-phone-list>

            <div class="container-email">
              <neb-textfield
                id="${ELEMENTS.email.id}"
                name="email"
                label="E-mail"
                helper="Required"
                .value="${this.model.email}"
                .error="${this.errors.email}"
                .onChange="${this.__handlers.change}"
                ?disabled="${this.disableEmail}"
              ></neb-textfield>
            </div>

            ${this.__renderBiography()}
          </div>

          <div>
            ${this.__renderProfilePhotoUpload()}
            ${this.__renderChangePassword()}
          </div>
        </div>

        <neb-header label="Preferences"> </neb-header>

        <div class="preferences col-fields">
          <div class="default-location">
            ${this.__renderLocationsDropdown()}
          </div>
          <div class="checkbox">${this.__renderCheckBox()}</div>
          <div class="checkbox">${this.__renderCreateEncounterCheckbox()}</div>
        </div>
        ${this.__renderSupport()}
      </div>
    `;
  }
}
customElements.define('neb-profile', NebProfile);
