import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../forms/neb-form-payment';
import { html, css } from 'lit';

import { updatePayment } from '../../../packages/neb-api-client/src/payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  form: {
    id: 'form',
  },
  header: {
    id: 'header',
  },
};
export const BANNER_MESSAGE = {
  success: 'Payment updated successfully',
  error: 'An error occurred when updating the Payment',
};

const DESCRIPTION = `Update payment details. 
The ledger balance and available amounts will not be impacted. 
Any allocations will remain associated with the payment.`;
class NebOverlayUpdatePayment extends Overlay {
  static get properties() {
    return {
      __savingError: Object,
      __locations: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          flex-flow: column nowrap;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .description {
          padding: 8px ${CSS_SPACING} ${CSS_SPACING};
        }

        .form {
          flex: 1 0 0;
          overflow: auto;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__formModel = {};
    this.__savingError = null;
    this.__locations = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,

      save: async formData => {
        try {
          const res = await updatePayment(this.model.id, formData);
          this.isDirty = false;
          this.__savingError = null;
          store.dispatch(openSuccess(BANNER_MESSAGE.success));
          this.dismiss(res);
        } catch (e) {
          this.__savingError = e;
          store.dispatch(openError(BANNER_MESSAGE.error));
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = {
        ...this.model,
        payerPlanId: this.model.payerPlanId || '',
        transactionDate: this.model.transactionDate
          ? parseDate(this.model.transactionDate).toISOString()
          : null,
        dateOfServiceFrom: this.model.dateOfServiceFrom
          ? parseDate(this.model.dateOfServiceFrom).toISOString()
          : null,
        dateOfServiceTo: this.model.dateOfServiceTo
          ? parseDate(this.model.dateOfServiceTo).toISOString()
          : null,
      };
    }
    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.model.payerPlanId
          ? 'Update Payer Payment'
          : 'Update Patient Payment'}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        .showBackButton="${true}"
        showCancelButton
      ></neb-popup-header>
      <div id="${ELEMENTS.description.id}" class="description">
        ${DESCRIPTION}
      </div>
      <neb-form-payment
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .forPayer="${!!this.model.payerPlanId}"
        .patientId="${this.model.patientId}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .savingError="${this.__savingError}"
      ></neb-form-payment>
    `;
  }
}

customElements.define('neb-overlay-update-payment', NebOverlayUpdatePayment);
