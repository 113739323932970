import './neb-calendar-popover';
import '../../../../src/components/misc/neb-icon';
import './inputs/neb-text-helper';
import './inputs/neb-textfield';
import { LitElement, html, css } from 'lit';

import { baseStyles, CSS_COLOR_DISABLED } from '../../../../src/styles';
import { date as dateMask } from '../../../neb-utils/masks';
import {
  getDatePickerLocaleDate,
  parseDatePickerInput,
} from '../../../neb-utils/moment-flag-conversion';
import { isDate } from '../../../neb-utils/validators';

export const ELEMENTS = {
  blocker: { id: 'blocker' },
  containerPopover: { id: 'container-popover' },
  textfield: { id: 'textfield' },
  popover: { id: 'popover' },
};

export const POPOVER_POSITION = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

export const POPOVER_HORIZ_ALIGN = {
  NONE: '',
  LEFT: 'left',
};

export class NebDatePicker extends LitElement {
  static get properties() {
    return {
      __textInputDate: String,
      __popoverPosition: String,
      __clearSelectedDate: Boolean,

      customBoundTop: Number,
      customBoundBottom: Number,
      isDateSelectable: Function,
      updateDependency: String,
      manualPopoverPosition: String,
      manualPopoverHorizontalAlignment: String,
      name: String,
      placeholder: String,
      invalidText: String,
      helperText: String,
      nonSelectableDateText: String,
      invalidTextInput: String,
      momentFlag: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      invalid: {
        type: Boolean,
        reflect: true,
      },
      label: {
        type: String,
        reflect: true,
      },
      selectedDate: {
        type: Object,
        reflect: true,
      },
      showPopover: {
        type: Boolean,
        reflect: true,
        attribute: 'showpopover',
      },
      readOnly: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__textInputDate = '';
    this.invalidTextInput = '';
    this.__popoverPosition = POPOVER_POSITION.BOTTOM;
    this.__clearSelectedDate = false;

    this.momentFlag = false;
    this.readOnly = false;
    this.customBoundTop = 0;
    this.customBoundBottom = 0;
    this.invalid = false;
    this.label = '';
    this.manualPopoverPosition = '';
    this.manualPopoverHorizontalAlignment = POPOVER_HORIZ_ALIGN.NONE;
    this.updateDependency = null;
    this.name = '';
    this.placeholder = '';
    this.invalidText = 'Required';
    this.helperText = '';
    this.nonSelectableDateText = 'Date is not selectable';
    this.selectedDate = null;
    this.showPopover = false;

    this.isDateSelectable = () => true;

    this.onClick = () => {};

    this.onClear = () => {};

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      present: () => {
        if (!this.disabled) this.showPopover = true;
        this.__handlers.handleWindowResize();
        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      dismiss: () => {
        this.showPopover = false;
        this.__elements.calendarPopover.reset();
        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      setTextInput: e => {
        this.__clearSelectedDate = false;
        this.__textInputDate = e.value;

        const isValidDate = this.__textInputDate
          ? isDate.validate(this.__textInputDate)
          : false;

        if (isValidDate) {
          const parsedDate = parseDatePickerInput(
            this.momentFlag,
            this.__textInputDate,
          );

          if (this.isDateSelectable(parsedDate)) {
            this.invalidTextInput = '';

            this.selectedDate = parsedDate;
            this.onClick(this.selectedDate);
          } else {
            this.invalidTextInput = this.nonSelectableDateText;
            this.__clearTextInputSelectedDate();
          }
        } else {
          this.invalidTextInput = this.__textInputDate ? 'mm/dd/yyyy' : '';
          this.__clearTextInputSelectedDate();
        }

        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      clearDate: () => {
        this.__clearSelectedDate = true;
        this.__textInputDate = '';
        this.selectedDate = null;
        this.showPopover = false;
        this.onClear();
        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      dateSelected: date => {
        this.__clearSelectedDate = false;
        this.invalidTextInput = '';
        this.selectedDate = date;
        this.showPopover = false;
        this.onClick(date);
        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      todaySelected: date => {
        this.__clearSelectedDate = false;
        this.invalidTextInput = '';
        this.selectedDate = date;
        this.showPopover = false;
        this.onClick(date);
        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      },
      resize: () => {
        clearTimeout(this.__resizeTimeoutId);
        this.__resizeTimeoutId = setTimeout(
          this.__handlers.handleWindowResize,
          200,
        );
      },
      handleWindowResize: () => {
        const containerBottom = this.customBoundBottom || window.innerHeight;
        const containerTop = this.customBoundTop || 0;

        const calendarHeight =
          this.__elements.calendarPopover.getBoundingClientRect().height;

        const inputBottom =
          this.__elements.textfield.getBoundingClientRect().bottom;

        const inputTop = this.__elements.textfield.getBoundingClientRect().top;

        if (this.manualPopoverPosition) {
          this.__popoverPosition = this.manualPopoverPosition;
          return;
        }

        this.__setPopoverPosition(
          containerTop,
          containerBottom,
          calendarHeight,
          inputBottom,
          inputTop,
        );
      },
      monthChanged: async () => {
        this.__elements.calendarPopover = await this.shadowRoot.getElementById(
          ELEMENTS.popover.id,
        );

        this.__handlers.handleWindowResize();
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: 200px;
          position: relative;
        }

        :host([disabled]) {
          cursor: default;
          pointer-events: none;
          color: ${CSS_COLOR_DISABLED};
        }

        .blocker {
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          background-color: transparent;
        }

        .container-input {
          width: 100%;
        }

        .popover-container {
          transition: opacity 0.2s ease-out;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }

        .popover-container[show] {
          opacity: 1;
          pointer-events: initial;
        }

        .popover-container[manualpopoverhorizontalalignment='left'] {
          left: 90%;
        }
      `,
    ];
  }

  __setPopoverPosition(
    containerTop,
    containerBottom,
    calendarHeight,
    inputBottom,
    inputTop,
  ) {
    if (calendarHeight + inputBottom <= containerBottom) {
      this.__popoverPosition = POPOVER_POSITION.BOTTOM;
    } else if (inputTop - calendarHeight >= containerTop) {
      this.__popoverPosition = POPOVER_POSITION.TOP;
    } else {
      this.__popoverPosition = POPOVER_POSITION.CENTER;
    }
  }

  firstUpdated() {
    this.__elements = {
      calendarPopover: this.shadowRoot.getElementById(ELEMENTS.popover.id),
      textfield: this.shadowRoot.getElementById(ELEMENTS.textfield.id),
    };

    setTimeout(() => this.__handlers.handleWindowResize());
  }

  __checkSelectedDate(changedProps) {
    return (
      changedProps.has('selectedDate') &&
      !this.selectedDate &&
      !this.__elements.textfield.hasAttribute('focused')
    );
  }

  updated(changedProps) {
    if (changedProps.has('disabled') && this.disabled) {
      this.showPopover = false;
    }

    if (this.__checkSelectedDate(changedProps)) {
      this.__textInputDate = '';
      this.invalidTextInput = '';
      this.selectedDate = null;
    }

    if (changedProps.has('placeholder') && !this.selectedDate) {
      this.__textInputDate = '';
      this.invalidTextInput = '';
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.__handlers.resize);
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener('resize', this.__handlers.resize);
  }

  validateDate() {
    const isValidDate = this.__textInputDate
      ? isDate.validate(this.__textInputDate)
      : false;

    if (isValidDate) {
      const parsedDate = parseDatePickerInput(
        this.momentFlag,
        this.__textInputDate,
      );

      if (this.isDateSelectable(parsedDate)) {
        this.invalidTextInput = '';
        this.selectedDate = parsedDate;

        this.onChange({
          name: this.name,
          value: this.selectedDate,
        });
      } else {
        this.invalidTextInput = this.nonSelectableDateText;
      }
    } else {
      this.invalidTextInput = this.__textInputDate ? 'mm/dd/yyyy' : '';
    }
  }

  __clearTextInputSelectedDate() {
    if (this.selectedDate) {
      this.selectedDate = null;
      this.onClear();
    }
  }

  __getTextInputDate() {
    return this.selectedDate
      ? getDatePickerLocaleDate(this.momentFlag, this.selectedDate)
      : this.__textInputDate;
  }

  __getInvalidText() {
    if (this.invalidTextInput) {
      return this.invalidTextInput;
    }

    return this.invalid ? this.invalidText : '';
  }

  __setPopoverStyle() {
    if (this.__elements) {
      switch (this.__popoverPosition) {
        case POPOVER_POSITION.CENTER:
          return 'position:fixed; top: 50%; left: 50%; transform: translate(-50%, -50%)';

        case POPOVER_POSITION.TOP:
          return 'bottom: 40px';

        default:
          return '';
      }
    }

    return '';
  }

  render() {
    return html`
      ${this.showPopover
        ? html`
            <div
              id="${ELEMENTS.blocker.id}"
              class="blocker"
              @click="${this.__handlers.dismiss}"
            ></div>
          `
        : ''}

      <div class="container">
        <neb-textfield
          id="${ELEMENTS.textfield.id}"
          class="container-input"
          inputMode="numeric"
          .label="${this.label}"
          .placeholder="${this.placeholder}"
          .name="${this.name}"
          .value="${this.__getTextInputDate()}"
          .trailingIcon="${this.selectedDate ? 'neb:clear' : 'neb:calendar'}"
          .onClickIcon="${this.selectedDate
            ? this.__handlers.clearDate
            : this.__handlers.present}"
          .onChange="${this.__handlers.setTextInput}"
          .mask="${dateMask}"
          .error="${this.__getInvalidText()}"
          .helper="${this.helperText || ' '}"
          .readonly="${this.readOnly ? !!this.selectedDate : false}"
          ?disabled="${this.disabled}"
        ></neb-textfield>

        <div
          id="${ELEMENTS.containerPopover.id}"
          class="popover-container"
          manualPopoverHorizontalAlignment="${this
            .manualPopoverHorizontalAlignment}"
          ?show="${this.showPopover}"
          style="${this.__setPopoverStyle()}"
        >
          <neb-calendar-popover
            id="${ELEMENTS.popover.id}"
            .selectedDate="${this.selectedDate}"
            .onDateSelected="${this.__handlers.dateSelected}"
            .onTodaySelected="${this.__handlers.todaySelected}"
            .isDateSelectable="${this.isDateSelectable}"
            .isVisible="${this.showPopover}"
            .popoverPosition="${this.__popoverPosition}"
            .onMonthChanged="${this.__handlers.monthChanged}"
            .isPickerable="${true}"
            .updateDependency="${this.updateDependency}"
            .clearSelectedDate="${this.__clearSelectedDate}"
            ?momentFlag="${this.momentFlag}"
            incrementDisplayMonth
            tabindex="${this.showPopover ? 0 : -1}"
          ></neb-calendar-popover>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-date-picker', NebDatePicker);
