import DeprecatedApiClient, { Method } from './utils/api-client-utils';

const apiClient = new DeprecatedApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const createInsurance = (data, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    path: `patients/${data.patientId}/real-time-eligibility`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
    version: 2,
    optOutLoadingIndicator,
  });

export const updateInsurance = (
  patientInsuranceId,
  patientId,
  data,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: `patients/${patientId}/real-time-eligibility/${patientInsuranceId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.PUT,
    version: 2,
    optOutLoadingIndicator,
  });
