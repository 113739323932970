import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import { CSS_SPACING, OVERLAY_WIDTH_LARGE } from '../../../../../src/styles';
import * as api from '../../../../neb-api-client/src/person-api-client';
import {
  openError,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import FormPerson from '../forms/neb-form-person';

import OverlayFormItem, {
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-form-item';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
};

function mapToGuarantorFormNonsense(raw) {
  return {
    ...raw,
    dateOfBirth: raw.dateOfBirth
      ? moment(raw.dateOfBirth).format('MM/DD/YYYY')
      : '',
  };
}

class OverlayPerson extends OverlayFormItem {
  static get config() {
    return {
      itemName: 'Guarantor',
      form: FormPerson,
      messageSuccess: 'Guarantor saved successfully',
      messageError: 'Guarantor was not saved successfully',
    };
  }

  static get properties() {
    return {
      __savingError: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          grid-template-rows: max-content;
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.showBackButton = true;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async item => {
        try {
          const result = await this.save(item);

          if (result) {
            store.dispatch(openSuccess(this.constructor.config.messageSuccess));
            this.isDirty = false;
            this.dismiss(result);
          } else {
            this.__savingError = { label: 'Potential Duplicate Guarantor' };
          }
        } catch (e) {
          if (e.statusCode !== 409) {
            store.dispatch(openError(this.constructor.config.messageError));
          }
        }
      },
    };
  }

  getTitle() {
    const { itemName } = this.constructor.config;
    return `${this.isEditing() ? 'Update' : 'Create'} ${itemName}`;
  }

  async save(item) {
    const req = item.id ? api.update : api.add;

    // TODO cgenest: Refactor code to perform duplication check pre-flight.
    // TODO cgenest: Update www/ms-billing model to meet current patterns (name, address, etc).
    // TODO cgenest: Refactor guarantor form. :<
    try {
      const res = await req(item, true);

      return mapToGuarantorFormNonsense(res);
    } catch (err) {
      console.error(err);

      if (err.statusCode === 409) {
        const res = await this.__handleConflict(item);

        if (res) {
          return mapToGuarantorFormNonsense(res);
        }

        this.__savingError = { label: 'Potential Duplicate Guarantor' };
      } else {
        store.dispatch(
          openError('An error occurred when saving the Guarantor'),
        );
      }
    }

    return null;
  }

  async __handleConflict(item) {
    const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Possible Duplicate Guarantor',
      message:
        'There is an existing patient or guarantor with the same first and last name. Do you want to continue with the creation of this guarantor?',
      confirmText: 'Create New Guarantor',
      cancelText: 'Cancel',
    });

    if (response) return api.add(item, true, true);

    return null;
  }

  renderForm() {
    return html`
      <neb-form-person
        id="${ELEMENTS.form.id}"
        confirmLabel="Save Guarantor"
        .model="${this.getModel()}"
        .savingError="${this.__savingError}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onSave="${this.handlers.save}"
      ></neb-form-person>
    `;
  }
}

customElements.define('neb-overlay-person', OverlayPerson);
