export const isOverlayOpen = (popupOverlays = [], overlayKey) =>
  popupOverlays.find(({ key }) => key === overlayKey);

export const toMilliseconds = timespan => {
  const msHours = (timespan.hours || 0) * (1000 * 60 * 60);
  const msMinutes = (timespan.minutes || 0) * (1000 * 60);
  const msSeconds = (timespan.seconds || 0) * 1000;

  return msHours + msMinutes + msSeconds;
};

export const toTimespan = ms => {
  const seconds = Math.floor(ms / 1000);

  return {
    seconds: Math.floor(seconds % 60),
    minutes: Math.floor((seconds / 60) % 60),
    hours: Math.floor(seconds / 3600),
  };
};
