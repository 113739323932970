import { html, css } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_COLOR_GREY_1,
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  subtitle: { id: 'subtitle' },
  narrative: { id: 'narrative' },
  buttonAppend: { id: 'button-append' },
  buttonReplace: { id: 'button-replace' },
  buttonCancel: { id: 'button-cancel' },
};

class NebPopupSubjectiveQuestionnaireAppendReplace extends NebPopup {
  initState() {
    super.initState();
    this.title = 'Append or Replace Patient Subjective';
    this.subtitle =
      'Below is a preview of the patient narrative for the selected subjective complaint questionnaire. Would you like to add this narrative at the end of the existing subjective note or replace the existing subjective note with the new narrative?';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () => {
      this.onClose();
    };

    this.handlers.append = () => {
      this.onClose('append');
    };

    this.handlers.replace = () => {
      this.onClose('replace');
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          width: ${OVERLAY_WIDTH_LARGE};
          overflow-y: auto;
        }

        :host(:not([layout='large'])) {
          width: auto;
        }

        .action-buttons {
          display: flex;
          justify-content: space-between;
        }

        .button {
          margin-right: ${CSS_SPACING};
        }

        .content {
          flex: unset;
          display: flex;
          flex-direction: column;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        }

        .narrative-container {
          border-style: solid;
          border-color: ${CSS_COLOR_GREY_1};
          border-width: 1px 0;
          margin: ${CSS_SPACING} 0;
          padding: 10px 0;
          overflow-y: auto;
        }

        .narrative-container p {
          margin: 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div id="${ELEMENTS.subtitle.id}">${this.subtitle}</div>
        <div class="narrative-container">
          <div id="${ELEMENTS.narrative.id}">
            ${unsafeHTML(this.model.narrative)}
          </div>
        </div>
        <div class="action-buttons">
          <div>
            <neb-button
              id="${ELEMENTS.buttonAppend.id}"
              class="button"
              role="${BUTTON_ROLE.CONFIRM}"
              .label="${'Append'}"
              .onClick="${this.handlers.append}"
            ></neb-button>
            <neb-button
              id="${ELEMENTS.buttonReplace.id}"
              class="button"
              role="${BUTTON_ROLE.CONFIRM}"
              .label="${'Replace'}"
              .onClick="${this.handlers.replace}"
            ></neb-button>
          </div>
          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            role="${BUTTON_ROLE.CANCEL}"
            .label="${'Cancel'}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-subjective-questionnaire-append-replace',
  NebPopupSubjectiveQuestionnaireAppendReplace,
);
