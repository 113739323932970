import { html, css } from 'lit';

import NebFormMacroRegion from '../../../../packages/neb-lit-components/src/components/forms/neb-form-macro-region';
import { REGION_LENGTH } from '../../../../packages/neb-lit-components/src/components/macros/neb-macro-region';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

const formatModel = ({ question, answerSets, defaultBodyAnswerSetId }) => {
  if (question) {
    const defaultIndices = new Array(REGION_LENGTH).fill(null);

    question.defaultIndices.forEach(i => {
      defaultIndices[i] = i;
    });

    const answerSet =
      question.answerSetId === defaultBodyAnswerSetId
        ? NebFormMacroRegion.createModel(defaultBodyAnswerSetId).answerSet
        : answerSets[question.answerSetId];

    return {
      question: {
        ...question,
        defaultIndices,
      },
      answerSet,
    };
  }

  return NebFormMacroRegion.createModel(defaultBodyAnswerSetId);
};

class NebOverlayMacroRegion extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
      __answerSetDict: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = NebFormMacroRegion.createModel(
      this.model.defaultBodyAnswerSetId,
    );

    this.__answerSetDict = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      confirm: ({ question, answerSet }) => {
        this.handlers.dirty(false);

        this.dismiss({
          question: {
            type: this.model.type,
            text: question.text,
            defaultIndices: question.defaultIndices.filter(i => i !== null),
            answerSetId: question.answerSetId,
          },

          answerSets: {
            ...this.__answerSetDict,
            [question.answerSetId]: {
              ...answerSet,
              type: this.model.type,
            },
          },
        });
      },
      dismiss: () => this.dismiss({ answerSets: this.__answerSetDict }),
      dismissWithoutResult: () => {
        this.dismiss({
          dismissWithoutResult: true,
          answerSets: this.__answerSetDict,
        });
      },
      changeAnswerSets: answerSets => {
        this.__answerSetDict = { ...this.__answerSetDict, ...answerSets };
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = formatModel(this.model);

      this.__answerSetDict = this.model.answerSets;
    }

    super.update(changedProps);
  }

  dismissWithBlocker() {
    this.dismiss({ answerSets: this.__answerSetDict });
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        title="Advanced Macros - Region"
        .showBackButton="${this.model.showBackButton}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
      <neb-form-macro-region
        id="${ELEMENTS.form.id}"
        confirmLabel="Done"
        .macroSetId="${this.model.macroSetId}"
        .answerSetDict="${this.__answerSetDict}"
        .defaultBodyAnswerSetId="${this.model.defaultBodyAnswerSetId}"
        .model="${this.__formModel}"
        .onSave="${this.handlers.confirm}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onChangeDirty="${this.handlers.dirty}"
        .onChangeAnswerSets="${this.handlers.changeAnswerSets}"
      ></neb-form-macro-region>
    `;
  }
}

customElements.define('neb-overlay-macro-region', NebOverlayMacroRegion);
