import { Method } from '../utils/api-client-utils';
import ApiClientV2 from '../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getClaimsTotals = ({ patientId, userLocationIds }) =>
  apiClient.makeRequest({
    path: '/api/v3/tenants/:tenantId/claim-totals',
    ...(patientId && { queryParams: { patientId } }),
    headers: {
      'Content-Type': 'application/json',
      ...(userLocationIds && { userLocationIds }),
    },
    method: Method.GET,
    cacheKey: patientId ? `claim-totals-${patientId}` : 'claim-totals',
  });

export const getClaimsTotalsCountAndCharges = ({
  patientId,
  userLocationIds,
}) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/claim-totals-charges',
    ...(patientId && { queryParams: { patientId } }),
    headers: {
      'Content-Type': 'application/json',
      ...(userLocationIds?.length && { userLocationIds }),
    },
    method: Method.GET,
    cacheKey: patientId
      ? `claim-totals-charges-${patientId}`
      : 'claim-totals-charges',
  });

export const getClaimsTotalsApprovedBalance = ({
  patientId,
  userLocationIds,
}) =>
  apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/claim-totals-approved-balance',
    ...(patientId && { queryParams: { patientId } }),
    headers: {
      'Content-Type': 'application/json',
      ...(userLocationIds?.length && { userLocationIds }),
    },
    method: Method.GET,
    cacheKey: patientId
      ? `claim-totals-approved-balance-${patientId}`
      : 'claim-totals-approved-balance',
  });
