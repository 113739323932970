import {
  getPayerPlans,
  getPayerPlan,
  savePayerPlan,
} from '../payer-plan-api-client';

export class PayerPlanRecordService {
  constructor(callback, version) {
    this.callback = callback;
    this.__version = version;
  }

  async update(id) {
    const payerPlan = await getPayerPlan(id, this.__version);

    return this.callback(payerPlan);
  }

  save(payerPlan) {
    return savePayerPlan(payerPlan, this.__version);
  }
}

export class PayerPlanQueryService {
  constructor(callback, version) {
    this.__version = version;
    this.__masterCount = 0;
    this.__search = '';
    this.__items = [];
    this.callback = callback;
  }

  async update(query = {}) {
    const result = await this.__getPayerPlans(query);
    this.__items = result.model;
    this.__search = query.search || '';

    if (!query.search) {
      this.__masterCount = result.count;
    }

    this.callback({ ...result, masterCount: this.__masterCount });
  }

  async __getPayerPlans(query) {
    const { payerPlan, count } = await getPayerPlans(query, this.__version);

    return {
      model: payerPlan,
      count,
    };
  }

  async fetchMore(limit = 20) {
    if (this.__items.length === this.__masterCount) {
      return;
    }

    const result = await this.__getPayerPlans(
      {
        limit,
        offset: this.__items.length,
        search: this.__search,
        hideInactive: true,
      },
      this.__version,
    );
    this.__items = [...this.__items, ...result.model];
    this.callback({
      count: result.count,
      model: this.__items,
      masterCount: this.__masterCount,
    });
  }
}

export class PayerPlanService {
  constructor(callbackRecord, callbackQuery, version) {
    this.__recordService = new PayerPlanRecordService(callbackRecord, version);
    this.__queryService = new PayerPlanQueryService(callbackQuery, version);
  }

  async updateQuery(query = {}) {
    await this.__queryService.update(query);
  }

  async updateRecord(id) {
    await this.__recordService.update(id);
  }
}
