import { RESPONSE_TYPE } from '../../utils/api-client-utils';
import ApiClientV2 from '../../utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });
export const BASE_PATH =
  '/api/tenants/:tenantId/statements/batches/:batchId/csv';

export const getStatementBatchCSV = batchId =>
  apiClient.makeRequest({
    path: BASE_PATH,
    replacements: { batchId },
    cacheKey: `statements/batches/${batchId}/csv`,
    responseType: RESPONSE_TYPE.RAW,
  });
