import '../shared/neb-prior-encounters-list';
import './neb-charting-diagnosis-prior-diagnoses-table';
import '../../../../neb-lit-components/src/components/neb-pagination';

import { LitElement, html } from 'lit';

import {
  FilterType,
  NebPriorEncountersDataController,
} from '../../../../../src/features/charting/shared/neb-prior-encounters-data-controller';
import { getEncounterDiagnoses } from '../../../../neb-api-client/src/encounters-api-client';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  priorEncountersTable: {
    id: 'table-prior-encounters',
  },
  priorEncounterDiagnosesTable: {
    id: 'table-prior-diagnoses',
  },
  priorEncountersPagination: {
    id: 'prior-pagination',
  },
};
export const DIAGNOSIS_FILTER_TYPE = 'diagnosis';
const NO_PRIOR_ENCOUNTERS_TEXT =
  'This patient does not have any prior encounters.';
export const NO_PRIOR_ENCOUNTER_DIAGNOSES_TEXT =
  'There are no diagnoses for this encounter.';
export const NO_RESULTS_FOUND_TEXT = 'No results found.';

class NebChartingDiagnosisPriorEncountersController extends LitElement {
  static get properties() {
    return {
      __priorEncounterId: String,
      __priorDiagnoses: Array,
      codes: Array,
      encounter: Object,
      searchText: String,
      visible: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__priorEncounterId = '';
    this.__priorDiagnoses = [];
    this.__message = NO_PRIOR_ENCOUNTERS_TEXT;
    this.codes = [];
    this.encounter = null;
    this.searchText = '';

    this.onAddCode = () => {};

    this.onAddAllCodes = () => {};

    this.onSetPriorEncounterId = () => {};
  }

  __initServices() {
    this.__dataController = new NebPriorEncountersDataController(this, {
      filterType: FilterType.DIAGNOSIS,
    });
  }

  __initHandlers() {
    this.__handlers = {
      addCode: code => this.onAddCode(code),
      addAllCodes: codes => this.onAddAllCodes(codes),
      selectedEncounter: async encounter => {
        this.__priorEncounterId = encounter.id;
        this.onSetPriorEncounterId(this.__priorEncounterId);
        this.__priorDiagnoses = await getEncounterDiagnoses(
          this.__priorEncounterId,
        );
      },
      pageChanged: page => {
        if (this.visible) {
          this.__elements.priorEncountersList.resetSelected();
          this.__priorDiagnoses = [];
        }

        this.__priorEncounterId = '';

        if (this.searchText === '') {
          this.__message = NO_PRIOR_ENCOUNTERS_TEXT;
        } else {
          this.__message = NO_RESULTS_FOUND_TEXT;
        }

        return this.__dataController.applyPriorEncountersFilters({
          searchText: this.searchText,
          pageNumber: page,
        });
      },
    };
  }

  async updated(changed) {
    this.__elements = {
      priorEncountersList: this.shadowRoot.getElementById(
        ELEMENTS.priorEncountersTable.id,
      ),
    };

    if (changed.has('searchText')) {
      await this.__handlers.pageChanged(0);
    }
  }

  __mapDiagnosis(diagnosis) {
    return {
      ...diagnosis,
      added: this.codes.findIndex(code => code === diagnosis.code) !== -1,
    };
  }

  getPriorDiagnosesData() {
    return this.__priorEncounterId
      ? this.__priorDiagnoses.map(diagnosis => this.__mapDiagnosis(diagnosis))
      : null;
  }

  reset() {
    this.__priorEncounterId = '';

    this.__handlers.pageChanged(0);
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          min-width: 0;
        }

        .column-encounters {
          margin: 10px ${CSS_SPACING} 0 0;
          flex: 2 0 0;
        }

        .column-diagnoses {
          flex: 3 0 0;
        }

        .pagination {
          flex-direction: row-reverse;
          margin: ${CSS_SPACING} 0;
          padding-right: 10px;
        }
      </style>
    `;
  }

  __renderTable() {
    return html`
      <div class="column-encounters">
        <neb-prior-encounters-list
          id="${ELEMENTS.priorEncountersTable.id}"
          .model="${this.__dataController.priorEncountersModel}"
          .message="${this.__message}"
          .selectedEncounterId="${this.__priorEncounterId}"
          .onItemClicked="${this.__handlers.selectedEncounter}"
        ></neb-prior-encounters-list>
        <neb-pagination
          id="${ELEMENTS.priorEncountersPagination.id}"
          class="pagination"
          .pageCount="${this.__dataController.pageCount}"
          .currentPage="${this.__dataController.currentPage}"
          ?hidden="${this.__dataController.count === 0}"
          .onPageChanged="${this.__handlers.pageChanged}"
        ></neb-pagination>
      </div>

      <neb-charting-diagnosis-prior-diagnoses-table
        id="${ELEMENTS.priorEncounterDiagnosesTable.id}"
        class="column-diagnoses"
        .codes="${this.getPriorDiagnosesData()}"
        .message="${NO_PRIOR_ENCOUNTER_DIAGNOSES_TEXT}"
        .onAddCode="${this.__handlers.addCode}"
        .onAddAllCodes="${this.__handlers.addAllCodes}"
      ></neb-charting-diagnosis-prior-diagnoses-table>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()} ${this.visible ? this.__renderTable() : ''}
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-prior-encounters-controller',
  NebChartingDiagnosisPriorEncountersController,
);
