import { getAppointments } from '../neb-api-client/src/appointment-api-client';
import { store } from '../neb-redux/neb-redux-store';
import { parseDateTime } from '../neb-utils/neb-cal-util';

export const queryAppointments = async params => {
  const appointments = await getAppointments(params);
  const { providers } = store.getState();

  let mappedProviders = {};

  if (providers) {
    mappedProviders = providers.item.reduce(
      (memo, { id, name: { first, last, middle, preferred, suffix } }) => ({
        ...memo,
        [id]: {
          first,
          last,
          middle,
          preferred,
          suffix,
        },
      }),
      {},
    );
  }

  return {
    appointments: appointments.data.map(appointment => ({
      ...appointment,
      start: parseDateTime(appointment.start),
      end: parseDateTime(appointment.end),
      provider: mappedProviders[appointment.providerId],
    })),
    queryMetadata: appointments.queryMetadata,
    count: appointments.count,
  };
};
