import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';

const bulkUpdateMessage = maxUnits =>
  `This will update the Max Units in this Package for all charges currently filtered to ${maxUnits}. Are you sure that you want to proceed?`;
export const bulkUpdatePopupConfig = maxUnits => ({
  title: 'Bulk Update',
  message: bulkUpdateMessage(maxUnits),
  confirmText: 'YES',
  cancelText: 'NO',
});
export const openBulkUpdatePopup = maxUnits =>
  openPopup(POPUP_RENDER_KEYS.CONFIRM, bulkUpdatePopupConfig(maxUnits));
