import { html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';

import Table, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  patientLink: {
    id: 'patient-link',
  },
  payerLink: {
    id: 'payer-link',
  },
};

class NebTableDiscountTransaction extends Table {
  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.onSelectPatient = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickPatient: e => {
        e.stopPropagation();
        return this.onSelectPatient();
      },
    };
  }

  __renderPatientCell(value) {
    return html`
      <div
        id="${ELEMENTS.patientLink.id}"
        class="button"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderPayerCell(value) {
    return html`
      <div
        id="${ELEMENTS.payerLink.id}"
        class="button"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patient':
        return this.__renderPatientCell(value);
      case 'payer':
        return this.__renderPayerCell(value);
      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

window.customElements.define(
  'neb-table-discount-transaction',
  NebTableDiscountTransaction,
);
