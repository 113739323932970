import { css } from 'lit';

export const SLIDE_DIRECTION = {
  DOWN: css`down`,
  LEFT: css`left`,
  RIGHT: css`right`,
  UP: css`up`,
};
export const slideRight = css`
  @-webkit-keyframes slide-right {
    0% {
      visibility: hidden;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
    30% {
      visibility: hidden;
      transform: translateX(-3%);
      -webkit-transform: translateX(-3%);
    }
    60% {
      visibility: hidden;
      transform: translateX(3%);
      -webkit-transform: translateX(3%);
    }
    90% {
      visibility: visible;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
    100% {
      visibility: visible;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
  }
`;
export const slideLeft = css`
  @-webkit-keyframes slide-left {
    0% {
      visibility: hidden;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
    30% {
      visibility: hidden;
      transform: translateX(3%);
      -webkit-transform: translateX(3%);
    }
    60% {
      visibility: hidden;
      transform: translateX(-3%);
      -webkit-transform: translateX(-3%);
    }
    90% {
      visibility: visible;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
    100% {
      visibility: visible;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
  }
`;
export const slideUp = css`
  @-webkit-keyframes slide-up {
    0% {
      visibility: hidden;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    30% {
      visibility: hidden;
      transform: translateY(-20%);
      -webkit-transform: translateY(-20%);
    }
    60% {
      visibility: hidden;
      transform: translateY(20%);
      -webkit-transform: translateY(20%);
    }
    90% {
      visibility: visible;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    100% {
      visibility: visible;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
  }
`;
export const slideDown = css`
  @-webkit-keyframes slide-down {
    0% {
      visibility: hidden;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    30% {
      visibility: hidden;
      transform: translateY(20%);
      -webkit-transform: translateY(20%);
    }
    60% {
      visibility: hidden;
      transform: translateY(-20%);
      -webkit-transform: translateY(-20%);
    }
    90% {
      visibility: visible;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    100% {
      visibility: visible;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
  }
`;
