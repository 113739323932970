import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const BLANK_LINE = '<p>&nbsp;</p>';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const trimBlankLines = note => {
  note = note.trim();
  note = note.replace(new RegExp(`^(${BLANK_LINE})+`), '');
  note = note.replace(new RegExp(`(${BLANK_LINE})+$`), '');
  return note;
};

export async function fetchNote(
  noteKey,
  encounterId,
  optOutLoadingIndicator = false,
) {
  const response = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/chart-notes/',
    headers: { 'Content-Type': 'application/rtf' },
    optOutLoadingIndicator,
    queryParams: { type: noteKey },
    responseType: RESPONSE_TYPE.RAW,
    replacements: { encounterId },
  });

  let text = await response.text();
  text = text.trim();
  text = text.replace(/^<p data-table-header="/, `${BLANK_LINE}$&`);
  text = text && `${text}${BLANK_LINE}`;
  return text;
}

export async function fetchNotes(
  notes,
  encounterId,
  optOutLoadingIndicator = false,
) {
  const result = {};

  await Promise.all(
    Object.keys(notes).map(async noteKey => {
      result[noteKey] = await fetchNote(
        noteKey,
        encounterId,
        optOutLoadingIndicator,
      );
    }),
  );

  return result;
}

export function saveNote(encounterId, type, note) {
  return coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/chart-notes/',
    headers: { 'Content-Type': 'application/rtf' },
    queryParams: { type },
    body: trimBlankLines(note),
    replacements: { encounterId },
  });
}

export function saveNotes(notes, encounterId) {
  return Promise.all(
    Object.entries(notes).map(([key, note]) =>
      saveNote(encounterId, key, note),
    ),
  );
}
