import '../misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/inputs/neb-menu';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { Popup } from '@neb/popup';
import { html, css } from 'lit';

import { clearContexts } from '../../utils/context/constants';

export const BASE_MODEL = {
  top: 0,
  left: 0,
  items: [{ label: 'Placeholder', onSelect: () => {} }],
  data: {},
};

export const ELEMENTS = {
  container: { id: 'container' },
  content: { id: 'content' },
  menu: {
    id: 'menu',
  },
};

class NebContextMenu extends Popup {
  static get properties() {
    return {
      __result: Object,
      __loadingIndicator: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: inline-block;
          position: absolute;
          box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.4);
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .content {
          display: flex;
          position: absolute;
          top: 0;
          height: 100%;
          max-width: 100%;
          background-color: #fff;
          flex-flow: column nowrap;
        }

        .menu {
          height: fit-content;
          width: 200px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__result = undefined;
    this.__loadingIndicator = { loading: false, message: '' };

    this.model = BASE_MODEL;
  }

  initHandlers() {
    this.handlers = {
      select: async index => {
        const { onSelect } = this.model.items[index];

        if (this.__loadingIndicator.loading) return;

        this.__loadingIndicator = { loading: true, message: '' };

        const result = await onSelect(this.model.data);

        this.__loadingIndicator = { loading: false, message: '' };

        if (result) {
          this.onClose(result);
        }
      },
    };
  }

  preventAction(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('neb-context-menu-close', clearContexts, false);
    window.addEventListener('click', clearContexts, false);
    window.addEventListener('resize', clearContexts, false);
    window.addEventListener('wheel', this.preventAction, { passive: false });
  }

  disconnectedCallback() {
    window.removeEventListener('neb-context-menu-close', clearContexts, false);
    window.removeEventListener('click', clearContexts, false);
    window.removeEventListener('resize', clearContexts, false);
    window.removeEventListener('wheel', this.preventAction, {
      passive: false,
    });

    super.disconnectedCallback();
  }

  update(changedProps) {
    if ((changedProps.has('model') && this.model.left) || this.model.top) {
      this.style.setProperty('left', `${this.model.left}px`);
      this.style.setProperty('top', `${this.model.top}px`);
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-menu
        id="${ELEMENTS.menu.id}"
        class="menu"
        .items="${this.model.items}"
        .maxVisibleItems="${this.model.items.length}"
        .onChange="${this.handlers.select}"
        open
      ></neb-menu>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${this.loadingIndicator?.loading
          ? html`
              <neb-loading-overlay
                id="${ELEMENTS.spinner.id}"
                title="${this.loadingIndicator?.message}"
                showDelay="0"
                .show="${true}"
              ></neb-loading-overlay>
            `
          : ''}
        <div id="${ELEMENTS.content.id}" class="content">
          ${this.renderContent()}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-context-menu', NebContextMenu);
