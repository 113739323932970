const SupportedTypes = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  Object: 'object',
  Null: 'null',
  Array: 'array',
};

export const isPrimitive = obj => obj !== Object(obj);

export const isArray = obj => Array.isArray(obj);

export const getType = obj => {
  if (obj === null) return SupportedTypes.Null;
  if (Array.isArray(obj)) return SupportedTypes.Array;

  return obj && obj.constructor.name.toLowerCase();
};

const getNodePreview = (nodeValue, maxLength) => {
  const nodeType = getType(nodeValue);

  switch (nodeType) {
    case SupportedTypes.Object:
      return Object.keys(nodeValue).length === 0 ? '{ }' : '{ ... }';
    case SupportedTypes.Array:
      return nodeValue.length === 0 ? '[ ]' : '[ ... ]';
    case SupportedTypes.String:
      return `"${nodeValue.substring(0, maxLength)}${
        nodeValue.length > maxLength ? '...' : ''
      }"`;
    default:
      return String(nodeValue);
  }
};

export const generateNodePreview = (node, opts = {}) => {
  const { nodeCount = 3, maxLength = 15 } = opts;

  const isNodeArray = Array.isArray(node);
  const objectNodes = Object.keys(node);
  const keys = objectNodes.slice(0, nodeCount);
  const preview = [];

  const childPreviews = [];

  keys.forEach(key => {
    const nodePreview = [];
    const nodeValue = node[key];

    if (!isNodeArray) nodePreview.push(`${key}: `);

    nodePreview.push(getNodePreview({ nodeValue, maxLength }));
    childPreviews.push(nodePreview.join(''));
  });

  if (objectNodes.length > nodeCount) {
    childPreviews.push('...');
  }
  preview.push(childPreviews.join(', '));

  const previewText = preview.join('');

  return isArray ? `[ ${previewText} ]` : `{ ${previewText} }`;
};
