import '../../neb-lit-components/src/components/login/neb-change-password';

import { html, css } from 'lit';

import { resetPracticeUserMeta } from '../../neb-api-client/src/practice-user-meta-api-client';
import { store } from '../../neb-redux/neb-redux-store';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  changePasswordForm: {
    id: 'change-password-form',
  },
};
const TEXT_CHANGE_PASSWORD = 'Change Password';

class NebPopupChangePassword extends NebPopup {
  initState() {
    super.initState();
    this.title = TEXT_CHANGE_PASSWORD;
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      changePassword: () => {
        resetPracticeUserMeta(store.getState().session.item.id);
        this.onClose(true);
      },
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
        }

        .change-password {
          display: flex;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-change-password
        id="${ELEMENTS.changePasswordForm.id}"
        class="change-password"
        fitIntoPopup
        .onPasswordChanged="${this.__handlers.changePassword}"
        .onCancel="${this.__handlers.cancel}"
        .email="${this.model.email}"
      ></neb-change-password>
    `;
  }
}

customElements.define('neb-popup-change-password', NebPopupChangePassword);
