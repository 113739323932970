import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';
import { formatPhoneNumber } from '../../../packages/neb-utils/formatters';
import { renderMatchStrength } from '../../../packages/neb-utils/patient';

import { ROW_TYPE } from './neb-patient-online-booking-match-row';

export const ELEMENTS = {
  patientHeader: {
    id: 'patient-header',
  },
  patientRow: {
    id: 'patient-row',
  },
  matchHeader: {
    id: 'match-header',
  },
  matchRow: {
    id: 'match-row',
  },
  firstNameField: {
    id: 'firstname-field',
  },
  lastNameField: {
    id: 'lastname-field',
  },
  birthdateField: {
    id: 'birthdate-field',
  },
  emailField: {
    id: 'email-field',
  },
  phoneNumberField: {
    id: 'phone-number-field',
  },
  matchStrengthField: {
    id: 'match-strength-field',
  },
  headerAccount: {
    id: 'header-account',
  },
  headerReviewMatch: {
    id: 'header-review-match',
  },
};
const HEADER_PATIENT = {
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  phoneNumber: 'Phone',
  emailAddress: 'Email Address',
};
const HEADER_MATCH = { ...HEADER_PATIENT, matches: 'Match Strength' };

class NebPatientReviewMatches extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      model: Object,
      headerAccount: String,
      headerReviewMatch: String,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.layout = '';
    this.model = {
      account: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phoneNumber: '',
        emailAddress: '',
      },
      match: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phoneNumber: '',
        emailAddress: '',
        matches: '',
      },
    };

    this.headerAccount = '';
    this.headerReviewMatch = '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([layout='small']) .row-title {
          padding-top: 20px;
          padding-bottom: 12px;
        }

        .row-title {
          height: fit-content;
          width: 100%;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding: 0 17px 16px;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .booking {
          padding-top: 27px;
        }

        .header {
          font: Regular 14px/19px Open Sans;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin: 25px 0 22px 17px;
        }

        .first-name-header {
          margin-top: 0;
        }

        .body {
          margin: 12px 0 15px 17px;
        }

        .row {
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }
      `,
    ];
  }

  __renderPatientRecord() {
    return html`
      <div class="row-title" id="${ELEMENTS.headerAccount.id}">
        ${this.headerAccount}
      </div>
      <neb-patient-online-booking-match-row
        id="${ELEMENTS.patientHeader.id}"
        type="${ROW_TYPE.HEADER}"
        .layout="${this.layout}"
        .model="${HEADER_PATIENT}"
      ></neb-patient-online-booking-match-row>
      <neb-patient-online-booking-match-row
        id="${ELEMENTS.patientRow.id}"
        .layout="${this.layout}"
        .model="${this.model.account}"
      ></neb-patient-online-booking-match-row>
    `;
  }

  __renderBookingAccount() {
    return this.layout !== 'small'
      ? html`
          <neb-patient-online-booking-match-row
            id="${ELEMENTS.matchHeader.id}"
            type="${ROW_TYPE.HEADER}"
            .layout="${this.layout}"
            .model="${HEADER_MATCH}"
          ></neb-patient-online-booking-match-row>

          <neb-patient-online-booking-match-row
            id="${ELEMENTS.matchRow.id}"
            type="${ROW_TYPE.REVIEW}"
            .layout="${this.layout}"
            .model="${this.model.match}"
          ></neb-patient-online-booking-match-row>
        `
      : this.__renderMobileBookingAccount();
  }

  __renderMobileRow(id, header, value, customHeaderClass = '') {
    return html`
      <div class="row">
        <div class="header ${customHeaderClass}">${header}</div>
      </div>
      <div class="row"><div class="body" id="${id}">${value}</div></div>
    `;
  }

  __renderMobileRowMultipleValues(id, header, value, customHeaderClass = '') {
    return html`
      <div class="row">
        <div class="header ${customHeaderClass}">${header}</div>
      </div>
      <div class="row">
        <div class="body" id="${id}">
          ${value.map(item => html` <div>${item}</div> `)}
        </div>
      </div>
    `;
  }

  __renderMobileBookingAccount() {
    const { match } = this.model;
    return html`
      ${this.__renderMobileRow(
        ELEMENTS.firstNameField.id,
        HEADER_MATCH.firstName,
        match.firstName,
        'first-name-header',
      )}
      ${this.__renderMobileRow(
        ELEMENTS.lastNameField.id,
        HEADER_MATCH.lastName,
        match.lastName,
      )}
      ${this.__renderMobileRow(
        ELEMENTS.birthdateField.id,
        HEADER_MATCH.dateOfBirth,
        moment(match.dateOfBirth).format('MM/DD/YYYY'),
      )}
      ${match.phoneNumbers && match.phoneNumbers.length > 0
        ? this.__renderMobileRowMultipleValues(
            ELEMENTS.phoneNumberField.id,
            HEADER_MATCH.phoneNumber,
            match.phoneNumbers.map(phone => formatPhoneNumber(phone.number)),
          )
        : this.__renderMobileRow(
            ELEMENTS.phoneNumberField.id,
            HEADER_MATCH.phoneNumber,
            formatPhoneNumber(match.phoneNumber),
          )}
      ${match.emailAddresses && match.emailAddresses.length > 0
        ? this.__renderMobileRowMultipleValues(
            ELEMENTS.emailField.id,
            HEADER_MATCH.emailAddress,
            match.emailAddresses,
          )
        : this.__renderMobileRow(
            ELEMENTS.emailField.id,
            HEADER_MATCH.emailAddress,
            match.emailAddress,
          )}
      ${this.__renderMobileRow(
        ELEMENTS.matchStrengthField.id,
        HEADER_MATCH.matches,
        renderMatchStrength(match.matches),
      )}
    `;
  }

  render() {
    return html`
      ${this.layout !== 'small' ? this.__renderPatientRecord() : ''}
      <div class="row-title booking" id="${ELEMENTS.headerReviewMatch.id}">
        ${this.headerReviewMatch}
      </div>
      ${this.__renderBookingAccount()}
    `;
  }
}

customElements.define('neb-patient-review-matches', NebPatientReviewMatches);
