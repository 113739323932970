import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html } from 'lit';

import {
  ELEMENTS as BASE_ELEMENTS,
  Item,
} from '../../../../packages/neb-lit-components/src/components/field-groups/neb-item';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { getValueByPath } from '../../../../packages/neb-utils/utils';

export const ELEMENTS = { ...BASE_ELEMENTS };

const isRequiredIfFeeSchedule = () => [
  {
    error: 'Required',
    validate: (v, keyPath, model) => {
      const path = keyPath.slice(0, keyPath.length - 1);
      const feeSchedule = getValueByPath(model, [...path, 'feeScheduleId']);

      return feeSchedule.label ? v.length > 0 : true;
    },
  },
];

const isRequiredIfProviders = () => [
  {
    error: 'Required',
    validate: (v, keyPath, model) => {
      const path = keyPath.slice(0, keyPath.length - 1);
      const provider = getValueByPath(model, [...path, 'providerIds']);

      return provider.length > 0 ? v.label : true;
    },
  },
];

export default class NebFeeScheduleProviderItem extends Item {
  static get properties() {
    return {
      errors: Object,
      helpers: Object,
      model: Object,
      providerItems: Array,
      feeScheduleItems: Array,
      providerLabel: String,
      showAllProvidersOption: Boolean,
    };
  }

  static createModel() {
    return {
      feeScheduleId: '',
      providerIds: [],
    };
  }

  static createSelectors(feeScheduleItems = [], providerItems = []) {
    return {
      children: {
        feeScheduleId: selectors.select(
          feeScheduleItems,
          selectors.ITEM_EMPTY,
          {
            validators: isRequiredIfProviders(),
          },
        ),
        providerIds: selectors.multiSelect(providerItems, [], {
          validators: isRequiredIfFeeSchedule(),
        }),
      },
    };
  }

  initState() {
    super.initState();

    this.providerItems = [];
    this.feeScheduleItems = [];

    this.errors = {
      feeScheduleId: '',
      providerIds: '',
    };

    this.helpers = {
      feeScheduleId: '',
      providerIds: '',
    };

    this.model = {
      feeScheduleId: '',
      providerIds: [],
    };

    this.showAllProvidersOption = false;
  }

  __getActiveFeeScheduleItems() {
    return this.feeScheduleItems.filter(item => item.data.active);
  }

  renderLeftContent() {
    return html`
      <neb-select
        id="${ELEMENTS.leftContent.id}"
        class="field"
        name="feeScheduleId"
        label="Fee Schedule"
        .items="${this.__getActiveFeeScheduleItems()}"
        .value="${this.model.feeScheduleId}"
        .error="${this.errors.feeScheduleId}"
        .helper="${this.helpers.feeScheduleId || ' '}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  renderRightContent() {
    return html`
      <neb-select
        id="${ELEMENTS.rightContent.id}"
        class="field field-selector"
        name="providerIds"
        label="Providers"
        allLabel="${this.showAllProvidersOption ? 'Providers' : ''}"
        .items="${this.providerItems}"
        .value="${this.model.providerIds}"
        .error="${this.errors.providerIds}"
        .helper="${this.helpers.providerIds || ' '}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }
}

window.customElements.define(
  'neb-fee-schedule-provider-item',
  NebFeeScheduleProviderItem,
);
