import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { CODE_PAYMENTS } from '../../../packages/neb-utils/constants';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import {
  fetchPaymentTypeItems,
  fetchDiscountTypeItems,
} from '../../../packages/neb-utils/neb-ledger-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { getLocations } from '../../api-clients/locations';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';
import {
  ITEM_NO_LOCATION,
  ITEM_NO_PROVIDER,
} from './neb-filters-ledger-charges';

export const ITEM_PAYER_SELF = {
  label: 'Self',
  data: { id: 'null' },
};

export const ELEMENTS = {
  patientSearch: { id: 'search-patient' },
  payersSelect: { id: 'select-payers' },
  dosRange: { id: 'range-date-dos' },
  paymentId: { id: 'payment-id' },
  statusesSelect: {
    id: 'select-statuses',
    name: 'statuses',
    label: 'Payment Status',
  },
  paymentMethodsSelect: {
    id: 'select-methods',
    name: 'paymentMethods',
    label: 'Payment Methods',
  },
  paymentTypesSelect: {
    id: 'select-payment-types',
    name: 'paymentTypes',
    label: 'Type',
  },
  paymentAmountRange: { id: 'range-payment-amount' },
  availableAmountRange: { id: 'range-available-amount' },
  providerSelect: { id: 'provider-select' },
  locationSelect: { id: 'location-select' },
};

const buildPaymentStatus = status => ({
  label: status,
  data: { id: status },
});

const buildPaymentMethod = method => ({
  label: method,
  data: { id: method },
});

export const PAYMENT_STATUSES = [
  'Unallocated',
  'Allocated',
  'Voided',
  'Refunded',
  'Warning',
].map(buildPaymentStatus);

const BASE_PAYMENT_METHODS = [
  'Cash',
  'Check',
  'Direct Deposit',
  'Debit or Credit Card',
  'ERA',
];

const DISCOUNT_PAYMENT_METHODS = ['Discount'];

class NebLedgerPaymentsFilter extends NebFilters {
  static get properties() {
    return {
      patientPayers: Array,

      __patientItems: Array,
      __providerItems: Array,
      __locationItems: Array,
      __codePaymentItems: Array,
      __codeDiscountItems: Array,
      __payerPlansState: Object,

      enablePatient: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          grid-template-columns: repeat(4, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    const today = parseDate().endOf('day');
    const thirtyDaysAgo = parseDate().startOf('day').subtract(1, 'month');

    const dateOfService = {
      ...NebDateRange.createModel(),
      from: new Date(thirtyDaysAgo),
      to: new Date(today),
    };

    return {
      patientId: '',
      payerIds: [],
      paymentId: '',
      statuses: [],
      dateOfService,
      paymentMethods: [],
      paymentTypes: [],
      paymentAmount: NebCurrencyRange.createModel(),
      availableAmount: NebCurrencyRange.createModel(),
      providers: [],
      locations: [],
    };
  }

  initState() {
    super.initState();

    this.patientPayers = [];

    this.__patientItems = [];
    this.__providerItems = [];
    this.__locationItems = [];
    this.__codePaymentItems = [];
    this.__codeDiscountItems = [];
    this.__payerPlansState = FetchService.createModel();

    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          this.__payerPlansState = state;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      payerPlansApi.fetchMany,
    );

    this.enablePatient = false;
    this.mode = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changeStatuses: e =>
        this.formService.apply(
          e.name,
          e.value.length ? e.value : selectors.ITEM_EMPTY,
        ),
      changeMethods: e =>
        this.formService.apply(
          e.name,
          e.value.length ? e.value : selectors.ITEM_EMPTY,
        ),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      changePatients: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: e => {
        const model = this.formService.build();
        const { from, to } = model.dateOfService;
        const isButtonPress = !!e;

        this.onApply(
          {
            ...model,
            dateOfService: {
              from: from ? parseDate(from._i) : null,
              to: to ? parseDate(to._i) : null,
            },
          },
          isButtonPress,
        );
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        providerIds: selectors.multiSelect([]),
        payerIds: selectors.multiSelect([]),
        statuses: selectors.multiSelect([]),
        paymentMethods: selectors.multiSelect([]),
        paymentTypes: selectors.multiSelect([]),
        dateOfService: NebDateRange.createSelectors(),
        paymentAmount: NebCurrencyRange.createSelectors(),
        availableAmount: NebCurrencyRange.createSelectors(),
        providers: selectors.multiSelect([]),
        locations: selectors.multiSelect([]),
      },
    };
  }

  async load() {
    this.__payerPlansService.fetch();

    if (this.enablePatient) {
      this.__patientsService = createServerPatientsCollection({
        onChange: this.handlers.changePatients,
        alwaysShowInactive: true,
      });

      await this.__patientsService.fetchComplete();
    }

    const [codePaymentItems, codeDiscountItems] = await Promise.all([
      fetchPaymentTypeItems(),
      fetchDiscountTypeItems(),
    ]);

    this.__codePaymentItems = codePaymentItems.filter(
      item => item.data.id !== CODE_PAYMENTS.DISCOUNT.id,
    );

    this.__codeDiscountItems = codeDiscountItems;

    const [providers, allLocations] = await Promise.all([
      getProviderUsers(),
      getLocations(),
    ]);

    this.__providerItems = this.__getProviderItems(providers);
    this.__locationItems = this.__getLocationItems(allLocations);
  }

  connectedCallback() {
    super.connectedCallback();

    this.handlers.apply();
  }

  __getProviderItems(providers) {
    return [
      ITEM_NO_PROVIDER,
      ...providers.map(provider => ({
        label: objToName(provider.name, DEFAULT_NAME_OPTS),
        data: provider,
      })),
    ];
  }

  __getLocationItems(allLocations) {
    const { locations } = store.getState().session.item;

    const userLocations = allLocations.reduce((acc, location) => {
      if (locations.includes(location.id)) {
        acc.push({
          label: location.name,
          data: location,
        });
      }
      return acc;
    }, []);

    return [ITEM_NO_LOCATION, ...userLocations];
  }

  getPaymentMethodItems() {
    return [...BASE_PAYMENT_METHODS, ...DISCOUNT_PAYMENT_METHODS].map(
      buildPaymentMethod,
    );
  }

  getPaymentTypeItems() {
    return [...this.__codePaymentItems, ...this.__codeDiscountItems].sort(
      (a, b) => a.label.localeCompare(b.label),
    );
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  get payerItems() {
    return this.enablePatient
      ? [ITEM_PAYER_SELF, ...this.__payerPlansState.pageItems]
      : [ITEM_PAYER_SELF, ...this.patientPayers];
  }

  __renderProviderAndLocationDropdowns() {
    return html`
      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        name="providers"
        label="Provider"
        helper=" "
        .items="${this.__providerItems}"
        .value="${this.state.providers}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.locationSelect.id}"
        name="locations"
        label="Location"
        helper=" "
        .items="${this.__locationItems}"
        .value="${this.state.locations}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  renderContent() {
    const dos = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };

    return html`
      ${this.renderPatientFilter()}

      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.payerItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.fetchMorePayers}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateOfService"
        label="Date"
        .model="${dos}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-textfield
        id="${ELEMENTS.paymentId.id}"
        name="paymentId"
        label="Payment ID"
        helper=" "
        maxLength="7"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.paymentId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.statusesSelect.id}"
        name="${ELEMENTS.statusesSelect.name}"
        label="${ELEMENTS.statusesSelect.label}"
        helper=" "
        .value="${this.state.statuses}"
        .items="${PAYMENT_STATUSES}"
        .onChange="${this.handlers.change}"
        .maxVisibleItems="${5}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.paymentMethodsSelect.id}"
        name="${ELEMENTS.paymentMethodsSelect.name}"
        label="${ELEMENTS.paymentMethodsSelect.label}"
        helper=" "
        .value="${this.state.paymentMethods}"
        .items="${this.getPaymentMethodItems()}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.paymentTypesSelect.id}"
        name="${ELEMENTS.paymentTypesSelect.name}"
        label="${ELEMENTS.paymentTypesSelect.label}"
        helper=" "
        .value="${this.state.paymentTypes}"
        .items="${this.getPaymentTypeItems()}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-currency
        id="${ELEMENTS.paymentAmountRange.id}"
        name="paymentAmount"
        label="Payment Amount"
        .model="${this.state.paymentAmount}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>

      <neb-range-currency
        id="${ELEMENTS.availableAmountRange.id}"
        name="availableAmount"
        label="Available Amount"
        .model="${this.state.availableAmount}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>

      ${this.__renderProviderAndLocationDropdowns()}
    `;
  }
}

window.customElements.define(
  'neb-filters-ledger-payments',
  NebLedgerPaymentsFilter,
);
