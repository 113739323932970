import '../../neb-action-bar-payment';
import '../../controls/neb-button-bar';
import '../../../../../../src/components/controls/inputs/neb-checkbox';

import { LitElement, html, css } from 'lit';

import { FULL_DATE_FORMAT } from '../../../../../neb-input/nebFormatUtils';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../neb-utils/date-util';
import { centsToCurrency } from '../../../../../neb-utils/formatters';
import {
  formatPaymentMethodInfo,
  paymentHasAuthEFT,
} from '../../../../../neb-utils/neb-payment-util';
import { BUTTON_ROLE } from '../../neb-button';

export const INCLUDE_FUTURE_APPOINTMENTS_LOCALSTORAGE_KEY =
  'includeFutureAppointments';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  postHeader: {
    id: 'post-header',
  },
  paymentType: {
    id: 'payment-type',
  },
  voidPayment: {
    id: 'void-payment',
  },
  amount: {
    id: 'amount',
  },
  transactionDate: {
    id: 'transaction-date',
  },
  paymentMethod: {
    id: 'payment-method',
  },
  authEFT: {
    id: 'auth-EFT',
  },
  includeFutureAppointmentsCheckbox: {
    id: 'include-future-appointments-checkbox',
  },
  cardSaleId: {
    id: 'card-sale-id',
  },
  cardRefundId: {
    id: 'card-refund-id',
  },
  refundMethod: {
    id: 'card-refund-method',
  },
  postedBy: {
    id: 'posted-by',
  },
  note: {
    id: 'note',
  },
  actionBar: {
    id: 'action-bar',
  },
  voidedAmount: {
    id: 'voided-amount',
  },
  voidedBy: {
    id: 'voided-by',
  },
  voidedDate: {
    id: 'voided-date',
  },
  emailButton: {
    id: 'email-button',
  },
  buttonBar: {
    id: 'button-bar',
  },
};
const DESCRIPTION =
  'Optionally apply payments to individual charges, print or email receipt, otherwise click Close.';
const PURCHASE_TRANSACTION_DESCRIPTION =
  'Print or email receipt, otherwise click Close.';

class NebPatientPaymentPost extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      chargeInfo: {
        type: Object,
      },
      layout: {
        type: String,
        reflect: true,
      },
      voidPayment: {
        type: Object,
      },
      autoAllocate: {
        type: Boolean,
      },
      __includeFutureAppointments: {
        type: Boolean,
      },
      hasLsAutoAllocateReceiptFF: {
        type: Boolean,
      },
      hasItemizedReceiptFF: {
        type: Boolean,
      },
      showAutoAllocateButton: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.voidPayment = {};
    this.chargeInfo = {};
    this.__includeFutureAppointments = false;
    this.hasLsAutoAllocateReceiptFF = false;
    this.hasItemizedReceiptFF = false;

    this.onAllocatePayment = () => {};

    this.onAutoAllocate = () => {};

    this.onPrint = () => {};

    this.onEmail = () => {};

    this.onPrintItemizedReceipt = () => {};

    this.onClose = () => {};

    this.onVoidPayment = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      allocatePayment: () => this.onAllocatePayment(),
      print: () => this.onPrint(this.__includeFutureAppointments),
      close: () => this.onClose(),
      email: () => this.onEmail(),
      printItemizedReceipt: () => this.onPrintItemizedReceipt(),
      voidPayment: () =>
        Object.keys(this.voidPayment).length ? '' : this.onVoidPayment(),
      onClickIncludeFutureAppointmentsCheckbox: () =>
        this.__setIncludeFutureAppointments(!this.__includeFutureAppointments),
      onClickAutoAllocate: () => this.onAutoAllocate(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          flex: 1 0 auto;
        }

        :host([layout='small']) .action-bar:not([bottomPanelVisible]) {
          height: 120px;
        }

        :host([layout='small']) .action-bar-two-btn {
          height: 80px;
        }

        .flex-page {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          overflow-y: auto;
        }

        .post-content {
          padding-top: 30px;
        }

        .description {
          padding: 0 ${CSS_SPACING};
        }

        .separator {
          border-top: ${CSS_BORDER_GREY_1};
        }

        .section {
          padding: 10px ${CSS_SPACING};
        }

        .font-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .first-row {
          display: flex;
        }

        .first-item {
          padding-top: ${CSS_SPACING};
        }

        .void-payment {
          flex: 1;
          text-align: end;
          margin-left: 10px;
        }

        .max-content {
          width: max-content;
        }

        .payment-type {
          display: flex;
          flex-direction: column;
        }

        .space {
          margin: auto;
        }

        .section-checkbox {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .buttons-bar {
          padding: 0 0 25px 18px;
        }

        neb-button-bar {
          --stretch-grid-gap: 25px;
        }
      `,
    ];
  }

  __setIncludeFutureAppointments(
    value = this.__getIncludeFutureAppointments(),
  ) {
    this.__includeFutureAppointments = value;
    window.localStorage.setItem(
      INCLUDE_FUTURE_APPOINTMENTS_LOCALSTORAGE_KEY,
      value,
    );
  }

  __getIncludeFutureAppointments() {
    return (
      window.localStorage.getItem(
        INCLUDE_FUTURE_APPOINTMENTS_LOCALSTORAGE_KEY,
      ) === 'true'
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.__setIncludeFutureAppointments();
  }

  __renderPaymentMethod() {
    return html`
      <div class="section">
        <div class="font-bold">Payment Method</div>
        <div id="${ELEMENTS.paymentMethod.id}">
          ${formatPaymentMethodInfo(this.model)}
        </div>
      </div>
    `;
  }

  __renderAuthEFT() {
    return paymentHasAuthEFT(this.model)
      ? html`
          <div class="section">
            <div class="font-bold">Auth/Check/EFT</div>
            <div id="${ELEMENTS.authEFT.id}">
              ${this.model.authEFT ? this.model.authEFT : '-'}
            </div>
          </div>
        `
      : '';
  }

  __renderIncludeFutureAppointmentsCheckbox() {
    return html`
      <div class="space"></div>
      <div class="section-checkbox">
        <neb-checkbox
          id="${ELEMENTS.includeFutureAppointmentsCheckbox.id}"
          class="checkbox"
          label="Include future appointments (Print Only)"
          @click="${this.__handlers.onClickIncludeFutureAppointmentsCheckbox}"
          ?checked="${this.__includeFutureAppointments}"
        ></neb-checkbox>
      </div>
    `;
  }

  __renderButtonBar() {
    if (!this.hasLsAutoAllocateReceiptFF && !this.hasItemizedReceiptFF) {
      return '';
    }

    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="buttons-bar"
        tabindex="-1"
        .config="${[
          ...(this.hasLsAutoAllocateReceiptFF
            ? [
                {
                  name: 'receipt',
                  label: 'Print Receipt',
                  icon: 'print',
                  onClick: this.__handlers.print,
                },
              ]
            : []),
          ...(this.hasItemizedReceiptFF
            ? [
                {
                  name: 'itemized-receipt',
                  label: 'Print Itemized Receipt',
                  icon: 'print',
                  onClick: this.__handlers.printItemizedReceipt,
                },
              ]
            : []),
          ...(this.hasLsAutoAllocateReceiptFF
            ? [
                {
                  name: 'email-receipt',
                  label: 'Email Receipt',
                  icon: 'emailFilled',
                  onClick: this.__handlers.email,
                },
              ]
            : []),
        ]}"
        .stretch="${true}"
      ></neb-button-bar>
    `;
  }

  __renderActions() {
    return html`
      <div class="space"></div>
      ${this.__renderIncludeFutureAppointmentsCheckbox()}
      ${this.__renderButtonBar()}
    `;
  }

  __renderCardSaleId() {
    return this.model.electronicPaymentId
      ? html`
          <div class="section">
            <div class="font-bold">Card Sale ID</div>
            <div id="${ELEMENTS.cardSaleId.id}">
              ${this.model.cardSaleId ? this.model.cardSaleId : '-'}
            </div>
          </div>
        `
      : '';
  }

  __renderVoidPaymentContent() {
    return html`
      <div class="section separator first-item">
        <div class="font-bold">Voided Amount</div>
        <div id="${ELEMENTS.voidedAmount.id}">
          ${centsToCurrency(this.model.amount)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Voided Date</div>
        <div id="${ELEMENTS.voidedDate.id}">
          ${parseDate(this.voidPayment.updatedAt).format(FULL_DATE_FORMAT)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Voided By</div>
        <div id="${ELEMENTS.voidedBy.id}">${this.model.postedBy}</div>
      </div>
    `;
  }

  __renderRefundPaymentContent() {
    return html`
      <div class="section separator first-item">
        <div class="font-bold">Refunded Date</div>
        <div id="${ELEMENTS.voidedDate.id}">
          ${parseDate(this.voidPayment.updatedAt).format(FULL_DATE_FORMAT)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Refunded Amount</div>
        <div id="${ELEMENTS.voidedAmount.id}">
          ${centsToCurrency(this.model.amount)}
        </div>
      </div>

      <div class="section">
        <div class="font-bold">Refund Method</div>
        <div id="${ELEMENTS.refundMethod.id}">
          ${this.voidPayment.refundMethod}
        </div>
      </div>

      ${this.__renderTransactionID()}
      <div class="section">
        <div class="font-bold">Refunded By</div>
        <div id="${ELEMENTS.voidedBy.id}">${this.model.postedBy}</div>
      </div>
    `;
  }

  __renderTransactionID() {
    return this.voidPayment.cardRefundId
      ? html`
          <div class="section">
            <div class="font-bold">Refund Transaction ID</div>
            <div id="${ELEMENTS.cardRefundId.id}">
              ${this.voidPayment.cardRefundId}
            </div>
          </div>
        `
      : '';
  }

  __voidButtonLabel() {
    return this.model.electronicPaymentId ? 'REFUND PAYMENT' : 'VOID PAYMENT';
  }

  __purchaseOrAutoAllocate() {
    return (
      this.model.purchase || this.autoAllocate || this.model.available === 0
    );
  }

  __renderVoidPaymentButton() {
    let hideVoidRefundButton = false;

    if (this.chargeInfo) {
      hideVoidRefundButton = this.chargeInfo.hideVoidRefundButton;
    }

    const { codePayment, purchase, purchases, fees } = this.model;
    const { code } = codePayment;

    const carePackagePurchase = purchase && (code === 'Pkg' || code === 'Sub');

    const hasPurchase = carePackagePurchase || purchases || fees;

    const hideVoidRefund =
      hasPurchase ||
      hideVoidRefundButton === true ||
      this.model.amount !== this.model.available;
    return hideVoidRefund
      ? ''
      : html`
          <div class="void-payment">
            <neb-button
              id="${ELEMENTS.voidPayment.id}"
              class="max-content"
              .label="${this.__voidButtonLabel()}"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.__handlers.voidPayment}"
              ?disabled="${Object.keys(this.voidPayment).length}"
            ></neb-button>
          </div>
        `;
  }

  __renderPaymentPostContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="description">
        ${this.__purchaseOrAutoAllocate()
          ? PURCHASE_TRANSACTION_DESCRIPTION
          : DESCRIPTION}
      </div>

      <div class="post-content">
        <div id="${ELEMENTS.postHeader.id}" class="section font-bold">
          ${`Payment ID: ${this.model.paymentNumber} (PAID)`}
        </div>

        <div class="section separator">
          <div class="first-row first-item">
            <div class="payment-type">
              <div class="font-bold">Payment Type</div>
              <div id="${ELEMENTS.paymentType.id}">
                ${this.model.codePayment.description}
              </div>
            </div>

            ${this.__renderVoidPaymentButton()}
          </div>
        </div>

        <div class="section">
          <div class="font-bold">Payment Amount</div>
          <div id="${ELEMENTS.amount.id}">
            ${centsToCurrency(this.model.amount)}
          </div>
        </div>

        <div class="section">
          <div class="font-bold">Transaction Date</div>
          <div id="${ELEMENTS.transactionDate.id}">
            ${parseDate(this.model.transactionDate).format(FULL_DATE_FORMAT)}
          </div>
        </div>

        ${this.__renderPaymentMethod()} ${this.__renderAuthEFT()}
        ${this.__renderCardSaleId()}

        <div class="section">
          <div class="font-bold">Posted By</div>
          <div id="${ELEMENTS.postedBy.id}">${this.model.postedBy}</div>
        </div>

        <div class="section">
          <div class="font-bold">Payment Note</div>
          <div id="${ELEMENTS.note.id}">
            ${this.model.note ? html` ${this.model.note} ` : '-'}
          </div>
        </div>
      </div>
    `;
  }

  __renderRefundVoidPaymentContent() {
    if (!Object.keys(this.voidPayment).length) return '';

    return this.model.electronicPaymentId
      ? this.__renderRefundPaymentContent()
      : this.__renderVoidPaymentContent();
  }

  __renderActionBar() {
    return html`
      <neb-action-bar-payment
        id="${ELEMENTS.actionBar.id}"
        class="${this.__purchaseOrAutoAllocate()
          ? 'action-bar-two-btn'
          : 'action-bar'}"
        confirmLabel="${this.__purchaseOrAutoAllocate() ||
        Object.keys(this.voidPayment).length ||
        this.layout !== 'large'
          ? ''
          : 'ALLOCATE PAYMENT'}"
        cancelLabel="PRINT RECEIPT"
        emailReceiptLabel="EMAIL RECEIPT"
        removeLabel="CLOSE"
        ?bottomPanelVisible="${Object.keys(this.voidPayment).length}"
        ?showAutoAllocateButton="${this.showAutoAllocateButton}"
        .layout="${this.layout}"
        .onConfirm="${this.__handlers.allocatePayment}"
        .onCancel="${this.__handlers.print}"
        .onRemove="${this.__handlers.close}"
        .onEmailReceipt="${this.__handlers.email}"
        .onAutoAllocate="${this.__handlers.onClickAutoAllocate}"
        .elevatedRemove="${this.__purchaseOrAutoAllocate() ||
        Object.keys(this.voidPayment).length}"
        .hasLsAutoAllocateReceiptFF="${this.hasLsAutoAllocateReceiptFF}"
      ></neb-action-bar-payment>
    `;
  }

  render() {
    return html`
      <div id="patient-payment-container" class="flex-page">
        ${this.__renderPaymentPostContent()}
        ${this.__renderRefundVoidPaymentContent()} ${this.__renderActions()}
      </div>
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define('neb-patient-payment-post', NebPatientPaymentPost);
