import { _itemsGroupedById, _buildError } from './base';

export const _updating = state => ({
  ...state,
  updated: false,
  updating: true,
});

export const _updated = (state, { item, item: { id } } = {}) => {
  const updatedState = {
    ...state,
    updating: false,
    updateError: null,
    updated: true,
    items: state.items.slice(),
  };
  const updateItemIndex = updatedState.items.findIndex(
    ({ id: itemId }) => itemId === id,
  );
  updatedState.items[updateItemIndex] = item;
  updatedState.itemsByGroupId = _itemsGroupedById(updatedState.items);

  return updatedState;
};

export const _updateError = (state, action) => {
  const updateError = _buildError(action, 'updateError');

  return { ...state, updating: false, updateError, updated: false };
};
