import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { createNewPractice } from '../../../../../neb-api-client/src/support/practice-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { setItem } from '../../../../../neb-login/neb-session-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { OVERLAY_KEYS, openOverlay } from '../../../utils/overlay-constants';
import Overlay from '../neb-overlay';

import '../../../../../../src/components/forms/settings/neb-form-practice-information';
import '../../neb-tab-old';
import '../../neb-popup-header';

export const BANNER_SUCCESS = 'Practice saved successfully.';
export const BANNER_ERROR = 'An error occurred when saving the Practice.';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
};

class NebPracticeOverlay extends Overlay {
  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async model => {
        try {
          const result = await this.onSave(model);

          const {
            data: { id: tenantId, salesforceId, name: practiceName, locations },
          } = result;

          await store.dispatch(
            setItem({
              ...store.getState().session.item,
              tenantId,
            }),
          );

          this.isDirty = false;
          this.dismiss(result);
          store.dispatch(openSuccess(BANNER_SUCCESS));

          const confirmed = await openPopup(POPUP_RENDER_KEYS.CLIENT_ID, {
            salesforceId,
            practiceName,
          });

          if (confirmed) {
            await openOverlay(OVERLAY_KEYS.USER_ADD, {
              context: { superUser: true, locations },
            });
          }
        } catch (e) {
          console.error(e);
          store.dispatch(openError(BANNER_ERROR));
        }
      },
    };
  }

  onSave(model) {
    return createNewPractice({ ...model, locations: [model.address] });
  }

  getTitle() {
    return this.model.id ? 'Update Practice' : 'Add Practice';
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 800px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .page {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-practice-information
        id="${ELEMENTS.form.id}"
        class="page"
        .layout="${this.layout}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        showSupportFields
        enableTimezone
      >
      </neb-form-practice-information>
    `;
  }
}

window.customElements.define('neb-overlay-practice', NebPracticeOverlay);
