import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  icon: { id: 'icon' },
  tab: { id: 'tab' },
};

class NebTabOld extends LitElement {
  static get properties() {
    return {
      compact: {
        type: Boolean,
        reflect: true,
      },
      name: {
        type: String,
      },
      primary: {
        type: Boolean,
        reflect: true,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      underline: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.compact = false;
    this.primary = false;
    this.small = false;
    this.underline = false;
    this.disabled = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => {
        if (!this.disabled) {
          this.onClick(this.name);
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.__handlers.click);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click', this.__handlers.click);
  }

  static get styles() {
    return css`
      :host {
        margin-right: 30px;
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
      }

      :host(:last-child),
      :host([small]) {
        margin-right: 0;
      }

      :host([compact]) {
        margin-right: 0;
      }

      :host([disabled]) {
        cursor: default;
      }

      :host([primary]:not([small])) {
        padding: 10px ${CSS_SPACING} 5px ${CSS_SPACING};
        border-radius: 5px 5px 0 0;
      }

      :host([primary]:not([small]):not([compact])) {
        margin-right: ${CSS_SPACING};
      }

      :host([underline][primary]:not([small])) {
        background-color: ${CSS_COLOR_WHITE};
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      :host([underline][primary]) .underline {
        display: none;
      }

      .underline {
        position: absolute;
        bottom: 0;
        width: 0;
        -webkit-transition: width 200ms;
        transition: width 200ms;
      }

      .tab-container {
        position: relative;
        padding: 12px 10px 12px 0;
        font-family: ${CSS_FONT_FAMILY};
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      :host([primary]) .tab-container {
        font-weight: bold;
        padding-top: 5px;
        padding-right: 0;
      }

      :host([primary][small]) .tab-container {
        font-weight: normal;
      }

      :host([underline]) .underline {
        border-bottom: 3px solid ${CSS_COLOR_HIGHLIGHT};
        width: calc(100% - 10px);
      }

      :host([underline]:not([small])) .tab-container {
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .row {
        display: flex;
        flex-direction: row;
      }

      :host([small]) .tab-container {
        box-sizing: border-box;
        height: 50px;
      }

      :host([small]) .row-content {
        flex: 0.98;
      }

      :host([disabled]) .row-content {
        color: ${CSS_COLOR_GREY_1};
      }

      .row-nav {
        flex: 0.02;
      }

      .arrow {
        height: 11px;
        width: 11px;
        color: ${CSS_COLOR_GREY_1};
        transform: rotate(0.5turn);
      }
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.tab.id}" class="tab-container">
        <div id="tab-row" class="row">
          <div id="tab-content" class="row-content"><slot></slot></div>
          ${this.small
            ? html`
                <div id="tab-nav" class="row-nav">
                  <neb-icon
                    id="${ELEMENTS.icon.id}"
                    class="arrow"
                    icon="neb:back"
                  ></neb-icon>
                </div>
              `
            : html` <div class="underline"></div> `}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-tab-old', NebTabOld);
