/* eslint-disable complexity */
import { mapEncounterCharge } from '../../../../packages/neb-utils/fee-schedule';

export const formatChargesModel = (charges, feeSchedule, activeCharges) =>
  charges
    ? charges.map(c => {
        const settingsCharge = activeCharges.find(ac => ac.id === c.chargeId);
        const chargeWithModifiers = {
          ...c,
          modifiers: [c.modifier_1, c.modifier_2, c.modifier_3, c.modifier_4],
          posted: !!c.postedToLedgerId,
          repost: !!c.repost,
          nationalDrugCodeEnabled:
            settingsCharge?.nationalDrugCodeEnabled || false,
          nationalDrugCodeQualifier:
            settingsCharge?.nationalDrugCodeQualifier || '',
          nationalDrugCode: settingsCharge?.nationalDrugCode || null,
          nationalDrugCodeDosage:
            settingsCharge?.nationalDrugCodeDosage || null,
          nationalDrugCodeUnitOfMeasurement:
            settingsCharge?.nationalDrugCodeUnitOfMeasurement || '',
          nationalDrugCodeNumberCategory:
            settingsCharge?.nationalDrugCodeNumberCategory || '',
          nationalDrugCodeSequenceOrPrescription:
            settingsCharge?.nationalDrugCodeSequenceOrPrescription || null,
        };

        return mapEncounterCharge(
          chargeWithModifiers,
          feeSchedule,
          activeCharges,
        );
      })
    : [];

export const formatDiagnosesChargesDetail = diagnoses =>
  diagnoses.map(d => ({
    ...d,
    label: `${d.code} - ${d.shortDescription}`,
  }));
