import DeprecatedApiClient, { RESPONSE_TYPE } from './utils/api-client-utils';

export const BASE_URL = 'patients';

function buildRequestor(apiClient) {
  return (method, path, body = null, opts = {}) => {
    const {
      version,
      query,
      includeContentType = true,
      responseType = RESPONSE_TYPE.JSON,
      cacheKey,
    } = opts;

    return apiClient.makeRequest({
      optOutLoadingIndicator: false,
      path,
      method,
      responseType,
      version: version || undefined,
      queryParams: query || {},
      body: body ? JSON.stringify(body) : undefined,
      ...(includeContentType && {
        headers: { 'Content-Type': 'application/json' },
      }),
      ...(cacheKey && { cacheKey }),
    });
  };
}

export const apiClientBilling = new DeprecatedApiClient({
  microservice: 'billing',
});
const makeBillingRequest = buildRequestor(apiClientBilling);

export function fetchMany(patientId, opts = {}) {
  const path = `${BASE_URL}/${patientId}/fee-schedules`;
  return makeBillingRequest('GET', path, null, { version: 1, ...opts });
}

export function add(patientId, model) {
  const path = `${BASE_URL}/${patientId}/fee-schedules`;
  return makeBillingRequest('POST', path, model, { version: 1 });
}

export function remove(patientId, feeScheduleId) {
  const path = `${BASE_URL}/${patientId}/fee-schedules/${feeScheduleId}`;
  return makeBillingRequest('DELETE', path, null, {
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
}
