import { html, css } from 'lit';

import { CSS_FONT_FAMILY } from '../../../../neb-styles/neb-variables';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

import Overlay, { TRANSITION_SIDE } from './neb-overlay';

class TestOverlay extends Overlay {
  static get properties() {
    return {
      __value: String,
    };
  }

  initState() {
    super.initState();
    this.__value = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.keyPress = e => {
      this.__value = e.currentTarget.value;
    };

    this.handlers.openFromLeft = () =>
      openOverlay(OVERLAY_KEYS.TEST, {
        title: 'Overlay',
        message: 'This is in the overlay stack',
        side: TRANSITION_SIDE.LEFT,
      });

    this.handlers.openFromRight = () =>
      openOverlay(OVERLAY_KEYS.TEST, {
        title: 'Overlay',
        message: 'This is in the overlay stack',
      });
  }

  freeze() {
    return {
      value: this.__value,
    };
  }

  restore(state) {
    this.__value = state.value;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.title = this.model.title;
      this.side = this.model.side || TRANSITION_SIDE.LEFT;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
        }

        .message {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <p class="text text-message">${this.model.message}</p>

        <div class="content-container-inner">
          <button @click="${this.handlers.openFromLeft}">Open From Left</button>
          <button @click="${this.handlers.openFromRight}">
            Open From Right
          </button>
          <button @click="${this.handlers.dismiss}">Dismiss</button>

          <div class="spacer"></div>

          <input
            type="text"
            .value="${this.__value}"
            @keyup="${this.handlers.keyPress}"
          />
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-overlay-test', TestOverlay);
