import { LitElement, html, css } from 'lit';

import { renderNebTableStyles } from '../../neb-styles/neb-table-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_ERROR,
} from '../../neb-styles/neb-variables';

import {
  CSS_TYPE_COLUMN_WIDTH,
  CSS_MESSAGE_COLUMN_WIDTH,
} from './neb-alert-table-styles';

export const ELEMENTS = {
  typeHeader: {
    id: 'type-header',
  },
  messageHeader: {
    id: 'message-header',
  },
  rows: {
    selector: '.row-selector',
  },
};

class NebAlertSummary extends LitElement {
  static get properties() {
    return {
      alerts: {
        type: Array,
      },
    };
  }

  static get styles() {
    return css`
      .message {
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .type {
        flex: 0 0 75px;
      }

      .neb-table-cell {
        padding: 5px 10px;
      }

      .neb-table-row .neb-table-cell:first-child {
        padding-left: 10px;
      }

      .neb-table-header {
        padding: 10px 0 5px 0;
      }

      .no-alert-msg {
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
        border-bottom: none;
      }

      [is-alert] {
        color: ${CSS_COLOR_ERROR};
      }
    `;
  }

  constructor() {
    super();
    this.alerts = [];
  }

  render() {
    return html`
      ${renderNebTableStyles()} ${this.__renderAlertTableHeader()}
      ${this.alerts.length === 0
        ? this.__renderNoAlertMessage()
        : this.__renderAlertRow()}
    `;
  }

  __renderAlertTableHeader() {
    return html`
      <div class="neb-table-row neb-table-header">
        <div
          id="type-header"
          class="neb-table-cell ${CSS_TYPE_COLUMN_WIDTH} type"
        >
          Type
        </div>
        <div
          id="message-header"
          class="neb-table-cell ${CSS_MESSAGE_COLUMN_WIDTH} message"
        >
          Message
        </div>
      </div>
    `;
  }

  __renderAlertRow() {
    return this.alerts.map(
      (alert, index) => html`
        <div
          id="data-row-${index}"
          class="neb-table-row row-selector"
          ?is-alert="${alert.alert}"
        >
          <div
            id="type-${index}"
            class="type neb-table-cell ${CSS_TYPE_COLUMN_WIDTH}"
          >
            ${alert.alert ? 'Alert' : 'Note'}
          </div>

          <div
            id="message-${index}"
            class="message neb-table-cell ${CSS_MESSAGE_COLUMN_WIDTH}"
          >
            ${alert.message}
          </div>
        </div>
      `,
    );
  }

  __renderNoAlertMessage() {
    return html`
      <div id="no-alert-msg" class="neb-table-row neb-table-cell no-alert-msg">
        There are no Alerts or Notes for this patient.
      </div>
    `;
  }
}

customElements.define('neb-alert-summary', NebAlertSummary);
