export const isApproved = res => res && res.status === 'Approved';

export const mapCardType = cardType => {
  if (cardType.toLowerCase() === 'visa') return 'VISA';
  if (cardType.toLowerCase() === 'mastercard') return 'MC';
  if (cardType.toLowerCase() === 'master card') return 'MC';
  if (cardType.toLowerCase() === 'american express') return 'AMEX';
  if (cardType.toLowerCase() === 'americanexpress') return 'AMEX';
  if (cardType.toLowerCase() === 'discover') return 'DS';
  if (cardType.toLowerCase() === 'dinersclub') return 'DC';
  if (cardType.toLowerCase() === 'diners club') return 'DC';
  if (cardType.toLowerCase() === 'unionpay') return 'CUP';
  if (cardType.toLowerCase() === 'interac') return 'IDP';
  if (cardType.toLowerCase() === 'paypal') return 'PP';

  if (cardType.length > 4) return cardType.substring(0, 4).toUpperCase();

  return cardType.toUpperCase();
};

export const formatElectronicPayment = res => {
  const { card } = res;

  const { cardType, maskedCardNumber } = card;

  const abbreviatedCardType = mapCardType(cardType);

  const maskedCardDescription = abbreviatedCardType
    ? `${abbreviatedCardType} ${maskedCardNumber}`
    : maskedCardNumber;

  let receipt;

  if (res.receipt) {
    receipt = {};
    const parsedReceipt = JSON.parse(res.receipt);
    receipt.text = parsedReceipt.text.customer_receipt;
  }
  return {
    electronicPaymentId: res.id,
    cardSaleId: res.saleId,
    electronicReferenceId: res.referenceId,
    maskedCardDescription,
    receipt,
  };
};

export const REFUND_MESSAGES = {
  APPROVED: {
    title: 'Refund Transaction Successful',
    subheader: '',
    message:
      'The payment was refunded successfully. The cardholder will see a charge posted to their account, which will be refunded within 3-5 business days.',
    success: true,
    allowSaveCardOnFile: false,
    confirmLabel: 'OK',
    cancelLabel: '',
    cardOnFileName: '',
    hyperLink: '',
  },
  VOIDED: {
    title: 'Refund Transaction Successful',
    subheader: '',
    message:
      'The payment was refunded successfully. The cardholder may see a pending charge on their account, which will be removed within 1-3 business days.',
    success: true,
    allowSaveCardOnFile: false,
    confirmLabel: 'OK',
    cancelLabel: '',
    cardOnFileName: '',
    hyperLink: '',
  },
  FAILED_500: {
    title: 'Refund Transaction Unsuccessful',
    subheader: '',
    message:
      "A refund for this payment could not be completed. Click ‘Done’ to close the dialog, or click ‘Try Again' to attempt the refund transaction again.",
    success: false,
    allowSaveCardOnFile: false,
    confirmLabel: 'TRY AGAIN',
    cancelLabel: 'CANCEL',
    cardOnFileName: '',
    hyperLink: '',
  },
  FAILED_400: {
    title: 'Refund Transaction Unsuccessful',
    subheader: '',
    message: 'A refund for this payment could not be completed.',
    success: false,
    allowSaveCardOnFile: false,
    confirmLabel: 'OK',
    cancelLabel: '',
    cardOnFileName: '',
    hyperLink: '',
  },
  FAILED_400_MANUAL: {
    title: 'Refund Transaction Unsuccessful',
    subheader: '',
    message:
      "A refund for this payment could not be completed electronically. Click 'Process Refund Manually' to process this refund manually.",
    success: false,
    allowSaveCardOnFile: false,
    confirmLabel: 'OK',
    cancelLabel: '',
    cardOnFileName: '',
    hyperLink: '',
  },
  FAILED_DEBIT: {
    title: 'Refund Transaction Unsuccessful',
    subheader: '',
    message:
      "A refund for this payment could not be completed. Click ‘Done’ to close the dialog, or click ‘Try Again' to attempt the refund transaction again.",
    success: false,
    allowSaveCardOnFile: false,
    confirmLabel: 'OK',
    cancelLabel: '',
    cardOnFileName: '',
    hyperLink: '',
  },
};

export const ELECTRONIC_PAYMENT_TYPES = Object.freeze({
  DEBIT: 'Debit',
  CREDIT: 'Credit',
});

export const GENIUS_SALE_TYPES = Object.freeze({
  SALE: 'sale',
  RETURN: 'return',
});
