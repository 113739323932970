import '@polymer/polymer/polymer-legacy';

import { html, css } from 'lit';

import { nebButtonStyles } from './neb-button-styles';
import { nebCardStyles } from './neb-card-styles';
import { nebDialogStyles } from './neb-dialog-styles';
import { nebFormStyles } from './neb-form-styles';
import { nebIconStyles } from './neb-icon-styles';
import { nebTypography } from './neb-typography';
import {
  CSS_SPACING,
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_BANNER_SUCCESS_BORDER_COLOR,
  CSS_BANNER_SUCCESS_BACKGROUND_COLOR,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_BANNER_INFO_COLOR,
  CSS_BANNER_INFO_BORDER_COLOR,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_BLACK,
} from './neb-variables';

export const baseStyles = css`
  *,
  *::before,
  *::after {
    outline: none;
    box-sizing: border-box;

    font-family: ${CSS_FONT_FAMILY};
  }

  a:link,
  a:visited {
    text-decoration: none;
  }
`;

export const summaryStyles = css`
  .container {
    display: flex;
    flex-flow: column nowrap;
  }
  .spacer {
    margin-bottom: ${CSS_SPACING};
    margin-right: ${CSS_SPACING};
  }

  .spacer-small {
    margin-bottom: 10px;
  }

  .text {
    margin: 0;
    font-size: ${CSS_FONT_SIZE_BODY};
    color: ${CSS_COLOR_BLACK};
  }
  .text-bold {
    font-weight: 700;
  }

  .text-link {
    cursor: pointer;
    width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-highlight {
    color: ${CSS_COLOR_HIGHLIGHT};
  }

  .text-underline {
    text-decoration: underline;
  }

  .container-edit {
    display: flex;
    align-items: center;
  }
`;

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

const nebStyles = () => html`
  <style>
    [hidden] {
      display: none;
    }

    .clickable {
      cursor: pointer;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      text-decoration: inherit;
      color: inherit;
      outline: 0;
    }

    .persistent-bar-padding {
      height: 87px;
    }

    .neb-separator-border {
      border-top-style: solid;
      border-top-width: 1px;
      border-color: var(--neb-color-border);
    }

    .neb-separator-border:last-of-type {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--neb-color-border);
    }

    :host([small]) .neb-hide-on-small {
      display: none;
    }

    :host(:not([small])) .neb-hide-on-not-small {
      display: none;
    }
  </style>
`;

_documentContainer.innerHTML = `<custom-style>
  <style is="custom-style">
    html {

      --neb-color-dark-primary: #000000;
      --neb-color-light-primary: ${CSS_COLOR_WHITE};
      --neb-color-grey-1: ${CSS_COLOR_GREY_1};
      --neb-color-grey-2: ${CSS_COLOR_GREY_2};
      --neb-color-grey-3: ${CSS_COLOR_GREY_4};
      --neb-color-primary-1: #354C67;
      --neb-color-primary-2: #3072D6;
      --neb-color-primary-3: #005E7C;
      --neb-color-primary-4: ${CSS_COLOR_HIGHLIGHT};
      --neb-color-secondary-1: #C2DAFF;
      --neb-color-secondary-2: #8CE7E6;
      --neb-color-secondary-3: #CFB0FF;
      --neb-color-secondary-4: #FFA0C2;
      --neb-color-secondary-5: ${CSS_COLOR_ERROR};
      --neb-color-success-banner-background: ${CSS_BANNER_SUCCESS_BACKGROUND_COLOR};
      --neb-color-success-banner-border: ${CSS_BANNER_SUCCESS_BORDER_COLOR};
      --neb-color-success-banner: ${CSS_BANNER_SUCCESS_COLOR};
      --neb-color-error-background: ${CSS_ERROR_BACKGROUND_COLOR};
      --neb-color-error-banner-border: ${CSS_BANNER_ERROR_BORDER_COLOR};
      --neb-color-error-banner: ${CSS_BANNER_ERROR_COLOR};
      --neb-color-info-banner-background: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
      --neb-color-info-banner-border: ${CSS_BANNER_INFO_BORDER_COLOR};
      --neb-color-info-banner: ${CSS_BANNER_INFO_COLOR};

      --neb-color-highlight: var(--neb-color-primary-4);
      --neb-color-error: var(--neb-color-secondary-5);
      --neb-color-border: var(--neb-color-grey-2);

      --neb-text-indent-default: 10px;
      --neb-spacing-default: ${CSS_SPACING};

      --neb-toogle-button-checked-bar-color: #D7D7D7;
      --neb-toggle-button-unchecked-button-color: #F1F1F1;

      --neb-selected: {
        color: var(--neb-font-color-white);
        background-color: var(--neb-color-highlight);
      }
    }

    html.no-scroll,
    .no-scroll body {
      overflow: hidden !important;
    }
  </style>
</custom-style>
<dom-module id="neb-styles">
  <template>
    <style include="neb-typography neb-button-styles neb-card-styles neb-icon-styles neb-form-styles">
    </style>
    ${nebStyles()}
    </template>
</dom-module>`;

document.head.appendChild(_documentContainer);

export const renderStyles = () => html`
  ${nebStyles()} ${nebTypography()} ${nebButtonStyles()} ${nebCardStyles()}
  ${nebDialogStyles()} ${nebFormStyles()} ${nebIconStyles()}
`;
export const renderNebStyles = nebStyles;
