import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import NebFormMacroNoDefault from '../../../../packages/neb-lit-components/src/components/forms/neb-form-macro-no-default';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import { QUESTION_ANSWER_TOKEN } from '../../../utils/macros';

const BASE_TITLE = 'Advanced Macros -';

const TYPE_NOUN = {
  [QUESTION_ANSWER_TOKEN.date]: 'Date',
  [QUESTION_ANSWER_TOKEN.number]: 'Number',
  [QUESTION_ANSWER_TOKEN.ask]: 'Ask',
};

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class NebOverlayMacroNoDefault extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = NebFormMacroNoDefault.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      confirm: ({ question }) => {
        this.isDirty = false;
        this.dismiss({
          question: {
            text: question.text,
            type: this.model.type,
            defaultIndices: [],
          },
        });
      },

      dismissWithoutResult: () => this.dismiss({ dismissWithoutResult: true }),
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = this.model.question
        ? this.model
        : NebFormMacroNoDefault.createModel();
    }

    super.update(changedProps);
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        title="${`${BASE_TITLE} ${TYPE_NOUN[this.model.type]}`}"
        .showBackButton="${this.model.showBackButton}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-form-macro-no-default
        id="${ELEMENTS.form.id}"
        confirmLabel="Done"
        .model="${this.__formModel}"
        .onSave="${this.handlers.confirm}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-macro-no-default>
    `;
  }
}

customElements.define('neb-overlay-macro-no-default', NebOverlayMacroNoDefault);
