export const FETCH_PRACTICE_INFORMATION = 'FETCH_PRACTICE_INFORMATION';
export const KEY_LOCAL_PRACTICE_TIMEZONE = 'localPracticeTimezone';
export const KEY_LOCAL_PRACTICE_ENTERPRISE_ID = 'localPracticeEnterpriseId';

export function requestPracticeInformation() {
  return {
    type: FETCH_PRACTICE_INFORMATION,
  };
}

export function requestPracticeInformationSuccess(response) {
  return {
    type: FETCH_PRACTICE_INFORMATION,
    status: 'success',
    response,
  };
}

export function requestPracticeInformationFailure(error) {
  return {
    type: FETCH_PRACTICE_INFORMATION,
    status: 'failed',
    error,
  };
}
