import { LitElement, html, css } from 'lit';
import '../tables/neb-table';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import { centsToCurrency } from '../../../../neb-utils/formatters';

const CONFIG = [
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`1 0 80px`,
    truncate: true,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`3 0 100px`,
    truncate: true,
  },
  {
    key: 'billedAmount',
    label: 'Billed',
    flex: css`1 0 60px`,
    truncate: true,
    formatter: v => centsToCurrency(v),
  },
];

export const ELEMENTS = {
  table: { id: 'table' },
};
class NebEncounterChargesSummary extends LitElement {
  static get properties() {
    return {
      small: Boolean,
      savedCharges: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.small = false;
    this.savedCharges = [];
  }

  render() {
    const model = this.savedCharges.sort((a, b) => a.order - b.order);

    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        .model="${model}"
        .config="${CONFIG}"
        emptyMessage="There are no charges for this encounter."
        preview
        allowPropagation
      ></neb-table>
    `;
  }
}

customElements.define(
  'neb-encounter-charges-summary',
  NebEncounterChargesSummary,
);
