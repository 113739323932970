import './neb-document-item';

import { LitElement, html } from 'lit';

import {
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
} from '../../../neb-styles/neb-variables';

export const REQUEST_THRESHOLD = 640;
export const NO_DOCS_PATIENT = 'There are no documents for this patient.';
const NO_DOCS_ENCOUNTER =
  'There are no documents associated to this encounter.';
export const NO_DOCS_FILTER = 'No documents found.';
export const ELEMENTS = {
  itemRow: {
    selector: 'neb-document-item',
  },
  noDocumentsLabel: {
    id: 'no-documents',
  },
  scroll: {
    id: 'scroll',
  },
};
export class NebDocumentsScroll extends LitElement {
  static get properties() {
    return {
      isFiltering: Boolean,
      horizontalScroll: Boolean,
      small: {
        type: Boolean,
        reflect: true,
      },
      totalDocuments: Number,
      selectedIndex: Number,
      documents: Array,
      noDocuments: Boolean,
      checkedDocuments: Object,
      expanded: Boolean,
      isOnEncounterDocuments: Boolean,
      hasVerticalPadding: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__fetchEnabled = true;
    this.isFiltering = false;
    this.horizontalScroll = false;
    this.totalDocuments = 0;
    this.selectedIndex = -1;
    this.totalScrollOverflow = null;
    this.documents = [];
    this.noDocuments = false;
    this.checkedDocuments = {};
    this.expanded = false;
    this.isOnEncounterDocuments = false;
    this.hasVerticalPadding = false;

    this.onRequestDocuments = () => {};

    this.onSelectDocumentIndex = () => {};

    this.onDocumentListRendered = () => {};

    this.onCheckDocument = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      scroll: e => {
        const scrollContainer = e.currentTarget;
        const scrollPosition = this.horizontalScroll
          ? scrollContainer.scrollLeft
          : scrollContainer.scrollTop;
        const remainingScroll = this.totalScrollOverflow - scrollPosition;

        if (
          remainingScroll <= REQUEST_THRESHOLD &&
          this.__fetchEnabled === true
        ) {
          this.__fetchEnabled = false;
          this.onRequestDocuments();
        }
      },
      selectDocumentIndex: index => this.onSelectDocumentIndex(index),
      checkDocument: (documentId, checked) =>
        this.onCheckDocument(documentId, checked),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.__handlers.resize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.__handlers.resize);
  }

  reset() {
    this.__elements.scroll.scrollTo(0, 0);
  }

  firstUpdated() {
    this.__elements = {
      scroll: this.shadowRoot.getElementById(ELEMENTS.scroll.id),
    };
  }

  scrollToDocument(index) {
    const document = this.shadowRoot.getElementById(`doc-item-${index}`);

    if (document) {
      document.scrollIntoView({
        block: 'end',
        inline: 'nearest',
      });
    }
  }

  async updated(changedProps) {
    await this.updateComplete;
    const scrollContainer = this.__elements.scroll;
    const scrollContainerDistance = this.horizontalScroll
      ? scrollContainer.offsetWidth
      : scrollContainer.offsetHeight;
    const scrollContentDistance = this.horizontalScroll
      ? scrollContainer.scrollWidth
      : scrollContainer.scrollHeight;
    this.totalScrollOverflow = scrollContentDistance - scrollContainerDistance;
    this.__fetchEnabled = this.totalDocuments > this.documents.length;
    this.onDocumentListRendered(scrollContainer.children.length);

    super.updated(changedProps);
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: block;
          box-sizing: border-box;
          height: 100%;
        }

        .scroll-container {
          -webkit-overflow-scrolling: touch;
          height: 100%;
          padding-left: 15px;
          box-sizing: border-box;
        }

        neb-document-item:first-child {
          margin-bottom: ${CSS_SPACING};
        }

        .vertical-scroll {
          overflow-x: hidden;
          overflow-y: auto;
          padding-bottom: 0;
          padding-right: 10px;
        }

        .vertical-scroll-padding {
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }

        .horizontal-scroll {
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          padding-bottom: 17px;
          padding-right: 0;
          display: flex;
        }

        .filtered-documents {
          margin-left: 5px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .vertical-indicator {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 125px;
        }

        .horizontal-indicator {
          display: inline-block;
          vertical-align: top;
          height: 100%;
        }
      </style>
    `;
  }

  __showCheckbox() {
    return this.expanded;
  }

  __showTag(document) {
    return (
      this.expanded && document.documentTags && document.documentTags.length > 0
    );
  }

  renderFilterText() {
    let message = '';

    if (this.noDocuments) {
      if (this.isFiltering) {
        message = NO_DOCS_FILTER;
      } else if (this.horizontalScroll || this.small) {
        message = this.isOnEncounterDocuments
          ? NO_DOCS_ENCOUNTER
          : NO_DOCS_PATIENT;
      }
    }

    return html`
      <div id="${ELEMENTS.noDocumentsLabel.id}" class="filtered-documents">
        ${message}
      </div>
    `;
  }

  __getVerticalScrollClasses() {
    if (this.hasVerticalPadding) {
      return 'vertical-scroll vertical-scroll-padding';
    }
    return 'vertical-scroll ';
  }

  render() {
    return html`
      ${this.__renderStyles()}
      <div
        id="${ELEMENTS.scroll.id}"
        class="scroll-container ${this.horizontalScroll
          ? 'horizontal-scroll'
          : this.__getVerticalScrollClasses()}"
        @scroll="${this.__handlers.scroll}"
      >
        ${this.documents.length > 0
          ? this.documents.map(
              (doc, index) => html`
                <neb-document-item
                  id="doc-item-${index}"
                  .small="${this.small}"
                  .doc="${doc}"
                  .docIndex="${index}"
                  .onSelectDocumentIndex="${this.__handlers
                    .selectDocumentIndex}"
                  .isSelected="${this.selectedIndex === index}"
                  .horizontalDisplay="${this.horizontalScroll}"
                  .showCheckbox="${this.__showCheckbox()}"
                  .showTag="${this.__showTag(doc)}"
                  .onCheckDocument="${this.__handlers.checkDocument}"
                  .checkedDocuments="${this.checkedDocuments}"
                ></neb-document-item>
              `,
            )
          : this.renderFilterText()}
      </div>
    `;
  }
}
customElements.define('neb-documents-scroll', NebDocumentsScroll);
