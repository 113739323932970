export const formatOnlineAccount = onlineAccount => ({
  cognitoId: onlineAccount.cognitoId,
  firstName: onlineAccount.givenName,
  lastName: onlineAccount.familyName,
  dateOfBirth: onlineAccount.birthdate,
  sex: onlineAccount.sex,
  phoneNumber: onlineAccount.phoneNumber,
  phoneType: onlineAccount.phoneType,
  emailAddress: onlineAccount.email,
});
export const formatOnlineAccountMatches = matches =>
  matches.map(match => {
    const formattedAccount = formatOnlineAccount(match);
    formattedAccount.matches = match.matches;
    return formattedAccount;
  });
