import '../../../../../src/components/misc/neb-icon';
import './neb-patient-list';

import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import { ANY } from '../../../../../src/utils/update-notifications';
import { downloadPatients } from '../../../../neb-api-client/src/patient-roster-api-client';
import { store, connect } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_COLOR_GREY_2 } from '../../../../neb-styles/neb-variables';
import {
  createServerPatientsCollection,
  createServerPatientsModel,
} from '../../../../neb-utils/collections/server-patients';
import {
  HIDE_INACTIVE_PATIENTS_KEY,
  getHideInactivePatients,
} from '../../../../neb-utils/patient';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

export const ELEMENTS = {
  list: { id: 'list' },
};
class NebPatientListController extends connect(store)(LitElement) {
  static get properties() {
    return {
      __collectionState: Object,

      pageSize: Number,
      patientId: String,
      hideToolbar: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      searchBoxFocus: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__collectionState = createServerPatientsModel();

    this.pageSize = 20;
    this.patientId = '';
    this.hideToolbar = false;
    this.layout = 'large';

    this.onAdd = () => {};

    this.onSelect = () => {};

    this.onAction = () => {};

    this.onLoadPatientList = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      select: item => this.onSelect(item),
      change: state => {
        this.__collectionState = state;
      },
      toggle: flag => {
        this.__collectionService.hideInactive(flag);
        localStorage.setItem(HIDE_INACTIVE_PATIENTS_KEY, JSON.stringify(flag));
      },
      request: () => this.__collectionService.fetchMore(),
      add: async () => {
        const patient = await openOverlay(OVERLAY_KEYS.PATIENT);
        this.onAdd(patient);
      },
      uploadRoster: () => {
        navigate('/patients/roster');
        this.onAction();
      },
      downloadRoster: async () => {
        await downloadPatients();
        this.onAction();
      },
    };
  }

  __initServices() {
    this.__collectionService = createServerPatientsCollection({
      onChange: this.__handlers.change,
      recentList: true,
    });

    this.__notificationService = new UpdateNotificationService({
      defaultQuery: {
        patient: ANY,
      },
      callback: () => {
        this.__collectionService.fetch();
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__notificationService.disconnect();
  }

  _stateChanged(state) {
    this.__collectionService.search(state.patients.search, true);
  }

  firstUpdated() {
    const hideInactive = getHideInactivePatients();
    this.__collectionService.hideInactive(hideInactive);
  }

  updated(changedProps) {
    if (changedProps.has('searchBoxFocus') && this.searchBoxFocus) {
      this.onLoadPatientList();
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          border-right: solid 1px ${CSS_COLOR_GREY_2};
        }

        .list {
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <neb-patient-list
        id="${ELEMENTS.list.id}"
        class="list"
        .layout="${this.layout}"
        .selectedId="${this.patientId}"
        .hideInactive="${this.__collectionState.hideInactive}"
        .items="${this.__collectionState.pageItems}"
        .onAdd="${this.__handlers.add}"
        .onRequest="${this.__handlers.request}"
        .onSelect="${this.__handlers.select}"
        .onUploadRoster="${this.__handlers.uploadRoster}"
        .onDownloadRoster="${this.__handlers.downloadRoster}"
        .onToggleInactive="${this.__handlers.toggle}"
        ?isRecentPatientList="${this.__collectionState.body
          .isRecentPatientList}"
        ?hideToolbar="${this.hideToolbar}"
      ></neb-patient-list>
    `;
  }
}

window.customElements.define(
  'neb-patient-list-controller',
  NebPatientListController,
);
