import { css, html, LitElement, unsafeCSS } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  container: { id: 'container' },
};

class NebHorizontalScroll extends LitElement {
  static get properties() {
    return {
      minWidth: Number,
      rectTop: Number,
      yOffset: Number,
      fixAtBottom: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__scrollOffset = 0;

    this.minWidth = 0;
    this.rectTop = 0;
    this.yOffset = 0;
    this.fixAtBottom = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      scroll: e => {
        this.onChange(e.currentTarget.scrollLeft);
      },

      resizeWindow: () => {
        this.updateHoverBarTop();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.__handlers.resizeWindow);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.__handlers.resizeWindow);
  }

  updateHoverBarTop() {
    if (!this.__elements) return;

    if (this.fixAtBottom) {
      this.style.bottom = `${this.yOffset + 10}px`;
      return;
    }

    const spaceHeight = 20;
    const windowHeight = window.innerHeight;
    const result =
      windowHeight -
      this.__yLayoutPosition -
      spaceHeight +
      this.__scrollOffset -
      this.yOffset;
    this.style.transform = `translateY(${result}px)`;
  }

  __computeYLayout() {
    const docRect = document.body.getBoundingClientRect();

    this.__yLayoutPosition = this.rectTop - docRect.top;
  }

  setScrollOffsetTop(offsetTop) {
    this.__scrollOffset = offsetTop;
    this.updateHoverBarTop();
  }

  setScrollLeft(scrollLeft) {
    this.__elements.container.scrollLeft = scrollLeft;
  }

  firstUpdated() {
    this.__elements = {
      container: this.shadowRoot.getElementById(ELEMENTS.container.id),
    };

    this.__computeYLayout();
    this.updateHoverBarTop();
  }

  updated(changedProps) {
    if (changedProps.has('rectTop')) {
      this.__computeYLayout();
      this.updateHoverBarTop();
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 10px;
          position: absolute;
          top: 0;
        }

        .container {
          width: 100%;
          height: 20px;
          background: transparent;
          overflow: auto hidden;
        }

        .content {
          width: 100%;
          height: 100%;
        }

        :host([fixAtBottom]) {
          position: fixed;
          transform: none;
          top: unset;
        }
      `,
    ];
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @scroll="${this.__handlers.scroll}"
      >
        <div
          class="content"
          style="min-width: ${unsafeCSS(this.minWidth)}px"
        ></div>
      </div>
    `;
  }
}

customElements.define('neb-horizontal-scroll', NebHorizontalScroll);
