import '../../neb-lit-components/src/components/neb-drawable-canvas';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_FAMILY,
} from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  closeButton: {
    id: 'button-close',
  },
  saveButton: {
    id: 'button-save',
  },
  signatureText: {
    id: 'text-signature',
  },
  signatureContent: {
    id: 'content-signature',
  },
  drawableCanvas: {
    id: 'drawable-canvas',
  },
};

class NebPopupSignatureCreate extends NebPopup {
  static get properties() {
    return {
      __enableSave: Boolean,
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    super.initState();
    this.__enableSave = false;
  }

  modelChanged() {
    this.title = this.model.title;
  }

  __initHandlers() {
    this.__handlers = {
      save: () => {
        // TODO cgenest: Use a callback from neb-drawable-canvas to provide canvas data.
        const result = this.shadowRoot
          .querySelector('neb-drawable-canvas')
          .saveCanvas();
        this.onClose(result);
      },
    };
  }

  __recordSignature() {
    this.__enableSave = true;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;
          width: 100%;
          max-width: 590px;
          height: 100%;
          max-height: 385px;
          font-family: ${CSS_FONT_FAMILY};
        }

        :host([layout='small']) {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }

        .content {
          display: flex;
          margin: 0;
        }

        .container-signature {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          width: 100%;

          box-sizing: border-box;
        }

        .content-signature {
          display: flex;
          align-items: flex-end;
          height: 205px;
          margin-bottom: ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .signature-notation {
          cursor: default;
          font-weight: 600;
        }

        .text-signature {
          padding: 0;
          font-size: 14px;
        }

        .container-button {
          display: flex;
          flex-direction: row;

          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div id="containerSignature" class="container-signature">
        <div id="content" class="container-content">
          <div id="${ELEMENTS.signatureContent.id}" class="content-signature">
            <div id="signatureNotation" class="signature-notation">X</div>

            <neb-drawable-canvas
              id="${ELEMENTS.drawableCanvas.id}"
              @mousedown="${this.__recordSignature}"
              @touchstart="${this.__recordSignature}"
            >
            </neb-drawable-canvas>
          </div>

          <div id="${ELEMENTS.signatureText.id}" class="text-signature">
            <p>${this.model.message}</p>
          </div>
        </div>

        <div class="container-button">
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button-primary-focus button button-save"
            role="${BUTTON_ROLE.CONFIRM}"
            label="${this.model.confirmText}"
            .onClick="${this.__handlers.save}"
            ?disabled="${!this.__enableSave}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.closeButton.id}"
            class="button-secondary button button-signature"
            role="${BUTTON_ROLE.CANCEL}"
            label="${this.model.cancelText}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-signature-create', NebPopupSignatureCreate);
