export class NebScrollThreshold {
  constructor(
    scrollableElement,
    {
      onUpperThreshold,
      onLowerThreshold,
      upperThreshold,
      lowerThreshold,
      checkInterval = 250,
    },
  ) {
    const emptyFn = () => {};

    this.__onUpperThreshold = onUpperThreshold || emptyFn;
    this.__onLowerThreshold = onLowerThreshold || emptyFn;
    this.__upperThreshold = upperThreshold || 0;
    this.__lowerThreshold = lowerThreshold || 0;
    this.__didScroll = false;
    scrollableElement.addEventListener('scroll', e => {
      this.__didScroll = true;
      this.__scrollTarget = e.currentTarget;
    });

    window.setInterval(() => {
      if (this.__didScroll) {
        this.__didScroll = false;

        this.__checkThreshold(this.__scrollTarget);
      }
    }, checkInterval);

    this.__oldScrollTop = 0;
  }

  __checkThreshold({ scrollTop, scrollHeight, offsetHeight }) {
    const overflow = scrollHeight - offsetHeight;
    const contentRemaining = overflow - scrollTop;

    if (scrollTop < this.__oldScrollTop && scrollTop < this.__upperThreshold) {
      this.__onUpperThreshold();
    } else if (
      scrollTop > this.__oldScrollTop &&
      contentRemaining < this.__lowerThreshold
    ) {
      this.__onLowerThreshold();
    }

    this.__oldScrollTop = scrollTop;
  }
}
