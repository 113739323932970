import '../../encounter/neb-encounter-history-controller';

import { html, css } from 'lit';

import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebOverlayEncounterHistory extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        :host([layout='small']) .content {
          width: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-encounter-history-controller
        id="${ELEMENTS.controller.id}"
        .encounterId="${this.model.encounterId}"
        .layout="${this.layout}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-encounter-history-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-encounter-history',
  NebOverlayEncounterHistory,
);
