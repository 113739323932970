import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { formatPhoneNumber } from '../../../../../neb-utils/formatters';

const formatBillingAddress = address => ({
  address1: address.address1 || '',
  address2: address.address2 || '',
  city: address.city || '',
  state: address.state || '',
  zipCode: address.zipcode || address.zipCode || '',
});

const getLocationOptions = locations =>
  locations.map(location => {
    const formatPhone = formatPhoneNumber(location.phoneNumber);
    return {
      data: {
        active: location.active,
        billingProviderAddress: {
          ...formatBillingAddress(location),
          phone: formatPhone || '',
        },
        billingProviderPracticeName: location.name,
      },
      label: location.name,
    };
  });

export default async ({
  claimCellData,
  state,
  handlers,
  locations,
  formService,
}) => {
  const result = await openPopup(
    POPUP_RENDER_KEYS.CLAIM_BILLING_PROVIDER_INFORMATION,
    {
      title: `${claimCellData.title} Information and Phone Number`,
      tooltipText: claimCellData.tooltipText,
      billingProviderAddress: {
        ...formatBillingAddress(state.billingProviderAddress),
        phone: state.billingProviderAddress.phone || '',
      },
      billingProviderPracticeName: state.billingProviderPracticeName,
      locationOptions: getLocationOptions(
        locations.filter(l => !l.addressOnly && l.active),
      ),
    },
  );

  if (result) {
    Object.entries(result).forEach(([name, value]) => {
      if (name === 'billingProviderAddress') {
        formService.apply('claimEditedField.billingProviderAddressId', true);

        Object.entries(value).forEach(([addressName, addressValue]) => {
          handlers.change({
            name: `${name}.${addressName}`,
            value: addressValue,
          });
        });

        return;
      }

      formService.apply(`claimEditedField.${name}`, true);

      handlers.change({
        name,
        value,
      });
    });
  }
};
