import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  message: {
    id: 'message',
  },
  buttons: {
    selector: '.buttons > neb-button',
    tag: 'neb-button',
  },
};

const DEFAULT_MODEL = {
  title: '',
  message: '',
  buttons: [],
};

class NebPopupDialog extends NebPopup {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex-direction: column;
        }

        .buttons {
          margin-top: 30px;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.dynamic = true;
    this.model = DEFAULT_MODEL;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      click: ({ name }) => this.onClose(name),
    };
  }

  modelChanged() {
    this.title = this.model.title;
    this.model = { ...DEFAULT_MODEL, ...this.model };
  }

  __renderMessage() {
    return [this.model.message].flat().map(line => html` <div>${line}</div> `);
  }

  __renderButtons() {
    return this.model.buttons.map(
      ({ name, label, role, disabled }, index) => html`
        <neb-button
          class="button"
          .name="${name}"
          .label="${label}"
          .role="${role || (index ? BUTTON_ROLE.OUTLINE : BUTTON_ROLE.CONFIRM)}"
          .onClick="${this.handlers.click}"
          ?disabled="${disabled}"
        ></neb-button>
      `,
    );
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.message.id}" class="message">
        ${this.__renderMessage()}
      </div>

      <div class="buttons">${this.__renderButtons()}</div>
    `;
  }
}

customElements.define('neb-popup-dialog', NebPopupDialog);
