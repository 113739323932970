const createFavicon = sizes => {
  const { protocol, host } = window.location;
  const href = `${protocol}//${host}/favicon/favicon-${sizes}.png`;
  return `<link rel="icon" type="image/png" href="${href}" sizes="${sizes}">`;
};

export const createReportUrl = (link, title) =>
  URL.createObjectURL(
    new Blob(
      [
        `
            <!DOCTYPE html>
              <head>
                <title>${title}</title>
                ${createFavicon('16x16')}
                ${createFavicon('32x32')}
                ${createFavicon('96x96')}
              </head>
              <body>
                <iframe src="${link}" style="position: fixed; inset: 0; border: 0; width: 100%; height: 100%;"></iframe>
              </body>
            </html>
            `,
      ],
      { type: 'text/html' },
    ),
  );
