import { decodeUserPermissions } from '@neb/permissions';

import { ConnectedService } from '../../packages/neb-redux/services/connected-service';
import { objToInitials } from '../../packages/neb-utils/formatters';
import { setRumUser } from '../library/datadog';

const formatName = (first, last) => `${first} ${objToInitials({ last })}.`;

export class PracticeSessionService extends ConnectedService {
  constructor(callback) {
    super({
      isAuthenticated: 'session.isAuthenticated',
      requiresTenantSelection: 'session.requiresTenantSelection',
      userHasNoPractice: 'session.userHasNoPractice',
      user: 'session.item',
    });

    this.onStateChanged = callback;
  }

  _stateChanged({
    isAuthenticated,
    requiresTenantSelection,
    userHasNoPractice,
    user: {
      id,
      tenantId,
      tenantIds,
      profileImgUrl,
      firstName,
      lastName,
      access,
    },
  }) {
    const hasSession =
      !!tenantId &&
      isAuthenticated &&
      !requiresTenantSelection &&
      !userHasNoPractice;

    let user = null;

    if (hasSession) {
      const { p } = access[tenantId];
      const { superUser, permissions } = decodeUserPermissions(p);

      user = {
        id,
        tenantId,
        tenantIds,
        profileImgUrl,
        name: formatName(firstName, lastName),
        permissions,
        superUser,
      };

      setRumUser({ ...user, name: `${firstName} ${lastName}` });
    }

    return this.onStateChanged({
      requiresTenantSelection,
      hasSession,
      user,
    });
  }
}
