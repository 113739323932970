/* eslint-disable complexity */
export const RTE_STATUSES = Object.freeze({
  ERROR_CHC_INTERNAL: 'error-chc-internal',
  ERROR_CHC_VALIDATION: 'error-chc-validation',
  ERROR_INTERNAL: 'error-internal',
  ISSUE: 'issue',
  WARNING: 'warning',
  VALIDATED: 'validated',
  PARTIALLY_VALIDATED: 'partially-validated',
});

export function getRTEStatusIcon(status) {
  switch (status) {
    case RTE_STATUSES.ERROR_CHC_INTERNAL:
      return 'neb:radioButtonUnchecked';

    case RTE_STATUSES.ERROR_CHC_VALIDATION:
      return 'neb:radioButtonUnchecked';

    case RTE_STATUSES.ERROR_INTERNAL:
      return 'neb:radioButtonUnchecked';

    case RTE_STATUSES.ISSUE:
      return 'neb:error';

    case RTE_STATUSES.WARNING:
      return 'neb:rteWarning';

    case RTE_STATUSES.VALIDATED:
      return 'neb:checkCircle';

    case RTE_STATUSES.PARTIALLY_VALIDATED:
      return 'neb:checkOutline';

    default:
      return 'neb:radioButtonUnchecked';
  }
}
