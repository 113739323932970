import '../../../../neb-lit-components/src/components/neb-info';
import './neb-patient-roster-file-upload';
import './neb-patient-roster-upload-pages';

import { openPopup } from '@neb/popup';
import deepEqual from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';
import Papa from 'papaparse';

import * as api from '../../../../neb-api-client/src/patient-roster-api-client';
import * as banner from '../../../../neb-dialog/neb-banner-state';
import { reset } from '../../../../neb-login/neb-timeout-state';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { Dirty } from '../../../../neb-redux/services/dirty';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { CORE_API_URL } from '../../../../neb-utils/env';

export const ELEMENTS = {
  fileUploadPage: {
    id: 'file-upload-page',
  },
  pages: {
    id: 'roster-pages',
  },
};

export const TEXT_UPLOAD_ROSTER_HEADER = 'Patient Roster Upload';
export const ID_HEADER = 'patient-roster-upload-header';
const EXPECTED_HEADERS = [
  'lastName',
  'firstName',
  'middleName',
  'preferredName',
  'suffix',
  'dateOfBirth',
  'ssn',
  'medicalRecordNumber',
  'relationshipStatus',
  'employmentStatus',
  'sex',
  'phoneNumberOne',
  'phoneNumberOneType',
  'phoneNumberTwo',
  'phoneNumberTwoType',
  'phoneNumberThree',
  'phoneNumberThreeType',
  'emailAddressOne',
  'emailAddressTwo',
  'emailAddressThree',
  'addressOne',
  'addressTwo',
  'city',
  'state',
  'zipcode',
];
const INVALID_HEADER_TITLE = 'Unable to process file';
export const INVALID_HEADER_MESSAGE =
  'The file does not match the Patient Roster template format. Please refer to the sample CSV.';
export const GENERIC_ERROR =
  'An error occurred while processing your file. Please try again.';
const READY_STATE_DONE = 4;
const REQUEST_STATUS_OK = 200;

class NebPatientRosterUpload extends LitElement {
  static get properties() {
    return {
      touchDevice: {
        type: Boolean,
      },
      __page: {
        type: String,
      },
      __showIndicator: {
        type: Boolean,
      },
      __progress: {
        type: Number,
      },
      __importPatientJobId: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initPages();

    this.__initHandlers();

    this.__initStatusHandlers();

    this.__initState();
  }

  __initState() {
    this.touchDevice = false;
    this.__page = 'uploader';
    this.__progress = 0;
    this.__showIndicator = false;
    this.__importPatientJobId = 0;
    this.__dirty = new Dirty(store.getState().route.hash.replace('#', ''), () =>
      this.isDirty(),
    );

    this.onAfterPatientSaved = () => {};
  }

  isDirty() {
    return this.__page === 'rosterPages';
  }

  __initPages() {
    this.__pages = {
      uploader: () => this.__renderUploader(),
      rosterPages: () => this.__renderRosterPages(),
    };
  }

  __initStatusHandlers() {
    this.__statusHandlers = {
      Failed: () => {
        throw new Error('Processing failed.');
      },
      Success: () => {
        this.__progress = 1;

        this.__handleSuccess();
      },
      Validating: () => {
        this.__progress = 2 / 3;
      },
      Parsing: () => {},
      Uploading: () => {
        this.__progress = 1 / 3;
      },
    };
  }

  __renderInvalidHeaderErrorMessage() {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: INVALID_HEADER_TITLE,
      message: INVALID_HEADER_MESSAGE,
    });
  }

  __validateHeaders(file) {
    return new Promise(resolve => {
      Papa.parse(file, {
        header: true,

        step(results, parser) {
          const headers = results.meta.fields;
          const validHeaders = deepEqual(headers, EXPECTED_HEADERS);
          parser.abort();
          resolve(validHeaders);
        },

        complete() {},
      });
    });
  }

  __initHandlers() {
    this.__handlers = {
      selectFile: async file => {
        const valid = await this.__validateHeaders(file);

        if (valid) {
          this.__showIndicator = true;
          await this.updateComplete;
          await this.__uploadFileWithProgress(file);
        } else {
          this.__renderInvalidHeaderErrorMessage();
        }
      },
      cancel: async () => {
        if (await openDirtyPopup()) {
          this.__showIndicator = false;
          this.__progress = 0;
          this.__page = 'uploader';
        }
      },
      success: () => {
        this.__showIndicator = false;
        this.__progress = 0;
        this.__page = 'uploader';
      },
      afterPatientSaved: () => this.onAfterPatientSaved(),
      resetTimer: () => store.dispatch(reset()),
    };
  }

  __onUploadProgress(event) {
    this.__progress = event.loaded / event.total / 3;
  }

  __onReadyStateChange(req) {
    return () => {
      if (this.__whenUploadIsSuccessful(req)) {
        const importJob = JSON.parse(req.response);
        this.__importPatientJobId = importJob.data[0].id;

        this.__pollDbForImportPatientJobStatus();
      } else if (this.__whenUploadIsNotSuccessful(req)) {
        this.__handleError();
      }
    };
  }

  __whenUploadIsSuccessful(req) {
    return (
      req.readyState === READY_STATE_DONE && req.status === REQUEST_STATUS_OK
    );
  }

  __whenUploadIsNotSuccessful(req) {
    return (
      req.readyState === READY_STATE_DONE && req.status !== REQUEST_STATUS_OK
    );
  }

  __handleError() {
    store.dispatch(banner.openError(GENERIC_ERROR));
    this.__page = 'uploader';
    this.__showIndicator = false;
    this.__progress = 0;
    this.__importPatientJobId = 0;
  }

  __handleSuccess() {
    this.__page = 'rosterPages';
  }

  async __pollDbForImportPatientJobStatus() {
    try {
      const response = await api.getImportPatientJob(this.__importPatientJobId);

      this.__statusHandlers[response.status]();

      if (this.__progress < 1) {
        setTimeout(() => this.__pollDbForImportPatientJobStatus(), 500);
      }
    } catch (err) {
      this.__handleError();
    }
  }

  __addRequestListeners(resolve, reject, req) {
    req.upload.addEventListener('progress', event =>
      this.__onUploadProgress(event),
    );

    req.upload.addEventListener('load', () => resolve());
    req.upload.addEventListener('error', () => reject());
    req.onreadystatechange = this.__onReadyStateChange(req);
  }

  __sendRequest(file, req) {
    const session = store.getState().session.item;
    const formData = new FormData();
    formData.append('uploadFile', file);
    const tenantId = session ? session.tenantId : '';

    const url = `${CORE_API_URL}/api/v1/tenants/${tenantId}/patients/roster`;
    req.open('POST', url);
    req.setRequestHeader('x-access-token', session.accessToken);
    req.send(formData);
  }

  __uploadFileWithProgress(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      this.__addRequestListeners(resolve, reject, req);

      this.__sendRequest(file, req);
    }).catch(() => {
      this.__handleError();
    });
  }

  static get styles() {
    return css`
      :host {
        display: block;
        height: 100%;
        min-height: 0;
        margin-bottom: ${CSS_SPACING};
      }

      .roster-upload-container {
        display: flex;
        flex-direction: column;
        margin: ${CSS_SPACING};
        min-height: 0;
        height: 100%;
      }

      .patient-roster-upload-header {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .file-upload-page {
        margin-top: ${CSS_SPACING};
      }

      .roster-pages {
        margin-top: 10px;
        margin-bottom: 40px;
        min-height: 0;
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      <div class="roster-upload-container">
        <div class="patient-roster-upload-header" id="${ID_HEADER}">
          ${TEXT_UPLOAD_ROSTER_HEADER}
        </div>
        ${this.__pages[this.__page]()}
      </div>
    `;
  }

  connectedCallback() {
    super.connectedCallback();

    this.__dirty.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__dirty.disconnect();
  }

  __renderUploader() {
    return html`
      <neb-patient-roster-file-upload
        id="${ELEMENTS.fileUploadPage.id}"
        class="file-upload-page"
        .onFileSelected="${this.__handlers.selectFile}"
        .touchDevice="${this.touchDevice}"
        .indicateProgress="${this.__showIndicator}"
        .progressValue="${this.__progress}"
      ></neb-patient-roster-file-upload>
    `;
  }

  __renderRosterPages() {
    return html`
      <neb-patient-roster-upload-pages
        id="${ELEMENTS.pages.id}"
        class="roster-pages"
        .onAfterPatientSaved="${this.__handlers.afterPatientSaved}"
        .onTabChange="${this.__handlers.resetTimer}"
        .importJobId="${this.__importPatientJobId}"
        .onCancel="${this.__handlers.cancel}"
        .onSuccess="${this.__handlers.success}"
      ></neb-patient-roster-upload-pages>
    `;
  }
}

customElements.define('neb-patient-roster-upload', NebPatientRosterUpload);
