import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getProviderUsers } from '../../../../../packages/neb-api-client/src/practice-users-api-client';
import {
  openError,
  openSuccess,
} from '../../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { formatDisplayProviders } from '../../../../../packages/neb-utils/formatters';
import * as feeSchedulesApi from '../../../../api-clients/fee-schedules';
import { CSS_SPACING } from '../../../../styles';
import { NebFormFeeSchedules } from '../../../forms/settings/billing/neb-form-fee-schedules';

const SUCCESS_MESSAGES = {
  addV4: 'Fee schedule saved successfully',
  updateV4: 'Fee schedule updated successfully',
};

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};
class NebOverlayFeeSchedules extends Overlay {
  static get properties() {
    return {
      __savingError: Object,
      __formModel: Object,
      __payers: Array,
      __providers: Array,
    };
  }

  initState() {
    super.initState();

    this.__payers = [];
    this.__providers = [];
    this.__savingError = null;
    this.__formModel = NebFormFeeSchedules.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: model => {
        const action = !model.id ? 'addV4' : 'updateV4';

        this.__save(model, action);
      },
    };
  }

  async __save(model, action) {
    try {
      const result = await feeSchedulesApi[action](model);

      this.__savingError = null;
      this.__formModel = result;
      this.isDirty = false;

      this.dismiss(result);
      store.dispatch(openSuccess(SUCCESS_MESSAGES[action]));
    } catch (e) {
      console.error(e);

      if (e.statusCode === 409) {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Duplicate Name',
          message:
            "There is an existing record with this fee schedule's name. Please review record details and create or update the existing fee schedules accordingly.",
        });
      } else {
        store.dispatch(
          openError('An error occurred when saving the fee schedule'),
        );
      }

      this.__savingError = e;
    }
  }

  async __fetchPayerPlans() {
    const feeScheduleId = this.model.item?.id;

    const { data: availablePayerPlans } =
      await feeSchedulesApi.fetchAvailablePayerPlansV3({
        ...(feeScheduleId && { feeScheduleId }),
      });

    this.__payers = availablePayerPlans;
  }

  async __fetchProviders() {
    const providers = await getProviderUsers();

    this.__providers = formatDisplayProviders(providers, {
      allowEmpty: false,
      includeSpecialists: true,
    });
  }

  __setPayersOnModel() {
    const feeSchedule = this.model.item;
    const feeSchedulePayerIds = feeSchedule.payerPlans.map(item => item.id);

    const selectedPayers = this.__payers.filter(item =>
      feeSchedulePayerIds.includes(item.id),
    );

    this.__formModel = {
      ...feeSchedule,
      payerPlans: selectedPayers,
    };
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      await Promise.all([this.__fetchPayerPlans(), this.__fetchProviders()]);

      if (this.model.item) {
        this.__setPayersOnModel();
      } else {
        this.__formModel = NebFormFeeSchedules.createModel();
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          width: 100%;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    const title = this.model.item ? 'Update Fee Schedule' : 'Add Fee Schedule';

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-fee-schedules
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .savingError="${this.__savingError}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .payers="${this.__payers}"
        .providers="${this.__providers}"
      ></neb-form-fee-schedules>
    `;
  }
}

customElements.define('neb-overlay-fee-schedules', NebOverlayFeeSchedules);
