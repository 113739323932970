import { css, html } from 'lit';

import { TIERS } from '../../../../../src/components/forms/utils/form-utils';
import { store } from '../../../../neb-redux/neb-redux-store';
import { SUBMISSION_METHODS } from '../../../../neb-utils/claims';

import Table from './neb-table';

import '../inputs/neb-textfield';
import '../inputs/neb-select';
import '../neb-text';

export const ELEMENTS = {
  primaryIdentifier: { selector: '[id^=primaryIdentifier-]' },
  secondaryIdentifier: { selector: '[id^=secondaryIdentifier-]' },
  payerName: { selector: '[id^=payerName-]' },
  payerEtin: { selector: '[id^=payerEtin-]' },
  submissionMethodPrimary: { selector: '[id^=submission-method-primary-]' },
  submissionMethodSecondary: { selector: '[id^=submission-method-secondary-]' },
};

const getPrimaryIdentifierLabel = isChc =>
  isChc ? 'CHC Payer ID' : 'Primary Identifier';

export const getPayerTableConfig = ({ isChc, showEtin }) => [
  {
    key: 'payerName',
    label: 'Payer Name',
    flex: css`1 0 100px`,
  },
  {
    key: 'primaryIdentifier',
    label: getPrimaryIdentifierLabel(isChc),
    flex: css`1 0 80px`,
  },
  {
    key: 'submissionMethodPrimary',
    label: 'Primary Submission Method',
    flex: css`1 0 80px`,
  },
  ...[
    isChc
      ? {
          key: 'secondaryIdentifier',
          label: 'Other Payer Identifier',
          flex: css`1 0 80px`,
        }
      : [],
  ],

  {
    key: 'submissionMethodSecondary',
    label: 'Secondary Submission Method',
    flex: css`1 0 80px`,
  },
  ...[
    showEtin
      ? {
          key: 'payerEtin',
          label: 'Payer ETIN',
          flex: css`1 0 80px`,
        }
      : [],
  ],
];

class TablePayerIdentifiers extends Table {
  static get properties() {
    return {
      config: Array,
      __tier: String,
    };
  }

  static createModel() {
    return [
      {
        payerName: '',
      },
    ];
  }

  initState() {
    super.initState();

    this.onSelectPayer = () => {};
    this.__tier = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      selectPayer: e => {
        if (!this.readOnly) {
          this.onSelectPayer(e);
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    const { item } = store.getState().practiceInformation;
    this.__tier = item ? item.tier : '';
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button {
          width: 100%;
        }

        .textfield {
          width: 100%;
        }

        .payerText {
          display: inline-block;
          max-width: 150px;
        }
      `,
    ];
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex, name) {
    const disabled = !this.showRemoveButton;
    const { key } = columnConfig;

    const items =
      this.__tier === TIERS.ADVANCED
        ? SUBMISSION_METHODS
        : [SUBMISSION_METHODS[0], SUBMISSION_METHODS[2]];

    const { isChc, showEtin } = this.payerTableConfig;

    switch (columnConfig.key) {
      case 'primaryIdentifier':
        return html`
          <neb-textfield
            id="${key}-${rowIndex}"
            class="textfield"
            label="${getPrimaryIdentifierLabel(isChc)}"
            helper="Required"
            .error="${!value}"
            .name="${name}"
            .value="${value}"
            .onChange="${this.handlers.change}"
            ?disabled="${disabled}"
          >
          </neb-textfield>
        `;

      case 'secondaryIdentifier':
        return isChc
          ? html`
              <neb-textfield
                id="${key}-${rowIndex}"
                class="textfield"
                label="Other Payer Identifier"
                helper=" "
                .name="${name}"
                .value="${value}"
                .onChange="${this.handlers.change}"
                ?disabled="${disabled}"
              >
              </neb-textfield>
            `
          : '';

      case 'submissionMethodPrimary':
        return html`
          <neb-select
            id="${key}-${rowIndex}"
            label="Primary Submission Method"
            helper=" "
            .items="${items}"
            .onChange="${this.handlers.change}"
            .name="${name}"
            .value="${value}"
          ></neb-select>
        `;

      case 'submissionMethodSecondary':
        return html`
          <neb-select
            id="${key}-${rowIndex}"
            label="Secondary Submission Method"
            helper=" "
            .items="${items}"
            .onChange="${this.handlers.change}"
            .name="${name}"
            .value="${value}"
          ></neb-select>
        `;

      case 'payerName':
        return html`
          <neb-text
            id="${key}-${rowIndex}"
            class="payerText"
            .name="${name}"
            .link="${!this.readOnly}"
            .onClick="${this.handlers.selectPayer}"
            >${value}</neb-text
          >
        `;

      case 'payerEtin':
        return showEtin
          ? html`
              <neb-textfield
                id="${key}-${rowIndex}"
                class="textfield"
                label="Payer ETIN"
                helper=" "
                .name="${name}"
                .value="${value}"
                .onChange="${this.handlers.change}"
                ?disabled="${disabled}"
              >
              </neb-textfield>
            `
          : '';

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}
customElements.define('neb-table-payer-identifiers', TablePayerIdentifiers);
