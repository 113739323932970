import '@polymer/polymer/polymer-legacy';

import { html } from 'lit';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebIconStyles = () => html`
  <style>
    .neb-icon-small-profile {
      @apply --neb-icon-small-profile-styles;
    }

    .neb-icon-large-profile {
      @apply --neb-icon-large-profile-styles;
    }

    .neb-help-icon {
      color: var(--neb-font-color-secondary);
      width: 20px;
      height: 20px;
      padding-left: 10px;
    }

    .neb-close-left-container {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }

    .neb-close-left-container > .neb-close-icon {
      margin-left: -5px;
    }

    .neb-close-right-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .neb-close-right-container > .neb-close-icon {
      margin-right: -5px;
    }

    .neb-close-icon {
      color: var(--neb-font-color-secondary);
      padding: 0;
      width: 24px;
      height: 24px;
      margin-top: -5px;
    }

    :host([layout-small]) .neb-close-icon {
      margin-top: -10px;
    }

    :host([layout-small]) .neb-close-right-container > .neb-close-icon {
      margin-right: -10px;
    }

    :host([layout-small]) .neb-close-left-container > .neb-close-icon {
      margin-left: -10px;
    }
  </style>
`;
_documentContainer.innerHTML = `
<custom-style>
  <style is="custom-style">
    html {

      --neb-icon-small-width: 40px;
      --neb-icon-small-height: 40px;

      --neb-icon-medium-width: 60px;
      --neb-icon-medium-height: 60px;

      --neb-icon-large-width: 106px;
      --neb-icon-large-height: 106px;

      --neb-icon-profile-base: {
        border-radius: 50%;
        padding: 0;
      }

      --neb-icon-small-profile-styles: {
        @apply --neb-icon-profile-base;
        width: var(--neb-icon-small-width);
        height: var(--neb-icon-small-height);
      }

      --neb-icon-medium-profile-styles: {
        @apply --neb-icon-profile-base;
        width: var(--neb-icon-medium-width);
        height: var(--neb-icon-medium-height);
      }

      --neb-icon-large-profile-styles: {
        @apply --neb-icon-profile-base;
        width: var(--neb-icon-large-width);
        height: var(--neb-icon-large-height);
      }

    }
  </style>
</custom-style>

<dom-module id="neb-icon-styles">
  <template>
    ${nebIconStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
