import { html } from 'lit';

import '../../components/context/neb-context-menu';

import { CONTEXT_KEYS } from './constants';

export const RENDERER_CONTEXT = {
  [CONTEXT_KEYS.MENU.name]: (hide, index, layout, model, closeHandler) => html`
    <neb-context-menu
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-context-menu>
  `,
};
