import {
  getAllEncounters,
  searchPriorEncounters,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { formatPriorEncounters } from '../../../../packages/neb-utils/neb-charting-util';

import { safeRequest } from './safe-request';

const PAGE_SIZE = 10;

export const FilterType = Object.freeze({
  CHARGE: 'charge',
  DIAGNOSIS: 'diagnosis',
});

const BLANK = '';

const getEmptyPaginationState = () => ({
  currentPage: 0,
  pageCount: 0,
  count: 0,
  priorEncountersModel: [],
});

export class NebPriorEncountersDataController {
  constructor(host, opts = {}) {
    host.addController(this);
    this.host = host;
    this.__filterType = opts.filterType;

    this.__initState();
  }

  __initState() {
    this.__encounterId = '';
    this.__encounter = {};
    this.__allPriorEncounters = [];
    this.__searchText = BLANK;
    this.__paginationState = getEmptyPaginationState();
  }

  get currentPage() {
    return this.__paginationState.currentPage;
  }

  get count() {
    return this.__paginationState.count;
  }

  get pageCount() {
    return this.__paginationState.pageCount;
  }

  get priorEncountersModel() {
    return this.__paginationState.priorEncountersModel;
  }

  __updatePaginationState(stateUpdates) {
    this.__paginationState = {
      ...this.__paginationState,
      ...stateUpdates,
    };

    this.host.requestUpdate();
  }

  async __searchQueryPriorEncounters(search, pageNumber = 0) {
    const currentPage = pageNumber;
    const offset = currentPage * PAGE_SIZE;
    const results = await searchPriorEncounters(
      this.__encounterId,
      this.__filterType,
      search,
      offset,
      PAGE_SIZE,
    );

    const { total: count } = results;
    const priorEncountersModel = formatPriorEncounters({
      priorEncounters: results.data,
    });

    this.__updatePaginationState({
      count,
      currentPage,
      pageCount: Math.ceil(count / PAGE_SIZE),
      priorEncountersModel,
    });
  }

  __clientPaginateAllEncounters(pageNumber) {
    const currentPage = pageNumber;
    const count = this.__allPriorEncounters.length;

    this.__updatePaginationState({
      count,
      currentPage,
      pageCount: Math.ceil(count / PAGE_SIZE),
      priorEncountersModel: this.__allPriorEncounters.slice(
        currentPage * PAGE_SIZE,
        (currentPage + 1) * PAGE_SIZE,
      ),
    });
  }

  __loadPriorEncounters() {
    this.__searchText = BLANK;
    this.__updatePaginationState(getEmptyPaginationState());

    this.__loadingPromise = safeRequest(async () => {
      const { data: priorEncounters } = await getAllEncounters({
        patientId: this.__encounter.patientId,
        queryParams: {
          encounterId: this.__encounter.id,
        },
        version: 1,
        optOutLoadingIndicator: true,
      });

      if (priorEncounters && priorEncounters.length) {
        const { providers, appointmentTypes } = store.getState();

        this.__allPriorEncounters = formatPriorEncounters({
          priorEncounters,
          providers: providers.item,
          appointmentTypes: appointmentTypes.items,
        });

        this.applyPriorEncountersFilters({ searchText: this.__searchText });
      }
    }, 'Prior Encounters');
  }

  async applyPriorEncountersFilters({
    searchText = null,
    pageNumber = 0,
  } = {}) {
    if (searchText !== null) {
      this.__searchText = searchText;
    }

    if (this.__searchText === BLANK) {
      this.__clientPaginateAllEncounters(pageNumber);
    } else {
      await this.__searchQueryPriorEncounters(this.__searchText, pageNumber);
    }
  }

  hostUpdate() {
    const { encounter } = this.host;
    if (!encounter) return;

    const { id: encounterId } = encounter;

    if (encounterId && this.__encounterId !== encounterId) {
      this.__encounterId = encounterId;
      this.__encounter = encounter;
      this.__loadPriorEncounters();
    }
  }
}
