import '../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import NebPopupClaim from '../../../../packages/neb-popup/src/neb-popup-claim';
import {
  CODE_QUALIFIER,
  REPORT_TRANSMISSION_CODE,
  REPORT_TYPE_CODE,
} from '../../../../packages/neb-utils/claims';
import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_FIELD_MARGIN,
} from '../../../styles';

export const ELEMENTS = {
  supplementalCheckbox: { id: 'supplemental-checkbox' },
  additionalCheckbox: { id: 'additional-checkbox' },
  reportTypeCodeSelect: { id: 'report-type-code-select' },
  reportTransmissionCodeSelect: { id: 'report-transmission-code-select' },
  codeQualifierSelect: { id: 'code-qualifier-select' },
  identificationNumberTextfield: { id: 'identification-number-textfield' },
  additionalClaimInformationTextfield: {
    id: 'additional-claim-information-textfield',
  },
};

const additionalField = 'additionalClaimInformation';

class NebPopupClaimSupplementalInformation extends NebPopupClaim {
  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__state = { ...this.__state, [name]: value };
      },
      confirm: () => this.onClose(this.__state),
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = this.model.items.reduce((acc, { name, value }) => {
        acc[name] = value || '';
        return acc;
      }, {});

      this.__state.additionalCheckbox = !!this.__state[additionalField];
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          min-width: 575px;
        }

        .content {
          max-height: 500px;
          display: block;
          overflow-y: auto;
        }

        .grid {
          display: grid;
          padding: 0 ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING_ROW_LARGE};
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
          justify-content: center;
        }

        .grid-2 {
          grid-template-columns: auto 1fr;
        }

        .grid-4 {
          grid-template-columns: 1fr 1fr;
        }

        .grid-2 .checkbox {
          padding: ${CSS_FIELD_MARGIN} 0 0 0;
        }

        .checkbox {
          display: block;
          padding: 26px ${CSS_SPACING} ${CSS_SPACING_ROW_LARGE};
        }

        .spacing-left {
          margin-left: 38px;
        }

        .scroll {
          display: block;
          flex: 1 0 0;
          max-height: 400px;
          overflow-y: auto;
          margin: -15px 0 -5px;
        }

        .text-input {
          width: 100%;
        }
      `,
    ];
  }

  __formatSupplementalInformationLabel(codeList, code) {
    const formattedLabel = code === '' ? code : `${code} - ${codeList[code]}`;
    return formattedLabel;
  }

  __renderCheckbox({ type }) {
    const checkbox = {
      supplemental: html`
        <neb-checkbox
          id="${ELEMENTS.supplementalCheckbox.id}"
          name="supplementalCheckbox"
          class="checkbox"
          label="Supplemental Information"
          .checked="${!(
            this.__state.reportTypeCode === '' &&
            this.__state.reportTransmissionCode === ''
          )}"
          .onChange="${this.handlers.change}"
          .disabled="${true}"
        ></neb-checkbox>
      `,
      additional: html`
        <neb-checkbox
          id="${ELEMENTS.additionalCheckbox.id}"
          class="checkbox"
          name="additionalCheckbox"
          .checked="${!!this.__state.additionalCheckbox}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>
      `,
    };

    return checkbox[type];
  }

  __renderReportTypeCode() {
    return html`
      <neb-select
        id="${ELEMENTS.reportTypeCodeSelect.id}"
        class="text-input"
        label="Report Type Code"
        name="reportTypeCode"
        helper="Required"
        .items="${[]}"
        .value="${this.__formatSupplementalInformationLabel(
          REPORT_TYPE_CODE,
          this.__state.reportTypeCode,
        )}"
        .error="${this.__errors.reportTypeCode}"
        .onChange="${this.handlers.change}"
        .disabled="${true}"
      ></neb-select>
    `;
  }

  __renderReportTransmissionCode() {
    return html`
      <neb-select
        id="${ELEMENTS.reportTransmissionCodeSelect.id}"
        class="text-input"
        label="Report Transmission Code"
        name="reportTransmissionCode"
        helper="Required"
        .items="${[]}"
        .value="${this.__formatSupplementalInformationLabel(
          REPORT_TRANSMISSION_CODE,
          this.__state.reportTransmissionCode,
        )}"
        .error="${this.__errors.reportTransmissionCode}"
        .onChange="${this.handlers.change}"
        .disabled="${true}"
      ></neb-select>
    `;
  }

  __renderCodeQualifier() {
    return html`
      <neb-select
        id="${ELEMENTS.codeQualifierSelect.id}"
        class="text-input"
        label="Code Qualifier"
        name="codeQualifier"
        .items="${[]}"
        .value="${this.__formatSupplementalInformationLabel(
          CODE_QUALIFIER,
          this.__state.codeQualifier,
        )}"
        .error="${this.__errors.codeQualifier}"
        .onChange="${this.handlers.change}"
        .disabled="${true}"
      ></neb-select>
    `;
  }

  __renderIdentificationNumber() {
    return html`
      <neb-textfield
        id="${ELEMENTS.identificationNumberTextfield.id}"
        class="text-input"
        label="Identification Number"
        name="identificationNumber"
        .value="${this.__state.identificationNumber}"
        .error="${this.__errors.identificationNumber}"
        .onChange="${this.handlers.change}"
        .disabled="${true}"
      ></neb-textfield>
    `;
  }

  __renderAdditionalClaimInformation() {
    return html`
      <neb-textfield
        id="${ELEMENTS.additionalClaimInformationTextfield.id}"
        class="text-input"
        label="Custom Code"
        name="additionalClaimInformation"
        .maxLength="${71}"
        .value="${this.__state.additionalClaimInformation}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  renderContent() {
    if (Object.keys(this.__state).length === 0) return '';

    return html`
      <div class="scroll">
        ${this.__renderCheckbox({ type: 'supplemental' })}
        <div class="grid grid-4 spacing-left">
          ${this.__renderReportTypeCode()}
          ${this.__renderReportTransmissionCode()}
          ${this.__renderCodeQualifier()} ${this.__renderIdentificationNumber()}
        </div>
        <div class="grid grid-2">
          ${this.__renderCheckbox({ type: 'additional' })}
          ${this.__renderAdditionalClaimInformation()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-supplemental-information',
  NebPopupClaimSupplementalInformation,
);
