import '../../../../neb-lit-components/src/components/neb-toggle-button';

import { LitElement, html } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';

import {
  BUTTON_TEXT_ADD_DIAGNOSIS,
  BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS,
} from './neb-charting-diagnosis-icd10-controller';

const BUTTON_TEXT_ADD_ALL_DIAGNOSIS = 'Add All Diagnoses';
const BUTTON_TOGGLED_TEXT_ADD_ALL_DIAGNOSIS = 'All Diagnoses Added';
export const ELEMENTS = {
  button: {
    id: 'button',
  },
  labelCode: {
    id: 'label-code',
  },
  labelDescription: {
    id: 'label-description',
  },
};

class NebChartingDiagnosisPriorDiagnosesRow extends LitElement {
  static get properties() {
    return {
      model: Object,
      header: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {};

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this.model),
    };
  }

  getButtonLabel() {
    return this.header
      ? BUTTON_TEXT_ADD_ALL_DIAGNOSIS
      : BUTTON_TEXT_ADD_DIAGNOSIS;
  }

  __getButtonToggledLabel() {
    return this.header
      ? BUTTON_TOGGLED_TEXT_ADD_ALL_DIAGNOSIS
      : BUTTON_TOGGLED_TEXT_ADD_DIAGNOSIS;
  }

  getCodeLabel() {
    return this.header ? 'ICD10' : this.model.code;
  }

  getDescriptionLabel() {
    return this.header ? 'Description' : this.model.shortDescription;
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          padding: 8px 0;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          align-items: center;
        }

        :host([header]) {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding: ${CSS_SPACING} 0;
        }

        .cell {
          display: flex;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          align-items: flex-end;
        }

        .cell:first-child {
          margin-left: ${CSS_SPACING};
        }

        .cell-add {
          flex: 0 0 185px;
          white-space: nowrap;
          margin-right: 40px;
        }

        .cell-code {
          flex: 0 0 80px;
        }

        .cell-description {
          flex: 1 0 90px;
        }
      </style>
    `;
  }

  __getButtonText() {
    return this.model.added
      ? this.__getButtonToggledLabel()
      : this.getButtonLabel();
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="cell cell-add">
        <neb-toggle-button
          id="${ELEMENTS.button.id}"
          .buttonText="${this.__getButtonText()}"
          .isToggled="${this.model.added}"
          .onClick="${this.__handlers.click}"
        ></neb-toggle-button>
      </div>

      <div id="${ELEMENTS.labelCode.id}" class="cell cell-code">
        ${this.getCodeLabel()}
      </div>

      <div id="${ELEMENTS.labelDescription.id}" class="cell cell-description">
        ${this.getDescriptionLabel()}
      </div>
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-prior-diagnoses-row',
  NebChartingDiagnosisPriorDiagnosesRow,
);
