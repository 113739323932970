import '../../neb-material-design/src/components/neb-md-select';
import '../../neb-lit-components/src/components/inputs/neb-select-search';

import { html, css } from 'lit';

import { PAYER_PLAN_VERSION_CLAIMS } from '../../neb-api-client/src/payer-plan-api-client';
import { PayerPlanQueryService } from '../../neb-api-client/src/services/payer-plan';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';
import Debouncer from '../../neb-utils/debouncer';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  buttonCopy: {
    id: 'button-copy',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  searchInput: {
    id: 'search',
    label: 'Payer Plan',
    emptyMessage: 'No Payers Found',
  },
};

class NebCopyExistingPayer extends NebPopup {
  static get properties() {
    return {
      __payerPlans: {
        type: Array,
      },
      __payerSearchValue: {
        type: String,
      },
      __selectedPayerPlanId: {
        type: String,
      },
    };
  }

  initState() {
    super.initState();
    this.title = 'Copy Details from Existing Payer';
    this.__payerPlans = [];
    this.__payerSearchValue = '';
    this.__selectedPayerPlanId = '';
    this.__query = {
      hideInactive: true,
      search: '',
    };

    this.__searchDebouncer = new Debouncer(() => {
      this.__payerPlanQueryService.update(this.__query);
    });
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      copy: () =>
        this.onClose({
          accepted: true,
          payerPlanId: this.__selectedPayerPlanId,
        }),
      cancel: () => this.onClose({ accepted: false }),
      payerSelected: ({ value }) => {
        this.__selectedPayerPlanId = value && value !== '' ? value.id : '';
      },
      search: ({ value: search }) => {
        if (search !== this.__payerSearchValue) {
          this.__payerSearchValue = search;
          this.__query.search = search;

          this.__searchDebouncer.debounce();
        }
      },
    };
  }

  __setupPayerPlanQueryService() {
    this.__payerPlanQueryService = new PayerPlanQueryService(
      ({ model: payerPlans }) => {
        this.__payerPlans = payerPlans.map(payer => ({
          ...payer,
          label: `(${payer.alias}) ${
            payer.payerName ? `${payer.payerName}` : ''
          }`,
        }));
      },
      PAYER_PLAN_VERSION_CLAIMS,
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.__setupPayerPlanQueryService();

    this.__payerPlanQueryService.update(this.__query);
  }

  __getSelectedPrimaryPayer() {
    return this.__payerPlans.find(
      payer => payer.id === this.__selectedPayerPlanId,
    );
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          margin-top: -${CSS_SPACING};
        }

        .content {
          flex-basis: auto;
          min-width: 460px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .search {
          z-index: 1;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <neb-select-search
          id="${ELEMENTS.searchInput.id}"
          class="search"
          name="payerPlanId"
          .label="${ELEMENTS.searchInput.label}"
          .items="${this.__payerPlans}"
          .value="${this.__getSelectedPrimaryPayer()}"
          .search="${this.__payerSearchValue}"
          .emptyMessage="${ELEMENTS.searchInput.emptyMessage}"
          .onSearch="${this.__handlers.search}"
          .onChange="${this.__handlers.payerSelected}"
          showSearch
        ></neb-select-search>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonCopy.id}"
            class="button"
            label="COPY"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.copy}"
            ?disabled="${!this.__selectedPayerPlanId}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="CANCEL"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-copy-existing-payer', NebCopyExistingPayer);
