import '../../neb-tooltip';
import '../../../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_CAPTION,
  CSS_FONT_SIZE_BODY,
  CSS_BORDER_GREY_1,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_WARNING_COLOR,
  CSS_WARNING_BACKGROUND_COLOR,
} from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  data: { id: 'data' },
  expandIcon: { id: 'expand-icon' },
  label: { id: 'label' },
  problemText: { id: 'problem-text' },
  tooltip: { id: 'tooltip' },
};

class NebClaimFormCell extends LitElement {
  static get properties() {
    return {
      data: String,
      problemText: {
        type: String,
        reflect: true,
        converter: {
          toAttribute: val => val || null,
        },
      },
      name: String,
      label: String,
      tooltipAnchor: String,
      type: String,
      borderRight: {
        type: Boolean,
        reflect: true,
      },
      borderLeft: {
        type: Boolean,
        reflect: true,
      },
      borderTop: {
        type: Boolean,
        reflect: true,
      },
      borderBottom: {
        type: Boolean,
        reflect: true,
      },
      footerBreak: {
        type: Boolean,
        reflect: true,
      },
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
      warn: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([problemText]) .container {
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([problemText][warn]) .container {
          background-color: ${CSS_WARNING_BACKGROUND_COLOR};
          border: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([borderRight]) .container {
          border-right: ${CSS_BORDER_GREY_1};
        }

        :host([borderLeft]) .container {
          border-left: ${CSS_BORDER_GREY_1};
        }

        :host([borderTop]) .container {
          border-top: ${CSS_BORDER_GREY_1};
        }

        :host([borderBottom]) .container {
          border-bottom: ${CSS_BORDER_GREY_1};
        }

        :host([footerBreak]) .footer {
          padding-top: 10px;
        }

        :host([borderRight][problemText]) .container {
          border-right: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderLeft][problemText]) .container {
          border-left: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderTop][problemText]) .container {
          border-top: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderBottom][problemText]) .container {
          border-bottom: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([borderRight][problemText][warn]) .container {
          border-right: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([borderLeft][problemText][warn]) .container {
          border-left: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([borderTop][problemText][warn]) .container {
          border-top: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([borderBottom][problemText][warn]) .container {
          border-bottom: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([warn]) .footer {
          color: ${CSS_WARNING_COLOR};
        }

        :host([renderExpandIcon]) .container {
          cursor: pointer;
        }

        :host([renderExpandIcon]) .container:hover {
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([renderExpandIcon]) .container:hover .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .container {
          display: flex;
          flex-direction: column;

          width: 100%;
          padding: 10px 10px 10px ${CSS_SPACING};
        }

        .data-container {
          padding-top: 13px;
          font-size: ${CSS_FONT_SIZE_BODY};
          word-break: break-word;
        }

        .header-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-self: flex-start;
          align-items: center;
        }

        .label-tooltip-container {
          display: flex;
        }

        .label {
          padding-right: 10px;
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .tooltip {
          padding-right: 10px;
        }

        .footer {
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_BANNER_ERROR_COLOR};
        }

        .content {
          flex: 1 0 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandler();
  }

  __initState() {
    this.renderExpandIcon = false;
    this.borderRight = false;
    this.borderBottom = false;
    this.footerBreak = false;
    this.borderTop = false;
    this.borderLeft = false;
    this.warn = false;
    this.label = '';
    this.data = '';
    this.tooltipAnchor = 'right';

    this.onClick = () => {};
  }

  __initHandler() {
    this.__handlers = {
      click: () => {
        if (this.renderExpandIcon) {
          this.onClick({
            title: this.label,
            name: this.name,
            type: this.type,
          });
        }
      },
    };
  }

  get __dataTemplate() {
    return this.data
      ? html`
          <div id="${ELEMENTS.data.id}" class="data-container">
            ${this.data}
          </div>
        `
      : '';
  }

  get __expandButtonTemplate() {
    return this.renderExpandIcon
      ? html`
          <neb-icon
            id="${ELEMENTS.expandIcon.id}"
            class="icon"
            icon="neb:expand"
          ></neb-icon>
        `
      : '';
  }

  get __headerTemplate() {
    return html`
      <div class="label-tooltip-container">
        <div id="${ELEMENTS.label.id}" class="label">${this.label}</div>
      </div>

      ${this.__expandButtonTemplate}
    `;
  }

  get __problemTextTemplate() {
    return this.problemText
      ? html` <div id="${ELEMENTS.problemText.id}">${this.problemText}</div> `
      : '';
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <div class="header-container">${this.__headerTemplate}</div>
        <div class="content"><slot></slot> ${this.__dataTemplate}</div>
        <div class="footer">${this.__problemTextTemplate}</div>
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell', NebClaimFormCell);
