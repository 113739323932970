import '../../scheduling/neb-appointment-edit-form-controller';

import { html, css } from 'lit';

import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebOverlayAppointmentEditForm extends Overlay {
  initHandlers() {
    super.initHandlers();

    this.handlers.appointmentChanged = (_, dirty) => {
      this.isDirty = dirty;
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([layout='small']) .content {
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-appointment-edit-form-controller
        id="${ELEMENTS.controller.id}"
        .layout="${this.layout}"
        .appointmentId="${this.model.appointmentId}"
        .onChange="${this.handlers.appointmentChanged}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
      ></neb-appointment-edit-form-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-appointment-edit-form',
  NebOverlayAppointmentEditForm,
);
