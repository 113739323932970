import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { parseDate } from '../../neb-utils/date-util';

import { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});
export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});

export const getEmptyTreatmentPlan = () => ({
  startDate: parseDate().startOf('day').format(ISO_DATE_FORMAT),
  treatmentPhases: [],
});

export const calculateTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  appointmentsApiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
    queryParams,
    replacements: { patientId },
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });

export const getTreatmentPlan = async (patientId, queryParams = {}) => {
  const res = await coreApiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
    replacements: { patientId },
    method: Method.GET,
    queryParams,
  });

  return res.data[0];
};

export const getCompletedTreatmentPlans = async patientId => {
  const res = await coreApiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
    replacements: { patientId },
    method: Method.GET,
    queryParams: { status: 'Completed' },
  });

  return res.data;
};

export const updateTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  coreApiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans/:id',
    replacements: { patientId, id: treatmentPlan.id },
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
    queryParams,
  });

export const createTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  coreApiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
    replacements: { patientId },
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
    queryParams,
  });
