import { css, html } from 'lit';

import Table from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_HIGHLIGHT } from '../../../../packages/neb-styles/neb-variables';
import { CLAIM_STATUS } from '../../../../packages/neb-utils/claims';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import { allowDownload } from '../../../utils/e-claim-files';

export const ELEMENTS = {
  icons: { selector: '[id^=icon-row-]' },
  linkBatchId: { id: 'link-batch-id' },
  iconDownloads: { selector: '[id^=icon-downloads-]' },
};

const ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  x12: 'neb:download',
};

const CONFIG = [
  {
    key: 'isElectronic',
    label: '',
    flex: css`0 0 22px`,
  },
  {
    key: 'claimNumber',
    label: 'Claim',
    flex: css`1 0 0`,
  },
  {
    key: 'batchId',
    label: 'Batch ID',
    flex: css`0.7 0 0`,
  },
  {
    key: 'billingProviderName',
    label: 'Provider',
    flex: css`2 0 0`,
    truncate: true,
  },
  {
    key: 'payer',
    label: 'Payer',
    flex: css`2 0 0`,
    truncate: true,
  },
  {
    key: 'insurancePlanName',
    label: 'Plan',
    flex: css`2 0 0`,
    truncate: true,
  },
  {
    key: 'totalChargeAmount',
    label: 'Total Charges',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    key: 'paymentResponsibilityLevelCode',
    label: 'Type',
    flex: css`1 0 0`,
  },
  {
    key: 'createdAt',
    label: 'Date Created',
    flex: css`1 0 0`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'claimStatuses.0.status',
    label: 'Status',
    flex: css`1 0 0`,
  },
];

class NebTableLedgerChargesClaim extends Table {
  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
        .icon-download {
          width: 22px;
          height: 22px;
          padding-left: 5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.emptyMessage =
      'There are no claims for this invoice. Click "Add" to create a new record.';

    this.mode = MODE.DETAIL;
    this.config = CONFIG;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickBatchId: async ({ name: batchId }) => {
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, { batchId });
      },
    };
  }

  __shouldRenderDownload(item) {
    if (item.eClaimFile) {
      const { id } = item.eClaimFile;
      const { status } = item.claimStatuses[0];

      const checkDuration =
        status === CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE
          ? allowDownload(item.updatedAt)
          : true;

      return id && checkDuration;
    }
    return false;
  }

  __renderDownloadX12Icon(rowIndex) {
    return this.__shouldRenderDownload(this.model[rowIndex])
      ? html`
          <neb-button-icon
            class="icon-download"
            id="icon-downloads-${rowIndex}"
            icon="${ICON.x12}"
          ></neb-button-icon>
        `
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'claimNumber':
        return html`
          <neb-text>${value}</neb-text>
          ${this.__renderDownloadX12Icon(rowIndex)}
        `;

      case 'isElectronic': {
        const { isElectronic } = this.model[rowIndex];

        return html`
          <neb-icon
            id="icon-row-${rowIndex}"
            class="icon"
            .icon="${isElectronic ? ICON.electronic : ICON.paper}"
          ></neb-icon>
        `;
      }

      case 'batchId':
        return value
          ? html`
              <neb-text
                id="${ELEMENTS.linkBatchId.id}"
                name="${value}"
                .onClick="${this.handlers.clickBatchId}"
                bold
                link
              >${value}</neb-button-action>
            `
          : '';

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

customElements.define(
  'neb-table-ledger-charges-claim',
  NebTableLedgerChargesClaim,
);
