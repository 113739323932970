import '../../../../neb-document/src/components/neb-documents-scroll';

import { LitElement, html } from 'lit';

export const ELEMENTS = {
  scroll: {
    id: 'scroll',
  },
};
export class NebChartingDocumentsSummary extends LitElement {
  static get properties() {
    return {
      documents: Array,
      selectedDocumentIndex: Number,
      noDocuments: Boolean,
      isOnEncounterDocuments: Boolean,
    };
  }

  constructor() {
    super();

    this.__initHandlers();

    this.documents = [];
    this.noDocuments = false;
    this.isOnEncounterDocuments = true;

    this.onRequestDocuments = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      requestDocuments: () => this.onRequestDocuments(),
      selectDocumentIndex: index => this.onSelectDocumentIndex(index),
    };
  }

  scrollToDocument(index) {
    this.__elements.scroll.scrollToDocument(index);
  }

  reset() {
    this.__elements.scroll.scrollTo(0, 0);
  }

  firstUpdated() {
    this.__elements = {
      scroll: this.shadowRoot.getElementById(ELEMENTS.scroll.id),
    };
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .documents-scroll-container {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="documents-scroll-container">
        <neb-documents-scroll
          id="${ELEMENTS.scroll.id}"
          .documents="${this.documents}"
          .totalDocuments="${this.totalDocuments}"
          .selectedDocumentIndex="${this.selectedDocumentIndex}"
          .onRequestDocuments="${this.__handlers.requestDocuments}"
          .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
          .horizontalScroll="${true}"
          .noDocuments="${this.noDocuments}"
          .isOnEncounterDocuments="${this.isOnEncounterDocuments}"
        ></neb-documents-scroll>
      </div>
    `;
  }
}
customElements.define(
  'neb-charting-documents-summary',
  NebChartingDocumentsSummary,
);
