import stylesCss from '../material_styles/mdc-theme-css';

const STYLE_ID = 'material-style';

const init = () => {
  const currStyle = document.getElementById(STYLE_ID);
  if (currStyle && currStyle.innerHTML.length) return;
  const style = document.createElement('style');
  style.setAttribute('id', STYLE_ID);
  style.innerHTML = stylesCss;
  document.documentElement.append(style);
};

export default {
  init,
};
