import DeprecatedApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const BASE_URL = 'eclaim-file-download';

export const getFileDownloads = (optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    optOutLoadingIndicator,
  });

export const downloadFile = async (id, optOutLoadingIndicator = false) => {
  const response = await apiClient.makeRequest({
    path: `${BASE_URL}/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });

  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};

export const deleteDownloadedFiles = (ids, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.DELETE,
    path: BASE_URL,
    body: JSON.stringify({ ids }),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
    optOutLoadingIndicator,
  });
