import './neb-self-guarantor-table';
import './neb-guarantor-collection-page';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  guarantorCollectionPage: {
    id: 'guarantor-collection-page',
  },
  selfTable: {
    id: 'self-guarantor',
  },
};
class NebPatientGuarantorPage extends LitElement {
  static get properties() {
    return {
      guarantors: { type: Array },
      layout: {
        type: String,
        reflect: true,
      },
      patient: { type: Object },
      patientForm: { type: Boolean },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.patient = {};
    this.patientForm = false;
    this.guarantors = [{ balance: 0 }];

    this.onChecked = () => {};

    this.onGuarantorChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      guarantorChange: hideInactive => this.onGuarantorChange(hideInactive),
      check: (tabName, items) => this.onChecked(tabName, items),
    };
  }

  static get styles() {
    return css`
      :host {
        overflow-y: auto;
        background-color: white;
        height: 100%;
      }

      .container {
        display: block;
      }

      .self-guarantor {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        margin: 10px ${CSS_SPACING};
        border-radius: 5px;
        border: ${CSS_BORDER_GREY_2};
        width: 500px;
      }
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="self-guarantor">
          <neb-self-guarantor-table
            id="${ELEMENTS.selfTable.id}"
            .layout="${this.layout}"
            .patient="${this.patient}"
            .balance="${this.guarantors[0].balance}"
          >
          </neb-self-guarantor-table>
        </div>

        <neb-guarantor-collection-page
          id="${ELEMENTS.guarantorCollectionPage.id}"
          .patientId="${this.patient.id}"
          .onGuarantorChange="${this.__handlers.guarantorChange}"
          .onChecked="${this.__handlers.check}"
          .patientForm="${this.patientForm}"
          .layout="${this.layout}"
        ></neb-guarantor-collection-page>
      </div>
    `;
  }
}
window.customElements.define(
  'neb-patient-guarantor-page',
  NebPatientGuarantorPage,
);
