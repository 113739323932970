import { html, css } from 'lit';
import '../../neb-lit-components/src/components/forms/neb-form-patient-online-payment';
import '../../neb-lit-components/src/components/patients/payment/neb-patient-online-payment-payfields-form';
import '../../../src/features/payfac/neb-payfac-fiserv-booking';

import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../neb-utils/constants';
import { centsToCurrency } from '../../neb-utils/formatters';
import {
  SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH,
  SMALL_LAYOUT_ONLINE_PAYMENTS_POPUP_HEIGHT,
} from '../../neb-utils/payment-popup-constants';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
  subtitle: {
    id: 'subtitle',
  },
  payfieldsForm: {
    id: 'pay-fields-form',
  },
};

class NebPopupPatientOnlinePayment extends NebPopup {
  static get properties() {
    return {
      __formModel: Object,
      __payFieldsModel: Object,
      __isOnPayfields: Boolean,
    };
  }

  initState() {
    super.initState();

    this.title = 'Payment Amount';
    this.subtitle = 'Select the amount you would like to pay.';
    this.__formModel = {
      paymentAmount: 0,
      currentBalance: 0,
      otherAmount: 0,
      patient: {},
      merchantAccount: {},
      radioTotal: true,
      radioOther: false,
    };

    this.__payFieldsModel = {
      title: 'Card Details',
      subheader: 'Enter the card details for this transaction.',
      message: 'Payment Amount',
      confirmLabel: '',
    };

    this.__isOnPayfields = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      pageBack: () => {
        this.__isOnPayfields = false;
        this.showHeader = true;
      },
      nextPage: formModel => {
        this.__payFieldsModel = {
          ...this.__payFieldsModel,
          paymentAmount: formModel.paymentAmount,
          merchantAccount: formModel.merchantAccount,
          patient: formModel.patient,
          practiceInfo: this.__formModel.practiceInfo,
          billingAddress: this.__formModel.billingAddress,
        };

        this.__isOnPayfields = true;
        this.showHeader = false;
      },
      save: result => this.onClose(result),
      cancel: () => this.onClose(false),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          height: ${SMALL_LAYOUT_ONLINE_PAYMENTS_POPUP_HEIGHT}px;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: -20px;
        }

        .header {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
        }

        .subtitle {
          padding-top: 20px;
        }

        :host .content {
          margin-top: 0px;
        }
      `,
    ];
  }

  modelChanged() {
    this.__formModel = {
      ...this.__formModel,
      ...this.model,
      paymentAmount: this.model.currentBalance,
    };
  }

  __renderPaymentFields() {
    if (
      this.__payFieldsModel.merchantAccount?.merchantProvider ===
      MERCHANT_PROVIDERS.FISERV
    ) {
      return html`
        <neb-payfac-fiserv-booking
          id="${ELEMENTS.payfieldsForm.id}"
          .title="${this.__payFieldsModel.title}"
          .amount="${centsToCurrency(this.__payFieldsModel.paymentAmount)}"
          .merchantAccount="${this.__payFieldsModel.merchantAccount}"
          .patient="${this.__payFieldsModel.patient}"
          .practiceInfo="${this.__payFieldsModel.practiceInfo}"
          .billingAddress="${this.__payFieldsModel.billingAddress}"
          message="${this.__payFieldsModel.message}"
          subheader="${this.__payFieldsModel.subheader}"
          .confirmLabel="${this.__payFieldsModel.confirmLabel}"
          .layout="${this.layout}"
          .onSave="${this.handlers.save}"
          .onCancel="${this.handlers.cancel}"
          .onBack="${this.handlers.pageBack}"
        ></neb-payfac-fiserv-booking>
      `;
    }

    return html`
      <neb-patient-online-payment-payfields-form
        id="${ELEMENTS.payfieldsForm.id}"
        .title="${this.__payFieldsModel.title}"
        .amount="${centsToCurrency(this.__payFieldsModel.paymentAmount)}"
        .merchantAccount="${this.__payFieldsModel.merchantAccount}"
        .patient="${this.__payFieldsModel.patient}"
        .practiceInfo="${this.__payFieldsModel.practiceInfo}"
        .billingAddress="${this.__payFieldsModel.billingAddress}"
        message="${this.__payFieldsModel.message}"
        subheader="${this.__payFieldsModel.subheader}"
        confirmLabel="${this.__payFieldsModel.confirmLabel}"
        .layout="${this.layout}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.pageBack}"
      ></neb-patient-online-payment-payfields-form>
    `;
  }

  __renderPaymentSelection() {
    return html`
      <neb-form-patient-online-payment
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .onCancel="${this.handlers.cancel}"
        .onNext="${this.handlers.nextPage}"
      ></neb-form-patient-online-payment>
    `;
  }

  renderContent() {
    return html`
      ${!this.__isOnPayfields
        ? html`
            <div class="container-content">
              <div id="${ELEMENTS.subtitle.id}" class="subtitle">
                ${this.subtitle}
              </div>
              ${this.__renderPaymentSelection()}
            </div>
          `
        : this.__renderPaymentFields()}
    `;
  }
}

customElements.define(
  'neb-popup-patient-online-payment',
  NebPopupPatientOnlinePayment,
);
