import '../../neb-card';
import '../../../../../neb-patient/src/components/ledger/payment/neb-payments-list-page';
import './neb-practice-ledger-activity-controller';
import '../../patients/ledger/balance/neb-ledger-balance-controller';
import '../../patients/ledger/charges/neb-ledger-charges-controller';
import { navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { FAILED_PAYMENTS_COUNT_ERROR } from '../../../../../../src/utils/user-message';
import { getLedgerBillingTotals } from '../../../../../neb-api-client/src/ledger/totals';
import { countFailedPayments } from '../../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import { openError } from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_ERROR,
} from '../../../../../neb-styles/neb-variables';
import {
  centsToCurrency,
  formatDollarAmount,
} from '../../../../../neb-utils/formatters';
import { DEFAULT_LEDGER_TOTALS } from '../../../../../neb-utils/neb-ledger-util';
import { NEBULA_REFRESH_EVENT } from '../../../../../neb-utils/neb-refresh';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { CARDS } from '../../patients/ledger/neb-ledger-cards';

export const ELEMENTS = {
  container: { id: 'container' },
  ledgerCards: {
    id: 'ledger-cards',
  },
  activityPage: {
    id: 'page-activity',
  },
  balancePage: {
    id: 'page-balance',
  },
  chargesPage: {
    id: 'page-charges',
  },
  paymentsPage: {
    id: 'page-payments',
  },
  spinner: {
    id: 'spinner',
  },
  iconFailedPayments: {
    id: 'icon-failed-payments',
  },
  linkFailedPayments: {
    id: 'link-payments-link',
  },
};

export const PRACTICE_LEDGER_ERROR_BANNER =
  'An error occurred while fetching ledger totals';

class NebPracticeLedger extends LitElement {
  static get properties() {
    return {
      __selectedCard: String,
      __totals: Object,
      __failedPaymentsCount: Number,

      layout: {
        type: String,
        reflect: true,
      },
      route: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__selectedCard = CARDS.ACTIVITY;
    this.__totals = DEFAULT_LEDGER_TOTALS;
    this.__navItems = this.genNavItems();
    this.__elements = {
      paymentsPage: null,
      chargesPage: null,
      activityPage: null,
      balancePage: null,
    };

    this.__failedPaymentsCount = 0;

    this.layout = '';
    this.route = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectCard: card => {
        this.__selectedCard = card;
        navigate(this.__buildRoute(card));
      },
      ledgerButtonClicked: async cardType => {
        if (cardType === CARDS.PAYMENTS) {
          const payerPayment = await openOverlay(OVERLAY_KEYS.EOB_FORM);

          if (payerPayment) {
            await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
              readonly: false,
              payerPayment,
            });
          }
        }

        return this.__handleAddCardType(cardType);
      },
      refresh: () => this.__getLedgerTotals(),

      openFailedPaymentsOverlay: async () => {
        await openOverlay(OVERLAY_KEYS.FAILED_PAYMENTS);

        await this.__fetchFailedPaymentsCount();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);

    this.__fetchFailedPaymentsCount();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
  }

  genNavItems() {
    return [
      {
        id: CARDS.ACTIVITY,
        path: '/activity/',
        resolver: () => html`
          <neb-practice-ledger-activity-controller
            id="${ELEMENTS.activityPage.id}"
            .layout="${this.layout}"
            .route="${this.route}"
          ></neb-practice-ledger-activity-controller>
        `,
      },
      {
        id: CARDS.BALANCE,
        path: '/balance/',
        resolver: () => html`
          <neb-ledger-balance-controller
            id="${ELEMENTS.balancePage.id}"
            .layout="${this.layout}"
            .route="${this.route}"
            .balance="${this.__totals.balance}"
            .onChange="${this.__handlers.refresh}"
          ></neb-ledger-balance-controller>
        `,
      },
      {
        id: CARDS.CHARGES,
        path: '/charges/',
        resolver: () => html`
          <neb-ledger-charges-controller
            id="${ELEMENTS.chargesPage.id}"
            .layout="${this.layout}"
            .route="${this.route}"
            .onChange="${this.__handlers.refresh}"
          ></neb-ledger-charges-controller>
        `,
      },
      {
        id: CARDS.PAYMENTS,
        path: '/payments/',
        resolver: () => html`
          <neb-payments-list-page
            id="${ELEMENTS.paymentsPage.id}"
            .layout="${this.layout}"
            .onPaymentAction="${this.__handlers.refresh}"
          ></neb-payments-list-page>
        `,
      },
    ];
  }

  __buildRoute(card) {
    return `#/practice-ledger/ledger/${card}`;
  }

  __getRouteTail() {
    return this.route.split('/')[2];
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();

    return this.layout === 'large'
      ? !!this.__navItems.find(navItem => navItem.id === routeTail)
      : routeTail === '';
  }

  __buildCardValues() {
    const {
      payments: { unallocated, warning },
      charges: { open, responsible } = {},
      balance: { patientOwed, patientPaid, payerOwed, payerPaid } = {},
      activity: { encountersNotBalanced = 0, numberOfPurchases = 0 } = {},
    } = this.__totals;

    return [
      {
        encountersNotBalanced,
        numberOfPurchases,
      },
      {
        open: centsToCurrency(open),
        patientResponsible: centsToCurrency(responsible),
      },
      {
        unallocated: centsToCurrency(unallocated),
        warning,
      },
      {
        payers: formatDollarAmount(payerOwed - payerPaid),
        patients: formatDollarAmount(patientOwed - patientPaid),
      },
    ];
  }

  async __handleAddCardType() {
    if (this.__elements.paymentsPage) {
      await this.__elements.paymentsPage.service.fetch();
    }

    if (this.__elements.chargesPage) {
      this.__elements.chargesPage.fetch();
    }

    if (this.__elements.activityPage) {
      this.__elements.activityPage.fetch();
    }

    if (this.__elements.balancePage) {
      this.__elements.balancePage.fetch();
    }

    return this.__getLedgerTotals();
  }

  async __getLedgerTotals() {
    try {
      this.__totals = await getLedgerBillingTotals();
    } catch (e) {
      console.error(e);
      store.dispatch(openError(PRACTICE_LEDGER_ERROR_BANNER));
      this.__totals = { ...DEFAULT_LEDGER_TOTALS };
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          position: relative;
          flex-direction: column;
          width: 100%;
          height: auto;
        }

        .content {
          display: flex;
          flex-direction: column;
          height: auto;
          min-height: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-link {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .container-links {
          display: flex;
          align-items: center;
        }

        .container-link-failed-payments {
          margin-left: ${CSS_SPACING};
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 12px ${CSS_SPACING};
        }

        .header-text {
          flex: 1 0 0;
          height: fit-content;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon {
          margin-right: 5px;
        }

        .icon-warning {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_ERROR};
        }

        .link {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .spinner {
          position: absolute;
          display: flex;
          width: calc(100% - 50px);
          height: 200px;
        }

        .header-container {
          display: flex;
          align-items: center;
          margin: 20px;
          margin-bottom: 0px;
        }
      `,
    ];
  }

  firstUpdated() {
    this.__getLedgerTotals();
    this.__fetchFailedPaymentsCount();
  }

  update(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let card = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        card = this.layout === 'large' ? CARDS.ACTIVITY : '';
        const route =
          card === CARDS.ACTIVITY ? `${CARDS.ACTIVITY}/encounters` : '';

        redirect(this.__buildRoute(route));
      }

      this.__selectedCard = card;
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('route')) {
      this.__elements = {
        activityPage: this.shadowRoot.getElementById(ELEMENTS.activityPage.id),
        paymentsPage: this.shadowRoot.getElementById(ELEMENTS.paymentsPage.id),
        chargesPage: this.shadowRoot.getElementById(ELEMENTS.chargesPage.id),
        balancePage: this.shadowRoot.getElementById(ELEMENTS.balancePage.id),
      };
    }
  }

  async __fetchFailedPaymentsCount() {
    if (this.layout !== 'large') return;

    try {
      const { count } = await countFailedPayments();
      this.__failedPaymentsCount = count;
    } catch (err) {
      this.__failedPaymentsCount = 0;
      store.dispatch(openError(FAILED_PAYMENTS_COUNT_ERROR));
    }
  }

  __renderTotals() {
    return html`
      <div class="header-container">
        <div class="header-text">Practice Totals</div>
        <div class="container-links">${this.__renderFailedPaymentsLink()}</div>
      </div>

      <div class="header">
        <neb-ledger-cards
          id="${ELEMENTS.ledgerCards.id}"
          hideChargeButton="true"
          hideBalanceButton="true"
          .layout="${this.layout}"
          .cardValues="${this.__buildCardValues()}"
          .selectedCard="${this.__selectedCard}"
          .onCardSelect="${this.__handlers.selectCard}"
          .onButtonClick="${this.__handlers.ledgerButtonClicked}"
          ?disableCardClick="${this.layout !== 'large'}"
          ?loading="${this.__totals === DEFAULT_LEDGER_TOTALS}"
        ></neb-ledger-cards>
      </div>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(({ id }) => id === this.__selectedCard);
    return item.resolver();
  }

  __renderFailedPaymentsCount() {
    return this.__failedPaymentsCount !== 0
      ? `(${this.__failedPaymentsCount})`
      : '';
  }

  __renderFailedPaymentsLink() {
    return this.layout === 'large' && this.__failedPaymentsCount !== 0
      ? html`
          <div
            class="container-link container-link-failed-payments"
            @click="${this.__handlers.openFailedPaymentsOverlay}"
          >
            <neb-icon
              id="${ELEMENTS.iconFailedPayments.id}"
              class="icon icon-warning"
              icon="neb:warning"
            ></neb-icon>

            <neb-text id="${ELEMENTS.linkFailedPayments.id}" link Bold>
              Payment Alerts ${this.__renderFailedPaymentsCount()}
            </neb-text>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="content">${this.__renderTotals()}</div>
        ${this.layout === 'large' ? this.__renderTabContent() : ''}
      </div>
    `;
  }
}

window.customElements.define('neb-practice-ledger', NebPracticeLedger);
