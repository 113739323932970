import './neb-encounter-list';

import { css, html, LitElement } from 'lit';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import * as insuranceStatusApi from '../../../../../src/api-clients/insurance-status';
import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import { clearContexts } from '../../../../../src/utils/context/constants';
import { PollController } from '../../../../../src/utils/poll-controller';
import {
  getAppointments,
  getProviderAppointments,
} from '../../../../neb-api-client/src/appointment-api-client';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../../neb-api-client/src/patient-image-api-client';
import { getPracticeUser } from '../../../../neb-api-client/src/permissions-api-client';
import { getProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import { fetchOne } from '../../../../neb-api-client/src/settings-api-client';
import { computeTime } from '../../../../neb-input/nebFormatUtils';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { parseDate } from '../../../../neb-utils/date-util';
import { SERVICE_TIME_FORMAT } from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  list: { id: 'list' },
};

export const NO_PROVIDER_SCHEDULED = {
  label: 'No Provider Scheduled',
  id: null,
};

const ENCOUNTER_STATUS = {
  none: 'No Encounter',
  open: 'Open',
  signed: 'Signed',
};

const ACTIVE_CHECKED_IN_OUT = ['Active', 'Checked-In', 'Checked-Out'];

const ALL_STATUSES_EXCEPT_DELETED_AND_CANCELED = [
  'Active',
  'No-Show',
  'Checked-In',
  'Checked-Out',
];

const ENCOUNTER_LOADING_MESSAGE = Object.freeze({
  loadingEncounters: 'Loading Encounters...',
  loadingEncounter: 'Loading Encounter...',
  creatingEncounter: 'Creating Encounter...',
});

const BASE_QUERY_PARAMS = {
  secondarySortDir: 'desc',
  secondarySortField: 'start',
  sortDir: 'desc',
  sortField: 'start',
  status: ALL_STATUSES_EXCEPT_DELETED_AND_CANCELED,
  standardSort: true,
  expand: 'encounter,splits',
};

const uniq = arr => [...new Set(arr)];

function getProviderName(provider) {
  if (provider.name) {
    return `${provider.name.last}, ${provider.name.first}`;
  }

  return provider.lastName && provider.firstName
    ? `${provider.lastName}, ${provider.firstName}`
    : 'No Provider Scheduled';
}

function mapAppointment(appointment) {
  return {
    id: appointment.id || '',
    patientId: appointment.patientId || '',
  };
}

class NebEncounterListController extends LitElement {
  static get properties() {
    return {
      __sortByStatus: { type: Boolean },
      __events: { type: Object },
      __loadingIndicator: { type: Object },
      __userLocations: { type: Array },
      __patients: { type: Array },
      __providers: { type: Array },
      __rooms: { type: Array },
      appointmentTypes: { type: Object },
      encounterId: { type: String },
      selectedDate: { type: Object },
      filteredPatient: { type: Object },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .list {
          display: block;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__sortByStatus = false;
    this.__events = {};
    this.__loadingIndicator = this.__getLoadingIndicatorData({ loading: true });
    this.__providers = [];
    this.__rooms = [];
    this.__patients = [];
    this.__userLocations = [];
    this.filteredPatient = null;

    this.appointmentTypes = {};
    this.encounterId = '';
    this.selectedDate = parseDate();

    this.onSelect = () => {};

    this.onUpdateFilteredPatient = () => {};
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__userLocations = this.__formatLocations(userLocations);
    });

    this.__pollController = new PollController(
      this,
      async () => {
        await this.__updateEvents(true);
      },
      { force: true },
    );
  }

  __initHandlers() {
    this.__handlers = {
      addWalkInPatient: () => this.__updateEvents(),
      displayCard: model => {
        const { eventId, patient } = model;

        if (this.__patients[patient.id]) {
          const { hasPhoto, photoSrc } = this.__patients[patient.id];

          if (hasPhoto && (!photoSrc || patient.photoSrc !== photoSrc)) {
            this.__getProfilePicture(eventId, patient.id);
          }
        }
      },
      select: async e => {
        if (this.__loadingIndicator.loading) return;

        this.__loadingIndicator = this.__getLoadingIndicatorData({
          loading: true,
          message: e.value?.encounter
            ? ENCOUNTER_LOADING_MESSAGE.loadingEncounter
            : ENCOUNTER_LOADING_MESSAGE.creatingEncounter,
        });

        await this.onSelect(e);

        this.__loadingIndicator = this.__getLoadingIndicatorData({
          loading: false,
        });
      },
      selectDate: date => {
        this.selectedDate = date;

        return this.__updateEvents();
      },
      overlayUpdated: async (appointment, eventId) => {
        this.__loadingIndicator = this.__getLoadingIndicatorData({
          loading: true,
        });

        if (!eventId) {
          await this.__updateEvents();

          return;
        }

        const {
          status,
          arrivedAt,
          details: { appointmentTypeName: appointmentType, location },
          start,
          end,
          confirmedAt,
          providerId,
          resourceId,
          roomId,
          appointmentTypeId,
        } = appointment;

        const dayIsSame = parseDate(start)
          .startOf('day')
          .isSame(parseDate(this.__events[eventId].start).startOf('day'));

        if (!ACTIVE_CHECKED_IN_OUT.includes(status) || !dayIsSame) {
          delete this.__events[eventId];
        }

        const patient = await patientApiClient.fetchOne(
          appointment.patient.id,
          true,
        );
        const provider = appointment.providerId
          ? await getPracticeUser(appointment.providerId)
          : {};

        if (patient.hasPhoto) {
          patient.photoSrc = await getPatientImage(
            appointment.patient.id,
            'small',
            true,
          );
        }

        this.__events[eventId] = {
          ...this.__events[eventId],
          status,
          arrivedAt,
          appointmentType,
          start: parseDate(start),
          end: parseDate(end),
          duration: computeTime(end.diff(start)),
          formattedServiceTime: parseDate(start).format(SERVICE_TIME_FORMAT),
          patient,
          providerId,
          resource: this.__rooms.find(r => r.id === resourceId),
          room: this.__rooms.find(r => r.id === roomId),
          locationName: location,
          markerColor: this.appointmentTypes[appointmentTypeId].color,
          provider: getProviderName(provider),
          confirmedAt: parseDate(confirmedAt),
        };

        this.__events = { ...this.__events };
        this.__loadingIndicator = this.__getLoadingIndicatorData({
          loading: false,
        });
      },
      updateEvents: async () => {
        await this.__updateEvents(false);
      },
      patientSearch: patient => {
        this.onUpdateFilteredPatient(patient);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);

    const {
      data: { sortAppointmentsByStatus },
    } = await fetchOne(true);
    this.__sortByStatus = sortAppointmentsByStatus;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __getLoadingIndicatorData({
    loading = false,
    message = ENCOUNTER_LOADING_MESSAGE.loadingEncounters,
  }) {
    return { loading, message };
  }

  async __updateEvents(optOutLoadingIndicator = false) {
    clearContexts();
    const originalSelectedDate = moment(this.selectedDate);

    const events = await this.__fetchEvents(optOutLoadingIndicator);

    if (originalSelectedDate.isSame(moment(this.selectedDate))) {
      this.__events = events;
      this.__loadingIndicator = this.__getLoadingIndicatorData({
        loading: false,
      });
    }
  }

  __buildPatientsMap(patients) {
    return patients.reduce((acc, patient) => {
      acc[patient.id] = patient;

      if (patient.bookingAccountId) {
        acc[patient.bookingAccountId] = acc[patient.id];
      }

      return acc;
    }, {});
  }

  __getAppointmentType(appointment) {
    return (
      this.appointmentTypes[appointment.appointmentTypeId] || {
        name: '',
        color: '',
      }
    );
  }

  __getPatient(appointment) {
    const patient =
      this.__patients[appointment.patientId || appointment.accountId];
    return patient || this.__formatOnlineBookingPatient(appointment);
  }

  __getPatientImage(patient) {
    return patient.hasPhoto && !patient.photoSrc
      ? getPatientImage(patient.id, 'small', true)
      : patient.photoSrc;
  }

  async __getProfilePicture(eventId, patientId) {
    const patient = await patientApiClient.fetchOne(patientId, true);

    patient.photoSrc = await this.__getPatientImage(patient);

    if (patient.photoSrc === null) {
      patient.hasPhoto = false;
    }

    this.__patients[patientId] = patient;
    this.__patients = { ...this.__patients };

    this.__events[eventId] = {
      ...this.__events[eventId],
      patient,
    };

    this.__events = { ...this.__events };
  }

  __formatOnlineBookingPatient({ firstName: first, lastName: last }) {
    return { name: { first, last } };
  }

  __formatLocations(locations) {
    return locations.map(location => ({ ...location, label: location.name }));
  }

  __getProviderLabel(providers, appointment) {
    const provider = providers.find(p => p.id === appointment.providerId);
    return provider ? provider.label : 'No Provider Scheduled';
  }

  __getEncounterStatus(appt) {
    if (appt.encounter) {
      return appt.encounter.signed
        ? ENCOUNTER_STATUS.signed
        : ENCOUNTER_STATUS.open;
    }
    return ENCOUNTER_STATUS.none;
  }

  async __fetchEvents(optOutLoadingIndicator) {
    this.__loadingIndicator = this.__getLoadingIndicatorData({
      loading: !optOutLoadingIndicator,
    });

    const events = {};

    let patientIds;
    let appointments;

    let insuranceStatuses = [];
    let realTimeEligibilityStatus = null;

    const start = this.selectedDate.clone().startOf('day');
    const startBefore = this.selectedDate.clone().endOf('day');

    if (this.filteredPatient) {
      const queryParams = {
        ...BASE_QUERY_PARAMS,
        patientId: this.filteredPatient.id,
        startBefore: startBefore.format(),
        includeEncounterOnly: true,
        sortParams: {
          key: 'start',
          dir: 'asc',
        },
      };

      const res = await getAppointments(queryParams);
      appointments = res.data;

      patientIds = [this.filteredPatient.id];
    } else {
      const res = await getProviderAppointments(
        {
          start: start.format(),
          startBefore: startBefore.format(),
          end: startBefore.add(1, 'days').format(),
          expand: 'encounter,splits',
          includeEncounterOnly: true,
          sortParams: {
            key: 'start',
            dir: 'asc',
          },
        },
        true,
      );
      appointments = res.data.filter(appt => !appt.name);

      patientIds = uniq(
        appointments.map(x => x.patientId || x.accountId).filter(x => x),
      );
    }

    const providers = await getProviderUsers();
    this.__rooms = await fetchManyRooms();

    if (this.__hasAddOnCTVerify) {
      insuranceStatuses = await insuranceStatusApi.fetchMany(
        appointments.map(a => mapAppointment(a)),
        true,
      );
    }
    this.__patients = this.__buildPatientsMap(
      await patientApiClient.fetchSome(
        patientIds,
        { includeBooking: true, includeImage: true },
        false,
        true,
      ),
    );

    this.__providers = [
      NO_PROVIDER_SCHEDULED,
      ...providers.map(v => ({
        ...v,
        label: getProviderName(v),
      })),
    ];

    appointments.forEach(appointment => {
      let newEvent = {};

      const eventId = uuid();
      const appointmentType = this.__getAppointmentType(appointment);
      const appointmentStart = parseDate(appointment.start);
      const appointmentEnd = parseDate(appointment.end);
      const duration = computeTime(
        appointmentEnd.valueOf() - appointmentStart.valueOf(),
      );
      const patient = this.__getPatient(appointment);
      const location = this.__userLocations.find(
        l => l.id === appointment.locationId,
      );
      const resource = this.__rooms.find(r => r.id === appointment.resourceId);
      const room = this.__rooms.find(r => r.id === appointment.roomId);
      const provider = this.__getProviderLabel(this.__providers, appointment);
      const encounterStatus = this.__getEncounterStatus(appointment);

      if (this.__hasAddOnCTVerify) {
        ({ status: realTimeEligibilityStatus } = insuranceStatuses.find(
          s => s.appointmentId === appointment.id,
        ));
      }

      newEvent = {
        ...appointment,
        ...(appointment.encounter || { signed: false }),
        appointmentType: appointmentType.name,
        duration,
        markerColor: appointmentType.color,
        formattedServiceTime: appointmentStart.format(SERVICE_TIME_FORMAT),
        patient,
        provider,
        locationName: location ? location.name : '',
        realTimeEligibilityStatus: this.__hasAddOnCTVerify
          ? realTimeEligibilityStatus
          : null,
        resource: resource || null,
        room: room || null,
        encounterStatus,
      };

      events[eventId] = { ...newEvent, eventId };
    });

    return events;
  }

  async updated(changedProps) {
    if (changedProps.has('filteredPatient')) {
      await this.__updateEvents();
    }
  }

  render() {
    return html`
      <neb-encounter-list
        id="${ELEMENTS.list.id}"
        class="list"
        .encounterId="${this.encounterId}"
        .events="${Object.values(this.__events)}"
        .providers="${this.__providers}"
        .locations="${this.__userLocations}"
        .selectedDate="${this.selectedDate}"
        .sortByStatus="${this.__sortByStatus}"
        .filteredPatient="${this.filteredPatient}"
        .onAddWalkInPatient="${this.__handlers.addWalkInPatient}"
        .onDisplayCard="${this.__handlers.displayCard}"
        .onSelect="${this.__handlers.select}"
        .onSelectDate="${this.__handlers.selectDate}"
        .onUpdateOverlay="${this.__handlers.overlayUpdated}"
        .onQuickAction="${this.__handlers.updateEvents}"
        .onPatientSearch="${this.__handlers.patientSearch}"
        .loadingIndicator="${this.__loadingIndicator}"
      ></neb-encounter-list>
    `;
  }
}

window.customElements.define(
  'neb-encounter-list-controller',
  NebEncounterListController,
);
