import DeprecatedApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new DeprecatedApiClient({ microservice: 'billing' });

export const getPreferredPaymentAssociations = patientId =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/payment-associations`,
    version: 1,
  });
