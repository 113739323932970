import '../neb-tooltip';
import '../neb-dropdown';

import { html, css, LitElement } from 'lit';

import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_1,
  CSS_SPACING_ROW,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { POPOVER_POSITION } from '../neb-date-picker';

export const TYPE_DROPDOWN_ITEMS = [
  {
    displayValue: 'Acute',
    value: 'acute',
  },
  {
    displayValue: 'Chronic',
    value: 'chronic',
  },
  {
    displayValue: 'Self-Limiting',
    value: 'self-limiting',
  },
];
export const DATE_ONSET = 'Onset Date';
export const DATE_RESOLUTION = 'Resolution Date';
export const DROPDOWN_PROVIDER = 'Provider';
export const DROPDOWN_STATUS = 'Status';
export const DROPDOWN_TYPE = 'Type';
export const NOTES = 'Notes';
const STATUS_DEFAULT = {
  displayValue: 'Active',
  value: 'active',
};
export const TEXT_TOOLTIP =
  'This field will be disabled until the problem status is set to "Resolved" and the "Onset Date" is populated.';
export const TYPE_RESOLVED = 'resolved';
export const STATUS_DROPDOWN_ITEMS = [
  STATUS_DEFAULT,
  {
    displayValue: 'Inactive',
    value: 'inactive',
  },
  {
    displayValue: 'Resolved',
    value: 'resolved',
  },
];
export const ELEMENTS = {
  onsetDatePicker: {
    id: 'onset-date-picker',
  },
  providerDropdown: {
    id: 'provider-dropdown',
  },
  resolutionDatePicker: {
    id: 'resolution-date-picker',
  },
  statusDropdown: {
    id: 'status-dropdown',
  },
  toolTipText: {
    id: 'tooltip-text',
  },
  typeDropdown: {
    id: 'type-dropdown',
  },
  textAreaNote: {
    id: 'textarea-note',
  },
  toolTip: {
    id: 'tooltip',
  },
};

class NebProblemsFormBody extends LitElement {
  static get properties() {
    return {
      invalidFields: Array,
      errors: Object,
      notes: String,
      onsetDate: Object,
      provider: String,
      resolutionDate: Object,
      status: String,
      type: String,
      small: {
        type: Boolean,
        reflect: true,
      },
      providerDropdownData: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.invalidFields = [];
    this.provider = '';
    this.type = '';
    this.status = STATUS_DEFAULT;
    this.onsetDate = parseDate().startOf('day');
    this.resolutionDate = null;
    this.notes = '';
    this.small = false;
    this.providerDropdownData = {};
    this.errors = {
      [DROPDOWN_STATUS]: '',
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      providerSelected: item => {
        this.onChange(DROPDOWN_PROVIDER, item.selectedItem);
      },
      typeSelected: item => {
        this.onChange(DROPDOWN_TYPE, item.selectedItem);
      },
      statusSelected: item => {
        this.onChange(DROPDOWN_STATUS, item.selectedItem);
      },
      onsetDateSelected: date => {
        this.onChange(DATE_ONSET, date);
      },
      resolutionDateSelected: date => {
        this.onChange(DATE_RESOLUTION, date);
      },
      noteInputUpdated: () => {
        this.onChange(NOTES, this.__elements.notes.value);
      },
      clearOnsetDate: () => {
        this.onChange(DATE_ONSET, null);
      },
      clearResolutionDate: () => {
        this.onChange(DATE_RESOLUTION, null);
      },
      resolutionDateSelectable: date => date > this.onsetDate,
      onsetDateSelectable: date => date <= parseDate(),
    };
  }

  firstUpdated() {
    this.__elements = {
      notes: this.shadowRoot.getElementById(ELEMENTS.textAreaNote.id),
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex:
        flex-direction: column;
        width: 100%;
      }

      :host([small]) .row {
        display: flex;
        flex-direction: column;
      }

      :host([small]) .dropdown-container:first-child {
        max-width: unset;
        width: 100%;
        margin-right: 0;
      }

      :host([small]) .body-row {
        padding: 0;
      }

      :host([small]) .dropdown-container {
        padding: 10px 0;
      }


      :host([small]) .dropdown-container.spacer {
        padding: 0;
      }

      :host([small]) .resolution-date-picker {
        width: 100%;
        margin-right: 10px;
      }


      .close-icon-container {
        width: 19px;
        color: ${CSS_COLOR_GREY_1};
      }

      .note-title {
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      .note-input {
        margin-left: 0;
        padding: 6px;
        resize: none;
        outline: none;
        font-size: ${CSS_FONT_SIZE_BODY};
        height: 100%;
        border: 1px solid ${CSS_COLOR_GREY_2};
      }

      .onset-date-picker {
        width: 100%;
      }

      .dropdown-container {
        flex: 1 0 0;
      }

      .dropdown-container:first-child {
        margin-right: 30px;
        max-width: 47%;
      }


      .resolution-date-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .row {
        display: flex;
      }

      .body-row {
        padding: ${CSS_SPACING_ROW} 0;
      }

      .note {
        flex-direction: column;
      }
    `;
  }

  updated(changedProps) {
    if (changedProps.has('onsetDate')) {
      this.__handlers.resolutionDateSelectable = date => date > this.onsetDate;

      this.requestUpdate();
    }
  }

  __isInvalid(item) {
    return !!this.invalidFields.find(field => field === item);
  }

  __renderNoteTextField() {
    return html`
      <div class="note-title">Note</div>
      <textarea
        id="${ELEMENTS.textAreaNote.id}"
        @keyup="${this.__handlers.noteInputUpdated}"
        class="note-input"
        .value="${this.notes}"
        rows="5"
      ></textarea>
    `;
  }

  __enableResolutionDate() {
    return this.status.value === TYPE_RESOLVED && this.onsetDate;
  }

  __renderResolutionDatePicker() {
    return html`
      <div class="dropdown-container">
        <div class="label">${DATE_RESOLUTION}</div>
        <div class="resolution-date-container">
          <neb-date-picker
            id="${ELEMENTS.resolutionDatePicker.id}"
            class="resolution-date-picker"
            .placeholder="${''}"
            .selectedDate="${this.resolutionDate}"
            .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .onClick="${this.__handlers.resolutionDateSelected}"
            .onClear="${this.__handlers.clearResolutionDate}"
            .isDateSelectable="${this.__handlers.resolutionDateSelectable}"
            ?invalid="${this.__isInvalid(DATE_RESOLUTION)}"
            ?disabled="${!this.__enableResolutionDate()}"
            momentFlag
          ></neb-date-picker>
          <div class="close-icon-container">
            <neb-tooltip
              id="${ELEMENTS.toolTip.id}"
              ?small="${this.small}"
              defaultAnchor="right"
              ><div id="${ELEMENTS.toolTipText.id}" slot="tooltip">
                ${TEXT_TOOLTIP}
              </div></neb-tooltip
            >
          </div>
        </div>
      </div>
    `;
  }

  __renderOnsetDatePicker() {
    return html`
      <div class="dropdown-container">
        <div class="label">${DATE_ONSET}</div>
        <neb-date-picker
          id="${ELEMENTS.onsetDatePicker.id}"
          class="onset-date-picker"
          .placeholder="${''}"
          .selectedDate="${this.onsetDate}"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .onClick="${this.__handlers.onsetDateSelected}"
          .onClear="${this.__handlers.clearOnsetDate}"
          .isDateSelectable="${this.__handlers.onsetDateSelectable}"
          ?invalid="${this.__isInvalid(DATE_ONSET)}"
          momentFlag
        ></neb-date-picker>
      </div>
    `;
  }

  __renderStatusDropdown() {
    return html`
      <div class="dropdown-container">
        <div class="label">${DROPDOWN_STATUS}</div>
        <neb-dropdown
          id="${ELEMENTS.statusDropdown.id}"
          .items="${STATUS_DROPDOWN_ITEMS}"
          .selectedItem="${this.status}"
          .onItemSelected="${this.__handlers.statusSelected}"
          .invalidText="${this.errors[DROPDOWN_STATUS]}"
          .small="${this.small}"
          ?invalid="${!!this.errors[DROPDOWN_STATUS]}"
        ></neb-dropdown>
      </div>
    `;
  }

  __renderTypeDropdown() {
    return html`
      <div class="dropdown-container">
        <div class="label">${DROPDOWN_TYPE}</div>
        <neb-dropdown
          id="${ELEMENTS.typeDropdown.id}"
          .items="${TYPE_DROPDOWN_ITEMS}"
          .selectedItem="${this.type}"
          .onItemSelected="${this.__handlers.typeSelected}"
          .small="${this.small}"
          .useEmptyDefault="${true}"
          ?invalid="${this.__isInvalid(DROPDOWN_TYPE)}"
        ></neb-dropdown>
      </div>
    `;
  }

  __renderProviderDropdown() {
    return html`
      <div class="dropdown-container">
        <div class="label">${DROPDOWN_PROVIDER}</div>
        <neb-dropdown
          id="${ELEMENTS.providerDropdown.id}"
          .items="${this.providerDropdownData.providerDropdownItems}"
          .selectedItem="${this.provider}"
          .onItemSelected="${this.__handlers.providerSelected}"
          .small="${this.small}"
          .useEmptyDefault="${true}"
          ?invalid="${this.__isInvalid(DROPDOWN_PROVIDER)}"
        ></neb-dropdown>
      </div>
    `;
  }

  render() {
    return html`
      <div class="row body-row">
        ${this.__renderProviderDropdown()} ${this.__renderTypeDropdown()}
      </div>

      <div class="row body-row">
        ${this.__renderStatusDropdown()}
        <div class="dropdown-container spacer"></div>
      </div>

      <div class="row body-row">
        ${this.__renderOnsetDatePicker()} ${this.__renderResolutionDatePicker()}
      </div>

      <div class="row note">${this.__renderNoteTextField()}</div>
    `;
  }
}

customElements.define('neb-problems-form-body', NebProblemsFormBody);
