import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import NebFormMacroSpine from '../../../../packages/neb-lit-components/src/components/forms/neb-form-macro-spine';
import { SPINE_LABELS } from '../../../../packages/neb-lit-components/src/components/macros/utils/spine';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

const formatModel = ({ question }) => {
  const defaultIndices = new Array(SPINE_LABELS.length).fill(null);

  question.defaultIndices.forEach(i => {
    defaultIndices[i] = i;
  });

  return {
    question: {
      ...question,
      defaultIndices,
    },
  };
};

class NebOverlayMacroSpine extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__formModel = NebFormMacroSpine.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      confirm: ({ question }) => {
        this.handlers.dirty(false);

        this.dismiss({
          question: {
            text: question.text,
            defaultIndices: question.defaultIndices.filter(i => i !== null),
            answerSetId: this.model.defaultSpineAnswerSetId,
            type: this.model.type,
          },

          answerSets: this.model.answerSets,
        });
      },

      dismissWithoutResult: () => this.dismiss({ dismissWithoutResult: true }),
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__formModel = this.model.question
        ? formatModel(this.model)
        : NebFormMacroSpine.createModel();
    }

    super.update(changedProps);
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        title="Advanced Macros - Spine"
        .showBackButton="${this.model.showBackButton}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-form-macro-spine
        id="${ELEMENTS.form.id}"
        confirmLabel="Done"
        .model="${this.__formModel}"
        .onSave="${this.handlers.confirm}"
        .onCancel="${this.handlers.dismissWithoutResult}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-macro-spine>
    `;
  }
}

customElements.define('neb-overlay-macro-spine', NebOverlayMacroSpine);
