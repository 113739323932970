import { html, css } from 'lit';

import {
  NebMDBaseTextField,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-md-base-textfield';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  input: {
    id: 'textarea-input',
  },
};

class NebMDTextArea extends NebMDBaseTextField {
  static get properties() {
    return {
      rows: {
        type: Number,
      },
      cols: {
        type: Number,
      },
      resize: {
        type: Boolean,
      },
      readonly: {
        type: Boolean,
      },
    };
  }

  get inputEl() {
    return this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  get textfieldClasses() {
    return `${super._textFieldClasses()} mdc-text-field--textarea `;
  }

  initState() {
    super.initState();
    this.resize = false;
    this.readonly = false;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .mdc-text-field {
          height: 100%;
        }

        .textfield-base
          .mdc-text-field__input
          .mdc-text-field---outlined
          .mdc-text-field---textarea {
          height: 100%;
        }

        .mdc-text-field--textarea .mdc-text-field__input {
          padding: 10px 15px 10px 15px;
          margin: 0;
        }
      `,
    ];
  }

  __renderTextarea() {
    const resizeStyle = this.resize ? 'vertical' : 'none';
    const placeholder = this.getPlaceholder();

    return html`
      <textarea
        id="${ELEMENTS.input.id}"
        class="mdc-text-field__input"
        style="resize:${resizeStyle}"
        placeholder="${placeholder}"
        maxlength="${this.maxLength}"
        minlength="${this.minLength}"
        rows="${this.rows}"
        cols="${this.cols}"
        aria-controls="${ELEMENTS.helperText.id}"
        aria-describedby="${ELEMENTS.helperText.id}"
        @input="${this.handlers.input}"
        ?readonly="${this.readonly || this.disabled}"
      ></textarea>
    `;
  }

  renderTextfield() {
    return html`
      ${this.__renderTextarea()} ${this.__mdcNotchHTML(ELEMENTS.input.id)}
    `;
  }
}

customElements.define('neb-md-textarea', NebMDTextArea);
