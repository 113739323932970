import { getOrganizations } from '../../neb-api-client/src/organizations';

const toNumeric = str => str.replace(/\D/g, '');

export class OrganizationService {
  constructor(callback) {
    this.onOrganizationsChanged = callback;

    this.__fetchOrganizations();
  }

  search(query = '') {
    const refinedQuery = query.trim().split(' ');

    const searchResults = this.__organizations.filter(organization =>
      this.__filter(organization, refinedQuery),
    );

    this.onOrganizationsChanged(searchResults);
  }

  async fetch() {
    await this.__fetchOrganizations();
  }

  async update(query = '') {
    const refinedQuery = query.trim().split(' ');
    this.__organizations = await getOrganizations();

    const searchResults = this.__organizations.filter(organization =>
      this.__filter(organization, refinedQuery),
    );

    this.onOrganizationsChanged(searchResults);
  }

  __filter(organization, queryTerms) {
    const { emails, phones, name } = organization;
    const phoneItems = phones.map(phone => phone.number);
    const plainTextPhoneItems = phones.map(phone => toNumeric(phone.number));
    const search = [
      name,
      ...phoneItems,
      ...plainTextPhoneItems,
      ...emails,
    ].join(' ');
    return queryTerms.every(term =>
      search.toLowerCase().includes(term.toLowerCase()),
    );
  }

  async __fetchOrganizations() {
    this.__organizations = await getOrganizations();
    this.onOrganizationsChanged(this.__organizations);
  }
}
