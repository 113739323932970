import '../../../inputs/neb-textbox';
import '../../../forms/neb-form-encounter-charges';
import '../../../neb-loading-overlay';

import { html, css } from 'lit';

import { getMany } from '../../../../../../../src/api-clients/charges';
import { getLowInventoryMessage } from '../../../../../../../src/api-clients/inventory';
import { getAppointmentById } from '../../../../../../neb-api-client/src/appointment-api-client';
import { saveAndPostEncounterCharges } from '../../../../../../neb-api-client/src/charting/encounter-charge';
import { fetchOne } from '../../../../../../neb-api-client/src/patient-cases';
import { getChartingPermissions } from '../../../../../../neb-api-client/src/permissions-api-client';
import {
  EncounterDataService,
  ENCOUNTER,
  CHARGES,
  DIAGNOSES,
} from '../../../../../../neb-api-client/src/services/encounter-data';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../../../../neb-dialog/neb-banner-state';
import { EncounterService } from '../../../../../../neb-patient/src/services/encounter';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../../neb-redux/services/locations';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../../neb-styles/neb-variables';
import {
  getFeeSchedule,
  mapEncounterCharge,
  mapSettingsCharges,
} from '../../../../../../neb-utils/fee-schedule';
import { formatEncounterNumber } from '../../../../../../neb-utils/neb-encounters-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../../neb-utils/neb-refresh';
import Overlay from '../../neb-overlay';

export const ELEMENTS = {
  form: { id: 'form-encounter-charges' },
  spinner: { id: 'spinner' },
};
const ERROR_CHARGES_FETCH_MESSAGE =
  'An error occurred while retrieving charges';

class NebOverlayLedgerEncounterCharge extends Overlay {
  static get properties() {
    return {
      __autoSelectEncounter: Boolean,
      __locations: Array,
      __initialEncounters: Array,
      __filteredEncounters: Array,
      __settingsCharges: Array,
      __diagnosisItems: Array,
      __selectedEncounter: Object,
      __selectedEncounterData: Object,
      __selectedAuthorization: Object,
    };
  }

  constructor() {
    super();

    this.initState();
    this.initServices();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__autoSelectEncounter = false;
    this.__selectedEncounter = null;
    this.__selectedEncounterData = {};
    this.__selectedAuthorization = null;
    this.__locations = [];
    this.__initialEncounters = [];
    this.__filteredEncounters = [];
    this.__diagnosisItems = [];
  }

  initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__encounterService = new EncounterService((data, count) => {
      const encounters = data.map(e => {
        const location = this.__locations.find(l => l.id === e.locationId);
        const locationName = location ? location.name : '';
        return { ...e, locationName };
      });

      this._updateEncounters(encounters, count);
    });

    this.__encounterDataService = new EncounterDataService(
      async ({ charges, diagnoses }) => {
        const feeSchedule = await this.__getFeeSchedule();

        const encounterCharges = charges.map(item => {
          const itemWithModifiers = {
            ...item,
            modifiers: [
              item.modifier_1,
              item.modifier_2,
              item.modifier_3,
              item.modifier_4,
            ],
          };

          return mapEncounterCharge(itemWithModifiers, feeSchedule);
        });

        encounterCharges.forEach(item => {
          delete item.modifier_1;
          delete item.modifier_2;
          delete item.modifier_3;
          delete item.modifier_4;
        });

        this.__selectedEncounterData = {
          items: encounterCharges,
        };

        this.__diagnosisItems = diagnoses.map(d => ({
          item: d,
          label: d.code,
        }));
      },
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectEncounter: async ({ value }) => {
        this.__selectedEncounter = value;

        if (!this.model.fromCheckOut && value) {
          await this.__getAuthorizationFromEncounter();
        }
      },
      filterEncounter: ({ value }) => {
        if (value) {
          const searchTerms = value.trim().split(' ');

          this.__filteredEncounters = this.__initialEncounters.filter(e =>
            searchTerms.every(text =>
              e.label.toLowerCase().includes(text.toLowerCase()),
            ),
          );
        } else {
          this.__filteredEncounters = this.__initialEncounters;
        }
      },
      changeForm: dirty => {
        this.isDirty = dirty;
      },
      dismissAll: result => {
        if (this.model.invoiceId) {
          this.dismiss(true);
        } else {
          this.dismiss(result, true);
        }
      },
      cancel: result => {
        if (this.model.fromCheckOut) {
          this.dismiss(false);
        } else {
          this.handlers.dismissAll(result);
        }
      },
      save: async model => {
        const { invoiceId, fromCheckOut } = this.model;

        try {
          const encounterChargesWithEncounterIds = model.items.map(c => {
            const encounterCharge = {
              ...c,
              ...this.__buildModifiers(c),
              encounterId: this.__selectedEncounter.id,
              taxName: c.taxName || null,
              taxRate: c.taxRate || null,
            };

            delete encounterCharge.modifiers;
            return encounterCharge;
          });

          const { postedLineItemIds, postedChargeIds } =
            await saveAndPostEncounterCharges(this.__selectedEncounter.id, {
              encounterCharges: encounterChargesWithEncounterIds,
              patientId: this.model.patientId,
              invoiceId: invoiceId || null,
            });

          sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);

          store.dispatch(openSuccess('Charge(s) posted successfully'));

          const inventoryMessage = await getLowInventoryMessage({
            chargeIds: postedChargeIds,
            codes: [],
          });

          if (inventoryMessage.length) {
            store.dispatch(openInfo(inventoryMessage));
          }

          this.isDirty = false;

          if (invoiceId || fromCheckOut) {
            this.handlers.dismiss(postedLineItemIds);
          } else {
            this.handlers.dismissAll();
          }
        } catch (e) {
          store.dispatch(
            openError('An error occurred when posting charge(s).'),
          );
        }
      },
    };
  }

  dismissWithBlocker() {
    this.handlers.dismissAll();
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();

    this.__encounterService.connect();

    try {
      this.__settingsCharges = (await getMany({ hideInactive: true })).map(
        c => ({
          ...c,
          code: c.procedure,
          feeScheduleName: null,
          feeScheduleCharge: null,
          allowedAmount: null,
        }),
      );
    } catch (e) {
      console.error(e);
      store.dispatch(openError(ERROR_CHARGES_FETCH_MESSAGE));
      this.__settingsCharges = [];
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();

    this.__encounterService.disconnect();
  }

  async _updateEncounters(encounters, _count) {
    encounters = encounters.filter(enc => {
      enc.label = `(${formatEncounterNumber(enc)}) - ${enc.fullDate} ${
        enc.appointmentType
      }, ${enc.provider}`;

      if (getChartingPermissions()) {
        return enc;
      }

      return enc.signed === false;
    });

    if (this.model.fromCheckOut) {
      if (this.model.encounterIds[0]) {
        this.__selectedEncounter = encounters.find(
          x => x.id === this.model.encounterIds[0],
        );
      }
    } else if (this.model.encounterIds) {
      encounters = encounters.filter(enc =>
        this.model.encounterIds.includes(enc.id),
      );

      if (encounters.length === 1) {
        this.__selectedEncounter = encounters[0];
        await this.__getAuthorizationFromEncounter();
        this.__autoSelectEncounter = true;
      }
    }

    this.__initialEncounters = encounters;
    this.__filteredEncounters = encounters;
  }

  __getFeeSchedule() {
    if (this.__selectedEncounter) {
      return getFeeSchedule(
        this.model.patientId,
        this.__selectedEncounter.appointmentId,
      );
    }

    return null;
  }

  async __getAuthorizationFromEncounter() {
    const { appointment } = await getAppointmentById(
      this.__selectedEncounter.appointmentId,
    );

    this.__selectedAuthorization = null;

    if (appointment && appointment.caseId) {
      const patientCase = await fetchOne(
        appointment.patientId,
        appointment.caseId,
      );

      this.__selectedAuthorization =
        patientCase.patientAuthorizations.find(
          auth => auth.id === appointment.patientAuthorizationId,
        ) || null;
    }
  }

  __buildModifiers(c) {
    return {
      modifier_1: c.modifiers[0] || '',
      modifier_2: c.modifiers[1] || '',
      modifier_3: c.modifiers[2] || '',
      modifier_4: c.modifiers[3] || '',
    };
  }

  __setAuthorization() {
    return this.model.selectedAuthorization || null;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      this.__encounterService.update(this.model.patientId);

      this.__selectedAuthorization = this.__setAuthorization();
    }

    if (changedProps.has('__selectedEncounter') && this.__selectedEncounter) {
      this.__encounterDataService.update(this.__selectedEncounter.id, [
        ENCOUNTER,
        CHARGES,
        DIAGNOSES,
      ]);

      const feeSchedule = await this.__getFeeSchedule();

      this.__settingsCharges = mapSettingsCharges(
        this.__settingsCharges,
        feeSchedule,
      );
    }

    if (changedProps.has('__selectedEncounter') && !this.__selectedEncounter) {
      this.__selectedEncounterData = {
        items: [],
      };
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .encounter-spacer {
          width: 12px;
          height: 80px;
        }

        .encounter-menu-label {
          margin-left: 10px;
        }

        .icon-chevron {
          height: 12px;
          width: 12px;
          transform: rotate(90deg);
        }

        .main-header {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING};
        }

        .header {
          display: flex;
          align-items: center;
          padding: ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .description {
          padding: 0 ${CSS_SPACING};
        }

        .encounter-dropdown {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
          z-index: 2;
        }

        .button-add-charge {
          padding: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-encounter-charges
        id="${ELEMENTS.form.id}"
        confirmLabel="Post"
        saveBlockerLabel="Posting charge..."
        .model="${this.__selectedEncounterData}"
        .autoSelectEncounter="${this.__autoSelectEncounter}"
        .diagnosisItems="${this.__diagnosisItems}"
        .settingsCharges="${this.__settingsCharges}"
        .initialEncounters="${this.__initialEncounters}"
        .filteredEncounters="${this.__filteredEncounters}"
        .selectedEncounter="${this.__selectedEncounter}"
        .selectedAuthorization="${this.__selectedAuthorization}"
        .layout="${this.layout}"
        .onSelectEncounter="${this.handlers.selectEncounter}"
        .onFilterEncounter="${this.handlers.filterEncounter}"
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.changeForm}"
        .onSave="${this.handlers.save}"
      ></neb-form-encounter-charges>
    `;
  }
}

customElements.define(
  'neb-overlay-ledger-encounter-charge',
  NebOverlayLedgerEncounterCharge,
);
