import '../../../neb-lit-components/src/components/pdf-viewer/neb-pdf-viewer-controller';
import '../../../neb-material-design/src/components/neb-loading-spinner';
import '../../../../src/components/misc/neb-icon';
import './neb-image-viewer';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';
import mime from 'mime-types';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';
import { createObjectURL } from '../../../neb-utils/blobProcessor';

export const ID_NO_MIME = 'no-mime';
export const ID_NO_DOC_OVERLAY = 'no-doc-overlay';
export const ID_NO_DOCUMENT_ICON = 'no-document-icon';
export const ID_LOADING_MESSAGE = 'loading-message';
const PDF_MIME_TYPE = 'application/pdf';
const ID_DOCUMENT_VIEWER_CONTENT = 'document-viewer-content';
export const NO_DOCS_PATIENT = 'There are no documents for this patient.';
export const NO_DOCS_ENCOUNTER =
  'There are no documents associated to this encounter.';
export const ELEMENTS = {
  name: {
    id: 'name',
  },
  edit: {
    id: 'edit-document',
  },
  download: {
    id: 'download',
  },
  imageViewer: {
    id: 'image',
  },
  documentsInfoDetails: {
    id: 'documents-info-details',
  },
  loadingOverlay: {
    id: 'loading-overlay',
  },
  noDocumentMessage: {
    id: 'no-document-message',
  },
  pdf: {
    id: 'pdf',
  },
  fullScreen: {
    id: 'document-fullscreen-button',
  },
};
export class NebDocumentsInfo extends LitElement {
  static get properties() {
    return {
      __url: String,
      expanded: Boolean,
      noDocuments: Boolean,
      selectedDocument: Object,
      imageSrcSpec: Object,
      loadingImageSrcSpec: Boolean,
      showLocalLoading: Boolean,
      small: {
        type: Boolean,
        reflect: true,
      },
      medium: {
        type: Boolean,
        reflect: true,
      },
      isOnEncounterDocuments: Boolean,
    };
  }

  constructor() {
    super();

    this.__initHandlers();

    this.__initState();
  }

  __initState() {
    this.__url = null;
    this.expanded = false;
    this.noDocuments = false;
    this.selectedDocument = null;
    this.imageSrcSpec = null;
    this.loadingImageSrcSpec = false;
    this.isOnEncounterDocuments = false;

    this.onOpenPopupEdit = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      editClicked: () => this.onOpenPopupEdit(),
      getDocumentContentSize: () => this.__getDocumentContentSize(),
      fullscreenContent: () =>
        openPopup(POPUP_RENDER_KEYS.FULLSCREEN_CONTENT, {
          src: this.imageSrcSpec.src,
          name: this.__selectedDocument.name,
          note: this.__selectedDocument.note,
          mimeType: this.__imageSrcSpec ? this.__imageSrcSpec.mimeType : null,
        }),
    };
  }

  __getDocumentContentSize() {
    const content = this.shadowRoot.getElementById(ID_DOCUMENT_VIEWER_CONTENT);

    if (content && content.clientHeight && content.clientWidth) {
      return {
        width: content.clientWidth,
        height: content.clientHeight,
      };
    }

    return {
      width: null,
      height: null,
    };
  }

  reset() {
    const imageViewer = this.shadowRoot.getElementById(ELEMENTS.imageViewer.id);

    if (imageViewer) {
      imageViewer.identity();
    }
  }

  async updated(changedProps) {
    if (changedProps.has('imageSrcSpec')) {
      if (this.imageSrcSpec && this.imageSrcSpec.src) {
        this.reset();
        this.__url = await createObjectURL(this.imageSrcSpec.src);
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          min-width: 0;
          overflow-y: auto;
        }

        a:link,
        a:visited {
          text-decoration: underline;
        }

        .overlay-container {
          position: relative;
          height: 100%;
          width: 100%;
          min-width: 300px;
          min-height: 300px;
        }

        :host([small]) .overlay-container {
          min-height: 100vh;
        }

        .container {
          display: flex;
          height: 100%;
          width: 100%;
          flex-flow: column nowrap;
          overflow: auto;
          scrollbar-width: none;
        }

        .container::-webkit-scrollbar {
          width: 0;
        }

        .container::-webkit-scrollbar-track-piece {
          background-color: transparent;
          border-radius: 6px;
        }

        .container-center {
          align-items: center;
          justify-content: center;
        }

        .header-info {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: start;

          height: auto;
          top: 0;
          right: 0;
          left: 0;

          margin-top: 20px;
          padding: 0;

          white-space: nowrap;
        }

        .header-info-row {
          display: flex;
        }

        :host([small]) .header-info {
          margin: 20px 0 0 22px;
        }

        :host([small]) .header-info-row {
          height: auto;
          margin: 0;
          padding: 0;
          width: 100%;
          white-space: nowrap;
        }

        .body-info-pdf {
          position: absolute;
          top: 75px;
          bottom: 170px;
          right: 0;
          left: 0;
        }

        .body-info-image {
          position: absolute;
          right: 0;
          top: 75px;
          bottom: 10px;
          left: 0;
        }

        :host([small]) .body-info-image {
          top: 75px;
        }

        :host([small]) .body-info-pdf {
          top: 75px;
        }

        .footer-info-pdf {
          position: absolute;
          height: 170px;
          bottom: 0;
          right: 0;
          left: 0;
        }

        :host([small]) .footer-info-pdf {
          display: flex;
          flex-direction: column;
          padding: 0 22px;
        }

        .content {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .content[pdf] {
          width: 100%;
          height: 100%;
        }

        .row {
          display: flex;
          width: 100%;
          align-items: center;
        }

        .cell {
          min-width: 0;
          min-height: 0;
        }

        .cell-tabs:not(:last-child) {
          margin-right: 30px;
          margin-bottom: 5px;
        }

        .cell-tabs-edit {
          flex: 0 0 96px;
        }

        .cell-tabs-download {
          flex: 0 0 140px;
        }

        .cell-label {
          width: 120px;
        }

        .text {
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .text-highlight {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .text-title {
          width: 100%;

          padding: 0;
          margin: 0 20px 5px 0;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([small]) .text-title {
          align-items: center;
        }

        .button {
          cursor: pointer;
          display: flex;
          align-items: center;

          margin: 0;
          padding: 0;

          text-decoration: underline;
          font-size: 14px;
          border: none;
          outline: none;

          color: ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_WHITE};
        }

        .button-edit {
          margin-right: 20px;
        }

        .button-download {
          margin-right: 20px;
        }

        .text-header {
          cursor: pointer;
          border: none;
          outline: none;
          padding: 0;
          background-color: transparent;
          font-size: ${CSS_FONT_SIZE_BODY};
          text-decoration: none;
          white-space: nowrap;
          color: ${CSS_COLOR_HIGHLIGHT};
          border-bottom: 1px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          width: 15px;
          height: 15px;

          margin-right: 5px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-edit {
          width: 18px;
          height: 18px;
        }

        .icon-download {
          width: 22px;
          height: 22px;

          margin-top: 4px;
        }

        .icon-document {
          width: 50px;
          height: 50px;

          margin: 0 0 5px 0;
        }

        .pdf-container {
          height: 100%;
          width: 100%;
        }

        .loading-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
        }

        :host([small]) .loading-overlay {
          position: fixed;
        }

        .panel-right {
          margin: 0 ${CSS_SPACING} 0 50px;
          flex: 1 0 0;
          width: 100%;
        }
      `,
    ];
  }

  renderByMimeType(mimeType) {
    switch (mimeType) {
      case PDF_MIME_TYPE:
        return html`
          <neb-pdf-viewer-controller
            id="${ELEMENTS.pdf.id}"
            class="pdf-container"
            .src="${this.imageSrcSpec.src}"
            .small="${this.small}"
          ></neb-pdf-viewer-controller>
        `;

      case 'image/jpeg':
      case 'image/png':
        return html`
          <neb-image-viewer
            id="${ELEMENTS.imageViewer.id}"
            maxZoom="5"
            .src="${this.imageSrcSpec.src}"
            .small="${this.small}"
            .medium="${this.medium}"
            .onGetDocumentContentSize="${this.__handlers
              .getDocumentContentSize}"
            .model="${this.selectedDocument}"
          ></neb-image-viewer>
        `;

      default:
        return html`
          <div id="${ID_NO_MIME}" class="container container-center">
            No mimeType on document
          </div>
        `;
    }
  }

  renderDocInfo() {
    const info = this.__selectedDocument;
    const mimeType = this.__imageSrcSpec ? this.__imageSrcSpec.mimeType : null;
    const isPdf = mimeType === PDF_MIME_TYPE;
    return html`
      <div class="overlay-container">
        <div class="panel-right">
          <div class="header-info">
            <span id="${ELEMENTS.name.id}" class="text text-title"
              >${info.name}</span
            >
            <div class="header-info-row">
              <button
                id="${ELEMENTS.edit.id}"
                class="button-edit button"
                @click="${this.__handlers.editClicked}"
              >
                <neb-icon class="icon icon-edit" icon="neb:edit"></neb-icon>
                Edit
              </button>
              <a
                id="${ELEMENTS.download.id}"
                class="button-download button"
                href="${this.__url}"
                download="${info.name}.${mime.extension(mimeType)}"
              >
                <neb-icon
                  class="icon icon-download"
                  icon="neb:download"
                ></neb-icon>
                Download
              </a>

              <button
                id="${ELEMENTS.fullScreen.id}"
                class="button-fullscreen button"
                @click="${this.__handlers.fullscreenContent}"
              >
                <neb-icon
                  class="icon icon-expand"
                  icon="neb:fullscreen"
                ></neb-icon>
                Fullscreen
              </button>
            </div>
          </div>
          <div class="body-info-${isPdf ? 'pdf' : 'image'}">
            <div
              id="${ID_DOCUMENT_VIEWER_CONTENT}"
              class="content"
              ?pdf="${isPdf}"
            >
              ${(this.expanded || this.small) && mimeType
                ? this.renderByMimeType(mimeType)
                : ''}
            </div>
          </div>
          ${isPdf ? this.__renderFooter() : ''}
          ${this.renderLoadingOverlaySpinner()}
        </div>
      </div>
    `;
  }

  __renderFooter() {
    return html`
      <div class="footer-info-pdf">
        <neb-documents-info-details
          id="${ELEMENTS.documentsInfoDetails.id}"
          .model="${this.selectedDocument}"
        ></neb-documents-info-details>
      </div>
    `;
  }

  renderLoadingOverlaySpinner() {
    return html`
      ${this.loadingImageSrcSpec && this.showLocalLoading
        ? html`
            <div id="${ELEMENTS.loadingOverlay.id}" class="loading-overlay">
              <neb-loading-spinner></neb-loading-spinner>
              <div id="${ID_LOADING_MESSAGE}">Loading Document</div>
            </div>
          `
        : ''}
    `;
  }

  renderNoDocInfo() {
    return html`
      <div class="overlay-container">
        <div id="${ID_NO_DOC_OVERLAY}" class="container container-center">
          ${this.noDocuments
            ? html`
                <neb-icon
                  id="${ID_NO_DOCUMENT_ICON}"
                  class="icon icon-document"
                  icon="neb:noDocuments"
                ></neb-icon>
                <div
                  id="${ELEMENTS.noDocumentMessage.id}"
                  class="text text-highlight"
                >
                  ${this.isOnEncounterDocuments
                    ? NO_DOCS_ENCOUNTER
                    : NO_DOCS_PATIENT}
                </div>
              `
            : ''}
        </div>
        ${this.renderLoadingOverlaySpinner()}
      </div>
    `;
  }

  render() {
    return html`
      ${this.selectedDocument ? this.renderDocInfo() : this.renderNoDocInfo()}
    `;
  }
}
customElements.define('neb-documents-info', NebDocumentsInfo);
