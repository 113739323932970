import {
  OVERLAY_KEYS,
  openOverlay,
} from '../neb-lit-components/src/utils/overlay-constants';

export const APPOINTMENT_OVERLAY = 'neb-appointment-overlay';
export const PATIENT_OVERLAY = 'neb-patient-overlay';
export const CHARTING_AGENDA_OVERLAY = 'neb-charting-agenda-view-overlay';
export const ENCOUNTER_OVERLAY = 'neb-encounter-overlay';
export const PATIENT_LIST_OVERLAY = 'neb-patient-list-overlay';

export const openOverlayPatient = patient =>
  openOverlay(OVERLAY_KEYS.PATIENT, patient);

export const openOverlayPatientInsuranceView = params =>
  openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, params);

export const openOverlayPatientInsuranceEdit = params =>
  openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_EDIT, params);

export const openOverlayPatientInsuranceAddCoveredProcedure = params =>
  openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_ADD_COVERED_PROCEDURE, params);

export const openOverlayPatientPackageEdit = params =>
  openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, params);
