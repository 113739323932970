import DeprecatedApiClient, {
  Method,
  getTenantId,
} from '../../../packages/neb-api-client/src/utils/api-client-utils';

export const partnershipsApiClient = new DeprecatedApiClient({
  microservice: 'partner',
  useTenant: true,
});

export const BASE_URL = 'sso/v1/tenants';

export const getPartnerJwt = async () => {
  const tenantId = await getTenantId();

  return partnershipsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_URL}/${tenantId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
  });
};
