import '../../../neb-patient-panel';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../../../neb-styles/neb-variables';
import {
  getMatchingServiceTypes,
  isEmpty,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkPercentText: {
    id: 'in-network-percent-text',
  },
  inNetworkPercentAmount: {
    id: 'in-network-percent-amount',
  },
  outOfNetworkPercentText: {
    id: 'out-of-network-percent-text',
  },
  outOfNetworkPercentAmount: {
    id: 'out-of-network-percent-amount',
  },
  serviceTypesText: {
    id: 'service-types-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
  title: {
    id: 'title',
  },
};
const SERVICE_TYPES_HEADER = 'Service Types';
export const NO_CONTENT_TEXT = 'There are no coinsurances for this plan.';

class NebPatientInsuranceCoinsurancesCoverageDetails extends LitElement {
  static get properties() {
    return {
      coinsurance: {
        type: Object,
      },
      allServiceTypes: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.coinsurance = {};
    this.allServiceTypes = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .panel {
          border-style: solid;
          border-width: 1px;
          border-color: ${CSS_COLOR_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 10px 0 14px;
          height: 100%;
        }

        .coinsurance-container {
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .service-types-container {
          padding-bottom: ${CSS_SPACING};
        }

        .coinsurances-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .coinsurance-percent {
          padding-bottom: ${CSS_SPACING};
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .title {
          display: flex;
          padding-left: 10px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderInNetworkPercent(inNetworkPercent) {
    if (isEmpty(inNetworkPercent)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkPercentText.id}">
        <span class="bold">In Network %</span>
        <div
          id="${ELEMENTS.inNetworkPercentAmount.id}"
          class="coinsurance-percent"
        >
          ${inNetworkPercent}/${100 - inNetworkPercent}
        </div>
      </div>
    `;
  }

  __renderOutOfNetworkPercent(outOfNetworkPercent) {
    if (isEmpty(outOfNetworkPercent)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkPercentText.id}">
        <span class="bold">Out of Network %</span>
        <div
          id="${ELEMENTS.outOfNetworkPercentAmount.id}"
          class="coinsurance-percent"
        >
          ${outOfNetworkPercent}/${100 - outOfNetworkPercent}
        </div>
      </div>
    `;
  }

  __renderServiceTypes(serviceTypes) {
    if (!serviceTypes || serviceTypes.length === 0) return '';
    return html`
      <div class="bold">${SERVICE_TYPES_HEADER}</div>
      <div id="${ELEMENTS.serviceTypesText.id}">
        ${getMatchingServiceTypes(serviceTypes, this.allServiceTypes)}
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="coinsurance-container">
        <div class="coinsurances-row">
          <div class="flex-one">
            ${this.__renderInNetworkPercent(this.coinsurance.inNetworkPercent)}
            ${this.__renderOutOfNetworkPercent(
              this.coinsurance.outOfNetworkPercent,
            )}
          </div>
        </div>

        <div class="coinsurances-row">
          <div class="service-types-container">
            ${this.__renderServiceTypes(this.coinsurance.serviceTypes)}
          </div>
        </div>
      </div>
    `;
  }

  __renderNoContent() {
    return html`
      <div id="${ELEMENTS.noContentText.id}" class="no-content">
        ${NO_CONTENT_TEXT}
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderTitle(coinsurance) {
    const title = `Coinsurances${
      coinsurance.coverageLevel.code === 'NA'
        ? ''
        : ` - ${coinsurance.coverageLevel.description}`
    }`;

    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        ${title} ${this.__renderDefaultIndicator(coinsurance)}
      </div>
    `;
  }

  __renderCoinsurance() {
    return html`
      <div id="${ELEMENTS.coverageLevelText.id}" class="panel">
        ${this.__renderTitle(this.coinsurance)}
        ${this.coinsurance.coverageLevel.code === 'NA'
          ? this.__renderNoContent()
          : this.__renderContent()}
      </div>
    `;
  }

  render() {
    return html` ${this.__renderCoinsurance()} `;
  }
}

customElements.define(
  'neb-patient-insurance-coinsurances-coverage-details',
  NebPatientInsuranceCoinsurancesCoverageDetails,
);
