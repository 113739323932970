import { parseDate } from '../../packages/neb-utils/date-util';

export const mapToDocumentModel = document => {
  if (document == null) {
    return null;
  }

  const tags = document.documentTags
    ? document.documentTags.map(({ tag }) => ({
        label: tag.name,
        data: { id: tag.id, color: tag.color },
      }))
    : [];

  const model = {
    id: document.id,
    name: document.name,
    note: document.note,
    date: document.documentDate,
    file: null,
    uploadDate: document.uploadDate,
    thumbnail: document.img,
    tags,
  };

  return model;
};

export const getDefaultDocumentModel = () => ({
  id: null,
  name: '',
  note: '',
  date: parseDate().startOf('day'),
  file: null,
  uploadDate: new Date(),
  thumbnail: null,
  tags: [],
});
