import '../../../../neb-material-design/src/components/neb-md-select';
import '../inputs/neb-select';
import '../field-groups/neb-modifiers';
import '../neb-cell-fee-schedule';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { number } from '../../../../neb-utils/masks';

import NebTableOld, { ELEMENTS as ELEMENTS_BASE } from './neb-table-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  textNoDiagnoses: { id: 'no-diagnoses-text', text: 'No Encounter Dx' },
  modifiers: { selector: 'neb-modifiers' },
  units: { selector: '.units' },
  feeSchedule: { selector: '.fee-schedule' },
  diagnosisDropdown: { selector: '.diagnosis-dropdown' },
  mobileRows: { selector: 'neb-ledger-encounter-charges-mobile-row' },
};
class NebLedgerEncounterChargesTable extends NebTableOld {
  static get properties() {
    return {
      diagnoses: Array,
    };
  }

  initState() {
    super.initState();
    this.__config = this.__buildTableConfig();

    this.diagnoses = [];
    this.writable = true;
    this.showRemoveButton = true;
    this.lookupKey = 'chargeId';
  }

  __buildTableConfig() {
    return [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 85px`,
        mobile: true,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`1 0 0`,
        mobile: true,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: css`0 0 210px`,
      },
      {
        key: 'units',
        label: 'Units',
        flex: css`0 0 50px`,
      },
      {
        key: 'feeScheduleName',
        label: 'Fee Schedule',
        flex: css`0 0 120px`,
      },
      {
        key: 'diagnosisPointers',
        label: 'Diagnosis Pointer',
        flex: css`0 0 125px`,
      },
    ];
  }

  update(changedProps) {
    if (changedProps.has('layout')) {
      this.__config = this.__buildTableConfig();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_SPACING};
        }

        .description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        :host(:not([layout='small'])) .content {
          padding: 0 ${CSS_SPACING};
        }

        .row {
          align-items: center;
        }

        .cell {
          align-items: center;
          white-space: unset;
        }

        .units {
          --text-indent: 4px;
        }

        .text-no-items {
          padding: 10px ${CSS_SPACING};
        }
      `,
    ];
  }

  renderCell(value, key, rowIndex) {
    const name = `${rowIndex}.${key}`;
    const item = this.model[rowIndex];
    const error = this.errors[rowIndex][key];

    switch (key) {
      case 'procedure':
        return html` <div class="procedure" name="${name}">${value}</div> `;

      case 'description':
        return html` <div class="description" name="${name}">${value}</div> `;

      case 'modifiers':
        return html`
          <neb-modifiers
            label=" "
            helper=" "
            name="${name}"
            .errors="${error}"
            .values="${value}"
            .onChange="${this.handlers.change}"
            ?disabled="${!!this.model[rowIndex].id}"
          ></neb-modifiers>
        `;

      case 'units':
        return html`
          <neb-textfield
            class="units"
            name="${name}"
            helper=" "
            maxLength="3"
            label=" "
            .error="${error}"
            .value="${value}"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .onChange="${this.handlers.change}"
            ?disabled="${!!this.model[rowIndex].id}"
          ></neb-textfield>
        `;

      case 'feeScheduleName':
        return html`
          <neb-cell-fee-schedule
            class="fee-schedule"
            .model="${item}"
          ></neb-cell-fee-schedule>
        `;

      case 'diagnosisPointers':
        return this.diagnoses.length
          ? html`
              <neb-select
                class="diagnosis-dropdown"
                maxSelection="4"
                label=" "
                helper=" "
                .name="${name}"
                .error="${error}"
                .value="${value}"
                .items="${this.diagnoses}"
                .onChange="${this.handlers.change}"
                placeholder="Select"
                multiSelect
                showFullText
                ?disabled="${!!this.model[rowIndex].id}"
              ></neb-select>
            `
          : html`
              <span id="${ELEMENTS.textNoDiagnoses.id}"
                >${ELEMENTS.textNoDiagnoses.text}</span
              >
            `;

      default:
        return value;
    }
  }

  shouldEnableRemoveButton(item) {
    return !item.id;
  }
}
customElements.define(
  'neb-table-ledger-encounter-charges',
  NebLedgerEncounterChargesTable,
);
