import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/neb-action-bar';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { NebModifiers } from '../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../tables/claims-worklist/claim-charges/neb-table-modifiers-edit';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  table: {
    id: 'table',
  },
};

class NebFormModifiersEdit extends NebForm {
  static get properties() {
    return {
      isScrollable: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static createModel() {
    return { code: '', modifiers: ['', '', '', ''] };
  }

  initState() {
    super.initState();

    this.model = {
      code: '',
      modifiers: ['', '', '', ''],
    };

    this.isScrollable = false;
    this.actionBarHideBorderTop = true;
    this.alwaysRenderActionBar = true;

    this.onSave = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.formService.apply(name, value);
      },
      save: () => {
        const valid = this.formService.validate();

        if (valid) {
          this.onSave(this.state);
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        $: {
          children: {
            modifiers: NebModifiers.createSelectors({
              format: v => v || '',
              unformat: v => v || null,
            }),
          },
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          padding-bottom: 0px;
          background-color: white;
        }

        .content {
          overflow-y: unset;
          min-height: unset;
        }

        :host(:not([isScrollable])) {
        }

        :host([isScrollable]) {
          width: 360px;
          max-height: 400px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div>
        <neb-table-modifiers-edit
          .id="${ELEMENTS.table.id}"
          .model="${this.model}"
          .errors="${this.errors}"
          ?isScrollable="${this.isScrollable}"
          .onSave="${this.handlers.save}"
          .onChange="${this.handlers.change}"
        ></neb-table-modifiers-edit>
      </div>
    `;
  }
}

customElements.define('neb-form-modifiers-edit', NebFormModifiersEdit);
