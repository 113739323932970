import { EDIT_MODE } from '../../../../utils/neb-charges-util';
import { CLAIM_WARNINGS } from '../utils';

const getLineItemsToBeMerged = ({
  lineItems,
  claimReportId,
  serviceDate,
  procedureCode,
}) =>
  lineItems.filter(
    item =>
      !item.posted &&
      item.claimReportId === claimReportId &&
      item.serviceDate === serviceDate &&
      item.procedureCode === procedureCode,
  );

const checkClaimInUnmatched = statusMessage =>
  Array.isArray(statusMessage)
    ? statusMessage.includes(CLAIM_WARNINGS.UNMATCHED_PATIENT_CONTROL_NUMBER)
    : statusMessage === CLAIM_WARNINGS.UNMATCHED_PATIENT_CONTROL_NUMBER;

export default ({
  selectedItem: [{ statusMessage, serviceDate, procedureCode, isUpdating }],
  lineItems,
  editMode,
  isLineItemMatchInProgress,
  claimReportId,
}) => {
  if (isUpdating) return false;

  if (checkClaimInUnmatched(statusMessage)) {
    return false;
  }
  if (editMode === EDIT_MODE.MANUAL_POST) return false;
  if (isLineItemMatchInProgress) return false;

  const lineItemsToBeMerged = getLineItemsToBeMerged({
    lineItems,
    claimReportId,
    serviceDate,
    procedureCode,
  });
  return lineItemsToBeMerged.length > 1;
};
