import { isProductionEnvironment } from '../../packages/neb-utils/env';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../packages/neb-utils/feature-util';

import * as logDuplicateApiCalls from './duplicate-api-calls-log';

const { LOG_DUPLICATE } = logDuplicateApiCalls;

const shouldLogDuplicateApiCalls = async () => {
  if (isProductionEnvironment()) {
    return false;
  }

  const hasFeature = await hasFeatureOrBeta(
    FEATURE_FLAGS.LOG_DUPLICATE_API_CALLS,
  );

  return hasFeature;
};

export const initDuplicateApiCallsLog = async (
  logType = LOG_DUPLICATE.CONSOLE_LOG,
) => {
  try {
    const shouldLog = await shouldLogDuplicateApiCalls();

    if (shouldLog) {
      logDuplicateApiCalls.start();
      window.addEventListener('mousedown', () => {
        logDuplicateApiCalls.logApiCalls(logType);
      });
    }

    return shouldLog;
  } catch (err) {
    console.error(err);
  }
  return false;
};
