import { css, html } from 'lit';

import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../packages/neb-lit-components/src/components/neb-calendar-popover';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { currencyToCents } from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import { CSS_COLOR_HIGHLIGHT } from '../../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  editNoteIcon: { selector: '[id^=edit-note-icon-]' },
  totalsHeader: { id: 'totals-header' },
  totalsFooter: { id: 'totals-footer' },
  searchCalendarIcon: { id: 'search-calendar-icon' },
  calendarPopover: { id: 'calendar-popover' },
  blocker: { id: 'blocker' },
};

class NebTableRunningLedger extends NebTable {
  static get properties() {
    return {
      totals: {
        type: Object,
      },

      __selectedDate: String,
      __showCalendar: Boolean,
      __clickNote: Boolean,
    };
  }

  initState() {
    super.initState();
    this.totals = {};

    this.__selectedDate = null;
    this.__showCalendar = false;
    this.__clickNote = false;

    this.isDateSelectable = () => false;

    this.onSearchDate = () => {};

    this.onRefresh = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      openCalendar: () => {
        this.__showCalendar = true;
      },

      dateSelected: date => {
        this.__selectedDate = date;
        this.onSearchDate(this.__selectedDate);
        this.__showCalendar = false;
      },

      closeCalendar: () => {
        this.__showCalendar = false;
      },

      isDateSelectable: date => this.isDateSelectable(date),
      clickRow: e => {
        if (
          !this.writable &&
          this.mode !== MODE.NONE &&
          this.__clickNote !== true
        ) {
          const index = Number(e.currentTarget.getAttribute('row'));
          const item = this.model[index];

          if (this.isExpandable()) {
            this.onToggleExpand(
              this.name,
              item,
              index,
              !this.expandFlags[index],
            );
          } else {
            this.onSelectRow(this.name, item, index);
          }
        }

        if (!this.allowPropagation) e.stopPropagation();
      },
      openBillingNotesOverlay: async e => {
        let result;
        this.__clickNote = true;

        const type = e.currentTarget.getAttribute('type');
        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        const paymentType = e.currentTarget.getAttribute('paymentType');
        const row = this.model[rowIndex];

        if (type === BILLING_NOTE_TYPES.PAYMENT) {
          switch (paymentType) {
            case 'ERA':
            case 'EOB':
              result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
                parentType: type,
                parentId: row.paymentId,
                parentData: {
                  paymentType,
                  transactionDate: row.dateOfService,
                  amount: currencyToCents(row.credit),
                  payer: row.payer,
                },
                patientId: this.patientId,
              });

              break;

            case 'Discount':
              result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
                parentType: type,
                parentId: row.paymentId,
                parentData: {
                  paymentType,
                  transactionDate: row.dateOfService,
                  amount: currencyToCents(row.credit),
                  payer: row.payer,
                },
                patientId: this.patientId,
              });

              break;
            default:
              result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
                parentType: type,
                parentId: row.paymentId,
                parentData: {
                  transactionDate: row.dateOfService,
                  amount: currencyToCents(row.credit),
                  payer: row.payer,
                },
                patientId: this.patientId,
              });

              break;
          }
        } else if (type === BILLING_NOTE_TYPES.CHARGE) {
          result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
            parentType: BILLING_NOTE_TYPES.CHARGE,
            parentId: row.id,
            patientId: this.patientId,
            parentData: {
              dateOfService: row.dateOfService,
              code: row.code,
            },
          });
        }

        this.__clickNote = false;

        if (result) return this.onRefresh();

        return '';
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        #header {
          padding-top: ${CSS_SPACING};
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .totals {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .cell:not(:last-child) {
          margin-right: 10px;
        }

        .searchIcon {
          width: 16px;
          height: 16px;
          align-self: center;
        }

        .date-column-wrapper {
          position: relative;
          display: flex;
          align-items: flex-end;
          gap: 10px;
        }

        .sortable-date-column-wrapper {
          display: flex;
          align-items: flex-end;
          gap: 6px;
        }

        .blocker {
          position: fixed;
          inset: 0;
          background-color: transparent;
        }

        .calendar-popover {
          translate: -131px 20px;
          position: absolute;
          left: 0;
          top: 0;
        }

        @keyframes fade {
          from {
            background: rgb(192, 230, 255);
          }
          to {
            background: transparent;
          }
        }

        .item-highlight {
          animation: fade 3s;
        }

        .edit-note {
          cursor: pointer;
          width: 9px;
          height: 9px;
          scale: 2.2;
          margin-left: 2px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  __getModelTypeData(model) {
    if (model.isPayerPayment === true) {
      return {
        paymentType: model.code === 'Payment - ERA' ? 'ERA' : 'EOB',
        type: BILLING_NOTE_TYPES.PAYMENT,
      };
    }

    if (model.isDiscount === true) {
      return { paymentType: 'Discount', type: BILLING_NOTE_TYPES.PAYMENT };
    }

    if (model.debit) {
      return { paymentType: '', type: BILLING_NOTE_TYPES.CHARGE };
    }

    return { paymentType: '', type: BILLING_NOTE_TYPES.PAYMENT };
  }

  __getEditNoteIcon(_rowIndex) {
    const model = this.model[_rowIndex];
    const { paymentType, type } = this.__getModelTypeData(model);

    return this.__renderIcon(type, model, paymentType, _rowIndex);
  }

  __renderCellIcon(hasType, _rowIndex) {
    if (hasType) {
      return html`
        <neb-text> ${this.__getEditNoteIcon(_rowIndex)} </neb-text>
      `;
    }

    return '';
  }

  renderDataCell(value, columnConfig, _rowIndex, _name, _error) {
    let hasType = false;
    if (_name.split('.')[1] === 'hold') hasType = true;

    return html`
      <div style="display: flex;">
        <neb-text ?truncate="${columnConfig.truncate}">${value}</neb-text>
        ${this.__renderCellIcon(hasType, _rowIndex)}
      </div>
    `;
  }

  __renderIcon(type, model, paymentType, _rowIndex) {
    if (
      (type === BILLING_NOTE_TYPES.CHARGE && model.chargeHasNotes) ||
      (type === BILLING_NOTE_TYPES.PAYMENT && model.paymentHasNotes)
    ) {
      return html`
        <neb-icon
          id="edit-note-icon-${_rowIndex}"
          class="edit-note"
          icon="neb:editNote"
          type="${type}"
          rowIndex="${_rowIndex}"
          paymentType="${paymentType}"
          @click="${this.handlers.openBillingNotesOverlay}"
        ></neb-icon>
      `;
    }
    return '';
  }

  __renderTotals(id) {
    if (!this.config || !this.model.length) {
      return '';
    }

    const cells = this.config.map(
      cell => html`
        <span class="cell text-bold" style="flex: ${cell.flex}">
          ${this.totals[cell.key]}
        </span>
      `,
    );

    return html`
      <div id="${id}" class="totals">
        ${cells} <span class="cell cell-detail"></span>
      </div>
    `;
  }

  renderSubHeader() {
    return this.__renderTotals(ELEMENTS.totalsHeader.id);
  }

  __renderSearchIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.searchCalendarIcon.id}"
        class="searchIcon"
        icon="neb:search"
        @click="${this.handlers.openCalendar}"
      ></neb-icon
      >${this.__renderCalendarPopover()}
    `;
  }

  __renderCalendarPopover() {
    return this.__showCalendar
      ? html`
          <div
            id="${ELEMENTS.blocker.id}"
            class="blocker"
            @click="${this.handlers.closeCalendar}"
          ></div>
          <neb-calendar-popover
            id="${ELEMENTS.calendarPopover.id}"
            class="calendar-popover"
            .__selectedDate="${this.__selectedDate}"
            .onTodaySelected="${this.handlers.dateSelected}"
            .onDateSelected="${this.handlers.dateSelected}"
            .onClosePopover="${this.handlers.closeCalendar}"
            .isDateSelectable="${this.handlers.isDateSelectable}"
            .isPickerable="${true}"
            ?momentFlag="${true}"
          ></neb-calendar-popover>
        `
      : '';
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'dateOfService':
        return html`
          <div class="date-column-wrapper">
            <div
              class="sortable-date-column-wrapper"
              key="dateOfService"
              @click="${this.handlers.sort}"
            >
              ${super.renderHeaderCell(columnConfig)}
            </div>
            ${this.__renderSearchIcon()}
          </div>
        `;
      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  __renderConfigHeaderCells() {
    return this.config
      .filter(item => this.layout !== 'small' || item.mobile)
      .map(
        item => html`
          <div
            id="header-${item.key}"
            class="cell cell-header"
            style="flex: ${item.flex}"
            key="${item.key}"
            ?sortable="${item.sortable}"
            ?preview="${this.preview}"
          >
            ${this.renderHeaderCell(item)}
          </div>
        `,
      );
  }

  render() {
    return html`
      ${super.render()}${this.__renderTotals(ELEMENTS.totalsFooter.id)}
    `;
  }
}

customElements.define('neb-table-running-ledger', NebTableRunningLedger);
