import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { signedInteger } from '../../neb-utils/masks';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  offsetTop: { id: 'offset-top' },
  offsetLeft: { id: 'offset-left' },
  boldCheckbox: { id: 'bold-checkbox' },
};

class NebPopupClaimPrintSettings extends NebPopupClaim {
  static get properties() {
    return {
      __maxLength: Number,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .text-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            'a .'
            'b .'
            'c .';
        }

        .top {
          grid-area: a;
        }

        .left {
          grid-area: b;
        }

        .checkbox {
          grid-area: c;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__maxLength = 2;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__maxLength = value.startsWith('-') ? 3 : 2;
        this.__state = { ...this.__state, [name]: value };
      },

      changeBold: () => {
        const { bold } = this.__state;

        this.__state = { ...this.__state, bold: !bold };
      },

      confirm: () => {
        const validInput = /^-?[1-9]\d*$/;

        if (!validInput.test(this.__state.top)) {
          this.__state = { ...this.__state, top: '0' };
        }

        if (!validInput.test(this.__state.left)) {
          this.__state = { ...this.__state, left: '0' };
        }

        this.onClose(this.__state);
      },
    };
  }

  __renderBoldCheckbox() {
    return this.__state.bold !== undefined
      ? html`
          <neb-checkbox
            id="${ELEMENTS.boldCheckbox.id}"
            class="checkbox"
            label="Bold Text"
            ?checked="${this.__state.bold}"
            .onChange="${this.handlers.changeBold}"
          ></neb-checkbox>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="text-container">
        <neb-textfield
          id="${ELEMENTS.offsetTop.id}"
          class="text-input top"
          helper=" "
          label="Top Offset"
          name="top"
          maxLength="${this.__maxLength}"
          .mask="${signedInteger}"
          .inputMode="${'numeric'}"
          .value="${this.__state.top}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
        <neb-textfield
          id="${ELEMENTS.offsetLeft.id}"
          class="text-input left"
          helper=" "
          label="Left Offset"
          name="left"
          maxLength="${this.__maxLength}"
          .mask="${signedInteger}"
          .inputMode="${'numeric'}"
          .value="${this.__state.left}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
        ${this.__renderBoldCheckbox()}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-print-settings',
  NebPopupClaimPrintSettings,
);
