import '../../../packages/neb-lit-components/src/components/neb-tabs-old';
import '../../../packages/neb-lit-components/src/components/neb-tab-old';
import '../../../packages/neb-patient/src/components/documents/neb-patient-documents-controller';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
} from '../../../packages/neb-styles/neb-variables';

const LABEL_ENCOUNTER_DOCUMENTS = 'Encounter Documents';
const LABEL_PATIENT_DOCUMENTS = 'All Documents';
export const ELEMENTS = {
  documentsTabs: {
    id: 'documents-tabs',
  },
  encounterDocumentsTab: {
    id: 'encounter-documents',
  },
  patientDocumentsTab: {
    id: 'patient-documents',
  },
};

export class NebTabsDocuments extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
      },
      documentCount: {
        type: Number,
        reflect: true,
      },
      encounterDocumentCount: {
        type: Number,
        reflect: true,
      },
      selectedTab: {
        type: String,
      },
      firstTab: {
        type: String,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      .tabs {
        padding: 10px ${CSS_SPACING} 0 ${CSS_SPACING};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        background-color: ${CSS_COLOR_WHITE};
        flex: 0 0 auto;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  get elements() {
    return {
      encounterDocumentsTab: this.shadowRoot.getElementById(
        ELEMENTS.encounterDocumentsTab.id,
      ),
      documentsTabs: this.shadowRoot.getElementById(ELEMENTS.documentsTabs.id),
    };
  }

  __initState() {
    this.selectedTab = '';
    this.layout = '';
    this.documentCount = 0;
    this.encounterDocumentCount = 0;
    this.firstTab = ELEMENTS.encounterDocumentsTab.id;

    this.onUpdateSelectedTab = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        this.onUpdateSelectedTab(tab);
      },
    };
  }

  __getTabLabel(label, count) {
    return `${label} (${count})`;
  }

  __renderEncounterDocumentsTab() {
    return html`
      <neb-tab-old
        id="${ELEMENTS.encounterDocumentsTab.id}"
        name="encounter-documents"
        >${this.__getTabLabel(
          LABEL_ENCOUNTER_DOCUMENTS,
          this.encounterDocumentCount,
        )}</neb-tab-old
      >
    `;
  }

  __renderPatientDocumentsTab() {
    return html`
      <neb-tab-old
        id="${ELEMENTS.patientDocumentsTab.id}"
        name="patient-documents"
        >${this.__getTabLabel(
          LABEL_PATIENT_DOCUMENTS,
          this.documentCount,
        )}</neb-tab-old
      >
    `;
  }

  __renderDocumentTabs() {
    if (this.firstTab === ELEMENTS.patientDocumentsTab.id) {
      return html`
        ${this.__renderPatientDocumentsTab()}
        ${this.__renderEncounterDocumentsTab()}
      `;
    }

    return html`
      ${this.__renderEncounterDocumentsTab()}
      ${this.__renderPatientDocumentsTab()}
    `;
  }

  __renderTabs() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.documentsTabs.id}"
        class="tabs"
        .selected="${this.selectedTab}"
        .onChange="${this.__handlers.selectTab}"
      >
        ${this.__renderDocumentTabs()}
      </neb-tabs-old>
    `;
  }

  render() {
    return html` <div class="container">${this.__renderTabs()}</div> `;
  }
}
window.customElements.define('neb-tabs-documents', NebTabsDocuments);
