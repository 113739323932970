const WHITE_LIST = ['Matrix', 'OpenQuestion', 'MultipleChoice'];

function splitTableAnswer(answer) {
  if (answer && answer.includes('\n')) {
    return answer.split('\n');
  }

  return answer || '';
}

function applyMatrixFormatting(question) {
  if (question.ColumnNames.length === 0) return [];

  const columnNames = [
    '',
    ...question.ColumnNames.filter(
      (_, index) => question.ColumnTypes[index] !== 'Label',
    ),
  ];

  const columnTypes = [
    'Stub',
    ...question.ColumnTypes.filter(type => type !== 'Label'),
  ];

  if (columnNames.length === 1) return [];

  const totalsRow = ['Totals', ...Array(columnNames.length - 1).fill('')];

  const answer = [
    columnNames,
    ...question.Rows.map(row => {
      const rowAnswers = ['', ...row.Answers];

      return columnNames.map((col, idx) => {
        switch (columnTypes[idx]) {
          case 'Stub':
            return row.Text;

          case 'Numeric':
            totalsRow[idx] = +totalsRow[idx] + +rowAnswers[idx];
            break;

          case 'Checkboxes':
          case 'Radioboxes':
            if (rowAnswers.includes(col)) {
              return 'X';
            }
            rowAnswers.splice(idx, 0, '');
            return '';
          default:
            break;
        }

        return splitTableAnswer(rowAnswers[idx]);
      });
    }),
  ];

  if (columnTypes.includes('Numeric')) {
    answer.push(totalsRow);
  }

  if (question.Answer && !Number.isNaN(question.Answer)) {
    answer.push(`Score: ${question.Answer}`);
  }

  return answer;
}

function applySplit(answer, questionType) {
  const splitChar = questionType === 'OpenQuestion' ? '\n' : ',';

  return typeof answer === 'string' && answer.includes(splitChar)
    ? answer.split(splitChar)
    : answer;
}

function applyNote(answer, question) {
  if (question.QuestionType !== 'Matrix') {
    answer = Array.isArray(answer) ? answer : [answer];
    answer.push('');
  }

  if (question.OfficeNote) {
    answer.push(`Office Note: ${question.OfficeNote}`);
  }

  if (question.FurtherExplanation) {
    if (question.QuestionType === 'MultipleChoice') {
      const lastAnswer = answer.findIndex(a =>
        a.includes(`(${question.FurtherExplanation})`),
      );

      if (lastAnswer > -1) {
        answer[lastAnswer] = answer[lastAnswer].replace(
          `(${question.FurtherExplanation})`,
          '',
        );
      }
    }

    answer.push(`Further Explanation: ${question.FurtherExplanation}`);
  }

  return answer;
}

export function parse(documentData, excludeEmpty) {
  const data = JSON.parse(documentData).Questions;
  const items = data
    .filter(question => {
      const isAnsweredQuestion =
        question.Answer && question.QuestionType !== 'Matrix';

      const isAnsweredMatrix =
        question.Rows && question.Rows.some(row => row.Answers.length > 0);

      return (
        WHITE_LIST.includes(question.QuestionType) &&
        (!excludeEmpty || isAnsweredQuestion || isAnsweredMatrix)
      );
    })
    .map(question => {
      let parsedAnswer =
        question.QuestionType === 'Matrix'
          ? applyMatrixFormatting(question)
          : applySplit(question.Answer, question.QuestionType);

      if (question.FurtherExplanation || question.OfficeNote) {
        parsedAnswer = applyNote(parsedAnswer, question);
      }

      return {
        question: question.Text || '',
        answer: parsedAnswer || '',
      };
    });

  return items;
}
