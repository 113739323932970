import '../controls/neb-button-action';
import '../controls/neb-switch';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-select';
import '../inputs/neb-select-search';
import '../inputs/neb-textarea';
import '../neb-action-bar';
import '../patients/neb-patient-card';
import './neb-guarantor-summary';
import './neb-organization-card';

import { LitElement, html, css } from 'lit';

import { formatPersonForPatientCard } from '../../../../neb-api-client/src/formatters/person';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

import { INITIAL_GUARANTOR_FORM } from './guarantor-util';

export const ELEMENTS = {
  relation: {
    id: 'select-relation',
  },
  search: {
    id: 'search-bar',
  },
  addButton: {
    id: 'add-button',
  },
  default: {
    id: 'default-checkbox',
  },
  active: {
    id: 'active-switch',
  },
  note: {
    id: 'note',
  },
  actionBar: {
    id: 'action-bar',
  },
  summary: {
    id: 'guarantor-summary',
  },
};

export const LABEL_ORGANIZATION_BUTTON = 'Create New Organization';
const LABEL_PATIENT_SEARCH = 'Search for an existing patient or guarantor';
export const LABEL_ORGANIZATION_SEARCH = 'Search for an existing organization';
export const SELECT_BY_TYPE = {
  PERSON: 'person',
  ORGANIZATION: 'organization',
};
export const ITEMS_RELATION = [
  'Spouse',
  'Dependent',
  'Parent',
  'Caregiver',
  'Other',
];

const LABEL_PATIENT_BUTTON = 'Create New Guarantor Record';

class NebGuarantorForm extends LitElement {
  static get properties() {
    return {
      dirty: {
        type: Boolean,
      },
      showResults: {
        type: Boolean,
      },
      model: {
        type: Object,
      },
      type: {
        type: String,
      },
      saving: {
        type: Boolean,
      },
      searchValue: {
        type: String,
      },
      searchResults: {
        type: Array,
      },
      selectedGuarantor: {
        type: Object,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      .form-container {
        flex: 1 0 0;
        flex-direction: column;
        min-height: 0;
        overflow: auto;
      }

      .container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .search-container {
        padding: 0 ${CSS_SPACING} 0 ${CSS_SPACING};
      }

      .relation-container,
      .radio-buttons-container {
        display: flex;
      }

      .select {
        flex: 3 0 0;
      }

      neb-radio-button {
        margin-top: ${CSS_SPACING};
      }

      .info-container {
        padding: 0 ${CSS_SPACING} ${CSS_SPACING};
        display: grid;
        grid-template-columns: 1fr;
      }

      .summary {
        display: grid;
        grid-gap: ${CSS_SPACING};
        margin-top: 15px;
      }

      .checkbox {
        margin-left: -10px; // needed for mdc
      }

      .summary,
      .note,
      .checkbox,
      .switch {
        margin-bottom: ${CSS_SPACING};
      }

      .note {
        height: 170px;
        width: 100%;
      }

      .add-button {
        padding: 20px ${CSS_SPACING};
      }

      .action-bar {
        width: 100%;
        bottom: 0;
      }

      .search {
        display: flex;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.configByType = {
      person: {
        buttonLabel: LABEL_PATIENT_BUTTON,
        searchLabel: LABEL_PATIENT_SEARCH,
        renderItem: this.renderItem,
      },
      organization: {
        buttonLabel: LABEL_ORGANIZATION_BUTTON,
        searchLabel: LABEL_ORGANIZATION_SEARCH,
        renderItem: this.renderOrgItem,
      },
    };

    this.model = INITIAL_GUARANTOR_FORM;
    this.dirty = false;
    this.saving = false;
    this.showResults = true;
    this.type = 'person';
    this.searchValue = '';
    this.searchResults = [];
    this.selectedGuarantor = null;

    this.onChange = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onEdit = () => {};

    this.onSearch = () => {};

    this.onAddGuarantor = () => {};

    this.onChangeDefault = () => {};

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => this.onSave(),
      cancel: () => this.onCancel(),
      selectGuarantor: e => this.onSelect(e.value),
      addGuarantor: () => this.onAddGuarantor(this.type),
      edit: () => this.__editGuarantor(),
      changeNote: e => this.__commitChange('note', e.value),
      changeRelation: e => this.__commitChange('relation', e.value),
      changeDefault: e =>
        this.onChangeDefault({ ...this.model, default: e.value }),
      changeActive: ({ value }) => {
        if (!value) {
          this.onChange({ ...this.model, active: value, default: value });
        } else {
          this.__commitChange('active', value);
        }
      },
    };
  }

  __editGuarantor() {
    const type = this.model.personId ? 'person' : 'organization';
    this.onEdit(type);
  }

  __commitChange(key, value) {
    this.onChange({ ...this.model, [key]: value });
  }

  get __isGuarantorSelected() {
    const { personId, organizationId } = this.model;

    return organizationId || personId;
  }

  renderItem(model) {
    const patientModel = formatPersonForPatientCard(model);

    return html`
      <neb-patient-card
        class="list-item"
        .model="${patientModel}"
        hidePhoto
      ></neb-patient-card>
    `;
  }

  renderOrgItem(model) {
    return html`
      <neb-organization-card .model="${model}"></neb-organization-card>
    `;
  }

  __renderSearch() {
    return !this.model.id
      ? html`
          <div class="search-container">
            <div class="relation-container">
              <neb-select
                id="${ELEMENTS.relation.id}"
                class="select"
                helper="Required"
                label="Relation to Patient"
                .error="${!this.model.relation ? 'Required' : ''}"
                .items="${ITEMS_RELATION}"
                .value="${this.model.relation}"
                .onChange="${this.__handlers.changeRelation}"
                ?disabled="${this.type === 'organization'}"
              ></neb-select>
            </div>

            <neb-select-search
              id="${ELEMENTS.search.id}"
              class="search"
              emptyMessage="No guarantors found"
              itemHeight="84"
              .showMenu="${this.showResults}"
              .label="${this.configByType[this.type].searchLabel}"
              .value="${this.selectedGuarantor}"
              .search="${this.searchValue}"
              .items="${this.searchResults}"
              .onSearch="${this.onSearch}"
              .onChange="${this.__handlers.selectGuarantor}"
              .onRenderItem="${this.configByType[this.type].renderItem}"
              showSearch
            ></neb-select-search>
          </div>

          <neb-button-action
            id="${ELEMENTS.addButton.id}"
            class="add-button"
            .label="${this.configByType[this.type].buttonLabel}"
            .onClick="${this.__handlers.addGuarantor}"
          ></neb-button-action>
        `
      : '';
  }

  __renderGuarantorInfo() {
    const { personId, person, organization } = this.model;
    const model = personId ? person : organization;

    return html`
      <div class="info-container">
        <neb-guarantor-summary
          id="${ELEMENTS.summary.id}"
          class="summary"
          .model="${model}"
          .onEdit="${this.__handlers.edit}"
        ></neb-guarantor-summary>

        <neb-textarea
          id="${ELEMENTS.note.id}"
          class="note"
          label="Notes"
          maxLength="500"
          .value="${this.model.note}"
          .onChange="${this.__handlers.changeNote}"
          showCount
        ></neb-textarea>

        <neb-checkbox
          id="${ELEMENTS.default.id}"
          class="checkbox"
          label="Default Guarantor"
          .onChange="${this.__handlers.changeDefault}"
          ?checked="${this.model.default}"
          ?disabled="${!this.model.active}"
        ></neb-checkbox>

        <neb-switch
          id="${ELEMENTS.active.id}"
          class="switch"
          label="Active"
          .onChange="${this.__handlers.changeActive}"
          ?on="${this.model.active}"
        ></neb-switch>
      </div>
    `;
  }

  __renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="Save"
        .onConfirm="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
        ?processing="${this.saving}"
      ></neb-action-bar>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="form-container">
          ${this.__renderSearch()}
          ${this.__isGuarantorSelected ? this.__renderGuarantorInfo() : ''}
        </div>
        ${this.dirty ? this.__renderActionBar() : ''}
      </div>
    `;
  }
}

window.customElements.define('neb-guarantor-form', NebGuarantorForm);
