import { css, html } from 'lit';

import { formatGuarantors } from '../../../../neb-api-client/src/formatters/guarantor';
import { getPatientGuarantors } from '../../../../neb-api-client/src/patient-guarantor-api-client';
import { formatGuarantorName } from '../../../../neb-lit-components/src/components/guarantor/guarantor-util';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../../neb-lit-components/src/components/neb-table-header';
import { OVERLAY_KEYS } from '../../../../neb-lit-components/src/utils/overlay-constants';
import { formatDollarAmount } from '../../../../neb-utils/formatters';

export const ELEMENTS = { ...BASE_ELEMENTS };
export const NO_ITEMS_TEXT =
  'There are no additional guarantors for this patient.';
const TABLE_CONFIG = [
  {
    sortable: true,
    truncate: true,
    key: 'name',
    label: 'Name',
    flex: css`1 0 0`,
    formatter: (_, guarantor) => formatGuarantorName(guarantor),
  },
  {
    sortable: true,
    truncate: true,
    key: 'relation',
    label: 'Relation',
    flex: css`1 0 0`,
  },
  {
    truncate: true,
    label: 'Phone Number',
    flex: css`1 0 0`,
    formatter: (_, { person, organization }) => {
      const guarantor = person || organization;
      if (!guarantor.phones) return '';

      return guarantor.phones.map(({ type, number }) =>
        number ? html` ${type}: ${number}<br /> ` : '',
      );
    },
  },
  {
    sortable: true,
    truncate: true,
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 0`,
    formatter: v => formatDollarAmount(v || 0),
  },
  {
    truncate: true,
    key: 'patientCases',
    label: 'Cases',
    flex: css`1 0 0`,
    formatter: v => {
      const activeCases = v.filter(c => c.active);

      return activeCases.reduce(
        (memo, { name }, idx) => html`
          ${memo}${name}${idx < activeCases.length - 1 ? ',' : ''}<br />
        `,
        '',
      );
    },
  },
  {
    key: 'default',
    label: 'Default',
    flex: css`0 0 80px`,
    formatter: v => (v ? 'Yes' : ''),
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`0 0 80px`,
    formatter: v => (v ? 'Active' : 'Inactive'),
  },
];

class NebGuarantorCollectionPage extends CollectionPage {
  static get properties() {
    return {
      patientForm: {
        type: Boolean,
      },
      patientId: {
        type: String,
      },
    };
  }

  static get config() {
    return {
      unifyForm: true,
      singularName: 'Patient Guarantor',
      pluralName: '',
      initialSortKey: 'name',
      initialSortOrder: SORT_DIR.ASC,
      overlayKey: OVERLAY_KEYS.GUARANTOR,
      tableConfig: TABLE_CONFIG,
    };
  }

  initState() {
    super.initState();
    this.patientForm = false;
    this.patientId = '';

    this.onGuarantorChange = () => {};
  }

  updated(changedProps) {
    if (changedProps.has('patientId')) {
      this.changeOccurred({ hideInactive: true });
    }
  }

  async fetch() {
    let guarantors = await getPatientGuarantors(this.patientId, {
      includeBalance: true,
    });

    guarantors = guarantors.filter(g => g.id);
    this.service.setPageSize(guarantors.length + 1);

    return formatGuarantors(guarantors);
  }

  buildContext() {
    return {
      patientId: this.patientId,
    };
  }

  renderNoItemsContent() {
    return NO_ITEMS_TEXT;
  }

  async changeOccurred({ hideInactive }) {
    this.service.setItems(await this.fetch());
    this.onGuarantorChange(hideInactive);
  }

  sort(a, b, key) {
    if (a.default || b.default) return 0;

    return this.__handleSort(a, b, key);
  }

  __handleSort(a, b, key) {
    if (key === 'name') return this.__sortByName(a, b);
    if (key === 'balance') return this.__sortByBalance(a, b, key);

    return super.sort(a, b, key);
  }

  __sortByName(a, b) {
    const aName = formatGuarantorName(a);
    const bName = formatGuarantorName(b);

    return this.compare(aName, bName);
  }

  __sortByBalance(a, b) {
    if (a.balance !== b.balance) return a.balance < b.balance ? -1 : 1;

    return 0;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: calc(100% - 102px);
        }

        .row {
          padding-top: 0;
        }
      `,
    ];
  }
}

customElements.define(
  'neb-guarantor-collection-page',
  NebGuarantorCollectionPage,
);
