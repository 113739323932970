import { css, html } from 'lit';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import { getConversionSubJobs } from '../../../api-clients/conversions';
import '../../pages/conversions/neb-conversion-job-page';

export const ELEMENTS = {
  header: { id: 'header' },
  page: { id: 'page' },
};

class NebOverlayConversionJob extends Overlay {
  static get properties() {
    return {
      ...Overlay.properties,
      fetchingSubJobs: Boolean,
      subJobs: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          flex-flow: column nowrap;
          overflow: auto;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.fetchingSubJobs = false;
    this.subJobs = [];
  }

  async __fetchSubJobs() {
    this.fetchingSubJobs = true;

    const {
      item: {
        conversion: { tenantId },
        job,
      },
    } = this.model;
    const { data: subJobs } = await getConversionSubJobs({ ...job, tenantId });

    this.subJobs = subJobs;

    this.fetchingSubJobs = false;
  }

  getTitle() {
    return 'Conversion Job';
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showBackButton
        showCancelButton
      ></neb-popup-header>

      <neb-conversion-job-page
        id="${ELEMENTS.page.id}"
        .fetchingSubJobs="${this.fetchingSubJobs}"
        .model="${this.model.item}"
        .subJobs="${this.subJobs}"
      ></neb-conversion-job-page>
    `;
  }

  firstUpdated() {
    super.firstUpdated();
    this.__fetchSubJobs();
  }
}

customElements.define('neb-overlay-conversion-job', NebOverlayConversionJob);
