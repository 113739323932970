import '../neb-selection-card';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';

import { getOrganizations } from '../../../../neb-api-client/src/organizations';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../../neb-api-client/src/patient-image-api-client';
import { GuarantorService } from '../../../../neb-api-client/src/services/guarantor';
import { PersonService } from '../../../../neb-api-client/src/services/person';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import Debouncer from '../../../../neb-utils/debouncer';
import { mapPatientToPerson } from '../../../../neb-utils/patient';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import {
  INITIAL_GUARANTOR_FORM,
  addLabelToPerson,
  addLabelToOrg,
} from './guarantor-util';
import { SELECT_BY_TYPE } from './neb-guarantor-form';

const toNumeric = str => str.replace(/\D/g, '');

const removeSpecialCharacters = str => str.replace(/[()-/]/g, '');

const narrativePersonCard = html`
  <p>
    Add a new guarantor for the patient by
    <b>creating a new person or searching for an existing patient or person</b>.
  </p>
`;
const narrativeOrganizationCard = html`
  <p>
    Add a new guarantor for the patient by
    <b>creating a new organization or searching for an existing organization</b
    >.
  </p>
`;
export const ELEMENTS = {
  form: {
    id: 'form',
  },
  personCard: {
    id: 'person-card',
  },
  organizationCard: {
    id: 'organization-card',
  },
};
export const DEFAULT_GUARANTOR = {
  title: 'Default Guarantor',
  message:
    'This will now be the default guarantor when creating charges. All active guarantors, including the previous default, will be available to select if needed. Do you want to proceed?',
  confirmText: 'Yes',
  cancelText: 'No',
};
const GUARANTOR_TYPES_HANDLER = {
  [SELECT_BY_TYPE.PERSON]: {
    overlay: OVERLAY_KEYS.PERSON,
    model: 'person',
    label: addLabelToPerson,
  },
  [SELECT_BY_TYPE.ORGANIZATION]: {
    overlay: OVERLAY_KEYS.ORGANIZATION_FORM,
    model: 'organization',
    label: addLabelToOrg,
  },
};
const CARDS_CONFIG = [
  {
    id: ELEMENTS.personCard.id,
    title: 'Add Person',
    content: narrativePersonCard,
    icon: 'patients',
    handler: 'addPersonGuarantor',
  },
  {
    id: ELEMENTS.organizationCard.id,
    title: 'Add Organization',
    content: narrativeOrganizationCard,
    icon: 'organization',
    handler: 'addOrganizationGuarantor',
  },
];

const mapAddress = rawAddress => {
  if (typeof rawAddress.address1 === 'object') {
    return {
      address1: rawAddress.address1.value,
      address2: rawAddress.address2.value,
      city: rawAddress.city.value,
      state: rawAddress.state.value,
      zipcode: rawAddress.zipcode.value,
    };
  }

  return rawAddress;
};

const mapDateOfBirth = dateOfBirth => dateOfBirth || '';

const mapToModel = raw => {
  const { address } = raw;

  return {
    address: mapAddress(address),
    dateOfBirth: mapDateOfBirth(raw.dateOfBirth),
    emails: raw.emails,
    employmentStatus: raw.employmentStatus || '',
    id: raw.id,
    phones: raw.phones,
    name: {
      first: raw.firstName || '',
      last: raw.lastName || '',
      middle: raw.middleName || '',
      preferred: raw.preferredName || '',
      suffix: raw.suffix || '',
    },
    sex: raw.sex,
  };
};

class NebGuarantorFormController extends LitElement {
  static get properties() {
    return {
      __saving: Boolean,
      __showResults: Boolean,
      __search: String,
      __model: Object,
      __selectedGuarantor: Object,
      __searchResults: Array,
      selection: String,
      guarantor: Object,
      dirty: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
      }

      .card {
        margin-bottom: ${CSS_SPACING};
      }

      .content-padding {
        padding: ${CSS_SPACING} ${CSS_SPACING} 0;
      }
    `;
  }

  __initState() {
    this.dirty = false;
    this.selection = '';
    this.searchValue = '';
    this.__saving = false;
    this.__showResults = false;
    this.__search = '';
    this.__selectedGuarantor = null;
    this.__model = INITIAL_GUARANTOR_FORM;
    this.__searchResults = [];
    this.__searchDebouncer = new Debouncer(() =>
      this.__personService.search(this.__search, this.__model.patientId),
    );

    this.__patientGuarantorService = new GuarantorService(guarantor => {
      this.__saving = false;

      if (guarantor) {
        this.onDirtyChange(false);
        this.onSave(guarantor);
      }
    });

    this.onCancel = () => {};

    this.onSave = () => {};

    this.onDirtyChange = () => {};

    this.onGuarantorEdit = () => {};

    this.onTypeSelection = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => this.__saveGuarantorForm(),
      cancel: () => this.onCancel(),
      addGuarantor: type => this.__addGuarantor(type),
      changeModel: newModel => {
        this.__model = { ...newModel };

        this.onDirtyChange(this.isDirty());
      },
      changeDefault: async newModel => {
        const defaultGuarantor =
          newModel.default &&
          !!(await openPopup(POPUP_RENDER_KEYS.CONFIRM, DEFAULT_GUARANTOR));
        this.__model = { ...newModel, default: defaultGuarantor };

        this.onDirtyChange(this.isDirty());
      },
      search: e => {
        this.__search = e.value;

        if (this.selection === 'person') {
          this.__searchDebouncer.debounce();

          if (!this.__search) {
            this.__showResults = false;
          }
        } else {
          this.__searchOrganizations();
        }
      },
      select: value => {
        this.__selectedGuarantor = value;

        if (this.__selectedGuarantor) {
          this.__model = {
            ...this.__model,
            [this.selection]: value,
            [`${this.selection}Id`]: value.id,
          };

          this.onDirtyChange(this.isDirty());
        } else {
          this.__reset();
        }
      },
      edit: async type => {
        const handler = GUARANTOR_TYPES_HANDLER[type];
        const guarantor = await this.__openEditOverlay(handler);

        if (guarantor) {
          this.__model = {
            ...this.__model,
            ...{
              [`${handler.model}Id`]: guarantor.id,
              [handler.model]: guarantor,
            },
          };

          if (this.__model.id) {
            this.onGuarantorEdit({ ...this.__model });
          }

          this.__afterGuarantorOverlayClosed(handler.label(guarantor));
        }
      },
      addPersonGuarantor: () => {
        this.selection = 'person';
        this.__personService = new PersonService(data => {
          this.__showResults = Boolean(this.__search);
          this.__searchResults = data.map(person => addLabelToPerson(person));
        });

        this.onTypeSelection(this.selection);
      },
      addOrganizationGuarantor: () => {
        this.selection = 'organization';
        this.__showResults = true;

        this.__fetchOrganizations();

        this.onTypeSelection(this.selection);
      },
    };
  }

  __searchOrganizations() {
    const queryTerms = this.__search.trim().toLowerCase().split(' ');

    this.__searchResults = this.__organizations.filter(item => {
      const phoneItems = item.phones.map(phone => toNumeric(phone.number));
      const search = [item.name, ...item.emails, ...phoneItems]
        .join(' ')
        .toLowerCase();

      return queryTerms.every(term =>
        search.includes(removeSpecialCharacters(term)),
      );
    });
  }

  __reset() {
    this.__model = {
      ...this.guarantor,
      ...INITIAL_GUARANTOR_FORM,
      relation: this.__model.relation,
    };

    this.__selectedGuarantor = null;
    this.__searchResults = [];
    this.onDirtyChange(false);
  }

  __openEditOverlay(handler) {
    const { overlay, model } = handler;
    const rawModel = this.__model[model];

    if (model === SELECT_BY_TYPE.ORGANIZATION) {
      return openOverlay(overlay, {
        item: rawModel,
        showBackButton: true,
      });
    }

    return rawModel.patientId
      ? this.__openPatientEditOverlay(rawModel)
      : openOverlay(overlay, mapToModel(rawModel));
  }

  async __openPatientEditOverlay(person) {
    const { patientId, id } = person;
    const patient = await patientApiClient.fetchOne(patientId);

    if (patient.hasPhoto) {
      patient.photoSrc = await getPatientImage(patientId, 'small');
    }

    const patientFromOverlay = await openOverlay(OVERLAY_KEYS.PATIENT, patient);

    return patientFromOverlay
      ? { ...mapPatientToPerson(patientFromOverlay), id }
      : false;
  }

  async __fetchOrganizations() {
    const organizations = await getOrganizations();
    this.__organizations = organizations
      .filter(organization => organization.active)
      .map(org => addLabelToOrg(org));

    if (!this.__selectedGuarantor) this.__searchResults = this.__organizations;
  }

  async __addGuarantor(guarantorType) {
    const handler = GUARANTOR_TYPES_HANDLER[guarantorType];
    const guarantor = await openOverlay(handler.overlay, {
      item: null,
      showBackButton: true,
    });

    if (guarantor) {
      this.__model = {
        ...this.__model,
        ...{
          [`${handler.model}Id`]: guarantor.id,
          [handler.model]: guarantor,
        },
      };

      this.__afterGuarantorOverlayClosed(handler.label(guarantor));

      this.onDirtyChange(this.isDirty());
    }
  }

  __afterGuarantorOverlayClosed(guarantor) {
    if (this.selection === 'organization') {
      this.__fetchOrganizations();
    }

    this.__selectedGuarantor = guarantor;
    this.__searchResults = [this.__selectedGuarantor];
  }

  isDirty() {
    const { personId, organizationId } = this.__model;

    return (personId || organizationId) && !equal(this.__model, this.guarantor);
  }

  __saveGuarantorForm() {
    if (this.__saving) return;
    this.__saving = true;
    const newGuarantor = {
      id: this.__model.id,
      patientId: this.__model.patientId,
      personId: this.__model.personId,
      relation: this.__model.relation,
      active: this.__model.active,
      default: this.__model.default,
      note: this.__model.note,
      organizationId: this.__model.organizationId,
    };

    this.__patientGuarantorService.save(newGuarantor);
  }

  __updateModel() {
    this.__model =
      this.guarantor.personId || this.guarantor.organizationId
        ? { ...this.guarantor }
        : { ...this.guarantor, ...INITIAL_GUARANTOR_FORM };
  }

  __updateSelection(changedProps) {
    if (changedProps.has('selection') && !this.selection) {
      this.__reset();
    }
  }

  update(changedProps) {
    if (changedProps.has('guarantor') && this.guarantor) {
      this.__updateModel();
    }

    this.__updateSelection(changedProps);

    super.update(changedProps);
  }

  __renderCards() {
    return html`
      <div class="content-padding main">
        ${CARDS_CONFIG.map(c => this.__renderCard(c))}
      </div>
    `;
  }

  __renderCard(card) {
    return html`
      <neb-selection-card
        id="${card.id}"
        class="card"
        .title="${card.title}"
        .icon="${card.icon}"
        .onClick="${this.__handlers[card.handler]}"
      >
        ${card.content}
      </neb-selection-card>
    `;
  }

  render() {
    return this.__model.id || this.selection
      ? html`
          <neb-guarantor-form
            id="${ELEMENTS.form.id}"
            .saving="${this.__saving}"
            .showResults="${this.__showResults}"
            .searchValue="${this.__search}"
            .type="${this.selection}"
            .model="${this.__model}"
            .selectedGuarantor="${this.__selectedGuarantor}"
            .searchResults="${this.__searchResults}"
            .onEdit="${this.__handlers.edit}"
            .onSave="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
            .onSelect="${this.__handlers.select}"
            .onSearch="${this.__handlers.search}"
            .onChange="${this.__handlers.changeModel}"
            .onChangeDefault="${this.__handlers.changeDefault}"
            .onAddGuarantor="${this.__handlers.addGuarantor}"
            ?dirty="${this.dirty}"
          ></neb-guarantor-form>
        `
      : html` ${this.__renderCards()} `;
  }
}

window.customElements.define(
  'neb-guarantor-form-controller',
  NebGuarantorFormController,
);
