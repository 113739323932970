import { css, unsafeCSS } from 'lit';

import { getTextWidth } from './dom';
import { summate } from './utils';

const SPACING_ICON = 20;
const SPACING_MODE = 12;
export const MODE = {
  NONE: 'none',
  DETAIL: 'detail',
  EXPAND: 'expand',
};

function buildFlex(flex, value) {
  const grow = flex ? flex.cssText.split(' ')[0] : '0';

  // eslint-disable-next-line prettier/prettier
  return css`${unsafeCSS(grow)} 0 ${unsafeCSS(value)}px`;
}

function transmuteConfig(config, widths) {
  return config.map((item, index) => ({
    ...item,
    flex: buildFlex(item.flex, widths[index]),
  }));
}

export function findContentWidths(config, items, el, borderedColumns = []) {
  const sanitize = v => (v !== null && v !== undefined ? v : '');
  const headerItem = config.reduce(
    (accum, curr, _index) => ({ ...accum, [curr.key]: curr.label }),
    {},
  );

  const sanitizedItems = [headerItem, ...items].map(item =>
    Object.entries(item).reduce(
      (accum, [k, v]) => ({ ...accum, [k]: sanitize(v) }),
      {},
    ),
  );

  return config.map(column =>
    sanitizedItems.reduce((widest, item, index) => {
      const raw = item[column.key];
      const basis = column.flex
        ? Number(column.flex.cssText.split(' ')[2].replace('px', ''))
        : 0;

      const value =
        index && column.formatter ? column.formatter(raw, item) : raw;

      let width = column.preserveBasis ? basis : getTextWidth(value, el);

      if (borderedColumns.includes(column.key)) {
        width += 20;
      }

      return width > widest ? width : widest;
    }, 0),
  );
}

export function findTableWidth(config, items, opts = {}) {
  const el = document.createElement('span');
  document.body.appendChild(el);

  const configWidths = findContentWidths(
    config,
    items,
    el,
    opts.borderedColumns,
  );
  document.body.removeChild(el);

  const hasMode = opts.mode === MODE.DETAIL || opts.mode === MODE.EXPAND;
  const insetSpacing = opts.preview ? 10 : 20;
  const allWidths = [
    ...configWidths,
    opts.reorder ? SPACING_ICON : null,
    opts.remove ? SPACING_ICON : null,
    hasMode ? SPACING_MODE : null,
  ].filter(item => item !== null);

  return {
    width: insetSpacing * 2 + summate(allWidths),
    config: transmuteConfig(config, configWidths),
  };
}
