export default (reportData, updatedReport) => ({
  ...reportData,
  ...updatedReport,
  claims: [
    ...reportData.claims.map(claim => {
      const updatedClaim = updatedReport.claims.find(
        c => c.claimReportId === claim.claimReportId,
      );
      if (!updatedClaim) return claim;

      const lineItems = claim.lineItems.map(lineItem => {
        const updatedLineItem = updatedClaim.lineItems.find(
          li => li.lineItemReportId === lineItem.lineItemReportId,
        );
        return updatedLineItem || lineItem;
      });
      return {
        ...updatedClaim,
        lineItems,
      };
    }),
  ],
});
